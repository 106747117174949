/**
 * Creates a sort function to passe through arrays' sort method.
 *
 * @param prop The key of the prop to sort by
 * @example
 * ```
 * const list = [
 *     {
 *         label: "H",
 *     },
 *     {
 *         label: "A",
 *     },
 *     {
 *         label: "Z",
 *     },
 * ]
 * list.sort(sortAscending('label'));
 *
 * // [
 * //     {
 * //         label: "A",
 * //     },
 * //     {
 * //         label: "H",
 * //     },
 * //     {
 * //         label: "Z",
 * //     },
 * // ]
 * ```
 * @returns The sort function.
 */
export const sortAscending = <T extends object>(prop: keyof T): ((obj1: T, obj2: T) => number) => {
    return (obj1, obj2) => (obj1[prop] === obj2[prop] ? 0 : obj1[prop] > obj2[prop] ? 1 : -1);
};
