import { Component, Input, OnInit } from '@angular/core';
import { TextFlavor, ItemSlot } from '@genetec/gelato';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { TranslateService } from '@ngx-translate/core';
import { IGuid, SafeGuid } from 'safeguid';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Entity } from 'RestClient/Client/Model/Entity';
import { EventEntry } from '@modules/shared/api/api';
import { filter, take } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { SharedContentFields } from '../../../enumerations/shared-content-fields';
import { ContentService } from '../../../interfaces/plugins/public/plugin-services-public.interface';
import { ContentProviderService } from '../../../services/content/content-provider.service';
import { TrackingService } from '../../../services/tracking.service';
import { TrackedComponent } from '../../tracked/tracked.component';
import { EventsService } from '../../../services/events/events.service';
import { MonitoredEntityEvent } from '../../../controllers/events/monitored-entity-event';
import { Content } from '../../../interfaces/plugins/public/plugin-public.interface';

@UntilDestroy()
@Component({
    selector: 'app-latest-events-widget',
    templateUrl: './latest-events-widget.component.html',
    styleUrls: ['./latest-events-widget.component.scss'],
})
export class LatestEventsWidgetComponent extends TrackedComponent implements OnInit {
    private static readonly MaxNumberOfDisplayedEvents = 5;

    @Input() public content?: Content;
    @Input() public isLoading = true;
    @Input() public contentService?: ContentService;

    public readonly TextFlavor = TextFlavor;
    public readonly ItemSlot = ItemSlot;

    public recentEventsTitle = '';
    public entity?: IEntity;
    public events$: Observable<EventEntry[]>;

    private entityId!: IGuid;
    private eventsSubject$ = new BehaviorSubject<EventEntry[]>([]);

    constructor(
        private contentProviderService: ContentProviderService,
        trackingService: TrackingService,
        private translateService: TranslateService,
        private eventsService: EventsService,
        private securityCenterClientService: SecurityCenterClientService
    ) {
        super(trackingService);
        this.events$ = this.eventsSubject$.asObservable();
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();

        this.entityId = SafeGuid.parse(this.content?.source ?? '');

        const retrieveLatestEvents$ = this.eventsService.retrieveLatestEvents(this.entityId, LatestEventsWidgetComponent.MaxNumberOfDisplayedEvents);
        const getEntity$ = of(await this.securityCenterClientService?.scClient?.getEntityAsync<Entity, IEntity>(Entity, this.entityId));

        combineLatest([retrieveLatestEvents$, getEntity$])
            .pipe(take(1), untilDestroyed(this))
            .subscribe(([eventEntries, fetchedEntity]) => {
                if (fetchedEntity) {
                    this.entity = fetchedEntity;
                }
                this.isLoading = false;
                this.eventsSubject$.next(eventEntries);
                this.eventsService
                    .startMonitoringEvents(this.entityId)
                    .pipe(untilDestroyed(this))
                    .subscribe((events) => this.onEventsReceived(events));
            });

        this.eventsSubject$
            .pipe(
                filter(() => !this.isLoading),
                untilDestroyed(this)
            )
            .subscribe((entries) => this.updateRecentEventsTitle(entries.length > 0));
    }

    public async navigate(event: EventEntry): Promise<void> {
        const eventField = new FieldObject();
        eventField.setFieldGuid(SharedContentFields.EventId, event.id);
        eventField.setFieldGuid(SharedContentFields.ContextId, event.contextId);

        const content = await this.contentProviderService.getContentFromFieldsAsync(eventField);
        if (content && !content.id.isEmpty()) {
            this.contentService?.pushContent(content);
        }
    }

    private onEventsReceived(monitoredEntityEvents: MonitoredEntityEvent[]) {
        if (this.eventsSubject$ && monitoredEntityEvents?.length > 0) {
            const newEvents = monitoredEntityEvents.map((monitoredEntityEvent) => monitoredEntityEvent.event);
            const currentEvents = this.eventsSubject$.getValue() ?? [];
            const allEvents = [...newEvents, ...currentEvents];
            allEvents.length = Math.min(allEvents.length, LatestEventsWidgetComponent.MaxNumberOfDisplayedEvents);
            this.eventsSubject$.next(allEvents);
        }
    }

    private updateRecentEventsTitle(hasEvents: boolean) {
        this.recentEventsTitle = this.translateService.instant(hasEvents ? 'STE_LABEL_RECENTEVENTS' : 'STE_LABEL_NORECENTEVENTS') as string;
    }
}
