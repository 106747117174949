import { List } from 'immutable';
import { IGuid, SafeGuid } from 'safeguid';

export class IncidentFilter {
    public static empty: IncidentFilter = new IncidentFilter();

    private constructor(
        public incidentTypeIds = List<IGuid>(),
        public locationIds = List<IGuid>(),
        public ownerIds = List<IGuid>(),
        public priorityIds = List<IGuid>(),
        public stateIds = List<IGuid>(),
        public readonly dateTimeFrom: string | null = null,
        public readonly dateTimeTo: string | null = null
    ) {
        Object.freeze(this);
    }

    public with({
        incidentTypeIds,
        locationIds,
        ownerIds,
        priorityIds,
        stateIds,
        dateTimeFrom,
        dateTimeTo,
    }: {
        incidentTypeIds?: List<IGuid>;
        locationIds?: List<IGuid>;
        ownerIds?: List<IGuid>;
        priorityIds?: List<IGuid>;
        stateIds?: List<IGuid>;
        dateTimeFrom?: string | null;
        dateTimeTo?: string | null;
    }): IncidentFilter {
        return new IncidentFilter(
            incidentTypeIds ?? this.incidentTypeIds,
            locationIds ?? this.locationIds,
            ownerIds ?? this.ownerIds,
            priorityIds ?? this.priorityIds,
            stateIds ?? this.stateIds,
            dateTimeFrom === undefined ? this.dateTimeFrom : dateTimeFrom,
            dateTimeTo === undefined ? this.dateTimeTo : dateTimeTo
        );
    }

    public asURLSearchParams(): URLSearchParams {
        const params = new URLSearchParams();

        if (this.incidentTypeIds.count() > 0) params.set('incidentTypeIds', this.incidentTypeIds.join(','));
        if (this.locationIds.count() > 0) params.set('locationIds', this.locationIds.join(','));
        if (this.ownerIds.count() > 0) params.set('ownerIds', this.ownerIds.join(','));
        if (this.priorityIds.count() > 0) params.set('priorityIds', this.priorityIds.join(','));
        if (this.stateIds.count() > 0) params.set('stateIds', this.stateIds.join(','));
        if (this.dateTimeFrom) params.set('dateTimeFrom', this.dateTimeFrom.toString());
        if (this.dateTimeTo) params.set('dateTimeTo', this.dateTimeTo.toString());

        return params;
    }
}
