/**
 * Helper class that takes an array of string, built from the videoController that represents supported commands on a specific camera.
 * This class exposes everyPossible commands, and a method that returns true or false if its supported or not.
 * This makes for a cleaner approach in the template
 */
export class SupportedCommands {
    private InvalidCommand = 'InvalidCommand';
    private StartZoom = 'StartZoom';
    private StopZoom = 'StopZoom';
    private StartPanTilt = 'StartPanTilt';
    private StopPanTilt = 'StopPanTilt';
    private GoToPreset = 'GoToPreset';
    private SetPreset = 'SetPreset';
    private ClearPreset = 'ClearPreset';
    private StartIris = 'StartIris';
    private StopIris = 'StopIris';
    private StartFocus = 'StartFocus';
    private StopFocus = 'StopFocus';
    private SetAuxiliary = 'SetAuxiliary';
    private ClearAuxiliary = 'ClearAuxiliary';
    private StartPattern = 'StartPattern';
    private StopPattern = 'StopPattern';
    private RunPattern = 'RunPattern';
    private ClearPattern = 'ClearPattern';
    private Flip = 'Flip';
    private Enter = 'Enter';
    private Home = 'Home';
    private MenuOn = 'MenuOn';
    private MenuOff = 'MenuOff';
    private MenuUp = 'MenuUp';
    private MenuDown = 'MenuDown';
    private MenuLeft = 'MenuLeft';
    private MenuRight = 'MenuRight';
    private MenuNext = 'MenuNext';
    private MenuPrevious = 'MenuPrevious';
    private MenuStop = 'MenuStop';
    private MenuCancel = 'MenuCancel';
    private MenuTextMode = 'MenuTextMode';
    private SpecificCommand = 'SpecificCommand';
    private Vcr = 'Vcr';
    private Eject = 'Eject';
    private Stop = 'Stop';
    private Record = 'Record';
    private Pause = 'Pause';
    private Play = 'Play';
    private Rewind = 'Rewind';
    private Fastfoward = 'Fastfoward';
    private NextFrame = 'NextFrame';
    private PrevKeyFrame = 'PrevKeyFrame';
    private GoToPosition = 'GoToPosition';
    private RunTour = 'RunTour';
    private RunSequence = 'RunSequence';
    private SetPositionZero = 'SetPositionZero';
    private GoToAbsolutePosition = 'GoToAbsolutePosition';
    private GoToRelativePosition = 'GoToRelativePosition';
    private GetPosition = 'GetPosition';
    private MenuUpUp = 'MenuUpUp';
    private MenuDownUp = 'MenuDownUp';
    private MenuLeftUp = 'MenuLeftUp';
    private MenuRightUp = 'MenuRightUp';
    private MenuUpDown = 'MenuUpDown';
    private MenuDownDown = 'MenuDownDown';
    private MenuLeftDown = 'MenuLeftDown';
    private MenuRightDown = 'MenuRightDown';
    private Alarm = 'Alarm';
    private Sequence = 'Sequence';
    private Clear = 'Clear';
    private Set = 'Set';
    private First = 'First';
    private MenuBank = 'MenuBank';
    private MenuStart = 'MenuStart';
    private MenuNumber = 'MenuNumber';
    private MenuExit = 'MenuExit';
    private MenuScrollLeft = 'MenuScrollLeft';
    private MenuScrollRight = 'MenuScrollRight';
    private MenuScrollUp = 'MenuScrollUp';
    private MenuScrollDown = 'MenuScrollDown';
    private Lock = 'Lock';
    private OverrideLock = 'OverrideLock';
    private GoToNormalizedPosition = 'GoToNormalizedPosition';
    private GoToImagePosition = 'GoToImagePosition';
    private StartPresetTour = 'StartPresetTour';
    private StopPresetTour = 'StopPresetTour';
    private LastCommand = 'LastCommand';

    private commands: string[];
    constructor(commands: string[]) {
        this.commands = commands;
    }
    public supportsInvalidCommand(): boolean {
        return this.commands.indexOf(this.InvalidCommand) !== -1;
    }

    public supportsStartZoom(): boolean {
        return this.commands.indexOf(this.StartZoom) !== -1;
    }

    public supportsStopZoom(): boolean {
        return this.commands.indexOf(this.StopZoom) !== -1;
    }

    public supportsStartPanTilt(): boolean {
        return this.commands.indexOf(this.StartPanTilt) !== -1;
    }

    public supportsStopPanTilt(): boolean {
        return this.commands.indexOf(this.StopPanTilt) !== -1;
    }

    public supportsGoToPreset(): boolean {
        return this.commands.indexOf(this.GoToPreset) !== -1;
    }

    public supportsSetPreset(): boolean {
        return this.commands.indexOf(this.SetPreset) !== -1;
    }

    public supportsClearPreset(): boolean {
        return this.commands.indexOf(this.ClearPreset) !== -1;
    }

    public supportsStartIris(): boolean {
        return this.commands.indexOf(this.StartIris) !== -1;
    }

    public supportsStopIris(): boolean {
        return this.commands.indexOf(this.StopIris) !== -1;
    }

    public supportsStartFocus(): boolean {
        return this.commands.indexOf(this.StartFocus) !== -1;
    }

    public supportsStopFocus(): boolean {
        return this.commands.indexOf(this.StopFocus) !== -1;
    }

    public supportsSetAuxiliary(): boolean {
        return this.commands.indexOf(this.SetAuxiliary) !== -1;
    }

    public supportsClearAuxiliary(): boolean {
        return this.commands.indexOf(this.ClearAuxiliary) !== -1;
    }

    public supportsStartPattern(): boolean {
        return this.commands.indexOf(this.StartPattern) !== -1;
    }

    public supportsStopPattern(): boolean {
        return this.commands.indexOf(this.StopPattern) !== -1;
    }

    public supportsRunPattern(): boolean {
        return this.commands.indexOf(this.RunPattern) !== -1;
    }

    public supportsClearPattern(): boolean {
        return this.commands.indexOf(this.ClearPattern) !== -1;
    }

    public supportsFlip(): boolean {
        return this.commands.indexOf(this.Flip) !== -1;
    }

    public supportsEnter(): boolean {
        return this.commands.indexOf(this.Enter) !== -1;
    }

    public supportsHome(): boolean {
        return this.commands.indexOf(this.Home) !== -1;
    }

    public supportsMenuOn(): boolean {
        return this.commands.indexOf(this.MenuOn) !== -1;
    }

    public supportsMenuOff(): boolean {
        return this.commands.indexOf(this.MenuOff) !== -1;
    }

    public supportsMenuUp(): boolean {
        return this.commands.indexOf(this.MenuUp) !== -1;
    }

    public supportsMenuDown(): boolean {
        return this.commands.indexOf(this.MenuDown) !== -1;
    }

    public supportsMenuLeft(): boolean {
        return this.commands.indexOf(this.MenuLeft) !== -1;
    }

    public supportsMenuRight(): boolean {
        return this.commands.indexOf(this.MenuRight) !== -1;
    }

    public supportsMenuNext(): boolean {
        return this.commands.indexOf(this.MenuNext) !== -1;
    }

    public supportsMenuPrevious(): boolean {
        return this.commands.indexOf(this.MenuPrevious) !== -1;
    }

    public supportsMenuStop(): boolean {
        return this.commands.indexOf(this.MenuStop) !== -1;
    }

    public supportsMenuCancel(): boolean {
        return this.commands.indexOf(this.MenuCancel) !== -1;
    }

    public supportsMenuTextMode(): boolean {
        return this.commands.indexOf(this.MenuTextMode) !== -1;
    }

    public supportsSpecificCommand(): boolean {
        return this.commands.indexOf(this.SpecificCommand) !== -1;
    }

    public supportsVcr(): boolean {
        return this.commands.indexOf(this.Vcr) !== -1;
    }

    public supportsEject(): boolean {
        return this.commands.indexOf(this.Eject) !== -1;
    }

    public supportsStop(): boolean {
        return this.commands.indexOf(this.Stop) !== -1;
    }

    public supportsRecord(): boolean {
        return this.commands.indexOf(this.Record) !== -1;
    }

    public supportsPause(): boolean {
        return this.commands.indexOf(this.Pause) !== -1;
    }

    public supportsPlay(): boolean {
        return this.commands.indexOf(this.Play) !== -1;
    }

    public supportsRewind(): boolean {
        return this.commands.indexOf(this.Rewind) !== -1;
    }

    public supportsFastfoward(): boolean {
        return this.commands.indexOf(this.Fastfoward) !== -1;
    }

    public supportsNextFrame(): boolean {
        return this.commands.indexOf(this.NextFrame) !== -1;
    }

    public supportsPrevKeyFrame(): boolean {
        return this.commands.indexOf(this.PrevKeyFrame) !== -1;
    }

    public supportsGoToPosition(): boolean {
        return this.commands.indexOf(this.GoToPosition) !== -1;
    }

    public supportsRunTour(): boolean {
        return this.commands.indexOf(this.RunTour) !== -1;
    }

    public supportsRunSequence(): boolean {
        return this.commands.indexOf(this.RunSequence) !== -1;
    }

    public supportsSetPositionZero(): boolean {
        return this.commands.indexOf(this.SetPositionZero) !== -1;
    }

    public supportsGoToAbsolutePosition(): boolean {
        return this.commands.indexOf(this.GoToAbsolutePosition) !== -1;
    }

    public supportsGoToRelativePosition(): boolean {
        return this.commands.indexOf(this.GoToRelativePosition) !== -1;
    }

    public supportsGetPosition(): boolean {
        return this.commands.indexOf(this.GetPosition) !== -1;
    }

    public supportsMenuUpUp(): boolean {
        return this.commands.indexOf(this.MenuUpUp) !== -1;
    }

    public supportsMenuDownUp(): boolean {
        return this.commands.indexOf(this.MenuDownUp) !== -1;
    }

    public supportsMenuLeftUp(): boolean {
        return this.commands.indexOf(this.MenuLeftUp) !== -1;
    }

    public supportsMenuRightUp(): boolean {
        return this.commands.indexOf(this.MenuRightUp) !== -1;
    }

    public supportsMenuUpDown(): boolean {
        return this.commands.indexOf(this.MenuUpDown) !== -1;
    }

    public supportsMenuDownDown(): boolean {
        return this.commands.indexOf(this.MenuDownDown) !== -1;
    }

    public supportsMenuLeftDown(): boolean {
        return this.commands.indexOf(this.MenuLeftDown) !== -1;
    }

    public supportsMenuRightDown(): boolean {
        return this.commands.indexOf(this.MenuRightDown) !== -1;
    }

    public supportsAlarm(): boolean {
        return this.commands.indexOf(this.Alarm) !== -1;
    }

    public supportsSequence(): boolean {
        return this.commands.indexOf(this.Sequence) !== -1;
    }

    public supportsClear(): boolean {
        return this.commands.indexOf(this.Clear) !== -1;
    }

    public supportsSet(): boolean {
        return this.commands.indexOf(this.Set) !== -1;
    }

    public supportsFirst(): boolean {
        return this.commands.indexOf(this.First) !== -1;
    }

    public supportsMenuBank(): boolean {
        return this.commands.indexOf(this.MenuBank) !== -1;
    }

    public supportsMenuStart(): boolean {
        return this.commands.indexOf(this.MenuStart) !== -1;
    }

    public supportsMenuNumber(): boolean {
        return this.commands.indexOf(this.MenuNumber) !== -1;
    }

    public supportsMenuExit(): boolean {
        return this.commands.indexOf(this.MenuExit) !== -1;
    }

    public supportsMenuScrollLeft(): boolean {
        return this.commands.indexOf(this.MenuScrollLeft) !== -1;
    }

    public supportsMenuScrollRight(): boolean {
        return this.commands.indexOf(this.MenuScrollRight) !== -1;
    }

    public supportsMenuScrollUp(): boolean {
        return this.commands.indexOf(this.MenuScrollUp) !== -1;
    }

    public supportsMenuScrollDown(): boolean {
        return this.commands.indexOf(this.MenuScrollDown) !== -1;
    }

    public supportsLock(): boolean {
        return this.commands.indexOf(this.Lock) !== -1;
    }

    public supportsOverrideLock(): boolean {
        return this.commands.indexOf(this.OverrideLock) !== -1;
    }

    public supportsGoToNormalizedPosition(): boolean {
        return this.commands.indexOf(this.GoToNormalizedPosition) !== -1;
    }

    public supportsGoToImagePosition(): boolean {
        return this.commands.indexOf(this.GoToImagePosition) !== -1;
    }

    public supportsStartPresetTour(): boolean {
        return this.commands.indexOf(this.StartPresetTour) !== -1;
    }

    public supportsStopPresetTour(): boolean {
        return this.commands.indexOf(this.StopPresetTour) !== -1;
    }

    public supportsLastCommand(): boolean {
        return this.commands.indexOf(this.LastCommand) !== -1;
    }
}
