// Autogenerated: v2/help/Entities/ApiRoute?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:08 PM
import { IEntity, EntityFields } from './IEntity';
import { IGuid } from 'safeguid';

// #region Fields

export class RouteEntityFields extends EntityFields {
    public static detectedTransportsFrom1To2Field = 'DetectedTransportsFrom1To2';
    public static detectedTransportsFrom2To1Field = 'DetectedTransportsFrom2To1';
    public static endPoint1Field = 'EndPoint1';
    public static endPoint2Field = 'EndPoint2';
    public static supportedTransportsField = 'SupportedTransports';
    public static usePrivateAddressFrom1To2Field = 'UsePrivateAddressFrom1To2';
    public static usePrivateAddressFrom2To1Field = 'UsePrivateAddressFrom2To1';
}

// #endregion Fields

export interface IRouteEntity extends IEntity {
    // #region REST Properties

    readonly detectedTransportsFrom1To2: string;

    readonly detectedTransportsFrom2To1: string;

    endPoint1: IGuid;

    endPoint2: IGuid;

    supportedTransports: string;

    usePrivateAddressFrom1To2: boolean;

    usePrivateAddressFrom2To1: boolean;

    // #endregion REST Properties

}

