import { MapObjectView, MarkerMapObject, TextMapObject } from '@genetec/web-maps';
import { IEventBase } from 'RestClient/Client/Interface/IEventBase';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { Subject } from 'rxjs';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { WebAppClient } from 'WebClient/WebAppClient';
import { MapObjectEventBase } from '../../../data/MapObjectEventBase';
import { MapObjectAddOrUpdateEvent } from '../../../data/MapObjectAddOrUpdateEvent';
import { MapObjectRemoveEvent } from '../../../data/MapObjectRemoveEvent';
import { MapLayersRefreshEvent } from '../../../data/MapLayersRefreshEvent';
import { MapObjectPositionUpdateEvent } from '../../../data/MapObjectPositionUpdateEvent';
import { MapItemUpdater } from './map-item-updater';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class MapEventProvider {
    public onEventAdded$: Subject<MapObjectAddOrUpdateEvent> = new Subject<MapObjectAddOrUpdateEvent>();
    public onEventRemoved$: Subject<MapObjectRemoveEvent> = new Subject<MapObjectRemoveEvent>();

    private eventHandlers: Map<string, (event: IEventBase) => void> = new Map<string, (event: IEventBase) => void>();
    private subscriptions = new SubscriptionCollection();

    constructor(private scClient: WebAppClient, private mapItemUpdater: MapItemUpdater) {
        this.initialize();
    }

    public destroy(): void {
        this.subscriptions.unsubscribeAll();
    }

    private initialize() {
        this.scClient.registerAdditionalEventTypes('MapObjectAddOrUpdateEvent', MapObjectAddOrUpdateEvent);
        this.scClient.registerAdditionalEventTypes('MapObjectPositionUpdateEvent', MapObjectPositionUpdateEvent);
        this.scClient.registerAdditionalEventTypes('MapObjectRemoveEvent', MapObjectRemoveEvent);
        this.scClient.registerAdditionalEventTypes('MapLayersRefreshEvent', MapLayersRefreshEvent);
        this.eventHandlers.set('MapObjectPositionUpdateEvent', (event) => this.onMapObjectPositionUpdateEvent(event as MapObjectPositionUpdateEvent));
        this.eventHandlers.set('MapLayersRefreshEvent', (event) => this.onMapLayersRefreshEvent(event as MapLayersRefreshEvent));
        this.eventHandlers.set('MapObjectAddOrUpdateEvent', (event) => this.onMapObjectAddOrUpdateEvent(event as MapObjectAddOrUpdateEvent));
        this.eventHandlers.set('MapObjectRemoveEvent', (event) => this.onMapObjectRemoveEvent(event as MapObjectRemoveEvent));

        this.subscriptions.add(this.scClient.onEventReceived((arg) => this.onEventReceived(arg)));
    }

    private onEventReceived(args: EventReceivedArg) {
        const eventHandler = this.eventHandlers.get(args.event.eventType);
        if (eventHandler) {
            eventHandler(args.event);
        }
    }

    private onMapObjectPositionUpdateEvent(event: MapObjectPositionUpdateEvent) {
        if (!this.mapItemUpdater.map?.isMapInitialized()) return;

        const mapObjectView = this.mapItemUpdater.map.getMapObjectView(event.sourceEntity.toTypescriptGuid()) as MapObjectView;
        if (!mapObjectView) {
            this.mapItemUpdater.displayMapObject(event.sourceEntity);
        } else {
            // TODO: This won't work for shapes, they don't have latitude / longitude but only points.
            const mapObject = mapObjectView.data as MarkerMapObject | TextMapObject;
            mapObject.name = event.sourceEntityDesc;
            mapObject.latitude = event.latitude;
            mapObject.longitude = event.longitude;

            this.mapItemUpdater.map.updateMapObjects(mapObject);
        }
    }

    private async onMapLayersRefreshEvent(event: MapLayersRefreshEvent) {
        await this.mapItemUpdater.refreshLayers(Array.from(event.layers));
    }

    private refreshMapObjects(event: MapObjectAddOrUpdateEvent) {
        if (!this.mapItemUpdater.map?.isMapInitialized()) return;

        event.mapObjectIds.forEach((id) => {
            if (this.mapItemUpdater.map) {
                // if we have an event, only display map object if it doesn't exist
                if (event.event) {
                    if (!this.mapItemUpdater.map.mapObjectViewExists(id.toTypescriptGuid())) {
                        this.mapItemUpdater.displayMapObject(id);
                    }
                } else if (event.updateOnlyIfVisible) {
                    this.mapItemUpdater.updateMapObjectIfVisible(id);
                } else {
                    // new items
                    this.mapItemUpdater.displayMapObject(id);
                }
            }
        });
    }

    private onMapObjectRemoveEvent(event: MapObjectRemoveEvent) {
        if (!event.removeEventContentOnly) {
            this.mapItemUpdater.map?.removeItems(Array.from(event.mapObjectIds, (item) => item.toString().toLowerCase()));
        } else {
            this.processEvent(event);
        }
    }

    private onMapObjectAddOrUpdateEvent(event: MapObjectAddOrUpdateEvent) {
        this.refreshMapObjects(event);

        if (event.event?.length > 0) {
            this.processEvent(event);
        }
    }

    private processEvent(event: MapObjectEventBase) {
        if (event instanceof MapObjectRemoveEvent) {
            this.onEventRemoved$.next(event);
        } else if (event instanceof MapObjectAddOrUpdateEvent) {
            this.onEventAdded$.next(event);
        }
    }
}
