import { SecurityCenterClient } from '../SecurityCenterClient';
import { Deferred } from '../../Helpers/Helpers';
import { Debouncer } from '../../Helpers/Debouncer';
import { Timer } from '../../Helpers/Timer';
import { EntityQuery } from '../Queries/EntityQuery';
import { LogonStateChangedArgs } from '../Args/LogonStateChangedArgs';
import { FilterSet } from '../../Helpers/Linq';
import * as Args from '../../Connection/RestArgs';
import { EventFilter } from '../../Connection/EventFilter';
import { IGuid, SafeGuid } from 'safeguid';
import {
    modificationHandlerComparer,
    modificationHandlerAction,
    modificationHandlerDelete,
    modificationHandlerEvent,
    IEntityCacheTask,
    modificationHandlerField,
} from '../Interface/IEntityCacheTask';
import { IEntity } from '../Interface/IEntity';
import { Mutex } from '../../Helpers/semaphore';
import { ITransaction } from '../Interface/ITransaction';
import { IRestResponse } from '../Interface/IRestResponse';
import { EntityCacheTaskMonitor } from './EntityCacheTaskMonitor';
import { Entity } from '../Model/Entity';
import { merge, Observable } from 'rxjs';

class ModificationHandler {
    public id = SafeGuid.newGuid().toString();
    public validFlags = 0;
    public comparer: modificationHandlerComparer | null = null;
    public action: modificationHandlerAction | null = null;

    public ModificationHandler(validFlags: number, comparer: modificationHandlerComparer, action: modificationHandlerAction) {
        this.comparer = comparer;
        this.action = action;
        this.validFlags = validFlags;
    }
}

class CachedEntity {
    private _entity: IEntity;
    public modificationHandlers = new FilterSet<ModificationHandler>();
    public deleteHandlers = new FilterSet<modificationHandlerDelete>();
    public eventHandlers = new Map<string, modificationHandlerEvent>();
    public isDirty = false;

    public get entity() {
        return this._entity;
    }
    public set entity(value: IEntity) {
        this._entity = value;
        this.isDirty = false;
    }

    constructor(entity: IEntity) {
        this._entity = entity;
    }

    public dispose(): void {
        this.modificationHandlers.clear();
        this.deleteHandlers.clear();
        this.eventHandlers.clear();
    }
}

class RefreshEntity {
    public id: IGuid = SafeGuid.EMPTY;
    public clearRelationOnly = false;
    public timer: Timer;

    constructor(id: IGuid, clearRelationOnly: boolean, timer: Timer) {
        this.id = id;
        this.clearRelationOnly = clearRelationOnly;
        this.timer = timer;
    }
}

export class EntityCacheTask implements IEntityCacheTask {
    //#region fields

    private getEntityMutex = new Mutex();
    private _client: SecurityCenterClient;
    private _cache = SafeGuid.createMap<CachedEntity>();
    private _debouncerInvalidate: Debouncer;
    private _invalidatedEntities = SafeGuid.createMap<number>();
    private _registeredFilter = SafeGuid.createSet();
    private _clearRelationDictionary = SafeGuid.createMap<RefreshEntity>();
    private _defaultDownloadMask: Set<string>;
    private subscriptions: (() => void)[] = [];
    private uniqueId = SafeGuid.newGuid();

    // #endregion

    //#region constructor

    constructor(client: SecurityCenterClient, debouncePeriod: number, debouncePeriodMax: number, downloadMask = '*') {
        this._client = client;

        const mask = downloadMask.replace(/\s+/g, '').toLowerCase(); // Remove whitespaces
        if (mask.includes('*')) {
            this._defaultDownloadMask = new Set<string>('*');
        } else {
            this._defaultDownloadMask = new Set<string>(mask.split(','));
            for (const field of client.defaultFields.Fields) {
                this._defaultDownloadMask.add(field);
            }
        }

        this._debouncerInvalidate = new Debouncer(
            false,
            async () => {
                await this.performCacheUpdateAsync();
            },
            debouncePeriod,
            debouncePeriodMax,
        );

        // Register to Streamer
        this.subscriptions.push(this._client.onEntityInvalidated((arg) => this.onEntityInvalidated(arg)));
        this.subscriptions.push(this._client.onEntityRemoved((arg) => this.onEntityRemoved(arg)));
        this.subscriptions.push(this._client.onEventReceived((arg) => this.onEventReceived(arg)));
        this.subscriptions.push(
            this._client.onLogonStateChanged((arg) => {
                this.onLogonStateChanged(arg);
            }),
        );
    }

    public async dispose() {
        await this.resetAsync();
        for (const subscription of this.subscriptions) {
            subscription();
        }
        this.subscriptions = [];
    }

    // #endregion

    // #region Public methods

    public async resetAsync() {
        if (this._client.isLoggedOn) {
            await this._client.removeFromStreamerFiltersAsync(this._registeredFilter);
        }

        this._registeredFilter.clear();
        const monitor = EntityCacheTaskMonitor.get(this._client);
        for (const entry of this._cache.entries()) {
            monitor.removeMonitoredEntity(this.uniqueId, entry[0]);
            entry[1].dispose();
        }
        this._cache.clear();

        for (const refreshEntity of this._clearRelationDictionary) {
            refreshEntity[1].timer.dispose();
        }
        this._clearRelationDictionary.clear();
    }

    public removeCachedEntity(id: IGuid): void {
        if (this._cache.has(id)) {
            const entity = this._cache.get(id);
            if (entity) {
                this._cache.delete(id);
                entity.dispose();
            }
        }
        if (this._clearRelationDictionary.has(id)) {
            const re = this._clearRelationDictionary.get(id);
            if (re) {
                re.timer.dispose();
            }
            this._clearRelationDictionary.delete(id);
        }
    }

    public containsEntity(id: IGuid): boolean {
        return this._cache.has(id);
    }

    public async getEntityAsync<T extends Entity & U, U extends IEntity>(classType: new () => T, id: IGuid, addToFilter = true): Promise<U | null> {
        const lst = SafeGuid.createSet();
        lst.add(id);
        const entities = await this.getEntitiesAsync<T, U>(classType, lst, addToFilter);
        if (entities.length > 0) {
            return entities[0] as unknown as U;
        }
        return null;
    }

    public async addEntitiesAsync<U extends IEntity>(entitiesToAdd: Array<U>, addToFilter = true) {
        for (const nonCachedEntity of entitiesToAdd) {
            if (!this._cache.has(nonCachedEntity.id)) {
                this._cache.set(nonCachedEntity.id, new CachedEntity(nonCachedEntity));
            }
        }

        if (addToFilter && entitiesToAdd.length > 0) {
            const filter = new EventFilter();
            for (const nonCachedEntity of entitiesToAdd) {
                // start monitoring that entity
                filter.entities.add(nonCachedEntity.id);
            }
            const filterId = await this._client.addToStreamerFilterAsync(filter);
            this._registeredFilter.add(filterId);
        }
    }

    public async getEntitiesAsync<T extends Entity & U, U extends IEntity>(classType: new () => T, ids: Set<IGuid>, addToFilter = true): Promise<Array<U>> {
        const entities = new Array<U>();
        const release = await this.getEntityMutex.acquire();
        try {
            const nonCachedEntityIdsQuery = new EntityQuery();
            for (const field of this._defaultDownloadMask) {
                nonCachedEntityIdsQuery.fields.add(field);
            }

            for (const id of ids) {
                if (this._cache.has(id)) {
                    const cacheEntity = this._cache.get(id);
                    if (cacheEntity) {
                        const castedEntity = cacheEntity.entity as T;
                        if (castedEntity) {
                            entities.push(castedEntity as unknown as U);
                        } else {
                            const newT = new classType();
                            newT.loadFrom(cacheEntity.entity);
                            entities.push(newT as unknown as U);
                        }
                    }
                } else {
                    nonCachedEntityIdsQuery.guids.add(id);
                }
            }

            if (nonCachedEntityIdsQuery.guids.size > 0) {
                const monitor = EntityCacheTaskMonitor.get(this._client);
                const nonCachedEntities = await monitor.getEntitiesAsync<T, U>(this.uniqueId, classType, nonCachedEntityIdsQuery);
                await this.addEntitiesAsync(nonCachedEntities, addToFilter);
                if (nonCachedEntities != null) {
                    for (const nonCachedEntity of nonCachedEntities) {
                        entities.push(nonCachedEntity);
                    }
                }
            }
        } finally {
            release();
        }
        return entities;
    }

    public getAllCachedEntities(): Array<IEntity> {
        const result = new Array<IEntity>();
        for (const cachedEntity of this._cache.values()) {
            result.push(cachedEntity.entity);
        }
        return result;
    }

    /// <summary>
    /// After every "timespan" the entity in the cache are forced to rehresh ( full or relations only ).
    /// Should only be used on entity for "readonly" ( aka modifying a relation will have random result since they are
    /// going to be reseted in the background )
    /// </summary>
    public async setEntityCacheLifetimeAsync(id: IGuid, ts: number, addToFilter: boolean, clearRelationOnly: boolean) {
        const lst = SafeGuid.createSet();
        lst.add(id);
        return await this.setEntitiesCacheLifetimeAsync(lst, ts, addToFilter, clearRelationOnly);
    }

    /// <summary>
    /// After every "timespan" the entities in the cache are forced to rehresh ( full or relations only ).
    /// Should only be used on entity for "readonly" ( aka modifying a relation will have random result since they are
    /// going to be reseted in the background )
    /// </summary>
    public async setEntitiesCacheLifetimeAsync(ids: Set<IGuid>, ts: number, addToFilter: boolean, clearRelationOnly: boolean) {
        const nonCachedEntities = SafeGuid.createSet();
        for (const id of ids) {
            if (this._cache.has(id) === false) {
                nonCachedEntities.add(id);
            }
        }

        const entities = await this.getEntitiesAsync(Entity, nonCachedEntities, addToFilter); // force a cache update
        for (const entity of entities) {
            if (this._clearRelationDictionary.has(entity.id)) {
                const re = this._clearRelationDictionary.get(entity.id);
                if (re) {
                    re.timer.dispose();
                    this._clearRelationDictionary.delete(entity.id);
                }
            }

            const timer = new Timer(async () => {
                await this.clearRelationsAsync(entity.id);
            });
            timer.period(ts);
            this._clearRelationDictionary.set(entity.id, new RefreshEntity(entity.id, clearRelationOnly, timer));
        }
    }

    public async reEvaluateCachedEntitiesAsync(): Promise<void> {
        // First find all the cached entities that has a "detector" configured, and force and invalidate on them
        const entitiesToRefresh = mapFilter(this._cache, (x) => x[1].modificationHandlers.size !== 0);

        // Verify if entity were deleted
        const entitiesToVerify = mapFilter(this._cache, (x) => x[1].deleteHandlers.size !== 0);

        for (const cachedEntity of entitiesToRefresh) {
            let allFlags = 0;
            if (this._invalidatedEntities.has(cachedEntity[0]) === false) {
                cachedEntity[1].modificationHandlers.forEach((x) => (allFlags |= x.validFlags));
                this._invalidatedEntities.set(cachedEntity[0], allFlags);
            } else {
                let newFlags = this._invalidatedEntities.get(cachedEntity[0]);
                if (newFlags) {
                    newFlags |= allFlags;
                } else {
                    newFlags = allFlags;
                }
                this._invalidatedEntities.set(cachedEntity[0], newFlags);
            }
        }
        this._debouncerInvalidate.trigger();

        // Deleted entities
        if (entitiesToVerify.length > 0) {
            const query = new EntityQuery();
            entitiesToVerify.forEach((x) => query.guids.add(x[0]));
            const monitor = EntityCacheTaskMonitor.get(this._client);
            const queryResult = await monitor.getEntitiesAsync(this.uniqueId, Entity, query);
            for (const entity of entitiesToVerify) {
                if (!queryResult.find((x) => x.id.toString() === entity[0].toString())) {
                    // oups, it's deleted!
                    await this.onEntityRemoved(new Args.EntityRemovedArg(entity[0], entity[1].entity.entityType));
                }
            }
        }
    }

    public async detectChangeAsync(id: IGuid, validFlags: number, toBeCalledOnModify?: modificationHandlerAction, comparer?: modificationHandlerComparer): Promise<string> {
        if (this._cache.has(id) === false) {
            await this.getEntityAsync(Entity, id); // force a cache update
        }

        const cacheEntity = this._cache.get(id);
        if (cacheEntity) {
            const handler = new ModificationHandler();
            handler.validFlags = validFlags;
            if (comparer) {
                handler.comparer = comparer;
            }
            if (toBeCalledOnModify) {
                handler.action = toBeCalledOnModify;
            }

            cacheEntity.modificationHandlers.add(handler);
            if (comparer) {
                await comparer(cacheEntity.entity, cacheEntity.entity);
            }
            if (handler) {
                return handler.id;
            }
        }
        throw new Error('Entity cache element not found');
    }

    public async detectEntityRemovedAsync(id: IGuid, toBeCalledOnDelete: modificationHandlerDelete) {
        if (this._cache.has(id) === false) {
            await this.getEntityAsync(Entity, id); // force a cache update
        }

        const cacheEntity = this._cache.get(id);
        if (cacheEntity) {
            cacheEntity.deleteHandlers.add(toBeCalledOnDelete);
            return;
        }
        throw new Error('Entity cache element not found');
    }

    public detectEntityChange<TEntity extends IEntity>(entity: TEntity, ...fields: (keyof TEntity)[]): Observable<TEntity> {
        const createObservable = (field: keyof TEntity) =>
            new Observable<TEntity>((obs) => {
                const expr = () => entity[field];
                void this.detectFieldChangeAsync(entity, expr, (newEntity) => {
                    obs.next(newEntity as TEntity);
                    return Promise.resolve();
                });
            });

        // Each time one of the fields change, it will emit the new entity (with all the monitored fields updated).
        return merge(...fields.map((field) => createObservable(field)));
    }

    public async detectFieldChangeAsync<T>(
        entity: IEntity,
        expr: (...args: any[]) => void,
        toBeCalledOnModify?: modificationHandlerField<T>,
        comparer?: modificationHandlerComparer,
    ): Promise<string> {
        entity.lastFieldValidFlags = -1;
        expr();
        if (entity.lastFieldValidFlags === -1) {
            return new Deferred<string>(`Cannot detect field change because no ValidFlag is configured \r\n ${expr.toString()}`, false).promise;
        }
        const lastFieldName = entity.lastFieldName;
        const lastValidFlags = entity.lastFieldValidFlags;
        const lastFieldType = entity.lastFieldType;

        if (!comparer) {
            comparer = (newEntity: IEntity, orignalEntity: IEntity) => {
                if (lastFieldType === 'Date') {
                    const originalValue = orignalEntity.getFieldDate(lastFieldName);
                    const newValue = newEntity.getFieldDate(lastFieldName);
                    const comparedReturn = originalValue === newValue;
                    return new Deferred<boolean>(comparedReturn).promise;
                } else if (lastFieldType === 'Guid') {
                    const originalValue = orignalEntity.getFieldGuid(lastFieldName);
                    const newValue = newEntity.getFieldGuid(lastFieldName);
                    const comparedReturn = originalValue.equals(newValue);
                    return new Deferred<boolean>(comparedReturn).promise;
                } else {
                    const originalValue = orignalEntity.getField<T>(lastFieldName);
                    const newValue = newEntity.getField<T>(lastFieldName);
                    // tslint:disable-next-line: strict-comparisons
                    const comparedReturn = originalValue === newValue;
                    return new Deferred<boolean>(comparedReturn).promise;
                }
            };
        }

        const monitor = EntityCacheTaskMonitor.get(this._client);
        monitor.addMonitoredEntityField(this.uniqueId, entity, entity.lastFieldName, entity.lastFieldValidFlags);
        if (toBeCalledOnModify) {
            return await this.detectChangeAsync(
                entity.id,
                lastValidFlags,
                (newEntity, oldEntity) => toBeCalledOnModify(newEntity, newEntity.getField<T>(lastFieldName), oldEntity.getField<T>(lastFieldName)),
                comparer,
            );
        }

        // Doesn't need a callback, just make sure we are sniffing this invalidate
        return await this.setupInvalidateSnifferAsync(entity.id, lastValidFlags, comparer);
    }

    public async detectRelationChangeAsync<T extends IEntity = IEntity>(
        entity: IEntity,
        expr: (...args: any[]) => void,
        toBeCalledOnModify?: modificationHandlerAction<T>,
        comparer?: modificationHandlerComparer,
    ): Promise<string> {
        entity.lastRelationValidFlags = -1;
        expr();
        if (entity.lastRelationValidFlags === -1) {
            return new Deferred<string>(`Cannot detect relation change because no ValidFlag is configured \r\n ${expr.toString()}`, false).promise;
        }

        const monitor = EntityCacheTaskMonitor.get(this._client);
        monitor.addMonitoredEntityRelation(this.uniqueId, entity, entity.lastRelationId, entity.lastRelationValidFlags);
        if (toBeCalledOnModify) {
            return await this.detectChangeAsync(entity.id, entity.lastRelationValidFlags, toBeCalledOnModify as modificationHandlerAction<IEntity>, comparer);
        }
        // Doesn't need a callback, just make sure we are sniffing this invalidate
        return await this.setupInvalidateSnifferAsync(entity.id, entity.lastRelationValidFlags, comparer);
    }

    public clearAllDetection(id: IGuid) {
        const cacheEntity = this._cache.get(id);
        if (cacheEntity) {
            cacheEntity.modificationHandlers.clear();
        }
    }

    public async detectEntityEventAsync(id: IGuid, eventType: string, toBeCalledOnEvent: modificationHandlerEvent) {
        if (this._cache.has(id) === false) {
            await this.getEntityAsync(Entity, id); // force a cache update
        }
        const cacheEntity = this._cache.get(id);
        if (cacheEntity) {
            cacheEntity.eventHandlers.set(eventType, toBeCalledOnEvent);
            const filter = new EventFilter();
            filter.eventTypes.add(eventType);
            const filterId = await this._client.addToStreamerFilterAsync(filter);
            this._registeredFilter.add(filterId);
            return;
        }
        throw new Error('Entity cache element not found');
    }

    public async updateAsync(entity?: IEntity | null, entities?: Set<IEntity> | null, transaction?: ITransaction | null): Promise<IRestResponse> {
        if (!entities) {
            entities = new Set<IEntity>();
        }

        if (entity) {
            entities.add(entity);
        }

        for (const updatedEntity of entities) {
            const cachedEntity = this._cache.get(updatedEntity.id);
            if (cachedEntity) {
                cachedEntity.isDirty = updatedEntity.isDirty;
            }
        }

        return await this._client.updateAsync(entity, entities, transaction);
    }

    // #endregion

    // #region Private method

    private async setupInvalidateSnifferAsync(id: IGuid, validFlags: number, comparer?: modificationHandlerComparer): Promise<string> {
        if (this._cache.has(id) === false) {
            await this.getEntityAsync(Entity, id); // force a cache update
        }

        let setupDetector = false;
        const cacheEntity = this._cache.get(id);
        if (cacheEntity) {
            if (cacheEntity.modificationHandlers.any((x) => x.validFlags === validFlags) === false) {
                setupDetector = true;
            }
        } else {
            throw new Error('Entity cache element not found');
        }

        if (setupDetector) {
            return await this.detectChangeAsync(id, validFlags, () => new Deferred<void>(true).promise, comparer);
        } else {
            return '';
        }
    }

    // #endregion

    // #region callbacks

    private async clearRelationsAsync(id: IGuid) {
        try {
            const re = this._clearRelationDictionary.get(id);
            if (re) {
                const ce = this._cache.get(id);
                if (ce) {
                    await ce.entity.reloadAsync(re.clearRelationOnly);
                }
            }
        } catch (e) {}
    }

    private async performCacheUpdateAsync(): Promise<void> {
        const toNotify = new Map<string, [ModificationHandler, IEntity, IEntity]>();
        const ids = SafeGuid.createMap<number>(this._invalidatedEntities.entries());
        this._invalidatedEntities.clear();

        if (ids.size === 0) {
            return;
        }

        try {
            // Download new one
            const query = new EntityQuery();
            query.guids = SafeGuid.createSet(ids.keys());

            for (const field of this._defaultDownloadMask) {
                query.fields.add(field);
            }
            const monitor = EntityCacheTaskMonitor.get(this._client);
            const entities = await monitor.getEntitiesAsync(this.uniqueId, Entity, query);

            // use the comparer
            for (let i = 0; i < entities.length; ++i) {
                if (this._cache.has(entities[i].id)) {
                    const cachedElement = this._cache.get(entities[i].id);
                    if (!cachedElement) {
                        throw new Error('cachedElement is null');
                    }
                    // Copy old entity info
                    const isCachedEntityDirty = cachedElement.isDirty;
                    const oldEntity = cachedElement.entity;

                    // update it in the cache dict
                    const newEntity = entities[i];
                    cachedElement.entity = newEntity;

                    for (const handler of cachedElement.modificationHandlers) {
                        if (!handler.action) {
                            continue;
                        }

                        const invalidatedFlags = ids.get(newEntity.id);
                        if (!invalidatedFlags) {
                            throw new Error('invalidatedFlags is null');
                        }
                        if ((handler.validFlags & invalidatedFlags) !== 0) {
                            let needToNotify = true;
                            if (handler.comparer) {
                                needToNotify = isCachedEntityDirty || !(await handler.comparer(newEntity, oldEntity));
                            }
                            if (needToNotify) {
                                if (toNotify.has(handler.id) === false) {
                                    // prevent same handler being called multiple time
                                    toNotify.set(handler.id, [handler, newEntity, oldEntity]);
                                }
                            }
                        }
                    }
                }
            }
        } catch (e) {
            // Console.WriteLine(ex.ToString());
        } finally {
        }

        // Fire the notification
        for (const [, tuple] of toNotify) {
            try {
                const handler = tuple[0];
                if (!handler || !handler.action) {
                    throw new Error('null tuple!');
                }
                await handler.action(tuple[1], tuple[2]);
            } catch (e) {
                // the cache doesn't care if your task failed!
            }
        }
    }

    private onLogonStateChanged(e: LogonStateChangedArgs): Promise<void> {
        return new Promise<void>(async (resolve) => {
            try {
                if (e.loggedOn() === true) {
                    await this.reEvaluateCachedEntitiesAsync();
                }
            } catch (e) {}
            resolve(); // no matter, just complete it
        });
    }

    private async onEntityRemoved(e: Args.EntityRemovedArg) {
        try {
            const removed = new Set<CachedEntity>();

            if (this._cache.has(e.id)) {
                const foundElem = this._cache.get(e.id);
                if (foundElem) {
                    removed.add(foundElem);
                    this._cache.delete(e.id);
                }
            }

            for (const cachedElement of removed) {
                for (const handler of cachedElement.deleteHandlers) {
                    try {
                        await handler(e.id);
                    } catch (e) {
                        // ignore and continue
                    }
                }
            }
        } catch (e) {
            // _client?.DiagnosticManager?.SendTraceAsync(LogSeverity.Warning, nameof(EntityCacheTask), "OnEntityRemoved", ex).FireAndForget();
        }
    }

    private onEntityInvalidated(e: Args.EntityInvalidatedArg) {
        try {
            if (this._cache.has(e.id)) {
                const cachedElement = this._cache.get(e.id);
                if (cachedElement) {
                    for (const ele of cachedElement.modificationHandlers) {
                        if ((e.flags & ele.validFlags) !== 0) {
                            const monitor = EntityCacheTaskMonitor.get(this._client);
                            monitor.onEntityInvalidated(this.uniqueId, e);

                            if (this._invalidatedEntities.has(e.id) === false) {
                                this._invalidatedEntities.set(e.id, e.flags);
                                break;
                            } else {
                                let fl = this._invalidatedEntities.get(e.id);
                                if (fl) {
                                    fl |= e.flags;
                                } else {
                                    fl = e.flags;
                                }
                                this._invalidatedEntities.set(e.id, fl);
                            }
                        }
                    }
                    this._debouncerInvalidate.trigger();
                }
            }
        } catch (e) {}
    }

    private async onEventReceived(e: Args.EventReceivedArg) {
        try {
            if (e.event.hasField('sourceEntity')) {
                if (this._cache.has(e.event.sourceEntity)) {
                    const cachedElement = this._cache.get(e.event.sourceEntity);
                    if (cachedElement) {
                        for (const handler of cachedElement.eventHandlers) {
                            try {
                                if (handler[0] === e.event.eventType) {
                                    await handler[1](e.event);
                                }
                            } catch (e) {
                                // ignore and continue
                            }
                        }
                    }
                }
            }
        } catch (e) {
            // _client?.DiagnosticManager?.SendTraceAsync(LogSeverity.Warning, nameof(EntityCacheTask), "OnEntityRemoved", ex).FireAndForget();
        }
    }

    // #endregion
}
