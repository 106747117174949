<ng-container>
    <ng-container *ngIf="isLoading">
        <div class="tile-backdrop"></div>
        <gen-spinner class="spinner" [size]="SpinnerSize.Large"></gen-spinner>
    </ng-container>
    <div
        class="gen-grid {{ gridClass }} h-100 gg-1"
        [style.grid-template-columns]="gridTemplateColumns"
        [style.grid-template-rows]="gridTemplateRows"
        tabindex="0"
        (contextmenu)="onContextMenuRequested($event)"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
        (dragleave)="onDragLeave($event)"
        genKeyboardNavigation
        [genDirectionalNavigation]="true"
        genFindElementQuery="app-tile"
        [genRowCount]="rowCount"
        [genColumnCount]="colCount"
    >
        <app-tile
            *ngFor="let tile of tiles"
            class="tile h-100 w-100 p-relative"
            [class.fullscreen]="tile.isFullscreen"
            [class.has-active-threat-level]="activeThreatLevelColor$ | async"
            appCaptureEvents
            [style.expanded]="tile.isExpanded"
            [style.grid-column-start]="tile.boundary.startColumn"
            [style.grid-column-end]="tile.boundary.endColumn"
            [style.grid-row-start]="tile.boundary.startRow"
            [style.grid-row-end]="tile.boundary.endRow"
            [style.--border-color-override]="activeThreatLevelColor$ | async | argb"
            [canDrag]="!!tile.content"
            [state]="tile"
            [isFullscreen]="tile.isFullscreen"
            [isSelected]="tile.isSelected"
            (previewClick)="onTilePreviewClick(tile)"
            (contextmenu)="onTileContextMenu(tile, $event)"
            (dblclick)="onTileDoubleClick(tile)"
            (dragstart)="onTileDragStart(tile, $event)"
            (dragend)="onTileDragEnd(tile, $event)"
            (dragover)="onTileDragOver(tile, $event)"
            (drop)="onTileDrop(tile, $event)"
            (dragleave)="onTileDragLeave(tile, $event)"
            (focus)="onTileSelected(tile)"
            tabindex="0"
        ></app-tile>
    </div>
</ng-container>
