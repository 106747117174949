import { Resolution } from '../../utils/Resolution';
import { Vec2 } from '../../utils/Vec2';

export class Uniformf {
  protected readonly m_gl: WebGL2RenderingContext;
  protected readonly m_program: WebGLProgram;
  protected readonly m_uniformLocation: WebGLUniformLocation;

  constructor(gl: WebGL2RenderingContext, program: WebGLProgram, uniformLocation: WebGLUniformLocation) {
    this.m_gl = gl;
    this.m_program = program;
    this.m_uniformLocation = uniformLocation;
  }

  protected getUniform(): Float32Array {
    return this.m_gl.getUniform(this.m_program, this.m_uniformLocation);
  }
}

export class Uniform1f extends Uniformf {
  public toString(): string {
    return this.get().toFixed(3);
  }

  public set(value: number): void {
    this.m_gl.uniform1f(this.m_uniformLocation, value);
  }

  public get(): number {
    return this.m_gl.getUniform(this.m_program, this.m_uniformLocation);
  }
}

export class Uniform2f extends Uniformf {
  public toString(): string {
    return `${this.getX()}, ${this.getY()}`;
  }

  public set(x: number, y: number) {
    this.m_gl.uniform2f(this.m_uniformLocation, x, y);
  }

  public setFromResolution(resolution: Resolution) {
    this.set(resolution.Width, resolution.Height);
  }

  public setFromVec2(vec2: Vec2) {
    this.set(vec2.X, vec2.Y);
  }

  public get(): [number, number] {
    const uniformValue: Float32Array = this.getUniform();
    return [uniformValue[0], uniformValue[1]];
  }

  public getX(): number {
    return this.get()[0];
  }

  public getY(): number {
    return this.get()[1];
  }
}

export class Uniform3f extends Uniformf {
  public toString(): string {
    const uniformValue: Float32Array = this.getUniform();
    return `${uniformValue[0]}, ${uniformValue[1]}, ${uniformValue[2]}`;
  }

  public get(): [number, number, number] {
    const uniformValue: Float32Array = this.getUniform();
    return [uniformValue[0], uniformValue[1], uniformValue[2]];
  }

  public set(x: number, y: number, z: number) {
    this.m_gl.uniform3f(this.m_uniformLocation, x, y, z);
  }
}

export class Uniform4f extends Uniformf {
  public toString(): string {
    const uniformValue: Float32Array = this.getUniform();
    return `${uniformValue[0]}, ${uniformValue[1]}, ${uniformValue[2]}, ${uniformValue[3]}`;
  }

  public get(): [number, number, number, number] {
    const uniformValue: Float32Array = this.getUniform();
    return [uniformValue[0], uniformValue[1], uniformValue[2], uniformValue[3]];
  }

  public set(x: number, y: number, z: number, w: number) {
    this.m_gl.uniform4f(this.m_uniformLocation, x, y, z, w);
  }
}
