/* eslint-disable no-underscore-dangle */
import { ChangeDetectorRef } from '@angular/core';
import { Flavor, GenToastService, MeltedCheckboxState } from '@genetec/gelato-angular';
import { EntityBrowserItemService } from '@modules/shared/entity-browser/entity-browser-item.service';
import { EntityBrowserService } from '@modules/shared/entity-browser/entity-browser.service';
import {
    EntityBrowserResult,
    HierarchicalEntityBrowserResult,
    IEntityBrowserResult,
    IHierarchicalEntityBrowserResult,
} from '@modules/shared/entity-browser/interfaces/entity-browser-result';
import { IEntityBrowserFilter } from '@modules/shared/entity-browser/interfaces/filters/entity-browser-filter.interface';
import { IEntityBrowserItemModel } from '@modules/shared/entity-browser/interfaces/items/entity-browser-item-model.interface';
import { EntityBrowserItemModel } from '@modules/shared/entity-browser/Items/entity-browser-item-model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { GuidMap, GuidSet, IGuid, SafeGuid } from 'safeguid';
import { BrowserItem } from '../items/browser-item';
import { EntityBrowserItem } from '../items/entity-browser-item';
import { ShowMoreBrowserItem } from '../items/show-more-browser-item';

export class EntityBrowserItemsSource {
    public allowMultiSelect = true;
    public checkedItemsChanged$!: Observable<void>;
    public readonly items = new GuidMap<Set<EntityBrowserItem>>();
    public rootItems: BrowserItem[] = [];
    public selectedItemsChanged$!: Observable<void>;
    public selectedItems: EntityBrowserItem[] = [];

    public set canSelectItemFunction(value: (model: EntityBrowserItemModel) => Promise<boolean>) {
        this._canSelectItemFunction = value;
    }

    public get checkedEntityIds(): ReadonlySet<IGuid> {
        return this._checkedEntityIds;
    }

    public set createItemFunction(value: (model: EntityBrowserItemModel, entityBrowserItemService: EntityBrowserItemService) => EntityBrowserItem) {
        this._createItemFunction = value;
    }

    public get excludedCheckedEntityIds(): ReadonlySet<IGuid> {
        return this._excludedCheckedEntityIds;
    }

    public get isHierarchical(): boolean {
        return this._isHierarchical;
    }

    public lastSearchInstanceId = SafeGuid.EMPTY;

    private _canSelectItemFunction?: (model: EntityBrowserItemModel) => Promise<boolean>;
    private _checkedEntityIds = new GuidSet();
    private _createItemFunction?: (model: EntityBrowserItemModel, entityBrowserItemService: EntityBrowserItemService) => EntityBrowserItem;
    private _excludedCheckedEntityIds = new GuidSet();
    private _isHierarchical = false;
    private areSourceEntitiesSet = false;
    private checkedItemsChangedSubject = new Subject<void>();
    private entityBrowserFilter: IEntityBrowserFilter | undefined;
    private firstItemOfSelection: EntityBrowserItem | null = null;
    private itemEventUnsubscribes = new Map<string, (() => void)[]>();
    private lastItemOfSelection: EntityBrowserItem | null = null;
    private selectedItemsChangedSubject = new Subject<void>();

    constructor(
        private entityBrowserService: EntityBrowserService,
        private entityBrowserItemService: EntityBrowserItemService,
        private translateService: TranslateService,
        private toastService: GenToastService,
        private entityBrowserId: IGuid,
        private changeDetectorRef: ChangeDetectorRef,
        private isFiltered = false
    ) {
        this.checkedItemsChanged$ = this.checkedItemsChangedSubject.asObservable();
        this.selectedItemsChanged$ = this.selectedItemsChangedSubject.asObservable();
    }

    public addCheckedEntityIds(checkedEntityIds: Iterable<IGuid>): void {
        for (const checkedEntityId of checkedEntityIds) {
            this._checkedEntityIds.add(checkedEntityId);
        }
    }

    public addExcludedCheckedEntityIds(excludedCheckedEntityIds: Iterable<IGuid>): void {
        for (const excludedCheckedEntityId of excludedCheckedEntityIds) {
            this._excludedCheckedEntityIds.add(excludedCheckedEntityId);
        }
    }

    public checkAll(): void {
        for (const item of this.rootItems) {
            if (item instanceof EntityBrowserItem) {
                this.setItemIsChecked(item, true);
                this._checkedEntityIds.add(item.entityId);
            }
        }
        this.onCheckedItemsChanged();
    }

    public clear(clearSelection = false): void {
        this.items.clear();
        this.rootItems = [];

        if (clearSelection) {
            this.clearSelection();
        }
        for (const unsubscribes of this.itemEventUnsubscribes.values()) {
            this.unsubscribeFromItemEvents(unsubscribes);
        }
        this.itemEventUnsubscribes.clear();
    }

    public clearSelection(): void {
        this.selectedItems.length = 0;
        this._checkedEntityIds.clear();
        this._excludedCheckedEntityIds.clear();
    }

    public dispose(): void {
        this.clear(true);

        for (const unsubscribes of this.itemEventUnsubscribes.values()) {
            this.unsubscribeFromItemEvents(unsubscribes);
        }
        this.itemEventUnsubscribes.clear();
    }

    public async expandItemAsync(item: EntityBrowserItem): Promise<void> {
        item.isExpanding = true;
        try {
            if (this.entityBrowserFilter) {
                const result = await this.expandInternalAsync(item.entityId, this.entityBrowserFilter);
                if (result?.isValid && item.isExpanded) {
                    const children: EntityBrowserItem[] = [];
                    this.addItemsFromResult(children, result, item);
                    item.children = children;
                    if (children.length === 0) {
                        this.setItemIsExpanded(item, false);
                        item.canExpand = false;
                    }
                } else {
                    this.setItemIsExpanded(item, false);
                }
            }
        } finally {
            item.isExpanding = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    public getItemParentHierarchies(items: EntityBrowserItem[]): Set<EntityBrowserItem> {
        const parentItems = new Set<EntityBrowserItem>();
        for (const selectedItem of items) {
            this.getParentItemsRecursive(selectedItem, parentItems);
        }

        for (const checkedEntityId of this._checkedEntityIds) {
            this.getAllParentItemsRecursive(checkedEntityId, parentItems);
        }
        return parentItems;
    }

    public async refreshAsync(filter: IEntityBrowserFilter, isFullRefresh = false, searchInstanceId: IGuid = SafeGuid.EMPTY): Promise<EntityBrowserResult | null> {
        this.entityBrowserFilter = filter;
        this.areSourceEntitiesSet = filter.entityIds.size > 0;

        const result = (await this.entityBrowserService.refreshAsync(this.entityBrowserId, filter, isFullRefresh)).getOverload<EntityBrowserResult>();
        // Is a new search more recent in progress?
        if (!SafeGuid.EMPTY.equals(searchInstanceId) && !searchInstanceId.equals(this.lastSearchInstanceId)) {
            return null;
        }

        if (result.isValid) {
            this.insertResult(result);
        }
        return result;
    }

    public async selectItemAsync(item: BrowserItem, event?: MouseEvent | KeyboardEvent, ignoreEvents = false): Promise<boolean> {
        if (!(item instanceof EntityBrowserItem)) {
            return false;
        }

        if (!(await this.canSelectItemAsync(item))) {
            return false;
        }

        let keepCurrentSelection = false;
        let fillSelection = false;
        const hasExistingSelection = this.selectedItems && this.selectedItems.length > 0;
        let setFirstItemOfSelection = false;
        if (this.allowMultiSelect) {
            if (event instanceof KeyboardEvent) {
                if (event.shiftKey) {
                    fillSelection = true;
                } else if (event.ctrlKey) {
                    return false;
                } else {
                    setFirstItemOfSelection = true;
                }
            } else if (event instanceof MouseEvent) {
                setFirstItemOfSelection = true;
                if (event.ctrlKey) {
                    keepCurrentSelection = true;
                }
                if (event.shiftKey) {
                    fillSelection = true;
                    setFirstItemOfSelection = false;
                }
            }
        } else {
            setFirstItemOfSelection = true;
        }

        if (setFirstItemOfSelection) {
            this.firstItemOfSelection = item;
        }

        if (fillSelection && this.firstItemOfSelection && hasExistingSelection) {
            const newSelection: EntityBrowserItem[] = [this.firstItemOfSelection];
            const positionOfSelection = this.compareItemsPosition(this.firstItemOfSelection, item);
            if (positionOfSelection < 0) {
                let nextItem = this.getNextItem(this.firstItemOfSelection);
                while (nextItem && nextItem !== item) {
                    newSelection.push(nextItem);
                    nextItem = this.getNextItem(nextItem);
                }
                newSelection.push(item);
            } else if (positionOfSelection > 0) {
                let previousItem = this.getPreviousItem(this.firstItemOfSelection);
                while (previousItem && previousItem !== item) {
                    newSelection.push(previousItem);
                    previousItem = this.getPreviousItem(previousItem);
                }
                newSelection.push(item);
            }
            await this.setSelectedItems(newSelection, ignoreEvents);
            this.lastItemOfSelection = newSelection[newSelection.length - 1];
        } else if (keepCurrentSelection && hasExistingSelection) {
            const newSelection = Array.from(this.selectedItems);
            const itemIndex = newSelection.indexOf(item);
            if (itemIndex > -1) {
                newSelection.splice(itemIndex, 1);
            } else {
                newSelection.push(item);
            }
            await this.setSelectedItems(newSelection, ignoreEvents);
            this.lastItemOfSelection = item;
        } else {
            await this.setSelectedItems(item, ignoreEvents);
            this.lastItemOfSelection = item;
        }

        return true;
    }

    public async selectNextItemAsync(item: BrowserItem, event?: KeyboardEvent): Promise<BrowserItem | undefined> {
        let itemToSelect: EntityBrowserItem | undefined;
        const startItem = this.lastItemOfSelection ?? item;

        itemToSelect = this.getNextItem(startItem);
        while (itemToSelect && !(await this.selectItemAsync(itemToSelect, event))) {
            itemToSelect = this.getNextItem(itemToSelect);
        }

        return itemToSelect;
    }

    public async selectPreviousItemAsync(item: BrowserItem, event?: KeyboardEvent): Promise<BrowserItem | undefined> {
        let itemToSelect: EntityBrowserItem | undefined;
        const startItem = this.lastItemOfSelection ?? item;

        itemToSelect = this.getPreviousItem(startItem);
        while (itemToSelect && !(await this.selectItemAsync(itemToSelect, event))) {
            itemToSelect = this.getPreviousItem(itemToSelect);
        }

        return itemToSelect;
    }

    public async selectScrollDownItemAsync(item: BrowserItem, event: KeyboardEvent, minTop: number, maxBottom: number): Promise<BrowserItem | undefined> {
        return await this.selectScrollingItemAsync(this.lastItemOfSelection ?? item, true, event, minTop, maxBottom);
    }

    public async selectScrollTopItemAsync(item: BrowserItem, event: KeyboardEvent, minTop: number, maxBottom: number): Promise<BrowserItem | undefined> {
        return await this.selectScrollingItemAsync(this.lastItemOfSelection ?? item, false, event, minTop, maxBottom);
    }

    public setItemIsExpanded(item: BrowserItem, isExpanded: boolean): void {
        if (!item.canExpand) {
            return;
        }

        const wasItemExpanded = item.isExpanded;
        item.isExpanded = isExpanded;

        if (!isExpanded && wasItemExpanded) {
            if (item.children) {
                item.children.forEach((child) => {
                    child.isExpanded = false;
                    this.setItemIsExpanded(child, isExpanded);
                });
            }
        }
    }

    public async setSelectedItems(items: EntityBrowserItem[] | EntityBrowserItem | null = null, ignoreEvent = false): Promise<void> {
        const newSelection: EntityBrowserItem[] = [];
        if (items) {
            const entityBrowserItems = items instanceof EntityBrowserItem ? [items] : items;
            for (const item of entityBrowserItems) {
                if (await this.canSelectItemAsync(item)) {
                    // prevent duplicates
                    const existing = newSelection.find((x) => x.id === item.id);
                    if (!existing) {
                        newSelection.push(item);
                    }
                }
            }
        }

        this.selectedItems = newSelection;
        if (!ignoreEvent) {
            this.selectedItemsChangedSubject.next();
        }
    }

    public async showMoreItems(item: ShowMoreBrowserItem): Promise<void> {
        item.isQuerying = true;

        if (this.entityBrowserFilter) {
            const filter = this.entityBrowserService.cloneFilter(this.entityBrowserFilter);
            filter.pageInfo.page = item.page;
            filter.pageInfo.lastEntityId = item.lastEntityId;

            const parent = item.parent;
            if (!parent) {
                const result = await this.getResultAsync(filter);
                if (result?.isValid) {
                    this.rootItems.splice(this.rootItems.indexOf(item), 1);
                    this.insertResult(result);
                } else {
                    this.toastService.show({ text: this.translateService.instant('STE_MESSAGE_ERROR_FAILEDTOLOADMOREITEMS') as string, flavor: Flavor.Toast.Error });
                }
            } else if (parent && parent instanceof EntityBrowserItem && parent.children) {
                const result = await this.expandInternalAsync(parent.entityId, filter);
                if (result?.isValid) {
                    const index = parent.children.indexOf(item);
                    if (index >= 0) {
                        parent.children.splice(index, 1);
                    }
                    this.addItemsFromResult(parent.children, result, parent);
                }
            }
        }

        item.isQuerying = false;
        this.changeDetectorRef.markForCheck();
    }

    public toggleItemsCheckbox(items: EntityBrowserItem[], keyboardEventItem?: EntityBrowserItem): void {
        const itemsToCheck = items.slice();
        if (this._isHierarchical) {
            const parentItemIds = new GuidSet(items.filter((itemToCheck) => itemToCheck.isExpanded).map((parent) => parent.entityId));
            for (const itemToCheck of items) {
                const itemParentIds = new GuidSet();
                this.getAllParentIdsRecursive(itemToCheck.entityId, itemParentIds);
                for (const parentId of parentItemIds) {
                    if (itemParentIds.has(parentId)) {
                        itemsToCheck.remove(itemToCheck);
                        break;
                    }
                }
            }
        }

        const itemUsedForIsCheckedValue = keyboardEventItem ?? itemsToCheck[0];
        const isCheckedValue = !itemUsedForIsCheckedValue.isChecked || itemUsedForIsCheckedValue.isPartialChecked === true;
        for (const itemToCheck of itemsToCheck) {
            this.updateEntityIsChecked(itemToCheck.entityId, isCheckedValue, true);
        }

        this.onCheckedItemsChanged();
    }

    public uncheckAll(): void {
        if (this._isHierarchical) {
            for (const rootItem of this.rootItems) {
                this.updateEntityIsChecked((rootItem as EntityBrowserItem).entityId, false);
            }
        } else {
            for (const entityId of this._checkedEntityIds) {
                const items = this.items.get(entityId);
                if (items) {
                    for (const item of items.values()) {
                        this.setItemIsChecked(item, false);
                    }
                }
            }
        }

        this._checkedEntityIds.clear();
        this._excludedCheckedEntityIds.clear();

        this.onCheckedItemsChanged();
    }

    public updateEntityIsChecked(id: IGuid, isChecked: boolean, updateParents = false): void {
        const items = this.items.get(id);
        this.updateItemsIsChecked(items, isChecked, updateParents);
    }

    private canSelectEntityType(item: EntityBrowserItem): boolean {
        if (!this.entityBrowserFilter?.selectableEntityTypes || !this.entityBrowserFilter.selectableEntityTypes.size) {
            return true;
        }
        return this.entityBrowserFilter.selectableEntityTypes.has(item.model.entityType.type);
    }

    private addChildren(items: ReadonlyArray<BrowserItem>, result: IHierarchicalEntityBrowserResult): void {
        for (const [id, subResult] of result.subResults) {
            const parent = items.find((x) => x instanceof EntityBrowserItem && x.entityId.equals(id));
            if (parent) {
                if (!parent.children) {
                    parent.children = [];
                }
                parent.isExpanded = true;
                this.addItemsFromResult(parent.children, subResult, parent);

                this.addChildren(parent.children, subResult);
            }
        }
    }

    private addItem(item: EntityBrowserItem, parentItem?: BrowserItem) {
        const id = item.entityId;
        let existingItems = this.items.get(id);

        if (existingItems) {
            const firstItem = existingItems.values().next().value as EntityBrowserItem;
            if (firstItem) {
                this.setItemIsChecked(item, firstItem.isChecked, firstItem.isPartialChecked);
            }
        } else {
            existingItems = new Set<EntityBrowserItem>();
            this.items.set(id, existingItems);

            if (parentItem?.isChecked === true) {
                this.setItemIsChecked(item, true);
            }
        }

        if (this._checkedEntityIds.has(item.entityId)) {
            this.updateItemIsChecked(item, true, true);
        } else if (this.excludedCheckedEntityIds.has(item.entityId)) {
            this.updateItemIsChecked(item, false, true);
        }

        existingItems.add(item);

        let itemUnsubscribes = this.itemEventUnsubscribes.get(item.id);
        if (!itemUnsubscribes) {
            itemUnsubscribes = [];
            this.itemEventUnsubscribes.set(item.id, itemUnsubscribes);
        }
        itemUnsubscribes.push(item.expanded.subscribe((browserItem) => this.onItemExpanded(browserItem as EntityBrowserItem)));
        itemUnsubscribes.push(item.collapsed.subscribe((browserItem) => this.onItemCollapsed(browserItem as EntityBrowserItem)));
    }

    private addItemsFromResult(array: BrowserItem[], result: IEntityBrowserResult, parent?: BrowserItem): void {
        let items: EntityBrowserItem[] = [];
        for (const model of result.entityBrowserItemModels) {
            const item = this.createBrowserItem(model, parent);
            items.push(item);
        }

        if (this.areSourceEntitiesSet) {
            items = items.sort(this.compareItems.bind(this));
        }

        const resulIncludedEntityIds = new GuidSet(result.includedEntityIds);
        for (let item of items) {
            item.isIncludedItem = resulIncludedEntityIds.has(item.entityId);
            const existingItem = array.find((x) => x instanceof EntityBrowserItem && x.entityId.equals(item.entityId)) as EntityBrowserItem;
            if (existingItem) {
                if (!existingItem.isIncludedItem || item.isIncludedItem) {
                    continue;
                }

                // replace new item with existing one, but mark as non included
                array.remove(existingItem);
                item = existingItem;
                item.isIncludedItem = false;
            }

            array.push(item);
        }

        const inlcudedItems = array.filter((x) => x instanceof EntityBrowserItem && x.isIncludedItem) as EntityBrowserItem[];
        if (inlcudedItems.length > 0) {
            for (const includedItem of inlcudedItems) {
                array.remove(includedItem);
            }

            for (const includedItem of inlcudedItems) {
                let insertIndex: number;
                for (insertIndex = array.length - 1; insertIndex >= 0; insertIndex--) {
                    const lastItem = array[insertIndex];
                    if (lastItem instanceof EntityBrowserItem) {
                        if (this.compareItems(lastItem, includedItem) < 0) {
                            break;
                        }
                    }
                }
                array.splice(insertIndex + 1, 0, includedItem);
            }
        }

        if (!result.isResultComplete) {
            this.tryAddShowMoreButton(result, array, parent);
        }
    }

    private tryAddShowMoreButton(result: IEntityBrowserResult, array: BrowserItem[], parent?: BrowserItem): void {
        if (!array.some((item) => 'isShowMore' in item)) {
            const showMoreItem = new ShowMoreBrowserItem(result);
            if (parent) {
                showMoreItem.parent = parent;
            }
            array.push(showMoreItem);
        }
    }

    private async canSelectItemAsync(item: BrowserItem): Promise<boolean> {
        if (!(item instanceof EntityBrowserItem) || !item.canSelect) {
            return false;
        }
        return this._canSelectItemFunction ? await this._canSelectItemFunction(item.model) : true;
    }

    private compareItems(item1: EntityBrowserItem, item2: EntityBrowserItem): number {
        return this.entityBrowserService.compareItems(item1, item2, this._isHierarchical);
    }

    // Compares the position of two items in the tree and returns:
    //   0: either item is not found or if they are the same
    //  -1: item1 is before item2
    //   1: item1 is after item2
    private compareItemsPosition(item1: BrowserItem, item2: BrowserItem): number {
        if (item1 === item2) {
            return 0;
        }

        let item1Position = -1;
        let item2Position = -1;

        if (this.isHierarchical) {
            const item1Parents: BrowserItem[] = [];
            const item2Parents: BrowserItem[] = [];

            // get all parents of both items
            const getParentsFunc = (item: BrowserItem, parents: BrowserItem[]) => {
                let parent = item.parent;
                while (parent) {
                    parents.splice(0, 0, parent);
                    parent = parent.parent;
                }
            };

            getParentsFunc(item1, item1Parents);
            getParentsFunc(item2, item2Parents);

            // Find nearest common parent
            let commonParent = null;
            let parentIndex = 0;
            while (parentIndex < item1Parents.length && parentIndex < item2Parents.length) {
                if (item1Parents[parentIndex] !== item2Parents[parentIndex]) {
                    break;
                }
                commonParent = item1Parents[parentIndex];
                parentIndex++;
            }

            // If common parent found, get the position of direct relative (either item itself or one of its parents)
            if (commonParent?.children) {
                const getPositionItem = (item: BrowserItem, itemParents: BrowserItem[]): BrowserItem => {
                    if (parentIndex < itemParents.length) {
                        return itemParents[parentIndex];
                    }
                    return item;
                };

                item1Position = commonParent.children.indexOf(getPositionItem(item1, item1Parents));
                item2Position = commonParent.children.indexOf(getPositionItem(item2, item2Parents));
            }
        } else {
            item1Position = this.rootItems.indexOf(item1);
            item2Position = this.rootItems.indexOf(item2);
        }

        if (item1Position === -1 || item2Position === -1) {
            return 0;
        } else if (item2Position > item1Position) {
            return -1;
        } else {
            return 1;
        }
    }

    private createBrowserItem(model: IEntityBrowserItemModel, parent?: BrowserItem): EntityBrowserItem {
        const castedModel = (model as EntityBrowserItemModel).getOverload<EntityBrowserItemModel>();

        let item: EntityBrowserItem;
        if (this._createItemFunction) {
            item = this._createItemFunction(castedModel, this.entityBrowserItemService);
        } else {
            item = new EntityBrowserItem(castedModel, this._isHierarchical, this.entityBrowserItemService);
        }

        item.canSelect = this.canSelectEntityType(item);

        if (parent) {
            item.parent = parent;
        }

        this.addItem(item, parent);
        return item;
    }

    private doesItemHaveCheckedParent(item: EntityBrowserItem): boolean {
        const parentIds = new GuidSet();
        this.getAllParentIdsRecursive(item.entityId, parentIds);
        for (const parentId of parentIds) {
            if (this._checkedEntityIds.has(parentId)) {
                return true;
            }
        }
        return false;
    }

    private async expandInternalAsync(entityId: IGuid, filter: IEntityBrowserFilter): Promise<EntityBrowserResult> {
        const result = await this.entityBrowserService.expandAsync(this.entityBrowserId, entityId, filter);

        if (!result.isValid) {
            this.toastService.show({ text: this.translateService.instant('STE_MESSAGE_ERROR_FAILEDTOLOADMOREITEMS') as string, flavor: Flavor.Toast.Error });
        }
        return result;
    }

    private getAllParentIdsRecursive(id: IGuid, parents: Set<IGuid>): void {
        const items = this.items.get(id);
        if (items) {
            for (const item of items) {
                if (item.parent instanceof EntityBrowserItem) {
                    const parentId = item.parent.entityId;
                    parents.add(parentId);
                    this.getAllParentIdsRecursive(parentId, parents);
                }
            }
        }
    }

    private getAllParentItemsRecursive(id: IGuid, parents: Set<EntityBrowserItem>): void {
        const items = this.items.get(id);
        if (items) {
            for (const item of items) {
                if (item.parent instanceof EntityBrowserItem) {
                    parents.add(item.parent);
                    this.getAllParentItemsRecursive(item.parent.entityId, parents);
                }
            }
        }
    }

    private getCheckboxStateFromChildren(node: EntityBrowserItem): MeltedCheckboxState {
        let checkedCount = 0;
        let indeterminateCount = 0;

        let childrenCount = 0;

        if (node.children) {
            node.children.forEach((child) => {
                if (child instanceof EntityBrowserItem) {
                    childrenCount++;
                    if (child.isChecked) {
                        checkedCount++;
                    }

                    if (child.isPartialChecked) {
                        indeterminateCount++;
                    }
                } else if (child instanceof ShowMoreBrowserItem) {
                    indeterminateCount++;
                }
            });
        }

        if (checkedCount === childrenCount && indeterminateCount === 0) {
            return this.isFiltered ? MeltedCheckboxState.Indeterminate : MeltedCheckboxState.Checked;
        } else if (indeterminateCount > 0 || checkedCount > 0) {
            return MeltedCheckboxState.Indeterminate;
        } else {
            return this.isFiltered ? MeltedCheckboxState.Indeterminate : MeltedCheckboxState.Unchecked;
        }
    }

    private getItemSiblings(item: BrowserItem) {
        if (item.parent) {
            if (item.parent.children) {
                return item.parent.children;
            }
        } else {
            return this.rootItems;
        }
        return undefined;
    }

    private getLastChild(item: BrowserItem): EntityBrowserItem | undefined {
        if (!item.isExpanded && item instanceof EntityBrowserItem) {
            return item;
        }

        const children = item.children;
        if (children && children.length > 0) {
            let lastChild: EntityBrowserItem | undefined;
            for (let index = children.length - 1; index >= 0; index--) {
                const child = children[index];
                if (child instanceof EntityBrowserItem) {
                    lastChild = child;
                    break;
                }
            }
            if (lastChild) {
                return this.getLastChild(lastChild);
            }
        }
    }

    private getNextChild(item: BrowserItem): EntityBrowserItem | undefined {
        const siblings = this.getItemSiblings(item);
        if (siblings) {
            const index = siblings.indexOf(item);
            if (index > -1 && index < siblings.length - 1) {
                const nextChild = siblings[index + 1];
                if (nextChild instanceof EntityBrowserItem) {
                    return nextChild;
                }
            }
        }

        if (item.parent) {
            return this.getNextChild(item.parent);
        }
    }

    private getNextItem(item: BrowserItem) {
        if (item.isExpanded && item.children && item.children.length > 0) {
            const nextItem = item.children[0];
            if (nextItem instanceof EntityBrowserItem) {
                return nextItem;
            }
        }
        return this.getNextChild(item);
    }

    private getPreviousItem(item: BrowserItem) {
        const siblings = this.getItemSiblings(item);
        if (siblings) {
            const index = siblings.indexOf(item);
            if (index > 0) {
                return this.getLastChild(siblings[index - 1]);
            }
            return item.parent as EntityBrowserItem;
        }
    }

    private getParentItemsRecursive(item: BrowserItem, parents: Set<EntityBrowserItem>): void {
        if (item?.parent instanceof EntityBrowserItem) {
            parents.add(item.parent);
            this.getParentItemsRecursive(item.parent, parents);
        }
    }

    private async getResultAsync(filter: IEntityBrowserFilter, isFullRefresh = false): Promise<EntityBrowserResult> {
        return (await this.entityBrowserService.refreshAsync(this.entityBrowserId, filter, isFullRefresh)).getOverload<EntityBrowserResult>();
    }

    private insertResult(result: EntityBrowserResult): void {
        this._isHierarchical = result instanceof HierarchicalEntityBrowserResult;

        this.addItemsFromResult(this.rootItems, result);

        if (result instanceof HierarchicalEntityBrowserResult) {
            this.addChildren(this.rootItems, result);
        }
    }

    private onCheckedItemsChanged(): void {
        this.checkedItemsChangedSubject.next();
    }

    private onItemCollapsed(item: EntityBrowserItem) {
        // if (!this.filter.textFilter || this.filter.textFilter === '') {
        //     this.timeouts.push(
        //         setTimeout(() => {
        //             if (!item.isExpanded) {
        //                 this.clearItemChildren(item);
        //             }
        //         }, 5000)
        //     );
        // }
    }

    private onItemExpanded(item: EntityBrowserItem) {
        this.expandItemAsync(item).fireAndForget();
    }

    private async selectScrollingItemAsync(item: BrowserItem, isDown: boolean, event: KeyboardEvent, minTop: number, maxBottom: number): Promise<BrowserItem | undefined> {
        let selectedItem: BrowserItem | undefined;
        const itemToSelect = isDown ? this.getNextItem(item) : this.getPreviousItem(item);
        if (itemToSelect) {
            const child = itemToSelect.htmlElement;
            if (child) {
                if ((isDown && child.getBoundingClientRect().bottom < maxBottom) || (!isDown && child.getBoundingClientRect().top > minTop)) {
                    selectedItem = await this.selectScrollingItemAsync(itemToSelect, isDown, event, minTop, maxBottom);
                }
            }
        }

        return selectedItem ?? (await this.selectItemAsync(item, event)) ? item : undefined;
    }

    private setItemCheckboxState(item: EntityBrowserItem, state: MeltedCheckboxState) {
        switch (state) {
            case MeltedCheckboxState.Checked:
                this.setItemIsChecked(item, true, false);
                break;
            case MeltedCheckboxState.Unchecked:
                this.setItemIsChecked(item, false, false);
                break;
            case MeltedCheckboxState.Indeterminate:
                this.setItemIsChecked(item, item.isChecked, true);
                break;
        }
    }

    private setItemIsChecked(item: EntityBrowserItem, isChecked: boolean, isPartiallyChecked?: boolean): void {
        item.isChecked = isChecked;
        if (isPartiallyChecked !== undefined) {
            item.isPartialChecked = isPartiallyChecked;
        }
    }

    private unsubscribeFromItemEvents(unsubscribes: (() => void)[] | undefined) {
        if (unsubscribes) {
            for (const unsubscribe of unsubscribes) {
                unsubscribe();
            }
        }
    }

    private updateItemChilrendIsChecked(item: EntityBrowserItem) {
        if (item.children) {
            item.children.forEach((child) => {
                if (child instanceof EntityBrowserItem) {
                    this.setItemIsChecked(child, item.isChecked, false);
                    this.updateItemChilrendIsChecked(child);
                    this._checkedEntityIds.delete(child.entityId);
                    this._excludedCheckedEntityIds.delete(child.entityId);
                }
            });
        }
    }

    private updateItemIsChecked(item: EntityBrowserItem, isChecked: boolean, updateParents = false) {
        if (isChecked) {
            this._excludedCheckedEntityIds.delete(item.entityId);
        } else {
            this._checkedEntityIds.delete(item.entityId);
        }

        const parentItem = item.parent as EntityBrowserItem;
        if (parentItem) {
            if (isChecked) {
                if (!this.doesItemHaveCheckedParent(item)) {
                    this._checkedEntityIds.add(item.entityId);
                }
            } else if (!isChecked && this.doesItemHaveCheckedParent(item)) {
                this._excludedCheckedEntityIds.add(item.entityId);
            }
        } else if (isChecked) {
            this._checkedEntityIds.add(item.entityId);
        }

        this.setItemIsChecked(item, isChecked, false);
        this.updateItemChilrendIsChecked(item);

        if (updateParents && parentItem) {
            this.updateItemParentIsChecked(item.entityId);
        }
    }

    private updateItemParentIsChecked(id: IGuid) {
        const items = this.items.get(id);
        if (items) {
            for (const item of items) {
                const parentItem = item.parent as EntityBrowserItem;
                if (parentItem) {
                    // Set the parent node checkbox state to the new state
                    const checkboxState = this.getCheckboxStateFromChildren(parentItem);
                    let checkItem = false;
                    let uncheckItem = false;
                    if (checkboxState === MeltedCheckboxState.Checked && (!parentItem.isChecked || parentItem.isPartialChecked === true)) {
                        checkItem = true;
                    } else if (checkboxState === MeltedCheckboxState.Unchecked && (parentItem.isChecked || parentItem.isPartialChecked === true)) {
                        uncheckItem = true;
                    } else if (checkboxState === MeltedCheckboxState.Indeterminate && !parentItem.isChecked) {
                        this._excludedCheckedEntityIds.delete(parentItem.entityId);
                    }
                    this.setItemCheckboxState(parentItem, checkboxState);

                    // Compute the grandfather node checkbox state
                    this.updateItemParentIsChecked(parentItem.entityId);

                    if (checkItem) {
                        this.updateItemIsChecked(parentItem, true, false);
                    } else if (uncheckItem) {
                        this.updateItemIsChecked(parentItem, false, false);
                    }
                }
            }
        }
    }

    private updateItemsIsChecked(items: Iterable<EntityBrowserItem> | undefined, isChecked: boolean, updateParents = false) {
        if (items) {
            for (const item of items) {
                this.updateItemIsChecked(item, isChecked, updateParents);
            }
        }
    }
}
