<gen-text [flavor]="TextFlavor.Label" class="ha-left">
    {{ 'STE_LABEL_OVERVIEW' | translate }}
</gen-text>
<div class="mobile-details">
    <gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING' | translate }}</gen-spinner>

    <gen-flex *ngIf="!isLoading">
        <gen-flex-item [genColumns]="6" *ngIf="picture">
            <gen-image [fit]="ImageFit.Contain" [flavor]="ImageFlavor.Rounded" [src]="picture" style="width: 100px"> </gen-image>
        </gen-flex-item>
        <gen-flex-item [genColumns]="6">
            <gen-text [flavor]="TextFlavor.Label" class="ha-left">
                {{ 'STE_LABEL_APPLICATION' | translate }}
            </gen-text>
            <span class="fake-link" (click)="copyToClipboard(application)" [appTooltip]="application">
                {{ application }}
            </span>
        </gen-flex-item>
        <gen-flex-item [genColumns]="6">
            <gen-text [flavor]="TextFlavor.Label" class="ha-left">
                {{ 'STE_LABEL_USERNAME' | translate }}
            </gen-text>
            <span class="fake-link" (click)="goToUser(user)" [appTooltip]="user">
                {{ user }}
            </span>
        </gen-flex-item>
        <gen-flex-item [genColumns]="6">
            <gen-text [flavor]="TextFlavor.Label" class="ha-left">
                {{ 'STE_LABEL_LASTCONNECTIONTIME' | translate }}
            </gen-text>
            <gen-text> {{ lastConnectionTime }} </gen-text>
        </gen-flex-item>
        <gen-flex-item [genColumns]="6" *ngIf="lastKnownAddress">
            <gen-text [flavor]="TextFlavor.Label" class="ha-left">
                {{ 'STE_LABEL_ADDRESS' | translate }}
            </gen-text>
            <gen-text>
                {{ lastKnownAddress }}
            </gen-text>
        </gen-flex-item>
        <gen-flex-item [genColumns]="6" *ngIf="email">
            <gen-text [flavor]="TextFlavor.Label" class="ha-left">
                {{ 'STE_LABEL_NOUN_EMAIL' | translate }}
            </gen-text>
            <gen-link [href]="emailLink">{{ email }}</gen-link>
        </gen-flex-item>
    </gen-flex>
</div>
