import { Program } from './WebGL2/Program';
import { DewarperVertexShader, PositionBuffer } from './DewarperVertexShader';
import { DewarperFragmentShader } from './DewarperFragmentShader';
import { GLUtils } from './WebGL2/GLUtils';
import { Texture } from './WebGL2/Texture';
import { Uniform2f, Uniform4f } from './WebGL2/Uniform';
import { InternalDewarperSourceImageParameters } from './DewarperSourceImageParameters';
import { DewarperController } from './DewarperController';
import { IInternalDewarperControl } from './DewarperInterfaces';
import { ILogger } from '../utils/logger';
import { FloatPrecision } from './WebGL2/FragmentShader';
import { IVideoFrame } from '../players/VideoFrameSource';
import { Utils } from '../utils/Utils';
import { NormalizedColor } from '../utils/Color';
import { ControllerUniforms } from './ControllerUniforms';
import { DewarpingParameters } from './DewarpingParameters';

export class DewarperProgram extends Program {
  private readonly m_logger: ILogger;

  private readonly m_dewarperController: DewarperController;

  private readonly m_positionBuffer: PositionBuffer;
  private readonly m_texture: Texture;

  private readonly m_outsideColor: Uniform4f;

  private readonly m_warpedImageResolution: Uniform2f;
  private readonly m_canvasResolutionAdjustedToSrcAspectRatio: Uniform2f;
  private readonly m_canvasResolutionAspectRatioOverflow: Uniform2f;
  private readonly m_cropping: Uniform2f;

  public get Controller(): IInternalDewarperControl {
    return this.m_dewarperController;
  }

  public set OutsideColor(color: NormalizedColor) {
    this.m_outsideColor.set(color.R, color.G, color.B, color.A);
  }

  public constructor(logger: ILogger, gl: WebGL2RenderingContext, dewarperSourceImageParameters: InternalDewarperSourceImageParameters) {
    const vertexShader = new DewarperVertexShader(gl);
    const fragmentShader = new DewarperFragmentShader(gl, dewarperSourceImageParameters, FloatPrecision.high);
    super(gl, vertexShader, fragmentShader);

    this.m_logger = logger.subLogger('DewarperProgram');

    this.m_logger.intense?.trace(`vertex shader: ${this.m_compiledProgram.VertexShaderSource}`);
    this.m_logger.intense?.trace(`fragment shader: ${this.m_compiledProgram.FragmentShaderSource}`);

    this.m_positionBuffer = new PositionBuffer(this, 'a_position');
    this.m_warpedImageResolution = this.buildUniform2f('u_warpedImageResolution');
    this.m_canvasResolutionAdjustedToSrcAspectRatio = this.buildUniform2f('u_canvasResolutionAdjustedToSrcAspectRatio');
    this.m_canvasResolutionAspectRatioOverflow = this.buildUniform2f('u_canvasResolutionAspectRatioOverflow');
    this.m_cropping = this.buildUniform2f('u_cropping');

    this.m_outsideColor = this.buildUniform4f('u_outsideColor');

    this.m_dewarperController = this.buildController(dewarperSourceImageParameters);

    this.m_texture = this.createTexture();
    this.m_logger.debug?.trace(`original gl canvas: ${gl.canvas.width}x${gl.canvas.height}`);
  }

  public dispose() {
    this.m_texture.dispose();
    this.m_positionBuffer.dispose();

    super.dispose();
  }

  public setDewarpingParameters(dewarpingParameters: DewarpingParameters) {
    this.m_logger.info?.trace(`New dewarping parameters: ${dewarpingParameters.debugStatus(0)}`);

    this.m_logger.info?.trace(`Stream resolution changed from ${this.m_warpedImageResolution.toString()} to ${dewarpingParameters.StreamResolution.toString()}`);
    this.m_warpedImageResolution.setFromResolution(dewarpingParameters.StreamResolution);

    //canvas resolution management
    const canvasResolution = dewarpingParameters.DewarpedResolution;

    // set the resolution -> Tell WebGL how to convert from clip space to pixels
    this.m_gl.viewport(0, 0, canvasResolution.Width, canvasResolution.Height);

    this.m_canvasResolutionAdjustedToSrcAspectRatio.setFromResolution(dewarpingParameters.FitResolution.Scaled);
    this.m_canvasResolutionAspectRatioOverflow.setFromVec2(dewarpingParameters.FitResolution.PixelOffset);

    this.m_cropping.setFromVec2(dewarpingParameters.Cropping);

    this.m_logger.info?.trace(`Viewport resolution changed from ${this.m_gl.canvas.width}x${this.m_gl.canvas.height} to ${canvasResolution.toString()} dewarping at ${dewarpingParameters.FitResolution.Scaled.toString()} with an offset of ${dewarpingParameters.FitResolution.PixelOffset.toString()}. Cropping of ${dewarpingParameters.Cropping.toString()}`);

    this.m_dewarperController.DewarpingParameters = dewarpingParameters;
  }

  public dewarp(imageSource: IVideoFrame) {
    //todo: assert that imageSource is expected size instead...

    this.m_positionBuffer.setValue(GLUtils.getRectangle12Points(-1, -1, 2, 2)); //TODO: Try to do this only once

    this.m_texture.updateTexture(imageSource.Image);

    // Draw the rectangle (2 triangles)
    this.m_gl.drawArrays(this.m_gl.TRIANGLES, 0, 6);
  }

  private buildController(dewarperSourceImageParameters: InternalDewarperSourceImageParameters): DewarperController {
    const controller = new DewarperController(
      this.m_logger.subLogger('DewarperController - Program'),
      dewarperSourceImageParameters,
      new ControllerUniforms(
        this.buildUniform3f('u_pPrime'),
        this.buildUniform3f('u_uHat'),
        this.buildUniform3f('u_vHat'),
        null),
    );

    return controller;
  }

  public debugStatus(indent: number): string {
    const viewport: Int32Array = this.m_gl.getParameter(this.m_gl.VIEWPORT);

    return 'Dewarper Program:' + Utils.indentNewLine(indent) +
      'Uniforms: ' + Utils.indentNewLine(indent) +
      `Warped image resolution: ${this.m_warpedImageResolution.getX()}x${this.m_warpedImageResolution.getY()}` + Utils.indentNewLine(indent) +
      'Canvas resolution adjusted to src aspect ratio: ' + this.m_canvasResolutionAdjustedToSrcAspectRatio.toString() + Utils.indentNewLine(indent) +
      'Canvas resolution aspect ratio overflow: ' + this.m_canvasResolutionAspectRatioOverflow.toString() + Utils.indentNewLine(indent) +
      `gl viewport: ${viewport[0]}, ${viewport[1]} ${viewport[2]}x${viewport[3]}` + Utils.indentNewLine(indent) +
      'Outside color: ' + this.m_outsideColor.toString() + Utils.indentNewLine(indent) +
      this.m_dewarperController.debugStatus(indent + Utils.Indentation);
    //    + Utils.indentNewLine(indent) +
    //    'Source: ' + Utils.indentNewLine(indent) +
    //    this.m_compiledProgram.FragmentShaderSource;
  }
}
