import { Coords } from './Coords';
import { PolarVector } from './PolarVec2';
import { Radian } from './Radian';
import { Resolution } from './Resolution';

//Coordinates (I think)
//Screen:
//  origin: (0, 0) top left
//  directions: [left -] [right +] [up -] [down +]
//  scale: 1 is 1 pixel
//Normalized:
//  origin: (0, 0) bottom left (as webgl handles its texture. Don't try to go against this)
//  directions: [left -] [right +] [up +] [down -]
//  scale: 1 is the entire region (resolution of the image, resolution of the circle, depends on the context)
//cliped:
//  origin: (0, 0) center
//  directions: [left -] [right +] [up +] [down -]
//  scale: 1 is half of region in both direction (resolution of the image, resolution of the circle, depends on the context)

export class Vec2 {
  public static readonly Zero: Vec2 = new Vec2(0, 0);

  private readonly m_x: number;
  private readonly m_y: number;

  public get X(): number {
    return this.m_x;
  }

  public get Y(): number {
    return this.m_y;
  }

  constructor(x: number, y: number) {
    this.m_x = x;
    this.m_y = y;
  }

  public toString(): string {
    return `(${this.m_x.toFixed(3)}, ${this.m_y.toFixed(3)})`;
  }

  public equals(other: Vec2): boolean {
    return this.m_x === other.m_x && this.m_y === other.m_y;
  }

  public static build(value: number): Vec2 {
    return new Vec2(value, value);
  }

  //Angle between the positive X axis and the vector
  public angle(): Radian {
    return new Radian(Math.atan2(this.m_y, this.m_x));
  }

  public toPolar(): PolarVector {
    return new PolarVector(this.length(), this.angle());
  }

  public toClipSpace(screenSpace: Resolution): ClipSpaceVec2 {
    return ClipSpaceVec2.fromVec2(this, screenSpace);
  }

  public plus(right: Vec2): Vec2 {
    return new Vec2(this.m_x + right.m_x, this.m_y + right.m_y);
  }

  public minus(right: Vec2): Vec2 {
    return new Vec2(this.m_x - right.m_x, this.m_y - right.m_y);
  }

  public multiply(right: Vec2): Vec2 {
    return new Vec2(this.m_x * right.m_x, this.m_y * right.m_y);
  }

  public divide(right: Vec2): Vec2 {
    return new Vec2(this.m_x / right.m_x, this.m_y / right.m_y);
  }

  public plusC(right: number): Vec2 {
    return this.plus(Vec2.build(right));
  }

  public minusC(right: number): Vec2 {
    return this.minus(Vec2.build(right));
  }

  public multiplyC(right: number): Vec2 {
    return this.multiply(Vec2.build(right));
  }

  public divideC(right: number): Vec2 {
    return this.divide(Vec2.build(right));
  }

  public toNormalized(resolution: Resolution): NormalizedVec2 {
    if (resolution.equals(Resolution.None)) {
      throw new Error('Cannot convert to a normalized vector with Resolution.None');
    }
    return new NormalizedVec2(this.X / resolution.Width, this.Y / resolution.Height);
  }

  public static fromResolution(resolution: Resolution): Vec2 {
    return new Vec2(resolution.Width, resolution.Height);
  }

  public static fromCoords(coords: Coords): Vec2 {
    return new Vec2(coords.X, coords.Y);
  }

  private length(): number {
    return Math.sqrt(Math.pow(this.m_x, 2.0) + Math.pow(this.m_y, 2.0));
  }
}

// Just a tag type so we know when we are in [-1, 1]
// As a regular cartesian plan:
// - positive X are right of the origin
// - positive Y are up of the origin
export class ClipSpaceVec2 extends Vec2 {
  public static readonly Origin: ClipSpaceVec2 = new ClipSpaceVec2(0, 0);

  private static readonly normalizedTranslation: Vec2 = new Vec2(1, -1);
  private static readonly normalizedScaled: Vec2 = new Vec2(2, -2);

  public static fromVec2(vec2: Vec2, resolution: Resolution): ClipSpaceVec2 {
    const normalized = NormalizedVec2.fromVec2(vec2, resolution);
    return ClipSpaceVec2.fromNormalized(normalized);
  }

  public static fromNormalized(normalized: NormalizedVec2): ClipSpaceVec2 {
    const cliped = normalized.multiply(this.normalizedScaled).minus(this.normalizedTranslation);
    return new ClipSpaceVec2(cliped.X, cliped.Y);
  }

  public constructor(x: number, y: number) {
    super(x, y);
  }

  public isInClipSpace(): boolean {
    return (-1 <= this.X && this.X <= 1.0 &&
      -1 <= this.Y && this.Y <= 1.0);
  }

  public toNormalized(): NormalizedVec2 {
    const normalized = this.plus(ClipSpaceVec2.normalizedTranslation).divide(ClipSpaceVec2.normalizedScaled);
    return new NormalizedVec2(normalized.X, normalized.Y);
  }

  public toScreenSpace(screenSpace: Resolution): Vec2 {
    const normalized = this.toNormalized();
    return normalized.toPixel(screenSpace);
  }

  public rotate(angle: Radian): ClipSpaceVec2 {
    return this.rotate2(angle.sin(), angle.cos());
  }

  public rotate2(sin: number, cos: number): ClipSpaceVec2 {
    return new ClipSpaceVec2(this.X * cos - this.Y * sin, this.X * sin + this.Y * cos);
  }

  public roundEspilonToZero(): ClipSpaceVec2 {
    const epsilon: number = 0.0000001;
    return new ClipSpaceVec2(
      Math.abs(this.X) < epsilon ? 0 : this.X,
      Math.abs(this.Y) < epsilon ? 0 : this.Y);
  }

  public clipLength(maxLength: number): ClipSpaceVec2 {
    const polar = this.toPolar();
    if (polar.Radius <= maxLength) {
      return this;
    }

    const cliped = new PolarVector(maxLength, polar.Angle).toCartesian();
    return new ClipSpaceVec2(cliped.X, cliped.Y);
  }
}

// Just a tag type so we know when we are in [0, 1]
// 0, 0 is... top left
// Maybe we don't even need this type but note that:
// In windows, (0, 0) is top left. In WebGL, (0, 0) is bottom left...
export class NormalizedVec2 extends Vec2 {
  public static fromVec2(vec2: Vec2, resolution: Resolution) {
    const scaled = vec2.divide(Vec2.fromResolution(resolution));
    return new NormalizedVec2(scaled.X, scaled.Y);
  }

  public toPixel(screenSpace: Resolution): Vec2 {
    return this.multiply(Vec2.fromResolution(screenSpace));
  }
}
