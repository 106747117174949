import { SafeGuid } from 'safeguid';

export class EntityTypeIds {
    public static Area = SafeGuid.parse('5361E622-411B-4101-8A07-9AF19279EADD');
    public static Camera = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE1E6');
    public static Door = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE1E9');
    public static Alarm = SafeGuid.parse('29FC0073-8F91-4D0A-A30F-EB3CBDAC69F6');
    public static Device = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE113');
    public static IntrusionArea = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE112');
    public static Zone = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE111');
    public static LPRUnit = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE114');
    public static LPRDevice = SafeGuid.parse('38DA933C-2B98-4B99-ADA4-A7DD2EF87CD2');
    public static Ellipse = SafeGuid.parse('79112DA3-3D3F-443E-8109-F2EA29D85035');
    public static Rectangle = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE119');
    public static Line = SafeGuid.parse('DC2D9DD8-4C27-4E9B-B62F-63D04CFC1019');
    public static Image = SafeGuid.parse('0907310E-CA60-40AD-98C1-C4B6E5D1359C');
    public static Macro = SafeGuid.parse('57537B1D-81AA-42F9-8E27-1B9E689B37E4');
    public static Map = SafeGuid.parse('B23BD887-1EFF-41AA-8152-9EF204A365C5');
    public static Reader = SafeGuid.parse('71A9A544-8400-429B-84DA-4E7E21C6A661');
    public static Sequence = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE1E7');
    public static Text = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE118');
    public static TilePlugin = SafeGuid.parse('4C127AAB-04CC-4C7C-9898-2808708DDF58');
    public static CustomEntity = SafeGuid.parse('B815E823-F5A1-4D87-8045-9DF2FB07BCF7');
    public static Polygon = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE120');
    public static Cluster = SafeGuid.parse('DC2D9DD8-4E9F-4F84-9E3C-3A187C6FE121');
    public static AccessControlUnit = SafeGuid.parse('8F9D6059-AA93-435C-8438-2D1355AF336D');
    public static ParkingZone = SafeGuid.parse('F6BA1729-595B-4B13-A5A2-492430BADB6B');
    public static TileLayout = SafeGuid.parse('E37FC92D-57EA-432F-8436-4A09D58D9BB0');
    public static MobileUser = SafeGuid.parse('C3331B66-011A-4AEE-A13D-121D8602DA60');
    public static MobileApp = SafeGuid.parse('7FAE4449-E93C-4A5A-82DA-3B1DFF04F7A7');

    public static readonly values = [
        EntityTypeIds.Area,
        EntityTypeIds.Camera,
        EntityTypeIds.Door,
        EntityTypeIds.Alarm,
        EntityTypeIds.Device,
        EntityTypeIds.IntrusionArea,
        EntityTypeIds.Zone,
        EntityTypeIds.LPRUnit,
        EntityTypeIds.LPRDevice,
        EntityTypeIds.Ellipse,
        EntityTypeIds.Rectangle,
        EntityTypeIds.Line,
        EntityTypeIds.Image,
        EntityTypeIds.Macro,
        EntityTypeIds.Map,
        EntityTypeIds.Reader,
        EntityTypeIds.Sequence,
        EntityTypeIds.Text,
        EntityTypeIds.TilePlugin,
        EntityTypeIds.CustomEntity,
        EntityTypeIds.Polygon,
        EntityTypeIds.Cluster,
        EntityTypeIds.AccessControlUnit,
        EntityTypeIds.ParkingZone,
        EntityTypeIds.TileLayout,
        EntityTypeIds.MobileUser,
        EntityTypeIds.MobileApp,
    ];
}
