// Autogenerated: v2/help/Entities/ApiPartition?codegen=ts&InterfaceOnly=False&workflow=True on 2021-08-30 9:30:24 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { EntityManager } from './EntityManager';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { Entity } from '../Model/Entity';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IPartitionManager } from '../Interface/IPartitionManager';
import { ITransaction } from '../Interface/ITransaction';
import { IEntity } from '../Interface/IEntity';

export class PartitionManager extends EntityManager implements IPartitionManager {
    // #region Constructor

    constructor() {
        super('v2/entities/Partitions');
    }

    // #endregion Constructor

    public async getGrantedPrivilegedPartitionsAsync(privilege: IGuid, transaction?: ITransaction): Promise<Array<IEntity> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IEntity>> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = new Array<IEntity>();
                const body = response.body as Array<any>;
                for (const entry of body) {
                    const fo = new Entity();
                    fo.loadFields(entry);
                    result.push(fo);
                }
                return new Promise<Array<IEntity>>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IEntity>>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('privilege', privilege);

        return this.executeRequestTransactionAsync<Array<IEntity>>('privilegedpartitions', 'POST', inParam, transaction, responseHandler);
    }

}

