// Autogenerated: v2/help/Entities/ApiDoorSideAccessPoint?codegen=ts&InterfaceOnly=True&workflow=False on 2021-04-15 12:15:52 PM
import { ObservableCollection } from './../../Helpers/ObservableCollection';
import { IRestResponse } from './IRestResponse';
import { IEntity } from './IEntity';
import { IDoorAccessPointEntity, DoorAccessPointEntityFields } from './IDoorAccessPointEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class DoorSideAccessPointEntityFields extends DoorAccessPointEntityFields {
}

// #endregion Fields

export interface IDoorSideAccessPointEntity extends IDoorAccessPointEntity {
    // #region REST Methods

    getRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    setDeviceStateAsync(shunt: boolean, transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

