export class MultiJsonParser {
  private braceCount = 0;
  private inString = false;
  private escaped = false;
  private buffer = '';

  public onObject = (e: object) => {};

  public reset() {
    this.braceCount = 0;
    this.inString = false;
    this.escaped = false;
    this.buffer = '';
  }

  public parse(S: string) {
    let i;
    let pos = 0;
    for (i = 0; i < S.length; i++) {
      const c = S[i];
      if (this.inString) {
        if (this.escaped) {
          this.escaped = false;
        } else {
          if (c === '\\') {
            this.escaped = true;
          } else if (c === '"') {
            this.inString = false;
          }
        }
      } else {
        if (c === '{') {
          this.braceCount++;
        } else if (c === '}') {
          this.braceCount--;
          if (this.braceCount === 0) {
            this.buffer += S.substring(pos, i + 1);
            this.onObject(JSON.parse(this.buffer));
            this.buffer = '';
            pos = i + 1;
          }
        } else if (c === '"') {
          this.inString = true;
        }
      }
    }
    this.buffer += S.substring(pos);
  }
}
