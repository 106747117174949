import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { filterFieldChanged } from '@modules/shared/operators/filter-field-change';
import { notNullOrUndefined } from '@modules/shared/operators/not-null-or-undefined';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { ZoneArmingState } from 'RestClient/Client/Enumerations/ZoneArmingState';
import { ZoneState } from 'RestClient/Client/Enumerations/ZoneState';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { IZoneEntity, ZoneEntityFields } from 'RestClient/Client/Interface/IZoneEntity';
import { ZoneEntity } from 'RestClient/Client/Model/AccessControl/ZoneEntity';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { SecurityCenterClientService } from '../../../../security-center/services/client/security-center-client.service';
import { ContentPluginComponent } from '../../../shared/interfaces/plugins/public/plugin-public.interface';

@UntilDestroy()
@Component({
    selector: 'app-zone-widget-base-component',
    template: '',
})
export class ZoneWidgetBaseComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    private static monitoredFields = [
        ZoneEntityFields.customIconIdField,
        ZoneEntityFields.zoneStateField,
        ZoneEntityFields.armingStateField,
        ZoneEntityFields.runningStateField,
        ZoneEntityFields.maintenanceField,
    ];

    public dataContext?: ContentService;
    public zone: ZoneEntity | null = null;
    public content?: Content;
    public isLoading = true;
    public stateAddtionnalInfo = '';

    public zoneCustomIconId?: IGuid;
    public zoneArmingState?: string;
    public zoneIsArmed?: boolean;
    public zoneInputState?: string;
    public zoneRunningState?: RunningState;
    public zoneMaintenanceMode?: boolean;
    public zone$: Observable<IZoneEntity>;
    public zoneIsArmed$: Observable<boolean>;
    public zoneStateString$: Observable<string>;
    protected entityCacheTask?: IEntityCacheTask;
    protected get monitoredFields(): string[] {
        return ZoneWidgetBaseComponent.monitoredFields;
    }
    protected scClient: WebAppClient | null = null;
    private zoneSubject = new Subject<IZoneEntity>();
    constructor(securityCenterClientService: SecurityCenterClientService, protected translateService: TranslateService, trackingService: TrackingService) {
        super(trackingService);

        this.scClient = securityCenterClientService?.scClient;
        this.entityCacheTask = this.scClient?.buildEntityCache(this.monitoredFields);

        this.zone$ = this.zoneSubject.asObservable();
        this.zoneIsArmed$ = this.zone$.pipe(
            filterFieldChanged('armingState'),
            map((armingState) => armingState === ZoneArmingState.Armed),
            untilDestroyed(this)
        );

        this.zoneStateString$ = this.zone$.pipe(
            filterFieldChanged('zoneState'),
            notNullOrUndefined(),
            map((zoneState) => this.getTranslatedZoneStateString(zoneState)),
            untilDestroyed(this)
        );
    }

    public async ngOnDestroy() {
        await this.entityCacheTask?.dispose();
        super.ngOnDestroy();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.initializeZone().fireAndForget();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    protected async initializeZone(): Promise<void> {
        if (this.content && this.entityCacheTask) {
            const zone = await this.entityCacheTask?.getEntityAsync<ZoneEntity, IZoneEntity>(ZoneEntity, SafeGuid.parse(this.content.source), true);
            if (zone) {
                this.zoneSubject.next(zone);
                this.entityCacheTask
                    .detectEntityChange(zone, 'armingState', 'maintenance', 'runningState', 'zoneState')
                    .pipe(untilDestroyed(this))
                    .subscribe((updatedZone) => {
                        this.zoneSubject.next(updatedZone);
                    });
            }
        }
    }

    private getTranslatedZoneStateString(state: string): string {
        switch (state) {
            case ZoneState.Active:
                return this.translateService.instant('STE_LABEL_ZONE_STATE_ACTIVE') as string;

            case ZoneState.Normal:
                return this.translateService.instant('STE_LABEL_ZONE_STATE_NORMAL') as string;

            case ZoneState.Trouble:
                return this.translateService.instant('STE_LABEL_ZONE_STATE_TROUBLE') as string;

            default:
                return this.translateService.instant('STE_LABEL_ZONE_STATE_UNKNOWN') as string;
        }
    }
}
