import { IPtzControl } from './ptzControl';
import { IDigitalZoomControl } from './digitalZoomControl/DigitalZoomControl';
import { ILiteEvent, LiteEvent } from './utils/liteEvents';
import { StreamingConnectionStatusChangeEvent, PlayerStateChangeEvent, PlaySpeedChangeEvent, AudioStateChangeEvent, AudioAvailabilityChangeEvent, PlayerModeChangeEvent, ErrorStatusEvent } from './events';
import { TokenRetrieverFct } from './services/TokenRetriever';
import { MediaGatewayService, buildMediaGatewayService, IMediaGatewayService } from './services/mediaGatewayService';
import { ILogger, Logger } from './utils/logger';
import { HtmlElements } from './players/htmlElements';
import { WebPlayer } from './players/webPlayer';
import { Camera } from './players/camera';
import { PlaySpeed } from './players/PlaySpeed';
import { ITimelineProvider, TimelineProvider } from './timelineProvider';
import { IDewarperControl } from './dewarper/DewarperInterfaces';
import { PlayersList } from './Players';
import { Utils } from './utils/Utils';

let m_nextId: number = 1;

/**
 * Instanciate a new GWP instance in the provided div element.
 * @param container - Div Element into which the player will place itself
 * @returns instance used to control the new player
 */
export function buildPlayer(container: HTMLDivElement): IWebPlayer {
  if (container?.tagName?.toLowerCase() !== 'div') {
    // This check is required because a user calling us from javascript could ignore the type required
    throw new TypeError('container must be a *div* HTMLElement');
  }
  return new PublicWebPlayer(m_nextId++, container);
}

/** Primary interface use to control the GWP.
 * @public
 * */
export interface IWebPlayer {
  /** Is this player already started. */
  readonly isStarted: boolean;

  /** Acquire the interface used to send PTZ commands to the active player */
  readonly ptzControl: IPtzControl;

  /** Acquire the interface used to digitally zoom on the active player and to interact with the preview. Null if not supported by the browser */
  readonly digitalZoomControl: IDigitalZoomControl | null;

  /** Acquire the interface used to control the dewarper of the active player. Will be null if dewarping is not available */
  readonly dewarperControl: IDewarperControl | null;

  /** Acquire the interface used to get timeline events for the displayed camera, player must be started */
  readonly timelineProvider: ITimelineProvider;

  /** Is the player currently playing in live mode */
  readonly isLive: boolean;

  /** Is the player currently paused */
  readonly isPaused: boolean;

  /** Current play speed */
  readonly playSpeed: number

  /** Time of the last frame received */
  readonly lastFrameTime: Date;

  /** Is there an audio stream supported by the camera */
  readonly isAudioAvailable: boolean;

  /** Is audio playback currently enabled */
  readonly isAudioEnabled: boolean;

  /** Event raised when the player gets into an Error state */
  readonly onErrorStateRaised: ILiteEvent<ErrorStatusEvent>;
  /** Event raised periodically (not every time) when a frame is rendered */
  readonly onFrameRendered: ILiteEvent<Date>;
  /** Event raised when the streaming connection status changes */
  readonly onStreamStatusChanged: ILiteEvent<StreamingConnectionStatusChangeEvent>;
  /** Event raised when the player state changes */
  readonly onPlayerStateChanged: ILiteEvent<PlayerStateChangeEvent>;
  /** Event raised when play speed changes */
  readonly onPlaySpeedChanged: ILiteEvent<PlaySpeedChangeEvent>;
  /** Event raised when audio playback state changes */
  readonly onAudioStateChanged: ILiteEvent<AudioStateChangeEvent>;
  /** Event raised when audio playback availability changes */
  readonly onAudioAvailabilityChanged: ILiteEvent<AudioAvailabilityChangeEvent>;
  /** Event raised when player mode changes between live and playback */
  readonly onPlayerModeChanged: ILiteEvent<PlayerModeChangeEvent>;
  /** Event raised periodically while player needs to buffer before it can start playing */
  readonly onBufferingProgress: ILiteEvent<number>;

  /**
   * Prepare the player to play the specified camera. This will establish a session with the Media Gateway server.
   * @param camera - Camera GUID to request from the Media Gateway.
   * @param mediaGatewayEndpoint - Uri of the Media Gateway. Must be in the form `https://<endpoint>/<applicationPath>`.
   * @param tokenRetriever - Callback to use to retrieve a security token for the camera. It will be called during the start process and for each token renewal while the session is active.
   */
  start(camera: string, mediaGatewayEndpoint: string, tokenRetriever: TokenRetrieverFct): Promise<void>;

  /**
   * Prepare the player to play the specified camera using an already created Media Gateway service.
   * The player will establish its session within the already existing transport of the Media Gateway Service. This can be used to multiplex many players into the same socket.
   * @param camera - Camera GUID to request from the Media Gateway.
   * @param mediaGatewayService - Existing Media Gateway service to use for communications.
   */
  startWithService(camera: string, mediaGatewayService: IMediaGatewayService): Promise<void>;

  /**
   * Stop the current session on this player. This clears the running state for the current camera and prepares the player to be started for a new camera.
   * */
  stop(): void;

  /**
   * Stop and release the player, it will be removed from its containing "div" element.
   * */
  dispose(): void;

  /**
   * Obtain a snapshot of the currently displayed video. Returns null if no image is available.
   * */
  getSnapshot(): ImageData | null;

  /** Request to start playing the live stream. */
  playLive(): void;

  /** Pause the player.
   * @remarks
   * Pause may be called in playback or live mode.
   * */
  pause(): void;

  /** Resume the player. Must be called after a pause to resume the player on the last frame played.
   * @remarks
   * If the player was in live when pause was called, the resume will switch to playback at the last frame that was played.
   * If you want to restart the live stream, you must call {@link IWebPlayer.playLive} instead.
   * */
  resume(): void;

  /**
   * Seek the player to the specified time.
   * @param seekTime - Time to start the playback.
   *
   * @remarks
   * If no recording is available at the seek time, the player will start playing at the first available recording past the seek time depending on the play speed direction.
   */
  seek(seekTime: Date): void;

  /**
   * Set the play speed of the player. Any value between 0.01 to 100 is acceptable. Reverse values are also accepted between 0.01 and 100.
   * @param playSpeed - Playspeed to use. Acceptable values must be between -100 and -0.01 or between 0.01 and 100.
   *
   * @remarks
   * Reverse speeds will play only the key frames in the stream. If the key frame interval is too large, we recommend using at least -6x to have a smooth experience.
   * Higher speeds will also switch to using key frames only for playback.
   */
  setPlaySpeed(playSpeed: number): void;

  /**
   * Plays or mute the audio stream (if available)
   * @param isAudioEnabled - Set to true to enable the audio. False to disable.
   */
  setAudioEnabled(isAudioEnabled: boolean): void;

  /**
   * Set the video player in ptz mode. This mode attempts to favorize low latency over stream quality at the expense of being more expensive on server resources.
   * It has to be enabled in the media gateway configuration.
   * @param ptzMode - Enable (true) or Disable (false)
   */
  setPtzMode(ptzMode: boolean): void;

  /**
   * Show or Hide the debug overlay to help diagnostic of issues with the player.
   * The diagnostic can also be toggled by using the Ctrl+Shift+A shortcut.
   * @param show - Show (true) or Hide (false)
   */
  showDebugOverlay(show: boolean): void;
}

/**
  * Represents a single media player
  * @internal
  */
class PublicWebPlayer implements IWebPlayer {
  private readonly m_logger: ILogger;
  private readonly m_playerId: number;

  private readonly m_htmlElements: HtmlElements;

  // Will be set only if we created it and are responsible to dispose it.
  private m_mediaGatewayService?: MediaGatewayService;
  private m_webPlayer: WebPlayer | undefined;
  private m_isDisposed: boolean = false;

  private readonly m_timelineProvider = new TimelineProvider();

  private readonly errorEvent = new LiteEvent<ErrorStatusEvent>();
  private readonly frameRenderedEvent = new LiteEvent<Date>();
  private readonly streamStatusEvent = new LiteEvent<StreamingConnectionStatusChangeEvent>();
  private readonly playerStateEvent = new LiteEvent<PlayerStateChangeEvent>();
  private readonly playSpeedEvent = new LiteEvent<PlaySpeedChangeEvent>();
  private readonly audioEvent = new LiteEvent<AudioStateChangeEvent>();
  private readonly audioAvailabilityEvent = new LiteEvent<AudioAvailabilityChangeEvent>();
  private readonly playerModeEvent = new LiteEvent<PlayerModeChangeEvent>();
  private readonly bufferingProgress = new LiteEvent<number>();

  public get playerId(): number {
    this.throwIfDisposed();
    return this.m_playerId;
  }

  public get ptzControl(): IPtzControl {
    return this.webPlayer.PtzControl;
  }

  public get digitalZoomControl(): IDigitalZoomControl | null {
    return this.webPlayer.DigitalZoomControl;
  }

  public get dewarperControl(): IDewarperControl | null {
    return this.webPlayer.Dewarper;
  }

  public get timelineProvider(): ITimelineProvider {
    this.throwIfDisposed();
    return this.m_timelineProvider;
  }

  public get isLive(): boolean {
    return this.webPlayer.isLive;
  }

  public get isPaused(): boolean {
    return this.webPlayer.isPaused;
  }

  public get playSpeed(): number {
    return this.webPlayer.playSpeed.Value;
  }

  public get lastFrameTime(): Date {
    return this.webPlayer.lastFrameTime;
  }

  public get isAudioAvailable(): boolean {
    return this.webPlayer.isAudioAvailable;
  }

  public get isAudioEnabled(): boolean {
    return this.webPlayer.isAudioEnabled;
  }

  public get isStarted(): boolean {
    return this.m_webPlayer !== undefined;
  }

  public get onErrorStateRaised(): ILiteEvent<ErrorStatusEvent> {
    return this.errorEvent.expose();
  }
  public get onFrameRendered(): ILiteEvent<Date> {
    return this.frameRenderedEvent.expose();
  }
  public get onStreamStatusChanged(): ILiteEvent<StreamingConnectionStatusChangeEvent> {
    return this.streamStatusEvent.expose();
  }
  public get onPlayerStateChanged(): ILiteEvent<PlayerStateChangeEvent> {
    return this.playerStateEvent.expose();
  }
  public get onPlaySpeedChanged(): ILiteEvent<PlaySpeedChangeEvent> {
    return this.playSpeedEvent.expose();
  }
  public get onAudioStateChanged(): ILiteEvent<AudioStateChangeEvent> {
    return this.audioEvent.expose();
  }
  public get onAudioAvailabilityChanged(): ILiteEvent<AudioAvailabilityChangeEvent> {
    return this.audioAvailabilityEvent.expose();
  }
  public get onPlayerModeChanged(): ILiteEvent<PlayerModeChangeEvent> {
    return this.playerModeEvent.expose();
  }
  public get onBufferingProgress(): ILiteEvent<number> {
    return this.bufferingProgress.expose();
  }

  constructor(playerId: number, divContainer: HTMLDivElement) {
    PlayersList.Instance.add(this);
    this.m_playerId = playerId;
    this.m_logger = new Logger(playerId, 'WebPlayer');
    this.m_htmlElements = new HtmlElements(divContainer);
  }

  public dispose(): void {
    if (this.m_isDisposed) return;

    this.m_logger.debug?.trace('dispose');
    PlayersList.Instance.remove(this);

    if (this.isStarted) {
      this.stop();
    }

    this.errorEvent.dispose();
    this.frameRenderedEvent.dispose();
    this.streamStatusEvent.dispose();
    this.playerStateEvent.dispose();
    this.playSpeedEvent.dispose();
    this.audioEvent.dispose();
    this.audioAvailabilityEvent.dispose();
    this.playerModeEvent.dispose();
    this.bufferingProgress.dispose();
    this.m_timelineProvider.dispose();

    this.m_webPlayer?.disposeAsync().then(() => this.m_mediaGatewayService?.stop());
    this.m_htmlElements.dispose();

    this.m_isDisposed = true;
  }

  public getSnapshot(): ImageData | null {
    return this.webPlayer.getSnapshot();
  }

  public async start(camera: string, mediaGatewayEndpoint: string, tokenRetriever: TokenRetrieverFct): Promise<void> {
    this.throwIfDisposed();
    if (this.isStarted) {
      throw new Error(`Player ${this.m_playerId} is already started`);
    }

    this.m_logger.debug?.trace('Starting with its own MediaGatewayService');
    this.m_mediaGatewayService = await buildMediaGatewayService(mediaGatewayEndpoint, tokenRetriever) as MediaGatewayService;
    try {
      await this.startWithService(camera, this.m_mediaGatewayService);
    } catch (error) {
      this.m_logger.error?.trace('Couldnt start web player: ', error);
      this.m_mediaGatewayService?.stop();
      throw error;
    }
  }

  public async startWithService(camera: string, mediaGatewayService: IMediaGatewayService): Promise<void> {
    this.throwIfDisposed();
    if (this.isStarted) {
      throw new Error(`Player ${this.m_playerId} is already started`);
    }
    if (!(mediaGatewayService instanceof MediaGatewayService)) {
      throw new Error('The provided MediaGatewayService is not of the expected type');
    }

    const webPlayer = new WebPlayer(this.m_playerId, this.m_htmlElements, new Camera(camera), mediaGatewayService, true);
    try {
      await webPlayer.initialization();
      await webPlayer.SessionEstablishment;
    } catch (error) {
      await webPlayer.disposeAsync();
      throw error;
    }
    this.subscribeToPlayerEvent(webPlayer);
    this.m_webPlayer = webPlayer;
  }

  public stop() {
    this.m_logger.debug?.trace('stop()');
    this.throwIfDisposed();
    if (this.m_webPlayer !== undefined) { // isStarted
      this.unsubscribeToPlayerEvent(this.m_webPlayer);
      const mgService = this.m_mediaGatewayService;
      this.m_mediaGatewayService = undefined;
      const player = this.m_webPlayer;
      this.m_webPlayer = undefined;
      player.disposeAsync().then(() => mgService?.stop());
    }
  }

  public playLive(): void {
    this.webPlayer.playLive();
  }

  public pause(): void {
    this.webPlayer.pause();
  }

  public resume(): void {
    this.webPlayer.resume();
  }

  public seek(seekTime: Date): void {
    if (!(seekTime instanceof Date) || isNaN(seekTime.valueOf())) {
      throw new Error('Invalid Seek Time was requested');
    }
    this.webPlayer.seek(seekTime);
  }

  public setPlaySpeed(playSpeed: number): void {
    this.webPlayer.setPlaySpeed(new PlaySpeed(playSpeed));
  }

  public setAudioEnabled(isAudioEnabled: boolean): void {
    this.webPlayer.setAudioEnabled(isAudioEnabled);
  }

  public setPtzMode(ptzMode: boolean): void {
    this.webPlayer.setPtzMode(ptzMode);
  }

  public showDebugOverlay(show: boolean): void {
    this.webPlayer.showDebugOverlay(show);
  }

  private throwIfDisposed() {
    if (this.m_isDisposed) {
      throw new Error('PublicWebPlayer is disposed');
    }
  }

  private throwIfDisposedOrNotStarted() {
    this.throwIfDisposed();
    if (this.m_webPlayer === undefined) {
      throw new Error('Player need to be started first.');
    }
  }

  private get webPlayer(): WebPlayer {
    this.throwIfDisposedOrNotStarted();
    return this.m_webPlayer!;
  }

  private readonly onErrorState = (errorStatus: ErrorStatusEvent): void => {
    if (errorStatus.isFatal) {
      this.stop();
    }
    this.errorEvent.trigger(errorStatus);
  }

  private subscribeToPlayerEvent(webPlayer: WebPlayer): void {
    webPlayer.errorStateRaised.register(this.onErrorState);
    webPlayer.frameRendered.register(this.frameRenderedEvent.boundTrigger);
    webPlayer.streamStatusChanged.register(this.streamStatusEvent.boundTrigger);
    webPlayer.playerStateChanged.register(this.playerStateEvent.boundTrigger);
    webPlayer.playSpeedChanged.register(this.playSpeedEvent.boundTrigger);
    webPlayer.audioStateChanged.register(this.audioEvent.boundTrigger);
    webPlayer.audioAvailabilityChanged.register(this.audioAvailabilityEvent.boundTrigger);
    webPlayer.playerModeChanged.register(this.playerModeEvent.boundTrigger);
    webPlayer.bufferingProgress.register(this.bufferingProgress.boundTrigger);
    this.m_timelineProvider.register(webPlayer);
  }

  private unsubscribeToPlayerEvent(webPlayer: WebPlayer): void {
    webPlayer.errorStateRaised.unregister(this.onErrorState);
    webPlayer.frameRendered.unregister(this.frameRenderedEvent.boundTrigger);
    webPlayer.streamStatusChanged.unregister(this.streamStatusEvent.boundTrigger);
    webPlayer.playerStateChanged.unregister(this.playerStateEvent.boundTrigger);
    webPlayer.playSpeedChanged.unregister(this.playSpeedEvent.boundTrigger);
    webPlayer.audioStateChanged.unregister(this.audioEvent.boundTrigger);
    webPlayer.audioAvailabilityChanged.unregister(this.audioAvailabilityEvent.boundTrigger);
    webPlayer.playerModeChanged.unregister(this.playerModeEvent.boundTrigger);
    webPlayer.bufferingProgress.unregister(this.bufferingProgress.boundTrigger);
    this.m_timelineProvider.unregister(webPlayer);
  }

  public debugStatus(): string {
    const indent = 1;
    return `PublicWebPlayer${Utils.indentNewLine(indent)}` +
      `PlayerId: ${this.m_playerId}${Utils.indentNewLine(indent)}` +
      (this.m_isDisposed ? `${Utils.indentNewLine(indent)}Disposed${Utils.indentNewLine(indent)}` : '') +
      (this.m_webPlayer?.debugStatus(indent + Utils.Indentation) ?? 'WebPlayer undefined');
  }
}
