// Autogenerated: v2/help/Classes/ApiAccessEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:12:10 AM
import { TimezoneEvent } from './TimezoneEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { IAccessEvent } from '../Interface/IAccessEvent';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

export class AccessEvent extends TimezoneEvent implements IAccessEvent {
    // #region REST Properties

    // Cardholder
    public get cardholder(): IGuid {
        return this.getFieldGuid('Cardholder');
    }
    public set cardholder(value: IGuid) {
        this.setFieldGuid('Cardholder', value);
    }

    // CardholderDesc
    public get cardholderDesc(): string {
        return this.getField<string>('CardholderDesc');
    }
    public set cardholderDesc(value: string) {
        this.setField<string>('CardholderDesc', value);
    }

    // CardholderType
    public get cardholderType(): string {
        return this.getField<string>('CardholderType');
    }
    public set cardholderType(value: string) {
        this.setField<string>('CardholderType', value);
    }

    // Credentials
    public get credentials(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('Credentials');
    }
    public set credentials(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('Credentials', value);
    }

    // DoorSideGuid
    public get doorSideGuid(): IGuid {
        return this.getFieldGuid('DoorSideGuid');
    }
    public set doorSideGuid(value: IGuid) {
        this.setFieldGuid('DoorSideGuid', value);
    }

    // DoorSideGuidDesc
    public get doorSideGuidDesc(): string {
        return this.getField<string>('DoorSideGuidDesc');
    }
    public set doorSideGuidDesc(value: string) {
        this.setField<string>('DoorSideGuidDesc', value);
    }

    // DoorSideGuidType
    public get doorSideGuidType(): string {
        return this.getField<string>('DoorSideGuidType');
    }
    public set doorSideGuidType(value: string) {
        this.setField<string>('DoorSideGuidType', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Cardholder', SafeGuid.EMPTY);
        this.setField<string>('CardholderDesc', '');
        this.setField<string>('CardholderType', '');
        this.setFieldArrayGuid('Credentials', new ObservableCollection<IGuid>());
        this.setFieldGuid('DoorSideGuid', SafeGuid.EMPTY);
        this.setField<string>('DoorSideGuidDesc', '');
        this.setField<string>('DoorSideGuidType', '');
    }

    // #endregion Methods

}

