import { ILogger, Logger } from '../utils/logger';
import { HtmlElements } from './htmlElements';
import { DebugOverlay } from './debugOverlay';
import { StatisticProvider } from '../utils/StatisticProvider';
import { IStatefulSession } from './WebSocket/StatefulSession';
import { JpegInfo, SegmentInfo, StreamingStatus, ErrorDetails } from '../services/eventHub';
import { Codec, EventManager } from '../eventManager';
import { IMseRenderer, MseRenderer } from './mse/MseRenderer';
import { JpegRenderer } from './jpeg/JpegRenderer';
import { MseHtmlAudioElement } from './audio/MseAudioElementWrapper';
import { PtzControl, IPtzControl, IPtzThrottlingService } from '../ptzControl';
import { ITimeResolver, ResolvedFrameTime, GenerationTimeResolver } from '../utils/TimeResolver';
import { ILiteEvent } from '../utils/liteEvents';
import { StreamingConnectionStatusChangeEvent, PlayerStateChangeEvent, PlaySpeedChangeEvent, AudioStateChangeEvent, AudioAvailabilityChangeEvent, TimelineContentEvent, PlayerModeChangeEvent, ErrorStatusEvent } from '../events';
import { Camera } from './camera';
import { MediaGatewayService } from '../services/mediaGatewayService';
import { MseHtmlVideoElement } from './mse/MseHtmlVideoElement';
import { PtzRequestBuilder } from './WebSocket/PtzRequestBuilder';
import { PlaySpeed } from './PlaySpeed';
import { Utils } from '../utils/Utils';
import { StreamingConnectionStatus, PlayerMode, ErrorCode } from '../enums';
import { SupportedVideoFormats, VideoFormat } from './WebSocket/SupportedVideoFormats';
import { StreamUsageSelectorBuilder } from './StreamUsageSelector';
import { version } from '../gwp';
import { TimeLineRange } from '../TimeLineRange';
import { Dewarper } from '../dewarper/Dewarper';
import { FeatureFlag } from '../utils/FeatureFlag';
import { DewarperSourceImageParameters } from '../dewarper/DewarperSourceImageParameters';
import { DebugSwitch } from '../utils/Config';
import { DigitalZoomControl, IDigitalZoomControl, DigitalZoom } from '../digitalZoomControl/DigitalZoomControl';
import { Tile } from './Tile';
import { DigitalZoomPreview } from '../digitalZoomControl/preview/DigitalZoomPreview';
import { PreviewCanvas } from '../digitalZoomControl/preview/PreviewCanvas';
import { VideoFrameSources } from './VideoFrameSource';
import { TileSizeObserver } from './TileSizeProvider';
import { RenderingCanvas } from '../utils/RenderingCanvas';
import { IDataHub, IEventHubProvider } from '../services/eventHubDispatcher';
import { IDewarperControl } from '../dewarper/DewarperInterfaces';
import { DragObserver } from '../utils/DragObserver';
import { MouseWheelObserver } from '../utils/MouseWheelObserver';
import { DewarperVwoOverlay } from '../dewarper/DewarperVwoOverlay';

export interface ITimelineEventSource {
  readonly timelineContentUpdated: ILiteEvent<TimelineContentEvent>;
  setTimelineRange(timeLine: TimeLineRange): void;
}

export class WebPlayer implements ITimelineEventSource, IDataHub {
  public readonly PlayerId: number;

  private readonly m_camera: Camera;

  private readonly m_logger: ILogger;

  private readonly m_htmlElements: HtmlElements;

  private readonly m_debugOverlay: DebugOverlay;

  private readonly m_statisticProvider: StatisticProvider;

  private readonly m_digitalZoomControl: DigitalZoomControl;

  private m_sessionEstablishment: Promise<IStatefulSession>;

  private readonly m_eventManager: EventManager;

  private m_mseRenderer?: IMseRenderer;

  private readonly m_jpegRenderer: JpegRenderer;

  private readonly m_audioPlayer?: MseHtmlAudioElement;

  private readonly m_tile: Tile;

  private m_dewarper: Dewarper | null = null;

  private readonly m_eventHubProvider: IEventHubProvider;

  private m_session?: IStatefulSession;

  private m_ptzControl?: PtzControl;

  private m_timeResolver?: ITimeResolver;

  private m_ptzThrottlingService: IPtzThrottlingService | undefined;

  private m_isDisposed = false;

  private get session(): IStatefulSession {
    this.throwIfDisposed();
    if (this.m_session === undefined) {
      throw new Error('Player session must be established first');
    }
    return this.m_session;
  }

  public get SessionEstablishment(): Promise<IStatefulSession> {
    return this.m_sessionEstablishment;
  }

  public get PtzControl(): IPtzControl {
    if (this.m_ptzControl === undefined) {
      throw new Error('Player session must be established first');
    }
    return this.m_ptzControl;
  }

  public get DigitalZoomControl(): IDigitalZoomControl | null {
    if (!TileSizeObserver.supportsResizeObserver()) {
      // Digital Zoom is not supported by IE
      this.m_logger.info?.trace('Digital zoom is not supported by your browser.');
      return null;
    }
    if (this.m_dewarper !== null) {
      // Dewarper is enabled, we want to disable the Digital Zoom
      this.m_logger.info?.trace('Digital zoom is disabled when the dewarper is enabled.');
      return null;
    }
    return this.m_digitalZoomControl;
  }

  public get Dewarper(): IDewarperControl | null {
    if (this.m_session === undefined) {
      throw new Error('Player session must be established first');
    }
    return this.m_dewarper?.dewarperControl ?? null;
  }

  public set PtzThrottlingService(value: IPtzThrottlingService | undefined) {
    this.m_ptzThrottlingService = value;

    if (this.m_ptzControl) {
      this.m_ptzControl.PtzThrottlingService = value;
    }
  }

  public get isLive(): boolean {
    return this.m_session?.sessionStateView.playerMode === PlayerMode.live;
  }

  public get isPaused(): boolean {
    return this.m_session?.sessionStateView.isPaused ?? false;
  }

  public get playSpeed(): PlaySpeed {
    return this.m_session?.sessionStateView.playSpeed ?? PlaySpeed._1x;
  }

  public get lastFrameTime(): Date {
    return this.m_eventManager.lastFrameReceived.frameTime;
  }

  public get isAudioEnabled(): boolean {
    return (this.m_session?.sessionStateView.isAudioEnabled && !this.m_audioPlayer?.isMuted) ?? false;
  }

  public get isAudioAvailable(): boolean {
    return this.m_eventManager.isAudioAvailable;
  }

  public get isPlayerStarted(): boolean {
    return this.m_session !== undefined;
  }

  //* ** Public event handlers ***
  public get errorStateRaised(): ILiteEvent<ErrorStatusEvent> {
    return this.m_eventManager.errorStateRaised;
  }
  public get frameRendered(): ILiteEvent<Date> {
    return this.m_eventManager.frameRendered;
  }
  public get streamStatusChanged(): ILiteEvent<StreamingConnectionStatusChangeEvent> {
    return this.m_eventManager.streamStatusChanged;
  }
  public get playerStateChanged(): ILiteEvent<PlayerStateChangeEvent> {
    return this.m_eventManager.playerStateChanged;
  }
  public get playSpeedChanged(): ILiteEvent<PlaySpeedChangeEvent> {
    return this.m_eventManager.playSpeedChanged;
  }
  public get audioStateChanged(): ILiteEvent<AudioStateChangeEvent> {
    return this.m_eventManager.audioStateChanged;
  }
  public get audioAvailabilityChanged(): ILiteEvent<AudioAvailabilityChangeEvent> {
    return this.m_eventManager.audioAvailabilityChanged;
  }
  public get timelineContentUpdated(): ILiteEvent<TimelineContentEvent> {
    return this.m_eventManager.timelineContentUpdated;
  }
  public get playerModeChanged(): ILiteEvent<PlayerModeChangeEvent> {
    return this.m_eventManager.playerModeChanged;
  }
  public get bufferingProgress(): ILiteEvent<number> {
    return this.m_eventManager.bufferingProgress;
  }
  // *** End of event handlers ***

  constructor(id: number, htmlElements: HtmlElements, camera: Camera, mediaGatewayService: MediaGatewayService, enableH264: boolean) {
    this.m_logger = new Logger(id, 'WebPlayer');

    this.PlayerId = id;
    this.m_htmlElements = htmlElements;
    this.m_camera = camera;

    this.m_statisticProvider = new StatisticProvider();

    this.m_eventManager = new EventManager(this.m_logger, this.m_statisticProvider);

    const renderingCanvas = new RenderingCanvas(this.m_logger, this.m_htmlElements.CanvasElement);
    if (Utils.supportsMse()) {
      this.m_mseRenderer = new MseRenderer(this.m_logger, new MseHtmlVideoElement(this.m_logger, this.m_htmlElements.DivContainer, this.m_htmlElements.VideoElement, renderingCanvas, this.m_statisticProvider.frameRateProvider));
      this.m_audioPlayer = new MseHtmlAudioElement(this.m_logger, this.m_htmlElements.AudioElement);
      this.m_mseRenderer.timeUpdated.register(this.onTimeUpdated);
      this.m_mseRenderer.errorOccurred.register(this.onMseErrorOccurred);
    } else {
      this.m_logger.warn?.trace('MSE is not supported by this browser.');
    }

    this.m_jpegRenderer = new JpegRenderer(this.m_logger, renderingCanvas);
    this.m_jpegRenderer.timeUpdated.register(this.onJpegTimeUpdated);

    this.m_tile = new Tile(this.m_logger, this.m_mseRenderer ?? null, this.m_jpegRenderer, this.m_htmlElements.getTileSizeObserverBuilder().build());

    const digitalZoom = new DigitalZoom(this.m_logger, this.m_tile, new DragObserver(this.m_htmlElements.DivContainer));
    const digitalZoomPreview = new DigitalZoomPreview(this.m_logger, new PreviewCanvas(this.m_logger, this.m_htmlElements.DigitalZoomPreviewCanvas), digitalZoom, new VideoFrameSources(this.m_jpegRenderer, this.m_mseRenderer, this.m_htmlElements.VideoElement));
    this.m_digitalZoomControl = new DigitalZoomControl(digitalZoom, digitalZoomPreview);

    this.m_debugOverlay = new DebugOverlay(this.m_logger, this.PlayerId, this.m_htmlElements, this.m_digitalZoomControl, this.m_eventManager);

    // Create the eventHub before the session to be able to catch the create stream response
    this.m_eventHubProvider = mediaGatewayService.eventHubProvider;
    this.m_eventHubProvider.registerStream(this.PlayerId.toString(), this, this.m_eventManager);

    // DH
    if ( enableH264 === true) {
      this.m_sessionEstablishment = this.establishSession(mediaGatewayService, SupportedVideoFormats.getBrowserSupportedFormats());
    } else {
      // MJPEG Only
      this.m_sessionEstablishment = this.establishSession(mediaGatewayService, new SupportedVideoFormats([VideoFormat.Mjpeg]));
    }

    this.m_logger.debug?.trace('Player', this.PlayerId, 'created (Genetec Web Player v' + version() + ')');
  }

  private establishSession(mediaGatewayService: MediaGatewayService, supportedVideoFormats: SupportedVideoFormats): Promise<IStatefulSession> {
    const streamUsageSelectorBuilder = new StreamUsageSelectorBuilder(this.m_logger, this.m_htmlElements.getTileSizeObserverBuilder());
    this.m_sessionEstablishment = mediaGatewayService.startStream(this.m_logger, this.PlayerId, this.m_camera, supportedVideoFormats, streamUsageSelectorBuilder);
    this.m_sessionEstablishment.then(
      (establishedSession) => {
        this.m_session = establishedSession;
        this.m_session.ConnectionReestablished.register(this.onSessionRecovered);
        this.m_session.ConnectionLost.register(this.onSessionLost);
        this.m_session.SessionError.register(this.onSessionError);
        this.m_ptzControl = new PtzControl(this.m_logger, this.session.ptzControlSession, new PtzRequestBuilder(this.PlayerId), this.m_ptzThrottlingService);
        this.m_debugOverlay.sessionStateView = this.m_session.sessionStateView;

        this.m_tile?.updateVideoWatermarkingConfig(this.session.sessionParameters.videoWatermarkConfig);
        this.m_digitalZoomControl.updateVideoWatermarkingConfig(this.session.sessionParameters.videoWatermarkConfig);

        if (FeatureFlag.IsDewarperEnabled) {
          if (this.session.sessionParameters.dewarperConfig !== DewarperSourceImageParameters.None) {
            this.m_logger.debug?.trace(`Creating a dewarper: ${this.session.sessionParameters.dewarperConfig.toString()}`);

            const mouseWheelObserver = new MouseWheelObserver(this.m_htmlElements.DivContainer);
            const dragObserver = new DragObserver(this.m_htmlElements.DivContainer);

            const dewarperVwoOverlay = new DewarperVwoOverlay(this.m_logger, this.m_htmlElements.DewarpingVwoCanvas);

            this.m_dewarper = Dewarper.build(this.m_logger, this.m_htmlElements.DewarpingCanvas, dewarperVwoOverlay, this.m_htmlElements.DewarpingPreviewDiv, this.m_htmlElements.DewarpingPreviewCanvas, this.m_htmlElements.DewarpedAreaCanvas,
              this.session.sessionParameters.dewarperConfig,
              new VideoFrameSources(this.m_jpegRenderer, this.m_mseRenderer, this.m_htmlElements.VideoElement),
              mouseWheelObserver, dragObserver);
            this.m_dewarper.updateVideoWatermarkingConfig(this.session.sessionParameters.videoWatermarkConfig);
          } else {
            this.m_logger.debug?.trace('Dewarper config: none');
          }
        } else {
          this.m_logger.debug?.trace('no dewarper feature flag');
        }

        this.m_logger.debug?.trace(`Session ${this.PlayerId} established`);
      },
      (reason) => {
        this.m_logger.warn?.trace('Session establishement failure: ', reason);
      });

    return this.m_sessionEstablishment;
  }

  public async disposeAsync(): Promise<void> {
    this.m_logger.debug?.trace('dispose');

    if (this.m_isDisposed) {
      return;
    }
    this.m_isDisposed = true;

    this.m_debugOverlay.reset();
    this.m_debugOverlay.dispose();

    this.m_eventManager.reset();
    this.m_eventManager.dispose();

    if (this.m_session !== undefined) {
      this.m_session.ConnectionReestablished.unregister(this.onSessionRecovered);
      this.m_session.ConnectionLost.unregister(this.onSessionLost);
      this.m_session.SessionError.unregister(this.onSessionError);
    }

    this.m_digitalZoomControl.dispose();

    this.m_mseRenderer?.timeUpdated.unregister(this.onTimeUpdated);
    this.m_mseRenderer?.errorOccurred.unregister(this.onMseErrorOccurred);
    this.m_jpegRenderer.timeUpdated.unregister(this.onJpegTimeUpdated);

    this.m_statisticProvider.reset();

    this.m_mseRenderer?.dispose();
    this.m_jpegRenderer.dispose();
    this.m_audioPlayer?.dispose();

    if (this.m_session !== undefined) {
      try {
        await this.m_session.dispose();
        this.m_logger.debug?.trace('Session deleted succesfully from the media gateway.');
      } catch (e) {
        this.m_logger.warn?.trace('Session failed to delete from the media gateway.', e);
      }

      this.m_session = undefined;
    }

    this.m_eventHubProvider.unregisterStream(this.PlayerId.toString());
    this.m_dewarper?.dispose();
  }

  public async initialization(): Promise<void> {
    if (this.m_mseRenderer !== undefined) {
      await this.m_mseRenderer.initialization;
    }
    if (this.m_audioPlayer !== undefined) {
      await this.m_audioPlayer.initialization;
    }
  }

  public getSnapshot(): ImageData | null {
    return this.m_tile.getSnapshot(false);
  }

  public playLive(): void {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Going to Live');
    if (this.session.playLive()) {
      this.m_mseRenderer?.start();
      this.m_audioPlayer?.playLive();
      this.m_jpegRenderer.start();
      this.m_eventManager.receivePlaySpeed(PlaySpeed._1x);
      this.m_eventManager.receivePlayerMode(PlayerMode.live);
    }
  }

  public pause(): void {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Pausing');
    if (this.session.pause()) {
      this.m_mseRenderer?.stop();
      this.m_jpegRenderer.stop();
      this.m_audioPlayer?.pause();
      this.m_eventManager.receivePlayerMode(PlayerMode.playback);
    }
  }

  public resume(): void {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Resuming');
    if (this.session.resume()) {
      this.m_mseRenderer?.start();
      this.m_audioPlayer?.play();
      this.m_jpegRenderer.start();
    }
  }

  public seek(seekTime: Date): void {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Seeking to', Utils.formatDateUTC(seekTime));
    this.session.seek(seekTime);
    this.m_mseRenderer?.start();
    this.m_jpegRenderer.start();
    this.m_eventManager.receivePlayerMode(PlayerMode.playback);
  }

  public setPlaySpeed(playSpeed: PlaySpeed) {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Setting playspeed to', playSpeed.Value);
    if (this.session.setPlaySpeed(playSpeed)) {
      this.m_eventManager.receivePlaySpeed(playSpeed);
      this.m_audioPlayer?.setPlaySpeed(playSpeed);
      this.m_timeResolver?.setPlaySpeed(playSpeed);
    }
  }

  public setAudioEnabled(isAudioEnabled: boolean) {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Setting Audio Enabled to', isAudioEnabled);
    isAudioEnabled ? this.m_audioPlayer?.unmute() : this.m_audioPlayer?.mute();
    // We only toggle the audio once server-side, when we enable it the first time:
    // this is to make it faster for audio to play after it's been muted.
    if (isAudioEnabled) {
      this.session.setAudioEnabled(isAudioEnabled);
    }
    this.m_eventManager.receiveAudioState(isAudioEnabled);
  }

  public setTimelineRange(timeLineRange: TimeLineRange) {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Setting timeline range to', timeLineRange.toString());
    this.session.setTimelineRange(timeLineRange);
  }

  public setPtzMode(isEnabled: boolean) {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Setting PtzMode to', isEnabled);
    this.session.setPtzMode(isEnabled);
  }

  public showDebugOverlay(show: boolean) {
    this.throwIfDisposed();
    this.m_logger.info?.trace('Setting DebugOverlay to', show);
    this.m_debugOverlay.toggleShown(show);
  }

  public receiveJpeg(info: JpegInfo, buffer: Int8Array) {
    if (this.m_isDisposed) {
      this.m_logger.intense?.trace(`Disposed - Dropped video frame ${info.Timestamp} Gen: ${info.Generation} Size: ${Utils.readableByteCount(buffer.byteLength)}}`);
      return;
    }

    this.m_logger.intense?.trace(`Received video frame ${info.Timestamp} Gen: ${info.Generation} Size: ${Utils.readableByteCount(buffer.byteLength)}}`);

    this.m_statisticProvider.bitRateProvider.addBytes(buffer.length);
    this.m_statisticProvider.frameRateProvider.addFrame('k');

    this.m_eventManager.setLastFrameReceived(new ResolvedFrameTime(new Date(info.Timestamp)), Codec.Mjpeg);
    this.m_jpegRenderer.render(buffer, new Date(info.Timestamp));

    this.m_tile?.setCodec(Codec.Mjpeg);
  }

  public receiveSegment(info: SegmentInfo, buffer: Int8Array) {
    if (this.m_isDisposed) {
      this.m_logger.intense?.trace(`Disposed - Dropping segment #${info.Id} MediaTime: ${info.BaseDecodeTime}`);
      return;
    }

    if (info.IsVideo) {
      this.onVideoSegmentReceived(info, buffer);
    } else {
      this.onAudioSegmentReceived(info, buffer);
    }
  }

  private onVideoSegmentReceived(info: SegmentInfo, buffer: Int8Array) {
    this.m_logger.intense?.trace(`Received video frame #${info.Id} MediaTime: ${info.BaseDecodeTime}, ${Utils.formatDate(info.FrameTimeAsDate)} ${info.MediaTime}, Init: ${info.IsInit} Key: ${info.IsKeyFrame} Sequence: ${info.SequenceId} Gen: ${info.Generation} TotalSec: ${info.TotalSeconds} Size: ${Utils.readableByteCount(buffer.byteLength)}}`);

    // When doing live-pause, and in ptz mode
    if (!this.session.sessionStateView.isLivePaused) {
      this.m_jpegRenderer.clear();
    }

    if (info.IsInit) {
      if (this.m_timeResolver !== undefined) {
        //looking at timeresolver !== undefined to know if this is the very first packet.
        this.m_logger.info?.trace('Init segment. Resetting the MsePlayer');
        this.m_mseRenderer?.reset();
        this.m_mseRenderer?.updateVideoWatermarkingConfig(this.session.sessionParameters.videoWatermarkConfig);
      }
    }

    if (this.m_timeResolver === undefined || (!this.m_timeResolver.canResolve(info.Generation) || info.IsInit)) {
      this.m_timeResolver = new GenerationTimeResolver(info.Generation, info.BaseDecodeTime, info.FrameTimeAsDate, this.playSpeed);
      this.m_logger.info?.trace(`New time resolver based on => Id: ${info.Id} Init: ${info.IsInit} Key: ${info.IsKeyFrame} Sequence: ${info.SequenceId} Gen: ${info.Generation} TotalSec: ${info.TotalSeconds} -> ${this.m_timeResolver.toString()}`);
    }

    this.m_statisticProvider.bitRateProvider.addBytes(buffer.length);
    this.m_statisticProvider.frameRateProvider.addFrame(info.IsKeyFrame ? 'k' : 'p');

    this.m_timeResolver.updateResolver(info.BaseDecodeTime, info.FrameTimeAsDate);

    this.m_eventManager.setLastFrameReceived(new ResolvedFrameTime(info.FrameTimeAsDate, info.BaseDecodeTime), Codec.H264);
    this.m_mseRenderer?.render(info, buffer);

    this.m_tile?.setCodec(Codec.H264);
  }

  private onAudioSegmentReceived(info: SegmentInfo, buffer: Int8Array) {
    this.m_logger.intense?.trace(`Received audio frame: ${info.Id} ${info.BaseDecodeTime}, ${Utils.formatDate(info.FrameTimeAsDate)} ${info.MediaTime}, Init: ${info.IsInit} Key: ${info.IsKeyFrame} Sequence: ${info.SequenceId} Gen: ${info.Generation} TotalSec: ${info.TotalSeconds} Size: ${Utils.readableByteCount(buffer.byteLength)}}`);

    if (info.IsInit) {
      this.m_audioPlayer?.reset();
    }

    this.m_audioPlayer?.decode(info, buffer);
    this.m_statisticProvider.audioBitRateProvider.addBytes(buffer.length);
  }

  private readonly onTimeUpdated = (mediaTime: number) => {
    if (this.m_timeResolver !== undefined) {
      const lastFrameRendered = this.m_timeResolver.resolve(mediaTime);
      this.session.setLastFrameRendered(lastFrameRendered.frameTime);
      this.m_audioPlayer?.sync(lastFrameRendered.frameTime);
      this.m_eventManager.setLastFrameRendered(lastFrameRendered);
    }
  }

  private readonly onMseErrorOccurred = () => {
    this.m_logger.warn?.trace('Mse error occured. Retrying with Mjpeg transcoding.');

    this.m_mseRenderer?.timeUpdated.unregister(this.onTimeUpdated);
    this.m_mseRenderer?.errorOccurred.unregister(this.onMseErrorOccurred);
    this.m_mseRenderer?.dispose();
    this.m_mseRenderer = undefined;

    this.session.restartSession(this.session.sessionParameters.withSupportedVideoFormats(new SupportedVideoFormats([VideoFormat.Mjpeg])));
  }

  private readonly onJpegTimeUpdated = (frameTime: Date) => {
    this.session.setLastFrameRendered(frameTime);
    this.m_audioPlayer?.sync(frameTime);
    this.m_eventManager.setLastJpegFrameRendered(frameTime, this.m_jpegRenderer.streamResolution);
  }

  private readonly onSessionRecovered = () => {
    this.m_logger.debug?.trace('Session re-established');
    this.m_mseRenderer?.reset();
    this.m_timeResolver = undefined;
  }

  private readonly onSessionLost = () => {
    this.m_logger.info?.trace('Session was lost');
    this.m_eventManager.receiveStreamingConnectionStatus(new StreamingStatus(StreamingConnectionStatus.ConnectingToMediaGateway, ''));
  }

  private readonly onSessionError = (error: string) => {
    this.m_logger.error?.trace('An error occurred updating the SessionState, player is no longer usable:', error);
    this.m_eventManager.receiveErrorDetails(new ErrorDetails(ErrorCode.UpdateSessionError, error));
  }

  private throwIfDisposed() {
    if (this.m_isDisposed) {
      throw new Error('WebPlayer is disposed');
    }
  }

  public debugStatus(indent: number): string {
    return 'WebPlayer' + Utils.indentNewLine(indent) +
      (this.m_isDisposed ? `${Utils.indentNewLine(indent)}Disposed${Utils.indentNewLine(indent)}` : '') +
      'Camera: ' + this.m_camera.Id + Utils.indentNewLine(indent) +
      'Codec: ' + this.m_eventManager.codec + Utils.indentNewLine(indent) +
      this.m_statisticProvider.frameRateProvider.getFrameRate().frameRateToString() + ' (KeyFrame interval: ' + this.m_statisticProvider.frameRateProvider.getFrameRate().keyFrameRateToString() + ')' + Utils.indentNewLine(indent) +
      this.m_statisticProvider.bitRateProvider.getBitRate() + Utils.indentNewLine(indent) +
      '--- MsePlayer ---' + Utils.indentNewLine(indent) +
      'Mse: ' + (Utils.supportsMse() ? 'supported' : 'not supported') + ' ' + (DebugSwitch.IsMseBroken ? 'Broken by config' : '') + Utils.indentNewLine(indent) +
      this.m_mseRenderer?.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      '--- Jpeg ---' + Utils.indentNewLine(indent) +
      this.m_jpegRenderer.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      '--- Audio ---' + Utils.indentNewLine(indent) +
      'Audio available: ' + this.m_eventManager.isAudioAvailable + Utils.indentNewLine(indent) +
      'Audio bitrate: ' + this.m_statisticProvider.getAudioBitRate().toString() + Utils.indentNewLine(indent) +
      (this.m_audioPlayer !== undefined ? (this.m_audioPlayer?.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent)) : '') +
      '--- Session ---' + Utils.indentNewLine(indent) +
      (this.m_session !== undefined ? this.m_session.debugStatus(indent + Utils.Indentation) : 'No session') + Utils.indentNewLine(indent) +
      '--- Zoom ---' + Utils.indentNewLine(indent) +
      this.m_digitalZoomControl.debugStatus(indent + Utils.Indentation) +
      (this.m_dewarper !== null ?
        '--- Dewarper ---' + Utils.indentNewLine(indent) +
        this.m_dewarper.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) :
        '');
  }
}
