// Autogenerated: v2/help/Entities/ApiPluginRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class PluginRoleEntityFields extends RoleEntityFields {
}

// #endregion Fields

// #region Inner classes

export interface ISlotInfo extends IFieldObject {
    count: number;
}

export interface IRoleAgentApplication extends IFieldObject {
    appId: IGuid;
    serverId: IGuid;
}

export interface IAgentUrl extends IFieldObject {
    serverId: IGuid;
    applicationPath: string;
    restUrl: string;
}

export interface IRestVersion extends IFieldObject {
    minor: number;
    major: number;
    build: number;
    revision: number;
}

export interface IPluginRoleConfig extends IFieldObject {
    version: IRestVersion;
    urls: ObservableCollection<IAgentUrl>;
    externalPlugin: boolean;
    configToolData: string;
    configToolHtml: string;
    configToolHtmlZip: IGuid;
    configToolHtmlZipMd5: string;
    roleCustomData: string;
    supportDatabase: boolean;
    nameSpace: string;
}

export interface IDbUpgradeItem extends IFieldObject {
    sourceVersion: number;
    targetVersion: number;
    upgradeScript: string;
}

export interface IDatabaseScript extends IFieldObject {
    expectedVersion: number;
    creationScript: string;
    upgradeItems: ObservableCollection<IDbUpgradeItem>;
}

export interface IDatabaseConnectionInfo extends IFieldObject {
    databaseInstance: string;
    databaseName: string;
    connectionString: string;
}

// #endregion Inner classes

export interface IPluginRoleEntity extends IRoleEntity {
    // #region REST Methods

    getDatabaseConfigurationAsync(transaction?: ITransaction | null): Promise<IDatabaseConnectionInfo | null>;
    setDatabaseScriptsAsync(value: IDatabaseScript, transaction?: ITransaction): Promise<IRestResponse | null>;
    freeSlotAsync(groupId: string, slotId: string, transaction?: ITransaction): Promise<IRestResponse | null>;
    modifyLicenseItemAsync(item: string, count: number, tag: string, transaction?: ITransaction): Promise<IRestResponse | null>;
    getPluginRoleConfigAsync(transaction?: ITransaction | null): Promise<IPluginRoleConfig | null>;
    reserveSlotAsync(maxCountAllowed: number, groupId: string, slotId: string, transaction?: ITransaction): Promise<IRestResponse | null>;
    getRoleAgentApplicationsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRoleAgentApplication> | null>;
    getFreeSlotAsync(id: string, transaction?: ITransaction | null): Promise<ISlotInfo | null>;
    startOpenIdServerAsync(applicationPath: string, authCallbackUri: string, port: number, transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

