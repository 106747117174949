import { IGuid } from 'safeguid';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Injectable } from '@angular/core';
import { Mutex } from 'RestClient/Helpers/semaphore';
import { naturalSort } from '../utilities/natural-sort';
import { EntityBrowserController, EntityBrowserExpandData, EntityBrowserRefreshData, IEntityBrowserController } from '../controllers/entity-browser/entity-browser.controller';
import { EntityBrowserItem } from '../components/entity-browser/items/entity-browser-item';
import { EntityBrowserResult } from './interfaces/entity-browser-result';
import { IEntityBrowserFilter } from './interfaces/filters/entity-browser-filter.interface';
import { IEntityBrowserService } from './interfaces/entity-browser.service.interface';
import { IEntityTypeSet } from './interfaces/entity-type-set.interface';
import { EntityTypeSet } from './entity-type-set';
import { HierarchicalEntityBrowserFilter } from './filters/hierarchical-entity-browser-filter';

@Injectable({ providedIn: 'root' })
export class EntityBrowserService implements IEntityBrowserService {
    private static defaultLogicalEntityTypes: IEntityTypeSet;

    private logicalTypesMutex = new Mutex();
    private scClient: ISecurityCenterClient;

    constructor(securityCenterProvider: SecurityCenterClientService) {
        // always check availability since we use reflection to load this component
        this.scClient = securityCenterProvider?.scClient;

        this.retrieveLogicalTypesAsync().fireAndForget();
    }

    public cloneFilter(filter: IEntityBrowserFilter, isHierarchical = false): IEntityBrowserFilter {
        if (isHierarchical && !(filter instanceof HierarchicalEntityBrowserFilter)) {
            return filter.clone(new HierarchicalEntityBrowserFilter());
        }
        return filter.clone();
    }

    public compareItems(item1: EntityBrowserItem, item2: EntityBrowserItem, isHierarchical = false): number {
        if (isHierarchical) {
            if (item1.sortPriority > item2.sortPriority) {
                return -1;
            } else if (item1.sortPriority < item2.sortPriority) {
                return 1;
            }
        }

        return naturalSort(item1.text, item2.text);
    }

    public async getDefaultLogicalTypesAsync(): Promise<IEntityTypeSet> {
        if (EntityBrowserService.defaultLogicalEntityTypes) {
            return EntityBrowserService.defaultLogicalEntityTypes;
        }

        const release = await this.logicalTypesMutex.acquire();
        try {
            //await this.retrieveLogicalTypesAsync();
            return EntityBrowserService.defaultLogicalEntityTypes;
        } finally {
            release();
        }
    }

    // public static createFilterFromTypes(entityTypes: EntityTypeData[] | Iterable<EntityTypeData> | EntityTypeData): EntityBrowserFilter {
    //     const types = entityTypes instanceof EntityTypeData ? [entityTypes] : entityTypes;
    //     const hierarchicalTypes = this.getDefaultHierarchicalTypes();
    //     let filter: EntityBrowserFilter | undefined;
    //     for (const entityType of types) {
    //         if (!hierarchicalTypes.has(entityType)) {
    //             const subTypes = EntityTypes.getSubTypes(entityType.type);

    //             let applyFlatFilter = subTypes.length === 0;
    //             if (subTypes.length > 0) {
    //                 for (const subType of subTypes) {
    //                     if (!hierarchicalTypes.has(entityType.type + subType)) {
    //                         applyFlatFilter = true;
    //                         break;
    //                     }
    //                 }
    //             }
    //             if (applyFlatFilter) {
    //                 filter = new FlatEntityBrowserFilter();
    //                 break;
    //             }
    //         }
    //     }

    //     if (!filter) {
    //         filter = new HierarchicalEntityBrowserFilter();
    //     }

    //     filter.entityTypes.addRange(types);
    //     return filter;
    // }

    public async refreshAsync(componentId: IGuid, filter: IEntityBrowserFilter, isFullRefresh: boolean): Promise<EntityBrowserResult> {
        if (!componentId || componentId.isEmpty()) {
            const result = new EntityBrowserResult();
            result.initializeAllFields();
            return new EntityBrowserResult();
        }

        const controller = await this.getController();
        const data = new EntityBrowserRefreshData();
        data.isFullRefresh = isFullRefresh;
        data.componentId = componentId;
        data.filter = filter.extractRestParam();
        return ((await controller.getRefreshAsync(data)) as EntityBrowserResult) ?? new EntityBrowserResult();
    }

    public async expandAsync(componentId: IGuid, entityId: IGuid, filter: IEntityBrowserFilter): Promise<EntityBrowserResult> {
        if (!componentId || componentId.isEmpty()) {
            const result = new EntityBrowserResult();
            result.initializeAllFields();
            return new EntityBrowserResult();
        }

        const controller = await this.getController();
        const data = new EntityBrowserExpandData();
        data.componentId = componentId;
        data.entityId = entityId;
        data.filter = filter.extractRestParam();
        return ((await controller.getExpandAsync(data)) as EntityBrowserResult) ?? new EntityBrowserResult();
    }

    private async getController(): Promise<EntityBrowserController> {
        return (await this.scClient.getAsync<EntityBrowserController, IEntityBrowserController>(EntityBrowserController)) as EntityBrowserController;
    }

    private async retrieveLogicalTypesAsync(): Promise<void> {
        const release = await this.logicalTypesMutex.acquire();
        try {
            const logicalTypes = await (await this.getController()).getLogicalTypesAsync();
            if (logicalTypes) {
                EntityBrowserService.defaultLogicalEntityTypes = new EntityTypeSet(logicalTypes);
            }
        } finally {
            release();
        }
    }
}
