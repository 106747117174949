// Autogenerated: v2/help/Entities/ApiDevice?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { Entity } from './../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IDeviceEntity, DeviceEntityFields } from '../../Interface/IDeviceEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class DeviceEntity extends Entity implements IDeviceEntity {
    // #region REST Properties

    // DeviceType
    public get deviceType(): string {
        return this.getField<string>(DeviceEntityFields.deviceTypeField);
    }

    // IsDisabled
    public get isDisabled(): boolean {
        return this.getField<boolean>(DeviceEntityFields.isDisabledField);
    }
    public set isDisabled(value: boolean) {
        this.setField<boolean>(DeviceEntityFields.isDisabledField, value);
    }

    // PhysicalName
    public get physicalName(): string {
        return this.getField<string>(DeviceEntityFields.physicalNameField);
    }

    // UniqueId
    public get uniqueId(): string {
        return this.getField<string>(DeviceEntityFields.uniqueIdField);
    }

    // Unit
    public get unit(): IGuid {
        return this.getFieldGuid(DeviceEntityFields.unitField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Devices);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPoint', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

