import { List } from 'immutable';

export interface ListResultApi<T> {
    TotalResultsCount?: number;
    Results: Array<T>;
}

export class ListResult<T> {
    constructor(public totalResultsCount: number | null, public results: List<T>) {}
}
