import { Utils } from './utils/Utils';
import { TimeLine } from './players/WebSocket/Messages';
import { TimeSpan } from './utils/TimeSpan';

export class TimeLineRange {
  private readonly m_startTime: Date;

  private readonly m_endTime: Date;

  public get Span(): TimeSpan {
    return new TimeSpan(this.m_endTime.getTime() - this.m_startTime.getTime());
  }

  public constructor(startTime: Date, endTime: Date) {
    if (endTime.getTime() <= startTime.getTime()) {
      throw new Error(`Timeline range end time must be after start time. ${Utils.formatDate(startTime)} - ${Utils.formatDate(endTime)}`);
    }

    this.m_startTime = startTime;
    this.m_endTime = endTime;
  }

  public toString(): string {
    return `[${Utils.formatDate(this.m_startTime)} - ${Utils.formatDate(this.m_endTime)}]`;
  }

  public toTimeLine(): TimeLine {
    return new TimeLine(this.m_startTime, this.m_endTime);
  }
}
