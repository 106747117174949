import { FieldObject } from './FieldObject';
import { CancellationToken } from './Helpers';
import { ObservableCollection } from './ObservableCollection';
import { RelationTrackerBase } from './RelationTrackerBase';
import { ITransaction } from '../Client/Interface/ITransaction';
import { IRestResponse } from '../Client/Interface/IRestResponse';
import { IFieldObject } from '../Client/Interface/IFieldObject';
import { IRestObject } from '../Client/Interface/IRestObject';

export class RelationFieldObjectTracker<T extends FieldObject, U extends IFieldObject> extends RelationTrackerBase {
    //#region Fields

    protected _members: ObservableCollection<U> | null = null;
    private _classType: any;

    //#endregion

    //#region Properties

    //#endregion

    //#region constructor

    constructor(classType: new () => T, parent: IRestObject, relation: string, elementId: string, readOnly: boolean, allowValueUpdate: boolean) {
        super(parent, relation, elementId, readOnly, allowValueUpdate);
        this._classType = classType;
    }

    //#endregion

    //#region methods

    public async getValueAsync(): Promise<ObservableCollection<U>> {
        if (this._members == null) {
            this._members = new ObservableCollection<U>(
                (e: U) => {
                    this.onAdded(e);
                },
                (e: U) => {
                    this.onRemoved(e);
                },
                () => {
                    this.onCleared();
                },
            );
            this._members.suspend = true;
            if (this._parent.canExecuteRelations === true) {
                const response = await this._parent.executeRequestAsync('GET', this.relation, null, new CancellationToken());
                this.parseResponse(response);
            }
            this._members.suspend = false;
        }
        return this._members;
    }

    public async pushTransactionAsync(transaction: ITransaction): Promise<void> {
        if (this._parent.canExecuteRelations === false) {
            this._members = new ObservableCollection<U>(
                (e: U) => {
                    this.onAdded(e);
                },
                (e: U) => {
                    this.onRemoved(e);
                },
                () => {
                    this.onCleared();
                },
            );
        }

        if (this._members != null) {
            // no need for transaction since we already got the data
            // add empty entry simply to return the data
            transaction.addTransactionOperation<ObservableCollection<U>>('', '', null, (response: IRestResponse) => {
                return new Promise((resolve, reject) => {
                    if (this._members != null) {
                        resolve(this._members);
                        return;
                    }
                    reject('not supposed to be here!');
                    return;
                });
            });
            return;
        } else {
            this._members = new ObservableCollection<U>(
                (e: U) => {
                    this.onAdded(e);
                },
                (e: U) => {
                    this.onRemoved(e);
                },
                () => {
                    this.onCleared();
                },
            );
        }

        const uri = this._parent.baseUri + '/' + this.relation;
        transaction.addTransactionOperation<ObservableCollection<U>>(uri, 'GET', null, (response: IRestResponse) => {
            return new Promise((resolve, reject) => {
                if (!this._members) {
                    throw new Error('Member null');
                }
                try {
                    if (response.body) {
                        this._members.suspend = true;
                        this.parseResponse(response);
                        this._members.suspend = false;
                    }
                    resolve(this._members);
                } catch (e) {
                    reject(e);
                }
            });
        });
        return;
    }

    private parseResponse(response: IRestResponse) {
        if (response.body) {
            if (!this._members) {
                throw new Error('_members not initialize!');
            }

            for (const item of response.body) {
                const newFo = new this._classType() as T;
                newFo.roParent = this._parent;
                newFo.loadFields(item);
                this._members.add(newFo as unknown as U);
                newFo.onPropertyChanged((fieldObject) => {
                    const id = fieldObject.getField(this._elementId);
                    let op = this._relation + '/' + id;
                    op = op.toLowerCase();
                    const found = this.pendingOperations.firstOrDefault((x) => x.verb.toLowerCase() === 'put' && x.uri.toLowerCase().endsWith(op));
                    if (found) {
                        found.body = fieldObject;
                    }
                    this.addRelationOperation(op, 'PUT', fieldObject);
                });
            }
        }
    }

    public onAdded(element: U): void {
        if (this._readOnly === true) {
            throw new Error('the collection is read only');
        }
        this.addRelationOperation(this._relation, 'POST', element);
    }

    public onRemoved(element: U): void {
        const id = element.getField(this._elementId);
        this.addRelationOperation(this._relation + '/' + id, 'DELETE', null);
    }

    public onCleared(): void {
        this.addRelationOperation(this._relation, 'DELETE', null);
    }

    // TODO replace!

    //#region
}
