// Autogenerated: v2/help/Entities/ApiNetwork?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:01 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IEntity } from '../Interface/IEntity';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ITransaction } from '../Interface/ITransaction';
import { INetworkEntity, IPublicServer, NetworkEntityFields } from '../Interface/INetworkEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { FieldObject } from '../../Helpers/FieldObject';

// #region Inner classes

export class PublicServer extends FieldObject implements IPublicServer {
    // ServerId
    public get serverId(): IGuid {
        return this.getFieldGuid('ServerId');
    }
    public set serverId(value: IGuid) {
        this.setFieldGuid('ServerId', value);
    }
    // PublicEndPoint
    public get publicEndPoint(): string {
        return this.getField<string>('PublicEndPoint');
    }
    public set publicEndPoint(value: string) {
        this.setField<string>('PublicEndPoint', value);
    }
    // IsProxy
    public get isProxy(): boolean {
        return this.getField<boolean>('IsProxy');
    }
    public set isProxy(value: boolean) {
        this.setField<boolean>('IsProxy', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('ServerId', SafeGuid.EMPTY);
        this.setField<string>('PublicEndPoint', '');
        this.setField<boolean>('IsProxy', false);
    }
}

// #endregion Inner classes

export class NetworkEntity extends Entity implements INetworkEntity {
    // #region REST Properties

    // IPv4AddressPrefix
    public get iPv4AddressPrefix(): string {
        return this.getField<string>(NetworkEntityFields.iPv4AddressPrefixField, ValidFlags.Specific);
    }
    public set iPv4AddressPrefix(value: string) {
        this.setField<string>(NetworkEntityFields.iPv4AddressPrefixField, value);
    }

    // IPv4NetworkMask
    public get iPv4NetworkMask(): string {
        return this.getField<string>(NetworkEntityFields.iPv4NetworkMaskField, ValidFlags.Specific);
    }
    public set iPv4NetworkMask(value: string) {
        this.setField<string>(NetworkEntityFields.iPv4NetworkMaskField, value);
    }

    // IPv6Prefix
    public get iPv6Prefix(): string {
        return this.getField<string>(NetworkEntityFields.iPv6PrefixField, ValidFlags.Specific);
    }
    public set iPv6Prefix(value: string) {
        this.setField<string>(NetworkEntityFields.iPv6PrefixField, value);
    }

    // IPv6PrefixLength
    public get iPv6PrefixLength(): number {
        return this.getField<number>(NetworkEntityFields.iPv6PrefixLengthField, ValidFlags.Specific);
    }
    public set iPv6PrefixLength(value: number) {
        this.setField<number>(NetworkEntityFields.iPv6PrefixLengthField, value);
    }

    // IsDefaultNetwork
    public get isDefaultNetwork(): boolean {
        return this.getField<boolean>(NetworkEntityFields.isDefaultNetworkField);
    }

    // ParentRoute
    public get parentRoute(): IGuid {
        return this.getFieldGuid(NetworkEntityFields.parentRouteField);
    }

    // ProxyServer
    public get proxyServer(): IGuid {
        return this.getFieldGuid(NetworkEntityFields.proxyServerField, ValidFlags.Specific);
    }
    public set proxyServer(value: IGuid) {
        this.setFieldGuid(NetworkEntityFields.proxyServerField, value);
    }

    // PublicAddress
    public get publicAddress(): string {
        return this.getField<string>(NetworkEntityFields.publicAddressField, ValidFlags.Specific);
    }
    public set publicAddress(value: string) {
        this.setField<string>(NetworkEntityFields.publicAddressField, value);
    }

    // RemoteStreamLocatorId
    public get remoteStreamLocatorId(): IGuid {
        return this.getFieldGuid(NetworkEntityFields.remoteStreamLocatorIdField, ValidFlags.Specific);
    }
    public set remoteStreamLocatorId(value: IGuid) {
        this.setFieldGuid(NetworkEntityFields.remoteStreamLocatorIdField, value);
    }

    // SupportedTransports
    public get supportedTransports(): string {
        return this.getField<string>(NetworkEntityFields.supportedTransportsField, ValidFlags.Specific);
    }
    public set supportedTransports(value: string) {
        this.setField<string>(NetworkEntityFields.supportedTransportsField, value);
    }

    // SupportsIpv6
    public get supportsIpv6(): boolean {
        return this.getField<boolean>(NetworkEntityFields.supportsIpv6Field);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Networks);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getPublicServersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPublicServer> | null> {
        return this.getFieldObjectRelationAsync<PublicServer, IPublicServer>(PublicServer, 'PublicServers', 'ServerId', false, true, ValidFlags.Specific, transaction);
    }

    public async getRoutesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Routes', 'Id', true, query, ValidFlags.EntityDependency, transaction);
    }

    public async getServersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Servers', 'Id', false, query, ValidFlags.Membership3, transaction);
    }

    public async getSubNetworksAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('SubNetworks', 'Id', false, query, ValidFlags.Membership, transaction);
    }

    // #endregion REST Methods

}

