import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { GenAngularModule } from '@genetec/gelato-angular';
import { NgxsModule } from '@ngxs/store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '@shared/shared.module';
import { ActivityEventService } from '@modules/mission-control/services/activity-event/activity-event.service';
import { LinkedIncidentWidgetComponent } from '@modules/mission-control/components/linked-incident-widget/linked-incident-widget.component';
import { LinkedIncidentService } from '@modules/mission-control/services/linked-incident/linked-incident.service';
import { LinkedIncidentController } from '@modules/mission-control/controllers/linked-incident.controller';
import { QuickActionWidgetComponent } from '@modules/mission-control/components/quick-action-widget/quick-action-widget.component';
import { OverviewWidgetComponent } from '@modules/mission-control/components/overview-widget/overview-widget.component';
import { SourceIdsNamePipe } from '@modules/mission-control/pipes/source-ids.pipe';
import { LocationAreaNamePipe } from '@modules/mission-control/pipes/local-area-ids.pipe';
import { CategoryApiService } from '@modules/mission-control/services/category/category-api.service';
import { LinkedIncidentFilterComponent } from '@modules/mission-control/components/linked-incident-filter/linked-incident-filter.component';
import { IncidentCardComponent } from '@modules/mission-control/components/incident-card/incident-card.component';
import { TimezoneConversionPipe } from '@modules/mission-control/pipes/timezone-conversion.pipe';
import { IncidentApiService } from './services/incident/incident-api.service';
import { IncidentSelectionService } from './services/incident/incident-selection.service';
import { McSettingService } from './services/mc-setting/mc-setting.service';
import { IncidentRecipientModalComponent } from './components/incident-recipient/incident-recipient-modal.component';
import { IncidentCommandService } from './services/incident/incident-command.service';
import { FilterOwnerComponent } from './components/filters/filter-owner/filter-owner.component';
import { IncidentFilterComponent } from './components/incident-filter/incident-filter.component';
import { McUserService } from './services/mc-user.service';
import { UserNamePipe } from './pipes/user-name.pipe';
import { IncidentsTrayComponent } from './components/incidents-tray/incidents-tray.component';
import { IncidentsListComponent } from './components/incidents-list/incidents-list.component';
import { IncidentCommandProvider } from './services/incident-command-provider';
import { IncidentService } from './services/incident/incident.service';
import { McClient } from './services/mc-client.service';
import { PriorityNamePipe } from './pipes/priority-name.pipe';
import { StateNamePipe } from './pipes/state-name.pipe';
import { PriorityService } from './services/priority/priority.service';
import { StateService } from './services/state/state.service';
import { LocationNamePipe } from './pipes/location-name/location-name.pipe';
import { IncidentLocationService } from './services/incident-location/incident-location.service';
import { IncidentTypeService } from './services/incident-type.service';
import { McNotificationService } from './services/mc-notification.service';
import { IconService } from './services/icons/icon.service';
import { IncidentActionService } from './services/incident-actions/incident-actions.service';
import { LocationIconPipe } from './pipes/location-icon.pipe';
import { IncidentNotificationComponent } from './components/incident-notification/incident-notification.component';
import { IncidentCountService } from './services/incident-count/incident-count.service';
import { IncidentMapSelectorService } from './services/incident-map-selector/incident-map-selector.service';
import { ProcedureWidgetComponent } from './components/procedure-widget/procedure-widget.component';
import { ProcedureService } from './services/procedures/procedure.service';
import { IncidentEventService } from './services/events/incident-event.service';
import { ActivityWidgetComponent } from './components/activity-widget/activity-widget.component';
import { TriggerIncidentModalComponent } from './components/trigger-incident-modal/trigger-incident-modal.component';
import { McActionService } from './services/action/mc-action.service';
import { IncidentCommentComponent } from './components/incident-comment/incident-comment.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IncidentSidePaneHeaderComponent } from './components/incident-sidepane-header/incident-sidepane-header.component';
import { IncidentIconComponent } from './components/incident-icon/incident-icon.component';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@NgModule({
    imports: [CommonModule, SharedModule, GenAngularModule, NgxsModule, InfiniteScrollModule, ReactiveFormsModule],
    declarations: [
        FilterOwnerComponent,
        IncidentCardComponent,
        IncidentFilterComponent,
        IncidentNotificationComponent,
        IncidentRecipientModalComponent,
        IncidentsTrayComponent,
        IncidentsListComponent,
        LinkedIncidentWidgetComponent,
        LinkedIncidentFilterComponent,
        TriggerIncidentModalComponent,
        LocationIconPipe,
        LocationNamePipe,
        PriorityNamePipe,
        SourceIdsNamePipe,
        LocationAreaNamePipe,
        TimezoneConversionPipe,
        ProcedureWidgetComponent,
        ActivityWidgetComponent,
        OverviewWidgetComponent,
        QuickActionWidgetComponent,
        StateNamePipe,
        UserNamePipe,
        IncidentCommentComponent,
        IncidentSidePaneHeaderComponent,
        IncidentIconComponent,
    ],
    providers: [
        ActivityEventService,
        IconService,
        IncidentActionService,
        IncidentCommandProvider,
        IncidentCommandService,
        IncidentCountService,
        IncidentEventService,
        McActionService,
        IncidentLocationService,
        IncidentMapSelectorService,
        IncidentService,
        IncidentApiService,
        IncidentSelectionService,
        IncidentTypeService,
        LinkedIncidentService,
        McClient,
        McUserService,
        McNotificationService,
        PriorityService,
        ProcedureService,
        StateService,
        McSettingService,
        CategoryApiService,
        UserNamePipe,
        StateNamePipe,
        LocationNamePipe,
        PriorityNamePipe,
        SourceIdsNamePipe,
        LocationAreaNamePipe,
        AsyncPipe,
        DatePipe,
        LinkedIncidentController,
    ],
})
export class MissionControlModule {
    constructor(incidentCommandProvider: IncidentCommandProvider) {}
}
