import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    //#region Constructor
    constructor(private authService: AuthService) {}

    //#endregion

    //#region Public Methods
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.loggedIn$.pipe(
            map((isLoggedIn: boolean) => {
                // Redirects if the user is loggedIn.
                this.authService.loginRedirectUrl = state.url;
                // Indicate if we're loggedIn or not.
                return isLoggedIn;
            }),
            catchError((err) => {
                return of(false);
            }),
            untilDestroyed(this)
        );
    }

    //#endregion
}
