import { FragmentShader } from './FragmentShader';
import { VertexShader } from './VertexShader';

export class CompiledProgram {
  protected readonly m_gl: WebGL2RenderingContext;
  protected readonly m_program: WebGLProgram;
  private readonly m_vertexShader: VertexShader;
  private readonly m_fragmentShader: FragmentShader;

  public get Program(): WebGLProgram {
    return this.m_program;
  }

  public get VertexShaderSource(): string {
    return this.m_vertexShader.Source;
  }

  public get FragmentShaderSource(): string {
    return this.m_fragmentShader.Source;
  }

  public constructor(gl: WebGL2RenderingContext, program: WebGLProgram, vertexShader: VertexShader, fragmentShader: FragmentShader) {
    this.m_gl = gl;
    this.m_program = program;
    this.m_vertexShader = vertexShader;
    this.m_fragmentShader = fragmentShader;
  }

  public dispose() {
    this.m_vertexShader.dispose();
    this.m_fragmentShader.dispose();
    this.m_gl.deleteProgram(this.Program);
  }
}
