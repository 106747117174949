// Autogenerated: v2/help/Entities/ApiOutputDevice?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { IGuid } from 'safeguid';
import { FieldObject } from '../../../Helpers/FieldObject';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IRestResponse } from '../../Interface/IRestResponse';
import { ITransaction } from '../../Interface/ITransaction';
import { IOutputDeviceEntity, OutputDeviceEntityFields } from '../../Interface/IOutputDeviceEntity';
import { DeviceEntity } from './DeviceEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';

export class OutputDeviceEntity extends DeviceEntity implements IOutputDeviceEntity {
    // #region REST Properties

    // OutputState
    public get outputState(): string {
        return this.getField<string>(OutputDeviceEntityFields.outputStateField, ValidFlags.EntityStatus);
    }
    public set outputState(value: string) {
        this.setField<string>(OutputDeviceEntityFields.outputStateField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async triggerOutputAsync(outputBehaviorId: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('outputBehaviorId', outputBehaviorId);

        return this.executeRequestTransactionAsync<IRestResponse>('TriggerOutput', 'POST', inParam, transaction, responseHandler);
    }

    // #endregion REST Methods

}

