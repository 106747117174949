import { Component, OnDestroy, OnInit } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { ZoneContentTypes } from '@modules/general/enumerations/zone-content-types';
import { IZoneEntity, ZoneEntityFields } from 'RestClient/Client/Interface/IZoneEntity';
import { ZoneEntity } from 'RestClient/Client/Model/AccessControl/ZoneEntity';
import { Observable, Subject } from 'rxjs';

export const isContentSupported = (content: Content, pluginType: IGuid): boolean => {
    if (content?.type?.equals(ZoneContentTypes.Zone) && pluginType.equals(PluginTypes.Tile)) {
        // only display the zone controls in tiles if we don't have anything else to display
        return !content.parameters?.getField<boolean>('HasAttachedData');
    }
    return false;
};

@Component({
    selector: 'app-zone-state',
    templateUrl: './zone-state.component.html',
    styleUrls: ['./zone-state.component.scss'],
})
@InternalContentPluginDescriptor({
    type: ZoneStateComponent,
    pluginTypes: [PluginTypes.Tile],
    exposure: { id: ZoneStateComponent.pluginId, priority: 0 },
    isContentSupported,
})
export class ZoneStateComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('E638751E-BA7E-4FBE-BA4C-5A98B1D8BCDC');
    public content?: Content;
    public dataContext: unknown;
    public zoneEntityId?: string;
    public zone$: Observable<IZoneEntity | null>;
    public readonly parameterIcon = Icon.Perimeter;
    private entityCacheTask!: IEntityCacheTask;

    private scClient: WebAppClient | null = null;

    private zoneSubject = new Subject<IZoneEntity | null>();

    constructor(private securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService) {
        super(trackingService);
        this.zone$ = this.zoneSubject.asObservable();
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();
        await this.initializeEntityCache();
    }

    public async ngOnDestroy(): Promise<void> {
        await this.entityCacheTask?.dispose();
        super.ngOnDestroy();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
        this.zoneEntityId = content.source;
    }

    private async initializeEntityCache() {
        this.scClient = this.securityCenterClientService?.scClient;
        if (this.scClient) {
            this.entityCacheTask = this.scClient.buildEntityCache([ZoneEntityFields.customIconIdField]);

            if (this.entityCacheTask && this.content) {
                this.zoneSubject.next(await this.entityCacheTask.getEntityAsync<ZoneEntity, IZoneEntity>(ZoneEntity, SafeGuid.parse(this.content.source), true));
            }
        }
    }
}
