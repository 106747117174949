import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { LogonInfo } from 'RestClient/Client/Parameters/LogonInfo';
import { RestResponse } from 'RestClient/Connection/RestResponse';
import { CancellationToken } from 'RestClient/Helpers/Helpers';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { LogonState } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { RestConnection } from 'RestClient/Connection/RestConnection';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import { SystemConfigurationEntity } from 'RestClient/Client/Model/SystemConfigurationEntity';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { UserTokenData } from 'RestClient/Client/Parameters/UserTokenData';
import { LogSeverity } from 'RestClient/Client/Enumerations/LogSeverity';
import { WebAppConfig, IWebAppConfig } from './Model/WebAppRoleconfig';
import { LicenseDetails } from './LicenseDetails';
import { Globals as WebAppGlobals } from './Globals';
import { WebAppConnection } from './WebAppConnection';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { IVersionInfo } from "../../genetec-webapp-clientapp/src/app/modules/shared/api/api";
import { WrapperFactory } from "./RestWrappers/WrapperFactory";
import { ISecurityCenterClient } from "RestClient/Client/Interface/ISecurityCenterClient";
import { Entity } from "RestClient/Client/Model/Entity";

export class WebAppRegistrationInfo {
    public uniqueId = '';
    public machineName = '';
    public language = 'en';
    public machineId = SafeGuid.newGuid().toString();

    constructor(uniqueId: string) {
        this.uniqueId = uniqueId;
    }
}

export class TokenIdentityClaims {
    public userId = '';
    public userName = '';
    public grantedPrivileges: Set<string> = new Set<string>();
    public expiration: Date = new Date();
}

export class UserTokenDataWithClaims extends UserTokenData {
    public claims: TokenIdentityClaims | undefined;
}

export class WebAppClient extends SecurityCenterClient implements ISecurityCenterClient{
    // #region Fields
    private _applicationId = SafeGuid.EMPTY;
    private _roleId = SafeGuid.EMPTY;
    private _serverId = SafeGuid.EMPTY;
    private _userId = SafeGuid.EMPTY;
    private _userLogonLanguage = '';
    private _registrationFailReason = '';
    private _registrationFailResponse = '';
    private _roleConfig: IWebAppConfig | undefined = undefined;
    private _licenseDetails: LicenseDetails | undefined = undefined;
    private _grantedGlobalPrivileges: Set<string> | undefined;
    private _afterLogonCallbacks: (() => Promise<void>)[] = [];
    private _postLogonFailResponse: IRestResponse | undefined;
    private _securityCenterVersion: IVersionInfo | null = null;

    // #endregion

    // #region Properties
    public get additionalHeaders(): Map<string, string> {
        return this._additionalHeaders;
    }

    public get applicationId(): IGuid {
        return this._applicationId;
    }

    public get securityCenterVersion(): IVersionInfo | null {
        return this._securityCenterVersion;
    }

    public get roleId(): IGuid {
        return this._roleId;
    }

    public get serverId(): IGuid {
        return this._serverId;
    }

    public get userId(): IGuid {
        return this._userId;
    }

    /** Returns the language of the user immediately after logon. */
    public get userLogonLanguage(): string {
        return this._userLogonLanguage;
    }

    public get registrationFailReason(): string {
        return this._registrationFailReason;
    }

    public get registrationFailResponse(): string {
        return this._registrationFailResponse;
    }

    public get isWebSocket(): boolean {
        if (this._rest !== null) {
            return this._rest.isWebSocket;
        }
        return false;
    }

    public get roleConfig(): IWebAppConfig | undefined {
        return this._roleConfig;
    }

    public get license(): LicenseDetails | undefined {
        return this._licenseDetails;
    }

    public registrationInfo = new WebAppRegistrationInfo('');
    public enableWebSocket = true;
    public enableSSE = true;
    public windowsAuthentificationEnabled = false;

    // #endregion

    // #region Constructor

    constructor(registrationInfo: WebAppRegistrationInfo) {
        super();
        this.registrationInfo = registrationInfo;
        this.allowImpersonate = true;

        WrapperFactory.initialize();
    }

    // #endregion

    // #region Public methods public

    public createLocalEntity<T extends Entity & U, U extends IEntity>(classType: new () => U, id: IGuid): U {
        return WrapperFactory.wrap(super.createLocalEntity(classType, id));
    }

    public async logonAsync(logonInfo: LogonInfo, cancelToken?: CancellationToken): Promise<IRestResponse> {
        this._postLogonFailResponse = undefined;
        this.addAdditionalHeader(WebAppGlobals.webAppUniqueIdHeader, this.registrationInfo.uniqueId);
        const response = await super.logonAsync(logonInfo, cancelToken);
        if (response.statusCode === HttpStatusCode.OK) {
            if (this.applicationId.toString() === SafeGuid.EMPTY.toString()) {
                return new RestResponse(HttpStatusCode.INTERNAL_SERVER_ERROR, this.registrationFailReason);
            }

            // sometimes errors only occurs when renewing the token, so advise when this happens
            if (this._postLogonFailResponse) {
                return this._postLogonFailResponse;
            }
        }
        return response;
    }

    public pauseConnectionAsync(): Promise<void> | undefined {
        if (this._rest) {
            return this._rest.pauseConnectionAsync();
        }
    }

    public async logoffAsync(): Promise<void> {
        try {
            await this._rest?.executeRequestAsync('POST', '/api/Session/Logoff', null);
        } catch (e) {
            // Catch...
        }
        await super.logoffAsync();
    }

    public async getIsAuthenticatedWithOpenIdAsync(): Promise<boolean> {
        if (!this._rest) return Promise.resolve(false);
        const response = await this._rest.executeRequestAsync('GET', '/api/Session/IsAuthenticatedWithOpenId', null);
        return response?.body;
    }

    public isGlobalPrivilegeGranted(privilege: IGuid): boolean {
        if (!this._grantedGlobalPrivileges) {
            return false;
        }

        return this._grantedGlobalPrivileges.has(privilege.toString());
    }

    public async afterLogonOperationAsync(logonState: LogonState, cancelToken?: CancellationToken): Promise<void> {
        this.addAdditionalHeader('WebAppSessionIdHeader', this.rest.streamerConnectionId);
        try {
            await this.fetchScVersion();
            await this.registerApplicationAsync(cancelToken);
            await this.initClientData();
            await this.renewTokenAsync();
        } catch (exception: any) {
            const response = exception.response as RestResponse;
            if (response) {
                this._postLogonFailResponse = response;
            }
            throw exception;
        }

        for (const callback of this._afterLogonCallbacks) {
            try {
                await callback();
            } catch (exception: any) {
                this.diagnosticManager?.sendTraceAsync(LogSeverity.Error, this.debugAppName, 'After logon callback error', exception);
            }
        }
    }

    public getAdvancedSetting<T extends string | number | boolean>(key: string, defaultValue: T): T {
        const value = this.roleConfig?.advancedSettings.firstOrDefault((item: { key: string }) => item.key.toLocaleLowerCase() === key.toLocaleLowerCase())?.value;
        if (value) {
            switch (typeof defaultValue) {
                case 'string':
                    return value as T;
                case 'number':
                    if (isNaN(new Number(value).valueOf())) {
                        return defaultValue;
                    }
                    return new Number(value).valueOf() as T;
                case 'boolean':
                    if (value.toLowerCase() === 'true') return true as T;
                    if (value.toLowerCase() === 'false') return false as T;
                    return defaultValue;
            }
        }

        return defaultValue;
    }

    // adds a callback to be called just before the app is considered logged on
    public addLogonInterceptCallback(callback: () => Promise<void>): void {
        this._afterLogonCallbacks.push(callback);
    }

    // #endregion

    // #region Protected

    protected buildRestConnection(url: string, username: string | null, password: string | null, authToken: string | null): RestConnection {
        const validatedUrl = this.validateUrl(url);

        const newConnection = new WebAppConnection(this);
        newConnection.enableWebSocket = this.enableWebSocket;
        newConnection.enableSSE = this.enableSSE;
        newConnection.setupConnection(validatedUrl, username, password, authToken);

        if (this._rest != null) {
            newConnection.retryCount = this._rest.retryCount;
            newConnection.retryDelay = this._rest.retryDelay;
        }

        for (const [key, value] of this._additionalHeaders) {
            newConnection.addAdditionalHeader(key, value, false);
        }
        return newConnection;
    }

    protected readUserTokenData(response: RestResponse): UserTokenDataWithClaims {
        const result = new UserTokenDataWithClaims();
        result.token = response.body['Token'];
        result.validUntil = new Date(response.body['ValidUntil']);
        result.millisecondsValid = response.body['MillisecondsValid'];

        const claims = new TokenIdentityClaims();
        claims.expiration = response.body['Claims']['Expiration'];
        claims.grantedPrivileges = new Set<string>(response.body['Claims']['GrantedPrivileges']);
        claims.userId = response.body['Claims']['UserId'];
        claims.userName = response.body['Claims']['UserName'];
        result.claims = claims;

        return result;
    }

    protected async applyTokenAsync(token: UserTokenData): Promise<void> {
        await super.applyTokenAsync(token);

        if (token instanceof UserTokenDataWithClaims) {
            this._grantedGlobalPrivileges = token.claims?.grantedPrivileges;
        }
    }

    // #endregion

    // #region Private methods

    private async registerApplicationAsync(cancelToken?: CancellationToken) {
        const webAppConnection = this._rest as WebAppConnection;

        const payload = new FieldObject();
        payload.setField('UniqueId', this.registrationInfo.uniqueId);
        payload.setField('MachineName', this.registrationInfo.machineName);
        payload.setField('Language', this.registrationInfo.language);
        payload.setField('MachineId', this.registrationInfo.machineId);
        const registrationResult = await webAppConnection.streamer.getObjectInvokeOnStreamerAsync<FieldObject>(FieldObject, 'RegisterApplication', payload.toJson());
        this._applicationId = registrationResult.getFieldGuid('ApplicationId');
        this._roleId = registrationResult.getFieldGuid('RoleId');
        this._serverId = registrationResult.getFieldGuid('ServerId');
        this._userId = registrationResult.getFieldGuid('UserId');
        this._userLogonLanguage = registrationResult.getField<string>('UserLanguage');
        this._registrationFailReason = registrationResult.getField<string>('RegistrationFailReason');
        this._registrationFailResponse = registrationResult.getField<string>('RegistrationFailResponse');
    }

    // here we do 2 requests for all init data, one for the entities and one for the data itself,
    // we keep it inside the client for furthur usage
    private async initClientData() {
        this._roleConfig = await this.fetchRoleConfig();

        const systemConfig = (await this.getEntityAsync(SystemConfigurationEntity, Guids.SystemConfiguration)) as SystemConfigurationEntity;
        if (systemConfig) {
            const response = await systemConfig.getLicenseDetailsAsync();
            if (response) {
                this._licenseDetails = new LicenseDetails(response);
            }
        }
    }

    private async fetchRoleConfig(): Promise<WebAppConfig> {
        const roleConfig = new WebAppConfig();
        // Call straight the role instead of Wrapper since the role entity might be not accessible
        const response = await this.rest.executeRequestAsync('GET', 'api/info/roleConfig', null);
        if (response.statusCode === HttpStatusCode.OK) {
            roleConfig.loadFields(response.body);
        }

        return roleConfig;
    }

    private async fetchScVersion() {
        const response = await this.rest.executeRequestAsync('GET', 'api/version/versionInfo', null);
        if (response.statusCode === HttpStatusCode.OK) {
            this._securityCenterVersion = response.body as IVersionInfo;
        }
    }

    // #endregion
}
