import { EventEmitter, NgZone } from '@angular/core';

export class RenderingHelper {
    public static onRefresh: EventEmitter<void> = new EventEmitter<void>();

    private static initialized = false;
    private static zone: NgZone;
    private static invalidated = false;

    // initialize the helper
    public static initialize(zone: NgZone): void {
        RenderingHelper.zone = zone;
        RenderingHelper.initialized = true;

        zone.runOutsideAngular(() => {
            setInterval(() => RenderingHelper.onTick(), 500);
        });
    }

    // inform the RenderingHelper that we received a frame
    public static invalidate(): void {
        if (!RenderingHelper.initialized) {
            throw new Error('Not initialized');
        }
        RenderingHelper.invalidated = true;
    }

    private static onTick() {
        if (RenderingHelper.invalidated) {
            // invoke the callback in the Angular zone
            this.zone.runGuarded(() => {
                RenderingHelper.onRefresh.emit();
                RenderingHelper.invalidated = false;
            });
        }
    }
}
