// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class Settings {
    public isAvailable = true;
    public data: Map<string, any> = new Map<string, any>();

    public clone(): Settings {
        const holder = new Settings();
        holder.isAvailable = this.isAvailable;
        holder.data = new Map<string, any>(this.data);
        return holder;
    }

    public get<T>(key: string): T {
        return this.data.get(key.toLowerCase()) as T;
    }

    public set<T>(key: string, value: T): void {
        this.data.set(key.toLowerCase(), value);
    }
}

// Dictionary where the key is the name of the setting
export type SettingsMap = Map<string, Settings>;
