import { Injectable } from "@angular/core";
import { LoggerService } from "@modules/shared/services/logger/logger.service";
import { isArray } from "lodash-es";

export class McSetting {
    public static eventFilter = "MC_EventFilter";
}

export class McSettingNameError extends Error {
}

export class McSettingTypeError extends Error {
}

@Injectable()
export class McSettingService {

    constructor(private _logger: LoggerService) {}

    public getEventFilter(): Array<string>  {
        try {
            const eventFilter = this.get<Array<string>>(McSetting.eventFilter);
            if (!isArray(eventFilter))
                throw new McSettingTypeError();
            return eventFilter;
        }
        catch(error) {
            if (error instanceof McSettingNameError) return Array();
            else if (error instanceof McSettingTypeError) this._logger.traceError(`EventFilter setting has invalid type`);
            else throw error;
        }
        return Array();
    }

    public set<T>(settingName: string, objectToStore: T): void {
        sessionStorage.setItem(settingName, JSON.stringify(objectToStore));
    }

    public doesSettingExist = (settingName: string): boolean => !!sessionStorage.getItem(settingName);

    private get<T>(settingName: string): T {
        const setting: any = sessionStorage.getItem(settingName);
        if (setting) {
            return JSON.parse(setting) as T;
        }
        throw new McSettingNameError(`Setting name ${settingName} doesn't exist`);
    }
}
