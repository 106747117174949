import { Component, Input } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { IconService } from '@modules/mission-control/services/icons/icon.service';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IGuid } from 'safeguid';

/**
 * Component used to display an incident icon with the Gelato Incident Icon (⚡) being defaulted if iconId is null
 */
@Component({
    selector: 'app-incident-icon',
    templateUrl: './incident-icon.component.html',
})
export class IncidentIconComponent {
    @Input() public set iconId(value: IGuid | null) {
        this.incidentSubject.next(value);
    }

    @Input() public width = '100%';
    @Input() public height = '100%';

    private incidentSubject = new BehaviorSubject<IGuid | null>(null);

    public incident$ = this.incidentSubject.asObservable();

    public icon$ = this.incident$.pipe(switchMap((iconId) => (iconId ? this.iconService.getIcon(iconId, '') : of(''))));

    public Icon = Icon;

    constructor(private iconService: IconService) {}
}
