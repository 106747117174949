import { ShaderBuilder } from './ShaderBuilder';

export class FragmentShader {
  private readonly m_gl: WebGL2RenderingContext;
  private readonly m_shader: WebGLShader;
  private readonly m_source: string;

  public get Source(): string {
    return this.m_source;
  }

  public get Shader(): WebGLShader {
    return this.m_shader;
  }

  constructor(gl: WebGL2RenderingContext, fragmentShaderCode: string, floatPrecision: FloatPrecision) {
    this.m_gl = gl;

    this.m_source = `${ShaderBuilder.m_version300es}
${this.buildFloatPrecision(floatPrecision)}
${fragmentShaderCode}`;

    const shaderBuilder = new ShaderBuilder(gl);
    this.m_shader = shaderBuilder.buildFragmentShader(this.m_source);
  }

  public dispose() {
    this.m_gl.deleteShader(this.m_shader);
  }

  private buildFloatPrecision(floatPrecision: FloatPrecision): string {
    return `precision ${floatPrecision} float;`;
  }
}

export enum FloatPrecision {
  low = 'lowp',
  medium = 'mediump',
  high = 'highp'
}
