import { Component, Input, OnInit } from '@angular/core';
import { CalendarFlavor, CalendarTimeFrame, GenMeltedItem } from '@genetec/gelato-angular';
import { FilterContext } from '@modules/shared/services/filters/filter';
import { stringFormat } from '@shared/utilities/StringFormat';
import moment from 'moment';
import { MAX_INT32 } from 'src/app/modules/shared/constants/numbers';
import { FilterBaseComponent } from '../filter-base.component';
import { DateFilterMode } from './enums/date-filter-mode.enum';
import { DateFilterPeriod } from './enums/date-filter-period.enum';
import { FilterDateModel } from './filter-date.model';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 * Filter restraining by date
 *
 * @example 3 modes are available : Any date, last period range, calendar time frame
 */
@Component({
    selector: 'app-filter-date',
    templateUrl: './filter-date.component.html',
    styleUrls: ['./filter-date.component.scss'],
})
export class FilterDateComponent extends FilterBaseComponent<FilterDateModel> implements OnInit {
    //#region Statics
    private static readonly defaultDuration = 1;
    private static readonly defaultTimePeriod = DateFilterPeriod.Days;
    //#endregion

    //#region Decorated
    @Input() public calendarTimeFrame = CalendarTimeFrame.All;
    @Input() public calendarFlavor = CalendarFlavor.Date;

    //#endregion

    //#region Public fields
    public showDuringTheLastPeriod = false;
    public showForTheNextPeriod = false;

    public periodItems: Array<GenMeltedItem> = [];
    public dateFilterMode = DateFilterMode;
    public mode = DateFilterMode.Any;
    public duration = FilterDateComponent.defaultDuration;
    public period?: GenMeltedItem;
    public timeStart?: string;
    public timeEnd?: string;
    public invalidDateRange = false;
    public durationWarning = '';
    //#endregion

    //#region Public methods
    ngOnInit() {
        super.ngOnInit();
        this.initializePeriodItems();
        this.showDuringTheLastPeriod = this.calendarTimeFrame !== CalendarTimeFrame.Future;
        this.showForTheNextPeriod = this.calendarTimeFrame !== CalendarTimeFrame.Past;
        this.clearFilter();
    }

    public onFilterChange(newMode: DateFilterMode): void {
        this.mode = newMode;

        this.timeStart = undefined;
        this.timeEnd = undefined;

        switch (this.mode) {
            case DateFilterMode.Range:
                this.timeStart = moment.utc(moment().subtract(1, 'day').startOf('day')).toISOString();
                this.timeEnd = moment.utc(moment().endOf('day')).toISOString();
                break;
        }

        this.updateValue();
    }

    public onDurationChange(newDuration: number): void {
        this.duration = Math.min(newDuration, MAX_INT32);
        // Int32 is used in the web server so if we have a bigger period, it may result in an overflow
        this.durationWarning = this.duration === MAX_INT32 ? `${this.translateService.instant('STE_MESSAGE_WARNING_DURATION_USING_MAX_VALUE') as string}` : '';
        this.updateValue();
    }

    public onPeriodChange(newPeriod: GenMeltedItem): void {
        this.period = newPeriod;
        this.updateValue();
    }

    public onTimeStartChange(newDate: string): void {
        if (!moment(newDate).isValid()) return; // Gelato isValidDate is not working so we need to validate directly with moment
        this.timeStart = newDate;
        this.updateValue();
    }

    public onTimeEndChange(newDate: string): void {
        if (!moment(newDate).isValid()) return; // Same as previous comment
        this.timeEnd = newDate;
        this.updateValue();
    }

    public onSpecificDateTimeClicked(event: MouseEvent, isStart: boolean): void {
        const target = event.target as HTMLElement;
        const rowCount = target?.getAttribute('ng-reflect-gen-row-count');

        // Temporary until Gelato's bug is fixed (https://dev.azure.com/genetecux/UXDev/_workitems/edit/829)
        // Without this workaround, the calendar closes when the user clicks a year or a month.

        // 67 is the years view, and 4 is the months view
        if (rowCount === '67' || rowCount === '4') {
            event.stopPropagation();
        }
    }

    public updateState(): void {
        let selection = this.state.selection;

        switch (this.mode) {
            case DateFilterMode.Any:
                selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
                break;
            case DateFilterMode.DuringLastPeriod:
                selection = stringFormat((this.translateService.instant('STE_LABEL_DURING_THE_LAST_X_Y') as string) ?? '', this.duration.toString(), this.period?.text || '');
                break;
            case DateFilterMode.ForNextPeriod:
                selection = stringFormat((this.translateService.instant('STE_LABEL_DURING_THE_NEXT_X_Y') as string) ?? '', this.duration.toString(), this.period?.text || '');
                break;
            case DateFilterMode.Range:
                selection = this.translateService.instant('STE_LABEL_SPECIFICDATE') as string;
                break;
        }

        // Error if invalid date; Warning if the filter covers all timeline
        const status = this.invalidDateRange ? 'Error' : this.isCoveringAllTimelineInReportsContext() ? 'Warning' : 'Ok';
        const message = status === 'Warning' ? (this.translateService.instant('STE_LABEL_WARNING_DATE_FILTER_SEARCH_TOO_LONG') as string) : '';

        this.state = { status, selection, message };
    }

    public clearFilter(): void {
        this.mode = DateFilterMode.Any;
        this.period = this.periodItems.find((x) => x.id === FilterDateComponent.defaultTimePeriod);
        this.timeStart = this.timeEnd = undefined;
        this.duration = FilterDateComponent.defaultDuration;
        this.updateValue();
    }

    public isDefaulted(): boolean {
        return this.mode === DateFilterMode.Any;
    }

    public override isDirty(firstValue: FilterDateModel, secondValue: FilterDateModel): boolean {
        if (firstValue.isAbsolute !== secondValue.isAbsolute) {
            return true;
        }

        if (firstValue.isAbsolute) {
            if (firstValue.timeEnd === secondValue.timeEnd && firstValue.timeStart === secondValue.timeStart) {
                return false;
            }
        } else {
            if (firstValue.quanta === secondValue.quanta && firstValue.timeUnit === secondValue.timeUnit) {
                return false;
            }
        }

        return true;
    }

    public override getDefaultValue(): FilterDateModel {
        return {
            // the default value of FilterDate is DateFilterMode.Any
            // DateFilterMode.Any corresponds to isAbsolute (true), timeStart (undefined) and timeEnd (undefined)
            isAbsolute: true,
            timeStart: undefined,
            timeEnd: undefined,
            quanta: -1, // can be anything
        };
    }

    //#endregion

    //#region Private methods

    private updateValue() {
        this.validateDateRange();

        const isAbsolute = this.mode === DateFilterMode.Range || this.mode === DateFilterMode.Any;

        this.value = {
            timeStart: this.timeStart,
            timeEnd: this.timeEnd,
            quanta: this.mode === DateFilterMode.DuringLastPeriod ? -this.duration : this.duration,
            isAbsolute,
            timeUnit: this.period?.id,
        };
    }

    private isCoveringAllTimelineInReportsContext() {
        return this.isPrimary && this.isDefaulted() && this.filterCoordinatorService.context === FilterContext.Reports;
    }

    private validateDateRange() {
        //Validate the date range according to current mode
        if (this.timeStart && this.timeEnd) {
            //In calendar mode, check if dates are ordered correctly
            this.invalidDateRange = Date.parse(this.timeStart) > Date.parse(this.timeEnd);
        } else if (this.mode === DateFilterMode.DuringLastPeriod || this.mode === DateFilterMode.ForNextPeriod) {
            //In Last/Next Period mode, check if the duration frame is strictly positive and an integer
            this.invalidDateRange = this.duration < 1 || this.duration % 1 !== 0;
        } else if (this.mode === DateFilterMode.Any) {
            this.invalidDateRange = false;
        }
    }

    private initializePeriodItems() {
        this.periodItems = [
            {
                id: DateFilterPeriod.Seconds,
                text: this.translateService.instant('STE_UNIT_SECONDS_LC') as string,
            },
            {
                id: DateFilterPeriod.Minutes,
                text: this.translateService.instant('STE_UNIT_MINUTES_LC') as string,
            },
            {
                id: DateFilterPeriod.Hours,
                text: this.translateService.instant('STE_UNIT_HOURS_LC') as string,
            },
            {
                id: DateFilterPeriod.Days,
                text: this.translateService.instant('STE_UNIT_DAYS_LC') as string,
            },
            {
                id: DateFilterPeriod.Weeks,
                text: this.translateService.instant('STE_UNIT_WEEKS_LC') as string,
            },
            {
                id: DateFilterPeriod.Months,
                text: this.translateService.instant('STE_UNIT_MONTHS_LC') as string,
            },
            {
                id: DateFilterPeriod.Years,
                text: this.translateService.instant('STE_UNIT_YEARS_LC') as string,
            },
        ];

        this.period = this.periodItems.find((x) => x.id === FilterDateComponent.defaultTimePeriod);
    }

    //#endregion
}
