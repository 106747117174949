import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';

export class LicenseDetails {
    constructor(private _fieldObject: IFieldObject) {}

    public hasLicense(license: string): boolean {
        return this._fieldObject.getField<boolean>(license) ?? false;
    }

    public get version(): number {
        return this._fieldObject.getField<number>('Version') ?? 0;
    }
}
