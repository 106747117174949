import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, TextFlavor, SelectionType } from '@genetec/gelato';
import { ModalEntitiesSelectorComponent, SelectedEntity } from '@modules/shared/components/entity-browser/modal-entities-selector/modal-entities-selector.component';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { IconsService } from '@modules/shared/services/icons.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { GuidSet, IGuid, SafeGuid } from 'safeguid';
import { concatMap, filter, scan, take, tap, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { IWatchlist, IWatchlistEntry, Watchlist, WatchlistPriority } from '@modules/shared/api/api';
import { UserWatchlistService } from '@modules/shared/services/watchlist/user-watchlist.service';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { EventMonitoringFeatureFlags } from '@modules/shared/services/events/feature-flags';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { EventMonitoringContentTypes } from '@modules/shared/enumerations/event-monitoring-content-types';
import { EventMonitoringContext } from '@modules/shared/enumerations/analytics-event-monitoring-context';
import { EventMonitoringLoggerService } from '@modules/shared/services/analytics/event-monitoring-logger.service';
import { EventsService } from '@modules/shared/services/events/events.service';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { StateItem } from '@modules/shared/components/entity-state-item/state-item/state-item';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

export class WatchlistItem implements StateItem {
    constructor(
        public guid: IGuid,
        public name: string,
        public flagged: boolean,
        public icon: Icon,
        public state: string,
        public maintenance: boolean,
        public customIconId?: IGuid
    ) {}

    get hasWarnings(): boolean {
        return this.state === RunningState.Warning;
    }
    get isInMaintenance(): boolean {
        return this.maintenance;
    }
    get isOffline(): boolean {
        return this.state === RunningState.NotRunning;
    }
}

@UntilDestroy()
@InternalContentPluginDescriptor({
    type: WatchlistEditorComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: WatchlistEditorComponent.pluginId, priority: 0, standalone: true },
    isContentSupported: (content: Content) => !!content?.type?.equals(EventMonitoringContentTypes.EditWatchList),
    requirements: { enabledFeatureFlags: [EventMonitoringFeatureFlags.General] },
})
@Component({
    selector: 'app-watchlist-editor',
    templateUrl: './watchlist-editor.component.html',
    styleUrls: ['./watchlist-editor.component.scss'],
})
export class WatchlistEditorComponent implements AfterViewInit {
    public static pluginId = SafeGuid.parse('70737118-bcda-4526-8a44-9362e8777645');
    @ViewChild('entitiesSelector') public entitiesSelector?: ModalEntitiesSelectorComponent;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;
    public readonly SelectionType = SelectionType;

    public entities: WatchlistItem[] = [];
    public isLoading = true;

    public entityBrowserFilter$: Observable<EntityBrowserFilter>;

    private watchedEntities$ = new BehaviorSubject<IGuid[]>([]);
    private entityCacheTask: IEntityCacheTask;
    private selectedEntitiesCache$ = new BehaviorSubject<SelectedEntity[]>([]);

    constructor(
        private userWatchlistService: UserWatchlistService,
        private securityCenterClientService: SecurityCenterClientService,
        private iconsService: IconsService,
        private eventsService: EventsService,
        private eventMonitoringLoggerService: EventMonitoringLoggerService
    ) {
        this.entityCacheTask = this.securityCenterClientService.scClient.buildEntityCache([
            EntityFields.nameField,
            EntityFields.entityTypeField,
            EntityFields.customIconIdField,
            EntityFields.runningStateField,
            EntityFields.maintenanceField,
        ]);

        this.userWatchlistService.added$
            .pipe(
                withLatestFrom(this.selectedEntitiesCache$),
                concatMap(([watchlistEntries, entityCache]) => this.onUserWatchlistAdded(watchlistEntries, entityCache)),
                tap(() => this.updateWatchedEntities()),
                untilDestroyed(this)
            )
            .subscribe();

        this.userWatchlistService.watchlist$
            .pipe(
                take(1),
                concatMap((watchlist) => this.initializeUserWatchlistAsync(watchlist)),
                tap(() => this.updateWatchedEntities()),
                untilDestroyed(this)
            )
            .subscribe();

        this.userWatchlistService.removed$
            .pipe(
                tap((item) => this.onUserWatchlistRemoved(item)),
                tap(() => this.updateWatchedEntities()),
                untilDestroyed(this)
            )
            .subscribe();

        this.userWatchlistService.modified$.pipe(untilDestroyed(this)).subscribe((item) => this.onUserWatchlistModified(item));

        this.entityBrowserFilter$ = combineLatest([this.eventsService.supportedEntityTypes$, this.watchedEntities$]).pipe(
            scan((newFilter: EntityBrowserFilter, [entityTypes, watchedEntities]) => {
                newFilter.entityTypes = entityTypes;
                newFilter.excludedEntities = new GuidSet(watchedEntities);
                return newFilter;
            }, new EntityBrowserFilter())
        );
    }

    public ngAfterViewInit(): void {
        // Automatically open the Add items dialog if no element in watchlist
        this.userWatchlistService.watchlist$
            .pipe(
                take(1),
                filter((watchlist) => this.isEmpty(watchlist)),
                tap(() => this.addItems()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public addItems(): void {
        this.entitiesSelector?.show();
    }

    public addItemsToWatchlist(guids: IGuid[]): void {
        if (guids.length > 0) {
            this.userWatchlistService.add(guids);
        }
    }

    public onEntitiesSelected(entities: SelectedEntity[]): void {
        this.selectedEntitiesCache$.next(entities);
        this.addItemsToWatchlist(entities.map((entity) => entity.id));
        this.eventMonitoringLoggerService.logEvent(
            `Added new monitored entities of type ${entities.map((entity) => entity.entityType).toString()}`,
            `${entities.length} New monitored entities has been added`,
            EventMonitoringContext.EditWatchlist
        );
    }

    public removeItem(item: WatchlistItem): void {
        this.userWatchlistService.remove(item.guid);
    }

    public setItemIsFlagged(item: WatchlistItem, isFlagged: boolean): void {
        this.userWatchlistService.updateIsFlagged(item.guid, isFlagged);
        this.eventMonitoringLoggerService.logEvent(
            'Changed entity importance',
            'Entity importance has been changed',
            EventMonitoringContext.EditWatchlist,
            isFlagged ? 'Flagged' : 'Other'
        );
    }

    private createWatchlistItem(entry: IWatchlistEntry, name: string, icon: Icon, state: string, maintenance: boolean, customIconId: IGuid) {
        return new WatchlistItem(entry.entityId, name, this.isWatchlistEntryFlagged(entry), icon, state, maintenance, customIconId);
    }

    private async initializeUserWatchlistAsync(watchlist: IWatchlist | null): Promise<void> {
        if (this.isLoading && watchlist) {
            await this.fetchAndAddEntriesAsync(watchlist.entries);
            this.isLoading = false;
        }
    }

    private isEmpty(watchList: Watchlist | null) {
        return !watchList?.entries?.length;
    }

    private async fetchAndAddEntriesAsync(entries: IWatchlistEntry[]): Promise<void> {
        const entities = await this.entityCacheTask.getEntitiesAsync<Entity, IEntity>(Entity, new GuidSet(entries.map((entry) => entry.entityId)), true);
        entities.forEach((entity) => {
            const entry = entries.find((watchlistEntry) => watchlistEntry.entityId.equals(entity.id));
            if (entry) {
                this.entities.push(
                    this.createWatchlistItem(entry, entity.name, this.iconsService.getEntityIcon(entity), entity.runningState, entity.maintenance, entity.customIconId)
                );
            }
        });
    }

    private async onUserWatchlistAdded(entries: IWatchlistEntry[], entityCache: SelectedEntity[]): Promise<void> {
        entries.forEach((entry) => {
            const entryCache = entityCache.find((cache) => cache.id.equals(entry.entityId));
            if (entryCache) {
                this.entities.push(
                    this.createWatchlistItem(
                        entry,
                        entryCache.name,
                        this.iconsService.getEntityTypeIcon(entryCache.entityType),
                        entryCache.state,
                        entryCache.maintenance,
                        entryCache.customIconId
                    )
                );
            }
        });

        const entriesToFetch = entries.filter((entry) => !entityCache.find((cache) => cache.id.equals(entry.entityId)));
        if (entriesToFetch.length > 0) {
            await this.fetchAndAddEntriesAsync(entriesToFetch);
        }
    }

    private onUserWatchlistRemoved(entityId: IGuid): void {
        const existingItem = this.findItem(entityId);
        if (existingItem) {
            this.entities.remove(existingItem);
        }
    }

    private onUserWatchlistModified(entry: IWatchlistEntry): void {
        const existingItem = this.findItem(entry.entityId);
        if (existingItem) {
            existingItem.flagged = this.isWatchlistEntryFlagged(entry);
        }
    }

    private findItem(entityId: IGuid): WatchlistItem | undefined {
        return this.entities.find((entityItem) => entityItem.guid.equals(entityId));
    }

    private isWatchlistEntryFlagged(entry?: IWatchlistEntry): boolean {
        return entry?.priority === WatchlistPriority.Flagged ?? false;
    }

    private updateWatchedEntities() {
        this.watchedEntities$.next(this.entities.map((watchedEntity) => watchedEntity.guid));
    }
}
