import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { GenMeltedItem, GenMeltedListItem, GenMeltedModalComponent, ItemDensity, MeltedModalAction } from '@genetec/gelato-angular';
import { SpinnerSize } from '@genetec/gelato-angular';
import { isNullOrWhitespace } from '@genetec/web-maps';
import { TranslateService } from '@ngx-translate/core';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { IGuid, SafeGuid } from 'safeguid';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { EntityBrowserService } from '@modules/shared/entity-browser/entity-browser.service';
import { EntityTypeSet } from '@modules/shared/entity-browser/entity-type-set';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { EntityTypeData } from '@modules/shared/models/entity-type-data';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ExtendedSearchParameterType, LightCorrelationSupportedSearchParameter, LightDataSourceRegistration, ParameterComparison } from '../../api/api';
import { CorrelationService } from '../../services/correlation.service';
import { CorrelationSearchParameter } from './correlation-search-parameter';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-correlation-parameters-modal',
    templateUrl: './correlation-parameters-modal.component.html',
    styleUrls: ['./correlation-parameters-modal.component.scss'],
})
export class CorrelationParametersModalComponent extends ConnectionAwareModalComponent implements OnInit {
    //#region Fields

    @ViewChild(GenMeltedModalComponent)
    public genModalComponent!: GenMeltedModalComponent;

    @Input()
    public returnResult!: (parameter?: CorrelationSearchParameter) => void;

    public readonly TextFlavor = TextFlavor;
    public readonly SpinnerSize = SpinnerSize;

    public querying = false;
    public allSupportedParameters: Array<GenMeltedItem> = [];
    public selectedSupportedParameter: GenMeltedListItem | undefined;
    public comparisons: Array<GenMeltedItem> = [];
    public selectedComparison: ContextMenuItem | undefined;
    public comboValue: GenMeltedListItem[] = [];
    public comboOptions: Array<GenMeltedListItem> = [];
    public searchText = '';

    public parameter: CorrelationSearchParameter = new CorrelationSearchParameter();
    // default entity browser filter
    public entityFilter = new EntityBrowserFilter();

    public get parameterValue(): any {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.parameter.getFormattedValue();
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public set parameterValue(theValue: any) {
        if (theValue !== null) {
            this.parameter.setValueString(theValue);
        } else {
            this.parameter.resetValue();
        }
    }

    public get selectedLightSupportedParameter(): LightCorrelationSupportedSearchParameter {
        const defaultRangeParameter = new LightCorrelationSupportedSearchParameter({
            displayName: '',
            fieldName: '',
            supportedComparison: [],
            valueType: ExtendedSearchParameterType.String,
            minDate: undefined,
            maxDate: undefined,
            minimum: undefined,
            maximum: undefined,
        });
        const param = this.selectedSupportedParameter;
        if (param) {
            const parts = param.id.split('||');
            if (parts.length >= 2) {
                const supported = this.supportedParameters.find((el) => el.fieldName === parts[0] && el.valueType === parts[1]);
                return supported ?? defaultRangeParameter;
            }
        }
        return defaultRangeParameter;
    }

    private registrations: Array<LightDataSourceRegistration> = [];
    private supportedParameters: Array<LightCorrelationSupportedSearchParameter> = [];
    private isClosing = false;
    //#endregion

    //#region Constructor

    constructor(
        injector: Injector,
        trackingService: TrackingService,
        authService: AuthService,
        private correlationService: CorrelationService,
        private translateService: TranslateService,
        private securityCenterClientService: SecurityCenterClientService,
        private entityBrowserService: EntityBrowserService
    ) {
        super(authService, injector, trackingService);

        // hook up to the modal send action
        this.genModalAction.pipe(untilDestroyed(this)).subscribe((modalAction: MeltedModalAction) => this.onSubmit(modalAction));
    }

    //#endregion

    //#region Methods

    async ngOnInit() {
        super.ngOnInit();
        this.entityFilter = new EntityBrowserFilter(await this.entityBrowserService.getDefaultLogicalTypesAsync());
    }

    public hideModal(): void {
        void this.onSubmit(MeltedModalAction.Dismiss);
        if (this.genModalComponent) {
            this.genModalComponent.hide();
        }
    }

    public async refresh(dataTypes: Array<IGuid> | undefined, parameter: CorrelationSearchParameter | undefined): Promise<void> {
        let registrations: Array<LightDataSourceRegistration> | undefined;
        this.registrations = [];
        this.supportedParameters = [];
        this.allSupportedParameters = [];
        this.selectedSupportedParameter = undefined;
        this.querying = true;
        try {
            if (this.correlationService) {
                if (dataTypes && dataTypes?.length > 0) {
                    registrations = await this.correlationService.getRegistrations(dataTypes);
                } else {
                    registrations = await this.correlationService.getRegistrations(undefined);
                }
                if (registrations) {
                    for (const reg of registrations) {
                        this.registrations.push(reg);
                        if (reg.supportedSearchParameters) {
                            for (const param of reg.supportedSearchParameters) {
                                if (
                                    this.supportedParameters.findIndex(
                                        (item) => item.fieldName === param.fieldName && item.displayName === param.displayName && item.valueType === param.valueType
                                    ) > -1
                                ) {
                                    // parameter already exists, don't add it 2x
                                } else {
                                    this.supportedParameters.push(param);
                                }
                            }
                        }
                    }
                }
            }
            // sort the supported params by name
            this.supportedParameters.sort((a, b) => {
                if (a.displayName > b.displayName) return 1;
                return -1;
            });

            // convert the supported parameters to GenItem's for display
            this.supportedParameters.forEach((parameter2) => {
                this.allSupportedParameters.push({
                    text: parameter2.displayName,
                    id: parameter2.fieldName + '||' + parameter2.valueType,
                    density: ItemDensity.Compact,
                });
            });

            // there is a previous parameter, reload it!
            if (parameter?.FieldName && parameter?.ValueType && parameter?.Comparison) {
                this.parameter.Value = parameter.Value;
                this.parameter.FieldName = parameter.FieldName;
                this.parameter.ValueType = parameter.ValueType;
                this.parameter.Comparison = parameter.Comparison;

                const fid = parameter.FieldName + '||' + parameter.ValueType;
                const supported = this.supportedParameters.find((item) => item.fieldName === parameter.FieldName && item.valueType === parameter.ValueType);
                const selectedSupported = this.allSupportedParameters.find((item) => item.id === fid);
                if (selectedSupported && supported) {
                    await this.setEntityFilterAsync(supported);
                    this.selectedSupportedParameter = selectedSupported;
                    this.comparisons = Array.from(supported.supportedComparison).map((item) => {
                        return {
                            text: CorrelationSearchParameter.localizeComparison(this.translateService, item),
                            id: item,
                        };
                    });
                    this.selectedComparison = this.comparisons.find((item) => item.id === this.parameter.Comparison);
                    this.parameter.DisplayName = supported.displayName;
                }
            }
        } finally {
            this.querying = false;
        }
    }

    //#region Events

    public async onSubmit(modalAction: MeltedModalAction): Promise<void> {
        if (this.isClosing) {
            // prevent re-entrance
            return;
        }

        this.isClosing = true;
        try {
            if (modalAction === MeltedModalAction.Default && this.parameter) {
                await this.setParameterDisplayText();
                this.returnResult(this.parameter);
            } else {
                this.returnResult(undefined);
            }
        } finally {
            this.isClosing = false;
        }
    }

    public onSearchChange(text: string): void {
        this.searchText = text;
        if (text) {
            this.limitSupportedParameters(text);
        } else {
            this.limitSupportedParameters(null);
        }
    }

    public async onSelectedSupportedParameterChanged(event: GenMeltedListItem | null): Promise<void> {
        if (!event) {
            // early exit if event is null.
            return;
        }
        this.selectedSupportedParameter = event;
        this.selectedComparison = undefined;
        this.comparisons = [];
        this.comboOptions = [];
        this.comboValue = [];

        let previousValueType = ExtendedSearchParameterType.String;
        if (this.parameter) {
            previousValueType = this.parameter.ValueType;
        }

        const parts = event.id.split('||');
        if (parts.length >= 2) {
            const supported = this.supportedParameters.find((el) => el.fieldName === parts[0] && el.valueType === parts[1]);
            if (supported) {
                await this.setEntityFilterAsync(supported);

                // change the parameter
                this.parameter = new CorrelationSearchParameter();

                // set the value type
                this.parameter.ValueType = supported.valueType;

                // save the value (if possible)
                if (!this.valueTypeConversionExists(previousValueType, supported.valueType)) {
                    this.parameterValue = null;
                }

                // update the parameter's field name
                this.parameter.FieldName = supported.fieldName;
                this.parameter.DisplayName = supported.displayName;

                // reset the comparisons
                if (supported.supportedComparison) {
                    supported.supportedComparison.forEach((item) => {
                        this.comparisons.push({
                            text: CorrelationSearchParameter.localizeComparison(this.translateService, item),
                            id: item,
                        });
                    });
                    if (this.comparisons.length > 0) {
                        this.selectedComparison = this.comparisons[0];
                    }
                }

                if (supported.valueType === 'Categorical' && supported.categories) {
                    supported.categories.forEach((cat) => {
                        this.comboOptions.push({
                            id: cat.id,
                            text: cat.text,
                            isChecked: false,
                        });
                    });
                    if (this.comboOptions.length > 0) {
                        this.comboValue = [this.comboOptions[0]];
                        this.parameterValue = this.comboValue.map((item) => item.id);
                    }
                }

                // set the first supported comparison as the selected comparison
                if (this.comparisons.length > 0) {
                    this.parameter.Comparison = this.comparisons[0].id as ParameterComparison;
                    this.selectedComparison = this.comparisons[0];
                }
            }
        }
    }

    public onComparisonChanged(newValue: ContextMenuItem): void {
        // the comparison was changed, apply to the current "parameter"
        if (newValue?.id && this.parameter) {
            this.parameter.Comparison = newValue.id as ParameterComparison;
        }
    }

    public onComboboxValueChanged(newValues: GenMeltedListItem[]): void {
        if (newValues) {
            this.parameterValue = newValues.map((item) => item.id);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public onValueChanged(value: any): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.parameterValue = value;
    }

    public onEntityGuidsChanged(value: IGuid[]): void {
        if (this.parameter?.Comparison === 'In' || this.parameter?.Comparison === 'NotIn') {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            this.parameterValue = value.map(SafeGuid.from);
        } else if (value.length > 0) {
            this.parameterValue = new SafeGuid(value[0]);
        } else {
            this.parameterValue = '';
        }
    }

    //#endregion

    private valueTypeConversionExists(previousType: ExtendedSearchParameterType, newType: ExtendedSearchParameterType) {
        if (newType === ExtendedSearchParameterType.String) return true;
        if (newType === previousType) return true;

        if (newType === ExtendedSearchParameterType.Int && previousType === ExtendedSearchParameterType.Long) return true;
        if (newType === ExtendedSearchParameterType.Long && previousType === ExtendedSearchParameterType.Int) return true;
        if (newType === ExtendedSearchParameterType.Double && (previousType === ExtendedSearchParameterType.Int || previousType === ExtendedSearchParameterType.Long)) return true;

        return false;
    }

    private limitSupportedParameters(text: string | null) {
        const pselected = this.selectedSupportedParameter?.id;
        const allSupportedParameters: GenMeltedItem[] = [];
        if (!isNullOrWhitespace(text)) {
            // convert the supported parameters to GenItem's for display
            this.supportedParameters.forEach((parameter) => {
                if (!parameter.displayName.toLowerCase().includes(text.toLowerCase())) {
                    // skip this parameter
                    return;
                }

                allSupportedParameters.push({
                    text: parameter.displayName,
                    id: parameter.fieldName + '||' + parameter.valueType,
                    density: ItemDensity.Compact,
                });
            });
        } else {
            // no filter, reload everything
            this.supportedParameters.forEach((parameter) => {
                allSupportedParameters.push({
                    text: parameter.displayName,
                    id: parameter.fieldName + '||' + parameter.valueType,
                    density: ItemDensity.Compact,
                });
            });
        }

        this.allSupportedParameters = allSupportedParameters;

        const previouslySelected = this.allSupportedParameters.find((item) => !isNullOrWhitespace(pselected) && item.id === pselected);
        if (previouslySelected) {
            this.selectedSupportedParameter = previouslySelected;
        } else if (this.allSupportedParameters.length > 0) {
            this.selectedSupportedParameter = this.allSupportedParameters[0];
        } else {
            this.selectedSupportedParameter = undefined;
        }
    }

    private async setEntityFilterAsync(supported: LightCorrelationSupportedSearchParameter | undefined): Promise<void> {
        if (supported) {
            if (supported.entityTypes && supported.entityTypes?.length > 0) {
                const set = new EntityTypeSet();
                for (const ty of supported.entityTypes) {
                    set.add(new EntityTypeData(ty));
                }
                if (supported.entityTypes.some((item) => item === EntityTypes.CustomEntities) && supported.customEntityTypes && supported.customEntityTypes?.length > 0) {
                    for (const custom of supported.customEntityTypes) {
                        set.add(new EntityTypeData(EntityTypes.CustomEntities, custom));
                    }
                }
                this.entityFilter = new EntityBrowserFilter(set);
            } else {
                this.entityFilter = new EntityBrowserFilter(await this.entityBrowserService.getDefaultLogicalTypesAsync());
            }
        }
    }

    private async setParameterDisplayText(): Promise<void> {
        if (this.parameter) {
            let supported: LightCorrelationSupportedSearchParameter | undefined;
            if (this.selectedSupportedParameter) {
                supported = this.supportedParameters.find((item) => item.fieldName === this.parameter.FieldName && item.valueType === this.parameter.ValueType);
            }
            await this.parameter.setDisplayText(this.translateService, this.securityCenterClientService, supported);
        }
    }

    //#endregion
}
