// Autogenerated: v2/help/Entities/ApiElevator?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IElevatorEntity, ElevatorEntityFields } from '../../Interface/IElevatorEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class ElevatorEntity extends Entity implements IElevatorEntity {
    // #region REST Properties

    // CabinReader
    public get cabinReader(): IGuid {
        return this.getFieldGuid(ElevatorEntityFields.cabinReaderField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Elevators);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getFloorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Floors', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', true, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

