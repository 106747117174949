import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { MethodEmitter } from '@src/app/store';
import { Observable } from 'rxjs';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SecurityCenterStateService } from '@modules/shared/services/state/security-center-state.service';
import { ColumnDescriptor, WhatFilter, WhenFilter, WhereFilter } from './investigate-data.interfaces';
import { InvestigateDataState } from './investigate-data.state';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class InvestigateDataContext extends SecurityCenterStateService {
    //#region Properties

    // Filters

    @Select(InvestigateDataState.whatFilter)
    public whatFilter$!: Observable<WhatFilter | null>;

    @Select(InvestigateDataState.whenFilter)
    public whenFilter$!: Observable<WhenFilter | null>;

    @Select(InvestigateDataState.whereFilter)
    public whereFilter$!: Observable<WhereFilter | null>;

    @SelectSnapshot(InvestigateDataState.whatFilter)
    public whatFilter!: WhatFilter | null;

    @SelectSnapshot(InvestigateDataState.whenFilter)
    public whenFilter!: WhenFilter | null;

    @SelectSnapshot(InvestigateDataState.whereFilter)
    public whereFilter!: WhereFilter | null;

    // Table state

    @Select(InvestigateDataState.columns)
    public columns$!: Observable<ColumnDescriptor[]>;

    @Select(InvestigateDataState.tableBusy)
    public tableBusy$!: Observable<boolean>;

    @SelectSnapshot(InvestigateDataState.columns)
    public columns!: ColumnDescriptor[];

    @SelectSnapshot(InvestigateDataState.tableBusy)
    public tableBusy!: boolean;

    //#endregion

    //#region Constructors
    constructor(securityCenterClient: SecurityCenterClientService) {
        super(securityCenterClient);
    }

    //#endregion

    //#region Methods

    @MethodEmitter(InvestigateDataState.setWhatFilter)
    public setWhatFilter(what?: WhatFilter | null): void {}

    @MethodEmitter(InvestigateDataState.setWhenFilter)
    public setWhenFilter(when?: WhenFilter | null): void {}

    @MethodEmitter(InvestigateDataState.setWhereFilter)
    public setWhereFilter(where?: WhereFilter | null): void {}

    @MethodEmitter(InvestigateDataState.setColumns)
    public setColumns(columns?: ColumnDescriptor[]): void {}

    @MethodEmitter(InvestigateDataState.setTableBusy)
    public setTableBusy(tableBusy: boolean): void {}

    protected clearState(): void {
        super.clearState();

        this.setWhatFilter(null);
        this.setWhereFilter(null);
        this.setWhenFilter(null);

        this.setTableBusy(false);
    }

    //#endregion
}
