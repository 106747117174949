import { Component, Input } from '@angular/core';
import { IconService } from '@modules/mission-control/services/icons/icon.service';
import { SidePaneHeaderInfo } from '@modules/shared/components/side-pane/sidepane-header/sidepane-header.component';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { Icon } from '@genetec/gelato';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { KnownCustomHeaderIds } from '@modules/mission-control/side-pane-custom-headers';

@Component({
    selector: 'app-incident-sidepane-header',
    templateUrl: './incident-sidepane-header.component.html',
})
@InternalPluginDescriptor({
    type: IncidentSidePaneHeaderComponent,
    pluginTypes: [PluginTypes.SidePaneHeader],
    exposure: {
        id: IncidentSidePaneHeaderComponent.pluginId,
        subSection: KnownCustomHeaderIds.IncidentCustomHeaderId,
    },
    isSupported: () => true,
})
export class IncidentSidePaneHeaderComponent extends TrackedComponent implements ContentPluginComponent {
    public static pluginId = SafeGuid.parse('1C1E8D8B-7EA7-4F3D-8076-E8C5BA911D0B');

    @Input() public headerInfo?: SidePaneHeaderInfo;

    private dataContext?: unknown;
    private onDestroy$ = new Subject<void>();

    constructor(trackingService: TrackingService, private iconService: IconService) {
        super(trackingService);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.onDestroy$.next();
    }

    setContent(content: Content): void {
        if (this.isHeaderInfo(content)) {
            this.headerInfo = content;

            //Load the icon from MC Icon Service instead of ResourceProvider
            if (this.headerInfo.titleIcon) {
                const iconID = this.headerInfo.titleIcon;
                // Show this icon while loading
                this.headerInfo.titleIcon = Icon.Incident;
                this.iconService
                    .getIcon(iconID)
                    .pipe(take(1), takeUntil(this.onDestroy$))
                    .subscribe((icon) => {
                        if (this.headerInfo) {
                            this.headerInfo.titleIcon = icon;
                        }
                    });
            }
        }
    }

    setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext;
    }

    private isHeaderInfo(content: unknown): content is SidePaneHeaderInfo {
        return (content as SidePaneHeaderInfo) !== undefined;
    }
}
