import { IWatchlistEntry, WatchlistPriority } from '@modules/shared/api/api';
import { MonitoredEntityEvent } from '@modules/shared/controllers/events/monitored-entity-event';
import { EventTimeService } from '@modules/shared/services/time/event-time.service';
import { Subscription, timer } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { EventsDismisser } from './event-dismisser';

export class CachedMonitoredEntityEvent {
    public isNew = true;
    public isDestroyed = false;
    public eventNumber = 0;
    private monitoredEntityPriority: WatchlistPriority;
    private monitoredEntityId: IGuid;
    private eventId: IGuid;
    private monitoredEntityTimestampMs: number;

    private cachedFormattedDate?: string;
    private cachedFormattedTime?: string;

    private timerSubscription: Subscription;

    constructor(
        public monitoredEntityEvent: MonitoredEntityEvent,
        userWatchlistItem: IWatchlistEntry,
        private eventTimeService: EventTimeService,
        eventsDismisser: EventsDismisser,
        timeoutMs: number
    ) {
        this.monitoredEntityPriority = userWatchlistItem.priority;
        this.monitoredEntityId = userWatchlistItem.entityId;
        this.eventId = this.monitoredEntityEvent.event.id ?? SafeGuid.EMPTY;
        this.monitoredEntityTimestampMs = Date.parse(this.monitoredEntityEvent.event.timestamp) ?? Date.now();
        this.timerSubscription = timer(timeoutMs).subscribe(() => eventsDismisser.dismissEvent(this.eventNumber));
    }

    get formattedDate(): string {
        this.cachedFormattedDate ??= this.eventTimeService.formatDate(this.monitoredEntityEvent.event);
        return this.cachedFormattedDate;
    }

    get formattedTime(): string {
        this.cachedFormattedTime ??= this.eventTimeService.formatTime(this.monitoredEntityEvent.event);
        return this.cachedFormattedTime;
    }

    get id(): IGuid {
        return this.eventId;
    }

    get priority(): WatchlistPriority {
        return this.monitoredEntityPriority;
    }

    get entityId(): IGuid {
        return this.monitoredEntityId;
    }

    get timestampMs(): number {
        return this.monitoredEntityTimestampMs;
    }

    public updateUserWatchlistItem(newUserWatchlistItem: IWatchlistEntry): void {
        this.monitoredEntityPriority = newUserWatchlistItem.priority;
    }

    public destroy(): void {
        if (!this.timerSubscription.closed) {
            this.timerSubscription.unsubscribe();
        }
        this.isDestroyed = true;
    }
}
