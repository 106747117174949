import { Inject, Injectable } from '@angular/core';
import { ActionType } from 'RestClient/Client/Enumerations/ActionTypes';
import { ActionBase } from 'RestClient/Client/Event/ActionBase';
import { IApplicationEntity } from 'RestClient/Client/Interface/IApplicationEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { ApplicationEntity } from 'RestClient/Client/Model/Application/ApplicationEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { ActionHandler } from '../../shared/services/actions/actions.service';
import { SharedCommands } from '../../shared/enumerations/shared-commands';
import { stringFormat } from '../../shared/utilities/StringFormat';
import { InternalCommandsService } from '../../shared/services/commands/commands.service';
import { COMMANDS_SERVICE } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { ContextTypes } from '../../shared/interfaces/plugins/public/context-types';

@Injectable({
    providedIn: 'root',
})
export class SendMessageActionHandler implements ActionHandler {
    constructor(
        private translateService: TranslateService,
        private securityCenterClientService: SecurityCenterClientService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService
    ) {}

    public async executeAsync(action: ActionBase): Promise<boolean> {
        if (action.actionType === ActionType.SendMessage) {
            const message = action.getField<string>('message');
            const hasTimeout = action.getField<boolean>('hastimeout');
            const timeoutDuration = action.getField<number>('timeoutduration');
            const sourceApp = action.getFieldGuid('sourceapplication');

            if (message) {
                let timeout: number | undefined;
                if (hasTimeout) {
                    timeout = timeoutDuration;
                }

                let title = '';
                const sourceName = await this.getSourceName(sourceApp);
                if (sourceName) {
                    title = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_MESSAGE_FROM_USER_X') as string, sourceName);
                }

                const data = {
                    title,
                    message,
                    timeout,
                };
                this.commandsService.executeCommand(SharedCommands.ShowMessage, { type: ContextTypes.Specific, data });

                return true;
            }
        }
        return false;
    }

    private async getSourceName(sourceApplication: IGuid): Promise<string | null> {
        let result: string | null = null;
        const application = await this.securityCenterClientService.scClient?.getEntityAsync<ApplicationEntity, IApplicationEntity>(ApplicationEntity, sourceApplication);
        if (application) {
            result = application.name;
            if (!application.loggedUser.equals(SafeGuid.EMPTY)) {
                const loggedUser = await this.securityCenterClientService.scClient?.getEntityAsync<UserEntity, IUserEntity>(UserEntity, application.loggedUser);
                if (loggedUser) {
                    result = loggedUser.name;
                    if (loggedUser.firstName && loggedUser.lastName) {
                        result = loggedUser.firstName + ' ' + loggedUser.lastName;
                    }
                }
            }
        }
        return result;
    }
}
