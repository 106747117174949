import { SafeGuid } from 'safeguid';

export class KnownFeatures {
    public static accessControlId = SafeGuid.parse('22325086-4f8e-4368-940b-f3573ac8c635');
    public static alarmsId = SafeGuid.parse('33cf2d6b-54a2-49d2-93fb-0b34b14a7084');
    public static licensePlateManagementId = SafeGuid.parse('eb544ab9-43fc-483f-a514-1ed8b3472d20');
    public static mapsId = SafeGuid.parse('61f6ba68-0c0d-4f24-82b8-e89b81ad3fbc');
    public static videoId = SafeGuid.parse('2324ab76-be35-4dcf-931c-43d615d20dcd');

    public static readonly values = [];
}
