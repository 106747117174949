import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';

export const getAccessStatusResourceString = (accessStatus: AccessStatus): string => {
    switch (accessStatus) {
        case AccessStatus.Active:
            return 'STE_LABEL_CARDHOLDER_STATUS_ACTIVE';
            break;
        case AccessStatus.Inactive:
            return 'STE_LABEL_CARDHOLDER_STATUS_INACTIVE';
            break;
        case AccessStatus.Requested:
            return 'STE_LABEL_CARDHOLDER_STATUS_REQUESTED';
            break;
        case AccessStatus.Expired:
            return 'STE_LABEL_CARDHOLDER_STATUS_EXPIRED';
            break;
        case AccessStatus.Lost:
            return 'STE_LABEL_CARDHOLDER_STATUS_LOST';
            break;
        case AccessStatus.Stolen:
            return 'STE_LABEL_CARDHOLDER_STATUS_STOLEN';
            break;
        default:
            return accessStatus as string;
    }
};
