import { Injectable, OnDestroy } from '@angular/core';
import { from, Observable, of, Subscription } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Entity } from 'RestClient/Client/Model/Entity';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { WebAppClient } from 'WebClient/WebAppClient';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { LoggerService } from '@shared/services/logger/logger.service';
import { concatAll, map, tap, toArray } from 'rxjs/operators';

@Injectable()
export class IncidentLocationService implements OnDestroy {
    private readonly _scClient: WebAppClient;
    private _entityById: Map<string, IEntity> = new Map<string, IEntity>();
    private _subscription?: Subscription;

    constructor(securityCenterProvider: SecurityCenterClientService, private _logger: LoggerService) {
        this._scClient = securityCenterProvider.scClient;
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public getLocation(id: IGuid | string): Observable<IEntity | null> {
        if (!id) return of(null);
        const locationId = SafeGuid.from(id);
        if (locationId.isEmpty()) return of(null);

        const entity = this._entityById.get(locationId.toString());
        if (entity) return of(entity);

        return from(this._scClient.getEntityAsync(Entity, locationId)).pipe(map((x) => x ?? null));
    }

    public prefetch(ids: IGuid[]): Observable<IEntity[]> {
        const query = new EntityQuery();
        query.guids = SafeGuid.createSet(ids); // TODO: ***Not a cache***, it simply fixes the case where we ha cache miss on entities task
        return from(this._scClient.getEntitiesAsync(Entity, query)).pipe(
            concatAll(),
            tap((entity) => this._entityById.set(entity.id.toString(), entity)),
            toArray()
        );
    }
}
