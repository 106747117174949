<app-entity-commands-widget-host
    [content]="content"
    [isOwnerLoading]="isLoading"
    [displayContext]="dataContext?.displayContext"
    [stateTooltip]="stateAdditionalInfoString$ | async"
>
    <span primaryState>{{ isOpenedStateString$ | async }}</span>

    <span secondaryState *ngIf="isMaintenanceModeActiveStateString$ | async as maintenanceModeActiveString; else lockedString" [ngClass]="'fg-color-warning'"
        >{{ maintenanceModeActiveString }}
    </span>
</app-entity-commands-widget-host>

<ng-template #lockedString>
    <span secondaryState [ngClass]="(door$ | async)?.isLocked ? 'fg-color-danger' : 'fg-color-success'">{{ isLockedStateString$ | async }} </span>
</ng-template>
