import { EventBase } from 'RestClient/Client/Event/EventBase';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { IGuid, SafeGuid } from 'safeguid';
import { ExportStatus2 } from './video-export-progress.event';

/** Represents a video export progress updated event. Keep in sync with VideoExportCompletedEvent.cs */
export class VideoExportCompletedEvent extends EventBase {
    //#region Properties

    // Status
    public get status(): ExportStatus2 {
        const statusString = this.getField<string>('Status');
        if (statusString as ExportStatus2) {
            return statusString as ExportStatus2;
        } else {
            return ExportStatus2.UnknownError;
        }
    }
    public set status(status: ExportStatus2) {
        this.setField<string>('Status', status);
    }

    // ExportId
    public get exportId(): IGuid {
        return this.getFieldGuid('ExportId');
    }
    public set exportId(exportId: IGuid) {
        this.setFieldGuid('ExportId', exportId);
    }

    public get statusCode(): number {
        return this.getField<number>('StatusCode');
    }
    public set statusCode(statusCode: number) {
        this.setField<number>('StatusCode', statusCode);
    }

    public get message(): string {
        return this.getField<string>('Message');
    }
    public set message(message: string) {
        this.setField<string>('Message', message);
    }

    //#endregion

    //#region Constructors

    constructor() {
        super();
        this.eventType = 'VideoExportCompletedEvent';
    }

    //#endregion

    //#region Public Methods

    /** Setup the handler callback on the REST subscription, so we are subscribing to "VideoExportProgressEvent"s */
    public static setupCallbackOnRestConnection(client: ISecurityCenterClient, callback: (event: VideoExportCompletedEvent) => void): (() => void) | undefined {
        if (client) {
            // add the event def
            client.registerAdditionalEventTypes('VideoExportCompletedEvent', VideoExportCompletedEvent);
            // wire up the subscription
            return client.onEventReceived((arg) => {
                if (arg?.event?.eventType === 'VideoExportCompletedEvent') {
                    callback(arg.event as VideoExportCompletedEvent);
                }
            });
        }
        return undefined;
    }

    //#endregion
}
