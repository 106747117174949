import { TransactionResult } from './RestTransactionResponse';
import HttpStatusCode from '../Client/Enumerations/HttpStatusCode';
import { IRestResponse } from '../Client/Interface/IRestResponse';

export class RestResponseError extends Error {
    constructor(message: string, public response: RestResponse) {
        super(message);
    }
}
export class RestResponse implements IRestResponse {
  protected _body: any | null = null;
  protected _statusCode = 0;
  protected _cancelled = false;
  protected _logonResult = '';
  protected _passwordConstraints: object | null = null;
  protected _error = '';

  public get body(): any | null {
    return this._body;
  }

  public get statusCode(): number {
    return this._statusCode;
  }

  public set statusCode(value: number) {
    this._statusCode = value;
  }

  public get cancelled(): boolean {
    return this._cancelled;
  }

  public get logonResult(): string {
    return this._logonResult;
  }

  public get passwordConstraints(): object | null {
    return this._passwordConstraints;
  }
  
  public headers: Map<string, string>;

  constructor(code?: number, body?: any | null, responseInfo?: { response?: Response | null, responseType?: 'arraybuffer' | 'blob' | 'formdata' | 'json' | 'text'} | null, cancelled?: boolean | null) {
    if (code) {
        this._statusCode = code;
    }
    if (body) {
        try {
            // default json
            if(!responseInfo?.response || !responseInfo.responseType || responseInfo.responseType === 'text') {
                this._body = JSON.parse(body);
            } else {
                this._body = body;
            }
        } catch (e) {
            this._body = body;
        }
    }
    if (cancelled) {
        this._cancelled = cancelled;
    }
    this.headers = new Map<string, string>();
    if (responseInfo?.response?.headers) {
        responseInfo.response.headers.forEach((value, key) => {
        this.headers.set(key, value);
      });
    }
    this.parseLoginResponseBody();
  }

  public parseTransactionResult(response: TransactionResult) {
    this._statusCode = response.statusCode;
    this._cancelled = false;
    this._body = response.result;
    this.parseLoginResponseBody();
  }

  public toString(): string {
    return JSON.stringify(this);
  }

  // For testing purpose only
  public shouldSucceed(): void {
    if (this._statusCode === HttpStatusCode.OK || this._statusCode === HttpStatusCode.CREATED) {
      return;
    }
    throw new Error('Response was ' + this._statusCode);
  }

  public shouldFail(code?: number): void {
    if (!code) {
      if (this._statusCode === HttpStatusCode.OK || this._statusCode === HttpStatusCode.CREATED) {
        throw new Error('Response was ' + this._statusCode);
      }
    } else {
      if (this._statusCode !== code) {
        throw new Error('Response was ' + this._statusCode);
      }
    }
    return;
  }

  private parseLoginResponseBody(): void {
    if (
      this._body &&
      (this._statusCode === HttpStatusCode.UNAUTHORIZED || this._statusCode === HttpStatusCode.FORBIDDEN)
    ) {
      try {
        this._logonResult = this._body['LogonResult'];
        this._passwordConstraints = this._body['PasswordConstraints'];
      } catch (e) {}
    }
    }
    
    public static FromJson(str : string) : RestResponse | null {
        try {
            return JSON.parse(str) as RestResponse;
        } catch (error) {
            return null;
        }
    }
}
