import { HttpMethod } from '@genetec/web-maps';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { McClientError as McClientError } from './mc-client-error';

interface ErrorResponse {
    Details: string;
}
export class McClientResponseError extends McClientError {
    public details: string;
    public subMessage: string | null;

    constructor(public requestUrl: string, public httpMethod: HttpMethod, public statusCode: number, responseBody: string) {
        super();
        this.requestUrl = requestUrl;
        this.message = this.createErrorMessage(httpMethod, statusCode);
        this.subMessage = this.getSubMessageKey(statusCode);
        this.httpMethod = httpMethod;
        this.statusCode = statusCode;
        this.details = this.getDetailsFromResponseBody(responseBody);
        this.isRetryable = this.isRetryableError(statusCode);
    }

    public toLogMessage(): string {
        return `${this.httpMethod} ${this.requestUrl} returned ${this.statusCode} status code with error details ${this.details}.`;
    }

    private createErrorMessage(httpMethod: HttpMethod, statusCode: number): string {
        switch (statusCode) {
            case HttpStatusCode.BAD_REQUEST:
                return httpMethod === HttpMethod.POST ? 'STE_MESSAGE_ERROR_HTTPCODE_BAD_POST_REQUEST' : 'STE_MESSAGE_ERROR_HTTPCODE_BAD_GET_REQUEST';
            case HttpStatusCode.FORBIDDEN:
                return 'STE_MESSAGE_ERROR_HTTPCODE_FORBIDDEN';
            case HttpStatusCode.NOT_FOUND:
                return 'STE_MESSAGE_ERROR_HTTPCODE_NOT_FOUND';
            case McCustomHttpStatusCode.LicenseInvalid:
                return 'STE_INCIDENTS_UNAVAILABLE';
            case HttpStatusCode.INTERNAL_SERVER_ERROR:
                return 'STE_MESSAGE_ERROR_HTTPCODE_INTERNAL_SERVER_ERROR';
            case HttpStatusCode.BAD_GATEWAY:
                return 'STE_MESSAGE_ERROR_HTTPCODE_BAD_GATEWAY';
            case HttpStatusCode.GATEWAY_TIMEOUT:
                return 'STE_MESSAGE_ERROR_HTTPCODE_GATEWAY_TIMEOUT';
            default:
                return `Unhandled status code ${statusCode}`;
        }
    }

    private getSubMessageKey(statusCode: number): string | null {
        try {
            if (statusCode === McCustomHttpStatusCode.LicenseInvalid) return 'STE_MESSAGE_MAXIMUM_LICENSE_REACHED';

            return null;
        } catch (e) {
            return null;
        }
    }

    private getDetailsFromResponseBody(responseBody: string): string {
        try {
            const errorObj = JSON.parse(responseBody) as ErrorResponse;
            return errorObj.Details;
        } catch (e) {
            return '';
        }
    }

    private isRetryableError(statusCode: number): boolean {
        switch (statusCode) {
            case McCustomHttpStatusCode.LicenseInvalid:
                return true;
            default:
                return false;
        }
    }
}

enum McCustomHttpStatusCode {
    LicenseInvalid = 460,
}
