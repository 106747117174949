import { IEntity } from "RestClient/Client/Interface/IEntity";
import { EntityTypes } from "RestClient/Client/Enumerations/EntityTypes";
import {
    AccessPointEntityWrapper,
    AlarmEntityWrapper,
    AccessPointRuleEntityWrapper,
    ApplicationEntityWrapper,
    AreaEntityWrapper,
    MapEntityWrapper,
    CameraEntityWrapper,
    CardholderEntityWrapper,
    CardholderGroupEntityWrapper,
    CredentialEntityWrapper,
    DeviceEntityWrapper,
    DoorEntityWrapper,
    ElevatorEntityWrapper,
    EntityWrapper,
    PartitionEntityWrapper,
    RoleEntityWrapper,
    ScheduleEntityWrapper,
    SystemConfigurationEntityWrapper,
    UnitEntityWrapper,
    UserEntityWrapper,
    UserGroupEntityWrapper,
    VisitorEntityWrapper,
    ZoneEntityWrapper,
    MacroEntityWrapper,
    LprUnitEntityWrapper,
    PatrollerEntityWrapper,
    HotlistEntityWrapper,
    UserTaskEntityWrapper,
    IntrusionAreaEntityWrapper,
    IntrusionUnitEntityWrapper,
    ServerEntityWrapper,
    VideoUnitEntityWrapper,
    FileEntityWrapper,
    ThreatLevelEntityWrapper,
    StreamEntityWrapper,
    NetworkEntityWrapper,
    AgentEntityWrapper,
    RouteEntityWrapper,
    SequenceEntityWrapper,
    TilePluginEntityWrapper,
    TransferGroupEntityWrapper,
    CustomEntityWrapper,
    TileLayoutEntityWrapper,
    DoorTemplateEntityWrapper,
    PrivilegedEntityWrapper,
    DoorAccessPointEntityWrapper,
    InputDeviceEntityWrapper,
    ContentFileEntityWrapper,
    LocatableEntityWrapper,
    AuthenticationRoleEntityWrapper,
    FederationBaseRoleEntityWrapper,
    PluginRoleEntityWrapper,
    AccessControlUnitEntityWrapper,
    AccessManagerRoleEntityWrapper,
    AreaAccessPointEntityWrapper,
    DoorSideAccessPointEntityWrapper,
    MobileApplicationEntityWrapper,
    IntrusionInputDeviceEntityWrapper,
    OutputDeviceEntityWrapper,
    LicensePlateManagerRoleEntityWrapper,
    ActiveDirectoryRoleEntityWrapper,
    AdfsRoleEntityWrapper,
    DirectoryFailoverRoleEntityWrapper,
    HealthMonitoringRoleEntityWrapper,
    IncidentManagementRoleEntityWrapper,
    MapManagerRoleEntityWrapper,
    MediaGatewayRoleEntityWrapper,
    OmnicastFederationRoleEntityWrapper,
    PointOfSaleRoleEntityWrapper,
    ReverseTunnelClientRoleEntityWrapper,
    ReverseTunnelServerRoleEntityWrapper,
    SecurityCenterFederationRoleEntityWrapper,
    WebClientRoleEntityWrapper,
    ZoneManagementRoleEntityWrapper,
    ArchiverAgentEntityWrapper,
    AuxiliaryArchiverRoleEntityWrapper,
    ArchiverRoleEntityWrapper, MediaRouterRoleEntityWrapper
} from "./EntityWrapper";
import { MapEntity } from "RestClient/Client/Model/MapEntity";
import { AccessControlUnitEntity } from "RestClient/Client/Model/AccessControl/AccessControlUnitEntity";
import { DoorEntity } from "RestClient/Client/Model/AccessControl/DoorEntity";
import { AccessPointRuleEntity } from "RestClient/Client/Model/AccessControl/AccessPointRuleEntity";
import { AlarmEntity } from "RestClient/Client/Model/AlarmEntity";
import { ApplicationEntity } from "RestClient/Client/Model/Application/ApplicationEntity";
import { AreaEntity } from "RestClient/Client/Model/AreaEntity";
import { CameraEntity } from "RestClient/Client/Model/Video/CameraEntity";
import { CardholderEntity } from "RestClient/Client/Model/AccessControl/CardholderEntity";
import { CardholderGroupEntity } from "RestClient/Client/Model/AccessControl/CardholderGroupEntity";
import { CredentialEntity } from "RestClient/Client/Model/AccessControl/CredentialEntity";
import { DeviceEntity } from "RestClient/Client/Model/Devices/DeviceEntity";
import { ElevatorEntity } from "RestClient/Client/Model/AccessControl/ElevatorEntity";
import { RoleEntity } from "RestClient/Client/Model/Roles/RoleEntity";
import { PartitionEntity } from "RestClient/Client/Model/PartitionEntity";
import { SystemConfigurationEntity } from "RestClient/Client/Model/SystemConfigurationEntity";
import { ScheduleEntity } from "RestClient/Client/Model/ScheduleEntity";
import { UnitEntity } from "RestClient/Client/Model/UnitEntity";
import { UserEntity } from "RestClient/Client/Model/UserEntity";
import { UserGroupEntity } from "RestClient/Client/Model/UserGroupEntity";
import { VisitorEntity } from "RestClient/Client/Model/AccessControl/VisitorEntity";
import { ZoneEntity } from "RestClient/Client/Model/AccessControl/ZoneEntity";
import { MacroEntity } from "RestClient/Client/Model/MacroEntity";
import { LprUnitEntity } from "RestClient/Client/Model/LicensePlateRecognition/LprUnitEntity";
import { PatrollerEntity } from "RestClient/Client/Model/LicensePlateRecognition/LprPatrollerEntity";
import { UserTaskEntity } from "RestClient/Client/Model/UserTaskEntity";
import { IntrusionAreaEntity } from "RestClient/Client/Model/Intrusion/IntrusionAreaEntity";
import { IntrusionUnitEntity } from "RestClient/Client/Model/Intrusion/IntrusionUnitEntity";
import { ServerEntity } from "RestClient/Client/Model/ServerEntity";
import { VideoUnitEntity } from "RestClient/Client/Model/Video/VideoUnitEntity";
import { FileEntity } from "RestClient/Client/Model/FileEntity";
import { ThreatLevelEntity } from "RestClient/Client/Model/ThreatLevelEntity";
import { StreamEntity } from "RestClient/Client/Model/Video/StreamEntity";
import { NetworkEntity } from "RestClient/Client/Model/NetworkEntity";
import { AgentEntity } from "RestClient/Client/Model/AgentEntity";
import { RouteEntity } from "RestClient/Client/Model/RouteEntity";
import { HotlistEntity } from "RestClient/Client/Model/LicensePlateRecognition/HotlistEntity";
import { SequenceEntity } from "RestClient/Client/Model/Video/SequenceEntity";
import { TilePluginEntity } from "RestClient/Client/Model/TilePluginEntity";
import { TransferGroupEntity } from "RestClient/Client/Model/Video/TransferGroupEntity";
import { CustomEntity } from "RestClient/Client/Model/CustomEntity";
import { TileLayoutEntity } from "RestClient/Client/Model/TileLayoutEntity";
import { DoorTemplateEntity } from "RestClient/Client/Model/AccessControl/DoorTemplateEntity";
import { Entity } from "RestClient/Client/Model/Entity";
import { PrivilegedEntity } from "RestClient/Client/Model/PrivilegedEntity";
import { AccessManagerRoleEntity } from "RestClient/Client/Model/AccessControl/AccessManagerRoleEntity";
import { AccessPointEntity } from "RestClient/Client/Model/AccessControl/AccessPointEntity";
import { AreaAccessPointEntity } from "RestClient/Client/Model/AccessControl/AreaAccessPointEntity";
import { DoorAccessPointEntity } from "RestClient/Client/Model/AccessControl/DoorAccessPointEntity";
import { DoorSideAccessPointEntity } from "RestClient/Client/Model/AccessControl/DoorSideAccessPointEntity";
import { MobileApplicationEntity } from "RestClient/Client/Model/Application/MobileApplicationEntity";
import { InputDeviceEntity } from "RestClient/Client/Model/Devices/InputDeviceEntity";
import { IntrusionInputDeviceEntity } from "RestClient/Client/Model/Devices/IntrusionInputDeviceEntity";
import { OutputDeviceEntity } from "RestClient/Client/Model/Devices/OutputDeviceEntity";
import { ContentFileEntity } from "RestClient/Client/Model/LicensePlateRecognition/ContentFileEntity";
import { LicensePlateManagerRoleEntity } from "RestClient/Client/Model/LicensePlateRecognition/LicensePlateManagerRole";
import { ActiveDirectoryRoleEntity } from "RestClient/Client/Model/Roles/ActiveDirectoryRoleEntity";
import { AdfsRoleEntity } from "RestClient/Client/Model/Roles/AdfsRoleEntity";
import { AuthenticationRoleEntity } from "RestClient/Client/Model/Roles/AuthenticationRoleEntity";
import { DirectoryFailoverRoleEntity } from "RestClient/Client/Model/Roles/DirectoryFailOverRoleEntity";
import { FederationBaseRoleEntity } from "RestClient/Client/Model/Roles/FederationBaseRoleEntity";
import { HealthMonitoringRoleEntity } from "RestClient/Client/Model/Roles/HealthMonitoringRoleEntity";
import { IncidentManagementRoleEntity } from "RestClient/Client/Model/Roles/IncidentManagementRoleEntity";
import { MapManagerRoleEntity } from "RestClient/Client/Model/Roles/MapManagerRoleEntity";
import { MediaGatewayRoleEntity } from "RestClient/Client/Model/Roles/MediaGatewayRoleEntity";
import { OmnicastFederationRoleEntity } from "RestClient/Client/Model/Roles/OmnicastFederationRoleEntity";
import { PluginRoleEntity } from "RestClient/Client/Model/Roles/PluginRoleEntity";
import { PointOfSaleRoleEntity } from "RestClient/Client/Model/Roles/PointOfSaleRoleEntity";
import { ReverseTunnelClientRoleEntity } from "RestClient/Client/Model/Roles/ReverseTunnelClientRoleEntity";
import { ReverseTunnelServerRoleEntity } from "RestClient/Client/Model/Roles/ReverseTunnelServerRoleEntity";
import { SecurityCenterFederationRoleEntity } from "RestClient/Client/Model/Roles/SecurityCenterFederationRoleEntity";
import { WebClientRoleEntity } from "RestClient/Client/Model/Roles/WebClientRoleEntity";
import { ZoneManagementRoleEntity } from "RestClient/Client/Model/Roles/ZoneManagementRoleEntity";
import { ArchiverAgentEntity } from "RestClient/Client/Model/Video/ArchiverAgentEntity";
import { ArchiverRoleEntity } from "RestClient/Client/Model/Video/ArchiverRoleEntity";
import { AuxiliaryArchiverRoleEntity } from "RestClient/Client/Model/Video/AuxiliaryArchiverRoleEntity";
import { MediaRouterRoleEntity } from "RestClient/Client/Model/Video/MediaRouterRoleEntity";
import { LocatableEntity } from "RestClient/Client/Model/LocatableEntity";

export class WrapperFactory {
    public static initialize(): void {
        try {
            // Access Control
            Object.setPrototypeOf(AccessControlUnitEntity.prototype, UnitEntityWrapper.prototype);
            Object.setPrototypeOf(AccessManagerRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(AccessPointEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(AccessPointRuleEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(AreaAccessPointEntity.prototype, AccessPointEntityWrapper.prototype);
            Object.setPrototypeOf(CardholderEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(CardholderGroupEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(CredentialEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(DoorAccessPointEntity.prototype, AccessPointEntityWrapper.prototype);
            Object.setPrototypeOf(DoorEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(DoorSideAccessPointEntity.prototype, DoorAccessPointEntityWrapper.prototype);
            Object.setPrototypeOf(DoorTemplateEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(ElevatorEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(VisitorEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(ZoneEntity.prototype, EntityWrapper.prototype);

            // Application
            Object.setPrototypeOf(ApplicationEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(MobileApplicationEntity.prototype, ApplicationEntityWrapper.prototype);

            // Devices
            Object.setPrototypeOf(DeviceEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(InputDeviceEntity.prototype, DeviceEntityWrapper.prototype);
            Object.setPrototypeOf(IntrusionInputDeviceEntity.prototype, InputDeviceEntityWrapper.prototype);
            Object.setPrototypeOf(OutputDeviceEntity.prototype, DeviceEntityWrapper.prototype);

            // Intrusion
            Object.setPrototypeOf(IntrusionAreaEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(IntrusionUnitEntity.prototype, UnitEntityWrapper.prototype);

            // LPR
            Object.setPrototypeOf(ContentFileEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(HotlistEntity.prototype, ContentFileEntityWrapper.prototype);
            Object.setPrototypeOf(LicensePlateManagerRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(PatrollerEntity.prototype, LocatableEntityWrapper.prototype);
            Object.setPrototypeOf(LprUnitEntity.prototype, UnitEntityWrapper.prototype);

            // Roles
            Object.setPrototypeOf(ActiveDirectoryRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(AdfsRoleEntity.prototype, AuthenticationRoleEntityWrapper.prototype);
            Object.setPrototypeOf(AuthenticationRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(DirectoryFailoverRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(FederationBaseRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(HealthMonitoringRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(IncidentManagementRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(MapManagerRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(MediaGatewayRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(OmnicastFederationRoleEntity.prototype, FederationBaseRoleEntityWrapper.prototype);
            Object.setPrototypeOf(PluginRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(PointOfSaleRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(ReverseTunnelClientRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(ReverseTunnelServerRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(RoleEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(SecurityCenterFederationRoleEntity.prototype, FederationBaseRoleEntityWrapper.prototype);
            Object.setPrototypeOf(WebClientRoleEntity.prototype, PluginRoleEntityWrapper.prototype);
            Object.setPrototypeOf(ZoneManagementRoleEntity.prototype, RoleEntityWrapper.prototype);

            // Video
            Object.setPrototypeOf(ArchiverAgentEntity.prototype, AgentEntityWrapper.prototype);
            Object.setPrototypeOf(ArchiverRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(AuxiliaryArchiverRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(CameraEntity.prototype, LocatableEntityWrapper.prototype);
            Object.setPrototypeOf(MediaRouterRoleEntity.prototype, RoleEntityWrapper.prototype);
            Object.setPrototypeOf(SequenceEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(StreamEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(TransferGroupEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(VideoUnitEntity.prototype, UnitEntityWrapper.prototype);

            // Generic
            Object.setPrototypeOf(AgentEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(AlarmEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(AreaEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(CustomEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(FileEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(LocatableEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(MacroEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(MapEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(NetworkEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(PartitionEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(PrivilegedEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(RouteEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(ScheduleEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(ServerEntity.prototype, LocatableEntityWrapper.prototype);
            Object.setPrototypeOf(SystemConfigurationEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(ThreatLevelEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(TileLayoutEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(TilePluginEntity.prototype, EntityWrapper.prototype);
            Object.setPrototypeOf(UnitEntity.prototype, LocatableEntityWrapper.prototype);
            Object.setPrototypeOf(UserEntity.prototype, PrivilegedEntityWrapper.prototype);
            Object.setPrototypeOf(UserGroupEntity.prototype, PrivilegedEntityWrapper.prototype);
            Object.setPrototypeOf(UserTaskEntity.prototype, EntityWrapper.prototype);
        } catch (e) {
            console.error(e as string);
        }
    }

    public static wrap<T extends Entity & U, U extends IEntity>(entity: IEntity): U {
        switch (entity.constructor) {
            case AccessControlUnitEntity:
                Object.setPrototypeOf(entity, AccessControlUnitEntityWrapper.prototype);
                break;
            case AccessManagerRoleEntity:
                Object.setPrototypeOf(entity, AccessManagerRoleEntityWrapper.prototype);
                break;
            case AccessPointEntity:
                Object.setPrototypeOf(entity, AccessPointEntityWrapper.prototype);
                break;
            case AccessPointRuleEntity:
                Object.setPrototypeOf(entity, AccessPointRuleEntityWrapper.prototype);
                break;
            case AreaAccessPointEntity:
                Object.setPrototypeOf(entity, AreaAccessPointEntityWrapper.prototype);
                break;
            case CardholderEntity:
                Object.setPrototypeOf(entity, CardholderEntityWrapper.prototype);
                break;
            case CardholderGroupEntity:
                Object.setPrototypeOf(entity, CardholderGroupEntityWrapper.prototype);
                break;
            case CredentialEntity:
                Object.setPrototypeOf(entity, CredentialEntityWrapper.prototype);
                break;
            case DoorAccessPointEntity:
                Object.setPrototypeOf(entity, DoorAccessPointEntityWrapper.prototype);
                break;
            case DoorEntity:
                Object.setPrototypeOf(entity, DoorEntityWrapper.prototype);
                break;
            case DoorSideAccessPointEntity:
                Object.setPrototypeOf(entity, DoorSideAccessPointEntityWrapper.prototype);
                break;
            case DoorTemplateEntity:
                Object.setPrototypeOf(entity, DoorTemplateEntityWrapper.prototype);
                break;
            case ElevatorEntity:
                Object.setPrototypeOf(entity, ElevatorEntityWrapper.prototype);
                break;
            case VisitorEntity:
                Object.setPrototypeOf(entity, VisitorEntityWrapper.prototype);
                break;
            case ZoneEntity:
                Object.setPrototypeOf(entity, ZoneEntityWrapper.prototype);
                break;
            case ApplicationEntity:
                Object.setPrototypeOf(entity, ApplicationEntityWrapper.prototype);
                break;
            case MobileApplicationEntity:
                Object.setPrototypeOf(entity, MobileApplicationEntityWrapper.prototype);
                break;
            case DeviceEntity:
                Object.setPrototypeOf(entity, DeviceEntityWrapper.prototype);
                break;
            case InputDeviceEntity:
                Object.setPrototypeOf(entity, InputDeviceEntityWrapper.prototype);
                break;
            case IntrusionInputDeviceEntity:
                Object.setPrototypeOf(entity, IntrusionInputDeviceEntityWrapper.prototype);
                break;
            case OutputDeviceEntity:
                Object.setPrototypeOf(entity, OutputDeviceEntityWrapper.prototype);
                break;
            case IntrusionAreaEntity:
                Object.setPrototypeOf(entity, IntrusionAreaEntityWrapper.prototype);
                break;
            case IntrusionUnitEntity:
                Object.setPrototypeOf(entity, IntrusionUnitEntityWrapper.prototype);
                break;
            case ContentFileEntity:
                Object.setPrototypeOf(entity, ContentFileEntityWrapper.prototype);
                break;
            case HotlistEntity:
                Object.setPrototypeOf(entity, HotlistEntityWrapper.prototype);
                break;
            case LicensePlateManagerRoleEntity:
                Object.setPrototypeOf(entity, LicensePlateManagerRoleEntityWrapper.prototype);
                break;
            case PatrollerEntity:
                Object.setPrototypeOf(entity, PatrollerEntityWrapper.prototype);
                break;
            case LprUnitEntity:
                Object.setPrototypeOf(entity, LprUnitEntityWrapper.prototype);
                break;
            case ActiveDirectoryRoleEntity:
                Object.setPrototypeOf(entity, ActiveDirectoryRoleEntityWrapper.prototype);
                break;
            case AdfsRoleEntity:
                Object.setPrototypeOf(entity, AdfsRoleEntityWrapper.prototype);
                break;
            case AuthenticationRoleEntity:
                Object.setPrototypeOf(entity, AuthenticationRoleEntityWrapper.prototype);
                break;
            case DirectoryFailoverRoleEntity:
                Object.setPrototypeOf(entity, DirectoryFailoverRoleEntityWrapper.prototype);
                break;
            case FederationBaseRoleEntity:
                Object.setPrototypeOf(entity, FederationBaseRoleEntityWrapper.prototype);
                break;
            case HealthMonitoringRoleEntity:
                Object.setPrototypeOf(entity, HealthMonitoringRoleEntityWrapper.prototype);
                break;
            case IncidentManagementRoleEntity:
                Object.setPrototypeOf(entity, IncidentManagementRoleEntityWrapper.prototype);
                break;
            case MapManagerRoleEntity:
                Object.setPrototypeOf(entity, MapManagerRoleEntityWrapper.prototype);
                break;
            case MediaGatewayRoleEntity:
                Object.setPrototypeOf(entity, MediaGatewayRoleEntityWrapper.prototype);
                break;
            case OmnicastFederationRoleEntity:
                Object.setPrototypeOf(entity, OmnicastFederationRoleEntityWrapper.prototype);
                break;
            case PluginRoleEntity:
                Object.setPrototypeOf(entity, PluginRoleEntityWrapper.prototype);
                break;
            case PointOfSaleRoleEntity:
                Object.setPrototypeOf(entity, PointOfSaleRoleEntityWrapper.prototype);
                break;
            case ReverseTunnelClientRoleEntity:
                Object.setPrototypeOf(entity, ReverseTunnelClientRoleEntityWrapper.prototype);
                break;
            case ReverseTunnelServerRoleEntity:
                Object.setPrototypeOf(entity, ReverseTunnelServerRoleEntityWrapper.prototype);
                break;
            case RoleEntity:
                Object.setPrototypeOf(entity, RoleEntityWrapper.prototype);
                break;
            case SecurityCenterFederationRoleEntity:
                Object.setPrototypeOf(entity, SecurityCenterFederationRoleEntityWrapper.prototype);
                break;
            case WebClientRoleEntity:
                Object.setPrototypeOf(entity, WebClientRoleEntityWrapper.prototype);
                break;
            case ZoneManagementRoleEntity:
                Object.setPrototypeOf(entity, ZoneManagementRoleEntityWrapper.prototype);
                break;
            case ArchiverAgentEntity:
                Object.setPrototypeOf(entity, ArchiverAgentEntityWrapper.prototype);
                break;
            case ArchiverRoleEntity:
                Object.setPrototypeOf(entity, ArchiverRoleEntityWrapper.prototype);
                break;
            case AuxiliaryArchiverRoleEntity:
                Object.setPrototypeOf(entity, AuxiliaryArchiverRoleEntityWrapper.prototype);
                break;
            case CameraEntity:
                Object.setPrototypeOf(entity, CameraEntityWrapper.prototype);
                break;
            case MediaRouterRoleEntity:
                Object.setPrototypeOf(entity, MediaRouterRoleEntityWrapper.prototype);
                break;
            case SequenceEntity:
                Object.setPrototypeOf(entity, SequenceEntityWrapper.prototype);
                break;
            case StreamEntity:
                Object.setPrototypeOf(entity, StreamEntityWrapper.prototype);
                break;
            case TransferGroupEntity:
                Object.setPrototypeOf(entity, TransferGroupEntityWrapper.prototype);
                break;
            case AgentEntity:
                Object.setPrototypeOf(entity, AgentEntityWrapper.prototype);
                break;
            case AlarmEntity:
                Object.setPrototypeOf(entity, AlarmEntityWrapper.prototype);
                break;
            case AreaEntity:
                Object.setPrototypeOf(entity, AreaEntityWrapper.prototype);
                break;
            case CustomEntity:
                Object.setPrototypeOf(entity, CustomEntityWrapper.prototype);
                break;
            case FileEntity:
                Object.setPrototypeOf(entity, FileEntityWrapper.prototype);
                break;
            case LocatableEntity:
                Object.setPrototypeOf(entity, LocatableEntityWrapper.prototype);
                break;
            case MacroEntity:
                Object.setPrototypeOf(entity, MacroEntityWrapper.prototype);
                break;
            case MapEntity:
                Object.setPrototypeOf(entity, MapEntityWrapper.prototype);
                break;
            case NetworkEntity:
                Object.setPrototypeOf(entity, NetworkEntityWrapper.prototype);
                break;
            case PartitionEntity:
                Object.setPrototypeOf(entity, PartitionEntityWrapper.prototype);
                break;
            case PrivilegedEntity:
                Object.setPrototypeOf(entity, PrivilegedEntityWrapper.prototype);
                break;
            case RouteEntity:
                Object.setPrototypeOf(entity, RouteEntityWrapper.prototype);
                break;
            case ScheduleEntity:
                Object.setPrototypeOf(entity, ScheduleEntityWrapper.prototype);
                break;
            case ServerEntity:
                Object.setPrototypeOf(entity, ServerEntityWrapper.prototype);
                break;
            case SystemConfigurationEntity:
                Object.setPrototypeOf(entity, SystemConfigurationEntityWrapper.prototype);
                break;
            case ThreatLevelEntity:
                Object.setPrototypeOf(entity, ThreatLevelEntityWrapper.prototype);
                break;
            case TileLayoutEntity:
                Object.setPrototypeOf(entity, TileLayoutEntityWrapper.prototype);
                break;
            case TilePluginEntity:
                Object.setPrototypeOf(entity, TilePluginEntityWrapper.prototype);
                break;
            case UnitEntity:
                Object.setPrototypeOf(entity, UnitEntityWrapper.prototype);
                break;
            case UserEntity:
                Object.setPrototypeOf(entity, UserEntityWrapper.prototype);
                break;
            case UserGroupEntity:
                Object.setPrototypeOf(entity, UserGroupEntityWrapper.prototype);
                break;
            case UserTaskEntity:
                Object.setPrototypeOf(entity, UserTaskEntityWrapper.prototype);
                break;
        }

        return entity as U;
    }
}
