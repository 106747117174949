import { RestResponse } from './RestResponse';
import * as Args from './RestArgs';
import * as Helpers from '../Helpers/Helpers';
import { EventFilter } from './EventFilter';
import HttpStatusCode from '../Client/Enumerations/HttpStatusCode';
import { FieldObject } from '../Helpers/FieldObject';
import { MultiJsonParser } from '../Helpers/multiJsonParser';
import { StreamerBase } from './Streamers/StreamerBase';
import { Streamer } from './Streamers/Streamer';
import { Globals } from '../Globals';
import { IGuid } from 'safeguid';

export class RestConnection {
    // #region Fields
    protected _maxUriLength = 2000;
    protected _restStreamer!: StreamerBase;
    protected _restServerUrl = '';
    protected _restConnectionTestUrl: string;
    protected _additionalHeaders = new Map<string, string>();
    protected _authorizationHeader = '';
    protected _retryCount = 0;
    protected _retryDelay = 0;

    // #endregion

    // #region Properties

    public get restServerUrl(): string {
        return this._restServerUrl;
    }

    public get additionalHeaders(): Map<string, string> {
        return this._additionalHeaders;
    }

    public get appName(): string {
        const result = this._additionalHeaders.get('RestAppName');
        if (result) {
            return result;
        } else {
            return 'Default';
        }
    }

    public set appName(value: string) {
        this.addAdditionalHeader('RestAppName', value);
    }

    public allowImpersonate = false;

    public get authorizationHeader(): string {
        return this._authorizationHeader;
    }

    public set authorizationHeader(value: string) {
        this._authorizationHeader = value;
        this._restStreamer.authorizationHeader = value;
    }

    public entitiesStreamHubName: string;

    public get streamerConnectionId(): string {
        return this._restStreamer.streamerConnectionId;
    }

    public get retryCount(): number {
        return this._retryCount;
    }

    public set retryCount(value: number) {
        this._retryCount = value;
        this._restStreamer.retryCount = value;
    }

    public get retryDelay(): number {
        return this._retryDelay;
    }

    public set retryDelay(value: number) {
        this._retryCount = value;
        this._restStreamer.retryDelay = value;
    }

    public get streamer(): StreamerBase {
        return this._restStreamer;
    }

    public isWebSocket = false;
    public enableWebSocket = true;
    public enableSSE = true;
    public windowsAuthentificationEnabled = false;

    // #endregion

    // #region Constructor

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Constructor
    constructor(enableWebSocket?: boolean, enableSSE?: boolean) {
        this.appName = 'Unknown';
        this.entitiesStreamHubName = 'EntitiesStreamHub';
        this._restConnectionTestUrl = '/v2/help';
        if (enableWebSocket != undefined) {
            this.enableWebSocket = enableWebSocket;
        }
        if (enableSSE != undefined) {
            this.enableSSE = enableSSE;
        }
        this.buildStreamer();
    }

    // #endregion

    // #region static methods

    public static buildBasicAuthorizationHeader(username: string | null, password: string | null): string {
        if (password == null) {
            password = '';
        }
        return 'Basic ' + window.btoa(username + ':' + password);
    }

    public static buildTokenAuthorizationHeader(token: string): string {
        return 'Token ' + token;
    }

    public static normalizeValue(input: string): string {
        if (input !== null) {
            return input.replace(/[^a-zA-Z0-9._-]/, '');
        }
        return 'Unknown';
    }

    // #endregion

    // #region Public methods

    public buildStreamer(): void {
        this._restStreamer = new Streamer(this);
    }

    public async disposeAsync() {
        await this._restStreamer.disposeAsync();
        return;
    }

    public setupConnection(url: string, username: string | null, password: string | null, authorizationToken: string | null) {
        this._restServerUrl = url;
        if (!username === false) {
            this.authorizationHeader = RestConnection.buildBasicAuthorizationHeader(username, password);
        } else {
            if (authorizationToken) {
                this.authorizationHeader = authorizationToken;
            }
        }
    }

    public async executeRequestWithResponseTypeAsync(
        method: string,
        url: string,
        body: string | null,
        responseType: 'arraybuffer' | 'blob' | 'formdata' | 'json' | 'text',
        cancelToken?: Helpers.CancellationToken | null,
    ) {
        return await this.internalExecuteRequestAsync(method, url, body, cancelToken, responseType);
    }

    public async executeRequestAsync(method: string, url: string, body: string | null, cancelToken?: Helpers.CancellationToken | null) {
        return await this.internalExecuteRequestAsync(method, url, body, cancelToken);
    }

    private internalExecuteRequestAsync(
        method: string,
        url: string,
        body: string | null,
        cancelToken?: Helpers.CancellationToken | null,
        responseType?: 'arraybuffer' | 'blob' | 'formdata' | 'json' | 'text',
    ) {
        let cancelTokenSubscription: (() => void) | undefined;
        return new Promise<RestResponse>(async (resolve, reject) => {
            try {
                let restUrl = '';
                if (url.startsWith('/') === true) {
                    url = url.substring(1);
                }
                if (this.restServerUrl.endsWith('/') === false) {
                    restUrl = this.restServerUrl + '/' + url;
                } else {
                    restUrl = this.restServerUrl + url;
                }

                const requestHeaders: HeadersInit = new Headers();
                if (method.toUpperCase() === 'GET' && restUrl.length > this._maxUriLength) {
                    // query too long... transform it
                    method = 'POST';
                    const splitUrl = restUrl.split('?');
                    restUrl = splitUrl[0];
                    const fo = new FieldObject();
                    fo.setField('Query', splitUrl[1]);
                    body = fo.toString();
                    requestHeaders.append('X-HTTP-Method-Override', 'GET');
                }

                if (this.allowImpersonate === true) {
                    requestHeaders.set('AllowImpersonate', 'Impersonate');
                }

                requestHeaders.set('Content-Type', 'application/json');
                if (!this.windowsAuthentificationEnabled && this.authorizationHeader.length > 0) {
                    requestHeaders.set('Authorization', this.authorizationHeader);
                }
                for (const [key, value] of this._additionalHeaders) {
                    requestHeaders.set(key, value);
                }

                if (method.toUpperCase() !== 'GET' && body === null) {
                    body = '{}';
                }

                const requestInit: RequestInit = {
                    method: method,
                    headers: requestHeaders,
                    body: body,
                };
                if (cancelToken) {
                    const controller = new AbortController();
                    if (cancelToken.timeout !== -1) {
                        setTimeout(() => controller.abort(), cancelToken.timeout);
                    }
                    cancelTokenSubscription = cancelToken.onCancel(() => controller.abort());
                    requestInit.signal = controller.signal;
                }

                let restResponse: RestResponse;
                const fetchResponse = await fetch(restUrl, requestInit);
                if (fetchResponse?.body === null) {
                    restResponse = new RestResponse(HttpStatusCode.INTERNAL_SERVER_ERROR);
                    resolve(restResponse);
                    return;
                }

                if (fetchResponse.status === HttpStatusCode.REQUEST_TIMEOUT) {
                    const response = new RestResponse(HttpStatusCode.REQUEST_TIMEOUT, null, null, true);
                    reject(response);
                    return;
                }

                let responseBody: any | null = null;
                if (responseType) {
                    switch (responseType) {
                        case 'arraybuffer': {
                            responseBody = await fetchResponse.arrayBuffer();
                            break;
                        }
                        case 'blob': {
                            responseBody = await fetchResponse.blob();
                            break;
                        }
                        case 'formdata': {
                            responseBody = await fetchResponse.formData();
                            break;
                        }
                        case 'json': {
                            responseBody = await fetchResponse.json();
                            break;
                        }
                    }
                }

                // Default is json
                if (!responseBody) {
                    responseBody = await fetchResponse.text();
                }
                const response = new RestResponse(fetchResponse.status, responseBody, { response: fetchResponse, responseType }, false);
                resolve(response);
            } catch (e) {
                reject(e);
            } finally {
                cancelTokenSubscription?.();
            }
        });
    }

    public async getStreamedJsonAsync(
        url: string,
        verb: string,
        body: string | null,
        callback: (e: object) => void,
        cancelToken?: Helpers.CancellationToken | null,
    ): Promise<RestResponse> {
        const multiParser = new MultiJsonParser();
        multiParser.onObject = (json: object) => {
            callback(json);
        };

        let cancelTokenSubscription: (() => void) | undefined;
        return new Promise<RestResponse>(async (resolve, reject) => {
            try {
                let restUrl = '';
                if (url.startsWith('/') === true) {
                    url = url.substring(1);
                }
                if (this.restServerUrl.endsWith('/') === false) {
                    restUrl = this.restServerUrl + '/' + url;
                } else {
                    restUrl = this.restServerUrl + url;
                }

                const requestHeaders: HeadersInit = new Headers();
                if (!this.windowsAuthentificationEnabled && this.authorizationHeader.length > 0) {
                    requestHeaders.set('Authorization', this.authorizationHeader);
                }
                requestHeaders.set('Content-Type', 'application/octet-stream');
                requestHeaders.set('EnableStreamResponse', 'true');
                for (const [key, value] of this._additionalHeaders) {
                    requestHeaders.set(key, value);
                }

                if (verb.toUpperCase() !== 'GET' && body === null) {
                    body = '{}';
                }

                const requestInit: RequestInit = {
                    method: verb,
                    headers: requestHeaders,
                    body: body,
                };

                if (cancelToken) {
                    const controller = new AbortController();
                    if (cancelToken.timeout !== -1) {
                        setTimeout(() => controller.abort(), cancelToken.timeout);
                    }
                    cancelTokenSubscription = cancelToken.onCancel(() => controller.abort());
                    requestInit.signal = controller.signal;
                }

                let restResponse: RestResponse;
                const fetchResponse = await fetch(restUrl, requestInit);
                if (fetchResponse.body === null) {
                    restResponse = new RestResponse(HttpStatusCode.INTERNAL_SERVER_ERROR);
                    resolve(restResponse);
                    return;
                }
                if (fetchResponse.status === HttpStatusCode.REQUEST_TIMEOUT) {
                    const response = new RestResponse(HttpStatusCode.REQUEST_TIMEOUT, null, null, true);
                    reject(response);
                    return;
                }

                const reader = fetchResponse.body?.getReader();
                while (true) {
                    const result = await reader.read();
                    if (result.done) {
                        restResponse = new RestResponse(fetchResponse.status);
                        resolve(restResponse);
                        return;
                    }
                    const text = new TextDecoder('utf-8').decode(result.value);
                    multiParser.parse(text);
                }
            } catch (e) {
                reject(e);
            } finally {
                cancelTokenSubscription?.();
            }
        });
    }

    public async waitForRestReadyAsync(cancelToken?: Helpers.CancellationToken | null) {
        const that = this;
        return new Promise<RestResponse>(async function (resolve, reject) {
            while (!cancelToken || cancelToken.isCancellationRequested === false) {
                try {
                    const response = await that.executeRequestAsync('GET', that._restConnectionTestUrl, null, cancelToken);
                    switch (response.statusCode) {
                        case HttpStatusCode.OK: {
                            resolve(response);
                            return;
                        }

                        case HttpStatusCode.FORBIDDEN:
                        case HttpStatusCode.UNAUTHORIZED: {
                            resolve(response);
                            return;
                        }

                        default: {
                            await Helpers.sleepAsync(5000);
                            break;
                        }
                    }
                } catch (e) {
                    await Helpers.sleepAsync(5000);
                }
            }
            resolve(new RestResponse(HttpStatusCode.REQUEST_TIMEOUT, null, null, true));
        });
    }

    public addAdditionalHeader(headerKey: string, headerValue: string, normalize = true) {
        const value = normalize === true ? RestConnection.normalizeValue(headerValue) : headerValue;
        this._additionalHeaders.set(headerKey, value);
    }

    public setImpersonatedApp(serviceCredential: string, app: string) {
        this.addAdditionalHeader(Globals.impersonateServiceCredentialHeader, serviceCredential, false);
        this.addAdditionalHeader(Globals.impersonateAppHeader, app, false);
    }

    public async startStreamerAsync(cancelToken?: Helpers.CancellationToken | null) {
        return await this._restStreamer.startStreamerAsync(cancelToken);
    }

    public async stopStreamerAsync() {
        return await this._restStreamer.stopStreamerAsync();
    }

    public clearCurrentFitler(): void {
        this._restStreamer.clearCurrentFilter();
    }

    public async setStreamerFilterAsync(filter: EventFilter) {
        await this._restStreamer.setStreamerFilterAsync(filter);
    }

    public async addToStreamerFilterAsync(filter: EventFilter): Promise<IGuid> {
        return await this._restStreamer.addToStreamerFilterAsync(filter);
    }

    public async removeFromStreamerFilterAsync(filterIds: Set<IGuid>) {
        return await this._restStreamer.removeFromStreamerFilterAsync(filterIds);
    }

    public async sendRequestResponseAsync(id: string, response: string) {
        return await this._restStreamer.sendRequestResponseAsync(id, response);
    }

    public async increaseDelayRequestResponseAsync(id: string, timeout: number) {
        return await this._restStreamer.increaseDelayRequestResponseAsync(id, timeout);
    }

    public async setReportFilterAsync(customReportIds: Set<string>) {
        return await this._restStreamer.setReportFilterAsync(customReportIds);
    }

    public async sendQueryResultAsync(result: FieldObject) {
        return await this._restStreamer.sendQueryResultAsync(result);
    }

    public async sendQueryCompletedAsync(result: FieldObject) {
        return await this._restStreamer.sendQueryCompletedAsync(result);
    }

    public async addConsoleDebugCommandAsync(group: string, method: string, param1: string, param2: string, param3: string) {
        return await this._restStreamer.addConsoleDebugCommandAsync(group, method, param1, param2, param3);
    }

    public async pauseConnectionAsync(): Promise<void> {
        return this._restStreamer.pauseConnectionAsync();
    }

    public async getApplicationIdAsync() {
        const result = await this._restStreamer.getApplicationIdAsync();
        return result;
    }

    // #endregion

    // #region Event handling methods

    public onConnectionStateChanged(handler: Helpers.Handler<Args.StateChangeArg>) {
        return this._restStreamer._streamerStateChangedDispatcher.subscribe(handler);
    }

    public onEntityInvalidated(handler: Helpers.Handler<Args.EntityInvalidatedArg>) {
        return this._restStreamer._streamerEntityInvalidatedDispatcher.subscribe(handler);
    }

    public onEntityAdded(handler: Helpers.Handler<Args.EntityAddedArg>) {
        return this._restStreamer._streamerEntityAddedDispatcher.subscribe(handler);
    }

    public onEntityRemoved(handler: Helpers.Handler<Args.EntityRemovedArg>) {
        return this._restStreamer._streamerEntityRemovedDispatcher.subscribe(handler);
    }

    public onEventReceived(handler: Helpers.Handler<FieldObject>) {
        return this._restStreamer._streamerEventDispatcher.subscribe(handler);
    }

    public onActionReceived(handler: Helpers.Handler<FieldObject>) {
        return this._restStreamer._streamerActionDispatcher.subscribe(handler);
    }

    public onQueryReceived(handler: Helpers.Handler<Args.QueryReceivedArg>) {
        return this._restStreamer._streamerQueryDispatcher.subscribe(handler);
    }

    public onRequestReceived(handler: Helpers.Handler<Args.RequestReceivedArg>) {
        return this._restStreamer._streamerRequestDispatcher.subscribe(handler);
    }

    public onDisconnectRequestReceived(handler: Helpers.AsyncHandler<Args.DisconnectRequestArgs>) {
        return this._restStreamer._streamerDisconnectRequestDispatcher.subscribe(handler);
    }

    public onConsoleCommandRequestReceived(handler: Helpers.Handler<Args.ConsoleCommandRequestReceivedArg>) {
        return this._restStreamer._streamerConsoleCommandRequestReceivedDispatcher.subscribe(handler);
    }

    public onSessionIdReceived(handler: Helpers.AsyncHandler<string>) {
        return this._restStreamer._streamerSessionIdReceivedDispatcher.subscribe(handler);
    }

    // #endregion

    // #region Private methods

    // #endregion
}
