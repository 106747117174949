// Autogenerated: v2/help/Entities/ApiAccessPointRule?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:25 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IAccessPointRuleEntity, AccessPointRuleEntityFields } from '../../Interface/IAccessPointRuleEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class AccessPointRuleEntity extends Entity implements IAccessPointRuleEntity {
    // #region REST Properties

    // AccessPointRuleType
    public get accessPointRuleType(): string {
        return this.getField<string>(AccessPointRuleEntityFields.accessPointRuleTypeField);
    }

    // DefaultRule
    public get defaultRule(): boolean {
        return this.getField<boolean>(AccessPointRuleEntityFields.defaultRuleField);
    }
    public set defaultRule(value: boolean) {
        this.setField<boolean>(AccessPointRuleEntityFields.defaultRuleField, value);
    }

    // EndTime
    public get endTime(): Date {
        return this.getFieldDate(AccessPointRuleEntityFields.endTimeField);
    }
    public set endTime(value: Date) {
        this.setFieldDate(AccessPointRuleEntityFields.endTimeField, value);
    }

    // Positive
    public get positive(): boolean {
        return this.getField<boolean>(AccessPointRuleEntityFields.positiveField);
    }
    public set positive(value: boolean) {
        this.setField<boolean>(AccessPointRuleEntityFields.positiveField, value);
    }

    // Schedule
    public get schedule(): IGuid {
        return this.getFieldGuid(AccessPointRuleEntityFields.scheduleField);
    }
    public set schedule(value: IGuid) {
        this.setFieldGuid(AccessPointRuleEntityFields.scheduleField, value);
    }

    // StartTime
    public get startTime(): Date {
        return this.getFieldDate(AccessPointRuleEntityFields.startTimeField);
    }
    public set startTime(value: Date) {
        this.setFieldDate(AccessPointRuleEntityFields.startTimeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.AccessRules);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointGroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPointGroups', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPoints', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getCardholdersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cardholders', 'Id', false, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

