// Autogenerated: v2/help/Entities/ApiCredential?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { IGuid } from 'safeguid';
import { ICredentialEntity, CredentialEntityFields } from '../../Interface/ICredentialEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';

export class CredentialEntity extends Entity implements ICredentialEntity {
    // #region REST Properties

    // AccessStatus
    public get accessStatus(): string {
        return this.getField<string>(CredentialEntityFields.accessStatusField, ValidFlags.Specific);
    }
    public set accessStatus(value: string) {
        this.setField<string>(CredentialEntityFields.accessStatusField, value);
    }

    // ActivationDate
    public get activationDate(): Date {
        return this.getFieldDate(CredentialEntityFields.activationDateField, ValidFlags.Specific);
    }
    public set activationDate(value: Date) {
        this.setFieldDate(CredentialEntityFields.activationDateField, value);
    }

    // BadgeTemplate
    public get badgeTemplate(): IGuid {
        return this.getFieldGuid(CredentialEntityFields.badgeTemplateField, ValidFlags.Specific);
    }
    public set badgeTemplate(value: IGuid) {
        this.setFieldGuid(CredentialEntityFields.badgeTemplateField, value);
    }

    // Cardholder
    public get cardholder(): IGuid {
        return this.getFieldGuid(CredentialEntityFields.cardholderField, ValidFlags.Specific);
    }
    public set cardholder(value: IGuid) {
        this.setFieldGuid(CredentialEntityFields.cardholderField, value);
    }

    // CardNumber
    public get cardNumber(): number {
        return this.getField<number>(CredentialEntityFields.cardNumberField);
    }
    public set cardNumber(value: number) {
        this.setField<number>(CredentialEntityFields.cardNumberField, value);
    }

    // CredentialCode
    public get credentialCode(): string {
        return this.getField<string>(CredentialEntityFields.credentialCodeField, ValidFlags.Specific);
    }

    // CredentialType
    public get credentialType(): string {
        return this.getField<string>(CredentialEntityFields.credentialTypeField, ValidFlags.Base);
    }
    public set credentialType(value: string) {
        this.setField<string>(CredentialEntityFields.credentialTypeField, value);
    }

    // ExpirationDate
    public get expirationDate(): Date {
        return this.getFieldDate(CredentialEntityFields.expirationDateField, ValidFlags.Specific);
    }
    public set expirationDate(value: Date) {
        this.setFieldDate(CredentialEntityFields.expirationDateField, value);
    }

    // ExpirationDuration
    public get expirationDuration(): number {
        return this.getField<number>(CredentialEntityFields.expirationDurationField, ValidFlags.Specific);
    }
    public set expirationDuration(value: number) {
        this.setField<number>(CredentialEntityFields.expirationDurationField, value);
    }

    // ExpirationMode
    public get expirationMode(): string {
        return this.getField<string>(CredentialEntityFields.expirationModeField, ValidFlags.Specific);
    }
    public set expirationMode(value: string) {
        this.setField<string>(CredentialEntityFields.expirationModeField, value);
    }

    // Facility
    public get facility(): number {
        return this.getField<number>(CredentialEntityFields.facilityField);
    }
    public set facility(value: number) {
        this.setField<number>(CredentialEntityFields.facilityField, value);
    }

    // Format
    public get format(): IGuid {
        return this.getFieldGuid(CredentialEntityFields.formatField);
    }
    public set format(value: IGuid) {
        this.setFieldGuid(CredentialEntityFields.formatField, value);
    }

    // FormatFriendlyName
    public get formatFriendlyName(): string {
        return this.getField<string>(CredentialEntityFields.formatFriendlyNameField, ValidFlags.Specific);
    }

    // KeyPad
    public set keyPad(value: number) {
        this.setField<number>(CredentialEntityFields.keyPadField, value);
    }

    // UniqueId
    public get uniqueId(): string {
        return this.getField<string>(CredentialEntityFields.uniqueIdField, ValidFlags.Specific);
    }
    public set uniqueId(value: string) {
        this.setField<string>(CredentialEntityFields.uniqueIdField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Credentials);
    }

    // #endregion Constructor

}

