import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, IconSize, ItemSlot, TextFlavor } from '@genetec/gelato';
import { GenMenu, MeltedIcon } from '@genetec/gelato-angular';
import { CustomIconState } from '@modules/shared/api/api';
import { ContextMenuItem } from '@modules/shared/interfaces/context-menu-item/context-menu-item';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CommandsUsage } from '@modules/shared/services/commands/commands-usage/commands-usage';
import { TileHeaderService } from '@modules/tiles/components/tile-state-header/services/tile-state-header.service';
import { TileDetailsVisibility } from '@modules/tiles/components/tile-state-header/tile-state-header.component';
import { TileItem } from '@modules/tiles/models/tile-item';
import { TileService } from '@modules/tiles/services/tile.service';
import { ContextMenuItemSwitcherService } from '@shared/services/context-menu/context-menu-item-switcher.service';
import { uniqueId } from 'lodash-es';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IGuid } from 'safeguid';

/**
 * @copyright Copyright (C) 2020 by Genetec Inc. All rights reserved. May be used only in accordance with a valid Source Code License Agreement.
 * @description This is a display component that is designed to be nested inside a plugin. The scope is to only support doors, zones and cameras.
 * @example door-entity-state is a plugin that is injected on a tile, and uses <app-entity-state-overlay>.
 *          It will pass down all the inputs to it, and entity-state-overlay will take care of the rest.
 * @link Analysis document: https://nietzsche.genetec.com/display/UW/WebApp+-+Tiles+-+Entity+States
 */
@UntilDestroy()
@Component({
    selector: 'app-entity-state-overlay',
    templateUrl: './entity-state-overlay.component.html',
    styleUrls: ['./entity-state-overlay.component.scss'],
})
export class EntityStateOverlayComponent implements OnInit {
    @ViewChild('contextMenu') public caretMenu!: GenMenu;

    @Input()
    public content?: Content;

    @Input()
    public commandsUsage!: CommandsUsage | null;

    @Input()
    public excludedCommandIds: IGuid[] = [];

    @Input()
    public maximumButtonCount = 1;

    @Input()
    public customIconId?: IGuid | null;

    @Input()
    public customIconState?: CustomIconState | null;

    @Input()
    public entityIcon?: Icon | string | null;

    @Input()
    public commandsLoadingChangeCallback?: (val: boolean) => void;

    /**
     * RelatedContent is the data that will be displayed inside the entity switcher caret.
     */
    public set relatedContent(content: Content[]) {
        // clear entitySwitcher data.
        const dataSource: ContextMenuItem[] = [];
        this.#relatedContent = content;

        // Process related content for caret dropdown.
        this.#relatedContent.forEach((relatedContent: Content, index) => {
            dataSource.push({
                guid: relatedContent.id,
                id: index.toString(),
                text: relatedContent.title,
                icon: MeltedIcon.None,
                actionItem: { execute: this.switchEntityCallback.bind(this) },
            } as ContextMenuItem);
        });

        // Set first item as "Selected"
        if (dataSource.length > 0) {
            this.entitySwitcherDataSource = dataSource;
            this.entitySwitcherDataSourceSubject.next(dataSource);
            const firstEntity = dataSource[0];
            this.currentEntity = firstEntity;
            this.currentEntityText = firstEntity.text;
            this.currentEntity.icon = MeltedIcon.Checkmark;
        }
    }
    public get relatedContent(): Content[] {
        // eslint-disable-next-line no-underscore-dangle
        return this.#relatedContent;
    }

    // Enums
    public readonly ItemSlot = ItemSlot;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly IconSize = IconSize;
    public readonly componentGuid = uniqueId();
    public readonly TileDetailsVisibility = TileDetailsVisibility;
    public readonly RunningState = RunningState;

    // Public fields (used in template)
    public isComponentFullyLoaded = false;
    public areButtonsSpaced = false;
    public entityId = '';
    public title = '';
    public titleColor = '';
    public description = '';
    public entitySwitcherDataSource$ = new Observable<ContextMenuItem[]>();
    public currentEntity: ContextMenuItem = { id: '', text: '' };
    public tileId = -1;
    public tileDetailsVisibility?: TileDetailsVisibility;
    public associatedTileItem!: TileItem;
    public currentEntityText = '';
    public entitySwitcherDataSource: ContextMenuItem[] = [];

    // Private fields
    #relatedContent: Content[] = [];

    private entitySwitcherDataSourceSubject = new ReplaySubject<ContextMenuItem[]>();

    constructor(
        private contextMenuItemSwitcherService: ContextMenuItemSwitcherService,
        private tileService: TileService,
        private tileHeaderService: TileHeaderService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.entitySwitcherDataSource$ = this.entitySwitcherDataSourceSubject.asObservable();

        this.tileHeaderService.tileDetailsVisibility$.pipe(untilDestroyed(this)).subscribe((visibility: TileDetailsVisibility) => {
            this.tileDetailsVisibility = visibility;
        });

        // process state change.
        this.tileHeaderService.state$.pipe(untilDestroyed(this)).subscribe((state) => {
            if (state) {
                this.relatedContent = state.content?.subContents ?? [];
                this.associatedTileItem = state;
            }
        });
    }

    ngOnInit() {
        const content = this.content;
        if (content) {
            this.entityId = content.source;
            this.title = content.title;
            this.description = content.description;
            this.titleColor = content.color;
            this.entityIcon ??= content.icon;
            this.relatedContent = content?.contextsubContents ?? [];
        }
    }

    /**
     * Fired when the app-command-buttons are done loading
     *
     * @param val whether the buttons are still loading (true if loading, false if loaded)
     */
    public isLoadingChangeCallback(val: boolean): void {
        // this fixes an issue with ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.isComponentFullyLoaded = !val;
            this.changeDetectorRef.markForCheck();
        });
    }

    public toggleEntitySwitcher(): void {
        this.caretMenu?.toggle().fireAndForget();
    }

    /**
     * Will change video feed to the selected item.
     *
     * @param item selected item from the caret's context menu
     */
    public async switchEntityCallback(item: ContextMenuItem): Promise<void> {
        // Change selection
        this.entitySwitcherDataSource$.pipe(take(1), untilDestroyed(this)).subscribe((dataSource) => {
            this.entitySwitcherDataSourceSubject.next(this.contextMenuItemSwitcherService.getNextDataSourceWithNewSelection(item.id, dataSource));
        });

        // Assign new current Entity
        this.currentEntity = item;
        this.currentEntityText = item.text;

        // use service to notify Tile Component that we want to change content.
        const tileItem = this.associatedTileItem;
        if (tileItem.content && item.guid) {
            // alter the order of the subcontents so that the selected on appears first.
            let temp: Content[] | undefined = [];
            const newSelectedCamera = tileItem.content.subContents.find((content) => content.id === item.guid);
            if (newSelectedCamera) {
                // make new array with everything except the selected item
                temp = tileItem.content.subContents.filter((val) => val.id !== item.guid);
                if (temp) {
                    // add selected item as first one in array
                    temp.unshift(newSelectedCamera);

                    // assign new reference
                    tileItem.content.subContents = temp;
                }
            }
            // Update tile content with that new contentGroup.
            await this.tileService.tryUpdateTileSubContentsAsync(tileItem, tileItem.content, false);
        }
    }
}
