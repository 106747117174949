// Autogenerated: v2/help/Classes/ApiLprReadEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:52 AM
import { TimezoneEvent } from './TimezoneEvent';
import { FieldObject } from '../../Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { ILprReadEvent, ILocation } from '../Interface/ILprReadEvent';

// #region Inner classes

export class Location extends FieldObject implements ILocation {
    // Address
    public get address(): string {
        return this.getField<string>('Address');
    }
    public set address(value: string) {
        this.setField<string>('Address', value);
    }
    // Altitude
    public get altitude(): number {
        return this.getField<number>('Altitude');
    }
    public set altitude(value: number) {
        this.setField<number>('Altitude', value);
    }
    // Latitude
    public get latitude(): number {
        return this.getField<number>('Latitude');
    }
    public set latitude(value: number) {
        this.setField<number>('Latitude', value);
    }
    // Longitude
    public get longitude(): number {
        return this.getField<number>('Longitude');
    }
    public set longitude(value: number) {
        this.setField<number>('Longitude', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Address', '');
        this.setField<number>('Altitude', 0);
        this.setField<number>('Latitude', 0);
        this.setField<number>('Longitude', 0);
    }
}

// #endregion Inner classes

export class LprReadEvent extends TimezoneEvent implements ILprReadEvent {
    // #region REST Properties

    // AttributesXml
    public get attributesXml(): string {
        return this.getField<string>('AttributesXml');
    }
    public set attributesXml(value: string) {
        this.setField<string>('AttributesXml', value);
    }

    // ContextThumbnailImage
    public get contextThumbnailImage(): string {
        return this.getField<string>('ContextThumbnailImage');
    }
    public set contextThumbnailImage(value: string) {
        this.setField<string>('ContextThumbnailImage', value);
    }

    // DefaultColor
    public get defaultColor(): string {
        return this.getField<string>('DefaultColor');
    }
    public set defaultColor(value: string) {
        this.setField<string>('DefaultColor', value);
    }

    // DeviceId
    public get deviceId(): IGuid {
        return this.getFieldGuid('DeviceId');
    }
    public set deviceId(value: IGuid) {
        this.setFieldGuid('DeviceId', value);
    }

    // EditedRead
    public get editedRead(): boolean {
        return this.getField<boolean>('EditedRead');
    }
    public set editedRead(value: boolean) {
        this.setField<boolean>('EditedRead', value);
    }

    // Heading
    public get heading(): number {
        return this.getField<number>('Heading');
    }
    public set heading(value: number) {
        this.setField<number>('Heading', value);
    }

    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }

    // IsHit
    public get isHit(): boolean {
        return this.getField<boolean>('IsHit');
    }
    public set isHit(value: boolean) {
        this.setField<boolean>('IsHit', value);
    }

    // IsProtected
    public get isProtected(): boolean {
        return this.getField<boolean>('IsProtected');
    }
    public set isProtected(value: boolean) {
        this.setField<boolean>('IsProtected', value);
    }

    // IsRealTime
    public get isRealTime(): boolean {
        return this.getField<boolean>('IsRealTime');
    }
    public set isRealTime(value: boolean) {
        this.setField<boolean>('IsRealTime', value);
    }

    // Location
    public get location(): ILocation {
        return this.getFieldObject<Location, ILocation>(Location, 'Location');
    }
    public set location(value: ILocation) {
        this.setFieldObject<ILocation>('Location', value);
    }

    // LprThumbnailImage
    public get lprThumbnailImage(): string {
        return this.getField<string>('LprThumbnailImage');
    }
    public set lprThumbnailImage(value: string) {
        this.setField<string>('LprThumbnailImage', value);
    }

    // ManualRead
    public get manualRead(): boolean {
        return this.getField<boolean>('ManualRead');
    }
    public set manualRead(value: boolean) {
        this.setField<boolean>('ManualRead', value);
    }

    // PatrollerId
    public get patrollerId(): IGuid {
        return this.getFieldGuid('PatrollerId');
    }
    public set patrollerId(value: IGuid) {
        this.setFieldGuid('PatrollerId', value);
    }

    // Plate
    public get plate(): string {
        return this.getField<string>('Plate');
    }
    public set plate(value: string) {
        this.setField<string>('Plate', value);
    }

    // PlateState
    public get plateState(): string {
        return this.getField<string>('PlateState');
    }
    public set plateState(value: string) {
        this.setField<string>('PlateState', value);
    }

    // Speed
    public get speed(): number {
        return this.getField<number>('Speed');
    }
    public set speed(value: number) {
        this.setField<number>('Speed', value);
    }

    // UnitId
    public get unitId(): IGuid {
        return this.getFieldGuid('UnitId');
    }
    public set unitId(value: IGuid) {
        this.setFieldGuid('UnitId', value);
    }

    // VehicleMake
    public get vehicleMake(): string {
        return this.getField<string>('VehicleMake');
    }
    public set vehicleMake(value: string) {
        this.setField<string>('VehicleMake', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('AttributesXml', '');
        this.setField<string>('ContextThumbnailImage', '');
        this.setField<string>('DefaultColor', '');
        this.setFieldGuid('DeviceId', SafeGuid.EMPTY);
        this.setField<boolean>('EditedRead', false);
        this.setField<number>('Heading', 0);
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('IsHit', false);
        this.setField<boolean>('IsProtected', false);
        this.setField<boolean>('IsRealTime', false);
        const objLocation = new Location();
        objLocation.initializeAllFields();
        this.setFieldObject('Location', objLocation);
        this.setField<string>('LprThumbnailImage', '');
        this.setField<boolean>('ManualRead', false);
        this.setFieldGuid('PatrollerId', SafeGuid.EMPTY);
        this.setField<string>('Plate', '');
        this.setField<string>('PlateState', '');
        this.setField<number>('Speed', 0);
        this.setFieldGuid('UnitId', SafeGuid.EMPTY);
        this.setField<string>('VehicleMake', '');
    }

    // #endregion Methods

}

