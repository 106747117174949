import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, TextFlavor, PopupPosition } from '@genetec/gelato';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { IUserEntity, IHotAction } from 'RestClient/Client/Interface/IUserEntity';
import { UserManager } from 'RestClient/Client/Managers/UserManager';
import { GenPopup, GenToastService } from '@genetec/gelato-angular';
import { ItemSlot, SelectionType } from '@genetec/gelato';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { CommandBindings, CommandsService, COMMANDS_SERVICE, CommandsSubscription } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { IUserManager } from 'RestClient/Client/Interface/IUserManager';
import { Item } from '@modules/shared/interfaces/item';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-actions-tray',
    styleUrls: ['./actions-tray.component.scss'],
    templateUrl: './actions-tray.component.html',
})
@InternalPluginDescriptor({
    type: ActionsTrayComponent,
    pluginTypes: [PluginTypes.Tray],
    exposure: { id: ActionsTrayComponent.pluginId, priority: 2 },
    isSupported: () => true,
})
export class ActionsTrayComponent extends TrackedComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('00E5F5D0-1667-4756-B392-4F5690E91F65');

    private static baseCommandId = 'BAE5DDC0-D9A4-4837-8E75-AD48EBCC467';

    @ViewChild(GenPopup) actionsPopup!: GenPopup;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;
    public readonly PopupPosition = PopupPosition;
    public readonly ItemSlot = ItemSlot;
    public readonly ListSelectionType = SelectionType;

    public maxShortcut = 9;

    public quickActions: Item[] = [];

    private commandsSubscription!: CommandsSubscription;
    private scClient: SecurityCenterClient;
    private actions: IHotAction[] = [];

    constructor(
        private securityCenterClientService: SecurityCenterClientService,
        private translateService: TranslateService,
        private toastService: GenToastService,
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        private el: ElementRef
    ) {
        super(trackingService);

        this.scClient = securityCenterClientService?.scClient;
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.scClient = this.securityCenterClientService?.scClient;

        const currentUser = this.scClient?.currentUserInfo;
        if (currentUser) {
            const user = await this.scClient?.getEntityAsync<UserEntity, IUserEntity>(UserEntity, currentUser.id);
            if (user) {
                const hotActions = await user.getHotActionsAsync();
                if (hotActions) {
                    this.actions = Array.from(hotActions);
                    this.quickActions = this.actions.map((x, i) => ({
                        id: x.id.toString(),
                        text: x.description,
                        secondaryText: i < this.maxShortcut ? `Shift + F${i + 1}` : undefined,
                    }));
                }
            }
        }

        this.subscribeCommands();
    }

    public ngOnDestroy(): void {
        this.commandsSubscription?.unsubscribe();
        super.ngOnDestroy();
    }

    public async togglePopupOpen(): Promise<void> {
        await this.actionsPopup.toggle();
    }

    public async executeAction(action: Item | null): Promise<void> {
        if (action === null) {
            return;
        }

        await this.actionsPopup.close();
        const userManager = await this.scClient.getAsync<UserManager, IUserManager>(UserManager);
        const response = await userManager.executeHotActionAsync(SafeGuid.parse(action.id));
        if (response && response.statusCode === HttpStatusCode.OK) {
            const msg = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_ACTION_X_EXECUTED_SUCCESSFULLY') as string, action.text);
            this.toastService.show({ text: msg, flavorCustomColor: this.Color.Pistacchio });
        } else {
            const msg = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_ERROR_OCCURRED_EXECUTING_ACTION_X') as string, action.text);
            this.toastService.show({ text: msg, flavorCustomColor: this.Color.Fragola });
        }
    }

    private subscribeCommands(): void {
        if (this.commandsService) {
            for (let i = 1; i <= this.maxShortcut; i++) {
                this.commandsService.registerCommandShortcut(SafeGuid.parse(ActionsTrayComponent.baseCommandId + i.toString()), 'Shift.F' + i.toString());
            }

            const bindings = new CommandBindings();
            for (let i = 1; i <= this.maxShortcut; i++) {
                bindings.addCommand({
                    commandId: SafeGuid.parse(ActionsTrayComponent.baseCommandId + i.toString()),
                    executeCommandHandler: (executeCommandData) => {
                        this.executeAction(this.quickActions[i - 1]).fireAndForget();
                        executeCommandData.isHandled = true;
                    },
                });
            }

            this.commandsSubscription = this.commandsService.subscribe(bindings, { priority: 1 });
        }
    }
}
