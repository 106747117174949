// Autogenerated: CodeGen/EntityTypeData?language=ts&InterfaceOnly=True on 2021-04-21 3:54:20 PM
// Autogenerated: CodeGen/EntityTypeData?language=ts&InterfaceOnly=False on 2021-04-21 3:54:20 PM
import { IGuid, SafeGuid } from 'safeguid';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FieldObject } from 'RestClient/Helpers/FieldObject';

export interface IEntityTypeData extends IFieldObject {
    type: string;
    subType: string;
    subTypeGuid: IGuid | null;
}

export class EntityTypeData extends FieldObject implements IEntityTypeData {
    // Type
    public get type(): string {
        return this.getField<string>('Type');
    }
    public set type(value: string) {
        this.setField<string>('Type', value);
    }
    // SubType
    public get subType(): string {
        return this.getField<string>('SubType');
    }
    public set subType(value: string) {
        this.setField<string>('SubType', value);
    }
    // SubTypeGuid
    public get subTypeGuid(): IGuid | null {
        return this.getNullableFieldGuid('SubTypeGuid');
    }
    public set subTypeGuid(value: IGuid | null) {
        this.setNullableFieldGuid('SubTypeGuid', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Type', '');
        this.setField<string>('SubType', '');
        this.setFieldGuid('SubTypeGuid', SafeGuid.EMPTY);
    }

    //#region Custom Methods

    private _idString = "";

    // eslint-disable-next-line @typescript-eslint/member-ordering    
    public get idString(): string {
        if(!this._idString) {
            this.updateStringId();
        }

        return this._idString;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    constructor(type?: string, subType?: string | IGuid) {
        super();

        if (this.setTypeField(type)) {
            if (isInstanceOfGuid(subType)) {
                this.setSubTypeGuidField(subType)
            } else {
                this.setSubTypeField(subType);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static getEntityTypeData(entityType: EntityTypeData | IEntityTypeData | string, entitySubType?: string | IGuid): EntityTypeData {
        if (typeof entityType === 'string') {
            return new EntityTypeData(entityType, entitySubType);
        }

        return entityType as EntityTypeData;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public equals(other: EntityTypeData): boolean {
        return this.idString === other.idString;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public hasSubType(): boolean {
        return this.type !== this.idString;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public is(other: EntityTypeData): boolean {
        return this.type === other.type && !other.hasSubType();
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loadFields(config: { [k: string]: any }): void {
        if (config) {
            this._fields = new Map<string, any>();
            for (const key of Object.keys(config)) {
                // Key must be case insensitive
                const value = config[key];
                const lowerCaseKey = key.toLowerCase();
                if(lowerCaseKey === 'type') {
                    this.setTypeField(value);
                } else if(lowerCaseKey === 'subtype') {
                    this.setSubTypeField(value);
                } else if(lowerCaseKey === 'subtypeguid') {
                    this.setSubTypeGuidField(value);
                }
            }
        }
        this.fieldLoaded();
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public setField<T>(fieldId: string, newValue: T): void {
        if(fieldId === 'Type') {
            this.setTypeField(newValue);
        } else if(fieldId === 'SubType') {
            this.setSubTypeField(newValue);
        }else{
            super.setField(fieldId, newValue);
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public setNullableFieldGuid(fieldId: string, newValue: IGuid | null): void {
        if(fieldId === 'SubTypeGuid') {
            this.setSubTypeGuidField(newValue);
        } else {
            super.setNullableFieldGuid(fieldId, newValue);
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private setSubTypeField(value: any): boolean {
        if(isString(value) && value) {
            super.setField<string>('SubType', value);
            return true;
        } else {
            this._fields.delete('SubType');
        }
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private setSubTypeGuidField(value: any): boolean {
        if(isInstanceOfGuid(value) && !value.isEmpty()) {
            super.setNullableFieldGuid('SubTypeGuid', value);
            return true;
        } else {
            this._fields.delete('SubTypeGuid');
        }
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private setTypeField(value: any): boolean {
        if(isString(value) && value) {
            super.setField<string>('Type', value);
            return true;
        } else {
            this._fields.delete('Type');
        }
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private updateStringId(): void {
        if (this.hasField('Type')) {
            this._idString = this.type;

            if (this.hasField('SubType')) {
                if(this.subType) {
                    this._idString += this.subType;
                }
            } else if (this.hasField('SubTypeGuid')) {
                if(this.subTypeGuid?.isEmpty() === false) {
                    this._idString += this.subTypeGuid;    
                }
            }
        }
    }

    //#endregion Custom Methods
}
