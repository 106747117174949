import { Injectable } from '@angular/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IFileEntity } from 'RestClient/Client/Interface/IFileEntity';
import { FileEntity } from 'RestClient/Client/Model/FileEntity';
import { IGuid } from 'safeguid';
import { ResourcesService } from '../resources/resources.service';

@Injectable()
export class AudioService {
    constructor(private securityCenterClientService: SecurityCenterClientService, private resourcesService: ResourcesService) {}

    public async playAudio(id: IGuid): Promise<void> {
        const file = await this.securityCenterClientService.scClient?.getEntityAsync<FileEntity, IFileEntity>(FileEntity, id);
        if (file) {
            // no need to encrypt those sounds
            const fileResult = await this.resourcesService.getFilesCacheAsync(file, { encrypted: false });
            const raw = fileResult[file.cacheGuid.toString()];
            if (raw) {
                const audioSource = 'data:audio/mp3;base64,' + raw;
                const player = new Audio(audioSource);
                await player.play();
                return;
            }
        }

        throw new Error('Cannot play sound');
    }
}
