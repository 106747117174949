<ng-container [ngSwitch]="hasTileHeaderFeatureFlag">
    <ng-container *ngSwitchCase="true">
        <ng-container [ngTemplateOutlet]="WithFeatureFlag"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <ng-container [ngTemplateOutlet]="WithoutFeatureFlag"></ng-container>
    </ng-container>
</ng-container>

<ng-template #WithFeatureFlag>
    <ng-container *ngIf="tileNumberVisibility$ | async as tileNumberVisibility">
        <!-- Current entity is supported -->
        <ng-container *ngIf="pluginItem">
            <div class="tile-state-header" appClickStopPropagation @fadeSlideIn>
                <ng-container *ngIf="content !== undefined; else noData">
                    <!-- Tile number Section -->
                    <strong
                        class="tile-state-header__tile-number"
                        style="cursor: move"
                        [ngClass]="{
                            visible: tileNumberVisibility === TileNumberVisibility.Shown
                        }"
                    >
                        {{ tileNumber }}
                    </strong>

                    <!-- Host for plugins of type PluginTypes.TileHeader -->
                    <div class="tile-state-header__tile-content" [ngClass]="{ visible: tileHeaderVisible$ | async }">
                        <app-plugin-host [pluginItem]="pluginItem"></app-plugin-host>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <!-- Current entity is NOT supported -->
        <ng-container *ngIf="!pluginItem">
            <!-- Only display a number if user selected a display preference other than Never. -->
            <ng-container *ngIf="tileNumberDisplayPreference !== TileNumberDisplayPreference.Never">
                <ng-container *ngTemplateOutlet="JustTheTileNumber; context: { condition: tileNumberVisibility === TileNumberVisibility.Shown }"></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #WithoutFeatureFlag>
    <ng-container *ngTemplateOutlet="JustTheTileNumber; context: { condition: true }"></ng-container>
</ng-template>

<ng-template #noData>
    <div class="va-center">{{ 'STE_LABEL_NO_DATA_AVAILABLE' | translate }}</div>
</ng-template>

<ng-template #JustTheTileNumber let-condition="condition">
    <div class="tile-state-header" appClickStopPropagation @fadeSlideIn *ngIf="condition">
        <ng-container *ngIf="content !== undefined; else noData">
            <strong class="tile-state-header__tile-number" style="cursor: move" class="visible">
                {{ tileNumber }}
            </strong>
        </ng-container>
    </div>
</ng-template>
