<!-- Hidden is important instead of ngIf because it's possible that popup stays open wile we empty the mail list -->
<gen-button
    [flavor]="ButtonFlavor.Flat"
    appTooltip="{{ 'STE_LABEL_MAILBOX' | translate }}"
    id="btn-toggle-mailbox"
    (click)="toggleIsPopupOpen()"
    class="focus-style-override"
    [hidden]="!mailList.length"
>
    <gen-icon [icon]="Icon.Inbox"></gen-icon>
    {{ mailList.length }}
</gen-button>

<gen-popup
    class="mailbox-popup"
    [position]="PopupPosition.BottomRight"
    targetSelector="#btn-toggle-mailbox"
    genKeyboardNavigation
    (genEscape)="toggleIsPopupOpen()"
    appFocusPopupWhenOpen
>
    <div class="gen-h4 p-1">
        {{ 'STE_LABEL_MAILBOX' | translate }}
    </div>
    <gen-separator></gen-separator>
    <!-- This component must be migrated once genActions is migrated -->
    <gen-melted-list
        *ngIf="mailboxPopup.open"
        [genItemsSource]="mailList"
        (genSelectedItemChange)="openMail($event)"
        [genSelectionType]="SelectionType.List.Single"
        [genActions]="mailActions"
    >
    </gen-melted-list>
</gen-popup>
