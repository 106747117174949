import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MeltedIcon, SpinnerSize } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { DragDropTypes } from '@modules/shared/enumerations/drag-drop-types';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { ContentGroup } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import {
    CommandBindings,
    CommandContext,
    CommandDisplay,
    COMMANDS_SERVICE,
    CommandsSubscription,
} from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { CommandsUsageCollection } from '@modules/shared/services/commands/commands-usage/commands-usage-collection';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ContentSerializer } from '@modules/shared/utilities/content.serializer';
import { EventHelper } from '@modules/shared/utilities/event.helper';
import { TileItem } from '@modules/tiles/models/tile-item';
import { PtzPopupService } from '@modules/video/components/ptz-controls/services/ptz-popup.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { ITileLayoutContent, ITilePattern } from 'RestClient/Client/Interface/ITileLayoutEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { TileLayoutEntity } from 'RestClient/Client/Model/TileLayoutEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { IGuid, SafeGuid } from 'safeguid';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ThreatLevelService } from '@modules/shared/services/threat-level/threat-level.service';
import { map } from 'rxjs/operators';
import { CarouselService } from '../../services/carousel.service';
import { TilesConstants } from '../../enumerations/tiles-constants';
import { TilePatternItem } from '../../models/tile-pattern-item';
import { TileService } from '../../services/tile.service';
import { PendingContentChange, TileCanvasStateService } from '../../state/tile-canvas-state.service';
import { ContentMove } from '../../state/tile-canvas.state';
import { DragDropEffect, DragDropHelper, DragTarget } from '../../utilities/dragdrophelper';
import { TilesTaskEventService } from '../tiles-task/services/tiles-task-event.service';
import { TileComponent } from '../tiles/tile.component';

@UntilDestroy()
@Component({
    selector: 'app-tile-canvas',
    templateUrl: './tile-canvas.component.html',
    styleUrls: ['./tile-canvas.component.scss'],
    providers: [{ provide: CarouselService, useClass: CarouselService }],
})
export class TileCanvasComponent extends TrackedComponent implements OnInit, OnDestroy {
    @Input() public tilePattern?: TilePatternItem;
    public readonly SpinnerSize = SpinnerSize;
    public isLoading = false;
    public fullscreenElement: ElementRef | null = null;
    public gridClass = '';
    public gridTemplateColumns = '';
    public gridTemplateRows = '';
    public tiles: TileItem[] = [];
    public rowCount = 0;
    public colCount = 0;

    public activeThreatLevelColor$ = this.threatLevelService?.activeThreatLevel$?.pipe(map((threatLevel) => threatLevel?.color ?? null));

    private commandsSubscription!: CommandsSubscription;
    private commandsUsages = new CommandsUsageCollection();
    private contextMenuCommands = [SharedCommands.ClearTile, SharedCommands.ClearTiles, SharedCommands.ExpandTile, SharedCommands.MaximizeTileFullscreen];
    private dragSourceTile: TileItem | null = null;
    private expandedTile: TileItem | null = null;
    private isDraggingOver = false;
    private maximizedFullscreenTile: TileItem | null = null;
    private scClient: SecurityCenterClient | undefined;
    private selectedTile: TileItem | null = null;
    private canExecuteClearAllTiles = false;
    private fullOverlayEntityTypes = [EntityTypes.TileLayouts];

    constructor(
        private securityCenterProvider: SecurityCenterClientService,
        private contentProviderService: ContentProviderService,
        private tileService: TileService,
        private tilesCanvasStateService: TileCanvasStateService,
        private translateService: TranslateService,
        private ptzPopupService: PtzPopupService,
        private viewContainerRef: ViewContainerRef,
        private tileTaskService: TilesTaskEventService,
        private changeDetectorRef: ChangeDetectorRef,
        private carouselService: CarouselService,
        private threatLevelService: ThreatLevelService,
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService
    ) {
        super(trackingService);

        this.scClient = this.securityCenterProvider?.scClient;
        this.ptzPopupService.viewContainerRef = this.viewContainerRef;
    }

    public async displayEntities(entityIds: IGuid[]): Promise<void> {
        this.isLoading = true;
        try {
            await this.displayEntitiesInternal(entityIds);
        } finally {
            this.isLoading = false;
        }
    }

    public async displayEntity(entityId: IGuid, entityType?: string): Promise<void> {
        const isFullOverlayRequired = this.fullOverlayEntityTypes.find((type) => type === entityType);
        const elementToBeLoading = isFullOverlayRequired ? this : this.getAvailableTile();
        if (elementToBeLoading) {
            elementToBeLoading.isLoading = true;
        }

        try {
            await this.displayEntitiesInternal(entityId);
        } finally {
            if (elementToBeLoading) {
                elementToBeLoading.isLoading = false;
            }
        }
    }

    public expandTile(tile: TileItem | null): void {
        if (this.expandedTile && this.expandedTile.id !== tile?.id) {
            this.expandedTile.isExpanded = false;
        }

        if (tile) {
            tile.isExpanded = !tile.isExpanded;
            if (tile.isExpanded) {
                this.expandedTile = tile;
            } else {
                this.expandedTile = null;
            }

            // Make sure to close all popups so they don't "hang" in the screen.
            this.ptzPopupService.closeAllPopups().fireAndForget();
            this.updateGridClass();
            this.commandsUsages.invalidateDisplay(SharedCommands.ExpandTile);
        }
    }

    public ngOnDestroy(): void {
        this.commandsSubscription?.unsubscribe();
        this.commandsUsages.clear();

        document.removeEventListener('fullscreenchange', () => this.onFullScreenChange(), false);
        document.removeEventListener('webkitfullscreenchange', () => this.onFullScreenChange(), false);
        document.removeEventListener('mozfullscreenchange', () => this.onFullScreenChange(), false);

        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        // subscribe to tile content changes to display tiles for plugins
        if (this.tilesCanvasStateService !== undefined) {
            this.tilesCanvasStateService.tilePatternChanged$.pipe(untilDestroyed(this)).subscribe((pattern) => this.onPatternChanged(pattern));
            this.tilesCanvasStateService.contentsChanged$.pipe(untilDestroyed(this)).subscribe((contents) => this.onContentsChanged(contents));
            this.tilesCanvasStateService.contentMoved$.pipe(untilDestroyed(this)).subscribe((contentMove) => this.onContentMoved(contentMove));
            this.tilesCanvasStateService.selectedTileChanged$.pipe(untilDestroyed(this)).subscribe((tile) => this.onSelectedTileChanged(tile));
            this.tilesCanvasStateService.contentChanging$.subscribe((contentChange) => this.onContentChangingAsync(contentChange));

            // Make sure we load contents that already exists in the state
            this.onContentsChanged(this.tilesCanvasStateService.contents);
        }

        // listen to the fullscreen changed event to monitor 'Restore tile from fullscreen'
        document.addEventListener('fullscreenchange', () => this.onFullScreenChange(), false);
        document.addEventListener('webkitfullscreenchange', () => this.onFullScreenChange(), false);
        document.addEventListener('mozfullscreenchange', () => this.onFullScreenChange(), false);

        if (this.commandsService) {
            const bindings = new CommandBindings();

            bindings.addCommand({
                commandId: SharedCommands.ClearTiles,
                canExecuteCommandHandler: () => this.canExecuteClearAllTiles,
                executeCommandHandler: (executeCommandData) => {
                    this.tilesCanvasStateService.clearTiles(null);
                    this.ptzPopupService.destroyAllPopups();
                    this.restoreExpandedOrFullscreenTile();

                    executeCommandData.isHandled = true;
                },
            });
            bindings.addCommand({
                commandId: SharedCommands.ClearTile,
                canExecuteCommandHandler: (commandContext) => this.doesTileHaveContent(commandContext),
                executeCommandHandler: async (executeCommandData) => {
                    const tile = this.getTile(this.extractTileId(executeCommandData.commandContext));
                    if (tile?.content) {
                        await this.ptzPopupService.destroyPopup(tile.content.mainContent.id);
                        this.tilesCanvasStateService.clearTile(tile.id);
                    }
                    this.restoreExpandedOrFullscreenTile();
                    executeCommandData.isHandled = true;
                },
            });
            bindings.addCommand({
                commandId: SharedCommands.ExpandTile,
                canExecuteCommandHandler: (commandContext) => this.doesTileHaveContent(commandContext),
                executeCommandHandler: async (executeCommandData) => {
                    const tile = this.getTile(this.extractTileId(executeCommandData.commandContext));
                    if (tile) {
                        this.expandTile(tile);
                        await this.ptzPopupService.closeAllPopups();
                    }
                    executeCommandData.isHandled = true;
                },
                getCommandDisplayHandler: (commandContext) => this.getExpandTileDisplay(this.getTile(this.extractTileId(commandContext))),
            });
            bindings.addCommand({
                commandId: SharedCommands.MaximizeTileFullscreen,
                canExecuteCommandHandler: (commandContext) => this.doesTileHaveContent(commandContext),
                executeCommandHandler: (executeCommandData) => {
                    const tile = this.getTile(this.extractTileId(executeCommandData.commandContext));
                    if (tile && !this.maximizedFullscreenTile) {
                        this.maximizeTileFullscreen(tile);
                    } else {
                        this.maximizeTileFullscreen(null);
                    }
                    executeCommandData.isHandled = true;
                },
                getCommandDisplayHandler: () => this.getMaximizeTileFullscreenDisplay(),
            });
            bindings.addCommand({
                commandId: SharedCommands.SelectPreviousTile,
                executeCommandHandler: (executeCommandData) => {
                    this.selectPreviousTile();
                    executeCommandData.isHandled = true;
                },
            });
            bindings.addCommand({
                commandId: SharedCommands.SelectNextTile,
                executeCommandHandler: (executeCommandData) => {
                    this.selectNextTile();
                    executeCommandData.isHandled = true;
                },
            });

            this.commandsSubscription = this.commandsService.subscribe(bindings, {
                priority: 100,
            });
        }
    }

    public async onContextMenuRequested(event: MouseEvent, tile?: TileItem): Promise<void> {
        // add our own
        event.preventDefault();
        event.stopPropagation();

        this.commandsUsages.clear();

        this.commandsUsages.addUsage(await this.commandsService.getCommandsUsage({ type: ContextTypes.Content, data: tile?.content?.mainContent }, this.contextMenuCommands));

        let commandsTile = tile;
        if (!commandsTile && this.selectedTile) {
            commandsTile = this.selectedTile;
        }

        if (commandsTile) {
            this.commandsUsages.addUsages(commandsTile.commandsUsages);
        }

        this.commandsService.showContextMenu(event, await this.commandsUsages.createContextMenuItemsAsync(), this.fullscreenElement);
    }

    public onDragLeave(event: DragEvent): void {
        this.isDraggingOver = false;
        this.updateGridClass();
    }

    public onDragOver(event: DragEvent): void {
        const dataTransfer = event.dataTransfer;
        if (dataTransfer) {
            // dataTransfer.dropEffect = 'none';

            if (DragDropHelper.getDragTarget(event) === DragTarget.TilesTask) {
                // the tiles task only support copy
                dataTransfer.dropEffect = 'copy';
                this.isDraggingOver = true;
                this.updateGridClass();

                // when supported, stop the bubbling
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    public async onDrop(event: DragEvent): Promise<void> {
        if (!this.isDraggingOver) {
            return;
        }

        try {
            if (!(await EventHelper.tryCaptureEventAsync(event))) {
                return;
            }

            this.isDraggingOver = false;
            this.updateGridClass();
            this.isLoading = true;

            const dragResult = await DragDropHelper.extractTileDragDropData(event, this.securityCenterProvider, this.contentProviderService);
            if (dragResult) {
                // determine if all the tiles need to be cleared like when a tile pattern is dropped
                let clear = false;

                // if the drag result contains a tile pattern, apply it
                if (dragResult.tilePattern) {
                    clear = true;
                    this.applyPattern(dragResult.tilePattern);
                }

                // apply the tile contents
                const contents = dragResult.contents;
                if (contents && contents.length > 0) {
                    if (contents.length > 1 && this.tilePattern) {
                        // let the carousel handle the logic to determine where to drop the contents
                        this.carouselService.displayContents(contents, clear);
                        event.stopPropagation();
                    } else {
                        // only display in the tile
                        const selectedId = this.tilesCanvasStateService.selectedTile;
                        if (selectedId) {
                            this.tilesCanvasStateService?.changeContent(selectedId, contents[0]);
                        }
                    }
                }
            }
        } finally {
            this.isLoading = false;
            EventHelper.releaseEvent(event);
        }
    }

    public onTileDragEnd(tile: TileItem, event: DragEvent): void {
        this.dragSourceTile = null;
        DragDropHelper.endDrag();
    }

    public onTileDragLeave(tile: TileItem, event: DragEvent): void {
        tile.isDraggingOver = false;
    }

    public onTileDragOver(tile: TileItem, event: DragEvent): void {
        const dataTransfer = event.dataTransfer;
        if (dataTransfer) {
            dataTransfer.dropEffect = 'none';

            if (this.dragSourceTile?.id !== tile.id) {
                if (DragDropHelper.getDragTarget(event) === DragTarget.Tile) {
                    if (event.ctrlKey) {
                        DragDropHelper.setDropEffect(DragDropEffect.Copy, event);
                    } else if (event.shiftKey) {
                        DragDropHelper.setDropEffect(DragDropEffect.Link, event);
                    } else {
                        DragDropHelper.setDropEffect(DragDropEffect.Move, event);
                    }
                    tile.isDraggingOver = true;

                    // when supported, stop the bubbling
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }
    }

    public onTileDragStart(tile: TileItem, event: DragEvent): void {
        // can we drag?
        const content = tile.content;
        if (content) {
            const data = ContentSerializer.serializeContentGroup(content);
            if (data) {
                this.dragSourceTile = tile;
                DragDropHelper.startDrag('sc-content', data);
                DragDropHelper.updateDragData('sc-tile', tile.id);
                const target = event.target;
                if (target instanceof Element) {
                    const element = target.closest(TileComponent.selector)?.querySelector('.tileid') as Element;
                    if (element) {
                        event.dataTransfer?.setDragImage(element, 0, 0);
                    }
                }
            }
        }
    }

    public async onTileDrop(tile: TileItem, event: DragEvent): Promise<void> {
        if (!tile.isDraggingOver) {
            return;
        }

        try {
            if (!(await EventHelper.tryCaptureEventAsync(event))) {
                return;
            }

            // I don't like that we have to do this, I feel context menu should close automatically
            this.commandsService.hideCurrentContextMenu();

            tile.isDraggingOver = false;
            const dropEffect = DragDropHelper.getDropEffect();
            const tileId = tile.id;
            if (dropEffect && this.dragSourceTile?.id !== tile.id) {
                let handled = false;

                // first, handle the external drop from Security Center of type 'text/plain'
                if (event?.dataTransfer) {
                    // no xml is found in the buffered data, try to extract it from the event (from Security Desk)
                    const xml = event.dataTransfer.getData('text/plain');
                    if (xml) {
                        tile.isLoading = true;
                        try {
                            const contents = await DragDropHelper.extractSecurityCenterDragContent(xml, this.contentProviderService);
                            if (contents && contents.length === 1) {
                                this.tilesCanvasStateService.changeContent(tile.id, contents[0]);
                                event.stopPropagation();
                                handled = true;
                            }
                        } finally {
                            tile.isLoading = false;
                        }
                    }
                }

                if (!handled) {
                    const sourceTileId = DragDropHelper.getDragData('sc-tile') as number;
                    if (sourceTileId) {
                        tile.isLoading = true;
                        try {
                            const destinationGuid = this.getTile(tileId)?.content?.mainContent.id;
                            const sourceGuid = this.getTile(sourceTileId)?.content?.mainContent.id;
                            switch (dropEffect) {
                                // Default Drag
                                case DragDropEffect.Move: {
                                    // Delete previous popups (fails gracefully if not existing)
                                    await this.ptzPopupService.destroyPopup(destinationGuid ?? SafeGuid.EMPTY);
                                    await this.ptzPopupService.closePopup(sourceGuid ?? SafeGuid.EMPTY);
                                    // Then proceed with the move.
                                    this.tilesCanvasStateService.moveContent({ sourceTileId, destinationTileId: tileId, swapContents: false });
                                    event.stopPropagation();
                                    handled = true;
                                    break;
                                }
                                // CTRL + Drag
                                case DragDropEffect.Copy: {
                                    const dragResult = await DragDropHelper.extractTileDragDropData(event, this.securityCenterProvider, this.contentProviderService);
                                    const contents = dragResult?.contents;
                                    if (contents && contents.length === 1) {
                                        // Destroy popup IF there was one associated with the destination tile.
                                        await this.ptzPopupService.destroyPopup(destinationGuid ?? SafeGuid.EMPTY);
                                        // Then move.
                                        this.tilesCanvasStateService.changeContent(tileId, contents[0]);
                                        event.stopPropagation();
                                        handled = true;
                                    }
                                    break;
                                }
                                // Shift+Drag
                                case DragDropEffect.Link: {
                                    // Delete previous popup (fails gracefully if not existing)
                                    await this.ptzPopupService.closePopup(destinationGuid ?? SafeGuid.EMPTY);
                                    await this.ptzPopupService.closePopup(sourceGuid ?? SafeGuid.EMPTY);
                                    // Then move.
                                    this.tilesCanvasStateService.moveContent({ sourceTileId, destinationTileId: tileId, swapContents: true });
                                    event.stopPropagation();
                                    handled = true;
                                    break;
                                }
                            }
                        } finally {
                            tile.isLoading = false;
                        }
                    } else {
                        const entityIds = DragDropHelper.getDragData(DragDropTypes.EntityId) as IGuid[];
                        if (entityIds && entityIds.length === 1) {
                            tile.isLoading = true;
                            try {
                                const dragResult = await DragDropHelper.extractTileDragDropData(event, this.securityCenterProvider, this.contentProviderService);
                                const contents = dragResult?.contents;
                                if (contents && contents.length === 1) {
                                    this.tilesCanvasStateService.changeContent(tile.id, contents[0]);
                                    event.stopPropagation();
                                    handled = true;
                                }
                            } finally {
                                tile.isLoading = false;
                            }
                        }
                    }
                }
                this.selectTile(tileId);
            }
        } finally {
            EventHelper.releaseEvent(event);
        }
    }

    public onTileContextMenu(tile: TileItem, event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();

        this.onTileSelected(tile);
        this.onContextMenuRequested(event, tile).fireAndForget();
    }

    public onTilePreviewClick(tile: TileItem): void {
        this.selectTile(tile.id, false);
    }

    public onTileSelected(tile: TileItem): void {
        this.selectTile(tile.id);
    }

    public onTileDoubleClick(tile: TileItem): void {
        this.expandTile(tile);
    }

    public setTilePattern(tilePattern: TilePatternItem): void {
        this.tilesCanvasStateService.setTilePattern(tilePattern);
    }

    private async displayEntitiesInternal(entityId: IGuid): Promise<void>;
    private async displayEntitiesInternal(entityIds: IGuid[]): Promise<void>;
    private async displayEntitiesInternal(entityIds: IGuid[] | IGuid): Promise<void> {
        const ids = Array.isArray(entityIds) ? entityIds : [entityIds];

        const entityQuery = new EntityQuery();
        ids.forEach((entity) => entityQuery.guids.add(entity));
        const entities = await this.scClient?.getEntitiesAsync<Entity, IEntity>(Entity, entityQuery);

        if (!entities || entities.length === 0) {
            return;
        }

        const tileLayoutEntity = entities.find((entity) => entity.entityType === EntityTypes.TileLayouts) as TileLayoutEntity | undefined;
        if (tileLayoutEntity) {
            await this.displayTileLayoutContents(tileLayoutEntity);
            return;
        }
        await this.displayEntitiesContents(ids);
    }

    private async displayEntitiesContents(entityIds: IGuid[]): Promise<void> {
        const contentFromEntities = await this.contentProviderService.getContentsAsync(entityIds, TilesConstants.TilesContentContextId);
        if (!contentFromEntities) {
            return;
        }

        this.carouselService.displayContents(contentFromEntities, false);
    }

    private async displayTileLayoutContents(tileLayoutEntity: TileLayoutEntity): Promise<void> {
        const entitiesContentFromTileLayout = await this.tryApplyPatternAndGetEntitiesContent(tileLayoutEntity);
        if (!entitiesContentFromTileLayout) {
            return;
        }
        this.carouselService.displayContents(entitiesContentFromTileLayout, true);
    }

    private async tryApplyPatternAndGetEntitiesContent(tileLayoutEntity: TileLayoutEntity): Promise<ContentGroup[] | null> {
        const contentFromTileLayout = await tileLayoutEntity.getContentAsync();
        if (contentFromTileLayout?.pattern) {
            this.applyPattern(contentFromTileLayout.pattern);
        }

        const canGetEntitiesContentFromTileLayout = contentFromTileLayout && this.tilePattern && contentFromTileLayout.entities.size;
        if (!canGetEntitiesContentFromTileLayout) {
            return null;
        }
        return await this.getEntitiesContentFromTileLayout(contentFromTileLayout);
    }

    private async getEntitiesContentFromTileLayout(tileLayoutContent: ITileLayoutContent): Promise<ContentGroup[] | null> {
        const contentsFromTileLayout = await this.contentProviderService.getContentsAsync(Array.from(tileLayoutContent.entities), TilesConstants.TilesContentContextId);
        return contentsFromTileLayout;
    }

    private restoreExpandedOrFullscreenTile(): void {
        this.restoreExpandedTile();
        this.restoreFullscreenTile();
    }

    private restoreExpandedTile(): void {
        this.expandTile(this.expandedTile);
    }

    private restoreFullscreenTile(): void {
        this.maximizeTileFullscreen(null);
    }

    private applyPattern(pattern: ITilePattern): void {
        const item = TilePatternItem.fromTilePattern(pattern);
        this.setTilePattern(item);
    }

    private refreshCanExecuteClearAllTiles(contents: (ContentGroup | null)[]): void {
        const isThereATileWithContent = contents.find((content) => content !== null) !== undefined;
        this.canExecuteClearAllTiles = isThereATileWithContent;
    }

    private doesTileHaveContent(commandContext?: CommandContext): boolean {
        const tile = this.getTile(this.extractTileId(commandContext));
        return tile?.hasContent ?? false;
    }

    private extractTileId(commandContext?: CommandContext): number | undefined {
        let tileId: number | undefined;
        if (commandContext?.type.equals(ContextTypes.Specific)) {
            if (isNonEmptyString(commandContext.data)) {
                const parsedNumber = Number.parseInt(commandContext.data);
                if (!Number.isNaN(parsedNumber)) {
                    tileId = parsedNumber - 1;
                }
            } else if (isNumber(commandContext.data)) {
                tileId = commandContext.data - 1;
            }
        }
        return tileId;
    }

    private getExpandTileDisplay(tile?: TileItem): CommandDisplay | undefined {
        if (tile && tile.id === this.selectedTile?.id) {
            const restore = this.translateService?.instant('STE_BUTTON_RESTORETILE') as string;
            const maximize = this.translateService?.instant('STE_BUTTON_MAXIMIZETILE') as string;
            return tile.isExpanded ? { name: () => restore, icon: MeltedIcon.RestoreWindow } : { name: () => maximize, icon: MeltedIcon.MaximizeWindow };
        }
    }

    private getMaximizeTileFullscreenDisplay(): CommandDisplay | undefined {
        return this.maximizedFullscreenTile === null
            ? { name: () => this.translateService?.instant('STE_ACTION_MAXIMIZETILEFULLSCREEN') as string, icon: MeltedIcon.Fullscreen }
            : { name: () => this.translateService?.instant('STE_ACTION_RESTORETILEFULLSCREEN') as string, icon: MeltedIcon.ExitFullscreen };
    }

    private getTile(tileId?: number): TileItem | undefined {
        if (tileId && tileId <= this.tiles.length) {
            return this.tiles[tileId - 1];
        }

        if (this.selectedTile) {
            return this.selectedTile;
        }
    }

    private getAvailableTile(): TileItem | undefined {
        let availableTileId = this.carouselService.getFirstAvailableTileId();
        if (availableTileId === null) {
            // Special case.
            // If all tiles are occupied and we only have one content to place, reuse tile #1 (same as Web Client)
            availableTileId = CarouselService.firstTileId;
        }
        return this.getTile(availableTileId);
    }

    private maximizeTileFullscreen(tile: TileItem | null): void {
        if (this.maximizedFullscreenTile && this.maximizedFullscreenTile.id !== tile?.id) {
            this.maximizedFullscreenTile.isFullscreen = false;
            this.maximizedFullscreenTile = null;
        }

        if (tile) {
            tile.isFullscreen = true;
            this.maximizedFullscreenTile = tile;
        }
    }

    private async onContentChangingAsync(contentChange: PendingContentChange): Promise<void> {
        const tile = this.tiles.find((x) => x.id === contentChange.tileId);
        if (tile) {
            if (await this.tileService.tryUpdateTileContentAsync(tile, contentChange.content, true)) {
                contentChange.changesAccepted = true;
            }
        }
    }

    /** Move the actual tiles before the store is updated to avoid content change and resetting plugins */
    private onContentMoved(move: ContentMove): void {
        if (move) {
            const sourceTileIndex = this.tiles.findIndex((x) => x.id === move.sourceTileId);
            const destinationTileIndex = this.tiles.findIndex((x) => x.id === move.destinationTileId);
            const sourceTile = this.tiles[sourceTileIndex];
            const destinationTile = this.tiles[destinationTileIndex];

            if (sourceTile && destinationTile) {
                const sourceBoundary = sourceTile.boundary;
                const destinationBoundary = destinationTile.boundary;

                sourceTile.id = move.destinationTileId;
                destinationTile.id = move.sourceTileId;
                sourceTile.boundary = destinationBoundary;
                destinationTile.boundary = sourceBoundary;
                this.tiles[destinationTileIndex] = sourceTile;
                this.tiles[sourceTileIndex] = destinationTile;

                if (!move.swapContents && destinationTile.content) {
                    destinationTile.content = null;
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    private onContentsChanged(contents: (ContentGroup | null)[]): void {
        this.tiles.forEach((tile) => {
            const tileContent = contents[tile.id - 1];
            if (!tileContent) {
                tile.clear();
            } else {
                this.tileService.tryUpdateTileContentAsync(tile, tileContent, false).fireAndForget();
            }
        });
        this.refreshCanExecuteClearAllTiles(contents);
        this.commandsService.invalidateCanExecuteForAllCommandsUsage(SharedCommands.ClearTiles);
        this.changeDetectorRef.markForCheck();
    }

    private onFullScreenChange(): void {
        const fullscreenElement = document.fullscreenElement;
        let ref: ElementRef | null = null;
        if (fullscreenElement) {
            ref = new ElementRef(fullscreenElement);
        }
        this.fullscreenElement = ref;

        // when no element is in fullscreen, clear the state
        if (fullscreenElement === null) {
            // clear the states
            this.tiles.forEach((tile) => {
                tile.isFullscreen = false;
            });
            this.maximizedFullscreenTile = null;
        }
        // Notify interested components/services in this event
        this.tileTaskService.publishFullscreenChangedEvent();
        this.commandsService.hideCurrentContextMenu();
        this.commandsUsages.invalidateDisplay(SharedCommands.MaximizeTileFullscreen);
    }

    private onPatternChanged(pattern: TilePatternItem): void {
        let existingPattern = pattern;

        if (pattern) {
            [this.colCount, this.rowCount] = pattern.definition.split('x').map(parseInt);
        }

        // ensure we always have a pattern. if not valid, take the default pattern.
        if (!existingPattern) {
            existingPattern = TileCanvasStateService.defaultTilePattern;
        }

        // use unique id to determine if the tile pattern is identical
        if (existingPattern && (!this.tilePattern || this.tilePattern.uniqueId !== existingPattern.uniqueId)) {
            const selectedTileId = this.selectedTile?.id;
            this.tilePattern = existingPattern;
            this.tiles = existingPattern.createTiles(this.tiles);

            this.gridTemplateColumns = existingPattern.getGridTemplateColumns();
            this.gridTemplateRows = existingPattern.getGridTemplateRows();

            // Minimize any maximized tile before changing pattern
            if (this.expandedTile) {
                this.expandTile(this.expandedTile);
            }

            // if we had a selected tile, restore it
            if (selectedTileId) {
                const selectedTile = this.tiles.find((tile) => tile.id === selectedTileId);
                if (selectedTile) {
                    selectedTile.isSelected = true;
                }
            }
            this.updateGridClass();
        }
    }

    private onSelectedTileChanged(tileId: number): void {
        if (!tileId) {
            this.tiles.forEach((item) => {
                item.isSelected = false;
            });
            this.selectedTile = null;
            return;
        }

        const tileToSelect = this.tiles.find((tile) => tile.id === tileId);
        if (tileToSelect) {
            let itemToSelect: TileItem | undefined;
            this.tiles.forEach((item) => {
                if (item.id !== tileToSelect.id) {
                    item.isSelected = false;
                } else {
                    // Wait until all other tiles are unselected before selecting new tile
                    itemToSelect = item;
                }
            });

            if (itemToSelect) {
                itemToSelect.isSelected = true;
                this.selectedTile = tileToSelect;
            }
        }
    }

    private selectNextTile(): void {
        let id = this.selectedTile?.id;
        const count = this.tilePattern?.tileCount;
        if (id && count) {
            id++;
            if (id > count) {
                id = 1;
            }
            this.selectTile(id);
        }
    }

    private selectPreviousTile(): void {
        let id = this.selectedTile?.id;
        const count = this.tilePattern?.tileCount;
        if (id && count) {
            id--;
            if (id <= 0) {
                id = count;
            }
            this.selectTile(id);
        }
    }

    private selectTile(tileId: number, shouldHideCurrentContextMenu: boolean = true): void {
        if (shouldHideCurrentContextMenu) {
            // I don't like that we have to do this, I feel context menu should close automatically
            this.commandsService.hideCurrentContextMenu();
        }
        this.tilesCanvasStateService.selectTile(tileId);
    }

    private updateGridClass(): void {
        let classInfo = '';
        if (this.expandedTile) {
            classInfo += ' columns-1 rows-1 expanded';
        }

        if (this.isDraggingOver) {
            classInfo += ' isDraggingOver';
        }

        this.gridClass = classInfo;
    }
}
