<ng-container *ngIf="hasSetThreatLevelPrivilege; then enabled; else disabled"> </ng-container>

<ng-template #enabled>
    <gen-button class="container" [flavor]="ButtonFlavor.Flat" (click)="openThreatLevelsModal()" [style.backgroundColor]="threatLevelColor$ | async | argb">
        <ng-template *ngTemplateOutlet="threatLevelData"></ng-template>
    </gen-button>
</ng-template>

<ng-template #disabled>
    <div class="container pl-1 pr-1" [style.backgroundColor]="threatLevelColor$ | async | argb">
        <ng-template *ngTemplateOutlet="threatLevelData"></ng-template>
    </div>
</ng-template>

<ng-template #threatLevelData>
    <ng-template
        *ngTemplateOutlet="theThreatLevel; context: { isThereAnActiveThreatLevel: (isThereAnActiveThreatLevel$ | async), activeThreatLevelName: (activeThreatLevelName$ | async) }"
    ></ng-template>
</ng-template>

<ng-template #theThreatLevel let-isThereAnActiveThreatLevel="isThereAnActiveThreatLevel" let-activeThreatLevelName="activeThreatLevelName">
    <gen-item
        appTooltip="{{ 'STE_TOOLTIP_BUTTON_THREATLEVELS' | translate }}"
        *ngIf="hasSetThreatLevelPrivilege || isThereAnActiveThreatLevel"
        [ngClass]="{ inverted: (threatLevelColor$ | async | luminance) > 0.5, 'text-visible p-1': isThereAnActiveThreatLevel }"
    >
        <gen-icon [icon]="isThereAnActiveThreatLevel ? Icon.ThreatOn : Icon.ThreatOff" appTooltip="{{ 'STE_TOOLTIP_BUTTON_THREATLEVELS' | translate }}"></gen-icon>
        <gen-text [appTooltipOnlyIfOverflow]="hasSetThreatLevelPrivilege" appTooltip="{{ trayTooltip$ | async }}">
            <strong>
                {{ activeThreatLevelName }}
            </strong>
        </gen-text>
    </gen-item>
</ng-template>
