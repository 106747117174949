import { Component } from '@angular/core';
import { FilterTypes } from 'src/app/modules/shared/enumerations/filter-types';
import { TrackingService } from 'src/app/modules/shared/services/tracking.service';
import { InternalPluginDescriptor } from 'src/app/modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from 'src/app/modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { FilterPluginBaseComponent } from './filter-plugin-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template: `<app-filter-search [descriptor]="descriptor" (valueChange)="updateFilter($event)"></app-filter-search>`,
})
@InternalPluginDescriptor({
    type: FilterPluginSearchComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginSearchComponent.pluginId,
        subSection: FilterTypes.Search,
    },
    isSupported: () => true,
})
export class FilterPluginSearchComponent extends FilterPluginBaseComponent {
    public static pluginId = SafeGuid.parse('9C951DCE-3A59-45B6-B1D7-71711221CAA2');

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginSearchComponent.pluginId);
    }
}
