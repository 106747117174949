import { Injectable, OnDestroy } from '@angular/core';
import { BannerFlavor } from '@genetec/gelato-angular';
import { NotificationItem, NotificationsClient, NotificationsFilter, NotificationType } from '@modules/shared/api/api';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IEventBase } from 'RestClient/Client/Interface/IEventBase';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { BehaviorSubject, Observable } from 'rxjs';
import { SafeGuid } from 'safeguid';
import { LanguageService } from '../language/language.service';
import { NotificationsChangedEvent } from './NotificationsChangedEvent';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
    //#region Fields

    public notifications: NotificationItem[] = [];

    private scClient!: SecurityCenterClient;
    private message: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private messageType: BehaviorSubject<BannerFlavor> = new BehaviorSubject<BannerFlavor>(BannerFlavor.Information);

    private eventHandlers: Map<string, (event: IEventBase) => void> = new Map<string, (event: IEventBase) => void>();
    private subscriptions = new SubscriptionCollection();
    private notificationsMap = SafeGuid.createMap<NotificationItem>();

    //#endregion

    //#region Properties

    public get message$(): Observable<string> {
        return this.message.asObservable();
    }

    public get messageType$(): Observable<BannerFlavor> {
        return this.messageType.asObservable();
    }

    //#endregion

    //#region Constructors

    constructor(
        scClientService: SecurityCenterClientService,
        private languageService: LanguageService,
        private translateService: TranslateService,
        private notificationsClient: NotificationsClient
    ) {
        this.scClient = scClientService.scClient;
        this.initialize();

        if (this.scClient) {
            this.scClient.onLogonStateChanged(async (args) => {
                if (args.loggedOn()) {
                    await this.refreshNotifications();
                } else {
                    this.notifications.length = 0;
                    this.notificationsMap.clear();
                }
            });
        }

        this.languageService.languageChanged.pipe(untilDestroyed(this)).subscribe(async () => {
            await this.refreshNotifications();
        });
    }

    //#endregion

    //#region Methods

    public ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }

    public async refreshNotifications(): Promise<void> {
        this.notifications.length = 0;
        this.notificationsMap.clear();

        const filter = new NotificationsFilter();
        const notifications = await this.notificationsClient.getNotifications(filter).toPromise();
        if (notifications) {
            notifications.forEach((nt) => {
                this.notifications.push(nt);
                this.notificationsMap.set(nt.id, nt);
            });
        }

        this.setMainNotification();
    }

    private initialize() {
        this.scClient.registerAdditionalEventTypes('NotificationsChangedEvent', NotificationsChangedEvent);
        this.eventHandlers.set('NotificationsChangedEvent', (event) => this.onNotificationsChangedEvent(event as NotificationsChangedEvent));

        this.subscriptions.add(this.scClient.onEventReceived((arg) => this.onEventReceived(arg)));

        // load active notifications on startup
        this.refreshNotifications().fireAndForget();
    }

    private setMainNotification() {
        let message = '';
        let messageType = BannerFlavor.Information;

        if (this.notifications && this.notifications.length > 0) {
            const maxPriorityValue = Number.MAX_VALUE;
            const sorted = this.notifications.sort((a, b) => ((a.priority ?? maxPriorityValue) > (b.priority ?? maxPriorityValue) ? 1 : -1));

            message = sorted[0].title;
            if (sorted[0].type) {
                switch (sorted[0].type) {
                    case NotificationType.Error:
                        messageType = BannerFlavor.Error;
                        break;
                    case NotificationType.Warning:
                        messageType = BannerFlavor.Warning;
                        break;
                }
            }
            if (this.notifications.length > 1) {
                const more = stringFormat(this.translateService.instant('STE_LABEL_X_MORE_FORMAT') as string, (this.notifications.length - 1).toString());
                message += ' (+ ' + more + ')';
            }
        }

        this.message.next(message);
        this.messageType.next(messageType);
    }

    //#endregion

    //#region Event Handlers

    private onEventReceived(args: EventReceivedArg) {
        const eventHandler = this.eventHandlers.get(args.event.eventType);
        if (eventHandler) {
            eventHandler(args.event);
        }
    }

    private async onNotificationsChangedEvent(event: NotificationsChangedEvent) {
        const added = event.added;
        const removed = event.removed;
        let modified = false;

        if (removed && removed.size > 0) {
            removed.forEach((id) => {
                const nt = this.notificationsMap.get(id);
                if (nt) {
                    this.notifications.remove(nt);
                    this.notificationsMap.delete(id);
                }
            });
            modified = true;
        }

        if (added && added.size > 0) {
            const filter = new NotificationsFilter();
            filter.ids = [];
            added.forEach((x) => filter.ids?.push(x));
            const newItems = await this.notificationsClient.getNotifications(filter).toPromise();
            if (newItems) {
                newItems.forEach((nt) => {
                    this.notifications.push(nt);
                    this.notificationsMap.set(nt.id, nt);
                });
            }
            modified = true;
        }

        if (modified) {
            this.setMainNotification();
        }
    }

    //#endregion
}
