import { DateFilterMode } from '@modules/shared/components/filters/common-filters/filter-date/enums/date-filter-mode.enum';
import { unitOfTime } from 'moment-timezone';
import { IGuid } from 'safeguid';
import { DataTypeDisplayModel, FieldType, WhereMode } from '../../../api/api';
import { CorrelationSearchParameter } from '../../parameters-modal/correlation-search-parameter';
import { DurationUnit } from './investigate-filter-data.enumerations';

export interface DisplayableFilter {
    description: string;
    isValid?: boolean;
}

export interface WhatFilter extends DisplayableFilter {
    dataTypes: IGuid[];
    parameters: CorrelationSearchParameter[];
}

export interface WhenFilter extends DisplayableFilter {
    mode: DateFilterMode;
    timeStart: string | null;
    timeEnd: string | null;
    period: LastPeriodPreset | null;
}
export interface WhereFilter extends DisplayableFilter {
    wkt: string | null;
    intersection: boolean;
    includedAreas: IGuid[] | null;
    excludedAreas: IGuid[] | null;
    mode: WhereMode;
}

export interface ColumnDescriptor {
    colIndex: number;
    name: string;
    dataType?: DataTypeDisplayModel;
    fieldKey: string;
    isSortable: boolean;
    sortOrder?: string;
    type: FieldType;
    displayed: boolean;
}

export interface InvestigateDataStateModel {
    whatFilter: WhatFilter | null;
    whenFilter: WhenFilter | null;
    whereFilter: WhereFilter | null;
    columns: ColumnDescriptor[];
    tableBusy: boolean;
}

export class LastPeriodPreset {
    public id: string;
    constructor(public label: DurationUnit, public duration: number, public durationUnit: unitOfTime.Base) {
        this.id = duration.toString().concat(`-${durationUnit}`);
    }
}
