import { IncidentLocation } from '../incident-location';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.TriggerIncident;
export class TriggerIncidentCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentTypeId: string, location: IncidentLocation | null, externalId: string | null, comment: string | null) {
        super(
            '/v2/Incidents',
            JSON.stringify({
                Type: commandType,
                IncidentTypeId: incidentTypeId,
                Location: location
                    ? {
                          EntityId: location.entityId?.toString(),
                          Latitude: location.latitude,
                          Longitude: location.longitude,
                      }
                    : null,
                ExternalId: externalId,
                Comment: comment,
            })
        );
    }

    public successMessage(statusCode?: number): string {
        return 'STE_MESSAGE_INFO_INCIDENT_SUCCESSFULLYCREATED';
    }
    public errorMessage(statusCode?: number): string {
        return 'STE_MESSAGE_ERROR_INCIDENT_CREATIONFAILED';
    }
}
