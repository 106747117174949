import { VideoWatermarkConfig } from './VideoWatermarkingConfig';
import { VideoWatermarkingOverlay } from './VideoWatermarkingOverlay';

export class VideoWatermarkingRenderer {
  private readonly m_videoWatermarkingConfig: VideoWatermarkConfig;

  private m_watermarkOverlay: HTMLCanvasElement | undefined;

  constructor(watermarkConfiguration: VideoWatermarkConfig, width: number, height: number) {
    this.m_videoWatermarkingConfig = watermarkConfiguration;
    this.m_watermarkOverlay = (width !== 0 && height !== 0) ? this.createVideoWatermarkingOverlay(width, height, width, height) : undefined;
  }

  public drawVideoWatermarkingOverlay(canvasContext: CanvasRenderingContext2D, offsetX: number, offsetY: number) {
    if (this.m_watermarkOverlay === undefined) {
      return;
    }

    const previousGlobalAlpha = canvasContext.globalAlpha;
    canvasContext.globalAlpha = this.m_videoWatermarkingConfig.Opacity;

    try {
      canvasContext.drawImage(this.m_watermarkOverlay, offsetX, offsetY);
    } finally {
      canvasContext.globalAlpha = previousGlobalAlpha;
    }
  }

  public updateVideoWatermarkingOverlaySize(streamWidth: number, streamHeight: number) {
    this.updateVideoWatermarkingOverlaySizeWithSpecificDimensions(streamWidth, streamHeight, streamWidth, streamHeight);
  }

  public updateVideoWatermarkingOverlaySizeWithSpecificDimensions(streamWidth: number, streamHeight: number, fitWidth: number, fitHeight: number) {
    // Only create a new watermark overlay if the size has changed
    if (this.m_watermarkOverlay !== undefined && this.m_watermarkOverlay.width === fitWidth && this.m_watermarkOverlay.height === fitHeight) {
      return;
    }
    this.m_watermarkOverlay = this.createVideoWatermarkingOverlay(streamWidth, streamHeight, fitWidth, fitHeight);
  }

  private createVideoWatermarkingOverlay(streamWidth: number, streamHeight: number, overlayWidth: number, overlayHeight: number): HTMLCanvasElement {
    let videoWatermarkingOverlay = new VideoWatermarkingOverlay(this.m_videoWatermarkingConfig, streamWidth, streamHeight);

    const fontSize = videoWatermarkingOverlay.calculateFontSize();

    // If the overlay should have a different dimension than the stream dimension, use the font size calculated previously but with the new dimensions.
    if (overlayWidth !== streamWidth || overlayHeight !== streamHeight) {
      videoWatermarkingOverlay = new VideoWatermarkingOverlay(this.m_videoWatermarkingConfig, overlayWidth, overlayHeight);
    }

    // The overlay block size needs to be at least the size of the text itself, but that size is not known until after we create the block.
    // Setting the correct size is done in four steps.
    // 1. Create an overlayBlock and fill it with text; the text is needed for when we calculate the text size in the next step.
    let overlayBlock = videoWatermarkingOverlay.createOverlayBlock(fontSize);
    // 2. Calculate the text size, using the text filled in the last step.
    let overlayBlockSize = videoWatermarkingOverlay.calculateOverlayBlockSize(overlayBlock, fontSize);
    // 3. Set the overlayBlock's size to the text size. This resets the canvas context, and erases the text.
    videoWatermarkingOverlay.setOverlaySize(overlayBlock, overlayBlockSize[0], overlayBlockSize[1]);
    // 4. Fill the text a second time, knowing the overlayBlock is exactly the correct size to fit the text.
    videoWatermarkingOverlay.fillText(overlayBlock, fontSize);

    if (this.m_videoWatermarkingConfig.Rotation > 0) {
      const newOverlayBlockElements = videoWatermarkingOverlay.rotateOverlayBlock(overlayBlock, overlayBlockSize[0], overlayBlockSize[1]);
      overlayBlock = newOverlayBlockElements[0];
      overlayBlockSize = [newOverlayBlockElements[1], newOverlayBlockElements[2]];
    }

    const overlay = document.createElement('canvas');
    videoWatermarkingOverlay.createVideoWatermarkingOverlay(overlay, overlayBlock, overlayBlockSize[0], overlayBlockSize[1]);
    return overlay;
  }
}
