import { Component, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { incidentCommentValidator } from '@modules/mission-control/validators/incident-comment-validator';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';

@Component({
    selector: 'app-incident-comment',
    templateUrl: './incident-comment.component.html',
})
export class IncidentCommentComponent extends TrackedComponent implements OnInit, ControlValueAccessor {
    @Input() comment = '';
    @Input() label?: string;
    @Input() disabled = false;
    @Input() required = false;
    @Input() readonly = false;
    @Input() multiline = 2;
    @Input() maxlength?: number;
    @Input() autofocus = false;
    @Input() placeholder = '';
    @Input() customError?: string;

    constructor(@Self() public ngControl: NgControl, trackingService: TrackingService) {
        super(trackingService);
        ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        const control = this.ngControl.control;
        if (control) {
            let validators = control.validator ? [control.validator, incidentCommentValidator] : incidentCommentValidator;
            control.setValidators(validators);
            control.updateValueAndValidity();
        }
    }
    public onChange = (_value: unknown) => {};
    public onTouch = () => {};

    public writeValue(value: unknown): void {
        if (typeof value === 'string') {
            this.comment = value;
        }
    }

    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    public onCommentChange(value: string): void {
        this.onChange?.(value);
    }
}
