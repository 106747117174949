// Autogenerated: v2/help/Classes/ApiCardholderAntipassbackEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { IGuid, SafeGuid } from 'safeguid';
import { ICardholderAntipassbackEvent } from '../Interface/ICardholderAntipassbackEvent';
import { CardholderEvent } from './CardholderEvent';

export class CardholderAntipassbackEvent extends CardholderEvent implements ICardholderAntipassbackEvent {
    // #region REST Properties

    // AreaDirectionGuid
    public get areaDirectionGuid(): IGuid {
        return this.getFieldGuid('AreaDirectionGuid');
    }
    public set areaDirectionGuid(value: IGuid) {
        this.setFieldGuid('AreaDirectionGuid', value);
    }

    // DoorSideGuid
    public get doorSideGuid(): IGuid {
        return this.getFieldGuid('DoorSideGuid');
    }
    public set doorSideGuid(value: IGuid) {
        this.setFieldGuid('DoorSideGuid', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('AreaDirectionGuid', SafeGuid.EMPTY);
        this.setFieldGuid('DoorSideGuid', SafeGuid.EMPTY);
    }

    // #endregion Methods

}

