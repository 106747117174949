import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonFlavor, GenMenu, MeltedIcon, Position } from '@genetec/gelato-angular';
import { IFloor } from '@modules/maps/controllers/map.controller.data';
import { MapFloorInfo } from '@modules/maps/models';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { MenuSeparator } from '@shared/interfaces/context-menu-item/menu-separator';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '@src/constants';
import { IDefaultView } from 'RestClient/Client/Interface/IMapEntity';
import { ResizeWidthEvent } from '@modules/shared/directives/resized/interfaces/resize-width-event';

@Component({
    selector: 'app-map-controls',
    templateUrl: './map-controls.component.html',
    styleUrls: ['./map-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapControlsComponent {
    @ViewChild('contextMenu') public contextMenu!: GenMenu;

    @ViewChild('floorsScrollContainer') public set floorsScrollContainer(elementRef: ElementRef<HTMLDivElement>) {
        this.#floorsScrollContainer = elementRef;
        this.scrollToSelectedFloorButton(false);
    }

    @ViewChild('mapControl') public mapControl!: ElementRef<HTMLElement>;

    @Input() public set defaultView(view: IDefaultView | null | undefined) {
        this.#defaultView = view;
        this.updatePresetsContextMenu();
    }

    @Input() public set presets(presets: IDefaultView[]) {
        this.#presets = presets;
        this.updatePresetsContextMenu();
    }

    @Input()
    public get selectedFloor(): IFloor | null {
        return this.#selectedFloor;
    }
    public set selectedFloor(floor: IFloor | null) {
        this.#selectedFloor = floor;
        this.scrollToSelectedFloorButton();
    }

    @Input()
    public get floorInfo(): MapFloorInfo {
        return this.#floorInfo;
    }
    public set floorInfo(floorInfo: MapFloorInfo) {
        this.#floorInfo = floorInfo;
        this.scrollToSelectedFloorButton();
    }

    @Input() public isGoToParent = true;
    @Input() public isGoToParentEnabled = false;

    @Output() public floorClick = new EventEmitter<IFloor>();
    @Output() public goToParentClick = new EventEmitter<void>();
    @Output() public zoomInClick = new EventEmitter<void>();
    @Output() public zoomOutClick = new EventEmitter<void>();
    @Output() public presetSelect = new EventEmitter<IDefaultView>();
    @Output() public widthChange = new EventEmitter<number>();

    public contextMenuPresets: ContextMenuItem[] = [];
    public isPresetsOpen = false;
    public width = 0;

    // Gelato enums
    public ButtonFlavor = ButtonFlavor;
    public Position = Position;
    public Icon = MeltedIcon;

    #floorsScrollContainer?: ElementRef<HTMLDivElement>;
    #defaultView?: IDefaultView | null;
    #presets?: IDefaultView[];
    #selectedFloor: IFloor | null = null;
    #floorInfo: MapFloorInfo = {
        floors: [],
    };

    constructor(private translateService: TranslateService, private cdRef: ChangeDetectorRef) {}

    @HostListener('document:keydown', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.ctrlKey && !isNaN(Number(event.key))) {
            event.preventDefault();
            (this.contextMenuPresets.find((item) => item.id === event.key)?.actionItem?.execute as () => void | undefined)?.();
        }
    }

    @HostListener('wheel', ['$event'])
    public handleMouseWheelEvent(event: WheelEvent): void {
        // Prevents from registering on the map
        event.stopPropagation();
    }

    public getSortedFloors(): IFloor[] {
        return this.floorInfo?.floors.sort((floorA, floorB) => floorB.orderPosition - floorA.orderPosition) ?? [];
    }

    public isFloorSelected(floor: IFloor): boolean {
        return !!this.selectedFloor && floor.id.equals(isInstanceOfGuid(this.selectedFloor) ? this.selectedFloor : this.selectedFloor.id);
    }

    public goToParentMap(): void {
        this.goToParentClick.emit();
    }

    public toggleIsPresetsOpen(): void {
        this.contextMenu
            .toggle()
            .then(() => {
                this.cdRef.markForCheck();
            })
            .fireAndForget();
    }

    public focus(index: number, event: Event): void {
        if (index >= 0 && index < this.floorInfo.floors.length) {
            event.preventDefault();
            (this.#floorsScrollContainer?.nativeElement.querySelectorAll('.gen-toggle-button button')[index] as HTMLElement | null)?.focus();
        }
    }

    public switchFloor(floor: IFloor): void {
        if (!this.isFloorSelected(floor)) {
            this.selectedFloor = floor;
            this.floorClick.emit(floor);
        }
    }

    public zoom(zoomIn: boolean): void {
        (zoomIn ? this.zoomInClick : this.zoomOutClick).emit();
    }

    public onPresetClick(view: IDefaultView): void {
        this.presetSelect.emit(view);
    }

    public onMapControlResize(event: ResizeWidthEvent): void {
        this.width = event.newWidth + this.getMapControlMarginRight();
        this.widthChange.emit(this.width);
    }

    private getMapControlMarginRight(): number {
        let horizontalMargin = 0;
        const computedStyle = getComputedStyle(this.mapControl?.nativeElement);
        if (computedStyle) {
            const marginRight = parseInt(computedStyle.right, 10);
            if (!isNaN(marginRight)) {
                horizontalMargin = marginRight;
            }
        }
        return horizontalMargin;
    }

    private updatePresetsContextMenu(): void {
        this.contextMenuPresets = [];
        this.addDefaultViewContextMenuItem();
        this.addCustomPresetsContextMenuItem();
    }

    private addDefaultViewContextMenuItem() {
        if (this.#defaultView) {
            this.contextMenuPresets.push({
                id: '0',
                text: this.translateService.instant('STE_MENU_ITEM_PRESET_DEFAULTVIEW') as string,
                icon: MeltedIcon.Home,
                secondaryText: `${Constants.ctrlText}+0`,
                actionItem: {
                    execute: () => this.#defaultView && this.onPresetClick(this.#defaultView),
                },
            });
        }
    }

    private addCustomPresetsContextMenuItem() {
        if (this.#presets?.length) {
            let startIndex = 0;
            if (this.#defaultView) {
                this.contextMenuPresets.push(new MenuSeparator());
                startIndex = 1;
            }
            this.contextMenuPresets.push(
                ...this.#presets.map((view, index) => ({
                    id: `${startIndex + index}`,
                    text: view.name,
                    secondaryText: index < 10 ? `${Constants.ctrlText}+${startIndex + index}` : undefined,
                    actionItem: {
                        execute: () => this.onPresetClick(view),
                    },
                }))
            );
        }
    }

    private scrollToSelectedFloorButton(smooth = true): void {
        // Timeout to wait for template update
        setTimeout(() => {
            if (this.#floorsScrollContainer && this.selectedFloor) {
                const floorButton = this.#floorsScrollContainer.nativeElement.querySelectorAll(`.gen-toggle-button`)[
                    this.floorInfo.floors.length - this.selectedFloor.orderPosition - 1
                ] as HTMLElement | undefined;

                if (floorButton) {
                    // Only scroll if necessary and to show the whole button that was clicked
                    const currentScroll = this.#floorsScrollContainer.nativeElement.scrollTop;
                    const scrollContainerHeight = this.#floorsScrollContainer.nativeElement.clientHeight;
                    if (floorButton.offsetTop < currentScroll) {
                        // Scroll up to until button is fully shown
                        this.#floorsScrollContainer.nativeElement.scrollTo({
                            top: floorButton.offsetTop,
                            behavior: smooth ? 'smooth' : 'auto',
                        });
                    } else if (floorButton.offsetTop + floorButton.clientHeight > currentScroll + scrollContainerHeight) {
                        // Scroll down until button is fully shown
                        this.#floorsScrollContainer.nativeElement.scrollTo({
                            top: floorButton.offsetTop - scrollContainerHeight + floorButton.clientHeight,
                            behavior: smooth ? 'smooth' : 'auto',
                        });
                    }
                }
            }
        });
    }
}
