/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GenPopup } from '@genetec/gelato-angular';
import { PopupPosition, Orientation, SelectionType } from '@genetec/gelato';
import { isEqual } from 'lodash-es';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { ListItem } from '@modules/shared/interfaces/list-item';

@Component({
    selector: 'app-hideable-filters-container',
    templateUrl: './hideable-filters-container.component.html',
    styleUrls: ['./hideable-filters-container.component.scss'],
})
export class HideableFiltersContainerComponent extends TrackedComponent {
    @ViewChild(GenPopup) popup!: GenPopup;

    @Input() public filters: ListItem[] = [];
    @Output() public selectionChanged = new EventEmitter<string[]>();

    public wasChecked: Array<string> = [];
    public isDirty = false;

    public readonly PopupPosition = PopupPosition;
    public readonly Orientation = Orientation;
    public readonly ListSelectionType = SelectionType;

    private _isPopupOpen = false;
    public get isPopupOpen(): boolean {
        return this._isPopupOpen;
    }
    public set isPopupOpen(isOpen: boolean) {
        this._isPopupOpen = isOpen;
        if (isOpen) {
            // opening
            this.wasChecked = this.getCheckedItemsId();
        } else {
            // closing
            this.revert();
        }
    }

    public async apply(): Promise<void> {
        await this.popup.close();
        this._isPopupOpen = false; // Close without reverting
        const checked = this.getCheckedItemsId();
        this.selectionChanged.emit(checked);
        this.wasChecked = checked;
        this.isDirty = false;
    }

    public async cancel(): Promise<void> {
        await this.popup.close();
        this.isPopupOpen = false;
        this.isDirty = false;
    }

    public onSelectionChange(item: ListItem, isChecked: boolean): void {
        item.isChecked = isChecked;
        this.isDirty = !isEqual(this.getCheckedItemsId(), this.wasChecked);
    }

    public async togglePopup(): Promise<void> {
        await this.popup.toggle();
        this.isPopupOpen = this.popup.open;
    }

    private getCheckedItemsId(): Array<string> {
        return this.filters.filter((x) => x.isChecked).map((x) => x.id);
    }

    private revert(): void {
        this.filters.forEach((x) => (x.isChecked = this.wasChecked?.includes(x.id)));
    }
}
