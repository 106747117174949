import { ILogger } from '../../utils/logger';
import { IPreviewCanvas } from './PreviewCanvas';
import { Coords } from '../../utils/Coords';
import { DigitalZoom } from '../DigitalZoomControl';
import { IVideoFrameSource, IVideoFrame } from '../../players/VideoFrameSource';
import { Cancellation } from '../../utils/CancellationToken';
import { Resolution } from '../../utils/Resolution';
import { FitResolution } from '../../utils/FitResolution';
import { Rectangle } from '../../utils/Rectangle';
import { TopLeftCoords } from '../../utils/TopLeftCoords';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { Utils } from '../../utils/Utils';

/**
 * Interface to manipulate the Digital Zoom preview control
 * @public
 * */
export interface IDigitalZoomPreview {
  /**
   * Get or set whether the digital zoom preview is displayed when zoomed
   */
  Enabled: boolean;

  /**
   * The canvas to draw the preview on
   */
  Canvas: HTMLCanvasElement;
}

export class DigitalZoomPreview implements IDigitalZoomPreview {
  private readonly m_logger: ILogger;
  private readonly m_previewCanvas: IPreviewCanvas;
  private readonly m_digitalZoom: DigitalZoom;
  private readonly m_videoFrameSource: IVideoFrameSource;

  private m_videoFrameSourceListenLoopStarted = false;
  private m_isEnabled = false;
  private m_lastImageSource: IVideoFrame | null = null;

  private get CanvasResolution(): Resolution {
    return this.m_previewCanvas.Resolution;
  }

  public get Canvas(): HTMLCanvasElement {
    return this.m_previewCanvas.Canvas;
  }

  public get Enabled(): boolean {
    return this.m_isEnabled;
  }

  public set Enabled(value: boolean) {
    if (this.m_isEnabled !== value) {
      this.m_isEnabled = value;
      this.videoFrameSourceListenLoop();
      this.render();
    }
  }

  public constructor(logger: ILogger, previewCanvas: IPreviewCanvas, digitalZoom: DigitalZoom, videoFrameSource: IVideoFrameSource) {
    this.m_logger = logger.subLogger('DigitalZoomPreview');
    this.m_previewCanvas = previewCanvas;
    this.m_previewCanvas.Clicked.register(this.onClicked);
    this.m_previewCanvas.Resized.register(this.onResized);
    this.m_digitalZoom = digitalZoom;
    this.m_videoFrameSource = videoFrameSource;

    this.zoomConfigUpdateListenLoop();
  }

  public dispose(): void {
    this.m_logger.debug?.trace('dispose');
    this.m_previewCanvas.Clicked.unregister(this.onClicked);
    this.m_previewCanvas.Resized.unregister(this.onResized);
    this.m_previewCanvas.Show = false;
    this.m_previewCanvas.dispose();
    this.m_videoFrameSource.dispose();
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void {
    this.m_previewCanvas.updateVideoWatermarkingConfig(videoWatermarkingConfig);
  }

  private async videoFrameSourceListenLoop(): Promise<void> {
    if (this.m_videoFrameSourceListenLoopStarted) {
      return;
    }
    this.m_logger.debug?.trace('Start of videoFrameSourceListenLoop()');
    this.m_videoFrameSourceListenLoopStarted = true;
    while (this.m_isEnabled && this.m_digitalZoom.IsZoomed) {
      const result = await this.m_videoFrameSource.getImage();
      if (result instanceof Cancellation) {
        break;
      }

      this.m_lastImageSource = result;
      this.render();
    }
    this.m_videoFrameSourceListenLoopStarted = false;
    this.m_logger.debug?.trace('End of videoFrameSourceListenLoop()');
  }

  private async zoomConfigUpdateListenLoop(): Promise<void> {
    this.m_logger.debug?.trace('Start of zoomConfigUpdateListenLoop()');
    while (true) {
      const result = await this.m_digitalZoom.ZoomConfigChange;
      if (result instanceof Cancellation) {
        break;
      }

      this.videoFrameSourceListenLoop();
      this.render();
    }
    this.m_logger.debug?.trace('End of zoomConfigUpdateListenLoop()');
  }

  private render(): void {
    if (this.m_lastImageSource === null) {
      return;
    }

    if (this.m_digitalZoom.IsZoomed && this.m_isEnabled) {
      this.m_previewCanvas.Show = true;
      this.m_previewCanvas.render(this.m_lastImageSource);
      this.drawRectangle();
    } else {
      this.m_previewCanvas.Show = false;
    }
  }

  private drawRectangle(): void {
    if (this.m_lastImageSource === null) {
      return;
    }
    const rectDimensions = Resolution.build(this.CanvasResolution.Width / this.m_digitalZoom.Zoom,
      this.CanvasResolution.Height / this.m_digitalZoom.Zoom);

    const fitRes = new FitResolution(this.m_lastImageSource.Resolution, Resolution.build(this.CanvasResolution.Width, this.CanvasResolution.Height));

    const rectCoords = new TopLeftCoords(
      fitRes.Scaled.Width * (this.m_digitalZoom.X - 1 / this.m_digitalZoom.Zoom / 2) - fitRes.OffsetH / this.m_digitalZoom.Zoom,
      fitRes.Scaled.Height * (this.m_digitalZoom.Y - 1 / this.m_digitalZoom.Zoom / 2) - fitRes.OffsetV / this.m_digitalZoom.Zoom);

    this.m_previewCanvas.drawRectangle(new Rectangle(rectCoords, rectDimensions));
  }

  private readonly onClicked = (data: Coords) => {
    if (this.m_lastImageSource === null) {
      return;
    }
    const imageRes = new FitResolution(this.m_lastImageSource.Resolution, Resolution.build(this.CanvasResolution.Width, this.CanvasResolution.Height)).Scaled;
    if (data.X < 0 || data.X > imageRes.Width || data.Y < 0 || data.Y > imageRes.Height) {
      return; // Clicking outside the image
    }
    this.m_digitalZoom.goTo(data.X / imageRes.Width, data.Y / imageRes.Height, this.m_digitalZoom.Zoom);
    this.render();
  }

  private readonly onResized = (_: Resolution) => {
    this.render();
  }

  public debugStatus(indent: number): string {
    return `Digital Zoom preview: (${this.m_isEnabled ? 'enabled' : 'disabled'})` + Utils.indentNewLine(indent) +
      'Is zoomed: ' + this.m_digitalZoom.IsZoomed + Utils.indentNewLine(indent) +
      this.m_previewCanvas.debugStatus(indent + Utils.Indentation);
  }
}
