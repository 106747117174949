import { Injectable, OnDestroy } from '@angular/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { IGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { EventTypes } from 'RestClient/Client/Enumerations/EventTypes';
import { TranslateService } from '@ngx-translate/core';
import { AlarmStates } from 'RestClient/Client/Enumerations/AlarmStates';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { RefreshAlarmsEvent } from '../controllers/events/RefreshAlarmsEvent';
import { IAlarmAcknowledgeAction, IAlarmCountResult, IAlarmResult, IAlarmsController } from '../controllers/alarms.controller.interfaces';
import { AlarmsController } from '../controllers/alarms.controller';
import { AdvancedSettingsService } from '../../shared/services/advanced-settings/advanced-settings.service';

@UntilDestroy()
@Injectable()
export class AlarmsService implements OnDestroy {
    public alarmEventReceived$: Observable<EventReceivedArg>;
    public activeAlarmCountUpdated$: Observable<number>;

    private alarmEventReceivedSubject$ = new Subject<EventReceivedArg>();
    private activeAlarmCountUpdatedSubject$ = new BehaviorSubject<number>(0);

    private readonly defaultMaxAlarmsCount = 50;
    private readonly strictMaxAlarmsCount = 500;

    private initialActiveAlarmsCountFetched = false;

    private scClient: WebAppClient;
    private subscriptions = new SubscriptionCollection();

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        private translateService: TranslateService,
        private advancedSettingsService: AdvancedSettingsService,
        private authService: AuthService,
        private logger: LoggerService
    ) {
        this.scClient = securityCenterClientService?.scClient;

        this.alarmEventReceived$ = this.alarmEventReceivedSubject$.asObservable();
        this.activeAlarmCountUpdated$ = this.activeAlarmCountUpdatedSubject$.asObservable();

        if (this.scClient) {
            this.scClient.registerAdditionalEventTypes('RefreshAlarmsEvent', RefreshAlarmsEvent);
            this.subscriptions.add(this.scClient.onEventReceived((arg) => this.onAlarmTrayEventReceived(arg)));
            if (this.scClient.isLoggedOn) {
                this.refreshActiveAlarmsCountAsync().fireAndForget();
            }
        }

        this.subscribeAuthService();
    }

    public async acknowledgeAsync(alarmAction: IAlarmAcknowledgeAction): Promise<IRestResponse | null> {
        const controller = await this.getController();
        return await controller?.acknowledgeAsync(alarmAction);
    }

    public async getActiveInstancesCountAsync(): Promise<IAlarmCountResult | null> {
        const controller = await this.getController();
        return await controller?.getActiveInstancesCountAsync();
    }

    public getAlarmStateString(alarmState: string): string {
        switch (alarmState) {
            case AlarmStates.Active: {
                return this.translateService.instant('STE_LABEL_ALARM_ACTIVE') as string;
            }
            case AlarmStates.Acked: {
                return this.translateService.instant('STE_LABEL_ALARMSTATE_ACKED') as string;
            }
            case AlarmStates.AcknowledgeRequired: {
                return this.translateService.instant('STE_LABEL_ALARMSTATE_ACKNOWLEDGEMENT_REQUIRED') as string;
            }
            case AlarmStates.SourceConditionInvestigating: {
                return this.translateService.instant('STE_LABEL_ALARMSTATE_INVESTIGATING') as string;
            }
            default: {
                this.logger.traceDebug(`Unknown alarm state, cannot translate ${alarmState}`);
            }
        }
        return '';
    }

    public getListMaxCount(): number {
        let count = this.advancedSettingsService.get('AlarmsListMaxCount', this.defaultMaxAlarmsCount);

        if (count < 0) {
            count = this.defaultMaxAlarmsCount;
        }

        return Math.min(count, this.strictMaxAlarmsCount);
    }

    public async getInstancesAsync(): Promise<IAlarmResult | null> {
        const controller = await this.getController();

        return await controller?.getInstancesAsync(this.getListMaxCount());
    }

    public async investigateAsync(instanceId: number, alarmId: IGuid): Promise<IRestResponse | null> {
        const controller = await this.getController();
        return await controller?.investigateAsync(instanceId, alarmId);
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }

    public async triggerAsync(alarmId: IGuid): Promise<IRestResponse | null> {
        const controller = await this.getController();
        return await controller?.triggerAsync(alarmId);
    }

    private async getController(): Promise<IAlarmsController> {
        return await this.scClient.getAsync<AlarmsController, IAlarmsController>(AlarmsController);
    }

    private async onAlarmTrayEventReceived(arg: EventReceivedArg) {
        if (arg.event) {
            if (arg.event.eventType === EventTypes.AlarmTriggered) {
                this.setActiveAlarmCount(this.activeAlarmCountUpdatedSubject$.getValue() + 1);
                this.alarmEventReceivedSubject$.next(arg);
            } else if (
                arg.event.eventType === EventTypes.AlarmAcknowledged ||
                arg.event.eventType === EventTypes.AlarmAcknowledgedAlternate ||
                arg.event.eventType === EventTypes.AlarmForciblyAcked
            ) {
                this.setActiveAlarmCount(this.activeAlarmCountUpdatedSubject$.getValue() - 1);
                this.alarmEventReceivedSubject$.next(arg);
            } else if (arg.event.eventType === EventTypes.AlarmInvestigating) {
                this.alarmEventReceivedSubject$.next(arg);
            } else if (arg.event.eventType === 'RefreshAlarmsEvent') {
                await this.refreshActiveAlarmsCountAsync();
                this.alarmEventReceivedSubject$.next(arg);
            }
        }
    }

    private async refreshActiveAlarmsCountAsync() {
        this.initialActiveAlarmsCountFetched = false;
        const alarmCountResult = await this.getActiveInstancesCountAsync();
        if (alarmCountResult) {
            // After receiving the count, we can now start to listen to events and do +1 and -1
            this.initialActiveAlarmsCountFetched = true;

            this.setActiveAlarmCount(alarmCountResult.count);
        }
    }

    private setActiveAlarmCount(count: number) {
        if (!this.initialActiveAlarmsCountFetched) {
            this.logger.traceDebug(`Trying to set AlarmCount to ${count} but initial count not yet fetch.`);
            return;
        }

        if (count < 0) {
            // Log if less than 0, something not normal!
            this.logger.traceWarning(`Setting AlarmCount to ${count}`);

            // Prevent user from seeing -1 and force value to 0 in that case
            this.activeAlarmCountUpdatedSubject$.next(0);

            // Force a refresh, if we were in a state where we have less than 0 alarm, we need to restart our count
            this.refreshActiveAlarmsCountAsync().fireAndForget();

            return;
        }

        this.activeAlarmCountUpdatedSubject$.next(count);
    }

    private subscribeAuthService(): void {
        this.authService.loggedIn$
            .pipe(
                tap((isLoggedIn) => (isLoggedIn ? this.onLoggedIn() : this.onLoggedOut())),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private onLoggedIn(): void {
        this.refreshActiveAlarmsCountAsync().fireAndForget();
    }

    private onLoggedOut(): void {
        this.initialActiveAlarmsCountFetched = false;
    }
}
