import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[appDebounceClick]',
})
export class DebounceClickDirective implements OnInit {
    @Input()
    public debounceTime = 400;

    @Output()
    public debounceClick = new EventEmitter();

    private clicks = new Subject();

    constructor() {}

    @HostListener('click', ['$event'])
    public clickEvent(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }

    ngOnInit() {
        // use throttleTime instead of debounceTime to ensure no delay with the first click
        this.clicks.pipe(throttleTime(this.debounceTime), untilDestroyed(this)).subscribe((e) => this.debounceClick.emit(e));
    }
}
