import { Injectable, OnDestroy, Version } from '@angular/core';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { GuidMap, IGuid, SafeGuid } from 'safeguid';
import { EventFilter } from 'RestClient/Connection/EventFilter';
import { EventTypes } from 'RestClient/Client/Enumerations/EventTypes';
import { isEqual } from 'lodash-es';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { delayWhen, filter, first, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { MonitoredEntityEvent } from '@modules/shared/controllers/events/monitored-entity-event';
import { IEventBase } from 'RestClient/Client/Interface/IEventBase';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EntityTypeResult, EventDefinition, EventEntry, EventsClient } from '@modules/shared/api/api';
import { WebAppClient } from 'WebClient/WebAppClient';
import { EntityTypeSet } from '@modules/shared/entity-browser/entity-type-set';
import { EntityTypeData } from '@modules/shared/models/entity-type-data';
import { LoggerService } from '../logger/logger.service';
import { AdvancedSettingsService } from '../advanced-settings/advanced-settings.service';
import { EntityEventObserver } from './observers/entity-event-observer';
import { EventTypeData } from './event-type-data';
import { EventsSettingsService } from './events-settings.service';

@Injectable({
    providedIn: 'root',
})
export class MonitoredEntityEventObserver extends EntityEventObserver<MonitoredEntityEvent> {}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class EventsService implements OnDestroy {
    public static readonly MinimumSecurityCenterVersionForFilteredEntityTypes = new Version('5.11.2');

    public supportedEntityTypes$: Observable<EntityTypeSet>;

    public cacheLifetimeMilliseconds$: Observable<number>;

    private scClient: WebAppClient;

    private eventsSubscription?: Subscription;
    private multiEventsSubscription?: Subscription;

    private listeningEvents: EventTypeData[] = [];

    private lastAddFilterApplied: IGuid = SafeGuid.EMPTY;

    private readonly monitoredEntityEventKey = 'MonitoredEntityEvent';

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        private authService: AuthService,
        private entityEventObserver: MonitoredEntityEventObserver,
        private eventsClient: EventsClient,
        private loggerService: LoggerService,
        private eventsSettingsService: EventsSettingsService,
        private advancedSettingService: AdvancedSettingsService
    ) {
        this.scClient = securityCenterClientService?.scClient;
        this.subscribeMonitoredEntityEvents();
        this.subscribeApplySettingsOnUserSettingsChanged();
        this.subscribeApplySettingsOnLogonStateChanged();

        this.supportedEntityTypes$ = combineLatest([this.authService.loggedIn$, this.eventsClient.supportedEntityTypes()]).pipe(
            map(([isLoggedIn, entityTypes]) => (isLoggedIn ? entityTypes : [])),
            this.removeEntityTypeResultsSubTypesIfNotSupported(),
            map((entityTypeResults) => {
                return new EntityTypeSet(entityTypeResults.map((entityTypeResult) => new EntityTypeData(entityTypeResult.type, entityTypeResult.subType ?? undefined)));
            })
        );
        this.cacheLifetimeMilliseconds$ = this.eventsClient.getCacheLifetimeMilliseconds().pipe(shareReplay(1));
    }

    ngOnDestroy(): void {
        this.eventsSubscription?.unsubscribe();
        this.multiEventsSubscription?.unsubscribe();
        this.entityEventObserver.complete();
    }

    public fetchRegisteredEventTypesAsync(): Promise<EventDefinition[]> {
        return this.eventsClient.events().toPromise();
    }

    public retrieveLatestEvents(source: IGuid, maxResults: number): Observable<EventEntry[]> {
        return this.eventsClient.latestEvents(source, maxResults);
    }

    public startMonitoringEvents(entityId: IGuid): Observable<MonitoredEntityEvent[]> {
        const existingObservable = this.entityEventObserver.getObservableFor(entityId);
        if (existingObservable) {
            return existingObservable;
        }
        // Todo: send the start/stop monitoring in batch, now that the backend supports it.
        return this.eventsClient.startMonitoringEvents([entityId]).pipe(
            first(),
            mergeMap(() =>
                this.entityEventObserver.setObservableFor(entityId, () => this.eventsClient.stopMonitoringEvents([entityId]).pipe(take(1), untilDestroyed(this)).subscribe())
            )
        );
    }

    public applyDefaultSettingsIfNotSet =
        () =>
        (source$: Observable<any>): Observable<any> =>
            source$.pipe(
                filter(() => !this.eventsSettingsService.getListeningEventsSettings()),
                switchMap(() => from(this.fetchRegisteredEventTypesAsync())),
                map((eventDefinitions) => this.getEventsSelectedFromEventDefinitions(eventDefinitions)),
                this.applyListeningEvents()
            );

    private getEventsSelectedFromEventDefinitions(eventDefinitions: EventDefinition[]) {
        const listeningEventsFromStorage = this.eventsSettingsService.getListeningEventsFromLocalStorage();
        const isAllEnabledByDefault = !listeningEventsFromStorage && this.advancedSettingService.get('EnableAllEventsByDefault', false);
        return eventDefinitions
            .filter((eventDefinition) => {
                return (
                    isAllEnabledByDefault ||
                    eventDefinition.listenByDefault ||
                    (listeningEventsFromStorage && this.isEventDefinitionInEventTypeData(eventDefinition, listeningEventsFromStorage))
                );
            })
            .map((eventDefinition) => new EventTypeData(eventDefinition.eventType, eventDefinition.eventSubType));
    }

    private isEventDefinitionInEventTypeData(eventDefinition: EventDefinition, eventTypeData: EventTypeData[]): boolean {
        return eventTypeData.some((item) => item.type === eventDefinition.eventType && item.subType === eventDefinition.eventSubType);
    }

    private getMonitoredEntitiesFromEventsReceived(args: EventReceivedArg[]): MonitoredEntityEvent[] {
        return args.map((arg) => this.getMonitoredEntityEvent(arg?.event) ?? null).filter((event): event is MonitoredEntityEvent => event != null);
    }

    private groupEventsByMonitoredEntityId =
        () =>
        (source$: Observable<MonitoredEntityEvent[]>): Observable<Map<IGuid, MonitoredEntityEvent[]>> =>
            source$.pipe(
                map((monitoredEntityEvents) =>
                    monitoredEntityEvents.reduce((acc: Map<IGuid, MonitoredEntityEvent[]>, current: MonitoredEntityEvent) => {
                        if (acc.has(current.monitoredEntityId)) {
                            acc.get(current.monitoredEntityId)?.push(current);
                        } else {
                            acc.set(current.monitoredEntityId, [current]);
                        }
                        return acc;
                    }, new GuidMap<MonitoredEntityEvent[]>())
                )
            );

    private publishAllEvents(mappedEvents: Map<IGuid, MonitoredEntityEvent[]>): void {
        mappedEvents.forEach((value, key) => this.entityEventObserver.publishEvents(key, value));
    }

    private getMonitoredEntityEvent(eventBase: IEventBase): MonitoredEntityEvent | undefined {
        return eventBase.eventType === this.monitoredEntityEventKey && eventBase instanceof MonitoredEntityEvent ? eventBase : undefined;
    }

    private applyListeningEvents =
        () =>
        (source$: Observable<EventTypeData[]>): Observable<EventTypeData[]> =>
            source$.pipe(
                filter((listeningEvents) => !isEqual(this.listeningEvents, listeningEvents)),
                delayWhen(() => (this.lastAddFilterApplied.isEmpty() ? of(undefined) : from(this.scClient.removeFromStreamerFilterAsync(this.lastAddFilterApplied)))),
                tap((listeningEvents) => (this.listeningEvents = listeningEvents)),
                delayWhen(() => from(this.scClient.addToStreamerFilterAsync(this.createEventsToAddFilter()).then((filterId) => (this.lastAddFilterApplied = filterId)))),
                tap(() => this.eventsSettingsService.setListeningEventsToLocalStorage(this.listeningEvents)),
                tap((eventTypeData) => this.loggerService.traceDebug(`EventsService - Listening events changed. Count of monitored events: ${eventTypeData.length}`))
            );

    private createEventsToAddFilter(): EventFilter {
        const eventToAddFilter = new EventFilter();
        this.listeningEvents.forEach((event) => {
            eventToAddFilter.eventTypes.add(event.type);
            if (event.type === EventTypes.CustomEvent) {
                eventToAddFilter.customEventTypeIds.add(event.subType);
            }
        });
        return eventToAddFilter;
    }

    private onLoggedOut(): void {
        this.listeningEvents.length = 0;
        this.entityEventObserver.complete();
    }

    private subscribeApplySettingsOnUserSettingsChanged() {
        this.eventsSettingsService.listeningEvents$.pipe(this.applyListeningEvents(), untilDestroyed(this)).subscribe();
    }

    private subscribeApplySettingsOnLogonStateChanged() {
        this.authService.loggedIn$
            .pipe(
                tap((isLoggedIn) => {
                    if (!isLoggedIn) {
                        this.onLoggedOut();
                    }
                }),
                filter((isLoggedIn) => isLoggedIn),
                this.applyDefaultSettingsIfNotSet(),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private removeEntityTypeResultsSubTypesIfNotSupported =
        () =>
        (source$: Observable<EntityTypeResult[]>): Observable<EntityTypeResult[]> =>
            source$.pipe(
                map((entityTypeResults) =>
                    this.areSubTypesSupportedForEntityTypes()
                        ? entityTypeResults
                        : entityTypeResults.map((entityTypeResult) => {
                              const newType = new EntityTypeResult();
                              newType.type = entityTypeResult.type;
                              return newType;
                          })
                )
            );

    private areSubTypesSupportedForEntityTypes(): boolean {
        let areSubTypesSupported = true;

        if (this.scClient.securityCenterVersion) {
            const scVersion = new Version(this.scClient.securityCenterVersion.securityCenterVersion);
            if (scVersion.full < EventsService.MinimumSecurityCenterVersionForFilteredEntityTypes.full) {
                areSubTypesSupported = false;
            }
        }
        return areSubTypesSupported;
    }

    private subscribeMonitoredEntityEvents() {
        if (this.scClient) {
            this.scClient.registerAdditionalEventTypes(this.monitoredEntityEventKey, MonitoredEntityEvent);
            this.scClient.eventsReceived$
                .pipe(
                    map((args) => this.getMonitoredEntitiesFromEventsReceived(args)),
                    this.groupEventsByMonitoredEntityId(),
                    tap((mappedEvents) => this.publishAllEvents(mappedEvents)),
                    untilDestroyed(this)
                )
                .subscribe();
        }
    }
}
