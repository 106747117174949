import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-investigate-filter-base',
    template: '',
})
export abstract class InvestigateFilterBaseComponent<TFilter> extends TrackedComponent implements OnInit, OnDestroy {
    //#region Properties

    @Output() filterToggle = new EventEmitter<boolean>();

    @Input()
    public get value(): TFilter {
        return this.privateValue;
    }
    public set value(newValue: TFilter) {
        if (newValue && newValue !== this.privateValue) {
            this.privateValue = newValue;
            // fire value changed
            this.onValueChanged();
        }
    }

    @Output()
    public valueChange = new EventEmitter<TFilter>();

    public filterStatus!: string;

    private privateValue!: TFilter;
    private dataSubscription: Subscription | undefined = undefined;

    //#endregion

    //#region Constructors

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    //#endregion

    //#region Methods

    @HostListener('pointerdown')
    @HostListener('pointerup')
    @HostListener('keydown')
    @HostListener('keyup')
    public onUserInteracted(): void {
        this.onValueChanged();
    }

    public ngOnInit() {
        const sub = this.setupSubscription();
        if (sub) {
            this.dataSubscription = sub;
        }
    }

    public ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    //#endregion

    //#region Events

    public onFilterToggled(isFilterOpened: boolean): void {
        if (isFilterOpened) {
            this.onUserInteracted();
        }
    }

    protected onValueChanged(): void {
        this.valueChange.emit(this.value);
    }

    protected silentSetValue(value: TFilter): void {
        this.privateValue = value;
    }

    protected abstract setupSubscription(): Subscription | undefined;

    //#endregion
}
