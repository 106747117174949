<div genkeyboardNavigation (genEscape)="closeSidePane()">
    <gen-text-input
        #mainTextInput
        placeholder="{{ 'STE_MESSAGE_INFO_WHATAREYOULOOKINGFOR' | translate }}"
        appAutoFocus
        class="columns-span-3"
        [appDebounce]="1000"
        [ngModel]="searchText"
        (ngModelChange)="onSearchTextChange($event)"
        [maxlength]="100"
        appKeydownStopPropagation
        (focus)="onFocus()"
        data-cy="global-search"
    >
    </gen-text-input>
</div>
