// Autogenerated: v2/help/Entities/ApiPrivilegedEntity?codegen=ts&InterfaceOnly=False&workflow=False on 2022-02-24 3:03:15 PM
import { FieldObject } from '../../Helpers/FieldObject';
import { Entity } from './Entity';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { IUserBurningConfiguration, IPrivilege, IPrivilegedEntity, PrivilegedEntityFields, IPtzPriority } from '../Interface/IPrivilegedEntity';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ITransaction } from '../Interface/ITransaction';

// #region Inner classes

export class UserBurningConfiguration extends FieldObject implements IUserBurningConfiguration {
    // IsEnabled
    public get isEnabled(): boolean | null {
        return this.getNullableField<boolean>('IsEnabled');
    }
    public set isEnabled(value: boolean | null) {
        this.setNullableField<boolean>('IsEnabled', value);
    }
    // Opacity
    public get opacity(): number | null {
        return this.getNullableField<number>('Opacity');
    }
    public set opacity(value: number | null) {
        this.setNullableField<number>('Opacity', value);
    }
    // Size
    public get size(): number | null {
        return this.getNullableField<number>('Size');
    }
    public set size(value: number | null) {
        this.setNullableField<number>('Size', value);
    }
    // IncludeUsername
    public get includeUsername(): boolean | null {
        return this.getNullableField<boolean>('IncludeUsername');
    }
    public set includeUsername(value: boolean | null) {
        this.setNullableField<boolean>('IncludeUsername', value);
    }
    // IncludeCameraName
    public get includeCameraName(): boolean | null {
        return this.getNullableField<boolean>('IncludeCameraName');
    }
    public set includeCameraName(value: boolean | null) {
        this.setNullableField<boolean>('IncludeCameraName', value);
    }
    // IncludeWorkstation
    public get includeWorkstation(): boolean | null {
        return this.getNullableField<boolean>('IncludeWorkstation');
    }
    public set includeWorkstation(value: boolean | null) {
        this.setNullableField<boolean>('IncludeWorkstation', value);
    }
    // OverlayType
    public get overlayType(): string | null {
        return this.getNullableField<string>('OverlayType');
    }
    public set overlayType(value: string | null) {
        this.setNullableField<string>('OverlayType', value);
    }
    // MosaicOrientation
    public get mosaicOrientation(): string | null {
        return this.getNullableField<string>('MosaicOrientation');
    }
    public set mosaicOrientation(value: string | null) {
        this.setNullableField<string>('MosaicOrientation', value);
    }
    // OverlayPosition
    public get overlayPosition(): string | null {
        return this.getNullableField<string>('OverlayPosition');
    }
    public set overlayPosition(value: string | null) {
        this.setNullableField<string>('OverlayPosition', value);
    }

    public initializeAllFields(): void {
        this.setField<boolean>('IsEnabled', false);
        this.setField<number>('Opacity', 0);
        this.setField<number>('Size', 0);
        this.setField<boolean>('IncludeUsername', false);
        this.setField<boolean>('IncludeCameraName', false);
        this.setField<boolean>('IncludeWorkstation', false);
        this.setField<string>('OverlayType', '');
        this.setField<string>('MosaicOrientation', '');
        this.setField<string>('OverlayPosition', '');
    }
}

export class PtzPriority extends FieldObject implements IPtzPriority {
    // Task
    public get task(): IGuid {
        return this.getFieldGuid('Task');
    }
    public set task(value: IGuid) {
        this.setFieldGuid('Task', value);
    }
    // Priority
    public get priority(): number {
        return this.getField<number>('Priority');
    }
    public set priority(value: number) {
        this.setField<number>('Priority', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Task', SafeGuid.EMPTY);
        this.setField<number>('Priority', 0);
    }
}

export class Privilege extends FieldObject implements IPrivilege {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // State
    public get state(): string {
        return this.getField<string>('State');
    }
    public set state(value: string) {
        this.setField<string>('State', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // Parents
    public get parents(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('Parents');
    }
    public set parents(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('Parents', value);
    }
    // SetChildren
    public get setChildren(): boolean {
        return this.getField<boolean>('SetChildren');
    }
    public set setChildren(value: boolean) {
        this.setField<boolean>('SetChildren', value);
    }
    // SetParentsIfNecessary
    public get setParentsIfNecessary(): boolean {
        return this.getField<boolean>('SetParentsIfNecessary');
    }
    public set setParentsIfNecessary(value: boolean) {
        this.setField<boolean>('SetParentsIfNecessary', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('State', '');
        this.setField<string>('Description', '');
        this.setFieldArrayGuid('Parents', new ObservableCollection<IGuid>());
        this.setField<boolean>('SetChildren', false);
        this.setField<boolean>('SetParentsIfNecessary', false);
    }
}

// #endregion Inner classes

export class PrivilegedEntity extends Entity implements IPrivilegedEntity {
    // #region REST Properties

    // ArchiveViewingLimitation
    public get archiveViewingLimitation(): number {
        return this.getField<number>(PrivilegedEntityFields.archiveViewingLimitationField, ValidFlags.SpecificExtra);
    }
    public set archiveViewingLimitation(value: number) {
        this.setField<number>(PrivilegedEntityFields.archiveViewingLimitationField, value);
    }

    // AutoLogOffDuration
    public get autoLogOffDuration(): number {
        return this.getField<number>(PrivilegedEntityFields.autoLogOffDurationField, ValidFlags.Specific);
    }
    public set autoLogOffDuration(value: number) {
        this.setField<number>(PrivilegedEntityFields.autoLogOffDurationField, value);
    }

    // AutoLogOffDurationInMinutes
    public get autoLogOffDurationInMinutes(): number {
        return this.getField<number>(PrivilegedEntityFields.autoLogOffDurationInMinutesField, ValidFlags.Specific);
    }
    public set autoLogOffDurationInMinutes(value: number) {
        this.setField<number>(PrivilegedEntityFields.autoLogOffDurationInMinutesField, value);
    }

    // Domain
    public get domain(): string {
        return this.getField<string>(PrivilegedEntityFields.domainField);
    }
    public set domain(value: string) {
        this.setField<string>(PrivilegedEntityFields.domainField, value);
    }

    // EmailAddress
    public get emailAddress(): string {
        return this.getField<string>(PrivilegedEntityFields.emailAddressField, ValidFlags.Specific);
    }
    public set emailAddress(value: string) {
        this.setField<string>(PrivilegedEntityFields.emailAddressField, value);
    }

    // InheritArchiveViewingLimitationFromUserGroup
    public get inheritArchiveViewingLimitationFromUserGroup(): boolean {
        return this.getField<boolean>(PrivilegedEntityFields.inheritArchiveViewingLimitationFromUserGroupField, ValidFlags.SpecificExtra);
    }
    public set inheritArchiveViewingLimitationFromUserGroup(value: boolean) {
        this.setField<boolean>(PrivilegedEntityFields.inheritArchiveViewingLimitationFromUserGroupField, value);
    }

    // InheritUserBurningConfigurationFromUserGroup
    public get inheritUserBurningConfigurationFromUserGroup(): boolean {
        return this.getField<boolean>(PrivilegedEntityFields.inheritUserBurningConfigurationFromUserGroupField, ValidFlags.SpecificExtra);
    }
    public set inheritUserBurningConfigurationFromUserGroup(value: boolean) {
        this.setField<boolean>(PrivilegedEntityFields.inheritUserBurningConfigurationFromUserGroupField, value);
    }

    // IsAdministrator
    public get isAdministrator(): boolean {
        return this.getField<boolean>(PrivilegedEntityFields.isAdministratorField);
    }

    // IsAutoLogOffInherited
    public get isAutoLogOffInherited(): boolean {
        return this.getField<boolean>(PrivilegedEntityFields.isAutoLogOffInheritedField, ValidFlags.Specific);
    }
    public set isAutoLogOffInherited(value: boolean) {
        this.setField<boolean>(PrivilegedEntityFields.isAutoLogOffInheritedField, value);
    }

    // IsSecurityLevelInherited
    public get isSecurityLevelInherited(): boolean {
        return this.getField<boolean>(PrivilegedEntityFields.isSecurityLevelInheritedField, ValidFlags.Specific);
    }
    public set isSecurityLevelInherited(value: boolean) {
        this.setField<boolean>(PrivilegedEntityFields.isSecurityLevelInheritedField, value);
    }

    // QualifiedName
    public get qualifiedName(): string {
        return this.getField<string>(PrivilegedEntityFields.qualifiedNameField);
    }

    // SecurityLevel
    public get securityLevel(): number {
        return this.getField<number>(PrivilegedEntityFields.securityLevelField, ValidFlags.Specific);
    }
    public set securityLevel(value: number) {
        this.setField<number>(PrivilegedEntityFields.securityLevelField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor(entityType: string) {
        super(entityType);
    }

    // #endregion Constructor

    // #region REST Methods

    public async isLicenseSupportPrivilegeAsync(privilege: IGuid, transaction?: ITransaction): Promise<boolean | null> {
        const responseHandler = (response: IRestResponse): Promise<boolean> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body['licenseSupportPrivilege'] as boolean;
                return new Promise<boolean>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<boolean>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('privilege', privilege);

        return this.executeRequestTransactionAsync<boolean>('IsLicenseSupportPrivilege', 'POST', inParam, transaction, responseHandler);
    }

    public async isPrivilegeGrantedAsync(privilege: IGuid, entity: IGuid, transaction?: ITransaction): Promise<boolean | null> {
        const responseHandler = (response: IRestResponse): Promise<boolean> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body['granted'] as boolean;
                return new Promise<boolean>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<boolean>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('privilege', privilege);
        inParam.setFieldGuid('entity', entity);

        return this.executeRequestTransactionAsync<boolean>('IsPrivilegeGranted', 'POST', inParam, transaction, responseHandler);
    }

    public async getPartitionAdminsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('PartitionAdmin', 'Id', false, query, ValidFlags.Specific, transaction);
    }

    public async getPartitionExceptionsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('PartitionExceptions', 'Id', true, query, ValidFlags.Specific, transaction);
    }

    public async getPartitionUserAccessAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('PartitionUserAccess', 'Id', false, query, ValidFlags.MemberOf2, transaction);
    }

    public async getPrivilegesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPrivilege> | null> {
        return this.getFieldObjectRelationAsync<Privilege, IPrivilege>(Privilege, 'Privileges', 'Id', false, true, ValidFlags.Specific, transaction);
    }

    public async getPtzPrioritiesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzPriority> | null> {
        return this.getFieldObjectRelationAsync<PtzPriority, IPtzPriority>(PtzPriority, 'PtzPriorities', 'Task', false, true, ValidFlags.SpecificExtra, transaction);
    }

    public async getPtzPrioritiesRecursiveAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzPriority> | null> {
        return this.getFieldObjectRelationAsync<PtzPriority, IPtzPriority>(PtzPriority, 'PtzPrioritiesRecursive', 'Task', true, false, ValidFlags.SpecificExtra, transaction);
    }

    public async getRelatedAlarmsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('RelatedAlarms', 'Id', true, query, ValidFlags.MemberOf3, transaction);
    }

    public async getRemoteControllableAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('RemoteControllable', 'Id', false, query, ValidFlags.SpecificExtra, transaction);
    }

    public async sendMessageAsync(message: string, timeoutDuration: number, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('message', message);
        inParam.setField('timeoutDuration', timeoutDuration);

        return this.executeRequestTransactionAsync<IRestResponse>('SendMessage', 'POST', inParam, transaction, responseHandler);
    }

    public async getUserBurningConfigurationAsync(transaction?: ITransaction | null): Promise<IUserBurningConfiguration | null> {
        return await this.getDataRelationAsync<UserBurningConfiguration>(UserBurningConfiguration, 'UserBurningConfiguration', true, ValidFlags.SpecificExtra, transaction);
    }

    public async getUsergroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Usergroups', 'Id', false, query, ValidFlags.Membership, transaction);
    }

    public async getUsergroupsRecursiveAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('UsergroupsRecursive', 'Id', true, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

