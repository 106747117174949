import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon, IconSize } from '@genetec/gelato';
import { GenPopup, ImageFit, ImageFlavor, PopupPosition } from '@genetec/gelato-angular';
import { SafeGuid } from 'safeguid';

@Component({
    selector: 'app-thumbnail',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailComponent {
    @Input() public id = SafeGuid.newGuid().toString();
    @Input() public thumbnailData: string | undefined | null;
    // Specific to image
    @Input() public flavor = ImageFlavor.Rectangle;
    @Input() public fit = ImageFit.Default;
    @Input() public thumbnailBorderRadius: string | undefined;
    // Specific to placeholder
    @Input() public defaultPlaceholder: string | undefined;
    @Input() public placeholderOpacity = '100%';
    // Specific to popup
    @Input() public displayPopup = false;
    @Input() public popupPosition = PopupPosition.Auto;

    public readonly IconSize = IconSize;
    public readonly Icon = Icon;

    public isData(data: string | undefined | null): boolean {
        if (data) {
            return !data.startsWith('Icon.');
        }
        return false;
    }

    public formatImageData(data: string | undefined | null): string {
        if (data && this.isData(data)) {
            if (data.startsWith('data:')) {
                return data;
            } else {
                return 'data:image/png;base64,' + data;
            }
        }
        return data ?? '';
    }

    public getIcon(thumbnailData: string | undefined | null): string {
        let icon: Icon | undefined;
        if (thumbnailData?.startsWith('Icon.')) {
            const splitString = thumbnailData?.split('.');

            if (splitString?.length > 1) {
                const iconName = splitString[1];
                if (iconName in Icon) {
                    icon = Icon[iconName as keyof typeof Icon];
                }
            }
        }
        if (!icon && this.defaultPlaceholder) {
            return this.defaultPlaceholder;
        }

        return icon ?? Icon.None;
    }

    public async showPopupAsync(popup: GenPopup): Promise<void> {
        if (!this.displayPopup) {
            return;
        }
        await popup.show();
    }
}
