// Autogenerated: v2/help/Entities/ApiFile?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:24 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { IGuid } from 'safeguid';
import { FileEntityFields, IFileEntity } from '../Interface/IFileEntity';

export class FileEntity extends Entity implements IFileEntity {
    // #region REST Properties

    // CacheGuid
    public get cacheGuid(): IGuid {
        return this.getFieldGuid(FileEntityFields.cacheGuidField);
    }
    public set cacheGuid(value: IGuid) {
        this.setFieldGuid(FileEntityFields.cacheGuidField, value);
    }

    // FileCategory
    public get fileCategory(): string {
        return this.getField<string>(FileEntityFields.fileCategoryField);
    }
    public set fileCategory(value: string) {
        this.setField<string>(FileEntityFields.fileCategoryField, value);
    }

    // XmlInfo
    public get xmlInfo(): string {
        return this.getField<string>(FileEntityFields.xmlInfoField);
    }
    public set xmlInfo(value: string) {
        this.setField<string>(FileEntityFields.xmlInfoField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Files);
    }

    // #endregion Constructor

}

