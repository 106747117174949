import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { GenMeltedModalComponent } from '@genetec/gelato-angular';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ImageFit, ImageFlavor } from '@genetec/gelato';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { ConnectionAwareModalComponent } from '../tracked/connection-aware-modal.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-image-viewer-modal',
    templateUrl: './image-viewer-modal.component.html',
    styleUrls: ['./image-viewer-modal.component.scss'],
})
export class ImageViewerModalComponent extends ConnectionAwareModalComponent implements OnInit {
    //#region Fields
    @ViewChild(GenMeltedModalComponent) public genModalComponent!: GenMeltedModalComponent;

    @Input()
    public image: string | null = null;

    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;

    //#endregion

    //#region Constructor

    constructor(protected authService: AuthService, injector: Injector, trackingService: TrackingService) {
        super(authService, injector, trackingService);
    }

    //#endregion

    //#region Methods

    ngOnInit() {
        super.ngOnInit();
    }

    //#endregion
}
