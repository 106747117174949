import { List } from 'immutable';
import { IncidentFilter } from '../../models/incident-filter';
import { MCIncident } from '../../models/mc-incident';
import { IncidentSorting } from './../../models/incident-sorting';

export class IncidentPipelineParameter {
    public readonly incidents: List<MCIncident>;
    public readonly filter: IncidentFilter;
    public readonly needRefresh: boolean;
    public readonly page: number;
    public readonly sorting: IncidentSorting;

    constructor(incidents = List<MCIncident>(), filter = IncidentFilter.empty, needRefresh = true, page = 0, sorting = new IncidentSorting()) {
        this.incidents = incidents;
        this.filter = filter;
        this.needRefresh = needRefresh;
        this.page = page;
        this.sorting = sorting;
        Object.freeze(this);
    }

    public with({
        incidents,
        filter,
        needRefresh,
        page,
        sorting,
    }: {
        incidents?: List<MCIncident>;
        filter?: IncidentFilter;
        needRefresh?: boolean;
        page?: number;
        sorting?: IncidentSorting;
    }): IncidentPipelineParameter {
        return new IncidentPipelineParameter(
            incidents ?? this.incidents,
            filter ?? this.filter,
            needRefresh === undefined ? this.needRefresh : needRefresh,
            page === undefined ? this.page : page,
            sorting ?? this.sorting
        );
    }
}
