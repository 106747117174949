// Autogenerated: v2/help/Entities/ApiMapManagerRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { RoleEntity } from './RoleEntity';
import { IGuid } from 'safeguid';
import { IMapManagerRoleEntity, MapManagerRoleEntityFields } from '../../Interface/IMapManagerRoleEntity';

export class MapManagerRoleEntity extends RoleEntity implements IMapManagerRoleEntity {
    // #region REST Properties

    // CacheLocation
    public get cacheLocation(): string {
        return this.getField<string>(MapManagerRoleEntityFields.cacheLocationField);
    }
    public set cacheLocation(value: string) {
        this.setField<string>(MapManagerRoleEntityFields.cacheLocationField, value);
    }

    // ConnectionPort
    public get connectionPort(): number {
        return this.getField<number>(MapManagerRoleEntityFields.connectionPortField);
    }
    public set connectionPort(value: number) {
        this.setField<number>(MapManagerRoleEntityFields.connectionPortField, value);
    }

    // DefaultMapId
    public get defaultMapId(): IGuid {
        return this.getFieldGuid(MapManagerRoleEntityFields.defaultMapIdField);
    }
    public set defaultMapId(value: IGuid) {
        this.setFieldGuid(MapManagerRoleEntityFields.defaultMapIdField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

