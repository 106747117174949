// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.

import { IGuid } from 'safeguid';

// ==========================================================================
export class MapEntryParams {
    public mapId?: IGuid;
    public viewLat?: number;
    public viewLng?: number;
    public selectedMapObjectId?: string;
    public parentAreaId?: IGuid;
}
