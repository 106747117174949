<gen-melted-modal
    [id]="id"
    genTitle="{{ 'STE_BUTTON_GOTOTIME' | translate }}"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_LABEL_OK' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    (genModalAction)="onAction($event)"
    appFocusModalWhenOpen
    genKeyboardNavigation
    (genEnter)="close()"
>
    <gen-melted-modal-body>
        <section class="gen-app">
            <gen-calendar [(ngModel)]="timestamp" [genFlavor]="Flavor.Calendar.DateTime" genHideToday="false" [genIsMilitaryTime]="isMilitaryTime"> </gen-calendar>
        </section>
    </gen-melted-modal-body>
</gen-melted-modal>
