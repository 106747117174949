import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ItemSlot, PopupPosition, IconSize } from '@genetec/gelato';
import { GenPopup } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { FilterState } from '@modules/shared/services/filters/filter';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { IGuid } from 'safeguid';

export interface FilterHostDescriptor {
    filterId: string;
    label: string;
    type?: IGuid;
    icon?: string;
    lazyLoad?: boolean;
}

/**
 * Component used as a host for filter display and state management
 */
@Component({
    selector: 'app-filter-host',
    templateUrl: './filter-host.component.html',
    styleUrls: ['./filter-host.component.scss'],
})
export class FilterHostComponent extends TrackedComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(GenPopup, { static: true }) popup!: GenPopup;
    @ViewChild(GenPopup, { read: ElementRef }) public popupHTMLRef!: ElementRef<HTMLGenPopupElement>;

    @Input() public filterDescriptor!: FilterHostDescriptor;

    @Input() public state: FilterState = { status: 'Ok', selection: '', message: '' };

    @Input() public staysOpen = false;
    @Input() public popupPosition = PopupPosition.BottomLeft;
    @Output() public filterToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    public get filterType(): string {
        return (this.filterDescriptor && 'type' in this.filterDescriptor && this.filterDescriptor.type?.toString()) || '';
    }

    public readonly IconSize = IconSize;
    public readonly ItemSlot = ItemSlot;
    public readonly PopupPosition = PopupPosition;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        this.popupHTMLRef.nativeElement.addEventListener('didClose', this.onPopupClose);
        this.popupHTMLRef.nativeElement.addEventListener('didOpen', this.onPopupOpen);
    }

    ngOnDestroy(): void {
        this.popupHTMLRef.nativeElement.removeEventListener('didClose', this.onPopupClose);
        this.popupHTMLRef.nativeElement.removeEventListener('didOpen', this.onPopupOpen);
    }

    public async toggleFilter(): Promise<void> {
        await this.popup.toggle();
    }

    private onPopupClose = () => {
        this.filterToggled.emit(false);
    };

    private onPopupOpen = () => {
        this.filterToggled.emit(true);
    };
}
