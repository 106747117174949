import { Component, Inject } from '@angular/core';
import { ContentService, SIDE_CONTEXT_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Component({
    selector: 'app-shell-side-pane',
    templateUrl: 'side-pane.component.html',
})
export class AppSidePaneComponent {
    constructor(@Inject(SIDE_CONTEXT_SERVICE) public contextDataService: ContentService) {}
}
