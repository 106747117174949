import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonFlavor, ItemSlot, PopupPosition } from '@genetec/gelato';
import { ComboboxFlavor, Gelato, GenMeltedListItem, GenPopup, ItemDensity } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { uniqueId } from 'lodash-es';
import { coerceBooleanProperty } from '@modules/shared/utilities/coerceBooleanProperty';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-one-or-many-combobox-combobox',
    templateUrl: './one-or-many-combobox-combobox.component.html',
    styleUrls: ['./one-or-many-combobox-combobox.component.scss'],
})
export class OneOrManyComboboxComponent extends Gelato implements OnInit {
    //#region Properties

    @ViewChild(GenPopup) popup!: GenPopup;

    @Input()
    public values: GenMeltedListItem[] = [];

    @Input()
    public get options(): GenMeltedListItem[] {
        return this.privateOptions;
    }
    public set options(values: GenMeltedListItem[]) {
        if (values?.length > 0) {
            this.privateOptions = values;
        } else {
            this.privateOptions = [];
        }
        this.visibleOptions = this.privateOptions;
    }

    @Input()
    public get multiSelect(): boolean {
        return this.privateMultiSelect;
    }
    public set multiSelect(v: boolean) {
        this.privateMultiSelect = coerceBooleanProperty(v);
        this.onMultiSelectChanged();
    }

    @Output()
    public valuesChanged = new EventEmitter<GenMeltedListItem[]>();

    /**
     * String to describe the accociated component
     */
    @Input()
    public genLabel = '';

    /**
     * Style of element
     *
     * Ex: Flavor.Combobox.Flat
     */
    @Input()
    public genFlavor: ComboboxFlavor = ComboboxFlavor.Solid;

    /**
     * Text to show when nothing is selected.
     */
    @Input()
    public genPlaceholder!: string;

    /**
     * #### [HTML id Attribute](https://www.w3schools.com/tags/att_id.asp)
     * The id attribute specifies a unique id for an HTML element (the value must be unique within the HTML document).
     * The id attribute is most used to point to a style in a style sheet, and by JavaScript to manipulate the element with the specific id.
     */
    @Input()
    public id!: string;

    /**
     * Disallows user interaction
     */
    @Input()
    public get disabled(): boolean {
        return this.isDisabled;
    }
    public set disabled(value: boolean) {
        this.isDisabled = coerceBooleanProperty(value);
    }

    /**
     * Shows a button to clear the selection
     * Default: False
     */
    @Input()
    public get genShowClearButton(): boolean {
        return this.showClearButton;
    }
    public set genShowClearButton(value: boolean) {
        this.showClearButton = coerceBooleanProperty(value);
    }

    /**
     * Event emitted when the combo panel has been toggled.
     */
    @Output()
    public readonly genIsOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly ItemDensity = ItemDensity;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly ItemSlot = ItemSlot;
    public readonly PopupPosition = PopupPosition;

    /**
     * The ID to be set to the combobox
     */
    public comboboxId = '';

    /**
     * If combobox list is currently opened
     */
    public selectedText = '';
    public searchText = '';
    public visibleOptions: GenMeltedListItem[] = [];

    private showClearButton = false;
    private privateMultiSelect = false;
    private privateOptions: GenMeltedListItem[] = [];

    /**
     * Emits whenever the component is destroyed.
     */
    private isDisabled = false;

    //#endregion

    //#region Constructors

    constructor(private translateService: TranslateService) {
        super();
    }

    //#endregion

    //#region Public Methods

    /**
     * A callback method that is invoked immediately after the default change detector
     * has checked the directive's data-bound properties for the first time, and before any of
     * the view or content children have been checked. It is invoked only once when the directive
     * is instantiated.
     */
    public ngOnInit(): void {
        this.ensureIdGenerated();
        this.comboboxId = 'component-child--' + this.id;

        if (!this.genPlaceholder) {
            this.translateService
                .get('STE_LABEL_SELECT')
                .pipe(untilDestroyed(this))
                .subscribe((value) => {
                    this.genPlaceholder = value as string;
                });
        }
    }

    //#endregion

    //#region Events

    public onSearchChange(newSearchText: string): void {
        this.searchText = newSearchText;
        if (this.searchText) {
            this.visibleOptions = this.options.filter((item) => item.text.toLowerCase().includes(this.searchText.toLowerCase()));
        } else {
            this.visibleOptions = this.options;
        }
    }
    public searchBackspace(): void {}

    /**
     * Called when an item is clicked in the combobox list
     */
    public async selectItem(newValue: GenMeltedListItem): Promise<void> {
        if (this.multiSelect) {
            // toggle the check mark in the event of a click
            newValue.isChecked = !newValue.isChecked;
        } else {
            this.options.forEach((item) => (item.isChecked = false));
            newValue.isChecked = true;
            await this.closeDropdown();
        }

        this.values = this.options.filter((opt) => opt.isChecked);
        this.valuesChanged.emit(this.values);
        this.setSelectedText();
    }

    /**
     * Called when an item's checkbox is toggled
     */
    public onItemCheckChanged(checked: boolean, id: string): void {
        const found = this.options.find((item) => item.id === id);
        if (found) {
            found.isChecked = checked;
            this.values = this.options.filter((opt) => opt.isChecked);
            this.valuesChanged.emit(this.values);
        }

        this.setSelectedText();
    }

    public onItemCheckClicked(id: string, event?: MouseEvent): void {
        if (event) {
            event.preventDefault();
            const found = this.options.find((item) => item.id === id);
            if (found) {
                found.isChecked = !found.isChecked;
                this.values = this.options.filter((opt) => opt.isChecked);
                this.valuesChanged.emit(this.values);
            }
            this.setSelectedText();
        }
    }

    /**
     * Called when the Clear button is clicked
     *
     * _genShowClearButton must be set_
     */
    public async clearSelection(event: Event): Promise<void> {
        this.values = [];
        this.valuesChanged.emit(this.values);
        await this.closeDropdown();

        this.setSelectedText();
    }

    /**
     * Closes the dropdown and emits the close event.
     */
    public async closeDropdown(): Promise<void> {
        if (this.popup.open) {
            await this.popup.close();
            this.genIsOpenChange.emit(false);
            // this.onTouched();
        }

        this.setSelectedText();
    }

    /**
     * Opens the dropdown.
     */
    public async openDropdown(): Promise<void> {
        if (this.disabled) {
            return;
        }
        await this.popup.show();
        this.genIsOpenChange.emit(true);

        this.setSelectedText();
    }

    /**
     * Toggles the dropdown.
     */
    public async toggleDropdown(event?: MouseEvent): Promise<void> {
        if (this.isDisabled) {
            return;
        }

        if (event !== undefined && event !== null) {
            event.preventDefault();
        }

        if (!this.multiSelect) {
            await this.popup.toggle();
        } else if (!this.popup.open) {
            await this.popup.show();
        }
    }

    public searchInputClick(event?: MouseEvent): void {
        if (event !== undefined && event !== null) {
            event.stopPropagation();
        }
    }

    private onMultiSelectChanged() {
        if (this.multiSelect) {
            if (this.values.length > 0) {
                this.values[0].isChecked = true;
            }
            // enabled
        } else {
            // disabled
            if (this.options?.length && this.options.length > 0) {
                this.options.forEach((opt) => (opt.isChecked = false));
                this.options[0].isChecked = true;
                this.values = this.options.filter((opt) => opt.isChecked);
                this.valuesChanged.emit(this.values);
            }
        }
    }

    //#endregion

    //#region Private Methods

    private setSelectedText() {
        this.selectedText = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: this.privateOptions.filter((item) => item.isChecked).length }) as string;
    }

    /**
     * Make sure that an id is generated
     */
    private ensureIdGenerated(): void {
        if (!this.id) {
            this.id = uniqueId();
        }
    }

    //#endregion
}
