// Autogenerated: v2/help/Entities/ApiMediaGatewayRole?codegen=ts&InterfaceOnly=True&workflow=False on 2020-09-21 3:54:06 PM
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';

// #region Fields

export class MediaGatewayRoleEntityFields extends RoleEntityFields {
    public static httpPortField = 'HttpPort';
    public static httpsPortField = 'HttpsPort';
    public static listenPortField = 'ListenPort';
    public static rtspEnabledField = 'RtspEnabled';
    public static secureServerField = 'SecureServer';
    public static useDefaultPortsField = 'UseDefaultPorts';
    public static useRtspsField = 'UseRtsps';
    public static webApplicationPathField = 'WebApplicationPath';
}

// #endregion Fields

export interface IMediaGatewayRoleEntity extends IRoleEntity {
    // #region REST Properties

    httpPort: number;

    httpsPort: number;

    listenPort: number;

    rtspEnabled: boolean;

    secureServer: boolean;

    useDefaultPorts: boolean;

    useRtsps: boolean;

    webApplicationPath: string;

    // #endregion REST Properties

}

