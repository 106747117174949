import { MeltedIcon } from '@genetec/gelato-angular';
import { ITilePattern } from 'RestClient/Client/Interface/ITileLayoutEntity';
import { TileBoundary } from './tile-boundary';
import { TileItem } from './tile-item';
import { TilePatternDefinition } from './tile-pattern-definition';

export class TilePatternItem {
    //#region Properties

    public readonly name: string;
    public readonly definition: string;

    // gets the number of tiles
    public get tileCount(): number {
        return this.patternDefinition.gridCellDefinitions.length;
    }

    public get uniqueId(): string {
        return this.definition;
    }

    private readonly patternDefinition: TilePatternDefinition;

    //#endregion

    //#region Constructor

    constructor(name: string, public icon: MeltedIcon, definition: string) {
        this.name = name;
        this.definition = definition;
        this.patternDefinition = TilePatternDefinition.fromDefinition(this.definition);
    }

    //#endregion

    //#region Methods

    public static fromTilePattern(pattern: ITilePattern): TilePatternItem {
        return new TilePatternItem(pattern.name, MeltedIcon.TilePattern, TilePatternDefinition.toDefinition(pattern));
    }

    public static fromJson(json: string): TilePatternItem | undefined {
        try {
            const deserializedObject = JSON.parse(json) as Record<string, unknown>;

            // Validate most important properties
            if (typeof deserializedObject.name !== 'string' || typeof deserializedObject.definition !== 'string' || typeof deserializedObject.icon !== 'string') {
                throw new Error('json string does not represent a valid TilePatternItem');
            }

            return new TilePatternItem(deserializedObject.name, deserializedObject.icon as MeltedIcon, deserializedObject.definition);
        } catch (error: unknown) {
            return undefined;
        }
    }

    public toJson(): string {
        return JSON.stringify(this);
    }

    public getGridTemplateColumns(): string {
        return this.patternDefinition.columnDefinitions.map((i) => i.toString() + 'fr ').join(' ');
    }

    public getGridTemplateRows(): string {
        return this.patternDefinition.rowDefinitions.map((i) => i.toString() + 'fr ').join(' ');
    }

    public createTiles(existingTiles: TileItem[]): TileItem[] {
        const tiles: TileItem[] = [];

        // determine the number of tiles to create
        const count = this.patternDefinition.gridCellDefinitions.length;

        // build each tile
        for (let tileId = 0; tileId < count; ++tileId) {
            const cellSpanDef = this.patternDefinition.gridCellDefinitions[tileId];

            const boundary = new TileBoundary(cellSpanDef.startColumn, cellSpanDef.startRow, cellSpanDef.endColumn, cellSpanDef.endRow);
            let tile = new TileItem(tileId + 1, boundary);

            const existingTile = existingTiles.find((item) => item.id === tileId + 1);
            if (existingTile) {
                existingTile.boundary = boundary;
                tile = existingTile;
            }
            tiles.push(tile);
        }

        return tiles;
    }

    //#endregion
}

export class HighlightTilePatternItem extends TilePatternItem {
    constructor(public name: string, public icon: MeltedIcon) {
        super(name, icon, '3x3 [1.1.2.2]');
    }
}
