import { Component, Inject, OnInit } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { SelectionType } from '@genetec/gelato';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { ListItem } from '@modules/shared/interfaces/list-item';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TimelineService } from '@modules/video/services/timeline.service';
import { SafeGuid } from 'safeguid';

@Component({
    selector: 'app-video-options',
    templateUrl: './video-options.component.html',
})
@InternalPluginDescriptor({
    type: VideoOptionsComponent,
    pluginTypes: [PluginTypes.Option],
    exposure: {
        id: VideoOptionsComponent.pluginId,
        subSection: OptionSubSections.Video,
    },
    isSupported: () => true,
})
export class VideoOptionsComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('4CCCF685-5909-4D0D-9949-9A2EF0912950');

    public providers: ListItem[] = [];
    public hasTileHeaderFeatureFlag? = false;
    public isContentVisible = false;
    public readonly ListSelectionType = SelectionType;

    public get selectedProvidersId(): string[] {
        return this.providers.filter((provider) => provider.isChecked).map((selectedProvider) => selectedProvider.id);
    }

    constructor(
        private timelineService: TimelineService,
        trackingService: TrackingService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService,
        private advancedSettingsService: AdvancedSettingsService
    ) {
        super(trackingService);
        this.hasTileHeaderFeatureFlag = GeneralFeatureFlags.EntityState.isEnabled(this.advancedSettingsService);
    }

    async ngOnInit() {
        const contentVisibility = this.userSettingsService.getOptionAvailability(OptionTypes.Appearance);
        if (contentVisibility) {
            this.isContentVisible = true;
            const list: ListItem[] = [];
            const providers = await this.timelineService.fetchProvidersAsync();
            if (providers) {
                providers.forEach((x) => {
                    list.push({ id: x.id.toString(), text: x.title, icon: x.icon as MeltedIcon, isChecked: false });
                });
            }
            let providersSelectedString = this.userSettingsService.get<string>(OptionTypes.Appearance, 'timelineProviders');
            if (!providersSelectedString) {
                providersSelectedString = await this.timelineService.applyDefaultSettingsAsync();
            }
            const providersSelected = providersSelectedString ? (JSON.parse(providersSelectedString) as string[]) : undefined;

            if (providersSelected) {
                providersSelected.forEach((provider) => {
                    const item = list.find((x) => x.id === provider);
                    if (item) {
                        item.isChecked = true;
                    }
                });
            }

            this.providers = list;
        }
    }

    public onSelectedItemsChange(provider: ListItem, isChecked: boolean): void {
        provider.isChecked = isChecked;

        const providersSelected: string[] = [];
        this.userSettingsService.set(OptionTypes.Appearance, 'timelineProviders', JSON.stringify(this.selectedProvidersId));
    }
}
