import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventTypes } from 'RestClient/Client/Enumerations/EventTypes';
import { IAlarmEvent } from 'RestClient/Client/Interface/IAlarmEvent';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { IGuid, SafeGuid } from 'safeguid';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { DateFormat } from '@genetec/gelato-angular';
import { AlarmStates } from 'RestClient/Client/Enumerations/AlarmStates';
import { IAlarmAcknowledgedEvent } from 'RestClient/Client/Interface/IAlarmAcknowledgedEvent';
import { IAlarmInvestigatingEvent } from 'RestClient/Client/Interface/IAlarmInvestigatingEvent';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TrackedComponent } from '../../shared/components/tracked/tracked.component';
import { Content, ContentPluginComponent } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { TrackingService } from '../../shared/services/tracking.service';
import { AlarmContentFields } from '../enumerations/alarm-content-fields';
import { AlarmContentTypes } from '../enumerations/alarm-content-types';
import { AlarmsService } from '../services/alarms.service';
import { SharedContentFields } from '../../shared/enumerations/shared-content-fields';
import { TimeService } from '../../shared/services/time/time.service';
import { ContentService } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';

@UntilDestroy()
@Component({
    selector: 'app-alarm-instance-widget-base-component',
    template: '',
})
export class AlarmInstanceWidgetBaseComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public alarmState = '';
    public alarmStateString = '';
    public content?: Content;
    public dataContext?: ContentService;
    public instanceId!: number;
    public isLoading = true;

    protected alarmId?: IGuid;
    protected triggerTime?: string;

    constructor(protected alarmsService: AlarmsService, protected timeService: TimeService, trackingService: TrackingService) {
        super(trackingService);
    }

    public ngOnInit() {
        super.ngOnInit();

        if (this.content) {
            this.alarmId = SafeGuid.parse(this.content.source);

            this.alarmsService.alarmEventReceived$.pipe(untilDestroyed(this)).subscribe((eventArgs) => {
                this.onEventReceived(eventArgs);
            });
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    protected tryGetAlarmInstanceInfo(content: Content | null | undefined): boolean {
        if (content?.type.equals(AlarmContentTypes.Instance) && content.parameters) {
            this.setAlarmState(content.parameters.getField<string>(AlarmContentFields.State));
            this.instanceId = content.parameters.getField<number>(AlarmContentFields.AlarmInstanceId);

            const triggerTime = content.parameters.getField<Date>(SharedContentFields.Timestamp);
            this.triggerTime = this.timeService.formatTime(triggerTime, DateFormat.DateTime);
            return true;
        }
        return false;
    }

    protected onAlarmAcknowledged(alarmEvent: IAlarmAcknowledgedEvent): void {
        this.setAlarmState(AlarmStates.Acked);
    }

    protected onAlarmInvestigating(alarmEvent: IAlarmInvestigatingEvent): void {
        this.setAlarmState(AlarmStates.SourceConditionInvestigating);
    }

    private setAlarmState(alarmState: string): void {
        this.alarmState = alarmState;
        this.alarmStateString = this.alarmsService.getAlarmStateString(alarmState);
    }

    private onEventReceived(eventArg: EventReceivedArg) {
        const event = eventArg.event as IAlarmEvent;
        if (this.alarmId && event.sourceEntity.equals(this.alarmId) && event.instanceId === this.instanceId) {
            if (
                event.eventType === EventTypes.AlarmAcknowledged ||
                event.eventType === EventTypes.AlarmAcknowledgedAlternate ||
                event.eventType === EventTypes.AlarmForciblyAcked
            ) {
                this.onAlarmAcknowledged(event as IAlarmAcknowledgedEvent);
            } else if (event.eventType === EventTypes.AlarmInvestigating) {
                this.onAlarmInvestigating(event as IAlarmInvestigatingEvent);
            }
        }
    }
}
