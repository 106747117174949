// Autogenerated: v2/help/Entities/ApiIntrusionArea?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IEntity } from '../../Interface/IEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { IGuid } from 'safeguid';
import { FieldObject } from '../../../Helpers/FieldObject';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IArmData, IIntrusionAreaEntity, IntrusionAreaEntityFields } from '../../Interface/IIntrusionAreaEntity';
import { IRestResponse } from '../../Interface/IRestResponse';
import { Entity } from '../Entity';

// #region Inner classes

export class ArmData extends FieldObject implements IArmData {
    // Scope
    public get scope(): string {
        return this.getField<string>('Scope');
    }
    public set scope(value: string) {
        this.setField<string>('Scope', value);
    }
    // Timing
    public get timing(): string {
        return this.getField<string>('Timing');
    }
    public set timing(value: string) {
        this.setField<string>('Timing', value);
    }
    // Type
    public get type(): string {
        return this.getField<string>('Type');
    }
    public set type(value: string) {
        this.setField<string>('Type', value);
    }
    // CustomDelay
    public get customDelay(): number {
        return this.getField<number>('CustomDelay');
    }
    public set customDelay(value: number) {
        this.setField<number>('CustomDelay', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Scope', '');
        this.setField<string>('Timing', '');
        this.setField<string>('Type', '');
        this.setField<number>('CustomDelay', 0);
    }
}

// #endregion Inner classes

export class IntrusionAreaEntity extends Entity implements IIntrusionAreaEntity {
    // #region REST Properties

    // AlarmState
    public get alarmState(): string {
        return this.getField<string>(IntrusionAreaEntityFields.alarmStateField, ValidFlags.EntityStatus);
    }
    public set alarmState(value: string) {
        this.setField<string>(IntrusionAreaEntityFields.alarmStateField, value);
    }

    // AlarmTriggeringInput
    public get alarmTriggeringInput(): IGuid {
        return this.getFieldGuid(IntrusionAreaEntityFields.alarmTriggeringInputField, ValidFlags.Specific);
    }
    public set alarmTriggeringInput(value: IGuid) {
        this.setFieldGuid(IntrusionAreaEntityFields.alarmTriggeringInputField, value);
    }

    // AlarmTriggeringOutput
    public get alarmTriggeringOutput(): IGuid {
        return this.getFieldGuid(IntrusionAreaEntityFields.alarmTriggeringOutputField, ValidFlags.Specific);
    }
    public set alarmTriggeringOutput(value: IGuid) {
        this.setFieldGuid(IntrusionAreaEntityFields.alarmTriggeringOutputField, value);
    }

    // ArmingState
    public get armingState(): string {
        return this.getField<string>(IntrusionAreaEntityFields.armingStateField, ValidFlags.EntityStatus);
    }
    public set armingState(value: string) {
        this.setField<string>(IntrusionAreaEntityFields.armingStateField, value);
    }

    // Capabilities
    public get capabilities(): string {
        return this.getField<string>(IntrusionAreaEntityFields.capabilitiesField, ValidFlags.Specific);
    }

    // ExtensionStatus
    public get extensionStatus(): string {
        return this.getField<string>(IntrusionAreaEntityFields.extensionStatusField, ValidFlags.EntityStatus2);
    }
    public set extensionStatus(value: string) {
        this.setField<string>(IntrusionAreaEntityFields.extensionStatusField, value);
    }

    // InputActive
    public get inputActive(): boolean {
        return this.getField<boolean>(IntrusionAreaEntityFields.inputActiveField);
    }

    // InputTrouble
    public get inputTrouble(): boolean {
        return this.getField<boolean>(IntrusionAreaEntityFields.inputTroubleField);
    }

    // IntrusionUnit
    public get intrusionUnit(): IGuid {
        return this.getFieldGuid(IntrusionAreaEntityFields.intrusionUnitField, ValidFlags.MemberOf3);
    }
    public set intrusionUnit(value: IGuid) {
        this.setFieldGuid(IntrusionAreaEntityFields.intrusionUnitField, value);
    }

    // IsArmed
    public get isArmed(): boolean {
        return this.getField<boolean>(IntrusionAreaEntityFields.isArmedField);
    }

    // IsBypassed
    public get isBypassed(): boolean {
        return this.getField<boolean>(IntrusionAreaEntityFields.isBypassedField);
    }

    // PhysicalName
    public get physicalName(): string {
        return this.getField<string>(IntrusionAreaEntityFields.physicalNameField, ValidFlags.Specific);
    }
    public set physicalName(value: string) {
        this.setField<string>(IntrusionAreaEntityFields.physicalNameField, value);
    }

    // SynergisArea
    public get synergisArea(): IGuid {
        return this.getFieldGuid(IntrusionAreaEntityFields.synergisAreaField, ValidFlags.Specific);
    }
    public set synergisArea(value: IGuid) {
        this.setFieldGuid(IntrusionAreaEntityFields.synergisAreaField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.IntrusionAreas);
    }

    // #endregion Constructor

    // #region REST Methods

    public async acknowledgeIntrusionAlarmAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('AcknowledgeIntrusionAlarm', 'POST', null, transaction, responseHandler);
    }

    public async armZoneAsync(value: IArmData, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('ArmZone', 'POST', value, transaction, responseHandler);
    }

    public async cancelPostponeArmingAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('CancelPostponeArming', 'POST', null, transaction, responseHandler);
    }

    public async disarmZoneAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('DisarmZone', 'POST', null, transaction, responseHandler);
    }

    public async getInputsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Inputs', 'Id', false, query, ValidFlags.Specific, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.EntityMembership, transaction);
    }

    public async postponeArmingAsync(delay: number, masterArm: boolean, armingDelay: number, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('delay', delay);
        inParam.setField('masterArm', masterArm);
        inParam.setField('armingDelay', armingDelay);

        return this.executeRequestTransactionAsync<IRestResponse>('PostponeArming', 'POST', inParam, transaction, responseHandler);
    }

    public async silenceIntrusionAlarmAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('SilenceIntrusionAlarm', 'POST', null, transaction, responseHandler);
    }

    public async triggerIntrusionAlarmAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('TriggerIntrusionAlarm', 'POST', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

