<div class="wrapper" *ngLet="isLoadingMore$ | async as isLoadingMore">
    <div *ngIf="refreshing || isLoadingMore" class="spinner-overlay" [class.load-more]="isLoadingMore">
        <gen-spinner>{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
    </div>
    <div class="no-result-container" *ngIf="!refreshing && cardholders && !cardholders.length">
        <gen-text class="no-result-message">{{ 'STE_LABEL_NORESULTS' | translate }}</gen-text>
        <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_MESSAGE_INFO_NORESULTS_DETAILS' | translate }}</gen-text>
    </div>
    <div
        #container
        class="container"
        [class.no-results]="!cardholders?.length"
        infiniteScroll
        (scrolled)="onLoadMore()"
        [scrollWindow]="false"
        [infiniteScrollDisabled]="!hasMore"
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="150"
        [immediateCheck]="true"
    >
        <div *ngFor="let cardholder of cardholders" class="tiles-container pl-1 pt-1 pr-1 pb-1">
            <app-cardholder-card
                [isSelected]="!!selectedId?.equals(cardholder.guid)"
                [entityName]="buildEntityName(cardholder)"
                [groups]="isViewCardholdersPrivilegeGranted ? cardholder.cardholderGroups : undefined"
                [picture]="cardholder.picture"
                [guid]="cardholder.guid"
                [status]="isViewCardholdersPrivilegeGranted ? cardholder.status : undefined"
                [isViewCardholdersPrivilegeGranted]="isViewCardholdersPrivilegeGranted"
                (itemSelected)="onSelectedChanged($event)"
            ></app-cardholder-card>
        </div>
    </div>
</div>
