// Autogenerated: v2/help/Entities/ApiRoute?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:24 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { IGuid } from 'safeguid';
import { IRouteEntity, RouteEntityFields } from '../Interface/IRouteEntity';
import { ValidFlags } from '../Enumerations/ValidFlags';

export class RouteEntity extends Entity implements IRouteEntity {
    // #region REST Properties

    // DetectedTransportsFrom1To2
    public get detectedTransportsFrom1To2(): string {
        return this.getField<string>(RouteEntityFields.detectedTransportsFrom1To2Field, ValidFlags.EntityStatus);
    }

    // DetectedTransportsFrom2To1
    public get detectedTransportsFrom2To1(): string {
        return this.getField<string>(RouteEntityFields.detectedTransportsFrom2To1Field, ValidFlags.EntityStatus);
    }

    // EndPoint1
    public get endPoint1(): IGuid {
        return this.getFieldGuid(RouteEntityFields.endPoint1Field, ValidFlags.Specific);
    }
    public set endPoint1(value: IGuid) {
        this.setFieldGuid(RouteEntityFields.endPoint1Field, value);
    }

    // EndPoint2
    public get endPoint2(): IGuid {
        return this.getFieldGuid(RouteEntityFields.endPoint2Field, ValidFlags.Specific);
    }
    public set endPoint2(value: IGuid) {
        this.setFieldGuid(RouteEntityFields.endPoint2Field, value);
    }

    // SupportedTransports
    public get supportedTransports(): string {
        return this.getField<string>(RouteEntityFields.supportedTransportsField, ValidFlags.Specific);
    }
    public set supportedTransports(value: string) {
        this.setField<string>(RouteEntityFields.supportedTransportsField, value);
    }

    // UsePrivateAddressFrom1To2
    public get usePrivateAddressFrom1To2(): boolean {
        return this.getField<boolean>(RouteEntityFields.usePrivateAddressFrom1To2Field, ValidFlags.Specific);
    }
    public set usePrivateAddressFrom1To2(value: boolean) {
        this.setField<boolean>(RouteEntityFields.usePrivateAddressFrom1To2Field, value);
    }

    // UsePrivateAddressFrom2To1
    public get usePrivateAddressFrom2To1(): boolean {
        return this.getField<boolean>(RouteEntityFields.usePrivateAddressFrom2To1Field, ValidFlags.Specific);
    }
    public set usePrivateAddressFrom2To1(value: boolean) {
        this.setField<boolean>(RouteEntityFields.usePrivateAddressFrom2To1Field, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Routes);
    }

    // #endregion Constructor

}

