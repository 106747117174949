import {
  GoHomeBody,
  GoToPresetBody,
  PanTiltBody,
  PtzFocusDistance,
  PtzIrisOperation,
  PtzRequest,
  PtzRequestBody,
  RunPatternBody,
  ZoomBody,
  FocusBody,
  IrisBody,
  LockBody,
  UnlockBody,
} from './players/WebSocket/Messages';
import { PtzRequestBuilder } from './players/WebSocket/PtzRequestBuilder';
import { IPtzControlSession } from './players/WebSocket/WsSessionV2';
import { ILogger } from './utils/logger';

/** This class regroups methods to send PTZ commands to supported cameras
 * @public */
export interface IPtzControl {
  /**
   * Start panning (horizontal) and tilting (vertical) movement
   * @param panSpeed - Pan speed, between 0 and 100%
   * @param tiltSpeed - Tilt speed, between 0 and 100%
   */
  startPanTilt(panSpeed: number, tiltSpeed: number): void;
  /** Stop any pan or tilt movement */
  stopPanTilt(): void;
  /**
   * Start zooming movement (optical zoom)
   * @param zoomSpeed - zoom speed between 0 and 100%
   */
  startZoom(zoomSpeed: number): void;
  /** Stop any zooming movement */
  stopZoom(): void;
  /** Move the camera to a preset position */
  goToPreset(preset: number): void;
  /** Move camera to the home position */
  goHome(): void;
  /** Start moving following a pattern */
  runPattern(pattern: number): void;
  /** Stop any PTZ movement */
  stop(): void;
  /** Lock */
  lock(): void;
  /** Unlock */
  unlock(): void;
  /** Start focus */
  startFocus(distance: PtzFocusDistance, speed: number): void;
  /** Stop focus */
  stopFocus(): void;
  /** Start iris */
  startIris(operation: PtzIrisOperation, speed: number): void;
  /** Stop iris */
  stopIris(): void;
}

export interface IPtzThrottlingService {
  /**
   * Sends a command in the queue. It will be processed only if it's different from the last one.
   * @param command - Throttled command
   */
  enqueue(command: ThrottledCommand): void;

  /**
   * Only use this when you know what you are doing.
   * @param cmd - Command to send straight to the session.
   */
  bypassQueue(command: PtzRequest): void;
}

export class ThrottledCommand {
  public command: PtzRequest;
  public body: PtzRequestBody;

  constructor(command: PtzRequest, body: PtzRequestBody) {
    this.command = command;
    this.body = body;
  }
}

export class PtzControl implements IPtzControl {
  private readonly m_logger: ILogger;

  private readonly m_ptzRequestBuilder: PtzRequestBuilder;

  private readonly m_session: IPtzControlSession;

  private m_ptzThrottlingService: IPtzThrottlingService | undefined;

  public set PtzThrottlingService(value: IPtzThrottlingService | undefined) {
    this.m_ptzThrottlingService = value;
  }

  public constructor(
    logger: ILogger,
    session: IPtzControlSession,
    ptzRequestBuilder: PtzRequestBuilder,
    ptzThrottlingService: IPtzThrottlingService | undefined,
  ) {
    this.m_logger = logger.subLogger('PtzControl');
    this.m_session = session;
    this.m_ptzRequestBuilder = ptzRequestBuilder;
    this.m_ptzThrottlingService = ptzThrottlingService;
  }

  public startPanTilt(panSpeed: number, tiltSpeed: number): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStartPanTilt(panSpeed, tiltSpeed),
      new PanTiltBody(panSpeed, tiltSpeed),
    );
    this.sendCommand(throttledCommand);
  }

  public stopPanTilt(): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStopPanTilt(),
      new PanTiltBody(null, null),
    );
    this.sendCommand(throttledCommand, true);
  }

  public startZoom(zoomSpeed: number): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStartZoom(zoomSpeed),
      new ZoomBody(zoomSpeed),
    );
    this.sendCommand(throttledCommand);
  }

  public stopZoom(): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStopZoom(),
      new ZoomBody(null),
    );
    this.sendCommand(throttledCommand);
  }

  public goToPreset(preset: number): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildGoToPreset(preset),
      new GoToPresetBody(preset),
    );
    this.sendCommand(throttledCommand);
  }

  public goHome(): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildGoHome(),
      new GoHomeBody(),
    );
    this.sendCommand(throttledCommand);
  }

  public runPattern(pattern: number): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildRunPattern(pattern),
      new RunPatternBody(pattern),
    );
    this.sendCommand(throttledCommand);
  }

  public lock(): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildLock(),
      new LockBody(),
    );
    this.sendCommand(throttledCommand, true);
  }

  public unlock(): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildUnlock(),
      new UnlockBody(),
    );
    this.sendCommand(throttledCommand, true);
  }

  public stop() {
    this.stopPanTilt();
    this.stopZoom();
  }

  public startFocus(distance: PtzFocusDistance, speed: number): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStartFocus(distance, speed),
      new FocusBody(distance, speed),
    );
    this.sendCommand(throttledCommand);
  }

  public stopFocus(): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStopFocus(),
      new FocusBody(null, null),
    );
    this.sendCommand(throttledCommand);
  }

  public startIris(operation: PtzIrisOperation, speed: number): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStartIris(operation, speed),
      new IrisBody(operation, speed),
    );
    this.sendCommand(throttledCommand);
  }

  public stopIris(): void {
    const throttledCommand = new ThrottledCommand(
      this.m_ptzRequestBuilder.buildStopIris(),
      new IrisBody(null, null),
    );
    this.sendCommand(throttledCommand);
  }

  private sendCommand(command: ThrottledCommand, bypass: boolean = false): void {
    if (this.m_ptzThrottlingService) {
      if (!bypass) {
        this.m_ptzThrottlingService.enqueue(command);
      } else {
        this.m_ptzThrottlingService.bypassQueue(command.command);
      }
    } else {
      try {
        this.m_session.sendCommand(command.command);
      } catch (error) {
        this.m_logger.error?.trace('Error while trying to send PTZ request:', error);
        throw error;
      }
    }
  }
}
