<gen-melted-modal
    id="MyModal"
    genTitle="{{ 'STE_TITLE_EXPORTVIDEO' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_AUTHORIZE' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_BACK' | translate }}"
    genOptionalActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnDefaultAction]="onAuthorize"
    [genIsDefaultActionDisabled]="!enableAuthorize"
    [genOnAlternativeAction]="onBack"
    [genIsBlocking]="true"
    data-cy="exportVideoAuthorizationModal"
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <section class="gen-app">
            <div id="container">
                <div id="warningMessage" class="mb-2">
                    <gen-text flavor="warning" data-cy="authorizationMsg">
                        {{ 'STE_TITLE_AUTHORIZATION_FOUR_EYE' | translate }}
                    </gen-text>
                </div>
                <div id="thumbnail" class="mb-0" data-cy="thumbnail">
                    <gen-text flavor="label"> {{ 'STE_LABEL_REQUESTED_VIDEO' | translate }} </gen-text>
                    <gen-image name="thumbnail" [attr.src]="thumbnail"></gen-image>
                </div>
                <div id="cameraInfo" class="mt-4">
                    <gen-text data-cy="cameraName"> {{ cameraName }} <br /></gen-text>
                    <gen-text data-cy="startTimeDate" flavor="secondary">
                        <br />
                        {{ startTimeString }}
                    </gen-text>
                    <gen-text data-cy="endTimeDate" *ngIf="isNewDay" flavor="secondary"> <br />{{ endTimeString }} </gen-text>
                    <gen-text data-cy="time" flavor="secondary"> <br />{{ timeString }} </gen-text>
                    <gen-text data-cy="duration" flavor="secondary"> <br />{{ duration }} </gen-text>
                </div>
                <div id="login">
                    <gen-text-input data-cy="usernameInput" appAutoFocus required [maxlength]="100" [(ngModel)]="username" (ngModelChange)="onUsernameChanged($event)">
                        <gen-label>{{ 'STE_LABEL_USERNAME' | translate: { Default: 'Username' } }}</gen-label>
                    </gen-text-input>
                    <gen-password-input data-cy="passwordInput" [maxlength]="32" [(ngModel)]="password">
                        <gen-label>{{ 'STE_LABEL_PASSWORD' | translate: { Default: 'Password' } }}</gen-label>
                    </gen-password-input>
                    <gen-text data-cy="errorMesage" flavor="error" *ngIf="errorMessage">
                        <span class="ha-left mt-2">{{ errorMessageString }}</span>
                    </gen-text>
                </div>
            </div>
        </section>
    </gen-melted-modal-body>
</gen-melted-modal>
