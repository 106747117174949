// Autogenerated: v2/help/Entities/ApiReverseTunnelClientRole?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:18 PM
import { RoleEntity } from './RoleEntity';
import { IReverseTunnelClientRoleEntity, ReverseTunnelClientRoleEntityFields } from '../../Interface/IReverseTunnelClientRoleEntity';

export class ReverseTunnelClientRoleEntity extends RoleEntity implements IReverseTunnelClientRoleEntity {
    // #region REST Properties

    // EncryptionMode
    public get encryptionMode(): string {
        return this.getField<string>(ReverseTunnelClientRoleEntityFields.encryptionModeField);
    }
    public set encryptionMode(value: string) {
        this.setField<string>(ReverseTunnelClientRoleEntityFields.encryptionModeField, value);
    }

    // KeyFile
    public get keyFile(): string {
        return this.getField<string>(ReverseTunnelClientRoleEntityFields.keyFileField);
    }

    // RemoteEncryptionMode
    public get remoteEncryptionMode(): string {
        return this.getField<string>(ReverseTunnelClientRoleEntityFields.remoteEncryptionModeField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

