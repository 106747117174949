import { ConnectionState } from './Streamers/ConnectionState';
import { FieldObject } from '../Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { IEventBase } from '../Client/Interface/IEventBase';
import { IFieldObject } from '../Client/Interface/IFieldObject';

export class StateChangeArg {
  public state: ConnectionState = ConnectionState.Disconnected;
}

export class EntityInvalidatedArg {
  public id: IGuid = SafeGuid.EMPTY;
  public entityType = '';
  public flags = 0;
}

export class EntityRemovedArg {
  public id: IGuid = SafeGuid.EMPTY;
  public entityType = '';
  constructor(id?: IGuid, entityType?: string) {
    if (id) {
      this.id = id;
    }
    if (entityType) {
      this.entityType = entityType;
    }
  }
}

export class EntityAddedArg {
  public id: IGuid = SafeGuid.EMPTY;
  public entityType = '';
}

export class EventReceivedArg {
  public event: IEventBase;
  constructor(evt: IEventBase) {
    this.event = evt;
  }
}

export class QueryReceivedArg {
  public query: any;
}

export class UnknownRequestReceivedArg {
  constructor(public msgType: string, public payload: IFieldObject) {}
}

export class RequestReceivedArg {
  public id = '';
  public request: IFieldObject;
  public userId = '';
  public applicationId = '';
  constructor(id: string, request: IFieldObject, userId: string, applicationId: string) {
    this.id = id;
    this.request = request;
    this.userId = userId;
    this.applicationId = applicationId;
  }
}

export class DisconnectRequestArgs {
    constructor(public tryToReconnect = true) {}
}

export class ConsoleCommandRequestReceivedArg {
  public id = '';
  public group = '';
  public method = '';
  public param1 = '';
  public param2 = '';
  public param3 = '';
  constructor(id: string, group: string, method: string, param1: string, param2: string, param3: string) {
    this.id = id;
    this.group = group;
    this.method = method;
    this.param1 = param1;
    this.param2 = param2;
    this.param3 = param3;
  }
}

export class ActionReceivedArg {
  public action: FieldObject;
  constructor(e: FieldObject) {
    this.action = e;
  }
}
