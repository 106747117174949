import { SegmentInfo, JpegInfo, ErrorDetails, SerializedPlayerState, StreamingStatus, TimelineContent } from './eventHub';
import { IEventHubProvider, IDataHub, IEventHub } from './eventHubDispatcher';
import { ILogger } from '../utils/logger';
import { PlaySpeed } from '../players/PlaySpeed';
import { TimeSpan } from '../utils/TimeSpan';
import { Utils } from '../utils/Utils';

export class UnregisteredHub implements IDataHub, IEventHub {
  private readonly m_timeBeforeUnregister: TimeSpan;
  private readonly m_logger: ILogger;
  private readonly m_streamId: string;
  private readonly m_eventHubProvider: IEventHubProvider;

  constructor(logger: ILogger, streamId: string, eventHubProvider: IEventHubProvider, timeBeforeUnregister: TimeSpan) {
    this.m_logger = logger.subLogger('UnregisteredHub');
    this.m_streamId = streamId;
    this.m_eventHubProvider = eventHubProvider;
    this.m_timeBeforeUnregister = timeBeforeUnregister;
    this.m_logger.debug?.trace(`UnregisteredHub created for streamId ${streamId}, will unregister itself in ${this.m_timeBeforeUnregister.toString()}`);
    this.unregisterAfterTimeout();
  }

  receiveSegment(_: SegmentInfo, __: Int8Array): void {
    this.m_logger.debug?.trace('receiveSegment()');
  }
  receiveJpeg(_: JpegInfo, __: Int8Array): void {
    this.m_logger.debug?.trace('receiveJpeg()');
  }
  receivePlayerState(_: SerializedPlayerState): void {
    this.m_logger.debug?.trace('receivePlayerState()');
  }
  receiveStreamingConnectionStatus(_: StreamingStatus): void {
    this.m_logger.debug?.trace('receiveStreamingConnectionStatus()');
  }
  receiveBufferingProgress(_: number): void {
    this.m_logger.debug?.trace('receiveBufferingProgress()');
  }
  receiveErrorDetails(_: ErrorDetails): void {
    this.m_logger.debug?.trace('receiveErrorDetails()');
  }
  receiveTimelineContent(_: TimelineContent): void {
    this.m_logger.debug?.trace('receiveTimelineContent()');
  }
  receivePlaySpeed(_: PlaySpeed): void {
    this.m_logger.debug?.trace('receivePlaySpeed()');
  }
  receiveAudioAvailable(_: boolean): void {
    this.m_logger.debug?.trace('receiveAudioAvailable()');
  }

  private async unregisterAfterTimeout(): Promise<void> {
    await Utils.delayFromTimeSpan(this.m_timeBeforeUnregister);
    this.m_eventHubProvider.unregisterStream(this.m_streamId);
    this.m_logger.debug?.trace(`UnregisteredHub for stream ${this.m_streamId} has been unregistered`);
  }
}
