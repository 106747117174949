// Autogenerated: v2/help/Entities/ApiCardholder?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:02 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { ICardholderPicture, ICardholderEntity, CardholderEntityFields } from '../../Interface/ICardholderEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { RestResponse } from '../../../Connection/RestResponse';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';

// #region Inner classes

export class CardholderPicture extends FieldObject implements ICardholderPicture {
    // Guid
    public get guid(): IGuid {
        return this.getFieldGuid('Guid');
    }
    public set guid(value: IGuid) {
        this.setFieldGuid('Guid', value);
    }
    // Data
    public get data(): string {
        return this.getField<string>('Data');
    }
    public set data(value: string) {
        this.setField<string>('Data', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Guid', SafeGuid.EMPTY);
        this.setField<string>('Data', '');
    }
}

// #endregion Inner classes

export class CardholderEntity extends Entity implements ICardholderEntity {
    // #region REST Properties

    // AccessPermissionLevel
    public get accessPermissionLevel(): number {
        return this.getField<number>(CardholderEntityFields.accessPermissionLevelField, ValidFlags.Specific);
    }
    public set accessPermissionLevel(value: number) {
        this.setField<number>(CardholderEntityFields.accessPermissionLevelField, value);
    }

    // AccessStatus
    public get accessStatus(): string {
        return this.getField<string>(CardholderEntityFields.accessStatusField, ValidFlags.Specific);
    }
    public set accessStatus(value: string) {
        this.setField<string>(CardholderEntityFields.accessStatusField, value);
    }

    // ActivationDate
    public get activationDate(): Date {
        return this.getFieldDate(CardholderEntityFields.activationDateField, ValidFlags.Specific);
    }
    public set activationDate(value: Date) {
        this.setFieldDate(CardholderEntityFields.activationDateField, value);
    }

    // AntipassbackExemption
    public get antipassbackExemption(): boolean {
        return this.getField<boolean>(CardholderEntityFields.antipassbackExemptionField, ValidFlags.Specific);
    }
    public set antipassbackExemption(value: boolean) {
        this.setField<boolean>(CardholderEntityFields.antipassbackExemptionField, value);
    }

    // AntipassbackExemptionIsInherited
    public get antipassbackExemptionIsInherited(): boolean {
        return this.getField<boolean>(CardholderEntityFields.antipassbackExemptionIsInheritedField, ValidFlags.Specific);
    }
    public set antipassbackExemptionIsInherited(value: boolean) {
        this.setField<boolean>(CardholderEntityFields.antipassbackExemptionIsInheritedField, value);
    }

    // CanEscort
    public get canEscort(): boolean {
        return this.getField<boolean>(CardholderEntityFields.canEscortField, ValidFlags.Specific);
    }
    public set canEscort(value: boolean) {
        this.setField<boolean>(CardholderEntityFields.canEscortField, value);
    }

    // EmailAddress
    public get emailAddress(): string {
        return this.getField<string>(CardholderEntityFields.emailAddressField, ValidFlags.SpecificExtra);
    }
    public set emailAddress(value: string) {
        this.setField<string>(CardholderEntityFields.emailAddressField, value);
    }

    // ExpirationDate
    public get expirationDate(): Date {
        return this.getFieldDate(CardholderEntityFields.expirationDateField, ValidFlags.Specific);
    }
    public set expirationDate(value: Date) {
        this.setFieldDate(CardholderEntityFields.expirationDateField, value);
    }

    // ExpirationDuration
    public get expirationDuration(): number {
        return this.getField<number>(CardholderEntityFields.expirationDurationField, ValidFlags.Specific);
    }
    public set expirationDuration(value: number) {
        this.setField<number>(CardholderEntityFields.expirationDurationField, value);
    }

    // ExpirationMode
    public get expirationMode(): string {
        return this.getField<string>(CardholderEntityFields.expirationModeField, ValidFlags.Specific);
    }
    public set expirationMode(value: string) {
        this.setField<string>(CardholderEntityFields.expirationModeField, value);
    }

    // FirstName
    public get firstName(): string {
        return this.getField<string>(CardholderEntityFields.firstNameField, ValidFlags.SpecificExtra);
    }
    public set firstName(value: string) {
        this.setField<string>(CardholderEntityFields.firstNameField, value);
    }

    // HasPicture
    public get hasPicture(): boolean {
        return this.getField<boolean>(CardholderEntityFields.hasPictureField, ValidFlags.SpecificExtra);
    }

    // InheritAccessPermissionLevelFromGroup
    public get inheritAccessPermissionLevelFromGroup(): boolean {
        return this.getField<boolean>(CardholderEntityFields.inheritAccessPermissionLevelFromGroupField, ValidFlags.Specific);
    }
    public set inheritAccessPermissionLevelFromGroup(value: boolean) {
        this.setField<boolean>(CardholderEntityFields.inheritAccessPermissionLevelFromGroupField, value);
    }

    // IsVisitor
    public get isVisitor(): boolean {
        return this.getField<boolean>(CardholderEntityFields.isVisitorField);
    }

    // LastAccess
    public get lastAccess(): Date {
        return this.getFieldDate(CardholderEntityFields.lastAccessField, ValidFlags.EntityStatus);
    }

    // LastAccessGranted
    public get lastAccessGranted(): boolean | null {
        return this.getNullableField<boolean>(CardholderEntityFields.lastAccessGrantedField, ValidFlags.EntityStatus);
    }

    // LastAccessLocation
    public get lastAccessLocation(): IGuid {
        return this.getFieldGuid(CardholderEntityFields.lastAccessLocationField, ValidFlags.EntityStatus);
    }

    // LastName
    public get lastName(): string {
        return this.getField<string>(CardholderEntityFields.lastNameField, ValidFlags.SpecificExtra);
    }
    public set lastName(value: string) {
        this.setField<string>(CardholderEntityFields.lastNameField, value);
    }

    // MobilePhoneNumber
    public get mobilePhoneNumber(): string {
        return this.getField<string>(CardholderEntityFields.mobilePhoneNumberField, ValidFlags.SpecificExtra);
    }
    public set mobilePhoneNumber(value: string) {
        this.setField<string>(CardholderEntityFields.mobilePhoneNumberField, value);
    }

    // PictureId
    public get pictureId(): IGuid {
        return this.getFieldGuid(CardholderEntityFields.pictureIdField, ValidFlags.SpecificExtra);
    }

    // UseExtendedGrantTime
    public get useExtendedGrantTime(): boolean {
        return this.getField<boolean>(CardholderEntityFields.useExtendedGrantTimeField, ValidFlags.Specific);
    }
    public set useExtendedGrantTime(value: boolean) {
        this.setField<boolean>(CardholderEntityFields.useExtendedGrantTimeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Cardholders);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPointRules', 'Id', true, query, ValidFlags.MemberOf2, transaction);
    }

    public async getCardholdergroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cardholdergroups', 'Id', false, query, ValidFlags.MemberOf, transaction);
    }

    public async getCredentialsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Credentials', 'Id', false, query, ValidFlags.EntityDependency, transaction);
    }

    public async forgiveAntipassbackAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('ForgiveAntipassback', 'POST', null, transaction, responseHandler);
    }

    public async getHostedVisitorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('HostedVisitors', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async deletePictureAsync(): Promise<IRestResponse> {
        await this.updateDataRelation2Async<CardholderPicture, ICardholderPicture>(CardholderPicture, 'Picture', 'DELETE', false, ValidFlags.SpecificExtra, null);
        return new RestResponse(HttpStatusCode.OK);
    }

    public async getPictureAsync(transaction?: ITransaction | null): Promise<ICardholderPicture | null> {
        return await this.getDataRelationAsync<CardholderPicture>(CardholderPicture, 'Picture', false, ValidFlags.SpecificExtra, transaction);
    }

    public async addPictureAsync(value: ICardholderPicture, transaction?: ITransaction | null): Promise<ICardholderPicture | null> {
        return await this.updateDataRelation2Async<CardholderPicture, ICardholderPicture>(CardholderPicture, 'Picture', 'POST', false, ValidFlags.SpecificExtra, value);
    }

    // #endregion REST Methods

}

