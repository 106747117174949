import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { LanguageService, StringEntry } from '@modules/shared/services/language/language.service';
import { NavigationService } from '@modules/shared/services/navigation/navigation.service';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { Constants } from '@src/constants';
import { SafeGuid } from 'safeguid';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-task-plugin',
    templateUrl: './task-plugin.component.html',
    styleUrls: ['./task-plugin.component.scss'],
})
export class TaskPluginComponent extends TrackedComponent implements OnInit, OnDestroy {
    public taskPlugin: PluginItem | undefined;
    constructor(
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private pluginService: PluginService,
        private languageService: LanguageService,
        trackingService: TrackingService
    ) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.route.params.pipe(untilDestroyed(this)).subscribe(async (params) => {
            const pluginId = params[Constants.pluginTaskIdentifier] as string;
            const subTaskId = params[Constants.subTaskIdentifier] as string;

            let useFirstAvailableTask = true;

            if (pluginId) {
                this.taskPlugin = await this.pluginService.getPlugin(PluginTypes.Task, SafeGuid.parse(pluginId), undefined);
                if (this.taskPlugin) {
                    useFirstAvailableTask = false;

                    // update the page's title
                    const title = this.taskPlugin.exposure.title;
                    if (title) {
                        this.languageService.setTitle(new StringEntry(title));
                    }
                }
            }

            if (useFirstAvailableTask) {
                const plugins = await this.pluginService.getPlugins(PluginTypes.Task, undefined);
                if (plugins.length > 0) {
                    const id = plugins[0].exposure?.id;
                    if (id) {
                        // use the navigation service to bring up the default task because we want the route to be applied
                        let url = `/task/${id.toString()}`;

                        if (subTaskId) {
                            url += `/${subTaskId}`;
                        }
                        await this.navigationService.navigate(url);
                    }
                }
            }
        });
    }
}
