import { ILogger } from '../../utils/logger';
import { MseMediaSource, MediaType } from '../mse/MseMediaSource';
import { SequenceInfo } from './SequenceInfo';
import { SegmentInfo } from '../../services/eventHub';
import { PlaySpeed } from '../PlaySpeed';
import { BufferQueue } from '../msePlayer';
import { Utils } from '../../utils/Utils';

export class MseHtmlAudioElement {
  private static readonly AudioSyncToleranceMilliseconds = 200;

  private static readonly AudioSyncMaxDifferenceMilliseconds= 2000;

  private readonly m_logger: ILogger;

  private readonly m_htmlAudioElement: HTMLAudioElement;

  private readonly m_mseMediaSource: MseMediaSource;

  private m_currentSequence: SequenceInfo | undefined;

  private m_isMuted: boolean;

  private m_isPlayable: boolean;

  private m_isPaused: boolean;

  private m_playSpeed: PlaySpeed;

  public get isMuted(): boolean {
    return this.m_isMuted;
  }

  constructor(logger: ILogger, audioElement: HTMLAudioElement) {
    this.m_logger = logger.subLogger('MseHtmlAudioElement');
    this.m_htmlAudioElement = audioElement;

    this.m_isMuted = true;
    this.m_isPaused = false;
    this.m_isPlayable = false;
    this.m_playSpeed = PlaySpeed._1x;

    const mediaSource = new MediaSource();
    this.m_htmlAudioElement.src = window.URL.createObjectURL(mediaSource);
    this.m_mseMediaSource = new MseMediaSource(logger, mediaSource, MediaType.audio, new BufferQueue(logger));
  }

  public dispose() {
    this.m_logger.debug?.trace('dispose()');
    window.URL.revokeObjectURL(this.m_htmlAudioElement.src);
    this.m_mseMediaSource.dispose();
    this.m_htmlAudioElement.src = '';
  }

  public get initialization(): Promise<void> {
    return this.m_mseMediaSource.initialization;
  }

  public async reset() {
    this.m_logger.debug?.trace('reset()');
    await this.m_mseMediaSource.reset();
    // IE Complains when the same value is assigned (InvalidStateError)
    if (this.m_htmlAudioElement.currentTime !== 0) {
      this.m_htmlAudioElement.currentTime = 0;
    }
    this.m_logger.debug?.trace('reset() - completed');
  }

  public playLive() {
    this.m_logger.debug?.trace('playLive()');
    this.m_playSpeed = PlaySpeed._1x;
    this.m_isPaused = false;
    this.evaluatePlayability();
  }

  public play() {
    this.m_logger.debug?.trace('play()');
    this.m_isPaused = false;
    this.evaluatePlayability();
  }

  public pause() {
    this.m_logger.debug?.trace('pause()');
    this.m_isPaused = true;
    this.evaluatePlayability();
  }

  public mute() {
    this.m_logger.debug?.trace('mute()');
    this.m_isMuted = true;
    this.evaluatePlayability();
  }

  public unmute() {
    this.m_logger.debug?.trace('unmute()');
    this.m_isMuted = false;
    this.evaluatePlayability();
  }

  public setPlaySpeed(playSpeed: PlaySpeed): void {
    this.m_logger.debug?.trace('setPlaySpeed(', playSpeed.Value, ')');
    this.m_playSpeed = playSpeed;
    this.evaluatePlayability();
  }

  public decode(segmentInfo: SegmentInfo, buffer: Int8Array) {
    this.m_mseMediaSource.decode(segmentInfo, buffer);

    // Keep track of the current sequence info, in order to synchronize audio
    if (this.m_currentSequence !== undefined && this.m_currentSequence.isInSequence(segmentInfo)) {
      this.m_currentSequence.add(segmentInfo);
    } else if (!segmentInfo.IsInit) { // Init segment won't have the frame information needed to create a sequence
      // Starting a new sequence
      this.m_logger.intense?.trace('Starting a new sequence, segment ID:', segmentInfo.Id, 'FrameTime:', segmentInfo.FrameTime, 'MediaTime:', segmentInfo.MediaTime, 'Duration:', segmentInfo.TotalMilliseconds);
      this.m_currentSequence = SequenceInfo.from(segmentInfo);
      const currentTime = segmentInfo.MediaTime / 1000;
      // IE Complains when the same value is assigned (InvalidStateError)
      if (this.m_htmlAudioElement.currentTime !== currentTime) {
        this.m_htmlAudioElement.currentTime = currentTime;
      }
    }
  }

  public sync(frameTime: Date): void {
    if (this.m_currentSequence === undefined) {
      return;
    } else if (!this.m_isPlayable) {
      this.m_logger.intense?.trace('SYNC - No sync because audio is not playing');
      return; // We are not playing any audio
    } else if (frameTime < this.m_currentSequence.StartResolvedTime.frameTime) {
      this.m_logger.intense?.trace('SYNC - No sync because frameTime is before sequence start time');
      return; // No mapping available
    } else if (this.m_currentSequence.EndResolvedTime.frameTime < frameTime) {
      return; // We are late, but we haven't received the audio to play yet
    }

    const syncDetails = MseHtmlAudioElement.calculateSyncParameters(this.m_logger, this.m_currentSequence, frameTime, this.m_htmlAudioElement.currentTime * 1000, this.m_htmlAudioElement.playbackRate);
    if (syncDetails === null) {
      return;
    }
    if (syncDetails.SeekTime !== undefined) {
      const seekTime = syncDetails.SeekTime / 1000;
      // IE Complains when the same value is assigned (InvalidStateError)
      if (this.m_htmlAudioElement.currentTime !== seekTime) {
        this.m_htmlAudioElement.currentTime = seekTime;
      }
    }
    if (syncDetails.PlaySpeed !== undefined && syncDetails.PlaySpeed !== this.m_htmlAudioElement.playbackRate) {
      this.m_htmlAudioElement.playbackRate = syncDetails.PlaySpeed;
    }
    if (syncDetails.ShouldPlay) {
      this.m_htmlAudioElement.play();
    } else {
      this.m_htmlAudioElement.pause();
    }
  }

  public static calculateSyncParameters(logger: ILogger, sequenceInfo: SequenceInfo, frameTime: Date, currentMediaTime: number, currentPlaySpeed: number): SynchronizationParameters | null {
    const targetRange = frameTime.getTime() - sequenceInfo.StartResolvedTime.frameTime.getTime();
    const targetMediaTime = sequenceInfo.StartResolvedTime.mediaTime + targetRange;
    const desyncMs = Math.abs(currentMediaTime - targetMediaTime);
    logger.intense?.trace('SYNC - Current media time:', currentMediaTime, 'target:', targetMediaTime, 'diff:', desyncMs);

    if (desyncMs < this.AudioSyncToleranceMilliseconds) {
      return new SynchronizationParameters(true, 1, undefined);
    }
    if (desyncMs > this.AudioSyncMaxDifferenceMilliseconds) {
      if (currentMediaTime > targetMediaTime) {
        logger.warn?.trace('SYNC - Audio is', desyncMs, 'ms early, pausing audio...', '(Curr media time:', currentMediaTime, 'target:', targetMediaTime, ')');
        return new SynchronizationParameters(false, 1, undefined);
      } else {
        logger.warn?.trace('SYNC - Audio is', desyncMs, 'ms late, forcing seek...', '(Curr media time:', currentMediaTime, 'target:', targetMediaTime, ')');
        return new SynchronizationParameters(true, 1, targetMediaTime);
      }
    } else {
      // Try to tinker with the playspeed to smoothly resync the streams
      if (currentMediaTime < targetMediaTime && currentPlaySpeed !== 1.1) {
        logger.info?.trace('SYNC - Audio is', desyncMs, 'ms late', '(Curr media time:', currentMediaTime, 'target:', targetMediaTime, 'current playspeed:', currentPlaySpeed, ')');
        return new SynchronizationParameters(true, 1.1, undefined);
      } else if (currentMediaTime > targetMediaTime && currentPlaySpeed !== 0.9) {
        logger.info?.trace('SYNC - Audio is', desyncMs, 'ms early', '(Curr media time:', currentMediaTime, 'target:', targetMediaTime, ')');
        return new SynchronizationParameters(true, 0.9, undefined);
      } else {
        return null; // We're either paused due to a delay > 2000ms, or already at the modified speed. No modifications to do.
      }
    }
  }

  private evaluatePlayability(): void {
    const playable = this.m_playSpeed.Value === PlaySpeed._1x.Value && !this.m_isPaused && !this.m_isMuted;
    if (this.m_isPlayable !== playable) {
      this.m_logger.debug?.trace('Audio is now', playable ? 'playable' : 'unplayable');
      this.m_isPlayable = playable;
      this.m_htmlAudioElement.muted = !playable;
    }
  }

  public debugStatus(indent: number): string {
    return 'MseAudioElementWrapper' + Utils.indentNewLine(indent) +
      'Muted: ' + this.m_isMuted + Utils.indentNewLine(indent) +
      'Is Playable: ' + this.m_isPlayable + Utils.indentNewLine(indent) +
      'Is Paused: ' + this.m_isPaused + Utils.indentNewLine(indent) +
      'PlaySpeed: ' + this.m_playSpeed + Utils.indentNewLine(indent) +
      (this.m_currentSequence !== undefined ? this.m_currentSequence?.debugStatus(indent + Utils.Indentation) : 'No Sequence') + Utils.indentNewLine(indent) +
      this.m_mseMediaSource.debugStatus(indent + Utils.Indentation);
  }
}

class SynchronizationParameters {
  public readonly ShouldPlay: boolean;
  public readonly PlaySpeed?: number;
  public readonly SeekTime?: number;

  constructor(shouldPlay: boolean, playSpeed: number | undefined, seekTime: number | undefined) {
    this.ShouldPlay = shouldPlay;
    this.PlaySpeed = playSpeed;
    this.SeekTime = seekTime;
  }
}
