import { Filter } from '../../interfaces/plugins/public/plugin-services-public.interface';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export { Filter };

/**
 *  Dictionary where the key is the filterId of the filter
 */
export type FiltersMap = Map<string, Filter>;

/**
 * Represents the current Filter user state
 */
export interface FilterState {
    /**
     * Indicates the status of the filter
     */
    status: 'Ok' | 'Error' | 'Warning';
    /**
     * Label displayed as quick information on the filter selection status
     */
    selection: string;
    /**
     * Additional tooltip message to be displayed in the case of a warning or an error
     */
    message: string;
}

/**
 * Represents the possible context where a filter can be found in
 */
export enum FilterContext {
    None,
    Reports,
    IncidentList,
    Correlation,
    CardholderList,
}
