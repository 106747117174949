import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ButtonFlavor, TextFlavor, ButtonType } from '@genetec/gelato';
import { Gelato, GenModalService } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { CancellationToken } from 'RestClient/Helpers/Helpers';
import { AboutComponent } from '@modules/general/components/about/about.component';
import { ChangePasswordComponent } from '@modules/general/components/change-password/change-password.component';
import { LanguageService } from '@shared/services/language/language.service';
import { VersionService } from '@shared/services/version/version.service';
import { Constants } from '@src/constants';
import { AuthenticationError } from '@securityCenter/services/authentication/models/authentication-error';
import { ChangePasswordNotificationService } from '@modules/general/components/change-password/notification/change-password-notification.service';
import { SessionClient, OpenIdProvider } from '@shared/api/api';
import { WINDOW } from '@utilities/common-helper';
import { SendFeedbackComponent } from '@modules/general/components/send-feedback/send-feedback.component';
import { AuthService } from '../../security-center/services/authentication/auth.service';
import { UserLogin } from './user-login.model';
import { LogonResult } from 'RestClient/Client/Enumerations/LogonResult';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Gelato implements OnInit, OnDestroy {
    //#region Fields

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly TextFlavor = TextFlavor;
    public readonly ButtonType = ButtonType;

    public userLogin = new UserLogin();
    public isLoggingIn = false;
    public isWaitingForReload = false;
    public appVersion = '';
    public openIdProviders: OpenIdProvider[] = [];
    public hasCancelled = false;
    public waitOnOpenId = true;

    private cancellationToken?: CancellationToken;

    //#endregion

    constructor(
        @Inject(Constants.baseUrlIdentifier) private baseUrl: string,
        public authService: AuthService,
        private languageService: LanguageService,
        private modalService: GenModalService,
        private translateService: TranslateService,
        private versionService: VersionService,
        public changePasswordNotificationService: ChangePasswordNotificationService,
        private sessionClient: SessionClient,
        @Inject(WINDOW) private window: Window
    ) {
        super();
    }

    async ngOnInit() {
        // setting a blank title will display the base title ('Security Center Web')
        this.languageService.setTitle('');

        const versionInfo = await this.versionService.retrieveVersionAsync();
        if (versionInfo) {
            // display the build version at login
            this.appVersion = versionInfo.buildVersion;
        }

        // Verify if the role supports OpenId
        const encodedRedirectUri = encodeURIComponent(this.baseUrl + '?redirect=true');
        const info = await this.sessionClient.getLoginInfo(encodedRedirectUri).toPromise();
        if (info === null) throw new Error('');

        this.openIdProviders = info.openIdProviders ?? [];
        setTimeout(() => {
            this.waitOnOpenId = false;
        }, 750);
    }

    public onSignIn(): void {
        //If form empty, do not login
        if (!this.userLogin.username.value.trim()) {
            return;
        }
        if (this.cancellationToken) {
            // if we are already logging in, cancel it
            this.cancellationToken.cancelToken();
            this.cancellationToken = undefined;
            this.hasCancelled = true;
            this.authService.isSigningIn(false);
            // be sure to logout if we were already sign in as we priorize the cancel request
            this.authService.logOutAsync().fireAndForget();
        } else {
            this.isLoggingIn = true;
            this.userLogin.password.resetMessage();
            this.cancellationToken = new CancellationToken(AuthService.defaultTimeout);
            this.hasCancelled = false;
            this.authService.isSigningIn(false);
            this.authService
                .logInWithCredentialsAsync(this.userLogin, this.cancellationToken)
                .catch((error: AuthenticationError) => {
                    if (error.type === LogonResult.PasswordExpired) {
                        this.modalService.open(ChangePasswordComponent, {
                            isExpired: true,
                            oldPassword: this.userLogin.password.value,
                            directory: this.authService.userDirectory,
                            username: this.userLogin.username.value,
                            logonResultPasswordConstraint: this.authService.passwordConstraint,
                        });
                    } else if (error.type === LogonResult.AlreadyConnected) {
                        this.isWaitingForReload = true;
                        this.window.location.reload();
                    }
                })
                .then(() => this.changePasswordNotificationService.openPasswordNotificationModalIfNeeded())
                .finally(() => {
                    this.isLoggingIn = false;
                    this.cancellationToken = undefined;
                })
                .fireAndForget();
        }
    }

    public onAbout(): void {
        this.modalService.open(AboutComponent);
    }

    public onSendFeedback(): void {
        this.modalService.open(SendFeedbackComponent);
    }

    public ngOnDestroy(): void {
        this.authService.isSigningIn(false);
    }
}
