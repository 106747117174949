// Autogenerated: v2/help/Entities/ApiSequence?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { Entity } from '../Entity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { ITransaction } from '../../Interface/ITransaction';
import { ISequenceEntity, ISequenceMember } from '../../Interface/ISequenceEntity';

// #region Inner classes

export class SequenceMember extends FieldObject implements ISequenceMember {
    // Id
    public get id(): number {
        return this.getField<number>('Id');
    }
    public set id(value: number) {
        this.setField<number>('Id', value);
    }
    // CameraId
    public get cameraId(): IGuid {
        return this.getFieldGuid('CameraId');
    }
    public set cameraId(value: IGuid) {
        this.setFieldGuid('CameraId', value);
    }
    // Dwell
    public get dwell(): number | null {
        return this.getNullableField<number>('Dwell');
    }
    public set dwell(value: number | null) {
        this.setNullableField<number>('Dwell', value);
    }
    // Command
    public get command(): string | null {
        return this.getNullableField<string>('Command');
    }
    public set command(value: string | null) {
        this.setNullableField<string>('Command', value);
    }
    // Auxiliary
    public get auxiliary(): string | null {
        return this.getNullableField<string>('Auxiliary');
    }
    public set auxiliary(value: string | null) {
        this.setNullableField<string>('Auxiliary', value);
    }
    // CommandNumber
    public get commandNumber(): number | null {
        return this.getNullableField<number>('CommandNumber');
    }
    public set commandNumber(value: number | null) {
        this.setNullableField<number>('CommandNumber', value);
    }
    // AuxiliaryNumber
    public get auxiliaryNumber(): number | null {
        return this.getNullableField<number>('AuxiliaryNumber');
    }
    public set auxiliaryNumber(value: number | null) {
        this.setNullableField<number>('AuxiliaryNumber', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('Id', 0);
        this.setFieldGuid('CameraId', SafeGuid.EMPTY);
        this.setField<number>('Dwell', 0);
        this.setField<string>('Command', '');
        this.setField<string>('Auxiliary', '');
        this.setField<number>('CommandNumber', 0);
        this.setField<number>('AuxiliaryNumber', 0);
    }
}

// #endregion Inner classes

export class SequenceEntity extends Entity implements ISequenceEntity {
    // #region Constructor

    constructor() {
        super(EntityTypes.Sequences);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getSequenceMembersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ISequenceMember> | null> {
        return this.getFieldObjectRelationAsync<SequenceMember, ISequenceMember>(SequenceMember, 'Members', 'Id', false, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

