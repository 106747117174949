import { IGuid, SafeGuid } from 'safeguid';
import { CommandContext, CommandDisplay } from '../../../interfaces/plugins/public/plugin-services-public.interface';
import { SubscriptionCollection } from '../../../utilities/subscription-collection';

export abstract class CommandsUsageContext implements CommandContext {
    public abstract data: any;
    public abstract invalidateCanExecute: (commandId: IGuid, canExecute?: boolean) => void;
    public abstract invalidateDisplay: (commandId: IGuid, commandDisplay?: CommandDisplay) => void;
    public abstract subscriptions: SubscriptionCollection;
    public abstract type: IGuid;
}
