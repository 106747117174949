import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// ==========================================================================
// Copyright (C) 2023 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 * Wrapper service around native JS FileReader
 */
@Injectable({
    providedIn: 'root',
})
export class FileReaderService {
    public readAsDataURL(file: File): Observable<string> {
        const fileReader = new FileReader();
        return new Observable<string>((observer) => {
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                observer.next(fileReader.result as string);
                observer.complete();
            };
            fileReader.onerror = () => {
                observer.error(fileReader.error);
            };
        });
    }
}
