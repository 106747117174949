import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../../utilities/constants';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const ContextTypes = Constants({
    Content: SafeGuid.parse('B94A419C-7F68-4487-9C91-547706DC3F93'),
    Entity: SafeGuid.parse('88E3422A-C9F5-4F55-976B-297B30698900'),
    EntityId: SafeGuid.parse('BA83C494-BD9C-4053-921B-5B3F48E04AFF'),
    Map: SafeGuid.parse('FDA8AD7D-BEE4-4204-81BA-1528BF29E2EF'),
    Specific: SafeGuid.parse('27E6ADB0-2BCC-49F2-BC58-2C11A3E6AE84'),
});
