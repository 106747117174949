import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, ItemSlot } from '@genetec/gelato';
import { GenMenu } from '@genetec/gelato-angular';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { IGuid } from 'safeguid';
import { MenuPosition } from '@genetec/gelato';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CommandUsage } from '../../services/commands/commands-usage/command-usage';
import { CommandsUsage } from '../../services/commands/commands-usage/commands-usage';
import { TrackingService } from '../../services/tracking.service';
import { TrackedComponent } from '../tracked/tracked.component';

@UntilDestroy()
@Component({
    selector: 'app-command-buttons',
    templateUrl: './command-buttons.component.html',
    styleUrls: ['./command-buttons.component.scss'],
})
export class CommandButtonsComponent extends TrackedComponent implements OnInit, OnChanges {
    @ViewChild('mainDiv') public mainDiv!: ElementRef;
    @ViewChild('moreMenu') public moreMenu!: GenMenu;

    @Input() public commandsUsage!: CommandsUsage;
    @Input() public excludedCommandIds: IGuid[] = [];
    @Input() public maximumButtonCount!: number;
    @Input() public areButtonsSpaced = false;
    @Input() public mainButtonFlavor = ButtonFlavor.Flat;

    @Output() public isLoadingChange = new EventEmitter<boolean>();

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly ItemSlot = ItemSlot;
    public readonly MenuPosition = MenuPosition;

    public additionnalCommands: CommandUsage[] = [];
    public displayedCommands: CommandUsage[] = [];
    public isLoading = true;
    public isMoreMenuOpen = false;
    public moreMenuItemSource: ContextMenuItem[] = [];

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.commandsUsage !== 'undefined') {
            const change = changes.commandsUsage;
            const commandsUsage = change.currentValue as CommandsUsage;
            if (commandsUsage && change.previousValue !== commandsUsage) {
                this.createCommandButtons();
                this.isLoading = !commandsUsage.isReady$.getValue();
                if (this.isLoading) {
                    commandsUsage.isReady$.pipe(untilDestroyed(this)).subscribe((isReady: boolean) => {
                        this.isLoading = !isReady;
                        this.isLoadingChange.emit(this.isLoading);
                    });
                } else {
                    this.isLoadingChange.emit(this.isLoading);
                }
            }
        }
    }

    public onClicked(event: Event): void {
        event.cancelBubble = true;
        event.preventDefault();
    }

    public getName(command: CommandUsage): string | undefined {
        // if there is no icon, use the text
        if (!command.icon) {
            return command.name();
        }
    }

    public getTooltip(command: CommandUsage): string {
        let tooltip = command.tooltip();
        if (!tooltip) {
            // if there is no tooltip and that we only have an icon, use the name as tooltip
            if (command.icon) {
                tooltip = command.name();
            }
        }
        return tooltip;
    }

    public toggleMoreMenu(): void {
        this.createAdditionnalCommands();
        this.moreMenu.toggle().fireAndForget();
        this.isMoreMenuOpen = !this.isMoreMenuOpen;
    }

    private createAdditionnalCommands() {
        this.moreMenuItemSource = [];
        if (this.additionnalCommands.length > 0) {
            for (const command of this.additionnalCommands) {
                this.moreMenuItemSource.push(command.createMenuItem());
            }
        }
    }

    private createCommandButtons() {
        let index = 0;
        this.displayedCommands = [];
        this.additionnalCommands = [];
        this.moreMenuItemSource = [];
        const commands = this.commandsUsage.commands.filter((commandUsage) => !this.excludedCommandIds.some((excludedCommandId) => excludedCommandId.equals(commandUsage.id)));

        if (commands.length > 0) {
            for (; index < commands.length && index < this.maximumButtonCount; index++) {
                this.displayedCommands.push(commands[index]);
            }

            if (commands.length > this.displayedCommands.length) {
                for (; index < commands.length; index++) {
                    this.additionnalCommands.push(commands[index]);
                }
            }
        }
    }
}
