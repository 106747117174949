import { Component, OnInit } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { SafeGuid } from 'safeguid';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
const isContentSupported = (content: Content): boolean => {
    if (content && !content.contextContent) {
        return (
            content.type.equals(AccessControlContentTypes.Door) ||
            content.type.equals(AccessControlContentTypes.Cardholder) ||
            content.type.equals(AccessControlContentTypes.Credential)
        );
    }
    return false;
};

@Component({
    selector: 'app-door-events-widget',
    templateUrl: './door-events-widget.component.html',
    styleUrls: ['./door-events-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: DoorEventsWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: DoorEventsWidgetComponent.pluginId, priority: 10 },
    requirements: { globalPrivileges: [KnownPrivileges.unitActivityReportPrivilege] },
    isContentSupported,
})
export class DoorEventsWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('9698F4F7-7F83-444F-958E-E1ADF01364CF');
    public content?: Content;
    public dataContext!: ContentService;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
