import { IGuid, SafeGuid } from 'safeguid';
import { Flavor, GenToastService, ToastFlavor } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MCCommandBase } from '../models/commands/command';

@Injectable()
export class McNotificationService {
    constructor(private toastNotificationService: GenToastService, private translateService: TranslateService) {}

    public notifyFromCommandResponse(command: MCCommandBase, statusCode: number): void {
        if (statusCode === 202) {
            const message = command.successMessage(statusCode);
            this.notifyWarning(message);
        } else if (statusCode >= 200 && statusCode <= 299) {
            const message = command.successMessage(statusCode);
            this.notifySuccess(message);
        } else {
            const message = command.errorMessage(statusCode);
            this.notifyError(message);
        }
    }

    public notifySuccess(primaryTextKey: string, secondaryTextKey?: string): void {
        this.toastNotify(primaryTextKey, secondaryTextKey, Flavor.Toast.Success);
    }

    public notifyWarning(primaryTextKey: string, secondaryTextKey?: string): void {
        this.toastNotify(primaryTextKey, secondaryTextKey, Flavor.Toast.Warning);
    }

    public notifyError(primaryTextKey: string, secondaryTextKey?: string): void {
        this.toastNotify(primaryTextKey, secondaryTextKey, Flavor.Toast.Error);
    }

    private toastNotify(primaryTextKey: string, secondaryTextKey?: string, flavor: ToastFlavor = ToastFlavor.Information) {
        if (primaryTextKey === '') return;
        this.toastNotificationService.show({
            id: SafeGuid.newGuid().toString(),
            text: this.translateService?.instant(primaryTextKey) as string,
            secondaryText: secondaryTextKey ? (this.translateService?.instant(secondaryTextKey) as string) : secondaryTextKey,
            flavor,
        });
    }
}
