import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'limitTo',
})
@Injectable()
export class LimitToPipe implements PipeTransform {
    public readonly DEFAULT_TRAIL = '+';

    public transform(value: number, limit: number, trail: string = this.DEFAULT_TRAIL): string {
        return value > limit ? `${limit}${trail}` : `${value}`;
    }
}
