<gen-melted-modal
    [id]="id"
    genTitle="{{ 'STE_BUTTON_OVERRIDE_UNLOCK_SCHEDULES' | translate }}"
    [genIsBlocking]="false"
    genAlternativeActionText="{{ 'STE_BUTTON_CLOSE' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_SAVE' | translate }}"
    [genIsDefaultActionDisabled]="isSaveDisabled()"
>
    <gen-melted-modal-body>
        <gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        <ul *ngIf="!isLoading" class="p-2">
            <li>
                <gen-radio
                    name="Maintenance"
                    [value]="OverrideScheduleOption.Maintenance.toString()"
                    [ngModel]="option.toString()"
                    (ngModelChange)="onOptionChange($event)"
                    [disabled]="!canUnlockForMaintenance"
                >
                    <gen-label>{{ 'STE_LABEL_DOORUNLOCKEDFORMAINTENANCE' | translate }}</gen-label>
                </gen-radio>
            </li>
            <li>
                <gen-radio
                    name="TemporaryOverride"
                    [value]="OverrideScheduleOption.TemporaryOverride.toString()"
                    [ngModel]="option.toString()"
                    (ngModelChange)="onOptionChange($event)"
                >
                    <gen-label>{{ 'STE_LABEL_TEMPORARYOVERRIDESCHEDULE' | translate }}</gen-label>
                </gen-radio>
                <ul *ngIf="option === OverrideScheduleOption.TemporaryOverride" class="p-2">
                    <li>
                        <div class="lock-filter-container">
                            <gen-icon [icon]="Icon.Unlock"></gen-icon>
                            <gen-switch [ngModel]="isOverrideLocking" (ngModelChange)="onLockOverrideChange()"></gen-switch>
                            <gen-icon [icon]="Icon.Lock" class="right-lock-icon"></gen-icon>
                        </div>
                    </li>
                    <li>
                        <gen-radio
                            name="ForNextX"
                            [value]="TemporaryOverrideScheduleOption.ForNextX.toString()"
                            [ngModel]="temporaryOverrideOption.toString()"
                            (ngModelChange)="onTemporaryOverrideOptionChange($event)"
                        >
                            <gen-label>{{ 'STE_LABEL_FORTHENEXT_X_YEARS_MONTHS_WEEKS_HOURS_MINUTES' | translate }}</gen-label>
                        </gen-radio>
                        <div class="duration-filter-container">
                            <gen-numeric-input
                                [ngModel]="duration"
                                (ngModelChange)="onDurationChange($event)"
                                [disabled]="temporaryOverrideOption !== TemporaryOverrideScheduleOption.ForNextX"
                                [min]="1"
                                [max]="maxDuration"
                                [step]="1"
                            ></gen-numeric-input>
                            <gen-combobox
                                [ngModel]="period"
                                (ngModelChange)="onPeriodChange($event)"
                                [genItemsSource]="periodItems"
                                [disabled]="temporaryOverrideOption !== TemporaryOverrideScheduleOption.ForNextX"
                            ></gen-combobox>
                        </div>
                    </li>
                    <li>
                        <gen-radio
                            name="Range"
                            [value]="TemporaryOverrideScheduleOption.Range.toString()"
                            [ngModel]="temporaryOverrideOption.toString()"
                            (ngModelChange)="onTemporaryOverrideOptionChange($event)"
                        >
                            <gen-label>{{ 'STE_LABEL_SPECIFICDATE' | translate }}</gen-label>
                        </gen-radio>
                        <div class="range-filter-container">
                            <gen-datetime-input
                                [ngModel]="rangeTimeStart"
                                (ngModelChange)="onRangeTimeStartChange($event)"
                                [genLabel]="'STE_LABEL_TIMERANGE_FROM' | translate"
                                [genFlavor]="Flavor.Calendar.DateTime"
                                [disabled]="temporaryOverrideOption !== TemporaryOverrideScheduleOption.Range"
                            ></gen-datetime-input>
                            <gen-datetime-input
                                [ngModel]="rangeTimeEnd"
                                (ngModelChange)="onRangeTimeEndChange($event)"
                                [genLabel]="'STE_LABEL_TIMERANGE_TO' | translate"
                                [genFlavor]="Flavor.Calendar.DateTime"
                                [disabled]="temporaryOverrideOption !== TemporaryOverrideScheduleOption.Range"
                            ></gen-datetime-input>
                            <small class="gen-message error">{{ rangeTimeError }}</small>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
        <gen-item *ngIf="!isOnline">
            <gen-icon [icon]="MeltedIcon.Warning" [genColor]="Color.Mango"></gen-icon>
            <gen-text class="gen-item-description" [flavor]="TextFlavor.Warning">{{ 'STE_LABEL_WARNING_OFFLINE_DOOR_SCHEDULE_OVERRIDE' | translate }}</gen-text>
        </gen-item>
    </gen-melted-modal-body>
</gen-melted-modal>

<gen-alert
    id="overrideScheduleAlert"
    genText="{{ 'STE_MESSAGE_WARNING_APPLYOVERRIDESCHEDULE' | translate }}"
    genSecondaryText="{{ 'STE_MESSAGE_WARNING_CAUSETEMPORARYDISRUPTIONS' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_CONTINUE' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnDefaultAction]="onOverrideScheduleConfirm"
    [genFlavor]="Flavor.Alert.Warning"
    [genIsDefaultActionDisabled]="false"
>
</gen-alert>
