/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language/language.service';

/*
  Injects parameters into a format string like 'Bla bla {0} {1}'
  Ex : <div>{{'Hello {name}' | stringFormat:'George'}}<div/>
*/
@Pipe({ name: 'stringFormat' })
export class StringFormatPipe implements PipeTransform {
    // Using the browser's culture to format numbers.
    private readonly locale = navigator.language ?? LanguageService.DefaultLanguage;

    public transform(format: string, ...args: any[]): string {
        if (!args?.length) {
            return format;
        }

        return format.replace(/{(\d+)}/g, (match: string, index: number) => {
            let argString = args[index].toString() as string;

            if (typeof args[index] === 'number') {
                argString = (args[index] as number).toLocaleString(this.locale);
            }

            return args[index] !== undefined ? argString : match;
        });
    }
}
