<div class="when-container">
    <!-- Filter list of options -->
    <gen-list class="pr-2 when-list" [selectionType]="ListSelectionType.Single">
        <gen-list-item (selectedChange)="onFilterChange(dateFilterMode.DuringLastPeriod)" [selected]="mode === dateFilterMode.DuringLastPeriod">
            <div>
                <gen-text>{{ 'STE_LABEL_DURINGTHELAST_X_YEARS_MONTHS_WEEKS_HOURS_MINUTES' | translate }}</gen-text>
                <i class="gen-ico-angle-right right-caret"></i>
            </div>
        </gen-list-item>
        <gen-list-item (selectedChange)="onFilterChange(dateFilterMode.Range)" [selected]="mode === dateFilterMode.Range">
            <div>
                <gen-text>{{ 'STE_LABEL_SPECIFICDATE' | translate }}</gen-text>
                <i class="gen-ico-angle-right right-caret"></i>
            </div>
        </gen-list-item>
        <gen-list-item (selectedChange)="onFilterChange(dateFilterMode.Any)" [selected]="mode === dateFilterMode.Any">
            <gen-text>{{ 'STE_LABEL_ANYTIME' | translate }}</gen-text>
        </gen-list-item>
    </gen-list>
    <div class="date-time-mode-container">
        <!-- During the last mode layout -->
        <div [hidden]="mode !== dateFilterMode.DuringLastPeriod" class="display-inherit">
            <gen-numeric-input [(ngModel)]="lastPeriodDuration" (ngModelChange)="onDurationValueChange($event)" [step]="1" [min]="MinDurationValue" [max]="MaxDurationValue"> </gen-numeric-input>
            <gen-combobox
                class="pl-2"
                [ngModel]="selectedDurationUnit"
                [genItemsSource]="durationUnitsComboboxItems"
                [genFlavor]="Flavor.Combobox.Solid"
                (ngModelChange)="onDurationUnitComboboxChange($event)"
            ></gen-combobox>
        </div>
        <!-- Specific period mode layout -->
        <div [hidden]="mode !== dateFilterMode.Range" class="display-inherit">
            <div #fromDateTime>
                <gen-datetime-input
                    [ngModel]="timeStart"
                    (ngModelChange)="onTimeStartChange($event)"
                    class="mr-2 pb-1 datetime-width"
                    [genFlavor]="CalendarFlavor.Date"
                    genLabel="{{ 'STE_LABEL_TIMERANGE_FROM' | translate }}"
                    [genFormat]="DateFormat.Date"
                    [genTimeFrame]="CalendarTimeFrame.Past"
                    placeholder="{{ 'STE_LABEL_DATE_FORMAT_PLACEHOLDER' | translate }}"
                >
                </gen-datetime-input>
                <gen-datetime-input
                    class="mr-2 datetime-width"
                    [ngModel]="timeStart"
                    (ngModelChange)="onTimeStartChange($event)"
                    [genFlavor]="CalendarFlavor.Time"
                    [genFormat]="DateFormat.Time"
                    placeholder="{{ 'STE_LABEL_TIME_FORMAT_PLACEHOLDER' | translate }}"
                ></gen-datetime-input>
            </div>
            <div #toDateTime>
                <gen-datetime-input
                    class="datetime-width pb-1"
                    [ngModel]="timeEnd"
                    (ngModelChange)="onTimeEndChange($event)"
                    [genFlavor]="CalendarFlavor.Date"
                    genLabel="{{ 'STE_LABEL_TIMERANGE_TO' | translate }}"
                    [genFormat]="DateFormat.Date"
                    [genTimeFrame]="CalendarTimeFrame.Past"
                    placeholder="{{ 'STE_LABEL_DATE_FORMAT_PLACEHOLDER' | translate }}"
                ></gen-datetime-input>
                <gen-datetime-input
                    class="datetime-width"
                    [ngModel]="timeEnd"
                    (ngModelChange)="onTimeEndChange($event)"
                    [genFlavor]="CalendarFlavor.Time"
                    [genFormat]="DateFormat.Time"
                    placeholder="{{ 'STE_LABEL_TIME_FORMAT_PLACEHOLDER' | translate }}"
                >
                </gen-datetime-input>
            </div>
        </div>
        <div class="mt-1">
            <gen-text [flavor]="TextFlavor.Error" *ngIf="specificDateTimePeriodValidationState !== DateTimePeriodValidationState.Valid">
                {{ specificDateTimePeriodValidationState | translate }}
            </gen-text>
        </div>
    </div>
</div>
