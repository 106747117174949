// Autogenerated: v2/help/Entities/ApiAccessManagerRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class AccessManagerRoleEntityFields extends RoleEntityFields {
    public static activateGlobalAntiPassbackField = 'ActivateGlobalAntiPassback';
    public static activatePeerToPeerField = 'ActivatePeerToPeer';
    public static keepHistoryField = 'KeepHistory';
    public static logRetentionDaysField = 'LogRetentionDays';
}

// #endregion Fields

// #region Inner classes

export interface IAccessControlExtension extends IFieldObject {
    extensionType: IGuid;
}

// #endregion Inner classes

export interface IAccessManagerRoleEntity extends IRoleEntity {
    // #region REST Properties

    activateGlobalAntiPassback: boolean;

    activatePeerToPeer: boolean;

    keepHistory: boolean;

    logRetentionDays: number;

    // #endregion REST Properties

    // #region REST Methods

    getExtensionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessControlExtension> | null>;

    // #endregion REST Methods

}

