import { Component, OnInit } from '@angular/core';
import { FilterClient } from '@shared/api/api';
import { FilterCoordinatorService } from '@shared/services/filters/filter-coordinator-service';
import { TrackingService } from '@shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterBaseComponent } from './filter-base.component';

@Component({
    template: '',
})
export abstract class FilterTextBaseComponent extends FilterBaseComponent<string> implements OnInit {
    public actualText = '';

    constructor(
        trackingService: TrackingService,
        protected filterClient: FilterClient,
        protected filterCoordinatorService: FilterCoordinatorService,
        protected translateService: TranslateService
    ) {
        super(trackingService, filterClient, filterCoordinatorService, translateService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.value = '';
    }

    public onFilterChanged(text: string): void {
        this.actualText = text;
        this.value = text.trim();
    }

    public onFilterToggled(isFilterOpened: boolean): void {
        super.onFilterToggled(isFilterOpened);
        if (!isFilterOpened) {
            this.actualText = this.actualText.trim();
        }
    }

    public updateState(): void {
        let selection = this.state.selection;

        if (this.actualText.trim() === '') {
            selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
        } else {
            selection = this.value;
        }

        this.state = { ...this.state, selection };
    }

    public clearFilter(): void {
        this.onFilterChanged('');
    }

    public isDefaulted(): boolean {
        return this.value === '';
    }

    public override isDirty(firstValue: string, secondValue: string): boolean {
        return firstValue.trim() !== secondValue.trim();
    }

    public override getDefaultValue(): string {
        return '';
    }
}
