// Autogenerated: v2/help/Entities/ApiMediaGatewayRole?codegen=ts&InterfaceOnly=False&workflow=False on 2020-09-21 3:54:11 PM
import { RoleEntity } from './RoleEntity';
import { IMediaGatewayRoleEntity, MediaGatewayRoleEntityFields } from '../../Interface/IMediaGatewayRoleEntity';

export class MediaGatewayRoleEntity extends RoleEntity implements IMediaGatewayRoleEntity {
    // #region REST Properties

    // HttpPort
    public get httpPort(): number {
        return this.getField<number>(MediaGatewayRoleEntityFields.httpPortField);
    }
    public set httpPort(value: number) {
        this.setField<number>(MediaGatewayRoleEntityFields.httpPortField, value);
    }

    // HttpsPort
    public get httpsPort(): number {
        return this.getField<number>(MediaGatewayRoleEntityFields.httpsPortField);
    }
    public set httpsPort(value: number) {
        this.setField<number>(MediaGatewayRoleEntityFields.httpsPortField, value);
    }

    // ListenPort
    public get listenPort(): number {
        return this.getField<number>(MediaGatewayRoleEntityFields.listenPortField);
    }
    public set listenPort(value: number) {
        this.setField<number>(MediaGatewayRoleEntityFields.listenPortField, value);
    }

    // RtspEnabled
    public get rtspEnabled(): boolean {
        return this.getField<boolean>(MediaGatewayRoleEntityFields.rtspEnabledField);
    }
    public set rtspEnabled(value: boolean) {
        this.setField<boolean>(MediaGatewayRoleEntityFields.rtspEnabledField, value);
    }

    // SecureServer
    public get secureServer(): boolean {
        return this.getField<boolean>(MediaGatewayRoleEntityFields.secureServerField);
    }
    public set secureServer(value: boolean) {
        this.setField<boolean>(MediaGatewayRoleEntityFields.secureServerField, value);
    }

    // UseDefaultPorts
    public get useDefaultPorts(): boolean {
        return this.getField<boolean>(MediaGatewayRoleEntityFields.useDefaultPortsField);
    }
    public set useDefaultPorts(value: boolean) {
        this.setField<boolean>(MediaGatewayRoleEntityFields.useDefaultPortsField, value);
    }

    // UseRtsps
    public get useRtsps(): boolean {
        return this.getField<boolean>(MediaGatewayRoleEntityFields.useRtspsField);
    }
    public set useRtsps(value: boolean) {
        this.setField<boolean>(MediaGatewayRoleEntityFields.useRtspsField, value);
    }

    // WebApplicationPath
    public get webApplicationPath(): string {
        return this.getField<string>(MediaGatewayRoleEntityFields.webApplicationPathField);
    }
    public set webApplicationPath(value: string) {
        this.setField<string>(MediaGatewayRoleEntityFields.webApplicationPathField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

