import { ListItem } from '@modules/shared/interfaces/list-item';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { FiltersService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { FilterCoordinatorService } from '@modules/shared/services/filters/filter-coordinator-service';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { Item } from '@modules/shared/interfaces/item';
import { FilterDescriptor } from '@modules/general/components/filter-plugins/interfaces/filter-descriptor';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent extends TrackedComponent implements OnInit, OnDestroy {
    @Input() public filtersService?: FiltersService;

    @Input() public set filterDescriptors(value: FilterDescriptor[]) {
        if (value !== this.filterDescriptorsValue) {
            this.filterDescriptorsValue = value;
            this.onDescriptorChanged().fireAndForget();
        }
    }

    public get filterDescriptors(): FilterDescriptor[] {
        return this.filterDescriptorsValue;
    }

    public allFilterPlugins: PluginItem[] = [];
    public actualFilterPlugins: PluginItem[] = [];
    public hideableFilters: Item[] = [];

    private alwaysVisibleFilterPlugins: Map<string, PluginItem> = new Map<string, PluginItem>();
    private hideableFilterPlugins: Map<string, PluginItem> = new Map<string, PluginItem>();
    private filterDescriptorsValue!: FilterDescriptor[];
    private filtersLoaded = false;

    constructor(private pluginService: PluginService, trackingService: TrackingService, private filterCoordinatorService: FilterCoordinatorService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        this.filtersService?.reset();
    }

    public onHideableFiltersSelectionChanged(selectedItems: string[]): void {
        const displayedFilters: PluginItem[] = [];
        selectedItems.forEach((uniqueId) => {
            const plugin = this.hideableFilterPlugins.get(uniqueId);
            if (plugin) displayedFilters.push(plugin);
        });

        this.actualFilterPlugins = [...Array.from(this.alwaysVisibleFilterPlugins.values())];
        this.actualFilterPlugins.push(...displayedFilters);
    }

    public clearFilters(): void {
        this.filterCoordinatorService.resetAllFiltersToDefault();
    }

    public areFiltersCleared(): boolean {
        return this.filterCoordinatorService.allDefaulted;
    }

    private async loadFilters() {
        this.actualFilterPlugins = [];
        this.alwaysVisibleFilterPlugins = new Map<string, PluginItem>();
        this.hideableFilterPlugins = new Map<string, PluginItem>();

        // load filters only once
        if (!this.filtersLoaded) {
            this.allFilterPlugins = await this.pluginService.getPlugins(PluginTypes.Filter, null);
            this.filtersLoaded = true;
        }

        this.filterDescriptorsValue?.forEach((descriptor, index) => {
            const plugin = Object.assign(
                {},
                this.allFilterPlugins.find((x) => x.exposure.subSection?.equals(descriptor.type))
            );
            plugin.data = descriptor;

            if (plugin) {
                const uniqueId = SafeGuid.newGuid();

                if (descriptor.hideable) {
                    this.hideableFilterPlugins.set(uniqueId.toString(), plugin);
                    this.hideableFilters.push({ id: uniqueId.toString(), text: descriptor.label, isChecked: false } as ListItem);
                } else {
                    this.alwaysVisibleFilterPlugins.set(uniqueId.toString(), plugin);
                }
            }
        });
        this.actualFilterPlugins = Array.from(this.alwaysVisibleFilterPlugins.values());
    }

    private async onDescriptorChanged() {
        await this.loadFilters();
    }
}
