import { EMPTY, from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { GuidSet, IGuid, SafeGuid } from 'safeguid';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { bufferTime, catchError, map, filter, tap, mergeMap, switchMap } from 'rxjs/operators';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { WebAppClient } from 'WebClient/WebAppClient';
import { LoggerService } from '../../shared/services/logger/logger.service';

class EntityQuery<U> {
    public entityId: IGuid;
    public observable$: Subject<U>;

    constructor(entityId: IGuid, query: Subject<U>) {
        this.entityId = entityId;
        this.observable$ = query;
    }
}

export class BatchEntitiesObservable {
    private _classType: new () => Entity = Entity;
    private _startFetching$ = new Subject<EntityQuery<IEntity>>();
    private _subscription$!: Subscription;
    private _entitiesObsMap = SafeGuid.createMap<Subject<IEntity | null>>();
    private _entityCache: IEntityCacheTask;

    constructor(scClient: WebAppClient, private _logger: LoggerService, timeToBuffer: number = 100, fields?: string[]) {
        this._entityCache = fields ? scClient.buildEntityCache(fields) : scClient.buildEntityCache();

        const accumulatedIds$ = this._startFetching$.pipe(
            bufferTime(timeToBuffer),
            filter((buffer) => buffer.length > 0)
        );

        this._subscription$ = accumulatedIds$
            .pipe(
                mergeMap((accumulatedIds) => this.fetching(new GuidSet(accumulatedIds.map((x) => x.entityId)), accumulatedIds)),
                tap(({ guidMap, queries }) => {
                    for (const query of queries) {
                        if (!query.entityId || !query.observable$) return throwError('Invalid query');

                        const entity = guidMap.get(query.entityId) ?? null;
                        if (entity) {
                            query.observable$.next(entity);
                            query.observable$.complete();
                        } else {
                            query.observable$.error('Entity was not found');
                        }
                    }
                }),
                catchError((e) => {
                    this._logger.traceError(`Unable to process ids from the cache ${e}`);
                    return EMPTY;
                })
            )
            .subscribe();
    }

    public registerEntity(id: IGuid | string): Observable<IEntity> {
        const entityId = typeof id === 'string' ? SafeGuid.parse(id) : id;
        const entity$ = new Subject<IEntity>();
        const query = new EntityQuery<IEntity>(entityId, entity$);
        this._startFetching$.next(query);
        return entity$.pipe(
            switchMap((x) => (x?.name ? of(x) : EMPTY)),
            catchError((_) => EMPTY)
        );
    }

    public async unsubscribe(): Promise<void> {
        this._subscription$.unsubscribe();
        await this._entityCache.dispose();
    }

    private fetching(accumulatedIds: Set<IGuid>, queries: EntityQuery<IEntity>[]): Observable<{ guidMap: Map<IGuid, IEntity>; queries: EntityQuery<IEntity>[] }> {
        return from(this._entityCache.getEntitiesAsync<Entity, IEntity>(this._classType, accumulatedIds, false)).pipe(
            map((entities) => {
                const guidMap = SafeGuid.createMap<IEntity>();
                entities.forEach((entity) => guidMap.set(entity.id, entity));
                return { guidMap, queries };
            }),
            catchError((err) => {
                this._logger.traceError(`BatchEntitiesObservable was unable to fetch entities ${[...accumulatedIds].join(', ')}. ${err}`);
                for (const id of accumulatedIds) {
                    const entity$ = this._entitiesObsMap.get(id);
                    if (entity$) {
                        entity$.complete();
                        this._entitiesObsMap.delete(id);
                    }
                }
                return EMPTY;
            })
        );
    }
}
