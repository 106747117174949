<main class="gen-main gen-app p-0">
    <section class="gen-header p-1">
        <div class="toolbar">
            <gen-button [flavor]="ButtonFlavor.Flat" (click)="toggleEntityBrowser()" [ngClass]="{ active: isEntityBrowserPinned }"
                ><gen-icon [icon]="Icon.Area"></gen-icon>
                {{ 'STE_BUTTON_BROWSE' | translate }}
            </gen-button>
            <gen-button
                id="tiles-pattern-popup-toggle-{{ componentId }}"
                *ngIf="allowChangeTilePattern"
                [flavor]="ButtonFlavor.Flat"
                [appTooltip]="'STE_TOOLTIP_BUTTON_CHANGETILEPATTERN' | translate"
                (click)="togglePatternSelectionPopup()"
            >
                <gen-icon [icon]="Icon.TilePattern"></gen-icon>
            </gen-button>
        </div>
    </section>
    <section [appResizeElement]="true" [appResizableSides]="['right']" [hidden]="!isEntityBrowserPinned" class="gen-sidepane-left bg-color pt-1 pb-1 entity-browser-container">
        <app-entity-browser
            #entityBrowser
            class="h-100"
            [autoRefresh]="false"
            dragAndDropEnabled
            [canSelectItemFunction]="canSelectItem.bind(this)"
            (entityDoubleClicked)="onBrowserEntitySelected($event)"
        ></app-entity-browser>
    </section>
    <section class="gen-main p-relative o-hidden" [ngClass]="{ tilesWithBrowser: isEntityBrowserPinned, tiles: !isEntityBrowserPinned }">
        <app-tile-canvas #tileCanvas> </app-tile-canvas>
    </section>
</main>

<!-- Tile pattern selection -->
<gen-popup
    [position]="PopupPosition.BottomRight"
    targetSelector="#tiles-pattern-popup-toggle-{{ componentId }}"
    appFocusPopupWhenOpen
    genKeyboardNavigation
    (genEscape)="togglePatternSelectionPopup()"
>
    <!-- TODO: Remove the separators when this is implemented by UX: https://dev.azure.com/geneteccentral/UXDev/_workitems/edit/31573 -->
    <gen-list [selectionType]="ListSelectionType.Single">
        <gen-list-item *ngFor="let tilePattern of tilePatterns; index as i" (selectedChange)="onSelectLayoutChange(i)" data-cy="tilesSelectionList">
            <gen-item>
                <gen-icon [icon]="tilePattern.icon"></gen-icon>
                <gen-text>{{ tilePattern.name }}</gen-text>
            </gen-item>
        </gen-list-item>
    </gen-list>
</gen-popup>
