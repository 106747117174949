import { Component } from '@angular/core';
import { InvestigateSideContextDataService } from '../services/investigate-side-context-data.service';

@Component({
    selector: 'app-investigate-side-pane',
    templateUrl: 'investigate-side-pane.component.html',
})
export class InvestigateSidePaneComponent {
    constructor(public contextDataService: InvestigateSideContextDataService) {}
}
