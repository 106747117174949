import { ILogger } from '../utils/logger';
import { DewarperProgram } from './DewarperProgram';
import { DewarperSourceImageParameters, InternalDewarperSourceImageParameters } from './DewarperSourceImageParameters';
import { FrameRateComputer, FrameRate } from '../utils/FrameRateComputer';
import { ControllerAggregate, DewarperControl, IDewarperControl, IInternalDewarperControl } from './DewarperInterfaces';
import { DewarpedArea } from './DewarpedArea/DewarpedArea';
import { IVideoFrame, IVideoFrameSource, ThrottledVideoSource } from '../players/VideoFrameSource';
import { DewarperPreview } from './Preview/DewarperPreview';
import { IPreviewCanvas, PreviewCanvas } from './Preview/PreviewCanvas';
import { DewarperCanvas, IDewarperCanvas } from './DewarperCanvas';
import { Resolution } from '../utils/Resolution';
import { Utils } from '../utils/Utils';
import { NormalizedColor } from '../utils/Color';
import { DewarpedAreaCanvas, IDewarpedAreaCanvas } from './DewarpedArea/DewarpedAreaCanvas';
import { Cancellation } from '../utils/CancellationToken';
import { DragInfo, DragObserver } from '../utils/DragObserver';
import { DewarpingParametersBuilder } from './DewarpingParameters';
import { MouseWheelObserver } from '../utils/MouseWheelObserver';
import { VideoWatermarkConfig } from '../utils/VideoWatermarkingConfig';
import { IDewarperVwoOverlay } from './DewarperVwoOverlay';

/**
 * @beta
 * */
export class Dewarper {
  /*
   * todo:
   * draging accross center, fix the rotation and when mouse is released, then, do the rotation
   * dewarp where the mouse is instead of staying centered
   * unit tests
   * ctrl+alt+a -> frame rate, dewarper settings, coordinates
   * boolean on interface to disable user interaction
   * lower gpu usage mode? mode for pixel extrapolation gl_linear or something like that on texture I think?
   *    search for other place we could save gpu? disable dewarped area shader? automatically lower input frame rate?
   * snapshot
   * use DewarpingRoi
   * expose max frame rate in interface?
   *
   * Test:
   * cpu usage (idle/moving) and memory consumption
   * Change of stream resolution
   * Change of tile resolution
   * Change of preview resolution
   * pan tilt operations
   *    should block when limit is reached (tilt)
   * Canvas interaction
   *    mouse wheel to zoom on both tile and preview (beyond 20x lower than 1x)
   *    dragging on both tile and preview (validate precision and crossing center especially on tile)
   *    single click on preview (validate precision)
   * Operations while stream is paused
   * Dewarping with preview on/off
   * Try to go beyond the circle with different zoom, camera position, projection, dewarping resolution (aspect ratio taller than wide), stream that has circle cropping to do
   *    Beyond circle attempt should not add up.
   * Try to enable on a non fisheye camera
   * ctrl+shift+a -> show some details about dewarper (dewarping frame rate?)
   * debugStatus should show a lot of detail for dewarper
   *
*/

  private static readonly DefaultOutsideColor: NormalizedColor = new NormalizedColor(0, 1, 0, 1);

  private readonly m_dewarperControl: IDewarperControl;

  private readonly m_logger: ILogger;
  private readonly m_canvas: IDewarperCanvas;
  private readonly m_dewarperVwoOverlay: IDewarperVwoOverlay;
  private readonly m_program: DewarperProgram;
  private readonly m_preview: DewarperPreview;
  private readonly m_controllers: IInternalDewarperControl;
  private readonly m_videoFrameSource: ThrottledVideoSource;
  private readonly m_dewarpingParametersBuilder: DewarpingParametersBuilder;

  private m_lastImageSource: IVideoFrame | null = null;

  private readonly m_frameRateComputer: FrameRateComputer = new FrameRateComputer();

  public get dewarperControl(): IDewarperControl {
    return this.m_dewarperControl;
  }

  public getFrameRate(): FrameRate {
    return this.m_frameRateComputer.getFrameRate();
  }

  public get isDewarping(): boolean {
    return this.m_videoFrameSource.IsStarted;
  }

  public static build(logger: ILogger, dewarperHtmlCanvas: HTMLCanvasElement, dewarperVwoOverlay: IDewarperVwoOverlay, previewHtmlDiv: HTMLDivElement,
    previewHtmlCanvas: HTMLCanvasElement, dewarpedAreaHtmlCanvas: HTMLCanvasElement,
    dewarperParameters: DewarperSourceImageParameters,
    videoFrameSource: IVideoFrameSource, mouseWheelObserver: MouseWheelObserver, dragObserver: DragObserver): Dewarper {
    const dewarperSourceImageParameters: InternalDewarperSourceImageParameters = InternalDewarperSourceImageParameters.build(dewarperParameters);
    const dewarperCanvas: IDewarperCanvas = new DewarperCanvas(logger, dewarperHtmlCanvas);
    const previewCanvas: IPreviewCanvas = new PreviewCanvas(logger, previewHtmlDiv, previewHtmlCanvas);
    const dewarpedAreaCanvas: IDewarpedAreaCanvas = new DewarpedAreaCanvas(logger, dewarpedAreaHtmlCanvas);
    const dewarpedArea: DewarpedArea | null = DewarpedArea.build(logger, dewarpedAreaCanvas, dewarperSourceImageParameters);

    return new Dewarper(logger, dewarperCanvas, dewarperVwoOverlay, previewCanvas, dewarpedArea, dewarperSourceImageParameters, videoFrameSource, mouseWheelObserver, dragObserver);
  }

  constructor(logger: ILogger, dewarperCanvas: IDewarperCanvas, dewarperVwoOverlay: IDewarperVwoOverlay, previewCanvas: IPreviewCanvas, dewarpedArea: DewarpedArea | null, dewarperParameters: InternalDewarperSourceImageParameters, videoFrameSource: IVideoFrameSource, mouseWheelObserver: MouseWheelObserver, dragObserver: DragObserver) {
    this.m_logger = logger.subLogger('Dewarper');
    this.m_canvas = dewarperCanvas;
    this.m_dewarperVwoOverlay = dewarperVwoOverlay;
    this.m_canvas.Resized.register(this.onCanvasResized);
    this.m_videoFrameSource = new ThrottledVideoSource(logger, videoFrameSource, 10);

    const gl = this.m_canvas.getWebGL2Context();
    this.logVersion(gl);

    this.m_program = new DewarperProgram(logger, gl, dewarperParameters);
    this.m_program.OutsideColor = this.getOutsideColor();
    this.m_controllers = new ControllerAggregate(this.m_program.Controller, dewarpedArea?.Controller ?? null);
    this.m_preview = new DewarperPreview(logger, previewCanvas, this.m_controllers, dewarpedArea);

    this.m_dewarperControl = new DewarperControl(this, this.m_preview, this.m_controllers);

    this.m_controllers.sceneUpdated.register(this.onSceneChanged);
    this.observeCanvasStyleLoop();
    this.observeMouseWheelLoop(mouseWheelObserver);
    this.observeDragLoop(dragObserver);

    this.videoFrameSourceListenLoop();

    this.m_dewarpingParametersBuilder = new DewarpingParametersBuilder(this.m_logger, dewarperParameters, Resolution.build(gl.canvas.width, gl.canvas.height));
    this.m_logger.debug?.trace('Dewarper constructor done');
  }

  public dispose(): void {
    this.m_videoFrameSource.dispose();
    this.stopDewarping();

    this.m_canvas.Resized.unregister(this.onCanvasResized);
    this.m_canvas.dispose();

    this.m_controllers.sceneUpdated.unregister(this.onSceneChanged);

    this.m_program.dispose();
    this.m_preview.dispose();

    this.m_logger.debug?.trace('Dewarper disposed');
  }

  public stopDewarping(): void {
    this.m_logger.debug?.trace('stopDewarping()');
    this.m_videoFrameSource.stop();
    this.m_lastImageSource = null;
    this.m_preview.IsDewarping = false;
    this.m_dewarperVwoOverlay.Show = false;
    this.m_canvas.Show = false;
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void {
    this.m_dewarperVwoOverlay.updateVideoWatermarkingConfig(videoWatermarkingConfig);
    this.m_preview.updateVideoWatermarkingConfig(videoWatermarkingConfig);
  }

  private dewarpImageSource(imageSource: IVideoFrame): void {
    if (imageSource.Resolution.IsNone) {
      this.m_logger.intense?.trace(`Not dewarping. Image source resolution is ${imageSource.Resolution.toString()}.`);
      return;
    }

    const imageElementCheck = imageSource.Image;
    if (imageElementCheck instanceof HTMLImageElement && !imageElementCheck.complete) {
      return;
    }

    this.m_lastImageSource = imageSource;

    const canvasResolution = this.m_canvas.Resolution;
    if (canvasResolution.IsNone) {
      this.m_logger.intense?.trace(`Not dewarping. Canvas resolution is ${this.m_canvas.Resolution.toString()}.`);
      return;
    }

    this.m_logger.intense?.trace(`Dewarping a frame: ${imageSource.toString()}`);

    if (!this.m_dewarpingParametersBuilder.StreamResolution.equals(imageSource.Resolution)) {
      const dewarpingParameters = this.m_dewarpingParametersBuilder.withStreamResolution(imageSource.Resolution);
      if (dewarpingParameters === null) {
        this.m_logger.error?.trace(`Update to dewarping parameters stream resolution to ${imageSource.Resolution.toString()} gave null dewarping parameters.`);
        return;
      }

      this.m_preview.setDewarpingParameters(dewarpingParameters);
      this.m_program.setDewarpingParameters(dewarpingParameters);
    }

    this.m_preview.render(imageSource);
    this.m_program.dewarp(imageSource);

    this.m_dewarperVwoOverlay.drawVwo();

    this.m_logger.intense?.trace('Dewarping done');
  }

  private readonly onCanvasResized = (canvasResolution: Resolution): void => {
    const dewarpingParameters = this.m_dewarpingParametersBuilder.withDewarpedResolution(canvasResolution);
    if (dewarpingParameters !== null) {
      this.m_logger.debug?.trace(`Canvas resized to ${canvasResolution.toString()}. Sending new dewarping parameters`);
      this.m_preview.setDewarpingParameters(dewarpingParameters);
      this.m_program.setDewarpingParameters(dewarpingParameters);
    }

    this.refresh(); //So even if we are paused, we still apply changes (either of the environment as canvas resolution or user requests, change of position/zoom etc)
  }

  private readonly onSceneChanged = (): void => {
    this.ensureEnabled();
    this.refresh();
  }

  private ensureEnabled(): void {
    this.m_videoFrameSource.start();
    this.m_preview.IsDewarping = true;
    this.m_canvas.Show = true;
    this.m_dewarperVwoOverlay.Show = true;
  }

  private refresh(): void {
    if (this.m_lastImageSource !== null) {
      this.dewarpImageSource(this.m_lastImageSource);
    }
  }

  private logVersion(gl: WebGL2RenderingContext) {
    this.m_logger.debug?.trace(`webgl2 version ${gl.getParameter(gl.VERSION)} shading language version ${gl.getParameter(gl.SHADING_LANGUAGE_VERSION)} ${gl.getParameter(gl.VENDOR)}`);
    // try to get the extensions
    const ext = gl.getExtension('WEBGL_debug_renderer_info');
    // if the extension exists, find out the info.
    if (ext !== null) {
      this.m_logger.debug?.trace(`Extension graphic information: ${gl.getParameter(ext.UNMASKED_VENDOR_WEBGL)} ${gl.getParameter(ext.UNMASKED_RENDERER_WEBGL)}`);
    }
  }

  private getOutsideColor(): NormalizedColor {
    try {
      return this.m_canvas.Color;
    } catch (e) {
      this.m_logger.warn?.trace('Failed to parse color. Fallbacking to default outside color.', e);
      return Dewarper.DefaultOutsideColor;
    }
  }

  private async observeCanvasStyleLoop() {
    let styleChangeAwaitingResult = await this.m_canvas.StyleChange;
    while (!(styleChangeAwaitingResult instanceof Cancellation)) {
      this.m_program.OutsideColor = this.getOutsideColor();
      styleChangeAwaitingResult = await this.m_canvas.StyleChange;
    }
    this.m_logger.debug?.trace('End of canvas style observation loop');
  }

  private async observeMouseWheelLoop(mouseWheelObserver: MouseWheelObserver) {
    let mouseWheeled = await mouseWheelObserver.MouseWheel;
    while (!(mouseWheeled instanceof Cancellation)) {
      const decrease: boolean = (<number>mouseWheeled > 0);

      if (decrease && this.m_controllers.ZoomFactor.IsMinimum) {
        this.stopDewarping();
      } else {
        const newZoom =
          decrease ?
            this.m_controllers.ZoomFactor.decrease() :
            this.m_controllers.ZoomFactor.increase();
        this.m_controllers.zoom(newZoom);
      }

      mouseWheeled = await mouseWheelObserver.MouseWheel;
    }
    this.m_logger.debug?.trace('End of mouse wheel observation loop');
  }

  private async observeDragLoop(dragObserver: DragObserver) {
    let dragged = await dragObserver.Drag;
    while (!(dragged instanceof Cancellation)) {
      const dragInfo = <DragInfo>dragged;
      this.m_controllers.drag(dragInfo);

      dragged = await dragObserver.Drag;
    }
    this.m_logger.debug?.trace('End of drag observation loop');
  }

  private async videoFrameSourceListenLoop(): Promise<void> {
    this.m_logger.debug?.trace('Start of videoFrameSourceListenLoop()');
    while (true) {
      const result = await this.m_videoFrameSource.getImage();
      if (result instanceof Cancellation) {
        break;
      }

      this.dewarpImageSource(result);
      this.m_frameRateComputer.addFrame('p'); //Saves processing by having just 'p' frames, todo: a simpler version of framerate computer that does not compute keyframe rate
    }
    this.m_logger.debug?.trace('End of videoFrameSourceListenLoop');
  }

  public debugStatus(indent: number): string {
    const gl = this.m_canvas.getWebGL2Context();
    let extendedInfo: string = 'Extended information not available';
    const ext = gl.getExtension('WEBGL_debug_renderer_info');
    // if the extension exists, find out the info.
    if (ext !== null) {
      extendedInfo = `Extension graphic information: ${gl.getParameter(ext.UNMASKED_VENDOR_WEBGL)} ${gl.getParameter(ext.UNMASKED_RENDERER_WEBGL)}`;
    }

    return 'Dewarper' + Utils.indentNewLine(indent) +
      `webgl2 version ${gl.getParameter(gl.VERSION)} shading language version ${gl.getParameter(gl.SHADING_LANGUAGE_VERSION)} ${gl.getParameter(gl.VENDOR)}` + Utils.indentNewLine(indent) +
      extendedInfo + Utils.indentNewLine(indent) +
      'Is dewarping: ' + this.m_videoFrameSource.IsStarted + ' ' + this.m_frameRateComputer.getFrameRate().frameRateToString() + Utils.indentNewLine(indent) +
      'Last dewarped image: ' + (this.m_lastImageSource?.toString() ?? 'none') + Utils.indentNewLine(indent) +
      this.m_canvas.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      this.m_program.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      this.m_preview.debugStatus(indent + Utils.Indentation);
  }
}
