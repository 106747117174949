import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import { RestResponse } from 'RestClient/Connection/RestResponse';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';

export class PushResponseReceiver {
    private _client: ISecurityCenterClient;
    private _maxUriLength = 2000;
    private _currentPacketView: DataView | null = null;
    private _currentPacket: Uint8Array | null = null;
    private _currentPacketOffSet = 0;
    private _mergeNextBuffer = false;

    constructor(client: ISecurityCenterClient) {
        this._client = client;
    }

    public getAsync(url: string, onMessageReceived: (size: number, buffer: ArrayBuffer) => void): Promise<IRestResponse> {
        return new Promise<IRestResponse>((resolve, reject) => {
            const restUrl = this._client.rest.restServerUrl;
            let fullUrl = '';
            if (restUrl.endsWith('/') === false) {
                fullUrl = restUrl + '/' + url;
            } else {
                fullUrl = restUrl + url;
            }
            const requestHeaders: HeadersInit = new Headers();
            const headers = this._client.rest.additionalHeaders;
            for (const [key, value] of headers) {
                requestHeaders.set(key, value);
            }
            requestHeaders.set('Authorization', this._client.rest.authorizationHeader);

            let body = null;
            let method = 'GET';
            if (fullUrl.length > this._maxUriLength) {
                // query too long... transform it
                method = 'POST';
                const splitUrl = fullUrl.split('?');
                fullUrl = splitUrl[0];
                const fo = new FieldObject();
                fo.setField('Query', splitUrl[1]);
                body = fo.toString();
                requestHeaders.append('X-HTTP-Method-Override', 'GET');
            }

            let restResponse: RestResponse;
            fetch(fullUrl, {
                method: method,
                headers: requestHeaders,
                body: body,
            })
                .then(async (response) => {
                    if (response.body == null) {
                        restResponse = new RestResponse(HttpStatusCode.INTERNAL_SERVER_ERROR);
                        resolve(restResponse);
                        return;
                    }
                    const reader = response.body.getReader();
                    do {
                        const result = await reader.read();
                        if (result.done) {
                            restResponse = new RestResponse(HttpStatusCode.OK);
                            resolve(restResponse);
                            return;
                        }
                        this.queueData(result.value, onMessageReceived);
                        // eslint-disable-next-line no-constant-condition
                    } while (true);
                })
                .catch((err) => {
                    restResponse = new RestResponse(HttpStatusCode.INTERNAL_SERVER_ERROR, err);
                    resolve(restResponse);
                });
        });
    }

    public async getJsonAsync<T extends FieldObject>(classType: new () => T, url: string, onMessageReceived: (message: T) => void): Promise<IRestResponse> {
        const response = await this.getAsync(url, (size, buffer) => {
            const dv = new DataView(buffer, 0, size);
            const body = this.getString(dv, 0);
            const jsonObj = JSON.parse(body[0]);
            const result = new classType();
            result.loadFields(jsonObj);
            onMessageReceived(result);
        });
        return response;
    }

    private queueData(data: Uint8Array, onMessageReceived: (size: number, buffer: ArrayBuffer) => void) {
        if (this._mergeNextBuffer === false) {
            this._currentPacketOffSet = 0;
            this._currentPacketView = new DataView(data.buffer);
            this._currentPacket = data;
        } else {
            if (this._currentPacket == null) {
                throw Error('_currentPacket should not be null');
            }
            // merge with current
            const mergedArray = new Uint8Array(this._currentPacket.length + data.length);
            mergedArray.set(this._currentPacket);
            mergedArray.set(data, this._currentPacket.length);
            this._currentPacketView = new DataView(mergedArray.buffer);
            this._currentPacket = mergedArray;
            this._mergeNextBuffer = false;
        }

        let parseAgain = true;
        while (parseAgain === true) {
            if (this._currentPacketView == null) {
                throw Error('_currentPacketView should not be null');
            }
            // Read the total length of the message
            if (this._currentPacketOffSet + 4 > this._currentPacket.byteLength) {
                // Not enought data, wait for next packet
                parseAgain = false;
                this._mergeNextBuffer = true;
            } else {
                const msgLength = this._currentPacketView.getInt32(this._currentPacketOffSet, true);
                const nextOffSet = this._currentPacketOffSet + 4 + msgLength;
                if (nextOffSet > this._currentPacket.byteLength) {
                    // Not enought data, wait for next packet
                    this._mergeNextBuffer = true;
                    parseAgain = false;
                } else {
                    // Parse current
                    this.parseMessage(this._currentPacketView, this._currentPacketOffSet, onMessageReceived);
                    this._currentPacketOffSet += 4 + msgLength;
                    // End of current buffer ?
                    if (this._currentPacketOffSet === this._currentPacketView.byteLength) {
                        parseAgain = false;
                        this._currentPacketOffSet = 0;
                        this._currentPacketView = null;
                    }
                }
            }
        }
    }

    private parseMessage(data: DataView, startOffset: number, onMessageReceived: (size: number, buffer: ArrayBuffer) => void) {
        const messageLen = data.getInt32(startOffset, true);
        const buffer = data.buffer.slice(startOffset, startOffset + 4 + messageLen);
        onMessageReceived(messageLen + 4, buffer);
    }

    private pad(n: string) {
        return n.length < 2 ? '0' + n : n;
    }

    private getString(dataView: DataView, startOffset: number): [string, number] {
        let offset = startOffset;
        const length = dataView.getInt32(offset, true);
        offset += 4;
        let str = '';
        for (let i = 0; i < length; i++) {
            str += '%' + this.pad(dataView.getUint8(offset).toString(16));
            offset += 1;
        }
        const result: [string, number] = [decodeURIComponent(str), 4 + length];
        return result;
    }

    private getGuid(dataView: DataView, startOffset: number): [string, number] {
        // const str = uuidParse.unparse(dataView.buffer.slice(startOffset, startOffset + 16));
        const str = '';
        const result: [string, number] = [str, 16];
        return result;
    }

    private getInt64(dataView: DataView, startOffset: number, littleEndian: boolean) {
        let low = 4;
        let high = 0;
        if (littleEndian) {
            low = 0;
            high = 4;
        }
        // eslint-disable-next-line no-bitwise
        return (dataView.getUint32(startOffset + high, littleEndian) << 32) | dataView.getUint32(startOffset + low, littleEndian);
    }
}
