import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { GenSearchInputItem, GenTextInput, MeltedIcon } from '@genetec/gelato-angular';
import { Observable } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { SafeGuid } from 'safeguid';
import { SearchResultGroup, SearchService } from '../../services/search/search.service';
import { TrackingService } from '../../services/tracking.service';
import { TrackedComponent } from '../tracked/tracked.component';
import { COMMANDS_SERVICE } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '../../services/commands/commands.service';
import { SharedCommands } from '../../enumerations/shared-commands';
import { GlobalSearchContext } from '../../api/api';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-main-search',
    templateUrl: './main-search.component.html',
    styleUrls: ['./main-search.component.scss'],
})
export class MainSearchComponent extends TrackedComponent implements OnInit {
    //#region Fields

    @Input() public clearText?: Observable<void>;

    @Output() public searchResultsChange = new EventEmitter<SearchResultGroup[] | null>(); // Empty array means no results, whereas null means "didn't search" (search text is null)

    @Output() public isSearchingChange = new EventEmitter<boolean>(); // In case the outside control wants to show loading feedback while searching

    @Output() public focusEvent = new EventEmitter<void>();

    @ViewChild('mainTextInput') public mainTextInput!: GenTextInput;
    @ViewChild('mainTextInput', { read: ElementRef }) public mainTextInputElement!: ElementRef;

    public isMainSearchReady = false;

    public searchText = '';

    private lastSearchInstanceId = SafeGuid.EMPTY;

    //#endregion

    //#region Constructor

    constructor(private searchService: SearchService, trackingService: TrackingService, @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService) {
        super(trackingService);
    }

    //#endregion

    //#region Methods

    ngOnInit() {
        // Wait until other elements load before showing the main search so it gets the focus
        setTimeout(() => (this.isMainSearchReady = true));

        this.clearText?.pipe(untilDestroyed(this)).subscribe(() => {
            this.searchText = '';
            this.searchResultsChange.emit(null);
        });
    }

    public clear(): void {
        this.mainTextInput.value = '';
    }

    public focus(): void {
        setTimeout(() => {
            const element = this.mainTextInputElement?.nativeElement as HTMLElement | undefined | null;
            if (element) {
                element.focus();

                // the first try might not work, in that case, try again will a slightly longer delay
                if (document.activeElement !== element) {
                    setTimeout(() => {
                        element.focus();
                    }, 500);
                }
            }
        }, 300);
    }

    public closeSidePane(): void {
        this.commandsService.executeCommand(SharedCommands.CloseNavBar);
    }

    public onFocus(): void {
        this.focusEvent.emit();
    }

    //#endregion

    //#region Event Handlers

    public async onSearchTextChange(searchText: string): Promise<void> {
        this.searchText = searchText;
        if (searchText.length > 0) {
            const currentSearchId = SafeGuid.newGuid();
            this.lastSearchInstanceId = currentSearchId;

            this.isSearchingChange.emit(true);
            const searchResults = await this.search(searchText);

            if (currentSearchId.equals(this.lastSearchInstanceId)) {
                this.isSearchingChange.emit(false);
                this.searchResultsChange.emit(searchResults);
            }
        } else {
            this.searchResultsChange.emit(null);
        }
    }

    //#endregion

    //#region Private methods

    private async search(searchText: string): Promise<Array<SearchResultGroup>> {
        let searchResults: Array<SearchResultGroup> = [];
        if (searchText.length > 0) {
            const context = new GlobalSearchContext();
            context.text = searchText;
            searchResults = await this.searchService.getSearchResults(context);
        }
        return searchResults;
    }
}

export class SearchResultItem implements GenSearchInputItem {
    public text!: string;
    public id!: string;
    public icon?: MeltedIcon;
    public secondaryText?: string;
    public groupName?: string;
    public navigation?: string;
}
