import { IGuid } from 'safeguid';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { MeltedIcon } from '@genetec/gelato-angular';
import { EntityBrowserItemModel } from '../../../entity-browser/Items/entity-browser-item-model';
import { IEntityTypeData } from '../../../models/entity-type-data';
import { EntityBrowserItemService } from '../../../entity-browser/entity-browser-item.service';
import { StateItem } from '../../entity-state-item/state-item/state-item';
import { BrowserItem } from './browser-item';

export class EntityBrowserItem extends BrowserItem implements StateItem {
    public entityId: IGuid;
    public isIncludedItem = false;
    public model: EntityBrowserItemModel;
    public canSelect = false;

    public get hasWarnings(): boolean {
        return this.state === RunningState.Warning;
    }

    public get entityType(): IEntityTypeData {
        return this.model.entityType;
    }

    public get isInMaintenance(): boolean {
        return this.maintenance;
    }

    public get isOffline(): boolean {
        return this.state === RunningState.NotRunning;
    }

    public get state(): string {
        return this.model.runningState;
    }

    public get maintenance(): boolean {
        return this.model.maintenance;
    }

    public get name(): string {
        return this.model.name;
    }

    public get guid(): IGuid {
        return this.model.id;
    }

    constructor(model: EntityBrowserItemModel, isViewHierarchical: boolean, protected entityBrowserItemService: EntityBrowserItemService) {
        super();
        this.model = model;
        this.entityId = model.id;
        this.itemId = this.entityId.toString();
        this.children = null;
        this.sortPriority = model.sortPriority;
        this.canExpand = isViewHierarchical && model.canHaveChildren;

        this.setText();
        this.setIcon();
    }

    protected setIcon(): void {
        let iconName = this.entityBrowserItemService.getItemIcon(this.model) as string;

        if (iconName.startsWith('gen-ico-') === false) {
            iconName = 'gen-ico-' + iconName;
        }

        this.icon = iconName as MeltedIcon;

        if (this.model.hasField('customIconId')) {
            this.customIconId = this.model.customIconId;
        }
    }

    protected setText(): void {
        this.text = this.model.name;
    }
}
