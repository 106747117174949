// Autogenerated: v2/help/Entities/ApiSchedule?codegen=ts&InterfaceOnly=True&workflow=False on 2021-06-03 12:03:22 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity, EntityFields } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class ScheduleEntityFields extends EntityFields {
    public static coverageTypeField = 'CoverageType';
    public static dataField = 'Data';
    public static isArchiverFriendlyField = 'IsArchiverFriendly';
    public static scheduleTypeField = 'ScheduleType';
}

// #endregion Fields

// #region Inner classes

export interface IDateRange extends IFieldObject {
    start: Date;
    end: Date;
}

// #endregion Inner classes

export interface IScheduleEntity extends IEntity {
    // #region REST Properties

    coverageType: string;

    data: string;

    readonly isArchiverFriendly: boolean;

    scheduleType: string;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    isTimeCoverAsync(time: Date, transaction?: ITransaction): Promise<boolean | null>;
    normalizeFlattenedDatesAsync(time: Date, transaction?: ITransaction): Promise<Array<IDateRange> | null>;

    // #endregion REST Methods

}

