import { AbstractControl, FormArray, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivationModeLabel, ActiveExpirationModeLabel, InactiveExpirationModeLabel } from '@modules/access-control/enumerations';
import moment from 'moment';
import { StatusFormGroup } from '../cardholder-edit-form-group';

const invalidRangeError = { invalidRange: true };

const isStatusFormGroup = (control: FormGroup | FormArray | StatusFormGroup | null): control is StatusFormGroup => {
    return !!control && 'expirationDate' in control.controls && 'activationMode' in control.controls;
};

/**
 * Returns error only if both the firstName and lastName control are empty. If only one of them is empty and
 * the other one isn't, this validator won't return an error.
 *
 */
export const firstNameLastNameValidator = (control: AbstractControl): ValidationErrors | null => {
    const firstNameControl = control.get('firstName');
    const lastNameControl = control.get('lastName');

    if (firstNameControl && lastNameControl) {
        const firstNameErrors = Validators.required(firstNameControl);
        const lastNameErrors = Validators.required(lastNameControl);

        // Both empty
        if (firstNameErrors !== null && lastNameErrors !== null) {
            firstNameControl.setErrors(firstNameErrors);
            lastNameControl.setErrors(lastNameErrors);
            return firstNameErrors;
        }

        firstNameControl.setErrors(null);
        lastNameControl.setErrors(null);
    }

    return null;
};

export const activationDateValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
        return null;
    }

    if (!isStatusFormGroup(control.parent)) {
        throw new Error('Invalid form control for activationDateValidator.');
    }

    const form = control.parent;

    const activationMode = form.controls.activationMode.getRawValue();
    const expirationMode = form.controls.expirationMode.getRawValue();
    const activation = form.controls.activationDate.getRawValue();
    const expiration = form.controls.expirationDate.getRawValue();

    if (activationMode === ActivationModeLabel.ActivateOn) {
        // Activation date is valid?
        if (!activation || !moment(activation).isValid()) {
            return invalidRangeError;
        }

        // Validating 'activation < expiration' is only relevant when expiration mode is set to specific date
        if (expirationMode !== ActiveExpirationModeLabel.Expiring && expirationMode !== InactiveExpirationModeLabel.ActivateOn) {
            return null;
        }

        // If we have an expiration date, make sure expiration date > activation date
        if (expiration && activation > expiration) {
            return invalidRangeError;
        }
    }

    return null;
};

export const expirationDateValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
        return null;
    }

    if (!isStatusFormGroup(control.parent)) {
        throw new Error('Invalid form control for expirationDateValidator.');
    }

    const form = control.parent;

    const expirationMode = form.controls.expirationMode.getRawValue();
    const activation = form.controls.activationDate.getRawValue();
    const expiration = form.controls.expirationDate.getRawValue();

    if (expirationMode === ActiveExpirationModeLabel.Expiring || expirationMode === InactiveExpirationModeLabel.ActivateOn) {
        if (!expiration || !moment(expiration).isValid()) {
            return invalidRangeError;
        }

        // If we have an activation date, make sure expiration date < activation date
        if (activation && moment(activation).isValid() && activation > expiration) {
            return invalidRangeError;
        }
    }

    return null;
};
