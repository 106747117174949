import { Component, OnInit } from '@angular/core';
import { Guid } from 'typescript-guid';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { FilterTypes } from '@modules/shared/enumerations/filter-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { SafeGuid } from 'safeguid';
import { FilterContext, IFilterContent } from '@modules/shared/api/api';
import { FilterPluginBaseComponent } from './filter-plugin-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template:
        '<app-filter-tree-view [descriptor]="descriptor" [options]="options" [filterContext]="context" [rootItems]="rootItems" (updateRootItemsEvent)="loadRootItems($event)" (valueChange)="updateFilter($event)"></app-filter-tree-view>',
})
@InternalPluginDescriptor({
    type: FilterPluginTreeViewComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginTreeViewComponent.pluginId,
        subSection: FilterTypes.TreeView,
    },
    isSupported: () => true,
})
export class FilterPluginTreeViewComponent extends FilterPluginBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('42C2A783-2634-45C4-B179-7947399BBB7C');

    public context!: FilterContext;
    public options!: string[];
    public rootItems!: unknown[];

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginTreeViewComponent.pluginId);
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadOptions();
        this.buildFilterContext();
    }

    public buildFilterContext(): void {
        const context = new FilterContext();
        context.filterProviderId = this.descriptor.providerId ?? Guid.EMPTY;

        const metadata = this.extractValueFromFieldObjects<string>('metadata', this.descriptor.typeSpecificFields) ?? '';
        const includeCustomEvents = this.extractValueFromFieldObjects<boolean>('includeCustomEvents', this.descriptor.typeSpecificFields) ?? true;

        const typeSpecificFields = new FieldObject();
        typeSpecificFields.setField('metadata', metadata);
        typeSpecificFields.setField('includeCustomEvents', includeCustomEvents);
        context.typeSpecificFields = typeSpecificFields;

        this.context = context;
    }

    public loadRootItems(content: IFilterContent): void {
        this.rootItems = this.extractArrayFromFieldObjects<unknown[]>('rootItems', Array.from(content.typeSpecificFields));
    }

    private loadOptions(): void {
        this.options = this.descriptor.typeSpecificFields ? this.extractArrayFromFieldObjects<string[]>('availableOptions', this.descriptor.typeSpecificFields) : [];
    }

    private extractArrayFromFieldObjects<T extends any[]>(fieldName: string, fields: any[]): T {
        const field = Array.from(fields).find((x) => fieldName in x) as Record<string, unknown[]>;
        const fieldValue = field?.[fieldName];
        return Array.isArray(fieldValue) ? (fieldValue as T) : ([] as unknown as T);
    }

    private extractValueFromFieldObjects<T>(fieldName: string, fields: any[]): T | undefined {
        const field = Array.from(fields).find((x) => fieldName in x) as Record<string, unknown>;
        return (field?.[fieldName] as T) ?? undefined;
    }
}
