/** One of two player modes: live or playback
 * @public */
export enum PlayerMode {
  /** Live mode */
  live = 'live',
  /** Playback mode */
  playback = 'playback'
}

/** The player state
 * @public */
export enum PlayerState {
  /** Stopped: player not ready to accept commands */
  Stopped = 0,
  /** Player is buffering more data so it can start playing */
  Buffering = 1,
  /** Player is actively receving and decoding frames */
  Playing = 2,
  /** Player is starting */
  Starting = 3,
  /** Player was paused and is ready to resume playing */
  Paused = 4,
  /** Player is stopping */
  Stopping = 5,
  /** Player is playing in reverse */
  Rewinding = 6,
  /** Player is playing in reverse but there is nothing left to play */
  BeginReached = 7,
  /** Player is playing forward but there is nothing left to play */
  EndReached = 8,
  /** Player is waiting for the archiver to send its recoding */
  WaitingForArchiverData = 9,
  /** There is no video recorded this player can play */
  NoVideoSequenceAvailable = 10,
  /** Reserved for future use */
  AwaitingSynchronizedTime = 11,
  /** Player can't play because an error occured, a new command is required to try again */
  Error = 12,
  /** The stream is encrypted and cannot be decrypted */
  InsufficientSecurityInformation = 13
}

/** This status describes the connection between the player and the stream source
 * @public */
export enum StreamingConnectionStatus {
  /** Connection to Media Gateway is being established */
  ConnectingToMediaGateway = -1,
  /** Player is not initialized yet */
  Initializing = 0,
  /** Connecting to Media Router to establish stream route */
  ConnectingToMediaRouter = 1,
  /** Connecting to an Archiver */
  ConnectingToArchiver = 2,
  /** Waiting to receive live frames */
  RequestingLiveStream = 3,
  /** Waiting to receive a keyframe */
  AnalyzingStream = 4,
  /** Currently receiving a stream */
  Streaming = 5,
  /** Waiting for download */
  WaitingForDownload = 6,
  /** Waiting to receive playback stream */
  RequestingPlaybackStream = 7,
  /** Waiting for playback stream to start streaming again */
  ResumingPlaybackStream = 8,
  /** Connecting to a video unit */
  ConnectingToUnit = 9,
  /** Waiting for stream from video unit */
  GettingDataFromUnit = 10,
  /** Could not get data from Archivers */
  CouldNotGetData = 11,
  /** Could not get data from video units */
  CouldNotGetDataFromUnit = 12,
  /** Waiting for archive sequence list */
  SearchingArchives = 13,
  /** Reading archives */
  ReadingArchives = 14,
  /** Buffering */
  Buffering = 15,
  /** Buffering done */
  BufferingCompleted = 16,
  /** Unused here */
  VirtualCameraNoOutput = 17,
  /** Timeout */
  RequestTimedOut = 18,
  /** Stream was stopped because a bandwitdh limit was reached along its route */
  NotEnoughBandwidth = 19,
  /** Unused here */
  AwaitingSynchronizedTime = 20,
  /** Media Router needs a database upgrade */
  DatabaseUpgradeRequired = 21,
  /** Media Router did not find the stream */
  MediaRouterStreamNotFound = 22,
  /** Camera is offline */
  CameraNotRunning = 23,
  /** Archiver is offline */
  ArchiverNotRunning = 24,
  /** No route found for the stream */
  StreamUnreachable = 25,
  /** Another user blocked the stream */
  Blocked = 26,
  /** Stream got disconnected */
  StreamLost = 27,
  /** Waiting for decryption key */
  RequestingEncryption = 28,
  /** Unused here */
  DecodingStream = 29,
  /** Connection failed */
  ConnectionNotSuccessful = 30,
  /** Provided token was denied */
  UnauthorizedToken = 31
}

/** The various possible error codes
 * @public */
export enum ErrorCode {
  /** Unknown */
  Unknown = 0,
  /** Connection lost */
  ConnectionLost = 2,
  /** Server has disk space issues */
  InsufficientDiskSpace = 3,
  /** Connection failed */
  ConnectionFailed = 4,
  /** Stream not found */
  StreamNotFound = 6,
  /** Timeout */
  RequestTimedOut = 9,
  /** Video unit can't do playback */
  UnitPlaybackUnavailable = 11,
  /** A limit of live streams has been reached */
  LiveCapacityExceeded = 12,
  /** A limit of playback streams has been reached */
  PlaybackCapacityExceeded = 13,
  /** The stream has an unsupported codec */
  UnsupportedEncoding = 14,
  /** Media Router database needs upgrade */
  DatabaseUpgradeRequired = 15,
  /** Current user privilege doesn't allow the requested operation */
  InsufficientPrivilege = 16,
  /** Video unit cannot process the command */
  InsufficientCapability = 17,
  /** Cannot decrypt encrypted stream */
  MissingCertificate = 18,
  /** Command not supported */
  NotSupported = 20,
  /** The certificate to decrypt the stream is missing its private key */
  CantUsePrivateKey = 21,
  /** Unused here */
  Mp4CodecNotSupported = 22,
  /** Server has rejected the request */
  DeniedByServer = 23,
  /** Transcoding is prevented, see Media Gateway configuration page */
  NoTranscodingAllowed = 24,
  /** A configured stream transformation (like rotation) required transcoding, which is prevented */
  ForbiddenTransformation = 32,

  // Error codes with value 1000 or above are associated with local player errors.

  /** The requested stream state is invalid */
  UpdateSessionError = 1000
}

/** Types of timeline event, can be sequences or punctual
 * @public */
export enum TimelineEventKind {
  /** Sequence event describing available video archives */
  RecordingSequence = 0,
  /** Punctual event holding a user bookmark */
  Bookmark = 1
}
