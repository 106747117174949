// Autogenerated: v2/help/Classes/ApiCardholderEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { IGuid, SafeGuid } from 'safeguid';
import { ICardholderEvent } from '../Interface/ICardholderEvent';
import { RoutableEvent } from './RoutableEvent';
import { TimezoneEvent } from './TimezoneEvent';

export class CardholderEvent extends TimezoneEvent implements ICardholderEvent {
    // #region REST Properties

    // Cardholder
    public get cardholder(): IGuid {
        return this.getFieldGuid('Cardholder');
    }
    public set cardholder(value: IGuid) {
        this.setFieldGuid('Cardholder', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Cardholder', SafeGuid.EMPTY);
    }

    // #endregion Methods

}

