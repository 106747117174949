// Autogenerated: v2/help/Entities/ApiAlarm?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class AlarmEntityFields extends EntityFields {
    public static alarmRecordingTimeField = 'AlarmRecordingTime';
    public static alarmVideoModeField = 'AlarmVideoMode';
    public static audioFileField = 'AudioFile';
    public static autoAckAfterField = 'AutoAckAfter';
    public static canBeInvestigatedField = 'CanBeInvestigated';
    public static colorField = 'Color';
    public static createIncidentField = 'CreateIncident';
    public static distributionModeField = 'DistributionMode';
    public static dwellTimeField = 'DwellTime';
    public static enableProcedureField = 'EnableProcedure';
    public static playbackPreTriggerTimeField = 'PlaybackPreTriggerTime';
    public static previewDefaultPositionField = 'PreviewDefaultPosition';
    public static previewSourceVideoModeField = 'PreviewSourceVideoMode';
    public static priorityField = 'Priority';
    public static procedureField = 'Procedure';
    public static protectVideoPeriodField = 'ProtectVideoPeriod';
    public static rearmThresholdField = 'RearmThreshold';
}

// #endregion Fields

// #region Inner classes

export interface ITriggerInfo extends IFieldObject {
    sourceEntity: IGuid | null;
    latitude: number | null;
    longitude: number | null;
    attachedEntities: ObservableCollection<IGuid>;
    context: string;
    forwardedRecipients: ObservableCollection<IGuid>;
    priority: number | null;
    urls: ObservableCollection<string>;
}

export interface IRecipient extends IFieldObject {
    id: IGuid;
    propagationLevel: number;
}

// #endregion Inner classes

export interface IAlarmEntity extends IEntity {
    // #region REST Properties

    alarmRecordingTime: number;

    alarmVideoMode: string;

    audioFile: IGuid;

    autoAckAfter: number;

    canBeInvestigated: boolean;

    color: string;

    createIncident: boolean;

    distributionMode: string;

    dwellTime: number;

    enableProcedure: boolean;

    playbackPreTriggerTime: number;

    previewDefaultPosition: string;

    previewSourceVideoMode: string;

    priority: number;

    procedure: string;

    protectVideoPeriod: number;

    rearmThreshold: number;

    // #endregion REST Properties

    // #region REST Methods

    getAttachedEntitiesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    forceAckAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getRecipientsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRecipient> | null>;
    getSchedulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    triggerAsync(value: ITriggerInfo | null, transaction?: ITransaction): Promise<number | null>;

    // #endregion REST Methods

}

