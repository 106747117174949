// Autogenerated: v2/help/Entities/ApiUnit?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:25 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { LocatableEntity } from './LocatableEntity';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IUnitEntity, UnitEntityFields } from '../Interface/IUnitEntity';
import { ITransaction } from '../Interface/ITransaction';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity } from '../Interface/IEntity';
import { IQueryFilter } from '../Interface/IQueryFilter';

export class UnitEntity extends LocatableEntity implements IUnitEntity {
    // #region REST Properties

    // CanReboot
    public get canReboot(): boolean {
        return this.getField<boolean>(UnitEntityFields.canRebootField);
    }

    // CertificateId
    public get certificateId(): IGuid {
        return this.getFieldGuid(UnitEntityFields.certificateIdField);
    }
    public set certificateId(value: IGuid) {
        this.setFieldGuid(UnitEntityFields.certificateIdField, value);
    }

    // CertificateSigningRequestCommonName
    public get certificateSigningRequestCommonName(): string {
        return this.getField<string>(UnitEntityFields.certificateSigningRequestCommonNameField, ValidFlags.Specific);
    }
    public set certificateSigningRequestCommonName(value: string) {
        this.setField<string>(UnitEntityFields.certificateSigningRequestCommonNameField, value);
    }

    // ExtensionId
    public get extensionId(): IGuid {
        return this.getFieldGuid(UnitEntityFields.extensionIdField);
    }
    public set extensionId(value: IGuid) {
        this.setFieldGuid(UnitEntityFields.extensionIdField, value);
    }

    // FirmwareVersion
    public get firmwareVersion(): string {
        return this.getField<string>(UnitEntityFields.firmwareVersionField, ValidFlags.Specific);
    }
    public set firmwareVersion(value: string) {
        this.setField<string>(UnitEntityFields.firmwareVersionField, value);
    }

    // IPAddress
    public get iPAddress(): string {
        return this.getField<string>(UnitEntityFields.iPAddressField, ValidFlags.Specific);
    }
    public set iPAddress(value: string) {
        this.setField<string>(UnitEntityFields.iPAddressField, value);
    }

    // IsUsingCertificate
    public get isUsingCertificate(): boolean {
        return this.getField<boolean>(UnitEntityFields.isUsingCertificateField);
    }

    // LoginPolicy
    public get loginPolicy(): string {
        return this.getField<string>(UnitEntityFields.loginPolicyField);
    }
    public set loginPolicy(value: string) {
        this.setField<string>(UnitEntityFields.loginPolicyField, value);
    }

    // Manufacturer
    public get manufacturer(): string {
        return this.getField<string>(UnitEntityFields.manufacturerField, ValidFlags.Specific);
    }
    public set manufacturer(value: string) {
        this.setField<string>(UnitEntityFields.manufacturerField, value);
    }

    // Model
    public get model(): string {
        return this.getField<string>(UnitEntityFields.modelField, ValidFlags.Specific);
    }
    public set model(value: string) {
        this.setField<string>(UnitEntityFields.modelField, value);
    }

    // Password
    public get password(): string {
        return this.getField<string>(UnitEntityFields.passwordField);
    }
    public set password(value: string) {
        this.setField<string>(UnitEntityFields.passwordField, value);
    }

    // PhysicalAddress
    public get physicalAddress(): string {
        return this.getField<string>(UnitEntityFields.physicalAddressField, ValidFlags.Specific);
    }

    // PlatformVersion
    public get platformVersion(): string {
        return this.getField<string>(UnitEntityFields.platformVersionField, ValidFlags.Specific);
    }
    public set platformVersion(value: string) {
        this.setField<string>(UnitEntityFields.platformVersionField, value);
    }

    // SecureCommandPort
    public get secureCommandPort(): number {
        return this.getField<number>(UnitEntityFields.secureCommandPortField);
    }
    public set secureCommandPort(value: number) {
        this.setField<number>(UnitEntityFields.secureCommandPortField, value);
    }

    // SerialNumber
    public get serialNumber(): string {
        return this.getField<string>(UnitEntityFields.serialNumberField, ValidFlags.Specific);
    }
    public set serialNumber(value: string) {
        this.setField<string>(UnitEntityFields.serialNumberField, value);
    }

    // SupportOutputDeviceStates
    public get supportOutputDeviceStates(): boolean {
        return this.getField<boolean>(UnitEntityFields.supportOutputDeviceStatesField);
    }

    // SupportsCertificateManagement
    public get supportsCertificateManagement(): boolean {
        return this.getField<boolean>(UnitEntityFields.supportsCertificateManagementField);
    }
    public set supportsCertificateManagement(value: boolean) {
        this.setField<boolean>(UnitEntityFields.supportsCertificateManagementField, value);
    }

    // SupportServerControlledFirmwareUpgrade
    public get supportServerControlledFirmwareUpgrade(): boolean {
        return this.getField<boolean>(UnitEntityFields.supportServerControlledFirmwareUpgradeField);
    }
    public set supportServerControlledFirmwareUpgrade(value: boolean) {
        this.setField<boolean>(UnitEntityFields.supportServerControlledFirmwareUpgradeField, value);
    }

    // SupportsPasswordChange
    public get supportsPasswordChange(): boolean {
        return this.getField<boolean>(UnitEntityFields.supportsPasswordChangeField);
    }
    public set supportsPasswordChange(value: boolean) {
        this.setField<boolean>(UnitEntityFields.supportsPasswordChangeField, value);
    }

    // TimeZone
    public get timeZone(): string {
        return this.getField<string>(UnitEntityFields.timeZoneField);
    }

    // Username
    public get username(): string {
        return this.getField<string>(UnitEntityFields.usernameField);
    }
    public set username(value: string) {
        this.setField<string>(UnitEntityFields.usernameField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor(entityType: string) {
        super(entityType);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getDevicesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Devices', 'Id', true, query, ValidFlags.Membership, transaction);
    }

    public async moveUnitAsync(id: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('id', id);

        return this.executeRequestTransactionAsync<IRestResponse>('MoveUnit', 'POST', inParam, transaction, responseHandler);
    }

    public async rebootAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('Reboot', 'POST', null, transaction, responseHandler);
    }

    public async getUnitOnlyDevicesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('UnitOnlyDevices', 'Id', true, query, ValidFlags.Membership, transaction);
    }

    // #endregion REST Methods

}

