// Autogenerated: v2/help/enumerations/ApiEntityTypes?codegen=ts&showresources=False on 2022-07-05 4:50:08 PM
import { AccessPointType } from "./AccessPointType";
import { AccessPointRuleType } from "./AccessPointRuleType";
import { CameraTypes } from "./CameraType";
import { DeviceType } from "./DeviceType";
import { ScheduleType } from "./ScheduleType";
import { StreamType } from "./StreamType";
import { AccessControlInterfaceType } from "./AccessControlInterfaceType";
import { ZoneType } from "./ZoneType";
import { ServerType } from "./ServerType";
import { LprUnitType } from "./LprUnitType";
import { ApplicationType } from "./ApplicationType";

export class EntityTypes {
    public static None = 'None';
    public static AccessPoints = 'AccessPoints';
    public static AccessRules = 'AccessRules';
    public static Alarms = 'Alarms';
    public static Applications = 'Applications';
    public static Areas = 'Areas';
    public static Cameras = 'Cameras';
    public static Cardholders = 'Cardholders';
    public static CardholderGroups = 'CardholderGroups';
    public static Credentials = 'Credentials';
    public static Devices = 'Devices';
    public static Doors = 'Doors';
    public static Elevators = 'Elevators';
    public static Roles = 'Roles';
    public static OutputBehaviors = 'OutputBehaviors';
    public static Partitions = 'Partitions';
    public static SystemConfigurations = 'SystemConfigurations';
    public static Schedules = 'Schedules';
    public static Units = 'Units';
    public static Users = 'Users';
    public static UserGroups = 'UserGroups';
    public static Visitors = 'Visitors';
    public static Zones = 'Zones';
    public static Macros = 'Macros';
    public static CashRegisters = 'CashRegisters';
    public static LprUnits = 'LprUnits';
    public static Patrollers = 'Patrollers';
    public static Permits = 'Permits';
    public static Hotlists = 'Hotlists';
    public static OvertimeRules = 'OvertimeRules';
    public static PermitRules = 'PermitRules';
    public static SharedPermitRules = 'SharedPermitRules';
    public static UserTasks = 'UserTasks';
    public static IntrusionAreas = 'IntrusionAreas';
    public static IntrusionUnits = 'IntrusionUnits';
    public static Servers = 'Servers';
    public static Assets = 'Assets';
    public static VideoUnits = 'VideoUnits';
    public static Files = 'Files';
    public static Monitors = 'Monitors';
    public static ThreatLevels = 'ThreatLevels';
    public static Streams = 'Streams';
    public static Networks = 'Networks';
    public static Agents = 'Agents';
    public static Routes = 'Routes';
    public static Badges = 'Badges';
    public static Sequences = 'Sequences';
    public static TilePlugins = 'TilePlugins';
    public static MlpiRules = 'MlpiRules';
    public static ScheduledTasks = 'ScheduledTasks';
    public static AnalogMonitors = 'AnalogMonitors';
    public static AnalogMonitorGroups = 'AnalogMonitorGroups';
    public static InterfaceModules = 'InterfaceModules';
    public static TransferGroups = 'TransferGroups';
    public static CustomEntities = 'CustomEntities';
    public static Maps = 'Maps';
    public static Keyboards = 'Keyboards';
    public static ArchivedStreams = 'ArchivedStreams';
    public static HardwareUnits = 'HardwareUnits';
    public static ParkingZones = 'ParkingZones';
    public static ParkingRules = 'ParkingRules';
    public static FusionStreams = 'FusionStreams';
    public static TileLayouts = 'TileLayouts';
    public static DoorTemplates = 'DoorTemplates';
    public static InterfaceModuleDeviceDefinitions = 'InterfaceModuleDeviceDefinitions';
    public static Contact = 'Contact';
    public static Workstation = 'Workstation';
    public static Endpoint = 'Endpoint';

    public static readonly values = [
        EntityTypes.None,
        EntityTypes.AccessPoints,
        EntityTypes.AccessRules,
        EntityTypes.Alarms,
        EntityTypes.Applications,
        EntityTypes.Areas,
        EntityTypes.Cameras,
        EntityTypes.Cardholders,
        EntityTypes.CardholderGroups,
        EntityTypes.Credentials,
        EntityTypes.Devices,
        EntityTypes.Doors,
        EntityTypes.Elevators,
        EntityTypes.Roles,
        EntityTypes.OutputBehaviors,
        EntityTypes.Partitions,
        EntityTypes.SystemConfigurations,
        EntityTypes.Schedules,
        EntityTypes.Units,
        EntityTypes.Users,
        EntityTypes.UserGroups,
        EntityTypes.Visitors,
        EntityTypes.Zones,
        EntityTypes.Macros,
        EntityTypes.CashRegisters,
        EntityTypes.LprUnits,
        EntityTypes.Patrollers,
        EntityTypes.Permits,
        EntityTypes.Hotlists,
        EntityTypes.OvertimeRules,
        EntityTypes.PermitRules,
        EntityTypes.SharedPermitRules,
        EntityTypes.UserTasks,
        EntityTypes.IntrusionAreas,
        EntityTypes.IntrusionUnits,
        EntityTypes.Servers,
        EntityTypes.Assets,
        EntityTypes.VideoUnits,
        EntityTypes.Files,
        EntityTypes.Monitors,
        EntityTypes.ThreatLevels,
        EntityTypes.Streams,
        EntityTypes.Networks,
        EntityTypes.Agents,
        EntityTypes.Routes,
        EntityTypes.Badges,
        EntityTypes.Sequences,
        EntityTypes.TilePlugins,
        EntityTypes.MlpiRules,
        EntityTypes.ScheduledTasks,
        EntityTypes.AnalogMonitors,
        EntityTypes.AnalogMonitorGroups,
        EntityTypes.InterfaceModules,
        EntityTypes.TransferGroups,
        EntityTypes.CustomEntities,
        EntityTypes.Maps,
        EntityTypes.Keyboards,
        EntityTypes.ArchivedStreams,
        EntityTypes.HardwareUnits,
        EntityTypes.ParkingZones,
        EntityTypes.ParkingRules,
        EntityTypes.FusionStreams,
        EntityTypes.TileLayouts,
        EntityTypes.DoorTemplates,
        EntityTypes.InterfaceModuleDeviceDefinitions,
        EntityTypes.Contact,
        EntityTypes.Workstation,
        EntityTypes.Endpoint
    ];

    public static getSubTypes(entityType: string): Array<string> {
        switch (entityType) {
            case EntityTypes.AccessPoints: {
                return AccessPointType.values;
            }
            case EntityTypes.AccessRules: {
                return AccessPointRuleType.values;
            }
            case EntityTypes.Applications: {
                return ApplicationType.values;
            }
            case EntityTypes.Cameras: {
                return CameraTypes.values;
            }
            case EntityTypes.Devices: {
                return DeviceType.values;
            }
            case EntityTypes.Schedules: {
                return ScheduleType.values;
            }
            case EntityTypes.Zones: {
                return ZoneType.values;
            }
            case EntityTypes.LprUnits: {
                return LprUnitType.values;
            }
            case EntityTypes.Servers: {
                return ServerType.values;
            }
            case EntityTypes.Streams: {
                return StreamType.values;
            }
            case EntityTypes.InterfaceModules: {
                return AccessControlInterfaceType.values;
            }
        }
        return new Array<string>();
    }
}
