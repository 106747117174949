import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenAngularModule } from '@genetec/gelato-angular';
import { SharedModule } from '../shared/shared.module';
import { AlarmsTrayComponent } from './components/alarms-tray/alarms-tray.component';
import { AlarmsContextWidgetComponent } from './components/alarms-context-widget/alarms-context-widget.component';
import { AlarmEventCompactWidgetComponent } from './components/maps/alarm-event-compact-widget/alarm-event-compact-widget.component';
import { AlarmEventExpandWidgetComponent } from './components/maps/alarm-event-expand-widget/alarm-event-expand-widget.component';
import { AlarmActionsWidgetComponent } from './components/widgets/alarm-actions-widget/alarm-actions-widget.component';
import { AlarmInstanceInfoWidgetComponent } from './components/widgets/alarm-instance-info-widget/alarm-instance-info-widget.component';
import { AlarmCommandProvider } from './services/alarm-command-provider';
import { AlarmsService } from './services/alarms.service';
import { AlarmInstanceWidgetBaseComponent } from './components/alarm-instance-widget-base.component';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@NgModule({
    imports: [CommonModule, SharedModule, GenAngularModule],
    declarations: [
        AlarmsTrayComponent,
        AlarmsContextWidgetComponent,
        AlarmEventCompactWidgetComponent,
        AlarmEventExpandWidgetComponent,
        AlarmActionsWidgetComponent,
        AlarmInstanceInfoWidgetComponent,
        AlarmInstanceWidgetBaseComponent,
    ],
    providers: [AlarmCommandProvider, AlarmsService],
})
export class AlarmsModule {
    constructor(_alarmCommandHandlersService: AlarmCommandProvider) {}
}
