// Autogenerated: v2/help/Entities/ApiThreatLevel?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:08 PM
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { IRestResponse } from './IRestResponse';
import { ITransaction } from './ITransaction';

// #region Fields

export class ThreatLevelEntityFields extends EntityFields {
    public static colorField = 'Color';
    public static levelField = 'Level';
}

// #endregion Fields

export interface IThreatLevelEntity extends IEntity {
    // #region REST Properties

    color: string;

    level: number;

    // #endregion REST Properties

    // #region REST Methods

    activateAsync(target: IGuid, recursive: boolean, transaction?: ITransaction): Promise<IRestResponse | null>;
    deactivateAsync(target: IGuid, recursive: boolean, transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

