export class Deserializer {
  private readonly m_arrayBuffer: ArrayBuffer;

  private readonly m_dataView: DataView;

  private readonly m_hasNativeTextDecoder: boolean;

  private m_offset: number = 0;

  public get Offset(): number {
    return this.m_offset;
  }

  constructor(arrayBuffer: ArrayBuffer) {
    this.m_arrayBuffer = arrayBuffer;
    this.m_dataView = new DataView(this.m_arrayBuffer, 0);
    this.m_hasNativeTextDecoder = window.TextDecoder !== undefined && window.TextDecoder.toString().indexOf('[native code]') !== -1;
  }

  public getUint8(): number {
    return this.m_dataView.getUint8(this.m_offset++);
  }

  public getUint32(): number {
    const uint32 = this.m_dataView.getUint32(this.m_offset);
    this.m_offset += 4;
    return uint32;
  }

  public getInt32(): number {
    const int32 = this.m_dataView.getInt32(this.m_offset);
    this.m_offset += 4;
    return int32;
  }

  public getString(): string {
    const stringLength = this.getInt32();
    if (stringLength < 0) {
      throw new Error(`String length was negative ${stringLength}`);
    }

    if (this.m_hasNativeTextDecoder) {
      // We have a native TextDecoder implementation. We may rely on the DataView overload
      const dataView = new DataView(this.m_arrayBuffer, this.m_offset, stringLength);
      this.m_offset += stringLength;
      return new TextDecoder().decode(dataView);
    }

    // When we can't detect a native TextDecoder, we have to slice the array (copy it) instead of simply
    // creating a view to it. All TextEncoder/TextDecoder polyfills have trouble dealing with a DataView polyfill
    const subArray = this.m_arrayBuffer.slice(this.m_offset, this.m_offset + stringLength);
    this.m_offset += stringLength;
    return new TextDecoder().decode(subArray);
  }
}
