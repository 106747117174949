import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
    constructor(private host: ElementRef) {}

    ngAfterViewInit() {
        setTimeout(() => {
            (this.host.nativeElement as HTMLElement).focus();
        }, 100);
    }
}
