/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit } from '@angular/core';
import { CalendarTimeFrame, CalendarFlavor } from '@genetec/gelato-angular';
import { InternalPluginDescriptor } from 'src/app/modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from 'src/app/modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from 'src/app/modules/shared/services/tracking.service';
import { FilterTypes } from 'src/app/modules/shared/enumerations/filter-types';
import { SafeGuid } from 'safeguid';
import { FilterPluginBaseComponent } from './filter-plugin-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template: `<app-filter-date
        [calendarFlavor]="calendarFlavor"
        [calendarTimeFrame]="calendarTimeFrame"
        [descriptor]="descriptor"
        (valueChange)="updateFilter($event)"
    ></app-filter-date>`,
})
@InternalPluginDescriptor({
    type: FilterPluginDateComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginDateComponent.pluginId,
        subSection: FilterTypes.Date,
    },
    isSupported: () => true,
})
export class FilterPluginDateComponent extends FilterPluginBaseComponent implements OnInit {
    public static readonly pluginId = SafeGuid.parse('AC0FAA33-187F-48E0-B172-1FB5DC2D20C3');

    public calendarFlavor: CalendarFlavor = CalendarFlavor.Date;
    public calendarTimeFrame: CalendarTimeFrame = CalendarTimeFrame.All;

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginDateComponent.pluginId);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initData) {
            const typeSpecificFields = this.initData.typeSpecificFields;
            const fieldsArray = typeSpecificFields;

            const calendarTypeField = fieldsArray.find((x) => 'calendarType' in x);
            this.calendarFlavor = calendarTypeField?.calendarType as CalendarFlavor;

            const timeFrameField = fieldsArray.find((x) => 'timeFrame' in x);
            this.calendarTimeFrame = timeFrameField?.timeFrame as CalendarTimeFrame;
        }
    }
}
