import { Inject, Injectable } from '@angular/core';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { LanguageService } from '@modules/shared/services/language/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EventBase } from 'RestClient/Client/Event/EventBase';
import { IGuid, SafeGuid } from 'safeguid';
import { FEATURES_SERVICE } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { CorrelationClient, DataTypeDisplayModel, LightCorrelationExtendedFieldResult, LightDataSourceRegistration } from '../api/api';
import { CorrelationFeatures } from '../enumerations/correlation.features';

export class CorrelationDataSourcesRefreshedEvent extends EventBase {
    constructor() {
        super();
    }
}

@Injectable()
@UntilDestroy()
export class CorrelationService {
    //#region Fields

    private dataTypes: DataTypeDisplayModel[] | undefined;
    private allRegistrations: LightDataSourceRegistration[] | undefined;

    //#endregion

    //#region Constructor

    constructor(
        private correlationClient: CorrelationClient,
        private scClientService: SecurityCenterClientService,
        @Inject(FEATURES_SERVICE) private featuresService: FeaturesService,
        private authService: AuthService,
        private languageService: LanguageService
    ) {
        this.initialize().fireAndForget();
    }

    //#endregion

    //#region Methods

    public async getDataTypes(): Promise<DataTypeDisplayModel[] | undefined> {
        // check if there's a cached value, if miss load it
        if (!this.dataTypes) {
            await this.buildCaches(false, true);
        }
        // return them
        return this.dataTypes;
    }

    public async getRegistrations(dataTypes: IGuid[] | string[] | undefined): Promise<Array<LightDataSourceRegistration> | undefined> {
        // construct the guid filter
        const filter: IGuid[] = [];
        if (dataTypes instanceof Array) {
            for (const item of dataTypes) {
                const safeGuid = new SafeGuid(item);
                filter.push(safeGuid);
            }
        }
        // check the cache, if miss, load the cache
        if (!this.allRegistrations) {
            await this.buildCaches(true, false);
        }
        // apply the filter if needed
        if (filter.length > 0) {
            return this.allRegistrations?.filter((reg) => filter.some((f) => f.equals(reg.dataType)));
        }
        // no filter
        return this.allRegistrations;
    }

    public async loadFirstImages(keys: string[]): Promise<LightCorrelationExtendedFieldResult[] | null> {
        if (keys?.length > 0) {
            const result = await this.correlationClient.loadExtendedImages(keys).toPromise();
            if (result) {
                return result;
            }
        }
        return null;
    }

    //#endregion

    //#region Private Methods

    private async initialize(): Promise<void> {
        const client = this.scClientService?.scClient;

        //Prevent initialization if no Correlation feature is enabled
        const correlationFeatures = setFilter(await this.featuresService.getFeaturesAsync(), (x) => !!CorrelationFeatures.all.find((y) => x.equals(y)));
        const hasCorrelationFeaturesEnabled = correlationFeatures.length > 0;

        if (client && hasCorrelationFeaturesEnabled) {
            // add the event def
            client.registerAdditionalEventTypes('CorrelationDataSourcesRefreshedEvent', CorrelationDataSourcesRefreshedEvent);
            // wire up the subscription
            client.eventsReceived$.pipe(untilDestroyed(this)).subscribe((events) => {
                if (events.some((eventArg) => eventArg.event?.eventType === 'CorrelationDataSourcesRefreshedEvent')) {
                    this.flushCaches();
                }
            });
            // wire up the loggedIn authService observable
            this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe((loggedIn) => {
                if (loggedIn) {
                    this.flushCaches();
                } else {
                    this.invalidateCaches();
                }
            });
            // reset cache if language changed to display the correct report name
            this.languageService.languageChanged.pipe(untilDestroyed(this)).subscribe(() => {
                this.flushCaches();
            });
        }
    }

    private flushCaches(): void {
        this.invalidateCaches();
        this.buildCaches().fireAndForget();
    }

    private invalidateCaches(): void {
        // flush the caches
        this.dataTypes = undefined;
        this.allRegistrations = undefined;
    }

    private async buildCaches(reg: boolean = true, dt: boolean = true): Promise<void> {
        // check the cache, if miss, load the cache
        if (!this.allRegistrations && reg) {
            this.allRegistrations = await this.correlationClient.getRegistrations(undefined).toPromise();
        }
        // check if there's a cached value, if miss load it
        if (!this.dataTypes && dt) {
            this.dataTypes = await this.correlationClient.listDataTypes().toPromise();
        }
    }

    //#endregion
}
