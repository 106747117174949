import { Cancellation, CancellationToken } from './CancellationToken';
import { PromiseCompletionSource } from './PromiseCompletionSource';

export class MouseWheelObserver {
  private readonly m_htmlElement: HTMLElement;
  private readonly m_disposeToken: CancellationToken;
  private m_mouseWheelPromise: PromiseCompletionSource<number>;

  private m_listening: boolean = false;

  public get MouseWheel(): Promise<number | Cancellation> {
    if (!this.m_listening) {
      this.m_htmlElement.addEventListener('wheel', this.onMouseWheel, false);
      this.m_listening = true;
    }
    return Promise.race([this.m_mouseWheelPromise.Promise, this.m_disposeToken.Cancellation]);
  }

  public constructor(htmlCanvasElement: HTMLElement) {
    this.m_htmlElement = htmlCanvasElement;

    this.m_disposeToken = new CancellationToken();
    this.m_mouseWheelPromise = new PromiseCompletionSource<number>();
  }

  public dispose(): void {
    if (this.m_listening) {
      this.m_htmlElement.removeEventListener('wheel', this.onMouseWheel);
    }
    this.m_disposeToken.cancel('dispose');
  }

  private readonly onMouseWheel = (mouseEvent: MouseEvent): void => {
    const toResolve = this.m_mouseWheelPromise;
    this.m_mouseWheelPromise = new PromiseCompletionSource<number>();
    toResolve.resolve((<WheelEvent>mouseEvent).deltaY);
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();
  }
}
