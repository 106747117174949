// Autogenerated: v2/help/Classes/ApiIdentityValidationEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:52 AM
import { TimezoneEvent } from './TimezoneEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { IIdentityValidationEvent } from '../Interface/IIdentityValidationEvent';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

export class IdentityValidationEvent extends TimezoneEvent implements IIdentityValidationEvent {
    // #region REST Properties

    // Area
    public get area(): IGuid {
        return this.getFieldGuid('Area');
    }
    public set area(value: IGuid) {
        this.setFieldGuid('Area', value);
    }

    // Cardholder
    public get cardholder(): IGuid {
        return this.getFieldGuid('Cardholder');
    }
    public set cardholder(value: IGuid) {
        this.setFieldGuid('Cardholder', value);
    }

    // Door
    public get door(): IGuid {
        return this.getFieldGuid('Door');
    }
    public set door(value: IGuid) {
        this.setFieldGuid('Door', value);
    }

    // DoorSide
    public get doorSide(): IGuid {
        return this.getFieldGuid('DoorSide');
    }
    public set doorSide(value: IGuid) {
        this.setFieldGuid('DoorSide', value);
    }

    // Unit
    public get unit(): IGuid {
        return this.getFieldGuid('Unit');
    }
    public set unit(value: IGuid) {
        this.setFieldGuid('Unit', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Area', SafeGuid.EMPTY);
        this.setFieldGuid('Cardholder', SafeGuid.EMPTY);
        this.setFieldGuid('Door', SafeGuid.EMPTY);
        this.setFieldGuid('DoorSide', SafeGuid.EMPTY);
        this.setFieldGuid('Unit', SafeGuid.EMPTY);
    }

    // #endregion Methods

}

