/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { RestResponse } from 'RestClient/Connection/RestResponse';
import { RoleManager, ExecuteRequest } from 'RestClient/Client/Managers/RoleManager';
import { Injectable } from '@angular/core';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import { from, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SafeGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { HttpMethod } from '@genetec/web-maps';
import { LoggerService } from '@shared/services/logger/logger.service';
import { WebApiResponse } from '../models/web-api-response';
import { ListResultApi } from '../models/list-result';
import { McClientResponseError } from '../models/errors/mc-client-response-error';
import { McClientNotConnectedError } from '../models/errors/mc-client-not-connected-error';

export const INCIDENT_MANAGER_GUID = SafeGuid.parse('9E58371C-45CD-4557-ABDE-3A4FB9BC217C');

@Injectable()
export class McClient {
    private _scClient: WebAppClient;

    constructor(securityCenterProvider: SecurityCenterClientService, private _logger: LoggerService) {
        this._scClient = securityCenterProvider.scClient;
    }

    public getAll<T>(url: string, searchParams: URLSearchParams | null = null): Observable<T[]> {
        return from(this.fetchAll<T>(url, searchParams ?? new URLSearchParams())).pipe(first());
    }

    public get<T>(url: string, responseType?: 'json', searchParams?: URLSearchParams): Observable<T>;
    public get(url: string, responseType: 'blob', searchParams?: URLSearchParams): Observable<Blob>;
    public get<T>(url: string, responseType: 'blob' | 'json' = 'json', searchParams: URLSearchParams = new URLSearchParams()): Observable<T> {
        const params = searchParams.toString();
        const urlWithQuery = params === '' ? url : `${url}?${params}`;
        return from(this.buildAndExecuteRequestWithType('GET', urlWithQuery, null, responseType)).pipe(
            map((x) => {
                if (!this.isSuccess(x)) {
                    const err = new McClientResponseError(url, HttpMethod.GET, x.statusCode, x.body);
                    this._logger.traceError(err.toLogMessage());
                    throw err;
                } else {
                    return x?.body as T;
                }
            })
        );
    }

    public post(url: string, body: string): Observable<WebApiResponse<null>>;
    public post<T>(url: string, body: string): Observable<WebApiResponse<T> | WebApiResponse<null>> {
        return from(this.buildAndExecuteRequestWithType('POST', url, body, 'text')).pipe(
            map((x) => {
                if (this.isServerError(x)) {
                    const err = new McClientResponseError(url, HttpMethod.POST, x.statusCode, x.body);
                    this._logger.traceError(err.toLogMessage());
                    throw err;
                } else if (x.body) {
                    return new WebApiResponse(x.statusCode, x.body as T);
                } else {
                    return new WebApiResponse<null>(x.statusCode, null);
                }
            }),
            catchError((err) => {
                this._logger.traceError(`POST ${url} failed : ${err}`);
                throw err;
            })
        );
    }

    private async buildAndExecuteRequestWithType(
        method: string,
        url: string,
        content: string | null = null,
        responseType: 'blob' | 'json' | 'text' = 'json'
    ): Promise<IRestResponse> {
        const restObject: RoleManager = await this._scClient.getAsync(RoleManager);

        const request = new ExecuteRequest();
        request.roleType = INCIDENT_MANAGER_GUID;
        request.url = url;
        request.method = method;
        request.headers = new Map<string, string>();
        request.headers.set('Authorization', `Bearer ${this._scClient.rest.authorizationHeader.split(' ')[1]}`);

        if (content) {
            request.content = content;
        }

        const response: RestResponse = await restObject.executeRequestWithResponseTypeAsync('POST', 'executeapirequest', request, responseType);

        if (response?.body?.Exception) {
            /* RestConnection (used by RestObject) does something odd: if the response is not successful (2XX code),
                it'll throw an error of type IRestResponse with an altered status code of 500. This catch block restores the proper code. */
            const statusCodeRegexExp = new RegExp('\\d{3}');
            const errorCodeRegexMatch = statusCodeRegexExp.exec(response.body.Exception)?.[0];
            if (errorCodeRegexMatch) {
                const actualErrorCode = parseInt(statusCodeRegexExp.exec(response.body.Exception)?.[0] ?? '500');
                response.statusCode = actualErrorCode;
                return response;
            } else {
                // The MC WebAPI can't be reached
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                if (response.body.Exception.includes('Unable to connect to the remote server')) {
                    throw new McClientNotConnectedError();
                } else {
                    throw new Error();
                }
            }
        } else {
            return response;
        }
    }

    private async fetchAll<T>(url: string, searchParams: URLSearchParams): Promise<T[]> {
        let allElements = new Array<T>();
        let currentOffset = 0;
        let totalResultCount: number;
        const limit = 100;
        searchParams.set('limit', limit.toString());

        do {
            searchParams.set('offset', currentOffset.toString());
            const response = (await this.buildAndExecuteRequestWithType('GET', `${url}?${searchParams.toString()}`, null)) as RestResponse;
            if (!this.isSuccess(response)) {
                const err = new McClientResponseError(url, HttpMethod.GET, response.statusCode, response.body);
                this._logger.traceError(err.toLogMessage());
                throw err;
            }
            const result = response.body as ListResultApi<T>;
            totalResultCount = result.TotalResultsCount ?? 0;
            allElements = [...allElements, ...result.Results];
            currentOffset += limit;
        } while (currentOffset < totalResultCount);

        return allElements;
    }

    private isSuccess(response: IRestResponse | null): boolean {
        return response != null && response.statusCode >= 200 && response.statusCode <= 299;
    }

    private isServerError(response: IRestResponse | null): boolean {
        return response != null && response.statusCode >= 500;
    }
}
