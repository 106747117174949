<div class="door-state h-100 w-100">
    <span>
        <app-custom-icon
            slot="gen-icon"
            [icon]="doorIcon$ | async"
            [entityId]="doorId$ | async"
            [customIconId]="doorCustomIconId$ | async"
            [customIconState]="doorCustomIconState$ | async"
            [iconFontSizePx]="200"
            [customIconWidthPx]="200"
            [customIconHeightPx]="200"
        ></app-custom-icon>
    </span>
</div>
