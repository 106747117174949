// Autogenerated: v2/help/enumerations/ApiLprUnitType?codegen=ts&showresources=False on 2020-07-23 3:40:06 PM

export class LprUnitType {
    public static None = 'None';
    public static Mobile = 'Mobile';
    public static Fixed = 'Fixed';

    public static readonly values = [
        LprUnitType.None,
        LprUnitType.Mobile,
        LprUnitType.Fixed
    ];
}
