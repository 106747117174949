import { Inject, Injectable } from '@angular/core';
import { EventMonitoringContentTypes } from '@modules/shared/enumerations/event-monitoring-content-types';
import { SIDE_CONTEXT_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { ContentProviderService } from '../content/content-provider.service';
import { SideContextDataService } from '../side-context-data/side-context-data.service';

@Injectable({
    providedIn: 'root',
})
export class UserWatchlistRequestService {
    constructor(@Inject(SIDE_CONTEXT_SERVICE) private sideContextService: SideContextDataService, private contentProviderService: ContentProviderService) {}

    public async toggleShowWatchListAsync(): Promise<void> {
        const currentContent = this.sideContextService.currentContent;
        if (!currentContent?.mainContent.type.equals(EventMonitoringContentTypes.WatchList)) {
            const watchListRequest = new FieldObject();
            watchListRequest.setField('WatchlistRequest', '');
            const content = await this.contentProviderService.getContentFromFieldsAsync(watchListRequest);
            if (content) {
                this.sideContextService.setMainContent(content);
            }
        } else {
            this.sideContextService.clearMainContent();
        }
    }

    public async showWatchlistEditorAsync(): Promise<void> {
        const editWatchListRequest = new FieldObject();
        editWatchListRequest.setField('EditWatchlistRequest', '');
        const content = await this.contentProviderService.getContentFromFieldsAsync(editWatchListRequest);
        if (content) {
            this.sideContextService.pushContent(content);
        }
    }
}
