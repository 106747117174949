<ng-container *ngIf="isContentVisible; else insufficientPrivileges">
    <gen-search-input
        (keyup.enter)="onEnterKeyPress($event)"
        (keydown.enter)="onEnterKeyPress($event)"
        [ngModel]="searchCriteria"
        (ngModelChange)="onSearchCriteriaChange($event)"
        style="padding: 0"
        placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
    >
    </gen-search-input>

    <div class="scroll-section">
        <!-- filtered tree view -->
        <ng-container *ngIf="searchCriteria; else regularTreeView">
            <app-events-option-tree
                [eventsSource]="filteredEvents$ | async"
                (selectionChanged)="syncWithRegularEvents($event)"
                [keepParentExpanded]="true"
            ></app-events-option-tree>
        </ng-container>

        <!-- real tree view -->
        <ng-template #regularTreeView>
            <app-events-option-tree [eventsSource]="events$ | async" (selectionChanged)="saveSelectionToLocalStorage($event)"></app-events-option-tree>
        </ng-template>
    </div>
</ng-container>

<ng-template #insufficientPrivileges>
    <app-insufficient-privileges></app-insufficient-privileges>
</ng-template>
