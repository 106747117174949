import { Injectable, Inject } from '@angular/core';
import { NavigationStart, Params, Router } from '@angular/router';
import { IGuid, SafeGuid } from 'safeguid';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ContentProviderService } from '../content/content-provider.service';
import { COMMANDS_SERVICE, CommandsService, SIDE_CONTEXT_SERVICE } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { SideContextDataService } from '../side-context-data/side-context-data.service';
import { SharedContentFields } from '../../enumerations/shared-content-fields';
import { ContextTypes } from '../../interfaces/plugins/public/context-types';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    public routeChanging$: Observable<void>;
    private navigationContextId: IGuid = SafeGuid.parse('C40A73FB-D994-46A7-B4E1-EABFBB38FC31');

    constructor(
        private router: Router,
        private contentProviderService: ContentProviderService,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        @Inject(SIDE_CONTEXT_SERVICE) private sideContextService: SideContextDataService
    ) {
        this.routeChanging$ = router.events.pipe(
            filter((event) => event instanceof NavigationStart),
            map(() => undefined)
        );
    }

    public getCurrentTask(): IGuid {
        const url = this.router.url;
        return SafeGuid.parse(this.router.parseUrl(url)?.root?.children?.primary?.segments[1]?.path);
    }

    public getCurrentTaskPath(): string {
        const task = this.getCurrentTask().toString();
        const taskIdPos = this.router.url.indexOf(task);
        return this.router.url.substr(0, taskIdPos + task.length);
    }

    public async navigate(navigation: string, keepContext = false, queryParams?: Params): Promise<void> {
        if (navigation !== null) {
            const tokens = toLowerCaseGuids(navigation).split('?');
            const task = tokens[0];

            // Parse the navigation parameters
            const params = new Map<string, string>();
            const routerParams: Params = queryParams ?? {};
            if (tokens.length > 1) {
                const queryString = tokens[1];
                const queries = queryString.split('&');

                queries.forEach((indexQuery: string) => {
                    const indexPair = indexQuery.split('=');
                    const queryKey = decodeURIComponent(indexPair[0]);
                    const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : '');
                    params.set(queryKey, queryValue);
                    routerParams[queryKey] = queryValue;
                });
            }

            // navigate depending on the group name
            if (task === '/sidepane') {
                this.sideContextService.setLoadingMainContent();

                const id = params.get('id');
                if (id) {
                    const content = await this.contentProviderService.getContentAsync(id, this.navigationContextId);
                    if (content) {
                        // push the parameters in the mainContent
                        for (const [k, v] of params.entries()) {
                            content.mainContent.parameters?.setField(k, v);
                        }

                        if (keepContext) {
                            this.sideContextService.pushContent(content);
                        } else {
                            this.sideContextService.setMainContent(content);
                        }
                    }
                }
            } else if (task?.startsWith('/modal')) {
                // modal outlet navigation
                const modalTokens = task.split(':');
                if (modalTokens.length > 1) {
                    const modalPath = modalTokens[1];
                    await this.router.navigate([{ outlets: { modal: modalPath } }], { queryParams: routerParams, skipLocationChange: true });
                    // const currentRoute = this.router.routerState.root;
                    // await this.router.navigate([{ outlets: { modal: modalPath } }], { queryParams: params, skipLocationChange: true, relativeTo: currentRoute });
                }
            } else if (task?.startsWith('/command')) {
                // commands based navigation
                const commandTokens = task.split(':');
                if (commandTokens.length > 1) {
                    const commandId = SafeGuid.parse(commandTokens[1]);
                    this.commandsService.executeCommand(commandId, { type: ContextTypes.Specific, data: routerParams });
                }
            } else if (task?.startsWith('/event')) {
                // events based navigation
                const eventId = params.get('id');
                if (eventId) {
                    const eventField = new FieldObject();
                    eventField.setFieldGuid(SharedContentFields.EventId, SafeGuid.parse(eventId));

                    const content = await this.contentProviderService.getContentFromFieldsAsync(eventField);
                    if (content && !content.id.isEmpty()) {
                        if (keepContext) {
                            this.sideContextService.pushContent(content);
                        } else {
                            this.sideContextService.setMainContent(content);
                        }
                    }
                }
            } else if (task?.startsWith('/datum')) {
                // datum based navigation
                const sourceId = params.get('id');
                const typeId = params.get('type');
                const roleId = params.get('role');
                if (sourceId && typeId && roleId) {
                    const fields = new FieldObject();
                    fields.setField('SourceId', sourceId);
                    fields.setField('DataType', typeId);
                    fields.setField('SourceRole', roleId);
                    const content = await this.contentProviderService.getContentFromFieldsAsync(fields, this.navigationContextId);
                    if (content) {
                        if (keepContext) {
                            this.sideContextService.pushContent(content);
                        } else {
                            this.sideContextService.setMainContent(content);
                        }
                    }
                }
            } else {
                // navigate!
                await this.router.navigate([task], { queryParams: routerParams });
            }
        }
    }
}
