<gen-melted-modal
    id="changePasswordModal"
    data-cy="changePasswordModal"
    genTitle="{{ 'STE_BUTTON_CHANGEPASSWORD' | translate }}"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_BUTTON_SAVE' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnDefaultAction]="onSave"
    [genIsDefaultActionDisabled]="!(isPasswordAccepted && isPasswordsMatching)"
    appFocusModalWhenOpen
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <div class="modal-length">
            <gen-password-input *ngIf="!isExpired" data-cy="oldPassword" [(ngModel)]="oldPassword" (ngModelChange)="onOldPassword($event)">
                <gen-label>{{ 'STE_LABEL_OLD_PASSWORD' | translate: { Default: 'Old password' } }}</gen-label>
            </gen-password-input>
            <gen-text data-cy="invalidCred" flavor="error" *ngIf="isInvalidCredentials">
                <span class="ha-left mt-2"> {{ 'STE_MESSAGE_ERROR_INVALIDCREDENTIALS' | translate }} </span>
            </gen-text>

            <gen-password-input
                [maxlength]="32"
                data-cy="newPassword"
                [(ngModel)]="newPassword"
                (ngModelChange)="onNewPassword($event)"
                [required]="isNewPasswordBlurredOrDirty"
                (blur)="onNewPasswordBlurred()"
                class="mt-2"
            >
                <gen-label>{{ 'STE_LABEL_NEW_PASSWORD' | translate: { Default: 'New password' } }}</gen-label>
                <gen-message data-cy="complexityLabel" [severity]="complexityMessageSeverity">{{ isNewPasswordBlurredOrDirty ? complexityMessage : '' }}</gen-message>
            </gen-password-input>

            <gen-password-input
                [maxlength]="32"
                data-cy="confirmedPassword"
                [ngModel]="confirmedPassword"
                (ngModelChange)="onConfirmedPassword($event)"
                [required]="isConfirmPasswordBlurredOrDirty"
                (blur)="onConfirmPasswordBlurred()"
                class="mt-2"
            >
                <gen-label>{{ 'STE_LABEL_CONFIRM_PASSWORD' | translate: { Default: 'Confirm password' } }}</gen-label>
            </gen-password-input>

            <div *ngIf="minCharNum > 0" class="mt-2 text-alignement">
                <gen-icon data-cy="match" *ngIf="isCharNumMatch; else noMatch" icon="checkmark" size="small" genColor="#6AC66A"> </gen-icon>
                <gen-text flavor="secondary" class="ml-1"> {{ 'STE_LABEL_FORMAT_X_OR_MORE_CHARS_NEEDED_PASSWORD' | translate | stringFormat: [minCharNum] }} </gen-text>
            </div>
            <div *ngIf="minLowerCase > 0" class="text-alignement">
                <gen-icon data-cy="match" *ngIf="isLowerCaseMatch; else noMatch" icon="checkmark" size="small" genColor="#6AC66A"> </gen-icon>
                <gen-text flavor="secondary" class="ml-1"> {{ 'STE_LABEL_FORMAT_X_OR_MORE_LOWER_CHARS_NEEDED_PASSWORD' | translate | stringFormat: [minLowerCase] }}</gen-text>
            </div>
            <div *ngIf="minUpperCase > 0" class="text-alignement">
                <gen-icon data-cy="match" *ngIf="isUpperCaseMatch; else noMatch" icon="checkmark" size="small" genColor="#6AC66A"> </gen-icon>
                <gen-text flavor="secondary" class="ml-1"> {{ 'STE_LABEL_FORMAT_X_OR_MORE_UPPER_CHARS_NEEDED_PASSWORD' | translate | stringFormat: [minUpperCase] }}</gen-text>
            </div>
            <div *ngIf="minSpecialChar > 0" class="text-alignement">
                <gen-icon data-cy="match" *ngIf="isSpecialCharMatch; else noMatch" icon="checkmark" size="small" genColor="#6AC66A"> </gen-icon>
                <gen-text flavor="secondary" class="ml-1"> {{ 'STE_LABEL_FORMAT_X_OR_MORE_SPECIAL_CHARS_NEEDED_PASSWORD' | translate | stringFormat: [minSpecialChar] }}</gen-text>
            </div>
            <div *ngIf="minNumericalChar > 0" class="text-alignement">
                <gen-icon data-cy="match" *ngIf="isNumericalCharMatch; else noMatch" icon="checkmark" size="small" genColor="#6AC66A"> </gen-icon>
                <gen-text flavor="secondary" class="ml-1">
                    {{ 'STE_LABEL_FORMAT_X_OR_MORE_NUMERICAL_CHARS_NEEDED_PASSWORD' | translate | stringFormat: [minNumericalChar] }}</gen-text
                >
            </div>
            <div *ngIf="confirmedPassword.length > 0" class="text-alignement">
                <gen-icon data-cy="match" *ngIf="isPasswordsMatching; else noMatch" icon="checkmark" size="small" genColor="#6AC66A"> </gen-icon>
                <gen-text flavor="secondary" class="ml-1"> {{ 'STE_LABEL_PASSWORD_MUST_MATCH' | translate }}</gen-text>
            </div>
            <gen-text data-cy="passCannotMatchUsername" flavor="error" *ngIf="isPasswordEqualToUserName">
                <span class="ha-left mt-2"> {{ 'STE_LABEL_PASSWORD_CANNOT_MATCH_USERNAME' | translate }} </span>
            </gen-text>
            <gen-text data-cy="oldPassMatchNew" flavor="error" *ngIf="isOldPasswordEqualToNew">
                <span class="ha-left mt-2"> {{ 'STE_LABEL_PASSWORD_CANNOT_MATCH_OLD_PASSWORD' | translate }} </span>
            </gen-text>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>

<ng-template #noMatch>
    <gen-icon data-cy="noMatch" icon="close" size="small" genColor="#FF695C"> </gen-icon>
</ng-template>
