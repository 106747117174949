import { Directive, HostListener, ElementRef, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { WINDOW } from '@utilities/common-helper';
import { coerceBooleanProperty } from '../../utilities/coerceBooleanProperty';

// Directive that allows an element to be resized
@Directive({
    selector: '[appResizeElement]',
})
export class ResizeElementDirective implements OnInit, OnDestroy {
    /**
     * Whether the element is resizable or not
     */
    @Input()
    public get appResizeElement(): boolean | '' {
        return this.isResizable;
    }
    public set appResizeElement(value: boolean | '') {
        this.isResizable = coerceBooleanProperty(value);
    }

    /**
     * An array that contains the sides that will be resizable.
     *
     * _The default is: ['top', 'left', 'right', 'bottom']_
     */
    @Input() public appResizableSides = ['top', 'left', 'right', 'bottom'];

    private isResizable = false;
    private nodes: HTMLElement[] = [];
    private limit = 50;
    private data?: { x: number; y: number; rect: ClientRect; direction: string };

    constructor(public element: ElementRef<HTMLElement>, @Inject(WINDOW) private window: Window) {
        this.mousemove = this.mousemove.bind(this);
        this.mouseup = this.mouseup.bind(this);
    }

    @HostListener('mousedown', ['$event'])
    public mousedown(e: MouseEvent): void {
        const htmlElement: HTMLElement | null = e?.target as HTMLElement;
        if (htmlElement?.classList.contains('border') && this.appResizeElement) {
            const rect = this.element.nativeElement.getBoundingClientRect();
            this.data = {
                x: e.clientX,
                y: e.clientY,
                rect,
                direction: /border-([^ ]+)/.exec(htmlElement.className)?.[1] ?? '',
            };
            e.preventDefault();
        } else {
            delete this.data;
        }
    }

    /* eslint-disable @typescript-eslint/unbound-method */

    ngOnInit() {
        this.appResizableSides.forEach(this.createNode.bind(this));
        this.element.nativeElement.classList.add('resize');
        this.window.addEventListener('mousemove', this.mousemove);
        this.window.addEventListener('mouseup', this.mouseup);
    }

    ngOnDestroy() {
        this.nodes.forEach((n) => n.remove());

        this.window.removeEventListener('mousemove', this.mousemove);
        this.window.removeEventListener('mouseup', this.mouseup);
    }

    /* eslint-enable @typescript-eslint/unbound-method */

    public mousemove(event: MouseEvent): void {
        if (this.data) {
            const { height, width, top, left } = this.data.rect;
            const offsetY = this.data.y - event.clientY;
            const offsetX = this.data.x - event.clientX;
            const stylesDictionary: { [key: string]: number } = {};

            switch (this.data.direction) {
                case 'top':
                    stylesDictionary.height = height + offsetY;
                    stylesDictionary.top = top - offsetY;
                    break;
                case 'bottom':
                    stylesDictionary.height = height - offsetY;
                    break;
                case 'left':
                    stylesDictionary.width = width + offsetX;
                    stylesDictionary.left = left - offsetX;
                    break;
                case 'right':
                    stylesDictionary.width = width - offsetX;
            }

            if (stylesDictionary.width < this.limit) {
                delete stylesDictionary.width;
                delete stylesDictionary.left;
            }

            if (stylesDictionary.height < this.limit) {
                delete stylesDictionary.height;
                delete stylesDictionary.top;
            }

            Object.entries(stylesDictionary).forEach(([name, value]) => {
                this.element.nativeElement.style.setProperty(name, `${value}px`, 'important');
            });
        }
    }

    public createNode(side: string): void {
        const node = document.createElement('div');
        node.classList.add('border-' + side, 'border');
        this.element.nativeElement.appendChild(node);
        this.nodes.push(node);
    }

    public mouseup(): void {
        delete this.data;
    }
}
