import { ILogger } from '../utils/logger';
import { ITileSizeObserver, ITileSizeObserverBuilder } from './TileSizeProvider';
import { StreamUsage } from './WebSocket/Api/CreateStream';
import { DefaultStreamUsage } from './WebSocket/DefaultStreamUsage';
import { LiteEvent, ILiteEvent } from '../utils/liteEvents';
import { Resolution } from '../utils/Resolution';

/**
 * @beta
* */
export interface IStreamUsageSelectorBuilder {
  build(defaultStreamUsage: DefaultStreamUsage): IStreamUsageSelector;
}

/**
 * @beta
* */
export interface IStreamUsageSelector {
  readonly onRequiresStreamUsageUpdate: ILiteEvent<StreamUsage>;
  readonly streamUsage: StreamUsage;

  dispose(): void;
}

export class StreamUsageSelectorBuilder implements IStreamUsageSelectorBuilder {
  private readonly m_logger: ILogger;

  private readonly m_tileSizeProviderBuilder: ITileSizeObserverBuilder;

  constructor(logger: ILogger, tileSizeProviderBuilder: ITileSizeObserverBuilder) {
    this.m_logger = logger;
    this.m_tileSizeProviderBuilder = tileSizeProviderBuilder;
  }

  public build(defaultStreamUsage: DefaultStreamUsage): IStreamUsageSelector {
    return new StreamUsageSelector(this.m_logger, defaultStreamUsage, this.m_tileSizeProviderBuilder.build());
  }
}

export class StreamUsageSelector implements IStreamUsageSelector {
  private readonly m_logger: ILogger;

  private readonly m_tileSizeProvider: ITileSizeObserver;

  private readonly m_defaultStreamUsage: DefaultStreamUsage;

  private readonly m_requiresStreamUsageUpdateEvent: LiteEvent<StreamUsage>;

  private m_lastStreamUsageRaised: StreamUsage | undefined;

  private m_isDisposed: boolean = false;

  public get onRequiresStreamUsageUpdate(): ILiteEvent<StreamUsage> {
    this.throwIfDisposed();
    return this.m_requiresStreamUsageUpdateEvent;
  }

  public get streamUsage(): StreamUsage {
    this.throwIfDisposed();
    return this.determineStreamUsage();
  }

  constructor(logger: ILogger, defaultStreamUsage: DefaultStreamUsage, tileSizeProvider: ITileSizeObserver) {
    this.m_logger = logger.subLogger('StreamUsageSelector');
    this.m_logger.info?.trace('Default stream usage: ', defaultStreamUsage);
    this.m_requiresStreamUsageUpdateEvent = new LiteEvent<StreamUsage>();
    this.m_tileSizeProvider = tileSizeProvider;
    this.m_defaultStreamUsage = defaultStreamUsage;

    tileSizeProvider.tileResizeEvent.register(this.onTileResized);
  }

  public dispose() {
    this.m_tileSizeProvider.tileResizeEvent.unregister(this.onTileResized);
    this.m_tileSizeProvider.dispose();
    this.m_isDisposed = true;
  }

  private determineStreamUsage(): StreamUsage {
    return this.m_defaultStreamUsage.getStreamUsage(this.m_tileSizeProvider.tileResolution);
  }

  private readonly onTileResized = (resolution: Resolution) => {
    const streamUsage = this.m_defaultStreamUsage.getStreamUsage(resolution);
    if (streamUsage !== this.m_lastStreamUsageRaised) {
      this.m_logger.info?.trace(`${this.m_lastStreamUsageRaised}->${streamUsage} res: ${resolution.toString()}`);
      this.m_lastStreamUsageRaised = streamUsage;
      this.m_requiresStreamUsageUpdateEvent.trigger(streamUsage);
    }
  }

  private throwIfDisposed() {
    if (this.m_isDisposed) {
      throw new Error('StreamUsageSelector is disposed');
    }
  }
}
