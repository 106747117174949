// Autogenerated: v2/help/Entities/ApiDoorTemplate?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity, EntityFields } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class DoorTemplateEntityFields extends EntityFields {
    public static doorForcedBehaviorField = 'DoorForcedBehavior';
    public static doorHeldBehaviorField = 'DoorHeldBehavior';
    public static doorHeldOpenDelayField = 'DoorHeldOpenDelay';
    public static doorRelockDelayField = 'DoorRelockDelay';
    public static doorRelockOnCloseField = 'DoorRelockOnClose';
    public static enforceVisitorEscortRulesAlphaSideField = 'EnforceVisitorEscortRulesAlphaSide';
    public static enforceVisitorEscortRulesBetaSideField = 'EnforceVisitorEscortRulesBetaSide';
    public static extendedEntryTimeField = 'ExtendedEntryTime';
    public static extendedGrantTimeField = 'ExtendedGrantTime';
    public static grantOnRexField = 'GrantOnRex';
    public static interfaceModuleDeviceDefinitionField = 'InterfaceModuleDeviceDefinition';
    public static isAccessRulesEnabledField = 'IsAccessRulesEnabled';
    public static isDefaultField = 'IsDefault';
    public static isDoorPropertiesEnabledField = 'IsDoorPropertiesEnabled';
    public static isHardwareMappingEnabledField = 'IsHardwareMappingEnabled';
    public static isHardwareSettingsEnabledField = 'IsHardwareSettingsEnabled';
    public static maskAccessWhenUnlockedByScheduleField = 'MaskAccessWhenUnlockedBySchedule';
    public static maskDoorOpenTooLongWhenUnlockedByScheduleField = 'MaskDoorOpenTooLongWhenUnlockedBySchedule';
    public static maximumDelayBetweenSwipesField = 'MaximumDelayBetweenSwipes';
    public static normalEntryTimeField = 'NormalEntryTime';
    public static normalGrantTimeField = 'NormalGrantTime';
    public static rexHoldOffAfterClosedDelayField = 'RexHoldOffAfterClosedDelay';
    public static rexHoldOffAfterGrantDelayField = 'RexHoldOffAfterGrantDelay';
    public static rexHoldoffWhileOpenedField = 'RexHoldoffWhileOpened';
}

// #endregion Fields

// #region Inner classes

export interface ICardAndPinSetting extends IFieldObject {
    cardAndPinCoverage: IGuid;
    cardAndPinTimeout: number;
    side: string;
    readerMode: string;
}

export interface IAccessRulesConfig extends IFieldObject {
    accessRule: IGuid;
    side: string;
}

export interface IAccessPointsInfo extends IFieldObject {
    deviceId: string;
    side: string;
    type: string;
}

// #endregion Inner classes

export interface IDoorTemplateEntity extends IEntity {
    // #region REST Properties

    doorForcedBehavior: string;

    doorHeldBehavior: string;

    doorHeldOpenDelay: number;

    doorRelockDelay: number;

    doorRelockOnClose: boolean;

    enforceVisitorEscortRulesAlphaSide: boolean;

    enforceVisitorEscortRulesBetaSide: boolean;

    extendedEntryTime: number;

    extendedGrantTime: number;

    grantOnRex: boolean;

    interfaceModuleDeviceDefinition: IGuid;

    isAccessRulesEnabled: boolean;

    isDefault: boolean;

    isDoorPropertiesEnabled: boolean;

    isHardwareMappingEnabled: boolean;

    isHardwareSettingsEnabled: boolean;

    maskAccessWhenUnlockedBySchedule: boolean;

    maskDoorOpenTooLongWhenUnlockedBySchedule: boolean;

    maximumDelayBetweenSwipes: number;

    normalEntryTime: number;

    normalGrantTime: number;

    rexHoldOffAfterClosedDelay: number;

    rexHoldOffAfterGrantDelay: number;

    rexHoldoffWhileOpened: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessPointsInfo> | null>;
    getAccessRulesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessRulesConfig> | null>;
    getAppliedDoorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    applyAsync(doorGuid: IGuid, unitGuid: IGuid, interfaceGuid: IGuid, transaction?: ITransaction): Promise<IRestResponse | null>;
    getCardAndPinSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICardAndPinSetting> | null>;

    // #endregion REST Methods

}

