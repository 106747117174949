<ng-container *ngIf="isContentVisible; else insufficientPrivileges">
    <gen-list [selectionType]="ListSelectionType.Multiple">
        <gen-list-header>
            <gen-label>{{ 'STE_LABEL_TIMELINELAYERS' | translate }}</gen-label>
        </gen-list-header>
        <gen-list-item *ngFor="let provider of providers | sortBy: 'asc':'text'" (selectedChange)="onSelectedItemsChange(provider, $event.detail)" [selected]="provider.isChecked">
            <gen-item>
                <gen-icon [icon]="provider.icon"></gen-icon>
                <gen-text>{{ provider.text }}</gen-text>
            </gen-item>
        </gen-list-item>
    </gen-list>
</ng-container>

<ng-template #insufficientPrivileges>
    <app-insufficient-privileges></app-insufficient-privileges>
</ng-template>

<ng-container *ngIf="hasTileHeaderFeatureFlag">
    <gen-separator class="pt-3 pb-3"></gen-separator>
    <app-tiles-options></app-tiles-options>
</ng-container>
