// Autogenerated: v2/help/Entities/ApiOmnicastFederationRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-15 12:15:59 PM
import { FederationBaseRoleEntity } from './FederationBaseRoleEntity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IOmnicastFederationRoleEntity, OmnicastFederationRoleEntityFields } from '../../Interface/IOmnicastFederationRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IFederationEvent } from '../../Interface/ISecurityCenterFederationRoleEntity';
import { FederationEvent } from './SecurityCenterFederationRoleEntity';
import { IFieldObject } from '../../Interface/IFieldObject';

export class OmnicastFederationRoleEntity extends FederationBaseRoleEntity implements IOmnicastFederationRoleEntity {
    // #region REST Properties

    // DefaultStreamUsage
    public get defaultStreamUsage(): string {
        return this.getField<string>(OmnicastFederationRoleEntityFields.defaultStreamUsageField);
    }
    public set defaultStreamUsage(value: string) {
        this.setField<string>(OmnicastFederationRoleEntityFields.defaultStreamUsageField, value);
    }

    // FederateAlarms
    public get federateAlarms(): boolean {
        return this.getField<boolean>(OmnicastFederationRoleEntityFields.federateAlarmsField);
    }
    public set federateAlarms(value: boolean) {
        this.setField<boolean>(OmnicastFederationRoleEntityFields.federateAlarmsField, value);
    }

    // Version
    public get version(): string {
        return this.getField<string>(OmnicastFederationRoleEntityFields.versionField);
    }
    public set version(value: string) {
        this.setField<string>(OmnicastFederationRoleEntityFields.versionField, value);
    }

    // VideoRedirectionMode
    public get videoRedirectionMode(): boolean {
        return this.getField<boolean>(OmnicastFederationRoleEntityFields.videoRedirectionModeField);
    }
    public set videoRedirectionMode(value: boolean) {
        this.setField<boolean>(OmnicastFederationRoleEntityFields.videoRedirectionModeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getEventsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IFederationEvent> | null> {
        return this.getFieldObjectRelationAsync<FederationEvent, IFederationEvent>(FederationEvent, 'Events', 'Id', true, true, ValidFlags.None, transaction);
    }

    public async getRedirectorsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IFieldObject>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IFieldObject>;
                return new Promise<Array<IFieldObject>>(function(resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IFieldObject>>(function(resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<Array<IFieldObject>>('Redirectors', 'GET', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

