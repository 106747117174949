// Autogenerated: v2/help/enumerations/roletypeids?codegen=ts on 2022-12-21 2:23:44 PM
import { SafeGuid } from 'safeguid';

export class RoleGuids {
    public static CloudArchiveRole = SafeGuid.parse('83048a6e-40e5-481d-bdc9-e2a754e7bf26');
    public static RestRole = SafeGuid.parse('94477a58-8d8d-44a6-b5a7-52827bf38f23');
    public static ZoneManagement = SafeGuid.parse('f7e2737f-c84c-46a3-93fb-29bd4dec60dc');
    public static WebSdk = SafeGuid.parse('b1817f00-672b-4708-bf08-6a8e2e898cdf');
    public static IngestionRole = SafeGuid.parse('d4376829-44d5-4bb9-9830-a111ff4b128b');
    public static ReverseTunnelClientRole = SafeGuid.parse('85714724-74cd-4a91-a7de-90a099939f67');
    public static AuthRole = SafeGuid.parse('9b7c06f7-ed2e-46f1-b486-54feb38b5452');
    public static PointOfSale = SafeGuid.parse('0ffef791-2583-4c7e-8107-e0e6c741d7cc');
    public static Federation = SafeGuid.parse('fe3380c2-3c2c-4997-9eb1-143c8947a0fa');
    public static AccessManager = SafeGuid.parse('c04c211f-384b-4529-91f3-7129ffa100e0');
    public static AuxiliaryArchiver = SafeGuid.parse('f0ccf0cf-476e-41fd-8218-30cd4dffbc2a');
    public static IntrusionDetection = SafeGuid.parse('e5158ced-6d26-4a60-b788-59fb1aec3c9d');
    public static LicensePlateManager = SafeGuid.parse('1cb17dad-049d-491d-8406-b1c9c2eeb395');
    public static DirectoryFailover = SafeGuid.parse('2303fc88-ca03-4f3e-b8de-652d8b8a4b35');
    public static AutomationRole = SafeGuid.parse('c0e1aa70-1b23-4170-a0a1-03d80877b5d5');
    public static MediaGateway = SafeGuid.parse('cee443eb-47fd-453d-aaa8-08d0781c1e62');
    public static MobileRole = SafeGuid.parse('4f836034-5133-4e28-9ea5-38261d842435');
    public static SimulationRole = SafeGuid.parse('c8d0c1da-5dc8-481e-a369-9872afbdf9fc');
    public static UnitAssistantRole = SafeGuid.parse('b31b061a-273a-45ed-bb8f-694b5a97a39a');
    public static BwcManager = SafeGuid.parse('87f13c8b-240a-485d-abc0-0826b8d0aad6');
    public static Plugin = SafeGuid.parse('276ed751-bb08-4755-9a59-82b6d855d9ce');
    public static PrivacyProtector = SafeGuid.parse('8da59415-3552-45b5-abdf-9df0ed280886');
    public static TaskAutomation = SafeGuid.parse('276496e6-e600-41f7-bbfe-ccdcda0b053a');
    public static MapManagerRole = SafeGuid.parse('5908fc43-64e9-439b-a35f-22611f7c2598');
    public static ReverseTunnelServer = SafeGuid.parse('abb13073-d718-4287-a640-323f0e31f787');
    public static StreamManagement = SafeGuid.parse('c4dba4b9-8c5c-4b86-84a2-0394829a5a82');
    public static SharingManager = SafeGuid.parse('be1ce618-e55e-45b7-8e42-499f0e88bcce');
    public static MobileCredentialManagerRole = SafeGuid.parse('6331f060-d93c-4dd2-9adc-dba72c4eac59');
    public static HealthMonitoring = SafeGuid.parse('6adeaf49-2843-4695-9430-c20d2884c8cc');
    public static ActiveDirectory = SafeGuid.parse('6b9722d5-6ca8-4104-b2b6-a40dd46b7616');

    public static readonly values = [
        RoleGuids.CloudArchiveRole,
        RoleGuids.RestRole,
        RoleGuids.ZoneManagement,
        RoleGuids.WebSdk,
        RoleGuids.IngestionRole,
        RoleGuids.ReverseTunnelClientRole,
        RoleGuids.AuthRole,
        RoleGuids.PointOfSale,
        RoleGuids.Federation,
        RoleGuids.AccessManager,
        RoleGuids.AuxiliaryArchiver,
        RoleGuids.IntrusionDetection,
        RoleGuids.LicensePlateManager,
        RoleGuids.DirectoryFailover,
        RoleGuids.AutomationRole,
        RoleGuids.MediaGateway,
        RoleGuids.MobileRole,
        RoleGuids.SimulationRole,
        RoleGuids.UnitAssistantRole,
        RoleGuids.BwcManager,
        RoleGuids.Plugin,
        RoleGuids.PrivacyProtector,
        RoleGuids.TaskAutomation,
        RoleGuids.MapManagerRole,
        RoleGuids.ReverseTunnelServer,
        RoleGuids.StreamManagement,
        RoleGuids.SharingManager,
        RoleGuids.MobileCredentialManagerRole,
        RoleGuids.HealthMonitoring,
        RoleGuids.ActiveDirectory
    ];
}
