<gen-grid>
    <!--    Title & Menu Button-->
    <gen-grid-item class="gen-flex">
        <div class="gen-col-6 ha-left">
            <gen-text [flavor]="Flavor.Text.Secondary">{{ 'STE_LABEL_LATEST_ACTIVITY' | translate }}</gen-text>
        </div>
        <div class="gen-col-6 ha-right">
            <gen-button [flavor]="ButtonFlavor.Flat" id="toggle-filter-popup-{{ componentId }}" (click)="toggleEventFilterSelection()" appTooltip="Filter Events">
                <gen-icon [icon]="Icon.Filter"></gen-icon>
            </gen-button>
        </div>
    </gen-grid-item>
    <!-- "   Start-->
    <div class="container" *ngIf="filteredIncidentEvents$ | async as filteredIncidentEvents; else loading">
        <div *ngIf="filteredIncidentEvents.length === 0" class="ml-2 mt-1">
            <gen-text [flavor]="TextFlavor.Normal">{{ 'STE_MESSSAGE_NO_FILTER' | translate }}</gen-text>
        </div>
        <ng-container *ngFor="let activityEvent of filteredIncidentEvents; trackBy: trackById">
            <div *ngIf="activityEvent.textDisplay">
                <div class="mt-2 mb-2">
                    <gen-text [flavor]="TextFlavor.Secondary">
                        <u> {{ activityEvent.delimiter }}</u>
                    </gen-text>
                </div>
                <div class="activity-grid">
                    <div class="activity-icon">
                        <gen-icon [icon]="activityEvent.category.icon" [size]="IconSize.Medium"></gen-icon>
                    </div>
                    <div class="activity-category">
                        <gen-text [flavor]="TextFlavor.Secondary">{{ activityEvent.category.name }} </gen-text>
                    </div>
                    <div class="activity-time">
                        <gen-text [flavor]="TextFlavor.Secondary">
                            {{
                                activityEvent.isSameDay
                                    ? (activityEvent.processTimeUtc | dateFormatWithTimezone: 'h:mm:ss A' | async)
                                    : (activityEvent.processTimeUtc | dateFormatWithTimezone: 'MMM d, y h:mm:ss A' | async)
                            }}
                        </gen-text>
                    </div>
                    <div class="activity-text">
                        <gen-text [flavor]="TextFlavor.Normal" *ngIf="!activityEvent.isExpanded">
                            {{ activityEvent.textDisplay.length > maxTextLength ? truncate(activityEvent.textDisplay) : activityEvent.textDisplay }}
                            <gen-button
                                *ngIf="activityEvent.textDisplay.length > maxTextLength"
                                [flavor]="ButtonFlavor.Flat"
                                (click)="activityEvent.isExpanded = true"
                                appTooltip="Show More"
                            >
                                <gen-icon [icon]="MeltedIcon.ArrowDown"></gen-icon>
                            </gen-button>
                        </gen-text>
                        <gen-text [flavor]="TextFlavor.Normal" *ngIf="activityEvent.isExpanded"
                            >{{ activityEvent.textDisplay }}
                            <gen-button [flavor]="ButtonFlavor.Flat" (click)="activityEvent.isExpanded = false" appTooltip="Show Less">
                                <gen-icon [icon]="MeltedIcon.ArrowUp"></gen-icon>
                            </gen-button>
                        </gen-text>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #loading>
        <gen-grid-item class="pl-4 pr-4 ha-center gen-flex gen-flex-column">
            <gen-spinner [size]="SpinnerSize.Large" class="ha-center va-center">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        </gen-grid-item>
    </ng-template>
</gen-grid>

<gen-melted-modal
    #filterModal
    id="activityEventTypesFilterModal"
    genTitle="{{ 'STE_TITLE_SELECT_ACTIVITY_EVENT_TYPE' | translate }}"
    [genIsBlocking]="false"
    genAlternativeActionText="{{ 'STE_BUTTON_CLOSE' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_APPLY' | translate }}"
    [genOnDefaultAction]="onEventFilterSelectionSaved"
    [genOnAlternativeAction]="onEventFilterSelectionCancelled"
>
    <gen-text [flavor]="TextFlavor.Secondary"></gen-text>
    <gen-melted-modal-body>
        <div class="available-events-types-list-wrapper">
            <gen-list [selectionType]="ListSelectionType.Multiple">
                <gen-list-item
                    *ngFor="let availableEventType of availableEventTypes"
                    (selectedChange)="eventFilterChange(availableEventType, $event.detail)"
                    [selected]="availableEventType.isChecked"
                >
                    <gen-item>
                        <gen-icon [icon]="availableEventType.icon"></gen-icon>
                        <gen-text>{{ availableEventType.text }}</gen-text>
                    </gen-item>
                </gen-list-item>
            </gen-list>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
