import { SafeGuid } from 'safeguid';
import { Constants } from '../../utilities/constants';

export const KnownContentContext = Constants({
    Dashboards: SafeGuid.parse('C2124CB8-65E3-48B9-ACBF-10C7A34C274D'),
    Navigation: SafeGuid.parse('C40A73FB-D994-46A7-B4E1-EABFBB38FC31'),
    Reports: SafeGuid.parse('3134F869-9376-4618-B77D-F9E5E29B1B3C'),
    Tiles: SafeGuid.parse('DB8C7286-07E1-41F6-B54B-037DF302C0F9'),
    Map: SafeGuid.parse('67B6B6F3-AA00-4B65-B57D-F7B77F4C7791'),
    Commmands: SafeGuid.parse('B95245EC-1283-41DB-9116-AD6B6531B210'),
    Investigate: SafeGuid.parse('EE14C544-8E6E-4626-8737-55B2A7AF9B49'),
});
