import { OnDestroy, ViewChild } from '@angular/core';
import { Component, Injector } from '@angular/core';
import { GenMeltedModalComponent, GenModalService, MeltedModalAction } from '@genetec/gelato-angular';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-send-message',
    templateUrl: './send-message.component.html',
    styleUrls: ['./send-message.component.scss'],
})
export class SendMessageComponent extends ConnectionAwareModalComponent implements OnDestroy {
    private static onDestroy: Subject<string> = new Subject();

    @ViewChild(GenMeltedModalComponent) public genModalComponent!: GenMeltedModalComponent;

    public title = '';
    public actionText = 'OK';
    public cancelText: string | undefined;
    public message = '';
    public isValid = false;
    public closedHandler: ((value: boolean, message: string) => void) | undefined = undefined;

    constructor(protected authService: AuthService, injector: Injector, private translateService: TranslateService, trackingService: TrackingService) {
        super(authService, injector, trackingService);
    }

    public static show(
        modalService: GenModalService,
        title: string,
        message: string,
        closedHandler: (value: boolean, message: string) => void,
        actionText: string | undefined = undefined,
        cancelText: string | undefined = undefined
    ): void {
        const component = modalService.open(SendMessageComponent, undefined);
        if (component) {
            component.title = title;
            component.message = message;
            component.closedHandler = closedHandler;
            if (actionText) {
                component.actionText = actionText;
            }
            if (cancelText) {
                component.cancelText = cancelText;
            }
            component.genModalAction.pipe(takeUntil(SendMessageComponent.onDestroy)).subscribe((modalAction: MeltedModalAction) => {
                if (closedHandler) {
                    if (modalAction === MeltedModalAction.Default) {
                        closedHandler(true, component.message);
                    } else {
                        closedHandler(false, '');
                    }
                }
            });
        }
    }

    /**
     * TODO: MB make sure static subjects work as well
     */
    ngOnDestroy() {
        SendMessageComponent.onDestroy.next();
        SendMessageComponent.onDestroy.complete();

        super.ngOnDestroy();
    }

    public onChange(newValue: string): void {
        if (this.message) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
    }
}
