// Autogenerated: v2/help/Entities/ApiLocatable?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:16 PM
import { Entity } from './Entity';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { ILocatableEntity, LocatableEntityFields } from '../Interface/ILocatableEntity';

export class LocatableEntity extends Entity implements ILocatableEntity {
    // #region REST Properties

    // IsPredefinedLocation
    public get isPredefinedLocation(): boolean {
        return this.getField<boolean>(LocatableEntityFields.isPredefinedLocationField);
    }

    // Latitude
    public get latitude(): number {
        return this.getField<number>(LocatableEntityFields.latitudeField, ValidFlags.EntityStatus);
    }
    public set latitude(value: number) {
        this.setField<number>(LocatableEntityFields.latitudeField, value);
    }

    // Longitude
    public get longitude(): number {
        return this.getField<number>(LocatableEntityFields.longitudeField, ValidFlags.EntityStatus);
    }
    public set longitude(value: number) {
        this.setField<number>(LocatableEntityFields.longitudeField, value);
    }

    // TimeZoneId
    public get timeZoneId(): string {
        return this.getField<string>(LocatableEntityFields.timeZoneIdField);
    }
    public set timeZoneId(value: string) {
        this.setField<string>(LocatableEntityFields.timeZoneIdField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor(entityType: string) {
        super(entityType);
    }

    // #endregion Constructor

}

