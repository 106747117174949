import { KeyValue } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { groupBy } from 'lodash-es';

@Pipe({
    name: 'groupBy',
})
@Injectable()
export class GroupBy implements PipeTransform {
    public transform<T extends object>(list: T[], field: keyof T): Array<KeyValue<string, T[]>> {
        const keyValues: Array<KeyValue<string, T[]>> = [];
        const group = groupBy(list, field);
        Object.entries(group).forEach(([key, value]) => {
            keyValues.push(this.createKeyValue(key, value));
        });
        return keyValues;
    }

    private createKeyValue<T>(key: string, value: T[]): KeyValue<string, T[]> {
        return { key, value };
    }
}
