import { SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2022 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const LprContentTypes = Constants({
    HitEvent: SafeGuid.parse('649788e8-1fc6-4de3-a8e4-5719c84d435d'),
    ReadEvent: SafeGuid.parse('73352e23-f185-4b98-98e3-51e1370548f4'),
    ReadEventContent: SafeGuid.parse('34b29c02-3406-48fb-8ef4-ea42ba1c0857'),
});
