import { IGuid, SafeGuid } from 'safeguid';
import { toGuid } from '../utils/guid-utils';

export interface PriorityApi {
    Id: string;
    Name: string;
}

export class Priority {
    constructor(public id: IGuid, public name: string) {}

    public static assign = (priority: PriorityApi): Priority => new Priority(toGuid(priority.Id), priority.Name);
}
