import { IGuid } from 'safeguid';
import { CorrelationSearchParameter } from '../../parameters-modal/correlation-search-parameter';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export class FilterDataTypesModel {
    public dataTypes: Array<IGuid> = [];
    public parameters: Array<CorrelationSearchParameter> = [];
    public description!: string;
}
