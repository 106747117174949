import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MeltedIcon, MeltedModalAction, TextFlavor } from '@genetec/gelato-angular';
import { EvaluateSaveOutcome, SaveRequest, TasksClient, TaskVisibility } from '@modules/shared/api/api';
import { EntityComboComponent } from '@modules/shared/components/entity-browser/entity-combo/entity-combo.component';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { EntityBrowserItemModel } from '@modules/shared/entity-browser/Items/entity-browser-item-model';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { TreeItem } from '@shared/interfaces/tree-item/tree-item';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { PrivilegedEntity } from 'RestClient/Client/Model/PrivilegedEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { TreeViewItem } from './tree-view-item';

@UntilDestroy()
@Component({
    selector: 'app-save-task',
    templateUrl: './save-task.component.html',
    styleUrls: ['./save-task.component.scss'],
})
export class SaveTaskComponent extends ConnectionAwareModalComponent implements OnInit {
    /** Represents the task type of all web app tasks */
    private static readonly webAppTaskTypeId = SafeGuid.parse('{5CC16A69-2247-4E63-A415-C0913AFD105A}');

    /** Guid of the Partition feature. If the feature is not enabled, partitions are hidden from the user */
    private static readonly partitionFeatureGuid = SafeGuid.parse('9260e773-4d06-4778-99e6-a638b2e4e6e1');

    @Input() public taskSubtype: IGuid = SafeGuid.EMPTY;
    @Input() public taskContext = '';

    @ViewChild('partitionEntityCombo') public set setPartitionEntityCombo(content: EntityComboComponent) {
        if (content) {
            const filter = new EntityBrowserFilter(EntityTypes.Partitions);
            filter.excludedEntities.add(Guids.SystemPartition);
            content.entityBrowserFilter = filter;
            // TODO: Pick a default partition
        }
    }

    public readonly TextFlavor = TextFlavor;

    /** Indicates which radio button was selected. Either public or private */
    public taskVisibility?: string;

    public canSave = false;
    public saveButtonText: string;
    public destructiveAction = MeltedModalAction.Optional;
    public publicTaskDisabled = false;
    public privateTaskDisabled = false;
    public showPartitionPicker = false;
    public partitionGuid = SafeGuid.EMPTY;
    public taskName = '';
    public existingTaskItems: Array<TreeViewItem> = [];

    private wouldCreate?: boolean;
    private partitionFeatureEnabled = true;
    private privateTaskItems?: Array<TreeViewItem>;
    private publicTaskItems?: Array<TreeViewItem>;
    private evaluateSaveState: Subject<boolean> = new Subject<boolean>();

    constructor(
        injector: Injector,
        trackingService: TrackingService,
        authService: AuthService,
        private taskClient: TasksClient,
        private securityCenterClientService: SecurityCenterClientService,
        private translateService: TranslateService
    ) {
        super(authService, injector, trackingService);

        // TODO SCD: List of enabled features is not available from REST. Assume the feature is enabled for now
        this.partitionFeatureEnabled = true;
        this.saveButtonText = translateService.instant('STE_BUTTON_SAVE') as string;
    }

    ngOnInit(): void {
        this.publicTaskDisabled = !this.securityCenterClientService.scClient.isGlobalPrivilegeGranted(KnownPrivileges.modifyUserTasksPrivilege);
        this.privateTaskDisabled = !this.securityCenterClientService.scClient.isGlobalPrivilegeGranted(KnownPrivileges.managePrivateTasksPrivilege);

        // if (!this.privateTaskDisabled /* && !Proxy.EntityManager.CurrentUser.LastSelectedSaveAsPublicTask */) {
        //     this.taskVisibility = 'private';
        // }

        this.evaluateSaveState
            .asObservable()
            .pipe(debounceTime(500), untilDestroyed(this))
            .subscribe(async () => await this.refreshSaveState());
    }

    public onSaveClick = async (): Promise<boolean> => {
        const saveRequest = new SaveRequest();
        saveRequest.partitionId = SafeGuid.from(this.partitionGuid);
        saveRequest.fullPath = this.taskName;
        saveRequest.taskType = SaveTaskComponent.webAppTaskTypeId;
        saveRequest.taskSubtype = this.taskSubtype;
        saveRequest.context = this.taskContext;
        saveRequest.visibility = this.taskVisibility === 'private' ? TaskVisibility.Private : TaskVisibility.Public;

        try {
            await this.taskClient.save(saveRequest).toPromise();
        } catch (e) {
            console.log('Error saving task: ', e);
            return false;
        }

        // Returning true closes the modal.
        return true;
    };

    public async onDestinationChange(): Promise<void> {
        const isPrivate = this.taskVisibility === 'private';

        this.refreshPartitionPickerVisibility();

        this.queueRefreshSaveState();

        let taskItems = isPrivate ? this.privateTaskItems : this.publicTaskItems;

        if (!taskItems) {
            // Not populated. Do the initial query
            const response = await this.taskClient.retrieve(isPrivate ? TaskVisibility.Private : TaskVisibility.Public).toPromise();
            taskItems = [];

            if (response.treeViewItems) {
                for (const item of response.treeViewItems) {
                    const newChild: TreeViewItem = {
                        id: item.id?.toString() ?? '',
                        text: item.name ?? '',
                        icon: item.isFolder ? MeltedIcon.Folder : MeltedIcon.Cards,
                        isFolder: !!item.isFolder,
                        alreadyVisited: false,
                    };

                    taskItems.push(newChild);
                }
            }

            if (isPrivate) {
                this.privateTaskItems = taskItems;
            } else {
                this.publicTaskItems = taskItems;
            }
        }

        this.existingTaskItems = taskItems;
    }

    // TODO Rework the typings design of TreeViewItem
    public async onSelectedTaskChange(item: TreeItem | null): Promise<void> {
        if (!item) {
            // early exit if null or undefined
            return;
        }
        const treeViewItem = item as TreeViewItem;

        const fragments: string[] = [];

        let node: TreeItem = item;

        while (node) {
            if (node.text) {
                fragments.push(node.text);
            }
            if (node.parent) {
                node = node.parent;
            }
        }

        fragments.reverse();

        const searchPath = fragments.join('\\');
        this.taskName = '/' + fragments.join('/');
        this.queueRefreshSaveState();

        if (treeViewItem.alreadyVisited || !treeViewItem.isFolder) {
            return;
        }

        const response = await this.taskClient.retrieve(this.taskVisibility === 'private' ? TaskVisibility.Private : TaskVisibility.Public, searchPath).toPromise();

        treeViewItem.alreadyVisited = true;

        if (response.treeViewItems && response.treeViewItems.length > 0) {
            item.children = [];

            for (const child of response.treeViewItems) {
                const newChild: TreeViewItem = {
                    parent: item,
                    id: child.id?.toString() ?? '',
                    text: child.name ?? '',
                    icon: child.isFolder ? MeltedIcon.Folder : MeltedIcon.Cards,
                    isFolder: !!child.isFolder,
                    alreadyVisited: false,
                    isChecked: false,
                };

                item.children?.push(newChild);
            }
        } else {
            item.children = undefined;
        }
    }

    public onNameChange(): void {
        this.queueRefreshSaveState();
    }

    public onSelectedPartitionChanged(): void {
        this.queueRefreshSaveState();
    }

    public canSelectPartition = async (model: EntityBrowserItemModel): Promise<boolean> => {
        const user = (await this.securityCenterClientService.scClient.getEntityAsync(UserEntity, this.securityCenterClientService.scClient.userId)) as PrivilegedEntity;
        const hasPrivilege = await user.isPrivilegeGrantedAsync(KnownPrivileges.addPublicTasksPrivilege, model.id);

        return !!hasPrivilege;
    };

    /** Queues a state refresh of the Save button */
    private queueRefreshSaveState(): void {
        this.canSave = false;
        this.evaluateSaveState.next();
    }

    private async refreshSaveState(): Promise<void> {
        const partitionRequired = this.taskVisibility === 'public' && SafeGuid.EMPTY.equals(this.partitionGuid);

        if (this.taskName !== '') {
            const result = await this.taskClient
                .evaluateSave(this.taskName, SafeGuid.EMPTY, SaveTaskComponent.webAppTaskTypeId, this.taskVisibility === 'private' ? TaskVisibility.Private : TaskVisibility.Public)
                .toPromise();

            switch (result.evaluateSaveOutcome) {
                case EvaluateSaveOutcome.CannotBeSaved: {
                    this.canSave = false;
                    this.wouldCreate = false;
                    this.destructiveAction = MeltedModalAction.Optional;
                    break;
                }
                case EvaluateSaveOutcome.WouldCreate: {
                    this.canSave = !partitionRequired;
                    this.saveButtonText = this.translateService.instant('STE_BUTTON_SAVE') as string;
                    this.wouldCreate = true;
                    this.destructiveAction = MeltedModalAction.Optional;
                    break;
                }
                case EvaluateSaveOutcome.WouldOverwrite: {
                    this.canSave = true;
                    this.saveButtonText = this.translateService.instant('STE_BUTTON_OVERWRITE') as string;
                    this.wouldCreate = false;
                    this.destructiveAction = MeltedModalAction.Default;
                    break;
                }
            }
        }

        this.refreshPartitionPickerVisibility();
    }

    private refreshPartitionPickerVisibility(): void {
        this.showPartitionPicker = this.taskVisibility === 'public' && this.partitionFeatureEnabled && this.wouldCreate === true; //and available partitions > 1
    }
}
