import { Injectable } from '@angular/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { ContentController, ContentFieldSource, ContentMultiSource, ContentSource } from '../../controllers/content/content.controller';
import { ContentGroup, Content } from '../../interfaces/plugins/public/plugin-public.interface';
import { IContentGroup, IContent, ContentGroup as ControllerContentGroup } from '../../controllers/content/content.controller.data';
import { IContentController } from '../../controllers/content/content.controller.interfaces';
import { LoggerService } from '../logger/logger.service';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable({
    providedIn: 'root',
})
export class ContentProviderService {
    private contentsCache: Map<string, ContentGroup | null> = new Map<string, ContentGroup | null>();
    private readonly cacheDuration = 2000;

    constructor(private securityCenterProvider: SecurityCenterClientService, private loggerService: LoggerService) {}

    public async getContentAsync(sourceId: string | IGuid, contextId?: IGuid): Promise<ContentGroup | null> {
        // TODO: Check if contextId being undefined is properly handled, cacheKey could result in "<a guid>_undefined";
        const cacheKey = `${sourceId.toString()}_${contextId?.toString() || 'undefined'}`;
        if (this.contentsCache.has(cacheKey)) {
            return new Deferred<ContentGroup | null>(this.contentsCache.get(cacheKey)).promise;
        }

        const contentController = await this.securityCenterProvider?.scClient.getAsync<ContentController, IContentController>(ContentController);
        if (contentController) {
            const source = new ContentSource();
            source.sourceId = sourceId.toString();
            if (contextId) {
                source.contextId = contextId;
            }
            const contentGroup = await contentController.getContentAsync(source);
            const adapted = this.adaptGroup(contentGroup);
            this.contentsCache.set(cacheKey, adapted);

            setTimeout(() => {
                this.contentsCache.delete(cacheKey);
            }, this.cacheDuration);
            return adapted;
        }

        return null;
    }

    public async getContentsAsync(sourceIds: string[] | IGuid[], contextId?: IGuid): Promise<ContentGroup[] | null> {
        const contentController = await this.securityCenterProvider?.scClient.getAsync<ContentController, IContentController>(ContentController);
        if (contentController) {
            const sources = new ObservableCollection<string>();
            sourceIds.forEach((item: string | IGuid) => sources.add(item.toString()));
            const mutliSource = new ContentMultiSource();
            mutliSource.sourceIds = sources;
            if (contextId) {
                mutliSource.contextId = contextId;
            }
            const contentGroups = await contentController.getContentsAsync(mutliSource);

            if (contentGroups) {
                return Array.from(contentGroups, (contentGroup) => this.adaptGroup(contentGroup) as ContentGroup);
            }
        }

        return null;
    }

    public async getContentFromFieldsAsync(fields: IFieldObject, contextId?: IGuid): Promise<ContentGroup | null> {
        const contentController = await this.securityCenterProvider?.scClient.getAsync<ContentController, IContentController>(ContentController);
        if (contentController) {
            const fieldSource = new ContentFieldSource();
            fieldSource.fields = fields;
            if (contextId) {
                fieldSource.contextId = contextId;
            }
            const contentGroup = await contentController.getContentFromFieldsAsync(fieldSource);
            return this.adaptGroup(contentGroup);
        }

        return null;
    }

    public getContentFromString(contentGroupString: string): ContentGroup | null {
        try {
            const contentGroup = new ControllerContentGroup();
            const json = JSON.parse(contentGroupString) as Record<string, unknown>;
            contentGroup.loadFields(json);
            return this.adaptGroup(contentGroup);
        } catch (e) {
            this.loggerService.traceError('Unable to get a content from a string data', e);
            return null;
        }
    }

    private adaptGroup(contentGroup: IContentGroup | null): ContentGroup | null {
        if (!contentGroup) {
            return null;
        }

        const mainContentAdapted = this.adapt(contentGroup.mainContent);

        if (!mainContentAdapted) {
            return null;
        }

        const subContents = Array.from(contentGroup.subContents, (content) => this.adapt(content, mainContentAdapted)) as Content[];
        subContents.forEach((subContent) => {
            subContent.contextsubContents = Array.from(
                contentGroup.subContents.where((item) => item.id !== subContent.id),
                (subContentToAdapt) => this.adapt(subContentToAdapt, mainContentAdapted)
            ) as Content[];
        });

        return {
            id: contentGroup.id,
            mainContent: mainContentAdapted,
            subContents,
        };
    }

    private adapt(content: IContent | null, mainContent?: Content): Content | null {
        if (!content) {
            return null;
        }
        return {
            id: content.id,
            type: content.type,
            title: content.title,
            source: content.source,
            icon: content.icon,
            customIconId: content.customIconId ?? undefined,
            description: content.description,
            contextContent: mainContent ?? null,
            parameters: content.parameters,
            grantedPrivileges: Array.from(content.grantedPrivileges),
            color: content.color,
            contextsubContents: null, // will be set afterwards since content only doesn't know...
        };
    }
}
