import { Component, Inject, OnInit } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { ContentGroup } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { MAPS_BOTTOM_CONTEXT_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MapsBottomContextDataService } from '../../services/maps-bottom-context-data/maps-bottom-context-data.service';
@UntilDestroy()
@Component({
    selector: 'app-maps-bottom-pane',
    templateUrl: './maps-bottom-pane.component.html',
    styleUrls: ['./maps-bottom-pane.component.scss'],
})
export class MapsBottomPaneComponent extends TrackedComponent implements OnInit {
    public plugin: PluginItem | undefined;
    public isLoading = false;

    constructor(
        @Inject(MAPS_BOTTOM_CONTEXT_SERVICE) public contextDataService: MapsBottomContextDataService,
        private pluginService: PluginService,
        trackingService: TrackingService
    ) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.contextDataService.currentContent$.pipe(untilDestroyed(this)).subscribe(async (content) => await this.currentContentChanged(content));
    }

    private async currentContentChanged(content: ContentGroup | null) {
        const currentContent = content;
        this.plugin = undefined;

        if (currentContent) {
            this.isLoading = true;

            const plugins = await this.pluginService.getPlugins(PluginTypes.Widget, currentContent.mainContent);

            if (plugins.length > 0) {
                this.plugin = plugins[0];
            }
        }
        this.isLoading = false;
    }
}
