import { Entity } from '../Model/Entity';
import { IEntity } from './IEntity';

export class IEntityExtensions {
    public static isEntity(object: any): object is IEntity {
        if (object instanceof Object && 'isOfType' in object) {
            const isOfType = object['isOfType'];
            if (typeof isOfType === 'function' && isOfType.length === 1) {
                try {
                    return object.isOfType(Entity);
                } catch {}
            }
        }
        return false;
    }
}
