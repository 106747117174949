// Autogenerated: v2/help/Entities/ApiVisitor?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:09 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity, EntityFields } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class VisitorEntityFields extends EntityFields {
    public static accessPermissionLevelField = 'AccessPermissionLevel';
    public static accessStatusField = 'AccessStatus';
    public static activationDateField = 'ActivationDate';
    public static antipassbackExemptionField = 'AntipassbackExemption';
    public static antipassbackExemptionIsInheritedField = 'AntipassbackExemptionIsInherited';
    public static checkinDateField = 'CheckinDate';
    public static checkoutDateField = 'CheckoutDate';
    public static emailAddressField = 'EmailAddress';
    public static escortField = 'Escort';
    public static escort2Field = 'Escort2';
    public static expirationDateField = 'ExpirationDate';
    public static expirationDurationField = 'ExpirationDuration';
    public static expirationModeField = 'ExpirationMode';
    public static firstNameField = 'FirstName';
    public static hasPictureField = 'HasPicture';
    public static inheritAccessPermissionLevelFromGroupField = 'InheritAccessPermissionLevelFromGroup';
    public static isArchivedField = 'IsArchived';
    public static isVisitorField = 'IsVisitor';
    public static lastAccessField = 'LastAccess';
    public static lastAccessGrantedField = 'LastAccessGranted';
    public static lastAccessLocationField = 'LastAccessLocation';
    public static lastNameField = 'LastName';
    public static mandatoryEscortField = 'MandatoryEscort';
    public static mobilePhoneNumberField = 'MobilePhoneNumber';
    public static useExtendedGrantTimeField = 'UseExtendedGrantTime';
    public static validateEscortRulesField = 'ValidateEscortRules';
    public static visitDateField = 'VisitDate';
}

// #endregion Fields

// #region Inner classes

export interface IVisitorImage extends IFieldObject {
    guid: IGuid;
    data: string;
}

// #endregion Inner classes

export interface IVisitorEntity extends IEntity {
    // #region REST Properties

    accessPermissionLevel: number;

    accessStatus: string;

    activationDate: Date;

    antipassbackExemption: boolean;

    antipassbackExemptionIsInherited: boolean;

    readonly checkinDate: Date;

    readonly checkoutDate: Date;

    emailAddress: string;

    escort: IGuid;

    escort2: IGuid;

    expirationDate: Date;

    expirationDuration: number;

    expirationMode: string;

    firstName: string;

    readonly hasPicture: boolean;

    inheritAccessPermissionLevelFromGroup: boolean;

    readonly isArchived: boolean;

    readonly isVisitor: boolean;

    readonly lastAccess: Date;

    readonly lastAccessGranted: boolean | null;

    readonly lastAccessLocation: IGuid;

    lastName: string;

    mandatoryEscort: boolean;

    mobilePhoneNumber: string;

    useExtendedGrantTime: boolean;


    visitDate: Date;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getUsergroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getCredentialsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    deletePictureAsync(transaction?: ITransaction | null): Promise<IRestResponse>;
    getPictureAsync(transaction?: ITransaction | null): Promise<IVisitorImage | null>;
    addPictureAsync(value: IVisitorImage, transaction?: ITransaction | null): Promise<IVisitorImage | null>;

    // #endregion REST Methods

}

