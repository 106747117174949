import { Buffer } from './Buffer';
import { GLUtils } from './GLUtils';

export class Texture {
  private readonly m_gl: WebGL2RenderingContext;
  private readonly m_texture: WebGLTexture;
  private readonly m_textureCoordinatesBuffer: Buffer;

  public get CoordinatesBuffer(): Buffer {
    return this.m_textureCoordinatesBuffer;
  }

  public constructor(gl: WebGL2RenderingContext, buffer: Buffer) {
    this.m_gl = gl;
    this.m_textureCoordinatesBuffer = buffer;

    gl.bindBuffer(gl.ARRAY_BUFFER, this.m_textureCoordinatesBuffer.WebGLBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, GLUtils.getRectangle12Points(0, 0, 1, 1), gl.STATIC_DRAW);

    const texture = gl.createTexture();
    if (texture === null) {
      throw new Error('Failed to create texture');
    }
    this.m_texture = texture;
    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    const interpolation: GLenum = gl.LINEAR; //gl.NEAREST;
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, interpolation);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, interpolation);
  }

  public dispose() {
    this.m_gl.deleteTexture(this.m_texture);
    this.m_textureCoordinatesBuffer.dispose();
  }

  // Upload the image into the texture.
  public updateTexture(image: TexImageSource): void {
    const gl = this.m_gl;
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
  }
}
