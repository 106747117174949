// Autogenerated: v2/help/enumerations/ApiActionType?codegen=ts&showresources=False on 2021-04-08 07:46:41

export class ActionType {
    public static None = 'None';
    public static ForwardAlarm = 'ForwardAlarm';
    public static SendMessage = 'SendMessage';
    public static PlaySound = 'PlaySound';
    public static DisplayEntity = 'DisplayEntity';
    public static CorrelationSendDatums = 'CorrelationSendDatums';
    public static Custom = 'Custom';

    public static readonly values = [
        ActionType.None,
        ActionType.ForwardAlarm,
        ActionType.SendMessage,
        ActionType.PlaySound,
        ActionType.DisplayEntity,
        ActionType.CorrelationSendDatums,
        ActionType.Custom
    ];
}
