import { ITokenRenewer } from '../../services/TokenRenewer';
import { Camera } from '../camera';
import { SupportedVideoFormats } from './SupportedVideoFormats';
import { TranscodingAllowance } from './Messages';
import { DefaultStreamUsage } from './DefaultStreamUsage';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { Utils } from '../../utils/Utils';
import { DewarperSourceImageParameters } from '../../dewarper/DewarperSourceImageParameters';

export class SessionCreationParameters {
  // Client side specification
  public readonly camera: Camera;
  public readonly channel: number;
  public readonly tokenRenewer: ITokenRenewer
  public readonly supportedVideoFormats: SupportedVideoFormats;
  public readonly clientVersion: string;

  constructor(channel: number, camera: Camera, tokenRenewer: ITokenRenewer, supportedVideoFormats: SupportedVideoFormats, clientVersion: string) {
    this.channel = channel;
    this.camera = camera;
    this.tokenRenewer = tokenRenewer;
    this.supportedVideoFormats = supportedVideoFormats;
    this.clientVersion = clientVersion;
  }
}

export class SessionParameters {
  private readonly m_sessionCreationParameters: SessionCreationParameters;

  public get channel(): number {
    return this.m_sessionCreationParameters.channel;
  }
  public get camera(): Camera {
    return this.m_sessionCreationParameters.camera;
  }
  public get tokenRenewer(): ITokenRenewer {
    return this.m_sessionCreationParameters.tokenRenewer;
  }
  public get supportedVideoFormats(): SupportedVideoFormats {
    return this.m_sessionCreationParameters.supportedVideoFormats;
  }
  public get clientVersion(): string {
    return this.m_sessionCreationParameters.clientVersion;
  }

  // Server side specifications
  public readonly transcodingAllowance: TranscodingAllowance;
  public readonly defaultStreamUsage: DefaultStreamUsage;
  public readonly serverVersion: string;
  public readonly videoWatermarkConfig: VideoWatermarkConfig;
  public readonly dewarperConfig: DewarperSourceImageParameters;

  constructor(sessionCreationParameters: SessionCreationParameters, transcodingAllowance: TranscodingAllowance, defaultStreamUsage: DefaultStreamUsage, videoWatermarkConfig: VideoWatermarkConfig, dewarperConfig: DewarperSourceImageParameters, serverVersion: string) {
    this.m_sessionCreationParameters = sessionCreationParameters;
    this.transcodingAllowance = transcodingAllowance;
    this.defaultStreamUsage = defaultStreamUsage;
    this.videoWatermarkConfig = videoWatermarkConfig;
    this.dewarperConfig = dewarperConfig;
    this.serverVersion = serverVersion;
  }

  public withSupportedVideoFormats(newSupportedVideoFormat: SupportedVideoFormats): SessionParameters {
    return new SessionParameters(new SessionCreationParameters(
      this.channel,
      this.camera,
      this.tokenRenewer,
      newSupportedVideoFormat,
      this.clientVersion),
    this.transcodingAllowance,
    this.defaultStreamUsage,
    this.videoWatermarkConfig,
    this.dewarperConfig,
    this.serverVersion);
  }

  public withServerSideSessionParameters(transcodingAllowance: TranscodingAllowance, defaultStreamUsage: DefaultStreamUsage, videoWatermarkConfig: VideoWatermarkConfig, dewarpConfig: DewarperSourceImageParameters, serverVersion: string): SessionParameters {
    return new SessionParameters(this.m_sessionCreationParameters, transcodingAllowance, defaultStreamUsage, videoWatermarkConfig, dewarpConfig, serverVersion);
  }

  public debugStatus(indent: number): string {
    return 'SessionParameters' + Utils.indentNewLine(indent) +
      'Channel: ' + this.channel + Utils.indentNewLine(indent) +
      'Camera: ' + this.camera + Utils.indentNewLine(indent) +
      this.supportedVideoFormats.toString() + Utils.indentNewLine(indent) +
      'Client version: ' + this.clientVersion + Utils.indentNewLine(indent) +
      'Transcoding allowance: ' + TranscodingAllowance[this.transcodingAllowance] + Utils.indentNewLine(indent) +
      'Default stream usage: ' + this.defaultStreamUsage.toString() + Utils.indentNewLine(indent) +
      'Server version: ' + this.serverVersion + Utils.indentNewLine(indent) +
      this.videoWatermarkConfig.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      this.dewarperConfig.debugStatus(indent + Utils.Indentation);
  }
}
