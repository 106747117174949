import { Injectable } from '@angular/core';
import { WebAppClient } from 'WebClient/WebAppClient';
import { IGuid, SafeGuid } from 'safeguid';
import { LoggerService } from '@shared/services/logger/logger.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap, take } from 'rxjs/operators';
import { StateMapService } from '@modules/maps/services/map/state-map-service';
import { MissionControlContextIds } from '@modules/mission-control/mission-control-context-ids';
import { MissionControlContentTypes } from '@modules/mission-control/mission-control-content-types';
import { ContentProviderService } from '@shared/services/content/content-provider.service';
import { MCIncident } from '../../models/mc-incident';
import { IncidentSelectionService } from '../incident/incident-selection.service';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { AreaEntity } from 'RestClient/Client/Model/AreaEntity';
import { IAreaEntity } from 'RestClient/Client/Interface/IAreaEntity';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class IncidentMapSelectorService {
    public readonly currentDisplayedIncident$!: Observable<MCIncident | null>;

    private readonly _scClient: WebAppClient;

    private _currentDisplayedIncident$: Subject<MCIncident | null> = new Subject<MCIncident | null>();
    private _onIncidentClose: Subscription | null = null;
    private _activeContentId: IGuid | null = null;

    constructor(
        securityCenterProvider: SecurityCenterClientService,
        private _incidentSelectionService: IncidentSelectionService,
        private _contentProviderService: ContentProviderService,
        private _mapService: StateMapService,
        private _logger: LoggerService
    ) {
        this._scClient = securityCenterProvider.scClient;
        this.currentDisplayedIncident$ = this._currentDisplayedIncident$.asObservable();
        this._onIncidentClose = this._incidentSelectionService.selectedIncident$.pipe(untilDestroyed(this)).subscribe((incident) => {
            if (!incident && this._activeContentId) this.hideWidgets();
        });
    }

    public ngOnDestroy(): void {
        this.hideWidgets();
        this._onIncidentClose?.unsubscribe();
    }

    public setMapSidePaneSelection(incident: MCIncident): void {
        this.getAssociatedMap(incident)
            .pipe(
                take(1),
                tap(async (mapId) => {
                    if (mapId) {
                        this._mapService.select(incident.id.toString(), mapId);
                    }

                    const content = await this._contentProviderService.getContentAsync(incident.id.toString(), MissionControlContextIds.incidentList);
                    if (!content || !content?.mainContent.type?.equals(MissionControlContentTypes.incident)) {
                        this._logger.traceError('No content found');
                        return;
                    }
                    this._activeContentId = content.id;
                    this._mapService.updateContextualData(content);

                    this._currentDisplayedIncident$.next(incident);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private hideWidgets(): void {
        if (this._activeContentId) {
            this._mapService.clearContextualData(this._activeContentId);
            this._activeContentId = null;
            this._currentDisplayedIncident$.next(null);
        }
    }

    private getAssociatedMap(incident: MCIncident): Observable<IGuid | null> {
        const location = incident.location;

        if (!location || !location.entityId) return of(null);

        const locationId = toGuid(location.entityId);

        if (locationId.equals(SafeGuid.EMPTY)) return of(null);

        return from(this._scClient.getEntityAsync<Entity, IEntity>(Entity, locationId)).pipe(
            switchMap((entity) => {
                if (!entity) return of(null);

                // Directly return id if the entity is already a map
                if (entity?.entityType === EntityTypes.Areas) {
                    return from(this._scClient.getEntityAsync<AreaEntity, IAreaEntity>(AreaEntity, locationId)).pipe(map((area) => area?.link ?? null));
                }

                //Take the first map where we can find this attached entity
                return from(entity.getLinkedMapsAsync()).pipe(map((maps) => maps?.first().id ?? null));
            }),
            catchError((err) => {
                this._logger.traceError(`Unable to get map ${err as string}`);
                return of(null);
            })
        );
    }
}
