// Autogenerated: v2/help/Entities/ApiReverseTunnelServerRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:08 PM
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class ReverseTunnelServerRoleEntityFields extends RoleEntityFields {
    public static globalEncryptionModeField = 'GlobalEncryptionMode';
    public static globalTcpPortRangeEndField = 'GlobalTcpPortRangeEnd';
    public static globalTcpPortRangeStartField = 'GlobalTcpPortRangeStart';
}

// #endregion Fields

// #region Inner classes

export interface ISite extends IFieldObject {
    name: string;
    mandatoryExistingEntity: IGuid | null;
    friendlyDescription: string;
    encryptionMode: string;
    forceReset: boolean;
}

// #endregion Inner classes

export interface IReverseTunnelServerRoleEntity extends IRoleEntity {
    // #region REST Properties

    globalEncryptionMode: string;

    globalTcpPortRangeEnd: number;

    globalTcpPortRangeStart: number;

    // #endregion REST Properties

    // #region REST Methods

    addSitesAsync(value: ISite, transaction?: ITransaction): Promise<string | null>;
    deleteSitesAsync(name: string, transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

