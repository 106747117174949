/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IGuid, SafeGuid } from 'safeguid';
import { toGuid } from '../../utils/guid-utils';
import { IncidentLocation } from '../incident-location';
import { sortingParameter } from '../incident-sorting';

export class Header {
    constructor(public messageid: IGuid, public correlationid: IGuid, public antecedentid: IGuid) {}

    public static Empty = (): Header => new Header(SafeGuid.EMPTY, SafeGuid.EMPTY, SafeGuid.EMPTY);
}

export interface IncidentLocationLowered {
    entityid: IGuid;
    latitude: number | null;
    longitude: number | null;
}

export abstract class IncidentEvent {
    public readonly id: IGuid;
    public readonly type: IncidentEventType;
    public readonly incidentId: IGuid;
    public readonly header: Header;
    public readonly processTimeUtc: Date;
    public readonly instigatorId: IGuid;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, header: Header) {
        this.id = SafeGuid.newGuid();
        this.type = type;
        this.incidentId = toGuid(incidentId);
        this.processTimeUtc = processTimeUtc;
        this.instigatorId = toGuid(instigatorId);
        this.header = new Header(toGuid(header.messageid), toGuid(header.correlationid), toGuid(header.antecedentid));
    }

    public isEventForSort(sortParameter: sortingParameter): boolean {
        const isState = sortParameter === 'state' && this.type === IncidentEventType.StateChanged;
        const isPriority = sortParameter === 'priority' && this.type === IncidentEventType.PriorityChanged;
        const isLocation = sortParameter === 'location' && (this.type === IncidentEventType.LocationChanged || this.type === IncidentEventType.LocationCleared);
        const isName = sortParameter === 'name' && this.type === IncidentEventType.IncidentTypeChanged;
        return isState || isPriority || isLocation || isName;
    }
}

export class DescriptionChangedEvent extends IncidentEvent {
    public readonly description: string;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, description: string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.description = description;
    }
}

export class RecipientAlteredEvent extends IncidentEvent {
    public readonly addedRecipients: IGuid[];
    public readonly removedRecipients: IGuid[];
    public readonly alterationType: AlterationType;
    public readonly recipientsMode: RecipientsMode;

    constructor(
        type: IncidentEventType,
        incidentId: IGuid | string,
        processTimeUtc: Date,
        instigatorId: IGuid | string,
        addedRecipients: string[],
        removedRecipients: string[],
        alterationType: AlterationType,
        recipientsMode: RecipientsMode,
        header: Header
    ) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.addedRecipients = addedRecipients.map((x) => toGuid(x));
        this.removedRecipients = removedRecipients.map((x) => toGuid(x));
        this.alterationType = alterationType;
        this.recipientsMode = recipientsMode;
    }
}

export class StateChangedEvent extends IncidentEvent {
    public readonly stateId: IGuid;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, stateId: IGuid | string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.stateId = toGuid(stateId);
    }
}

export class PriorityChangedEvent extends IncidentEvent {
    public readonly priorityId: IGuid;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, priorityId: IGuid | string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.priorityId = toGuid(priorityId);
    }
}

export class IncidentCreatedEvent extends IncidentEvent {
    public readonly parentId: IGuid | null;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, parentId: IGuid | string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.parentId = parentId ? toGuid(parentId) : null;
    }
}

export class LocationChangedEvent extends IncidentEvent {
    public readonly location: IncidentLocation;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, location: IncidentLocationLowered, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.location = new IncidentLocation(location.entityid, location.latitude, location.longitude);
    }
}

export class TypeChangedEvent extends IncidentEvent {
    public readonly incidentTypeId: IGuid;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, incidentTypeId: IGuid | string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.incidentTypeId = toGuid(incidentTypeId);
    }
}

export class ProcedureStepAnsweredEvent extends IncidentEvent {
    public readonly actionType: ProcedureStepActionType;
    public readonly optionId: IGuid | null;
    public readonly comment: string;
    public readonly stepId: IGuid;
    public readonly nextStepId: IGuid;

    constructor(
        type: IncidentEventType,
        incidentId: IGuid | string,
        processTimeUtc: Date,
        instigatorId: IGuid | string,
        actionType: string,
        comment: string,
        stepId: IGuid | string,
        nextStepId: IGuid | string,
        header: Header,
        optionId?: IGuid | string
    ) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.actionType = (ProcedureStepActionType as any)[actionType] as ProcedureStepActionType;
        this.comment = comment;
        this.optionId = optionId ? toGuid(optionId) : null;
        this.stepId = toGuid(stepId);
        this.nextStepId = toGuid(nextStepId);
    }
}

export class OwnershipChangedEvent extends IncidentEvent {
    public readonly owner: IGuid | null;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, owner: IGuid | string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.owner = owner ? toGuid(owner) : null;
    }
}

export class CommentAddedEvent extends IncidentEvent {
    public readonly commentId: IGuid | null;
    public readonly comment: string;

    constructor(
        type: IncidentEventType,
        incidentId: IGuid | string,
        processTimeUtc: Date,
        instigatorId: IGuid | string,
        commentId: IGuid | string,
        comment: string,
        header: Header
    ) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.commentId = commentId ? toGuid(commentId) : null;
        this.comment = comment;
    }
}

export class CommentDeletedEvent extends IncidentEvent {
    public readonly commentId: IGuid | null;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, commentId: IGuid | string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.commentId = commentId ? toGuid(commentId) : null;
    }
}

export class CommentChangedEvent extends IncidentEvent {
    public readonly commentId: IGuid | null;
    public readonly comment: string;

    constructor(
        type: IncidentEventType,
        incidentId: IGuid | string,
        processTimeUtc: Date,
        instigatorId: IGuid | string,
        commentId: IGuid | string,
        comment: string,
        header: Header
    ) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.commentId = commentId ? toGuid(commentId) : null;
        this.comment = comment;
    }
}

export class LinkedEvent extends IncidentEvent {
    public readonly linkedIncidentIds: IGuid[] = [];

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, linkedIncidentIds: IGuid[], header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.linkedIncidentIds = linkedIncidentIds ? linkedIncidentIds.map((id: IGuid) => toGuid(id)) : [];
    }
}

export class UnlinkedEvent extends IncidentEvent {
    public readonly unlinkedIncidentIds: IGuid[] = [];

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, unlinkedIncidentIds: IGuid[], header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.unlinkedIncidentIds = unlinkedIncidentIds ? unlinkedIncidentIds.map((id: IGuid) => toGuid(id)) : [];
    }
}

export class DisabledStatesChangedEvent extends IncidentEvent {
    public readonly disabledStates: IGuid[] = [];

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, disabledStates: IGuid[], header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.disabledStates = disabledStates ? disabledStates.map((id: IGuid) => toGuid(id)) : [];
    }
}

export class NoteAddedEvent extends IncidentEvent {
    public readonly displayText: string;
    public readonly payload: string;
    public readonly payloadFormat: string;

    constructor(
        type: IncidentEventType,
        incidentId: IGuid | string,
        processTimeUtc: Date,
        instigatorId: IGuid | string,
        displayText: string,
        payload: string,
        payloadFormat: string,
        header: Header
    ) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.displayText = displayText;
        this.payload = payload;
        this.payloadFormat = payloadFormat;
    }
}

export class ExternalEventAggregatedEvent extends IncidentEvent {
    public readonly publicType: number;
    public readonly publicTypeName: string;
    public readonly locationEntity: IGuid | string;
    public readonly triggeringEntity: IGuid | string;
    public readonly sourceEntity: IGuid | string;
    public readonly payload: string;
    public readonly raiseTimeUTC: Date;

    constructor(
        type: IncidentEventType,
        incidentId: IGuid | string,
        processTimeUtc: Date,
        instigatorId: IGuid | string,
        publicType: number,
        publicTypeName: string,
        locationEntity: IGuid | string,
        triggeringEntity: IGuid | string,
        sourceEntity: IGuid | string,
        payload: string,
        raiseTimeUTC: Date,
        header: Header
    ) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.publicType = publicType;
        this.publicTypeName = publicTypeName;
        this.locationEntity = locationEntity;
        this.triggeringEntity = triggeringEntity;
        this.sourceEntity = sourceEntity;
        this.payload = payload;
        this.raiseTimeUTC = raiseTimeUTC;
    }
}

export class LocationClearedEvent extends IncidentEvent {}

export class ExternalIdChangedEvent extends IncidentEvent {
    public readonly externalId: string;

    constructor(type: IncidentEventType, incidentId: IGuid | string, processTimeUtc: Date, instigatorId: IGuid | string, externalId: string, header: Header) {
        super(type, incidentId, processTimeUtc, instigatorId, header);
        this.externalId = externalId;
    }
}

export enum IncidentEventType {
    StateChanged = 'StateChanged',
    RecipientsAltered = 'RecipientsAltered',
    Created = 'Created',
    PriorityChanged = 'PriorityChanged',
    Linked = 'Linked',
    Unlinked = 'Unlinked',
    CommentAdded = 'CommentAdded',
    CommentChanged = 'CommentChanged',
    CommentDeleted = 'CommentDeleted',
    LocationChanged = 'LocationChanged',
    DescriptionChanged = 'DescriptionChanged',
    ExternalIdChanged = 'ExternalIdChanged',
    IncidentTypeChanged = 'IncidentTypeChanged',
    OwnershipChanged = 'OwnershipChanged',
    LocationCleared = 'LocationCleared',
    NoteAdded = 'NoteAdded',
    KeepAlive = 'KeepAlive',
    ProcedureStepAnswered = 'ProcedureStepAnswered',
    ExternalEventAggregated = 'ExternalEventAggregated',
    DisabledStatesChanged = 'DisabledStatesChanged',
}

export const IncidentEventTypeValues: Record<IncidentEventType, number> = {
    [IncidentEventType.KeepAlive]: -1,
    [IncidentEventType.StateChanged]: 1,
    [IncidentEventType.RecipientsAltered]: 2,
    [IncidentEventType.Created]: 3,
    [IncidentEventType.PriorityChanged]: 4,
    [IncidentEventType.Linked]: 5,
    [IncidentEventType.Unlinked]: 6,
    [IncidentEventType.CommentAdded]: 7,
    [IncidentEventType.CommentChanged]: 8,
    [IncidentEventType.CommentDeleted]: 9,
    [IncidentEventType.LocationChanged]: 10,
    [IncidentEventType.DescriptionChanged]: 11,
    [IncidentEventType.ExternalIdChanged]: 12,
    [IncidentEventType.IncidentTypeChanged]: 13,
    [IncidentEventType.OwnershipChanged]: 14,
    [IncidentEventType.LocationCleared]: 15,
    [IncidentEventType.NoteAdded]: 16,
    [IncidentEventType.ProcedureStepAnswered]: 17,
    [IncidentEventType.ExternalEventAggregated]: 18,
    [IncidentEventType.DisabledStatesChanged]: 19,
};

export enum ProcedureStepActionType {
    Complete = 0,
    Skip = 1,
    Reset = 2,
    Previous = 3,
    Resume = 4,
    SystemComplete = 5,
    SystemPrevious = 6,
}

//TODO: remove when all event types are supported
export const isSupportedEventType = (incidentEventType: IncidentEventType): boolean => {
    return (
        incidentEventType === IncidentEventType.CommentAdded ||
        incidentEventType === IncidentEventType.CommentDeleted ||
        incidentEventType === IncidentEventType.CommentChanged ||
        incidentEventType === IncidentEventType.LocationCleared ||
        incidentEventType === IncidentEventType.DescriptionChanged ||
        incidentEventType === IncidentEventType.StateChanged ||
        incidentEventType === IncidentEventType.PriorityChanged ||
        incidentEventType === IncidentEventType.Created ||
        incidentEventType === IncidentEventType.LocationChanged ||
        incidentEventType === IncidentEventType.ExternalIdChanged ||
        incidentEventType === IncidentEventType.OwnershipChanged ||
        incidentEventType === IncidentEventType.IncidentTypeChanged ||
        incidentEventType === IncidentEventType.RecipientsAltered ||
        incidentEventType === IncidentEventType.ProcedureStepAnswered ||
        incidentEventType === IncidentEventType.Linked ||
        incidentEventType === IncidentEventType.Unlinked ||
        incidentEventType === IncidentEventType.NoteAdded ||
        incidentEventType === IncidentEventType.ExternalEventAggregated ||
        incidentEventType === IncidentEventType.DisabledStatesChanged
    );
};

const objectToLower = (object: Record<string, any>) => {
    const lowerCasedObject: Record<string, any> = {};
    const keys = Object.keys(object);
    keys.forEach((key) => {
        const val = object[key];
        lowerCasedObject[key.toLowerCase()] = !!val && typeof val === 'object' && !Array.isArray(val) ? objectToLower(val) : val;
    });
    return lowerCasedObject;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const eventFactory = (incidentEvent: any): IncidentEvent => {
    const ie = objectToLower(incidentEvent);
    const type = ie.type as IncidentEventType;
    switch (type) {
        case IncidentEventType.CommentAdded:
            return new CommentAddedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.commentid, ie.comment, ie.header);
        case IncidentEventType.CommentDeleted:
            return new CommentDeletedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.commentid, ie.header);
        case IncidentEventType.CommentChanged:
            return new CommentChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.commentid, ie.comment, ie.header);
        case IncidentEventType.DisabledStatesChanged:
            return new DisabledStatesChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.disabledstates, ie.header);
        case IncidentEventType.Linked:
            return new LinkedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.linkedincidentids, ie.header);
        case IncidentEventType.Unlinked:
            return new UnlinkedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.unlinkedincidentids, ie.header);
        case IncidentEventType.StateChanged:
            return new StateChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.stateid, ie.header);
        case IncidentEventType.ExternalEventAggregated:
            return new ExternalEventAggregatedEvent(
                ie.type,
                ie.incidentid,
                ie.processtimeutc,
                ie.instigatorid,
                ie.publictype,
                ie.publictypename,
                ie.locationentity,
                ie.triggeringentity,
                ie.sourceentity,
                ie.payload,
                ie.raiseTimeUTC,
                ie.header
            );
        case IncidentEventType.NoteAdded:
            return new NoteAddedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.displaytext, ie.payload, ie.payloadformat, ie.header);
        case IncidentEventType.RecipientsAltered:
            return new RecipientAlteredEvent(
                ie.type,
                ie.incidentid,
                ie.processtimeutc,
                ie.instigatorid,
                ie.addedrecipients,
                ie.removedrecipients,
                ie.alterationtype,
                ie.recipientsmode,
                ie.header
            );
        case IncidentEventType.Created:
            return new IncidentCreatedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.parentid, ie.header);
        case IncidentEventType.PriorityChanged:
            return new PriorityChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.priorityid, ie.header);
        case IncidentEventType.LocationChanged:
            return new LocationChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.location, ie.header);
        case IncidentEventType.DescriptionChanged:
            return new DescriptionChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.description, ie.header);
        case IncidentEventType.ExternalIdChanged:
            return new ExternalIdChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.externalid, ie.header);
        case IncidentEventType.OwnershipChanged:
            return new OwnershipChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.owner, ie.header);
        case IncidentEventType.LocationCleared:
            return new LocationClearedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.header);
        case IncidentEventType.IncidentTypeChanged:
            return new TypeChangedEvent(ie.type, ie.incidentid, ie.processtimeutc, ie.instigatorid, ie.incidenttypeid, ie.header);
        case IncidentEventType.ProcedureStepAnswered:
            return new ProcedureStepAnsweredEvent(
                ie.type,
                ie.incidentid,
                ie.processtimeutc,
                ie.instigatorid,
                ie.procedurestepactiontype ? ie.procedurestepactiontype : ie.actiontype,
                ie.comment,
                ie.stepid,
                ie.nextstepid,
                ie.header,
                ie.optionid
            );
        default:
            throw new Error(`Unsupported event ${type}`);
    }
};

export enum AlterationType {
    Alteration,
    Dispatch,
    Forward,
    Transfer,
    AutoDispatch,
}

export enum RecipientsMode {
    None = 0,
    Everyone = 1,
    Specific = 2,
    Supervisors = 3,
}
