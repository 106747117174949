// Autogenerated: v2/help/Entities/ApiApplication?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { Entity } from './../Entity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IApplicationEntity, ApplicationEntityFields } from '../../Interface/IApplicationEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { IFieldObject } from '../../Interface/IFieldObject';

export class ApplicationEntity extends Entity implements IApplicationEntity {
    // #region REST Properties

    // ApplicationVersion
    public get applicationVersion(): string {
        return this.getField<string>(ApplicationEntityFields.applicationVersionField, ValidFlags.Specific);
    }
    public set applicationVersion(value: string) {
        this.setField<string>(ApplicationEntityFields.applicationVersionField, value);
    }

    // AppType
    public get appType(): string {
        return this.getField<string>(ApplicationEntityFields.appTypeField);
    }
    public set appType(value: string) {
        this.setField<string>(ApplicationEntityFields.appTypeField, value);
    }

    // ExternalApplicationOwner
    public get externalApplicationOwner(): IGuid {
        return this.getFieldGuid(ApplicationEntityFields.externalApplicationOwnerField, ValidFlags.Specific);
    }
    public set externalApplicationOwner(value: IGuid) {
        this.setFieldGuid(ApplicationEntityFields.externalApplicationOwnerField, value);
    }

    // IsInBackwardCompatibilityMode
    public get isInBackwardCompatibilityMode(): boolean {
        return this.getField<boolean>(ApplicationEntityFields.isInBackwardCompatibilityModeField, ValidFlags.Specific);
    }

    // LastLoggedTimestamp
    public get lastLoggedTimestamp(): Date {
        return this.getFieldDate(ApplicationEntityFields.lastLoggedTimestampField, ValidFlags.Specific);
    }

    // LastLoggedUser
    public get lastLoggedUser(): IGuid {
        return this.getFieldGuid(ApplicationEntityFields.lastLoggedUserField, ValidFlags.Specific);
    }

    // LoggedUser
    public get loggedUser(): IGuid {
        return this.getFieldGuid(ApplicationEntityFields.loggedUserField, ValidFlags.Specific);
    }
    public set loggedUser(value: IGuid) {
        this.setFieldGuid(ApplicationEntityFields.loggedUserField, value);
    }

    // MachineName
    public get machineName(): string {
        return this.getField<string>(ApplicationEntityFields.machineNameField, ValidFlags.EntityExtra);
    }

    // StreamingVersion
    public get streamingVersion(): number {
        return this.getField<number>(ApplicationEntityFields.streamingVersionField, ValidFlags.Specific);
    }

    // Version
    public get version(): string {
        return this.getField<string>(ApplicationEntityFields.versionField, ValidFlags.Specific);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Applications);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getMonitorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Monitors', 'Id', true, query, ValidFlags.Membership, transaction);
    }

    // #endregion REST Methods

    // #region Custom Methods

    public async sendRequestAsync(value: IFieldObject, transaction?: ITransaction): Promise<string|null> {
        value.setField('DestinationApp', this.id);
        return super.sendRequestAsync(value, transaction);
    }

    // #endregion Custom Methods

}

