// Autogenerated: v2/help/Classes/ApiAlarmInvestigatingEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { AlarmEvent } from './AlarmEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { IAlarmInvestigatingEvent } from '../Interface/IAlarmInvestigatingEvent';

export class AlarmInvestigatingEvent extends AlarmEvent implements IAlarmInvestigatingEvent {
    // #region REST Properties

    // InvestigatedBy
    public get investigatedBy(): IGuid {
        return this.getFieldGuid('InvestigatedBy');
    }
    public set investigatedBy(value: IGuid) {
        this.setFieldGuid('InvestigatedBy', value);
    }

    // InvestigatedByDesc
    public get investigatedByDesc(): string {
        return this.getField<string>('InvestigatedByDesc');
    }
    public set investigatedByDesc(value: string) {
        this.setField<string>('InvestigatedByDesc', value);
    }

    // InvestigatedByType
    public get investigatedByType(): string {
        return this.getField<string>('InvestigatedByType');
    }
    public set investigatedByType(value: string) {
        this.setField<string>('InvestigatedByType', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('InvestigatedBy', SafeGuid.EMPTY);
        this.setField<string>('InvestigatedByDesc', '');
        this.setField<string>('InvestigatedByType', '');
    }

    // #endregion Methods

}

