import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TimeService } from '../services/time/time.service';

@Pipe({
    name: 'time',
})
export class TimePipe implements PipeTransform {
    constructor(private timeService: TimeService) {}

    public transform(value: Date | number | string, args?: string[]): string {
        if (value) {
            const mmt = moment(value);
            if (args) {
                return this.timeService.formatTime(mmt, ...args);
            }
            return this.timeService.formatTime(mmt);
        }
        return '';
    }
}
