import { Pipe, PipeTransform } from '@angular/core';

/*
  Convert number/float value to video speed format (i.e. : '-0.125' becomes '-1/8x')
  Example : <div [style.background-Color]="row.ArgbColor | argb"></div>
*/
@Pipe({ name: 'videoSpeed' })
export class VideoSpeedPipe implements PipeTransform {
    public static readonly speedSymbol = 'x';
    public static readonly videoSpeedFractions: ReadonlyMap<number, string> = new Map<number, string>([
        [1 / 8, '1/8'],
        [1 / 4, '1/4'],
        [0.333, '1/3'],
        [1 / 2, '1/2'],
    ]);

    public transform(speed?: number): string {
        if (!speed) {
            return '';
        }

        // Registered Fractions
        if (!Number.isInteger(speed)) {
            const speedFraction = VideoSpeedPipe.videoSpeedFractions.get(Math.abs(speed));
            if (speedFraction) {
                return `${speed < 0 ? '-' : ''}${speedFraction}${VideoSpeedPipe.speedSymbol}`;
            }
        }

        // Integers and Unregistered fractions
        return `${speed}${VideoSpeedPipe.speedSymbol}`;
    }
}
