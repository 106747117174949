import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export const deepUpdateValueAndValidity = (group: UntypedFormGroup | UntypedFormArray): void => {
    group.markAllAsTouched();

    // call updateValueAndValidity on all form controls (statusChange will be emitted for each one)
    deepUpdateValueAndValidityInternal(group);
};

const deepUpdateValueAndValidityInternal = (group: UntypedFormGroup | UntypedFormArray): void => {
    for (const control of Object.values(group.controls)) {
        if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
            deepUpdateValueAndValidityInternal(control);
            return;
        }

        control.updateValueAndValidity({ emitEvent: true });
    }
};
