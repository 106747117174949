// Autogenerated: v2/help/Entities/ApiMobileApplication?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { IApplicationEntity, ApplicationEntityFields } from './IApplicationEntity';
import { IGuid } from 'safeguid';

// #region Fields

export class MobileApplicationEntityFields extends ApplicationEntityFields {
    public static altitudeField = 'Altitude';
    public static headingField = 'Heading';
    public static isBlackListedField = 'IsBlackListed';
    public static latitudeField = 'Latitude';
    public static longitudeField = 'Longitude';
    public static mobileVideoUnitIdField = 'MobileVideoUnitId';
    public static oSField = 'OS';
    public static speedField = 'Speed';
    public static timeZoneIdField = 'TimeZoneId';
}

// #endregion Fields

export interface IMobileApplicationEntity extends IApplicationEntity {
    // #region REST Properties

    altitude: number;

    heading: number;

    isBlackListed: boolean;

    latitude: number;

    longitude: number;

    mobileVideoUnitId: IGuid;

    oS: string;

    speed: number;

    timeZoneId: string;

    // #endregion REST Properties

}

