import { FieldObject } from './FieldObject';
import { IFieldObject } from '../Client/Interface/IFieldObject';
import { ITransaction } from '../Client/Interface/ITransaction';
import { ObservableCollection } from './ObservableCollection';
import { RelationFieldObjectTracker } from './RelationFieldObjectTracker';
import { RelationObjectTracker } from './RelationObjectTracker';
import { RelationSimpleFieldObjectTracker } from './RelationSimpleFieldObjectTracker';

export class FieldObjectWithRelations extends FieldObject {
  protected async getFieldObjectRelationAsync<T extends FieldObject, U extends IFieldObject>(
    classType: new () => T,
    relationId: string,
    elementId: string,
    readOnly: boolean,
    allowValueUpdate: boolean,
    validFlags?: number | null,
    transaction?: ITransaction | null,
  ): Promise<ObservableCollection<U> | null> {
    if ( this.roParent == null ) { throw new Error('roParent is null'); }
    const tracker = this.roParent.getRelation(
      relationId,
      new RelationFieldObjectTracker<T, U>(classType, this.roParent, relationId, elementId, readOnly, allowValueUpdate),
      validFlags,
    ) as RelationFieldObjectTracker<T, U>;
    if (!transaction) {
      return await tracker.getValueAsync();
    } else {
      await tracker.pushTransactionAsync(transaction);
      return null;
    }
  }

  public async getDataRelationAsync<T extends FieldObject>(
    classType: new () => T,
    relationId: string,
    allowValueUpdate: boolean,
    validFlags?: number | null,
    transaction?: ITransaction | null,
  ): Promise<T | null> {
    if ( this.roParent == null ) { throw new Error('roParent is null'); }
    const tracker = this.roParent.getRelation(
      relationId,
      new RelationSimpleFieldObjectTracker<T, IFieldObject>(classType, this.roParent, relationId, allowValueUpdate),
      validFlags,
    ) as RelationSimpleFieldObjectTracker<T, IFieldObject>;
    if (!transaction) {
      return await tracker.getValueAsync();
    } else {
      await tracker.pushTransactionAsync(transaction);
      return null;
    }
  }

  public async getDataRelation2Async<T>(
    relationId: string,
    fieldId: string,
    validFlags?: number | null,
    transaction?: ITransaction | null,
  ): Promise<T | null> {
    if ( this.roParent == null ) { throw new Error('roParent is null'); }
    const tracker = this.roParent.getRelation(
      relationId,
      new RelationObjectTracker<T>(this.roParent, relationId, fieldId),
      validFlags,
    ) as RelationObjectTracker<T>;
    if (!transaction) {
      return await tracker.getValueAsync();
    } else {
      await tracker.pushTransactionAsync(transaction);
      return null;
    }
  }
}
