import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ButtonFlavor, Icon } from '@genetec/gelato';
import { GenMenu, MeltedIcon } from '@genetec/gelato-angular';
import { MeltedModalAction } from '@genetec/gelato-angular/lib/components/gen-melted-modal/enums/melted-modal-action.enum';
import { ActivityTrailClient, ITimezoneInfo, SaveSnapshotInfo } from '@modules/shared/api/api';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { TimelineComponent } from '@modules/shared/components/timeline/timeline.component';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import {
    CommandBindings,
    CommandContext,
    CommandsService,
    COMMANDS_SERVICE,
    ContentExtensionServicesProvider,
    CONTENT_SERVICES_PROVIDER,
    PluginComponentCommandHandler,
} from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { ContextMenuFactory } from '@modules/shared/services/context-menu/context-menu.factory';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TileItem } from '@modules/tiles/models/tile-item';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import * as FileSaver from 'file-saver';
import { uniqueId } from 'lodash-es';
import { IVideoPlayer } from 'Marmot/ivideoplayer';
import {
    AudioAvailabilityChangeEvent,
    AudioStateChangeEvent,
    PlayerMode,
    PlayerModeChangeEvent,
    PlayerState,
    PlayerStateChangeEvent,
    PlaySpeedChangeEvent,
} from 'Marmot/Marmot/gwp';
import moment from 'moment';
import { CameraTypes } from 'RestClient/Client/Enumerations/CameraType';
import { CameraEntityFields, ICameraEntity } from 'RestClient/Client/Interface/ICameraEntity';
import { IEntityCacheTask, modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CameraEntity } from 'RestClient/Client/Model/Video/CameraEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { CameraHelper } from '@modules/video/utilities/camerahelper';
import { Enum } from '@src/app/utilities/types';
import { FullscreenService } from '@modules/shared/services/fullscreen/fullscreen.service';
import { RecordingStates } from '@modules/shared/interfaces/recording-states';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DisplayContext } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentOverlayService } from '@modules/shared/services/content-overlay/content-overlay.service';
import { CameraFeatureCapabilties } from 'RestClient/Client/Enumerations/CameraFeatureCapabilties';
import { GenMeltedModalParams } from '@genetec/gelato-angular/lib/components/gen-melted-modal/interfaces/gen-melted-modal-params';
import { InternalContentPluginDescriptor } from '../../../shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent, PluginComponentExposure } from '../../../shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '../../../shared/interfaces/plugins/public/plugin-types';
import { ExportMp4Request, SerializableDateTimeSpan } from '../../api/api';
import { VideoContentTypes } from '../../enumerations/video-content-types';
import { VideoExportService } from '../../services/video-export.service';
import { RenderingHelper } from '../../utilities/renderinghelper';
import { ExportVideoComponent } from '../export-video/export-video.component';
import { GotoTimeComponent } from '../goto-time/goto-time.component';
import { PtzPopupService } from '../ptz-controls/services/ptz-popup.service';
import { PtzPrivileges } from '../ptz-controls/services/ptz-privileges';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { VideoButtonsLeftBar, VideoButtonsRightBar } from './buttons/enum/buttons.enum';
import { TileButton } from './buttons/interfaces/tile-button.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export const isContentSupported = (content: Content): boolean => {
    if (content?.type.equals(VideoContentTypes.Video)) {
        return content.grantedPrivileges.some(
            (privilege) => privilege.equals(KnownPrivileges.allowVideoPrivilege) || privilege.equals(KnownPrivileges.allowPlaybackVideoPrivilege)
        );
    }
    return false;
};

@UntilDestroy()
@Component({
    selector: 'app-video-controls',
    templateUrl: './video-controls.component.html',
    styleUrls: ['./video-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalContentPluginDescriptor({
    type: VideoControlsComponent,
    pluginTypes: [PluginTypes.Tile, PluginTypes.Widget],
    exposure: {
        id: VideoControlsComponent.pluginId,
        priority: 5,
        requiredParentPlugin: VideoPlayerComponent.pluginId,
        availableCommands: [SharedCommands.SaveSnapshot],
    } as PluginComponentExposure,
    isContentSupported,
    requirements: { features: [KnownFeatures.videoId], licenses: [KnownLicenses.video] },
})
export class VideoControlsComponent extends TrackedComponent implements AfterViewInit, OnInit, OnDestroy, ContentPluginComponent, PluginComponentCommandHandler {
    public static pluginId = SafeGuid.parse('71101942-A977-4CC0-A04A-5AA28C4FC69D');
    private static MinDate = new Date(0);

    @ViewChild('timeline') public timeline?: TimelineComponent;

    @ViewChild('PtzPopupTemplate') public ptzPopupTemplate!: TemplateRef<any>;

    @Input() public dataContext?: TileItem;

    @ViewChild('GotoTimeModal')
    private gotoTimeModal!: GotoTimeComponent;

    public gotoTimeModalId = SafeGuid.newGuid().toString();

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly componentId: number | string;
    public readonly timeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

    public get fullscreenElement(): ElementRef | undefined {
        return document?.fullscreenElement ? new ElementRef(document.fullscreenElement) : undefined;
    }

    public title = '';
    public entityId = SafeGuid.EMPTY;
    public entityContent?: Content;

    public isPaused = false;
    public isLive = true;
    public isSlowMotion = false;
    public isAudioAvailable = false;
    public isListening = false;
    public isWithinTile = false;
    public isCameraStreaming = false;
    public canListen = false;

    // Recording states
    public recordingStates$: Observable<RecordingStates>;

    public timestampText = '';
    public relativeTimeText = '';
    public isMoreMenuOpen = false;

    public get canShowPlayback(): boolean {
        return this.isPlaybackCapabilitySupported && this.hasPlaybackVideoPrivilege;
    }

    public get moreMenuItemSource(): ContextMenuItem[] {
        return this.moreMenuItemSourceInternal;
    }

    public set moreMenuItemSource(newMoreMenuItemSource: ContextMenuItem[]) {
        this.moreMenuItemSourceInternal = newMoreMenuItemSource;
        this.destroyMoreMenu();
    }

    public content?: Content;
    public videoPlayer?: IVideoPlayer;

    // Buttons
    public leftBarVisibleButtons: TileButton[] = []; // Buttons that fit in the player's bottom bar directly
    public rightBarVisibleButtons: TileButton[] = [];

    // Privileges
    public hasLiveVideoPrivilege = false;
    public hasPlaybackVideoPrivilege = false;
    public hasBasicPtzPrivilege = false;
    public canAddBookmark = true;
    public canSaveSnapshots = false;

    // Determine if the current camera is a ptz
    public isPtz = false;
    public ptzPopup?: HTMLGenPopupElement | null;

    // Speed
    public currentSpeed = 1;
    private readonly speeds = {
        forward: {
            normalMotion: [2, 4, 6, 8, 10, 20, 40, 100],
            slowMotion: [1 / 8, 1 / 4, 0.333, 1 / 2],
        },
        backward: {
            normalMotion: [-10, -20, -40, -100],
            slowMotion: [-1 / 8, -1 / 4, -0.333, -1 / 2],
        },
    };

    private entityTimezone?: ITimezoneInfo;
    private parentHost?: Element | null;

    // Buttons
    private leftBarAllowedButtons: TileButton[] = []; // Buttons that the user has the privileges to use/see
    private leftBarButtonsPriority: string[] = []; // Button IDs ordered by priority, the least important ones will appear in the More menu first
    private leftBarAdditionalButtons: ContextMenuItem[] = []; // Buttons that are displayed in the More menu because they don't fit in the player's bottom bar directly

    private rightBarAllowedButtons: TileButton[] = [];
    private rightBarButtonsPriority: string[] = [];
    private rightBarAdditionalButtons: ContextMenuItem[] = [];

    private readonly BUTTON_WIDTH = 40;
    private lastRightBarAvailableWidth = 0; // Used to recalculate the visible buttons (the Audio button for example causes a race condition)
    private lastLeftBarAvailableWidth = 0;
    private computeButtonsVisibilityDebounced: Subject<void> = new Subject<void>();

    private isPlaybackCapabilitySupported = false;

    // Privileges
    private hasStartRecordingPrivilege = false;
    private hasExportVideoPrivilege = false;
    private hasBookmarkPrivilege = false;
    private hasSaveSnapshotsPrivilege = false;

    private entityCacheTask!: IEntityCacheTask;

    private playerStateChangedSubject = new Subject<PlayerState>();

    private moreMenu?: ComponentRef<GenMenu>;

    private moreMenuCloseSubscription?: Subscription;

    private moreMenuItemSourceInternal: ContextMenuItem[] = [];

    // Recording states
    private recordingStates: RecordingStates = { isRecording: false, isRecordingLocked: false };

    // Subjects
    private recordingStatesSubject = new BehaviorSubject<RecordingStates>(this.recordingStates);

    //#endregion

    //#region Constructor

    constructor(
        private hostElement: ElementRef<Element>,
        private zone: NgZone,
        private translateService: TranslateService,
        private timeService: TimeService,
        private scClientService: SecurityCenterClientService,
        private videoExportService: VideoExportService,
        private ptzPopupService: PtzPopupService,
        private viewContainerRef: ViewContainerRef,
        private contextMenuFactory: ContextMenuFactory,
        private activityTrailClient: ActivityTrailClient,
        @Inject(CONTENT_SERVICES_PROVIDER) private tilesService: ContentExtensionServicesProvider,
        @Inject(COMMANDS_SERVICE) public commandsService: CommandsService,
        trackingService: TrackingService,
        private fullscreenService: FullscreenService,
        private changeDetectorRef: ChangeDetectorRef,
        private contentOverlayService: ContentOverlayService
    ) {
        super(trackingService);

        this.recordingStates$ = this.recordingStatesSubject.asObservable();

        this.computeButtonsVisibilityDebounced
            .pipe(debounceTime(200))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.computeButtonsVisibility());
        this.componentId = uniqueId();
        this.ptzPopupService.viewContainerRef = this.viewContainerRef;

        this.playerStateChangedSubject
            .pipe(debounceTime(200))
            .pipe(untilDestroyed(this))
            .subscribe((playerState) => this.updateIsCameraStreaming(playerState));

        this.fullscreenService.fullscreenElementChanged$.pipe(untilDestroyed(this)).subscribe(() => {
            this.destroyMoreMenu();
        });

        this.recordingStates$.pipe(untilDestroyed(this)).subscribe((recordingStates) => (this.recordingStates = recordingStates));
    }

    @HostListener('document:keydown', ['$event']) async handleGlobalKeyboardEvent(event: KeyboardEvent): Promise<void> {
        const key = event.key;
        if (key === 'Escape') {
            await this.ptzPopupService.closeAllPopups();
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as TileItem;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public getCommandBindings(): CommandBindings {
        const bindings = new CommandBindings();
        bindings.addCommand({
            commandId: SharedCommands.ExportVideo,
            executeCommandHandler: (executeCommandData) => {
                const commandContext = executeCommandData.commandContext;
                if (this.hasExportVideoPrivilege && commandContext?.type.equals(ContextTypes.Specific) && isNonEmptyString(commandContext.data)) {
                    this.downloadAsync(commandContext.data).fireAndForget();
                    executeCommandData.isHandled = true;
                } else {
                    // if we are capturing this we are the target, so download from our specific data
                    this.downloadAsync(undefined).fireAndForget();
                    executeCommandData.isHandled = true;
                }
            },
            isCommandAvailableHandler: () => this.hasExportVideoPrivilege,
        });
        bindings.addCommand({
            commandId: SharedCommands.FastForward,
            executeCommandHandler: (executeCommandData) => {
                this.fastForward();
                executeCommandData.isHandled = true;
            },
            isCommandAvailableHandler: () => !this.isLive,
            canExecuteCommandHandler: () => this.isCameraStreaming,
        });
        bindings.addCommand({
            commandId: SharedCommands.PauseVideo,
            executeCommandHandler: (executeCommandHandler) => {
                this.pause();
                executeCommandHandler.isHandled = true;
            },
            canExecuteCommandHandler: () => this.isCameraStreaming,
        });
        bindings.addCommand({
            commandId: SharedCommands.PlayVideo,
            executeCommandHandler: (executeCommandHandler) => {
                this.playLive();
                executeCommandHandler.isHandled = true;
            },
            canExecuteCommandHandler: () => this.isCameraStreaming,
        });
        bindings.addCommand({
            commandId: SharedCommands.Rewind,
            executeCommandHandler: (executeCommandHandler) => {
                this.rewind();
                executeCommandHandler.isHandled = true;
            },
            canExecuteCommandHandler: () => this.isCameraStreaming,
        });
        bindings.addCommand({
            commandId: SharedCommands.SaveSnapshot,
            executeCommandHandler: (executeCommandHandler) => {
                this.takeSnapshot().fireAndForget();
                executeCommandHandler.isHandled = true;
            },
            isCommandAvailableHandler: () => this.canSaveSnapshots,
            canExecuteCommandHandler: () => this.isCameraStreaming,
        });
        bindings.addCommand({
            commandId: SharedCommands.SlowMotion,
            executeCommandHandler: (executeCommandHandler) => {
                this.slowMotion();
                executeCommandHandler.isHandled = true;
            },
            canExecuteCommandHandler: () => this.isCameraStreaming,
        });
        // Hijack shareEntity command to set playbackTime
        bindings.addCommand({
            commandId: SharedCommands.ShareEntity,
            executeCommandHandler: (executeCommandHandler) => {
                const commandContext = executeCommandHandler.commandContext;
                if (this.content && commandContext) {
                    let entityId = SafeGuid.parse(this.content.source);
                    if (this.content.contextContent?.source) {
                        entityId = SafeGuid.parse(this.content.contextContent.source);
                    }

                    if (entityId) {
                        commandContext.type = ContextTypes.Specific;
                        if (!this.isLive) {
                            commandContext.data = { entityId, timestamp: this.videoPlayer?.lastFrameTime };
                        } else {
                            commandContext.data = { entityId, timestamp: undefined };
                        }
                    }
                }
                executeCommandHandler.isHandled = false;
            },
        });
        // Hijack addBookmark command to set playbackTime
        bindings.addCommand({
            commandId: SharedCommands.AddBookmark,
            executeCommandHandler: (executeCommandHandler) => {
                const commandContext = executeCommandHandler.commandContext;
                if (this.content && this.videoPlayer && commandContext) {
                    const entityId = SafeGuid.parse(this.content.source);
                    let timestamp: Date | undefined;
                    // In playback, add the timestamp
                    if (!this.isLive) {
                        timestamp = this.videoPlayer?.lastFrameTime;
                    }

                    const params = { entityId, timestamp };
                    commandContext.type = ContextTypes.Specific;
                    commandContext.data = params;
                }
                executeCommandHandler.isHandled = false;
            },
            canExecuteCommandHandler: () => this.isCameraStreaming,
        });
        return bindings;
    }

    //#endregion

    //#region Ng...

    async ngOnInit() {
        super.ngOnInit();

        if (this.content) {
            this.entityContent = this.content;
            this.title = this.entityContent.title;
            this.entityId = SafeGuid.parse(this.entityContent.source);

            // Read privileges
            const priv = this.content.grantedPrivileges;
            this.hasLiveVideoPrivilege = priv.some((item) => item.equals(KnownPrivileges.allowVideoPrivilege));
            this.hasPlaybackVideoPrivilege = priv.some((item) => item.equals(KnownPrivileges.allowPlaybackVideoPrivilege));
            this.hasBasicPtzPrivilege = priv.some((item) => item.equals(KnownPrivileges.basicPtzOperationsPrivilege));
            this.hasBookmarkPrivilege = priv.some((item) => item.equals(KnownPrivileges.addBookmarkPrivilege));
            this.hasSaveSnapshotsPrivilege = priv.some((item) => item.equals(KnownPrivileges.saveAndPrintSnapshotsPrivilege));
            this.hasStartRecordingPrivilege = priv.some((item) => item.equals(KnownPrivileges.recordManuallyPrivilege));
            this.hasExportVideoPrivilege = priv.some((item) => item.equals(KnownPrivileges.exportVideoPrivilege));

            // determine if the camera is a PTZ or not
            if (this.entityContent.parameters?.hasField(CameraEntityFields.cameraSubTypeField) === true) {
                this.isPtz = this.entityContent.parameters.getField<string>(CameraEntityFields.cameraSubTypeField) === CameraTypes.PTZCamera;
            }

            // Determines whether or not the input audio is permitted on the video unit
            if (this.entityContent?.parameters?.hasField(CameraEntityFields.audioStreamInField)) {
                const audioStreamInId = this.entityContent.parameters.getFieldGuid(CameraEntityFields.audioStreamInField);
                this.canListen = !audioStreamInId.isEmpty();
            }
        }

        await this.updateFromCameraCapabilities();

        // Wait until we read the privileges before initializing the buttons
        this.initializeButtons();

        const scClient = this.scClientService?.scClient;
        if (scClient) {
            this.entityCacheTask = scClient.buildEntityCache([CameraEntityFields.timeZoneIdField, CameraEntityFields.recordingStateField]);
            const cameraEntity = (await this.entityCacheTask.getEntityAsync<CameraEntity, ICameraEntity>(CameraEntity, this.entityId, true)) as CameraEntity;
            if (cameraEntity) {
                this.entityTimezone = await this.timeService.retrieveTimezoneAsync(cameraEntity.timeZoneId);
                this.initTimeline();
                this.updateRecordingState(cameraEntity.recordingState);

                await this.entityCacheTask.detectFieldChangeAsync(
                    cameraEntity,
                    () => {
                        const _ = cameraEntity?.recordingState;
                    },
                    ((_: ICameraEntity, newValue: string, __: string) => {
                        this.updateRecordingState(newValue);
                        this.setMoreMenuItemSource();
                        return new Deferred<void>(true).promise;
                    }) as modificationHandlerField<string>
                );
            }
        }

        this.setMoreMenuItemSource();
    }

    ngAfterViewInit() {
        // take care, timeline can be undefined if we do not have the playback privilege
        setTimeout(() => {
            this.zone.runOutsideAngular(() => {
                if (this.content) {
                    this.videoPlayer = this.tilesService.getService<IVideoPlayer>(this.content.id);
                    if (this.videoPlayer) {
                        this.videoPlayer.onPlayerModeChanged.register(this.onPlayerModeChangedHandler);
                        this.videoPlayer.onPlaySpeedChanged.register(this.onPlayerSpeedChangedHandler);
                        this.videoPlayer.onPlayerStateChanged.register(this.onPlayerStateChangedHandler);
                        this.videoPlayer.onAudioAvailabilityChanged.register(this.onPlayerAudioAvailabilityChangedHandler);
                        this.videoPlayer.onAudioStateChanged.register(this.onPlayerAudioStateChangedHandler);

                        this.isAudioAvailable = this.videoPlayer.isAudioAvailable;

                        this.computeRightButtonsVisibility(this.lastRightBarAvailableWidth);
                    }
                }
            });

            // subscribe to the RenderingHelper that is used to reduce the number of Angular's change detection
            RenderingHelper.onRefresh.pipe(untilDestroyed(this)).subscribe(() => this.onRenderingRefresh());

            // determine whether we are inside a TileComponent
            const parentTile = this.hostElement.nativeElement.closest('app-tile');
            if (parentTile) {
                this.isWithinTile = true;
            }
            this.parentHost = this.hostElement.nativeElement.closest('app-navigation-plugin-host');
        });
        this.parentHost = this.hostElement.nativeElement.closest('app-navigation-plugin-host');
    }

    async ngOnDestroy() {
        if (this.videoPlayer) {
            this.videoPlayer.onPlayerModeChanged.unregister(this.onPlayerModeChangedHandler);
            this.videoPlayer.onPlaySpeedChanged.unregister(this.onPlayerSpeedChangedHandler);
            this.videoPlayer.onPlayerStateChanged.unregister(this.onPlayerStateChangedHandler);
            this.videoPlayer.onAudioAvailabilityChanged.unregister(this.onPlayerAudioAvailabilityChangedHandler);
            this.videoPlayer.onAudioStateChanged.unregister(this.onPlayerAudioStateChangedHandler);
        }

        this.recordingStatesSubject.complete();

        if (this.entityCacheTask) {
            await this.entityCacheTask.dispose();
        }

        this.destroyMoreMenu();

        super.ngOnDestroy();
    }

    //#endregion

    //#region Methods
    public playLive(): void {
        this.videoPlayer?.playLive();
    }

    public togglePlayingState(): void {
        if (this.isPaused) {
            this.resume();
        } else {
            this.pause();
        }
    }

    public resume(maintainSpeed: boolean = this.isSlowMotion): void {
        this.videoPlayer?.resume(maintainSpeed);
    }

    public pause(maintainSpeed: boolean = this.isSlowMotion): void {
        this.videoPlayer?.pause(maintainSpeed);
    }

    public rewind(): void {
        // if we are in live mode, switch to playback
        if (this.isLive) {
            this.switchToNearLive();
        }

        this.changeSpeed(false);
    }

    public fastForward(): void {
        this.changeSpeed(true);
    }

    public slowMotion(): void {
        // if we are in live mode, switch to playback
        if (this.isLive) {
            this.switchToNearLive();
        }

        const isSlowMotion = !this.isSlowMotion;
        if (isSlowMotion) {
            const speeds = this.currentSpeed > 0 ? this.speeds.forward : this.speeds.backward;
            this.videoPlayer?.setPlaySpeed(speeds.slowMotion[0]);
        } else {
            this.videoPlayer?.setPlaySpeed(1);
        }
    }

    public toggleExpandWidget(): void {
        if (this.parentHost) {
            this.contentOverlayService.setOverlay(this.parentHost);
        }
    }

    public jumpBackAsync(seconds: number): void {
        if (this.videoPlayer) {
            const lastFrameReceived = this.videoPlayer?.lastFrameTime;
            if (lastFrameReceived) {
                const toGo = moment(lastFrameReceived).subtract(seconds, 'seconds');
                this.videoPlayer.seek(toGo.toDate());
            }
        }
    }

    public jumpForwardAsync(seconds: number): void {
        if (this.videoPlayer) {
            const lastReceivedFrame = this.videoPlayer?.lastFrameTime;
            if (lastReceivedFrame) {
                let toGo = moment(lastReceivedFrame).add(seconds, 'seconds');

                // if we do not allow live video, ensure we stay in near live
                if (!this.hasLiveVideoPrivilege && toGo.isAfter(moment())) {
                    toGo = moment().subtract(5, 'seconds');
                }

                this.videoPlayer.seek(toGo.toDate());
            }
        }
    }

    public addBookmark(message?: string): void {
        // check the add bookmark privilege
        if (this.hasBookmarkPrivilege && this.content && this.videoPlayer) {
            const entityId = SafeGuid.parse(this.content.source);
            let timestamp: Date | undefined;
            // In playback, add the timestamp
            if (!this.isLive) {
                timestamp = this.videoPlayer?.lastFrameTime;
            }

            const params = { entityId, timestamp, message };
            const commandContext: CommandContext = { type: ContextTypes.Specific, data: params };

            // we want to force execution on this component to prevent the command being routed to the active tile when the user executed the command in the side pane.
            this.commandsService.forceExecuteCommand(SharedCommands.AddBookmark, commandContext, undefined, this.hostElement?.nativeElement);
        }
    }

    public expandTile(): void {
        this.commandsService.executeCommand(SharedCommands.ExpandTile);
    }

    public gotoTime(): void {
        // check the allow playback privilege
        if (this.hasPlaybackVideoPrivilege && this.videoPlayer) {
            const gotoTimeComponent = this.fullscreenService.displayModal(this.gotoTimeModal);
            if (gotoTimeComponent) {
                gotoTimeComponent.genModalAction
                    .pipe(untilDestroyed(this))
                    .subscribe((modalAction: MeltedModalAction) => this.handleGotoTime(modalAction, gotoTimeComponent.timestamp));
            }
        }
    }

    public async takeSnapshot(): Promise<void> {
        // ensure the user has privilege to save the snapshot
        if (this.hasSaveSnapshotsPrivilege) {
            const blob = await this.videoPlayer?.takeSnapshotAsync();
            if (blob != null) {
                // build the default snapshot's filename
                let defaultFilename = '';
                // the browser saves it as png by default
                const defaultExtension = '.png';

                // append the content's title
                if (this.entityContent) {
                    defaultFilename = this.entityContent.title;
                }

                // append the date/time
                const lastFrameTime = this.videoPlayer?.lastFrameTime;
                let timestamp;
                if (lastFrameTime) {
                    // 'L LTS' => L: "MM/DD/YYYY" LTS: "h:mm:ss A"
                    timestamp = this.timeService.formatTime(lastFrameTime, 'L LTS', true, true, this.entityTimezone, false);
                    defaultFilename += ` (${timestamp})`;
                    // remove invalid characters for a file name added by the timestamp
                    defaultFilename = defaultFilename.replaceAll(':', '_');
                }

                // save the file (the FileSaver already ensure the filename is valid by replacing forbidden characters by '_')
                FileSaver.saveAs(blob, defaultFilename + defaultExtension);

                // log activity trail
                const saveSnapshotInfo = new SaveSnapshotInfo();
                saveSnapshotInfo.fileName = defaultFilename + defaultExtension;
                saveSnapshotInfo.fileType = defaultExtension;
                if (lastFrameTime) {
                    saveSnapshotInfo.videoFrameDateTime = timestamp;
                }
                if (this.content) {
                    saveSnapshotInfo.camera = SafeGuid.parse(this.content.source);
                }
                await this.activityTrailClient.saveSnapshot(saveSnapshotInfo).toPromise();
            }
        }
    }

    public toggleListen(): void {
        this.commandsService.executeCommand(SharedCommands.ToggleListen);

        this.computeRightButtonsVisibility(this.lastRightBarAvailableWidth);
    }

    public async toggleMoreMenu(): Promise<void> {
        this.buildMoreMenu();

        await this.moreMenu?.instance.toggle();

        this.isMoreMenuOpen = this.moreMenu?.instance.open ?? false;
    }

    public async togglePtzControls(event?: Event): Promise<void> {
        let id = '';
        if (!event) {
            id = `#video-controls-more-${this.componentId}`;
        } else {
            id = `#${(event?.currentTarget as HTMLElement).id}`;
        }
        const key = this.generateUniqueKeyForContent(this.content);
        // Create the popup if it doesn't already exist. Otherwise will return null.
        const newPopup = this.ptzPopupService.createPtzPopupRef(this.ptzPopupTemplate, key, id);
        if (newPopup) {
            this.ptzPopup = newPopup;
        }
        await this.ptzPopupService.togglePopup(key, id);
    }

    public onHidePtzRequested(request: boolean): void {
        if (request) {
            // limitation for now, we can't know when a user moves or deletes a tile.
            this.ptzPopupService.destroyAllPopups();
        }
    }

    private changeSpeed(isForward: boolean) {
        const speedDirectionValues = isForward ? this.speeds.forward : this.speeds.backward;
        const speeds = this.isSlowMotion ? speedDirectionValues.slowMotion : speedDirectionValues.normalMotion;

        let newSpeed = speeds[0];
        let speedIndex = speeds.findIndex((speed) => speed === this.currentSpeed);

        if (speedIndex >= 0) {
            speedIndex = Math.min(speedIndex + 1, speeds.length - 1);
            newSpeed = speeds[speedIndex];
        }

        if (this.currentSpeed !== newSpeed) {
            this.videoPlayer?.setPlaySpeed(newSpeed);
        } else {
            this.videoPlayer?.setPlaySpeed(speeds[0]);
        }
    }

    private moreItemMenuClosed = () => {
        this.isMoreMenuOpen = false;
    };

    private generateUniqueKeyForContent(content?: Content): IGuid {
        return content?.id ?? SafeGuid.EMPTY;
    }

    private initTimeline() {
        if (this.content && this.timeline) {
            let entityId!: IGuid;
            // inform the timeline about the source & camera entities
            if (this.content.contextContent?.source) {
                entityId = SafeGuid.parse(this.content.contextContent.source);
            }

            const cameraId = SafeGuid.parse(this.content.source);

            // setup privileges
            this.timeline.allowPlayback = this.hasPlaybackVideoPrivilege;

            // refesh the timeline by providing it entities to query
            this.timeline.refresh(cameraId, entityId, this.entityTimezone);
        }
    }

    // switch in playback mode to near live position
    private switchToNearLive(): void {
        this.videoPlayer?.seek(new Date());
    }

    private async downloadAsync(tokensString?: string) {
        // ensure the user has the export video privilege
        if (this.hasExportVideoPrivilege) {
            let handled = false;
            const filename = this.content?.title as string;

            // if an argument is specified, use it
            if (tokensString) {
                const tokens = tokensString.split(' ');
                if (tokens.length === 2) {
                    // tslint:disable-next-line: radix
                    const quanta = Number.parseInt(tokens[0]);
                    const units = tokens[1] as moment.unitOfTime.DurationConstructor;
                    if (!Number.isNaN(quanta) && units) {
                        const endTime = moment().toDate();
                        const startTime = moment(endTime).subtract(quanta, units).toDate();
                        if (this.videoPlayer) {
                            const model2 = new ExportMp4Request({
                                cameraId: new SafeGuid(this.entityId),
                                timeRange: new SerializableDateTimeSpan({
                                    end: endTime.toISOString(),
                                    start: startTime.toISOString(),
                                }),
                            });
                            await this.videoExportService.exportAsync(model2, filename);
                            handled = true;
                        }
                    }
                }
            }

            if (!handled) {
                // ExportVideoComponent should need Timezone to display times accordingly
                // only use params for entityId. ExportVideoComponent does public properties that can be set.
                const params: GenMeltedModalParams = {};

                if (this.timeline) {
                    params.entityId = this.entityId;

                    // try to extract the looping range
                    const range = this.timeline.getLoop();
                    if (this.entityCacheTask) {
                        const cameraEntity = (await this.entityCacheTask.getEntityAsync<CameraEntity, ICameraEntity>(CameraEntity, this.entityId, true)) as CameraEntity;
                        if (cameraEntity) {
                            this.entityTimezone = await this.timeService.retrieveTimezoneAsync(cameraEntity.timeZoneId);

                            let startTime;
                            let endTime;

                            if (range.start && range.end) {
                                startTime = this.timeService.formatTime(range.start, this.timeFormat, true, false, this.entityTimezone, false);
                                endTime = this.timeService.formatTime(range.end, this.timeFormat, true, false, this.entityTimezone, false);
                            } else {
                                startTime = this.timeService.formatTime(this.timeline.startTime, this.timeFormat, true, false, this.entityTimezone, false);
                                endTime = this.timeService.formatTime(this.timeline.endTime, this.timeFormat, true, false, this.entityTimezone, false);
                            }

                            const exportVideoModal = this.fullscreenService.displayModal(ExportVideoComponent, params);

                            exportVideoModal.form.controls.startTime.setValue(startTime);
                            exportVideoModal.form.controls.endTime.setValue(endTime);

                            handled = true;
                        }
                    }
                }
            }
        }
    }

    private toggleRecording() {
        // ensure we have the privilege
        if (this.hasStartRecordingPrivilege) {
            const commandContext: CommandContext = { type: ContextTypes.Content, data: this.content };
            // we want to force execution on this component to prevent the command being routed to the active tile when the user executed the command in the side pane.
            this.commandsService.forceExecuteCommand(SharedCommands.StartStopRecording, commandContext, undefined, this.hostElement?.nativeElement);
        }
    }

    private setMoreMenuItemSource() {
        this.moreMenuItemSource = [];

        if (this.hasExportVideoPrivilege) {
            this.moreMenuItemSource.push({
                id: 'download',
                text: this.translateService.instant('STE_BUTTON_DOWNLOAD') as string,
                icon: MeltedIcon.Download,
                actionItem: {
                    execute: () => this.downloadAsync(),
                    isAllowed: () => this.hasExportVideoPrivilege,
                },
            });
        }
    }

    private updateRecordingState(state: string) {
        this.recordingStatesSubject.next({
            isRecording: CameraHelper.isRecording(state),
            isRecordingLocked: CameraHelper.isRecordingLocked(state),
        });
    }

    private getRecordColor(): string {
        if (this.recordingStates.isRecording) return this.Color.Fragola;
        return '';
    }

    private getRecordIcon(): MeltedIcon {
        return this.recordingStates.isRecordingLocked ? MeltedIcon.RecordLocked : MeltedIcon.Record;
    }

    private getRecordTooltip(): string {
        let resourceId: string | undefined;
        if (this.recordingStates.isRecording) resourceId = this.recordingStates.isRecordingLocked ? 'STE_TOOLTIP_RECORDING_ON_LOCKED' : 'STE_ACTION_STOPRECORDING';
        else resourceId = this.recordingStates.isRecordingLocked ? 'STE_TOOLTIP_RECORDING_OFF_LOCKED' : 'STE_BUTTON_STARTRECORDING';
        return this.translateService.instant(resourceId) as string;
    }

    private updateTime(frameTime: Date | null | undefined) {
        // ensure the player is started to prevent exceptions
        if (this.videoPlayer?.isStarted !== true) return;

        let newFrameTime = frameTime;
        if (!newFrameTime) {
            newFrameTime = this.videoPlayer?.lastFrameTime;
        }

        let text = this.translateService.instant('STE_LABEL_VIDEOTIMELINE_SEEKING') as string;
        let relativeTime = '';

        if (!this.isLive) {
            if (newFrameTime && newFrameTime > VideoControlsComponent.MinDate) {
                text = this.timeService.formatTime(newFrameTime, 'LTS', true, true, this.entityTimezone);
                relativeTime = this.timeService.formatRelative(newFrameTime);
            }
        } else {
            text = this.translateService.instant('STE_LABEL_VIDEOTIMELINE_LIVE') as string;
        }

        this.timestampText = text;
        this.relativeTimeText = relativeTime;
    }

    private async updateFromCameraCapabilities() {
        const scClient = this.scClientService?.scClient;
        if (scClient) {
            this.entityCacheTask = scClient.buildEntityCache([CameraEntityFields.timeZoneIdField, CameraEntityFields.recordingStateField]);
            const cameraEntity = (await this.entityCacheTask.getEntityAsync<CameraEntity, ICameraEntity>(CameraEntity, this.entityId, true)) as CameraEntity;
            if (cameraEntity) {
                const capabilities = await cameraEntity.getFeatureCapabilitiesAsync();
                if (!capabilities) {
                    return;
                }
                const bookmarkCapabilities = capabilities.firstOrDefault((c) => c?.id === CameraFeatureCapabilties.Bookmarks);
                this.canAddBookmark = bookmarkCapabilities?.supported ?? false;

                const playbackCapabilities = capabilities.firstOrDefault((c) => c?.id === CameraFeatureCapabilties.Playback);
                this.isPlaybackCapabilitySupported = playbackCapabilities?.supported ?? false;
            }
        }
    }

    //#endregion

    /* eslint-disable @typescript-eslint/member-ordering */

    //#region Event Handlers

    private handleGotoTime(modalAction: MeltedModalAction, timestamp: string) {
        if (this.videoPlayer && timestamp && modalAction === 'default') {
            let timezone: ITimezoneInfo | undefined;
            if (this.timeService.useDeviceTimezone) {
                timezone = this.entityTimezone;
            }
            // The received timestamp will be in computer local time. We need to converted it to the local time of the unit's timezone
            const seekTime = this.timeService.convertToTimeZone(timestamp, timezone).toDate();
            this.videoPlayer.seek(seekTime);
        }
    }

    public async onMoreMenuItemClick(clickedItem: ContextMenuItem): Promise<void> {
        switch (clickedItem?.id) {
            case 'record':
                this.toggleRecording();
                break;
            case 'download':
                await this.downloadAsync();
                break;
            default:
                break;
        }
    }

    private onRenderingRefresh() {
        this.updateTime(null);
    }

    private onPlayerModeChangedHandler = (event: PlayerModeChangeEvent): void => {
        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.isLive = event.playerMode === PlayerMode.live;
            this.changeDetectorRef.markForCheck();
            this.updateTime(null);
        });
    };

    private onPlayerSpeedChangedHandler = (event: PlaySpeedChangeEvent): void => {
        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.currentSpeed = event.playSpeed;
            // determine if we are in slow motion motion (between -1 and 1)
            this.isSlowMotion = this.currentSpeed > -1 && this.currentSpeed < 1;
            this.changeDetectorRef.markForCheck();
        });
    };

    private onPlayerStateChangedHandler = (event: PlayerStateChangeEvent): void => {
        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.playerStateChangedSubject.next(event.playerState);
            this.isPaused = event.playerState === PlayerState.Paused;
            this.changeDetectorRef.markForCheck();
            this.updateTime(null);
        });
    };

    private updateIsCameraStreaming(playerState: PlayerState) {
        this.isCameraStreaming = [
            PlayerState.BeginReached,
            PlayerState.Rewinding,
            PlayerState.Playing,
            PlayerState.Paused,
            PlayerState.EndReached,
            PlayerState.Buffering,
            PlayerState.NoVideoSequenceAvailable,
        ].includes(playerState);
        this.changeDetectorRef.markForCheck();
    }

    private onPlayerAudioAvailabilityChangedHandler = (event: AudioAvailabilityChangeEvent): void => {
        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.isAudioAvailable = event.isAudioAvailable;
            this.computeRightButtonsVisibility(this.lastRightBarAvailableWidth);
            this.changeDetectorRef.markForCheck();
        });
    };

    private onPlayerAudioStateChangedHandler = (event: AudioStateChangeEvent): void => {
        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.isListening = event.isAudioEnabled;
            this.changeDetectorRef.markForCheck();
        });
    };

    //#endregion

    //#region TileButtons

    private enumToArray(enumToConvert: Enum): string[] {
        return (
            Object.keys(enumToConvert)
                .filter((value) => isNaN(Number(value)))
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                .map((key) => enumToConvert[key] as string)
        );
    }

    public getColor(button: TileButton): string {
        const color = typeof button.color === 'function' ? button.color() : button.color;
        return color ?? '';
    }

    public getIcon(button: TileButton): MeltedIcon {
        return typeof button.icon === 'function' ? button.icon() : button.icon;
    }

    public getId(button: TileButton): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return typeof button.id === 'function' ? (button.id?.() as string) : (button.id as string);
    }

    public getText(button: TileButton): string {
        return typeof button.text === 'function' ? button.text() : button.text;
    }

    public isVisible(button: TileButton): boolean {
        return !button.isVisible || button.isVisible();
    }

    public execute(button: TileButton, event?: Event): void {
        button.execute(event);
    }

    public isDisabled(button: TileButton): boolean {
        return button.isDisabled ? button.isDisabled() : false;
    }

    public computeLeftButtonsVisibility(newWidth: number): void {
        this.lastLeftBarAvailableWidth = newWidth;

        const { visibleButtons, additionalButtons } = this.getVisibleAndAdditionalButtons(this.leftBarAllowedButtons, this.leftBarButtonsPriority, newWidth);
        this.leftBarVisibleButtons = visibleButtons;
        this.leftBarAdditionalButtons = additionalButtons;

        this.computeButtonsVisibilityDebounced.next();
    }

    public computeRightButtonsVisibility(newWidth: number): void {
        this.lastRightBarAvailableWidth = newWidth;

        // Save space for the More button
        const { visibleButtons, additionalButtons } = this.getVisibleAndAdditionalButtons(this.rightBarAllowedButtons, this.rightBarButtonsPriority, newWidth - this.BUTTON_WIDTH);

        this.rightBarVisibleButtons = visibleButtons;
        this.rightBarAdditionalButtons = additionalButtons;

        this.computeButtonsVisibilityDebounced.next();
    }

    private computeButtonsVisibility() {
        this.setMoreMenuItemSource();
        this.moreMenuItemSource = [...this.moreMenuItemSource, ...this.leftBarAdditionalButtons, ...this.rightBarAdditionalButtons];
    }

    private buildMoreMenu() {
        if (!this.moreMenu) {
            this.moreMenu = this.contextMenuFactory.buildMenu(this.viewContainerRef, this.moreMenuItemSource, `#video-controls-more-${this.componentId}`);
            this.moreMenuCloseSubscription = fromEvent(this.moreMenu.location.nativeElement as HTMLElement, 'didClose')
                .pipe(untilDestroyed(this))
                .subscribe(this.moreItemMenuClosed);
        }
    }

    private destroyMoreMenu() {
        if (this.moreMenu) {
            this.moreMenuCloseSubscription?.unsubscribe();
            this.moreMenu.destroy();
            this.moreMenu = undefined;
        }
    }

    // Returns the list of buttons that the user has the right to use/see
    private getAllowedButtons(buttons: Array<TileButton>): Array<TileButton> {
        const allowedButtons = [];

        for (const button of buttons) {
            if (!button.isAllowed || button.isAllowed?.()) {
                allowedButtons.push(button);
            }
        }

        return allowedButtons;
    }

    // Returns a tuple of the visible buttons on the player bar and the ones that will be collapsed into the More button
    private getVisibleAndAdditionalButtons(allowedButtons: Array<TileButton>, buttonsByPriority: Array<string>, availableWidth: number) {
        const visibleButtons: Array<TileButton> = [];
        const additionalButtons: Array<ContextMenuItem> = [];
        let width = availableWidth;

        for (const buttonByPriority of buttonsByPriority) {
            const button = allowedButtons.find((x) => x.id === buttonByPriority);

            if (!button) {
                continue;
            }

            if (!button.isVisible || button.isVisible?.()) {
                // If the button fits
                if (this.BUTTON_WIDTH < width) {
                    visibleButtons.push(button);
                    width -= this.BUTTON_WIDTH;
                } else {
                    additionalButtons?.push({
                        id: button.id as string,
                        text: this.getText(button),
                        icon: this.getIcon(button),
                        actionItem: {
                            execute: () => button.execute(),
                        },
                    });
                }
            }
        }

        return { visibleButtons, additionalButtons };
    }

    private initializeButtons() {
        this.leftBarAllowedButtons = this.getAllowedButtons(this.getLeftBarButtons());
        this.rightBarAllowedButtons = this.getAllowedButtons(this.getRightBarButtons());

        this.leftBarButtonsPriority = this.enumToArray(VideoButtonsLeftBar);
        this.rightBarButtonsPriority = this.enumToArray(VideoButtonsRightBar);

        this.computeLeftButtonsVisibility(this.lastLeftBarAvailableWidth);
        this.computeRightButtonsVisibility(this.lastRightBarAvailableWidth);
    }

    // To add a new button on the left player bar, add it to this list
    // To set it's priority (used to decide when the button is collapsed), add it to `buttons.enum.ts`
    private getLeftBarButtons(): Array<TileButton> {
        return [
            {
                id: VideoButtonsLeftBar.Record,
                text: () => this.getRecordTooltip(),
                icon: () => this.getRecordIcon(),
                color: () => this.getRecordColor(),
                execute: this.toggleRecording.bind(this),
                isVisible: () => this.isLive,
                isAllowed: () => this.hasStartRecordingPrivilege && !this.recordingStates.isRecordingLocked,
                isDisabled: () => !this.isCameraStreaming || this.recordingStates.isRecordingLocked,
            },
            {
                id: VideoButtonsLeftBar.PlayOrPause,
                text: () => (this.isPaused ? this.translateService.instant('STE_BUTTON_RESUMEVIDEO') : this.translateService.instant('STE_BUTTON_PAUSEVIDEO')) as string,
                icon: () => (this.isPaused ? MeltedIcon.Play : MeltedIcon.Pause),
                color: undefined,
                execute: this.togglePlayingState.bind(this),
                isAllowed: () => this.canShowPlayback,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsLeftBar.JumpBack,
                text: this.translateService.instant('STE_BUTTON_JUMPBACKWARD') as string,
                icon: MeltedIcon.Rewind15,
                color: undefined,
                execute: () => this.jumpBackAsync(15),
                isAllowed: () => this.canShowPlayback,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsLeftBar.JumpForward,
                text: this.translateService.instant('STE_BUTTON_JUMPFORWARD') as string,
                icon: MeltedIcon.Forward15,
                color: undefined,
                execute: () => this.jumpForwardAsync(15),
                isVisible: () => !this.isLive,
                isAllowed: () => this.canShowPlayback,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsLeftBar.Rewind,
                text: this.translateService.instant('STE_BUTTON_REWINDVIDEO') as string,
                icon: MeltedIcon.Rewind,
                color: undefined,
                execute: this.rewind.bind(this),
                isVisible: () => !this.isLive,
                isAllowed: () => this.canShowPlayback,
                isDisabled: () => !this.isCameraStreaming || this.isSlowMotion, //Bug49903: Smooth reverse isnt supported in browsers according to Marmot
            },
            {
                id: VideoButtonsLeftBar.FastForward,
                text: this.translateService.instant('STE_BUTTON_FASTFORWARD') as string,
                icon: MeltedIcon.FastForward,
                color: undefined,
                execute: this.fastForward.bind(this),
                isVisible: () => !this.isLive,
                isAllowed: () => this.canShowPlayback,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsLeftBar.SlowMotion,
                text: this.translateService.instant('STE_BUTTON_SLOWMOTION') as string,
                icon: () => (this.isSlowMotion ? MeltedIcon.SlowOff : MeltedIcon.Slow),
                color: undefined,
                execute: this.slowMotion.bind(this),
                isVisible: () => !this.isLive,
                isAllowed: () => this.canShowPlayback,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsLeftBar.GoLive,
                text: this.translateService.instant('STE_BUTTON_GOLIVE') as string,
                icon: MeltedIcon.ArrowRight,
                color: undefined,
                execute: this.playLive.bind(this),
                isVisible: () => !this.isLive,
                isAllowed: () => this.hasLiveVideoPrivilege,
                isDisabled: () => !this.isCameraStreaming,
            },
        ];
    }

    // To add a new button on the right player bar, add it to this list
    // To set it's priority (used to decide when the button is collapsed), add it to `buttons.enum.ts`
    private getRightBarButtons(): Array<TileButton> {
        let hasAtLeastOnePtzPrivilege = false;
        if (this.content) {
            const priv = new PtzPrivileges(this.content);
            hasAtLeastOnePtzPrivilege = priv.hasAtLeastOnePtzPrivilege();
        }

        return [
            {
                id: VideoButtonsRightBar.Expand,
                text: () =>
                    (this.dataContext?.isExpanded ? this.translateService.instant('STE_BUTTON_RESTORETILE') : this.translateService.instant('STE_BUTTON_MAXIMIZETILE')) as string,
                icon: () => (this.dataContext?.isExpanded ? MeltedIcon.Collapse : MeltedIcon.Expand),
                color: undefined,
                execute: this.expandTile.bind(this),
                isVisible: () => this.isWithinTile,
            },
            {
                id: VideoButtonsRightBar.Bookmarks,
                text: this.translateService.instant('STE_BUTTON_ADDBOOKMARK') as string,
                icon: MeltedIcon.Bookmark,
                color: undefined,
                execute: () => this.addBookmark(),
                isAllowed: () => this.hasBookmarkPrivilege && this.canAddBookmark,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsRightBar.SaveSnapshot,
                text: this.translateService.instant('STE_BUTTON_SAVESNAPSHOT') as string,
                icon: MeltedIcon.Snapshot,
                color: undefined,
                execute: this.takeSnapshot.bind(this),
                isAllowed: () => this.hasSaveSnapshotsPrivilege,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsRightBar.Audio,
                text: () => (this.isListening ? this.translateService.instant('STE_BUTTON_STOP_LISTENING') : this.translateService.instant('STE_BUTTON_START_LISTENING')) as string,
                icon: () => (this.isListening ? MeltedIcon.VolumeLoud : MeltedIcon.VolumeMute),
                color: undefined,
                execute: this.toggleListen.bind(this),
                isVisible: () => this.isAudioAvailable && this.canListen,
                isDisabled: () => !this.isCameraStreaming,
            },
            {
                id: VideoButtonsRightBar.PTZControls,
                text: this.translateService.instant('STE_LABEL_PTZ_ANALOG_PTZ') as string,
                icon: MeltedIcon.Joystick,
                color: undefined,
                execute: (event?: Event) => this.togglePtzControls(event),
                // TODO : Remove when technical debt #57297 is done
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                isVisible: () => this.isLive && hasAtLeastOnePtzPrivilege && (this.dataContext as any)?.displayContext !== DisplayContext.Sidepane,
                isAllowed: () => this.isPtz && hasAtLeastOnePtzPrivilege,
                isDisabled: () => !this.isCameraStreaming,
            },
        ];
    }
    //#endregion
}
