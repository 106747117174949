import { McSetting, McSettingService } from './../../services/mc-setting/mc-setting.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GenMeltedModal } from '@genetec/gelato-angular';
import { ButtonFlavor, Icon, IconSize, TextFlavor, PopupPosition, SpinnerSize, SelectionType } from '@genetec/gelato';
import { TranslateService } from '@ngx-translate/core';
import { TrackedComponent } from '@shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { MissionControlContentTypes } from '../../mission-control-content-types';
import { Content, ContentPluginComponent, PluginComponentExposure } from '@shared/interfaces/plugins/public/plugin-public.interface';
import { TrackingService } from '@shared/services/tracking.service';
import { IncidentService } from '../../services/incident/incident.service';
import { ContentFields } from '../../content-fields';
import { toGuid } from '../../utils/guid-utils';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ActivityEvent, ActivityEventService } from '@modules/mission-control/services/activity-event/activity-event.service';
import { IncidentSelectionService } from '@modules/mission-control/services/incident/incident-selection.service';
import { ListItem } from '@modules/shared/interfaces/list-item';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

const isContentSupported = (content: Content): boolean => !!content?.type.equals(MissionControlContentTypes.incident);

@UntilDestroy()
@Component({
    selector: 'app-activity-widget',
    templateUrl: './activity-widget.component.html',
    styleUrls: ['./activity-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: ActivityWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: ActivityWidgetComponent.pluginId } as PluginComponentExposure,
    isContentSupported,
})
export class ActivityWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('A9258DF6-7A91-4232-8276-1C22D3B4FA63');

    @ViewChild('filterModal') eventFilterModal!: GenMeltedModal;

    public readonly TextFlavor = TextFlavor;
    public readonly IconSize = IconSize;
    public readonly Icon = Icon;
    public readonly ListSelectionType = SelectionType;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly PopupPosition = PopupPosition;
    public readonly SpinnerSize = SpinnerSize;

    public maxTextLength = 250;
    public content: Content | null = null;
    public dataContext: unknown;
    public filteredIncidentEvents$!: Observable<ActivityEvent[] | null>;
    private _incidentId: IGuid | null = null;

    public set selectedEventTypes(value: ListItem[]) {
        this._selectedEventTypes$.next(value);
        this._mcSettingService.set(
            McSetting.eventFilter,
            this.selectedEventTypes.map((x) => x.id)
        );
    }

    public get selectedEventTypes() {
        return this._selectedEventTypes$.getValue();
    }

    private cachedSelectedEventTypes: ListItem[] = [];

    public availableEventTypes!: ListItem[];

    private _selectedEventTypes$ = new BehaviorSubject<ListItem[]>([]);
    private _filteredIncidentEvents$!: Subject<ActivityEvent[] | null>;

    constructor(
        private _incidentService: IncidentService,
        private _incidentSelectionService: IncidentSelectionService,
        private _translateService: TranslateService,
        private _activityEventService: ActivityEventService,
        private _mcSettingService: McSettingService,
        trackingService: TrackingService
    ) {
        super(trackingService);
    }

    public async ngOnInit() {
        super.ngOnInit();

        this.availableEventTypes = [
            { id: '1000', text: this._translateService.instant('STE_LABEL_SYSTEM_ACTION') as string, icon: this.MeltedIcon.Incident },
            { id: '1001', text: this._translateService.instant('STE_LABEL_USER_ACTION') as string, icon: this.MeltedIcon.Person },
            { id: '1002', text: this._translateService.instant('STE_LABEL_COMMENTS') as string, icon: this.MeltedIcon.Message },
            { id: '1003', text: this._translateService.instant('STE_LABEL_NOTES') as string, icon: this.MeltedIcon.Email },
            { id: '1004', text: this._translateService.instant('STE_LABEL_PROCEDURE') as string, icon: this.MeltedIcon.LogFile },
        ] as ListItem[];

        const targetedIncident = this._incidentId ? (await this._incidentService.getIncident(this._incidentId).toPromise()) ?? null : null;

        const incident$ = this._incidentSelectionService.selectedIncident$.pipe(filter((i): i is MCIncident => i instanceof MCIncident && i.id.equals(targetedIncident?.id!)));

        this._filteredIncidentEvents$ = new Subject<ActivityEvent[] | null>();
        this.filteredIncidentEvents$ = this._filteredIncidentEvents$.asObservable();

        this.refreshEventTypesFromSettings();

        const filters$ = this._selectedEventTypes$.pipe(map((item) => item.map((i) => i.text)));

        combineLatest([incident$, filters$])
            .pipe(
                switchMap(([incident, filters]) => this._activityEventService.getActivityEvents(incident, filters)),
                untilDestroyed(this)
            )
            .subscribe(this._filteredIncidentEvents$);
    }

    truncate = (input: string): string => (input.length > this.maxTextLength ? `${input.substring(0, this.maxTextLength)}...` : input);

    public eventFilterChange(selectEventType: ListItem, isChecked: boolean): void {
        selectEventType.isChecked = isChecked;
        if (isChecked) {
            this.selectedEventTypes.push(selectEventType);
        } else {
            this.selectedEventTypes.remove(selectEventType);
        }
    }

    public toggleEventFilterSelection() {
        this.refreshEventTypesFromSettings();

        // Clone the current list to restore it in case the user cancels
        this.cachedSelectedEventTypes = this.selectedEventTypes.map(({ id, text, icon, isChecked }) => ({
            id,
            text,
            icon,
            isChecked,
        }));

        this.eventFilterModal.show();
    }

    public onEventFilterSelectionSaved = async (): Promise<boolean> => {
        this._selectedEventTypes$.next(this.selectedEventTypes);
        this._mcSettingService.set(
            McSetting.eventFilter,
            this.selectedEventTypes.map((x) => x.id)
        );
        return Promise.resolve(true);
    };

    public onEventFilterSelectionCancelled = async (): Promise<boolean> => {
        this.selectedEventTypes = this.cachedSelectedEventTypes;
        return Promise.resolve(true);
    };

    public trackById = (_: number, item: ActivityEvent): IGuid => item.id;

    public setContent(content: Content): void {
        this.content = content;
        // TODO: Move to main content which will probably be the incident-info widget
        if (this.content?.parameters?.hasField(ContentFields.incidentId) ?? false) {
            const incidentIdString = this.content?.parameters?.getField(ContentFields.incidentId) as string;
            const incidentId = toGuid(incidentIdString);
            this._incidentId = incidentId;
            this._incidentService
                .getIncident(incidentId)
                .pipe(
                    filter((i): i is MCIncident => i instanceof MCIncident),
                    tap(async (incident) => await this._incidentSelectionService.selectIncident(incident)),
                    untilDestroyed(this)
                )
                .subscribe();
        }
    }

    public setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext;
    }

    private refreshEventTypesFromSettings() {
        const eventFilter = this._mcSettingService.doesSettingExist(McSetting.eventFilter) ? this._mcSettingService.getEventFilter() : this.availableEventTypes.map((x) => x.id);
        this.selectedEventTypes = this.availableEventTypes.filter((type) => eventFilter.includes(type.id));
        this.availableEventTypes.forEach((x) => (x.isChecked = eventFilter.includes(x.id)));
    }
}
