import { ZoomConfiguration } from '../players/ZoomConfiguration';
import { Resolution } from './Resolution';
import { FitResolution } from './FitResolution';
import { VideoWatermarkingRenderer } from './VideoWatermarkingRenderer';
import { VideoWatermarkConfig } from './VideoWatermarkingConfig';
import { ILogger } from './logger';

export class RenderingCanvas {
  private readonly m_logger: ILogger;

  private readonly m_canvas: HTMLCanvasElement;

  private m_watermarkRenderer: VideoWatermarkingRenderer | null;

  public get canvasElement(): HTMLCanvasElement {
    return this.m_canvas;
  }

  constructor(logger: ILogger, canvas: HTMLCanvasElement) {
    this.m_logger = logger.subLogger('RenderingCanvas');
    this.m_canvas = canvas;
    this.m_watermarkRenderer = null;
  }

  public draw(image: CanvasImageSource, streamResolution: Resolution, zoomConfiguration: ZoomConfiguration): void {
    const ctx = this.m_canvas.getContext('2d'); // context is used to draw images, shapes and text

    if (!ctx) {
      throw new Error('Unable to get the 2D context for the canvas');
    }

    // Handle resizing
    if (this.m_canvas.scrollWidth > 0 && this.m_canvas.scrollHeight > 0 && (this.m_canvas.scrollWidth !== this.m_canvas.width || this.m_canvas.scrollHeight !== this.m_canvas.height)) {
      this.m_canvas.width = this.m_canvas.scrollWidth;
      this.m_canvas.height = this.m_canvas.scrollHeight;
    }

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, this.m_canvas.width, this.m_canvas.height);

    const [zoom, drawingCoordinates] = zoomConfiguration.toCanvasParameters(streamResolution, Resolution.build(this.m_canvas.width, this.m_canvas.height));
    ctx.scale(zoom, zoom);
    ctx.drawImage(image, drawingCoordinates.X, drawingCoordinates.Y);
    ctx.scale(1 / zoom, 1 / zoom); // always clean up! Reverse the scale

    const fitRes = new FitResolution(streamResolution, Resolution.build(this.m_canvas.width, this.m_canvas.height));

    // The portion of the watermark overlay overlapping the black bars on the canvas when zoomed
    const clipX = this.m_canvas.width - fitRes.Scaled.Width * zoomConfiguration.Zoom - drawingCoordinates.X * zoom;
    const clipY = this.m_canvas.height - fitRes.Scaled.Height * zoomConfiguration.Zoom - drawingCoordinates.Y * zoom;

    // The overlay size is the size of the scaled image if it fully fits in the canvas,
    // or the canvas size minus the clipped portion. This ensures the black bars on the right/bottom of the image
    // do not have the overlay when zoomed.
    const overlaySizeX = Math.min(fitRes.Scaled.Width * zoomConfiguration.Zoom, this.m_canvas.width - Math.max(0, clipX));
    const overlaySizeY = Math.min(fitRes.Scaled.Height * zoomConfiguration.Zoom, this.m_canvas.height - Math.max(0, clipY));

    this.m_watermarkRenderer?.updateVideoWatermarkingOverlaySizeWithSpecificDimensions(fitRes.Scaled.Width, fitRes.Scaled.Height, overlaySizeX, overlaySizeY);
    this.m_watermarkRenderer?.drawVideoWatermarkingOverlay(ctx, Math.max(drawingCoordinates.X * zoom, 0), Math.max(drawingCoordinates.Y * zoom, 0));
  }

  public getSnapshot(): ImageData {
    const context = this.m_canvas.getContext('2d');

    if (!context) {
      this.m_logger.warn?.trace('getSnapshot(): Failed to obtain the 2d context of the snapshot canvas');
      throw new Error('Failed to obtain the 2d context of the snapshot canvas');
    }
    this.m_logger.info?.trace(`getSnapshot(): returning a ${this.m_canvas.width}x${this.m_canvas.height} image`);
    return context.getImageData(0, 0, this.m_canvas.width, this.m_canvas.height);
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void {
    // Only create the WatermarkRenderer if the config is enabled
    if (videoWatermarkingConfig !== VideoWatermarkConfig.Disabled) {
      this.m_logger.debug?.trace('Updating video watermark configuration');
      this.m_watermarkRenderer = new VideoWatermarkingRenderer(videoWatermarkingConfig, this.m_canvas.scrollWidth, this.m_canvas.scrollHeight);
    } else {
      this.m_logger.debug?.trace('Disabling video watermark');
      this.m_watermarkRenderer = null;
    }
  }
}
