<gen-melted-modal
    [genTitle]="'STE_TITLE_RECIPIENTS' | translate"
    [genCanClose]="true"
    [genDefaultActionText]="'STE_LABEL_OK' | translate"
    [genOnDefaultAction]="recipientChangeAction"
    [genAlternativeActionText]="'STE_BUTTON_CANCEL' | translate"
    [genIsDefaultActionDisabled]="seletedRecipients.length === 0"
>
    <gen-melted-modal-body>
        <div id="recipientEntityWrapper">
            <app-entity-browser (checkedEntitiesChanged)="onRecipientChange($event)" [filter]="entityBrowserFilter" [areItemsCheckable]="true"> </app-entity-browser>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
