import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[appCaptureEvents]',
})
export class CaptureEventsDirective implements OnInit, OnDestroy {
    @Output()
    public previewClick = new EventEmitter();

    constructor(private el: ElementRef<Element>) {}

    ngOnInit() {
        this.el?.nativeElement?.addEventListener('pointerdown', this.onClickHandler, { capture: true });
    }

    ngOnDestroy() {
        this.el?.nativeElement?.removeEventListener('pointerdown', this.onClickHandler, { capture: true });
    }

    private readonly onClickHandler = (event: Event): void => {
        this.previewClick.emit(event);
    };
}
