import { Component, Input, ViewChild, ElementRef, ChangeDetectionStrategy, NgZone, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { GuidSet, IGuid } from 'safeguid';
import moment, { MomentInput } from 'moment-timezone';
import { RenderingHelper } from '@modules/video/utilities/renderinghelper';
import { PlayerMode, PlayerModeChangeEvent, PlayerState, PlayerStateChangeEvent, TimelineEvent, TimelineEventKind } from 'Marmot/Marmot/gwp';
import { IVideoPlayer, LoopChangeEvent } from 'Marmot/ivideoplayer';
import { TimelineService } from '@modules/video/services/timeline.service';
import { TimelineProviderId, TimelinePartEvent } from 'Marmot/Timeline/timelinePartEvent';
import { ITimelinePart } from 'Marmot/Timeline/timelinePart';
import { debounce, find } from 'lodash-es';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TimeService } from '../../services/time/time.service';
import { LoggerService } from '../../services/logger/logger.service';
import { TrackedComponent } from '../tracked/tracked.component';
import { TrackingService } from '../../services/tracking.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { ITimezoneInfo } from '../../api/api';
import { TooltipItem } from './tooltipItem';

export class ThumbnailPart {
    constructor(public timestamp: Date, public duration: number, public image: string) {}
}

@UntilDestroy()
@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent extends TrackedComponent implements OnDestroy {
    // #region Constants

    private static readonly minLength = 30000; // 30 seconds
    private static readonly maxLength = 86400000; // 24 hours
    private static readonly rangeThreshold = 14400000; // 4 hours
    private static readonly autoScrollThreshold = 0.05; // 5%
    private static readonly liveThreshold = 0.1; // 10%
    private static readonly autoscrollBuffer = 5000; // 5 seconds
    private static readonly cursorThumbWidth = 10; // on hover, the thumb is 10px wide
    private static readonly livePopupTime = 6000;

    // #endregion

    // #region Properties
    @ViewChild('canvas') public canvas!: ElementRef;
    @ViewChild('ticks') public ticks!: ElementRef;
    @ViewChild('thumb') public thumb!: ElementRef;
    @ViewChild('tooltip') public tooltip!: ElementRef;
    @ViewChild('maindiv')
    public set maindivContent(child: ElementRef | undefined) {
        if (this.maindiv) {
            this.maindiv.removeEventListener('wheel', this.onMouseWheelHandler);
        }
        this.maindiv = child?.nativeElement as HTMLCanvasElement | undefined;
        if (this.maindiv) {
            // do not use the passive event handler in that case because we want to stopPropagation()
            this.maindiv.addEventListener('wheel', this.onMouseWheelHandler, { passive: false });
        }
    }

    public readonly TextFlavor = TextFlavor;

    public get allowPlayback(): boolean {
        return this.allowPlaybackValue;
    }
    public set allowPlayback(value: boolean) {
        this.allowPlaybackValue = value;
    }

    // gets the camera entity
    public get cameraId(): IGuid {
        return this.cameraIdValue;
    }

    // gets the source entity (like a door)
    public get entityId(): IGuid | undefined {
        return this.entityIdValue;
    }

    @Input()
    public set player(player: IVideoPlayer | undefined) {
        this.videoPlayer = player;

        this.log('Hooking up the VideoPlayer callbacks');

        // IMPORTANT, setup the video player outside Angular to prevent change detection
        this.zone.runOutsideAngular(() => {
            if (this.videoPlayer) {
                this.videoPlayer.onPlayerStateChanged.register((x) => this.onPlayerStateChanged(x));
                this.videoPlayer.onPlayerModeChanged.register((x) => this.onPlayerModeChanged(x));
                this.videoPlayer.onLoopChanged.register((x) => this.onLoopChanged(x));
                this.videoPlayer.onTimelinePart.register((x) => this.onTimelinePart(x));

                this.refresh(this.cameraIdValue, this.entityIdValue, this.timezone);
                this.queryParts();
            }
        });
    }

    // #enregion

    // #region Fields

    public startTime!: Date;
    public endTime!: Date;
    public currentTime!: Date;
    public currentX = 0;
    public loopThumbs: { left?: number; right?: number } = {};
    public isLoopEnabled = false;
    public captureMouse = -1;
    public isLive: boolean | undefined;
    public cursorTime = '';
    public tooltips: TooltipItem[] = [];
    public currentThumbnail: ThumbnailPart | undefined;
    public cursorX = 0;
    public tooltipX = 0;
    public tooltipArrowOffset = '50%';
    public showTooltip = false;
    public isMouseOver = false;
    public isPanning = false;

    private timezone: ITimezoneInfo | undefined;
    private queriedStart!: Date;
    private queriedEnd!: Date;
    private entityIdValue!: IGuid | undefined;
    private cameraIdValue!: IGuid;
    private currentState!: PlayerState;
    private loopTime: { start?: Date; end?: Date } = {};
    private currentHeight = 0;
    private currentWidth = 0;
    private isAutoTooltip = false;
    private isCompressible = false;
    private isExpandable = false;
    private isOverDiv = false;
    private isOverTooltip = false;

    private maindiv?: HTMLCanvasElement;

    // flag indicating if we have already setup the timeline providers
    private isTimelineProvidersSet = false;

    // determine if we were playing or paused before a seek
    private wasPlaying = false;

    // Indicates if the loop is expanding with the right thumb
    //  (otherwise, expanding with the left thumb)
    private isLoopExpandingWithRightThumb = true;
    private queryControllerDebounce = debounce(() => {
        this.queryParts();
    }, 3000);

    private hideTooltipDebounce2s = debounce(() => {
        if (!this.isMouseOver) {
            this.hideTooltip();
        }
    }, 2000);

    private hideTooltipDebounceLive = debounce(() => {
        if (this.isAutoTooltip) {
            this.hideTooltip();
        }
    }, TimelineComponent.livePopupTime);

    private currentTickInterval!: { ms: number; ticks: number };
    private tickIntervals: { ms: number; ticks: number }[] = [
        { ms: -1, ticks: 0 }, // invalid
        { ms: 1, ticks: 5 }, // 5 ms
        { ms: 10, ticks: 5 }, // 50 ms
        { ms: 1000, ticks: 5 }, // 5 s
        { ms: 5000, ticks: 6 }, // 30 s
        { ms: 10000, ticks: 6 }, // 1 min
        { ms: 30000, ticks: 4 }, // 2 min
        { ms: 60000, ticks: 5 }, // 5 min
        { ms: 120000, ticks: 5 }, // 10 min
        { ms: 300000, ticks: 6 }, // 30 min
        { ms: 600000, ticks: 6 }, // 1 hr
        { ms: 1800000, ticks: 4 }, // 2 hr
        { ms: 3600000, ticks: 6 }, // 6 hr
        { ms: 7200000, ticks: 6 }, // 12 hr
        { ms: 14400000, ticks: 6 }, // 1 d
        { ms: 28800000, ticks: 6 }, // 2 d
        { ms: 86400000, ticks: 7 }, // 1 w
    ];

    private sequenceEvents: TimelineEvent[] = [];
    private thumbnailEvents: ThumbnailPart[] = [];
    private parts: ITimelinePart[] = [];
    private isDragging = false;
    private isDraggingLoop = false;
    private mouseDownPosition?: number = undefined;
    private initialMovingTime?: Date;
    private videoPlayer?: IVideoPlayer;
    private zoomFactor = 2;
    private lastAutoRedraw!: Date;
    private currentProviders = new GuidSet();

    // privileges
    private allowPlaybackValue = false;

    // flag used to enable logs for more diagnostics
    private enableLog = false;

    private debouncedDraw = debounce(() => {
        this.draw();
        this.replaceThumbs();
    }, 50);

    // #endregion

    // #region Constructors

    constructor(
        private zone: NgZone,
        private timeService: TimeService,
        private timelineService: TimelineService,
        private navigationService: NavigationService,
        trackingService: TrackingService,
        private loggerService: LoggerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(trackingService);

        this.initialize();

        // subscribe to the RenderingHelper that is used to reduce the number of Angular's change detection
        RenderingHelper.onRefresh.pipe(untilDestroyed(this)).subscribe(() => this.onRenderingRefresh());
        timelineService.providersChanged.pipe(untilDestroyed(this)).subscribe(() => this.onProvidersChanged());
    }

    // #endregion

    //#region Ng...

    ngOnDestroy() {
        // clearing the following property will unsubcribe it's event listener
        this.maindivContent = undefined;
    }

    //#endregion

    // #region Methods

    // #region Drawing

    public draw(): void {
        const canvas = this.canvas?.nativeElement as HTMLCanvasElement | undefined | null;
        if (!canvas) {
            return;
        }

        // ensure we have a valid length
        const length = moment(this.endTime).diff(this.startTime);
        if (length <= 0) {
            return;
        }

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        this.currentWidth = ctx.canvas.scrollWidth;
        this.currentHeight = ctx.canvas.scrollHeight;
        canvas.width = this.currentWidth;

        // apply a different style depending on mouse over
        if (this.isMouseOver) {
            this.currentHeight = 8;
        } else {
            this.currentHeight = 4;
        }
        canvas.height = this.currentHeight;

        // draw unknown background
        ctx.fillStyle = 'gray';
        ctx.fillRect(0, 0, this.currentWidth, this.currentHeight);

        // draw sequences background
        if (this.queriedStart && this.queriedEnd) {
            ctx.fillStyle = 'black';
            let queryStartX = this.getPercentageFromTime(this.queriedStart) * this.currentWidth;
            queryStartX = this.limitWithinRange(queryStartX, 0, this.currentWidth);
            let queryEndX = this.getPercentageFromTime(this.queriedEnd) * this.currentWidth;
            queryEndX = this.limitWithinRange(queryEndX, 0, this.currentWidth);
            ctx.fillRect(queryStartX, 0, queryEndX, this.currentHeight);
        }

        // draw sequence events first
        ctx.fillStyle = 'white';
        this.drawEvents(ctx, this.sequenceEvents, false);

        // draw parts
        this.drawParts(ctx);

        // draw ticks
        this.drawTicks(length);

        // draw future
        const startX = this.getCurrentTimeX();
        const stopX = this.currentWidth - startX;
        ctx.fillStyle = 'purple';
        ctx.fillRect(startX, 0, stopX, this.currentHeight);

        // draw selection
        ctx.fillStyle = '#C0C0C0'; // Silver
        ctx.globalAlpha = 0.7;
        if (this.loopThumbs.left) {
            ctx.fillRect(0, 0, this.loopThumbs.left, this.currentHeight);
        }
        if (this.loopThumbs.right) {
            ctx.fillRect(this.loopThumbs.right, 0, this.currentWidth, this.currentHeight);
        }
    }

    public drawEvents(ctx: CanvasRenderingContext2D, events: TimelineEvent[], isPonctual: boolean): void {
        for (const event of events) {
            let posStartPercent = this.getPercentageFromTime(event.time);

            // ensure events starting before start time are drawn from x=0
            if (posStartPercent < 0) {
                if (isPonctual) {
                    continue;
                } else {
                    posStartPercent = 0;
                }
            }

            const startX = posStartPercent * this.currentWidth;
            let stopX = startX + 2;

            if (event.duration) {
                const endTime = moment(event.time).add(event.duration, 'seconds').toDate();
                const posEndPercent = this.getPercentageFromTime(endTime);
                stopX = posEndPercent * this.currentWidth;
            }

            // ensure events ending after end time stop at the end of the timeline
            if (stopX > this.currentWidth) {
                stopX = this.currentWidth;
            }

            /*
            // if we need to apply transparency
            let alpha = 1.0;
            ctx.globalAlpha = alpha;
            */

            // only draw if it's in the timeline boundaries
            if (startX >= 0 && startX <= this.currentWidth && stopX >= 0 && stopX <= this.currentWidth) {
                ctx.fillRect(startX, 0, stopX - startX, this.currentHeight);
            }
        }

        // restore the alpha
        ctx.globalAlpha = 1.0;
    }

    public drawParts(ctx: CanvasRenderingContext2D): void {
        // draw using priority
        const sorted = this.parts.sort((a, b) => {
            if (a.priority < b.priority) {
                return -1;
            }
            if (a.priority > b.priority) {
                return 1;
            }
            return 0;
        });

        for (const part of sorted) {
            // parse the renderer
            ctx.fillStyle = 'orange';
            const tokens = this.extractTokens(part);
            if (tokens) {
                const color = tokens.get('color');
                if (color) {
                    ctx.fillStyle = color;
                }
            }

            const posStartPercent = this.getPercentageFromTime(part.timestamp);

            const startX = posStartPercent * this.currentWidth;
            let stopX = startX + 2;

            if (part.duration && part.duration > 0) {
                const endTime = moment(part.timestamp).add(part.duration, 'ms').toDate();
                const posEndPercent = this.getPercentageFromTime(endTime);
                stopX = posEndPercent * this.currentWidth;
            }

            // ensure events that occurred completly before x=0 are not drawn
            if (startX < 0 && stopX < 0) {
                continue;
            } else if (stopX > this.currentWidth) {
                // ensure events ending after end time stop at the end of the timeline
                stopX = this.currentWidth;
            } else if (stopX - startX < 2) {
                // ensure we are at least 2 pixels wide
                stopX = startX + 2;
            }

            // only draw if it's in the timeline boundariess
            if (startX >= 0 && startX <= this.currentWidth && stopX >= 0 && stopX <= this.currentWidth) {
                ctx.fillRect(startX, 0, stopX - startX, this.currentHeight);
            }
        }
    }

    public drawTicks(length: number): void {
        const ticks = this.ticks.nativeElement as HTMLCanvasElement | null;
        if (!ticks) {
            return;
        }

        const ticskHeight = 12;
        const ctx = ticks.getContext('2d') as CanvasRenderingContext2D;
        ticks.width = this.currentWidth;
        ticks.height = ticskHeight;

        this.updateTickSpacing();

        // draw ticks
        const ms = this.currentTickInterval.ms;
        const majorTicks = this.currentTickInterval.ticks;
        // const showMilliseconds = ms < 200;
        const showSeconds = ms < 10000;
        const showMinutes = ms < 600000;
        const showHours = ms < 14400000;
        const is24HourFormat = this.timeService.is24HourFormat;

        let timeFormat = 'hA';
        if (showHours && !showMinutes && !showSeconds) {
            timeFormat = is24HourFormat ? (timeFormat = 'HH') : (timeFormat = 'h');
        } else if (showHours && showMinutes && !showSeconds) {
            timeFormat = is24HourFormat ? (timeFormat = 'HH:mm') : (timeFormat = 'h:mm');
        } else if (showHours && showMinutes && showSeconds) {
            timeFormat = is24HourFormat ? (timeFormat = 'HH:mm:ss') : (timeFormat = 'h:mm:ss');
        }

        const tickCount = length / ms;

        // draw every tick
        ctx.beginPath();
        ctx.lineWidth = 0.5;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.strokeStyle = '#000000';
        ctx.fillStyle = 'white';
        ctx.font = '11px Arial';
        ctx.textAlign = 'left';

        let currentTimestamp = moment(this.startTime);
        for (let i = 0; i <= tickCount; ++i) {
            currentTimestamp.add(ms, 'ms');
            const percentX = this.getPercentageFromTime(currentTimestamp);
            const x = Math.floor(percentX * this.currentWidth) + 0.5;
            let y = ticskHeight / 2;
            let lineHeight = ticskHeight / 2;

            // detect major ticks
            const isMajorTick = majorTicks !== 0 && i % majorTicks === 0;
            if (isMajorTick) {
                y = 0;
                lineHeight = ticskHeight;
            }

            ctx.moveTo(x, y);
            ctx.lineTo(x, y + lineHeight);
            ctx.stroke();
        }

        // draw start time
        const startTimeText = this.timeService.formatTime(this.startTime, 'ddd D', true, false, this.timezone);
        ctx.fillText(startTimeText, 2, 11);
        // const startTextWidth = ctx.measureText(startTimeText);

        // draw total time
        ctx.textAlign = 'right';
        const totalMs = moment(this.endTime).diff(this.startTime);
        const totalTimeText = '(' + this.timeService.formatDuration(totalMs) + ')';
        ctx.fillText(totalTimeText, this.currentWidth - 3, 11);
        const totalTextWidth = ctx.measureText(totalTimeText);
        const totalTextX = this.currentWidth - 3 - totalTextWidth.width;

        // draw timestamps
        currentTimestamp = moment(this.startTime);
        for (let i = 1; i <= tickCount - 1; ++i) {
            currentTimestamp.add(ms, 'ms');
            const percentX = this.getPercentageFromTime(currentTimestamp);
            const x = Math.floor(percentX * this.currentWidth) + 0.5;

            // detect major ticks
            const isMajorTick = majorTicks !== 0 && i % majorTicks === 0;
            // skip the first time because we will display date
            if (isMajorTick) {
                const text = this.timeService.formatTime(currentTimestamp, timeFormat, true, false, this.timezone);
                const textWidth = ctx.measureText(text).width;
                const textX = x + 3;
                // ensure the text doesn't overlap
                if (textX + textWidth < totalTextX + 6) {
                    ctx.fillText(text, textX, 11);
                }
            }
        }
    }

    public compress(origin: number): void {
        if (!this.isCompressible) {
            return;
        }

        const length = moment(this.endTime).diff(this.startTime);
        let newLength = length / this.zoomFactor;

        // we cannot go under 30 seconds range
        if (newLength < TimelineComponent.minLength) {
            newLength = TimelineComponent.minLength;
        }

        let start: Date | undefined;
        let end: Date | undefined;
        // if we have a looping range, limit the compression
        if (this.isLoopEnabled) {
            const loopLength = moment(this.loopTime.end).diff(this.loopTime.start);
            const threshold = (loopLength * TimelineComponent.autoScrollThreshold) / 2;
            if (threshold > 0) {
                start = moment(this.loopTime.start).subtract(threshold, 'ms').toDate();
                end = moment(this.loopTime.end).add(threshold, 'ms').toDate();
            }
        } else {
            const offset = newLength * origin;
            const centerTime = moment(this.startTime).add(length * origin, 'ms');
            start = centerTime.clone().subtract(offset, 'ms').toDate();
            end = centerTime
                .clone()
                .add(newLength - offset, 'ms')
                .toDate();
        }

        if (start && end) {
            this.moveTo(start, end);
            this.replaceThumbs();
            this.draw();
            this.updateCursorTime();
        }
    }

    public expand(origin: number): void {
        if (!this.isExpandable) {
            return;
        }

        const length = moment(this.endTime).diff(this.startTime);
        let newLength = length * this.zoomFactor;

        // we cannot go over 24 hours
        if (newLength > TimelineComponent.maxLength) {
            newLength = TimelineComponent.maxLength;
        }

        const offset = newLength * origin;
        const centerTime = moment(this.startTime).add(length * origin, 'ms');
        const start = centerTime.clone().subtract(offset, 'ms').toDate();
        const end = centerTime
            .clone()
            .add(newLength - offset, 'ms')
            .toDate();
        this.moveTo(start, end);
        this.replaceThumbs();
        this.draw();
        this.updateCursorTime();
    }

    public moveTo(start: Date, end: Date): void {
        this.startTime = start;
        this.endTime = end;

        this.draw();

        this.updateZoomAvailability();

        // debounce the queries to the controller
        this.queryControllerDebounce();
    }

    public clearLoop(): void {
        this.videoPlayer?.clearLoop();
    }

    // #region Queries

    public queryParts(): void {
        // remove all the non-persistent parts
        this.parts = this.parts.filter((x) => x.isPersistent);

        // query +/- 4 hours before and after the range
        this.queriedStart = moment(this.startTime).subtract(TimelineComponent.rangeThreshold, 'ms').toDate();
        this.queriedEnd = moment(this.endTime).add(TimelineComponent.rangeThreshold, 'ms').toDate();
        this.log(`Applying timeline range ${this.queriedStart.toString()} to ${this.queriedEnd.toString()}`);

        this.zone.runOutsideAngular(() => {
            this.videoPlayer?.timelineProvider?.setTimelineRange(this.queriedStart, this.queriedEnd);
        });
    }

    // refresh the timeline parts
    public refresh(cameraId: IGuid, entityId?: IGuid, timezone?: ITimezoneInfo): void {
        this.cameraIdValue = cameraId;
        this.entityIdValue = entityId;
        this.timezone = timezone;

        this.setupTimelineProviders().fireAndForget();
    }

    public getLoop(): { start: Date | undefined; end: Date | undefined } {
        return { start: this.loopTime.start, end: this.loopTime.end };
    }

    public setLoop(start: Date, end: Date): void {
        if (start > end) {
            this.log('Loop starts after it ends. Ensure "start" is chronologically before "end"');
            return;
        }
        this.loopTime.start = start;
        this.loopTime.end = end;
        this.videoPlayer?.setLoop(start, end);
    }

    //#endregion

    // #region Initialization code

    private initialize() {
        // the inital range is 5 minutes (95% | 5%)
        const now = Date.now();
        const initStartTime = moment(now).subtract('285', 's').toDate();
        const initEndTime = moment(now).add('15', 's').toDate();
        this.moveTo(initStartTime, initEndTime);
    }

    // #endregion

    private extractTokens(part: ITimelinePart): Map<string, string> | undefined {
        let result: Map<string, string> | undefined;

        if (part.renderer) {
            const tokens = part.renderer.split(';');
            if (tokens && tokens.length > 0) {
                result = new Map<string, string>();

                for (const token of tokens) {
                    const kv = token.split(':');
                    if (kv && kv.length >= 2) {
                        const key = kv[0].toLowerCase().trimLeft();
                        const value = kv[1].trimLeft();
                        result.set(key, value);
                    }
                }
            }
        }
        return result;
    }

    // #endregion

    private autoRedraw() {
        const now = new Date(Date.now());

        // suspend auto redraw when holding the mouse down
        if (this.mouseDownPosition) {
            return;
        }

        // esnrue the user is not currently dragging something
        if (this.isDragging || this.isDraggingLoop) {
            return;
        }

        // ensure we are not updating more than twice a second
        if (this.lastAutoRedraw) {
            const diff = moment(now).diff(this.lastAutoRedraw);
            if (diff <= 500) {
                return;
            }
        }

        // update the bounds
        const length = moment(this.endTime).diff(this.startTime);
        const offset = length * TimelineComponent.liveThreshold;

        // compute new begin and end time
        const newStartTime = moment(now).subtract(length, 'ms').add(offset, 'ms');
        const newEndTime = moment(now).add(offset, 'ms');

        const diffPercentage = (newStartTime.diff(this.startTime) / length) * 100;
        if (Math.abs(diffPercentage) > TimelineComponent.liveThreshold) {
            this.startTime = newStartTime.toDate();
            this.endTime = newEndTime.toDate();

            // now that we receive live events, update the queried end
            this.queriedEnd = this.endTime;

            // snap the tooltip to it's timestamp
            if (this.isAutoTooltip && this.tooltips.length > 0) {
                const x = this.getPercentageFromTime(this.tooltips[0].timestamp) * this.currentWidth;
                this.positionTooltip(x);
            }

            this.draw();
            this.replaceThumbs();
            this.lastAutoRedraw = now;
        }
    }

    private autoScroll() {
        const length = moment(this.endTime).diff(this.startTime);

        // handle forward auto-scroll
        const maxTime = moment(this.currentTime)
            .subtract(length * TimelineComponent.autoScrollThreshold, 'ms')
            .toDate();
        if (this.currentTime > maxTime || this.currentTime < this.startTime) {
            const start = moment(this.currentTime)
                .subtract(length * TimelineComponent.autoScrollThreshold, 'ms')
                .toDate();
            const end = moment(this.currentTime)
                .add(length * (1 - TimelineComponent.autoScrollThreshold), 'ms')
                .toDate();

            this.moveTo(start, end);
        }
    }

    private containsPart(part: ITimelinePart): boolean {
        let result = false;
        this.parts.forEach((itr) => {
            if (itr.id.equals(part.id)) {
                result = true;
                return;
            }
        });
        return result;
    }

    private getCurrentTimeX(): number {
        const now = new Date(Date.now());
        const posStartPercent = this.getPercentageFromTime(now);
        return posStartPercent * this.currentWidth;
    }

    private getHoverEvents(): TooltipItem[] {
        const results: TooltipItem[] = [];
        const pixelOffset = 5.5; // this represent the pixel range around the event to display the tooltip

        // parts
        const display: { offset: number; part: ITimelinePart }[] = [];
        for (const part of this.parts) {
            // ensure there is a tooltip to display
            if (part.toolTip) {
                const eventPercentX = this.getPercentageFromTime(part.timestamp);
                if (eventPercentX >= 0 && eventPercentX <= this.currentWidth) {
                    const left = eventPercentX * this.currentWidth;
                    const offset = left - this.cursorX;
                    if (Math.abs(offset) <= pixelOffset) {
                        display.push({ offset, part });
                    }
                }
            }
        }

        // JDT - TODO : sort by offset
        display.forEach((d) => {
            const item = new TooltipItem(this.navigationService, d.part.timestamp);
            const tooltip = d.part.toolTip;
            if (tooltip) {
                // ensure we are able to load the tooltip
                if (item.loadFrom(tooltip)) {
                    results.push(item);
                }
            }
        });

        return results;
    }

    private getThumbnail(x: number): ThumbnailPart | undefined {
        const timestamp = this.getTimeFromPercentage(x / this.currentWidth);

        for (const thumb of this.thumbnailEvents) {
            if (timestamp >= thumb.timestamp) {
                const endTime = moment(thumb.timestamp).add(thumb.duration, 'ms').toDate();
                if (timestamp <= endTime) {
                    return thumb;
                }
            }
        }
    }

    private getPercentageFromTime(time: MomentInput): number {
        // 1pm -> 2pm (7600 seconds). 2pm - 1:45pm (15 minutes = 900 seconds)
        // 900/7600 ~ 12% | 100 - 12 ~ 88% | return 88%
        const totalDifference = this.getTotalDifference();
        if (totalDifference < 1) {
            // who knows what will happen if we divide by 0
            return -1;
        }

        const timeDifference = moment(time).diff(this.startTime);
        return timeDifference / totalDifference;
    }

    private getTotalDifference(): number {
        return moment(this.endTime).diff(this.startTime);
    }

    private getTimeFromPercentage(percentage: number): Date {
        const totalDifference = this.getTotalDifference();
        const result = totalDifference * percentage;
        return moment(this.startTime).add(result, 'ms').toDate();
    }

    private hideTooltip() {
        this.showTooltip = false;
        if (this.isAutoTooltip) {
            this.tooltips.length = 0;
            this.isAutoTooltip = false;
        }
    }

    private limitWithinRange(num: number, min: number, max: number) {
        return Math.min(Math.max(num, min), max);
    }

    private log(msg: string) {
        if (this.enableLog) {
            this.loggerService.traceInformation('Timeline: ' + msg);
        }
    }

    // called when we switch from playback to live
    private onLive() {
        this.clearLoop();
        this.autoRedraw();
        // in live, the cursor is always at 90%
        this.currentX = (1 - TimelineComponent.liveThreshold) * this.currentWidth;
    }

    // called when we switch from live to playback
    private onPlayback() {}

    // reposition the tooltip at the specified position and ensure it has a valid position
    private positionTooltip(x: number) {
        let newX = x;
        if (this.tooltip && this.maindiv) {
            const tooltipHalfWidth = (this.tooltip.nativeElement as HTMLElement).offsetWidth / 2;
            const totalWidth = this.maindiv.offsetWidth;

            // apply constraint
            if (x + tooltipHalfWidth > totalWidth) {
                const offsetX = tooltipHalfWidth * 2 - (totalWidth - x);
                newX = totalWidth - tooltipHalfWidth;
                this.tooltipArrowOffset = `${offsetX}px`;
            } else {
                this.tooltipArrowOffset = '50%';
            }

            this.tooltipX = newX;
        }
    }

    // replace the thumbs positions on the canvas
    private replaceThumbs(cursor = true, loop = true) {
        if (cursor) {
            this.currentX = this.getPercentageFromTime(this.currentTime) * this.currentWidth;
        }

        if (loop) {
            if (this.loopTime.start) {
                this.loopThumbs.left = this.getPercentageFromTime(this.loopTime.start) * this.currentWidth;
            }
            if (this.loopTime.end) {
                this.loopThumbs.right = this.getPercentageFromTime(this.loopTime.end) * this.currentWidth;
            }
        }
    }

    private setIsMouseOver() {
        if (this.isOverDiv) {
            this.isMouseOver = true;
            this.draw();

            this.showTooltip = true;
            this.hideTooltipDebounce2s?.cancel();
        } else if (this.isOverTooltip) {
            this.showTooltip = true;
            this.hideTooltipDebounce2s?.cancel();
        } else {
            this.isMouseOver = false;
            this.hideTooltipDebounce2s();
            this.draw();
        }
    }

    // apply the list of timeline providers to query
    private async setupTimelineProviders(): Promise<void> {
        // ensure the player is started before applying the timeline providers
        if (!this.videoPlayer || !this.videoPlayer.isStarted || !this.cameraIdValue) return;

        this.isTimelineProvidersSet = true;
        let refresh = false;
        const entities: IGuid[] = [];
        if (this.cameraIdValue) {
            entities.push(this.cameraIdValue);
        }
        if (this.entityIdValue) {
            entities.push(this.entityIdValue);
        }

        const addProviders = new GuidSet();
        this.timelineService.activeProviders.forEach((x) => addProviders.add(x));

        const removeProviders = new GuidSet();
        this.currentProviders.forEach((id) => {
            // if we had this provider but doesnt need it anymore
            if (!addProviders.has(id)) {
                removeProviders.add(id);
                refresh = true;
            } else {
                // we already have this provider, remove it from the list to add
                addProviders.delete(id);
                refresh = true;
            }
        });

        const toAdd = Array.from(addProviders);
        if (toAdd.length > 0) {
            addProviders.forEach((x) => this.currentProviders.add(x));
            await this.videoPlayer.addTimelineProviders(toAdd, true, entities);
        }

        const toRemove = Array.from(removeProviders);
        if (toRemove.length > 0) {
            removeProviders.forEach((x) => this.currentProviders.delete(x));
            await this.videoPlayer.addTimelineProviders(toRemove, false, entities);
        }

        // refresh everything
        if (refresh) {
            this.log(`Refreshing after provider changes: added=${addProviders.size}, removed=${removeProviders.size}`);

            this.parts.length = 0;
            this.queryParts();
        }

        let logText = 'List of active providers:\r\n';
        this.currentProviders.forEach((id) => {
            logText += id.toString() + '\r\n';
        });
        this.log(logText);
    }

    private updateCursorTime() {
        const timestamp = this.getTimeFromPercentage(this.cursorX / this.currentWidth);
        let timestampText = '';
        // ensure the timestamp is valid
        if (!isNaN(timestamp.valueOf())) {
            timestampText = this.timeService.formatTime(timestamp, 'LTS', true, true, this.timezone);
        }
        this.cursorTime = timestampText;
    }

    private updateTickSpacing() {
        const length = moment(this.endTime).diff(this.startTime);
        const tickSpacing = (10 * length) / this.currentWidth;

        // find the best tick interval for the current time range
        for (let i = 0; i < this.tickIntervals.length; ++i) {
            if (i < this.tickIntervals.length - 1) {
                if (tickSpacing >= this.tickIntervals[i].ms && tickSpacing < this.tickIntervals[i + 1].ms) {
                    this.currentTickInterval = this.tickIntervals[i + 1];
                    break;
                }
            } else {
                this.currentTickInterval = this.tickIntervals[i];
                break;
            }
        }
    }

    private updateTime() {
        // ensure the player is started to prevent exceptions
        if (this.videoPlayer?.isStarted !== true) return;

        const frameTime = this.videoPlayer?.lastFrameTime;
        if (frameTime) {
            // only update the thumb position when not dragging
            if (!this.isDragging) {
                // always update the time because we can seek even when
                if (this.currentTime !== frameTime) {
                    this.currentTime = frameTime;
                    this.replaceThumbs(true, false);
                }

                // only update the rest when playing or rewinding
                if (this.currentState !== PlayerState.Playing && this.currentState !== PlayerState.Rewinding) {
                    return;
                }

                // when live, auto redraw at the near-live position
                if (this.isLive) {
                    // in live, ensure the loop is cleared
                    if (!this.isDraggingLoop) {
                        this.clearLoop();
                    }
                    this.autoRedraw();
                    // in live, the cursor is always at 90%
                    this.currentX = (1 - TimelineComponent.liveThreshold) * this.currentWidth;
                } else {
                    this.currentX = this.getPercentageFromTime(this.currentTime) * this.currentWidth;

                    // ensure we are not outside of the timeline's bounds
                    const minX = TimelineComponent.liveThreshold * this.currentWidth;
                    const maxX = (1 - TimelineComponent.liveThreshold) * this.currentWidth;
                    if (this.currentX > maxX) {
                        // autoscroll forward
                        const total = this.getTotalDifference();
                        const thres = total * TimelineComponent.liveThreshold + TimelineComponent.autoscrollBuffer;
                        this.endTime = moment(this.currentTime).add(thres).toDate();
                        this.startTime = moment(this.endTime).subtract(total).toDate();

                        this.replaceThumbs(false, true);
                        this.draw();
                    } else if (this.currentX < minX) {
                        // autoscroll backward
                        const total = this.getTotalDifference();
                        const thres = total * TimelineComponent.liveThreshold + TimelineComponent.autoscrollBuffer;
                        this.startTime = moment(this.currentTime).subtract(thres).toDate();
                        this.endTime = moment(this.startTime).add(total).toDate();

                        this.replaceThumbs(false, true);
                        this.draw();
                    }

                    // check if we need to requery
                    if (frameTime < this.queriedStart || frameTime > this.queriedEnd) {
                        this.queryParts();
                    }
                }
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    private updateZoomAvailability() {
        const diff = this.getTotalDifference();
        const isBigEnough = diff > TimelineComponent.minLength; // 30 seconds
        const isSmallEnough = diff < TimelineComponent.maxLength; // 24 hours

        this.isCompressible = isBigEnough;
        this.isExpandable = isSmallEnough;
    }

    // #endregion

    /* eslint-disable @typescript-eslint/member-ordering */

    // #region Event Handlers

    private onMouseWheelHandler = (event: WheelEvent): void => {
        this.onMouseWheel(event);
    };

    public onResize(): void {
        this.debouncedDraw();
    }

    public onContextMenu(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    public onMouseUp(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    public onPointerDown(event: PointerEvent): void {
        // note the mouse down position because we are going to determine the action to perfrom on mouse move
        this.mouseDownPosition = event.offsetX;
    }

    public onPointerUp(event: PointerEvent): void {
        const x = event.offsetX;
        const timestamp = this.getTimeFromPercentage(x / this.currentWidth);
        let handled = false;

        // on right-click up, cancel operation
        if (event.button === 2 && !this.isDraggingLoop) {
            this.clearLoop();
            handled = true;
        } else if (this.isPanning) {
            // if we were pannig, cancel it
            this.isPanning = false;
            this.initialMovingTime = undefined;
            handled = true;
        } else if (this.isDragging) {
            // if we were dragging the time cursor
            this.isDragging = false;

            // ensure the date is valid
            if (!isNaN(timestamp.valueOf())) {
                const now = Date.now();
                const diff = moment(timestamp).diff(now);
                if (diff < 0) {
                    this.videoPlayer?.seek(timestamp);

                    // resume the video once the user completed it's seek
                    // when user was paused when seeking, we want to stay paused as well like in the old WC.
                    if (this.wasPlaying) {
                        this.videoPlayer?.resume();
                        this.wasPlaying = false;
                    }
                } else {
                    // we dragged in the future, go live
                    this.videoPlayer?.playLive();
                }
                handled = true;
            }
        } else if (this.isDraggingLoop) {
            // if we were selecting the looping range
            this.updateMovingThumb(x);
            this.isDraggingLoop = false;
            if (this.loopThumbs.left !== undefined && this.loopThumbs.right !== undefined) {
                const loopStart = this.getTimeFromPercentage(this.loopThumbs.left / this.currentWidth);
                const loopEnd = this.getTimeFromPercentage(this.loopThumbs.right / this.currentWidth);
                this.setLoop(loopStart, loopEnd);
                handled = true;
            }
        } else {
            // no action is being performed, try a seek
            // ensure the date is valid
            if (!isNaN(timestamp.valueOf())) {
                this.videoPlayer?.seek(timestamp);
                handled = true;
            }
        }

        // if the click was handled, force a redraw
        if (handled) {
            this.draw();

            event.preventDefault();
            event.stopPropagation();
        }

        this.captureMouse = -1; // release mouse capture
    }

    public onPointerMove(event: PointerEvent): void {
        if (!this.maindiv) return;

        // adapt the x position relative to the main div
        const divRect = this.maindiv.getBoundingClientRect();
        const x = event.x - divRect.x;
        this.cursorX = x;
        this.isAutoTooltip = false;
        this.positionTooltip(x);

        const timestamp = this.getTimeFromPercentage(this.cursorX / this.currentWidth);
        let handled = false;

        this.currentThumbnail = undefined;

        if (this.mouseDownPosition) {
            // detect mouse move threshold
            if (Math.abs(this.mouseDownPosition - event.offsetX) > 2) {
                // if left button down
                if (event.buttons === 1) {
                    // if the user is not dragging, perform a seek
                    if (!this.isDragging && !this.isPanning) {
                        this.initialMovingTime = timestamp;
                        this.isPanning = true;
                        this.captureMouse = event.pointerId;
                        handled = true;
                    }
                } else if (event.buttons === 2) {
                    this.clearLoop();

                    // right button down, setup the looping range
                    this.isDragging = false;
                    this.isDraggingLoop = true;
                    this.isLoopEnabled = true;
                    this.loopThumbs = { left: this.mouseDownPosition, right: this.mouseDownPosition };
                    this.captureMouse = event.pointerId;
                    handled = true;
                }

                // clear the mouse down
                this.mouseDownPosition = undefined;
            }
        }

        // are we panning the timeline?
        if (this.isPanning) {
            const diff = moment(timestamp).diff(this.initialMovingTime);
            this.startTime = moment(this.startTime).subtract(diff, 'ms').toDate();
            this.endTime = moment(this.endTime).subtract(diff, 'ms').toDate();
            handled = true;
        } else {
            this.tooltips.length = 0;

            // are we over an event?
            const hoverEvents = this.getHoverEvents();
            if (hoverEvents) {
                hoverEvents.forEach((evt) => {
                    this.tooltips.push(evt);
                });

                // snap the tooltip to it's timestamp
                if (this.isAutoTooltip && this.tooltips.length > 0) {
                    const percentage = this.getPercentageFromTime(this.tooltips[0].timestamp) * this.currentWidth;
                    this.positionTooltip(percentage);
                }
            }

            // retrieve the thumbail
            this.currentThumbnail = this.getThumbnail(this.cursorX);

            // move the thumb
            if (this.isDragging) {
                this.currentX = this.cursorX - TimelineComponent.cursorThumbWidth / 2;
            } else if (this.isDraggingLoop) {
                this.updateMovingThumb(this.cursorX);
                handled = true;
            }
        }

        this.updateCursorTime();

        if (handled) {
            this.draw();
            this.replaceThumbs();

            event.preventDefault();
            event.stopPropagation();
        }
    }

    private updateMovingThumb(newPosition: number) {
        if (this.isLoopExpandingWithRightThumb) {
            this.loopThumbs.right = newPosition;
        } else {
            this.loopThumbs.left = newPosition;
        }

        if (this.loopThumbs.left === undefined || this.loopThumbs.right === undefined) {
            return;
        }

        const shouldSwapLeftAndRight = this.loopThumbs.left > this.loopThumbs.right;
        if (shouldSwapLeftAndRight) {
            this.isLoopExpandingWithRightThumb = !this.isLoopExpandingWithRightThumb;
            this.loopThumbs = { left: this.loopThumbs.right, right: this.loopThumbs.left };
            this.loopTime = { start: this.loopTime.end, end: this.loopTime.start };
        }
    }

    public onMouseEnter(): void {
        this.isOverDiv = true;
        this.setIsMouseOver();
    }

    public onMouseLeave(): void {
        this.isOverDiv = false;
        this.setIsMouseOver();
    }

    public onTooltipMouseEnter(): void {
        this.isOverTooltip = true;
        this.setIsMouseOver();
    }

    public onTooltipMouseLeave(): void {
        this.isOverTooltip = false;
        this.setIsMouseOver();
    }

    public onThumbnailClick(thumbnail: ThumbnailPart | undefined): void {
        if (thumbnail) {
            const timestamp = this.getTimeFromPercentage(this.cursorX / this.currentWidth);
            if (timestamp) {
                this.videoPlayer?.seek(timestamp);
            }
        }
    }

    public onMouseWheel(event: WheelEvent): void {
        const percentX = event.offsetX / this.currentWidth;

        if (event.deltaY > 0) {
            this.expand(percentX);
        } else if (event.deltaY < 0) {
            this.compress(percentX);
        }

        event.preventDefault();
        event.stopPropagation();
    }

    public onCursorMouseDown(event: PointerEvent): void {
        // ensure the user has playback privilege
        if (this.allowPlayback) {
            // if left button down
            if (event.buttons === 1) {
                if (!this.isDragging) {
                    this.isDragging = true;

                    this.wasPlaying = this.currentState === PlayerState.Playing;
                    if (this.wasPlaying) {
                        // pause video when starting a drag
                        this.videoPlayer?.pause();
                    }

                    // start capturing events
                    this.captureMouse = event.pointerId;
                }
            } else if (event.buttons !== 1) {
                this.isDragging = false;
                this.initialMovingTime = undefined;
            }
        }
    }

    /* eslint-enable @typescript-eslint/member-ordering */

    private onRenderingRefresh() {
        this.updateTime();
    }

    private onLoopChanged(event: LoopChangeEvent) {
        this.loopTime.start = event.start;
        this.loopTime.end = event.end;
        this.isLoopEnabled = event.isLooping;

        if (!this.isLoopEnabled) {
            this.loopThumbs = { right: undefined, left: undefined };
        }
    }

    private onPlayerModeChanged(event: PlayerModeChangeEvent) {
        if (event) {
            // process updates in Angular's zone
            this.zone.runGuarded(() => {
                const isLive = event.playerMode === PlayerMode.live;
                if (!this.isLive && isLive) {
                    this.isLive = true;
                    this.onLive();
                } else if (this.isLive && !isLive) {
                    this.onPlayback();
                    this.isLive = false;
                }

                this.updateTime();
            });
        }
    }

    private onPlayerStateChanged(event: PlayerStateChangeEvent) {
        if (event) {
            // process updates in Angular's zone
            this.zone.runGuarded(() => {
                this.currentState = event.playerState;
                this.changeDetectorRef.markForCheck();
            });

            // We need to wait for the TimelineSession to be created server-side before applying the providers
            if (!this.isTimelineProvidersSet && event.playerState === PlayerState.Starting) {
                this.setupTimelineProviders().fireAndForget();
            }
        }
    }

    private onTimelinePart(update: TimelinePartEvent) {
        this.log(`Receiving ${update.parts.size} timeline parts.`);
        let newParts = 0;

        const now = new Date(Date.now());
        update.parts.forEach((part) => {
            if (part.sourceId.equals(TimelineProviderId.RecordingSequenceTimelineProviderId)) {
                const event = new TimelineEvent(part.timestamp, TimelineEventKind.RecordingSequence, part.duration, undefined);
                // prevent duplicates
                const found = find(this.sequenceEvents, event);
                if (!found) {
                    this.sequenceEvents.push(event);
                    newParts++;
                }
            } else {
                // first, ensure the provider is currently active
                if (this.currentProviders.has(part.sourceId)) {
                    // prevent duplicates
                    const contains = this.containsPart(part);
                    if (contains === false) {
                        // see if the part contains a thumbnail
                        if (part.duration > 0) {
                            const tokens = this.extractTokens(part);
                            if (tokens) {
                                const image = tokens.get('thumb');
                                if (image) {
                                    const thumbPart = new ThumbnailPart(part.timestamp, part.duration, image);
                                    this.thumbnailEvents.push(thumbPart);
                                }
                            }
                        }

                        // if the part as a tooltip and is near-live (2 sec or less)
                        const diff = moment(now).diff(part.timestamp);
                        const tooltip = part.toolTip;
                        if (tooltip && diff <= TimelineComponent.livePopupTime) {
                            const item = new TooltipItem(this.navigationService, part.timestamp);
                            // ensure we are able to load the tooltip
                            if (item.loadFrom(tooltip)) {
                                this.tooltips.push(item);
                            }
                        }
                        this.parts.push(part);
                        newParts++;
                    }
                }
            }
        });

        if (newParts > 0) {
            this.log(`${newParts} new parts have been added.`);
        }

        // filter out obsolete tooltips
        this.tooltips = this.tooltips.filter((x) => {
            const diff = moment(now).diff(x.timestamp);
            return diff <= TimelineComponent.livePopupTime;
        });

        this.draw();

        /* JDT - Disable auto-popup for now
        // determine if we need to automatically popup the tooltip
        if (this.isLive && this.tooltips.length > 0) {
            const x = this.getPercentageFromTime(this.tooltips[0].timestamp) * this.currentWidth;
            this.isAutoTooltip = true;
            this.showTooltip = true;
            this.positionTooltip(x);
            this.hideTooltipDebounceLive(); // show for a couple of seconds
        }*/
    }

    // handler called when the user change the list of active providers
    private onProvidersChanged() {
        this.log('Providers settings changed.');

        // redraw
        this.draw();
        this.queryParts();

        this.setupTimelineProviders().fireAndForget();
    }

    // #endregion
}
