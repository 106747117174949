// Autogenerated: v2/help/Classes/ApiCardholderAntipassbackForgivenEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-01-21 12:36:23 PM
import { IGuid } from 'safeguid';
import { ICardholderAntipassbackForgivenEvent } from '../Interface/ICardholderAntipassbackForgivenEvent';
import { CardholderEvent } from './CardholderEvent';

export class CardholderAntipassbackForgivenEvent extends CardholderEvent implements ICardholderAntipassbackForgivenEvent {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
    }

    // #endregion Methods

}

