import { Inject, Injectable, OnDestroy, Output } from '@angular/core';
import { DateFormat } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ITimezoneInfo, TimezonesClient, ITimezoneAbbreviations } from '../../api/api';
import { OptionTypes } from '../../enumerations/option-types';
import { USER_SETTINGS_SERVICE } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { LanguageService } from '../language/language.service';
import { LoggerService } from '../logger/logger.service';
import { UserSettingsService } from '../user-settings/user-settings.service';
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TimeService implements OnDestroy {
    //#region Fields

    @Output() public displayTimezone = false;

    @Output() public is24HourFormat = false;

    @Output() public isSmartTime = true; // do not display date if it's today

    // represent the local timezone to use
    @Output() public timezoneId!: string | null | undefined;
    @Output() public timezoneAbbreviation?: ITimezoneAbbreviations;

    @Output() public useDeviceTimezone = true;

    public timezone$: Observable<ITimezoneInfo | undefined>;
    private timezone: BehaviorSubject<ITimezoneInfo | undefined> = new BehaviorSubject<ITimezoneInfo | undefined>(undefined);
    private timezones!: ITimezoneInfo[] | undefined;
    private timezonesMap = new Map<string, ITimezoneInfo>();
    private systemTimezone: ITimezoneInfo | undefined;

    // Using the browser's culture to format dates.
    private readonly locale = navigator.language ?? LanguageService.DefaultLanguage;

    //#endregion

    //#region Constructors

    constructor(
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: UserSettingsService,
        private timezonesClient: TimezonesClient,
        private translateService: TranslateService,
        private loggerService: LoggerService,
        private authService: AuthService
    ) {
        this.userSettingsService.onSettingsChanged$.pipe(withLatestFrom(this.authService.loggedIn$), untilDestroyed(this)).subscribe(async ([_, loggedIn]) => {
            if (loggedIn) {
                await this.applySettings();
                await this.ensureTimezonesAsync();
            }
        });

        this.timezone$ = this.timezone.asObservable().pipe(shareReplay(1));
    }

    ngOnDestroy(): void {
        this.timezone.complete();
    }

    //#endregion

    //#region Methods

    /**
     * Convert the specified value to local time depending on the specified timezone
     *
     * @param value Value to convert
     * @param timezone Timezone to use for conversion
     * @returns Local time
     */
    public convertToLocal(value: Date | number | string | Moment, timezone?: string | ITimezoneInfo): Moment {
        let result = moment(value);

        const ianaTimezone = this.retrieveTimezoneIANA(timezone);
        if (ianaTimezone) {
            result = result.tz(ianaTimezone);
        }

        return result;
    }

    /**
     * Convert the specified value to universal time depending on the specified timezone
     *
     * @param value Value to convert
     * @param timezone Timezone to use for conversion
     * @returns Universal time
     */
    public convertToUTC(value: Date | number | string | Moment, timezone?: string | ITimezoneInfo): Moment {
        let result = moment(value);
        const ianaTimezone = this.retrieveTimezoneIANA(timezone);
        if (ianaTimezone) {
            result = result.tz(ianaTimezone).utc();
        }

        return result;
    }

    /**
     * Convert the specified value to the use the specified timezone
     *
     * @param value Value to convert
     * @param timezone Timezone to use for conversion
     * @returns Converted time
     */
    public convertToTimeZone(value: Date | number | string | Moment, timezone?: string | ITimezoneInfo): Moment {
        let result = moment(value);

        const ianaTimezone = this.retrieveTimezoneIANA(timezone);
        if (ianaTimezone) {
            // to perform conversion, we must set the flag to keep the local time
            result = result.tz(ianaTimezone, true);
        }

        return result;
    }

    /**
     * Format the specified duration as text
     *
     * @param milliseconds Duration in miliseconds
     * @param useAbbrev True to use abbreviations (m instead on minutes)
     * @param autoDetermineUnits True to automatically determine the units to use
     * @param years True to display the number of years
     * @param days True to display the number of days
     * @param hours True to display the number of hours
     * @param minutes True to display the number of minutes
     * @param seconds True to display the number of seconds
     * @returns Formatted text representing the duration
     */
    public formatDuration(milliseconds: number, useAbbrev = true, autoDetermineUnits = true, years = false, days = false, hours = false, minutes = false, seconds = false): string {
        const lst: string[] = [];

        const MILLIS_PER_SECOND = 1000;
        const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * 60; // 60,000
        const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * 60; // 3,600,000
        const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24; // 86,400,000
        const MILLIS_PER_YEAR = MILLIS_PER_DAY * 365;

        let ms = milliseconds;
        // append the number of years
        const totalYears = Math.floor(ms / MILLIS_PER_YEAR);
        if (years || (autoDetermineUnits && totalYears > 0)) {
            ms -= totalYears * MILLIS_PER_YEAR;
            const abbr = useAbbrev ? 'y' : (this.translateService.instant('STE_UNIT_YEARS_LC') as string);
            lst.push(`${totalYears} ${abbr}`);
        }

        // append the number of days
        const totalDays = Math.floor(ms / MILLIS_PER_DAY);
        if (days || (autoDetermineUnits && totalDays > 0)) {
            ms -= totalDays * MILLIS_PER_DAY;
            const abbr = useAbbrev ? 'd' : (this.translateService.instant('STE_UNIT_DAYS_LC') as string);
            lst.push(`${totalDays} ${abbr}`);
        }

        // append the number of hours
        const totalHours = Math.floor(ms / MILLIS_PER_HOUR);
        if (hours || (autoDetermineUnits && totalHours > 0)) {
            ms -= totalHours * MILLIS_PER_HOUR;
            const abbr = useAbbrev ? 'h' : (this.translateService.instant('STE_UNIT_HOURS_LC') as string);
            lst.push(`${totalHours} ${abbr}`);
        }

        // append the number of minutes
        const totalMinutes = Math.floor(ms / MILLIS_PER_MINUTE);
        if (minutes || (autoDetermineUnits && totalMinutes > 0)) {
            ms -= totalMinutes * MILLIS_PER_MINUTE;
            const abbr = useAbbrev ? 'm' : (this.translateService.instant('STE_UNIT_MINUTES_LC') as string);
            lst.push(`${totalMinutes} ${abbr}`);
        }

        // append the number of seconds
        const totalSeconds = Math.floor(ms / MILLIS_PER_SECOND);
        if (seconds || (autoDetermineUnits && totalSeconds > 0)) {
            const abbr = useAbbrev ? 's' : (this.translateService.instant('STE_UNIT_SECONDS_LC') as string);
            if (totalSeconds === 0 && ms > 0) {
                lst.push(`1 ${abbr}`);
            } else {
                lst.push(`${totalSeconds} ${abbr}`);
            }
        }

        return lst.join(' ');
    }

    /**
     * Format the specfied time relative to now (5 minutes ago)
     *
     * @param value Value to format
     * @returns Relative time text
     */
    public formatRelative(value: Date | number | string | Moment): string {
        const time = moment(value).locale(this.translateService.currentLang).fromNow();
        return time;
    }

    /**
     * Format the specified value depending on the current user's timezone settings
     *
     * @param value Timestamp to convert
     * @param format Format to use
     * @param convertTimeZone True to convert the timestamp to the local timezone time
     * @param displayTimeZone True to display the timezone suffix
     * @param deviceTimezone Specific device timezone to use if needed
     * @param useSmartTimestamp True to use the smart timestamp, if it is activated in the time service
     * @returns Formatted time
     */
    public formatTime(
        value: Date | number | string | Moment,
        format = 'LTS',
        convertTimeZone = true,
        displayTimeZone = true,
        deviceTimezone?: ITimezoneInfo | string,
        useSmartTimestamp = true
    ): string {
        let time = '';
        let formatValue = format;

        let deviceTimezoneValue: ITimezoneInfo | undefined;
        if (deviceTimezone) {
            if (typeof deviceTimezone === 'string') {
                deviceTimezoneValue = this.timezonesMap.get(deviceTimezone);
            } else {
                deviceTimezoneValue = deviceTimezone;
            }
        }

        let timezoneIana = this.timezoneId;
        // if we received a specific timezone, use it
        if (this.useDeviceTimezone && deviceTimezoneValue) {
            timezoneIana = deviceTimezoneValue.iana;
        }

        // check smart time
        if (this.isSmartTime && useSmartTimestamp && this.isToday(value, timezoneIana, convertTimeZone)) {
            if (format === DateFormat.DateTime) {
                formatValue = DateFormat.Time;
            } else if (format === DateFormat.Date) {
                // if today, just display 'Today' instead of the date
                return this.translateService.instant('STE_LABEL_TODAY') as string;
            }
        }

        // ensure we are displaying valid dates
        if (moment(value).year() > 1) {
            if (convertTimeZone && timezoneIana) {
                time = moment(value, undefined, this.locale).tz(timezoneIana).format(formatValue);
            } else {
                time = moment(value, undefined, this.locale).format(formatValue);
            }
        } else {
            this.loggerService.traceDebug(`Invalid date specified: ${value.toString()}`);
        }

        // Append the timezone abbreviation based on the user settings if the time is not empty.
        if (displayTimeZone && this.displayTimezone && time) {
            const timezoneAbbreviations = this.useDeviceTimezone && deviceTimezoneValue ? deviceTimezoneValue.abbreviations : this.timezoneAbbreviation;

            if (timezoneAbbreviations) {
                // Concat the right TimeZone abbreviation based on Daylight Saving Time vs Standard Time
                const abbreviation = moment(value).isDST() ? timezoneAbbreviations.daylight : timezoneAbbreviations.standard;
                if (abbreviation) {
                    time = time.concat(` ${abbreviation}`);
                }
            }
        }
        return time;
    }

    /**
     * Gets the list of available timezones
     *
     * @returns List of available timezones
     */
    public async getTimezonesAsync(): Promise<ITimezoneInfo[]> {
        let results: ITimezoneInfo[] = [];

        // fetch the timezones on first use and cache the results
        if (!this.timezones || this.timezones.length === 0) {
            this.timezones = [];
            this.timezonesMap.clear();
            const timezones = await this.timezonesClient.getTimezones().toPromise();
            if (timezones) {
                for (const x of timezones) {
                    if (this.timezones && x.id && x.iana && x.displayName) {
                        this.timezones.push(x);

                        // index the timezone but it's id & IANA name
                        this.timezonesMap.set(x.id, x);
                        this.timezonesMap.set(x.iana, x);
                    }
                }
                results = this.timezones;
            }
        } else {
            results = this.timezones;
        }

        return results;
    }

    /**
     * Retrieve the browser timezone in IANA format
     *
     * @returns The browser's timezone (IANA format)
     */
    public getBrowserTimezone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    /**
     * Retrieve the current local timezone (i.e. the default one)
     *
     * @returns The current local timezone
     */
    public async getLocalTimezone(): Promise<ITimezoneInfo | undefined> {
        let result: ITimezoneInfo | undefined;

        // retrieve the browser timezone
        const browserTimezone = this.getBrowserTimezone();
        if (browserTimezone) {
            result = await this.timezonesClient.getTimezone(browserTimezone).toPromise();
        }

        // if we haven't been able to retrieve the browser timezone
        if (!result) {
            if (!this.systemTimezone) {
                this.systemTimezone = await this.timezonesClient.getSystemTimezone().toPromise();
            }
            result = this.systemTimezone;
        }

        return result;
    }

    /**
     * Retrieve the timezone from the specified id
     *
     * @param timezoneId Timezone's identifier (Windows or IANA)
     * @returns Timezone having the specified id
     */
    public async retrieveTimezoneAsync(timezoneId: string): Promise<ITimezoneInfo | undefined> {
        await this.ensureTimezonesAsync();

        const result = this.timezonesMap.get(timezoneId);
        return result;
    }

    private async applySettings() {
        const localeData = moment().localeData();

        if (localeData) {
            const dateFormat = localeData.longDateFormat('LT');
            this.is24HourFormat = dateFormat.indexOf('HH') >= 0;
        }

        const displayTimezone = this.userSettingsService.get<boolean>(OptionTypes.LanguageAndTime, 'DisplayTimezone');
        if (displayTimezone !== undefined) {
            this.displayTimezone = displayTimezone;
        }

        const useDeviceTimezone = this.userSettingsService.get<boolean>(OptionTypes.LanguageAndTime, 'UseDeviceTimezone');
        if (useDeviceTimezone !== undefined) {
            this.useDeviceTimezone = useDeviceTimezone;
        }

        const timezoneId = this.userSettingsService.get<string>(OptionTypes.LanguageAndTime, 'SelectedTimezone');
        let timezone;
        if (!useDeviceTimezone && timezoneId) {
            // Security Center set TimezoneInfo.Id, not the IANA so compare by id
            timezone = await this.retrieveTimezoneAsync(timezoneId);
            this.timezoneId = timezone?.iana;
        } else {
            // if no timezone is set or using device timezone, use the local one as fallback.
            const systemTimezone = await this.getLocalTimezone();
            timezone = systemTimezone;

            if (systemTimezone?.id) {
                this.timezoneId = systemTimezone.iana;
            }
        }
        if (timezone?.abbreviations) {
            this.timezoneAbbreviation = timezone?.abbreviations;
        }
        this.setMomentGlobalTimezone(this.timezoneId);
        this.timezone.next(timezone);
    }

    /**
     * Ensure the timezones are downloaded from the server
     */
    private async ensureTimezonesAsync() {
        try {
            if (!this.timezonesMap.size) {
                await this.getTimezonesAsync();
            }
        } catch (e: any) {
            this.loggerService.traceError(e);
            throw e;
        }
    }

    /**
     * Indicates if the date time is today
     *
     * @param dateTime date time
     * @param timezone time zone of the date time
     * @param convertTimeZone indicates if we need to convert the time zone
     * @returns date time is today or not
     */
    private isToday(dateTime: Date | number | string | Moment, timezone: string | null | undefined, convertTimeZone = true): boolean {
        const nowDate = moment().format('L');
        let valueDate = moment(dateTime).format('L');
        if (convertTimeZone && timezone) {
            valueDate = moment(dateTime).tz(timezone).format('L');
        }
        return nowDate === valueDate;
    }

    /**
     * Retrieve the timezone IANA name
     *
     * @param timezoneId Timezone's identifier (Windows or IANA)
     * @returns Timezone's IANA name
     */
    private retrieveTimezoneIANA(timezone?: string | ITimezoneInfo): string | null | undefined {
        let ianaTimezone = this.timezoneId;
        if (timezone) {
            if (typeof timezone === 'string') {
                const tzInfo = this.timezonesMap.get(timezone);
                if (tzInfo) {
                    ianaTimezone = tzInfo.iana;
                }
            } else {
                ianaTimezone = timezone.iana;
            }
        }

        return ianaTimezone;
    }

    private setMomentGlobalTimezone(timezoneId?: string | null) {
        if (timezoneId) {
            this.loggerService.traceDebug(`setting default timezone to : ${timezoneId}`);
            moment.tz.setDefault(timezoneId);
        } else {
            moment.tz.setDefault(); // reset to default local timezone
        }
    }

    //#endregion
}
