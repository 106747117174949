import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.AddComment;
export class AddCommentCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentId: string, comment: string) {
        super(
            `/v2/Incidents/${incidentId}`,
            JSON.stringify({
                Type: commandType,
                Comment: comment,
            })
        );
    }
}
