import { ILogger } from '../utils/logger';
import { TokenRetriever } from './TokenRetriever';
import { Camera } from '../players/camera';
import { Token } from './Token';
import { Utils } from '../utils/Utils';
import { TimeSpan } from '../utils/TimeSpan';
import { CancellationToken, cancellableDelay } from '../utils/CancellationToken';

export interface ITokenRenewer {
  readonly Token: Promise<Token>;
  readonly RenewalPromise: Promise<Token | null>

  dispose(): void;
}

export class TokenRenewer {
  private static readonly SmallestRenewalDelay: TimeSpan = TimeSpan.fromSeconds(30);

  private readonly m_logger: ILogger;

  private readonly m_tokenRetriever: TokenRetriever;

  private readonly m_camera: Camera;

  private readonly m_renewCancel: CancellationToken;

  private m_isDisposed: boolean = false;

  private m_token: Token;

  private m_renewalPromise: Promise<Token | null>;

  public get Token(): Promise<Token> {
    return this.isValidLongEnough(this.m_token) ? Promise.resolve(this.m_token) : this.waitRenewal();
  }

  public get RenewalPromise(): Promise<Token | null> {
    if (this.m_isDisposed) {
      this.m_logger.warn?.trace('Trying to obtain a token from a disposed token renewer.');
    }
    return this.m_renewalPromise;
  }

  constructor(logger: ILogger, tokenRetriever: TokenRetriever, camera: Camera, token: Token) {
    this.m_logger = logger.subLogger('TokenRenewer');
    this.m_tokenRetriever = tokenRetriever;
    this.m_camera = camera;
    this.m_token = token;

    this.m_renewCancel = new CancellationToken();
    this.m_renewalPromise = this.doRenewal();
  }

  public dispose() {
    this.m_logger.debug?.trace('dispose()');
    this.m_isDisposed = true;
    this.m_renewCancel.cancel('dispose');
  }

  private async doRenewal(): Promise<Token | null> {
    const expirationDelay: TimeSpan = TimeSpan.fromNowUntil(this.m_token.ExpirationDate);
    let renewalDelay: TimeSpan = expirationDelay.divideBy(2);
    this.m_logger.info?.trace('New token for', this.m_camera.Id, 'expires at', Utils.formatDate(this.m_token.ExpirationDate), '(in', expirationDelay.toString(), ') renewing in', renewalDelay.toString());

    while (true) {
      await this.sleep(renewalDelay);

      if (this.m_isDisposed) {
        return null;
      }

      try {
        const newToken = await this.m_tokenRetriever.retrieveToken(this.m_camera);
        this.m_token = newToken;
        this.m_renewalPromise = this.doRenewal(); // Prepare renewal of the new token
        return newToken;
      } catch (error) {
        renewalDelay = TimeSpan.fromNowUntil(this.m_token.ExpirationDate).divideBy(2);
        this.m_logger.warn?.trace('Failed to renew access token for ', this.m_camera.toString(), error, `Will retry in ${renewalDelay.toString()}.`);
      }
    }
  }

  private async sleep(delay: TimeSpan) {
    if (delay.InMs > 0x7FFFFFFF) {
      // Browsers don't like timeouts above int32. This is also 24 days of token expiration
      // We'll just never renew those.
      this.m_logger.info?.trace(`Token duration is too long, disabling automatic renewal. Renewal was scheduled in ${delay.toString()}.`);
      await this.m_renewCancel.Cancellation;
      return;
    }

    if (delay.isLesserThan(TokenRenewer.SmallestRenewalDelay)) {
      // Always wait for 30 seconds at least
      this.m_logger.debug?.trace(`Renewal delay was ${delay.toString()}. Raising it to be ${TokenRenewer.SmallestRenewalDelay.toString()} from now`);
      delay = TokenRenewer.SmallestRenewalDelay;
    }

    await cancellableDelay(delay, this.m_renewCancel);
  }

  private isValidLongEnough(token: Token): boolean {
    return TimeSpan.fromNowUntil(token.ExpirationDate).isGreaterThan(TokenRenewer.SmallestRenewalDelay);
  }

  private async waitRenewal(): Promise<Token> {
    this.m_logger.info?.trace(`Token was expiring shortly (${TimeSpan.fromNowUntil(this.m_token.ExpirationDate).toString()}), awaiting new one.`);
    const token = await this.RenewalPromise;
    if (token === null) {
      throw new Error('Disposed');
    }

    return token;
  }
}
