import { Component, OnDestroy, OnInit } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { filterFieldChanged } from '@modules/shared/operators/filter-field-change';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { DoorEntityFields, IDoorEntity } from 'RestClient/Client/Interface/IDoorEntity';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { DoorEntity } from 'RestClient/Client/Model/AccessControl/DoorEntity';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';

@Component({
    selector: 'app-door-widget-base-component',
    template: '',
})
export class DoorWidgetBaseComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    private static monitoredFields = [
        DoorEntityFields.runningStateField,
        DoorEntityFields.doorOpenedField,
        DoorEntityFields.isLockedField,
        DoorEntityFields.maintenanceModeActiveField,
        DoorEntityFields.customIconIdField,
    ];

    public dataContext?: ContentService;
    public content?: Content;
    public isLoading = true;

    public doorIcon$: Observable<string>;

    public door$!: Observable<IDoorEntity | null>;

    protected entityCacheTask?: IEntityCacheTask;
    protected scClient: WebAppClient | null = null;
    protected get monitoredFields(): string[] {
        return DoorWidgetBaseComponent.monitoredFields;
    }

    protected get door(): IDoorEntity | null {
        return this.doorSubject.getValue();
    }

    private doorSubject = new BehaviorSubject<IDoorEntity | null>(null);
    private onDestroy = new Subject<void>();

    constructor(securityCenterClientService: SecurityCenterClientService, protected translateService: TranslateService, trackingService: TrackingService) {
        super(trackingService);

        this.scClient = securityCenterClientService?.scClient;
        this.entityCacheTask = this.scClient?.buildEntityCache(this.monitoredFields);

        this.door$ = this.doorSubject.asObservable();
        this.doorIcon$ = this.door$.pipe(
            filterFieldChanged('doorOpened'),
            map((doorOpened) => (doorOpened ? Icon.DoorOpened : Icon.DoorClosed))
        );
    }

    public async ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
        await this.entityCacheTask?.dispose();
        super.ngOnDestroy();
    }

    public async ngOnInit() {
        super.ngOnInit();
        if (this.content && this.entityCacheTask) {
            const door = await this.entityCacheTask?.getEntityAsync<DoorEntity, IDoorEntity>(DoorEntity, SafeGuid.parse(this.content.source), true);
            if (door) {
                this.doorSubject.next(door);
                this.entityCacheTask
                    .detectEntityChange(door, 'doorOpened', 'isLocked', 'runningState', 'maintenanceModeActive')
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe((updatedDoor) => {
                        this.doorSubject.next(updatedDoor);
                    });
            }
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
