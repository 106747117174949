// Autogenerated: v2/help/Classes/ApiAlarmTriggeredEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { AlarmEvent } from './AlarmEvent';
import { IGuid } from 'safeguid';
import { IAlarmTriggeredEvent } from '../Interface/IAlarmTriggeredEvent';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

export class AlarmTriggeredEvent extends AlarmEvent implements IAlarmTriggeredEvent {
    // #region REST Properties

    // TriggeredTime
    public get triggeredTime(): Date {
        return this.getFieldDate('TriggeredTime');
    }
    public set triggeredTime(value: Date) {
        this.setFieldDate('TriggeredTime', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldDate('TriggeredTime', new Date());
    }

    // #endregion Methods

}

