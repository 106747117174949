// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export enum ContentFields {
    Cardholder = 'Cardholder',
    Credentials = 'Credentials',
    Rule = 'Rule',
    AccessPointGroup = 'AccessPointGroup',
    ActivatedOn = 'ActivationDate',
    ExpiringOn = 'ExpirationDate',
    CardholderPicture = 'PicureId',
    HasAttachedData = 'HasAttachedData',
}
