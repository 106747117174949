// Autogenerated: v2/help/Entities/ApiMacro?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-30 8:57:48 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IRestResponse } from '../Interface/IRestResponse';
import { IMacroEntity, MacroEntityFields } from '../Interface/IMacroEntity';
import { ITransaction } from '../Interface/ITransaction';
import { ValidFlags } from '../Enumerations/ValidFlags';

export class MacroEntity extends Entity implements IMacroEntity {
    // #region REST Properties

    // Code
    public get code(): string {
        return this.getField<string>(MacroEntityFields.codeField, ValidFlags.Specific);
    }
    public set code(value: string) {
        this.setField<string>(MacroEntityFields.codeField, value);
    }

    // ExecutionIntegrity
    public get executionIntegrity(): string {
        return this.getField<string>(MacroEntityFields.executionIntegrityField, ValidFlags.Specific);
    }
    public set executionIntegrity(value: string) {
        this.setField<string>(MacroEntityFields.executionIntegrityField, value);
    }

    // Language
    public get language(): string {
        return this.getField<string>(MacroEntityFields.languageField, ValidFlags.Specific);
    }

    // LoadingStrategy
    public get loadingStrategy(): string {
        return this.getField<string>(MacroEntityFields.loadingStrategyField, ValidFlags.Specific);
    }
    public set loadingStrategy(value: string) {
        this.setField<string>(MacroEntityFields.loadingStrategyField, value);
    }

    // MustBeSingleInstance
    public get mustBeSingleInstance(): boolean {
        return this.getField<boolean>(MacroEntityFields.mustBeSingleInstanceField, ValidFlags.Specific);
    }
    public set mustBeSingleInstance(value: boolean) {
        this.setField<boolean>(MacroEntityFields.mustBeSingleInstanceField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Macros);
    }

    // #endregion Constructor

    // #region REST Methods

    public async executeAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('Execute', 'POST', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

