import { State, StateContext } from '@ngxs/store';
import { EmitterAction } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import { isDraft, original } from 'immer';
import { MeltedIcon } from '@genetec/gelato-angular';
import { TilePatternItem } from '../models/tile-pattern-item';
import { ContentGroup } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { Receiver, Selector } from '../../../store';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface TileCanvasStateModel {
    contents: (ContentGroup | null)[];
    contentUpdateId: IGuid | undefined;
    tilePattern: TilePatternItem;
    selectedTile: number;
}

export interface ContentChange {
    content: ContentGroup;
    tileId: number;
}

// Interface used to swat contents from one tile to another
export interface ContentMove {
    // Id of the source tile content
    sourceTileId: number;

    // Id of the destination tile content
    destinationTileId: number;

    // Flag indicating if the contents should be swap between source and destination tiles
    swapContents: boolean;
}

export const defaultTilePattern = new TilePatternItem('2x2', MeltedIcon.Layout2X2, '2x2');

const initializeContents = (size: number): (ContentGroup | null)[] => {
    const contents: (ContentGroup | null)[] = [];
    for (let index = 0; index < size; index++) {
        contents[index] = null;
    }
    return contents;
};

const initializeTileHistory = () => {
    const tilesHistory = new Array<ContentGroup[]>(maxTileCount);
    tilesHistory.fill([]);
    return tilesHistory;
};
const maxTileCount = 64;

@State<TileCanvasStateModel>({
    name: 'tileCanvasState',
    defaults: {
        contents: initializeContents(defaultTilePattern.tileCount),
        tilePattern: defaultTilePattern,
        selectedTile: -1,
        contentUpdateId: undefined,
    },
})
@Injectable()
export class TileCanvasState {
    private static tileHistorySize = 10;
    // TODO: Fix these weird static variables that behaves like the state.
    private static allCurrentContents = initializeContents(maxTileCount);
    private static tilesHistory = initializeTileHistory();

    private static getContent(content: ContentGroup | null) {
        let returnedContent = content;
        if (isDraft(returnedContent)) {
            const originalContent = original(content);
            if (originalContent) {
                returnedContent = originalContent;
            }
        }
        return returnedContent;
    }

    private static trySetContent(contents: (ContentGroup | null)[], index: number, content: ContentGroup | null): boolean {
        if (index < contents.length) {
            const previousContent = contents[index];
            contents[index] = content;
            this.allCurrentContents[index] = content;
            if (previousContent) {
                const tileHistory = this.tilesHistory[index];
                tileHistory.unshift(previousContent);
                // Keep one extra content as buffer in case change is rejected
                if (tileHistory.length > this.tileHistorySize + 1) {
                    tileHistory.pop();
                }
            }
            return true;
        }
        return false;
    }

    /* eslint-disable @typescript-eslint/unbound-method */

    @Selector()
    public static contents(state: TileCanvasStateModel): (ContentGroup | null)[] {
        return state.contents;
    }

    @Selector()
    public static contentUpdateId(state: TileCanvasStateModel): IGuid | undefined {
        return state.contentUpdateId;
    }

    @Selector()
    public static tilePattern(state: TileCanvasStateModel): TilePatternItem {
        return state.tilePattern;
    }

    @Selector()
    public static selectedTile(state: TileCanvasStateModel): number {
        return state.selectedTile;
    }

    /* eslint-enable @typescript-eslint/unbound-method */

    @Receiver()
    public static moveContent({ setState }: StateContext<TileCanvasStateModel>, { payload }: EmitterAction<ContentMove>): void {
        if (!payload) {
            return;
        }

        setState((state: TileCanvasStateModel) => {
            const sourceTileIndex = payload.sourceTileId - 1;
            const destinationTileIndex = payload.destinationTileId - 1;
            const sourceTileContent = this.getContent(state.contents[sourceTileIndex]);
            const destinationTileContent = this.getContent(state.contents[destinationTileIndex]);

            this.trySetContent(state.contents, destinationTileIndex, sourceTileContent);
            this.trySetContent(state.contents, sourceTileIndex, payload.swapContents ? destinationTileContent : null);
            state.contentUpdateId = SafeGuid.newGuid();
            return state;
        });
    }

    @Receiver()
    public static clearTiles({ setState }: StateContext<TileCanvasStateModel>, { payload }: EmitterAction<number[] | null>): void {
        if (!payload) {
            setState((state: TileCanvasStateModel) => {
                for (let index = 0; index < state.contents.length; index++) {
                    this.trySetContent(state.contents, index, null);
                }
                state.contentUpdateId = SafeGuid.newGuid();
                return state;
            });
        } else {
            setState((state: TileCanvasStateModel) => {
                for (const tileId of payload) {
                    const index = tileId - 1;
                    if (index > -1 && index < state.contents.length) {
                        this.trySetContent(state.contents, index, null);
                    }
                }
                state.contentUpdateId = SafeGuid.newGuid();
                return state;
            });
        }
    }

    @Receiver()
    public static selectTile({ setState }: StateContext<TileCanvasStateModel>, { payload }: EmitterAction<number>): void {
        setState((state: TileCanvasStateModel) => {
            if (payload) {
                state.selectedTile = payload;
            } else {
                state.selectedTile = -1;
            }
            return state;
        });
    }

    @Receiver()
    public static setTilePattern({ setState }: StateContext<TileCanvasStateModel>, { payload }: EmitterAction<TilePatternItem>): void {
        setState((state: TileCanvasStateModel) => {
            state.tilePattern = payload;
            if (payload.tileCount !== state.contents.length) {
                if (payload.tileCount > state.contents.length) {
                    for (let index = state.contents.length; index < payload.tileCount; index++) {
                        state.contents[index] = this.allCurrentContents[index];
                    }
                } else {
                    state.contents.splice(payload.tileCount, state.contents.length - payload.tileCount);
                }
                state.contentUpdateId = SafeGuid.newGuid();
            }
            return state;
        });
    }

    @Receiver()
    public static changeContent({ getState, setState }: StateContext<TileCanvasStateModel>, { payload }: EmitterAction<ContentChange>): void {
        if (!payload || !payload.content || !payload.content.mainContent || payload.content.id.isEmpty()) {
            return;
        }

        const currentState = getState();

        const index = payload.tileId - 1;
        if (index > -1 && index < currentState.contents.length) {
            setState((state: TileCanvasStateModel) => {
                this.trySetContent(state.contents, index, this.getContent(payload.content));
                state.contentUpdateId = SafeGuid.newGuid();
                return state;
            });
        } else {
            console.log('A tile id should be specified.');
        }
    }
}
