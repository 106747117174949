import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../services/state/state.service';

@Pipe({ name: 'stateName' })
export class StateNamePipe implements PipeTransform {
    constructor(private stateService: StateService, private _translateService: TranslateService) {}

    public transform(stateId: IGuid | null): Observable<string> {
        if (!stateId) return of(this._translateService.instant('STE_LABEL_NONE') as string);

        return this.stateService.getState$(stateId).pipe(map((x) => x?.name ?? (this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string)));
    }
}
