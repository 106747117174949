// Autogenerated: v2/help/Entities/ApiPointOfSaleRole?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:18 PM
import { RoleEntity } from './RoleEntity';
import { IPointOfSaleRoleEntity } from '../../Interface/IPointOfSaleRoleEntity';

export class PointOfSaleRoleEntity extends RoleEntity implements IPointOfSaleRoleEntity {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

