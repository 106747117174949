import { Component, Injector, Input, OnInit } from '@angular/core';
import { GenToastService, MeltedModalAction } from '@genetec/gelato-angular';
import { CardholderManagementClient } from '@modules/access-control/api/api';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { take } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-cardholder-delete-modal',
    templateUrl: './cardholder-delete-modal.component.html',
})
export class CardholderDeleteModalComponent extends ConnectionAwareModalComponent implements OnInit {
    @Input() public cardholderId?: SafeGuid;
    @Input() public cardholderName?: string;
    @Input() public onDeleteSuccessful?: () => void;

    public readonly modalAction = MeltedModalAction;

    constructor(
        protected authService: AuthService,
        injector: Injector,
        trackingService: TrackingService,
        private cardholderManagementClient: CardholderManagementClient,
        private toastService: GenToastService,
        private translateService: TranslateService,
        private logger: LoggerService
    ) {
        super(authService, injector, trackingService);
    }

    public onDeleteClicked = (): Promise<boolean> => {
        if (this.cardholderId) {
            this.cardholderManagementClient
                .delete(this.cardholderId)
                .pipe(take(1))
                .subscribe(
                    () => this.onDeleteSuccessful?.(),
                    (error) => {
                        this.logger.traceDebug(error);
                        // TODO: Error toast to be added in separate PBI
                        // this.toastService.show({
                        //     id: 'cardholder-save-error',
                        //     flavor: ToastFlavor.Error,
                        //     text: this.translateService.instant('STE_LABEL_ERROR') as string,
                        //     secondaryText: this.translateService.instant('STE_MESSAGE_ERROR_CARDHOLDERSAVE_ERROR') as string,
                        // });
                    }
                );
        }
        return Promise.resolve(true);
    };
}
