<div class="entity-state-overlay">
    <!-- Entity Name Section -->
    <span class="entity-state-overlay__entity-name-section" [ngClass]="{ visible: tileDetailsVisibility !== TileDetailsVisibility.Hidden }">
        <!-- Title -->
        <ng-content select="[entityTitle]"></ng-content>

        <!-- Caret menu -->
        <span class="d-flex" style="cursor: pointer" (click)="toggleEntitySwitcher()" *ngIf="entitySwitcherDataSource$ | async as dataSource">
            <ng-container *ngIf="dataSource.length > 1">
                <gen-icon id="caret-icon-{{ componentGuid }}" class="entity-switcher" size="medium" [icon]="Icon.AngleDown"></gen-icon>
            </ng-container>
        </span>
    </span>

    <!-- Entity State Section-->
    <span class="entity-state-overlay__entity-state-section" [ngClass]="{ visible: tileDetailsVisibility === TileDetailsVisibility.Full }">
        <ng-content select="[primaryState]"></ng-content>
        <ng-content class="secondary-state" select="[secondaryState]"></ng-content>
    </span>

    <!-- Selected Camera Section -->
    <span class="entity-state-overlay__selected-camera-section" [ngClass]="{ visible: tileDetailsVisibility === TileDetailsVisibility.Full }">
        <!-- Icon -->
        <span class="subgrid">
            <span class="icon">
                <ng-container *ngIf="customIconId">
                    <app-custom-icon slot="gen-icon" [icon]="entityIcon" [entityId]="entityId" [customIconId]="customIconId" [customIconState]="customIconState"></app-custom-icon>
                </ng-container>
                <ng-container *ngIf="!customIconId">
                    <gen-icon class="entity-state-overlay__icon" [icon]="entityIcon"></gen-icon>
                </ng-container>
            </span>

            <!-- Camera name -->
            <gen-text class="camera-name" [flavor]="TextFlavor.Secondary"> {{ currentEntityText }} </gen-text>
        </span>
    </span>

    <!-- Main Action Button & Ellipsis -->
    <div class="entity-state-overlay__action" [ngClass]="{ visible: tileDetailsVisibility === TileDetailsVisibility.Full }" *ngIf="commandsUsage !== null">
        <ng-container *ngIf="!isComponentFullyLoaded">
            <gen-spinner class="va-center">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        </ng-container>

        <app-command-buttons
            [commandsUsage]="commandsUsage"
            [excludedCommandIds]="excludedCommandIds"
            [maximumButtonCount]="maximumButtonCount"
            [areButtonsSpaced]="areButtonsSpaced"
            (isLoadingChange)="isLoadingChangeCallback($event)"
            [mainButtonFlavor]="ButtonFlavor.Outline"
        >
        </app-command-buttons>
    </div>

    <!-- The caret context menu, this has to be outside of targetSelector's scope for display purposes (otherwise will display underneath the navbar) -->
    <gen-menu #contextMenu targetSelector="#caret-icon-{{ componentGuid }}" [appMenuDataSource]="entitySwitcherDataSource$ | async"></gen-menu>
</div>
