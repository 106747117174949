import { Constants } from '@modules/shared/utilities/constants';
import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const MapProviderTypes = Constants({
    Image: SafeGuid.EMPTY,
    Esri: SafeGuid.parse('2f3a8f7a-51f4-4558-970f-0521687b661e'),
    BeNomad: SafeGuid.parse('aef66392-87a1-4f8b-a281-ab12691fb8ee'),
    Bing: SafeGuid.parse('d0ceb371-f10a-4e12-a2c1-df617d6674a8'),
    BingHybrid: SafeGuid.parse('94e2fcb4-caac-45ea-a1f9-8147c4b14970'),
    BingSatellite: SafeGuid.parse('3ac742dd-966b-4cfb-b67d-33e7f82f2d37'),
    Google: SafeGuid.parse('d7287da0-a7ff-405f-8166-b6baf26d066c'),
    GoogleTerrain: SafeGuid.parse('a42edf2e-63c5-4967-9dbf-4efb3af7bc11'),
    Custom: SafeGuid.parse('2df9773a-18d5-4bb7-88e9-255bb6805ab2'),
});
