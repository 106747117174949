import { IGuid, SafeGuid } from 'safeguid';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CommandContext, CommandDisplay } from '../../../interfaces/plugins/public/plugin-services-public.interface';
import { EntityCommandContext } from '../entity-command-context';
import { SubscriptionCollection } from '../../../utilities/subscription-collection';
import { CommandsUsageContext } from './commands-usage-context';

export class EntityCommandsUsageContext extends CommandsUsageContext implements EntityCommandContext {
    public type: IGuid;
    public data: any;
    public subscriptions = new SubscriptionCollection();

    constructor(
        commandContext: CommandContext,
        public entityCache: IEntityCacheTask,
        public invalidateCanExecute: (commandId: IGuid, canExecute?: boolean) => void,
        public invalidateDisplay: (commandId: IGuid, commandDisplay?: CommandDisplay) => void
    ) {
        super();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.data = commandContext.data;
        this.type = commandContext.type;
    }
}
