import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessPointEntityFields, IAccessPointEntity } from 'RestClient/Client/Interface/IAccessPointEntity';
import { IEntityCacheTask, modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { AccessPointEntity } from 'RestClient/Client/Model/AccessControl/AccessPointEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { DeviceStates } from '@modules/shared/enumerations/device-states';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const isContentSupported = (content: Content, pluginType: IGuid): boolean => {
    return !!(content?.type.equals(AccessControlContentTypes.Reader) && (content.contextContent === null || pluginType.equals(PluginTypes.Widget)));
};

@Component({
    selector: 'app-reader-actions-widget',
    templateUrl: './reader-actions-widget.component.html',
})
@InternalContentPluginDescriptor({
    type: ReaderActionsWidgetComponent,
    pluginTypes: [PluginTypes.Widget, PluginTypes.MapPopupCompact],
    exposure: {
        id: ReaderActionsWidgetComponent.pluginId,
        priority: 0,
    },
    isContentSupported,
})
export class ReaderActionsWidgetComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('1D8422E1-A445-4010-9556-1A17DAE529CE');
    private static monitoredFields = [AccessPointEntityFields.deviceStateField, AccessPointEntityFields.runningStateField];

    public content?: Content;
    public dataContext?: ContentService;
    public get entityName(): string {
        return stringFormat(this.translateService.instant('STE_LABEL_FORMAT_READER_X') as string, this.readerName);
    }
    public isLoading = true;
    public get isShunted(): boolean {
        return this.isShuntedValue;
    }
    public set isShunted(value: boolean) {
        this.isShuntedValue = value;
        this.isShuntedString = this.translateService.instant(value ? 'STE_LABEL_DOOR_READER_SHUNTED' : 'STE_LABEL_DOOR_READER_ENABLED') as string;
    }
    public isShuntedString = '';
    public reader: AccessPointEntity | null = null;

    protected entityCacheTask!: IEntityCacheTask;
    protected get monitoredFields(): string[] {
        return ReaderActionsWidgetComponent.monitoredFields;
    }
    protected scClient: WebAppClient | null = null;

    private isShuntedValue = false;
    private get readerName(): string {
        return this.reader?.name || '';
    }

    constructor(securityCenterClientService: SecurityCenterClientService, protected translateService: TranslateService, trackingService: TrackingService) {
        super(trackingService);
        this.scClient = securityCenterClientService?.scClient;
    }

    public async ngOnDestroy() {
        await this.entityCacheTask?.dispose();
        super.ngOnDestroy();
    }

    public async ngOnInit() {
        super.ngOnInit();
        if (this.content) {
            await this.getAccessPointAsync(SafeGuid.parse(this.content.source));
            await this.initializeAccessPointInfo();
        }
        this.isLoading = false;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    protected async getAccessPointAsync(accessPointId: IGuid): Promise<void> {
        if (this.scClient) {
            this.entityCacheTask = this.scClient.buildEntityCache(this.monitoredFields);
            this.reader = (await this.entityCacheTask.getEntityAsync<AccessPointEntity, IAccessPointEntity>(AccessPointEntity, accessPointId, true)) as AccessPointEntity;
        }
    }

    protected async initializeAccessPointInfo(): Promise<void> {
        if (this.reader && this.entityCacheTask) {
            this.isShunted = this.reader.deviceState === DeviceStates.Disabled;

            await this.entityCacheTask.detectFieldChangeAsync(
                this.reader,
                () => {
                    const _ = this.reader?.deviceState === DeviceStates.Disabled;
                },
                ((_: IAccessPointEntity, newValue: string, __: string) => {
                    this.isShunted = newValue === DeviceStates.Disabled;
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<string>
            );
        }

        return Promise.resolve();
    }
}
