<div *ngIf="!isLoading; else loading" [style.cursor]="'pointer'" (click)="navigateToDoorAsync()" appClickActionOnSpaceEnter tabindex="0">
    <div class="title-bar">
        <gen-text [flavor]="TextFlavor.Label">{{ 'STE_ENTITY_DOOR' | translate }}</gen-text>
        <gen-icon [icon]="Icon.AngleRight"></gen-icon>
    </div>
    <gen-item>
        <gen-icon [icon]="doorIcon$ | async"></gen-icon>
        <gen-text>{{ (door$ | async)?.name ?? '' }}</gen-text>
        <gen-text [slot]="ItemSlot.TextSecondary"> {{ doorSecondaryText$ | async }} </gen-text>
    </gen-item>
</div>

<ng-template #loading>
    <gen-spinner>{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
</ng-template>
