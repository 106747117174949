// Autogenerated: v2/help/Entities/ApiUserTask?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:09 PM
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';

// #region Fields

export class UserTaskEntityFields extends EntityFields {
    public static contextField = 'Context';
    public static guidField = 'Guid';
    public static isSystemTaskField = 'IsSystemTask';
    public static pathField = 'Path';
    public static taskSubTypeField = 'TaskSubType';
    public static taskTypeField = 'TaskType';
}

// #endregion Fields

export interface IUserTaskEntity extends IEntity {
    // #region REST Properties

    context: string;

    readonly guid: IGuid;

    readonly isSystemTask: boolean;

    path: string;

    taskSubType: IGuid;

    taskType: IGuid;

    // #endregion REST Properties

}

