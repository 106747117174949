<gen-melted-modal
    id="UnsavedChangesModal"
    genTitle="{{ 'STE_TITLE_UNSAVED_CHANGES' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_UNSAVED_CHANGES_SAVE' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_UNSAVED_CHANGES_CANCEL' | translate }}"
    genOptionalActionText="{{ 'STE_BUTTON_UNSAVED_CHANGES_DISCARD' | translate }}"
    [genOnDefaultAction]="onSave"
    [genOnAlternativeAction]="onCancel"
    [genOnOptionalAction]="onDiscard"
    [genCanClose]="true"
    [genIsBlocking]="true"
    [genDestructiveAction]="MeltedModalAction.Optional"
>
    <gen-melted-modal-body>
        <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_MESSAGE_UNSAVED_CHANGES' | translate }}</gen-text>
    </gen-melted-modal-body>
</gen-melted-modal>
