import { Injectable } from '@angular/core';
import { ActionType } from 'RestClient/Client/Enumerations/ActionTypes';
import { ActionBase } from 'RestClient/Client/Event/ActionBase';
import { SafeGuid } from 'safeguid';
import { AudioService } from '@modules/shared/services/audio/audio.service';
import { ActionHandler } from '../../shared/services/actions/actions.service';

@Injectable({
    providedIn: 'root',
})
export class PlaySoundActionHandler implements ActionHandler {
    constructor(private audioService: AudioService) {}

    public async executeAsync(action: ActionBase): Promise<boolean> {
        if (action.actionType === ActionType.PlaySound) {
            const sound = action.getField<string>('sound');
            if (sound) {
                try {
                    await this.audioService.playAudio(SafeGuid.parse(sound));
                    return true;
                } catch (error) {
                    return false;
                }
            }
        }
        return false;
    }
}
