// Autogenerated: v2/help/Entities/ApiDoorSideAccessPoint?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-15 12:15:58 PM
import { ObservableCollection } from './../../../Helpers/ObservableCollection';
import { DoorAccessPointEntity } from './DoorAccessPointEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { FieldObject } from './../../../Helpers/FieldObject';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { IDoorSideAccessPointEntity, DoorSideAccessPointEntityFields } from '../../Interface/IDoorSideAccessPointEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class DoorSideAccessPointEntity extends DoorAccessPointEntity implements IDoorSideAccessPointEntity {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Rules', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async setDeviceStateAsync(shunt: boolean, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('shunt', shunt);

        return this.executeRequestTransactionAsync<IRestResponse>('SetDeviceState', 'POST', inParam, transaction, responseHandler);
    }

    // #endregion REST Methods

}

