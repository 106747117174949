import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { GenAngularModule } from '@genetec/gelato-angular';
import { GeneralModule } from '@modules/general/general.module';
import { NgxsModule } from '@ngxs/store';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { ReactiveFormsModule } from '@angular/forms';
import { FeatureFlagsModule } from '@modules/feature-flags/feature-flags.module';
import { ModuleDescriptor } from '@modules/shared/interfaces/modules/public/module-public.interface';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GetBaseUriForCodeGen } from '@src/app/utilities/base-uri-getter';
import { SharedCommands } from '../shared/enumerations/shared-commands';
import { CommandsService, COMMANDS_SERVICE } from '../shared/interfaces/plugins/public/plugin-services-public.interface';
import { SharedModule } from '../shared/shared.module';
import { baseUrl, CardholderManagementClient, CredentialsClient } from './api/api';
import { CardholderEditContextState } from './cardholder-edit-context.state';
import { AccessControlTaskComponent } from './components/access-control-task/access-control-task.component';
import { CardholderActionWidgetComponent } from './components/cardholder/cardholder-action-widget/cardholder-action-widget.component';
import { CardholderCardComponent } from './components/cardholder/cardholder-card/cardholder-card.component';
import { CardholderDetailsPaneComponent } from './components/cardholder/cardholder-details-pane/cardholder-details-pane.component';
import { CardholderInfoWidgetComponent } from './components/cardholder/cardholder-info-widget/cardholder-info-widget.component';
import { CardholderPictureWidgetComponent } from './components/cardholder/cardholder-picture-widget/cardholder-picture-widget.component';
import { CardholderPreviewWidgetComponent } from './components/cardholder/cardholder-preview-widget/cardholder-preview-widget.component';
import { CardholderStateWidgetBaseComponent } from './components/cardholder/cardholder-state-widget-base.component';
import { CardholderSubTaskComponent } from './components/cardholder/cardholder-sub-task/cardholder-sub-task.component';
import { CardholderWidgetBaseComponent } from './components/cardholder/cardholder-widget-base.component';
import { CredentialActionWidgetComponent } from './components/credential/credential-action-widget/credential-action-widget.component';
import { CredentialInfoWidgetComponent } from './components/credential/credential-info-widget/credential-info-widget.component';
import { CredentialPreviewWidgetComponent } from './components/credential/credential-preview-widget/credential-preview-widget.component';
import { CredentialWidgetBaseComponent } from './components/credential/credential-widget-base.component';
import { DoorCompactInfoComponent } from './components/door-compact-info/door-compact-info.component';
import { DoorActionsWidgetComponent } from './components/door/door-actions-widget/door-actions-widget.component';
import { DoorEventsWidgetComponent } from './components/door/door-events-widget/door-events-widget.component';
import { DoorPreviewWidgetComponent } from './components/door/door-preview-widget/door-preview-widget.component';
import { DoorWidgetBaseComponent } from './components/door/door-widget-base.component';
import { OverrideScheduleModalComponent } from './components/door/override-schedule-modal/override-schedule-modal.component';
import { ShuntReaderModalComponent } from './components/door/shunt-reader-modal/shunt-reader-modal.component';
import { DoorEventWidgetComponent } from './components/maps/door-event-widget/door-event-widget.component';
import { ReaderActionsWidgetComponent } from './components/reader/reader-actions-widget/reader-actions-widget.component';
import { DoorEntityStateComponent } from './components/tile/door-entity-state/door-entity-state.component';
import { DoorStateComponent } from './components/tile/door-state/door-state.component';
import { AccessControlFeatureFlags } from './feature-flags';
import { CardholderCommandProvider } from './services/cardholder-command-provider';
import { CredentialCommandProvider } from './services/credential-command-provider';
import { DoorCommandProvider } from './services/door-command-provider';
import { ReaderCommandProvider } from './services/reader-command-provider';
import { CardholderEditService } from './services/cardholder-edit-service';
import { CardholderIdentityFormComponent } from './components/cardholder/cardholder-edit-form/cardholder-identity-form/cardholder-identity-form.component';
import { CardholderStatusFormComponent } from './components/cardholder/cardholder-edit-form/cardholder-status-form/cardholder-status-form.component';
import { CardholderAccessRightsFormComponent } from './components/cardholder/cardholder-edit-form/cardholder-access-rights-form/cardholder-access-rights-form.component';
import { CardholderAdvancedFormComponent } from './components/cardholder/cardholder-edit-form/cardholder-advanced-form/cardholder-advanced-form.component';
import { CardholderRelationsSubFormComponent } from './components/cardholder/cardholder-edit-form/cardholder-access-rights-sub-form/cardholder-relations-sub-form.component';
import { CardholderDeleteModalComponent } from './components/cardholder/cardholder-delete-modal/cardholder-delete-modal.component';
import { CardholderCancelModalComponent } from './components/cardholder/unsaved-changes-modal/unsaved-changes-modal.component';
import { CardholderCardViewComponent } from './components/cardholder/cardholder-card-view/cardholder-card-view.component';
import { CardholderEditablePictureComponent } from './components/cardholder/cardholder-edit-form/cardholder-editable-picture/cardholder-editable-picture.component';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        GenAngularModule,
        GeneralModule,
        NgxsModule.forFeature([CardholderEditContextState]),
        FeatureFlagsModule.forRoot(AccessControlFeatureFlags),
        InfiniteScrollModule,
    ],
    declarations: [
        DoorActionsWidgetComponent,
        DoorEventsWidgetComponent,
        CardholderWidgetBaseComponent,
        CardholderStateWidgetBaseComponent,
        CardholderActionWidgetComponent,
        CardholderPreviewWidgetComponent,
        CardholderPictureWidgetComponent,
        CardholderInfoWidgetComponent,
        CredentialActionWidgetComponent,
        CredentialWidgetBaseComponent,
        CredentialPreviewWidgetComponent,
        DoorEventWidgetComponent,
        CredentialInfoWidgetComponent,
        OverrideScheduleModalComponent,
        ShuntReaderModalComponent,
        DoorCompactInfoComponent,
        DoorEntityStateComponent,
        DoorStateComponent,
        DoorPreviewWidgetComponent,
        DoorWidgetBaseComponent,
        ReaderActionsWidgetComponent,
        AccessControlTaskComponent,
        CardholderSubTaskComponent,
        CardholderCardComponent,
        CardholderDetailsPaneComponent,
        CardholderIdentityFormComponent,
        CardholderStatusFormComponent,
        CardholderAccessRightsFormComponent,
        CardholderAdvancedFormComponent,
        CardholderRelationsSubFormComponent,
        CardholderDeleteModalComponent,
        CardholderCancelModalComponent,
        CardholderCardViewComponent,
        CardholderEditablePictureComponent,
    ],
    providers: [
        DoorCommandProvider,
        CardholderCommandProvider,
        CredentialCommandProvider,
        ReaderCommandProvider,
        CardholderManagementClient,
        CredentialsClient,
        CardholderEditService,
        { provide: baseUrl, deps: [], useFactory: GetBaseUriForCodeGen },
    ],
})
@ModuleDescriptor({
    type: AccessControlModule,
    inclusions: {
        globalPrivileges: [
            KnownPrivileges.addCardholdersPrivilege,
            KnownPrivileges.modifyCardholderNamePrivilege,
            KnownPrivileges.changeCardholderStatusPrivilege,
            KnownPrivileges.modifyCardholdersPrivilege,
            KnownPrivileges.modifyCardholderGroupsPrivilege,
            KnownPrivileges.modifyCredentialAssociationToCardholderPrivilege,
            KnownPrivileges.addCredentialsPrivilege,
            KnownPrivileges.modifyCardholdersPrivilege,
            KnownPrivileges.modifyAccessRulesPrivilege,
            KnownPrivileges.changeCardholderOptionsPrivilege,
            KnownPrivileges.deleteCardholdersPrivilege,
            KnownPrivileges.generalSettingsLogicalIdPrivilege,
            KnownPrivileges.setCardholderSecurityClearancePrivilege,
            KnownPrivileges.modifyCardholderPicturePrivilege,
        ],
    },
})
export class AccessControlModule {
    constructor(
        doorCommandProvider: DoorCommandProvider, // DO NOT DELETE
        cardholderCommandProvider: CardholderCommandProvider, // DO NOT DELETE
        credentialCommandProvider: CredentialCommandProvider, // DO NOT DELETE
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        readerCommandProvider: ReaderCommandProvider // DO NOT DELETE
    ) {
        commandsService.appendRequirements(SharedCommands.ShareEntity, {
            supportedEntityTypes: new Set<string>([
                EntityTypes.Doors,
                EntityTypes.Elevators,
                EntityTypes.Credentials,
                EntityTypes.Cardholders,
                EntityTypes.CardholderGroups,
                EntityTypes.AccessPoints,
            ]),
        });
    }
}
