<ng-container>
    <div [ngClass]="{ tooltip: genIsTooltipVisible }">
        <input
            class="gen-slider lower-fill"
            type="range"
            [name]="name"
            min="0"
            [max]="tickLabels.length - 1"
            step="1"
            [ngModel]="value"
            (ngModelChange)="onPeriodSliderValueChanged($event)"
            (mouseenter)="onMouseEnter()"
            (mouseleave)="onMouseLeave()"
            style="background: linear-gradient(to right, 
                {{ fillColor }} 0%, 
                {{ fillColor }} {{ valuePercentage }}%, 
                rgba(0, 0, 0, 0.25) {{ valuePercentage }}%, 
                rgba(0, 0, 0, 0.25) 100%)"
        />
        <div class="gen-slider-tooltip gen-tooltip" [style.left]="tooltipPosition" *ngIf="genIsTooltipVisible">
            <span>{{ tickLabels[value] | translate }}</span>
        </div>
    </div>
    <div class="ticks lower-fill">
        <div class="tick" *ngFor="let tick of tickLabels">
            <gen-text class="tick-label" [flavor]="TextFlavor.Label">{{ tick | translate }}</gen-text>
        </div>
    </div>
</ng-container>
