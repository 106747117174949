import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { IGuid } from 'safeguid';
import { ContextTypes } from '../../../interfaces/plugins/public/context-types';
import { COMMANDS_SERVICE } from '../../../interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '../../../services/commands/commands.service';
import { CommandsUsage } from '../../../services/commands/commands-usage/commands-usage';
import { TrackingService } from '../../../services/tracking.service';
import { TrackedComponent } from '../../tracked/tracked.component';
import { SubscriptionCollection } from '../../../utilities/subscription-collection';
import { Content, DisplayContext } from '../../../interfaces/plugins/public/plugin-public.interface';
@Component({
    selector: 'app-entity-commands-widget-host',
    templateUrl: './entity-commands-widget-host.component.html',
    styleUrls: ['./entity-commands-widget-host.component.scss'],
})
export class EntityCommandsWidgetHostComponent extends TrackedComponent implements OnInit, OnDestroy, OnChanges {
    public static readonly DISPLAYED_ACTION_COUNT = 4;

    @Input() public stateTooltip?: string | null;
    @Input() public content?: Content | null;
    @Input() public excludedCommandIds: IGuid[] = [];
    @Input() public includedCommandIds: IGuid[] = [];
    @Input() public isOwnerLoading = false;
    @Input() public areButtonsSpaced = true;
    @Input() public set displayContext(value: DisplayContext | undefined) {
        this.displayContextValue = value;
    }

    public get displayContext(): DisplayContext | undefined {
        return this.displayContextValue;
    }

    public get DisplayContext(): typeof DisplayContext {
        return DisplayContext;
    }

    public isLoading = true;
    public commandsUsage!: CommandsUsage;

    public get maximumButtonCount(): number {
        return EntityCommandsWidgetHostComponent.DISPLAYED_ACTION_COUNT;
    }

    private areCommandsLoading = true;
    private displayContextValue?: DisplayContext;
    private subscriptions = new SubscriptionCollection();

    constructor(@Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService, trackingService: TrackingService, private cdRef: ChangeDetectorRef) {
        super(trackingService);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.isOwnerLoading !== 'undefined') {
            const change = changes.isOwnerLoading;
            if (!change.isFirstChange()) {
                this.refreshIsLoading();
            }
        }
    }

    public ngOnDestroy() {
        this.subscriptions?.unsubscribeAll();
        super.ngOnDestroy();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.createCommandsUsageAsync().fireAndForget();
    }

    public onAreCommandsLoadingChanged(areCommandsLoading: boolean): void {
        this.areCommandsLoading = areCommandsLoading;
        this.refreshIsLoading();
    }

    private async createCommandsUsageAsync() {
        if (this.content) {
            this.commandsUsage = await this.commandsService.getCommandsUsage({ type: ContextTypes.Content, data: this.content }, this.includedCommandIds);
            this.cdRef.detectChanges();
            this.subscriptions.add(this.commandsUsage.subscribe());
        }
    }

    private refreshIsLoading() {
        // Execute later, otherwise Angular complains about ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.isLoading = this.isOwnerLoading || this.areCommandsLoading;
            this.cdRef.detectChanges();
        });
    }
}
