import { WebAppClient } from 'WebClient/WebAppClient';
import { IVideoPlayer, LoopChangeEvent } from './ivideoplayer';
import { ExportModel, ExportProgressEvent } from './export';
import gwp, {
    AudioAvailabilityChangeEvent,
    AudioStateChangeEvent,
    Camera,
    ErrorStatusEvent,
    IDewarperControl,
    IDigitalZoomControl,
    IMediaGatewayService,
    IPtzControl,
    ITimelineProvider,
    IWebPlayer,
    PlayerModeChangeEvent,
    PlayerState,
    PlayerStateChangeEvent,
    PlaySpeed,
    PlaySpeedChangeEvent,
    StreamingConnectionStatusChangeEvent,
    TokenRetrieverFct,
} from './Marmot/gwp';
import { HtmlElements } from './Marmot/players/htmlElements';
import { WebPlayer } from './Marmot/players/webPlayer';
import { buildMediaGatewayService, MediaGatewayService } from './Marmot/services/mediaGatewayService';
import { TimelineProvider } from './Marmot/timelineProvider';
import { ILiteEvent, LiteEvent } from './Marmot/utils/liteEvents';
import { ILogger, Logger } from './Marmot/utils/logger';
import { WebAppTransport, WebAppTransportBuilder } from './webapp-transport';
import { TimelinePartEvent } from './Timeline/timelinePartEvent';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { Utils } from './Marmot/utils/Utils';
import { PlayersList } from './Marmot/Players';
import { IStreamUsageSelectorBuilder } from './Marmot/players/StreamUsageSelector';
import { PtzThrottlingService } from './ptzThrottlingService';
import { IGuid, SafeGuid } from 'safeguid';

export class Size {
    public width: number;
    public height: number;

    constructor(width: number, heigth: number) {
        this.width = width;
        this.height = heigth;
    }

    public equals(size: Size): boolean {
        return size.width === this.width && size.height === this.height;
    }

    public toString(): string {
        return JSON.stringify(this);
    }
}

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class VideoCorePlayer implements IVideoPlayer, IWebPlayer {
    // #region Fields
    private readonly _logger: ILogger;
    private readonly _playerId: number;
    private _client!: WebAppClient;
    private _placeholder!: HTMLDivElement;
    private _transport!: WebAppTransport;
    private _cameraId = SafeGuid.EMPTY;

    private isLooping = false;
    private loopStart: Date | undefined;
    private loopEnd: Date | undefined;
    private lastLoopingSeek = 0;

    private readonly _htmlElements: HtmlElements;

    // Will be set only if we created it and are responsible to dispose it.
    private _mediaGatewayService?: MediaGatewayService;
    private _webPlayer: WebPlayer | undefined;
    private readonly _timelineProvider = new TimelineProvider();
    private _isDisposed = false;

    private static _nextPlayerId = 1;

    private _lastPlayerState: PlayerState = PlayerState.Stopped;

    private readonly errorEvent = new LiteEvent<ErrorStatusEvent>();
    private readonly frameRenderedEvent = new LiteEvent<Date>();
    private readonly streamStatusEvent = new LiteEvent<StreamingConnectionStatusChangeEvent>();
    private readonly playerStateEvent = new LiteEvent<PlayerStateChangeEvent>();
    private readonly playSpeedEvent = new LiteEvent<PlaySpeedChangeEvent>();
    private readonly loopEvent = new LiteEvent<LoopChangeEvent>();
    private readonly audioEvent = new LiteEvent<AudioStateChangeEvent>();
    private readonly audioAvailabilityEvent = new LiteEvent<AudioAvailabilityChangeEvent>();
    private readonly playerModeEvent = new LiteEvent<PlayerModeChangeEvent>();
    private readonly bufferingProgress = new LiteEvent<number>();
    private readonly exportProgress = new LiteEvent<ExportProgressEvent>();
    private readonly timelinePart = new LiteEvent<TimelinePartEvent>();

    // #endregion

    // #region Properties

    public get playerId(): number {
        return this._playerId;
    }

    public get renderingDimension(): Size {
        return new Size(this.canvasElement.scrollWidth, this.canvasElement.scrollHeight);
    }

    public get lastFrameTime(): Date {
        return this.webPlayer.lastFrameTime;
    }

    public get ptzControl(): IPtzControl {
        return this.webPlayer.PtzControl;
    }

    public get digitalZoomControl(): IDigitalZoomControl | null {
        if (this.webPlayer.DigitalZoomControl) return this.webPlayer.DigitalZoomControl;
        return null;
    }

    public get dewarperControl(): IDewarperControl | null {
        return this.webPlayer.Dewarper;
    }

    public get timelineProvider(): ITimelineProvider {
        return this._timelineProvider;
    }

    public get isLive(): boolean {
        return this._webPlayer?.isLive === true;
    }

    public get isPaused(): boolean {
        return this._webPlayer?.isPaused === true;
    }

    public get playSpeed(): number {
        if (this._webPlayer) {
            return this._webPlayer?.playSpeed.Value;
        }
        return 0;
    }

    public get isAudioAvailable(): boolean {
        return this._webPlayer?.isAudioAvailable === true;
    }

    public get isAudioEnabled(): boolean {
        return this._webPlayer?.isAudioEnabled === true;
    }

    public get isStarted(): boolean {
        return this._webPlayer !== undefined;
    }

    public get playerState(): PlayerState {
        return this._lastPlayerState;
    }

    // retrieve the underlying audio element
    public get audioElement(): HTMLAudioElement {
        return this._htmlElements.AudioElement;
    }

    // retrieve the underlying canvas element to render motion jpeg video
    public get canvasElement(): HTMLCanvasElement {
        return this._htmlElements.CanvasElement;
    }

    // retrieve the underlying video element
    public get videoElement(): HTMLVideoElement {
        return this._htmlElements.VideoElement;
    }

    // Base url
    public get baseUrl(): string | undefined {
        return this._client.rest.restServerUrl + '/media/';
    }

    public enableH264 = true;

    // #endregion

    //#region Events

    public get onErrorStateRaised(): ILiteEvent<ErrorStatusEvent> {
        return this.errorEvent.expose();
    }

    public get onFrameRendered(): ILiteEvent<Date> {
        return this.frameRenderedEvent.expose();
    }

    public get onStreamStatusChanged(): ILiteEvent<StreamingConnectionStatusChangeEvent> {
        return this.streamStatusEvent.expose();
    }

    public get onPlayerStateChanged(): ILiteEvent<PlayerStateChangeEvent> {
        return this.playerStateEvent.expose();
    }

    public get onPlaySpeedChanged(): ILiteEvent<PlaySpeedChangeEvent> {
        return this.playSpeedEvent.expose();
    }

    public get onLoopChanged(): ILiteEvent<LoopChangeEvent> {
        return this.loopEvent.expose();
    }

    public get onAudioStateChanged(): ILiteEvent<AudioStateChangeEvent> {
        return this.audioEvent.expose();
    }

    public get onAudioAvailabilityChanged(): ILiteEvent<AudioAvailabilityChangeEvent> {
        return this.audioAvailabilityEvent.expose();
    }

    public get onPlayerModeChanged(): ILiteEvent<PlayerModeChangeEvent> {
        return this.playerModeEvent.expose();
    }

    public get onBufferingProgress(): ILiteEvent<number> {
        return this.bufferingProgress.expose();
    }

    public get onExportProgress(): ILiteEvent<ExportProgressEvent> {
        return this.exportProgress.expose();
    }

    public get onTimelinePart(): ILiteEvent<TimelinePartEvent> {
        return this.timelinePart.expose();
    }

    //#endregion

    // #region Constructor

    constructor(client: WebAppClient, placeholder: HTMLDivElement) {
        PlayersList.Instance.add(this);
        this._playerId = VideoCorePlayer._nextPlayerId++;
        this._logger = new Logger(this._playerId, 'WebPlayer');
        this._client = client;
        this._placeholder = placeholder;
        this._htmlElements = new HtmlElements(this._placeholder);
    }

    public dispose(): void {
        if (this._isDisposed) return;

        PlayersList.Instance.remove(this);

        this.errorEvent.dispose();
        this.frameRenderedEvent.dispose();
        this.streamStatusEvent.dispose();
        this.playerStateEvent.dispose();
        this.playSpeedEvent.dispose();
        this.audioEvent.dispose();
        this.audioAvailabilityEvent.dispose();
        this.playerModeEvent.dispose();
        this._timelineProvider.dispose();

        this._webPlayer?.disposeAsync().then(() => this._mediaGatewayService?.stop());
        this._htmlElements.dispose();

        this._isDisposed = true;
    }

    //#endregion

    //#region Methods

    public getSnapshot(): ImageData | null {
        return this.webPlayer.getSnapshot();
    }

    public async start(camera: string, mediaGatewayEndpoint: string, tokenRetriever: TokenRetrieverFct): Promise<void> {
        this.throwIfDisposed();
        if (this.isStarted) {
            throw new Error(`Player ${this._playerId} is already started`);
        }

        this._logger.debug?.trace('Starting with its own MediaGatewayService');
        this._mediaGatewayService = (await buildMediaGatewayService(mediaGatewayEndpoint, tokenRetriever)) as MediaGatewayService;
        try {
            await this.startWithService(camera, this._mediaGatewayService);
        } catch (error) {
            this._logger.error?.trace('Couldnt start web player: ', error);
            this._mediaGatewayService?.stop();
            throw error;
        }
    }

    public async startAsync(cameraId: IGuid, streamUsageSelectorBuilder: IStreamUsageSelectorBuilder | null = null): Promise<void> {
        this.throwIfDisposed();
        if (this.isStarted) {
            throw new Error(`Player ${this._playerId} is already started`);
        }
        try {
            // ensure GWP is created
            if (!window.gwp) {
                window.gwp = new gwp();
            }

            this._cameraId = cameraId;
            const builder = new WebAppTransportBuilder(this._client);
            this._transport = builder.getTransport();
            this._transport.setExportEventHandler(this.exportProgress);
            this._transport.setTimelinePartEventHandler(this.timelinePart);
            const mgService = await window.gwp.buildMediaGatewayServiceWithTransport('', (cam: string) => builder.getTokenRetriever(cam), builder, streamUsageSelectorBuilder);
            this._mediaGatewayService = mgService as MediaGatewayService;
            await this.startWithService(cameraId.toString(), this._mediaGatewayService);
        } catch (error) {
            this._logger.error?.trace('Couldnt start web player: ', error);
            this._mediaGatewayService?.stop();
            throw error;
        }
    }

    public async startWithService(camera: string, mediaGatewayService: IMediaGatewayService): Promise<void> {
        this.throwIfDisposed();
        if (this.isStarted) {
            throw new Error(`Player ${this._playerId} is already started`);
        }
        if (!(mediaGatewayService instanceof MediaGatewayService)) {
            throw new Error('The provided MediaGatewayService is not of the expected type');
        }

        const webPlayer = new WebPlayer(this._playerId, this._htmlElements, new Camera(camera), mediaGatewayService, this.enableH264);
        try {
            const session = await webPlayer.SessionEstablishment;
            webPlayer.PtzThrottlingService = new PtzThrottlingService(this._logger, session.ptzControlSession);
        } catch (error) {
            await webPlayer.disposeAsync();
            throw error;
        }
        this.subscribeToPlayerEvent(webPlayer);
        this._webPlayer = webPlayer;
    }

    public async stop(): Promise<void> {
        if (this._webPlayer) {
            this.unsubscribeToPlayerEvent(this._webPlayer);
            await this._webPlayer.disposeAsync();
            this._webPlayer = undefined;
        }
        this._mediaGatewayService?.stop();
        this._mediaGatewayService = undefined;
        this.onPlayerStateChangedHandler(new PlayerStateChangeEvent(PlayerState.Stopped, ''));
    }

    public playLive(): void {
        // when going live, restore the play speed
        if (this._webPlayer) {
            if (this._webPlayer.playSpeed.Value !== 1) {
                this.setPlaySpeed(1);
            }
            this._webPlayer.playLive();
        }
    }

    public pause(maintainSpeed: boolean = false): void {
        if (!this._webPlayer) {
            return;
        }
        if (!maintainSpeed) {
            // when paused, restore the play speed
            if (this._webPlayer.playSpeed.Value !== 1) {
                this.setPlaySpeed(1);
            }
        }
        this._webPlayer.pause();
    }

    public resume(maintainSpeed: boolean = false): void {
        if (!this._webPlayer) {
            return;
        }
        if (!maintainSpeed) {
            // when resuming, restore the play speed
            if (this._webPlayer.playSpeed.Value !== 1) {
                this.setPlaySpeed(1);
            }
        }
        this._webPlayer.resume();
    }

    public seek(seekTime: Date): void {
        if (!(seekTime instanceof Date) || isNaN(seekTime.valueOf())) {
            throw new Error('Invalid Seek Time was requested');
        }
        this._webPlayer?.seek(seekTime);
    }

    // perform a safe seek operation by ensuring to skip it if we asked for one few seconds ago
    private safeSeek(time: Date) {
        const now = new Date();
        const ticks = now.getTime();
        const diff = ticks - this.lastLoopingSeek;

        if (diff > 2500) {
            this.lastLoopingSeek = ticks;
            this.seek(time);
        }
    }

    public clearLoop(): void {
        this.isLooping = false;
        this.loopEvent.trigger(new LoopChangeEvent(false, undefined, undefined));
    }

    public setLoop(start: Date, end: Date): void {
        this.loopStart = start;
        this.loopEnd = end;
        this.isLooping = true;

        // seek at the beginning of the loop
        this.seek(start);

        this.loopEvent.trigger(new LoopChangeEvent(true, this.loopStart, this.loopEnd));
    }

    public setPlaySpeed(playSpeed: number): void {
        this._webPlayer?.setPlaySpeed(new PlaySpeed(playSpeed));
    }

    public setAudioEnabled(isAudioEnabled: boolean): void {
        this._webPlayer?.setAudioEnabled(isAudioEnabled);
    }

    public setPtzMode(ptzMode: boolean): void {
        this._webPlayer?.setPtzMode(ptzMode);
    }

    public showDebugOverlay(show: boolean): void {
        this._webPlayer?.showDebugOverlay(show);
    }

    private throwIfDisposed() {
        if (this._isDisposed) {
            throw new Error('PublicWebPlayer is disposed');
        }
    }

    private throwIfDisposedOrNotStarted() {
        this.throwIfDisposed();
        if (this._webPlayer === undefined) {
            throw new Error('Player need to be started first.');
        }
    }

    private get webPlayer(): WebPlayer {
        this.throwIfDisposedOrNotStarted();

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._webPlayer!;
    }

    public async takeSnapshotAsync(): Promise<Blob> {
        const imageData = this.getSnapshot();
        if (imageData) {
            const canvas = document.createElement('canvas');
            canvas.width = imageData.width;
            canvas.height = imageData.height;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.putImageData(imageData, 0, 0);
            }
            return new Promise(function (resolve) {
                canvas.toBlob(function (blob) {
                    resolve(blob as Blob);
                });
            });
        }
        return Promise.reject();
    }

    public async getExportAsync(exportModel: ExportModel): Promise<Response> {
        const response = await this._transport.fetch('POST', 'api/v1/exports', JSON.stringify(exportModel));
        return response;
    }

    public async getThumbnailAsync(timestamp: Date, width = 192): Promise<string> {
        let base64 = '';
        const cameraId = this._cameraId.toString();
        const timeText = timestamp.toISOString();
        const url = `api/v1/cameras/${cameraId}/thumbnails/${timeText}/?width=${width}`;
        const response = await this._transport.fetch('GET', url, null);
        if (response && response.ok) {
            const blob = await response.blob();
            base64 = (await this.convertBlobToBase64(blob)) as string;
        }

        return base64;
    }

    private convertBlobToBase64 = (blob: Blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });

    private onErrorState = (errorStatus: ErrorStatusEvent): void => {
        if (errorStatus.isFatal) {
            this.stop();
        }
        // forward the event
        this.errorEvent.trigger(errorStatus);
    };

    private onFrameRenderedHandler = (frameTime: Date): void => {
        // if we are looping, ensure we are between the bounds
        if (this.isLooping && this.loopStart && this.loopEnd) {
            const isReverse = this._webPlayer?.playSpeed.IsReverse;
            if (isReverse) {
                if (frameTime < this.loopStart) {
                    this.safeSeek(this.loopEnd);
                }
            } else if (frameTime > this.loopEnd) {
                this.safeSeek(this.loopStart);
            }
        }
        // forward the event
        this.frameRenderedEvent.trigger(frameTime);
    };

    private onPlayerStateChangedHandler = (evt: PlayerStateChangeEvent): void => {
        this._lastPlayerState = evt.playerState;
        // forward the event
        this.playerStateEvent.trigger(evt);
    };

    private subscribeToPlayerEvent(webPlayer: WebPlayer): void {
        webPlayer.errorStateRaised.register(this.onErrorState);
        webPlayer.frameRendered.register(this.onFrameRenderedHandler);
        webPlayer.streamStatusChanged.register(this.streamStatusEvent.boundTrigger);
        webPlayer.playerStateChanged.register(this.onPlayerStateChangedHandler);
        webPlayer.playSpeedChanged.register(this.playSpeedEvent.boundTrigger);
        webPlayer.audioStateChanged.register(this.audioEvent.boundTrigger);
        webPlayer.audioAvailabilityChanged.register(this.audioAvailabilityEvent.boundTrigger);
        webPlayer.playerModeChanged.register(this.playerModeEvent.boundTrigger);
        webPlayer.bufferingProgress.register(this.bufferingProgress.boundTrigger);
        this._timelineProvider.register(webPlayer);
    }

    private unsubscribeToPlayerEvent(webPlayer: WebPlayer): void {
        webPlayer.errorStateRaised.unregister(this.onErrorState);
        webPlayer.frameRendered.unregister(this.onFrameRenderedHandler);
        webPlayer.streamStatusChanged.unregister(this.streamStatusEvent.boundTrigger);
        webPlayer.playerStateChanged.unregister(this.onPlayerStateChangedHandler);
        webPlayer.playSpeedChanged.unregister(this.playSpeedEvent.boundTrigger);
        webPlayer.audioStateChanged.unregister(this.audioEvent.boundTrigger);
        webPlayer.audioAvailabilityChanged.unregister(this.audioAvailabilityEvent.boundTrigger);
        webPlayer.playerModeChanged.unregister(this.playerModeEvent.boundTrigger);
        webPlayer.bufferingProgress.unregister(this.bufferingProgress.boundTrigger);
        this._timelineProvider.unregister(webPlayer);
    }

    public debugStatus(): string {
        const indent = 1;
        return (
            `PublicWebPlayer${Utils.indentNewLine(indent)}` +
            `PlayerId: ${this._playerId}${Utils.indentNewLine(indent)}` +
            (this._isDisposed ? `${Utils.indentNewLine(indent)}Disposed${Utils.indentNewLine(indent)}` : '') +
            (this._webPlayer?.debugStatus(indent + Utils.Indentation) ?? 'WebPlayer undefined')
        );
    }

    public async addTimelineProviders(providers: Array<IGuid>, add: boolean, entities: Array<IGuid>): Promise<Response> {
        const fo = new FieldObject();
        fo.setField('add', add);
        const providerIds = new ObservableCollection<IGuid>();
        providers.forEach((x) => {
            providerIds.add(x);
        });
        fo.setFieldArrayGuid('Providers', providerIds);
        const col = new ObservableCollection<IGuid>();
        entities.forEach((x) => {
            col.add(x);
        });
        fo.setFieldArrayGuid('Entities', col);
        const response = await this._transport.fetch('POST', 'api/v1/timeline', fo.toString());
        return response;
    }

    //#endregion
}
