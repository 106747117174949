import { Pipe, PipeTransform } from '@angular/core';

/*
  Adds the bold property (<b> ... </b>) to a substring of a text
  Ex : <..> [innerHTML]="'The next variable {0} will be bold' | boldString: '{0}'" </..>

  This pipe is mainly used with the translate pipe and the stringFormat pipe :
  Ex : 
    'STE_..._FORMAT_..._X' | translate | boldString: '{0}' | stringFormat: 'important'
    'This {0} value will soon be bold' | boldString: '{0}' | stringFormat: 'important'
    'This <b>{0}</b> value will soon be bold' | stringFormat: 'important'
    'This <b>important</b> value will soon be bold'

  Prefer using <b> ... </b> directly in other use cases.
*/
@Pipe({ name: 'boldString' })
export class BoldStringPipe implements PipeTransform {
    public transform(text: string, subText: string): string {
        return text.replace(new RegExp(`(\\${subText})`, 'gi'), '<b>$1</b>');
    }
}
