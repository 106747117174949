<ng-container *ngIf="isPortalEnabled">
    <gen-button
        class="expand-widget-button"
        [flavor]="ButtonFlavor.Flat"
        *ngLet="isExpanded$ | async as isExpanded"
        appTooltip="{{ (isExpanded ? 'STE_BUTTON_MINIMIZE_VIDEO' : 'STE_BUTTON_MAXIMIZE_VIDEO') | translate }}"
        (click)="toggleExpandWidget()"
    >
        <gen-icon [icon]="isExpanded ? Icon.ExitFullscreen : Icon.Fullscreen"></gen-icon>
    </gen-button>
</ng-container>
