<ng-container *ngIf="filters.length > 0">
    <div class="filter-container ml-1">
        <div class="btn-more-filters h-100 pointer focus-style-override" data-cy="hideableFilters" (click)="togglePopup()" appClickActionOnSpaceEnter tabindex="0">
            <gen-icon icon="filter" class="fg-color-accent"></gen-icon>
            <span class="fg-color-accent text-bold m-auto">{{ 'STE_LABEL_SELECT_FILTERS' | translate }}</span>
        </div>
    </div>
    <gen-popup
        #popup
        [position]="PopupPosition.BottomLeft"
        targetSelector=".btn-more-filters"
        appFocusPopupWhenOpen
        genKeyboardNavigation
        (genEscape)="cancel()"
        (didClose)="cancel()"
        data-cy="hideableFiltersPopup"
    >
        <div class="popup-content">
            <gen-list [selectionType]="ListSelectionType.Multiple">
                <gen-list-item *ngFor="let filter of filters" [selected]="filter.isChecked" (selectedChange)="onSelectionChange(filter, $event.detail)">
                    <gen-text>{{ filter.text }}</gen-text>
                </gen-list-item>
            </gen-list>
            <div class="popup-footer">
                <gen-button (click)="cancel()" [destructive]="true" [flavor]="Flavor.Button.Flat" data-cy="cancelFilters">{{ 'STE_BUTTON_CANCEL' | translate }}</gen-button>
                <gen-separator [orientation]="Orientation.Vertical" class="no-margin"></gen-separator>
                <gen-button [disabled]="!isDirty" (click)="apply()" [flavor]="Flavor.Button.Flat" data-cy="applyFilters">{{ 'STE_BUTTON_APPLY' | translate }}</gen-button>
            </div>
        </div>
    </gen-popup>
</ng-container>
