export class TimeSpan {
    public readonly days: number;
    public readonly hours: number;
    public readonly minutes: number;
    public readonly seconds: number;
    public readonly milliseconds: number;
    public readonly totalMilliseconds: number;

    private readonly SECONDS_IN_MILLISECONDS = 1000;
    private readonly MINUTES_IN_MILLISECONDS = 60 * this.SECONDS_IN_MILLISECONDS;
    private readonly HOUR_IN_MILLISECONDS = 60 * this.MINUTES_IN_MILLISECONDS;
    private readonly DAYS_IN_MILLISECONDS = 24 * this.HOUR_IN_MILLISECONDS;

    constructor(milliseconds: number) {
        this.totalMilliseconds = milliseconds;
        this.days = Math.floor(milliseconds / this.DAYS_IN_MILLISECONDS);
        milliseconds %= this.DAYS_IN_MILLISECONDS;
        this.hours = Math.floor(milliseconds / this.HOUR_IN_MILLISECONDS);
        milliseconds %= this.HOUR_IN_MILLISECONDS;
        this.minutes = Math.floor(milliseconds / this.MINUTES_IN_MILLISECONDS);
        milliseconds %= this.MINUTES_IN_MILLISECONDS;
        this.seconds = Math.floor(milliseconds / this.SECONDS_IN_MILLISECONDS);
        this.milliseconds = milliseconds % this.SECONDS_IN_MILLISECONDS;
    }

    public toString(): string {
        let value = '';
        if (this.days > 0) value += this.days >= 10 ? `${this.days}:` : `0${this.days}:`;
        if (this.hours > 0) value += this.hours >= 10 ? `${this.hours}:` : `0${this.hours}:`;
        value += this.minutes === 0 || this.minutes >= 10 ? `${this.minutes}:` : `0${this.minutes}:`;
        value += this.seconds >= 10 ? `${this.seconds}` : `0${this.seconds}`;

        return value;
    }
}
