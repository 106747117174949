<app-entity-state-overlay
    *ngIf="door$ | async as door"
    [draggable]="true"
    [content]="content"
    [commandsUsage]="commandsUsage"
    [customIconId]="door.customIconId"
    [customIconState]="doorCustomIconState$ | async"
    [entityIcon]="doorIcon$ | async"
>
    <span
        entityTitle
        class="entity-name"
        [ngClass]="{
            'fg-color-danger': door.runningState === RunningState.NotRunning,
            'fg-color-warning': door.runningState === RunningState.Warning,
            'fg-color-maintenance': door.maintenanceModeActive
        }"
    >
        {{ content?.title }}
    </span>
    <span primaryState class="hyphen-after"> {{ (door.doorOpened ? 'STE_LABEL_DOOROPENED' : 'STE_LABEL_DOORCLOSED') | translate }} </span>
    <span secondaryState *ngIf="!door.maintenanceModeActive" [ngClass]="door.isLocked ? 'fg-color-danger' : 'fg-color-success'">
        {{ (door.isLocked ? 'STE_LABEL_DOOR_LOCKED' : 'STE_LABEL_DOOR_UNLOCKED') | translate }}
    </span>
    <span secondaryState *ngIf="door.maintenanceModeActive" [ngClass]="'fg-color-warning'">
        {{ 'STE_LABEL_DOOR_MAINTENANCE_MODE' | translate }}
    </span>
</app-entity-state-overlay>
