/* eslint-disable id-blacklist */
export enum TableCellType {
    Custom = 0,
    Text = 1,
    Image = 2,
    Boolean = 3,
    Number = 4,
    Date = 5,
    Time = 6,
    DateTime = 7,
    Action = 8,
    Timezone = 9,
}
