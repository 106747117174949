// Autogenerated: v2/help/Classes/ApiEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { IEventBase } from '../Interface/IEventBase';
import { ICoordinate } from '../Interface/IMapEntity';
import { Coordinate } from '../Model/MapEntity';

export class EventBase extends FieldObject implements IEventBase {
    // #region Fields

    private _convertedJObject: string|null = null;

    // #endregion

    // #region REST Properties

    // ContextId
    public get contextId(): IGuid {
        return this.getFieldGuid('ContextId');
    }
    public set contextId(value: IGuid) {
        this.setFieldGuid('ContextId', value);
    }

    // Coordinate
    public get coordinate(): ICoordinate {
        return this.getFieldObject<Coordinate, ICoordinate>(Coordinate, 'Coordinate');
    }
    public set coordinate(value: ICoordinate) {
        this.setFieldObject<ICoordinate>('Coordinate', value);
    }

    // EventSubType
    public get eventSubType(): number {
        return this.getField<number>('EventSubType');
    }
    public set eventSubType(value: number) {
        this.setField<number>('EventSubType', value);
    }

    // EventType
    public get eventType(): string {
        return this.getField<string>('EventType');
    }
    public set eventType(value: string) {
        this.setField<string>('EventType', value);
    }

    // SourceEntity
    public get sourceEntity(): IGuid {
        return this.getFieldGuid('SourceEntity');
    }
    public set sourceEntity(value: IGuid) {
        this.setFieldGuid('SourceEntity', value);
    }

    // SourceEntityDesc
    public get sourceEntityDesc(): string {
        return this.getField<string>('SourceEntityDesc');
    }
    public set sourceEntityDesc(value: string) {
        this.setField<string>('SourceEntityDesc', value);
    }

    // SourceEntityType
    public get sourceEntityType(): string {
        return this.getField<string>('SourceEntityType');
    }
    public set sourceEntityType(value: string) {
        this.setField<string>('SourceEntityType', value);
    }

    // Timestamp
    public get timestamp(): Date {
        return this.getFieldDate('Timestamp');
    }
    public set timestamp(value: Date) {
        this.setFieldDate('Timestamp', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    // Some field are optional ( if you ask for expansion or not )
    public getSafeField<T>(fieldId: string): T|null {
        try {
            return super.getField<T>(fieldId);
        } catch (e) {
            return null;
        }
    }

    public initializeAllFields(): void {
        this.setFieldGuid('ContextId', SafeGuid.EMPTY);
        const objCoordinate = new Coordinate();
        objCoordinate.initializeAllFields();
        this.setFieldObject('Coordinate', objCoordinate);
        this.setField<number>('EventSubType', 0);
        this.setField<string>('EventType', '');
        this.setFieldGuid('SourceEntity', SafeGuid.EMPTY);
        this.setField<string>('SourceEntityDesc', '');
        this.setField<string>('SourceEntityType', '');
        this.setFieldDate('Timestamp', new Date());
    }

    // #endregion Methods

}

