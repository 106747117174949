import { Degree } from './Degree';

export class Radian {
  private readonly m_angle: number;

  public get InRadian(): number {
    return this.m_angle;
  }

  constructor(angle: number) {
    this.m_angle = angle;
  }

  public toString(): string {
    return `${this.m_angle}R (${this.toDegree().toString()})`;
  }

  public toDegree(): Degree {
    return new Degree(this.m_angle * 180 / Math.PI);
  }

  public add(radian: Radian): Radian {
    return new Radian(this.m_angle + radian.m_angle);
  }

  public subtract(radian: Radian): Radian {
    return new Radian(this.m_angle - radian.m_angle);
  }

  public opposite(): Radian {
    return new Radian(this.m_angle * -1);
  }

  public cos(): number {
    return Math.cos(this.m_angle);
  }

  public sin(): number {
    return Math.sin(this.m_angle);
  }
}
