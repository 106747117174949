import { IGuid, SafeGuid } from 'safeguid';
import { WebMapObject } from '../controllers/map.controller.data';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export interface Point {
    X: number;
    Y: number;
}

export enum Stretch {
    None = 0,
    Fill = 1,
    Uniform = 2,
}

export class ImageWebMapObject extends WebMapObject {
    public get width(): number {
        return this.getField<number>('width');
    }

    public get height(): number {
        return this.getField<number>('height');
    }

    public get stretch(): Stretch {
        return this.getField<Stretch>('stretch');
    }

    public get entity(): IGuid {
        return this.getFieldGuid('entity');
    }
}

export class ShapeWebMapObject extends WebMapObject {
    public get borderColor(): string {
        return this.getField<string>('borderColor');
    }

    public get borderThickness(): number {
        return this.getField<number>('borderThickness');
    }

    public get opacity(): number {
        return this.getField<number>('opacity');
    }

    public get points(): Set<Point> {
        return this.getField<Set<Point>>('points');
    }

    public get blockFieldOfView(): boolean {
        return this.getField<boolean>('blockFieldOfView');
    }

    public get blockFieldOfViewUsingAltitude(): boolean {
        return this.getField<boolean>('blockFieldOfViewUsingAltitude');
    }

    public get isPolyline(): boolean | undefined {
        return this.getField<boolean | undefined>('isPolyline');
    }
}

export class TextWebMapObject extends WebMapObject {
    public get width(): number {
        return this.getField<number>('width');
    }

    public get height(): number {
        return this.getField<number>('height');
    }

    public get borderColor(): string {
        return this.getField<string>('borderColor');
    }

    public get borderThickness(): number {
        return this.getField<number>('borderThickness');
    }

    public get opacity(): number {
        return this.getField<number>('opacity');
    }

    public get fontFamily(): string {
        return this.getField<string>('fontFamily');
    }

    public get fontIsBold(): boolean {
        return this.getField<boolean>('fontIsBold');
    }

    public get fontIsItalic(): boolean {
        return this.getField<boolean>('fontIsItalic');
    }

    public get fontIsUnderline(): boolean {
        return this.getField<boolean>('fontIsUnderline');
    }

    public get fontSize(): number {
        return this.getField<number>('fontSize');
    }

    public get foreground(): string {
        return this.getField<string>('foreground');
    }

    public get text(): string {
        return this.getField<string>('text');
    }

    public get textAlignment(): string {
        return this.getField<string>('textAlignment');
    }

    public get isShadowDisplayed(): boolean {
        return this.getField<boolean>('isShadowDisplayed');
    }
}
