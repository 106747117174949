import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarFlavor, CalendarTimeFrame } from '@genetec/gelato-angular';
import { PopupPosition } from '@genetec/gelato';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { List } from 'immutable';
import moment from 'moment';
import { unitOfTime } from 'moment';
import { FilterCoordinatorService } from '@modules/shared/services/filters/filter-coordinator-service';
import { FilterDateModel } from '@modules/shared/components/filters/common-filters/filter-date/filter-date.model';
import { EntityFilterModel } from '@modules/shared/components/filters/common-filters/filter-entity/filter-entity.model';
import { IncidentType } from '../../models/incident-type';
import { Priority } from '../../models/priority';
import { State } from '../../models/state';
import { IncidentTypeService } from '../../services/incident-type.service';
import { PriorityService } from '../../services/priority/priority.service';
import { StateService } from '../../services/state/state.service';
import { INCIDENT_LOCATION } from '../../entity-location';
import { IncidentFilter } from '../../models/incident-filter';
import { toGuid } from '../../utils/guid-utils';
import { ListItem } from '@modules/shared/interfaces/list-item';
import { SharedEntityBrowserRefreshTypes } from '@modules/shared/entity-browser/enumerations/shared-entity-browser-refresher-types';

@Component({
    selector: 'app-incident-filter',
    templateUrl: './incident-filter.component.html',
})
export class IncidentFilterComponent extends TrackedComponent implements OnInit {
    @Input() public incidentFilter = IncidentFilter.empty;
    @Output() public incidentFilterChange = new EventEmitter<IncidentFilter>();

    public priorityFilterItem$!: Observable<ListItem[]>;
    public stateFilterItem$!: Observable<ListItem[]>;
    public typeAvailableFilterItem$!: Observable<ListItem[]>;
    public typeFilteredFilterItem$!: Observable<ListItem[]>;

    public readonly PopupPosition = PopupPosition;

    // MC API doesn't work with location ID that are areas, so display flat Entity Browser until it is supported
    public readonly locationEntityTypes = INCIDENT_LOCATION;

    public readonly SharedEntityBrowserRefreshTypes = SharedEntityBrowserRefreshTypes;

    constructor(
        trackingService: TrackingService,
        private _priorityService: PriorityService,
        private _stateService: StateService,
        private _incidentTypeService: IncidentTypeService,
        private _filterCoordinatorService: FilterCoordinatorService
    ) {
        super(trackingService);
    }

    public get CalendarTimeFrame(): typeof CalendarTimeFrame {
        return CalendarTimeFrame;
    }

    public get CalendarFlavor(): typeof CalendarFlavor {
        return CalendarFlavor;
    }

    ngOnInit() {
        const convertToFilterItem = (i: State | Priority | IncidentType) => ({ id: i.id.toString(), text: i.name, isChecked: false });

        this.priorityFilterItem$ = this._priorityService.getPriorities$().pipe(map((priorities) => priorities.map(convertToFilterItem)));

        this.stateFilterItem$ = this._stateService.getActiveStates$().pipe(map((states) => states.map(convertToFilterItem)));

        this.typeAvailableFilterItem$ = this._incidentTypeService.getIncidentTypes().pipe(map((types) => types.map(convertToFilterItem)));
        this.typeFilteredFilterItem$ = this.typeAvailableFilterItem$;
    }

    public onSearchTypeChange(search: string): void {
        if (!search || search.trim().length === 0) {
            this.typeFilteredFilterItem$ = this.typeAvailableFilterItem$;
            return;
        }

        this.typeFilteredFilterItem$ = this.typeAvailableFilterItem$.pipe(map((types) => types.filter((type) => type.text.toLowerCase().includes(search.toLowerCase()))));
    }

    public typeFilterChange(types: ListItem[] | null): void {
        this.applyCheckedListItemFilters('incidentTypeIds', types);
    }

    public priorityFilterChange(priorities: ListItem[] | null): void {
        this.applyCheckedListItemFilters('priorityIds', priorities);
    }

    public stateFilterChange(states: ListItem[] | null): void {
        this.applyCheckedListItemFilters('stateIds', states);
    }

    public onDateChange(dateFilter: FilterDateModel | null): void {
        let start: string | undefined;
        let end: string | undefined;

        if (dateFilter) {
            if (dateFilter.isAbsolute) {
                start = dateFilter.timeStart;
                end = dateFilter.timeEnd;
            } else if (dateFilter.quanta) {
                if (dateFilter.quanta > 0) {
                    start = moment.utc().toISOString();
                    end = moment
                        .utc()
                        .add(dateFilter.quanta, dateFilter.timeUnit as unitOfTime.DurationConstructor | undefined)
                        .toISOString();
                } else {
                    start = moment
                        .utc()
                        .subtract(-dateFilter.quanta, dateFilter.timeUnit as unitOfTime.DurationConstructor | undefined)
                        .toISOString();
                    end = undefined;
                }
            }
        }
        this.incidentFilter = this.incidentFilter.with({ dateTimeFrom: start ?? null, dateTimeTo: end ?? null });
        this.emitFilter();
    }

    public onEntityChange(entityFilter: EntityFilterModel | null): void {
        this.applyIdListFilter('locationIds', entityFilter?.includedEntities);
    }

    public onOwnerChange(owners: IGuid[] | null): void {
        this.applyIdListFilter('ownerIds', owners);
    }

    public clearFilters(): void {
        this._filterCoordinatorService.resetAllFiltersToDefault();
    }

    public areFiltersCleared(): boolean {
        return this._filterCoordinatorService.allDefaulted;
    }

    public emitFilter = (): void => this.incidentFilterChange.emit(this.incidentFilter);

    public onReloadPriorities = (): void => this._priorityService.refresh();

    public onReloadStates = (): void => this._stateService.refresh();

    public onReloadTypes = (): void => this._incidentTypeService.refresh();

    private applyCheckedListItemFilters(filterKey: keyof Parameters<IncidentFilter['with']>[0], filterItems: ListItem[] | null | undefined) {
        this.applyIdListFilter(filterKey, filterItems ? filterItems.filter((item) => item.isChecked).map((item) => toGuid(item.id)) : []);
    }

    private applyIdListFilter(filterKey: keyof Parameters<IncidentFilter['with']>[0], guids: IGuid[] | null | undefined) {
        this.incidentFilter = this.incidentFilter.with({ [filterKey]: List(guids ?? []) });
        this.emitFilter();
    }
}
