import { Injectable } from '@angular/core';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { LoggerService } from './logger/logger.service';

export interface ActionDescription {
    name: string;
    originComponent?: string;
}

@Injectable({ providedIn: 'root' })
export class TrackingService {
    private scClient: ISecurityCenterClient;
    private activeComponents = new Map<string, number>();

    constructor(securityCenterProvider: SecurityCenterClientService, private loggerService: LoggerService) {
        this.scClient = securityCenterProvider?.scClient;
    }

    public addActiveComponent(name: string): void {
        const activeCount = this.activeComponents.get(name) ?? 0;
        this.activeComponents.set(name, activeCount + 1);
    }

    public removeActiveComponent(name: string): void {
        const activeCount = this.activeComponents.get(name);
        if (activeCount) {
            this.activeComponents.set(name, activeCount - 1);

            if (activeCount === 1) {
                this.activeComponents.delete(name);
            }
        }
    }

    public async logActionAsync(actionDescription: ActionDescription): Promise<void> {
        // TODO: log the action
    }

    public outputStats(): void {
        this.loggerService.traceInformation('Active components');
        for (const component of this.activeComponents) {
            this.loggerService.traceInformation(`${component[0]}: ${component[1]}`);
        }
    }
}
