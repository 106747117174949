import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GenModalService, MeltedModalAction } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { EntityBrowserCheckSelection, EntityBrowserSelection } from '@modules/shared/entity-browser/entity-browser-selection';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { IGuid, SafeGuid } from 'safeguid';
import { IEntityTypeData } from '@modules/shared/models/entity-type-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { EntityBrowserComponent } from '../entity-browser/entity-browser.component';

export class SelectedEntity {
    constructor(public id: IGuid, public name: string, public entityType: IEntityTypeData, public state: string, public maintenance: boolean, public customIconId: IGuid) {}
}
@Component({
    selector: 'app-modal-entities-selector',
    templateUrl: './modal-entities-selector.component.html',
    styleUrls: ['./modal-entities-selector.component.css'],
})
export class ModalEntitiesSelectorComponent {
    @Input() title = '';
    @Input() filter?: EntityBrowserFilter;
    @Input() set allowMultiSelect(allowMultiSelect: boolean) {
        this.allowMultiSelectSubject$.next(allowMultiSelect);
    }
    @Input() public areItemsCheckable = false;
    // Display the footer or not (refresh / check / uncheckall / etc.)
    @Input() public showFooter = true;
    @Input() public defaultActionText = this.translateService.instant('STE_BUTTON_SELECT') as string;
    @Output() entitiesSelected = new EventEmitter<SelectedEntity[]>();
    @Output() entityIdsSelected = new EventEmitter<IGuid[]>();
    @ViewChild('entityBrowser') entityBrowser!: EntityBrowserComponent;
    public defaultTitle = '';
    public id = SafeGuid.newGuid().toString();
    public allowMultiSelect$: Observable<boolean>;
    public defaultAllowMultiSelect = true;

    private selectedEntities: SelectedEntity[] = [];
    private allowMultiSelectSubject$ = new BehaviorSubject<boolean>(this.defaultAllowMultiSelect);

    constructor(private modalService: GenModalService, private translateService: TranslateService) {
        this.allowMultiSelect$ = this.allowMultiSelectSubject$.pipe(
            distinctUntilChanged(),
            tap((allowMultiSelect) => this.updateDefaultTitle(allowMultiSelect))
        );
    }

    public onAction(action: MeltedModalAction): void {
        switch (action) {
            case MeltedModalAction.Default:
                if (this.selectedEntities.length > 0) {
                    this.entitiesSelected.emit(this.selectedEntities);
                    this.entityIdsSelected.emit(this.selectedEntities.map((entity) => entity.id));
                }
                break;
            default:
                break;
        }
    }

    public onEntityDoubleClicked(selection: EntityBrowserSelection): void {
        this.onSelectedEntitiesChanged(selection);
        this.onAction(MeltedModalAction.Default);
        this.modalService.hide(this.id);
    }

    public onSelectedEntitiesChanged(selection: EntityBrowserSelection | EntityBrowserCheckSelection): void {
        if (selection instanceof EntityBrowserSelection) {
            this.selectedEntities = selection.allItems.map(
                (item) => new SelectedEntity(item.model.id, item.model.name, item.model.entityType, item.model.runningState, item.model.maintenance, item.model.customIconId)
            );
        } else if (selection instanceof EntityBrowserCheckSelection) {
            this.selectedEntities = selection.items.map((item) => new SelectedEntity(item.id, item.name, item.entityType, item.runningState, item.maintenance, item.customIconId));
        }
    }

    public show(): void {
        this.modalService.show(this.id);
        this.entityBrowser.refreshAsync(this.filter, false).fireAndForget();
    }

    private updateDefaultTitle(allowMultiSelect: boolean): void {
        this.defaultTitle = this.translateService.instant(allowMultiSelect ? 'STE_TOOLTIP_SELECTENTITIES' : 'STE_TOOLTIP_SELECTENTITY') as string;
    }
}
