import { AbstractControl, ValidationErrors } from '@angular/forms';

const appendRegexMatches = (regex: RegExp, str: string) => {
    let append = '';
    for (let match = regex.exec(str); match; match = regex.exec(str)) {
        append += match;
    }
    return append;
};

// Those regexes were provided by MissionControl team from Security Center
const incidentCommentFirstCharRegex = new RegExp(/^[;=\+@-]/);
const incidentCommentRegex = new RegExp(/[^\p{L}\p{N}\p{Sc}!@&=+$#%?*:;_,.~""'`°^<>«»(){}[\]|\/\\\r\n -]/gu);

export const incidentCommentValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null; //Empty comment is valid
    const comment = control.value;
    let error = { invalidFirstChar: '', invalidChars: '' };
    // Match all invalid chars in the whole string
    error.invalidChars = appendRegexMatches(incidentCommentRegex, comment);
    // Match invalid chars at the beginning of the string
    error.invalidFirstChar += incidentCommentFirstCharRegex.exec(comment) ?? '';

    if (error.invalidChars || error.invalidFirstChar) {
        return error;
    }

    return null;
};
