import { Observable, timer } from 'rxjs';
import { finalize, mergeMap, filter } from 'rxjs/operators';
import { isRetryableError } from '../models/errors/mc-client-error';

export const retryWithIncreasingLinearTime =
    (operationName: string, scalingDurationFactor = 1_000, maxDuration = 180_000) =>
    (attempts: Observable<any>): Observable<number> => {
        return attempts.pipe(
            filter((err) => isRetryableError(err)),
            mergeMap((_, i) => {
                const retryAttempt = i++;
                const duration = retryAttempt * scalingDurationFactor;
                const time = duration > maxDuration ? maxDuration : duration;

                console.log(`${operationName}: attempt ${retryAttempt}; retrying in ${time}ms`);
                return timer(time);
            }),
            finalize(() => console.log(`${operationName}: done retrying`))
        );
    };

export const retryWithConstantTime =
    (operationName: string, duration = 15_000) =>
    (attempts: Observable<any>): Observable<number> => {
        return attempts.pipe(
            filter((err) => isRetryableError(err)),
            mergeMap((_, i) => {
                const retryAttempt = i++;
                console.log(`${operationName}: attempt ${retryAttempt}; retrying in ${duration}ms`);
                return timer(duration);
            }),
            finalize(() => console.log(`${operationName}: done retrying`))
        );
    };
