/* eslint-disable */
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { Content, ContentGroup } from '../interfaces/plugins/public/plugin-public.interface';

export class ContentSerializer {
    public static deserializeContentGroup(json: string): ContentGroup | null {
        if (json) {
            const obj = JSON.parse(json);
            const mainContent = this.deserializeContent(obj.mainContent);
            const result: ContentGroup = { id: obj.id, mainContent: mainContent as Content, subContents: [] };

            return result;
        }
        return null;
    }

    public static deserializeContent(json: string): Content | null {
        if (json) {
            const obj = JSON.parse(json);

            const parameters = new FieldObject();
            parameters.fromJson(obj.parameters);
            const grantedPrivileges: IGuid[] = [];
            (obj.grantedPrivileges as string[]).forEach((x) => grantedPrivileges.push(SafeGuid.parse(x)));

            const result: Content = {
                id: SafeGuid.parse(obj.id),
                type: SafeGuid.parse(obj.type),
                title: obj.title,
                description: obj.description,
                icon: obj.icon,
                customIconId: obj.customIconId,
                source: obj.source,
                parameters,
                contextContent: null,
                contextsubContents: null,
                grantedPrivileges,
                color: obj.color,
            };

            return result;
        }
        return null;
    }

    public static serializeContentGroup(contentGroup: ContentGroup): string {
        if (contentGroup) {
            const mainContent = ContentSerializer.serializeContent(contentGroup.mainContent);
            const obj: { id: string; mainContent: string } = { id: contentGroup.id.toString(), mainContent };
            const data = JSON.stringify(obj);
            return data;
        }
        return '';
    }

    public static serializeContent(content: Content): string {
        if (content) {
            let parameters = '';
            if (content.parameters) {
                parameters = content.parameters.toString();
            }

            const grantedPrivileges: string[] = [];
            if (content.grantedPrivileges) {
                content.grantedPrivileges.forEach((x) => grantedPrivileges.push(x.toString()));
            }

            const obj: { id: string; type: string; title: string; description: string; icon: string; customIconId?: string, source: string; parameters: string; grantedPrivileges: string[] } = {
                id: content.id.toString(),
                type: content.type.toString(),
                title: content.title.toString(),
                description: content.description.toString(),
                icon: content.icon.toString(),
                customIconId: content.customIconId?.toString(),
                source: content.source.toString(),
                parameters,
                grantedPrivileges,
            };
            const data = JSON.stringify(obj);
            return data;
        }
        return '';
    }
}
