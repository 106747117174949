import { IGuid } from 'safeguid';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { EntityTypeData, IEntityTypeData } from '../models/entity-type-data';
import { IEntityTypeSet } from './interfaces/entity-type-set.interface';

export class EntityTypeSet implements IEntityTypeSet {
    public get [Symbol.toStringTag](): string {
        return this.entityTypes.values.toString();
    }

    public get size(): number {
        return this.entityTypes.size;
    }

    private entityTypes = new Map<string, EntityTypeData>();

    constructor(entityTypes?: EntityTypeData[] | Iterable<IEntityTypeData> | string[] | Iterable<string>) {
        this[Symbol.iterator] = this.values.bind(this);
        if (entityTypes) {
            this.addRange(entityTypes);
        }
    }

    public [Symbol.iterator](): IterableIterator<EntityTypeData> {
        return this.values();
    }

    public addRange(entityTypes: EntityTypeData[] | Iterable<IEntityTypeData> | string[] | Iterable<string>): void {
        if (entityTypes) {
            for (const entityType of entityTypes) {
                this.add(entityType as EntityTypeData);
            }
        }
    }

    public add(entityType: EntityTypeData | string, entitySubType?: string | IGuid): this {
        const entityTypeData = EntityTypeData.getEntityTypeData(entityType, entitySubType);
        if (!entityTypeData.hasSubType()) {
            const subTypes = EntityTypes.getSubTypes(entityTypeData.type);
            if (subTypes.length > 0) {
                for (const subType of subTypes) {
                    const subTypeData = new EntityTypeData(entityTypeData.type, subType);
                    this.entityTypes.set(subTypeData.idString, subTypeData);
                }
            } else {
                this.entityTypes.set(entityTypeData.idString, entityTypeData);
            }
        } else {
            this.entityTypes.set(entityTypeData.idString, entityTypeData);
        }
        return this;
    }

    public clear(): void {
        this.entityTypes.clear();
    }

    public delete(entityType: EntityTypeData | string, entitySubType?: string | IGuid): boolean {
        const entityTypeData = EntityTypeData.getEntityTypeData(entityType, entitySubType);
        let result = false;
        if (!entityTypeData.hasSubType()) {
            const subTypes = EntityTypes.getSubTypes(entityTypeData.type);
            if (subTypes.length > 0) {
                for (const subType of subTypes) {
                    const subTypeData = new EntityTypeData(entityTypeData.type, subType);
                    result = result || this.entityTypes.delete(subTypeData.idString);
                }
            } else {
                result = this.entityTypes.delete(entityTypeData.idString);
            }
        } else {
            result = this.entityTypes.delete(entityTypeData.idString);
        }

        return result;
    }

    public entries(): IterableIterator<[EntityTypeData, EntityTypeData]> {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public forEach(callbackfn: (value: EntityTypeData, value2: EntityTypeData, set: Set<EntityTypeData>) => void, thisArg?: any): void {
        if (thisArg) {
            callbackfn.bind(thisArg);
        }

        for (const entityType of this.values()) {
            callbackfn(entityType, entityType, this);
        }
    }

    public has(entityType: EntityTypeData | string, entitySubType?: string | IGuid): boolean {
        const entityTypeData = EntityTypeData.getEntityTypeData(entityType, entitySubType);

        return this.entityTypes.has(entityTypeData.idString);
    }

    public keys(): IterableIterator<EntityTypeData> {
        return this.values();
    }

    public tryAdd(entityType: EntityTypeData | string, entitySubType?: string | IGuid): boolean {
        const entityTypeData = EntityTypeData.getEntityTypeData(entityType, entitySubType);

        if (this.has(entityTypeData)) {
            return false;
        }

        this.add(entityTypeData);
        return true;
    }

    public values(): IterableIterator<EntityTypeData> {
        return this.entityTypes.values();
    }
}
