import { IVideoFrame } from '../../players/VideoFrameSource';
import { TopLeftPixelCoords } from '../../utils/TopLeftPixelCoords';
import { ILiteEvent, LiteEvent } from '../../utils/liteEvents';
import { ILogger } from '../../utils/logger';
import { Resolution } from '../../utils/Resolution';
import { ClipSpaceVec2 } from '../../utils/Vec2';
import { CanvasWrapper } from '../CanvasWrapper';
import { Utils } from '../../utils/Utils';
import { Cancellation } from '../../utils/CancellationToken';
import { MouseWheelObserver } from '../../utils/MouseWheelObserver';
import { VideoWatermarkingRenderer } from '../../utils/VideoWatermarkingRenderer';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';

export interface IPreviewCanvas {
  readonly Clicked: ILiteEvent<ClipSpaceVec2>;
  readonly Resized: ILiteEvent<Resolution>;
  readonly MouseWheel: Promise<number | Cancellation>;

  readonly Canvas: HTMLCanvasElement;
  Show: boolean;

  dispose(): void;
  render(imageSource: IVideoFrame): void;
  updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void;

  debugStatus(indent: number): string
}

export class PreviewCanvas extends CanvasWrapper implements IPreviewCanvas {
  private readonly m_context: CanvasRenderingContext2D;
  private readonly m_divElement: HTMLDivElement;
  private readonly m_mouseWheelObserver: MouseWheelObserver;

  private readonly m_onClick = new LiteEvent<ClipSpaceVec2>();

  //The warped image scaled resolution to proper aspect ratio
  private m_displayedResolution: Resolution = Resolution.None;

  private m_watermarkRenderer: VideoWatermarkingRenderer | null = null;

  private m_videoWatermarkConfig = VideoWatermarkConfig.Disabled;

  public get Clicked(): ILiteEvent<ClipSpaceVec2> {
    return this.m_onClick.expose();
  }

  public get MouseWheel(): Promise<number | Cancellation> {
    return this.m_mouseWheelObserver.MouseWheel;
  }

  constructor(logger: ILogger, previewHtmlDiv: HTMLDivElement, previewCanvas: HTMLCanvasElement) {
    super(logger, 'PreviewCanvas', previewCanvas);
    this.m_context = this.getCanvasRenderingContext2D();
    this.m_divElement = previewHtmlDiv;

    this.m_divElement.addEventListener('pointerdown', this.onPointerDown, false);
    this.m_divElement.addEventListener('pointerup', this.onPointerUp, false);
    this.m_divElement.addEventListener('pointermove', this.onPointerMove, false);
    this.m_mouseWheelObserver = new MouseWheelObserver(this.m_divElement);

    this.Canvas.width = 640;
    this.Canvas.height = 480;
  }

  public dispose() {
    super.dispose();

    this.m_divElement.removeEventListener('pointerdown', this.onPointerDown);
    this.m_divElement.addEventListener('pointerup', this.onPointerUp, false);
    this.m_divElement.removeEventListener('pointermove', this.onPointerMove);
    this.m_mouseWheelObserver.dispose();
  }

  public render(imageSource: IVideoFrame): void {
    //Do not try to render in an empty space
    if (this.isCanvasEmpty()) {
      return;
    }

    const displayedResolution = imageSource.Resolution.fitInto(this.Resolution);

    if (this.m_videoWatermarkConfig !== VideoWatermarkConfig.Disabled &&
      (!displayedResolution.equals(this.m_displayedResolution) || this.m_watermarkRenderer === null)) {
      this.Logger.debug?.trace(`Updating dewarper videowatermark config: ${this.m_videoWatermarkConfig} in ${displayedResolution.toString()}`);
      this.m_watermarkRenderer = new VideoWatermarkingRenderer(this.m_videoWatermarkConfig,
        displayedResolution.Width,
        displayedResolution.Height);
    }

    this.m_displayedResolution = displayedResolution;

    this.Logger.intense?.trace(`Preview from ${(imageSource instanceof HTMLVideoElement ? 'Video' : 'Jpeg')} at ${imageSource.Resolution.toString()} rendered in ${this.Canvas.width}x${this.Canvas.height}, scalling to ${this.m_displayedResolution.toString()}`);

    this.m_context.drawImage(imageSource.Image, 0, 0, this.m_displayedResolution.Width, this.m_displayedResolution.Height);
    this.m_watermarkRenderer?.drawVideoWatermarkingOverlay(this.m_context, 0, 0);
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void {
    this.Logger.debug?.trace(`Updating dewarper videowatermark config: ${videoWatermarkingConfig.toString()}`);
    this.m_videoWatermarkConfig = videoWatermarkingConfig;
    if (this.m_videoWatermarkConfig === VideoWatermarkConfig.Disabled) {
      this.m_watermarkRenderer = null;
    }
  }

  private readonly onPointerDown = (pointerEvent: PointerEvent): void => {
    //Do not try to render in an empty space
    if (this.isCanvasEmpty()) {
      return;
    }
    //We can't compute the clipSpaceCoordinates if no image was ever rendered
    if (this.m_displayedResolution.IsNone) {
      return;
    }

    const clickCoords = new TopLeftPixelCoords(pointerEvent.offsetX, pointerEvent.offsetY, this.m_displayedResolution);
    const clipSpaceCoords = clickCoords.toClipSpaceVec2();
    if (clipSpaceCoords.isInClipSpace()) {
      // Capture the mouse to ensure we can move even outside the box
      this.m_divElement.setPointerCapture(pointerEvent.pointerId);

      this.Logger.info?.trace(`click at ${clickCoords.toString()} on image ${this.m_displayedResolution.toString()}. Translates to ${clipSpaceCoords.toString()}`);
      this.m_onClick.trigger(clipSpaceCoords);
      pointerEvent.stopPropagation();
    }
  }

  private readonly onPointerUp = (pointerEvent: PointerEvent): void => {
    this.Canvas.releasePointerCapture(pointerEvent.pointerId);
    pointerEvent.stopPropagation();
  }

  private readonly onPointerMove = (pointerEvent: PointerEvent): void => {
    if (this.isCanvasEmpty()) {
      return;
    }
    //We can't compute the clipSpaceCoordinates if no image was ever rendered
    if (this.m_displayedResolution.IsNone) {
      return;
    }

    //Is main mouse button down
    if ((pointerEvent.buttons & 1) !== 1) {
      return;
    }

    const clickCoords = new TopLeftPixelCoords(pointerEvent.offsetX, pointerEvent.offsetY, this.m_displayedResolution);
    const clipSpaceCoords = clickCoords.toClipSpaceVec2();
    this.Logger.intense?.trace(`dragging at ${clickCoords.toString()} on image ${this.m_displayedResolution.toString()}. Translates to ${clipSpaceCoords.toString()}`);
    this.m_onClick.trigger(clipSpaceCoords);
    pointerEvent.stopPropagation();
  }

  private getCanvasRenderingContext2D(): CanvasRenderingContext2D {
    const context = this.Canvas.getContext('2d'/*, { alpha: false }*/); //Might help performance but leaves a black area when we don't use the whole canvas
    if (context === null) {
      throw new Error('Failed to obtain dewarping preview 2d context');
    }
    return context;
  }

  private isCanvasEmpty(): boolean {
    return this.Canvas.clientWidth === 0 || this.Canvas.clientHeight === 0;
  }

  public debugStatus(indent: number): string {
    return super.debugStatus(indent) + Utils.indentNewLine(indent) +
      'Preview displayed resolution: ' + this.m_displayedResolution.toString();
  }
}
