import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MeltedIcon, Severity } from '@genetec/gelato-angular';
import { SelectionType, TreeDensity, Icon, TextFlavor } from '@genetec/gelato';
import { Category } from '@modules/mission-control/models/category';
import { CategoryApiService } from '@modules/mission-control/services/category/category-api.service';
import { IconService } from '@modules/mission-control/services/icons/icon.service';
import { toRecord } from '@modules/mission-control/utils/list-utils';
import { TreeItem } from '@shared/interfaces/tree-item/tree-item';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { EntityBrowserComponent } from '@shared/components/entity-browser/entity-browser/entity-browser.component';
import { Coordinate, GeoCoordinate } from '@shared/interfaces/plugins/public/plugin-services-public.interface';
import { TrackingService } from '@shared/services/tracking.service';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, map, switchMap, tap } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { INCIDENT_LOCATION } from '../../entity-location';
import { TriggerIncidentCommand } from '../../models/commands/trigger-incident-command';
import { IncidentLocation } from '../../models/incident-location';
import { IncidentType } from '../../models/incident-type';
import { IncidentTypeService } from '../../services/incident-type.service';
import { IncidentCommandService } from '../../services/incident/incident-command.service';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { AuthService } from '@securityCenter/services/authentication/auth.service';

@Component({
    selector: 'app-trigger-incident-modal',
    templateUrl: './trigger-incident-modal.component.html',
    styleUrls: ['./trigger-incident-modal.component.scss'],
})
export class TriggerIncidentModalComponent extends ConnectionAwareModalComponent implements OnInit {
    public static pluginId = SafeGuid.parse('96F59E47-1AAF-4868-B9F7-945F98C585C2');

    @ViewChild('incidentTriggerEntityBrowser') public entityBrowser!: EntityBrowserComponent;

    public incidentTypeTreeItems$!: Observable<TreeItem[]>;
    public selectedLocation: IGuid = SafeGuid.EMPTY;
    public selectedIncidentTypeId: string | null = null;
    public selectedCoordinates: Coordinate | null = null;
    public searchText!: string;
    public dataContext: any;
    public comment = '';
    public canUserTrigger = false;
    public triggerAction: () => Promise<boolean>;

    public readonly incidentTypeModalBrowserFilter = new EntityBrowserFilter(INCIDENT_LOCATION);
    public readonly SelectionTypes = SelectionType;
    public readonly Severity = Severity;
    public readonly TreeDensity = TreeDensity;
    public readonly TextFlavor = TextFlavor;

    private readonly _nameFilter$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    private _skipCoordinateReset = false;
    private availableTypes: string[] = [];

    constructor(
        protected authService: AuthService,
        injector: Injector,
        trackingService: TrackingService,
        private incidentTypeService: IncidentTypeService,
        private incidentCommandService: IncidentCommandService,
        private incidentCategoryApiService: CategoryApiService,
        private iconService: IconService
    ) {
        super(authService, injector, trackingService);
        this.triggerAction = this.onTriggerClick.bind(this);
    }

    ngOnInit() {
        super.ngOnInit();

        // TODO: Fix image (looks like garbage)
        // Incident categories (folder) icons retrieval not implemented  yet
        const getIcon = (iconId: IGuid) => this.iconService.getIcon(iconId).pipe(map((icon) => ({ iconId, icon })));

        const categories$ = this.incidentCategoryApiService.getCategories();
        const incidentTypes$ = this.incidentTypeService.getIncidentTypes().pipe(
            tap((types) => (this.availableTypes = types.map((t) => t.id.toString()))),
            map((incidentTypes) => incidentTypes.filter((x) => x.isManuallyTriggerable))
        );
        const elements$ = combineLatest([categories$, incidentTypes$]).pipe(map(([categories, incidentTypes]) => [...categories, ...incidentTypes]));

        const elementImages$ = elements$.pipe(
            map((elements) => [...elements.map((e) => getIcon(e.iconId))]),
            switchMap((requests) => forkJoin([...requests]).pipe(defaultIfEmpty([] as { iconId: IGuid; icon: string }[]))),
            map((pairs) =>
                toRecord(
                    pairs,
                    (p) => p.iconId.toString(),
                    (v) => v.icon
                )
            )
        );

        const incidentTypeTreeItems$ = combineLatest([elements$, elementImages$]).pipe(map(([elements, elementImages]) => this.buildTreeItems(elements, elementImages)));
        this.incidentTypeTreeItems$ = combineLatest([incidentTypeTreeItems$, this._nameFilter$]).pipe(
            map(([treeItems, filter]) => (filter ? this.filterItems(filter, cloneDeep(treeItems)) : treeItems))
        );
    }

    public onSearchInputChange(input: string): void {
        this.canUserTrigger = false;
        this.selectedIncidentTypeId = null;

        if (!input || input.trim().length === 0) {
            this._nameFilter$.next(null);
            return;
        }

        this._nameFilter$.next(input);
    }

    public async onTriggerClick(): Promise<boolean> {
        if (!this.selectedIncidentTypeId) {
            return true;
        }
        let location: IncidentLocation | null = null;
        if (this.selectedLocation) {
            location = new IncidentLocation(this.selectedLocation, null, null);
        }
        if (this.selectedCoordinates) {
            location = new IncidentLocation(this.selectedLocation, this.selectedCoordinates.x, this.selectedCoordinates.y);
        }

        await this.incidentCommandService.execute(new TriggerIncidentCommand(this.selectedIncidentTypeId, location, null, this.comment)).toPromise();
        return true;
    }

    public initializeLocation(location: IGuid, coordinates: GeoCoordinate | Coordinate | null = null, isGeoLocalised: boolean): void {
        if (coordinates) {
            if (isGeoLocalised) {
                const geoCoordinates = coordinates as GeoCoordinate;
                this.selectedCoordinates = { x: geoCoordinates.latitude, y: geoCoordinates.longitude } as Coordinate;
            } else {
                this.selectedCoordinates = coordinates as Coordinate;
            }
            this._skipCoordinateReset = true;
        }
        this.selectedLocation = location;
    }

    public onSelectedIncidentTypeChange(newValue: TreeItem | null, event: Event): void {
        if (newValue?.id) {
            const isNewValudIncluded = this.availableTypes.includes(newValue.id ?? '');
            this.canUserTrigger = !!newValue && isNewValudIncluded;
            this.selectedIncidentTypeId = newValue?.id ?? null;
            event.stopImmediatePropagation();
        }
    }

    public onLocationChanged(location: IGuid): void {
        this.selectedLocation = location;
        if (!this._skipCoordinateReset) this.selectedCoordinates = null;
        this._skipCoordinateReset = false;
    }

    private buildTreeItems(elements: (IncidentType | Category)[], elementImages: Record<string, string>): TreeItem[] {
        const getParentId = (element: Category | IncidentType): string | null => {
            if (element instanceof Category && !!element.parentId) return element.parentId.toString();

            if (element instanceof IncidentType && !!element.categoryId) return element.categoryId.toString();

            return null;
        };

        const createElement = (
            element: Category | IncidentType,
            elementsRecord: Record<string, Category | IncidentType>,
            displayItems: Map<string, TreeItem>,
            parentId: string | null
        ): Map<string, TreeItem> => {
            const current: TreeItem = {
                id: element.id.toString(),
                text: element.name,
                icon: element instanceof IncidentType ? MeltedIcon.Incident : MeltedIcon.Folder,
                isChecked: false,
            };
            if (element instanceof IncidentType) {
                const img = elementImages[element.iconId.toString()];
                current.image = img !== Icon.Incident ? elementImages[element.iconId.toString()] : undefined;
            } else {
                current.icon = MeltedIcon.Folder;
            }

            if (parentId) {
                const parent: Category | IncidentType = elementsRecord[parentId];
                if (parent) {
                    let parentItem = displayItems.get(parentId);
                    if (!parentItem) {
                        createElement(parent, elementsRecord, displayItems, getParentId(parent));
                        parentItem = displayItems.get(parentId);
                    }
                    if (!parentItem!.children) parentItem!.children = [];
                    parentItem!.children.push(current);
                    current.parent = parentItem;
                }
            }

            displayItems.set(element.id.toString(), current);

            return displayItems;
        };

        let items: Map<string, TreeItem> = new Map<string, TreeItem>();

        for (const element of elements) {
            items = createElement(
                element,
                toRecord(
                    elements,
                    (e) => e.id.toString(),
                    (e) => e
                ),
                items,
                getParentId(element)
            );
        }

        const array = Array.from(items, ([key, value]) => value).filter((i) => !i.parent);
        return array;
    }

    private filterItems(filter: string, treeItems: TreeItem[]): TreeItem[] {
        const hasChildren = (item: TreeItem): boolean => !!item.children && item.children.length > 0;

        const shouldIncludeItem = (item: TreeItem): boolean => {
            if (item?.text) {
                return !filter || item.text.toLowerCase().includes(filter.toLowerCase());
            } else {
                return false;
            }
        };

        const filterItems = (displayItems: TreeItem[]): TreeItem[] => {
            const result: TreeItem[] = [];
            for (const displayItem of displayItems) {
                if (shouldIncludeItem(displayItem) && !hasChildren(displayItem)) {
                    result.push(displayItem);
                } else if (shouldIncludeItem(displayItem) && hasChildren(displayItem)) {
                    displayItem.isExpanded = filterItems(displayItem.children!).length > 0;
                    result.push(displayItem);
                } else if (!shouldIncludeItem(displayItem) && hasChildren(displayItem)) {
                    displayItem.children = filterItems(displayItem.children!);
                    displayItem.isExpanded = true;
                    if (displayItem.children.length > 0) result.push(displayItem);
                }
            }

            return result;
        };

        return filterItems(treeItems);
    }
}
