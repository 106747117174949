// Autogenerated: v2/help/Entities/ApiThreatLevel?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:24 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { IThreatLevelEntity, ThreatLevelEntityFields } from '../Interface/IThreatLevelEntity';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { FieldObject } from '../../Helpers/FieldObject';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IRestResponse } from '../Interface/IRestResponse';
import { ITransaction } from '../Interface/ITransaction';

export class ThreatLevelEntity extends Entity implements IThreatLevelEntity {
    // #region REST Properties

    // Color
    public get color(): string {
        return this.getField<string>(ThreatLevelEntityFields.colorField, ValidFlags.Specific);
    }
    public set color(value: string) {
        this.setField<string>(ThreatLevelEntityFields.colorField, value);
    }

    // Level
    public get level(): number {
        return this.getField<number>(ThreatLevelEntityFields.levelField, ValidFlags.Specific);
    }
    public set level(value: number) {
        this.setField<number>(ThreatLevelEntityFields.levelField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.ThreatLevels);
    }

    // #endregion Constructor

    // #region REST Methods

    public async activateAsync(target: IGuid, recursive: boolean, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('target', target);
        inParam.setField('recursive', recursive);

        return this.executeRequestTransactionAsync<IRestResponse>('Activate', 'POST', inParam, transaction, responseHandler);
    }

    public async deactivateAsync(target: IGuid, recursive: boolean, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('target', target);
        inParam.setField('recursive', recursive);

        return this.executeRequestTransactionAsync<IRestResponse>('Deactivate', 'POST', inParam, transaction, responseHandler);
    }

    // #endregion REST Methods

}

