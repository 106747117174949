import { Directive, OnDestroy, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
    selector: '[appClickActionOnSpaceEnter]',
})
export class ClickActionOnSpaceEnterDirective implements AfterViewInit, OnDestroy {
    /**
     * Call the handler assigned to the click event whenn Space or Enter keyup
     */
    @Input() appClickActionOnSpaceEnter: boolean | string = false;
    constructor(private el: ElementRef<HTMLElement>) {}

    ngAfterViewInit() {
        this.el?.nativeElement?.addEventListener('keyup', this.onKeyUp);
    }

    ngOnDestroy() {
        this.el?.nativeElement?.removeEventListener('keyup', this.onKeyUp);
    }

    private readonly onKeyUp = (e: KeyboardEvent): void => {
        if (e.code === 'Space' || e.key === 'Enter') {
            this.el?.nativeElement?.click();
        }

        e.stopPropagation();
    };
}
