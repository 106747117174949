// Autogenerated: v2/help/enumerations/ApiAccessControlInterfaceType?codegen=ts&showresources=False on 2022-12-21 2:23:40 PM

export class AccessControlInterfaceType {
    public static None = 'None';
    public static V100 = 'V100';
    public static V200 = 'V200';
    public static V300 = 'V300';
    public static MR50 = 'MR50';
    public static MR52 = 'MR52';
    public static MR16IN = 'MR16IN';
    public static MR16OUT = 'MR16OUT';
    public static MRDT = 'MRDT';
    public static M52RP = 'M52RP';
    public static M52SRP = 'M52SRP';
    public static M520In = 'M520In';
    public static M516Do = 'M516Do';
    public static M516Dor = 'M516Dor';
    public static M58RP = 'M58RP';
    public static M582K = 'M582K';
    public static MR51e = 'MR51e';
    public static EP1502 = 'EP1502';
    public static EP1501 = 'EP1501';
    public static STidW33 = 'STidW33';
    public static STidUSB = 'STidUSB';
    public static DDSTPL2 = 'DDSTPL2';
    public static DDSTPL4 = 'DDSTPL4';
    public static KeriNXTWithEntraguard = 'KeriNXTWithEntraguard';
    public static KeriNXT = 'KeriNXT';
    public static Sim = 'Sim';
    public static CorbinRusswin = 'CorbinRusswin';
    public static Persona = 'Persona';
    public static Sargent = 'Sargent';
    public static Tyco = 'Tyco';
    public static AAother = 'AAother';
    public static Aperio = 'Aperio';
    public static Sallis = 'Sallis';
    public static A1001 = 'A1001';
    public static Unknown = 'Unknown';
    public static EP1501WithExpansion = 'EP1501WithExpansion';
    public static EP2500 = 'EP2500';
    public static M5IC = 'M5IC';
    public static EP4502 = 'EP4502';
    public static PW6K1IC = 'PW6K1IC';
    public static PW6K1R2 = 'PW6K1R2';
    public static PW6K1IN = 'PW6K1IN';
    public static PW6K1OUT = 'PW6K1OUT';
    public static PIM400 = 'PIM400';
    public static AD400 = 'AD400';
    public static AD300 = 'AD300';
    public static PW5K1R1 = 'PW5K1R1';
    public static PW5K1R2 = 'PW5K1R2';
    public static PW5K1IN = 'PW5K1IN';
    public static PW5K1OUT = 'PW5K1OUT';
    public static MSICS = 'MSICS';
    public static MSACS = 'MSACS';
    public static MSI8S = 'MSI8S';
    public static MSR8S = 'MSR8S';
    public static AutoVu = 'AutoVu';
    public static GatewayNode = 'GatewayNode';
    public static SmartHandle = 'SmartHandle';
    public static PadLock = 'PadLock';
    public static Cylinder = 'Cylinder';
    public static External = 'External';
    public static SmartHandleWithInputs = 'SmartHandleWithInputs';
    public static CylinderWithInputs = 'CylinderWithInputs';
    public static NDEPIM400 = 'NDEPIM400';
    public static NDEAD400 = 'NDEAD400';
    public static OSDP = 'OSDP';
    public static SynergisIX = 'SynergisIX';
    public static A1601 = 'A1601';
    public static LP1501 = 'LP1501';
    public static LP1501WithExpansion = 'LP1501WithExpansion';
    public static LP1502 = 'LP1502';
    public static LP2500 = 'LP2500';
    public static LP4502 = 'LP4502';
    public static MR62e = 'MR62e';
    public static SynergisIX_Controller = 'SynergisIX_Controller';
    public static SynergisIX_InputExpander = 'SynergisIX_InputExpander';
    public static SynergisIX_OutputExpander = 'SynergisIX_OutputExpander';
    public static SynergisIX_ReaderExpander = 'SynergisIX_ReaderExpander';
    public static SynergisIX_AnalogExpander = 'SynergisIX_AnalogExpander';
    public static SynergisIX_Keypad = 'SynergisIX_Keypad';
    public static SynergisIX_Controller_CTRL_DIN = 'SynergisIX_Controller_CTRL_DIN';
    public static SynergisIX_Controller_CTRL_DIN_1D = 'SynergisIX_Controller_CTRL_DIN_1D';
    public static SynergisIX_ReaderExpander_RDM2_DIN = 'SynergisIX_ReaderExpander_RDM2_DIN';
    public static PRO32IC = 'PRO32IC';
    public static PRO32R2 = 'PRO32R2';
    public static PRO32IN = 'PRO32IN';
    public static PRO32OUT = 'PRO32OUT';
    public static PRO22R1 = 'PRO22R1';
    public static SchlageIP = 'SchlageIP';
    public static WiQGateway = 'WiQGateway';
    public static WiQLock = 'WiQLock';
    public static PW7K1IC = 'PW7K1IC';
    public static PRO42IC = 'PRO42IC';
    public static PW7K1R1 = 'PW7K1R1';
    public static PW7K1R2 = 'PW7K1R2';
    public static PW7K1IN = 'PW7K1IN';
    public static PW7K1OUT = 'PW7K1OUT';
    public static PRO42R1 = 'PRO42R1';
    public static PRO42R2 = 'PRO42R2';
    public static PRO42IN = 'PRO42IN';
    public static PRO42OUT = 'PRO42OUT';

    public static readonly values = [
        AccessControlInterfaceType.None,
        AccessControlInterfaceType.V100,
        AccessControlInterfaceType.V200,
        AccessControlInterfaceType.V300,
        AccessControlInterfaceType.MR50,
        AccessControlInterfaceType.MR52,
        AccessControlInterfaceType.MR16IN,
        AccessControlInterfaceType.MR16OUT,
        AccessControlInterfaceType.MRDT,
        AccessControlInterfaceType.M52RP,
        AccessControlInterfaceType.M52SRP,
        AccessControlInterfaceType.M520In,
        AccessControlInterfaceType.M516Do,
        AccessControlInterfaceType.M516Dor,
        AccessControlInterfaceType.M58RP,
        AccessControlInterfaceType.M582K,
        AccessControlInterfaceType.MR51e,
        AccessControlInterfaceType.EP1502,
        AccessControlInterfaceType.EP1501,
        AccessControlInterfaceType.STidW33,
        AccessControlInterfaceType.STidUSB,
        AccessControlInterfaceType.DDSTPL2,
        AccessControlInterfaceType.DDSTPL4,
        AccessControlInterfaceType.KeriNXTWithEntraguard,
        AccessControlInterfaceType.KeriNXT,
        AccessControlInterfaceType.Sim,
        AccessControlInterfaceType.CorbinRusswin,
        AccessControlInterfaceType.Persona,
        AccessControlInterfaceType.Sargent,
        AccessControlInterfaceType.Tyco,
        AccessControlInterfaceType.AAother,
        AccessControlInterfaceType.Aperio,
        AccessControlInterfaceType.Sallis,
        AccessControlInterfaceType.A1001,
        AccessControlInterfaceType.Unknown,
        AccessControlInterfaceType.EP1501WithExpansion,
        AccessControlInterfaceType.EP2500,
        AccessControlInterfaceType.M5IC,
        AccessControlInterfaceType.EP4502,
        AccessControlInterfaceType.PW6K1IC,
        AccessControlInterfaceType.PW6K1R2,
        AccessControlInterfaceType.PW6K1IN,
        AccessControlInterfaceType.PW6K1OUT,
        AccessControlInterfaceType.PIM400,
        AccessControlInterfaceType.AD400,
        AccessControlInterfaceType.AD300,
        AccessControlInterfaceType.PW5K1R1,
        AccessControlInterfaceType.PW5K1R2,
        AccessControlInterfaceType.PW5K1IN,
        AccessControlInterfaceType.PW5K1OUT,
        AccessControlInterfaceType.MSICS,
        AccessControlInterfaceType.MSACS,
        AccessControlInterfaceType.MSI8S,
        AccessControlInterfaceType.MSR8S,
        AccessControlInterfaceType.AutoVu,
        AccessControlInterfaceType.GatewayNode,
        AccessControlInterfaceType.SmartHandle,
        AccessControlInterfaceType.PadLock,
        AccessControlInterfaceType.Cylinder,
        AccessControlInterfaceType.External,
        AccessControlInterfaceType.SmartHandleWithInputs,
        AccessControlInterfaceType.CylinderWithInputs,
        AccessControlInterfaceType.NDEPIM400,
        AccessControlInterfaceType.NDEAD400,
        AccessControlInterfaceType.OSDP,
        AccessControlInterfaceType.SynergisIX,
        AccessControlInterfaceType.A1601,
        AccessControlInterfaceType.LP1501,
        AccessControlInterfaceType.LP1501WithExpansion,
        AccessControlInterfaceType.LP1502,
        AccessControlInterfaceType.LP2500,
        AccessControlInterfaceType.LP4502,
        AccessControlInterfaceType.MR62e,
        AccessControlInterfaceType.SynergisIX_Controller,
        AccessControlInterfaceType.SynergisIX_InputExpander,
        AccessControlInterfaceType.SynergisIX_OutputExpander,
        AccessControlInterfaceType.SynergisIX_ReaderExpander,
        AccessControlInterfaceType.SynergisIX_AnalogExpander,
        AccessControlInterfaceType.SynergisIX_Keypad,
        AccessControlInterfaceType.SynergisIX_Controller_CTRL_DIN,
        AccessControlInterfaceType.SynergisIX_Controller_CTRL_DIN_1D,
        AccessControlInterfaceType.SynergisIX_ReaderExpander_RDM2_DIN,
        AccessControlInterfaceType.PRO32IC,
        AccessControlInterfaceType.PRO32R2,
        AccessControlInterfaceType.PRO32IN,
        AccessControlInterfaceType.PRO32OUT,
        AccessControlInterfaceType.PRO22R1,
        AccessControlInterfaceType.SchlageIP,
        AccessControlInterfaceType.WiQGateway,
        AccessControlInterfaceType.WiQLock,
        AccessControlInterfaceType.PW7K1IC,
        AccessControlInterfaceType.PRO42IC,
        AccessControlInterfaceType.PW7K1R1,
        AccessControlInterfaceType.PW7K1R2,
        AccessControlInterfaceType.PW7K1IN,
        AccessControlInterfaceType.PW7K1OUT,
        AccessControlInterfaceType.PRO42R1,
        AccessControlInterfaceType.PRO42R2,
        AccessControlInterfaceType.PRO42IN,
        AccessControlInterfaceType.PRO42OUT
    ];
}
