import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ButtonFlavor, Icon } from '@genetec/gelato';
import { InternalPluginDescriptor } from '../../../shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '../../../shared/interfaces/plugins/public/plugin-types';
import { PluginComponentExposure, PluginComponentRequirements } from '../../../shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService, MAPS_BOTTOM_CONTEXT_SERVICE, SIDE_CONTEXT_SERVICE } from '../../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { MissionControlContentTypes } from '../../mission-control-content-types';
import { MCFeature } from '../../mc-feature';
import { McEventReceiverService } from '../../services/events/mc-event-receiver.service';
import { IncidentOrientation } from '../incidents-list/incidents-list.component';
import { McContentNames } from '../../mc-content-names';
import { ContentFields } from '../../content-fields';
import { McActionService } from '@modules/mission-control/services/action/mc-action.service';

@Component({
    selector: 'app-incidents-tray',
    templateUrl: './incidents-tray.component.html',
    styleUrls: ['./incidents-tray.component.css'],
})
@InternalPluginDescriptor({
    type: IncidentsTrayComponent,
    pluginTypes: [PluginTypes.MapTray],
    exposure: { id: IncidentsTrayComponent.pluginId } as PluginComponentExposure,
    requirements: {
        features: [MCFeature.missionControlfeature],
        globalPrivileges: [KnownPrivileges.incidentMonitoringTaskPrivilege],
    },
    isSupported: () => true,
})
export class IncidentsTrayComponent extends TrackedComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('786FCAF6-EA8A-43C3-95BB-D5319DA931EF');

    public isBottom = true;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;

    public incidentListContentType = MissionControlContentTypes.incidentList;

    constructor(
        @Inject(MAPS_BOTTOM_CONTEXT_SERVICE) public bottomContextService: ContentService,
        @Inject(SIDE_CONTEXT_SERVICE) private sideContextService: ContentService,
        private contentProviderService: ContentProviderService,
        trackingService: TrackingService,
        actionService: McActionService, // Initialize service hidden behind the feature requirement
        mcEventReceiver: McEventReceiverService // Initialize service hidden behind the feature requirement
    ) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public async toggleIncidents(): Promise<void> {
        if (this.bottomContextService.contentCount === 0) {
            const incidentListRequest = new FieldObject();
            incidentListRequest.setField(McContentNames.incidentListContent, this.incidentListContentType.toString());
            incidentListRequest.setField(ContentFields.listOrientation, IncidentOrientation.Bottom);
            const content = await this.contentProviderService.getContentFromFieldsAsync(incidentListRequest);
            if (content && !content.id.isEmpty()) {
                this.bottomContextService.setMainContent(content);
            } else {
                this.bottomContextService.setMainContent(undefined);
            }
        } else {
            this.bottomContextService.clearMainContent();
        }
    }
}
