<!-- Loading spinner until app is bootstrapped -->
<ng-container *ngIf="isReconnecting">
    <div class="loadingScreen__container">
        <div class="loadingScreen__wrapper">
            <div class="gen-spinner lg show loadingScreen__spinner" *ngIf="showSpinner | async">
                <div class="spin">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <path
                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3V0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 7.48196 2.49686 3.54709 6.18599 1.5C7.5 1 9 2.5 8 3.93552C5.03656 5.40822 3 8.46628 3 12C3 16.9706 7.02944 21 12 21Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0)">
                            <path d="M6.00604 -0.58667L10.1008 3.79883L4 11L-2.5 11L6.00604 -0.58667Z" fill="url(#paint0_linear)"></path>
                            <path d="M-1.00001 11L3.99999 11L8.4748 18.3142L6.62196 24.5445L6.91647e-08 18.3142L-1.00001 11Z" fill="url(#paint1_linear)"></path>
                            <path d="M6.68073 24.3137L8.13298 19.5L16.6849 17.1701L22.1819 20.6389L14.0929 25.3291L6.68073 24.3137Z" fill="url(#paint2_linear)"></path>
                            <path d="M22.4186 20.7828L18.11 18.0832L18.3925 9.61067L23.3159 5.36684L25.526 14.1863L22.4186 20.7828Z" fill="url(#paint3_linear)"></path>
                            <path d="M23.7492 4.99219L19.9662 8.26159L11.798 5.65362L9.12595 -0.27177L18.21 0.112111L23.7492 4.99219Z" fill="url(#paint4_linear)"></path>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear" x1="8" y1="1.5" x2="1.9231" y2="10.7771" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" />
                                <stop offset="1" stop-color="white" stop-opacity="0.8" />
                            </linearGradient>
                            <linearGradient id="paint1_linear" x1="0.500001" y1="12" x2="7.91784" y2="21.1617" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0.8" />
                                <stop offset="1" stop-color="white" stop-opacity="0.6" />
                            </linearGradient>
                            <linearGradient id="paint2_linear" x1="8" y1="22.5" x2="18.9977" y2="18.3201" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0.6" />
                                <stop offset="1" stop-color="white" stop-opacity="0.4" />
                            </linearGradient>
                            <linearGradient id="paint3_linear" x1="21.696" y1="19.2311" x2="20.3987" y2="7.51452" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0.4" />
                                <stop offset="1" stop-color="white" stop-opacity="0.2" />
                            </linearGradient>
                            <linearGradient id="paint4_linear" x1="21" y1="6.5" x2="15.8056" y2="2.2369" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0.2" />
                                <stop offset="1" stop-color="white" stop-opacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <div class="loadingScreen__text">
                {{ message | async }}
                <span class="loadingScreen__progress-ellipsis" *ngIf="showSpinner | async">{{ progressEllipsis | async }}</span>
            </div>
        </div>
    </div>
</ng-container>
