import { SafeGuid } from 'safeguid';
import { ITimelinePart, TimelinePart } from './timelinePart';

export class TimelineProviderId {
    public static BookmarkTimelineProviderId = SafeGuid.parse('156F26CE-B014-4992-8186-E70344A4D794');
    public static MotionTimelineProviderId = SafeGuid.parse('23159516-6217-46EE-AC2C-C5824250A6C4');
    public static RecordingSequenceTimelineProviderId = SafeGuid.parse('E25BA7BA-3F31-4212-B539-83C4A1E9D266');
    public static AccessControlTimelineProviderId = SafeGuid.parse('9A569C59-0D86-4761-8A8D-07E46199A972');
    public static AlarmTimelineProviderId = SafeGuid.parse('C1BAE616-1986-4D66-9B7C-E6EB95670818');
}

export class TimelinePartEvent {
    public readonly parts: Set<ITimelinePart>;

    constructor(data: any) {
        this.parts = new Set<ITimelinePart>();
        for (const item of data) {
            const newFo = new TimelinePart();
            newFo.loadFields(item);
            this.parts.add(newFo as ITimelinePart);
        }
    }
}
