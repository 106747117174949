import { Pipe, PipeTransform } from '@angular/core';

/*
  Returns the luminance of an argb color code
  Example : <div [style.filter]="(row.ArgbColor | luminance) > 0.5 ? invert(1) : invert(0)"></div>
*/
@Pipe({ name: 'luminance' })
export class LuminancePipe implements PipeTransform {
    public transform(argb: string | undefined | null): number {
        if (!argb) {
            return 0;
        }
        const argbColor = argb.slice(1, argb.length);
        const r = parseInt(argbColor.slice(2, 4), 16);
        const g = parseInt(argbColor.slice(4, 6), 16);
        const b = parseInt(argbColor.slice(6, 8), 16);
        const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
        if (Number.isNaN(luminance)) {
            throw Error(`Luminance of ${argb} could not be evaluated`);
        }
        return luminance;
    }
}
