import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Point } from '@modules/shared/interfaces/drawing';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentExtensionServicesProvider, CONTENT_SERVICES_PROVIDER } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IVideoPlayer } from 'Marmot/ivideoplayer';
import { PlayerMode } from 'Marmot/Marmot/enums';
import { PlayerModeChangeEvent } from 'Marmot/Marmot/events';
import { PtzFocusDistance, PtzIrisOperation } from 'Marmot/Marmot/gwp';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { CameraEntityFields, ICameraEntity, IPtzInfo } from 'RestClient/Client/Interface/ICameraEntity';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { Entity } from 'RestClient/Client/Model/Entity';
import { CameraEntity, PtzCapabilitiesInfo } from 'RestClient/Client/Model/Video/CameraEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { VideoClient } from '../../../api/api';
import { SupportedCommands } from '../supported-commands';
import { PtzPrivileges } from './ptz-privileges';

@UntilDestroy()
@Injectable()
export class PtzControlsService implements OnDestroy {
    // public
    public entityCacheTask: IEntityCacheTask;

    // Constants
    private readonly guid = SafeGuid.parse('BFE42E92-40FB-4EFC-BD22-1285BBFB2A69');
    private readonly defaultSupportedCommandsHex = '0000000000000000000';

    // Camera Information
    private lockingStateInformationSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.translateService.instant('STE_LABEL_PTZ_ANALOG_PTZ') as string);

    // Observable tracker

    // privileges related to PTZ control
    private ptzPrivilegesSubject = new ReplaySubject<PtzPrivileges>();
    private ptzPrivileges?: PtzPrivileges = undefined;

    private iscontentReceived = false;
    // Init data
    private contentSubject: Subject<Content> = new Subject<Content>();
    private content$: Observable<Content> = this.contentSubject.asObservable();

    // Instance of the video player to which this service is bound.
    private videoPlayer?: IVideoPlayer;

    // Ptz Configuration
    private zoomSpeed = 50;

    // Security Center client
    private scClient: SecurityCenterClient;

    // CameraEntity
    private cameraEntitySubject = new Subject<CameraEntity>();
    private cameraEntity$ = this.cameraEntitySubject.asObservable();

    // PtzCapabilities
    private cameraCapabilitiesSubject = new Subject<PtzCapabilitiesInfo>();
    private ptzPatternsSubject = new Subject<ObservableCollection<IPtzInfo>>();
    private ptzPresetsSubject = new Subject<ObservableCollection<IPtzInfo>>();

    // Supported commands
    private supportedCommandsHexSubject = new BehaviorSubject<string>(this.defaultSupportedCommandsHex);
    private supportedCommandsSubject = new Subject<SupportedCommands>();

    // Camera mode
    private playerModeSubject = new BehaviorSubject<PlayerMode>(PlayerMode.live);

    private currentLockingUserId: IGuid | undefined;
    private currentLockingUserAppId: IGuid | undefined;

    // Cache pour les entity informationd
    constructor(
        @Inject(CONTENT_SERVICES_PROVIDER) public contentServicesProvider: ContentExtensionServicesProvider,
        private translateService: TranslateService,
        private securityCenterClientService: SecurityCenterClientService,
        private videoApiClient: VideoClient
    ) {
        // Retrieve SecurityCenterClient
        this.scClient = this.securityCenterClientService.scClient;
        // Build cacheTask
        this.entityCacheTask = this.scClient.buildEntityCache([CameraEntityFields.lockingUserIdField, CameraEntityFields.lockingUserAppIdField, EntityFields.isFederatedField]);

        // Listen for incoming content from a Ptz controllable content
        // content will be coming from a component that injects this service.
        this.content$.pipe(untilDestroyed(this)).subscribe(async (content: Content) => {
            this.iscontentReceived = true;
            // We received the ID of the videoPlayer, let's bind our service to it.
            this.videoPlayer = this.contentServicesProvider.getService<IVideoPlayer>(content.id);

            // Register this service with the ContentServicesProvider
            this.contentServicesProvider.setService<PtzControlsService>(this.guid, this as PtzControlsService);

            // Register callback for videoMode
            this.registerPlayerModeChangedCallback();

            // Create the privilege class from the content, en emit the resutlt
            this.ptzPrivileges = new PtzPrivileges(content);
            this.ptzPrivilegesSubject.next(this.ptzPrivileges);

            //Bind cameraService to a specific guid entity
            const cameraEntity = await this.getCameraEntityFromCache(SafeGuid.parse(content.source));
            if (cameraEntity !== null) {
                this.cameraEntitySubject.next(cameraEntity);
            }
        });
        this.cameraEntity$.pipe(untilDestroyed(this)).subscribe(async (cameraEntity: CameraEntity) => {
            // Check for ptzLockInfo
            await this.checkForLockingStateInformation(cameraEntity);

            // init cache
            await this.entityCacheTask.detectFieldChangeAsync(
                cameraEntity,
                () => {
                    const _ = cameraEntity?.lockingUserId;
                },
                async (entity: IEntity) => {
                    await this.checkForLockingStateInformation(entity as CameraEntity);
                }
            );
            await this.entityCacheTask.detectFieldChangeAsync(
                cameraEntity,
                () => {
                    const _ = cameraEntity?.lockingUserAppId;
                },
                async (entity: IEntity) => {
                    await this.checkForLockingStateInformation(entity as CameraEntity);
                }
            );

            // Look for ptzCamera capabilities
            const ptzCapabilities = await cameraEntity.getPtzCapabilitiesAsync();
            if (ptzCapabilities) {
                this.cameraCapabilitiesSubject.next(ptzCapabilities as PtzCapabilitiesInfo);
                // for internal use
                this.supportedCommandsHexSubject.next(ptzCapabilities.supportedCommands);
            }

            // Look for ptzPatterns
            const ptzPatterns = await cameraEntity.getPatternsAsync();
            if (ptzPatterns) {
                this.ptzPatternsSubject.next(ptzPatterns);
            }

            // look for ptzPresets
            const ptzPresets = await cameraEntity.getPresetsAsync();
            if (ptzPresets) {
                this.ptzPresetsSubject.next(ptzPresets);
            }
        });
    }
    /**
     * Assigns the content of the component that injects this service
     * to the observable so that we can properly retrieve the videoPlayer.
     * This HAS to be done before the service is usable.
     *
     * @param content
     */
    public setContent(content: Content): void {
        this.contentSubject.next(content);
    }

    public getZoomSpeed(): number {
        return this.zoomSpeed;
    }

    public ngOnDestroy(): void {
        this.entityCacheTask.dispose().fireAndForget();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Exposed Observables
    ///////////////////////////////////////////////////////////////////////////////////////
    /**
     * Subscribe to this to receive the latests PtzPrivilege your user have over the PtzCamera bound to this service
     *
     * @returns A PtzPrivilege object that encapsulates every PtzRelated privileges for the user interacting with the camera bound to this service.
     */
    public getPtzPrivileges(): Observable<PtzPrivileges> {
        return this.ptzPrivilegesSubject.asObservable();
    }

    /**
     * Returns an observable containing the lock string information. This observable will be updated whenever there is a change in
     * the ptzLockUser or ptzLockUserApp.
     */
    public getLockingStateInformation(): Observable<string> {
        return this.lockingStateInformationSubject.asObservable();
    }

    /**
     * Subscribe to this to receive the latest PtzCapabilitiesInfo for the PtzCamera bound to this service
     *
     * @returns ptzCapabilities of the ptz camera bound to this service
     */
    public getCameraCapabilities(): Observable<PtzCapabilitiesInfo> {
        return this.cameraCapabilitiesSubject.asObservable();
    }

    /**
     * Subscribe to this to get the latest player mode of the PtzCamera bound to this service
     *
     * @returns Observable of the latest playerMode
     */
    public getPlayerMode(): Observable<PlayerMode> {
        return this.playerModeSubject.asObservable();
    }

    /**
     * Subscribe to this to get the camera presets list of the PtzCamera bound to this service
     *
     * @returns Observable of the latest cameraPresets
     */
    public getCameraPresets(): Observable<ObservableCollection<IPtzInfo>> {
        return this.ptzPresetsSubject.asObservable();
    }

    /**
     * Subscribe to this to get the camera patterns list of the PtzCamera bound to this service
     *
     * @returns Observable of the latest cameraPatterns
     */
    public getCameraPatterns(): Observable<ObservableCollection<IPtzInfo>> {
        return this.ptzPatternsSubject.asObservable();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Locking Functionnalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public ptzTriggerLock(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        // ensure the user has the basic PTZ privilege
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowLockPtz) {
            this.videoPlayer.ptzControl?.lock();
            return true;
        } else {
            return false;
        }
    }

    public ptzTriggerUnlock(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        // ensure the user has the basic PTZ privilege
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowPtzLockOverride) {
            this.videoPlayer.ptzControl?.unlock();
            return true;
        } else {
            return false;
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////
    // Home Position Functionnalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public ptzGoHomePosition(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        // ensure the user has the basic PTZ privilege
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowBasicPtz) {
            this.videoPlayer.ptzControl?.goHome();
            return true;
        } else {
            return false;
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Presets  Functionnalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public ptzGoToPreset(preset: number): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        // ensure the user has the basic PTZ privilege
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowPresetsUse) {
            this.videoPlayer.ptzControl?.goToPreset(preset);
            return true;
        } else {
            return false;
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Patterns  Functionnalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public ptzRunPattern(patternNumber: number): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        // ensure the user has the basic PTZ privilege
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowPatternsUse) {
            this.videoPlayer.ptzControl?.runPattern(patternNumber);
            return true;
        } else {
            return false;
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Zooming Functionnalities
    ///////////////////////////////////////////////////////////////////////////////////////
    /**
     * Allows to set the zoom speed for the startZoom method.
     *
     * @param value numeric value between -100 and 100. If smaller or bigger, will be adjusted to the closer limit.
     */
    public setZoomSpeed(value: number): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowDigitalZoom) {
            let speed = value;
            // constraint the zoom value
            if (speed < -100) {
                speed = -100;
            } else if (speed > 100) {
                speed = 100;
            }
            this.zoomSpeed = speed;
            return true;
        } else {
            return false;
        }
    }

    /**
     * Zooms in the Ptz Camera until it receives a stopZoom Signal.
     * if the zoom speed is set to -x%, then the value is adjusted to it's positive equivalent.
     */
    public zoomIn(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowDigitalZoom) {
            if (this.zoomSpeed < 0) {
                this.zoomSpeed = Math.abs(this.zoomSpeed);
            }
            this.videoPlayer.ptzControl?.startZoom(this.zoomSpeed);
            return true;
        } else {
            return false;
        }
    }

    /**
     * Zooms out the Ptz Camera until it receives a stopZoom() signal.
     * if the zoom speed is set to +x%, then the value is adjusted to it's negative equivalent.
     */
    public zoomOut(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowDigitalZoom) {
            if (this.zoomSpeed > 0) {
                this.zoomSpeed = this.zoomSpeed * -1;
            }
            this.videoPlayer.ptzControl?.startZoom(this.zoomSpeed);
            return true;
        } else {
            return false;
        }
    }

    /**
     * Stops the zooming action in progress (in, or out)
     */
    public stopZoom(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowDigitalZoom) {
            this.videoPlayer.ptzControl?.stopZoom();
            return true;
        } else {
            return false;
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Pan, Tilt Functionnalities
    ///////////////////////////////////////////////////////////////////////////////////////
    /**
     * Stops any movement of the camera
     */
    public stopPtz(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowBasicPtz) {
            this.videoPlayer.ptzControl?.stopPanTilt();
            return true;
        } else {
            return false;
        }
    }

    /**
     * Applies velocity on x and y coordinates.
     * Negative x values move the camera to the left, and positive values to the right.
     * Negative y values move the camera downward, and positive values upward.
     *
     * @param velocity x and y values must be between -100 and 100
     */
    public ptzStartPanTilt(velocity: Point): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        // ensure the user has the basic PTZ privilege
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowBasicPtz) {
            this.videoPlayer.ptzControl?.startPanTilt(velocity.x, velocity.y);
            return true;
        } else {
            return false;
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Focus & Iris Functionnalities
    ///////////////////////////////////////////////////////////////////////////////////////
    /**
     * Focuses in the ptz camera
     *
     * @param ptzSpeed speed at which the action will take place
     * @returns whether the action succeeded or failed
     */
    public ptzFocusIn(ptzSpeed: number): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowChangeFocusAndIris) {
            this.videoPlayer.ptzControl?.startFocus(PtzFocusDistance.Far, ptzSpeed);
            return true;
        } else {
            return false;
        }
    }

    /**
     * Focuses out the ptz camera
     *
     * @param ptzSpeed speed at which the action will take place
     * @returns whether the action succeeded or failed
     */
    public ptzFocusOut(ptzSpeed: number): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowChangeFocusAndIris) {
            this.videoPlayer.ptzControl?.startFocus(PtzFocusDistance.Near, ptzSpeed);
            return true;
        } else {
            return false;
        }
    }

    /**
     * Sends a stop signal to the focus motors
     *
     * @returns whether the action succeeded or failed
     */
    public ptzStopFocus(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowChangeFocusAndIris) {
            this.videoPlayer.ptzControl?.stopFocus();
            return true;
        } else {
            return false;
        }
    }

    /**
     * Opens the aperture of the camera
     *
     * @param ptzSpeed speed at which the action will take place
     * @returns whether the action succeeded or failed
     */
    public ptzIrisOpen(ptzSpeed: number): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowChangeFocusAndIris) {
            this.videoPlayer.ptzControl?.startIris(PtzIrisOperation.Open, ptzSpeed);
            return true;
        } else {
            return false;
        }
    }

    /**
     * Closes the aperture of the camera
     *
     * @param ptzSpeed speed at which the action will take place
     * @returns whether the action succeeded or failed
     */
    public ptzIrisClose(ptzSpeed: number): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowChangeFocusAndIris) {
            this.videoPlayer.ptzControl?.startIris(PtzIrisOperation.Close, ptzSpeed);
            return true;
        } else {
            return false;
        }
    }

    /**
     * Sends a stop signal to the iris motors
     *
     * @returns whether the action succeeded or failed
     */
    public ptzStopIris(): boolean {
        if (!this.isPtzServiceProperlyInitialized()) {
            throw new Error('PTZ methods cannot be used at the moment, are you sure you called method "setcontent" before calling this PTZ action ?');
        }
        if (this.videoPlayer?.isPtz && this.ptzPrivileges?.allowChangeFocusAndIris) {
            this.videoPlayer.ptzControl?.stopIris();
            return true;
        } else {
            return false;
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Capabilities Section
    ///////////////////////////////////////////////////////////////////////////////////////
    public getAllSupportedCommands(supportedCommandHex: string | undefined): Observable<SupportedCommands> {
        const subject = new BehaviorSubject<SupportedCommands>(new SupportedCommands([]));
        if (!supportedCommandHex) {
            const emptyCommands = new SupportedCommands([]);
            subject.next(emptyCommands);
        } else {
            this.videoApiClient
                .supportedCommands(supportedCommandHex)
                .pipe(take(1), untilDestroyed(this))
                .subscribe((supportedCommandList: string[]) => {
                    subject.next(new SupportedCommands(supportedCommandList));
                });
        }
        return subject.asObservable();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Locking State Information
    ///////////////////////////////////////////////////////////////////////////////////////
    /**
     * Returns the locking state information of the PtzCamera bound to this service. The Business logic is extracted straight from the
     * Security Desk application.
     *
     * @param entityQuery must regroup lockingUserAppId and lockingUserId. The order does not matter.
     */
    private async getPtzLockingStateInformation(entity: CameraEntity): Promise<string> {
        // Create a guidset from the two guids
        const guidset = SafeGuid.createSet();
        guidset.add(entity?.lockingUserAppId);
        guidset.add(entity?.lockingUserId);

        // Seek out an empty Guid
        let msg = '';
        guidset.forEach((guid: IGuid) => {
            if (guid.isEmpty()) {
                msg = this.translateService.instant('STE_LABEL_PTZ_ANALOG_PTZ') as string;
            }
        });
        if (msg !== '') {
            return Promise.resolve(msg);
        }

        // Extract the objects from the returned entities
        const entities = await this.entityCacheTask.getEntitiesAsync<Entity, IEntity>(Entity, guidset, false);
        const ptzLockOwnerAppObject: IEntity | undefined = entities?.find((entry) => entry.entityType === EntityTypes.Applications);
        const ptzLockOwnerObject: IEntity | undefined = entities?.find((entry) => entry.entityType === EntityTypes.Users);

        // retrieve their name if present, otherwise return null
        let ptzLockOwner = ptzLockOwnerObject?.name || null;
        let ptzLockOwnerApp = ptzLockOwnerAppObject?.name || null;

        if (ptzLockOwnerObject === null || ptzLockOwnerObject === undefined) {
            if (entity?.isFederated) {
                ptzLockOwner = this.translateService.instant('STE_LABEL_PTZ_FEDERATION') as string;
            } else {
                ptzLockOwner = this.translateService.instant('STE_LABEL_PTZ_ANOTHER_USER_LC') as string;
            }
        }
        let message = '';
        if (ptzLockOwnerApp !== null && ptzLockOwner !== null) {
            // if the returned ptzLockOwnerApp contains a GUID,
            const itMatchesRegex = /[a-z0-9A-Z]{8}-([a-z0-9-A-Z]{4}-){3}[a-zA-Z0-9]{12}/.exec(ptzLockOwnerApp);
            if (itMatchesRegex) {
                // then reinterpret it as being issued by the webApp itself
                ptzLockOwnerApp = this.translateService.instant('STE_LABEL_PTZ_LOCKED_BY_WEB_APP') as string;
            }
            message = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_PTZ_LOCKED_BY_ON_MACHINE') as string, ptzLockOwner, ptzLockOwnerApp ? ptzLockOwnerApp : '');
        } else if (ptzLockOwner !== null) {
            message = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_PTZ_LOCKED_BY') as string, ptzLockOwner);
        }
        return Promise.resolve(message);
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Privileges Section
    ///////////////////////////////////////////////////////////////////////////////////////
    /**
     *
     * @param entity the entity on which change detection is registered
     */
    private async checkForLockingStateInformation(entity: ICameraEntity): Promise<void> {
        if (entity.lockingUserId.equals(this.currentLockingUserId) && entity.lockingUserAppId.equals(this.currentLockingUserAppId)) {
            return;
        }

        this.currentLockingUserId = entity.lockingUserId;
        this.currentLockingUserAppId = entity.lockingUserAppId;
        // Fetch them as one
        const lockingStateInfo = await this.getPtzLockingStateInformation(entity as CameraEntity);

        // Put the lockingInformation in the Subject
        this.lockingStateInformationSubject.next(lockingStateInfo);
    }

    private isPtzServiceProperlyInitialized(): boolean {
        if (this.iscontentReceived === false) {
            return false;
        } else {
            return true;
        }
    }

    private async getCameraEntityFromCache(guid: IGuid): Promise<CameraEntity | null> {
        return this.entityCacheTask.getEntityAsync(CameraEntity, guid);
    }

    private registerPlayerModeChangedCallback(): void {
        this.videoPlayer?.onPlayerModeChanged.register((event: PlayerModeChangeEvent) => {
            this.playerModeSubject.next(event.playerMode);
        });
    }
}
