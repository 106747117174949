// Import the core angular services.
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NgZone } from '@angular/core';
import { SafeGuid, IGuid } from 'safeguid';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { WebAppClient } from 'WebClient/WebAppClient';
import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WINDOW } from '@utilities/common-helper';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import {
    CommandBindings,
    CommandsService,
    CommandSubscriptionOptions,
    CommandsSubscription,
    CommandEntry,
    CommandProvider,
    CommandContext,
    CommandDisplay,
    ExecuteCommandData,
    CommandRequirements,
    FEATURES_SERVICE,
} from '../../interfaces/plugins/public/plugin-services-public.interface';
import { PluginCommand } from '../../interfaces/plugins/public/plugin-public.interface';
import { SubscriptionCollection } from '../../utilities/subscription-collection';
import { FeaturesService } from '../features/features.service';
import { CommandsUsageManager } from './commands-usage/commands-usage-manager';
import { CommandsUsage } from './commands-usage/commands-usage';
import { CommandsUsageCollection } from './commands-usage/commands-usage-collection';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
// Based on Ban Naddel's code
// https://www.bennadel.com/blog/3382-handling-global-keyboard-shortcuts-using-priority-and-terminality-in-angular-5-0-5.htm

interface Listener {
    priority: number;
    inputs: boolean;
    bindings: CommandBindings;
}

interface NormalizedKeys {
    [key: string]: string;
}

interface ValidationParameters {
    skipCommandValidation?: boolean;
    activeTargetOverride?: Element;
    commandContext?: CommandContext;
}

export interface ContextMenuHandler {
    isContextMenuOpen: boolean;
    onContextMenuShow$: Observable<void>;
    onContextMenuHide$: Observable<void>;
    showContextMenu(event: MouseEvent, menuItems?: ContextMenuItem[], reference?: ElementRef | null): boolean;
    hideContextMenu(): void;
}

// --------------------------

// Map to normalized keys across different browser implementations.
// --
// https://github.com/angular/angular/blob/5.0.5/packages/platform-browser/src/browser/browser_adapter.ts#L25-L42
const KEY_MAP: { [k: string]: string } = {
    '\b': 'Backspace',
    '\t': 'Tab',
    '\x7F': 'Delete',
    '\x1B': 'Escape',
    Del: 'Delete',
    Esc: 'Escape',
    Left: 'ArrowLeft',
    Right: 'ArrowRight',
    Up: 'ArrowUp',
    Down: 'ArrowDown',
    Menu: 'ContextMenu',
    Scroll: 'ScrollLock',
    Win: 'OS',
    ' ': 'Space',
    '.': 'Dot',
};

// NOTE: These will only be applied after the key has been lower-cased. As such, both the
// alias and the final value (in this mapping) should also be lower-case.
const KEY_ALIAS: { [k: string]: string } = {
    command: 'meta',
    ctrl: 'control',
    del: 'delete',
    down: 'arrowdown',
    esc: 'escape',
    left: 'arrowleft',
    right: 'arrowright',
    up: 'arrowup',
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class InternalCommandsService implements CommandsService, OnDestroy {
    // determine the currently active element for commands having a target (like tiles)
    public activeTarget: Element | undefined;
    public get isContextMenuOpen(): boolean {
        return this.contextMenuHandler?.isContextMenuOpen || false;
    }

    public onContextMenuShow$: Observable<void>;
    public onContextMenuHide$: Observable<void>;
    private onContextMenuShowSubject: Subject<void> = new Subject<void>();
    private onContextMenuHideSubject: Subject<void> = new Subject<void>();
    private activeCommandsUsages = new CommandsUsageCollection();
    private activeContextMenuCommandsUsages = new CommandsUsageCollection();
    private commandProviders: CommandProvider[] = [];
    private commands = SafeGuid.createMap<PluginCommand>();
    private commandsUsageManager = new CommandsUsageManager(this);
    private contextMenuHandler!: ContextMenuHandler;
    private knownShortcutCommands = SafeGuid.createMap<string>();
    private listeners: Listener[];
    private normalizedKeys: NormalizedKeys = {};
    private scClient: WebAppClient;
    private zone: NgZone;
    private additionnalRequirements = SafeGuid.createMap<CommandRequirements[]>();
    private subscriptions = new SubscriptionCollection();
    private commandHandlerSubscriptions = new SubscriptionCollection();

    // Initialize the keyboard shortcuts service.
    constructor(
        zone: NgZone,
        securityCenterClientService: SecurityCenterClientService,
        @Inject(FEATURES_SERVICE) private featuresService: FeaturesService,
        @Inject(WINDOW) window: Window
    ) {
        this.scClient = securityCenterClientService?.scClient;

        this.onContextMenuShow$ = this.onContextMenuShowSubject.asObservable();
        this.onContextMenuHide$ = this.onContextMenuHideSubject.asObservable();

        if (this.scClient) {
            this.subscriptions.add(this.scClient.onLogonStateChanged((args) => this.onLogonStateChanged(args)));
        }
        this.zone = zone;

        this.listeners = [];

        // Since we're going to create a root event-handler for the keydown event, we're
        // gonna do this outside of the NgZone. This way, we're not constantly triggering
        // change-detection for every key event - we'll only re-enter the Angular Zone
        // when we have an event that is actually being consumed by one of our components.
        this.zone.runOutsideAngular((): void => {
            window.addEventListener('keydown', (event) => this.handleKeyboardEvent(event));
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribeAll();
    }

    public addActiveCommandsUsages(commandsUsages: CommandsUsage[] | CommandsUsageCollection): void {
        this.activeCommandsUsages.addUsages(commandsUsages);
    }

    public appendRequirements(commandId: IGuid, commandRequirements: CommandRequirements): void {
        const currentRequirements = this.additionnalRequirements.get(commandId) ?? [];
        currentRequirements.push(commandRequirements);
        this.additionnalRequirements.set(commandId, currentRequirements);
    }

    public getAdditionnalRequirements(commandId: IGuid): CommandRequirements[] {
        return this.additionnalRequirements.get(commandId) ?? [];
    }

    public async canExecuteCommandAsync(commandId: IGuid, commandContext?: CommandContext): Promise<boolean> {
        for (const listener of this.listeners) {
            const commandEntry = listener.bindings.getCommand(commandId);
            if (commandEntry && (await this.internalCanExecuteCommandAsync(commandEntry, commandContext))) {
                return true;
            }
        }
        return false;
    }

    public executeCommand(commandId: IGuid, commandContext?: CommandContext, event?: Event, targetElement?: Element): void {
        this.internalExecuteCommandAsync(commandId, false, commandContext, event, targetElement).fireAndForget();
    }

    public forceExecuteCommand(commandId: IGuid, commandContext?: CommandContext, event?: Event, targetElement?: Element): void {
        this.internalExecuteCommandAsync(commandId, true, commandContext, event, targetElement).fireAndForget();
    }

    public async getAvailableCommandsAsync(contextArgs: CommandContext): Promise<PluginCommand[]> {
        const commandIds = SafeGuid.createSet();
        if (contextArgs) {
            for (const provider of await this.getActiveCommandProvidersAsync()) {
                const supportedCommands = await provider.getAvailableCommandIdsAsync(contextArgs);
                supportedCommands.forEach((commandId) => {
                    commandIds.add(commandId);
                });
            }
        }
        const pluginCommands = await this.getCommandsAsync(Array.from(commandIds), true, contextArgs);
        return pluginCommands;
    }

    public async getCommandAsync(commandId: IGuid, executable?: boolean, commandContext?: CommandContext): Promise<PluginCommand | undefined> {
        const commands = await this.getCommandsAsync([commandId], executable, commandContext);

        if (commands?.length > 0) {
            return commands[0];
        }
        return undefined;
    }

    public async getCommandDisplayAsync(commandId: IGuid, commandContext?: CommandContext): Promise<CommandDisplay | undefined> {
        for (const listener of this.listeners) {
            const commandEntry = listener.bindings.getCommand(commandId);

            if (commandEntry) {
                const commandDisplay = await this.internalGetCommandDisplayAsync(commandEntry, commandContext);
                if (commandDisplay) {
                    return commandDisplay;
                }
            }
        }

        const command = await this.getCommandAsync(commandId);
        if (command) {
            return { name: command.name, icon: command.icon, tooltip: command.tooltip };
        }
    }

    public async getCommandsAsync(commandIds: IGuid[], executable?: boolean, commandContext?: CommandContext, activeTargetOverriden?: Element): Promise<PluginCommand[]> {
        const result: PluginCommand[] = [];
        for (const commandId of commandIds) {
            const command = this.commands.get(commandId);
            if (command) {
                result.push(command);
            }
        }

        if (!executable) {
            return result;
        }

        const filteredCommands: PluginCommand[] = [];
        for (const command of result) {
            if (await this.isAnyAvailableAsync(command.id, { commandContext, activeTargetOverride: activeTargetOverriden })) {
                filteredCommands.push(command);
            }
        }
        return filteredCommands;
    }

    public getCommandShortcut(commandId: IGuid): string | undefined {
        return this.knownShortcutCommands.get(commandId);
    }

    public invalidateCanExecuteForAllCommandsUsage(commandId: IGuid, canExecute?: boolean): void {
        this.commandsUsageManager.invalidateCanExecuteForAllCommandsUsage(commandId, canExecute);
    }

    public async getCommandsUsage(commandContext: CommandContext, specificCommandIds?: IGuid[], onlyUserSpecificCommands = false, targetElement?: Element): Promise<CommandsUsage> {
        return await this.commandsUsageManager.createUsageAsync(
            this.scClient,
            await this.getActiveCommandProvidersAsync(),
            commandContext,
            specificCommandIds,
            onlyUserSpecificCommands,
            targetElement
        );
    }

    // add the provider to the internal collection in DESCENDING priority order.
    public registerCommandProvider(commandProvider: CommandProvider): void {
        this.commandProviders.push(commandProvider);
        this.commandProviders.sort(this.compareByPriority.bind(this));
    }

    public registerCommands(commands: PluginCommand[]): void {
        if (commands) {
            for (const command of commands) {
                this.commands.set(command.id, command);
                if (command.keyBinding) {
                    this.knownShortcutCommands.set(command.id, command.keyBinding);
                }
            }
        }
    }

    public registerCommandShortcut(commandId: IGuid, shortcut: string): void {
        this.knownShortcutCommands.set(commandId, shortcut);
    }

    public registerContextMenuHandler(handler: ContextMenuHandler): void {
        if (this.contextMenuHandler) {
            this.commandHandlerSubscriptions.unsubscribeAll();
        }
        this.contextMenuHandler = handler; // only one can be set
        this.commandHandlerSubscriptions.add(
            this.contextMenuHandler.onContextMenuShow$.pipe(untilDestroyed(this)).subscribe(() => {
                this.onContextMenuShowSubject.next.bind(this);
            }),
            this.contextMenuHandler.onContextMenuHide$.pipe(untilDestroyed(this)).subscribe(() => {
                this.onContextMenuHideSubject.next();
            })
        );
    }

    public removeActiveCommandsUsages(commandsUsages: CommandsUsage[] | CommandsUsageCollection): void {
        this.activeCommandsUsages.removeUsages(commandsUsages);
    }

    public async showContextMenuAsync(event: MouseEvent, contextArgs?: CommandContext, commandsUsages?: CommandsUsage[] | CommandsUsageCollection): Promise<boolean> {
        this.clearActiveContextMenuCommandsUsage();

        if (commandsUsages) {
            this.activeContextMenuCommandsUsages.addUsages(commandsUsages);
        } else if (contextArgs) {
            this.activeContextMenuCommandsUsages.addUsage(
                await this.getCommandsUsage(contextArgs, undefined, undefined, event.target instanceof Element ? event.target : undefined)
            );
        }

        return this.showContextMenu(event, await this.activeContextMenuCommandsUsages.createContextMenuItemsAsync());
    }

    public showContextMenu(mouseEvent: MouseEvent, menuItems: ContextMenuItem[], reference?: ElementRef | null): boolean {
        if (menuItems.length > 0) {
            return this.contextMenuHandler.showContextMenu(mouseEvent, menuItems, reference);
        }
        return false;
    }

    public hideCurrentContextMenu(): void {
        this.clearActiveContextMenuCommandsUsage();
    }

    // configure key-event listener at the given priority. Returns a subscription that can
    // be used to unbind the listener.
    public subscribe(bindings: CommandBindings, options: CommandSubscriptionOptions): CommandsSubscription {
        this.normalizeBindings(bindings);

        const listener = this.addListener({
            priority: options.priority,
            inputs: false,
            bindings,
        });

        return {
            unsubscribe: () => {
                this.removeListener(listener);
            },
        };
    }

    // add the listener to the internal collection in DESCENDING priority order.
    private addListener(listener: Listener): Listener {
        this.listeners.push(listener);
        this.listeners.sort(this.compareByPriority.bind(this));

        return listener;
    }

    private async canExecuteAsync(commandEntry: CommandEntry, validationParams: ValidationParameters): Promise<boolean> {
        return (
            this.isValidTargetForCommand(commandEntry, validationParams.activeTargetOverride) &&
            (validationParams.skipCommandValidation || (await this.internalCanExecuteCommandAsync(commandEntry, validationParams.commandContext)))
        );
    }

    private clearActiveContextMenuCommandsUsage(): void {
        this.activeContextMenuCommandsUsages.clear();
        this.contextMenuHandler.hideContextMenu();
    }

    private compareByPriority(a: Listener | CommandProvider, b: Listener | CommandProvider): number {
        // We want to sort in DESCENDING priority order so that the
        // higher-priority items are at the start of the collection - this will
        // make it easier to loop over later (highest priority first).
        if (a.priority < b.priority) {
            return 1;
        } else if (a.priority > b.priority) {
            return -1;
        } else {
            return 0;
        }
    }

    private async getActiveCommandProvidersAsync(): Promise<CommandProvider[]> {
        const features = await this.featuresService.getFeaturesAsync();
        return this.commandProviders.filter((commandProvider) => setEvery(commandProvider.requiredFeatures, (feature) => features.has(feature)));
    }

    // Get the normalized event-key from the given event.
    private getKeyFromEvent(event: KeyboardEvent): string {
        let key = event.key || 'Unidentified';

        if (key.startsWith('U+')) {
            key = String.fromCharCode(parseInt(key.slice(2), 16));
        }

        const parts = [KEY_MAP[key] || key];

        if (event.altKey) {
            parts.push('Alt');
        }
        if (event.ctrlKey) {
            parts.push('Control');
        }
        if (event.metaKey) {
            parts.push('Meta');
        }
        if (event.shiftKey) {
            parts.push('Shift');
        }

        return this.normalizeKey(parts.join('.')) ?? '';
    }

    // handle the keyboard events for the root handler (and delegate to the listeners).
    private async handleKeyboardEvent(event: KeyboardEvent): Promise<void> {
        const key = this.getKeyFromEvent(event);
        const isInputEvent = this.isEventFromInput(event);

        // Iterate over the listeners in DESCENDING priority order.
        for (const listener of this.listeners) {
            const bindings = listener.bindings;
            const commandEntry = bindings.getCommandFromKey(key);
            if (commandEntry) {
                let commandContext = commandEntry.targetData;
                let targetElement: Element | undefined;

                for (const commandUsage of this.activeCommandsUsages.allCommandUsages) {
                    if (commandUsage.id.equals(commandEntry.commandId)) {
                        commandContext = commandUsage.commandContext;
                        targetElement = commandUsage.targetElement;
                        break;
                    }
                }

                // if a target is specified, ensure it contains focus
                const canExecute = await this.canExecuteAsync(commandEntry, { commandContext, activeTargetOverride: targetElement });

                // Execute handler if this is NOT an input event that we need to ignore.
                if (canExecute && (!isInputEvent || listener.inputs)) {
                    // Right now, we're executing outside of the NgZone. As such, we
                    // have to re-enter the NgZone so that we can hook back into change-
                    // detection. Plus, this will also catch errors and propagate them
                    // through application properly.
                    const executeCommandData: ExecuteCommandData = { commandContext, event };
                    const isHandled = this.zone.runGuarded((): boolean | void | Promise<void> => {
                        try {
                            if (commandEntry.executeHandler) {
                                commandEntry.executeHandler(executeCommandData);
                                return executeCommandData.isHandled;
                            }
                        } catch (error) {}
                    });

                    // If the command was handled, we're going to treat this listener as Terminal
                    if (isHandled) {
                        executeCommandData.event?.stopPropagation();
                        executeCommandData.event?.preventDefault();
                        return;

                        // If the is marked as not handled, we're going to treat this listener as NOT Terminal
                    } else {
                        continue;
                    }
                }
            }
        } // END: For-loop.
    }

    private async internalCanExecuteCommandAsync(commandEntry: CommandEntry, args?: CommandContext): Promise<boolean> {
        return this.zone.runGuarded(async () => {
            try {
                const isAvailableHandler = commandEntry.isAvailableHandler;
                if (isAvailableHandler && !(await isAvailableHandler(args))) {
                    return false;
                }
                const canExecuteHandler = commandEntry.canExecuteHandler;
                if (canExecuteHandler) {
                    return await canExecuteHandler(args);
                }
            } catch (error) {
                return false;
            }
            return true;
        });
    }

    private async internalExecuteCommandAsync(
        commandId: IGuid,
        skipCommandValidation: boolean,
        commandContext?: CommandContext,
        event?: Event,
        targetElement?: Element
    ): Promise<void> {
        for (const listener of this.listeners) {
            const entry = listener.bindings.getCommand(commandId);
            if (entry?.executeHandler) {
                // if a target is specified, ensure it contains focus
                const canExecute = await this.canExecuteAsync(entry, { skipCommandValidation, commandContext, activeTargetOverride: targetElement });
                if (canExecute) {
                    try {
                        const executeCommandData: ExecuteCommandData = { commandContext, event };
                        entry.executeHandler(executeCommandData);
                        if (executeCommandData.isHandled) {
                            executeCommandData.event?.stopPropagation();
                            executeCommandData.event?.preventDefault();
                            return;
                        }
                    } catch (error) {}
                }
            }
        }
    }

    private async internalGetCommandDisplayAsync(commandEntry: CommandEntry, commandContext?: CommandContext): Promise<CommandDisplay | undefined> {
        return this.zone.runGuarded(async () => {
            const displayHandler = commandEntry.getDisplayHandler;
            if (displayHandler) {
                try {
                    return await displayHandler(commandContext);
                } catch (error) {
                    return;
                }
            }
            return;
        });
    }

    private async internalIsCommandAvailableAsync(commandEntry: CommandEntry, commandContext?: CommandContext): Promise<boolean> {
        return this.zone.runGuarded(async () => {
            try {
                const isAvailableHandler = commandEntry.isAvailableHandler;
                if (isAvailableHandler && !(await isAvailableHandler(commandContext))) {
                    return false;
                }
            } catch (error) {
                return false;
            }
            return true;
        });
    }

    private async isAnyAvailableAsync(commandId: IGuid, validationParams: ValidationParameters): Promise<boolean> {
        for (const listener of this.listeners) {
            const commandEntry = listener.bindings.getCommand(commandId);
            if (commandEntry && (await this.isAvailableAsync(commandEntry, validationParams))) {
                return true;
            }
        }
        return false;
    }

    private async isAvailableAsync(commandEntry: CommandEntry, validationParams: ValidationParameters): Promise<boolean> {
        return (
            this.isValidTargetForCommand(commandEntry, validationParams.activeTargetOverride) &&
            (validationParams.skipCommandValidation || (await this.internalIsCommandAvailableAsync(commandEntry, validationParams.commandContext)))
        );
    }

    // determine if the given event originated from a form input element.
    private isEventFromInput(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return true;
                default:
                    return false;
            }
        }

        return false;
    }

    private isValidTargetForCommand(commandEntry: CommandEntry, activeTargetOverriden?: Element): boolean {
        const activeTarget = activeTargetOverriden ? activeTargetOverriden : this.activeTarget;

        // if a target is specified, ensure it contains focus
        const commandTarget = commandEntry.target;
        if (commandTarget) {
            if (!activeTarget) {
                return false;
            }
            return activeTarget === commandTarget || activeTarget.contains(commandTarget);
        }
        return true;
    }

    // modify a bindings collection in which the keys of the given bindings have been
    // normalized into a predictable format.
    private normalizeBindings(bindings: CommandBindings): void {
        const entries = bindings.getCommands();
        for (const entry of entries) {
            const shortcut = this.getCommandShortcut(entry.commandId);
            const normalizedKey = this.normalizeKey(shortcut);
            if (normalizedKey) {
                bindings.assignKey(entry.commandId, normalizedKey);
            }
        }
    }

    // return the given key in a normalized, predictable format.
    private normalizeKey(key: string | undefined): string | undefined {
        if (!key) {
            return undefined;
        }

        if (!this.normalizedKeys[key]) {
            this.normalizedKeys[key] = key
                .toLowerCase()
                .split('.')
                .map((segment): string => {
                    return KEY_ALIAS[segment] || segment;
                })
                .sort()
                .join('.');
        }

        return this.normalizedKeys[key];
    }

    private onLogonStateChanged(args: LogonStateChangedArgs): void {
        if (!args.loggedOn()) {
            this.activeCommandsUsages.clear();
            this.clearActiveContextMenuCommandsUsage();
            this.contextMenuHandler.hideContextMenu();
        }
    }

    // remove the given listener from the internal collection.
    private removeListener(listenerToRemove: Listener): void {
        this.listeners = this.listeners.filter((listener: Listener): boolean => {
            return listener !== listenerToRemove;
        });
    }
}
