import { Constants } from '../utilities/constants';
/** Store the analytic name for a custom event in some constants under a specific module */
export const AnalyticsNames = Constants({
    Investigate: {
        ReportGenerated: 'Unified Report Generated',
        SelectedColumns: 'Unified Report Selected Columns',
    },
    Shared: {
        EventMonitoring: 'Event Monitoring',
        ThreatLevels: 'Threat Levels',
    },
    SendFeedback: {
        DialogOpened: 'Send feedback dialog opened',
        DialogClosedSuccess: 'Send feedback dialog closed [SENT]',
        DialogClosedCancel: 'Send feedback dialog closed [CANCELED]',
        SubmitFailed: 'Send feedback submit failed [INVALID FORM]',
    },
});
