import { EventFilter } from '../Connection/EventFilter';
import * as Args from '../Connection/RestArgs';
import { RestConnection } from '../Connection/RestConnection';
import { ConnectionState } from '../Connection/Streamers/ConnectionState';
import * as Helpers from '../Helpers/Helpers';
import { LogonState, LogonStateChangedArgs } from './Args/LogonStateChangedArgs';
import { AlarmEntity } from './Model/AlarmEntity';
import { Entity } from './Model/Entity';
import { RestObject } from './Model/RestObject';
import { LogonInfo } from './Parameters/LogonInfo';
import { RestResponse, RestResponseError } from '../Connection/RestResponse';
import { IRestResponse } from '../Client/Interface/IRestResponse';
import { EntityQuery } from './Queries/EntityQuery';
import { FieldList } from '../Helpers/FieldList';
import { RestTransaction } from '../Connection/RestTransaction';
import { EntityTypes } from './Enumerations/EntityTypes';
import { SystemConfigurationEntity } from './Model/SystemConfigurationEntity';
import { Transaction } from './Transaction';
import { UserTokenData } from './Parameters/UserTokenData';
import { ApplicationType } from './Enumerations/ApplicationType';
import HttpStatusCode from './Enumerations/HttpStatusCode';
import { EntityCacheTask } from './Operations/EntityCacheTask';
import { ActionBase } from './Event/ActionBase';
import { FieldObject } from '../Helpers/FieldObject';
import { CurrentUserInfo } from './Parameters/CurrentUserInfo';
import { UserEntity } from './Model/UserEntity';
import { UserGroupEntity } from './Model/UserGroupEntity';
import { ServerEntity } from './Model/ServerEntity';
import { AreaEntity } from './Model/AreaEntity';
import { CameraEntity } from './Model/Video/CameraEntity';
import { RoleEntity } from './Model/Roles/RoleEntity';
import { AgentEntity } from './Model/AgentEntity';
import { ZoneEntity } from './Model/AccessControl/ZoneEntity';
import { TilePluginEntity } from './Model/TilePluginEntity';
import { ThreatLevelEntity } from './Model/ThreatLevelEntity';
import { ScheduleEntity } from './Model/ScheduleEntity';
import { PartitionEntity } from './Model/PartitionEntity';
import { RouteEntity } from './Model/RouteEntity';
import { NetworkEntity } from './Model/NetworkEntity';
import { MacroEntity } from './Model/MacroEntity';
import { IntrusionUnitEntity } from './Model/Intrusion/IntrusionUnitEntity';
import { IntrusionAreaEntity } from './Model/Intrusion/IntrusionAreaEntity';
import { DeviceEntity } from './Model/Devices/DeviceEntity';
import { CustomEntity } from './Model/CustomEntity';
import { ApplicationEntity } from './Model/Application/ApplicationEntity';
import { VideoUnitEntity } from './Model/Video/VideoUnitEntity';
import { TransferGroupEntity } from './Model/Video/TransferGroupEntity';
import { StreamEntity } from './Model/Video/StreamEntity';
import { SequenceEntity } from './Model/Video/SequenceEntity';
import { TileLayoutEntity } from './Model/TileLayoutEntity';
import { AccessControlUnitEntity } from './Model/AccessControl/AccessControlUnitEntity';
import { AccessPointEntity } from './Model/AccessControl/AccessPointEntity';
import { AccessPointRuleEntity } from './Model/AccessControl/AccessPointRuleEntity';
import { CardholderEntity } from './Model/AccessControl/CardholderEntity';
import { CardholderGroupEntity } from './Model/AccessControl/CardholderGroupEntity';
import { SecurityCache } from './Model/SecurityCache';
import { EventBase } from './Event/EventBase';
import { EventTypes } from './Enumerations/EventTypes';
import { AlarmTriggeredEvent } from './Event/AlarmTriggeredEvent';
import { AlarmAcknowledgedEvent } from './Event/AlarmAcknowledgedEvent';
import { AlarmInvestigatingEvent } from './Event/AlarmInvestigatingEvent';
import { AccessUnknownCredentialEvent } from './Event/AccessUnknownCredentialEvent';
import { AccessEvent } from './Event/AccessEvent';
import { ApplicationEvent } from './Event/ApplicationEvent';
import { CustomPayloadEvent } from './Event/CustomEvent';
import { LprReadEvent } from './Event/LprReadEvent';
import { LprHitEvent } from './Event/LprHitEvent';
import { UserEvent } from './Event/UserEvent';
import { BaseReport } from './Reports/BaseReport';
import { ReportResult } from './Reports/ReportResult';
import { DiagnosticManager } from './DiagnosticManager';
import { LogSeverity } from './Enumerations/LogSeverity';
import { Timer } from '../Helpers/Timer';
import { SafeGuid, IGuid } from 'safeguid';
import { EntityFields, IEntity } from './Interface/IEntity';
import { MultiEvent } from './Event/MultiEvent';
import { ModifiedIncidentEvent } from './Event/ModifiedIncidentEvent';
import { IRestObject } from './Interface/IRestObject';
import { IReportResult } from './Interface/IReportResult';
import { IEntityCacheTask } from './Interface/IEntityCacheTask';
import { ISecurityCenterClient } from './Interface/ISecurityCenterClient';
import { CardholderAntipassbackEvent } from './Event/CardholderAntipassbackEvent';
import { CardholderAntipassbackForgivenEvent } from './Event/CardholderAntipassbackForgivenEvent';
import { DoorEntity } from './Model/AccessControl/DoorEntity';
import { ITransaction } from './Interface/ITransaction';
import { CredentialEntity } from './Model/AccessControl/CredentialEntity';
import { CardholderAccessEvent } from './Event/CardholderAccessEvent';
import { CredentialStatusEvent } from './Event/CredentialStatusEvent';
import { AccessPointCredentialStatusEvent } from './Event/AccessPointCredentialStatusEvent';
import { RootEntitiesQuery } from './Queries/RootEntitiesPageQuery';
import { CardholderIdentityValidationEvent } from './Event/CardholderIdentityValidationEvent';
import { IdentityValidationEvent } from './Event/IdentityValidationEvent';
import { CardholderAccessGrantConfirmationRequestedEvent } from './Event/CardholderAccessGrantConfirmationRequestedEvent';
import { AccessGrantConfirmationRequestedEvent } from './Event/AccessGrantConfirmationRequestedEvent';
import { CancellationToken } from '../Helpers/Helpers';
import { Globals } from '../Globals';
import { Observable, Subject } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export class SecurityCenterClient implements ISecurityCenterClient {
    // #region Fields

    public debugAppName = 'RestClient';
    public allowImpersonate = false;
    public connectStreamer = true;
    public defaultFields: FieldList;
    public applicationType = ApplicationType.IntegrationService;
    public tokenValidity = 0;
    public requestedPrivileges: Array<string> = new Array<string>();
    public supportWebSocket = true;
    public supportSSE = true;

    public eventsReceived$: Observable<Args.EventReceivedArg[]>;

    protected _rest: RestConnection | null;
    protected _logonStateChangedDispatcher = new Helpers.EventDispatcher<LogonStateChangedArgs>();
    protected _logonState = LogonState.LoggedOff;
    protected _logonCancellationToken: Helpers.CancellationToken | null = null;
    protected _additionalHeaders = new Map<string, string>();
    protected _currentToken = '';
    protected _currentUserInfo: CurrentUserInfo | null = null;
    protected _wasLoggedOn = false;
    protected _entityTypeDic = new Map<string, new () => Entity>();
    protected _eventTypeDic = new Map<string, new () => any>();
    protected _tokenAppliedDispatcher = new Helpers.EventDispatcher<UserTokenData>();
    protected _entityInvalidatedDispatcher = new Helpers.EventDispatcher<Args.EntityInvalidatedArg>();
    protected _entityAddedDispatcher = new Helpers.EventDispatcher<Args.EntityAddedArg>();
    protected _entityRemovedDispatcher = new Helpers.EventDispatcher<Args.EntityRemovedArg>();
    protected _actionDispatcher = new Helpers.EventDispatcher<Args.ActionReceivedArg>();
    protected _requestDispatcher = new Helpers.EventDispatcher<Args.RequestReceivedArg>();
    protected _diagnosticManager: DiagnosticManager;
    protected _timerTokenRenewal: Timer;
    protected _securityCache: SecurityCache = new SecurityCache();

    private eventsReceivedSubject$ = new Subject<Args.EventReceivedArg[]>();
    private readonly renewTokenDelayBeforeEnd = 15 * 60 * 1000; // 15 min
    private disposing = false;

    // #endregion

    // #region Properties

    public get rest(): RestConnection {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }
        return this._rest;
    }

    public get currentLogonState(): LogonState {
        return this._logonState;
    }

    /**
     * Gets a buffered copy of the current user information class
     */
    public get currentUserInfo(): CurrentUserInfo | null {
        return this._currentUserInfo;
    }

    public get isLoggedOn(): boolean {
        if (this._logonState === LogonState.LoggedOn || this._logonState === LogonState.ReloggedOn) {
            return true;
        }
        return false;
    }

    public get diagnosticManager(): DiagnosticManager {
        return this._diagnosticManager;
    }

    // #endregion

    // #region Constructor, Dispose
    constructor() {
        this._rest = null;

        this._diagnosticManager = new DiagnosticManager(this);

        // Fill the entity type dictionary (toLowerCase()!!)
        this._entityTypeDic.set(EntityTypes.Alarms.toLowerCase(), AlarmEntity);
        this._entityTypeDic.set(EntityTypes.SystemConfigurations.toLowerCase(), SystemConfigurationEntity);
        this._entityTypeDic.set(EntityTypes.Users.toLowerCase(), UserEntity);
        this._entityTypeDic.set(EntityTypes.UserGroups.toLowerCase(), UserGroupEntity);
        this._entityTypeDic.set(EntityTypes.Servers.toLowerCase(), ServerEntity);
        this._entityTypeDic.set(EntityTypes.Areas.toLowerCase(), AreaEntity);
        this._entityTypeDic.set(EntityTypes.Cameras.toLowerCase(), CameraEntity);
        this._entityTypeDic.set(EntityTypes.Roles.toLowerCase(), RoleEntity);
        this._entityTypeDic.set(EntityTypes.Agents.toLowerCase(), AgentEntity);
        this._entityTypeDic.set(EntityTypes.Zones.toLowerCase(), ZoneEntity);
        this._entityTypeDic.set(EntityTypes.TilePlugins.toLowerCase(), TilePluginEntity);
        this._entityTypeDic.set(EntityTypes.ThreatLevels.toLowerCase(), ThreatLevelEntity);
        this._entityTypeDic.set(EntityTypes.Schedules.toLowerCase(), ScheduleEntity);
        this._entityTypeDic.set(EntityTypes.Routes.toLowerCase(), RouteEntity);
        this._entityTypeDic.set(EntityTypes.Partitions.toLowerCase(), PartitionEntity);
        this._entityTypeDic.set(EntityTypes.Networks.toLowerCase(), NetworkEntity);
        this._entityTypeDic.set(EntityTypes.Macros.toLowerCase(), MacroEntity);
        this._entityTypeDic.set(EntityTypes.IntrusionUnits.toLowerCase(), IntrusionUnitEntity);
        this._entityTypeDic.set(EntityTypes.IntrusionAreas.toLowerCase(), IntrusionAreaEntity);
        this._entityTypeDic.set(EntityTypes.Devices.toLowerCase(), DeviceEntity);
        this._entityTypeDic.set(EntityTypes.CustomEntities.toLowerCase(), CustomEntity);
        this._entityTypeDic.set(EntityTypes.Applications.toLowerCase(), ApplicationEntity);
        this._entityTypeDic.set(EntityTypes.VideoUnits.toLowerCase(), VideoUnitEntity);
        this._entityTypeDic.set(EntityTypes.TransferGroups.toLowerCase(), TransferGroupEntity);
        this._entityTypeDic.set(EntityTypes.Streams.toLowerCase(), StreamEntity);
        this._entityTypeDic.set(EntityTypes.Sequences.toLowerCase(), SequenceEntity);
        this._entityTypeDic.set(EntityTypes.TileLayouts.toLowerCase(), TileLayoutEntity);
        this._entityTypeDic.set(EntityTypes.Units.toLowerCase(), AccessControlUnitEntity);
        this._entityTypeDic.set(EntityTypes.Doors.toLowerCase(), DoorEntity);
        this._entityTypeDic.set(EntityTypes.AccessPoints.toLowerCase(), AccessPointEntity);
        this._entityTypeDic.set(EntityTypes.AccessRules.toLowerCase(), AccessPointRuleEntity);
        this._entityTypeDic.set(EntityTypes.Cardholders.toLowerCase(), CardholderEntity);
        this._entityTypeDic.set(EntityTypes.CardholderGroups.toLowerCase(), CardholderGroupEntity);
        this._entityTypeDic.set(EntityTypes.Credentials.toLowerCase(), CredentialEntity);

        this.registerEvents();

        this.defaultFields = new FieldList('id', 'name', 'entitytype'); // column to download by default in relations
        this._timerTokenRenewal = new Timer(() => this.onTimerRenewToken());

        this.eventsReceived$ = this.eventsReceivedSubject$.asObservable();
    }

    public async disposeAsync() {
        await this.logoffAsync();
        this._logonStateChangedDispatcher.clear();
        this._tokenAppliedDispatcher.clear();
        this._timerTokenRenewal.dispose();

        this.eventsReceivedSubject$.next();
        this.eventsReceivedSubject$.complete();
    }

    // #endregion

    // #region Public methods

    public async logonAsync(logonInfo: LogonInfo, cancelToken?: Helpers.CancellationToken): Promise<IRestResponse> {
        if (this._logonCancellationToken) {
            this._logonCancellationToken.cancelToken();
        }
        if (cancelToken) {
            this._logonCancellationToken = cancelToken;
        }

        await this.disposeRestConnectionAsync();
        this._rest = this.buildRestConnection(logonInfo.directory, null, null, logonInfo.authorizationHeader);
        this._currentToken = logonInfo.authorizationHeader;
        this._rest.appName = this.debugAppName;
        this._rest.allowImpersonate = this.allowImpersonate;

        this._rest.onEventReceived((arg) => this.onInternalEventReceived(arg));
        this._rest.onEntityInvalidated((arg) => this.onInternalEntityInvalidated(arg));
        this._rest.onEntityAdded((arg) => this.onInternalEntityAdded(arg));
        this._rest.onEntityRemoved((arg) => this.onInternalEntityRemoved(arg));
        this._rest.onConnectionStateChanged((arg) => this.onConnectionStateChanged(arg));
        this._rest.onActionReceived((arg) => this.onInternalActionReceived(arg));
        this._rest.onRequestReceived((arg) => this.onInternalRequestReceived(arg));
        this._rest.onDisconnectRequestReceived((arg) => this.onInternalDisconnectRequestReceived(arg));
        this._rest.onQueryReceived(() => this.onQueryReceived());
        this._diagnosticManager.newConnectionCreated();

        let response = await this._rest.waitForRestReadyAsync(this._logonCancellationToken);
        if (response.statusCode === HttpStatusCode.OK) {
            try {
                this._currentUserInfo = await this.getCurrentUserInfoAsync();
            } catch (exception: any) {
                const response = exception.response as RestResponse;
                if (response) {
                    return response;
                }
            }

            if (this.connectStreamer === true) {
                response = await this._rest.startStreamerAsync(this._logonCancellationToken);
            }
        }
        this._logonCancellationToken = null;
        return response;
    }

    public async logoffAsync(): Promise<void> {
        if (this._timerTokenRenewal != null) {
            this._timerTokenRenewal.change(-1);
        }
        if (this._rest != null) {
            this._rest.clearCurrentFitler();
        }
        return await this.disposeRestConnectionAsync();
    }

    public async changeExpiredPasswordAsync(url: string, username: string, oldPassword: string, newPassword: string, cancelToken?: CancellationToken): Promise<IRestResponse> {
        var newRest = this.buildRestConnection(url, username, oldPassword, null);

        if (this.debugAppName != null && this._rest != null) {
            this._rest.appName = this.debugAppName;
        }
        newRest.allowImpersonate = this.allowImpersonate;
        newRest.addAdditionalHeader(Globals.newAuthorizationHeader, RestConnection.buildBasicAuthorizationHeader(username, newPassword), false);
        let response: RestResponse;
        try {
            response = await newRest.executeRequestAsync('GET', '/v2/help', null, cancelToken);
            if (response.statusCode == HttpStatusCode.OK && this._rest != null) {
                this._rest.authorizationHeader = RestConnection.buildBasicAuthorizationHeader(username, newPassword);
            }
        } finally {
            void newRest.disposeAsync();
        }

        return response;
    }

    public buildEntityCache(fields?: string[], debounce?: number, maxDebouce?: number): IEntityCacheTask;
    public buildEntityCache(fields?: string, debounce?: number, maxDebouce?: number): IEntityCacheTask;
    public buildEntityCache(fields?: string | string[], debounce = 1000, maxDebouce = 10000): IEntityCacheTask {
        const downloadMask = fields ? fields.toString() : this.defaultFields.toString();
        return new EntityCacheTask(this, debounce, maxDebouce, downloadMask) as IEntityCacheTask;
    }

    public async setStreamerFilterAsync(filter: EventFilter): Promise<void> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }
        return await this._rest.setStreamerFilterAsync(filter);
    }

    public async addToStreamerFilterAsync(filter: EventFilter): Promise<IGuid> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }
        return await this._rest.addToStreamerFilterAsync(filter);
    }

    public async removeFromStreamerFilterAsync(filterId: IGuid): Promise<void> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }
        const lst = SafeGuid.createSet();
        lst.add(filterId);
        return await this._rest.removeFromStreamerFilterAsync(lst);
    }

    public async removeFromStreamerFiltersAsync(filterIds: Set<IGuid>): Promise<void> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }
        return await this._rest.removeFromStreamerFilterAsync(filterIds);
    }

    public addAdditionalHeader(headerKey: string, headerValue: string, normalize = true) {
        const value = normalize === true ? RestConnection.normalizeValue(headerValue) : headerValue;
        this._additionalHeaders.set(headerKey, value);
        if (this._rest !== null) {
            this._rest.addAdditionalHeader(headerKey, value);
        }
    }

    public async getAsync<T extends RestObject, U extends IRestObject>(
        classType: new () => T,
        identifier?: string | null,
        transaction?: Transaction | null,
        token?: Helpers.CancellationToken | null,
    ): Promise<U> {
        let objectUri = '';
        const ro = new classType();
        if (identifier === null || identifier === undefined) {
            objectUri = ro.baseUri + '?Fields=*';
        } else {
            objectUri = ro.baseUri + '/' + identifier + '?Fields=*';
        }

        if (!transaction) {
            if (ro.canGet === true) {
                if (this._rest == null) {
                    throw new Error('_rest is null');
                }

                const response = await this._rest.executeRequestAsync('GET', objectUri, null, new Helpers.CancellationToken());
                if (response.statusCode === HttpStatusCode.OK) {
                    if (!response.body) {
                        throw new Error('bodyObject is null');
                    }
                    ro.load(this, response.body);
                    return ro as unknown as U;
                }
                throw new RestResponseError('getAsync failed', response);
            } else {
                ro.load(this, {});
                return ro as unknown as U;
            }
        } else {
            const responseHandler = (response: IRestResponse) => {
                if (response.statusCode === HttpStatusCode.OK) {
                    if (!response.body) {
                        throw new Error('bodyObject is null');
                    }
                    ro.load(this, response.body);
                    return new Promise<U>(function (resolve) {
                        resolve(ro as unknown as U);
                    });
                }
                return new Promise<U>(function (resolve, reject) {
                    reject();
                });
            };
            transaction.addTransactionOperation(objectUri, 'GET', null, responseHandler);
            return new Promise<U>(function (resolve) {
                resolve(null as unknown as U);
            });
        }
    }

    public createLocalEntity<T extends Entity & U, U extends IEntity>(classType: new () => U, id: IGuid): U {
        const newEntity = this.createEntityModel(classType) as U;
        newEntity.setFieldGuid(EntityFields.idField, id);
        newEntity.load(this);
        return newEntity;
    }

    public async getEntityAsync<T extends Entity & U, U extends IEntity>(
        classType: new () => U,
        id: IGuid,
        transaction?: Transaction | null,
        token?: Helpers.CancellationToken | null,
        ...fields: string[]
    ): Promise<U | null> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }

        if (!transaction) {
            const transactionQuery = new EntityQuery();
            transactionQuery.guids.add(id);
            for (const arg of fields) {
                const fieldName = arg.toLowerCase().trim();
                transactionQuery.fields.add(fieldName);
            }

            const result = await this.getEntitiesAsync<T, U>(classType, transactionQuery, transaction, token);
            if (result != null && result.length > 0) {
                return result[0] as unknown as U;
            }
            return null;
        }

        const responseHandler = (response: IRestResponse) => {
            if (response.statusCode === HttpStatusCode.OK) {
                if (!response.body) {
                    throw new Error('bodyObject is null');
                }
                const scEntity = this.createEntityModel(classType, response.body);
                return new Promise<U | null>(function (resolve) {
                    resolve(scEntity);
                });
            }
            return new Promise<U | null>(function (resolve, reject) {
                reject();
            });
        };

        let query = 'v2/entities/' + id;
        if (fields != null) {
            const fieldList = new FieldList(...fields);
            query += '?Fields=' + fieldList.toString();
        }
        transaction.addTransactionOperation(query, 'GET', null, responseHandler);
        return new Promise<U | null>(function (resolve) {
            resolve(null);
        });
    }

    // TODO scale mode
    public async getEntitiesAsync<T extends Entity & U, U extends IEntity>(
        classType: new () => U,
        query: EntityQuery | RootEntitiesQuery,
        transaction?: Transaction | null,
        token?: Helpers.CancellationToken | null,
    ): Promise<Array<U>> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }

        const queryString = query.buildQuery();

        if (!transaction) {
            const response = await this._rest.executeRequestAsync('GET', queryString, null, token);
            if (response.statusCode === HttpStatusCode.OK) {
                const result = this.buildQueryResult(classType, response);
                const arrayResult = new Array<U>();
                for (const elem of result.values()) {
                    arrayResult.push(elem as unknown as U);
                }
                return arrayResult;
            }
            throw new RestResponseError('getEntitiesAsync failed', response);
        } else {
            const responseHandler = (response: RestResponse) => {
                const result = this.buildQueryResult(classType, response);
                return new Promise<Array<U>>(function (resolve) {
                    resolve(Array.from(result.values()));
                });
            };

            transaction.addTransactionOperation(queryString, 'GET', null, responseHandler);
            return new Array<U>();
        }
    }

    public async queryAsync<T extends ReportResult, U extends IReportResult>(classType: new () => T, query: BaseReport, token?: Helpers.CancellationToken | null): Promise<U> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }

        const queryString = query.buildQuery();
        let response: RestResponse | null = null;
        if (query.enableStreamedResponse) {
            response = await this._rest.getStreamedJsonAsync(queryString, 'GET', null, (x) => {
                const result = new classType();
                result.load(x);
                query.fireSubResult(result);
                // if (sendActivityReport) {
                //    reportResult.Add(result);
                // }
                return;
            });
            if (response.statusCode === HttpStatusCode.OK) {
                // await HandleActityReportAsync(query, reportResult).ConfigureAwait(false);
                return new classType() as unknown as U;
            }
        } else {
            response = await this._rest.executeRequestAsync('GET', queryString, null, token);
            if (response.statusCode === HttpStatusCode.OK) {
                const result = new classType();
                result.load(response.body);
                // if (sendActivityReport) {
                //    await HandleActityReportAsync(query, new List<ReportResult>() { result }).ConfigureAwait(false);
                // }
                return result as unknown as U;
            }
        }
        if (response == null) {
            throw new Error('response is null');
        }
        throw new RestResponseError('queryAsync failed', response);
    }

    public async createAsync(entity?: IEntity, entities?: Set<IEntity>) {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }

        if (!entities) {
            entities = new Set<IEntity>();
        }

        if (entity) {
            entities.add(entity);
        }

        let transaction = new RestTransaction(this._rest);

        // Create them
        for (const e of entities) {
            const body = e.getCreateBody();
            if (body != null) {
                transaction.addTransactionOperation('v2/Entities', 'POST', body, e);
            }
        }

        const result = await transaction.executeTransactionAsync();
        if (result.statusCode !== HttpStatusCode.OK) {
            throw new Error(result.toString());
        }

        // Get their Id
        transaction = new RestTransaction(this._rest);
        const ids = new Set<string>();
        for (const trans of result.transactionResult) {
            if (trans.result) {
                ids.add(trans.result['Id']);
                const restEntity = trans.context as IEntity;
                transaction.addTransactionOperation('v2/Entities/' + trans.result['Id'], 'GET', null, restEntity);
            }
        }

        // Load them back
        const result2 = await transaction.executeTransactionAsync();
        if (result2.statusCode !== HttpStatusCode.OK) {
            throw new Error(result2.toString());
        }
        for (const trans of result2.transactionResult) {
            if (trans.result) {
                const restEntity = trans.context as IEntity;
                restEntity.load(this, trans.result);
                restEntity.securityCache = this._securityCache;
            }
        }

        // Execute any pending operation
        transaction = new RestTransaction(this._rest);
        for (const e of entities) {
            e.addPendingOperation(transaction);
        }
        const result3 = await transaction.executeTransactionAsync();
        if (result3.statusCode !== HttpStatusCode.OK) {
            throw new Error(result3.toString());
        }

        this.resetDirty(entities);
        return ids;
    }

    public async updateAsync(entity?: IRestObject | null, entities?: Set<IRestObject> | null, transaction?: ITransaction | null): Promise<IRestResponse> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }

        if (!entities) {
            entities = new Set<IRestObject>();
        }

        if (entity) {
            entities.add(entity);
        }

        let response = new RestResponse();

        if (!transaction || !transaction.restTransaction) {
            const t = new RestTransaction(this._rest);
            for (const e of entities) {
                const body = e.getUpdateBody();
                if (body != null) {
                    t.addTransactionOperation(e.baseUri, 'PUT', body);
                }
                e.addPendingOperation(t);
            }
            response = await t.executeTransactionAsync();
            if (response.statusCode !== HttpStatusCode.OK) {
                throw new RestResponseError('updateAsync failed', response);
            }
            this.resetDirty(entities);
        } else {
            // already a transaction there
            for (const e of entities) {
                const responseHandler = (rep: IRestResponse) => {
                    e.isDirty = false;
                    return new Promise<IRestResponse>(function (resolve) {
                        resolve(rep);
                    });
                };

                let body = e.getUpdateBody();
                if (body == null) {
                    body = {}; // because we want to reset the dirty
                }

                transaction.addTransactionOperation(e.baseUri, 'PUT', body, responseHandler);
                e.addPendingOperation(transaction.restTransaction);
            }
            response = new RestResponse(HttpStatusCode.OK);
        }
        return response;
    }

    public createEntityModel<T extends IEntity>(classType: new () => T, config?: { [k: string]: any }): T | null {
        let instance: T;
        let entityType: string | undefined;
        if (config) {
            entityType = config[EntityFields.entityTypeField];
        }

        const isValidEntityType = typeof entityType === 'string';
        instance = this.createEntityClass(classType, isValidEntityType ? entityType : undefined);

        if (entityType && isValidEntityType && instance.hasField(EntityFields.entityTypeField) && instance.entityType.toLowerCase() !== entityType.toLowerCase()) {
            return null;
        }

        if (config) {
            instance.load(this, config);
            instance.securityCache = this._securityCache;
        }
        return instance;
    }

    public createTransaction(): Transaction {
        return new Transaction(this);
    }

    public async deleteAsync(id?: IGuid | null, ids?: Set<IGuid> | null, transaction?: Transaction | null): Promise<IRestResponse> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }

        if (!ids) {
            ids = SafeGuid.createSet();
        }

        if (id) {
            ids.add(id);
        }

        let response = new RestResponse();

        if (!transaction) {
            const t = new RestTransaction(this._rest);
            for (const elem of ids) {
                t.addTransactionOperation('v2/Entities/' + elem, 'DELETE', null);
            }
            response = await t.executeTransactionAsync();
            if (response.statusCode !== HttpStatusCode.OK) {
                throw new RestResponseError('deleteAsync failed', response);
            }
        } else {
            // already a transaction there
            for (const elem of ids) {
                const responseHandler = (rep: RestResponse) => {
                    return new Promise<RestResponse>(function (resolve) {
                        resolve(rep);
                    });
                };
                transaction.addTransactionOperation('v2/Entities/' + elem, 'DELETE', null, responseHandler);
            }
            response.statusCode = HttpStatusCode.OK;
        }
        return response;
    }

    public async renewTokenAsync() {
        // -1 indicates that the server will put us a duration for us
        const toKenDuration = this.tokenValidity === 0 ? -1 : this.tokenValidity;
        const token = await this.getUserToken(this._currentToken, this.applicationType, this.requestedPrivileges, toKenDuration);
        await this.applyTokenAsync(token);
    }

    public async getUserToken(authentificationHeader: string, applicationType: string, privs: Array<string>, duration: number): Promise<UserTokenData> {
        if (this._rest == null) {
            throw new Error('_rest is null');
        }

        const restConnection = this.buildRestConnection(this._rest.restServerUrl, null, null, authentificationHeader);

        restConnection.allowImpersonate = false; // make sure it is off
        restConnection.appName = this.debugAppName;

        let request = 'v2/Tokens/webtoken?ApplicationType=' + applicationType;
        if (privs != null && privs.length > 0) {
            let sb = '&Privileges=';
            let first = false;
            for (const priv of privs) {
                if (first === false) {
                    sb += priv;
                } else {
                    sb += ',' + priv;
                }
                first = true;
            }
            request += sb;
        }

        if (duration !== -1) {
            request += '&Duration=' + duration;
        }

        try {
            const response = await restConnection.executeRequestAsync('GET', request, null);
            if (response.statusCode === HttpStatusCode.OK) {
                if (!response.body) {
                    throw new Error('body is null');
                }
                return this.readUserTokenData(response);
            }
            throw new RestResponseError('RenewToken fails', response);
        } finally {
            void restConnection.disposeAsync();
        }
    }

    protected readUserTokenData(response: RestResponse) {
        const result = new UserTokenData();
        result.token = response.body['Token'];
        result.validUntil = new Date(response.body['ValidUntil']);
        result.millisecondsValid = response.body['MillisecondsValid'];
        return result;
    }

    public async getCurrentUserInfoAsync(authentificationHeader?: string): Promise<CurrentUserInfo> {
        let response: RestResponse;

        if (!this._rest) {
            throw new Error('Not Connected');
        }

        if (!authentificationHeader) {
            response = await this._rest.executeRequestAsync('GET', 'v2/Entities/users/currentuserinfo', null);
        } else {
            const body = new FieldObject();
            body.setField('authentification', authentificationHeader);
            response = await this._rest.executeRequestAsync('POST', 'v2/Entities/users/currentuserinfo', body.toString());
        }

        if (response.statusCode === HttpStatusCode.OK) {
            const result = new CurrentUserInfo();
            result.id = SafeGuid.parse(response.body.UserGuid.toLowerCase());
            result.directoryId = SafeGuid.parse(response.body.DirectoryGuid.toLowerCase());
            result.isAdministrator = response.body.IsAdministrator;
            result.name = response.body.UserName;
            return result;
        }
        throw new RestResponseError('getCurrentUserInfoAsync failed', response);
    }

    public registerAdditionalEventTypes(typeName: string, type: new () => any) {
        this._eventTypeDic.set(typeName, type);
    }

    // #endregion

    // #region Event handling methods

    public onLogonStateChanged(handler: Helpers.Handler<LogonStateChangedArgs>) {
        return this._logonStateChangedDispatcher.subscribe(handler);
    }

    public onTokenApplied(handler: Helpers.Handler<UserTokenData>) {
        return this._tokenAppliedDispatcher.subscribe(handler);
    }

    public onEntityInvalidated(handler: Helpers.Handler<Args.EntityInvalidatedArg>) {
        return this._entityInvalidatedDispatcher.subscribe(handler);
    }

    public onEntityAdded(handler: Helpers.Handler<Args.EntityAddedArg>) {
        return this._entityAddedDispatcher.subscribe(handler);
    }

    public onEntityRemoved(handler: Helpers.Handler<Args.EntityRemovedArg>) {
        return this._entityRemovedDispatcher.subscribe(handler);
    }

    public onEventReceived(handler: Helpers.Handler<Args.EventReceivedArg>): () => void {
        const subscription = this.eventsReceived$.pipe(concatMap((events) => events)).subscribe((event) => handler(event));
        return () => subscription.unsubscribe();
    }

    public onActionReceived(handler: Helpers.Handler<Args.ActionReceivedArg>) {
        return this._actionDispatcher.subscribe(handler);
    }

    public onRequestReceived(handler: Helpers.Handler<Args.RequestReceivedArg>) {
        return this._requestDispatcher.subscribe(handler);
    }

    // #endregion

    // #region Protected

    protected async applyTokenAsync(token: UserTokenData): Promise<void> {
        if (this._rest != null) {
            // only set the token if it is a valid one
            this._currentToken = token.getAuthentificationHeader();
            this._rest.authorizationHeader = this._currentToken;
            this.startValidityProcess(token.validUntil);
            await this.afterTokenAppliedAsync();
            this._tokenAppliedDispatcher.dispatch(token);
        }
    }

    protected afterTokenAppliedAsync(): Promise<void> {
        return new Promise<void>(function (resolve) {
            resolve();
        });
    }

    protected buildRestConnection(url: string, username: string | null, password: string | null, authToken: string | null): RestConnection {
        url = this.validateUrl(url);

        const newConnection = new RestConnection(this.supportWebSocket, this.supportSSE);
        newConnection.setupConnection(url, username, password, authToken);

        if (this._rest != null) {
            newConnection.retryCount = this._rest.retryCount;
            newConnection.retryDelay = this._rest.retryDelay;
        }

        for (const [key, value] of this._additionalHeaders) {
            newConnection.addAdditionalHeader(key, value, false);
        }
        return newConnection;
    }

    protected validateUrl(url: string): string {
        // Ensure we supports http (non-ssl)
        const dir = url.toLowerCase();
        if (!dir.startsWith('http://') && !dir.startsWith('https://')) {
            url = 'https://' + dir;
        }
        if (url.endsWith('/')) {
            url = url.slice(0, -1);
        }
        return url;
    }

    protected onInternalEventReceived(e: FieldObject) {
        if (e.hasField('ActionType')) {
            const action = new ActionBase(e);
            const e2 = new Args.ActionReceivedArg(e);
            this._actionDispatcher.dispatch(e2);
        } else {
            let evt: EventBase;
            if (this._eventTypeDic.has(e.getField<string>('EventType'))) {
                const activator = this._eventTypeDic.get(e.getField<string>('EventType'));
                if (!activator) {
                    throw new Error('activator is undefined!');
                }
                evt = new activator();
            } else {
                evt = new EventBase();
            }
            evt.loadFrom(e);
            if (evt.eventType === 'MultiEvent') {
                const multiEvent = evt as MultiEvent;

                if (multiEvent) {
                    this.fireMultiEvents(multiEvent);
                }

                return; // only send events inside the mutli event, not the multi event itself
            }

            const eventArg = new Args.EventReceivedArg(evt);
            this.eventsReceivedSubject$.next([eventArg]);
        }
    }

    protected onInternalActionReceived(e: FieldObject) {
        this._actionDispatcher.dispatch(new Args.ActionReceivedArg(e));
    }

    protected onInternalEntityInvalidated(obj: Args.EntityInvalidatedArg) {
        this._entityInvalidatedDispatcher.dispatch(obj);
    }

    protected onInternalEntityAdded(obj: Args.EntityAddedArg) {
        this._entityAddedDispatcher.dispatch(obj);
    }

    protected onInternalEntityRemoved(obj: Args.EntityRemovedArg) {
        this._entityRemovedDispatcher.dispatch(obj);
    }

    protected onConnectionStateChanged(e: Args.StateChangeArg) {
        try {
            if (e.state === ConnectionState.Connected) {
                this._wasLoggedOn = true;

                if (this.currentLogonState === LogonState.ReloggingOn) {
                    this.fireLogonStateChange(new LogonStateChangedArgs(LogonState.ReloggedOn));
                } else {
                    this.fireLogonStateChange(new LogonStateChangedArgs(LogonState.LoggedOn));
                }
            } else if (e.state === ConnectionState.Reconnecting) {
                this.fireLogonStateChange(new LogonStateChangedArgs(LogonState.ReloggingOn));
            } else if (e.state === ConnectionState.Disconnected) {
                if (this._wasLoggedOn) {
                    this._wasLoggedOn = false;
                    this._currentUserInfo = null;
                    this.fireLogonStateChange(new LogonStateChangedArgs(LogonState.LoggedOff));
                }
            }
        } catch (e) {}
    }

    protected onInternalRequestReceived(arg: Args.RequestReceivedArg) {
        this._requestDispatcher.dispatch(arg);
    }

    protected async onInternalDisconnectRequestReceived(arg: Args.DisconnectRequestArgs): Promise<void> {
        // Streamer would try to reconnect when the socket closes. Disposing the rest connection here assures
        // us the user will stay disconnected.
        if (!arg.tryToReconnect) {
            await this.disposeRestConnectionAsync();
        }
    }

    protected onQueryReceived() {}

    // #endregion

    // #region Private methods

    private createEntityClass<T extends IEntity>(classType: new () => IEntity, entityType?: string): T {
        if (!classType) {
            classType = Entity;
        }

        let instance: IEntity | null = null;
        if (entityType) {
            const cleanedEntityType = entityType.toLowerCase().trim();
            const activator = this._entityTypeDic.get(cleanedEntityType);
            if (activator) {
                instance = new activator();
            }
        }

        if (!instance || !instance.isOfType(classType)) {
            if (classType === Entity) {
                instance = new Entity(entityType);
            } else {
                instance = new classType();
            }
        }

        return instance as T;
    }

    private fireLogonStateChange(state: LogonStateChangedArgs) {
        this._logonState = state.state;
        this._logonStateChangedDispatcher.dispatch(state);
    }

    private async disposeRestConnectionAsync() {
        if (this.disposing) return;

        this.disposing = true;

        if (this._timerTokenRenewal != null) {
            this._timerTokenRenewal.change(-1);
        }

        if (this._rest != null) {
            try {
                await this._rest.disposeAsync();
            } catch (e) {
                // ignore and continue
            }
            this._rest = null;
        }
        this._securityCache.clear();

        this.disposing = false;
    }

    private fireMultiEvents(multiEvent: MultiEvent) {
        const eventsReceived: Args.EventReceivedArg[] = [];
        multiEvent.events.forEach((event) => {
            let singleEvent: EventBase | undefined;
            if (this._eventTypeDic.has(event.EventType)) {
                const activator = this._eventTypeDic.get(event.EventType);
                if (activator) {
                    singleEvent = new activator();
                    if (singleEvent === undefined) {
                        throw new Error('singleEvent === undefined');
                    }
                }
            } else {
                singleEvent = new EventBase();
            }
            if (singleEvent !== undefined) {
                singleEvent.loadFields(event);
                eventsReceived.push(new Args.EventReceivedArg(singleEvent));
            }
        });

        if (eventsReceived.length > 0) {
            this.eventsReceivedSubject$.next(eventsReceived);
        }
    }

    private buildQueryResult<T extends IEntity>(classType: new () => T, response: RestResponse): Map<IGuid, T> {
        const result = SafeGuid.createMap<T>();
        let entities;
        if (response.body) {
            entities = response.body;
        } else {
            if (!response.body) {
                throw new Error('no body');
            }
            entities = response.body;
        }

        for (let i = 0; i < entities.length; i++) {
            const scEntity = this.createEntityModel(classType, entities[i]);
            if (scEntity && !result.has(scEntity.id)) {
                result.set(scEntity.id, scEntity);
            }
        }
        return result;
    }

    private resetDirty(entities: Set<IRestObject>) {
        for (const entity of entities) {
            entity.isDirty = false;
        }
    }

    private startValidityProcess(validUntil: Date): void {
        let timeUntilNow = validUntil.valueOf() - Date.now();

        if (timeUntilNow > this.renewTokenDelayBeforeEnd) {
            timeUntilNow = timeUntilNow - this.renewTokenDelayBeforeEnd;
        } else {
            timeUntilNow = timeUntilNow - 60000; // - 1 min
        }

        if (timeUntilNow > 0) {
            this._timerTokenRenewal.change(timeUntilNow);
        } else {
            this._timerTokenRenewal.change(30000); // to prevent infinte fast loop of token generation, still wait 30 sec
            /*
      this._diagnosticManager.traceLocal(
          LogSeverity.Error,
          this.debugAppName,
          'Token validity range is too high.',
          'Please ensure you have the correct date & time on device.',
      );
      */
        }
    }

    private async onTimerRenewToken() {
        try {
            await this.renewTokenAsync();
        } catch (e: any) {
            // If it fails... retry because we don't have anything else better to do at this point
            this.startValidityProcess(new Date(Date.now() + this.renewTokenDelayBeforeEnd));
            await this._diagnosticManager.sendTraceAsync(LogSeverity.Error, this.debugAppName, 'Unable to renew token automatically', e.toString());
        }
    }

    private registerEvents() {
        this._eventTypeDic.set(EventTypes.AlarmTriggered, AlarmTriggeredEvent);

        // multi
        this._eventTypeDic.set('MultiEvent', MultiEvent);

        // cardholder access event
        this._eventTypeDic.set(EventTypes.CardholderAccessRefused, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderAccessGranted, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardHolderCredentialExpired, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardHolderCredentialInactive, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardHolderCredentialLost, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardHolderCredentialStolen, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderDoubleBadgeOff, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderDoubleBadgeOn, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderDuressPinEntered, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderEntryAssumed, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderEntryDetected, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderNoEntryDetected, CardholderAccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderInactive, CardholderAccessEvent);

        // credential status event
        this._eventTypeDic.set(EventTypes.CredentialExpired, CredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.CredentialLost, CredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.CredentialInactive, CredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.CredentialStolen, CredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.CredentialUnassigned, CredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.CredentialAccessDenied, CredentialStatusEvent);

        // AP credential status event
        this._eventTypeDic.set(EventTypes.AccessExpiredCredential, AccessPointCredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.AccessLostCredential, AccessPointCredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.AccessInactiveCredential, AccessPointCredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.AccessStolenCredential, AccessPointCredentialStatusEvent);
        this._eventTypeDic.set(EventTypes.AccessUnassignedCredential, AccessPointCredentialStatusEvent);

        // alarm
        this._eventTypeDic.set(EventTypes.AlarmTriggered, AlarmTriggeredEvent);
        this._eventTypeDic.set(EventTypes.AlarmAcknowledged, AlarmAcknowledgedEvent);
        this._eventTypeDic.set(EventTypes.AlarmForciblyAcked, AlarmAcknowledgedEvent);
        this._eventTypeDic.set(EventTypes.AlarmAcknowledgedAlternate, AlarmAcknowledgedEvent);
        this._eventTypeDic.set(EventTypes.AlarmInvestigating, AlarmInvestigatingEvent);

        // Access control
        this._eventTypeDic.set(EventTypes.AccessGranted, AccessEvent);
        this._eventTypeDic.set(EventTypes.AccessUnknownCredential, AccessUnknownCredentialEvent);
        this._eventTypeDic.set(EventTypes.DoorNoEntryDetected, AccessEvent);
        this._eventTypeDic.set(EventTypes.DoorEntryAssumed, AccessEvent);
        this._eventTypeDic.set(EventTypes.DoorEntryDetected, AccessEvent);
        this._eventTypeDic.set(EventTypes.CardholderAntipassback, CardholderAntipassbackEvent);
        this._eventTypeDic.set(EventTypes.AreaAntipassbackForgiven, CardholderAntipassbackForgivenEvent);

        // Cardholder identity event
        this._eventTypeDic.set(EventTypes.CardholderIdentityValidationSucceededManual, CardholderIdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.CardholderIdentityValidationSucceededBiometric, CardholderIdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.CardholderIdentityValidationFailedTimeout, CardholderIdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.CardholderIdentityValidationFailedBiometricMissing, CardholderIdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.CardholderIdentityValidationFailedBiometricMismatch, CardholderIdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.CardholderIdentityValidationFailedManual, CardholderIdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.CardholderAccessGrantConfirmationRequested, CardholderAccessGrantConfirmationRequestedEvent);

        // identity event
        this._eventTypeDic.set(EventTypes.IdentityValidationSucceededManual, IdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.IdentityValidationSucceededBiometric, IdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.IdentityValidationFailedTimeout, IdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.IdentityValidationFailedBiometricMissing, IdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.IdentityValidationFailedBiometricMismatch, IdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.IdentityValidationFailedManual, IdentityValidationEvent);
        this._eventTypeDic.set(EventTypes.AccessGrantConfirmationRequested, AccessGrantConfirmationRequestedEvent);

        // app
        this._eventTypeDic.set(EventTypes.ApplicationConnected, ApplicationEvent);
        this._eventTypeDic.set(EventTypes.ApplicationDisconnected, ApplicationEvent);

        // Custom
        this._eventTypeDic.set(EventTypes.CustomEvent, CustomPayloadEvent);

        // Lpr
        this._eventTypeDic.set(EventTypes.LprRead, LprReadEvent);
        this._eventTypeDic.set(EventTypes.LprHit, LprHitEvent);

        // user
        this._eventTypeDic.set(EventTypes.UserLoggedOn, UserEvent);
        this._eventTypeDic.set(EventTypes.UserLoggedOff, UserEvent);
        this._eventTypeDic.set(EventTypes.UserLogonFailed, UserEvent);
        this._eventTypeDic.set(EventTypes.ModifiedIncident, ModifiedIncidentEvent);
    }

    // #endregion
}
