import { ComponentRef, Injectable, TemplateRef, ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injector, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { GenPopup } from '@genetec/gelato-angular';
import { PopupPosition } from '@genetec/gelato';
import { TilesTaskEventService } from '@modules/tiles/components/tiles-task/services/tiles-task-event.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { filter } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class PtzPopupService {
    public viewContainerRef: ViewContainerRef | undefined;

    // private
    private popupTracker = SafeGuid.createMap<ComponentRef<GenPopup>>();

    constructor(
        private injector: Injector,
        private applicationRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private router: Router,
        private tileTaskService: TilesTaskEventService,
        private authService: AuthService
    ) {
        // If a user changes to another route, make sure to clear state.
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.destroyAllPopups();
            });

        // If user goes into fullscreen, it will hide the popup, so we might as well destroy it, in case the user wants to create a new one.
        this.tileTaskService.fullscreenChanged$.pipe(untilDestroyed(this)).subscribe(() => {
            this.destroyAllPopups();
        });

        // If pattern changes, try to destroy all opened ptz popup as there is a high chance they won't be displayed properly.
        this.tileTaskService.tilePatternChanged$.pipe(untilDestroyed(this)).subscribe(() => {
            this.destroyAllPopups();
        });

        // Register to LogonState change, if we log out, clear all popups.
        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn) => {
            if (!isLoggedIn) {
                this.destroyAllPopups();
            }
        });
    }

    // public methods
    /**
     * Creates and tracks a popup. This method does not call .openPopup on it. You have to call it yourself.
     *
     * @param templateRef ptz component
     * @param coords where you want the popup to show
     * @param key id to associate with the popup in the tracker
     *
     * @returns the key used to store the popup in the tracker.
     * null if the popup already exists
     */
    public createPtzPopupRef(templateRef: TemplateRef<any>, key: IGuid, targetSelector: string): HTMLGenPopupElement | null {
        // check if key is already inserted.
        if (this.popupTracker.has(key)) {
            return null;
        }

        // Create a GenPopup
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GenPopup);
        const ptzPopupRef = componentFactory.create(this.injector);

        if (ptzPopupRef) {
            ptzPopupRef.instance.position = PopupPosition.Top;
            ptzPopupRef.instance.targetSelector = targetSelector;
            ptzPopupRef.instance.staysOpen = false;

            // Add to tracker.
            this.popupTracker.set(key, ptzPopupRef);

            // Place popup in correct location in DOM
            this.applicationRef.attachView(ptzPopupRef.hostView);
            const domElement = this.extractHTMLPopupFromRef(ptzPopupRef);
            domElement.id = key.toString();

            // Append to the gen-design-system-provider in order to use Gelato styles or the fullscreen element if there is one
            let appendToElement;
            if (document.fullscreenEnabled && document.fullscreenElement) {
                appendToElement = document.fullscreenElement;
            } else {
                appendToElement = document.querySelector('gen-design-system-provider');
            }
            appendToElement?.appendChild(domElement);

            // Append the template to the popup for rendering
            const embeddedView = this.viewContainerRef?.createEmbeddedView(templateRef);
            embeddedView?.rootNodes.forEach((rootNode) => {
                domElement.appendChild(rootNode);
            });

            // Return the key used in the tracker.
            return domElement;
        }
        return null;
    }

    /**
     * Returns the current state of the requested popup
     *
     * @param key id of the popup to retrieve in the tracker
     * @returns the state of the popup. If for any reasons the popup does not exist, state returned will be "Closed"
     */
    public getPopupState(key: IGuid): boolean {
        const popup = this.popupTracker.get(key);
        if (popup) {
            return popup.instance.open;
        }
        return false;
    }

    /**
     * Based on the current state of the popup associated to the key passed in argument, it will either close or show.
     *
     * @param key key associated to the popup in the popup tracker.
     * @param targetSelector where you want the popup to show.
     */
    public async togglePopup(key: IGuid, targetSelector?: string): Promise<void> {
        const domElement = this.extractHTMLPopupFromKey(key);
        if (domElement?.open) {
            await this.closePopup(key);
        } else {
            await this.openPopup(key, targetSelector);
        }
    }

    /**
     * Shows a popup that was already in the DOM
     *
     * @param key associated to the popup.
     * @param targetSelector where you want the popup to show.
     */
    public async openPopup(key: IGuid, targetSelector?: string): Promise<void> {
        const domElement = this.extractHTMLPopupFromKey(key);
        if (targetSelector) {
            const popup = this.popupTracker.get(key);
            if (popup) {
                popup.instance.targetSelector = targetSelector;
            }
        }
        if (!domElement?.open) {
            await domElement?.show();
        }
    }
    /**
     * Simply hides the popup from the view.
     *
     * @param key associated to the popup.
     */
    public async closePopup(key: IGuid): Promise<void> {
        const domElement = this.extractHTMLPopupFromKey(key);
        if (domElement?.open) {
            await domElement.close();
        }
    }

    /**
     * Goes through every tracked popup instance, and hides them.
     */
    public async closeAllPopups(): Promise<void> {
        for await (const [guid, item] of this.popupTracker) {
            const popup = this.extractHTMLPopupFromRef(item);

            if (popup.open) {
                await popup.close();
            }
        }
    }

    /**
     * Goes through every tracked popup instance, and opens them.
     */
    public async openAllPopups(): Promise<void> {
        for await (const [guid, item] of this.popupTracker) {
            const popup = this.extractHTMLPopupFromRef(item);
            if (popup.open) {
                await popup.show();
            }
        }
    }

    /**
     * Goes through every tracked popup instance and deletes them. Removes them from the tracker as well.
     */
    public destroyAllPopups(): void {
        this.popupTracker.forEach((item) => {
            item.destroy();
        });
        this.popupTracker.clear();
    }

    /**
     * Completely removes from the DOM the tracked popup instance by key.
     * Also removes the tracked instance from the tracker.
     *
     * @param key associated to the popup.
     */
    public async destroyPopup(key: IGuid): Promise<void> {
        const popup = this.popupTracker.get(key) ?? null;
        if (popup) {
            await popup.instance.close();
            popup.destroy();
            this.popupTracker.delete(key);
        }
    }

    private extractHTMLPopupFromKey(key: IGuid): HTMLGenPopupElement | null {
        const popup = this.popupTracker.get(key);
        if (popup) {
            return (popup.hostView as EmbeddedViewRef<GenPopup>).rootNodes[0] as HTMLGenPopupElement;
        } else {
            return null;
        }
    }

    private extractHTMLPopupFromRef(item: ComponentRef<GenPopup>): HTMLGenPopupElement {
        return (item.hostView as EmbeddedViewRef<GenPopup>).rootNodes[0] as HTMLGenPopupElement;
    }
}
