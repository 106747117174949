<app-filter-host [filterDescriptor]="descriptor" [state]="state" (filterToggled)="onFilterToggled($event)" [popupPosition]="popupPosition">
    <div class="filter-container">
        <div class="filter-content">
            <gen-spinner class="align-center text-bold ml-1" *ngIf="isContentLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>

            <gen-list [selectionType]="ListSelectionType.Multiple">
                <gen-list-item *ngFor="let enumValue of enumValues" (selectedChange)="onSelectedEnumValuesChanged(enumValue, $event.detail)" [selected]="enumValue.isChecked">
                    <gen-text>{{ enumValue.text }}</gen-text>
                </gen-list-item>
            </gen-list>

            <ng-container *ngIf="isContentLoaded && enumValues.length === 0">
                <span class="align-center text-bold ml-1">{{ 'STE_LABEL_NORESULTS' | translate }}</span>
            </ng-container>
        </div>
        <div>
            <ng-container *ngIf="isFooterVisible">
                <gen-button
                    class="ml-1"
                    [flavor]="Flavor.Button.Flat"
                    [hidden]="isAllSelected"
                    appTooltip="{{ 'STE_ACTION_CHECKALL' | translate }}"
                    (click)="onCheckAllItemsClicked()"
                >
                    <gen-icon [icon]="Icon.CheckAll"></gen-icon>
                </gen-button>
                <gen-button [flavor]="Flavor.Button.Flat" [hidden]="isNothingSelected" appTooltip="{{ 'STE_ACTION_UNCHECKALL' | translate }}" (click)="clearFilter()">
                    <gen-icon [icon]="Icon.Close"></gen-icon>
                </gen-button>
            </ng-container>
        </div>
    </div>
</app-filter-host>
