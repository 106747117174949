<div class="picture-wrapper" (mouseenter)="toggleMouseOver(true)" (mouseleave)="toggleMouseOver(false)">
    <gen-spinner *ngIf="isLoadingSubject | async; else picture"></gen-spinner>
    <ng-template #picture>
        <!-- Actual cardholder picture or user uploaded one if changed -->
        <app-thumbnail
            *ngLet="cardholderPictureSrc$ | async as cardholderPicture"
            [thumbnailData]="cardholderPicture"
            [flavor]="ImageFlavor.Rounded"
            [fit]="ImageFit.Contain"
            [defaultPlaceholder]="Icon.Cardholder"
            placeholderOpacity="30%"
        ></app-thumbnail>

        <!-- Button in corner to show trigger Change/Remove menu-->
        <gen-button
            *ngIf="(isMouseOverCardholderPicture || isPictureEditMenuOpen) && isEditFeatureFlagEnabled && (isDisabledSubject | async) === false && !readonly"
            class="edit-picture-button"
            [flavor]="ButtonFlavor.Flat"
            (click)="isPictureEditMenuOpen = true"
        >
            <gen-icon [icon]="Icon.EllipsisVertical"></gen-icon>
        </gen-button>
    </ng-template>
</div>

<!-- Picture Change/Remove menu -->
<gen-menu [open]="isPictureEditMenuOpen" (didClose)="isPictureEditMenuOpen = false" target-selector=".edit-picture-button">
    <gen-menu-item data-test="change" (click)="fileInput.click(); onTouch()">
        <gen-icon [icon]="Icon.Pencil"></gen-icon>
        <gen-text>{{ 'STE_LABEL_CHANGE' | translate }}</gen-text>
    </gen-menu-item>
    <gen-menu-item data-test="remove" *ngIf="cardholderPictureSrc$ | async" (click)="toggleRemovePictureConfirmationModal(true)">
        <gen-icon [icon]="Icon.Trash"></gen-icon>
        <gen-text>{{ 'STE_LABEL_REMOVE' | translate }}</gen-text>
    </gen-menu-item>
</gen-menu>

<input #fileInput data-test="file-input" type="file" accept="image/*" (change)="onImageFileSelected($event)" />

<!-- Picture Remove confirmation Modal -->
<gen-modal #removePictureModal>
    <gen-modal-header>{{ 'STE_LABEL_REMOVE' | translate }} {{ 'STE_LABEL_CARDHOLDER_PICTURE' | translate | lowercase }}</gen-modal-header>
    <gen-modal-body>{{ 'STE_MESSAGE_CONFIRM_CARDHOLDER_PICTURE_REMOVAL' | translate }}</gen-modal-body>
    <gen-modal-footer>
        <gen-button data-test="cancel" (click)="toggleRemovePictureConfirmationModal(false)">{{ 'STE_BUTTON_CANCEL' | translate }}</gen-button>
        <gen-button data-test="confirm" (click)="removePicture()" destructive [flavor]="ButtonFlavor.Solid">{{ 'STE_LABEL_REMOVE' | translate }}</gen-button>
    </gen-modal-footer>
</gen-modal>
