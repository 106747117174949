<gen-melted-modal
    genTitle="{{ 'STE_LABEL_SETTINGS' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_APPLY' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genCanClose]="true"
    [genIsDefaultActionDisabled]="!(dirty && isValid)"
    [genIsBlocking]="true"
>
    <gen-melted-modal-body>
        <div class="parameter-modal-body">
            <gen-grid [genColumns]="4">
                <gen-grid-item>
                    <!-- Aggregation type combo -->
                    <gen-combobox
                        name="agg-type-combo"
                        [ngModel]="aggregationType"
                        (ngModelChange)="onAggregationTypeChanged($event)"
                        [genItemsSource]="aggregationTypes"
                        genLabel="{{ 'STE_LABEL_AGGREGATION_TYPE' | translate }}"
                        [genPosition]="Position.Combobox.BottomLeft"
                        [genFlavor]="Flavor.Combobox.Solid"
                    >
                    </gen-combobox>
                </gen-grid-item>
                <gen-grid-item>
                    <!-- nil -->
                </gen-grid-item>
                <gen-grid-item [genRowSpan]="4" [genColSpan]="2">
                    <!-- DataTypes List -->
                    <div class="data-types-list">
                        <gen-text [flavor]="TextFlavor.Label">
                            {{ 'STE_LABEL_NOUN_RECORDTYPES' | translate }}
                        </gen-text>
                        <gen-flex class="data-types-toggle-container">
                            <gen-flex-item [genColumnsXS]="12" [genColumnsSM]="12" [genColumnsMD]="12" [genColumnsLG]="12" [genColumnsXL]="12" [genColumnsXXL]="12">
                                <gen-search-input
                                    *ngIf="dataTypes.length > 15"
                                    [genDelay]="100"
                                    placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
                                    [ngModel]="searchText"
                                    (ngModelChange)="onSearchChange($event)"
                                >
                                </gen-search-input>
                            </gen-flex-item>
                            <ng-container *ngFor="let item of visibleDataTypes">
                                <gen-flex-item class="m-1">
                                    <app-image-name-toggle-button [item]="item" (isCheckedChanged)="onDataTypeChanged(item)"> </app-image-name-toggle-button>
                                </gen-flex-item>
                            </ng-container>
                        </gen-flex>
                    </div>
                </gen-grid-item>

                <gen-grid-item>
                    <!-- Aggregation field combo -->
                    <gen-combobox
                        name="agg-field-combo"
                        [ngModel]="aggregationField"
                        (ngModelChange)="onAggregationFieldChanged($event)"
                        [genItemsSource]="aggregationFields"
                        genLabel="{{ 'STE_LABEL_AGGREGATION_FIELD' | translate }}"
                        [genPosition]="Position.Combobox.BottomLeft"
                        [genFlavor]="Flavor.Combobox.Solid"
                    >
                    </gen-combobox>
                </gen-grid-item>
                <gen-grid-item>
                    <!-- Custom aggregation field -->
                    <gen-text-input
                        *ngIf="aggregationField?.id === 'Custom'"
                        name="agg-custom-field-text"
                        [ngModel]="parameter.customAggregationField"
                        (ngModelChange)="onAggregationCustomFieldChanged($event)"
                    >
                        <gen-label>{{ 'STE_LABEL_CUSTOM_AGGREGATION_FIELD' | translate }}</gen-label>
                    </gen-text-input>
                </gen-grid-item>
                <gen-grid-item>
                    <!-- Aggregation grouping combo -->
                    <gen-combobox
                        name="agg-group-combo"
                        [ngModel]="aggregationGroup"
                        (ngModelChange)="onAggregationGroupChanged($event)"
                        [genItemsSource]="aggregationGroups"
                        genLabel="{{ 'STE_LABEL_AGGREGATION_GROUPING' | translate }}"
                        [genPosition]="Position.Combobox.BottomLeft"
                        [genFlavor]="Flavor.Combobox.Solid"
                    >
                    </gen-combobox>
                </gen-grid-item>
                <gen-grid-item>
                    <!-- Custom aggregation grouping -->
                    <gen-text-input
                        *ngIf="aggregationGroup?.id === 'Custom'"
                        name="agg-custom-group-text"
                        [ngModel]="parameter.customAggregationGroup"
                        (ngModelChange)="onAggregationCustomGroupChanged($event)"
                    >
                        <gen-label>{{ 'STE_LABEL_CUSTOM_AGGREGATION_GROUPING' | translate }}</gen-label>
                    </gen-text-input>
                </gen-grid-item>
                <gen-grid-item [genColSpan]="2">
                    <!-- When -->
                    <gen-text [flavor]="TextFlavor.Label"> {{ 'STE_LABEL_WHEN' | translate }} </gen-text>
                </gen-grid-item>
                <gen-grid-item [genColSpan]="2">
                    <!-- Where -->
                    <gen-text [flavor]="TextFlavor.Label"> {{ 'STE_LABEL_WHERE' | translate }} </gen-text>
                </gen-grid-item>

                <gen-grid-item [genRowSpan]="1" [genColSpan]="2">
                    <!-- Additional Parameters List -->
                    <div class="data-types-list">
                        <!-- This component must be migrated once genActions is migrated -->
                        <gen-melted-list
                            [genItemsSource]="additionalParameters"
                            genLabel="{{ 'STE_LABEL_CONDITIONS' | translate }}"
                            [genActions]="conditionActions"
                            [genSelectionType]="SelectionType.List.Single"
                            [genDensity]="Density.List.Compact"
                        >
                        </gen-melted-list>
                    </div>
                </gen-grid-item>
            </gen-grid>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
