import { IGuid } from 'safeguid';
import { toGuid } from '../utils/guid-utils';
export interface CategoryApi {
    Id: string;
    Name: string;
    ParentId: string | null;
    IconId: string;
}

export class Category {
    constructor(public id: IGuid, public name: string, public parentId: string | null, public iconId: IGuid) {}

    public static assign = (category: CategoryApi): Category => new Category(toGuid(category.Id), category.Name, category.ParentId, toGuid(category.IconId));
}
