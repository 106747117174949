import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { IAccessPointEntity, AccessPointEntityFields } from 'RestClient/Client/Interface/IAccessPointEntity';
import { IEntity, EntityFields } from 'RestClient/Client/Interface/IEntity';
import { IEntityCacheTask, modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { AccessPointEntity } from 'RestClient/Client/Model/AccessControl/AccessPointEntity';
import { CardholderEntity } from 'RestClient/Client/Model/AccessControl/CardholderEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { CardholderEntityFields, ICardholderEntity } from 'RestClient/Client/Interface/ICardholderEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { TimeService } from '@modules/shared/services/time/time.service';
import { DateFormat } from '@genetec/gelato-angular';
import { CardholderWidgetBaseComponent } from './cardholder-widget-base.component';

@Component({
    selector: 'app-cardholder-state-widget-base-component',
    template: '',
})
export class CardholderStateWidgetBaseComponent extends CardholderWidgetBaseComponent implements OnInit, OnDestroy {
    private static monitoredFields = [
        CardholderEntityFields.accessStatusField,
        CardholderEntityFields.lastAccessField,
        CardholderEntityFields.lastAccessLocationField,
        CardholderEntityFields.lastAccessGrantedField,
    ];

    public isActive = false;
    public displayLastAccess = false;
    public lastAccessLocation = '';
    public lastAccessTime = '';
    public wasLastAccessGranted = false;

    protected entityCacheTask!: IEntityCacheTask;

    protected get monitoredFields(): string[] {
        const fields = super.monitoredFields;
        fields.push(...CardholderStateWidgetBaseComponent.monitoredFields);
        return fields;
    }
    constructor(
        securityCenterClientService: SecurityCenterClientService,
        protected translateService: TranslateService,
        protected timeService: TimeService,
        trackingService: TrackingService
    ) {
        super(securityCenterClientService, trackingService);
    }

    public async ngOnDestroy() {
        await this.entityCacheTask?.dispose();
        super.ngOnDestroy();
    }

    protected async getCardholderAsync(cardholderId: IGuid): Promise<void> {
        if (this.scClient) {
            this.entityCacheTask = this.scClient.buildEntityCache(this.monitoredFields);
            this.cardholder = (await this.entityCacheTask.getEntityAsync<CardholderEntity, ICardholderEntity>(CardholderEntity, cardholderId, true)) as CardholderEntity;
        }
    }

    protected async initializeCardholerInfo(): Promise<void> {
        await super.initializeCardholerInfo();
        if (this.cardholder && this.entityCacheTask) {
            this.updateIsActive(this.cardholder.accessStatus);
            await this.entityCacheTask.detectFieldChangeAsync(
                this.cardholder,
                () => {
                    const _ = this.cardholder?.accessStatus;
                },
                ((_: ICardholderEntity, newValue: string, __: string) => {
                    this.updateIsActive(newValue);
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<string>
            );

            if (this.cardholder.lastAccessGranted !== null) {
                this.updateLastAccessTime(this.cardholder.lastAccess);
                await this.updateLastAccessLocationAsync(this.cardholder.lastAccessLocation);
                this.updateWasLastAccessGranted(this.cardholder.lastAccessGranted);
            }

            await this.entityCacheTask.detectFieldChangeAsync(
                this.cardholder,
                () => {
                    const _ = this.cardholder?.lastAccessLocation;
                },
                (async (_: ICardholderEntity, newValue: IGuid, __: IGuid) => {
                    await this.updateLastAccessLocationAsync(newValue);
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<IGuid>
            );

            await this.entityCacheTask.detectFieldChangeAsync(
                this.cardholder,
                () => {
                    const _ = this.cardholder?.lastAccess;
                },
                ((entity: ICardholderEntity, _: Date, __: Date) => {
                    // TODO: use newValue instead of getting the field from the cardholder once newValue is a correctly formatted Date instead of string
                    this.updateLastAccessTime(entity.lastAccess);
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<Date>
            );

            await this.entityCacheTask.detectFieldChangeAsync(
                this.cardholder,
                () => {
                    const _ = this.cardholder?.lastAccessGranted;
                },
                ((_: ICardholderEntity, newValue: boolean, __: boolean) => {
                    this.updateWasLastAccessGranted(newValue);
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<boolean>
            );
        }
    }

    private updateIsActive(accessStatus: string) {
        this.isActive = accessStatus === AccessStatus.Active;
    }

    private async updateLastAccessLocationAsync(accessPointId: IGuid) {
        const accessPoint = await this.scClient.getEntityAsync<AccessPointEntity, IAccessPointEntity>(
            AccessPointEntity,
            accessPointId,
            null,
            null,
            AccessPointEntityFields.parentField
        );
        if (accessPoint) {
            const parentEntity = await this.scClient.getEntityAsync<Entity, IEntity>(Entity, accessPoint.parent, null, null, EntityFields.nameField);
            this.lastAccessLocation = parentEntity ? parentEntity.name : (this.translateService.instant('STE_LABEL_LASTACCESSLOCATION_UNKNOWN') as string);
        }
    }

    private updateLastAccessTime(time: Date) {
        if (time) {
            this.lastAccessTime = this.timeService.formatTime(time, DateFormat.DateTime, true, true);
        }
    }

    private updateWasLastAccessGranted(wasAccessGranted: boolean) {
        this.wasLastAccessGranted = wasAccessGranted;
        this.displayLastAccess = this.lastAccessTime.length > 0 && this.lastAccessLocation.length > 0;
    }
}
