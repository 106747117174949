import { SafeGuid } from 'safeguid';
import { Constants } from '../utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const EventMonitoringContentTypes = Constants({
    WatchList: SafeGuid.parse('0DA4E751-A290-412C-9A6D-FCD9E2E51889'),
    EditWatchList: SafeGuid.parse('7B8DC150-2AD6-4376-86C1-4BA4A50A8645'),
});
