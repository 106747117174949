export class Serializer {
  private readonly m_dataView: DataView;

  private m_offset: number;

  public get arrayBuffer(): ArrayBuffer {
    return this.m_dataView.buffer;
  }

  public static build(size: number) {
    return new Serializer(new DataView(new ArrayBuffer(size)), 0);
  }

  constructor(dataView: DataView, offset: number) {
    this.m_dataView = dataView;
    this.m_offset = offset;
  }

  public writeUint8(byte: number) {
    this.m_dataView.setUint8(this.m_offset, byte);
    this.m_offset++;
  }

  public writeUint32(uint32: number) {
    this.m_dataView.setUint32(this.m_offset, uint32);
    this.m_offset += 4;
  }

  public writeString(s: string) {
    const bytes = new TextEncoder().encode(s);
    this.writeUint32(bytes.length);
    for (let i = 0; i < bytes.length; ++i) {
      this.writeUint8(bytes[i]);
    }
  }

  public static serializeString(s: string): ArrayBuffer {
    const bytes = new TextEncoder().encode(s);
    const serializer = Serializer.build(4 + bytes.length);
    serializer.writeUint32(bytes.length);
    for (let i = 0; i < bytes.length; ++i) {
      serializer.writeUint8(bytes[i]);
    }
    return serializer.m_dataView.buffer;
  }

  public static combineDataView(first: DataView, second: DataView) {
    const totalMessage = new ArrayBuffer(first.byteLength + second.byteLength);
    const totalMessageFirst = new DataView(totalMessage);
    const totalMessageSecond = new DataView(totalMessage, first.byteLength);

    for (let i = 0; i < first.byteLength; ++i) {
      totalMessageFirst.setUint8(i, first.getUint8(i));
    }

    for (let i = 0; i < second.byteLength; ++i) {
      totalMessageSecond.setUint8(i, second.getUint8(i));
    }

    return totalMessage;
  }
}
