// Autogenerated: v2/help/Entities/ApiContentFileEntity?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-26 3:26:01 PM
import { Entity } from '../Entity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { FieldObject } from '../../../Helpers/FieldObject';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IContentFileEntity, IRuleTranslation, IRuleAttribute, ContentFileEntityFields } from '../../Interface/IContentFileEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IFieldObject } from '../../Interface/IFieldObject';

// #region Inner classes

export class RuleTranslation extends FieldObject implements IRuleTranslation {
    // From
    public get from(): string {
        return this.getField<string>('From');
    }
    public set from(value: string) {
        this.setField<string>('From', value);
    }
    // To
    public get to(): string {
        return this.getField<string>('To');
    }
    public set to(value: string) {
        this.setField<string>('To', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('From', '');
        this.setField<string>('To', '');
    }
}

export class RuleAttribute extends FieldObject implements IRuleAttribute {
    // IsMandatory
    public get isMandatory(): boolean {
        return this.getField<boolean>('IsMandatory');
    }
    public set isMandatory(value: boolean) {
        this.setField<boolean>('IsMandatory', value);
    }
    // Key
    public get key(): string {
        return this.getField<string>('Key');
    }
    public set key(value: string) {
        this.setField<string>('Key', value);
    }
    // DefaultValue
    public get defaultValue(): string {
        return this.getField<string>('DefaultValue');
    }
    public set defaultValue(value: string) {
        this.setField<string>('DefaultValue', value);
    }
    // Length
    public get length(): number {
        return this.getField<number>('Length');
    }
    public set length(value: number) {
        this.setField<number>('Length', value);
    }
    // Start
    public get start(): number {
        return this.getField<number>('Start');
    }
    public set start(value: number) {
        this.setField<number>('Start', value);
    }
    // DateFormat
    public get dateFormat(): string {
        return this.getField<string>('DateFormat');
    }
    public set dateFormat(value: string) {
        this.setField<string>('DateFormat', value);
    }
    // Translations
    public get translations(): ObservableCollection<IRuleTranslation> {
        return this.getFieldObjectArray<RuleTranslation, IRuleTranslation>(RuleTranslation, 'Translations');
    }
    public set translations(value: ObservableCollection<IRuleTranslation>) {
        this.setFieldObjectArray('Translations', value);
    }

    public initializeAllFields(): void {
        this.setField<boolean>('IsMandatory', false);
        this.setField<string>('Key', '');
        this.setField<string>('DefaultValue', '');
        this.setField<number>('Length', 0);
        this.setField<number>('Start', 0);
        this.setField<string>('DateFormat', '');
        this.setFieldArray<IRuleTranslation>('Translations', new ObservableCollection<IRuleTranslation>());
    }
}

// #endregion Inner classes

export class ContentFileEntity extends Entity implements IContentFileEntity {
    // #region REST Properties

    // Password
    public get password(): string {
        return this.getField<string>(ContentFileEntityFields.passwordField);
    }
    public set password(value: string) {
        this.setField<string>(ContentFileEntityFields.passwordField, value);
    }

    // Path
    public get path(): string {
        return this.getField<string>(ContentFileEntityFields.pathField);
    }
    public set path(value: string) {
        this.setField<string>(ContentFileEntityFields.pathField, value);
    }

    // Schema
    public get schema(): string {
        return this.getField<string>(ContentFileEntityFields.schemaField);
    }

    // SupportsEditor
    public get supportsEditor(): boolean {
        return this.getField<boolean>(ContentFileEntityFields.supportsEditorField);
    }
    public set supportsEditor(value: boolean) {
        this.setField<boolean>(ContentFileEntityFields.supportsEditorField, value);
    }

    // UserName
    public get userName(): string {
        return this.getField<string>(ContentFileEntityFields.userNameField);
    }
    public set userName(value: string) {
        this.setField<string>(ContentFileEntityFields.userNameField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor(entityType: string) {
        super(entityType);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAttributesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRuleAttribute> | null> {
        return this.getFieldObjectRelationAsync<RuleAttribute, IRuleAttribute>(RuleAttribute, 'Attributes', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async addPlateAsync(value: IFieldObject, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('Plate', 'POST', value, transaction, responseHandler);
    }

    public async getRolesAsync(transaction?: ITransaction): Promise<Array<IGuid> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IGuid>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IGuid>;
                return new Promise<Array<IGuid>>(function(resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IGuid>>(function(resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<Array<IGuid>>('Roles', 'GET', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

