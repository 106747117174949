// Autogenerated: v2/help/Entities/ApiApplication?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class ApplicationEntityFields extends EntityFields {
    public static applicationVersionField = 'ApplicationVersion';
    public static appTypeField = 'AppType';
    public static externalApplicationOwnerField = 'ExternalApplicationOwner';
    public static isInBackwardCompatibilityModeField = 'IsInBackwardCompatibilityMode';
    public static lastLoggedTimestampField = 'LastLoggedTimestamp';
    public static lastLoggedUserField = 'LastLoggedUser';
    public static loggedUserField = 'LoggedUser';
    public static machineNameField = 'MachineName';
    public static streamingVersionField = 'StreamingVersion';
    public static versionField = 'Version';
}

// #endregion Fields

export interface IApplicationEntity extends IEntity {
    // #region REST Properties

    applicationVersion: string;

    appType: string;

    externalApplicationOwner: IGuid;

    readonly isInBackwardCompatibilityMode: boolean;

    readonly lastLoggedTimestamp: Date;

    readonly lastLoggedUser: IGuid;

    loggedUser: IGuid;

    readonly machineName: string;

    readonly streamingVersion: number;

    readonly version: string;

    // #endregion REST Properties

    // #region REST Methods

    getMonitorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

