// Autogenerated: v2/help/enumerations/ApiZoneType?codegen=ts&showresources=False on 2020-07-23 3:40:08 PM

export class ZoneType {
    public static Hardware = 'Hardware';
    public static Software = 'Software';
    public static IO = 'IO';

    public static readonly values = [
        ZoneType.Hardware,
        ZoneType.Software,
        ZoneType.IO
    ];
}
