import { Pipe, PipeTransform } from '@angular/core';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { TextFlavor } from '@genetec/gelato';

@Pipe({ name: 'accessStatusToColor' })
export class AccessStatusToColorPipe implements PipeTransform {
    public transform(accessStatus: AccessStatus): TextFlavor {
        switch (accessStatus) {
            case AccessStatus.Active:
                // green
                return TextFlavor.Success;
            case AccessStatus.Inactive:
            case AccessStatus.Requested:
                // white
                return TextFlavor.Normal;
            case AccessStatus.Expired:
            case AccessStatus.Lost:
            case AccessStatus.Stolen:
                // red
                return TextFlavor.Error;
            default:
                return TextFlavor.Normal;
        }
    }
}
