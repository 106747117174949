<ng-container *ngFor="let item of dataSource ?? []">
    <ng-container *ngIf="item.children; else noChildMenu">
        <!-- Item contains children -->
        <gen-menu-item>
            <ng-container *ngIf="item.icon">
                <gen-icon [slot]="ItemSlot.Icon" [icon]="item.icon"></gen-icon>
            </ng-container>
            <gen-text> {{ item.text }} </gen-text>
            <gen-menu>
                <ng-container *ngFor="let child of item.children">
                    <ng-container *ngIf="canExecute(child)">
                        <gen-menu-item [disabled]="!isAllowed(child)" (click)="execute(child)">
                            <ng-container *ngIf="item.icon && item.icon">
                                <gen-icon [slot]="ItemSlot.Icon" [icon]="child.icon" [genColor]="child.iconColor ?? ''"></gen-icon>
                            </ng-container>
                            <gen-text>{{ child.text }}</gen-text>
                            <gen-text [slot]="ItemSlot.TextSecondary">{{ child.secondaryText }}</gen-text>
                        </gen-menu-item>
                    </ng-container>
                </ng-container>
            </gen-menu>
        </gen-menu-item>
    </ng-container>
    <!-- Regular menu, no children -->
    <ng-template #noChildMenu>
        <ng-container *ngIf="item.text === Separator; else regularMenuItem">
            <!-- New menu item section -->
            <gen-separator></gen-separator>
        </ng-container>
        <ng-template #regularMenuItem>
            <!-- Actual menu items -->
            <ng-container *ngIf="canExecute(item)">
                <gen-menu-item [disabled]="!isAllowed(item)" (click)="execute(item)">
                    <ng-container *ngIf="item?.icon">
                        <gen-icon [slot]="ItemSlot.Icon" [icon]="item.icon" [genColor]="item.iconColor ?? ''"></gen-icon>
                    </ng-container>
                    <gen-text>{{ item.text }}</gen-text>
                    <gen-text [slot]="ItemSlot.TextSecondary">{{ item.secondaryText }}</gen-text>
                </gen-menu-item>
            </ng-container>
        </ng-template>
    </ng-template>
</ng-container>
