import { EventBase } from 'RestClient/Client/Event/EventBase';
import { IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class MapLayersRefreshEvent extends EventBase {
    public get layers(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('layers');
    }
}
