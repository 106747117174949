// Autogenerated: v2/help/Classes/ApiApplicationEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:08 PM
import { RoutableEvent } from './RoutableEvent';
import { IApplicationEvent } from '../Interface/IApplicationEvent';

export class ApplicationEvent extends RoutableEvent implements IApplicationEvent {
    // #region REST Properties

    // ApplicationName
    public get applicationName(): string {
        return this.getField<string>('ApplicationName');
    }
    public set applicationName(value: string) {
        this.setField<string>('ApplicationName', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('ApplicationName', '');
    }

    // #endregion Methods

}

