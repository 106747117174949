import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Injectable, Inject, Pipe, PipeTransform, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { GenTranslateService } from '@genetec/gelato-angular';
import { Constants } from '@src/constants';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { Language, LanguageClient } from '@modules/shared/api/api';
import { WINDOW } from '@utilities/common-helper';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { USER_SETTINGS_SERVICE } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { OptionTypes } from '../../enumerations/option-types';

export class StringEntry {
    // represent the key to retrieve from the resources (like STE_LABEL_)
    public key: string;

    constructor(key: string) {
        this.key = key;
    }
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    //#region Constants

    public static readonly DefaultLanguage = 'en';
    public static readonly supportedLocales = ['en', 'es', 'fr', 'de'];

    //#endregion

    //#region Fields

    @Output() public ready = new EventEmitter();
    @Output() public languageChanged = new EventEmitter();

    private downloaded: { [key: string]: boolean } = {};
    private languageBrowserTag = 'WebApp.Language';
    private selectedlanguageTag = 'selectedlanguage';
    private currentLanguage = LanguageService.DefaultLanguage;
    private copyrights: Map<string, string> = new Map<string, string>();
    private currentTitle: (string | StringEntry)[] = [];

    //#endregion

    //#region Constructors

    constructor(
        @Inject(Constants.baseUrlIdentifier) private baseUrl: string,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: UserSettingsService,
        securityCenterClientService: SecurityCenterClientService,
        private translateService: TranslateService,
        private genTranslateService: GenTranslateService,
        private languageClient: LanguageClient,
        private titleService: Title,
        @Inject(WINDOW) private window: Window
    ) {
        // Try to retrieve the current language from the cache
        let language = LanguageService.DefaultLanguage;
        const currentLanguage = this.window.localStorage.getItem(this.languageBrowserTag);
        if (currentLanguage && currentLanguage !== '') {
            language = currentLanguage;
        }

        this.useLanguage(language);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.fetchStrings(language);

        // NgXs store not loaded right away after injection... Use setTimeout to prevent crash
        setTimeout(() => {
            this.userSettingsService.settings$.pipe(untilDestroyed(this)).subscribe(() => {
                const selectedLanguage = this.userSettingsService.get<string>(OptionTypes.LanguageAndTime, this.selectedlanguageTag, false);

                // Fall back to default language (en) if not specified.
                const effectiveLanguage = !selectedLanguage ? LanguageService.DefaultLanguage : selectedLanguage;

                // If the language has changed, set it
                this.setLanguage(effectiveLanguage).fireAndForget();
            });
        }, 1);

        securityCenterClientService?.scClient.addLogonInterceptCallback(async () => {
            this.translateService.use(securityCenterClientService.scClient.userLogonLanguage).pipe(take(1), untilDestroyed(this)).subscribe();
            await this.fetchStrings(securityCenterClientService.scClient.userLogonLanguage);
        });
    }

    //#endregion

    //#region Public Methods
    public async getAvailableLanguages(): Promise<Language[]> {
        let languages!: Language[];

        const result = await this.languageClient.getAvailableLanguages().toPromise();
        if (result) {
            languages = Array.from(result);
        }

        return languages;
    }

    public async fetchStrings(lang?: string): Promise<void> {
        // fetch all the strings required by the WebClient

        const result = await this.languageClient.getAllWebClientStrings(lang).toPromise();
        if (result !== null) {
            // note that we downloaded this language
            this.downloaded[lang as string] = true;
            this.translateService.setTranslation(result.language ?? '', result.values ?? {});
            this.useLanguage(result.language ?? '');

            // send an event informing that we are ready
            this.ready.emit();
        }
    }

    public async retrieveCopyrightsAsync(): Promise<string> {
        // look in the cache first
        const text = this.copyrights.get(this.currentLanguage);
        if (text) {
            return text;
        }

        const copyrightsInfo = await this.languageClient.getCopyrights(this.currentLanguage).toPromise();
        if (copyrightsInfo.copyrights) {
            this.copyrights.set(this.currentLanguage, copyrightsInfo.copyrights);
            return copyrightsInfo.copyrights;
        }
        return '';
    }

    public async setLanguage(lang: string): Promise<void> {
        if (this.downloaded[lang] !== undefined) {
            this.useLanguage(lang);
        } else {
            await this.fetchStrings(lang);
        }

        // Set the current language in the cache
        this.window.localStorage.setItem(this.languageBrowserTag, lang);
        this.updateApplicationTitle();
    }

    public setTitle(title: string | StringEntry, ...args: (string | StringEntry)[]): void {
        // keep the current title in case of language change
        this.currentTitle = args;

        const array: (string | StringEntry)[] = [];
        if (title) {
            array.push(title);
        }

        if (args) {
            for (const arg of args) {
                array.push(arg);
            }
        }

        // ensure to wait the languages to be ready
        const baseTitleKey = 'STE_TITLE_SECURITYCENTERWEB';
        const baseTitle = this.translateService.instant(baseTitleKey) as string;

        // maybe the language are not ready yet
        if (baseTitle !== baseTitleKey) {
            array.push(baseTitle);

            const strings: string[] = [];
            for (const item of array) {
                if (item instanceof StringEntry) {
                    strings.push(this.translateService.instant(item.key) as string);
                } else {
                    strings.push(item);
                }
            }

            const fullTitle = strings.join(' - ');
            if (fullTitle) {
                this.titleService.setTitle(fullTitle);
            }
        }
    }

    public useLanguage(lang: string): void {
        const raiseEvent = this.currentLanguage !== lang;

        this.currentLanguage = lang;
        this.translateService
            .use(lang)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                // Set Gelato's language
                this.genTranslateService.init(lang);
            });
        // also apply the change to moment
        moment.locale(lang);

        if (raiseEvent) {
            this.languageChanged.emit();
        }
    }

    private updateApplicationTitle() {
        this.setTitle('', ...this.currentTitle);
    }

    //#endregion
}

export class MissingTranslationHelper implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams): string {
        if (params.interpolateParams) {
            const defaultString = (params.interpolateParams as Record<string, string | undefined>).Default;
            return typeof defaultString === 'string' ? defaultString : params.key;
        }
        return params.key;
    }
}

@Pipe({
    name: 'translate',
})
export class TranslatePipeMock implements PipeTransform {
    public name = 'translate';

    public transform(query: string, ...args: any[]): any {
        return query;
    }
}
