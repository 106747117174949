<app-filter-host [filterDescriptor]="descriptor" [state]="state" [popupPosition]="popupPosition">
    <ul class="p-2">
        <li>
            <gen-radio
                id="anytime_radio_button"
                [name]="descriptor.filterId + '-radio'"
                [value]="dateFilterMode.Any"
                [ngModel]="mode"
                (ngModelChange)="onFilterChange($event)"
                data-cy="datetime-filter-any"
            >
                <gen-label>{{ 'STE_LABEL_ANYTIME' | translate }}</gen-label>
            </gen-radio>
        </li>
        <li *ngIf="showDuringTheLastPeriod">
            <gen-radio
                id="last_period_radio_button"
                [name]="descriptor.filterId + '-radio'"
                [value]="dateFilterMode.DuringLastPeriod"
                [ngModel]="mode"
                (ngModelChange)="onFilterChange($event)"
                data-cy="datetime-filter-during-last"
            >
                <gen-label>{{ 'STE_LABEL_DURINGTHELAST_X_YEARS_MONTHS_WEEKS_HOURS_MINUTES' | translate }}</gen-label>
            </gen-radio>
            <div [hidden]="mode !== dateFilterMode.DuringLastPeriod" class="duration-filter-container">
                <gen-numeric-input [ngModel]="duration" (ngModelChange)="onDurationChange($event)" [min]="1">
                    <gen-message class="fg-color-warning">
                        {{ durationWarning }}
                    </gen-message>
                </gen-numeric-input>
                <gen-combobox [ngModel]="period" (ngModelChange)="onPeriodChange($event)" [genItemsSource]="periodItems"></gen-combobox>
            </div>
        </li>
        <li *ngIf="showForTheNextPeriod">
            <gen-radio
                [name]="descriptor.filterId + '-radio'"
                [value]="dateFilterMode.ForNextPeriod"
                [ngModel]="mode"
                (ngModelChange)="onFilterChange($event)"
                data-cy="datetime-filter-for-next"
            >
                <gen-label>{{ 'STE_LABEL_FORTHENEXT_X_YEARS_MONTHS_WEEKS_HOURS_MINUTES' | translate }}</gen-label>
            </gen-radio>
            <div [hidden]="mode !== dateFilterMode.ForNextPeriod" class="duration-filter-container">
                <gen-numeric-input [ngModel]="duration" (ngModelChange)="onDurationChange($event)" [min]="1" [max]="9999">
                    <gen-message class="fg-color-warning">
                        {{ durationWarning }}
                    </gen-message>
                </gen-numeric-input>
                <gen-combobox [ngModel]="period" (ngModelChange)="onPeriodChange($event)" [genItemsSource]="periodItems"></gen-combobox>
            </div>
        </li>
        <li>
            <gen-radio
                id="range_radio_button"
                [name]="descriptor.filterId + '-radio'"
                [value]="dateFilterMode.Range"
                [ngModel]="mode"
                (ngModelChange)="onFilterChange($event)"
                data-cy="datetime-filter-range"
            >
                <gen-label>{{ 'STE_LABEL_SPECIFICDATE' | translate }}</gen-label>
            </gen-radio>
            <div [hidden]="mode !== dateFilterMode.Range" class="range-filter-container">
                <gen-datetime-input
                    [ngModel]="value.timeStart"
                    (ngModelChange)="onTimeStartChange($event)"
                    [genLabel]="'STE_LABEL_TIMERANGE_FROM' | translate"
                    [genFlavor]="calendarFlavor"
                    [genTimeFrame]="calendarTimeFrame"
                    (click)="onSpecificDateTimeClicked($event, true)"
                    data-cy="datetime-filter-range-from"
                >
                </gen-datetime-input>
                <gen-datetime-input
                    [ngModel]="value.timeEnd"
                    (ngModelChange)="onTimeEndChange($event)"
                    [genLabel]="'STE_LABEL_TIMERANGE_TO' | translate"
                    [genFlavor]="calendarFlavor"
                    [genTimeFrame]="calendarTimeFrame"
                    (click)="onSpecificDateTimeClicked($event, true)"
                    data-cy="datetime-filter-range-to"
                >
                </gen-datetime-input>

                <gen-text *ngIf="invalidDateRange" [flavor]="Flavor.Text.Error">
                    {{ 'STE_MESSAGE_WARNING_INVALIDTIMERANGE' | translate }}
                </gen-text>
            </div>
        </li>
    </ul>
</app-filter-host>
