import { Directive, ElementRef, Renderer2, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { TooltipService } from '@modules/shared/services/tooltip/tooltip.service';
import { coerceBooleanProperty } from '@modules/shared/utilities/coerceBooleanProperty';

/**
 * Directive applying a tooltip for the given element, appearing after a specific amount the mouse is hovering on the element (defined by {@link TooltipDirective.DELAY_MS}).
 *
 * Reuses a previously defined <app-app-tooltip> that must be present in the body tag of the document
 */
@Directive({
    selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy, AfterViewInit {
    public static readonly DELAY_MS = 500;
    public static designSystemProvider: HTMLElement | null;

    @Input() appTooltip?: string | null;

    /**
     * If true, the tooltip will only show if appTooltip is big enough to overflow its tooltip container.
     *
     * @example
     * ```html
     *  <!-- Will NOT show a tooltip because text width < width of its container -->
     *  <span class="long-span" [appTooltip]="shortText" appTooltipOnlyIfOverflow>
     *      {{ shortText }}
     *  </span>
     *
     *  <!-- Shows a tooltip because text width > width of its container -->
     *  <span class="short-span" [appTooltip]="longText" appTooltipOnlyIfOverflow>
     *      {{ longText }}
     *  </span>
     * ```
     */
    @Input()
    get appTooltipOnlyIfOverflow(): boolean {
        return this.isTooltipOnlyIfOverflow;
    }
    set appTooltipOnlyIfOverflow(value: boolean | '') {
        this.isTooltipOnlyIfOverflow = coerceBooleanProperty(value);
    }

    private isTooltipOnlyIfOverflow = false;
    private handlers: Array<() => void> = [];
    private timeout?: number;

    constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2, private tooltipService: TooltipService) {}

    ngAfterViewInit() {
        this.handlers.push(this.renderer.listen(this.elementRef.nativeElement, 'mouseenter', this.onMouseEnter.bind(this)));
        this.handlers.push(this.renderer.listen(this.elementRef.nativeElement, 'mouseleave', this.onMouseLeave.bind(this)));
    }

    ngOnDestroy(): void {
        this.handlers?.forEach((handler) => handler());
        this.handlers.length = 0;
    }

    private onMouseEnter() {
        if (this.appTooltip && (!this.isTooltipOnlyIfOverflow || this.elementRef.nativeElement.offsetWidth < this.elementRef.nativeElement.scrollWidth)) {
            this.timeout = window.setTimeout(() => {
                this.tooltipService.showTooltip(this.appTooltip ?? '', this.elementRef.nativeElement);
            }, TooltipDirective.DELAY_MS);
        }
    }
    private onMouseLeave() {
        window.clearTimeout(this.timeout);
    }
}
