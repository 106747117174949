// Autogenerated: v2/help/enumerations/ApiCameraFeatureCapabilties?codegen=ts&showresources=False on 2022-12-21 2:23:40 PM

export class CameraFeatureCapabilties {
    public static Trickling = 'Trickling';
    public static Backup = 'Backup';
    public static Restore = 'Restore';
    public static EdgeTrickling = 'EdgeTrickling';
    public static Thumbnails = 'Thumbnails';
    public static Bookmarks = 'Bookmarks';
    public static MotionSearch = 'MotionSearch';
    public static DegradeVideo = 'DegradeVideo';
    public static BlockCamera = 'BlockCamera';
    public static LiveVideo = 'LiveVideo';
    public static StreamUsage = 'StreamUsage';
    public static DigitalZoom = 'DigitalZoom';
    public static Snapshot = 'Snapshot';
    public static LiveCache = 'LiveCache';
    public static VideoInfo = 'VideoInfo';
    public static ProtectVideoFile = 'ProtectVideoFile';
    public static Transparency = 'Transparency';
    public static Playback = 'Playback';
    public static NetworkTrace = 'NetworkTrace';
    public static Ping = 'Ping';
    public static Ptz = 'Ptz';
    public static LockPtz = 'LockPtz';
    public static EnhancedPtz = 'EnhancedPtz';
    public static PtzCoordinates = 'PtzCoordinates';
    public static Lens = 'Lens';
    public static CustomIcons = 'CustomIcons';
    public static PtzPriority = 'PtzPriority';
    public static CopyConfig = 'CopyConfig';
    public static MotionDetection = 'MotionDetection';
    public static CameraColor = 'CameraColor';
    public static CameraStreams = 'CameraStreams';
    public static Hardware = 'Hardware';
    public static Recording = 'Recording';
    public static VisualTracking = 'VisualTracking';
    public static ArchiveStorageDetails = 'ArchiveStorageDetails';
    public static VideoPage = 'VideoPage';
    public static SystemStatus = 'SystemStatus';
    public static RecordOnAuxArc = 'RecordOnAuxArc';
    public static RecordOnEdge = 'RecordOnEdge';
    public static ForensicSearch = 'ForensicSearch';
    public static Overlay = 'Overlay';
    public static Encrypted = 'Encrypted';
    public static PtzConnectionShareable = 'PtzConnectionShareable';
    public static DeniedRetrieveCloudArchives = 'DeniedRetrieveCloudArchives';
    public static CameraConfigurationReport = 'CameraConfigurationReport';
    public static CameraEventReport = 'CameraEventReport';
    public static Location = 'Location';
    public static LocationEdit = 'LocationEdit';
    public static PrivacyProtection = 'PrivacyProtection';
    public static CameraSwap = 'CameraSwap';
    public static FeatureLast = 'FeatureLast';

    public static readonly values = [
        CameraFeatureCapabilties.Trickling,
        CameraFeatureCapabilties.Backup,
        CameraFeatureCapabilties.Restore,
        CameraFeatureCapabilties.EdgeTrickling,
        CameraFeatureCapabilties.Thumbnails,
        CameraFeatureCapabilties.Bookmarks,
        CameraFeatureCapabilties.MotionSearch,
        CameraFeatureCapabilties.DegradeVideo,
        CameraFeatureCapabilties.BlockCamera,
        CameraFeatureCapabilties.LiveVideo,
        CameraFeatureCapabilties.StreamUsage,
        CameraFeatureCapabilties.DigitalZoom,
        CameraFeatureCapabilties.Snapshot,
        CameraFeatureCapabilties.LiveCache,
        CameraFeatureCapabilties.VideoInfo,
        CameraFeatureCapabilties.ProtectVideoFile,
        CameraFeatureCapabilties.Transparency,
        CameraFeatureCapabilties.Playback,
        CameraFeatureCapabilties.NetworkTrace,
        CameraFeatureCapabilties.Ping,
        CameraFeatureCapabilties.Ptz,
        CameraFeatureCapabilties.LockPtz,
        CameraFeatureCapabilties.EnhancedPtz,
        CameraFeatureCapabilties.PtzCoordinates,
        CameraFeatureCapabilties.Lens,
        CameraFeatureCapabilties.CustomIcons,
        CameraFeatureCapabilties.PtzPriority,
        CameraFeatureCapabilties.CopyConfig,
        CameraFeatureCapabilties.MotionDetection,
        CameraFeatureCapabilties.CameraColor,
        CameraFeatureCapabilties.CameraStreams,
        CameraFeatureCapabilties.Hardware,
        CameraFeatureCapabilties.Recording,
        CameraFeatureCapabilties.VisualTracking,
        CameraFeatureCapabilties.ArchiveStorageDetails,
        CameraFeatureCapabilties.VideoPage,
        CameraFeatureCapabilties.SystemStatus,
        CameraFeatureCapabilties.RecordOnAuxArc,
        CameraFeatureCapabilties.RecordOnEdge,
        CameraFeatureCapabilties.ForensicSearch,
        CameraFeatureCapabilties.Overlay,
        CameraFeatureCapabilties.Encrypted,
        CameraFeatureCapabilties.PtzConnectionShareable,
        CameraFeatureCapabilties.DeniedRetrieveCloudArchives,
        CameraFeatureCapabilties.CameraConfigurationReport,
        CameraFeatureCapabilties.CameraEventReport,
        CameraFeatureCapabilties.Location,
        CameraFeatureCapabilties.LocationEdit,
        CameraFeatureCapabilties.PrivacyProtection,
        CameraFeatureCapabilties.CameraSwap,
        CameraFeatureCapabilties.FeatureLast
    ];
}
