import { Camera } from '../players/camera';
import { Token } from './Token';
import { ILogger, Logger } from '../utils/logger';
import { ITokenRenewer, TokenRenewer } from './TokenRenewer';

/** Function that will be called by the GWP infrastructure when it requires an access token for a camera.
 * This will be called during the startup of a {@link IWebPlayer} or during the operation when a token renewal is required.
 * @public */
export type TokenRetrieverFct = (camera: string) => Promise<string>;

export class TokenRetriever {
  private readonly m_logger: ILogger;

  private readonly m_tokenRetrieverFct: TokenRetrieverFct;

  constructor(tokenRetrieverFct: TokenRetrieverFct) {
    this.m_logger = new Logger(0, 'TokenRetriever');
    this.m_tokenRetrieverFct = tokenRetrieverFct;
  }

  public async retrieveToken(camera: Camera): Promise<Token> {
    this.m_logger.info?.trace('Requesting token for', camera.toString());
    const requestTime = new Date().getTime();
    const token = new Token(await this.m_tokenRetrieverFct(camera.Id));
    this.m_logger.debug?.trace('Request token for', camera.toString(), 'took', new Date().getTime() - requestTime, 'ms');
    return token;
  }

  public async acquireRenewer(logger: ILogger, camera: Camera): Promise<ITokenRenewer> {
    const initialToken = await this.retrieveToken(camera);
    //Cannot reuse our logger, it does not have the proper id.
    return new TokenRenewer(logger, this, camera, initialToken);
  }
}
