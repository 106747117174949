<div [ngClass]="{ 'more-menu-open': isMoreMenuOpen || this.ptzPopup?.open }">
    <app-timeline #timeline *ngIf="canShowPlayback" [player]="videoPlayer"></app-timeline>

    <div class="bottom-bar" draggable="true">
        <div class="left-bar">
            <div class="title">
                <app-custom-icon slot="gen-icon" [icon]="content?.icon" size="small" [entityId]="content?.source" [customIconId]="content?.customIconId"></app-custom-icon>
                <span appTooltipOnlyIfOverflow [appTooltip]="title">{{ title }}</span>
            </div>

            <div class="buttons-panel left" (appResizeWidth)="computeLeftButtonsVisibility($event.newWidth)">
                <ng-container *ngFor="let button of leftBarVisibleButtons">
                    <gen-button
                        id="left-bar-button-{{ getId(button) }}-{{ componentId }}"
                        style="pointer-events: auto"
                        class="focus-style-override"
                        appDebounceClick
                        appClickStopPropagation
                        [flavor]="ButtonFlavor.Flat"
                        *ngIf="isVisible(button)"
                        [disabled]="isDisabled(button)"
                        [appTooltip]="getText(button)"
                        (debounceClick)="execute(button)"
                        tabindex="0"
                    >
                        <gen-icon [icon]="getIcon(button)" [genColor]="getColor(button)" slot="gen-icon"></gen-icon>
                    </gen-button>
                </ng-container>
            </div>
        </div>

        <div class="time-panel" [ngClass]="{ playback: !isLive }">
            <span class="recordingbutton" *ngIf="(recordingStates$ | async)?.isRecording"></span>
            <button
                type="button"
                class="gen-button flat video-time-button focus-style-override"
                appTooltip="{{ 'STE_BUTTON_GOTOTIME' | translate }}"
                (click)="gotoTime()"
                [disabled]="!canShowPlayback || !isCameraStreaming"
                tabindex="0"
            >
                <span [ngClass]="{ 'video-time-live': isLive, 'video-time-playback': !isLive }">
                    {{ timestampText }}
                </span>
                <i class="gen-ico-angle-down" *ngIf="canShowPlayback && isCameraStreaming"></i>
            </button>
            <div class="details-panel">
                <span class="video-time-ago" *ngIf="!isLive" [ngClass]="{ 'd-none': !relativeTimeText }">{{ relativeTimeText }}</span>
                <span [ngClass]="{ 'd-none': currentSpeed === 1 }">{{ currentSpeed | videoSpeed }}</span>
            </div>
        </div>

        <div class="buttons-panel right" (appResizeWidth)="computeRightButtonsVisibility($event.newWidth)" id="right-bar-buttons-{{ componentId }}">
            <ng-container *ngFor="let button of rightBarVisibleButtons">
                <gen-button
                    id="right-bar-button-{{ getId(button) }}-{{ componentId }}"
                    class="focus-style-override"
                    style="pointer-events: auto"
                    appDebounceClick
                    appClickStopPropagation
                    [flavor]="ButtonFlavor.Flat"
                    *ngIf="isVisible(button)"
                    [appTooltip]="getText(button)"
                    [disabled]="isDisabled(button)"
                    (debounceClick)="execute(button, $event)"
                    tabindex="0"
                >
                    <gen-icon [icon]="getIcon(button)" slot="gen-icon"></gen-icon>
                </gen-button>
            </ng-container>
            <!-- </div> -->

            <gen-button
                id="video-controls-more-{{ componentId }}"
                class="focus-style-override"
                [hidden]="!moreMenuItemSource.length"
                [flavor]="ButtonFlavor.Flat"
                appTooltip="{{ 'STE_BUTTON_SHOWMORE' | translate }}"
                (click)="toggleMoreMenu()"
            >
                <gen-icon [icon]="Icon.EllipsisVertical"></gen-icon>
            </gen-button>
        </div>
    </div>
</div>

<ng-template #PtzPopupTemplate>
    <gen-card ptz__controller-popup-card>
        <gen-card-body>
            <app-ptz-controls [content]="content" [showContextMenu]="true" (hideRequested)="onHidePtzRequested($event)"></app-ptz-controls>
        </gen-card-body>
    </gen-card>
</ng-template>

<app-goto-time #GotoTimeModal [id]="gotoTimeModalId" [hiddenOnInit]="true"></app-goto-time>
