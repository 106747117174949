// Autogenerated: v2/help/Entities/ApiAccessManagerRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:02 AM
import { RoleEntity } from '../Roles/RoleEntity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IAccessManagerRoleEntity, IAccessControlExtension, AccessManagerRoleEntityFields } from '../../Interface/IAccessManagerRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class AccessControlExtension extends FieldObject implements IAccessControlExtension {
    // ExtensionType
    public get extensionType(): IGuid {
        return this.getFieldGuid('ExtensionType');
    }
    public set extensionType(value: IGuid) {
        this.setFieldGuid('ExtensionType', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('ExtensionType', SafeGuid.EMPTY);
    }
}

// #endregion Inner classes

export class AccessManagerRoleEntity extends RoleEntity implements IAccessManagerRoleEntity {
    // #region REST Properties

    // ActivateGlobalAntiPassback
    public get activateGlobalAntiPassback(): boolean {
        return this.getField<boolean>(AccessManagerRoleEntityFields.activateGlobalAntiPassbackField);
    }
    public set activateGlobalAntiPassback(value: boolean) {
        this.setField<boolean>(AccessManagerRoleEntityFields.activateGlobalAntiPassbackField, value);
    }

    // ActivatePeerToPeer
    public get activatePeerToPeer(): boolean {
        return this.getField<boolean>(AccessManagerRoleEntityFields.activatePeerToPeerField);
    }
    public set activatePeerToPeer(value: boolean) {
        this.setField<boolean>(AccessManagerRoleEntityFields.activatePeerToPeerField, value);
    }

    // KeepHistory
    public get keepHistory(): boolean {
        return this.getField<boolean>(AccessManagerRoleEntityFields.keepHistoryField);
    }
    public set keepHistory(value: boolean) {
        this.setField<boolean>(AccessManagerRoleEntityFields.keepHistoryField, value);
    }

    // LogRetentionDays
    public get logRetentionDays(): number {
        return this.getField<number>(AccessManagerRoleEntityFields.logRetentionDaysField);
    }
    public set logRetentionDays(value: number) {
        this.setField<number>(AccessManagerRoleEntityFields.logRetentionDaysField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getExtensionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessControlExtension> | null> {
        return this.getFieldObjectRelationAsync<AccessControlExtension, IAccessControlExtension>(AccessControlExtension, 'Extensions', 'Id', true, false, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

