<div class="appgrid vh-100">
    <div class="gen-login">
        <div id="gen-login-background" style="background-image: url('assets/images/login-background.jpg')"></div>
        <div class="gen-background-grid-fx"></div>
        <div id="gen-login-pane" class="gen-login-pane">
            <div>
                <div>
                    <gen-text [flavor]="TextFlavor.Header3">{{ 'STE_TITLE_WELCOME' | translate: { Default: 'Welcome' } }} </gen-text>
                    <br />
                </div>
                <form #loginForm="ngForm" (ngSubmit)="onSignIn()">
                    <!-- OpenId providers button's -->
                    <div *ngFor="let provider of openIdProviders; let i = index" class="w-100">
                        <a
                            [ngClass]="{ 'authority focus-style-override': true, disabled: waitOnOpenId }"
                            [href]="waitOnOpenId ? '#' : provider.challengeUri"
                            tabindex="0"
                            attr.data-cy="loginPageSsoProviderButton{{ i }}"
                        >
                            <gen-spinner *ngIf="waitOnOpenId; else providerLogo" class="login-spinner va-center m-1"></gen-spinner>
                            <ng-template #providerLogo
                                ><img src="{{ provider.logo }}" [alt]="provider.displayName + ' logo'" />
                                <span appTooltip="{{ ('STE_LABEL_SIGN_IN_WITH' | translate) + provider.displayName }}" [appTooltipOnlyIfOverflow]="true">
                                    {{ 'STE_LABEL_LOG_ON_WITH' | translate }} {{ provider.displayName }}
                                </span>
                            </ng-template>
                        </a>
                    </div>

                    <div *ngIf="openIdProviders.length" class="or-separator">or</div>
                    <gen-text-input
                        [(ngModel)]="userLogin.username.value"
                        [readonly]="isLoggingIn"
                        id="userNameInput"
                        data-cy="userNameInput"
                        #username="ngModel"
                        name="username"
                        appAutoFocus
                        [maxlength]="100"
                    >
                        <gen-label>{{ 'STE_LABEL_USERNAME' | translate: { Default: 'Username' } }}</gen-label>
                        <gen-message [severity]="userLogin.username.severity">{{ userLogin.username.message }}</gen-message>
                    </gen-text-input>

                    <br />
                    <br />

                    <gen-password-input
                        [(ngModel)]="userLogin.password.value"
                        [readonly]="isLoggingIn"
                        id="passwordInput"
                        data-cy="passwordInput"
                        #password="ngModel"
                        name="password"
                        [maxlength]="32"
                    >
                        <gen-label>{{ 'STE_LABEL_PASSWORD' | translate: { Default: 'Password' } }}</gen-label>
                        <gen-message [severity]="userLogin.password.severity">{{ userLogin.password.message }}</gen-message>
                    </gen-password-input>

                    <div class="gen-grid-item form-group pt-5">
                        <gen-button
                            data-cy="loginButton"
                            class="w-100 btn-block"
                            [flavor]="ButtonFlavor.Solid"
                            [type]="ButtonType.Submit"
                            [disabled]="!userLogin.username.value.trim()"
                        >
                            <div class="login-button-inside-wrapper">
                                <gen-spinner *ngIf="isLoggingIn || isWaitingForReload || authService.isLoggingIn" slot="gen-icon" color="#000000" class="login-spinner">
                                </gen-spinner>
                                <span>
                                    {{
                                        isLoggingIn || isWaitingForReload || authService.isLoggingIn
                                            ? ('STE_BUTTON_CANCEL' | translate: { Default: 'Cancel' })
                                            : ('STE_BUTTON_LOGON' | translate: { Default: 'Log on' })
                                    }}
                                </span>
                            </div>
                        </gen-button>
                    </div>

                    <div data-cy="loginStatus" class="pt-3" *ngIf="!hasCancelled">
                        <gen-text [flavor]="TextFlavor.Error" class="ha-center">{{ authService.loginStatus }}</gen-text>
                    </div>
                </form>
            </div>
        </div>

        <div id="gen-login-logo">
            <img src="assets/images/Genetec Web App White.svg" class="ml-1" [alt]="'STE_TOOLTIP_GENETEC_WEBAPP_LOGO' | translate" />
            <div id="gen-login-version">{{ appVersion }}</div>
            <div class="mt-1">
                <!-- About -->
                <gen-button [flavor]="Flavor.Button.Flat" (click)="onAbout()" class="mr-1 focus-style-override inline-block" data-cy="loginPageAboutButton">{{
                    'STE_LABEL_ABOUT' | translate: { Default: 'About' }
                }}</gen-button>
                <!-- Send feedback -->
                <gen-button [flavor]="Flavor.Button.Flat" (click)="onSendFeedback()" class="mr-1 focus-style-override inline-block">{{
                    'STE_LABEL_SEND_FEEDBACK' | translate: { Default: 'Send feedback' }
                }}</gen-button>
            </div>
        </div>
    </div>
</div>
