// Autogenerated: v2/help/Entities/ApiEntity?codegen=ts&InterfaceOnly=False&workflow=False on 2022-02-24 3:03:15 PM
import { RestObject } from './RestObject';
import { FieldObject } from '../../Helpers/FieldObject';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntityNode, IFileCacheItem, IDiagnostic, ICustomIcon, ICustomField, IEntity, EntityFields, IHealthEvent } from '../Interface/IEntity';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ITransaction } from '../Interface/ITransaction';
import { IFieldObject } from '../Interface/IFieldObject';
import { RestResponse } from '../../Connection/RestResponse';
import { SecurityCache } from './SecurityCache';

// #region Inner classes

export class HealthEvent extends FieldObject implements IHealthEvent {
    // HealthEventType
    public get healthEventType(): string {
        return this.getField<string>('HealthEventType');
    }
    public set healthEventType(value: string) {
        this.setField<string>('HealthEventType', value);
    }
    // Timestamp
    public get timestamp(): Date {
        return this.getFieldDate('Timestamp');
    }
    public set timestamp(value: Date) {
        this.setFieldDate('Timestamp', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // Timezone
    public get timezone(): string {
        return this.getField<string>('Timezone');
    }
    public set timezone(value: string) {
        this.setField<string>('Timezone', value);
    }
    // ObserverGuid
    public get observerGuid(): IGuid {
        return this.getFieldGuid('ObserverGuid');
    }
    public set observerGuid(value: IGuid) {
        this.setFieldGuid('ObserverGuid', value);
    }
    // ObserverSourceType
    public get observerSourceType(): string {
        return this.getField<string>('ObserverSourceType');
    }
    public set observerSourceType(value: string) {
        this.setField<string>('ObserverSourceType', value);
    }
    // ObserverServerName
    public get observerServerName(): string {
        return this.getField<string>('ObserverServerName');
    }
    public set observerServerName(value: string) {
        this.setField<string>('ObserverServerName', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('HealthEventType', '');
        this.setFieldDate('Timestamp', new Date());
        this.setField<string>('Description', '');
        this.setField<string>('Timezone', '');
        this.setFieldGuid('ObserverGuid', SafeGuid.EMPTY);
        this.setField<string>('ObserverSourceType', '');
        this.setField<string>('ObserverServerName', '');
    }
}

export class EntityNode extends FieldObject implements IEntityNode {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // EntityType
    public get entityType(): string {
        return this.getField<string>('EntityType');
    }
    public set entityType(value: string) {
        this.setField<string>('EntityType', value);
    }
    // Areas
    public get areas(): ObservableCollection<IEntityNode> {
        return this.getFieldObjectArray<EntityNode, IEntityNode>(EntityNode, 'Areas');
    }
    public set areas(value: ObservableCollection<IEntityNode>) {
        this.setFieldObjectArray('Areas', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Name', '');
        this.setField<string>('EntityType', '');
        this.setFieldArray<IEntityNode>('Areas', new ObservableCollection<IEntityNode>());
    }
}

export class FileCacheItem extends FieldObject implements IFileCacheItem {
    // Data
    public get data(): string {
        return this.getField<string>('Data');
    }
    public set data(value: string) {
        this.setField<string>('Data', value);
    }
    // Context
    public get context(): string {
        return this.getField<string>('Context');
    }
    public set context(value: string) {
        this.setField<string>('Context', value);
    }
    // LocalFilePath
    public get localFilePath(): string {
        return this.getField<string>('LocalFilePath');
    }
    public set localFilePath(value: string) {
        this.setField<string>('LocalFilePath', value);
    }
    // RelatedEntityGuid
    public get relatedEntityGuid(): IGuid {
        return this.getFieldGuid('RelatedEntityGuid');
    }
    public set relatedEntityGuid(value: IGuid) {
        this.setFieldGuid('RelatedEntityGuid', value);
    }
    // FileGuid
    public get fileGuid(): IGuid {
        return this.getFieldGuid('FileGuid');
    }
    public set fileGuid(value: IGuid) {
        this.setFieldGuid('FileGuid', value);
    }
    // FileLength
    public get fileLength(): number {
        return this.getField<number>('FileLength');
    }
    public set fileLength(value: number) {
        this.setField<number>('FileLength', value);
    }
    // LastAccessTimeUtc
    public get lastAccessTimeUtc(): Date {
        return this.getFieldDate('LastAccessTimeUtc');
    }
    public set lastAccessTimeUtc(value: Date) {
        this.setFieldDate('LastAccessTimeUtc', value);
    }
    // Extension
    public get extension(): string {
        return this.getField<string>('Extension');
    }
    public set extension(value: string) {
        this.setField<string>('Extension', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Data', '');
        this.setField<string>('Context', '');
        this.setField<string>('LocalFilePath', '');
        this.setFieldGuid('RelatedEntityGuid', SafeGuid.EMPTY);
        this.setFieldGuid('FileGuid', SafeGuid.EMPTY);
        this.setField<number>('FileLength', 0);
        this.setFieldDate('LastAccessTimeUtc', new Date());
        this.setField<string>('Extension', '');
    }
}

export class Diagnostic extends FieldObject implements IDiagnostic {
    // Result
    public get result(): string {
        return this.getField<string>('Result');
    }
    public set result(value: string) {
        this.setField<string>('Result', value);
    }
    // Message
    public get message(): string {
        return this.getField<string>('Message');
    }
    public set message(value: string) {
        this.setField<string>('Message', value);
    }
    // Details
    public get details(): string {
        return this.getField<string>('Details');
    }
    public set details(value: string) {
        this.setField<string>('Details', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Result', '');
        this.setField<string>('Message', '');
        this.setField<string>('Details', '');
    }
}

export class CustomIcon extends FieldObject implements ICustomIcon {
    // Guid
    public get guid(): IGuid {
        return this.getFieldGuid('Guid');
    }
    public set guid(value: IGuid) {
        this.setFieldGuid('Guid', value);
    }
    // DataSmall
    public get dataSmall(): string {
        return this.getField<string>('DataSmall');
    }
    public set dataSmall(value: string) {
        this.setField<string>('DataSmall', value);
    }
    // DataLarge
    public get dataLarge(): string {
        return this.getField<string>('DataLarge');
    }
    public set dataLarge(value: string) {
        this.setField<string>('DataLarge', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Guid', SafeGuid.EMPTY);
        this.setField<string>('DataSmall', '');
        this.setField<string>('DataLarge', '');
    }
}

export class CustomField extends FieldObject implements ICustomField {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Value
    public get value(): string {
        return this.getField<string>('Value');
    }
    public set value(value: string) {
        this.setField<string>('Value', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Name', '');
        this.setField<string>('Value', '');
    }
}

// #endregion Inner classes

export class Entity extends RestObject implements IEntity {
    // #region REST Properties

    // CanDiagnose
    public get canDiagnose(): boolean {
        return this.getField<boolean>(EntityFields.canDiagnoseField);
    }

    // CreationTime
    public get creationTime(): Date {
        return this.getFieldDate(EntityFields.creationTimeField);
    }

    // CustomIconId
    public get customIconId(): IGuid {
        return this.getFieldGuid(EntityFields.customIconIdField, ValidFlags.EntityExtra);
    }

    // DefaultPartition
    public set defaultPartition(value: IGuid) {
        this.setFieldGuid(EntityFields.defaultPartitionField, value);
    }

    // Description
    public get description(): string {
        return this.getField<string>(EntityFields.descriptionField, ValidFlags.EntityExtra);
    }
    public set description(value: string) {
        this.setField<string>(EntityFields.descriptionField, value);
    }

    // EntityType
    public get entityType(): string {
        return this.getField<string>(EntityFields.entityTypeField);
    }
    public set entityType(value: string) {
        this.setField<string>(EntityFields.entityTypeField, value);
    }

    // FederationLogicalParent
    public get federationLogicalParent(): IGuid {
        return this.getFieldGuid(EntityFields.federationLogicalParentField);
    }

    // HiddenFromUI
    public get hiddenFromUI(): boolean {
        return this.getField<boolean>(EntityFields.hiddenFromUIField);
    }
    public set hiddenFromUI(value: boolean) {
        this.setField<boolean>(EntityFields.hiddenFromUIField, value);
    }

    // Id
    public get id(): IGuid {
        return this.getFieldGuid(EntityFields.idField);
    }

    // IsCopyConfigSupported
    public get isCopyConfigSupported(): boolean {
        return this.getField<boolean>(EntityFields.isCopyConfigSupportedField);
    }

    // IsDeletable
    public get isDeletable(): boolean {
        return this.getField<boolean>(EntityFields.isDeletableField);
    }

    // IsFederated
    public get isFederated(): boolean {
        return this.getField<boolean>(EntityFields.isFederatedField);
    }

    // IsImportedFromExternalSystem
    public get isImportedFromExternalSystem(): boolean {
        return this.getField<boolean>(EntityFields.isImportedFromExternalSystemField, ValidFlags.EntityExtra);
    }

    // IsLogicalEntity
    public get isLogicalEntity(): boolean {
        return this.getField<boolean>(EntityFields.isLogicalEntityField);
    }

    // IsOwnedByPlugin
    public get isOwnedByPlugin(): boolean {
        return this.getField<boolean>(EntityFields.isOwnedByPluginField, ValidFlags.EntityExtra);
    }

    // IsReadOnly
    public get isReadOnly(): boolean {
        return this.getField<boolean>(EntityFields.isReadOnlyField, ValidFlags.EntityExtra);
    }

    // IsSynchronizedFromPlugin
    public get isSynchronizedFromPlugin(): boolean {
        return this.getField<boolean>(EntityFields.isSynchronizedFromPluginField, ValidFlags.EntityExtra);
    }

    // IsSynchronizing
    public get isSynchronizing(): boolean {
        return this.getField<boolean>(EntityFields.isSynchronizingField, ValidFlags.SyncStatus);
    }
    public set isSynchronizing(value: boolean) {
        this.setField<boolean>(EntityFields.isSynchronizingField, value);
    }

    // IsVirtualChild
    public get isVirtualChild(): boolean {
        return this.getField<boolean>(EntityFields.isVirtualChildField, ValidFlags.EntityExtra);
    }

    // LocalName
    public get localName(): string {
        return this.getField<string>(EntityFields.localNameField);
    }

    // LogicalId
    public get logicalId(): number | null {
        return this.getNullableField<number>(EntityFields.logicalIdField, ValidFlags.EntityExtra);
    }
    public set logicalId(value: number | null) {
        this.setNullableField<number>(EntityFields.logicalIdField, value);
    }

    // Maintenance
    public get maintenance(): boolean {
        return this.getField<boolean>(EntityFields.maintenanceField, ValidFlags.Base);
    }
    public set maintenance(value: boolean) {
        this.setField<boolean>(EntityFields.maintenanceField, value);
    }

    // MaintenanceEndTime
    public get maintenanceEndTime(): Date {
        return this.getFieldDate(EntityFields.maintenanceEndTimeField);
    }

    // MaintenanceReason
    public get maintenanceReason(): string {
        return this.getField<string>(EntityFields.maintenanceReasonField);
    }

    // MaxNameLength
    public get maxNameLength(): number {
        return this.getField<number>(EntityFields.maxNameLengthField);
    }

    // Name
    public get name(): string {
        return this.getField<string>(EntityFields.nameField, ValidFlags.EntityExtra);
    }
    public set name(value: string) {
        this.setField<string>(EntityFields.nameField, value);
    }

    // NameDependsOnOtherEntity
    public get nameDependsOnOtherEntity(): boolean {
        return this.getField<boolean>(EntityFields.nameDependsOnOtherEntityField, ValidFlags.EntityExtra);
    }

    // NetworkParent
    public get networkParent(): IGuid {
        return this.getFieldGuid(EntityFields.networkParentField);
    }

    // OriginalDirectory
    public get originalDirectory(): string {
        return this.getField<string>(EntityFields.originalDirectoryField);
    }
    public set originalDirectory(value: string) {
        this.setField<string>(EntityFields.originalDirectoryField, value);
    }

    // OriginalId
    public get originalId(): IGuid {
        return this.getFieldGuid(EntityFields.originalIdField);
    }
    public set originalId(value: IGuid) {
        this.setFieldGuid(EntityFields.originalIdField, value);
    }

    // OriginalName
    public get originalName(): string {
        return this.getField<string>(EntityFields.originalNameField);
    }
    public set originalName(value: string) {
        this.setField<string>(EntityFields.originalNameField, value);
    }

    // OwnerMasterApplication
    public get ownerMasterApplication(): IGuid {
        return this.getFieldGuid(EntityFields.ownerMasterApplicationField);
    }
    public set ownerMasterApplication(value: IGuid) {
        this.setFieldGuid(EntityFields.ownerMasterApplicationField, value);
    }

    // OwnerMasterRole
    public get ownerMasterRole(): IGuid {
        return this.getFieldGuid(EntityFields.ownerMasterRoleField);
    }
    public set ownerMasterRole(value: IGuid) {
        this.setFieldGuid(EntityFields.ownerMasterRoleField, value);
    }

    // OwnerMasterRoleType
    public get ownerMasterRoleType(): string {
        return this.getField<string>(EntityFields.ownerMasterRoleTypeField);
    }

    // OwnerMasterRoleTypeId
    public get ownerMasterRoleTypeId(): IGuid {
        return this.getFieldGuid(EntityFields.ownerMasterRoleTypeIdField);
    }

    // RunningState
    public get runningState(): string {
        return this.getField<string>(EntityFields.runningStateField, ValidFlags.RunningStatus);
    }
    public set runningState(value: string) {
        this.setField<string>(EntityFields.runningStateField, value);
    }

    // Shared
    public get shared(): boolean {
        return this.getField<boolean>(EntityFields.sharedField);
    }
    public set shared(value: boolean) {
        this.setField<boolean>(EntityFields.sharedField, value);
    }

    // SharedOverride
    public get sharedOverride(): boolean {
        return this.getField<boolean>(EntityFields.sharedOverrideField);
    }

    // SupportsMembership
    public get supportsMembership(): boolean {
        return this.getField<boolean>(EntityFields.supportsMembershipField, ValidFlags.EntityExtra);
    }

    // Synchronised
    public get synchronised(): boolean {
        return this.getField<boolean>(EntityFields.synchronisedField);
    }

    // #endregion REST Properties

    // #region Properties

    public get canExecuteRelations(): boolean {
        // only once the entity has an Id ( no Id => local creation before the commit )
        return this.hasField('Id');
    }

    // #endregion Properties

    // #region Constructor

    constructor(entityType?: string) {
        super('v2/Entities');
        if (entityType !== undefined) {
            this.entityType = entityType;
        }
    }

    public loadFrom(source: IEntity) {
        this.securityCache = source.securityCache;
        super.loadFrom(source);
    }

    public loadFieldsFrom(source: IEntity, fields?: Set<string>): void {
        this.securityCache = source.securityCache;
        super.loadFieldsFrom(source, fields);
    }

    // #endregion Constructor

    // #region Methods

    protected fieldLoaded(): void {
        if (this.hasField('Id')) {
            const id = this.getField<string>('Id');
            if (id.length > 0) {
                this.baseUri = 'v2/Entities/' + id;
            }
        }
    }

    /// <summary>
    /// Reload the entity
    /// if ( clearRelationOnly == false )
    ///     All the entity fields are redownloaded
    ///     all the relations are clear and will be redownloaded on the next 'get'
    /// if ( clearRelationOnly == true )
    ///     The fields are NOT redownloaded
    ///     all the relations are clear and will be redownloaded on the next 'get'
    /// </summary>
    /// <param name='clearRelationOnly'></param>
    /// <returns></returns>
    public async reloadAsync(clearRelationOnly = false) {
        if (this.client == null) { throw new Error('client is null'); }

        // Reload the entity with the current loaded fields only
        if (clearRelationOnly === false) {
            const newLoaded = await this.client.getEntityAsync(Entity, this.id, null, null, '*');
            if (!newLoaded) { throw new Error('reloadAsync failed'); }
            this.loadFrom(newLoaded);
        }
        this.clearRelations();
    }

    // #endregion Methods

    // #region REST Methods

    public async getAreasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Areas', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getCopyConfigDataTemplateAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'CopyConfig', false, ValidFlags.None, transaction);
    }

    public async getCustomFieldValuesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomField> | null> {
        return this.getFieldObjectRelationAsync<CustomField, ICustomField>(CustomField, 'CustomFieldValues', 'Id', true, true, ValidFlags.None, transaction);
    }

    public async getCustomIconAsync(transaction?: ITransaction | null): Promise<ICustomIcon | null> {
        return await this.getDataRelationAsync<CustomIcon>(CustomIcon, 'CustomIcon', true, ValidFlags.None, transaction);
    }

    public async getCustomIconsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomIcon> | null> {
        return this.getFieldObjectRelationAsync<CustomIcon, ICustomIcon>(CustomIcon, 'CustomIcons', 'Guid', false, false, ValidFlags.None, transaction);
    }

    public async getDiagnosticsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDiagnostic> | null> {
        return this.getFieldObjectRelationAsync<Diagnostic, IDiagnostic>(Diagnostic, 'Diagnostics', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async enableMaintenanceAsync(endTime: Date, reason: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldDate('endTime', endTime);
        inParam.setField('reason', reason);

        return this.executeRequestTransactionAsync<IRestResponse>('EnableMaintenance', 'PUT', inParam, transaction, responseHandler);
    }

    public async getEntityPrivilegesAsync(transaction?: ITransaction): Promise<Array<IGuid> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IGuid>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IGuid>;
                return new Promise<Array<IGuid>>(function(resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IGuid>>(function(resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<Array<IGuid>>('EntityPrivileges', 'GET', null, transaction, responseHandler);
    }

    public async deleteFilesCacheAsync(id: IGuid, transaction?: ITransaction | null): Promise<IRestResponse> {
        await this.updateDataRelation2Async<FileCacheItem, IFileCacheItem>(FileCacheItem, 'FilesCache/' + id, 'DELETE', true, ValidFlags.None, null);
        return new RestResponse(HttpStatusCode.OK);
    }

    public async getFilesCacheAsync(id: IGuid, transaction?: ITransaction | null): Promise<IFileCacheItem | null> {
        return await this.getDataRelationAsync<FileCacheItem>(FileCacheItem, 'FilesCache/' + id, true, ValidFlags.None, transaction);
    }

    public async addFilesCacheAsync(id: IGuid, value: IFileCacheItem, transaction?: ITransaction | null): Promise<IFileCacheItem | null> {
        return await this.updateDataRelation2Async<FileCacheItem, IFileCacheItem>(FileCacheItem, 'FilesCache/' + id, 'POST', true, ValidFlags.None, value);
    }

    public async getInformationProvidersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('InformationProviders', 'Id', true, query, ValidFlags.InformationProviders, transaction);
    }

    public async getLinkedMapsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('LinkedMaps', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getPartitionsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Partitions', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getRecursiveParentAreaAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEntityNode> | null> {
        return this.getFieldObjectRelationAsync<EntityNode, IEntityNode>(EntityNode, 'RecursiveParentAreas', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async sendHealthEventsAsync(value: Array<IHealthEvent>, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('SendHealthEvents', 'POST', value, transaction, responseHandler);
    }

    public async sendRequestAsync(value: IFieldObject, transaction?: ITransaction): Promise<string | null> {
        const responseHandler = (response: IRestResponse): Promise<string> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body as string;
                return new Promise<string>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<string>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<string>('SendRequest', 'POST', value, transaction, responseHandler);
    }

    // #endregion REST Methods

    // #region Custom Methods

    // tslint:disable-next-line: member-ordering
    public securityCache: SecurityCache | undefined;

    public async hasPrivilegesAsync(privileges: Set<IGuid>, transaction?: ITransaction): Promise<boolean | null> {
        if (!this.securityCache) {
            return false;
        }

        const entityId = this.id;

        let grantedPrivileges: Set<IGuid> | undefined;
        grantedPrivileges = this.securityCache.tryGet(entityId, privileges);
        if (grantedPrivileges) {
            const hasPrivileges = setEvery(privileges, (item) => {
                if (grantedPrivileges) {
                    return grantedPrivileges.has(item);
                }
                return false;
            });
            if (transaction == null) {
                return hasPrivileges;
            } else {
                transaction.addTransactionOperation<boolean>('entityPrivileges', 'GET', null, (response: IRestResponse) => {
                    return new Promise<boolean>(function (resolve, reject) {
                        resolve(hasPrivileges);
                    });
                });
            }
            return false;
        }

        const responseHandler = (response: IRestResponse): Promise<boolean> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const body = response.body as IterableIterator<string>;

                grantedPrivileges = SafeGuid.createSet();
                for (const guidString of body) {
                    const privilegeGuid = SafeGuid.tryParse(guidString);
                    if (privilegeGuid.success) {
                        grantedPrivileges.add(privilegeGuid.value);
                    }
                }
                if (this.securityCache) {
                    this.securityCache.insert(entityId, grantedPrivileges);
                }

                const hasPrivileges = setEvery(privileges, (item) => {
                    if (grantedPrivileges) {
                        return grantedPrivileges.has(item);
                    }
                    return false;
                });
                return new Promise<boolean>(function (resolve, reject) {
                    resolve(hasPrivileges);
                });
            } else {
                return new Promise<boolean>(function (resolve, reject) {
                    reject(response);
                });
            }
        };

        return this.executeRequestTransactionAsync<boolean>('entityPrivileges', 'GET', null, transaction, responseHandler);
    }

    public isOfType<T extends IEntity>(classType: new () => T): this is T {
        return this instanceof classType;
    }

    // #endregion Custom Methods

}

