import { ITilePattern } from 'RestClient/Client/Interface/ITileLayoutEntity';
import { TileBoundary } from './tile-boundary';

export class TilePatternDefinition {
    /** Returns an empty pattern: Single tile, 100% width, 100% height */
    public static readonly singleTile = Object.freeze(new TilePatternDefinition([100], [100], [TileBoundary.topLeft]));

    constructor(public columnDefinitions: number[], public rowDefinitions: number[], public gridCellDefinitions: TileBoundary[]) {}

    /** Converts an ITilePattern object to a string definition */
    public static toDefinition(pattern: ITilePattern): string {
        const colSpans = pattern.columnSpans.values();
        const rowSpans = pattern.rowSpans.values();

        let spans = '';

        // Returns the top left position of each tiles in the grid.
        const topLeftPosisions = this.getTopLeftPositions(pattern);

        for (let tileId = 1; tileId <= colSpans.length; ++tileId) {
            if (colSpans[tileId - 1] !== 1 || rowSpans[tileId - 1] !== 1) {
                spans += ` [${topLeftPosisions[tileId].startColumn}.${topLeftPosisions[tileId].startRow}.${colSpans[tileId - 1]}.${rowSpans[tileId - 1]}]`;
            }
        }

        return `${pattern.columns}x${pattern.rows}${spans}`;
    }

    /** Build a PatternDefinition from cols, rows and spans.
     * cols:    Can represent equally spaces columns ([4] => 4 columns of 25% each)
     *          Can represent different columns with different width [10, 90] => 2 columns having 10% and 90% respectively
     * rows:    Same as cols
     * spans:   Array of span. A span is a length-4 array [colStart, rowStart, colSpan, rowSpan]. colStart and rowStart are 1-based.
     *          For example [[3,2,2,1]] indicates that a cell located at col3, row2 is spanning 2 cols wide and 1 row high.
     */
    public static build(cols: number[], rows: number[], spans?: number[][]): TilePatternDefinition {
        const maxCols = 15;
        const maxRows = 10;

        if ((cols.length === 1 && cols[0] > maxCols) || cols.length > maxCols) {
            console.warn(`Pattern exceeds maximum column count which is set to ${maxCols}.`);
            return TilePatternDefinition.singleTile;
        }

        if ((rows.length === 1 && rows[0] > maxRows) || rows.length > maxRows) {
            console.warn(`Pattern exceeds maximum row count which is set to ${maxRows}.`);
            return TilePatternDefinition.singleTile;
        }

        // TODO: Validate spans. Check if span overlaps, if spans are larger than grid, etc.

        const colsDef = this.sanitizeSpacingDefinitions(cols);
        const rowsDef = this.sanitizeSpacingDefinitions(rows);

        return new TilePatternDefinition(colsDef, rowsDef, this.getGridCellDefinitions(colsDef.length, rowsDef.length, spans));
    }

    /** Creates a TilePatternDefinition from a string definition */
    public static fromDefinition(definition: string): TilePatternDefinition {
        const definitionRegex = /(?<cols>\d+(?::\d+)*)x(?<rows>\d+(?::\d+)*)(?<spans>(?:\s*\[\d+\.\d+\.\d+\.\d+\])*)/;
        const matches = definitionRegex.exec(definition);

        if (matches) {
            const cols = (matches.groups?.cols ?? '1').split(':').map((n) => parseInt(n));
            const rows = (matches.groups?.rows ?? '1').split(':').map((n) => parseInt(n));
            const spans = (matches.groups?.spans ?? '').match(/\d+(?:\.\d+){3}/g)?.map((m) => m.split('.').map((n) => parseInt(n)));

            return this.build(cols, rows, spans);
        }

        console.warn('Tile pattern could not be not reified: ' + definition);
        return TilePatternDefinition.singleTile;
    }

    /** Given an ITilePattern, returns a dictionary indicating the start position (col/row) of a given tile id.
     * Tile id and start col/row are 1-based.
     * For example: dic[4].startColumn === 1 indicates that the forth tile of the pattern is on the left edge of the grid
     */
    private static getTopLeftPositions(pattern: ITilePattern): { [id: number]: TileBoundary } {
        const cellMapping: number[] = [];

        const topLeftLocations: { [id: number]: TileBoundary } = {};

        for (let i = 0; i < pattern.rows * pattern.columns; ++i) {
            cellMapping[i] = 0; // init array with tile id 0 (unknown tile id)
        }

        const colSpans = pattern.columnSpans.values();
        const rowSpans = pattern.rowSpans.values();

        for (let tileId = 1; tileId <= colSpans.length; ++tileId) {
            const colSpan = colSpans[tileId - 1];
            const rowSpan = rowSpans[tileId - 1];

            // First free spot
            const index = cellMapping.indexOf(0);

            topLeftLocations[tileId] = new TileBoundary((index % pattern.columns) + 1, Math.trunc(index / pattern.columns) + 1);

            for (let r = 0; r < rowSpan; ++r) {
                for (let c = 0; c < colSpan; ++c) {
                    cellMapping[index + c + r * pattern.columns] = tileId;
                }
            }
        }

        return topLeftLocations;
    }

    /** Returns an array of TileBoundary defining the boundaries of each cell of the pattern. */
    private static getGridCellDefinitions(colCount: number, rowCount: number, spans?: number[][]): TileBoundary[] {
        const result: TileBoundary[] = [];

        // Populate all cells as 1x1
        for (let i = 0; i < rowCount; ++i) {
            for (let j = 0; j < colCount; ++j) {
                result[i * colCount + j] = new TileBoundary(j + 1, i + 1, j + 2, i + 2);
            }
        }

        if (spans) {
            for (const span of spans) {
                const startCol = span[0] - 1;
                const startRow = span[1] - 1;
                const width = span[2];
                const height = span[3];

                // Set cell span
                const topLeftCellIndex = startRow * colCount + startCol;
                const cell = result[topLeftCellIndex];
                cell.endColumn += width - 1;
                cell.endRow += height - 1;

                // Remove cells that are covered by span
                for (let i = startRow; i < startRow + height; ++i) {
                    for (let j = startCol; j < startCol + width; ++j) {
                        if (topLeftCellIndex !== i * colCount + j) {
                            result[i * colCount + j].startColumn = -1;
                        }
                    }
                }
            }
        }

        return result.filter((r) => r.startColumn > 0);
    }

    /** Returns an arrow of gird line spacings
     * For example:
     *  [3] => [33, 33, 34]
     *  [20, 80] => [20, 80]
     *  [100, 50] => [67, 33]
     */
    private static sanitizeSpacingDefinitions(values: number[]): number[] {
        let result: number[] = [];
        let total: number = values.reduce((a, b) => a + b, 0);
        if (values.length === 1) {
            // Create equally spaced rows/columns
            for (let i = 0; i < values[0]; ++i) {
                result.push(Math.round(100 / values[0]));
            }
        } else {
            // Normalize total size to 100%
            result = values.map((v) => Math.round((v / total) * 100));
        }

        // Adjust last value to get a total of 100%. Might not be the case due to rounding
        total = result.reduce((a, b) => a + b, 0);
        result[result.length - 1] += 100 - total;

        return result;
    }
}
