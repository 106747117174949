// Autogenerated: v2/help/Entities/ApiRole?codegen=ts&InterfaceOnly=False&workflow=False on 2022-02-24 3:03:19 PM
import { Entity } from '../Entity';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { IRoleEntity, IDatabaseInfo, ISecureData, IRoleServer, IDatabaseCreateInfo, RoleEntityFields } from '../../Interface/IRoleEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { IFieldObject } from '../../Interface/IFieldObject';
import { RestResponse } from '../../../Connection/RestResponse';

// #region Inner classes

export class DatabaseInfo extends FieldObject implements IDatabaseInfo {
    // Database
    public get database(): string {
        return this.getField<string>('Database');
    }
    public set database(value: string) {
        this.setField<string>('Database', value);
    }
    // Server
    public get server(): string {
        return this.getField<string>('Server');
    }
    public set server(value: string) {
        this.setField<string>('Server', value);
    }
    // Username
    public get username(): string {
        return this.getField<string>('Username');
    }
    public set username(value: string) {
        this.setField<string>('Username', value);
    }
    // Password
    public get password(): string {
        return this.getField<string>('Password');
    }
    public set password(value: string) {
        this.setField<string>('Password', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Database', '');
        this.setField<string>('Server', '');
        this.setField<string>('Username', '');
        this.setField<string>('Password', '');
    }
}

export class SecureData extends FieldObject implements ISecureData {
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // Payload
    public get payload(): string {
        return this.getField<string>('Payload');
    }
    public set payload(value: string) {
        this.setField<string>('Payload', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Id', '');
        this.setField<string>('Payload', '');
    }
}

export class RoleServer extends FieldObject implements IRoleServer {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // RoleServerNetworkInterface
    public get roleServerNetworkInterface(): string {
        return this.getField<string>('RoleServerNetworkInterface');
    }
    public set roleServerNetworkInterface(value: string) {
        this.setField<string>('RoleServerNetworkInterface', value);
    }
    // RoleServerOrder
    public get roleServerOrder(): number | null {
        return this.getNullableField<number>('RoleServerOrder');
    }
    public set roleServerOrder(value: number | null) {
        this.setNullableField<number>('RoleServerOrder', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('RoleServerNetworkInterface', '');
        this.setField<number>('RoleServerOrder', 0);
    }
}

export class DatabaseCreateInfo extends FieldObject implements IDatabaseCreateInfo {
    // Overwrite
    public get overwrite(): boolean {
        return this.getField<boolean>('Overwrite');
    }
    public set overwrite(value: boolean) {
        this.setField<boolean>('Overwrite', value);
    }
    // Folder
    public get folder(): string {
        return this.getField<string>('Folder');
    }
    public set folder(value: string) {
        this.setField<string>('Folder', value);
    }
    // LogFolder
    public get logFolder(): string {
        return this.getField<string>('LogFolder');
    }
    public set logFolder(value: string) {
        this.setField<string>('LogFolder', value);
    }
    // Database
    public get database(): string {
        return this.getField<string>('Database');
    }
    public set database(value: string) {
        this.setField<string>('Database', value);
    }
    // Server
    public get server(): string {
        return this.getField<string>('Server');
    }
    public set server(value: string) {
        this.setField<string>('Server', value);
    }
    // Username
    public get username(): string {
        return this.getField<string>('Username');
    }
    public set username(value: string) {
        this.setField<string>('Username', value);
    }
    // Password
    public get password(): string {
        return this.getField<string>('Password');
    }
    public set password(value: string) {
        this.setField<string>('Password', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<boolean>('Overwrite', false);
        this.setField<string>('Folder', '');
        this.setField<string>('LogFolder', '');
        this.setField<string>('Database', '');
        this.setField<string>('Server', '');
        this.setField<string>('Username', '');
        this.setField<string>('Password', '');
    }
}

// #endregion Inner classes

export class RoleEntity extends Entity implements IRoleEntity {
    // #region REST Properties

    // ApiUrlPrefixes
    public get apiUrlPrefixes(): ObservableCollection<string> {
        return this.getFieldArray<string>(RoleEntityFields.apiUrlPrefixesField);
    }
    public set apiUrlPrefixes(value: ObservableCollection<string>) {
        this.setFieldArray<string>(RoleEntityFields.apiUrlPrefixesField, value);
    }

    // AutomaticBackupFileCount
    public get automaticBackupFileCount(): number {
        return this.getField<number>(RoleEntityFields.automaticBackupFileCountField);
    }
    public set automaticBackupFileCount(value: number) {
        this.setField<number>(RoleEntityFields.automaticBackupFileCountField, value);
    }

    // AutomaticBackupFrequency
    public get automaticBackupFrequency(): string {
        return this.getField<string>(RoleEntityFields.automaticBackupFrequencyField);
    }
    public set automaticBackupFrequency(value: string) {
        this.setField<string>(RoleEntityFields.automaticBackupFrequencyField, value);
    }

    // AutomaticBackupTime
    public get automaticBackupTime(): number {
        return this.getField<number>(RoleEntityFields.automaticBackupTimeField);
    }
    public set automaticBackupTime(value: number) {
        this.setField<number>(RoleEntityFields.automaticBackupTimeField, value);
    }

    // BackupFolder
    public get backupFolder(): string {
        return this.getField<string>(RoleEntityFields.backupFolderField);
    }
    public set backupFolder(value: string) {
        this.setField<string>(RoleEntityFields.backupFolderField, value);
    }

    // DatabaseFolder
    public get databaseFolder(): string {
        return this.getField<string>(RoleEntityFields.databaseFolderField);
    }
    public set databaseFolder(value: string) {
        this.setField<string>(RoleEntityFields.databaseFolderField, value);
    }

    // DatabaseInstance
    public get databaseInstance(): string {
        return this.getField<string>(RoleEntityFields.databaseInstanceField);
    }
    public set databaseInstance(value: string) {
        this.setField<string>(RoleEntityFields.databaseInstanceField, value);
    }

    // DatabaseName
    public get databaseName(): string {
        return this.getField<string>(RoleEntityFields.databaseNameField);
    }
    public set databaseName(value: string) {
        this.setField<string>(RoleEntityFields.databaseNameField, value);
    }

    // Disabled
    public get disabled(): boolean {
        return this.getField<boolean>(RoleEntityFields.disabledField);
    }
    public set disabled(value: boolean) {
        this.setField<boolean>(RoleEntityFields.disabledField, value);
    }

    // EnableAutomaticBackup
    public get enableAutomaticBackup(): boolean {
        return this.getField<boolean>(RoleEntityFields.enableAutomaticBackupField);
    }
    public set enableAutomaticBackup(value: boolean) {
        this.setField<boolean>(RoleEntityFields.enableAutomaticBackupField, value);
    }

    // EnableBackupCompression
    public get enableBackupCompression(): boolean {
        return this.getField<boolean>(RoleEntityFields.enableBackupCompressionField);
    }
    public set enableBackupCompression(value: boolean) {
        this.setField<boolean>(RoleEntityFields.enableBackupCompressionField, value);
    }

    // EncryptDatabaseConnection
    public get encryptDatabaseConnection(): boolean {
        return this.getField<boolean>(RoleEntityFields.encryptDatabaseConnectionField);
    }
    public set encryptDatabaseConnection(value: boolean) {
        this.setField<boolean>(RoleEntityFields.encryptDatabaseConnectionField, value);
    }

    // EnforceDatabaseServerCertificate
    public get enforceDatabaseServerCertificate(): boolean {
        return this.getField<boolean>(RoleEntityFields.enforceDatabaseServerCertificateField);
    }
    public set enforceDatabaseServerCertificate(value: boolean) {
        this.setField<boolean>(RoleEntityFields.enforceDatabaseServerCertificateField, value);
    }

    // ForceExecutionByPriority
    public get forceExecutionByPriority(): boolean {
        return this.getField<boolean>(RoleEntityFields.forceExecutionByPriorityField);
    }
    public set forceExecutionByPriority(value: boolean) {
        this.setField<boolean>(RoleEntityFields.forceExecutionByPriorityField, value);
    }

    // Group
    public get group(): string {
        return this.getField<string>(RoleEntityFields.groupField);
    }
    public set group(value: string) {
        this.setField<string>(RoleEntityFields.groupField, value);
    }

    // NotificationsDatabaseUsage
    public get notificationsDatabaseUsage(): boolean {
        return this.getField<boolean>(RoleEntityFields.notificationsDatabaseUsageField);
    }
    public set notificationsDatabaseUsage(value: boolean) {
        this.setField<boolean>(RoleEntityFields.notificationsDatabaseUsageField, value);
    }

    // NotificationsDatabaseUsageAsPercentage
    public get notificationsDatabaseUsageAsPercentage(): number {
        return this.getField<number>(RoleEntityFields.notificationsDatabaseUsageAsPercentageField);
    }
    public set notificationsDatabaseUsageAsPercentage(value: number) {
        this.setField<number>(RoleEntityFields.notificationsDatabaseUsageAsPercentageField, value);
    }

    // NotificationsDiskSpace
    public get notificationsDiskSpace(): boolean {
        return this.getField<boolean>(RoleEntityFields.notificationsDiskSpaceField);
    }
    public set notificationsDiskSpace(value: boolean) {
        this.setField<boolean>(RoleEntityFields.notificationsDiskSpaceField, value);
    }

    // NotificationsRemainingGb
    public get notificationsRemainingGb(): number {
        return this.getField<number>(RoleEntityFields.notificationsRemainingGbField);
    }
    public set notificationsRemainingGb(value: number) {
        this.setField<number>(RoleEntityFields.notificationsRemainingGbField, value);
    }

    // NotifyOnlyWhenLimitIsReached
    public get notifyOnlyWhenLimitIsReached(): boolean {
        return this.getField<boolean>(RoleEntityFields.notifyOnlyWhenLimitIsReachedField);
    }
    public set notifyOnlyWhenLimitIsReached(value: boolean) {
        this.setField<boolean>(RoleEntityFields.notifyOnlyWhenLimitIsReachedField, value);
    }

    // OverriddenLoadingStrategy
    public get overriddenLoadingStrategy(): string {
        return this.getField<string>(RoleEntityFields.overriddenLoadingStrategyField);
    }
    public set overriddenLoadingStrategy(value: string) {
        this.setField<string>(RoleEntityFields.overriddenLoadingStrategyField, value);
    }

    // RoleSubType
    public get roleSubType(): IGuid {
        return this.getFieldGuid(RoleEntityFields.roleSubTypeField);
    }
    public set roleSubType(value: IGuid) {
        this.setFieldGuid(RoleEntityFields.roleSubTypeField, value);
    }

    // RoleTypeId
    public get roleTypeId(): IGuid {
        return this.getFieldGuid(RoleEntityFields.roleTypeIdField);
    }
    public set roleTypeId(value: IGuid) {
        this.setFieldGuid(RoleEntityFields.roleTypeIdField, value);
    }

    // Server
    public get server(): IGuid {
        return this.getFieldGuid(RoleEntityFields.serverField, ValidFlags.EntityStatus3);
    }
    public set server(value: IGuid) {
        this.setFieldGuid(RoleEntityFields.serverField, value);
    }

    // SpecificXml
    public get specificXml(): string {
        return this.getField<string>(RoleEntityFields.specificXmlField, ValidFlags.Specific);
    }
    public set specificXml(value: string) {
        this.setField<string>(RoleEntityFields.specificXmlField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Roles);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAgentsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Agents', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async createDatabaseAsync(value: IDatabaseCreateInfo, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('CreateDatabase', 'POST', value, transaction, responseHandler);
    }

    public async createDatabaseBackupAsync(folder: string, enableCompression: boolean, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('folder', folder);
        inParam.setField('enableCompression', enableCompression);

        return this.executeRequestTransactionAsync<IRestResponse>('CreateDatabaseBackup', 'POST', inParam, transaction, responseHandler);
    }

    public async getRoleDatabaseStatusAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'DatabaseStatus', false, ValidFlags.None, transaction);
    }

    public async deleteDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('DeleteDatabase', 'POST', null, transaction, responseHandler);
    }

    public async getOwnershipEntitiesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('OwnershipEntities', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async restoreDatabaseBackupAsync(file: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('file', file);

        return this.executeRequestTransactionAsync<IRestResponse>('RestoreDatabaseBackup', 'POST', inParam, transaction, responseHandler);
    }

    public async getRoleServersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRoleServer> | null> {
        return this.getFieldObjectRelationAsync<RoleServer, IRoleServer>(RoleServer, 'RoleServers', 'Id', false, true, ValidFlags.Membership2, transaction);
    }

    public async deleteSecureDataAsync(id: string, transaction?: ITransaction | null): Promise<IRestResponse> {
        await this.updateDataRelation2Async<SecureData, ISecureData>(SecureData, 'SecureData/' + id, 'DELETE', false, ValidFlags.None, null);
        return new RestResponse(HttpStatusCode.OK);
    }

    public async getSecureDataAsync(id: string, transaction?: ITransaction | null): Promise<ISecureData | null> {
        return await this.getDataRelationAsync<SecureData>(SecureData, 'SecureData/' + id, false, ValidFlags.None, transaction);
    }

    public async addSecureDataAsync(id: string, value: ISecureData, transaction?: ITransaction | null): Promise<ISecureData | null> {
        return await this.updateDataRelation2Async<SecureData, ISecureData>(SecureData, 'SecureData/' + id, 'POST', false, ValidFlags.None, value);
    }

    public async setDatabaseAsync(value: IDatabaseInfo, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('SetDatabase', 'POST', value, transaction, responseHandler);
    }

    public async upgradeDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('UpgradeDatabase', 'POST', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

