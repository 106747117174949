<div (window:resize)="onResize($event)" class="timeline-container">
    <div>
        <button class="gen-button flat" appTooltip="{{ 'STE_TOOLTIP_BUTTON_QUERYINVISIBLETIMERANGE' | translate }}" genStopPropagation type="button" (click)="onRequery()">
            <i class="gen-ico-reset"></i>
        </button>
    </div>
    <div class="tooltip" [hidden]="currentBin === null" [style.left.px]="cursorX">
        {{ currentBinCount }}
        <div class="arrow"></div>
        <div class="line"></div>
    </div>
    <div
        #maindiv
        class="timeline-maindiv"
        [style.cursor]="isPanning ? 'col-resize' : 'default'"
        [appMouseCapture]="captureMouse"
        (pointerdown)="onPointerDown($event)"
        (pointerup)="onPointerUp($event)"
        (pointermove)="onPointerMove($event)"
        (mouseup)="onMouseUp($event)"
        (wheel)="onMouseWheel($event)"
    >
        <canvas #canvas class="drawingcanvas"></canvas>
        <canvas #ticks class="tickscanvas"></canvas>
    </div>
    <div>
        <button class="gen-button flat timeline-button" genStopPropagation type="button" (click)="onZoomIn()">
            <i class="gen-ico-plus sm"></i>
        </button>
        <gen-separator></gen-separator>
        <button class="gen-button flat timeline-button" genStopPropagation type="button" (click)="onZoomOut()">
            <i class="gen-ico-minus sm"></i>
        </button>
    </div>
</div>
