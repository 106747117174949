// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
import { Constants } from '../../shared/utilities/constants';

export const SharedContentFields = Constants({
    DomainEventType: 'DomainEventType',
    EventType: 'EventType',
    SourceEntityGuid: 'SourceEntityGuid',
    SourceEntityName: 'SourceEntityName',
    Timestamp: 'Timestamp',
    Timezone: 'Timezone',
    EventId: 'EventId',
    ContextId: 'ContextId',
});
