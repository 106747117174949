<gen-melted-modal
    [genTitle]="title"
    [genDefaultActionText]="actionText"
    [genAlternativeActionText]="cancelText ?? ''"
    [genIsDefaultActionDisabled]="!isValid"
    [genCanClose]="true"
    [genIsBlocking]="true"
>
    <gen-melted-modal-body>
        <gen-text-input [(ngModel)]="message" (ngModelChange)="onChange($event)">
            <gen-label>{{ 'STE_LABEL_BOOKMARK_MESSAGE' | translate }}</gen-label>
        </gen-text-input>
    </gen-melted-modal-body>
</gen-melted-modal>
