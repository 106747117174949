import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { GenAngularModule } from '@genetec/gelato-angular';
import { GetBaseUriForCodeGen } from '@utilities/base-uri-getter';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { enableMapSet } from 'immer';
import { FeatureFlagsModule } from '@modules/feature-flags/feature-flags.module';
import { AccessStatusToColorPipe } from '@modules/shared/pipes/access-status-to-color/access-status-to-color.pipe';
import { PortalModule } from '@angular/cdk/portal';
import { NgxsModule } from '@ngxs/store';
import {
    baseUrl,
    FilterClient,
    OptionsClient,
    NotificationsClient,
    ResourcesClient,
    SearchClient,
    TasksClient,
    TimezonesClient,
    VersionClient,
    UserWatchlistClient,
    EventsClient,
    ThreatLevelClient,
} from './api/api';
import { CommandButtonComponent } from './components/command-button/command-button.component';
import { CommandButtonsComponent } from './components/command-buttons/command-buttons.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { EntityBrowserKeyboardNavigationDirective } from './components/entity-browser/entity-browser-keyboard-navigation.directive';
import { EntityBrowserTaskComponent } from './components/entity-browser/entity-browser-task/entity-browser-task.component';
import { EntityBrowserComponent } from './components/entity-browser/entity-browser/entity-browser.component';
import { EntityComboComponent } from './components/entity-browser/entity-combo/entity-combo.component';
import { FilterDateComponent } from './components/filters/common-filters/filter-date/filter-date.component';
import { FilterEntityComponent } from './components/filters/common-filters/filter-entity/filter-entity.component';
import { FilterEnumComponent } from './components/filters/common-filters/filter-enum/filter-enum.component';
import { FilterListComponent } from './components/filters/common-filters/filter-list/filter-list.component';
import { FilterNumericRangeComponent } from './components/filters/common-filters/filter-numeric-range/filter-numeric-range.component';
import { FilterTextComponent } from './components/filters/common-filters/filter-text/filter-text.component';
import { FilterTreeViewComponent } from './components/filters/common-filters/filter-tree-view/filter-tree-view.component';
import { HideableFiltersContainerComponent } from './components/filters/common-filters/hideable-filters-container/hideable-filters-container.component';
import { FilterHostComponent } from './components/filters/filter-host/filter-host.component';
import { FiltersComponent } from './components/filters/filters.component';
import { GenericSliderComponent } from './components/generic-slider/generic-slider.component';
import { ImageViewerModalComponent } from './components/image-viewer-modal/image-viewer-modal.component';
import { MainSearchComponent } from './components/main-search/main-search.component';
import { MenuItemsComponent } from './components/menu-item/menu-item.component';
import { MultiPluginHostComponent } from './components/plugins/multi-plugin-host/multi-plugin-host.component';
import { NavigationPluginHostComponent } from './components/plugins/navigation-plugin-host/navigation-plugin-host.component';
import { PluginHostComponent } from './components/plugins/plugin-host.component';
import { ShareEntityModalComponent } from './components/share-entity-modal/share-entity-modal.component';
import { SidePaneComponent } from './components/side-pane/side-pane.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TrackedGenModalComponent } from './components/tracked/tracked-gen-modal.component';
import { TrackedComponent } from './components/tracked/tracked.component';
import { TreeItemsComponent } from './components/tree-items/tree-items.component';
import { EntityCommandsWidgetHostComponent } from './components/widgets/entity-commands-widget-host/entity-commands-widget-host.component';
import { LatestEventsWidgetComponent } from './components/widgets/latest-events-widget/latest-events-widget.component';
import { EventItemComponent } from './components/events/event-item/event-item.component';
import { CaptureEventsDirective } from './directives/captureEvents.directive';
import { AutoFocusDirective } from './directives/appAutoFocus.directive';
import { DebounceDirective } from './directives/appDebounce.directive';
import { ClickActionOnSpaceEnterDirective } from './directives/click-action-on-space-enter.directive';
import { DebounceClickDirective } from './directives/debounceClick.directive';
import { FocusModalWhenOpenDirective } from './directives/focus-modal-when-open.directive';
import { FocusPopupWhenOpenDirective } from './directives/focus-popup-when-open.directive';
import { HoverClassDirective } from './directives/hover-class/hover-class.directive';
import { MenuDataSourceDirective } from './directives/menu-data-source/menu-data-source.directive';
import { MouseCaptureDirective } from './directives/mousecapture/mousecapture.directive';
import { ResizeElementDirective } from './directives/resize-element/resize-element.directive';
import { ResizeWidthDirective } from './directives/resized/resize-width.directive';
import { ResizeDirective } from './directives/resized/resize.directive';
import { ClickStopPropagationDirective } from './directives/stopClickPropagation.directive';
import { KeydownStopPropagationDirective } from './directives/stopKeydownPropagation.directive';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { TreeDataSourceDirective } from './directives/tree-data-source/tree-data-source.directive';
import { ModuleDescriptor } from './interfaces/modules/public/module-public.interface';
import { ArgbPipe } from './pipes/argb.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { StringFormatPipe } from './pipes/string-format.pipe';
import { StringToIconPipe } from './pipes/string-to-icon.pipe';
import { AudioService } from './services/audio/audio.service';
import { LoggerService } from './services/logger/logger.service';
import { TableComponent } from './components/table/table.component';
import { WatchlistEditorComponent } from './components/watchlist/editor/watchlist-editor/watchlist-editor.component';
import { GroupBy } from './pipes/group-by.pipe';
import { WatchlistComponent } from './components/watchlist/watchlist.component';
import { GelatoInvalidityCompatDirective } from './directives/gelato-invalidity-compat/gelato-invalidity-compat.directive';
import { ApplicationInsightsFeatureFlags } from './services/analytics/feature-flags';
import { EventMonitoringFeatureFlags } from './services/events/feature-flags';
import { FormControlErrorDirective } from './directives/form-control-error.directive';
import { EventListComponent } from './components/events/event-list/event-list.component';
import { AccessStatusToTranslatedStringPipe } from './pipes/access-status-to-translated-string/access-status-to-translated-string.pipe';
import { ModalEntitiesSelectorComponent } from './components/entity-browser/modal-entities-selector/modal-entities-selector.component';
import { FormGenComboboxComponent } from './components/form-gen-combobox/form-gen-combobox.component';
import { ThreatLevelsTrayComponent } from './components/threat-levels-tray/threat-levels-tray.component';
import { ThreatLevelModalComponent } from './components/threat-levels-modal/threat-level-modal.component';
import { ThreatLevelConfirmationModalComponent } from './components/threat-levels-modal/threat-level-confirmation-modal/threat-level-confirmation-modal.component';
import { BoldStringPipe } from './pipes/bold-string.pipe';
import { EntityStateItemComponent } from './components/entity-state-item/entity-state-item.component';
import { LuminancePipe } from './pipes/luminance.pipe';
import { SidePaneHeaderComponent } from './components/side-pane/sidepane-header/sidepane-header.component';
import { TimePipe } from './pipes/time.pipe';
import { PortalOutletComponent } from './portal-outlet/portal-outlet.component';
import { AppTooltipComponent } from './components/tooltip/app-tooltip.component';
import { DisableDefaultContextMenuDirective } from './directives/appDisableDefaultContextMenu.directive';
import { NgLetDirective } from './directives/ng-let.directive';
import { WEB_SOCKET_SERVICE } from './services/web-sockets/web-socket.service';
import { WEB_SOCKET_SERIALIZER_SERVICE } from './services/web-sockets/web-socket-serializer.service';
import { WebSocketJsonSerializerService } from './services/web-sockets/web-socket-json-serializer.service';
import { SCClientWebSocketService } from './services/web-sockets/sc-client-web-socket.service';
import { FiltersState } from './store/filters.state';
import { FiltersContext } from './store/filters.context';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { FilterSearchComponent } from './components/filters/common-filters/filter-search/filter-search.component';
import { ScrollOnExpandDirective } from './directives/scroll-on-expand.directive';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@NgModule({
    declarations: [
        MainSearchComponent,
        PluginHostComponent,
        MultiPluginHostComponent,
        NavigationPluginHostComponent,
        EntityBrowserComponent,
        EntityComboComponent,
        EntityBrowserTaskComponent,
        TimelineComponent,
        MouseCaptureDirective,
        TooltipDirective,
        FilterPipe,
        SafePipe,
        SortByPipe,
        ArgbPipe,
        LuminancePipe,
        StringFormatPipe,
        BoldStringPipe,
        StringToIconPipe,
        AccessStatusToColorPipe,
        AccessStatusToTranslatedStringPipe,
        MultiPluginHostComponent,
        TrackedComponent,
        TrackedGenModalComponent,
        ShareEntityModalComponent,
        HoverClassDirective,
        ResizeDirective,
        ResizeWidthDirective,
        ResizeElementDirective,
        EntityCommandsWidgetHostComponent,
        FilterHostComponent,
        ImageViewerModalComponent,
        FiltersComponent,
        FilterDateComponent,
        FilterListComponent,
        FilterEntityComponent,
        FilterTextComponent,
        FilterEnumComponent,
        FilterTreeViewComponent,
        FilterNumericRangeComponent,
        GenericSliderComponent,
        CommandButtonsComponent,
        LatestEventsWidgetComponent,
        EntityBrowserKeyboardNavigationDirective,
        CommandButtonComponent,
        HideableFiltersContainerComponent,
        CaptureEventsDirective,
        AutoFocusDirective,
        DebounceDirective,
        ClickStopPropagationDirective,
        KeydownStopPropagationDirective,
        FocusModalWhenOpenDirective,
        FocusPopupWhenOpenDirective,
        ClickActionOnSpaceEnterDirective,
        DebounceClickDirective,
        SidePaneComponent,
        CustomIconComponent,
        MenuItemsComponent,
        TreeItemsComponent,
        MenuDataSourceDirective,
        DisableDefaultContextMenuDirective,
        TreeDataSourceDirective,
        TableComponent,
        EventItemComponent,
        WatchlistEditorComponent,
        EventListComponent,
        GroupBy,
        WatchlistComponent,
        GelatoInvalidityCompatDirective,
        FormControlErrorDirective,
        ModalEntitiesSelectorComponent,
        FormGenComboboxComponent,
        ThreatLevelsTrayComponent,
        ThreatLevelModalComponent,
        ThreatLevelConfirmationModalComponent,
        EntityStateItemComponent,
        SidePaneHeaderComponent,
        TimePipe,
        PortalOutletComponent,
        AppTooltipComponent,
        NgLetDirective,
        ThumbnailComponent,
        FilterSearchComponent,
        ScrollOnExpandDirective,
    ],
    imports: [
        CommonModule,
        GenAngularModule,
        BrowserAnimationsModule,
        ScrollingModule,
        FeatureFlagsModule.forRoot(ApplicationInsightsFeatureFlags, EventMonitoringFeatureFlags),
        PortalModule,
        NgxsModule.forFeature([FiltersState]),
    ],
    exports: [
        MainSearchComponent,
        PluginHostComponent,
        MultiPluginHostComponent,
        NavigationPluginHostComponent,
        EntityBrowserComponent,
        EntityComboComponent,
        TimelineComponent,
        GenAngularModule,
        MouseCaptureDirective,
        TooltipDirective,
        FilterPipe,
        SafePipe,
        SortByPipe,
        ArgbPipe,
        LuminancePipe,
        StringFormatPipe,
        BoldStringPipe,
        StringToIconPipe,
        AccessStatusToColorPipe,
        AccessStatusToTranslatedStringPipe,
        TrackedComponent,
        TrackedGenModalComponent,
        HoverClassDirective,
        ResizeDirective,
        ResizeWidthDirective,
        ResizeElementDirective,
        EntityCommandsWidgetHostComponent,
        FilterHostComponent,
        ImageViewerModalComponent,
        FiltersComponent,
        GenericSliderComponent,
        CommandButtonsComponent,
        LatestEventsWidgetComponent,
        FilterDateComponent,
        FilterListComponent,
        FilterEntityComponent,
        FilterTextComponent,
        FilterEnumComponent,
        FilterTreeViewComponent,
        FilterNumericRangeComponent,
        HideableFiltersContainerComponent,
        CaptureEventsDirective,
        ClickStopPropagationDirective,
        KeydownStopPropagationDirective,
        FocusModalWhenOpenDirective,
        FocusPopupWhenOpenDirective,
        ClickActionOnSpaceEnterDirective,
        DebounceClickDirective,
        DisableDefaultContextMenuDirective,
        AutoFocusDirective,
        DebounceDirective,
        SidePaneComponent,
        CustomIconComponent,
        MenuDataSourceDirective,
        TreeDataSourceDirective,
        TableComponent,
        EventItemComponent,
        GroupBy,
        GelatoInvalidityCompatDirective,
        FormControlErrorDirective,
        ModalEntitiesSelectorComponent,
        FormGenComboboxComponent,
        SidePaneHeaderComponent,
        TimePipe,
        PortalOutletComponent,
        AppTooltipComponent,
        NgLetDirective,
        ThumbnailComponent,
        FilterSearchComponent,
        ScrollOnExpandDirective,
    ],
    providers: [
        LoggerService,
        VersionClient,
        TimezonesClient,
        TasksClient,
        SearchClient,
        NotificationsClient,
        ResourcesClient,
        AudioService,
        OptionsClient,
        FilterClient,
        /* NOTE: Do not inject the FeaturesClient here, it's injected globally in app.module.ts for EVERYONE since FeaturesService is global for everyone */
        { provide: baseUrl, deps: [], useFactory: GetBaseUriForCodeGen },
        UserWatchlistClient,
        EventsClient,
        ThreatLevelClient,
        FiltersContext,
        { provide: WEB_SOCKET_SERVICE, useClass: SCClientWebSocketService },
        { provide: WEB_SOCKET_SERIALIZER_SERVICE, useClass: WebSocketJsonSerializerService },
    ],
})
@ModuleDescriptor({
    type: SharedModule,
    inclusions: {
        globalPrivileges: [KnownPrivileges.managePrivateTasksPrivilege, KnownPrivileges.modifyUserTasksPrivilege],
    },
})
export class SharedModule {
    constructor() {
        // Load map and set plugin in immer
        enableMapSet();
    }

    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [{ provide: 'logSeverities', useValue: environment.logSeverities }],
        };
    }
}
