import { OnInit, Component, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboboxFlavor, Gelato, GenPopup, LabelComplement } from '@genetec/gelato-angular';
import { ButtonFlavor, Icon, PopupPosition } from '@genetec/gelato';
import { TranslateService } from '@ngx-translate/core';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { AccessPointEntityFields } from 'RestClient/Client/Interface/IAccessPointEntity';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { AccessPointEntity } from 'RestClient/Client/Model/AccessControl/AccessPointEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { EntityBrowserCheckSelection, EntityBrowserSelection } from '../../../entity-browser/entity-browser-selection';
import { EntityBrowserService } from '../../../entity-browser/entity-browser.service';
import { EntityBrowserFilter } from '../../../entity-browser/filters/entity-browser-filter';
import { EntityBrowserItemModel } from '../../../entity-browser/Items/entity-browser-item-model';
import { IconsService } from '../../../services/icons.service';
import { coerceBooleanProperty } from '../../../utilities/coerceBooleanProperty';
import { stringFormat } from '../../../utilities/StringFormat';
import { EntityBrowserComponent } from '../entity-browser/entity-browser.component';
import { EntityBrowserItem } from '../items/entity-browser-item';
// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-entity-combo',
    templateUrl: './entity-combo.component.html',
    styleUrls: ['./entity-combo.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: EntityComboComponent,
            multi: true,
        },
    ],
})
export class EntityComboComponent extends Gelato implements OnInit, OnChanges {
    //#region Fields

    @ViewChild('combo')
    public comboElement!: ElementRef<HTMLElement>;

    @ViewChild('entityBrowser')
    public entityBrowser!: EntityBrowserComponent;

    @ViewChild(GenPopup)
    public popup!: GenPopup;

    @Input()
    public entityId?: IGuid;

    @Input()
    public entityIds: IGuid[] = [];

    @Input() public entityBrowserCanSelectItemFunction!: (model: EntityBrowserItemModel) => Promise<boolean>;
    @Input() public entityBrowserFilter!: EntityBrowserFilter;
    @Input() public showClearButton!: boolean;

    /**
     * Disallows user interaction
     */
    @Input() public disabled!: boolean;
    /**
     * Style of element
     *
     * Ex: Flavor.Combobox.Flat
     */
    @Input() public genFlavor = ComboboxFlavor.Solid;
    /**
     * String to xdescribe the accociated component
     */
    @Input() public genLabel!: string;

    /**
     * Label complement
     *
     * Ex: _Complement.Optional_
     */
    @Input() public genLabelComplement!: LabelComplement;

    /**
     * Give more information about the unit of the value
     */
    @Input() public genSuffix!: string;

    /**
     * Text to show when nothing is selected.
     */
    @Input() public genPlaceholder!: string;

    /**
     * Supports selecting multiple entities in 1 combo
     */
    @Input()
    public get multiSelect(): boolean {
        return this.isMultiSelect;
    }
    public set multiSelect(value: boolean) {
        this.isMultiSelect = coerceBooleanProperty(value);
    }

    /**
     * event triggers every time a selected entity guid change occurs
     */
    @Output() public entityIdChange = new EventEmitter<IGuid>();

    /**
     * event triggers every time a group of selected entities change occurs
     */
    @Output() public entityIdsChange = new EventEmitter<IGuid[]>();

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly PopupPosition = PopupPosition;

    public icon: Icon | string | null = null;
    public displayText = 'N/A';
    public entityCustomId?: IGuid;

    #entityIds: IGuid[] = [];
    private isMultiSelect = false;
    private wasBrowserRefreshed = false;

    //#endregion

    //#region Constructor

    constructor(
        private securityCenterClientService: SecurityCenterClientService,
        private iconsService: IconsService,
        private translateService: TranslateService,
        private entityBrowserService: EntityBrowserService
    ) {
        super();
        this.displayText = this.translateService.instant('STE_TOOLTIP_SELECTENTITY') as string;
    }

    //#endregion

    //#region Methods
    public async ngOnInit(): Promise<void> {
        // if the user didn't supply a filter, use the "default" one
        if (!this.entityBrowserFilter) {
            this.entityBrowserFilter = new EntityBrowserFilter(await this.entityBrowserService.getDefaultLogicalTypesAsync());
            this.setEntitiesInFilter();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.entityId && changes.entityId.previousValue !== changes.entityId.currentValue) {
            const guid = toGuid(changes.entityId.currentValue);
            this.refreshEntities(guid);
            this.entityIds = guid.isEmpty() ? [] : [guid];
        } else if (changes.entityIds) {
            const entityIds = this.toGuids(changes.entityIds.currentValue as IGuid[]);
            this.emitChangeEventIfNecessary(...entityIds);
            this.refreshEntities(...entityIds);
            delete this.entityId;
        }
    }

    public async toggleComboOpen(): Promise<void> {
        await this.popup.toggle();
        // reload the entity browser with the applied filter & optionally the selected entity(s)
        if (this.popup.open && !this.wasBrowserRefreshed) {
            await this.entityBrowser.refreshAsync();
            this.wasBrowserRefreshed = true;
        }
    }

    public async clearSelection(): Promise<void> {
        this.entityIds = [];
        delete this.entityId;

        this.resetItem();
        this.entityBrowser.onUncheckAllItemsClicked();

        this.entityIdChange.emit(SafeGuid.EMPTY);
        this.entityIdsChange.emit([]);

        await this.popup.close();
    }

    //#region Events

    public onEntitySelected(selection: EntityBrowserSelection): void {
        if (this.multiSelect || !selection.singleItem) {
            return;
        }

        this.setSelectedEntity(selection.singleItem);
        this.popup.close().fireAndForget();
    }

    public onEntityChecksChanged(selection: EntityBrowserCheckSelection): void {
        if (!this.multiSelect) return;
        this.entityIds = selection.items.map((model) => model.id);

        // ngOnChanges not called when this.entityIds changes...
        this.emitChangeEventIfNecessary(...this.entityIds);
        this.refreshEntities(...this.entityIds);
    }

    //#endregion

    private setSelectedEntity(entity: EntityBrowserItem) {
        // Don't need to refresh since we already have info from entityBrowser, properties set manually
        const entityGuid = toGuid(entity.itemId);
        this.emitChangeEventIfNecessary(entityGuid);
        this.displayText = entity.model.name;
        this.entityCustomId = entity.model.customIconId;
        this.icon = entity.icon as string;
        this.#entityIds = [toGuid(entity.itemId)];
        this.setEntitiesInFilter();
    }

    /**
     * Updates the selected entity ids and loads corresponding info, updating the selected item's text and icon
     *
     * @param entityIds - The new entity ids to refresh the combobox with
     */
    private refreshEntities(...entityIds: IGuid[]) {
        if (!entityIds?.length) {
            // there are no selected entities, reset the selection combo value
            this.displayText = this.translateService.instant('STE_TOOLTIP_SELECTENTITY') as string;
            this.icon = Icon.None;
            delete this.entityCustomId;
        } else if (!this.multiSelect || entityIds.length === 1) {
            this.loadEntityInfos(entityIds[0]).fireAndForget();
        } else {
            // there are > 1 entity, display the "N entities selected" text
            this.displayText = stringFormat(this.translateService.instant('STE_LABEL_N_ENTITIESSELECTED') as string, entityIds.length.toString());
            this.icon = Icon.CheckAll;
        }
        this.#entityIds = entityIds ?? [];
        this.setEntitiesInFilter();
    }

    private async loadEntityInfos(entityId: IGuid) {
        const previousEntityId = this.#entityIds[0];
        if (previousEntityId?.equals(entityId)) {
            // the guid hasn't changed, don't do any kind of refresh
            if (entityId.isEmpty()) {
                this.resetItem();
            }
            return;
        }

        // a refresh is needed, wipe the old information and gather the entity info
        // this.entityObject = null;
        this.displayText = '';
        let entity;
        if (!entityId.isEmpty()) {
            const entityFieldsToDownload = [
                EntityFields.entityTypeField,
                EntityFields.idField,
                EntityFields.nameField,
                EntityFields.descriptionField,
                EntityFields.customIconIdField,
                AccessPointEntityFields.accessPointTypeField,
            ];
            entity = await this.securityCenterClientService.scClient.getEntityAsync(Entity, entityId, null, null, ...entityFieldsToDownload);
        }

        if (entity) {
            this.displayText = entity.name;
            this.entityCustomId = entity.customIconId;
            // We need to get the subtype of the entity to display the correct icon
            if (entity.entityType === EntityTypes.AccessPoints) {
                const accessPoint = entity as AccessPointEntity;
                this.icon = this.iconsService.getEntityTypeIcon(accessPoint?.entityType, accessPoint.accessPointType);
            } else {
                this.icon = this.iconsService.getEntityIcon(entity);
            }
        } else {
            this.resetItem();
        }
    }

    private setEntitiesInFilter() {
        if (!this.entityBrowserFilter) {
            return;
        }

        if (this.multiSelect) {
            this.entityBrowserFilter.checkedEntityIds = SafeGuid.createSet(this.entityIds);
        } else {
            this.entityBrowserFilter.selectedEntityIds = SafeGuid.createSet(this.entityIds);
        }
    }

    private resetItem() {
        delete this.entityCustomId;
        this.displayText = this.translateService.instant('STE_TOOLTIP_SELECTENTITY') as string;
        this.icon = Icon.None;
    }

    private emitChangeEventIfNecessary(...entities: EntityBrowserItem[] | IGuid[]) {
        const previousEntityIds = this.#entityIds;
        const entityIds = this.toGuids(entities);
        // identify changes
        if (entityIds.length !== previousEntityIds.length || !entityIds.every((guid) => previousEntityIds.some((pguid) => pguid.equals(guid)))) {
            this.setEntitiesInFilter();

            if (this.isMultiSelect) {
                this.entityIdsChange.emit(entityIds);
            } else if (entityIds.length > 0) {
                this.entityIdChange.emit(entityIds[0]);
                this.entityIdsChange.emit(entityIds);
            } else {
                this.entityIdChange.emit(SafeGuid.EMPTY);
                this.entityIdsChange.emit([]);
            }
        }
    }

    private toGuids(entities: EntityBrowserItem[] | IGuid[]) {
        if (entities.length) {
            if (isGuid(entities[0])) {
                return entities as IGuid[];
            } else {
                return (entities as EntityBrowserItem[]).map((entity) => entity.model.id);
            }
        }
        return [];
    }

    //#endregion
}
