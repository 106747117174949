import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { ExpirationMode } from 'RestClient/Client/Enumerations/ExpirationMode';

export enum ActiveExpirationModeLabel {
    DontExpire = 'STE_LABEL_NEVER',
    Expiring = 'STE_LABEL_SPECIFIC_DATE',
    ExpiredAfterActivation = 'STE_LABEL_DAYSAFTERFIRSTUSE',
    WhenNotUsed = 'STE_LABEL_DAYSAFTERLASTUSE',
}
export enum InactiveExpirationModeLabel {
    DontExpire = 'STE_LABEL_NEVER',
    ActivateOn = 'STE_LABEL_SPECIFIC_DATE',
}

export const stringToExpirationModeKey = (expModeLabel: string | undefined, accessStatus: string): string => {
    if (accessStatus === AccessStatus.Inactive) {
        switch (expModeLabel) {
            case InactiveExpirationModeLabel.DontExpire:
                return ExpirationMode.DontExpire;
            case InactiveExpirationModeLabel.ActivateOn:
                return ExpirationMode.ActivateOn;
            default:
                return '';
        }
    } else {
        switch (expModeLabel) {
            case ActiveExpirationModeLabel.DontExpire:
                return ExpirationMode.DontExpire;
            case ActiveExpirationModeLabel.ExpiredAfterActivation:
                return ExpirationMode.ExpiredAfterActivation;
            case ActiveExpirationModeLabel.Expiring:
                return ExpirationMode.Expiring;
            case ActiveExpirationModeLabel.WhenNotUsed:
                return ExpirationMode.WhenNotUsed;
            default:
                return '';
        }
    }
};
