/**
 * The GWP package provide a way to view and control video streams coming from the Security Center infrastructure.
 * It relies on a Media Gateway Role within a given Security Center system to provide access to streams.
 * Current version: [VERSION]
 *
 * @packageDocumentation
 * */

// JDT - Removed
/** Polyfills */
// import 'core-js/features/object/values';
// import 'core-js/features/array/from';
// import 'core-js/features/array/includes';
// import 'core-js/features/array-buffer';
// import 'core-js/features/data-view';
// import 'core-js/features/math/trunc';
// import 'core-js/features/promise';
// import 'core-js/features/url';
// import 'fastestsmallesttextencoderdecoder';
/** End Polyfills */

import { Logger } from './utils/logger';
import { buildPlayer } from './PublicWebPlayer';
import { buildMediaGatewayService, buildMediaGatewayServiceWithTransport } from './services/mediaGatewayService';
import { Config } from './utils/Config';
import { internalBuildDewarper } from './dewarper/InternalDewarperBuilder';
import { PlayersList } from './Players';
// import & load global declarations
import 'RestClient/Helpers/iterable-extensions';

export { IWebPlayer, buildPlayer } from './PublicWebPlayer';
export { IMediaGatewayService, buildMediaGatewayService } from './services/mediaGatewayService';
export { IPtzControl } from './ptzControl';
export { IDigitalZoomControl } from './digitalZoomControl/DigitalZoomControl';
export { IDigitalZoomPreview } from './digitalZoomControl/preview/DigitalZoomPreview';
export { ITimelineProvider } from './timelineProvider';
export { ILiteEvent } from './utils/liteEvents';
export { TokenRetrieverFct } from './services/TokenRetriever';
export * from './events';
export * from './enums';

/* beta */
export { buildMediaGatewayServiceWithTransport } from './services/mediaGatewayService';
export { IDewarperControl, IDewarperPreview } from './dewarper/DewarperInterfaces';
export { ITransport, ITransportBuilder } from './services/ITransport';
export { IEventDispatcher } from './services/eventHubDispatcher';
export { IWebSocketMessage, IWebSocketRequest, IWebSocketResponse } from './players/WebSocket/Api/IWebSocketMessage';
export { IConnectionEvent } from './players/WebSocket/WsSessionV2';
export { SegmentInfo, JpegInfo, ErrorDetails, SerializedPlayerState, StreamingStatus, TimelineContent, DateTimeSpan, SerializedTimelineEvent, SerializedPlaySpeed } from './services/eventHub';
export { CreateStreamRequest, CreateStreamRequestBody, StreamUsage } from './players/WebSocket/Api/CreateStream';
export { CreateStreamResponse, CreateStreamResponseBody, TranscodingAllowance } from './players/WebSocket/Api/CreateStreamResponse';
export { DeleteStreamRequest, DeleteStreamRequestBody } from './players/WebSocket/Api/DeleteStream';
export { ErrorResponse, ErrorResponseBody } from './players/WebSocket/Api/ErrorResponse';
export { PtzRequest, PtzSubCommand, PanTiltBody, ZoomBody, GoToBody, GoToPresetBody, GoHomeBody, RunPatternBody, LockBody, UnlockBody, FocusBody, PtzFocusDistance, PtzIrisOperation, IrisBody } from './players/WebSocket/Api/Ptz';
export { SuccessResponse, SuccessResponseBody } from './players/WebSocket/Api/SuccessResponse';
export { UpdateStreamRequest, UpdateStreamRequestBody, TimeLine } from './players/WebSocket/Api/UpdateStream';
export { UpdateStreamResponse, UpdateStreamResponseBody } from './players/WebSocket/Api/UpdateStreamResponse';
export { WebSocketMessageHeader } from './players/WebSocket/Api/WebSocketMessageHeader';
export { VideoWatermarkConfig } from './utils/VideoWatermarkingConfig';
export { Camera } from './players/camera';
export { Token } from './services/Token';
export { VideoFormat, SupportedVideoFormats } from './players/WebSocket/SupportedVideoFormats';
export { DefaultStreamUsage } from './players/WebSocket/DefaultStreamUsage';
export { Resolution } from './utils/Resolution';
export { PlaySpeed } from './players/PlaySpeed';
export { IStreamUsageSelectorBuilder, IStreamUsageSelector } from './players/StreamUsageSelector';
export { DewarperSourceImageParameters, Projection, CameraPosition } from './dewarper/DewarperSourceImageParameters';
export { Coords } from './utils/Coords';

/**
* Enable logging for the WebPlayer instances.
* @param intense - Enable advanced logging. This is much more verbose than the default option.
* @public */
export function enableLogs(intense?: boolean): string {
  Logger.enableLogs(intense);
  return intense === true ? 'Logs enabled (intense)' : 'Logs enabled';
}

/**
* Disable logging for the WebPlayer instances. This does not disable the warning and above logs.
* @public */
export function disableLogs(): string {
  Logger.disableLogs();
  return 'Logs disabled';
}

/**
* Get the current version for GWP library, useful for troubleshooting
* @public */
export function version(): string {
  // This string is replaced with Nuget version when built in Azure DevOps - DO NOT CHANGE
  // The result looks like '1.0.166'
  return '[VERSION]';
}

/**
 * Provide global configurations to the gwp players
 * @internal */
export const config: any = Config.instance;

/**
 * Provide the list of the gwp players
 * @internal*/
export const players: any = PlayersList.Instance;

/**
 * @internal*/
export const buildDewarper: any = internalBuildDewarper;

// The following part is required for compatibility for some module formats
/** Entry point of the GWP. Allows you to create new players.
 * @public */
export default class gwp {
  /**
  * Instanciate a new GWP instance in the provided div element.
  * @param container - Div Element into which the player will place itself
  * @returns instance used to control the new player
  * @public
  */
  readonly buildPlayer = buildPlayer;

  /**
  * @public
  * Create a new media gateway service that will coordinate requests and event with a media gateway server.
  * The service may be used to control one or more {@link IWebPlayer} instances.
  * @param url - URL to the media gateway instance you want to use. Must be in the form `https://<endpoint>/<applicationPath>`
  * @param tokenRetriever - Function to use to resolve a new access token for the specified camera.
  * @returns Promise to a new Media Gateway Service instance.
  */
  readonly buildMediaGatewayService = buildMediaGatewayService;

  /**
   * @beta
   * */
  readonly buildMediaGatewayServiceWithTransport = buildMediaGatewayServiceWithTransport;

  /**
  * Enable logging for the WebPlayer instances.
  * @param intense - Enable advanced logging. This is much more verbose than the default option.
  * @public */
  readonly enableLogs = enableLogs;

  /**
  * Disable logging for the WebPlayer instances. This does not disable the warning and above logs.
  * @public */
  readonly disableLogs = disableLogs;

  /**
  * Get the current version for GWP library, useful for troubleshooting
  * @public */
  readonly version = version;

  /**
  * Provide global configurations to the gwp players
  * @internal*/
  readonly config = config;

  /**
   * Provides a list of the players
   * @internal*/
  readonly players = players;
}
