<nav class="gen-breadcrumb">
    <ul #theList>
        <li *ngIf="showRoot()">
            <i
                *ngIf="home.icon"
                (click)="clickItem(home)"
                appClickActionOnSpaceEnter
                appTooltip="{{ 'STE_TOOLTIP_BUTTON_HOME' | translate }} "
                [ngClass]="{ pointer: !home.selected, 'fg-color-puffo': !home.selected }"
                class="root-icon sm {{ home.icon }}"
            ></i>
            <span
                *ngIf="home.selected; else clickable"
                class="gen-label"
                [ngClass]="{ pointer: !home.selected }"
                (click)="clickItem(home)"
                [appTooltip]="home.text"
                [appTooltipOnlyIfOverflow]="true"
            >
                {{ home.text }}
            </span>
            <ng-template #clickable>
                <a *ngIf="home.text" [appTooltip]="hasTooltip(home) ? home.text : ''" [ngClass]="{ pointer: !home.selected }" (click)="clickItem(home)">
                    {{ truncate(home.text || '') }}
                </a>
            </ng-template>
        </li>

        <li *ngIf="hasBreadcrumbOverflowLeft">
            <span> ... </span>
        </li>

        <li *ngFor="let item of leftCrumbs">
            <i *ngIf="item.icon" [ngClass]="{ pointer: !item.selected, 'fg-color-puffo': !item.selected }" (click)="clickItem(item)" class="root-icon sm {{ item.icon }}"></i>
            <span
                *ngIf="item.selected; else clickable"
                class="gen-label"
                [ngClass]="{ pointer: !item.selected }"
                (click)="clickItem(item)"
                [appTooltip]="item.text"
                [appTooltipOnlyIfOverflow]="true"
            >
                {{ item.text }}
            </span>
            <ng-template #clickable>
                <a *ngIf="item.text" [appTooltip]="hasTooltip(item) ? item.text : ''" [ngClass]="{ pointer: !item.selected }" (click)="clickItem(item)">
                    {{ truncate(item.text || '') }}
                </a>
            </ng-template>
        </li>

        <li *ngFor="let item of rightCrumbs">
            <i *ngIf="item.icon" [ngClass]="{ pointer: !item.selected, 'fg-color-puffo': !item.selected }" (click)="clickItem(item)" class="root-icon sm {{ item.icon }}"></i>
            <span
                *ngIf="item.selected; else clickable"
                class="gen-label"
                [ngClass]="{ pointer: !item.selected }"
                (click)="clickItem(item)"
                [appTooltip]="item.text"
                [appTooltipOnlyIfOverflow]="true"
            >
                {{ item.text }}
            </span>
            <ng-template #clickable>
                <a *ngIf="item.text" [appTooltip]="hasTooltip(item) ? item.text : ''" [ngClass]="{ pointer: !item.selected }" (click)="clickItem(item)">
                    {{ truncate(item.text || '') }}
                </a>
            </ng-template>
        </li>

        <li *ngIf="hasBreadcrumbOverflowRight">
            <span> ... </span>
        </li>
    </ul>
</nav>
