<div (click)="onProfile()" class="profile-button" appTooltip="{{ userNameDisplay }}" data-cy="profile-button">
    <gen-button *ngIf="!hasUserPicture" [flavor]="ButtonFlavor.Flat" id="btn-toggle-profile" class="focus-style-override" genKeyboardNavigation (genEscape)="closeProfile()">
        <gen-icon [icon]="Icon.Profile"></gen-icon>
    </gen-button>
    <div *ngIf="hasUserPicture" class="gen-image custom circle fill">
        <img src="data:image/png;base64,{{ userPicture }}" id="btn-toggle-profile" class="focus-style-override" alt="user profile avatar" />
    </div>
</div>

<gen-popup [position]="PopupPosition.Bottom" targetSelector="#btn-toggle-profile" genKeyboardNavigation (genEscape)="closeProfile()" appFocusPopupWhenOpen>
    <div class="user-menu">
        <div class="gen-flex user-id m-0">
            <div *ngIf="!hasUserPicture"><gen-icon [icon]="Icon.Profile" [size]="IconSize.Medium"></gen-icon></div>
            <div *ngIf="hasUserPicture">
                <div class="gen-image custom circle fill">
                    <img src="data:image/png;base64,{{ userPicture }}" alt="user profile avatar" />
                </div>
            </div>
            <div class="ml-1 w-100 no-wrap">
                <gen-text [flavor]="TextFlavor.Normal" appTooltip="{{ userNameDisplay }}">
                    {{ userNameDisplay }}
                </gen-text>
                <br />
                <gen-text [flavor]="TextFlavor.Secondary">
                    {{ directoryDisplay }}
                </gen-text>
            </div>
        </div>

        <div class="gen-list flex menu-buttons">
            <gen-separator></gen-separator>
            <!-- Change password -->
            <gen-button
                *ngIf="hasChangePasswordPrivilege && !isLoggedInWithOpenId && !isUserImportedFromExternalSystem"
                [flavor]="Flavor.Button.Flat"
                (click)="onChangePassword()"
                data-cy="change-password"
            >
                <gen-item>
                    <gen-text>{{ 'STE_BUTTON_CHANGEPASSWORD' | translate }}</gen-text>
                </gen-item>
            </gen-button>
            <!-- Options -->
            <gen-button (click)="onOptions()" [flavor]="Flavor.Button.Flat" data-cy="options-menu-item">
                <gen-item>
                    <gen-text>{{ 'STE_TITLE_OPTIONS' | translate }}</gen-text>
                </gen-item>
            </gen-button>
            <gen-separator></gen-separator>
            <!-- Send feedback -->
            <gen-button (click)="onSendFeedback()" [flavor]="Flavor.Button.Flat" data-cy="send-feedback">
                <gen-item>
                    <gen-text>{{ 'STE_LABEL_SEND_FEEDBACK' | translate }}</gen-text>
                </gen-item>
            </gen-button>
            <gen-button (click)="onAbout()" [flavor]="Flavor.Button.Flat" data-cy="about">
                <gen-item>
                    <gen-text>{{ 'STE_LABEL_ABOUT' | translate }}</gen-text>
                </gen-item>
            </gen-button>
            <gen-button (click)="onHelp()" [flavor]="Flavor.Button.Flat" data-cy="help">
                <gen-item>
                    <gen-text>{{ 'STE_LABEL_HELP' | translate }}</gen-text>
                </gen-item>
            </gen-button>
            <gen-separator></gen-separator>
            <!-- Sign out -->
            <gen-button (click)="onSignOut()" [flavor]="Flavor.Button.Flat" data-cy="sign-out">
                <gen-item>
                    <gen-icon icon="sign-out"></gen-icon>
                    <gen-text>{{ 'STE_BUTTON_LOGOFF' | translate }}</gen-text>
                </gen-item>
            </gen-button>
        </div>
    </div>
</gen-popup>
