import { RestObject } from 'RestClient/Client/Model/RestObject';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { IncidentFilter } from '@modules/mission-control/models/incident-filter';
import { ILinkedIncidentController } from '@modules/mission-control/controllers/incident.controller.interfaces';

export class LinkedIncidentController extends RestObject implements ILinkedIncidentController {
    constructor() {
        super('api');
        this._canGet = true;
    }

    public async getFilteredLinkedIncidents(displayId: string | null, incidentFilter: IncidentFilter, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>((resolve, reject) => {
                    resolve(response);
                });
            } else {
                return new Promise<IRestResponse>((resolve, reject) => {
                    reject(response);
                });
            }
        };


        const params = incidentFilter.asURLSearchParams();
        if(displayId) params.set('displayId', displayId);

        const url = params.toString() !== '' ? `LinkedIncident?${params.toString()}` : 'LinkedIncident';

        return this.executeRequestTransactionAsync<IRestResponse>(url, 'GET', null, transaction, responseHandler);
    }
}
