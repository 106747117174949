import { Component, Injector, Input, OnInit } from '@angular/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { DoorEntity } from 'RestClient/Client/Model/AccessControl/DoorEntity';
import { IDoorEntity } from 'RestClient/Client/Interface/IDoorEntity';
import { DoorSideAccessPointEntity } from 'RestClient/Client/Model/AccessControl/DoorSideAccessPointEntity';
import { WebAppClient } from 'WebClient/WebAppClient';
import { IGuid } from 'safeguid';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { DeviceStates } from '@modules/shared/enumerations/device-states';

@Component({
    selector: 'app-shunt-reader-modal',
    templateUrl: './shunt-reader-modal.component.html',
    styleUrls: ['./shunt-reader-modal.component.scss'],
})
export class ShuntReaderModalComponent extends ConnectionAwareModalComponent implements OnInit {
    @Input()
    public entityId!: IGuid;
    public doorSideAccessPointEntityWithReaders?: DoorSideAccessPointEntity[] | null = null;
    public devicesShuntState: boolean[] = [];

    private scClient!: WebAppClient;
    private doorEntity: DoorEntity | null = null;

    constructor(private securityCenterClientService: SecurityCenterClientService, protected authService: AuthService, injector: Injector, trackingService: TrackingService) {
        super(authService, injector, trackingService);
    }

    async ngOnInit() {
        super.ngOnInit();

        this.scClient = this.securityCenterClientService?.scClient;
        if (this.scClient) {
            this.doorEntity = (await this.scClient.getEntityAsync<DoorEntity, IDoorEntity>(DoorEntity, this.entityId)) as DoorEntity;
            if (this.doorEntity) {
                this.initializeDevices();
            }
        }
    }

    public onShuntStateChange(value: boolean, index: number): void {
        this.devicesShuntState[index] = value;
    }

    public onShuntStateConfirm = (): Promise<boolean> => {
        this.doorSideAccessPointEntityWithReaders?.forEach(async (device, index) => {
            if ((device.deviceState === DeviceStates.Disabled) !== this.devicesShuntState[index]) {
                const response = await device.setDeviceStateAsync(this.devicesShuntState[index]);
                if (response?.statusCode === HttpStatusCode.OK) {
                    device.deviceState = this.devicesShuntState[index] ? DeviceStates.Disabled : DeviceStates.Enabled;
                }
            }
        });
        return Promise.resolve(true);
    };

    private initializeDevices() {
        if (this.doorSideAccessPointEntityWithReaders) {
            const devicesShuntState: Array<boolean> = [];
            this.doorSideAccessPointEntityWithReaders.forEach((entity) => {
                devicesShuntState.push(entity.deviceState === DeviceStates.Disabled);
            });

            this.devicesShuntState = devicesShuntState;
        }
    }
}
