// Autogenerated: v2/help/Entities/ApiIntrusionInputDevice?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:06 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';
import { InputDeviceEntityFields, IInputDeviceEntity } from './IInputDeviceEntity';
import { IRestResponse } from './IRestResponse';

// #region Fields

export class IntrusionInputDeviceEntityFields extends InputDeviceEntityFields {
    public static alarmStateField = 'AlarmState';
    public static bypassedStateField = 'BypassedState';
    public static capabilitiesField = 'Capabilities';
    public static inputTypeField = 'InputType';
    public static inputTypeCustomIconField = 'InputTypeCustomIcon';
    public static inputTypeIdField = 'InputTypeId';
    public static inputTypeNameField = 'InputTypeName';
}

// #endregion Fields

export interface IIntrusionInputDeviceEntity extends IInputDeviceEntity {
    // #region REST Properties

    alarmState: string;

    bypassedState: string;

    readonly capabilities: string;

    readonly inputType: string;

    readonly inputTypeCustomIcon: IGuid;

    readonly inputTypeId: IGuid;

    readonly inputTypeName: string;

    // #endregion REST Properties

    // #region REST Methods

    getAlarmSourcesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    bypassInputAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    unbypassInputAsync(transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

