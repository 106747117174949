import { QueryHelper } from '../../Helpers/QueryHelper';
import { FieldList } from '../../Helpers/FieldList';

export class BaseQuery {
  private _baseQuery: string;
  protected _queryHelper = new QueryHelper();

  constructor(baseQuery?: string) {
    if (!baseQuery) {
      this._baseQuery = 'v2/Entities';
    } else {
      this._baseQuery = baseQuery;
    }
  }

  public buildQuery(): string {
    const query = this._baseQuery + '?';
    return query;
  }

  public prepareQuery(): void {}
}
