import { RestConnection } from './RestConnection';
import { RestTransactionResponse, TransactionResult } from './RestTransactionResponse';
import * as Helpers from '../Helpers/Helpers';
import { RestOperation } from '../Helpers/RestOperation';
import { FieldObject } from '../Helpers/FieldObject';
import HttpStatusCode from '../Client/Enumerations/HttpStatusCode';

export class RestTransaction {
  //#region Fields

  private _rest: RestConnection;
  private _transaction = new Set<RestOperation>();
  private _response = new RestTransactionResponse();

  //#endregion

  //#region Properties

  public get count(): number {
    return this._transaction.size;
  }

  //#endregion

  //#region constructor

  constructor(conn: RestConnection) {
    this._rest = conn;
  }

  //#endregion

  //#region methods

  public addTransactionOperation(uri: string, verb: string, body: object | null, context?: object) {
    const operation = new RestOperation();
    operation.verb = verb;
    operation.uri = this._rest.restServerUrl + '/' + uri;

    if (verb != null && body == null && verb.toLowerCase() !== 'get') {
      operation.body = new FieldObject();
    } else {
      operation.body = body;
    }
    this._transaction.add(operation);

    const result = new TransactionResult();
    result.index = this._transaction.size;
    result.context = context;
    result.verb = verb;
    result.uri = uri;
    this._response.transactionResult.push(result);

    return this._transaction.size;
  }

  public async executeTransactionAsync(token?: Helpers.CancellationToken): Promise<RestTransactionResponse> {
    if (this._transaction.size === 0) {
      return new RestTransactionResponse(HttpStatusCode.OK);
    }

    if (this._transaction.size === 1) {
      // Detransaction the transaction!
      const zeSingleTransaction = this._transaction.keys().next().value;
      let uri = zeSingleTransaction.uri;
      uri = uri.replace(this._rest.restServerUrl + '/', '');
      let body: string | null = null;
      if (zeSingleTransaction.body != null) {
        if ( zeSingleTransaction.body instanceof FieldObject) {
          body = zeSingleTransaction.body.toString();
        } else {
          body = JSON.stringify(zeSingleTransaction.body);
        }
      }
      const restResponse = await this._rest.executeRequestAsync(zeSingleTransaction.verb, uri, body, token);

      this._response.statusCode = restResponse.statusCode;
      if (this._response.statusCode === HttpStatusCode.CREATED) {
        this._response.statusCode = HttpStatusCode.OK;
      }
      const firstTransResult = this._response.transactionResult[0];
      firstTransResult.statusCode = restResponse.statusCode;
      if (restResponse.body) {
        firstTransResult.result = restResponse.body;
      }
    } else {
      const body = new Array<object>();
      for (const item of this._transaction) {
        const subBody: { [k: string]: any } = {};
        subBody.uri = item.uri;
        subBody.verb = item.verb;
        if (item.body) {
          subBody.body = item.body.toString();
        }
        body.push(subBody);
      }
      const restResponse = await this._rest.executeRequestAsync(
        'POST',
        'v2/Entities/Transactions',
        JSON.stringify(body),
        token,
      );
      this._response.parseResponse(restResponse);
    }
    return this._response;
  }

  //#region
}
