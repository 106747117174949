import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ZoneContentTypes } from '@modules/general/enumerations/zone-content-types';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { CommandsUsage } from '@modules/shared/services/commands/commands-usage/commands-usage';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { Observable, Subject } from 'rxjs';
import { SafeGuid } from 'safeguid';
import { ZoneWidgetBaseComponent } from '../../zone-widget-base.component';

@Component({
    selector: 'app-zone-entity-state',
    templateUrl: './zone-entity-state.component.html',
    styleUrls: ['./zone-entity-state.component.scss'],
})
@InternalContentPluginDescriptor({
    type: ZoneEntityStateComponent,
    pluginTypes: [PluginTypes.TileHeader],
    exposure: {
        id: ZoneEntityStateComponent.pluginId,
        priority: -1,
    },
    isContentSupported: (content: Content) => {
        if (content && !content.contextContent) {
            if (content.type) {
                return content.type.equals(ZoneContentTypes.Zone);
            }
        }
        return false;
    },
    requirements: { enabledFeatureFlags: [GeneralFeatureFlags.EntityState] },
})
export class ZoneEntityStateComponent extends ZoneWidgetBaseComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('7688AFE1-3C61-4039-946A-535F8513456A');

    /**
     * List of commands to hide from the tile state overlay.
     * UX asked we remove the "share" button because it's already in the timeline.
     */
    public readonly excludedCommands = [SafeGuid.parse('2e13993e8f7a4cdaadf4e3cd6f46052a')];
    public readonly RunningState = RunningState;

    public commandsUsage$: Observable<CommandsUsage>;

    private commandsUsageSubject = new Subject<CommandsUsage>();
    private subscriptions = new SubscriptionCollection();

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        translateService: TranslateService,
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService
    ) {
        super(securityCenterClientService, translateService, trackingService);
        this.commandsUsage$ = this.commandsUsageSubject.asObservable();
    }

    async ngOnDestroy() {
        await super.ngOnDestroy();
        this.subscriptions.unsubscribeAll();
    }

    setContent(content: Content): void {
        this.content = content;
        this.createCommandsUsageAsync().fireAndForget();
    }

    private async createCommandsUsageAsync() {
        if (this.content) {
            const commandsUsage = await this.commandsService.getCommandsUsage({ type: ContextTypes.Content, data: this.content }, []);

            this.subscriptions.add(commandsUsage.subscribe());
            this.commandsUsageSubject.next(commandsUsage);
        }
    }
}
