import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appClickStopPropagation]',
})
export class ClickStopPropagationDirective {
    @HostListener('dblclick', ['$event'])
    public onDoubleClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
