import { OnDestroy, Inject, Injectable } from '@angular/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { IEntityExtensions } from 'RestClient/Client/Interface/IEntityExtensions';
import { Entity } from 'RestClient/Client/Model/Entity';
import { WebAppClient } from 'WebClient/WebAppClient';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { FeatureFlag } from '@modules/feature-flags/feature-flag';
import { ContextTypes } from '../../interfaces/plugins/public/context-types';
import { SubscriptionCollection } from '../../utilities/subscription-collection';
import { LanguageService } from '../language/language.service';
import {
    CommandProvider,
    CommandsSubscription,
    COMMANDS_SERVICE,
    CommandsService,
    CommandContext,
    CommandBindings,
    MapContext,
    ExecuteCommandHandler,
    ExecuteCommandData,
    CommandDisplay,
    CommandRequirements,
    FEATURES_SERVICE,
} from '../../interfaces/plugins/public/plugin-services-public.interface';
import { Content, PluginCommand } from '../../interfaces/plugins/public/plugin-public.interface';
import { FeaturesService } from '../features/features.service';
import { AdvancedSettingsService } from '../advanced-settings/advanced-settings.service';
import { InternalCommandsService } from './commands.service';
import { isEntityCommandContext } from './entity-command-context';
import { CommandsUsageContext } from './commands-usage/commands-usage-context';
export interface CommandArgs {
    content?: Content;
    entity?: IEntity;
    entityId?: IGuid;
    mapContext?: MapContext;
    specificData?: any;
}

export interface CommandDescriptor {
    id: IGuid;
    nameResourceId?: string;
    icon?: MeltedIcon;
    color?: string;
    tooltipResourceId?: string;
    sectionNameResourceId?: string;
    groupNameResourceId?: string;
    groupIcon?: MeltedIcon;
    keyBinding?: string;
    requirements?: CommandRequirements;
}

export const arePrivilegesGrantedAsync = async (
    scClient: WebAppClient,
    requiredPrivileges: Set<IGuid>,
    grantedPrivileges?: Set<IGuid>,
    entity?: IEntity,
    entityId?: IGuid,
    entityCache?: IEntityCacheTask
): Promise<boolean> => {
    if (requiredPrivileges.size === 0) {
        return true;
    }

    let privilegesToCheck: Set<IGuid>;
    if (grantedPrivileges && grantedPrivileges.size > 0) {
        // Gets the privileges not already in granted privileges
        privilegesToCheck = SafeGuid.createSet(setFilter(requiredPrivileges, (privilege) => !setSome(grantedPrivileges, (granted) => granted.equals(privilege))));

        // If all required privileges were in granted privileges, privileges are granted
        if (privilegesToCheck.size === 0) {
            return true;
        }
    } else {
        privilegesToCheck = requiredPrivileges;
    }

    let checkedEntity: IEntity | undefined | null = entity;
    if (!checkedEntity && isNonEmptyGuid(entityId)) {
        if (entityCache) {
            checkedEntity = await entityCache.getEntityAsync<Entity, IEntity>(Entity, entityId);
        } else {
            checkedEntity = await scClient.getEntityAsync<Entity, IEntity>(Entity, entityId);
        }
    }
    if (checkedEntity) {
        const result = await checkedEntity.hasPrivilegesAsync(SafeGuid.createSet(privilegesToCheck));
        return result ? true : false;
    }

    for (const requiredPrivilege of privilegesToCheck) {
        if (!scClient.isGlobalPrivilegeGranted(requiredPrivilege)) {
            return false;
        }
    }

    return false;
};

@Injectable()
export abstract class CommandProviderBase implements OnDestroy, CommandProvider {
    protected commandsSubscription!: CommandsSubscription;
    protected internalCommandDescriptors!: Map<IGuid, CommandDescriptor>;
    protected isUserAdmin = false;
    protected readonly scClient: WebAppClient;
    protected readonly subscriptions = new SubscriptionCollection();

    private areCommandsRegistered = false;

    public get priority(): number {
        return 100;
    }

    public get requiredFeatures(): Set<IGuid> {
        return SafeGuid.createSet();
    }

    public get requiredPrivileges(): Set<IGuid> {
        const descriptors = Array.from(this.commandDescriptors.values());
        const privileges = SafeGuid.createSet();
        for (const descriptor of descriptors) {
            if (descriptor.requirements?.requiredPrivileges) {
                for (const privilege of descriptor.requirements.requiredPrivileges) {
                    privileges.add(privilege);
                }
            }
        }
        return privileges;
    }

    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        return SafeGuid.createMap<CommandDescriptor>();
    }

    constructor(
        @Inject(COMMANDS_SERVICE) protected commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) protected featuresService: FeaturesService,
        protected translateService: TranslateService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        this.scClient = scClientService?.scClient;
        this.subscriptions.add(
            this.scClient.onLogonStateChanged((args) => {
                if (args.loggedOn()) {
                    this.initialize();
                }
            })
        );

        this.commandsService.registerCommandProvider(this);

        this.subscriptions.add(
            languageService.ready.subscribe(() => {
                if (!this.areCommandsRegistered) {
                    this.registerCommands();
                    this.subscribeCommands();
                    this.areCommandsRegistered = true;
                }
            })
        );
    }

    public ngOnDestroy() {
        this.commandsSubscription.unsubscribe();
        this.subscriptions.unsubscribeAll();
    }

    protected areCommandContentRequirementsMet(supportedContentTypes: Set<IGuid>, content: Content): boolean {
        if (!setSome(supportedContentTypes, (contentType) => content.type.equals(contentType))) {
            return false;
        }
        return true;
    }

    protected async areCommandRequirementsMetAsync(commandId: IGuid, commandContext?: CommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        const descritor = this.commandDescriptors.get(commandId);
        if (!descritor) {
            return Promise.resolve(false);
        }

        const requirements = this.extractRequirements(commandId);
        if (!requirements) {
            return Promise.resolve(true);
        }

        if (requirements.requiredFeatures && requirements.requiredFeatures.size > 0) {
            const features = await this.featuresService.getFeaturesAsync();
            if (!setEvery(requirements.requiredFeatures, (x) => features.has(x))) {
                return false;
            }
        }

        if (requirements.enabledFeatureFlags && requirements.enabledFeatureFlags.size > 0) {
            if (!setEvery(requirements.enabledFeatureFlags, (featureFlag) => featureFlag.isEnabled(this.advancedSettingsService))) {
                return false;
            }
        }

        const content = commandArgs?.content ?? this.extractArgs(commandContext)?.content;
        if (content) {
            if (requirements.supportedContentTypes) {
                if (!this.areCommandContentRequirementsMet(requirements.supportedContentTypes, content)) {
                    return Promise.resolve(false);
                }
            }
        }
        return Promise.resolve(true);
    }

    protected async arePrivilegesGrantedAsync(requiredPrivileges: Set<IGuid>, grantedPrivileges?: Set<IGuid>): Promise<boolean> {
        // return this.isUserAdmin ? true : await arePrivilegesGrantedAsync(this.scClient, requiredPrivileges, grantedPrivileges);

        if (this.isUserAdmin) {
            return true;
        }

        return await arePrivilegesGrantedAsync(this.scClient, requiredPrivileges, grantedPrivileges);
    }

    protected createCommand(commandDescriptor: CommandDescriptor): PluginCommand {
        let name: () => string;
        if (commandDescriptor.nameResourceId) {
            name = () => this.translateService?.instant(commandDescriptor.nameResourceId as string) as string;
        } else {
            name = () => '';
        }

        let icon = MeltedIcon.None;
        if (commandDescriptor.icon) {
            icon = commandDescriptor.icon;
        }

        let tooltip: (() => string) | undefined;
        if (commandDescriptor.tooltipResourceId) {
            tooltip = () => this.translateService?.instant(commandDescriptor.tooltipResourceId as string) as string;
        }

        const command: PluginCommand = {
            id: commandDescriptor.id,
            name,
            icon,
            tooltip,
            keyBinding: commandDescriptor.keyBinding,
            groupIcon: commandDescriptor.groupIcon,
        };
        if (commandDescriptor.color) {
            command.color = () => commandDescriptor.color as string;
        }
        if (commandDescriptor.groupNameResourceId) {
            command.groupName = () => this.translateService?.instant(commandDescriptor.groupNameResourceId as string) as string;
        }
        if (commandDescriptor.sectionNameResourceId) {
            command.sectionName = () => this.translateService?.instant(commandDescriptor.sectionNameResourceId as string) as string;
        }
        return command;
    }

    protected extractArgs(commandContext?: CommandContext): CommandArgs {
        const args: CommandArgs = {};
        if (commandContext) {
            if (commandContext.data !== undefined) {
                if (commandContext.type.equals(ContextTypes.Content)) {
                    args.content = commandContext.data as Content;
                    args.entityId = SafeGuid.parse(args.content.source);
                } else if (commandContext.type.equals(ContextTypes.Map)) {
                    args.mapContext = commandContext.data as MapContext;
                } else if (commandContext.type.equals(ContextTypes.Entity)) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    const entity = commandContext.data;
                    if (IEntityExtensions.isEntity(entity)) {
                        args.entity = entity;
                        args.entityId = entity.id;
                    }
                } else if (commandContext.type.equals(ContextTypes.EntityId)) {
                    if (isNonEmptyGuid(commandContext.data)) {
                        args.entityId = commandContext.data;
                    }
                } else if (commandContext.type.equals(ContextTypes.Specific)) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    args.specificData = commandContext.data;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    const entityId = args.specificData.entityId;
                    if (isNonEmptyGuid(entityId)) {
                        args.entityId = entityId;
                    }
                }
            }
        }
        return args;
    }

    protected extractRequirements(commandId: IGuid): CommandRequirements {
        const requirements: CommandRequirements = {};

        const descritor = this.commandDescriptors.get(commandId);
        const internalCommandsService = this.commandsService as InternalCommandsService;
        const additionnalRequirements = internalCommandsService.getAdditionnalRequirements(commandId);
        if (descritor?.requirements) {
            additionnalRequirements?.push(descritor.requirements);
        }

        for (const req of additionnalRequirements) {
            if (req.requiredPrivileges) {
                requirements.requiredPrivileges = requirements.requiredPrivileges ?? SafeGuid.createSet();
                for (const privilege of req.requiredPrivileges) {
                    requirements.requiredPrivileges.add(privilege);
                }
            }
            if (req.requiredFeatures) {
                requirements.requiredFeatures = requirements.requiredFeatures ?? SafeGuid.createSet();
                for (const feature of req.requiredFeatures) {
                    requirements.requiredFeatures.add(feature);
                }
            }
            if (req.supportedContentTypes) {
                requirements.supportedContentTypes = requirements.supportedContentTypes ?? SafeGuid.createSet();
                for (const type of req.supportedContentTypes) {
                    requirements.supportedContentTypes.add(type);
                }
            }
            if (req.enabledFeatureFlags) {
                requirements.enabledFeatureFlags = requirements.enabledFeatureFlags ?? new Set<FeatureFlag>();
                for (const featureFlag of req.enabledFeatureFlags) {
                    requirements.enabledFeatureFlags.add(featureFlag);
                }
            }
        }
        return requirements;
    }

    protected fillCommandRequirements(requirements: CommandRequirements): CommandRequirements {
        return requirements;
    }

    protected getCommands(): PluginCommand[] {
        const commands: PluginCommand[] = [];
        for (const descriptor of this.commandDescriptors.values()) {
            commands.push(this.createCommand(descriptor));
        }
        return commands;
    }

    protected initialize(): void {
        if (this.scClient) {
            const userInfo = this.scClient.currentUserInfo;
            if (userInfo) {
                this.isUserAdmin = userInfo.isAdministrator;
            }
        }
    }

    protected invalidateCommandCanExecute(commandId: IGuid, commandContext: CommandContext): void {
        if (commandContext instanceof CommandsUsageContext) {
            commandContext.invalidateCanExecute(commandId);
        }
    }

    protected invalidateCommandDisplay(commandId: IGuid, commandContext: CommandContext): void {
        if (commandContext instanceof CommandsUsageContext) {
            commandContext.invalidateDisplay(commandId);
        }
    }

    protected registerCommands(): void {
        this.commandsService.registerCommands(this.getCommands());
        this.subscribeCommands();
    }

    protected safeCanExecuteCommand(canExecuteCommandFunction: (commandContext: CommandContext) => boolean, commandContext?: CommandContext): boolean {
        if (!commandContext) {
            return false;
        }
        return canExecuteCommandFunction(commandContext);
    }

    protected async safeCanExecuteCommandAsync(canExecuteCommandFunction: (commandContext: CommandContext) => Promise<boolean>, commandContext?: CommandContext): Promise<boolean> {
        if (!isEntityCommandContext(commandContext)) {
            return false;
        }
        return await canExecuteCommandFunction(commandContext);
    }

    protected safeExecuteCommand(executeCommandFunction: ExecuteCommandHandler, executeCommandData: ExecuteCommandData): void {
        const commandContext = executeCommandData.commandContext;
        if (!commandContext) {
            executeCommandData.isHandled = false;
            return;
        }
        executeCommandFunction(executeCommandData);
        if (executeCommandData.isHandled) {
            executeCommandData.event?.stopPropagation();
            executeCommandData.event?.preventDefault();
        }
    }

    protected safeGetCommandDisplay(
        getCommandDisplayFunction: (commandContext: CommandContext) => CommandDisplay | undefined,
        commandContext?: CommandContext
    ): CommandDisplay | undefined {
        if (!commandContext) {
            return;
        }
        return getCommandDisplayFunction(commandContext);
    }

    protected async safeGetCommandDisplayAsync(
        getCommandDisplayFunction: (commandContext: CommandContext) => Promise<CommandDisplay | undefined>,
        commandContext?: CommandContext
    ): Promise<CommandDisplay | undefined> {
        if (!commandContext) {
            return;
        }
        return await getCommandDisplayFunction(commandContext);
    }

    protected safeIsCommandAvailable(isCommandAvailableFunction: (commandContext: CommandContext) => boolean, commandContext?: CommandContext): boolean {
        return this.safeCanExecuteCommand(isCommandAvailableFunction, commandContext);
    }

    protected async safeIsCommandAvailableAsync(
        isCommandAvailableFunction: (commandContext: CommandContext) => Promise<boolean>,
        commandContext?: CommandContext
    ): Promise<boolean> {
        return await this.safeCanExecuteCommandAsync(isCommandAvailableFunction, commandContext);
    }

    protected setCommandCanExecute(commandId: IGuid, commandContext: CommandContext, canExecute: boolean): void {
        if (commandContext instanceof CommandsUsageContext) {
            commandContext.invalidateCanExecute(commandId, canExecute);
        }
    }

    protected setCommandDisplay(commandId: IGuid, commandContext: CommandContext, commandDisplay: CommandDisplay): void {
        if (commandContext instanceof CommandsUsageContext) {
            commandContext.invalidateDisplay(commandId, commandDisplay);
        }
    }

    protected subscribeCommands(): void {
        // unsubscribe if any
        this.commandsSubscription?.unsubscribe();

        if (this.commandsService) {
            const bindings = new CommandBindings();
            this.fillCommandBindings(bindings);

            this.commandsSubscription = this.commandsService.subscribe(bindings, {
                priority: this.priority,
            });
        }
    }

    public abstract getAvailableCommandIdsAsync(contextArgs: CommandContext): Promise<IGuid[]>;
    protected abstract fillCommandBindings(bindings: CommandBindings): void;
}
