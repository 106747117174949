import { Inject, Injectable } from '@angular/core';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { DetailedFeatureFlagGroup, FeatureFlag } from '@modules/feature-flags/feature-flag';
import { createSelector, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Receiver } from '../../store/decorators/receiver.decorator';
import { KnownFeatureFlagsToken } from './tokens';

export interface FeatureFlagsStateModel {
    featureFlags: Record<string, boolean>;
    registeredOnce: boolean;
}

class UpdateFeatureFlagsCacheAction {
    static readonly type = '[FeatureFlags] Update feature flags cached values';
}

/**
 * State that contains cached values for all feature flags contained in the app.
 * Register feature flags for new modules in the constructor of the modules.
 *
 * @example
 * [at sign]NgModule({
 *     imports: [
 *         // ...
 *         FeatureFlagsModule.forRoot(MapsFeatureFlags),
 *     ]
 * })
export class MapsModule {}
 */
@State<FeatureFlagsStateModel>({
    name: 'featureFlags',
    defaults: {
        featureFlags: {},
        registeredOnce: false,
    },
})
@Injectable()
export class FeatureFlagsState implements NgxsOnInit {
    private static advancedSettingsService: AdvancedSettingsService;
    private static loggerService: LoggerService;
    private static knownFeatureFlagGroups: DetailedFeatureFlagGroup[];

    constructor(
        private advancedSettingsService: AdvancedSettingsService,
        private securityCenterClientService: SecurityCenterClientService,
        private loggerService: LoggerService,
        @Inject(KnownFeatureFlagsToken) knownFeatureFlags: DetailedFeatureFlagGroup[]
    ) {
        FeatureFlagsState.advancedSettingsService = this.advancedSettingsService;
        FeatureFlagsState.loggerService = this.loggerService;
        FeatureFlagsState.knownFeatureFlagGroups = knownFeatureFlags;
    }

    public static featureFlags(featureFlag: FeatureFlag): (state: FeatureFlagsStateModel) => boolean {
        return createSelector([FeatureFlagsState], (state: FeatureFlagsStateModel) => {
            const cachedFlag = state.featureFlags[featureFlag];
            if (cachedFlag === undefined) {
                this.logFeatureFlagNotFound(featureFlag);
            }
            return cachedFlag ?? featureFlag.defaultValue;
        });
    }

    public static featureFlagGroups<T extends DetailedFeatureFlagGroup>(featureFlagGroup: T): (state: FeatureFlagsStateModel) => Record<keyof T, boolean> {
        return createSelector([FeatureFlagsState], (state: FeatureFlagsStateModel) => {
            const evaluatedGroup = {} as Record<keyof T, boolean>;

            for (const [propertyKey, featureFlag] of Object.entries(featureFlagGroup)) {
                const value = state.featureFlags[featureFlag];
                if (value === undefined) {
                    this.logFeatureFlagNotFound(featureFlag);
                }
                evaluatedGroup[propertyKey as keyof T] = value ?? featureFlag.defaultValue;
            }

            return evaluatedGroup;
        });
    }

    private static logFeatureFlagNotFound(featureFlag: FeatureFlag) {
        this.loggerService.traceError(`Feature flag ${featureFlag} is not registered!`);
    }

    @Receiver({ action: UpdateFeatureFlagsCacheAction })
    public static updateFeatureFlagsCache({ setState }: StateContext<FeatureFlagsStateModel>): void {
        setState((state: FeatureFlagsStateModel) => {
            // Update all feature flag values with initialized role config.
            // Flattens the feature flag groups into an array of feature flags
            const featureFlags = FeatureFlagsState.knownFeatureFlagGroups
                .flat()
                .reduce((allFeatureFlags: FeatureFlag[], group: DetailedFeatureFlagGroup) => [...allFeatureFlags, ...Object.values(group)], []);

            for (const featureFlag of featureFlags) {
                state.featureFlags[featureFlag.key] = this.advancedSettingsService.get(featureFlag.key, featureFlag.defaultValue);
            }

            state.registeredOnce = true;
            return state;
        });
    }

    ngxsOnInit({ dispatch }: StateContext<FeatureFlagsStateModel>): void {
        // No need to unsubscribe, it lives as long as the service.
        this.securityCenterClientService.scClient.onLogonStateChanged(() => {
            dispatch(UpdateFeatureFlagsCacheAction);
        });
    }
}
