// Autogenerated: v2/help/Entities/ApiCardholderGroup?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:17 PM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IEntity } from '../../Interface/IEntity';
import { ICardholderGroupEntity, CardholderGroupEntityFields } from '../../Interface/ICardholderGroupEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class CardholderGroupEntity extends Entity implements ICardholderGroupEntity {
    // #region REST Properties

    // AccessPermissionLevel
    public get accessPermissionLevel(): number {
        return this.getField<number>(CardholderGroupEntityFields.accessPermissionLevelField);
    }
    public set accessPermissionLevel(value: number) {
        this.setField<number>(CardholderGroupEntityFields.accessPermissionLevelField, value);
    }

    // AntipassbackExemption
    public get antipassbackExemption(): boolean {
        return this.getField<boolean>(CardholderGroupEntityFields.antipassbackExemptionField);
    }
    public set antipassbackExemption(value: boolean) {
        this.setField<boolean>(CardholderGroupEntityFields.antipassbackExemptionField, value);
    }

    // AntipassbackExemptionIsInherited
    public get antipassbackExemptionIsInherited(): boolean {
        return this.getField<boolean>(CardholderGroupEntityFields.antipassbackExemptionIsInheritedField);
    }
    public set antipassbackExemptionIsInherited(value: boolean) {
        this.setField<boolean>(CardholderGroupEntityFields.antipassbackExemptionIsInheritedField, value);
    }

    // EmailAddress
    public get emailAddress(): string {
        return this.getField<string>(CardholderGroupEntityFields.emailAddressField);
    }
    public set emailAddress(value: string) {
        this.setField<string>(CardholderGroupEntityFields.emailAddressField, value);
    }

    // InheritAccessPermissionLevelFromParentGroup
    public get inheritAccessPermissionLevelFromParentGroup(): boolean {
        return this.getField<boolean>(CardholderGroupEntityFields.inheritAccessPermissionLevelFromParentGroupField);
    }
    public set inheritAccessPermissionLevelFromParentGroup(value: boolean) {
        this.setField<boolean>(CardholderGroupEntityFields.inheritAccessPermissionLevelFromParentGroupField, value);
    }

    // IsVisitorGroup
    public get isVisitorGroup(): boolean {
        return this.getField<boolean>(CardholderGroupEntityFields.isVisitorGroupField);
    }
    public set isVisitorGroup(value: boolean) {
        this.setField<boolean>(CardholderGroupEntityFields.isVisitorGroupField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.CardholderGroups);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPointRules', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

