// Autogenerated: v2/help/Entities/ApiTileLayout?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:12:21 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { ITilePattern, ITileLayoutContent, ITileLayoutEntity } from '../Interface/ITileLayoutEntity';
import { ITransaction } from '../Interface/ITransaction';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

// #region Inner classes

export class TilePattern extends FieldObject implements ITilePattern {
    // Columns
    public get columns(): number {
        return this.getField<number>('Columns');
    }
    public set columns(value: number) {
        this.setField<number>('Columns', value);
    }
    // ColumnSpans
    public get columnSpans(): ObservableCollection<number> {
        return this.getFieldArray<number>('ColumnSpans');
    }
    public set columnSpans(value: ObservableCollection<number>) {
        this.setFieldArray<number>('ColumnSpans', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Rows
    public get rows(): number {
        return this.getField<number>('Rows');
    }
    public set rows(value: number) {
        this.setField<number>('Rows', value);
    }
    // RowSpans
    public get rowSpans(): ObservableCollection<number> {
        return this.getFieldArray<number>('RowSpans');
    }
    public set rowSpans(value: ObservableCollection<number>) {
        this.setFieldArray<number>('RowSpans', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Columns', 0);
        this.setFieldArray<number>('ColumnSpans', new ObservableCollection<number>());
        this.setField<string>('Name', '');
        this.setField<number>('Rows', 0);
        this.setFieldArray<number>('RowSpans', new ObservableCollection<number>());
    }
}

export class TileLayoutContent extends FieldObject implements ITileLayoutContent {
    // Pattern
    public get pattern(): ITilePattern {
        return this.getFieldObject<TilePattern, ITilePattern>(TilePattern, 'Pattern');
    }
    public set pattern(value: ITilePattern) {
        this.setFieldObject<ITilePattern>('Pattern', value);
    }
    // Entities
    public get entities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('Entities');
    }
    public set entities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('Entities', value);
    }

    public initializeAllFields(): void {
        const objPattern = new TilePattern();
        objPattern.initializeAllFields();
        this.setFieldObject('Pattern', objPattern);
        this.setFieldArrayGuid('Entities', new ObservableCollection<IGuid>());
    }
}

// #endregion Inner classes

export class TileLayoutEntity extends Entity implements ITileLayoutEntity {
    // #region Constructor

    constructor() {
        super(EntityTypes.TileLayouts);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getContentAsync(transaction?: ITransaction | null): Promise<ITileLayoutContent | null> {
        return await this.getDataRelationAsync<TileLayoutContent>(TileLayoutContent, 'Content', true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

