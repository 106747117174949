/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { IGuid } from 'safeguid';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatAll, map, toArray } from 'rxjs/operators';
import { eventFactory, IncidentEvent, IncidentEventType, IncidentEventTypeValues } from '../../models/events/incident-event';
import { McClient } from '../mc-client.service';

@Injectable()
export class IncidentEventService {
    constructor(private _mcClient: McClient) {
    }

    public getAllFromMcClient<T extends IncidentEvent>(url: string, searchParams: URLSearchParams): Observable<T[]> {


        return this._mcClient.getAll<T>(url, searchParams).pipe(
            concatAll(),
            map((ie) => (eventFactory(ie) as T)),
            toArray(),
            catchError((e) => {
                console.error(e);
                return EMPTY;
            })
        );
    }

    public getIncidentEvent<T extends IncidentEvent>(incidentId: IGuid, filter?: IncidentEventType[]): Observable<T[]> {
        const url = `/v2/Incidents/${incidentId.toString()}/events`;
        const searchParams = new URLSearchParams();

        if (filter && filter.length > 0) {
            searchParams.append('eventTypes', filter.map((t) => IncidentEventTypeValues[t].toString()).join(','));
        }
        return this.getAllFromMcClient<T>(url, searchParams);
    }

    public getAllIncidentEvents<T extends IncidentEvent>(incidentId: IGuid): Observable<T[]> {
        const url = `/v2/Incidents/${incidentId.toString()}/events`;
        const searchParams = new URLSearchParams();
        searchParams.append('eventTypes', '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19');
        return this.getAllFromMcClient<T>(url, searchParams);
    }
}
