// Autogenerated: v2/help/enumerations/ApiLogSeverity?codegen=ts&showresources=False on 2020-07-23 3:40:06 PM

export class LogSeverity {
    public static All = 'All';
    public static Debug = 'Debug';
    public static Error = 'Error';
    public static Fatal = 'Fatal';
    public static Full = 'Full';
    public static Information = 'Information';
    public static None = 'None';
    public static Performance = 'Performance';
    public static Warning = 'Warning';

    public static readonly values = [
        LogSeverity.All,
        LogSeverity.Debug,
        LogSeverity.Error,
        LogSeverity.Fatal,
        LogSeverity.Full,
        LogSeverity.Information,
        LogSeverity.None,
        LogSeverity.Performance,
        LogSeverity.Warning
    ];
}
