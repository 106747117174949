import { AfterViewInit, Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { GenMeltedTreeItem, GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { TreeFlavor } from '@genetec/gelato';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { commonInvestigateColumns } from '@modules/correlation/enumerations/common-columns';
import { CorrelationService } from '@modules/correlation/services/correlation.service';
import { FieldType } from '@modules/correlation/api/api';
import { sortAscending } from '@modules/shared/utilities/sort.helper';
import { TreeItem } from '@modules/shared/interfaces/tree-item/tree-item';
import { AnalyticsNames } from '@modules/shared/enumerations/analytics-names';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ColumnDescriptor } from '../../investigate/services/investigate-data.interfaces';
import { InvestigateDataContext } from '../../investigate/services/investigate-data.context';

interface ColumnVisibilityTupple {
    fieldKey: string;
    displayed: boolean;
    column: ColumnDescriptor;
}

interface DataTypeColumns {
    dataType: { id?: string; name: string };
    columns: { id: string; name: string; columnType: string }[];
}

@UntilDestroy()
@Component({
    selector: 'app-select-investigate-columns',
    templateUrl: './select-investigate-columns.component.html',
    styleUrls: ['./select-investigate-columns.component.scss'],
})
export class SelectInvestigateColumnsComponent extends ConnectionAwareModalComponent implements OnInit, AfterViewInit, OnDestroy {
    public selectedDataTypes: IGuid[] = [];
    public treeItems: TreeItem[] = [];
    public columns = new Map<string, ColumnVisibilityTupple>();
    public modalId = 'investigateColumnSelector';
    public readonly TreeFlavor = TreeFlavor;

    // Unwanted + not supported column types
    private excludedColumnTypes = [FieldType.Entity, FieldType.SoundPlayer, FieldType.WebView, FieldType.Geocode, FieldType.Barcode, FieldType.EntityIcon];

    constructor(
        authService: AuthService,
        injector: Injector,
        trackingService: TrackingService,
        private genModalService: GenModalService,
        private dataContext: InvestigateDataContext,
        private correlationService: CorrelationService,
        private logger: LoggerService,
        private analyticsService: AnalyticsService
    ) {
        super(authService, injector, trackingService);
    }

    public ngOnInit(): void {
        this.dataContext.whatFilter$
            .pipe(
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                untilDestroyed(this)
            )
            .subscribe(async (filter) => {
                this.selectedDataTypes = filter?.dataTypes ?? [];
                await this.initializeColumns();
            });
    }

    public ngAfterViewInit(): void {
        this.genModalService.hide(this.modalId);
    }

    public handleApply = (): Promise<boolean> => {
        this.updateStateWithSpinner();
        this.logSelectedColumns();

        return Promise.resolve(true);
    };

    public handleCancel = (): Promise<boolean> => {
        this.restoreFromState();
        return Promise.resolve(true);
    };

    public onItemChecked(item: TreeItem, event: CustomEvent<boolean>): void {
        if (item.children?.length) return; // Ignore parents
        this.setColumnDisplayed(item.id, event.detail);
    }

    public show(): void {
        this.genModalService.show(this.modalId);
    }

    private setColumnDisplayed(colKey: string, displayed: boolean): void {
        const columnVis = this.getColumn(colKey);
        if (!columnVis) throw new Error(`Column '${colKey}' not found`);
        this.columns.set(colKey, { ...columnVis, displayed });
        this.treeItems = this.updateTreeItemsIsChecked(this.treeItems, colKey);
    }

    private async initializeColumns(): Promise<void> {
        for (const iterator of this.columns.values()) {
            // If datatype is not selected or not datatype (core columns), remove the column
            if (iterator.column.dataType && !this.selectedDataTypes.find((col) => col.equals(iterator.column.dataType?.dataType))) {
                this.columns.delete(this.generateColKey(iterator.column));
            }
        }
        this.treeItems = commonInvestigateColumns.map((col) => {
            const colKey = this.generateColKey(col);
            if (!this.columns.has(colKey)) this.columns.set(colKey, { displayed: col.displayed, column: col, fieldKey: col.fieldKey });
            return {
                id: colKey,
                text: col.name,
                isChecked: col.displayed || this.getColumn(col)?.displayed,
            } as GenMeltedTreeItem;
        });
        if (this.selectedDataTypes.length === 0) return;
        const allDataTypes = await this.correlationService.getDataTypes();
        const registrations = await this.correlationService.getRegistrations(this.selectedDataTypes);
        const colIndexSart = commonInvestigateColumns[commonInvestigateColumns.length - 1].colIndex + 1;
        registrations?.forEach((reg) => {
            const dataType = allDataTypes?.find((x) => x.dataType.equals(reg.dataType));
            if (dataType) {
                const dataTypeTreeViewRoot = {
                    id: dataType.dataType.toString(),
                    text: dataType.name,
                    icon: dataType.icon as MeltedIcon,
                    children: [],
                } as TreeItem;

                reg.columns?.forEach((col, index) => {
                    // Exclude unwanted column types
                    if (!col.renderType || this.excludedColumnTypes.includes(col.renderType)) return;

                    if (!col.fieldName || !col.displayName || !col.renderType) {
                        if (!col.isExtended) {
                            this.logger.traceError(`Investigate column selection : Invalid column`, col);
                        }
                        return; // Skip columns without a name or fieldId
                    }

                    const colDesc: ColumnDescriptor = {
                        colIndex: colIndexSart + index,
                        fieldKey: col.fieldName,
                        name: col.displayName,
                        dataType,
                        type: col.renderType, // Have text for now, but we can add more types later
                        displayed: col.isDisplayed ?? false,
                        isSortable: true,
                    };
                    const colKey = this.generateColKey(colDesc);
                    if (!this.columns.has(colKey)) {
                        this.columns.set(colKey, { column: colDesc, displayed: col.isDisplayed ?? false, fieldKey: col.fieldName });
                    }
                    dataTypeTreeViewRoot.children?.push({
                        id: colKey,
                        text: col.displayName,
                        isChecked: col.isDisplayed || this.getColumn(colDesc)?.displayed,
                    });
                });
                if (dataTypeTreeViewRoot.children?.length) {
                    this.treeItems.push(dataTypeTreeViewRoot);
                }
            }
        });
        this.updateState();
    }

    private updateTreeItemsIsChecked(tree: GenMeltedTreeItem[], fieldId?: string) {
        for (const child of tree) {
            if (child.children?.length) {
                child.children = this.updateTreeItemsIsChecked(child.children, fieldId);

                // Update parent checkbox according to children
                child.isChecked = child.children.every((x) => x.isChecked);
            } else {
                if (fieldId && child.id !== fieldId) continue; // Only update the given field
                const colVis = this.getColumn(child.id);
                child.isChecked = colVis?.displayed ?? false;
            }
        }
        return [...tree];
    }

    private restoreFromState(): void {
        for (const colEntry of this.columns.entries()) {
            const [key, col] = colEntry;
            const fieldKey = col.fieldKey;
            const selected = this.dataContext.columns.find((x) => x.fieldKey === fieldKey);
            this.setColumnDisplayed(key, selected?.displayed ?? false);
        }
        this.treeItems = this.updateTreeItemsIsChecked(this.treeItems);
    }

    private updateStateWithSpinner(): void {
        this.dataContext.setTableBusy(true);

        // Wait until the spinner is displayed before doing the heavy lifting
        this.updateState();
    }

    private updateState(): void {
        setTimeout(() => {
            this.updateColumns();
        });
    }

    private updateColumns() {
        const columns = [];
        for (const col of this.columns.values()) {
            const column = { ...col.column, displayed: col.displayed };
            columns.push(column);
        }

        // All the columns are set, but their visibility (displayed field) can be toggled to false
        this.dataContext.setColumns(columns.sort(sortAscending('colIndex')));

        this.dataContext.setTableBusy(false);
    }

    private getColumn(col: ColumnDescriptor | string): ColumnVisibilityTupple | undefined {
        let key;
        if (typeof col !== 'string') key = this.generateColKey(col);
        else key = col;
        const colVis = this.columns.get(key);
        return colVis;
    }

    private generateColKey(col: ColumnDescriptor): string {
        return `${col.dataType?.dataType.toString() ?? 'core'}_${col.fieldKey}`;
    }

    private logSelectedColumns() {
        const coreColumnsName = 'Core';
        // Log the new selected columns.
        const columnsPerDataType: DataTypeColumns[] = [{ dataType: { name: coreColumnsName }, columns: [] }];
        const visibleColumns = [...this.columns.values()].filter((columnVisibility) => {
            return columnVisibility.displayed;
        });

        for (const { column } of visibleColumns) {
            // Core columns like description, title etc. does not contain dataType object.
            const dataType = columnsPerDataType.find((dataTypeColumns) => dataTypeColumns.dataType.name === (column.dataType?.name ?? coreColumnsName));
            if (dataType) {
                dataType.columns.push({ name: column.name, id: column.fieldKey, columnType: column.type });
            } else {
                columnsPerDataType.push({
                    dataType: { name: column.dataType?.name ?? coreColumnsName, id: column.dataType?.dataType.toString() },
                    columns: [{ name: column.name, id: column.fieldKey, columnType: column.type }],
                });
            }
        }
        this.analyticsService.logEvent(AnalyticsNames.Investigate.SelectedColumns, { selectedColumnsByDataType: columnsPerDataType });
    }
}
