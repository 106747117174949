import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

export const AlarmCommands = Constants({
    AcknowledgeAlarm: SafeGuid.parse('3925326B-B3F1-42FB-8387-38CB25651FF9'),
    AlternateAcknowledgeAlarm: SafeGuid.parse('7FE810E9-1485-4A9F-B1FE-137C7C17F8E1'),
    ForceAcknowledgeAlarm: SafeGuid.parse('FB7CDC9B-A004-4B7A-A3B6-D704424A663E'),
    InvestigateAlarm: SafeGuid.parse('61FABE62-94D6-4F26-B858-4A2F3A69E17B'),
    TriggerAlarm: SafeGuid.parse('C8E461B0-C413-4954-9C6D-B18627960F88'),
    ShowAlarmProcedure: SafeGuid.parse('4B99EFAE-FFC4-4220-BFE1-8AA4BC62E1C4'),
});
