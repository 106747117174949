import { Pipe, PipeTransform } from '@angular/core';

/*
  Convert html argb to rgba
  Example : <div [style.background-Color]="row.ArgbColor | argb"></div>
*/
@Pipe({ name: 'argb' })
export class ArgbPipe implements PipeTransform {
    public transform(argb: string | null | undefined): string {
        if (!argb) {
            return '';
        }
        const argbColor = argb.slice(1, argb.length); // Slice the #

        const a = parseInt(argbColor.slice(0, 2), 16) / 255;
        const r = parseInt(argbColor.slice(2, 4), 16);
        const g = parseInt(argbColor.slice(4, 6), 16);
        const b = parseInt(argbColor.slice(6, 8), 16);

        return `rgba(${r},${g},${b},${a})`;
    }
}
