// Array extensions
export {};
declare global {
    interface TypedHistogramBin<TCenter, TWidth> {
        count: number;
        center: TCenter;
        width: TWidth;
    }

    type HistogramBin = TypedHistogramBin<number, number>;

    interface Array<T> {
        /**
         * Returns an array where the elements are distinct given a selector of a property to do the distinct by
         *
         * @param selector - Function to select the property to compare
         * @returns The filtered array
         */
        distinctBy(selector: (a: T) => any): T[];

        /**
         * Remove all the elements of an array after the first match from the selector. Defaults to returning the entire array if no match
         *
         * @param selector - Function to select the match to trim after
         * @returns The adjusted array
         */
        removeAfter(selector: (a: T) => boolean): T[];
        /**
         * Shuffle the elements of the array using a uniform random distribution
         *
         * @returns The shuffled array
         */
        shuffle(): T[];

        /**
         * Compute a histogram of an array of values
         *
         * @param bins - The number of bins to create
         * @param selector - Function to select which value to use for the binning
         * @returns The histogram results
         */
        hist(bins: number, selector: (a: T) => number): HistogramBin[];

        /**
         * Get the maximum value of the array
         *
         * @returns The max value or null if the array is of length 0
         */
        max(): T | null;

        /**
         * Get the minimum value of the array
         *
         * @returns The min value or null if the array is of length 0
         */
        min(): T | null;

        /**
         * Compute the maximum value utilizing a selector
         *
         * @param selector - Function to select the value to use for computation
         * @returns the maximum value or null of array.length = 0
         */
        maxBy(selector: (a: T) => any): T | null;

        /**
         * Compute the minimum value utilizing a selector
         *
         * @param selector - Function to select the value to use for computation
         * @returns the minimum value or null of array.length = 0
         */
        minBy(selector: (a: T) => any): T | null;

        /**
         * Compute the sub-array at a given start index, optionally with a max number of elements
         *
         * @param start - The starting 0-based index
         * @param length - The max number of elements to take
         * @returns The new sub-array
         */
        sub(start: number, length?: number): T[];

        /**
         * Remove specific elements from the array (in-place)
         *
         * @param selector - Function to identify which element to remove
         */
        removeWhere(selector: (a: T) => boolean): void;

        /**
         * Remove the specified element from the array (in-place)
         *
         * @param element - The element to remove
         */
        remove(element: T): void;
    }
}

if (!Array.prototype.hist) {
    Array.prototype.hist = function <T>(this: T[], nbins: number, selector: (a: T) => number): HistogramBin[] {
        const bins = Array<HistogramBin>(nbins);

        const values = this.map(selector);
        const min = Math.min(...values);
        const max = Math.max(...values);
        const duration = max - min;
        const binWidth = duration / nbins;
        const halfWidth = binWidth / 2.0;

        for (let i = 0; i < nbins; i++) {
            let center = min + halfWidth;
            if (i > 0) {
                center += binWidth * i;
            }

            // left edge
            const low = center - halfWidth;
            // right edge
            const high = center + halfWidth;
            // count the points between the edges
            const count = values.filter((v) => v >= low && v < high).length;
            bins[i] = {
                center,
                width: binWidth,
                count,
            };
        }

        return bins;
    };
}

if (!Array.prototype.distinctBy) {
    Array.prototype.distinctBy = function <T>(this: T[], selector: (a: T) => any): T[] {
        return this.filter((thing, i, arr) => {
            if (arr) {
                const found = arr.find((t) => selector(t) === selector(thing));
                if (found) {
                    return arr.indexOf(found) === i;
                }
            }
            return false;
        });
    };
}

if (!Array.prototype.removeAfter) {
    Array.prototype.removeAfter = function <T>(this: T[], selector: (a: T) => boolean): T[] {
        const idx = this.findIndex(selector);
        if (idx >= 0 && this.length - idx - 1 > 0) {
            this.splice(idx + 1, this.length - idx - 1);
        }
        return this;
    };
}

if (!Array.prototype.shuffle) {
    Array.prototype.shuffle = function <T>(this: T[]): T[] {
        let currentIndex = this.length;
        let temporaryValue;
        let randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = this[currentIndex];
            this[currentIndex] = this[randomIndex];
            this[randomIndex] = temporaryValue;
        }

        return this;
    };
}

if (!Array.prototype.max) {
    Array.prototype.max = function <T>(this: T[]): T | null {
        if (this.length === 0) return null;

        let max = this[0];
        for (let i = 1; i < this.length; i++) {
            if (this[i] > max) {
                max = this[i];
            }
        }
        return max;
    };
}

if (!Array.prototype.min) {
    Array.prototype.min = function <T>(this: T[]): T | null {
        if (this.length === 0) return null;

        let min = this[0];
        for (let i = 1; i < this.length; i++) {
            if (this[i] < min) {
                min = this[i];
            }
        }
        return min;
    };
}

if (!Array.prototype.maxBy) {
    Array.prototype.maxBy = function <T>(this: T[], selector: (a: T) => any): T | null {
        if (this.length === 0) return null;

        let item = this[0];

        // TODO: should we validate we are comparing valid types?
        /* eslint-disable @typescript-eslint/no-unsafe-assignment */
        let max = selector(this[0]);
        for (let i = 1; i < this.length; i++) {
            if (selector(this[i]) > max) {
                max = selector(this[i]);
                item = this[i];
            }
        }
        /* eslint-enable @typescript-eslint/no-unsafe-assignment */
        return item;
    };
}

if (!Array.prototype.minBy) {
    Array.prototype.minBy = function <T>(this: T[], selector: (a: T) => any): T | null {
        if (this.length === 0) return null;

        let item = this[0];

        // TODO: should we validate we are comparing valid types?
        /* eslint-disable @typescript-eslint/no-unsafe-assignment */
        let min = selector(this[0]);
        for (let i = 1; i < this.length; i++) {
            if (selector(this[i]) < min) {
                min = selector(this[i]);
                item = this[i];
            }
        }
        /* eslint-enable @typescript-eslint/no-unsafe-assignment */
        return item;
    };
}

if (!Array.prototype.sub) {
    Array.prototype.sub = function <T>(this: T[], start: number, length?: number): T[] {
        // null/undefined
        if (!this) return this;
        // no array to take
        if (this.length === 0) return this;
        // no length requested
        if (length !== undefined && length !== null && length === 0) return [];
        // array index out of bounds
        if (start >= this.length) return [];
        // at end of array, only 1 el to take
        if (start === this.length - 1) return [this[start]];

        if (length !== undefined && length !== null && length > 0 && start + length < this.length) {
            return this.slice(start, start + length);
        }
        return this.slice(start);
    };
}

if (!Array.prototype.removeWhere) {
    Array.prototype.removeWhere = function <T>(this: T[], selector: (a: T) => boolean): void {
        let idx = this.findIndex((a) => selector(a));
        while (idx > -1) {
            this.splice(idx, 1);
            // loop and do it again if there are more matches
            idx = this.findIndex((a) => selector(a));
        }
    };
}

if (!Array.prototype.remove) {
    Array.prototype.remove = function <T>(this: T[], element: T): void {
        const idx = this.findIndex((a) => a === element);
        if (idx > -1) {
            this.splice(idx, 1);
        }
    };
}
