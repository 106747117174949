import { ComponentFactoryResolver, ComponentRef, Directive, Input, ViewContainerRef } from '@angular/core';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { MenuItemsComponent } from '@shared/components/menu-item/menu-item.component';

/**
 * Use this directive to automatically add gen-menu-item(s) based on a datasource. This directive will invoke the MenuItemsComponent to do the rendering.
 * If you want a more "granular" control over the behavior of the gen-menu-items, use the declarative approach as stated in the Gelato documentation.
 *
 * This directive was mainly written to accomodate backward compatibility with the previous behaviour of the GenMenu (which supported datasource.).
 *
 * @input the datasource that you want rendered. The datasource assumes you have an execute function bound to every menu-item.
 */
@Directive({
    selector: '[appMenuDataSource]',
})
export class MenuDataSourceDirective {
    private menuItemsComponent?: ComponentRef<MenuItemsComponent>;
    @Input() set appMenuDataSource(dataSource: ContextMenuItem[] | undefined | null) {
        if (dataSource) {
            // First time render
            if (!this.menuItemsComponent) {
                // create the MenuItemComponent
                const menuItemsComponentFactory = this.componentFactoryResolver.resolveComponentFactory(MenuItemsComponent);
                this.menuItemsComponent = menuItemsComponentFactory.create(this.viewContainer.injector);

                // Pass our datasource to it
                this.menuItemsComponent.instance.dataSource = dataSource ?? [];

                // run a round of change detection
                this.menuItemsComponent.changeDetectorRef.detectChanges();

                // Inject the menuItem in the GenMenu
                const domElement = this.viewContainer.element.nativeElement as HTMLElement;
                domElement.appendChild(this.menuItemsComponent.location.nativeElement);
            } else {
                // Component already created, only reassing datasource and run change detection.
                // Pass our datasource to it
                this.menuItemsComponent.instance.dataSource = dataSource;

                // run a round of change detection
                this.menuItemsComponent.changeDetectorRef.detectChanges();
            }
        }
    }

    constructor(private viewContainer: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}
}
