import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

/**
 * Will validate if there's less than 24 hours between start time and end time.
 * To be used by passing directly to the validators array in a FormGroup.
 *
 * @param control - an AbstractControl (usually a FormGroup) that has a child control called 'startTime' and another called 'endTime'
 */
function twentyFourHoursValidator(control: AbstractControl): ValidationErrors | null;

/**
 * Will validate if there's less than 24 hours between start time and end time.
 * To be used by calling the function with the keys of the FormGroup's startTime and the endTime.
 *
 * @param startTime - the key that reprensents a startTime in the FormGroup
 * @param endTime - the key that reprensents a endTime in the FormGroup
 */
function twentyFourHoursValidator(startTimeKey: string, endTimeKey: string): ValidatorFn;

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function twentyFourHoursValidator(startTimeKeyOrControl: string | AbstractControl, endTimeKey?: string): ValidatorFn | ValidationErrors | null {
    if (typeof startTimeKeyOrControl === 'string' && typeof endTimeKey === 'string') {
        return (control: AbstractControl) => {
            const startTime = control.get(startTimeKeyOrControl);
            const endTime = control.get(endTimeKey);
            if (!startTime || !endTime) {
                return null;
            }
            return validate(startTime, endTime);
        };
    } else {
        const startTime = (startTimeKeyOrControl as AbstractControl).get('startTime');
        const endTime = (startTimeKeyOrControl as AbstractControl).get('endTime');
        if (!startTime || !endTime) {
            return null;
        } else {
            return validate(startTime, endTime);
        }
    }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function validate(startTime: AbstractControl, endTime: AbstractControl): ValidationErrors | null {
    // has errors
    if (startTime && endTime && moment(endTime.value as string).diff(startTime.value as string, 'h') >= 24) {
        setErrors(startTime);
        setErrors(endTime);

        return { twentyFourHours: true };
    }

    // no errors
    if (startTime) {
        removeErrors(startTime);
    }
    if (endTime) {
        removeErrors(endTime);
    }

    return null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function setErrors(control: AbstractControl) {
    control.setErrors({ ...control.errors, twentyFourHours: true });
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function removeErrors(control: AbstractControl) {
    if (control.errors?.twentyFourHours) {
        // Angular Forms considers a FormControl as invalid when Object.keys(control.errors) >= 1
        delete control.errors.twentyFourHours;
        control.updateValueAndValidity();
    }
}

export { twentyFourHoursValidator };
