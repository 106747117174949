import { ListItem } from '@modules/shared/interfaces/list-item';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon, SelectionType } from '@genetec/gelato';
import { FilterBaseComponent } from '../filter-base.component';

/**
 * Filter restraining on element selection in a list
 */
@Component({
    selector: 'app-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss'],
})
export class FilterListComponent extends FilterBaseComponent<ListItem[]> implements OnInit {
    @Input() public isSearchable = false;
    @Output() public reload = new EventEmitter();
    @Output() public searchChange = new EventEmitter<string>();

    @Input() public set itemsSource(source: ListItem[] | null) {
        const itemSource = source ?? [];

        this.visibleItemsSource = [];

        for (const item of itemSource) {
            if (!this.itemSourceCatalog.has(item.id)) {
                this.itemSourceCatalog.set(item.id, item.isChecked ?? false);
            } else {
                //Already in catalog
                item.isChecked = this.itemSourceCatalog.get(item.id);
            }
            this.visibleItemsSource.push(item);
        }
    }

    public readonly Icon = Icon;
    public ListSelectionType = SelectionType;

    public searchText!: string;
    public visibleItemsSource: ListItem[] = [];

    private itemSourceCatalog = new Map<string, boolean>();

    ngOnInit() {
        super.ngOnInit();
        this.value = [];
    }

    public onReload = (): void => this.reload.emit();

    public onSearchChange = (search: string): void => this.searchChange.emit(search);

    public onSelectedItemsChange(item: ListItem, isChecked: boolean): void {
        item.isChecked = isChecked;
        this.itemSourceCatalog.set(item.id, isChecked);

        this.computeValue();
    }

    public onSelectAll(): void {
        this.visibleItemsSource?.forEach((x) => (x.isChecked = true));
        this.itemSourceCatalog.forEach((_, key) => this.setItemCheckedStateIfVisible(key, true));
        this.computeValue();
    }

    public onUnselectAll(): void {
        this.visibleItemsSource?.forEach((x) => (x.isChecked = false));
        this.itemSourceCatalog.forEach((_, key) => this.setItemCheckedStateIfVisible(key, false));
        this.computeValue();
    }

    public updateState(): void {
        const selectedItems = this.value;

        let selection = this.state.selection;

        if (!selectedItems || selectedItems.length === 0) {
            selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
        } else if (selectedItems.length === 1) {
            selection = this.visibleItemsSource.find((x) => x.isChecked)?.text ?? '';
        } else {
            selection = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: selectedItems.length }) as string;
        }

        this.state = { ...this.state, selection };
    }

    public clearFilter(): void {
        this.itemSourceCatalog = new Map<string, boolean>();
        this.onUnselectAll();
        this.searchText = '';
    }

    public isDefaulted(): boolean {
        return this.value.length === 0;
    }

    public override isDirty(firstValue: ListItem[], secondValue: ListItem[]): boolean {
        const arr1 = firstValue.map((listItem: ListItem) => listItem.id);
        const arr2 = secondValue.map((listItem: ListItem) => listItem.id);

        if (arr1.length !== arr2.length) {
            return true;
        }

        // compare each item
        return arr1.some((value) => arr2.indexOf(value) === -1);
    }

    public override getDefaultValue(): ListItem[] {
        return [];
    }

    private computeValue(): void {
        const items: ListItem[] = [];
        this.itemSourceCatalog.forEach((value, key) => items.push({ id: key, text: '', isChecked: value }));
        this.value = items.filter((x) => x.isChecked);
    }

    private setItemCheckedStateIfVisible(key: string, isChecked: boolean): void {
        if (this.visibleItemsSource.some((x) => x.id === key)) {
            this.itemSourceCatalog.set(key, isChecked);
        }
    }
}
