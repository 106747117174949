// Autogenerated: CodeGen/PageInfo?language=ts&InterfaceOnly=True on 2020-09-15 2:34:03 PM
// Autogenerated: CodeGen/PageInfo?language=ts&InterfaceOnly=False on 2020-09-15 2:34:03 PM

import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { FieldObject } from 'RestClient/Helpers/FieldObject';

export interface IPageInfo extends IFieldObject {
    lastEntityId: IGuid;
    page: number;
    pageSize: number;
}

export class PageInfo extends FieldObject implements IPageInfo {
    // LastEntityId
    public get lastEntityId(): IGuid {
        return this.getFieldGuid('LastEntityId');
    }
    public set lastEntityId(value: IGuid) {
        this.setFieldGuid('LastEntityId', value);
    }
    // Page
    public get page(): number {
        return this.getField<number>('Page');
    }
    public set page(value: number) {
        this.setField<number>('Page', value);
    }
    // PageSize
    public get pageSize(): number {
        return this.getField<number>('PageSize');
    }
    public set pageSize(value: number) {
        this.setField<number>('PageSize', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('LastEntityId', SafeGuid.EMPTY);
        this.setField<number>('Page', 0);
        this.setField<number>('PageSize', 0);
    }

    //#region Custom Methods

    public clone(): PageInfo {
        const pageInfo = new PageInfo();
        pageInfo.page = this.page;
        pageInfo.pageSize = this.pageSize;
        pageInfo.lastEntityId = this.lastEntityId;
        return pageInfo;
    }

    //#endregion Custom Methods
}
