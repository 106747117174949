import { Utils } from './Utils';
import { PlaySpeed } from '../players/PlaySpeed';

export interface ITimeResolver {
  canResolve(generation: number): boolean;
  updateResolver(mediaTime: number, baseTime: Date): void;
  resolve(mediaTime: number): ResolvedFrameTime;
  setPlaySpeed(playSpeed: PlaySpeed): void;
  toString(): string;
}

export class TimeResolver {
  private m_mediaBaseTime: number;

  private m_absoluteBaseTime: Date;

  private m_playSpeed: PlaySpeed;

  constructor(currentMediaTime: number, currentBaseTime: Date, playSpeed = PlaySpeed._1x) {
    this.m_mediaBaseTime = currentMediaTime;
    this.m_absoluteBaseTime = currentBaseTime;
    this.m_playSpeed = playSpeed;
  }

  public toString(): string {
    return `${Utils.formatDateUTC(this.m_absoluteBaseTime)} => ${this.m_mediaBaseTime}, PlaySpeed: ${this.m_playSpeed}`;
  }

  public updateResolver(mediaTime: number, baseTime: Date): void {
    this.m_absoluteBaseTime = baseTime;
    this.m_mediaBaseTime = mediaTime;
  }

  public resolve(mediaTime: number): ResolvedFrameTime {
    return new ResolvedFrameTime(Utils.addSeconds(this.m_absoluteBaseTime, (mediaTime - this.m_mediaBaseTime) / 1000 * this.m_playSpeed.Value), mediaTime);
  }

  public setPlaySpeed(playSpeed: PlaySpeed): void {
    this.m_playSpeed = playSpeed;
  }
}

export class GenerationTimeResolver implements ITimeResolver {
  private readonly m_generation: number;

  private readonly m_timeResolver: TimeResolver;

  constructor(generation: number, mediaBaseTime: number, absoluteBaseTime: Date, playSpeed = PlaySpeed._1x) {
    this.m_generation = generation;
    this.m_timeResolver = new TimeResolver(mediaBaseTime, absoluteBaseTime, playSpeed);
  }

  public canResolve(generation: number): boolean {
    return this.m_generation === generation;
  }

  public toString(): string {
    return `Generation: ${this.m_generation} ${this.m_timeResolver.toString()}`;
  }

  public updateResolver(mediaTime: number, baseTime: Date): void {
    this.m_timeResolver.updateResolver(mediaTime, baseTime);
  }

  public resolve(mediaTime: number): ResolvedFrameTime {
    return this.m_timeResolver.resolve(mediaTime);
  }

  public setPlaySpeed(playSpeed: PlaySpeed): void {
    this.m_timeResolver.setPlaySpeed(playSpeed);
  }
}

export class ResolvedFrameTime {
  private readonly m_mediaTime: number;

  private readonly m_frameTime: Date;

  public get mediaTime(): number {
    return this.m_mediaTime;
  }

  public get frameTime(): Date {
    return this.m_frameTime;
  }

  constructor(frameTime: Date, mediaTime: number = -1) {
    this.m_frameTime = frameTime;
    this.m_mediaTime = mediaTime;
  }

  public toString(): string {
    return this.m_mediaTime !== -1 ? `${Utils.formatDateUTC(this.m_frameTime)} (${Math.round(this.m_mediaTime)})` : `${Utils.formatDateUTC(this.m_frameTime)}`;
  }
}
