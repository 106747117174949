<div *ngIf="incident" class="link-incident-grid mt-1" [ngClass]="{ disabled: disabled, 'selected-incident': selected }">
    <div class="incident-color" [style.background-Color]="incident.argbColor | argb"></div>
    <app-incident-icon [iconId]="incident.iconId" class="incident-type-icon"></app-incident-icon>
    <div class="first-row">
        <div class="flex-cell">
            <div class="primary-text truncate" [appTooltipOnlyIfOverflow]="true" [appTooltip]="incident.name + ' (#' + incident.displayId + ')'" [ngClass]="{ disabled: disabled }">
                {{ incident.name + ' (#' + incident.displayId + ')' }}
            </div>
        </div>
        <div class="secondary-text" [ngClass]="{ disabled: disabled }">
            {{ incident.triggerTimeUtc | dateFormatWithTimezone: 'MM-DD h:mm:ss A' | async }}
        </div>
    </div>
    <div class="second-row">
        <div class="secondary-text" [ngClass]="{ disabled: disabled }">
            {{ incident.location?.entityId | incidentLocationName | async }}
        </div>
        <div class="secondary-text" [ngClass]="{ disabled: disabled }">
            {{ incident.stateId | stateName | async }}
        </div>
    </div>
</div>
