import { ILogger } from '../utils/logger';
import { HtmlElements } from './htmlElements';
import { EventManager } from '../eventManager';
import { ISessionStateView } from './WebSocket/SessionState';
import { PlayerStateChangeEvent, StreamingConnectionStatusChangeEvent, ErrorStatusEvent } from '../events';
import { PlayerState, StreamingConnectionStatus, PlayerMode } from '../enums';
import { DigitalZoomControl } from '../digitalZoomControl/DigitalZoomControl';

export class DebugOverlay {
  private static m_debounceCtrlShiftA = false;

  private static m_areEventListenersRegistered: boolean = false;

  private readonly m_logger: ILogger;

  private readonly m_playerId: number;

  private readonly m_htmlElements: HtmlElements;

  private readonly m_digitalZoomControl: DigitalZoomControl;

  private readonly m_eventManager: EventManager;

  private readonly m_keyDownBinding: (event: KeyboardEvent) => void;

  private m_sessionStateView: ISessionStateView | undefined;

  private m_errorDetails: ErrorStatusEvent | undefined;

  private m_minWidthOverlay = 0;

  private m_playerState: PlayerStateChangeEvent = new PlayerStateChangeEvent(PlayerState.Starting, '');

  private m_streamingConnectionStatus: StreamingConnectionStatusChangeEvent = new StreamingConnectionStatusChangeEvent(StreamingConnectionStatus.Initializing, '');

  private isVisible = false;

  public set sessionStateView(sessionStateView: ISessionStateView) {
    this.m_sessionStateView = sessionStateView;
  }

  constructor(logger: ILogger, playerId: number, htmlElements: HtmlElements, digitalZoomControl: DigitalZoomControl, eventManager: EventManager) {
    this.m_logger = logger.subLogger('DebugOverlay');
    this.m_playerId = playerId;
    this.m_htmlElements = htmlElements;
    this.m_digitalZoomControl = digitalZoomControl;
    this.m_eventManager = eventManager;
    this.registerEventListeners();
    this.m_eventManager.frameRendered.register(this.render);
    this.m_eventManager.playerStateChanged.register(this.onPlayerStateChanged);
    this.m_eventManager.streamStatusChanged.register(this.onStreamingConnectionStatusChanged);
    this.m_eventManager.errorStateRaised.register(this.onError);
    this.m_keyDownBinding = this.onKeyDown.bind(this);
    document.addEventListener('keydown', this.m_keyDownBinding, false);
  }

  public dispose() {
    this.m_eventManager.frameRendered.unregister(this.render);
    this.m_eventManager.playerStateChanged.unregister(this.onPlayerStateChanged);
    this.m_eventManager.streamStatusChanged.unregister(this.onStreamingConnectionStatusChanged);
    document.removeEventListener('keydown', this.m_keyDownBinding, false);
    this.m_logger.debug?.trace('disposed');
  }

  public reset() {
    this.m_sessionStateView = undefined;
    this.m_playerState = new PlayerStateChangeEvent(PlayerState.Starting, '');
    this.m_streamingConnectionStatus = new StreamingConnectionStatusChangeEvent(StreamingConnectionStatus.Initializing, '');
  }

  public toggleShown(show: boolean) {
    this.isVisible = show;
    this.m_minWidthOverlay = 0;
    this.render();
  }

  private registerEventListeners() {
    // This event is registered only once for the whole application, no need to unregister
    if (DebugOverlay.m_areEventListenersRegistered) return;
    DebugOverlay.m_areEventListenersRegistered = true;

    document.addEventListener('keyup', (event) => {
      // Wait for the 'A' key to be released before allowing another overlay toggle
      if (event.key === 'A' || event.key === 'a') {
        DebugOverlay.m_debounceCtrlShiftA = false;
      }
    }, false);
  }

  private onKeyDown(event: KeyboardEvent) {
    // If our video element was the last clicked element and the Ctrl+Shift+A key combo is done, show/hide the debug overlay
    if (!DebugOverlay.m_debounceCtrlShiftA &&
      this.m_htmlElements.IsFocusOnVideo &&
      event.ctrlKey && (event.shiftKey || event.altKey) && (event.key === 'A' || event.key === 'a')) {
      this.isVisible = !this.isVisible;
      this.m_minWidthOverlay = 0;
      this.render(); // Force update to the canvas right now
      DebugOverlay.m_debounceCtrlShiftA = true; // Prevent multiple toggles if the user keeps the keys pressed
    }
  }

  private readonly onPlayerStateChanged = (playerState: PlayerStateChangeEvent) => {
    this.m_playerState = playerState;
  }

  private readonly onStreamingConnectionStatusChanged = (streamingConnectionStatus: StreamingConnectionStatusChangeEvent) => {
    this.m_streamingConnectionStatus = streamingConnectionStatus;
  }

  private readonly onError = (errorDetails: ErrorStatusEvent) => {
    this.m_errorDetails = errorDetails;
  }

  private readonly render = () => {
    const canvas = this.m_htmlElements.DebugOverlayCanvas;

    // Resize the canvas' buffer if we need to
    if (canvas.scrollWidth !== canvas.width || canvas.scrollHeight !== canvas.height) {
      canvas.width = canvas.scrollWidth;
      canvas.height = canvas.scrollHeight;
    }

    // Clear canvas
    const ctx = canvas.getContext('2d');
    ctx!.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the overlay
    if (ctx === null || !this.isVisible) return;

    // Select data we would like to print, in the right order
    const interestingData = this.collectInterestingData();

    // Note: The canvas draw surface size is always resized to match the canvas actual display size
    const fontHeight = 11;
    ctx.font = fontHeight + 'px Roboto, sans-serif';
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';

    // Compute the required width and height to display every property
    let minWidthLeft = 0;
    let minWidthRight = 0;
    let minHeight = 0;
    let i;
    const numberOfProps = interestingData.length;
    for (i = 0; i < numberOfProps; i++) {
      interestingData[i][0] = interestingData[i][0] + ': ';
      const propNameSize = ctx.measureText(interestingData[i][0]);
      const propValueSize = ctx.measureText(interestingData[i][1]);
      if (propNameSize.width > minWidthLeft) {
        minWidthLeft = propNameSize.width;
      }
      if (propValueSize.width > minWidthRight) {
        minWidthRight = propValueSize.width;
      }
      minHeight += fontHeight + 5; // 5 px of padding between each line
    }

    // Center the overlay and draw, if it's too big we let it overflow down or right
    let minWidth = (minWidthRight < minWidthLeft ? minWidthLeft : minWidthRight) * 2;
    if (this.m_minWidthOverlay > minWidth) {
      minWidth = this.m_minWidthOverlay;
    } else {
      this.m_minWidthOverlay = minWidth;
    }
    let x = (canvas.width - (minWidth + 20)) / 2;
    if (x < 0) x = 0;
    let y = (canvas.height - (minHeight + 20)) / 2;
    if (y < 0) y = 0;
    this.roundRect(ctx, x, y, minWidth + 20, minHeight + 20, 12);

    /* JDT - Removed
    const hideText = '(hide with Ctrl+Alt+A)';
    const tempFont = ctx.font;
    ctx.font = (fontHeight - 2) + 'px Roboto, sans-serif';
    ctx.fillStyle = 'gold';
    ctx.fillText(hideText, x + 3, y + 10);

    //Restore font
    ctx.font = tempFont;
    */

    // Now print the text!
    const middlex = x + ((minWidth + 20) / 2);
    y += 10;
    for (i = 0; i < numberOfProps; i++) {
      y += fontHeight; // The text is drawn OVER the given position
      const propNameWidth = ctx.measureText(interestingData[i][0]).width;
      ctx.fillStyle = 'gold';
      ctx.fillText(interestingData[i][0], middlex - propNameWidth, y);
      ctx.fillStyle = 'yellow';
      ctx.fillText(interestingData[i][1], middlex, y);

      //After the first line draw a line. As to separate the 'title' from the rest
      if (i === 0) {
        y += 5;
        ctx.strokeStyle = 'gold';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(x + 10, y + 1);
        ctx.lineTo(x + 10 + minWidth, y + 1);
        ctx.closePath();
        ctx.stroke();
      }
      y += 5; // 5 px of padding between each line
    }
  }

  private collectInterestingData(): string[][] {
    const interestingData = [];

    if (this.m_sessionStateView === undefined) {
      interestingData.push(['Player state', 'Not started']);
      // JDT - Removed - interestingData.push(['Version', version()]);
      return interestingData;
    }

    interestingData.push(['Tile ID', 'GWP #' + this.m_playerId]);
    // JDT - Removed - interestingData.push(['Version', version()]);
    if (this.m_errorDetails !== undefined) {
      interestingData.push(['Last error', this.m_errorDetails.errorCode + ' (' + this.m_errorDetails.value + ')']);
    }


    interestingData.push(['Codec', this.m_eventManager.codec]);
    if (this.m_eventManager.codec === 'H264') {
      interestingData.push(['Resolution', this.m_htmlElements.VideoElement.videoWidth + ' x ' + this.m_htmlElements.VideoElement.videoHeight]);
    } else if (this.m_eventManager.codec.toUpperCase() === 'MJPEG') {
      interestingData.push(['Resolution', this.m_eventManager.lastJpegResolution.toString()]);
    }

    let playerMode: string = this.m_sessionStateView.playerMode !== undefined ? this.m_sessionStateView.playerMode.toString() : 'none';
    playerMode = `${playerMode}${this.m_sessionStateView.isPaused ? ' (paused)' : ''}${this.m_sessionStateView.isPtzMode ? ' (PTZ Mode)' : ''}`;
    interestingData.push(['Player mode', playerMode]);
    interestingData.push(['Player state', PlayerState[this.m_playerState.playerState] + ' (' + this.m_playerState.value + ')']);
    interestingData.push(['Streaming state', StreamingConnectionStatus[this.m_streamingConnectionStatus.state] + ' (' + this.m_streamingConnectionStatus.value + ')']);

    if (this.m_sessionStateView.playerMode === PlayerMode.live) {
      if (this.m_eventManager.networkLatency !== null) {
        interestingData.push(['Network Latency (s)', '' + this.m_eventManager.networkLatency]);
      }
      if (this.m_eventManager.globalLatency !== null && this.m_eventManager.codec !== 'MJPEG') {
        interestingData.push(['Global Latency (s)', '' + this.m_eventManager.globalLatency]);
      }
    }
    interestingData.push(['Speed', this.m_sessionStateView.playSpeed.toString()]);

    if (this.m_eventManager.lastFrameReceived.frameTime.getTime() !== 0) {
      interestingData.push(['Last frame received', this.m_eventManager.lastFrameReceived.toString()]);
    }

    if (this.m_eventManager.lastFrameRendered.frameTime.getTime() !== 0) {
      interestingData.push(['Last frame rendered', this.m_eventManager.lastFrameRendered.toString()]);
    }
    if (this.m_eventManager.codec === 'H264') {
      interestingData.push(['Media time', '' + Math.round(this.m_htmlElements.VideoElement.currentTime * 1000)]);
    }

    const bitRate = this.m_eventManager.bitRate;
    interestingData.push(['Bitrate', bitRate.toString()]);

    const frameRate = this.m_eventManager.frameRate;
    interestingData.push(['Framerate', frameRate.frameRateToString()]);

    const audioBitRate = this.m_eventManager.audioBitRate;
    interestingData.push(['Audio Bitrate', audioBitRate.toString()]);

    if (this.m_eventManager.codec === 'H264') {
      interestingData.push(['Key frame interval', frameRate.keyFrameRateToString()]);
    }

    if (this.m_digitalZoomControl.Zoom > 1) {
      interestingData.push(['Zoom Level', '' + this.m_digitalZoomControl.Zoom + 'x']);
    }

    //todo for dewarping:
    //Dewarping available
    //Dewarping enabled
    //Dewarping frame rate
    //Dewarping Parameters: -> debug command

    return interestingData;
  }

  /**
   * Draws a rounded rectangle using the current state of the canvas.
   * If you omit the last two params, it will draw a filled rectangle with no outlines
   * @param ctx - Rendering Context
   * @param x - The top left x coordinate
   * @param y - The top left y coordinate
   * @param width - The width of the rectangle
   * @param height - The height of the rectangle
   * @param radius - The corner radius;
   */
  private roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fill();
  }
}
