import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ButtonFlavor, Icon, ItemSlot, TextFlavor } from '@genetec/gelato';
import { Color, ContextMenuPosition, DateFormat, MeltedIcon } from '@genetec/gelato-angular';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { CommandsService, COMMANDS_SERVICE, ContentService, SIDE_CONTEXT_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { ContextMenuFactory } from '@modules/shared/services/context-menu/context-menu.factory';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EventTypes } from 'RestClient/Client/Enumerations/EventTypes';
import { IAlarmAcknowledgedEvent } from 'RestClient/Client/Interface/IAlarmAcknowledgedEvent';
import { AlarmEntityFields, IAlarmEntity } from 'RestClient/Client/Interface/IAlarmEntity';
import { IAlarmInvestigatingEvent } from 'RestClient/Client/Interface/IAlarmInvestigatingEvent';
import { IAlarmTriggeredEvent } from 'RestClient/Client/Interface/IAlarmTriggeredEvent';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { AlarmEntity } from 'RestClient/Client/Model/AlarmEntity';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { WINDOW } from '@utilities/common-helper';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AlarmAcknowledgeAction } from '../../controllers/alarms.controller';
import { AlarmContentTypes } from '../../enumerations/alarm-content-types';
import { AlarmsService } from '../../services/alarms.service';
import { AlarmItem } from './alarm-item';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-alarms-context-widget',
    templateUrl: './alarms-context-widget.component.html',
    styleUrls: ['./alarms-context-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: AlarmsContextWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: AlarmsContextWidgetComponent.pluginId, standalone: true },
    isContentSupported: (content: Content) => !!content?.type.equals(AlarmContentTypes.AlarmList),
    requirements: { features: [KnownFeatures.alarmsId], globalPrivileges: [KnownPrivileges.allowAlarmManagementPrivilege] },
})
export class AlarmsContextWidgetComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('FAC4D8FD-2A84-4FC8-9C1A-4B0088B7EC78');

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly ContextMenuPosition = ContextMenuPosition;
    public readonly Icon = Icon;
    public readonly ItemSlot = ItemSlot;
    public readonly TextFlavor = TextFlavor;

    public alarmItems: AlarmItem[] = [];
    public alarmActions$: Observable<ContextMenuItem[]>;
    public isAlarmActionsOpen = false;
    public actionTarget = '';
    public content?: Content;
    public dataContext?: ContentService;
    public isLoading = true;

    private readonly scClient: ISecurityCenterClient;
    private isAdminUser = false;
    private alarmActionsSubject = new Subject<ContextMenuItem[]>();

    constructor(
        @Inject(SIDE_CONTEXT_SERVICE) private sideContextService: ContentService,
        @Inject(COMMANDS_SERVICE) public commandsService: CommandsService,
        private alarmService: AlarmsService,
        private contentProviderService: ContentProviderService,
        private timeService: TimeService,
        securityCenterProvider: SecurityCenterClientService,
        trackingService: TrackingService,
        private translateService: TranslateService,
        private contextMenuFactory: ContextMenuFactory,
        @Inject(WINDOW) private window: Window,
        private viewContainerRef: ViewContainerRef
    ) {
        super(trackingService);

        this.scClient = securityCenterProvider?.scClient;
        this.alarmActions$ = this.alarmActionsSubject.asObservable();
    }

    async ngOnInit() {
        super.ngOnInit();

        if (this.scClient) {
            const userInfo = this.scClient.currentUserInfo;
            if (userInfo) {
                this.isAdminUser = userInfo.isAdministrator;
            }

            this.alarmService.alarmEventReceived$.pipe(untilDestroyed(this)).subscribe((eventArg) => this.onAlarmContextEventReceived(eventArg));

            await this.refreshAlarmInstances(true);
        }

        this.isLoading = false;
    }

    public setDataContext(context: ContentService): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public getAlarmIcon(alarmItem: AlarmItem): MeltedIcon {
        return alarmItem.investigating ? MeltedIcon.Search : MeltedIcon.Alarm;
    }

    public async onOptionsClicked(alarmItem: AlarmItem, event: MouseEvent): Promise<void> {
        event.stopPropagation();

        // reclicking the dots will close previous popup if already open
        if (this.contextMenuFactory.isContextMenuOpen) {
            this.contextMenuFactory.destroy();
            return;
        }

        // Listen for incoming data;
        this.alarmActions$.pipe(take(1), untilDestroyed(this)).subscribe((menuItems: ContextMenuItem[]) => {
            const targetSelector = `#alarm${alarmItem.instanceId.toString()}`;
            this.contextMenuFactory.showMenuAsync(this.viewContainerRef, menuItems, targetSelector).fireAndForget();
        });

        // Fetch menu information.
        const alarmActions = await this.loadAlarmActions(alarmItem);
        if (alarmActions) {
            this.alarmActionsSubject.next(alarmActions);
        }
    }

    public async onAlarmInfoRequested(item: AlarmItem): Promise<void> {
        if (item) {
            const contentRequest = new FieldObject();
            contentRequest.setField('AlarmInstanceId', item.instanceId);
            const content = await this.contentProviderService.getContentFromFieldsAsync(contentRequest);
            if (content) {
                this.sideContextService.pushContent(content);
            }
        }
    }

    private async refreshAlarmInstances(clear: boolean) {
        if (clear) {
            this.alarmItems.length = 0;
        }

        const alarmResult = await this.alarmService?.getInstancesAsync();
        const instances = alarmResult?.alarmInstances;
        if (instances && alarmResult) {
            const sortedInstances = Array.from(instances).sort((a, b) => {
                if (a.triggerTime && b.triggerTime) {
                    return b.triggerTime.getTime() - a.triggerTime.getTime();
                } else return -1;
            });

            this.alarmItems.removeWhere((item) => sortedInstances.every((sortedItem) => sortedItem.instanceID !== item.instanceId));
            for (let i = 0; i < sortedInstances.length; i++) {
                const instance = sortedInstances[i];

                const entityData = alarmResult.entityLookup.get(instance.alarmId.toString());
                if (entityData) {
                    const descriptionText = this.timeService.formatTime(instance.triggerTime, DateFormat.DateTime);

                    if (clear || this.alarmItems.findIndex((item) => item.instanceId === instance.instanceID) === -1) {
                        this.alarmItems[i] = {
                            name: entityData.getField<string>('name'),
                            description: descriptionText,
                            color: '#' + entityData.getField<string>('color').slice(3), // removes alpha
                            instanceId: instance.instanceID,
                            alarmId: instance.alarmId,
                            investigating: !instance.investigatedBy.isEmpty(),
                            customIconId: entityData.getField<IGuid>('customIconId'),
                            hasMoreMenu: await this.hasAlarmMoreMenuAsync(instance.alarmId),
                        };
                    }
                }
            }
        }
    }

    private async getAlarmEntityWithProcedureFieldsAsync(alarmId: IGuid): Promise<AlarmEntity | null> {
        const alarmFields = [EntityFields.idField, AlarmEntityFields.enableProcedureField, AlarmEntityFields.procedureField];
        return await this.scClient.getEntityAsync(AlarmEntity, alarmId, undefined, undefined, alarmFields.toString());
    }

    private async hasAlarmMoreMenuAsync(alarmId: IGuid): Promise<boolean> {
        const alarmEntity = await this.getAlarmEntityWithProcedureFieldsAsync(alarmId);
        if (!alarmEntity) {
            return false;
        }
        const hasAcknowledgeAlarmsPrivilege = (await alarmEntity.hasPrivilegesAsync(SafeGuid.createSet([KnownPrivileges.acknowledgeAlarmsPrivilege]))) ?? false;
        const hasProcedure = alarmEntity.enableProcedure && !!alarmEntity.procedure;
        return hasAcknowledgeAlarmsPrivilege || hasProcedure;
    }

    private async loadAlarmActions(alarmItem: AlarmItem): Promise<ContextMenuItem[] | undefined> {
        const alarmActions = [];
        const alarmEntity = await this.getAlarmEntityWithProcedureFieldsAsync(alarmItem.alarmId);
        if (alarmEntity) {
            if (await alarmEntity.hasPrivilegesAsync(SafeGuid.createSet([KnownPrivileges.acknowledgeAlarmsPrivilege]))) {
                alarmActions.push({
                    id: this.translateService.instant('STE_ACTION_ALARM_ACK') as string,
                    text: this.translateService.instant('STE_ACTION_ALARM_ACK') as string,
                    icon: MeltedIcon.Checkmark,
                    iconColor: Color.Pistacchio,
                    actionItem: {
                        execute: async () => {
                            const action = this.buildAckAction(alarmItem);
                            await this.alarmService.acknowledgeAsync(action);
                        },
                    },
                });
                alarmActions.push({
                    id: this.translateService.instant('STE_ACTION_ALARM_NACK') as string,
                    text: this.translateService.instant('STE_ACTION_ALARM_NACK') as string,
                    icon: MeltedIcon.Checkmark,
                    iconColor: Color.Arancia,
                    actionItem: {
                        execute: async () => {
                            const action = this.buildAckAction(alarmItem);
                            action.alternate = true;
                            await this.alarmService.acknowledgeAsync(action);
                        },
                    },
                });
                if (this.isAdminUser) {
                    alarmActions.push({
                        id: this.translateService.instant('STE_ACTION_ALARM_FORCE_ACK') as string,
                        text: this.translateService.instant('STE_ACTION_ALARM_FORCE_ACK') as string,
                        icon: MeltedIcon.ForciblyAcknowledge,
                        actionItem: {
                            execute: async () => {
                                const action = this.buildAckAction(alarmItem);
                                action.force = true;
                                await this.alarmService.acknowledgeAsync(action);
                            },
                        },
                    });
                }
                if (!alarmItem.investigating) {
                    alarmActions.push({
                        id: this.translateService.instant('STE_ACTION_ALARM_INVESTIGATE') as string,
                        text: this.translateService.instant('STE_ACTION_ALARM_INVESTIGATE') as string,
                        icon: MeltedIcon.Search,
                        actionItem: {
                            execute: async () => {
                                await this.alarmService.investigateAsync(alarmItem.instanceId, alarmItem.alarmId);
                            },
                        },
                    });
                }
            }
            if (alarmEntity.enableProcedure && alarmEntity.procedure) {
                alarmActions.push({
                    id: this.translateService.instant('STE_ACTION_SHOWALARMPROCEDURE') as string,
                    text: this.translateService.instant('STE_ACTION_SHOWALARMPROCEDURE') as string,
                    icon: MeltedIcon.Link,
                    actionItem: {
                        execute: () => {
                            let procedure = alarmEntity.procedure;
                            if (procedure.indexOf('https://') < 0 && procedure.indexOf('http://') < 0) {
                                procedure = 'http://' + procedure;
                            }
                            this.window.open(procedure, '_blank');
                        },
                    },
                });
            }
            return alarmActions;
        }
    }

    private async onAlarmContextEventReceived(arg: EventReceivedArg) {
        if (arg.event) {
            const maxCount = this.alarmService.getListMaxCount();
            if (arg.event.eventType === EventTypes.AlarmTriggered) {
                const alarmEvent = arg.event as IAlarmTriggeredEvent;
                const alarmEntity = await this.scClient.getEntityAsync<AlarmEntity, IAlarmEntity>(
                    AlarmEntity,
                    alarmEvent.sourceEntity,
                    null,
                    null,
                    EntityFields.customIconIdField,
                    AlarmEntityFields.colorField
                );

                if (alarmEntity) {
                    const descriptionText = this.timeService.formatTime(alarmEvent.timestamp, DateFormat.DateTime);

                    if (this.alarmItems.length === maxCount) {
                        this.alarmItems.pop();
                    }

                    this.alarmItems.unshift({
                        name: alarmEntity.name,
                        description: descriptionText,
                        color: '#' + alarmEntity.color.slice(3), // removes alpha
                        instanceId: alarmEvent.instanceId,
                        alarmId: alarmEvent.sourceEntity,
                        investigating: false,
                        customIconId: alarmEntity.customIconId,
                        hasMoreMenu: await this.hasAlarmMoreMenuAsync(alarmEntity.id),
                    });
                }
            }

            if (
                arg.event.eventType === EventTypes.AlarmAcknowledged ||
                arg.event.eventType === EventTypes.AlarmAcknowledgedAlternate ||
                arg.event.eventType === EventTypes.AlarmForciblyAcked
            ) {
                if (this.alarmItems.length === maxCount) {
                    // needs to refresh to fill in the gap... do not clear, add only new ones
                    await this.refreshAlarmInstances(false);
                } else {
                    const alarmEvent = arg.event as IAlarmAcknowledgedEvent;
                    const alarmIndex = this.alarmItems.findIndex((item) => item.instanceId === alarmEvent.instanceId);
                    if (alarmIndex !== -1) {
                        this.alarmItems.splice(alarmIndex, 1);
                    }
                }
            }

            if (arg.event.eventType === EventTypes.AlarmInvestigating) {
                const alarmEvent = arg.event as IAlarmInvestigatingEvent;
                const alarmIndex = this.alarmItems.findIndex((item) => item.instanceId === alarmEvent.instanceId);
                if (alarmIndex !== -1) {
                    this.alarmItems[alarmIndex].investigating = true;
                }
            }

            if (arg.event.eventType === 'RefreshAlarmsEvent') {
                await this.refreshAlarmInstances(true);
            }
        }
    }

    private buildAckAction(alarmItem: AlarmItem) {
        const action = new AlarmAcknowledgeAction();
        action.instanceId = alarmItem.instanceId;
        action.alarmId = alarmItem.alarmId;
        return action;
    }
}
