// Autogenerated: v2/help/enumerations/resources?codegen=ts on 2022-12-21 2:23:43 PM

export class Resources {
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CANTDELETEDB = 0;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASE = 1;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATASERVER = 2;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DBALREADYEXIST = 3;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DBNAME = 4;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DSINCOMINGPORT = 5;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ENABLETELNET = 6;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_FAILEDCREATEDB = 7;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_INVALIDDBNAME = 8;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_OVERWRITEDB = 10;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_PASSWORD = 11;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TAB_SYSTEM = 13;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TELNETPORT = 14;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD = 15;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADDGROUP = 16;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_APPLY = 17;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CANCEL = 18;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CLOSE = 19;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT = 21;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_FIRMWARE_UPGRADE = 22;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IMPORT = 23;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_LOGS = 24;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OK = 25;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REFRESH = 26;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REMOVE = 27;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SEARCH = 28;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_EDIT = 29;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_LOGOFF = 30;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_LOGON = 31;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_WINDOWS_CREDENTIALS = 32;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYORDINAL_FIRST = 33;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYORDINAL_FOURTH = 34;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYORDINAL_LAST = 35;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYORDINAL_SECOND = 36;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYORDINAL_THIRD = 37;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_DAY = 38;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_FRIDAY = 39;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_MONDAY = 40;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_SATURDAY = 41;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_SUNDAY = 42;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_THURSDAY = 43;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_TUESDAY = 44;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_WEDNESDAY = 45;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_WEEKDAY = 46;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DAYSELECTION_WEEKENDDAY = 47;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_APRIL = 48;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_AUGUST = 49;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_DECEMBER = 50;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_EVERYMONTH = 51;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_FEBRUARY = 52;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_JANUARY = 53;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_JULY = 54;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_JUNE = 55;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_MARCH = 56;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_MAY = 57;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_NOVEMBER = 58;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_OCTOBER = 59;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_MONTHS_SEPTEMBER = 60;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_PERIODTYPE_ORDINAL = 62;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_PERIODTYPE_SPECIFIC = 63;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_TIMECOVERAGE_ALLDAY = 65;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_TIMECOVERAGE_RANGE = 66;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COVERAGE_TIMEFRAME_DAILY_DAYS = 68;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COVERAGE_TIMEFRAME_FUZZYDAILY_DAYS = 69;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COVERAGE_TIMEFRAME_WEEKLY_DAYS = 70;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ADMINUSER = 71;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ADMINUSERGROUP = 72;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ALWAYSCOVERAGE = 73;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWACCESSPOINT = 74;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWACCESSPOINTRULE = 76;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWCOVERAGE = 77;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWCREDENTIAL = 78;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWDEVICE = 79;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWPARTITION = 80;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWPERSON = 81;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWPERSONGROUP = 82;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWUNIT = 83;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWUSER = 84;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWUSERGROUP = 85;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_SERVICEUSER = 86;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_SYSTEMCONFIG = 87;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_SYSTEMSECTION = 88;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_USERSUSERGROUP = 89;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEVICE_NICKNAME = 90;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEVICE_SERIAL = 91;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DLG_CONNECT_INFO = 92;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCESSPOINT = 93;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCESSPOINTRULE = 95;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_APPLICATION = 96;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_COVERAGE = 97;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_CREDENTIAL = 98;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_DEVICE = 99;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PARTITION = 100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PERSON = 101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PERSONGROUP = 102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_SYSTEMCONFIG = 103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_UNIT = 104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_UNKNOWN = 105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_USER = 106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_USERGROUP = 107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIRMWARE_VER = 109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GENERIC_READY = 110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BAUDRATE = 111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL = 113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABIT = 115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATASTORE = 116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAY = 117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESCRIPTION = 118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEVICE_TYPE = 119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_ADD = 124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_DELETEENTITY = 126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_APPLICATIONS = 129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_AREAS = 130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_CREDENTIALS = 131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_DOORS = 132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_ELEVATORS = 133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_PERSONGROUPS = 134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_PERSONS = 135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_SCHEDULES = 136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_UNITS = 137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_USERGROUPS = 138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_USERS = 139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_APPLICATIONS = 140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_AREAS = 141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_CREDENTIALS = 142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_DOORS = 143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_ELEVATORS = 144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_PERSONGROUPS = 145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_PERSONS = 146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_SCHEDULES = 147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_UNITS = 148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_USERGROUPS = 149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_USERS = 150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_HIDE = 151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HANDSHAKE = 157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NAME = 159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OF = 160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARITY = 161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD = 162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERIODTYPE = 163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERIALPORT = 166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOPBIT = 167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THE = 168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIMECOVERAGE = 169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TREEVIEW_DISPLAYCATEGORY = 171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TREEVIEW_TARGETFIELD_GROUPNAME = 176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERNAME = 180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CONNECT = 181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_DISCONNECT = 182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_HELP = 183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_HELP_ABOUT = 184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_RENAME = 187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SYSTEM = 188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SYSTEM_CONNECT = 189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SYSTEM_DISCONNECT = 190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SYSTEM_EXIT = 191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSG_CONNECTION_ERROR = 196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_ENTITIES = 198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_ENTITY = 199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_SAVEMODIFIEDCONFIG = 200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK_ADDR = 203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_QUESTION_SAVEMODIFICATIONS = 207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_CONNECTION_CANCELLOGON = 210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_CONNECTION_INFO = 211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_CONNECTION_LOGGEDON = 212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_CONNECTION_LOGONFAILURE = 213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_CONNECTION_LOST = 214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_IDENTITY = 215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_PROPERTIES = 216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TEST_WAZZA = 217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ABOUT = 218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADDCREDENTIAL = 219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CONFIRMATION = 220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CONNECT = 221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ERROR = 222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_FIRMWARE_UPGRADE_SELECT_LOAD = 225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_RENAME = 227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WARNING = 229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ENTITYTYPEFILTER = 230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSERT_ENTITY = 231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TITLE_CAPSLOCK = 234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_CREATIONDATE = 235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_DESCRIPTION = 236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_FIRSTNAME = 237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_LASTNAME = 238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_NAME = 239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIRM_PASSWORD = 240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_PASSWORD = 241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PASSWORD_MODIFICATION = 242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_PRIVILEGES = 243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEMBERS = 244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEPARTMENT = 245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SEARCH = 246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGE = 249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CPU = 250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALTYPE = 251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLIESTO = 252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNSPECIFIED = 256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDID = 257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FACILITYCODE = 258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOTSPECIFIED = 259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ALLPERSON = 260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIRMWARE_VERSION = 261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERIAL_NUMBER = 262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_ACCESSPOINTRULES = 263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_ACCESSPOINTRULES = 264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SELECT_PERSON = 265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERSONSAFFECTEDBYRULE = 266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSGRANTED = 267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSREFUSED = 268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNASSIGNEDCREDENTIAL = 269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNKNOWNCREDENTIAL = 270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_USERLOGIN = 271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_USERLOGOUT = 272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ALLOPENRULE = 275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_LOCKDOWNRULE = 276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITS = 278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK_CONFIG = 279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK_GATEWAY = 280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK_NETMASK = 281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK_USE_DHCP = 282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK_USE_STATIC = 283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERRESPONSETIMEOUT = 284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEREQUESTINTERVAL = 285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWSECTION = 289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_SECTION = 290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DOORSIDE_A = 292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DOORSIDE_B = 293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_SIDE = 295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCESSPOINTGROUP_AREA = 296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCESSPOINTGROUP_DOOR = 297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCESSPOINTGROUP_ELEVATOR = 298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_OCCURRENCES = 299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_TIME = 300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ABORT = 301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IGNORE = 302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_NO = 303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RETRY = 304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_YES = 305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_AUTOCLOSE_MESSAGE = 306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NOTIFICATIONS = 307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_SECTIONS = 309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_SECTIONS = 311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESET = 312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIRMWARE_UPGRADE_PROGRESS = 313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWALARM = 314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ALARM = 315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_ZONE = 316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_ENTITY_HAS_BEEN_REMOVED = 318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_FINISH = 320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_ENTITY_HAS_BEEN_UPDATED = 322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MARRIEDCHANNELS = 323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERY_TYPE = 325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IP_ADDRESS = 326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ITEM = 327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ITEMS = 328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PORT = 329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOFTWARE_VERSION = 332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_DISCOVERY = 335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_LICENSE = 336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DATERANGE_AFTER = 337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DATERANGE_BEFORE = 338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DATERANGE_BETWEEN = 339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AND = 341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_ENTITIES = 345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_SECTIONS = 348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HARDWARE_MODEL = 350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_TOO_MANY_UNITS_DISCOVERED = 351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_TYPE = 352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_ENTITY_FROM_MEMBERSHIP = 353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTRANCE = 354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXIT = 355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_ACK = 356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_TRIGGERED = 357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_ACTIONS = 358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_ACK = 359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_CUSTOMACK = 360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_NACK = 361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_TRIGGER = 362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SECURITYDESK_DISPLAY_ENTITY = 363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SENDMESSAGE = 365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_NONE = 366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_IPENDPOINT = 368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_SERVER = 369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_UNIT_TYPE = 370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTENSION_TYPE = 371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUEST_SENT = 372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_ALARMS = 374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIORITY = 375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_TRIGGERALARM = 376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_RECIPIENTS = 378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STATE = 379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ACTIVEALARMCOUNT = 381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ARCHIVEDALARMCOUNT = 382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATASERVERVERSION = 383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DBVERSION = 384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ENTITYCOUNT = 385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_IMAGES = 387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_PICTURE_FILE = 391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIRECTORY = 394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROADCAST_MODE = 398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROADCAST_MODE_SEQUENTIAL = 399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROADCAST_MODE_SIMULTANEOUS = 400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIORITY_HIGHEST = 401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIORITY_LOWEST = 402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REACTIVATION_THRESHOLD = 403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECIPIENT = 404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYPAD_CODE = 407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DENY_ACCESS_TO_CARDHOLDERS = 408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GRANT_ACCESS_TO_CARDHOLDERS = 411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN_RULE_IS_ACTIVE = 412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DOOR_SIDE_NAME = 414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SYSTEMACCESSRIGHTS = 415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SYSTEMLICENSEKEY = 416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SYSTEMVALIDATIONKEY = 417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_BADLICENSEFILE = 418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CANTOPENLICENSE = 419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CANTSAVECHALLENGE = 420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_EXPIRATIONDATE = 421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_INVALIDLICENSEFILE = 422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEALARM = 423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSECOMPANY = 424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEREADER = 425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEPACKAGE = 426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEPHONE = 427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSESYSTEMID = 428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SUPPORTED = 429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEEXPIRED = 430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_PORTLOCK = 431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSENOTSTARTED = 433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEFAULT_LAYOUT_NAME_ALARMS = 434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA = 439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OMNICAST_VI_STATE_LOGIN_ON = 447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_UNIT_INPUTS = 449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_LICENSEVALID = 452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_DBLOST = 453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_DBRECOVERED = 454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_DEFAULTENTITIESFAILED = 455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NODBCONFIGURED = 456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_WRONGDBVERSION = 457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DAYS = 458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_VERSIONS = 460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_ADD_CREDENTIAL = 462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_CREATE_ACCESS_RULE = 463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_ADD_NEW_CARDHOLDER = 464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_OPENEDFORTOOLONG = 469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_VIEWENTITY = 470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_ACTIVE = 473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_EXPIRED = 474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_INACTIVE = 475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_LOST = 476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_STOLEN = 477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STATUS = 478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_ASSIGN_TO_NEW_DOOR = 479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WIZARD_CAPTION_DISCOVERY_PORT = 486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLYING = 487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTING = 488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_DISCOVERY_PORT = 489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED = 490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUCCEEDED = 491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CLIENTCOUNT = 492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEVICETYPE_INPUTPIN = 493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNASSIGNED = 494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_DOORSENSOR = 498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_NONE = 499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_PULLSTATION = 500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_REX = 501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOSE_WIZARD = 502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIZARD_CONNECTION_LOST = 503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_USER = 504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_BASIC_INFO_PAGE_SUBTITLE = 505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_BASIC_INFO_PAGE_TITLE = 506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_DESCRIPTION = 509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_SECTION_INFO = 510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_EXISTING_SECTION = 511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_USER_INFO_PAGE_TITLE = 512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_POSTCREATION_PAGE_TITLE = 515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_PRECREATION_PAGE_SUBTITLE = 516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_PRECREATION_PAGE_TITLE = 517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATION_NEW_ENTITY = 518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_COULD_NOT_ROLLBACK = 519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_FAILED_UPDATEDS_FAILED = 520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_ROLLEDBACK = 521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUCCESSFUL_OPERATION = 522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_NAME = 523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_CARDHOLDER = 524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_CARDHOLDERGROUP = 525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_USERGROUP = 526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_CARDHOLDER_INFO_PAGE_TITLE = 527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_ALARM = 528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_AREA = 529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_SCHEDULE = 530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_SECTION = 531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_UNIT_INFO_PAGE_TITLE = 532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_END_POINT = 533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_UNIT = 534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_CREDENTIAL_AUTOMATIC_CREATION_PAGE_TITLE = 535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_CREDENTIAL_CREATION_MODE_PAGE_TITLE = 536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC_CREDENTIAL_CREATION_DESCRIPTION = 537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_CREDENTIAL_AUTOMATICALLY = 538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_CREDENTIAL_MANUALLY = 539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERED_CREDENTIALS = 540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUAL_CREDENTIAL_CREATION_DESCRIPTION = 541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_CREDENTIAL_MANUAL_CREATION_PAGE_TITLE = 543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CODE = 544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_CREDENTIAL_ASSIGNMENT_PAGE_TITLE = 546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGN_CREDENTIAL_LATER = 547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGN_CREDENTIAL_NOW = 548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATED_ON_X = 549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATION_OF_CREDENTIAL_X = 550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_DISCOVERED_ON_X = 551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_INFO = 552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_CREDENTIAL = 553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_CARDHOLDER_CREDENTIAL_CREATION_PAGE_TITLE = 554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_CREDENTIAL_LATER = 555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_CREDENTIAL_NOW = 556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COVERAGE = 559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_ACCESSPOINTRULE_INFO_PAGE_TITLE = 560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_ACCESSPOINTRULE = 561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_DOOR = 562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_DOOR_INFO_PAGE_TITLE = 563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHANGE_UNIT_PORT_PAGE_TITLE = 564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHANGE_UNIT_PORT_RESULT_PAGE_TITLE = 565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_DOOR_HARDWARE_PAGE_TITLE = 566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_SENSOR = 568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_PIN = 569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NONE = 570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PULL_STATION = 571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READER = 572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUEST_TO_EXIT = 573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMALLYCLOSED = 574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMALLYOPENED = 575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TYPE = 576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_REX = 577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_DOORCLOSE = 579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_DOOROPEN = 580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_PULLSTATIONACTIVATE = 581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_PULLSTATIONNORMAL = 582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYEVENT_PERSONACCESS = 585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYEVENT_UNASSIGNED_CREDENTIAL = 586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ID = 587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT = 588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MORE = 589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_LAYOUTS_RENAME = 593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS = 594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_CLEAREVENTS = 595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_EVENTFILTER = 596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_OPTIONS = 597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_FULLSCREEN = 598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_BRINGTOFRONT = 603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_NAME = 604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_GENERAL_NAME = 607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_GENERAL_PAGESIZE = 608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_VISUAL_NAME = 609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYEVENT_AREAINPERSONACCESS = 622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYEVENT_AREAOUTPERSONACCESS = 623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_WIZARD_DEFAULTCREDENTIALNAME = 624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_NAME_CONNECTED = 627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_NAME_LOST = 628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_DEVICES = 629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_ALARMS = 630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_DEVICES = 631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOADING_ELLIPSIS = 632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_ENTITY_SECTION = 633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_USER_ALARM = 634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_USER_APPLICATION = 635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_INFO = 637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_ALARM_USER = 638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_APPLICATION_UNIT = 639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_APPLICATION_USER = 640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_APR_AREA = 641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_APR_MEMBERS = 643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_APR_SCHEDULE = 644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_AREA_APR = 645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_AREA_CHILDREN = 646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_AREA_PARENTS = 647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CARDHOLDER_APR = 648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CARDHOLDER_CARDHOLDERGROUP = 649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CARDHOLDER_CREDENTIAL = 650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CARDHOLDERGROUP_APR = 651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CARDHOLDERGROUP_CHILDREN = 652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CARDHOLDERGROUP_PARENTS = 653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CREDENTIAL_CARDHOLDER = 654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_DOOR_APR = 655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_DOOR_AREA = 656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_DOOR_UNIT = 657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_SCHEDULE_APR = 658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_SECTION_MEMBERS = 659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_SECTION_PARENTS = 660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_SECTION_RIGHTS = 661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_UNIT_APPLICATION = 662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_UNIT_DEVICE = 663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_UNIT_DOOR = 664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_USERGROUP_CHILDREN = 665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_USERGROUP_PARENTS = 666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CAPTURE_UNABLETOCONNECT = 667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CARDHOLDER_NAME_WALKTHROUGH = 668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_APPLICATION_NAME_CONNECTED = 669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_APPLICATION_NAME_LOST = 670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_CONNECTIONLOST = 671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_FAILED = 672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_INSUFFICIENTPRIV = 673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_INVALIDCREDENTIAL = 674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_INVALIDDATASTOREVERSION = 675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_NEWBACKUPDS = 676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_OPERATIONTIMEOUT = 678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_SUCCESS = 679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_TRYCONNECT = 680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENDTIME = 682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_WALKTHROUGH = 683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STARTTIME = 684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_RANGE = 685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WALKTHROUGH_BY_REPORTING = 686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WALKTHROUGH_INFO_PAGE_TITLE = 687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WALKTHROUGH_MIXED_MODE = 688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WALKTHROUGH_MODE = 689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WALKTHROUGH_REALTIME = 690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_DOOR_WALKTHROUGH = 691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_DOOR = 693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_NAME = 695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_WALKTHROUGH_DEVICE_DISCOVERY_PAGE_TITLE = 696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_WALKTHROUGH_DOOR_INFO_PAGE_TITLE = 697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WALKTHROUGH_DOOR_CREATION_PAGE_SUBTITLE = 698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFLICTS_DETECTED = 699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_WALKTHROUGH_PRECREATION_PAGE_TITLE = 700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CONFLICTS = 701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNASSIGNED_DEVICE = 702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION = 703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BOOKMARK = 705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STARTRECORDING = 706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STOPRECORDING = 707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNKNOWN = 708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRESET = 710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY = 712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SPLASH_CONFIG_TOOL_FINALIZING_LEFT_PANE_CONSTRUCTION = 716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SPLASH_CONFIG_TOOL_FINALIZING_LOGON_CONSTRUCTION = 717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SPLASH_CONFIG_TOOL_FINALIZING_RIGHT_PANE_CONSTRUCTION = 718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SPLASH_CONFIG_TOOL_LOADING_LOGON_BAR = 726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SPLASH_CONFIG_TOOL_REGISTERING_FOR_PROXY_EVENTS = 729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_RIGHTS = 730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTROLLER_ON_PORT_X = 731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORPHAN_UNITS = 732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DISCOVERY_ITEM_REMOVED_FROM_CHANGE_PORT_LIST = 733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_CARD = 734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_AREAACTIVITY = 735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_CREDENTIALACTIVITY = 737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_DOORACTIVITY = 738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_PERSONACTIVITY = 740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLICATION_OPTIONS = 747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_UNIT_LOST = 755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_OPEN = 760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GOTOPRESET = 761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_FORCEOPEN = 762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EXPIREDCREDENTIAL = 765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INACTIVECREDENTIAL = 766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INACTIVEPERSON = 767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LOSTCREDENTIAL = 768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_STOLENCREDENTIAL = 769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FROM = 771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TO = 773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATE_RANGE = 774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAYS = 775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOURS = 777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONTHS = 778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEEKS = 780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YEARS = 781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_QUERY_RESULTCOUNT = 783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_QUERY_TIMEOUT = 784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_TIMESTAMP = 785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FALSE = 786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_NAME = 791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_ONLINE = 792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_UNIT = 794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRUE = 795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_REX_SIDE = 796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_AREA = 797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_DIRECTION = 798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_TIMEOUT = 800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANYCREDENTIAL = 803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANYLOCATION = 804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANYPERSON = 805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTING_ERROR_TOOMANYRESULTS = 806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNKNOWN_ERROR = 808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTING_ERROR_EXCEPTION = 809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISCELLANEOUS = 814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMQUERY_BASE = 815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_ACKBY = 819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL = 826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRATIONDATE = 828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIREAT = 830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRSTUSE = 831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NIET = 832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERSON_ACTIVE = 833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERSON_EXPIRED = 834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIREAFTER = 836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_PICTURE = 837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_NORMALLY = 838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENTS = 841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DEENERGIZED = 842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ENERGIZED = 843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_NOSELECTION = 845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_TITLE_NOSELECTION = 846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOROPENED_WHEN_LOCKED = 847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_EVENTDB_CONNECTIONFAILED = 848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_EVENTDB_CONNECTIONLOST = 849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_EVENTDB_CONNECTIONRESTORED = 850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_QUESTION_DELETE_DATABASE = 851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ACTION_RESTART = 853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ACTION_START = 854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ACTION_STOP = 855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_UNIT_RECOVERED = 856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TAB_CONSOLE = 857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SEPARATOR_GENERAL = 861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_CREDENTIALNOTUNIQUE = 862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_GENERIC = 863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_LICENSE = 864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_USERALREADYEXIST = 865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_GENERICFOREIGNKEYDELETE = 866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_ERRORDELETE = 867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_ERRORDEP = 868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFORMATION = 871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MESSAGE = 874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_EMAILADD = 875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SERVERIP = 876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ENABLEBACKUP = 881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_EVERY = 882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AT = 884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKUP = 885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOLDER = 886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTORE = 887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATIONFAILED = 888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_BACKUPFILE = 889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_KEEP = 890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_PLUGINS = 893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT = 900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTS = 901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMSTATE_ACKED = 902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMSTATE_ACTIVE = 903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_MANUALACTIONEXECUTE = 904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DBPATH = 905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_EVENT_TIME_RANGE = 906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYREPORT_COLUMNNAME_EVENT_TIMESTAMP = 914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYREPORT_COLUMNNAME_RULE = 915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYREPORT_COLUMNNAME_SIDE_DIRECTION = 916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMREPORT_COLUMNNAME_ACKNOWLEDGED_ON = 921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMREPORT_COLUMNNAME_ACKNOWLEDGED_REASON = 922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMREPORT_COLUMNNAME_SOURCE = 926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMREPORT_COLUMNNAME_TRIGGER = 928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMREPORT_COLUMNNAME_TRIGGERING_TIME = 929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DURING_THE_LAST_X_Y = 930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_ACTIVITY_REPORT = 932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECK_ALL = 933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_ACTIVITY_REPORT = 934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURING_THE_LAST = 936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_RANGE = 938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNCHECK_ALL = 941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_DAY_S = 944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_HOUR_S = 945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_EQUAL_TO = 947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_GREATER_THAN = 948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_GREATER_THAN_OR_EQUAL_TO = 949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_LESS_THAN = 950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_LESS_THAN_OR_EQUAL_TO = 951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_MINUTE_S = 952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_MONTH_S = 953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_SECOND_S = 954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_WEEK_S = 955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_YEAR_S = 956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_TAMPER = 968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ARCHIVEDEVENTSCOUNT = 970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_RECURSIVE = 971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_APR_APG = 972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_ERRORDELETEALARM = 973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LICENSEERROR = 974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RENAME_REPORT = 979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_STATE = 981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONCLUSION = 982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COVERAGE_X_NOT_IN_ACTION = 983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_NOT_LINKED_TO_CARDHOLDER = 984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_STATE = 985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICTED_ACCESS_POINT = 986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICTED_ACCESS_POINT_RULE = 987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICTED_CARDHOLDER = 988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICTED_COVERAGE = 989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICTED_CREDENTIAL = 990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_DOES_NOT_TARGET_CARDHOLDER = 991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_NOT_BINDED_TO_SCHEDULE = 992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CONCLUSION_ACCESS_NOT_GRANTED = 993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CONCLUSION_ERROR = 994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CONCLUSION_LIKELY_GRANTED = 995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CONCLUSION_OK = 996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CONCLUSION_UNKNOWN = 997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKING_ACCESS_RULE = 1001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_ALLOWED_CARDHOLDER_DURING_COVERAGE_X = 1002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_DENIED_CARDHOLDER_DURING_COVERAGE_X = 1003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOT = 1004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_TO_AREA_X = 1005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_TO_DOOR_X = 1006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE = 1007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANTIPASSBACKHARD = 1014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANTIPASSBACKMODE = 1015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANTIPASSBACKSOFT = 1017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANTIPASSBACKTIMEOUT = 1018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ANTIPASSBACK = 1019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_FIRSTMANIN = 1020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_LASTMANOUT = 1021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STRICT = 1022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_AUTOUNLOCK_SCHEDULES = 1023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNLOCK_COVERAGE = 1024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNLOCK_EXCEPTION_COVERAGE = 1025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_LOCK = 1026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_SCHEDULED_LOCK = 1027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_SCHEDULED_UNLOCK = 1028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_GUESTACTIVITY = 1029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTING_REPORTPREVIEW = 1030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_GUEST = 1032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GUESTREPORT_COLUMNNAME_FIRSTNAME = 1044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GUESTREPORT_COLUMNNAME_LASTNAME = 1045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GUESTREPORT_COLUMNNAME_SUPERVISORFIRSTNAME = 1046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GUESTREPORT_COLUMNNAME_SUPERVISORLASTNAME = 1047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GUESTREPORT_COLUMNNAME_VISITREASON = 1048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUESTSGROUP = 1050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISITOR = 1052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUT_PIN = 1057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_COULD_NOT_DELETE_ENTITIES = 1062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_FOR_DOOR_SIDE_X = 1064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_FOR_DOOR_X = 1065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_ONLINE_ACCESS_POINT_RULES = 1066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_FOR_AREA_X = 1069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_FOR_ENTRANCE_AREA_X = 1070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_FOR_EXIT_AREA_X = 1071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRATION = 1076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_AREAPRESENCE = 1092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR_ALL = 1094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CAPTION_DETAILS = 1095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNTITLED = 1099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPANY = 1101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLTYPES = 1102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDERSONLY = 1103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUESTSONLY = 1107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_UNIT_DISCOVERY = 1109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_UNIT_DISCOVERY = 1111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_COPYTOCLIP = 1118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_IMPORTFROMFILE = 1119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_INVALID_LICENSE = 1120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_NO_LICENSE_FOUND = 1121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_PASTEFROMCLIP = 1122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SAVETOFILE = 1123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_VALID_LICENSE = 1124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERY_PORTS = 1128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TAB_LICENSE = 1133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_CARDHOLDERSTATUS = 1146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_CREDENTIALSTATUS = 1147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_REPORTS = 1150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SENDEMAIL = 1151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE = 1152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYBACK = 1153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERY_PORT = 1154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CT_EMAIL = 1156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACKREASON_ACKED = 1157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACKREASON_NACKED = 1160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACKREASON_NONE = 1161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GUESTWIZARD_PHONENUMBER = 1163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISOR = 1164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REPORT_TOOMANYRESULTS = 1165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COUNT = 1166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_QUERYING = 1167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_SETOUTPUTDEVICE = 1175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_VISITORPERSONGROUP = 1177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REXHOLDOFF_AFTERGRANT = 1178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REXHOLDOFF_AFTERCLOSED = 1179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REXHOLDOFF_WHILEOPENED = 1180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_JUMP_TO_CONFIG = 1190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_INVERTED_READER_LEDS = 1193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ANTIPASSBACK_FORGIVE = 1195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_APR_SCHEDULE_WILL_BE_OVERWRITTEN = 1196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_APR_SCHEDULE_REVERTED_TO_ALWAYS = 1197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REASSIGN_CREDENTIALS = 1198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ANTIPASSBACK_FORGIVEN = 1199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_SECTION_INSERTION_FAILED = 1200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECTION_INSERTION_FAILED_LIMIT_REACHED = 1202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TOGGLE_TO_ADVANCED_MODE = 1203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TOGGLE_TO_BASIC_MODE = 1204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CLEAR_LIST = 1207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_COMMIT = 1208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ROLLBACK = 1209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_STOP_SERVICE = 1211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_START_SERVICE = 1212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_NEXT_PAGE = 1217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PREVIOUS_PAGE = 1218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CLEAR_SELECTED_ITEMS = 1219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_USER_LOGON = 1221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_USER_LOGOFF = 1222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REFRESH = 1223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ENTITY_SEARCH = 1227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSERT_ITEM = 1228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REMOVE_ITEM = 1229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EDIT_PICTURE = 1230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CLEAR_PICTURE = 1232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT_PICTURE = 1233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_LATER = 1234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EARLIER = 1235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TOGGLE_TO_HIGH_RESOLUTION = 1236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TOGGLE_TO_LOW_RESOLUTION = 1237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EDIT_ITEM = 1238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DELETE_ITEM = 1239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_ITEM = 1240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FORCE_DOOR_OPEN = 1241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DISPLAY_IN_TILE = 1242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EXPAND_TILE = 1246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PLAYBACK_FORWARD = 1248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PLAYBACK_PLAY = 1249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PLAYBACK_PAUSE = 1250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PLAYBACK_REWIND = 1251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FORGIVE_ANTIPASSBACK = 1255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_BOOKMARK = 1256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RECORDING_STATE = 1257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TIMELINE_ZOOM_IN = 1258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TIMELINE_ZOOM_OUT = 1259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_UPGRADE_UNIT = 1268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CREATE_DOOR = 1269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DESTROY_DOOR = 1270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CHANGE_PORT = 1272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_START_DISCOVERY = 1273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_STOP_DISCOVERY = 1274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SWAP_DOOR_SIDE = 1282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOVE_ITEM_UP = 1285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOVE_ITEM_DOWN = 1286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOW_DATABASE_INFO = 1287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTRICTEDINFO = 1289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_UNITACTIVITY = 1296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DLG_DATABASEUPGRADE = 1300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPLETED = 1301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPLETEDWITHERRORS = 1302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERROR = 1307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_OFFLINESYNC_STARTED = 1309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_OFFLINESYNC_SUCCESS = 1310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_OFFLINESYNC_FAILURE = 1311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_WDMSG_OFFLINESYNC_FAILED = 1312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ALL_PRIVILEGES = 1315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_APPLICATIONS = 1316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ALLOW_PLAYBACK = 1317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ALARM_MANAGEMENT = 1318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_CHANGE_DISPLAY = 1319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_CREATE_NEW_LAYOUTS = 1320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_CHANGE_PATTERN = 1321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_EXPLICITLY_UNLOCK_DOORS = 1322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_RECORD_MANUALLY = 1323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ADD_BOOKMARK = 1324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_VISITOR_MANAGEMENT = 1325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_CONTROLS = 1326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_CHANGE_FOCUS_AND_IRIS_SETTINGS = 1327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_DO_BASIC_OPERATIONS = 1328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_LOCK_PTZ = 1329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_USE_PRESETS = 1330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_EDIT_PRESETS = 1331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_GENERAL_PRIVILEGES = 1332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ACKNOWLEDGE_ALARMS = 1333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_CHANGE_OWN_PASSWORD = 1334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ALLOW_REPORT_GENERATION = 1335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_EMPTYENTITYNAME = 1336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_INVALIDIMAGEFILE = 1337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DB_UPGRADE_ITEM = 1338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TROUBLESHOOTER_ANTIPASSBACK = 1340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_FAILED_EMAIL = 1341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CLOSE = 1343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TRACK = 1345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GO = 1350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_VISUAL_DISPLAYINFO = 1351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_GENERAL_MAXEVENTS = 1353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERSION = 1354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEPERSON = 1356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSECONTROLLER = 1358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEANTIPASSBACK = 1361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEEMAIL = 1364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEPEOPLECOUTING = 1366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSESECTION = 1370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERAL = 1373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM = 1374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARMS = 1375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISUAL = 1376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_CREDDENTIALLICENSE = 1387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_GUESTLICENSE = 1388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_SHOWALARMPROCEDURE = 1406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_COLLAPSE_TILE = 1408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFINITE = 1409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNLIMITED = 1411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIRMWARE_CANNOT_UPGRADE_ENCRYPTED = 1412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_KEEP_INFINITE = 1413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_ALLOW = 1419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_DENY = 1420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_UNDEFINED = 1421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_INHERITEDFROM = 1422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TO_LC = 1425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSESTATUS = 1429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILE = 1432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEVER_EXPIRES = 1436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEINPUT = 1438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEOUTPUT = 1439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SYSTEM_CHANGEPASSWORD = 1440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OLD_PASSWORD = 1441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_NAME = 1446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE = 1447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_EVENTDB_DROPPED_LOG = 1449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_CAMERA = 1450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_EXTERNALSYSTEM = 1451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_CAMERAS = 1452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_EXTERNALSYSTEMS = 1453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_CAMERAS = 1454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_EXTERNALSYSTEMS = 1455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_NAME_LIVEBOOKMARK = 1456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_NAME_PLAYBACKBOOKMARK = 1457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_NAME_MOTIONON = 1458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_NAME_MOTIONOFF = 1459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_EXTERNALSYSTEM = 1461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_OMNICASTVIDEO_NAME = 1462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_OMNICASTVIDEO_DESCRIPTION = 1463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_CAMERAS = 1467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EXPORT_VIDEO = 1470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ATTACHED_ENTITIES = 1473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWCAMERA = 1475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWEXTERNALSYSTEM = 1476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_CAMERAACTIVITY = 1478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXECUTEMACRO = 1489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_EXTERNALSYSTEM_CAMERAS = 1491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROPERTIES = 1492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARAMETERS = 1493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_EXTERNALSYSTEM_ALARMS = 1494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_ALARM_EXTERNALSYSTEM = 1495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SENDSTRINGONSERIAL = 1502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RUNPATTERN = 1503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETEALLLINKEDENTITIES = 1504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FAILEDTOIMPORT = 1505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOOKMARK = 1508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATERN = 1509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SIZEUNITS_BYTE = 1511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SIZEUNITS_BYTES = 1512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SIZEUNITS_KBYTES = 1513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SIZEUNITS_MBYTES = 1514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SIZEUNITS_GBYTES = 1515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SIZEUNITS_TBYTES = 1516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ALLOW_VIDEO = 1518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_EXECUTE_MACROS = 1519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_EXPORT_VIDEO = 1520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLIENTSIDE = 1521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVERSIDE = 1522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILENAME = 1523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROGRESS = 1525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ELAPSEDTIME = 1526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SAVEDIALOG_SELECTDESTINATIONFILE = 1527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALIDDATETIME = 1529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INPUT_TROUBLE_SHORT = 1530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INPUT_TROUBLE_OPEN = 1531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT = 1535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUT = 1536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_CONFIGURED = 1538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_NOTCONFIGURED = 1539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_PLUGINLOADED = 1540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_PLUGINNOTLOADED = 1541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_CONNECTIONRESOLVED = 1542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_CONNECTIONNOTRESOLVED = 1543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_CONNECTED = 1544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_CONNECTIONERROR = 1545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NOVIDEO = 1547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNASSOCIATEDCAMERA = 1548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_EXTERNALSYSTEM_NAME_ALARMTRIGGERED = 1549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALTYPE_WIEGAND = 1551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALTYPE_WIEGAND_X_BITS = 1552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALTYPE_KEYPAD = 1553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCESSPOINTGROUP_ZONE = 1554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_ZONES = 1555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_ZONES = 1556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_ZONEACTIVITY = 1557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMEVENTS = 1558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATECUSTOMEVENT = 1559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDITCUSTOMEVENT = 1560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMEVENT = 1561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALUE = 1562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VARIABLE = 1563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REPORTVIEWERNOTINSTALLED = 1564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_ACIM_FIRMWARE_ARCHIVES = 1566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_OUTPUTBEHAVIOR = 1567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_OUTPUTBEHAVIORS = 1568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_OUTPUTBEHAVIORS = 1569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DISCOVERY_ITEM_REMOVED_FROM_UPGRADE_LIST = 1570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WIZARD_CAPTION_UPGRADE_UNIT_FIRMWARE = 1571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPGRADE_FIRMWARE_SELECTION_PAGE_TITLE = 1572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNITS_SELECTED_FOR_UPGRADE = 1573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_OUTPUTBEHAVIOR = 1574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_ZONE = 1575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_FIRMWARE_UPGRADE_FAILED = 1576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT = 1577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMAL = 1578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE = 1579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MS = 1580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PULSE = 1581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERIODIC = 1582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_ASSOCIATEDEVENTS = 1583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USAGE = 1585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH = 1586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORMULA = 1587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR = 1588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALIDFORMULA = 1589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_VARIABLE_X_NOTFOUND = 1590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OUTPUTBEHAVIOR_OUTPUTTYPE = 1591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MSEC = 1592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELAY = 1593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURATION = 1594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUTYCYCLE = 1595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERIOD = 1596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_FIRMWARE_UPGRADE_STARTED = 1597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_FIRMWARE_UPGRADE_SUCCESS = 1598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_FIRMWARE_UPGRADE_FAILURE = 1599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IN_PROGESS_X = 1600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PENDING = 1601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANCELLED = 1602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNREACHABLE = 1603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_CUSTOMEVENT = 1604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_INFO = 1605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_GLASSBREAK = 1606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_WINDOWOPENED = 1607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_WINDOWCLOSED = 1608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_DEADBOLTLOCKED = 1609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_DEADBOLTUNLOCKED = 1610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_KNOBINPLACE = 1611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_KNOBROTATED = 1612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_LOCKSECURED = 1613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_LOCKRELEASED = 1614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ZONETHRESHOLD = 1616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ZONEONLINEMODEONLY = 1617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGEROUTPUT = 1618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_HEALTH_WARNING = 1619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH_WARNING_NONE = 1620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICASTSDKNOTINSTALLED = 1621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_ANTIPASSBACK_DISABLED_MIXEDMODE = 1622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_ANTIPASSBACK_DISABLED_UNITOFFLINE = 1623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_PEOPLECOUNT_DISABLED_UNITOFFLINE = 1624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BEEPS = 1626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNITBEEP_REX = 1627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNITBEEP_ACCESSGRANTED = 1628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLEENCRYPTION = 1629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISABLEENCRYPTION = 1630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENROLL_UNIT = 1631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCAL_ENTITIES = 1632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_ENTITIES = 1633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ENTITY_WARNING = 1634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAC_ADDRESS = 1637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNITSTATS_NUMCREDENTIAL = 1641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IN_PROGRESS = 1642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOEXPIRATION = 1643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOTSUPERVISED = 1646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_3STATESUPERVISED = 1647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_4STATESUPERVISED = 1648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACTIS = 1649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OTHER = 1650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZATION = 1652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_HEALTH = 1653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLINESYNCHRONIZATIONMODE = 1654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUALONLY = 1655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC = 1656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATICALLY = 1657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONSCHEDULE = 1658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOTCHANGEPASSWORD = 1661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALPHADOORSIDE = 1663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMEGADOORSIDE = 1664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_NACK = 1665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LASTUPDATE = 1666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZENOW = 1667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_MAINTENANCE_ON = 1669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_MAINTENANCE_OFF = 1670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_WARNING_UNITOFFLINE = 1672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COULD_NOT_SAVE_FILE_X = 1677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COULD_NOT_START_TASK_X = 1679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COULD_NOT_SWITCH_DATABASE_X = 1680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNKNOWN_UNIT = 1681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_ENOUGH_SPACE_FOR_FILE_TRANSFER = 1682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_DATABASE_CONFIGURATION_ERROR = 1683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAIN_MEMORY = 1684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECONDARY_MEMORY = 1685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_DETECTED = 1686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FREE = 1687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FREE_PLURAL = 1688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REBOOT = 1691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE = 1692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_QUIT = 1693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CLICKTOEDIT = 1695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUALENTRY = 1698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_MENU_SYSTEM = 1699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_MENU_SYNERGISSECURITY = 1700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_MENU_ACCESSCONTROL = 1701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIORITY_NONE = 1710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIORITY_LOW = 1711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIORITY_MEDIUM = 1712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIORITY_HIGH = 1713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIORITY_URGENT = 1714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTORETODEFAULT = 1715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NA = 1716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALSPREFIX = 1720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HELP = 1721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLICATION = 1722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMALSTATE = 1723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVESTATE = 1724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESTATE = 1725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OPERATOR_AND = 1726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OPERATOR_OR = 1727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLUGIN_TASKS = 1729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATIONDATE = 1731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_RECURSIVESECTION = 1732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_ERROR_RECURSIVEDETECTED = 1733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_TRANSLATED_HOST_ADDRESS = 1734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR = 1735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAUNCHQUERY = 1736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YES = 1739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO = 1740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_USE_TRANSLATED_HOST_IP = 1741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROCEDURE = 1744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNAVAILABLEINFO = 1745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPTED_USERS = 1748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STARTED = 1749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOPPED = 1750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACKNOWLEDGE = 1751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NACKNOWLEDGE = 1752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNAVAILABLEINFO_CARDHOLDER = 1754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNAVAILABLEINFO_DOORAREA = 1755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEBBROWSER = 1756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOTRETRIEVECREDENTIALINFO = 1759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OMNICAST_VI_ERROR_OMNICASTSDKNOTINSTALLED = 1761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_CONTACTING = 1762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_INITIALIZING = 1763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_CREATED = 1764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLEMENT_FAILED = 1765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_MAXIMUM_CREDENTIAL_LIMIT_REACHED_X = 1766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_APPROXIMATION_ONLY = 1767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_NOT_USING_EXPANSION_CARD = 1768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_UNIT_PROGRESS_PAGE_TITLE = 1769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_UNSPECIFIED = 1770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_ALREADYCONNECTED = 1771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_INVALIDPARAMS = 1772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_UNABLETOCONNECT = 1773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_UNITIDENTIFIERTIMEOUT = 1774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_INITFAILED = 1775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_SECURITYLAYERINITFAILED = 1776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_UNSPECIFIED = 1777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_INVALIDPARAMS = 1778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_MACADDRESSUNRETRIEVABLE = 1779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FIRMWAREVERSIONUNRETRIEVABLE = 1780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FIRMWAREVERSIONUNSUPPORTED = 1781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_CFGFILEUNRETRIEVABLE = 1782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_COMMHOSTSUNRETRIEVABLE = 1783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_COMMCFGUNRETRIEVABLE = 1784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FAILEDSAVECOMMCFG = 1785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FAILEDSAVECOMMHOSTS = 1786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FAILEDCONTACTGATEWAY = 1787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_COULDNOTCONNECT = 1788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FAILEDQUERYINFO = 1789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FAILEDRETRIEVECONFIG = 1790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_ERROR_DISCOVERYPORTMISMATCH = 1791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_BUILDCARDS_FAILED_X = 1792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DOORONLINEMODEONLY = 1793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNITNAME = 1794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_OFFLINE_SYNC_MULTI_TIMEZONE_ERROR = 1795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OFFLINESYNC_NOTENOUGHSPACE_XY = 1798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_CONFIGURATION_FILES_NOT_FOUND = 1799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_START_STOP_TASK_ERROR = 1801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_COULD_NOT_SET_TIME = 1802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDERTYPE = 1805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNASSIGNEDCREDENTIAL = 1807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP = 1808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SHOW = 1809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_ITEMS_SELECTED = 1811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOPTALKING = 1814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STARTLISTENING = 1815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOPLISTENING = 1816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TALK = 1817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTEN = 1818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLEMENTERROR_UNITALREADYENROLLED = 1820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATE = 1821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMFIELD = 1822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMFIELDS = 1823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULTVALUE = 1826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANDATORY = 1827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDITCUSTOMFIELD = 1828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEXT = 1829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMERIC = 1830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_QUERY_OPTIONS = 1832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN = 1833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHO = 1834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INACTIVE_CREDENTIAL = 1836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INACTIVE_CARDHOLDER = 1837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORPHAN_CREDENTIAL = 1838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_WITH_NO_ACCESS = 1839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_ACTIVE_CREDENTIAL = 1840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GRANTED_CARDHOLDERS = 1841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_DIAGNOSTIC = 1842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOOLEAN = 1843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALUES = 1844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATAENTRY = 1845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_EXCEPTION_NOINPUT = 1849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_EXCEPTION_DOUBLEINPUT = 1850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_EXCEPTION_NOTENOUGHVALUES = 1851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_EXCEPTION_PARSING = 1852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_LINEPARSED = 1853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_TYPE_BOOL = 1854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_TYPE_DECIMAL = 1855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_TYPE_LONG = 1856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_TYPE_ULONG = 1857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_TYPE_CHAR = 1858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_TYPE_STRING = 1859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CSVIMPORTER_TYPE_UNDEFINED = 1860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITYTYPE = 1861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTCOLUMNS = 1864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTENSIONTYPE_ACIM = 1865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTENSIONTYPE_VERTX = 1866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTENSIONTYPE_ION = 1867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTENSIONTYPE_SIMULATION = 1868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILTER_DOORSAREAS = 1869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STATES = 1870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUESTS = 1871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOURCES = 1872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALUE_MUST_START_WITH = 1877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALUE_MUST_CONTAIN_WORD = 1878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECIMAL = 1879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMFIELDSFILTER = 1880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_GUEST = 1882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACCESSPOINTSIDENAME = 1883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMNAME = 1884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDEREMAIL = 1885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDERFIRSTNAME = 1886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDERLASTNAME = 1887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDERPICTURE = 1888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDERNAME = 1889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIALNAME = 1890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_DEVICENAME = 1891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EVENTID = 1892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EVENTNAME = 1893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EVENTTIME = 1894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNITNAME = 1895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_APG_AREA = 1896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_LASTACCESS = 1897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_APG_DOOR = 1898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_APG_ZONE = 1899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNITTYPE = 1901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_READERUNIQUE = 1902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEELEVATOR = 1903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOOR = 1904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLUMN = 1905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTTOOL = 1906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLUMNDELIMITER = 1907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECIMALDELIMITER = 1908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAMPLEVALUE = 1910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BINDING = 1911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALFORMAT = 1913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_ELEVATOR = 1914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ELEVATOR_FLOORS = 1915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SETCUSTOMFIELDSVALUES = 1917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_STATUS = 1919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMACKBY = 1921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMACKTIME = 1923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CABIN_READER = 1924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMEXTERNALINSTANCEID = 1925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMINSTANCEID = 1926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMPRIORITY = 1927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMSOURCE = 1928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMTRIGGEREVENT = 1929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARMTRIGGERTIME = 1930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CSVIMPORT = 1932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDFORMAT26WIEGAND = 1934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDFORMATH10304 = 1935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDFORMATH10306 = 1936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDFORMATH10302 = 1937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDERFIRSTNAME = 1938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDERLASTNAME = 1939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDERDESCRIPTION = 1940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDERGROUP = 1941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDERPICTURE = 1942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CREDENTIALNAME = 1943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_WIEGANDFACILITY = 1944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_WIEGANDCODE = 1945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_WIEGANDFORMAT = 1946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDEREMAIL = 1947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDERSTATUS = 1948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_SUCCESS = 1950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_NOINPUT = 1951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_COLUMNNOTUNIQUE = 1952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_EXCEPTION = 1953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_INVALIDDATATYPE = 1954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_NOTENOUGHCOLUMNS = 1955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_NOTENOUGHCARDHOLDERINFO = 1956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_PICTURENOTFOUND = 1957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THOUSANDSEPARATOR = 1960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STARTATLINE = 1961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_START = 1962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVIOUS = 1964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEXT = 1965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FINISH = 1966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANCEL = 1967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START = 1968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_IMPORTTOOL = 1969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCATION = 1971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ELEVATOR_SCHEDULE_EXCEPTION = 1977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_FLOORS = 1978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_ELEVATORACTIVITY = 1983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_APG_ELEVATOR = 1984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ELEVATOR_ONLINE_MODE_ONLY_UNIT_CAPABILITIES = 1985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ELEVATOR_FREEACCESS_ON_SCHEDULE = 1987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ELEVATOR_SECURED_ON_SCHEDULE = 1988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LINE = 1989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_DOOR_ELEVATOR = 1990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLETOPARSEFILE = 1991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_ACCESSPOINTS = 1992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_ACCESSPOINTS = 1993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_INVALIDCARDDATA = 1994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_DOOR_SIDE_FLOOR = 1995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GRANTED_ACCESSPOINTS = 1996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSV = 1997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_CSVFILES = 1998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_ALLFILES = 1999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_REX_OFF = 2000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_ELEVATOR_OFFLINE_SYNC_ERROR_TOO_MANY_ELEVATORS = 2001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_ANTIPASSBACK_DISABLED_PERIMETERONELEVATOR = 2002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_APPLICATIONNOTRUNNING = 2003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_APG_ALL_ACCESS_POINT_GROUPS = 2004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALL_ACCESS_POINT_NAMES = 2005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_DATE = 2008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_TOTALTIME = 2009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_WEEKDAY = 2010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WEEKDAY_MONDAY = 2011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WEEKDAY_TUESDAY = 2012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WEEKDAY_WEDNESDAY = 2013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WEEKDAY_THURSDAY = 2014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WEEKDAY_FRIDAY = 2015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WEEKDAY_SATURDAY = 2016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WEEKDAY_SUNDAY = 2017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACFAIL = 2018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_BATFAIL = 2019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_LABEL_TO_FLOOR_X = 2020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_MONITOR_ACFAIL = 2021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_MONITOR_BATFAIL = 2022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBO_BROWSEFORMORE = 2025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYEVENT_ELEVATOR_ACCESS = 2026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPYTOCLIPBOARD = 2027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_APPLICATION_SEMAPHORE = 2029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ELEVATOR_WARNING_UNITOFFLINE = 2030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTRICTEDINFOCREDENTIAL = 2031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_TASKSCHEDULER = 2032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_TASKSCHEDULERS = 2033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_TASKSCHEDULERS = 2034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_MACRO = 2035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_MACROS = 2036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_MACROS = 2037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_MAP = 2038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_MAPS = 2039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_MAPS = 2040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_UNITSWAP = 2041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTENDED_GRANT_TIME = 2042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_SOURCE = 2045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIALFORMAT = 2046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIALCODE = 2047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EVENTDESCRIPTION = 2049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_VIEW_ADVANCED_CREDENTIAL_INFO = 2052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CREDENTIAL_WOULD_BE_EXPIRED = 2054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CREDENTIAL_COULD_BE_EXPIRED = 2055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUDITTRAILS = 2056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMFIELDSVALUES = 2058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ID = 2060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MODIFICATIONTIME = 2061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_USER = 2062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_USEREMAIL = 2063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_AUDITDESCRIPTION = 2066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ENTITY = 2067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ENTITYTYPE = 2068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_TIMEATTENDANCE = 2069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_AUDITTRAILS = 2070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITIES = 2071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODIFIEDBY = 2072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_EXTENDED_GRANT_TIME = 2073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_MACRO = 2074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_MACRO_SOURCE_CODE_FILE = 2075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_REPORTS_AUDITTRAILS = 2077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_REPORTS_TIMEATTENDANCE = 2078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_BUZZER = 2079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SOUND_BUZZER = 2080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SILENCE_BUZZER = 2081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_MACRO = 2082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_MAP = 2083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_TASK = 2084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MACRO_LANGUAGE = 2085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MACRO_SECURITY_LEVEL_AT_RUNTIME = 2086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_MACRO = 2087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MACRO_PROPERTIES = 2088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_NOBINDING = 2089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLON_FORMAT = 2090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MACRO_DEFINITION = 2096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_MACRO_VB = 2098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_CANNOT_OPEN_FILE_X = 2099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DEVICESDONTMATCH = 2100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXTENSIONDONTMATCH = 2101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_MACROLICENSE = 2102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_IDALREADYEXIST = 2103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_CONTEXT_ITEM_RUN_MACROS = 2104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_FROM_FILE = 2107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECK_SYNTAX = 2108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LINE_X_COLUMN_Y = 2109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGICALID = 2110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPILER_ERRORS = 2111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SDKCOUNT = 2113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SWAP_NEWUNITDISCONNECTED = 2114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SWAP_OLDUNITCONNECTED = 2115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_MAP = 2116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_SYNTAX_IS_VALID = 2117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_NO_MACRO = 2118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_MULTIPLE_MACROS = 2119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_CANNOT_LOAD_MACRO = 2120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_CANNOT_LOAD_SDK = 2121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_CANNOT_LOCATE_MACRO = 2122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_CANNOT_LOCATE_BASE_CLASS = 2123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_MACRO_HAS_ERRORS = 2124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_ERROR_UNKNOWN = 2125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_COMPILATION_ERROR_X = 2126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATETIME = 2127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MACRO_EXECUTION_CONTEXT = 2128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_DEFAULT_EXECUTION_CONTEXT = 2129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_HAS_ERRORS_REVERTING_TO_ORIGINAL_STATE = 2130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_MACRO_HAS_ERRORS_CHECK_SYNTAX_BEFORE_SAVING = 2131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RUN_MACRO = 2132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ABORT_MACRO = 2133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PHYSICALNAME = 2134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_TASKSCHEDULER = 2135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MACRO_SECURITY_LEVEL_FULLTRUST = 2136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MACRO_SECURITY_LEVEL_LOCALINTRANET = 2137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MACRO_SECURITY_LEVEL_INTERNET = 2138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_MAP_FILE = 2139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_MAPS = 2140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ADDITIONNAL_SETTINGS = 2142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_NORMALLYOPENED = 2145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_NORMALLYCLOSED = 2146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_NOTSUPERVISED = 2147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_4STATESUPERVISED = 2148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_FIRMWARE_VERSION = 2149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_EEPROM_VERSION = 2150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_DOORBEHAVIOR = 2151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_CARDIN_CARDOUT = 2152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_CARDIN_FREEOUT = 2153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLUGIN_HOTACTIONS_NAME = 2155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_MACRO_NAME_STARTED = 2156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_MACRO_NAME_COMPLETED = 2157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_MACRO_NAME_ABORTED = 2158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEWINFREETILE = 2160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORCEVIEWINTILE = 2161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CONFIGUREACTION = 2162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_EXECUTING_MACROS = 2163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MACRO_START_TIME = 2164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTIGATOR = 2165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONCE = 2167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVERYMINUTE = 2168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOURLY = 2169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAILY = 2170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEEKLY = 2171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECURRENCE = 2174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVERY = 2178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_CONTEXT = 2180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDEN_CONTEXT = 2181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CREATE_EDIT_MACRO_CONTEXT = 2182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CLEAR_MACRO_CONTEXT = 2183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_BADGE = 2184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_BADGES = 2185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_BADGES = 2186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_BADGE = 2187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_REPORTTEMPLATES = 2188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_REPORTTEMPLATE = 2189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_REPORTTEMPLATES = 2190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_READER_SETTINGS = 2191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USECARDKEYPAD = 2192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDKEYPAD_TIMEOUT = 2193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_CREDENTIALHASEXPIRED = 2194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_CREDENTIALISEXPIRING = 2195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINT = 2196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_SELECTPICTUREFROMDISK = 2197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_PICTUREBINDING = 2198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_ENTERLABELTEXT = 2199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_BADGENAME = 2200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_MINTIME = 2201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIREIN = 2202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_DEBOUNCE = 2203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOOLS = 2206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILL = 2208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STROKE = 2209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STROKETHICKNESS = 2210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PORTRAIT = 2211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LANDSCAPE = 2212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZORDER = 2213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BORDER = 2214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BRINGTOFRONT = 2215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENDTOBACK = 2216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_COMPLEMENTARYCREDENTIALNAME = 2217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CARDKEYPADERROR = 2218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CARDKEYPADMODE = 2219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTBADGEFORMAT = 2220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE = 2221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BARCODE = 2222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTERTEXTHERE = 2223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_INTERFACE_CODE_ERROR_INVALID_INTERFACE = 2224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK = 2225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_VERTX_DOWNLOAD_INTERFACE_CODE = 2226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_VERTX_DOWNLOAD_INTERFACE_CODE = 2227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_INTERFACE_CODE_ERROR_EEPROM_X = 2228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_INTERFACE_CODE_ERROR_PROGRAM_X = 2229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALEXPIRING = 2230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FONT = 2231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_FLOOR_ACCESS = 2234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_INCIDENT = 2235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_NOTES = 2236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREATEDBY = 2237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREATIONTIME = 2238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MODIFIEDBY = 2239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REFERENCES = 2240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_FILE = 2241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_FILES = 2242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_FILES = 2243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATENEWINCIDENT = 2245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDITINCIDENT = 2246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE = 2247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_AREA = 2248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_ZONE = 2249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_ELEVATOR = 2250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NEW_FILE = 2251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_INCIDENTS = 2252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_CREATEINCIDENTS = 2253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_MODIFYINCIDENTS = 2254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_REPORTS_INCIDENTS = 2255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SAVE = 2258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REPORT_SENDEMAIL = 2261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REPORT_PRINT = 2262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTENANCEMODE = 2263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOREQUESTEXIT = 2266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_TIMEATTENDANCE = 2267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTERDESCRIPTIONHERE = 2268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENDTO = 2269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOLD = 2270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ITALIC = 2271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNDERLINE = 2272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATEINCIDENTONACK = 2273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORMAT = 2274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_FORMAT_PDF = 2275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_FORMAT_EXCEL = 2276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_FORMAT_HTML = 2277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALIGNMENT = 2281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LEFT = 2282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RIGHT = 2283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CENTER = 2284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOOR_DEVICENOTATTACHED = 2286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_DEVICENOTATTACHED = 2287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUSH_BUTTON_RELAY = 2288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOOR_TRACKING = 2289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DUPLICATE_KEYPAD = 2290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_KEYPAD_CODE_OUT_OF_RANGE = 2292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_ADDMEMBER = 2293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_REMOVEMEMBER = 2294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_MODIFYMEMBER = 2295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ABBREVIATION_SECOND = 2296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ABBREVIATION_MINUTE = 2297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNITIPADDRESS = 2301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNITTIMEZONE = 2302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNITFIRMWAREVERSION = 2303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DELETED = 2304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ERROR_RAISED_BY_MAP_CONTAINER = 2305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MEMBER = 2307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_TYPE = 2308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ICON = 2309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_UNITLIST = 2310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPACITY = 2316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTCOLOR = 2318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEXTCOLOR = 2319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT = 2321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OK = 2322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_IMAGESIZETOOBIG = 2323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLETOGENERATEREPORT = 2324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REPORTGENERATOR_UNABLETOPROCESSREQUEST = 2325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REASON = 2326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REPORTGENERATOR_INCOMPLETEFILTER = 2327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAPVIEWER_MAPNOTFOUND = 2328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_IMPORTTOOL_UNABLETOACCESSSFILE = 2329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLY = 2330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_CREATEINCIDENT = 2331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME = 2332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINUTE_OF_THE_HOUR = 2333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MACRO_COMMENT_EXECUTE = 2334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MACRO_COMMENT_CLEANUP = 2335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MACRO_COMMENT_CLEANUP_INNER = 2336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_INCIDENTTIME = 2337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_NO_PRIVILEGE_TO_TRIGGER_ACTION = 2338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISION_MODE = 2339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINTBADGE = 2340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_NOTIFICATION = 2342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_CONFIRM_HOT_ACTION_DELETION = 2345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDER_STATUS = 2346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_STATUS = 2347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_TOO_MANY_CONFIRMING_PINS = 2349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_HOT_ACTION_OBSOLETE = 2350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_BACKUP = 2351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_NOTCONNECTED = 2354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISTINCTCREDENTIAL = 2355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAMEPERSONCREDENTIAL = 2356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICTED_CREDENTIAL2 = 2357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CREDENTIAL2_COULD_BE_EXPIRED = 2358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTER_CREDENTIAL2_WOULD_BE_EXPIRED = 2359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL2_STATE = 2360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ELEVATOR_GRANT_TIME = 2362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_RULES = 2365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGERING_EVENT = 2366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_DESCRIPTION = 2367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGERED_BY = 2368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_LOG_ON_THROUGH_SDK = 2369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_CFDEFAULTVALUE = 2370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTERNAL_SYSTEM_TYPE = 2371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_WRITEEEPROM_ERROR = 2372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_DISCOVERING_INTERFACES = 2375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_PRE_CONNECTION_TEST = 2378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_RESOLVING_IP_ADDRESS = 2379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_REFRESHING_EEPROM_VALUES = 2380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_CONTACTING_THE_UNIT = 2381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_CHECKING_FOR_MEMORY_EXPANSION = 2382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_RETRIEVING_CONFIGURATION_FILE = 2383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_QUERY_INTERFACE_STATUS = 2387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_NETWORK_CARD_NOT_BINDED = 2388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_WRONG_INTERFACE_VERSION = 2389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_INVALID_INTERFACE_EEPROM_VERSION = 2390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_INVALID_INTERFACE_PROGRAM_VERSION = 2391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_DHCP_UNIT_NOT_DISCOVERABLE = 2392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DHCP_UNIT_NOT_DISCOVERABLE = 2393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRESSF1FORVERTXTROUBLESHOOT = 2394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USB_READER = 2396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USB_READER_CONNECTED = 2397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USB_READER_DISCONNECTED = 2398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDFORMATCORPORATE1000 = 2400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENROLLMENT_DEVICE = 2402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_ACTIVEDIRECTORY_NAME = 2403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_ACTIVEDIRECTORY_DESCRIPTION = 2404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_ACTIVEDIRECTORY_CONFLICT_ASK_FOR_BACKUP = 2408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BROWSE_FOR_FILE = 2409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_CONFLICT_MANIFEST = 2411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROCESSING_STEP_X_OF_Y = 2413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTORE_DB_AFTER_CONFLICT_MANIFEST_ERROR = 2414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFLICT_MANIFEST = 2416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACTIVEDIRECTORY_CONFLICT_RESOLUTION = 2417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_OMNICASTVIDEOSDK_NAME = 2420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE = 2424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRAMERATE = 2425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCODERNAME = 2427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_TOO_MANY_AUTHENTICATION_AGENTS = 2429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_NO_AUTHENTICATION_AGENT = 2430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_AUTHENTICATION_AGENT_RESPONSE_TIMEOUT = 2431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXTERNALSYSTEM_ACTIVE_DIRECTORY_LOGON = 2432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_VIDEO = 2433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXTERNALSYSTEM_ACTIVE_DIRECTORY_LOGON_RESPONSE = 2434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY = 2436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_AUTHENTICATION_AGENT_IMPORT_FAILURE = 2438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BADGEDESIGNER_FRONT = 2439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BADGEDESIGNER_REAR = 2440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRINTDIALOGEXCEPTION = 2441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STRETCH = 2442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STRETCH_NONE = 2443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STRETCH_FILL = 2444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STRETCH_UNIFORM = 2445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_BADGES = 2447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_OVERRIDE_ON = 2448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_OVERRIDE_OFF = 2449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_LOCKDOWN_ON = 2450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_LOCKDOWN_OFF = 2451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CREATEDATABASE = 2452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_MULTIPLEUNIT_WARNING = 2453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_UNSUPPORTED_WARNING = 2454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_MAINTENANCE_MODE_WARNING = 2455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_FREEACCESS_WARNING = 2456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_ANTIPASSBACK_WARNING = 2457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_FLOOR_WARNING = 2458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_CONFLICT_RESOLUTION = 2459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_APB_MULTIPLEREADERASSIGNMENT = 2460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_APB_READERMULTIPLE_IN = 2461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_APB_READERMULTIPLE_OUT = 2462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_APB_MAX_AREAID_REACHED = 2464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_PROPERTIES = 2465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APB_PROPERTIES = 2467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS = 2468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_PRIORITIZE = 2469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_OVERRIDE = 2470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_LOCKDOWN = 2471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ON = 2472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFF = 2473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SELECT = 2474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTED_ENTITIES = 2475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_EMAIL = 2476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DLG_SAVEMANIFEST = 2477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_NETWORK_CONNECTION_LOST = 2478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SORT_BYNAME = 2479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SORT_BYTYPE = 2480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SHOW_OFFLINE_ENTITIES = 2481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BROWSER_TEXT_HINT = 2482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BROWSER_CUSTOM_FILTER = 2483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BROWSER_CLEAR_FILTER = 2484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_CONNECTIONSTATUS = 2485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS = 2486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_IMPORTEDGROUPS = 2487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COLHEADER_ACTIVE_DIRECTORY_IMPORTGROUP = 2489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COLHEADER_ACTIVE_DIRECTORY_SYNCUSERS = 2490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COLHEADER_ACTIVE_DIRECTORY_SYNCCARDHOLDERS = 2491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_NOTRUNNING = 2492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_ERREUR = 2493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_IDLE = 2494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_INITIALIZING = 2495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_DOWNLOADING = 2498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_UPDATINGCARDHOLDERS = 2499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_UPDATINGUSERS = 2500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_LINE2 = 2501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_CONNECTIONSTATUS_CONNECTED = 2502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_CONNECTIONSTATUS_NOTCONNECTED = 2503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_CANNOTAUTHENTICATE = 2504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_CANNOTFINDENTRY = 2505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_INVALIDREQUEST = 2506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_SERVERCONNECTION = 2507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_SERVERINVALIDCREDENTIALS = 2508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_UNKNOWN = 2509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE__ = 2510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HISTORY = 2511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_FEDERATION_NAME = 2514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_FEDERATION_DESCRIPTION = 2515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_MINOPENTIME = 2516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_SUCCEESS = 2517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_CONNECTING = 2518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_CONNECTIONESTABLISHED = 2519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_CONNECTIONLOST = 2520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_FAILED = 2521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_INSUFFICIENTPRIVILEGES = 2522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_INVALIDCREDENTIAL = 2523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_INVALIDVERSION = 2524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_LICENSEERROR = 2525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEDERATION_CONNECT_STATUS_TIMEOUT = 2526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_SERVER = 2527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CAMERANAME = 2528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EXTERNALSYSTEMAME = 2529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_ARITHMETIC_EXCEPTION_X = 2530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANTIPASSBACK_EXEMPTION = 2531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_EXTERNALSYSTEM_NAME_SYNCHRONIZATION_STARTED = 2532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_EXTERNALSYSTEM_NAME_SYNCHRONIZATION_COMPLETED = 2533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_EXTERNALSYSTEM_NAME_SYNCHRONIZATION_ERROR = 2534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_MOTION = 2535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_AUTOSTARTRECORDING = 2536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_AUTOSTOPRECORDING = 2537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_USERSTARTRECORDING = 2538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_USERSTOPRECORDING = 2539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_ARCHIVEFILEDELETED = 2540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFLICT_RESOLUTION_DESCRIPTION = 2541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXTERNALSYSTEM_TRIGGER_SYNCHRONISATION = 2542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_DETAILEDFOREIGNKEYDELETE = 2543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_MOTIONSTARTRECORDING = 2544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_MOTIONSTOPRECORDING = 2545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_EXTERNALSTARTRECORDING = 2546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_EXTERNALSTOPRECORDING = 2547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_ALARMSTARTRECORDING = 2548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_ALARMSTOPRECORDING = 2549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_CASHREGISTER = 2550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_CASHREGISTERS = 2551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_VIDEO_MOTION = 2552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_VIDEO_ARCHIVES = 2554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_VIDEO_BOOKMARKS = 2555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_POS = 2556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_MOTION_THRESHOLD = 2564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_MOTION_FRAMEHIT = 2565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_MOTION_TIMEBETWEEN = 2566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRAMES = 2567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASKSAVELAYOUTS = 2568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEVERASKAGAIN = 2569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_VIDEOSTARTTIME = 2570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_VIDEOENDTIME = 2571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_SNAPSHOT_BITMAP = 2572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_SNAPSHOT_JPEG = 2573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS_TIMEZONE_CHOICE = 2575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS_TIMEZONE_UNITS = 2576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS_TIMEZONE_SPECIFIC = 2577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS_TIMEZONE_ABBREVIATION = 2578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_SNAPSHOT_NAME = 2580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_SNAPSHOT_WITH_DATA = 2581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_SNAPSHOT_FILE_FORMAT = 2583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_RESULTS_COUNT = 2585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEVER = 2588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS = 2589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_CONTROLS = 2591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_FULL_PATH_ENTITY = 2592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SNAPSHOTS = 2594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_NOTASSIGNED = 2595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_UNIT_ALREADY_EXISTS = 2596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT_ALREADY_ASSOCIATED = 2601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVELAYOUTSONEXIT = 2604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONAPPLICATIONEXIT = 2605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASKUSER = 2606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTERNALSYSTEM_SPECIFIC_INFO = 2607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ = 2608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STREAM = 2609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_CATEGORY_VIDEO = 2610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_CATEGORY_ACCESSCONTROL = 2611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_CATEGORY_AUTHENTICATION = 2612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_INTERLOCK_AND_HARD_APB_MUTUALLY_EXCLUSIVE = 2615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_INTERLOCK_OVERRIDING_HARD_APB = 2616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONTROLLER_RUNNING = 2618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONTROLERDONTMATCH = 2619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_SNAPSHOT_PNG = 2625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_SNAPSHOT_GIF = 2626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_OMNICAST_ROOTAREA_DESC = 2628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXTENSION_SUBTYPES_DONT_MATCH = 2629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_APB_MUST_BE_CONTROLLED_BY_SINGLE_UNIT = 2630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_APB_READERMULTIPLE_CAPTIVE = 2631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONTROLLER_WDMSG_OFFLINESYNC_COMPLETED_WITH_FAILURE = 2632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_SCHEDULE = 2633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_MODE = 2634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_DATE = 2635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_END_DATE = 2636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_CATEGORY_POS = 2639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_APPLICATION_EXTERNALSYSTEM = 2640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVEDIRECTORY_MEMBERS = 2642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ACTIVEDIRECTORY_GROUPS = 2643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIND_ACTIVEDIRECTORY_GROUPS = 2644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTEDGROUPS = 2645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_DESTINATION_FOLDER = 2646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_FORMAT = 2647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ENCRYPT = 2648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIMERANGE_SETTINGS = 2649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_SETTINGS = 2650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_IN_PROGRESS = 2651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_COMPLETED = 2652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CANCEL_SELECTIONS = 2653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CANCEL_REMAINING = 2654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_STATUS_SUCCESSFUL = 2655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_STATUS_QUEUED = 2658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_STATUS_CANCELLING = 2659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPONENT = 2660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_UNABLETOCREATECOMPONENT = 2661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_UNASSOCIATEDCAMERA = 2662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_CANNOTRETRIEVEEXTERNALSYSTEM = 2663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_CANNOTRETRIEVEEXTERNALSYSTEM_EX = 2664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_CANNOTRETRIEVE_ES_INTERFACE = 2665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_MOTION_BLOCKS = 2666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_IOCONFIG = 2669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IOCONFIGREPORT_COLUMNNAME_CONTRLLING = 2670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_LOG_ON_THROUGH_WEB_CLIENTS = 2671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_WEB_CLIENT_COUNT = 2672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_BIND_PICTURE_TO_CUSTOM_FIELD = 2675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_WRONG_BADGE_FORMAT = 2676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_CLOSE = 2683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_STATUS_CONVERTING = 2684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_ASF_USE_PROFILE = 2685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_ASF_DELETE_INT_G64 = 2686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_ASF_DISPLAY_DATE_TIME = 2687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_AUDIO = 2688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_DATELINE_DISPLAY = 2689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAMOA_DISPLAY = 2693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_HAWAIIAN_DISPLAY = 2697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ALASKAN_DISPLAY = 2701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFIC_DISPLAY = 2705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOUNTAIN_DISPLAY = 2709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_USMOUNTAIN_DISPLAY = 2717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRAL_DISPLAY = 2721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CANADACENTRAL_DISPLAY = 2725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALAMERICA_DISPLAY = 2733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EASTERN_DISPLAY = 2737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_USEASTERN_DISPLAY = 2741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAPACIFIC_DISPLAY = 2745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ATLANTIC_DISPLAY = 2749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAWESTERN_DISPLAY = 2753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFICSA_DISPLAY = 2757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEWFOUNDLAND_DISPLAY = 2761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ESOUTHAMERICA_DISPLAY = 2765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAEASTERN_DISPLAY = 2769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GREENLAND_DISPLAY = 2773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MIDATLANTIC_DISPLAY = 2777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AZORES_DISPLAY = 2781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CAPEVERDE_DISPLAY = 2785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GMT_DISPLAY = 2789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GREENWICH_DISPLAY = 2793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALEUROPE_DISPLAY = 2797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALEUROPEAN_DISPLAY = 2801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ROMANCE_DISPLAY = 2805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WEUROPE_DISPLAY = 2809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WCENTRALAFRICA_DISPLAY = 2813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EEUROPE_DISPLAY = 2817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EGYPT_DISPLAY = 2821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_FLE_DISPLAY = 2825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GTB_DISPLAY = 2829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ISRAEL_DISPLAY = 2833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SOUTHAFRICA_DISPLAY = 2837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_RUSSIAN_DISPLAY = 2841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARAB_DISPLAY = 2845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EAFRICA_DISPLAY = 2849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARABIC_DISPLAY = 2853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_IRAN_DISPLAY = 2857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARABIAN_DISPLAY = 2861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CAUCASUS_DISPLAY = 2865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AFGHANISTAN_DISPLAY = 2869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EKATERINBURG_DISPLAY = 2873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WESTASIA_DISPLAY = 2877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_INDIA_DISPLAY = 2881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEPAL_DISPLAY = 2885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALASIA_DISPLAY = 2889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SRILANKA_DISPLAY = 2893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NCENTRALASIA_DISPLAY = 2897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MYANMAR_DISPLAY = 2901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SEASIA_DISPLAY = 2905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NORTHASIA_DISPLAY = 2909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CHINA_DISPLAY = 2913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SINGAPORE_DISPLAY = 2917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TAIPEI_DISPLAY = 2921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WAUSTRALIA_DISPLAY = 2925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NORTHASIAEAST_DISPLAY = 2929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_KOREA_DISPLAY = 2933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TOKYO_DISPLAY = 2937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_YAKUTSK_DISPLAY = 2941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AUSCENTRAL_DISPLAY = 2945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENAUSTRALIA_DISPLAY = 2949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AUSEASTERN_DISPLAY = 2953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EAUSTRALIA_DISPLAY = 2957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TASMANIA_DISPLAY = 2961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_VLADIVOSTOK_DISPLAY = 2965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WESTPACIFIC_DISPLAY = 2969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALPACIFIC_DISPLAY = 2973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_FIJI_DISPLAY = 2977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEWZEALAND_DISPLAY = 2981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TONGA_DISPLAY = 2985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_UNKNOWN_ABBREVIATION = 2993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_DATELINE_STANDARDABBR = 2994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_DATELINE_DAYLIGHTABBR = 2995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAMOA_STANDARDABBR = 2996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAMOA_DAYLIGHTABBR = 2997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_HAWAIIAN_STANDARDABBR = 2998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_HAWAIIAN_DAYLIGHTABBR = 2999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ALASKAN_STANDARDABBR = 3000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ALASKAN_DAYLIGHTABBR = 3001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFIC_STANDARDABBR = 3002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFIC_DAYLIGHTABBR = 3003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOUNTAIN_STANDARDABBR = 3004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOUNTAIN_DAYLIGHTABBR = 3005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_USMOUNTAIN_STANDARDABBR = 3008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_USMOUNTAIN_DAYLIGHTABBR = 3009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRAL_STANDARDABBR = 3010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRAL_DAYLIGHTABBR = 3011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CANADACENTRAL_STANDARDABBR = 3012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CANADACENTRAL_DAYLIGHTABBR = 3013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALAMERICA_STANDARDABBR = 3016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALAMERICA_DAYLIGHTABBR = 3017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EASTERN_STANDARDABBR = 3018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EASTERN_DAYLIGHTABBR = 3019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_USEASTERN_STANDARDABBR = 3020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_USEASTERN_DAYLIGHTABBR = 3021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAPACIFIC_STANDARDABBR = 3022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAPACIFIC_DAYLIGHTABBR = 3023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ATLANTIC_STANDARDABBR = 3024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ATLANTIC_DAYLIGHTABBR = 3025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAWESTERN_STANDARDABBR = 3026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAWESTERN_DAYLIGHTABBR = 3027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFICSA_STANDARDABBR = 3028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFICSA_DAYLIGHTABBR = 3029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEWFOUNDLAND_STANDARDABBR = 3030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEWFOUNDLAND_DAYLIGHTABBR = 3031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ESOUTHAMERICA_STANDARDABBR = 3032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ESOUTHAMERICA_DAYLIGHTABBR = 3033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAEASTERN_STANDARDABBR = 3034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SAEASTERN_DAYLIGHTABBR = 3035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GREENLAND_STANDARDABBR = 3036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GREENLAND_DAYLIGHTABBR = 3037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MIDATLANTIC_STANDARDABBR = 3038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MIDATLANTIC_DAYLIGHTABBR = 3039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AZORES_STANDARDABBR = 3040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AZORES_DAYLIGHTABBR = 3041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CAPEVERDE_STANDARDABBR = 3042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CAPEVERDE_DAYLIGHTABBR = 3043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GMT_STANDARDABBR = 3044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GMT_DAYLIGHTABBR = 3045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GREENWICH_STANDARDABBR = 3046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GREENWICH_DAYLIGHTABBR = 3047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALEUROPE_STANDARDABBR = 3048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALEUROPE_DAYLIGHTABBR = 3049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALEUROPEAN_STANDARDABBR = 3050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALEUROPEAN_DAYLIGHTABBR = 3051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ROMANCE_STANDARDABBR = 3052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ROMANCE_DAYLIGHTABBR = 3053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WEUROPE_STANDARDABBR = 3054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WEUROPE_DAYLIGHTABBR = 3055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WCENTRALAFRICA_STANDARDABBR = 3056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WCENTRALAFRICA_DAYLIGHTABBR = 3057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EEUROPE_STANDARDABBR = 3058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EEUROPE_DAYLIGHTABBR = 3059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EGYPT_STANDARDABBR = 3060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EGYPT_DAYLIGHTABBR = 3061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_FLE_STANDARDABBR = 3062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_FLE_DAYLIGHTABBR = 3063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GTB_STANDARDABBR = 3064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GTB_DAYLIGHTABBR = 3065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ISRAEL_STANDARDABBR = 3066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ISRAEL_DAYLIGHTABBR = 3067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SOUTHAFRICA_STANDARDABBR = 3068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SOUTHAFRICA_DAYLIGHTABBR = 3069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_RUSSIAN_STANDARDABBR = 3070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_RUSSIAN_DAYLIGHTABBR = 3071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARAB_STANDARDABBR = 3072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARAB_DAYLIGHTABBR = 3073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EAFRICA_STANDARDABBR = 3074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EAFRICA_DAYLIGHTABBR = 3075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARABIC_STANDARDABBR = 3076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARABIC_DAYLIGHTABBR = 3077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_IRAN_STANDARDABBR = 3078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_IRAN_DAYLIGHTABBR = 3079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARABIAN_STANDARDABBR = 3080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARABIAN_DAYLIGHTABBR = 3081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CAUCASUS_STANDARDABBR = 3082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CAUCASUS_DAYLIGHTABBR = 3083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AFGHANISTAN_STANDARDABBR = 3084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AFGHANISTAN_DAYLIGHTABBR = 3085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EKATERINBURG_STANDARDABBR = 3086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EKATERINBURG_DAYLIGHTABBR = 3087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WESTASIA_STANDARDABBR = 3088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WESTASIA_DAYLIGHTABBR = 3089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_INDIA_STANDARDABBR = 3090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_INDIA_DAYLIGHTABBR = 3091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEPAL_STANDARDABBR = 3092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEPAL_DAYLIGHTABBR = 3093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALASIA_STANDARDABBR = 3094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALASIA_DAYLIGHTABBR = 3095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SRILANKA_STANDARDABBR = 3096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SRILANKA_DAYLIGHTABBR = 3097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NCENTRALASIA_STANDARDABBR = 3098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NCENTRALASIA_DAYLIGHTABBR = 3099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MYANMAR_STANDARDABBR = 3100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MYANMAR_DAYLIGHTABBR = 3101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SEASIA_STANDARDABBR = 3102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SEASIA_DAYLIGHTABBR = 3103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NORTHASIA_STANDARDABBR = 3104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NORTHASIA_DAYLIGHTABBR = 3105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CHINA_STANDARDABBR = 3106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CHINA_DAYLIGHTABBR = 3107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SINGAPORE_STANDARDABBR = 3108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SINGAPORE_DAYLIGHTABBR = 3109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TAIPEI_STANDARDABBR = 3110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TAIPEI_DAYLIGHTABBR = 3111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WAUSTRALIA_STANDARDABBR = 3112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WAUSTRALIA_DAYLIGHTABBR = 3113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NORTHASIAEAST_STANDARDABBR = 3114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NORTHASIAEAST_DAYLIGHTABBR = 3115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_KOREA_STANDARDABBR = 3116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_KOREA_DAYLIGHTABBR = 3117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TOKYO_STANDARDABBR = 3118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TOKYO_DAYLIGHTABBR = 3119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_YAKUTSK_STANDARDABBR = 3120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_YAKUTSK_DAYLIGHTABBR = 3121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AUSCENTRAL_STANDARDABBR = 3122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AUSCENTRAL_DAYLIGHTABBR = 3123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENAUSTRALIA_STANDARDABBR = 3124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENAUSTRALIA_DAYLIGHTABBR = 3125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AUSEASTERN_STANDARDABBR = 3126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AUSEASTERN_DAYLIGHTABBR = 3127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EAUSTRALIA_STANDARDABBR = 3128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_EAUSTRALIA_DAYLIGHTABBR = 3129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TASMANIA_STANDARDABBR = 3130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TASMANIA_DAYLIGHTABBR = 3131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_VLADIVOSTOK_STANDARDABBR = 3132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_VLADIVOSTOK_DAYLIGHTABBR = 3133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WESTPACIFIC_STANDARDABBR = 3134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_WESTPACIFIC_DAYLIGHTABBR = 3135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALPACIFIC_STANDARDABBR = 3136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALPACIFIC_DAYLIGHTABBR = 3137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_FIJI_STANDARDABBR = 3138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_FIJI_DAYLIGHTABBR = 3139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEWZEALAND_STANDARDABBR = 3140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NEWZEALAND_DAYLIGHTABBR = 3141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TONGA_STANDARDABBR = 3142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_TONGA_DAYLIGHTABBR = 3143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AZERBAIJAN_DISPLAY = 3144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AZERBAIJAN_STANDARDABBR = 3148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_AZERBAIJAN_DAYLIGHTABBR = 3149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALBRAZILIAN_DISPLAY = 3150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALBRAZILIAN_STANDARDABBR = 3154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALBRAZILIAN_DAYLIGHTABBR = 3155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALMEXICO_DISPLAY = 3156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALMEXICO_STANDARDABBR = 3160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_CENTRALMEXICO_DAYLIGHTABBR = 3161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GEORGIAN_DISPLAY = 3162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GEORGIAN_STANDARDABBR = 3166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_GEORGIAN_DAYLIGHTABBR = 3167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_JORDAN_DISPLAY = 3168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_JORDAN_STANDARDABBR = 3172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_JORDAN_DAYLIGHTABBR = 3173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MIDDLEEAST_DISPLAY = 3174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MIDDLEEAST_STANDARDABBR = 3178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MIDDLEEAST_DAYLIGHTABBR = 3179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MONTEVIDEO_DISPLAY = 3180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MONTEVIDEO_STANDARDABBR = 3184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MONTEVIDEO_DAYLIGHTABBR = 3185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOUNTAINMEXICO_DISPLAY = 3186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOUNTAINMEXICO_STANDARDABBR = 3190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOUNTAINMEXICO_DAYLIGHTABBR = 3191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NAMIBIA_DISPLAY = 3192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NAMIBIA_STANDARDABBR = 3196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_NAMIBIA_DAYLIGHTABBR = 3197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFICMEXICO_DISPLAY = 3198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFICMEXICO_STANDARDABBR = 3202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PACIFICMEXICO_DAYLIGHTABBR = 3203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_VENEZUELA_DISPLAY = 3217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_VENEZUELA_STANDARDABBR = 3220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_VENEZUELA_DAYLIGHTABBR = 3221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SNOOZE = 3222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SNOOZE_TIME = 3223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONSTARTUP = 3224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_EVENT_FILTERS_SELECTED = 3225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SNOOZE_ALARM = 3226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_INTERACTION_OPTIONS = 3229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PLAY_SOUND = 3233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOUND_TO_PLAY = 3234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_OMNICAST_EVENT_FILTER_LABEL = 3235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GENERIC_SCROLLUP = 3236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GENERIC_SCROLLDOWN = 3237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_APPLICATIONLAUNCHER = 3238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_FPS = 3239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_KBPS = 3240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_PIXELS = 3241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLAYBACK_NEXT_FRAME = 3242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAVE_SNAPSHOT = 3245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRINT_SNAPSHOT = 3246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RECORDING_ON = 3249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RECORDING_OFF = 3250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RECORDING_ABOUT_TO_STOP = 3251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RECORDING_ON_LOCKED = 3252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RECORDING_OFF_LOCKED = 3253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOW_COMMAND_BAR = 3256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HIDE_COMMAND_BAR = 3257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_WDMSG_DEFAULT_AUDIO_CREATION_FAILED = 3259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_DISPLAY_REPORT_WARNING = 3260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_WARNING_MESSAGE = 3261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_WARNING_TIME_RANGE = 3262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_QUESTION_CONTINUE = 3263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_WARNING_ENTITIES = 3264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKBOX_DISPLAY_AGAIN = 3265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ARCHIVE_ALL_AVAILABLE = 3266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ARCHIVE_SHOW_TIME = 3267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLAYBACKSTATE_PLAYING = 3269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLAYBACKSTATE_PAUSED = 3271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLAYBACKSTATE_FASTREVERSE = 3272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLAYBACKSTATE_BEGINREACH = 3274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLAYBACKSTATE_ENDREACH = 3275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CASHREGISTERS = 3279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CAMERAS = 3280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_LICENSEMAXENTITYEXCEEDED_FORMAT = 3281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CANTCOPYVALIDATIONKEY = 3282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CANTPASTELICENSE = 3283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_AUTOHIDE_PAGES = 3284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RELATIVE_TIME = 3285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ABSOLUTE_TIME = 3286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_CONNECTIONSTATUS_CONNECTING = 3288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SPLASH_MODULE_LOADING = 3289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_REFRESHING = 3294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TIME_RANGE = 3300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEFAULT_LAYOUT_NAME_GUARDTOUR = 3302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CRITERIAS = 3303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_QUICKACCESS = 3304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEWERLAYOUT = 3307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_LOOP_PLAYBACK = 3309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SLOW_MOTION = 3310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PANLEFT = 3312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PANRIGHT = 3313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_TILTUP = 3314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_TILTDOWN = 3315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_ZOOMIN = 3316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_ZOOMOUT = 3317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_SPEED = 3318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_FOCUS_NEAR = 3320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_FOCUS_FAR = 3321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_IRIS_OPEN = 3322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_IRIS_CLOSE = 3323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_HOME = 3324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_FLIP = 3325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_LOCK = 3326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_SPECIFIC_COMMANDS = 3327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PRESET_GOTO = 3328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PRESET_SET = 3329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PATTERN_RUN = 3330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PATTERN_REC = 3331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_AUX_SET = 3332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_AUX_CLEAR = 3333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_AUXILIARIES = 3334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PRESETS = 3335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PATTERNS = 3336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIO = 3338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDITAUDIO = 3339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE = 3340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_CREDENTIALS = 3341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REQUIREDFIELDEMPTY = 3344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERRORONTAB = 3347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREATE_REPORT_OVERWRITE = 3349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PRINT = 3350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_BADGE_BATCH_PRINTING = 3351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_BATCH_BADGE_PRINTING = 3354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_PEOPLE_COUNT_RESET = 3356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESET_PEOPLE_COUNT = 3357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_WIEGAND = 3358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_CLOCK_AND_DATA = 3359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READER_TYPE = 3360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_XML = 3361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_NAME_DUPLICATES = 3362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_CARD_FORMATS = 3364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARD_DATA = 3365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_WIEGAND_DORADO = 3366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_CLOCK_AND_DATA_DORADO = 3367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOR = 3368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DONTSAVE = 3369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVINGLAYOUTS_INFORMATIVE = 3370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OVERWRITE = 3371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DONTBACKUP = 3372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTIVE = 3373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERIMETER = 3374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MODIFY = 3376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DONTAPPLY = 3380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONTINUE = 3381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ASSIGN = 3382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DONTASSIGN = 3383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DONTOVERWRITE = 3384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_CARD_FORMAT = 3385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_ID_DUPLICATES = 3386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_LENGTH = 3387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WIEGANDFIELD_MASK_EXCEEDED = 3388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_ID_MANDATORY = 3389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_NAME_MANDATORY = 3390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_FORMATFIELD_MANDATORY = 3391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WIEGANDFIELD_MASK_DUPLICATES = 3392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WIEGANDFIELD_SIZE_EXCEEDED = 3393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FORMATFIELD_NAME_MANDATORY = 3394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDFORMATMULTIPLE = 3395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXCEPTION_OCCURED = 3396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXCEPTION_SAVED = 3397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_INTERACTION = 3399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNICAST_INTEGRATION_CONNECTION_STATUS = 3400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNICAST_INTEGRATION_STATUS = 3401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNICAST_INTEGRATION_CONNECTIONSTATUS_CONNECTED = 3402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNICAST_INTEGRATION_CONNECTIONSTATUS_NOTCONNECTED = 3403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IDLE = 3404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING = 3405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ARCHIVEPREVIEW = 3406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_CONNECTIONSTATUS_CONNECTED = 3408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_CONNECTIONSTATUS_NOTCONNECTED = 3409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_CONNECTION_STATUS = 3410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_STATUS = 3411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOTRETRIEVESNAPSHOT = 3413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOTACCESSCAMERA = 3414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CATEGORY_GENERAL = 3417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FORMATFIELD_NOT_UNIQUE = 3418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FORMATFIELD_ORDER_INVALID = 3419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FORMATFIELD_ORDER_CONFLICT = 3420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FIXEDFIELD_VALUE_INVALID = 3421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CATEGORY_ABA = 3422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ABADELIMITERFIELD_MANDATORY = 3423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ABADELIMITERFIELD_MAXLENGTH = 3424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SIZEDABAFIELD_LENGTH_EXCEEDED = 3425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SIZEDABAFIELD_LENGTH_INVALID = 3426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ABAFORMATFIELD_SIZE_EXCEEDED = 3427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DELIMITEDABAFIELD_MAXLENGTH = 3428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CATEGORY_WIEGAND = 3429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_NON_WIEGANDFIELD = 3432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_NON_ABAFIELD = 3433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CATEGORY_PARITY = 3434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PARITYFIELD_VALUE_INVALID = 3435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALIDOPTION_FORMAT = 3436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALIDOPTIONS_FORMAT = 3437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_VIEW_WEB_PAGES = 3438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRODUCTORY_DELIMITER = 3439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETING_ENTITIES = 3440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DELIMITER_ELLIPSIS = 3441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_EVENT_FILTER_SELECTED = 3442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RETRIEVING_PLEASEWAIT = 3443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_LARGE_FILE_WARNING_X = 3447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DELIMITEDABAFIELD_DELIMITER_MANDATORY = 3448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ABADELIMITER_DELIMITER_MANDATORY = 3449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PARITYCHECK_TYPE_MANDATORY = 3450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CODE_STRING_INVALID = 3451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_SELECTOR = 3453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_DASHBOARD = 3454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PEN = 3462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECT = 3463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERASER = 3464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVERT = 3465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXTERNALSYSTEM_CANNOT_DELETE_USING_IMPORTEDUSER = 3471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_CATEGORY_INVESTIGATION = 3473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_CATEGORY_MAINTENANCE = 3474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_CATEGORY_ACCESS_CONTROL = 3475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_ENTITY = 3476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_ENTITIES = 3477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DELETE = 3479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SAVE_SNAPSHOT_FILTER = 3480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ABBREVIATION_HOUR = 3481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ABBREVIATION_DAY = 3482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGICAL_VIEW = 3483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CUSTOM_SIZE = 3485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DELETE_SIZE = 3486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CUSTOM_BADGE_SIZE = 3487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LENGTH = 3488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIDTH = 3489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_INCHES_ABBREV = 3491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_NAME = 3493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFINITION = 3494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAYOUT_OPTIONAL = 3495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_GROUP = 3496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_USAGE_NOTIFICATION = 3497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISK_SPACE = 3499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_USAGE = 3500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GB_REMAINING = 3501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_TIME_RANGE = 3502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ABSOLUTE_TIME_RANGE = 3503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE_FILTER = 3504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MEMBER_OF = 3512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CARDHOLDER_GRANTED_ACCESS_BY = 3513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CARDHOLDER_DENIED_ACCESS_BY = 3514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TRIGGER = 3515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TRIGGER_OUTPUT_BEHAVIORS = 3516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVAILABLE_OUTPUT_BEHAVIORS = 3517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUTS = 3518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_INPUTS = 3519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPAND_CARDHOLDER_GROUPS = 3525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_STATE_ACTIVE = 3530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_STATE_TROUBLE_SHORT = 3531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_STATE_TROUBLE_OPEN = 3532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCESS_TYPE = 3533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ACCESS_RULE = 3534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_TYPE_GRANTED = 3536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_TYPE_DENIED = 3537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATH = 3540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GENERIC_SCROLLLEFT = 3543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GENERIC_SCROLLRIGHT = 3544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CARDHOLDERPARTITION = 3546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CREDENTIALPARTITION = 3547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTFIELD_CREDENTIALSTATUS = 3548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ZONES_UPDATED = 3549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE = 3550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDE_PERIMETER_ENTITIES = 3552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALUE_MUST_BE_BETWEEN = 3553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INVALID_VALUE = 3555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ACCESS = 3556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EDIT_LOGICALID = 3557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_PLAYBACK_RESTRICTED = 3563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSACTION_NUMBER = 3564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMPLOYEE_NUMBER = 3565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AMOUNT = 3566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDIT_CARD_NUMBER = 3567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYWORDS = 3568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIONSHIPS = 3571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RESTRICTED_INFO_ON_READER = 3572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_SINGLEINSTANCEEXTERNALSYSTEM_ALREADY_EXISTS = 3573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALOG_PTZ = 3574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SAVE_CONFIG_SYNCHRONIZE = 3575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_APPLY_AND_SYNC = 3576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DO_NOT_SYNC = 3577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PROCESSING_PLEASE_WAIT = 3581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_RULE = 3582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CASH_REGISTER_NAME = 3583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_CASHREGISTERS = 3585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXTERNAL_SYSTEM_DELETION_MAY_TAKE_A_LONG_TIME = 3586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ACTIVEDIRECTORIES = 3587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXTERNALSYSTEM_RESET = 3589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_CAMERA = 3590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESET_CONNECTION = 3591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALUE_NOT_PROVIDED = 3592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALUE_PROVIDED = 3593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_METADATA = 3594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NOTENOUGHFREETILES = 3595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONNECTION_REFUSED_BY_UNIT = 3598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_CONNECTION_TIMED_OUT = 3599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_FORCED_OPEN_GRACE_PERIOD = 3600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOOR_FORCED_OPEN_GRACE_PERIOD = 3601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_NAME_LAST_NAME_ORDERING = 3602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT_COLUMNS = 3603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXCEPTIONS_ARE_APPLICABLE_WITH_UNLOCKING_SCHEDULES_ONLY = 3605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_ALREADY_BEING_ENROLLED = 3606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_CARDHOLDER_MANAGEMENT = 3607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM = 3608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OPTIONAL_UPGRATE_STEP = 3609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_VIDEOARCHIVES = 3610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_FILENAME = 3612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_FILESIZE = 3613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCATE_FILE_X = 3614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANIMPORTENTITIES = 3616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSACTION = 3617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_TRANSACTION_AVAILABLE = 3618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_OPERATOR_SEQUENCE = 3619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISSING_CLOSING_QUOTE = 3620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_KEYWORDS_REACHED = 3621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TERMINAL = 3622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMERIC_VALUES_INVALID = 3623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMPLOYEE = 3624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_OPERATOR_AND = 3625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_OPERATOR_OR = 3626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_COPYSTANDALONEAP = 3629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_CREATEAUTORUN = 3630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SAAP_UNKNOWNERROR = 3633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESTINATIONPATH = 3636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSION = 3637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSION_SETTINGS = 3638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSION_IN_PROGRESS = 3639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UPGRADETOFEDERATION = 3641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CUSTOM_CARD_EDITOR = 3642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MASK = 3643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_LENGTH = 3644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_LENGTH = 3645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELIMITER = 3646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CODE_FORMAT_STRING = 3647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARD_FORMAT_TYPE = 3648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_ABA = 3649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORMAT_LENGTH = 3650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BIT_POSITION = 3651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_XML = 3652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IMPORT_XML = 3653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_VALIDATE_WITH_CREDENTIALS = 3654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHARACTERS = 3656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ERRORDLG_HEADER = 3657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ERRORDLG_FOOTER = 3658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FAILEDTOEXPORT = 3659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_FIELD = 3660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_PARITY_CHECK = 3661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_FORMAT_LENGTHS_NO_MATCH = 3662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CREDENTIAL_NO_MATCH = 3663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_AMBIGUOUS_MATCHES = 3664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CARDFORMAT_VALIDATION_SUCCESS = 3665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_INVALID_CREDENTIAL = 3666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CARDFORMAT_CHANGE_TYPE = 3667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_INVALID = 3668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CORRECT_ERRORS = 3669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DELIMITED_ABA_FIELD = 3670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SIZED_ABA_FIELD = 3671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DELIMITER_ABA_FIELD = 3672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIEGAND_FIELD = 3673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELIMITED = 3674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIZED = 3675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIXED_VALUE = 3677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PARITY_CHECK = 3678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ODD = 3680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVEN = 3681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARITY_BIT = 3682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_MASK_MANDATORY = 3683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_MASK_INVALID = 3684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_VALUE_MANDATORY_ON_FIXED_FIELDS = 3685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_UNIQUE_PARITY_CHECK = 3686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_FIELDS_LENGTH_GREATER_THAN_CARD_LENGTH = 3687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLICKHEREMOREINFO = 3690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_STARTED = 3692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MATCHING_GROUPS = 3695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MULTIPLE_GROUPS_FOUND = 3696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_GROUP_NAME = 3697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MORE_THAN_ONE_GROUP_MATCHED = 3698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_START_RECORDING = 3699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_STOP_RECORDING = 3700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALIDFOLDER = 3701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MUST_PAUSE_TO_SCROLL = 3703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SCROLL_BACK = 3704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SCROLL_FORWARD = 3705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MESSAGE = 3706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXPORT_INVALIDDIRECTORY = 3712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CONVERT = 3717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_ACTIVE_DIRECTORY_USE_SERVICE_CREDENTIALS = 3718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CASHREGISTERS = 3723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_CHILD_PARTITIONS = 3724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_USERGROUPS = 3725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONVERSION_STATUS_INPROGRESS = 3726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PTZ_SPEED = 3727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINUTES = 3729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECONDS = 3730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CASH_REGISTER_EXTERNAL_ID = 3737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_ICON_AND_FIELD_NAME = 3739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTOREFILE = 3744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DOOR_LOCK_NOT_PREFERRED_OUTPUT = 3745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_CUSTOM_FIELDS = 3746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_EXTERNALSYSTEM_UPGRADE_NOTE = 3747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHARACTER_ENCODING = 3748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ELEVATOR_CONTROLLED_BY_MULTIPLE_UNITS = 3749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISIBLE_TO_ADMINISTRATORS = 3750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_DESCRIPTION = 3751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OPTIONS_DATETIME = 3752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS = 3753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOP = 3754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOTTOM = 3755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TASKBAR = 3759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS_TASKBAR_POSITION = 3761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERFORMANCE = 3762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERFORMANCE_OPTIONS = 3763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOMEMENU_CATEGORY_CONFIGURATION = 3767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ERROR_DELETING_TEMPLATE = 3769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CANCEL = 3773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_UNDO_MODIFICATION = 3774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOURCE_WEBCAM = 3776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_INVALID_COLLATION_SERVER = 3778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_INVALID_COLLATION_DATABASE = 3779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GROUPBOX_SYSTEM_MESSAGES = 3783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GROUPBOX_ADMINISTRATION_TASKS = 3784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RENAME_ALL_DEVICES_INSIDE_UNIT = 3786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GROUPBOX_NETWORK_OPTIONS = 3792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_PLAYSOUND = 3793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_DISPLAYOVERLAID = 3794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_SWITCHALARMMONITOR = 3795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_ALARMHANDLED = 3796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_GBHEADER = 3797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TASKBAR_RENAME = 3803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_NOTENOUGHCREDENTIALINFO = 3804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_PICTURE_SIZE = 3805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_CREDENTIAL_AS_KEY = 3806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEY = 3807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUT = 3809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPY = 3810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASTE = 3811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_INVALID_PICTURE_FORMAT = 3812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOTAL_PROGRESS = 3813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_BATCH_PROGRESS = 3814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEIGHT = 3820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEASUREMENT_UNIT = 3822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRONT_BADGE = 3828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACK_BADGE = 3829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIZE = 3830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_INCHES = 3831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_MILLIMETERS = 3832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_CATEGORY_OPERATIONS = 3834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_CATEGORY_ADMINISTRATION = 3835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_ALARM_MONITORING = 3836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_BARCODE_UNSUPPORTED_CHARACTER = 3837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BARCODETYPE_CODE39 = 3838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BARCODETYPE_CODE39EXTENDED = 3839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BARCODETYPE_I25 = 3840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_INCLUDE_CHECK_DIGIT = 3842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_BARCODE_SHOW_FOOTER = 3843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_ENTER_BARCODE_TYPE = 3844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_ALARM_ALARMQUERYRESULTS = 3845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TASKBAR_SAVEWORKSPACE = 3848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_TASK_TEMPLATES = 3849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CPUUSAGE = 3851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_NOTIFICATIONS_MESSAGE_COUNT = 3852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_ALARMPROCEDURE = 3853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_MUTED_VOLUME_TOGGLE = 3854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_UNMUTED_VOLUME_TOGGLE = 3855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_CREATED = 3856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_PRIVILEGES = 3857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_FULLSCREEN_VIDEO = 3860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCAL_SYSTEM = 3865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNKNOWN_SYSTEM = 3866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_TILES_ONLY = 3867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MORE_OPTIONS = 3868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUBLIC = 3870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVATE = 3871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTERVAL = 3872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_ALLTASKS = 3874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_NO_SYNCHRONIZATION_TASK = 3875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_FILTER_CRITERION = 3878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TASK = 3880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_VISUALS_ASKFORTASKNAME = 3881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC = 3882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_OPTIONS = 3883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WAITING_FOR_SIGNAL = 3884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_FAILURE_DIAMOND_FEDERATION = 3885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PROCESSING_CONFLICT_MANIFEST = 3886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILES = 3888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_PARTIAL_RESULT = 3889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_DISPATCH_FAILED = 3890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_ERRORS_OCCURED = 3891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OPTIONS_DATETIME_OPTIONS = 3892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_OVERRIDDEN_ENTITIES = 3893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_OVERRIDE = 3894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCAL_SUFFIX = 3895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FEDERATED_CREDENTIAL_CONFLICT = 3896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PROXY_DENIED_MODIFICATIONS = 3897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENTS_OPTIONS = 3898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_TEMPLATE = 3899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRAY = 3901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PACK = 3902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNPACK = 3903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARMCONTENTVIEWER_INSTANCE = 3904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_TROUBLESHOOT_FEDERATED_LOCATIONS = 3905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECEIVE_EVENT = 3907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_DELETING_OBSELETE_CARDHOLDERS = 3908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_DELETING_OBSELETE_USERS = 3909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_SYNCHRONIZING_USERGROUPS = 3910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_SYNCHRONIZING_CARDHOLDERGROUPS = 3911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_DELETING_CARDHOLDERGROUPS = 3912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_DELETING_USERGROUPS = 3913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_POPUP_VIDEO_TO_PLAYBACK = 3914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_POPUP_VIDEO_TO_LIVE = 3915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SAVE_IMAGE = 3916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TASKBAR_DELETEALL = 3918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEVICE_ALREADY_IN_USE = 3921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEADER_WHEN = 3922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEADER_WHERE = 3923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_APPLICATION_NOTCONNECTED = 3924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADVANCED_SEARCH = 3925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKINDATE = 3926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_TASK = 3930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISCELLANIOUS_PRIVILEGES = 3932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_TILES = 3933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTITION_ADMINISTRATOR = 3935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_ACCOUNT_DISABLED_OR_LOCKED = 3936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_QUEUEDCONNECTION_FORMAT = 3937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILE_TRACKING = 3939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITIONINQUEUE_FORMAT = 3940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SET_OF_PRIVILEGES = 3942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_SET_OF_PRIVILEGES = 3943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEWER_PEOPLECOUNTING = 3945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_READ_ONLY_PRIVILEGES_BECAUSE_OF_ADMIN_USER = 3946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CURRENT_READ_ONLY_PRIVILEGES_BECAUSE_OF_PARTITION_ADMIN = 3947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_USER_GROUP = 3948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOTED_ENTITY = 3949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PRIVILEGE_TROUBLESHOOTER = 3950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ENTITY_TYPE = 3951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ENTITIES = 3952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PRIVILEGE_TROUBLESHOOTER_ELLIPS = 3953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOT_PRIVILEGES = 3956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PRESET_APPLIED = 3957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_RETRIEVE_PRIVILEGES = 3958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_BACKWARD = 3959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_FORWARD = 3960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_INVESTIGATE = 3961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_APPLICATIONPRIVILEGES_GROUP = 3962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_FEDERATIONLOGON = 3963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADMINISTRATIONPRIVILEGES_GROUP = 3964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LOGICALENTITYTASKS_GROUP = 3965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWAREAS = 3966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYAREAS = 3967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEAREAS = 3968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWMAPS = 3969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYMAPS = 3970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEMAPS = 3971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PHYSICALENTITYTASKS_GROUP = 3972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWUNITS = 3973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RESETUNITS = 3974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UNITFIRMWAREUPGRADE = 3975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYUNITS = 3976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEUNITS = 3977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWCAMERAS = 3978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCAMERAS = 3979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWOUTPUTBEHAVIORS = 3980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYOUTPUTBEHAVIORS = 3981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEOUTPUTBEHAVIORS = 3982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWDOORS = 3983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYDOORS = 3984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEDOORS = 3985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWELEVATORS = 3986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYELEVATORS = 3987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEELEVATORS = 3988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWZONES = 3989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYZONES = 3990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEZONES = 3991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWCASHREGISTERS = 3992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCASHREGISTERS = 3993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SCHEDULEMANAGEMENTTASKS_GROUP = 3994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWSCHEDULES = 3995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYSCHEDULES = 3996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETESCHEDULES = 3997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWSCHEDULEDTASKS = 3998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYSCHEDULEDTASKS = 3999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETESCHEDULEDTASKS = 4000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACCESSCONTROLMANAGEMENTTASKS_GROUP = 4001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWCARDHOLDERS = 4002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCARDHOLDERS = 4003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETECARDHOLDERS = 4004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWCARDHOLDERGROUPS = 4005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCARDHOLDERGROUPS = 4006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETECARDHOLDERGROUPS = 4007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWCREDENTIALS = 4008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCREDENTIALS = 4009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETECREDENTIALS = 4010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWACCESSRULES = 4011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYACCESSRULES = 4012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEACCESSRULES = 4013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWBADGETEMPLATES = 4014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYBADGETEMPLATES = 4015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEBADGETEMPLATES = 4016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ALARMMANAGEMENTTASKS_GROUP = 4017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWALARMS = 4018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYALARMS = 4019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEALARMS = 4020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TEMPLATEMANAGEMENTTASKS_GROUP = 4021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWREPORTTEMPLATES = 4022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYREPORTTEMPLATES = 4023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEREPORTTEMPLATES = 4024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TASKPRIVILEGES_GROUP = 4025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MAINTENANCE_GROUP = 4026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACCESSREPORT = 4027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACCESSTROUBLESHOOTERREPORT = 4028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CONFIGURATIONREPORTS_GROUP = 4029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_IOCONFIGURATIONREPORT = 4030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACCESSRULESCONFIGURATIONREPORT = 4031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CARDHOLDERSCONFIGURATIONREPORT = 4032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CREDENTIALSCONFIGURATIONREPORT = 4033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UNITSCONFIGURATIONREPORT = 4034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INVESTIGATION_GROUP = 4035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_AREAPRESENCEREPORT = 4036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIDEOARCHIVEREPORT = 4037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIDEOBOOKMARKREPORT = 4038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIDEOMOTIONSEARCH = 4039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_POINTOFSALEREPORT = 4040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VISITORREPORT = 4041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ALARMREPORT = 4042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACTIVITYREPORTS_GROUP = 4043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CARDHOLDERACTIVITYREPORT = 4044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DOORACTIVITYREPORT = 4045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_AREAACTIVITYREPORT = 4046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ELEVATORACTIVITYREPORT = 4047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CREDENTIALACTIVITYREPORT = 4048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VISITORACTIVITYREPORT = 4049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UNITACTIVITYREPORT = 4050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ZONEACTIVITYREPORT = 4051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_OPERATION_GROUP = 4052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CARDHOLDERCREATOR = 4053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CREDENTIALENROLLMENT = 4054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PEOPLECOUNTING = 4055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SURVEILLANCE = 4056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACTIONPRIVILEGES_GROUP = 4057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACCESSCONTROL_GROUP = 4058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGECARDHOLDERACTIVATIONOREXPIRATIONDATE = 4060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGECARDHOLDERSTATUS = 4061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCARDHOLDERNAME = 4063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCARDHOLDERCUSTOMFIELDS = 4064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCARDHOLDERPICTURE = 4065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGECREDENTIALACTIVATIONOREXPIRATIONDATE = 4067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGECREDENTIALSTATUS = 4068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCREDENTIALNAME = 4069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCREDENTIALASSOCIATIONTOCARDHOLDER = 4070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCREDENTIALCUSTOMFIELDS = 4071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PRINTBADGES = 4072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DOORS_GROUP = 4073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_FORGIVEANTIPASSBACKVIOLATION = 4074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VISITORS_GROUP = 4075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHECKINANDCHECKOUTVISITORS = 4076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYVISITORSCUSTOMFIELDS = 4077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYVISITORPICTURE = 4078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_AUDIOVIDEO_GROUP = 4079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_USEAUDIO = 4080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CAMERAS_GROUP = 4081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SAVEANDPRINTSNAPSHOTS = 4082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PTZMOTORPRIVILEGES_GROUP = 4083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_USEPTZPATTERNS = 4084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SETPTZPATTERNS = 4085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_USEPTZAUXILIARIES = 4086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SETPTZAUXILIARIES = 4087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ALARMS_GROUP = 4088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SNOOZEALARMS = 4089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MACROS_GROUP = 4090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_USERS_GROUP = 4091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SENDEMAIL = 4092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SENDMESSAGE = 4093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PLAYSOUND = 4094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERALPRIVILEGES_GROUP = 4095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PRINTANDEXPORTREPORTS = 4096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SAVETASKTEMPLATE = 4097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWVISITORS = 4098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYVISITORS = 4099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LASTNAME_COMMA_FIRSTNAME_ORDERING = 4100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTANT_REPLAY = 4101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRINTTEMPLATEFILE = 4104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_FULLSCREEN_COLLAPSE = 4105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TIME_LOCAL_TO_DIRECTORY = 4106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_FAILED_UPDATEDS_INSUFFICIENTPRIVILEGES = 4107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOOR_UNLOCK = 4108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOOR_LOCK = 4109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_OPENED = 4110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_CLOSED = 4111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGETEMPLATE_PROVISIONNING = 4114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGETEMPLATE_SUPERVISOR = 4115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGETEMPLATE_INVESTIGATOR = 4116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGETEMPLATE_OPERATOR = 4117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGETEMPLATE_REPORTING = 4118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GROUPHEADER_OPTION = 4119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BUTTON_TEMPORARY_OVERRIDE_DOOR_SCHEDULE = 4120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_LOCK_DOOR = 4121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_UNLOCK_DOOR = 4122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR = 4123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZOOM = 4124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKIN = 4125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKOUT = 4126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_VIA_PARTITION_Y = 4128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CREATING_INCIDENT = 4129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_TRACKING = 4130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_TRACKING = 4131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKUP_FOLDER = 4132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_ACCEPTED_USER_OF = 4133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ABA_FIELD = 4135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIEGAND_FIELD = 4136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTION_EXECUTED = 4139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EXECUTE_ACTION = 4140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNKNOWN_CREDENTIAL = 4148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIFFERENT_CARDHOLDER = 4154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_AUTOHIDE_TILE_TOOLBAR = 4157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCARDHOLDERGROUPCUSTOMFIELDS = 4158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_NARROW_BAR_WIDTH = 4159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_WIDE_BAR_WIDTH_RATIO = 4160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_WHITE_SPACE_WIDTH_RATIO = 4161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_DATABASE_SERVER_DISK_FULL = 4163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SWIPE_A_CARD = 4165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PEOPLE_COUNT = 4168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DELETEFORMAT = 4169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CLEARAREA = 4170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGNED_TO = 4171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_RETRIEVE_REPORT = 4173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HOME = 4174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_EMPTY_QUERY = 4175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_ACCESS_TROUBLESHOOTER = 4176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_ACCESS_TROUBLESHOOTER_ELLIPS = 4177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_TRACKEDENTITIES = 4178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_LOGON = 4179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LOGON = 4181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LOGOFF = 4182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LOGON_POPUP = 4183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_ALREADY_BELONGS_TO_ANOTHER_CARDHOLDER = 4184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INSUFFICIENT_PRIVILEGES_VIEW_VIDEO = 4187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARD_FACILITY_OUT_OF_RANGE = 4188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARD_DATA_OUT_OF_RANGE = 4190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_FORMAT_NOT_SUPPORTED = 4191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANNOT_PARSE_CREDENTIAL_DATA = 4192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CLIENT_COULD_NOT_BE_IDENTIFIED = 4193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPAND_ALL = 4194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_COLLAPSE_ALL = 4195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_NAME_AND_PRIORITY = 4196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPAND_SELECTION = 4198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_COLLAPSE_SELECTION = 4199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORWARDDELAYSECONDS = 4201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISSING_AUDIT_TRAIL_INFO = 4202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_DATA_TYPE = 4203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE = 4204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_CUSTOM_DATA_TYPE = 4205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOOR_COLON = 4206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SET_USER_AS_PARTITION_ADMINISTRATOR = 4210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACKING_QUERY_ERRORS_OCCURED = 4212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GHOST_PROCESS_DETECTED = 4213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_MAP_OVERRIDE_SCHEDULE_FOR_TIME = 4215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIDE_COLON = 4216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTING_ERROR_NOTSUPPORTEDONTARGETVERSION = 4217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMFIELDSIMAGEMODIFY = 4218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KILL_GHOST_PROCESS2 = 4219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_MESSAGE_SUPPLIED = 4220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_SELECTION = 4221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TAKE_SNAPSHOT = 4222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_KEEP_SNAPSHOT = 4223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DISCARD_SNAPSHOT = 4224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_IN_ANOTHER_PARTITION = 4225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALIDURL = 4226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOGGLEVIEW_CARDS = 4227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INSUFFICIENT_PRIVILEGES = 4228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_MODIFY_OWN_BASIC_PRIVILEGES = 4230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARITY_MASK_INCLUDES_POSITION_BIT = 4231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_SEND_REPORT_BY_EMAIL_CHECK_PRIVILEGES = 4232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_READ = 4233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_HIT = 4234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_AUTOPATROLLER = 4235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_AUTOPATROLLERS = 4238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_RULES = 4239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_AUTOPATROLLERS = 4241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CREATE_DATABASE = 4244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DELETE_DATABASE = 4245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BACK_UP_RESTORE_DATABASE = 4246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TEST_DATABASE_CONNECTION = 4247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_UPGRADE_DATABASE = 4252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_LPR_EVENT_PROTECTION = 4255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_DROPPED = 4256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_BACKUP_IN_PROGRESS_X = 4258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_BACKUP_FAILED_X = 4259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_BACKUP_SUCCEEDED = 4260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_RESTORE_IN_PROGRESS_X = 4261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_RESTORE_FAILED_X = 4262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_RESTORE_SUCCEEDED = 4263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_UPGRADE_IN_PROGRESS_X = 4264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_UPGRADE_SUCCEEDED = 4265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_UPGRADE_FAILED_X = 4266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNEXPECTED_LOGGING_ERROR_X = 4267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_DROP_FAILED_X = 4268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARITY_BIT_USED_IN_MASK_DETAILED = 4269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MASK_USES_LOWER_PRIORITY_PARITY_BIT_DETAILED = 4270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDFORMAT_CORRECT_ERRORS_IMPORT = 4271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERSION_ERROR_NONE_SELECTED = 4272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_TAILING = 4273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_TAILING = 4274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAILING = 4275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAIL = 4276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR = 4277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPRMANAGEMENTTASKS_GROUP = 4279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_UNIT = 4285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_UNITS = 4286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_LPR_UNITS = 4287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_OVERTIME_RULE = 4288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_OVERTIME_RULES = 4289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_LPR_OVERTIME_RULES = 4290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_PERMIT_RULE = 4291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_PERMIT_RULES = 4292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_SHARED_PERMIT_RULE = 4294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_SHARED_PERMIT_RULES = 4295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_LPR_SHARED_PERMIT_RULES = 4296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_HOTLIST_RULE = 4297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_HOTLIST_RULES = 4298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_LPR_HOTLIST_RULES = 4299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_NEW_WANTED_RULE = 4300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_COLOR = 4305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_LOTS = 4306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_FILE = 4307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_FOLDER = 4308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERTIME_ZONE = 4309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_DAYS = 4310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCEMENT = 4311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_LIMIT = 4312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOURS_MAJ = 4313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAYS_MAJ = 4314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_CARDHOLDER = 4317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DELETE_CARDHOLDER = 4318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RETURN_CARD = 4319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SETDOORMAINTENANCEMODE = 4321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_LPR_VALIDITY = 4323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODE = 4324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIMPLE = 4325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVERYONE = 4327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_PERMITS = 4328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_PERMIT = 4329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEEKDAYS = 4330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEEKEND = 4331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM = 4332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLICABLE_DAYS = 4333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLICABLE_HOURS = 4334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_LPR_PERMITZONE = 4336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW = 4337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_PERMITS = 4340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_OVERRIDE_LOCKING_SCHEDULE = 4341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_SETTINGS = 4342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITYDESK_SETTINGS = 4343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HTTP_LISTENING_PORT = 4344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEND_READ_LP_IMAGE = 4348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEND_READ_CONTEXT_IMAGE = 4349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_GHOST_PATROLLER_DESCRIPTION = 4351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ELLIPSE = 4352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIZE_AND_POSITION = 4353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLORS_AND_LINES = 4354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIDTH_ABREVIATION = 4355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEIGHT_ABREVIATION = 4356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_POSITION_ABBREVIATION = 4357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_Y_POSITION_ABBREVIATION = 4358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GROUPBOX_HEADER_CREDENTIAL_STATUS = 4360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SAVE_AND_CLOSE = 4361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_PATROLLER_TYPE_UNDEFINED = 4362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCREDENTIALINFORMATION = 4363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGECARDHOLDEROPTIONS = 4364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSEPLATE = 4365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FULL = 4366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTIAL = 4367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_PERMIT_RESTRICTION_RULE = 4369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_PERMIT_RESTRICTION_RULES = 4370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_LPR_PERMIT_RESTRICTION_RULES = 4371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODULES = 4372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPPING_TYPE = 4373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAPPING_TYPE_MSBING = 4374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAPPING_TYPE_MAPINFO = 4375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPINFO_WORKSPACE = 4376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPINFO_VERSION = 4377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OLD = 4378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INITIAL_LONGITUDE = 4379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INITIAL_LATITUDE = 4380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST = 4381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT_REJECT_REASONS = 4382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_CATEGORIES = 4383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMIT_LISTS = 4384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARED_PERMIT_ELAPSED_TIME = 4385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BASE_FOLDER = 4387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMAIL_NOTIFICATOR = 4389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOCODER = 4391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT_MATCHER = 4392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XML_IMPORTER = 4393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XML_EXPORTER = 4394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HORIZONTAL_POSITION = 4395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTICAL_POSITION = 4396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROOT_FOLDER = 4397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLOAD = 4400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONES = 4401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MODIFY_EVENTS = 4403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_SQLSERVERTIMEOUT = 4404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PERMIT = 4405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PERMITS = 4406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_PERMITS = 4407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER_USERGROUP = 4411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENROLL = 4412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC_ENTRY = 4413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXISTING_CREDENTIAL = 4414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LONG_TERM = 4415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNKNOWNERROR_LICENSECALCULATION = 4417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRESENT_CARDS_TO_READER = 4420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEMPORARY_CARD = 4421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESTORE_ORIGINAL_CREDENTIAL = 4422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_PLATE = 4425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_PLATEIMAGE = 4426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_PATROLLERNAME = 4427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_USERNAME = 4428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ADDRESS = 4429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEQUENCE_GENERATOR = 4430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ON_READ = 4431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_REMOTEDB_WARNING = 4432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_HOTLIST = 4433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_OVERTIME = 4434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_PERMIT = 4435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_PERMIT_RESTRICTION = 4436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_POSITION = 4437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_POSITION_PARALLEL = 4438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_POSITION_ANGLED = 4439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_SETTING = 4440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERATING_DATA_FOR_PRINTING = 4441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERTIME = 4442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMIT = 4443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHAREDPERMIT = 4444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HITS = 4445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_PLATEREADS = 4446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTITION_COUNT_OVER_MAX_ALLOWED = 4448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_LEGACY = 4449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_SUPPORTS_MAPINFO = 4450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_LAW_ENFORCEMENT = 4451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_CITY_PARKING_ENFORCEMENT = 4452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_UNIVERSITY_PARKING_ENFORCEMENT = 4453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XML_DATA_PATH = 4454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_FORMAT = 4456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATE_FORMAT = 4457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XML_FOLDER_PATH = 4458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XML_TEMPLATE_FILE_PATH = 4459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_DATABASE = 4460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REMOVE_DATABASE = 4461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GRACE_PERIOD = 4464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOVU_DB_CREATING = 4467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOVU_DB_DROPPING = 4468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOVU_DB_RESTORING = 4469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOVU_DB_BACKINGUP = 4470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOVU_DB_WAITING_UPGRADE = 4471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOVU_DB_UPGRADING = 4472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SUFFIX_DAYS = 4473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SUFFIX_HOURS = 4474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SUFFIX_MINUTES = 4475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SUFFIX_SECONDS = 4476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ASSIGN_TEMPORARY_CARD = 4477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_TYPE_LPR = 4478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_TYPE_CONTEXT = 4479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_TYPE_OVERVIEW = 4480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_HIT = 4481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_NAME = 4484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_ALREADY_ASSIGNED = 4487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAME_POSITION = 4488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISTRICT = 4489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLOCK_FACE = 4490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_POSITION_RETENTION = 4491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_READS_RETENTION = 4492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_HITS_RETENTION = 4493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_CARDSEARCH = 4494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FIND_ENTITY_BY_CARD = 4495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CREATED = 4497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ZONE_NAME_UNIQUE = 4498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATION = 4499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_DATE = 4500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRAWING_NOT_SUPPORTED = 4501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_RIGHTS_OVERVIEW = 4502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXCEPTIONS = 4503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_GENERATINGDATA = 4505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_EXPORTINGTOFORMAT = 4506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PDF = 4507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_PDFFILES = 4508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXCEL = 4509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_EXCELFILES = 4510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PICTURE = 4511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_CREDENTIAL_BATCH = 4512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_VISITORS = 4513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_VISITORS = 4514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISREAD = 4515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PATH_ON_SAME_ES = 4517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PATH_ON_SAME_IS = 4518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_MAX_PIN_VALUE = 4519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKOUTDATE = 4521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPACITY = 4522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARD_DETECTED = 4523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CARD_DETECTED = 4524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_UNKNOWN_USER = 4526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_UNKNOWN_HOTLIST_RULE = 4527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_UNKNOWN_PERMIT_RULE = 4528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_UNKNOWN_OVERTIME_RULE = 4529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_UNKNOWN_SHARED_PERMIT_RULE = 4530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_VIEW = 4531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DURING_EXTERNAL_SYSTEM_INITIALIZATION = 4532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTER_PIN_CODE = 4533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTER_PIN_CODE_VALIDATION = 4534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PASSWORDS_DONT_MATCH = 4536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_GHOST = 4537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_SERIALIZE_TASK = 4538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_OFFLINEPERIOD = 4539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OFFLINEPERIOD_ONLINE = 4540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OFFLINEPERIOD_GRACE = 4541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OFFLINEPERIOD_OFFLINEALARM = 4542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OFFLINEPERIOD_OFFLINE = 4543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SAVING_ENTITY = 4544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SHOW_ALL = 4545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SHOW_ACTIVE = 4546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SHOW_ACKNOWLEDGED = 4547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REGION = 4548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLETOCREATE_CREDENTIAL = 4549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_ALREADY_EXIST_NO_PRIVILEGE = 4550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_DOESNT_EXIST_NO_PRIVILEGE = 4551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_NAME_HAS_ILLEGAL_CHARACTERS = 4552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WATERMARK_UNKNOWN = 4554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WATERMARK_TAMPERED = 4555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WATERMARK_GOOD = 4556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_WATERMARK = 4557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MULTIPLE_FORMAT_FOUND = 4558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DURING_EXTERNAL_SYSTEM_DELETION = 4559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEEK_BACK_TIME = 4560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_SEEK_BACK = 4561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT_TYPE = 4562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_TAKEN = 4563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REJECT_REASON = 4564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HITUSERACTION_ACCEPTED = 4565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HITUSERACTION_NOTENFORCED = 4566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HITUSERACTION_REJECTED = 4567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTOFMETADATAVALUES = 4568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_RANGE = 4569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CORRECT_ERRORS = 4570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAPINFO_WORKSPACE_NOT_FOUND = 4571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SYNCHRONIZING_LARGE_PATROLLER_USER_GROUP = 4572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEADER_HIT_INFORMATION = 4574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_REMOVE_ITEMS_FROM_REPORT = 4575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_HEADER_LPR_READ_CONTEXT_IMAGE = 4576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_HEADER_LPR_READ_MAP = 4577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_HEADER_LPR_READ_TIRES_IMAGES = 4578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_CONNECTINGEXTERNALSYSTEM = 4579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_CONNECTIONTOEXTERNALSYSTEMESTABLISHED = 4580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_SYNCHRONIZINGENTITIES = 4581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_RESULTS = 4584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_DELETIONPROGRESS_PERCENTAGE = 4585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_GROUP = 4588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_PROTECT_READS_HITS = 4589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_PROTECTED = 4590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PROTECT = 4591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_UNPROTECT = 4592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CATEGORY_VIDEO = 4600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_SYNCHRONIZINGCAMERAS = 4601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_SYNCHRONIZINGCASHREGISTERS = 4602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_SYNCHRONIZINGALARMS = 4603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_DELETINGORPHANENTITIES = 4604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ENFORCED_HITS = 4605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_BING_LICENSE_EXPIRATION = 4606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_DATA_IMPORT_MODULE = 4607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_DAILY_USAGE_PATROLLER = 4608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWPATROLLERS = 4609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYPATROLLERS = 4610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEPATROLLERS = 4611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYLPRUNITS = 4612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETELPRRULES = 4613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PATROLLERPLAYBACK = 4614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_READHITSPERLPRZONE = 4615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_READHITSPERDAY = 4616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPRZONEOCCUPATION = 4617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LOGINPERPATROLLER = 4618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETELPRUNIT = 4619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWLPRUNIT = 4620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RESETLPRUINTS = 4621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_OPERATING_TIME = 4622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MAX_STOP = 4623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_TOTAL_STOP = 4624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_INSTANCES = 4625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MAX_SHUTDOWN = 4626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_TOTAL_SHUTDOWN = 4627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYLPRRULES = 4628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OMNICAST = 4630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_AUTOVU = 4631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PURCHASEORDER = 4632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SYNERGIS = 4633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SECURITYCENTER = 4634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVICEMAINTENANCEAGREEMENT = 4635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SMANUMBER = 4636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRINT_HEADER_HIT_REPORT = 4637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRINT_HEADER_PHOTO_EVIDENCE = 4638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_HIT_CATEGORY = 4639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LPRREPORT_READS_HITS_PER_ZONE = 4640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LPRREPORT_ZONE_OCCUPATION = 4641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LPRREPORT_LOGIN_PER_PATROLLER = 4642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LPRREPORT_READS_HITS_PER_DAY = 4643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORT = 4645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CONTEXTIMAGE = 4646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RETRIEVINGMAP_FORMAT = 4648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LPR_READEVENT_FORMAT = 4649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LPR_HITEVENT_FORMAT = 4650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_UNSUPPORTED = 4651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_XML_IMPORT_MODULE = 4652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_SMA_SUPPORT = 4653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ERROR_WIEGANDFIELD_MASK_PARITY_CHECK = 4654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIXED_LENGTH = 4655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSLATIONS = 4656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ATTRIBUTES = 4657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_DELIMITERS = 4659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_LOGINTIME = 4660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_LOGOUTTIME = 4661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_NOTIFICATION = 4662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMIT_ID = 4663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_TOTAL = 4664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ZONE = 4665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_READS = 4666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_RULE = 4667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_HITS = 4668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_NOT_ENFORCED_HITS = 4669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_REJECTED_HITS = 4670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_HIT_TYPE = 4671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_TIRE_IMAGE = 4672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTERSECTION = 4673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_INTERSECTION = 4674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_LOT = 4675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VEHCILES = 4676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OCCUPATION_PECENT = 4677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_EVENT_RETENTION = 4678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT = 4679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READ = 4680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MANUALREAD = 4681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CONTEXTIMAGES = 4682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_TIRESIMAGES = 4683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DRILLDOWN_HITSFROMREAD_FORMAT = 4684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ENTITY_STATE = 4685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_SOURCETIME = 4686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGEDESIGNER_UC = 4687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_YEAR = 4688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_ALREADY_ENROLLED = 4689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERATED_CREDENTIALS = 4690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILTER_SHARPS_PATROLLERS = 4691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOUND_FILE = 4692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_COVERT = 4693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PATH_ON_SAME_PATROLLER = 4694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NETWORK_CONNECTION_LOST = 4695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE_AS_REPORT = 4697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONFIRM = 4698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_DUPLICATE_DIRECTORIES_DETECTED_ERROR = 4699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOTACCESSFILE_FORMAT = 4700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NAME_COPY_FORMAT = 4701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_PERMIT = 4702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NAME_UNIQUE = 4703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GENERATE_REPORT_AGAIN = 4706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_DOESNT_EXIST = 4707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERALSETTINGS = 4709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_PLATESTATE = 4711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_REPLICATION = 4712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WATERMARK_INCONCLUSIVE = 4714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DRAW_REGION = 4716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GROUPBOX_HEADER_ASSOCIATED_ENTITIES = 4717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERCENT = 4718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_AVERAGE = 4719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STATISTICS = 4720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SQLSERVER_DOESNT_EXIST = 4721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WRONG_ENGINE_VERSION = 4722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DATABASE_DOESNT_EXIST = 4723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNKNOWN_SQL_ERROR = 4724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_VIEW_LIVE_COVERT_HITS = 4725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NETWORK_ADAPTER_UNAVAILABLE = 4726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_FEDERATION = 4727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_PAPER_CREDENTIAL = 4729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COUNT_READY = 4730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWLPRRULES = 4731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT_MATCHER_DESCRIPTION = 4732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LONGITUDE = 4733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LATITUDE = 4734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_PARSE_LINE_VS_ROWS = 4735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_FILE_NOT_FOUND = 4736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_FILE_EMPTY_OR_NULL = 4737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_FILE_LOAD_ERROR = 4738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_TOOLTIP_MAXIMUM_RESULT_REACHED = 4739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETED = 4740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONNECTION_REFUSED_BY_UNIT_2 = 4741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LEGACY_AUTOVU_VERSION = 4742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENABLING_NOTIFICATIONS = 4743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_CREATE_FOLDER_X = 4744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_WRITE_TO_EXPORT_FOLDER_X = 4745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_LOAD_TEMPLATE_X = 4746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_XML_IMPORT_FOLDER_NOT_SET = 4747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GHOST_ENTITIES_NOT_SET = 4748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TEMPLATE_PATH_NOT_SET = 4749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_XML_IMPORT_TEMPLATE_X = 4750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ROOT_FOLDER_NOT_SET = 4751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TROUBLESHOOTER_CARDHOLDER_WILL_BE_EXPIRED = 4754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TROUBLESHOOTER_CARDHOLDER_COULD_BE_EXPIRED = 4755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THOUSANDSEPARATOR_NONBREAKINGSPACE = 4756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_MLPI_RULE = 4757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LPR_MLPI_RULES = 4758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_EMAIL = 4759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_LPR_MLPI_RULES = 4760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_MOBILE_LICENSE_PLATE_INVENTORY = 4762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTIPLE_ANCESTORS = 4763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READ_COLOR = 4765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_MANUAL_ATT = 4766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_HOTLIST_REJECT_REASON = 4767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_HOTLIST_REJECT_REASON = 4768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_HOTLIST_MANUAL_ENTRY_ATTRIBUTE = 4769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_HOTLIST_MANUAL_ENTRY_ATTRIBUTE = 4770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_HOTLIST_CATEGORY = 4771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_HOTLIST_CATEGORY = 4772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_OVERTIME_REJECT_REASON = 4773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_OVERTIME_REJECT_REASON = 4774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_PERMIT_REJECT_REASON = 4775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_PERMIT_REJECT_REASON = 4776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_METADATA = 4777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_METADATA = 4778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_AUTOMATICALLY_APPLIED = 4779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CREATED_CREATING_FUNCTIONS = 4780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CONNECTED = 4781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_VISITORS = 4782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_VISITOR = 4783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PRINT_EVIDENCE = 4784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_HOTLIST_ATTRIBUTE = 4785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_HOTLIST_ATTRIBUTE = 4786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_PERMIT_ATTRIBUTE = 4787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_PERMIT_ATTRIBUTE = 4788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_PARTITION_MEMBERS = 4790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_TRAIL_MEMBERSHIP_ADDED = 4792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_TRAIL_MEMBERSHIP_REMOVED = 4793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_TRAIL_MEMBERSHIP_MODIFIED = 4794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_PERMIT_CONSTRAINT = 4797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_PERMIT_CONSTRAINT = 4798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNASSIGNED_IMPORTED_CREDENTIAL = 4799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HOME_PAGE = 4801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILTER_DOORSELEVATORS = 4802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAY_LOSE_ACCESS_TO_ENTITIES_AFTER_DELETION = 4803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_ENTITY_INFORMATION = 4805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOVU_DB_UPGRADE_RESTORE_FAILED = 4806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALIDNAME = 4807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RECONCILE_INVENTORIES = 4809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_DELETE_READS_NOT_IN_INVENTORY = 4810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REGULATION = 4811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_MODIFY_DELETE_MLPI_READ = 4812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_INVENTORY = 4813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVENTORY_MANAGEMENT = 4814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITY_STATUS_INVALID = 4815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITY_ONE_OR_MORE_PARTITIONS = 4816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITY_NAME_FIELD_MANDATORY = 4817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_LIMITS_HIGH_RANGE = 4818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_LIMITS_LOW_RANGE = 4819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_LIMITS_UPPER_BOUND = 4820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_LIMITS_LOWER_BOUND = 4821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_LAST_VALUE = 4822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ERROR_IMAGES_WERE_REMOVED = 4824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVENTORYREPORT_FORMAT = 4825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HANDHELDDEVICE = 4826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MLPIACTION_ADDED = 4827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MLPIACTION_REMOVED = 4828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MLPIACTION_ADDEDREMOVED = 4829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MLPIACTION_NOACTION = 4830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MLPIACTION_ALL = 4831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_VEHICULES_ADDED = 4832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_VEHICULES_REMOVED = 4833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_VEHICULES_MANUALLY_REMOVED = 4834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MANUAL_ENTRIES_COUNT = 4835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FETCHIMAGES = 4836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONLY_SHOW_MANUALLY_REMOVED_READS = 4837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPAREWITH = 4838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTION = 4839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ARRIVAL = 4840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ELAPSEDTIME = 4841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MLPIZONE = 4842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MLPIROW = 4843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MLPI_ISEDITED = 4844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MLPI_ISMANUALLYREMOVED = 4845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROUTE = 4846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURATION = 4847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVENTORY = 4848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LATEST = 4849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC = 4850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVE_DELETE_PLATE_FORMAT = 4851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVIOUS_INVENTORY = 4854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNTAGGED_READS = 4855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_MLPI_RULE = 4856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_ROWS = 4857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SEND = 4858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_DESTINATION = 4860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MESSAGE = 4861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORKSPACE = 4862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIST_OPENED_TASKS = 4863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SEND_ELLIPSIS = 4864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SEND_TASK = 4865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_UNSPECIFIED = 4866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_ORPHANRULE = 4867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_UNKNOWNRULE = 4868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_UNKNOWNEXTERNALSYSTEM = 4869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_INITIALQUERYFAILED = 4870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_INVENTORYINPROGRESS = 4871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_INVENTORYCREATIONFAILED = 4872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_INVENTORYDELETED = 4873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_CANCELED = 4874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_SUCCESS = 4875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVENTORY_EXCEPTION_DATETIMERANGEINVALID = 4876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXCEPTION_MLPI_LOAD_PLUGIN_FAILED = 4877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXCEPTION_MLPI_NO_PLUGIN_FOUND = 4878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OFFLOADS = 4879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_FIRST_READ_TIME = 4880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_LAST_READ_TIME = 4881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_READ_COUNT = 4882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_EXISTING_INVENTORIES = 4883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MLPI_RECONCILIATION_POSSIBLE_MATCHES = 4884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MLPI_CREATE_INVENTORY = 4888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MLPI_PLATE_CONFIRMATION = 4890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MLPI_DELETE_LAST_INVENTORY = 4891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MLPI_READ_TIME = 4892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MLPI_CONFIRM_SAME_CAR = 4893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MLPI_CONFIRM_DIFFERENT_CAR = 4894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XML_TEMPLATE_FOLDER_PATH = 4895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MLPI_INVENTORY_WARNING_COMPARE_UNTAGGED_READS = 4896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MLPI_INVALIDATE_PATROLLER_READS = 4898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MESSAGE_FROM_SENDER_MESSAGE = 4899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DO_YOU_ACCEPT = 4900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SENDER_WANTS_TO_SEND_TASK_TO_YOU = 4902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESTINATION = 4903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MY_TASKS = 4904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUBLIC_TASKS = 4905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SAVE_TASK = 4906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXISTING_TASKS = 4907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_ITEMS_TO_DISPLAY = 4908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOURCE = 4909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INSUFFICIENT_MEMEORY_TO_SYNCHRONIZE_PATROLLERS = 4911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_X_COULD_NOT_BE_TRANSFERED = 4912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REMOVE_FROM_REPORT = 4913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DELETE_FROM_DATABASE = 4914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOST_NAME = 4915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IPV4_ADDRESS = 4916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IPV6_ADDRESS = 4917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ROLE = 4918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_SERVER = 4921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_SERVERS = 4923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SAVE_AS = 4924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SAVE = 4925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPGRADE_PRIVILEGETEMPLATERESET = 4926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SYSTEM_STATUS_TASK = 4927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_OPEN_THE_TASK = 4928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAY_NOT_HAVE_NECESSARY_PRIVILEGES = 4929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VIDEOSTATE_BUFFERING = 4930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VIDEOSTATE_STARTING = 4931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VIDEOSTATE_STOPPING = 4932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DWELL_TIME = 4933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MLPI_CREATE_INVENTORY_OPEN_DIALOG = 4934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EDIT_BOOKMARK = 4935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DELETE_BOOKMARK = 4936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SYSTEM_STATUS_FILTER_MONITOR = 4937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COUNT_X_ITEMS = 4938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ACTION_UNLOCK_DOOR_MAINTENANCE = 4939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SYSTEM_STATUS_FILTER_HEALTH_ISSUE = 4942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SYSTEM_STATUS_FILTER_ALL_ENTITIES = 4943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVE_DELETE_BOOKMARK_FORMAT = 4945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MACHINE_NAME = 4946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ENTITY_LOGICAL_PATH = 4947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_DOOR_LOCK_STATE = 4948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_DOOR_OPEN_STATE = 4949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CAMERA_RECORDING_STATE = 4950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CAMERA_ANALOG_SIGNAL = 4951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ZONE_ARMED = 4952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNIT_SYNC = 4953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNIT_FIRMWARE = 4954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONESTATE_TROUBLE = 4955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_AREA_PEOPLECOUNT = 4956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOADING_HI_RES_IMAGES = 4957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_LPR_PARKINGZONE = 4958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MANAGE_PRIVATE_TASKS = 4959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN_OPENED_TASK_IS_UPDATED = 4960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RELOAD_TASK = 4961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TASK_HAS_BEEN_UPDATED = 4962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DO_YOU_WANT_TO_RELOAD_TASK = 4963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SAVE_CHANGES_TO_TASK = 4964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_SHORTCUTS = 4965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PRESS_KEY_TO_RECORD = 4966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ADD_TASK = 4967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHORTCUT = 4968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMAND = 4969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOADING_HI_RES_IMAGES_ERROR = 4971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PRESS_ESCAPE_TO_CLOSE = 4972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_NETWORK = 4973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ROUTE = 4974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_KEYBOARD = 4975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_SEQUENCE = 4976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_MONITOR = 4977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_NETWORKS = 4978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_NETWORKS = 4979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ROUTES = 4980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_ROUTES = 4981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_KEYBOARDS = 4982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_KEYBOARDS = 4983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_SEQUENCES = 4984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_SEQUENCES = 4985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_MONITORS = 4986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_MONITORS = 4987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DO_YOU_WANT_TO_SET_OR_REMOVE_MAINTENANCE_MODE = 4988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SETDOORSMAINTENANCEMODE = 4989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REMOVEDOORSMAINTENANCEMODE = 4990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_FILE_PATH_ON_SAME_IS = 4991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_STREAM = 4992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_STREAMS = 4993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_STREAMS = 4994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REBOOT_UNIT_CONFIRMATION = 4995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_KEYSHORTCUT_INVALID = 4997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ALARM_PANEL = 4998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_ALARM_PANELS = 5000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTRUSION_ZONE = 5001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTRUSION_ZONES = 5002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_INTRUSION_ZONES = 5003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_NAME_SIGNAL_LOST = 5004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_NAME_SIGNAL_RECOVERED = 5005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_ALARM_PANEL_COUNT = 5006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_PANEL_AC_FAILED = 5007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_PANEL_BATTERY_FAILED = 5008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_PANEL_CONNECTED = 5009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_PANEL_DISCONNECTED = 5010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_PANEL_INPUT_BYPASS_ACTIVATED = 5011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_PANEL_INPUT_BYPASS_DEACTIVATED = 5012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_PANEL_INPUT_TROUBLE = 5013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_ALARM_ACTIVATED = 5014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_ARMING = 5015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_ARMING_POSTPONED = 5016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_DISARMED = 5017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_DURESS = 5018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_ENTRY_DELAY = 5019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_INPUT_BYPASS_ACTIVATED = 5020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_INPUT_BYPASS_DEACTIVATED = 5021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_INPUT_TROUBLE = 5022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_MASTER_ARMED = 5023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_PERIMETER_ARMED = 5024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_INTRUSION_ZONE_POSTPONE_ARMING = 5025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_INTRUSION_ZONE_DISARM = 5026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_INTRUSION_ZONE_ARM = 5027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INTRUSIONDETECTION_GROUP = 5028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ARMDISARMINTRUSIONZONES = 5029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_INTRUSION_DETECTION_NAME = 5033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_INTRUSION_DETECTION_DESCRIPTION = 5034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_NO_RESULTS = 5035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZ_COMMAND = 5037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPABILITIES = 5038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IPV6_NETWORK = 5040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCOMING_UDP_PORT_RANGE = 5041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROXY_SERVER = 5042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_END_POINT_1 = 5043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_END_POINT_2 = 5044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ROUTE_PROPERTIES = 5045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CREATING_A_NEW_ALARM_PANEL = 5046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ALARM_PANEL_INFORMATION = 5047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_DISARMED_NOT_READY = 5048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_DISARMED_READY_TO_ARM = 5049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_ZONE_INPUT_TROUBLE = 5050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_ARMING = 5051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE__PERIMETER_ARMED = 5052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_MASTER_ARMED = 5053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_ALARM_ACTIVE = 5054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_ARCHIVER_NAME = 5056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_ARCHIVER_DESCRIPTION = 5057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_VIDEOUNIT = 5058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_UC_VIDEOUNITS = 5059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_ENTITYNAME_LC_VIDEOUNITS = 5060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_GUARDTOUR = 5061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_GUARDTOUR = 5062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CREATING_NEW_NETWORK = 5063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_END_POINT = 5065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNICAST_TCP = 5066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNICAST_UDP = 5067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTICAST = 5068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CAMERA_SIGNALSTATE_RECOVERED = 5070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INSUFFICIENT_DISK_SPACE = 5071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECT_RECORDED_VIDEO = 5074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYBACK_PRETRIGGER_TIME = 5075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_ACCESSMANAGER_NAME = 5076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_ACCESSMANAGER_DESCRIPTION = 5077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC_DISPLAY_ALARM = 5078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SALVO_MODE_DWELL_TIME = 5079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_DISPLAYED_ALARM = 5080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_APPLICATION_CAMERA_CONNECTIONS = 5081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITYDESK_ON_MACHINE_IS_WATCHING_CAMERAS = 5082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_SEQUENCE = 5083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_FORWARD_ALARM = 5084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DUPLICATE_READS = 5085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERIPHERALS = 5086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMUNICATION_PORT = 5088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_CONFIGURATION = 5089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_PROTOCOL = 5090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_ADDRESS = 5091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOCONNECT_KEYBOARD = 5092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KEYBOARD_CONNECTION_WARNING = 5093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_STATUS = 5094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_CONNECTION_STATUS = 5095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_LAST_COMMAND_RECEIVED = 5096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_BYTES_SENT = 5097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_BYTES_RECEIVED = 5098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_KEYBOARD_CONNECT = 5099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_KEYBOARD_DISCONNECT = 5100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_COMPORT_OPENED = 5101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_COMPORT_CLOSED = 5102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_FAILED_TO_OPEN_COMPORT = 5103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_PTZ_COMMAND = 5104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_COMMAND = 5105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_BAD_PTZ_COMMAND = 5106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYBOARD_BAD_COMMAND = 5107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_USE_SPECIFIC_COMMANDS = 5108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PTZ_OVERRIDE_LOCKS = 5109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_DEFAULT_NETWORK = 5110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_SERVERS = 5112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ROLES = 5113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_START_ALARM_FORWARD = 5114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STOP_ALARM_FORWARD = 5115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SELECT_ALARM_RECIPIENT = 5116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MESSAGE_OPTIONAL = 5117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_UNITS = 5118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_INTRUSION_ZONE_BYPASS = 5119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ARMING_STATE = 5120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ROLE_CURRENT_SERVER = 5121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SYSTEM_STATUS_FILTER_ONLINE_ONLY = 5122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_STREAM_MANAGEMENT_NAME = 5123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_STREAM_MANAGEMENT_DESCRIPTION = 5124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING_STATE_MASTER = 5125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_INTRUSION_ZONE_ARM_IMMEDIATELY = 5126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_INTRUSION_ZONE_ARM_IN = 5127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INTRUSION_ZONE_ARM = 5128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PTZACTIVATED = 5129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PTZSTOPPED = 5130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PTZLOCKED = 5131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ZOOMBYUSER = 5132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ZOOMBYUSERSTOPPED = 5133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DWELL_TIME_SEC = 5134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_AUXILIARY = 5135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_COMMAND = 5136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_FEDERATED_CANNOT_BE_ASSIGNED = 5137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEM_STATUS_HEALTH_ISSUES = 5140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GO_TO_TIME = 5141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GO_TO_SPECIFIC_TIME = 5142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_CONNECTED = 5143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_CONNECTION_ESTABLISHED = 5144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGESERVICE_LOAD_SAVED_PAGES = 5145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGESERVICE_PREFETCH_ENTITIES = 5147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGESERVICE_ADD_PAGES_TO_WORKSPACE = 5148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGESERVICE_LOAD_ACTIVE_PAGE = 5149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGESERVICE_INITIALIZATION_COMPLETED = 5150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGESERVICE_INITIALIZE_WORKSPACE = 5151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGESERVICE_LOAD_PAGE = 5152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_EXPLICITLY_START_MACROS = 5153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_START_CYCLING = 5154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_STOP_CYCLING = 5155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HIT_REPORT_PER_TYPE = 5156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HIT_REPORT_PER_AGE = 5157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOWARD_ALARM = 5158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VIDEOSTATE_WAITING_FOR_DATA = 5159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASEBACKUP = 5161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASENOTIFICATIONS = 5162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_GENERALPROPERTIES = 5163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DIRECTORYCONFIGURATION = 5164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_GENETECSERVERCONFIGURATION = 5165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_MAINSERVERCONNECTION = 5166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SMTP = 5168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_HTTPPORT = 5169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LOCALMACHINEONLY = 5170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_ENABLECONSOLE = 5171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SMTPSERVERPORT = 5172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_FROMEMAILADDRESS = 5173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LISTENINGTCPPORT = 5175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_AUTHENTICATION = 5176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SENDEMAILON = 5177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SAVEKEYTOFILE = 5184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASEINSTANCE = 5186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASESERVER = 5187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LAUNCHRESTORENOW = 5189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SECURECOMMUNICATION = 5191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BUTTON = 5192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOWN_COMMAND = 5193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UP_COMMAND = 5194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARGUMENTS = 5195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AXIS = 5196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_FORWARDALARMS = 5198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUBNETS = 5200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTING_TO_READER = 5201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_JOYSTICK = 5203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVEJOYSTICK = 5204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_GENETECSERVER = 5207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORCE_INCIDENT_CREATION_ON_DOOR_OVERRIDE = 5208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INITIALIZING = 5209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SYNCHRONIZE_VIDEO = 5211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_DAYS_PRIOR_TO_EXPIRATION = 5213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_PORT = 5214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_CHANGE_IP_ADDRESS = 5217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_ADD_UNIT = 5218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_REBOOT = 5219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_IDENTIFY = 5220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_CHANGE_DISCOVERY_PORT = 5221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_CHANGE_COMMAND_PORT = 5222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_ITEMS_Y_SELECTED = 5223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CHANGE_COMMAND_PORT = 5224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_AREA_ADD_FEDERATED_ENTITIES = 5225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_CYCLING = 5226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INTRUSION_UNITS = 5227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INTRUSION_AREAS = 5228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_CARDHOLDER_SYNCHRONIZER_NAME = 5229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_CARDHOLDER_SYNCHRONIZER_DESCRIPTION = 5230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_ARCHIVING_QUEUE_FULL = 5231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_MULTIPLE_SOURCE = 5232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_NOT_ARCHIVING = 5233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_RTP_PACKET_LOST = 5234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_TRANSMISSION_LOST = 5235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_ARCHIVING_STOPPED = 5236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_CAN_WRITE = 5237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_DATABASE_LOST = 5238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_DATABASE_RECOVERED = 5239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_DISK_CHANGED = 5240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_DISK_FULL = 5241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_DISK_LOAD = 5242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_INVALID_CUSTOM_ENCRYPTION = 5243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_NO_WRITE = 5244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_NO_WRITE_ON_SPECIFIED_DISK = 5245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_INVALID_CONFIG = 5246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_LICENSE_PLATE_IN_SIGHT = 5247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_LICENSE_PLATE_OUT_OF_SIGHT = 5248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_LICENSE_PLATE_READING = 5249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_CONDITION_CHANGE = 5250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_CROSSED_LINE = 5251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_ENTERED = 5252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_EXITED = 5253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_FALL = 5254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_FOLLOWING_ROUTE = 5255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_IN_FIELD = 5256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_LEFT = 5257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_LOITERING = 5258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_MERGED = 5259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_REMOVED = 5260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_RUN = 5261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_SLIDE = 5262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_SPLIT = 5263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_STOPPED = 5264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_TAILGATING = 5265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_TAMPERING = 5266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_UNDEFINED = 5267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_JOYSTICK_X_AXIS = 5268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_JOYSTICK_Y_AXIS = 5269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_JOYSTICK_Z_AXIS = 5270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_JOYSTICK_RX_AXIS = 5271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_JOYSTICK_RY_AXIS = 5272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_JOYSTICK_RZ_AXIS = 5273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_JOYSTICK_SLIDER_NUMBER = 5274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CHANGE_IP_ADDRESS = 5275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCAL_IP = 5276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STARTING_IP = 5277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_IP_RANGE_EXCEEDED = 5278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ROUTE_STATUS_OPTIMAL = 5279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ROUTE_STATUS_NOT_OPTIMAL = 5280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_CONFIGURATION = 5281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETECTED_CAPABILITIES = 5282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_CACHE = 5283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_FOLDER = 5284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WINDOWS_USER_FOLDER = 5285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_SIZE = 5286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADVANCED = 5290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_OPTIONS = 5291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_POS_NAME = 5292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_POS_DESCRIPTION = 5293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BOOSTMANUALQUALITY = 5294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BOOSTMOTIONQUALITY = 5295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BOOSTSCHEDULEDQUALITY = 5296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STARTFILEPROTECTION = 5297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STOPFILEPROTECTION = 5298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PANSTOP = 5300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_FOCUS_STOP = 5301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_IRIS_STOP = 5302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_ZOOM_STOP = 5303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_TILT_STOP = 5304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_FOCUS = 5305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_IRIS = 5306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PAN = 5307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_TILT = 5308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_ZOOM = 5309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PLAYBACK_PREVIOUS_FRAME = 5310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVER_ADDRESS_EXAMPLE = 5311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_MANUFACTURERS_WEBPAGE = 5312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CREATING_NEW_INTRUSION_AREA = 5313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTRUSION_AREA = 5314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_INTRUSION_AREA_INFO_PAGE_TITLE = 5315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_ANTIPASSBACK_DISABLED_INVALIDSETTINGS = 5316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_SHOW_ALL_UNITS = 5317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_SHOW_ONLY_NEW_UNITS = 5318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVINGMODE_AUTO = 5319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVINGMODE_ON = 5320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVINGMODE_MANUAL = 5321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDING_MODES = 5322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_MANUAL_RECORDING_LENGTH = 5323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_TO_RECORD_AFTER_MOTION = 5324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_TO_RECORD_BEFORE_EVENT = 5325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RETENTION_PERIOD = 5326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BROWSE_FOR_FOLDER = 5327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_ADDEMAILRECIPIENT = 5328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_REMOVEEMAILRECIPIENT = 5329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISITOR_STATE_ACTIVE = 5330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISITOR_STATE_ARCHIVED = 5331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISITOR_STATE_ALL = 5332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORTFULLIMAGES = 5333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LIVE_CACHING_DISABLED = 5334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SLOW_VIDEO_CACHING = 5335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_JITTER_BUFFER_NOT_BIG_ENOUGH = 5336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_VIDEO_CACHE_PATH = 5337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_VIDEO_CACHING = 5338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OPTIONS_APPLIED_AT_STARTUP = 5339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASEINFO = 5340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERSIONS = 5341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA = 5342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATASERVERVERSION = 5343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CACHE_LOCATION = 5344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_MANUFACTURERS = 5345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMAND_PORT = 5346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CAMERA_DISTRIBUTION = 5347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_SETTINGS = 5349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUFACTURER = 5352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRODUCT_TYPE = 5353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSL = 5354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_VERSION = 5355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DISCOVER = 5357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SELECT_MANUFACTURER = 5358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_UNIT_MANUALLY = 5359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERING = 5360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_MANUFACTURER_SELECTED = 5361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PLEASE_SELECT_MANUFACTURER = 5362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLEMENTERROR_CANNOTCONNECTTOUNIT = 5363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SORT = 5364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_OLD_FILES_ON_DISK_FULL = 5366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTED_VIDEO_THRESHOLD = 5367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ABBREVIATION_MEGABYTES = 5368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_WATERMARKING = 5369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_FILES = 5373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NTP_SETTINGS = 5376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NTP_SERVER = 5377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NTP_PORT = 5378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLL_TIMEOUT = 5379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_MODE = 5380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LINE_DRIVER = 5382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID = 5383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPD_PORT = 5390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTICAST_IP = 5391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTION_TYPE = 5392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPEAKER_PROPERTIES = 5393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_PIN_PROPERTIES = 5394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUT_PIN_PROPERTIES = 5395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERIAL_PORT_PROPERTIES = 5396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MICROPHONE_PROPERTIES = 5397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_SETTINGS = 5398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_TYPE = 5399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENSITIVITY = 5400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_INTRUSION_ZONE_CANCEL_POSTPONE_ARMING = 5401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADVANCED_SETTINGS = 5402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_AUDIO = 5403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC_CLEANUP = 5404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_INTRUSION_AREA_ACTIVITY = 5406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_INTRUSION_UNIT_ACTIVITY = 5407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NESTED_PARTITIONS = 5409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RETREIVINGFILEVERSIONS = 5410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASECREATIONFAILED = 5411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDING_SETTINGS = 5412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INHERIT_FROM_ARCHIVER = 5413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_SETTINGS = 5414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_EXTENSIONS = 5415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTENSIONS = 5416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDING = 5417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIGH_PRIORITY = 5419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOW_PRIORITY = 5420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMAL_PRIORITY = 5421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_LOGICALID = 5422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETOCREATEINUSE = 5423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABLASTOCREATEINUSEINFO = 5424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_STOPANDCREATE = 5425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_FAILEDRESTOREDB = 5428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESTOREDATABASE = 5429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_BACKUP_FAILED = 5430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETORESTOREINUSE = 5431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETORESTOREINUSEINFO = 5432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_STOPANDRESTORE = 5433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILTER_BACKUPFILES = 5434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILBACKUPBEFORECONFLICTMANIFEST = 5435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TOOLS_COPYCONFIGTOOL = 5436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TITLE_COPYCONFIGTOOL = 5437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPYCONFIG_OPTIONS = 5439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_ENTITYNOTFOUND = 5469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_INVALIDTYPES = 5470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_PERMISSION = 5471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_SETPROPERTY = 5472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_EXCEPTION = 5473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_EXTENSIONNOTFOUND = 5474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_MEMBERS = 5475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_ATTACHEDENTITIES = 5476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_RECIPIENTS = 5477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_VIDEOENCODER = 5478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_VIDEOQUALITY = 5479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_BOOSTQUALITY = 5480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_STREAMUSAGE = 5481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_NETWORK = 5482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_CARDHOLDERGROUP = 5483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_ACCESSRULE = 5484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_PRIVILEGES = 5485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_ACCEPTEDUSEROF = 5486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_USERGROUP = 5487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECIPIENTS = 5488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES = 5489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY = 5490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY = 5492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOOSTQUALITY = 5493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STREAMUSAGE = 5494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION = 5495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_VIDEOUNIT = 5496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_VIDEOUNITMODEL = 5497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_VIDEOUNITMANUFACTURER = 5498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_DOORSIDE = 5499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ENABLE_ROLE = 5500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISABLE_ROLE = 5501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETOUPGRADEINUSE = 5502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETOUPGRADEINUSEINFO = 5503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_STOPANDUPGRADE = 5504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPGRADE = 5506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_UPGRADE_FAILED = 5507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASTE_FROM_CLIPBOARD = 5509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPY_CONFIG_APPLY_CHANGES_QUESTION = 5510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOTACKALARM_PRIVILEGE = 5512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENTPRIVILEGENEEDEDFORACK = 5513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACTSYSTEMADMIN = 5514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_TYPE = 5515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COUNT_X_ERROR = 5516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COUNT_X_ERRORS = 5517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_CONNECT_ERROR = 5521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_NOEXTENSION_ERROR = 5522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_MAXCONNECTIONS_ERROR = 5523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_EXTENSIONTYPE_ERROR = 5524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_DISCOVERYPORT_ERROR = 5525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_NODRIVER_ERROR = 5526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_UNIT_STATUS = 5527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_MANAGER = 5528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_FOWARD_ALARM = 5529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_APPLYCONFIGANDRESTARTSERVICE = 5531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ASKAPPLYCONFIGANDRESTARTSERVICEL = 5532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_ERROR_SOURCE_AND_DESTINATION_EXACTLYSAME = 5535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_WARNING_SOURCE_AND_DESTINATION_CONTAINS = 5536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_ERROR_MUST_SELECT_OPTION = 5537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_SOURCEENTITYNOTFOUND = 5538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_SAMEENTITY = 5539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_LOGONENTITY = 5546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_SYSTEMENTITY = 5547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PACK_TOGGLE = 5548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMIT_NAME = 5549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERY_REPLY_PORT = 5550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNICAST_PERIOD = 5551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTICAST_PERIOD = 5552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROADCAST_PERIOD = 5553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSACTION_TIMEOUT = 5554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RTSP_PORT = 5555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_HTTPS = 5556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TCP_NOTIFICATION_PORT = 5557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOTIFICATION_CHANNEL = 5558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_MANAGEMENT = 5559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_MANAGEMENT_DESCRIPTION = 5560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_LOGIN = 5561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEODATAFORMAT = 5562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_QUALITY = 5563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGEQUALITY = 5564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYFRAMEINTERVAL = 5565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROFILEANDLEVEL = 5566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOOBJECTTYPE = 5567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDINGFRAMERATE = 5568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATEPRIORITY = 5569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_REDIRECTOR_CONFIGURATION = 5570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETEC_SERVER = 5571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REDIRECTORS = 5574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_MULTICAST_ENDPOINT = 5575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETEC_SERVERS = 5576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONDETECTION = 5577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IDS_LABEL_MAIN = 5578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IDS_LABEL_CORE = 5579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADVANCEDSIMPLE = 5580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_ENTRY_DELAY = 5581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATEMODE_CONSTANT = 5582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_SP_L0 = 5583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_SP_L1 = 5584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_SP_L2 = 5585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_SP_L3 = 5586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_CP_L1 = 5587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_CP_L2 = 5588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_MP_L2 = 5589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_MP_L3 = 5590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_MP_L4 = 5591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_ASP_L0 = 5592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_ASP_L1 = 5593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_ASP_L2 = 5594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_ASP_L3 = 5595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_ASP_L3B = 5596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_ASP_L4 = 5597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG4PROFILELEVEL_ASP_L5 = 5598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_ALLFRAMES = 5599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_ALLKEYFRAMES = 5600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_15FS = 5601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_10FS = 5602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_6FS = 5603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_5FS = 5604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_2FS = 5605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_1FS = 5606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_2SF = 5607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_5SF = 5608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_10SF = 5609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_15SC = 5610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_30SF = 5611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_1MF = 5612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_2MF = 5613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRREC_5MF = 5614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLE_VIEW = 5615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGES = 5616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RTSP_STREAM_OVER_HTTP = 5617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RTSP_STREAM_OVER_TCP = 5618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAME_AS_UNIT = 5619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BEST_AVAILABLE = 5620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_HOTACTION = 5621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTICAST_ADDRESS = 5622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOOST_QUALITY_ON_MANUAL_RECORDING = 5624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOOST_QUALITY_ON_EVENT_RECORDING = 5625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE = 5626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOW_RESOLUTION = 5627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIGH_RESOLUTION = 5628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CHANGE_MULTICAST_ADDRESS = 5629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2MBITSHIGHQUALITY = 5631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2MBITPERSECONDLOWDELAY = 5632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2MBITPERSECONDAND256KBITPERSECONDAUDIO = 5633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFFULLMOTION25FPS = 5634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFFULLMOTION30FPS = 5635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFHIGH12FPS = 5636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFHIGH15FPS = 5637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFLOW5FPS = 5639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFLOW6FPS = 5640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFMEDIUM10FPS = 5641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFMEDIUM8FPS = 5642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFMEDIUM_LOW6FPS = 5643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFMEDIUM_LOW7FPS = 5644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_2CIFVERYLOW2FPS = 5645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_3_5MBITPERSECONDHIGHQUALITY = 5646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_3_5MBITPERSECONDLOWDELAY = 5647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_4MBITPERSECONDAND256KBITPERSECONDAUDIO = 5648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_4CIFHIGH15FPS = 5649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_4CIFLOW6FPS = 5650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_4CIFMEDIUM10FPS = 5651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_5MBITPERSECONDHIGHQUALITY = 5652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_5MBITPERSECONDLOWDELAY = 5653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFFULLMOTION25FPS = 5654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFFULLMOTION30FPS = 5655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFHIGH12FPS = 5656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFHIGH15FPS = 5657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFLOW5FPS = 5659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFLOW6FPS = 5660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFMEDIUM10FPS = 5661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFMEDIUM8FPS = 5662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFMEDIUM_LOW6FPS = 5663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFMEDIUM_LOW7FPS = 5664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_CIFVERYLOW2FPS = 5665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1FULLMOTIONHIGHBITRATE25FPS = 5667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1FULLMOTIONHIGHBITRATE30FPS = 5668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1FULLMOTIONLOWBITRATE25FPS = 5669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1FULLMOTIONLOWBITRATE30FPS = 5670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1FULLMOTIONMEDIUMBITRATE25FPS = 5671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1FULLMOTIONMEDIUMBITRATE30FPS = 5672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1HIGH12FPS = 5673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1HIGH15FPS = 5674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1LOW5FPS = 5676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1MEDIUM10FPS = 5677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1MEDIUM8FPS = 5678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1MEDIUM_LOW6FPS = 5679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1MEDIUM_LOW7FPS = 5680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_D1VERYLOW2FPS = 5681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_DEFAULTSETTINGS = 5682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_FULLMOTION25FPS = 5683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_FULLMOTION30FPS = 5684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_HIGH = 5685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_HIGH12FPS = 5686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_HIGH15FPS = 5687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_LOW = 5689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_LOW4FPS = 5690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_LOW5FPS = 5691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM = 5693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM15FPS = 5695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM6_8FPS = 5696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM8FPS = 5697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM_HIGH = 5698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM_HIGH10FPS = 5699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM_LOW = 5700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_MEDIUM_LOW5_7FPS = 5702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_VERYLOW1FPS = 5706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_VERYLOW2FPS = 5707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_CONFIG_PRIORITY_GROUP = 5708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_CONFIG_INHERIT_FROM_PARENT = 5710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_READ_ONLY_PRIVILEGES_BECAUSE_OF_NOT_ACCEPTED_USER = 5711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_NETWORKCARDSELECTANY = 5712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_KEYSHORTCUT_USED = 5713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_KEYSHORTCUT_USED_INFO = 5714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ZONES_GROUP = 5715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ARMDISARMZONES = 5716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_QCIF = 5717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_CIF = 5718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_2CIF = 5719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_2CIFH = 5720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_HALFCIF = 5721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_2THIRDD1 = 5722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_3QUARTERD1 = 5723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_4CIF = 5724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_D1 = 5725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_VGA = 5726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERAS_AND_SHAREDTASKS = 5728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARM_INTRUSION_AREA = 5729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANCEL_POSTPONED_ARMING = 5730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISARM_INTRUSION_AREA = 5731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSTPONE_ARMING = 5732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BRIGHTNESS = 5733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTRAST = 5734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HUE = 5735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SATURATION = 5736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT = 5737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWINTRUSIONAREAS = 5738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVE_VIEWING_LIMITATION = 5739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NTP_SETTINGS = 5742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_CANCELED_ALARM = 5743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALLED_EXTENSIONS = 5744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_ZONE_ROLE = 5745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_MORE_AVAILABLE_LOGICAL_IDS = 5746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGICAL_MUST_BE_ASSIGNED_A_VALUE = 5747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_ASSIGN_LOGICAL_ID_BECAUSE_RESERVED = 5748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOGGLE_MONITORING = 5749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ZONE_ARM = 5750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ZONE_DISARM = 5751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_INTRUSION_CUSTOM_EXECUTE = 5752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_MULTIPLE_ADDRESSES = 5755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_SINGLE_ADDRESSE = 5756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_MULTIPLE_PORTS = 5757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_SINGLE_PORT = 5758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING_DELAY = 5764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMED_GRACE_PERIOD = 5765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COUNT_DOWN_SOUNDER = 5766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUT_BEHAVIOR = 5767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING_SCHEDULE = 5768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VSIP_PORT = 5769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_SSL_CONNECTION = 5772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTRUSION_AREAS = 5777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_AGENT = 5778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_AGENTS = 5779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_AGENTS = 5780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_ERROR_MUST_SELECT_DESTINATION = 5782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_ERROR_CANT_GET_SOURCE = 5783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_DISARM = 5784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_PERIMETER_ARM = 5785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_MASTER_ARM = 5786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_COLORPALETTE = 5787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_DISK_GROUP = 5789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_MASTER_ARM_REQUEST = 5790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_PERIMETER_ARM_REQUEST = 5791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_DISARM_REQUEST = 5792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_MAXVARIABLE_IMAGEQUALITY = 5793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_ARMED = 5794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ZONE_NAME_DISARMED = 5795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_WARNING_SHARED_STREAM_CONFIG = 5796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_NULL_LOGICAL_IDS = 5797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_DOWN = 5798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_UP = 5799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_RIGHT = 5800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_LEFT = 5801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_ON_OFF = 5802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_OK = 5803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_STATIC_DOWNSAMPLING = 5804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRESET_LOWLATENCY = 5806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_VARIABLEBITRATE = 5807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLEAUDIO = 5808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_LIVE_STREAM = 5809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SELECT_STREAM = 5810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_ARCHIVER_EVENT = 5811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GROUP_OPTION_UI_ENHANCEMENT = 5813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTION_SHOW_LOGICAL_ID = 5814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPGRADE_TO_SECURITYCENTER = 5815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_TASK_FORWARDER_NAME = 5818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_TASK_FORWARDER_DESCRIPTION = 5819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPTYPE = 5820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEBPAGE = 5821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPFILE = 5822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVIEW = 5823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATH_NOT_SET = 5824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISK_BASE_PATH = 5825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIN_FREE_SPACE = 5826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FREE_SPACE = 5827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOTED_SPACE = 5828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOTAL_SIZE = 5829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNTITLED_DISK_GROUP = 5830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_ALL_VIDEO_STREAMS_AS_SEPARATE_CAMERAS = 5831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PREVIOUS_BOOKMARK = 5832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_NEXT_BOOKMARK = 5833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVERGAGE_DISK_USAGE = 5834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ESTIMATED_RECORDING_TIME = 5835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVING_CAMERAS = 5836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVING_SPAN = 5837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORSTCASE_BANDWITH = 5838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXECUTE_COMMAND = 5841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_MPEG4 = 5842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_MPEG2 = 5843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_MJPEG = 5845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_H264 = 5846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_CHECK_AUTO_QUALITY = 5847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_GOPSTRUCTURE_I = 5849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_GOPSTRUCTURE_IP = 5850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_GOPSTRUCTURE_IPB = 5851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_GOPSTRUCTURE_IPBB = 5852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG2STREAMINGTYPE_VES = 5854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MPEG2STREAMINGTYPE_PRG = 5855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MPEG2_STREAMINGTYPE = 5856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MPEG2_GOPMODE = 5857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MPEG2_GOPLENGTH = 5858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_VIDEOFILEREPORT = 5859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_FULL = 5860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTRUSION_UNIT = 5861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTRUSION_UNITS = 5862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INTRUSION_UNIT_COUNT = 5863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AT_LEAST_ONE_DISK_MUST_BE_SELECTED = 5865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNSUPPORTED_G64_VERSION = 5867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KEEP_DELETED_GROUP_NETWORK_LOCATIONS = 5868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_SHOULD_HAVE_ZONE_MANAGER_PARENT = 5870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_SHOULD_HAVE_ACCESS_MANAGER_PARENT = 5871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TOOMANYSYSTEMS_FORMAT = 5872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TASK_NOT_A_REPORT = 5874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_CAMERAS = 5875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISK = 5876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USED_SPACE = 5877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVAILABLE_SPACE = 5878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RW = 5879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_AREA_POSTPONED_ARM_REQUEST = 5880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_AREA_CANCEL_POSTPONE_ARM_REQUEST = 5881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ACCESS_BEHAVIORS_TOGGLE_STATE = 5883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ANTIPASSBACK_TYPE = 5884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TASK_FORWARDER_NOT_PRESENT = 5885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUBLIC_ADDRESS = 5887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_UNIT_OPTION_CLEAR_LOG_AFTER_DOWNLOAD = 5888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CREATE_OVERWRITE = 5889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNAVAILABLE = 5890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_OPEN_TOO_LONG_EVENT_AFTER = 5891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_OFFLINE_DOOR_SCHEDULE_OVERRIDE = 5892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_OFFLINE_DOOR_SCHEDULE_OVERRIDE_WIDGET_TOOLTIP = 5893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MEDIA_ROUTER_UNAVAILABLE = 5894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGE_WORKSPACE = 5895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_STARTSTOP_GUARDTOUR = 5896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGE_SECURITYDESK_OPTIONS = 5897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_DEACTIVATED = 5898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGE_TILE_CONTEXT = 5899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGE_TILE_PATTERN = 5900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CHANGE_CLIENT_VIEWS = 5901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DIGITAL_ZOOM = 5903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_OVERRIDE_VIDEO_QUALITY = 5904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CAMERA_ANALYTIC = 5905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETECAMERASEQUENCE = 5906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCAMERASEQUENCE = 5907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CANNOT_READ_G64 = 5909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEVIDEOUNITS = 5910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYVIDEOUNITS = 5911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWVIDEOUNITS = 5912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGES_ADDDELETECASHREGISTERS = 5916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_STARTSTOPSEQUENCE = 5918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_HARDWARE = 5919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIDEOUNIT_FIRMWAREUPGRADE = 5920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_RECORDING_ACTION_DURATION = 5921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_RECORDING_ACTION_DELAY = 5922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_PROTECTION_DURATION = 5923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWCAMERASEQUENCE = 5924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_PROTECTION_AND_KEEP_PROTECT_NEXT_VIDEO = 5925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_PROTECTION_ACTION_DELAY = 5926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_EVENTSCOUNT = 5928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_COMMAND_PROTECT_VIDEO_FILE = 5929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_COMMAND_UNPROTECT_VIDEO_FILE = 5930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FILE_NOT_FOUND = 5931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OCCURRED = 5932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONFIGURATION_MISSING = 5933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SPECIFIED_SERVER_UNAVAILABLE = 5934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ENTITY_NOT_FOUND = 5935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_STATUS_EXPORTING = 5936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTION_STATUS_UNPROTECTED = 5937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTION_STATUS_RPOTECTIONENDING = 5938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTION_STATUS_PROTECTED = 5939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILTER_PROTECTION_END_DATE = 5940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTION_END_INFINITE = 5941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTION_END_RELATIVE_DATE = 5942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTION_END_ABSOLUTE_DATE = 5943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_INPUTFILTERMODE_NONE = 5945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_INPUTFILTERMODE_LOW = 5946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_INPUTFILTERMODE_MEDIUM = 5947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_INPUTFILTERMODE_HIGH = 5948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_COMPRESSIONMODE_SM4 = 5949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_COMPRESSIONMODE_MPEG4 = 5950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_COMPRESSIONMODE_MPEG4_ASP = 5951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_INPUTFILTERMODE = 5952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_COMPRESSIONMODE = 5953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_BOSCH_MPEG4 = 5954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_VERINT_SM4 = 5955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOURCE_IP = 5956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR_FINISHED = 5957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_UPGRADE = 5958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_FW_IN_PROGRESS_EXIT = 5960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORPHAN_FILES = 5961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_TRANSPORT = 5962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DONE = 5963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARGUMENT1 = 5964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARGUMENT2 = 5965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_FORWARDER_SEND_EMAIL_FAILED = 5966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PROTECT_VIDEO = 5967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UNPROTECT_VIDEO = 5968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DEGREES = 5969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MOVE_ABSOLUTE = 5970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MOVE_RELATIVE = 5971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_ZOOM_FACTOR = 5973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_ZOOM_FACTOR = 5974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZERO_POSITION = 5975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_USE_CURRENT_POSITION = 5976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESTORE_FACTORY_DEFAULT = 5977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_UNIQUE_DISCOVERY_PORT = 5981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_DISCOVERY_PORT_RANGE = 5982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_MULTICAST_DISCOVERY = 5983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_UNICAST_REPLIES = 5984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PACKETS_PER_SECONDS = 5985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CA_CERTIFICATE = 5986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSL_PASSWORD = 5987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEM_TASKS = 5989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ENTITIES_WERE_NOT_ADDED = 5990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TRACKING_LIMIT_REACHED_FORMAT = 5991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_AUTOVU_NAME = 5992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IPV4_NETWORK_IP_PREFIX = 5993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_OFF = 5994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_FULL = 5995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLACK = 5996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WHITE = 5997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ON = 5998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_LOW = 5999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MEDIUM = 6000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_HIGH = 6001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MANUAL = 6002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOSTATS = 6003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTORESP = 6004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ONEPOINTCAL = 6005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_GRAYSCALE = 6006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VIDEOPOLARITY = 6007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WINDOWHEATER = 6008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_HISTOGRAM = 6009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VIDEOLEVEL = 6010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VIDEOGAIN = 6011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AGLMODE = 6012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_START_SEQ = 6013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_INSERT_PRESET_SEQ = 6014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CLEAR_SEQUENCE = 6015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHOW_SEQUENCE = 6016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_SEQ_DWELL = 6017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUX_ON_OFF = 6018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN = 6019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAMERA_SETUP = 6020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SAVE_PRESET_SPEED = 6021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_INSERT_PRESET = 6022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR_FILTER_OFF = 6023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR_FILTER_ON = 6024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SEQUENCE = 6025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PRESET = 6026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SECONDS = 6027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RELAY = 6028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RIGHT = 6029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_LEFT = 6030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SPEED = 6031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_LIMIT = 6032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_START = 6033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_STACK = 6034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_HOURS = 6035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MINUTES = 6036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_FACTORY_DEFAULT = 6037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_STOP_TIME = 6038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_FOCUS_TIME = 6039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_IRIS_TIME = 6040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_R_GAIN = 6041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_B_GAIN = 6042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AWB_MODE = 6043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_IRIS_ALC = 6044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_IRIS_PLC = 6045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MAX_DIGITAL_ZOOM = 6046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DSS_LIMIT = 6047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_EXPOSURE_PRIORITY = 6048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTLE_PRIORITY = 6049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AE_MODE = 6050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC = 6051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AGC = 6052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WDR = 6053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR_FILTER_OFF_GATE = 6054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR_FILTER_ON_GATE = 6055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR_FILTER_CTRL_MTHD = 6056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DOME_RESET = 6057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_AUTOFLIP_OFF = 6058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_AUTOFLIP_TILTINGATDOWN = 6059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_AUTOFLIP_ATDOWN = 6060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_VIDEOCONTROL_GAINUP = 6061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_VIDEOCONTROL_GAINDOWN = 6062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_VIDEOCONTROL_LIFTUP = 6063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_VIDEOCONTROL_LIFTDOWN = 6064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_VIDEOCONTROL_ADVANCESYNC = 6065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_VIDEOCONTROL_RETARDSYNC = 6066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_RESET_UNIT = 6067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_RESET_CAMERA = 6068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_RESET_FACTORY = 6069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_DIGITALZOOM_DISABLE = 6070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_DIGITALZOOM_ENABLE = 6071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_STEADYSHOT_ENABLE = 6072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_STEADYSHOT_DISABLE = 6073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_SHUTTER_OFF = 6074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_SHUTTER_ON = 6075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_SHUTTER_TOGGLE = 6076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_PSPEED_DISABLE = 6077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_360VISIONDLG_PSPEED_ENABLE = 6078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTO_IRIS_ON = 6079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTO_IRIS_OFF = 6080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTO_FOCUS_ON = 6081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTO_FOCUS_OFF = 6082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTOTRACKING_RUNNING = 6083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTOTRACKING_NOTRUNNING = 6084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_STOP_ALL_PATTERNS = 6085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_NIGHTMODE_ON = 6086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_NIGHTMODE_OFF = 6087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_FOCUS_AUTO = 6088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_FOCUS_MANUAL = 6089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_FOCUS_FAR_FIX = 6090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_FOCUS_DOME = 6091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_FOCUS_ONE_SHOT = 6092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_BACKLIGHT_ON = 6093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_BACKLIGHT_OFF = 6094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_SHUTTER_SPEED_AUTO = 6095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_SHUTTER_SPEED_60 = 6096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_SHUTTER_SPEED_100 = 6097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_AUTO = 6098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_LOCK = 6099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE2 = 6100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_FLUORESCENT_L = 6101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_FLUORESCENT_H = 6102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_MERCURY = 6103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_SODIUM = 6104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_HALOGEN = 6105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE_ONE_SHOT = 6106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_EXPOSURE_AUTO = 6107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_EXPOSURE_LOCK = 6108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAMERA_OFF = 6109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAMERA_ON = 6110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_ONE_PUSH_AF = 6111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_EXPOSED_AUTO = 6112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_EXPOSED_MANUAL = 6113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_SHUTTER_SPEED_PRO = 6114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE3 = 6115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_FADING = 6116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITEFADE = 6117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAMERA_RESET = 6118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PT_INIT1 = 6119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PT_INIT2 = 6120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SER_RCON = 6121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SER_RCOFF = 6122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_REMOTE_COMMAND_PASSO = 6123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_REMOTE_COMMAND_PASSN = 6124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_CASCADE_ON = 6125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_CASCADE_OFF = 6126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ENTER_HOST_MODE = 6127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ENTER_LOCAL_MODE = 6128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_SCREEN_OFF = 6129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_SCREEN_ON = 6130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SCREEN_TITLE_OFF = 6131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SCREEN_TITLE_ON = 6132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_TIME_MODE0 = 6133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_TIME_MODE1 = 6134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_TIME_MODE2 = 6135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_DATE_MODE0 = 6136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_DATE_MODE1 = 6137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_DATE_MODE2 = 6138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DEFAULT_RESET = 6139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE0 = 6140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_WHITE_BALANCE1 = 6141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_NOTIFY_OFF = 6142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_NOTIFY_ON = 6143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_GLOBAL_NOTIFY_OFF = 6144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_GLOBAL_NOTIFY_ON = 6145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_AE_LOCK_OFF = 6146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_AE_LOCK_ON = 6147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_LED_NORMAL = 6148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_LED_GREEN_ON = 6149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_LED_ALL_OFF = 6150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_LED_RED_ON = 6151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_LED_ORANGE_ON = 6152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_FOCUS_ON = 6153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_ID_ON = 6154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_ID_OFF = 6155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_POWER = 6156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_FOCUS = 6157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_ACTIVATE_FOCUS = 6158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_INIT = 6159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_OSD = 6160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_CHANGE_OSD = 6161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_BACKLIGHT = 6162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_FLICKER = 6163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_DIGITAL_EFFECTS = 6164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_MIRROR = 6165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_NEGATIVE = 6166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_TOGGLE_COLOR = 6167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CNB_SPCDLG_SWITCH_AWB = 6168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_RESTOREFACTORYDEFAULTS = 6169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_RESTORESTOREDDEFAULTS = 6170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_SAVESETTINGSDEFAULT = 6171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_RESETCALIBRATION = 6172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_STARTAUTOSCAN = 6173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_STOPAUTOSCAN = 6174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_ENABLEAUTOSCANPWRUP = 6175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_DISABLEAUTOSCANPWRUP = 6176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_ENABLEUSRLIMITS = 6177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_DISABLEUSRLIMITS = 6178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_SETMINPANLIMIT = 6179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_SETMAXPANLIMIT = 6180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_SETMINTILTLIMIT = 6181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_SETMAXTILTLIMIT = 6182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DT_REINIT = 6183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RESET = 6184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_WB_RED = 6185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_WB_BLUE = 6186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AUX_ALL_OFF = 6187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AUX_AUTO_PAN_RIGHT_ON = 6188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AUX_AUTO_PAN_RIGHT_OFF = 6189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AUX_SHOT_KEY_RELEASE = 6190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AUX_ALL_ON = 6191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AUX_AUTO_PAN_LEFT_ON = 6192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AUX_AUTO_PAN_LEFT_OFF = 6193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_ALL_OFF = 6194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_WIPER_OFF = 6195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_WASHER_OFF = 6196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_LIGHT_OFF = 6197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_IRIS = 6198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_PAN_OFF = 6199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_SCAN_NORMAL_OFF = 6200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_SCAN_ALARM_OFF = 6201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_COLOR = 6202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_IR_CUT_FILTER_OFF = 6203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_ZOOM_EXTENDER_OFF = 6204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_FOCUS = 6205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_WHITE_BALANCE = 6206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_CAMERA_SWITCHOVER = 6207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_WIPER_ON = 6208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_WASHER_ON = 6209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_LIGHT_ON = 6210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_MANUAL_IRIS = 6211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_PAN_ON = 6212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_SCAN_NORMAL_ON = 6213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_AUTO_SCAN_ALARM_ON = 6214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_BW = 6215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_IR_CUT_FILTER_ON = 6216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_ZOOM_EXTENDER_ON = 6217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_MANUAL_FOCUS = 6218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_MANUAL_WHITE_BALANCE = 6219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_EQ_MANUAL_CAMERA_SWITCHOVER = 6220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_APT_CONTINUE = 6221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_APT_5MIN_STEPS = 6222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_APT_30MIN_STEPS = 6223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_APT_1HR_STEPS = 6224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP2_WIPER_SPEED = 6225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_TEST1 = 6226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_TEST2 = 6227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_TEST3 = 6228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_TEST4 = 6229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_TEST5 = 6230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_ERROR_CLEAR = 6231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_AUTO_PAN_TIMER_CLEAR = 6232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_STATUS_REQUEST = 6233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_POWER_ON_RESQUEST = 6234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_ALL_CLEAR = 6235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SP_ACCESS_LEVEL_REQUEST = 6236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_MR_RECALL_LAST_POSITION = 6237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_MR_ALARM_CLEAR = 6238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_MR_RECALL_ALARM = 6239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_CLEARALL_PRESETS = 6240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_MW_ALL_ALARM_PRESET_CLEAR = 6241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_MW_ENTER_ALARM_PRESET = 6242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_MW_ENTER_LOCAL_ALARM = 6243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AS_NORMAL_AUTO_SCAN_SET = 6244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AS_ALARM_AUTO_SCAN_SET = 6245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AS_NORMAL_AUTO_SCAN_BYPASS = 6246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AS_ALARM_AUTO_SCAN_BYPASS = 6247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AS_NORMAL_AUTO_SCAN_TIMING = 6248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ELBEXDLG_AS_ALARM_AUTO_SCAN_TIMING = 6249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_COMMAND_PTZ_ZOOM = 6250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_COMMAND_PTZ_IRIS = 6251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_COMMAND_PTZ_FOCUS = 6252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IMAGELABSDLG_TIMEOUT = 6253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IMAGELABSDLG_DZ = 6254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IMAGELABSDLG_DF = 6255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IMAGELABSDLG_DI = 6256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IMAGELABSDLG_AUX_RELAY = 6257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IMAGELABSDLG_ANALOG_OUTPUT = 6258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_BRIGHTNESS_INC = 6259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_BRIGHTNESS_DEC = 6260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_CONTRAST_INC = 6261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_CONTRAST_DEC = 6262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_CURSOR = 6263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_ST = 6264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_CALIBRATE_WITH_SHUTTER = 6265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_CALIBRATE_WITHOUT_SHUTTER = 6266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_POLARITY = 6267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IR516_THERMAL_CAMERA_AUTO = 6268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PANTILT_RESET = 6269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_HOMING_CYCLE = 6270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MOTOR_REVERSE = 6271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MOTOR_NORMAL = 6272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MOMENTARY_RELAY_DURATION = 6273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOHOME_PERIOD = 6274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_AUTOFOCUSOFF = 6275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_AUTOIRISON = 6276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_AUTOIRISOFF = 6277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_ON = 6278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_OFF = 6279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZCOMMAND_STARTPATTERN = 6280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZCOMMAND_STOPPATTERN = 6281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_TARGETTRACKINGON = 6282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_TARGETTRACKINGOFF = 6283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_INFRARED_ON = 6284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_INFRARED_OFF = 6285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_AUTO_FOCUS_ON = 6286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_AUTO_FOCUS_OFF = 6287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_SHUTTER_UP = 6288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_SHUTTER_DOWN = 6289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_SHUTTER_ON = 6290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_SHUTTER_OFF = 6291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_COLOR = 6292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_MONO = 6293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_DAY = 6294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_NIGHT = 6295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_CAM_ON = 6296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_CAM_OFF = 6297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_HIGH_POWER = 6298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_LOW_POWER = 6299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_TX_ON = 6300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_TX_OFF = 6301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_FOV_TOGGLE = 6302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLACK_HOT = 6303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WHITE_HOT = 6304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_AUTOCAL_ON = 6305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_AUTOCAL_OFF = 6306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_HIGH_SENSITIVITY = 6307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_LOW_SENSITIVITY = 6308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AGC_ON = 6309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AGC_OFF = 6310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_DAYMODE_OFF = 6311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_DAYMODE_ON = 6312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_D_ZOOM_ON = 6313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_D_ZOOM_OFF = 6314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_IRIS_CAM_RESET = 6315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALC_ON = 6316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ELC_ON = 6317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MANUAL_IRIS_ON = 6318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BW_ON = 6319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BW_OFF = 6320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BW_AUTO = 6321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BW_BURST_ON = 6322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BW_BURST_OFF = 6323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_ON = 6324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_OFF = 6325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_INC = 6326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_DEC = 6327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_1_100 = 6328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_1_250 = 6329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_1_500 = 6330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_1_1000 = 6331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_1_2000 = 6332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_1_4000 = 6333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SHUTTER_1_10000 = 6334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_AUTO_ON = 6335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_AUTO_OFF = 6336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_AUTO_INC = 6337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_AUTO_DEC = 6338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_MANU_ON = 6339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_MANU_OFF = 6340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_MANU_INC = 6341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_MANU_DEC = 6342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AGC_GAIN_UP_LOW = 6343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AGC_GAIN_UP_MID = 6344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AGC_GAIN_UP_HIGH = 6345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MANU_GAIN_UP_LOW = 6346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MANU_GAIN_UP_MID = 6347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MANU_GAIN_UP_HIGH = 6348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_MODE_AUTO = 6349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_MODE_PRESET = 6350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_START = 6351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_END = 6352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_MASK_ON = 6353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_MASK_OFF = 6354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_MASK_CLEAR = 6355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_MASK_REVERSE = 6356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_CURSOR_UP = 6357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_CURSOR_RIGHT = 6358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_CURSOR_DOWN = 6359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BLC_SETUP_CURSOR_LEFT = 6360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AWC_ON = 6361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AWC_RESET = 6362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ATW_ON = 6363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BURST_PHASE_UP_PLUS = 6364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BURST_PHASE_UP_MINUS = 6365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BURST_PHASE_STOP = 6366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_BURST_PHASE_RESET = 6367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_LINE_LOCK_ON = 6368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_LINE_LOCK_OFF = 6369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_EL_ZOOM_ON = 6370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_EL_ZOOM_OFF = 6371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PRIVACY_ZONE_ON = 6372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PRIVACY_ZONE_OFF = 6373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MOTION_DETECT_ON = 6374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_MOTION_DETECT_OFF = 6375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_D_RANGE_ON = 6376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_D_RANGE_OFF = 6377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALL_RESET = 6378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DIGITAL_FLIP_ON = 6379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DIGITAL_FLIP_OFF = 6380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_FOCUS_AREA_SMALL = 6381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_FOCUS_AREA_MIDDLE = 6382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_FOCUS_AREA_LARGE = 6383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_REVERSE = 6384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_LEFT_END_SET = 6385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_RIGHT_END_SET = 6386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_SPEED_INC = 6387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_SPEED_DEC = 6388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_ENDLESS_ON = 6389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_ENDLESS_OFF = 6390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_KEY_AUTO_PAN = 6391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_KEY_SEQ = 6392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_PAN_SORT = 6393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PROPO_PAN_TILT_ON = 6394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PROPO_PAN_TILT_OFF = 6395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PAN_LIMIT_ON = 6396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PAN_LIMIT_OFF = 6397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PRESET_SEQ_OFF = 6398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PRESET_MODE_SEQ_ON = 6399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PRESET_MODE_SORT_ON = 6400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_PRESET_POS_ID_ALLCLEAR = 6401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_1_OFF = 6402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_1_POSI = 6403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_2_OFF = 6404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_2_POSI = 6405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_3_OFF = 6406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_3_POSI = 6407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_4_OFF = 6408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_4_POSI = 6409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ALARM_IN_4_BW = 6410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CONT_1_OFF = 6411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CONT_1_AUX_1 = 6412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CONT_1_VMD = 6413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CONT_2_OFF = 6414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CONT_2_AUX_2 = 6415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CONT_2_BW = 6416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOPAN_STARTPOINT = 6417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOPAN_ENDPOINT = 6418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOMODE_OFF = 6419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SUPER_DII_ON = 6420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SUPER_DII_OFF = 6421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_AUTOFOCUSON = 6422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AREATITLE_NESW_ON = 6423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AREATITLE_USER_ON = 6424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AREATITLE_OFF = 6425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_REFRESH = 6426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_FIX_ON = 6427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_FIX_OFF = 6428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_FIX_INC = 6429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SENS_UP_FIX_DEC = 6430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_LL_PHASE_INC = 6431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_LL_PHASE_DEC = 6432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CLEANING_ON = 6433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CLEANING_OFF = 6434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_START_PRESETSEQ = 6435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_STOP_PRESETSEQ = 6436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTO_IRIS = 6437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_AXISDLG_AUTO_FOCUS = 6438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_ADDRESSSET = 6439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IFCLEAR = 6440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_COMMANDCANCEL = 6441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_CAMPOWERON = 6442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_CAMPOWEROFF = 6443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOFOCUSON = 6444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MANUALFOCUSON = 6445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_WHITEBALANCEAUTO = 6446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_WHITEBALANCEINDOOR = 6447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_WHITEBALANCEOUTDOOR = 6448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_WHITEBALANCEONEPUSHMODE = 6449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_WHITEBALANCEONEPUSHTRIGGER = 6450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOEXPOSUREFULLAUTO = 6451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOEXPOSUREMANUAL = 6452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AESHUTTERPRIORITY = 6453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AEIRISPRIORITY = 6454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AEBRIGHTMODE = 6455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BRIGHTRESET = 6456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BRIGHTUP = 6457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BRIGHTDOWN = 6458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_SHUTTERRESET = 6459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_SHUTTERUP = 6460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_SHUTTERDOWN = 6461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IRISRESET = 6462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IRISUP = 6463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IRISDOWN = 6464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_GAINRESET = 6465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_GAINUP = 6466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_GAINDOWN = 6467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BACKLIGHTON = 6468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BACKLIGHTOFF = 6469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_KEYLOCKOFF = 6470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_KEYLOCKON = 6471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IRRECEIVEON = 6472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IRRECEIVEOFF = 6473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IRRECEIVERETURNON = 6474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_IRRECEIVERETURNOFF = 6475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_HOME = 6476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_PANTILTRESET = 6477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_DATASCREENON = 6478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_DATASCREENOFF = 6479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AEFORTARGETON = 6480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AEFORTARGETOFF = 6481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOZOOMFORTARGET = 6482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOZOOMFORTARGETOFF = 6483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_FRAMEDISPLAYFORTARGETON = 6484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_FRAMEDISPLAYFORTARGETOFF = 6485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETOFFSETON = 6486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETOFFSETOFF = 6487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETSTARTSTOP = 6488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETCHASE1 = 6489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETCHASE2 = 6490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETCHASE3 = 6491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETCHASE123 = 6492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETENTRY1 = 6493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETENTRY2 = 6494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETENTRY3 = 6495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTARGETENTRY4 = 6496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONON = 6497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONOFF = 6498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONFRAME = 6499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONDETECT1212 = 6500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTOTRACKINGLOSTINFO = 6501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONLOSTINFO = 6502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONMEASUREMODE1ON = 6503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONMEASUREMODE1OFF = 6504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONMEASUREMODE2ON = 6505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MOTIONDETECTIONMEASUREMODE2OFF = 6506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_CAMORIENTATION_NONINVERTED = 6507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_CAMORIENTATION_INVERTED = 6508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_CAMPWRPOR_ON = 6509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_CAMPWRPOR_OFF_AT_POR = 6510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_CAMPWRPOR_OFF = 6511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_CYCLE_WIPER = 6512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_IRLAMP_ON = 6513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_IRLAMP_OFF = 6514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISION_SLOWSHUTTER_ON = 6515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISION_SLOWSHUTTER_OFF = 6516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISION_CAM_STABILIZATION_ON = 6517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISION_CAM_STABILIZATION_OFF = 6518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZCOMMAND_MENU_ON = 6519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZCOMMAND_MENU_OFF = 6520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZDLG_TOOLTIP_MENUENTER = 6521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZDLG_TOOLTIP_MENUUP = 6522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZDLG_TOOLTIP_MENUDOWN = 6523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZDLG_TOOLTIP_MENULEFT = 6524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZDLG_TOOLTIP_MENURIGHT = 6525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZDLG_TOOLTIP_ZOOMIN = 6526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZDLG_TOOLTIP_ZOOMOUT = 6527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_COMMAND_PTZ_STOP_ZOOM = 6528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_MOUNT_CEILING = 6529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_MOUNT_DESKTOP = 6530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_TOURRESUME_ON = 6531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_TOURRESUME_OFF = 6532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTO_PANTILT_SPEED_ON = 6533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTO_PANTILT_SPEED_OFF = 6534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_COLOR_COLOR = 6535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_COLOR_BLACK = 6536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_CAMERA_INIT = 6537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHTMODE_DISABLE = 6538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHTMODE_AUTO = 6539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHTMODE_MANUAL = 6540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHTMODE_TIMER = 6541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_ON = 6542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_OFF = 6543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_INIT_COMPSCAN = 6544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_CUSTOM_RESET = 6545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_CUSTOM_SET = 6546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_DISPINFO_ON = 6547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_DISPINFO_OFF = 6548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_TALLY_ON = 6549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_TALLY_OFF = 6550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_TILTREVERSE_ON = 6551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_TILTREVERSE_OFF = 6552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_PANREVERSE_ON = 6553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_PANREVERSE_OFF = 6554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_DATAMIX_ON = 6555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_DATAMIX_OFF = 6556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_PICTUREFLIP_ON = 6557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_PICTUREFLIP_OFF = 6558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_RGAIN_RESET = 6559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_RGAIN_UP = 6560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_RGAIN_DOWN = 6561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BGAIN_RESET = 6562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BGAIN_UP = 6563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_BGAIN_DOWN = 6564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_EXPOSURECOMPENSATION_ON = 6565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_EXPOSURECOMPENSATION_OFF = 6566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_EXPOSURECOMPENSATION_RESET = 6567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_EXPOSURECOMPENSATION_UP = 6568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_EXPOSURECOMPENSATION_DOWN = 6569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_APERTURE_RESET = 6570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_APERTURE_UP = 6571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_APERTURE_DOWN = 6572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_PICTUREEFFECT_OFF = 6573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_PICTUREEFFECT_NEGART = 6574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_PICTUREEFFECT_MONO = 6575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_WIDE_OFF = 6576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYBRC300_SPCDLG_WIDE_ON = 6577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHTMODE_SENSOR = 6578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_SENSOR1_ON = 6579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_SENSOR1_OFF = 6580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_SENSOR2_ON = 6581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_SENSOR2_OFF = 6582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_MANUAL_FUNC_ON = 6583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONYRZ30_SPCDLG_DAYNIGHT_MANUAL_FUNC_OFF = 6584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_EC_ON = 6585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_EC_OFF = 6586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_EC_RESET = 6587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_EC_UP = 6588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_EC_DOWN = 6589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AE_SPOT_ON = 6590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AE_SPOT_OFF = 6591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MIRROR_IMAGE_ON = 6592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_MIRROR_IMAGE_OFF = 6593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_FREEZE_IMAGE_ON = 6594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_FREEZE_IMAGE_OFF = 6595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTO_INFRARED_ON = 6596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AUTO_INFRARED_OFF = 6597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_IR_ON = 6598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_IR_OFF = 6599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_AUTO_IR_ON = 6600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_AUTO_IR_OFF = 6601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_IMAGE_FLIP_ON = 6602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_CAM_IMAGE_FLIP_OFF = 6603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_COORDINATESOFF = 6604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_COORDINATESON = 6605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_FLASHBACK = 6606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_BACKSPACE = 6607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_0 = 6608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_1 = 6609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_2 = 6610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_3 = 6611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_4 = 6612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_5 = 6613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_6 = 6614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_7 = 6615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_8 = 6616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_CHAR_9 = 6617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_DOWN = 6618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_ENTER = 6619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_ESCAPE = 6620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_LEFT = 6621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_RIGHT = 6622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_MENU_UP = 6623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_NIGHTMODE = 6624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAK_SWITCH_BLC = 6625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_RESET = 6626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_HOME = 6627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_DEFINETOUR = 6628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_STARTTOUR = 6629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_STOPTOUR = 6630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_TOGGLEBW = 6631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_AUTOBW = 6632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_CAMERASETUP = 6633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VCL_PRIVACY = 6634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTO_IRIS = 6635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_HALT = 6636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_TEXTFILES = 6637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_PROTOCOL = 6638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_ADDRESS = 6639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LINKS = 6640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPEAKER = 6641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MICROPHONE = 6642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PATH_INVALID = 6643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_PLUGIN = 6644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PTZ_COORDINATES = 6645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRANSFER_VIDEO = 6646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_APPLIES_TO = 6647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_APPLIES_TO_BOTH_SIDES = 6648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_APPLIES_TO_INDIVIDUAL_SIDES = 6649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_NO_READER_FOR_SIDE_X = 6650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IDLE_DELAY = 6652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCK_DELAY = 6653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_NETWORK_LOCATION = 6654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILTER_TEXTFILES = 6656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILENAME_DEFAULT_FIRMWAREUPGRADE_LOG = 6657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTROLLED_BY = 6658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_CONTROLLING_UNIT = 6659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_CONTROLLING_ZONEMANAGER = 6660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_CONTROLLING_UNIT_COPIE = 6661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESSCONTROL_ZONE = 6662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESSCONTROL_ZONE_DESCRIPTION = 6663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOFTWARE_ZONE = 6664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOFTWARE_ZONE_DESCRIPTION = 6665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_PTZ_NO_SERIAL_PORT = 6666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_NIREMITTER_ON = 6667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_RVISON_NIREMITTER_OFF = 6668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REFRESH_DRIVE_INFO = 6669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AF_SENSITIVITY_NORMAL = 6670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AF_SENSITIVITY_LOW = 6671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AF_MODE_NORMAL = 6672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AF_MODE_ZOOM_TRIGGER = 6673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_INIT_LENS = 6674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AEAUTO = 6675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AEMANUAL = 6676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GROUPBOX_ZONE_ARMING_SOURCE = 6677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GROUPBOX_ZONE_ARMING_DELAYS = 6678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GROUPBOX_ZONE_ARMING_BUZZER = 6679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRANSFER_VIDEO_PROGRESS = 6680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_AF_MODE_INTERVAL = 6681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SONY_SPCDLG_WHITEBALANCEMANUAL = 6682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCDLG_WHITEBALANCEATW = 6683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LMW_SLOW_SHUTTER_AUTO = 6684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SLOW_SHUTTER_MANUAL = 6685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADDITIONAL_SETTINGS = 6686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_GUARDTOUR_ONLOGON = 6687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_THUMBNAIL = 6688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CASH_REGISTER_PROPERTIES = 6689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CASH_REGISTER_ID_ALREADY_EXISTS = 6690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MENU_MODE = 6691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRANSFER_VIDEO_ERROR_OVERLAP = 6695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRANSFER_VIDEO_PROGRESS_COMPLETE = 6697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_UNIT_EXTENSION_TYPE = 6699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_UNIT_VERTX = 6700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CREATE_FOLDER = 6701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SWAP = 6703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HOLD_MOUSEDOWN_TO_EXECUTE_INSTANTLY = 6706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ACTION_DOOR_SET_NEW_SCHEDULE = 6707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ACTION_DOOR_REMOVE_SCHEDULE = 6708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_OR_OVERRIDE_DOOR_SCHEDULE = 6709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_INFO_OVERRIDE_DOOR_SCHEDULE = 6710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMUM_DURATION = 6711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_FOR_DARK_IMAGES = 6712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_LEVEL = 6713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNIT_SWAP_UTILITY = 6714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_ARM = 6715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECURSIVE_ACTION_WARNING_FORMAT = 6716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PROCEED = 6717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_ARMED = 6720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_DISARMED = 6721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_EXTENSION_SELECTED = 6722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_ARM_RECURSIVE = 6723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_DISARM_RECURSIVE = 6724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_MASTER_ARM_RECURSIVE = 6725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_OVERRIDE_SCHEDULE_RECURSIVE = 6726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_PERIMETER_ARM_RECURSIVE = 6727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_ADDBOOKMARK_RECURSIVE = 6728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_STARTRECORDING_RECURSIVE = 6729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_STOPRECORDING_RECURSIVE = 6730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_DELETE_SERVER_ROLES_ASSOCIATION = 6731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LINE_IN = 6732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIC_IN = 6733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_DELETE_USERGROUP_WITH_MEMBERS = 6734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOW_STREAM = 6735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERIAL = 6736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IN = 6737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUT = 6738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_UNITDEVICES_NAMES = 6739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SHOWALARMPAGE = 6740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_TOGGLE_RECORDING = 6741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SWITCH_TO_LIVE = 6742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SWITCH_TO_PLAYBACK = 6743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SELECT_PREVIOUS_TILE = 6744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SELECT_NEXT_TILE = 6745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_DISPLAYENTITY = 6747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_DISPLAYCONTENT = 6748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_CYCLE_PREVIOUS_PATTERN = 6749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_CYCLE_NEXT_PATTERN = 6750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_DBCONNECTIONSUCCES_FORMAT = 6752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_DBCONNECTIONFAILED_FORMAT = 6753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_INITIALIZATIONFAILEDSPECIFIC_FORMAT = 6754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_INITIALIZATIONFAILED_FORMAT = 6755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_ROLE_STOPPED = 6756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_DETECTIONSUCCESS = 6757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_DETECTIONFAILED = 6758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_LOADFAILED = 6759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_STARTSUCCESS = 6760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_STARTFAILED = 6761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_BACKUPSTARTED = 6762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_BACKUPSUCCEEDED = 6763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_BACKUPFAILED = 6764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_PROTECTIONTHRESHOLDEXCEEDED = 6765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CCTV_KEYBOARD_CONNECTED = 6766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_DEFAULT_LOGIN = 6767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTED_VIDEO_FILES_STATS = 6768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_LOGICALIDEXIST = 6769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_STOP_SYNCHRONIZING_VIDEO = 6770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZ_UNLOCK = 6771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECENTLY_USED = 6773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGICAL_VIEW_ENTITY_TYPES = 6774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_VIEW_ENTITY_TYPES = 6776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ACCESS_TROUBLESHOOTING = 6777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANT_DRAW_CHART = 6778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HEALTH_HISTORY = 6779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DOOR_UNLOCK = 6781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOAD_PERCENTAGE = 6783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNTIL = 6785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERCENTAGE = 6786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCKED_BY = 6787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ABBREVIATION_GIGABYTES = 6788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AFTER_LC = 6794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_READING_FILE = 6795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_EDGE_ANALYTICS_CAMERAS = 6796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_CANCELOVERRIDE_RECURSIVE = 6797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ACTIVITY_TRAIL_ADD = 6800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_USER_CONTROL = 6801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CHANGES_FORCE_REBOOT = 6802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_LOCATION_ALREADY_ADDED = 6803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESOURCES = 6804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_WATERMARK_NOT_SUPPORTED_BY_ALL_SEQUENCES = 6805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEPEVENTHISTORY = 6806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEPAUDITTRAILS = 6807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEPINCIDENTS = 6808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONLYDISPLAYCHECKEDITEMS = 6809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAYALLITEMS = 6810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETODELETEINUSEINFO = 6811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_STOPANDDELETE = 6812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLE_GROUP = 6813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADVANCED_SETTINGS = 6814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THUMBNAIL_BAR = 6816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_ALARMS = 6817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEANUP_PERIOD = 6818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEANUP_DUE_TIME = 6819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CAMERAACTIVITYREPORT = 6820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ARCHIVERACTIVITYREPORT = 6821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIDEOFILEREPORT = 6822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_LPRMANAGER_NAME = 6823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNAL_SYSTEM_DESCRIPTION_LPRMANAGER = 6824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PROTECT_ARCHIVES = 6825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DATABASE_NEEDUPGRADE = 6826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_RECORDED_VIDEO = 6827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_RTPSERVERSUCCESS_FORMAT = 6828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_RTPSERVERFAILED_FORMAT = 6829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_AGENTSTARTED_FORMAT = 6830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_AGENTSTOPPED_FORMAT = 6831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_SERVERNOTRUNNING_FORMAT = 6833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_CREATEAGENTFAILED_FORMAT = 6834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_CREATEDEFAULTAGENTFAILED = 6835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_AGENTSTARTFAILED_FORMAT = 6836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_AGENTSTARTFAILED = 6837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTHENTICATIONFAILED_PASSWORDERRORINFO = 6838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HOSTUNREACHABLE_CONNECTION_NOINFO = 6839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HOSTUNREACHABLE_CONNECTION_INFO = 6840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HOSTUNREACHABLE = 6841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_DETECTION_LOCATION = 6842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_MOTION_ZONE = 6843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_MOTION_ON_THRESHOLD = 6844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_MOTION_OFF_THRESHOLD = 6845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_MOTIONDETECTION_TEST_ZONE = 6846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_MOTIONDETECTION_TEST_ALL_ZONES = 6847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_MOTIONDETECTION_VIEW_ALL_MOTION = 6848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_MOTION_ON_EVENT = 6849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_MOTION_OFF_EVENT = 6850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_MOTION_EVENTS = 6851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_ADVANCED_SETTINGS = 6852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_LUMA_WEIGHT = 6853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_CHROMA_WEIGHT = 6854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_VECTORS_WEIGHT = 6855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_MACROBLOCKS_WEIGHT = 6856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_LEARNING_MODE = 6857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_REPORTPANE = 6859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_CANVASPANE = 6860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_REPORTANDCANVASPANE = 6861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SILENCE_SOUND_BUZZER = 6865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MAINTENANCE_MODE = 6866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_EMAIL_REPORT = 6867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_USE_ASF_FORMAT = 6868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DISPLAY_ENTITY_IN_SD = 6869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RENAME_TASK = 6870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_TASK = 6871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_ALL_TASKS = 6872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_ALL_OTHERTASKS = 6873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTICAST_INTERFACE = 6874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_COULD_NOT_ADD_FOLDER = 6875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FOLDER_NOT_ACCESSIBLE = 6876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FOLDER_MUST_BE_WRITABLE = 6877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PORT_ALREADY_EXISTS = 6878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CASH_REGISTER_MAPPINGS_INVALID = 6879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHORTCUT_USED = 6881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CONNECT = 6882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISCONNECT = 6883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXIT = 6887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_SELECTION_CHANGED = 6891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_RESTART_APPLY = 6892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_ACTIONS_APPLY = 6893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_WATERMARKED = 6894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_WATERMARKED_AUTHENTICATED = 6896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_TAMPERED = 6897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SERVER_RUNNING_ROLE = 6898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALIDATE_FILE = 6899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALIDATING = 6900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_RECORDING = 6901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_AUDIO = 6902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DELETE_SPECIFIC_DATABASE = 6903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOW = 6904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RESET_PEOPLE_COUNT = 6908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_AREAS_GROUP = 6909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_PATTERN_STOP_REC = 6910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_PORT_RANGE = 6911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_UPNP = 6912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CLICK_TO_MODIFY = 6913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_PPROTECTION_STATUS = 6914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEP_EXPORT_IN_PROGRESS = 6916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVING_PROBLEM_LICENSEDENIED = 6917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVING_PROBLEM_PERMISSIONDENIED = 6918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_2CIFEXP = 6919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_2CIFV = 6920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SOURCE_IP_CONFLICT = 6921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVEENCODER_H264ANDMJPEG = 6922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVEENCODER_JPEG = 6923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVEENCODER_BOTH = 6924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_DISK_GROUP_SHOULD_BE_EMPTY = 6925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POS_TRANSACTION_HEADER_TABLE = 6926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POS_TRANSACTION_DETAILS_TABLE = 6927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POS_FETCH_TRANSACTIONS_EVERY = 6928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIO_OUPUT = 6929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RTP_STREAMING_MODE = 6930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VOLUME = 6931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENSIVITY = 6932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARENTHESES_FORMAT = 6933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_INCOMPATIBLE_SCHEDULE = 6934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_INCOMPATIBLE_SCHEDULE_OPTIONS = 6935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_INCOMPATIBLE_SCHEDULE_OPTIONS_MIX_YEARLY_MONTHLY = 6936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_INCOMPATIBLE_SCHEDULE_OPTIONS_DAY_BEFORE = 6937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_INCOMPATIBLE_SCHEDULE_OPTIONS_DAY_AFTER = 6938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_STREAMS = 6939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STREAM_CODEC = 6940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEE_DETAILS = 6941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTECTION_ENDING = 6942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_OMNICAST_METADATA_ENGINE = 6943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISKGROUP = 6944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECODER_X = 6945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WINDOWS_LAYOUT = 6946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_LUMA_EMPHASIS = 6947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_VECTOR_EMPHASIS = 6948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_H264_PRESET = 6949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DBALREADYEXIST_OVERWRITE = 6951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GENERATING_THUMBNAILS = 6952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_THUMBNAILS_NOT_SUPPORTED = 6953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_UTCMINUS11_STANDARDABBR = 6954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PARAGUAY_STANDARDABBR = 6955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PARAGUAY_DAYLIGHTABBR = 6956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARGENTINA_STANDARDABBR = 6957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ARGENTINA_DAYLIGHTABBR = 6958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_UTCMINUS02_STANDARDABBR = 6959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOROCCO_STANDARDABBR = 6960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MOROCCO_DAYLIGHTABBR = 6961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_UTC_STANDARDABBR = 6962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SYRIA_STANDARDABBR = 6963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_SYRIA_DAYLIGHTABBR = 6964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MAURITIUS_STANDARDABBR = 6965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_MAURITIUS_DAYLIGHTABBR = 6966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PAKISTAN_STANDARDABBR = 6967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_PAKISTAN_DAYLIGHTABBR = 6968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_BANGLADESH_STANDARDABBR = 6969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_BANGLADESH_DAYLIGHTABBR = 6970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ULAANBAATAR_STANDARDABBR = 6971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_ULAANBAATAR_DAYLIGHTABBR = 6972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_UTCPLUS12_STANDARDABBR = 6973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_KAMCHATKA_STANDARDABBR = 6974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_KAMCHATKA_DAYLIGHTABBR = 6975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CUSTOM_FIELD_IS_SELECTED = 6976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_FIELD = 6977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_FIELDS_SELECTED = 6978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLEASE_WAIT_DOWNLOADING_ENTITIES = 6979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHITE_BALANCE = 6981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENSOR_TYPE = 6982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENSORTYPE_INTERLACED = 6983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENSORTYPE_PROGRESSIVE = 6984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IQEYE_NUMBER_OF_VCAMS = 6985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DB_INFO_EVENT_COUNT = 6986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DB_INFO_SOURCE_COUNT = 6987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DB_INFO_VIDEOFILE_COUNT = 6988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DB_INFO_VIDEOFILELOST = 6989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DB_INFO_SIZEONDISK = 6990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_QUADVIEW = 6991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_SINGLEVIEW = 6992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_VIDEO_FILES = 6993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_MANAGER = 6994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALOG_FORMAT = 6995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGING_SETTING_REQUIRE_REBOOT = 6996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_APPLICATION_NO_CAMERA_DISPLAYED = 6998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LOAD_DEFAULT = 6999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_SCHEDULE = 7001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_AUDIO = 7002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OTHER_PARAMETERS = 7004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_ROTATION = 7005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUID = 7006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSION_IN_PROGRESS_EXIT = 7007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLE_DATABASE = 7008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POS_DATABASE = 7009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RS232 = 7010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RS485 = 7011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RS422485 = 7012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_IN_PROGRESS_EXIT = 7013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSTPONE_FOR_X_SECONDS = 7015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_ACTION_FAILED = 7016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_DETECT = 7017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_TYPE_INTERNAL = 7018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING_DELAY_TYPE_INSTANT = 7019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING_DELAY_TYPE_DELAYED = 7020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYINTRUSIONAREAS = 7021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEINTRUSIONAREAS = 7022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_DIAGNOSTIC_INFORMATION = 7023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_BYPASSED = 7024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_HAVE_THE_SAME_DISCOVERY_PORT = 7025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WAITING_FOR_RESULTS = 7026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RESULT_WILL_REFRESH_IN = 7027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REFRESH_NOW = 7028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_OPERATION = 7030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_MONITORING = 7031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CARDHOLDER_MANAGEMENT = 7032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_VISITOR_MANAGEMENT = 7033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_PEOPLE_COUNTING = 7034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CREDENTIAL_ENROLLMENT = 7035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_INVENTORY_MANAGEMENT = 7036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_INVESTIGATION = 7037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_AREA_ACTIVITIES = 7038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_DOOR_ACTIVITIES = 7039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CARDHOLDER_ACTIVITIES = 7040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_VISITOR_ACTIVITIES = 7041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_AREA_PRESENCE = 7042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_TIME_AND_ATTENDANCE = 7043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CREDENTIAL_ACTIVITIES = 7044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ELEVATOR_ACTIVITIES = 7045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_VISIT_DETAILS = 7046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_INTRUSION_DETECTION_AREA_ACTIVITIES = 7047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_INTRUSION_DETECTION_UNIT_EVENTS = 7048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_HITS = 7049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_READS = 7050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ROUTE_PLAYBACK = 7051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_INVENTORY_REPORT = 7052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_DAILY_USAGE_PER_PATROLLER = 7053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_LOGONS_PER_PATROLLER = 7054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_READSHITS_PER_DAY = 7055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_READSHITS_PER_ZONE = 7056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ZONE_OCCUPANCY = 7057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ARCHIVES = 7058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_BOOKMARKS = 7059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_MOTION_SEARCH = 7060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_VIDEO_FILE_PLAYER = 7061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_INCIDENTS = 7062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_TRANSACTIONS = 7063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ZONE_ACTIVITIES = 7064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_MAINTENANCE = 7065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ACCESS_CONTROL_UNIT_EVENTS = 7066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CARDHOLDER_ACCESS_RIGHTS = 7067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_DOOR_TROUBLESHOOTER = 7068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ACCESS_CONTROL_UNIT_CONFIGURATION = 7069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ACCESS_RULE_CONFIGURATION = 7070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CARDHOLDER_CONFIGURATION = 7071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CREDENTIAL_CONFIGURATION = 7072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_IO_CONFIGURATION = 7073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CAMERA_EVENTS = 7074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ARCHIVER_EVENTS = 7075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ARCHIVE_STORAGE_DETAILS = 7076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_SYSTEM_STATUS = 7077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_AUDIT_TRAILS = 7078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_HEALTH_HISTORY = 7079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ALARM_MANAGEMENT = 7080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ALARM_REPORT = 7082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GOHOME = 7084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_SSL = 7085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DIAGNOSE = 7086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCKED_BY_ON_MACHINE = 7087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SERVER_AUTHENTICATIONFAILED = 7088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_TASK_COUNT_REACHED = 7089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_IS_CONNECTED = 7091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_THE_DISK_DOES_NOT_EXIST = 7092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ROLEDATABASE_CONNECTED = 7093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PLUGINDATABASE_CONNECTED = 7094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PLUGINDATABASE_DISCONNECTED = 7095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_LINEDRIVERMODE_RS422 = 7096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_LINEDRIVERMODE_RS4854WIRES = 7097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_LINEDRIVERMODE_RS4852WIRES = 7098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SERIALMODE_RECEIVER = 7099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SERIALMODE_TRANSMITTER = 7100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SERIALMODE_BIDIRECTIONAL = 7101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PELCO_PRESET_VERYHIGH = 7102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PELCO_PRESET_HIGH = 7103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PELCO_PRESET_MEDHIGH = 7104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PELCO_PRESET_MEDLOW = 7105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PELCO_PRESET_LOW = 7106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PELCO_PRESET_VERYLOW = 7107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOQUALITY_4CIFFULLMOTION30FPS = 7108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_AUDIO_ALARM = 7109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PLEASE_ENTER_VALID_IP = 7110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISABLED = 7111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOW = 7112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEDIUM = 7113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIGH = 7114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_NOTRUNNING = 7115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DATABASE_NEEDUPGRADE_WITHINFO = 7116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_ZONE_FORCED_ARMING = 7117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCODERS_DELETED = 7118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_MULTICAST = 7121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_REDIRECTORINITIALIZATIONFAILED = 7122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_REDIECTORRTSPPORTUNAVAILABLE = 7123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_CONFIGURATIONPROVIDERNOTYETSTARTED = 7124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_REDIECTORCONFIGURATIONADDRESSCONFLICT = 7125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTENSION_CONFIG_USE_DNS = 7126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_USE_SECURE_COMMUNICATION = 7127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG320_MPEG4320 = 7128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG320_MPEG4640 = 7129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG384_MPEG4384 = 7130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG384_MPEG4640 = 7131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG640_MPEG4320 = 7132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG640_MPEG4384 = 7133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG640_MPEG4640 = 7134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG768_MPEG4OFF = 7135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG768_MPEG4640 = 7136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG960_MPEG4OFF = 7137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG1280_MPEG4OFF = 7138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG1280_MPEG4320 = 7139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG1280_MPEG4384 = 7140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MJPEG1280_MPEG4640 = 7141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VMF_ANALYTICS = 7142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_TAMPERING_SENSIBILITY = 7143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COAXITRON_PTZ = 7144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_AGENTSTARTING = 7145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_SYNCHRONIZATION_ERROR = 7146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_SYNCHRONIZATION_ERROR_RETRY_IN = 7147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_AUTOSEEK_MODE = 7148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRINTEVIDENCE_CONFIRM = 7149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEPALARM = 7150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_PATH = 7151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_SOME_INCOMPATIBLE_SCHEDULE = 7152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_ACTIVITYTRAILS = 7153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ACTIVITY_TRAILS = 7154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_REPORTS_ACTIVITYTRAILS = 7155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONTYPE_CONFIGTOOL = 7156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONTYPE_SECURITYDESK = 7157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONTYPE_WEBCLIENT = 7158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONTYPE_SDK = 7159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_FILTER_ACTIVITY_INITIATOR = 7160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_FILTER_ACTIVITY_IMPACTED = 7161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_FILTER_ACTIVITY_ACTIVITIES = 7162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_STATUS = 7163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_EXPIRATION = 7164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONCURRENT_LOGIN_LIMIT = 7165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_LOGON_SCHEDULE = 7166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SCHEDULE = 7168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ALLOWED = 7169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_BLOCKED = 7170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORKSTATIONS = 7171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_VRM = 7172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SERVER_URL = 7173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYBACK_MODE = 7174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VRM_PLAYBACK_ISCSI_CONNECTION = 7175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VRM_PLAYBACK_UDP_REDIRECTION = 7176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VRM_PLAYBACK_TCP_REDIRECTION = 7177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTION_HIGHER_THAN = 7178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODIFYLICENSE = 7179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_PLUGIN_NAME = 7180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_PLUGIN_DESCRIPTION = 7181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEBACTIVATION = 7182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEBACTIVATION_DETAILS = 7183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUALACTIVATION = 7184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUALACTIVATION_DETAILS = 7185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATINGSECURITYCENTER = 7186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUALACTIVATION_PASTEORBROWSE = 7187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSEMANAGEMENT = 7188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_VRM_LOGIN_PROPERTIES = 7190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITYCENTER_LICENSESERVER_COMMUNICATIONPROBLEM = 7191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITYCENTER_LICENSESERVER_CANTCONNECT = 7192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITYCENTER_LICENSESERVER_UNKNOWNPROBLEM = 7193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITYCENTER_LICENSESERVER_BUSY = 7194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITYCENTER_LICENSESERVER_BUSYDETAILS = 7195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITYCENTER_LICENSESERVER_CANTCONNECTDETAILS = 7196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REDUNDANT_ARCHIVING = 7197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STANDBY_ARCHIVING_PRIORITIES = 7198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER = 7199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_CONFLICTRESOLUTIONFILES = 7200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_STOPANDRESOLVECONFLICT = 7201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETORESOLVECONFLICTINUSE = 7202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASETORESOLVECONFLICTINUSEINFO = 7203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESOLVECONFLICTS = 7204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFLICTRESOLUTIONFILE = 7205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFLICTRESOLUTIONEXPLANATION = 7206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_EXPIRED_ADMIN_RESET = 7207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_WILL_EXPIRE_CHANGE_IT_NOW = 7210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITYTRAIL_EXPORT_VIDEO_REQUESTED = 7211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SUPERVISION = 7212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISOR_OF = 7213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISED_BY = 7214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INHERITED_SUPERVISION = 7215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_OPEN_TASK = 7216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_LOGON_DIALOG = 7217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICT_ACCESS_TO_CONNECTION_PARAMS = 7218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_CURRENT_WINDOWS_CREDENTIALS_BY_DEFAULT = 7219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_SELECTION_CHANGEDREMOTE = 7220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_SELECTION_CHANGEREMOTEDETAILS = 7221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DABASE_LOCATIONMISMATCHSERVERLOCATION = 7222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPLACE = 7223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILEDEVICES = 7224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATES = 7225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUBLISHER = 7226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMINTOOL_CERTIFICATEMAXCONNECTIONS = 7227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_INITIATOR_ENTITY_NAME = 7228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_INITIATOR_ENTITY_TYPE = 7229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_INITIATOR_ENTITY_VERSION = 7230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_INITIATOR_MACHINE_NAME = 7231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_INITIATOR_APPLICATION_TYPE = 7232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_INITIATOR_APPLICATION_VERSION = 7233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_ACTIVITYNAME = 7234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_IMPACTED_ENTITY_NAME = 7235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_IMPACTED_ENTITY_TYPE = 7236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_IMPACTED_ENTITY_VERSION = 7237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PRINT_SNAPSHOT = 7242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_SAVE_SNAPSHOT = 7243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PLAYBACK_BOOKMARK_MODIFIED = 7245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PLAYBACK_BOOKMARK_DELETED = 7246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_VIDEO_UNIT_REBOOT = 7250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_VIDEO_UNIT_IDENTIFY = 7251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ENTITY_CONNECTION = 7253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ENTITY_DISCONNECTION = 7255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_PTZ_COMMAND = 7260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_VIDEO_EXPORT = 7261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMINISTRATIVE_RIGHTS = 7265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPTED_USER = 7266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTITION_MANAGERS = 7267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPTED_USERS_DESCRIPTION = 7268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTITION_MANAGERS_DESCRIPTION = 7269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CHOOSE_USER_ADMIN_RIGHT_OVER_PARTITIONNAME = 7270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CONFLICTRESOLUTION_IN_PROGRESS_X = 7271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CONFLICTRESOLUTION_SUCCEEDED = 7272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CONFLICTRESOLUTION_FAILED = 7273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_WATERMARK = 7274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ENCRYPTION_KEY = 7275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTAINS = 7276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BINDINGS = 7278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXECUTION = 7279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALS_OPERATION = 7280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SETTINGS = 7281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUMMARY = 7282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WELCOME = 7283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLOCKS = 7284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRAMES_UC = 7285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTTOOL_PREVIOUSVALUE = 7286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGIN_PASSWORDEXPIRED = 7287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGIN_SUPERVISORREQUIRED = 7288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGIN_DISALLOWEDBYSCHEDULE = 7289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGIN_EXCEEDEDWORKSATIONS = 7290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PTZ_GO_TO_PRESET = 7291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PTZ_SET_PRESET = 7292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PTZ_START_PATTERN = 7293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PTZ_RUN_PATTERN = 7294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PTZ_SPECIFIC_COMMANDS = 7295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DECRYPT_FILES = 7296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_TEST_DESCRIPTION = 7297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_SAVE_SNAPSHOT = 7298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PRINT_SNAPSHOT = 7299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTED_FILE = 7300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTION = 7301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_WARNING_TIME_OUT_OF_RANGE = 7303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDER_ACTIVATION_DATE = 7304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CARDHOLDER_EXPIRATION_DATE = 7305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_ACTIVATION_DATE = 7306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_EXPIRATION_DATE = 7307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_INTRUSIONUNITLICENSE = 7308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ENCRYPTING = 7309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DECRYPTING = 7310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FILE_NOT_ENCRYPTED = 7311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_SOURCE_FILE = 7312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_TARGET_FILE = 7313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_READ = 7314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WRITE = 7315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_CONNECTING = 7317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_ADDED = 7318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_ALREADY_ADDED = 7319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_ALREADY_EXISTING_ELSEWHERE = 7320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_TIMEOUT = 7321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_BAD_LOGIN = 7322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_LICSENSE_ERROR = 7323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_DISCOVERY_ERROR = 7324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_VIDEO_DECODER_NOT_SUPPORTED = 7325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_BAD_SETTINGS = 7326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_UNEXPECTED_ERROR = 7327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BETWEEN_X_AND_Y = 7328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FROM_X_TO_Y = 7329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_WELCOME = 7330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_CANCEL_CHANGES = 7331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_IMPORT_IN_PROGRESS = 7332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_REMOTE_FILE_WARNING = 7333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_ACTION_CONFIGURATION = 7334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_UPDATE_NEWPASSWORD = 7336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ENTITYVERSION = 7337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CATEGORY_ACTIVITY_TRAIL_VIDEO = 7338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CATEGORY_ACTIVITY_TRAIL_EVENTS = 7339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_TRAIL_CONFIGURATION_PAGE_DESCRIPTION = 7340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MOBILEDEVICESERVER_COUNT = 7341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MOBILEDEVICE_COUNT = 7342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESOLVINGCONFLICTS = 7343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PLAYBACK = 7344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DIRECTORYSTATUS = 7346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SHOWACTIONSPROGRESS = 7347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DATABASENOTCONNECTED = 7348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DIRECTORY_DIRECTORYSTARTED = 7349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DIRECTORY_DIRECTORYSTOPPED = 7350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_UNIT_TO_ROLENAME = 7351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPIREDMAPLICENSE = 7352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_MINIMUM_OF_CHARACTERS = 7354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_MINIMUM_OF_UPPERCASE = 7355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_MINIMUM_OF_LOWERCASE = 7356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_MINIMUM_OF_NUMERICAL = 7357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_MINIMUM_OF_SPECIAL = 7358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMFIELD_MUST_BE_UNIQUE = 7360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UNITSWAP_SAME_SOURCE_DESTINATION = 7361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UNITSWAP_SOURCE_IS_ONLINE = 7362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERTIFICATE_NOT_FOUND = 7364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERTIFICATE_COUNT_EXCEEDED = 7365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEVICE_MACHINE_NAME = 7366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_WILL_EXPIRE_TODAY_CHANGE_IT_NOW = 7368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BACKUP_DBSERVER_DBINSTANCE_DATABASE = 7369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CLIENT_CERTIFICATE_NOT_FOUND = 7370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_COMPLEXITY_NOT_RESPECTED = 7371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXECUTE_ON_HIGHEST_PRIORITY_SERVER = 7372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DELETEARCHIVERDATABASE_DELETEVIDEOFILES = 7373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXTENSION_DELETE_WARNING = 7374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITYCENTERDIRECTORY = 7375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_CLIENT_CERTIFICATE = 7376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DECRYPT = 7377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DECRYPTION_SETTINGS = 7378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECRYPTION_IN_PROGRESS = 7379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECRYPTION_COMPLETED = 7380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DECRYPTION = 7381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALIDWEBLICENSEURLSCHEME = 7382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DECRYPTION_IN_PROGRESS_EXIT = 7383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DECRYPTION_CLOSE = 7384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_EDITOR_SUPPORT = 7385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_ROLE_IS_NOT_ACTIVATED = 7386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WRONG_ENCRYPTION_KEY = 7387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LIVE_VIDEO_STREAM_NAME = 7388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_ACTIONS = 7389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MANIFESTFILETOOBIGDESCRIPTION = 7390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MANIFESTFILETOOBIG = 7391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALIDCONFLICTRESOLUTIONFILE = 7392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALIDCONFLICTRESOLUTIONFILEDESCRIPTION = 7393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERRORWHILEREADINGFILE = 7394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COVERAGE_NIGHTTIME = 7395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COVERAGE_DAYTIME = 7396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFSETS = 7397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUNSET = 7398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUNRISE = 7399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLUS = 7400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINUS = 7401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COVERAGE_ALL_DAY = 7402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COVERAGE_SUNRISE_OFFSET = 7404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COVERAGE_SUNSET_OFFSET = 7405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLUS_SIGN = 7406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINUS_SIGN = 7407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_MODIFICATION = 7408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HOTLIST_MODIFICATION_TASK_DESCRIPTION = 7409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANNOT_LOAD_CUSTOM_TASK = 7410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHANNEL_SECURITY = 7411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPT_CHANNEL = 7412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPT_NON_ENCRYPTED_MESSAGES = 7413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_THUMBNAIL_FOUND = 7414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURERESOLUTIONINHARDWARETAB = 7415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_PCM = 7416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G711 = 7417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_GSM0610 = 7418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G721 = 7419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G723_24 = 7420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G723_40 = 7421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G722 = 7422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G728 = 7423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G723_1 = 7424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT = 7425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALIDSYSTEMID = 7427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALIDPASSWORD = 7428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALIDVALIDATIONKEY = 7429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALIDVALIDATIONKEYDETAILS = 7430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STAR_MANDATORY_FIELDS = 7431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CUSTOM_FIELDS_INVALID = 7432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FOLLOWING_CUSTOM_FIELDS_INVALID = 7433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT_ACCEPT_REASONS = 7434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_CUSTOMFIELDNOTUNIQUE = 7435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCEPT_REASON_PROPERTIES = 7436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCEPT_REASON_GROUP_PROPERTIES = 7437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPT_REASONS = 7438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPT_REASON_IS_MANDATORY = 7439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_HOME_DISABLE = 7442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_HOME_SEEK_TO_PRESET = 7443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_HOME_RUN_TOUR = 7444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_HOME_RUN_PATTERN = 7445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOUR = 7446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ATTEMPTS_COUNT_BEFORE_LOCK_DOWN = 7447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER = 7448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_PASSWORD_SETTINGS = 7449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_EXPIRY_NOTIFICATION_PERIOD = 7450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_TOUR_STEPS = 7451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_TOUR_MAXIMUM_SPEED = 7452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_TOUR_ADD_STEP = 7453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_TOUR_CLEAR_STEP_LIST = 7454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLICATIONS = 7455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_NO_INFRACTION_OPTION = 7456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_CAMERA_AUTO_FLIP = 7457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_CAMERA_INFRARED = 7458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VCL_CAMERA_DIGITAL_ZOOM = 7459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXPORT_VIDEO_SELECTED_TILE = 7461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXPORT_VIDEO_ALL_TILES = 7462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCEPT_REASON_ADD_GROUP = 7463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCEPT_REASON_EDIT_GROUP = 7464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCEPT_REASON_ADD_REASON = 7465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCEPT_REASON_EDIT_REASON = 7466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REASONS = 7467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_LOAD_FILE = 7468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_DATE_FORMAT = 7469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DELIMITER_CONFLICT = 7470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FIELD_LENGTH_EXCEEDED = 7471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MISSING_DATE_FORMAT = 7472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_HOTLIST_PERMIT = 7473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONFLICTING_ENTRIES = 7474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_AUTOHIDE_TILE_NUMBER = 7475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_USERINTERACTION_ASKFORTASKDELETE = 7476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYFIELD_NAME = 7477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYFIELD_DISPLAY_NAME = 7478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPT_REASON_ALLOW_MULTIPLE_SELECTION = 7479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STREAMING_PORT = 7480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BASE_URI = 7481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSL_SETTINGS = 7482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE = 7483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BIND_CERTIFICATE_TO_PORT = 7484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RENDERING = 7485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_STREAM_NOT_FOUND = 7486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REQUEST_TIMED_OUT = 7487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OUT_OF_MEMORY = 7488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_PAN_VALUE = 7489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_TILT_VALUE = 7490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_ZOOM_VALUE = 7491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_DATA_AVAILABLE = 7492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DPI_SETTING = 7493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEVER_WARN_ME_AGAIN = 7494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGON_SUPERVISED_BY = 7495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGON_SUPERVISOR_OF = 7496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CUSTOM_FIELDS_NOT_INITIALIZED = 7497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTION_IN_PROGRESS = 7498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ENCRYPTION_SETTINGS = 7499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTION_COMPLETED = 7500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENCRYPTION_IN_PROGRESS_EXIT = 7501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_EXPIRES_TOMORROW_CHANGE_IT_NOW = 7503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENCRYPTION_CLOSE = 7504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PLAYBACK_WARNING_PRETRIGGER_TIME = 7505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_SHOW_OVERLAY_TASK_NAME = 7506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_CAMERA_VIDEO = 7507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOTE_ERROR_OCCURRED_WHILE_PROCESSING_THE_FILE = 7508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SOME_MANDATORY_VALUES_ARE_MISSING = 7509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_APPLICATIONID_CERTIFICATE = 7510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ENCRYPT = 7511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_PROVIDER = 7512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TEXT_FILE_PARTIALLY_LOADED = 7513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_INVALIDDBNAMEDESCRIPTION = 7514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KEYFIELD_CANNOT_BE_EMPTY = 7516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ONLY_ALPHANUMERIC_CHARACTERS_ARE_ACCEPTED = 7517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALUEFIELD_CANNOT_BE_EMPTY = 7518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_UPDATE_SUCCESS = 7520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_ROLLBACK_SUCCESS = 7521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_MISSINGKEYVALUE = 7526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_SET_CF_UNIQUE_ON_CREATION = 7527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DRILLDOWN_READONPLATE_FORMAT = 7528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOADING_FILE_X = 7529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_LICENSE_INVALID = 7530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_INVALID_CONTACT_ADMIN = 7531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_SUMMARY_STATUS = 7532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_SUMMARY_FILE = 7533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_SUMMARY_IMPORTED_ROWS = 7534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_SUMMARY_COMMENTED_ROWS = 7535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTTOOL_SUMMARY_NUMBER_OF_ERRORS = 7536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LINENUMBER = 7537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOOMANYERRORS = 7538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_STATUS = 7539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_SELECTED_CAMERA = 7540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_MOTION_MASK = 7541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TIMELIMIT_MUST_BE_HIGHER_THAN_ZERO = 7542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETECLATITUDELONGITUDE_EXAMPLE = 7543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_BACKUPRESTORE = 7544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_INTRUSION_UNIT = 7545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_INTRUSION_AREA = 7546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TRIGGER_NETWORK_DETECTION = 7547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_VIDEO_RECEIVING_RATE = 7549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_VIDEO_WRITING_RATE = 7550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_AUDIO_RECEIVING_RATE = 7551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_AUDIO_WRITING_RATE = 7552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AVAILABLE_DATABASE_DISK_SPACE_NOTIFICATION = 7553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELAY_ARM = 7554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_STANDARD_SCHEDULE = 7555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_TWILIGHT_SCHEDULE = 7556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SCHEDULE_INFORMATION = 7557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SCHEDULE_TIMERANGE_SELECTION = 7558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STANDARD_SCHEDULE_DESCRIPTION = 7559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TWILIGHT_SCHEDULE_DESCRIPTION = 7560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SCHEDULE_CREATION_INFORMATION = 7561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGING_AUTHENTICATION_REQUIRE_REBOOT = 7562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPANSIONTOMAIN_DESCRIPTION = 7563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ACTIVATEDIRECTORYROLE = 7564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DEACTIVATEDIRECTORYROLE = 7565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVATEDIRECTORYROLEDESCRIPTION = 7566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DEACTIVATEDIRECTORYROLEDESCRIPTION = 7567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANTCONNECTGS = 7569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANTCONNECTGS_DESC_PORTOUTOFRANGE_FORMAT = 7570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_DRIVE = 7571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGEREQUIREREBOOT_FORMAT = 7572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGEGENETECSERVERCONNECTPARAM_DESC = 7573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_VISITOR_SELECTED = 7574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGIN_CANTSUPERVISESELF = 7576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_NO_AGENT_RUNNING = 7577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_FORMAT = 7578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHAT_THE_NOTATIONS_MEAN = 7579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_FORMAT_NOTATION_HOUR_MINUTE = 7580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_FORMAT_NOTATION_SECOND = 7581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_FORMAT_NOTATION_AM_PM = 7582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_FORMAT_NOTATION_HOUR = 7583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_FORMAT_NOTATION_WITH_LEADING_ZERO = 7584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_FORMAT_NOTATION_WITHOUT_LEADING_ZERO = 7585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATE_FORMAT_NOTATION = 7586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUBNET_MASK = 7587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DISPLAY_OPTIONS = 7588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SUBNET_DISPLAY = 7589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_CIDR_BLOCK_DISPLAY = 7590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_USER_ACTIVE_DIRECTORY_ACCOUNT_DISABLED = 7591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_WEB_SDK = 7592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_FAILOVER_WARNING = 7593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMPORTERROR_NOREPLACECREDENTIAL = 7594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_INVALID_MULTICAST_CONFIGURATION = 7595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CREATE_DBSERVER_DBINSTANCE_DATABASE = 7596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DELETE_DBSERVER_DBINSTANCE_DATABASE = 7597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_SYNCHRONIZATION_IN_PROGRESS = 7598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_SYNCHRONIZATION_SUCCEEDED = 7599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPT_REASONS_RETAIN_VALUE = 7600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENFORCED_HIT_ATTRIBUTE = 7601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_ENFORCED_HIT_ATTRIBUTE = 7602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCED_HIT_ATT = 7603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_ACKNOWLEDGEMENT = 7604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_VIDEO_RECORDING = 7605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_DISPLAY_OPTION = 7606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_PROCEDURE_URL = 7607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DEASCTIVATE_ROLE = 7608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DEASCTIVATE_ROLES = 7609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MIGRATIONTOOL_APPNAME_SHORT = 7610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_H264MP = 7611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_COPYOTHERSTREAM = 7612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_H264BPPLUS = 7613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_H264MPSD = 7614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_H264MPFIXED720P = 7615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_H264MPFIXED720PFULLFRAMERATE = 7616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_H264MPFIXED1080P = 7617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_BOSCH_OPERATIONMODE_PROFILE = 7618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_CROPIMAGE = 7619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOIMAGESIZE = 7620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_PRIVILEGE_TO_ADD_UNIT_TO_ARCHIVER_PARTITION = 7621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_PROPERTIES_ADVANCED = 7622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOGGLE_OUT_OF_PROCESS_COMPONENTS = 7623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PRINT_REPORT = 7624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_EXPORT_REPORT = 7625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PRINT_HIT_REPORT_EVIDENCE = 7626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_EXPORT_REPORT_NAME_TYPE_LOCATION = 7627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PRINT_REPORT_NAME_TYPE_PRINTER = 7628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_FILTER_DESCRIPTION = 7631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_HEALTH_MONITORING_NAME = 7632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_HEALTH_MONITORING_DESCRIPTION = 7633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_HOTLIST_EDITOR = 7634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHMONITOR_DIAGNOSTIC_LOADFAILED = 7635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHMONITOR_DIAGNOSTIC_DBCONNECTIONSUCCESS_FORMAT = 7636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHMONITOR_DIAGNOSTIC_DBCONNECTIONFAILED_FORMAT = 7637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_IMPORTED_EVENT_TIMESTAMP = 7639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BATCH_FLOOR_CREATE = 7640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_COPY_CONFIG_SELECT_ENTITY_TYPE = 7641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_COPY_CONFIG_SELECT_SOURCE = 7642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_COPY_CONFIG_SELECT_OPTIONS = 7643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_COPY_CONFIG_SELECT_DESTINATIONS = 7644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_COPY_CONFIG_PROGRESS = 7645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_VIDEO_ENABLE_DEINTERLACE = 7646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LOAD_HOTLIST = 7647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ADD_RECORD_HOTLIST = 7648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_DELETE_RECORD_HOTLIST = 7649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MODIFY_RECORD_HOTLIST = 7650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_FILTERING = 7651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_INVALID_PLATE = 7652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_MODIFY = 7653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_REMOVE = 7654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_EMPTY_PLATE = 7655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_LOGGING = 7656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_LOGGING_FILE = 7658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_NAME = 7660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DESCRIPTION = 7661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_PRIVACY = 7662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVACY = 7663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_CREATION_OUTCOME = 7664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ACTION_ENTITY_TYPE = 7665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ACTION_ENTITY = 7666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ACTION_EVENT = 7667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ACTION_ACTION = 7668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGIN_SPECIFYDOMAIN = 7669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CHARACTER_IN_NAME = 7670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTION_SHOW_USER_DOMAIN = 7671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPY_PROCESS_COMPLETED_SUCCESSFULLY = 7672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FINALIZING_PROCESS = 7673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPY_PROCESS_COMPLETED_WITH_ERRORS = 7674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WIZARD_SELECT_OPTIONS_STEP_INSTRUCTION = 7675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WIZARD_SELECT_ENTITY_TYPE_STEP_INSTRUCTION = 7676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WIZARD_SELECT_SOURCE_STEP_INSTRUCTION = 7677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WIZARD_SELECT_DESTINATIONS_INSTRUCTION = 7678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SEARCH_MEMBER_ENTITIES = 7679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVING_STOPPED = 7684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVING_STARTED = 7685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_APPLICATION_DISCONNECTED_BYUSER = 7686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_APPLICATION_DISCONNECTED_UNEXPECTEDLY = 7687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_APPLICATION_CONNECTED = 7688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_APPLICATIONSTOPPED_BYUSER = 7689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_APPLICATIONSTOPPED_UNEXPECTEDLY = 7690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_APPLICATION_STARTED = 7691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CONNECTION_FAILED = 7694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CONNECTION_RESTORED = 7695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNITCONNECTION_FAILED = 7696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNITCONNECTION_RESTORED = 7697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASEAUTOMATICBACKUP_FAILED = 7700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASEAUTOMATICBAKCUP_RESTORED = 7701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASE_LOST = 7703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASE_RECOVERED = 7704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_HIGHCPUUSAGE = 7709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_HIGHCPUUSAGE_RESTORED = 7710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_HIGHMEMORYUSAGE = 7715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_HIGHMEMORYUSAGE_RESTORED = 7716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_LOWDATABASESPACE = 7719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_LOWDATABASESPACE_RESTORED = 7720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_OFFLOAD_FAILED = 7727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_OFFLOAD_RESTORED = 7728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_PATROLLER_OFFLINE = 7729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_PATROLLER_ONLINE = 7730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_POS_DATABASE_LOST = 7732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_POS_DATABASE_RECOVERED = 7733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ROLE_STARTED = 7736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ROLE_STOPPED_BYUSER = 7737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ROLE_STOPPED_UNEXPECTEDLY = 7738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_RTPPACKET_LOSS = 7739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_RTPPACKET_RECOVERED = 7740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SERVICE_STOPPED_BYUSER = 7741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SERVICE_STOPPED_UNEXPECTEDLY = 7742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SERVICE_STARTED = 7743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SYNCHRONIZATION_FAILED = 7744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SYNCHRONIZATION_RECOVERED = 7745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_VIDEOSIGNAL_LOSS = 7752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_VIDEOSIGNAL_RECOVERED = 7753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_VIDEOUNIT = 7754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_ACCESSCONTROLUNIT = 7755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_INTRUSIONDETECTIONUNIT = 7756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_GENETECSERVER = 7757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_ARCHIVER = 7758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_ACCESSMANAGER = 7759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_LPRMANAGER = 7760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_DIRECTORY = 7761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_MEDIAROUTER = 7762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_ACTIVEDIRECTORY = 7764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_FEDERATION_SECURITYCENTER = 7765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_FEDERATION_OMNICAST = 7766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_REPORTMANAGER = 7767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_INTRUSIONMANAGER = 7769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_POINTOFSALE = 7770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_ZONEMANAGER = 7772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_SECURITYDESK = 7774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_PATROLLER = 7776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_LPRUNIT = 7777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_HOTLIST_INVALIDENTRIES = 7778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLISTFILTER_LOGGING_MODIFIED_RECORDS = 7779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLISTFILTER_LOGGING_REMOVED_RECORDS = 7780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_FILTERS = 7781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_EVENTSHEADER = 7782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DETAILSHEADER = 7783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CONFIGURATIONPARAMETERS = 7784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_USERSUPERVISORDIFFERENTDOMAIN = 7785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_DOWNLOAD_ONLY_AT_LOGON = 7786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_DOWNLOAD_EXPEDITIVE_TRANSFER = 7787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CPUUSAGE_DETAIL = 7788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_MEMORYUSAGE_DETAIL = 7791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DATABASESPACE_DETAIL = 7792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_PACKETLOSS_DETAIL = 7794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DETAIL_SHORT = 7795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DETAIL_LONG = 7796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DETAIL_LONG_WITHTIME = 7797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERIOD_OF_TIME = 7798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_DUPLICATEACTIVEDIRECTORIES = 7799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREFERRED_UNIT = 7800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_AUXILIARY_ARCHIVER_NAME = 7801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOR_LC = 7802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_AUTOHIDE_TIMELINE = 7803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_TIMELINE_AUTOHIDE = 7804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSOCIATE_CAMERA = 7805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_CONNECTION = 7806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_CONNECTED = 7807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_NOT_CONNECTED = 7808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_NOT_INITIALIZED = 7809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_INITIALIZING = 7810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_ERROR = 7811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_SYNC_START = 7812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_UPDATING_ENTITIES = 7813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_SYNC_COMPLETED = 7814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_SYNC_PICTURES = 7815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_DELETING_ENTITIES = 7816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_CREATING_ENTITIES = 7817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_SHARED_PARTITION_INSERT = 7818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_SHARED_PARTITION_REMOVE = 7819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_ENTITY = 7820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADDITIONAL_CONNECTIONS = 7821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOTE_PROXY_DENIED_MODIFICATIONS = 7822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMAIL_COMPONENTS = 7824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANNOTATION_EMAIL_ADDRESS = 7825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SENT_EMAIL = 7826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EMAIL_NOTIFICATION_LOGGING_FILE = 7827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_AND_CLOSE = 7828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOTION_DETECTION_SETTINGS_LOST = 7830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_STREAM = 7832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_JUMP_FORWARD = 7833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_JUMP_BACKWARD = 7834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_CUSTOMFIELD = 7835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLING_MIGHT_AFFECT_SYSTEM_PERFORMANCE = 7836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SNAPSHOTS_FOLDER = 7837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SORT_BY = 7838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EVENTSOURCE_TYPE = 7840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_SEVERITY = 7841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_HEALTHEVENT = 7843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH_SEVERITY = 7844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_SOURCE_ENTITY = 7845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_JOGWHEEL_PLAYING = 7846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_JOGWHEEL_PAUSED = 7847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_CARDHOLDER_SYNCHRONIZER_NAME_PLURAL = 7848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONFIGTOOL_ERRORCOPYCONFIG_CONSTRAINTVIOLATION = 7849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNABLE_UPDATE_ENTITIES = 7850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNICAST_VERSION_COMPATIBLE = 7851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNICAST_VERSION_NOT_SUPPORTED = 7852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_FORENSIC_SEARCH = 7854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_FORENSIC_SEARCH = 7855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INHERIT_FROM_AUXILIARY_ARCHIVER = 7856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MAXALLOWEDBITRATE = 7859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MISSING_MEDIAPLAYER_HOTFIX = 7860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISABLING_MIGHT_AFFECT_STABILITY = 7861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_DECODERS_IN_ONE_PROCESS = 7862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECODERS_PER_PROCESS = 7863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NAVIGATE = 7864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_FILE_ANALYSER = 7865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CONVERT_SHARED_TO_LOCAL = 7866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_CONNECTIONLOST_RETRYING = 7868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_BADGE_SELECTED = 7869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_PACKED_ENTITY = 7870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ATTRIBUTE = 7871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVICE_MODE = 7872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SERVICE_MODE_ENABLE = 7873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SERVICE_MODE_DISABLE = 7874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANOTHER_USER = 7875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVICE_MODE_INFO = 7876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MINIMIZE_SPLASHSCREEN = 7877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CANCEL_SPLASHSCREEN = 7878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOAD_FROM_FILE = 7879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOAD_FROM_WEBCAM = 7880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOAD_FROM_CAMERA = 7881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOAD_FROM_CLIPBOARD = 7882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVICEURI = 7883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYCHRONIZE_AUTOMATICALLY = 7884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_CAMERAS_ENTER_ADDRESS_HERE = 7886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_CAMERAS_CAMERA_EXPOSURE_ON_STARTUP = 7887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_CAMERAS_START_SUSPENDED_MODE = 7888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_CAMERAS_ADVANCED_DATABASE_SETTINGS = 7889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_MAX_LOGOUT = 7890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_RECORD_STORAGE = 7891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_HIT_RECORD_STORAGE = 7892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_RECORD_SEARCH = 7893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_RECORD_DISPLAY = 7894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_FOLDER = 7895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_OFFLOAD_QUERY_TIMEOUT = 7896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_PATROLLER_NAME = 7897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_LOGIN_TYPE = 7898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_DATABASE_NAME = 7899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_GENERAL_CONNECTION_STRING = 7900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_ADVANCED = 7901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_COMMON = 7902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SYSTEM = 7903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_HOTLISTS = 7904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_PERMITS = 7905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_OVERTIME = 7906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_ENABLE_NEW_WANTED = 7907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_SHOW_COMMENT_FORM_NEW_WANTED = 7908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_EXPIRATION_NEW_WANTED = 7909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_DUPLICATE_HOSTLIST_HIT_DELAY = 7912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_ALLOW_SAME_HITS_ROW = 7913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_PAUSE_READ_PLATES = 7914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_ALLOW_POPUP_HIT = 7915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_FIRST_ON_TOP = 7916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_DISPLAY_HITS_BY_PRIORITY = 7917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_ADVANCED_OVERTIME_SETTINGS = 7918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_LINK_READ_PERSISTANCE_DURATION = 7919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_MINUTES_TO_DUE_VEHICLES = 7920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_USE_OVERTIME = 7921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_USE_TIRE_IMAGES = 7922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_WHEEL_IMAGING_ENFORCED = 7923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_PARALLEL = 7924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_45_DEGREE_ANGLE = 7925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_TIRE_CAM_PLATE_DISTANCE = 7926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_MAXIMUM_VEHICLE_LENGTH = 7927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_DISTANCE_TRAVELLED_BEFORE_SAVING = 7928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_USE_PERMIT = 7931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_USE_SHARED_PERMIT = 7932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_GPS = 7933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_ODOMETRY = 7934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_MAPS = 7935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_USE_GPS = 7936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_CONSECUTIVE_INVALID_GPS = 7937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_NOISE = 7938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_INIT_STRING = 7939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_USE_MAPS = 7940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_MAPPING_TYPE = 7941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_GPS_DISTANCE_TOLERANCE = 7942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_GPS_ODOMETRY_CALIBRATION_TOLERANCE = 7943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_MAX_DISTANCE_ERROR = 7944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_NAVBOX = 7945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_SHUTDOWN_DELAY = 7946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_USE_ODOMETRY = 7947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_USE_REVERSE = 7948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_SCALE = 7949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_SENSITIVITY = 7951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_READ_CAR_STOPPED = 7953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_POSITIONING_READ_MOVING_BACKWARDS = 7954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_LIVE_CONNECTION = 7955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_OFFLOAD = 7956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_CONNECT_TO_SC = 7957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_IP_ADDRESS = 7958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_ENCRYPT_COMMUNICATION = 7959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_UPDATE_PROVIDER_PORT = 7960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_LIVE_EVENTS = 7961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_HITS = 7962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_READS = 7963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_UNIT_POSITION = 7964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_PERIODIC_TRANSFER = 7966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_OFFLOAD_METHOD = 7967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_OFFLOAD_DRIVE = 7968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_USE_ENCRYPTION = 7969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_PUBLIC_KEY = 7970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_INCLUDE_IMAGES_BACKUP = 7971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_INCREMENTAL_BACKUP = 7972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_MAX_FILE_SIZE = 7973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_FORCE_OFFLOAD_EXIT = 7974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_OFFLOAD_EXIT = 7975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_DELETE_RECORD_OFFLOAD = 7976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_DEFAULT_PLATE_STATE = 7978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_HIGHLIGHT_PLATE_CONTEXT_IMAGE = 7979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_SHOW_OVERVIEW = 7980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_ENABLE_REVIEWS = 7981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_HOTLIST_STATUS_LED = 7982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_AUTO_POPUP_DOWNLOAD_INFOS = 7983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_SOUNDS = 7984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_PLAY_SOUND_VEHICLE_EVENT = 7985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_PLAY_SOUNDS_MINIMIZED = 7986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_ENABLE_MINIMIZE_BUTTON = 7987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_ENABLE_CONTEXT_MENU = 7988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_START_MINIMIZED = 7989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_SILENT_MODE = 7990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_ENABLE_MAIN_BUTTONS = 7991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SUFFIX_MILLISECONDS = 7993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_METERS = 7994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPEN_IN_WINDOWS_EXPLORER = 7995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INCOMPLETE = 7997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_SEQUENCE = 7998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMBOBOX_DATERANGE_LAST = 7999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_ON_CONNECTION = 8001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_EVERY = 8003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_INTERVAL = 8005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_PLAYBACK_REQUEST = 8006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONS = 8007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_OFFLINE = 8008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_TRIGGERS = 8009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ANALYTICS = 8010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_DOWNLOAD_EVENTS = 8011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_DELAY = 8012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_DOWNLOAD_SIMULTANEOUS = 8013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_STATUS = 8014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_DOWNLOAD_LAST = 8015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_LAST_FRAME = 8016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_NEXT_SCHEDULE = 8017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRICKLING_START_SELECTION = 8018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRICKLING_STOP_SELECTION = 8019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRICKLING_START_ALL = 8020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRICKLING_STOP_ALL = 8021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_BEFORE = 8022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEP_SYNCHRONIZED = 8023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARED_PARTITIONS = 8024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_CONFIGURATION_CHANGE_SYNC_FAIL = 8025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_CONFIGURATION_CHANGE_SYNC_FAIL_QUESTION = 8026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SYNCHRONIZE_FROM_HOST = 8027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STAY_IN_OVERRIDE = 8028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YESTERDAY = 8029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_TIME_SECONDS = 8030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_TIME_MINUTES = 8031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_TIME_HOURS = 8032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAY_OF_WEEK_TIME = 8033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_MODIFY_SHAREABLE_PARTITION_IMPACT = 8034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_REMOVE_SHAREABLE_QUESTION = 8035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_REMOVE_SHAREABLE_ACCEPTED_USER_QUESTION = 8036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUXILIARY_ARCHIVER_ADDING_CAMERA = 8037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUXILIARY_ARCHIVER_CAMERA_ADDED = 8038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUXILIARY_ARCHIVER_REMOVING_CAMERA = 8039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUXILIARY_ARCHIVER_CAMERA_REMOVED = 8040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUXILIARY_ARCHIVER_CAMERA_ASSOCIATED = 8041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ENTRANCE_AND_EXIT = 8043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_AUXILIARY_ARCHIVER_DESCRIPTION = 8044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACK_ID = 8045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALGORITHM_ID = 8046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT_TYPE = 8047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_LOAD_FORENSIC_CONTROL = 8048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DISPLAY_FORENSIC_CONFIGURATION = 8049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_GET_FORENSIC_CONFIGURATION_FROM_UNIT = 8051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MTTR = 8052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MTBF = 8053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_FAILURES = 8054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_AVAILABILITY = 8055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UPTIME = 8056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EXPECTED_DOWNTIME = 8057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_UNEXPECTED_DOWNTIME = 8058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT_COLORS = 8059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SET_CURRENT_POSITION = 8060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FORENSIC_NOT_SUPPORTED = 8061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_STATISTICS_NOT_AVAILABLE_OFFLINE_SERVER = 8064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_STATISTICS_NOT_AVAILABLE_HEALTH_EVENT_FILTER = 8065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HEALTH_STATISTICS = 8066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CALCULATION_STATUS = 8067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_AUTHORIZED = 8068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_NOTAUTHORIZED = 8069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_SYNCHRONIZING = 8070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_SYNCHRONIZED = 8071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_INSTALLING = 8072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_INSTALLED = 8073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_UNINSTALLING = 8074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_UNINSTALLED = 8075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_ERROR = 8076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_TYPE_APPLICATION_UPDATE = 8077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_UPDATE_ERROR = 8078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_UPDATE_INSTALLING = 8079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_UPDATE_PUBLISHED = 8080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_UPDATE_UNINSTALLING = 8081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_NOTIFICATIONS_HEALTH_EVENT_COUNT = 8082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_TRICKLING_ARCHIVING_SCHEDULE = 8084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALID_MAC = 8085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INVALID_SID = 8086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNAUTHORIZED_DISK = 8088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNAUTHORIZED_DISK_RECOVERED = 8089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZATIONMODE_AUTOMATIC = 8090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZATIONMODE_SHEDULE = 8091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SYNCHORNIZATION_ACTION = 8092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_FEEDS = 8093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUAL_CAPTURE = 8095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_WANTED = 8096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMITS = 8097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFO = 8098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIT_ENFORCE = 8099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIT_REJECT = 8100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIT_NO_INFRACTION = 8101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_LABEL_REQUIRES_INTERNET = 8102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIT_ACCEPT = 8103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_HEALTH_STATISTICS = 8104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_ONLINE = 8105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_ONLINE_DESCRIPTION = 8106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_OFFLINE = 8107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_OFFLINE_DESCRIPTION = 8108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_MOVED = 8109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_MOVED_DESCRIPTION = 8110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_LOW_BATTERY = 8111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ASSET_LOW_BATTERY_DESCRIPTION = 8112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_ASSET_ACTIVITIES = 8114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_ASSET_INVENTORY = 8115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ASSET_ACTIVITIES = 8116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ASSET_INVENTORY = 8117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ASSET = 8118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ASSETS = 8119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_EXITED_AREA = 8120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ENTERED_AREA = 8121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSETMANAGEMENT = 8122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWASSETS = 8123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYASSETS = 8124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDDELETEASSETS = 8125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CLEANUP = 8126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CLEANUP_SUCCEEDED = 8127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CLEANUP_EXCEPTION_X = 8128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CLEANUP_COMPLETED_WITH_ERRORS = 8129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CLEANUP_IN_PROGRESS_X = 8131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CLEANNOW = 8132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEANEACHDAYSELECTION = 8133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DATABASECLEANUP = 8134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIT_NOT_ENFORCE = 8135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CLEANUP_DATABASE = 8136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASECLEANUP = 8137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CLEANUP_DBSERVER_DBINSTANCE_DATABASE = 8138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STEP_X_OF_Y = 8140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ITEMS_COUNT = 8141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ITEMS_COUNT_WITH_X_SELECTED = 8142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT_AUTO_ENFORCE_AFTER_ACCEPT = 8144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_VERSION = 8147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATES = 8148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_ALL = 8149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLERS_AND_SHARPS_UNITS = 8150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_SERVICES = 8151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_ENTITLED = 8152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_ERROR = 8153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_INSTALLED = 8154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_INSTALLING = 8155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_NOT_AVAILABLE = 8156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_SYNCHRONIZED = 8157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_SYNCHRONIZING = 8158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_UNINSTALLED = 8159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_UNINSTALLING = 8160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ARCHIVES = 8161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CRITICAL_EVENT = 8162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOOLTIP_NOTIFICATIONS_REMAINING_COUNT = 8164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_G711_VERINT = 8165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_AAC = 8166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_BONJOUR = 8167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYSOUNDONHIT = 8168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYSOUNDONREAD = 8169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYSOUNDWHENMINIMIZED = 8170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACKBUFFERREJECTPRIORITY = 8171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACKBUFFERREJECTCOUNT = 8172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUXILIARY_ARCHIVERS = 8173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDGERECORDING = 8174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_DIRECTORY_FAILOVERS = 8175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PLAN_MANAGER_BASIC = 8176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PLAN_MANAGER_ADVANCED_MAP = 8177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PLAN_MANAGER_GIS = 8178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_GO_TO_PREVIOUS_PAGE = 8179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_GO_TO_NEXT_PAGE = 8180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_PLATE_VALID_CHARACTERS = 8181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLIST_FILTERING_LANGUAGE = 8182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLISTLANGUAGE_LATIN = 8183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLISTLANGUAGE_ARABIC = 8184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GLOBAL_ENTITIES = 8185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GLOBAL_PARTITION = 8186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GLOBAL_ONLY = 8187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCAL_ONLY = 8188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HORIZONTAL_FIELDOFVIEW = 8189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTICAL_FIELDOFVIEW = 8190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_AUTO_ENFORCE_OVERTIME_HITS = 8191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_AUTO_ENFORCE_HOTLIST_HITS = 8192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_AUTO_ENFORCE_PERMIT_HITS = 8193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_AUTO_ENFORCE_SHAREDPERMIT_HITS = 8194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ACTIVE_ALARMS = 8195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ACTIVE_ALARMS_RECOVERED = 8196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_WAVELET = 8197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SELECT_PLAYBACK_SOURCE = 8199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_ALL_PLAYBACK_SOURCES = 8200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READS = 8201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HITS = 8202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_OFFLOAD_TIME = 8203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_DIRECTORYREDIRECTED = 8204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OFFLOAD_TERMINATED_SUCCESSFULLY = 8205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIMELINE = 8206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NOT_CONNECTED_TO_SECURITYCENTER = 8207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLOR = 8208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRONT_CENTER = 8209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REAR_CENTER = 8210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRONT_LEFT = 8211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FRONT_RIGHT = 8212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REAR_LEFT = 8213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REAR_RIGHT = 8214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_CHANGED_SUCCESSFULLY = 8215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_IS_NOT_RUNNING = 8216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OFFLOAD_EXIT_ACTION_EXIT = 8217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OFFLOAD_EXIT_ACTION_SHUTDOWN = 8218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OFFLOAD_WILL_BE_DONE_TO_LOCAL_FILE = 8219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_LOWARCHIVEDISKSPACE_RESTORED = 8220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_LOWARCHIVEDISKSPACE = 8221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_USE_IPV6 = 8222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_EXTERNAL_TOOLS = 8223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_VIEW_LOG_FILE = 8224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLATE = 8225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORIENTATION = 8226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GO_LIVE = 8227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTRIES = 8228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOURCE_LAST_MODIFIED = 8229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_VIDEO_AVAILABLE = 8230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPOSURE = 8231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_BEHAVIOR = 8232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTSIDE_WEATHER = 8233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MUTE = 8234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_SELECTION = 8235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MESSAGES = 8236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOWNLOAD_INFO = 8237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTICS = 8238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRATION_IN_DAYS = 8239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMENT = 8240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLLBACK = 8241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHUTDOWN = 8242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGOUT = 8243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_SHOW_TASKBAR_WHEN_FULLSCREEN = 8244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_ENABLE_PRINTING = 8245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_IOSERVICES = 8246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CLEAR_DB_EXIT = 8247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_DROP_DB_EXIT = 8248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OWNER = 8249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_DATABASE_FAILOVER = 8250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_FAILOVER_MIRRORING_MODE = 8251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_FAILOVER_BACKUPANDRESTORE_MODE = 8252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTORE_FOLDER = 8254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABLE_FAILOVER_DATABASES = 8255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER_SERVERS = 8256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DIRECTORY_STARTED = 8257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DIRECTORY_STOPPED_BYUSER = 8258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DIRECTORY_STOPPED_UNEXPECTEDLY = 8259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MESSAGE_CAMERA_ORIENTATION = 8260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_FULLSCREEN_OFFSET_BOX = 8261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SUSPEND_READS = 8262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESUME_READS = 8263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISPLAY_ERRORS_ONLY = 8264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_RETRIEVE_CURRENT_LOCATION = 8265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PCT_SHOW_DEFAULT_VALUE = 8267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERAS = 8268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER_CONTEXT = 8269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITIONING = 8270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_INTERFACE = 8271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OFFLOAD_AND_EXIT = 8272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OFFLOAD_AND_SHUTDOWN = 8273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADDING_SERVER_REQUIRE_LICENSE_UPDATE = 8275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_SERVER_LICENSE_UPDATE = 8276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGE_LICENSE_QUESTION = 8277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODIFYLICENSE_ELLIPSIS = 8278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_FAILOVER = 8279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER_MODE = 8280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERATE_FULL_BACKUP = 8281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERATE_PARTIAL_BACKUP = 8282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MASTER_BACKUP = 8283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GENERATE_FULL_BACKUP_NOW = 8284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOUND_MANAGEMENT = 8285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACKNOWLEDGEMENT_BUFFER = 8286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ARCHIVE_DISK_SPACE_DETAIL = 8287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_NUMBER_OF_ACTIVE_ALARMS_DETAIL = 8288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVENT_CONNECTION_TO_DIFFERENT_DIRECTORIES = 8289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MASTER_AUTO_RECONNECT = 8290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGINSUCCESSFUL = 8291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGINFAIL_ACCOUNTDEACTIVATED = 8292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGINFAIL_WRONGPASSWORD = 8293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGINFAIL_ACCOUNTLOCKED = 8294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGINFAIL_INVALIDCREDENTIALS = 8295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGINFAIL_PASSWORDEXPIRED = 8296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPDATECOMPLETED = 8297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MANUALCAPTUREADDED = 8298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NEWWANTED_ADDED = 8299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NEWWANTED_ADDFAIL = 8300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_UNRESOLVED = 8301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_READHITRATIO = 8302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_TYPE_TIRE = 8303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HITUSERACTION_ENFORCED = 8304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_AUXILIARY_ARCHIVER = 8305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SAVE_CONFIG_ACTIVATE_DATABASE = 8306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CHANGE_ACTIVE_DATABASE = 8307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_ACTIVATION_FAILED = 8309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_GEMPEG4 = 8310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_BOOKMARK = 8311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTARTING_DIRECTORY = 8312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGER_INTRUSION_ALARM = 8313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_TRIGGER_INTRUSION_ALARM = 8314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_ERROR_SAVE = 8315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECIPIENT_TYPE = 8316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_IS_MANUAL_CAPTURE_ENABLED = 8317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_8KHZ = 8318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_11KHZ = 8319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_16KHZ = 8320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_22KHZ = 8321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_24KHZ = 8322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_32KHZ = 8323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_44KHZ = 8324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_8KBPS = 8325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_12KBPS = 8326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_16KBPS = 8327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_24KBPS = 8328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_32KBPS = 8329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_40KBPS = 8330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_48KBPS = 8331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_64KBPS = 8332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_96KBPS = 8333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_128KBPS = 8334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE = 8335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE = 8336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_LICENSE_WARNING = 8337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_LICENSE_WARNING_CONTINUE = 8338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ERROR_NUMBER = 8339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_OCCURRENCE = 8340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_CAMERAS_DISCOVERY_PORT = 8341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_ERROR_REPORT_QUERY = 8342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_ERROR_QUERY_TOO_COMPLEX = 8343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_UNAVAILABLE = 8344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVER_UNAVAILABLE = 8345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_BACKUP_RESTORE = 8346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SOME_CHANGES_NOT_SAVED = 8347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RESET_OPTIONS_TO_DEFAULT = 8348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LAUNCH_PATROLLER = 8349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_PERMIT_SELECTION = 8350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_ZONE_SELECTION = 8351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_EXPORT_INCOMPLETE_SEQUENCE = 8352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_INSERT_CUSTOMFIELDTYPENOTUNIQUE = 8354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_CUSTOMFIELDVALUENOTUNIQUE = 8355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_UPDATE_CUSTOMFIELDTYPENOTUNIQUE = 8356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STOP_GUARD_TOUR = 8357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OFFLOAD_FAILED = 8358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADDRESS_CANNOT_BE_RESOLVED = 8361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTORE_AGENT_UNAVAILABLE = 8362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_CAMERAS_WILL = 8363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_TRICKLE_ON_CONNECTION = 8364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_TRICKLE_ON_SCHEDULE = 8365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKUP_AGENT_UNAVAILABLE = 8366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_TRANSMISSION_RECOVERED = 8367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_RTP_PACKET_LOST_RECOVERY = 8368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_VRMCONNECTIONFAILURE = 8369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_VRMCONNECTIONATTEMPT = 8370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_PIN = 8371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_LIVE_HEALTH_MONITORING_STOPPED = 8372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_LIVE_HEALTH_MONITORING_RECOVERED = 8373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_FILTER = 8374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CHANGE_ACTIVE_DIRECTORY = 8375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_ACTIVATION_FAILED = 8376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONLY_CAMERAS_THAT_SUPPORT_TRICKLING_ARE_LISTED = 8377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_ACTIVATION_CONNECTION_LOST = 8378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CLEAR_TASKS = 8380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAVORITES = 8381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_ADD_FAVORITE = 8382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_REMOVE_FROM_FAVORITES = 8383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MOVE_UNIT = 8384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MOVE = 8385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DISCOVERY_REQUEST_FAILED_X = 8386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_MLPI_SELECTION = 8387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_DIRECTORYFAILOVER = 8388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASEFAILOVER_MAINDBLOST = 8389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASEFAILOVER_MAINDBRECOVERED = 8390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIFFERENT_DATABASE_CONFIG_EXISTS = 8391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIRECTORY_SERVERS = 8392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_READ_LICENSE_FAILED = 8393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_APPLICATION_FAILED = 8394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_SERVERS_NOT_RUNNING = 8395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_SECONDS_ROUNDED_UP = 8396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CANNOT_MOVE_LPR_UNIT = 8397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DESTINATION_MUST_BE_DIFFERENT_FROM_SOURCE = 8398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CASH_REGISTER_MAPPINGS_INVALID_DETAIL = 8399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITIONNING_NOT_SUPPORTED_3D_MODE = 8400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ACTIVATE_DIFFERENT_DATABASE = 8402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_WARNING_EVENTS_WILL_NOT_BE_DOWNLOADED = 8403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DUE = 8405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REVIEW = 8406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ACTIVATE_DATABASE_DELETE_DIFFERENT_CONFIG = 8407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPDATE_STATUS_UPTODATE = 8408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CONTEXT_IMAGE = 8409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_PLATE_IMAGE = 8410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SWITCH_TO_MAP = 8411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLATE_STATE = 8412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOMATIC_BACKUP_SUCCESSFUL = 8413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTOMATIC_BACKUP_FAILED = 8414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICASTSDK_NOT_CONFIGURED = 8415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CANNOT_OPEN_TO_HEALTH_MONITORING_DATABASE = 8416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CANNOT_LOGIN_TO_HEALTH_MONITORING_DATABASE = 8417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SEND_CLEAR_USER_TASK = 8418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_ENOUGH_RESOURCE = 8419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_SECURITY = 8420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_SYSTEM = 8421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_VIDEO = 8422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ACCESSCONTROL = 8423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_INTRUSIONDETECTION = 8424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_LPR = 8425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONTACT_US = 8426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSEINFO_UNAVAILABLE = 8427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_SYSTEM_UNIT = 8428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_CALIBRATE_CURRENT_ZONE = 8429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_CALIBRATE_ALL_ZONES = 8430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_CALIBRATE_ALL_MOTION = 8431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_MOTIONDETECTION_CALIBRATE = 8432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOTIONDETECTION_CALIBRATE_WARNING = 8433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CONFIGURATION = 8434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GLOBAL_CARDHOLDER_SYNC_CANNOT_RETRIEVE_HOST_ENTITY = 8435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITYBROWSER_REFRESH = 8436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_PLUGINS = 8437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORCE_DIRECTORY_ON_FIRST_SERVER = 8438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOTIONDETECTION_CALIBRATE_ERROR = 8439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TRICKLING_ARCHIVER_OFFLINE = 8440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ACCESSCONTROL_HEALTHHISTORY = 8441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ACCESSCONTROL_HEALTHHISTORY = 8442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOTIONDETECTION_MOTIONMASK_EMPTY = 8443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOTIONDETECTION_MOTIONMASK_EMPTY_SCHEDULE = 8444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NOT_CONNECTED_TO_FEDERATE_SYSTEM = 8445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SAVE_CONFIG_ACTIVATE_DIRECTORY = 8446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_QUESTION_CANCEL_OFFLOAD_AND_EXIT = 8447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_QUESTION_CANCEL_OFFLOAD_AND_SHUTDOWN = 8449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLOAD_IN_PROGRESS = 8450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASE_RESTORESUCCESS = 8451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DATABASE_RESTOREFAILURE = 8452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_BACKUP_IN_PROGRESS = 8454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CLEANUP_IN_PROGRESS = 8455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CREATION_IN_PROCESS = 8456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_DROPPING = 8457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_RESTORE_IN_PROGRESS = 8458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_STARTING = 8459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_UPGRADE_IN_PROGRESS = 8460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_WAITING_FOR_UPGRADE = 8461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SAVED_TASKS = 8462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TASKS = 8463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DO_YOU_WANT_TO_SHUTDOWN = 8464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MESSAGE_CAMERA_ORIENTATION_NOT_SET = 8465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_LOG_ON_THROUGH_MOBILE_CLIENT = 8467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_VIDEO_ENABLE_SMOOTH_PLAYBACK = 8469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISABLING_AFFECT_USER_EXPERIENCE = 8470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_TRICKLING_NO_FILTER_SET = 8471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WINDOWS_TIME_HAS_CHANGED = 8472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZE_SHARPS_TIME = 8473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIRM_CLEAR_DATABASE = 8474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR_DATABASE = 8475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONNECTING_DATABASE = 8476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SERVEROFFLINE_DBCORRUPTED = 8477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DROP_DATBASE_EXIT = 8478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIRM_DROP_DATABASE = 8479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DROP_DATABASE = 8480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTRUSION_UNIT_TAMPER = 8481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_ON = 8482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_MENU_OFF = 8483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTION_INSUFFICIENT_PRIVILEGES_RECIPIENTS = 8484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERTIME_HIT = 8485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMIT_HIT = 8486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARED_PERMIT_HIT = 8487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ACTIVATE_DATABASE = 8488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REFRESH_DATABASE_STATES = 8489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITY_DELETED = 8490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_SELECTION_BOTH = 8491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXCEPTION_DETAILS = 8492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MESSAGE_DUPLICATE_CAMERAS = 8493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTIPLE_HIT = 8494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MESSAGE_UNIT = 8495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVATE_ADDRESSES = 8496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HOTLISTFILTER_LOGGING_REMOVED_DUPLICATE_RECORDS = 8497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNITCONNECTION_STOPPED = 8498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER_CONTEXTCAMERA = 8499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZATIONMODE_MANUAL = 8500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_AUXILIARY_ARCHIVER_ADD_OMNICAST_FEDERED_CAMERA = 8501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_GOTO_PREVIOUS_CONTENT_IN_CYCLE = 8502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_GOTO_NEXT_CONTENT_IN_CYCLE = 8503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOW_CONTROL = 8504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STE_LABEL_APPLYING_CHANGES = 8505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_VISITOR_PROPERTY = 8506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVING_STOPPED_FOR_AGENT_RUNNING_ON = 8507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVING_RESTORED_FOR_AGENT_RUNNING_ON = 8508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NEW_DIRECTORY_FAILOVER_CONFIG_DETECTED = 8509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESTART_MAIN_SERVER = 8510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_KEEP_EXPANSION_SERVER = 8511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NEW_FAILOVER_CONFIG_TITLE = 8512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISSING_VIEW_PRIVILEGES = 8513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ABOUT_COPYRIGHT = 8514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DISMISS_HEALTH_EVENT = 8515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_POSITIONING = 8516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_UNIT_STATE = 8517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_OFFLOAD_MESSAGE = 8518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_OFFLOAD_CONNECTION_AVAILABLE = 8519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_OFFLOAD_CONNECTION_NOTAVAILABLE = 8520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_OFFLOAD_STATUS = 8521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_NOINFO = 8523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_GPS_INFOS = 8524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_ODOMETRY_INFOS = 8525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_CAMERAS_RUNNING = 8526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_CAMERAS_DISCONNECTED = 8527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_POSITIONING_INFOS = 8528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_MAP_INFOS = 8529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_UNIT_STATE_INFOS = 8530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DEFINED = 8531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNDEFINED = 8532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SHOW_VEHICLE_ROUTE = 8533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OPTION_DISPLAY_STREAM_USAGE_WARNING = 8534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_STREAM_USAGE_AUTOMATIC = 8535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_ALREADYCONNECTED = 8536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLEASE_WAIT_ELLIPSIS = 8537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREPARING_CANVAS = 8538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_TRIGGER_INTRUSION_ALARM_RECURSIVE = 8539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVERT_TILT_AXIS = 8540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LENSTYPE = 8541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PTZ_SERIALPORT_NOT_CONFIGURED = 8543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTENSIONTYPE_SMC = 8544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_ENABLE_EDGE_PLAYBACK_REQUESTS = 8545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SENTRY360_CENTERCALIBRATION = 8546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CALIBRATE_LENS = 8547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEWARPED_RESOLUTION = 8548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_NOT_ENOUGH_FREE_SPACE_ON_DISK = 8549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVEPRESET_DETAILS = 8551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CREATE_PRESET = 8552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_RENAME_PRESET = 8553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RELOAD = 8554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIGITALZOOM_PRESETS = 8555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RFID_USB_READER = 8558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUEST_CARD = 8559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BADGE_TEMPLATE = 8560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE = 8561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AFTER_ENROLLMENT = 8562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMAIL_WHEN_CARD_IS_READY = 8563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READ_FIRST_SEEN = 8566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READ_LAST_SEEN = 8567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PRINT_CARDS = 8568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ASSOCIATE_CARDS = 8569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ASSOCIATE_CARD = 8570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DENY_REQUEST = 8571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CARD_REQUESTS = 8572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_BATCH_ENROLLMENT = 8573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ENROLL = 8574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CREDENTIAL_MANAGEMENT = 8575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CREDENTIAL_MANAGEMENT = 8576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_CREDENTIAL = 8577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DELETE_CREDENTIAL = 8578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEED_CHANGE_PASSWORD = 8579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_REQUESTED = 8580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ALARMS = 8581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_AVAILABLE_TO_MOBILESHARPS = 8582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_AVAILABLE_TO_PATROLLER = 8583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLLBACK_PATROLLER_HOTFIX = 8584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLLBACK_SHARPS_HOTFIXES = 8585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADDITIONAL_INFO = 8586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_BADGE_PRINT_REQUESTED = 8587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_BADGE_PRINT_CANCELLED = 8588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_BADGE_PRINT_COMPLETED = 8589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMSTATE_ACK_CONDITION_PENDING = 8590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMSTATE_READY_TO_CLEAR = 8592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_CONDITION_CLEARED = 8593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_FORCIBLY_CLEARED = 8595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMUM_PAN_ANGLE = 8596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_PAN_ANGLE = 8597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMUM_TILT_ANGLE = 8598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_TILT_ANGLE = 8599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOCK = 8600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAY_PAUSE = 8601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_TYPE_CREDENTIAL_REQUEST = 8602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_TYPE_CREDENTIAL_REQUEST_CANCELLED = 8603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_TYPE_CREDENTIAL_REQUEST_COMPLETE = 8604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_CREDENTIALREQUESTED = 8605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_CREDENTIALREQUESTCANCELLED = 8606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_CREDENTIALREQUESTCOMPLETED = 8607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_ROLE_CONNECTED = 8608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_DIAGNOSTICS_ROLE_DISCONNECTED = 8609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISPLAYONANALOGMONITOR = 8610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALOGMONITOR = 8611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_ACTION = 8612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_EDIT_ACTION = 8614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_JPEG2000 = 8616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRY_DATE = 8619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NEWWANTED_EDIT_SUCCESS = 8620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NEWWANTED_EDIT_ERROR = 8621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SET_PLAYBACK_SPEED = 8622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SELECT_TILE_OR_MONITOR = 8623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ADDITIONAL_ACTION = 8624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ANALOGMONITOR = 8625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_MONITORGROUP = 8626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_BLOCK_UNBLOCK_VIDEO = 8627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_MONITORGROUPS = 8628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_MONITORGROUPS = 8629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_LOGON_NOT_CONNECTED_TO_SC_PASSWORD_CHANGE = 8630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGONABORTED = 8631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_MISSINGREQUESTUSERCHANGEPASSWORDEVENT = 8632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ANALOGMONITORS = 8633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_ANALOGMONITORS = 8634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_CLEAR = 8635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSPARENCY_COLOR = 8637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOLERANCE = 8638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_NEW_WANTED = 8639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CREDENTIAL_REQUEST_HISTORY_REPORT = 8640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_CREDENTIAL_REQUEST_HISTORY = 8641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CREDENTIAL_REQUEST_HISTORY = 8642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EMAIL_NOTIFICATION_CARDREQUEST_COMPLETE = 8645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUESTING_USERS = 8646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINTING_USERS = 8647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_CUSTOMFIELDS = 8648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_CUSTOMFIELDS = 8649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_DATE_QUEUED = 8650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_DATE_PRINTED = 8651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_REQUESTER_USERNAME = 8652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_REQUESTER_EMAIL = 8653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_PRINT_REASON = 8654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_CREDENTIAL_PRINTED_BY_USERNAME = 8655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEWWANTED_MANAGEMENT = 8656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_NEWWANTED_MANAGEMENT = 8657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_MANAGE = 8658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_REQUEST_REASONS = 8659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOPAN_START = 8660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOPAN_STOP = 8661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PANORAMIC_CAMERAS = 8662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGON_TYPE_NO_LOGON = 8663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGON_TYPE_WINDOWS = 8664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGON_TYPE_SECURE_NAME = 8665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGON_TYPE_SECURE_NAME_AND_PASSWORD = 8666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER_READ_ELAPSED = 8667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER_READ_ZONE = 8668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER_READ_VIOLATION = 8669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLOAD_TYPE_LOCAL_FILE = 8670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLOAD_TYPE_WCF = 8671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_READS_ON_FOR = 8672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HITS_ON_FOR = 8673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_READS_GROUPING_DESCRIPTION = 8674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_READS_GROUPING = 8675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HITS_GROUPING_DESCRIPTION = 8676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HITS_GROUPING = 8677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUB_TOTAL = 8678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_BY = 8679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_NUMBER_OF_READS = 8680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_NUMBER_OF_HITS = 8681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_ANALOG_MONITORS = 8682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CAMERA_BLOCKING = 8683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLED_CAMERAS = 8684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PERMITNAMEMATCHCATEGORY = 8685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_SELECTION = 8686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_STREAMINFORMATIONS = 8688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CALIBRATE = 8689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COLHEADER_ACTIVE_DIRECTORY_SYNCCREDENTIALS = 8690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOPAZ_SIGNATURE_PAD = 8691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DYMO_CARD_SCANNER = 8692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TOPAZ_SIGNATURE_PAD_INI_TITLE = 8693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TOPAZ_SIGNATURE_PAD_INI_MESSAGE = 8694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKING_FOR_CARD = 8695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CARD_FOUND = 8696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCANNING = 8697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALYZING = 8698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTRACTING = 8699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_CARD_FOUND = 8700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WHILE_SCANNING = 8701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INITIALIZING_CARD_SCANNER_TILE = 8702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INITIALIZING_CARD_SCANNER_MESSAGE = 8703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_AN_UNKNOWN_ERROR_OCCURED = 8704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WHILE_SCANNING_TITLE = 8705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DYMO_CARD_SCANNER_ACTION = 8706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOPAZ_SIGNATURE_PAD_ACTION = 8707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SETCOORDINATES = 8708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CALIBRATIONASSISTANT = 8709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FETCHINGACTUALPTZPOSITION = 8710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLBOUNDARIESFOUND = 8711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETERMININGMAXIMUMZOOMFACTOR = 8712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOTOMINIMUMZOOMFACTOR = 8713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETERMININGTILTBOUNDARIES = 8714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETERMININGPANBOUNDARIES = 8715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANCELLINGCALIBRATIONASSISTANT = 8716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOTTOMLEFTFOVCALIBRATION_DIRECTIVE = 8717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOPRIGHTFOVCALIBRATION_DIRECTIVE = 8718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TESTABSOLUTECOORDINATES_DIRECTIVE = 8719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVERT_PAN_AXIS = 8720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOTTOMRIGHTFOVCALIBRATION_DIRECTIVE = 8721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOPLEFTFOVCALIBRATION_DIRECTIVE = 8722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CAMERA_BLOCKING_BLOCK = 8723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CAMERA_BLOCKING_UNBLOCK = 8724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_MONITORGROUP = 8725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_IMAGEEDITOR = 8726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMAGEEDITOR_UNDO = 8727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMAGEEDITOR_REDO = 8728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMAGEEDITOR_ROTATERIGHT = 8729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMAGEEDITOR_ROTATELEFT = 8730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMAGEEDITOR_FLIP_HORIZONTALLY = 8731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMAGEEDITOR_FLIP_VERTICALLY = 8732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IMAGEEDITOR_CROP = 8733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IMAGEEDITOR_TRANSPARENCY = 8734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGEEDITOR_ORIGINALPICTURE = 8735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGEEDITOR_CURRENTPICTURE = 8736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMAGEEDITOR_LOCK_AR = 8737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IMAGEEDITOR_CROPPINGUNIT_PIXELS = 8738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_IMAGEEDITOR_TITLE_UNSAVED = 8741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOSPECIFIC_VIDEOMODE = 8745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_ANALOGMONITORINOTHERGROUP = 8746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BADGE_PRINT_ACTIVITY_TRAILS_DESCRIPTION = 8748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATERSERVICE_VERSION = 8749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECODERSTARVEMODE = 8750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECODERDISPLAYCAMERANAME = 8751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECODERSTARVEMODE_COLORBAR = 8753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECODERSTARVEMODE_LASTIMAGE = 8754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_REDUCING_VIDEO_RETENTION_PERIOD = 8755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_MONITORGROUPS = 8756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MOFIFY_MONITORGROUPS = 8757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETE_MONITORGROUPS = 8758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_ANALOGMONITORS = 8759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MOFIFY_ANALOGMONITORS = 8760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AC_READY = 8772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BATTERY_READY = 8773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_IMAGES = 8778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_READ = 8779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECOND_READ = 8780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPLOAD_PICTURES_TO_ACTIVE_DIRECTORY = 8781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_SOURCECONDITION_ACK_REQUIRED = 8784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARM_INVESTIGATED_ON = 8786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARM_INVESTIGATED_BY = 8787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMSTATE_INVESTIGATING = 8789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMSTATE_ACKNOWLEDGEMENT_REQUIRED = 8790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SET_AS_DEFAULT_PARTITION = 8791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_NEW_ENTITIES_IN_THE_PARTITION = 8792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_ENTITY = 8793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_INVESTIGATING = 8794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYONANALOGMONITOR_CAMERA_UNSUPPORTED = 8795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYONANALOGMONITOR_SEQUENCE_UNSUPPORTED = 8796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAYVIDEOCONTROLBUTTONSONTILE = 8797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_ALARM_WARNING = 8798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INDOOR = 8799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTDOOR = 8800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABLE_MIX = 8801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BALANCED = 8802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPEED = 8803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIGHSPEED = 8804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOONLIGHT = 8805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_BLUE = 8806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_RED = 8807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_SHARPNESS = 8808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_ILLUMINATIOMODE = 8809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_AUTOEXPOSURE = 8810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_SHORTEXPOSURES = 8811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_FREQUENCY = 8812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_DAYNIGHTMODE = 8813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_FREQUENCY_60HZ = 8814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_SWITCHINGGAIN = 8815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_TOGGLEGUARD = 8816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_FREQUENCY_50HZ = 8817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_LOWLIGHTMODE = 8818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_EXPOSUREWINDOW = 8819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DROP_CAMERA_OR_SEQUENCE_HERE = 8821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACKREASON_FORCIBLY_ACKED = 8822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WAITING = 8823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_FORCE_ACK = 8824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_FORCE_ACK_ALL = 8825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ALARM_SHOW_INVESTIGATE = 8826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ALARM_SHOW_ACK_REQUIRED = 8827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ALARM_FORCE_ACK = 8828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ALARM_FORCE_ACK_ALL = 8829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_INVESTIGATE = 8830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLETOAUTOCALIBRATE_ZOOM1X = 8831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLIPCAMERA = 8832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMUM_TILT = 8834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_TILT = 8835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_USECENTERANDZOOMBOX = 8836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ANALOG_MONITOR_CONNECTION = 8837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ANALOG_MONITOR_DISCONNECTION = 8838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTED_ENTITY = 8839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINT_AND_ENROLL = 8840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENHANCEDZOOMENABLED = 8842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CENTERONCLICKENABLED = 8843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZOOMBOXCENTERONCLICKENABLED = 8844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENHANCEDPTZCALIBRATIONNEEDED = 8845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENHANCEDPTZ = 8846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CANNOTREACHTHATOBJECT = 8847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RECALCULATEBOUNDARIES = 8848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_USETHISVALUE = 8849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_COORDINATEPASSEDBOUND = 8850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OBSERVEDVALUE = 8851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIMITDEFINED = 8852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CALIBRATEPTZ = 8853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLISTLANGUAGE_JAPANESE = 8854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_PRINT_CREDENTIALS_NO_BADGE_ASSOCIATED = 8855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NOT_ALL_CARDHOLDERS_HAVE_CREDENTIALS_TO_PRINT_DO_YOU_WANT_TO_CONTINUE = 8859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWINTRUSIONUNITS = 8860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYINTRUSIONUNITS = 8861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDANDDELETEINTRUSIONUNITS = 8862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PC_AND_ANALOG_MONITORS = 8863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_DISPLAY_CAMERA_SEQUENCE = 8864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_EDIT_CARD_REQUEST_REASON = 8865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_CARD_REQUEST_REASON = 8866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_CANNOT_BE_CHANGED_MUST_BE_RESET_BY_ADMIN = 8867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_WHEEL_IMAGE = 8868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_EXPORT_FULL_RES_IMAGES = 8869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CARDHOLDER_STATUS_SYNCHRONIZED = 8870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_VIOLATION_DETAIL = 8871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALIDNETWORLADDRESS = 8872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICE_VEHICLE = 8873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOUN_DUPLICATE = 8874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALREADY_PROCESSED = 8875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AMBER = 8876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERSON_OF_INTEREST = 8877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FELON = 8878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BE_ON_THE_LOOK_OUT = 8879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_WANTED_STOLEN = 8880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_TIREIMAGES_SET_OFF = 8881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_CAMERATREE = 8885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIDE_CAMERATREE = 8886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHMONITOR_DIAGNOSTIC_FAILED_TO_LOAD_STATE = 8887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAMPER_ACTIVE = 8888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAMPER_NORMAL = 8889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PULL_STATION_NORMAL = 8891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARMING_STATE_ALARM_NORMAL = 8892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_NORMAL = 8893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PULL_STATION_ACTIVE = 8894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARP_DISCOVERY_PORT = 8895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGETEMPLATE_BASIC_AUTOVU = 8896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_HOTLIST = 8897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_FILE_ASSOCIATION = 8898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DENY_CARD_REQUESTS_CONFIRMATION = 8900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MEDIA_ROUTER_CONNECTION_LOST = 8901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MEDIA_ROUTER_CONNECTION_SUCCESSFUL = 8902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COULD_NOT_LOAD_ROLE_CONFIGURATION_USING_DEFAULT = 8903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COULD_NOT_FIND_THE_ROLE_CONFIGURATION = 8904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUXILIARY_ARCHIVER_ROLE_SUCCESSFULLY_STOPPED = 8905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_WHILE_STOPPING_AUXILIARY_ARCHIVER_ROLE = 8906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUXILIARY_ARCHIVER_ROLE_SUCCESSFULLY_STARTED = 8907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_WHILE_STARTING_AUXILIARY_ARCHIVER_ROLE = 8908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROCESSING_PICTURE = 8909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_SHOW_USER_NAME_IN_TRAY = 8910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_UI_SHOW_PATROLLER_NAME_IN_TRAY = 8911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CONFIGURE_MANUFACTURERS_EXTENSIONS = 8912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OVERTIME_REGULATIONS_ORDER = 8913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PRIMARY_ARCHIVER = 8914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECONDARY_ARCHIVER = 8915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_WRITE_TO_DISK = 8916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVING_DISABLED_ON_THE_ARCHIVER_REASON_FORMAT = 8917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_ENTITY = 8918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PATROLLER_UPDATE_ALL_CONFIRMATION = 8920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EMAIL_NOTIFICATION_CARDREQUEST_CANCELLED = 8921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOTRANSMISSIONMODE = 8922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOWFIRMWAREUPGRADE = 8923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOMODEPUSHTOTALK = 8924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOMODEFULLDUPLEX = 8925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SAVE_CONFIG_OVERRIDE = 8926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITOREDENTITIES = 8927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_EXPIREDCREDENTIAL = 8928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_INACTIVECREDENTIAL = 8929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_LOSTCREDENTIAL = 8930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_STOLENCREDENTIAL = 8931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_UNASSIGNEDCREDENTIAL = 8932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SWITCHTOMAPMODE = 8933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SWITCHTOTILEMODE = 8934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALIDATE_LICENSE = 8935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNIT_PLAYBACK_UNAVAILABLE = 8936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOWEVENTLIST = 8937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOWCANVAS = 8938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GTAP = 8939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GTAP_FORUM = 8940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOWALARMLIST = 8941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSATION = 8942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSATIONCAMERA = 8943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_GROUPBOX_CONVERSATION_OPTIONS = 8944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTERVAL_BETWEEN_TIRE_IMAGES = 8945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_STARTCONVERSATION = 8946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSATIONSUBJECT = 8947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOWRESULTS = 8948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SET_THREAT_LEVEL = 8949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_THREAT_LEVEL = 8950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_USER_LEVEL = 8951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_ACCESS_PERMISSION_LEVEL = 8952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_READER_MODE = 8953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_THREATLEVEL = 8954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_THREAT_LEVEL_ACTIVATED = 8956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_NO_SPACE = 8957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_DOES_NOT_MATCH = 8958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_INTRO = 8959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_TOTAL_CHARACTERS = 8960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_N_UPPERCASE_LETTERS = 8961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_N_LOWERCASE_LETTERS = 8962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_N_NUMERICAL_CHARACTERS = 8963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_N_SPECIAL_CHARACTERS = 8964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_DIRECTLY_ASSOCIATED_WITH_RULE = 8965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_LACKS_PRIVILEGES_TO_CHANGE_OWN_PASSWORD = 8966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNIKEY_USB_READER = 8967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_THREAT_LEVEL = 8968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_THREATLEVELS = 8969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_THREATLEVELS = 8971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_START_BLOCK = 8972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_STOP_BLOCK = 8973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_INFO = 8974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_NOTIFICATIONS_ONLY = 8975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TRAY_MONITOR = 8976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_CONVERSION = 8977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUARD_TOUR = 8978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CCTV_KEYBOARD = 8979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTRY_SENSOR = 8980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_ENTRYSENSOR = 8981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_ENTRYSENSOR_SIDE = 8982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READERMODE_CARDANDKEYPAD = 8984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READERMODE_CARDORKEYPAD = 8985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READERMODE = 8986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_SET_THREAT_LEVEL = 8987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_SETTHREATLEVEL = 8988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_SETTHREATLEVEL_RECURSIVE = 8989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_RESET_THREAT_LEVEL = 8990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_RESETTHREATLEVEL = 8991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_RESETTHREATLEVEL_RECURSIVE = 8992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTPARTICIPANTSTOINVITE = 8993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENDINVITATION = 8994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVITETOJOINCONVERSATION = 8995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OTHERPARTICIPANTSINVITED = 8996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DOYOUJOINCONVERSATION = 8997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WAITINGPARTICIPANTS = 8998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSATIONS = 8999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHENJOININGCONVERSATION = 9000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDEMYVIDEO = 9002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DOOR_OPEN_TOO_LONG_CONFIG_ERROR = 9003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_VIEWCONVERSATION = 9004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REPORT_QUERY_COMPLETED_WARNING = 9006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REPORT_QUERY_COMPLETED_INFORMATION = 9007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TRAIL_REMOTE_SD_SUBSCRIBERS = 9008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_SUBSCRIBE_REMOTE_SD = 9009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_UNSUBSCRIBE_REMOTE_SD = 9010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGNED = 9011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THREATLEVEL_CONFIG = 9012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THREATLEVEL_ACTIVATION_ACTIONS = 9013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THREATLEVEL_DEACTIVATION_ACTIONS = 9014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_PERMISSION_LEVEL = 9015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIO_MODE = 9016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FULL_DUPLEX = 9017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HALF_DUPLEX_PUSH_TO_TALK = 9018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INHERIT_LPRFILES_FROM_ROLE = 9019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_CONFIG_INHERIT_FROM_PARENT = 9021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_CONFIGURE_ENTITY = 9022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SUBJECT = 9023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMAIL_TEMPLATE = 9024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONVERSATIONOVERVIEW = 9026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_REMOTE_SECURITY_DESK = 9027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_REMOTE_DESCRIPTION = 9028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_CATEGORY_IDENTIFICATION = 9031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_CATEGORY_HIT = 9032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_CATEGORY_LOCATION = 9033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOPARTICIPANTS = 9034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOWMYVIDEO = 9035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADDPARTICIPANT = 9036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_POKEPARTICIPANT = 9037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_LEAVECONVERSATION = 9038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_ERROR_INVALID_PARAMETER = 9039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FULLSCREEN_MONITORS = 9040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_SELECTED_MONITOR = 9041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KICKOUT = 9042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_APPLY_THREAT_LEVEL_SYSTEM = 9043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_APPLY_THREAT_LEVEL_AREA = 9044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_THREAT_LEVEL_ACTIVE = 9046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMAIL_RESTORE_DEFAULT_TEMPLATE = 9049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOAD_FROM_TWAIN = 9051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TWAIN_CAPTURE = 9052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_PERMISSION_INSUFFICIENT = 9053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HARDWAREINVENTORY = 9054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_UNITTYPE_MOBILE = 9055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_UNITTYPE_FIXED = 9056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEM_THREAT_LEVEL = 9058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRED = 9059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_TILE = 9060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILL_THE_TILE = 9061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_FULL_IMAGE = 9062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_VIDEO_ENABLE_QUALITY_DEGRADATION = 9063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISABLING_MIGHT_AFFECT_SYSTEM_PERFORMANCE = 9064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_PHYSICALADDRESS = 9065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIGH_CPU = 9066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_COULD_NOT_LOCATE_FILE = 9082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_VERYWEAK = 9083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_WEAK = 9084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_GOOD = 9085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_STRONG = 9086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_VERYSTRONG = 9087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SPECIFIC_FOLDER_CACHE_LOCATION_INVALID = 9088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSERT_AREA_AND_CHILDREN_QUESTION = 9089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSERT_AREA_AND_CHILDREN = 9090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSERT_AREA_ONLY = 9091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_AREA_AND_CHILDREN_QUESTION = 9092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_AREA_AND_CHILDREN = 9093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_AREA_ONLY = 9094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVING_SERVER_PUT_SYSTEM_DOWN = 9095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILTORENAMESNAPSHOT = 9098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILEALREADYEXIST = 9099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILENAMETOOLONG = 9101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ELEVATOR_FREEACCESS = 9102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POWER_FAILURE_ACCESS_CONTROL_UNIT = 9103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_FLOORS = 9104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ELEVATOR_CABIN_READER = 9105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CABIN = 9106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NETWORK_NAME_RESOLUTION = 9109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTQUERY_UNITMODEL = 9110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_ACCESS = 9111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_BACKUP_SERVER = 9114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_RESTORE_SERVER = 9115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_FLOOR_PROPERTIES = 9120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRECT_PLATE_VALUE = 9123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_READ_CORRECT_VALUE = 9128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECOND_READ_CORRECT_VALUE = 9129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FOLDER_IN_USE = 9131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_NEW_TASK = 9133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RELOAD_WORKSPACE = 9134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONNECTED_USERS = 9135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_THIS_TASK_CANNOT_BE_REMOTELY_CONTROLLED = 9141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_THIS_TILE_CONTENT_CANNOT_BE_REMOTELY_CONTROLLED = 9142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_INVALID_LICENSE_HARDWARE_MISMATCH = 9144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DROP_FOLDER = 9145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_USER_LOGON = 9146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_USER_LOGOFF = 9147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_USER_LOGOFF = 9148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_USER_LOGON = 9149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SETMYCAMERA = 9150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAY_BEFORE = 9152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_DAY = 9153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAY_AFTER = 9154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BELONGS_TO = 9155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARMREPORT_COLUMNNAME_ALARMPRIORITY = 9156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPDATEPARTIALLYSUCCEEDED = 9157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPDATEFAILED = 9158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EDITING_NOT_SUPPORTED_EXCEPTION = 9159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_WAITING_FOR_DOWNLOAD = 9160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_CONNECTING_TO_MEDIA_ROUTER = 9161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_CONNECTING_TO_ARCHIVER = 9162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_INITIAL_DOWNLOAD = 9163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_REQUESTING_DOWNLOAD = 9164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_SEARCHING_ARCHIVES = 9165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_READING_ARCHIVES = 9166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_CONNECTING_TO_UNIT = 9167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_GETTING_DATA_FROM_UNIT = 9168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_NO_DATA = 9169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_ZEROCONF = 9170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_REPORT_TO_DISK = 9171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_PIN = 9172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_UNASSIGNEDCREDENTIAL = 9173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_UNKNOWNCREDENTIAL = 9174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_EXPIREDCREDENTIAL = 9175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_INACTIVECREDENTIAL = 9176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_INACTIVEPERSON = 9177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_UNASSIGNEDCREDENTIAL = 9178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_EXPIREDCREDENTIAL = 9179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_INACTIVECREDENTIAL = 9180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_LOSTCREDENTIAL = 9181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_LOSTCREDENTIAL = 9182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_STOLENCREDENTIAL = 9183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_STOLENCREDENTIAL = 9184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DEVICES_COULD_NOT_BE_ADDED = 9186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_FAILOVER_BACKUP_ERROR_DETAILS = 9187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_FAILOVER_RESTORE_ERROR_DETAILS = 9188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AREA_INTERLOCK_SAME_VALUE = 9189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ETHERNETPORT = 9190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_FILE_TYPE = 9191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_INVALIDPIN = 9192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_VALIDCARDINVALIDPIN = 9193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_INVALIDACCESSRULE = 9194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_INSUFFICIENTPRIVILEGES = 9195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_ANTIPASSBACK = 9196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_OUTOFSCHEDULE = 9197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_ENFORCE_AFTER_ACCEPT_PERMIT_HIT = 9198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_ENFORCE_AFTER_ACCEPT_SHARED_PERMIT_HIT = 9199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSERT_CHILDREN_IN_PARTITION_QUESTION = 9200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSERT_CHILDREN_IN_PARTITION = 9201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSERT_AREA_ONLY_IN_PARTITION = 9202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_CHILDREN_FROM_PARTITION_QUESTION = 9203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_CHILDREN_FROM_PARTITION = 9204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_AREA_ONLY_FROM_PARTITION = 9205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTED_YP3040 = 9206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_FAILOVER_UNAVAILABLE_HINTS = 9207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_INVALIDACCESSRULE = 9215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_ANTIPASSBACK = 9216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_INVALIDPIN = 9217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_VALIDCARDINVALIDPIN = 9218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_INSUFFICIENTPRIVILEGES = 9219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_OUTOFSCHEDULE = 9220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_NOENTRYDETECTED = 9221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ENTRYDETECTED = 9222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ENHANCED_ACCESS = 9223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CONVERSION_OUT_OF_MEMORY = 9224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_LAST_EVENT = 9225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_RECEIVING_DATA = 9226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RETURNING_VISITOR = 9227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MINIMUM_SECURITY_CLEARANCE_VERTX = 9228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DIAGNOSTICS_LOCALADDRESS = 9229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DIAGNOSTICS_NOCONNECTION = 9230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DIAGNOSTICS_LOGONFAILED = 9231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DIAGNOSTICS_TCPDISABLED = 9232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DIAGNOSTICS_REMOTECONNECTIONDISABLED = 9233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DIAGNOSTICS_ACCESSDENIED = 9234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_JITTER_BUFFER_DELAY = 9236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECTION_INSERTION_FAILED_MULTIPLE_LIMIT_REACHED = 9237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PROCEED_ANYWAY = 9238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESOLVE_EACH = 9239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTITION_INSERTION_FAILED_MULTIPLE_LIMIT_REACHED = 9240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITYNOTDIAGNOSABLE = 9241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHARPCONFIG_PARAMSNULL = 9242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHARPCONFIG_AUTOCONFSKIPPEDPORT = 9243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHARPCONFIG_FAULTOCCURED = 9244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHARPCONFIG_EXCEPTIONOCCURED = 9245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSG_SHARPCONFIG_EXTALREADYCONFIGURED = 9246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSG_SHARPCONFIG_EXTNOWCONFIGURED = 9247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHARPCONFIG_EXTENSIONLOADFAILED = 9248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHARPCONFIG_EXTENSIONNOTSUPPORTED = 9249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SHARPCONFIG_AUTOCONFNOTSUPPORTED = 9250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_NAME = 9252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_FIELD_INFO = 9253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_DATABASE_DIAGNOSTIC_ISSUES = 9254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_DATABASE_DIAGNOSTIC_OK = 9255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVER_IS_UNREACHABLE = 9256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_MASTERDIRECTORYSTARTED = 9259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_SLAVEDIRECTORYSTARTED = 9260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_SLAVEDIRECTORYLOST = 9261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_DIRECTORYFAILOVEROCCURED = 9262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_DATABASEFAILOVEROCCURED = 9263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_CONNECTEDTOPRIMARYDATABASE = 9264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_MASTERDIRECTORYLOST = 9265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_SAVE_WORKSPACE = 9266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_RESTORE_WORKSPACE = 9267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_RENAME_PAGE = 9268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_REFRESH_PAGE = 9269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_QUICKSNAPSHOT = 9270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_MOVE_PAGE = 9271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_KICKOUT = 9272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_GET_SUBSCRIBERS = 9273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_GET_VIDEO_SNAPSHOT = 9274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_GET_AVAILABLE_TASKS = 9275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_EXECUTE_COMMAND = 9276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_CREATE_TASK = 9277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_CLOSE_PAGE = 9280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_CHANGE_ACTIVE_PAGE = 9281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_CLOSE_ALL_PAGES = 9282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_APPLY_MODIFICATIONS = 9283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSFERINGPORTABLEAP = 9284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INITIALIZINGPORTABLEAPTRANSFER = 9285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PORTABLEAPTRANSFERED = 9286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNINGS = 9288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_EXIT_FULL_SCREEN = 9289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATH_NOT_VALID = 9290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDERGROUP_CUSTOMFIELDS = 9291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEACTIVATE = 9292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CAMERA_BLOCKING_BLOCK_RECURSIVE = 9293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CAMERA_BLOCKING_UNBLOCK_RECURSIVE = 9294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_THREAT_LEVEL_DEACTIVATED = 9295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MESSAGE_UPDATE_PENDING_HITS = 9296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERRORWHILEDIAGNOSTICREQUEST = 9297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_WARNING_SEARCH_COVERS_ENTIRE_SYSTEM = 9298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEE_EVENT_LOG = 9299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_CUSTOMFIELD = 9300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_CONNECT_ACCESS_DENIED = 9301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_CONNECT_MONITOR_UNAVAILABLE = 9302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_DISCONNECTED_KICK_OUT = 9303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_DISCONNECTED_TIMEOUT = 9304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECTOR_ROW = 9305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVENTORY_LOCATION = 9306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CAMERA_DIAGNOSTIC_SCHEDULE_CONFLICT = 9307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CAMERA_DIAGNOSTIC_SCHEDULE_CONFLICT_DETAILS = 9308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CLICK_TO_CANCEL = 9309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KEEP_DELETED_EDGEGROUP_CAMERA = 9310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_INVALIDRESOLUTION = 9313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CLICKTOCHANGERESOUTION = 9314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UDP_DIFFERENT_VALUE = 9315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_EDGE_GROUP_SHOULD_BE_EMPTY = 9320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_EXTENSION_FORMATED = 9321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_DELETE_READ = 9322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_READ = 9323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADDING_CHILDREN = 9324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FINDING_ALL_CHILDRENS = 9325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADDING_CHILDREN_OF = 9326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVING_CHILDREN = 9327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVING_CHILDREN_OF = 9328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPT_REASON = 9329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RELEARN_BACKGROUND = 9330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RELEARN_BACKGROUND_CONFIRMATION = 9331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SAVING = 9332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_JOYSTICK_DEAD_ZONE = 9333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOVING_UNIT = 9334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOVING_TO = 9335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MEDIAPROXY_NAME = 9337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MEDIAPROXY_DESCRIPTION = 9338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION = 9339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_AUTHENTICATION_FAILED = 9340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALIDATING = 9341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRE_AUTHENTICATION = 9342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_CONNECT_INVALID_USER_FORMAT = 9343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRIVILEGE_DENIED_FORMAT = 9344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_HIT_MATCHPLATE = 9346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_FAILOVER_ARCHIVERS = 9347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_METADATA_ERROR = 9348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_DVR_INPUTS = 9349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_KEYBOARDS = 9350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_INVALID_FILE = 9351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_INVALID_FILE_DETAIL = 9352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_OPEN_CUSTOM_G64 = 9353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_OPEN_BENCHMARK_RESULT = 9354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_EXPORT_RESULT = 9355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_CLEAR_BENCHMARK_HISTORY = 9356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_CONFIRM_CLEAR_BENCHMARK_HISTORY = 9357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_ERROR_DETAIL = 9358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_NO_RESULT_SELECTED = 9359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_BENCHMARK_FAILED = 9360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_CANT_RECOVER_BENCHMARK = 9361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_SHOW_ERROR_DETAIL = 9362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_SHOW_DETAILS = 9363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_BENCHMARK_ENDED_WITH_ERROR = 9364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TASK_FAILED_DETAIL = 9365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_ERROR_LOADING_RESULT = 9366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_EXPORT_ERROR = 9367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_RUNNING_ASSESSMENT = 9368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_VIDEO_FORMAT = 9369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TITLE_DESCRIPTION = 9370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TITLE = 9371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_WHAT_IS_RATED = 9372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_SUBSCORE = 9373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_GLOBAL_INDEX = 9374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_LAST_MEASURED_ON = 9375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_VIEW_ASSESSMENT_HISTORY = 9376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_LAOD_CUSTOM_FILES = 9377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_RE_RUN = 9378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_RESULT_INFORMATION = 9379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TEXT_RESULT_INFORMATION = 9380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_TASK_NAME = 9381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESULT = 9382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_FILE_USED = 9383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_COMPRESSION = 9384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_ERRORS_DURING_BENCHMARK = 9385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_CUSTOM_BENCHMARK = 9386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_TASK_LIST = 9387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_SUBRESULTS = 9388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_BASE_SCORE = 9389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_ERRORS_DURING_ASSESSMENT = 9390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TOOLTIP_EXPORT_RESULT = 9391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TOOLTIP_DELETE_RESULT = 9392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TOOLTIP_LOAD_RESULT = 9393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_BUTTON_CLEAR_HISTORY = 9394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_RAW_DATA = 9395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_DETAILED_INFO = 9396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_NO_DESCRIPTION = 9397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TEST_CUSTOM_FILE = 9398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TEXT_ERROR_DETAIL_1 = 9399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TEXT_ERROR_DETAIL_2 = 9400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TEXT_ERROR_DETAIL_3 = 9401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_RECOMMENDATION_HEADLINE = 9402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_RECOMMENDATION_SIMULTANEOUSLY = 9403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_RECOMMENDATION_STREMS_SIMULTANEOUSLY = 9404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_VIDEO_PLAYBACK = 9405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_TEST_DECODING_RENDERING = 9406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_CATEGORY_HIT_DATE = 9407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_CATEGORY_HIT_TIME = 9409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_CATEGORY_READ_TIME = 9410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_EMAIL_NOTIFICATION_DEFAULT_SUBJECT = 9411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_EMAIL_NOTIFICATION_DEFAULT_BODY = 9412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HASHTAG_CATEGORY_READ_DATE = 9413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_READ1_TIMESTAMP = 9414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_READ2_TIMESTAMP = 9415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_COPY_CONFIG_SYNCHRONIZED_CARDHOLDERS_SOURCE = 9416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_COPY_CONFIG_SYNCHRONIZED_CARDHOLDERS_DESTINATION = 9417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHECK_LOG_DETAILS = 9418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AD_USER_IMPORT_ERROR = 9419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITY_HAS_WARNINGS_CLICK_HERE = 9421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITY_IN_BACKWARD_COMPATIBILITY_MODE = 9422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_USE_SIMPLE_MATCHER = 9423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_ENABLE_PLATE_EDITING = 9424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CONSOLE = 9425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_MISCELLANEOUS = 9426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_FAILURE = 9427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_SUCCESS = 9428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_OLD_BACKUPS_CANNOT_BE_DELETED = 9432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CAMERA_DIAGNOSTIC_SCHEDULE_CONFLICT_COMPRESSION = 9433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_TCP = 9434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_HTTP = 9435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_MULTICAST_PORT = 9436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGIN_SUPERVISORPASSWORDEMPTY = 9437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_TCP_FAILURE = 9438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_HTTP_FAILURE = 9439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_CAMERA_BLOCK = 9440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_CAMERA_UNBLOCK = 9441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_CAMERA_BLOCKED = 9442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_CAMERA_UNBLOCKED = 9443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOW_TIREIMAGE = 9444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_CLOUDUNITMANAGER_DESCRIPTION = 9446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SYSTEM_WIDE = 9447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SPECIFIC_AREA = 9448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_PROCESS_TERMINATED = 9452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_PROCESS_FAILED_TO_CREATE = 9453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_CANNOTCONNECT = 9454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_LOADERROR = 9455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_INVALID_PLAYER_STATE = 9456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OMNICAST_WRONG_COMPATIBILITYPACK = 9457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXPORT_PLAYBACK_SOURCE_DELETED = 9458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYSOUNDONTOOMANYREADS = 9459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOGGLE_RESOLUTION = 9460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_SPACE_COUNT_PER_ROW = 9461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WEBSERVERINVALIDURL = 9462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_PROXY_SERVER = 9463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_PROXY_SERVER = 9464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROXY_SERVERS = 9465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PING_UNIT = 9466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONNECTINGTOWEBAUTHENTICATIONSERVER = 9467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONECTERRORTOAUTHENTICATIONWEBSERVER = 9468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROXY = 9469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MLPISPACECOUNT = 9470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_ALL_READS = 9471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZATION_ERRORS = 9472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BESTEFFORT = 9473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MINBITRATE = 9474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACK = 9475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MLPI_TOOMANYREADS = 9477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOO_MANY_READS = 9478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_VISUAL_TRACKING_LOAD_CONFIGURATION = 9479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_STRATOCASTCOORDINATOR_NAME = 9480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_STRATOCASTCOORDINATOR_DESCRIPTION = 9481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_FILE_PATH = 9482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_DISPLAY_NANE = 9483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PERF_INDEX_LABEL_MAX_NUMBER = 9484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MAXBITRATE = 9485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOW_REGIONS = 9486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MLPI_READS_DELETION = 9487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MLPI_READS_DELETION_CONFIRMATION = 9488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MLPI_READS_DELETION_FAILED = 9489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MLPI_READS_DELETION_SUCCEEDED = 9490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_METRES = 9492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_AUTO_TRACKING_HEIGHT = 9493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_AUTOTRACKING = 9494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLETOFETCHONLINEHELP = 9495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_ROLE_SPECIFIC = 9496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_AUTOTRACKING_SELFRETURN = 9497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_AUTOTRACKING_SELFRETURN_TIMER = 9498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HITMATCHER_ENABLENOEVENT = 9499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_AUTOTRACKING_SELFRETURN_TOOLTIP = 9500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_VIQSWINDOW = 9502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_ENTITYTODISPLAY = 9503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_H264VIQS = 9504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INITIALIZING = 9505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_NOMATCHEVENT = 9506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOLDER_EXPORTED_FILES_SAVED = 9507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ENCRYPTION_KEY_LENGHT = 9508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_H264VIQS_STREAM = 9509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_ENABLE_THUMBNAILS_REQUESTS = 9510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_VIQS_TOOLTIP = 9511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MLPI_CURRENT_VS_AVAILABLE = 9513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SKIP = 9514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACFAIL = 9515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACFAIL_DESC = 9516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BATTERYFAIL = 9517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BATTERYFAIL_DESC = 9518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAMPER = 9519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAMPER_DESC = 9520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCFAILURE = 9521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCFAILURE_DESC = 9522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_VIDEOQUALITY_CAPABILITIES_NOT_EQUALS = 9527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAVORITES_AND_RECENTLY_USED = 9528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALLED_COMPONENTS = 9529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALLED_UPDATES = 9530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALL_DATE = 9531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLOCK_LEVEL = 9532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROWSE_ALL_TASKS = 9533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARGUMENTS_LONG = 9534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MISSING_CONFIG_TOOL_SPECIFIC_VERSION = 9535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MISSING_CONFIG_TOOL = 9536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EDIT_FEDERATED_ENTITY_SAME_VERSION = 9537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_LOG_OFF_AND_LOG_ON = 9538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ENTRYASSUMED = 9539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_CAMERAPOSITION_WALL = 9540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_CAMERAPOSITION_CEILING = 9541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_CAMERAPOSITION_GROUND = 9542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERAPOSITION = 9543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNBLOCK_CAMERA = 9544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_UNBLOCK = 9545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_INVALID = 9546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILTOOPENSNAPSHOTS = 9547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILTOPRINTSNAPSHOT = 9548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_LICENSE_INVALID_HARDWARE_CHANGE = 9549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEXT_ITEM = 9550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVIOUS_ITEM = 9551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEARED_CONDITION = 9552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNASSIGNED_CLICK_TO_ASSIGN = 9553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISASSOCIATE_CREDENTIAL = 9554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MLPI_READS_DELETION_ROW = 9555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MLPI_READS_ROW_DELETION_CONFIRMATION = 9556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_CBR = 9557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_AUTOFOCUS = 9561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_START_REDIRECTOR = 9571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_ENTITIES_SYNC_IN_PARTITION = 9572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UNIT_WILL_REBOOT = 9573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MODE_HIGH_FPS = 9574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_OVERTIME_ENABLE_LOGS = 9575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZE_DIRECTORY_CONFLICT = 9577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_RETRIEVING_ALL_PAGES = 9578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_RETRIEVING_PAGE = 9579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DBVERSION_HIGHER_THEN_CURRENT = 9580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ELEVATOR_DUPLICATE_PUSHBUTTONS = 9581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ELEVATOR_DUPLICATE_FLOORTRACKING = 9582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIRRORING_CONFIGURATION = 9583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MIRRORING_CONFIGURATION = 9584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_CAMERA_VIDEO_GHOST = 9585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOTRACKING_START = 9586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AUTOTRACKING_STOP = 9587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PASSWORD_CANNOT_NOT_BE_EMPTY = 9588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUBLIC_SERVERS = 9589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_CATEGORY_EXTREME = 9590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_CATEGORY_EQUAL = 9591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_CATEGORY_HIGHLIGHT = 9592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_CATEGORY_MIXED = 9593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_CATEGORY_HORIZONTAL = 9594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_CATEGORY_VERTICAL = 9595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_CREATE_INCIDENT = 9596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_ACCESS_TROUBLESHOOTER = 9597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_PRIVILEGES_TROUBLESHOOTER = 9598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_UNIT_DISCOVERY_TOOL = 9599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_UNIT_REPLACEMENT = 9600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_IMPORT_TOOL = 9601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_COPY_CONFIG = 9602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_SNAPSHOTS = 9603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_ADD_UNIT_STATUS = 9604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_FIRMWARE_UPGRADE_STATUS = 9605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_DATABASE_ACTION_STATUS = 9606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_THREATS = 9607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_LAUNCH_SECURITY_DESK = 9608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_LAUNCH_CONFIG_TOOL = 9609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_MOVE_UNIT = 9610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_EQ_IR_CUT_FILTER_AUTO = 9611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_EQ_IR_CUT_FILTER_OFF = 9612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_EQ_IR_CUT_FILTER_ON = 9613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_PLAY_AUDIO_CLIP_1 = 9614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_PLAY_AUDIO_CLIP_2 = 9615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_PLAY_AUDIO_CLIP_3 = 9616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_ZOOM_IN_FINE = 9617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_ZOOM_OUT_FINE = 9618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_WHITE_LED_ON = 9619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_WHITE_LED_OFF = 9620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_PANASONIC_AUTOBACKFOCUS = 9621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_VCS_RESET_IVA_COUNTERS = 9622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RECONNECT = 9623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXCEPTION = 9624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADD_UNIT = 9625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ROLE_REJECTED_SERVER = 9626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_DATE = 9627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DVR_ALARM_ACTIVE = 9628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DVR_ALARM_INACTIVE = 9629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DVR_CAPACITY_CHANGED = 9630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DVR_DISK_FULL = 9631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DVR_RECORDING_START = 9632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DVR_RECORDING_STOP = 9633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DVR_TIME_DIFFERENCE = 9634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_NOACCESSRULEASSIGNED = 9635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_NOACCESSRULEASSIGNED = 9636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_WILDCARDS = 9637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_MEGA_SD = 9638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_MEGA_SD_FACE_DETECTION = 9639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPR_VALID = 9640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPR_INVALID = 9641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPR_INVALID_DETAILS = 9642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPR_FILENOTFOUND = 9643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPR_FILENOTFOUND_DETAILS = 9644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPR_INVALID_CREDENTIALS = 9645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPS_EXTRACTOR_GROUP = 9646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REMOVE_FROM_LIST = 9647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REMOVE_FROM_INVENTORY = 9648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADVANCED_VIDEO_SYNCHRONIZATION = 9649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOSAMPLINGRATE_48KHZ = 9650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGEGENETECSERVER_IPV6_PARAM_DESC = 9651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MOTIONDETECTION_ZONE_COUNT_SINGLE_LIMIT = 9652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MOTIONDETECTION_ZONE_COUNT_MULTIPLE_LIMIT = 9653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAXIMUM_ITEM_COUNT = 9654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MINIMUM_ITEM_COUNT = 9655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNKNOWN_FILE_FORMAT = 9656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_PREVENT_LOCALADDRESS = 9657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SHORTCUT_CANNOT_BE_MODIFIED = 9658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINCIPAL_DATABASE = 9659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIRROR_DATABASE = 9660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_NAME_HERE = 9661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_VALUE_HERE = 9662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_PTZ_ON_THIS_ENCODER = 9663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_COPY_STATISTICS_SELECTED_VIDEO_TILE = 9664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SHOW_STATUS_OVERLAY_VIDEO_TILE = 9665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_TOGGLE_DIGITAL_ZOOM = 9666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REGION_NAME = 9667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SHOW_STATISTICS_OVERLAY_VIDEO_TILE = 9668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_WATERMARK_NOT_ENABLED_FOR_ALL_SEQUENCES = 9669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_CAMERADISPLAYMODE_NAVIGATION = 9670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_CAMERADISPLAYMODE_PERIMETER = 9671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AD_CREDENTIAL_IMPORT_ERROR = 9672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_DATABASE_DIAGNOSTIC_CANNOT_VERIFY = 9673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_CARD_DATA = 9674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLES_AND_UNITS = 9675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_COULD_NOT_CONFIGURE_RECOVERY_MODE = 9676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERIFY_WATERMARK = 9677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANOMORPH_LENSTYPE_RPL = 9678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLICKTOCLOSE = 9679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_SMC_FIRMWARE_PACKAGE = 9680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_DIGITAL_ZOOM_ON_CAMERA = 9681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITY_CLEARANCE_RESET_SYSTEM = 9682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITY_CLEARANCE_RESET_AREA = 9683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_USER_LEVEL_RESET = 9684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_LEVEL_VALUE = 9685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SECURITY_CLEARANCE_RESET = 9686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_USER_LEVEL_RESET_SYSTEM = 9687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESET_ALL = 9688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_HIT_MATCHPLATE_AND_STATE = 9689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_ARCHIVE_SELECTED = 9690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONNECT_KEYBOARD = 9691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPRESS_BACKUP_FILE = 9692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_ENABLE_VIRTUAL_KEYBOARD = 9693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_CHECKING_LISTENING_PORT = 9694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_FAILEDQUERYINTERFACES = 9695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURATION_OF_THE_EXPORT_CANNOT_EXCEED_X_MINUTES = 9696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURATION_OF_THE_EXPORT_MUST_BE_LARGER_THAN_X_MINUTES = 9697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_CANNOT_BE_COMPLETED_BECAUSE_START_DATE_BEFORE_X = 9698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_CANNOT_BE_COMPLETED_BECAUSE_END_DATE_AFTER_X = 9699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HINT_ANNOTATION_EMAIL_ADDRESS = 9700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SWITCH_HIGH_RESOLUTION = 9701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAPPING_TYPE_BENOMAD = 9702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_UNIT_METRIC = 9703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_UNIT_IMPERIAL = 9704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_QUERYING_INTERFACES = 9705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_EXCLUSIONFILTER = 9706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_MAPPING_INTERFACES = 9707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_MAPPED_INTERFACES = 9708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_MAPPING_READERS = 9709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_MAPPED_READERS = 9710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DRAW_LINE = 9711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DRAW_RECTANGLE = 9712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DRAW_ELLIPSIS = 9713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT = 9714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSERT_TEXT = 9715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_IMAGE = 9716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_BARCODE = 9717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_EDGESTORAGE_FAILURE = 9718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_SERVER_ON_MASTER_DATABASE = 9719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_FAILOVER_SERVER = 9720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_EDGERECORDING_COMMAND_TIMEOUT = 9721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_EDGEEVOMODULEFIRMWAREVERSIONUNSUPPORTED = 9722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IDLE_COMMAND = 9723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNAVAILABLE_MONITOR = 9724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INACTIVE_MONITOR = 9725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PARKING_FACILITY = 9726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EDIT_HOTLIST = 9727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EDIT_PERMIT = 9728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_MLPI_ENABLE_DELETION = 9729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_MLPI_ENABLE_MODIFICATION = 9730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_MLPI_ENABLE_TOO_MANY_READS_POPUP = 9731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_MLPI = 9732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIMITED = 9733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYBACK_CAPACITY = 9734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_CAPACITY = 9735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_STREAM_CAPACITY_EXCEEDED_LIVE = 9736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_STREAM_CAPACITY_EXCEEDED_PLAYBACK = 9737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTIONS_OPERATION = 9738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MEDIA_PLAYER_DOES_NOT_RECOGNIZE_THE_SCHEME = 9739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLESYMBOLICLINKS = 9740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ENABLESYMBOLICLINKS = 9741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_UNC_ROOT_SHARE = 9742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYBACK_OFFSET = 9743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_JUMP_BACKWARD_FORWARD = 9744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MLPI_READS_DELETION_PARKINGFACILITY = 9745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MLPI_READS_PARKINGFACILITY_DELETION_CONFIRMATION = 9746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_WAIT_DLG_REFRESH_UNIT = 9747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_ACTIVATE_MONITOR = 9748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BACKUP_NOW = 9749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESTORE_NOW = 9750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENTITY_CANNOT_MOVE_DELETE = 9751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UPDATE_MOBILE_SHARP_SUCCEED = 9752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UPDATE_MOBILE_SHARP_FAIL = 9753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOHIDE = 9754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_LIVE_TIMELINE = 9755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_ALWAYS_DISPLAY_LIVE_TIMELINE = 9756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_USERINTERACTION_ASK_TO_ACCEPT_SENT_TASKS = 9757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_OPEN_SENT_TASKS = 9758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_IMPORTTOOL_EMAIL_FAILED = 9759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_INSUFFICIENT_SECURITY_LEVEL = 9760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CALIBRATION = 9761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMMERVISION_CALIBRATION_WARNING = 9762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMMERVISION_CALIBRATION_INFO = 9763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_RETRIEVINGENTITIES = 9764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CAMERA_USE_TRICKLING = 9765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CALIBRATION_NO_VIDEO = 9766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CALIBRATION_FAILED = 9767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CALIBRATION_NONE = 9768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CALIBRATION_SUCCESS = 9769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CALIBRATION_CALIBRATING = 9770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_DISPLAYED = 9771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_MORE = 9772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_EDGEEVO_EDM_GRP1_AND_ELM_GRP1_CANNOT_COHABIT = 9773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CAMERA_BLOCKING_UNBLOCKED = 9774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_BLOCKING_INDEFINITELY = 9775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_BLOCKING_BLOCKFROM = 9776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TRAY_SYSTEMMESSAGES = 9777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTEMONITORING = 9778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TRIGGERALARMS = 9779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_RECORDING_ACTION_NOW = 9780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VIRTUALCAMERA_NO_OUTPUT = 9781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_BYPASS_ENFORCEMENT_SET_OFF = 9782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEE_ALL_X_RESULTS = 9783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_MORE_RESULTS = 9784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WDR = 9785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PRIVILEGES_ACTIONS = 9786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SET_ENTITIES_READONLY = 9787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SET_ENTITIES_READWRITE = 9788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_CAMERACONFIGURATION = 9789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_DETAILS = 9790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PTZ_PRIORITY_OVERRIDES = 9791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_OFFLOAD_EVENTS = 9794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREATEARCHIVERDATABASE_DELETEVIDEOFILES = 9804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_VIDEOFORMAT = 9805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_KFI = 9806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_NETWORK_SETTING = 9807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_RECORDING_MODE = 9808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MASK_OPEN_TOO_LONG_EVENTS = 9809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MASK_ACCESS_DENIED_EVENTS = 9810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_UNLOCKED_BY_SCHEDULE = 9811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_FORCEACKNOWLEDGEALARMS = 9812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_FORMAT_PROGRESS = 9813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_EXCELFILES_RECENT = 9814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_OPTIONS = 9815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_STREAM = 9816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYBACK_SOURCE = 9817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_EXPORT = 9818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXCEL_REPORTING_ERROR_GENERIC = 9819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXCEL_REPORTING_ERROR_NOT_INSTALLED = 9820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXCEL_REPORTING_VERSIONING_ERROR = 9821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_APPLY_TEMPLATE = 9822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_RETRY = 9823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_INTERRUPTED = 9824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_FORCESTOP = 9825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_BUILDING = 9826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_RECV_FAILURE = 9827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_NOVIDEO = 9828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_NOSEQUENCE = 9829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_INCOMPLETE_LASTSEQ = 9830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_SEQRECV_FAILURE = 9831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_INFO = 9832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHAT_KIND_MAP = 9833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEB_MAP_DESCRIPTION = 9834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILEPLUGIN_MAP_DESCRIPTION = 9835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEB_SITE = 9836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILE_PLUGIN = 9837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PRIVILEGE_EXCEPTION = 9838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ADD_EXCEPTION = 9839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_VIA_AREA_Y = 9840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_TIME = 9841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAME_DURATIONS = 9842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INDEPENDENT_DURATIONS = 9843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_FRAMES = 9844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRETRIGGER_TIME = 9845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STILL_FRAMES = 9846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_AND_PLAYBACK = 9847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_AND_STILL_FRAMES = 9848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STILL_FRAME_TIMES = 9849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STILL_FRAME_DURATIONS = 9850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_FRAME_PRETRIGGER_TIME = 9851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DURATION_LONGER_THAN_DWELL_TIME = 9852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_SECURITYCENTER_HITS_AND_ASSOCIATED_READS = 9853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_ERROR_ENTITY_IN_USE = 9854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS_CHANGED = 9855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXCEPTIONS_FOR_X = 9856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_VISUAL_TRACKING_NOT_SUPPORTED = 9857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_CAMERA_CONFIGREPORT = 9858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_PRIVATESECTION = 9859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_RESTRICTEDSECTION = 9860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMINISTRATOR = 9861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SET_PARTITION_ADMINISTRATOR_OF_X = 9862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNIT_ALREADY_CONFIGURED = 9863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INCOMPATIBLE_CONFIGURATIONS = 9864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RESETVIDEOUNITS = 9865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_METADATA = 9866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ROLE_SERVERS_CLEAR_WARNINGS = 9867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SWITCH_WALL_MODE = 9868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SWITCH_SIMPLE_MODE = 9869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROWS = 9870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLUMNS = 9871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ZEROISUNLIMITED = 9872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ON_CLOSE = 9873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_RELOCK = 9874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_RELOCK_WITH_DELAY = 9875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_3X3 = 9876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_4X4 = 9877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_5X5 = 9878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_3X3 = 9879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_4X4 = 9880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_5X5 = 9881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_6X6 = 9882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_7X7 = 9883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_8X8 = 9884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ANTIPASSBACK_SCHEDULE_WITH_VERTX = 9885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CANNOT_LINK_UNIT_TO_SAME_CHANNEL = 9886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHANNEL_ASSOCIATION = 9887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_SIDE_A_TO_SMC_CHANNEL = 9888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_SIDE_B_TO_SMC_CHANNEL = 9889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTERFACE_MODULE = 9890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTERFACE_MODULES = 9891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_INTERFACE_MODULES = 9892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIRSTINRULE_CONFIGURATION_ERROR = 9896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_FIR_NOSUPERVISORS_ERROR = 9897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_FIR_MAXSUPERVISORS_ERROR = 9898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ANTIPASSBACK_DISABLED = 9899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ANTIPASSBACK_INVALIDSETTINGS_ERROR = 9900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ANTIPASSBACK_UNITMIXMODE_ERROR = 9901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ANTIPASSBACK_UNIT_ERROR = 9902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_PEOPLECOUNT_DISABLED = 9903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_CONFIGURATION_ERROR = 9904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_ELEVATOR_ERROR = 9905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_MULTIPLEUNITS_ERROR = 9906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_UNSUPPORTED_ERROR = 9907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_MAINTENANCEMODE_ERROR = 9908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_UNLOCKSCHEDULE_ERROR = 9909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_ANTIPASSBACK_ERROR = 9910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAS_FLOORSINAREA_ERROR = 9911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_RTSP_PORT_ALREADY_IN_USE = 9912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_FIR_NOTSUPPORTED_ERROR = 9913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMIT_RULE = 9914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_DOORS_UNLOCK_SCHEDULES = 9915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_ACCESS_RULES = 9916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_PERSON_RULE = 9917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISORS = 9918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BYPASS_LIST = 9919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_IP_RANGE_INVALID = 9920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MAP_ROUNDABOUT = 9921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MAP_RAMP = 9922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_MAP_BRANCH = 9923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_FOLDER = 9924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERSONAL_INFORMATION = 9925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_SETTINGS = 9926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE = 9927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPT_CONNECTED = 9928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGON_SETTINGS = 9929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVERY_LC = 9930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_PERMISSION = 9931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_PERMISSION_SOURCE = 9932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_PERMISSION_DESTINATION = 9933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_LOCKED = 9934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_ABOUT_TO_LOCK = 9935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_INACTIVE = 9936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_LOCK = 9937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_INFORMATION_ANONYMOUS = 9938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEEDBACK_ATTACHMENTS = 9939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SYSTEM_INFORMATION = 9940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ATTACH_FILES = 9941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ATTACH_SCREENSHOTS = 9942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SEND = 9943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STILL_FRAME_CAMERA_NAME = 9944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SEND_FEEDBACK = 9945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEEDBACK_SENDING_TOOLTIP = 9946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DROPPIN_LEARN_INFO = 9947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MODIFY_NONEXISTANT_SHAPE = 9948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOVE_NONEXISTANT_SHAPE = 9949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CREDENTIAL_REQUEST_ACTIVITY_EVENTS_NOTCONFIGURED = 9951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ACTIVITY_TRAIL_EVENTS_NOT_CONFIGURED = 9952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_TIME = 9953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SYNCHRONIZATION_RECURRENCE = 9955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BUZZER_BEHAVIOR_SUPPRESSED = 9958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BUZZER_BEHAVIOR_SUPPRESSED_WHEN_DOOR_CLOSES = 9959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BUZZER_BEHAVIOR = 9960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AT_CC = 9961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEXT_UPDATE = 9962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURATION_EXPIRES_ON = 9963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_FORCE_REFRESH = 9964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLINE_SYNCHRONIZATION_FREQUENCY = 9965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OFFLINE_SYNC_DAILY_SCHEDULE_EMPTY = 9966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_OFFLINE_SYNC_FORCE_REFRESH = 9967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUAL = 9968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC_SYNCHRONIZATION = 9969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUAL_SYNCHRONIZATION = 9970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCHEDULED_SYNCHRONIZATION = 9971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OFFLINE_SYNC_WARNING = 9972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_TAMPERING_OFF = 9973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GHOST = 9974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_DIGITALAUTOTRACKING_START = 9975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_DIGITALAUTOTRACKING_STOP = 9976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_RELOCK_ON_CLOSE_NOT_SUPPORTED_VERTX = 9977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_MAXIMUM_DELAY_RELOCK_VERTX = 9978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIGITALAUTOTRACKING = 9979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_DOOR_RELOCK_CONFIGURATION = 9980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BENOMAD_FOLDER_NOT_FOUND = 9981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONTYPE_MOBILECLIENT = 9982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMINISTRATORS_DESCRIPTION = 9983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_PRIVILEGE_DESCRIPTION = 9984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTING_PRIVILEGE_TEMPLATE_DESCRIPTION = 9985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_PRIVILEGE_TEMPLATE_DESCRIPTION = 9986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVESTIGATOR_PRIVILEGE_TEMPLATE_DESCRIPTION = 9987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISOR_PRIVILEGE_TEMPLATE_DESCRIPTION = 9988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROVISIONING_PRIVILEGE_TEMPLATE_DESCRIPTION = 9989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_OPERATOR_PRIVILEGE_TEMPLATE_DESCRIPTION = 9990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_ILLUMINATION_ON = 9991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_ILLUMINATION_OFF = 9992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PRESET_RECALL_SPEED = 9993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_CAMERA_ON = 9994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_CAMERA_OFF = 9995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_WIPER_ON = 9996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_WIPER_OFF = 9997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PUMP_ON = 9998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PUMP_OFF = 9999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_AUX_F0_ON = 10000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_AUX_F0_OFF = 10001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_AUX_F1_ON = 10002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_AUX_F1_OFF = 10003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_START_WIPER_CYCLE = 10004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_ALL_RELAYS_ON = 10005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_ALL_RELAYS_OFF = 10006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_RELAYS_1ON_2OFF = 10007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_RELAYS_2ON_1OFF = 10008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_RELAYS_12ON = 10009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_RELAYS_12OFF = 10010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_START_PRESET = 10011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_STOP_PRESET = 10012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_DELAY = 10013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_ADD_PRESET = 10014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_REMOVE_PRESET = 10015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_PAN_SPEED = 10016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_TILT_SPEED = 10017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_END_STOP_RIGHT = 10018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_END_STOP_LEFT = 10019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_END_STOP_UP = 10020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_END_STOP_DOWN = 10021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_ACTIVATE_END_STOP_POSITIONS = 10022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_DEACTIVATE_END_STOP_POSISIONS = 10023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_ACTIVATE_RANDOM_MODE = 10024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_DEACTIVATE_RANDOM_MODE = 10025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_MAX_SPEED_RANDOM_MODE = 10026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_MIN_SPEED_RANDOM_MODE = 10027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_MAX_TIME_RANDOM_MODE = 10028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_MIN_TIME_RANDOM_MODE = 10029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_ACTIVATE_PARK_MODE = 10030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_DEACTIVATE_PARK_MODE = 10031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_DELAY_PARK_MODE = 10032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_AUTOPAN_ON = 10033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_AUTOPAN_OFF = 10034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_AUTOPAN_SPEED = 10035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_AUTOPAN_DURATION = 10036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_MODE_STOP = 10037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_INITIALIZATION = 10038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BUZZER_BEHAVIOR_SUPPRESSED_ON_ACCESS_GRANTED = 10039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOLOCK = 10040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NETWORK_CARD_NOT_FOUND = 10041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARECONT_WDRENABLED = 10042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_EVENTS = 10043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STILL_FRAME_BEFORE_ALARM_TIME = 10044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STILL_FRAME_AFTER_ALARM_TIME = 10045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_ESCORTREQUIRED = 10046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_ESCORTREQUIRED = 10047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_CARDHOLDERREQUIRED = 10048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_CARDHOLDERREQUIRED = 10049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_STREAMING_SCHEDULE = 10050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASK_BEFORE_UNIT_SYNC = 10051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VISITORESCORT_CONFIGURATION_ERROR = 10052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_VISITORESCORT_NOTSUPPORTED_ERROR = 10053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TWORULE_CONFIGURATION_ERROR = 10054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_TOWRULE_NOTSUPPORTED_ERROR = 10055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GRANT_TIME = 10056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STANDARD = 10057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTENDED = 10058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGER_EVENT = 10059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_FORCED = 10060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_HELD = 10061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUEST_TO_EXIT_PLUS_ACRONYM = 10062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADD_NONEXISTANT_SHAPE = 10063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DRAW_POLYGON = 10064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HAS_TIMEOUT = 10065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATICALLY_CLOSE_AFTER = 10066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_PROGRESSION = 10067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_360_FEATURES = 10068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_HORIZONTAL = 10069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_VERTICAL = 10070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_CIRCULAR = 10071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_2M_DOUBLE_PANO_CEILING = 10072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1M_DOUBLE_PANO_CEILING = 10073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1_3M_QUAD_PTZ_CEILING = 10074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1_3M_SINGLE_PTZ_CEILING = 10075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_DOUBLE_PANO_QUAD_PTZ_CEILING = 10076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_DOUBLE_PANO_SINGLE_PTZ_CEILING = 10077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_2M_PANO_WALL = 10078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1M_PANO_WALL = 10079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1_3M_QUAD_PTZ_WALL = 10080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1_3M_SINGLE_PTZ_WALL = 10081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_PANO_QUAD_PTZ_WALL = 10082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_PANO_SINGLE_PTZ_WALL = 10083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_3M_FISHEYE_CEILING = 10084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1_3M_FISHEYE = 10085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_VGA_QUAD_STREAMS_CEILING = 10086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DATABASE_TASK_TIME = 10087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTION_SETTINGS = 10088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEB_ADDRESS = 10089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_USE_DHCP = 10090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IGNORE_WEB_PROXY = 10091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERED_PROPERTIES = 10092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_USE_MIXED_MODE = 10093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_FACE_DETECTED = 10094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TITLE = 10095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CATEGORY = 10096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_CATEGORIES = 10097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_SEQUENCES = 10098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_SEQUENCES = 10099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMINISTRATOR_OF = 10100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT = 10102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ATTACHMENT = 10103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_START_INCIDENT_RECORDING = 10104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_STOP_INCIDENT_RECORDING = 10105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_NOTIFY = 10106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE = 10107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVAILABLE_COLUMNS = 10108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTED_COLUMNS = 10109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RENAME = 10110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_FULL_REPORT = 10111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_VIEW_ENTRIES = 10112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_MODIFY_ENTRIES = 10113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_DELETE_ENTRIES = 10114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_ADD_ENTRIES = 10115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PORTAL = 10116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LOAD_HOTLIST_WITH_VIEW = 10117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LOAD_HOTLIST_WITHOUT_VIEW = 10118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_UNCHECK_PARTITION_WARNING = 10119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_GROUPSECURITYVIOLATION = 10120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DEFAULT_RELAY_NAME = 10121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_MAX_SUPPORTED = 10122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SMC_NOTSUPPORTED_ERROR = 10123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIN_FIRMWARE_REQUIRED = 10124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_NEXT_SEQUENCE = 10125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PREVIOUS_SEQUENCE = 10126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOTAL_COUNT = 10127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MAPDESIGNER = 10128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_MAPDESIGNER = 10129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_LIVE_CAMERA_NAME = 10130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_PLAYBACK_CAMERA_NAME = 10131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_PLATE_LIST = 10132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKGROUND_PROCESS_TRAY = 10133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ATTACHMENTS = 10134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENDING_FEEDBACK = 10135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEEDBACK_SENT = 10136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ATTACH = 10137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOTAL_DURATION = 10138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTENT_CYCLING = 10139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_SEC_BEFORE_ALARM = 10140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_VIDEO_ARCHIVE_DELETED = 10141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_FOLDER = 10142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_VIDEO_ARCHIVE_DELETED_DESCRIPTION = 10143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REVERT_INHERITED_VALUE = 10144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_OUTPUT_TRIGGERED = 10145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREFERRED_INTERFACE = 10146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INTERFACE_AND_UNIT_MISMATCH = 10147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_OUTPUT_TRIGGERED_DESCRIPTION = 10148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATE_MODIFIED = 10149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_PARTITIONS = 10150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVIOUS_DIRECTORY = 10151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMPTY_FOLDER = 10152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_STOPPED_TIMEDOUT = 10153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SAVE_NOTIFY = 10154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSERT_AN_IMAGE = 10155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INCIDENT_RECORDING_STARTED = 10156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_CAMERA = 10157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_NETWORK_FILE = 10158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_NETWORK_FILE = 10159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FILE_DOES_NOT_EXIST = 10160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_COULD_NOT_ACCEPT_FILE = 10161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALIGN_LEFT = 10162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALIGN_CENTER = 10163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALIGN_RIGHT = 10164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OMNICAST_DIAGNOSTIC_A_REDIRECTOR_FAILED = 10165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OMNICAST_DIAGNOSTIC_ALL_REDIRECTORS_SUCCESS = 10166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPRUNIT_ENCRYPT_COMMUNICATION_CHANNEL_NOT_SUPPORTED = 10167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPRUNIT_ENCRYPT_COMMUNICATION_CHANNEL_NOT_SUPPORTED_DETAILS = 10168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_ALARM_TRIGGER = 10169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NOT_LOADED_MAP_ENGINE = 10170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VAULT = 10171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_SNOOZED = 10172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ALARM_FORWARDED = 10173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_MANAGEMENT = 10174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDERS_AND_CREDENTIALS = 10175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_ALARM = 10176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_ENTITY = 10177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CHANGE_ICON = 10178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_MAIN_SERVER = 10179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_FAILOVER_SERVER = 10180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PTZ_PRIORITY_ADD_EXCEPTION = 10181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_PARENTS = 10182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONFIGURE_OVERRIDES = 10183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_USER_LEVEL_OVERRIDES = 10184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_LEVEL_OVERRIDES_PTZ_MESSAGE = 10185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_VALUE = 10186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_REQUEST_NOT_SUPPORTED = 10187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VAULT_LOCATION = 10188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLIST_PERMIT = 10189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_DUPLICATE_DELAY = 10190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_ANNOTATIONFIELD = 10191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIST = 10192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_IMAGE_RETENTION = 10193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OMNICAST_FEDERATION_MODE = 10194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_OMNICAST_VIDEOREDIRECTION = 10196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORMAT_SETTINGS_AS_STRING_EXTENDED = 10197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORMAT_SETTINGS_AS_STRING = 10198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTORE_TYPE = 10199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOAD_FROM = 10200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEARCH_IN = 10201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVINGENTITY_FROM_VISIBLEPARTITIONS_WARNING = 10204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOTADDCAMERA_NOARCHIVER = 10205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACCESS_MANAGER_RUNNING = 10206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPRUNIT_DISCOVERY_PORT_MISMATCH = 10207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_LPRUNIT_DISCOVERY_PORT_MISMATCH_DETAILS = 10208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_COMPLETED_NORESULT = 10209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_READS_IMAGE_RETENTION = 10210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_HITS_IMAGE_RETENTION = 10211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_SQLVERSION_ERROR = 10212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LESS = 10213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RGB = 10214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEXADECIMAL_SHORT = 10215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKUP_TO_DISK = 10216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WAIT = 10217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_DATA = 10219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_SOURCES = 10220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN_CONNECTION_RECOVERS = 10221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEC_UPON_RECONNECTION = 10222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_THREATS = 10223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIT_TO_WINDOW = 10224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_CONFIG360 = 10226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_VIDEO_MODE = 10227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_APPLIES_TO_ENTER = 10228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_POINT_RULE_APPLIES_TO_EXIT = 10229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_VAULT = 10230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED_TO_OPEN_VIDEO_FILE = 10231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED_TO_OPEN_FOLDER = 10232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_INVALID_VAULT_PATH = 10233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_FOLDER_PATH = 10234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CHANGING_VAULT_FOLDER = 10235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNAVAILABLE_PREVIEW_ENCRYPTED = 10236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_CUSTOMENTITY = 10237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVING_ENTITY_TO_PARTITION_MAKE_UNAVALIABLE = 10238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OCCURS = 10239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EFFECTIVE = 10240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_EVENT_TO_ACTION = 10241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_DOOR_MANUALLY_UNLOCKED = 10242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_DOOR_MAINTEANCE_MODE_SET = 10243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_DOOR_MAINTENNACE_MODE_CANCELLED = 10244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_LOCKING_OVERRIDE_DOOR_UNLOCK_SCHEDULE = 10245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_UNLOCK_OVERRIDE_DOOR_UNLOCK_SCHEDULE = 10246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_UNLOCK_SCHEDULE_OVERRIDE_CANCELLED = 10247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GO_TO_FILE_LOCATION = 10249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_CUSTOMENTITY = 10250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_CUSTOMENTITIES = 10251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALYTICS = 10252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIO_EXPORT = 10253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_AS = 10254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_G64X_DEFAULT_MULT_EXPORTS = 10255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_BACKUP_FAILED = 10256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_BACKUP_SUCCESS = 10257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNIT_TRICKLING_FAILED = 10258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNIT_TRICKLING_PARTIALLY_FAILED = 10259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNIT_TRICKLING_SUCCESS = 10260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVER_TRICKLING_FAILED = 10261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVER_TRICKLING_PARTIALLY_FAILED = 10262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVER_TRICKLING_SUCCESS = 10263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SET_VIEWABLE_AREA = 10264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_BACKUP_PARTIAL_FAILURE = 10265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_RESTORE_SUCCESS = 10266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_RESTORE_FAILURE = 10267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARHCIVE_RESTORE_PARTIAL_FAILURE = 10268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIDGET_POLYGON = 10269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INVALID_ZONENAME = 10270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPRESENTATION = 10271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEXADECIMAL_LONG = 10272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_ENCODE_FIELD_USING_UTF8 = 10273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_ENCODE_FIELD_USING_UFT8_AND_SPECIFIED_MASK = 10274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MINIMUM_UTF8_FIELD_MASK_LENGTH = 10275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SPECIFY_VALID_MASK = 10276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MAXIMUM_UTF8_FIELD_LENGTH = 10277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UTF8 = 10278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PACKEDBCD = 10279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_MODE = 10280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACKING_PLAYBACK_MODE = 10281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GO_PLAYBACK = 10282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_GO_LIVE = 10283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_GO_PLAYBACK = 10284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NAVBOX_PORT_SET = 10285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NAVBOX_GPS_PORT_INFO = 10286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NAVBOX_GPS_NOT_FOUND = 10287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NAVBOX_PORT_NOT_FOUND = 10288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NAVBOX_INSTRUCTIONS = 10289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLER_OPTION_AUTODETECT_NAVBOX = 10290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XDNR = 10291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUTOVU_RETENTIONPERIODPOPUP_HEADER = 10292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUTOVU_RETENTIONPERIODPOPUP_CONTENT1 = 10293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUTOVU_RETENTIONPERIODPOPUP_CONTENT2 = 10294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_ITEMS_CHECKED = 10301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_TITLE_SHARPPORTAL_ERROR = 10302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_SHARPPORTAL_ERROR = 10303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CENTER_X = 10304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CENTER_Y = 10305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAMETER = 10306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOCAL_LENGTH = 10307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GET_CAMERA_PARAMETERS = 10308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEWS = 10309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIAGNOSTIC_DISKDB_FULL = 10310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_AS_FAVORITE = 10311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_AVAILABLE = 10312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROJECTION = 10313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROTATION = 10314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WALL_ANGLE = 10315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SELECT_MANUFACTURERS = 10316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_UNIT_MANUALLY = 10317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_FAVORITES = 10318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_PATTERN = 10319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_PATTERN = 10320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REMOTE_UNABLE_CONNECT_ACCESS_DENIED_USER_NOT_FOUND = 10321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_BADREQUEST_ERROR = 10322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_STATE_DOWNLOADING_FILES = 10323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_STATE_UPLOADING_FILES = 10324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CLEAR_COMPLETED = 10325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GATHER_DIAGNOSTIC_INFO = 10326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_GENETEC_SERVERS = 10327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_GENETEC_SERVERS = 10328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPLOAD_RESULT_TO_CLOUD = 10329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OPEN_DROP_FOLDER = 10330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DIAGNOSTIC_PROGRESS = 10331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITYTRAIL_EXPORT_VIDEO_ERROR = 10332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DOOR_SIDE_NAME_EMPTY = 10333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DUPLICATE_DOOR_SIDE_NAME = 10334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FREE_ACCESS = 10335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DIRECTORY_FAILOVER_SERVER_REBOOT = 10336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_CONNECTION_MODE = 10337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROADCAST = 10338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNICAST = 10339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKIN_EDIT = 10340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_STATISTICS_REPORT = 10341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_DISK_GROUP = 10342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STATISTICS_REPORT_FORMAT = 10343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURING_THE_NEXT = 10344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AGENT_SUCCESSFULLY_STARTED_ON_X = 10345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SERVICE_LOST_X_Y = 10346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AGENT_FAILED_TO_START_BY_X = 10347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_FAILED_TO_INITIALIZE_ON_AGENT_X = 10348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_STARTED_SUCCESSFULLY_ON_X = 10349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_STARTING_ON_X = 10350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_STOPPING_ON_X = 10351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_STOPPED_ON_X = 10352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CONNECTION_ESTABLISHED_WITH_XYZ = 10353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_XY_DATABASE_USED_BY_ANOTHER_ARCHIVER = 10354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_CONNECTION_LOST_WITH_XYZ = 10355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPDATE_SERVERS_TO_SAME_VERSION = 10356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_ERROR_ON_AGENT_X = 10357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RTSP_SERVER_LISTENING_ON_XY = 10358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RTSP_SERVER_FAILED_TO_LISTEN_ON_XY = 10359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXTENSION_X_COULD_NOT_OPEN_ON_YZ = 10360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHECK_PORT_NOT_IN_USE = 10361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_FAILED_TO_START_ON_X = 10362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MEDIA_ROUTER_CONNECTION_LOST_ON_X = 10363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MEDIA_ROUTER_CONNECTION_SUCCESSFUL_ON_X = 10364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MACHINEKEYS_FOLDER_PERMISSIONS = 10365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DISPLAYING_VIDEO = 10366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_BUFFERING_COMLPLETED = 10367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATE_ALARMS = 10368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CHANGES_FORCE_RESYNC = 10369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTING_SPY_MODE = 10370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTING_LOW_BANDWIDTH = 10371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MOTIONDETECTION_NOT_SUPPORTED = 10372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_DISKGROUP_VALIDATION_CONFLICTS_TITLE = 10373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_DISKGROUP_VALIDATION_CONFLICTS_ITEM = 10374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CAMERA_COUNT_TIME_DETAILS = 10375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CAMERA_COUNT_NOT_RECORDING_LIMIT_EXCEEDED = 10376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CAMERA_COUNT_LIMIT_EXCEEDED = 10377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CAMERA_COUNT_DETAILS = 10378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_ARCHIVING_QUEUE_FULL = 10379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_ARCHIVING_QUEUE_FULL_DETAILS = 10380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_ASF_PROFILE = 10381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_READ_UNDERSTAND_STATEMENT = 10382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIMARY = 10383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STANDBY = 10384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MUST_SPECIFY_VALID_VIDEO = 10385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MUST_SPECIFY_NETWORKTRANSPORT = 10386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNSUPPORTED_FORMAT = 10387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NOTENOUGHBANDWIDTH = 10388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNUSED_CARDS = 10389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INACTIVE_CAMERAS = 10390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_VIDEOUNIT_COLLECT_POSTMORTEM = 10391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SERVER_REGISTERED = 10392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WAITING_FOR_DIRECTORY_STATUS = 10393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_FILE_SIZE = 10394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_FILE_LENGTH = 10395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CAPTURE_ARCHIVER_INFO = 10396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_CUSTOM_FILTER = 10397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_FILE_ROTATION = 10398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CAPTURE_START_ALL = 10399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CAPTURE_STOP_ALL = 10400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NETWORK_TRACE = 10401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_STARTED = 10402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_STOPPED = 10403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_IN_PROGRESS = 10404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_ERROR = 10405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_ALL_TRAFFIC = 10406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_TCP_HTTP = 10407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ROLE_OFFLINE = 10408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ADD_PRESET = 10409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_FILTER = 10410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_H265 = 10411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_MXPEG = 10412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_UNAVAILABLE_SERVERS = 10413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_ALL_SERVERS_AVAILABLE = 10414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSG_COULD_NOT_SEND_FEEDBACK = 10415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNSUPPORTED_ENCODING = 10416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_OPEN_VAULT = 10417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPY_ENCRYPTED_PASSWORD = 10418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTED_PASSWORD_COPIED = 10419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_SPECIFIC_SETTINGS = 10420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADVANCED_DIAGNOSTIC = 10421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TOOLTIP_RETENTION_FOR_INCIDENTS = 10422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TOOLTIP_RETENTION_FOR_AUDITTRAIL = 10423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TOOLTIP_RETENTION_FOR_ALARMS = 10424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DIAGNOSTIC = 10425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_NO_DATA_FROM_UNIT = 10426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SEE_VIDEO_TROUBLESHOOTING_HELP = 10427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_INITIALIZING = 10428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_CONNECTING_TO_MEDIA_ROUTER = 10429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_CONNECTING_TO_ARCHIVER = 10430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_REQUESTING_LIVE_STREAM = 10431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_ANALYZING_STREAM = 10432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_WAITING_FOR_DOWNLOAD = 10433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_REQUESTING_PLAYBACK_STREAM = 10434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_RESUMING_PLAYBACK_STREAM = 10435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_CONNECTING_TO_VIDEO_UNIT = 10436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_REQUESTING_VIDEO_UNIT_STREAM = 10437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_NO_DATA_AVAILABLE = 10438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_NO_DATA_AVAILABLE_FROM_UNIT = 10439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_SEARCHING_FOR_ARCHIVES = 10440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_READING_ARCHIVES = 10441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_BUFFERING = 10442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_SERVER_RESPONSE_TIMEOUT = 10443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_NOT_ENOUGH_BANDWIDTH = 10444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MUTED = 10445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_ZONE_TROUBLE = 10446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_PRIVATE_ADDRESS = 10447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RECIPIENT_INVALID_EMAIL = 10448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROCESS_STARTED = 10449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLISTLANGUAGE_CYRILLIC = 10450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VBR = 10451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNIT_ENROLLMENT = 10452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_NOT_LISTED = 10453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SETTINGS_AND_MANUFACTURERS = 10454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONFIGURE = 10455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CLEAR_ADDED_UNITS = 10456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DISCOVERING_UNITS = 10457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MANUFACTURERS_WE_ARE_LOOKING_FOR = 10458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MANUFACTURERS_NOT_LISTED = 10459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OPEN_MANUFACTURERS = 10460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXTENSIVE_SEARCH_DESCRIPTION = 10461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_RUN_EXTENSIVE_SEARCH = 10462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RUN_EXTENSIVE_SEARCH = 10463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVER_UNITS_AUTOMATICALLY = 10464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONFIGURE_MANUFACTURER = 10465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REMOVE_MANUFACTURER = 10466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_MANUFACTURERS_CONFIGURED = 10467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IGNORED_UNITS = 10468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IGNORED_UNITS = 10469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REMOVE_IGNORED_UNIT = 10470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_IGNORED_UNITS = 10471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REMOVE_ALL_IGNORED_UNITS = 10472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE_AS_DEFAULT_AUTHENTIFICATION_FOR_ALL_MANUFACTURERS = 10473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERED_UNITS = 10474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_ALL = 10475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNITS_FOUND = 10476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_MANUFACTURER = 10477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_ACTIVEDIRECTORY = 10478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_ACTIVITYTRAIL = 10479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_ALARMS = 10480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_AUDIO = 10481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_AUDITTRAIL = 10482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_AUTOVU = 10483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_ENTITYMONITORING = 10484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_HEALTHMONITORING = 10485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_HOTACTIONS = 10486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_INCIDENTS = 10487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_INTRUSIONDETECTION = 10488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_MACROS = 10489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_NETWORKING = 10490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_OMNICAST = 10491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_PARTITIONS = 10492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_PLUGINS = 10493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_REMOTESD = 10494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_SAVEDTASKS = 10495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_SYNERGIS = 10496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_CARDREQUEST = 10497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_BADGETEMPLATE = 10498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_GLOBALCARDHOLDERSYNCHRONIZERS = 10499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_THREATLEVEL = 10500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_TIMEZONE = 10501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_ZONEMANAGER = 10502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITYMONITORING = 10503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH_MONITORING = 10504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORKING = 10505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVITATION_TO_CREATE_OPERATOR = 10506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INITIAL_CONFIGURATION_WIZARD = 10507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMIN_PASSWORD = 10508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURE_ADMIN_ACCOUNT = 10509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INITIAL_CONFIGURATION_COMPLETED = 10510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVAILABLE_FEATURES = 10511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHAT_DO_YOU_NEED = 10512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRO = 10513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WELCOME_TO_SECURITY_CENTER = 10514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_AN_OPERATOR = 10515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SHOW_PASSWORD = 10516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CLOSE_WIZARD = 10517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CLOSE_WIZARD_CONFIRMATION = 10518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_NEVER_SHOW_WIZARD = 10519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERNAME_COPIE = 10520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INITIAL_CONFIGURATION_INTRODUCTION = 10521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SET_ADMIN_PASSWORD = 10522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_COPIE = 10523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIRM_PASSWORD_COPIE = 10524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPLAIN_DEFAULT_FEATURE_VISIBILITY = 10525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHOOSE_DEFAULT_FEATURE_VISIBILITY = 10526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REFER_TO_ABOUT_SECTION = 10527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LAUNCH_DISCOVERY_TOOL = 10528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIG_TOOL_RESTART = 10529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REFER_TO_USERS_SECTION = 10530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RESTART_CONFIGTOOL_FEATURES = 10531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUAL_RESTART = 10532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATURE = 10533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FEATURE_SAVED_LOCAL = 10534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_SET_AUTO_FOCUS = 10535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUXILIARY_OUTPUT = 10536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANASONIC_SELF_RETURN = 10537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_RTSPROLE_NAME = 10538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ROLE_RTSPROLE_DESCRIPTION = 10539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CLEAR_CACHE = 10541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CACHE_CLEARED = 10542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_VIEW = 10543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_VIEW_ON_MAP = 10544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTERFACEMODULENAME = 10545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTION_DISPLAY_FAVORITES_RECENTS_HOMEPAGE = 10546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_TILE_PATTERN_EDITOR = 10547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REVERT = 10548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REVERT_TILE_PATTERN_TO_DEFAULT = 10549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STATIC_IP = 10550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TILE_PLUGIN_INVALID_PROCESSOR_ARCHITECTURE = 10551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOSTNAME_IP = 10552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_SYNCHRONIZATION_NEEDED = 10554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RESTART_APPLICATION = 10555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DURATION_LONGER_THAN_RECURRENCE = 10556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SHORTCUT_SCRATCH_PAD = 10557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BACKUP_DATABASE_BEFORE_UNITSWAP = 10558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONTINUE_CONFIRMATION = 10559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_TEMPERATURE_ALARM = 10560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_OPEN_PDF = 10561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_ACCELERATION = 10562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_DECODING_NOT_SUPPORTED = 10563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRIVER_VERSION = 10564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_USED = 10565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEMORY_USED = 10566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEMORY_USED_FORMAT = 10567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AT_LEAST_ONE_REGIONS_NEEDS_TO_BE_SELECTED = 10568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HITS_MULTIREGION = 10569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_HITS_MULTIREGION_DESCRIPTION = 10570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_READS_MULTIREGION = 10571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_READS_MULTIREGION_DESCRIPTION = 10572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_MANAGE_INCIDENT_CATEGORIES = 10573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_EXPORTED_TO_LOCATION = 10574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_EXPORT_SUCCESSFUL = 10575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_EXPORT_HAS_FAILED = 10576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_OPEN_FILE = 10577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_ANALOGMONITORS = 10578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_VISUALTRACKING = 10579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_VISITORS = 10580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_CPU = 10581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CPU_NAME = 10582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEMORY = 10583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU = 10584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ENGINE_LOAD = 10585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEMORY_CONTROLLER_LOAD = 10586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_BACKUP_STARTED = 10587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_BACKUP_FINISHED = 10588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_BACKUP_FINISHED_ERROR = 10589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVER_TRICKLING_STARTED = 10590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVER_TRICKLING_FINISHED = 10591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVER_TRICKLING_FINISHED_ERROR = 10592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVER_TRICKLING_ERROR = 10593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_BACKUP_ERROR = 10594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_RESTORE_STARTED = 10595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_RESTORE_FINISHED = 10596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_RESTORE_FINISHED_ERROR = 10597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_RESTORE_ERROR = 10598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNIT_TRICKLING_STARTED = 10599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNIT_TRICKLING_FINISHED = 10600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNIT_TRICKLING_FINISHED_ERROR = 10601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_UNIT_TRICKLING_ERROR = 10602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVER_TRICKLING_MANUAL_START = 10603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVER_TRICKLING_MANUAL_STOP = 10604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_UNIT_TRICKLING_MANUAL_START = 10605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_UNIT_TRICKLING_MANUAL_STOP = 10606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVE_BACKUP_MANUAL_START = 10607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVE_BACKUP_MANUAL_STOP = 10608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVE_RESTORE_MANUAL_START = 10609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVE_RESTORE_MANUAL_STOP = 10610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_REPORT_SELECTOR = 10614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_REPORT_SELECTOR = 10615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILTERS = 10616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_RTSP_STREAMS = 10617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_INFORMATION = 10618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_CUSTOM_DATA_TYPE = 10619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_DATA_TYPE_INFORMATION = 10620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_SUCCESS = 10621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_FAILED = 10622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_PARTIALLY_FAILED = 10623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_SUCCESS = 10624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_FAILED = 10625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_PARTIALLY_FAILED = 10626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_NOT_ENOUGH_SPACE = 10627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_NO_PATH_AVAILABLE = 10628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_NOT_ENOUGH_SPACE = 10629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_NO_PATH_AVAILABLE = 10630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_CARD = 10631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATING_SYSTEM = 10632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESOURCES_METER = 10633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_PASSWORD = 10634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PACKAGE_VIDEO_FILES = 10635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE_TO = 10636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPY_PORTABLEVIDEOPLAYER_COMPLETE = 10639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATH_NOT_FOUND = 10640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_IN_USE = 10641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_IN_USE_OTHER_PROCESS = 10642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE_ENCRYPTED_PASSWORD = 10643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILTER_PWDFILES = 10644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_USERACCESS = 10645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVER_TRICKLING_MANUAL_START = 10646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVER_TRICKLING_MANUAL_STOP = 10647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVE_BACKUP_MANUAL_START = 10648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVE_BACKUP_MANUAL_STOP = 10649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVE_RESTORE_MANUAL_START = 10650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVE_RESTORE_MANUAL_STOP = 10651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_UNIT_TRICKLING_MANUAL_START_ALL = 10652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_UNIT_TRICKLING_MANUAL_STOP_ALL = 10653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_UNIT_TRICKLING_MANUAL_START_LIST = 10654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_UNIT_TRICKLING_MANUAL_STOP_LIST = 10655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MERGE = 10657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_EXIST_AT_LOCATION = 10658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESTINATION_BEING_MOVED = 10659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEP_DESTINATION = 10660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_NOT_MOVED = 10661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEP_BOTH = 10662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_MOVED_RENAMED = 10663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PACKAGE_WITH_VIDEO_PLAYER = 10664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED_TO_MOVE_FILE = 10665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDE_SELECTED_ITEM = 10666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERROR_FOLDERALREADYEXIST = 10667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_UNUSED_PARTITION = 10668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DIAGNOSTIC_CREATEAGENTFAILEDNOTRUNNING_FORMAT = 10669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR_CACHE_AT_LOGOFF = 10670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_WATERMARKED_MANUFACTURER = 10671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_WATERMARKED_AUTHENTICATED_MANUFACTURER = 10672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WATERMARK_TAMPERED_MANUFACTURER = 10673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_ACCESS_RIGHTS = 10677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFY_UPGRADE_FILE = 10678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEM_TO_UPGRADE = 10679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_DATABASE_UPGRADE = 10680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACCESS_RIGHTS_TOOL_WHATS_NEW = 10681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DEFAULT_PARTITIONS = 10682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PUBLIC_PARTITION_DESCRIPTION = 10684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RESTRICTED_PARTITION_DESCRIPTION = 10685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVATE_PARTITION_LC = 10686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUBLIC_PARTITION_LC = 10687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACCESS_GRANTED_AREAS_PARTITIONS = 10689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_RIGHTS_TITLE_EXAMPLE = 10690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITY_MODEL_UPGRADE_MODE = 10691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURITY_MODEL_UPGRADE_MODE_DESCRIPTION = 10692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHOOSE_UPGRADE_MODE = 10693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LEAST_RESTRICTIVE_MODE = 10694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LEAST_RESTRICTIVE_DESCRIPTION1 = 10695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LEAST_RESTRICTIVE_DESCRIPTION2 = 10696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOST_RESTRICTIVE_MODE = 10697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOST_RESTRICTIVE_DESCRIPTION1 = 10698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOST_RESTRICTIVE_DESCRIPTION2 = 10699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_MODE_INFO = 10700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREATING_NEW_SECURITY_SYSTEM = 10701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOADING_DATA_FROM_SPECIFIED_FILE = 10702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_READING_SPECIFIED_DATABASE = 10703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPDATING_SECURITY_SYSTEM_USING_MODE = 10704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BUILDING_AUTOMATIC_UPGRADE_REPORT = 10705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALIDATE_UPGRADED_SYSTEM = 10706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BROWSE_UPGRADED_SYSTEM = 10707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OLD_ACCESS_RIGHTS = 10708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_ACCESS_RIGHTS = 10709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_IMPACTED_ENTITIES = 10710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_NEW_ACCESS_RIGHTS_TREE = 10711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GENERATING_UPGRADE_DATA = 10712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_DATA_GENERATED = 10713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR = 10714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_FILE_LOCATION = 10715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_AGREEMENT = 10716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_LOCATION = 10717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_SELECTED_ITEMS = 10718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISABLE_SELECTED_ITEMS = 10719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_MANAGER_MAXIMUM_RESULTS = 10720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REPORT_MANAGER_EXPORT_LIMITATION = 10721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPORT_LIMITATION = 10722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPACTED_USERS = 10723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_REPORT_LOCATION = 10724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PARTITIONS_UPGRADE_TOOL = 10725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTINUE_INSTALLATION = 10726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BACK_LOST_CHANGES = 10727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANUAL_CHANGES = 10728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANCEL_UPGRADE = 10729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MODIFY_LOGICAL_VIEW = 10733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLEASEWAIT_EXPORTINPROGRESS = 10734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATURE_AVAILABILITY_IN_SYSTEM_TASK = 10735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_WARNING_NOTSUPPORTED = 10736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERWRITE_EXISTING_FILE = 10737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPEND_A_DATE = 10738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_FILE_FORMAT = 10739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_INFORMATION_NOT_AVAILABLE = 10740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_ACCESS_MANAGER_ROLES = 10741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AT_LEAST_ONE_ACCESS_MANAGER_ROLE_OFFLINE = 10742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_QUERY_TO_ACCESS_MANAGER_TIMED_OUT = 10743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISK_USAGE = 10744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_FREE_OF_NUMBER_AVAILABLE = 10745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOTAL_DISK_SPACE = 10746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMPACTED_ENTITIES_DESCRIPTION = 10747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPGRADE_FILE_DESCRIPTION = 10748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_SYNERGISDBCONNECTIONTOOL_DESCRIPTION = 10753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REVERT_TO_DEFAULT = 10754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEWPATTERN = 10755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLED = 10756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_ABSENTSUPERVISOR = 10757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_ABSENTSUPERVISOR = 10758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_SHUNTING = 10759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_INTRUSION_ZONE_ARM_MASTER = 10760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_INTRUSION_ZONE_ARM_PERIMETER = 10761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_AUTOHIDE_ALARMEVENT = 10762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_MBPS = 10763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_LOAD_WEB_SDK_ROLE_TYPE = 10764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANNOT_LOAD_ROLE_CONFIG = 10765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_ALARM_SNOOZED = 10766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_ALARM_FORWARDED = 10767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SMTP_TEST_EMAIL_TITLE = 10768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SMTP_TEST_EMAIL_BODY_SERVER_X_AT_TIME_Y = 10769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEND_TEST_EMAIL = 10770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEND_TEST_EMAIL_EMAIL_ADDRESS = 10771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEND_TEST_EMAIL_RESULT = 10772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONLY_CSV_SUPPORTED = 10773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRED_AUTHENTIFICATION = 10774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_APPLY_PARTITION_TO_CREDENTIALS = 10775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_APPLY_PARTITION_TO_CARDHOLDERS = 10776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCELERATION = 10777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_TRANSFER_GROUP = 10778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_RESTORE = 10779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_LIMIT = 10780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ZONEARM_MINUTESDELAY = 10781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ZONEARM_SECONDSDELAY = 10782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_STATUS_PARTIALLY_SUCCESSFUL = 10783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPORT_PARTIAL_SUCCESS = 10784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVATE_ADDRESS = 10785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PUBLIC_ADDRESS = 10786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVATE_PORT = 10787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PUBLIC_PORT = 10788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVATE_ADDRESSES = 10789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_ACKNOWLEDGED = 10790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_ALARM_ACKNOWLEDGED = 10791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECEIVE = 10792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_KBPS = 10793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_MBPS = 10794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATION = 10795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIONS = 10796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTENANCE = 10797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTERNAL_DEVICES = 10798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PING_UNITS = 10799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_VISUAL_TRACKING_ZONE_WILL_NOT_MOVE = 10800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILES_DELETING = 10801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_UPGRADE_VALIDATING_PREREQUISITES = 10802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_ITEMS = 10803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOLDER_ALREADY_EXIST = 10804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_USE_SPECIFIC_DOMAIN_CONTROLLER = 10805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_ALARM_FORCE_ACKNOWLEDGED = 10806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FACTORY_RESET = 10807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_ERROR_FILE_IN_USE = 10808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PROMOTIONAL_CAMERAS = 10809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_IMPORT = 10810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SERVER_NOTRUNNING = 10811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_PATH_AIVAILABLE = 10812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DATABASE_CONNECTION_LOST = 10813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPON_RECONNECTION = 10815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAX_DURATION_EXCEEDED = 10816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ANTIPASSBACK_VIOLATION_FORGIVEN = 10817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GATHERING_DIAGNOSTIC_INFORMATION = 10818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_ADAPTERNOTFOUND = 10819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_ADAPTERNOTUP = 10820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHUNTED = 10821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_MULTIPLE_VIDEOS = 10822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_VIDEO_FILENAME = 10823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STORE_SOURCE_INSTALLED = 10824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STORE_SOURCE_ONLINE = 10825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STORE_SOURCE_UPDATES = 10826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_SHARED_PARTITION_INSERT_CARDHOLDERGROUP = 10827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ADD_PRIVILEGE_EXCEPTION_AREA = 10828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ADD_PRIVILEGE_EXCEPTION_PARTITION = 10829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_EXTRA_LARGE_ICONS = 10830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_LARGE_ICONS = 10831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_MEDIUM_ICONS = 10832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_SMALL_ICONS = 10833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_DETAILS = 10834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WATERMARKED = 10835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FPSWITHUNIT = 10836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EMAIL_SNAPSHOT = 10837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EMAIL_SNAPSHOT_DETAILS = 10838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SCHEDULED_UNLOCK_IGNORED_ABSENT_SUPERVISOR = 10839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGED_REQUIRE_REBOOT_DIRECTORY = 10840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGED_LISTENING_PORT_REBOOT = 10841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_FROM_EVENT_MONITORING = 10842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILTER_NOT_AVAILABLE_UNLESS_SWITCHING_TO_MAP_FRAGMENT1_ENDS_WITH_WHITESPACE = 10843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILTER_NOT_AVAILABLE_UNLESS_SWITCHING_TO_MAP_FRAGMENT2 = 10844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_EXPORTWITHWARNING = 10845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYER_STATISTICS = 10846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION = 10847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_ANCESTORS_HAVE_BEEN_UPDATED = 10848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXTENSION_NOT_CONFIGURED_AM_SERVER = 10849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_2M_DOUBLE_PANO_FISHEYE_CEILING = 10850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1M_DOUBLE_PANO_FISHEYE_CEILING = 10851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_2M_PANO_FISHEYE_WALL = 10852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_1M_PANO_FISHEYE_WALL = 10853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PANASONIC_FISHEYE_QUAD_PTZ = 10854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ADDRESS_LOCATION_RELATIVE_TO_PATROLLER = 10855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_SAVE_FILE = 10856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_SAVE_PATTERNS_FILE = 10857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_SELECTED_REPORT = 10858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_ERROR_WRITING_TO_DISK = 10859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_ERROR_WRITING_TO_DB = 10860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EDGETRANSFER_DETAILS_UNKNOWN_ARCHIVE_FILE_ERROR = 10861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEND_TEST_EMAIL_ERROR = 10862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVINGENTITY_FROM_VISIBLEAREAS_WARNING = 10863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_PARTITIONS = 10864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIDE_PARTITIONS = 10865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARTITION_UPGRADE_FILE_NEEDED = 10866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RENAME_FILE_IN_USE = 10867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILTORENAME_FILE = 10868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_UNASSIGNED_TO_VISITOR = 10869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_UNASSIGNED_TO_CARDHOLDER = 10870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANCESTOR_UNAVAILABLE = 10871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTORE_ARCHIVE_START_TIME_INVALID = 10872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTORE_ARCHIVE_START_TIME_INVALID_DETAILS = 10873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTORE_ARCHIVE_END_TIME_INVALID = 10874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTORE_ARCHIVE_END_TIME_INVALID_DETAILS = 10875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTORE_ARCHIVE_TIME_RANGE_INVALID = 10876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTORE_ARCHIVE_TIME_RANGE_INVALID_DETAILS = 10877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_DIRECTION_ALARM = 10878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_HITRULE = 10879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_OPEN_FILE = 10880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_OCCUPANCY_ALL_LOTS = 10881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DRAG_VIDEO_FILES_HERE = 10882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MLPIACTION_MOVED = 10883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_USERNAME_PASSWORD = 10884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONTYPE_MOBILESERVERSLAVE = 10885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINUTELY = 10886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTHENTICATION_VALIDATION_FAILED_FORMAT = 10887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARING_MANAGER_CREDENTIAL_CONFLICT = 10888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_EDIT_TILE_PATTERNS = 10889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_SAS = 10890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_RECEIVING_RATE = 10891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_WRITING_RATE = 10892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPUTER_NETWORK_TRAFFIC = 10893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ARCHIVE_GENERATOR = 10894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ARCHIVE_GENERATOR = 10895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURATION_CHANGED = 10896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_VEHICULES_MOVED = 10897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_MLPI_INVENTORYINDEX = 10898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PULSE_ONE_SECOND = 10899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORTING_ERROR_QUERY_TOO_COMPLEX = 10900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_LOCATEME = 10901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_EMPTY_INVENTORY = 10902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REBOOT_UNIT = 10903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PRINT_READ_REPORT_EVIDENCE = 10904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_ASSOCIATED_MICROPHONE = 10905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_ASSOCIATED_METADATA = 10906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SUFFIX_INHERITED = 10907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_GENERATED_HIT = 10908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_VISITOR_ESCORT_GROUP = 10909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_FORMAT_CSV = 10910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_INDIVIDUAL_FILE_NAMES = 10911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_EXPORT_NOTAVAILABLE = 10912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_VAULT_FOLDER = 10913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_UNLIMITEDBITRATE = 10914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_ENTER_FULL_SCREEN = 10915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPY_SNAPSHOT_TO_CLIPBOARD = 10916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLICK_TO_OPEN_VAULT = 10917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDE_DOTNET_FRAMEWORK = 10918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDE_DOTNET_FRAMEWORK_NOTICE = 10919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR_FILTERS = 10920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KEYFIELD_MAX_FORMAT = 10921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RESTORE_ARCHIVE_TIME_RANGE_FROM_DATE_INVALID = 10922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_FILE_EXISTS = 10923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OVERRIDE_EXISTING_FILE = 10924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTINUE_UPGRADE = 10925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_UPGRADE = 10926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFY_DATABASE = 10927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DISPLAY_OVERLAYS = 10928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED_CREATE_FOLDER = 10929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOLDER_IN_USE = 10930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZATION_COMPLETED_WITH_PARTITION_COUNT_CONFLICT = 10931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXTERNALSYSTEM_RESYNCHRONIZE_PARTITION_GCSROLE = 10932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_RETRIEVESNAPSHOT = 10933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CAPTURE_DONE_YET = 10934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_TRANSFER_GROUPS = 10935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_TRANSFER_GROUPS = 10937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERTX_FIR_SCHEDULES_NOTSUPPORTED_ERROR = 10938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OPTION_CHANGING_VAULT_FOLDER_TITLE = 10939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_OPTION_CHANGING_VAULT_FOLDER = 10940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMIN_ACCESS_ALL_ENTITIES = 10941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_DETECTIONMISMATCH = 10942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_PRIVILEGE_TO_ENABLE_SETTING = 10944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_AWAITING_SYNCHRONIZED_TIME = 10945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_AWAITING_SYNCHRONIZED_TIME = 10946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_ON = 10947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TWILIGHT_SCHEDULES_USAGE = 10948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_OLD_BACKUPS_ON_DISK_FULL = 10949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DATABASE_UPGRADE_REQUIRED_MEDIAROUTER = 10951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DATABASE_UPGRADE_REQUIRED_MEDIAROUTER_HELP = 10952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_ACCESS_DENIED_SUPERVISOR_ABSENT = 10953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVER_MODE = 10954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGEQUALITY_WITHUNIT = 10955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_PARTIALLY_FAILED_FOR_GROUP = 10956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_FAILED_FOR_GROUP = 10957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_SUCCESS_FOR_GROUP = 10958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_PARTIALLY_FAILED_FOR_ONE_UNIT = 10959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_FAILED_FOR_ONE_UNIT = 10960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_SUCCESS_FOR_ONE_UNIT = 10961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_SUCCESS_FOR_ONE_CAMERA = 10962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_FAILED_FOR_ONE_CAMERA = 10963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_PARTIALLY_FAILED_FOR_ONE_CAMERA = 10964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_SUCCESS_FOR_ONE_CAMERA = 10965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_FAILED_FOR_ONE_CAMERA = 10966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_PARTIALLY_FAILED_FOR_ONE_CAMERA = 10967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEY_FAME_INTERVAL_WITH_MESURE = 10968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ERROR_HEALTHSTATS_INCOMPLETE_RESULTS = 10969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MISSING_THUMBNAILS = 10970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ERROR_HEALTHSTATS_INCOMPLETE_RESULTS_DETAILS = 10971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRESET_ALREADY_EXISTS = 10972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRESET_NOT_UNIQUE = 10973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETECTED_HARDWARE = 10974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_ACCELERATION_NOT_SUPPORTED = 10975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_G64X_FILE_CORRUPTED = 10976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CUSTOM_CAMERAS = 10977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_OFFLINE_ARCHIVER = 10978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_OFFLINE_ARCHIVER = 10979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_OFFLINE_CAMERA = 10980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_OFFLINE_CAMERA = 10981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_STREAM_UNREACHABLE = 10982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_STREAM_UNREACHABLE = 10983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_STREAM_NOT_FOUND = 10984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_STREAM_NOT_FOUND = 10985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SLOW_VIDEO_PLAYBACK = 10986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ARCHIVER_DISK_LOAD = 10987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER_DIRECTORY_0_IS_NOT_CONNECTED = 10988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAIN_DB_IS_UNREACHABLE = 10990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_CAMERA = 10991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_DATA = 10993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_ARCHIVE_FOLDER_PATH_TOO_LONG = 10994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_STILL_FRAME_OMNICAST_CAMERAS = 10995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAY_PAUSE_ALL = 10996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORWARD_ALL = 10997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REWIND_ALL = 10998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEXT_FRAME_ALL = 10999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVIOUS_FRAME_ALL = 11000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_JUMP_FORWARD_ALL = 11001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_JUMP_BACKWARD_ALL = 11002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ESCORT_REQUIRED = 11003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DB_LOCATION_BACKUP = 11004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GENERATE = 11005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAYS_AGO_LC = 11007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_TRANSFER_GROUP_NAME = 11008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PARTITION_ADMINISTRATOR_DESCRIPTION = 11009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERFORMANCE_MODE = 11010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERFORMANCE_MODE_INFO = 11011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AXYS_THERMAL = 11012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AXYS_BLACK_HOT = 11013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_IDS_SPCPTZDLG_AXYS_WHITE_HOT = 11014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_AXYS_AUTOFOCUS = 11015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_LPR_INSUFFICIENT_DISK_SPACE = 11016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REPORT_MANAGER_INVALID_FOLDER = 11017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_TYPE_PANORAMIC = 11018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TERMINAL_RS232 = 11019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TERMINAL_RS422485 = 11020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LONG_OPERATION_WARNING = 11021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_EXPIRATION = 11022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_ABOUT_TO_EXPIRED = 11023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_EXPIRED = 11024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_ABOUT_TO_EXPIRED_DETAILS = 11025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LICENSE_EXPIRED_DETAILS = 11026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SMA_EXPIRATION = 11027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SMA_ABOUT_TO_EXPIRED = 11028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SMA_EXPIRED = 11029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SMA_ABOUT_TO_EXPIRED_DETAILS = 11030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SMA_EXPIRED_DETAILS = 11031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OPEN_LICENSE_PAGE = 11032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DISMISS = 11033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OPTION_NOTIFICATION_SMA_EXPIRATION = 11034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_OPTION_NOTIFICATION_LICENSE_EXPIRATION = 11035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DO_NOT_SHOW_AGAIN = 11036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SUCCESSFULLY_ADDED_TO_ARCHIVER = 11037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUCCESS = 11038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CLOSE_AND_TRY_AGAIN = 11039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PCAP_STREAMING_ALREADY_OPEN = 11040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_DIRECTORY = 11041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_DOES_NOT_EXIST = 11042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_ACCESS_DIRECTORY_FORMAT = 11043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_IN_FILES_ALREADY_IN_DB = 11044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_IN_FILE_ALREADY_IN_DB = 11045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXCEPTION_WHILE_CHECKING_FILE_IN_DB = 11046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXCEPTION_WHILE_ADDING_FILE_TO_DB = 11047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INITIALIZING_MEDIA_PLAYER = 11048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CLOSE_AND_COPY_TO_CLIPBOARD = 11049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONVERSION_NOT_FINISH_CANCEL_OR_CONTINUE = 11050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONVERSION_IN_PROGRESS = 11051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILES_WILL_BE_DELETED_FROM_DISK = 11052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_QUESTION_TROUBLE_BUILDING_SDP_CONTINUE_OR_ABORT_FORMAT = 11053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SEARCH_UNTIL_NUMBER_OF_FRAMES_FORMAT = 11054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OPENING_FILE = 11055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXCEPTION_THROW_IN_FORMAT = 11056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_OPEN_ENCRYPTED_FILES = 11057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DISPLAY_FIRST_NUMBER_OF_STREAM_PACKET_FORMAT = 11058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RETENTION_PERIOD_INFO = 11059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXCLUDED_LOW_PACKETS_IN_CONVERSATION_FORMAT = 11060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TROUBLE_BUILDING_SDP = 11061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_STREAM_LOST = 11062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_STREAM_LOST = 11063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLICK_HERE_FOR_DETAILS = 11064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_AND_EXPORT = 11065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SAVE_AND_EXPORT = 11066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PLAYBACK_STARTED = 11067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXCEPTION_WHILE_LOADING_FILES_FROM_DIRECTORIES = 11068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXCEPTION_WHILE_ADDING_FILES_TO_DB = 11069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXCEPTION_WHILE_LOADING_FILES_FROM_DIRECTORY = 11070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_LOGIN_ACCESS_CONTROLS_UNITS = 11071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_LOGIN_ACCESS_CONTROLS_UNITS_DESCRIPTION = 11072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_CONTROL_SECURITY = 11073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_CONTROL_AUTHENTIFICATION_USERNAME = 11074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOW_ADD_CARDHOLDERS = 11075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOW_ADD_CARDHOLDERS_DESCRIPTION = 11076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_MANAGEMENT_TASK = 11077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_CARDHOLDER_MANAGEMENT_TASK_DESCRIPTION = 11078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAUNCH_IMPORT_TOOL = 11079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIZARD_LAUNCH_IMPORT_TOOL_DESCRIPTION = 11080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_OTHERS_USERS = 11081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_OTHERS_USERS_DESCRIPTION = 11082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_OTHERS_USERS_SECOND_DESCRIPTION = 11083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_TYPE = 11084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERY_PORT_COPIE = 11085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REX_EVENT_HOLDOFF_AFTERGRANT = 11086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REX_EVENT_HOLDOFF_AFTERCLOSED = 11087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTROLPORT = 11088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LEGACYLOGONDISABLED = 11089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_LOGONNEGOTIATE = 11090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_STATUS_EVENTS = 11091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOSIGNALEVENT_EVENTS = 11092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOCKDETECTION_EVENTS = 11093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STORAGEERROR_EVENTS = 11094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_4X3 = 11095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_5X4 = 11096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_6X5 = 11097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_4X3 = 11098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_5X4 = 11099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_6X5 = 11100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_8X6 = 11101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_9X7 = 11102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_BPS = 11103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_KBPS = 11104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_MBPS = 11105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_GBPS = 11106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_ONE_PERIMETER_DOOR_WARNING = 11107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SAS_DOOR_NO_SENSOR_WARNING = 11108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_AVAILABLE_OF_NUMBER_FREE = 11109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PENDING_RETRY = 11110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_RETRY = 11111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERROR_RETRY = 11112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACKED_PATROLLERS = 11113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEVICE_IS_SHUNTED = 11114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEVICE_IS_NOT_OURS = 11115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IODIAGNOSTICS = 11116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_WASHER_ON = 11117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_WASHER_OFF = 11118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_WASHINGPROCEDURE_ON = 11119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_WASHINGPROCEDURE_OFF = 11120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_IR_ON = 11121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_IR_OFF = 11122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_IR_AUTO = 11123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_OSM_ON = 11124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_PATROL_MODE_START = 11125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_ACCESS_FILES_OR_DIRECTORIES_FORMAT = 11126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSH_LAYOUT_HIGHLIGHT_10X8 = 11127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_RECV_SEQUENCE_LIST_FAILURE = 11128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_RTSP_SESSION_ERROR = 11129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_UNHANDLED_EXCEPTION = 11130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_NO_SESSION_DESCRIPTOR_RECV = 11131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_NO_FOLDER_WRITE_ACCESS = 11132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_FOLDER_PATH_TOO_LONG = 11133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_FILE_ALLOCATION_ERROR = 11134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_FILE_CREATION_ERROR = 11135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVE_TRANSFER_UNHANDLED_EXCEPTION = 11136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVE_TRANSFER_INVALID_STATE_TRANSITION = 11137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDGE_TRANSFER = 11138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_NOT_ENOUGH_SPACE_DETAILED_MESSAGE = 11139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_SPACE = 11140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_LEFT = 11141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_RIGHT = 11142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_UP = 11143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_DOWN = 11144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_ADD = 11145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_SUBSTRACT = 11146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_DIVIDE = 11147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_MULTIPLY = 11148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_HOME = 11149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_RETURN = 11150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_PAGEUP = 11151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_PAGEDOWN = 11152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_END = 11153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_DELETE = 11154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_INSERT = 11155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_KEYBOARD_SCROLL = 11156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRANSFER_NOT_ENOUGH_SPACE_DETAILED_MESSAGE = 11157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVERT_ABS_TILT = 11158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVERT_ABS_PAN = 11159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ABS_TILT_OFFSET = 11160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ABS_PAN_OFFSET = 11161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_METADATAEVENTSTREAM_CONNECTION_TYPE = 11162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CARDHOLDR_IS_AN_ESCORT_CANNOT_BE_DELETED = 11163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECONNECTION_DELAY = 11164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_AXIS_EVNTSTRMCONNTYPE_FORCED_RTSPTCP = 11165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPECIFY_DIRECTION_RATION_OFFSETS = 11166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_NEW = 11167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_VALUE = 11168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_NUMBER = 11169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_AT_LEAST = 11170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_NO_MORE_THAN = 11171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_IP_ADDRESS = 11172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_IPV4_ADDRESS = 11173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_IPADDRESS_PORT = 11174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SUBMIT = 11175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TROUBLESHOOT_HARDWARE_PROBLEMS = 11176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_AT_LEAST_X_CHARACTERS = 11177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MUST_BE_AT_MOST_X_CHARACTERS = 11178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_IS_CONDITIONAL = 11179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AND_LC = 11180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFY_A_CONDITION = 11181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_DEVICE_CONFIGURED = 11182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CONTEXT = 11183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURE_UNIT_WEBPAGE = 11184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHORTCUTS_INVALID_KEY_FORMAT = 11185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_HARDWARE_INFO = 11186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VECTOR = 11187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_LOGICAL_VIEW = 11188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OCCURRED_PROCESSING_REQUEST = 11189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTION_WITHOUT_SOURCE = 11190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_APPLICATIONPRIVILEGES_GROUP = 11191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_CATEGORY = 11192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_CATEGORY = 11193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_GENERALPRIVILEGES_GROUP = 11194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_CHANGE_OWN_PASSWORD = 11195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_CREATE_INCIDENTS = 11196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFY_INCIDENTS = 11197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PRINT_AND_EXPORT_REPORTS = 11198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEW_WEB_PAGES = 11199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEW_LIVE_COVERT_HITS = 11200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_REMOVE_ITEMS_FROM_REPORT = 11201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADMINISTRATIONPRIVILEGES_GROUP = 11202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LOGICALENTITYTASKS_GROUP = 11203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_AREAS = 11204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_AREAS = 11205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_AREAS = 11206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_MAPS = 11207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_MAPS = 11208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_MAPS = 11209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PHYSICAL_ENTITY_TASKS_GROUP = 11210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_UNITS = 11211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RESET_UNITS = 11212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UNIT_FIRMWARE_UPGRADE = 11213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_UNITS = 11214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_UNITS = 11215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_PATROLLERS = 11216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_PATROLLERS = 11217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_PATROLLERS = 11218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_LPR_UNIT = 11219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_LPR_UNITS = 11220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_LPR_UNIT = 11221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RESET_LPR_UNITS = 11222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CAMERAS = 11223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CAMERAS = 11224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_ANALOG_MONITORS = 11225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_ANALOGMONITORS = 11226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_OUTPUT_BEHAVIORS = 11227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_OUTPUT_BEHAVIORS = 11228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_OUTPUT_BEHAVIORS = 11229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_DOORS = 11230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_DOORS = 11231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_DOORS = 11232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_ELEVATORS = 11233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_ELEVATORS = 11234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_ELEVATORS = 11235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_ZONES = 11236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_INTRUSION_AREAS = 11237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_INTRUSION_AREAS = 11238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_INTRUSION_AREAS = 11239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_INTRUSION_UNITS = 11240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_INTRUSION_UNITS = 11241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_INTRUSION_UNITS = 11242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_ZONES = 11243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_ZONES = 11244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CASH_REGISTERS = 11245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CASH_REGISTERS = 11246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_ASSETS = 11247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_ASSETS = 11248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_ASSETS = 11249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SCHEDULEMANAGEMENTTASKS_GROUP = 11250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_SCHEDULES = 11251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_SCHEDULES = 11252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_SCHEDULES = 11253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_SCHEDULED_TASKS = 11254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_SCHEDULED_TASKS = 11255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_SCHEDULED_TASKS = 11256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACCESSCONTROLMANAGEMENTTASKS_GROUP = 11257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CONVERT_SHARED_TO_LOCAL = 11258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CARDHOLDERS = 11259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDERS = 11260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_CARDHOLDERS = 11261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_CARDHOLDER_STATUS = 11262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDER_NAME = 11263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDER_CUSTOM_FIELDS = 11264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CREDENTIAL_INFORMATION = 11265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_CARDHOLDER_OPTIONS = 11266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDER_PICTURE = 11267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CARDHOLDER_GROUPS = 11268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDER_GROUPS = 11269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_CARDHOLDER_GROUPS = 11270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDER_GROUP_CUSTOM_FIELDS = 11271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CREDENTIALS = 11272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CREDENTIALS = 11273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_CREDENTIALS = 11274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEW_ADVANCED_CREDENTIAL_INFO = 11275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_CREDENTIAL_STATUS = 11276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CREDENTIAL_NAME = 11277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CREDENTIAL_ASSOCIATION_TO_CARDHOLDER = 11278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CREDENTIAL_CUSTOM_FIELDS = 11279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PRINT_BADGES = 11280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_ACCESS_RULES = 11281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_ACCESS_RULES = 11282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_ACCESS_RULES = 11283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_BADGE_TEMPLATES = 11284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_BADGE_TEMPLATES = 11285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_VISITORS = 11286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_VISITORS = 11287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHECHIN_AND_CHECKOUT_VISITORS = 11288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_VISITORS_CUSTOM_FIELDS = 11289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_VISITOR_PICTURE = 11290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPRMANAGEMENTTASKS_GROUP = 11291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ALARMMANAGEMENTTASKS_GROUP = 11292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_ALARMS = 11293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_ALARMS = 11294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_ALARMS = 11295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_MONITOR_GROUPS = 11296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_MONITOR_GROUPS = 11297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_MONITOR_GROUPS = 11298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_LPR_RULES = 11299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_LPR_RULES = 11300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_LPR_RULES = 11301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PLATE_LIST = 11302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEW_ENTRIES = 11303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFY_ENTRIES = 11304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETE_ENTRIES = 11305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ADD_ENTRIES = 11306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASKPRIVILEGES_GROUP = 11307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_REPORT_TEMPLATES = 11308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_REPORT_TEMPLATES = 11309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_REPORT_TEMPLATES = 11310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MANAGE_PRIVATE_TASKS = 11311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASK_CATEGORY_ADMINISTRATION = 11312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LOGICAL_VIEW = 11313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADMINTOOL_TAB_SYSTEM = 11314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_EXTERNALSYSTEM_CATEGORY_VIDEO = 11315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CONFIGTOOL_MENU_ACCESSCONTROL = 11316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INTRUSIONDETECTION_GROUP = 11317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MENU_TOOLS_PLUGINS = 11318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR = 11319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASK_CATEGORY_OPERATIONS = 11320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SURVEILLANCE = 11321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASK_ALARM_MONITORING = 11322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TOOLS = 11323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TOOLS_UNIT_DISCOVERY = 11324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SELECT_MANUFACTURERS = 11325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_UNIT_MANUALLY = 11326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RECONCILE_INVENTORIES = 11327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_HOTLIST_MODIFICATION = 11328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REMOTE = 11329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MAP_DESIGNER = 11330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SYSTEM_STATUS_TASK = 11331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_HARDWARE_INVENTORY = 11332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PEOPLE_COUNTING = 11333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VISITOR_MANAGEMENT = 11334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INVESTIGATION_GROUP = 11335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ALARM_REPORT = 11336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_QUERY_INCIDENT = 11337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_POINT_OF_SALE_REPORT = 11338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CATEGORY_ACCESS_CONTROL = 11339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_AREA_ACTIVITY_REPORT = 11340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_AREA_PRESENCE_REPORT = 11341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CARDHOLDER_ACTIVITY_REPORT = 11342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CREDENTIAL_ACTIVITY_REPORT = 11343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CREDENTIAL_REQUEST_HISTORY_REPORT = 11344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DOOR_ACTIVITY_REPORT = 11345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ELEVATOR_ACTIVITY_REPORT = 11346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_REPORTS_TIME_ATTENDANCE = 11347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UNIT_ACTIVITY_REPORT = 11348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VISITOR_REPORT = 11349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VISITOR_ACTIVITY_REPORT = 11350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ASSET_MANAGEMENT = 11351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_QUERY_ASSET_ACTIVITIES = 11352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_QUERY_ASSET_INVENTORY = 11353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ZONE_ACTIVITY_REPORT = 11354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_INTRUSION_AREA_ACTIVITY = 11355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_INTRUSION_UNIT_ACTIVITY = 11356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CATEGORY_VIDEO = 11357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIDEO_ARCHIVE_REPORT = 11358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIDEO_BOOKMARK_REPORT = 11359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIDEO_MOTION_SEARCH = 11360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_FORENSIC_SEARCH = 11361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CAMERA_ACTIVITY_REPORT = 11362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIDEO_FILE_REPORT = 11363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_HITS = 11364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_HITS_MULTIREGION = 11365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_HITS_GROUPING = 11366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TITLE_HIT_REPORT_PER_AGE = 11367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TITLE_HIT_REPORT_PER_TYPE = 11368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_PLATE_READS = 11369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_READS_MULTIREGION = 11370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_READS_GROUPING = 11371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PATROLLER_PLAYBACK = 11372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_INVENTORY = 11373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_DAILY_USAGE_PATROLLER = 11374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LOGIN_PER_PATROLLER = 11375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_READ_HITS_PER_DAY = 11376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_READ_HITS_PER_LPR_ZONE = 11377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_ZONE_OCCUPATION = 11378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MAINTENANCE_GROUP = 11379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_REPORTS_ACTIVITY_TRAILS = 11380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_REPORTS_AUDIT_TRAILS = 11381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_QUERY_HEALTH = 11382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_ACCESS_CONTROL_HEALTH_HISTORY = 11383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_IO_CONFIGURATION_REPORT = 11384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_CATEGORY_ACCESS_CONTROL = 11385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACCESS_REPORT = 11386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_ENHANCED_ACCESS = 11387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_ACCESS_TROUBLESHOOTING = 11388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_ACCESS_RULE = 11389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_QUERY_CARDHOLDER_STATUS = 11390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_QUERY_CREDENTIAL_STATUS = 11391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CAMERA_CONFIGURATION = 11392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ARCHIVER_ACTIVITY_REPORT = 11393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CARDHOLDER_CREATOR = 11394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CREDENTIAL_MANAGEMENT = 11395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACTIONPRIVILEGES_GROUP = 11396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SET_THREAT_LEVEL = 11397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACCESSCONTROL_GROUP = 11398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DOORS_GROUP = 11399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_EXPLICITLY_UNLOCK_DOORS = 11400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_OVERRIDE_SCHEDULE = 11401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MAINTENANCE_MODE = 11402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_FORGIVE_ANTIPASSBACK_VIOLATION = 11403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SILENCE_SOUND_BUZZER = 11404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ARM_DISARM_INTRUSION_ZONES = 11405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TRIGGER_INTRUSION_ALARM = 11406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_AREAS_GROUP = 11407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RESET_PEOPLE_COUNT = 11408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CAMERAS_GROUP = 11409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_USE_AUDIO = 11410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DISPLAY_OVERLAYS = 11411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PROTECT_VIDEO = 11412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UNPROTECT_VIDEO = 11413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_BLOCK_UNBLOCK_VIDEO = 11414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ALLOW_VIDEO = 11415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_RECORD_MANUALLY = 11416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ADD_BOOKMARK = 11417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_EDIT_BOOKMARK = 11418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETE_BOOKMARK = 11419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SAVE_AND_PRINT_SNAPSHOTS = 11420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PTZMOTORPRIVILEGES_GROUP = 11421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PTZ_DO_BASIC_OPERATIONS = 11422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PTZ_CHANGE_FOCUS_AND_IRIS_SETTINGS = 11423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PTZ_USE_PRESETS = 11424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PTZ_EDIT_PRESETS = 11425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_USE_PTZ_PATTERNS = 11426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SET_PTZ_PATTERNS = 11427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_USE_PTZ_AUXILIARIES = 11428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SET_PTZ_AUXILIARIES = 11429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_SET_USE_SPECIFIC_COMMANDS = 11430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PTZ_MENU_ON_OFF = 11431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PTZ_LOCK_PTZ = 11432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PTZ_OVERRIDE_LOCKS = 11433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ALLOW_PLAYBACK = 11434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_EXPORT_VIDEO = 11435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_USE_ASF_FORMAT = 11436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ALARMS_GROUP = 11437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ACKNOWLEDGE_ALARMS = 11438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TRIGGER_ALARMS = 11439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SNOOZE_ALARMS = 11440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_FORWARD_ALARMS = 11441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MACROS_GROUP = 11442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_EXECUTE_MACROS = 11443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_USERS_GROUP = 11444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SEND_MESSAGE = 11445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SEND_EMAIL = 11446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_EMAIL_REPORT = 11447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DISPLAY_ENTITY_IN_SD = 11448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PLAY_SOUND = 11449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SEND_CLEAR_USER_TASK = 11450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TRIGGER_OUTPUT = 11451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_START_STOP_SEQUENCE = 11452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_GROUP = 11453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_PROTECT_READS_HITS = 11454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_DELETE_READS_NOT_IN_INVENTORY = 11455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ZONES_GROUP = 11456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ARM_DISARM_ZONES = 11457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_WORKSPACE = 11458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_START_STOP_GUARDTOUR = 11459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_SECURITYDESK_OPTIONS = 11460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_TILE_CONTEXT = 11461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_TILE_PATTERN = 11462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_EDIT_TILE_PATTERNS = 11463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CHANGE_CLIENT_VIEWS = 11464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CAMERA_ANALYTIC = 11465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DIGITAL_ZOOM = 11466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_OVERRIDE_VIDEO_QUALITY = 11467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CAMERA_SEQUENCE = 11468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CAMERA_SEQUENCE = 11469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_CAMERA_SEQUENCE = 11470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_VIDEO_UNITS = 11471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIDEO_UNIT_FIRMWARE_UPGRADE = 11472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_VIDEO_UNITS = 11473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AND_DELETE_VIDEO_UNITS = 11474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RESET_VIDEO_UNITS = 11475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RECONNECTVIDEOUNIT = 11476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_DELETE_CASH_REGISTERS = 11477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RECONNECT_VIDEO_UNIT = 11478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_LOG_ON_THROUGH_WEB_CLIENTS = 11479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_LOG_ON_THROUGH_SDK = 11480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_EXTERNALSYSTEM_CARDHOLDER_SYNCHRONIZER_NAME = 11481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_LOG_ON_THROUGH_MOBILE_CLIENT = 11482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_FEDERATION_LOGON = 11483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_BADGE_TEMPLATES = 11484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFORMATION_FROM_GTAP = 11485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IRCUTFILTER = 11486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REDGAIN = 11487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLUEGAIN = 11488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKLIGHTCOMPENSATION = 11489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGESTABILIZATION = 11490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CANCEL_ALL = 11491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DIRECTORY_UPDATE = 11492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SQLSERVER_USER_PERMISSION = 11493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SELECT_TILE_PATTERN = 11494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_QUERY_TIMEOUT_RETRY = 11495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_WINDOWS_CREDENTIALS = 11496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSE = 11497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LETMECHOOSE = 11498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_CACHE_OPTIONS_APPLIED_AT_STARTUP = 11499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_STATUS_SYNCHRONIZING_CREDENTIALS = 11500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CLOCK_SYNCHRONIZATION = 11501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_CARD = 11502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENDED_WITH_ERROR = 11503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ALL_TILES = 11504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BACKUP_FOLDER_LOCAL = 11505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ELEVATOR_DUPLICATE_PUSHBUTTONS_AND_FLOORTRACKING = 11506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_ACK_ALARMS_AFTER = 11507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_NAME = 11508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_ASSIGNMENT = 11509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CAMERA_REMOVED_FROM_TRANSFER_GROUP = 11510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_UPLOADED_PICTURE_SIZE = 11511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOL_DESCRIPTION_GENETEC_VIDEO_PLAYER = 11512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_SHOCK_DETECTED = 11513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOCK_LEVEL = 11514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SSTERROR_DHCPBUTCANNOTDISCOVER = 11518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PACKAGE = 11519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_DB_ASSOCIATED_VIDEO_FILES = 11520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRMATION_DELETE_DB_VIDEO_FILES = 11521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WRAP_TEXT = 11522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNUSED_CARDS_FILTER_IS_UNAVAILABLE = 11523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BING_FOLDER_NOT_FOUND = 11524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BING_FILE_NOT_FOUND = 11525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BING_KEY_NOT_FOUND = 11526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_AREA = 11527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_AREA = 11528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_AREA = 11529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_AREA = 11530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_TILE_PLUGIN = 11531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_TILE_PLUGIN = 11532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_TILE_PLUGIN = 11533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_TILE_PLUGIN = 11534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_ACCESS_CONTROL_UNIT = 11535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_ACCESS_CONTROL_UNIT = 11536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_ACCESS_CONTROL_UNIT = 11537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_ACCESS_CONTROL_UNIT = 11538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_PATROLLER = 11539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_PATROLLER = 11540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_PATROLLER = 11541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_PATROLLER = 11542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_LPR_UNIT = 11543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_LPR_UNIT = 11544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_LPR_UNIT = 11545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_LPR_UNIT = 11546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_OUTPUT_BEHAVIOR = 11547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_OUTPUT_BEHAVIOR = 11548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_OUTPUT_BEHAVIOR = 11549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_OUTPUT_BEHAVIOR = 11550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_DOOR = 11551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_DOOR = 11552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_DOOR = 11553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_DOOR = 11554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_ELEVATOR = 11555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_ELEVATOR = 11556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_ELEVATOR = 11557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_ELEVATOR = 11558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_INTRUSION_DETECTION_AREA = 11559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_INTRUSION_DETECTION_AREA = 11560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_INTRUSION_DETECTION_AREA = 11561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_INTRUSION_DETECTION_AREA = 11562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_INTRUSION_DETECTION_UNIT = 11563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_INTRUSION_DETECTION_UNIT = 11564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_INTRUSION_DETECTION_UNIT = 11565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_INTRUSION_DETECTION_UNIT = 11566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_ZONE = 11567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_ZONE = 11568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_ZONE = 11569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_ZONE = 11570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_ASSET = 11571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_ASSET = 11572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_ASSET = 11573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_ASSET = 11574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_SCHEDULE = 11575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_SCHEDULE = 11576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_SCHEDULE = 11577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_SCHEDULE = 11578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_SCHEDULED_TASK = 11579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_SCHEDULED_TASK = 11580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_SCHEDULED_TASK = 11581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_SCHEDULED_TASK = 11582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_CARDHOLDER_GROUP = 11583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_CARDHOLDER_GROUP = 11584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CARDHOLDER_GROUP = 11585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CARDHOLDER_GROUP = 11586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_CARDHOLDER = 11587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_CARDHOLDER = 11588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CARDHOLDER = 11589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CARDHOLDER = 11590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_CREDENTIAL = 11591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_CREDENTIAL = 11592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CREDENTIAL = 11593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CREDENTIAL = 11594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_ACCESS_RULE = 11595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_ACCESS_RULE = 11596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_ACCESS_RULE = 11597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_ACCESS_RULE = 11598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_BADGE_TEMPLATE = 11599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_BADGE_TEMPLATE = 11600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_BADGE_TEMPLATE = 11601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_BADGE_TEMPLATE = 11602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_VISITOR = 11603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_VISITOR = 11604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_VISITOR = 11605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_VISITOR = 11606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_ALARM = 11607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_ALARM = 11608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_ALARM = 11609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_ALARM = 11610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_MONITOR_GROUP = 11611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_MONITOR_GROUP = 11612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_MONITOR_GROUP = 11613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_MONITOR_GROUP = 11614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_LPR_RULE = 11615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_LPR_RULE = 11616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_LPR_RULE = 11617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_LPR_RULE = 11618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_PUBLIC_TASK = 11619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_PUBLIC_TASK = 11620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_PUBLIC_TASK = 11621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_PUBLIC_TASK = 11622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_CAMERA_SEQUENCE = 11623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_CAMERA_SEQUENCE = 11624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CAMERA_SEQUENCE = 11625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CAMERA_SEQUENCE = 11626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_VIDEO_UNIT = 11627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_VIDEO_UNIT = 11628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_VIDEO_UNIT = 11629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_VIDEO_UNIT = 11630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_CASH_REGISTER = 11631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_CASH_REGISTER = 11632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CASH_REGISTER = 11633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CASH_REGISTER = 11634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LPR_NOMATCHEVENT_FORMAT = 11635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNAVAILABLE_ENTITY = 11636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNITSTATS_NUMCARDHOLDER = 11637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNUSED_CARDS_ACCESS_MANAGER_NOT_AVAILABLE = 11638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_EDIT_READ = 11639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GEOCODING_INVALID_CREDENTIAL = 11640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_USER_GROUP_INFO_PAGE_TITLE = 11641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_GROUP_NO_PRIVILEGE_DESCRIPTION = 11642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTICS_EXTERNALSYSTEM_PLUGINDEPENDENCYNOTLOADED = 11643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_ROLENOTLOADING = 11644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_CANNOTAUTHENTICATEUSERS = 11645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_CANNOTAUTHENTICATEUSERS_DETAILED = 11646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_SERVER_NEEDS_UPDATE = 11647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_CAMERA = 11648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER_SERVERS_GATEWAY = 11650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ALARMS_FORCEACK_ALL = 11651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ALARMS_TRIGGERED = 11652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_INTRUSION_ALARM_TRIGGERED = 11653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_VISUALTRACKING_ENABLED = 11656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_VISUALTRACKING_DISABLED = 11657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PLAYBACK_STARTED = 11658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_VIDEOUNIT_RECONNECTED = 11659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_BADGE_PRINTED = 11662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_DEVICE_SHUNTED = 11663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PLATE_FILTERING = 11664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_GENERATE_REPORT = 11665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXPORT_REPORT = 11666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PRINT_REPORT = 11667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_BOOKMARK_ALL = 11668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE_SNAPSHOT_ALL = 11669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SLOW_MOTION_ALL = 11670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISUAL_TRACKING_ALL = 11671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENROLL_UNITS_AUTOMATICALLY = 11672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_ASSOCIATED_TO = 11673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLAT_VIEW = 11674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIERARCHICAL_VIEW = 11675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_ENTITIES = 11676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTINUOUSLY = 11677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MUTE_ALL_ALARMS = 11678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SHOW_ADVANCED_SETTINGS = 11679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_HIDE_ADVANCED_SETTINGS = 11680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_HOLD = 11681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOR_MINUTE_PRECISION = 11682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEST = 11683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_APPLY_THREAT_LEVEL_NONE = 11684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MAP_MANAGER_NAME = 11685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MAP_MANAGER_DESCRIPTION = 11686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_LENS_CAPABILITIES_NOT_EQUALS = 11687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_FAILOVER = 11688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_SUBTYPE_GHOST_CAMERA = 11689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NOT_ALL_INPUTS_SHOWN = 11690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_HEALTH_EVENT_NUMBER = 11691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_SOUND = 11692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ALARM_SOUND = 11693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_TIMELINE = 11694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_ALWAYS_DISPLAY_TIMELINE = 11695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IN_LIVE = 11696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IN_PLAYBACK = 11697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MAP_LAYERS_FILE_PATH_INVALID = 11698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAP_LAYERS_ON_PARSING = 11699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAP_LAYERS_FILE_TYPE_INVALID = 11700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAP_LAYERS_NO_PLACEMARK_FOUND = 11701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDERS = 11702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_MAP_PROVIDER = 11703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REMOVE_MAP_PROVIDER = 11704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EDIT_MAP_PROVIDER = 11705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DEFAULT_PROVIDER = 11706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYERS = 11707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_MAP_LAYER = 11708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REMOVE_MAP_LAYER = 11709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDER_USE_CLOUD = 11710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDER_OUTPUT_FOLDER = 11711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDER_WEB_SERVER_PORT = 11712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDER_ACCOUNT_NAME = 11713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDER_ACCESS_KEY = 11714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_UNCHECK_ALL_MAP_LAYERS = 11715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CHECK_ALL_MAP_LAYERS = 11716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROVIDERS = 11717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDER_LICENSE_KEY = 11718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PROVIDER = 11719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_EXPORT_WARNING = 11720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_NEW = 11721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_INPUT_STATE_CHANGED = 11722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_STATE_IS = 11723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATING_FILES = 11724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_LPR_MANAGERS = 11725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_LPR_MANAGERS = 11726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_LPR_MANAGERS = 11727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_PATROLLERS = 11728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_SHARPS = 11729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NEW_PLATELIST_LPR_MANAGERS_ASSOCIATION = 11730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NEW_PLATELIST_PROPERTIES = 11731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NEW_PLATELIST_SPECIFIC_UNITS_ASSOCIATION = 11732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_ASSIGNED_LPR_MANAGERS = 11733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGNED_LPR_MANAGERS = 11734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_ASSIGNED_UNITS = 11735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGNED_UNITS = 11736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_PLATELIST_PREFETCHERROR = 11737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_ANOTHER_SIMILAR_HOTLIST = 11738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_ANOTHER_SIMILAR_PERMIT = 11739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WAIT_FOR_ENTITIES = 11740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGN_HOTLIST_TO = 11741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSIGN_PERMIT_TO = 11742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_LPR_MANAGERS_HOTLIST_DESCRIPTION = 11743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_LPR_MANAGERS_PERMIT_DESCRIPTION = 11744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_LPR_MANAGERS_HOTLIST_DESCRIPTION = 11745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_LPR_MANAGERS_PERMIT_DESCRIPTION = 11746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_LPR_MANAGERS_HOTLIST_DESCRIPTION = 11747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_LPR_MANAGERS_PERMIT_DESCRIPTION = 11748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_PATROLLERS_HOTLIST_DESCRIPTION = 11749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_PATROLLERS_PERMIT_DESCRIPTION = 11750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_SHARPS_HOTLIST_DESCRIPTION = 11751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_SHARPS_PERMIT_DESCRIPTION = 11752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_HOTLIST = 11753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_PERMIT = 11754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANCEL_ENTITY_CREATION = 11755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANCEL_ENTITY_MODIFICATION = 11756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_ENTRY_WILL_BE_DELETED = 11757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WHILE_CHECKING_VIDEOFILE_DATABASE_ENTRY = 11758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WHILE_BUILDING_MISSING_FILES_LIST = 11759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WHILE_BUILDING_ORPHAN_FILES_LIST = 11760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SYNCHRONIZE_RESTART = 11761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SYNCHRONIZE_BUTTON = 11762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SYNCHRONIZE_AND_RESTART_BUTTON = 11763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DEVICES_CANNOT_BE_SWAPPED = 11764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEVICE_CONFIGURED_ON_ACCESSPOINTGROUP = 11765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_ARCHIVER = 11766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_ACCESSMANAGER = 11767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AS_ALARM = 11768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AS_EVENT = 11769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_PROMOTE_TO_ADMIN = 11770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS = 11771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_2D_WORLD_IMAGERY = 11772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_2D_WORLD_SHADED_RELIEF = 11773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_2D_STREET_MAP_WORLD = 11774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_2D_TOPOGRAPHIC_US = 11775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_WORLD_PHYSICAL = 11776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_WORLD_SHADED_RELIEF = 11777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_WORLD_STREET = 11778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_WORLD_TERRAIN = 11779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARC_GIS_WORLD_TOPOGRAPHIC = 11780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BING_MAP = 11781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BING_HYBRID_MAP = 11782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BING_SATELLITE_MAP = 11783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOUD_MADE_MAP = 11784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CZECH_HISTORY_MAP = 11785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CZECH_HYBRID_MAP = 11786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CZECH_MAP = 11787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CZECH_SATELLITE_MAP = 11788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CZECH_TURIST_MAP = 11789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_CHINA_HYBRID_MAP = 11790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_CHINA_MAP = 11791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_CHINA_SATELLITE_MAP = 11792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_CHINA_TERRAIN_MAP = 11793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_HYBRID_MAP = 11794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_KOREA_HYBRID_MAP = 11795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_KOREA_MAP = 11796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_KOREA_SATELLITE_MAP = 11797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_MAP = 11798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_SATELLITE_MAP = 11799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOOGLE_TERRAIN_MAP = 11800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LATVIA_MAP = 11801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LITHUANIA__3D_MAP = 11802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LITHUANIA_HYBRID_MAP = 11803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LITHUANIA_HYBRID_OLD_MAP = 11804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LITHUANIA_MAP = 11805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LITHUANIA_ORTO_FOTO_MAP = 11806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LITHUANIA_ORTO_FOTO_OLD_MAP = 11807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BENDER_WMS_DEMO_MAP = 11808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEAR_HYBRID_MAP = 11809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEAR_MAP = 11810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEAR_SATELLITE_MAP = 11811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_CYCLE_LANDSCAPE_MAP = 11812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_CYCLE = 11813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_CYCLE_TRANSPORT_MAP = 11814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_SEA_HYBRID_MAP = 11815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_STREET_4U_MAP = 11816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_STREET_MAP = 11817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_STREET_MAPQUEST_MAP = 11818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_STREET_MAPQUEST_HYBRID_MAP = 11819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_STREET_MAPQUEST_SATELLITE_MAP = 11820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVI_HYBRID_MAP = 11821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVI_MAP = 11822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVI_SATELLITE_MAP = 11823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVI_TERRAIN_MAP = 11824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPAIN_MAP = 11825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TURKEY_MAP = 11826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIKIMAPIA_MAP = 11827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YAHOO_HYBRID_MAP = 11828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YAHOO_MAP = 11829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YAHOO_SATELLITE_MAP = 11830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YANDEX_HYBRID_MAP = 11831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YANDEX_MAP = 11832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DECRYPTION_FAILED = 11833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_RECIPIENTS = 11836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ENROLLING_NEW_HID_EVO_CONTROLLERS = 11837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACCESS_MANAGER_IN_BACKWARDS = 11838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INSUFFICIENTPRIVILEGES_CREATE_ALARM_TRIGGER = 11839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STE_LICENSE_BADLICENSE_ADC_WARNING = 11840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STE_LICENSE_BADLICENSE_ADC_ERROR = 11841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDHOLDER_DUPLICATE_PIN = 11842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FIXED_LENGTH_ATTRIBUTE_REQUIREMENT = 11843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_SAVE_HOTLIST_PROPERTIES = 11844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_SAVE_PERMIT_PROPERTIES = 11845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FIXED_LENGTH_ATTRIBUTES_REQUIRED = 11846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_VAULT_FOLDER_DOES_NOT_EXIST = 11847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_PARSE_EMAILS = 11848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_ENTITY_TYPES = 11849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EMAILS = 11850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_OCCURED_READING_FILE = 11851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED_TO_OPEN_GENETEC_VIDEO_PLAYER = 11852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IMPORT_KML = 11853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KML_NO_VALID_POLYGON = 11854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KML_NO_POLYGON = 11855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_KML_INVALID = 11856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_BITRATE_REDUCTION_STRENGTH = 11857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_DYNAMIC_GOP = 11858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_MAXLENGTH = 11859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DYNAMIC_GOP_MIN_LENGTH = 11860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REDIRECTION_STRATEGY = 11861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STRATEGY = 11862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXISTING = 11863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_FAST_ACCESS_CONTROL = 11864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_DESCRIPTION_FAST_ACCESS_CONTROL = 11865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVE_INTERFACE_FROM_SMC_UNIT = 11866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_ACCESS_ERROR = 11867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_NO_FREE_SPACE = 11868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_WRITE_PROTECTED = 11869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_NOT_INSERTED = 11870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_MAINTENANCE_WARNING = 11871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_MAINTENANCE_ERROR = 11872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORTTRICKLING = 11873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_ADD_NETWORKCARD_PORT_TWICE = 11874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REDIRECTION_LIST_ORDER = 11875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SILENCE_INTRUSION_ALARM = 11876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_INTRUSION_ALARM_SILENCED = 11877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SILENCE_INTRUSION_ALARM = 11878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ALARM_STATE_ALARM_SILENCED = 11879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_INTRUSION_ALARM_ACKNOWLEDGED = 11880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_ACKNOWLEDGE_INTRUSION_ALARM = 11881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ACKNOWLEDGE_INTRUSION_ALARM = 11882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DOOR_UNLOCK_ALL = 11883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GOTO_NEXT_CONTENT_IN_CYCLE_ALL = 11884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GOTO_PREVIOUS_CONTENT_IN_CYCLE_ALL = 11885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_ACK_ALL = 11886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_NACK_ALL = 11887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALARM_FORCE_ACK_ALL_TILE = 11888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SNOOZE_ALARM_ALL = 11889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TOGGLE_RECORDING_ALL = 11890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STOP_CYCLING_ALL = 11891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TOGGLE_MONITORING_ALL = 11892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ARM_ALL = 11893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISARM_ALL = 11894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_START_CYCLING_ALL = 11895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_INTRUSION_ALARM_STATE = 11896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TALK_ALL = 11897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_LISTEN_ALL = 11898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_IP_ADDRESS = 11899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_REQUESTING_ENCRYPTION = 11900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_REQUESTING_ENCRYPTION = 11901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DECODING_STREAM = 11902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STREAMING_STATUS_DETAILS_DECODING_STREAM = 11903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHANNEL = 11904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTERFACES = 11905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMALLY_OPEN_NOT_SUPERVISED = 11906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMALLY_CLOSED_NOT_SUPERVISED = 11907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMALLY_OPEN_SUPERVISED = 11908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMALLY_CLOSED_SUPERVISED = 11909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZE_PICTURES = 11910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRANSFER_NOW = 11911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_ACTIVE_DIRECTORY_DOMAIN = 11912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_ENCRYPTION = 11913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CLEAR_SELECTION = 11914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_ADFS_USERGROUPS = 11915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_TRUST_CHAINS_DOMAINS = 11916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_USER_GROUPS_HINT = 11917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PENDING_DELETE = 11918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PENDING_ADD = 11919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BAUDRATES = 11920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_DATABASE_SETTINGS = 11921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOLIDAYS = 11922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_ZONES = 11923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROCEDURES = 11924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGERS = 11925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSACTIONS = 11926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EDITED = 11927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DIAGNOSTIC_DATA_COLLECTION_TOOL = 11928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_SUPERVISED_INPUT_VALUES = 11929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_STATE_PARTIALLY_SUCCEEDED = 11931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_STATE_USER_CANCELLATION = 11932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LPR_CANT_START_WEB_SERVICE = 11933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_TEMPERATURE_DETECTION_ON = 11934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_TEMPERATURE_DETECTION_OFF = 11935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PATROLLER_LOGON = 11936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_PATROLLER_LOGON = 11937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INSUFFICIENT_SECURITY_INFORMATION = 11938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PATROLLER_ACCESS_READS_HITS = 11939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PATROLLER_ACCESS_READS_HITS = 11940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATROLLER_USER_PRIVILEGE_TEMPLATE_DESCRIPTION = 11941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGETEMPLATE_PATROLLER_USER = 11942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNSUPPORTED_FILTER = 11943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGER_LPRUNIT_READ = 11944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TRIGGER_LPRUNIT_READ = 11945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TRIGGER_LPRUNIT_READ = 11946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_TRIGGER_READ = 11947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_ADFS_INTEGRATION_COUNT = 11949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ADFS_NAME = 11950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ADFS_DESCRIPTION = 11951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_INPUT_STATE_ACTIVE = 11952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_INPUT_STATE_NORMAL = 11953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_INPUT_STATE_TROUBLE = 11954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_SUBTYPE_INPUT_STATE_ACTIVE = 11955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_SUBTYPE_INPUT_STATE_TROUBLE = 11956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_INTERFACE_DETAILS = 11957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MEMBERSHIP_ASSIGN = 11958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MEMBERSHIP_ASSIGN_REMOVE = 11959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_PASSWORD_CHANGE = 11960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DEFAULT_CHANGE = 11961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_VALUE_CHANGE = 11962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_BLOB_MODIFIED = 11963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_INT_OFF = 11964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ITEM_ADDED = 11965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ITEM_REMOVED = 11966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_STILLFRAME = 11967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASEFAILOVER_ADD = 11968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASEFAILOVER_REMOVE = 11969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DIRECTORYFAILOVER_ADD = 11970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DIRECTORYFAILOVER_REMOVE = 11971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_THREATLEVEL_ACTION_ADD = 11972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_THREATLEVEL_ACTION_REMOVE = 11973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_USER_HOTACTION_ADD = 11974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_USER_HOTACTION_REMOVE = 11975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_USER_LOGONSCHEDULE_ADD = 11976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_USER_LOGONSCHEDULE_REMOVE = 11977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_PTZPRIORITY_ADD = 11978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_PTZPRIORITY_REMOVE = 11979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_USER_TASK_ADD = 11980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_USER_TASK_REMOVE = 11981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ADGROUP_SYNC_ADD = 11982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ADGROUP_SYNC_REMOVE = 11983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_PARTITION_ADMIN_ON = 11984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_PARTITION_ADMIN_OFF = 11985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ACCESSRIGHT_ADD = 11986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ACCESSRIGHT_REMOVE = 11987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_SUPERVISOR_ADD = 11988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_SUPERVISOR_REMOVE = 11989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISSING_FILES = 11990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RUN_TEST = 11991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_ENCRYPTION_KEY_FILE = 11992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ENCRYPTED_VIDEO_PROHIBITED = 11993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ENCRYPTION_DENIED_RENDERLESS = 11994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_STILLFRAME_REMOVED = 11995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TWO_PERSON_RULE = 11996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_VISITOR_ESCORT_RULE = 11997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INHERITED = 11998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_INHERITED = 11999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_DELAY_BETWEEN_CARD_PRESENTATIONS = 12000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORT_ELEVATOR_CONTROL = 12001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORT_IO_LINKING = 12002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORT_IO_LINKING_SCHEDULE = 12003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PHYSICAL_ADDRESS = 12004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGER_EVENT_DELAY = 12005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNLOCKING_DOOR_ACTION = 12006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCKING_DOOR_ACTION = 12007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ENCRYPTED_FILE = 12008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENCRYPTION_CERTIFICATE_EXPIRED_FORMAT = 12009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENCRYPTION_CERTIFICATE_EXPIRING_SOON_FORMAT = 12010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENCRYPTION_CERTIFICATE_EXPIRED = 12011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENCRYPTION_CERTIFICATE_EXPIRING_SOON = 12012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_OUTPUTMODE_4KHIGHFRAMERATE = 12013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_OUTPUTMODE_4KMULTISTREAMING = 12014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_OUTPUTMODE_VGACROPPING = 12015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_OUTPUTMODE_FHDCROPPING = 12016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_OUTPUTMODE_FULLSIZE = 12017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_OUTPUTMODE_HDMI = 12018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_VIDEO_CODEC = 12019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_CAMERA_CONFIG_RESET_WARNING = 12020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUT_MODE = 12021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASPECT_RATIO = 12022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASPECT_RATIO_4_3 = 12023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASPECT_RATIO_16_9 = 12024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASPECT_RATIO_3_2 = 12025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BFRAME = 12026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALLATION = 12027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PERMIT_RETRICTION = 12028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_GENERAL_SETTINGS_HOTLIST = 12029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_GENERAL_SETTINGS_OVERTIME = 12030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_GENERAL_SETTINGS_PERMIT = 12031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_GENERAL_SETTINGS_ACCEPT_REASONS = 12032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_GENERAL_SETTINGS_RETRY_COUNT = 12033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_GENERAL_SETTINGS_ENFORCED_HIT_ATTR = 12034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_ANNOTATION_FIELD_DELETED = 12035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_METADATA = 12036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODIFIED_METADATA = 12037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_LOADING_CERTIFICATE_FILE = 12038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CERTIFICATE_UNICITY = 12039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALLED_CERTIFICATES = 12040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ISSUED_BY = 12041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ISSUED_TO = 12042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALID_FROM = 12043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BROWSE_CERTIFICATE_FILE = 12044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SELECT_CERTIFICATE = 12045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_VIEW_CERTIFICATE_DETAILS = 12046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_USE_SERVERADMIN_SERVER_CERTIFICATE = 12047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REMOVE_CERTIFICATE = 12048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REMOVE_CERTIFICATES = 12049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVE_SERVER_CERTIFICATES = 12050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_SUMMARY = 12051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_TOP_LEFT = 12052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_TOP_RIGHT = 12053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_BOTTOM_LEFT = 12054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_BOTTOM_RIGHT = 12055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_OVERTIMETYPE_NONE = 12056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_OVERTIMETYPE_SHORTTERM = 12057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_OVERTIMETYPE_LONGTERM = 12058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_FEDERATION_ENABLE_PLAYBACK = 12059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_SHARP_DISCOVERY_PORT_MODIFIED = 12060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_FEDERATION_EVENTFILTERS_ADDED = 12061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_FEDERATION_EVENTFILTERS_REMOVED = 12062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_ERROR_DATA_RETRIEVED = 12063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ITEM_MODIFIED = 12064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SWITCH_VIEW = 12065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_CERTIFICATE = 12066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_USER_LOGON_FAILED = 12067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_USER_LOGON_FAILED = 12068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOSPECIFIC_LINKEDID = 12069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEACTIVATE_X_DAYS_AFTER_FIRST_USE = 12070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_START_PROTECT_SEQUENCE = 12071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_STOP_PROTECT_SEQUENCE = 12072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYEVENT_START_STOP_PROTECT_SEQUENCE = 12073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESSPOINTTYPE_READER_SIDE = 12074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEVICE_SPECIFIC_SETTING = 12075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_STORAGE_DIFFERENT_COUNT = 12076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_LISTADDITIONTYPE_ADDED = 12077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_LISTADDITIONTYPE_REMOVED = 12078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKING_LOTS = 12079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_SPECIFIC_SETTING = 12080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_ROOM_DESCRIPTION = 12081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_BUILDING_DESCRIPTION = 12082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_CAMPUS_DESCRIPTION = 12083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_CITY_DESCRIPTION = 12084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_SPECIFIC_DESCRIPTION = 12085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_GEOGRAPHICAL_DESCRIPTION = 12086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_ROOM = 12087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_BUILDING = 12088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_CAMPUS = 12089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_CITY = 12090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_SPECIFIC = 12091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE_GEOGRAPHICAL = 12092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_MILLIMETERS_ABBREV = 12093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCEPT_REASONS_GROUP = 12094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ACCEPT_REASONS_GROUPS = 12095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INDEX = 12096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_DIRECTORY_0_CERT_NOT_TRUSTED = 12097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DISCOVER = 12098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PATROLLER_CUSTOM_HOTLISTS = 12099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PATROLLER_CUSTOM_PERMITS = 12100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PATROLLER_INHERITS_HOTLISTS = 12101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PATROLLER_INHERITS_PERMITS = 12102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PATROLLER_IS_IN_SERVICE_MODE = 12103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TRUSTED_SERVER_CONFIRMATION = 12104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_SERVER_NOT_VERIFIED = 12105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_CERTIFYING_AUTHORITY = 12106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CONTINUE_LOGON = 12107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ACCEPT_CERTIFICATE = 12108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THUMBPRINT = 12109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALLOW_PREV_VER_INSECURE_CONNECTION = 12110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUT_OF_FORMAT = 12111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_OR_INTERFACE = 12112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_LPRUNIT_SUPPORTS_TRIGGER_READ = 12113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_LPRUNIT_INSTANCE_ID = 12114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ENTITY_OWNER_REMOVE = 12115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ENTITY_OWNER_ADD = 12116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTED = 12117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_PROVIDER_AUTOCAD = 12118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_PROVIDER_IMAGE = 12119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_PROVIDER_PDF = 12120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_PROVIDER_XPS = 12121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_CREATEINCIDENT = 12122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_SINGLEMONITOR = 12123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_MULTIMONITOR = 12124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_LAYERS = 12125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_SMARTCLICK = 12126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_COMBO_EMPTY_SELECTION_MESSAGE = 12127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_HIDEEVENTS = 12128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LINKSITEM_TOOLTIP_MULTIPLE = 12129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LINKSITEM_TOOLTIP = 12130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MACRO_DEFAULTCONTEXT = 12131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_KML_ACTIVELAYERS = 12132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_STORAGE_INVALIDFOLDER = 12133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_STORAGE_UNABLETOCREATE = 12134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_STORAGE_ROLE_READPERMISSION = 12135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_STORAGE_ROLE_WRITEPERMISSION = 12136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_SUPERVISOR_LOGON_FAILED = 12137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_H263 = 12138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLYGON = 12139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_IOS = 12140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FILE_FORMAT_NOT_SUPPORTED = 12141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_USE_SUPPORTED_FORMAT = 12142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_TOOL_LASSO_SELECT = 12143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_BRING_FORWARD = 12144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_BRING_TO_FRONT = 12145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_SEND_TO_BACK = 12146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_SEND_BACKWARD = 12147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_DISCARD_CHANGES = 12148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_CHANGE_BACKGROUND = 12149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_SET_DEFAULT_VIEW = 12150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_ALIGN_CENTER_HORIZONTAL = 12151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_ALIGN_TOP = 12152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_ALIGN_CENTER_VERTICAL = 12153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_ALIGN_BOTTOM = 12154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_DISTRIBUTE_HORIZONTALLY = 12155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_DISTRIBUTE_VERTICALLY = 12156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_MENUITEM_SELECT_ALL = 12157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_TOOL_RECTANGLE_SELECT = 12158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_TOOL_TYPE_SELECT = 12159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BEHAVIOR = 12160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_ADD_ACTION = 12161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_BLOCK_FOV = 12162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_BLOCK_FOV_TOOLTIP = 12163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_SHOW_FOV = 12164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISTANCE = 12165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFSET = 12166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_FOV_MAX_DISTANCE = 12167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_FOV_ALTITUDE = 12168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_FOV = 12169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_PREVIEW_VIDEO = 12170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_LONG = 12171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIDGET_LAT = 12172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_PREVIEW_UNAVAILABLE = 12173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARRANGE = 12174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALIGN = 12175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROTATE = 12176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_DESIGNER_DELETE_MAP_ASSOCIATED = 12177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_DESIGNER_DELETE_MAP = 12178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_DEFAULT_SELECT_GEOGRAPHICAL = 12179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_DEFAULT_SET_IN_ROLE = 12180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DRILLDOWN_ADFS_ROOT = 12181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADFS_DOMAINS = 12182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADFS_RELYINGPARTY = 12183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_FULLYQUALIFIEDNAME = 12184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_NETBIOS = 12185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_DEFAULTCLAIM = 12186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_NAME_CLAIM = 12187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_LOGOFF_DURATION = 12188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOMAIN = 12189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PTZ_PRIORITY = 12190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORKSTATIONS_MAXIMUM = 12191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASECLEANUP_INTERVAL = 12192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_FILESTREAM_FOLDER = 12193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_HOTLISTS = 12194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_PROVIDER_INTERVAL = 12195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_PROPERTY_SERVICEDOMAIN = 12196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_XML = 12197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_OWNERSHIP_UPDATE = 12198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_DRILLDOWN_IGNORE = 12199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_IMPORT_AREA_AS_REGION = 12200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_IMPORT_AREA_ELEMENT_ONLY = 12201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_SELECT_DEFAULT_VIEW = 12202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OR = 12203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_ACK_ALL = 12204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_FORCE_ACK_ALL = 12205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION = 12206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOATING = 12207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_RECENT_MAP = 12208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_ALL_MAPS = 12209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_NO_THUMBNAIL = 12210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_BROWSE_ALL_MAPS = 12211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_SELECT_MAP = 12212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAST_EVENTS = 12213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_LAYERS = 12214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_SHOW_MINIMAP = 12215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_ADD_TO_FAVORITE = 12216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_REMOVE_FROM_FAVORITE = 12217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_OPEN_IN_DESIGNER = 12218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_SET_AS_DEFAULT = 12219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_SET_AS_DEFAULT_GLOBAL = 12220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROWSE_ALL = 12221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_NO_DEFAULT_SELECT = 12222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_APPLY_BACKGROUND = 12223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_WIZARD_IMAGE_FORMAT = 12224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_GEOGRAPHIC = 12225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_WIZARD_GEOGRAPHIC_TYPE = 12226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERATING_IMAGE = 12227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_WIZARD_REPLACE_BACKGROUND = 12228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_WIZARD_WHAT_KIND_BACKGROUND = 12229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_SUPPORTED = 12230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_FORMAT_NOT_SUPPORTED_LICENSE = 12231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_WIZARD_IDENTITY_DESCRIPTION = 12232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_AREA = 12233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_AREA_NAME = 12234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ICON = 12235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_WIZARD_IDENTITY_TITLE = 12236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WARNING_SELECT_EXISTING_AREA = 12237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIZARD_SPECIFIC_SCALE_INSTRUCTION = 12238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_WIZARD_GEOGRAPHIC_SCALE_INSTRUCTION = 12239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_SET_MARKER = 12240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_WIZARD_SCALE_TITLE = 12241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_LABEL_GENERATING_MAP = 12242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_MAP = 12243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_LAYERS_IMPORT = 12244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_SELECTED_LAYERS = 12245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BAD_FILE_FORMAT = 12246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_ADDRESS = 12247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_ADDRESS_DESCRIPTION = 12248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THUMBNAIL_NOT_AVAILABLE = 12249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATE_MAP = 12250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_MAP = 12251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_MAP = 12252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIODATAFORMAT_MPGA = 12253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LEGACY_SERVER_PORT = 12254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPS = 12255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPS_LC = 12256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ENCRYPTED_VIDEO_NO_PRIVATE_KEY = 12257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCEPTED_USER_GROUPS = 12258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_DEFAULT_GROUP_CLAIM = 12259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_URL = 12260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_OVERRIDE_RELYING_PARTY = 12261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_DIFFERENT_FROM_PREVIOUS = 12262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INVALID_CREDENTIALS = 12263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_REQUIRED_USERNAME = 12264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INVALID_USERNAME = 12265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UNABLE_VALIDATE_LOCALUSER = 12266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMIT_PATH = 12267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NOT_ENOUGH_MEMORY_FOR_BUFFERING = 12268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PERMIT_HIT_REJECT_REASONS = 12269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_OVERTIME_HIT_REJECT_REASONS = 12270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_HOTLIST_HIT_REJECT_REASONS = 12271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_RECORDINGSCHEDULE_ADD = 12272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_RECORDINGSCHEDULE_REMOVE = 12273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_STATES = 12274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIDE_STATES = 12275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIP_SMART_CLICK = 12276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_STATE_COMPRESSING_FILES = 12277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_MAP_SOURCE_FILES = 12278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_AUTOCAD = 12279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_IMAGE = 12280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_PDF = 12281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_XPS = 12282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_KILOMETERS = 12283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_CENTIMETERS = 12284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_FEET = 12285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_MILES = 12286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MEASUREMENT_UNIT_INCHES = 12287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MOTIONZONE_ADD = 12288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MOTIONZONE_REMOVE = 12289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MOTIONZONE_MAP_MODIFIED = 12290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_MOTION = 12291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_RECORDING = 12292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_DEFAULT_CONTEXT = 12293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_MAP_MONITORING = 12294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OLDER_FIRST = 12295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEWER_FIRST = 12296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MACRO_CODECHANGED = 12307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_1_DETAIL = 12308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_1_TITLE = 12309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_2_DETAIL = 12310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_2_TITLE = 12311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_3_DETAIL = 12312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_3_TITLE = 12313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_4_DETAIL = 12314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_5_DETAIL = 12315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_5_TITLE = 12316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSISTANT_PAGE_7_TITLE = 12317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_STATE_USER_CANCELLATION_IN_PROGRESS = 12318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_EDIT_READ_PLATE = 12319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_EDIT_READ_PLATE = 12320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ON_MAP = 12321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPERATION_MAPS = 12322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_FILES = 12323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LIVE_VIDEO_FAILURE = 12324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PLAYBACK_VIDEO_FAILURE = 12325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_VIDEO_FAILURE = 12326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGER_AN_ALARM = 12327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_NOT_REFERENCED = 12328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAPS_NOT_REFERENCING_CAMERA = 12329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYER_CLUSTER = 12330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYER_DEFAULT = 12331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYER_FOV = 12332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_ROLE_FAILOVER_SERVER = 12333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_ROLE_FAILOVER_REMOVE = 12334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_REMOVE_ENCRYPTION = 12335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_EXPORT_VIDEO_REMOVE_ENCRYPTION = 12336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_DUPLICATEACTIVEDIRECTORIES_DETAILED = 12337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_DUPLICATEADFS = 12338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_ERROR_DUPLICATEADFS_DETAILED = 12339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTEXTMENU_SHOW_EVENTS = 12340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIAGNOSTIC_STATE_PREPARING_ARCHIVE = 12341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAYED_AT = 12342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVIEW_POSITION = 12343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_VIDEO_DISPLAY_CONFIGURATION = 12344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT_MONITORING = 12345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SUSPEND_MONITORING = 12346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESUME_MONITORING = 12347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARM_ALL_TILES = 12348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISARM_ALL_TILES = 12349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITOR_ALARMS = 12350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITOR_EVENTS = 12351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MONITORING_SUSPENDED = 12352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MONITORING_EVENT_WARNING = 12353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MONITORING_ALARM_WARNING = 12354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MONITORING_INACTIVE = 12355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MONITORING_ACTIVE = 12356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INSUFFICIENT_PRIVILEGES_CONVERT_ENCRYPTED = 12357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_ROLE_SERVER_ADDED = 12358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_ROLE_SERVER_REMOVED = 12359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGER_NOUN = 12360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_METER_ABBREV = 12361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_CENTIMETERS_ABBREV = 12362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_FEET_ABBREV = 12363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_MILES_ABBREV = 12364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_KILOMETERS_ABBREV = 12365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITOR_ALL_ALARMS = 12366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_MANAGER_ROLE = 12367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_EVENT2ACTION_ADDED = 12368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_EVENT2ACTION_MODIFIED = 12369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_EVENT2ACTION_REMOVED = 12370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_CREDENTIALS_SYNCED = 12371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_VALIDCARDNOPIN = 12372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_VALIDCARDNOPIN = 12373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_VALIDCARDREJECTEDPIN = 12374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_VALIDCARDREJECTEDPIN = 12375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_LIVE_HTTP_LISTENING_PORT = 12376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_UPDATE_PROVIDER_HTTP_LISTENING_PORT = 12377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_EMAIL_NOTIFICATION_LOGGING = 12379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_CARDANDPINTIMEOUT = 12380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_CARDANDPINTIMEOUT = 12381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_VALIDCARDINACTIVEPIN = 12382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_VALIDCARDINACTIVEPIN = 12383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_HOTLIST_PLATE_FILTER_LOGGING = 12384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HTTPS_LISTENING_PORT = 12385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEWARPER_OPTIMIZE_QUALITY = 12387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEWARPER_OPTIMIZE_PERFORMANCE = 12388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UNIT_USING_DEFAULT_USER_PASSWORD = 12389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READS_HITS_SERVICE = 12390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PICTURE_IN_PICTURE = 12391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SUSPEND_TILE_MONITORING = 12392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CREDENTIAL_REQUEST_REASONS = 12393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_USER_LOGOFF_FORCED = 12394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ACCESS_CONTROL_UNIT_REBOOT = 12395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_USE_CAMERA_APPLICATION = 12396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NOTIFICATION_MAP_CRUNCH_DELETED = 12397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NOTIFICATION_MAP_CRUNCH_PENDING = 12398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NOTIFICATION_MAP_CRUNCH_THUMBNAIL = 12399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NOTIFICATION_MAP_CRUNCH_TILES = 12400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NOTIFICATION_MAP_CRUNCH_TILES_REMAINING = 12401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_NOTIFICATION_MAP_CRUNCH_COMPLETED = 12402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_AM_EXTENSION_ADDED = 12403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_AM_EXTENSION_REMOVED = 12404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ACCESS_CONTROL_UNIT_SYNC_STARTED = 12405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_REGISTRATION_REQUIRED = 12406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYER_DEFAULT_COPIE = 12407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYER_HITS = 12408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYER_READS = 12409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GOTO_UNIT_WEBPAGE = 12410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_MULTIMONITOR_OFF = 12411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_FILTER = 12412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOTION_DETECTION_WHEN_ENCRYPTION_ENABLED = 12413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_CONFIGURATION_TASK = 12414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_CONFIGURATION_TASK_DESC = 12415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_MONITORING_TASK = 12416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCIDENT_MONITORING_TASK_DESC = 12417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATURE_MC_INCIDENTS = 12418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATURE_MC_INCIDENTS_DESC = 12419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_INCIDENT_MANAGEMENT = 12420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WINPCAP_LIBRARY_NOT_FOUND = 12421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_REQUIRED_PASSWORDS = 12422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAXIMUM_INTERFACES_REACHED = 12423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOM_CARD_FORMATS = 12424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAYERS = 12425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UNABLE_VALIDATE_MISSING_SERVER = 12426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ROOTFOLDER_INCORRECT = 12427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOM_CARD_FORMAT_CREATED = 12428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOM_CARD_FORMAT_DELETED = 12429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOM_CARD_FORMAT_MODIFIED = 12430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNABLE_EXTRACT_IMAGE = 12431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_EXTENSION_NAME = 12432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MEMBERSHIP_THREATLEVEL_ASSIGN = 12433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FILE_NOT_ACCESSIBLE = 12434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMUNICATION_MODE = 12435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INSUFFICIENT_SECURITY_INFORMATION_HELP = 12436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_FAILED_UPDATEDS_CARDHOLER_HAS_SHARED_CREDETIALS = 12437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALERT = 12438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DISPLAYEVENT_ELEVATOR_UNKNOWN_CREDENTIAL = 12439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_ENCRYPTION_ERROR = 12440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_UNIT_SUCCESS = 12441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_UNIT_FAILED = 12442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_UNIT_ENROLLMENT_DLG = 12443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADDING_UNIT_FORMAT = 12444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERROR_NO_ACCESS_MANAGER = 12445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOCUMENT = 12446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STATIC = 12447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DYNAMIC = 12448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MAP_CACHE_LOCATION_INFO = 12449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AFTER = 12450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISPLAY_SHADOW = 12451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_MAPMANAGER = 12452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PERMIT_RESTRICTION_DAYS = 12453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PERMIT_RESTRICTION_HOURS = 12454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PERMIT_RESTRICTION_VALIDITY_RANGE = 12455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_COLLAPSE = 12456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPAND = 12457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATED = 12458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PEOPLE_IN_AREA = 12459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITOR_ENTITY = 12460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_SWITCH_TO_LIVE_ALL = 12461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MLPILOCATIONS_ROUTE_CHANGED = 12462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_AUXILIARY_ARCHIVER_ADD_NO_ARCHIVE_STREAM_CAMERA = 12463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HEX_VALUE = 12464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_IS_SYNCHRONIZING = 12465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_NO_SERVER = 12466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERROR_TILE_NOT_FOUND = 12467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_DISPLAY_IN = 12468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_MONITORING_TASK = 12469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_MAP = 12470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRICKLING_TRANSFER_SIMULTANEOUS = 12471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPPROVIDER_CUSTOM = 12472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PERMIT_RESTRICTION_PERMITS = 12473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BROWSE = 12474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORPHAN_FILE_INDEXED_ARCHIVER_NEEDS_TO_BE_RESTARTED = 12475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORPHAN_FILES_INDEXED_ARCHIVER_NEEDS_TO_BE_RESTARTED = 12476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INCIDENTMANAGERS = 12477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OBFUSCATE_ENTITY_NAMES = 12478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATURE_ADVANCED_SECURITY = 12479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_ADVANCED_SECURITY = 12480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CLIENT_CERTIFICATE_UNSUPPORTED = 12481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKGROUND = 12482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROJECTION_WGS84 = 12483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_AUTHENTICATION = 12484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PING_AUTHENTICATION_FAILURE = 12485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UC_OCCURS = 12486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SECURITYCENTER_ONLINE = 12487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_USER_VIEWER = 12488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_USER_OPERATOR = 12489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_FAILOVER_ARCHIVERS_PER_ARCHIVER = 12490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_INVALIDAUTHENTICATION_ERROR = 12491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION_SCHEME = 12492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION_BASIC = 12493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION_DIGEST = 12494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION_ANONYMOUS = 12495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION_USERNAME_TOKEN = 12496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION_THIRD_PARTY = 12497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REFUSE_BASICAUTHENTICATION = 12498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESET_AUTHENTICATION_MODE = 12499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOR_ENTITY = 12500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SYSTEM_AVAILABILITY_MONITOR = 12501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_COLLECTION_POLICY = 12502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_DATA_COLLECTION = 12503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANONYMOUS_DATA_COLLECTION = 12504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEM_DATA_COLLECTION = 12505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_READ_PRIVACY_POLICY = 12506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_READ_PRIVACY_POLICY_END = 12507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_INTELLIGENT_CROPPING = 12508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_INTELLIGENT_CODING = 12509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHORT = 12510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIDDLE = 12511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LONG = 12512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_TRAP_AREA = 12513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_TRACKING_PERIOD = 12514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SONY_HIGHRESOLUTION_WARNING = 12515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENROLL_DISCONNECTED_UNIT = 12516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADDED_DISCONNECTED = 12517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISABLE_UNIT = 12518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_UNIT = 12519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DISABLE_UNIT = 12520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DISABLE_UNITS = 12521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MAINTENANCE_ENABLED = 12522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MAINTENANCE_DISABLED = 12523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MAINTENANCE_MODIFIED = 12524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WELCOME_TO_SECURITY_CENTER = 12525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPACT_EDITION = 12526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INITIAL_CONFIGURATION_INTRODUCTION_COMPACT = 12527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_END_USER_LICENSE_AGREEMENT = 12528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSE_AGREEMENT = 12529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MACHINE_NAME_BLANK_LOCAL = 12530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLEASE_ACTIVATE_DIRECTORY = 12531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSE_MODIFIED = 12532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTART_REQUIRED = 12533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LANGUAGE_MODIFIED_RESTART = 12534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_I_ACCEPT = 12535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_I_DECLINE = 12536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONMODE_STANDARD = 12537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONMODE_APPLIANCE = 12538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONMODE_COMPACT = 12539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONMODE_ONLINE = 12540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_APPLICATIONMODE_CLOUD = 12541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN_NOT_USED = 12542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_TOGGLE_FULL_VIEW = 12543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOOR_LOCK_AD400 = 12544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOOR_LOCK_AD300 = 12545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_NUMBER = 12546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RESTART_INTRUSION_UNITS = 12547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RESTART_INTRUSION_UNITS = 12548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UPGRADE_INTRUSION_UNIT_FIRMWARE = 12549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UPGRADE_INTRUSION_UNIT_FIRMWARE = 12550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RTSP_SERVER_HTTPFAILED_TOLISTEN = 12551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RTSP_SERVER_HTTPSUCCEED_TOLISTEN = 12552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SQL_ALWAYSON = 12553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THEN_BY = 12554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_GROUPING = 12555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_LAYER_SHAPE = 12556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPS_HIDE_EMPTY_LAYERS = 12557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOOKMARK_NOT_SAVED_IN_ARCHIVER = 12558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLUR = 12559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLUR_LEVEL = 12560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHAPE = 12561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAINTENANCE_TURN_OFF = 12562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TURN_ON = 12563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TURN_OFF = 12564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORPHAN_FILES_IN_CONFLICT = 12565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORPHAN_FILE_IN_CONFLICT = 12566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTENANCE_MODE_IS = 12567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_ENDTIME = 12568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HTTP = 12569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RTSP = 12570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_NO_PASSWORD = 12571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_RTSP_NOT_SECURE = 12572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STREAM_ACCESSIBLE_TO = 12573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IO_ZONE = 12574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IO_ZONE_DESCRIPTION = 12575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DISASTERRECOVERY = 12576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUTBEHAVIOUR_WHEN_ACTIVE = 12577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUTBEHAVIOUR_WHEN_INACTIVE = 12578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_PASSWORD = 12579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SDK_ACCESS_1 = 12580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SDK_ACCESS_2 = 12581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_FAILED_INVALID_VIDEO_CODEC_MP4 = 12582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_FAILED_INVALID_AUDIO_CODEC_MP4 = 12583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_FAILED_INVALID_NAMED_CODEC_MP4 = 12584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_PARTIAL_SUCCESS_OVERLAY_REMOVED = 12585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_IOZONE_INVALID_UNIT_TYPE_ONLY_SMC = 12586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_INPUTS = 12587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBER_OF_OUTPUTS = 12588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUT_BEHAVIOR_INACTIVE_COMPLEMENT = 12589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERT_TO = 12590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING_SCHEDULE_EXCEPTION = 12591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REPORT_SENDEMAILSNAPSHOT = 12592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SQL_NATIVE_CLIENT_NOT_INSTALLED = 12593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MEDIA_SDK = 12594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PLUGIN_SDK = 12595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_USE_GLOBAL_CATALOG = 12596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COVER_CAMERA = 12597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGTOOL_AUTHORIZED_FORMAT = 12598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGTOOL_UNAUTHORIZED_FORMAT = 12599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_AVAILABILITY_MONITOR = 12600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOUR = 12601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LBAEL_UPDATES_AVAILABLE = 12602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALL = 12603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSTPONE = 12604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNAUTHORIZED = 12605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_UPDATES_INSTALL_NOW = 12606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATES_WILL_BE_INSTALLED_FORMAT = 12607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MACHINE_UNAUTHORIZED = 12608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_UNAUTHORIZED_ANYMORE_FORMAT = 12609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSE_COMPACT_RESTART = 12610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTART_COMPACT_MODE = 12611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPRGADED_COMPACT_TO_FULL = 12612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MASTER_UNIT = 12613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CONNECTIONSCOUNT = 12614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CAMERASCOUNT = 12615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REPORT_SENDEMAILSNAPSHOT_COPIE = 12616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DUPLICATE_GROUP = 12617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ADD_COLUMN = 12618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABLE_THEN_BY = 12619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORDER_ASCENDING = 12620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORDER_DESCENDING = 12621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_WEB_CLIENT_ROLE_NAME = 12622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_WEB_CLIENT_ROLE_DESCRIPTION = 12623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READ_PRIVACY_AGREEMENT = 12624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAM_YES_MONITOR = 12625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAM_DO_NOT_MONITOR = 12626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAM_DESCRIPTION = 12627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ALLOW_ANONYMOUS_DATA_REPORTING = 12628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_TIMEOUT = 12629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SET_SCALE = 12632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIORITY_CRITICAL = 12633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_USERLOGON_FAILED = 12634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_PROPERTIES = 12635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTION_ON_UNIT = 12636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CAMERA_MUST_BE_RECORDING = 12637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_X_FIELD_REQUIRED = 12638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_FIELD_INVALID_BOUNDS_X_Y = 12639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_FIELD_INVALID_ENUMERATION = 12640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_CONFIGURATION = 12641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE_ON = 12642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_PARTITION = 12643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_RULE = 12644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AFTER_ACTIVATION = 12645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_CAMERA = 12646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_TIME = 12647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVAILABLE_VISITORS = 12648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIALS_INFO = 12649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETAILED_VIEW = 12650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_ACTIVITY = 12651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRING = 12652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TELL_US_WHAT_YOU_THINK = 12653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETEC_WEBSITE = 12654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP = 12655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUPS = 12656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEBCLIENT = 12657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOST = 12658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINTING = 12659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULES = 12660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEARCH_DESCRIPTION = 12661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENDING = 12662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_SELECTED = 12663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIGN_OUT = 12664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THUMBNAIL_VIEW = 12665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILE_PATTERN = 12666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_GUIDE = 12667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LANGUAGE_CULTURE_NOT_FOUND = 12668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASK_AFTER = 12669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_INVALID_EMAIL_ADDRESS = 12670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_INVALID_CANNOT_EXCEED_X_CHARACTERS = 12671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_INVALID_NAN = 12672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_FIELD_IS_REQUIRED = 12673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_INVALID_BETWEEN_X_Y = 12674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_INFORMATION_AVAILABLE = 12675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_NOT_SUPPORTED = 12676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERT_OUTPUT_BEHAVIOR_NORMAL_COMPLEMENT = 12677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OUTPUT_BEHAVIOR_INACTIVE_TROUBLE = 12678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CAMERAS_SELECTED = 12679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDE_AUDIT_METADATA = 12680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPY_COORDINATES = 12681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROWSERS_SUPPORTED = 12682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_DIRECTORY_OLD_VERSION = 12683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_UNTRUSTED_FED_CONNECTIONS = 12684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_SEARCH_FOR_RESULTS = 12685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTION = 12686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DBPROPERTIES = 12687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTARTING = 12688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SERVICEUNAVAILABLE = 12689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_SERVICEUNAVAILABLEMSG = 12690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGGER = 12691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGTIME = 12692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGSEVERITY = 12693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THREADID = 12694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THREADNAME = 12695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMANDPARAMETERS = 12696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREATEDUMP = 12697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CREATEDUMP = 12698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WAITDUMP = 12699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THEMES = 12700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THEME_DARK = 12701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_THEMES_LIGHT = 12702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGGERS = 12703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVICES = 12704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMANDS = 12705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACELOGGER = 12706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_USERCOMMANDONLY = 12707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_ACTIVELOGGERS = 12708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_TRACE = 12709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_ADDSERVER = 12710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_ASSEMBLY = 12711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_KILLSERVICE = 12712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_CREATEDUMP = 12713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_REDIRECTMAIN = 12714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_CANCELREDIRECTION = 12715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_RESTARTGENETECSERVER = 12716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_RESTARTGENETECSERVERQUESTION = 12717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_TIME_INVALID = 12718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_HTTPSPORT = 12719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MODIDYGENETECSERVERPASSWORD = 12720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACCESS_SERVER_ADMIN = 12721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_PLEASEUPGRADE = 12722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_PLEASEUPGRADEPERFO = 12723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_NOTSUPPORTED = 12724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_BROWSERSUPPORTED = 12725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_BROWSERIE9 = 12726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_FAILOVERDESC = 12727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_LOGFOLDER = 12728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_DELETEFILESAFTER = 12729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_CREATEFILEAFTER = 12730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_LINES = 12731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_DEBUG = 12732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_PERFORMANCE = 12733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_INFORMATION = 12734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_WARNING = 12735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_ERROR = 12736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_FATAL = 12737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADDEDSERVERS = 12738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTION_ONARCHIVER = 12739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPTION_ONUNIT = 12740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_X_UPDATED = 12741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_X_CREATED = 12742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_CREATE_X = 12743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_UPDATE_X = 12744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_DELETE_X = 12745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_X_DELETED = 12746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_IMAGE = 12747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLYING_EFFECT = 12748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SETTINGS_MULTIPLE_ENTITIES = 12749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FILE_TOO_LARGE_MAX_X = 12750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_IMAGE_DIMENSIONS = 12751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARENT = 12752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNABLE_TO_SAVE_LANGUAGE = 12753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY_ERROR = 12754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANNOT_SET_AP_TO_APG = 12755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_GROUP_SECURITY_VIOLATION = 12756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTION_SENT_TO_MEDIA_ROUTER = 12757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_X_ENTITIES_DELETED = 12758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_DELETE_ENTITIES = 12759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GENERAL_INVENTORY_ERROR = 12760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_RETRIEVING_LPR = 12761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ASSIGNMENT_NOT_POSSIBLE = 12762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ETHERNET_CONNECTION_FAILED = 12763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_SAVE_CUSTOM_IMAGE = 12764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_EXCEPTIONS_RELATED_TO_ERROR = 12765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_LOADING_FISHEYE_PARAMS = 12766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_SELECTED_TIME_CRITERIA = 12767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DATABASE_RESTORE = 12768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE_PEER_TO_PEER = 12769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALIDATION_FAILED = 12770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NB_ITEMS = 12771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNEXPECTED_ERROR = 12772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CERTIFICATE_PASSWORD = 12773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_WRONGVERSION = 12774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEM_MINIMUM_USER_LEVEL = 12775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INSUFFICIENT_PRIVILEGES_VIEW_MAP = 12776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_IOZONE_BACWARD_COMPATIBILITY_ISSUE = 12777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_UNTRACE = 12778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENVIRONMENT = 12779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DATABASE = 12780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANT_ADD_UNIT_NO_INTRUSION = 12781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMUNICATION_SETTINGS = 12782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ZONE_MAINTENANCE_ON = 12783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ZONE_MAINTENANCE_OFF = 12784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_STARTINGDIRECTORY = 12785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_STOPPINGDIRECTORY = 12786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_ALARM_ACKNOWLEDGED_ALTERNATE = 12787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_RENAMING_ASF_FILE = 12788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILOVER_DATABASE_DIAGNOSTIC_CANNOT_VERIFY_TCP = 12789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIRECTORY_FAILOVER_DIAGNOSTICS_ACCESSDENIED_TCP = 12790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEARCH_MESSAGE = 12791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSGAGE_STOP_DIRECTORY_UPGRADE = 12792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREAS = 12793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_MAINTENANCE_MODE_ENDTIME = 12794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_MAINTENANCE_MODE_ENDTIME_DETAILS = 12795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_PRIORITY = 12796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PROCEDURE_STEP_PROPERTIES = 12797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_RESETTRACELOGGERS = 12798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_IMPORTLOGGERCLIPBOARD = 12799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_EXPORTLOGGERCLIPBOARD = 12800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_SHOWTRACELOGGERCONSOLE = 12801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEO_REFERENCE = 12802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCALE_FACTOR = 12803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_AREA = 12804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_PLAN = 12805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUBSCRIPTION_LICENSE = 12806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDED_WITH_SUBSCRIPTION = 12807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANNUAL = 12808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONTHLY = 12809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SUBSCRIPTION_EXPIRED = 12810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CONNECTION = 12811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNABLE_CONNECT_SERVER = 12812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_DEFAULT = 12813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOREF_NOT_ENOUGH_POINTS = 12814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOREF_INVALID_POINTS = 12815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR_GEOREFERENCE = 12816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLACE_MARKER = 12817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_MARKER = 12818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OWNERS = 12819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXPORT_STATUS_INVALID_CODEC = 12820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DELETE_LPR_HIT = 12821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_DELETE_HIT = 12822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DELETE_LPR_HIT = 12823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_DELETE_HIT = 12824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_DELETE_HIT_INFORMATION = 12825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_DELETE_PROTECTED_HIT = 12826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_DELETE_HITS = 12827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_DELETE_HITS = 12828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EYE_DROPPER = 12829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIGHT_THEME = 12830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DARK_THEME = 12831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE_SYSTEM_REPORT = 12832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_CODE = 12833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHORIZATION_KEY = 12834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONLINE_HELP = 12835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CAMERA_CONNECTION_LOST = 12836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INSUFFICIENT_SECURITY_CAMERA = 12837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STILL_HERE = 12838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SESSION_ABOUT_TO_EXPIRE = 12839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONNECTION_LOST_MEDIAGATEWAY = 12840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_RECORDED_VIDEO_FOR_CAMERA = 12841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TILEPATTERN = 12842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_STREAM_CONNECTION_LOST = 12843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_AREAVIEW = 12844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FILTERS = 12845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_VISITOR = 12846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_CARDHOLDERGROUP = 12847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT_CAMERAS = 12848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT_EVENTTIMERANGE = 12849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONNECTION_LOST_MEDIAGATEWAY_REFRESH = 12850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONNECTION_FAILED_MEDIAGATEWAY = 12851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_MONITORING = 12852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_BOOKMARKS = 12853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_DOORACTIVITY = 12854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_CARDHOLDERS = 12855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_VISITORS = 12856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_CARDHOLDERGROUPS = 12857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_CREDENTIALS = 12858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ZONE_ARMED = 12859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ZONE_DISARMED = 12860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT_DOORS = 12861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_ALARMREPORT = 12862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEARCH_ENTITIES = 12863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GENETEC_UPDATER_SERVICE_UNAVAILABLE = 12864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_SCHEDULE_TIME_FOR_UPDATES = 12865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN_TO_UPDATE = 12866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDING_LOCATION = 12867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEP_ARCHIVES_FOR = 12868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_PERMISSIONS = 12869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAN_USE_PTZ = 12870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WELCOME_SECURITY_CENTER_COMPACT = 12871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADVANCED_SUPPORT_OPERATIONS = 12872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ASSISTANCE_CONTACT_YOUR_INTEGRATOR = 12873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_ENCRYPTION_KEY = 12874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_ENCRYPTION_KEY = 12875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_SSLDISABLED = 12876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PLEASE_ENTER_VALID_HOSTNAME = 12877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CURRENT_TIME = 12878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ZONE_WARNING_UNITOFFLINE = 12879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOCUMENTATION = 12880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_SELECTED_MAP_LAYER_EXCEEDS_LIMIT = 12881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_CONNECTION_SUBSCRIPTION_LICENSE = 12882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_SERVEROFFLINE = 12883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_SPLASHSCREEN = 12884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SELECT_PARTITION = 12885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PTZ_SLIDER = 12886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ALL_PRESETS = 12887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ALL_PATTERNS = 12888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CAMERA_REQUIRED = 12889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOOR_REQUIRED = 12890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BOOKMARKS_PLAY = 12891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REPORT_EXPORT = 12892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONNECTION_ERROR_SEE_LOG = 12893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TOOLS_MOVE_UNIT = 12894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOURCE_FILE = 12895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_PROTECT_LPR_EVENT = 12896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_UNPROTECT_LPR_EVENT = 12897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_PROTECTION_EXPIRATION = 12898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNDOABLE_ACTION = 12899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USEINFINITESESSION = 12900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TRIGGER_PAST_READ_MATCHING = 12901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TRIGGER_PAST_READ_MATCHING = 12902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_TRIGGER_PAST_READ_MATCHING = 12903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGER_PAST_READ_MATCHING = 12904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ALARM_REQUIRED = 12905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_PATROLLER_LOCKDOWN = 12906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODIFY_GEO_REFERENCE = 12907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SET_GEO_REFERENCE = 12908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_MEDIA_GATEWAY = 12909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_MEDIA_GATEWAY = 12910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_LPR_HOTLIST_CHANGED = 12911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAG_PERMIT_INVALID_DATA = 12912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAG_PERMIT_INVALID_DATA_DETAILS = 12913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MISSING_KB2588507_HOTFIX = 12914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT_ALARMS = 12915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_MAP_ITEMS_TO_SET_GEOREF = 12916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_STATE_CHANGE_NOT_POSSIBLE = 12917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OPENWEBCLIENT = 12918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ATTEMPTING_TO_PLAY_MJPEG = 12919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MP4_LIMIT_REACHED = 12920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_STREAM_LOST = 12921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CAMERA_LOST = 12922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_RECORDED_VIDEO_AVAILABLE = 12923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INSUFFICIENT_SECURITY = 12924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GOTOPATTERN = 12925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAP_LAYER_DISABLED = 12926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TRYING_TO_RECONNECT = 12927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_SELECTED_KMZ_EXCEEDS_LIMIT = 12928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_EVENTS = 12929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PROTECT_LPR_EVENT = 12930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_UNPROTECT_LPR_EVENT = 12931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PROTECTED_INDEFINITELY = 12932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PROTECTED_UNTIL = 12933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSG_CONNECTION_ERROR_FIREWALL = 12934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_CREATECERTIFICATE = 12935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WEBCLIENT_CONNECTION_LIMIT_REACHED = 12936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_EQ_IR_ILLUMINATOR_ON = 12937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_EQ_IR_ILLUMINATOR_OFF = 12938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_EQ_IR_ILLUMINATOR_AUTO = 12939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_DETAILS = 12940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ROTATION_CHANGED_MOTION_DETECTION_LOST = 12941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FULLHD = 12942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_BACKGROUND = 12943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_VERYWEAK_DETAILS = 12944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_WEAK_DETAILS = 12945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_GOOD_DETAILS = 12946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_STRONG_DETAILS = 12947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_VERYSTRONG_DETAILS = 12948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_SERVERADMIN_NEEDS_UPDATE = 12949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_UNIT_WEB_PAGE = 12950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_EVENTS_CURRENT_STATUS = 12951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_EVENTS_HISTORY = 12952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADDUNIT_SSLCERTIFICATE_ERROR = 12953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC_CLEANUP_BACKUP_FILES = 12954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_VIDEO_DEINTERLACING_UNAVAILABLE = 12955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVBR = 12956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DRIVER_AND_FIRMWARE_VER = 12957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CERTIFICATEERROR_DEPRECATEDHASH = 12958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CERTIFICATEERROR_DEPRECATEDCRYPTO = 12959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHANGE_SECUREMODE_CONFIRMATION = 12960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLEX_CONFIG = 12961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLEX_CONFIG_FULL = 12962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLEX_CONFIG_HALF = 12963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXCLUDE_RESULTS_REPORT = 12964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNABLE_TO_BUILD_MAPPING_ENGINE = 12965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_AUTHENTICATION = 12966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_BWC_NAME = 12967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_BWC_DESCRIPTION = 12968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TAB_HOLDERS = 12969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CERTIFICATEERROR_AUTOUPGRADE = 12970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESET_INVENTORY = 12971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE_GLOBAL_ANTI_PASSBACK = 12972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_SELECTED = 12973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_IGNORE_REX_AFTER_CLOSE_CONFIGURATION_ERROR = 12974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RESET_INVENTORY = 12975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RESET_INVENTORY = 12976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GENETEC_VIDEO_PLAYER = 12977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_DYNAMIC_FPS = 12978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNSUPPORTED_SD_64 = 12979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SUPPORTED_SD_32 = 12980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_REQUIREMENTS_MAX_CHARACTERS = 12981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGZONE_CAPACITY = 12982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGZONE_LPRMANAGER = 12983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGZONE_HOTLIST = 12984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGZONE_MAX_INVENTORY_DURATION = 12985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGZONE_PARKING_RULES = 12986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGRULE_DEFAULT_EXPIRATION_DELAY = 12987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGRULE_ENABLE_PBP_SYNC = 12988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGRULE_FREE_PARKING_PERIOD = 12989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGRULE_GRACE_PERIOD = 12990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGRULE_PERMIT = 12991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TASK_PARKING_MONITORING = 12992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_PARKING_MONITORING = 12993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_MONITORING_FULL_UPDATE = 12994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_MONITORING_PARTIAL_UPDATE = 12995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_MONITORING_REFRESHING = 12996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_MONITORING_CLICK_TO_REFRESH = 12997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_MONITORING_COUNTDOWN = 12998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_MONITORING_ZONE_NAME = 12999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_MONITORING_CAPACITY = 13000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_MONITORING_IN_LOT_VEHICLE_COUNT = 13001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_MONITORING_OCCUPANCY_PERCENT = 13002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_MONITORING_IN_LOT_VIOLATIONS = 13003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_MONITORING_IN_LOT_VIOLATIONS_PERCENT = 13004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PARKING_ZONE = 13005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PARKING_ZONES = 13006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_PARKING_ZONES = 13007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PARKING_RULE = 13008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_PARKING_RULES = 13009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_PARKING_RULES = 13010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_PARKING_ZONES = 13011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_PARKING_ZONES = 13012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_PARKING_ZONES = 13013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_PARKING_ZONES = 13014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_PARKING_ZONES = 13015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_PARKING_ZONES = 13016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_REMOVE_PARKING_ZONES = 13017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REMOVE_PARKING_ZONES = 13018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_INVENTORY_RESET = 13019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_INVENTORY_RESET = 13020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_UPDATE_INLOT_VIOLATIONS = 13021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_UPDATE_INLOT_VIOLATIONS = 13022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_MONITORING_TASK = 13023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_MONITORING_TASK = 13024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_SESSION_ACTIVITY_REPORT = 13025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_SESSION_ACTIVITY_REPORT = 13026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_SESSIONS_REPORT = 13027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_SESSIONS_REPORT = 13028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_INVENTORY_REPORT = 13029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_INVENTORY_REPORT = 13030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTIPLE_TIME_RANGE = 13031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_CONTROLS = 13032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEWARPER_SETTINGS = 13033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIN_DURATION_OF_STAY = 13034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURATION_OF_STAY = 13035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_TIME = 13036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_PARKING_ZONE_COUNT = 13037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_PARKING_MANAGEMENT = 13038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_PARKING_ZONE = 13039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DIRECTORY_CERTIFICATE_NOT_FULLY_TRUSTED = 13040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DIRECTORY_CERTIFICATE_MANUALLY_TRUSTED = 13041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_BACKWARD_COMPATIBILITY_MODE = 13042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_PLUGIN = 13043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_APPLICATION = 13044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFO_CANNOT_MODIFY_VAULT = 13045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_PARKING_ZONE_RETENTION = 13046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_OCCUPANCY_RETENTION = 13047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_INCIDENTMANAGER = 13048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_PARKING_ZONE_RULES = 13049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_PARKING_ZONE_RULES = 13050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_PARKING_ZONE_RULES = 13051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_PARKING_ZONE_RULES = 13052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_PARKING_ZONE_RULES = 13053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_PARKING_ZONE_RULES = 13054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_REMOVE_PARKING_ZONE_RULES = 13055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REMOVE_PARKING_ZONE_RULES = 13056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAY_BY_PLATE = 13057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNERGIS_ONLY = 13058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRES_10_2_AND_UP = 13059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRES_10_3_AND_UP = 13060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAY_BY_PLATE_SYNC = 13061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_LPR_DEVICES = 13062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MAX_INVENTORY_DURATION = 13063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CONVENIENCE_TIME = 13064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_GRACE_PERIOD = 13065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DEFAULT_EXPIRATION_DELAY = 13066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MISSING_LPR_MANAGER = 13067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PARKING_DEVICE_ALREADY_EXIT_AND_ENTRANCE = 13068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PARKING_DEVICE_ALREADY_EXIT = 13069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PARKING_DEVICE_ALREADY_ENTRANCE = 13070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_UNABLE_TO_PLAY_SOUND = 13071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TIME_ZONE_NOT_SYNCED = 13072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEARCH_BACK_TIME = 13073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXIT_LPR_DEVICES = 13074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTRANCE_LPR_DEVICES = 13075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATED = 13076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIOLATIONS_AMOUNT = 13077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_CAMERAS_ATTACHED = 13078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_PLAY_ALARM = 13079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAY_ALARM = 13080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_CONFIGURE_SOURCE_ENTITY = 13081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_PLAY_DOORACTIVITY = 13082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DOOR_WITHOUT_CAMERA = 13083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAY_DOORACTIVITY = 13084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_HOSTNAME_IP = 13085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SECURITYDESK_OPTIONS_SNAPSHOT_WITH_USERNAME = 13086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_CONTROLS = 13087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_HOSTNAME_OR_IPADDRESS = 13088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_SOURCE_ENTITY_TYPE = 13089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FILE_PATH_ACCESS_DENIED = 13090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FILE_UNABLE_TO_LOAD = 13091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_BASICAUTHENTICATION_DISABLED = 13092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_PLAY_BOOKMARK = 13093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_POSTMATCHED_STATUS = 13094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSTMATCHED_STATUS_POSTMATCHED = 13095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSTMATCHED_STATUS_NOTPOSTMATCHED = 13096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAINTENANCE_INDEFINITELY = 13097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_PTRZCALIBRATION = 13098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PREVIOUS_YEAR = 13099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_NEXT_YEAR = 13100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_CMD_CONFIGURE_X = 13101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_CONVENIENCE_TIME_EXCEEDED = 13102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VEHICLE_ENTRY = 13103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VEHICLE_EXIT = 13104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_INVENTORY_RESET = 13105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_MAX_CAPACITY = 13106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_RULE_INVALID = 13107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_RULE_VALID = 13108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VIOLATION = 13109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VIOLATION_ENFORCED = 13110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VEHICLE_EXIT_UNKNOWN = 13111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VEHICLE_EXIT_CONSECUTIVE_ENTRY = 13112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VEHICLE_EXIT_INVENTORY_RESET = 13113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VEHICLE_EXIT_MAX_DURATION = 13114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY = 13115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DISCOVERY_NAME = 13116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DISCOVERY_DESCRIPTION = 13117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_IMAGEEDITOR_BRIGHTNESSCONTRAST = 13118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_ENTERED_TIMESTAMP = 13119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_EXITED_TIMESTAMP = 13120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_CONVENIENCE_ENDED_TIMESTAMP = 13121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_VIOLATION_TIMESTAMP = 13122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_VIOLATION_ENFORCED_TIMESTAMP = 13123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_CONVENIENCE_TIME_DURATION = 13124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_PAID_DURATION = 13125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_VIOLATION_DURATION = 13126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_ENFORCED_DURATION = 13127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_CONVENIENCE_TIME_TIMESTAMP = 13128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_PAID_TIMESTAMP = 13129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_GRACE_PERIOD_TIMESTAMP = 13130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_GRACE_PERIOD_DURATION = 13131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_TOTAL_DURATION = 13132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTRANCE_READ = 13133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXIT_READ = 13134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ENTRY_PLATE = 13135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ENTRY_CONTEXT_IMAGE = 13136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ENTRY_LPR_IMAGE = 13137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EXIT_PLATE = 13138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EXIT_CONTEXT_IMAGE = 13139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_EXIT_LPR_IMAGE = 13140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_OBJECT_ADDED = 13141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_OBJECT_REMOVED = 13142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_LAYER_ADDED = 13143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_LAYER_REMOVED = 13144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_LINK_SETTING_MODIFIED = 13145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_VIEW = 13146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALID_ACTIVE = 13147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IN_VIOLATION = 13148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_SELECTION = 13149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_STATE = 13150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_PARKING_SESSIONS = 13151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAST_READ_MATCHING_WARNING = 13152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_STATE_MODIFIED = 13153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BANDWIDTH_CONTROL = 13154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BANDWIDTH_CONTROL_SOFT_LIMIT = 13155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BANDWIDTH_CONTROL_HARD_LIMIT = 13156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_MAP_PROVIDER_CHANGE = 13157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAST_READ_MATCHING_WARNING_ROLE = 13158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_PARKING_ZONE_ACTIVITIES = 13159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATURE_NOT_AVAILABLE_GIF = 13160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DISCOVER_INTERFACES = 13161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RTP_PORT = 13162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PIN = 13163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_UNPIN = 13164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEADER_FIELDS = 13165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GROUPING_SWITCH_SIMPLE_VIEW = 13166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNABLE_TO_CHECKOUT_X = 13167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_UNPROTECTED = 13168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_LINK_MODIFIED = 13169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_TIME_RANGE = 13170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SET_MAX_ZOOM = 13171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_RANGE_MULTICAST_PORT_EVEN = 13172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_STARTED = 13173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_CONVENIENCE_TIME = 13174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_GRACE_PERIOD = 13175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_CHECK_PAID_TIME = 13176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_PAID_TIME = 13177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETED = 13178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_PAID_TIME_EXPIRED = 13179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_GRACE_PERIOD_EXPIRED = 13180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_PAID_TIME_GRANTED = 13181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_NO_PAID_TIME_GRANTED = 13182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_PBP_SERVER_ISSUE = 13183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_DUMP_FILE = 13184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_INTERFACE = 13185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_IDENTIFIER_NOT_FOUND = 13186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESTRICTED = 13187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORPHAN_FILES_RESULTS_MAXIMUM_REACHED = 13188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CONFIGURE_PTZ_DRIVER = 13189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_SCHEDULE_COVERAGETYPE = 13190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_EXTERIOR_CAMERAS = 13191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_EXTERIOR_CAMERAS_DETAILS = 13192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ALL_CAMERAS_OFFLINE = 13193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ALL_CAMERAS_OFFLINE_DETAILS = 13194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_FAULTED_CAMERAS_EXIST = 13195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_FAULTED_CAMERAS_EXIST_DETAILS = 13196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ALL_CAMERAS_WORKING = 13197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ALL_CAMERAS_WORKING_DETAILS = 13198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_NO_CAMERAS_ASSIGNED = 13199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_NO_CAMERAS_ASSIGNED_DETAILS = 13200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_MISSING_ENTRANCE_CAMERAS = 13201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_MISSING_ENTRANCE_CAMERAS_DETAILS = 13202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_MISSING_EXIT_CAMERAS = 13203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_MISSING_EXIT_CAMERAS_DETAILS = 13204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ROLE_ERROR = 13205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ROLE_ERROR_DETAILS = 13206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ROLE_WORKING = 13207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_ROLE_WORKING_DETAILS = 13208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_RULE_ERROR = 13209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_RULE_ERROR_DETAILS = 13210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_RULE_WORKING = 13211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKINGZONE_RULE_WORKING_DETAILS = 13212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LPR_CAMERAS = 13213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_VEHICLES = 13214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_ENFORCEMENT = 13215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_STARTED_UNKNOWN_VEHICLE_EXIT = 13216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_STARTED_VEHICLE_ENTERED = 13217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_GRACE_PERIOD_CONVENIENCE_TIME_EXPIRED = 13218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_GRACE_PERIOD_PAID_TIME_INVALID = 13219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_CHECK_PAID_TIME_CONVENIENCE_TIME_EXPIRED = 13220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_CHECK_PAID_TIME_PAID_TIME_EXPIRED = 13221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_PAID_TIME_PAID_TIME_VALID = 13222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_PAID_TIME_PBP_SERVER_ISSUE = 13223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VIOLATION_CONVENIENCE_TIME_EXCEEDED = 13224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VIOLATION_GRACE_PERIOD_EXPIRED = 13225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VIOLATION_PAID_TIME_INVALID = 13226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_INVENTORY_RESET = 13227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_MAX_INVENTORY_DURATION = 13228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_VEHICLE_EXITED = 13229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_VEHICLE_REENTERED = 13230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AXIS_DECODERS_MUST_USE_HTTPS = 13231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWPARKINGZONE = 13232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWPARKINGZONERULE = 13233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION_EVENT_FOR = 13234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_ENTERED_FROM = 13235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_EXITED_FROM = 13236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_MONITORING_COLOR = 13237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PAST_READ_MATCHING = 13238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PAST_READ_MATCHING_DAYS = 13239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORCE_DIGITAL_PTZ = 13240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_INVALID_URL = 13241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_PARKING_ZONE_ACTIVITIES = 13242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_PARKING_ZONE_SESSIONS = 13243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDFORMATCORPORATE1000_48BIT = 13244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SUPERVISORIN_ACCESS_RULE = 13245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_SUPERVISORIN_UNLOCKING_SCHEDULE = 13246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROOT_PASSWORD = 13247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESET_PARKING_ZONE_INVENTORY = 13248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKING_MANAGEMENT_SERVICE_OFFLINE = 13249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_FAILED_TIME_NOT_MONOTONIC_MP4 = 13250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_EXTERNALSYSTEM_NAME_SYNCHRONIZATION_PARTIALLY_COMPLETED = 13251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROPERTY = 13252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PBP_SYNC_INFORMATION = 13253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERROR_LOADING_PARKING_SESSION = 13254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_RESET_PARKING_ZONE_INVENTORY = 13255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTERFACEDISCOVERY_CONFIRMATION = 13256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_GRACE_PERIOD_ENDS = 13257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_CONVENIENCE_TIME_ENDS = 13258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_PAID_TIME_ENDS = 13259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_IMAGE_SOURCE = 13260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_NO_UNIT_CONFIGURED = 13261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPEN_CONFIGURATION_WEB_PAGE = 13262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVED = 13263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED_TO_SAVE = 13264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PRESETTOURS = 13265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZCOMMAND_STARTPRESETTOUR = 13266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PTZCOMMAND_STOPRESETTOUR = 13267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STARTPRESETTOUR = 13268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_STOPPRESETTOUR = 13269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_NOT_SET = 13270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELOG_FOR_CHANGES = 13271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PTZ_PRESETTOUR = 13272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_TASKBAR_STOPALLPRESETTOURS = 13273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALIDATE_MAP_PROVIDER = 13274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SERVER = 13275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_OCCUPANCY_LAST_UPDATED_AT = 13276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SENTENCE_AN_UPDATE_IS_AVAILABLE = 13277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SOFTWARE_UPDATE_AVAILABLE = 13278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SENTENCE_UPDATE_FOR_X_VERSION_Y_IS_AVAILABLE = 13279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SYSTEMMANAGEMENT_GROUP = 13280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWROLES = 13281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYROLES = 13282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDROLES = 13283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETEROLES = 13284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWUSERS = 13285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYUSERS = 13286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDUSERS = 13287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETEUSERS = 13288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWUSERGROUPS = 13289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYUSERGROUPS = 13290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDUSERGROUPS = 13291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETEUSERGROUPS = 13292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWSERVERS = 13293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYSERVERS = 13294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETESERVERS = 13295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWNETWORKS = 13296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYNETWORKS = 13297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDNETWORKS = 13298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETENETWORKS = 13299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWPARTITIONS = 13300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYPARTITIONS = 13301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADDPARTITIONS = 13302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETEPARTITIONS = 13303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESILIENTCONNECTION = 13304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECONNECTIONTIMEOUT = 13305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_FUSIONSTREAM = 13306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_FUSIONSTREAMS = 13307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_FUSIONSTREAM = 13308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFIC_RANGE = 13309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SET_TIME = 13310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_AGENT_STARTED = 13311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_AGENT_STOPPED = 13312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_AGENT_STOPPED_UNEXPECTEDLY = 13313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTORUNMODE = 13314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATIONTIME = 13315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SWINGMODE = 13316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ENABLEAUTORUN = 13317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_DISABLEAUTORUN = 13318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PARKING_SESSION_REPORT_SPECIFICTIME_INFO = 13319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PARKING_SESSION_REPORT_TIMERANGE_INFO = 13320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SWING = 13321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSIONS_SPECIFIC_TIME_AT = 13322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVERTED = 13323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEARING = 13324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DRILLDOWN_PARKING_SESSION_ON = 13325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKING_MANAGEMENT_LOADED = 13326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKING_MANAGEMENT_UNABLE_LOAD = 13327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PBP_PERMIT_MISSING = 13328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPGRADE_STATUS = 13329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEXT_UPGRADE = 13330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_PICTURE = 13331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UPGRADE_FIRMWARE = 13332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CODEC_CONFIGURATION = 13333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYMAPLAYERS = 13334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFYMAPLAYERS = 13335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MATCH_TOLERANCE_THRESHOLD = 13336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PBP_PERMIT_MISSING_DETAILS = 13337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DEPLOY_TO = 13338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_INTRUSION_UNIT_STAGING = 13339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PAYBYPLATE_PORT = 13340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PAYBYPLATE_SERVER = 13341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CANCEL_FIRMWARE_UPGRADES = 13342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZOOM_CONTENT = 13343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTED_FILES = 13344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTED_LAYERS = 13345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SORT_LAYERS = 13346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURE_SERVERS = 13347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_LAYOUTS = 13348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_INSUFFICIENT_PRIVILEGES = 13349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_SAVED = 13350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_SAVE_FAILED = 13351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_LOADED = 13352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_LOAD_FAILED = 13353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_DELETED = 13354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_DELETE_FAILED = 13355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_UPDATED = 13356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_UPDATE_FAILED = 13357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_LAYOUT = 13358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAYOUT_NAME = 13359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_RENAME_LAYOUT = 13360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PBP_SYNC_MATCHERNOTREADY = 13361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PBP_SYNC_PAYPROVIDERERROR = 13362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PBP_SYNC_PERMITNOTCONFIGURED = 13363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PBP_SYNC_PERMITNOTFOUND = 13364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PBP_SYNC_REQUESTTIMEOUT = 13365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PBP_SYNC_UNEXPECTEDERROR = 13366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PARKING_PBP_UNREACHABLE = 13367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PARKING_PBP_TIME_OUT = 13368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PARKING_PBP_UNEXPECTEDERROR = 13369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PBP_CONNECTION_ESTABLISHED = 13370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PBP_CONNECTION_UNREACHABLE = 13371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOPAN_SPEED = 13372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOPAN_TILTANGLE = 13373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CODEC_CONFIGURATION_DESCRIPTION = 13374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_COMPATIBLE_EXTENSIONS = 13375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSCH_ROTATION_MIRRORIMAGE = 13376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TIMERANGE_EXCEEDS_MINUTE_LIMIT = 13377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_RENAMED = 13378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_LAYOUT_RENAME_FAILED = 13379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_CANCEL_FIRMWARE_UPGRADES = 13380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARM_COLOR = 13381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FIRMWARE_UPGRADE_FILE = 13382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_TIMERANGE_END_BEFORE_START = 13383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STARTED_AT = 13384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPLETED_AT = 13385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_SESSION = 13386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VEHICLE_PARKING_SESSION = 13387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELAY_UPGRADE_UNTIL = 13388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIFFERENT_VERSIONS = 13389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNITS_SELECTED = 13390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEARABLE = 13391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT = 13392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_FIRMWARE = 13393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_FIRMWARE = 13394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_KIWI_MODULE = 13395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PLAN_MANAGER_ARCGIS = 13396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPTURE_FREQUENCY = 13397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_CROWD_DETECTED = 13398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_FACE_RECOGNIZED = 13399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_HEAT_MAP_CHANGED = 13400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_DETECTED = 13401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_COUNT_CHANGED = 13402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_DIRECTION_CHANGED = 13403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_IGNORED = 13404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_OBJECT_VELOCITY_CHANGED = 13405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DOWNLOAD = 13406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COMPLETING = 13407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PREPARING = 13408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_READYTODOWNLOAD = 13409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALIDATION_INVALID_FILENAME = 13410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_COMPLETED_PARKING_SESSION = 13411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_VIOLATION_PARKING_SESSION = 13412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_PARKING_OCCUPANCY = 13413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_PARKING_HIGH_RES_IMAGES = 13414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKING_EXPORT_ERROR = 13415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKING_EXPORT_ERROR_GENERIC_DETAILS = 13416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_BANDWIDTH_LIMIT_EXCEEDED = 13417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_ADVANCED_LICENSE_OPTIONS = 13418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_ADVANCED_LICENSE_OPTIONS = 13419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCGIS_LICENSE_BASIC = 13420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCGIS_LICENSE_STANDARD = 13421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSE_PARAMETERS = 13422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSE_CODE = 13423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLIENT_ID = 13424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM = 13425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_CAMERA_RELATIVE_MOTION = 13426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_MOTION_APPROACH_AS_ENTER = 13427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_MOTION_APPROACH_AS_EXIT = 13428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_VALUE_CHANGE_SIMPLE = 13429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_FAILED = 13430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_PARTIALLY_FAILED = 13431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_SUCCESS = 13432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_PARTIALLY_FAILED_FOR_ONE_CAMERA = 13433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_SUCCESS_FOR_ONE_CAMERA = 13434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_NOT_ENOUGH_SPACE = 13435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_NO_PATH_AVAILABLE = 13436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVE_CONSOLIDATION_MANUAL_START = 13437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ARCHIVE_CONSOLIDATION_MANUAL_STOP = 13438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVE_CONSOLIDATION_MANUAL_START = 13439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_ARCHIVE_CONSOLIDATION_MANUAL_STOP = 13440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNUSED_CARDHOLDERS = 13441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNUSED_CREDENTIALS = 13442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_CONSOLIDATION_SUCCESS = 13443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_CONSOLIDATION_FAILED = 13444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ARCHIVE_CONSOLIDATION_PARTIALLY_FAILED = 13445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNUSED_CARDHOLDERS_FILTER_IS_UNAVAILABLE = 13446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNUSED_CREDENTIALS_FILTER_IS_UNAVAILABLE = 13447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_MOTION_APPROACHING = 13448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_MOTION_MOVING_AWAY = 13449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_RELATIVE_MOTION_DESCRIPTION_TITLE = 13450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_DIRECTION = 13451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_DIRECTION_APPROACHING = 13452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_DIRECTION_MOVING_AWAY = 13453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOSE_SESSION = 13454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_CLOSE_SESSION = 13455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_CLOSE_SESSION = 13456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ENFORCE_IN_LOT_VIOLATIONS = 13457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEWARPER_LIMITVIEWAREA = 13458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_RESILIENCY_NOTSUPPORTED = 13459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_RESILIENCY_RECOVERINGCONNECTION = 13460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECURE_MODE_NOT_SUPPORTED = 13461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IGNORED = 13462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SMART_CARD_ENCODING = 13463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CANCEL_FIRMWARE_UPGRADE = 13464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_RECORDING_STARTED_MANUAL_P = 13465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_RECORDING_STOPPED_MANUAL_P = 13466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_RECORDING_MANUAL_START = 13467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_RECORDING_MANUAL_STOP = 13468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKING_EXPORT_FOLDER_INITIALIZATION_ERROR_DETAILS = 13469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_TOO_MANY_INVALID_LOGIN_ATTEMPTS = 13470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NO_MAPS_FOUND = 13471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_GROUP = 13472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_CUSTOM_FIELD = 13473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_EVENTS = 13474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_ACTIONS = 13475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_LOGICAL_ID = 13476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_PASSWORD_SETTINGS = 13477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_ACTIVITY_TRAIL = 13478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_GENERAL_SETTINGS_ACTIVITY_TRAIL = 13479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_AUDIO = 13480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_INCIDENT_CATEGORIES = 13481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_FEATURES = 13482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LANE_DIRECTION = 13483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_ENTRY_IGNORE_MOTION = 13484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_ENTRY_APPROACHING_MOTION = 13485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_ENTRY_MOVING_AWAY_MOTION = 13486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_ENTRY_EXIT_APPROACH_TO_ENTER = 13487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_EXIT_IGNORE_MOTION = 13488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_EXIT_APPROACHING_MOTION = 13489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_EXIT_MOVING_AWAY_MOTION = 13490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_ENTRY_EXIT_APPROACH_TO_EXIT = 13491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_SELECT_DETAILS = 13492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PARKING_CONFIG_RELATIVE_MOTION_INTRO = 13493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLATE_READER = 13494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_FOLDER_ITEM = 13495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MOVE_SELECTED_ITEM = 13496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_EXPORT_DISABLED_INVALID_SETTINGS = 13497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_EXPORT_UNAVAILABLE = 13498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CERTIFICATE = 13499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_PROCEED_FIRST_LOGON_UNSAFE = 13500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CANCEL_LOGON = 13501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_IDENTITY_SERVER_NOT_VERIFIED_FIRST_LOGON = 13502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DESTINATION_SUBFOLDER_OF_SOURCE = 13503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGES_SAVED_TO = 13504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTROLLERS_FIRMWARE_UPGRADE = 13505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCODE_BEFORE_ENROLLMENT = 13506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STID_USB_READER = 13507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTION_SENT = 13508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MISSING_MANDATORY_FIELDS = 13509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PLATELIST_MISSING_MANDATORY_FIELD = 13510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PLATELIST_CONFLICTING_ROWS = 13511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_MOTION_MOVEAWAY_AS_ENTER = 13512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_MOTION_MOVEAWAY_AS_EXIT = 13513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SESSION_STATE_REASON = 13514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_RESTRICTED_CAMERAS = 13515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECT_TO = 13516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LICENSEPLATE_CREDENTIALEXACTMATCH = 13517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOCOMPRESSION_SMART_CODEC = 13518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_GOT_SERIALNUMBER = 13519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_GOT_VERSION = 13520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_GOT_DISCOVERYPORT = 13521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_TESTED_DISCOVERYPORT = 13522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_GOT_NETWORKSETTINGS = 13523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_PROCESSED_HARDWAREMODEL = 13524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_INITIALIZED_HARDWARE = 13525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_IP_RESOLVING = 13526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_IP_RESOLVED = 13527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_SOFTWIRE_IP_UNRESOLVED = 13528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TIMERANGE_EXCEEDS_HOUR_LIMIT = 13529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURATION_OF_THE_EXPORT_CANNOT_EXCEED_X_HOURS = 13530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_DURATION = 13531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_UNKNOWN_VEHICLE = 13532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPORT_TOOMANYCONCURRENTREQUESTS = 13533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPORT_REQUESTTHROTTLED = 13534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_ZONE_DEVICE_BROWSER_INFO = 13535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAPACITY_THRESHOLD = 13536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPGRADE_FAILED_REASON = 13537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_TRANSFER_FILE_FAILED = 13538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_EXTRACT_FAILED = 13539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_INSTALL_FAILED = 13540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_PATH = 13541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_UNABLE_START = 13542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_UNIT_MOVED = 13543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_WRONG_FILE = 13544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_DOWNGRADE = 13545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARKING_CAPACITY_THRESHOLD_EVENT_DESCRIPTION = 13546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DRILLDOWN_ACTIVE_PARKING_SESSIONS = 13547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DRILLDOWN_VIOLATION_PARKING_SESSIONS = 13548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CERTIFICATE_REVOKED = 13549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CERTIFICATE_EXPIRED = 13550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CERTIFICATE_UNTRUSTED_ROOT = 13551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_CERTIFICATE_DNS_MISMATCH = 13552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_FAILED_UPDATEDS_ENTITY_TYPE_CANNOT_BE_SHARED = 13553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_FAILED_UPDATEDS_CANNOT_MODIFY_OFFLINE_SHARED_ENTITY = 13554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_MULTIPLE_PARKING_VIOLATIONS_CONFIRMATION = 13555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_CANNOT_BE_UNDONE = 13556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADMIN_PASSWORD_REQUIRED = 13557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ROOT_PASSWORD_REQUIRED = 13558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADMIN_PASSWORD_REQUIRED_SECURE = 13559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ROOT_PASSWORD_REQUIRED_SECURE = 13560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADMIN_PASSWORD_REQUIRED_UPGRADE = 13561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PIN_ON_MAP = 13562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PIN_ON_SCREEN = 13563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ARCHIVER_IS_OFFLINE = 13564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_OBJECT_MODIFIED = 13565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_ARCHIVER_SELECTED = 13566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADMIN_PASSWORD_REQUIRED = 13567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_INFORMATION = 13568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION_FAILED_OFFLINE_SHARED_PARTITION_CONTENT_MODIFIED = 13569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ENABLE_LAYER = 13570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_MANUAL_ENROLLMENT_REFUSED = 13571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ADD_UNIT_STATUS_ENROLLMENT_REFUSED = 13572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NOT_LOADED_ESRI_MAP_ENGINE = 13573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CAMERA_RESTRICTED = 13574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_CAMERA = 13575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ARCHIVER_IS_UNSELECTED = 13576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ARCHIVER_IS_UNSELECTED_DETAILS = 13577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_VERIFY_ARCHIVER_STATE = 13578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WEBCLIENT_LOGIN_FORBIDDEN = 13579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_LAYOUT = 13580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTRA = 13581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DRILLDOWN_PARKING_ACTIVITIES_ON = 13582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ACCESS_CONTROL_UNIT_FIRMWARE_UPGRADE_SCHEDULED = 13583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ACCESS_CONTROL_UNIT_FIRMWARE_UPGRADE_CANCELED = 13584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIOLATION_REASON = 13585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USEDEFAULTWEBPORT = 13586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FAILED_QUERY_PARKING_READS = 13587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FAILED_QUERY_PARKING_DATA = 13588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FIRMWARE_UPGRADE_STATUS = 13589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CHANGED = 13590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CHANGED_SUBTEXT = 13591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_CHANGE_ARCHIVER = 13592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_YES_CHANGE_ARCHIVER = 13593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_NUMBER_OF_DATA_POINTS = 13594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_OPERATOR_CONNECTIONS = 13595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_INCIDENT_TYPES = 13596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_DYNAMIC_DOCUMENTS = 13597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_INCIDENT_EXPORT = 13598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_RULES_ENGINE = 13599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_NUMBER_OF_WORKFLOWS = 13600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_INCIDENT_LINKING = 13601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_OPERATING_PROCEDURES = 13602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_NUMBER_OF_FORMS = 13603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_CASE_MANAGEMENT = 13604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_DYNAMIC_SOPS = 13605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_ESCALATION_PROCESS = 13606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_DYNAMIC_DOCUMENT_MANAGEMENT = 13607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_NUMBER_OF_SUPERVISORS = 13608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_NUMBER_OF_DASHBOARDS = 13609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_NUMBER_OF_MOBILE_USERS = 13610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROWSER_NOT_SUPPORTED = 13611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_DASHBOARD = 13612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_ADVANCED_REPORTING = 13613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CREATE_NEW_FOLDER = 13614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_PARKING_RULE = 13615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SELECTED_ITEMS = 13616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ARCHIVER_IN_WARNING = 13617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_SNAPSHOT = 13618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ARCHIVER_NOT_CONNECTED = 13619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GO_TO_UNIT = 13620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FEDERATED_ESRI_MAP_NOT_SUPPORTED = 13621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_BROWSE_REMOTE_DATABASE_SERVER = 13622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WISE_STREAM = 13623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_WEBCLIENTROLE = 13624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INITIAL_CONFIGURATION_WARNING = 13625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TASK_CANT_BE_DISPLAYED = 13626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PREPARE_DOWNLOAD = 13627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PREPARING_DOWNLOAD = 13628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DOWNLOAD_VIDEO = 13629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_CSV = 13630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ITEMS_COUNT_MAXIMUM = 13631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_DURATION_CANNOT_EXCEED_X_HOURS = 13632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXPORTING_VIDEO_FILE = 13633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_WEBCLIENT_APPLICATIONPATH_ERROR = 13634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_REPORTGENERATOR_EXCEPTIONREPORT = 13635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PARKING_PBP_CONFIG = 13636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PARKING_EXPORT_FOLDER_CONFIG = 13637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWAREUPGRADE_CONFIRMATION = 13638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CLOSE_CONFIRMATION = 13639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_SPLASHSCREEN = 13640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_MONITORING = 13641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_BOOKMARKS = 13642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_DOORACTIVITY = 13643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_ALARMREPORT = 13644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_CARDHOLDERS = 13645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_VISITORS = 13646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_CARDHOLDERGROUPS = 13647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_CREDENTIALS = 13648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_SUBTYPE_WEARABLE_CAMERA = 13649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MORE_FILTERS = 13650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOWNLOAD_VIDEO = 13651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_NOTIFICATIONS = 13652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_WILL_BE_AUTOMATICALLY_GENERATED = 13653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_UPGRADE_IN_PROGRESS = 13654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CAPTURE_INPROGRESS = 13655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_READY_TO_BE_ENCODED = 13656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_NOT_READY_TO_BE_ENCODED = 13657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FIRMWARE_UPRADE_INTERFACES = 13658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FIRMWARE_UPGRADE_ACCESS_DISRUPTION = 13659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_PARKING_OPERATOR_INITIALIZATION_ERROR = 13660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_PARKING_RULE_ADDITIONS = 13661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_PARKING_RULE_REMOVALS = 13662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_STOP_SLOW_MOTION = 13663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SESSION_TIMED_OUT = 13664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SESSION_EXPIRING = 13665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SOUND_FILE_NOT_FOUND = 13666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_EDIT_FAILED = 13667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETE_MAP_ITEMS_TO_CLEAR_GEOREF = 13668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDIOBITRATE_80KBPS = 13669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_UNLOCK_DOOR_EXPLICITLY = 13670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_DOOR_EXPLICITLY_UNLOCKED = 13671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_RESOLUTION_WARNING = 13672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_DEFAULT_PARKING_RULE = 13673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_G64_FORMAT_LONG_NAME = 13674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INITIALIZE_CONFIGURATION = 13675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIGURATION_INITIALIZED = 13676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_INITIALIZE_CONFIGURATION = 13677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_BIND_CERTIFICATE = 13678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CERTIFICATE_BOUND = 13679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_BIND_CERTIFICATE = 13680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DETAILS_UNABLE_TO_BIND_CERTIFICATE_TO_PORT = 13681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_START_HOST = 13682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HOST_STARTED = 13683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_START_HOST = 13684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DETAILS_HOST_CONFIGURATION = 13685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_START_HOST_HTTPS_DISABLED = 13686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_START_HOST_HTTPS_DISABLED = 13687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_START_HOST_HTTPS_DISABLED = 13688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_START_WEBCLIENT = 13689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WEBCLIENT_STARTED = 13690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WEBCLIENT_STARTED_WITH_ERRORS = 13691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_START_WEBCLIENT = 13692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_RETREIVE_VIDEO_EXPORT_FOLDER = 13693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_CREATE_VIDEO_EXPORT_FOLDER = 13694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_START = 13695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNSUPPORTED_CAMERA_TYPE = 13696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_MUSTCHANGEPASSWORD = 13697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_AUX_ARCHIVE_RECORDING = 13698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PORT_ALREADY_IN_USE = 13699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHENTICATION_UNKNOWN = 13700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REPLACE_LAYOUT = 13701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_EXISTING_SESSION_OPENED = 13702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_EXPORT_DETAILS = 13703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_EXPORT_DETAILS = 13704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COOKIES_MUST_BE_ENABLED = 13705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_ANTIPASSBACK_NOTSUPPORTEDBY_ELEVATOR = 13706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_INTERLOCK_NOTSUPPORTEDBY_ELEVATOR = 13707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_DETAILS_PEOPLECOUNT_NOTSUPPORTEDBY_ELEVATOR = 13708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_DETAILS = 13709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_IMAGE_EXPORT_TIMEOUT = 13710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AUTHENTICATION_COOKIE_NOT_SET = 13711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNIT_CLOCK_NOT_SYNCED = 13712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_SSLDISABLED = 13713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EVENT_TO_LOCATION_ON_DATE = 13714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UPGRADE_BROWSER = 13715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VALIDATION_GLOBAL_CREDENTIAL = 13716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_BELOW_RECOMMENDED = 13717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_STREAM_ROUTE = 13718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_STREAM_ROUTE = 13719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WEBCLIENT_STREAM_LIMIT_REACHED = 13720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILEPLUGIN_SECURITY_DEMAND_FORMAT = 13721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILEPLUGIN_DO_NOT_ASK_AGAIN = 13722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY_WARNING = 13723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXECUTE = 13724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DO_NOT_EXECUTE = 13725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DOWNLOAD_CREATING_VIDEO = 13726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FILE_TOO_LARGE_MAX_X_KB = 13727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_TILELAYOUT = 13728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_TILELAYOUT = 13729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SAVE_AS_TILELAYOUT = 13730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_TILELAYOUTS = 13731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_TILELAYOUTS = 13732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_TILELAYOUTS = 13733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_TILELAYOUTS = 13734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_TILELAYOUTS = 13735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_TILELAYOUTS = 13736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_TILELAYOUTS = 13737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_TILELAYOUTS = 13738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARE = 13739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_OVERWRITE_TILELAYOUT = 13740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_STREAM_STATISTICS = 13741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_STREAM_STATISTICS = 13742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_EDIT_READ = 13743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_UNITCONTROLSTARTING = 13744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_UNITCONTROLSTARTFAILED_FORMAT = 13745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_UNITCONTROLSTARTFAILED = 13746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANNOT_OVERWRITE_TILELAYOUT_PARTITION = 13747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_NODATACOLLECTION_TOOLTIP = 13748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_ANONYMOUSDATACOLLECTION_TOOLTIP = 13749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_YESDATACOLLECTION_TOOLTIP = 13750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DISK_USAGE_REACHED_CONFIGURED_MAX = 13751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DATABASE_USAGE_REACHED_CONFIGURED_MAX = 13752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CERTIFICATEERROR_AUTOUPGRADE_DIRECTORY = 13753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CERTIFICATEERROR_AUTOUPGRADE_EXPANSION = 13754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DECODER_PERFORMANCE_LOW = 13755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_ENABLE_ARCHIVE_CONSOLIDATION = 13756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WIPER_HIGH = 13757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WIPER_LOW = 13758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WIPER_ONESHOT = 13759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WIPER_WASHER = 13760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_WIPER_OFF = 13761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_PANASONIC_BLACKWHITE_ON = 13762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_PANASONIC_BLACKWHITE_OFF = 13763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_PANASONIC_BLACKWHITE_AUTO1 = 13764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_PANASONIC_BLACKWHITE_AUTO2 = 13765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENCODER_COMMAND_PANASONIC_BLACKWHITE_AUTO3 = 13766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_ARCHIVE_CONSOLIDATION_GROUP = 13767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SERVER__X__VERSION__Y__IS_TOO_OLD = 13768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHAPES = 13769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_RETRIEVE_TILE_IMAGES = 13770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GO_TO_SPECIFIC_DATE_TIME = 13771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_DURATION_CANNOT_BE_UNDER_X_SECONDS = 13772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOREF_CHANGE_BG = 13773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPLACE_ITEMS_CHANGE_BG = 13774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SENSORIMAGESIZE = 13775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_CROPSENSOR = 13776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WS_DISCOVERY = 13777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFO_HOSTNAME_CHANGE_NOT_HANDLED = 13778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_SETTINGS_AUTOMATICALLY_CONFIGURED = 13779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DELETE_TILELAYOUT = 13780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OVERWRITE_TILELAYOUT = 13781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PASSWORD_REQUIREMENTS_NOT_MET = 13782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CANNOT_MOVE_PATROLLER = 13783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_NEWSEQUENCE = 13784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_ARCHIVER_SETTINGS_NOT_MODIFIABLE = 13785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_SETTINGS = 13786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CONVERT_EXPORTED_FILES = 13787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CONVERT_EXPORTED_FILES = 13788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EDIT_ALARM_CONTEXT = 13789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_EDIT_ALARM_CONTEXT = 13790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ALARM_CONTEXT_EDITED = 13791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_EDIT_ALARM_CONTEXT = 13792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_ALARM_CONTEXT = 13793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TEXT_FILE_DATE_ORDER = 13794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_DATES = 13795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORIGINAL_ENTITY_NAME = 13796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNC_ENTITY_NAME_WITH_ORIGINAL_NAME = 13797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENTUSERSEQUENCES = 13798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MESSAGE_RESTORE_ENTITY_ORIGINAL_NAME = 13799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SAVEASUSERSEQUENCE = 13800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RECORDING_PROBLEM = 13801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_LASTACCESSTIME = 13802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MEDIAPROCESSOR_NAME = 13803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MEDIAPROCESSOR_DESCRIPTION = 13804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_UNABLE_TO_OPEN_WEB_BROWSER = 13805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_REDUCING_DATA_RETENTION_PERIOD = 13806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION = 13807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ALARM_CONTEXT_CHANGED = 13808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ALARM_CONTEXT_CHANGED_OVERWRITE = 13809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ACTIVITY_ORIGINAL_INITIATOR_ENTITY_NAME = 13810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_FROM_AREA = 13811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PIE_CHART = 13812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOUGHNUT_CHART = 13813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLUMNS_CHART = 13814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STACKED_COLUMNS_CHART = 13815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROWS_CHART = 13816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STACKED_ROWS_CHART = 13817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LINES_CHART = 13818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHART_X_AXIS = 13819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHART_SHOW = 13820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPLIT_BY = 13821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHART_TOTAL = 13822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHARTS = 13823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOP_X = 13824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHART_TOO_MUCH_DATA = 13825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_BY_HOUR = 13826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_BY_WEEK = 13827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_BY_MONTH = 13828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_BY_YEAR = 13829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_CHARTS = 13830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINT_DATA = 13831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINT_GRAPH = 13832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHAT_TO_EXPORT = 13833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHART_OTHERS = 13834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GRAPH_EXPORTED_TO_LOCATION = 13835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_CHECK_PAID_TIME_READ_EDITED = 13836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DEPLOY_SELECTED_TO = 13837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_DEPLOY_ALL_TO = 13838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNIT_CONFIGS = 13839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_STAGING_CONFIG = 13840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DEPLOY_STAGING_CONFIG_CONFIRM = 13841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_REFRESH = 13842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTICAST_PORT = 13843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_PATH = 13844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEST_FOV = 13845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_TEST = 13846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_READ_EDITED = 13847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_RULE_DELETED = 13848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_DELETED = 13849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMPTY = 13850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ROLE_TO_ENABLE_FEATURE = 13851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_REMOVE_PRIVACY_PROTECTION = 13852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REMOVE_PRIVACY_PROTECTION = 13853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_OPACITY_MODIFIED = 13854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_LAYER_PROPERTY_MODIFIED = 13855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEDIAPROCESSOR_REQUIRED = 13856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHART_Y_AXIS = 13857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SPECIFIC_DATE = 13858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DELETING = 13859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_CARDHOLDER_REMOVED_FROM_AREA = 13860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_REVERSETUNNEL_NAME = 13861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_REVERSETUNNEL_DESCRIPTION = 13862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCGIS_SDK_NOT_INSTALLED = 13863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_PEOPLE_COUNT = 13864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_PEOPLE_COUNT = 13865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNCHRONIZATION_REFRESHING_GLOBAL_PARTITIONS = 13866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CHANGE_ALARM_STATE = 13867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CREDENTIAL_NO_LONGER_FITS_FORMAT = 13868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_RECORDING_PROBLEM = 13869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_RECORDING_PROBLEM_RECOVERED = 13870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_GOTOBACKUP = 13871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_FAILEDAUTOBACKUP = 13872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_WHATLIKEDO = 13873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_RESTART_ANYWAY = 13874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_CUSTOM_ENTITY_SINGLE_INSTANCE = 13875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_THREAT_LEVEL = 13876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_TEST_FOV = 13877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STOP_TEST_FOV = 13878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_RECORDING_ON = 13879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_RECORDING_OFF = 13880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EXTEND_MAINTENANCE_MODE = 13881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CHARTS = 13882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_REMOVE_PRIVACY_PROTECTION = 13883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_RECORDING_PROBLEM_RESOLVED = 13884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARED_PERMIT_MATCH = 13885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_VIOLATION_SHARED_PERMIT_MATCH = 13886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SHARED_PERMIT_MATCH = 13887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_MACROS = 13888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZIPSTREAM_SETTINGS = 13889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORPHAN_FILE_INVALID = 13890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORPHAN_FILES_INVALID = 13891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_OPTIONAL = 13892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_RECOMMENDED = 13893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_CRITICAL = 13894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECOMMENDED_FIRMWARE_VERSION = 13895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNITS_WITHOUT_RECOMMENDED_FIRMWARE = 13896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILTER_RECOMMENDED_FIRMWARE = 13897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_UPDATES = 13898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIVE_STREAMING_UDP_PORT_RANGE = 13899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CREDENTIAL_PROVIDERS = 13900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CREDENTIAL_PROVIDER_EDITOR = 13901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMER_ID = 13902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PRIVACY_PROTECTION = 13903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_PRIVACY_PROTECTION_STREAMS = 13904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVER = 13905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LIVE_STREAMING_PORT_RANGE = 13906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TO = 13907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MOBILE_CREDENTIAL_PROVIDER_CREATED = 13908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MOBILE_CREDENTIAL_PROVIDER_UPDATED = 13909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MOBILE_CREDENTIAL_PROVIDER_DELETED = 13910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MOBILE_CREDENTIAL_PROVIDERS = 13911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOAST_TRIGGER_ALARMS_FAILED = 13912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PHYSICAL = 13913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTION_LEGACY = 13914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTION_TEST = 13915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OBJECT = 13916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_FOV_COVERAGE = 13917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_CONNECTIONCONFIGURATION = 13918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_CONNECTIONSCHEME = 13919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_CONNECTIONVERSION = 13920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FORWARD_ALARM = 13921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_FORCE_ACK_ALARMS = 13922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_NO_EMAIL_SERVER_CONFIGURED = 13923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARM_ZONE_WITH_ACTIVE_TROUBLE_INPUT = 13924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_MOBILE_CREDENTIAL = 13925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARM_INTRUSION_AREA_WITH_ACTIVE_TROUBLE_INPUT = 13926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIEW_PRESET_ADDED = 13927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIEW_PRESET_DELETED = 13928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIEW_PRESET_MODIFIED = 13929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PRESET_ALREADY_ADDED = 13930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SELECT_PRESET = 13931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ACTIVE_ALARMS = 13932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_PARTNUMBER = 13933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_NOCRED = 13934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BATTERY_SENSOR = 13935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTNUMBER = 13936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CAMERAS = 13937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CAMERAS = 13938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROPOSED_FIRMWARE = 13939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECOMMENDED_FIRMWARE_STATUS = 13940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_LAST_CHECK = 13941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CRITICAL_UPDATE_AVAILABLE = 13942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CRITICAL_UPDATE_AVAILABLE = 13943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_OPEN_HARDWARE_INVENTORY = 13944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_MOBILE_INVITE = 13945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRE_REASON = 13946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRE_REASON_LPR_REPORT = 13947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_REASON_REQUIRED = 13948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CREDENTIAL = 13949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DIRECTORY_WHERE_TO_CONNECT = 13950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TRUSTED_DIRECTORY = 13951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNTRUSTED_DIRECTORY = 13952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NOT_RESPONDING = 13953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INCOMPATIBLE_VERSION = 13954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_USER_OR_PASSWORD = 13955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OTHER_LOGON_METHOD = 13956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURED = 13957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CARDHOLDER_SAVE = 13958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD = 13959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_FILES = 13960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_EMAIL = 13961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EMAIL_DO_NOT_REPLY = 13962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_SYNC_COMPLETED = 13963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_SUBTYPE_WEARABLECAMERA_HOLDER = 13964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ACCESS_CONTROL_UNIT_DOWNSTREAM_FIRMWARE_UPGRADE_SCHEDULED = 13965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GCS_SYNC_NOT_RUNNING = 13966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GCS_SYNC_NOT_RUNNING_DIR_FAILED = 13967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SYNC_OFFLINE = 13968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CANNOT_CONNECT_HOST = 13969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_RETRIEVING_DATA = 13970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SYNC_DISABLED = 13971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_STOPPING = 13972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_RECONNECTION_PENDING = 13973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_NO_SCHEDULED_TASK = 13974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_NO_SCHEDULED_TASK_TO_START_SYNC = 13975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_CREATION_CANT_SYNC = 13976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_CREATION_CANT_APPLY_TO_SITE = 13977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_DELETION_CANT_SYNC = 13978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_DELETION_CANT_APPLY_TO_SITE = 13979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_MODIFICATION_CANT_SYNC = 13980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_MODIFICATION_CANT_APPLY_TO_SITE = 13981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CHANGES_CANT_SYNC_HOST_TO_SITE = 13982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CREATIONS_DELETIONS_MODIFICATIONS_CANT_APPLY_TO_SITE = 13983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_CREATION_CANT_SYNC = 13984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_CREATION_CANT_APPLY_TO_HOST = 13985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_DELETION_CANT_SYNC = 13986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_DELETION_CANT_APPLY_TO_HOST = 13987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_MODIFICATION_CANT_SYNC = 13988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_MODIFICATION_CANT_APPLY_TO_HOST = 13989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CHANGES_CANT_SYNC_SITE_TO_HOST = 13990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CREATIONS_DELETIONS_MODIFICATIONS_CANT_APPLY_TO_HOST = 13991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_CREATION_CANT_APPLY_TO_SITE_LICENSE_LIMIT = 13992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CREATIONS_DELETIONS_MODIFICATIONS_CANT_APPLY_TO_SITE_LICENSE_LIMIT = 13993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_CREATION_CANT_APPLY_TO_HOST_LICENSE_LIMIT = 13994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CREATIONS_DELETIONS_MODIFICATIONS_CANT_APPLY_TO_HOST_LICENSE_LIMIT = 13995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_DELETION_REVERTED = 13996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_DELETION_REVERTED_DETAILED = 13997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CREATION_REVERTED = 13998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CREATION_REVERTED_DETAILED = 13999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_MODIFICATION_REVERTED = 14000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_MODIFICATION_REVERTED_DETAILED = 14001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CREATIONS_DELETIONS_MODIFICATIONS_REVERTED = 14002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_CHANGES_REVERTED = 14003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_CREATION_CANT_APPLY_TO_SITE_UNAVAILABLE_DEPENDENCIES = 14004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_CREATION_CANT_APPLY_TO_SITE_UNAVAILABLE_DEPENDENCIES_DETAILED = 14005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_DELETION_CANT_APPLY_TO_SITE_UNAVAILABLE_DEPENDENCIES = 14006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_DELETION_CANT_APPLY_TO_SITE_UNAVAILABLE_DEPENDENCIES_DETAILED = 14007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_MODIFICATION_CANT_APPLY_TO_SITE_UNAVAILABLE_DEPENDENCIES = 14008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_HOST_MODIFICATION_CANT_APPLY_TO_SITE_UNAVAILABLE_DEPENDENCIES_DETAILED = 14009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CANT_SYNC_TO_SITE_UNAVAILABLE_DEPENDENCIES = 14010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CANT_SYNC_TO_SITE_UNAVAILABLE_DEPENDENCIES_DETAILED = 14011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_CREATION_CANT_APPLY_TO_HOST_UNAVAILABLE_DEPENDENCIES = 14012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_CREATION_CANT_APPLY_TO_HOST_UNAVAILABLE_DEPENDENCIES_DETAILED = 14013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_DELETION_CANT_APPLY_TO_HOST_UNAVAILABLE_DEPENDENCIES = 14014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_DELETION_CANT_APPLY_TO_HOST_UNAVAILABLE_DEPENDENCIES_DETAILED = 14015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_MODIFICATION_CANT_APPLY_TO_HOST_UNAVAILABLE_DEPENDENCIES = 14016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SITE_MODIFICATION_CANT_APPLY_TO_HOST_UNAVAILABLE_DEPENDENCIES_DETAILED = 14017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CANT_SYNC_TO_HOST_UNAVAILABLE_DEPENDENCIES = 14018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CANT_SYNC_TO_HOST_UNAVAILABLE_DEPENDENCIES_DETAILED = 14019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_DUPLICATE_CREDENTIAL = 14020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_SINGLE_DUPLICATE_CREDENTIAL = 14021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_MULTIPLE_DUPLICATE_CREDENTIAL = 14022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CANT_SYNC_SYSTEM_CONFIG = 14023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CUSTOMFIELD_LIMIT_REACHED = 14024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_MOBILE_CREDENTIAL = 14025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MOBILE_CREDENTIAL_TRANSFER = 14026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_CUSTOMFIELD_MISSING_PRIVILEGE = 14027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_INTERACTIVELOGONREQUIRED = 14028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_PROVIDER_CREDENTIAL_ERROR = 14029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MOBILE_CANCEL = 14030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEWNETWORKS = 14031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFYNETWORKS = 14032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEWPARTITIONS = 14033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFYPARTITIONS = 14034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ADDPARTITIONS = 14035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETEPARTITIONS = 14036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEWROLES = 14037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFYROLES = 14038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ADDROLES = 14039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETEROLES = 14040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEWSERVERS = 14041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFYSERVERS = 14042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETESERVERS = 14043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEWUSERGROUPS = 14044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFYUSERGROUPS = 14045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ADDUSERGROUPS = 14046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETEUSERGROUPS = 14047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEWUSERS = 14048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFYUSERS = 14049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_ADDUSERS = 14050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETEUSERS = 14051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_GROUP = 14052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_CUSTOM_FIELD = 14053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_EVENTS = 14054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_ACTIONS = 14055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_LOGICAL_ID = 14056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_PASSWORD_SETTINGS = 14057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_AUDIO = 14058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_INCIDENT_CATEGORIES = 14059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_FEATURES = 14060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEW_MACROS = 14061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_SYSTEMCONFIG_ACTIVITYLOG_ACTIVATED = 14062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_SYSTEMCONFIG_ACTIVITYLOG_DEACTIVATED = 14063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_SCALE = 14064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_START_OVER = 14065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_START_INVESTIGATION = 14066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SEEK_VIDEO = 14067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ROI = 14068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISUALLY_INSPECT = 14069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAST_FINDER = 14070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSPECTING_INTERVAL = 14071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSPECT_SPECIFIC = 14072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSPECT_PREVIOUS = 14073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INSPECT_NEXT = 14074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_MILLISECONDS = 14075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_SECONDS = 14076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_MINUTES = 14077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_HOURS = 14078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_1DAY = 14079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_DAYS = 14080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENTNAME = 14081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOMEVENT_MODIFIED = 14082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_SERVERADMIN_BACKWARDAPPS = 14083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAYER_SETTINGS = 14084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_EMAIL_VALIDATION_ERROR = 14085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESET_TRUSTED_CERTIFICATE = 14086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_ACCESS_CONTROL_DOWNSTREAM_FIRMWARE_UPGRADE_SCHEDULED = 14087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_AREA_PERIMETER_DOOR_ADD = 14088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_AREA_CAPTIVE_DOOR_ADD = 14089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_DOOR_REMOVE_DEVICE_UNASSIGNED = 14090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_DOOR_REMOVE_DEVICE = 14091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_IMPORTTOOL_UNABLETOACCESSSFILE_SCHEDULEDTASK = 14092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EXTERNALSYSTEM_COMMUNICATIONS_NAME = 14093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CHARTS = 14094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SYSTEMMANAGEMENT_GROUP = 14095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACTIONS_INTRUSIONDETECTION_GROUP = 14096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_AUTOVU_PARKING_MANAGEMENT = 14097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADMIN_ALARMMANAGEMENTTASKS_GROUP = 14098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MISSING_DEPENDENCY = 14099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MOBILECREDENTIAL_REQUIREMENTS_NOTMET = 14100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_PERSON_REMOVED = 14101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_VIDEO_CONFIDENTIAL_VIDEO_REQUESTED = 14102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_ENABLE_ACCESS_CONTROL_UNIT_SUPPORT_LOGS = 14103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_DISABLE_ACCESS_CONTROL_UNIT_SUPPORT_LOGS = 14104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_EVENT_COLOR_MODIFIED = 14105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_IGNORE = 14106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEWINSYSTEMSTATUS = 14107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANYTIME = 14108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_AVAILABLE = 14109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GRAPH = 14110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXOCCUPENCYMODE = 14111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXOCCUPENCYLIMIT = 14112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXOCCUPANCYLIMIT = 14113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXOCCUPANCYMODE = 14114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXOCCUPANCYSOFT = 14115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXOCCUPANCYHARD = 14116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXOCCUPANCYSTRICT = 14117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURESS = 14118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_MAXOCCUPANCY = 14119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_MAXOCCUPANCY = 14120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_MAXOCCUPANCY_EXCEEDED = 14121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LEGACY_EXPORT_ENCRYPTION_ERROR = 14122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_MAXOCCUPANCYREACHED = 14123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_MAXOCCUPANCYEXCEEDED = 14124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_BELOWMAXOCCUPANCY = 14125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_MAXOCCUPANCYREACHED = 14126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURESS_ISUNDERDURESS = 14127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_RESET_SYSTEM_CLEARANCE = 14128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DURESSPINENTERED = 14129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_OVERRIDE_ELEVATOR_SCHEDULE = 14130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_TYPE = 14131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_DATA = 14132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_VISITOR_ASTRAY = 14133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_DATABASE_ERRORS = 14134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_ENTITY = 14135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_MISSING_TAIL_ESCORT = 14136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_CAPACITY_VALUE = 14137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_VIRTUALINPUT = 14138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOMFIELDTYPE_VALUE_ADDED = 14139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOMFIELDTYPE_VALUE_REMOVED = 14140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOMFIELDTYPE_VALUE_MODIFIED = 14141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOMFIELDTYPE_ADDED = 14142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_CUSTOMFIELDTYPE_REMOVED = 14143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SDCARD = 14144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIZEINBYTES = 14145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_SYSTEM = 14146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STORAGE_LOCK = 14147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STORAGE_MOUNT_ON_BOOT = 14148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DELETE_RECORDINGS_OLDER_THAN = 14149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEANUP_LEVEL = 14150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECALIBRATE = 14151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CALIBRATE_FOV_INSTRUCTIONS = 14152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEYPAD_MODE = 14153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LED_DRIVE_MODE = 14154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIEGAND_PULSES = 14155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIM_ZEROS = 14156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORMAT_NIBBLE_ARRAY = 14157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_BI_MAG_MODE = 14158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_NORTHERN_MAG_MODE = 14159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CASI_1_WIRE = 14160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISED = 14161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUTS_COME_FROM_READER = 14162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OSDP_2_ONLY = 14163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BAUD_RATE = 14164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACING = 14165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SMART_CARD = 14166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LC_MILLISECOND_S = 14167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OLD_FIRMWARE = 14168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVE_UNIT_CERTIFICATE = 14169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_ERROR = 14170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INCOMPATIBLE_FLOORS = 14171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SKIPBACKUPANDSTARTDIRECTORY = 14172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAY_BY_PLATE_PERMIT = 14173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRED_SESSION_HOTLIST = 14174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_INVENTORY_DURATION = 14175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTRANCE_DEVICES = 14176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXIT_DEVICES = 14177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IPV6 = 14178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IPV4 = 14179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SPECIFY_LPR_UNIT_IP_ADDRESS = 14180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEMPORARY = 14181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMANENT = 14182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_REMOVE_ALLENTITIES = 14183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CHANGING_SETTING_WILL_RECONNECT = 14184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TOOLS_UNIT_ENROLLMENT = 14185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MIFAREDESFIRE = 14186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MIFARE_DESFIRE = 14187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_ACCESSMANAGER_FAILOVEROCCURED = 14188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CREDENTIAL_PROVIDERS_CREATE = 14189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_ENTITY_TO_MONITOR = 14190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_TO_SEE_MESSAGE_CONTENT = 14191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_CRED_PASSWORD = 14192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ONLY_NUMERIC_CHARACTERS_ARE_ACCEPTED = 14193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITYTRAIL_EXPORT_VIDEO_NO_PP = 14194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITYTRAIL_EXPORT_VIDEO_ERROR_NO_PP = 14195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LIVE_VIDEO_STREAM_NAME_NO_PP = 14196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PLAYBACK_STARTED_NO_PP = 14197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_LPR_EDIT_READ = 14198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_CARDHOLDER_ADDED_TO_AREA = 14199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_EXTERNALSYSTEM_NOT_RUNNING = 14200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_DIRECTORY_GROUP_NOT_FOUND = 14201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVE_DIRECTORY_GROUP_NOT_FOUND = 14202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ARCHIVING_PROBLEM_STREAMING = 14203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOTE_INSUFFICIENT_SECURITY_INFO = 14204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FIRMWARE_UPGRADE_SCHEDULED = 14205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVATE_SEQUENCE = 14206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_MULTICAST_ENDPOINT_IPV6 = 14207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_MULTICAST_IPV6 = 14208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DATASTORE_ERROR_CUSTOM_FIELD_TYPE_ALREADY_EXISTS = 14209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITYTRAIL_EXPORT_VIDEO_ERROR_WITHFORMAT = 14210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITYTRAIL_EXPORT_VIDEO_REQUESTED_WITHFORMAT = 14211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_UNIT_TRUSTED_CERTIFICATE_RESET = 14212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_ASSOCIATED_CAMERA = 14213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SILENCE_INTRUSION_ALARM = 14214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACKNOWLEDGE_INTRUSION_ALARM = 14215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SILENCEINTRUSIONALARM = 14216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACKNOWLEDGEINTRUSIONALARM = 14217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TRIGGERINTRUSIONALARM = 14218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_PARTIAL_SUCCESS_AUDIO_REMOVED = 14219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BOLD = 14220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ITALIC = 14221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_UNDERLINE = 14222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USED = 14223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNUSED = 14224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CARDHOLDER_GROUP_IS_AN_ESCORT_CANNOT_BE_DELETED = 14225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INVALID_TIME_FORMAT = 14226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WEBCLIENT_CANNOTLOADSYSCONFIG = 14227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_WEBCLIENT_CANNOT_INIT_SYSMANAGER = 14228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MISSING_TIME_FORMAT = 14229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_PERSON_ADDED = 14230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_SELECTED_EXPORT_FORMAT = 14231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ROTATION = 14232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VIDEO_ROTATION_INFORMATION = 14233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_INVALIDDIRECTORYENDPOINT = 14234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOO_MANY_ITEMS_TO_DISPLAY = 14235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_IS_TEMPORARY_AND_INACTIVE = 14236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_OFFLINE_ELEVATOR_SCHEDULE_OVERRIDE = 14237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANCEL_SCHEDULE_OVERRIDE = 14238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_CONTROL_RTSP_PORT = 14239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_ALARM_CONTEXT = 14240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTX_ENROLLMENT_DISCOVERY_WARNING = 14241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UDP_START_PORT = 14242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UDP_END_PORT = 14243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_TIME_OUT_OF_SYNC = 14244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_TIME_OUT_OF_SYNC_RECOVERED = 14245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH_MONITORING_DASHBOARD_TASK = 14246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_RESTROLE_NAME = 14247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_RESTROLE_DESCRIPTION = 14248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCAL = 14249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RETENTION = 14250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSOCIATED_CAMERAS = 14251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_EVENTSOURCE_FEDERATEDCAMERA = 14252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CHOOSE_AREA = 14253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_CONNECTION_RECOVERED = 14254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_CONNECTION_STOPPED_UNEXPECTEDLY = 14255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_CONNECTION_STOPPED_BYUSER = 14256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH_EVENTS = 14257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_HEALTH_STATISTICS = 14258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_ALL_HEALTH_EVENTS = 14259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOW_DISK_CHANGE_LIMIT = 14260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTICAST_CONFIGURATION = 14261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_GATEWAY_ADDRESS = 14262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_OVERRIDE = 14263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKLIGHTCOMPENSATION_MODE = 14264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WDR_MODE = 14265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_NOT_SUPPORTED = 14266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERRIDE_FLOOR_ACCESS = 14267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_OVERRIDE_FLOOR_ACCESS_SCHEDULE_FREE = 14268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_OVERRIDE_FLOOR_ACCESS_SCHEDULE_RESTRICTED = 14269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_OVERRIDE_FLOOR_ACCESS_SCHEDULE_CANCELLED = 14270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_OVERRIDE_SCHEDULE_FOR_FLOOR = 14271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT_FILTERS = 14272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOMEONE = 14273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ELEVATORS_GROUP = 14274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ELEVATORS_GROUP = 14275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_30_SECONDS = 14276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_1_MINUTE = 14277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_5_MINUTES = 14278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READER_SUPERVISION = 14279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READER_SUPERVISION_INTERVAL = 14280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_INHERITS_RULE = 14281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENFORCE_SINGLE_PARKING_VIOLATION_CONFIRMATION = 14282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCONNECT_USER = 14283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DISCONNECT_SELECTED_USERS = 14284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_TEMPORARY_ACCESSPOINTRULE = 14285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATION_MODE = 14286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_USE_EXISTING_ACCESSPOINTRULE = 14287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_TO = 14288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CREATE_NEW_TEMPORARY_ACCESSPOINTRULE = 14289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_ZOOM_LEVEL = 14290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MAX_ZOOM_LEVEL_INFORMATION = 14291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOVE_UP = 14292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOVE_DOWN = 14293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_DELETE_INCIDENTS = 14294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_DELETEINCIDENTS = 14295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_READER_SUPERVISION = 14296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_CUSTOM_ACCESSPOINTRULE = 14297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_STREAMINGINFO = 14298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_STREAMINGINFO = 14299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INVALID_INPUT_PIN = 14300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTIPLE_MOBILE_CREDENTIAL_INFO = 14301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEMPORARY_ACCESSPOINTRULE = 14302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSEMAXOCCUPANCY = 14303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POPUP = 14304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CENTER_MAP_ALARM_RECEIVED = 14305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_OPTIONS = 14306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PANEL_POSITION = 14307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_ITEMS = 14308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_PANEL_POSITION = 14309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT_PANEL_POSITION = 14310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAYER_PANEL_POSITION = 14311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ON_SINGLE_CLICK = 14312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ON_DOUBLE_CLICK = 14313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POPUP_ON_MAP = 14314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DO_NOTHING = 14315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_IN_MONITORING_TASK = 14316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_MONITOR = 14317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_THREATLEVELS = 14318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_THREATLEVELS = 14319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_CAMERA_STREAMING_INFO = 14320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_CAMERASTREAMINGINFO = 14321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRIVACY_PROTECTED_CAMERA_CANNOT_BE_MOVED_TO_56_OR_EARLIER_ROLE = 14322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_REVERSETUNNEL_SERVER_NAME = 14323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_FEDERATED_SITES = 14324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_FEDERATED_SITES_STATUS_NOTREGISTERED = 14325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_SET_PARKING_ZONE_OCCUPANCY = 14326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_PARKING_ZONE_OCCUPANCY = 14327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_OCCUPANCY_SET = 14328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PARKINGZONE_SET_OCCUPANCY = 14329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_PARKINGZONE_SET_OCCUPANCY = 14330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_SET_OCCUPANCY = 14331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DEPRECATED_VERSION = 14332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADFS_ENABLE_PASSIVE_AUTH = 14333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORIGINAL_VIDEO_NOT_RECORDED_AUXILIARY = 14334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ORIGINAL_VIDEO_RECORDED_ARCHIVER_ONLY = 14335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_ENABLED = 14336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_OVERRIDE_ELEVATOR_SCHEDULE = 14337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONFIDENTIAL_VIDEO_UNAVAILABLE = 14338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PRIVACY_PROTECTION_TO_VIEW_CONFIDENTIAL_VIDEO = 14339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_REPORT_GENERATED_NO_RESULTS = 14340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REPORT_GENERATED_NO_RESULTS = 14341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_SELECT_PLAYBACK_ORIGIN = 14342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAYBACK_FILTER_TYPE = 14343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STREAMING_SOURCE = 14344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORIGINAL_ARCHIVE_SOURCE = 14345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSG_CONNECTION_TIMEOUT = 14346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INTERFACE_BATTERY_FAIL = 14347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INTERFACE_AC_FAIL = 14348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INTERFACE_TAMPER_FAIL = 14349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADFS_NO_RESPONSE = 14350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_HEALTH_MONITOR_DASHBOARD = 14351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CONFIGURE_ITEM_NUMBER_INFO = 14352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INTERFACE_BATTERY_FAIL_RESTORED = 14353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INTERFACE_AC_FAIL_RESTORED = 14354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_INTERFACE_TAMPER_FAIL_RESTORED = 14355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GCS_FAILED_CONNECT_HOST = 14356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GCS_FAILED_CONNECT_HOST_REASON = 14357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_SCHEDULE_ASSOCIATED_WITH_APR = 14358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_ANTIPASSBACK_HARD_STRICT_SHOULD_SET_FOR_MAXOCCUPANCY = 14359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_LOAD_PART_NUMBERS = 14360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_PUT_BACK_PRIVACY_PROTECTION = 14361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PATH_TOO_LONG = 14362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DASHBOARDS_PAGE = 14363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_DASHBOARD_NAME = 14364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REMOTE_CONFIGURATION = 14365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_REMOTE_CONFIGURATION_DESCRIPTION = 14366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIGURATION = 14367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERY_RULES = 14368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_ENROLL = 14369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISCOVERY_RULE_PROPERTIES = 14370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MAXOCCUPANCY_EXCEEDED = 14371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASE_CREATED = 14372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASE_DELETED = 14373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASE_UPGRADE = 14374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASE_BACKUP = 14375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASE_RESTORE = 14376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_DATABASE_CONFLICTRESOLUTION = 14377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_PORT_FORMAT = 14378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_PATHS_MUST_BE_ENTERED_MANUALLY = 14379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORWARD_DIRECTORY_REPORTS = 14380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_EDGESTORAGE_ENABLED = 14381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_SHARP_CREDENTIALS = 14382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_NO_CALIBRATION_REQUIRED = 14383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAXOCCUPANCY_CONFIGURATION_ERROR = 14384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETAILS_WARNING_MAXOCCUPANCY_NOTSUPPORTEDBY_UNIT = 14385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_LPRUNIT_PRE_SHARED_KEY = 14386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_ACCOUNT_TEMPORARILY_LOCKED = 14387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLINE_TILE_SELECTION = 14388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFLINE_PTZ_MODE = 14389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPERVISOR_2 = 14390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SD_LICENSE_USAGE_WARNING_THRESHOLD = 14391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HM_DASHBOARD_OPTIONS = 14392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAN_ESCORT_VISITORS = 14393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANNOT_ESCORT_VISITORS = 14394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_CARDHOLDER_GROUP_AS_ESCORT = 14395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_ALL_SIMILAR_EVENTS = 14396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_USERTEMPORARYLOCKED = 14397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_LOADING_VERSION = 14398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_UNSUPPORTED_REMOTE_VERSION = 14399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_UNABLE_LOCATE_VERSION = 14400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_CONNECTION_ERROR = 14401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_WINDOW_CLOSED = 14402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_TOO_MUCH_TIME_OPEN = 14403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TIMEZONE_LINEISLANDS_STANDARDABBR = 14404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTENSION_PRODUCT = 14405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLATE_REPORT = 14406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_TO_HOTLIST = 14407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_INSUFFICIENT_PRIVILEGES = 14408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MOBILEROLE_NAME = 14409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MOBILEROLE_DESCRIPTION = 14410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_VIDEOFILE_REEXPORT = 14411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIGITALPTZ_PRESETS = 14412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_ALL_EVENTS_FROM = 14413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIMIT_VISITORS = 14414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_SECOND_HOST = 14415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VISITOR_LIMIT_EXCEEDED = 14416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_VISITOR_INVALID_HOST_CONFIGURATION = 14417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_VISITORS = 14418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CARDHOLDERGROUP_HAS_NO_CARDHOLDERS_WHO_CAN_ESCORT = 14419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_SEQUENCE_PAUSED = 14420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITY_TYPE_SEQUENCE_RESUMED = 14421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_FLOORS = 14422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_LOCKDISPLAY = 14423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOORS = 14424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FLOORS_OF = 14425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_EDIT_FLOORS_INSUFFICIENT_PRIVILEGES = 14426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_FLOOR_REMOVED = 14427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_MAP_FLOOR_ADDED = 14428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_ALARMS_LINKED_MAPS = 14429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_OUTOFSYNC_RECOVERED = 14430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_CAMERA_OUTOFSYNC = 14431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INTRUSIONAREAWIZARD_ERRORBECAUSEDUPLICATEPHYSICALNAME = 14432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INTRUSIONAREAWIZARD_ERRORBECAUSEDUPLICATEPHYSICALNAMEWITHNAME = 14433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DURESS_CONFIGURATION_ERROR = 14434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETAILS_WARNING_DURESS_NOTSUPPORTEDBY_UNIT = 14435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HOST_ALREADY_ESCORTING = 14436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COMMAND_UNLOCK_ALL_DOORS_OF_AREA = 14437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SELECTED_CARDHOLDER_CANNOT_ESCORT_VISITORS = 14438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_DOORS_OF_AREA_EXPLICITLY_UNLOCKED = 14439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_UNLOCK_DOORS_OF_AREA = 14440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_STREAMMISMATCH = 14441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READ_DETAILS = 14442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CAMERA_NOT_ARCHIVING_NO_STREAM_DETAILS = 14443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRYING_TO_CONNECT_TO_X = 14444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_ENROLLMENT_WIZARD = 14445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MULTIFACTOR_AUTHENTICATION = 14446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_USER_ENROLLMENT_WIZARD = 14447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_INVALID_KEYFILE = 14448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_REENROLL_HINT = 14449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_WRONG_KEYFILE_FOR_SITE = 14450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_KEYFILE_ALREADY_CONSUMED = 14451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_USE_MANAGED_ACCOUNT = 14452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_CONNETION_NOT_TRUSTED_EXPLANATION = 14453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EGRESS_TRAFFIC = 14454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INGRESS_TRAFFIC = 14455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_CONNECTION_REFUSED_EXPLANATION = 14456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNEL_SITE_NAME = 14457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNEL_SITE_X = 14458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_TUNNELAGENT_DISCONNECTED_FROM_SERVER_X = 14459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_TUNNELAGENT_CONNECTED_TO_SERVER_X = 14460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_TUNNELROLE_CONNECTED = 14461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_TUNNELROLE_DISCONNECTED = 14462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_STATUS_TUNNELROLE_AGENT_LOST_ON_SERVER_X = 14463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TUNNEL_NOT_YET_ENROLLED = 14464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TUNNEL_REENROLL_WILL_STOP_WORKING = 14465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TUNNEL_RESET_ENROLLMENT = 14466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_TUNNELSERVER_DELETE_WILL_STOP_WORKING = 14467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_SERVER_NOT_TRUSTED = 14468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_CONNECTED = 14469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_CONNECTION_REFUSED = 14470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNEL_KEYFILE = 14471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_COPY_KEYFILE_TO_CLIPBOARD = 14472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TUNNEL_SAVE_KEYFILE_TO_DISK = 14473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TUNNEL_ALREADY_ENROLLED = 14474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_DASHBOARDS = 14475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_TITLE = 14476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_REFRESH_COPIE = 14477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_WIDGET = 14478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIDGETS = 14479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERAL_WIDGET_CONFIGURATION = 14480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DASHBOARD_EDITION_INFO = 14481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DASHBOARD_OPTIONS = 14482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EMPTY_DASHBOARD = 14483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_WIDGETS = 14484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_DASHBOARD = 14485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_DASHBOARD = 14486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_BACK = 14487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_NEW_DASHBOARD = 14488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_SELECTION = 14489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_NOT_SUPPORTING_CHARTS = 14490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIDGET_NOT_CONFIGURED = 14491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOP_RESULTS_COUNT = 14492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOP_RESULTS_WIDGET = 14493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IF_RESULT_COUNT = 14494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_COLORING_CONDITION = 14495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESULT_COUNT_WIDGET = 14496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RESULTCOUNT_WIDGET_DESCRIPTION = 14497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOPROWS_WIDGET_DESCRIPTION = 14498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_LEGEND = 14499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHART_WIDGET_DESCRIPTION = 14500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_COUNT_WIDGET = 14501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITYCOUNT_WIDGET_DESCRIPTION = 14502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_TOP_ROWS_WIDGET = 14503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_NUMBER_OF_RESULTS_WIDGET = 14504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_TO_DASHBOARD = 14505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEB_WIDGET_DESCRIPTION = 14506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_WEB_ADDRESS = 14507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEXT_WIDGET_DESCRIPTION = 14508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TILE_WIDGET_DESCRIPTION = 14509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMAGE_WIDGET_DESCRIPTION = 14510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SELECT_IMAGE_FILE = 14511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_DASHBOARDS = 14512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_DASHBOARDS = 14513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_BYPASSANTIPASSBACK = 14514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BYPASSANTIPASSBACK_EVENT_MESSAGE = 14515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DASHBOARDS_PAGE_DESCRIPTION = 14516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_DASHBOARDS = 14517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_DASHBOARDS = 14518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CONVERSION_PARTIAL_SUCCESS_INCOMPLETE_FRAMES = 14519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_FOV_NOT_SUPPORTED = 14520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIN_FLOORS_REQUIRED = 14521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDIT_ENTITY_ICON_CHANGED = 14522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_LAYERS = 14523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTENDED_INFORMATION = 14524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MODEL = 14525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_DOORTEMPLATE = 14526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_DOORTEMPLATES = 14527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTERFACEMODULE_DEVICEDEFINITION = 14528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_INTERFACEMODULE_DEVICEDEFINITIONS = 14529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_OUTPUT_NORMAL = 14530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SET_OUTPUT_ACTIVE = 14531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIT_DETAILS = 14532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RULE_LIMIT_REACHED = 14533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAYED_EVENTS = 14534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_HOST_CANNOT_DISABLE_CAN_ESCORT = 14535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_CHECKOUT_OR_UPDATE_VISITOR = 14536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_CHECKOUT_OR_UPDATE_VISITORS = 14537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_CHECKOUT_OR_UPDATE_VISITORS_SHORTLIST = 14538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PLAYBACK_VIDEO_FAILURE_SEQUENCE = 14539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LIVE_VIDEO_FAILURE_SEQUENCE = 14540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASEAUTHENTICATIONMODE_WINDOWS = 14541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASEAUTHENTICATIONMODE_SQLSERVER = 14542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_HOTLIST = 14543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CANNOT_MOVE_LPR_UNIT_LEGACY = 14544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_FEATURE_MAPS = 14545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_FEATURE_THREAT_LEVELS = 14546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_FEATURE_TRACKING = 14547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_MAPS = 14548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_THREAT_LEVELS = 14549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_TRACKING = 14550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CANNOT_ADD_TO_HOTLIST_NO_LPRMANAGER = 14551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTITION_ADD_ACCESS_RIGHT_NEW_PARTITION_EXCEPTION = 14552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARTITION_MODIFY_ACCESS_RIGHT_INSUFFICIENT_PRIVILEGES = 14553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_CREATION_PRIVILEGE_ESCALATION_GROUP = 14554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADD_TO_HOTLIST_FAILED = 14555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VERTXERROR_UNABLETOCONFIGURETLS = 14556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HELP_URL_HIDENROLLMENT = 14557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HELP_URL_HARDWAREACCELERATION = 14558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HELP_URL_LIVEVIDEO = 14559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_TIME_DAYS = 14560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE = 14561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_MOBILE = 14562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARE_TO = 14563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEAR_X = 14564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONAL = 14565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATIVE_TIME_LESS1MIN = 14566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLEASE_SELECT_LAYERS = 14567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MOBILEONMAPS = 14568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MOBILEONMAPS = 14569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_LASTACCESSLOCATION = 14570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_LASTACCESSDECISION = 14571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_1 = 14572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_2 = 14573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_3 = 14574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_4 = 14575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AD_LIMITS = 14576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROUP_ANALYTICS = 14577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUBTASK_ANALYTICS = 14578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUBTASK_ARCHIVETRANSFER = 14579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_PART_NUMBERS = 14580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_AGENTSTARTED_FORMAT = 14581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_ARMING = 14582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_DIRECTION = 14583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING = 14584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISARMING = 14585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_IN = 14586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_OUT = 14587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNERGIS_AREA = 14588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AREA_ALREADY_ASSIGNED = 14589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AREA_ALREADY_ASSIGNED_DETAILS = 14590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_AREA_ASSIGNED_TO_INTRUSION_AUDIT = 14591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_PRIVACYPROTECTION = 14592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY_PROTOCOL = 14593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTOCOL_TLS10 = 14594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTOCOL_TLS11 = 14595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTOCOL_TLS12 = 14596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROTOCOL_HID_ENCRYPTION = 14597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INCORRECT_PASSWORD = 14598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_PROTECT = 14599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_PASSWORD_EMPTY = 14600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OPEN_PROTECTED_FILE = 14601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_REEXPORT = 14602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_LOADING_ERROR_VERSION = 14603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_IS_PASSWORD_PROTECTED = 14604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEXT_WATERMARK = 14605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_PASSWORD_PROTECTED = 14606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_REGISTERARCHIVERSOURCE = 14607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DATABASE_ENCRYPT_CONNECTIONS = 14608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_VERIFY_DATABASE_CERTIFICATE = 14609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALIDATE_SERVER_CERTIFICATE = 14610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENCRYPT_SERVER_CONNECTIONS = 14611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_SCHEDULE = 14612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_OPTION = 14613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_WARNING_UNSUPPORTED_OPTIONS = 14614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_DATABASE_NOT_TRUSTED = 14615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPILING_MACRO = 14616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOR_THE_NEXT = 14617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CANCEL_SCHEDULE_OVERRIDE_CONFIRMATION = 14618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_SCHEDULE_OVERRIDE_CONFIRMATION = 14619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_MOBILE_LOWSTREAM = 14620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_WIFISTREAM = 14621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOBILE_DOESNT_SUPPORT_ALL_ENTITIES = 14622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECT_ENTITY_UNIT_PROGRESS_PAGE_TITLE = 14623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_RECONNECTION_FAILED = 14624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_RECONNECTION_SUCCESSFUL = 14625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_PRECONNECTION_PAGE_TITLE = 14626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHG_OPERATION = 14627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WINDOWS_INTEGRITYLEVEL_MACROS = 14628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_STATUS_WIDGET = 14629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_STATUS_WIDGET_DESCRIPTION = 14630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_ADMIN_PASSWORD = 14631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ENFORCE_STRONG_USER_PASSWORD = 14632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_LOCAL_SERVICE_ACCOUNT_FOR_GENETEC_SERVER = 14633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_LOGON_PASSORD = 14634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ACTIVATE_AUTO_LOCKING = 14635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CONFIGURATE_FEDERATION_USER = 14636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USER_WINDOWS_ACTIVE_DIRECTORY_INTEGRATION = 14637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_CLAIMS_BASED_AUTHENTICATION = 14638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_RESTRICT_SERVER_ADMIN_ACCESS_TO_LOCAL_CONNECTIONS = 14639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_RESTRICT_USER_PRIVILEGES = 14640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_RESTRICT_CONNECTION_OF_CLIENT_APPLICATIONS = 14641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USER_RECOMMENDTED_SECURITY_SETTINGS = 14642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_REVIEW_PRODUCT_IMPROVEMENT_PROGRAM = 14643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_TRUSTED_CERTIFICATE = 14644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CONTROL_ACCESS_TO_RESOURCES = 14645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_FUSION_STREAM_ENCRYPTION = 14646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DO_NOT_USE_SC_IN_BACKWARDS = 14647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_DIRECTORY_GATEWAY = 14648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_KEEP_SC_UPDATED = 14649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_RECOMMENDED_FIRMWARE = 14650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DISABLE_BASIC_AUTHENTICATION = 14651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_TURN_ON_SECURE_COMMUNICATION_MEDIA_ROUTER = 14652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_UPDATE_CAMERA_FIRMWARE = 14653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_SET_ADMIN_PASSWORD_CAMERAS = 14654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CONNECT_CAMERAS_HTTPS = 14655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DEACTIVATE_UNUSED_SERVICES = 14656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_GLOBAL_CARDHOLDER_SYNCHRONIZER = 14657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_HID_ENABLE_SECURE_MODE = 14658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_UPDATE_HID_FIRMWARE = 14659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_SYNERGIS_APPLIANCE_FIRMWARE = 14660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_ADMIN_PASSWORD_APPLIANCE = 14661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ENABLE_SECURE_MODE_APPLIANCE = 14662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DO_NOT_ACTIVATE_P2P_AND_GLOBAL_ANTIPASSBACK = 14663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_WHITELIST_IP_ADDRESS = 14664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ACTIVATE_LOGGING_IN_ACTIVITY_TRAIL = 14665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DISABLE_UNLIMITED_SESSION_TIME = 14666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_INSTALL_VALID_CERTIFICATE = 14667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ENSURE_HTTPS_MOBILE_ADMIN_AND_MOBILE_SERVER = 14668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHOOSE_LONG_UNIQUE_PASSWORD = 14669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_MOBILE_SERVER_AND_WEB_CLIENT = 14670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_MOBILE_SERVER_AND_MOBILE_APPS = 14671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ENCRYPT_VIDEO_STREAMS = 14672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DO_NOT_USE_ADMIN_PRIVILEDGE_ACCOUNT = 14673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_ENCRYPTED_COMMUNICATION = 14674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DO_NOT_USE_ADMIN_PRIVILEDGES = 14675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CONFIGURE_WINDOWS_SECURELY = 14676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_MICROSOFT_EMET = 14677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_SYNCHRONIZE_CLOCKS_WITHIN_SYSTEM = 14678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLE_STATUS_WIDGET = 14679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLE_STATUS_WIDGET_DESCRIPTION = 14680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH_EVENT_WIDGET_DESCRIPTION = 14681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATURE_KEY = 14682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SENDMESSAGE_TO_X = 14683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERTIFICATE_NOT_PART_OF_LICENSE = 14684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY_SCORE = 14685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY_CHECKLIST = 14686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_SCORE = 14687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_COMPLETED_SECTIONS = 14688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_SECURITY_CHECKLIST = 14689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_GUS = 14690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_SECURITY_CENTER_MOBILE = 14691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_WINDOWS = 14692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_NOT_RESPECTING = 14693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_WARNING_MESSAGE = 14694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_SC_HARDENING_GUIDE = 14695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISARM_PRIMARY_AREA_ON_LOGIN = 14696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REARM_IN_STAY_MODE = 14697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_MENU = 14698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAN_ACKNOWLEDGE_ALARMS = 14699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAN_ACKNOWLEDGE_SYSTEMTROUBLE = 14700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAN_LOGIN_REMOTELY = 14701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_LOITER_EXPIRY_COUNT_ENABLED = 14702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HAS_DURESS_PIN = 14703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GO_TO_MENU_ON_LOGIN = 14704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GREET_USER = 14705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_ALARM_ON_LOGIN = 14706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PHONE_EXTENSION = 14707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTING_ID = 14708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_MENU_NONE = 14709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_MENU_INSTALLER = 14710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_MENU_USER = 14711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ILLEGITIMATE_CERTIFICATE = 14712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_SWITCH_TO_MAP_MODE = 14713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_SPY_MODE = 14714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_GENETEC_COPYRIGHT = 14715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_ANALYTICS = 14716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIDE_ANALYTICS = 14717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_ALLOW_REX = 14718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_CHECK_UNLOCK_SCHEDULE = 14719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_DISARMED_AND_SCHEDULE_VALID_UNLOCK_DOOR = 14720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_DISARMED_OR_SCHEDULE_VALID_UNLOCK_DOOR = 14721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DENY_ENTRY_IF_INSIDE_AREA_IS_AREMED = 14722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DENY_EXIT_IF_OUTSIDE_AREA_IS_AREMED = 14723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_LOCK_FOLLOWS_INSIDE_AREA = 14724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_LOCK_FOLLOWS_OUTSIDE_AREA = 14725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_ACCESS_TAKEN_ON_DOOR_UNLOCK_EVENTS = 14726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_ACCESS_TAKEN_ON_REX_REN_EVENTS = 14727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_OPEN_CLOSE_EVENTS_ON_SCHEDULE = 14728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORCED_DOOR_SENDS_DOOR_OPEN = 14729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCK_OUT_REX_WHEN_INSIDE_AREA_ARMED = 14730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTAIN_REX = 14731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVENT_UNLOCK_ON_ECHEDULE_IF_INSIDE_AREA_ARMED = 14732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PREVENT_UNLOCK_ON_ECHEDULE_IF_OUTSIDE_AREA_ARMED = 14733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROMPT_USER_FOR_ACCESS_REASON_CODE = 14734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PULSE_READER_BEEPER_ON_REX = 14735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RCYCLE_DOOR_OPEN_TIME_ON_REX = 14736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECYCLE_REX_TIME = 14737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCHEDULE_OPERATED_LATE_TO_OPEN = 14738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_USER_AREA_WHEN_PASSBACK_DISABLED = 14739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEPAD_ADDRESS = 14740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOOR_PRE_ALARM_DELAY_TIME = 14741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_PRE_ALARM_EVENTS = 14742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECYCLE_DOOR_OPEN_TIME_ON_REX = 14743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_LICENSESYNERGISIX = 14744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_AGENTSTOPPED_FORMAT = 14745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_WAITINGFORCONNECTION = 14746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_FAILEDCREATEAGENT = 14747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_INITIALIZATIONFAILED = 14748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVERS_WIDGET_DESCRIPTION = 14749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVERS_WIDGET = 14750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ON_BEHALF_OF = 14751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_ON_HOST = 14752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_NETWORK_TRAFFIC_IN = 14753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_NETWORK_TRAFFIC_OUT = 14754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_ARCHIVER_STATISTICS = 14755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_TASK = 14756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CPU_USAGE = 14757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEMORY_USAGE = 14758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALYTICSAGENT_TOTAL_CAMERA = 14759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALYTICSAGENT_NETWORK_RECEIVING_RATE = 14760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALYTICSAGENT_NETWORK_SENDING_RATE = 14761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_MODEL = 14762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_DRIVER = 14763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_USAGE = 14764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_VIDEO_ENGINE_LOAD = 14765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_VIDEO_MEMORY_USAGE = 14766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_MEMORY_CONTROLLER_LOAD = 14767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_BENCHMARK_FPS = 14768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPU_BENCHMARK_LAST_RUN_DATE = 14769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY_SCORE_WIDGET_DESCRIPTION = 14770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_PASSWORD_AND_CONFIRM_PASSWORD_MATCH = 14771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_CONTAINS_WHITESPACE = 14772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCHEDULE_OPERATES_LATE_TO_OPEN = 14773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PROCEED_ANYWAY_NOT_RECOMMENDED = 14774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LIVE_VIDEO_STREAM_NAME_WITH_ID = 14775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZERO_POSITION_PAN = 14776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZERO_POSITION_TILT = 14777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZERO_POSITION_ZOOM = 14778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LENS_CALIBRATION_CIRCLE_RADIUS = 14779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IDLE_COMMAND_NUMBER = 14780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOVEINTRUSIONUNIT_NOTSUPPORTED = 14781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_LASTACCESSGRANTEDTIME = 14782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_RAW_DATA = 14783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTION_WIDGET_DESCRIPTION = 14784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_CARDHOLDER_EXPIRE_AFTER_FIRST_USE = 14785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_CREDENTIAL_EXPIRE_AFTER_FIRST_USE = 14786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_CARDHOLDER_EXPIRE_WHEN_UNUSED = 14787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_CREDENTIAL_EXPIRE_WHEN_UNUSED = 14788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_MULTICAST = 14789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALWAYS_MULTICAST_WARNING = 14790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_DISK_ACTION_ERROR = 14791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_DISK_FORMATTED = 14792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_DISK_LOCKED = 14793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_DISK_MOUNTED = 14794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_DISK_UNLOCKED = 14795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_UNIT_DISK_UNMOUNTED = 14796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MESSAGE_FROM_SENDER = 14797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARE_VIDEO_FROM_SENDER = 14798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MULTIPLE_ENTRIES_MORE = 14799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLATFORM_VERSION = 14800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAYER = 14801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAYERS_TO_IMPORT = 14802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNABLE_EXTRACT_IMAGE_FROM_LAYERS = 14803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MISSING_MAP_PROVIDER = 14804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUIRES_10_8_AND_UP = 14805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDE_IDENTIFIABLE_DATA = 14806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOSETTINGS = 14807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE_PERSONAL_DATA_SYNC = 14808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_DURESS_USER = 14809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_STREAM_QUALITY_MAXRESOLUTION = 14810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_HIGH_RESOLUTION_CUTOFF = 14811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION_AREAS_EXIST_BUT_NONE_IS_SELECTED = 14812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLUGIN_CANNOT_LEVERAGE_DATABASE_ENCRYPTION = 14813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROPOSED_PLATFORM_VERSION = 14814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROPOSED_PLATFORM_DESCRIPTION = 14815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_ENCRYPTED_FILE = 14816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXTERNALSYSTEM_ACCEPT_NEXT_CERTIFICATE = 14817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_LICENSE = 14818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_LICENSE = 14819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANALYTICS_MODULES = 14820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATE_THUMBNAIL = 14821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_HOTLIST_INFO = 14822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HDREADY = 14823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_RESOLUTION = 14824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_HIDE_OUTPUT_STREAM = 14825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_SHOW_OUTPUT_STREAM = 14826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOUBLESWIPEUNLOCK = 14827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOUBLESWIPELOCK = 14828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_IDENTITY_UNIT_NOT_VERIFIED = 14829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COULDNT_CREATE_ROLE = 14830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLATFORM_UPGRADE = 14831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_PLATFORM = 14832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_PLATFORM = 14833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PLATFORM_UPGRADE_ACCESS_DISRUPTION = 14834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CAMERA_INTEGRITY_MONITORS = 14835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNCOMPLETED = 14836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECOMMENDATION_ONLY = 14837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_COPYCONFIG_ERROR_IMAGESTABILISATION_CAPABILITIES_NOT_EQUALS = 14838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUS_CONNECTION_ERROR = 14839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLESTATUS_ONLINE = 14840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ROLESTATUS_OFFLINE = 14841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UAR_STATUS = 14842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FIRMWARE_UPGRADE_NEEDS_UAR = 14843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_PARKINGRULE_PERMIT_OR_PERMIT_RESTRICTION = 14844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_MOTOSCANS_COUNT = 14845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_PARKING_ENFORCEMENT_LIGHT_COUNT = 14846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_UPDATE_GENETEC_SERVICE = 14847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_AVOID_DIRECT_INTERNET_ACCESS = 14848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DEACTIVATE_UNUSED_ROLES = 14849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_HID_ENROLL_ACCESS_CONTROL_UNITS = 14850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_ADMIN_PASSWORD_HID_CONTROLLERS = 14851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_AUTHENTICATE_DATABASE_CONNECTIONS = 14852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_MOTOSCAN = 14853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PATROLLERTYPE_PARKING_ENFORCEMENT_LIGHT = 14854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHITELIST = 14855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WHITELIST_MOBILE_DEVICES = 14856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLACKLIST = 14857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OS = 14858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_APPLICATIONS = 14859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BLACKLIST_MOBILE_DEVICES = 14860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BLACKLISTED = 14861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMISSIONS = 14862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_S = 14863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SHOW_ALLOWED = 14864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SHOW_DENIED = 14865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_SHOW_UNDEFINED = 14866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE = 14867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_ACCESS = 14868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_WHAT_PRIVILEGES = 14869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_ALLOWED = 14870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER = 14871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_LIVE_STREAM = 14872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_GO_TO_UPDATES = 14873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPDATES_AVAILABLE = 14874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVAILABILITY_WIDGET_DESCRIPTION = 14875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUS_NOT_PROPERLY_CONFIGURED = 14876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_MACHINES_DETECTED = 14877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_REPORTING_UNKNOWN = 14878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDITABLE = 14879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_USERTEMPORARILYLOCKED = 14880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ABBREVIATION = 14881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KICKOUT_USER = 14882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_PATROLLER_ROUTE = 14883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_PARKING_OCCUPANCY = 14884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_PARKING_ZONE_DATA = 14885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_HIT_IMAGE = 14886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOVU_READ_IMAGE = 14887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BYPASS_CACHE = 14888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_OPERATION_DENIED_BY_FIREWALL = 14889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CONFIGURE_GLOBAL_CARDHOLDER = 14890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_TRUSTED_CERTIFICATES_SYNERGIS_APPLIANCES = 14891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_LATEST_SYNERGIS_APPLIANCE_SECURITY = 14892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTH_MONITORING_PAGE_DESCRIPTION = 14893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_SECURITY_WIDGET = 14894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_OPEN_GUS_WEBPORTAL = 14895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RECEIVE_GUS_NOTIFICATIONS = 14896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GUS_OPEN_WEBPORTAL = 14897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RECEIVE_GUS_NOTIFICATIONS = 14898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOVEVIDEOUNIT_NOTSUPPORTEDARCHIVER = 14899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_DIRECTORY_CACHE = 14900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_CACHE = 14901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_JOURNAL_SIZE = 14902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_TOOLTIP_MAXIMUM_JOURNAL_TABLE_SIZE = 14903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTH_MONITORING_ROLE_DEACTIVATED = 14904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_ON_REMOTE_MONITOR = 14905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CREDENTIALS_VALIDATION_VALIDATING = 14906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CREDENTIALS_VALIDATION_INVALID_CREDENTIALS = 14907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CREDENTIALS_VALIDATION_TIMEOUT = 14908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CREDENTIALS_VALIDATION_ERROR = 14909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION__REQUEST_ACCESSCONTROL_UNIT_DETAILS = 14910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_SECURITY_WIDGET = 14911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DIRECTORY_CACHE = 14912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_DOWNLOADINGENTITIES = 14913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_DELETINGENTITIES = 14914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_CREATINGENTITIES = 14915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MACRO_EXECUTE_METHOD_CANNOT_BE_ASYNCVOID = 14916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TODAY = 14917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ON_LASSO = 14918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_VIEW_THREAT_LEVELS = 14919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_MODIFY_THREAT_LEVELS = 14920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_ADD_THREAT_LEVELS = 14921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_DELETE_THREAT_LEVELS = 14922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_RESETSENSORS = 14923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_RESETSENSORS = 14924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_BYPASSINTRUSIONINPUT = 14925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_BYPASSINTRUSIONINPUT = 14926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_THREAT_LEVELS = 14927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_THREAT_LEVELS = 14928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_THREAT_LEVELS = 14929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_THREAT_LEVELS = 14930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_IMPORT_TOOL = 14931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASK_ARCHIVE_TRANSFER = 14932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITOR_ALL_CAMERA_EVERY = 14933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_TIME_TO_MONITOR_ALL_CAMERAS = 14934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_CAMERA_INTEGRITY_TOOK_TOO_LONG = 14935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CAMERA_INTEGRITY_TOOK_TOO_LONG = 14936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_PROFILES = 14937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_SDK = 14938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_VISITORS = 14939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MSGBOX_BODY_DELETE_VISITOR = 14940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_VERIFY_FILE_FORMAT = 14941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BACKUP_FOLDER_LOCAL2 = 14942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CARDHOLDER_GROUPS_CANNOT_ESCORT = 14943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILEDCONNNECTIONNOTIFICATION = 14944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_PROXY_DISCONNECTED = 14945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_PROCESSING = 14946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_ERROR_MULTIPLE_SYSTEMS = 14947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_SCREENSHOT = 14948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_MAP_SCREENSHOT = 14949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_MAXCAMBANDWITH = 14950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_NOTAPPLICABLE = 14951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORDSTRENGTH_NOTAPPLICABLE_DETAILS = 14952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_WIFIVIDEODATAFORMAT = 14953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_WIFIFRAMERATE = 14954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CELLULARSTREAM = 14955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CELLULARVIDEODATAFORMAT = 14956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CELLULARFRAMERATE = 14957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_VIDEO_RESOLUTION_DESCRIPTION_HIGH = 14958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_VIDEO_RESOLUTION_DESCRIPTION_STANDARD = 14959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_VIDEO_RESOLUTION_DESCRIPTION_LOW = 14960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_VIDEO_RESOLUTION_DESCRIPTION_DEFAULT = 14961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_PASSWORD_WATERMARK = 14962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILEVIDEOSETTINGS_WIFI = 14963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILEVIDEOSETTINGS_CELLULAR = 14964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_DESFIRE_CONFIGURATION = 14965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_VIDEOMODULES = 14966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIEW_VIDEOMODULES = 14967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFY_VIDEOMODULES = 14968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_VIDEOMODULES_ACTIVATION = 14969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_VIDEOMODULES = 14970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIDEOMODULES_ACTIVATION = 14971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MAP_CONTEXTMENU_UNLOCKDISPLAY = 14972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISIT_DATE = 14973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_DEFAULT_HIGHSTREAM = 14974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_DEFAULT_LOWSTREAM = 14975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXITING_INTRUSION = 14976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTERING_INTRUSION = 14977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCHEDULE_OPTIONS = 14978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCK_UNLOCK_OPTIONS = 14979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADVANCED_OPTIONS = 14980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERAL_OPTIONS = 14981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTERING_EXITING = 14982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_KICKOUTBLACKLISTMOBILE = 14983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_KICKOUTBLACKLISTMOBILE = 14984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWMOBILE = 14985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEWMOBILE = 14986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ARMINTRUSIONAREA = 14987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DISARMINTRUSIONAREA = 14988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_FORCEARMINTRUSIONAREA = 14989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_BYPASSARMINTRUSIONAREA = 14990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ARMINTRUSIONAREA = 14991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DISARMINTRUSIONAREA = 14992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_FORCEARMINTRUSIONAREA = 14993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_BYPASSARMINTRUSIONAREA = 14994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SMARTZOOM = 14995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_USE_EVENT_LOCATION = 14996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RADIUS = 14997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_MAP_MANAGER_NOT_PRESENT = 14998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISITOR_PENDING_ARRIVAL = 14999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISITOR_ON_SITE = 15000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_AVERAGING_PERIOD = 15001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_AVERAGING = 15002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_OPTIMIZATION = 15003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_OPTIMIZATION_OFF = 15004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_OPTIMIZATION_MAXIMUM_QUALITY = 15005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_OPTIMIZATION_HIGH_QUALITY = 15006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_OPTIMIZATION_MEDIUM_QUALITY = 15007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_OPTIMIZATION_LOW_BIT_RATE = 15008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BITRATE_OPTIMIZATION_MINIMUM_BIT_RATE = 15009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISITOR_CONFIRM_CHECKIN = 15010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISIT_DURATION = 15011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAVE_AND_CHECKIN = 15012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSION = 15013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_OPEN_REPORT = 15014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXMOBILESESSIONS = 15015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXMOBILEVIDEOSESSIONS = 15016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_COMMUNICATIONS = 15017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_COMMUNICATIONS = 15018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CALL_PANEL = 15019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CALL_PANEL = 15020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MAKE_NEW_CALL = 15021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MAKE_NEW_CALL = 15022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_USE_DIAL_PAD = 15023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_USE_DIAL_PAD = 15024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_USE_CONTACT_LIST = 15025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_USE_CONTACT_LIST = 15026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TRANSFER_CALL = 15027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TRANSFER_CALL = 15028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CALL_REPORT_TASK = 15029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CALL_REPORT_TASK = 15030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_DESFIRE_PRELOADEDINDEX = 15031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_DESFIRE_INLINEKEY = 15032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_CONFIGURATION = 15033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_CONFIG = 15034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_USB_READER_TESTING = 15035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_CARD_ENCODING_MODE = 15036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_VERB_FORMAT_CARD_MODE = 15037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_DESFIRE_UPDATE_APPLICATIONMASTERKEY_MODE = 15038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_FREE_CREATE_MODE = 15039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURATION_NAME = 15040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLICATION_ID = 15041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CRYPTO_METHOD = 15042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_ID = 15043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_COMMUNICATION_SETTINGS = 15044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_LENGTH_IN_BITS = 15045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_VALIDATION = 15046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEXADECIMAL_KEY = 15047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_KEY_DIVERSIFIED = 15048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SITE_CONFIGURATION_DIALOG = 15049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CARD_MASTER_KEY = 15050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_APPLICATION_MASTER_KEY = 15051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_APPLICATION_READ_KEY = 15052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OLD_APPLICATION_MASTER_KEY = 15053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_KEY_AT_INDEX = 15054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOUN_NUMBER = 15055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_READ_MODE = 15056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_WRITE_MODE = 15057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_OFFSET_IN_BYTES = 15058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE = 15059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUTTYPE = 15060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INPUTDEFINITIONS = 15061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAY_LOWERCASE = 15062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINUTE = 15063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECOND = 15064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FAILED_TO_OVERWRITE_FILE = 15065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIG_NAME_ALREADY_USED = 15066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INLINE_KEYS_WARNING = 15067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FORTY_BYTES_HEXADECIMAL_ONLY = 15068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PICTURE_BEING_SYNCHRONIZED = 15069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUSH_NOTIFICATIONS = 15070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GPNS = 15071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AZUREHUB = 15072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUSH_NOTIFICATION_SERVICE_SETTINGS = 15073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HUB_NAME = 15074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_TEST_SENDS = 15075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_MOBILE_FEATURE = 15076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_INBOX_MESSAGES_PER_USER = 15077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEVICE_CAMERA_STREAMING = 15078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_DYNAMIC_STREAM = 15079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_FIRE = 15080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_BURGLARY = 15081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_PANIC = 15082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_DOOR = 15083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_MOTIONSENSOR = 15084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_WINDOW = 15085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_FENCE = 15086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_GAS = 15087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDWARE_DECODING_NOT_ENABLED = 15088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DUPLICATE_SHARP = 15089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DUPLICATE_SHARP = 15090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OFFICERS = 15091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_IS_WATCHING_CAMERAS = 15092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_DELTED_ENTITIES = 15093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USEDEFAULTSECUREPORT = 15094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SUMMARY_VIEW = 15095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DETAILS_VIEW = 15096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENING_GUIDE_INSUFFICIENT_PRIVILEGES = 15097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYNERGISIX = 15098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_REVOKE_ENCRYPTED_KEY_STREAM = 15099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_REVOKE_ENCRYPTED_KEY_STREAM = 15100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CREDENTIAL_ALREADY_BELONGS_TO_ANOTHER_CARDHOLDER_NOT_RESET = 15101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_INTRUSIONINPUTTYPE_ICONMODIFIED = 15102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UPGRADE_OPTIMIZING_DATABASE_AND_CLEANUP = 15103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_ENROLLMENT_CHOOSE_GROUPS = 15104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_ENROLLMENT_CHOOSE_EXTRA_PROPERTIES = 15105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FILE_FILTER_TXTFILES_CSVFILES = 15106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_TURN_ON_ENCRYPTION_ON_TRANSIT = 15107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCLUDE_DELETED_ENTITIES = 15108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRESET_CIM_LOW_SENSITIVITY = 15109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRESET_CIM_HIGH_SENSITIVITY = 15110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRESET_CIM_MEDIUM_SENSITIVITY = 15111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FIELD_INTRUSIONINPUTTYPE = 15112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHARP_PORT_INFO = 15113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ATTEMPTING_TO_RECONNECT = 15114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_STARTINGAGENT_FORMAT = 15115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DASHBOARD_ALREADY_REFRESH_EVERY = 15116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEMSTATUSRXRATE = 15117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEMSTATUSTXRATE = 15118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEMSTATUSMAXTXRATE = 15119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEMSTATUSMAXRXRATE = 15120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEMSTATUSVIDEOSESSIONS = 15121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEMSTATUSACTIVECAMERA = 15122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_COPYCONFIG_ERROR_DIFFERENT_VERSION = 15123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMUNICATION_ENCRYPTION = 15124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYSTEMSTATUSCLIENTS = 15125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_MUST_CONFIGURE_ONE_SERVER = 15126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_INTERVENTION_REQUIRED = 15127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_USE_JOURNALING = 15128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDING_PRIVACY_PROTECTION = 15129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MODIFY_USER_GRANTS_PARTITION_ACCESS = 15130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MULTICAST_DISABLED = 15131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_INFO_BEST_AVAILABLE_MULTICAST_DISABLED = 15132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_UNREACHABLE_PEER = 15133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_EVENT_SOUNDS = 15134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_PERMIT_RESTRICTION = 15135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOTAL_NUMBER_OF_CAMERAS = 15136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HTTP_SERVER_START_FAILED = 15137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_EMPTY_PERSISTENCE_PATH = 15138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ENABLING_LARGE_RTP_PACKETS_WARNING = 15139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_MOTION_ANOMALY_UP = 15140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_MOTION_ANOMALY_DOWN = 15141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_MOTION_ANOMALY_NOMINAL = 15142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_WEBCLIENTLICENSEEXCEEDED = 15143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_DISCONNECT_SELECTED_USER = 15144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_REJECT_CALL = 15145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REJECT_CALL = 15146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_CONFIGURED = 15147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MANUALENROLLMENT_FAILED_TIMEOUT = 15148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE_ACTION_ON_DISARMED_TROUBLE = 15149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_PLATEREPORT = 15150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HELP_PLATEREPORT = 15151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECEIVED_REQUEST = 15152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_CATEGORY_DEACTIVATED = 15153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_REPORTING_NOT_IMPLEMENTED = 15154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_RUN_MACROS_WITH_LIMITED_ACCESS_RIGHTS = 15155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADVANCEDSECURITYSETTINGS = 15156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_ALLOWUNKNOWNCA = 15157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_ALLOWNONSERVER = 15158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_ALLOWINVALIDNAME = 15159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_ALLOWINVALIDDATE = 15160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_ALLOWWEAKSIGNATURE = 15161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CERTIFICATE_ALLOWUNKNOWNCA = 15162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CERTIFICATE_ALLOWNONSERVER = 15163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CERTIFICATE_ALLOWINVALIDNAME = 15164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CERTIFICATE_ALLOWINVALIDDATE = 15165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CERTIFICATE_ALLOWWEAKSIGNATURE = 15166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_UNKNOWNCAALLOWED = 15167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_NONSERVERALLOWED = 15168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_INVALIDNAMEALLOWED = 15169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_INVALIDDATEALLOWED = 15170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_WEAKSIGNATUREALLOWED = 15171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADVANCEDSETTINGS_INVALIDNAME = 15172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_EXTENSIONIDENTIFIER = 15173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIMITMEDIAGATEWAYSERVER = 15174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_MAX_ITEMS_SELECTED_REACHED = 15175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FIRST_HOST_ALREADY_ESCORTING = 15176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ENCODE_SMART_CARDS = 15177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ENCODE_SMART_CARDS = 15178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ERROR_FIRST_OR_LAST_NAME_REQUIRED = 15179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_MACROS = 15180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_MACROS = 15181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_AC_GENERAL_SETTINGS = 15182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_REPORT_INTRUSIONINPUTACTIVITY = 15183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_INTRUSIONINPUTACTIVITY = 15184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_CATEGORY_INTRUSIONDETECTION = 15185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_REPORT_INTRUSIONINPUTACTIVITY = 15186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_INTRUSIONINPUTACTIVITY = 15187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AGENT_NETWORK_RECEIVING_RATE = 15188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AGENT_NETWORK_SENDING_RATE = 15189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASE_DATAFOLDER = 15190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_DATABASE_LOGFOLDER = 15191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_AGENT_MODIFIED = 15192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GVP_NOT_ENOUGH_VIDEO_TILES = 15193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_HID_SECURE_MODE_DISABLED = 15194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_MOTION_SEARCH_WHEN_ENCRYPTION_ENABLED = 15195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LIMITATIONS_WHEN_ENCRYPTION_ENABLED = 15196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERLAYS = 15197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADFS_USER_NO_MATCHING_GROUP = 15198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADFS_USER_EXISTS_IN_XNUMBER_GROUPS = 15199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADFS_USER_NO_CLAIMED_GROUPS = 15200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_ADFS_NO_USERNAME_CLAIM = 15201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUPPORTEDRESOLUTION_QVGA = 15202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIEW_ARCHIVER_STATISTICS = 15203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_FILTER_SELECTED = 15204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_CIM_SETTINGS_CHANGED = 15205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPUTER_NETWORK_TRAFFIC_IN = 15206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMPUTER_NETWORK_TRAFFIC_OUT = 15207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAIL_CAMERA_ANALYTIC_SETTINGS_CHANGED = 15208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_ARCHIVER_STATISTICS = 15209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ARCHIVER_STATISTICS = 15210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_CANNOT_ENROLL_ACCESSCONTROLUNIT_BECAUSE_DEFAULTPASSWORD = 15211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OLD_ROLE_CANNOT_LEVERAGE_DATABASE_ENCRYPTION = 15212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MOBILE_ROLE_GOOGLE_LICENSE_KEY = 15213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_MOBILE_PORT = 15214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ABOUT_TRUSTED_ROOT_CERTIFICATES = 15215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_WEB_CLIENT_PORT = 15216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_REMOVE_MOBILE_DEVICE_SC = 15217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_CONFIG_TOOL_NESTED_FEDERATIONS_NOT_SUPPORTED = 15218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOTO_DIGITALZOOM_PRESET = 15219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GOTO_PTZ_PRESET = 15220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_CPU_USAGE = 15221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_PROCESS_MEMORY = 15222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MACRO_STARTED = 15223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MACRO_ABORTED = 15224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILED_PRINT_DASHBOARD = 15225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_BULK_APPLY_THREAT_LEVEL_AREAS = 15226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_APPLICATION_CONFIGURATION = 15227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_HOTLIST_CONFIGURATION = 15228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_OVERTIME_RULE_CONFIGURATION = 15229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_PERMIT_CONFIGURATION = 15230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_ANNOTATION_FIELDS_CONFIGURATION = 15231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_APPLICATION_CONFIGURATION = 15232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_HOTLIST_CONFIGURATION = 15233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_OVERTIME_RULE_CONFIGURATION = 15234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_PERMIT_CONFIGURATION = 15235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_ANNOTATION_FIELDS_CONFIGURATION = 15236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATABASE_SECURITY = 15237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_LOGS_FOLDER_HAS_MOVED = 15238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_REMOVE_UNIT_CERTIFICATE_HID = 15239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_SUCCESS = 15240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_FAILURE = 15241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_DECRYPTION_FAILURE = 15242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_ENCRYPTION_FAILURE = 15243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_NO_PRIVATE_KEY = 15244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_SIGN_ONLY = 15245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_UNKNOWN_FAILURE = 15246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_OFFLINE_MOBILE_USERS = 15247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_ACTIVATION_FAIL = 15248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_ACTIVATION_FAIL_MISSING = 15249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_ACTIVATION_FAIL_NOT_FOUND = 15250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_TOKEN_GENERATION_FAIL = 15251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_TOKEN_GENERATION_FAIL_MISSING = 15252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_TOKEN_GENERATION_FAIL_NOT_FOUND = 15253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_TOKEN_GENERATION_FAIL_INVALID = 15254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_GPNS_ACTIVATION_FAIL_INVALID = 15255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_FEATURE_ALARMS = 15256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_FEATURE_LPR = 15257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_ALARMS = 15258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_PUSH_NOTIFICATIONS = 15259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_DEVICE_CAMERA_STREAMING = 15260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_VIDEO = 15261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_LPR = 15262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_FEATURE_VIDEO_STREAM_MAX_SEQUENCE_LENGTH = 15263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INDETERMINATE = 15264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENING_CATEGORY = 15265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENING_ENTRY = 15266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENING_STATUS = 15267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAUSED_BY = 15268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_STORE_LOCATION = 15269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_STORE_CURRENTUSER = 15270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CERTIFICATE_VALIDATOR_STORE_LOCALMACHINE = 15271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELSERVER_CREATE_FEDERATION_FOR_NEW_TUNNEL_QUESTION = 15272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NUMBEROFMEMBERS = 15273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_ENTITIES_SELECTED = 15274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALIDWINDOWSFILENAME = 15275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEAK_ADMIN_PASSWORD = 15276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_AUDITTRAIL_REMOTEUSER_THROUGH_FEDUSER = 15277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_SHARP_PASSWORD = 15278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CONFIGURE_HTTPS_CONNECTION_SHARPV = 15279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CHANGE_DEFAULT_SHARPX_PASSWORD = 15280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_CONFIGURE_HTTPS_CONNECTION_SHARPX = 15281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_RESTRICT_ACCESS_AUTOVU = 15282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_NETWORK_LOCATION = 15283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ENCRYPT_COMMUNICATION_PATROLLER_AND_SC = 15284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ENCRYPT_GENETEC_PATROLLER_DATABASE = 15285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_RESTRICT_ACCESS_GENETEC_PATROLLER_WORKSTATION = 15286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_SELECT_GENETEC_PATROLLER_LOGON_TYPE = 15287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DISABLE_SIMPLE_HOST_FUNCTIONALITY = 15288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_FLOORS = 15289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NAVIGATE_TO_PARENT = 15290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOOMANYRESULTS = 15291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOACTIONSAVAILABLE = 15292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEVICE_CAMERA_STREAMING_SETTINGS = 15293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PORT_START_INDEX = 15294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PORT_STOP_INDEX = 15295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_REVOKE_PERMISSION_STORED_PROCEDURE = 15296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DB_CONNECT_WINDOWS_AUTHENTICATION = 15297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_LIMITING_CONCURRENT_LOGONS = 15298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_SCRAMBLE_ENTITY_NAMES = 15299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TECHDOC_HUB = 15300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_ON = 15301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_BLACKLIST_MOBILE_DEVICE = 15302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_SECTION_LPR = 15303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNABLE_TO_GET_VIDEO_DISPLAY_INFORMATION = 15304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACTIONREQUEST_BACKWARD_NOT_SUPPORTED = 15305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACTIONREQUEST_NO_REGISTRATION = 15306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACTIONREQUEST_DENIED = 15307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACTIONREQUEST_FAILED = 15308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACTIONREQUEST_INACCESSIBLE = 15309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CANCEL_WIZARD = 15310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ACTIONREQUEST_RECIPIENT_OFFLINE = 15311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_VALIDATOR_SUCCESS_BACKWARD_FAILURE = 15312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_SECURITYDESK_OPTIONS_USERINTERACTION_ASKVISITORCHECKOUT = 15313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MSMQ_NOT_RUNNING_ON_SERVER_X = 15314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MOBILELOCALDB = 15315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_UNKNOWN_ENTRIES = 15316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_UNKNOWN_ENTRY = 15317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPOSURE_ZONE = 15318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_INVALID_EXPOSURE_ZONE = 15319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_REFRESH_STATUS = 15320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USER_MANAGEMENT = 15321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ACCESS_CONTROL = 15322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_LPM_PROTOCOL_TO_CONNECT_SHARPV = 15323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOTE_SYSTEM = 15324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SECURITYCONFIG_FIXCONFIGURATIONPROBLEM = 15325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GRANT_PARTITION_ACCESS = 15326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_REMOVE_PRIVILEGE = 15327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_FIXCONFIGURATIONPROBLEM = 15328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_PRIVILEGECONFIG_PROBLEMATICPARTITIONACCESS = 15329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_USERS_CANCONFIGURESECURITY = 15330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_USERGROUPS_CANCONFIGURESECURITY = 15331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACCESSRIGHTSCONFIG_PROBLEMATICPARTITIONACCESS = 15332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_UNIT_TIME_OUT_OF_SYNC_RECOVERED = 15333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_UNIT_TIME_OUT_OF_SYNC = 15334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TUNNELCLIENT_DISCONNECTED_FAILED_TO_CONNECT_TO_ADDRESSES = 15335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITORING_WIDGET = 15336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITORING_WIDGET_DESCRIPTION = 15337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITORING_WIDGET_MODE = 15338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONITORING_WIDGET_ALLOW_DISPLAY_MODE_TOGGLE = 15339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEND_TO_TILES = 15340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AREA_ZOOM = 15341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EDIT_WIDGET = 15342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALLOW1 = 15343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALLOW2 = 15344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALLOW3 = 15345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALLOW4 = 15346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALHIGH1 = 15347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALHIGH2 = 15348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALHIGH3 = 15349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_NORMALHIGH4 = 15350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVELOW1 = 15351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVELOW2 = 15352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVELOW3 = 15353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVELOW4 = 15354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVEHIGH1 = 15355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVEHIGH2 = 15356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVEHIGH3 = 15357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAIL_CUSTOM_SUPERVISED_INPUT_VALUES_ACTIVEHIGH4 = 15358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_WATERMARKING_OVERLAY = 15359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_STATUS = 15360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_UPGRADE_REVERT = 15361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_UPGRADE_TO = 15362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_RECOMMENDED_IS = 15363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_UNIT_UP_TO_DATE = 15364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FACTORY_RESET_REQUIRED = 15365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FACTORY_RESET_REQUIRED = 15366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_FIRMWARE_READ_RELEASE_NOTES = 15367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUS_DOWNLOAD_ERROR = 15368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_FIRMWARE_UPGRADE_NOW = 15369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_CANNOT_MOVE_ACCESSCONTROLUNIT_SAAS = 15370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GUS_DISABLED = 15371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEDIA_PROCESSORS = 15372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CANNOT_DELETE_ARCHIVER = 15373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORKSTATION = 15374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_NAME = 15375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERLAY_TYPE_SINGLE = 15376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERLAY_TYPE_MOSAIC = 15377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_CATEGORY_DIAGONAL = 15378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_VIDEO_WATERMARKING_OVERLAY = 15379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_CENTER_LEFT = 15380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_CENTER_RIGHT = 15381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_TOP_CENTER = 15382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POSITION_BOTTOM_CENTER = 15383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CREDENTIAL_MISSING_CARD_FORMAT = 15384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AFTER_WIZARD_COMPLETED = 15385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANAGE_CARDHOLDERS = 15386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANAGE_CARDHOLDERS_DESCRIPTION = 15387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANAGE_CAMERAS = 15388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANAGE_CAMERAS_DESCRIPTION = 15389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_WIDGET_TOOMANYRESULTS_HEADER = 15390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPMANAGER_ROLE_OFFLINE = 15391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_GENERAL_SETTINGS_GROUP = 15392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_LPR_GENERAL_SETTINGS_GROUP = 15393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_ONLINE = 15394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_WATERMARK_INVALID_CONFIG = 15395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MSMQ_SLOW_QUEUE_ON_SERVER_X = 15396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ARCHIVER_MSMQ_ERRORS = 15397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_CHAR_IN_ARCHIVE_BACKUP_PATH = 15398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOMORROW = 15399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DURING_THE_NEXT_X_Y = 15400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CANNOT_RETRIEVE_TILE_SERVER = 15401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADDSELECTED = 15402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_COPY_MAP_OBJECT_DATA = 15403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTENANCE_ENABLED = 15404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERTIFICATE_VALIDATOR_EXPIRED = 15405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_VALIDATOR_EXPIRING_SOON = 15406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERTIFICATE_VALIDATOR_NOT_VALID_YET = 15407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_ACCESS_RIGHTS = 15408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_MODIFY_ACCESS_RIGHTS = 15409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_USERS_CANEDITPRIVILEGES = 15410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_USERGROUPS_CANEDITPRIVILEGES = 15411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_USERS_CANEDITACCESSRIGHTS = 15412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_USERGROUPS_CANEDITACCESSRIGHTS = 15413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_REQUIRES_AT_LEAST_ONE_USER = 15414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DIAGNOSE_ROLE = 15415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CARDHOLDER_DUPLICATE_LICENCE_PLATE = 15416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IP_ADDRESS_PORT = 15417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTENANCE_MODE_MANUALLY = 15418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_STATE_FILTER = 15419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_WIDGET_DESCRIPTION = 15420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARM_WIDGET_NAME = 15421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARVESTER_WHAT_TO_COLLECT = 15422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CONNECTION_LOST_WITH_ARCHIVER = 15423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CONNECTION_ESTABLISHED_WITH_ARCHIVER = 15424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_INTERFACE_ONLINE = 15425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_INTERFACE_OFFLINE = 15426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADAPTIVE_MOTION_SETTINGS = 15427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ANALYTICS_MOTION_ANOMALY_THRESHOLD = 15428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ANALYTICS_MOTION_ANOMALY_HISTORY_WEIGHT = 15429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ANALYTICS_MOTION_ANOMALY_COMPUTE_WINDOW = 15430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_ABNORMAL_MOTION_THRESHOLD = 15431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_ABNORMAL_MOTION_TEST_WINDOW = 15432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_ABNORMAL_MOTION_COMPUTE_SAMPLES = 15433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_ABNORMAL_MOTION_HISTORY_WEIGHT = 15434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_ENCRYPT_DATA_OVER_TUNNEL = 15435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_ENCRYPT_ENFORCED = 15436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_ENCRYPT_PREFERRED = 15437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_ENCRYPT_DISABLED = 15438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_STATUS_INCOMPATIBLE_ENCRYPTION = 15439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_ENCRYPTION_STATUS = 15440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVERSETUNNEL_ENCRYPTION_STATUS_DESCRIPTION = 15441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ARCHIVER_INVALID_ENCRYPTION = 15442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DIAGNOSTIC_ENTITY_X_IS_OFFLINE = 15443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENGINEERING_BUILD = 15444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_USERLOCKED_X_SECONDS = 15445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PROXYRETURNCODE_USERLOCKED_X_MINUTES = 15446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PUBLIC_KEY = 15447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOCK_SENSOR = 15448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_DOORSECURED = 15449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_DOORUNSECURED = 15450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_MODIFY_CARDHOLDER_SECURITY_CLEARANCE = 15451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_MODIFY_CARDHOLDER_GROUP_SECURITY_CLEARANCE = 15452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDER_SECURITY_CLEARANCE = 15453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CARDHOLDER_GROUP_SECURITY_CLEARANCE = 15454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PARENT_UNIT = 15455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_CONTROLLERS = 15456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_MODULES = 15457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNKNOWN_FIRMWARE_DETECTED = 15458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_READ_BEFORE_UPGRADE = 15459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNKNOWN_FIRMWARE_DETECTED = 15460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FILE_WATERMARKED_WITH_OBSOLETE_ALGORITHM = 15461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPY_WATERMARKINGORIGIN_TO_CLIPBOARD = 15462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_WATERMARK_NOT_SUPPORTED_BY_ALL_SEQUENCES = 15463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_WATERMARKING_TYPE_NOT_SUPPORTED = 15464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_ARCHIVED_STREAM = 15465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_UC_ARCHIVED_STREAMS = 15466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_ARCHIVED_STREAMS = 15467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FIELDSREQUIREATTENTION = 15468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FILLREQUIREDFIELDS = 15469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_RECORDING_ISSUE = 15470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ENCODER_COMMAND_PANASONIC_PRIVACYMODE_ON = 15471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ENCODER_COMMAND_PANASONIC_PRIVACYMODE_OFF = 15472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVABLE_CAMERAS = 15473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_NOEXTENSION = 15474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_GRID = 15475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_VALUES = 15476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXCLUDE = 15477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FAILOVER_LICENSE_SERVERS_DOWN = 15478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CAMERASTOINPUT = 15479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DEVICES_ALREADY_ASSIGNED = 15480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ADD_UNIT_API_UNSUPPORTED = 15481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_TRAY_INTRUSIONOVERVIEW = 15482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INTRUSION_DETECTION = 15483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_DISARMEDNOTREADY = 15484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_DISARMEDREADY = 15485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_ARMING = 15486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_PERIMETERARMED = 15487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_MASTERARMED = 15488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_ALARMACTIVE = 15489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_ALARMSILENCED = 15490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATE_INTRUSIONAREA_ENTRYDELAY = 15491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATECATEGORY_INTRUSIONAREA_DISARMED = 15492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATECATEGORY_INTRUSIONAREA_ARMED = 15493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAPSTATECATEGORY_INTRUSIONAREA_ALARMS = 15494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_LICENSEPLATE_CONTAINSWHITESPACE = 15495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_LICENSEPLATE_TOOLONG = 15496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKUP_CONFIGURATION = 15497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATION_LOOP = 15498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_CONTACT = 15499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_UP_CONTACTS = 15500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_LC_CONTACTS = 15501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWCONTACTS = 15502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_CONTACTS = 15503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFYCONTACTS = 15504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_CONTACTS = 15505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_CONTACT = 15506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_CONTACT = 15507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CONTACT = 15508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CONTACT = 15509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CREATE_FEDROLE_FOR_TUNNEL_SITE = 15510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNSECURED = 15511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOVE_INTERFACEMODULE_TO_OTHER_UNIT = 15512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MOVE_INTERFACEMODULE_SELECTDESTINATIONUNIT_DIALOG = 15513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_READ_MATCHING_MAX_RESULTS = 15514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_READ_MATCHING_ENABLE_HIGH_RESOLUTION_IMAGES = 15515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_READ_MATCHING_QUERY_INTERVAL_TIMESPAN = 15516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_READ_MATCHING_SELECTED_ENTITIES = 15517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CHANGEUNITPASSWORD = 15518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BUTTON_GENERATEPASSWORD = 15519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PASSWORDCHANGED = 15520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PASSWORDNOTACCEPTED = 15521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CHECKIFUNITONLINE = 15522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CHANGEPASSWORDUNSECURECONNECTION = 15523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BUTTON_APPLYTOCONTINUE = 15524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BUTTON_UARMUSTBEONLINE = 15525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BUTTON_UNITMUSTBEONLINE = 15526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MSGBOX_MIGHTLOSEUNITCONNECTION = 15527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MSGBOX_MIGHTLOSEUNITCONNECTION_DETAILS = 15528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LENGTH_EXACTLYXCHARACTERS = 15529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LENGTH_BETWEENXANDYCHARACTERS = 15530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LENGTH_MINXCHARACTERS = 15531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LENGTH_MAXXCHARACTERS = 15532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_CUSTOM_EXACTLYXCHARACTERS = 15533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_CUSTOM_BETWEENXANDYCHARACTERS = 15534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_CUSTOM_MINXCHARACTERS = 15535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_CUSTOM_CANNOTCONTAIN = 15536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_CUSTOM_MAXXCHARACTERS = 15537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LOWERCASE_EXACTLYXCHARACTERS = 15538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LOWERCASE_BETWEENXANDYCHARACTERS = 15539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LOWERCASE_MINXCHARACTERS = 15540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LOWERCASE_CANNOTCONTAIN = 15541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_LOWERCASE_MAXXCHARACTERS = 15542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_DIGIT_EXACTLYXCHARACTERS = 15543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_DIGIT_BETWEENXANDYCHARACTERS = 15544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_DIGIT_MINXCHARACTERS = 15545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_DIGIT_CANNOTCONTAIN = 15546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_DIGIT_MAXXCHARACTERS = 15547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_SPECIAL_EXACTLYXCHARACTERS = 15548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_SPECIAL_BETWEENXANDYCHARACTERS = 15549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_SPECIAL_MINXCHARACTERS = 15550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_SPECIAL_CANNOTCONTAIN = 15551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_SPECIAL_MAXXCHARACTERS = 15552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_UPPERCASE_EXACTLYXCHARACTERS = 15553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_UPPERCASE_BETWEENXANDYCHARACTERS = 15554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_UPPERCASE_MINXCHARACTERS = 15555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_UPPERCASE_CANNOTCONTAIN = 15556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_UPPERCASE_MAXXCHARACTERS = 15557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_FAILURELOSTPASSWORDTITLE = 15558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_FAILURELOSTPASSWORDDESCRIPTION = 15559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_FAILURERECOVERYTITLE = 15560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_FAILURERECOVERYDESCRIPTION = 15561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_FAILUREOLDPASSWORDTITLE = 15562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_FAILUREOLDPASSWORDDESCRIPTION = 15563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_EDGESTORAGE_RECOVERED = 15564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_EDGESTORAGE_RECORDING_FAILURE = 15565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_EDGESTORAGE_RECORDING_RECOVERED = 15566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_EDGESTORAGE_FAILURE = 15567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARMING_STATE_PERIMETER = 15568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNKNOWNCUSTOMFIELDVALUENOTUNIQUE = 15569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_WEBSDK_VIEWPRIVILEGE_ROLENOTLOADEDYET = 15570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ARCHIVER_TRICKLING_RETRY = 15571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_HEALTHEVENT_ARCHIVER_TRICKLING_PARTIALLY_RETRY = 15572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_ROLE_BACKWARD_COMPATIBILITY = 15573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MAP_ROLE_BACKWARD_COMPATIBILITY = 15574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_DELETES_SUPPRESSED = 15575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_GCS_DELETES_SUPPRESSED_DETAILS = 15576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ARCHIVER_TRICKLING_CANCELLED = 15577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ARCHIVER_TRICKLING_CANCEL_FOR_ONE_CAMERA = 15578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ARCHIVER_TRICKLING_RETRY = 15579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ARCHIVER_TRICKLING_CANCEL = 15580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_COPYCONFIG_CREDENTIALS = 15581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_FILTER_TXTFILES_CSVFILES_DATFILES = 15582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_LOCKSETS = 15583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_READERS = 15584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_LAST_ERROR_TIMESTAMP = 15585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARVESTER_PERFORMANCE_WARNING = 15586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_NO_PARKING_RULE_ASSIGNED = 15587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MUSTERING = 15588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_DESCRIPTION_MUSTERING = 15589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DELETE_OFFICER = 15590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_LABEL_PRIVILEGES_FORCEACKNOWLEDGEALARMS = 15591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LASTING_MORE_THAN = 15592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LASTING_MORE_THAN_X_Y = 15593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_CARDHOLDERMOBILEPHONE = 15594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSGRANT_CONFIRMATION_REQUESTED = 15595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_DENIED_CONFIRMATIONTIMEOUT = 15596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_GRANTCONFIRMATION_TIMEOUT = 15597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_PASSAGECOUNTEXCEEDED = 15598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_DENIED_PASSAGECOUNTEXCEEDED = 15599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONNECTED_DIRECTORY = 15600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_CONFIRMATION = 15601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_DIAGNOSTIC_TITLE_ARCHIVERSERVICECONNECTIONFAILED = 15602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_DIAGNOSTIC_DETAILS_ARCHIVERSERVICECONNECTIONFAILED = 15603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_READS_MATCHING = 15604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EVIDENCE_READY = 15605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_DESCRIPTION_EVIDENCE_READY = 15606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_FEDERATED_ENTITIES = 15607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_VIDEOWATERMARKING_WORKSTATION_LIMITATION = 15608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSOCIATED_ARCHIVERS = 15609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_DIAGNOSTIC_TITLE_ENTITIESHAVEDIAGNOSTIC = 15610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_DIAGNOSTIC_DETAILS_ENTITIESHAVEDIAGNOSTIC = 15611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CIM = 15612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CIM_WIDGET_DESCRIPTION = 15613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVIEW_VIEW = 15614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERAS_MONITORED = 15615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAMPERING_DETECTED = 15616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_TAMPERING_DETECTED = 15617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOUN_REFERENCE = 15618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADJECTIVE_CURRENT = 15619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLICK_TO_LOAD = 15620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_THUMBNAIL = 15621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_EDIT_IN_CONFIGTOOL = 15622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_VIEW_IN_MONITORING = 15623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_RESET_CAMERAS = 15624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RESET_CAMERA = 15625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RESET_ALL_CAMERAS = 15626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_READ_MATCHING_EXCLUDED_ENTITIES = 15627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DISMISS_HEALTH_EVENTS = 15628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DISMISS_HEALTH_EVENTS = 15629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_DISMISS_HEALTH_EVENT = 15630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ORDER_ALARM_MONITORING_BY_LATEST = 15631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_UPDATE_PASSWORD = 15632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UNIT_UPDATE_PASSWORD = 15633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UNIT_UPDATE_PASSWORD = 15634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_DISMISSED_EVENT = 15635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASSWORD_STRENGTH = 15636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NOBADGETEMPLATE = 15637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_UNIT_UPDATE_PASSWORD = 15638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_PASSWORD_UPDATED = 15639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASE_BACKUP_BEFORE_PASSWORD_CHANGE = 15640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NONLOCALENTITY = 15641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_THEOFFICERISACTIVE = 15642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_THEOFFICERISINACTIVE = 15643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_THEOFFICERISUNLICENSED = 15644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_YOUAREGOINGTODEACTIVATEOFFICER = 15645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_INACTIVEOFFICERSCANNOTUPLOADNEWEVIDENCE = 15646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACAMERAISSTILLLINKEDTOOFFICER = 15647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_QUESTION_DOYOUWANTTOUNLINKTHECAMERA = 15648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CAMERASLINKEDTOANINACTIVEOFFICERCANNOTUPLOAD = 15649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLUGIN_TYPE = 15650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SOURCE_AND_DESTINATION_ROLES_HAVE_DIFFERENT_CONFIGS = 15651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_PASSWORDS = 15652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_PASSWORD = 15653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_POLICY_SPECIAL_CONTAINEDINLIST = 15654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CONFIRM_USER_PASSWORD_EXPORT_FILE = 15655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALIDATION = 15656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INCORRECT_USER_PASSWORD = 15657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_CREDENTIAL_CONFIGURATION = 15658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RENAME_CREDENTIAL_CONFIGURATION = 15659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UNIT_VIEW_EXPORT_PASSWORDS = 15660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_UNIT_VIEW_EXPORT_PASSWORDS = 15661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVER_DIAGNOSTIC = 15662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DIAGNOSTIC_FAILED = 15663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DIAGNOSTIC_PARTIALLY_SUCCEEDED = 15664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DIAGNOSTIC_COMPLETED_SUCCESSFULLY = 15665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_UNIT_PASSWORDS_EXPORTED = 15666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIT_PASSWORDS_EXPORTED_DESCRIPTION = 15667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MOBILE_CREDENTIAL_TRANSFER_NO_CARDHOLDER = 15668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_UNIT_INVENTORY = 15669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_CONTROL_UNIT_INVENTORY = 15670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_UNIT_PASSWORD_CHANGED = 15671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UNIT_PASSWORD_CHANGED_MANUAL_DESCRIPTION = 15672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UNIT_PASSWORD_CHANGED_GENERATED_DESCRIPTION = 15673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DIFFERENT_USER_IS_NEEDED_FOR_AUTHORIZATION = 15674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_VAULT_AUTOMATIC_CLEANUP_OPTION = 15675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATUREDESCRIPTION_UNITASSISTANT = 15676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNITASSISTANT = 15677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNITASSISTANT_TRACEROUTE = 15678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATUREDESCRIPTION_UNITASSISTANT_TRACEROUTE = 15679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_VALIDATE_SAAS_CLOUDLINK_LOCALIP = 15680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_IN_TIME = 15681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_OUT_TIME = 15682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TOTAL_TIME_PRESENT = 15683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_SINCE_FIRST_ENTRY = 15684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CIM = 15685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CIM = 15686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CIM_MISSING_PRIVILEGE = 15687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GROSS_TOTAL_TIME = 15688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_EXPORT_VIDEO_REQUESTED_AUTHORIZED = 15689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOSE_MULTIPLE_PARKING_SESSIONS_CONFIRMATION = 15690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOSE_SINGLE_PARKING_SESSION_CONFIRMATION = 15691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_MAP_ROLE_DISABLING_BACKWARD = 15692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_BWCAGENTPORTALREADYUSED = 15693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_BWCAGENTHASFAILOVER = 15694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_BWCAGENTNOTCOMPATIBLE = 15695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_BWCAGENTCANNOTSTART = 15696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_BWCROLECANNOTHAVEFAILOVE = 15697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_NOASSOCIATEDARCHIVERSRUNNING = 15698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIGN_IN_WITH = 15699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_CLOSE_PARKING_SESSIONS = 15700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_CLOSED_MANUALLY = 15701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_PARKING_SESSION_COMPLETE_MANUAL_CLOSURE = 15702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION = 15703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_CORRELATION = 15704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CORRELATION_REPORT = 15705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_SHORT_DESC_CORRELATION_REPORT = 15706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPES = 15707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_SOURCES = 15708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_SOURCE = 15709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_SEARCH = 15710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLEAR_SEARCH = 15711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_CORRELATION_MANAGER_NAME = 15712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_CORRELATION_MANAGER_DESCRIPTION = 15713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CORRELATION_RULE_HIT = 15714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_RULE_TRIGGERING_TYPE = 15715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_RULE_ROOT_CONDITION = 15716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_CORRELATION_RULE_TRIGGER_CONDITIONS = 15717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_CORRELATION_RULE_ACTIONS = 15718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_NAME_CORRELATION_RULE = 15719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_CORRELATION_ALERT = 15720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_CORRELATION_RULE_HIT_EVENT = 15721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_ALARM_DESCRIPTION = 15722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CORRELATION_DATA_SOURCES_GROUP = 15723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CORRELATION_GROUP = 15724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CORRELATION_VIEW_RULES = 15725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CORRELATION_ADD_RULES = 15726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CORRELATION_REMOVE_RULES = 15727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CORRELATION_MODIFY_RULES = 15728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_GROUP = 15729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_VIEW_RULES = 15730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_ADD_RULES = 15731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_REMOVE_RULES = 15732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_MODIFY_RULES = 15733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SERVER_AUTHENTICATION_FAILED = 15734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_INVALID_LICENSE = 15735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACTIVE_DIRECTORY_LINK_PROPERTIES = 15736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SHARED_PARTITION_CANNOTADD = 15737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SHARED_PARTITION_PARTIALADD_XY = 15738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ADDELIGIBLEENTITIES = 15739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ADD_UNIT_USER_INSUFFICIENT_PERMISSIONS = 15740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_NETWORK_SETTINGS_COMBINATION = 15741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SET_DATE = 15742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ACTIVEDIRECTORY_SLOW_LOGIN = 15743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_PASSWORD = 15744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTHORIZATION = 15745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UNIT_PASSWORD_REQUEST_SENT = 15746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNITASSISTANT_RESTART = 15747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_VALIDATION_REQUESTED = 15748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_VALIDATION_SUCCEEDED = 15749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_BIOMETRIC_MATCH = 15750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_IDENTITY_BIOMETRIC_MATCH = 15751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_SUCCEEDED_OPERATOR_CONFIRMATION = 15752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_IDENTITY_OPERATOR_CONFIRMATION = 15753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_VALIDATION_FAILED = 15754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_FAILED_OPERATOR_CONFIRMATION = 15755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_BIOMETRIC_MISMATCH = 15756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_IDENTITY_BIOMETRIC_MISMATCH = 15757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_BIOMETRIC_MISSING = 15758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_IDENTITY_BIOMETRIC_MISSING = 15759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ACCESSPOINT_IDENTITY_TIMEOUT = 15760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_IDENTITY_TIMEOUT = 15761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_SINGLE_PERSON_CHECK_FAILED = 15762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_NO_PRESENCE_DETECTED = 15763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_AREA_NO_PRESENCE_DETECTED = 15764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_AREA_TOO_MANY_PEOPLE = 15765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_NAME_AREA_TOO_MANY_PEOPLE = 15766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_REX = 15767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_UNLOCK_POSTPONED = 15768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_UNLOCKED = 15769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_UNLOCKED_EXTENSION_GUARD = 15770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_UNLOCKED_EXTENSION_GUARD = 15771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_UNLOCKED_MAXIMUM_TIME = 15772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_UNLOCKED_MAXIMUM_TIME = 15773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_UNLOCKED_OPERATOR = 15774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_OPERATOR = 15775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_UNLOCKED_RELEASE_DELAY = 15776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_UNLOCKED_RELEASE_DELAY = 15777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_UNLOCKED_HOST_TIMEOUT = 15778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_UNLOCKED_HOST_TIMEOUT = 15779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_RESET = 15780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_RESET_AUTOMATIC = 15781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_RESET_AUTOMATIC = 15782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_RESET_HARDWARE = 15783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_RESET_HARDWARE = 15784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EMERGENCY_DOOR_RESET_OPERATOR = 15785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ITEMS_CREATED = 15786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ITEM_CREATED = 15787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ITEMS_CHANGED = 15788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ITEM_CHANGED = 15789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ITEMS_REMOVED = 15790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ITEM_REMOVED = 15791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_BLOCK = 15792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_NAME_ATTRIBUTE = 15793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MAP_AUTOCAD_SYNCHRONIZE_ENTITIES = 15794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MAP_AUTOCAD_SYNCHRONIZE_ENTITIES_OPTIONAL = 15795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_REMOVED_ITEMS = 15796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MAP_AUTOCAD_SELECT_MAP = 15797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD = 15798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_MAP_AUTOCAD_BLOCK = 15799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_MAP_AUTOCAD_NAME_ATTRIBUTE = 15800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_MAP_AUTOCAD_LATITUDE_ATTRIBUTE = 15801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_MAP_AUTOCAD_LONGITUDE_ATTRIBUTE = 15802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_NO_MAPPINGS_FOUND = 15803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MAP_AUTOCAD_ASSOCIATE_BLOCK = 15804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ENTITY_STATUS_UNMODIFIED = 15805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ENTITY_STATUS_TOBECREATED = 15806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ENTITY_STATUS_TOBECHANGED = 15807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_AUTOCAD_ENTITY_STATUS_TOBEREMOVED = 15808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MAP_AUTOCAD_ITEM_TOBEREMOVED_X = 15809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MAP_AUTOCAD_ITEMS_TOBEREMOVED_X = 15810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DUPLICATEDPIN = 15811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_PROFILE_SELECTION = 15812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CARDSCANNER_REQUIRES_VC2008SP1RUNTIME = 15813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEASUREMENT_UNITS = 15814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUFFIX_WEEKS = 15815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOURCE_SYSTEM = 15816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOFENCE_AREAS = 15817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTING = 15818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MUST_BE_ADMIN_TOOL = 15819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_FAILED = 15820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SAML2_INTEGRATION_COUNT = 15821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_OPENID_INTEGRATION_COUNT = 15822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERB_REQUIRES_VC2008SP1RUNTIME = 15823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_DISPLAY_NAME = 15824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_ISSUER = 15825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_DOMAIN_NAMES = 15826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_CLIENT_ID = 15827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_RESOURCE_ID = 15828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_AUDIENCE = 15829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_SCOPES = 15830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_REDIRECT_URI = 15831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_USERNAME_CLAIM = 15832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_GROUP_CLAIM = 15833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_METADATA_URL = 15834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_ACS_URL = 15835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_USER_ASSERTION = 15836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_GROUP_ASSERTION = 15837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_SETTINGS = 15838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_IDP = 15839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_PROTOCOL = 15840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_METADATA_MISSING_HTTPPOST = 15841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_METADATA_NOTSUPPORTED_WANTAUTHNSIGNED = 15842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_ACS_MISSINGCHARACTER = 15843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SSO_URI_VALIDATION = 15844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_ENROLLMENT_IMPORTED_GROUP_NAME = 15845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_ENROLLMENT_IMPORTED_GROUP_INTERNAL_NAME = 15846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_ENROLLMENT_SUMMARY = 15847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_ENABLE_DATA_TYPE_SECURITY = 15848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CORRELATION_ENABLE_DATA_TYPE_SECURITY = 15849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_REGISTRATION_HEARTBEAT = 15850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_NUMBER_OF_QUERIES_PER_RULES_ENGINE = 15851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_QUERY_TIMEOUT = 15852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_QUERY_EXTENDED_TIMEOUT = 15853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_ENABLE_V1_EVENTS = 15854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_V1_CUSTOM_EVENT_ID = 15855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_V1_EVENT_DESCRIPTION_FORMAT = 15856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_V1_EVENT_DESCRIPTION_FORMAT_ALT = 15857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_ADDRESS_NOT_AVAILABLE = 15858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_HTML_EMAIL_IMAGE_UNAVAILABLE = 15859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_HTML_EMAIL_WEBSITE_LINK = 15860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_HTML_EMAIL_SOUND = 15861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_HTML_EMAIL_UNKNOWN = 15862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_RULE_HIT_DEFAULT_MESSAGE = 15863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_DATA_SOURCE_PLURAL_ENDING = 15864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_SELECTED_N_ENTITIES = 15865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_NOT_EQUAL_TO = 15866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_LIKE = 15867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_NOT_IN = 15868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MATCHES_PATTERN = 15869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_REGION = 15870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_CORRELATION_RULE_VARIABLE_TEXT = 15871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_TIMES = 15872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_SOURCES = 15873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CORRELATION_CHANGING_TRIGGER_DATUM = 15874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_SEARCH_CONDITION = 15875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REMOVE_CONDITION = 15876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ASSIGN_VARIABLE = 15877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN_SEEN = 15878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_CHILD_CONDITION = 15879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REMOVE_BLOCK = 15880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ADD_FILTER_ON_TRIGGER = 15881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALUE_CONDITION = 15882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGICAL_CONDITION = 15883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONDITION = 15884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHERE = 15885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMOVE_VARIABLE_ASSIGNMENT = 15886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGER_ID = 15887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGER_TIME = 15888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRIGGER_TYPE = 15889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOGICAL_CONDITION_OF_TYPE = 15890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOG_CORRELATION_RULE_DIAGNOSTICS = 15891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOG_DIAGNOSTICS_CUSTOM_ACTION_NAME = 15892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_CLONED_CORRELATION_RULE_NAME = 15893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLONE_CORRELATION_RULE = 15894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_FILE_FILTER_ALL_FILES = 15895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_FILE_FILTER_ZIP_FILES = 15896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_CORRELATION_RULE = 15897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_CORRELATION_RULES = 15898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ALL_CORRELATION_RULES = 15899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTED_CORRELATION_RULE_DEFAULT_NAME = 15900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTED_CORRELATION_RULE_DEFAULT_DESCRIPTION = 15901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PATH_MUST_EXIST_ON_CORRELATION_SERVER = 15902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_CORRELATION_FILE_PATH = 15903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_RULE_ACTION_AUTOMATICALLY_ATTACH_ENTITIES = 15904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SEND_ENTITIES_TO_APPLICATION = 15905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATIC_DISPATCH = 15906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_AUTOMATIC_DISPATCH = 15907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXPORT_FILES = 15908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGER_CUSTOM_EVENT = 15909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGER_CORRELATION_HIT_EVENT = 15910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGER_CUSTOM_EVENT_DESCRIPTION_FMT = 15911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BOOKMARK_CAMERAS_DESCRIPTION_FMT = 15912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_BOOKMARK_CAMERAS_DESCRIPTION_FMT_DEFAULT = 15913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_EXPORT_FILE_TO = 15914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SEND_MESSAGE_DESCRIPTION_FMT = 15915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TRIGGER_ALARM_AUTOMATIC_DISPATCH_DESCRIPTION_FMT = 15916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PLAY = 15917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FREQUENCY = 15918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WITHIN_RANGE = 15919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WITHIN_TIME = 15920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_TYPE_FMT = 15921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_TEST_TAG = 15922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_TEST_SOURCE_ID = 15923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CORRELATION_SERVICES_NOT_AVAILABLE = 15924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_TEST_CORRELATION_RULE_FOUND_EVENTS = 15925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_TEST_CORRELATION_RULE_NO_HIT = 15926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATICALLY_ATTACH_CAMERAS = 15927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATICALLY_SEND_TO_RECIPIENTS = 15928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIME_OF_SIMULATION = 15929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CLICK_PIN_TO_SELECT_LOCATION = 15930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_RULE_SIMULATION = 15931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DOUBLE_CLICK_TO_UPDATE = 15932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_DATA_SOURCES_GROUP = 15933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILES_OLDER_THAN = 15934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CLOSE_PARKING_SESSIONS = 15935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCHEDULED = 15936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_CREDENTIAL = 15937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_IMAGE_COMPRESSED_IF_CONTINUE = 15938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_OF_THE_FOLLOWING_OCCURS = 15939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYMBOL_GREATER_THAN = 15940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYMBOL_LESS_THAN = 15941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYMBOL_GREATER_THAN_OR_EQUAL_TO = 15942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYMBOL_LESS_THAN_OR_EQUAL_TO = 15943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYMBOL_NOT_EQUAL_TO = 15944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SYMBOL_EQUAL_TO = 15945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_IN = 15946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DETAILS = 15947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVIEW = 15948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_ERROR_DUPLICATEFRIENDLYNAME = 15949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_METADATA_ISSUER_NOTFOUND = 15950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_METADATA_AVAILABLE_ISSUERS = 15951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_ACCESS_TOKEN_CONFIG_ERROR = 15952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS = 15953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NAME_OF_RULE = 15954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_CONFIG_EXTERNAL_UNIQUE_ID = 15955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_SEAMILES_ABBREV = 15956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_SEAMILES = 15957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_FAILED_CHECK_DIAG = 15958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_PROBLEMS = 15959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COORDINATES = 15960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERATE_QR_CODE = 15961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAMERA_ID = 15962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ZONES_CONTROLLED_WITH_MULTIPLE_UNITS = 15963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DELETE_MAP_ITEMS_TO_CHANGE_SCALE = 15964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_TASK_SENT_TO_YOU = 15965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECTED_TEMPLATE = 15966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVAILABLE_TEMPLATES = 15967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_MOBILE = 15968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_MOBILE = 15969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_MOBILE_MOBILEFORWARDCAMERAS = 15970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_MOBILE_MOBILEFORWARDCAMERAS = 15971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_ACCESSCONTROL_DOORFORCEDOPENWITHMOTION = 15972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_ACCESSCONTROL_DOORFORCEDOPENWITHMOTION = 15973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_ACCESSCONTROL = 15974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_ACCESSCONTROL = 15975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_MOBILE_AUTOMATICDISPATCH = 15976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_MOBILE_AUTOMATICDISPATCH = 15977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_START_OF_DAY = 15978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONE_REGION = 15979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_N_REGIONS = 15980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PATTERN_MATCHING_OPTIONS = 15981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_CHARACTER = 15982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_DIGIT = 15983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_NON_DIGIT = 15984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_WORD_CHARACTER = 15985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ANY_NON_WORD_CHARACTER = 15986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZERO_OR_MORE_TIMES = 15987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZERO_OR_ONE_TIMES = 15988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONE_OR_MORE_TIMES = 15989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OCR_EQUIVALENTS = 15990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OCR_EQUIVALENTS_N_TIMES = 15991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_AUTOVU = 15992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_AUTOVU = 15993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_AUTOVU_ONTHEFLYHOTLIST = 15994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_AUTOVU_ONTHEFLYHOTLIST = 15995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_AUTOVU_SPECIFICHOTLIST = 15996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_AUTOVU_SPECIFICHOTLIST = 15997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_MOBILE_MOBILETHREATLEVEL = 15998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATION_TEMPLATE_DESCRIPTION_MOBILE_MOBILETHREATLEVEL = 15999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_ACCOUNTLOCKEDDURINGRECOVERYTITLE = 16000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_UNIT_DIAGNOSTIC_PASSWORDCHANGE_ACCOUNTLOCKEDDURINGRECOVERYDESCRIPTION = 16001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ACTIVEDIRECTORY_IMAGE_SYNC_COMPLETED = 16002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ACTIVEDIRECTORY_WARNING_DELTA_SYNC_SLOW = 16003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ACTIVEDIRECTORY_DELTA_SYNC_COMPLETED = 16004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SET_MINIMUM_SECURITY_CLEARANCE = 16005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SET_MINIMUM_SECURITY_CLEARANCE = 16006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_TARGET_ENTITIES_RESTART = 16007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_IMAGE_FAILED_TO_UPLOAD = 16008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_CAMERA_AUDIO = 16009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_SOUCRES_ONLINE = 16010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_FILTER_KMLFILES = 16011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_EVENTS_GROUP = 16012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIST_DATASOURCES_WARNING = 16013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REGISTERED_BY = 16014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_MAP_LOCATIONS_FOR = 16015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLING_THREAT_LEVEL_REQ_AREAS = 16016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVE_NORULER_DIFFERENTTIMEZONES = 16017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_MINIMUM_SECURITY_CLEARANCE_MODIFIED = 16018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_MINIMUM_SECURITY_CLEARANCE_CHANGED_TO = 16019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_COPYCONFIG_OPTION_WARNINGS_TOOLTIP = 16020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_EXPORT_REPORT_CONTAINS_FIRST_X_RESULTS = 16021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OHMS = 16022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERB_ACCESSRULE_GRANT = 16023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERB_ACCESSRULE_DENY = 16024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_VERB_ACCESSRULE_ADDDOORSELEVATORSAREAS = 16025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESSPOINT_SIDE_X = 16026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_UNIT_RENEW_CERTIFICATE = 16027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_ACCESSRULE = 16028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MOBILE_SPYMODE = 16029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MOBILE_SPYMODE = 16030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIDEO_UNIT_UPDATE_CERTIFICATE = 16031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIDEO_UNIT_UPDATE_CERTIFICATE = 16032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MOBILE_MANAGE = 16033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MOBILE_MANAGE = 16034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MENU_PEOPLE = 16035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_PROVIDER_CREDENTIAL_LICENSE_ERROR = 16036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSV_FILE = 16037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_COMPLETE = 16038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_ADDED = 16039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_UPDATED = 16040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_IGNORED = 16041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOUND_COLUMNS_AND_USER_GROUPS = 16042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_SKIPPED_DUPLICATE_EXTERNALID = 16043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_DETECTED = 16044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLUMN_USED_MORE_THAN_ONCE = 16045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERB_SHOWADVANCED = 16046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERB_HIDEADVANCED = 16047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ROWS_WITH_EMPTY_NAME_DETECTED = 16048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATURENAME_TRACEROUTE = 16049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FEATUREDESCRIPTION_TRACEROUTE = 16050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FEATUREINFO_TRACEROUTE = 16051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_TRACEROUTESETTINGS = 16052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACEROUTESETTING_EXECUTIONTIMEOUT = 16053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACEROUTESETTING_MAXCONCURRENCY = 16054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TRACEROUTESETTINGINFO_MAXCONCURRENCY = 16055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACEROUTESETTING_MAXHOPS = 16056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TRACEROUTESETTINGINFO_MAXHOPS = 16057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TRACEROUTESETTINGINFO_EXECUTIONTIMEOUT = 16058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNITASSISTANTMODULENAME_DIAGNOSTIC = 16059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUPS_ALREADY_UPTODATE = 16060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DUPLICATE_USERGROUPS_CREATED = 16061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ARCHIVE_TRANSFER_HISTORY = 16062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_USERINFO_ENDPOINT = 16063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ACCESSTOKEN = 16064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_CLIENT_SECRET = 16065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_CONFIDENTIAL_CLIENT = 16066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_OBTAIN_CLAIMS_FROM = 16067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_FAILED_TO_DECODE_ACCESS_TOKEN = 16068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_FAILED_TO_EXTRACT_GROUPS_FROM_TOKEN = 16069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ERROR_TOKEN_HAS_NO_CLAIM_OF_TYPE_X = 16070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_FAILED_TO_EXTRACT_USERNAME_FROM_TOKEN = 16071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_TOKEN_CONTAINED_EXTRA_CLAIMS = 16072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_DISPLAYNAME_CONFLICT = 16073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ERROR_X_FROM_REMOTE_WITH_DETAILS_Y = 16074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_VALIDATE_REMOTE_SERVER_CONFIG = 16075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_VALIDATE_CLIENTID = 16076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_VALIDATE_REMOTE_SERVER_ERROR_LOGS = 16077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_TOKEN_EXPIRED = 16078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_TOKEN_NOT_YET_VALID = 16079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_TOKEN_NOT_TRUSTED = 16080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_TOKEN_WRONG_USAGE = 16081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_TOKEN_MISSING_PROOF_OF_POSSESSION = 16082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ERROR_UNSUPPORTED_RESPONSE_TYPE = 16083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ERROR_UNAUTHORIZED_CLIENT = 16084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ERROR_INVALID_CLIENT = 16085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ERROR_INVALID_SCOPE = 16086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_ERROR_INVALID_REQUEST = 16087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_CONFIGURED_CLAIMS_NOT_FOUND = 16088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RADIO_CANTCHANGEACCESSRULETYPEAFTERCREATION = 16089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_AUTHENTICATION_NAME = 16090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_AUTHENTICATION_DESCRIPTION = 16091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_TSV = 16092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_SSV = 16093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_GEOJSON = 16094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_JSON = 16095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_BSON = 16096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_KML = 16097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_KMZ = 16098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_SHP = 16099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_SHX = 16100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_DBF = 16101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_XLS = 16102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_XLSX = 16103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INGESTION_DATABASE_PREFIX = 16104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_POPULATE_FROM_SAMPLE_FILE = 16105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_POPULATE_FROM_SAMPLE_FILE = 16106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_POPULATE_FROM_SAMPLE_FILE = 16107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DEFAULT_FIELD_NAME = 16108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_ON_MAP = 16109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHAPE_ON_MAP = 16110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_IMAGE = 16111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAYED_FIELDS = 16112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTROL_TYPE = 16113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_DESCRIPTOR = 16114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_FIELDS = 16115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DEFAULT_FIELD_NAME_NO_INDEX = 16116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SELECT_ICON = 16117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESCRIPTOR_ICON_FILE_FILTER = 16118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IMPORT_DATA = 16119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REFRESH_COUNTS = 16120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORTING_FILE = 16121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_LOG = 16122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_INGESTION_CONFIGURATION_BEGIN = 16123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELDS = 16124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_EVENT_DRIVEN = 16125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOFENCE = 16126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_GEOFENCE_SHOULD_BE_SET_FOR_EVENT_DRIVEN_DATA = 16127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESCRIPTOR = 16128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INDEXED_FIELD = 16129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELDS_COUNT = 16130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONE_FIELD = 16131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZERO_FIELDS = 16132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_STRING = 16133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_INT = 16134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_LONG = 16135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_BOOL = 16136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_DOUBLE = 16137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_DATETIME = 16138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_ENTITY = 16139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_BINARY_BASE64 = 16140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_BINARY_UTF8 = 16141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_BINARY_FILE = 16142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_TYPE_NON_BINARY_EXTENDED = 16143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INGESTION_DATABASE_METRICS = 16144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_INGESTION_DATABASE_COUNT_A = 16145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_INGESTION_DATABASE_COUNT_B = 16146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_DATASOURCE = 16147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESIGN_NEW_DATASOURCE = 16148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PARSING_COMPLETED = 16149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DISABLING_TRIGGERS = 16150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FAILED_TO_DISABLE_CONTRAINTS = 16151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FAILED_TO_RENABLE_CONTRAINTS = 16152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DUPLICATE_KEY_DETECTED = 16153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FAILED_TO_PARSE_FILE = 16154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SELECT_IMPORT_FILE = 16155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_INGESTION_ENGINE_NAME = 16156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_INGESTINO_ENGINE_DESCRIPTION = 16157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_DATA_ON_MAP = 16158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ADD_NEW_DATA = 16159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_OR_DROP_FILE_HERE = 16160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_QUERYING_INGESTION_ROLES = 16161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PLEASE_WAIT_SENDING = 16162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATION = 16163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_AUTOMATION_NAME = 16164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_AUTOMATION_DESCRIPTION = 16165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MUST_SELECT_DATA_TO_INGEST = 16166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNABLE_TO_LOAD_ROLE = 16167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ERROR_INGESTING_DATA = 16168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ERROR_SENDING_MESSAGE_TO_INGESTION_ROLE = 16169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_COULD_NOT_FIND_OWNING_ROLE = 16170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INGESTION_REGISTRATIONS = 16171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_WORKLOAD_PER_RULES_ENGINE = 16172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLUMN_MAPPING_INDICIES = 16173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COLUMN_TYPES = 16174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_EXPORTER_ENDPOINTS_COUNT = 16175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_METADATA_DOWNLOAD_FAILED = 16176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_STATUS_METADATA_ENDPOINTS = 16177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_REDIRECT_URIS = 16178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_STATUS_VIEW_SETTINGS = 16179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_STATUS_VIEW_SETTINGS_PUBLIC_ENDPOINTS = 16180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MOBILE_CREDENTIAL_MANAGER_NAME = 16181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_MOBILE_CREDENTIAL_MANAGER_DESCRIPTION = 16182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOREGROUND = 16183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_LESS = 16184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNKNOWN_ACTION = 16185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COMMIT_DATASOURCE_FIRST = 16186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DISABLE_ADMIN_ACCOUNT = 16187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DISABLE_ADMIN_ACCOUNT = 16188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DIAGNOSTIC_UARCOMPATIBILITYFAILURETITLE = 16189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_DIAGNOSTIC_UARCOMPATIBILITYFAILUREDESCRIPTION = 16190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_TRUST_CERTIFICATE = 16191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTOMATION_RULES = 16192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_RULE = 16193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_USER_LOCATIONS = 16194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHERE_LC = 16195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_INGESTION_ENGINE_DESCRIPTION = 16196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONDITION_LC = 16197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONDITIONS_LC = 16198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTION_LC = 16199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIONS_LC = 16200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ON_EVENT_FORMAT = 16201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_AUTOMATION_MANAGER_NAME = 16202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_AUTOMATION_MANAGER_DESCRIPTION = 16203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALLEDDRIVERSVERSION = 16204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TYPE_GPX = 16205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TEMPLATES = 16206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TAGS = 16207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COINCIDENCE = 16208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COINCIDENCES = 16209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MODIFY_ENTITIES_NO_PRIVILEGE_WITH_NAME = 16210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MODIFY_ENTITIES_NO_PRIVILEGE_COUNT = 16211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_PASSWORDHISTORY = 16212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_DATEOFATTEMPT = 16213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_PREVIOUSPASSWORD = 16214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COLUMN_NEWPASSWORD = 16215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PASSWORDHISTORYDESCRIPTION = 16216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PASSWORDCHANGECOUNT = 16217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FOV_MAX_DISTANCE = 16218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID_REQUEST_OFFLINE_ACCESS = 16219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_USE_ARTIFACT_RESOLUTION = 16220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2 = 16221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_USE_ARTIFACT_RESOLUTION = 16222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_GROUP_ASSERTION = 16223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_USERNAME_CLAIM = 16224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_METADATA_URL = 16225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_ISSUER = 16226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPENID = 16227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WSFED_AND_WSTRUST = 16228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SSO_PROTOCOL_CHANGE = 16229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_FOV_DISTANCE = 16230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOV_DISTANCE = 16231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FOV_ANGLE_OF_VIEW = 16232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_IMPORT_CAMERAS = 16233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WSFED_OR_WSTRUST = 16234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_UNITPASSWORDHISTORYCONSULTED = 16235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_UNITPASSWORDHISTORYCONSULTEDDESCRIPTION = 16236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDING_PERCENTAGE = 16237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASK_SHORTDESC_COINCIDENCES = 16238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CERTIFICATE_VALID = 16239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CERTIFICATE_WARNING = 16240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CERTIFICATE_ERROR = 16241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FEATURENOTE_TRACEROUTE = 16242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVE_TRANSFER_SEQUENCE_IMPORTED = 16243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EDIT_DATA = 16244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_UNITPASSWORDRECOVERED = 16245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_UNITPASSWORDRECOVEREDDESCRIPTION = 16246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_VERB_PASSWORDRECOVERY_RECOVER = 16247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDRECOVERY_INFO = 16248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PASSWORDRECOVERY_FAILED = 16249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PASSWORDRECOVERY_SUCCESS = 16250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PASSWORDRECOVERY_ONGOING = 16251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DIAGNOSTIC_UNITCONTROLDRIVERPACKNOTINSTALLED = 16252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DIAGNOSTIC_UNITCONTROLDRIVERPACKNOTINSTALLED_FORMAT = 16253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PASSWORDCHANGE_ACCOUNTBLOCKED = 16254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_ACTIVEDIRECTORY_DELTA_SYNC_USN_WARNING = 16255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_DISABLE_DRIVE_OUTPUT_RELAYS_SYNERGIS_APPLIANCES = 16256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_SECURE_READER_CONNECTIONS = 16257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_USE_STRONG_PASSWORDS_ACCESS_CONTROL_UNITS = 16258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_UPDATE_ACCESS_CONTROL_EQUIPMENT = 16259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INPUT_TYPE_DIGITAL = 16260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASE_ACCESS_DENIED = 16261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASE_NO_CONNECTION = 16262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASE_REMOTE_CONNECTION_DISABLED = 16263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASE_IN_USE = 16264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASE_COULD_NOT_VERIFY = 16265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_TITLE_DATABASE_INVALID_SETTINGS = 16266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DATABASE_VERIFYING_SETTINGS = 16267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_FILE = 16268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_TOO_LARGE = 16269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX_FILESIZE_IS_FORMAT = 16270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOCODING_SERVICE_URL = 16271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOOLTIP_UNITALREADYONLINE = 16272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IMPORT_MAP_OBJECTS = 16273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_OBJECT_SPECIFIC_PROPERTY = 16274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MAP_OBJECT_PROPERTY = 16275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASE_USED_BY_ANOTHER_ROLE = 16276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_AUTO_SAVE = 16277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_LAYERS = 16278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BACKGROUND_LAYERS = 16279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_VALID_DRIVER_PACK = 16280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_INVALID_DRIVER_PACK = 16281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CERTIFICATESETTINGS = 16282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CERTIFICATESETTINGS_POLICIES = 16283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESETTINGS_EXPIREDCERTIFICATERENEWAL = 16284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNITASSISTANTMODULENAME_SECURITY = 16285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESETTINGS_ENABLEHTTPSAFTERINSTALLATION = 16286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESETTINGS_NOTIFICATIONSENT_XDAYSBEFOREEXPIRED = 16287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESETTINGS_NOTIFICATIONSENT_XDAYSBEFOREEXPIRED_UNIT = 16288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DRIVERPACK_NOT_INSTALLED = 16289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_OBSERVER_ENTITY = 16290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_OBSERVED_RESOURCE_TYPE = 16291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSO_STATUS_VIEW_LOGOUT_URIS = 16292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_SCOPES = 16293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_AUDIENCE = 16294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_RESOURCE = 16295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_GROUP_CLAIM = 16296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SSO_DOMAINS = 16297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SSO_DISPLAY_NAME = 16298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SSO_CLIENT_ID = 16299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_ISSUER = 16300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_USERNAME_CLAIM = 16301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_ADDUNITDIALOG_DRIVERPACK_NOT_INSTALLED = 16302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNITENROLLMENT_DRIVERPACK_NOT_INSTALLED = 16303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SPECIFICCOMMANDS_DRIVERPACK_NOT_INSTALLED = 16304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FIRMWAREUPGRADE_DRIVERPACK_NOT_INSTALLED = 16305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOMATION_BASIC = 16306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOMATION_ADVANCED = 16307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INGESTION = 16308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_INGESTION_COUNT = 16309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERTIFICATE_SSL_FAILED = 16310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERTIFICATE_NOT_VERIFIED = 16311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CORRELATION_REPORT_DESCRIPTION = 16312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LATESTVERSIONRECOMMENDED = 16313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_TEMPERATURE_DETECTION_ALARM = 16314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILECREDENTIALSTATUS = 16315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ISMOBILECREDENTIAL = 16316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HEALTHEVENT_INVALID_DRIVER_PACK_DESCRIPTION = 16317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HEALTHEVENT_VALID_DRIVER_PACK_DESCRIPTION_FORMAT = 16318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNITMANAGEMENT_GROUP = 16319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_CERTIFICATE_MANAGEMENT_SETTINGS = 16320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_YEARLY = 16321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MONTH = 16322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ONLY_COMMA_SEPARATED_VALUES_ACCEPTED = 16323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_USER_GROUPS_MATCH_WITH_PROVIDER_HINT = 16324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ADDED_AND_UPDATED_ENTITIES_ON_MAP = 16325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_PREVIEW = 16326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_LPRMONITORING = 16327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_LPRMONITORING = 16328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CAR_PLATES = 16329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_HIGH_RES_REPORT_IMAGES = 16330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CERTIFICATESETTINGS_INFORMATION = 16331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_VALIDITYPERIOD = 16332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_SUBJECTALTERNATIVENAMES = 16333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_COUNTRY = 16334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_STATEPROVINCE = 16335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_LOCALITY = 16336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_ORGANIZATION = 16337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_ORGANIZATIONALUNIT = 16338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CSR_COMMONNAME = 16339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CSR_SUBJECTALTERNATIVENAMES = 16340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMAL_CARDHOLDER_SYNCHRONIZATION = 16341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAYS_BEFORE_EXPIRATION = 16342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_RECOMMENDED_DAILY_RECURRENCE_ACTION = 16343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_UNITS = 16344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_UNITASSISTANT_SETTINGS_NOT_FOUND = 16345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_UNITASSISTANT_ROLE_NOT_FOUND = 16346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_UNITASSISTANT_UNEXPECTED_ERROR = 16347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_INHERIT_FROM_UNITASSISTANT = 16348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_USE_CURRENT = 16349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UPDATE_CERTIFICATE_FOR_ENTITIES = 16350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UPDATE_CERTIFICATE_ON_DEVICE = 16351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UPDATE_CERTIFICATE_ON_DEVICES = 16352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UPDATE_CERTIFICATE_FOR_ENTITY = 16353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UPDATE_EXPIRED_CERTIFICATES_NOT_PERMIT = 16354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHECKBOX_FORCE_UPDATE_EXPIRED_CERTIFICATES = 16355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MULTI_VALUES = 16356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UPDATE_CERTIFICATES = 16357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_MISSING_PRIVILEGES_UPDATE_EXPIRED_CERTIFICATES = 16358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_UNABLE_RETRIEVE_UNITASSISTANT_SETTINGS = 16359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_VIEW_CERTIFICATE = 16360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_EXPIRATION = 16361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_STATUS = 16362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_CERTIFICATE_UPDATE_RESULT = 16363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_VALID = 16364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_NOT_AVAILABLE = 16365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_INVALID_COMMON_NAME = 16366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_INVALID_TIME = 16367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_INVALID_KEY_USAGE = 16368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_REVOKED = 16369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_EXPLICITLY_DISTRUSTED = 16370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_INVALID_CHAIN_OF_TRUST = 16371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_SIGNATURE_ERROR = 16372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_INVALID_CERTIFICATE_TRUST_LIST = 16373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_REVOCATION_CHECK_ERROR = 16374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_CONSTRAINT_VIOLATION = 16375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATESTATUS_CERTIFICATE_EXTENSION_ERROR = 16376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CERTIFICATE = 16377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_UPDATERESULT_FAILED_GENERATE_SIGNING_REQUEST = 16378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_UPDATERESULT_FAILED_GET_SIGNED_CERTIFICATE = 16379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_UPDATERESULT_FAILED_APPLY_CERTIFICATE = 16380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_UPDATERESULT_FAILED_CURRENT_CERTIFICATE_REVOKED = 16381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_UPDATERESULT_FAILED_CURRENT_CERTIFICATE_EXPIRED = 16382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_UPDATERESULT_FAILED_UNEXPECTEDLY = 16383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UNITMANAGEMENT_GROUP = 16384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CERTIFICATE_MANAGEMENT_SETTINGS = 16385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CHANGING_SETTING_REQUIRE_ACCESSMANAGER_RESTART = 16386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HIGH_RES_IMAGE_USE = 16387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_INVALIDVERSIONCONFIGURATIONNOTSUPPORTED = 16388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PROTOCOL_VERIFYING_AVAILABILITY = 16389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PROTOCOL_INSTANCE_NOT_AVAILABLE = 16390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PROTOCOL_INSTANCE_NOT_AVAILABLE = 16391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HID_MOBILELICENSESTATUS_GOOD = 16392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HID_MOBILELICENSESTATUS_NEARING_LIMIT = 16393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HID_MOBILELICENSESTATUS_FULL_USAGE = 16394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HID_MOBILELICENSESTATUS_UNKNOWN = 16395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REMAININGLICENSECOUNT = 16396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NOMOBILECREDENTIALMANAGERROLE = 16397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ARCHIVING_NOTMONITORED = 16398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_APPLICATION_CONNECTION_NOTMONITORED = 16399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_APPLICATION_NOTMONITORED = 16400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CONNECTION_NOTMONITORED = 16401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_UNITCONNECTION_NOTMONITORED = 16402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DATABASEAUTOMATICBACKUP_NOTMONITORED = 16403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DATABASE_NOTMONITORED = 16404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_HIGHCPUUSAGE_NOTMONITORED = 16405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_HIGHMEMORYUSAGE_NOTMONITORED = 16406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_LOWDATABASESPACE_NOTMONITORED = 16407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_OFFLOAD_NOTMONITORED = 16408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_PATROLLER_NOTMONITORED = 16409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_POS_DATABASE_NOTMONITORED = 16410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ROLE_NOTMONITORED = 16411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_RTPPACKET_NOTMONITORED = 16412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_SERVICE_NOTMONITORED = 16413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_SYNCHRONIZATION_NOTMONITORED = 16414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_VIDEOSIGNAL_NOTMONITORED = 16415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_UNAUTHORIZED_DISK_NOTMONITORED = 16416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ACTIVE_ALARMS_NOTMONITORED = 16417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DIRECTORY_NOTMONITORED = 16418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_LOWARCHIVEDISKSPACE_NOTMONITORED = 16419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_LIVE_HEALTH_MONITORING_NOTMONITORED = 16420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DATABASEFAILOVER_MAINDB_NOTMONITORED = 16421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DATABASE_RESTORE_NOTMONITORED = 16422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_UNIT_TRICKLING_NOTMONITORED = 16423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ARCHIVE_BACKUP_NOTMONITORED = 16424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_AGENT_NOTMONITORED = 16425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ARCHIVE_CONSOLIDATION_NOTMONITORED = 16426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CAMERA_RECORDING_NOTMONITORED = 16427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_UNIT_TIME_OUT_OF_SYNC_NOTMONITORED = 16428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CAMERA_CONNECTION_NOTMONITORED = 16429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CAMERA_TRANSMISSION_NOTMONITORED = 16430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_EDGESTORAGE_NOTMONITORED = 16431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_EDGESTORAGE_RECORDING_NOTMONITORED = 16432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_ARCHIVER_TRICKLING_NOTMONITORED = 16433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_CERTIFICATE_NOTMONITORED = 16434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_DRIVER_PACK_NOTMONITORED = 16435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_IMPORT_CANCELLED = 16436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DESFIRE_DUPLICATE_CONFIGURATION = 16437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DIAGNOSTIC_INTRUSIONLICENSE = 16438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_LPRMONITORING = 16439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HELP_LPRMONITORING = 16440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_LPR_LIVE_HIT_MONITORING = 16441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_LPR_LIVE_HIT_MONITORING = 16442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_REVERSETUNNEL_CLIENTS = 16443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_REVERSETUNNEL_SERVERS = 16444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOTAPPLICABLE = 16445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CUSTOM = 16446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CONFIGURATION = 16447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_KEYTRANSFER = 16448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_FILE_XML = 16449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_UNITS_XML = 16450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_EXPORT = 16451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORTHEADER_COMPONENTS = 16452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HASH = 16453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEY_INDEXED = 16454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HASH_METHOD_KCV = 16455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HASH_METHOD_SHA1 = 16456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HASH_METHOD_SHA256 = 16457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HASH_METHOD_SHA384 = 16458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HASH_METHOD_SHA512 = 16459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RETRIEVE_APPLICATION_READ_KEYS = 16460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_KEYS_CONFIGURATIONS = 16461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_INVALID_KEY = 16462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_NEW_KEY_VERSION = 16463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_KEY_COMPONENT = 16464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UNIT_SELECTION = 16465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONINPUTTYPE_VIRTUAL = 16466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CANNOT_SELECT_UNIT = 16467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MIFARE_DESFIRE_EXPORT_CONGURATIONS_AND_KEYS = 16468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MIFARE_DESFIRE_MODIFY_KEYS = 16469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MIFAREDESFIREEXPORTCONGURATIONSANDKEYS = 16470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MIFAREDESFIREMODIFYKEYS = 16471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTRUSIONAREA_VIRTUALALARM = 16472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIRTUALALARM_INPUT = 16473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIRTUALALARM_OUTPUT = 16474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIRTUALALARM_REMINDER = 16475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_VIRTUALALARM_INPUT = 16476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_VIRTUALALARM_OUTPUT = 16477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CHILD_LAYER_IN_ERROR = 16478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_LAYER_TYPE_NOT_SUPPORTED = 16479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNABLE_FIND_LAYER_SERVER = 16480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNAVAILABLE_ESRI_LICENSE = 16481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALARMSOURCES = 16482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_CONFIDENTIAL_CLIENT = 16483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_CLIENT_SECRET = 16484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_CLAIMS_FROM_ACCESSTOKEN = 16485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_CLAIMS_FROM_USERINFO_ENDPOINT = 16486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OPENID_REQUEST_OFFLINE_ACCESS = 16487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EVACUATION_STARTED = 16488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_EVACUATION_ENDED = 16489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCGIS_LICENSE_LITE = 16490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCGIS_LICENSE_ADVANCED = 16491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_TOKEN_AUTHENTICATION = 16492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NAME_MATCH_MODE = 16493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STARTS_WITH = 16494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENDS_WITH = 16495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXACT_MATCH = 16496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NOCERTIFICATEFOUND = 16497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_NOCERTIFICATEFOUND = 16498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOUD_STORAGE = 16499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_MUSTERING_ENDED = 16500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_MUSTERING_STARTED = 16501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_DIAGNOSTIC_TITLE_CERTIFICATEUPDATEFAILED = 16502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_DIAGNOSTIC_DETAILS_CERTIFICATEUPDATEFAILED = 16503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALIGNMENT_ON_MULTILINE_ONLY = 16504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ALIGNMENT_ON_MULTILINE_ONLY = 16505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UNIT_CERTIFICATE_UPDATED = 16506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEWROLERESOURCES = 16507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEWROLERESOURCES = 16508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITYTRAIL_UNIT_CERTIFICATE_CHANGED = 16509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_INTRUSIONINPUTTYPE_VIRTUAL_EDITDISABLED = 16510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_REQUEST_PARAMETERS = 16511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PARAMETER_X_MUST_USE_ROLE_PROPERTIES_UI = 16512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_PARAMETERS = 16513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CUSTOM_PROTOCOL_REQUEST_PARAMETERS_DESCRIPTION = 16514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML2_AUDIENCE = 16515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML2_AUDIENCE = 16516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_FILE_CONTENT = 16517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NEWCREDENTIAL = 16518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASK_CLOUD_STORAGE = 16519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ACCESSCONTROLUNIT_WARNING_ACTIVATED = 16520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ACCESSCONTROLUNIT_WARNING_DEACTIVATED = 16521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_COLOR = 16522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_TYPE = 16523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ACTIVITY_TYPE_RETRIEVE_CLOUD_ARCHIVES_REQUEST = 16524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DXDIAG_REPORT = 16525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INSTALLED_PROGRAMS_LIST = 16526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RUNNING_PROCESSES_LIST = 16527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT_LOGS = 16528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLR_ASSEMBLIES = 16529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NIC_LIST = 16530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NETWORK_CONNECTIONS = 16531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETEC_PROCESS_INFO = 16532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIG_FILES = 16533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOG_FILES = 16534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REGISTRY_KEYS = 16535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERFORMANCE_COUNTERS = 16536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CRASH_DUMP_INFORMATION = 16537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CERTIFICATE_ALLOWREVOKEDCERTIFICATES = 16538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CERTIFICATE_ALLOWREVOKEDCERTIFICATES = 16539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_ALLOWREVOKEDCERTIFICATES = 16540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_STARTED = 16541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_FAILED = 16542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_SUCCESS = 16543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FIRMWAREUPGRADE_MISSINGPASSWORDPRIVILEGE = 16544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_TEST_FAILED_ABORTED = 16545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_TEST_FAILED_READERSHUNTED = 16546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_TEST_FAILED_DOORNOTSECURED = 16547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_TEST_FAILED_ERROR = 16548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_TEST_FAILED_UNLOCKFAILED = 16549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_REASON_DESCRIPTION_EMERGENCY_DOOR_TEST_FAILED_RELOCKFAILED = 16550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_SIPIDENTITY = 16551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_USERNAME = 16552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_PASSWORD = 16553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_FAILED_ABORTED = 16554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_FAILED_READERSHUNTED = 16555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_FAILED_DOORNOTSECURED = 16556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_FAILED_ERROR = 16557;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_FAILED_UNLOCKFAILED = 16558;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_DOOR_EMERGENCY_DOOR_TEST_FAILED_RELOCKFAILED = 16559;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_FIRMWARE_UPGRADE_SCHEDULED = 16560;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_FIRMWARE_UPGRADE_CANCELLED = 16561;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTERFACE_MODULE_FIRMWARE_UPGRADE_SUCCEEDED = 16562;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTERFACE_MODULE_FIRMWARE_UPGRADE_STARTED = 16563;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTERFACE_MODULE_FIRMWARE_UPGRADE_FAILED = 16564;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTERFACE_MODULE_FIRMWARE_UPGRADE_CANCELLED = 16565;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTERFACE_MODULE_FIRMWARE_UPGRADE_SCHEDULED = 16566;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INTERFACE_MODULE_FIRMWARE_UPGRADE_TRANSFERRED = 16567;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRANSFERRED = 16568;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETEC_SYSTEM_INFORMATION = 16569;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_LOGS = 16570;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_CACHE = 16571;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_UNIT_INFORMATION = 16572;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURATION_FILES = 16573;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_AGENT = 16574;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAM_LOCK_UNLOCK = 16575;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CANNOT_GET_READ_KEYS = 16576;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_TOO_MANY_ENTITIES_REQUIRES_AUTO_SAVE = 16577;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UAR_DIAGNOSTIC_PASSWORDCHANGEFAILED = 16578;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_DIAGNOSTIC_DETAILS_PASSWORDCHANGEFAILED = 16579;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_QUICK_MOBILE_CREDENTIAL_REFRESH_INTERVAL = 16580;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NORMAL_MOBILE_CREDENTIAL_REFRESH_INTERVAL = 16581;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CREDENTIAL_AUTO_REVOKE = 16582;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_AS = 16583;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RENDERED_AS = 16584;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOM_EXPRESSION = 16585;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_EXPRESSION = 16586;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RAISE_EVENT_DATA_INGESTION = 16587;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RAISE_EVENT_DATA_INGESTION = 16588;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ASSOCIATE_EVENTS_TO_ENTITY = 16589;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ASSOCIATE_EVENTS_TO_ENTITY = 16590;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_IN_MAP_AS = 16591;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PIN = 16592;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_PIN_PREVIEW = 16593;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATA_PRESENTATION = 16594;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_ITEMS = 16595;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONE_ITEM = 16596;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_ITEMS = 16597;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_X_ITEMS_IMPORTED_CREATION = 16598;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_SIPELIA = 16599;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SIPELIA_BASE = 16600;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SIPELIA_CPACK = 16601;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SIPELIA_FULLDUPLEX_DEVICE_COUNT = 16602;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SIPELIA_HALFDUPLEX_DEVICE_COUNT = 16603;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_SIPELIA_TRUNK_DEVICE_COUNT = 16604;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_POTENTIALPASSWORDLOSS = 16605;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_POTENTIALPASSWORDLOSS = 16606;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COULD_NOT_SAVE_KEYS = 16607;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_GREATER_THAN = 16608;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_GREATER_THAN_OR_EQUAL_TO = 16609;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_LESS_THAN = 16610;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_LESS_THAN_OR_EQUAL_TO = 16611;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_EQUAL_TO = 16612;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATOR_NOT_EQUAL_TO = 16613;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IN_SET = 16614;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_IN_SET = 16615;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MATCHES_PATTERN_UC = 16616;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOOK_FOR = 16617;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_EXCLUDED = 16618;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_INCLUDED = 16619;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_DEFINED = 16620;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXCLUDE_REGIONS = 16621;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_EXCLUDEREGIONS = 16622;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_REGION = 16623;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONDITIONS = 16624;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CONDITION_SELECTFIELD = 16625;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COINCIDENCE_FIELD = 16626;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COINCIDENCE_FIELDS = 16627;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COINCIDENCE_MODE = 16628;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIMILAR = 16629;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVITATION_STATUS = 16630;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PROVIDER = 16631;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESEND = 16632;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_INVITATION = 16633;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUBSCRIPTIONS = 16634;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MOBILE_CREDENTIAL_CREATION_WIZARD = 16635;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_PROVIDER_DELETE = 16636;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_PROVIDER_DELETE_INFORMATIVE_TEXT = 16637;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRED = 16638;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_MOBILE_ID = 16639;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CREDENTIAL = 16640;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVOKED = 16641;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REVOKING = 16642;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HID_MOBILE_ACCESS = 16643;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_CREDENTIAL_NORMAL_REFRESH_QUEUE = 16644;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_CREDENTIAL_FAST_REFRESH_QUEUE = 16645;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SETTINGS_CHANGED_MOBILE_CREDENTIAL_ROLE_RESTART = 16646;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CUSTOM_ACTION_NO_UI_COMPONENT = 16647;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_MAKE = 16648;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_HELP_ACCESSRULES = 16649;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HELP_ACCESSRULES = 16650;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_HELP_FILE_RELATIVE_PATH = 16651;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_HELP_FILE_NAME_USER_GUIDE = 16652;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_HELP_FILE_NAME_ADMIN_GUIDE = 16653;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_HELP_ARGUMENT_XLANGUAGE_YPATH = 16654;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_HELP_ADMIN_GUIDE_LANGUAGE_CODE = 16655;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_HELP_URL_HOME_PAGE = 16656;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEO_ANALYTICS_MOTION_ANOMALY_ENABLED = 16657;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_AUTOVU_EXPORTER_JSON2_ENDPOINTS_COUNT = 16658;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE = 16659;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_CONFIG_CREATED = 16660;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_CONFIG_MODIFIED = 16661;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_CONFIG_DELETED = 16662;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_CONFIG_IMPORTED = 16663;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_KEY_STORE_INDEX_CREATED = 16664;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_KEY_STORE_INDEX_DELETED = 16665;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_KEY_STORE_INDEX_MODIFIED = 16666;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_KEY_STORE_VERSION_CREATED = 16667;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_KEY_STORE_VERSION_DELETED = 16668;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_MIFARE_DESFIRE_KEY_STORE_VERSION_MODIFIED = 16669;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_INVALID_FIRMWARE_PACKAGE_SELECTED = 16670;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_NOT_SUPPORTED_BY_FIRMWARE_UPGRADE_PACKAGE = 16671;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_FAILED_INSTALL_CA_CERTIFICATES = 16672;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FAILED_INSTALL_CA_CERTIFICATES = 16673;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_CONCURRENT_OPERATOR_CONNECTIONS = 16674;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_MC_ACTIVE_INCIDENTS = 16675;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_READER_SIDE = 16676;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REFRESH_MOBILE_CREDENTIAL_DATA = 16677;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PENDING_PROVIDER_UPDATE = 16678;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GLOG_FILES = 16679;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_MOBILE_CREDENTIAL_CREATED = 16680;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MOBILE_CREDENTIAL_CREATION_FAILED = 16681;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_MOBILE_CREDENTIAL_DELETED = 16682;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MOBILE_CREDENTIAL_DELETION_FAILED = 16683;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_MOBILE_INVITATION_CANCELED = 16684;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MOBILE_INVITATION_CANCELLATION_FAILED = 16685;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_MOBILE_INVITATION_RESENT = 16686;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MOBILE_INVITATION_RESENT_FAILED = 16687;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVE_REVOKED_CREDENTIALS_HIDDEN = 16688;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IGNORE_ACCESS_DENIED = 16689;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_STATISTICS_CLOUD_BACKLOG_FILES_COUNT = 16690;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_STATISTICS_CLOUD_BACKLOG_SIZE = 16691;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_STATISTICS_CLOUD_BACKLOG_OLDEST_FILE = 16692;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVER_STATISTICS_CLOUD_UPLOAD_SPEED = 16693;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MOBILE_CREDENTIAL_MANAGER_QUERY_TIMEOUT = 16694;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MOBILE_CREDENTIAL_FAILED_LOADING = 16695;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_SUCCESS = 16696;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_TIMEOUT = 16697;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_BACKUPFAILED = 16698;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_BACKUPFAILED = 16699;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_GENERATIONFAILED = 16700;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_CANNOTCHANGE = 16701;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_CANNOTCHANGE = 16702;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_VALIDATIONFAILED = 16703;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_FAILED = 16704;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_FAILED = 16705;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_FAILED_CAUSEPOLICIES = 16706;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_FAILED_CAUSEFIRMARE = 16707;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_FAILED_CAUSECONNECTION = 16708;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_TESTFAILED = 16709;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_TESTFAILED = 16710;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_ACCOUNTBLOCKED = 16711;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_ACCOUNTBLOCKED = 16712;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_NOTSECURE = 16713;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PASSWORDCHANGERESULT_NOTSECURE = 16714;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENUM_PASSWORDCHANGERESULT_UNSUPPORTEDMANUFACTURER = 16715;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TITLE_COLUMN_PASSWORDCHANGERESULT = 16716;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNSUPPORTEDENCODING = 16717;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_AUTH_SERVICE_MAX_LICENSE = 16718;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_ROTATIONANDDIRECTIONOFFSETS = 16719;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIDE_ROTATIONANDDIRECTIONOFFSETS = 16720;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_QUERY_TYPE = 16721;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_QUERY = 16722;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_EXTENDED_FIELDS_QUERY = 16723;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_LIST_DATASOURCES_QUERY = 16724;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_DEFAULT_TEXT_QUERY = 16725;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CORRELATION_DATUMS_BY_ID = 16726;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORRELATED_ALARM = 16727;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_GO_TO_ALARM_TIME = 16728;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_INGESTION_DATASOURCE_CREATED = 16729;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_INGESTION_DATASOURCE_DELETED = 16730;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_INGESTION_DATA_ADDED = 16731;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_INGESTION_DATA_REMOVED = 16732;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_INGESTION_DATA_UPDATED = 16733;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ACTIVITY_TYPE_CORRELATION_QUERY = 16734;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_ACTIVITY_TYPE_INGESTION_DATA_MODIFICATION = 16735;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORDS = 16736;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MIFARE_DESFIRE_EXPORT_CONFIGURATION_FILE = 16737;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MIFARE_DESFIRE_EXPORT_CONFIGURATION_UNIT = 16738;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MIFARE_DESFIRE_EXPORT_KEYS = 16739;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MIFARE_DESFIRE_ENCODED_CARD = 16740;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MIFARE_DESFIRE_TESTED_ENCODING_CARD = 16741;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ANNOTATION_FIELDSFILTERS_MUST_USE_PROMOTED_SEARCHFILTERS = 16742;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ANNOTATION_FIELDSFILTERS_MULTICRITERIA_SEARCH_ALLOWED = 16743;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNITSPASSWORDCHANGE = 16744;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UNITSPASSWORDCHANGEREPORT = 16745;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_MIFARE_DESFIRE_IMPORT_CONFIGURATION = 16746;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UPDATE_CERTIFICATE_REFRESH = 16747;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTERSUBJECTALTERNATIVENAMEHERE = 16748;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_RINGGROUP_CALL_STRATEGY = 16749;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_RINGGROUP_SEQUENTIAL_MAX_LOOP_COUNT = 16750;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_RINGGROUP_SEQUENTIAL_CALL_RINGING_TIMEOUT = 16751;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ESRI_CLIENT_ID = 16752;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ROLE_SERVICES_DOWN = 16753;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ROLE_ALL_SERVICES_ONLINE = 16754;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SYNCHRONIZE_PROVIDER_DATA = 16755;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SYNCHRONIZE_PROVIDER_DATA_INFORMATIVE_TEXT = 16756;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SYNCHRONIZE_PROVIDER_DATA_SUCCESS = 16757;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SYNCHRONIZE_PROVIDER_DATA_FAILURE = 16758;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SYNCHRONIZE = 16759;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SYNCHRONIZE_PROVIDER_DATA = 16760;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PASSWORDCHANGE_ALREADYPENDING = 16761;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PASSWORDCHANGE_UNITOFFLINE = 16762;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PASSWORDCHANGE_CANNOTCHANGEPASSWORD = 16763;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_DESFIRE_RANDOM_UID = 16764;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_RETRIEVE_UID_KEY = 16765;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_WARNING_DESFIRE_RANDOM_UID_DIVERSIFIED_KEYS = 16766;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTERCOM = 16767;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_INGESTION_DATASOURCE_UPDATED = 16768;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SCTOKEN_CREATION_READY = 16769;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SCTOKEN_CREATION_ERROR = 16770;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_SCTOKEN_KEY_MISMATCH = 16771;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_SCTOKEN_KEY_MISSING = 16772;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_SCTOKEN_NEW_LICENSE = 16773;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_SCTOKEN_RESTART = 16774;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_VIEW_TRUNK = 16775;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_VIEW_TRUNK = 16776;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MODIFY_TRUNK = 16777;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_TRUNK = 16778;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ADD_TRUNK = 16779;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ADD_TRUNK = 16780;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_TRUNK = 16781;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_TRUNK = 16782;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_DATA_SOURCE_READ_WRITE = 16783;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_CORRELATION_DATA_SOURCE_READ_ONLY = 16784;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_HEALTHEVENT_SCTOKEN_INVALID_LICENSE = 16785;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_HEALTHEVENT_SCTOKEN_MISSING_SIGNING_KEY = 16786;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FIRWAREUPGRADE_FAILURE = 16787;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INGESTION_ACTION_GROUP = 16788;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INGESTION_ACTION_GROUP = 16789;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INGESTION_INGEST_EVENT_ACTION = 16790;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INGESTION_INGEST_EVENT_ACTION = 16791;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INGESTION_INGEST_FILE_ACTION = 16792;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INGESTION_INGEST_FILE_ACTION = 16793;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INGESTION_INGEST_REST_ACTION = 16794;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INGESTION_INGEST_REST_ACTION = 16795;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INGEST_EVENT = 16796;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INGEST_FILE = 16797;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_INGEST_REST = 16798;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INGEST_EVENT_DESCRIPTION = 16799;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INGEST_FILE_DESCRIPTION = 16800;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INGEST_REST_DESCRIPTION = 16801;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_SCTOKEN_NOTMONITORED = 16802;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_SET_READER_CARDANDPIN = 16803;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_SET_READER_CARDORPIN = 16804;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACTIVITYTRAIL_SET_READER_MODE = 16805;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACUNIT_VIEWEXPORTPASSWORDS = 16806;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACUNIT_VIEWEXPORTPASSWORDS = 16807;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ALPRUNIT_VIEWEXPORTPASSWORDS = 16808;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ALPRUNIT_VIEWEXPORTPASSWORDS = 16809;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INTRUSIONUNIT_VIEWEXPORTPASSWORDS = 16810;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INTRUSIONUNIT_VIEWEXPORTPASSWORDS = 16811;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACUNIT_UPDATEPASSWORD = 16812;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACUNIT_UPDATEPASSWORD = 16813;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACUNIT_UPDATECERTIFICATE = 16814;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACUNIT_UPDATECERTIFICATE = 16815;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACEROUTESETTING_RETENTIONPERIOD = 16816;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TRACEROUTESETTINGINFO_RETENTIONPERIOD = 16817;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPIRING_ON_SPECIFIC_DATE = 16818;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVATE_ON_SPECIFIC_DATE = 16819;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CALL_RINGING = 16820;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CALL_DECLINED = 16821;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CALL_ANSWERED = 16822;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CALL_REJECTED = 16823;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CALL_TIMED_OUT = 16824;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CALL_ENDED = 16825;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CALL_CREATED = 16826;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CONTACT_REGISTERED = 16827;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_CONTACT_UNREGISTERED = 16828;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_TRUNK_REGISTERED = 16829;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_COMMUNICATIONS_TRUNK_UNREGISTERED = 16830;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUBTRING = 16831;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LIST_OF_CSV_VALUES = 16832;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REGULAR_EXPRESSION = 16833;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTEGER = 16834;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_RULE_EXPIRATION = 16835;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UAR_UNITCOMMANDQUEUEFULL = 16836;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WARNING_UAR_UNITCOMMANDQUEUEFULL = 16837;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ERROR_INITIALIZING_CARD_SCANNER_DLL_MESSAGE = 16838;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_TOO_MANY_MAP_LAYERS = 16839;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ENTITY_TYPE_NAME_CUSTOMENTITY_TYPE = 16840;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_TYPE_NAME_CUSTOMACTION_TYPE = 16841;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_EXPORT_TO_UNIT_KEY_STORE = 16842;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONTACT_RINGGROUP_SEQUENTIAL_MEMBER_ORDER = 16843;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ENTITY_ONLINE = 16844;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ENTITY_OFFLINE = 16845;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_ENTITY_NOTMONITORED = 16846;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CREDENTIAL_PROFILE = 16847;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALLOW_MJPEG = 16848;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_TROUBLESHOOT_UNIT_IN_ANOTHER_PARTITION = 16849;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTICATEABOUTTOEXPIRE = 16850;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTICATEABOUTTOEXPIRE_DETAILS = 16851;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FIRMWARE_UPGRADE_11X = 16852;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UARCOMMANDQUEUEFULL = 16853;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UARCOMMANDQUEUEFULL_DETAILS = 16854;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UARQUEUEDCOMMANDTIMEOUT = 16855;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UARQUEUEDCOMMANDTIMEOUT_DETAILS = 16856;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_SHUNT_READER = 16857;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WHEN_VISITOR_ESCORT_SELECTED = 16858;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_ENABLE_ESCORT_REQUIRED = 16859;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HEADING_AUTO_ENABLE_ESCORT_REQUIRED = 16860;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_AUTO_ENABLE_ESCORT_REQUIRED = 16861;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MOBILE_CREDENTIAL_MANAGER_PLURAL = 16862;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_FUNCTION = 16863;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ONE_RECORD = 16864;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_X_RECORDS = 16865;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_RECORDS = 16866;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ITEM = 16867;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_LAST_IMPORT_LOGS = 16868;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ROTATE_CAMERA_PASSWORDS_PERIODICALLY = 16869;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MOBILE_FEATURE_INTRUSIONDETECTION = 16870;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAY_OF_WEEK = 16871;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINUTE_FIELD = 16872;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COUNT_DISTINCT = 16873;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISTINCT = 16874;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVG = 16875;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAX = 16876;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMUM = 16877;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MIN = 16878;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STANDARD_DEVIATION = 16879;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_STANDARD_DEVIATION_SHORT_CODE = 16880;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SUM = 16881;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VARIANCE = 16882;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VARIANCE_SHORT_CODE = 16883;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEDIAN = 16884;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MEDIAN_SHORT_CODE = 16885;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVER = 16886;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPERATION = 16887;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INGESTED_EVENTS = 16888;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_AUTOMATION_RULE_SCALE_BREACH = 16889;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_AUTOMATION_RULE_INVALID_CONFIGURATION = 16890;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_AUTOMATION_RULE_TIMING_INFORMATION = 16891;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_AUTOMATION_RULE_EXPIRED = 16892;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_INGESTION_STARTED = 16893;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_INGESTION_FINISHED = 16894;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_INGESTION_FAILED = 16895;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_INGESTION_FILE_DELETED = 16896;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MOBILE_FEATURES = 16897;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_THIS_IN_CORRELATION_REPORT = 16898;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_IN_CORRELATION_REPORT = 16899;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOW_IN_CORRELATION_REPORT = 16900;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESET_VIEW = 16901;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_AGGREGATION_OPTIONS = 16902;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLY_OVER = 16903;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_AVAILABLE_FIELDS = 16904;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HTTP_POST_PAYLOAD = 16905;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXECUTION_TIME = 16906;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ALLOTED_TIME = 16907;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ALLOTED_TIME_ZERO = 16908;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HTTP_GET = 16909;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HTTP_POST = 16910;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REQUEST_METHOD = 16911;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTH_HEADER = 16912;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PATH_MUST_BE_ACCESSABLE = 16913;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ADAPTATIVEMOTIONNORMAL = 16914;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ADAPTATIVEMOTIONABNORMAL = 16915;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HEADING_CERTIFICATEVALIDATIONFAILED = 16916;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATEVALIDATIONFAILED_DETAILS = 16917;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_HEADING_CERTIFICATEENABLEHTTPSFAILED = 16918;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATEENABLEHTTPSFAILED_DETAILS = 16919;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SKIP_PTZ_EVALUATION = 16920;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SKIP_PTZ_EVALUATION = 16921;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_EDITPARTITIONMEMBERSHIPS = 16922;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_EDITPARTITIONMEMBERSHIPS = 16923;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_IMPORT_MAP_OBJECTS = 16924;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_OBJECT_TYPE = 16925;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INTRUSIONMANAGEMENT_GROUP = 16926;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INTRUSIONMANAGEMENT_GROUP = 16927;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INTRUSION_GENERALSETTINGS_GROUP = 16928;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INTRUSION_GENERALSETTINGS_GROUP = 16929;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_INTRUSION_ALARMCONFIGURATION = 16930;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_INTRUSION_ALARMCONFIGURATION = 16931;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DEFAULT_STREAM_USAGE = 16932;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DIFFERENTCERTIFICATE = 16933;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNITPASSWORDCHANGEFAILURE = 16934;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INTRUSIONINPUT_ASSIGNCAMERA = 16935;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ADD_PRIVACY_PROTECTION = 16936;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MISMATCH = 16937;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MANAGED = 16938;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNMANAGED = 16939;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DATABASEDISK_USAGE_REACHED_CONFIGURED_MAX = 16940;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_ACTIVITYTRAIL_REMOVED_UNIT_FROM_CERTIFICATE_MANAGEMENT = 16941;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_REMOVED_UNIT_FROM_CERTIFICATE_MANAGEMENT = 16942;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_MANAGE_PARTITIONS = 16943;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_USER_MANAGE_PARTITIONS = 16944;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_HOSTNAMECERTIFICATEWARNING = 16945;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_H264_VIDEO_QUALITY = 16946;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIFI = 16947;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CELLULAR = 16948;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_FOLDER_PATH_TOO_LONG = 16949;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZONE_STATE_UNKNOWN = 16950;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_CERTIFICATESETTINGS_HTTPSPORTMAYCHANGE = 16951;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VIDEOTYPE_AV1 = 16952;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEATURENAME_ADVANCEDCERTIFICATEMONITORING = 16953;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FEATUREDESCRIPTION_ADVANCEDCERTIFICATEMONITORING = 16954;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FEATUREINFO_ADVANCEDCERTIFICATEMONITORING = 16955;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FEATURENOTE_ADVANCEDCERTIFICATEMONITORING = 16956;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_OPERATION_MIGHT_HAVE_TIMED_OUT = 16957;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_TLS_CONNECTION = 16958;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PENDING_MOBILE_INVITATION = 16959;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_MAP_AUTOCAD_NAME_COMPARISON = 16960;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLY_ROTATION = 16961;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_REENABLING_TRIGGERS = 16962;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_WEBSDK_FAILEDTOSTART_ERRORTITLE = 16963;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ROLE_WEBSDK_FAILEDTOSTART_ERRORMESSAGE = 16964;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SETCUSTOMENDPOINT = 16965;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESETTODEFAULTENDPOINT = 16966;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENDPOINT = 16967;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CHANGEPKIENDPOINTRISK = 16968;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CHANGEPKIENDPOINTRISK = 16969;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PKISETTINGS = 16970;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_ACTION_MIGHT_AFFECT_UNITASSISTANT_OPERATIONS = 16971;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ACTION_MIGHT_AFFECT_UNITASSISTANT_OPERATIONS = 16972;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HARDENINGGUIDE_USECERTIFICATECAMERA = 16973;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HARDENINGGUIDE_RENEWCAMERACERTIFICATE = 16974;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_BWC_INHERITFROMARCHIVERISDEPRECATED = 16975;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_MAP_AUTOCAD_ROTATION_OFFSET = 16976;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SOFTWIRE_VERSION = 16977;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTERNALSYSTEM_COMMUNICATIONS_RINGGROUPS = 16978;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTERNALSYSTEM_COMMUNICATIONS_INTERCOMS = 16979;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WEB_API_PATH = 16980;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SERVICE_UNAVAILABLE = 16981;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNABLE_RETRIEVE_PKI_ENDPOINT = 16982;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CERTIFICATE_PROFILE = 16983;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_UNABLE_NAVIGATE_URL_EMPTY = 16984;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DELETED_FILES_ONLY_REMOVED_ON_SUCCESS = 16985;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WARNING_GCS_CUSTOMFIELD_DUPLICATE = 16986;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_RIGHT_CLICK_DRILL_DOWN_OPTIONS = 16987;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_DISPLAY_ON_MAPS = 16988;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_ACTIVE_RECORDS_FOR_PAST = 16989;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_NUMBER_OF_RECORDS = 16990;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_AUTH_SERVICE_REMOVE_USER_GROUPS_TITLE = 16991;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_AUTH_SERVICE_REMOVE_USER_GROUPS_DETAILS = 16992;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_APPLY_CHANGES = 16993;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_AUDITTRAIL_CUSTOMENTITYTYPE = 16994;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SEARCH_THIS_AREA = 16995;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ALL_SOURCES = 16996;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CARDHOLDER_SHARED_OVERRIDE_NOT_SYNCED = 16997;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_PAN_AND_ZOOM = 16998;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_DISPLAY_LOCKED = 16999;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_ZOOM_LOCKED = 17000;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_CONTENT_FOUND = 17001;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_AD_CARDHOLDER_IMPORT_ERROR = 17002;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_CARDHOLDER_EXPIRATION_DURATION = 17003;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_CREDENTIAL_EXPIRATION_DURATION = 17004;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CARDHOLDER_EXPIRATION_CONFLICTS = 17005;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREDENTIAL_EXPIRATION_CONFLICTS = 17006;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADMIN_GROUP_NOT_ALLOWED = 17007;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_GENERAL_SETTINGS_ADVANCED_SETTINGS = 17008;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGES_GENERAL_SETTINGS_ADVANCED_SETTINGS = 17009;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_SPECIFIC_ACTIVATION_DATE = 17010;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARP_CONNECTION_MODE = 17011;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_MAXARCHIVETRANSFERTHROUGHPUT = 17012;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_INVALID_MOBILE_SERVER_CONFIGURATION = 17013;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BOSCH_COMMANDPROTOCOL_CHANGED = 17014;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAP_MINIMAP = 17015;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_MAP_ENABLE_MINIMAP = 17016;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_MAP_DISABLE_MINIMAP = 17017;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_MAP_TOO_SMALL = 17018;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_MAP_FULLY_VISIBLE = 17019;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIVITY_LPR_UNIT_FIRMWARE_UPGRADE_SCHEDULED = 17020;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LAST_SPECIFIC_EXPIRATION_DATE = 17021;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FILE_SAVED_TO_ARCHIVER_DISK = 17022;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_FILE_SAVED_TO_ARCHIVER_DISK = 17023;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_LIGHTWEIGHTRECORDER_TABNAME = 17024;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EVENT2ACTION_USE_SOURCE_TIMEZONE = 17025;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EVENT2ACTION_USE_SOURCE_TIMEZONE = 17026;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_LIGHTWEIGHTRECORDER_CONNECTION = 17027;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_LIGHTWEIGHTRECORDER_UNAUTHORIZED = 17028;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MENU_ITEM_MAP_MINIMAP_UNAVAILABLE = 17029;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SSH_PORT = 17030;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_ENCRYPTEDCUSTOMFIELDNOFILTERS = 17031;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_WIQ_LOCK_LIMIT = 17032;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_MAC_ADDRESS_CONFLICT = 17033;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_TYPE_CONFLICT = 17034;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_WIQ_GATEWAY_LIMIT = 17035;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_UNLOCK_DOOR_MAINTENANCE = 17036;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_UNLOCK_DOOR_MAINTENANCE_RECURSIVE = 17037;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISABLE_MAINTENANCE = 17038;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_DISABLE_MAINTENANCE_RECURSIVE = 17039;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MISSING_ECM_PLUGIN = 17040;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_EXTERNALCARDHOLDERVALIDATION = 17041;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CARDHOLDERSANDCREDENTIALS_REMOVENEVEREXPIRATION = 17042;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_SSL_OR_TLS = 17043;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_STARTTLS = 17044;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_STARTTLS_IF_AVAILABLE = 17045;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLE_DYNAMIC_STARTUP = 17046;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DYNAMIC_STARTUP = 17047;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TIMEZONE_BAHIA_STANDARDABBR = 17048;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_KEEPCONFIGFROMUNITWEBPAGE = 17049;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_MAP_ROLE_BACKWARD_DESCRIPTION = 17050;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INVALID_PASSWORD_CONFIGURATION = 17051;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_PASSWORD_CONFIGURATION = 17052;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_BOOST_QUALITY_VIDEO_GAPS = 17053;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_WIZARD_SELECT_EXPORT_ENTITY_TYPE_STEP_INSTRUCTION = 17054;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TITLE_EXPORTTOOL = 17055;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_WIZARD_EXPORT_SELECT_SOURCE_STEP_INSTRUCTION = 17056;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_WIZARD_SELECT_OPTIONS_STEP_INSTRUCTION = 17057;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARRAYDELIMITER = 17058;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SELECT_FILE_PATH = 17059;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_EXPORTCONFIG_CANT_GET_SOURCE = 17060;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_EXPORT_PROCESS_COMPLETED_SUCCESSFULLY = 17061;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WIZARD_SELECT_SETTINGS_STEP_INSTRUCTION = 17062;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TOOL_DESCRIPTION_EXPORTCONFIG = 17063;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPORT_ALL_ENTITIES_OF_TYPE = 17064;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FEDERATED_ENTITY = 17065;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FEDERATED_ENTITY_DETAILS = 17066;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_PARTIAL_SUCCESS_SERVER_DOWN = 17067;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_VIDEOQUALITY_SUPERHIGH = 17068;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SENDEMAIL_TO_EXTERNAL_RECIPIENTS = 17069;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_SENDEMAIL_TO_EXTERNAL_RECIPIENTS = 17070;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_UNIT_REPLACEMENT_STATUS_CANNOT_COMPLETE_AT_THIS_TIME = 17071;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_GROUPING_SORTORDER = 17072;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_MERCURY_OSDP_READER_ON_SAME_PORT = 17073;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_MUST_BE_ADMIN_SAAS_MOVE_UNIT = 17074;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_CONNECTIONS = 17075;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_EVENTINSERTIONTIME = 17076;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LICENSE_WEB_MOBILE_CONNECTIONS_COUNT = 17077;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CUSTOM_PLUGINS_UNABLE_LOAD = 17078;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SEE_STATISTICS_FOR_DETAILS = 17079;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ARCHIVER_FLOODING_CAMERA_DETAILS = 17080;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ARCHIVER_FLOODING_DETAILS = 17081;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ARCHIVER_DATABASE_FLOODING = 17082;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FORWARDEVENTSIFNOTSOURCEENTITYOWNER = 17083;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FORWARDEVENTSIFNOTSOURCEENTITYOWNER = 17084;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DYNAMIC_STARTUP = 17085;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORKSTATION_MANAGEMENT = 17086;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_FEATUREDESCRIPTION_WORKSTATION_MANAGEMENT = 17087;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_LPRUNIT_LOCATIONS = 17088;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_AREA_LOCATIONS = 17089;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_ASSET_LOCATIONS = 17090;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_CAMERA_LOCATIONS = 17091;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_CUSTOMENTITY_LOCATIONS = 17092;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_DEVICE_LOCATIONS = 17093;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_DOOR_LOCATIONS = 17094;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_INTRUSIONUNIT_LOCATIONS = 17095;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_MAP_LOCATIONS = 17096;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_VIDEOUNIT_LOCATIONS = 17097;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_ZONE_LOCATIONS = 17098;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_ACCESS_EVENTS = 17099;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_CAMERA_EVENTS = 17100;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_ZONE_EVENTS = 17101;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_ALARM_INSTANCES = 17102;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_TYPE_INTRUSION_EVENTS = 17103;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HRECORD_TYPE_EALTH_EVENTS = 17104;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TASKSHORTDESC_WORKSTATION = 17105;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SID = 17106;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRST_CONNECTION = 17107;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_USER_ACCESS = 17108;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOUN_DISPLAY_MODE = 17109;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_WIZARD_ADD_NEW_WORKSTATION = 17110;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ADD_ENTITY_ENTITY_WORKSTATION_INFO_PAGE_TITLE = 17111;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_WIZARD_ADD_SELECT_SOURCE_STEP_INSTRUCTION = 17112;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_ARCHIVER_DATABASE_FLOODING = 17113;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_LIVE_STREAM_STARTED = 17114;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PHYSICAL_LOCATION = 17115;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GEOGRAPHICAL_LOCATION = 17116;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRESETTOUR = 17117;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SELECT_PHYSICAL_LOCATION = 17118;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CANNOT_MOVE_LPR_UNIT_SSH = 17119;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUM_ROLES_REQUIRING_QUERY_EXPANSION = 17120;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_REPORT_MISSING_EVENTS = 17121;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_PARTITION_ACCESS = 17122;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_REMOVE_WORKSTATION_MEMBERS = 17123;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DEPENDENTENTITIES = 17124;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DEPENDENTENTITIESSHOWMORE = 17125;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEEPDEPENDENTENTITIES = 17126;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_LOWDISKSPACE_LOW = 17127;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_LOWDISKSPACE_NORMAL = 17128;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HEALTHEVENT_LOWDISKSPACE_NOTMONITORED = 17129;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_LIVE_STREAM_STOPPED = 17130;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORKSTATION_ACCESS_RIGHTS_EXCEPTIONS = 17131;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WORKSTATION_PRIVILEGES_EXCEPTIONS = 17132;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_REMOVE_MEMBER_FROM_WORKSTATIONS = 17133;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_LIST_CHANGES_AUTOMATICALLY_APPLIED = 17134;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHARP_SSH_CONNECTION_STATUS = 17135;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_MULTISORTING_COLUMN_HEADER_CLICK_OVERRIDE = 17136;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESS_DECISION_AUTHORITY = 17137;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_LPR_UNIT_FIRMWARE_UPGRADE_CANCELLED = 17138;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVERADMIN_SSL_PROTOCOL_OPTION_USE_OS_DEFAULT = 17139;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVERADMIN_SSL_PROTOCOL_OPTION_USE_WHITELIST = 17140;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SERVERADMIN_SELECT_AT_LEAST_ONE_PROTOCOL = 17141;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ALPR_UNIT_FIRMWARE_UPGRADE = 17142;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ALPR_UNIT_FIRMWARE_UPGRADE = 17143;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPGRADE_PROGRESSION = 17144;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MARGINS = 17145;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MORE_SETTINGS = 17146;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PAGES = 17147;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_COPIES = 17148;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINTER_PROPERTIES = 17149;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRINTER = 17150;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PDF_PRINT_PAGE_COUNT = 17151;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PDF_PRINT_TOTAL_SHEETS_COUNT = 17152;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PDF_PRINT_ILLEGAL_CHARACTERS = 17153;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PDF_PRINT_NEGATIVE_END_PAGE = 17154;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PDF_PRINT_NEGATIVE_START_PAGE = 17155;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PDF_PRINT_END_SMALLERTHAN_START = 17156;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PDF_PRINT_PAGE_OUT_OF_RANGE = 17157;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_LPRUNIT_SUPPORTS_REBOOT = 17158;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXTERNAL_RECIPIENTS = 17159;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_EXTERNAL_RECIPIENTS = 17160;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LEGACY_WEB_SERVER = 17161;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LEGACY_WEB_CLIENT = 17162;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PDF_PRINT_CUSTOM_MARGINS_INFO = 17163;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_TEST_USB_READER = 17164;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_FILE_DETAILS = 17165;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_READ_KEY = 17166;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_CUSTOM_STRING = 17167;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_CREDENTIAL = 17168;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_BADGE_CONFIGURATION = 17169;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_APPLICATION_DETAILS = 17170;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_FILE_SELECTION = 17171;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_ASCII = 17172;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_UTF16 = 17173;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_TEXT_ALIGNEMENT_LEFT = 17174;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_TEXT_ALIGNEMENT_RIGHT = 17175;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_CUSTOM_STRING_MANAGEMENT = 17176;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_CONTENT = 17177;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_REMAINING_SPACE = 17178;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_OFFSET = 17179;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_TEXT_ALIGNEMENT = 17180;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_X_KEYS_USED_OF_Y = 17181;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DESFIRE_UPDATE_ALL_KEYS = 17182;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_WRITE_KEY = 17183;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_READ_WRITE_KEY = 17184;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_CHANGE_ACCESS_KEY = 17185;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_DESFIRE_KEY_NUMBERS = 17186;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_FREE_KEY = 17187;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_NOTHING_TO_IMPORT = 17188;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESSDECISIONAUTHORITY_ACCESSMANAGER = 17189;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESSDECISIONAUTHORITY_SOFTWIRE = 17190;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACCESSDECISIONAUTHORITY_INTELLIGENTCONTROLLER = 17191;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_CHANGE_CONFIG_WITHOUT_SAVE = 17192;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_CARDHOLDER_INFORMATION_NOT_BE_ENCODED = 17193;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_DUPLICATE_READS = 17194;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_DUPLICATE_READS = 17195;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DESFIRE_UTF16_CUSTOM_STRING_TOO_LONG = 17196;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_FILL_WITH_NULL = 17197;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_OPTIMIZEDDIRECTORYSTARTUP_SUCCEEDED = 17198;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_OPTIMIZEDDIRECTORYSTARTUP_FAILED = 17199;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_OPTIMIZEDDIRECTORYSTARTUP_NOTMONITORED = 17200;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_DIAGNOSTIC_SECURE_CONNECTION_NOT_ESTABLISHED = 17201;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGE_MODIFY_MAINTENANCE_REASONS = 17202;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MODIFY_MAINTENANCE_REASONS = 17203;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_READER_ADDRESS_ALREADY_IN_USE = 17204;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_PDF_PRINT_NO_PRINT_SERVER = 17205;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PDF_PRINT_CHECK_PRINT_SERVICES_AND_PRINTERS = 17206;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_VALUE_HELP_USER_GUIDE_LANGUAGE_CODE = 17207;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXPANDO_EVENT_TIMESPAN_RANGE = 17208;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOWER_BOUND = 17209;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UPPER_BOUND = 17210;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_MINIMUM = 17211;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_UPPER_BOUND = 17212;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_MAINTENANCE_REASONS = 17213;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTENANCE_REASON_ADD = 17214;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAINTENANCE_REASON_MODIFY = 17215;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_CREDENTIAL_FILE = 17216;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_DELETE_FILE = 17217;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_DELETE_CONFIGURATION = 17218;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_DELETE_CUSTOMSTRING = 17219;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNABLED_TO_SATISFY = 17220;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_PROFILE_SECURE_EMAILS = 17221;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SET_USER_PROFILE = 17222;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PERMISSION_DENIED = 17223;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_REPORTMANAGER_NO_SECURE_USER_PROFILE = 17224;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HOTLISTLANGUAGE_THAI = 17225;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ENABLE_DIGITAL_SIGNATURE = 17226;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_OPTIMIZEDDIRECTORYSTARTUP_DIRECTORY_AVAILABLE = 17227;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_OPTIMIZEDDIRECTORYSTARTUP_STARTUP_FASTER = 17228;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_OPTIMIZEDDIRECTORYSTARTUP_STARTUP_SLOWER = 17229;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_OPTIMIZEDDIRECTORYSTARTUP_FAILURE_PHASE = 17230;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_OPTIMIZEDDIRECTORYSTARTUP_FAILURE_COMPLETE = 17231;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_ACUNIT_VIEWPORTAL = 17232;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_ACUNIT_VIEWPORTAL = 17233;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LC_NOT_LIKE = 17234;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEARCH_CONDITIONS = 17235;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_EDIT_CONDITIONS = 17236;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CONVERSION_PARTIAL_SUCCESS_FOUND_FRAMES_BACK_IN_TIME = 17237;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_XOR = 17238;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_CRITERION = 17239;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_ROTATE_SYNERGIS_PASSWORDS_PERIODICALLY = 17240;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_BADGE_TEMPLATE_IMAGE_INVALID = 17241;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_EQUAL_TO = 17242;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_NOT_EQUAL_TO = 17243;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DOES_NOT_CONTAIN = 17244;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_IN = 17245;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_NOT_IN = 17246;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_GREATER_THAN = 17247;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_LESS_THAN = 17248;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_GREATER_THAN_OR_EQUAL_TO = 17249;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_LESS_THAN_OR_EQUAL_TO = 17250;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CONVERSION_PARTIAL_SUCCESS_H264_FRAME_SLICE_MISSING = 17251;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATED_ENTITY_TYPES = 17252;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_FEDERATION_ENTITYTYPEFILTERS_ADDED = 17253;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_AUDITTRAILS_FEDERATION_ENTITYTYPEFILTERS_REMOVED = 17254;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_LIVE_STREAM_REQUESTED_FEDERATION = 17255;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_PLAYBACK_STREAM_REQUESTED_FEDERATION = 17256;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_WORKSTATION_MANAGEMENT_FEATURE_DEACTIVATION = 17257;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_PRIVILEGE_TO_ADD_UNIT_TO_ACCESSMANAGER_PARTITION = 17258;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_CREATION_DATE = 17259;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CREATEAGENTSONROLESERVERS = 17260;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CREATEAGENTSONROLESERVERS = 17261;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_FEDERATION_ENTITY_ADVANCED_SETTINGS_INFO = 17262;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NUMBER_2_RESERVED_FOR_UID_RETRIEVAL = 17263;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEY_NUMBER_IN_APPLICATION = 17264;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_READKEY_OR_READ_WRITEKEY_MUST_BE_DEFINED = 17265;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DESFIRE_HAVE_AT_LEAST_ONE_CUSTOM_STRING = 17266;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_MODIFY_DATA_OF_CREDENTIAL_SECTION = 17267;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NEW_CONFIGURATION = 17268;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CONFIGURATIONS = 17269;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_UID_RETRIEVAL_KEY = 17270;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DESFIRE_UTF16_CUSTOM_STRING_TOO_LONG = 17271;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NOUN_DESFIRE_READ_KEY = 17272;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NOUN_DESFIRE_WRITE_KEY = 17273;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NOUN_DESFIRE_READ_WRITE_KEY = 17274;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_NOUN_DESFIRE_CHANGE_ACCESS_KEY = 17275;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DESFIRE_LEAST_ONE_CONFIGURATION_SELECTED = 17276;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTODISCONNECT = 17277;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SESSION_DISCONNECT = 17278;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SESSION_ABOUT_TO_DISCONNECT = 17279;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ENHANCED_SEARCH_PASTE_CANCELED = 17280;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ENHANCED_SEARCH_PASTE_INCORRECT = 17281;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_GIVE_RIGHTS_TO_MACHINEKEYS_FOLDER = 17282;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_ALARMRESOLUTIONTIMESPAN = 17283;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REPORT_WINDOWS_FONT_FAMILY__DONT_TRANSLATE = 17284;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USERGROUP_DISCONNECT_DURATION = 17285;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DAYS_AND_OLDER = 17286;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_OVERWRITE_CONDITION = 17287;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_OVERWRITE_CONDITION = 17288;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PRIVILEGES_BULKACKNOWLEDGEALARMS = 17289;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_LABEL_PRIVILEGES_BULKACKNOWLEDGEALARMS = 17290;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECLAIM_OWNERSHIP_CB = 17291;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INPUT_UNIQUE = 17292;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_OUTPUT_UNIQUE = 17293;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_LOGON_PREVIEW = 17294;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_BROWSER_CONSOLE = 17295;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_EXPORT_LOGS = 17296;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_TEST_AUTHENTICATION = 17297;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_CHECKBOX_INCLUDE_WARNING = 17298;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ITEM_OVEWRITTEN = 17299;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_RESET_CONFIGURATION = 17300;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_ENDPOINTS = 17301;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_RESET_ENDPOINT_CONFIGURATION = 17302;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_RESET_ENDPOINTS_PROCEDURE = 17303;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_INVALID_URI = 17304;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_USER_DEFINED_ENDPOINT = 17305;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_EDIT_READ_ANALYTIC = 17306;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_LOGON_SUCCESSFUL = 17307;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TOGGLE_PII = 17308;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTION_CONNECTION_SERVERCONSOLE = 17309;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_EDIT_READ_ANALYTIC = 17310;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_TOOLTIP_REPORT_SINGLE_USER_AUTHORIZATION = 17311;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SHOW_SCALE_INDICATOR = 17312;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HIDE_SCALE_INDICATOR = 17313;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_LOGS_SERVERSIDE = 17314;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_OLDVERSIONCONFIGURATION_INCOMPATIBLE = 17315;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NO_SOURCE = 17316;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_SUBSCRIBING_TO_LOGGERS_FAILED = 17317;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BROWSER_CONSOLE = 17318;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CONNECTION_SERVERADMIN_CONSOLE_FAILED = 17319;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SOME_UNITS_CONTAIN_EXPIRED_CERTIFICATES_CANNOT_RENEW_DUE_TO_POLICY = 17320;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_EXPORT_REPORT_NAME_TYPE_LOCATION_AUTHORIZED = 17321;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_ACTIVITYTRAIL_PRINT_REPORT_NAME_TYPE_PRINTER_AUTHORIZED = 17322;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_REPLACE_CARHOLDER_FIELDS = 17323;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_BEFORE_START = 17324;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_WIZARD_CONSULT_TECH_DOC = 17325;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIND_HERE = 17326;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SUSPEND_WIZARD = 17327;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SUSPEND = 17328;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_WIZARD_STEP_BY_STEP = 17329;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCALE_INDICATOR_UNAVAILABLE = 17330;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_APP_REGISTRATION = 17331;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_AZURE_AD_APP = 17332;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AZURE_PORTAL = 17333;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATED_DOCUMENTATION = 17334;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INTEGRATE_AZURE_OPEN_ID = 17335;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_REGISTER_ENDPOINT = 17336;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SERVER_INDEPENDENT_SETTINGS = 17337;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PER_SERVER_SETTINGS = 17338;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PER_SERVER_SETTINGS = 17339;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SERVER_RECORDING_DISABLED = 17340;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ARCHIVING_ENABLED = 17341;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_3RDPARTY_NEEDED_TO_OPEN_ZIP_FILE = 17342;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_OPTIONS_FILE_ERROR_LINE_X_POSITION_Y = 17343;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MIGRATE = 17344;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFO_BACKUP_OLDVERSIONCONFIGURATION_DESFIRE = 17345;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_HITCH = 17346;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_MAKE_MODEL = 17347;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_REAR_ATTACHMENT = 17348;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_SPARE_TIRE = 17349;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIELD_VEHICLE_TYPE_ENHANCED = 17350;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CORE_CONFIGURATION = 17351;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_CLAIMS_AND_SCOPES = 17352;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_LOAD_SETTINGS = 17353;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_TEMPORARY_SETTINGS = 17354;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RELATED_HELP = 17355;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_METADATA = 17356;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AZURE_AD_CONFIGURATION = 17357;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_EXAMPLE_AZURE_AD_METADATA_URL = 17358;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_MANUAL_METADATA = 17359;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VALID_METADATA = 17360;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_METADATA_UTILITY = 17361;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_HIDE_METADATA = 17362;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SHOW_METADATA = 17363;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_PASTE_METADATA = 17364;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_SAVE_ROLE_STATE = 17365;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CUSTOMFIELDENCRYPTION_REQUIRESDIRECTORY5111ANDUP = 17366;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_WIZARD_IDENTITY_AND_PROTOCOL = 17367;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HELP_WHICHPROTOCOL = 17368;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_HELP_THIRDPARTYAUTH = 17369;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_OIDC_DESCRIPTION = 17370;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SAML_DESCRIPTION = 17371;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETEC_WEBAPP_PREVIEW = 17372;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_GENETEC_WEBAPP_PREVIEW_DESCRIPTION = 17373;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_TOGGLE_PII = 17374;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISPLAY_OFFLINE_UNITS = 17375;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMUMDISKSPACE = 17376;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUMDISKSPACE = 17377;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VERB_ENABLECOMPRESSION = 17378;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MAXIMUMLOGFILESIZE = 17379;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_VISIBLE_FROM = 17380;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CURRENT_SCALE = 17381;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_SCALE = 17382;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ZOOM_AUTO = 17383;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SCALE_FACTOR_ITEM_NOT_VISIBLE_FORMAT = 17384;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_VERB_CREATETRACERGROUP = 17385;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RETENTIONPOLICY = 17386;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DISKUSAGE_LOGFILESIZE = 17387;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_TRACERGROUPNAME = 17388;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_TRACERGROUP = 17389;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_FILTER_NOT_SUPPORTED = 17390;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AVERAGE_SPEED = 17391;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_DUPLICATE_FILTER = 17392;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SYNCHRONIZE_WITH_ADMIN_USER = 17393;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DESFIRE_DIVERSIFICATION_MODE = 17394;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DESFIRE_UNSUPPORTED_MODE = 17395;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SYNCHRONIZE_WITH_ADMIN_USER_DESCRIPTION = 17396;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USER_DEFINED = 17397;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTER_URL_HERE = 17398;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENETEC_WEBCLIENT_LEGACY = 17399;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER1_RETENTION_PERIOD = 17400;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER2_RETENTION_PERIOD = 17401;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_MIFAREDESFIREREMOVEKEYS = 17402;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_MIFARE_DESFIRE_REMOVE_KEYS = 17403;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KEY_HASH = 17404;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_REMOVE_KEY = 17405;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_SHOW_KEY = 17406;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MILES_PER_HOUR_ABBREV = 17407;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_KILOMETER_PER_HOUR_ABBREV = 17408;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEET_PER_HOUR_ABBREV = 17409;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_METER_PER_HOUR_ABBREV = 17410;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DIAGNOSTIC_LPR_MAXIMUM_UNITS_LIMITS = 17411;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_HEALTHEVENT_DIAGNOSTIC_LPR_MAXIMUM_UNITS_LIMITS_DETAILS = 17412;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER1_RECORDING_MODE = 17413;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FAILOVER2_RECORDING_MODE = 17414;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIT_WINDOW = 17415;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIT_TO_WINDOW_DETAIL = 17416;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AUTO_HIDE_HEADER = 17417;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TASKSHORTDESC_IDENTICAL_PLATE_MULTI_VEHICLE = 17418;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_REPORT_IDENTICAL_PLATE_MULTI_VEHICLE = 17419;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MINIMUM_DISTANCE = 17420;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_EVEN_NUMBER_OF_CHARACTERS = 17421;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_IP_ADDRESS_FILTER = 17422;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FIRMWARE_UPGRADE_WITH_LOCAL_FILE = 17423;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_PRINT_EXPORT_SINGLE_USER_AUTHORIZATION = 17424;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENTITY_STATE_WIDGET = 17425;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_WIDGET_ENTITYSTATE_DESCRIPTION = 17426;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_NOT_AVAILABLE = 17427;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CERT_KEY_SMALLER_THAN = 17428;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_FEDERATE_CUSTOM_ICONS = 17429;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SEARCH_SELECTED_HOTLISTS = 17430;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OCCURRENCE = 17431;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SEARCH_SELECTED_HOTLISTS_AND_PERMITS = 17432;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_DESFIRE_CONFIGURATION_CANNOT_BE_ENCODED_WITHOUT_CREDENTIAL = 17433;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DESFIRE_NO_CONFIGURATION_WITH_CREDENTIAL_SELECTED = 17434;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_STEP_METADATA_RETRIEVED = 17435;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_STEP_INITIAL_REQUEST = 17436;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_STEP_IDP_RESPONSE = 17437;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_STEP_TOKEN_PROVIDED = 17438;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_STEP_TOKEN_VALID = 17439;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_STEP_SYNCHRONISATION_GROUPS = 17440;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_RECORD_SOURCE = 17441;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPTIONS_SHOW_STATE = 17442;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AZURE_AD = 17443;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AZURE_ACTIVE_DIRECTORY = 17444;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OKTA = 17445;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OPEN_ID_CONNECT = 17446;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SAML_2_0 = 17447;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_WIZARD_NAME_DESCRIPTION = 17448;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SUCCESSFULL_CONFIGURATION = 17449;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SEE_AUTHENTICATION_ERROR = 17450;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_LOG_PII = 17451;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_PII_ACTIVATION_TEST_LOGIN = 17452;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_COPIED = 17453;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SELECT_OKTA_APP = 17454;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_OKTA_ADD_REDIRECT_URI = 17455;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OKTA_CONFIGURATION = 17456;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OKTA_OPEN_ID_HELP_LINK = 17457;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OKTA_SAML2_HELP_LINK = 17458;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_WS_FAMILY_HELP_LINK = 17459;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_CONFIRM_ENDPOINTS_REGISTERED = 17460;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_ENDPOINTS_PURPOSE = 17461;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SUSPEND_SAVES_SETTINGS = 17462;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_TEST_CONFIGURATION = 17463;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_TEST_AUTHENTICATION_SERVICE = 17464;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_RECOMMEND_TEST = 17465;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_TEST_LOGIN = 17466;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_FILES_DELETED_BEFORE_UPLOAD_TO_CLOUD_ON_RETENTION = 17467;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_AZURE_AD_USER_GROUPS = 17468;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_OKTA_USER_GROUPS = 17469;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_WS_FED_USER_GROUPS = 17470;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_DEFAULT_USER_GROUPS = 17471;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_GROUP_HAS_MEMBERS = 17472;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_DISASSOCIATING_SSO_GROUPS = 17473;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_USER_GROUP_NO_LOGIN_PRIVILEGES = 17474;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SELECT_EXISITING_GROUP = 17475;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_CREATE_GROUP = 17476;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_EXTERNAL_UNIQUE_ID = 17477;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OBJECT_ID = 17478;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_VALIDATE_INFORMATION = 17479;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_UNIQUE_TO_EACH_USER = 17480;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_ADD_ANOTHER_SCOPE = 17481;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ADD_DOMAINS = 17482;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_HAVE_AT_LEAST_ONE = 17483;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_USE_PROVIDER_AS_LOGON_OPTION = 17484;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_DUPLICATE_DOMAIN = 17485;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_DOMAINS = 17486;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TEST_LOGON_RESULT = 17487;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ENABLEKEYROTATION = 17488;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_DATAKEYROTATIONPERIOD = 17489;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_MASTERKEYSROTATIONPERIOD = 17490;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CLIENT_SECRET_PURPOSE = 17491;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_IS_CLIENT_SECRET_CONFIGURED = 17492;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CLIENT_SECRET_EMPTY = 17493;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNABLE_TO_RETRIEVE_METADATA = 17494;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CLIENT_SECRET_INVALID = 17495;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CLIENT_SECRET_VALID = 17496;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_WS_FAMILY_DOMAIN_NO_TEST_LOGON = 17497;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_DOMAIN_FORMAT = 17498;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_VALID_DOMAINS = 17499;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SSO_NO_DISPLAY_NAME = 17500;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_EMPTY_METADATA = 17501;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_VALID_METADATA = 17502;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_METADATA_INPUT_INVALID = 17503;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_METADATA_URI_INVALID = 17504;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_RESPONSE_FROM_PROVIDER = 17505;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_METADATA_RESPONSE_INVALID = 17506;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_INFO_COMMUNICATE_IDP = 17507;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_ISSUER_URI = 17508;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNREACHABLE_OPEN_ID_ENDPOINTS = 17509;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_METADATA_RETREIVED = 17510;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_ISSUER = 17511;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_UNREACHABLE_SAML2_ENDPOINTS = 17512;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ISSUER_VALID = 17513;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CLIENT_ID_FORMAT_INVALID = 17514;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CLIENT_ID_GUID = 17515;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_CLIENT_ID_URI = 17516;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_APPLICATION_CLIENT_ID_NOT_FOUND = 17517;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_CLIENT_ID_VALID = 17518;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_WARNING_SAML2_AUDIENCE = 17519;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_APPLICATION_CLIENT_ID = 17520;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALID_URI = 17521;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_CLOUD = 17522;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_EVENT_CAMERA_FILES_DELETED_BEFORE_UPLOAD_TO_CLOUD_ON_DISK_FULL = 17523;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_WIZARD_PREREQUISITES = 17524;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_SUSPEND_BUTTON = 17525;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_SSO_AUTO_SAVE = 17526;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AZURE_AD_ISSUER_COMPLEMENT = 17527;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERVIEW_ENDPOINT = 17528;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_AZURE_AD_CLIENT_ID_COMPLEMENT = 17529;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OVERVIEW = 17530;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OKTA_OPEN_ID_ISSUER_COMPLEMENT = 17531;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SECURITY_API_AUTHORIZATION_SERVERS = 17532;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OKTA_COMPLEMENT = 17533;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERAL_CLIENT_CREDENTIALS = 17534;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIGN_ON_SAML_SETUP_INSTRUCTIONS = 17535;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_GENERAL_AUDIENCE_RESTRICTION = 17536;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_AZURE_AD_SAML2_INSTRUCTIONS = 17537;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_OKTA_OPEN_ID_INSTRUCTIONS = 17538;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_OKTA_ISSUER_URI = 17539;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_OKTA_SAML2_INSTRUCTIONS = 17540;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_SIGN_ON = 17541;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_LABEL_ACTIONS_VIEW_IDP_METADATA = 17542;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_STEP_METADATA_RETRIEVED = 17543;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_STEP_INITIAL_REQUEST = 17544;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_STEP_IDP_RESPONSE = 17545;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_STEP_TOKEN_PROVIDED = 17546;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_STEP_TOKEN_VALID = 17547;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_STEP_SYNCHRONISATION_GROUPS = 17548;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_ERROR_TEST_LOGIN_NO_LOG = 17549;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_SEE_LOG_OUTPUT = 17550;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_BEFORE_YOU_CONTINUE = 17551;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TITLE_COMMUNICATE_WITH_PROVIDER = 17552;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_ITEMS_ARE_NOW_IN_TASK_FORMAT = 17553;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_BUTTON_TAKE_ME_TO_FORMAT = 17554;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_MESSAGE_INFO_WHEN_READY_REMOVE_TASK_FORMAT = 17555;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_PRIVILEGE_DELETE_CAMERAS_WITH_ARCHIVES = 17556;
    public static Genetec_Sentinel_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_DELETE_CAMERAS_WITH_ARCHIVES = 17557;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_PRODUCTGROUP_LPR = 201326592;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_DEFAULT_OUTPUT_RELAY_NAME = 201326597;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_HOTLIST_AND_PERMIT = 201326600;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_PATROLLER_OPTION_HITS_DUPLICATE_HIT_DELAY = 201326601;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_ASSIGNMENT = 201326603;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PASSIVE = 201326604;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_SWAPERROR_UNITNOTAVAILABLE = 201326605;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_SWAPERROR_ENTITYISRUNNING = 201326606;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_SWAPERROR_DIFFERENTMODELS = 201326607;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_SWAPERROR_NOTFIXEDSHARP = 201326608;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_SWAPERROR_ENTITYISREADONLY = 201326609;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_SWAPERROR_DIFFERENTDEVICES = 201326610;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_UNITSWAPPED_DELETEME = 201326611;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_NOT_SENT_EMAIL = 201338094;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MSGBOX_BODY_EDIT_READ_PROTECTED = 201338095;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MSGBOX_BODY_EDIT_READ_MODIFIED = 201338096;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PROTECTION_STATUS_NO_EXPIRATION = 201338097;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_DAYS_LEFT = 201338098;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_HEALTHMONITOR_DIAGNOSTICS_PASTREADERROR = 201338099;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_FIELD_POSTMATCHED = 201338100;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_HEALTHMESSAGE_PASTREADMATCHING_ALLHOTLISTSNOTASSOCIATED = 201338101;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_HEALTHMESSAGE_PASTREADMATCHING_MATCHINGMUSTBEENABLED = 201338102;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_HEALTHMESSAGE_PASTREADMATCHING_SOMEHOTLISTSNOTASSOCIATED = 201338103;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_HEALTHMESSAGE_PASTREADMATCHING_UPDATEACTION = 201338104;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_CONFIRM_UNPROTECTION = 201338105;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_CONFIRM_UNPROTECTION_INFORMATION = 201338106;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ACTIVITYTRAIL_DELETE_HIT = 201338107;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PAST_READ_MATCHING = 201338108;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_NO_MATCH_EVENTS = 201338109;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_AUTOVU_PASTREADMATCHING_HOTLISTS = 201338110;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MSGBOX_BODY_UNSELECT_FROM_PASTREADMATCHING = 201338111;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MSGBOX_ACTION_UNSELECT = 201338112;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_WARNING_SLOW_QUERY_WITH_IMAGES = 201338113;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PATROLLER_POSITIONS = 201338114;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PATROLLER_EVENTS = 201338115;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PARKING_EVENTS = 201338116;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PARKING_OCCUPANCY = 201338117;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ACTION_SET_PARKING_ZONE_OCCUPANCY = 201338118;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_CONNECTION_CONNECTING_TO_SHARP_PORTAL = 201338119;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_CONNECTION_ADD_UNIT_NOT_SECURE = 201338120;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_HEALTHEVENT_DEVICES_CONNECTIVITY_PROBLEM_WITH_FUSIONSTREAM = 201338121;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LPM_PROTOCOL = 201338122;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_AUDITTRAILS_LPM_PROTOCOL_HTTP_LISTENING_PORT = 201338123;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LPM_PROTOCOL_INFO = 201338124;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LPM_PROTOCOL_UPDATE_PORT_WARNING = 201338125;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_DIAGNOSTIC_DUPLICATED_LIVE_PORT_MESSAGE = 201338126;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_DIAGNOSTIC_DUPLICATE_PORT_DETAILS = 201338127;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_DIAGNOSTIC_DUPLICATE_DISCOVERY_PORT_MESSAGE = 201338128;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_DIAGNOSTIC_DUPLICATE_LPM_PROTOCOL_PORT_MESSAGE = 201338129;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LPM_PROTOCOL_DISABLED_INFO = 201338130;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_UPGRADE_TO_LPM_PROTOCOL = 201338131;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_CANNOT_UPGRADE_TO_LPM_PROTOCOL_INFO = 201338132;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_UPGRADE_TO_LPM_PROTOCOL_FAILED = 201338133;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_UPGRADE_TO_LPM_PROTOCOL_SUCCESS = 201338134;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ERROR_WHILE_GETTING_LPR_UNIT_CAPABILITIES = 201338135;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_WARNING_PBP_PERMIT_OR_PERMIT_RESTRICTION_MISSING = 201338136;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PERMIT_OR_PERMIT_RESTRICTION = 201338137;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_PERMIT_RESTRICTION_REQUIRES_PAY_BY_PLATE_VERSION = 201338138;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ERROR_INVALID_IPADDRESS_OR_PORT_USED_FOR_HTTPS_CONNECTION = 201338139;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_WARNING_SWAP_DIFFERENTPROTOCOLMODELS = 201338140;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_HOURS_LEFT = 201338141;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_UNIT_UNREACHABLE = 201338142;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_UNIT_UNREACHABLE = 201338143;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ERROR_DUPLICATE_LPR_UNIT_ROLE = 201338144;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_HTTPS_PORT = 201338145;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_FILE_ASSOCIATION_FOR_SHARP_INFO = 201338146;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ERROR_UNIT_UPGRADE_REQUIRED = 201338147;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_FOLDER_DOES_NOT_USABLE_TEMPLATES = 201338148;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_FOLDER_IS_MISSING_USABLE_TEMPLATES = 201338149;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MANUALENROLLMENT_CONFIGURED = 201338150;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MANUALENROLLEMENT_FAILED_TIMEOUT = 201338151;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_WARNING_ZERORETENTION = 201338152;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MANUALENROLLMENT_CONFIGURED_MINUTE = 201338153;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ERROR_DUPLICATE_LPR_UNIT_AREA = 201338154;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_FAILED_TO_EXPORT_PDF = 201338155;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_INFO_IPADDRESS_CHANGE_LPMSHARP = 201338156;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_UNITSWAP_NO_PHYSICAL_ADDRESS = 201338157;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LPR_MANAGER_UNREACHABLE = 201338158;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LEGACY_PROTOCOL_UPGRADEABLE = 201338159;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LEGACY_PROTOCOL = 201338160;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_WARNING_DUPLICATE_DATABASE_REFERENCE = 201338161;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_WARNING_DUPLICATE_DATABASE_REFERENCE_DETAILS = 201338162;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_EDIT_EMAILS_DELIMITTER_TO_USE = 201338163;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_SEARCH_TIPS = 201338164;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TOOLTIP_PLATE_FILTER_INFO = 201338165;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TOOLTIP_PLATE_FILTER_LABEL_INFO = 201338166;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_EXPORTER_PUBLISH_FAILED = 201338167;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_EXPORTER_EXPORT_FAILED = 201338168;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_LPR_MONITORING = 201338169;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_NO_HOTLIST_HIT_TO_DISPLAY = 201338170;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_SSLCERTIFICATEREQUIRED = 201338171;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_FILEISNOTVALIDCERTIFICATE = 201338172;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_URL = 201338173;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_MANDATORYINT = 201338174;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_SSLCERTIFICATE = 201338175;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_TOKENURL = 201338176;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_SCOPE = 201338177;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_CUSTOMERID = 201338178;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_ENDPOINTS = 201338179;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_ENDPOINTNAME = 201338180;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_SERVERURL = 201338181;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TITLE_FILEFORMATSPECIFIC = 201338182;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_AUTHORIZATION = 201338183;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_AUTHORIZATIONMODE = 201338184;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_DATAEXPORTER_INVALIDCONFIG = 201338185;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_LICENSEMAXENDPOINTSEXCEEDED = 201338186;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TITLE_DATAEXPORTER_DISPLAYNAME = 201338187;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_INFO_DATAEXPORTER_DESCRIPTION = 201338188;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TITLE_LIVE_HIT_MONITORING = 201338189;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_CRITICAL = 201338190;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TOOLTIP_DATAEXPORTER_CRITICAL = 201338191;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_HITS_ENFORCEDONLY = 201338192;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_FILENAMETEMPLATE = 201338193;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_SSHKEY = 201338194;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_SSHPASSPHRASE = 201338195;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_INFO_FILENAMETEMPLATE = 201338196;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_INFO_EDIT_METADATASUGGESTIONS_WITHDELIMITER = 201338197;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TITLE_EDIT_METADATASUGGESTIONS = 201338198;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TOOLTIP_EDIT_METADATASUGGESTIONS = 201338199;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TITLE_MODIFY_KNOWN_VALUE = 201338200;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_KNOWN_VALUE = 201338201;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_COMPANYCODE = 201338202;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_CONTRACTCODE = 201338203;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_QUEUEINGPROTOCOL = 201338204;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_RABBITMQCOMMUNICATION = 201338205;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_MAXENDPOINTSEXCEEDEDPERALPRROLE = 201338206;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_WATERMARKSTEMPLATE_AVAILABLEVARIABLES = 201338207;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_ADDWATERMARKSTOCONTEXTIMAGES = 201338208;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_WATERMARKSTEMPLATE = 201338209;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALIDAUTHTOKEN = 201338210;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_EMPTYAUTHTOKEN = 201338211;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_ERROR_INVALIDENDPOINT = 201338212;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_CANNOT_USE_HOSTNAME_WITH_LEGACY_CONNECTION = 201338213;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_LABEL_FILTER_TIMESTAMP_SHARPS = 201338214;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_TITLE_UNITS_OFFLINE = 201338215;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_WARNING_UNITS_OFFLINE_LIST = 201338216;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_ACTION_ADDTOHOTLIST = 201338217;
    public static Genetec_LicensePlateManagement_Resources_RE_L_S_STE_MESSAGE_WARNING_NOPLATEINFO = 201338218;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_PRODUCTGROUP_VIDEO = 268435456;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_METADATA_RECEIVING_RATE = 268435457;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_METADATA_WRITING_RATE = 268435458;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_END_STOP_RIGHT = 268435459;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_END_STOP_LEFT = 268435460;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_END_STOP_UP = 268435461;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_END_STOP_DOWN = 268435462;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_ACTIVATE_END_STOP_POSITIONS = 268435463;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_VT_DEACTIVATE_END_STOP_POSISIONS = 268435464;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BACKLIGHT_COMPENSATION_ON = 268435465;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BACKLIGHT_COMPENSATION_OFF = 268435466;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_COAX_PTZ_CONTROL = 268435467;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_ARCHIVE_TRANSFER = 268435468;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_RESTORE_VIDEO_ARCHIVES = 268435469;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVER_BACKUP_FOLDER = 268435470;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BUTTON_FIND_ARCHIVES = 268435471;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVERS = 268435472;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_OFFLINE_CAMERA_ARCHIVES = 268435473;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_EVERYTHING_SINCE_LAST_BACKUP = 268435474;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PLAYBACK_REQUESTS_FROM_EDGE = 268435475;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_ANALYTICS_EVENTS = 268435476;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RESPECTIVE_ARCHIVER = 268435477;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_EVENTS_OFFLOAD = 268435478;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_TRANSFER_GROUP_PROPERTIES = 268435479;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_TYPE = 268435480;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_EDGE_TO_ARCHIVER = 268435481;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVER_TO_ARCHIVER = 268435482;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RESPECTIVE_ARCHIVER_BACKUP_FOLDER = 268435483;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_FULL_ARCHIVER = 268435484;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_COMMAND_CLEAR_PRESET = 268435485;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_COMMAND_CLEAR_PATTERN = 268435486;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEOSPECIFIC_VIDEOINPUTFORMAT = 268435487;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAKDIAMOND_NIGHTMODE_ON = 268435488;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAKDIAMOND_NIGHTMODE_OFF = 268435489;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAKDIAMOND_AUTOIRIS_ON = 268435490;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAKDIAMOND_AUTOIRIS_OFF = 268435491;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAKDIAMOND_BACKLIGHTCOMPENSATION_ON = 268435492;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SPCPTZDLG_ULTRAKDIAMOND_BACKLIGHTCOMPENSATION_OFF = 268435493;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_H264 = 268435495;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_MPEG4 = 268435496;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_MJPEG = 268435497;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_MPEG2 = 268435498;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_JPEG = 268435499;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_JPEG2000 = 268435500;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_WAVELET = 268435501;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_CONTAINERSTREAM = 268435502;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_DEC = 268435503;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_ENC = 268435504;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_INPUT = 268435505;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_OUTPUT = 268435506;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_PTZ = 268435507;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_SERIAL = 268435508;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_SPK = 268435509;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_MIC = 268435510;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_UNIT = 268435511;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_UNITPLAYBACK = 268435512;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_SERIAL_RS232 = 268435513;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_SERIAL_RS485 = 268435514;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_SERIAL_RS422485 = 268435515;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_SUCCESS = 268435516;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_FAILURE = 268435517;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_PENDING = 268435518;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_STARTED = 268435519;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_INCOMPLETE = 268435520;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_NOVIDEO = 268435521;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_NOSEQUENCE = 268435522;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_TRICKLING_STATUS_INITIALIZING = 268435523;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_DEFAULT_DISK_GROUP = 268435524;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_HANDSHAKE = 268435525;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_TRANSFER = 268435526;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_DONE = 268435527;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_REQSENT = 268435528;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_WAIT = 268435529;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_FINALIZING = 268435530;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_GET_INFO_MSG = 268435531;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_PREPARING_FLASH = 268435532;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_REBOOTING = 268435533;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_LOADING_NEW_FILE_SYSTEM = 268435534;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_STATUS_STARTUPGRADENONAME = 268435535;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_DOWNFIRMFOUND = 268435536;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_DOWNGRADE = 268435537;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_FAILURE = 268435538;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_FLASHERROR = 268435539;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_FWU_LIB_TOO_OLD = 268435540;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_GENERALFAILURE = 268435541;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_INSUFMEM = 268435542;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_INVALIDARCH = 268435543;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_INVALIDCOMPORT = 268435544;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_INVALIDFILE = 268435545;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_INVALIDFILENAME = 268435546;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_INVALIDIP = 268435547;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_LOADFAIL = 268435548;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_MEMIMAGEFAIL = 268435549;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_OLDFWULIB = 268435550;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_RELOCFAIL = 268435551;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_RESOLVESYMBOLFAIL = 268435552;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_RETRY = 268435553;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_RXFAILURE = 268435554;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_TIMEOUT = 268435555;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_UNABLECONNECT = 268435556;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_UNEXPFAILURE = 268435557;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_UNKNOWNRAMSETTING = 268435558;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_UNKNOWNSIGN = 268435559;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_UNKNOWNSTP = 268435560;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_UPGRADE_17XX_35_TO_40 = 268435561;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_UPGRADE_17XX_35_TO_42 = 268435562;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_USERCANCEL = 268435563;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_WININET_LIB_OFFLINE = 268435564;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_FILETOOSHORT = 268435565;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_FORMATERROR = 268435566;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_READFIRMFILE = 268435567;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_PASSWORD = 268435568;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEODATAFORMAT = 268435569;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_FRAMERATE = 268435570;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_STORAGE_FAILURE = 268435571;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_CONNECTION_TIMEOUT = 268435572;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_CONNECTION_TIMEOUT_DESC = 268435573;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_INVALID_STREAM_RESOLUTION = 268435574;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EVENT_MULTIPLESOURCE_DESC = 268435575;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EVENT_RTPPACKETLOST_DESC = 268435576;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_DETECTION_WINDOW = 268435577;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_DETECTION_ZONE_TYPE = 268435578;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_GLOBAL_CHANGE = 268435579;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_IMAGE_TOO_BLURRY = 268435580;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_REFERENCE_CHECK_FAILED = 268435581;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_SIGNAL_TOO_BRIGHT = 268435582;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_SIGNAL_TOO_DARK = 268435583;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_SIGNAL_TOO_NOISY = 268435584;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_NOT_ENOUGH_RSC_FOR_CONFIG = 268435585;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEO_ANALYTICS_SONY_VMF = 268435586;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_PELCO_INVALID_CONFIG = 268435587;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_PELCO_INVALID_BOOSTQUALITY = 268435588;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_PELCO_EVENT_ERROR = 268435589;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_KEEPING_CONFIGURATION = 268435590;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_LOOSING_CONFIGURATION = 268435591;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEO_ANALYTICS_SONY_VMF_FILTER = 268435592;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_ANALYTICS_PRESENCE_DETECTED = 268435593;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_TRESHOLD = 268435595;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_EVENT = 268435596;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_SECTION_MOTION_ACTIVE = 268435597;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MOTIONDETECTION_SECTION_MOTION_INACTIVE = 268435598;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_H265 = 268435599;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BOSCH_INVALIDCONFIG_NUMBER_OF_STREAM = 268435600;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECORDING_SCHEDULE = 268435603;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECORDING_MODE = 268435604;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERA_QUALITY_SETTINGS = 268435605;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_CHOOSE_DEFAULT_QUALITY = 268435606;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERA_SECURITY = 268435607;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_PROMPT_FOR_CAMERA_CREDENTIALS = 268435608;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_CHOOSE_DEFAULT_RECORDING_SCHEDULE = 268435609;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_SPECIFY_DEFAULT_CAMERA_CREDENTIALS = 268435610;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_CHOOSE_QUALITY_SETTINGS = 268435611;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_REFER_TO_ARCHIVER_SETTINGS = 268435612;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_RESOLUTION_DESCRIPTION_HIGH = 268435613;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_RESOLUTION_DESCRIPTION_STANDARD = 268435614;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_RESOLUTION_DESCRIPTION_LOW = 268435615;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_HIGHEST_RESOLUTION_AVAILABLE = 268435616;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECORDING_MODE_DESCRIPTION_ALWAYS = 268435617;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECORDING_MODE_DESCRIPTION_CUSTOM = 268435618;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECORDING_MODE_DESCRIPTION_MANUAL = 268435619;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECORDING_MODE_DESCRIPTION_MOTION = 268435620;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECORDING_MODE_DESCRIPTION_OFF = 268435621;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_CAMERA_DEFAULT_SETTINGS = 268435622;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVER_VIDEO_QUALITY = 268435623;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_QUALITY_CHANGED = 268435624;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_APPLY_TO_ALL_CAMERAS = 268435625;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERA_AUTHENTICATION_USERNAME = 268435626;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SHUTTER_HIGH_SPEED_MODE = 268435627;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SHUTTER_SLOW_MODE = 268435628;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SHUTTER_AUTO_MODE = 268435629;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MENU_ITEM_OVERLAYS = 268435632;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SHOW_OVERLAYS = 268435633;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_BACKUP_FOLDER_CONFLICT = 268435634;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_ORIGIN_TYPE = 268435635;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_MEDIA_TYPE = 268435636;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_STREAMINGTYPE_EDGEPLAYBACK = 268435637;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_STREAMINGTYPE_EXPORT = 268435638;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_STREAMINGTYPE_METADATA = 268435639;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVER_TRICKLING = 268435640;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIORITIZED_SOURCE = 268435641;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_EDGETOARCHIVER = 268435642;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_ARCHIVERTOARCHIVER = 268435643;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TASKSHORTDESC_ARCHIVETRANSFER = 268435644;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TASK_ARCHIVETRANSFER = 268435645;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFERGROUP_MODIFIED = 268435646;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFERGROUP_DELETED = 268435647;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFERGROUP_DELETE_CONFIRMATION = 268435648;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_START_TRANSFER_TRANSFERGROUPS = 268435649;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_STOP_TRANSFER_TRANSFERGROUPS = 268435650;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_BACKUP_DESCRIPTION = 268435651;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_ARCHIVER = 268435652;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_ARCHIVER_DESCRIPTION = 268435653;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_EDGE = 268435654;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_EDGE_DESCRIPTION = 268435655;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_TRANSFERGROUP_DETAILS = 268435656;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_TRANSFERRED_SIZE = 268435657;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_LAST_TRANSFER_START = 268435658;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_LAST_TRANSFER_END = 268435659;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_LAST_TRANSFER_STATUS = 268435660;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_NEXT_TRANSFER = 268435661;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_TRANSFERGROUP_DETAILS = 268435662;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_START_TRANSFER = 268435663;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_STOP_TRANSFER = 268435664;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_EVERYTHING = 268435665;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFERGROUP_INCOMPATIBLE_SOURCES = 268435666;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFERGROUP_DELETED_DETAILS = 268435667;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFER_ARCHIVER_OFFLINE = 268435668;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVEBACKUP_MAX_THROUGHPUT = 268435669;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_AVCPROFILE_MAIN = 268435670;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_AVCPROFILE_BASELINE = 268435671;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_AVCPROFILE_HIGH = 268435672;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ENABLE_PLAYBACK_REQUESTS = 268435673;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EXPORT_PARTIAL_SUCCESS_SUMMARY = 268435674;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EXPORT_FAILURE_SUMMARY = 268435675;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SHUTTER_NORMAL_MODE = 268435676;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ELBEXDLG_SHUTTER_SPECIAL_MODE = 268435677;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_SEQUENCE_OVERLAP = 268435678;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_MAX_UNIT_PLAYBACK_COUNT = 268435679;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_MAX_PLAYBACK_CAPACITY = 268435680;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_NO_OTHER_ARCHIVER_OWNER_FOUND = 268435681;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_REMOTEZOOM = 268435682;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_INCREMENT_ZOOM = 268435683;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_DECREMENT_ZOOM = 268435684;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_INCREMENT_FOCUS = 268435685;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_DECREMENT_FOCUS = 268435686;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_FULLRANGE_FOCUS = 268435687;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_SHORTRANGE_FOCUS = 268435688;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_ARECONT_RESET_ZOOM = 268435689;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_APPLICATION_FAILURE = 268435690;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_RESTORE_ARCHIVES = 268435692;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_RESTORE_MULTIPLE_ARCHIVES = 268435693;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_OPTION_VIDEO_WATERMARKING_ENABLED = 268435694;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_TEMPERATURE_ALARM_ABOVE_THRESHOLD = 268435697;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_TEMPERATURE_ALARM_BELOW_THRESHOLD = 268435698;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_DELETE_OLD_BACKUP_ON_DISK_FULL = 268435701;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_PARTIALLY_FAILED = 268435702;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_FAILED = 268435703;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_UNIT_TRICKLING_SUCCESS = 268435704;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_APPLICATION_EVENTS = 268435705;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RESTORED_BACKUP = 268435706;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ORIGINTYPE_EDGE_TRICKLING = 268435707;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_CONNECTION_TO_MEDIAROUTER_FAILED = 268435708;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_MEDIAROUTER_DESCRIBE_EMPTY = 268435709;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_COVERAGE_USERTYPE_ARCHIVING = 268435710;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_COVERAGE_USERTYPE_MOTION = 268435711;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_AUXARC_COVERAGE_CONFLICT_DIAGNOSE_FORMAT = 268435712;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_COVERAGE_USERTYPE_COMPRESSION = 268435713;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_NETWORK_ROUTE_NOT_FOUND = 268435714;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_MEDIAROUTER_NO_STREAM_SOURCE_FOUND = 268435715;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_NO_CONNECTION_TO_ANY_SOURCE_ARCHIVER = 268435716;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVETRANSFER_COVERAGE_TIMESPAN = 268435718;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFERGROUP_ARCHIVER_OFFLINE = 268435719;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_EVERYTHING_UP_TO = 268435720;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFER_GROUP_NO_VALID_SOURCES = 268435721;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSFERGROUP_RELATIVE_TIMEZONE = 268435723;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_COMMAND_SHOW_DIAGNOSTIC_TIMELINE = 268435724;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_DUAL = 268435725;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_HINT_REORDER_SEQUENCES = 268435726;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_RESULT_NOT_SET = 268435727;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_NO_VALID_RTSP_RESULT = 268435728;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_CONNECTION_TO_MEDIA_ROUTER_FORMAT = 268435729;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_ARCHIVER_NOT_OWNER_OF_TRANSFER_GROUP_FORMAT = 268435730;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_NO_DATA_RECV_FROM_MEDIA_PLAYER = 268435731;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_BACKUP_SET_FILE_NOT_FOUND_FORMAT = 268435732;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_CANT_RESTORE_NOT_A_BACKUP_SET_FORMAT = 268435733;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_EMPTY_BACKUP_SET = 268435734;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_RESTORE_NOT_ENOUGH_SPACE_FORMAT = 268435735;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_NOWHERE_TO_ALLOCATE_FILE_FORMAT = 268435736;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_FILE_ALLOCATION__FORMAT = 268435737;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_TEMPERATURE_CTRL_START_FANS = 268435738;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_TEMPERATURE_CTRL_STOP_FANS = 268435739;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_TEMPERATURE_CTRL_START_HEATERS = 268435740;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCODER_COMMAND_AXIS_TEMPERATURE_CTRL_STOP_HEATERS = 268435741;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PLAYBACK_DURATION = 268435742;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_EDGE_TRICKLING_WRITE_TO_DISK = 268435743;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_EDGE_TRICKLING_WRITE_TO_DB = 268435744;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_EDGE_TRICKLING_ARCHIVE_FILE_ERROR = 268435745;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_DATABASE_USED_BY_ANOTHER_AUXARC = 268435746;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_NOT_ENOUGH_SPACE_MESSAGE = 268435747;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_NOT_ENOUGH_SPACE_MESSAGE = 268435748;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_TRICKLING_NO_PATH_AVAILABLE_MESSAGE = 268435749;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVE_BACKUP_NO_PATH_AVAILABLE_MESSAGE = 268435750;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNINGMODE = 268435751;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNINGMODE_ON = 268435752;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNINGMODE_AUTO = 268435753;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNINGMODE_OFF = 268435754;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNINGMODE_DAY = 268435755;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNINGMODE_NIGHT = 268435756;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNINGMODE_DAYNIGHT = 268435757;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_BINNING = 268435758;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ENDED_WITH_ERRORS = 268435759;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_SYSTEM_CANCEL = 268435760;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ORIGIN_TRANSFERED_FROM_ARCHIVER = 268435761;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ORIGIN_RECORDED_BY_LOCAL_ARCHIVER = 268435762;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ORIGIN_STORED_ON_UNIT = 268435763;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ORIGIN_TRICKLED_UNIT = 268435764;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_TRANSFERGROUP_CANNOT_MODIFY_ACTIVE = 268435765;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCRIPTION_SHOCK_DETECTED = 268435766;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_MANUAL_BACKUP_DUPLICATE_ARCHIVES = 268435767;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_PELCO_OBJECT_COUNT_REACHED = 268435768;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_PELCO_ADAPTIVE_MOTION = 268435769;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INSUFFICIENT_CAPABILITY = 268435770;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SONY_ROTATION_INFO = 268435771;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_SHOULD_BE_REPLACED = 268435778;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_SDCARD_MUST_BE_REPLACED = 268435779;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_AXIS_WS_CONFIGURATION_WEBPAGE = 268435780;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_DOWNSCALING = 268435781;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_DOWNSCALING_RESOLUTION = 268435782;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SHOCK_DETECTION_TRESHOLD = 268435783;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_IMPORT_FILE_NOT_FOUND_FORMAT = 268435784;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_IMPORT_FILE_ACCESS_ERROR_FORMAT = 268435785;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_IMPORT_FILE_ERROR = 268435786;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_CAMERA_INVALID_ENCRYPTION_CONFIG = 268435787;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_VIEWING_MODE = 268435790;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_PARTIAL_SUCCESS_LEGACY_EXPORT_SOME_SEQUENCE_ENCRYPTED = 268435791;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_LEGACY_EXPORT_ALL_SEQUENCE_ENCRYPTED = 268435792;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONNECTED_TO_UC = 268435793;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_LOSTCONNECTION_TO_UC = 268435794;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_ROLE_RECOVERED_UC = 268435795;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_ROLE_LOST_UC = 268435796;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_UC_STOPPED = 268435797;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_UC_RESTORED = 268435798;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_SERVICE_CREATION = 268435799;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BENCHMARK_TEST = 268435800;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BENCHMARK_TEST_DESCRIPTION = 268435801;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BENCHMARK_TEST_RUN = 268435802;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_SINGLE = 268435803;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_QUAD = 268435804;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_PANORAMIC = 268435805;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_RUN_BENCHMARK_TEST = 268435806;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_VIDEO_FILE_IMPORT = 268435808;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_UNIQUEID = 268435809;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LENS_NOT_CONFIGURED = 268435810;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LENS_NOT_CONFIGURED_DESCRIPTION = 268435811;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_SINGLE_PANORAMIC = 268435813;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_DUAL_PANORAMIC = 268435814;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_CLIP = 268435815;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_UNABLE_TO_SET_MC_PORT = 268435816;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_STREAMINGTYPE_ANY_SOURCE = 268435817;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_INVALID_NETWORK = 268435818;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BENCHMARK_TEST_GPU_WARNING = 268435819;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BENCHMARK_TEST_RERUN = 268435820;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BENCHMARK_RESULT_SUCCESS = 268435821;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BENCHMARK_RESULT_FAIL = 268435822;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BENCHMARK_RESULT_IN_PROGRESS = 268435823;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_NARROW_PANORAMA = 268435824;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_WIDE_PANORAMA = 268435825;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTIVITYTRAIL_LIVE_FAILED = 268435826;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTIVITYTRAIL_PLAYBACK_FAILED = 268435827;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ARCHIVE_TRANSFER_AUDIO_IMPORT_FAILED = 268435828;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERA_APPLICATIONS = 268435829;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VENDOR = 268435830;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_MOTION_SEARCH_NOTSUPPORTED_FOR_CAMERA = 268435831;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_THERMAL_SETTINGS = 268435832;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_UNIT_CELSIUS = 268435833;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_UNIT_FAHRENHEIT = 268435834;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_UPPER_LEVEL = 268435835;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MIDDLE_LEVEL = 268435836;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LOWER_LEVEL = 268435837;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_UNIT_CELSIUS_SHORT = 268435838;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_UNIT_FAHRENHEIT_SHORT = 268435839;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_RESOLUTION_DESCRIPTION_DEFAULT = 268435840;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ASSUME_DATES_AS_LOCAL = 268435841;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_AXISDLG_SPEEDDRY = 268435842;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LOCAL_TIME = 268435843;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_INFO_TIME_REPORTED = 268435844;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_RTSP_AUTHENTICATION_INVALID = 268435845;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_AUTHENTICATION_FAILED = 268435846;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTSP_STREAM_OVER_HTTPS = 268435847;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_HTTP_SERVER_FAILED_TO_LISTEN_ON_XY = 268435848;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_DELETE_CERTIFICATE_FILES = 268435849;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_PANASONIC_INVALIDCONFIG_MJPEG = 268435850;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTSPS_STREAM_OVER_TCP = 268435851;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTSPS_STREAM_OVER_UDP = 268435852;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTSPS_STREAM_OVER_MULTICAST = 268435853;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_INPUT_NUMBER = 268435854;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_LICENSE_COUNT_EXCEEDED = 268435855;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_LICENSE_COUNT_EXCEEDED_DETAIL = 268435856;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_USE_SECURE_RTSP_WHEN_AVAILABLE = 268435857;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_DELETE_CERTIFICATE_FILES_WARNING = 268435858;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_WIZARD_COPY_CONFIG_VALIDATION = 268435859;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_SUBFOLDER_ACCEPTED = 268435860;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_SUSPICIOUS_RETENTION = 268435861;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_SUSPICIOUS_RETENTION_DETAILS = 268435862;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_CANNOT_DISPLAY_STREAM = 268435863;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ENABLE_SECURE_COMMUNICATION = 268435864;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ENCRYPTION_NOT_SUPPORTED = 268435865;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ENABLE_METADATA_STREAM = 268435869;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ORIGIN_UNIT_REPLACEMENT = 268435870;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_MAXIMUM_ITEM_COUNT_FAILOVER = 268435871;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_DISABLING_METADATA_STREAM = 268435872;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERA_STATIONS = 268435873;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERA_STATION = 268435874;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_STATION_CONFIG_ADD_NEW = 268435875;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_STATION_DUPLICATE = 268435876;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_FIRMWAREUPGRADE_ERROR_CERTIFICATE = 268435877;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MENU_AUXILIARY_RECORDING = 268435878;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_STATION_CONFIG_WARNING = 268435879;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CAMERA_DUPLICATE = 268435880;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_EQUIPMENT = 268435881;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_HOLDERS = 268435882;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_HOLDER = 268435883;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EXPORT_PARTIAL_FAILURE_SUMMARY = 268435884;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PROTECTED_VIDEOS_FILTER = 268435885;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_MOTION_CAPABILITIES_INCOMPATIBLE = 268435886;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_MC_RANGE_WARNING = 268435887;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_MC_PORT_RANGE_WARNING = 268435888;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_VIDEOTYPE_MXPEG = 268435889;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_METADATA = 268435890;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_OMNICASTCPNOTINSTALLED = 268435891;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_PANASONIC_INVALID_RESOLUTION_MJPEG = 268435892;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_BOSCH_IVA = 268435893;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_AXIS_DECODER_USE_HTTPS_WHEN_NOBASICAUTH = 268435894;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PANASONIC_SMARTCODEC = 268435901;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_STORAGEMEDIA_ERROR = 268435902;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_STORAGE_ACCESS_ERROR = 268435903;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_STORAGE_NO_FREE_SPACE = 268435904;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_STORAGE_WRITE_PROTECTED = 268435905;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_NETWORKSHARE = 268435907;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_REDIRECTOR_NOUDPPORTAVAILABLE = 268435908;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCRIPTION_TRUE = 268435909;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCRIPTION_FALSE = 268435910;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CONFIGURE_MOTION_ZONE_FROM_CONFIGTOOL = 268435912;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_IGNORE_SHORT_LIVED_OBJECTS = 268435913;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_IGNORE_SWAYING_OBJECTS = 268435914;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_IGNORE_SMALL_OBJECTS = 268435915;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MAX_EDGE_SIMULTANEOUS_CAMERAS = 268435916;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_NOT_ENOUGH_SPACE_MESSAGE = 268435917;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_CONSOLIDATION_NO_PATH_AVAILABLE_MESSAGE = 268435918;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_ARCHIVECONSOLIDATION = 268435919;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFERGROUP_ARCHIVECONSOLIDATION_DESCRIPTION = 268435920;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ORIGIN_ARCHIVE_CONSOLIDATION = 268435921;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTIVITY_TYPE_RECORDING_STARTED_MANUAL = 268435922;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTIVITY_TYPE_RECORDING_STOPPED_MANUAL = 268435923;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_PANASONIC_SMARTCODEC_INVALIDCFG_FMT = 268435924;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_OBJECT_MIN_TIME_DETECTION_THRESHOLD = 268435925;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_OBJECT_MIN_DISTANCE_DETECTION_THRESHOLD = 268435926;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_OBJECT_MIN_SIZE_DETECTION_THRESHOLD = 268435927;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_MEDIAGATEWAY_APPLICATIONPATH_ERROR = 268435928;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARECONT_SNAPSTREAM = 268435929;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EVENT_MOTIONONZONE_DESC = 268435930;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EVENT_MOTIONOFFZONE_DESC = 268435931;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SAMPLE_URL = 268435932;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SAMPLE_URL_RTSP_TOOLTIP = 268435933;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_BWC_REGENERATE_CONFIG_FILES = 268435934;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_STATION_CONFIG_REGENERATE_ALL = 268435935;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_BWC_STATIONS_CONF_FILE_OBSOLETE = 268435936;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_REGENERATE_ALL = 268435937;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_INCOMING_UDP_PORT_RANGE_EXHAUSTED = 268435938;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_USER_UNAUTHORIZED_EXPORT = 268435939;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MEDIAGATEWAY_WEBCLIENTCONNECTION = 268435940;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_ARCHIVE_CONSOLIDATION_CONFIG_ONLY_IN_ADVANCED_SETTINGS = 268435941;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_ROLE_LOST_ARCHIVER_AGENT_FORMAT = 268435942;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_ROLE_LOST_ARCHIVER_AGENT = 268435943;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EXPORT_NOVIDEO_ALLSOURCE = 268435944;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_EXPORT_NOVIDEO_SPECIFICSOURCE = 268435945;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_ALL = 268435946;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_VIDEO_ERROR = 268435947;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_ALL_ERROR = 268435948;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_NONE = 268435949;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_ALL_DETAILS = 268435950;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_VIDEO_ERROR_DETAILS = 268435951;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_ALL_ERROR_DETAILS = 268435952;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_NONE_DETAILS = 268435953;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BWC_CONVERSION_POLICY = 268435954;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_BWC_CONVERSION_POLICY = 268435955;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BWC_CONVERSION_ERROR = 268435956;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BWC_CONVERSION_ERROR_COPY = 268435957;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BWC_CONVERSION_ERROR_COPIED = 268435958;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_BWC_CONVERSION_ERROR_RETENTION = 268435959;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_FIELD_STREAMINGTYPE_PRIVATE_VIDEO = 268435960;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_INHERIT_FROM_ROLE = 268435961;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BWC_UNIT_DIAGNOSTIC_NO_ARCHIVER_WARNING = 268435962;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BWC_UNIT_DIAGNOSTIC_ARCHIVER_NOT_RUNNING_WARNING = 268435963;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BWC_UNIT_DIAGNOSTIC_ARCHIVER_SUCCESS = 268435964;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MSGBOX_INFO_DELETE_BWCAMERA = 268435965;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LINKED_CAMERA = 268435966;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_RECORDING_PROBLEM_DATABASE = 268435967;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_RECORDING_PROBLEM_STREAMING = 268435968;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_RECORDING_PROBLEM_FILEWRITE = 268435969;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_BWC_CHANGING_SETTINGS_REQUIRE_REGENERATE_STATION = 268435970;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_AUDIOANALYTICS_CUSTOM_EVENT = 268435971;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ARCHIVER_RECEPTION_UDP_PORT_RANGE_OVERLAP = 268435972;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_BWC_DEFAULT_ARCHIVER_HAS_MULTIPLE_AGENTS = 268435974;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BWC_UNIT_DIAGNOSTIC_ARCHIVER_HAS_FAILOVER = 268435975;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ADD_PUBLIC_ADDRESS_OVERRIDE = 268435976;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_REMOVE_PUBLIC_ADDRESS_OVERRIDE = 268435977;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_REMOVING_PRIVACY_PROTECTION = 268435978;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_CANNOT_DELETE_MEDIAPROCESSOR_ASSOCIATION = 268435979;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_BWC_NO_DEFAULT_ARCHIVER = 268435980;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BWC_UNIT_DIAGNOSTIC_ARCHIVER_INWARNINGSTATE_WARNING = 268435981;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PUBLIC_ADDRESSES = 268435982;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_METHOD = 268435983;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_INTENSITY_LEVEL = 268435984;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_COLOR = 268435985;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_HIGHLIGHT_INTENSITY = 268435986;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_TOLERANCE = 268435987;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_PRESET = 268435988;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_NUM_ILLUMINATION = 268435989;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_ILLUMINATION_TOLERANCE = 268435990;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_CONTRAST_THRESHOLD = 268435991;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_LOADING_PREVIEW = 268435992;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_STARTUP_PERIOD = 268435993;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_CONTINUOUS_PERIOD = 268435994;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_GRID_BLOCK_SIZE = 268435995;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_ANALYSIS_RESOLUTION = 268435996;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_SHOW_ZONES_BOUNDARIES = 268435997;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SCENEPRESET_OUTDOOR = 268435998;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SCENEPRESET_INDOOR = 268435999;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACYMETHOD_PIXELATION = 268436000;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACYMETHOD_BLUR = 268436001;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACYMETHOD_COLORIZE = 268436002;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_NAME_VIRTUAL_OUTPUT = 268436003;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_FAST_PREVIEW = 268436004;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_PRIVACY_PROTECTION_FAST_PREVIEW = 268436005;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_LEARNING_PERIOD = 268436006;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_CANNOT_CHANGE_VIRTUAL_PORT_STATE = 268436007;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_PRESET_CONFIGURATION = 268436008;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_PREVIEW_CONFIGURATION = 268436009;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_PRIVACY_PROTECTION_PREVIEW = 268436010;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_CAMERA_TIME_OUT_OF_SYNC = 268436012;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_CAMERA_TIME_IS_OFF_BY_MS = 268436013;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_CAMERA_TIME_NOT_SUPPORTED = 268436014;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MULTICAST_IPV6 = 268436015;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_NO_ANALYSIS_CONFIG = 268436016;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_SAVING_DEFAULT_UNRELIABLE = 268436017;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_VIDEO_INVALID_BITRATE_VALUE = 268436018;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_INACURATE_DETECTION_BLOCKS = 268436019;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_PRIVACYPROTECTION_ANALYSISQUALITY_PERFORMANCE = 268436020;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_PRIVACYPROTECTION_ANALYSISQUALITY_BALANCED = 268436021;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_PRIVACYPROTECTION_ANALYSISQUALITY_QUALITY = 268436022;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_ANALYSIS_QUALITY = 268436023;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_BLOCK_PRECISION = 268436024;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_0_DEGREE = 268436025;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_90_DEGREE = 268436026;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_180_DEGREE = 268436027;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_270_DEGREE = 268436028;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PANASONIC_PRESET_SEQUENCE = 268436029;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PANASONIC_PATROL = 268436030;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PANASONIC_HOME_POSITION = 268436031;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_PEOPLE_COUNTING = 268436032;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_PEOPLE_COUNTING_ALARM = 268436033;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PUNCTUAL = 268436034;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_DIRECTION = 268436623;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RULENAME = 268436624;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CALLUPTIMEOPTIMIZATION_TOGGLE = 268437704;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CALLUPTIMEOPTIMIZATION_CAMERALIST = 268437705;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CALLUPTIMEOPTIMIZATION_TOOLTIP_CONTINUOUSSTREAMING = 268437706;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_CALLUPTIMEOPTIMIZATION_AUTOMATIC_STREAMUSAGE = 268437707;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TEMPERATURE_ALARM_THRESHOLD = 268437708;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MSGBOX_INFO_DELETE_BWCAMERAS = 268437709;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_SDCARD_DELETE = 268437710;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_SDCARD_FILESYSTEM = 268437711;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_SDCARD_OTHER = 268437712;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_SDCARD_WRITE = 268437713;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_SDCARD_READ = 268437714;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MTU = 268437715;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_TRANSMISSION_LOST_MTU_JUMBO_FRAME_DETECTED = 268437718;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_CHECKBOX_KEEPMOTIONDETECTIONFROMUNITWEBPAGE = 268437719;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_CALLUPTIMEOPTIMIZATION_NOSEQUENCESELECTED = 268437720;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_CALLUPTIMEOPTIMIZATION_NOSEQUENCEFOUND = 268437721;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_ADD_NEVER_BLUR_ZONE = 268437722;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_ADD_ALWAYS_BLUR_ZONE = 268437723;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_REDIRECTION_STRATEGY_USAGE_NOT_SUPPORTED = 268437724;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_CANNOT_RESOLVE_GENETEC_SERVER_ADDRESS = 268437725;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ERROR_EXPORT_NOT_POSSIBLE_CURRENT_CONFIGURATION = 268437726;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SECURE_COMMUNICATION_ENCRYPTION_ON_TRANSIT = 268437727;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LARGERTPPACKETS = 268437728;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_LARGERTPPACKETS = 268437729;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERA_INTEGRITY_MONITOR_SYSTEMWIDE = 268437730;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_AUDIOANALYTICS_SCREAM = 268437731;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_AUDIOANALYTICS_GUNSHOT = 268437732;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_AUDIOANALYTICS_EXPLOSION = 268437733;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_AUDIOANALYTICS_GLASSBREAKING = 268437734;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ANALYTICS_DETECTED = 268437735;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ANALYTICS_DETECTED_WITH_LIKELIHOOD = 268437736;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_USE_PUBLIC_ADDRESS = 268437737;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_MOVED_IN = 268437738;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_MOVED_OUT = 268437739;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_MOVED_LEFT = 268437740;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_MOVED_RIGHT = 268437741;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_SUMMARY_IN = 268437742;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_SUMMARY_OUT = 268437743;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_SUMMARY_LEFT = 268437744;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTING_SUMMARY_RIGHT = 268437745;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_PEOPLECOUNTINGALARM = 268437746;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_EDGE_RECORDING_STORAGE_STATUS_STARTED = 268437747;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_EDGE_RECORDING_STORAGE_STATUS_STOPPED = 268437748;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_EDGE_RECORDING_STORAGE_FAILURE_DETAILS = 268437749;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_EDGE_STORAGE_STATUS_MEDIUM_RECOVERED = 268437750;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_EDGE_STORAGE_STATUS_MEDIUM_FULL = 268437751;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_EDGE_STORAGE_STATUS_MEDIUM_EMPTY = 268437752;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_EDGE_STORAGE_STATUS_UNEXPECTED_ERROR = 268437753;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_EDGE_STORAGE_STATUS_UNKNOWN = 268437754;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_CAMERA_TIME_OFF_BY_SEC_MS_DESCRIPTION = 268437755;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_CAMERA_TIME_OFF_BY_MINUS_SEC_MS_DESCRIPTION = 268437756;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_CAMERA_TIME_UNKNOWN_DESCRIPTION = 268437757;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_GPU_WARNING_DRIVER_OUTDATED = 268437758;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_EVIDENCE_NAME = 268437759;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAPTURE_TIME = 268437760;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_UPLOAD_TIME = 268437761;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CONVERSION_STATUS = 268437762;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CONVERSION_PROGRESS = 268437763;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_REPORT_WEARABLE_CAMERA_EVIDENCES = 268437764;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TASKSHORTDESC_REPORT_WEARABLE_CAMERA_EVIDENCES = 268437765;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_CIM = 268437766;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEO_CIM_TAMPERING_IMAGE_COVERED = 268437767;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEO_CIM_TAMPERING_IMAGE_BLURRY = 268437768;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEO_CIM_TAMPERING_CAMERA_MOVED = 268437769;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEO_CIM_TAMPERING_BAD_LIGHTING = 268437770;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEO_CIM_TAMPERING_LOW_CONSTRAST = 268437771;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_CIM_PERSISTENCE_PATH = 268437772;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_MODULES = 268437773;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_CIM_NETWORK_PATH_HINT = 268437774;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_MEDIAROUTER_CONFIG_ENCRYPTION_ON_TRANSIT_BACKWARD_REDIRECTOR = 268437775;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_MEDIAROUTER_CONFIG_ENCRYPTION_ONTRANSIT_LIMITATION = 268437776;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ANALYTIC_CONFIGURATION_PRESETS = 268437777;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_ANALYTIC_CONFIGURATION_PRESET_INDOOR = 268437778;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_ANALYTIC_CONFIGURATION_PRESET_OUTDOOR_NEAR = 268437779;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_ANALYTIC_CONFIGURATION_PRESET_OUTDOOR_FAR = 268437780;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENUM_ANALYTIC_CONFIGURATION_PRESET_THERMAL = 268437781;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ANALYTIC_CONFIGURATION_SELECTED_PRESET = 268437782;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ANALYTIC_CONFIGURATION_EVENT_STORAGE_ARCHIVER = 268437783;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ANALYTIC_CONFIGURATION_LEARNING_RATE = 268437784;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ANALYTIC_CONFIGURATION_CONTRAST_CHANGE_DETECTION = 268437785;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ANALYTIC_CONFIGURATION_LIGHTING_CHANGE_DETECTION = 268437786;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ANALYTIC_CONFIGURATION_NO_SELECTED_ARCHIVER = 268437787;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ANALYTIC_CONFIGURATION_INVALID_FIELDS = 268437788;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ANALYTIC_CONFIGURATION_INVALID_PATH = 268437789;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_AUDITTRAILS_REDIRECTION_STRATEGY_ADDED_REMOVED = 268437790;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ARCHIVER_DOES_NOT_EXISTS = 268437791;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ARCHIVER_DIAGNOSTIC_SERVERVERSION = 268437792;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CREATEREMOTESITEARCHIVER = 268437794;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PTZ_CONFIGURATION = 268437795;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_EXTENDED_PTZ_RANGE = 268437796;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_ENABLE_ENCRYPTION_ON_TRANSIT_TILE = 268437797;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_MULTICAST_ADDRESSES_NOT_VACANT = 268437798;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MEDIAROUTER_DIAGNOSTIC_MULTICAST_ADDRESSES_VALID = 268437799;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_REDIRECTOR_NEEDS_UPDATE_FOR_ENCRYPTION_ON_TRANSIT_TILE = 268437800;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BANDWIDTH_CONTROL_LIVE = 268437801;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BANDWIDTH_CONTROL_PLAYBACK = 268437802;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_EXPORT_G64X_FILE_CREATION_FAILED = 268437803;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_REPORT_WEARABLE_CAMERA_EVIDENCES = 268437804;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_CANNOT_CREATE_FILE = 268437805;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_FILE_PATH_LENGTH_TOO_LONG = 268437806;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_EXPORT_FILE_PATH_TOO_LONG = 268437807;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACYMETHOD_TRANSPARENCY = 268437808;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ERROR_FEDERATION_RETURN_ON_TILE = 268437809;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MULTICAST_PORT_IPV6 = 268437810;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_PRIVACYPROTECTION_ADDEDAGENTSLOADBALANCING = 268437811;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ENCRYPTION_ONTRANSIT_STREAM_INFO = 268437812;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_FULL_ANALYSIS_TIME_RANGE = 268437813;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MAXIMUM_SIMULTANEOUS_ANALYSIS = 268437814;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CIM_CAMERAS = 268437815;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CIM_RELATIONSHIPS = 268437816;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LIST_CAMERAS = 268437817;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PP_RELATIONSHIPS = 268437818;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_WARNING_CIM_CONFIG_SHORT_TIME = 268437819;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MESSAGE_NO_RELATED_CAMERA = 268437820;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTP_PORT_IN_USE_ERROR = 268437821;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTSP_PORT_IN_USE_ERROR = 268437822;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_DISABLING_SETTING_MIGHT_RECONNECT = 268437823;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_INFO_MULTICAST_FROM_UNIT_NOT_SUPPORTED = 268437824;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ENCRYPTION_TYPE_IN_TRANSIT_AT_REST = 268437825;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_WARNING_ENCRYPTION_IN_TRANSIT_REQUIRES_SECURECOMM = 268437826;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ANALYSING_STREAMING_STATUS = 268437827;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BENCHMARK_RESULT_FAIL_REMOVETILE = 268437828;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_STREAMING = 268437829;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MEDIA_GATEWAY_GENERAL = 268437830;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MINIMUM_RESOLUTION_FOR_HIGH_QUALITY = 268437831;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LOW_QUALITY = 268437832;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_DEFAULT_STREAM = 268437833;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MAX_RESOLUTION_JPEG_TRANSCODING = 268437834;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_NO_LIMIT_RESOLUTION = 268437835;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_HIGH_QUALITY = 268437836;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_HARDENINGGUIDE_MOBILE_SERVER = 268437841;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_GPU_WARNING_INTELDRIVER_OUTDATED = 268437842;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_BLOCK_HIGHLIGHT = 268437843;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PRIVACY_PROTECTION_PIXELATION_ADVANCED = 268437844;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PT_SPEED_TYPE = 268437845;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PT_PROPORTIONAL_MODE = 268437846;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIDEO_CIM_ROLE = 268437847;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SCENE_CHANGED = 268437848;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO__ABOUT_REMOVE_CIM_FROM_CAMERAS = 268437849;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_ARCHIVER_VIDEO_FILE_NOT_FOUND = 268437850;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SHRINK = 268437851;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_EXPAND = 268437852;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ENCODER_COMMAND_AXIS_START_WIPER_SEQUENCE = 268437853;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ENCODER_COMMAND_AXIS_STOP_WIPER_SEQUENCE = 268437854;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_LOADING_MEDIAPROCESSOR_DATA = 268437855;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_DIAGNOSE_AUXILIARY_DECRYPTION_NO_KEY_DROPPED_FRAMES = 268437856;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BENCHMARK_RESULT_FAIL_RENDER_ONLY = 268437857;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BENCHMARK_RESULT_FAIL_GRAPHIC_CARDS = 268437858;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_CONFIGURATION_WILL_BE_LOST = 268437859;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_PRIVILEGE_PARTIAL_SUCCESS_EXPORT_SOME_SEQUENCE_ENCRYPTED = 268437860;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_PARTIAL_SUCCESS_EXPORT_LOST_FILE = 268437861;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_FILE_HEADERS_RETRIEVED = 268437862;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_FILE_HEADERS_RETRIEVED_RANGE = 268437863;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_VIDEO_DIGITALLY_SIGNED = 268437864;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_FILE_WONT_CONTAIN_DIGITAL_SIGNATURE = 268437865;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MENU_ITEM_FEDERATION_ARCHIVER_PLAYBACK_SOURCE = 268437866;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MENU_ITEM_FEDERATION_AUXILIARY_ARCHIVER_PLAYBACK_SOURCE = 268437867;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MENU_ITEM_ANY_FEDERATION_PLAYBACK_SOURCE = 268437868;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_ARCHIVE_TRANSFER_MISSING_FILES = 268437869;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BUTTON_CAMERAINTEGRITY_REFRESHALLTHUMBNAILS = 268437870;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_CAMERAINTEGRITY_REFRESHTHUMBNAILS = 268437871;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_CAMERAINTEGRITY_REFRESHTHUMBNAILSCONFIRM = 268437872;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ANALYTICMODULEOFFLINE = 268437873;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERAINTEGRITY_MUSTBERUNNING = 268437874;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CAMERAINTEGRITY_MUSTBEUPGRADED = 268437875;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_PRIVILEGE_EXPORT_SINGLE_USER_AUTHORIZATION = 268437876;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_PRIVILEGE_TOOLTIP_EXPORT_SINGLE_USER_AUTHORIZATION = 268437877;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_SERVER_AUTHENTICATION_FAILED = 268437878;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_INVALID_LICENSE = 268437879;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_CERTIFICATE_DELETED_VIDEO_URECOVERABLE = 268437880;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BUTTON_KEEP_ARCHIVES = 268437881;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ROLE_CLOUD_ARCHIVE_PLAYBACK_NAME = 268437882;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ROLE_CLOUD_ARCHIVE_PLAYBACK_DESCRIPTION = 268437883;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_TRIGGER_DATE = 268437884;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_END_TRANSFER_TIME = 268437885;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRIGGER_REASON = 268437886;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_RESULT = 268437887;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_TIMESTAMP_FILTER = 268437888;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_RESULT_COUNT_FILTER = 268437889;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MANUAL_TRANSFER = 268437890;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SCHEDULED_TRANSFER = 268437891;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_DUPLICATES_TRANSFER = 268437892;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_BACKUP_TRANSFER = 268437893;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_RESULT_FAIL = 268437894;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_RESULT_SUCCESS = 268437895;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_RESULT_PARTIAL = 268437896;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_RESULT_CANCEL = 268437897;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_SHOW_LAST = 268437898;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_ATTEMPTS = 268437899;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TASK_REPORT_ARCHIVE_TRANSFER_HISTORY = 268437900;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TASK_TASKSHORTDESC_ARCHIVER_TRANSFER_HISTORY = 268437901;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_RETRY_COUNT = 268437902;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_TRANSFER_RESULT_DETAILS = 268437903;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_CAMERA_RESULT = 268437904;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_DESCR_GPSLOCATION = 268437905;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_GPSLOCATION = 268437906;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_MOTION_DETECTION_CODEC_NOT_SUPPORTED_IN_SOFTWARE = 268437907;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_NEVER = 268437908;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ALLOW_TRANSCODING = 268437909;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ONLY_FOR_PTZ_CONTROL_AND_MOBILE_SERVER = 268437910;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ALWAYS_FOR_UNSUPPORTED_DEVICES_AND_CODECS = 268437911;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_STREAMS_LOCAL = 268437912;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_STREAMS_FEDERATED = 268437913;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_START_ADDRESS = 268437914;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PORT_INCREMENT = 268437915;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_PORT_INCREMENT = 268437916;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_UNIT_METADATAFILELOCATION = 268437917;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ONE_ROW_FOR_ALL_ORIGINS = 268437918;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ONE_ROW_PER_ORIGIN = 268437919;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_DISPLAY_MODE = 268437920;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_TEMPERATURE_DETECTION_ALARM_TO_REMOVE = 268437921;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_WARNING_CANNOT_CONNECT_CLOUDARCHIVEPLAYBACK = 268437922;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_CANNOT_CONNECT_CLOUDARCHIVEPLAYBACK = 268437923;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MENU_ITEM_CLOUD_ARCHIVE_PLAYBACK_SOURCE = 268437924;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ARCHIVES_REPORT_FORCE_DISPLAY_GAPS_GREATER_THAN = 268437925;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LOW_RESOLUTION_TO_LIVE = 268437926;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LIVE_TO_HIGH_RESOLUTION = 268437927;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_STREAM_DISPLAY_THRESHOLD = 268437928;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MAX_RESOLUTION_MJPEG_TRANSCODING = 268437929;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SHOW_ORIGINAL_STREAMS_FOR_ALL_TILES = 268437930;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_SHOW_PROTECTED_STREAMS_FOR_ALL_TILES = 268437931;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BUTTON_SHOW_ORIGINAL_STREAMS = 268437932;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BUTTON_SHOW_PROTECTED_STREAMS = 268437933;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_PRIVILEGE_RETRIEVE_CLOUD_ARCHIVES = 268437934;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_PRIVILEGE_TOOLTIP_RETRIEVE_CLOUD_ARCHIVES = 268437935;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CLOUD_STORAGE = 268437936;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CLOUD_STORAGE_NOT_ACTIVATED = 268437937;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CLOUD_STORAGE_OPEN_BROWSER_ACTIVATION = 268437938;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASK_CLOUD_STORAGE = 268437939;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_WHAT_IS_CLOUD_STORAGE = 268437940;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CLOUD_STORAGE_DESCRIPTION = 268437941;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LEARN_MORE = 268437942;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ACTIVATE_PRODUCT = 268437943;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_ACTIVITYTRAIL_RETRIEVE_FROM_CLOUD_ARCHIVE = 268437944;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_ACTION_ACTIVITYTRAIL_TYPE_RETRIEVEFROMCLOUD_REQUESTED = 268437945;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_CLOUD_PLAYBACK_IS_OFFLINE = 268437946;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_ORIGIN_RECORDED_BY_AUXILIARY_ARCHIVER = 268437947;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_REQUIRE_TLS_RTSPS = 268437948;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_CLOUD_STORAGE_ACTIVATION = 268437949;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_CLOUD_STORAGE_CONNECTION = 268437950;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_NOT_ENOUGH_REFERENCE_POINTS = 268437951;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_REFERENCE_POINTS_CHANGED = 268437952;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_VIDEOANALYTICS_CROWD_COUNTING = 268437953;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_CLOUD_ARCHIVE_TOKEN_MISSING = 268437954;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_CLOUD_ARCHIVE_TOKEN_MISSING_DETAILS = 268437955;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_ERROR_FORBIDDEN_TRANSFORMATION = 268437956;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_LIVE_TO_HIGH_MUST_BE_GREATER_OR_EQUAL_TO_LOW_TO_LIVE = 268437957;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_CLOUD_ARCHIVES_LONG_TERM_STORAGE = 268437958;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_CLOUD_ARCHIVES_REQUESTED_FROM_LONG_TERM_STORAGE = 268437959;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_NO_CLOUD_PLAYBACK_ROLE = 268437960;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_CREATE_ROLE = 268437961;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_DEACTIVATE_CLOUD_STORAGE = 268437962;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_DEACTIVATE_CLOUD_STORAGE = 268437963;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_EXISTING_CONFIG_NOT_DELETED = 268437964;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_NOT_ACTIVATED_CLOUDARCHIVEPLAYBACK = 268437965;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_WARNING_SERVER_UNAUTHORIZED_CLOUDARCHIVEPLAYBACK = 268437966;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_SERVER_UNAUTHORIZED_CLOUDARCHIVEPLAYBACK = 268437967;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_WARNING_NOT_FOUND_CLOUDARCHIVEPLAYBACK = 268437968;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_NOT_FOUND_CLOUDARCHIVEPLAYBACK = 268437969;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_WARNING_SYNCHRONIZATION_ERROR_CLOUDARCHIVEPLAYBACK = 268437970;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TITLE_WARNING_NETWORK_ERROR_CLOUDARCHIVEPLAYBACK = 268437971;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_CLOUD_STORAGE_NOT_SUPPORTED = 268437972;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_CLOUD_STORAGE_AUTHENTICATION = 268437973;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_COMMUNICATE_CLOUD_SERVICES = 268437974;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_CLOUD_TURNED_OFF = 268437975;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_CLOUD_STORAGE_DESCRIPTION = 268437976;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_CLOUD_PLAYBACK_NOT_ACTIVATED = 268437977;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_NOTIFICATION_NAME_RETRIEVE_CLOUD_ARCHIVES = 268437978;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RETRIEVING_CLOUD_ARCHIVES_CAMERAS_NOTIFICATION = 268437979;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_RETRIEVE_LTS_CLOUD_ARCHIVES = 268437980;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RETRIEVE_LTS_SHOULD_RETRY_STATUS = 268437981;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RETRIEVE_LTS_EXPIRED_DETAILS = 268437982;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RETRIEVE_LTS_FAILURE_DETAILS = 268437983;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RETRIEVE_LTS_RETRYABLE_ERROR_DETAILS = 268437984;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RETRIEVE_LTS_NOTIFICATION_COMPLTED = 268437985;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_COLUMNNAME_LTS_READY_BEFORE = 268437986;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_BUTTON_RETRIEVE_LTS_REMOVE_NOTIFICATION = 268437987;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_RETRIEVE_LTS_ROLE_UNAVAILABLE_NOT_UPDATING = 268437988;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTSP = 268437989;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_HTTP = 268437990;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_TILE_CLOUD_PLAYBACK_DOWNLOAD = 268437991;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_EVENT_AUDIOANALYTICS_VEHICLEHORN = 268437992;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_SECURITYDESK_OPTIONS_VIDEO_SYNC_VIDEO_BY_DEFAULT = 268437993;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_ARCHIVE_TRANSFER_CONSOLIDATION_BEFORE_INTEGRITY_CHECK = 268437994;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_SERVER_NOT_SYNCHRONIZED = 268437995;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_SERVER_NOT_SYNCHRONIZED_BIGGEST_TIME_DIFFERENCE = 268437996;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_SERVER_NOT_SYNCHRONIZED_TOLERANCE = 268437997;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_SERVER_NOT_SYNCHRONIZED_TIMEOUT = 268437998;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_SERVER_UNREACHABLE = 268437999;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_LIST_SERVERS_NOT_SYNCHRONIZED = 268438000;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MANAGED_APPLIANCE = 268438001;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_TASK_MANAGED_APPLIANCE = 268438002;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_MANAGEDARCHIVER_DATAREQUESTFAILED = 268438003;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_MANAGEDARCHIVER_NODATARECEIVED = 268438004;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_MANAGEDARCHIVER_STORAGEPROBLEM = 268438005;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_MANAGEDARCHIVER_INCOMPLETEDATARECEIVED = 268438006;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_MANAGEDARCHIVER_PERFORMANCEISSUES = 268438007;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_ERROR_CLOUDSCADAPTER_STATUSREQUESTFAILED = 268438008;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_MONITOR_INCOMING_EVENTS = 268438009;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_NOTIFY_HIGH_NUMBER_OF_EVENTS = 268438010;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_THRESHOLD_NUMBER_OF_EVENTS = 268438011;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TIME_PERIOD = 268438012;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TIME_PERIOD_MINUTE = 268438013;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_TOOLTIP_TIME_PERIOD = 268438014;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LISTVIEW_COLUMN_EVENTS_LAST_MINUTE = 268438015;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RTSP_PORT = 268438016;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_INCOMING_UDP_PORT_RANGE = 268438017;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_PTZ_OVERVIEW = 268438018;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_VIEWING_MODE_CORRIDOR = 268438019;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_TRANSFER_EVERYTHING_THAT_IS = 268438020;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_PUBLIC_IP = 268438021;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_CAMERAS_MISSING_STREAMGROUP = 268438022;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_MEDIAPROCESSOR_NO_NETWORK_MATCHING_CONSTRAINTS = 268438023;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_LOCAL_UDP_PORT_RANGE = 268438024;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_FILES_DELETION_BEFORE_SENT_CLOUD = 268438025;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_OLDEST_FILES_DELETION_DISK_FULL = 268438026;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RESOLUTION_STEPS_FILES_DELETION_BEFORE_SENT_CLOUD = 268438027;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RESOLUTION_STEPS_OLDEST_FILES_DELETION_DISK_FULL = 268438028;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_WARNING_ENUMERATE_FILES_DELETED = 268438029;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_FILES_DELETION_DETAILS = 268438030;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_INFO_RESOLUTION_STEPS_COULD_NOT_CONNECT_TO_CLOUD_STORAGE = 268438031;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_MESSAGE_WARNING_COULD_NOT_CONNECT_TO_CLOUD_STORAGE = 268438032;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_RECONNECTED_TO_CLOUD_STORAGE = 268438033;
    public static Genetec_VideoSurveillance_Resources_RE_L_S_STE_LABEL_WARNING_COULD_NOT_CONNECT_TO_CLOUD_STORAGE = 268438034;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_PRODUCTGROUP_ACCESSCONTROL = 67108864;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ENFORCE_VISITOR_ESCORT = 67108865;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_VISITOR_ESCORT = 67108866;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ENFORCE_TWOMAN_RULE = 67108867;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MAX_DELAY_SWIPES = 67108868;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_TWOMAN_RULE_ON = 67108869;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_TWOMAN_RULE_SIDE_A = 67108870;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_TWOMAN_RULE_SIDE_B = 67108871;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_TWOMAN_RULE = 67108872;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_TYPE_NAME_UNIT_ESCORTNOTSUPPORTED = 67108873;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_ESCORTNOTSUPPORTED = 67108874;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_TYPE_ACCESSPOINT_COMPANIONVISITORDENIED = 67108875;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_COMPANIONVISITORDENIED = 67108876;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CARDHOLDER_PICTURE = 67108880;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_DOOR_WAS_MANUALLY_UNLOCKED_BY = 67108881;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_DOOR_MAINTENANCE_MODE_SET = 67108882;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_DOOR_MAINTENANCE_MODE_CANCELLED = 67108883;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_UNLOCKING_OVERRIDE_FOR_DOOR = 67108884;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_LOCKING_OVERRIDE_FOR_DOOR = 67108885;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_UNLOCK_SCHEDULE_OVERRIDE_CANCELLED = 67108886;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_VERTXERROR_APB_HARDNOTSTRICT = 67108887;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_VERTXERROR_APB_TIMEOUT = 67108888;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_VERTXERROR_APB_ONEDOORONEREADER = 67108889;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MSGBOX_BODY_INTERLOCK_AND_APB_VERTX_ERROR = 67108891;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_WARNING_FAILED_CREDENTIAL_FETCH = 67108892;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_WARNING_CONFLICTING_CRED_FOUND = 67108893;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READERLESS_DOOR = 67108894;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DOOR_TYPE = 67108895;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EXTERNALSYSTEM_SYNERGISSCHEDULEIMPORT_NAME = 10750;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EXTERNALSYSTEM_SYNERGISSCHEDULEIMPORT_DESCRIPTION = 10751;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_OUT_OF_RANGE_VERIFYING_PIN = 67108898;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_ACCESSPOINT_WAS_SHUNTED_BY = 67108899;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ACTIVITY_DEVICE_WAS_SHUNTED_BY = 67108900;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_SHUNT_DOOR = 67108901;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_DISABLE_SHUNT = 67108902;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ONE_POTENTIAL_MATCH = 67108904;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_POTENTIAL_MATCHES = 67108905;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_STATE_ACTIVE = 67108906;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_STATE_SHUNTED = 67108907;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CARDFORMATCSN32BIT = 67108908;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ALL_READERS_SHUNTED = 67108909;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_FIRST_PERSON_RULE_OFFSET = 67108910;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_VERTX_FIR_OFFSET_NOTSUPPORTED_ERROR = 67108911;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_DIAGNOSTIC_ACCESSMANAGER_ROLES_CONFLICT = 67108912;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_DIAGNOSTIC_ACCESSMANAGER_ROLES_CONFLICT_DETAILS = 67108913;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_WARNING_LENGTH_OF_CREDENTIAL_IS_INVALID = 67108914;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_WARNING_SECURITY_CLEARANCE_IS_NOT_SYNCHED = 67108916;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_FIR_ACCESSRULES_NOTSUPPORTED_PERIMETER_UNIT = 67108920;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_FIR_SCHEDULES_NOTSUPPORTED_PERIMETER_UNIT = 67108921;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_NO_DEVICE = 67108922;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_VERTX_TOO_MANY_DEVICES = 67108923;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_DEVICE_NOT_RUNNING = 67108924;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_ARMING_INPUT_CANNOT_BE_QUERIED = 67108925;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ARMING_INPUT_TROUBLE_STATE = 67108926;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_DEVICE_IN_WARNING = 67108927;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_DEVICE_DOES_NOT_EXIST = 67108928;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ARMING_INPUT_OFFLINE = 67108929;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SMC_LOGIN = 67108930;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_LOGIN_TIMEOUT = 67108931;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_LOGIN_SUCCEEDED = 67108932;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_STRICT_ANTIPASSBACK_NO_READER_ON_ENTRY = 67108933;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_STRICT_ANTIPASSBACK_NO_READER_ON_EXIT = 67108934;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_AREA_DEVICES_IN_TROUBLE_SHORT_STATE = 67108935;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_AREA_DEVICES_IN_TROUBLE_OPEN_STATE = 67108936;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_FIR_AREA_WITH_ALWAYS_SCHEDULE = 67108937;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_FIR_AREA_NO_UNLOCK_SCHEDULE = 67108938;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_AUTONOMOUS_CAPACITIES_EXCEEDED = 67108939;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ENROLLMENT_UPDATED_UNIT_ENDPOINT = 67108940;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_UNIT_ALREADY_ENROLLED_NOT_STEALING = 67108941;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_UNIT_ALREADY_ENROLLED_STEAL_IT = 67108942;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_NOT_AUTO_ENROLLING = 67108943;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CONNECTION_REFUSED_BY_FACTORY = 67108944;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CONTACTING_TIMING_OUT = 67108945;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CONTACTING_FOR_LAST = 67108946;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SAS_ONE_PERIMETER_DOOR_ERROR = 67108949;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SAS_DOOR_WITH_NO_SENSOR_ERROR = 67108950;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ELEVATOR_OFFLINE_DEVICE = 67108951;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ELEVATOR_TOUBLE_STATE_DEVICE = 67108952;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_ELEVATOR_NO_ONLINE_DEVICES = 67108953;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_ELEVATOR_RELAY_NOT_CONFIGURED = 67108954;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_UNIT_UNABLE_TO_DISCOVER = 67108957;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_READER_OFFLINE = 67108958;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_ALL_INPUTS_OFFLINE = 67108959;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ONE_OR_MORE_INPUTS_OFFLINE = 67108960;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_ALL_OUTPUTS_OFFLINE = 67108961;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ONE_OR_MORE_OUTPUTS_OFFLINE = 67108962;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_NO_READER_IS_CONFIGURED = 67108963;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_NO_READER_CONFIGURED_ALL_INPUTS_OFFLINE = 67108964;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_NO_READER_CONFIGURED_AT_LEAST_ONE_INPUT_OFFLINE = 67108965;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_NO_READER_CONFIGURED_BUT_OUTPUT_CONFIGURED = 67108966;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_NO_I_O_CONFIGURED = 67108967;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_INPUTS_OFFLINE = 67108968;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_OUTPUTS_OFFLINE = 67108969;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ELEVATOR_ACCESSRULE_SUPPORT = 67108971;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ELEVATOR_SCHEDULE_SUPPORT = 67108972;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_UNIT_DISCONNECTED = 67108973;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_WIRING = 67108974;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_TRIGGER_DOOR_HELD = 67108975;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_TRIGGER_DOOR_FORCED = 67108976;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_CARDHOLDERGROUP_CANNOT_ADD_CHILD = 67108977;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACCESSPOINTTYPE_REX_SIDE_A = 67108978;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACCESSPOINTTYPE_REX_SIDE_B = 67108979;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACCESSPOINTTYPE_ENTRYSENSOR_SIDE_A = 67108980;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACCESSPOINTTYPE_ENTRYSENSOR_SIDE_B = 67108981;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_SIDE_A = 67108982;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_SIDE_B = 67108983;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_DUPLICATE_WIRINGTEMPLATE_NAME_X = 67108984;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_TOOLTIP_SAVE_WIRINGTEMPLATE = 67108985;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CAMERAS_SIDE_A = 67108986;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CAMERAS_SIDE_B = 67108987;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_ADD_ANOTHER_DOOR = 67108988;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_FIR_AREA_UNLOCKING_SCHEDULE_WITH_ALWAYS_SCHEDULE = 67108989;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_DATASTORE_NEWINTERFACEMODULE = 67108990;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MERCURY_ENCLOSURE = 67108991;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MERCURY_PORT_2 = 67108992;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MERCURY_PORT_3 = 67108993;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MERCURY_PORT_TB2 = 67108994;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MERCURY_PORT_TB3 = 67108995;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_STANDARD_WIEGAND = 67108996;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_STANDARD_MAGSTRIPE = 67108997;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_STANDARD_OSDP = 67108998;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_STANDARD_F2F = 67108999;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_STANDARD_SUPERVISED_F2F = 67109000;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_BI_COLOR = 67109001;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_2_WIRE = 67109002;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DORADO_780 = 67109003;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_LCD = 67109004;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_BIOSCRYPT = 67109005;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_OSDP = 67109006;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SNET = 67109007;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_MR20 = 67109008;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_HID = 67109009;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_INDALA = 67109010;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_MR20_NO_TAMPER = 67109011;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_4BIT_60SEC_KEEP_ALIVE = 67109012;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_8BIT_60SEC_KEEP_ALIVE = 67109013;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_4BIT_10SEC_KEEP_ALIVE = 67109014;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_KEYPAD_8BIT_10SEC_KEEP_ALIVE = 67109015;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_INTERFACE_MODULE_ADDRESS_CONFLICT = 67109016;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_INTERFACE_MODULE_IP_ADDRESS_CONFLICT = 67109017;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_TWOMAN_RULE_FEATURE = 67109018;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ACCESS_LEVELS = 67109019;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ACCESS_LEVELS_PER_CARDHOLDER = 67109020;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ACCESS_CONTROL_READERS = 67109021;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MONITOR_POINTS = 67109022;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CONTROL_POINTS = 67109023;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MONITOR_POINT_GROUPS = 67109024;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_INTERFACE_COUNT_PER_PORT = 67109025;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_PIN_DIGITS = 67109026;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CARD_BITS_SIZE = 67109027;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_PRECISION_ACCESS_LEVELS_PER_CARDHOLDER = 67109028;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_INPUTS_PER_ZONE = 67109029;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_DEVICE_OR_INTERFACE_OFFLINE = 67109030;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_INTERFACE_MODULE_CHANNEL_CONFLICT = 67109031;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ELEVATOR_CAB_READER_SHUNTED = 67109032;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_SUPERVISED_F2F_WITH_INPUTS = 67109033;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_POWER_INPUT_MEANING = 67109034;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_INVALID_DEBOUNCE = 67109035;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_WIRING_TEMPLATE = 67109036;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_UNIT_PASSWORD_CHANGE_WARNING = 67109038;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_STRICT_ANTIPASSBACK_SOME_REX_IN_OR_OUT = 67109039;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COMMUNICATIONMODE_PLAIN = 67109040;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COMMUNICATIONMODE_CRYPTED = 67109041;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COMMUNICATIONMODE_SIGNED = 67109042;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COMMUNICATIONMODE_CRYPTEDSIGNED = 67109043;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COMMUNICATIONMODE_RESERVED = 67109044;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_INVALID_INPUT_CONFIGURATION = 67109045;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_SHARED_MERCURY_ZONE_INPUT = 67109046;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_EXPIRATIONMODE_WHEN_NOT_USED = 67109047;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DOOR_NUMBER = 67109048;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ZONE_INTENANCE_ON = 67109049;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_TYPE_NAME_ZONE_MAINTENANCE_OFF = 67109050;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_REVERT_OUTPUT_BEHAVIOR_INACTIVE_TROUBLE = 67109051;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_OUTPUT_BEHAVIOR_NORMAL_COMPLEMENT = 67109052;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ACTIVATE_ACTION_ON_DISARMED_TROUBLE = 67121454;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SYNERGIS_SUPPORT_LOGS = 67121455;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ENABLE_UNIT_SUPPORT_LOGS = 67121456;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DISABLE_UNIT_SUPPORT_LOGS = 67121457;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_BUS_GRACE_PERIOD_DESC = 67121458;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_BUS_GRACE_PERIOD_PASSED = 67121459;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_BUS_GRACE_PERIOD_WARNING = 67121460;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_BUS_GRACE_PERIOD_FAILED = 67121461;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_COUNT_DESC = 67121462;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_COUNT_PASSED = 67121463;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_COUNT_WARNING = 67121464;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_COUNT_FAILED = 67121465;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_INTERVALS_DESC = 67121466;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_INTERVALS_PASSED = 67121467;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_INTERVALS_WARNING = 67121468;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_TIMEZONE_INTERVALS_FAILED = 67121469;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_UNLOCKING_TIMEZONE_INTERVALS_DESC = 67121470;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_UNLOCKING_TIMEZONE_INTERVALS_PASSED = 67121471;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_UNLOCKING_TIMEZONE_INTERVALS_WARNING = 67121472;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_UNLOCKING_TIMEZONE_INTERVALS_FAILED = 67121473;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ZONE_ARMING_TIMEZONE_INTERVALS_DESC = 67121474;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ZONE_ARMING_TIMEZONE_INTERVALS_PASSED = 67121475;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ZONE_ARMING_TIMEZONE_INTERVALS_WARNING = 67121476;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ZONE_ARMING_TIMEZONE_INTERVALS_FAILED = 67121477;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESS_LEVEL_COUNT_DESC = 67121478;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESS_LEVEL_COUNT_PASSED = 67121479;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESS_LEVEL_COUNT_WARNING = 67121480;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESS_LEVEL_COUNT_FAILED = 67121481;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_CREDENTIAL_COUNT_DESC = 67121482;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_CREDENTIAL_COUNT_PASSED = 67121483;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_CREDENTIAL_COUNT_WARNING = 67121484;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_CREDENTIAL_COUNT_FAILED = 67121485;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_DOOR_COUNT_DESC = 67121486;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_DOOR_COUNT_PASSED = 67121487;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_DOOR_COUNT_WARNING = 67121488;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_DOOR_COUNT_FAILED = 67121489;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_COUNT_DESC = 67121490;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_COUNT_PASSED = 67121491;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_COUNT_WARNING = 67121492;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_COUNT_FAILED = 67121493;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_READER_COUNT_DESC = 67121494;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_READER_COUNT_PASSED = 67121495;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_READER_COUNT_WARNING = 67121496;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_READER_COUNT_FAILED = 67121497;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_WEBSERVICE_VERSION_DESC = 67121498;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_WEBSERVICE_VERSION_PASSED = 67121499;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_WEBSERVICE_VERSION_WARNING = 67121500;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_WEBSERVICE_VERSION_FAILED = 67121501;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_ACCESSRULE_TIMEZONE_INTERVALS_DESC = 67121502;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_ACCESSRULE_TIMEZONE_INTERVALS_PASSED = 67121503;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_ACCESSRULE_TIMEZONE_INTERVALS_WARNING = 67121504;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_EP_ACCESSRULE_TIMEZONE_INTERVALS_FAILED = 67121505;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESSRULE_TIMEZONE_INTERVALS_DESC = 67121506;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESSRULE_TIMEZONE_INTERVALS_PASSED = 67121507;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESSRULE_TIMEZONE_INTERVALS_WARNING = 67121508;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_MREP_ACCESSRULE_TIMEZONE_INTERVALS_FAILED = 67121509;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_MASTER_CONTROLLER_NOT_RUNNING = 67121510;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_NO_INPUT_SELECTED = 67121511;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_AT_LEAST_ONE_IN_OUT_OFFLINE = 67121512;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_VERTX_SECUREMODE = 67121513;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SECUREMODE_DISABLED = 67121514;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SECUREMODE_INVALID_FIRMWARE = 67121515;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_VERTX_UNSECURE_PROTOCOLS_ENABLED = 67121516;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_VERTX_SSL_PROTOCOL_DISABLED_SECUREMODE = 67121517;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_EXCEPTION_DETAILS_WITH_DETAILS = 67121518;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_VERTX_SECUREMODE_DEFAULT_USER_INFO = 67121519;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_VERTX_SECUREMODE_MUST_BE_ENABLED = 67121520;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SAS_ATLEAST_TWO_PERIMETER_DOORS_ERROR = 67121521;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ROUTER = 67121522;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DOOR_LOCK_NUMBER = 67121523;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_INTERFACE_MODULE_DOOR_LOCK_NUMBER_CONFLICT = 67121524;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_READER_SECURED_OSDP = 67121525;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_INTERFACE_MODULE_ROUTER_CONFLICT = 67121526;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CUSTOM_OSDP = 67121527;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_DIAGNOSTIC_ACCESSMANAGER_ROLES_FETCHING_FROM_DIRECTORY = 67121528;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_READER_OSDP_SECURE_STATUS_DESC = 67121529;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_READER_OSDP_SECURE_STATUS_PASSED = 67121530;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_READER_OSDP_SECURE_STATUS_WARNING = 67121531;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_READER_OSDP_SECURE_STATUS_FAILED = 67121532;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_RIBBON_PORT = 67121533;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_WARNING_ANTIPASSBACK_EXEMPTION_IS_NOT_SYNCHED = 67122402;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_MISSING_DISCOVERY_PORT = 67122403;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DOOR_MAXIMUM_DELAY_INVALID = 67122404;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ERROR_UNIT_EXTENSION_NOT_REACHABLE = 67122405;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACCESS_CONTROL_SCHEDULE_NOT_SUPPORTED = 67122406;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_ZONE_SINGLE_IN_OUT_OFFLINE = 67122407;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_DIAGNOSTIC_ACCESSMANAGER_ROLES_FAILOVER_DATABASE_LOCALADDRESS = 67122408;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_DIAGNOSTIC_ACCESSMANAGER_ROLES_SYNERGIS_NODISCOVERYPORTS = 67122409;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_IOZONE_MULTIPLEUNITS_NOPEERTOPEER = 67122410;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_REASON_NAME_ACCESSPOINT_MAXOCCUPANCY = 67122411;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_STRICT_ANTIPASSBACK_NON_ZERO_TIMEOUT = 67122412;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_INVALID_USERNAME_OR_PASSWORD = 67122413;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_EVENT_ASSET_ON_WIRELESS_LOCK = 67122414;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SAS_INPUT_ON_DIFFERENT_UNIT_ERROR = 67122415;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_AREA_WITH_MAXOCUUPANCY_MULTIPLEUNITS_NOPEERTOPEER = 67122416;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_STRICT_ANTIPASSBACK_NO_UNLOCKING_SCHEDULES = 67122417;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_AREA_WITH_ANTIPASSBACK_MULTIPLEUNITS_NOGLOBALANTIPASSBACK = 67122418;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_MAXOCCUPANCY_REACHED = 67122419;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_MAXOCCUPANCY_ON_AREA = 67122420;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_WARNING_SETTING_IS_NOT_SYNCHED = 67122421;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_UNLOCKING_TIMEZONE_INTERVALS_DESC = 67122422;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_UNLOCKING_TIMEZONE_INTERVALS_PASSED = 67122423;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_UNLOCKING_TIMEZONE_INTERVALS_WARNING = 67122424;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_HEALTHREPORT_UNIT_UNLOCKING_TIMEZONE_INTERVALS_FAILED = 67122425;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CARDFORMATFASCN_75BIT = 67122426;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CARDFORMATFASCN_200BIT = 67122427;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CARDFORMATCSN_32BIT = 67122428;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_AGENCY = 67122429;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_SYSTEM = 67122430;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CS = 67122431;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ICI = 67122432;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_PI = 67122433;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_OC = 67122434;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_OI = 67122435;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_POA = 67122436;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_LRC = 67122437;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_RAW = 67122438;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_GATEWAYCERTIFICATE_ERROR = 67122439;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_NOGATEWAYCERTIFICATE_MESSAGE = 67122440;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_NOGATEWAYCERTIFICATE_DETAILS = 67122441;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_EVENTPOLLING_OK = 67122442;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_EVENTPOLLING_ERROR = 67122443;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_GATEWAYCOMMUNICATION_OK = 67122444;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_GATEWAYCOMMUNICATION_ERROR = 67122445;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_UNITCONFIGURATIONREPORTED_OK = 67122446;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_UNITCONFIGURATIONREPORTED_ERROR = 67122447;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_UNITCONFIGURATIONREPORTED_ERROR_DETAILS = 67122448;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_SOFTWIRE_ONLINE = 67122449;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_SOFTWIRE_OFFLINE = 67122450;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_SOFTWIRE_OFFLINE_DETAILS = 67122451;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_TOGGLEOUTPUTNOTSUPPORTED = 67122452;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_TOGGLEOUTPUTNOTSUPPORTED_DETAILS = 67122453;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_SYNERGISSAAS_TENANTALREADYLINKED = 67122454;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_STRICT_ANTIPASSBACK_MAINTENANCE_MODE_ON = 67122455;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_WARNING_REPORTING_SUBSYSTEM_NOT_RUNNING = 67122456;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_TIMED_APB_ONEDOORONEENTERINGREADER = 67122457;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MERCURY_PORT_1 = 67122458;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_CANNOT_ENROLL_ACCESSCONTROLUNIT_BECAUSE_CLOUDAGENTVERSION = 67122459;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_WARNING_SYNERGIS_P2P_TOO_MANY_UNITS = 67122460;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DIP_SWITCH = 67122461;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ACCESS_CONTROL_INTELLIGENT_CONTROLLER_DIALECT = 67122462;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_PIM400_DOOR_NUMBERS_CANT_OVERLAP_ON_SAME_PORT = 67122463;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_EP_CANNOT_HAVE_MORE_9_PIM_ON_SAME_PORT = 67122464;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_MERCURY_CANNOT_HAVE_GAETWAY_AND_MR51E_ON_SAME_PORT = 67122465;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_MSACS_DOWNSTREAM_ADDRESSES_SHOULD_BE_UNIQUE = 67122466;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_EP_MSICS_CANNOT_HAVE_MORE_THAN_2_MSCACS = 67122467;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_EP_MSACS_MISCONFIGURED = 67122468;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_MERCURY_CANNOT_HAVE_DOWNSTREAM_DEVICES_FROM_DIFFERENT_MANUFACTURERS_ON_SAME_PORT = 67122469;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_DOOR_LOCK_NUMBER_170 = 67122470;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_INVALID_CERTIFICATE = 67122471;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_MISSING_ECM_PLUGIN = 67122472;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_WARNING_ACCESSMANAGER_ROLES_MAXUNITCOUNTREACHED = 67122473;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_WARNING_ACCESSMANAGER_ROLES_APPROACHINGMAXUNITCOUNT = 67122474;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_WARNING_SYNERGIS_P2P_PASSWORD_NOT_SUPPORTED = 67122475;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_TASK_REPORTQUERY_UNITPROPAGATIONDELAY = 67122476;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_TOOLTIP_TASKSHORTDESC_UNITPROPAGATIONDELAY = 67122477;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_UNIT_UNITPROPAGATIONDELAY = 67122478;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_DEVICE_UNITPROPAGATIONDELAY = 67122479;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_ACCESSPOINT_UNITPROPAGATIONDELAY = 67122480;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_LATESTEVENT_UNITPROPAGATIONDELAY = 67122481;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_TOTALEVENTS_UNITPROPAGATIONDELAY = 67122482;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_TOTALDELAY_UNITPROPAGATIONDELAY = 67122483;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_AVERAGEDELAY_UNITPROPAGATIONDELAY = 67122484;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MINDELAY_UNITPROPAGATIONDELAY = 67122485;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_MAXDELAY_UNITPROPAGATIONDELAY = 67122486;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COUNTBELOW_UNITPROPAGATIONDELAY = 67122487;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COUNTBETWEEN_UNITPROPAGATIONDELAY = 67122488;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COUNTABOVE_UNITPROPAGATIONDELAY = 67122489;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COUNT4_UNITPROPAGATIONDELAY = 67122490;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_COUNT5_UNITPROPAGATIONDELAY = 67122491;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_PRIVILEGE_UNITPROPAGATIONDELAYREPORT = 67122492;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_UNIT_PROPAGATIONDELAY_REPORT = 67122493;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_PRIVILEGE_SHUNT_INPUTS = 67122494;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_TOOLTIP_PRIVILEGE_SHUNT_INPUTS = 67122495;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_TOOLTIP_SHUNT_UNSHUNT_INPUTS = 67122496;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_SHUNT_ALL_INPUTS = 67122497;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_SHUNT_DOOR_SENSORS = 67122498;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_SHUNT_ENTRY_SENSORS = 67122499;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_SHUNT_LOCK_SENSORS = 67122500;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_SHUNT_MANUAL_STATIONS = 67122501;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_SHUNT_REQUEST_TO_EXIT_INPUTS = 67122502;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_UNSHUNT_ALL_INPUTS = 67122503;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_UNSHUNT_DOOR_SENSORS = 67122504;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_UNSHUNT_ENTRY_SENSORS = 67122505;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_UNSHUNT_LOCK_SENSORS = 67122506;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_UNSHUNT_MANUAL_STATIONS = 67122507;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_ACTION_UNSHUNT_REQUEST_TO_EXIT_INPUTS = 67122508;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_INPUTS_SHUNTED = 67122509;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_WARNING_UNITOFFLINE = 67122510;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_LABEL_CONFIRM = 67122511;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_MAC_ADDRESS_LENGTH = 67122512;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_MAC_ADDRESS_HEX_ONLY = 67122513;
    public static Genetec_AccessControl_Resources_RE_L_S_STE_MESSAGE_ERROR_INTERFACE_MODULE_DOOR_LOCK_NUMBER_SHOULD_BE_UNIQUE = 67122514;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_DATASTORE_APP_NAME = 9;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_SECURITYDESK_APPNAME_SHORT = 12;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_WD_APP_NAME_SHORT = 20;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_LICENSE_AUTOVU_MANAGER_GEOCODER = 64;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_MESSAGE_COULD_NOT_CONNECT_TO_SHARP = 75;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_SERVICE_DISPLAY_NAME_SERVER_SERVICE = 94;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_DIAGNOSTICS_WARNING_DUPLICATE_LIVE_PORT_MESSAGE = 108;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_DIAGNOSTICS_ERROR_PORT_DETAILS = 112;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_TOOLTIP_PRIVILEGE_CONFIGTOOL_APPNAME_SHORT = 114;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_CALCULUS_INPUT = 120;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_TOOLTIP_PRIVILEGE_GUS_APPNAME_SHORT = 121;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_GUS_APPNAME = 122;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_MESSAGE_SHARP_BELONGS_TO_ANOTHER_ROLE = 123;
    public static Genetec_Sentinel_Resources_RE_T_S_STE_MESSAGE_INFO_COPYRIGHT = 125;
}
