import { NgModule } from '@angular/core';
import { DefaultUrlSerializer, RouterModule, Routes, UrlSerializer, UrlTree } from '@angular/router'; // CLI imports router
import { Constants } from '@src/constants';
import { TaskPluginComponent } from '../components/nav-bar/task-plugin/task-plugin.component';
import { OptionsComponent } from '../components/options/options.component';
import { AuthGuard } from '../security-center/services/authentication/auth-guard.service';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        return super.parse(url.toLowerCase());
    }
}

export const routes: Routes = [
    { path: '', component: TaskPluginComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    {
        path: `task/:${Constants.pluginTaskIdentifier}`,
        component: TaskPluginComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `task/:${Constants.pluginTaskIdentifier}/:${Constants.subTaskIdentifier}`,
        component: TaskPluginComponent,
        canActivate: [AuthGuard],
    },
    { path: 'options', outlet: 'modal', component: OptionsComponent },
    { path: '**', component: TaskPluginComponent, canActivate: [AuthGuard] }, // wildcard match
];

// configures NgModule imports and exports
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer,
        },
    ],
})
export class AppRoutingModule {}
