// Autogenerated: v2/help/Entities/ApiServer?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:01 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { LocatableEntity } from './LocatableEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { ITransaction } from '../Interface/ITransaction';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { IServerEntity, IRestRoleConfig, INetworkInterface, ServerEntityFields } from '../Interface/IServerEntity';

// #region Inner classes

export class RestRoleConfig extends FieldObject implements IRestRoleConfig {
    // PluginType
    public get pluginType(): IGuid {
        return this.getFieldGuid('PluginType');
    }
    public set pluginType(value: IGuid) {
        this.setFieldGuid('PluginType', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // Supports64Bits
    public get supports64Bits(): boolean {
        return this.getField<boolean>('Supports64Bits');
    }
    public set supports64Bits(value: boolean) {
        this.setField<boolean>('Supports64Bits', value);
    }
    // ServiceDomain
    public get serviceDomain(): string {
        return this.getField<string>('ServiceDomain');
    }
    public set serviceDomain(value: string) {
        this.setField<string>('ServiceDomain', value);
    }
    // PluginAssemblyLocation
    public get pluginAssemblyLocation(): string {
        return this.getField<string>('PluginAssemblyLocation');
    }
    public set pluginAssemblyLocation(value: string) {
        this.setField<string>('PluginAssemblyLocation', value);
    }
    // PluginTypeFullName
    public get pluginTypeFullName(): string {
        return this.getField<string>('PluginTypeFullName');
    }
    public set pluginTypeFullName(value: string) {
        this.setField<string>('PluginTypeFullName', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('PluginType', SafeGuid.EMPTY);
        this.setField<string>('Name', '');
        this.setField<string>('Description', '');
        this.setField<boolean>('Supports64Bits', false);
        this.setField<string>('ServiceDomain', '');
        this.setField<string>('PluginAssemblyLocation', '');
        this.setField<string>('PluginTypeFullName', '');
    }
}

export class NetworkInterface extends FieldObject implements INetworkInterface {
    // MacAddress
    public get macAddress(): string {
        return this.getField<string>('MacAddress');
    }
    public set macAddress(value: string) {
        this.setField<string>('MacAddress', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // PhysicalAddress
    public get physicalAddress(): string {
        return this.getField<string>('PhysicalAddress');
    }
    public set physicalAddress(value: string) {
        this.setField<string>('PhysicalAddress', value);
    }
    // IPv4
    public get iPv4(): string {
        return this.getField<string>('IPv4');
    }
    public set iPv4(value: string) {
        this.setField<string>('IPv4', value);
    }
    // IPv6
    public get iPv6(): string {
        return this.getField<string>('IPv6');
    }
    public set iPv6(value: string) {
        this.setField<string>('IPv6', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('MacAddress', '');
        this.setField<string>('Name', '');
        this.setField<string>('PhysicalAddress', '');
        this.setField<string>('IPv4', '');
        this.setField<string>('IPv6', '');
    }
}

// #endregion Inner classes

export class ServerEntity extends LocatableEntity implements IServerEntity {
    // #region REST Properties

    // DefaultDatabaseServer
    public get defaultDatabaseServer(): string {
        return this.getField<string>(ServerEntityFields.defaultDatabaseServerField);
    }
    public set defaultDatabaseServer(value: string) {
        this.setField<string>(ServerEntityFields.defaultDatabaseServerField, value);
    }

    // DescriptiveOSVersion
    public get descriptiveOSVersion(): string {
        return this.getField<string>(ServerEntityFields.descriptiveOSVersionField);
    }
    public set descriptiveOSVersion(value: string) {
        this.setField<string>(ServerEntityFields.descriptiveOSVersionField, value);
    }

    // DescriptiveProductVersion
    public get descriptiveProductVersion(): string {
        return this.getField<string>(ServerEntityFields.descriptiveProductVersionField);
    }

    // ExactVersion
    public get exactVersion(): string {
        return this.getField<string>(ServerEntityFields.exactVersionField);
    }

    // FullyQualifiedName
    public get fullyQualifiedName(): string {
        return this.getField<string>(ServerEntityFields.fullyQualifiedNameField);
    }

    // IsInBackwardCompatibilityMode
    public get isInBackwardCompatibilityMode(): boolean {
        return this.getField<boolean>(ServerEntityFields.isInBackwardCompatibilityModeField);
    }

    // IsMainServer
    public get isMainServer(): boolean {
        return this.getField<boolean>(ServerEntityFields.isMainServerField);
    }

    // IsProxy
    public get isProxy(): boolean {
        return this.getField<boolean>(ServerEntityFields.isProxyField);
    }

    // MajorVersion
    public get majorVersion(): string {
        return this.getField<string>(ServerEntityFields.majorVersionField);
    }

    // Network
    public get network(): IGuid {
        return this.getFieldGuid(ServerEntityFields.networkField);
    }

    // OSVersion
    public get oSVersion(): string {
        return this.getField<string>(ServerEntityFields.oSVersionField);
    }
    public set oSVersion(value: string) {
        this.setField<string>(ServerEntityFields.oSVersionField, value);
    }

    // PrivateAddress
    public get privateAddress(): string {
        return this.getField<string>(ServerEntityFields.privateAddressField, ValidFlags.Specific);
    }

    // PrivateAddresses
    public get privateAddresses(): ObservableCollection<string> {
        return this.getFieldArray<string>(ServerEntityFields.privateAddressesField, ValidFlags.Specific);
    }

    // PrivateLegacyPort
    public get privateLegacyPort(): number {
        return this.getField<number>(ServerEntityFields.privateLegacyPortField);
    }

    // PrivatePort
    public get privatePort(): number {
        return this.getField<number>(ServerEntityFields.privatePortField, ValidFlags.Specific);
    }
    public set privatePort(value: number) {
        this.setField<number>(ServerEntityFields.privatePortField, value);
    }

    // PublicAddress
    public get publicAddress(): string {
        return this.getField<string>(ServerEntityFields.publicAddressField);
    }

    // RestBaseEndpoint
    public get restBaseEndpoint(): string {
        return this.getField<string>(ServerEntityFields.restBaseEndpointField);
    }

    // RootWebApplicationPath
    public get rootWebApplicationPath(): string {
        return this.getField<string>(ServerEntityFields.rootWebApplicationPathField);
    }
    public set rootWebApplicationPath(value: string) {
        this.setField<string>(ServerEntityFields.rootWebApplicationPathField, value);
    }

    // SecureWebPort
    public get secureWebPort(): number {
        return this.getField<number>(ServerEntityFields.secureWebPortField, ValidFlags.Specific);
    }

    // ServerCertificate
    public get serverCertificate(): IGuid {
        return this.getFieldGuid(ServerEntityFields.serverCertificateField, ValidFlags.Specific);
    }

    // ServerCertificateThumbprint
    public get serverCertificateThumbprint(): string {
        return this.getField<string>(ServerEntityFields.serverCertificateThumbprintField, ValidFlags.Specific);
    }

    // ServerType
    public get serverType(): string {
        return this.getField<string>(ServerEntityFields.serverTypeField);
    }

    // WebPort
    public get webPort(): number {
        return this.getField<number>(ServerEntityFields.webPortField, ValidFlags.Specific);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Servers);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAgentsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Agents', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getDirectoriesAsync(value: string, transaction?: ITransaction): Promise<Array<string> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<string>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<string>;
                return new Promise<Array<string>>(function(resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<string>>(function(resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<Array<string>>('Directories?Parent=' + value, 'GET', null, transaction, responseHandler);
    }

    public async getDrivesAsync(transaction?: ITransaction): Promise<Array<string> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<string>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<string>;
                return new Promise<Array<string>>(function(resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<string>>(function(resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<Array<string>>('Drives', 'GET', null, transaction, responseHandler);
    }

    public async getFilesAsync(value: string, transaction?: ITransaction): Promise<Array<string> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<string>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<string>;
                return new Promise<Array<string>>(function(resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<string>>(function(resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<Array<string>>('Files?Parent=' + value, 'GET', null, transaction, responseHandler);
    }

    public async getNetworkInterfacesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<INetworkInterface> | null> {
        return this.getFieldObjectRelationAsync<NetworkInterface, INetworkInterface>(NetworkInterface, 'NetworkInterfaces', 'MacAddress', true, false, ValidFlags.None, transaction);
    }

    public async getRestRoleAvailableAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRestRoleConfig> | null> {
        return this.getFieldObjectRelationAsync<RestRoleConfig, IRestRoleConfig>(RestRoleConfig, 'RestRoleAvailable', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async getRolesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Roles', 'Id', true, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

