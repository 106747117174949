<gen-melted-modal
    genTitle="{{ 'STE_LABEL_CONDITIONS' | translate }}"
    genDefaultActionText="{{ 'STE_LABEL_OK' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genCanClose]="true"
    [genIsBlocking]="true"
>
    <gen-melted-modal-body>
        <div>
            <div [hidden]="!querying" class="parameters-loading-spinner-overlay">
                <!-- Querying spinner overlay on results container -->
                <gen-spinner [size]="SpinnerSize.Large" class="parameters-loading-spinner-overlay-position">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
            </div>
            <gen-grid [genColumns]="2" [genRows]="1">
                <gen-grid-item class="form-area">
                    <gen-search-input placeholder="{{ 'STE_ACTION_SEARCH' | translate }}" [ngModel]="searchText" (ngModelChange)="onSearchChange($event)"> </gen-search-input>
                    <!-- This code is not used right now -->
                    <!-- List of supported parameters -->
                    <gen-melted-list
                        [genItemsSource]="allSupportedParameters"
                        [genSelectionType]="SelectionType.List.Single"
                        (genSelectedItemChange)="onSelectedSupportedParameterChanged($event)"
                        [genSelectedItem]="selectedSupportedParameter"
                        class="parameter-list"
                    >
                    </gen-melted-list>
                </gen-grid-item>
                <gen-grid-item class="form-area">
                    <!-- The controls for the settings of the parameter -->
                    <ng-container *ngIf="selectedSupportedParameter">
                        <!-- Field name -->
                        <gen-text [flavor]="TextFlavor.Label" class="ha-left">
                            {{ 'STE_LABEL_DATAFIELD' | translate }}
                        </gen-text>
                        <gen-text [flavor]="TextFlavor.Normal" class="ha-left">
                            {{ selectedSupportedParameter.text }}
                        </gen-text>
                        <!-- Field comparison -->
                        <gen-combobox
                            genLabel="{{ 'STE_LABEL_COMPARISON' | translate }}"
                            [ngModel]="selectedComparison"
                            [genItemsSource]="comparisons"
                            (ngModelChange)="onComparisonChanged($event)"
                            [genFlavor]="Flavor.Combobox.Solid"
                        >
                        </gen-combobox>
                        <!-- TODO: plug in the min & max values for date-time. numeric is done -->
                        <!-- Field value -->
                        <ng-container [ngSwitch]="parameter.ValueType">
                            <div *ngSwitchCase="'Int'">
                                <gen-numeric-input
                                    [ngModel]="parameterValue"
                                    (ngModelChange)="onValueChanged($event)"
                                    [min]="selectedLightSupportedParameter.minimum !== undefined ? selectedLightSupportedParameter.minimum : null"
                                    [max]="selectedLightSupportedParameter.maximum !== undefined ? selectedLightSupportedParameter.maximum : null"
                                    [step]="1"
                                    name="valueInput1"
                                >
                                    <gen-label>{{ 'STE_LABEL_NOUN_VALUE' | translate }}</gen-label>
                                </gen-numeric-input>
                            </div>
                            <div *ngSwitchCase="'Long'">
                                <gen-numeric-input
                                    [ngModel]="parameterValue"
                                    (ngModelChange)="onValueChanged($event)"
                                    [min]="selectedLightSupportedParameter.minimum !== undefined ? selectedLightSupportedParameter.minimum : null"
                                    [max]="selectedLightSupportedParameter.maximum !== undefined ? selectedLightSupportedParameter.maximum : null"
                                    [step]="1"
                                    name="valueInput2"
                                >
                                    <gen-label>{{ 'STE_LABEL_NOUN_VALUE' | translate }}</gen-label></gen-numeric-input
                                >
                            </div>
                            <div *ngSwitchCase="'Double'">
                                <gen-numeric-input
                                    [ngModel]="parameterValue"
                                    (ngModelChange)="onValueChanged($event)"
                                    [min]="selectedLightSupportedParameter.minimum !== undefined ? selectedLightSupportedParameter.minimum : null"
                                    [max]="selectedLightSupportedParameter.maximum !== undefined ? selectedLightSupportedParameter.maximum : null"
                                    [step]="0.00000001"
                                    name="valueInput3"
                                    ><gen-label>{{ 'STE_LABEL_NOUN_VALUE' | translate }}</gen-label></gen-numeric-input
                                >
                            </div>
                            <div *ngSwitchCase="'Bool'">
                                <br />
                                <gen-switch [ngModel]="parameterValue" (ngModelChange)="onValueChanged($event)" name="valueInput4">
                                    <gen-label>
                                        {{ 'STE_LABEL_NOUN_VALUE' | translate }}
                                    </gen-label>
                                </gen-switch>
                            </div>
                            <div *ngSwitchCase="'Categorical'">
                                <app-one-or-many-combobox-combobox
                                    [options]="comboOptions"
                                    [values]="comboValue"
                                    (valuesChanged)="onComboboxValueChanged($event)"
                                    [genFlavor]="Flavor.Combobox.Solid"
                                    genLabel="{{ 'STE_LABEL_NOUN_VALUE' | translate }}"
                                    [multiSelect]="parameter.isMultiselect"
                                    name="valueInput5"
                                    [genShowClearButton]="true"
                                >
                                </app-one-or-many-combobox-combobox>
                            </div>
                            <div *ngSwitchCase="'Date'">
                                <gen-datetime-input
                                    genLabel="{{ 'STE_LABEL_NOUN_VALUE' | translate }}"
                                    [ngModel]="parameterValue"
                                    (ngModelChange)="onValueChanged($event)"
                                    name="valueInput6"
                                    [genFlavor]="Flavor.Calendar.DateTime"
                                    genFormat="L LT"
                                ></gen-datetime-input>
                            </div>
                            <div *ngSwitchCase="'Entity'">
                                <app-entity-combo
                                    [entityIds]="parameterValue"
                                    (entityIdsChange)="onEntityGuidsChanged($event)"
                                    name="valueInput7"
                                    genLabel="{{ 'STE_LABEL_NOUN_VALUE' | translate }}"
                                    [entityBrowserFilter]="entityFilter"
                                    [showClearButton]="true"
                                    [multiSelect]="parameter.isMultiselect"
                                ></app-entity-combo>
                            </div>
                            <div *ngSwitchDefault>
                                <gen-text-input [ngModel]="parameterValue" (ngModelChange)="onValueChanged($event)" name="valueInput8">
                                    <gen-label>{{ 'STE_LABEL_NOUN_VALUE' | translate }}</gen-label>
                                </gen-text-input>
                            </div>
                        </ng-container>
                    </ng-container>
                </gen-grid-item>
            </gen-grid>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
