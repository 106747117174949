import { ITileSizeObserverBuilder, TileSizeObserverBuilder } from './TileSizeProvider';

// zIndex:
// -- Main image --
// 1: Dewarping canvas / Mjpeg-Vwo
// 2: Dewarping Vwo overlay
// -- Preview --
// 10: Digital Zoom preview / Dewarping preview
// 11: Dewarping area (rez zone)
// -- Debug --
// 100: Debug overlay

export class HtmlElements {
  private static m_areEventListenersRegistered: boolean = false;

  private static m_lastMouseDownTarget: EventTarget | null = null;

  private readonly GwpPlayerClassName: string = 'hasGwpPlayer';

  private m_divContainer?: HTMLDivElement;
  private m_canvasElement?: HTMLCanvasElement; //jpeg player rendering, video watermark overlay
  private m_debugOverlayCanvas?: HTMLCanvasElement; //ctrl+shift+A
  private m_videoElement?: HTMLVideoElement;
  private m_audioElement?: HTMLAudioElement;
  private m_digitalZoomPreviewDivContainer?: HTMLDivElement;
  private m_digitalZoomPreviewCanvas?: HTMLCanvasElement;

  private m_dewarpingCanvas?: HTMLCanvasElement; //Dewarping drawing area
  private m_dewarpingVwoCanvas?: HTMLCanvasElement; //Used to put Vwo overlay over the dewarper. Cannot use the m_canvasElement because it could be the source image of the dewarping
  private m_dewarpingPreviewDivContainer?: HTMLDivElement; //Div containing the preview and the dewarpedArea canvas
  private m_dewarpingPreviewCanvas?: HTMLCanvasElement; //Original warped image for preview
  private m_dewarpedAreaCanvas?: HTMLCanvasElement; //Dewarped area (red zone)

  public get DivContainer(): HTMLDivElement {
    if (this.m_divContainer === undefined) {
      throw new Error('object disposed');
    }

    return this.m_divContainer;
  }

  public get CanvasElement(): HTMLCanvasElement {
    if (this.m_canvasElement === undefined) {
      throw new Error('object disposed');
    }

    return this.m_canvasElement;
  }

  public get DebugOverlayCanvas(): HTMLCanvasElement {
    if (this.m_debugOverlayCanvas === undefined) {
      throw new Error('object disposed');
    }

    return this.m_debugOverlayCanvas;
  }

  public get VideoElement(): HTMLVideoElement {
    if (this.m_videoElement === undefined) {
      throw new Error('object disposed');
    }

    return this.m_videoElement;
  }

  public get AudioElement(): HTMLAudioElement {
    if (this.m_audioElement === undefined) {
      throw new Error('object disposed');
    }

    return this.m_audioElement;
  }

  public get DewarpingCanvas(): HTMLCanvasElement {
    if (this.m_dewarpingCanvas === undefined) {
      throw new Error('object disposed');
    }
    return this.m_dewarpingCanvas;
  }

  public get DewarpingVwoCanvas(): HTMLCanvasElement {
    if (this.m_dewarpingVwoCanvas === undefined) {
      throw new Error('object disposed');
    }
    return this.m_dewarpingVwoCanvas;
  }

  public get DewarpingPreviewDiv(): HTMLDivElement {
    if (this.m_dewarpingPreviewDivContainer === undefined) {
      throw new Error('object disposed');
    }
    return this.m_dewarpingPreviewDivContainer;
  }

  public get DewarpingPreviewCanvas(): HTMLCanvasElement {
    if (this.m_dewarpingPreviewCanvas === undefined) {
      throw new Error('object disposed');
    }
    return this.m_dewarpingPreviewCanvas;
  }

  public get DewarpedAreaCanvas(): HTMLCanvasElement {
    if (this.m_dewarpedAreaCanvas === undefined) {
      throw new Error('object disposed');
    }
    return this.m_dewarpedAreaCanvas;
  }

  public get DigitalZoomPreviewCanvas(): HTMLCanvasElement {
    if (this.m_digitalZoomPreviewCanvas === undefined) {
      throw new Error('object disposed');
    }
    return this.m_digitalZoomPreviewCanvas;
  }

  public get IsFocusOnVideo(): boolean {
    // When the video element is hidden (such as when digital zooming), the div container is the mouse down target
    return HtmlElements.m_lastMouseDownTarget === this.VideoElement ||
      HtmlElements.m_lastMouseDownTarget === this.DivContainer ||
      HtmlElements.m_lastMouseDownTarget === this.DewarpingCanvas ||
      HtmlElements.m_lastMouseDownTarget === this.DewarpingVwoCanvas;
  }

  public getTileSizeObserverBuilder(): ITileSizeObserverBuilder {
    if (this.m_divContainer === undefined) {
      throw new Error('object disposed');
    }

    return new TileSizeObserverBuilder(this.m_divContainer);
  }

  constructor(divContainer: HTMLDivElement) {
    // Check if there is already a marmot created in this canvas
    if (divContainer.classList.contains(this.GwpPlayerClassName)) {
      throw new Error('This HTMLDivElement already contains a player');
    }

    this.m_divContainer = divContainer;
    this.m_canvasElement = document.createElement('canvas');
    this.m_canvasElement.id = 'mjpeg-vwo';
    divContainer.appendChild(this.m_canvasElement);

    this.m_debugOverlayCanvas = document.createElement('canvas');
    this.m_debugOverlayCanvas.id = 'debug-overlay';
    divContainer.appendChild(this.m_debugOverlayCanvas);

    this.m_videoElement = document.createElement('video');
    // Chrome's autoplay policies can prevent the video from playing after refreshing the page. Autoplay/Muted ensures the video always plays.
    // For more info: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
    this.m_videoElement.autoplay = true;
    this.m_videoElement.muted = true;
    // Only available in the latest Typescript version so using any to bypass it
    (this.m_videoElement as any).disablePictureInPicture = true;
    divContainer.appendChild(this.m_videoElement);

    this.m_audioElement = document.createElement('audio');
    divContainer.appendChild(this.m_audioElement);

    this.m_dewarpingCanvas = document.createElement('canvas');
    this.m_dewarpingCanvas.id = 'dewarping';
    divContainer.appendChild(this.m_dewarpingCanvas);

    this.m_dewarpingVwoCanvas = document.createElement('canvas');
    this.m_dewarpingVwoCanvas.id = 'dewarping vwo';
    divContainer.appendChild(this.m_dewarpingVwoCanvas);

    this.m_dewarpingPreviewDivContainer = document.createElement('div');
    this.m_dewarpingPreviewDivContainer.id = 'preview-div';
    divContainer.appendChild(this.m_dewarpingPreviewDivContainer);

    this.m_dewarpingPreviewCanvas = document.createElement('canvas');
    this.m_dewarpingPreviewCanvas.id = 'dewarping-preview';
    this.m_dewarpingPreviewDivContainer.appendChild(this.m_dewarpingPreviewCanvas);

    this.m_dewarpedAreaCanvas = document.createElement('canvas');
    this.m_dewarpedAreaCanvas.id = 'dewarped-area';
    this.m_dewarpingPreviewDivContainer.appendChild(this.m_dewarpedAreaCanvas);

    this.m_digitalZoomPreviewDivContainer = document.createElement('div');
    divContainer.appendChild(this.m_digitalZoomPreviewDivContainer);
    this.m_digitalZoomPreviewCanvas = document.createElement('canvas');
    this.m_digitalZoomPreviewDivContainer.appendChild(this.m_digitalZoomPreviewCanvas);
    this.setMarmotStyle(divContainer, this.m_canvasElement, this.m_debugOverlayCanvas, this.m_videoElement, this.m_audioElement);
    this.setDewarperStyles(this.m_dewarpingPreviewDivContainer, this.m_dewarpingCanvas, this.m_dewarpingVwoCanvas, this.m_dewarpingPreviewCanvas, this.m_dewarpedAreaCanvas);

    this.setDigitalZoomStyle(this.m_digitalZoomPreviewDivContainer, this.m_digitalZoomPreviewCanvas);
    divContainer.classList.add(this.GwpPlayerClassName);

    this.disableVideoRightClick();
    this.registerEventListeners();
  }

  public dispose(): void {
    // We set the element to "undefined" to avoid leaking them (if the lib user kept reference to our MarmotPlayer objects)
    if (this.m_divContainer !== undefined && this.CanvasElement !== undefined && this.DebugOverlayCanvas !== undefined && this.m_videoElement !== undefined && this.m_audioElement !== undefined &&
        this.m_dewarpingCanvas !== undefined && this.m_dewarpingVwoCanvas !== undefined && this.m_dewarpingPreviewDivContainer !== undefined && this.m_dewarpingPreviewCanvas !== undefined && this.m_dewarpedAreaCanvas !== undefined && this.m_digitalZoomPreviewCanvas !== undefined && this.m_digitalZoomPreviewDivContainer !== undefined) {
      this.VideoElement.removeEventListener('contextmenu', this.onContextMenu, true);

      this.m_divContainer.removeChild(this.CanvasElement);
      this.m_divContainer.removeChild(this.DebugOverlayCanvas);
      this.m_divContainer.removeChild(this.m_videoElement);
      this.m_divContainer.removeChild(this.m_audioElement);
      this.m_divContainer.removeChild(this.DewarpingCanvas);
      this.m_divContainer.removeChild(this.DewarpingVwoCanvas);

      this.m_dewarpingPreviewDivContainer.removeChild(this.m_dewarpingPreviewCanvas);
      this.m_dewarpingPreviewDivContainer.removeChild(this.m_dewarpedAreaCanvas);
      this.m_divContainer.removeChild(this.m_dewarpingPreviewDivContainer);


      this.m_digitalZoomPreviewDivContainer.removeChild(this.m_digitalZoomPreviewCanvas);
      this.m_divContainer.removeChild(this.m_digitalZoomPreviewDivContainer);
      this.m_divContainer.classList.remove(this.GwpPlayerClassName);

      this.m_divContainer = undefined;
      this.m_canvasElement = undefined;
      this.m_debugOverlayCanvas = undefined;
      this.m_videoElement = undefined;
      this.m_audioElement = undefined;

      this.m_dewarpingCanvas = undefined;
      this.m_dewarpingVwoCanvas = undefined;
      this.m_dewarpingPreviewDivContainer = undefined;
      this.m_dewarpingPreviewCanvas = undefined;
      this.m_dewarpedAreaCanvas = undefined;
      this.m_digitalZoomPreviewCanvas = undefined;
      this.m_digitalZoomPreviewDivContainer = undefined;
    }
  }

  /*
* Marmot HTML elements need custom style to behave properly.
* Inject it here so the user won't need to do it
*/
  private setMarmotStyle(divContainer: HTMLDivElement, canvas: HTMLCanvasElement, debugCanvas: HTMLCanvasElement, video: HTMLVideoElement, audio: HTMLAudioElement): void {
    // The div container
    divContainer.style.height = '100%';
    divContainer.style.position = 'relative';
    divContainer.style.backgroundColor = '#000000';
    divContainer.style.display = 'block';
    divContainer.style.flexDirection = 'column';
    divContainer.style.webkitUserSelect = 'none';
    divContainer.style.userSelect = 'none';
    divContainer.style.overflow = 'hidden';

    // The video element
    video.style.width = '100%';
    video.style.height = '100%';
    video.style.objectFit = 'contain';

    // The audio element
    audio.style.display = 'none';

    // The mjpeg/vwo canvas elements
    this.setCanvasStyle(canvas);
    canvas.style.zIndex = '1';

    // The debug overlay
    this.setCanvasStyle(debugCanvas);
    debugCanvas.style.zIndex = '100';
  }

  private setDigitalZoomStyle(divElement: HTMLDivElement, canvas: HTMLCanvasElement) {
    // div for preview area
    divElement.style.top = '0';
    divElement.style.bottom = '0';
    divElement.style.right = '0';
    divElement.style.left = '0';
    divElement.style.position = 'absolute';
    divElement.style.width = '20%';
    divElement.style.height = '20%';

    // The digital zoom preview
    canvas.style.top = '0';
    canvas.style.bottom = '0';
    canvas.style.right = '0';
    canvas.style.left = '0';
    canvas.style.pointerEvents = 'auto';
    canvas.style.position = 'absolute';
    canvas.style.zIndex = '10';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.objectFit = 'contain';
    canvas.style.objectPosition = 'top left';
    canvas.style.display = 'none';
  }

  setCanvasStyle(canvas: HTMLCanvasElement) {
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.top = '0';
    canvas.style.bottom = '0';
    canvas.style.right = '0';
    canvas.style.left = '0';
    canvas.style.margin = 'auto';
    canvas.style.pointerEvents = 'none';
    canvas.style.position = 'absolute';
  }

  private setDewarperStyles(dewarpingPreviewDivContainer: HTMLDivElement, dewarping: HTMLCanvasElement, dewarpingVwo: HTMLCanvasElement, dewarpingPreview: HTMLCanvasElement, dewarpedArea: HTMLCanvasElement): void {
    // The dewarping
    this.setCanvasStyle(dewarping);
    dewarping.style.pointerEvents = 'auto'; //zoom listening
    dewarping.style.zIndex = '1';

    this.setCanvasStyle(dewarpingVwo);
    dewarpingVwo.style.pointerEvents = 'none'; //makes the click passthrough
    dewarpingVwo.style.zIndex = '2';


    // div for preview/dewarped area
    dewarpingPreviewDivContainer.style.top = '0';
    dewarpingPreviewDivContainer.style.bottom = '0';
    dewarpingPreviewDivContainer.style.right = '0';
    dewarpingPreviewDivContainer.style.left = '0';
    dewarpingPreviewDivContainer.style.position = 'absolute';
    dewarpingPreviewDivContainer.style.width = '20%';
    dewarpingPreviewDivContainer.style.height = '20%';

    // The dewarping preview
    dewarpingPreview.style.top = '0';
    dewarpingPreview.style.bottom = '0';
    dewarpingPreview.style.right = '0';
    dewarpingPreview.style.left = '0';
    dewarpingPreview.style.pointerEvents = 'auto';
    dewarpingPreview.style.position = 'absolute';
    dewarpingPreview.style.zIndex = '10';
    dewarpingPreview.style.width = '100%';
    dewarpingPreview.style.height = '100%';
    dewarpingPreview.style.objectFit = 'contain';
    dewarpingPreview.style.objectPosition = 'top left';

    // The dewarped area
    dewarpedArea.style.top = '0';
    dewarpedArea.style.bottom = '0';
    dewarpedArea.style.right = '0';
    dewarpedArea.style.left = '0';
    dewarpedArea.style.pointerEvents = 'none'; //makes the click event pass through and reach the preview canvas
    dewarpedArea.style.position = 'absolute';
    dewarpedArea.style.zIndex = '11';
    dewarpedArea.style.width = '100%';
    dewarpedArea.style.height = '100%';
    dewarpedArea.style.objectFit = 'contain';
    dewarpedArea.style.objectPosition = 'top left';
    dewarpedArea.style.color = 'rgba(90, 0, 0, 0.35)';
    dewarpedArea.style.borderColor = 'rgba(255, 0, 0, 1)';
  }

  private disableVideoRightClick() {
    this.VideoElement.addEventListener('contextmenu', this.onContextMenu, true);
    this.DewarpingPreviewCanvas.addEventListener('contextmenu', this.onContextMenu, true);
  }

  private registerEventListeners() {
    if (HtmlElements.m_areEventListenersRegistered) {
      return;
    }

    // TODO: unregister
    document.addEventListener('mousedown',
      function(event) {
        HtmlElements.m_lastMouseDownTarget = event.target; // We keep track of the last clicked element in the page
      }, false);

    HtmlElements.m_areEventListenersRegistered = true;
  }

  private readonly onContextMenu = (e: MouseEvent): void => {
    e.preventDefault();
  }
}
