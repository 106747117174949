import { ErrorDetails, StreamingStatus, SegmentInfo, JpegInfo, SerializedPlayerState, TimelineContent, SerializedPlaySpeed } from './eventHub';
import { ILogger } from '../utils/logger';
import { PlaySpeed } from '../players/PlaySpeed';
import { UnregisteredHub } from './UnregisteredHub';
import { TimeSpan } from '../utils/TimeSpan';

/**
 * @beta
 * */
export interface IDataHub {
  receiveSegment(info: SegmentInfo, buffer: Int8Array): void;
  receiveJpeg(info: JpegInfo, buffer: Int8Array): void;
}

/**
 * @beta
 */
export interface IEventHub {
  receivePlayerState(state: SerializedPlayerState): void;
  receiveStreamingConnectionStatus(status: StreamingStatus): void;
  receiveBufferingProgress(progress: number): void;
  receiveErrorDetails(errorDetails: ErrorDetails): void;
  receiveTimelineContent(content: TimelineContent): void;
  receivePlaySpeed(playSpeed: SerializedPlaySpeed): void;
  receiveAudioAvailable(isAudioAvailable: boolean): void;
}

/**
 * @beta
 */
export interface IEventDispatcher {
  receiveSegment(streamId: string, info: SegmentInfo, buffer: Int8Array): void;
  receiveJpeg(streamId: string, info: JpegInfo, buffer: Int8Array): void;
  receivePlayerState(streamId: string, state: SerializedPlayerState): void;
  receiveStreamingConnectionStatus(streamId: string, status: StreamingStatus): void;
  receiveBufferingProgress(streamId: string, progress: number): void;
  receiveErrorDetails(streamId: string, errorDetails: ErrorDetails): void;
  receiveTimelineContent(streamId: string, content: TimelineContent): void;
  receivePlaySpeedChange(streamId: string, playSpeed: SerializedPlaySpeed): void;
  receiveAudioAvailable(streamId: string, isAudioAvailable: boolean): void;
}

export interface IEventHubProvider {
  registerStream(streamId: string, dataHub: IDataHub, eventHub: IEventHub): void;
  unregisterStream(streamId: string): void;
}

export class EventHubDispatcher implements IEventHubProvider, IEventDispatcher {
  private readonly m_logger: ILogger;

  private readonly m_registered: { [id: string]: [IDataHub, IEventHub] };

  constructor(logger: ILogger) {
    this.m_logger = logger.subLogger('EventHubDispatcher');
    this.m_registered = {};
  }

  public registerStream(streamId: string, dataHub: IDataHub, eventHub: IEventHub): void {
    if (this.m_registered.hasOwnProperty(streamId)) {
      throw new Error(`streamId ${streamId} is already registered`);
    }

    this.m_registered[streamId] = [dataHub, eventHub];
    this.m_logger.debug?.trace(`Registered streamId ${streamId}`);
  }

  public unregisterStream(streamId: string) {
    if (!this.m_registered.hasOwnProperty(streamId)) {
      throw new Error(`streamId ${streamId} was not registered`);
    }
    if (this.m_registered[streamId][0] instanceof UnregisteredHub) {
      this.m_logger.debug?.trace(`Deleting UnregisteredHub from EventHubDispatcher for streamId ${streamId}`);
      delete this.m_registered[streamId];
    } else {
      this.m_logger.debug?.trace(`Creating new UnregisteredHub from EventHubDispatcher for streamId ${streamId}`);

      const unregisteredHub = new UnregisteredHub(this.m_logger, streamId, this, TimeSpan.fromSeconds(5));
      this.m_registered[streamId] = [unregisteredHub, unregisteredHub];
    }

    this.m_logger.debug?.trace(`Removed streamId ${streamId}`);
  }

  public receiveSegment(streamId: string, info: SegmentInfo, buffer: Int8Array) {
    this.findDataHub(streamId)?.receiveSegment?.(info, buffer);
  }

  public receiveJpeg(streamId: string, info: JpegInfo, buffer: Int8Array) {
    this.findDataHub(streamId)?.receiveJpeg?.(info, buffer);
  }

  public receivePlayerState(streamId: string, state: SerializedPlayerState) {
    this.findEventHub(streamId)?.receivePlayerState?.(state);
  }

  public receiveStreamingConnectionStatus(streamId: string, status: StreamingStatus) {
    this.findEventHub(streamId)?.receiveStreamingConnectionStatus?.(status);
  }

  public receiveBufferingProgress(streamId: string, progress: number) {
    this.findEventHub(streamId)?.receiveBufferingProgress?.(progress);
  }

  public receiveErrorDetails(streamId: string, errorDetails: ErrorDetails) {
    this.findEventHub(streamId)?.receiveErrorDetails?.(errorDetails);
  }

  public receiveTimelineContent(streamId: string, content: TimelineContent) {
    this.findEventHub(streamId)?.receiveTimelineContent?.(content);
  }

  public receivePlaySpeedChange(streamId: string, playSpeed: SerializedPlaySpeed) {
    this.findEventHub(streamId)?.receivePlaySpeed?.(new PlaySpeed(playSpeed.Value));
  }

  public receiveAudioAvailable(streamId: string, isAudioAvailable: boolean) {
    this.findEventHub(streamId)?.receiveAudioAvailable?.(isAudioAvailable);
  }

  private findDataHub(streamId: string): IDataHub | null {
    const hubTuple = this.find(streamId);
    if (hubTuple === null) {
      return null;
    }
    return hubTuple[0];
  }

  private findEventHub(streamId: string): IEventHub | null {
    const hubTuple = this.find(streamId);
    if (hubTuple === null) {
      return null;
    }
    return hubTuple[1];
  }

  private find(streamId: string): [IDataHub, IEventHub] | null {
    const hub = this.m_registered[streamId];
    if (hub === undefined) {
      this.m_logger.warn?.trace(`No session found for ${streamId}`);
      return null;
    }
    return hub;
  }
}
