import { Component, OnInit } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { LatLngBounds } from '@genetec/web-maps';
import { ReadLocation } from '@modules/lpr/api/api';
import { LprContentFields } from '@modules/lpr/enumerations/lpr-content-fields';
import { LprContentTypes } from '@modules/lpr/enumerations/lpr-content-types';
import { MapService } from '@modules/maps/services/map/map-service';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { from } from 'rxjs';
import { take } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2022 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
const isContentSupported = (content: Content): boolean => {
    return (
        (content.type.equals(LprContentTypes.ReadEvent) || content.type.equals(LprContentTypes.HitEvent)) &&
        content.parameters != null &&
        content.parameters.hasField(LprContentFields.ReadLocations) &&
        content.parameters.getField<string[]>(LprContentFields.ReadLocations).length > 0
    );
};

@UntilDestroy()
@InternalContentPluginDescriptor({
    type: ReadLocationWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: ReadLocationWidgetComponent.pluginId, priority: 2 },
    isContentSupported,
})
@Component({
    selector: 'app-read-location-widget',
    templateUrl: './read-location-widget.component.html',
    styleUrls: ['./read-location-widget.component.scss'],
    providers: [MapService],
})
export class ReadLocationWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('0dd4c0ad-f610-4419-be71-f27b5746098a');

    public dataContext?: ContentService;
    public content?: Content;
    public readLocations: ReadLocation[] = [];
    public isLoading = true;
    public Icon = Icon;

    constructor(trackingService: TrackingService, private mapService: MapService) {
        super(trackingService);
    }

    ngOnInit() {
        if (this.content?.parameters?.hasField(LprContentFields.ReadLocations)) {
            this.readLocations = this.content.parameters.getField<string[]>(LprContentFields.ReadLocations).map((location) => ReadLocation.fromJS(location));
        }
        from(this.mapService.initializeMap({ mapId: null, withEvents: false }))
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
                this.addAndZoomToReadLocations();
                this.isLoading = false;
            });
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext as ContentService;
    }

    private addAndZoomToReadLocations() {
        this.addReadLocationsToMap();
        this.fitMapBoundsToReadLocations();
    }

    private addReadLocationsToMap() {
        this.readLocations.forEach((readLocation, index) => {
            if (readLocation.latitude && readLocation.longitude) {
                this.mapService.addPin(`pin${index}`, { latitude: readLocation.latitude, longitude: readLocation.longitude });
            }
        });
    }

    private fitMapBoundsToReadLocations() {
        const latitudes = this.readLocations.map((location) => location.latitude).filter((latitude) => latitude);
        const longitudes = this.readLocations.map((location) => location.longitude).filter((longitude) => longitude);

        if (!this.mapService.map || latitudes.length === 0 || longitudes.length === 0) return;

        const southWest = { lat: latitudes.min() as number, lng: longitudes.min() as number };
        const northEast = { lat: latitudes.max() as number, lng: longitudes.max() as number };

        this.mapService.map.fitBounds(new LatLngBounds(southWest, northEast), { maxZoom: 8 });
    }
}
