import { Injectable } from '@angular/core';
import { EventMonitoringContext } from '@modules/shared/enumerations/analytics-event-monitoring-context';
import { AnalyticsNames } from '@modules/shared/enumerations/analytics-names';
import { AnalyticsService } from './analytics.service';

@Injectable({
    providedIn: 'root',
})
export class EventMonitoringLoggerService {
    constructor(private analyticsService: AnalyticsService) {}

    public logEvent(action: string, description: string, context: EventMonitoringContext, value?: unknown): void {
        const properties: Record<string, unknown> = {
            action,
            description,
            context,
        };

        if (typeof value !== 'undefined') {
            properties.value = value;
        }

        this.analyticsService.logEvent(AnalyticsNames.Shared.EventMonitoring, properties);
    }
}
