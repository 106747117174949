// Autogenerated: v2/help/Entities/ApiTilePlugin?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:08 PM
import { IEntity, EntityFields } from './IEntity';
import { IGuid } from 'safeguid';

// #region Fields

export class TilePluginEntityFields extends EntityFields {
    public static contextField = 'Context';
    public static contextIdField = 'ContextId';
    public static fileField = 'File';
    public static rawLargeImageField = 'RawLargeImage';
    public static rawSmallImageField = 'RawSmallImage';
    public static urlField = 'Url';
    public static urlDisplayModeField = 'UrlDisplayMode';
}

// #endregion Fields

export interface ITilePluginEntity extends IEntity {
    // #region REST Properties

    context: string;

    contextId: IGuid;

    readonly file: IGuid;

    readonly rawLargeImage: string;

    readonly rawSmallImage: string;

    url: string;

    urlDisplayMode: string;

    // #endregion REST Properties

}

