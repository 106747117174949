// Autogenerated: v2/help/Entities/ApiElevator?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class ElevatorEntityFields extends EntityFields {
    public static cabinReaderField = 'CabinReader';
}

// #endregion Fields

export interface IElevatorEntity extends IEntity {
    // #region REST Properties

    readonly cabinReader: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    getFloorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

