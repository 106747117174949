import { Injectable } from '@angular/core';
import {
    AlterationType,
    DescriptionChangedEvent,
    ExternalIdChangedEvent,
    IncidentEvent,
    IncidentEventType,
    LinkedEvent,
    LocationChangedEvent,
    OwnershipChangedEvent,
    PriorityChangedEvent,
    ProcedureStepAnsweredEvent,
    RecipientAlteredEvent,
    StateChangedEvent,
    UnlinkedEvent,
} from '@modules/mission-control/models/events/incident-event';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import { McProcedure } from '@modules/mission-control/models/procedure';
import { ProcedureService } from '@modules/mission-control/services/procedures/procedure.service';
import { StateGuids } from '@modules/mission-control/state-guids';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { List } from 'immutable';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { McEventReceiverService } from '../events/mc-event-receiver.service';
import { IncidentEventService } from './../events/incident-event.service';
import { IncidentApiService } from './incident-api.service';
import { IncidentHandler } from './incident-handler';

@UntilDestroy()
@Injectable()
export class IncidentSelectionService {
    private _refresh$ = new BehaviorSubject<MCIncident | null>(null);
    private _selectedIncident$!: Observable<MCIncident | null>;

    constructor(
        private _incidentEventService: IncidentEventService,
        private _procedureService: ProcedureService,
        private _incidentApiService: IncidentApiService,
        _eventReceiver: McEventReceiverService
    ) {
        this.createSelectedObservable();

        _eventReceiver
            .getIncidentEvents()
            .pipe(
                withLatestFrom(this._refresh$),
                tap(async ([events, selectedIncident]) => await this.handleIncidentEvent(events, selectedIncident)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public get selectedIncident$(): Observable<MCIncident | null> {
        return this._selectedIncident$;
    }

    public async selectIncident(incident: MCIncident): Promise<void> {
        const incidentEventsPromise = this._incidentEventService.getAllIncidentEvents<IncidentEvent>(incident.id).toPromise();
        let procedurePromise: Promise<McProcedure> | null = null;
        if (incident.procedureId && incident.procedureRevision) {
            procedurePromise = this._procedureService.getProcedure(incident.procedureId, incident.procedureRevision).toPromise();
        }
        let newIncident: MCIncident;
        if (procedurePromise) {
            await Promise.all([incidentEventsPromise, procedurePromise]);
            newIncident = incident.with({
                events: List<IncidentEvent>(await incidentEventsPromise),
                procedure: await procedurePromise,
            });
        } else {
            newIncident = incident.with({
                events: List<IncidentEvent>(await incidentEventsPromise),
            });
        }
        this._refresh$.next(newIncident);
    }

    private async handleIncidentEvent(events: IncidentEvent[], selectedIncident: MCIncident | null): Promise<void> {
        for (const event of events) {
            const handleSelectedEvent = async (incident: MCIncident): Promise<MCIncident | null> => {
                const oldEventList = incident?.events;
                incident = incident.with({ events: oldEventList.insert(0, event) });
                switch (event?.type) {
                    case IncidentEventType.StateChanged:
                        return this.handleSelectedStateChange(incident, event as StateChangedEvent);
                    case IncidentEventType.RecipientsAltered:
                        return await this.handleSelectedRecipientChange(incident, event as RecipientAlteredEvent);
                    case IncidentEventType.PriorityChanged:
                        return IncidentHandler.priorityChange(incident, event as PriorityChangedEvent);
                    case IncidentEventType.LocationChanged:
                        return IncidentHandler.locationChange(incident, event as LocationChangedEvent);
                    case IncidentEventType.DescriptionChanged:
                        return IncidentHandler.descriptionChange(incident, event as DescriptionChangedEvent);
                    case IncidentEventType.ExternalIdChanged:
                        return IncidentHandler.externalIdChange(incident, event as ExternalIdChangedEvent);
                    case IncidentEventType.ProcedureStepAnswered:
                        return IncidentHandler.currentStepChange(incident, event as ProcedureStepAnsweredEvent);
                    case IncidentEventType.OwnershipChanged:
                        return IncidentHandler.ownerChange(incident, event as OwnershipChangedEvent);
                    case IncidentEventType.Linked:
                        return IncidentHandler.linkIncidents(incident, event as LinkedEvent);
                    case IncidentEventType.Unlinked:
                        return IncidentHandler.unlinkIncidents(incident, event as UnlinkedEvent);
                    case IncidentEventType.LocationCleared:
                        return IncidentHandler.clearLocation(incident);
                    case IncidentEventType.IncidentTypeChanged:
                        return await this.handleSelectedIncidentTypeChange(incident);
                }

                return incident;
            };

            const isEventForSelection = selectedIncident?.id.equals(event.incidentId) ?? false;
            if (isEventForSelection && selectedIncident) {
                selectedIncident = await handleSelectedEvent(selectedIncident);
            }

            this._refresh$.next(selectedIncident);
        }
    }

    private handleSelectedStateChange(incident: MCIncident, event: StateChangedEvent): MCIncident | null {
        return !event.stateId.equals(StateGuids.CLOSED) ? IncidentHandler.stateChange(incident, event) : null;
    }

    private createSelectedObservable(): void {
        this._selectedIncident$ = this._refresh$.pipe(
            distinctUntilChanged((a, b) => isEqual(a, b)),
            shareReplay(1)
        );
    }

    private async handleSelectedIncidentTypeChange(incident: MCIncident): Promise<MCIncident | null> {
        const newIncident = await this._incidentApiService.getIncident(incident.id).toPromise();
        if (newIncident) {
            return IncidentHandler.incidentTypeChange(incident, newIncident);
        } else {
            return null;
        }
    }

    private async handleSelectedRecipientChange(incident: MCIncident, event: RecipientAlteredEvent): Promise<MCIncident | null> {
        incident = incident.with({ recipientsMode: event.recipientsMode });
        switch (event.alterationType) {
            case AlterationType.Dispatch:
            case AlterationType.Forward: {
                return IncidentHandler.recipientChange(incident, event);
            }
            case AlterationType.Transfer: {
                const newIncident = await this._incidentApiService.getIncident(incident.id).toPromise();
                if (!newIncident) {
                    return null;
                }
                return newIncident;
            }
        }
        return incident;
    }
}
