import { Component, OnDestroy, OnInit } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent, PluginContext } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { ZoneEntity } from 'RestClient/Client/Model/AccessControl/ZoneEntity';
import { IEntityCacheTask, modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { IZoneEntity, ZoneEntityFields } from 'RestClient/Client/Interface/IZoneEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { ZoneArmingState } from 'RestClient/Client/Enumerations/ZoneArmingState';
import { ZoneState } from 'RestClient/Client/Enumerations/ZoneState';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { ZoneContentTypes } from '../../../enumerations/zone-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
const isContentSupported = (content: Content, pluginType: IGuid): boolean => {
    return !!(
        content?.type.equals(ZoneContentTypes.Zone) &&
        (content.contextContent === null || (content.contextContent.type.equals(ZoneContentTypes.Event) && pluginType.equals(PluginTypes.Widget)))
    );
};

@InternalContentPluginDescriptor({
    type: ZoneActionComponent,
    pluginTypes: [PluginTypes.Widget, PluginTypes.MapPopupCompact],
    exposure: { id: ZoneActionComponent.pluginId, priority: 0 },
    isContentSupported,
})
@Component({
    selector: 'app-zone-action',
    templateUrl: './zone-action.component.html',
    styleUrls: ['./zone-action.component.scss'],
})
export class ZoneActionComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('0AD785B0-130A-44F0-BCDE-F6A542C9A950');
    public isLoading = true;

    public isArmed = false;
    public inputState = '';
    public content?: Content;
    public dataContext?: PluginContext;

    private entityCacheTask: IEntityCacheTask;

    private monitoredFields = [ZoneEntityFields.zoneStateField, ZoneEntityFields.armingStateField];

    constructor(securityCenterClientService: SecurityCenterClientService, private translateService: TranslateService, trackingService: TrackingService) {
        super(trackingService);

        this.entityCacheTask = securityCenterClientService?.scClient?.buildEntityCache(this.monitoredFields);
    }

    public async ngOnInit() {
        super.ngOnInit();
        await this.initZone();

        this.isLoading = false;
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.entityCacheTask?.dispose().fireAndForget();
    }

    public setDataContext(context: ContentService): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    private async initZone() {
        if (this.entityCacheTask && this.content) {
            const zone = await this.entityCacheTask.getEntityAsync<ZoneEntity, IZoneEntity>(ZoneEntity, SafeGuid.parse(this.content.source));
            if (zone) {
                await this.entityCacheTask.detectFieldChangeAsync(zone, () => zone.armingState, ((_: IZoneEntity, newValue: string, __: string) => {
                    this.isArmed = newValue === ZoneArmingState.Armed;
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<string>);

                await this.entityCacheTask.detectFieldChangeAsync(zone, () => zone.zoneState, ((_: IZoneEntity, newValue: string, __: string) => {
                    this.updateZoneState(newValue);
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<string>);

                this.isArmed = zone.armingState === ZoneArmingState.Armed;
                this.updateZoneState(zone.zoneState);
            }
        }
    }

    private updateZoneState(state: string) {
        switch (state) {
            case ZoneState.Active:
                this.inputState = this.translateService.instant('STE_LABEL_ZONE_STATE_ACTIVE') as string;
                break;
            case ZoneState.Normal:
                this.inputState = this.translateService.instant('STE_LABEL_ZONE_STATE_NORMAL') as string;
                break;
            case ZoneState.Trouble:
                this.inputState = this.translateService.instant('STE_LABEL_ZONE_STATE_TROUBLE') as string;
                break;
            default:
                this.inputState = this.translateService.instant('STE_LABEL_ZONE_STATE_UNKNOWN') as string;
        }
    }
}
