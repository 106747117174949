// Autogenerated: v2/help/Entities/ApiFile?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:06 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class FileEntityFields extends EntityFields {
    public static cacheGuidField = 'CacheGuid';
    public static fileCategoryField = 'FileCategory';
    public static xmlInfoField = 'XmlInfo';
}

// #endregion Fields

export interface IFileEntity extends IEntity {
    // #region REST Properties

    cacheGuid: IGuid;

    fileCategory: string;

    xmlInfo: string;

    // #endregion REST Properties

}

