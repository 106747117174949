// Autogenerated: v2/help/Entities/ApiLprUnit?codegen=ts&InterfaceOnly=True&workflow=False on 2021-05-11 9:36:09 AM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity } from './IEntity';
import { IUnitEntity, UnitEntityFields } from './IUnitEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class LprUnitEntityFields extends UnitEntityFields {
    public static extendedInfoField = 'ExtendedInfo';
    public static lprUnitTypeField = 'LprUnitType';
}

// #endregion Fields

export interface ILprUnitEntity extends IUnitEntity {
    // #region REST Properties

    readonly extendedInfo: string;

    readonly lprUnitType: string;

    // #endregion REST Properties

}

