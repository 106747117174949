import { ITransport, ITransportBuilder } from './ITransport';
import { ILogger } from '../utils/logger';
import { IEventDispatcher } from './eventHubDispatcher';
import { ISocket, AutoReconnectSocket } from '../players/WebSocket/AutoReconnectSocket';
import { IMessageParser, WebSocketMessageTypeV2, MessageParser } from '../players/WebSocket/MessageParser';
import { InFlightQueries, InFlightQuery } from './InFlightQueries';
import { LiteEvent } from '../utils/liteEvents';
import { IWebSocketRequest, IWebSocketResponse, CreateStreamResponse, UpdateStreamResponse, SuccessResponse, ErrorResponse } from '../players/WebSocket/Messages';
import { WebSocketBuilder } from '../players/WebSocket/WebSocketBuilder';
import { ErrorDetails, StreamingStatus, SegmentInfo } from './eventHub';
import { ErrorCode } from '../enums';
import { Utils } from '../utils/Utils';

export class WebSocketTransportBuilder implements ITransportBuilder {
  private readonly m_logger: ILogger;

  private readonly m_url: string;

  public constructor(logger: ILogger, url: string) {
    this.m_logger = logger;
    this.m_url = url;
  }

  public async build(eventDispatcher: IEventDispatcher): Promise<ITransport> {
    const autoReconnect = await AutoReconnectSocket.build(this.m_logger, new WebSocketBuilder(this.m_logger, this.m_url));
    return new WebSocketTransportV2(this.m_logger, eventDispatcher, autoReconnect, new MessageParser());
  }
}

export class WebSocketTransportV2 implements ITransport {
  private readonly m_logger: ILogger;

  private readonly m_dispatcher: IEventDispatcher;

  private readonly m_socket: ISocket;

  private readonly m_messageParser: IMessageParser;

  private readonly m_inFlightQueries = new InFlightQueries();

  private readonly m_connectionReestablishedEvent = new LiteEvent<void>();

  private readonly m_connectionLostEvent = new LiteEvent<void>();

  public get ConnectionReestablished() {
    return this.m_connectionReestablishedEvent.expose();
  }

  public get ConnectionLost() {
    return this.m_connectionLostEvent.expose();
  }

  public constructor(marmotLogger: ILogger, dispatcher: IEventDispatcher, socket: ISocket, messageParser: IMessageParser) {
    this.m_logger = marmotLogger.subLogger('WebSocketTransportV2');
    this.m_dispatcher = dispatcher;

    this.m_socket = socket;
    this.m_socket.onmessage = this.onMessage.bind(this);
    this.m_socket.ConnectionLost.register(this.onConnectionLost);
    this.m_socket.ConnectionReestablished.register(this.onConnectionReestablished);
    this.m_messageParser = messageParser;
  }

  public async stop() {
    this.unbindEvents();
    await this.m_socket.dispose();
    this.m_inFlightQueries.failAll('Connection closed');
  }

  public async send(message: IWebSocketRequest): Promise<IWebSocketResponse> {
    let p: Promise<IWebSocketResponse>;

    if (message.expectResponse) {
      p = new Promise<IWebSocketResponse>((win, fail) => {
        this.m_inFlightQueries.add(message.channel, new InFlightQuery(win, fail, message));
        this.m_socket.send(message.serialize());
      });
    } else {
      p = Promise.resolve<IWebSocketResponse>({ channel: message.channel });
      this.m_socket.send(message.serialize());
    }

    return p;
  }

  private unbindEvents() {
    this.m_socket.onmessage = null;
    this.m_socket.ConnectionLost.unregister(this.onConnectionLost);
    this.m_socket.ConnectionReestablished.unregister(this.onConnectionReestablished);
  }

  private onMessage(arrayBuffer: ArrayBuffer) {
    const payload = this.m_messageParser.parse(arrayBuffer);
    if (payload === undefined) {
      return;
    }

    // Intercept responses to complete requests
    switch (payload?.MessageType) {
      case WebSocketMessageTypeV2.UpdateStreamResponse:
        const updateStreamResponse: UpdateStreamResponse = payload.Event;
        this.m_inFlightQueries.complete(updateStreamResponse.channel, updateStreamResponse);
        this.receiveEvent(payload);
        break;
      case WebSocketMessageTypeV2.SuccessResponse:
        const successResponse: SuccessResponse = payload.Event;
        this.m_inFlightQueries.complete(successResponse.channel, successResponse);
        break;
      case WebSocketMessageTypeV2.ErrorResponse:
        const errorResponse: ErrorResponse = payload.Event;
        this.m_logger.error?.trace('Received an error response:', errorResponse.body.ErrorMessage);
        this.m_inFlightQueries.fail(errorResponse.channel, errorResponse.body.ErrorMessage);
        break;
      case WebSocketMessageTypeV2.CreateStreamResponse:
        const createStreamResponse: CreateStreamResponse = payload.Event;
        this.m_inFlightQueries.complete(createStreamResponse.channel, createStreamResponse);
        break;
      default: // Code path for events
        this.receiveEvent(payload);
    }
  }

  private receiveEvent(payload: WebSocketPayloadV2) {
    switch (payload?.MessageType) {
      case WebSocketMessageTypeV2.Buffering:
        this.m_dispatcher.receiveBufferingProgress(payload.ChannelId.toString(), payload.Event);
        break;
      case WebSocketMessageTypeV2.Error:
        const errorDetails: ErrorDetails = payload.Event;
        if (errorDetails.ErrorCode === undefined) {
          this.m_logger.error?.trace('Failed to deserialize an error detail event:', payload);
          this.m_dispatcher.receiveErrorDetails(payload.ChannelId.toString(), new ErrorDetails(ErrorCode.Unknown, 'WebPlayer parsing error'));
          return;
        }

        this.m_dispatcher.receiveErrorDetails(payload.ChannelId.toString(), errorDetails);
        break;
      case WebSocketMessageTypeV2.Jpeg:
        this.m_dispatcher.receiveJpeg(payload.ChannelId.toString(), payload.Event, payload.Payload!);
        break;
      case WebSocketMessageTypeV2.PlayerState:
        this.m_dispatcher.receivePlayerState(payload.ChannelId.toString(), payload.Event);
        break;
      case WebSocketMessageTypeV2.PlaySpeed:
        this.m_dispatcher.receivePlaySpeedChange(payload.ChannelId.toString(), payload.Event);
        break;
      case WebSocketMessageTypeV2.Segment:
        this.m_dispatcher.receiveSegment(payload.ChannelId.toString(), new SegmentInfo(payload.Event), payload.Payload!);
        break;
      case WebSocketMessageTypeV2.StreamingStatus:
        const streamingStatus: StreamingStatus = payload.Event;
        if (streamingStatus.State === undefined) {
          this.m_logger.error?.trace('Failed to deserialize a streaming connection status event: ', payload);
          return;
        }
        this.m_dispatcher.receiveStreamingConnectionStatus(payload.ChannelId.toString(), streamingStatus);
        break;
      case WebSocketMessageTypeV2.TimelineUpdate:
        this.m_dispatcher.receiveTimelineContent(payload.ChannelId.toString(), payload.Event);
        break;
      case WebSocketMessageTypeV2.UpdateStreamResponse:
        this.m_dispatcher.receiveAudioAvailable(payload.ChannelId.toString(), (<UpdateStreamResponse>payload.Event).body.AudioAvailable);
        break;
      default:
        this.m_logger.error?.trace('Unsupported payload from Web Socket', payload);
    }
  }

  private readonly onConnectionLost = () => {
    this.m_inFlightQueries.failAll('Connection lost');
    this.m_connectionLostEvent.trigger();
  }

  private readonly onConnectionReestablished = () => {
    this.m_logger.info?.trace('Connection reestablished');
    this.m_connectionReestablishedEvent.trigger();
  }

  public debugStatus(indent: number): string {
    return 'WebSocketTransportV2' + Utils.indentNewLine(indent) +
      this.m_inFlightQueries.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      this.m_socket.debugStatus(indent + Utils.Indentation);
  }
}

export class WebSocketPayloadV2 {
  public readonly MessageType: WebSocketMessageTypeV2;
  public readonly ChannelId: number;
  public readonly Event: any;
  public readonly Payload?: Int8Array

  constructor(msgType: WebSocketMessageTypeV2, channelId: number, event?: any, payload?: Int8Array) {
    this.MessageType = msgType;
    this.ChannelId = channelId;
    this.Event = event;
    this.Payload = payload;
  }
}
