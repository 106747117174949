// Autogenerated: v2/help/Entities/ApiArchiverRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { RoleEntity } from '../Roles/RoleEntity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IArchiverRoleEntity, IVideoQualityPreset, INTPSetting, IExtension, IAdvanceSetting, ArchiverRoleEntityFields } from '../../Interface/IArchiverRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { IEncryptionCertificate, IRecordingMode } from '../../Interface/ICameraEntity';
import { EncryptionCertificate, RecordingMode } from './CameraEntity';
import { FieldObjectWithRelations } from '../../../Helpers/FieldObjectWithRelations';

// #region Inner classes

export class VideoQualityPreset extends FieldObject implements IVideoQualityPreset {
    // FrameRate
    public get frameRate(): number {
        return this.getField<number>('FrameRate');
    }
    public set frameRate(value: number) {
        this.setField<number>('FrameRate', value);
    }
    // Resolution
    public get resolution(): string {
        return this.getField<string>('Resolution');
    }
    public set resolution(value: string) {
        this.setField<string>('Resolution', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('FrameRate', 0);
        this.setField<string>('Resolution', '');
    }
}

export class NTPSetting extends FieldObject implements INTPSetting {
    // PollTimeout
    public get pollTimeout(): number | null {
        return this.getNullableField<number>('PollTimeout');
    }
    public set pollTimeout(value: number | null) {
        this.setNullableField<number>('PollTimeout', value);
    }
    // Port
    public get port(): number | null {
        return this.getNullableField<number>('Port');
    }
    public set port(value: number | null) {
        this.setNullableField<number>('Port', value);
    }
    // Server
    public get server(): string {
        return this.getField<string>('Server');
    }
    public set server(value: string) {
        this.setField<string>('Server', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('PollTimeout', 0);
        this.setField<number>('Port', 0);
        this.setField<string>('Server', '');
    }
}

export class Extension extends FieldObjectWithRelations implements IExtension {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // ExtensionType
    public get extensionType(): IGuid {
        return this.getFieldGuid('ExtensionType');
    }
    public set extensionType(value: IGuid) {
        this.setFieldGuid('ExtensionType', value);
    }
    // RefuseBasicAuthentication
    public get refuseBasicAuthentication(): boolean {
        return this.getField<boolean>('RefuseBasicAuthentication');
    }
    public set refuseBasicAuthentication(value: boolean) {
        this.setField<boolean>('RefuseBasicAuthentication', value);
    }

    public async getAdvancedSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAdvanceSetting> | null> {
        return this.getFieldObjectRelationAsync<AdvanceSetting, IAdvanceSetting>(AdvanceSetting, 'Extensions/' + this.id + '/AdvancedSettings', 'Name', false, true, null, transaction);
    }


    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setFieldGuid('ExtensionType', SafeGuid.EMPTY);
        this.setField<boolean>('RefuseBasicAuthentication', false);
    }
}

export class AdvanceSetting extends FieldObject implements IAdvanceSetting {
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Value
    public get value(): string {
        return this.getField<string>('Value');
    }
    public set value(value: string) {
        this.setField<string>('Value', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Name', '');
        this.setField<string>('Value', '');
    }
}

// #endregion Inner classes

export class ArchiverRoleEntity extends RoleEntity implements IArchiverRoleEntity {
    // #region REST Properties

    // ArchiveBackupMaxBandwidth
    public get archiveBackupMaxBandwidth(): number {
        return this.getField<number>(ArchiverRoleEntityFields.archiveBackupMaxBandwidthField);
    }
    public set archiveBackupMaxBandwidth(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.archiveBackupMaxBandwidthField, value);
    }

    // ArchiveFileSizeEnabled
    public get archiveFileSizeEnabled(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.archiveFileSizeEnabledField);
    }
    public set archiveFileSizeEnabled(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.archiveFileSizeEnabledField, value);
    }

    // ArchivingLoadThreshold
    public get archivingLoadThreshold(): number {
        return this.getField<number>(ArchiverRoleEntityFields.archivingLoadThresholdField);
    }
    public set archivingLoadThreshold(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.archivingLoadThresholdField, value);
    }

    // AudioRecording
    public get audioRecording(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.audioRecordingField);
    }
    public set audioRecording(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.audioRecordingField, value);
    }

    // AutodiscoverArea
    public get autodiscoverArea(): IGuid {
        return this.getFieldGuid(ArchiverRoleEntityFields.autodiscoverAreaField);
    }
    public set autodiscoverArea(value: IGuid) {
        this.setFieldGuid(ArchiverRoleEntityFields.autodiscoverAreaField, value);
    }

    // BackupRetentionPeriod
    public get backupRetentionPeriod(): number {
        return this.getField<number>(ArchiverRoleEntityFields.backupRetentionPeriodField);
    }
    public set backupRetentionPeriod(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.backupRetentionPeriodField, value);
    }

    // DefaultManualRecordingTime
    public get defaultManualRecordingTime(): number {
        return this.getField<number>(ArchiverRoleEntityFields.defaultManualRecordingTimeField);
    }
    public set defaultManualRecordingTime(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.defaultManualRecordingTimeField, value);
    }

    // DeleteOldFilesOnDiskFull
    public get deleteOldFilesOnDiskFull(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.deleteOldFilesOnDiskFullField);
    }
    public set deleteOldFilesOnDiskFull(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.deleteOldFilesOnDiskFullField, value);
    }

    // EnableArchiveConsolidation
    public get enableArchiveConsolidation(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.enableArchiveConsolidationField);
    }
    public set enableArchiveConsolidation(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.enableArchiveConsolidationField, value);
    }

    // EnableAutomaticCleanup
    public get enableAutomaticCleanup(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.enableAutomaticCleanupField);
    }
    public set enableAutomaticCleanup(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.enableAutomaticCleanupField, value);
    }

    // EnableCloudArchives
    public get enableCloudArchives(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.enableCloudArchivesField);
    }
    public set enableCloudArchives(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.enableCloudArchivesField, value);
    }

    // EnableEdgePlaybackRequests
    public get enableEdgePlaybackRequests(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.enableEdgePlaybackRequestsField);
    }
    public set enableEdgePlaybackRequests(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.enableEdgePlaybackRequestsField, value);
    }

    // EnableThumbnailsRequests
    public get enableThumbnailsRequests(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.enableThumbnailsRequestsField);
    }
    public set enableThumbnailsRequests(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.enableThumbnailsRequestsField, value);
    }

    // EncryptionEnabled
    public get encryptionEnabled(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.encryptionEnabledField);
    }
    public set encryptionEnabled(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.encryptionEnabledField, value);
    }

    // EncryptionType
    public get encryptionType(): string {
        return this.getField<string>(ArchiverRoleEntityFields.encryptionTypeField);
    }
    public set encryptionType(value: string) {
        this.setField<string>(ArchiverRoleEntityFields.encryptionTypeField, value);
    }

    // IsVideoWatermarking
    public get isVideoWatermarking(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.isVideoWatermarkingField);
    }
    public set isVideoWatermarking(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.isVideoWatermarkingField, value);
    }

    // MaximumSimultaneousDownload
    public get maximumSimultaneousDownload(): number {
        return this.getField<number>(ArchiverRoleEntityFields.maximumSimultaneousDownloadField);
    }
    public set maximumSimultaneousDownload(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.maximumSimultaneousDownloadField, value);
    }

    // MaximumVideoLenght
    public get maximumVideoLenght(): number {
        return this.getField<number>(ArchiverRoleEntityFields.maximumVideoLenghtField);
    }
    public set maximumVideoLenght(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.maximumVideoLenghtField, value);
    }

    // MaximumVideoSize
    public get maximumVideoSize(): number {
        return this.getField<number>(ArchiverRoleEntityFields.maximumVideoSizeField);
    }
    public set maximumVideoSize(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.maximumVideoSizeField, value);
    }

    // MetadataRecording
    public get metadataRecording(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.metadataRecordingField);
    }
    public set metadataRecording(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.metadataRecordingField, value);
    }

    // PostEventRecordingTime
    public get postEventRecordingTime(): number {
        return this.getField<number>(ArchiverRoleEntityFields.postEventRecordingTimeField);
    }
    public set postEventRecordingTime(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.postEventRecordingTimeField, value);
    }

    // PreEventRecordingTime
    public get preEventRecordingTime(): number {
        return this.getField<number>(ArchiverRoleEntityFields.preEventRecordingTimeField);
    }
    public set preEventRecordingTime(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.preEventRecordingTimeField, value);
    }

    // PrimaryArchiverId
    public get primaryArchiverId(): string {
        return this.getField<string>(ArchiverRoleEntityFields.primaryArchiverIdField);
    }

    // ProtectedVideoThreshold
    public get protectedVideoThreshold(): number {
        return this.getField<number>(ArchiverRoleEntityFields.protectedVideoThresholdField);
    }
    public set protectedVideoThreshold(value: number) {
        this.setField<number>(ArchiverRoleEntityFields.protectedVideoThresholdField, value);
    }

    // RedundantArchiving
    public get redundantArchiving(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.redundantArchivingField);
    }
    public set redundantArchiving(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.redundantArchivingField, value);
    }

    // TelnetEnabled
    public get telnetEnabled(): boolean {
        return this.getField<boolean>(ArchiverRoleEntityFields.telnetEnabledField);
    }
    public set telnetEnabled(value: boolean) {
        this.setField<boolean>(ArchiverRoleEntityFields.telnetEnabledField, value);
    }

    // TelnetPassword
    public get telnetPassword(): string {
        return this.getField<string>(ArchiverRoleEntityFields.telnetPasswordField);
    }
    public set telnetPassword(value: string) {
        this.setField<string>(ArchiverRoleEntityFields.telnetPasswordField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAdvancedSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAdvanceSetting> | null> {
        return this.getFieldObjectRelationAsync<AdvanceSetting, IAdvanceSetting>(AdvanceSetting, 'AdvancedSettings', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async getAgentsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Agents', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cameras', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getEncryptionCertificatesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEncryptionCertificate> | null> {
        return this.getFieldObjectRelationAsync<EncryptionCertificate, IEncryptionCertificate>(EncryptionCertificate, 'EncryptionCertificates', 'Id', false, false, ValidFlags.None, transaction);
    }

    public async getExtensionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IExtension> | null> {
        return this.getFieldObjectRelationAsync<Extension, IExtension>(Extension, 'Extensions', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async getNTPSettingsAsync(transaction?: ITransaction | null): Promise<INTPSetting | null> {
        return await this.getDataRelationAsync<NTPSetting>(NTPSetting, 'NTPSettings', true, ValidFlags.None, transaction);
    }

    public async getRecordingModesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRecordingMode> | null> {
        return this.getFieldObjectRelationAsync<RecordingMode, IRecordingMode>(RecordingMode, 'RecordingModes', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async getVideoQualityPresetsAsync(transaction?: ITransaction | null): Promise<IVideoQualityPreset | null> {
        return await this.getDataRelationAsync<VideoQualityPreset>(VideoQualityPreset, 'VideoQualityPresets', true, ValidFlags.None, transaction);
    }

    public async getVideoUnitsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('VideoUnits', 'Id', true, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

