<div #parent class="parent" [class.popup]="isInPopup" data-cy="videoPlayer" (dblclick)="onMouseDoubleClick()">
    <div
        #freelook
        class="freelook"
        tabindex="0"
        *ngIf="supportPtz"
        [hidden]="!displayVideo || isDigitalZoomEnabled"
        [appMouseCapture]="freeLookMousecapture"
        [style.cursor]="freeLookIsGripped ? 'all-scroll' : 'default'"
        (mouseenter)="onMouseEnter($event)"
        (mouseleave)="onMouseLeave($event)"
        (pointerdown)="onPointerDown($event)"
        (pointerup)="onPointerUp($event)"
        (pointermove)="onPointerMove($event)"
        (focusin)="onGotFocus()"
        (focusout)="onLostFocus()"
    >
        <span class="ptzdot" *ngIf="isMouseOver" [style.opacity]="dotOpacity"></span>
    </div>
    <div #div class="video-player h-100 w-100" [hidden]="!displayVideo"></div>
    <div class="noVideoOverlay" [style.background]="stateBackgroundColor">
        <div class="opaqueBackground" [style.display]="displayedState ? 'inherit' : 'none'">
            <label class="playerState h-100 w-100">
                {{ displayedState }}
            </label>
        </div>
    </div>
</div>
