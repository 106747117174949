import { Radian } from '../utils/Radian';
import { Resolution } from '../utils/Resolution';
import { ClipSpaceVec2, Vec2 } from '../utils/Vec2';
import { DewarpingParameters } from './DewarpingParameters';
import { ZoomFactor } from './ZoomFactor';

export class ImageGeometry {
  private readonly m_dewarpingParameters: DewarpingParameters

  private get Fov(): Radian {
    return new Radian(this.m_dewarpingParameters.FovInRadians);
  }

  private get StreamResolution(): Resolution {
    return this.m_dewarpingParameters.StreamResolution;
  }

  constructor(dewarpingParameters: DewarpingParameters) {
    this.m_dewarpingParameters = dewarpingParameters;
  }

  public rotateAroundCircle(xy: ClipSpaceVec2, pan: Radian): ClipSpaceVec2 {
    //Since circle might not be centered, we need to go into screenspace. We can't stay in clipSpace and just circle around the center of the whole image
    return xy.toScreenSpace(this.StreamResolution)
      .minus(this.m_dewarpingParameters.CircleCenterInPixels)
      .toPolar()
      .rotate(pan)
      .toCartesian()
      .plus(this.m_dewarpingParameters.CircleCenterInPixels)
      .toClipSpace(this.StreamResolution);
  }

  public tiltInCircle(xy: ClipSpaceVec2, tilt: Radian): ClipSpaceVec2 {
    const tiltInPixel = (tilt.InRadian / (this.Fov.InRadian / 2)) * this.m_dewarpingParameters.RadiusInPixels;
    return xy.toScreenSpace(this.StreamResolution)
      .minus(this.m_dewarpingParameters.CircleCenterInPixels)
      .toPolar()
      .tilt(tiltInPixel)
      .toCartesian()
      .plus(this.m_dewarpingParameters.CircleCenterInPixels)
      .toClipSpace(this.StreamResolution);
  }

  public panFrom(imageXY: ClipSpaceVec2): Radian {
    //todo: review this in other mode than ceiling
    return imageXY.toScreenSpace(this.StreamResolution)
      .minus(this.m_dewarpingParameters.CircleCenterInPixels)
      .toPolar()
      .Angle;
  }

  public tiltFrom(xy: ClipSpaceVec2): Radian {
    //todo: review this in other mode than ceiling
    const radius = xy.toScreenSpace(this.StreamResolution)
      .minus(this.m_dewarpingParameters.CircleCenterInPixels)
      .toPolar()
      .Radius;

    return new Radian((radius / this.m_dewarpingParameters.RadiusInPixels) * (this.Fov.InRadian / 2));
  }

  public limitToWarpedCircle(imageXY: ClipSpaceVec2, zoom: ZoomFactor): [ClipSpaceVec2, ClipSpaceVec2] {
    //Limit dewarping plane to circle by computing the minimum angle at which the dewarping plane touches the limit of the dewarping sphere
    const angleMin = Math.atan((1.0 + this.m_dewarpingParameters.FitResolution.OffsetRatio.Y * 2.0) / zoom.Value);
    const onXYPlane = Math.cos(angleMin);

    const circleXY = this.imageToCircle(imageXY);

    const circleClipedXY = circleXY.clipLength(1.0); //this might seems superfluous as we will reclip later in this method but the intermediate manipulations will have errors/wraparounds if input is above 1.0
    const adjustedXY: ClipSpaceVec2 = this.m_dewarpingParameters.Projection.adjustXY(circleClipedXY);

    const adjustedAndCliped = adjustedXY.clipLength(onXYPlane);

    //For the user X/Y coords
    const unadjustedLimitedImg: ClipSpaceVec2 = this.circleToImage(this.m_dewarpingParameters.Projection.unadjustXY(adjustedAndCliped)).toClipSpace(this.m_dewarpingParameters.StreamResolution);

    return [adjustedAndCliped, unadjustedLimitedImg];
  }

  public circleToImage(circleCoordinates: ClipSpaceVec2): Vec2 {
    return circleCoordinates.toScreenSpace(this.m_dewarpingParameters.CircleResolution).plus(this.m_dewarpingParameters.Cropping);
  }

  public imageToCircle(imageCoordinates: ClipSpaceVec2): ClipSpaceVec2 {
    return this.imagePixelToCircle(imageCoordinates.toScreenSpace(this.StreamResolution));
  }

  private imagePixelToCircle(imagePixelCoordinates: Vec2): ClipSpaceVec2 {
    const circleCoordinates = imagePixelCoordinates.minus(this.m_dewarpingParameters.Cropping);
    return circleCoordinates.toClipSpace(this.m_dewarpingParameters.CircleResolution);
  }
}
