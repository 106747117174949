import { IWebPlayer } from './PublicWebPlayer';

declare class WeakRef<T extends object> {
  constructor(target?: T);
  deref(): T | undefined;
}

//Keeps weak references of all created PublicWebPlayer.
//Accessible in the console with gwp.players.players()
//to obtain the count of players:
//gwp.players.players().Count
//to obtain the status of player 42
//gwp.players.players()[42].debugStatus()
export class PlayersList {
  private static readonly m_isWeakRefDefined: boolean = typeof WeakRef !== 'undefined';

  public static readonly Instance: PlayersList = new PlayersList();

  private readonly m_players: WeakRef<IWebPlayer>[] = [];

  private get WeakRefIsDefined(): boolean {
    return PlayersList.m_isWeakRefDefined;
  }

  public get Count(): number {
    if (!this.WeakRefIsDefined) {
      console.warn('Weak reference not supported. Debug map is not available.');
      return 0;
    }

    let activeCount = this.m_players.filter((weakRef) => weakRef.deref() !== undefined).length;

    if (activeCount !== this.m_players.length) {
      this.cleanup();
      activeCount = this.m_players.filter((weakRef) => weakRef.deref() !== undefined).length;
    }

    if (this.m_players.length !== activeCount) {
      console.warn(`Unable to clean player weak references: ${this.m_players.length} player references vs ${activeCount} active references.`);
    }

    return this.m_players.length;
  }

  public players(): IWebPlayer[] {
    const players: IWebPlayer[] = [];
    if (!this.WeakRefIsDefined) {
      console.warn('Weak reference not supported. Debug map is not available.');
      return players;
    }

    this.m_players.forEach((weakRef) => {
      const maybeStillAlive = weakRef.deref();
      if (maybeStillAlive !== undefined) {
        players.push(maybeStillAlive);
      }
    });

    this.cleanup();

    return players;
  }

  public add(player: IWebPlayer) {
    if (!this.WeakRefIsDefined) {
      return;
    }

    this.m_players.push(new WeakRef(player));

    this.cleanup();
  }

  public remove(player: IWebPlayer) {
    if (!this.WeakRefIsDefined) {
      return;
    }

    const index = this.m_players.findIndex((weakRef) => weakRef.deref() === player);
    if (index > -1) {
      this.m_players.splice(index, 1);
    } else {
      console.warn(`Could not remove the player from the players list. List length is now ${this.m_players.length}`);
    }

    this.cleanup();
  }

  private cleanup() {
    const playersCopy: WeakRef<IWebPlayer>[] = [];

    this.m_players.forEach((weakRef) => playersCopy.push(weakRef));

    let cleanedUp = 0;
    playersCopy.forEach((weakRef) => {
      if (weakRef.deref() === undefined) {
        const index = this.m_players.indexOf(weakRef, 0);
        this.m_players.splice(index, 1);
        ++cleanedUp;
      }
    });

    if (cleanedUp !== 0) {
      console.warn(`${cleanedUp} gwp player(s) were not disposed.`);
    }
  }
}
