type ToErrorFunction = (message: any, originalError?: any) => Error;

/**
 * Utility function that assures whatever is passed to it will be converted to an Error if it's not already.
 *
 * If it's not already an instance of an error, it will create an error with the argument as the message if it's a string, has a message property that is a string or is an object with a toString() method.
 *
 * Accepts an original error as second parameter, if set, the message of the first argument (the string or the error's message) will be pre-prended to the original error's message.
 *
 * Returns a "Unknown error occured" as message of an error when it can't be converted.
 *
 * **Example**
 * ```typescript
 * try {
 *     throw new Error("original error");
 * }
 * catch (e: unknown) {
 *     // Will log "Error fetching the map: original error"
 *     console.error(toError('Error fetching the map', e));
 * }
 *
 * ```
 *
 * @param error The argument to return as an Error
 * @returns The Error
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const toError: ToErrorFunction = (messageOrError: any, originalError?: any): Error => {
    if (originalError) {
        if (originalError) {
            const error = convertToError(messageOrError);
            const outerError = convertToError(originalError);
            return new Error(`${error.message}: ${outerError.message}`);
        }
    }

    return convertToError(messageOrError);
};

const convertToError = (error?: any): Error => {
    if (!error) {
        return new Error('Unknown error occured');
    }

    /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    if (error instanceof Error) {
        return error;
    } else if (typeof error === 'string') {
        return new Error(error);
    } else if (typeof error.message === 'string') {
        return new Error((error as Record<'message', string>).message);
    } else if (typeof error.toString === 'function') {
        const str = error.toString() as unknown;
        if (typeof str === 'string') {
            return new Error(str);
        }
    }

    return new Error('Unknown error occured');
    /* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
};

export { toError };
