<gen-melted-modal
    id="DeleteCardholderModal"
    genTitle="{{ 'STE_TITLE_CARDHOLDER_DELETE' | translate }}"
    genDefaultActionText="{{ 'STE_ACTION_CARDHOLDER_DELETE' | translate }}"
    genAlternativeActionText="{{ 'STE_ACTION_CARDHOLDER_CANCEL_DELETION' | translate }}"
    [genOnDefaultAction]="onDeleteClicked"
    [genCanClose]="true"
    [genIsBlocking]="true"
    [genDestructiveAction]="modalAction.Default"
>
    <gen-melted-modal-body>
        <gen-label> {{ 'STE_MESSAGE_CONFIRM_CARDHOLDER_DELETION' | translate | stringFormat: [this.cardholderName] }}</gen-label>
    </gen-melted-modal-body>
</gen-melted-modal>
