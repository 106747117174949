// Autogenerated: v2/help/Entities/ApiUnit?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:09 PM
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { ITransaction } from '../Interface/ITransaction';
import { ILocatableEntity, LocatableEntityFields } from '../Interface/ILocatableEntity';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity } from './IEntity';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class UnitEntityFields extends LocatableEntityFields {
    public static canRebootField = 'CanReboot';
    public static certificateIdField = 'CertificateId';
    public static certificateSigningRequestCommonNameField = 'CertificateSigningRequestCommonName';
    public static extensionIdField = 'ExtensionId';
    public static firmwareVersionField = 'FirmwareVersion';
    public static iPAddressField = 'IPAddress';
    public static isUsingCertificateField = 'IsUsingCertificate';
    public static loginPolicyField = 'LoginPolicy';
    public static manufacturerField = 'Manufacturer';
    public static modelField = 'Model';
    public static passwordField = 'Password';
    public static physicalAddressField = 'PhysicalAddress';
    public static platformVersionField = 'PlatformVersion';
    public static secureCommandPortField = 'SecureCommandPort';
    public static serialNumberField = 'SerialNumber';
    public static supportOutputDeviceStatesField = 'SupportOutputDeviceStates';
    public static supportsCertificateManagementField = 'SupportsCertificateManagement';
    public static supportServerControlledFirmwareUpgradeField = 'SupportServerControlledFirmwareUpgrade';
    public static supportsPasswordChangeField = 'SupportsPasswordChange';
    public static timeZoneField = 'TimeZone';
    public static usernameField = 'Username';
}

// #endregion Fields

export interface IUnitEntity extends ILocatableEntity {
    // #region REST Properties

    readonly canReboot: boolean;

    certificateId: IGuid;

    certificateSigningRequestCommonName: string;

    extensionId: IGuid;

    firmwareVersion: string;

    iPAddress: string;

    readonly isUsingCertificate: boolean;

    loginPolicy: string;

    manufacturer: string;

    model: string;

    password: string;

    readonly physicalAddress: string;

    platformVersion: string;

    secureCommandPort: number;

    serialNumber: string;

    readonly supportOutputDeviceStates: boolean;

    supportsCertificateManagement: boolean;

    supportServerControlledFirmwareUpgrade: boolean;

    supportsPasswordChange: boolean;

    readonly timeZone: string;

    username: string;

    // #endregion REST Properties

    // #region REST Methods

    getDevicesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    moveUnitAsync(id: IGuid, transaction?: ITransaction): Promise<IRestResponse | null>;
    rebootAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getUnitOnlyDevicesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

