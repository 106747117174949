import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { LabeledFormControl } from '@shared/forms/LabeledFormControl';

const addInvalidControlErrors = (invalidControl: AbstractControl, invalidControls: AbstractControl[]) => {
    if (invalidControl instanceof UntypedFormGroup || invalidControl instanceof UntypedFormArray) {
        // Iterate through invalid controls in the group or array and add its control in errors to the errors array.
        for (const innerInvalidControl of Object.values(invalidControl.controls).filter((innerControl) => innerControl.invalid)) {
            addInvalidControlErrors(innerInvalidControl, invalidControls);
        }
    } else {
        invalidControls.push(invalidControl);
    }

    return invalidControls;
};

/**
 * Returns the invalid controls in the form if there is any.
 */
export const getInvalidControls = <TControls extends Record<string, AbstractControl>>(form: FormGroup<TControls>): (LabeledFormControl | AbstractControl)[] => {
    return Object.values(form.controls)
        .filter((control) => control.invalid)
        .reduce((invalidControls: AbstractControl[], currentInvalidControl: AbstractControl) => addInvalidControlErrors(currentInvalidControl, invalidControls), []);
};
