import {
    CreateStreamResponse,
    ErrorCode,
    ErrorDetails,
    ErrorResponse,
    IEventDispatcher,
    ITransport,
    ITransportBuilder,
    IWebSocketRequest,
    IWebSocketResponse,
    SegmentInfo,
    StreamingStatus,
    SuccessResponse,
    UpdateStreamResponse,
} from './Marmot/gwp';
import { IMessageParser, MessageParser, WebSocketMessageTypeV2 } from './Marmot/players/WebSocket/MessageParser';
import { InFlightQueries, InFlightQuery } from './Marmot/services/InFlightQueries';
import { WebSocketPayloadV2 } from './Marmot/services/WebSocketTransportV2';
import { ILiteEvent, LiteEvent } from './Marmot/utils/liteEvents';
import { AutoReconnectSocket, ISocket } from './Marmot/players/WebSocket/AutoReconnectSocket';
import { WebSocketBuilder } from './Marmot/players/WebSocket/WebSocketBuilder';
import { ILogger, Logger } from './Marmot/utils/logger';
import { WebAppClient } from 'WebClient/WebAppClient';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { Deserializer } from './Marmot/utils/Deserializer';
import { ExportProgressEvent } from './export';
import { TimelinePartEvent } from './Timeline/timelinePartEvent';
import { SafeGuid } from 'safeguid';

export class WebAppTransport implements ITransport {
    // #region Fields

    private readonly _logger: ILogger;
    private readonly _client: WebAppClient;
    private readonly _messageParser: IMessageParser;
    private _dispatcher!: IEventDispatcher;
    private readonly _inFlightQueries = new InFlightQueries();
    private readonly _connectionReestablishedEvent = new LiteEvent<void>();
    private readonly _connectionLostEvent = new LiteEvent<void>();
    private _socket!: ISocket;
    private _sessionId = SafeGuid.newGuid().toString();
    private _isWebSocket = false;
    private _isOpen = false;
    private _currentPacketView: DataView | null = null;
    private _currentPacket: Uint8Array | null = null;
    private _currentPacketOffSet = 0;
    private _mergeNextBuffer = false;
    private _exportHandler!: LiteEvent<ExportProgressEvent>;
    private _timelinePartHandler!: LiteEvent<TimelinePartEvent>;

    // #endregion

    // #region Properties

    public get ConnectionReestablished(): ILiteEvent<void> {
        return this._connectionReestablishedEvent.expose();
    }

    public get ConnectionLost(): ILiteEvent<void> {
        return this._connectionLostEvent.expose();
    }

    // #endregion

    // #region Events

    // #endregion

    // #region Constructor

    constructor(marmotLogger: ILogger, client: WebAppClient, messageParser: IMessageParser) {
        this._messageParser = messageParser;
        this._client = client;
        this._logger = marmotLogger;
        this._isWebSocket = this._client.isWebSocket;
    }

    // #endregion

    // #region Public methods

    public async start(eventDispatcher: IEventDispatcher): Promise<boolean> {
        this._dispatcher = eventDispatcher;
        this._isOpen = true;
        let url = this._client.rest.restServerUrl + '/media';

        if (this._isWebSocket) {
            // Websocket
            url = url.replace('http', 'ws');
            const headers: any = {};
            for (const [key, value] of this._client.rest.additionalHeaders) {
                headers[key] = value;
            }
            headers['WebAppMediaSessionIdHeader'] = this._sessionId;
            headers['Authorization'] = this._client.rest.authorizationHeader;
            const headerString = JSON.stringify(headers);
            url = url + '?headers=' + headerString;
            this._socket = await AutoReconnectSocket.build(this._logger, new WebSocketBuilder(this._logger, url));
            this._socket.onmessage = this.onMessage.bind(this);
            this._socket.ConnectionLost.register(this.onConnectionLost);
            this._socket.ConnectionReestablished.register(this.onConnectionReestablished);
        } else {
            // SSE
            const requestHeaders = this.getHttpHeaders();
            url = url + '/v2/ws';

            fetch(url, {
                method: 'GET',
                headers: requestHeaders,
            })
                .then(async (response) => {
                    if (response.body == null) {
                        this.onConnectionLost();
                        return;
                    }
                    const reader = response.body.getReader();
                    do {
                        const result = await reader.read();
                        if (result.done) {
                            this.onConnectionLost();
                            return;
                        }
                        this.queueData(result.value);
                    } while (this._isOpen === true);
                })
                .catch((err) => {
                    this.onConnectionLost();
                });
        }
        return true;
    }

    public fetch(method: string, url: string, body: string | null): Promise<Response> {
        const requestHeaders = this.getHttpHeaders();
        const fullUrl = this._client.rest.restServerUrl + '/media/' + url;

        if (body != null) {
            return fetch(fullUrl, {
                method: method,
                headers: requestHeaders,
                body: body,
            });
        } else {
            return fetch(fullUrl, {
                method: method,
                headers: requestHeaders,
            });
        }
    }

    public getHttpHeaders(): HeadersInit {
        const requestHeaders: HeadersInit = new Headers();
        const headers = this._client.rest.additionalHeaders;
        for (const [key, value] of headers) {
            requestHeaders.set(key, value);
        }
        requestHeaders.set('WebAppMediaSessionIdHeader', this._sessionId);
        requestHeaders.set('Authorization', this._client.rest.authorizationHeader);
        return requestHeaders;
    }

    public async stop(): Promise<void> {
        this.unbindEvents();
        await this._socket.dispose();
        this._inFlightQueries.failAll('Connection closed');
    }

    public async send(message: IWebSocketRequest): Promise<IWebSocketResponse> {
        let p: Promise<IWebSocketResponse>;

        if (message.expectResponse) {
            p = new Promise<IWebSocketResponse>((win, fail) => {
                this._inFlightQueries.add(message.channel, new InFlightQuery(win, fail, message));
                this.internalSend(message.serialize());
            });
        } else {
            p = Promise.resolve<IWebSocketResponse>({ channel: message.channel });
            this.internalSend(message.serialize());
        }
        return p;
    }

    public async getTokenRetriever(camera: string): Promise<string> {
        const response = await this._client.rest.executeRequestAsync('GET', `/media/v2/token/${camera}?id=${this._sessionId}`, null);
        return response.body;
    }

    public setExportEventHandler(handler: LiteEvent<ExportProgressEvent>): void {
        this._exportHandler = handler;
    }

    public setTimelinePartEventHandler(handler: LiteEvent<TimelinePartEvent>): void {
        this._timelinePartHandler = handler;
    }

    // #endregion

    // #region Private methods

    private unbindEvents() {
        this._socket.onmessage = null;
        this._socket.ConnectionLost.unregister(this.onConnectionLost);
        this._socket.ConnectionReestablished.unregister(this.onConnectionReestablished);
    }

    private onMessage(arrayBuffer: ArrayBuffer) {
        const payload = this._messageParser.parse(arrayBuffer);
        if (payload === undefined) {
            // WebApp specific message
            const des = new Deserializer(arrayBuffer);
            const version = des.getUint8();
            const channel = des.getInt32();
            const msgType = des.getString();
            if (msgType === 'ExportProgress') {
                const str = des.getString();
                const exportProgressPayload = JSON.parse(str);

                if (this._exportHandler) {
                    const event = new ExportProgressEvent(exportProgressPayload.Status, exportProgressPayload.Progress);
                    this._exportHandler.trigger(event);
                }
            } else if (msgType === 'TimelinePart') {
                const str = des.getString();
                const timelinePartPayload = JSON.parse(str);

                if (this._timelinePartHandler) {
                    const event = new TimelinePartEvent(timelinePartPayload);
                    this._timelinePartHandler.trigger(event);
                }
            }
            return;
        }

        // Intercept responses to complete requests
        switch (payload?.MessageType) {
            case WebSocketMessageTypeV2.UpdateStreamResponse: {
                const updateStreamResponse: UpdateStreamResponse = payload.Event;
                this._inFlightQueries.complete(updateStreamResponse.channel, updateStreamResponse);
                this.receiveEvent(payload);
                break;
            }
            case WebSocketMessageTypeV2.SuccessResponse: {
                const successResponse: SuccessResponse = payload.Event;
                this._inFlightQueries.complete(successResponse.channel, successResponse);
                break;
            }
            case WebSocketMessageTypeV2.ErrorResponse: {
                const errorResponse: ErrorResponse = payload.Event;
                this._logger.error?.trace('Received an error response:', errorResponse.body.ErrorMessage);
                this._inFlightQueries.fail(errorResponse.channel, errorResponse.body.ErrorMessage);
                break;
            }
            case WebSocketMessageTypeV2.CreateStreamResponse: {
                const createStreamResponse: CreateStreamResponse = payload.Event;
                this._inFlightQueries.complete(createStreamResponse.channel, createStreamResponse);
                break;
            }
            default:
                // Code path for events
                this.receiveEvent(payload);
        }
    }

    private receiveEvent(payload: WebSocketPayloadV2) {
        switch (payload?.MessageType) {
            case WebSocketMessageTypeV2.Buffering: {
                this._dispatcher.receiveBufferingProgress(payload.ChannelId.toString(), payload.Event);
                break;
            }
            case WebSocketMessageTypeV2.Error: {
                const errorDetails: ErrorDetails = payload.Event;
                if (errorDetails.ErrorCode === undefined) {
                    this._logger.error?.trace('Failed to deserialize an error detail event: ' + payload);
                    this._dispatcher.receiveErrorDetails(payload.ChannelId.toString(), new ErrorDetails(ErrorCode.Unknown, 'WebPlayer parsing error'));
                    return;
                }

                this._dispatcher.receiveErrorDetails(payload.ChannelId.toString(), errorDetails);
                break;
            }
            case WebSocketMessageTypeV2.Jpeg: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this._dispatcher.receiveJpeg(payload.ChannelId.toString(), payload.Event, payload.Payload!);
                break;
            }
            case WebSocketMessageTypeV2.PlayerState: {
                this._dispatcher.receivePlayerState(payload.ChannelId.toString(), payload.Event);
                break;
            }
            case WebSocketMessageTypeV2.PlaySpeed: {
                this._dispatcher.receivePlaySpeedChange(payload.ChannelId.toString(), payload.Event);
                break;
            }
            case WebSocketMessageTypeV2.Segment: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this._dispatcher.receiveSegment(payload.ChannelId.toString(), new SegmentInfo(payload.Event), payload.Payload!);
                break;
            }
            case WebSocketMessageTypeV2.StreamingStatus: {
                const streamingStatus: StreamingStatus = payload.Event;
                if (streamingStatus.State === undefined) {
                    this._logger.error?.trace('Failed to deserialize a streaming connection status event: ' + payload);
                    return;
                }
                this._dispatcher.receiveStreamingConnectionStatus(payload.ChannelId.toString(), streamingStatus);
                break;
            }
            case WebSocketMessageTypeV2.TimelineUpdate: {
                this._dispatcher.receiveTimelineContent(payload.ChannelId.toString(), payload.Event);
                break;
            }
            case WebSocketMessageTypeV2.UpdateStreamResponse: {
                this._dispatcher.receiveAudioAvailable(payload.ChannelId.toString(), (<UpdateStreamResponse>payload.Event).body.AudioAvailable);
                break;
            }
            default:
                this._logger.error?.trace('Unsupported payload from Web Socket', payload);
        }
    }

    private onConnectionLost = () => {
        this._inFlightQueries.failAll('Connection lost');
        this._connectionLostEvent.trigger();
    };

    private onConnectionReestablished = () => {
        this._logger.info?.trace('Connection reestablished');
        this._connectionReestablishedEvent.trigger();
    };

    private queueData(data: Uint8Array) {
        if (this._mergeNextBuffer === false) {
            this._currentPacketOffSet = 0;
            this._currentPacketView = new DataView(data.buffer);
            this._currentPacket = data;
        } else {
            if (this._currentPacket == null) {
                throw Error('_currentPacket should not be null');
            }
            // merge with current
            // this._logger.info?.trace('Merging packet!');
            const mergedArray = new Uint8Array(this._currentPacket.length + data.length);
            mergedArray.set(this._currentPacket);
            mergedArray.set(data, this._currentPacket.length);
            this._currentPacketView = new DataView(mergedArray.buffer);
            this._currentPacket = mergedArray;
            this._mergeNextBuffer = false;
        }

        let parseAgain = true;
        while (parseAgain === true) {
            if (this._currentPacketView == null) {
                throw Error('_currentPacketView should not be null');
            }
            // Read the total length of the message
            if (this._currentPacketOffSet + 4 > this._currentPacket.byteLength) {
                // Not enought data, wait for next packet
                parseAgain = false;
                this._mergeNextBuffer = true;
            } else {
                const msgLength = this._currentPacketView.getInt32(this._currentPacketOffSet, true);
                const nextOffSet = this._currentPacketOffSet + 4 + msgLength;
                if (nextOffSet > this._currentPacket.byteLength) {
                    // Not enought data, wait for next packet
                    this._mergeNextBuffer = true;
                    parseAgain = false;
                } else {
                    // Parse current
                    this._currentPacketOffSet += 4;
                    // this._logger.info?.trace('Message of ' + msgLength.toString() + ' bytes');

                    const buffer = this._currentPacketView.buffer.slice(this._currentPacketOffSet, this._currentPacketOffSet + msgLength);
                    this.onMessage(buffer);
                    this._currentPacketOffSet += msgLength;
                    // End of current buffer ?
                    if (this._currentPacketOffSet === this._currentPacketView.byteLength) {
                        parseAgain = false;
                        this._currentPacketOffSet = 0;
                        this._currentPacketView = null;
                    }
                }
            }
        }
    }

    private internalSend(data: ArrayBuffer) {
        if (this._isWebSocket === true) {
            this._socket.send(data);
        } else {
            const payload = new FieldObject();

            payload.setField('payload', this.arrayBufferToBase64(data));
            this._client.rest.executeRequestAsync('POST', `/media/v2/${this._sessionId}/commands`, payload.toString());
        }
    }

    private arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => (binary += String.fromCharCode(b)));
        return window.btoa(binary);
    }

    // #endregion
}

export class WebAppTransportBuilder implements ITransportBuilder {
    private readonly _client: WebAppClient;
    private readonly _transport: WebAppTransport;

    constructor(client: WebAppClient) {
        this._client = client;
        const logger = new Logger(0, 'MediaGatewayService');
        this._transport = new WebAppTransport(logger, this._client, new MessageParser());
    }

    public async getTokenRetriever(camera: string): Promise<string> {
        return await this._transport.getTokenRetriever(camera);
    }

    public async build(eventDispatcher: IEventDispatcher): Promise<ITransport> {
        await this._transport.start(eventDispatcher);
        return this._transport;
    }

    public getTransport(): WebAppTransport {
        return this._transport;
    }
}
