export class Globals {
  public static authorizationHeader = 'Authorization';
  public static newAuthorizationHeader = 'NewAuthorization';
  public static allowImpersonateHeader = 'AllowImpersonate';
  public static impersonateServiceCredentialHeader = 'ImpersonateServiceCredential';
  public static impersonateAppHeader = 'ImpersonateApp';
  public static publicKeyHeader = 'PublicKey';
  public static dedicatedSessionIdHeader = 'DedicatedSessionId';
  public static sessionTimeoutHeader = 'SessionTimeout';
  public static restAppNameHeader = 'RestAppName';
  public static xHttpMethodOverrideHeader = 'X-HTTP-Method-Override';
}
