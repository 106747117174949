import { Constants } from '../utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const GenAlerts = Constants({
    OptionsConfirmRefreshAlert: 'D9DE7474-7A7A-40AA-BD76-D13BE80FED69',
});
