// Autogenerated: v2/help/Entities/ApiStream?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:08 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IFieldObject } from './IFieldObject';
import { IEntity, EntityFields } from './IEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class StreamEntityFields extends EntityFields {
    public static blockSizeField = 'BlockSize';
    public static cameraField = 'Camera';
    public static configuredConnectionField = 'ConfiguredConnection';
    public static deviceField = 'Device';
    public static isPresentField = 'IsPresent';
    public static legacyConfigurationsField = 'LegacyConfigurations';
    public static multicastAddressField = 'MulticastAddress';
    public static multicastPortField = 'MulticastPort';
    public static numberField = 'Number';
    public static streamTypeField = 'StreamType';
    public static supportedBlockSizeField = 'SupportedBlockSize';
    public static supportedConnectionField = 'SupportedConnection';
    public static uDPPortField = 'UDPPort';
}

// #endregion Fields

// #region Inner classes

export interface ICompression extends IFieldObject {
    id: IGuid;
    videoCompressionAlgorithm: string;
    frameRate: number | null;
    bitRate: number | null;
    imageQuality: number | null;
    keyframeInterval: number | null;
    resolutionX: number | null;
    resolutionY: number | null;
    recordingFrameInterval: string | null;
}

// #endregion Inner classes

export interface IStreamEntity extends IEntity {
    // #region REST Properties

    blockSize: number;

    readonly camera: IGuid;

    configuredConnection: string;

    device: IGuid;

    isPresent: boolean;

    legacyConfigurations: string;

    multicastAddress: string;

    multicastPort: number;

    numberValue: number;

    streamType: string;

    supportedBlockSize: string;

    supportedConnection: string;

    uDPPort: number;

    // #endregion REST Properties

    // #region REST Methods

    getCompressionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICompression> | null>;

    // #endregion REST Methods

}

