import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { GenAngularModule, GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { ModuleDescriptor } from '@modules/shared/interfaces/modules/public/module-public.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { DndModule } from 'ngx-drag-drop';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { SharedCommands } from '../shared/enumerations/shared-commands';
import { CommandsService, COMMANDS_SERVICE } from '../shared/interfaces/plugins/public/plugin-services-public.interface';
import { LanguageService } from '../shared/services/language/language.service';
import { SharedModule } from '../shared/shared.module';
import { TileCanvasComponent } from './components/tile-canvas/tile-canvas.component';
import { TileStateHeaderComponent } from './components/tile-state-header/tile-state-header.component';
import { TilesTaskComponent } from './components/tiles-task/tiles-task.component';
import { TileComponent } from './components/tiles/tile.component';
import { TileService } from './services/tile.service';
import { TileCanvasStateService } from './state/tile-canvas-state.service';
import { TileCanvasState } from './state/tile-canvas.state';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@NgModule({
    imports: [CommonModule, SharedModule, DndModule, NgxsModule.forFeature([TileCanvasState]), GenAngularModule],
    declarations: [TilesTaskComponent, TileComponent, TileCanvasComponent, TileStateHeaderComponent],
    providers: [GenModalService, TileCanvasStateService, TileService],
})
@ModuleDescriptor({
    type: TilesModule,
    inclusions: {
        globalPrivileges: [KnownPrivileges.changeTilePatternPrivilege],
    },
})
export class TilesModule {
    constructor(private translateService: TranslateService, languageService: LanguageService, @Inject(COMMANDS_SERVICE) private commandsService: CommandsService) {
        languageService.ready.pipe(untilDestroyed(this)).subscribe(() => {
            this.registerCommands();
        });
    }

    private registerCommands() {
        this.commandsService.registerCommands([
            {
                id: SharedCommands.ClearTiles,
                name: () => this.translateService?.instant('STE_BUTTON_CLEARALL') as string,
                icon: MeltedIcon.Trash,
                sectionName: () => this.translateService?.instant('STE_LABEL_TILES') as string,
                keyBinding: 'CTRL.Backspace',
            },
            {
                id: SharedCommands.ClearTile,
                name: () => this.translateService?.instant('STE_BUTTON_CLEAR') as string,
                icon: MeltedIcon.Trash,
                sectionName: () => this.translateService?.instant('STE_LABEL_TILES') as string,
                keyBinding: 'Backspace',
            },
            {
                id: SharedCommands.ExpandTile,
                name: () => this.translateService?.instant('STE_BUTTON_MAXIMIZETILE') as string,
                icon: MeltedIcon.MaximizeWindow,
                sectionName: () => this.translateService?.instant('STE_LABEL_TILES') as string,
                keyBinding: 'E',
            },
            {
                id: SharedCommands.MaximizeTileFullscreen,
                name: () => this.translateService?.instant('STE_ACTION_MAXIMIZETILEFULLSCREEN') as string,
                icon: MeltedIcon.Fullscreen,
                sectionName: () => this.translateService?.instant('STE_LABEL_TILES') as string,
                keyBinding: 'CTRL.Enter',
            },
            {
                id: SharedCommands.SelectPreviousTile,
                name: () => this.translateService?.instant('STE_ACTION_SELECTPREVIOUSTILE') as string,
                icon: MeltedIcon.AngleLeft,
                sectionName: () => this.translateService?.instant('STE_LABEL_TILES') as string,
                keyBinding: 'T',
            },
            {
                id: SharedCommands.SelectNextTile,
                name: () => this.translateService?.instant('STE_ACTION_SELECTNEXTTILE') as string,
                icon: MeltedIcon.AngleRight,
                sectionName: () => this.translateService?.instant('STE_LABEL_TILES') as string,
                keyBinding: 'Y',
            },
            {
                id: SharedCommands.ShowVideoDiagnostics,
                name: () => this.translateService?.instant('STE_ACTION_SHOWVIDEODIAG') as string,
                icon: MeltedIcon.LogFile,
                sectionName: () => this.translateService?.instant('STE_LABEL_TILES') as string,
                keyBinding: 'CTRL.SHIFT.A',
            },
        ]);
    }
}
