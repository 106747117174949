import { Component, OnInit } from '@angular/core';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { FilterTypes } from '@modules/shared/enumerations/filter-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { FilterContext, IFilterContent } from '@modules/shared/api/api';
import { SafeGuid } from 'safeguid';
import { FilterPluginBaseComponent } from './filter-plugin-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template: `<app-filter-enum
        #filter
        [descriptor]="descriptor"
        [filterContext]="filterContext"
        [values]="values"
        (lazyLoadEvent)="loadEnumValues($event)"
        (valueChange)="updateFilter($event)"
    ></app-filter-enum>`,
})
@InternalPluginDescriptor({
    type: FilterPluginEnumComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginEnumComponent.pluginId,
        subSection: FilterTypes.Enum,
    },
    isSupported: () => true,
})
export class FilterPluginEnumComponent extends FilterPluginBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('7FE4FCE8-6C56-4A14-8BDA-EC6AAB04D568');

    public filterContext?: FilterContext;
    public values?: { id: string; text: string }[];

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginEnumComponent.pluginId);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.descriptor.lazyLoad && !this.descriptor.providerId.isEmpty()) this.buildFilterContext();
    }

    public loadEnumValues(content: IFilterContent): void {
        const enumValuesFieldName = 'enumValues';

        /* eslint-disable @typescript-eslint/no-unsafe-member-access */
        const fieldValue = content.typeSpecificFields?.find((x) => enumValuesFieldName in x)?.[enumValuesFieldName] as unknown | undefined;
        if (Array.isArray(fieldValue) && fieldValue.length > 0 && typeof fieldValue[0].id === 'string' && typeof fieldValue[0].text === 'string') {
            this.values = fieldValue as { id: string; text: string }[];
        } else {
            delete this.values;
        }
        /* eslint-enable @typescript-eslint/no-unsafe-member-access */
    }

    private buildFilterContext(): void {
        const context = new FilterContext();
        context.filterProviderId = this.descriptor.providerId ?? SafeGuid.EMPTY;

        const typeSpecificFields: Record<string, string> = {};

        const enumTranslationDataTypeFieldName = 'enumTranslationDataType';
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const fieldValue = this.descriptor.typeSpecificFields?.find((x) => enumTranslationDataTypeFieldName in x)?.[enumTranslationDataTypeFieldName] as unknown | undefined;
        if (typeof fieldValue === 'string') {
            typeSpecificFields[enumTranslationDataTypeFieldName] = fieldValue;
        }
        context.typeSpecificFields = typeSpecificFields;

        this.filterContext = context;
    }
}
