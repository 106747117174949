import { Inject, Injectable } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { TranslateService } from '@ngx-translate/core';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { CardholderEntityFields, ICardholderEntity } from 'RestClient/Client/Interface/ICardholderEntity';
import { CardholderEntity } from 'RestClient/Client/Model/AccessControl/CardholderEntity';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { EntityCommandsUsageContext } from '../../shared/services/commands/commands-usage/entity-commands-usage-context';
import { CommandArgs, CommandDescriptor } from '../../shared/services/commands/command-provider-base';
import { EntityCommandContext } from '../../shared/services/commands/entity-command-context';
import { AccessControlCommands } from '../enumerations/access-control-commands';
import { EntityCommandProviderBase, ExecuteEntityCommandData } from '../../shared/services/commands/entity-command-provider-base';
import { AccessControlContentTypes } from '../enumerations/access-control-content-types';
import {
    CommandBindings,
    CommandsService,
    COMMANDS_SERVICE,
    CommandDisplay,
    EntityCommandRequirements,
    FEATURES_SERVICE,
} from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { LanguageService } from '../../shared/services/language/language.service';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class CardholderCommandProvider extends EntityCommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(
                AccessControlCommands.ActivateDeactivateCardholder,
                this.createCardholderCommandDescriptor(
                    AccessControlCommands.ActivateDeactivateCardholder,
                    'STE_ACTION_DEACTIVATECARDHOLDER',
                    MeltedIcon.Denied,
                    this.fillCommandRequirements({ requiredPrivileges: SafeGuid.createSet([KnownPrivileges.changeCardholderStatusPrivilege]), isFederated: false })
                )
            );
            // FM: Diabled for now until discussed with UX
            // descriptors.set(
            //     AccessControlCommands.reportLostCardholderCredential,
            //     this.createCardholderCommandDescriptor(
            //         AccessControlCommands.reportLostCardholderCredential,
            //         'STE_EVENT_REASON_NAME_LOSTCREDENTIAL',
            //         MeltedIcon.Badge,
            //         this.fillCommandRequirements({ requiredPrivileges: [KnownPrivileges.changeCardholderStatusPrivilege], isFederated: false })
            //     )
            // );
            // descriptors.set(
            //     AccessControlCommands.reportStolenCardholderCredential,
            //     this.createCardholderCommandDescriptor(
            //         AccessControlCommands.reportStolenCardholderCredential,
            //         'STE_EVENT_REASON_NAME_STOLENCREDENTIAL',
            //         MeltedIcon.Badge,
            //         this.fillCommandRequirements({ requiredPrivileges: [KnownPrivileges.changeCardholderStatusPrivilege], isFederated: false })
            //     )
            // );
            this.internalCommandDescriptors = descriptors;
        }

        return this.internalCommandDescriptors;
    }

    public get requiredEntityFields(): Set<string> {
        return new Set<string>([...super.requiredEntityFields, CardholderEntityFields.accessStatusField, EntityFields.isFederatedField]);
    }

    public get requiredFeatures(): Set<IGuid> {
        return SafeGuid.createSet([...super.requiredFeatures, KnownFeatures.accessControlId]);
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);

        // Prefetch privileges
        await this.prefetchPrivileges(commandContext, commandArgs);

        if (await this.isActivateDeactivateAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(AccessControlCommands.ActivateDeactivateCardholder);
            this.configureActivateDeactivateInvalidateDisplayAsync(commandContext as EntityCommandsUsageContext).fireAndForget();
        }
        // FM: Diabled for now until discussed with UX
        // if (await this.isReportLostCredentialAvailableAsync(commandContext, commandArgs)) {
        //     commandIds.push(AccessControlCommands.reportLostCardholderCredential);
        //     this.configureChangeAccessStatusInvalidateCanExecuteAsync(commandContext as EntityCommandsUsageContext, AccessControlCommands.reportLostCardholderCredential).catch();
        // }
        // if (await this.isReportStolenCredentialAvailableAsync(commandContext, commandArgs)) {
        //     commandIds.push(AccessControlCommands.reportStolenCardholderCredential);
        //     this.configureChangeAccessStatusInvalidateCanExecuteAsync(commandContext as EntityCommandsUsageContext, AccessControlCommands.reportStolenCardholderCredential).catch();
        // }
        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: AccessControlCommands.ActivateDeactivateCardholder,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeActivateDeactivate(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isActivateDeactivateAvailableAsync(entityCommandContext), commandContext),
            getCommandDisplayHandler: (commandContext) =>
                this.safeGetEntityCommandDisplayAsync((entityCommandContext) => this.getActivateDeactivateDisplayAsync(entityCommandContext), commandContext),
        });
        // FM: Diabled for now until discussed with UX
        // bindings.addCommand({
        //     commandId: AccessControlCommands.reportLostCredential,
        //     executeCommandHandler: (executeCommandData) =>
        //         this.safeExecuteCommand((entityExecuteCommandData) => this.executeReportLostCredential(entityExecuteCommandData), executeCommandData),
        //     isCommandAvailableHandler: (commandContext) =>
        //         this.safeIsCommandAvailableAsync((entityCommandContext) => this.isReportLostCredentialAvailableAsync(entityCommandContext), commandContext),
        //     canExecuteCommandHandler: (commandContext) =>
        //         this.safeCanExecuteCommandAsync((entityCommandContext) => this.canReportCredentialLostAsync(entityCommandContext), commandContext),
        // });
        // bindings.addCommand({
        //     commandId: AccessControlCommands.reportStolenCredential,
        //     executeCommandHandler: (executeCommandData) =>
        //         this.safeExecuteCommand((entityExecuteCommandData) => this.executeReportStolenCredential(entityExecuteCommandData), executeCommandData),
        //     isCommandAvailableHandler: (commandContext) =>
        //         this.safeIsCommandAvailableAsync((entityCommandContext) => this.isReportStolenCredentialAvailableAsync(entityCommandContext), commandContext),
        //     canExecuteCommandHandler: (commandContext) =>
        //         this.safeCanExecuteCommandAsync((entityCommandContext) => this.canReportCredentialStolenAsync(entityCommandContext), commandContext),
        // });
    }

    protected fillCommandRequirements(requirements: EntityCommandRequirements): EntityCommandRequirements {
        if (!requirements.supportedContentTypes) {
            requirements.supportedContentTypes = SafeGuid.createSet([AccessControlContentTypes.Cardholder]);
        }

        if (!requirements.supportedEntityTypes) {
            requirements.supportedEntityTypes = new Set<string>([EntityTypes.Cardholders]);
        }
        return super.fillCommandRequirements(requirements);
    }

    private async canReportCredentialLostAsync(commandContext: EntityCommandContext): Promise<boolean> {
        const cardholderEntity = await this.extractCardholderEntity(commandContext);
        return cardholderEntity !== null && cardholderEntity.accessStatus !== AccessStatus.Lost;
    }

    private async canReportCredentialStolenAsync(commandContext: EntityCommandContext): Promise<boolean> {
        const cardholderEntity = await this.extractCardholderEntity(commandContext);
        return cardholderEntity !== null && cardholderEntity.accessStatus !== AccessStatus.Stolen;
    }

    private async changeAccessStatusAsync(commandContext: EntityCommandContext, accessStatus: string, cardholderEntity?: ICardholderEntity): Promise<void> {
        let entity = cardholderEntity || null;
        if (!entity) {
            entity = await this.extractCardholderEntity(commandContext);
        }
        if (entity) {
            entity.accessStatus = accessStatus;
            await this.updateEntityAsync(entity, commandContext.entityCache);
        }
    }

    private async configureActivateDeactivateInvalidateDisplayAsync(commandContext: EntityCommandsUsageContext): Promise<void> {
        if (commandContext.entityCache && commandContext.invalidateDisplay) {
            const cardholderEntity = await this.extractCardholderEntity(commandContext);
            if (cardholderEntity) {
                await commandContext.entityCache.detectFieldChangeAsync(
                    cardholderEntity,
                    () => {
                        const _ = cardholderEntity.accessStatus;
                    },
                    (() => {
                        commandContext.invalidateDisplay(AccessControlCommands.ActivateDeactivateCardholder);
                        return new Deferred<void>(true).promise;
                    }) as modificationHandlerField<string>
                );
            }
        }
    }

    private async configureChangeAccessStatusInvalidateCanExecuteAsync(commandContext: EntityCommandsUsageContext, commandId: IGuid): Promise<void> {
        if (commandContext.entityCache && commandContext.invalidateCanExecute) {
            const cardholderEntity = await this.extractCardholderEntity(commandContext);
            if (cardholderEntity) {
                await commandContext.entityCache.detectFieldChangeAsync(
                    cardholderEntity,
                    () => {
                        const _ = cardholderEntity.accessStatus;
                    },
                    (() => {
                        commandContext.invalidateCanExecute(commandId);
                        return new Deferred<void>(true).promise;
                    }) as modificationHandlerField<string>
                );
            }
        }
    }

    private createCardholderCommandDescriptor(
        commandId: IGuid,
        nameResourceId: string,
        icon: MeltedIcon,
        requirements?: EntityCommandRequirements,
        keyBinding?: string
    ): CommandDescriptor {
        return {
            id: commandId,
            nameResourceId,
            icon,
            groupNameResourceId: 'STE_ENTITY_CARDHOLDER',
            groupIcon: MeltedIcon.Cardholder,
            requirements,
            keyBinding,
        };
    }

    private executeActivateDeactivate(executeCommandData: ExecuteEntityCommandData): void {
        this.executeActivateDeactivateAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async executeActivateDeactivateAsync(commandContext: EntityCommandContext): Promise<void> {
        const cardholderEntity = await this.extractCardholderEntity(commandContext);
        if (cardholderEntity) {
            await this.changeAccessStatusAsync(commandContext, this.isActive(cardholderEntity) ? AccessStatus.Inactive : AccessStatus.Active, cardholderEntity);
        }
    }

    private executeReportLostCredential(executeCommandData: ExecuteEntityCommandData): void {
        this.changeAccessStatusAsync(executeCommandData.commandContext, AccessStatus.Lost).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private executeReportStolenCredential(executeCommandData: ExecuteEntityCommandData): void {
        this.changeAccessStatusAsync(executeCommandData.commandContext, AccessStatus.Stolen).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async extractCardholderEntity(commandContext: EntityCommandContext): Promise<ICardholderEntity | null> {
        return this.extractEntityAsync<CardholderEntity, ICardholderEntity>(CardholderEntity, commandContext);
    }

    private async getActivateDeactivateDisplayAsync(commandContext: EntityCommandContext): Promise<CommandDisplay | undefined> {
        const cardholderEntity = await this.extractCardholderEntity(commandContext);
        if (cardholderEntity) {
            if (!this.isActive(cardholderEntity)) {
                return { name: () => this.translateService?.instant('STE_ACTION_ACTIVATECARDHOLDER') as string, icon: MeltedIcon.Update };
            }
            return { name: () => this.translateService?.instant('STE_ACTION_DEACTIVATECARDHOLDER') as string, icon: MeltedIcon.Denied };
        }
    }

    private async isActivateDeactivateAvailableAsync(commandContext?: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(AccessControlCommands.ActivateDeactivateCardholder, commandContext, commandArgs);
    }

    private isActive(cardholderEntity: ICardholderEntity): boolean {
        return this.isSameAccessStatus(cardholderEntity, AccessStatus.Active);
    }

    private async isReportLostCredentialAvailableAsync(commandContext?: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(AccessControlCommands.ReportLostCardholderCredential, commandContext, commandArgs);
    }

    private async isReportStolenCredentialAvailableAsync(commandContext?: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(AccessControlCommands.ReportStolenCardholderCredential, commandContext, commandArgs);
    }

    private isSameAccessStatus(cardholderEntity: ICardholderEntity, accessStatus: string): boolean {
        return cardholderEntity.accessStatus === accessStatus;
    }
}
