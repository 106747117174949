import { Injectable, Type, Compiler, Injector, ComponentFactoryResolver } from '@angular/core';
import { SafeGuid, IGuid, GuidMap } from 'safeguid';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { AccessControlFeatureFlags } from '@modules/access-control/feature-flags';
import { FeatureFlag } from '@modules/feature-flags/feature-flag';
import {
    MetadataKey,
    PluginDescriptor,
    PluginComponent,
    PluginComponentRequirements,
    ContentPluginComponent,
    ContentPluginDescriptor,
    ContentGroup,
    Content,
    LicensePartsState,
} from '../../interfaces/plugins/public/plugin-public.interface';
import { PluginItem } from '../../interfaces/plugins/internal/pluginItem';
import { PluginsController } from '../../controllers/plugins/plugins.controller';
import { IExternalPluginDescriptor } from '../../controllers/plugins/plugins.controller.data';
import { FEATURES_SERVICE } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { LoggerService } from '../logger/logger.service';
import { IPluginsController } from '../../controllers/plugins/plugins.controller.interfaces';
import { AdvancedSettingsService } from '../advanced-settings/advanced-settings.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Injectable({
    providedIn: 'root',
})
export class PluginService {
    //#region Fields

    public static availablePlugins: Type<PluginComponent>[] = [];
    public static availableContentPlugins: Type<ContentPluginComponent>[] = [];

    private resolvedExternalPluginDescriptors: Map<string, IExternalPluginDescriptor[]> = new Map<string, IExternalPluginDescriptor[]>();

    private pluginDescriptorsByPluginTypes = new GuidMap<{ type: Type<PluginComponent>; descriptor: PluginDescriptor | ContentPluginDescriptor }[]>();

    //#endregion

    //#region Constructors

    constructor(
        private securityCenterClientService: SecurityCenterClientService,
        private advancedSettingsService: AdvancedSettingsService,
        private compiler: Compiler,
        private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver,
        private loggerService: LoggerService
    ) {}

    //#endregion

    //#region Public Methods

    //
    public async getPlugin(pluginType: IGuid, pluginId: IGuid, data?: unknown): Promise<PluginItem | undefined> {
        const plugins = await this.getPlugins(pluginType, data);

        const filteredPlugins = plugins.filter((toFilterPlugin) => toFilterPlugin.exposure.id.equals(pluginId));

        return filteredPlugins.length === 0 ? undefined : filteredPlugins[0];
    }

    /**
     * Retrieves plugins of given type and that supports the given data.
     *
     * @param pluginType
     * @param data content associated with the plugin
     * @returns list of pluginItems
     */
    // eslint-disable-next-line @typescript-eslint/require-await
    public async getPlugins(pluginType: IGuid, data?: unknown): Promise<PluginItem[]> {
        // external ones, comes from server, disabled for now waiting for module federation
        const externalPlugins: PluginItem[] = []; // = await this.getExternalPlugins(pluginType, data);

        // internal ones, already compiled within this app
        const internalPlugins = await this.getInternalPluginsAsync(pluginType, data);

        // filter by priority
        let allPlugins = internalPlugins.concat(externalPlugins).sort((p1, p2) => {
            // By default, if no priority is set, apply 1000
            const defaultPriority = 1000;
            const priority1 = p1 === undefined || p1.exposure.priority === undefined ? defaultPriority : p1.exposure.priority;
            const priority2 = p2 === undefined || p2.exposure.priority === undefined ? defaultPriority : p2.exposure.priority;

            return priority1 - priority2;
        });

        // filter out plugins that have required parent plugins that are not present
        allPlugins = allPlugins.filter(
            (pluginToFilter) => !pluginToFilter.exposure?.requiredParentPlugin || allPlugins.some((plugin) => plugin.exposure.id === pluginToFilter.exposure.requiredParentPlugin)
        );

        return allPlugins;
    }

    /**
     * @param pluginId The id of the plugin that we are looking the name for
     * @returns If a plugin with the corresponding id exists, returns the name without the class name (ie. removes 'TaskComponent' substring). Else, returns an empty string.
     */
    public getPluginName(pluginId: IGuid): string {
        for (const pluginComponent of PluginService.availablePlugins.concat(PluginService.availableContentPlugins)) {
            const descriptor = this.getPluginDescriptor(pluginComponent);
            if (descriptor?.exposure.id.equals(pluginId)) {
                return pluginComponent.name?.replace('TaskComponent', '') ?? '';
            }
        }

        return '';
    }

    public getPluginsGlobalPrivileges(): Set<IGuid> {
        const globalPrivileges = SafeGuid.createSet();

        for (const pluginComponent of PluginService.availablePlugins.concat(PluginService.availableContentPlugins)) {
            const descriptor = this.getPluginDescriptor(pluginComponent);
            if (descriptor?.requirements) {
                descriptor.requirements.globalPrivileges?.forEach((item) => globalPrivileges.add(item));
                descriptor.requirements.optionalGlobalPrivileges?.forEach((item) => globalPrivileges.add(item));
            }
        }

        return globalPrivileges;
    }

    public async getPluginsFromContentGroup(pluginType: IGuid, contentGroup: ContentGroup): Promise<PluginItem[]> {
        let plugins = await this.getPlugins(pluginType, contentGroup.mainContent);
        const allPlugins: PluginItem[] = [];
        if (contentGroup.subContents) {
            for (const subContent of contentGroup.subContents) {
                const subPlugins = await this.getPlugins(pluginType, subContent);
                subPlugins.forEach((item) => {
                    plugins.push(item);
                });
            }
        }

        plugins = plugins.sort((p1, p2) => {
            const priority1 = p1 === undefined || p1.exposure.priority === undefined ? 0 : p1.exposure.priority;
            const priority2 = p2 === undefined || p2.exposure.priority === undefined ? 0 : p2.exposure.priority;

            return priority1 - priority2;
        });

        return plugins;
    }

    /**
     * Retrieve a set of string contaning the name of every license parts used inside the web app
     */
    public getWebAppLicensesPartsFromPlugins(): string[] {
        const licenseParts: Set<string> = new Set<string>();

        for (const pluginComponent of [...PluginService.availablePlugins, ...PluginService.availableContentPlugins]) {
            const descriptor = this.getPluginDescriptor(pluginComponent);
            if (descriptor?.requirements) {
                descriptor.requirements.licenses?.forEach((item) => licenseParts.add(item));
            }
        }

        return [...licenseParts];
    }

    /**
     * Get the licenses state (activated/deactivated) that are used inside the Web App
     *
     * @returns an object which keys correspond to the name of the license part and values to the state.
     */
    public getLicensesPartsState(): LicensePartsState {
        const licenseParts = this.getWebAppLicensesPartsFromPlugins();

        const license = this.securityCenterClientService?.scClient?.license;
        const result: LicensePartsState = {};
        if (license) {
            licenseParts.forEach((licensePart) => (result[licensePart] = license.hasLicense(licensePart)));
        }

        return result;
    }

    //#endregion

    //#region Private Methods

    private async getInternalPluginsAsync(pluginType: IGuid, data?: unknown): Promise<PluginItem[]> {
        if (this.pluginDescriptorsByPluginTypes.size === 0) {
            for (const pluginComponent of PluginService.availablePlugins.concat(PluginService.availableContentPlugins)) {
                const descriptor = this.getPluginDescriptor(pluginComponent);
                if (descriptor) {
                    for (const resolvedPluginType of descriptor.pluginTypes) {
                        const existingDescriptorsEntry = this.pluginDescriptorsByPluginTypes.get(resolvedPluginType);
                        if (existingDescriptorsEntry) {
                            existingDescriptorsEntry.push({ type: pluginComponent, descriptor });
                        } else {
                            this.pluginDescriptorsByPluginTypes.set(resolvedPluginType, [{ type: pluginComponent, descriptor }]);
                        }
                    }
                }
            }
        }

        const pluginItems: PluginItem[] = [];
        for (const entry of this.pluginDescriptorsByPluginTypes.get(pluginType) ?? []) {
            try {
                if ((await this.allRequirementsSatisfiedAsync(entry.descriptor.requirements)) && this.isSupported(entry.descriptor, data, pluginType)) {
                    const factory = this.componentFactoryResolver.resolveComponentFactory(entry.type);

                    const subTaskPlugins: PluginItem[] = [];
                    if (AccessControlFeatureFlags.General.isEnabled(this.advancedSettingsService) && entry.descriptor.exposure.subTaskPluginType != null) {
                        const subTasks = await this.getPlugins(entry.descriptor.exposure.subTaskPluginType);
                        subTasks.forEach((element) => {
                            subTaskPlugins.push(element);
                        });
                    }

                    pluginItems.push(new PluginItem(entry.type, factory, data, entry.descriptor.exposure, subTaskPlugins));
                }
            } catch (exception) {
                this.loggerService.traceError(exception);
            }
        }

        return pluginItems;
    }

    private isSupported(pluginDescriptor: PluginDescriptor | ContentPluginDescriptor, data: unknown, pluginType: IGuid): boolean {
        let isSupported = false;
        if ('isSupported' in pluginDescriptor) {
            const descriptor = pluginDescriptor;
            isSupported = descriptor.isSupported(data, pluginType, this.injector);
        } else if ('isContentSupported' in pluginDescriptor) {
            const descriptor = pluginDescriptor;
            isSupported = descriptor.isContentSupported(data as Content, pluginType, this.injector);
        }

        return isSupported;
    }

    private async getExternalPlugins(pluginType: IGuid, data?: unknown): Promise<PluginItem[]> {
        // TODO: Left the body here to keep trace of the code, but reference to SystemJS (commented out) will need to be replaced.

        const pluginItems: PluginItem[] = [];
        // do not ask server if already processed plugins of this type
        let resolvedPluginDescriptors = this.resolvedExternalPluginDescriptors.get(pluginType.toString());
        try {
            if (resolvedPluginDescriptors === undefined) {
                try {
                    const pluginsController = await this.securityCenterClientService?.scClient.getAsync<PluginsController, IPluginsController>(PluginsController);
                    const pluginDescriptors = await pluginsController?.getExternalDescriptorsAsync(pluginType);
                    if (pluginDescriptors) {
                        resolvedPluginDescriptors = Array.from(pluginDescriptors);
                        this.resolvedExternalPluginDescriptors.set(pluginType.toString(), resolvedPluginDescriptors);
                    }
                } catch (exception) {
                    resolvedPluginDescriptors = [];
                }
            }
            // Webpack bundles all modules into one or several chunks-a bunch of modules packaged in a single file.
            // SystemJS can also do that but in this respect it’s much more limited than Webpack.
            // Where they differ the most is when it comes to loading modules dynamically.
            // While SystemJS can load any module dynamically on demand during runtime, Webpack can only dynamically load chunks defined and created during build time.
            if (resolvedPluginDescriptors) {
                for (const descriptor of resolvedPluginDescriptors) {
                    if (descriptor.dependencies !== null) {
                        for (const dependency of descriptor.dependencies) {
                            // SystemJS.config({
                            //     map: {
                            //         [dependency.key]: dependency.libraryName,
                            //     },
                            // });
                        }
                    }
                    const module = {} as Record<string, Type<unknown>>; // (await SystemJS.import(descriptor.packageFileName)) as Record<string, Type<unknown>>;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    const moduleType = module[descriptor.pluginDescriptor.moduleClassType];
                    const moduleWithFactories = await this.compiler.compileModuleAndAllComponentsAsync(moduleType);
                    const ngModule = moduleWithFactories.ngModuleFactory.create(this.injector);
                    const factory = moduleWithFactories.componentFactories.find((x) => x.componentType.name === descriptor.pluginDescriptor.componentType);
                    if (factory !== undefined) {
                        const pluginDescriptor = this.getPluginDescriptor(factory.componentType);
                        if (pluginDescriptor && (await this.allRequirementsSatisfiedAsync(pluginDescriptor.requirements)) && this.isSupported(pluginDescriptor, data, pluginType)) {
                            const subTaskPlugins: PluginItem[] = [];
                            if (AccessControlFeatureFlags.General.isEnabled(this.advancedSettingsService) && pluginDescriptor.exposure.subTaskPluginType != null) {
                                const subTasks = await this.getPlugins(pluginDescriptor.exposure.subTaskPluginType);
                                subTasks.forEach((element) => {
                                    subTaskPlugins.push(element);
                                });
                            }
                            pluginItems.push(new PluginItem(factory.componentType, factory, data, pluginDescriptor.exposure, subTaskPlugins, ngModule));
                        }
                    }
                }
            }
        } catch (exception) {
            this.loggerService.traceError(exception);
        }
        return pluginItems;
    }

    private async allRequirementsSatisfiedAsync(requirements: PluginComponentRequirements | undefined) {
        return (
            !requirements ||
            ((await this.isFeatureSupportedAsync(requirements.features)) &&
                this.isLicenseSupported(requirements?.licenses) &&
                this.isGlobalPrivilegeSupported(requirements?.globalPrivileges) &&
                this.areFeatureFlagsEnabled(requirements?.enabledFeatureFlags) &&
                this.areFeatureFlagsDisabled(requirements?.disabledFeatureFlags))
        );
    }

    private isLicenseSupported(licenseRequired: string[] | undefined): boolean {
        if (!licenseRequired || licenseRequired.length === 0) {
            return true;
        }

        const license = this.securityCenterClientService?.scClient?.license;
        return license ? licenseRequired.some((item) => license.hasLicense(item)) : true;
    }

    private async isFeatureSupportedAsync(featuresRequired: IGuid[] | undefined): Promise<boolean> {
        // 1st check that some features are requested
        if (featuresRequired?.length && featuresRequired.length > 0) {
            // now retrieve the feature service (only needed if some features are requested)
            const featuresService = this.injector.get(FEATURES_SERVICE, null);
            const features = await featuresService?.getFeaturesAsync();
            if (features?.size && features.size > 0) {
                // check every feature in turn
                return featuresRequired.every((value) => {
                    return features.has(value);
                });
            }
            // Make sure to return false if we don't have any feature available
            return false;
        }
        // unable to get any features or doesn't require any features
        return true;
    }

    private isGlobalPrivilegeSupported(globalPrivileges: IGuid[] | undefined): boolean {
        const scClient = this.securityCenterClientService?.scClient;

        if (!globalPrivileges || globalPrivileges.length === 0) {
            return true;
        }

        return globalPrivileges.some((value) => {
            if (scClient.isGlobalPrivilegeGranted(value)) {
                return true;
            }
            return false;
        });
    }

    private areFeatureFlagsEnabled(enabledFeatureFlags?: FeatureFlag[]): boolean {
        return enabledFeatureFlags ? enabledFeatureFlags.some((featureFlag) => featureFlag.isEnabled(this.advancedSettingsService)) : true;
    }

    private areFeatureFlagsDisabled(disabledFeatureFlags?: FeatureFlag[]): boolean {
        return disabledFeatureFlags ? disabledFeatureFlags.every((featureFlag) => !featureFlag.isEnabled(this.advancedSettingsService)) : true;
    }

    // supposed to be memoized... to verify
    private getPluginDescriptor<T>(type: Type<T>): PluginDescriptor | ContentPluginDescriptor | undefined {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            return Reflect.getMetadata(MetadataKey, type.prototype.constructor) as PluginDescriptor | ContentPluginDescriptor | undefined;
        } catch (e) {
            this.loggerService.traceError(`Unable to find plugin descriptor of plugin ${type.name}, details: ${(e as Error).message}`);
        }
    }

    //#endregion
}
