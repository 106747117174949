import { Injectable, OnDestroy } from '@angular/core';
import { IWatchlistEntry, Watchlist, WatchlistPriority } from '@modules/shared/api/api';
import { MonitoredEntityEvent } from '@modules/shared/controllers/events/monitored-entity-event';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EventsService } from '@modules/shared/services/events/events.service';
import { EventTimeService } from '@modules/shared/services/time/event-time.service';
import { UserWatchlistService } from '@modules/shared/services/watchlist/user-watchlist.service';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { delayWhen, filter, take, takeUntil, tap } from 'rxjs/operators';
import { GuidMap, IGuid } from 'safeguid';
import { CachedMonitoredEntityEvent } from './cached-monitored-entity-event';
import { EventsDismisser } from './event-dismisser';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class EventsMonitoringService implements OnDestroy, EventsDismisser {
    public MAX_EVENTS_COUNT = 250;
    public missedEventsCount$: Observable<number>;
    public newEvents$: Observable<CachedMonitoredEntityEvent[]>;
    public receivedEvents$: Observable<CachedMonitoredEntityEvent[]>;

    private readonly fifteenMinutesInMilliseconds = 900_000;
    private missedEventsCountSubject$ = new BehaviorSubject<number>(0);
    private monitoredEntitySubscriptionsMap = new GuidMap<Subscription>();
    private watchlistEntryMap = new GuidMap<IWatchlistEntry>();
    private userWatchlistServiceSubscriptions = new SubscriptionCollection();
    private receivedEvents: CachedMonitoredEntityEvent[] = [];
    private newEventsSubject$ = new Subject<CachedMonitoredEntityEvent[]>();
    private receivedEventsSubject$ = new BehaviorSubject<CachedMonitoredEntityEvent[]>([]);
    private isLiveMonitoring = false;
    private isMonitoring = false;
    private cacheLifetimeMilliseconds = this.fifteenMinutesInMilliseconds;

    constructor(
        private userWatchlistService: UserWatchlistService,
        private eventsService: EventsService,
        private authService: AuthService,
        private eventTimeService: EventTimeService
    ) {
        this.missedEventsCount$ = this.missedEventsCountSubject$.asObservable();
        this.receivedEvents$ = this.receivedEventsSubject$.asObservable();
        this.newEvents$ = this.newEventsSubject$.asObservable();
        this.subscribeAuthService();
    }

    ngOnDestroy(): void {
        this.receivedEventsSubject$.complete();
        this.newEventsSubject$.complete();
        this.clearEvents();
    }

    /**
     * Dismiss an existing event from the list of cached events
     */
    public dismissEvent(eventNumber: number): void {
        const eventToDelete = this.findCachedEvent(eventNumber);
        if (eventToDelete) {
            this.removeReceivedEvent(eventToDelete);
        }
    }

    /**
     * Marks all events as read
     */
    public markAllAsRead(): void {
        const eventsNotRead = this.receivedEvents.filter((event) => event.isNew);
        if (eventsNotRead.length > 0) {
            eventsNotRead.forEach((event) => this.markAsRead(event.eventNumber));
            this.emitReceivedEvents();
        }
    }

    /**
     * Marks an event as read
     */
    public markAsRead(eventNumber: number): void {
        const eventToMarkAsRead = this.findCachedEvent(eventNumber);
        if (eventToMarkAsRead) {
            eventToMarkAsRead.isNew = false;
            this.emitReceivedEvents();
        }
    }

    /**
     * Dismiss all cached events
     */
    public dismissAllEvents(): void {
        this.dismissEvents();
    }

    /**
     * Dismiss all events associated to a priority
     */
    public dismissEvents(priority?: WatchlistPriority): void {
        this.clearReceivedEvents(priority);
    }

    public toggleLiveMonitoringState(): void {
        this.isLiveMonitoring = !this.isLiveMonitoring;

        // Reset missed events count when we are live monitoring
        if (this.isLiveMonitoring) {
            this.resetMissedEventsCount();
        }
    }

    /*
     * Clear all current event monitoring
     */
    private clearEvents(): void {
        this.userWatchlistServiceSubscriptions.unsubscribeAll();
        Array.from(this.monitoredEntitySubscriptionsMap.keys()).forEach((key) => this.removeUserWatchlistItem(key));
        this.clearReceivedEvents();
        this.resetMissedEventsCount();
    }

    private clearReceivedEvents(priority?: WatchlistPriority): void {
        if (this.receivedEvents.length > 0) {
            let eventsToClear = this.receivedEvents.slice();
            if (priority !== undefined) {
                eventsToClear = eventsToClear.filter((event) => event.priority === priority);
            }

            eventsToClear.forEach((event) => {
                event.destroy();
                this.receivedEvents.remove(event);
            });

            this.emitReceivedEvents();
        }
    }

    private removeReceivedEvent(cachedMonitoredEntityEvent: CachedMonitoredEntityEvent) {
        this.receivedEvents.remove(cachedMonitoredEntityEvent);
        cachedMonitoredEntityEvent.destroy();
        this.decreaseMissedEventsCount();
        this.emitReceivedEvents();
    }

    /*
     * Start monitoring for entities
     */
    private startMonitoring(): void {
        if (!this.isMonitoring) {
            this.isMonitoring = true;
            this.initializeMonitoring();
        }
    }

    /*
     * Stop monitoring entities
     */
    private stopMonitoring(): void {
        if (this.isMonitoring) {
            this.isMonitoring = false;
            this.clearEvents();
        }
    }

    /**
     * Fetches the user watch list and starts listening for new events.
     */
    private initializeMonitoring(): void {
        this.userWatchlistServiceSubscriptions.add(
            this.userWatchlistService.watchlist$
                .pipe(
                    delayWhen(() => this.fetchCacheLifetime()),
                    filter((watchlist): watchlist is Watchlist => watchlist !== null),
                    take(1)
                )
                .subscribe((watchlist) => {
                    this.addUserWatchlistItems(watchlist.entries);
                    this.subscribeToUserWatchlistObservers();
                })
        );
    }

    private fetchCacheLifetime(): Observable<number> {
        return this.eventsService.cacheLifetimeMilliseconds$.pipe(
            take(1),
            tap((cache) => (this.cacheLifetimeMilliseconds = cache))
        );
    }

    /**
     * Subscribes to watch list observers and dictates what to do when watch list items
     * are added, modified and removed from the watch list.
     */
    private subscribeToUserWatchlistObservers(): void {
        this.userWatchlistServiceSubscriptions.add(this.userWatchlistService.added$.subscribe((userWatchlistItems) => this.addUserWatchlistItems(userWatchlistItems)));
        this.userWatchlistServiceSubscriptions.add(this.userWatchlistService.modified$.subscribe((userWatchlistItem) => this.updateUserWatchlistItem(userWatchlistItem)));
        this.userWatchlistServiceSubscriptions.add(this.userWatchlistService.removed$.subscribe((userWatchlistItemId) => this.removeUserWatchlistItem(userWatchlistItemId)));
    }

    /**
     * Pushes new events to the received events list.
     *
     * @param event New event
     * @param userWatchlistItem Watch list item associated to the event
     */
    private newEvents(monitoredEntityEvents: MonitoredEntityEvent[], userWatchlistItem: IWatchlistEntry): void {
        const newEvents = monitoredEntityEvents.map(
            // Todo: Here we have a circular dependency between the EventsMonitoringService and the CachedMonitoredEntityEvent ('this' parameter).
            (entityEvent) => new CachedMonitoredEntityEvent(entityEvent, userWatchlistItem, this.eventTimeService, this, this.cacheLifetimeMilliseconds)
        );
        newEvents.sort((event1, event2) => event2.timestampMs - event1.timestampMs);
        const combinedEvents = [...newEvents, ...this.receivedEvents];

        // Delete oldest events
        if (combinedEvents.length > this.MAX_EVENTS_COUNT) {
            combinedEvents.slice(this.MAX_EVENTS_COUNT, combinedEvents.length).forEach((event) => event.destroy());
            combinedEvents.length = this.MAX_EVENTS_COUNT;
        }

        this.receivedEvents = combinedEvents;

        if (!this.isLiveMonitoring) {
            this.increaseMissedEventsCount(newEvents.length);
        }

        this.emitReceivedEvents();
        this.newEventsSubject$.next(newEvents);
    }

    /**
     * Adds a new watch list item and subscribes to the entity monitoring.
     *
     * @param userWatchlistItem Newly added watch list item
     */
    private addUserWatchlistItems(userWatchlistItems: IWatchlistEntry[]): void {
        userWatchlistItems.forEach((userWatchlistItem) => {
            this.watchlistEntryMap.set(userWatchlistItem.entityId, userWatchlistItem);
            this.monitoredEntitySubscriptionsMap.set(
                userWatchlistItem.entityId,
                this.eventsService.startMonitoringEvents(userWatchlistItem.entityId).subscribe((monitoredEntityEvents) => {
                    const watchlistEntry = this.watchlistEntryMap.get(userWatchlistItem.entityId);
                    if (watchlistEntry) {
                        this.newEvents(monitoredEntityEvents, watchlistEntry);
                    }
                })
            );
        });
    }

    private getEntityEvents(entityId: IGuid): CachedMonitoredEntityEvent[] {
        return this.receivedEvents.filter((monitoredEntityEvent) => monitoredEntityEvent.entityId.equals(entityId));
    }

    /**
     * Updates an existing user watch list item.
     *
     * @param updatedUserWatchlistItem Updated user watch list item
     */
    private updateUserWatchlistItem(updatedUserWatchlistItem: IWatchlistEntry): void {
        const affectedEvents = this.getEntityEvents(updatedUserWatchlistItem.entityId);
        if (affectedEvents.length > 0) {
            this.watchlistEntryMap.set(updatedUserWatchlistItem.entityId, updatedUserWatchlistItem);
            affectedEvents.forEach((monitoredEntityEvent) => monitoredEntityEvent.updateUserWatchlistItem(updatedUserWatchlistItem));
            this.emitReceivedEvents();
        }
    }

    /**
     * Unsubscribes from monitored entity.
     *
     * @param entityId Removed watch list item id
     */
    private removeUserWatchlistItem(entityId: IGuid): void {
        const subscription = this.monitoredEntitySubscriptionsMap.get(entityId);
        if (subscription) {
            subscription.unsubscribe();
            this.watchlistEntryMap.delete(entityId);
            this.monitoredEntitySubscriptionsMap.delete(entityId);
            this.removeEntityReceivedEvents(entityId);
        }
    }

    private removeEntityReceivedEvents(entityId: IGuid): void {
        const affectedEvents = this.getEntityEvents(entityId);
        if (affectedEvents.length > 0) {
            this.receivedEvents = this.receivedEvents.filter((receivedEvent) => affectedEvents.indexOf(receivedEvent) < 0);
            this.emitReceivedEvents();
        }
    }

    /*
     * Start monitoring when logged in, and stop when logged out.
     */
    private subscribeAuthService(): void {
        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.startMonitoring();
            } else {
                this.stopMonitoring();
            }
        });
    }

    private emitReceivedEvents() {
        this.resetEventNumbers();
        this.receivedEventsSubject$.next(this.receivedEvents);
    }

    private findCachedEvent(eventNumber: number): CachedMonitoredEntityEvent | undefined {
        return this.receivedEvents.find((receivedEvent) => receivedEvent.eventNumber === eventNumber);
    }

    private decreaseMissedEventsCount(): void {
        const currentMissedEventsCount = this.missedEventsCountSubject$.getValue();
        if (currentMissedEventsCount > 0) {
            this.missedEventsCountSubject$.next(currentMissedEventsCount - 1);
        }
    }

    private increaseMissedEventsCount(eventCount: number): void {
        const newEventCount = this.missedEventsCountSubject$.getValue() + eventCount;
        this.missedEventsCountSubject$.next(Math.min(newEventCount, this.MAX_EVENTS_COUNT + 1));
    }

    private resetEventNumbers() {
        this.receivedEvents.forEach((event, index) => (event.eventNumber = index));
    }

    private resetMissedEventsCount(): void {
        this.missedEventsCountSubject$.next(0);
    }
}
