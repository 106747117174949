import { GenSearchInputItem, MeltedIcon } from '@genetec/gelato-angular';

export class MapSearchInputItem implements GenSearchInputItem {
    public icon?: MeltedIcon;
    public secondaryText?: string;
    public groupName?: string;
    public navigation?: string;

    constructor(public id: string, public text: string) {}
}
