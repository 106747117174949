import { NormalizedColor } from '../../utils/Color';
import { ILogger } from '../../utils/logger';
import { Resolution } from '../../utils/Resolution';
import { Utils } from '../../utils/Utils';
import { ControllerUniforms } from '../ControllerUniforms';
import { DewarperController } from '../DewarperController';
import { IInternalDewarperControl } from '../DewarperInterfaces';
import { InternalDewarperSourceImageParameters } from '../DewarperSourceImageParameters';
import { PositionBuffer } from '../DewarperVertexShader';
import { DewarpingParameters } from '../DewarpingParameters';
import { GLUtils } from '../WebGL2/GLUtils';
import { Program } from '../WebGL2/Program';
import { Uniform1f, Uniform2f } from '../WebGL2/Uniform';
import { DewarpedAreaFragmentShader } from './DewarpedAreaFragmentShader';
import { DewarpedAreaVertexShader } from './DewarpedAreaVertexShader';

export interface IDewarpedAreaProgram {
  DewarpedAreaColor: NormalizedColor;
  DewarpedAreaBorderColor: NormalizedColor;
  readonly Controller: IInternalDewarperControl;

  setDewarpingParameters(dewarpingParameters: DewarpingParameters): void;
  dispose(): void;
  canvasResolutionChanged(): void;
  debugStatus(indent: number): string;
}

export class DewarpedAreaProgram extends Program implements IDewarpedAreaProgram {
  private readonly m_logger: ILogger;

  private readonly m_dewarperController: DewarperController;

  private readonly m_positionBuffer: PositionBuffer;

  private readonly m_warpedImageResolution: Uniform2f;

  private readonly m_lowerDewarpedLimit: Uniform2f;
  private readonly m_higherDewarpedLimit: Uniform2f;
  private readonly m_borderWidthRatio: Uniform1f;

  private readonly m_viewportResolution: Uniform2f;
  private readonly m_viewportOffset: Uniform2f;

  private m_dewarpingParameters: DewarpingParameters | null = null;

  private get DewarpingParameters(): DewarpingParameters {
    if (this.m_dewarpingParameters === null) {
      throw new Error('Unexpected null dewarping parameters');
    }

    return this.m_dewarpingParameters;
  }

  public set DewarpedAreaColor(newColor: NormalizedColor) {
    const dewarpedAreaColor = this.buildUniform4f('u_dewarpedAreaColor');
    const currentColorValues = dewarpedAreaColor.get();
    const currentColor = new NormalizedColor(currentColorValues[0], currentColorValues[1], currentColorValues[2], currentColorValues[3]);

    if (currentColor.equals(newColor)) {
      return;
    }

    this.m_logger.debug?.trace(`Setting dewarped area color from ${currentColor.toString()} to ${newColor.toString()}`);
    dewarpedAreaColor.set(newColor.R, newColor.G, newColor.B, newColor.A);
    this.render();
  }

  public set DewarpedAreaBorderColor(newBorderColor: NormalizedColor) {
    const dewarpedAreaBorderColor = this.buildUniform4f('u_dewarpedAreaBorderColor');
    const currentBorderColorValues = dewarpedAreaBorderColor.get();
    const currentBorderColor = new NormalizedColor(currentBorderColorValues[0], currentBorderColorValues[1], currentBorderColorValues[2], currentBorderColorValues[3]);

    if (currentBorderColor.equals(newBorderColor)) {
      return;
    }

    this.m_logger.debug?.trace(`Setting dewarped area border color from ${currentBorderColor.toString()} to ${newBorderColor.toString()}`);
    dewarpedAreaBorderColor.set(newBorderColor.R, newBorderColor.G, newBorderColor.B, newBorderColor.A);
    this.render();
  }

  public get Controller(): IInternalDewarperControl {
    return this.m_dewarperController;
  }

  constructor(logger: ILogger, gl: WebGL2RenderingContext, dewarperSourceImageParameters: InternalDewarperSourceImageParameters) {
    super(gl, new DewarpedAreaVertexShader(gl), new DewarpedAreaFragmentShader(gl, dewarperSourceImageParameters));

    this.m_logger = logger.subLogger('DewarpedAreaProgram');

    this.m_logger.intense?.trace(`vertex shader: ${this.m_compiledProgram.VertexShaderSource}`);
    this.m_logger.intense?.trace(`fragment shader: ${this.m_compiledProgram.FragmentShaderSource}`);

    this.m_positionBuffer = new PositionBuffer(this, 'a_position');
    this.m_positionBuffer.setValue(GLUtils.getRectangle12Points(-1, -1, 2, 2));

    this.m_warpedImageResolution = this.buildUniform2f('u_warpedImageResolution');

    this.m_lowerDewarpedLimit = this.buildUniform2f('u_lowerDewarpedLimit');
    this.m_higherDewarpedLimit = this.buildUniform2f('u_higherDewarpedLimit');
    this.m_borderWidthRatio = this.buildUniform1f('u_borderWidthRatio');

    this.m_viewportResolution = this.buildUniform2f('u_viewportResolution');
    this.m_viewportOffset = this.buildUniform2f('u_viewportOffset');

    this.m_dewarperController = this.buildController(dewarperSourceImageParameters);
    this.m_dewarperController.sceneUpdated.register(this.onSceneChanged);

    this.m_borderWidthRatio.set(0.05);
  }

  public dispose() {
    this.m_positionBuffer.dispose();
    this.m_dewarperController.sceneUpdated.unregister(this.onSceneChanged);
    super.dispose();
  }

  // Handle change of the canvas displayed resolution
  public canvasResolutionChanged(): void {
    this.setViewport();
  }

  // Set the dewarped resolution so the dewarped area can reflect dewarping into an area that does not respect the initial aspect ratio
  public setDewarpingParameters(dewarpingParameters: DewarpingParameters): void {
    this.m_dewarpingParameters = dewarpingParameters;

    this.setViewport();

    this.m_warpedImageResolution.setFromResolution(this.m_dewarpingParameters.StreamResolution);

    this.computeAspectRatioOverflow();

    this.m_dewarperController.DewarpingParameters = this.m_dewarpingParameters;
  }

  private computeAspectRatioOverflow() {
    const fitResolution = this.DewarpingParameters.FitResolution;

    this.m_lowerDewarpedLimit.set(
      -1.0 - (fitResolution.OffsetH * 2.0 / fitResolution.Scaled.Width),
      -1.0 - (fitResolution.OffsetV * 2.0 / fitResolution.Scaled.Height),
    );

    this.m_higherDewarpedLimit.set(
      1.0 + (fitResolution.OffsetH * 2.0 / fitResolution.Scaled.Width),
      1.0 + (fitResolution.OffsetV * 2.0 / fitResolution.Scaled.Height),
    );

    this.render();
  }

  private setViewport(): void {
    const canvasResolution = Resolution.build(this.m_gl.canvas.width, this.m_gl.canvas.height);

    // set the resolution -> Tell WebGL how to convert from clip space to pixels
    if (this.m_dewarpingParameters === null) {
      this.m_gl.viewport(0, 0, canvasResolution.Width, canvasResolution.Height);
      this.m_viewportResolution.setFromResolution(canvasResolution);
      this.m_logger.info?.trace(`Viewport resolution changed to ${canvasResolution.toString()}. No frame rendered. Changed viewport to ${canvasResolution.toString()}`);
    } else {
      const viewportResolution = this.m_dewarpingParameters.StreamResolution.fitInto(canvasResolution);
      const viewportVerticalOffset = canvasResolution.Height - viewportResolution.Height; //viewport position (0, 0) is lower left corner. We want to render upper left corner
      this.m_gl.viewport(0, viewportVerticalOffset, viewportResolution.Width, viewportResolution.Height);
      this.m_viewportResolution.setFromResolution(viewportResolution);
      this.m_viewportOffset.set(0, viewportVerticalOffset);
      this.m_logger.info?.trace(`Viewport resolution changed to ${canvasResolution.toString()}. Last frame rendered: ${this.m_dewarpingParameters.StreamResolution.toString()}. Changed viewport to ${viewportResolution.toString()} translation to (0,${viewportVerticalOffset})`);

      this.render();
    }
  }

  private render(): void {
    // Draw the rectangle (2 triangles)
    this.m_gl.drawArrays(this.m_gl.TRIANGLES, 0, 6);
  }

  private buildController(dewarperSourceImageParameters: InternalDewarperSourceImageParameters): DewarperController {
    const controller = new DewarperController(
      this.m_logger.subLogger('DewarperController - Area'),
      dewarperSourceImageParameters,
      new ControllerUniforms(
        this.buildUniform3f('u_pPrime'),
        this.buildUniform3f('u_uHat'),
        this.buildUniform3f('u_vHat'),
        this.buildUniform4f('u_dewarpingPlane')),
    );

    return controller;
  }

  private readonly onSceneChanged = (): void => {
    this.render();
  }

  public debugStatus(indent: number): string {
    const viewport: Int32Array = this.m_gl.getParameter(this.m_gl.VIEWPORT);

    return 'DewarpedAreaProgram:' + Utils.indentNewLine(indent) +
      'Dewarping Parameters:' + Utils.indentNewLine(indent) +
      (this.m_dewarpingParameters === null ? (' '.repeat(Utils.Indentation) + 'none') : this.m_dewarpingParameters?.debugStatus(indent + Utils.Indentation)) + Utils.indentNewLine(indent) +
      'Dewarped resolution: ' + (this.m_dewarpingParameters?.DewarpedResolution.toString() ?? 'none') + Utils.indentNewLine(indent) +
      'Warped image resolution: ' + this.m_warpedImageResolution.toString() + Utils.indentNewLine(indent) +
      'lower limit: ' + this.m_lowerDewarpedLimit.toString() + Utils.indentNewLine(indent) +
      'upper limit: ' + this.m_higherDewarpedLimit.toString() + Utils.indentNewLine(indent) +
      'border width: ' + this.m_borderWidthRatio.toString() + Utils.indentNewLine(indent) +
      'Viewport resolution and offset: ' + this.m_viewportResolution.toString() + ' ' + this.m_viewportOffset.toString() + Utils.indentNewLine(indent) +
      `gl viewport: ${viewport[0]}, ${viewport[1]} ${viewport[2]}x${viewport[3]}` + Utils.indentNewLine(indent) +
      this.m_dewarperController.debugStatus(indent + Utils.Indentation);
  }
}
