// Autogenerated: v2/help/Entities/ApiServer?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:08 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { ILocatableEntity, LocatableEntityFields } from './ILocatableEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class ServerEntityFields extends LocatableEntityFields {
    public static defaultDatabaseServerField = 'DefaultDatabaseServer';
    public static descriptiveOSVersionField = 'DescriptiveOSVersion';
    public static descriptiveProductVersionField = 'DescriptiveProductVersion';
    public static exactVersionField = 'ExactVersion';
    public static fullyQualifiedNameField = 'FullyQualifiedName';
    public static isInBackwardCompatibilityModeField = 'IsInBackwardCompatibilityMode';
    public static isMainServerField = 'IsMainServer';
    public static isProxyField = 'IsProxy';
    public static majorVersionField = 'MajorVersion';
    public static networkField = 'Network';
    public static oSVersionField = 'OSVersion';
    public static privateAddressField = 'PrivateAddress';
    public static privateAddressesField = 'PrivateAddresses';
    public static privateLegacyPortField = 'PrivateLegacyPort';
    public static privatePortField = 'PrivatePort';
    public static publicAddressField = 'PublicAddress';
    public static restBaseEndpointField = 'RestBaseEndpoint';
    public static rootWebApplicationPathField = 'RootWebApplicationPath';
    public static secureWebPortField = 'SecureWebPort';
    public static serverCertificateField = 'ServerCertificate';
    public static serverCertificateThumbprintField = 'ServerCertificateThumbprint';
    public static serverTypeField = 'ServerType';
    public static webPortField = 'WebPort';
}

// #endregion Fields

// #region Inner classes

export interface IRestRoleConfig extends IFieldObject {
    pluginType: IGuid;
    name: string;
    description: string;
    supports64Bits: boolean;
    serviceDomain: string;
    pluginAssemblyLocation: string;
    pluginTypeFullName: string;
}

export interface INetworkInterface extends IFieldObject {
    macAddress: string;
    name: string;
    physicalAddress: string;
    iPv4: string;
    iPv6: string;
}

// #endregion Inner classes

export interface IServerEntity extends ILocatableEntity {
    // #region REST Properties

    defaultDatabaseServer: string;

    descriptiveOSVersion: string;

    readonly descriptiveProductVersion: string;

    readonly exactVersion: string;

    readonly fullyQualifiedName: string;

    readonly isInBackwardCompatibilityMode: boolean;

    readonly isMainServer: boolean;

    readonly isProxy: boolean;

    readonly majorVersion: string;

    readonly network: IGuid;

    oSVersion: string;

    readonly privateAddress: string;

    readonly privateAddresses: ObservableCollection<string>;

    readonly privateLegacyPort: number;

    privatePort: number;

    readonly publicAddress: string;

    readonly restBaseEndpoint: string;

    rootWebApplicationPath: string;

    readonly secureWebPort: number;

    readonly serverCertificate: IGuid;

    readonly serverCertificateThumbprint: string;

    readonly serverType: string;

    readonly webPort: number;

    // #endregion REST Properties

    // #region REST Methods

    getAgentsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getDirectoriesAsync(value: string, transaction?: ITransaction): Promise<Array<string> | null>;
    getDrivesAsync(transaction?: ITransaction): Promise<Array<string> | null>;
    getFilesAsync(value: string, transaction?: ITransaction): Promise<Array<string> | null>;
    getNetworkInterfacesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<INetworkInterface> | null>;
    getRestRoleAvailableAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRestRoleConfig> | null>;
    getRolesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

