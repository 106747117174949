// Autogenerated: v2/help/Entities/ApiIncidentManagementRole?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:18 PM
import { RoleEntity } from './RoleEntity';
import { IIncidentManagementRoleEntity } from '../../Interface/IIncidentManagementRoleEntity';

export class IncidentManagementRoleEntity extends RoleEntity implements IIncidentManagementRoleEntity {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

