import { ILiteEvent, LiteEvent } from '../../utils/liteEvents';
import { Resolution } from '../../utils/Resolution';
import { IVideoFrame } from '../../players/VideoFrameSource';
import { CanvasWrapper } from '../CanvasWrapper';
import { ILogger } from '../../utils/logger';
import { Utils } from '../../utils/Utils';
import { Coords } from '../../utils/Coords';
import { TopLeftPixelCoords } from '../../utils/TopLeftPixelCoords';
import { VideoWatermarkingRenderer } from '../../utils/VideoWatermarkingRenderer';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { Rectangle } from '../../utils/Rectangle';
import { TopLeftCoords } from '../../utils/TopLeftCoords';

export interface IPreviewCanvas {
  readonly Clicked: ILiteEvent<Coords>;
  readonly Resized: ILiteEvent<Resolution>;
  readonly Resolution: Resolution;

  Canvas: HTMLCanvasElement;

  Show: boolean;

  dispose(): void;
  render(imageSource: IVideoFrame): void;
  drawRectangle(rectangle: Rectangle): void;
  clear(): void;

  updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void;

  debugStatus(indent: number): string;
}

export class PreviewCanvas extends CanvasWrapper implements IPreviewCanvas {
  private static readonly m_rectangleColor = 'Red';

  private readonly m_context: CanvasRenderingContext2D;

  private readonly m_onClick = new LiteEvent<Coords>();

  private m_watermarkRenderer: VideoWatermarkingRenderer | null;

  private m_videoWatermarkConfig = VideoWatermarkConfig.Disabled;

  private m_displayedResolution: Resolution = Resolution.None;

  public get Clicked(): ILiteEvent<Coords> {
    return this.m_onClick.expose();
  }

  constructor(logger: ILogger, previewCanvas: HTMLCanvasElement) {
    super(logger, 'PreviewCanvas', previewCanvas);
    this.m_context = this.getCanvasRenderingContext2D();

    this.Canvas.addEventListener('click', this.onClick, false);
    this.Canvas.addEventListener('pointermove', this.onPointerMove, false);
    this.Canvas.addEventListener('pointerdown', this.onPointerDown, false);
    this.Canvas.addEventListener('pointerup', this.onPointerUp, false);
    this.Canvas.width = 640;
    this.Canvas.height = 480;

    this.m_watermarkRenderer = null;
  }

  public dispose() {
    super.dispose();
    this.Canvas.removeEventListener('click', this.onClick);
    this.Canvas.removeEventListener('pointermove', this.onPointerMove);
    this.Canvas.removeEventListener('pointerdown', this.onPointerDown, false);
    this.Canvas.removeEventListener('pointerup', this.onPointerUp, false);
  }

  public render(imageSource: IVideoFrame): void {
    //Do not try to render in an empty space
    if (this.isCanvasEmpty()) {
      return;
    }

    const displayedResolution = imageSource.Resolution.fitInto(this.Resolution);

    if (this.m_videoWatermarkConfig !== VideoWatermarkConfig.Disabled &&
      (!displayedResolution.equals(this.m_displayedResolution) || this.m_watermarkRenderer === null)) {
      this.m_watermarkRenderer = new VideoWatermarkingRenderer(this.m_videoWatermarkConfig,
        displayedResolution.Width,
        displayedResolution.Height);
    }
    this.m_displayedResolution = displayedResolution;
    this.m_context.drawImage(imageSource.Image, 0, 0, this.m_displayedResolution.Width, this.m_displayedResolution.Height);

    this.m_watermarkRenderer?.drawVideoWatermarkingOverlay(this.m_context, 0, 0);
  }

  public drawRectangle(rectangle: Rectangle): void {
    // Sets the color of the rectangle outer edge to red
    this.m_context.strokeStyle = PreviewCanvas.m_rectangleColor;

    const fillRect = rectangle.clip(new Rectangle(new TopLeftCoords(0, 0), this.m_displayedResolution));

    // 1 pixel is removed from the width and height of the rectangle because the coordinates represent the middle of the line (which is 1px wide).
    // Without this removal, half the stroke exceeds the image we're drawing on, and leaves a red artifact on the image.
    this.m_context.strokeRect(fillRect.X, fillRect.Y, fillRect.Width - 1, fillRect.Height - 1);
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void {
    this.m_videoWatermarkConfig = videoWatermarkingConfig;
    if (this.m_videoWatermarkConfig === VideoWatermarkConfig.Disabled) {
      this.m_watermarkRenderer = null;
    }
  }

  public clear(): void {
    this.m_context.clearRect(0, 0, this.Canvas.width, this.Canvas.height);
  }

  private readonly onClick = (mouseEvent: MouseEvent): void => {
    //Do not try to render in an empty space
    if (this.isCanvasEmpty()) {
      this.Logger.warn?.trace('Returning due to empty canvas');
      return;
    }

    const coords = new Coords(mouseEvent.offsetX, mouseEvent.offsetY);
    this.m_onClick.trigger(coords);
  }

  private readonly onPointerDown = (event: PointerEvent): void => {
    //The displayed image can sometime not fill the canvas. Let the event pass to the ctrl under it.
    if (event.offsetX > this.m_displayedResolution.Width ||
      event.offsetY > this.m_displayedResolution.Height) {
      return;
    }

    // Capture the mouse to ensure we can move even outside the box
    this.Canvas.setPointerCapture(event.pointerId);

    // Prevent the DragObserver to process the event in double
    event.stopPropagation();
  }

  private readonly onPointerUp = (event: PointerEvent): void => {
    this.Canvas.releasePointerCapture(event.pointerId);

    // Prevent the DragObserver to process the event in double
    event.stopPropagation();
  }

  private readonly onPointerMove = (event: PointerEvent): void => {
    if (this.isCanvasEmpty()) {
      return;
    }
    //We can't compute the clipSpaceCoordinates if no image was ever rendered
    if (this.m_displayedResolution.IsNone) {
      return;
    }

    //Is main mouse button down
    if ((event.buttons & 1) !== 1) {
      return;
    }

    // Constraint the location inside the box
    const x = Math.min(Math.max(0, event.offsetX), this.m_displayedResolution.Width);
    const y = Math.min(Math.max(0, event.offsetY), this.m_displayedResolution.Height);

    const clickCoords = new TopLeftPixelCoords(x, y, this.m_displayedResolution);
    const clipSpaceCoords = clickCoords.toClipSpaceVec2();
    this.Logger.debug?.trace(`dragging at ${clickCoords.toString()} on image ${this.m_displayedResolution.toString()}. Translates to ${clipSpaceCoords.toString()}`);
    this.m_onClick.trigger(new Coords(x, y));

    // Prevent the DragObserver to process the event in double
    event.stopPropagation();
  }

  private getCanvasRenderingContext2D(): CanvasRenderingContext2D {
    const context = this.Canvas.getContext('2d'/*, { alpha: false }*/); //Might help performance but leaves a black area when we don't use the whole canvas
    if (context === null) {
      throw new Error('Failed to obtain preview 2d context');
    }
    return context;
  }

  private isCanvasEmpty(): boolean {
    return this.Canvas.clientWidth === 0 || this.Canvas.clientHeight === 0;
  }

  public debugStatus(indent: number): string {
    return super.debugStatus(indent) + Utils.indentNewLine(indent) +
      'Preview displayed resolution: ' + this.m_displayedResolution.toString();
  }
}
