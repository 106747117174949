<gen-melted-modal id="sendFeedbackSuccessModal" [genIsBlocking]="true" [genCanClose]="true" appFocusModalWhenOpen appKeydownStopPropagation>
    <gen-melted-modal-body>
        <div class="body-container">
            <div class="body-container__title">{{ 'STE_MESSAGE_INFO_SEND_FEEDBACK_SUCCESS_TITLE' | translate }}</div>
            <div class="body-container__icon-container">
                <gen-icon [icon]="Icon.Checkmark"></gen-icon>
            </div>

            <div class="body-container__message mt-2 mb-2">{{ 'STE_MESSAGE_INFO_SEND_FEEDBACK_SUCCESS_DESCRIPTION' | translate }}</div>
            <gen-button class="mt-4" (click)="onClose()" [flavor]="ButtonFlavor.Solid">{{ 'STE_BUTTON_CLOSE' | translate }}</gen-button>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
