import { Component, OnInit } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { HitClient, IHitRule } from '@modules/lpr/api/api';
import { LprContentFields } from '@modules/lpr/enumerations/lpr-content-fields';
import { LprContentTypes } from '@modules/lpr/enumerations/lpr-content-types';
import { TranslateService } from '@ngx-translate/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';

const isContentSupported = (content: Content): boolean => {
    return (
        content.type.equals(LprContentTypes.HitEvent) &&
        content.parameters != null &&
        (content.parameters.hasField(LprContentFields.LprRuleId) || content.parameters.hasField(LprContentFields.HitType))
    );
};

@InternalContentPluginDescriptor({
    type: HitEventWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: HitEventWidgetComponent.pluginId, priority: 1 },
    isContentSupported,
})
@Component({
    selector: 'app-hit-event-widget',
    templateUrl: './hit-event-widget.component.html',
    styleUrls: ['./hit-event-widget.component.scss'],
})
export class HitEventWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('9abccd14-bd66-415d-89fd-643e41cde116');

    public readonly TextFlavor = TextFlavor;

    public dataContext?: ContentService;
    public content?: Content;
    public hitRule$?: Observable<IHitRule>;

    public hitFields: { key: string; value: string }[] = [];

    constructor(private hitClient: HitClient, trackingService: TrackingService, private translateService: TranslateService) {
        super(trackingService);
    }

    setContent(content: Content): void {
        this.content = content;
    }
    setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext as ContentService;
    }

    ngOnInit() {
        this.initializeHitFields();
    }

    private initializeHitFields(): void {
        if (this.content?.parameters) {
            if (this.content.parameters.hasField(LprContentFields.LprRuleId)) {
                const lprRuleId = this.content.parameters.getField<string>(LprContentFields.LprRuleId);
                if (lprRuleId) {
                    const { success, value } = SafeGuid.tryParse(lprRuleId);
                    if (success) {
                        this.hitRule$ = this.hitClient.hitRule(value).pipe(first());
                    }
                }
            }

            if (this.content.parameters.hasField(LprContentFields.HitType)) {
                this.hitFields.push({ key: this.translateService.instant('STE_LABEL_HIT_TYPE') as string, value: this.content.parameters.getField(LprContentFields.HitType) });
            }
        }
    }
}
