// Autogenerated: v2/help/Entities/ApiPatroller?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';
import { ILocatableEntity, LocatableEntityFields } from './ILocatableEntity';
import { IRestResponse } from './IRestResponse';

// #region Fields

export class PatrollerEntityFields extends LocatableEntityFields {
    public static softwareVersionField = 'SoftwareVersion';
}

// #endregion Fields

export interface IPatrollerEntity extends ILocatableEntity {
    // #region REST Properties

    readonly softwareVersion: string;

    // #endregion REST Properties

    // #region REST Methods

    moveUnitAsync(id: IGuid, transaction?: ITransaction): Promise<IRestResponse | null>;
    getUnitsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

