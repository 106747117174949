import { ElementRef, forwardRef, Inject, Injectable, Renderer2 } from '@angular/core';
import { AppTooltipComponent } from '@modules/shared/components/tooltip/app-tooltip.component';
import { SharedModule } from '@modules/shared/shared.module';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW } from '@src/app/utilities';
import { FullscreenService } from '../fullscreen/fullscreen.service';
import { LoggerService } from '../logger/logger.service';

@UntilDestroy()
@Injectable({
    providedIn: forwardRef(() => SharedModule),
})
export class TooltipService {
    public static designSystemProvider: HTMLElement | null;
    private renderer?: Renderer2;
    private appTooltipComponent?: AppTooltipComponent;
    private tooltipElement?: ElementRef<HTMLElement>;
    constructor(private loggerService: LoggerService, private fullscreenService: FullscreenService, @Inject(WINDOW) private window: Window) {}

    public registerTooltip(tooltipComponent: AppTooltipComponent, element: ElementRef<HTMLElement>, renderer: Renderer2): void {
        if (this.appTooltipComponent) {
            this.loggerService.traceWarning(`
                App's tooltip component was already registered and will be overridden! Only one tooltip needs to be registered for the whole app.`);
        }
        this.appTooltipComponent = tooltipComponent;
        this.tooltipElement = element;
        this.renderer = renderer;
    }

    public showTooltip(message: string, element: HTMLElement): void {
        if (!this.appTooltipComponent || !this.renderer || !this.tooltipElement?.nativeElement) {
            this.loggerService.traceWarning('No tooltip component was registered, cannot use service to show a tooltip');
            return;
        }

        this.ensureCorrectParentElement();
        this.registerOnceCloseOnMouseLeave(element);

        this.appTooltipComponent.show(message, element);
    }

    private registerOnceCloseOnMouseLeave(element: HTMLElement): void {
        const mouseLeaveSubscription = this.renderer?.listen(element, 'mouseleave', () => {
            try {
                this.appTooltipComponent?.close();
            } finally {
                // Unsubscribe after a single event
                mouseLeaveSubscription?.();
            }
        });
    }

    private ensureCorrectParentElement(): void {
        if (!this.tooltipElement || !this.renderer) {
            return;
        }

        const tooltipDOMElement = this.tooltipElement.nativeElement;
        const parentElement = this.fullscreenService.isFullscreenOn ? this.fullscreenService.currentFullScreenElement : this.window.document.body;

        if (parentElement && tooltipDOMElement.parentElement !== parentElement) {
            this.renderer.appendChild(parentElement, tooltipDOMElement);
        }
    }
}
