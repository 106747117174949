import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BrowserItem } from './items/browser-item';

// Directive that outputs an event when the element's width or height changes
@Directive({
    selector: '[appEntityBrowserKeyboardNavigation]',
})
export class EntityBrowserKeyboardNavigationDirective implements OnChanges {
    @Input() public browserItem!: BrowserItem;

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.browserItem !== 'undefined') {
            const change = changes.browserItem;
            const browserItem = change.currentValue as BrowserItem;
            if (browserItem && change.previousValue !== browserItem) {
                this.browserItem = browserItem;
                this.browserItem.htmlElement = this.elementRef.nativeElement as HTMLElement;
            }
        }
    }
}
