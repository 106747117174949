/* eslint-disable no-console */
import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@utilities/common-helper';
import { LogSeverity } from 'RestClient/Client/Enumerations/LogSeverity';

const noop = (): any => undefined;
const prefix = '👉⚡👈 SCW - ';

declare global {
    interface Window {
        scwLogSeverity: Array<string>;
        scwSetAllLoggers: () => Array<string>;
        scwSetFullLoggers: () => Array<string>;
        scwClearLoggers: () => Array<string>;
        scwToggleLogServerSide: () => boolean;
        scwToggleSpecificLogger: (logger: string) => Array<string>;
    }
}

@Injectable()
export class LoggerService {
    public traceInformation: (message?: any, ...optionalParams: any[]) => void = noop;
    public traceDebug: (message?: any, ...optionalParams: any[]) => void = noop;
    public traceError: (message?: any, ...optionalParams: any[]) => void = noop;
    public traceFatal: (message?: any, ...optionalParams: any[]) => void = noop;
    public tracePerformance: (message?: any, ...optionalParams: any[]) => void = noop;
    public traceWarning: (message?: any, ...optionalParams: any[]) => void = noop;

    public logServerSide = false;

    public currentLogSeverities: Array<string>;

    constructor(@Inject('logSeverities') private logSeverities: Array<string>, @Inject(WINDOW) window: Window) {
        this.currentLogSeverities = this.logSeverities;
        this.setAvailableLoggers();

        window.scwLogSeverity = this.currentLogSeverities;
        window.scwSetAllLoggers = this.setAllLoggers.bind(this);
        window.scwSetFullLoggers = this.setFullLoggers.bind(this);
        window.scwClearLoggers = this.clearLoggers.bind(this);
        window.scwToggleSpecificLogger = this.toggleSpecificLogger.bind(this);
        window.scwToggleLogServerSide = this.toggleLogServerSide.bind(this);
    }

    public setAvailableLoggers(): void {
        if (this.currentLogSeverities.includes(LogSeverity.None)) {
            return;
        }

        if (this.currentLogSeverities.includes(LogSeverity.All)) {
            this.setAllLoggers();
        } else if (this.currentLogSeverities.includes(LogSeverity.Full)) {
            this.setFullLoggers();
        } else {
            this.setCurrentLoggers();
        }
    }

    public toggleSpecificLogger(logger: string): string[] {
        // First letter needs to be capitalized to match the enum
        const capitalizedLogger = logger.charAt(0).toUpperCase() + logger.slice(1);

        if (this.currentLogSeverities.includes(capitalizedLogger)) {
            this.currentLogSeverities.splice(this.currentLogSeverities.indexOf(capitalizedLogger), 1);
        } else {
            this.currentLogSeverities.push(capitalizedLogger);
        }

        this.setCurrentLoggers();

        return this.currentLogSeverities;
    }

    public setAllLoggers(): string[] {
        this.clearLoggers();

        this.currentLogSeverities.push(LogSeverity.Debug, LogSeverity.Error, LogSeverity.Fatal);

        this.setTraceDebug();
        this.setTraceError();
        this.setTraceFatal();

        return this.currentLogSeverities;
    }

    public setFullLoggers(): string[] {
        this.clearLoggers();

        this.currentLogSeverities.push(LogSeverity.Debug, LogSeverity.Error, LogSeverity.Fatal, LogSeverity.Information, LogSeverity.Performance, LogSeverity.Warning);

        this.setTraceDebug();
        this.setTraceError();
        this.setTraceFatal();
        this.setTraceInformation();
        this.setTracePerformance();
        this.setTraceWarning();

        return this.currentLogSeverities;
    }

    public setCurrentLoggers(): void {
        this.currentLogSeverities.forEach((x) => {
            switch (x) {
                case LogSeverity.Debug:
                    this.setTraceDebug();
                    break;
                case LogSeverity.Error:
                    this.setTraceError();
                    break;
                case LogSeverity.Fatal:
                    this.setTraceFatal();
                    break;
                case LogSeverity.Information:
                    this.setTraceInformation();
                    break;
                case LogSeverity.Performance:
                    this.setTracePerformance();
                    break;
                case LogSeverity.Warning:
                    this.setTraceWarning();
                    break;
            }
        });
    }

    public clearLoggers(): string[] {
        this.currentLogSeverities.length = 0;
        this.traceDebug = noop;
        this.traceError = noop;
        this.traceFatal = noop;
        this.traceInformation = noop;
        this.tracePerformance = noop;
        this.traceWarning = noop;

        return this.currentLogSeverities;
    }

    public setTraceDebug(): void {
        const fullTracePrefix = prefix + 'DEBUG:';
        if (this.logServerSide) {
            this.traceDebug = (message: string) => {
                // eslint-disable-next-line no-console
                console.debug(fullTracePrefix + ' ' + message);
                // TODO: Log server side
            };
        } else {
            this.traceDebug = Function.prototype.bind.call(console.debug, console, fullTracePrefix) as typeof console.debug;
        }
    }

    public setTraceError(): void {
        const fullTracePrefix = prefix + 'ERROR:';
        if (this.logServerSide) {
            this.traceError = (message: string) => {
                console.error(fullTracePrefix + ' ' + message);
                // TODO: Log server side
            };
        } else {
            this.traceError = Function.prototype.bind.call(console.error, console, fullTracePrefix) as typeof console.debug;
        }
    }

    public setTraceFatal(): void {
        const fullTracePrefix = prefix + 'FATAL:';
        if (this.logServerSide) {
            this.traceFatal = (message: string) => {
                // eslint-disable-next-line no-console
                console.error(fullTracePrefix + ' ' + message);
                // TODO: Log server side
            };
        } else {
            this.traceFatal = Function.prototype.bind.call(console.error, console, fullTracePrefix) as typeof console.error;
        }
    }

    public setTraceInformation(): void {
        const fullTracePrefix = prefix + 'INFO:';
        if (this.logServerSide) {
            this.traceInformation = (message: string) => {
                // eslint-disable-next-line no-console
                console.info(fullTracePrefix + ' ' + message);
                // TODO: Log server side
            };
        } else {
            this.traceInformation = Function.prototype.bind.call(console.info, console, fullTracePrefix) as typeof console.info;
        }
    }

    public setTracePerformance(): void {
        const fullTracePrefix = prefix + 'PERF:';
        if (this.logServerSide) {
            this.tracePerformance = (message: string) => {
                // eslint-disable-next-line no-console
                console.debug(fullTracePrefix + ' ' + message);
                // TODO: Log server side
            };
        } else {
            this.tracePerformance = Function.prototype.bind.call(console.debug, console, fullTracePrefix) as typeof console.debug;
        }
    }

    public setTraceWarning(): void {
        const fullTracePrefix = prefix + 'WARN:';
        if (this.logServerSide) {
            this.traceWarning = (message: string) => {
                // eslint-disable-next-line no-console
                console.warn(fullTracePrefix + ' ' + message);
                // TODO: Log server side
            };
        } else {
            this.traceWarning = Function.prototype.bind.call(console.warn, console, fullTracePrefix) as typeof console.warn;
        }
    }

    public toggleLogServerSide(): boolean {
        this.logServerSide = !this.logServerSide;
        this.setAvailableLoggers();

        return this.logServerSide;
    }
}
