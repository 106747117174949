<div class="hit-event" *ngIf="hitRule$ | async as hitRule; else loading">
    <div class="hit-event__header">
        <gen-item>
            <span slot="gen-icon" class="hit-event__header-color" *ngIf="hitRule.color" [style.backgroundColor]="hitRule.color | argb"></span>
            <gen-text>{{ hitRule.name }}</gen-text>
        </gen-item>
    </div>

    <div class="hit-event__fields">
        <div *ngFor="let hitField of hitFields | sortBy: 'asc':'key'" class="hit-event__fields-entry">
            <gen-text [flavor]="TextFlavor.Label">{{ hitField.key | titlecase }}</gen-text>
            <gen-text>{{ hitField.value }}</gen-text>
        </div>
    </div>
</div>

<!-- Loading -->
<ng-template #loading>
    <div class="ha-center va-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
