import { GuidSet, IGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { EntityTypeData, IEntityTypeData } from '../../models/entity-type-data';
import { IHierarchicalEntityBrowserFilter } from '../interfaces/filters/hiearchical-entity-browser-filter.interface';
import { IEntityBrowserFilterData } from '../interfaces/filters/entity-browser-filter-data.interface';
import { ExpanderFilterData, IExpanderFilterData } from '../../models/expander-filter-data';
import { IEntityBrowserFilter } from '../interfaces/filters/entity-browser-filter.interface';
import { HierarchicalEntityBrowserFilterData } from './entity-browser-filter.data';
import { EntityBrowserFilter } from './entity-browser-filter';

export class HierarchicalEntityBrowserFilter extends EntityBrowserFilter implements IHierarchicalEntityBrowserFilter {
    public entityExpanderTypes = new Map<EntityTypeData, IGuid>();
    public expandedParents = new GuidSet();
    public isRootExpanded = true;

    public get isHierarchical(): boolean {
        return true;
    }

    constructor(entityTypes?: IEntityTypeData[] | Iterable<IEntityTypeData> | string[] | Iterable<string>) {
        super(entityTypes);
    }

    public clone(filter?: IEntityBrowserFilter): IEntityBrowserFilter {
        const clonedFilter = super.clone(filter) as HierarchicalEntityBrowserFilter;
        clonedFilter.entityExpanderTypes = new Map<EntityTypeData, IGuid>(this.entityExpanderTypes);
        clonedFilter.expandedParents = new GuidSet(this.expandedParents);
        clonedFilter.isRootExpanded = this.isRootExpanded;
        return clonedFilter;
    }

    protected createFilter(): EntityBrowserFilter {
        return new HierarchicalEntityBrowserFilter();
    }

    protected createRestParam(): IEntityBrowserFilterData {
        return new HierarchicalEntityBrowserFilterData();
    }

    protected fillRestParam(param: IEntityBrowserFilterData): void {
        super.fillRestParam(param);

        if (param instanceof HierarchicalEntityBrowserFilterData) {
            const expanderDataCollection = new ObservableCollection<IExpanderFilterData>();
            for (const [key, value] of this.entityExpanderTypes) {
                const expanderData = new ExpanderFilterData();
                expanderData.entityTypeData = key;
                expanderData.expanderType = value;
                expanderDataCollection.add(expanderData);
            }

            param.expanderData = expanderDataCollection;

            param.expandedParents = new ObservableCollection<IGuid>();
            for (const guid of this.expandedParents) {
                param.expandedParents.add(guid);
            }

            param.isRootExpanded = this.isRootExpanded;
        }
    }
}
