<gen-design-system-provider>
    <body>
        <div *ngIf="(authService.loggedIn$ | async) === true; else loggedOut">
            <gen-app [genIsMaster]="true">
                <gen-header class="main-header">
                    <div class="header-bar pl-2 pr-2">
                        <ul (click)="onHamburgerMenuClick()">
                            <li *ngIf="(navBarService.isPinned$ | async) === false">
                                <gen-button id="burger-menu-button" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_LABEL_TASKS' | translate }}" data-cy="burger-menu-button">
                                    <gen-icon class="menu-burger" [icon]="Icon.Burger"></gen-icon>
                                </gen-button>
                            </li>
                            <li>
                                <img class="menu-burger-logo ml-1" src="assets/images/Genetec Web App White.svg" [alt]="'STE_TOOLTIP_GENETEC_WEBAPP_LOGO' | translate" />
                            </li>
                        </ul>

                        <ul class="tray-plugins">
                            <ng-container class="tray-plugin" *ngFor="let trayPlugin of mainTrayPlugins">
                                <li>
                                    <app-plugin-host [pluginItem]="trayPlugin"></app-plugin-host>
                                </li>
                            </ng-container>
                            <li>
                                <gen-button
                                    *ngIf="secondaryTrayPlugins?.length"
                                    id="btn-toggle-secondary-tray"
                                    class="tray-more-button"
                                    appTooltip="{{ 'STE_BUTTON_SHOWMORE' | translate }}"
                                    [flavor]="ButtonFlavor.Flat"
                                    (click)="onShowSecondaryTrayClicked()"
                                >
                                    <gen-icon [icon]="Icon.AngleDown"></gen-icon>
                                </gen-button>
                            </li>
                            <li>
                                <!-- user profile -->
                                <app-profile></app-profile>
                            </li>
                        </ul>
                    </div>
                </gen-header>

                <app-portal-outlet class="global-portal-outlet" *ngIf="isPortalEnabled$ | async"></app-portal-outlet>

                <div class="main-app-side-pane gen-sidepane right vertical" id="appRightSidepane">
                    <div class="gen-sidepane-body">
                        <app-shell-side-pane class="h-100"></app-shell-side-pane>
                    </div>
                </div>

                <gen-main>
                    <!-- primary outlet -->
                    <router-outlet></router-outlet>

                    <!-- modal outlet -->
                    <router-outlet name="modal"></router-outlet>
                </gen-main>

                <!-- Nav-bar-->
                <app-nav-bar></app-nav-bar>
            </gen-app>

            <!-- secondary tray popup -->
            <gen-popup [position]="PopupPosition.BottomRight" [staysOpen]="false" targetSelector="#btn-toggle-secondary-tray">
                <div *ngFor="let trayPlugin of secondaryTrayPlugins" style="display: inline-block">
                    <app-plugin-host [pluginItem]="trayPlugin"></app-plugin-host>
                </div>
            </gen-popup>
        </div>

        <ng-template #loggedOut>
            <ng-container *ngIf="!authService.isLoggingOn && (authService.loggedIn$ | async) === false">
                <app-login></app-login>
            </ng-container>
        </ng-template>
        <app-loading-screen></app-loading-screen>
        <app-app-tooltip></app-app-tooltip>
    </body>
</gen-design-system-provider>
