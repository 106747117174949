import { Injectable, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { IGuid } from 'safeguid';
import { AsyncEventDispatcher } from 'RestClient/Helpers/Helpers';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TilePatternItem } from '../models/tile-pattern-item';
import { SecurityCenterStateService } from '../../shared/services/state/security-center-state.service';
import { ContentGroup } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { MethodEmitter, StateEmitter } from '../../../store';
import { TileCanvasState, defaultTilePattern, ContentChange, ContentMove } from './tile-canvas.state';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface PendingContentChange extends ContentChange {
    changesAccepted: boolean;
}

@UntilDestroy()
@Injectable()
export class TileCanvasStateService extends SecurityCenterStateService implements OnDestroy {
    public static get defaultTilePattern(): TilePatternItem {
        return defaultTilePattern;
    }

    /* eslint-disable @typescript-eslint/unbound-method */

    @Select(TileCanvasState.contentUpdateId)
    public contentUpdateIdChanged$!: Observable<IGuid>;

    @SelectSnapshot(TileCanvasState.contents)
    public contents!: (ContentGroup | null)[];

    @Select(TileCanvasState.tilePattern)
    public tilePatternChanged$!: Observable<TilePatternItem>;

    @Select(TileCanvasState.selectedTile)
    public selectedTileChanged$!: Observable<number>;

    @SelectSnapshot(TileCanvasState.selectedTile)
    public selectedTile!: number | undefined;

    @MethodEmitter(TileCanvasState.clearTiles)
    public clearTiles!: StateEmitter<typeof TileCanvasState.clearTiles>;

    @MethodEmitter(TileCanvasState.selectTile)
    public selectTile!: StateEmitter<typeof TileCanvasState.selectTile>;

    @MethodEmitter(TileCanvasState.setTilePattern)
    public setTilePattern!: StateEmitter<typeof TileCanvasState.setTilePattern>;

    @MethodEmitter(TileCanvasState.moveContent)
    public emitMoveContent!: StateEmitter<typeof TileCanvasState.moveContent>;

    @MethodEmitter(TileCanvasState.changeContent)
    public emitChangeContent!: StateEmitter<typeof TileCanvasState.changeContent>;

    public get contentsChanged$(): Subject<(ContentGroup | null)[]> {
        this.setContentUpdateIdSubscription();
        return this.internalContentsChanged$;
    }

    public readonly contentMoved$ = new Subject<ContentMove>();
    public readonly contentChanging$ = new AsyncEventDispatcher<PendingContentChange>();

    private contentUpdateIdSubscription?: Subscription;
    private internalContentsChanged$ = new Subject<(ContentGroup | null)[]>();

    constructor(securityCenterProvider: SecurityCenterClientService) {
        super(securityCenterProvider);
    }

    public clearTile(tileId: number): void {
        this.clearTiles([tileId]);
    }

    public moveContent(move: ContentMove): void {
        this.contentMoved$.next(move);
        this.emitMoveContent(move);
    }

    public ngOnDestroy() {
        this.clearState();
    }

    public changeContent(tileId: number, content: ContentGroup): void {
        if (content) {
            const index = tileId - 1;
            const contents = this.contents;
            if (index > -1 && index < contents.length) {
                contents[index] = content;
                const contentChange = {
                    tileId,
                    content,
                    changesAccepted: false,
                };
                this.handleChangeContentAsync(contentChange).catch(() => {});
            }
        }
    }

    protected clearState(): void {
        super.clearState();
        this.clearContentUpdateIdSubscription();
        this.clearTiles(null);
    }

    private clearContentUpdateIdSubscription(): void {
        if (this.contentUpdateIdSubscription) {
            this.contentUpdateIdSubscription.unsubscribe();
            this.contentUpdateIdSubscription = undefined;
        }
    }

    private async handleChangeContentAsync(contentChange: PendingContentChange): Promise<void> {
        await this.contentChanging$.dispatch(contentChange);
        if (contentChange.changesAccepted) {
            this.emitChangeContent(contentChange);
        }
    }

    private setContentUpdateIdSubscription(): void {
        if (!this.contentUpdateIdSubscription) {
            this.contentUpdateIdSubscription = this.contentUpdateIdChanged$.pipe(untilDestroyed(this)).subscribe(() => this.internalContentsChanged$.next(this.contents));
        }
    }
}
