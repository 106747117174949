import { IWebSocketRequest } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';
import { Camera } from '../../camera';
import { Token } from '../../../services/Token';
import { Serializer } from '../../../utils/Serializer';
import { VideoFormat, SupportedVideoFormats } from '../SupportedVideoFormats';
import { Utils } from '../../../utils/Utils';

/**
 * @beta
 * */
export class CreateStreamRequest implements IWebSocketRequest {
  private static readonly command = 'CreateStream';

  private readonly m_header: WebSocketMessageHeader;

  private readonly m_body: CreateStreamRequestBody;

  public get channel(): number {
    return this.m_header.channel;
  }

  public get expectResponse() {
    return true;
  }

  public get body(): CreateStreamRequestBody {
    return this.m_body;
  }

  constructor(channel: number, cameraId: Camera, accessToken: Token, supportedFormats: SupportedVideoFormats, clientVersion: string) {
    this.m_header = new WebSocketMessageHeader(channel, CreateStreamRequest.command);
    this.m_body = new CreateStreamRequestBody(cameraId, accessToken, supportedFormats, clientVersion);
  }

  public serialize(): ArrayBuffer {
    const header = new DataView(this.m_header.serialize());
    const body = new DataView(this.m_body.serialize());

    return Serializer.combineDataView(header, body);
  }

  public debugStatus(indent: number): string {
    return this.m_body.debugStatus(indent);
  }
}

/**
 * @beta
 * */
export class CreateStreamRequestBody {
  public SupportedFormats: VideoFormat[];
  public CameraId: string;
  public AccessToken: string;
  public ClientVersion: string;

  constructor(cameraId: Camera, accessToken: Token, supportedFormats: SupportedVideoFormats, clientVersion: string) {
    this.SupportedFormats = supportedFormats.getAvailableFormats();
    this.CameraId = cameraId.Id;
    this.AccessToken = accessToken.Value;
    this.ClientVersion = clientVersion;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public debugStatus(indent: number): string {
    return 'CreateStreamRequest' + Utils.indentNewLine(indent) +
      'SupportedVideoFormat: [' + this.SupportedFormats.join(', ') + ']' + Utils.indentNewLine(indent) +
      'CameraId: ' + this.CameraId + Utils.indentNewLine(indent) +
      'AccessToken: ' + this.AccessToken + Utils.indentNewLine(indent) +
      'ClientVersion: ' + this.ClientVersion;
  }
}

/**
 * @beta
 * */
export enum StreamUsage {
  Live = 'Live',
  LowResolution = 'LowResolution',
  HighResolution = 'HighResolution',
  Remote = 'Remote',
  Recording = 'Recording',
  Automatic = 'Automatic' // review if we want another enum without automatic to prevent invalid enum values where it is not allowed
}
