import { Coords } from './Coords';
import { Resolution } from './Resolution';
import { ClipSpaceVec2, Vec2 } from './Vec2';

//Coordinates in a [0, 1] space
export class NormalizedCoords extends Coords {
  public static readonly Origin = new NormalizedCoords(0, 0);

  public constructor(x: number, y: number) {
    super(x, y);
  }

  public toPixel(resolution: Resolution): Vec2 {
    return new Vec2(this.m_x * resolution.Width, this.m_y * resolution.Height);
  }

  public toClipSpaceVec2(): ClipSpaceVec2 {
    return new ClipSpaceVec2(this.m_x * 2 - 1, this.m_y * 2 - 1);
  }
}
