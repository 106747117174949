<ng-template #template>
    <ng-container *ngFor="let item of dataSource ?? []">
        <ng-container *ngIf="item.children">
            <gen-tree-item [selected]="item.isChecked" (selectedChange)="itemCallbackToUse(item, $event)">
                <gen-item>
                    <gen-icon *ngIf="item?.icon" [icon]="item.icon"></gen-icon>
                    <gen-text>{{ item.text }}</gen-text>
                </gen-item>
                <ng-container *ngTemplateOutlet="treeItemWithChildren; context: { children: item.children }"></ng-container>
            </gen-tree-item>
        </ng-container>
        <ng-container *ngIf="!item.children">
            <ng-container *ngTemplateOutlet="regularTreeItem; context: { item: item }"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #regularTreeItem let-item="item">
    <!-- Only leaf items can be clicked. -->
    <gen-tree-item [selected]="item.isChecked" (selectedChange)="itemCallbackToUse(item, $event)">
        <gen-item>
            <gen-icon *ngIf="item?.icon" [icon]="item.icon"></gen-icon>
            <gen-text> {{ item.text }}</gen-text>
        </gen-item>
    </gen-tree-item>
</ng-template>

<ng-template #treeItemWithChildren let-children="children">
    <gen-tree>
        <ng-container *ngFor="let child of children">
            <ng-container *ngIf="child.children">
                <gen-tree-item [selected]="child.isChecked" (selectedChange)="itemCallbackToUse(child, $event)">
                    <gen-item>
                        <gen-icon *ngIf="child?.icon" [icon]="child.icon"></gen-icon>
                        <gen-text> {{ child.text }}</gen-text>
                    </gen-item>
                    <!-- Recurse. -->
                    <ng-container *ngTemplateOutlet="treeItemWithChildren; context: { children: child.children }"></ng-container>
                </gen-tree-item>
            </ng-container>
            <ng-container *ngIf="!child.children">
                <ng-container *ngTemplateOutlet="regularTreeItem; context: { item: child }"></ng-container>
            </ng-container>
        </ng-container>
    </gen-tree>
</ng-template>
