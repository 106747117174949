// Autogenerated: CodeGen/AlarmsController?language=ts&InterfaceOnly=False on 2021-04-21 3:54:19 PM
import { IGuid, SafeGuid } from 'safeguid';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { RestObject } from 'RestClient/Client/Model/RestObject';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { IAlarmAcknowledgeAction, IAlarmCountResult, IAlarmResult, IAlarmsController } from './alarms.controller.interfaces';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { AlarmInstance, IAlarmInstance } from './alarm-instance';

export class AlarmAcknowledgeAction extends FieldObject implements IAlarmAcknowledgeAction {
    // InstanceId
    public get instanceId(): number {
        return this.getField<number>('InstanceId');
    }
    public set instanceId(value: number) {
        this.setField<number>('InstanceId', value);
    }
    // AlarmId
    public get alarmId(): IGuid {
        return this.getFieldGuid('AlarmId');
    }
    public set alarmId(value: IGuid) {
        this.setFieldGuid('AlarmId', value);
    }
    // alternate
    public get alternate(): boolean {
        return this.getField<boolean>('alternate');
    }
    public set alternate(value: boolean) {
        this.setField<boolean>('alternate', value);
    }
    // force
    public get force(): boolean {
        return this.getField<boolean>('force');
    }
    public set force(value: boolean) {
        this.setField<boolean>('force', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('InstanceId', 0);
        this.setFieldGuid('AlarmId', SafeGuid.EMPTY);
        this.setField<boolean>('alternate', false);
        this.setField<boolean>('force', false);
    }
}

export class AlarmCountResult extends FieldObject implements IAlarmCountResult {
    // Count
    public get count(): number {
        return this.getField<number>('Count');
    }
    public set count(value: number) {
        this.setField<number>('Count', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Count', 0);
    }
}

export class AlarmResult extends FieldObject implements IAlarmResult {
    // AlarmInstances
    public get alarmInstances(): ObservableCollection<IAlarmInstance> {
        return this.getFieldObjectArray<AlarmInstance, IAlarmInstance>(AlarmInstance, 'AlarmInstances');
    }
    public set alarmInstances(value: ObservableCollection<IAlarmInstance>) {
        this.setFieldObjectArray('AlarmInstances', value);
    }
    // EntityLookup
    public get entityLookup(): Map<string, IFieldObject> {
        return this.getFieldObjectDictionary<string, FieldObject, IFieldObject>(FieldObject, 'EntityLookup', undefined, 'string');
    }
    public set entityLookup(value: Map<string, IFieldObject>) {
        this.setFieldObjectDictionary('EntityLookup', value);
    }

    public initializeAllFields(): void {
        this.setFieldArray<IAlarmInstance>('AlarmInstances', new ObservableCollection<IAlarmInstance>());
        this.setFieldDictionary('EntityLookup', new Map<string, IFieldObject>());
    }
}

export class AlarmsController extends RestObject implements IAlarmsController {
    constructor() {
        super('api/Alarms');
        this._canGet = false;
    }

    // #region Rest Methods

    public async acknowledgeAsync(value: IAlarmAcknowledgeAction, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('Acknowledge', 'POST', value, transaction, responseHandler);
    }

    public async getActiveInstancesCountAsync(transaction?: ITransaction | null): Promise<IAlarmCountResult | null> {
        return await this.getDataRelationAsync<AlarmCountResult>(AlarmCountResult, 'ActiveInstancesCount', false, null, transaction);
    }

    public async forceAcknowledgeAllAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('ForceAcknowledgeAll', 'POST', null, transaction, responseHandler);
    }

    public async forwardAsync(instanceId: number, users: ObservableCollection<IGuid>, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('instanceId', instanceId);
        inParam.setFieldArrayGuid('users', users);

        return this.executeRequestTransactionAsync<IRestResponse>('Forward', 'POST', inParam, transaction, responseHandler);
    }

    public async getInstancesAsync(maxCount: number, transaction?: ITransaction | null): Promise<IAlarmResult | null> {
        return await this.getDataRelationAsync<AlarmResult>(AlarmResult, 'Instances/' + maxCount, false, null, transaction);
    }

    public async investigateAsync(instanceId: number, alarmId: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('instanceId', instanceId);
        inParam.setFieldGuid('alarmId', alarmId);

        return this.executeRequestTransactionAsync<IRestResponse>('Investigate', 'POST', inParam, transaction, responseHandler);
    }

    public async triggerAsync(alarmId: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('alarmId', alarmId);

        return this.executeRequestTransactionAsync<IRestResponse>('Trigger', 'POST', inParam, transaction, responseHandler);
    }

    // #endregion Rest Methods

}
