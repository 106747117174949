import { Injectable } from '@angular/core';
import { McClient } from '@modules/mission-control/services/mc-client.service';
import { IGuid } from 'safeguid';
import { forkJoin, from, Observable, of } from 'rxjs';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import { catchError, concatAll, filter, first, map, switchMap, tap, toArray } from 'rxjs/operators';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { WebAppClient } from 'WebClient/WebAppClient';
import { LinkedIncidentController } from '@modules/mission-control/controllers/linked-incident.controller';
import { ILinkedIncidentController } from '@modules/mission-control/controllers/incident.controller.interfaces';
import { IncidentApiService } from '@modules/mission-control/services/incident/incident-api.service';
import { IncidentFilter } from '@modules/mission-control/models/incident-filter';

@Injectable()
export class LinkedIncidentService {
    private readonly _scClient: WebAppClient;

    constructor(securityCenterProvider: SecurityCenterClientService, private _incidentApiService: IncidentApiService, private _mcClient: McClient) {
        this._scClient = securityCenterProvider.scClient;
    }

    public getLinkableIncidents(displayId: string | null, incidentFilter: IncidentFilter): Observable<MCIncident[]> {
        return this.getController().pipe(
            switchMap((controller) => from(controller.getFilteredLinkedIncidents(displayId, incidentFilter))),
            map((x) => x?.body as MCIncident[]),
            catchError((_) => of([]))
        );
    }

    public getLinkedIncidents(incidentIds: IGuid[] | null): Observable<MCIncident[]> {
        if (!incidentIds) return of([]);

        return forkJoin([...incidentIds].map((id) => this._incidentApiService.getIncident(id).pipe(catchError((_) => of(null))))).pipe(
            concatAll(),
            filter((i): i is MCIncident => i instanceof MCIncident),
            toArray()
        );
    }

    private getController(): Observable<ILinkedIncidentController> {
        return from(this._scClient.getAsync<LinkedIncidentController, ILinkedIncidentController>(LinkedIncidentController));
    }
}
