import { Injectable } from '@angular/core';
import { GenModalService } from '@genetec/gelato-angular';
import { isNullOrUndefined } from '@genetec/web-maps';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { concatAll, filter, first, map, pairwise, startWith, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { IncidentRecipientModalComponent } from '../../components/incident-recipient/incident-recipient-modal.component';
import { ChangeStateCommand } from '../../models/commands/change-state-command';
import { ForwardCommand } from '../../models/commands/forward-command';
import { ReleaseOwnershipCommand } from '../../models/commands/release-ownership-command';
import { MCIncident } from '../../models/mc-incident';
import { StateGuids } from '../../state-guids';
import { toGuid } from '../../utils/guid-utils';
import { IncidentApiService } from '../incident/incident-api.service';
import { IncidentCommandService } from '../incident/incident-command.service';
import { PriorityService } from '../priority/priority.service';
import { PrivilegeService } from '../../../shared/privilege/privilege.service';
import { IncidentSelectionService } from '../incident/incident-selection.service';
import { MCPrivileges } from './../../mc-privileges';
import { ChangePriorityCommand } from './../../models/commands/change-priority-command';
import { TakeOwnershipCommand } from './../../models/commands/take-ownership-command';
import { McUserService } from './../mc-user.service';
import { StateService } from './../state/state.service';
import { RecipientsMode } from './../../models/events/incident-event';
import { TransfertCommand } from './../../models/commands/transfer-command';

@Injectable()
export class IncidentActionService {
    private closeContextMenu$: Subject<number> = new Subject();
    constructor(
        private _incidentCommandService: IncidentCommandService,
        private _userService: McUserService,
        private _priorityService: PriorityService,
        private _privilegeService: PrivilegeService,
        private _translateService: TranslateService,
        private _incidentSelectionService: IncidentSelectionService,
        private _incidentApiService: IncidentApiService,
        private _stateService: StateService,
        private _modalService: GenModalService,
        private _loggerService: LoggerService
    ) {}

    public getActions(incident: MCIncident): Observable<ContextMenuItem[]> {
        const createItem = async (i: MCIncident, createHandler: (i: MCIncident) => Promise<ContextMenuItem | null>) => {
            try {
                const item = await createHandler(i);
                if (!item) return;
                return item;
            } catch (error) {
                this._loggerService.traceError(`Exception occured while creating incident action: ${error as string}`);
            }
        };

        return from(this._incidentSelectionService.selectIncident(incident)).pipe(
            switchMap(() => this._incidentSelectionService.selectedIncident$),
            startWith(null),
            pairwise(),
            tap(([previous, current]) => {
                if (current === null || (previous !== null && !previous.id.equals(current.id))) {
                    this.closeContextMenu();
                }
            }),
            map(([_, current]) => current),
            takeUntil(this.closeContextMenu$),
            filter((i): i is MCIncident => i instanceof MCIncident),
            switchMap((i) => {
                return forkJoin([
                    from(createItem(i, this.createChangePriorityItem.bind(this))),
                    from(createItem(i, this.createTakeOwnershipItem.bind(this))),
                    from(createItem(i, this.createReleaseOwnershipItem.bind(this))),
                    from(createItem(i, this.createChangeStateItem.bind(this))),
                    from(createItem(i, this.createForwardItem.bind(this))),
                    from(createItem(i, this.createTransferItem.bind(this))),
                ]).pipe(
                    concatAll(),
                    filter((x): x is ContextMenuItem => x !== undefined),
                    toArray()
                );
            })
        );
    }

    private closeContextMenu() {
        this.closeContextMenu$.next();
    }

    private async createChangePriorityItem(incident: MCIncident): Promise<ContextMenuItem | null> {
        if (this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.editIncidentPriority) && this._userService.isCurrentUserOwner(incident.ownerId)) {
            const priorities = await this._priorityService.getPriorities$().pipe(first()).toPromise();

            const priorityItems = priorities
                .filter((p) => !p.id.equals(incident.priorityId))
                .map((p) => {
                    return {
                        text: p.name,
                        id: p.id.toString(),
                        actionItem: {
                            execute: async (menuItem: ContextMenuItem) => {
                                await this._incidentCommandService.execute(new ChangePriorityCommand(incident.id, toGuid(menuItem.id!))).toPromise();
                                this.closeContextMenu();
                            },
                        },
                    };
                });
            return {
                id: this._translateService.instant('STE_LABEL_CHANGE_PRIORITY') as string,
                text: this._translateService.instant('STE_LABEL_CHANGE_PRIORITY') as string,
                children: priorityItems,
            };
        }
        return null;
    }

    private createTakeOwnershipItem(incident: MCIncident): Promise<ContextMenuItem | null> {
        if (!this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.takeOwnership) || this._userService.isCurrentUserOwner(incident.ownerId)) return Promise.resolve(null);
        const isIncidentAlreadyOwned = !isNullOrUndefined(incident.ownerId);
        let item: ContextMenuItem | null = null;
        if (!isIncidentAlreadyOwned) {
            const commandHandler = async () => {
                await this._incidentCommandService.execute(new TakeOwnershipCommand(incident.id)).toPromise();
                this.closeContextMenu();
            };
            item = {
                id: this._translateService.instant('STE_LABEL_TAKEOWNERSHIP') as string,
                text: this._translateService.instant('STE_LABEL_TAKEOWNERSHIP') as string,
                actionItem: {
                    execute: commandHandler,
                },
            };
        } else if (this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.overrideOwnership)) {
            const commandHandler = async () => {
                await this._incidentCommandService.execute(new TakeOwnershipCommand(incident.id)).toPromise();
                this.closeContextMenu();
            };
            item = {
                id: this._translateService.instant('STE_LABEL_TAKEOWNERSHIP_FROMUSER') as string,
                text: this._translateService.instant('STE_LABEL_TAKEOWNERSHIP_FROMUSER') as string,
                actionItem: {
                    execute: commandHandler,
                },
            };
        }
        return Promise.resolve(item);
    }

    private createReleaseOwnershipItem(incident: MCIncident): Promise<ContextMenuItem | null> {
        if (!this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.takeOwnership)) return Promise.resolve(null);
        const isIncidentAlreadyOwned = !isNullOrUndefined(incident.ownerId);
        const isIncidentOwnedByMe = this._userService.isCurrentUserOwner(incident.ownerId);
        let item: ContextMenuItem | null = null;
        if (isIncidentOwnedByMe) {
            const commandHandler = async () => {
                await this._incidentCommandService.execute(new ReleaseOwnershipCommand(incident.id)).toPromise();
                this.closeContextMenu();
            };
            item = {
                id: this._translateService.instant('STE_LABEL_RELEASEOWNERSHIP') as string,
                text: this._translateService.instant('STE_LABEL_RELEASEOWNERSHIP') as string,
                actionItem: {
                    execute: commandHandler,
                },
            };
        } else if (isIncidentAlreadyOwned && this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.overrideOwnership)) {
            const commandHandler = async () => {
                await this._incidentCommandService.execute(new ReleaseOwnershipCommand(incident.id)).toPromise();
                this.closeContextMenu();
            };
            item = {
                id: this._translateService.instant('STE_LABEL_RELEASEOWNERSHIP_FROMUSER') as string,
                text: this._translateService.instant('STE_LABEL_RELEASEOWNERSHIP_FROMUSER') as string,
                actionItem: {
                    execute: commandHandler,
                },
            };
        }
        return Promise.resolve(item);
    }

    private async createChangeStateItem(incident: MCIncident): Promise<ContextMenuItem | null> {
        const isIncidentAlreadyOwned = !(incident.ownerId?.isEmpty() ?? true);
        const isIncidentOwnedByMe = this._userService.isCurrentUserOwner(incident.ownerId);
        if (!isIncidentOwnedByMe && isIncidentAlreadyOwned) return null;
        const stateTransitions = await this._incidentApiService.getPossibleStateTransitions(incident.id).toPromise();
        if (!stateTransitions) return Promise.resolve(null);
        const states = this._stateService.getStates().filter((s) => {
            const isEnabled = stateTransitions?.results.find((r) => toGuid(r.id).equals(s.id))?.enabled ?? false;

            // Temporary hide hold state for now, waiting for Gelato Time picker component
            return !s.id.equals(incident.stateId) && !s.id.equals(StateGuids.PARKED) && isEnabled;
        });

        const stateItems = states.map((s) => {
            /*
            if (s.Id.equals(StateGuids.PARKED)) {
                const executeOnHold = async (delayInSeconds: number) => {
                    await this._incidentCommandService.execute(new PlaceOnHoldCommand(incident.Id, delayInSeconds)).toPromise();
                    this.closeContextMenu();
                };
                const commandHandler = async () => this._modalService.open(OnHoldModalComponent, { delayInSecondsChange: executeOnHold.bind(this) });
                return new MenuItem({ text: s.Name, id: s.Id.toString(), execute: commandHandler });
            }
            */
            return {
                text: s.name,
                id: s.id.toString(),
                actionItem: {
                    execute: async (p: ContextMenuItem) => {
                        await this._incidentCommandService.execute(new ChangeStateCommand(incident.id, toGuid(p.id!))).toPromise();
                        this.closeContextMenu();
                    },
                },
            };
        });
        return {
            id: this._translateService.instant('STE_LABEL_CHANGE_STATE') as string,
            text: this._translateService.instant('STE_LABEL_CHANGE_STATE') as string,
            children: stateItems,
        };
    }

    private createForwardItem(incident: MCIncident): Promise<ContextMenuItem | null> {
        if (!this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.forwardIncident) || incident.recipientsMode === RecipientsMode.Everyone) return Promise.resolve(null);
        const executeForward = async (recipients: IGuid[]) => {
            await this._incidentCommandService.execute(new ForwardCommand(incident.id, recipients)).toPromise();
            this.closeContextMenu();
        };

        const commandHandler = () =>
            this._modalService.open(IncidentRecipientModalComponent, {
                incident,
                recipientChange: executeForward.bind(this),
            });
        return Promise.resolve({
            id: this._translateService.instant('STE_LABEL_FORWARD') as string,
            text: this._translateService.instant('STE_LABEL_FORWARD') as string,
            actionItem: {
                execute: commandHandler,
            },
        });
    }

    private createTransferItem(incident: MCIncident): Promise<ContextMenuItem | null> {
        if (!this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.transferIncident) || incident.recipientsMode === RecipientsMode.Everyone) return Promise.resolve(null);
        const executeTransfer = async (recipients: IGuid[]) => {
            await this._incidentCommandService.execute(new TransfertCommand(incident.id, recipients)).toPromise();
            this.closeContextMenu();
        };

        const commandHandler = () =>
            this._modalService.open(IncidentRecipientModalComponent, {
                incident,
                recipientChange: executeTransfer.bind(this),
            });
        return Promise.resolve({
            id: this._translateService.instant('STE_LABEL_TRANSFER') as string,
            text: this._translateService.instant('STE_LABEL_TRANSFER') as string,
            actionItem: {
                execute: commandHandler,
            },
        });
    }
}
