import { Component, ViewChild } from '@angular/core';
import { TextFlavor, ButtonFlavor, ButtonType } from '@genetec/gelato';
import { Color } from '@genetec/gelato-angular';
import { Cardholder, CardholderManagementClient } from '@modules/access-control/api/api';
import { DateFormat, GenMenu, GenToastService, ToastFlavor, MeltedIcon, GenModalService } from '@genetec/gelato-angular';
import { CardholderEditContextState, CardholderModel } from '@modules/access-control/cardholder-edit-context.state';
import { deepUpdateValueAndValidity } from '@modules/shared/forms';
import { DateHelper } from '@modules/shared/utilities/date.helper';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { MethodEmitter } from '@src/app/store';
import { StateEmitter } from '@src/app/store/decorators/state-emitter.decorator';
import { StateObservable } from '@src/app/store/decorators/state-observable.decorator';
import { BehaviorSubject, Observable, combineLatest, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { take, startWith, takeUntil, map } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { BypassAntipassbackRules } from '@modules/access-control/enumerations';
import { TimeService } from '@modules/shared/services/time/time.service';
import { ContextMenuItem } from '@modules/shared/interfaces/context-menu-item/context-menu-item';
import { CardholderEditService } from '@modules/access-control/services/cardholder-edit-service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { notNullOrUndefined } from '@modules/shared/operators/not-null-or-undefined';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { getInvalidControls } from '@shared/forms/form-helpers';
import { FormControl } from '@angular/forms';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { CardholderEditFormGroup } from '../cardholder-edit-form/cardholder-edit-form-group';
import { ActiveExpirationModeLabel } from '../../../enumerations/expiration-mode';
import { CardholderDeleteModalComponent } from '../cardholder-delete-modal/cardholder-delete-modal.component';
import { CardholderCancelModalComponent } from '../unsaved-changes-modal/unsaved-changes-modal.component';
import { UnsavedChangesModalActions } from '../../../enumerations/unsaved-changes-modal-actions';
import { CardholderHelper } from '../cardholder-helper';

@UntilDestroy()
@Component({
    selector: 'app-cardholder-details-pane',
    templateUrl: './cardholder-details-pane.component.html',
    styleUrls: ['./cardholder-details-pane.component.scss'],
})
export class CardholderDetailsPaneComponent {
    @Select(CardholderEditContextState.cardholder)
    public cardholder$!: StateObservable<typeof CardholderEditContextState.cardholder>;

    @Select(CardholderEditContextState.errorMessageId)
    public errorMessageId$!: StateObservable<typeof CardholderEditContextState.errorMessageId>;

    @MethodEmitter(CardholderEditContextState.setCardholderIdAndPicture)
    public setCardholderIdAndPicture!: StateEmitter<typeof CardholderEditContextState.setCardholderIdAndPicture>;

    @MethodEmitter(CardholderEditContextState.setEditMode)
    public setEditMode!: StateEmitter<typeof CardholderEditContextState.setEditMode>;

    @ViewSelectSnapshot(CardholderEditContextState.isSideBySide)
    public isSideBySide!: ReturnType<typeof CardholderEditContextState.isSideBySide>;

    @ViewSelectSnapshot(CardholderEditContextState.cardholderId)
    public cardholderId!: ReturnType<typeof CardholderEditContextState.cardholderId>;

    @ViewSelectSnapshot(CardholderEditContextState.isAddMode)
    public isAddMode!: ReturnType<typeof CardholderEditContextState.isAddMode>;

    @SelectSnapshot(CardholderEditContextState.cardholder)
    public cardholderSnapshot!: ReturnType<typeof CardholderEditContextState.cardholder>;

    @SelectSnapshot(CardholderEditContextState.grantedPrivileges)
    public grantedPrivileges!: ReturnType<typeof CardholderEditContextState.grantedPrivileges>;

    @ViewChild('moreMenu') public moreMenu?: GenMenu;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly ButtonType = ButtonType;
    public readonly TextFlavor = TextFlavor;
    public form?: CardholderEditFormGroup;
    public formReset$: Observable<IGuid | undefined>;
    public smartLastAccessString = '';
    public lastAccessString = '';
    public moreMenuItemSource: ContextMenuItem[] = [];
    public meltedIcon = MeltedIcon;

    public get isFormDirty(): boolean {
        return this.form?.dirty ?? false;
    }

    private formResetSubject: BehaviorSubject<IGuid | undefined> = new BehaviorSubject<IGuid | undefined>(undefined);
    private resetIsAddModeSubject: Subject<null> = new Subject();
    private readonly minDateISOString = '0001-01-01T00:00:00Z';

    constructor(
        private translateService: TranslateService,
        private toastService: GenToastService,
        private cardholderManagementClient: CardholderManagementClient,
        private timeService: TimeService,
        private modalService: GenModalService,
        private editService: CardholderEditService,
        private loggerService: LoggerService
    ) {
        this.cardholder$.pipe(notNullOrUndefined(), untilDestroyed(this)).subscribe((cardholder) => {
            this.resetForm(cardholder);
            this.initializeMoreMenuItemSource();
            if (this.isLastAccessUnknown(cardholder)) {
                this.smartLastAccessString = this.translateService.instant('STE_LABEL_LASTACCESSTIME_UNKNOWN') as string;
                this.lastAccessString = '';
            } else {
                this.smartLastAccessString = this.timeService.formatRelative(cardholder.lastAccess);
                this.lastAccessString = this.timeService.formatTime(cardholder.lastAccess, DateFormat.DateTime);
            }
        }, this.loggerService.traceError);
        this.formReset$ = this.formResetSubject.asObservable();
    }

    public onCancelClick(): void {
        if (this.form?.dirty) {
            this.askForCancel().pipe(untilDestroyed(this)).subscribe();
        } else {
            this.resetCardholderChanges();
        }
    }

    public resetCardholderChanges(): void {
        // has to be called first, otherwise there will be an error when calling this.setCardholderIdAndPicture(null)
        if (this.isAddMode) {
            this.setEditMode();
        }
        this.setCardholderIdAndPicture(null);
        this.form?.markAsPristine();
    }

    /**
     * @returns true if the cardholder the submit was successful, false otherwise
     */
    public onSubmit(): boolean {
        if (!this.form) {
            return false; // No form so no submit
        }

        if (!this.form.valid) {
            // Trigger validation of all form controls recursively
            deepUpdateValueAndValidity(this.form);

            this.toastService.show({
                id: 'cardholder-save-fail',
                flavor: ToastFlavor.Error,
                text: this.translateService.instant('STE_LABEL_ERROR') as string,
                secondaryText: this.getErrorMessage(),
            });
            return false; // If its not valid, don't submit
        }

        const cardholderData = this.formToCardholder();

        if (this.isAddMode) {
            this.addCardholder(cardholderData);
        } else {
            this.updateCardholder(cardholderData);
        }

        this.form.markAsPristine();
        return true;
    }

    public showDeleteCardholderModal(): void {
        this.modalService.open(CardholderDeleteModalComponent, {
            cardholderId: this.cardholderId,
            cardholderName: `${this.cardholderSnapshot?.firstName || ''} ${this.cardholderSnapshot?.lastName || ''}`.trim(),
            onDeleteSuccessful: () => {
                this.setCardholderIdAndPicture(null);
                this.editService.loadCardholders();
            },
        });
    }

    public async toggleMoreMenu(): Promise<void> {
        if (this.moreMenu) {
            await this.moreMenu.toggle();
        }
    }

    /**
     * @returns **true** if the user wants to cancel and **false** if the user wants to save or discard.
     */
    public askForCancel(): Observable<boolean> {
        const modal = this.modalService.open(CardholderCancelModalComponent);
        return modal.result$.pipe(
            map((result) => {
                switch (result) {
                    case UnsavedChangesModalActions.Discard:
                        this.resetCardholderChanges();
                        return false;
                    case UnsavedChangesModalActions.Save:
                        // onSubmit returns true if the form is valid and successfully submitted
                        return !this.onSubmit();
                    case UnsavedChangesModalActions.Cancel:
                    default:
                        return true;
                }
            })
        );
    }

    private addCardholder(cardholderData: Cardholder) {
        this.cardholderManagementClient
            .add(cardholderData)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(
                (id) => {
                    this.showSuccessToast();
                    this.setEditMode();
                    this.setCardholderIdAndPicture({ id, picture: this.form?.controls.identityFormGroup.controls.picture.value });
                    this.editService.loadCardholders();
                },
                (error) => {
                    this.showErrorToast(error as string);
                    this.form?.markAsDirty();
                }
            );
    }

    private updateCardholder(cardholderData: Cardholder) {
        this.cardholderManagementClient
            .update(cardholderData)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(
                () => {
                    this.showSuccessToast();
                    this.editService.loadCardholders();
                },
                (error) => {
                    this.loggerService.traceError(error);
                    this.showErrorToast(error as string);
                    this.form?.markAsDirty();
                }
            );
    }

    private getErrorMessage(): string {
        if (this.form) {
            const invalidControls = getInvalidControls(this.form);
            if (invalidControls && invalidControls.length === 1 && 'label' in invalidControls[0] && invalidControls[0].label) {
                const labelString = this.translateService.instant(invalidControls[0].label) as string;
                if (labelString !== invalidControls[0].label) {
                    // Display which control is in error.
                    return stringFormat(
                        this.translateService.instant('STE_MESSAGE_ERROR_CARDHOLDERSAVE_INVALID_FIELD') as string,
                        this.translateService.instant(invalidControls[0].label) as string
                    );
                }
            }
        }
        return this.translateService.instant('STE_MESSAGE_ERROR_CARDHOLDERSAVE_INVALID_FORM') as string;
    }

    private showSuccessToast(): void {
        this.toastService.show({
            id: 'cardholder-save-success',
            flavor: ToastFlavor.Success,
            text: this.translateService.instant('STE_LABEL_SUCCESS') as string,
            secondaryText: this.translateService.instant('STE_MESSAGE_INFO_CARDHOLDERSAVE_SUCCESS') as string,
        });
    }

    private showErrorToast(errorDetails: string): void {
        const details = errorDetails ?? (this.translateService.instant('STE_MESSAGE_ERROR_CARDHOLDERSAVE_ERROR') as string);
        this.toastService.show({
            id: 'cardholder-save-error',
            flavor: ToastFlavor.Error,
            text: this.translateService.instant('STE_LABEL_ERROR') as string,
            secondaryText: details,
        });
    }

    private resetForm(cardholder: CardholderModel) {
        if (this.grantedPrivileges && cardholder) {
            this.form = new CardholderEditFormGroup(cardholder, this.isAddMode, this.grantedPrivileges);

            if (this.isAddMode || CardholderHelper.isEntityNameDefaultValue(cardholder)) {
                this.setAutomaticUpdateEntityName();
            }
        }

        this.formResetSubject.next(cardholder?.id);
    }

    private setAutomaticUpdateEntityName(): void {
        if (!this.form) {
            return;
        }

        const firstName$ = this.form.controls.identityFormGroup.controls.firstName.valueChanges;
        const lastName$ = this.form.controls.identityFormGroup.controls.lastName.valueChanges;
        const displayName$ = this.form.controls.advancedFormGroup.controls.displayName.valueChanges;
        this.resetIsAddModeSubject.next();
        combineLatest([
            firstName$.pipe(startWith(this.form.controls.identityFormGroup.value.firstName)),
            lastName$.pipe(startWith(this.form.controls.identityFormGroup.value.lastName)),
        ])
            .pipe(takeUntil(this.resetIsAddModeSubject), takeUntil(displayName$), untilDestroyed(this))
            .subscribe(([firstName, lastName]) => {
                if (firstName || lastName) {
                    const newEntityName = `${firstName || ''} ${lastName || ''}`.trim();
                    this.form?.controls.advancedFormGroup.controls.displayName.setValue(newEntityName, { emitEvent: false });
                }
            });
    }

    private getBypassAntipassbackIsInherited(): boolean {
        const advancedGroup = this.form?.controls?.advancedFormGroup?.controls;
        const antipassBackControl = advancedGroup?.bypassAntipassbackRules;

        return antipassBackControl?.value === BypassAntipassbackRules.Inherited;
    }

    private getCalculatedBypassAntipassbackValue(): boolean {
        const advancedGroup = this.form?.controls?.advancedFormGroup?.controls;
        const antipassBackControl = advancedGroup?.bypassAntipassbackRules;

        if (this.getBypassAntipassbackIsInherited()) {
            return this.cardholderSnapshot?.antipassbackExemption || false;
        }

        return antipassBackControl?.value === BypassAntipassbackRules.Yes;
    }

    private isSecurityClearanceInherited(): boolean {
        const advancedGroup = this.form?.controls?.advancedFormGroup?.controls;
        const isSecurityClearanceInheritedControl = advancedGroup?.isSecurityClearanceInheritedFromGroup;

        return isSecurityClearanceInheritedControl?.value === 'true';
    }

    // TODO: method can be modified, when SC returns a lastTimeSeen in this format ending with a Z for 'null' dates: 0001-01-01T00:00:00Z
    // ie. 01-01-0001 is considered to be the null date
    private isLastAccessUnknown(cardholder: CardholderModel): boolean {
        const date = cardholder.lastAccess.getDate();
        const month = cardholder.lastAccess.getMonth();
        const year = cardholder.lastAccess.getFullYear();

        if (date === 1 && month === 0 && year === 1) {
            return true;
        }

        return false;
    }

    private returnNullIfControlPristine<T>(control: FormControl<T> | undefined): T | null {
        if (control?.dirty === true) {
            return control.value;
        }
        return null;
    }

    private formToCardholder(): Cardholder {
        const controls = this?.form?.controls;

        const activationDate = new Date(controls?.statusFormGroup?.controls?.activationDate?.value ?? this.minDateISOString);
        let expirationDate = new Date(controls?.statusFormGroup?.controls?.expirationDate?.value ?? this.minDateISOString);
        const accessStatus = controls?.statusFormGroup.controls.accessStatus.value.toString();
        const activationMode = controls?.statusFormGroup.controls.activationMode.value;

        // If ExpirationMode is set to WhenNotUsed, then we need to compute what is that expiration date value
        if (accessStatus === AccessStatus.Active && controls?.statusFormGroup.controls.expirationMode.value === ActiveExpirationModeLabel.WhenNotUsed) {
            expirationDate = DateHelper.addDays(new Date(), controls?.statusFormGroup?.controls?.expirationDuration?.value);
        }

        return new Cardholder({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: this.cardholderId!,
            firstName: controls?.identityFormGroup.controls.firstName.value,
            lastName: controls?.identityFormGroup.controls.lastName.value,
            displayName: controls?.advancedFormGroup?.controls?.displayName?.value ?? '',
            mobilePhoneNumber: controls?.identityFormGroup.controls.mobilePhoneNumber.value,
            picture: this.returnNullIfControlPristine(controls?.identityFormGroup.controls.picture),
            emailAddress: controls?.identityFormGroup.controls.emailAddress.value,
            status: accessStatus,
            activation: activationDate.toISOString(),
            expiration: expirationDate.toISOString(),
            // ExpirationMode is a bit complex, it is used for both Activation and Expiration
            expirationMode: CardholderHelper.buildExpirationMode(accessStatus, activationMode, controls?.statusFormGroup.controls.expirationMode.value),
            expirationDuration: controls?.statusFormGroup?.controls?.expirationDuration?.value ?? 0,
            canEscortVisitors: controls?.advancedFormGroup?.controls?.canEscortVisitors?.value,
            useExtendedGrantTime: controls?.advancedFormGroup?.controls?.useExtendedGrantTime?.value,
            description: controls?.advancedFormGroup?.controls?.description?.value,
            logicalId: controls?.advancedFormGroup?.controls?.logicalId?.value,
            bypassAntipassback: this.getCalculatedBypassAntipassbackValue(),
            antipassbackExemptionIsInherited: this.getBypassAntipassbackIsInherited(),
            securityClearance: controls?.advancedFormGroup?.controls?.securityClearance?.value,
            inheritAccessPermissionLevelFromGroup: this.isSecurityClearanceInherited(),
            //partition: controls?.advancedFormGroup.controls.partitions.value,
            // Access rights
            cardholderGroups: this.returnNullIfControlPristine(controls?.accessRightsFormGroup.controls.groups),
            accessRules: this.returnNullIfControlPristine(controls?.accessRightsFormGroup.controls.rules),
            credentials: this.returnNullIfControlPristine(controls?.accessRightsFormGroup.controls.credentials),
        });
    }

    private initializeMoreMenuItemSource() {
        this.moreMenuItemSource = [];

        // Only add Delete item if it can be execute by the current user
        if (this.cardholderSnapshot?.isDeletable && this.grantedPrivileges?.deleteCardholdersPrivilege) {
            this.moreMenuItemSource.push(
                // Delete Cardholder
                {
                    id: 'DeleteCardholder',
                    text: this.translateService.instant('STE_LABEL_CARDHOLDER_DELETE') as string,
                    icon: MeltedIcon.Trash,
                    iconColor: Color.Fragola,
                    actionItem: {
                        canExecute: () => (this.cardholderSnapshot?.isDeletable && this.grantedPrivileges?.deleteCardholdersPrivilege) ?? false,
                        execute: () => this.showDeleteCardholderModal(),
                    },
                }
            );
        }
    }
}
