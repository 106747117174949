import { FitResolution } from '../utils/FitResolution';
import { Guard } from '../utils/Guard';
import { Resolution } from '../utils/Resolution';
import { NormalizedTopLeftCoords, TopLeftCoords } from '../utils/TopLeftCoords';

export class ZoomConfiguration {
  public static readonly NoZoom: ZoomConfiguration = new ZoomConfiguration(new NormalizedTopLeftCoords(0, 0), 1);

  private readonly m_topLeftCoords: NormalizedTopLeftCoords;

  private readonly m_zoom: number;

  public get Zoom(): number {
    return this.m_zoom;
  }

  public get IsZoomed(): boolean {
    return this.m_zoom > 1;
  }

  public get X(): number {
    return this.m_topLeftCoords.X;
  }

  public get Y(): number {
    return this.m_topLeftCoords.Y;
  }

  public constructor(topLeftCoords: NormalizedTopLeftCoords, zoom: number) {
    Guard.isInRangeInclusive(topLeftCoords.X, 0, 1);
    Guard.isInRangeInclusive(topLeftCoords.Y, 0, 1);
    Guard.isInRangeInclusive(zoom, 1, 20);

    this.m_topLeftCoords = topLeftCoords;
    this.m_zoom = zoom;
  }

  public toString(): string {
    return `${this.m_topLeftCoords.X},${this.m_topLeftCoords.Y} at ${this.m_zoom}x`;
  }

  public toCanvasParameters(streamResolution: Resolution, tileResolution: Resolution): [number, TopLeftCoords] {
    //When we are not zooming, we are still stretching the image to a certain level to fill the tile while keeping the aspect ratio.
    //The digital requested zoom will apply on that scale:
    const fitResolution = new FitResolution(streamResolution, tileResolution);
    const pixelTopLeftCoords = this.m_topLeftCoords.toPixelTopLeftCoords(tileResolution);
    // Get the current offset
    const newOffsetX = ((tileResolution.Width - fitResolution.Scaled.Width) / 2 -
      // Displace the image by the calculated coordinates
      pixelTopLeftCoords.X) /
      // Apply the scale
      fitResolution.Scale;

    // Redo for Y
    const newOffsetY = ((tileResolution.Height - fitResolution.Scaled.Height) / 2 - pixelTopLeftCoords.Y) / fitResolution.Scale;

    const zoom = fitResolution.Scale * this.Zoom;

    return [zoom, new TopLeftCoords(newOffsetX, newOffsetY)];
  }

  public equals(zoomConfig: ZoomConfiguration): boolean {
    return this.X === zoomConfig.X && this.Y === zoomConfig.Y && this.Zoom === zoomConfig.Zoom;
  }
}
