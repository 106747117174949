<gen-melted-modal
    [id]="id"
    genTitle="{{ 'STE_TITLE_EXPORTVIDEO' | translate }}"
    [genIsDefaultActionDisabled]="!form.valid"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_BUTTON_EXPORT' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnSubmit]="onSubmit"
    data-cy="exportVideoModal"
    appFocusModalWhenOpen
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <form [formGroup]="form">
            <section class="gen-app">
                <gen-flex>
                    <gen-flex-item [genColumns]="4">
                        <ng-container *ngIf="thumbnail$ | async as thumbnail; else noThumbnail">
                            <gen-image data-cy="thumbnail" name="thumbnail" [attr.src]="thumbnail"></gen-image>
                        </ng-container>
                    </gen-flex-item>
                    <gen-flex-item [genColumns]="8" data-cy="cameraName">
                        <gen-text flavor="label" class="label"> {{ 'STE_LABEL_CAMERA_NAME' | translate }} </gen-text>
                        <gen-text class="mb-2"> {{ cameraName }}</gen-text>
                    </gen-flex-item>
                    <!--gen-text flavor="label"> area </gen-text>
                        <gen-text class="mb-2">area string</gen-text-->

                    <gen-flex-item [genColumns]="12">
                        <gen-text-input
                            data-cy="description"
                            #descriptionInput
                            formControlName="description"
                            appAutoFocus
                            placeholder="{{ 'STE_LABEL_DESCRIPTION' | translate }}"
                            [required]="!hasExportPrivilege"
                            [multiline]="2"
                            tabindex="1"
                        >
                            <gen-label>{{ 'STE_LABEL_DESCRIPTION' | translate }}</gen-label>
                            <gen-message [severity]="Severity.Error">{{ 'STE_MESSAGE_ERROR_FIELDISREQUIRED' | translate }}</gen-message>
                        </gen-text-input>
                    </gen-flex-item>

                    <gen-flex-item [genColumns]="5">
                        <gen-datetime-input
                            name="startTime"
                            formControlName="startTime"
                            [genFlavor]="Flavor.Calendar.DateTime"
                            genFormat="L LTS"
                            genLabel="{{ 'STE_LABEL_NOUN_STARTTIME' | translate }}"
                            data-cy="startTime"
                        >
                        </gen-datetime-input>
                    </gen-flex-item>
                    <gen-flex-item [genColumns]="5">
                        <gen-datetime-input
                            formControlName="endTime"
                            [genFlavor]="Flavor.Calendar.DateTime"
                            [genMessageSeverity]="Severity.Error"
                            [genMessage]="endTimeError"
                            genFormat="L LTS"
                            genLabel="{{ 'STE_LABEL_NOUN_ENDTIME' | translate }}"
                            data-cy="endTime"
                        >
                        </gen-datetime-input>
                    </gen-flex-item>

                    <gen-flex-item [genColumns]="2" data-cy="duration">
                        <label class="gen-label pb-1">{{ 'STE_LABEL_DURATION' | translate }}</label>
                        <gen-text> {{ duration$ | async }} </gen-text>
                    </gen-flex-item>

                    <gen-flex-item [genColumns]="2">
                        <gen-combobox
                            formControlName="format"
                            [genItemsSource]="formats"
                            genLabel="{{ 'STE_LABEL_NOUN_EXPORTFORMAT' | translate }}"
                            [genFlavor]="Flavor.Combobox.Flat"
                            data-cy="exportFormat"
                        >
                        </gen-combobox>
                    </gen-flex-item>

                    <gen-flex-item *ngIf="form.value.format?.supportsAudio" [genColumns]="4" data-cy="exportAudio">
                        <label class="gen-label"> {{ 'STE_ACTION_EXPORTAUDIO' | translate }}</label>
                        <div class="check-margin">
                            <gen-checkbox formControlName="exportAudio" tabindex="1"> </gen-checkbox>
                        </div>
                    </gen-flex-item>

                    <gen-flex-item *ngIf="form.value.format?.supportsEncryption" [genColumns]="6">
                        <gen-flex>
                            <gen-flex-item [genColumns]="3">
                                <label class="gen-label"> {{ 'STE_CHECKBOX_ENCRYPT' | translate }}</label>
                                <div class="check-margin">
                                    <gen-checkbox formControlName="encrypt" tabindex="1"> </gen-checkbox>
                                </div>
                            </gen-flex-item>
                            <gen-flex-item *ngIf="form.value.encrypt" [genColumns]="9">
                                <gen-password-input formControlName="encryptionKey" required>
                                    <gen-label>{{ 'STE_LABEL_ENCRYPTIONKEY' | translate }}</gen-label>
                                    <gen-message [severity]="Severity.Error" [appFormControlError]="form.controls.encryptionKey"></gen-message>
                                </gen-password-input>
                            </gen-flex-item>
                        </gen-flex>
                    </gen-flex-item>
                </gen-flex>
            </section>
        </form>
    </gen-melted-modal-body>
</gen-melted-modal>

<ng-template #noThumbnail>
    <gen-image name="thumbnail" [attr.src]="null"></gen-image>
</ng-template>
