import { Component, Inject, OnInit } from '@angular/core';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';

@Component({
    selector: 'app-tiles-options',
    templateUrl: './tiles-options.component.html',
})
@InternalPluginDescriptor({
    type: TilesOptionsComponent,
    pluginTypes: [PluginTypes.Option],
    exposure: {
        id: TilesOptionsComponent.pluginId,
        subSection: OptionSubSections.Tiles,
    },
    isSupported: () => true,
    requirements: { enabledFeatureFlags: [GeneralFeatureFlags.EntityState] },
})
export class TilesOptionsComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('A76B4F23-79C3-4A59-848A-D9E75470F747');

    public static readonly TileHeaderVisibilityId = 'TileHeaderVisibility';
    public readonly TileHeaderStateOptions = TileNumberDisplayPreference;

    public tileHeaderVisibility?: TileNumberDisplayPreference;
    public isInitialized = false;
    public isContentVisible = false;

    private readonly tileHeaderVisibilityDefaultValue = TileNumberDisplayPreference.Always;
    constructor(trackingService: TrackingService, @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService) {
        super(trackingService);
    }

    ngOnInit() {
        const contentVisibility = this.userSettingsService.getOptionAvailability(OptionTypes.Appearance);
        if (contentVisibility) {
            this.isContentVisible = true;
            this.tileHeaderVisibility = this.userSettingsService.get<TileNumberDisplayPreference>(OptionTypes.Appearance, TilesOptionsComponent.TileHeaderVisibilityId);
            if (!this.tileHeaderVisibility) {
                // set default value in settings.
                this.userSettingsService.set(OptionTypes.Appearance, TilesOptionsComponent.TileHeaderVisibilityId, this.tileHeaderVisibilityDefaultValue);
                this.tileHeaderVisibility = this.tileHeaderVisibilityDefaultValue;
            }
        }

        this.isInitialized = true;
    }

    public onTileHeaderVisibilityChange(): void {
        this.userSettingsService.set(OptionTypes.Appearance, TilesOptionsComponent.TileHeaderVisibilityId, this.tileHeaderVisibility);
    }
}

export enum TileNumberDisplayPreference {
    Always = 'Always',
    Never = 'Never',
    OnlyWhenHovering = 'OnlyWhenHovering',
}
