import { Component, OnInit, OnDestroy } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { IGuid, SafeGuid } from 'safeguid';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { IDoorEntity } from 'RestClient/Client/Interface/IDoorEntity';
import { filterFieldChanged } from '@modules/shared/operators/filter-field-change';
import { DoorWidgetBaseComponent } from '../door-widget-base.component';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const isContentSupported = (content: Content, pluginType: IGuid): boolean => {
    return !!(
        content?.type.equals(AccessControlContentTypes.Door) &&
        (content.contextContent === null || (content.contextContent.type.equals(AccessControlContentTypes.Event) && pluginType.equals(PluginTypes.Widget)))
    );
};

@Component({
    selector: 'app-door-actions-widget',
    templateUrl: './door-actions-widget.component.html',
    styleUrls: ['./door-actions-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: DoorActionsWidgetComponent,
    pluginTypes: [PluginTypes.Widget, PluginTypes.MapPopupCompact],
    exposure: { id: DoorActionsWidgetComponent.pluginId, priority: 0 },
    isContentSupported,
})
export class DoorActionsWidgetComponent extends DoorWidgetBaseComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('F9B8C125-E1C8-48D8-A0D9-4A5DDF387E08');

    public stateAdditionalInfoString$: Observable<string>;
    public isMaintenanceModeActiveStateString$: Observable<string>;
    public isLockedStateString$: Observable<string>;
    public isOpenedStateString$: Observable<string>;
    private stateAdditionalInfoStringSubject = new Subject<string>();

    constructor(securityCenterClientService: SecurityCenterClientService, translateService: TranslateService, trackingService: TrackingService) {
        super(securityCenterClientService, translateService, trackingService);

        this.isMaintenanceModeActiveStateString$ = this.door$.pipe(
            filterFieldChanged('maintenanceModeActive'),
            map((maintenanceModeActive) => (maintenanceModeActive ? (this.translateService.instant('STE_LABEL_DOOR_MAINTENANCE_MODE') as string) : ''))
        );

        this.isOpenedStateString$ = this.door$.pipe(
            filterFieldChanged('doorOpened'),
            map((doorOpened) => this.translateService.instant(doorOpened ? 'STE_LABEL_DOOROPENED' : 'STE_LABEL_DOORCLOSED') as string)
        );

        this.isLockedStateString$ = this.door$.pipe(
            filterFieldChanged('isLocked'),
            map((isLocked) => this.translateService.instant(isLocked ? 'STE_LABEL_DOOR_LOCKED' : 'STE_LABEL_DOOR_UNLOCKED') as string)
        );

        this.stateAdditionalInfoString$ = this.stateAdditionalInfoStringSubject.asObservable().pipe(distinctUntilChanged());
    }

    public async ngOnInit() {
        await super.ngOnInit();
        this.isLoading = false;

        const door = this.door;
        if (door && this.entityCacheTask) {
            this.entityCacheTask
                .detectRelationChangeAsync(
                    door,
                    () => {
                        const _ = door?.getAutoUnlockOverrideAsync();
                    },
                    async (doorEntity: IDoorEntity): Promise<void> => {
                        return this.updateAdditionnalInfo(doorEntity);
                    }
                )
                .fireAndForget();
        }
    }

    private async updateAdditionnalInfo(door: IDoorEntity): Promise<void> {
        if (door) {
            const scheduleOverride = await door.getAutoUnlockOverrideAsync();
            if (scheduleOverride?.hasField('isUnlocking') && door.runningState === RunningState.NotRunning) {
                this.stateAdditionalInfoStringSubject.next(this.translateService.instant('STE_LABEL_WARNING_OFFLINE_DOOR_SCHEDULE_OVERRIDE_WIDGET_TOOLTIP') as string);
                return;
            }
        }

        this.stateAdditionalInfoStringSubject.next('');
    }
}
