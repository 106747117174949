import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StateItem } from './state-item/state-item';

@Component({
    selector: 'app-entity-state-item',
    templateUrl: './entity-state-item.component.html',
    styleUrls: ['./entity-state-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStateItemComponent {
    @Input() entity?: StateItem;
    @Input() withExpander?: boolean;
    @Input() showIcon?: boolean;
    @Input() appTooltipOnlyIfOverflow?: boolean;
}
