<div class="gen-header">
    <div class="header__action-container">
        <div class="header__action-search">
            <gen-search-input
                data-cy="search-input"
                genAutofocus
                placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
                [genDelay]="1000"
                [genGetSearchResults]="onMapSearch"
                (genSearchResultClick)="onMapSearchResultSelected($event)"
                (genOffClick)="clearSearch()"
                [genOffClickIf]="searchText.length > 0"
                [(ngModel)]="searchText"
                [genMinLength]="1"
            >
            </gen-search-input>
            <gen-spinner [style.visibility]="(isSearching$ | async) ? 'visible' : 'hidden'" [size]="SpinnerSize.Large"></gen-spinner>
        </div>
        <div class="header__action-map-selector">
            <gen-button
                id="toggle-maps-popup-{{ componentId }}"
                [flavor]="ButtonFlavor.Flat"
                appTooltip="{{ 'STE_BUTTON_SELECTMAP' | translate }}"
                (click)="toggleMapSelectionAsync()"
                data-cy="toggle-map-selection"
            >
                <app-custom-icon slot="gen-icon" [icon]="Icon.FoldedMap" [entityId]="currentMapAreaId" [customIconId]="currentMapAreaCustomIconId" class="mr-1"></app-custom-icon>
                <div class="header__map-selector-name">
                    {{ mapService.currentMapEntity?.name }}
                </div>
            </gen-button>
        </div>
        <div class="header__tray-plugins-container" *ngIf="mapService.mapAvailable$ | async">
            <div class="va-center" *ngFor="let mapTrayPlugin of mapTrayPlugins$ | async">
                <app-plugin-host [pluginItem]="mapTrayPlugin"></app-plugin-host>
            </div>
            <gen-button
                id="toggle-layers-popup-{{ componentId }}"
                data-cy="toggle-layers-button"
                [disabled]="!initialMapLoadItemsDone"
                class="va-center"
                [flavor]="ButtonFlavor.Flat"
                appTooltip="{{ 'STE_BUTTON_SELECTMAPLAYERS' | translate }}"
                (click)="toggleLayerSelection()"
            >
                <gen-icon [icon]="Icon.Layers"></gen-icon>
            </gen-button>
        </div>
    </div>
</div>

<div class="gen-sidepane right overlay sm show" id="mapsRightSidepane">
    <div class="gen-sidepane-body">
        <app-maps-side-pane></app-maps-side-pane>
    </div>
</div>

<div class="map-container" [class.mapWithBottomPane]="bottomContextDataService.contentCount$ | async">
    <div
        id="map"
        data-cy="mapInstance"
        [class.mapWithBottomPane]="bottomContextDataService.contentCount$ | async"
        (contextmenu)="onContextMenu($event)"
        (mousedown)="onMouseDown()"
        (mouseup)="onMouseUp($event)"
        (click)="onClick($event)"
    >
        <gen-spinner class="loading-mapObjects-spinner" *ngIf="mapService.loadingMapObjects$ | async">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        <ng-container *ngIf="toggleMiniMapButton$ | async as toggleMiniMapButton">
            <gen-tooltip [targetElement]="toggleMiniMapButton">{{ minimapTooltip$ | async }}</gen-tooltip>
        </ng-container>

        <ng-container *ngIf="{ available: mapService.mapAvailable$ | async } as mapAvailable">
            <gen-text [hidden]="mapAvailable.available" [flavor]="TextFlavor.Header1" class="ha-center map-unavailable-text"> {{ mapUnavailableReason }}</gen-text>
            <app-map-controls
                #appMapControls
                id="app-map-controls"
                data-cy="mapControls"
                *ngIf="mapAvailable.available"
                [defaultView]="defaultView"
                [presets]="presets"
                [floorInfo]="floorInfo"
                [selectedFloor]="selectedFloor"
                [isGoToParentEnabled]="isGoToParentEnabled"
                (zoomInClick)="onZoomIn()"
                (zoomOutClick)="onZoomOut()"
                (presetSelect)="onPresetClick($event)"
                (floorClick)="switchFloor($event)"
                (goToParentClick)="goToParentMap()"
                (widthChange)="onMapControlWidthChange()"
            ></app-map-controls>
        </ng-container>
    </div>
    <app-portal-outlet class="portal-outlet" *ngIf="isPortalEnabled$ | async"></app-portal-outlet>
</div>

<app-maps-bottom-pane></app-maps-bottom-pane>

<!-- map selection browser -->
<gen-popup #mapSelectionBrowser [position]="PopupPosition.BottomLeft" targetSelector="#toggle-maps-popup-{{ componentId }}" appFocusPopupWhenOpen>
    <app-entity-browser
        #mapEntityBrowser
        class="entity-browser-size"
        [autoRefresh]="false"
        [allowMultiSelect]="false"
        (selectedEntitiesChanged)="onMapSelected($event)"
        (entityDoubleClicked)="onMapSelected($event)"
        [canSelectItemFunction]="canSelectMap"
    >
    </app-entity-browser>
</gen-popup>

<!-- link selection context menu -->
<gen-menu #linkContextMenu [positionX]="clickPositionX" [positionY]="clickPositionY">
    <gen-menu-item *ngIf="isLoadingContextMenu$ | async">
        <gen-spinner slot="gen-text"></gen-spinner>
    </gen-menu-item>
    <gen-menu-item *ngFor="let link of contextMenuLinks$ | async" (click)="onLinkClick(link)">
        <gen-icon [icon]="link.icon"></gen-icon>
        <gen-text>{{ link.text }}</gen-text>
    </gen-menu-item>
</gen-menu>

<!-- map layers browser -->
<gen-popup
    #mapLayersBrowser
    data-cy="layersSelectionPopup"
    [position]="PopupPosition.BottomRight"
    targetSelector="#toggle-layers-popup-{{ componentId }}"
    appFocusPopupWhenOpen
    genKeyboardNavigation
    (genEscape)="toggleLayerSelection()"
>
    <div class="layers-input-container">
        <gen-list [selectionType]="ListSelectionType.Multiple">
            <gen-list-item *ngFor="let layerItem of layersAsListItems$ | async" [selected]="layerItem.isChecked" (selectedChange)="onSelectedItemsChange(layerItem, $event.detail)">
                <gen-item>
                    <gen-icon *ngIf="!layerItem.image" [slot]="ItemSlot.Icon" [icon]="layerItem.icon"></gen-icon>
                    <gen-text [slot]="ItemSlot.Text">{{ layerItem.text }}</gen-text>
                    <gen-image
                        *ngIf="layerItem.image"
                        [slot]="ItemSlot.Icon"
                        class="va-center layer-icon"
                        [src]="layerItem.image"
                        [fit]="ImageFit.Contain"
                        [flavor]="ImageFlavor.Rounded"
                    ></gen-image>
                </gen-item>
            </gen-list-item>
        </gen-list>
    </div>
</gen-popup>
