import { Inject, Injectable } from '@angular/core';
import { Color, GenToastService, MeltedIcon, MeltedModalAction } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { isString } from 'lodash-es';
import moment from 'moment';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { CameraEntityFields, ICameraEntity } from 'RestClient/Client/Interface/ICameraEntity';
import { modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CameraEntity } from 'RestClient/Client/Model/Video/CameraEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { GuidMap, GuidSet, IGuid, SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { SharedContentFields } from '@modules/shared/enumerations/shared-content-fields';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { TimeService } from '@modules/shared/services/time/time.service';
import { Entity } from 'RestClient/Client/Model/Entity';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FullscreenService } from '@modules/shared/services/fullscreen/fullscreen.service';
import { CameraFeatureCapabilties } from 'RestClient/Client/Enumerations/CameraFeatureCapabilties';
import { SharedCommands } from '../../shared/enumerations/shared-commands';
import {
    CommandBindings,
    CommandDisplay,
    CommandsService,
    COMMANDS_SERVICE,
    EntityCommandRequirements,
    FEATURES_SERVICE,
} from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { CommandArgs, CommandDescriptor } from '../../shared/services/commands/command-provider-base';
import { EntityCommandsUsageContext } from '../../shared/services/commands/commands-usage/entity-commands-usage-context';
import { EntityCommandContext } from '../../shared/services/commands/entity-command-context';
import { EntityCommandProviderBase, ExecuteEntityCommandData } from '../../shared/services/commands/entity-command-provider-base';
import { LanguageService } from '../../shared/services/language/language.service';
import { BookmarkComponent } from '../components/bookmark/bookmark.component';
import { ExportVideoComponent } from '../components/export-video/export-video.component';
import { VideoContentTypes } from '../enumerations/video-content-types';
import { CameraHelper } from '../utilities/camerahelper';
import { VideoExportService } from './video-export.service';

interface AddBookmarkSpecificData {
    entityId: IGuid;
    message?: string;
    timestamp?: Date;
    playback?: boolean;
}

interface InternalRecordingState {
    isLocked: boolean;
    isRecording: boolean;
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class CameraCommandProvider extends EntityCommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = new GuidMap<CommandDescriptor>();
            descriptors.set(
                SharedCommands.AddBookmark,
                this.createCameraCommandDescriptor(
                    SharedCommands.AddBookmark,
                    'STE_BUTTON_ADDBOOKMARK',
                    MeltedIcon.Bookmark,
                    this.fillCommandRequirements({ requiredPrivileges: new GuidSet([KnownPrivileges.addBookmarkPrivilege]) }),
                    'Control.B'
                )
            );
            descriptors.set(
                SharedCommands.ExportVideo,
                this.createCameraCommandDescriptor(
                    SharedCommands.ExportVideo,
                    'STE_BUTTON_DOWNLOAD',
                    MeltedIcon.Download,
                    this.fillCommandRequirements({ requiredPrivileges: new GuidSet([KnownPrivileges.exportVideoPrivilege]) }),
                    'D'
                )
            );
            // );
            // descriptors.set(
            //     SharedCommands.saveSnapshot,
            //     this.createCameraCommandDescriptor(
            //         SharedCommands.saveSnapshot,
            //         'STE_BUTTON_SAVESNAPSHOT',
            //         MeltedIcon.Snapshot,
            //         undefined,
            //         this.createCommandRequirements({ requiredPrivileges: [KnownPrivileges.saveAndPrintSnapshotsPrivilege] })
            //     )
            // );
            descriptors.set(
                SharedCommands.StartStopRecording,
                this.createCameraCommandDescriptor(
                    SharedCommands.StartStopRecording,
                    'STE_BUTTON_STARTRECORDING',
                    MeltedIcon.Record,
                    this.fillCommandRequirements({ requiredPrivileges: new GuidSet([KnownPrivileges.recordManuallyPrivilege]) })
                )
            );
            this.internalCommandDescriptors = descriptors;
        }

        return this.internalCommandDescriptors;
    }

    public get requiredEntityFields(): Set<string> {
        return new Set<string>([...super.requiredEntityFields, CameraEntityFields.recordingStateField]);
    }

    public get requiredFeatures(): Set<IGuid> {
        return new GuidSet([...super.requiredFeatures, KnownFeatures.videoId]);
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        private timeService: TimeService,
        private toastService: GenToastService,
        private videoExportService: VideoExportService,
        protected advancedSettingsService: AdvancedSettingsService,
        private fullscreenService: FullscreenService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);

        // Prefetch privileges
        await this.prefetchPrivileges(commandContext, commandArgs);

        if (await this.isAddBookmarkAvailableAsync(commandContext, commandArgs)) {
            const cameraEntity = await this.extractCameraEntity(commandContext);
            if (cameraEntity) {
                const caps = await cameraEntity.getFeatureCapabilitiesAsync();
                const bookmarkCap = caps?.firstOrDefault((c) => c.id === CameraFeatureCapabilties.Bookmarks);
                if (bookmarkCap?.supported) {
                    commandIds.push(SharedCommands.AddBookmark);
                }
            }
        }

        if (await this.isExportVideoAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(SharedCommands.ExportVideo);
        }
        // TODO FM: Once possible, saveSnapshot should be handled here instead of in the VideoControlComponent
        // if (await this.isSaveSnapshotAvailableAsync(commandContext, args)) {
        //     commandIds.push(SharedCommands.saveSnapshot);
        // }
        if (await this.isStartStopRecordingAvailableAsync(commandContext, commandArgs)) {
            this.configureStartStopRecordingInvalidatesAsync(commandContext as EntityCommandsUsageContext).fireAndForget();
            commandIds.push(SharedCommands.StartStopRecording);
        }

        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: SharedCommands.AddBookmark,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeAddBookmark(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isAddBookmarkAvailableAsync(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: SharedCommands.ExportVideo,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeExportAsync(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsCommandAvailableAsync((entityCommandContext) => this.isExportVideoAvailableAsync(entityCommandContext), commandContext),
        });
        // TODO FM: Once possible, saveSnapshot should be handled here instead of in the VideoControlComponent
        // bindings.addCommand({
        //     commandId: SharedCommands.saveSnapshot,
        //     executeCommandHandler: (executeCommandData) => this.safeExecuteCommand((entityExecuteCommandData) => this.executeSaveSnapshot(entityExecuteCommandData), executeCommandData),
        //     isCommandAvailableHandler: (commandContext) => this.safeIsCommandAvailableAsync((entityCommandContext) => this.isSaveSnapshotAvailableAsync(entityCommandContext), commandContext),
        // });
        bindings.addCommand({
            commandId: SharedCommands.StartStopRecording,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeStartStopRecording(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isStartStopRecordingAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) =>
                this.safeCanExecuteEntityCommandAsync((entityCommandContext) => this.canStartStopRecordingAsync(entityCommandContext), commandContext),
            getCommandDisplayHandler: (commandContext) =>
                this.safeGetEntityCommandDisplayAsync((entityCommandContext) => this.getStartStopRecordingDisplayAsync(entityCommandContext), commandContext),
        });
    }

    protected fillCommandRequirements(requirements: EntityCommandRequirements): EntityCommandRequirements {
        if (!requirements.supportedContentTypes) {
            requirements.supportedContentTypes = new GuidSet([VideoContentTypes.Event, VideoContentTypes.Video]);
        }

        if (!requirements.supportedEntityTypes) {
            requirements.supportedEntityTypes = new Set<string>([EntityTypes.Cameras]);
        }

        return super.fillCommandRequirements(requirements);
    }

    private async addBookmarkAsync(cameraEntity: ICameraEntity, message: string, timestamp: Date, playback: boolean): Promise<void> {
        const response = await cameraEntity.addBookmarkAsync(message, timestamp, playback);
        // TODO PROTECT VIDEO
        if (response && response.statusCode === HttpStatusCode.OK) {
            const notification = this.translateService.instant('STE_LABEL_BOOKMARKADDED') as string;
            this.toastService.show({ text: notification, flavorCustomColor: Color.Pistacchio });
        } else {
            const notification = this.translateService.instant('STE_MESSAGE_ERROR_ERRORADDINGBOOKMARK') as string;
            this.toastService.show({ text: notification, flavorCustomColor: Color.Fragola });
        }
    }

    private async canStartStopRecordingAsync(commandContext: EntityCommandContext): Promise<boolean> {
        const recordingState = await this.getRecordingStateAsync(commandContext);
        if (recordingState) {
            return !recordingState.isLocked;
        }
        return false;
    }

    private async configureStartStopRecordingInvalidatesAsync(commandContext: EntityCommandsUsageContext): Promise<void> {
        if (commandContext.entityCache && commandContext.invalidateDisplay) {
            const cameraEntity = await this.extractCameraEntity(commandContext);
            if (cameraEntity) {
                commandContext.entityCache
                    .detectFieldChangeAsync(
                        cameraEntity,
                        () => {
                            const _ = cameraEntity.recordingState;
                        },
                        (() => {
                            commandContext.invalidateDisplay(SharedCommands.StartStopRecording);
                            commandContext.invalidateCanExecute(SharedCommands.StartStopRecording);
                            return new Deferred<void>(true).promise;
                        }) as modificationHandlerField<string>
                    )
                    .fireAndForget();
            }
        }
    }

    private createCameraCommandDescriptor(
        commandId: IGuid,
        nameResourceId: string,
        icon: MeltedIcon,
        requirements?: EntityCommandRequirements,
        keyBinding?: string
    ): CommandDescriptor {
        return {
            id: commandId,
            nameResourceId,
            icon,
            groupNameResourceId: 'STE_ENTITY_CAMERA',
            groupIcon: MeltedIcon.Camera,
            keyBinding,
            requirements,
        };
    }

    private executeAddBookmark(executeCommandData: ExecuteEntityCommandData): void {
        this.executeAddBookmarkAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async executeAddBookmarkAsync(commandContext: EntityCommandContext): Promise<void> {
        const args = this.extractArgs(commandContext);
        const speficicData = this.extractAddBookmarkSpecificData(commandContext, args);
        if (speficicData) {
            args.entityId = speficicData.entityId;
        }

        const cameraEntity = await this.getEntityAsync<CameraEntity, ICameraEntity>(CameraEntity, undefined, args.entity, args.entityId, commandContext.entityCache);
        if (cameraEntity) {
            const timestamp: Date = speficicData?.timestamp ?? new Date();
            const message = speficicData?.message;
            const playback = speficicData?.playback ?? false;

            const now = moment();
            const diff = moment(now).diff(timestamp);
            const isLive = diff < 5000; // if the timestamp is less than 5 seconds ago, consider it live
            let startedRecording = false;
            // in live mode, start the recording before adding the bookmark
            if (isLive) {
                // only start recording if the camera was not already recording
                const isRecording = CameraHelper.isRecording(cameraEntity.recordingState);
                const isRecordingLocked = CameraHelper.isRecordingLocked(cameraEntity.recordingState);
                if (!isRecording && !isRecordingLocked) {
                    cameraEntity.startRecordingAsync().fireAndForget();
                    startedRecording = true;
                }
            }

            if (message) {
                await this.addBookmarkAsync(cameraEntity, message, timestamp, playback);
            } else {
                const bookmarkComponent = this.fullscreenService.displayModal(BookmarkComponent, { entityId: cameraEntity.id, timestamp });

                if (bookmarkComponent) {
                    bookmarkComponent.genModalAction.pipe(untilDestroyed(this)).subscribe(async (modalAction: MeltedModalAction) => {
                        if (modalAction === 'default') {
                            await this.addBookmarkAsync(cameraEntity, bookmarkComponent.message, timestamp, playback);
                        } else if (startedRecording) {
                            // if we started the recording automatically and the user canceled, stop recording
                            cameraEntity.stopRecordingAsync().fireAndForget();
                        }
                    });
                }
            }
        }
    }

    private async executeExportAsync(executeCommandData: ExecuteEntityCommandData): Promise<void> {
        const args = this.extractArgs(executeCommandData.commandContext);
        const eventContent = args.content;

        let source = args.entityId;
        let filename = '';
        let startTime = moment.utc(moment()).toISOString() || '';
        if (eventContent) {
            if (eventContent.parameters?.hasField(SharedContentFields.Timestamp)) {
                // ExportVideoComponent should need Timezone to display times accordingly
                let timezone: string | undefined;
                if (eventContent.parameters?.hasField(SharedContentFields.Timezone)) {
                    timezone = eventContent.parameters.getField<string>(SharedContentFields.Timezone);
                }
                const startTimestamp = eventContent.parameters.getField<string>(SharedContentFields.Timestamp);
                // Export video 5 seconds before the event timestamp. Would be nice to use the archiver 'Time to record before an event'
                const preStartTimestamp = moment(startTimestamp).subtract(5, 'second');
                startTime = this.timeService.formatTime(preStartTimestamp.toISOString(), 'YYYY-MM-DDTHH:mm:ss.SSSZ', true, false, timezone, false);
                filename = eventContent.description;
            }
            source = SafeGuid.parse(eventContent.source);
        }

        if (!filename && source) {
            const entity = await this.scClient.getEntityAsync(Entity, source);
            if (entity) {
                filename = entity?.name;
            }
        }

        const exportVideoModal = this.fullscreenService.displayModal(ExportVideoComponent, {
            entityId: source ?? SafeGuid.EMPTY,
        });

        exportVideoModal.form.controls.filename.setValue(filename);

        executeCommandData.isHandled = true;
    }

    private executeStartStopRecording(executeCommandData: ExecuteEntityCommandData): void {
        this.executeStartStopRecordingAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async executeStartStopRecordingAsync(commandContext: EntityCommandContext): Promise<void> {
        const cameraEntity = await this.extractCameraEntity(commandContext);
        if (cameraEntity) {
            const recordingState = await this.getRecordingStateAsync(commandContext, cameraEntity);
            if (recordingState) {
                if (recordingState.isRecording) {
                    await cameraEntity.stopRecordingAsync();
                } else {
                    await cameraEntity.startRecordingAsync();
                }
            }
        }
    }

    private extractAddBookmarkSpecificData(commandContext: EntityCommandContext, commandArgs?: CommandArgs): AddBookmarkSpecificData | undefined {
        let args = commandArgs;
        if (!args) {
            args = this.extractArgs(commandContext);
        }

        let entityId: IGuid | undefined = args.entityId;
        let timestamp: Date | undefined;
        let message: string | undefined;

        if (args.specificData) {
            const argsData = args.specificData as AddBookmarkSpecificData;
            entityId = argsData.entityId;
            timestamp = argsData.timestamp;
            message = argsData.message;
        } else if (args.content?.parameters) {
            // try to extract the timestamp from the content
            timestamp = args.content?.parameters.getFieldDate('timestamp');
        }

        if (!isNonEmptyGuid(entityId)) {
            return undefined;
        }

        const data: AddBookmarkSpecificData = { entityId };
        if (isString(message)) {
            data.message = message;
        }
        if (timestamp instanceof Date) {
            data.timestamp = timestamp;
            data.playback = true;
        }
        return data;
    }

    private async extractCameraEntity(commandContext: EntityCommandContext): Promise<ICameraEntity | null> {
        return this.extractEntityAsync<CameraEntity, ICameraEntity>(CameraEntity, commandContext);
    }

    private async getRecordingStateAsync(commandContext: EntityCommandContext, cameraEntity?: ICameraEntity): Promise<InternalRecordingState | undefined> {
        const entity = cameraEntity ?? (await this.extractCameraEntity(commandContext));
        if (!entity) {
            return;
        }

        return {
            isLocked: CameraHelper.isRecordingLocked(entity.recordingState),
            isRecording: CameraHelper.isRecording(entity.recordingState),
        };
    }

    private async getStartStopRecordingDisplayAsync(commandContext: EntityCommandContext): Promise<CommandDisplay | undefined> {
        const recordingState = await this.getRecordingStateAsync(commandContext);
        if (recordingState) {
            let resourceId = '';
            let icon = MeltedIcon.Record;
            if (recordingState.isRecording) {
                if (recordingState.isLocked) {
                    resourceId = 'STE_TOOLTIP_RECORDING_ON_LOCKED';
                    icon = MeltedIcon.RecordLocked;
                } else {
                    resourceId = 'STE_ACTION_STOPRECORDING';
                }
            } else {
                if (recordingState.isLocked) {
                    resourceId = 'STE_TOOLTIP_RECORDING_OFF_LOCKED';
                    icon = MeltedIcon.RecordLocked;
                } else {
                    resourceId = 'STE_BUTTON_STARTRECORDING';
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return { name: () => this.translateService?.instant(resourceId) as string, icon };
        }
    }

    private async isAddBookmarkAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(SharedCommands.AddBookmark, commandContext, commandArgs);
    }

    private async isExportVideoAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(SharedCommands.ExportVideo, commandContext, commandArgs);
    }

    private async isSaveSnapshotAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(SharedCommands.SaveSnapshot, commandContext, commandArgs);
    }

    private async isStartStopRecordingAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(SharedCommands.StartStopRecording, commandContext, commandArgs);
    }
}
