<ng-container *ngIf="formGroup && formGroup.controls" [formGroup]="formGroup">
    <gen-text [flavor]="header3Flavor">{{ 'STE_LABEL_STATUS' | translate }}</gen-text>
    <div class="cardholder-status-form">
        <div class="cardholder-status-form__status">
            <app-form-gen-combobox
                [class.active]="formGroup.value.accessStatus === AccessStatus.Active"
                [class.expired]="formGroup.value.accessStatus === AccessStatus.Expired"
                genLabel="{{ 'STE_LABEL_STATUS' | translate }}"
                formControlName="accessStatus"
                [selectedItem]="selectedStatusItem"
                [genItemsSource]="statuses"
                genMessage="{{ activationDate ? ('STE_MESSAGE_INFO_DATE_ACTIVATED' | translate) + ': ' + activationDate : '' }}"
                [readonly]="readonly"
            >
            </app-form-gen-combobox>
        </div>
        <div class="cardholder-status-form__activation" *ngIf="formGroup.value.accessStatus === AccessStatus.Inactive || formGroup.value.accessStatus === AccessStatus.Expired">
            <app-form-gen-combobox
                class="cardholder-status-form__activation-mode"
                [class.error]="formGroup.controls.activationDate.errors?.invalidRange"
                genLabel="{{ 'STE_LABEL_ACTIVATION' | translate }}"
                formControlName="activationMode"
                [genItemsSource]="activationModes"
                [readonly]="readonly"
            >
            </app-form-gen-combobox>
            <gen-datetime-input
                *ngIf="formGroup.value.activationMode === ActivationMode.ActivateOn"
                class="cardholder-status-form__activation-date"
                formControlName="activationDate"
                [genFlavor]="dateTimeFlavor"
                [genMessage]="formGroup.controls.activationDate.errors?.invalidRange ? ('STE_MESSAGE_ERROR_INVALID_RANGE' | translate) : ''"
                [genMessageSeverity]="errorSeverity"
                [readonly]="readonly"
            >
            </gen-datetime-input>
        </div>
        <div
            *ngIf="formGroup.value.accessStatus === AccessStatus.Active || formGroup.value.activationMode === ActivationMode.ActivateOn"
            class="cardholder-status-form__expiration"
        >
            <app-form-gen-combobox
                class="cardholder-status-form__expiration-mode"
                [class.error]="formGroup.controls.expirationDate.errors?.invalidRange"
                genLabel="{{ 'STE_LABEL_EXPIRATION' | translate }}"
                formControlName="expirationMode"
                [genItemsSource]="formGroup.value.accessStatus === AccessStatus.Active ? activeExpirationModes : inactiveExpirationModes"
                [readonly]="readonly"
            >
            </app-form-gen-combobox>
            <ng-container *ngIf="isExpirationDateOtherThanDontExpire()">
                <gen-datetime-input
                    *ngIf="formGroup.value.expirationMode === ActiveExpirationMode.Expiring || formGroup.value.expirationMode === InactiveExpirationMode.ActivateOn"
                    class="cardholder-status-form__expiration-date"
                    formControlName="expirationDate"
                    [genFlavor]="dateTimeFlavor"
                    [genMessage]="formGroup.controls.expirationDate.errors?.invalidRange ? ('STE_MESSAGE_ERROR_INVALID_RANGE' | translate) : ''"
                    [genMessageSeverity]="errorSeverity"
                    [readonly]="readonly"
                ></gen-datetime-input>
                <div
                    *ngIf="formGroup.value.expirationMode === ActiveExpirationMode.ExpiredAfterActivation || formGroup.value.expirationMode === ActiveExpirationMode.WhenNotUsed"
                    class="cardholder-status-form__days"
                >
                    <gen-numeric-input
                        formControlName="expirationDuration"
                        *ngIf="
                            formGroup.value.expirationMode === ActiveExpirationMode.ExpiredAfterActivation || formGroup.value.expirationMode === ActiveExpirationMode.WhenNotUsed
                        "
                        [min]="1"
                        [max]="9999"
                        [readonly]="readonly"
                    ></gen-numeric-input>
                    <span>{{ 'STE_UNIT_DAYS_LC' | translate }}</span>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
