import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonFlavor, Icon, PopupPosition, SelectionType } from '@genetec/gelato';
import { GenPopup, MeltedIcon } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { EntityBrowserComponent } from '@modules/shared/components/entity-browser/entity-browser/entity-browser.component';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { EntityBrowserSelection } from '@modules/shared/entity-browser/entity-browser-selection';
import { EntityTypeSet } from '@modules/shared/entity-browser/entity-type-set';
import { HierarchicalEntityBrowserFilter } from '@modules/shared/entity-browser/filters/hierarchical-entity-browser-filter';
import { EntityBrowserItemModel } from '@modules/shared/entity-browser/Items/entity-browser-item-model';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { WINDOW } from '@utilities/common-helper';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { IEntityTypeSet } from '@modules/shared/entity-browser/interfaces/entity-type-set.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { InternalPluginDescriptor } from '../../../shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginComponentExposure } from '../../../shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '../../../shared/interfaces/plugins/public/plugin-types';
import { HighlightTilePatternItem, TilePatternItem } from '../../models/tile-pattern-item';
import { TileCanvasStateService } from '../../state/tile-canvas-state.service';
import { TileCanvasComponent } from '../tile-canvas/tile-canvas.component';
import { TilesTaskEventService } from './services/tiles-task-event.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-tiles',
    templateUrl: './tiles-task.component.html',
    styleUrls: ['./tiles-task.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalPluginDescriptor({
    type: TilesTaskComponent,
    pluginTypes: [PluginTypes.Task],
    exposure: {
        id: TilesTaskComponent.pluginId,
        icon: 'gen-ico-layout-3x3',
        title: 'STE_LABEL_TILES',
        tags: ['STE_LABEL_NOUN_TILE', 'STE_LABEL_VIDEO', 'STE_LABEL_MONITORING', 'wall'],
        priority: 20,
    } as PluginComponentExposure,
    requirements: { globalPrivileges: [KnownPrivileges.surveillancePrivilege] },
    isSupported: () => true,
})
export class TilesTaskComponent extends TrackedComponent implements OnInit, AfterViewInit, OnDestroy {
    public static pluginId = SafeGuid.parse('6EF4FFC0-64F4-4DCD-9565-5B9C13C84D27');

    @ViewChild('tileCanvas') public tileCanvas!: TileCanvasComponent;
    @ViewChild(GenPopup) tilePatternPopup!: GenPopup;
    @ViewChild('entityBrowser')
    public set entityBrowserContent(child: EntityBrowserComponent) {
        this.entityBrowser = child;
    }
    public readonly tilePatternSelectionModalId = 'tilePatternSelectionModalId';
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly PopupPosition = PopupPosition;
    public readonly ListSelectionType = SelectionType;

    public allowChangeTilePattern = false;
    public isEntityBrowserLoaded = false;
    public isEntityBrowserPinned = false;
    public tilePatterns: TilePatternItem[] = [];

    private entityBrowser!: EntityBrowserComponent;
    private isEntityBrowserPinnedTag = 'WebApp.IsEntityBrowserPinned';
    private supportedEntityTypes: IEntityTypeSet = new EntityTypeSet([EntityTypes.Cameras, EntityTypes.Doors, EntityTypes.TileLayouts, EntityTypes.Zones]);

    constructor(
        private route: ActivatedRoute,
        private securityCenterClientService: SecurityCenterClientService,
        private tileTaskService: TilesTaskEventService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        trackingService: TrackingService,
        @Inject(WINDOW) private window: Window
    ) {
        super(trackingService);

        // create the supported tile patterns
        this.tilePatterns = [
            new TilePatternItem('1x1', MeltedIcon.Layout1X1, '1x1'),
            new TilePatternItem('1x2', MeltedIcon.Layout1X2, '1x2'),
            new TilePatternItem('2x1', MeltedIcon.Layout2X1, '2x1'),
            TileCanvasStateService.defaultTilePattern,
            new TilePatternItem('3x2', MeltedIcon.Layout2X3, '3x2'), // Icon is incorrectly name
            new TilePatternItem('3x3', MeltedIcon.Layout3X3, '3x3'),
            new HighlightTilePatternItem(this.translateService.instant('STE_LABEL_TILE_PATTERN_HIGHLIGHT_6') as string, MeltedIcon.TilePattern),
        ];
    }

    ngOnInit() {
        super.ngOnInit();

        this.isEntityBrowserPinned = this.window.localStorage.getItem(this.isEntityBrowserPinnedTag) !== 'false';
        this.allowChangeTilePattern = this.securityCenterClientService.scClient.isGlobalPrivilegeGranted(KnownPrivileges.changeTilePatternPrivilege);
    }

    ngAfterViewInit() {
        if (this.isEntityBrowserPinned) {
            this.refreshBrowser();
        }

        this.route.queryParams?.pipe(untilDestroyed(this)).subscribe((params) => {
            if (typeof params.id === 'string') {
                const parsed = SafeGuid.tryParse(params.id);
                if (parsed.success) {
                    this.tileCanvas.displayEntity(parsed.value).fireAndForget();
                }
            }
        });
    }

    public canSelectItem(model: EntityBrowserItemModel): Promise<boolean> {
        const entityTypeIsSupported = model.entityType?.type ? this.supportedEntityTypes.has(model.entityType) : false;
        return Promise.resolve(entityTypeIsSupported);
    }

    public async onBrowserEntitySelected(entitySelection: EntityBrowserSelection): Promise<void> {
        if (entitySelection.hasMany) {
            await this.tileCanvas.displayEntities(entitySelection.allIds);
        } else {
            const entityType = entitySelection.singleItem?.model.entityType.type;
            await this.tileCanvas.displayEntity(entitySelection.singleId, entityType);
        }
        this.changeDetectorRef.detectChanges();
    }

    public async onSelectLayoutChange(index: number): Promise<void> {
        const pattern = this.tilePatterns[index];
        if (pattern) {
            this.tileCanvas.setTilePattern(pattern);
            this.tileTaskService.publishTilePatternChangedEvent();
        }
        await this.tilePatternPopup.close();
    }

    public async togglePatternSelectionPopup(): Promise<void> {
        await this.tilePatternPopup.toggle();
    }

    public toggleEntityBrowser(): void {
        this.isEntityBrowserPinned = !this.isEntityBrowserPinned;
        this.window.localStorage.setItem(this.isEntityBrowserPinnedTag, this.isEntityBrowserPinned.toString());

        if (!this.isEntityBrowserLoaded && this.isEntityBrowserPinned) {
            this.refreshBrowser();
        }
    }

    private refreshBrowser() {
        const filter = new HierarchicalEntityBrowserFilter(this.supportedEntityTypes);
        this.entityBrowser?.refreshAsync(filter).finally(() => (this.isEntityBrowserLoaded = true));
    }
}
