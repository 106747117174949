import { GenAngularModule } from '@genetec/gelato-angular';
import { Router } from '@angular/router';
import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetBaseUriForCodeGen } from '@utilities/base-uri-getter';
import { SharedModule } from '@shared/shared.module';
import { ClearanceOptionsComponent } from './components/options/clearance-options.component';
import { baseUrl, ClearanceClient } from './api/api';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@NgModule({
    imports: [CommonModule, SharedModule, GenAngularModule],
    declarations: [ClearanceOptionsComponent],
    providers: [
        ClearanceClient,
        {
            provide: baseUrl,
            deps: [],
            useFactory: GetBaseUriForCodeGen,
        },
    ],
    exports: [],
})
export class ClearanceModule {
    //#region Constructors

    constructor(router: Router, injector: Injector) {}

    //#endregion
}
