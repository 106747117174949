import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PlateRead } from '@modules/lpr/api/api';
import { TextFlavor } from '@genetec/gelato';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { BehaviorSubject, forkJoin, from, Observable } from 'rxjs';
import { filter, first, map, shareReplay, take, tap } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { DateFormat } from '@genetec/gelato-angular';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-read-general-information',
    templateUrl: './read-general-information.component.html',
    styleUrls: ['./read-general-information.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadGeneralInformationComponent implements OnInit {
    @Input()
    public plateRead?: PlateRead;
    public isLoading$: Observable<boolean>;
    public generalInformation: { key: string; value: string }[] = [];

    public readonly TextFlavor = TextFlavor;

    private isLoadingSubject$ = new BehaviorSubject<boolean>(true);

    constructor(private securityCenterClientService: SecurityCenterClientService, private timeService: TimeService, private translateService: TranslateService) {
        this.isLoading$ = this.isLoadingSubject$.asObservable();
    }

    ngOnInit() {
        if (this.plateRead?.source) {
            const patroller$ = this.getEntityObservable(this.plateRead.source.patrollerId).pipe(first(), shareReplay(1));
            const unit$ = this.getEntityObservable(this.plateRead.source.unitId).pipe(first(), shareReplay(1));

            // forkJoin will continue only when all input observables are completed
            forkJoin([patroller$, unit$])
                .pipe(take(1), untilDestroyed(this))
                .subscribe(() => {
                    this.isLoadingSubject$.next(false);
                });

            patroller$.pipe(this.addGeneralEntryFromEntityWithTitle('STE_ENTITY_PATROLLER'), untilDestroyed(this)).subscribe();
            unit$.pipe(this.addGeneralEntryFromEntityWithTitle('STE_ENTITY_LPR_UNIT'), untilDestroyed(this)).subscribe();
        } else {
            this.isLoadingSubject$.next(false);
        }

        if (this.plateRead?.timestamp) {
            const formattedTimestamp = this.timeService.formatTime(this.plateRead.timestamp, DateFormat.DateTime, true, false, undefined, false);
            this.addGeneralEntry('STE_LABEL_TIME', formattedTimestamp);
        }
        if (this.plateRead?.address) {
            this.addGeneralEntry('STE_LABEL_ADDRESS', this.plateRead.address);
        }
    }

    private addGeneralEntry(key: string, value: string): void {
        const translatedKey = this.translateService.instant(key) as string;
        this.generalInformation.push({ key: translatedKey, value });
    }

    private getEntityObservable(entityId?: IGuid): Observable<IEntity | null> {
        return from(entityId ? this.securityCenterClientService.scClient.getEntityAsync<Entity, IEntity>(Entity, entityId) : Promise.resolve(null));
    }

    private addGeneralEntryFromEntityWithTitle =
        (title: string) =>
        (source: Observable<IEntity | null>): Observable<string | undefined> =>
            source.pipe(
                this.getEntityName(),
                filter((entityName) => !!entityName),
                tap((entityName) => this.addGeneralEntry(title, entityName as string))
            );

    private getEntityName =
        () =>
        (source: Observable<IEntity | null>): Observable<string | undefined> =>
            source.pipe(map((entity) => entity?.name));
}
