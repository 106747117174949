import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, TextFlavor } from '@genetec/gelato';
import { TranslateService } from '@ngx-translate/core';
import { GuidMap, IGuid } from 'safeguid';
import { EventEntry, Watchlist, WatchlistPriority } from '@modules/shared/api/api';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { concatMap, delayWhen, map, shareReplay, take, tap } from 'rxjs/operators';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Entity } from 'RestClient/Client/Model/Entity';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { SharedContentFields } from '@modules/shared/enumerations/shared-content-fields';
import { SideContextDataService } from '@modules/shared/services/side-context-data/side-context-data.service';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { UserWatchlistService } from '@modules/shared/services/watchlist/user-watchlist.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { EventMonitoringContext } from '@modules/shared/enumerations/analytics-event-monitoring-context';
import { EventMonitoringLoggerService } from '@modules/shared/services/analytics/event-monitoring-logger.service';
import { CachedMonitoredEntityEvent } from '@modules/general/services/Events/cached-monitored-entity-event';
import { EventsMonitoringService } from '@modules/general/services/Events/events-monitoring.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

export class EventItem {
    get eventNumber(): number {
        return this.cachedEvent.eventNumber;
    }

    get id(): IGuid {
        return this.cachedEvent.id;
    }

    get isNew(): boolean {
        return this.cachedEvent.isNew;
    }

    get eventEntry(): EventEntry {
        return this.cachedEvent.monitoredEntityEvent.event;
    }

    get formattedTime(): string | null {
        return this.cachedEvent.formattedTime;
    }

    get formattedDate(): string | null {
        return this.cachedEvent.formattedDate;
    }

    get priority(): WatchlistPriority {
        return this.cachedEvent.priority;
    }

    constructor(public entity: IEntity, public cachedEvent: CachedMonitoredEntityEvent) {}
}
@UntilDestroy()
@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListComponent implements OnInit, OnDestroy {
    @ViewChild(CdkVirtualScrollViewport) viewPort?: CdkVirtualScrollViewport;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;

    public hasEvents$: Observable<boolean>;
    public events$: Observable<EventItem[]>;
    public hasMultiplePriorities$: Observable<boolean>;
    public isLoading$: Observable<boolean>;
    public isScrollToTopVisible = false;

    private cachedEntities = new GuidMap<IEntity | null>();
    private isLoadingSubject$ = new BehaviorSubject<boolean>(true);
    private isScrolled = false;
    private watchlist$: Observable<Watchlist | null>;

    constructor(
        private eventsMonitoringService: EventsMonitoringService,
        private securityCenterClientService: SecurityCenterClientService,
        private contentProviderService: ContentProviderService,
        private sideContextDataService: SideContextDataService,
        private userWatchlistService: UserWatchlistService,
        private translateService: TranslateService,
        private eventMonitoringLoggerService: EventMonitoringLoggerService
    ) {
        this.isLoading$ = this.isLoadingSubject$.asObservable();
        this.watchlist$ = this.userWatchlistService.watchlist$.pipe(shareReplay(1));
        this.hasMultiplePriorities$ = this.watchlist$.pipe(map((watchlist) => new Set<WatchlistPriority>(watchlist?.entries.map((entry) => entry.priority)).size > 1));
        this.events$ = this.eventsMonitoringService.receivedEvents$.pipe(
            delayWhen(() => this.fetchEntities()),
            map((events) => this.createEventItems(events)),
            map((eventItems) => this.sortEventItemsByPriority(eventItems)),
            shareReplay(1),
            untilDestroyed(this)
        );
        this.hasEvents$ = this.events$.pipe(
            map((events) => events.length > 0),
            untilDestroyed(this)
        );

        this.eventsMonitoringService.newEvents$.pipe(untilDestroyed(this)).subscribe(() => this.updateNewEventsButtonVisibility());
    }

    ngOnInit() {
        this.eventsMonitoringService.toggleLiveMonitoringState();
    }

    ngOnDestroy() {
        this.eventsMonitoringService.toggleLiveMonitoringState();
    }

    public trackByItem(index: number, item: EventItem): string {
        return item.id.toString();
    }

    public onDismissEvent(eventItem: EventItem): void {
        this.eventMonitoringLoggerService.logEvent(
            'Manually dismissed event',
            'Event was manually dismissed using the "X" when hovering the event in the event list',
            EventMonitoringContext.Watchlist
        );
        this.eventsMonitoringService.dismissEvent(eventItem.eventNumber);
    }

    public onDismissPriority(priority: string): void {
        this.eventsMonitoringService.dismissEvents(Number(priority));
        this.eventMonitoringLoggerService.logEvent(
            'Dismissed all event of a specific priority',
            'All events of a specific priority have been dismissed using the button in the event list',
            EventMonitoringContext.Watchlist,
            priority
        );
    }

    public onItemSeen(eventItem: EventItem): void {
        this.eventsMonitoringService.markAsRead(eventItem.eventNumber);
    }

    public async openEventDetailsAsync(event: EventEntry): Promise<void> {
        const eventField = new FieldObject();
        eventField.setFieldGuid(SharedContentFields.EventId, event.id);
        eventField.setFieldGuid(SharedContentFields.ContextId, event.contextId);

        const content = await this.contentProviderService.getContentFromFieldsAsync(eventField);
        if (content) {
            this.sideContextDataService?.pushContent(content);
            this.eventMonitoringLoggerService.logEvent(
                'Opened event details',
                'Opened event details in the sidepane by clicking on a specific event in the event list',
                EventMonitoringContext.Watchlist
            );
        }
    }

    public translatePriority(priority: string): string {
        const priorityString = Number(priority) === WatchlistPriority.Normal ? 'STE_LABEL_OTHER' : 'STE_LABEL_FLAGGED';
        return this.translateService.instant(priorityString) as string;
    }

    public didPriorityChange(currentEvent: EventItem, previousEvent: EventItem): boolean {
        return currentEvent.priority !== previousEvent?.priority;
    }

    public onScroll(topIndex: number): void {
        this.isScrolled = topIndex > 0;
        if (!this.isScrolled) this.isScrollToTopVisible = false;
    }

    public scrollBackToTop(): void {
        this.viewPort?.scrollToIndex(0);
    }

    private fetchEntities(): Observable<any> {
        return this.watchlist$.pipe(
            take(1),
            tap(() => this.isLoadingSubject$.next(true)),
            map((watchlist) => this.createEntityQueryFromWatchlist(watchlist)),
            tap((entityQuery) => this.addEntityIdsToCache(entityQuery?.guids)),
            concatMap((entityQuery) => this.getEntitiesObservable(entityQuery)),
            tap((entities) => this.addEntitiesToCache(entities)),
            tap(() => this.isLoadingSubject$.next(false))
        );
    }

    private addEntityIdsToCache(entityIds?: Set<IGuid>): void {
        entityIds?.forEach((entityId) => this.cachedEntities.set(entityId, null));
    }

    private addEntitiesToCache(entities?: IEntity[]): void {
        entities?.forEach((entity) => this.cachedEntities.set(entity.id, entity));
    }

    private getEntitiesObservable(entityQuery: EntityQuery | null): Observable<IEntity[]> {
        return from(entityQuery ? this.securityCenterClientService.scClient.getEntitiesAsync<Entity, IEntity>(Entity, entityQuery) : Promise.resolve([]));
    }

    private createEntityQueryFromWatchlist(watchlist: Watchlist | null): EntityQuery | null {
        const entityIds = watchlist?.entries?.map((entry) => entry.entityId);
        const entityIdsNotInCacheToQuery = entityIds?.filter((entityId) => !this.cachedEntities.has(entityId));
        if (entityIdsNotInCacheToQuery && entityIdsNotInCacheToQuery?.length > 0 && this.securityCenterClientService?.scClient) {
            return this.createEntityQuery(entityIdsNotInCacheToQuery);
        }
        return null;
    }

    private createEntityQuery(entityIds: IGuid[]): EntityQuery {
        const entityQuery = new EntityQuery();
        entityQuery.guids = new Set(entityIds);
        entityQuery.fields.add(EntityFields.nameField);
        entityQuery.fields.add(EntityFields.entityTypeField);
        return entityQuery;
    }

    private createEventItems(events: CachedMonitoredEntityEvent[]): EventItem[] {
        return events.reduce<EventItem[]>((result: EventItem[], event: CachedMonitoredEntityEvent) => {
            const foundEntity = this.cachedEntities.get(event.entityId);
            if (foundEntity) {
                result.push(new EventItem(foundEntity, event));
            }
            return result;
        }, []);
    }

    private sortEventItemsByPriority(eventItems: EventItem[]): EventItem[] {
        return eventItems.sort((item1, item2) => {
            return item1.priority < item2.priority ? 1 : item1.priority > item2.priority ? -1 : 0;
        });
    }

    private updateNewEventsButtonVisibility(): void {
        this.isScrollToTopVisible = this.isScrolled;
    }
}
