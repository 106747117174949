import { Receiver as ngxsReceiver } from '@ngxs-labs/emitter';
import { ReceiverMetaData } from '@ngxs-labs/emitter/lib/core/internal/internals';
import { ImmutableContext } from '../immder-adapter';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function Receiver(options?: Partial<ReceiverMetaData>): MethodDecorator {
    return <T>(target: object, key: string | symbol, descriptor: TypedPropertyDescriptor<T>) => {
        const immutable = ImmutableContext()(target, key, descriptor) as TypedPropertyDescriptor<T>;
        return ngxsReceiver(options)(target, key, immutable);
    };
}
