<!--Name Template-->
<ng-template #nameTemplate let-params="params">
    <div class="flex-cell">
        <div class="va-center">
            {{ params.data.name }}
        </div>
    </div>
</ng-template>

<!--Elapsed Time Template-->
<ng-template #elapsedTimeTemplate let-params="params">
    <div class="flex-cell">
        <div class="va-center">
            {{ params.data.elapsedTime.toString() }}
        </div>
    </div>
</ng-template>

<!--Cumulative Time Template-->
<ng-template #cumulativeTimeTemplate let-params="params">
    <div class="flex-cell">
        <div class="va-center">
            {{ params.data.cumulativeTime.toString() }}
        </div>
    </div>
</ng-template>

<gen-grid *ngIf="currentStepDisplayed$ | async as currentStep" [genColumns]="1">
    <!--    Title & Menu Button-->
    <gen-grid-item id="procedure-header">
        <div>
            <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_LABEL_PROCEDURE' | translate }}</gen-text>
        </div>
        <div>
            <gen-button *ngIf="procedureMenuOptions.length > 0" id="contextMenuButton" [flavor]="ButtonFlavor.Flat" (click)="toggleContextMenu()">
                <gen-icon [icon]="MeltedIcon.EllipsisVertical"></gen-icon>
            </gen-button>
            <gen-menu #contextMenu targetSelector="#contextMenuButton" [appMenuDataSource]="procedureMenuOptions"> </gen-menu>
        </div>
    </gen-grid-item>

    <!--    Start-->
    <gen-grid-item *ngIf="isStart(currentStep.id)" class="pl-4 pr-4 ha-center gen-flex gen-flex-column">
        <gen-button [flavor]="ButtonFlavor.Solid" [disabled]="isReadonly" (click)="onOptionClicked(startId)" [textContent]="'STE_LABEL_START' | translate"></gen-button>
    </gen-grid-item>

    <!--    Loading-->
    <gen-grid-item *ngIf="isLoading(currentStep.id)" class="pl-4 pr-4 ha-center gen-flex gen-flex-column">
        <gen-spinner [size]="SpinnerSize.Large" class="ha-center va-center">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
    </gen-grid-item>

    <!--    Solved-->
    <gen-grid-item *ngIf="isInterrupted(currentStep.id)" class="pl-4 pr-4 va-center ha-center gen-flex gen-flex-column">
        <div id="incident-procedure-interrupted">
            <gen-text [flavor]="TextFlavor.Error">
                <gen-icon id="incident-procedure-interrupted-icon" [icon]="MeltedIcon.Close"></gen-icon>
                {{ 'STE_LABEL_INTERRUPTED' | translate }}
            </gen-text>
        </div>
    </gen-grid-item>

    <!--    End-->
    <gen-grid-item *ngIf="isEnd(currentStep.id)" class="pl-4 pr-4 va-center ha-center gen-flex gen-flex-column">
        <div id="incident-procedure-completed">
            <gen-text [flavor]="TextFlavor.Success">
                <gen-icon id="incident-procedure-completed-icon" [icon]="MeltedIcon.Checkmark"></gen-icon>
                {{ completedLabel }}
            </gen-text>
        </div>
        <gen-button class="mt-1" (click)="toggleTTRModal()" [flavor]="ButtonFlavor.Flat" [textContent]="'STE_LABEL_MORE_DETAILS' | translate"></gen-button>
    </gen-grid-item>

    <!--    In Progress-->
    <gen-grid-item *ngIf="isInProgress(currentStep.id)" class="pl-4 pr-4 ha-center gen-flex gen-flex-column">
        <gen-text [flavor]="TextFlavor.Secondary">{{ currentStep.name }}</gen-text>
        <gen-text class="linebreaks" [flavor]="TextFlavor.Header3">{{ currentStep.instructions }}</gen-text>
        <div *ngIf="currentStep.isCommentRequired || manualCommentOpen" class="ha-left mt-2">
            <app-incident-comment
                [(ngModel)]="comment"
                [multiline]="5"
                [disabled]="isReadonly"
                [placeholder]="this.isReadonly ? '' : ((currentStep.isCommentRequired ? 'STE_LABEL_REQUIRED_COMMENT' : 'STE_LABEL_ENTER_COMMENT') | translate)"
                [maxlength]="1024"
                [required]="!isReadonly && currentStep.isCommentRequired"
                [label]=""
                #commentInput="ngModel"
            ></app-incident-comment>
        </div>
        <div *ngIf="!currentStep.isCommentRequired && !manualCommentOpen" class="ha-center mt-1">
            <gen-button (click)="addComment()" [disabled]="isReadonly" [flavor]="ButtonFlavor.Flat" [textContent]="'STE_LABEL_ADD_COMMENT' | translate"></gen-button>
        </div>
        <div *ngIf="currentStep.options.length <= 4" class="ha-center gen-flex gen-flex-column p-1">
            <gen-button
                *ngFor="let currentStepOption of currentStep.options"
                class="mt-1"
                [flavor]="ButtonFlavor.Solid"
                [disabled]="isReadonly || (commentInputModel?.invalid ?? false)"
                (click)="onOptionClicked(currentStepOption.id)"
            >
                {{ currentStepOption.name }}
            </gen-button>
        </div>
        <div *ngIf="currentStep.options.length > 4" class="ha-center gen-flex gen-flex-column p-1">
            <gen-button
                id="optionsButton"
                [flavor]="ButtonFlavor.Solid"
                (click)="toggleOptions()"
                [disabled]="isReadonly || (commentInputModel?.invalid ?? false)"
                [textContent]="'STE_LABEL_SELECT_OPTION' | translate"
            >
            </gen-button>
            <gen-popup #optionsPopup id="optionsPopup" [position]="PopupPosition.Bottom" [tailVisible]="true" targetSelector="#optionsButton">
                <div class="ha-center gen-flex gen-flex-column p-2">
                    <gen-button *ngFor="let currentStepOption of currentStep.options" class="mt-1" [flavor]="ButtonFlavor.Solid" (click)="onOptionClicked(currentStepOption.id)">
                        {{ currentStepOption.name }}
                    </gen-button>
                </div>
            </gen-popup>
        </div>
    </gen-grid-item>

    <!--    Back & Skip-->
    <gen-grid-item *ngIf="!isLoading(currentStep.id)" id="procedure-footer" class="pl-2 pr-2">
        <div>
            <gen-button [flavor]="ButtonFlavor.Flat" (click)="back()" [disabled]="isReadonly" [hidden]="isCurrentStepRoot || isStart(currentStep.id)">
                <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_LABEL_BACK' | translate }}</gen-text>
            </gen-button>
        </div>
        <div>
            <gen-button [flavor]="ButtonFlavor.Flat" (click)="toggleSkipModal()" [disabled]="isReadonly" [hidden]="!currentStep.isSkippable">
                <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_LABEL_SKIP' | translate }}</gen-text>
            </gen-button>
        </div>
    </gen-grid-item>
</gen-grid>

<!--Skip modal-->
<gen-melted-modal
    id="skip-step-modal"
    [genTitle]="'STE_TITLE_DSOP_SKIP_REASON' | translate"
    [genDefaultActionText]="'STE_LABEL_SKIP' | translate"
    [genOnDefaultAction]="skipAction"
    [genIsDefaultActionDisabled]="!skipReasonInput.valid"
    [genCanClose]="true"
>
    <gen-melted-modal-body class="gen-flex gen-flex-column">
        <app-incident-comment [(ngModel)]="skipReason" [multiline]="5" [maxlength]="1024" [label]="" #skipReasonInput="ngModel"></app-incident-comment>
    </gen-melted-modal-body>
</gen-melted-modal>

<!--Restart Modal-->
<gen-melted-modal
    id="restart-modal"
    [genTitle]="'STE_TITLE_ENTER_RESTART_REASON' | translate"
    [genAlternativeActionText]="'STE_BUTTON_CANCEL' | translate"
    [genOnAlternativeAction]="closeAction"
    [genDefaultActionText]="'STE_LABEL_RESTART' | translate"
    [genOnDefaultAction]="restartAction"
    [genIsDefaultActionDisabled]="!restartReasonInput.valid"
    [genCanClose]="true"
>
    <gen-melted-modal-body class="gen-flex gen-flex-column">
        <app-incident-comment [(ngModel)]="restartReason" [multiline]="5" [maxlength]="1024" [label]="" #restartReasonInput="ngModel"></app-incident-comment>
    </gen-melted-modal-body>
</gen-melted-modal>

<!--TTR Modal-->
<gen-melted-modal
    id="ttr-modal"
    [genTitle]="'STE_TITLE_PROCEDURE_TTR' | translate"
    [genDefaultActionText]="'STE_LABEL_OK' | translate"
    [genOnDefaultAction]="ttrOkAction"
    [genCanClose]="true"
>
    <gen-melted-modal-body>
        <gen-melted-table
            *ngIf="ttrDetails$ | async as ttrDetail"
            id="ttr-details-table"
            [genItemsSource]="ttrDetail"
            [genColumns]="ttrColumns"
            [genSelectionType]="Table.SelectionType.None"
            [genFit]="Fit.Table.Fill"
            [genDensity]="Density.Table.Default"
        ></gen-melted-table>
    </gen-melted-modal-body>
</gen-melted-modal>
