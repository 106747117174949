import { Component, OnInit } from '@angular/core';
import { InternalPluginDescriptor } from 'src/app/modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from 'src/app/modules/shared/interfaces/plugins/public/plugin-types';
import { FilterTypes } from 'src/app/modules/shared/enumerations/filter-types';
import { TrackingService } from 'src/app/modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { FilterPluginBaseComponent } from './filter-plugin-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template: `<app-filter-entity [entityTypes]="entityTypes" [refresherType]="refresherType" [descriptor]="descriptor" (valueChange)="updateFilter($event)"></app-filter-entity>`,
})
@InternalPluginDescriptor({
    type: FilterPluginEntityComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginEntityComponent.pluginId,
        subSection: FilterTypes.Entities,
    },
    isSupported: () => true,
})
export class FilterPluginEntityComponent extends FilterPluginBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('D3D756C0-912B-4425-9C9C-13EDA380C1D4');

    public entityTypes: string[] = [];
    public refresherType?: SafeGuid;

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginEntityComponent.pluginId);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.extractTypeSpecificFields();
    }

    private extractTypeSpecificFields(): void {
        this.entityTypes = Array.from(this.getField('entityTypes') ?? []);
        const guidStr = this.getField<string>('refresherType');
        this.refresherType = guidStr ? SafeGuid.parse(guidStr) : undefined;
    }
}
