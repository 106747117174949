import { InjectionToken } from '@angular/core';
import { WebSocketContent } from './web-socket-content';

export const WEB_SOCKET_SERIALIZER_SERVICE = new InjectionToken<WebSocketSerializerService>('Service to serialize a web socket content');

export interface WebSocketSerializerService {
    /**
     * Serializes a WebSocketContent into a string
     */
    serialize(webSocketContent: WebSocketContent): string;
}
