import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export const SharedCommands = Constants({
    // TODO YN: for now only one file for all, must be separated by modules
    AddBookmark: SafeGuid.parse('A25E9C64-487C-40BF-8A0A-CF2308E8C85D'),
    ClearTiles: SafeGuid.parse('840F2AE8-CD63-4CC2-B78E-F35E92D85995'),
    ClearTile: SafeGuid.parse('C8813FAF-9AB9-4824-9A42-98FEE439AF17'),
    DisplayEntities: SafeGuid.parse('D8603B2B-95E6-4728-A35A-060EFE6F40E2'),
    ExpandTile: SafeGuid.parse('F815358D-B9ED-4446-A1F6-D459B71F53BB'),
    ExportVideo: SafeGuid.parse('B8FBAF72-F7BA-4EF4-8082-6A196CB863B5'),
    FastForward: SafeGuid.parse('8940EDC3-F558-4637-9DC0-C2DA587D1F49'),
    MaximizeTileFullscreen: SafeGuid.parse('DFD9A3AF-60CD-4EE2-8526-232EFF9CCF23'),
    PauseVideo: SafeGuid.parse('5DFC0518-88F8-43B6-A50E-030E67567341'),
    PlayVideo: SafeGuid.parse('F92407D8-8E74-4FE3-A061-80889F7736BC'),
    PtzStartPanTilt: SafeGuid.parse('580E4C7F-BD2C-4F10-B66C-F5CE42EE0C2B'),
    PtzStopPanTilt: SafeGuid.parse('DFB8EDBC-5DD9-470A-8E8E-533ED1A3B5E9'),
    Rewind: SafeGuid.parse('249F3B04-6848-4737-86DD-FDD857ECD7B0'),
    SaveSnapshot: SafeGuid.parse('7D62085F-BDD7-479A-ADD7-9167DA79147A'),
    ShowVideoDiagnostics: SafeGuid.parse('A4E46E65-778B-4FA3-99B8-71C9F6DC59E6'),
    ShowOptions: SafeGuid.parse('BD68DF2F-38CB-4FFF-B794-B8EBADC49F89'),
    Search: SafeGuid.parse('70C4D823-FCD1-4D5D-8F85-2D352DF024EC'),
    SelectNextTile: SafeGuid.parse('CD9FEF50-57A1-4F60-8258-9F43B4D7DD6C'),
    SelectPreviousTile: SafeGuid.parse('E33463D5-C159-46AA-8E5C-03E2B3FA2DDA'),
    ShowMessage: SafeGuid.parse('C4D42245-BEC3-4893-9767-66110B699C9E'),
    SlowMotion: SafeGuid.parse('BACF9DE9-ABC2-4277-83D7-965C6636D011'),
    StartStopRecording: SafeGuid.parse('46D7A08B-BE2B-424F-A890-8F1422011258'),
    ToggleListen: SafeGuid.parse('44737E74-1FD5-4A93-9E69-DBE87F1D4385'),
    ShareEntity: SafeGuid.parse('2E13993E-8F7A-4CDA-ADF4-E3CD6F46052A'),
    SendMessage: SafeGuid.parse('3B009589-0527-43D1-913A-2BF4238102F4'),
    CloseNavBar: SafeGuid.parse('31DF55A0-103F-4114-B8F2-C4EF9B8908B9'),
    ToggleAddToWatchlist: SafeGuid.parse('9678DF87-3AA5-4788-90D2-3069AC9D6A2D'),
    ToggleMaximizeVideo: SafeGuid.parse('AFEB6FF4-B3EF-4E44-BB08-032743C573C1'),
    MinimizeVideo: SafeGuid.parse('9EB2FC41-715C-4DCF-AA2E-8CF74A978CAF'),
});
