<app-filter-host [filterDescriptor]="descriptor" [state]="state" (filterToggled)="onFilterToggled($event)" [popupPosition]="popupPosition">
    <div class="entity-browser-wrapper">
        <app-entity-browser
            #browser
            [filter]="entityBrowserFilter"
            [alwaysShowCheckedItemsActions]="alwaysShowCheckedItemsActions"
            [areItemsCheckable]="true"
            (checkedEntitiesChanged)="onBrowserCheckedEntitiesChanged($event)"
        ></app-entity-browser>
    </div>
</app-filter-host>
