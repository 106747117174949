export interface IBitRateProvider {
  getBitRate(): BitRate;
}

export class BitRateComputer implements IBitRateProvider {
  private static readonly SamplesTimeDelta = 150;
  private static readonly MaxSamplesCount = 32;
  private static readonly MASK_SAMPLE_IDX = 0x1F;
  private static readonly TotalTimeSpanInBuffer = BitRateComputer.SamplesTimeDelta * BitRateComputer.MaxSamplesCount;

  private readonly m_ringBuffer = Array<number>(BitRateComputer.MaxSamplesCount);

  private m_lastIdx: number = 0;

  public getBitRate(): BitRate {
    return this.compute();
  }

  public addBytes(byteCount: number) {
    const now = new Date();
    const num = Math.trunc(now.getTime() / BitRateComputer.SamplesTimeDelta);
    if (this.m_lastIdx === num) {
      this.m_ringBuffer[num & BitRateComputer.MASK_SAMPLE_IDX] += byteCount;
    } else if (num - this.m_lastIdx >= BitRateComputer.MaxSamplesCount) {
      this.resetBuffer();
      this.m_ringBuffer[num & BitRateComputer.MASK_SAMPLE_IDX] = byteCount;
    } else {
      for (let index = this.m_lastIdx + 1; index < num; ++index) {
        this.m_ringBuffer[index & BitRateComputer.MASK_SAMPLE_IDX] = 0;
      }
      this.m_ringBuffer[num & BitRateComputer.MASK_SAMPLE_IDX] = byteCount;
    }

    this.m_lastIdx = num;
  }

  public compute(): BitRate {
    const now = new Date();

    const idx = Math.trunc(now.getTime() / BitRateComputer.SamplesTimeDelta);
    if (idx === this.m_lastIdx) {
      // Use all the bytes
    } else if (idx - this.m_lastIdx >= BitRateComputer.MaxSamplesCount) {
      // We went full circle around the buffer
      this.resetBuffer();
    } else {
      // We must clear the timeranges in-between (for which we did not receive any bytes)
      for (let i = this.m_lastIdx + 1; i <= idx; ++i) {
        this.m_ringBuffer[i & BitRateComputer.MASK_SAMPLE_IDX] = 0;
      }
    }
    this.m_lastIdx = idx;

    // Actually compute the bitrate
    let sum = 0;
    for (let index = 0; index < BitRateComputer.MaxSamplesCount; ++index) {
      sum += this.m_ringBuffer[index];
    }

    return new BitRate(sum, BitRateComputer.TotalTimeSpanInBuffer);
  }

  private resetBuffer() {
    for (let index = 0; index < BitRateComputer.MaxSamplesCount; ++index) {
      this.m_ringBuffer[index] = 0;
    }
  }
}

export class BitRate {
  private static readonly BitsPerByte = 8;

  private readonly m_bitCount: number;
  private readonly m_timeSpanInSeconds: number;

  public get BitsPerSecond(): number {
    return this.m_bitCount / this.m_timeSpanInSeconds;
  }

  constructor(byteCount: number, timeSpanInMs: number) {
    if (timeSpanInMs === 0) {
      throw new Error('time span cannot be 0');
    }

    this.m_bitCount = byteCount * BitRate.BitsPerByte;
    this.m_timeSpanInSeconds = timeSpanInMs / 1000;
  }

  public toString(): string {
    const bitsPerSec = this.BitsPerSecond;
    if (bitsPerSec <= 0) {
      return '0 kbps';
    }
    if (bitsPerSec < 1024 * 1024) {
      return `${(bitsPerSec / 1024.0).toFixed(1)} kbps`;
    }
    if (bitsPerSec < 1024 * 1024 * 1024) {
      return `${(bitsPerSec / 1024.0 / 1024.0).toFixed(1)} Mbps`;
    }
    return `${(bitsPerSec / 1024.0 / 1024.0 / 1024.0).toFixed(1)} Gbps`;
  }
}
