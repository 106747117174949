export enum SecurityCenterVersionEquality{
    Greater,
    Equal,
    Smaller
}

export class SecurityCenterVersion {
    private constructor(public major: number, public minor: number, public build: number, public revision: number) {
    }

    public static isCompatible(version: SecurityCenterVersion | string | null, minimumVersion: SecurityCenterVersion | string | null, maximumVersion: SecurityCenterVersion | string | null = null): boolean {
        const currentVersion = version instanceof SecurityCenterVersion ? version : SecurityCenterVersion.tryParse(version);
        const minVersion = minimumVersion instanceof SecurityCenterVersion ? minimumVersion : SecurityCenterVersion.tryParse(minimumVersion);
        const maxVersion = maximumVersion instanceof SecurityCenterVersion ? maximumVersion : SecurityCenterVersion.tryParse(maximumVersion);

        return currentVersion?.isCompatible(minVersion, maxVersion) ?? false;
    }

    public static tryParse(version: string | null): SecurityCenterVersion | null {
        if (!version) {
            return null;
        }

        const element = version.split('.');
        if (element.length > 4) {
            return null;
        }

        let major: number | null = null, minor: number | null = null, build: number | null = null, revision: number | null = null;
        if (!!element[0]) {
            major = parseInt(element[0]);
        }
        if (!!element[1]) {
            minor = parseInt(element[1]);
        }
        if (!!element[2]) {
            build = parseInt(element[2]);
        }
        if (!!element[3]) {
            revision = parseInt(element[3]);
        }

        if (!major || !minor) {
            return null;
        }

        return new SecurityCenterVersion(major, minor, build ?? 0, revision ?? 0);
    }

    public isEqual(version: SecurityCenterVersion): SecurityCenterVersionEquality {
        if (this.major !== version.major) {
            if (this.major > version.major) {
                return SecurityCenterVersionEquality.Greater;
            } else if (this.major < version.minor) {
                return SecurityCenterVersionEquality.Smaller;
            }
        }

        if (this.minor !== version.minor) {
            if (this.minor > version.minor) {
                return SecurityCenterVersionEquality.Greater;
            } else if (this.minor < version.minor) {
                return SecurityCenterVersionEquality.Smaller
            }
        }

        const buildA = this.build < 10 ? this.build * 1000 : this.build;
        const buildB = version.build < 10 ? version.build * 1000 : version.build;

        if (buildA !== buildB) {
            if (buildA > buildB) {
                return SecurityCenterVersionEquality.Greater;
            } else if (buildA < buildB) {
                return SecurityCenterVersionEquality.Smaller;
            }
        }

        if (this.revision !== version.revision) {
            if (this.revision > version.revision) {
                return SecurityCenterVersionEquality.Greater;
            } else if (this.revision < version.revision) {
                return SecurityCenterVersionEquality.Smaller;
            }
        }

        return SecurityCenterVersionEquality.Equal;
    }

    private isCompatible(minVersion: SecurityCenterVersion | null, maxVersion: SecurityCenterVersion | null = null): boolean {
        let isGreaterOrEqual = true;
        if(!!minVersion){
            const minValidation = this.isEqual(minVersion);
            isGreaterOrEqual = minValidation === SecurityCenterVersionEquality.Equal || minValidation === SecurityCenterVersionEquality.Greater
        }

        let isSmallerOrEqual = true;
        if(!!maxVersion){
            const maxValidation = this.isEqual(maxVersion);
            isSmallerOrEqual = maxValidation === SecurityCenterVersionEquality.Equal || maxValidation === SecurityCenterVersionEquality.Smaller
        }

        return isGreaterOrEqual && isSmallerOrEqual;
    }
}
