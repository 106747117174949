import { Component, OnInit } from '@angular/core';
import { AsidePosition } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { PluginComponentExposure, PluginComponentRequirements } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { AccessControlFeatureFlags } from '@modules/access-control/feature-flags';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '@src/constants';
import { LoggerService } from '@modules/shared/services/logger/logger.service';

@Component({
    selector: 'app-access-control-task',
    templateUrl: './access-control-task.component.html',
    styleUrls: ['./access-control-task.component.scss'],
})
@InternalPluginDescriptor({
    type: AccessControlTaskComponent,
    pluginTypes: [PluginTypes.Task],
    exposure: {
        id: AccessControlTaskComponent.pluginId,
        icon: 'gen-ico-cardholder-group',
        title: 'STE_LABEL_CARDHOLDERS',
        tags: ['access control', 'cardholders', 'STE_TASK_ACCESS_CONTROL', 'STE_LABEL_CARDHOLDERS'],
        priority: 50,
        subTaskPluginType: PluginTypes.AccessControlSubTask,
    } as PluginComponentExposure,
    requirements: {
        features: [KnownFeatures.accessControlId],
        licenses: [KnownLicenses.accessControl],
        globalPrivileges: [KnownPrivileges.accessControlTaskPrivilege],
        enabledFeatureFlags: [AccessControlFeatureFlags.General],
    },
    isSupported: () => true,
})
export class AccessControlTaskComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('91EE769A-EE99-4A2B-8445-34EA43FC1AE5');

    public readonly AsidePosition = AsidePosition;

    /**
     * Selected subTask (plugin) currently displayed
     */
    public subTaskPlugin: PluginItem | undefined;

    constructor(private pluginService: PluginService, public trackingService: TrackingService, private route: ActivatedRoute, private loggerService: LoggerService) {
        super(trackingService);
    }

    ngOnInit(): void {
        this.selectSubTaskPlugin().fireAndForget();
    }

    private async selectSubTaskPlugin(): Promise<void> {
        this.subTaskPlugin = await this.getSubTaskPluginFromRouteAsync();

        if (!this.subTaskPlugin) {
            this.subTaskPlugin = await this.getFirstAvailablePluginAsync();
        }

        if (!this.subTaskPlugin) {
            this.loggerService.traceError('No Access Control sub task found!');
        }
    }

    private async getSubTaskPluginFromRouteAsync(): Promise<PluginItem | undefined> {
        const params = this.route.snapshot?.paramMap;
        let pluginItem: PluginItem | undefined;
        if (params?.has(Constants.subTaskIdentifier)) {
            const subTaskId = this.route.snapshot?.paramMap?.get(Constants.subTaskIdentifier) ?? '';
            const { success, value } = SafeGuid.tryParse(subTaskId);
            if (success) {
                pluginItem = (await this.pluginService.getPlugin(PluginTypes.AccessControlSubTask, value, undefined)) ?? undefined;
            }
        }
        return pluginItem;
    }

    private async getFirstAvailablePluginAsync(): Promise<PluginItem | undefined> {
        const subTaskPlugins = await this.pluginService.getPlugins(PluginTypes.AccessControlSubTask).then();
        return subTaskPlugins[0] ?? null;
    }
}
