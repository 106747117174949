import { Observable, Subject } from 'rxjs';
import { FinalizeReason, finalizeWithReason } from '../operators/finalize-with-reason';

/**
 * Subject that will execute a callback when:
 * - there are no more observers subscribed (last observer unsubscribed)
 * - the subject was completed
 *
 * @example
 * const finalizeCallback = () => console.log("completed or no more subscribers");
 * const finalizeCallbackSubject$ = new FinalizeCallbackSubject<boolean>(finalizeCallback);
 *
 * const firstSubscriber = finalizeCallbackSubject$.subscribe();
 * const secondSubscriber = finalizeCallbackSubject$.subscribe();
 *
 * firstSubscriber.unsubscribe();
 * secondSubscriber.unsubscribe(); // finalizeCallback emitted
 * finalizeCallbackSubject$.complete(); // finalizeCallback emitted
 */
export class FinalizeCallbackSubject<T> extends Subject<T> {
    public autoCompleteObservable$: Observable<T>;
    constructor(callbackWhenCompleteOrNoMoreSubscribers: () => void) {
        super();
        this.autoCompleteObservable$ = this.pipe(
            finalizeWithReason((reason) => {
                if (this.isCompleteOrNoMoreSubscribers(reason)) {
                    callbackWhenCompleteOrNoMoreSubscribers();
                }
            })
        );
    }

    public override asObservable(): Observable<T> {
        return this.autoCompleteObservable$;
    }

    private isCompleteOrNoMoreSubscribers(reason: FinalizeReason): boolean {
        return reason === FinalizeReason.Complete || reason === FinalizeReason.NoMoreSubscribers;
    }
}
