// Autogenerated: CodeGen/AlarmInstance?language=ts&InterfaceOnly=True on 2021-08-30 12:04:35 PM
// Autogenerated: CodeGen/AlarmInstance?language=ts&InterfaceOnly=False on 2021-08-30 12:04:35 PM

import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';

export interface IAlarmInstance extends IFieldObject {
    instanceID: number;
    alarmId: IGuid;
    alarmName: string;
    triggerTime: Date;
    triggerEntity: IGuid;
    triggerEvent: string;
    customEventId: number;
    creationTime: Date;
    ackTime: Date;
    ackBy: IGuid;
    ackReason: string;
    externalInstanceID: number;
    offlinePeriod: number;
    triggerEventSubType: number;
    state: string;
    investigatedBy: IGuid;
    investigatedTime: Date;
    hasSourceCondition: boolean;
    dynamicContent: string;
    priority: number;
    latitude: number;
    longitude: number;
    propagationLevel: number;
}

export class AlarmInstance extends FieldObject implements IAlarmInstance {
    // InstanceID
    public get instanceID(): number {
        return this.getField<number>('InstanceID');
    }
    public set instanceID(value: number) {
        this.setField<number>('InstanceID', value);
    }
    // AlarmId
    public get alarmId(): IGuid {
        return this.getFieldGuid('AlarmId');
    }
    public set alarmId(value: IGuid) {
        this.setFieldGuid('AlarmId', value);
    }
    // AlarmName
    public get alarmName(): string {
        return this.getField<string>('AlarmName');
    }
    public set alarmName(value: string) {
        this.setField<string>('AlarmName', value);
    }
    // TriggerTime
    public get triggerTime(): Date {
        return this.getFieldDate('TriggerTime');
    }
    public set triggerTime(value: Date) {
        this.setFieldDate('TriggerTime', value);
    }
    // TriggerEntity
    public get triggerEntity(): IGuid {
        return this.getFieldGuid('TriggerEntity');
    }
    public set triggerEntity(value: IGuid) {
        this.setFieldGuid('TriggerEntity', value);
    }
    // TriggerEvent
    public get triggerEvent(): string {
        return this.getField<string>('TriggerEvent');
    }
    public set triggerEvent(value: string) {
        this.setField<string>('TriggerEvent', value);
    }
    // CustomEventId
    public get customEventId(): number {
        return this.getField<number>('CustomEventId');
    }
    public set customEventId(value: number) {
        this.setField<number>('CustomEventId', value);
    }
    // CreationTime
    public get creationTime(): Date {
        return this.getFieldDate('CreationTime');
    }
    public set creationTime(value: Date) {
        this.setFieldDate('CreationTime', value);
    }
    // AckTime
    public get ackTime(): Date {
        return this.getFieldDate('AckTime');
    }
    public set ackTime(value: Date) {
        this.setFieldDate('AckTime', value);
    }
    // AckBy
    public get ackBy(): IGuid {
        return this.getFieldGuid('AckBy');
    }
    public set ackBy(value: IGuid) {
        this.setFieldGuid('AckBy', value);
    }
    // AckReason
    public get ackReason(): string {
        return this.getField<string>('AckReason');
    }
    public set ackReason(value: string) {
        this.setField<string>('AckReason', value);
    }
    // ExternalInstanceID
    public get externalInstanceID(): number {
        return this.getField<number>('ExternalInstanceID');
    }
    public set externalInstanceID(value: number) {
        this.setField<number>('ExternalInstanceID', value);
    }
    // OfflinePeriod
    public get offlinePeriod(): number {
        return this.getField<number>('OfflinePeriod');
    }
    public set offlinePeriod(value: number) {
        this.setField<number>('OfflinePeriod', value);
    }
    // TriggerEventSubType
    public get triggerEventSubType(): number {
        return this.getField<number>('TriggerEventSubType');
    }
    public set triggerEventSubType(value: number) {
        this.setField<number>('TriggerEventSubType', value);
    }
    // State
    public get state(): string {
        return this.getField<string>('State');
    }
    public set state(value: string) {
        this.setField<string>('State', value);
    }
    // InvestigatedBy
    public get investigatedBy(): IGuid {
        return this.getFieldGuid('InvestigatedBy');
    }
    public set investigatedBy(value: IGuid) {
        this.setFieldGuid('InvestigatedBy', value);
    }
    // InvestigatedTime
    public get investigatedTime(): Date {
        return this.getFieldDate('InvestigatedTime');
    }
    public set investigatedTime(value: Date) {
        this.setFieldDate('InvestigatedTime', value);
    }
    // HasSourceCondition
    public get hasSourceCondition(): boolean {
        return this.getField<boolean>('HasSourceCondition');
    }
    public set hasSourceCondition(value: boolean) {
        this.setField<boolean>('HasSourceCondition', value);
    }
    // DynamicContent
    public get dynamicContent(): string {
        return this.getField<string>('DynamicContent');
    }
    public set dynamicContent(value: string) {
        this.setField<string>('DynamicContent', value);
    }
    // Priority
    public get priority(): number {
        return this.getField<number>('Priority');
    }
    public set priority(value: number) {
        this.setField<number>('Priority', value);
    }
    // Latitude
    public get latitude(): number {
        return this.getField<number>('Latitude');
    }
    public set latitude(value: number) {
        this.setField<number>('Latitude', value);
    }
    // Longitude
    public get longitude(): number {
        return this.getField<number>('Longitude');
    }
    public set longitude(value: number) {
        this.setField<number>('Longitude', value);
    }
    // PropagationLevel
    public get propagationLevel(): number {
        return this.getField<number>('PropagationLevel');
    }
    public set propagationLevel(value: number) {
        this.setField<number>('PropagationLevel', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('InstanceID', 0);
        this.setFieldGuid('AlarmId', SafeGuid.EMPTY);
        this.setField<string>('AlarmName', '');
        this.setFieldDate('TriggerTime', new Date());
        this.setFieldGuid('TriggerEntity', SafeGuid.EMPTY);
        this.setField<string>('TriggerEvent', '');
        this.setField<number>('CustomEventId', 0);
        this.setFieldDate('CreationTime', new Date());
        this.setFieldDate('AckTime', new Date());
        this.setFieldGuid('AckBy', SafeGuid.EMPTY);
        this.setField<string>('AckReason', '');
        this.setField<number>('ExternalInstanceID', 0);
        this.setField<number>('OfflinePeriod', 0);
        this.setField<number>('TriggerEventSubType', 0);
        this.setField<string>('State', '');
        this.setFieldGuid('InvestigatedBy', SafeGuid.EMPTY);
        this.setFieldDate('InvestigatedTime', new Date());
        this.setField<boolean>('HasSourceCondition', false);
        this.setField<string>('DynamicContent', '');
        this.setField<number>('Priority', 0);
        this.setField<number>('Latitude', 0);
        this.setField<number>('Longitude', 0);
        this.setField<number>('PropagationLevel', 0);
    }
}
