// Autogenerated: v2/help/Entities/ApiCredential?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';

// #region Fields

export class CredentialEntityFields extends EntityFields {
    public static accessStatusField = 'AccessStatus';
    public static activationDateField = 'ActivationDate';
    public static badgeTemplateField = 'BadgeTemplate';
    public static cardholderField = 'Cardholder';
    public static cardNumberField = 'CardNumber';
    public static credentialCodeField = 'CredentialCode';
    public static credentialTypeField = 'CredentialType';
    public static expirationDateField = 'ExpirationDate';
    public static expirationDurationField = 'ExpirationDuration';
    public static expirationModeField = 'ExpirationMode';
    public static facilityField = 'Facility';
    public static formatField = 'Format';
    public static formatFriendlyNameField = 'FormatFriendlyName';
    public static keyPadField = 'KeyPad';
    public static uniqueIdField = 'UniqueId';
}

// #endregion Fields

export interface ICredentialEntity extends IEntity {
    // #region REST Properties

    accessStatus: string;

    activationDate: Date;

    badgeTemplate: IGuid;

    cardholder: IGuid;

    cardNumber: number;

    readonly credentialCode: string;

    credentialType: string;

    expirationDate: Date;

    expirationDuration: number;

    expirationMode: string;

    facility: number;

    format: IGuid;

    readonly formatFriendlyName: string;


    uniqueId: string;

    // #endregion REST Properties

}

