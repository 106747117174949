import { PtzRequest, PtzSubCommand, PanTiltBody, ZoomBody, GoToPresetBody, RunPatternBody, GoHomeBody, LockBody, UnlockBody, GoToBody, PtzFocusDistance, FocusBody, PtzIrisOperation, IrisBody } from './Messages';

export class PtzRequestBuilder {
  private readonly m_channelId: number;

  public constructor(channelId: number) {
    this.m_channelId = channelId;
  }

  public buildStartPanTilt(pan: number, tilt: number): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.PanTilt, new PanTiltBody(pan, tilt));
  }

  public buildStopPanTilt(): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.PanTilt, new PanTiltBody(null, null));
  }

  public buildStartZoom(zoom: number): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Zoom, new ZoomBody(zoom));
  }

  public buildStopZoom(): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Zoom, new ZoomBody(null));
  }

  public buildGoToPreset(preset: number): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.GoToPreset, new GoToPresetBody(preset));
  }

  public buildRunPattern(pattern: number): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.RunPattern, new RunPatternBody(pattern));
  }

  public buildGoHome(): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.GoHome, new GoHomeBody());
  }

  public buildLock(): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Lock, new LockBody());
  }

  public buildUnlock(): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Unlock, new UnlockBody());
  }

  public buildGoToPosition(isAbsolute: boolean, pan: number, tilt: number, zoom: number): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.GoTo, new GoToBody(isAbsolute, pan, tilt, zoom));
  }

  public buildStartFocus(distance: PtzFocusDistance, speed: number): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Focus, new FocusBody(distance, speed));
  }

  public buildStopFocus(): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Focus, new FocusBody(null, null));
  }

  public buildStartIris(operation: PtzIrisOperation, speed: number): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Iris, new IrisBody(operation, speed));
  }

  public buildStopIris(): PtzRequest {
    return new PtzRequest(this.m_channelId, PtzSubCommand.Iris, new IrisBody(null, null));
  }
}
