import { Component, OnDestroy, OnInit } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { CredentialEntityFields, ICredentialEntity } from 'RestClient/Client/Interface/ICredentialEntity';
import { IEntityCacheTask, modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CredentialEntity } from 'RestClient/Client/Model/AccessControl/CredentialEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';
import { CredentialWidgetBaseComponent } from '../credential-widget-base.component';

const isContentSupported = (content: Content): boolean => {
    return !!(content.contextContent === null && content.type.equals(AccessControlContentTypes.Credential));
};

@InternalContentPluginDescriptor({
    type: CredentialActionWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CredentialActionWidgetComponent.pluginId, priority: 0 },
    isContentSupported,
})
@Component({
    selector: 'app-credential-action-widget',
    templateUrl: './credential-action-widget.component.html',
    styleUrls: ['./credential-action-widget.component.scss'],
})
export class CredentialActionWidgetComponent extends CredentialWidgetBaseComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('19E22D1F-BC3B-4629-B9B4-86075826BB4B');

    public readonly TextFlavor = TextFlavor;

    public isActive = false;

    protected get monitoredFields(): string[] {
        const fields = super.monitoredFields;
        fields.push(CredentialEntityFields.accessStatusField);
        return fields;
    }

    private entityCacheTask!: IEntityCacheTask;

    constructor(securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService) {
        super(securityCenterClientService, trackingService);
    }

    public async changeCredentialStatusAsync(isActive: boolean): Promise<void> {
        if (this.credential && this.scClient) {
            this.credential.accessStatus = isActive ? AccessStatus.Active : AccessStatus.Inactive;
            await this.scClient.updateAsync(this.credential);
        }
    }

    public async findCredentialAsync(): Promise<void> {
        // TODO FM: Find credential
    }

    public async ngOnDestroy() {
        await this.entityCacheTask?.dispose();
        super.ngOnDestroy();
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = false;
    }

    protected async getCredentialAsync(credentialId: IGuid): Promise<void> {
        if (this.scClient) {
            this.entityCacheTask = this.scClient.buildEntityCache(this.monitoredFields);
            this.credential = (await this.entityCacheTask.getEntityAsync<CredentialEntity, ICredentialEntity>(CredentialEntity, credentialId, true)) as CredentialEntity;
        }
    }

    protected async initializeCredentialInfo(): Promise<void> {
        await super.initializeCredentialInfo();

        if (this.credential) {
            this.updateIsActive(this.credential.accessStatus);

            await this.entityCacheTask.detectFieldChangeAsync(
                this.credential,
                () => {
                    const _ = this.credential?.accessStatus;
                },
                ((_: ICredentialEntity, newValue: string, __: string) => {
                    this.updateIsActive(newValue);
                    return new Deferred<void>(true).promise;
                }) as modificationHandlerField<string>
            );
        }
    }

    private updateIsActive(accessStatus: string) {
        this.isActive = accessStatus === AccessStatus.Active;
    }
}
