// Autogenerated: v2/help/Entities/ApiMediaRouterRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class MediaRouterRoleEntityFields extends RoleEntityFields {
    public static rtspPortField = 'RtspPort';
    public static startMulticastAddressField = 'StartMulticastAddress';
    public static startMulticastPortField = 'StartMulticastPort';
    public static useSecureCommunicationField = 'UseSecureCommunication';
}

// #endregion Fields

// #region Inner classes

export interface IStrategy extends IFieldObject {
    networkCardMac: string;
    exportTricklingEnabled: boolean | null;
    liveEnabled: boolean | null;
    playbackEnabled: boolean | null;
    priority: number | null;
    networkCardRtpPort: number | null;
    networkCardRtspPort: number | null;
    publicAddresses: string;
    networkCardIPAddress: string;
}

export interface IUdpPortRange extends IFieldObject {
    lowerBound: number;
    upperBound: number;
}

export interface IRedirector extends IFieldObject {
    serverId: IGuid;
    udpPortRange: IUdpPortRange;
    rtpPort: number | null;
    rtspPort: number | null;
    maximumLiveStreams: number | null;
    maximumPlaybackStreams: number | null;
    liveBandwidthHardLimit: number | null;
    liveBandwidthSoftLimit: number | null;
    playbackBandwidthHardLimit: number | null;
    playbackBandwidthSoftLimit: number | null;
    strategy: ObservableCollection<IStrategy>;
    isAdvanceRedirectionStrategy: boolean | null;
}

// #endregion Inner classes

export interface IMediaRouterRoleEntity extends IRoleEntity {
    // #region REST Properties

    rtspPort: number;

    startMulticastAddress: string;

    startMulticastPort: number;

    useSecureCommunication: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getRedirectorsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRedirector> | null>;

    // #endregion REST Methods

}

