<div class="link-incident-filter-selection" [hidden]="hiddenFilter">
    <gen-checkbox [(ngModel)]="sameIncidentType" (ngModelChange)="onSameIncidentTypeChanged($event)">
        <gen-label>{{ 'STE_LABEL_SAME_INCIDENT_TYPE' | translate }} </gen-label>
    </gen-checkbox>
    <gen-checkbox [(ngModel)]="sameLocation" (ngModelChange)="onSameLocationChanged($event)">
        <gen-label>
            {{ 'STE_LABEL_SAME_LOCATION' | translate }}
        </gen-label>
    </gen-checkbox>
    <gen-combobox
        [ngModel]="selectedTimeRange"
        (ngModelChange)="onTimeFrameChanged($event)"
        [genFlavor]="ComboboxFlavor.Flat"
        [genItemsSource]="timeFrameList"
        [genPosition]="ComboboxPosition.BottomLeft"
    >
    </gen-combobox>
</div>
