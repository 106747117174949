// Autogenerated: v2/help/Entities/ApiCustomEntity?codegen=ts&InterfaceOnly=False&workflow=True on 2021-08-30 10:12:19 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { EntityManager } from './EntityManager';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IFieldObject } from '../Interface/IFieldObject';
import { ITransaction } from '../Interface/ITransaction';
import { ICustomEntitiesManager, ICustomEntityTypeAccessPrivilege, ICustomEntityTypeDescriptor } from '../Interface/ICustomEntityManager';

// #region Inner classes

export class CustomEntityTypeAccessPrivilege extends FieldObject implements ICustomEntityTypeAccessPrivilege {
    // Add
    public get add(): IGuid {
        return this.getFieldGuid('Add');
    }
    public set add(value: IGuid) {
        this.setFieldGuid('Add', value);
    }
    // Delete
    public get delete(): IGuid {
        return this.getFieldGuid('Delete');
    }
    public set delete(value: IGuid) {
        this.setFieldGuid('Delete', value);
    }
    // Modify
    public get modify(): IGuid {
        return this.getFieldGuid('Modify');
    }
    public set modify(value: IGuid) {
        this.setFieldGuid('Modify', value);
    }
    // View
    public get view(): IGuid {
        return this.getFieldGuid('View');
    }
    public set view(value: IGuid) {
        this.setFieldGuid('View', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Add', SafeGuid.EMPTY);
        this.setFieldGuid('Delete', SafeGuid.EMPTY);
        this.setFieldGuid('Modify', SafeGuid.EMPTY);
        this.setFieldGuid('View', SafeGuid.EMPTY);
    }
}

export class CustomEntityTypeDescriptor extends FieldObject implements ICustomEntityTypeDescriptor {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Icon
    public get icon(): IGuid {
        return this.getFieldGuid('Icon');
    }
    public set icon(value: IGuid) {
        this.setFieldGuid('Icon', value);
    }
    // SmallIcon
    public get smallIcon(): IGuid {
        return this.getFieldGuid('SmallIcon');
    }
    public set smallIcon(value: IGuid) {
        this.setFieldGuid('SmallIcon', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Version
    public get version(): string {
        return this.getField<string>('Version');
    }
    public set version(value: string) {
        this.setField<string>('Version', value);
    }
    // SupportedEvents
    public get supportedEvents(): ObservableCollection<string> {
        return this.getFieldArray<string>('SupportedEvents');
    }
    public set supportedEvents(value: ObservableCollection<string>) {
        this.setFieldArray<string>('SupportedEvents', value);
    }
    // SupportedCustomEvents
    public get supportedCustomEvents(): ObservableCollection<number> {
        return this.getFieldArray<number>('SupportedCustomEvents');
    }
    public set supportedCustomEvents(value: ObservableCollection<number>) {
        this.setFieldArray<number>('SupportedCustomEvents', value);
    }
    // ChildEntityTypesSupported
    public get childEntityTypesSupported(): ObservableCollection<string> {
        return this.getFieldArray<string>('ChildEntityTypesSupported');
    }
    public set childEntityTypesSupported(value: ObservableCollection<string>) {
        this.setFieldArray<string>('ChildEntityTypesSupported', value);
    }
    // ChildCustomEntityTypesSupported
    public get childCustomEntityTypesSupported(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('ChildCustomEntityTypesSupported');
    }
    public set childCustomEntityTypesSupported(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('ChildCustomEntityTypesSupported', value);
    }
    // Capabilities
    public get capabilities(): ObservableCollection<string> {
        return this.getFieldArray<string>('Capabilities');
    }
    public set capabilities(value: ObservableCollection<string>) {
        this.setFieldArray<string>('Capabilities', value);
    }
    // AccessPrivileges
    public get accessPrivileges(): ICustomEntityTypeAccessPrivilege {
        return this.getFieldObject<CustomEntityTypeAccessPrivilege, ICustomEntityTypeAccessPrivilege>(CustomEntityTypeAccessPrivilege, 'AccessPrivileges');
    }
    public set accessPrivileges(value: ICustomEntityTypeAccessPrivilege) {
        this.setFieldObject<ICustomEntityTypeAccessPrivilege>('AccessPrivileges', value);
    }
    // Properties
    public get properties(): IFieldObject {
        return this.getFieldObject<FieldObject, IFieldObject>(FieldObject, 'Properties');
    }
    public set properties(value: IFieldObject) {
        this.setFieldObject<IFieldObject>('Properties', value);
    }
    // SingleInstance
    public get singleInstance(): boolean {
        return this.getField<boolean>('SingleInstance');
    }
    public set singleInstance(value: boolean) {
        this.setField<boolean>('SingleInstance', value);
    }
    // IconImage
    public get iconImage(): string {
        return this.getField<string>('IconImage');
    }
    public set iconImage(value: string) {
        this.setField<string>('IconImage', value);
    }
    // SmallIconImage
    public get smallIconImage(): string {
        return this.getField<string>('SmallIconImage');
    }
    public set smallIconImage(value: string) {
        this.setField<string>('SmallIconImage', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setFieldGuid('Icon', SafeGuid.EMPTY);
        this.setFieldGuid('SmallIcon', SafeGuid.EMPTY);
        this.setField<string>('Name', '');
        this.setField<string>('Version', '');
        this.setFieldArray<string>('SupportedEvents', new ObservableCollection<string>());
        this.setFieldArray<number>('SupportedCustomEvents', new ObservableCollection<number>());
        this.setFieldArray<string>('ChildEntityTypesSupported', new ObservableCollection<string>());
        this.setFieldArrayGuid('ChildCustomEntityTypesSupported', new ObservableCollection<IGuid>());
        this.setFieldArray<string>('Capabilities', new ObservableCollection<string>());
        const objAccessPrivileges = new CustomEntityTypeAccessPrivilege();
        objAccessPrivileges.initializeAllFields();
        this.setFieldObject('AccessPrivileges', objAccessPrivileges);
        const objProperties = new FieldObject();
        objProperties.initializeAllFields();
        this.setFieldObject('Properties', objProperties);
        this.setField<boolean>('SingleInstance', false);
        this.setField<string>('IconImage', '');
        this.setField<string>('SmallIconImage', '');
    }
}

// #endregion Inner classes

export class CustomEntitiesManager extends EntityManager implements ICustomEntitiesManager {
    // #region Constructor

    constructor() {
        super('v2/entities/CustomEntities');
    }

    // #endregion Constructor

    public async getTypeDescriptorsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomEntityTypeDescriptor> | null> {
        return this.getFieldObjectRelationAsync<CustomEntityTypeDescriptor, ICustomEntityTypeDescriptor>(CustomEntityTypeDescriptor, 'typedescriptors', 'Id', false, true, ValidFlags.None, transaction);
    }

}

