// Autogenerated: v2/help/Entities/ApiAccessControlUnit?codegen=ts&InterfaceOnly=False&workflow=False on 2021-05-11 9:36:13 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { UnitEntity } from '../UnitEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IEntity } from '../../Interface/IEntity';
import { IAccessControlUnitEntity, AccessControlUnitEntityFields } from '../../Interface/IAccessControlUnitEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class AccessControlUnitEntity extends UnitEntity implements IAccessControlUnitEntity {
    // #region REST Properties

    // ExtensionType
    public get extensionType(): string {
        return this.getField<string>(AccessControlUnitEntityFields.extensionTypeField);
    }
    public set extensionType(value: string) {
        this.setField<string>(AccessControlUnitEntityFields.extensionTypeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Units);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getDoorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Doors', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getElevatorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Elevators', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getInterfaceModulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('InterfaceModules', 'Id', false, query, ValidFlags.Membership2, transaction);
    }

    // #endregion REST Methods

}

