import { VertexShader } from '../WebGL2/VertexShader';

export class DewarpedAreaVertexShader extends VertexShader {
  private static readonly VertexShaderCode: string =
    `

in vec2 a_position;

void main() {
   gl_Position = vec4(a_position, 0, 1);
}`;

  constructor(gl: WebGL2RenderingContext) {
    super(gl, DewarpedAreaVertexShader.VertexShaderCode);
  }
}
