import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { GenAction, GenMeltedItem, GenListActions, GenMeltedListItem, GenMeltedModalComponent, GenModalService, MeltedIcon, MeltedModalAction } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SafeGuid } from 'safeguid';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AggregationFieldType, AggregationGrouping, AggregationQueryFilter, AggregationType, GeoCoordinate, LightCorrelationSearchParameter } from '../../../api/api';
import { CorrelationService } from '../../../services/correlation.service';
import { CorrelationParametersModalComponent } from '../../parameters-modal/correlation-parameters-modal.component';
import { CorrelationSearchParameter } from '../../parameters-modal/correlation-search-parameter';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-aggregation-widget-settings-modal',
    templateUrl: './aggregation-widget-settings-modal.component.html',
    styleUrls: ['./aggregation-widget-settings-modal.component.scss'],
})
export class AggregationWidgetSettingsModalComponent extends ConnectionAwareModalComponent implements OnInit {
    //#region Constants

    public static readonly addParameterAction = '3acc3f1b-2978-4eec-a238-31cdff1422bd';
    public static readonly editParameterAction = '18fc447c-eed0-4e40-9d9f-53b6d603021f';
    public static readonly removeParameterAction = 'b6b5e08e-11fc-42ae-b194-9ccfb9f5694f';

    //#endregion

    //#region Properties
    @Input()
    public returnResult!: (parameter?: AggregationQueryFilter) => void;

    @ViewChild(GenMeltedModalComponent)
    public genModalComponent!: GenMeltedModalComponent;

    @Input()
    public parameter!: AggregationQueryFilter;

    public readonly TextFlavor = TextFlavor;

    public dirty = false;
    public isValid = this.parameter?.dataTypes?.length > 0;
    public aggregationType: GenMeltedItem | undefined = undefined;
    public aggregationTypes: GenMeltedItem[] = [];
    public aggregationGroup: GenMeltedItem | undefined = undefined;
    public aggregationGroups: GenMeltedItem[] = [];
    public aggregationField: GenMeltedItem | undefined = undefined;
    public aggregationFields: GenMeltedItem[] = [];
    public visibleDataTypes: GenMeltedListItem[] = [];
    public dataTypes: GenMeltedListItem[] = [];
    public additionalParameters: Array<GenMeltedItem> = [];
    public conditionActions!: GenListActions;
    public searchText = '';

    private addParameterAction: GenAction = {
        execute: (items) => this.addParameterActionExecute(items),
        canExecute: () => true,
        id: AggregationWidgetSettingsModalComponent.addParameterAction,
    };
    private editParameterAction: GenAction = {
        execute: (items) => this.editParameterActionExecute(items),
        canExecute: (items) => this.editParameterActionCanExecute(items),
        id: AggregationWidgetSettingsModalComponent.editParameterAction,
    };
    private removeParameterAction: GenAction = {
        execute: (items) => this.removeParameterActionExecute(items),
        canExecute: (items) => this.removeParameterActionCanExecute(items),
        id: AggregationWidgetSettingsModalComponent.removeParameterAction,
    };

    //#endregion

    //#region Constructor

    constructor(
        injector: Injector,
        trackingService: TrackingService,
        authService: AuthService,
        private translateService: TranslateService,
        private correlationService: CorrelationService,
        private modalService: GenModalService
    ) {
        super(authService, injector, trackingService);

        // hook up to the modal send action
        this.genModalAction.pipe(untilDestroyed(this)).subscribe((modalAction: MeltedModalAction) => this.onSubmit(modalAction));

        this.conditionActions = {
            add: this.addParameterAction,
            edit: this.editParameterAction,
            delete: this.removeParameterAction,
        };
    }

    //#endregion

    //#region Public Methods

    ngOnInit() {
        super.ngOnInit();
        this.setupLocalizations();
    }

    public async refresh(parameter?: AggregationQueryFilter | null | undefined): Promise<void> {
        if (parameter) {
            this.parameter = parameter;
            if (!this.parameter.dataTypes) {
                this.parameter.dataTypes = [];
            }
            if (!this.parameter.additionalParameters) {
                this.parameter.additionalParameters = [];
            }
        }

        if (!this.parameter) {
            this.parameter = new AggregationQueryFilter();
            this.parameter.centerPoint = new GeoCoordinate({ latitude: 0, longitude: 0 });
            this.parameter.radius = null;

            // configurable properties
            this.parameter.referenceTimeUtc = moment().utc().toISOString();
            this.parameter.timeWindow = null;
            this.parameter.aggregationType = AggregationType.Count;
            this.parameter.aggregationGroup = AggregationGrouping.None;
            this.parameter.aggregationFieldType = AggregationFieldType.Id;
            this.parameter.additionalParameters = [];
            this.parameter.customAggregationField = null;
            this.parameter.customAggregationGroup = null;
            this.parameter.dataTypes = [];
            this.isValid = false;
        }
        // reload the existing parameter

        // if the types are not loaded, load them since the translation service is now available
        if (this.aggregationTypes.length === 0) {
            this.setupLocalizations();
        }

        this.aggregationType = this.aggregationTypes.find((agg) => agg.id === this.parameter.aggregationType);
        this.aggregationGroup = this.aggregationGroups.find((agg) => agg.id === this.parameter.aggregationGroup);
        this.aggregationField = this.aggregationFields.find((agg) => agg.id === this.parameter.aggregationFieldType);
        await this.loadDataTypes();
        this.dirty = false;
        this.isValid = this.parameter.dataTypes?.length > 0;
    }

    //#endregion

    //#region Events

    public onSubmit(modalAction: MeltedModalAction): void {
        if (modalAction === MeltedModalAction.Default && this.parameter && this.parameter !== null) {
            this.returnResult(this.parameter);
        } else {
            this.returnResult(undefined);
        }
    }

    public onAggregationTypeChanged(item: GenMeltedItem): void {
        this.aggregationType = item;
        const ty = item.id as AggregationType;
        if (ty) {
            if (ty !== this.parameter.aggregationType) {
                this.dirty = true;
                this.parameter.aggregationType = ty;
            }
        }
    }

    public onAggregationGroupChanged(item: GenMeltedItem): void {
        this.aggregationGroup = item;
        const ty = item.id as AggregationGrouping;
        if (ty) {
            if (ty !== this.parameter.aggregationGroup) {
                this.dirty = true;
                this.parameter.aggregationGroup = ty;
            }
        }
    }

    public onAggregationCustomGroupChanged(text: string): void {
        if (text) {
            this.parameter.customAggregationGroup = text;
            this.dirty = true;
        }
    }

    public onAggregationFieldChanged(item: GenMeltedItem): void {
        this.aggregationField = item;
        const ty = item.id as AggregationFieldType;
        if (ty) {
            if (ty !== this.parameter.aggregationFieldType) {
                this.dirty = true;
                this.parameter.aggregationFieldType = ty;
            }
        }
    }

    public onAggregationCustomFieldChanged(text: string): void {
        if (text) {
            this.parameter.customAggregationField = text;
            this.dirty = true;
        }
    }

    public onDataTypeChanged(item?: GenMeltedListItem): void {
        if (item) {
            if (item.isChecked) {
                this.parameter.dataTypes.push(SafeGuid.parse(item.id));
            } else {
                this.parameter.dataTypes = this.parameter.dataTypes.filter((a) => !a.equals(item.id));
            }
            this.dirty = true;
            this.isValid = this.parameter.dataTypes?.length > 0;
        }
    }

    public onSearchChange(newSearchText: string): void {
        this.searchText = newSearchText;
        if (this.searchText) {
            this.visibleDataTypes = this.dataTypes.filter((item) => item.text.toLowerCase().includes(this.searchText.toLowerCase()));
        } else {
            this.visibleDataTypes = this.dataTypes;
        }
    }

    //#endregion

    //#region Private Methods

    private setupLocalizations() {
        if (this.aggregationTypes.length === 0) {
            // apply the necessary string translations
            this.aggregationTypes = Object.values(AggregationType).map((s) => {
                return {
                    id: s.toString(),
                    text: this.translateService.instant('STE_LABEL_AGGREGATIONTYPE_' + s.toUpperCase()) as string,
                };
            });
            this.aggregationGroups = Object.values(AggregationGrouping).map((s) => {
                return {
                    id: s.toString(),
                    text: this.translateService.instant('STE_LABEL_AGGREGATIONGROUPING_' + s.toUpperCase()) as string,
                };
            });
            this.aggregationFields = Object.values(AggregationFieldType).map((s) => {
                return {
                    id: s.toString(),
                    text: this.translateService.instant('STE_LABEL_AGGREGATIONFIELDTYPE_' + s.toUpperCase()) as string,
                };
            });
        }
    }

    private async loadDataTypes() {
        const dataTypeResults: GenMeltedListItem[] = [];
        if (this.correlationService) {
            const dataTypes = await this.correlationService.getDataTypes();
            if (dataTypes) {
                for (const dt of dataTypes) {
                    const item: GenMeltedListItem = { text: dt.name, id: dt.dataType.toString() };
                    if (dt.customIcon) {
                        // TODO: santization is needed?
                        item.image = dt.customIcon;
                    } else {
                        item.icon = (dt.icon as MeltedIcon) ?? MeltedIcon.CorrelationService;
                    }
                    dataTypeResults.push(item);
                }
            }
        }
        this.dataTypes = dataTypeResults.sort((a, b) => (a.text > b.text ? 1 : -1));
        this.visibleDataTypes = this.dataTypes;
        // if there is no value set already, initialize the value, otherwise set the checked items
        if (this.parameter?.dataTypes?.length > 0) {
            this.setSelectedDataTypes();
        } else if (this.parameter) {
            this.parameter.dataTypes = [];
        }

        if (this.parameter?.additionalParameters) {
            for (const parameter of this.parameter.additionalParameters) {
                const csp = CorrelationSearchParameter.ofLightParameter(parameter);
                if (csp) {
                    void csp.setDisplayTextAuto(this.injector, undefined);
                    this.pushParameter(csp);
                }
            }
        } else {
            this.additionalParameters = [];
        }
    }

    private setSelectedDataTypes() {
        if (this.dataTypes) {
            // wipe all the check's
            this.dataTypes.forEach((dt) => (dt.isChecked = false));
            // reset the checks
            if (this.parameter?.dataTypes) {
                this.parameter.dataTypes.forEach((item) => {
                    const dataTypeItem = this.dataTypes.find((item2) => item.equals(item2.id));
                    if (dataTypeItem) {
                        dataTypeItem.isChecked = true;
                    }
                });
            }
        }
    }

    private pushParameter(result: CorrelationSearchParameter | undefined, previous?: string | undefined) {
        if (result) {
            const theItem = {
                id: result.id,
                text: result.DisplayText,
            };
            // if there's a previous value, wipe it before setting the new parameter
            if (previous) {
                this.additionalParameters = this.additionalParameters.filter((item) => item.id !== previous);
                this.parameter.additionalParameters = this.parameter.additionalParameters?.filter((item) => this.getId(item) !== previous);
            }

            if (!this.additionalParameters.find((item) => item.id === theItem.id)) {
                this.additionalParameters.push(theItem);
            }
        }
    }

    private async addParameterActionExecute(items?: any[] | undefined): Promise<void> {
        if (this.modalService) {
            const modal = this.modalService.open(CorrelationParametersModalComponent, undefined);
            if (modal) {
                // set the modal data & refresh the view
                await modal.refresh(this.parameter.dataTypes, undefined);

                const promise = new Promise<CorrelationSearchParameter | undefined>((resolve) => {
                    modal.returnResult = resolve;
                });
                const result = await promise;
                if (result) {
                    this.pushParameter(result);
                    // set the value
                    this.parameter.additionalParameters?.push(result.toLightParameter());
                    this.dirty = true;
                }
            }
        }
    }

    private async editParameterActionExecute(items?: any[] | undefined) {
        let parameter: CorrelationSearchParameter | undefined;
        if (items && items.length > 0) {
            if (this.parameter?.additionalParameters) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const found = this.parameter.additionalParameters.find((item) => this.getId(item) === items[0].id);
                if (found) {
                    parameter = CorrelationSearchParameter.ofLightParameter(found);
                }
            }
        }

        if (parameter) {
            if (this.modalService) {
                const modal = this.modalService.open(CorrelationParametersModalComponent, undefined);
                if (modal) {
                    // set the modal data & refresh the view
                    await modal.refresh(this.parameter.dataTypes, parameter);

                    const promise = new Promise<CorrelationSearchParameter | undefined>((resolve) => {
                        modal.returnResult = resolve;
                    });
                    const result = await promise;
                    if (result) {
                        this.pushParameter(result, parameter.id);
                        // set the value
                        this.parameter.additionalParameters?.push(result.toLightParameter());
                        this.dirty = true;
                    }
                }
            }
        }
    }

    private editParameterActionCanExecute(items?: any[] | undefined): boolean {
        if (items) {
            if (items.length > 0) {
                return true;
            }
        }
        return false;
    }

    private removeParameterActionExecute(items?: any[] | undefined) {
        if (items) {
            if (items.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.additionalParameters = this.additionalParameters.filter((item) => item.id !== items[0].id);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.parameter.additionalParameters = this.parameter.additionalParameters?.filter((item) => this.getId(item) !== items[0].id);
                this.dirty = true;
            }
        }
    }

    private removeParameterActionCanExecute(items?: any[] | undefined): boolean {
        if (items) {
            if (items.length > 0) {
                return true;
            }
        }
        return false;
    }

    private getId(parameter: LightCorrelationSearchParameter) {
        return `${parameter.fieldName}||${parameter.valueType}||${parameter.value ?? ''}`;
    }

    //#endregion
}
