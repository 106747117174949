// Autogenerated: v2/help/Entities/ApiAreaAccessPoint?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:25 AM
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { AccessPointEntity } from './AccessPointEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IAreaAccessPointEntity, AreaAccessPointEntityFields } from '../../Interface/IAreaAccessPointEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class AreaAccessPointEntity extends AccessPointEntity implements IAreaAccessPointEntity {
    // #region REST Properties

    // Area
    public get area(): IGuid {
        return this.getFieldGuid(AreaAccessPointEntityFields.areaField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPoints', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Rules', 'Id', false, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

