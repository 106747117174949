import { Component, Injector, OnInit, Version } from '@angular/core';
import { Icon, TextFlavor } from '@genetec/gelato';
import { Color, GenAlertService, GenMeltedItem, GenToastService } from '@genetec/gelato-angular';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { AutoUnlockOverride, DoorEntity } from 'RestClient/Client/Model/AccessControl/DoorEntity';
import { IDoorEntity } from 'RestClient/Client/Interface/IDoorEntity';
import moment from 'moment';
import { WebAppClient } from 'WebClient/WebAppClient';
import { IGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { DateFilterPeriod } from 'src/app/modules/shared/components/filters/common-filters/filter-date/enums/date-filter-period.enum';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { TypedConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { TemporaryOverrideScheduleOption } from './enum/temporary-override-schedule-options.enum';
import { OverrideScheduleOption } from './enum/override-schedule-options.enum';
import { stringFormat } from '@modules/shared/utilities/StringFormat';

@Component({
    selector: 'app-override-schedule-modal',
    templateUrl: './override-schedule-modal.component.html',
    styleUrls: ['./override-schedule-modal.component.scss'],
})
export class OverrideScheduleModalComponent extends TypedConnectionAwareModalComponent<boolean> implements OnInit {
    private static readonly defaultTimePeriod = DateFilterPeriod.Minutes;
    private static readonly defaultDuration = 5;

    // Override unlock schedule and maintenance mode for federated door not supported before 5.11.1
    public static readonly MinimumSecurityCenterVersionForFederatedDoors = new Version('5.11.1');

    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;
    public readonly Colors = Color;

    public entityId?: IGuid;
    public canUnlockForMaintenance?: boolean;
    public hasOverrideSchedule = false;

    public OverrideScheduleOption = OverrideScheduleOption;
    public TemporaryOverrideScheduleOption = TemporaryOverrideScheduleOption;
    public periodItems: Array<GenMeltedItem> = [];
    public option = OverrideScheduleOption.TemporaryOverride;
    public isOverrideLocking = true;
    public temporaryOverrideOption = TemporaryOverrideScheduleOption.ForNextX;
    public duration = OverrideScheduleModalComponent.defaultDuration;
    public period?: GenMeltedItem;
    public durationTimeStart: string | null = null;
    public durationTimeEnd: string | null = null;
    public rangeTimeStart: string | null = null;
    public rangeTimeEnd: string | null = null;

    public maxDuration = 1;

    public durationError = false;
    public rangeTimeError = '';

    public isOnline = true;

    public isLoading = true;

    private scClient!: WebAppClient;
    private doorEntity: DoorEntity | null = null;

    constructor(
        private securityCenterClientService: SecurityCenterClientService,
        private translateService: TranslateService,
        private genAlertService: GenAlertService,
        protected authService: AuthService,
        injector: Injector,
        trackingService: TrackingService,
        private toastService: GenToastService
    ) {
        super(authService, injector, trackingService);
    }

    public async ngOnInit() {
        super.ngOnInit();

        this.scClient = this.securityCenterClientService?.scClient;
        if (this.scClient && this.entityId) {
            this.doorEntity = (await this.scClient.getEntityAsync<DoorEntity, IDoorEntity>(DoorEntity, this.entityId)) as DoorEntity;
            this.isOnline = this.doorEntity?.runningState !== RunningState.NotRunning;
        }
        this.initializePeriodItems();
        this.initializeTimeInputs();
        this.genModalComponent.onDefaultActionClick = (): Promise<void> => Promise.resolve(this.onOverrideScheduleSave());
        this.isLoading = false;
    }

    public onLockOverrideChange(): void {
        this.isOverrideLocking = !this.isOverrideLocking;
    }

    public onDurationChange(newDuration: number): void {
        this.duration = newDuration;
        this.checkDurationError();
    }

    public onPeriodChange(newPeriod: GenMeltedItem): void {
        this.period = newPeriod;
        this.checkDurationError();
    }

    public onRangeTimeStartChange(newDate: string): void {
        this.rangeTimeStart = newDate;
        this.checkRangeTimeError();
    }

    public onRangeTimeEndChange(newDate: string): void {
        this.rangeTimeEnd = newDate;
        this.checkRangeTimeError();
    }
    public onOptionChange(newOption: string): void {
        this.option = Number.parseInt(newOption);
    }

    public onTemporaryOverrideOptionChange(newOption: string): void {
        this.temporaryOverrideOption = Number.parseInt(newOption);

        switch (this.temporaryOverrideOption) {
            case TemporaryOverrideScheduleOption.ForNextX:
                this.rangeTimeError = '';
                this.checkDurationError();
                break;
            case TemporaryOverrideScheduleOption.Range:
                this.durationError = false;
                this.checkRangeTimeError();
                break;
        }
    }

    public onOverrideScheduleSave(): void {
        this.genAlertService.show('overrideScheduleAlert');
    }

    public onOverrideScheduleConfirm = async (): Promise<boolean> => {
        if (this.doorEntity) {
            switch (this.option) {
                case OverrideScheduleOption.Maintenance:
                    this.doorEntity.maintenanceModeActive = true;
                    break;
                case OverrideScheduleOption.TemporaryOverride: {
                    const override = new AutoUnlockOverride();
                    override.isUnlocking = !this.isOverrideLocking;

                    switch (this.temporaryOverrideOption) {
                        case TemporaryOverrideScheduleOption.ForNextX:
                            this.durationTimeStart = moment.utc().toISOString();
                            this.durationTimeEnd = moment
                                .utc()
                                .add(this.duration, this.period?.id as moment.unitOfTime.DurationConstructor)
                                .toISOString();

                            override.start = moment(this.durationTimeStart).toDate();
                            override.end = moment(this.durationTimeEnd).toDate();
                            break;
                        case TemporaryOverrideScheduleOption.Range:
                            if (this.rangeTimeStart && this.rangeTimeEnd) {
                                override.start = moment(this.rangeTimeStart).toDate();
                                override.end = moment(this.rangeTimeEnd).toDate();
                            }
                            break;
                    }
                    await this.doorEntity.addAutoUnlockOverrideAsync(override);
                    break;
                }
            }
            const response = await this.scClient.updateAsync(this.doorEntity);
            if (response.statusCode === HttpStatusCode.OK) {
                this.hasOverrideSchedule = true;
                this.emitResult(this.hasOverrideSchedule);
                this.updateOverrideSchedule();
                this.genModalComponent.hide();
                return true;
            } else {
                const msg = stringFormat(
                    this.translateService.instant('STE_LABEL_FORMAT_ERROR_OCCURRED_EXECUTING_ACTION_X') as string,
                    this.translateService.instant('STE_BUTTON_OVERRIDE_UNLOCK_SCHEDULES') as string
                );
                this.toastService.show({ text: msg, flavorCustomColor: this.Color.Fragola });
                return false;
            }
        }
        return false;
    };

    public updateOverrideSchedule(): void {}

    public isSaveDisabled = (): boolean => {
        if (this.option === OverrideScheduleOption.TemporaryOverride) {
            switch (this.temporaryOverrideOption) {
                case TemporaryOverrideScheduleOption.ForNextX:
                    return this.durationError;
                case TemporaryOverrideScheduleOption.Range:
                    return this.rangeTimeError !== '';
            }
        }
        return false;
    };

    private initializeTimeInputs() {
        this.durationTimeStart = moment.utc().toISOString();
        this.durationTimeEnd = moment
            .utc()
            .add(this.duration, this.period?.id as moment.unitOfTime.DurationConstructor)
            .toISOString();

        this.rangeTimeStart = moment.utc().toISOString();
        this.rangeTimeEnd = moment.utc().add(OverrideScheduleModalComponent.defaultDuration, OverrideScheduleModalComponent.defaultTimePeriod).toISOString();
    }

    private initializePeriodItems() {
        this.periodItems = [
            {
                id: DateFilterPeriod.Minutes,
                text: this.translateService.instant('STE_UNIT_MINUTES_LC') as string,
            },
            {
                id: DateFilterPeriod.Hours,
                text: this.translateService.instant('STE_UNIT_HOURS_LC') as string,
            },
        ];

        this.period = this.periodItems.find((x) => x.id === OverrideScheduleModalComponent.defaultTimePeriod);
        this.checkDurationError();
    }

    private checkDurationError() {
        this.maxDuration = this.period?.id === DateFilterPeriod.Minutes ? 24 * 60 : 24;
        this.durationError = isNaN(this.duration) || this.duration > this.maxDuration || this.duration < 1;
    }

    private checkRangeTimeError() {
        if (this.rangeTimeEnd && this.rangeTimeStart) {
            if (!moment(this.rangeTimeEnd).isValid() || !moment(this.rangeTimeStart).isValid()) {
                this.rangeTimeError = this.translateService.instant('STE_MESSAGE_ERROR_INVALIDDATETIMES') as string;
            } else if (moment(this.rangeTimeEnd).isBefore(this.rangeTimeStart)) {
                this.rangeTimeError = this.translateService.instant('STE_MESSAGE_ERROR_ENDTIMEEARLIERTHANSTARTTIME') as string;
            } else if (moment(this.rangeTimeEnd).isSame(this.rangeTimeStart)) {
                this.rangeTimeError = this.translateService.instant('STE_MESSAGE_ERROR_ENDTIMESAMEASSTARTTIME') as string;
            } else if (Math.abs(moment(this.rangeTimeStart).diff(moment(this.rangeTimeEnd), 'hours')) >= 24) {
                this.rangeTimeError = this.translateService.instant('STE_MESSAGE_ERROR_EXCEED24HOURS') as string;
            } else {
                this.rangeTimeError = '';
            }
        } else {
            this.rangeTimeError = this.translateService.instant('STE_MESSAGE_ERROR_INVALIDDATETIMES') as string;
        }
    }
}
