<gen-item [highlightColor]="headerInfo?.titleColor | argb" class="columns-span-10">
    <app-custom-icon
        slot="gen-icon"
        [icon]="headerInfo?.titleIcon"
        [entityId]="headerInfo?.entityId"
        [customIconId]="headerInfo?.titleCustomIconId"
        [customIconState]="headerInfo?.titleCustomIconState"
    ></app-custom-icon>
    <gen-text [appTooltip]="headerInfo?.title + (headerInfo?.description ? ': ' + headerInfo?.description : '')" appTooltipOnlyIfOverflow>
        {{ headerInfo?.title }}
    </gen-text>
    <gen-text [slot]="ItemSlot.TextSecondary" [appTooltip]="headerInfo?.description" appTooltipOnlyIfOverflow>{{ headerInfo?.description }}</gen-text>
    <gen-text [slot]="ItemSlot.TextTertiary" [appTooltip]="headerInfo?.timestamp" appTooltipOnlyIfOverflow>{{ headerInfo?.timestamp }}</gen-text>
</gen-item>
