import { RestOperation, RestRelationOperation } from './RestOperation';
import { FilterSet } from './Linq';
import { IRestTransaction } from '../Client/Interface/IRestTransaction';
import { IRestObject } from '../Client/Interface/IRestObject';

export class RelationTrackerBase {
    //#region Fields

    private _pendingOperations: FilterSet<RestOperation>;

    protected _parent: IRestObject;
    protected _relation: string;
    protected _elementId: string;
    protected _readOnly: boolean;
    protected _allowValueUpdate: boolean;

    //#endregion

    //#region Properties

    public get pendingOperations(): FilterSet<RestOperation> {
        return this._pendingOperations;
    }

    public get relation(): string {
        return this._relation;
    }

    public isValid = true;

    //#endregion

    //#region constructor

    constructor(parent: IRestObject, relation: string, elementId: string | null, readOnly: boolean, allowValueUpdate: boolean) {
        this._pendingOperations = new FilterSet<RestOperation>();
        this._parent = parent;
        this._relation = relation;
        if (elementId) {
            this._elementId = elementId;
        } else {
            this._elementId = '';
        }
        this._readOnly = readOnly;
        this._allowValueUpdate = allowValueUpdate;
    }

    //#endregion

    //#region methods

    public addRelationOperation(relation: string, verb: string, body: object | null) {
        if (this.isValid === false) {
            throw new Error('After a entity update, relations need to be refreshed');
        }

        const operation = new RestRelationOperation();
        operation.restObject = this._parent;
        operation.uri = relation;
        operation.verb = verb;
        operation.body = body;
        this._pendingOperations.add(operation);
    }

    public addPendingOperation(transaction: IRestTransaction) {
        if (this.isValid === false) {
            throw new Error('After a entity update, relations need to be refreshed');
        }

        for (const operation of this._pendingOperations) {
            transaction.addTransactionOperation(operation.uri, operation.verb, operation.body);
        }
        return;
    }

    //#region
}
