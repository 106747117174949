import { IFieldFilter } from './interfaces/field-filter.interface';
import { IFieldFilterSet } from './interfaces/field-filter-set.interface';

export class FieldFilterSet extends Set<IFieldFilter> implements IFieldFilterSet {
    private _fieldFilters = new Map<string, IFieldFilter>();

    public add(fieldFilter: IFieldFilter): this {
        const existingFilter = this.tryGet(fieldFilter);

        if (existingFilter !== undefined) {
            super.delete(existingFilter);
        }

        this._fieldFilters.set(fieldFilter.fieldName, fieldFilter);
        super.add(fieldFilter);

        return this;
    }

    public delete(fieldFilter: IFieldFilter): boolean {
        const existingFilter = this.tryGet(fieldFilter);

        if (existingFilter !== undefined) {
            super.delete(existingFilter);
        }

        return this._fieldFilters.delete(fieldFilter.fieldName);
    }

    public has(fieldFilter: IFieldFilter): boolean {
        return this._fieldFilters.has(fieldFilter.fieldName);
    }

    public clear(): void {
        super.clear();

        this._fieldFilters.clear();
    }

    private tryGet(fieldFilter: IFieldFilter): IFieldFilter | undefined {
        if (this._fieldFilters.has(fieldFilter.fieldName)) {
            for (const existingFilter of this) {
                if (existingFilter.fieldName === fieldFilter.fieldName) {
                    return existingFilter;
                }
            }

            return undefined;
        }
    }
}
