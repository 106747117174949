// Autogenerated: CodeGen/FeatureDescriptor?language=ts&InterfaceOnly=True on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/Feature?language=ts&InterfaceOnly=True on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/KeyValueParam?language=ts&InterfaceOnly=True on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/RestRoleConfigToolData?language=ts&InterfaceOnly=True on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/WebAppConfig?language=ts&InterfaceOnly=True on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/FeatureDescriptor?language=ts&InterfaceOnly=False on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/Feature?language=ts&InterfaceOnly=False on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/KeyValueParam?language=ts&InterfaceOnly=False on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/RestRoleConfigToolData?language=ts&InterfaceOnly=False on 2021-06-02 7:18:49 AM
// Autogenerated: CodeGen/WebAppConfig?language=ts&InterfaceOnly=False on 2021-06-02 7:18:49 AM
import { RestVersion } from 'RestClient/Client/Model/Roles/PluginRoleEntity';
import { IRestVersion } from 'RestClient/Client/Interface/IPluginRoleEntity';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';

export interface IFeatureDescriptor extends IFieldObject {
    id: IGuid;
    version: string;
    name: string;
    description: string;
    category: string;
    generalAvailability: boolean;
    enabledByDefault: boolean;
}

export interface IFeature extends IFieldObject {
    descriptor: IFeatureDescriptor;
    enabled: boolean;
    isVisible: boolean;
    specificConfig: string;
    serverSpecificConfig: string;
}

export interface IKeyValue extends IFieldObject {
    key: string;
    value: string;
}

export interface IRestRoleConfigToolData extends IFieldObject {
    features: ObservableCollection<IFeature>;
    advancedSettings: ObservableCollection<IKeyValue>;
}

export interface IWebAppConfig extends IRestRoleConfigToolData {
    version: IRestVersion;
    applicationPath: string;
    useDefaultPorts: boolean;
    httpPort: number;
    httpsPort: number;
    httpsSupported: boolean;
    httpSupported: boolean;
    tokenDuration: number;
    maxClientSessions: number;
    useKestrel: boolean;
    roleUniqueId: string;
    bindCertificate: boolean;
    enableLoadBalancing: boolean;
}

export class FeatureDescriptor extends FieldObject implements IFeatureDescriptor {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Version
    public get version(): string {
        return this.getField<string>('Version');
    }
    public set version(value: string) {
        this.setField<string>('Version', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // Category
    public get category(): string {
        return this.getField<string>('Category');
    }
    public set category(value: string) {
        this.setField<string>('Category', value);
    }
    // GeneralAvailability
    public get generalAvailability(): boolean {
        return this.getField<boolean>('GeneralAvailability');
    }
    public set generalAvailability(value: boolean) {
        this.setField<boolean>('GeneralAvailability', value);
    }
    // EnabledByDefault
    public get enabledByDefault(): boolean {
        return this.getField<boolean>('EnabledByDefault');
    }
    public set enabledByDefault(value: boolean) {
        this.setField<boolean>('EnabledByDefault', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Version', '');
        this.setField<string>('Name', '');
        this.setField<string>('Description', '');
        this.setField<string>('Category', '');
        this.setField<boolean>('GeneralAvailability', false);
        this.setField<boolean>('EnabledByDefault', false);
    }
}

export class Feature extends FieldObject implements IFeature {
    // Descriptor
    public get descriptor(): IFeatureDescriptor {
        return this.getFieldObject<FeatureDescriptor, IFeatureDescriptor>(FeatureDescriptor, 'Descriptor');
    }
    public set descriptor(value: IFeatureDescriptor) {
        this.setFieldObject<IFeatureDescriptor>('Descriptor', value);
    }
    // Enabled
    public get enabled(): boolean {
        return this.getField<boolean>('Enabled');
    }
    public set enabled(value: boolean) {
        this.setField<boolean>('Enabled', value);
    }
    // IsVisible
    public get isVisible(): boolean {
        return this.getField<boolean>('IsVisible');
    }
    public set isVisible(value: boolean) {
        this.setField<boolean>('IsVisible', value);
    }
    // SpecificConfig
    public get specificConfig(): string {
        return this.getField<string>('SpecificConfig');
    }
    public set specificConfig(value: string) {
        this.setField<string>('SpecificConfig', value);
    }
    // ServerSpecificConfig
    public get serverSpecificConfig(): string {
        return this.getField<string>('ServerSpecificConfig');
    }
    public set serverSpecificConfig(value: string) {
        this.setField<string>('ServerSpecificConfig', value);
    }

    public initializeAllFields(): void {
        const objDescriptor = new FeatureDescriptor();
        objDescriptor.initializeAllFields();
        this.setFieldObject('Descriptor', objDescriptor);
        this.setField<boolean>('Enabled', false);
        this.setField<boolean>('IsVisible', false);
        this.setField<string>('SpecificConfig', '');
        this.setField<string>('ServerSpecificConfig', '');
    }
}

export class KeyValue extends FieldObject implements IKeyValue {
    // Key
    public get key(): string {
        return this.getField<string>('Key');
    }
    public set key(value: string) {
        this.setField<string>('Key', value);
    }
    // Value
    public get value(): string {
        return this.getField<string>('Value');
    }
    public set value(value: string) {
        this.setField<string>('Value', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Key', '');
        this.setField<string>('Value', '');
    }
}

export class RestRoleConfigToolData extends FieldObject implements IRestRoleConfigToolData {
    // Features
    public get features(): ObservableCollection<IFeature> {
        return this.getFieldObjectArray<Feature, IFeature>(Feature, 'Features');
    }
    public set features(value: ObservableCollection<IFeature>) {
        this.setFieldObjectArray('Features', value);
    }
    // AdvancedSettings
    public get advancedSettings(): ObservableCollection<IKeyValue> {
        return this.getFieldObjectArray<KeyValue, IKeyValue>(KeyValue, 'AdvancedSettings');
    }
    public set advancedSettings(value: ObservableCollection<IKeyValue>) {
        this.setFieldObjectArray('AdvancedSettings', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldArray<IFeature>('Features', new ObservableCollection<IFeature>());
        this.setFieldArray<IKeyValue>('AdvancedSettings', new ObservableCollection<IKeyValue>());
    }
}

export class WebAppConfig extends RestRoleConfigToolData implements IWebAppConfig {
    // Version
    public get version(): IRestVersion {
        return this.getFieldObject<RestVersion, IRestVersion>(RestVersion, 'Version');
    }
    public set version(value: IRestVersion) {
        this.setFieldObject<IRestVersion>('Version', value);
    }
    // ApplicationPath
    public get applicationPath(): string {
        return this.getField<string>('ApplicationPath');
    }
    public set applicationPath(value: string) {
        this.setField<string>('ApplicationPath', value);
    }
    // UseDefaultPorts
    public get useDefaultPorts(): boolean {
        return this.getField<boolean>('UseDefaultPorts');
    }
    public set useDefaultPorts(value: boolean) {
        this.setField<boolean>('UseDefaultPorts', value);
    }
    // HttpPort
    public get httpPort(): number {
        return this.getField<number>('HttpPort');
    }
    public set httpPort(value: number) {
        this.setField<number>('HttpPort', value);
    }
    // HttpsPort
    public get httpsPort(): number {
        return this.getField<number>('HttpsPort');
    }
    public set httpsPort(value: number) {
        this.setField<number>('HttpsPort', value);
    }
    // HttpsSupported
    public get httpsSupported(): boolean {
        return this.getField<boolean>('HttpsSupported');
    }
    public set httpsSupported(value: boolean) {
        this.setField<boolean>('HttpsSupported', value);
    }
    // HttpSupported
    public get httpSupported(): boolean {
        return this.getField<boolean>('HttpSupported');
    }
    public set httpSupported(value: boolean) {
        this.setField<boolean>('HttpSupported', value);
    }
    // TokenDuration
    public get tokenDuration(): number {
        return this.getField<number>('TokenDuration');
    }
    public set tokenDuration(value: number) {
        this.setField<number>('TokenDuration', value);
    }
    // MaxClientSessions
    public get maxClientSessions(): number {
        return this.getField<number>('MaxClientSessions');
    }
    public set maxClientSessions(value: number) {
        this.setField<number>('MaxClientSessions', value);
    }
    // UseKestrel
    public get useKestrel(): boolean {
        return this.getField<boolean>('UseKestrel');
    }
    public set useKestrel(value: boolean) {
        this.setField<boolean>('UseKestrel', value);
    }
    // RoleUniqueId
    public get roleUniqueId(): string {
        return this.getField<string>('RoleUniqueId');
    }
    public set roleUniqueId(value: string) {
        this.setField<string>('RoleUniqueId', value);
    }
    // BindCertificate
    public get bindCertificate(): boolean {
        return this.getField<boolean>('BindCertificate');
    }
    public set bindCertificate(value: boolean) {
        this.setField<boolean>('BindCertificate', value);
    }
    // EnableLoadBalancing
    public get enableLoadBalancing(): boolean {
        return this.getField<boolean>('EnableLoadBalancing');
    }
    public set enableLoadBalancing(value: boolean) {
        this.setField<boolean>('EnableLoadBalancing', value);
    }
    public get localAddress(): ObservableCollection<string> {
        return this.getFieldArray<string>('LocalAddress');
    }
    public set localAddress(value: ObservableCollection<string>) {
        this.setFieldArray<string>('LocalAddress', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        const objVersion = new RestVersion();
        objVersion.initializeAllFields();
        this.setFieldObject('Version', objVersion);
        this.setField<string>('ApplicationPath', '');
        this.setField<boolean>('UseDefaultPorts', false);
        this.setField<number>('HttpPort', 0);
        this.setField<number>('HttpsPort', 0);
        this.setField<boolean>('HttpsSupported', false);
        this.setField<boolean>('HttpSupported', false);
        this.setField<number>('TokenDuration', 0);
        this.setField<number>('MaxClientSessions', 0);
        this.setField<boolean>('UseKestrel', false);
        this.setField<string>('RoleUniqueId', '');
        this.setField<boolean>('BindCertificate', false);
        this.setField<boolean>('EnableLoadBalancing', false);
        this.setField<string[]>('LocalAddress', []);
    }
}
