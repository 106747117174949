import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { StreamDataView } from 'RestClient/Helpers/StreamDataView';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export enum SerializationSteps {
  HeaderStart,
  HeaderEntry,
  HeaderEnd,
  DataStart,
  DataEntry,
  DataEnd,
}

export enum SerializationTypes {
  Long,
  Byte,
  Int,
  String,
  Guid,
  OptionalGuid,
  Double,
}

export class FieldColumn {
  constructor(public keyName: string, public type: SerializationTypes) {}
}

export class ListFieldObjectSerializer {
  public static deserialize<T extends FieldObject, U extends IFieldObject>(classType: new () => T, buffer: ArrayBuffer): ObservableCollection<U> {
    const values: ObservableCollection<U> = new ObservableCollection<U>();

    const columns: FieldColumn[] = new Array<FieldColumn>();

    if (buffer.byteLength <= 0) {
      return values;
    }

    const data = new StreamDataView(buffer);

    // read header for data referral columns
    if (data.getByte() === SerializationSteps.HeaderStart) {
      while (data.getByte() !== SerializationSteps.HeaderEnd) {
        const fieldKey = data.getString();
        columns.push(new FieldColumn(fieldKey, data.getByte()));
      }
    }

    if (data.getByte() === SerializationSteps.DataStart) {
      while (data.getByte() !== SerializationSteps.DataEnd) {
        const newObj = new classType();
        columns.forEach(column => {
          if (column.type === SerializationTypes.Byte) {
            newObj.setField(column.keyName, data.getByte());
          } else if (column.type === SerializationTypes.Double) {
            newObj.setField(column.keyName, data.getFloat64());
          } else if (column.type === SerializationTypes.Guid || column.type === SerializationTypes.OptionalGuid) {
            const guidString = data.getGuidString();
            newObj.setField(column.keyName, guidString.toLowerCase());
          } else if (column.type === SerializationTypes.Int) {
            newObj.setField(column.keyName, data.getInt32());
          } else if (column.type === SerializationTypes.Long) {
            newObj.setField(column.keyName, data.getFloat64());
          } else if (column.type === SerializationTypes.String) {
            const dataString = data.getString();
            newObj.setField(column.keyName, dataString);
          }
        });

        values.add(newObj as unknown as U);
      }
    }

    return values;
  }
}
