import { Injectable } from '@angular/core';
import { IGuid } from 'safeguid';
import { PluginsStack } from '../../shared/components/plugins/plugins-stack';
import { ContentGroup } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '../../shared/interfaces/plugins/public/plugin-types';
import { ContentProviderService } from '../../shared/services/content/content-provider.service';
import { PluginService } from '../../shared/services/plugin/plugin.service';
import { TilesConstants } from '../enumerations/tiles-constants';
import { TileItem } from '../models/tile-item';

@Injectable()
export class TileService {
    constructor(private pluginService: PluginService, private contentProviderService: ContentProviderService) {}

    public async tryUpdateTileContentAsync(tile: TileItem, content: ContentGroup | null, dryRun: boolean): Promise<boolean> {
        if (content && (!tile.content || content.id !== tile.content.id)) {
            if (!content.id.isEmpty()) {
                await this.updateTileContent(tile, content, dryRun);
                return true;
            }
        }
        return false;
    }

    public async tryDisplayEntityInTileAsync(tile: TileItem, entityId: IGuid): Promise<boolean> {
        // build a content to display in the tiles
        const contentGroup = await this.contentProviderService.getContentAsync(entityId, TilesConstants.TilesContentContextId);
        if (contentGroup) {
            return await this.tryUpdateTileContentAsync(tile, contentGroup, false);
        }
        return false;
    }

    /**
     * Use this method when the main content of the tile remains the same, but the subContents have changed.
     * For example, navigation arrows on a video player that supports multiple cameras.
     *
     * @param tile tile in which the update occurs
     * @param content the "altered" content of the tile (usually only the subContents order will be different. The first item in the array is the one displayed)
     * @param dryRun if true, only a "dry-run" will be run.
     * @returns if the call succeeded, false otherwise.
     */
    public async tryUpdateTileSubContentsAsync(tile: TileItem, content: ContentGroup, dryRun: boolean): Promise<boolean> {
        if (content) {
            if (!content.id.isEmpty()) {
                await this.updateTileContent(tile, content, dryRun);
                return true;
            }
        }
        return false;
    }

    private async updateTileContent(tile: TileItem, content: ContentGroup, dryRun: boolean) {
        const pluginsSupported = await this.pluginService.getPlugins(PluginTypes.Tile, content.mainContent);

        if (content.subContents) {
            for (const subContent of content.subContents) {
                const subPlugins = await this.pluginService.getPlugins(PluginTypes.Tile, subContent);
                subPlugins.forEach((item) => {
                    pluginsSupported.push(item);
                });
            }
        }

        // sort by priority
        const allPlugins = pluginsSupported.sort((p1, p2) => {
            const priority1 = p1 === undefined || p1.exposure.priority === undefined ? 0 : p1.exposure.priority;
            const priority2 = p2 === undefined || p2.exposure.priority === undefined ? 0 : p2.exposure.priority;

            return priority1 - priority2;
        });

        if (allPlugins && allPlugins.length > 0) {
            if (!dryRun) {
                tile.content = content;

                const pluginsStack = new PluginsStack();
                pluginsStack.fill(allPlugins);

                // be sure to update the reference here to trigger a change
                tile.pluginsStack = pluginsStack;
            }
        }
    }
}
