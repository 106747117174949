// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { AsyncValidatorFn, FormControl, FormControlOptions, FormControlState, ValidatorFn } from '@angular/forms';

/**
 * Typed form control accepting a label which can be used to get the corresponding label to the form control (useful for error toasts).
 */
export class LabeledFormControl<T = any> extends FormControl<T> {
    constructor(
        public label: string,
        formState: T | FormControlState<T>,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
    ) {
        super(formState, validatorOrOpts, asyncValidator);
    }
}
