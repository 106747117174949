import { Component, Inject, OnInit } from '@angular/core';
import { SafeGuid } from 'safeguid';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes, OptionSubSections } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { TextFlavor } from '@genetec/gelato-angular';
import { OptionTypes } from '../../enumerations/option-types';
import { ClearanceFeatures } from '../../enumerations/clearance-features';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-clearance-options',
    templateUrl: './clearance-options.component.html',
    styleUrls: ['./clearance-options.component.scss'],
})
@InternalPluginDescriptor({
    type: ClearanceOptionsComponent,
    pluginTypes: [PluginTypes.Option],
    exposure: {
        id: ClearanceOptionsComponent.pluginId,
        subSection: OptionSubSections.Advanced,
    },
    isSupported: () => true,
    requirements: {
        features: [ClearanceFeatures.clearanceFeature],
    },
})
export class ClearanceOptionsComponent extends TrackedComponent implements OnInit {
    public static readonly pluginId = new SafeGuid('4d33e967-0b87-4a21-a769-9bcb6c346b1f');

    public TextFlavor = TextFlavor;
    public currentSettings = '';

    constructor(private translateService: TranslateService, trackingService: TrackingService, @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        const clearanceSettings = this.userSettingsService.get<string>(OptionTypes.clearanceOptions, 'ClearanceConfiguration');
        if (clearanceSettings) {
            this.currentSettings = clearanceSettings;
        }
    }

    public settingsUpdated(text: string): void {
        this.userSettingsService.set(OptionTypes.clearanceOptions, 'ClearanceConfiguration', text);
    }
}
