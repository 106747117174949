import { SafeGuid } from 'safeguid';
import { Constants } from '../utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const OptionTypes = Constants({
    LanguageAndTime: SafeGuid.parse('D9DE7474-7A7A-40AA-BD76-D13BE80FED69'),
    Peripherals: SafeGuid.parse('8DBE99B1-9E6A-44BE-A498-A9C9351E60AC'),
    Appearance: SafeGuid.parse('2B0D3C81-A7A1-49BC-A7C4-20E5D88D8590'),
    Events: SafeGuid.parse('65C854A3-02C4-4A00-A3CB-B0E768581843'),
    Maps: SafeGuid.parse('B3513C19-F7A5-411A-8657-FC4E2665570D'),
});
