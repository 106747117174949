import { RestTransaction } from '../Connection/RestTransaction';
import { RestTransactionResponse } from '../Connection/RestTransactionResponse';
import { RestResponse } from '../Connection/RestResponse';
import { SecurityCenterClient } from './SecurityCenterClient';
import { CancellationToken } from '../Helpers/Helpers';
import HttpStatusCode from './Enumerations/HttpStatusCode';
import { ITransaction, ITransactionContext } from "./Interface/ITransaction";
import { ITransactionResult } from "./Interface/IRestTransactionResponse";
import { IRestTransaction } from "./Interface/IRestTransaction";

export class TransactionContext<T> implements ITransactionContext {
  public uri = '';
  public verb = '';
  public body: object | null = null;
  public handled = false;
  public transactionRequired = false;

  public responseHandler: ((response: RestResponse) => Promise<T>) | null = null;
  public callback: ((response: T) => Promise<void>) | null = null;

  public async handleResponse(result: ITransactionResult) {
    if (this.responseHandler != null) {
      const rr = new RestResponse();
      rr.parseTransactionResult(result);
      const res = await this.responseHandler(rr);
      if (this.callback != null) {
        await this.callback(await res);
      }
    }
  }
}

export class Transaction implements ITransaction {
  private _transaction: RestTransaction | null = null;
  private _lastContext: ITransactionContext | null = null;

  // #region Properties

  public get restTransaction(): IRestTransaction | null {
    return this._transaction;
  }

  // #endregion

  // #region Constructor, Dispose

  constructor(client: SecurityCenterClient) {
    this._transaction = new RestTransaction(client.rest);
  }

  public dispose(): void {
    this._transaction = null;
  }

  // #endregion

  // #region Public methods

  public async queueAsync<T>(operations: Promise<T | null>, action?: (response: T) => Promise<void>) {
    if (!this._lastContext) {
      throw new Error("This operation doesn't support transaction queueing");
    }

    const ctx = this._lastContext as TransactionContext<T>;
    if (ctx) {
      if (ctx.transactionRequired === false && action && ctx.responseHandler) {
        // no transaction, do it right now
        const response = new RestResponse();
        response.statusCode = HttpStatusCode.OK;
        await action(await ctx.responseHandler(response));
      } else {
        if (action) {
          ctx.callback = action;
        } else {
          ctx.callback = null;
        }
      }
    } else {
      throw new Error('Invalid transaction context');
    }
    this._lastContext = null;
    return;
  }

  public async executeAsync(token?: CancellationToken): Promise<RestTransactionResponse> {
    if (this._transaction == null) {
      throw new Error('Transaction is null');
    }

    const response = await this._transaction.executeTransactionAsync(token);
    if (response.statusCode === HttpStatusCode.OK) {
      for (const transactionResult of response.transactionResult) {
        // the result comes in the same order as they were requested
        const ctx = transactionResult.context as ITransactionContext;
        if (ctx != null) {
          await ctx.handleResponse(transactionResult);
        }
      }
    } else {
      // The transaction failed totally... at least call any registered handlers with the "big" error
      for (const transactionResult of response.transactionResult) {
        const ctx = transactionResult.context as ITransactionContext;
        if (ctx != null) {
          transactionResult.statusCode = response.statusCode;
          if (response.body) {
            transactionResult.result = response.body;
          }
          await ctx.handleResponse(transactionResult);
        }
      }
    }
    return response;
  }

  // #endregion

  // #region Internal methods

  public addTransactionOperation<T>(
    uri: string,
    verb: string,
    body: object | null,
    responseHandler?: (response: RestResponse) => Promise<T>,
  ) {
    if (this._transaction == null) {
      throw new Error('Transaction is null');
    }

    const ctx = new TransactionContext<T>();
    if (responseHandler) {
      ctx.responseHandler = responseHandler;
    }

    if (verb) {
      ctx.transactionRequired = true;
      this._transaction.addTransactionOperation(uri, verb, body, ctx);
    } else {
      // no need for transaction... can execute the callback right away
      ctx.transactionRequired = false;
    }
    this._lastContext = ctx;
  }
  // #endregion
}
