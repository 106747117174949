<gen-melted-modal
    [id]="modalId"
    genTitle="{{ 'STE_ACTION_EDITCOLUMNS' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_APPLY' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    class="toggle-columns-modal"
    [genOnDefaultAction]="handleApply"
    [genOnAlternativeAction]="handleCancel"
    [genIsBlocking]="true"
    appFocusModalWhenOpen
>
    <gen-melted-modal-body>
        <div class="container">
            <gen-tree [selectionType]="SelectionType.Tree.Multiple" [flavor]="TreeFlavor.Solid">
                <gen-tree-item *ngFor="let item of treeItems" [selected]="item.isChecked" (selectedChange)="onItemChecked(item, $event)">
                    <gen-item>
                        <gen-text>{{ item.text | translate }}</gen-text>
                    </gen-item>
                    <gen-tree *ngIf="item.children?.length">
                        <gen-tree-item *ngFor="let child of item.children" [selected]="child.isChecked" (selectedChange)="onItemChecked(child, $event)">{{
                            child.text | translate
                        }}</gen-tree-item>
                    </gen-tree>
                </gen-tree-item>
            </gen-tree>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
