import { Injectable } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { CameraTypes } from 'RestClient/Client/Enumerations/CameraType';
import { AccessPointType } from 'RestClient/Client/Enumerations/AccessPointType';
import { DeviceType } from 'RestClient/Client/Enumerations/DeviceType';
import { EntityTypeData, IEntityTypeData } from '../models/entity-type-data';

@Injectable({ providedIn: 'root' })
export class IconsService {
    public getEntityTypeIcon(entityType: IEntityTypeData | string, entitySubType?: string | IGuid): Icon {
        const entityTypeData = EntityTypeData.getEntityTypeData(entityType, entitySubType);

        switch (entityTypeData.type) {
            case EntityTypes.AccessPoints:
                if (entityTypeData.hasSubType()) {
                    switch (entityTypeData.subType) {
                        case AccessPointType.Floor:
                            return Icon.Layers;
                        case AccessPointType.CardReader:
                            return Icon.Reader;
                    }
                }
                return Icon.Io;
            case EntityTypes.Alarms:
                return Icon.Alarm;
            case EntityTypes.AnalogMonitors:
                return Icon.Monitor;
            case EntityTypes.Applications:
                return Icon.Applications;
            case EntityTypes.Areas:
                return Icon.Area;
            case EntityTypes.Cameras: {
                if (entityTypeData.hasSubType()) {
                    switch (entityTypeData.subType) {
                        case CameraTypes.Sequence:
                            return Icon.CameraSequence;
                        case CameraTypes.PanoramicCamera:
                        case CameraTypes.PTZCamera:
                        case CameraTypes.PTZVirtualCamera:
                            return Icon.Dome;
                        case CameraTypes.WearableCamera:
                            return Icon.BodyCamera;
                        case CameraTypes.GhostCamera:
                            return Icon.CameraGhost;
                    }
                }
                return Icon.Camera;
            }
            case EntityTypes.Cardholders:
                return Icon.Cardholder;
            case EntityTypes.CardholderGroups:
                return Icon.CardholderGroup;
            case EntityTypes.CashRegisters:
                return Icon.Money;
            case EntityTypes.Credentials:
                return Icon.Badge;
            case EntityTypes.CustomEntities:
                return Icon.Plugin;
            case EntityTypes.Devices:
                if (entityTypeData.hasSubType()) {
                    switch (entityTypeData.subType) {
                        case DeviceType.Lpr:
                            return Icon.Sharp;
                    }
                }
                return Icon.Io;
            case EntityTypes.Doors:
                return Icon.DoorClosed;
            case EntityTypes.Elevators:
                return Icon.Elevator;
            case EntityTypes.Hotlists:
                return Icon.Hotlist;
            case EntityTypes.IntrusionAreas:
                return Icon.Intrusion;
            case EntityTypes.LprUnits:
                return Icon.Sharp;
            case EntityTypes.Macros:
                return Icon.Script;
            case EntityTypes.Maps:
                return Icon.FoldedMap;
            case EntityTypes.ParkingZones:
                return Icon.Parking;
            case EntityTypes.Partitions:
                return Icon.Partition;
            case EntityTypes.Patrollers:
                return Icon.PoliceCar;
            case EntityTypes.Permits:
            case EntityTypes.PermitRules:
                return Icon.Permit;
            case EntityTypes.Roles:
                return Icon.Role;
            case EntityTypes.Sequences:
                return Icon.CameraSequence;
            case EntityTypes.SystemConfigurations:
                return Icon.Directory;
            case EntityTypes.TileLayouts:
                return Icon.TilePattern;
            case EntityTypes.TilePlugins:
                return Icon.Plugin;
            case EntityTypes.Units:
                return Icon.GenericUnit;
            case EntityTypes.UserGroups:
                return Icon.People;
            case EntityTypes.Users:
                return Icon.Person;
            case EntityTypes.VideoUnits:
                return Icon.GenericUnit;
            case EntityTypes.Visitors:
                return Icon.Visitor;
            case EntityTypes.Zones:
                return Icon.Perimeter;
            default:
                return Icon.None;
        }
    }

    public getEntityIcon(entity: IEntity): Icon {
        return this.getEntityTypeIcon(entity.entityType);
    }
}
