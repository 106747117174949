// Autogenerated: v2/help/Classes/ReportBase?codegen=ts&InterfaceOnly=False&workflow=False on 2022-12-21 2:23:59 PM
import { QueryHelper } from '../../Helpers/QueryHelper';
import * as Helpers from '../../Helpers/Helpers';
import { IBaseReport } from '../Interface/IBaseReport';
import { IReportResult } from '../Interface/IReportResult';
import { GuidSet } from 'safeguid';

export class BaseReport implements IBaseReport {
    // #region Fields

    protected _queryHelper = new QueryHelper();
    private _baseQuery = '';
    private _enableStreamedResponse = false;
    protected _supportStreamedResponse = false;
    protected _streamedResultDispatcher = new Helpers.EventDispatcher<IReportResult>();

    // #endregion

    // #region REST Properties

    public cultureName: string | undefined;
    public entityLookup: boolean | undefined;
    public excludedExpansionEntities = new GuidSet();
    public includedExpansionEntities = new GuidSet();
    public maxResultsCount: number | undefined;
    public sortOrder: string | undefined; // ApiSortingOption
    public sourceTimeZoneId: string | undefined;
    public timeEnd: Date | undefined;
    public timeout: number | undefined;
    public timeStart: Date | undefined;

    // #endregion REST Properties

    // #region Properties

    public get supportStreamedResponse(): boolean {
        return this._supportStreamedResponse;
    }

    public get enableStreamedResponse(): boolean {
        return this._enableStreamedResponse;
    }
    public set enableStreamedResponse(value: boolean) {
        if ( (this.supportStreamedResponse === false) && (value === true) ) {
            throw new Error('Not supported streamed response');
        }
        this._enableStreamedResponse = value;
    }

    // #endregion Properties

    // #region Constructor

    constructor(baseQuery: string) {
        this._baseQuery = baseQuery;
    }

    // #endregion Constructor

    // #region Methods

    public fireSubResult(result: IReportResult) {
        this._streamedResultDispatcher.dispatch(result);
    }

    public onStreamedResultReceived(handler: Helpers.Handler<IReportResult>) {
        return this._streamedResultDispatcher.subscribe(handler);
    }

    public buildQuery(): string {
        let query = this._baseQuery + '?';
        query = this._queryHelper.buildElem(query, 'CultureName', this.cultureName);
        query = this._queryHelper.buildElem(query, 'MaxResultsCount', this.maxResultsCount);
        query = this._queryHelper.buildElem(query, 'TimeStart', this.timeStart);
        query = this._queryHelper.buildElem(query, 'TimeEnd', this.timeEnd);
        query = this._queryHelper.buildElem(query, 'EntityLookup', this.entityLookup);
        query = this._queryHelper.buildElem(query, 'SortOrder', this.sortOrder);
        query = this._queryHelper.buildElemList(query, 'ExcludedExpansionEntities', this.excludedExpansionEntities);
        query = this._queryHelper.buildElemList(query, 'IncludedExpansionEntities', this.includedExpansionEntities);
        query = this._queryHelper.buildElem(query, 'Timeout', this.timeout);
        query = this._queryHelper.buildElem(query, 'SourceTimeZoneId', this.sourceTimeZoneId);
        return query;
    }

    // #endregion Methods

}

