import { ComponentFactory, NgModuleRef, Type } from '@angular/core';
import { PluginComponentExposure } from '../public/plugin-public.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class PluginItem {
    constructor(
        /**
         * The Component's type to be created for that plugin.
         */
        public type: Type<any>,
        public componentFactory: ComponentFactory<any>,
        public data: unknown,
        public exposure: PluginComponentExposure,
        public subTaskPlugins: PluginItem[],
        public ngModule?: NgModuleRef<any>,
        public commandTarget?: Element
    ) {}
}
