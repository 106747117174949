import { Component, OnInit } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { VideoContentTypes } from '@modules/video/enumerations/video-content-types';

const isContentSupported = (content: Content) => {
    if (content && !content.contextContent) {
        return content.type.equals(VideoContentTypes.Video);
    }
    return false;
};

@Component({
    selector: 'app-camera-events-widget',
    templateUrl: './camera-events-widget.component.html',
})
@InternalContentPluginDescriptor({
    type: CameraEventsWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CameraEventsWidgetComponent.pluginId, priority: 10 },
    isContentSupported,
})
export class CameraEventsWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('865E6579-6335-4093-8716-0D775A92C154');

    public content!: Content;
    public dataContext!: ContentService;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
