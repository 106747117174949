<div class="tile-host">
    <div
        *ngFor="let verticalPlugin of state.pluginsStack.verticalPlugins$ | async as navigationPluginList; index as i"
        [ngClass]="{ 'tile-height-remaining': i === navigationPluginList.length - 1, 'tile-height-auto': i < navigationPluginList.length - 1 }"
    >
        <app-tile-state-header [tileState]="state" [tileHostElement]="hostElement"></app-tile-state-header>
        <!-- stacked plugins (on top of each other) are only supported for last plugin for now -->
        <app-navigation-plugin-host
            [hideNavigationArrows]="hideNavigationArrows"
            #navigationPluginHost
            class="tile-plugin"
            [supportStackedPlugins]="i === navigationPluginList.length - 1"
            [captureContextMenu]="false"
            [contextDataPlugins]="verticalPlugin.navigationPlugins"
            [dataContext]="state"
            (displayedPluginsChanged)="onDisplayedPluginsChanged($event, i)"
        >
        </app-navigation-plugin-host>
    </div>
    <gen-spinner class="spinner" *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
</div>
