import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { GenAngularModule } from '@genetec/gelato-angular';
import { PlatformModule } from '@angular/cdk/platform';
import { FeatureFlagsModule } from '@modules/feature-flags/feature-flags.module';
import { ActionType } from 'RestClient/Client/Enumerations/ActionTypes';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { ReactiveFormsModule } from '@angular/forms';
import { GetBaseUriForCodeGen } from '@src/app/utilities/base-uri-getter';
import { ModuleDescriptor } from '../shared/interfaces/modules/public/module-public.interface';
import { ActionsService } from '../shared/services/actions/actions.service';
import { SharedModule } from '../shared/shared.module';
import { ExportVideoTrayComponent } from '../video/components/export-video-tray/export-video-tray.component';
import { DisplayEntitiesActionHandler } from './actions/display-entities-actionhandler';
import { PlaySoundActionHandler } from './actions/play-sound-actionhandler';
import { SendMessageActionHandler } from './actions/send-message-actionhandler';
import { AnalyticsClient, baseUrl } from './api/api';
import { AboutComponent } from './components/about/about.component';
import { ActionsTrayComponent } from './components/actions-tray/actions-tray.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangePasswordNotificationComponent } from './components/change-password/notification/change-password-notification.component';
import { EntityStateOverlayComponent } from './components/entity-state-overlay/entity-state-overlay.component';
import { FilterPluginDateComponent } from './components/filter-plugins/filter-plugin-date.component';
import { FilterPluginEntityComponent } from './components/filter-plugins/filter-plugin-entity.component';
import { FilterPluginEnumComponent } from './components/filter-plugins/filter-plugin-enum.component';
import { FilterPluginNumericRangeComponent } from './components/filter-plugins/filter-plugin-numeric-range.component';
import { FilterPluginTextComponent } from './components/filter-plugins/filter-plugin-text.component';
import { FilterPluginTreeViewComponent } from './components/filter-plugins/filter-plugin-tree-view.component';
import { MailboxTrayComponent } from './components/mailbox-tray/mailbox-tray.component';
import { MobileUserActionsWidgetComponent } from './components/mobile-user/mobile-user-actions-widget/mobile-user-actions-widget.component';
import { MobileUserWidgetComponent } from './components/mobile-user/mobile-user-widget/mobile-user-widget.component';
import { EventsOptionTreeComponent } from './components/options/events-option/events-option-tree/events-option-tree.component';
import { EventsOptionComponent } from './components/options/events-option/events-option.component';
import { JoystickOptionsComponent } from './components/options/joystick-options/joystick-options.component';
import { LanguageTimeOptionsComponent } from './components/options/language-time-options/language-time-options.component';
import { MapOptionsComponent } from './components/options/maps-options/maps-options.component';
import { TilesOptionsComponent } from './components/options/tiles-options/tiles-options.component';
import { VideoOptionsComponent } from './components/options/video-options/video-options.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PropertiesWidgetComponent } from './components/properties-widget/properties-widget.component';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { ShowMessageComponent } from './components/show-message/show-message.component';
import { ZoneEntityStateComponent } from './components/zone/tile/zone-entity-state/zone-entity-state.component';
import { ZoneActionComponent } from './components/zone/zone-action/zone-action.component';
import { ZoneEventWidgetComponent } from './components/zone/zone-event-widget/zone-event-widget.component';
import { ZoneEventsWidgetComponent } from './components/zone/zone-events-widget/zone-events-widget.component';
import { AreaBrowserItemModel } from './entity-browser/items/area-browser-item-model';
import { GeneralCommandProvider } from './services/general-command-provider';
import { JoystickService } from './services/joystick.service';
import { GamepadEventListenerService } from './services/gamepad-event-listener.service';
import { MailboxService } from './services/mailbox.service';
import { ZoneCommandProvider } from './services/zone-command-provider';
import { JoystickSelectionComponent } from './components/options/joystick-options/joystick-selection/joystick-selection.component';
import { WatchlistTrayComponent } from './components/watchlist-tray/watchlist-tray.component';
import { InsufficientPrivilegesComponent } from './components/options/components/insufficient-privileges/insufficient-privileges.component';
import { WatchlistSidepaneHeaderComponent } from './components/sidepane/watchlist-sidepane-header/watchlist-sidepane-header.component';
import { GeneralFeatureFlags } from './feature-flags';
import { SendFeedbackComponent } from './components/send-feedback/send-feedback.component';
import { SendFeedbackSuccessComponent } from './components/send-feedback/success-component/send-feedback-success.component';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { ZoneStateComponent } from './components/zone/tile/zone-state/zone-state.component';
import { FilterPluginSearchComponent } from './components/filter-plugins/filter-plugin-search.component';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@NgModule({
    declarations: [
        LanguageTimeOptionsComponent,
        VideoOptionsComponent,
        EventsOptionComponent,
        JoystickOptionsComponent,
        ActionsTrayComponent,
        ExportVideoTrayComponent,
        MailboxTrayComponent,
        ProfileComponent,
        PropertiesWidgetComponent,
        SendMessageComponent,
        ShowMessageComponent,
        MobileUserWidgetComponent,
        MobileUserActionsWidgetComponent,
        ZoneActionComponent,
        AboutComponent,
        ZoneEventsWidgetComponent,
        ZoneEventWidgetComponent,
        ChangePasswordComponent,
        FilterPluginTreeViewComponent,
        FilterPluginNumericRangeComponent,
        FilterPluginEntityComponent,
        FilterPluginDateComponent,
        FilterPluginEnumComponent,
        FilterPluginTextComponent,
        FilterPluginSearchComponent,
        ChangePasswordNotificationComponent,
        MapOptionsComponent,
        EventsOptionTreeComponent,
        EntityStateOverlayComponent,
        TilesOptionsComponent,
        ZoneEntityStateComponent,
        JoystickSelectionComponent,
        WatchlistTrayComponent,
        InsufficientPrivilegesComponent,
        WatchlistSidepaneHeaderComponent,
        SendFeedbackComponent,
        SendFeedbackSuccessComponent,
        LimitToPipe,
        ZoneStateComponent,
    ],
    imports: [CommonModule, GenAngularModule, SharedModule, PlatformModule, ReactiveFormsModule, FeatureFlagsModule.forRoot(GeneralFeatureFlags)],
    exports: [
        ProfileComponent,
        EntityStateOverlayComponent,
        FilterPluginTreeViewComponent,
        FilterPluginNumericRangeComponent,
        FilterPluginEntityComponent,
        FilterPluginDateComponent,
        FilterPluginEnumComponent,
        FilterPluginTextComponent,
        FilterPluginSearchComponent,
        LimitToPipe,
    ],
    providers: [
        DisplayEntitiesActionHandler,
        PlaySoundActionHandler,
        SendMessageActionHandler,
        JoystickService,
        GamepadEventListenerService,
        MailboxService,
        GeneralCommandProvider,
        ZoneCommandProvider,
        AnalyticsClient,
        { provide: baseUrl, deps: [], useFactory: GetBaseUriForCodeGen },
    ],
})
@ModuleDescriptor({
    type: GeneralModule,
    inclusions: {
        globalPrivileges: [KnownPrivileges.changeOwnPasswordPrivilege],
    },
})
export class GeneralModule {
    public static references = [AreaBrowserItemModel];

    constructor(
        injector: Injector,
        actionsService: ActionsService,
        mailboxService: MailboxService, //Do not remove
        generalCommandProvider: GeneralCommandProvider, //Do not remove
        zoneCommandProvider: ZoneCommandProvider //Do not remove
    ) {
        // register action handlers
        const displayEntitiesHandler = injector.get(DisplayEntitiesActionHandler);
        if (displayEntitiesHandler) {
            actionsService.registerHandler(ActionType.DisplayEntity, displayEntitiesHandler);
        }
        const playSoundHandler = injector.get(PlaySoundActionHandler);
        if (playSoundHandler) {
            actionsService.registerHandler(ActionType.PlaySound, playSoundHandler);
        }
        const sendMessageHandler = injector.get(SendMessageActionHandler);
        if (sendMessageHandler) {
            actionsService.registerHandler(ActionType.SendMessage, sendMessageHandler);
        }
    }
}
