import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/services/logger/logger.service';
import { IncidentService } from '@modules/mission-control/services/incident/incident.service';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, concatAll, filter, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import {
    AlterationType,
    CommentAddedEvent,
    CommentChangedEvent,
    CommentDeletedEvent,
    DescriptionChangedEvent,
    DisabledStatesChangedEvent,
    ExternalEventAggregatedEvent,
    ExternalIdChangedEvent,
    IncidentCreatedEvent,
    IncidentEvent,
    IncidentEventType,
    LinkedEvent,
    LocationChangedEvent,
    LocationClearedEvent,
    NoteAddedEvent,
    OwnershipChangedEvent,
    PriorityChangedEvent,
    ProcedureStepAnsweredEvent,
    RecipientAlteredEvent,
    RecipientsMode,
    StateChangedEvent,
    TypeChangedEvent,
    UnlinkedEvent,
} from '@modules/mission-control/models/events/incident-event';
import { TranslateService } from '@ngx-translate/core';
import { Icon } from '@genetec/gelato';
import moment from 'moment';
import { WebAppClient } from 'WebClient/WebAppClient';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { stringFormat } from '@shared/utilities/StringFormat';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { ProcedureStepActionType } from '@modules/mission-control/models/procedure-step-action-type';
import { StateService } from '@modules/mission-control/services/state/state.service';
import { IncidentLocationService } from '@modules/mission-control/services/incident-location/incident-location.service';
import { McUserService } from '@modules/mission-control/services/mc-user.service';
import { PriorityService } from '@modules/mission-control/services/priority/priority.service';
import { IGuid, SafeGuid } from 'safeguid';
import { EventTypes } from 'RestClient/Client/Enumerations/EventTypes';
import { EventTypesHelper } from '@shared/utilities/eventTypes.helper';
import { toGuid } from '@modules/mission-control/utils/guid-utils';
import { IEntityCacheTask } from "RestClient/Client/Interface/IEntityCacheTask";

export class ActivityEvent {
    constructor(
        public id: IGuid,
        public type: IncidentEventType,
        public category: EventCategory,
        public processTimeUtc: Date,
        public delimiter: string,
        public isSameDay: boolean,
        public isExpanded: boolean,
        public textDisplay: string
    ) {
    }
}

interface EventCategory {
    icon: Icon;
    name: string;
}

@Injectable()
export class ActivityEventService {

    private readonly _unavailableInformation: string;
    private readonly _ignoredInitialValue = [
        IncidentEventType.DescriptionChanged,
        IncidentEventType.ExternalIdChanged,
        IncidentEventType.PriorityChanged,
        IncidentEventType.StateChanged,
    ];
    private _entityCache: IEntityCacheTask;

    constructor(
        private _incidentService: IncidentService,
        private _locationService: IncidentLocationService,
        private _stateService: StateService,
        private _priorityService: PriorityService,
        private _userService: McUserService,
        private _translateService: TranslateService,
        private securityCenterProvider: SecurityCenterClientService,
        private _logger: LoggerService
    ) {
        this._entityCache = this.securityCenterProvider.scClient.buildEntityCache([EntityFields.idField, EntityFields.nameField]);
        this._unavailableInformation = this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string;
    }

    public getActivityEvents(incident: MCIncident, filters: string[]): Observable<ActivityEvent[]> {
        return of(incident).pipe(switchMap((i) => this.buildActivityEventForIncident(i, filters)));
    }

    private buildActivityEventForIncident(incident: MCIncident, filters: string[]): Observable<ActivityEvent[]> {
        if (!incident || incident.events.size === 0) {
            this._logger.traceInformation('No activity to build');
            return of([]);
        }

        const createdEvent = incident.events.find((e) => e.type === IncidentEventType.Created);

        const events = incident.events.filter((e) => !(this._ignoredInitialValue.includes(e.type) && e.header.correlationid.equals(createdEvent!.header.correlationid))).toArray();

        if (events.length === 0) {
            this._logger.traceInformation(`No activity Event to build [${events.length} event(s)]`);
            return of([]);
        }

        const instigators$ = this.buildInstigatorsObs(events);
        const commentHistory = this.buildCommentHistory(incident);
        const ownershipHistory = this.buildOwnershipHistory(incident);
        const disabledStateHistory = this.buildDisabledStateHistory(incident);
        const recipientNameById$ = this.buildRecipientsMap(incident);

        return forkJoin(
            [...events].map((ie) => {
                return combineLatest([instigators$, recipientNameById$]).pipe(
                    switchMap(([instigators, recipientNameById]) => {
                        const instigatorName = instigators[ie.instigatorId.toString().toUpperCase()] ?? this._unavailableInformation;
                        try {
                            switch (ie.type) {
                                case IncidentEventType.StateChanged:
                                    return this.generateStateChangedEvent(ie as StateChangedEvent, instigatorName);
                                case IncidentEventType.RecipientsAltered:
                                    return this.generateRecipientAlteredActivity(ie as RecipientAlteredEvent, instigatorName, recipientNameById);
                                case IncidentEventType.Created:
                                    return this.generateCreatedActivity(ie as IncidentCreatedEvent, instigatorName);
                                case IncidentEventType.PriorityChanged:
                                    return this.generatePriorityChangedActivity(ie as PriorityChangedEvent, instigatorName);
                                case IncidentEventType.Linked:
                                    return this.generateLinkedActivity(ie as LinkedEvent, instigatorName);
                                case IncidentEventType.Unlinked:
                                    return this.generateUnlinkedActivity(ie as UnlinkedEvent, instigatorName);
                                case IncidentEventType.CommentAdded:
                                    return this.generateCommentAddedActivity(ie as CommentAddedEvent, instigatorName);
                                case IncidentEventType.CommentChanged:
                                    return this.generateCommentChangedActivity(ie as CommentChangedEvent, instigatorName, commentHistory);
                                case IncidentEventType.CommentDeleted:
                                    return this.generateCommentDeletedActivity(ie as CommentDeletedEvent, instigatorName, commentHistory);
                                case IncidentEventType.LocationChanged:
                                    return this.generateLocationChangedActivity(ie as LocationChangedEvent, instigatorName);
                                case IncidentEventType.DescriptionChanged:
                                    return this.generateDescriptionChangedActivity(ie as DescriptionChangedEvent, instigatorName);
                                case IncidentEventType.ExternalIdChanged:
                                    return this.generateExternalIdChangedActivity(ie as ExternalIdChangedEvent, instigatorName);
                                case IncidentEventType.IncidentTypeChanged:
                                    return this.generateIncidentTypeChangedActivity(ie as TypeChangedEvent, instigatorName);
                                case IncidentEventType.OwnershipChanged:
                                    return this.generateOwnershipChangedActivity(ie as OwnershipChangedEvent, ie.instigatorId, instigatorName, ownershipHistory);
                                case IncidentEventType.LocationCleared:
                                    return this.generateLocationClearedActivity(ie as LocationClearedEvent, instigatorName);
                                case IncidentEventType.NoteAdded:
                                    return this.generateNoteAddedActivity(ie as NoteAddedEvent, instigatorName);
                                case IncidentEventType.ProcedureStepAnswered:
                                    return this.generateProcedureStepAnsweredActivity(incident, ie as ProcedureStepAnsweredEvent, instigatorName);
                                case IncidentEventType.DisabledStatesChanged:
                                    return this.generateDisabledStatesChangedActivity(ie as DisabledStatesChangedEvent, instigatorName, disabledStateHistory);
                                case IncidentEventType.ExternalEventAggregated:
                                    return this.generateExternalEventAggregatedActivity(ie as ExternalEventAggregatedEvent);
                                default:
                                    this._logger.traceInformation(`${ie.type} is not supported`);
                                    return of(null);
                            }
                        } catch (e) {
                            this._logger.traceError(`Unable to determine the text to display : ${e as string}`);
                        }
                        return of(null);
                    }),
                    map((textDisplay) => {
                        if (!textDisplay) {
                            this._logger.traceError(`Unable to build text display for ${ie.type}`);
                            return null;
                        }

                        const category = this.determineCategory(ie);
                        const isSameDay = moment(moment(ie.processTimeUtc)).isSame(new Date(), 'day');
                        const processTimeUtc = new Date(ie.processTimeUtc);

                        return new ActivityEvent(ie.id, ie.type, category, processTimeUtc, '', isSameDay, false, textDisplay);
                    }),
                    catchError((e) => {
                        this._logger.traceError(`Unable to build ${ie?.type} activity ${e as string}`);
                        throw e;
                    }),
                    take(1)
                );
            })
        ).pipe(
            concatAll(),
            filter((e): e is ActivityEvent => e instanceof ActivityEvent && filters.includes(e.category.name)),
            toArray(),
            map((activityEvents) => this.determineDelimiter(activityEvents)),
            catchError((e) => {
                this._logger.traceError(`Unable to build pipe activity events ${e as string}`);
                throw e;
            })
        );
    }

    private generateStateChangedEvent(stateEvent: StateChangedEvent, instigatorName: string): Observable<string> {
        const translatedString = this._translateService.instant('STE_LABEL_INCIDENT_STATE_CHANGED_ACTIVITY') as string;
        return this._stateService.getState$(stateEvent.stateId).pipe(map((state) => stringFormat(translatedString, instigatorName, state?.name ?? this._unavailableInformation)));
    }

    private determineCategory(event: IncidentEvent): EventCategory {
        const eventCategory: EventCategory = { icon: Icon.Incident, name: this._translateService.instant('STE_LABEL_SYSTEM_ACTION') as string } as EventCategory;
        const userActionLabel = this._translateService.instant('STE_LABEL_USER_ACTION') as string;
        const systemActionLabel = this._translateService.instant('STE_LABEL_SYSTEM_ACTION') as string;
        const commentsLabel = this._translateService.instant('STE_LABEL_COMMENTS') as string;
        switch (event.type) {
            case IncidentEventType.NoteAdded:
                eventCategory.name = this._translateService.instant('STE_LABEL_NOTES') as string;
                eventCategory.icon = Icon.Email;
                break;
            case IncidentEventType.ProcedureStepAnswered:
                eventCategory.name = this._translateService.instant('STE_LABEL_PROCEDURE') as string;
                eventCategory.icon = Icon.LogFile;
                break;
            case IncidentEventType.IncidentTypeChanged:
            case IncidentEventType.DisabledStatesChanged:
                eventCategory.name = systemActionLabel;
                eventCategory.icon = Icon.Incident;
                break;
            case IncidentEventType.LocationChanged:
                eventCategory.name = systemActionLabel;
                eventCategory.icon = Icon.PushpinOn;
                break;
            case IncidentEventType.ExternalEventAggregated:
                eventCategory.name = userActionLabel;
                eventCategory.icon = Icon.Event;
                break;
            case IncidentEventType.Created:
            case IncidentEventType.DescriptionChanged:
            case IncidentEventType.ExternalIdChanged:
            case IncidentEventType.Linked:
            case IncidentEventType.PriorityChanged:
            case IncidentEventType.OwnershipChanged:
            case IncidentEventType.RecipientsAltered:
            case IncidentEventType.StateChanged:
            case IncidentEventType.Unlinked:
                eventCategory.name = userActionLabel;
                eventCategory.icon = Icon.Person;
                break;
            case IncidentEventType.CommentAdded:
            case IncidentEventType.CommentChanged:
            case IncidentEventType.CommentDeleted:
                eventCategory.name = commentsLabel;
                eventCategory.icon = Icon.Message;
                break;
            default:
                this._logger.traceError(`${event.type} is not supported`);
                break;
        }

        if (event.instigatorId.equals(SafeGuid.EMPTY) && eventCategory) {
            eventCategory.name = systemActionLabel;
            eventCategory.icon = Icon.Incident;
        }

        return eventCategory;
    }

    private determineDelimiter(displayableEvents: ActivityEvent[]): ActivityEvent[] {
        const today = new Date();

        const isToday = (e: ActivityEvent): boolean => moment(moment(e.processTimeUtc)).isSame(today, 'day');
        const isYesterday = (e: ActivityEvent): boolean => moment(moment(e.processTimeUtc)).isSame(moment().subtract(1, 'days').startOf('day'), 'day');
        const isThisWeek = (e: ActivityEvent): boolean => moment(moment(e.processTimeUtc)).isSame(today, 'week');
        const isThisMonth = (e: ActivityEvent): boolean => moment(moment(e.processTimeUtc)).isSame(today, 'month');
        const isLastMonth = (e: ActivityEvent): boolean => moment(moment(e.processTimeUtc)).isSame(moment().subtract(1, 'month').startOf('month'), 'month');
        const isThisYear = (e: ActivityEvent): boolean => moment(moment(e.processTimeUtc)).isSame(today, 'year');

        let index = displayableEvents.findIndex((e) => isToday(e));
        if (index >= 0) displayableEvents[index].delimiter = this._translateService.instant('STE_LABEL_TODAY') as string;

        index = displayableEvents.findIndex((e) => !isToday(e) && isYesterday(e));
        if (index >= 0) displayableEvents[index].delimiter = this._translateService.instant('STE_LABEL_YESTERDAY') as string;

        index = displayableEvents.findIndex((e) => !isToday(e) && !isYesterday(e) && isThisWeek(e));
        if (index >= 0) displayableEvents[index].delimiter = this._translateService.instant('STE_LABEL_THIS_WEEK') as string;

        index = displayableEvents.findIndex((e) => !isToday(e) && !isYesterday(e) && !isThisWeek(e) && isThisMonth(e));
        if (index >= 0) displayableEvents[index].delimiter = this._translateService.instant('STE_LABEL_THIS_MONTH') as string;

        index = displayableEvents.findIndex((e) => !isToday(e) && !isYesterday(e) && !isThisWeek(e) && !isThisMonth(e) && isLastMonth(e));
        if (index >= 0) displayableEvents[index].delimiter = this._translateService.instant('STE_LABEL_LAST_MONTH') as string;

        index = displayableEvents.findIndex((e) => !isToday(e) && !isYesterday(e) && !isThisWeek(e) && !isThisMonth(e) && !isLastMonth(e) && isThisYear(e));
        if (index >= 0) displayableEvents[index].delimiter = this._translateService.instant('STE_LABEL_THIS_YEAR') as string;

        index = displayableEvents.findIndex((e) => !isToday(e) && !isYesterday(e) && !isThisWeek(e) && !isThisMonth(e) && !isLastMonth(e) && !isThisYear(e));
        if (index >= 0) displayableEvents[index].delimiter = this._translateService.instant('STE_LABEL_LONG_TIME_AGO') as string;

        return displayableEvents;
    }

    private buildInstigatorsObs(events: Array<IncidentEvent>): Observable<Record<string, string>> {
        const ids = SafeGuid.createSet(events.map((e) => e.instigatorId));
        return from(this._entityCache.getEntitiesAsync<UserEntity, IUserEntity>(UserEntity, ids, true)).pipe(
            map((users) => {
                const record: Record<string, string> = {};
                users.forEach((user) => (record[user.id.toString().toUpperCase()] = user.name));
                record[SafeGuid.EMPTY.toString().toUpperCase()] = this._translateService.instant('STE_LABEL_SYSTEM') as string;
                return record;
            }),
            catchError((e) => {
                this._logger.traceError(`Unable to build instigator record : ${e as string}`);
                throw e;
            })
        );
    }

    private buildCommentHistory(incident: MCIncident): Record<string, Array<string>> {
        const commentHistory: Record<string, Array<string>> = {};

        try {
            const revertedComments = incident.events.reverse();
            revertedComments.forEach((e) => {
                if (e instanceof CommentAddedEvent) {
                    const stringId = e.commentId!.toString();
                    if (stringId in commentHistory) {
                        commentHistory[stringId].push(e.comment);
                    } else {
                        commentHistory[stringId] = [e.comment];
                    }
                } else if (e instanceof CommentChangedEvent) {
                    const stringId = e.commentId!.toString();
                    if (stringId in commentHistory) {
                        commentHistory[stringId].push(e.comment);
                    } else {
                        commentHistory[stringId] = [e.comment];
                    }
                }
            });
        } catch (e) {
            this._logger.traceError(`Unable to build comment history : ${e as string}`);
        }
        return commentHistory;
    }

    private buildDisabledStateHistory(incident: MCIncident): Record<string, Array<IGuid>> {
        const disabledStateHistory: Record<string, Array<IGuid>> = {};
        try {
            const sortedEvents = incident.events
                .filter((e) => e instanceof DisabledStatesChangedEvent)
                .sort((a, b) => new Date(a.processTimeUtc).getTime() - new Date(b.processTimeUtc).getTime())
                .map((e) => e as DisabledStatesChangedEvent)
                .toArray()
                .reverse();

            sortedEvents.forEach((e, i) => {
                const eventId = e.id.toString().toUpperCase();
                disabledStateHistory[eventId] = i > 0 ? sortedEvents[i - 1].disabledStates : [];
            });
        } catch (e) {
            this._logger.traceError(`Unable to build disable states history : ${e as string}`);
        }
        return disabledStateHistory;
    }

    private buildOwnershipHistory(incident: MCIncident): Record<string, IGuid | null> {
        const ownershipHistory: Record<string, IGuid | null> = {};
        try {
            const ownershipEvents = incident.events
                .filter((e) => e instanceof OwnershipChangedEvent)
                .sort((a, b) => new Date(a.processTimeUtc).getTime() - new Date(b.processTimeUtc).getTime())
                .map((e) => e as OwnershipChangedEvent)
                .toArray();

            ownershipEvents.forEach((e, i) => {
                const eventId = e.id.toString().toUpperCase();
                ownershipHistory[eventId] = i > 0 ? ownershipEvents[i - 1].owner : null;
            });
        } catch (e) {
            this._logger.traceError(`Unable to build ownership history : ${e as string}`);
        }

        return ownershipHistory;
    }

    private buildRecipientsMap(incident: MCIncident): Observable<Map<string, string>> {
        const userIds = incident.events.filter((e): e is RecipientAlteredEvent => e instanceof RecipientAlteredEvent).flatMap((e) => e.addedRecipients.concat(e.removedRecipients));
        const ids = SafeGuid.createSet(userIds);
        return from(this._entityCache.getEntitiesAsync<Entity, IEntity>(Entity, ids, true)).pipe(
            map((entities) =>
                new Map(entities.map(entity =>
                    [entity.id.toString().toUpperCase(), entity.id.equals(SafeGuid.EMPTY) ? this._translateService.instant('STE_LABEL_SYSTEM') as string : entity.name]))),
            catchError((e) => {
                this._logger.traceError(`Unable to build recipient record : ${e as string}`);
                throw e;
            })
        );
    }

    private generateDispatchActivity(event: RecipientAlteredEvent, recipientNameById: Map<string, string>): string | null {
        let usersString: string | null = null;
        let dispatchToSupervisorsOnly = false;
        switch (event.recipientsMode) {
            case RecipientsMode.Everyone:
                usersString = this._translateService.instant('STE_LABEL_EVERYONE') as string;
                break;
            case RecipientsMode.Specific:
                usersString = event.addedRecipients.map((recipientId) => recipientNameById.get(recipientId.toString().toUpperCase())).join(', ');
                break;
            case RecipientsMode.Supervisors:
                dispatchToSupervisorsOnly = true;
                break;
        }
        if (!usersString && dispatchToSupervisorsOnly) {
            usersString = this._translateService.instant('STE_LABEL_SUPERVISORS') as string;
        } else if (!usersString) {
            return null;
        }

        const activityString = this._translateService.instant('STE_MESSAGE_X_RECEIVED_THE_INCIDENT') as string;
        return usersString ? stringFormat(activityString, usersString) : null;
    }

    private generateForwardActivity(event: RecipientAlteredEvent, instigatorName: string, recipientNameById: Map<string, string>): string | null {
        let usersString = event.addedRecipients.map((recipientId) => recipientNameById.get(recipientId.toString().toUpperCase())).join(', ');
        const dispatchToSupervisorsOnly = event.recipientsMode === RecipientsMode.Supervisors;
        if (!usersString && dispatchToSupervisorsOnly) {
            usersString = this._translateService.instant('STE_LABEL_SUPERVISORS') as string;
        } else if (!usersString) {
            return null;
        }
        const activityString = this._translateService.instant('STE_LABEL_INCIDENT_FORWARD_ACTIVITY') as string;
        return stringFormat(activityString, instigatorName, usersString);
    }

    private generateTransferActivity(event: RecipientAlteredEvent, instigatorName: string, recipientNameById: Map<string, string>): string | null {
        const usersString = event.addedRecipients.map((recipientId) => recipientNameById.get(recipientId.toString().toUpperCase())).join(', ');

        if (!usersString) return null;

        const activityString = this._translateService.instant('STE_LABEL_INCIDENT_TRANSFER_ACTIVITY') as string;
        return stringFormat(activityString, instigatorName, usersString);
    }

    private generateAutoDispatchActivity(event: RecipientAlteredEvent, instigatorName: string, recipientNameById: Map<string, string>): string | null {
        let usersString = event.addedRecipients.map((recipientId) => recipientNameById.get(recipientId.toString().toUpperCase())).join(', ');

        if (!usersString) usersString = this._unavailableInformation;

        const activityString = this._translateService.instant('STE_MESSAGE_X_AUTO_ASSIGNED_TO_Y') as string;
        return stringFormat(activityString, instigatorName, usersString);
    }

    private generateProcedureStepAnsweredActivity(incident: MCIncident, procedureAnsweredEvent: ProcedureStepAnsweredEvent, instigatorName: string): Observable<string | null> {
        const comment = procedureAnsweredEvent.comment ? `\n ${this._translateService.instant('STE_LABEL_INCIDENT_COMMENT') as string}: ${procedureAnsweredEvent.comment}` : '';

        let translateString: string;
        let displayText: string | null = null;
        const step = incident.procedure?.steps.find((s) => s.id.equals(procedureAnsweredEvent.stepId));
        const option = step?.options.find((o) => procedureAnsweredEvent?.optionId?.equals(o.id));
        switch (procedureAnsweredEvent.actionType) {
            case ProcedureStepActionType.Complete:
                translateString = this._translateService.instant('STE_INCIDENT_PROCEDURE_ANSWERED_ACTIVITY') as string;
                displayText = stringFormat(translateString, instigatorName, step!.name ?? this._unavailableInformation, option?.name ?? this._unavailableInformation) + comment;
                break;
            case ProcedureStepActionType.Previous:
                translateString = this._translateService.instant('STE_INCIDENT_PROCEDURE_PREVIOUS_ACTIVITY') as string;
                displayText = stringFormat(translateString, instigatorName);
                break;
            case ProcedureStepActionType.Reset:
                translateString = this._translateService.instant('STE_INCIDENT_PROCEDURE_RESET_ACTIVITY') as string;
                displayText = stringFormat(translateString, instigatorName);
                break;
            case ProcedureStepActionType.Skip:
                translateString = this._translateService.instant('STE_INCIDENT_PROCEDURE_SKIP_ACTIVITY') as string;
                displayText = stringFormat(translateString, instigatorName, step!.name ?? this._unavailableInformation);
                break;
            case ProcedureStepActionType.Resume:
                translateString = this._translateService.instant('STE_INCIDENT_PROCEDURE_RESUME_ACTIVITY') as string;
                displayText = stringFormat(translateString, instigatorName);
                break;
        }

        return of(displayText);
    }

    private generateRecipientAlteredActivity(recipientEvent: RecipientAlteredEvent, instigatorName: string, recipientNameById: Map<string,string>): Observable<string | null> {
        let activity: string | null;
        switch (recipientEvent.alterationType) {
            case AlterationType.Dispatch:
                activity = this.generateDispatchActivity(recipientEvent, recipientNameById);
                break;
            case AlterationType.Forward:
                // TODO: forwarding might not work
                activity = this.generateForwardActivity(recipientEvent, instigatorName, recipientNameById);
                break;
            case AlterationType.Transfer:
                activity = this.generateTransferActivity(recipientEvent, instigatorName, recipientNameById);
                break;
            case AlterationType.AutoDispatch:
                activity = this.generateAutoDispatchActivity(recipientEvent, instigatorName, recipientNameById);
                break;
            default:
                activity = null;
                break;
        }
        return of(activity);
    }

    private generateCreatedActivity(incidentCreatedEvent: IncidentCreatedEvent, instigatorName: string): Observable<string> {
        return of(stringFormat(this._translateService.instant('STE_LABEL_INCIDENT_CREATED_ACTIVITY') as string, instigatorName));
    }

    private generatePriorityChangedActivity(priorityEvent: PriorityChangedEvent, instigatorName: string): Observable<string> {
        const translateString = this._translateService.instant('STE_LABEL_INCIDENT_PRIORITY_CHANGED_ACTIVITY') as string;
        return this._priorityService
            .getPriority(priorityEvent.priorityId)
            .pipe(map((newPriority) => stringFormat(translateString, instigatorName, newPriority?.name ?? this._unavailableInformation)));
    }

    private generateLinkedActivity(linkedEvent: LinkedEvent, instigatorName: string): Observable<string> {
        const translateString = this._translateService.instant('STE_LABEL_INCIDENT_LINKED_ACTIVITY') as string;
        return from(linkedEvent.linkedIncidentIds).pipe(
            mergeMap((linkedId) => this._incidentService.getIncident(linkedId)),
            filter((i): i is MCIncident => i instanceof MCIncident),
            map((incident) => incident.displayId ?? this._unavailableInformation),
            toArray(),
            map((linkedIds) => stringFormat(translateString, instigatorName, linkedIds.toString()))
        );
    }

    private generateUnlinkedActivity(unlinkedEvent: UnlinkedEvent, instigatorName: string): Observable<string> {
        const translateString = this._translateService.instant('STE_LABEL_INCIDENT_UNLINKED_ACTIVITY') as string;
        return from(unlinkedEvent.unlinkedIncidentIds).pipe(
            mergeMap((unlinkedId) => this._incidentService.getIncident(unlinkedId)),
            filter((i): i is MCIncident => i instanceof MCIncident),
            map((incident) => incident.displayId ?? this._unavailableInformation),
            toArray(),
            map((linkedIds) => stringFormat(translateString, instigatorName, linkedIds.toString()))
        );
    }

    private generateCommentAddedActivity(commentAddedEvent: CommentAddedEvent, instigatorName: string): Observable<string> {
        return of(stringFormat(this._translateService.instant('STE_LABEL_COMMENT_POSTED_ACTIVITY') as string, instigatorName, commentAddedEvent.comment));
    }

    private generateCommentChangedActivity(commentChangedEvent: CommentChangedEvent, instigatorName: string, commentHistory: Record<string, string[]>): Observable<string> {
        const translatedString = this._translateService.instant('STE_LABEL_COMMENT_CHANGED_ACTIVITY') as string;
        const arrayLength = commentHistory[commentChangedEvent.commentId!.toString()].length;
        const previousComment = commentHistory[commentChangedEvent.commentId!.toString()][arrayLength - 2];
        return of(stringFormat(translatedString, instigatorName, previousComment, commentChangedEvent.comment));
    }

    private generateCommentDeletedActivity(commentDeletedEvent: CommentDeletedEvent, instigatorName: string, commentHistory: Record<string, string[]>): Observable<string> {
        const arrayLength = commentHistory[commentDeletedEvent.commentId!.toString()].length;
        const previousComment = commentHistory[commentDeletedEvent.commentId!.toString()][arrayLength - 1];
        return of(stringFormat(this._translateService.instant('STE_LABEL_COMMENT_DELETED_ACTIVITY') as string, instigatorName, previousComment));
    }

    private generateLocationChangedActivity(locationChangeEvent: LocationChangedEvent, instigatorName: string): Observable<string> {
        const translatedString = this._translateService.instant('STE_LABEL_INCIDENT_LOCATION_ACTIVITY') as string;
        return this._locationService.getLocation(locationChangeEvent.location.entityId!).pipe(
            map((location) => {
                return location ? stringFormat(translatedString, instigatorName, location.name) : stringFormat(translatedString, instigatorName, this._unavailableInformation);
            })
        );
    }

    private generateDescriptionChangedActivity(descriptionChangedEvent: DescriptionChangedEvent, instigatorName: string): Observable<string | null> {
        const translatedString = this._translateService.instant('STE_LABEL_INCIDENT_DESCRIPTION_CHANGE_ACTIVITY') as string;
        const description = descriptionChangedEvent.description;
        return of(description ? stringFormat(translatedString, instigatorName, description) : null);
    }

    private generateExternalIdChangedActivity(externalIdEvent: ExternalIdChangedEvent, instigatorName: string): Observable<string> {
        const translateString = this._translateService.instant('STE_LABEL_INCIDENT_EXTERNAL_ID_CHANGED_ACTIVITY') as string;
        return of(
            externalIdEvent.externalId
                ? stringFormat(translateString, instigatorName, externalIdEvent.externalId.toString())
                : (this._translateService.instant('STE_MESSAGE_EXTERNAL_ID_REMOVED') as string)
        );
    }

    private generateIncidentTypeChangedActivity(incidentTypeChangeEvent: TypeChangedEvent, instigatorName: string): Observable<string> {
        const translateString = this._translateService.instant('STE_LABEL_INCIDENT_TYPE_CHANGE_ACTIVITY') as string;
        return this._incidentService
            .getIncident(incidentTypeChangeEvent.incidentId)
            .pipe(map((incident) => stringFormat(translateString, instigatorName, incident?.name ? incident?.name : this._unavailableInformation)));
    }

    private generateOwnershipChangedActivity(
        ownershipEvent: OwnershipChangedEvent,
        instigatorId: IGuid,
        instigatorName: string,
        ownershipHistory: Record<string, IGuid | null>
    ): Observable<string> {
        const previousOwner = ownershipHistory[ownershipEvent.id.toString().toUpperCase()];
        const currentOwner = ownershipEvent.owner;
        let translateString: string;
        const instigatorIsNewOwner = currentOwner?.equals(instigatorId) ?? false;
        const instigatorWasOwner = previousOwner?.equals(instigatorId) ?? false;
        if (instigatorIsNewOwner && previousOwner) {
            translateString = this._translateService.instant('STE_MESSAGE_X_TOOK_OWNERSHIP_FROM_USER') as string;
            return of(stringFormat(translateString, instigatorName));
        } else if (!instigatorIsNewOwner && !instigatorWasOwner) {
            translateString = this._translateService.instant('STE_MESSAGE_X_RELEASED_OWNERSHIP_FROM_USER') as string;
            return of(stringFormat(translateString, instigatorName));
        } else if (instigatorIsNewOwner && !previousOwner) {
            translateString = this._translateService.instant('STE_MESSAGE_X_TOOK_OWNERSHIP') as string;
            return of(stringFormat(translateString, instigatorName));
        } else if (instigatorWasOwner && !currentOwner) {
            translateString = this._translateService.instant('STE_MESSAGE_X_RELEASED_OWNERSHIP') as string;
            return of(stringFormat(translateString, instigatorName));
        }
        throw new Error(`Unable to generate ownership change activity`);
    }

    private generateLocationClearedActivity(locationClearedEvent: LocationClearedEvent, instigatorName: string) {
        return of(stringFormat(this._translateService.instant('STE_LABEL_LOCATION_CLEARED_ACTIVITY') as string, instigatorName));
    }

    private generateNoteAddedActivity(noteAddedEvent: NoteAddedEvent, instigatorName: string): Observable<string> {
        const translateString = this._translateService.instant('STE_LABEL_INCIDENT_NOTE_ADDED_ACTIVITY') as string;
        return of(stringFormat(translateString, instigatorName, noteAddedEvent.displayText));
    }

    private generateDisabledStatesChangedActivity(
        disabledStatesEvent: DisabledStatesChangedEvent,
        instigatorName: string,
        disabledStateHistory: Record<string, Array<IGuid>>
    ): Observable<string> {
        const pastDisableStates = disabledStateHistory[disabledStatesEvent.id.toString().toUpperCase()];
        const enabledStates = pastDisableStates.filter((s) => !disabledStatesEvent.disabledStates.includes(s));
        const disabledStates = disabledStatesEvent.disabledStates.filter((s) => !pastDisableStates.includes(s));
        const isEnablingEvent = enabledStates.length > 0;
        const message = isEnablingEvent
            ? (this._translateService.instant('STE_MESSAGE_ENABLED_STATES_ACTIVITY') as string)
            : (this._translateService.instant('STE_MESSAGE_DISABLED_STATES_ACTIVITY') as string);
        const states = this._stateService.getStates(isEnablingEvent ? enabledStates : disabledStates);
        const messageStates = states.map((s) => s?.name ?? this._unavailableInformation).join(', ');
        return of(stringFormat(message, instigatorName, messageStates));
    }

    private isAccessPointEvent = (event: ExternalEventAggregatedEvent): boolean =>
        event.publicTypeName === EventTypes.AccessPointFirst || event.publicTypeName === EventTypes.AccessPointLast;

    private getRelatedAlarmGuid = (event: ExternalEventAggregatedEvent): IGuid | null => {
        try {
            const payload: { AlarmId: string } = JSON.parse(event.payload) as { AlarmId: string };
            return toGuid(payload.AlarmId);
        } catch {
            return null;
        }
    };

    private getRelatedAlarmInstanceId = (event: ExternalEventAggregatedEvent): number | null => {
        try {
            const payload: { AlarmInstanceId: number } = JSON.parse(event.payload) as { AlarmInstanceId: number };
            return payload.AlarmInstanceId;
        } catch {
            return null;
        }
    };

    private generateExternalEventAggregatedActivity(eeaEvent: ExternalEventAggregatedEvent): Observable<string> {
        const relatedAlarmGuid = this.getRelatedAlarmGuid(eeaEvent);
        const relatedAlarmInstanceId = this.getRelatedAlarmInstanceId(eeaEvent)?.toString() ?? this._unavailableInformation;
        const locationGuid = toGuid(eeaEvent.locationEntity);
        const triggeringEntityGuid = toGuid(eeaEvent.triggeringEntity);

        const ids = SafeGuid.createSet([toGuid(eeaEvent.sourceEntity), locationGuid, relatedAlarmGuid ?? SafeGuid.EMPTY, triggeringEntityGuid]);

        return from(this._entityCache.getEntitiesAsync<Entity, IEntity>(Entity, ids, false)).pipe(
            map((entities) => new Map(entities.map((entity) => [entity.id.toString(), entity.name]))),
            map((entities) => this.translateExternalEvent(relatedAlarmGuid, relatedAlarmInstanceId, triggeringEntityGuid, locationGuid, entities, eeaEvent))
        );
    }

    private translateExternalEvent(
        relatedAlarmGuid: IGuid | null,
        relatedAlarmInstanceId: string,
        triggeringEntityGuid: IGuid,
        locationGuid: IGuid,
        entities: Map<string, string>,
        eeaEvent: ExternalEventAggregatedEvent
    ): string {
        const stringKey = EventTypesHelper.getStringKey(eeaEvent.publicTypeName) ?? 'STE_LABEL_UNAVAILABLE_INFORMATION';
        let eventName = this._translateService.instant(stringKey) as string;

        if (eventName === stringKey) {
            // Fallback when module's specific event names are missing from translation
            const translatedString = this._translateService.instant('STE_LABEL_EXTERNAL_EVENT_AGGREGATED') as string;
            eventName = stringFormat(translatedString, eeaEvent.publicTypeName);
        }


        const locationEntityName = entities.get(eeaEvent.locationEntity.toString()) ?? this._unavailableInformation;
        let activityEvent: string;
        if (!relatedAlarmGuid) {
            const sourceEntityName = entities.get(eeaEvent.sourceEntity.toString()) ?? this._unavailableInformation;
            const translatedString = this._translateService.instant('STE_MESSAGE_INFO_EVENT_ON_LOCATION') as string;
            activityEvent = stringFormat(translatedString, eventName, this.isAccessPointEvent(eeaEvent) ? locationEntityName : sourceEntityName);
        } else {
            const alarmEntityName = relatedAlarmGuid ? entities.get(relatedAlarmGuid.toString()) ?? this._unavailableInformation : this._unavailableInformation;
            if (!triggeringEntityGuid.isEmpty() && !relatedAlarmGuid.equals(triggeringEntityGuid)) {
                const translatedString = this._translateService.instant('STE_MESSAGE_INFO_EVENT_FROM_ALARM_TRIGGERED_BY') as string;
                const triggeringEntityName = relatedAlarmGuid ? entities.get(triggeringEntityGuid.toString()) ?? this._unavailableInformation : this._unavailableInformation;
                activityEvent = stringFormat(translatedString, eventName, alarmEntityName, relatedAlarmInstanceId, triggeringEntityName);
            } else if (relatedAlarmGuid.equals(locationGuid)) {
                const translatedString = this._translateService.instant('STE_MESSAGE_INFO_EVENT_FROM_ALARM') as string;
                activityEvent = stringFormat(translatedString, eventName, alarmEntityName, relatedAlarmInstanceId);
            } else {
                const translatedString = this._translateService.instant('STE_MESSAGE_INFO_EVENT_FROM_ALARM_TRIGGERED_BY') as string;
                activityEvent = stringFormat(translatedString, eventName, alarmEntityName, relatedAlarmInstanceId, locationEntityName);
            }
        }

        return activityEvent;

    }
}
