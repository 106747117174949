/* eslint-disable @typescript-eslint/no-namespace */
import { IGuid, SafeGuid } from 'safeguid';

export namespace StateGuids {
    export const CLOSED = SafeGuid.parse('0CF34BCB-6CA1-4E1B-8DB9-C3F8A51BF3B2');
    export const NEW = SafeGuid.parse('775A9416-BE54-4628-806E-80DAF4DBAE65');
    export const PARKED = SafeGuid.parse('F397A502-3707-4454-AC50-A86155E7E89F');
    export const SNOOZED = SafeGuid.parse('025EA3B8-801F-44A6-81C8-720B65D4447E');
    export const IN_PROGRESS = SafeGuid.parse('FD980618-2310-4011-B10E-4C8C73E39750');
    export const SOLVED = SafeGuid.parse('891BB5A8-AC7C-4F5F-8481-639D2B2E5FB8');
}
