/* eslint-disable no-underscore-dangle */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, TextFlavor } from '@genetec/gelato';
import { GenToastService } from '@genetec/gelato-angular';
import { IEntityBrowserFilter } from '@modules/shared/entity-browser/interfaces/filters/entity-browser-filter.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { coerceBooleanProperty } from '@modules/shared/utilities/coerceBooleanProperty';
import { DragDropHelper } from '@modules/tiles/utilities/dragdrophelper';
import { TranslateService } from '@ngx-translate/core';
import { Debouncer } from 'RestClient/Helpers/Debouncer';
import { GuidSet, IGuid, SafeGuid } from 'safeguid';
import { EntityBrowserItemService } from '../../../entity-browser/entity-browser-item.service';
import { EntityBrowserCheckSelection, EntityBrowserSelection } from '../../../entity-browser/entity-browser-selection';
import { EntityBrowserService } from '../../../entity-browser/entity-browser.service';
import { EntityBrowserFilter } from '../../../entity-browser/filters/entity-browser-filter';
import { EntityBrowserResult } from '../../../entity-browser/interfaces/entity-browser-result';
import { EntityBrowserItemModel } from '../../../entity-browser/Items/entity-browser-item-model';
import { DragDropTypes } from '../../../enumerations/drag-drop-types';
import { ContextTypes } from '../../../interfaces/plugins/public/context-types';
import { COMMANDS_SERVICE } from '../../../interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '../../../services/commands/commands.service';
import { TrackingService } from '../../../services/tracking.service';
import { TrackedComponent } from '../../tracked/tracked.component';
import { BrowserItem } from '../items/browser-item';
import { EntityBrowserItem } from '../items/entity-browser-item';
import { ShowMoreBrowserItem } from '../items/show-more-browser-item';
import { EntityBrowserItemsSource } from './entity-browser-items-source';

@UntilDestroy()
@Component({
    selector: 'app-entity-browser',
    templateUrl: './entity-browser.component.html',
    styleUrls: ['./tree.scss', './entity-browser.component.scss'],
})
export class EntityBrowserComponent extends TrackedComponent implements OnInit, OnChanges, OnDestroy {
    static ngAcceptInputType_dragAndDropEnabled: boolean | '';

    @Input() public set allowMultiSelect(value: boolean) {
        this.itemsSource.allowMultiSelect = value;
    }

    // To display checkboxes for multi-selection
    @Input() public areItemsCheckable = false;

    // The browser will automatically refresh when the filter changes
    @Input() public autoRefresh = true;

    // Display the footer or not (refresh / check / uncheckall / etc.)
    @Input() public showFooter = true;

    // Callback method used to determine if an item can be selected
    @Input() public set canSelectItemFunction(value: (model: EntityBrowserItemModel) => Promise<boolean>) {
        this.itemsSource.canSelectItemFunction = value;
        this.filteredItemsSource.canSelectItemFunction = value;
    }

    @Input() public componentId!: string;

    // Callback method used to create a browser items from model
    @Input() public set createItemFunction(value: (model: EntityBrowserItemModel, entityBrowserItemService: EntityBrowserItemService) => EntityBrowserItem) {
        this.itemsSource.createItemFunction = value;
        this.filteredItemsSource.createItemFunction = value;
    }

    // EntityBrowserFilter that determines how and what the browser will display
    @Input() public filter?: EntityBrowserFilter;

    // Make show checked items button available even when working with a hierarchical view
    @Input() public alwaysShowCheckedItemsActions = false;

    // Enables / disables the drag and drop functionality.
    public get dragAndDropEnabled(): boolean {
        return this.isDragAndDropEnabled;
    }
    @Input()
    public set dragAndDropEnabled(value: boolean) {
        this.isDragAndDropEnabled = coerceBooleanProperty(value);
    }
    // Called when an item is double clicked (or enter was pressed when item has focus)
    @Output() public entityDoubleClicked: EventEmitter<EntityBrowserSelection> = new EventEmitter<EntityBrowserSelection>();

    // Called when checked entities changed when mutli-selection checkboxes are enabled
    @Output() public checkedEntitiesChanged: EventEmitter<EntityBrowserCheckSelection> = new EventEmitter<EntityBrowserCheckSelection>();

    // Called the browser is done refreshing
    @Output() public refreshed: EventEmitter<any> = new EventEmitter<any>();

    // Called when selected entities changed
    @Output() public selectedEntitiesChanged: EventEmitter<EntityBrowserSelection> = new EventEmitter<EntityBrowserSelection>();

    @ViewChild('browserTree', { read: ElementRef }) public browserTree!: ElementRef<HTMLDivElement>;

    @ViewChild('entitySearchInput', { read: ElementRef }) public entitySearchInput!: ElementRef;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;

    // Gets the currently checked items when mutli-selection checkboxes are enabled
    public get checkedEntities(): EntityBrowserCheckSelection {
        const checkedItemModels: EntityBrowserItemModel[] = [];
        const exlcludedCheckedItemModels: EntityBrowserItemModel[] = [];

        const checkedEntityIds = new GuidSet(this.itemsSource.checkedEntityIds);
        const excludedCheckedEntityIds = new GuidSet(this.itemsSource.excludedCheckedEntityIds);

        for (const entityId of checkedEntityIds) {
            let item = this.itemsSource.items.get(entityId)?.values()?.next()?.value as EntityBrowserItem;
            if (!item && this.isFiltered) {
                item = this.filteredItemsSource.items.get(entityId)?.values()?.next()?.value as EntityBrowserItem;
            }
            if (item) {
                checkedItemModels.push(item.model);
            }
        }

        for (const entityId of excludedCheckedEntityIds) {
            let item = this.itemsSource.items.get(entityId)?.values()?.next()?.value as EntityBrowserItem;
            if (!item && this.isFiltered) {
                item = this.filteredItemsSource.items.get(entityId)?.values()?.next()?.value as EntityBrowserItem;
            }
            if (item) {
                exlcludedCheckedItemModels.push(item.model);
            }
        }
        return new EntityBrowserCheckSelection(checkedItemModels, exlcludedCheckedItemModels);
    }
    public get currentItemsSource(): EntityBrowserItemsSource {
        return this.isFiltered ? this.filteredItemsSource : this.itemsSource;
    }

    public get currentTreeElementId(): string {
        if (this.onlyShowCheckedItems) {
            return this.checkedTreeId;
        } else if (this.filteredTreeId) {
            return this.filteredTreeId;
        }
        return this.treeId;
    }

    public get id(): string {
        return this.uniqueId.toString();
    }

    // Gets the currently selected items
    public get selectedEntities(): EntityBrowserSelection {
        return new EntityBrowserSelection(this.selectedItems);
    }

    public readonly checkedTreeId = 'entity-browser-checked-tree-id';
    public readonly filteredTreeId = 'entity-browser-filtered-tree-id';
    public emptyText = '';
    public readonly treeId = 'entity-browser-tree-id';
    public isFiltered = false;
    public isHierarchical = false;
    public isRefreshing = false;
    public onlyShowCheckedItems = false;
    public searchText = '';
    public sortedCheckedItems: EntityBrowserItem[] = [];
    public itemsSource!: EntityBrowserItemsSource;
    public filteredItemsSource!: EntityBrowserItemsSource;

    private entityIdsToSelect: Set<IGuid> | null = null;
    private filterChangeRefreshDebouncer = new Debouncer(false, () => this.refreshAsync(), 100, -1);
    private isDragAndDropEnabled = false;
    private isSelectingScrollItem = false;
    private isUpdatingCheckedItems = false;
    private refreshSelectedItemIds: Map<string, IGuid> | null = null;
    private readonly searchDebouncer = new Debouncer(true, () => this.searchAsync(), 1000, -1);
    private readonly timeouts: ReturnType<typeof setTimeout>[] = [];
    private readonly uniqueId = SafeGuid.newGuid();

    private get selectedItems(): EntityBrowserItem[] {
        return this.currentItemsSource.selectedItems;
    }

    private get treeElement(): HTMLElement {
        return this.browserTree?.nativeElement;
    }

    constructor(
        private entityBrowserService: EntityBrowserService,
        private entityBrowserItemService: EntityBrowserItemService,
        private translateService: TranslateService,
        private toastService: GenToastService,
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        private changeDetectionRef: ChangeDetectorRef
    ) {
        super(trackingService);

        this.itemsSource = new EntityBrowserItemsSource(
            this.entityBrowserService,
            this.entityBrowserItemService,
            this.translateService,
            this.toastService,
            this.uniqueId,
            changeDetectionRef
        );
        this.filteredItemsSource = new EntityBrowserItemsSource(
            this.entityBrowserService,
            this.entityBrowserItemService,
            this.translateService,
            this.toastService,
            this.uniqueId,
            changeDetectionRef,
            true
        );
        this.itemsSource.checkedItemsChanged$.pipe(untilDestroyed(this)).subscribe(() => this.onCheckedItemsChanged());
        this.itemsSource.selectedItemsChanged$.pipe(untilDestroyed(this)).subscribe(() => this.onSelectedItemsChanged());
        this.filteredItemsSource.checkedItemsChanged$.pipe(untilDestroyed(this)).subscribe(() => this.onCheckedItemsChanged());
        this.filteredItemsSource.selectedItemsChanged$.pipe(untilDestroyed(this)).subscribe(() => this.onSelectedItemsChanged());
    }

    public canCheckItem(item: BrowserItem): boolean {
        if (!(item instanceof EntityBrowserItem) || !item.canSelect) {
            return false;
        }
        return !this.isFiltered || (item.isIncludedItem && item.text.toLowerCase().includes(this.searchText.toLowerCase()));
    }

    public async clearSearch(): Promise<void> {
        this.searchText = '';
        if (this.isFiltered) {
            await this.searchAsync();
        } else {
            this.searchDebouncer.suspend();
            this.searchDebouncer.resume();
        }
    }

    public getItemId(index: number, item: BrowserItem): string {
        return item.id;
    }

    public isItemSelected(item: BrowserItem): boolean {
        if (item instanceof EntityBrowserItem) {
            return this.selectedItems.includes(item);
        }
        return false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.filter !== 'undefined') {
            const change = changes.filter;
            if (change.previousValue !== change.currentValue) {
                if (this.autoRefresh) {
                    this.filterChangeRefreshDebouncer.trigger();
                }
            }
        }
    }

    public ngOnDestroy() {
        // Cancel timeouts
        this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
        });
        this.searchDebouncer.dispose();
        this.itemsSource.dispose();
        this.filteredItemsSource.dispose();
        this.filterChangeRefreshDebouncer.dispose();

        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.resetItems(true);
    }

    public onBrowserDragEnd(event: DragEvent): void {
        DragDropHelper.endDrag();
    }

    public onCheckAllItemsClicked(): void {
        this.currentItemsSource.checkAll();
    }

    public async onItemArrowDownPressed(item: BrowserItem, event?: KeyboardEvent): Promise<void> {
        if (event?.ctrlKey || event?.altKey) {
            return;
        }
        event?.preventDefault();
        event?.stopPropagation();

        const selecteditem = await this.currentItemsSource.selectNextItemAsync(item, event);
        if (selecteditem) {
            this.focusItem(selecteditem);
        }
    }

    public async onItemArrowLeftPressed(item: BrowserItem, event?: KeyboardEvent): Promise<void> {
        if (event?.ctrlKey || event?.altKey) {
            return;
        }
        event?.preventDefault();
        event?.stopPropagation();

        if (item.isExpanded) {
            this.currentItemsSource.setItemIsExpanded(item, false);
        } else if (item.parent && item.parent instanceof EntityBrowserItem) {
            await this.currentItemsSource.selectItemAsync(item.parent, event, true);
            this.focusItem(item.parent);
        }
    }

    public async onItemArrowRightPressed(item: EntityBrowserItem, event?: KeyboardEvent): Promise<void> {
        if (event?.ctrlKey || event?.altKey) {
            return;
        }
        event?.preventDefault();
        event?.stopPropagation();

        if (!item.canExpand) {
            return;
        }

        if (!item.isExpanded) {
            this.currentItemsSource.setItemIsExpanded(item, true);
        } else if (item.children && item.children.length > 0) {
            let itemToSelect: EntityBrowserItem | undefined;
            for (const child of item.children) {
                if (child instanceof EntityBrowserItem) {
                    itemToSelect = child;
                    break;
                }
            }

            if (itemToSelect) {
                await this.currentItemsSource.selectItemAsync(itemToSelect, event, true);
                this.focusItem(itemToSelect);
            }
        }
    }

    public async onItemArrowUpPressed(item: BrowserItem, event?: KeyboardEvent): Promise<void> {
        if (event?.ctrlKey || event?.altKey) {
            return;
        }
        event?.preventDefault();
        event?.stopPropagation();

        const selecteditem = await this.currentItemsSource.selectPreviousItemAsync(item, event);
        if (selecteditem) {
            this.focusItem(selecteditem);
        }
    }

    public onItemCheckboxToggled(item: BrowserItem, event?: MouseEvent | KeyboardEvent): void {
        if (this.canCheckItem(item) && this.areItemsCheckable && item instanceof EntityBrowserItem) {
            if (event) {
                event.preventDefault();
            }

            const isKeyboardEvent = event instanceof KeyboardEvent;
            let itemsToCheck = [item];
            if (this.selectedItems.includes(item) || isKeyboardEvent) {
                itemsToCheck = this.selectedItems;
            }
            if (isKeyboardEvent && this.selectedItems.length > 0) {
                // Reset focus to the last selected item so that checking selection with spacebar still works
                this.focusItem(this.selectedItems[this.selectedItems.length - 1]);
            }

            this.currentItemsSource.toggleItemsCheckbox(itemsToCheck, isKeyboardEvent ? item : undefined);
        }
    }

    public async onItemClicked(item: BrowserItem, event?: MouseEvent | KeyboardEvent): Promise<void> {
        if (event) {
            event.preventDefault();
            event.stopPropagation();

            if (event instanceof MouseEvent) {
                if (await this.currentItemsSource.selectItemAsync(item, event)) {
                    this.focusItem(item);
                    this.onItemCheckboxToggled(item, event);
                }
            }
        }
    }

    public async onItemContextMenu(item: BrowserItem, event: MouseEvent): Promise<void> {
        await this.currentItemsSource.selectItemAsync(item, event);

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (item instanceof EntityBrowserItem) {
            this.commandsService.showContextMenuAsync(event, { type: ContextTypes.EntityId, data: item.entityId, target: event.target as Element }).fireAndForget();
        }
    }

    public onItemDoubleClicked(item: BrowserItem, event: MouseEvent | KeyboardEvent): void {
        if (event) {
            event.preventDefault();
        }

        if (item instanceof EntityBrowserItem) {
            this.entityDoubleClicked.emit(new EntityBrowserSelection([item]));
        }
    }

    public onItemEnter(item: BrowserItem, event: MouseEvent | KeyboardEvent): void {
        if (event) {
            event.preventDefault();
        }

        if (this.selectedItems && this.selectedItems.length > 0) {
            this.entityDoubleClicked.emit(new EntityBrowserSelection(this.selectedItems));
        } else if (item instanceof EntityBrowserItem) {
            this.entityDoubleClicked.emit(new EntityBrowserSelection([item]));
        }
    }

    public onItemDragDrop(item: EntityBrowserItem, event: DragEvent): void {
        if (this.isDragAndDropEnabled && item && event?.dataTransfer) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const dragData = DragDropHelper.getDragData(DragDropTypes.EntityBrowserItems);
            if (dragData) {
                // TODO FM: process drop
            }
        }
    }

    public async onItemDragStart(item: EntityBrowserItem, event: DragEvent): Promise<void> {
        if (this.isDragAndDropEnabled && item) {
            if (this.selectedItems.includes(item) || (await this.currentItemsSource.selectItemAsync(item, undefined, true))) {
                // only drag if we have a selection
                if (this.selectedItems.length > 0) {
                    DragDropHelper.startDrag(DragDropTypes.EntityBrowserItems, this.selectedItems);
                    DragDropHelper.updateDragData(
                        DragDropTypes.EntityId,
                        this.selectedItems.map((x) => x.entityId)
                    );
                } else {
                    if (event) {
                        event.preventDefault();
                    }
                }
            }
        }
    }

    public onItemDragEnter(item: EntityBrowserItem, event: DragEvent): void {
        if (this.isDragAndDropEnabled && item && event?.dataTransfer) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const dragData = DragDropHelper.getDragData(DragDropTypes.EntityBrowserItems);
            if (dragData) {
                let dropEffect = '';
                if (event.ctrlKey) {
                    dropEffect = 'copy';
                } else {
                    dropEffect = 'move';
                }

                // TODO FM: process is drop supported
                event.dataTransfer.dropEffect = 'none';

                // when supported, stop the bubbling
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    public onItemExpanderToggled(item: BrowserItem, event: MouseEvent | KeyboardEvent): void {
        if (event) {
            //Checks if the mouse click was directly on the correct expander icon
            if (event instanceof MouseEvent) {
                const target = event.target as HTMLElement | null;
                if (target && event.offsetX > target.offsetLeft && target.offsetTop !== 0) {
                    return;
                }
            }

            event.preventDefault();

            this.currentItemsSource.setItemIsExpanded(item, !item.isExpanded);
        }
    }

    public onItemKeyDown(item: BrowserItem, event: KeyboardEvent): void {
        if (event && !event.altKey && !event.ctrlKey) {
            event.preventDefault();

            if (event.code === 'Enter') {
                this.onItemEnter(item, event);
                event.cancelBubble = true;
            } else if (event.code === 'Space') {
                this.onItemCheckboxToggled(item, event);
                event.cancelBubble = true;
            } else if (event.code === 'PageDown') {
                this.selectScrollItem(item, true, event);
                event.cancelBubble = true;
            } else if (event.code === 'PageUp') {
                this.selectScrollItem(item, false, event);
                event.cancelBubble = true;
            }
        }
    }

    public async updateOnlyShowCheckedItemsAsync(): Promise<void> {
        if (this.onlyShowCheckedItems) {
            if (this.isFiltered) {
                await this.clearSearch();
            }
            const items: EntityBrowserItem[] = [];
            for (const entityId of this.currentItemsSource.checkedEntityIds) {
                const item = this.currentItemsSource.items.get(entityId)?.values()?.next().value as unknown;
                if (item instanceof EntityBrowserItem) {
                    items.push(item);
                }
            }
            this.sortedCheckedItems = items.sort(this.compareItems.bind(this));
        }
    }

    public onSearchTextChanged(): void {
        this.searchDebouncer.trigger();
    }

    public async onShowMoreItemClicked(item: ShowMoreBrowserItem): Promise<void> {
        await this.currentItemsSource.showMoreItems(item);
    }

    public onUncheckAllItemsClicked(): void {
        if (this.onlyShowCheckedItems) {
            this.onlyShowCheckedItems = false;
        }

        this.currentItemsSource.uncheckAll();
    }

    // Refreshes the browser with the provided filter if speficied
    public async refreshAsync(filter?: EntityBrowserFilter, keepCurrentSelection = false): Promise<void> {
        this.focusOnSearch();

        if (filter) {
            this.filter = filter;
        }
        if (this.filter) {
            if (!keepCurrentSelection) {
                this.itemsSource.clearSelection();
                if (this.filter.checkedEntityIds) {
                    this.itemsSource.addCheckedEntityIds(this.filter.checkedEntityIds);
                    if (this.filter.excludedCheckedEntityIds) {
                        this.itemsSource.addExcludedCheckedEntityIds(this.filter.excludedCheckedEntityIds);
                    }
                }

                if (this.filter.selectedEntityIds) {
                    this.entityIdsToSelect = new GuidSet();
                    for (const selectedEntityId of this.filter.selectedEntityIds) {
                        this.entityIdsToSelect.add(selectedEntityId);
                    }
                }
            }
            await this.refreshInternalAsync(this.filter, true);
            this.changeDetectionRef.markForCheck();
            this.refreshed.emit();
        }
    }

    public async searchAsync(event?: Event): Promise<void> {
        if (event) {
            event.preventDefault();
        }

        if (!this.filter || (!this.searchText && !this.isFiltered)) {
            return;
        }

        try {
            this.searchDebouncer.suspend();

            if (this.searchText) {
                this.clearFilteredItems();
                this.onlyShowCheckedItems = false;
                this.isFiltered = true;
                const currentSearchId = SafeGuid.newGuid();
                this.currentItemsSource.lastSearchInstanceId = currentSearchId;
                this.filteredItemsSource.addCheckedEntityIds(this.itemsSource.checkedEntityIds);
                this.filteredItemsSource.addExcludedCheckedEntityIds(this.itemsSource.excludedCheckedEntityIds);
                await this.refreshInternalAsync(this.filter, false, false, currentSearchId);
            } else {
                const selectionParentItems = this.filteredItemsSource.getItemParentHierarchies(this.selectedItems);
                this.filter.textFilter = '';
                this.isFiltered = false;
                await this.applyFilteredSelectionAsync(selectionParentItems);
                this.clearFilteredItems();
            }
        } finally {
            this.searchDebouncer.resume();
        }
    }

    public hasSelectedSomething(): boolean {
        return this.areItemsCheckable ? this.checkedEntities.ids.length === 0 : this.selectedEntities.allIds.length === 0;
    }

    private async applyFilteredSelectionAsync(selectionParentItems?: Set<EntityBrowserItem>): Promise<void> {
        if (!this.filter) {
            return;
        }

        const missingEntityIds = new GuidSet();
        let foundSelectedItems: EntityBrowserItem[] = [];
        let areSelectedItemsComplete = true;
        const itemsToSelect = Array.from(this.filteredItemsSource.selectedItems);
        for (const selectedItem of itemsToSelect) {
            let selectedItemFound = false;
            const items = this.itemsSource.items.get(selectedItem.entityId);
            if (items) {
                for (const item of items) {
                    if (item.id === selectedItem.id) {
                        selectedItemFound = true;
                        foundSelectedItems.push(item);
                        break;
                    }
                }
            }

            if (!selectedItemFound) {
                missingEntityIds.add(selectedItem.entityId);
                areSelectedItemsComplete = false;
            }
        }

        for (const checkedEntityId of this.filteredItemsSource.checkedEntityIds) {
            const checkedItems = this.itemsSource.items.get(checkedEntityId);
            if (!checkedItems) {
                missingEntityIds.add(checkedEntityId);
            }
        }

        for (const excludedCheckedEntityId of this.filteredItemsSource.excludedCheckedEntityIds) {
            const excludedCheckedItems = this.itemsSource.items.get(excludedCheckedEntityId);
            if (!excludedCheckedItems) {
                missingEntityIds.add(excludedCheckedEntityId);
            }
        }

        let isRefreshRequired = missingEntityIds.size > 0;
        if (!isRefreshRequired && selectionParentItems) {
            for (const selectionParentItem of selectionParentItems) {
                const items = this.itemsSource.items.get(selectionParentItem.entityId);
                if (items) {
                    let existingParent = false;
                    for (const item of items) {
                        if (item.id === selectionParentItem.id) {
                            existingParent = selectionParentItem.children !== null;
                            break;
                        }
                    }

                    if (!existingParent) {
                        isRefreshRequired = true;
                        break;
                    }
                } else {
                    isRefreshRequired = true;
                    break;
                }
            }
        }

        if (isRefreshRequired) {
            const clonedFilter = this.entityBrowserService.cloneFilter(this.filter);
            for (const missingEntityId of missingEntityIds) {
                clonedFilter.includedEntities.add(missingEntityId);
            }

            await this.refreshInternalAsync(clonedFilter, true, foundSelectedItems.length > 0);
        } else {
            if (selectionParentItems) {
                for (const selectionParentItem of selectionParentItems) {
                    const parentItems = this.itemsSource.items.get(selectionParentItem.entityId);
                    if (parentItems) {
                        for (const parentItem of parentItems) {
                            if (parentItem.id === selectionParentItem.id) {
                                this.itemsSource.setItemIsExpanded(parentItem, true);
                                break;
                            }
                        }
                    }
                }
            }
        }

        const excludedCheckedEntityIds = Array.from(this.itemsSource.excludedCheckedEntityIds);

        for (const checkedEntityId of this.itemsSource.checkedEntityIds) {
            this.itemsSource.updateEntityIsChecked(checkedEntityId, true, true);
        }

        for (const excludedCheckedEntityId of excludedCheckedEntityIds) {
            this.itemsSource.updateEntityIsChecked(excludedCheckedEntityId, false, true);
        }

        if (!areSelectedItemsComplete) {
            foundSelectedItems = [];
            for (const selectedItem of itemsToSelect) {
                const items = this.itemsSource.items.get(selectedItem.entityId);
                if (items) {
                    for (const item of items) {
                        if (item.id === selectedItem.id) {
                            foundSelectedItems.push(item);
                            break;
                        }
                    }
                }
            }
        }

        if (foundSelectedItems.length > 0) {
            await this.itemsSource.setSelectedItems(foundSelectedItems, true);
        }
    }

    private compareItems(item1: EntityBrowserItem, item2: EntityBrowserItem): number {
        return this.entityBrowserService.compareItems(item1, item2, this.isHierarchical);
    }

    private clearFilteredItems() {
        this.filteredItemsSource.clear(true);
    }

    private focusItem(item: BrowserItem) {
        const child = item.htmlElement;
        if (child) {
            child.focus();
            if (this.treeElement) {
                const left = this.getScrollLeft(child);
                const resetLeftScroll = left === this.treeElement.scrollLeft;
                let scrollOperation: (() => void) | undefined;
                if (child.getBoundingClientRect().bottom < this.treeElement.getBoundingClientRect().top) {
                    scrollOperation = () => {
                        child.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'start' });
                        if (resetLeftScroll && this.treeElement.scrollLeft !== left) {
                            this.treeElement.scroll({ top: this.treeElement.scrollTop, left });
                        }
                    };
                } else if (child.getBoundingClientRect().top > this.treeElement.getBoundingClientRect().bottom) {
                    scrollOperation = () => {
                        child.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
                        if (resetLeftScroll && this.treeElement.scrollLeft !== left) {
                            this.treeElement.scroll({ top: this.treeElement.scrollTop, left });
                        }
                    };
                } else if (!resetLeftScroll) {
                    scrollOperation = () => {
                        this.treeElement.scroll({ top: this.treeElement.scrollTop, left });
                    };
                }

                if (scrollOperation) {
                    this.scrollTree(scrollOperation);
                }
            }
        }
    }

    private focusOnSearch() {
        // until genAutoFocus works fine...
        const element = this.entitySearchInput?.nativeElement as HTMLElement | undefined | null;
        if (element) {
            element.focus();

            // the first try might not work, in that case, try again will a slightly longer delay
            if (document.activeElement !== element) {
                const timeout = setTimeout(() => {
                    element.focus();
                    this.timeouts.remove(timeout);
                }, 500);
                this.timeouts.push(timeout);
            }
        }
    }

    private getScrollLeft(element: Element): number {
        const treeLeft = this.treeElement.getBoundingClientRect().left;
        const treeRight = this.treeElement.getBoundingClientRect().right;
        const elementLeft = element.getBoundingClientRect().left - (this.isHierarchical ? 24 : 0);
        let left = this.treeElement.scrollLeft;
        if (elementLeft < treeLeft) {
            left = this.treeElement.scrollLeft - (treeLeft - elementLeft);
        } else if (elementLeft > treeRight) {
            left = this.treeElement.scrollLeft + (elementLeft - treeRight);
        }
        return left;
    }

    private getScrollTop(isScrollingDown: boolean): number {
        const maxBottom = this.treeElement.getBoundingClientRect().bottom;
        const minTop = this.treeElement.getBoundingClientRect().top;

        const height = maxBottom - minTop;
        const treeTop = this.treeElement.scrollTop;
        return isScrollingDown ? treeTop + height : treeTop - height;
    }

    private includeSelectionInFilter(filter: IEntityBrowserFilter) {
        for (const checkedEntityId of this.itemsSource.checkedEntityIds) {
            filter.includedEntities.add(checkedEntityId);
        }

        for (const excludedCheckedEntityId of this.itemsSource.excludedCheckedEntityIds) {
            filter.includedEntities.add(excludedCheckedEntityId);
        }

        let selectedEntityIds: Set<IGuid> | undefined;
        if (this.entityIdsToSelect) {
            selectedEntityIds = new GuidSet(this.entityIdsToSelect);
        } else {
            this.refreshSelectedItemIds = new Map<string, IGuid>();
            selectedEntityIds = new GuidSet();
            for (const selectedItem of this.selectedItems) {
                const entityId = selectedItem.entityId;
                this.refreshSelectedItemIds.set(selectedItem.id, entityId);
                selectedEntityIds.add(entityId);
            }
        }

        if (selectedEntityIds) {
            for (const selectedEntityId of selectedEntityIds) {
                filter.includedEntities.add(selectedEntityId);
            }
        }
    }

    private onCheckedItemsChanged(): void {
        if (this.isUpdatingCheckedItems) {
            return;
        }

        this.isUpdatingCheckedItems = true;
        try {
            if (this.currentItemsSource.checkedEntityIds.size < 1 && this.onlyShowCheckedItems) {
                this.onlyShowCheckedItems = false;
            }

            if (this.isFiltered) {
                for (const checkedEntityId of this.itemsSource.checkedEntityIds) {
                    if (!this.filteredItemsSource.checkedEntityIds.has(checkedEntityId)) {
                        if (this.itemsSource.items.has(checkedEntityId)) {
                            this.itemsSource.updateEntityIsChecked(checkedEntityId, false, true);
                        } else {
                            this.itemsSource.addExcludedCheckedEntityIds([checkedEntityId]);
                        }
                    }
                }

                for (const filteredCheckedEntityId of this.filteredItemsSource.checkedEntityIds) {
                    if (!this.itemsSource.checkedEntityIds.has(filteredCheckedEntityId)) {
                        if (this.itemsSource.items.has(filteredCheckedEntityId)) {
                            this.itemsSource.updateEntityIsChecked(filteredCheckedEntityId, true, true);
                        } else {
                            this.itemsSource.addCheckedEntityIds([filteredCheckedEntityId]);
                        }
                    }
                }

                for (const excludedCheckedEntityId of this.itemsSource.excludedCheckedEntityIds) {
                    if (!this.filteredItemsSource.excludedCheckedEntityIds.has(excludedCheckedEntityId)) {
                        if (this.itemsSource.items.has(excludedCheckedEntityId)) {
                            this.itemsSource.updateEntityIsChecked(excludedCheckedEntityId, true, true);
                        } else {
                            this.itemsSource.addCheckedEntityIds([excludedCheckedEntityId]);
                        }
                    }
                }

                for (const filteredExcludedCheckedEntityId of this.filteredItemsSource.excludedCheckedEntityIds) {
                    if (!this.itemsSource.excludedCheckedEntityIds.has(filteredExcludedCheckedEntityId)) {
                        if (this.itemsSource.items.has(filteredExcludedCheckedEntityId)) {
                            this.itemsSource.updateEntityIsChecked(filteredExcludedCheckedEntityId, false, true);
                        } else {
                            this.itemsSource.addExcludedCheckedEntityIds([filteredExcludedCheckedEntityId]);
                        }
                    }
                }

                if (this.isHierarchical) {
                    this.filteredItemsSource.uncheckAll();
                    for (const checkedEntityId of this.itemsSource.checkedEntityIds) {
                        this.filteredItemsSource.updateEntityIsChecked(checkedEntityId, true, true);
                    }
                    for (const checkedEntityId of this.itemsSource.excludedCheckedEntityIds) {
                        this.filteredItemsSource.updateEntityIsChecked(checkedEntityId, false, true);
                    }
                }
            }

            this.checkedEntitiesChanged.emit(this.checkedEntities);
        } finally {
            this.isUpdatingCheckedItems = false;
        }
    }

    private onSelectedItemsChanged(): void {
        this.isSelectingScrollItem = false;
        this.selectedEntitiesChanged.emit(this.selectedEntities);
    }

    private async refreshInternalAsync(
        filter: IEntityBrowserFilter,
        isFullRefresh = false,
        ignoreCurrentSelection = false,
        searchInstanceId: IGuid = SafeGuid.EMPTY
    ): Promise<EntityBrowserResult | null> {
        const clonedFilter = this.entityBrowserService.cloneFilter(filter);
        if (this.searchText) {
            clonedFilter.textFilter = this.searchText;
        }

        if (isFullRefresh) {
            if (!ignoreCurrentSelection) {
                this.includeSelectionInFilter(clonedFilter);
            }

            this.resetItems();
        }
        this.isRefreshing = true;

        const result = await this.currentItemsSource.refreshAsync(clonedFilter, isFullRefresh, searchInstanceId);
        // Is a new search more recent in progress?
        if (!result) {
            return null;
        }

        if (result.isValid) {
            this.isHierarchical = this.currentItemsSource.isHierarchical;
            if (this.currentItemsSource.rootItems.length === 0) {
                this.emptyText = this.translateService.instant('STE_LABEL_NORESULTS') as string;
            } else {
                const itemsToSelect: EntityBrowserItem[] = [];
                if (this.refreshSelectedItemIds) {
                    for (const pair of this.refreshSelectedItemIds.entries()) {
                        const items = this.currentItemsSource.items.get(pair[1]);
                        if (items) {
                            for (const item of items) {
                                if (item.id.toLowerCase() === pair[0].toLowerCase()) {
                                    itemsToSelect.push(item);
                                    break;
                                }
                            }
                        }
                    }
                } else if (this.entityIdsToSelect) {
                    for (const entityIdToSelect of this.entityIdsToSelect) {
                        const items = this.currentItemsSource.items.get(entityIdToSelect);
                        if (items) {
                            itemsToSelect.push(items.values()?.next()?.value as EntityBrowserItem);
                        }
                    }
                }

                if (itemsToSelect.length > 0) {
                    await this.currentItemsSource.setSelectedItems(itemsToSelect, true);
                }
            }
        } else {
            this.emptyText = this.translateService.instant('STE_LABEL_ERROR') as string;
        }

        this.refreshSelectedItemIds = null;
        this.entityIdsToSelect = null;

        if (!this.isFiltered) {
            this.clearFilteredItems();
        }

        this.isRefreshing = false;
        this.changeDetectionRef.markForCheck();
        return result;
    }

    private resetItems(clearSelection = false): void {
        this.currentItemsSource.clear(clearSelection);

        if (clearSelection) {
            this.refreshSelectedItemIds?.clear();
            this.entityIdsToSelect = null;
            this.refreshSelectedItemIds = null;
        }
    }

    private scrollTree(scrollOperation: () => void): void {
        // Scroll left and top
        this.treeElement.style.scrollBehavior = 'auto';
        scrollOperation();
        this.treeElement.style.scrollBehavior = '';
    }

    private selectScrollItem(item: BrowserItem, isDown: boolean, event: KeyboardEvent): void {
        if (this.treeElement) {
            const maxBottom = this.treeElement.getBoundingClientRect().bottom;
            const minTop = this.treeElement.getBoundingClientRect().top;

            if (this.isSelectingScrollItem) {
                this.scrollTree(() => {
                    this.treeElement.scroll({ top: this.getScrollTop(isDown), left: this.treeElement.scrollLeft });
                });
            }

            const timeout = setTimeout(async () => {
                let selectedItem: BrowserItem | undefined;
                // Do this in a timeout to make sure the scroll operation is over before we select the next item
                if (isDown) {
                    selectedItem = await this.currentItemsSource.selectScrollDownItemAsync(item, event, minTop, maxBottom);
                } else {
                    selectedItem = await this.currentItemsSource.selectScrollTopItemAsync(item, event, minTop, maxBottom);
                }

                if (selectedItem) {
                    const left = this.getScrollLeft(selectedItem.htmlElement);
                    if (left !== this.treeElement.scrollLeft) {
                        this.scrollTree(() => {
                            this.treeElement.scroll({ top: this.treeElement.scrollTop, left });
                        });
                    }
                }

                this.isSelectingScrollItem = true;
                this.timeouts.remove(timeout);
            }, 50);
            this.timeouts.push(timeout);
        }
    }
}
