// Autogenerated: v2/help/enumerations/ApiExpirationMode?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class ExpirationMode {
    public static DontExpire = 'DontExpire';
    public static ExpiredAfterActivation = 'ExpiredAfterActivation';
    public static Expiring = 'Expiring';
    public static ActivateOn = 'ActivateOn';
    public static WhenNotUsed = 'WhenNotUsed';

    public static readonly values = [
        ExpirationMode.DontExpire,
        ExpirationMode.ExpiredAfterActivation,
        ExpirationMode.Expiring,
        ExpirationMode.ActivateOn,
        ExpirationMode.WhenNotUsed
    ];
}
