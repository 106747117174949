<ng-container *ngIf="formGroup" [formGroup]="formGroup">
    <div class="cardholder-identity-form">
        <!-- Picture -->
        <app-cardholder-editable-picture formControlName="picture" [readonly]="readonly"></app-cardholder-editable-picture>
        <!-- Details (FirstName, LastName, EmailAddress, MobilePhoneNumber) -->
        <div class="cardholder-identity-form__details">
            <div>
                <gen-text-input formControlName="firstName" data-cy="firstName" [maxlength]="100" [readonly]="readonly">
                    <gen-label>{{ formGroup.controls.firstName.label | translate }}</gen-label>
                    <gen-message severity="error" [appFormControlError]="formGroup.controls.firstName"></gen-message>
                </gen-text-input>
            </div>
            <div>
                <gen-text-input formControlName="lastName" data-cy="lastName" [maxlength]="100" [readonly]="readonly">
                    <gen-label>{{ formGroup.controls.lastName.label | translate }}</gen-label>
                    <gen-message severity="error" [appFormControlError]="formGroup.controls.lastName"></gen-message>
                </gen-text-input>
            </div>
            <div>
                <gen-text-input formControlName="emailAddress" data-cy="emailAddress" [maxlength]="100" [readonly]="readonly">
                    <gen-label>{{ formGroup.controls.emailAddress.label | translate }}</gen-label>
                    <gen-message severity="error" [appFormControlError]="formGroup.controls.emailAddress"></gen-message>
                </gen-text-input>
            </div>
            <div>
                <gen-text-input formControlName="mobilePhoneNumber" data-cy="mobilePhoneNumber" [maxlength]="100" [readonly]="readonly">
                    <gen-label>{{ formGroup.controls.mobilePhoneNumber.label | translate }}</gen-label>
                    <gen-message severity="error" [appFormControlError]="formGroup.controls.mobilePhoneNumber"></gen-message>
                </gen-text-input>
            </div>
        </div>
    </div>
</ng-container>
