import { Inject, OnDestroy, ViewChild } from '@angular/core';
import { Component, Injector, OnInit } from '@angular/core';
import { GenMeltedModalComponent, GenModalService, MeltedModalAction } from '@genetec/gelato-angular';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WINDOW } from '@src/app/utilities';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { AuthService } from '@securityCenter/services/authentication/auth.service';

export enum ShowMessageAction {
    Ok = 'ok',
    Cancel = 'cancel',
    Timeout = 'timeout',
}

@Component({
    selector: 'app-show-message',
    templateUrl: './show-message.component.html',
    styleUrls: ['./show-message.component.scss'],
})
export class ShowMessageComponent extends ConnectionAwareModalComponent implements OnInit, OnDestroy {
    // Represent the currently active message on screen. It is used to prevent stacking multiple messages.
    private static activeMessage: ShowMessageComponent | null = null;

    private static onDestroy: Subject<string> = new Subject();
    @ViewChild(GenMeltedModalComponent) public genModalComponent!: GenMeltedModalComponent;

    public title = '';
    public message = '';
    public actionText = 'OK';
    public cancelText: string | undefined;
    public timerText = '';
    public countdown: number | undefined = 0;
    public closedHandler: ((value: ShowMessageAction) => void) | undefined = undefined;

    // timer id for countdown
    private countdownTimer: number | undefined;
    private hasTimedOut = false;

    constructor(
        protected authService: AuthService,
        injector: Injector,
        private translateService: TranslateService,
        trackingService: TrackingService,
        @Inject(WINDOW) private window: Window
    ) {
        super(authService, injector, trackingService);
    }

    public static showMessage(
        modalService: GenModalService,
        title: string,
        message: string,
        closedHandler: (value: ShowMessageAction) => void,
        actionText: string | undefined = undefined,
        cancelText: string | undefined = undefined,
        countdown: number | undefined = undefined
    ): void {
        // if there is already an active message, close it
        if (ShowMessageComponent.activeMessage) {
            // force a close like if it was a timeout so it goes in the mailbox
            ShowMessageComponent.activeMessage.forceClose(MeltedModalAction.Default, true);
            ShowMessageComponent.activeMessage = null;
        }

        const component = modalService.open(ShowMessageComponent, undefined);
        if (component) {
            // Note the currently active message
            ShowMessageComponent.activeMessage = component;

            component.title = title;
            component.message = message;
            component.countdown = countdown;
            component.closedHandler = closedHandler;
            if (actionText) {
                component.actionText = actionText;
            }
            if (cancelText) {
                component.cancelText = cancelText;
            }
            component.genModalAction.pipe(takeUntil(this.onDestroy)).subscribe((modalAction: MeltedModalAction) => {
                component.stopTimer();
                if (closedHandler) {
                    if (component.hasTimedOut === true) {
                        closedHandler(ShowMessageAction.Timeout);
                    } else if (modalAction === MeltedModalAction.Default) {
                        closedHandler(ShowMessageAction.Ok);
                    } else {
                        closedHandler(ShowMessageAction.Cancel);
                    }
                }
            });
        }
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.countdown && this.countdown > 0) {
            this.countdownTimer = this.window.setInterval(() => this.onTimerCountdown(), 1000); // every second
            this.updateTimerText();
        }
    }

    ngOnDestroy() {
        ShowMessageComponent.onDestroy.next();
        ShowMessageComponent.onDestroy.complete();

        if (ShowMessageComponent.activeMessage === this) {
            ShowMessageComponent.activeMessage = null;
        }

        this.stopTimer();

        super.ngOnDestroy();
    }

    public show(): void {}

    private forceClose(action: MeltedModalAction, hasTimedOut: boolean) {
        this.stopTimer();
        this.hasTimedOut = hasTimedOut;
        this.genModalComponent.genModalAction.emit(action);
        this.hide();

        // when closing, inform the static instance we are not active anymore
        if (ShowMessageComponent.activeMessage === this) {
            ShowMessageComponent.activeMessage = null;
        }
    }

    private stopTimer() {
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer);
            this.countdownTimer = undefined;
        }
    }

    private updateTimerText() {
        this.timerText = '';
        if (this.countdown && this.countdown > 0) {
            if (this.countdown > 1) {
                this.timerText = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_CLOSING_IN_N_SECONDS') as string, this.countdown.toString());
            } else {
                this.timerText = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_CLOSING_IN_N_SECOND') as string, this.countdown.toString());
            }
        }
    }

    private onTimerCountdown() {
        if (this.countdown) {
            this.countdown--;

            this.updateTimerText();
            if (this.countdown <= 0) {
                this.forceClose(MeltedModalAction.Default, true);
            }
        }
    }
}
