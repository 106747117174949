<div
    class="toggle-button h-100"
    [style.cursor]="'pointer'"
    (click)="toggleButtonClick()"
    appClickActionOnSpaceEnter
    tabindex="0"
    [style.background-color]="item.isChecked ? 'rgba(61, 193, 243, 0.14902)' : 'transparent'"
    *ngIf="item"
>
    <div class="gen-button-group">
        <gen-item class="w-100">
            <!-- text -->
            <gen-text [flavor]="TextFlavor.Normal">
                <span>{{ item.text }}</span>
            </gen-text>

            <!-- image (if available) -->
            <gen-image *ngIf="item.image; else icon" slot="gen-icon" [src]="item.image" class="ha-center mr-2" [fit]="ImageFit.Contain" [flavor]="ImageFlavor.Rectangle">
            </gen-image>

            <!-- icon (if no image available) -->
            <ng-template #icon><gen-icon slot="gen-icon" [icon]="item.icon"> </gen-icon></ng-template>
        </gen-item>
    </div>
</div>
