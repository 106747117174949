import { Cancellation } from '../../utils/CancellationToken';
import { ILogger } from '../../utils/logger';
import { Utils } from '../../utils/Utils';
import { IInternalDewarperControl } from '../DewarperInterfaces';
import { InternalDewarperSourceImageParameters } from '../DewarperSourceImageParameters';
import { DewarpingParameters } from '../DewarpingParameters';
import { IDewarpedAreaCanvas } from './DewarpedAreaCanvas';
import { DewarpedAreaProgram, IDewarpedAreaProgram } from './DewarpedAreaProgram';

// The area that was read to produce the dewarped image
export class DewarpedArea {
  private readonly m_logger: ILogger;
  private readonly m_canvas: IDewarpedAreaCanvas;

  private readonly m_program: IDewarpedAreaProgram;

  private m_isEnabled: boolean = false; // Dewarped area display in preview is configured to be displayed
  private m_isDewarping: boolean = false; // Indicate if we are currently dewarping

  public setDewarpingParameters(dewarpingParameters: DewarpingParameters) {
    this.m_program.setDewarpingParameters(dewarpingParameters);
  }

  public get Controller(): IInternalDewarperControl {
    return this.m_program.Controller;
  }

  public set IsDewarping(value: boolean) {
    if (this.m_isDewarping !== value) {
      this.m_isDewarping = value;
      this.setVisibility();
    }
  }

  public set Enabled(value: boolean) {
    if (this.m_isEnabled !== value) {
      this.m_isEnabled = value;
      this.setVisibility();
    }
  }

  public static build(logger: ILogger, dewarpedAreaCanvas: IDewarpedAreaCanvas, dewarperSourceImageParameters: InternalDewarperSourceImageParameters): DewarpedArea | null {
    try {
      const program = new DewarpedAreaProgram(logger, dewarpedAreaCanvas.getWebGL2Context(), dewarperSourceImageParameters);
      return new DewarpedArea(logger, program, dewarpedAreaCanvas);
    } catch (e) {
      logger.error?.trace('Failed to create dewarped area.', e);
    }

    return null;
  }

  public constructor(logger: ILogger, program: IDewarpedAreaProgram, dewarpedAreaCanvas: IDewarpedAreaCanvas) {
    this.m_logger = logger.subLogger('DewarpedArea');
    this.m_canvas = dewarpedAreaCanvas;

    this.m_program = program;
    this.m_program.DewarpedAreaColor = this.m_canvas.DewarpedAreaColor;
    this.m_program.DewarpedAreaBorderColor = this.m_canvas.DewarpedAreaBorderColor;

    this.m_canvas.Resized.register(this.onCanvasResized);
    this.observeCanvasStyleLoop();
  }

  public dispose(): void {
    this.m_canvas.Resized.unregister(this.onCanvasResized);
    this.m_canvas.dispose();
    this.m_program.dispose();
    this.m_logger.debug?.trace('dispose');
  }

  private setVisibility(): void {
    const show = this.m_isDewarping && this.m_isEnabled;
    this.m_logger.debug?.trace(`${(show ? 'showing' : 'hiding')} dewarping area [Dewarping: ${this.m_isDewarping}, Enabled: ${this.m_isEnabled}]`);
    this.m_canvas.Show = show;
  }

  private readonly onCanvasResized = () => {
    this.m_program.canvasResolutionChanged();
  }

  private async observeCanvasStyleLoop() {
    let styleChangeAwaitingResult = await this.m_canvas.StyleChange;
    while (!(styleChangeAwaitingResult instanceof Cancellation)) {
      this.m_program.DewarpedAreaColor = this.m_canvas.DewarpedAreaColor;
      this.m_program.DewarpedAreaBorderColor = this.m_canvas.DewarpedAreaBorderColor;

      styleChangeAwaitingResult = await this.m_canvas.StyleChange;
    }
    this.m_logger.debug?.trace('End of canvas style observation loop');
  }

  public debugStatus(indent: number): string {
    return `Dewarped Area: (${this.m_isEnabled ? 'enabled' : 'disabled'})` + Utils.indentNewLine(indent) +
      'Is dewarping: ' + this.m_isDewarping + Utils.indentNewLine(indent) +
      this.m_canvas.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      this.m_program.debugStatus(indent + Utils.Indentation);
  }
}
