import { Guard } from './Guard';

export class Color {
  //0 - 255 components
  private readonly m_r: number;
  private readonly m_g: number;
  private readonly m_b: number;
  private readonly m_a: number;

  public get R(): number {
    return this.m_r;
  }

  public get G(): number {
    return this.m_g;
  }

  public get B(): number {
    return this.m_b;
  }

  public get A(): number {
    return this.m_a;
  }

  public static fromCSSColor(str: string): Color {
    const canvas = document.createElement('canvas');
    canvas.height = 1;
    canvas.width = 1;
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
      throw new Error('Failed to obtain a canvas to translate the color.');
    }

    ctx.fillStyle = str;
    ctx.fillRect(0, 0, 1, 1);

    const rgba = ctx.getImageData(0, 0, 1, 1).data;
    const color = new Color(rgba[0], rgba[1], rgba[2], rgba[3]);
    return color;
  }

  public constructor(r: number, g: number, b: number, a: number) {
    Guard.isInRangeInclusive(r, 0, 255);
    Guard.isInRangeInclusive(g, 0, 255);
    Guard.isInRangeInclusive(b, 0, 255);
    Guard.isInRangeInclusive(a, 0, 255);

    this.m_r = r;
    this.m_g = g;
    this.m_b = b;
    this.m_a = a;
  }

  public toString(): string {
    return `R:${this.m_r} G:${this.m_g} B:${this.m_b} A:${this.m_a}`;
  }
}

export class NormalizedColor {
  //0 - 1 components
  private readonly m_r: number;
  private readonly m_g: number;
  private readonly m_b: number;
  private readonly m_a: number;

  public get R(): number {
    return this.m_r;
  }

  public get G(): number {
    return this.m_g;
  }

  public get B(): number {
    return this.m_b;
  }

  public get A(): number {
    return this.m_a;
  }

  public static fromColor(color: Color) {
    return new NormalizedColor(color.R / 255, color.G / 255, color.B / 255, color.A / 255);
  }

  public constructor(r: number, g: number, b: number, a: number) {
    Guard.isInRangeInclusive(r, 0, 1);
    Guard.isInRangeInclusive(g, 0, 1);
    Guard.isInRangeInclusive(b, 0, 1);
    Guard.isInRangeInclusive(a, 0, 1);

    this.m_r = r;
    this.m_g = g;
    this.m_b = b;
    this.m_a = a;
  }

  public equals(color: NormalizedColor): boolean {
    return this.m_r === color.m_r &&
      this.m_g === color.m_g &&
      this.m_b === color.m_b &&
      this.m_a === color.m_a;
  }

  public toString(): string {
    return `R:${this.m_r.toFixed(2)} G:${this.m_g.toFixed(2)} B:${this.m_b.toFixed(2)} A:${this.m_a.toFixed(2)}`;
  }
}
