import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { defaultIfEmpty, map } from 'rxjs/operators';
import { IncidentLocationService } from '../../services/incident-location/incident-location.service';

@Pipe({ name: 'incidentLocationName' })
export class LocationNamePipe implements PipeTransform {
    constructor(private _locationService: IncidentLocationService, private _translateService: TranslateService) {}

    public transform(id: IGuid | string | null | undefined): Observable<string> {
        const noLocationTranslatedName = this._translateService.instant('STE_LABEL_NONE') as string;
        const unavailableInformationTranslatedName = this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string;

        if (!id) return of(noLocationTranslatedName);
        const locationId = isInstanceOfGuid(id) ? id : SafeGuid.parse(id);
        if (locationId.isEmpty()) return of(noLocationTranslatedName);

        return from(this._locationService.getLocation(locationId)).pipe(
            map((entity) => entity?.name ?? unavailableInformationTranslatedName),
            defaultIfEmpty(unavailableInformationTranslatedName)
        );
    }
}
