// Autogenerated: CodeGen/TimelineController?language=ts&InterfaceOnly=False on 2021-06-21 12:13:18 PM
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { RestObject } from 'RestClient/Client/Model/RestObject';
import { ITimelineController, ITimelineProvider } from './timeline.controller.interfaces';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';

export class TimelineProvider extends FieldObject implements ITimelineProvider {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Title
    public get title(): string {
        return this.getField<string>('Title');
    }
    public set title(value: string) {
        this.setField<string>('Title', value);
    }
    // Icon
    public get icon(): string {
        return this.getField<string>('Icon');
    }
    public set icon(value: string) {
        this.setField<string>('Icon', value);
    }
    // IsEnabledDefault
    public get isEnabledDefault(): boolean {
        return this.getField<boolean>('IsEnabledDefault');
    }
    public set isEnabledDefault(value: boolean) {
        this.setField<boolean>('IsEnabledDefault', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Title', '');
        this.setField<string>('Icon', '');
        this.setField<boolean>('IsEnabledDefault', false);
    }
}

export class TimelineController extends RestObject implements ITimelineController {
    constructor() {
        super('api/Timeline');
        this._canGet = false;
    }

    // #region Rest Methods

    public async getProvidersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ITimelineProvider> | null> {
        return this.getFieldObjectRelationAsync<TimelineProvider, ITimelineProvider>(TimelineProvider, 'Providers', 'Id', true, false, null, transaction);
    }

    // #endregion Rest Methods

}
