// Autogenerated: v2/help/Entities/ApiCustomEntity?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:24 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { FieldObject } from '../../Helpers/FieldObject';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { ICustomEntity, ICustomState, ICustomInfo, CustomEntityFields } from '../Interface/ICustomEntity';
import { ITransaction } from '../Interface/ITransaction';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ICustomEntityTypeDescriptor } from '../Interface/ICustomEntityManager';
import { CustomEntityTypeDescriptor } from '../Managers/CustomEntityManager';

// #region Inner classes

export class CustomState extends FieldObject implements ICustomState {
    public get state0(): string {
        return this.getField<string>('0');
    }

    public set state0(value: string) {
        this.setField<string>('0', value);
    }
    public get state1(): string {
        return this.getField<string>('1');
    }

    public set state1(value: string) {
        this.setField<string>('1', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
    }
}

export class CustomInfo extends FieldObject implements ICustomInfo {
    public get info0(): string {
        return this.getField<string>('0');
    }

    public set info0(value: string) {
        this.setField<string>('0', value);
    }
    public get info1(): string {
        return this.getField<string>('1');
    }

    public set info1(value: string) {
        this.setField<string>('1', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
    }
}

// #endregion Inner classes

export class CustomEntity extends Entity implements ICustomEntity {
    // #region REST Properties

    // CustomEntityTypeDescriptorId
    public get customEntityTypeDescriptorId(): IGuid {
        return this.getFieldGuid(CustomEntityFields.customEntityTypeDescriptorIdField);
    }
    public set customEntityTypeDescriptorId(value: IGuid) {
        this.setFieldGuid(CustomEntityFields.customEntityTypeDescriptorIdField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.CustomEntities);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getInfosAsync(transaction?: ITransaction | null): Promise<ICustomInfo | null> {
        return await this.getDataRelationAsync<CustomInfo>(CustomInfo, 'Infos', true, ValidFlags.None, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getStatesAsync(transaction?: ITransaction | null): Promise<ICustomState | null> {
        return await this.getDataRelationAsync<CustomState>(CustomState, 'States', true, ValidFlags.None, transaction);
    }

    public async getTypeDescriptorAsync(transaction?: ITransaction | null): Promise<ICustomEntityTypeDescriptor | null> {
        return await this.getDataRelationAsync<CustomEntityTypeDescriptor>(CustomEntityTypeDescriptor, 'TypeDescriptor', false, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

