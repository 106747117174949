import { Component, Input, OnDestroy } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { MCIncident } from '@modules/mission-control/models/mc-incident';

@Component({
    selector: 'app-incident-card',
    templateUrl: './incident-card.component.html',
    styleUrls: ['./incident-card.component.scss'],
})
export class IncidentCardComponent {
    @Input() public incident: MCIncident | null = null;
    @Input() public disabled: boolean = false;
    @Input() public selected: boolean = false;

    public TextFlavor = TextFlavor;
}
