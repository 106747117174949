import {
    StateChangedEvent,
    PriorityChangedEvent,
    LocationChangedEvent,
    DescriptionChangedEvent,
    ExternalIdChangedEvent,
    ProcedureStepAnsweredEvent,
    OwnershipChangedEvent,
    RecipientAlteredEvent,
    LinkedEvent,
    UnlinkedEvent,
} from '@modules/mission-control/models/events/incident-event';
import { MCIncident } from '@modules/mission-control/models/mc-incident';

export class IncidentHandler {
    public static stateChange = (incident: MCIncident, event: StateChangedEvent): MCIncident => incident.with({ stateId: event.stateId });

    public static priorityChange = (incident: MCIncident, event: PriorityChangedEvent): MCIncident => incident.with({ priorityId: event.priorityId });

    public static locationChange = (incident: MCIncident, event: LocationChangedEvent): MCIncident => incident.with({ location: event.location });

    public static linkIncidents = (incident: MCIncident, event: LinkedEvent): MCIncident =>
        incident.with({ linkedIncidentIds: incident.linkedIncidentIds ? [...incident.linkedIncidentIds, ...event.linkedIncidentIds] : event.linkedIncidentIds });

    public static unlinkIncidents = (incident: MCIncident, event: UnlinkedEvent) =>
        incident.with({ linkedIncidentIds: incident.linkedIncidentIds?.filter((id) => !event.unlinkedIncidentIds.find((x) => x.equals(id))) });

    public static descriptionChange = (incident: MCIncident, event: DescriptionChangedEvent): MCIncident => incident.with({ description: event.description });

    public static externalIdChange = (incident: MCIncident, event: ExternalIdChangedEvent): MCIncident => incident.with({ externalId: event.externalId });

    public static currentStepChange = (incident: MCIncident, event: ProcedureStepAnsweredEvent): MCIncident => incident.with({ currentStepId: event.nextStepId });

    public static ownerChange = (incident: MCIncident, event: OwnershipChangedEvent): MCIncident => incident.with({ ownerId: event.owner });

    public static clearLocation = (incident: MCIncident): MCIncident => incident.with({ location: null });

    public static incidentTypeChange = (incident: MCIncident, newIncident: MCIncident): MCIncident =>
        incident.with({
            argbColor: newIncident.argbColor,
            name: newIncident.name,
            iconId: newIncident.iconId,
        });

    public static recipientChange = (incident: MCIncident, event: RecipientAlteredEvent): MCIncident =>
        incident.with({ recipientIds: incident.recipientIds.concat(event.addedRecipients) });
}
