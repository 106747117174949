// Autogenerated: v2/help/Entities/ApiArchiverAgent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-15 12:15:59 PM
import { AgentEntity } from '../AgentEntity';
import { FieldObject } from '../../../Helpers/FieldObject';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IArchiverAgentEntity, ArchiverAgentEntityFields } from '../../Interface/IArchiverAgentEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IFieldObject } from '../../Interface/IFieldObject';
import { IDatabaseCreateInfo, IDatabaseInfo } from '../../Interface/IRoleEntity';

export class ArchiverAgentEntity extends AgentEntity implements IArchiverAgentEntity {
    // #region REST Properties

    // ArchiveBackupPath
    public get archiveBackupPath(): string {
        return this.getField<string>(ArchiverAgentEntityFields.archiveBackupPathField);
    }
    public set archiveBackupPath(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.archiveBackupPathField, value);
    }

    // ArchivingPriority
    public get archivingPriority(): number {
        return this.getField<number>(ArchiverAgentEntityFields.archivingPriorityField);
    }
    public set archivingPriority(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.archivingPriorityField, value);
    }

    // AutomaticBackupFileCount
    public get automaticBackupFileCount(): number {
        return this.getField<number>(ArchiverAgentEntityFields.automaticBackupFileCountField);
    }
    public set automaticBackupFileCount(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.automaticBackupFileCountField, value);
    }

    // AutomaticBackupFrequency
    public get automaticBackupFrequency(): string {
        return this.getField<string>(ArchiverAgentEntityFields.automaticBackupFrequencyField);
    }
    public set automaticBackupFrequency(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.automaticBackupFrequencyField, value);
    }

    // AutomaticBackupTime
    public get automaticBackupTime(): number {
        return this.getField<number>(ArchiverAgentEntityFields.automaticBackupTimeField);
    }
    public set automaticBackupTime(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.automaticBackupTimeField, value);
    }

    // BackupFolder
    public get backupFolder(): string {
        return this.getField<string>(ArchiverAgentEntityFields.backupFolderField);
    }
    public set backupFolder(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.backupFolderField, value);
    }

    // BackupRetentionPeriod
    public get backupRetentionPeriod(): number {
        return this.getField<number>(ArchiverAgentEntityFields.backupRetentionPeriodField);
    }
    public set backupRetentionPeriod(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.backupRetentionPeriodField, value);
    }

    // DatabaseFolder
    public get databaseFolder(): string {
        return this.getField<string>(ArchiverAgentEntityFields.databaseFolderField);
    }
    public set databaseFolder(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.databaseFolderField, value);
    }

    // DatabaseInstance
    public get databaseInstance(): string {
        return this.getField<string>(ArchiverAgentEntityFields.databaseInstanceField);
    }
    public set databaseInstance(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.databaseInstanceField, value);
    }

    // DatabaseName
    public get databaseName(): string {
        return this.getField<string>(ArchiverAgentEntityFields.databaseNameField);
    }
    public set databaseName(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.databaseNameField, value);
    }

    // DatabasePassword
    public get databasePassword(): string {
        return this.getField<string>(ArchiverAgentEntityFields.databasePasswordField);
    }
    public set databasePassword(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.databasePasswordField, value);
    }

    // DatabaseUser
    public get databaseUser(): string {
        return this.getField<string>(ArchiverAgentEntityFields.databaseUserField);
    }
    public set databaseUser(value: string) {
        this.setField<string>(ArchiverAgentEntityFields.databaseUserField, value);
    }

    // DeleteOldBackupFilesOnDiskFull
    public get deleteOldBackupFilesOnDiskFull(): boolean {
        return this.getField<boolean>(ArchiverAgentEntityFields.deleteOldBackupFilesOnDiskFullField);
    }
    public set deleteOldBackupFilesOnDiskFull(value: boolean) {
        this.setField<boolean>(ArchiverAgentEntityFields.deleteOldBackupFilesOnDiskFullField, value);
    }

    // EnableAutomaticBackup
    public get enableAutomaticBackup(): boolean {
        return this.getField<boolean>(ArchiverAgentEntityFields.enableAutomaticBackupField);
    }
    public set enableAutomaticBackup(value: boolean) {
        this.setField<boolean>(ArchiverAgentEntityFields.enableAutomaticBackupField, value);
    }

    // EnableBackupCompression
    public get enableBackupCompression(): boolean {
        return this.getField<boolean>(ArchiverAgentEntityFields.enableBackupCompressionField);
    }
    public set enableBackupCompression(value: boolean) {
        this.setField<boolean>(ArchiverAgentEntityFields.enableBackupCompressionField, value);
    }

    // NotificationsDatabaseUsage
    public get notificationsDatabaseUsage(): boolean {
        return this.getField<boolean>(ArchiverAgentEntityFields.notificationsDatabaseUsageField);
    }
    public set notificationsDatabaseUsage(value: boolean) {
        this.setField<boolean>(ArchiverAgentEntityFields.notificationsDatabaseUsageField, value);
    }

    // NotificationsDatabaseUsageAsPercentage
    public get notificationsDatabaseUsageAsPercentage(): number {
        return this.getField<number>(ArchiverAgentEntityFields.notificationsDatabaseUsageAsPercentageField);
    }
    public set notificationsDatabaseUsageAsPercentage(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.notificationsDatabaseUsageAsPercentageField, value);
    }

    // NotificationsDiskSpace
    public get notificationsDiskSpace(): boolean {
        return this.getField<boolean>(ArchiverAgentEntityFields.notificationsDiskSpaceField);
    }
    public set notificationsDiskSpace(value: boolean) {
        this.setField<boolean>(ArchiverAgentEntityFields.notificationsDiskSpaceField, value);
    }

    // NotificationsOnlyWhenLimitIsReached
    public get notificationsOnlyWhenLimitIsReached(): boolean {
        return this.getField<boolean>(ArchiverAgentEntityFields.notificationsOnlyWhenLimitIsReachedField);
    }
    public set notificationsOnlyWhenLimitIsReached(value: boolean) {
        this.setField<boolean>(ArchiverAgentEntityFields.notificationsOnlyWhenLimitIsReachedField, value);
    }

    // NotificationsRemainingGb
    public get notificationsRemainingGb(): number {
        return this.getField<number>(ArchiverAgentEntityFields.notificationsRemainingGbField);
    }
    public set notificationsRemainingGb(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.notificationsRemainingGbField, value);
    }

    // RtspPort
    public get rtspPort(): number {
        return this.getField<number>(ArchiverAgentEntityFields.rtspPortField);
    }
    public set rtspPort(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.rtspPortField, value);
    }

    // RtspPortUnitControl
    public get rtspPortUnitControl(): number {
        return this.getField<number>(ArchiverAgentEntityFields.rtspPortUnitControlField);
    }
    public set rtspPortUnitControl(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.rtspPortUnitControlField, value);
    }

    // TelnetPort
    public get telnetPort(): number {
        return this.getField<number>(ArchiverAgentEntityFields.telnetPortField);
    }
    public set telnetPort(value: number) {
        this.setField<number>(ArchiverAgentEntityFields.telnetPortField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getArchiverStatsAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'ArchiverStats', false, ValidFlags.None, transaction);
    }

    public async createDatabaseAsync(value: IDatabaseCreateInfo, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('CreateDatabase', 'POST', value, transaction, responseHandler);
    }

    public async createDatabaseBackupAsync(folder: string, enableCompression: boolean, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('folder', folder);
        inParam.setField('enableCompression', enableCompression);

        return this.executeRequestTransactionAsync<IRestResponse>('CreateDatabaseBackup', 'POST', inParam, transaction, responseHandler);
    }

    public async deleteDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('DeleteDatabase', 'POST', null, transaction, responseHandler);
    }

    public async getDriveGroupsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IFieldObject>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IFieldObject>;
                return new Promise<Array<IFieldObject>>(function(resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IFieldObject>>(function(resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<Array<IFieldObject>>('DriveGroups', 'GET', null, transaction, responseHandler);
    }

    public async restoreDatabaseBackupAsync(file: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('file', file);

        return this.executeRequestTransactionAsync<IRestResponse>('RestoreDatabaseBackup', 'POST', inParam, transaction, responseHandler);
    }

    public async setDatabaseAsync(value: IDatabaseInfo, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('SetDatabase', 'POST', value, transaction, responseHandler);
    }

    public async upgradeDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('UpgradeDatabase', 'POST', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

