// Autogenerated: v2/help/Entities/ApiSystemConfiguration?codegen=ts&InterfaceOnly=False&workflow=False on 2022-12-21 2:23:55 PM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { FieldObject } from '../../Helpers/FieldObject';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { ITransaction } from '../Interface/ITransaction';
import { IFieldObject } from '../Interface/IFieldObject';
import { IQueryFilter } from '../Interface/IQueryFilter';
import {
    IActivityTrailConfig,
    IActivityTrailParameter,
    ICustomEvent,
    ICustomFieldConfig,
    ICustomFieldTypeDescriptor,
    IEventMetadata,
    ILicenseUsage,
    IMapProvider,
    IMapServer,
    INewActivityTrail,
    ISystemConfigurationEntity,
    SystemConfigurationEntityFields,
} from '../Interface/ISystemConfigurationEntity';
import { ICustomEntityTypeDescriptor } from '../Interface/ICustomEntityManager';
import { CustomEntityTypeDescriptor } from '../Managers/CustomEntityManager';

// #region Inner classes

export class ActivityTrailParameter extends FieldObject implements IActivityTrailParameter {
    // Value
    public get value(): string {
        return this.getField<string>('Value');
    }
    public set value(value: string) {
        this.setField<string>('Value', value);
    }
    // KeepCurrentFormat
    public get keepCurrentFormat(): boolean {
        return this.getField<boolean>('KeepCurrentFormat');
    }
    public set keepCurrentFormat(value: boolean) {
        this.setField<boolean>('KeepCurrentFormat', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Value', '');
        this.setField<boolean>('KeepCurrentFormat', false);
    }
}

export class NewActivityTrail extends FieldObject implements INewActivityTrail {
    // TemplateId
    public get templateId(): number {
        return this.getField<number>('TemplateId');
    }
    public set templateId(value: number) {
        this.setField<number>('TemplateId', value);
    }
    // ActivityType
    public get activityType(): IGuid {
        return this.getFieldGuid('ActivityType');
    }
    public set activityType(value: IGuid) {
        this.setFieldGuid('ActivityType', value);
    }
    // Initiator
    public get initiator(): IGuid {
        return this.getFieldGuid('Initiator');
    }
    public set initiator(value: IGuid) {
        this.setFieldGuid('Initiator', value);
    }
    // ImpactedEntity
    public get impactedEntity(): IGuid {
        return this.getFieldGuid('ImpactedEntity');
    }
    public set impactedEntity(value: IGuid) {
        this.setFieldGuid('ImpactedEntity', value);
    }
    // MachineName
    public get machineName(): string {
        return this.getField<string>('MachineName');
    }
    public set machineName(value: string) {
        this.setField<string>('MachineName', value);
    }
    // AppType
    public get appType(): string {
        return this.getField<string>('AppType');
    }
    public set appType(value: string) {
        this.setField<string>('AppType', value);
    }
    // Parameters
    public get parameters(): ObservableCollection<IActivityTrailParameter> {
        return this.getFieldObjectArray<ActivityTrailParameter, IActivityTrailParameter>(ActivityTrailParameter, 'Parameters');
    }
    public set parameters(value: ObservableCollection<IActivityTrailParameter>) {
        this.setFieldObjectArray('Parameters', value);
    }
    // AppVersion
    public get appVersion(): string {
        return this.getField<string>('AppVersion');
    }
    public set appVersion(value: string) {
        this.setField<string>('AppVersion', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('TemplateId', 0);
        this.setFieldGuid('ActivityType', SafeGuid.EMPTY);
        this.setFieldGuid('Initiator', SafeGuid.EMPTY);
        this.setFieldGuid('ImpactedEntity', SafeGuid.EMPTY);
        this.setField<string>('MachineName', '');
        this.setField<string>('AppType', '');
        this.setFieldArray<IActivityTrailParameter>('Parameters', new ObservableCollection<IActivityTrailParameter>());
        this.setField<string>('AppVersion', '');
    }
}

export class MapServer extends FieldObject implements IMapServer {
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // ServerUrl
    public get serverUrl(): string {
        return this.getField<string>('ServerUrl');
    }
    public set serverUrl(value: string) {
        this.setField<string>('ServerUrl', value);
    }
    // Username
    public get username(): string {
        return this.getField<string>('Username');
    }
    public set username(value: string) {
        this.setField<string>('Username', value);
    }
    // UniqueId
    public get uniqueId(): IGuid {
        return this.getFieldGuid('UniqueId');
    }
    public set uniqueId(value: IGuid) {
        this.setFieldGuid('UniqueId', value);
    }
    // Password
    public get password(): string {
        return this.getField<string>('Password');
    }
    public set password(value: string) {
        this.setField<string>('Password', value);
    }
    // UseAuthentication
    public get useAuthentication(): boolean {
        return this.getField<boolean>('UseAuthentication');
    }
    public set useAuthentication(value: boolean) {
        this.setField<boolean>('UseAuthentication', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Name', '');
        this.setField<string>('ServerUrl', '');
        this.setField<string>('Username', '');
        this.setFieldGuid('UniqueId', SafeGuid.EMPTY);
        this.setField<string>('Password', '');
        this.setField<boolean>('UseAuthentication', false);
    }
}

export class MapProvider extends FieldObject implements IMapProvider {
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Type
    public get type(): IGuid {
        return this.getFieldGuid('Type');
    }
    public set type(value: IGuid) {
        this.setFieldGuid('Type', value);
    }
    // LicenseKey
    public get licenseKey(): string {
        return this.getField<string>('LicenseKey');
    }
    public set licenseKey(value: string) {
        this.setField<string>('LicenseKey', value);
    }
    // SupportLicense
    public get supportLicense(): boolean {
        return this.getField<boolean>('SupportLicense');
    }
    public set supportLicense(value: boolean) {
        this.setField<boolean>('SupportLicense', value);
    }
    // InvertCoordinates
    public get invertCoordinates(): boolean {
        return this.getField<boolean>('InvertCoordinates');
    }
    public set invertCoordinates(value: boolean) {
        this.setField<boolean>('InvertCoordinates', value);
    }
    // IsCustom
    public get isCustom(): boolean {
        return this.getField<boolean>('IsCustom');
    }
    public set isCustom(value: boolean) {
        this.setField<boolean>('IsCustom', value);
    }
    // IsDefault
    public get isDefault(): boolean {
        return this.getField<boolean>('IsDefault');
    }
    public set isDefault(value: boolean) {
        this.setField<boolean>('IsDefault', value);
    }
    // MaxZoom
    public get maxZoom(): number {
        return this.getField<number>('MaxZoom');
    }
    public set maxZoom(value: number) {
        this.setField<number>('MaxZoom', value);
    }
    // Projection
    public get projection(): string {
        return this.getField<string>('Projection');
    }
    public set projection(value: string) {
        this.setField<string>('Projection', value);
    }
    // ProviderId
    public get providerId(): IGuid {
        return this.getFieldGuid('ProviderId');
    }
    public set providerId(value: IGuid) {
        this.setFieldGuid('ProviderId', value);
    }
    // UrlAddress
    public get urlAddress(): string {
        return this.getField<string>('UrlAddress');
    }
    public set urlAddress(value: string) {
        this.setField<string>('UrlAddress', value);
    }
    // UrlFormat
    public get urlFormat(): string {
        return this.getField<string>('UrlFormat');
    }
    public set urlFormat(value: string) {
        this.setField<string>('UrlFormat', value);
    }
    // UseAuthentication
    public get useAuthentication(): boolean {
        return this.getField<boolean>('UseAuthentication');
    }
    public set useAuthentication(value: boolean) {
        this.setField<boolean>('UseAuthentication', value);
    }
    // Username
    public get username(): string {
        return this.getField<string>('Username');
    }
    public set username(value: string) {
        this.setField<string>('Username', value);
    }
    // Password
    public get password(): string {
        return this.getField<string>('Password');
    }
    public set password(value: string) {
        this.setField<string>('Password', value);
    }
    // MapServers
    public get mapServers(): ObservableCollection<IMapServer> {
        return this.getFieldObjectArray<MapServer, IMapServer>(MapServer, 'MapServers');
    }
    public set mapServers(value: ObservableCollection<IMapServer>) {
        this.setFieldObjectArray('MapServers', value);
    }
    // MappingType
    public get mappingType(): string {
        return this.getField<string>('MappingType');
    }
    public set mappingType(value: string) {
        this.setField<string>('MappingType', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Name', '');
        this.setFieldGuid('Type', SafeGuid.EMPTY);
        this.setField<string>('LicenseKey', '');
        this.setField<boolean>('SupportLicense', false);
        this.setField<boolean>('InvertCoordinates', false);
        this.setField<boolean>('IsCustom', false);
        this.setField<boolean>('IsDefault', false);
        this.setField<number>('MaxZoom', 0);
        this.setField<string>('Projection', '');
        this.setFieldGuid('ProviderId', SafeGuid.EMPTY);
        this.setField<string>('UrlAddress', '');
        this.setField<string>('UrlFormat', '');
        this.setField<boolean>('UseAuthentication', false);
        this.setField<string>('Username', '');
        this.setField<string>('Password', '');
        this.setFieldArray<IMapServer>('MapServers', new ObservableCollection<IMapServer>());
        this.setField<string>('MappingType', '');
    }
}

export class LicenseUsage extends FieldObject implements ILicenseUsage {
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // Type
    public get type(): string {
        return this.getField<string>('Type');
    }
    public set type(value: string) {
        this.setField<string>('Type', value);
    }
    // Value
    public get value(): string {
        return this.getField<string>('Value');
    }
    public set value(value: string) {
        this.setField<string>('Value', value);
    }
    // Current
    public get current(): number {
        return this.getField<number>('Current');
    }
    public set current(value: number) {
        this.setField<number>('Current', value);
    }
    // Max
    public get max(): number {
        return this.getField<number>('Max');
    }
    public set max(value: number) {
        this.setField<number>('Max', value);
    }
    // Active
    public get active(): boolean {
        return this.getField<boolean>('Active');
    }
    public set active(value: boolean) {
        this.setField<boolean>('Active', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Id', '');
        this.setField<string>('Description', '');
        this.setField<string>('Type', '');
        this.setField<string>('Value', '');
        this.setField<number>('Current', 0);
        this.setField<number>('Max', 0);
        this.setField<boolean>('Active', false);
    }
}

export class EventMetadata extends FieldObject implements IEventMetadata {
    // Color
    public get color(): string {
        return this.getField<string>('Color');
    }
    public set color(value: string) {
        this.setField<string>('Color', value);
    }
    // EventType
    public get eventType(): string {
        return this.getField<string>('EventType');
    }
    public set eventType(value: string) {
        this.setField<string>('EventType', value);
    }
    // EventSubType
    public get eventSubType(): number {
        return this.getField<number>('EventSubType');
    }
    public set eventSubType(value: number) {
        this.setField<number>('EventSubType', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Color', '');
        this.setField<string>('EventType', '');
        this.setField<number>('EventSubType', 0);
    }
}

export class CustomFieldConfig extends FieldObject implements ICustomFieldConfig {
    // EntityType
    public get entityType(): string {
        return this.getField<string>('EntityType');
    }
    public set entityType(value: string) {
        this.setField<string>('EntityType', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // DefaultValue
    public get defaultValue(): string {
        return this.getField<string>('DefaultValue');
    }
    public set defaultValue(value: string) {
        this.setField<string>('DefaultValue', value);
    }
    // GroupName
    public get groupName(): string {
        return this.getField<string>('GroupName');
    }
    public set groupName(value: string) {
        this.setField<string>('GroupName', value);
    }
    // GroupPriority
    public get groupPriority(): number {
        return this.getField<number>('GroupPriority');
    }
    public set groupPriority(value: number) {
        this.setField<number>('GroupPriority', value);
    }
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Mandatory
    public get mandatory(): boolean {
        return this.getField<boolean>('Mandatory');
    }
    public set mandatory(value: boolean) {
        this.setField<boolean>('Mandatory', value);
    }
    // Unique
    public get unique(): boolean {
        return this.getField<boolean>('Unique');
    }
    public set unique(value: boolean) {
        this.setField<boolean>('Unique', value);
    }
    // ShowInReports
    public get showInReports(): boolean {
        return this.getField<boolean>('ShowInReports');
    }
    public set showInReports(value: boolean) {
        this.setField<boolean>('ShowInReports', value);
    }
    // ValueType
    public get valueType(): string {
        return this.getField<string>('ValueType');
    }
    public set valueType(value: string) {
        this.setField<string>('ValueType', value);
    }
    // Owner
    public get owner(): IGuid {
        return this.getFieldGuid('Owner');
    }
    public set owner(value: IGuid) {
        this.setFieldGuid('Owner', value);
    }
    // IsReadOnly
    public get isReadOnly(): boolean {
        return this.getField<boolean>('IsReadOnly');
    }
    public set isReadOnly(value: boolean) {
        this.setField<boolean>('IsReadOnly', value);
    }
    // CustomDataTypes
    public get customDataTypes(): number {
        return this.getField<number>('CustomDataTypes');
    }
    public set customDataTypes(value: number) {
        this.setField<number>('CustomDataTypes', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('EntityType', '');
        this.setField<string>('Name', '');
        this.setField<string>('DefaultValue', '');
        this.setField<string>('GroupName', '');
        this.setField<number>('GroupPriority', 0);
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('Mandatory', false);
        this.setField<boolean>('Unique', false);
        this.setField<boolean>('ShowInReports', false);
        this.setField<string>('ValueType', '');
        this.setFieldGuid('Owner', SafeGuid.EMPTY);
        this.setField<boolean>('IsReadOnly', false);
        this.setField<number>('CustomDataTypes', 0);
    }
}

export class CustomEvent extends FieldObject implements ICustomEvent {
    // Id
    public get id(): number {
        return this.getField<number>('Id');
    }
    public set id(value: number) {
        this.setField<number>('Id', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Role
    public get role(): IGuid {
        return this.getFieldGuid('Role');
    }
    public set role(value: IGuid) {
        this.setFieldGuid('Role', value);
    }
    // RoleSubType
    public get roleSubType(): IGuid {
        return this.getFieldGuid('RoleSubType');
    }
    public set roleSubType(value: IGuid) {
        this.setFieldGuid('RoleSubType', value);
    }
    // SupportedEntityType
    public get supportedEntityType(): string {
        return this.getField<string>('SupportedEntityType');
    }
    public set supportedEntityType(value: string) {
        this.setField<string>('SupportedEntityType', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Id', 0);
        this.setField<string>('Name', '');
        this.setFieldGuid('Role', SafeGuid.EMPTY);
        this.setFieldGuid('RoleSubType', SafeGuid.EMPTY);
        this.setField<string>('SupportedEntityType', '');
    }
}

export class CustomFieldTypeDescriptor extends FieldObject implements ICustomFieldTypeDescriptor {
    // Id
    public get id(): number | null {
        return this.getNullableField<number>('Id');
    }
    public set id(value: number | null) {
        this.setNullableField<number>('Id', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // DataType
    public get dataType(): string {
        return this.getField<string>('DataType');
    }
    public set dataType(value: string) {
        this.setField<string>('DataType', value);
    }
    // Values
    public get values(): ObservableCollection<string> {
        return this.getFieldArray<string>('Values');
    }
    public set values(value: ObservableCollection<string>) {
        this.setFieldArray<string>('Values', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Id', 0);
        this.setField<string>('Name', '');
        this.setField<string>('Description', '');
        this.setField<string>('DataType', '');
        this.setFieldArray<string>('Values', new ObservableCollection<string>());
    }
}

export class ActivityTrailConfig extends FieldObject implements IActivityTrailConfig {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Enabled
    public get enabled(): boolean {
        return this.getField<boolean>('Enabled');
    }
    public set enabled(value: boolean) {
        this.setField<boolean>('Enabled', value);
    }
    // DescriptionId
    public get descriptionId(): number {
        return this.getField<number>('DescriptionId');
    }
    public set descriptionId(value: number) {
        this.setField<number>('DescriptionId', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('Enabled', false);
        this.setField<number>('DescriptionId', 0);
        this.setField<string>('Description', '');
    }
}

// #endregion Inner classes

export class SystemConfigurationEntity extends Entity implements ISystemConfigurationEntity {
    // #region REST Properties

    // EnhancedPtzOnAllNewUnit
    public get enhancedPtzOnAllNewUnit(): boolean {
        return this.getField<boolean>(SystemConfigurationEntityFields.enhancedPtzOnAllNewUnitField);
    }
    public set enhancedPtzOnAllNewUnit(value: boolean) {
        this.setField<boolean>(SystemConfigurationEntityFields.enhancedPtzOnAllNewUnitField, value);
    }

    // IsFirstRun
    public get isFirstRun(): boolean {
        return this.getField<boolean>(SystemConfigurationEntityFields.isFirstRunField);
    }
    public set isFirstRun(value: boolean) {
        this.setField<boolean>(SystemConfigurationEntityFields.isFirstRunField, value);
    }

    // MaxImageFileSizeInKB
    public get maxImageFileSizeInKB(): number {
        return this.getField<number>(SystemConfigurationEntityFields.maxImageFileSizeInKBField, ValidFlags.Specific);
    }
    public set maxImageFileSizeInKB(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.maxImageFileSizeInKBField, value);
    }

    // PasswordExpiryNotificationPeriod
    public get passwordExpiryNotificationPeriod(): number {
        return this.getField<number>(SystemConfigurationEntityFields.passwordExpiryNotificationPeriodField, ValidFlags.Specific);
    }
    public set passwordExpiryNotificationPeriod(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.passwordExpiryNotificationPeriodField, value);
    }

    // PasswordMinCharacter
    public get passwordMinCharacter(): number {
        return this.getField<number>(SystemConfigurationEntityFields.passwordMinCharacterField, ValidFlags.Specific);
    }
    public set passwordMinCharacter(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.passwordMinCharacterField, value);
    }

    // PasswordMinLowerCharacter
    public get passwordMinLowerCharacter(): number {
        return this.getField<number>(SystemConfigurationEntityFields.passwordMinLowerCharacterField, ValidFlags.Specific);
    }
    public set passwordMinLowerCharacter(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.passwordMinLowerCharacterField, value);
    }

    // PasswordMinNumericalCharacter
    public get passwordMinNumericalCharacter(): number {
        return this.getField<number>(SystemConfigurationEntityFields.passwordMinNumericalCharacterField, ValidFlags.Specific);
    }
    public set passwordMinNumericalCharacter(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.passwordMinNumericalCharacterField, value);
    }

    // PasswordMinSpecialCharacter
    public get passwordMinSpecialCharacter(): number {
        return this.getField<number>(SystemConfigurationEntityFields.passwordMinSpecialCharacterField, ValidFlags.Specific);
    }
    public set passwordMinSpecialCharacter(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.passwordMinSpecialCharacterField, value);
    }

    // PasswordMinUpperCharacter
    public get passwordMinUpperCharacter(): number {
        return this.getField<number>(SystemConfigurationEntityFields.passwordMinUpperCharacterField, ValidFlags.Specific);
    }
    public set passwordMinUpperCharacter(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.passwordMinUpperCharacterField, value);
    }

    // SecurityLevel
    public get securityLevel(): number {
        return this.getField<number>(SystemConfigurationEntityFields.securityLevelField, ValidFlags.Specific);
    }
    public set securityLevel(value: number) {
        this.setField<number>(SystemConfigurationEntityFields.securityLevelField, value);
    }

    // ThreatLevel
    public get threatLevel(): IGuid {
        return this.getFieldGuid(SystemConfigurationEntityFields.threatLevelField, ValidFlags.EntityStatus2);
    }
    public set threatLevel(value: IGuid) {
        this.setFieldGuid(SystemConfigurationEntityFields.threatLevelField, value);
    }

    // TotalActiveThreatLevelCount
    public get totalActiveThreatLevelCount(): number {
        return this.getField<number>(SystemConfigurationEntityFields.totalActiveThreatLevelCountField, ValidFlags.EntityStatus2);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.SystemConfigurations);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getActivityTrailsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IActivityTrailConfig> | null> {
        return this.getFieldObjectRelationAsync<ActivityTrailConfig, IActivityTrailConfig>(ActivityTrailConfig, 'ActivityTrails', 'Id', true, true, ValidFlags.None, transaction);
    }

    public async getCustomDataTypesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomFieldTypeDescriptor> | null> {
        return this.getFieldObjectRelationAsync<CustomFieldTypeDescriptor, ICustomFieldTypeDescriptor>(
            CustomFieldTypeDescriptor,
            'CustomDataTypes',
            'Id',
            false,
            true,
            ValidFlags.Specific,
            transaction,
        );
    }

    public async getCustomEntityTypeDescriptorsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomEntityTypeDescriptor> | null> {
        return this.getFieldObjectRelationAsync<CustomEntityTypeDescriptor, ICustomEntityTypeDescriptor>(
            CustomEntityTypeDescriptor,
            'CustomEntityTypeDescriptors',
            'Id',
            false,
            true,
            ValidFlags.MemberOf4,
            transaction,
        );
    }

    public async getCustomEventsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomEvent> | null> {
        return this.getFieldObjectRelationAsync<CustomEvent, ICustomEvent>(CustomEvent, 'CustomEvents', 'Id', false, false, ValidFlags.EntityDependency2, transaction);
    }

    public async getCustomFieldConfigAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomFieldConfig> | null> {
        return this.getFieldObjectRelationAsync<CustomFieldConfig, ICustomFieldConfig>(CustomFieldConfig, 'CustomFields', 'Id', false, true, ValidFlags.Membership3, transaction);
    }

    public async getDefaultSystemMapAsync(transaction?: ITransaction | null): Promise<IGuid | null> {
        const result = await this.getDataRelation2Async<string>('DefaultSystemMap', 'id', ValidFlags.None, transaction);
        if (result) {
            return SafeGuid.parse(result.toLowerCase());
        }
        return SafeGuid.EMPTY;
    }

    public async getEventsMetadataAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEventMetadata> | null> {
        return this.getFieldObjectRelationAsync<EventMetadata, IEventMetadata>(EventMetadata, 'EventsMetadata', 'Id', true, false, ValidFlags.MemberOf3, transaction);
    }

    public async frozenSessionTestAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) {
                    resolve(response);
                });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) {
                    reject(response);
                });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('FrozenSessionTest', 'POST', null, transaction, responseHandler);
    }

    public async isPrivilegeLicenseSupportedAsync(id: IGuid, transaction?: ITransaction): Promise<boolean | null> {
        const responseHandler = (response: IRestResponse): Promise<boolean> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body['Supported'] as boolean;
                return new Promise<boolean>(function (resolve, reject) {
                    resolve(result);
                });
            } else {
                return new Promise<boolean>(function (resolve, reject) {
                    reject(response);
                });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('id', id);

        return this.executeRequestTransactionAsync<boolean>('IsPrivilegeLicenseSupported', 'POST', inParam, transaction, responseHandler);
    }

    public async getLicenseDetailsAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'LicenseDetails', false, ValidFlags.None, transaction);
    }

    public async getLicenseUsageAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ILicenseUsage> | null> {
        return this.getFieldObjectRelationAsync<LicenseUsage, ILicenseUsage>(LicenseUsage, 'LicenseUsage', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async getLprAcceptReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IFieldObject>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IFieldObject>;
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    resolve(result);
                });
            } else {
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    reject(response);
                });
            }
        };
        return this.executeRequestTransactionAsync<Array<IFieldObject>>('LprAcceptReasons', 'GET', null, transaction, responseHandler);
    }

    public async getLprAnnotationFieldsDefinitionsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IFieldObject>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IFieldObject>;
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    resolve(result);
                });
            } else {
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    reject(response);
                });
            }
        };
        return this.executeRequestTransactionAsync<Array<IFieldObject>>('LprAnnotationFieldsDefinitions', 'GET', null, transaction, responseHandler);
    }

    public async getLprHotlistRejectReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IFieldObject>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IFieldObject>;
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    resolve(result);
                });
            } else {
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    reject(response);
                });
            }
        };
        return this.executeRequestTransactionAsync<Array<IFieldObject>>('LprHotlistRejectReasons', 'GET', null, transaction, responseHandler);
    }

    public async getLprOvertimetRejectReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IFieldObject>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IFieldObject>;
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    resolve(result);
                });
            } else {
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    reject(response);
                });
            }
        };
        return this.executeRequestTransactionAsync<Array<IFieldObject>>('LprOvertimeRejectReasons', 'GET', null, transaction, responseHandler);
    }

    public async getLprPermitRejectReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IFieldObject>> => {
            if (response.statusCode === HttpStatusCode.OK) {
                const result = response.body as Array<IFieldObject>;
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    resolve(result);
                });
            } else {
                return new Promise<Array<IFieldObject>>(function (resolve, reject) {
                    reject(response);
                });
            }
        };
        return this.executeRequestTransactionAsync<Array<IFieldObject>>('LprPermitRejectReasons', 'GET', null, transaction, responseHandler);
    }

    public async getMapProvidersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMapProvider> | null> {
        return this.getFieldObjectRelationAsync<MapProvider, IMapProvider>(MapProvider, 'MapProviders', 'Id', true, false, ValidFlags.MemberOf4, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter | null, transaction?: ITransaction | null): Promise<ObservableCollection<IEntity> | null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.EntityMembership, transaction);
    }

    public async getRootPartitionsAsync(query?: IQueryFilter | null, transaction?: ITransaction | null): Promise<ObservableCollection<IEntity> | null> {
        return this.getGuidRelationAsync('RootPartitions', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async sendActivityTrailsAsync(value: INewActivityTrail, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) {
                    resolve(response);
                });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) {
                    reject(response);
                });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('SendActivityTrails', 'POST', value, transaction, responseHandler);
    }

    // #endregion REST Methods
}
