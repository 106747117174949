<gen-item class="gen-item" [highlightColor]="event?.color | argb" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [ngStyle]="{ fontWeight: isNew ? 'bold' : 'normal' }">
    <gen-icon [icon]="event?.icon"></gen-icon>
    <gen-text *ngIf="event?.title">{{ event?.title }}</gen-text>
    <gen-text *ngIf="event?.description" [slot]="ItemSlot.TextSecondary">{{ event?.description }}</gen-text>
    <gen-text *ngIf="showEntityName && monitoredEntity?.name && monitoredEntity?.name !== event?.description" [slot]="ItemSlot.TextTertiary">
        {{ monitoredEntity?.name }}
    </gen-text>

    <gen-button
        [slot]="showDismiss ? ItemSlot.ContentRight : null"
        [hidden]="!showDismiss"
        [flavor]="ButtonFlavor.Flat"
        appTooltip="{{ 'STE_BUTTON_DISMISS' | translate }}"
        (click)="onDismiss(); $event.stopPropagation()"
    >
        <gen-icon [icon]="Icon.Close"></gen-icon>
    </gen-button>

    <ng-container>
        <gen-text [hidden]="showDismiss" [slot]="showDismiss ? null : ItemSlot.TextRightTop">{{ formattedDate ?? formatDate() }}</gen-text>
        <gen-text [hidden]="showDismiss" [slot]="showDismiss ? null : ItemSlot.TextRightBottom">{{ formattedTime ?? formatTime() }}</gen-text>
    </ng-container>
</gen-item>
