// Autogenerated: v2/help/Entities/ApiAccessControlUnit?codegen=ts&InterfaceOnly=True&workflow=False on 2021-05-11 9:36:04 AM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity } from './IEntity';
import { IUnitEntity } from './IUnitEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';
import { UnitEntityFields } from './IUnitEntity';

// #region Fields

export class AccessControlUnitEntityFields extends UnitEntityFields {
    public static extensionTypeField = 'ExtensionType';
}

// #endregion Fields

export interface IAccessControlUnitEntity extends IUnitEntity {
    // #region REST Properties

    extensionType: string;

    // #endregion REST Properties

    // #region REST Methods

    getDoorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getElevatorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getInterfaceModulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

