// Autogenerated: v2/help/Classes/ApiAlarmEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:12:10 AM
import { RoutableEvent } from './RoutableEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { FieldObject } from '../../Helpers/FieldObject';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IDynamicContentInfo, IAlarmEvent } from '../Interface/IAlarmEvent';

// #region Inner classes

export class DynamicContentInfo extends FieldObject implements IDynamicContentInfo {
    // AttachedEntities
    public get attachedEntities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('AttachedEntities');
    }
    public set attachedEntities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('AttachedEntities', value);
    }
    // Context
    public get context(): string {
        return this.getField<string>('Context');
    }
    public set context(value: string) {
        this.setField<string>('Context', value);
    }
    // ForwardedRecipients
    public get forwardedRecipients(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('ForwardedRecipients');
    }
    public set forwardedRecipients(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('ForwardedRecipients', value);
    }
    // Priority
    public get priority(): number {
        return this.getField<number>('Priority');
    }
    public set priority(value: number) {
        this.setField<number>('Priority', value);
    }
    // Urls
    public get urls(): ObservableCollection<string> {
        return this.getFieldArray<string>('Urls');
    }
    public set urls(value: ObservableCollection<string>) {
        this.setFieldArray<string>('Urls', value);
    }

    public initializeAllFields(): void {
        this.setFieldArrayGuid('AttachedEntities', new ObservableCollection<IGuid>());
        this.setField<string>('Context', '');
        this.setFieldArrayGuid('ForwardedRecipients', new ObservableCollection<IGuid>());
        this.setField<number>('Priority', 0);
        this.setFieldArray<string>('Urls', new ObservableCollection<string>());
    }
}

// #endregion Inner classes

export class AlarmEvent extends RoutableEvent implements IAlarmEvent {
    // #region REST Properties

    // DynamicContent
    public get dynamicContent(): IDynamicContentInfo {
        return this.getFieldObject<DynamicContentInfo, IDynamicContentInfo>(DynamicContentInfo, 'DynamicContent');
    }
    public set dynamicContent(value: IDynamicContentInfo) {
        this.setFieldObject<IDynamicContentInfo>('DynamicContent', value);
    }

    // InstanceId
    public get instanceId(): number {
        return this.getField<number>('InstanceId');
    }
    public set instanceId(value: number) {
        this.setField<number>('InstanceId', value);
    }

    // Priority
    public get priority(): number {
        return this.getField<number>('Priority');
    }
    public set priority(value: number) {
        this.setField<number>('Priority', value);
    }

    // PropagationLevel
    public get propagationLevel(): number {
        return this.getField<number>('PropagationLevel');
    }
    public set propagationLevel(value: number) {
        this.setField<number>('PropagationLevel', value);
    }

    // SourceConditionState
    public get sourceConditionState(): number {
        return this.getField<number>('SourceConditionState');
    }
    public set sourceConditionState(value: number) {
        this.setField<number>('SourceConditionState', value);
    }

    // SourceConditionType
    public get sourceConditionType(): string {
        return this.getField<string>('SourceConditionType');
    }
    public set sourceConditionType(value: string) {
        this.setField<string>('SourceConditionType', value);
    }

    // TriggerEntity
    public get triggerEntity(): IGuid {
        return this.getFieldGuid('TriggerEntity');
    }
    public set triggerEntity(value: IGuid) {
        this.setFieldGuid('TriggerEntity', value);
    }

    // TriggerEntityDesc
    public get triggerEntityDesc(): string {
        return this.getField<string>('TriggerEntityDesc');
    }
    public set triggerEntityDesc(value: string) {
        this.setField<string>('TriggerEntityDesc', value);
    }

    // TriggerEntityType
    public get triggerEntityType(): string {
        return this.getField<string>('TriggerEntityType');
    }
    public set triggerEntityType(value: string) {
        this.setField<string>('TriggerEntityType', value);
    }

    // TriggerEvent
    public get triggerEvent(): string {
        return this.getField<string>('TriggerEvent');
    }
    public set triggerEvent(value: string) {
        this.setField<string>('TriggerEvent', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        const objDynamicContent = new DynamicContentInfo();
        objDynamicContent.initializeAllFields();
        this.setFieldObject('DynamicContent', objDynamicContent);
        this.setField<number>('InstanceId', 0);
        this.setField<number>('Priority', 0);
        this.setField<number>('PropagationLevel', 0);
        this.setField<number>('SourceConditionState', 0);
        this.setField<string>('SourceConditionType', '');
        this.setFieldGuid('TriggerEntity', SafeGuid.EMPTY);
        this.setField<string>('TriggerEntityDesc', '');
        this.setField<string>('TriggerEntityType', '');
        this.setField<string>('TriggerEvent', '');
    }

    // #endregion Methods

}

