import { PlaySpeed } from '../PlaySpeed';
import { ISessionStateView } from './SessionState';
import { PlayerMode } from '../../enums';
import { StreamUsage } from './Api/CreateStream';
import { TimeLineRange } from '../../TimeLineRange';
import { Utils } from '../../utils/Utils';

export class DesiredStateStructure {
  public playerMode?: PlayerMode = undefined;
  public pause?: boolean = undefined;
  public playSpeed?: PlaySpeed = undefined;
  public seekTime?: Date = undefined;
  public audioEnabled?: boolean = undefined;
  public timelineRange?: TimeLineRange = undefined;
  public isPtzMode?: boolean = undefined;
  public streamUsage?: StreamUsage = undefined;

  public constructor(copy?: DesiredState) {
    if (copy === undefined) {
      return;
    }

    this.playerMode = copy.playerMode;
    this.pause = copy.pause;
    this.playSpeed = copy.playSpeed;
    this.audioEnabled = copy.audioEnabled;
    this.seekTime = copy.seekTime;
    this.isPtzMode = copy.isPtzMode;
    this.timelineRange = copy.timelineRange;
    this.streamUsage = copy.streamUsage;
  }
}

export class DesiredStateBuilder {
  private temp: DesiredStateStructure;

  constructor(copy?: DesiredState) {
    this.temp = new DesiredStateStructure(copy);
  }

  public playerMode(playerMode: PlayerMode): DesiredStateBuilder {
    this.temp.playerMode = playerMode;
    return this;
  }

  public pause(pause: boolean): DesiredStateBuilder {
    this.temp.pause = pause;
    return this;
  }

  public playSpeed(playSpeed: PlaySpeed): DesiredStateBuilder {
    this.temp.playSpeed = playSpeed;
    return this;
  }

  public seekTime(seekTime: Date | undefined): DesiredStateBuilder {
    this.temp.seekTime = seekTime;
    return this;
  }

  public audioEnabled(audioEnabled: boolean): DesiredStateBuilder {
    this.temp.audioEnabled = audioEnabled;
    return this;
  }

  public timelineRange(timelineRange: TimeLineRange | undefined): DesiredStateBuilder {
    this.temp.timelineRange = timelineRange;
    return this;
  }

  public ptzMode(isPtzMode: boolean): DesiredStateBuilder {
    this.temp.isPtzMode = isPtzMode;
    return this;
  }

  public streamUsage(streamUsage: StreamUsage): DesiredStateBuilder {
    this.temp.streamUsage = streamUsage;
    return this;
  }

  public static copy(from: DesiredState): DesiredStateBuilder {
    return new DesiredStateBuilder(from);
  }

  public build(): DesiredState {
    return new DesiredState(this.temp.playerMode, this.temp.pause, this.temp.playSpeed, this.temp.seekTime, this.temp.audioEnabled, this.temp.timelineRange, this.temp.isPtzMode, this.temp.streamUsage);
  }
}

export class DesiredState {
  public static readonly Empty: DesiredState = new DesiredState(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

  private readonly m_playerMode?: PlayerMode;

  private readonly m_pause?: boolean;

  private readonly m_playSpeed?: PlaySpeed;

  private readonly m_seekTime?: Date;

  private readonly m_audioEnabled?: boolean;

  private readonly m_timelineRange?: TimeLineRange;

  private readonly m_isPtzMode?: boolean;

  private readonly m_streamUsage?: StreamUsage;

  public get playerMode(): PlayerMode | undefined {
    return this.m_playerMode;
  }

  public get pause(): boolean | undefined {
    return this.m_pause;
  }

  public get playSpeed(): PlaySpeed | undefined {
    return this.m_playSpeed;
  }

  public get seekTime(): Date | undefined {
    return this.m_seekTime;
  }

  public get audioEnabled(): boolean | undefined {
    return this.m_audioEnabled;
  }

  public get timelineRange(): TimeLineRange | undefined {
    return this.m_timelineRange;
  }

  public get isPtzMode(): boolean | undefined {
    return this.m_isPtzMode;
  }

  public get streamUsage(): StreamUsage | undefined {
    return this.m_streamUsage;
  }

  public get isNoRequest(): boolean {
    return this.m_playerMode === undefined &&
      this.m_pause === undefined &&
      this.m_playSpeed === undefined &&
      this.m_seekTime === undefined &&
      this.m_audioEnabled === undefined &&
      this.m_timelineRange === undefined &&
      this.m_isPtzMode === undefined &&
      this.m_streamUsage === undefined;
  }

  constructor(playerMode: PlayerMode | undefined, pause: boolean | undefined, playSpeed: PlaySpeed | undefined, seekTime: Date | undefined,
    audioEnabled: boolean | undefined, timelineRange: TimeLineRange | undefined, ptzMode: boolean | undefined, streamUsage: StreamUsage | undefined) {
    this.m_playerMode = playerMode;
    this.m_pause = pause;
    this.m_playSpeed = playSpeed;
    this.m_seekTime = seekTime;
    this.m_audioEnabled = audioEnabled;
    this.m_timelineRange = timelineRange;
    this.m_isPtzMode = ptzMode;
    this.m_streamUsage = streamUsage;
  }

  public withPlayLive(): DesiredState | undefined {
    if (this.m_playerMode === PlayerMode.live &&
      this.m_pause === false &&
      this.m_playSpeed !== undefined &&
      this.m_playSpeed.Value === 1) {
      return undefined;
    }

    return DesiredStateBuilder.copy(this)
      .playerMode(PlayerMode.live)
      .pause(false)
      .playSpeed(PlaySpeed._1x)
      .seekTime(undefined)
      .build();
  }

  public withSeek(seekTime: Date): DesiredState {
    return DesiredStateBuilder.copy(this)
      .playerMode(PlayerMode.playback)
      .seekTime(seekTime)
      .ptzMode(false)
      .build();
  }

  public withPause(): DesiredState | undefined {
    if (this.m_pause === true) return undefined;
    return DesiredStateBuilder.copy(this)
      .pause(true)
      .playerMode(PlayerMode.playback)
      .ptzMode(false)
      .build();
  }

  public withResume(): DesiredState | undefined {
    if (this.m_pause === false) return undefined;
    return DesiredStateBuilder.copy(this).pause(false).build();
  }

  public withPlaySpeed(playSpeed: PlaySpeed): DesiredState | undefined {
    if (playSpeed.equals(this.m_playSpeed)) return undefined;
    return DesiredStateBuilder.copy(this).playSpeed(playSpeed).build();
  }

  public withAudioEnabled(isAudioEnabled: boolean): DesiredState | undefined {
    if (this.m_audioEnabled === isAudioEnabled) return undefined;
    return DesiredStateBuilder.copy(this).audioEnabled(isAudioEnabled).build();
  }

  public withPtzMode(isPtzModeEnabled: boolean): DesiredState | undefined {
    if (this.m_isPtzMode === isPtzModeEnabled) return undefined;
    return DesiredStateBuilder.copy(this).ptzMode(isPtzModeEnabled).build();
  }

  public withStreamUsage(streamUsage: StreamUsage): DesiredState | undefined {
    if (this.m_streamUsage === streamUsage) return undefined;
    return DesiredStateBuilder.copy(this).streamUsage(streamUsage).build();
  }

  public withTimelineRange(timelineRange?: TimeLineRange): DesiredState | undefined {
    if (this.m_timelineRange === timelineRange) return undefined;
    return DesiredStateBuilder.copy(this).timelineRange(timelineRange).build();
  }

  public clearTimelineRange(): DesiredState {
    if (this.m_timelineRange === undefined) return this;
    return DesiredStateBuilder.copy(this).timelineRange(undefined).build();
  }

  public getDifferences(serverState: ISessionStateView): StateDifferences {
    return DesiredState.getDifferences(this, serverState);
  }

  // Needed to extract that method as a static one. Otherwise, unit test don't pass. Still don't understand why up to now...
  public static getDifferences(desiredState: DesiredState, serverState: ISessionStateView): StateDifferences {
    let playerMode: PlayerMode | undefined;
    let playSpeed: PlaySpeed | undefined;
    let paused: boolean | undefined;
    let audioEnabled: boolean | undefined;
    let timelineRange: TimeLineRange | undefined;
    let isPtzMode: boolean | undefined;
    let streamUsage: StreamUsage | undefined;

    if (desiredState.playerMode !== undefined && desiredState.playerMode !== serverState.playerMode) {
      playerMode = desiredState.playerMode;
    }

    if (desiredState.playSpeed !== undefined && !desiredState.playSpeed.equals(serverState.playSpeed)) {
      playSpeed = desiredState.playSpeed;
    }

    if (desiredState.pause !== undefined && desiredState.pause !== serverState.isPaused) {
      paused = desiredState.pause;
    }

    if (desiredState.audioEnabled !== undefined && desiredState.audioEnabled !== serverState.isAudioEnabled) {
      audioEnabled = desiredState.audioEnabled;
    }

    if (desiredState.timelineRange !== undefined && desiredState.timelineRange !== serverState.timelineRange) {
      timelineRange = desiredState.timelineRange;
    }

    if (desiredState.isPtzMode !== undefined && desiredState.isPtzMode !== serverState.isPtzMode) {
      isPtzMode = desiredState.isPtzMode;
    }

    if (desiredState.streamUsage !== undefined && desiredState.streamUsage !== serverState.streamUsage) {
      streamUsage = desiredState.streamUsage;
    }

    return new StateDifferences(playerMode, playSpeed, paused, audioEnabled, desiredState.seekTime, timelineRange, isPtzMode, streamUsage);
  }

  public debugStatus(indent: number): string {
    if (this === DesiredState.Empty) {
      return 'DesiredState: empty';
    }

    return 'DesiredState' + Utils.indentNewLine(indent) +
      'Mode: ' + this.m_playerMode + Utils.indentNewLine(indent) +
      'Paused: ' + this.m_pause + Utils.indentNewLine(indent) +
      'Speed: ' + this.m_playSpeed + Utils.indentNewLine(indent) +
      'Last seek: ' + Utils.formatDate(this.m_seekTime) + Utils.indentNewLine(indent) +
      'Audio: ' + this.m_audioEnabled + Utils.indentNewLine(indent) +
      'Ptz mode: ' + this.m_isPtzMode + Utils.indentNewLine(indent) +
      'Stream usage: ' + this.m_streamUsage;
  }
}

export class StateDifferences {
  public playerMode: PlayerMode | undefined;

  public playSpeed: PlaySpeed | undefined;

  public isPaused: boolean | undefined;

  public audioEnabled: boolean | undefined;

  public seekTime: Date | undefined;

  public timelineRange: TimeLineRange | undefined;

  public isPtzMode: boolean | undefined;

  public streamUsage: StreamUsage | undefined;

  public get isResuming(): boolean {
    return this.isPaused === false;
  }

  public get noDifference(): boolean {
    return this.playerMode === undefined &&
      this.playSpeed === undefined &&
      this.isPaused === undefined &&
      this.audioEnabled === undefined &&
      this.seekTime === undefined &&
      this.timelineRange === undefined &&
      this.isPtzMode === undefined &&
      this.streamUsage === undefined;
  }

  constructor(playerMode: PlayerMode | undefined, playSpeed: PlaySpeed | undefined, isPaused: boolean | undefined, audioEnabled: boolean | undefined, seekTime: Date | undefined, timelineRange: TimeLineRange | undefined, isPtzMode: boolean | undefined, streamUsage: StreamUsage | undefined) {
    this.playerMode = playerMode;
    this.playSpeed = playSpeed;
    this.isPaused = isPaused;
    this.audioEnabled = audioEnabled;
    this.seekTime = seekTime;
    this.timelineRange = timelineRange;
    this.isPtzMode = isPtzMode;
    this.streamUsage = streamUsage;
  }
}
