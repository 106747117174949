// Autogenerated: v2/help/Entities/ApiActiveDirectoryRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:03 AM
import { RoleEntity } from './RoleEntity';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IActiveDirectoryRoleEntity, IImportedGroup, ActiveDirectoryRoleEntityFields } from '../../Interface/IActiveDirectoryRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class ImportedGroup extends FieldObject implements IImportedGroup {
    // GroupAccountGuid
    public get groupAccountGuid(): IGuid {
        return this.getFieldGuid('GroupAccountGuid');
    }
    public set groupAccountGuid(value: IGuid) {
        this.setFieldGuid('GroupAccountGuid', value);
    }
    // GroupAccountName
    public get groupAccountName(): string {
        return this.getField<string>('GroupAccountName');
    }
    public set groupAccountName(value: string) {
        this.setField<string>('GroupAccountName', value);
    }
    // SynchronizeCardholders
    public get synchronizeCardholders(): boolean | null {
        return this.getNullableField<boolean>('SynchronizeCardholders');
    }
    public set synchronizeCardholders(value: boolean | null) {
        this.setNullableField<boolean>('SynchronizeCardholders', value);
    }
    // SynchronizeCredentials
    public get synchronizeCredentials(): boolean | null {
        return this.getNullableField<boolean>('SynchronizeCredentials');
    }
    public set synchronizeCredentials(value: boolean | null) {
        this.setNullableField<boolean>('SynchronizeCredentials', value);
    }
    // SynchronizeUserGroup
    public get synchronizeUserGroup(): boolean | null {
        return this.getNullableField<boolean>('SynchronizeUserGroup');
    }
    public set synchronizeUserGroup(value: boolean | null) {
        this.setNullableField<boolean>('SynchronizeUserGroup', value);
    }
    // SynchronizeUsers
    public get synchronizeUsers(): boolean | null {
        return this.getNullableField<boolean>('SynchronizeUsers');
    }
    public set synchronizeUsers(value: boolean | null) {
        this.setNullableField<boolean>('SynchronizeUsers', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('GroupAccountGuid', SafeGuid.EMPTY);
        this.setField<string>('GroupAccountName', '');
        this.setField<boolean>('SynchronizeCardholders', false);
        this.setField<boolean>('SynchronizeCredentials', false);
        this.setField<boolean>('SynchronizeUserGroup', false);
        this.setField<boolean>('SynchronizeUsers', false);
    }
}

// #endregion Inner classes

export class ActiveDirectoryRoleEntity extends RoleEntity implements IActiveDirectoryRoleEntity {
    // #region REST Properties

    // ConnectionStatus
    public get connectionStatus(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.connectionStatusField);
    }

    // DC
    public get dC(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.dCField);
    }
    public set dC(value: string) {
        this.setField<string>(ActiveDirectoryRoleEntityFields.dCField, value);
    }

    // DefaultBadgeTemplate
    public get defaultBadgeTemplate(): IGuid {
        return this.getFieldGuid(ActiveDirectoryRoleEntityFields.defaultBadgeTemplateField);
    }
    public set defaultBadgeTemplate(value: IGuid) {
        this.setFieldGuid(ActiveDirectoryRoleEntityFields.defaultBadgeTemplateField, value);
    }

    // DefaultCardFormat
    public get defaultCardFormat(): IGuid {
        return this.getFieldGuid(ActiveDirectoryRoleEntityFields.defaultCardFormatField);
    }
    public set defaultCardFormat(value: IGuid) {
        this.setFieldGuid(ActiveDirectoryRoleEntityFields.defaultCardFormatField, value);
    }

    // Domain
    public get domain(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.domainField);
    }
    public set domain(value: string) {
        this.setField<string>(ActiveDirectoryRoleEntityFields.domainField, value);
    }

    // ExternalAddress
    public get externalAddress(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.externalAddressField);
    }
    public set externalAddress(value: string) {
        this.setField<string>(ActiveDirectoryRoleEntityFields.externalAddressField, value);
    }

    // MaxPictureSize
    public get maxPictureSize(): number {
        return this.getField<number>(ActiveDirectoryRoleEntityFields.maxPictureSizeField);
    }
    public set maxPictureSize(value: number) {
        this.setField<number>(ActiveDirectoryRoleEntityFields.maxPictureSizeField, value);
    }

    // MaxPictureSizeLimit
    public get maxPictureSizeLimit(): number {
        return this.getField<number>(ActiveDirectoryRoleEntityFields.maxPictureSizeLimitField);
    }
    public set maxPictureSizeLimit(value: number) {
        this.setField<number>(ActiveDirectoryRoleEntityFields.maxPictureSizeLimitField, value);
    }

    // NetBiosName
    public get netBiosName(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.netBiosNameField);
    }
    public set netBiosName(value: string) {
        this.setField<string>(ActiveDirectoryRoleEntityFields.netBiosNameField, value);
    }

    // PartitionGuid
    public get partitionGuid(): IGuid {
        return this.getFieldGuid(ActiveDirectoryRoleEntityFields.partitionGuidField);
    }
    public set partitionGuid(value: IGuid) {
        this.setFieldGuid(ActiveDirectoryRoleEntityFields.partitionGuidField, value);
    }

    // Password
    public get password(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.passwordField);
    }
    public set password(value: string) {
        this.setField<string>(ActiveDirectoryRoleEntityFields.passwordField, value);
    }

    // Status
    public get status(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.statusField);
    }

    // UploadPicturesToActiveDirectory
    public get uploadPicturesToActiveDirectory(): boolean {
        return this.getField<boolean>(ActiveDirectoryRoleEntityFields.uploadPicturesToActiveDirectoryField);
    }
    public set uploadPicturesToActiveDirectory(value: boolean) {
        this.setField<boolean>(ActiveDirectoryRoleEntityFields.uploadPicturesToActiveDirectoryField, value);
    }

    // UseApplicationCredentials
    public get useApplicationCredentials(): boolean {
        return this.getField<boolean>(ActiveDirectoryRoleEntityFields.useApplicationCredentialsField);
    }
    public set useApplicationCredentials(value: boolean) {
        this.setField<boolean>(ActiveDirectoryRoleEntityFields.useApplicationCredentialsField, value);
    }

    // UseDC
    public get useDC(): boolean {
        return this.getField<boolean>(ActiveDirectoryRoleEntityFields.useDCField);
    }
    public set useDC(value: boolean) {
        this.setField<boolean>(ActiveDirectoryRoleEntityFields.useDCField, value);
    }

    // UseGlobalCatalog
    public get useGlobalCatalog(): boolean {
        return this.getField<boolean>(ActiveDirectoryRoleEntityFields.useGlobalCatalogField);
    }
    public set useGlobalCatalog(value: boolean) {
        this.setField<boolean>(ActiveDirectoryRoleEntityFields.useGlobalCatalogField, value);
    }

    // Username
    public get username(): string {
        return this.getField<string>(ActiveDirectoryRoleEntityFields.usernameField);
    }
    public set username(value: string) {
        this.setField<string>(ActiveDirectoryRoleEntityFields.usernameField, value);
    }

    // UseSSLConnection
    public get useSSLConnection(): boolean {
        return this.getField<boolean>(ActiveDirectoryRoleEntityFields.useSSLConnectionField);
    }
    public set useSSLConnection(value: boolean) {
        this.setField<boolean>(ActiveDirectoryRoleEntityFields.useSSLConnectionField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getImportedGroupsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IImportedGroup> | null> {
        return this.getFieldObjectRelationAsync<ImportedGroup, IImportedGroup>(ImportedGroup, 'ImportedGroups', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async triggerSynchronizationAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('TriggerSynchronization', 'POST', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

