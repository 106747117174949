// Autogenerated: v2/help/Entities/ApiMobileApplication?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { ApplicationEntity } from './ApplicationEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IMobileApplicationEntity, MobileApplicationEntityFields } from '../../Interface/IMobileApplicationEntity';

export class MobileApplicationEntity extends ApplicationEntity implements IMobileApplicationEntity {
    // #region REST Properties

    // Altitude
    public get altitude(): number {
        return this.getField<number>(MobileApplicationEntityFields.altitudeField, ValidFlags.EntityStatus3);
    }
    public set altitude(value: number) {
        this.setField<number>(MobileApplicationEntityFields.altitudeField, value);
    }

    // Heading
    public get heading(): number {
        return this.getField<number>(MobileApplicationEntityFields.headingField, ValidFlags.EntityStatus3);
    }
    public set heading(value: number) {
        this.setField<number>(MobileApplicationEntityFields.headingField, value);
    }

    // IsBlackListed
    public get isBlackListed(): boolean {
        return this.getField<boolean>(MobileApplicationEntityFields.isBlackListedField, ValidFlags.Specific);
    }
    public set isBlackListed(value: boolean) {
        this.setField<boolean>(MobileApplicationEntityFields.isBlackListedField, value);
    }

    // Latitude
    public get latitude(): number {
        return this.getField<number>(MobileApplicationEntityFields.latitudeField, ValidFlags.EntityStatus3);
    }
    public set latitude(value: number) {
        this.setField<number>(MobileApplicationEntityFields.latitudeField, value);
    }

    // Longitude
    public get longitude(): number {
        return this.getField<number>(MobileApplicationEntityFields.longitudeField, ValidFlags.EntityStatus3);
    }
    public set longitude(value: number) {
        this.setField<number>(MobileApplicationEntityFields.longitudeField, value);
    }

    // MobileVideoUnitId
    public get mobileVideoUnitId(): IGuid {
        return this.getFieldGuid(MobileApplicationEntityFields.mobileVideoUnitIdField, ValidFlags.Specific);
    }
    public set mobileVideoUnitId(value: IGuid) {
        this.setFieldGuid(MobileApplicationEntityFields.mobileVideoUnitIdField, value);
    }

    // OS
    public get oS(): string {
        return this.getField<string>(MobileApplicationEntityFields.oSField, ValidFlags.Specific);
    }
    public set oS(value: string) {
        this.setField<string>(MobileApplicationEntityFields.oSField, value);
    }

    // Speed
    public get speed(): number {
        return this.getField<number>(MobileApplicationEntityFields.speedField, ValidFlags.EntityStatus3);
    }
    public set speed(value: number) {
        this.setField<number>(MobileApplicationEntityFields.speedField, value);
    }

    // TimeZoneId
    public get timeZoneId(): string {
        return this.getField<string>(MobileApplicationEntityFields.timeZoneIdField, ValidFlags.Specific);
    }
    public set timeZoneId(value: string) {
        this.setField<string>(MobileApplicationEntityFields.timeZoneIdField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

