import { Directive, ElementRef, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ResizeSensor } from 'css-element-queries';
import { asyncScheduler, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { ResizeWidthEvent } from './interfaces/resize-width-event';

// Directive that outputs an event when the element's width changes
@UntilDestroy()
@Directive({
    selector: '[appResizeWidth]',
})
export class ResizeWidthDirective implements OnInit, OnDestroy {
    @Output() public appResizeWidth = new EventEmitter<ResizeWidthEvent>();

    public throttler: Subject<ResizeWidthEvent> = new Subject<ResizeWidthEvent>();

    private oldWidth = -1;
    private resizeSensor?: ResizeSensor;
    constructor(public elementRef: ElementRef<Element>) {
        const throttleConfig = {
            leading: false,
            trailing: true,
        };
        this.throttler.pipe(throttleTime(400, asyncScheduler, throttleConfig), untilDestroyed(this)).subscribe((value) => this.appResizeWidth.emit(value));
    }

    ngOnInit(): void {
        if (ResizeSensor) {
            this.resizeSensor = new ResizeSensor(this.elementRef.nativeElement, () => this.onResized());
        }
    }

    ngOnDestroy(): void {
        if (this.resizeSensor) {
            this.resizeSensor.detach();
        }
    }

    private onResized(): void {
        const newWidth = this.elementRef.nativeElement.clientWidth;

        if (newWidth === this.oldWidth) {
            return;
        }

        const resizeWidthEvent: ResizeWidthEvent = {
            oldWidth: this.oldWidth,
            newWidth,
        };

        this.oldWidth = newWidth;

        this.throttler.next(resizeWidthEvent);
    }
}
