import { Injectable } from '@angular/core';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { toError } from '@src/app/utilities';
import { CardholderEntityFields, ICardholderEntity } from 'RestClient/Client/Interface/ICardholderEntity';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { CardholderEntity } from 'RestClient/Client/Model/AccessControl/CardholderEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { QueryFilter } from 'RestClient/Client/Queries/QueryFilter';
import { Observable, from, of, Subject } from 'rxjs';
import { catchError, first, map, toArray, mergeMap } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { AccessPointRuleEntity } from 'RestClient/Client/Model/AccessControl/AccessPointRuleEntity';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { CredentialEntityFields, ICredentialEntity } from 'RestClient/Client/Interface/ICredentialEntity';
import { CardholderModel } from '../cardholder-edit-context.state';
import { CardholderPrivileges, EntityPrivilege, EvaluatedCardholderPrivileges } from '../interfaces';

const cardholderMonitoredFields = [
    CardholderEntityFields.firstNameField,
    CardholderEntityFields.lastNameField,
    CardholderEntityFields.lastAccessField,
    CardholderEntityFields.hasPictureField,
    CardholderEntityFields.pictureIdField,
    CardholderEntityFields.mobilePhoneNumberField,
    CardholderEntityFields.emailAddressField,
    CardholderEntityFields.accessStatusField,
    CardholderEntityFields.activationDateField,
    CardholderEntityFields.expirationDateField,
    CardholderEntityFields.expirationModeField,
    CardholderEntityFields.expirationDurationField,
    CardholderEntityFields.canEscortField,
    CardholderEntityFields.descriptionField,
    CardholderEntityFields.logicalIdField,
    CardholderEntityFields.antipassbackExemptionField,
    CardholderEntityFields.antipassbackExemptionIsInheritedField,
    CardholderEntityFields.accessPermissionLevelField,
    CardholderEntityFields.useExtendedGrantTimeField,
    CardholderEntityFields.inheritAccessPermissionLevelFromGroupField,
    EntityFields.nameField,
    EntityFields.isDeletableField,
    EntityFields.isFederatedField,
    EntityFields.isImportedFromExternalSystemField,
    EntityFields.isReadOnlyField,
];

export interface CardholderRelations {
    credentials: CardholderRelationEntityModel[] | null;
    cardholderGroups: CardholderRelationEntityModel[] | null;
    accessRules: CardholderRelationEntityModel[] | null;
}

export interface CardholderRelationEntityModel {
    id: IGuid;
    isDeletable: boolean;
    data: Record<string, string>;
}

export enum CardholderRelationType {
    CardholderGroups = 'CardholderGroups',
    AccessRules = 'AccessRules',
    Credentials = 'Credentials',
    Partitions = 'Partitions',
}

@Injectable()
export class CardholderEditService {
    public static readonly excludedCardholdersGroupIds: IGuid[] = [Guids.AllCardholders];
    public static readonly excludedAccessRulesIds: IGuid[] = [Guids.AllOpenRule, Guids.LockdownRule];

    public refreshCardholder$: Observable<void>;

    private scClient: WebAppClient;
    private refreshCardholderSubject: Subject<void>;

    constructor(securityCenterClientService: SecurityCenterClientService, private loggerService: LoggerService) {
        this.scClient = securityCenterClientService.scClient;

        this.refreshCardholderSubject = new Subject();
        this.refreshCardholder$ = this.refreshCardholderSubject.asObservable();
    }

    public async fetchRelationEntitiesAsync(ids: IGuid[], cardholderId?: IGuid, relationEntityType?: EntityTypes): Promise<CardholderRelationEntityModel[]> {
        if (ids.length === 0) {
            return [];
        }

        const query = new EntityQuery();
        query.guids = new Set(ids);
        query.fields = new Set([EntityFields.idField, EntityFields.nameField, EntityFields.descriptionField, CredentialEntityFields.accessStatusField]);

        const entities: Entity[] = await this.scClient.getEntitiesAsync(relationEntityType === EntityTypes.AccessRules ? AccessPointRuleEntity : Entity, query);

        // Gets whether each relation entity is inherited or not
        // In general, inherited relations from groups cannot be removed directly from cardholder (like Access Rules)
        const inheritedStates = await this.retrieveRelationInheritanceFromCardholder(entities, relationEntityType, cardholderId);

        return entities.map((entity) => this.toCardholderRelationEntityModel(entity, inheritedStates[entity.id.toString()]));
    }

    public fetchCardholder(cardholderId: IGuid): Observable<CardholderModel | null> {
        return from(this.scClient.getEntityAsync<CardholderEntity, ICardholderEntity>(CardholderEntity, cardholderId, null, null, ...cardholderMonitoredFields)).pipe(
            first(),
            map((cardholderEntity) => (cardholderEntity ? this.toSimpleModel(cardholderEntity) : null)),
            catchError(this.handleRequestError.bind(this))
        );
    }

    public fetchGrantedPrivileges(cardholderGuid: IGuid): Observable<EvaluatedCardholderPrivileges> {
        const userEntityObservable = from(this.scClient.getEntityAsync(UserEntity, this.scClient.userId));

        // Call isPrivilegeGrantedAsync on the user entity for each cardholder privileges we are interesting in then
        // create evaluated privileges object.
        return userEntityObservable.pipe(
            mergeMap((userEntity) =>
                from(Object.entries(CardholderPrivileges)).pipe(
                    mergeMap(([privilegeKey, entityPrivilege]) => this.isPrivilegeGrantedAsync(userEntity, cardholderGuid, privilegeKey, entityPrivilege)),
                    toArray(),
                    map((grantedPrivileges) => {
                        // Assign each privilege key to their evaluated value.
                        return grantedPrivileges.reduce((privileges: Partial<EvaluatedCardholderPrivileges>, current: { key: string; granted: boolean }) => {
                            privileges[current.key as keyof EvaluatedCardholderPrivileges] = current.granted;
                            return privileges;
                        }, {}) as EvaluatedCardholderPrivileges;
                    })
                )
            )
        );
    }

    public async fetchCardholderRelationIdsAsync(cardholderId: IGuid | undefined, entityType: CardholderRelationType): Promise<IGuid[] | null> {
        // If cardholders is not defined, then return default relations entities ids
        if (!cardholderId) return this.getDefaultRelationsEntities(entityType).map((x) => x.id);

        const cardholderEntity = await this.scClient.getEntityAsync<CardholderEntity, ICardholderEntity>(
            CardholderEntity,
            cardholderId,
            null,
            null,
            CardholderEntityFields.logicalIdField
        );

        if (cardholderEntity === null) {
            return null;
        }

        const query = new QueryFilter(EntityFields.idField);
        let entities: ObservableCollection<IEntity> | null = null;
        switch (entityType) {
            case CardholderRelationType.CardholderGroups:
                entities = await cardholderEntity.getCardholdergroupsAsync(query);
                break;
            case CardholderRelationType.Credentials:
                entities = await cardholderEntity.getCredentialsAsync(query);
                break;
            case CardholderRelationType.AccessRules:
                entities = await cardholderEntity.getAccessPointRulesAsync(query);
                break;
            case CardholderRelationType.Partitions:
                entities = await cardholderEntity.getPartitionsAsync(query);
                break;
            default:
                return null;
        }

        return entities?.values().map((entity) => entity.id) ?? [];
    }

    public loadCardholders(): void {
        this.refreshCardholderSubject.next();
    }

    private getDefaultRelationsEntities(type?: CardholderRelationType): CardholderRelationEntityModel[] {
        switch (type) {
            case CardholderRelationType.AccessRules:
                return CardholderEditService.excludedAccessRulesIds.map(
                    (id) =>
                        ({
                            id,
                            isDeletable: false,
                        } as CardholderRelationEntityModel)
                );
            case CardholderRelationType.CardholderGroups:
            case CardholderRelationType.Credentials:
            case CardholderRelationType.Partitions:
            default:
                return [];
        }
    }

    private isPrivilegeGrantedAsync(userEntity: IUserEntity | null, cardholderGuid: IGuid, key: string, privilege: EntityPrivilege): Observable<{ key: string; granted: boolean }> {
        return userEntity
            ? (privilege.isGlobal ? of(this.scClient.isGlobalPrivilegeGranted(privilege.id)) : from(userEntity.isPrivilegeGrantedAsync(privilege.id, cardholderGuid))).pipe(
                  map((granted) => ({ key, granted: granted ?? false }))
              )
            : of({ key, granted: false });
    }

    private async retrieveRelationInheritanceFromCardholder(entities: Entity[], relationEntityType?: EntityTypes, cardholderId?: IGuid): Promise<Record<string, boolean>> {
        const inheritedStates: Record<string, boolean> = {};

        // Only AccessRules can be inherited from Cardholder Group
        if (relationEntityType === EntityTypes.AccessRules) {
            if (cardholderId) {
                const query = new EntityQuery();
                query.fields = new Set([EntityFields.idField, EntityFields.nameField, EntityFields.descriptionField]);

                const cardholderEntity = await this.scClient.getEntityAsync<CardholderEntity, ICardholderEntity>(
                    CardholderEntity,
                    cardholderId,
                    null,
                    null,
                    CardholderEntityFields.logicalIdField
                );

                // Get AccessRules already saved on the cardholder
                const savedAccessRules = await cardholderEntity?.getAccessPointRulesAsync(query);

                if (savedAccessRules) {
                    await Promise.all(
                        savedAccessRules.values().map(async (rule) => {
                            const associatedCardholders = await (rule as AccessPointRuleEntity).getCardholdersAsync(query);

                            // Checks that this rule isn't directly associated to currently edited cardholder (indirect = inherited)
                            inheritedStates[rule.id.toString()] = !!(
                                associatedCardholders &&
                                associatedCardholders.size > 0 &&
                                !associatedCardholders.any((cardholder) => cardholder.id.equals(cardholderId))
                            );
                        })
                    );
                }
            } else {
                // If cardholder is undefined we should load default rules
                const defaultRules = this.getDefaultRelationsEntities(CardholderRelationType.AccessRules);
                entities.forEach((entity) => {
                    let isInherited = false;
                    const associatedDefaultRule = defaultRules.find((rule) => rule.id.equals(entity.id));
                    if (associatedDefaultRule) {
                        isInherited = !associatedDefaultRule.isDeletable;
                    }
                    inheritedStates[entity.id.toString()] = isInherited;
                });
            }
        }

        return inheritedStates;
    }

    private handleRequestError(error: any): Observable<null> {
        this.loggerService.traceError(toError(error));
        return of(null);
    }

    private toCardholderRelationEntityModel(entity: IEntity, isInherited = false): CardholderRelationEntityModel {
        const commonModel = {
            id: entity.id,
            isDeletable: !isInherited,
        };
        switch (entity.entityType) {
            case EntityTypes.Credentials:
                return {
                    ...commonModel,
                    data: {
                        name: entity.name,
                        accessStatus: (entity as ICredentialEntity).accessStatus,
                    },
                };
            default:
                return {
                    ...commonModel,
                    data: {
                        name: entity.name,
                        description: entity.description,
                    },
                };
        }
    }

    private toSimpleModel(cardholderEntity: ICardholderEntity): CardholderModel {
        return {
            id: cardholderEntity.id,
            firstName: cardholderEntity.firstName,
            lastName: cardholderEntity.lastName,
            lastAccess: cardholderEntity.lastAccess,
            hasPicture: cardholderEntity.hasPicture,
            pictureId: cardholderEntity.pictureId,
            mobilePhoneNumber: cardholderEntity.mobilePhoneNumber,
            emailAddress: cardholderEntity.emailAddress,
            accessStatus: cardholderEntity.accessStatus,
            activationDate: cardholderEntity.activationDate,
            expirationDate: cardholderEntity.expirationDate,
            expirationMode: cardholderEntity.expirationMode,
            expirationDuration: cardholderEntity.expirationDuration,
            canEscort: cardholderEntity.canEscort,
            description: cardholderEntity.description,
            logicalId: cardholderEntity.logicalId,
            antipassbackExemption: cardholderEntity.antipassbackExemption,
            antipassbackExemptionIsInherited: cardholderEntity.antipassbackExemptionIsInherited,
            accessPermissionLevel: cardholderEntity.accessPermissionLevel,
            name: cardholderEntity.name,
            useExtendedGrantTime: cardholderEntity.useExtendedGrantTime,
            inheritAccessPermissionLevelFromGroup: cardholderEntity.inheritAccessPermissionLevelFromGroup,
            isDeletable: cardholderEntity.isDeletable,
            readonly: cardholderEntity.isFederated || cardholderEntity.isImportedFromExternalSystem || cardholderEntity.isReadOnly,
        };
    }
}
