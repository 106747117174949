import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ButtonFlavor, Icon } from '@genetec/gelato';
import { SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { Observable } from 'rxjs';
import { SIDE_CONTEXT_SERVICE, ContentService } from '../../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '../../../shared/interfaces/plugins/public/plugin-types';
import { InternalPluginDescriptor } from '../../../shared/interfaces/plugins/internal/plugin-internal.interface';
import { AlarmContentTypes } from '../../enumerations/alarm-content-types';
import { AlarmsService } from '../../services/alarms.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-alarms-tray',
    templateUrl: './alarms-tray.component.html',
    styleUrls: ['./alarms-tray.component.scss'],
})
@InternalPluginDescriptor({
    type: AlarmsTrayComponent,
    pluginTypes: [PluginTypes.Tray],
    exposure: { id: AlarmsTrayComponent.pluginId },
    isSupported: () => true,
    requirements: { features: [KnownFeatures.alarmsId], globalPrivileges: [KnownPrivileges.allowAlarmManagementPrivilege] },
})
export class AlarmsTrayComponent extends TrackedComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('A77326F6-C6BC-4A7F-82B7-E0192E2F6A27');

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;

    public instanceCount$: Observable<number>;

    constructor(
        @Inject(SIDE_CONTEXT_SERVICE) private sideContextService: ContentService,
        private contentProviderService: ContentProviderService,
        trackingService: TrackingService,
        private alarmsService: AlarmsService
    ) {
        super(trackingService);
        this.instanceCount$ = this.alarmsService.activeAlarmCountUpdated$;
    }

    public async showMonitoredAlarms(): Promise<void> {
        const currentContent = this.sideContextService.currentContent;
        if (!currentContent?.mainContent.type.equals(AlarmContentTypes.AlarmList)) {
            const alarmListRequest = new FieldObject();
            alarmListRequest.setField('AlarmListRequest', '');
            const content = await this.contentProviderService.getContentFromFieldsAsync(alarmListRequest);
            if (content) {
                this.sideContextService.setMainContent(content);
            }
        } else {
            this.sideContextService.clearMainContent();
        }
    }
}
