import { IWebSocketRequest, IWebSocketResponse } from '../players/WebSocket/Messages';
import { Utils } from '../utils/Utils';

export class InFlightQueries {
  private readonly m_map = new Map<number, InFlightQuery>();

  public add(channel: number, inFlightQuery: InFlightQuery) {
    if (this.m_map.has(channel)) {
      throw new Error(`A query is already in flight for channel ${channel}: ${this.m_map.get(channel)?.debugStatus(0)}`);
    }

    this.m_map.set(channel, inFlightQuery);
  }

  public complete(channel: number, response: IWebSocketResponse): boolean {
    const query = this.pop(channel);
    if (query !== undefined) {
      query?.complete(response);
      return true;
    }

    return false;
  }

  public fail(channel: number, reason: string) {
    const query = this.pop(channel);
    if (query !== undefined) {
      query?.fail(reason);
      return true;
    }

    return false;
  }

  public failAll(reason: string) {
    this.m_map.forEach((query: InFlightQuery, _) => {
      query.fail(reason);
    });
    this.m_map.clear();
  }

  private pop(channel: number): InFlightQuery | undefined {
    const query = this.m_map.get(channel);

    if (query !== undefined) {
      this.m_map.delete(channel);
    }

    return query;
  }

  public debugStatus(indent: number): string {
    if (this.m_map.size === 0) {
      return 'InFlightQueries: none';
    }

    let debugStatus = 'InFlightQueries: ' + this.m_map.size + Utils.indentNewLine(indent);
    this.m_map.forEach((inFlightQuery, channelId, _map) => {
      debugStatus += 'Channel: ' + channelId + Utils.indentNewLine(indent);
      debugStatus += inFlightQuery.debugStatus(indent + Utils.Indentation);
    });
    return debugStatus;
  }
}

export class InFlightQuery {
  private readonly m_creation: Date;
  private readonly m_request: IWebSocketRequest;

  private readonly success: (value: IWebSocketResponse) => void;

  private readonly failure: (reason: string) => void;

  constructor(success: (value: IWebSocketResponse) => void, failure: (reason: string) => void, request: IWebSocketRequest) {
    this.m_creation = new Date();
    this.success = success;
    this.failure = failure;
    this.m_request = request;
  }

  public complete(response: IWebSocketResponse) {
    this.success(response);
  }

  public fail(reason: string) {
    this.failure(reason);
  }

  public debugStatus(indent: number): string {
    return 'InFlightQuery' + Utils.indentNewLine(indent) +
      'Created: ' + Utils.formatDate(this.m_creation) + `(${Utils.spanBetweenDates(this.m_creation, new Date()).toString()} ago)` + Utils.indentNewLine(indent) +
      this.m_request.debugStatus(indent + Utils.Indentation);
  }
}
