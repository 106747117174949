<app-filter-host [filterDescriptor]="descriptor" [state]="state" [popupPosition]="popupPosition">
    <ul class="p-2">
        <li>
            <gen-radio [name]="descriptor.filterId + '-radio'" [value]="FilterOwnerMode.Any" [ngModel]="mode" (ngModelChange)="onFilterChange($event)">
                <gen-label>{{ 'STE_LABEL_ANY_OWNER' | translate }}</gen-label>
            </gen-radio>
        </li>
        <li>
            <gen-radio [name]="descriptor.filterId + '-radio'" [value]="FilterOwnerMode.OwnedByMe" [ngModel]="mode" (ngModelChange)="onFilterChange($event)">
                <gen-label>{{ 'STE_LABEL_INCIDENTOWNEDBYME' | translate }}</gen-label>
            </gen-radio>
        </li>
        <li>
            <gen-radio [name]="descriptor.filterId + '-radio'" [value]="FilterOwnerMode.User" [ngModel]="mode" (ngModelChange)="onFilterChange($event)">
                <gen-label>{{ 'STE_LABEL_SPECIFICOWNER' | translate }}</gen-label>
            </gen-radio>
            <div [hidden]="mode !== FilterOwnerMode.User" class="range-filter-container">
                <app-entity-combo
                    #entityCombo
                    [entityIds]="users"
                    (entityIdsChange)="onUserChange($event)"
                    name="ownerUsers"
                    genLabel="{{ 'STE_ENTITY_USERS' | translate }}"
                    [multiSelect]="true"
                    [entityBrowserFilter]="ownerBrowserFilter"
                ></app-entity-combo>
            </div>
        </li>
    </ul>
</app-filter-host>
