// Autogenerated: v2/help/Classes/ApiTimezoneEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-12 06:43:50
import { RoutableEvent } from './RoutableEvent';
import { ITimezoneEvent } from '../Interface/ITimezoneEvent';

export class TimezoneEvent extends RoutableEvent implements ITimezoneEvent {
    // #region REST Properties

    // SourceTimeZoneId
    public get sourceTimeZoneId(): string {
        return this.getField<string>('SourceTimeZoneId');
    }
    public set sourceTimeZoneId(value: string) {
        this.setField<string>('SourceTimeZoneId', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('SourceTimeZoneId', '');
    }

    // #endregion Methods

}

