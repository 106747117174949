import { Component, Inject, OnInit } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { TextFlavor } from '@genetec/gelato';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';

@Component({
    selector: 'app-maps-options',
    templateUrl: './maps-options.component.html',
})
@InternalPluginDescriptor({
    type: MapOptionsComponent,
    pluginTypes: [PluginTypes.Option],
    exposure: {
        id: MapOptionsComponent.pluginId,
        subSection: OptionSubSections.Map,
    },
    isSupported: () => true,
})
export class MapOptionsComponent extends TrackedComponent implements OnInit {
    public static readonly pluginId = SafeGuid.parse('81A65627-16A8-6E15-4025-F62C2145FC6B');

    public static readonly disableMinimapSettingId = 'DisableMinimap';
    public static readonly showOfflineMobileUsersId = 'ShowOfflineMobileUsers';

    public isMiniMapDisabled = false;
    public isShowingOfflineMobileUsers = false;
    public isInitialized = false;
    public isContentVisible = false;

    public readonly TextFlavor = TextFlavor;

    constructor(trackingService: TrackingService, @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService) {
        super(trackingService);
    }

    ngOnInit(): void {
        const contentVisibility = this.userSettingsService.getOptionAvailability(OptionTypes.Maps);
        if (contentVisibility) {
            this.isContentVisible = true;
            this.isMiniMapDisabled = !!this.userSettingsService.get<boolean>(OptionTypes.Maps, MapOptionsComponent.disableMinimapSettingId);
            this.isShowingOfflineMobileUsers = !!this.userSettingsService.get<boolean>(OptionTypes.Maps, MapOptionsComponent.showOfflineMobileUsersId);
        }
        this.isInitialized = true;
    }

    public onShowMiniMapChanged(showMiniMap: boolean): void {
        this.userSettingsService.set(OptionTypes.Maps, MapOptionsComponent.disableMinimapSettingId, !showMiniMap);
    }

    public onShowingOfflineMobileUsersChanged(hidingOfflineMobileUsers: boolean): void {
        this.userSettingsService.set(OptionTypes.Maps, MapOptionsComponent.showOfflineMobileUsersId, hidingOfflineMobileUsers);
    }
}
