import { SafeGuid } from 'safeguid';

export class MCPrivileges {
    public static triggerIncidents = SafeGuid.parse('d0959abb-b035-426a-b474-ebbb6f8fe76d');
    public static editIncidentPriority = SafeGuid.parse('fbd95f37-5d3a-47f4-8576-d021a48f3e4c');
    public static takeOwnership = SafeGuid.parse('f0bdeb0b-4696-4655-97d2-6368b45899fa');
    public static overrideOwnership = SafeGuid.parse('802e26d5-db95-404b-b569-b5f61df2e9e6');
    public static forwardIncident = SafeGuid.parse('4c1a653e-6b9b-448f-97ae-767d030eb0df');
    public static transferIncident = SafeGuid.parse('9c8615fb-b61c-4e6a-820f-45a516cdc6da');
    public static linkIncidents = SafeGuid.parse('b82a859d-c88f-4387-a2e5-fd6937325d26');
    public static modifyReportedIncidents = SafeGuid.parse('cabd6491-881f-40d6-bc73-a1cfd1287b9a');
    public static editIncidentDescription = SafeGuid.parse('d61794d9-37e6-4b40-a6cb-abdd8449e120');
    public static addComment = SafeGuid.parse('269b79d9-003d-4647-ba58-605d03b1cad3');
}
