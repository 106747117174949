import { bufferTime, catchError, filter, map, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { WebAppClient } from 'WebClient/WebAppClient';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EventBase } from 'RestClient/Client/Event/EventBase';
import { ServiceState, StateEnum } from '../../models/service-state';
import { IncidentEvent } from '../../models/events/incident-event';
import { eventFactory, isSupportedEventType } from '../../models/events/incident-event';
import { McModuleHealthEvent } from '../../models/events/mc-module-health-event';
import { McClientSseError } from '../../models/errors/mc-client-sse-error';

@Injectable({
    providedIn: 'root',
})
export class McEventReceiverService {
    private _state$ = new BehaviorSubject<ServiceState>(new ServiceState(StateEnum.Unintialized));
    private _scClient: WebAppClient;
    private _events$ = new Subject<IncidentEvent>();

    constructor(securityCenterProvider: SecurityCenterClientService) {
        this._scClient = securityCenterProvider.scClient;
        this._scClient.registerAdditionalEventTypes('McIncidentEvent', EventBase);
        this._scClient.registerAdditionalEventTypes('McModuleHealthEvent', McModuleHealthEvent);
        this._scClient.onEventReceived((mcEvent) => this.onEventReceived(mcEvent));
    }

    public get state$(): Observable<ServiceState> {
        return this._state$;
    }

    public getIncidentEvents = (): Observable<IncidentEvent[]> =>
        this._events$.pipe(
            filter((ie) => isSupportedEventType(ie.type)),
            map((ie) => eventFactory(ie)),
            catchError((err) => {
                console.error(err);
                return throwError(err);
            }),
            bufferTime(200),
            filter((buffer) => buffer.length > 0),
            share()
        );

    private onEventReceived(theEvent: EventReceivedArg) {
        if (theEvent.event.eventType === 'McModuleHealthEvent') {
            const mcModuleHealthEvent = theEvent.event as McModuleHealthEvent;
            const serviceState = mcModuleHealthEvent.isInitialized ? new ServiceState(StateEnum.Running) : ServiceState.fromError(new McClientSseError());
            this._state$.next(serviceState);
        }

        if (theEvent.event.eventType === 'McIncidentEvent') {
            const incidentEvent = theEvent.event.toJson() as IncidentEvent;
            this._events$.next(incidentEvent);
        }
    }
}
