import { Cancellation, CancellationToken } from './CancellationToken';
import { PromiseCompletionSourceVoid } from './PromiseCompletionSource';

export class StyleObserver {
  private readonly m_styleObserver: MutationObserver;

  private m_styleChangePromise: PromiseCompletionSourceVoid;
  private readonly m_disposeToken: CancellationToken;

  public get StyleChange(): Promise<void | Cancellation> {
    return Promise.race([this.m_styleChangePromise.Promise, this.m_disposeToken.Cancellation]);
  }

  constructor(htmlElement: HTMLElement) {
    this.m_styleObserver = new MutationObserver(this.onStyleChanged);
    this.m_styleObserver.observe(htmlElement, { attributes: true, attributeFilter: ['style'] });

    this.m_disposeToken = new CancellationToken();
    this.m_styleChangePromise = new PromiseCompletionSourceVoid();
  }

  public dispose() {
    this.m_styleObserver.disconnect();
    this.m_disposeToken.cancel('dispose');
  }

  private readonly onStyleChanged = () => {
    const toResolve = this.m_styleChangePromise;
    this.m_styleChangePromise = new PromiseCompletionSourceVoid();
    toResolve.resolve();
  }
}
