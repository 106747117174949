import { GenModalService } from '@genetec/gelato-angular';
import { Injectable } from '@angular/core';
import { WebAppClient } from 'WebClient/WebAppClient';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { ISystemConfigurationEntity } from 'RestClient/Client/Interface/ISystemConfigurationEntity';
import { SystemConfigurationEntity } from 'RestClient/Client/Model/SystemConfigurationEntity';
import { ChangePasswordNotificationComponent } from './change-password-notification.component';

@Injectable({ providedIn: 'root' })
export class ChangePasswordNotificationService {
    private user?: IUserEntity;
    private milsInDay = 86400000;
    private lastTimeModified = 0;
    private passwordExpiration = 0;
    private today = Date.now();
    private securityCenterClient: WebAppClient;

    constructor(private modalService: GenModalService, private scProvider: SecurityCenterClientService) {
        this.securityCenterClient = scProvider?.scClient;
    }

    public async openPasswordNotificationModalIfNeeded(): Promise<void> {
        if (await this.verifyPasswordNotificationPeriod()) {
            this.modalService.open(ChangePasswordNotificationComponent, { daysRemaining: this.getRemainderToExpirePassword() });
        }
    }

    private getRemainderToExpirePassword(): number {
        return Math.max(0, Math.floor((this.lastTimeModified + this.passwordExpiration - this.today) / this.milsInDay));
    }

    private async verifyPasswordNotificationPeriod(): Promise<boolean> {
        this.user = (await this.securityCenterClient.getEntityAsync<UserEntity, IUserEntity>(UserEntity, this.securityCenterClient.userId)) as IUserEntity;
        const userSysConfig = (await this.securityCenterClient.getEntityAsync<SystemConfigurationEntity, ISystemConfigurationEntity>(
            SystemConfigurationEntity,
            Guids.SystemConfiguration
        )) as ISystemConfigurationEntity;
        if (this.user !== null) {
            this.lastTimeModified = this.user.passwordLastModified.getTime() - (this.user.passwordLastModified.getTime() % this.milsInDay);
            this.passwordExpiration = this.user.passwordExpiration * this.milsInDay;
            if (this.passwordExpiration === 0) {
                return false;
            }
            this.today = Date.now() - (Date.now() % this.milsInDay);
            if (userSysConfig !== null) {
                const notificationPeriod = userSysConfig.passwordExpiryNotificationPeriod * this.milsInDay;
                const notificationWindow = this.lastTimeModified + Math.max(0, this.passwordExpiration - notificationPeriod);
                if (this.today >= notificationWindow) {
                    return true;
                }
            }
        }
        return false;
    }
}
