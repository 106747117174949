import { ILogger } from '../../utils/logger';
import { MseMediaSource, MediaType } from './MseMediaSource';
import { ILiteEvent, LiteEvent } from '../../utils/liteEvents';
import { MseWatermarkOverlay } from './MseWatermarkOverlay';
import { PlaySpeed } from '../PlaySpeed';
import { SegmentInfo } from '../../services/eventHub';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { BufferQueue } from '../msePlayer';
import { IFrameRateProvider } from '../../utils/FrameRateComputer';
import { DebugSwitch } from '../../utils/Config';
import { Utils } from '../../utils/Utils';
import { ZoomConfiguration } from '../ZoomConfiguration';
import { Resolution } from '../../utils/Resolution';
import { RenderingCanvas } from '../../utils/RenderingCanvas';

export class MseHtmlVideoElement {
  private static readonly RenderingDelayToleranceMilliseconds = 5000; //for IE

  private readonly m_logger: ILogger;

  private readonly m_htmlVideoElement: HTMLVideoElement;

  private readonly m_renderingCanvas: RenderingCanvas;

  private readonly m_frameRateProvider: IFrameRateProvider;

  private readonly m_mseMediaSource: MseMediaSource;

  private readonly m_timeUpdateEvent = new LiteEvent<number>();

  private readonly m_errorEvent = new LiteEvent<void>();

  private readonly m_antiJitter = 200;

  private readonly m_watermarkOverlay: MseWatermarkOverlay;

  private m_renderInCanvas = false;

  private m_isPlayerActive = false;

  private m_zoomConfiguration: ZoomConfiguration = ZoomConfiguration.NoZoom;

  private get currentMediaTime(): number {
    return Math.trunc(this.m_htmlVideoElement.currentTime * 1000);
  }

  private set currentMediaTime(mediaTime: number) {
    // IE Complains when the same value is assigned (InvalidStateError)
    if (this.currentMediaTime !== mediaTime) {
      this.m_logger.info?.trace(`Setting video element media time to ${mediaTime}`);
      this.m_htmlVideoElement.currentTime = mediaTime / 1000;
    } else {
      this.m_logger.info?.trace(`About to set element media time to ${mediaTime} but was already that value`);
    }
  }

  public get readyState(): number {
    return this.m_htmlVideoElement.readyState;
  }

  public set playSpeed(playSpeed: PlaySpeed) {
    this.m_htmlVideoElement.playbackRate = playSpeed.Value;
  }

  public get timeUpdated(): ILiteEvent<number> {
    return this.m_timeUpdateEvent.expose();
  }

  public get errorOccurred(): ILiteEvent<void> {
    return this.m_errorEvent.expose();
  }

  public get FrameRendered(): Promise<void> {
    return this.m_mseMediaSource.FrameRendered;
  }

  public get initialization(): Promise<void> {
    return this.m_mseMediaSource.initialization;
  }

  public get streamResolution(): Resolution {
    if (this.m_htmlVideoElement.videoWidth === 0 || this.m_htmlVideoElement.videoHeight === 0) {
      return Resolution.None;
    }
    return Resolution.build(this.m_htmlVideoElement.videoWidth, this.m_htmlVideoElement.videoHeight);
  }

  public get tileResolution(): Resolution {
    if (this.m_htmlVideoElement.scrollWidth === 0 || this.m_htmlVideoElement.scrollHeight === 0) {
      return Resolution.None;
    }
    return Resolution.build(this.m_htmlVideoElement.scrollWidth, this.m_htmlVideoElement.scrollHeight);
  }

  constructor(logger: ILogger, divContainer: HTMLDivElement, videoElement: HTMLVideoElement, renderingCanvas: RenderingCanvas, frameRateProvider: IFrameRateProvider) {
    this.m_logger = logger.subLogger('MseHtmlVideoElement');
    this.m_htmlVideoElement = videoElement;
    this.m_renderingCanvas = renderingCanvas;
    this.m_frameRateProvider = frameRateProvider;

    // Periodic update of the media time the HTMLVideoElement. Will not fire if there is no more data to play.
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.timeupdate, this.onTimeUpdate, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.error, this.onError, false);

    // this.subscribeToEveryHtmlVideoElementEvents(); // When investigating HtmlVideoElements behavior

    const mediaSource = new MediaSource();
    this.m_htmlVideoElement.src = window.URL.createObjectURL(mediaSource);
    this.m_mseMediaSource = new MseMediaSource(logger, mediaSource, MediaType.video, new BufferQueue(logger));

    this.m_watermarkOverlay = new MseWatermarkOverlay(this.m_logger, divContainer, videoElement, this.m_renderingCanvas.canvasElement);
  }

  public dispose() {
    this.m_logger.debug?.trace('dispose()');
    this.m_watermarkOverlay.dispose();
    this.m_htmlVideoElement.removeEventListener(HtmlVideoElementEvents.timeupdate, this.onTimeUpdate, false);
    this.m_htmlVideoElement.removeEventListener(HtmlVideoElementEvents.error, this.onError, false);

    this.m_mseMediaSource.dispose();
    window.URL.revokeObjectURL(this.m_htmlVideoElement.src);
    this.m_htmlVideoElement.src = '';
  }

  public getSnapshot(fromZoomedImage: boolean): ImageData | null {
    if (fromZoomedImage && this.m_zoomConfiguration.IsZoomed) {
      return this.m_renderingCanvas.getSnapshot();
    } else {
      const snapshotCanvas = document.createElement('canvas');
      snapshotCanvas.width = this.m_htmlVideoElement.videoWidth;
      snapshotCanvas.height = this.m_htmlVideoElement.videoHeight;
      const context = snapshotCanvas.getContext('2d');
      if (context === null) {
        this.m_logger.warn?.trace('getSnapshot(): Failed to obtain the 2d context of the canvas');
        throw new Error('Failed to obtain the 2d context of the canvas');
      }

      context.drawImage(this.m_htmlVideoElement, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

      this.m_logger.info?.trace(`getSnapshot(): returning a ${snapshotCanvas.width}x${snapshotCanvas.height} image`);

      const watermarkRenderer = this.m_watermarkOverlay.watermarkRenderer;
      if (watermarkRenderer !== undefined) {
        watermarkRenderer.updateVideoWatermarkingOverlaySize(snapshotCanvas.width, snapshotCanvas.height);
        watermarkRenderer.drawVideoWatermarkingOverlay(context, 0, 0);
      }

      return context.getImageData(0, 0, snapshotCanvas.width, snapshotCanvas.height);
    }
  }

  public async reset() {
    this.m_logger.debug?.trace('reset()');
    await this.m_mseMediaSource.reset();
    this.m_logger.debug?.trace('reset() - completed. ReadyState:', HtmlVideoElementReadyState[this.m_htmlVideoElement.readyState], this.state());
  }

  public play() {
    this.m_logger.debug?.trace('play()');
    // IE doesnt return a Promise
    const play = this.m_htmlVideoElement.play();
    if (play !== undefined) {
      play.catch((error) => {
        this.m_logger.intense?.trace(`play() command was interrupted by a call to pause(): ${error}`);
      });
    }
    this.m_mseMediaSource.play();
  }

  public pause() {
    this.m_logger.debug?.trace('pause()');
    this.m_htmlVideoElement.pause();
    this.m_mseMediaSource.pause();
  }

  public decode(segmentInfo: SegmentInfo, buffer: Int8Array) {
    this.m_mseMediaSource.decode(segmentInfo, buffer);

    //Debugging, force an error to provoke a request mjpeg transcoding from the server
    if (DebugSwitch.IsMseBroken) {
      this.onError();
    }

    const currentMediaTime = this.currentMediaTime;

    if (segmentInfo.MediaTime - currentMediaTime > MseHtmlVideoElement.RenderingDelayToleranceMilliseconds) {
      const timeFor2Frames = this.m_frameRateProvider.getFrameRate().timeBetween2Frames * 2;
      const minimumMediaTime = segmentInfo.MediaTime - Math.max(timeFor2Frames, this.m_antiJitter);
      const maximumSeekMediaTime = this.getMaximumSeekMediaTime();
      const seekMediaTime = Math.min(minimumMediaTime, maximumSeekMediaTime);

      // Readjust the video element's time if it drags behind the last frame received by over the tolerated amount of time. This happens when the webpage is no longer in focus.
      this.m_logger.debug?.trace('Out of sync with received frame. Current time:', currentMediaTime, ', Last frame received:', segmentInfo.MediaTime, 'Difference of:', segmentInfo.MediaTime - currentMediaTime, '>', MseHtmlVideoElement.RenderingDelayToleranceMilliseconds, 'Maximum seek time is', maximumSeekMediaTime, 'Jumping to: ', seekMediaTime);

      this.currentMediaTime = seekMediaTime;
    }
  }

  public updateZoomConfig(zoomConfig: ZoomConfiguration): void {
    if (this.m_zoomConfiguration.equals(zoomConfig)) {
      return;
    }
    this.m_zoomConfiguration = zoomConfig;
    if (this.m_isPlayerActive) {
      this.applyZoom();
    }
  }

  public setPlayerActive(isPlayerActive: boolean): void {
    if (isPlayerActive) {
      this.applyZoom();
    } else if (this.m_isPlayerActive) {
      this.m_renderInCanvas = false;
    }
    this.m_isPlayerActive = isPlayerActive;
  }

  private applyZoom() {
    // If we're switching to a zoomed view, hide the html video element and display the renderingCanvas on which the zoom is applied
    if (this.m_zoomConfiguration.IsZoomed) {
      if (!this.m_renderInCanvas) {
        this.m_renderInCanvas = true;
        // Only call updateCanvas once when doing the switch, as it will get called by requestAnimationFrame
        this.updateCanvas();
        // The VWO is drawn directly on the renderingCanvas when zoomed.
        // Disable the MseWatermarkOverlay.
        this.m_watermarkOverlay.toggleOverlay(false);
      }
    } else {
      this.m_renderInCanvas = false;
      this.m_watermarkOverlay.toggleOverlay(true);
    }
  }

  private getMaximumSeekMediaTime(): number {
    let maximumSeekTime = 0;
    for (let i = 0; i < this.m_htmlVideoElement.buffered.length; ++i) {
      maximumSeekTime = Math.max(maximumSeekTime, this.m_htmlVideoElement.buffered.end(i));
    }

    return maximumSeekTime * 1000; //The times supplied by htmlVideoElement are in seconds. We work in mediaTime (ms)
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig) {
    this.m_watermarkOverlay.updateVideoWatermarkingConfig(videoWatermarkingConfig);
    this.m_renderingCanvas.updateVideoWatermarkingConfig(videoWatermarkingConfig);
  }

  private readonly onTimeUpdate = () => {
    const mediaTime = this.currentMediaTime;
    this.m_logger.intense?.trace('MediaTime update:', mediaTime, 'ReadyState:', HtmlVideoElementReadyState[this.m_htmlVideoElement.readyState], this.state());
    this.m_timeUpdateEvent.trigger(mediaTime);
  }

  private readonly onError = () => {
    this.m_logger.error?.trace('Error:', this.m_htmlVideoElement.error?.code, 'details:', this.m_htmlVideoElement.error?.message);
    this.m_errorEvent.trigger();
  }

  private readonly updateCanvas = () => {
    if (this.m_renderInCanvas) {
      // Only draw if we have content in the video element that we can pass to the canvas
      if (this.m_htmlVideoElement.videoWidth !== 0 && this.m_htmlVideoElement.videoHeight !== 0) {
        this.m_renderingCanvas.draw(this.m_htmlVideoElement, Resolution.build(this.m_htmlVideoElement.videoWidth, this.m_htmlVideoElement.videoHeight), this.m_zoomConfiguration);
      }
      requestAnimationFrame(this.updateCanvas);
    }
  }
  /*
   * We don't usually keep dead code but this is long to do and we keep redoing it each time we investigate something on the video so keeping it this time
   *
  private subscribeToEveryHtmlVideoElementEvents() {
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.timeupdate, () => {
      console.warn('HtmlVideoElement sent event timeupdate');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.waiting, () => {
      console.warn('HtmlVideoElement sent event waiting');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.abort, () => {
      console.warn('HtmlVideoElement sent event abort');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.canplay, () => {
      console.warn('HtmlVideoElement sent event canplay');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.canplaythrough, () => {
      console.warn('HtmlVideoElement sent event canplaythrough');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.durationchange, () => {
      console.warn('HtmlVideoElement sent event durationchange to ' + this.m_htmlVideoElement.duration + ' sec');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.emptied, () => {
      console.warn('HtmlVideoElement sent event emptied');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.ended, () => {
      console.warn('HtmlVideoElement sent event ended');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.error, () => {
      console.warn('HtmlVideoElement sent event error');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.loadeddata, () => {
      console.warn('HtmlVideoElement sent event loadeddata');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.loadedmetadata, () => {
      console.warn('HtmlVideoElement sent event loadedmetadata');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.loadstart, () => {
      console.warn('HtmlVideoElement sent event loadstart');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.pause, () => {
      console.warn('HtmlVideoElement sent event pause');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.play, () => {
      console.warn('HtmlVideoElement sent event play');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.playing, () => {
      console.warn('HtmlVideoElement sent event playing');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.progress, () => {
      console.warn('HtmlVideoElement sent event progress: ' + this.m_htmlVideoElement.buffered.length + ' ranges');
      for (let i = 0; i < this.m_htmlVideoElement.buffered.length; ++i) {
        console.warn('Range ' + i + ' ' + this.m_htmlVideoElement.buffered.start(i) + ' to ' + this.m_htmlVideoElement.buffered.end(i));
      }
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.ratechange, () => {
      console.warn('HtmlVideoElement sent event ratechange');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.seeked, () => {
      console.warn('HtmlVideoElement sent event seeked');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.seeking, () => {
      console.warn('HtmlVideoElement sent event seeking');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.stalled, () => {
      console.warn('HtmlVideoElement sent event stalled');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.suspend, () => {
      console.warn('HtmlVideoElement sent event suspend');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.timeupdate, () => {
      console.warn('HtmlVideoElement sent event timeupdate');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.volumechange, () => {
      console.warn('HtmlVideoElement sent event volumechange');
    }, false);
    this.m_htmlVideoElement.addEventListener(HtmlVideoElementEvents.waiting, () => {
      console.warn('HtmlVideoElement sent event waiting');
    }, false);
  }
  */

  public state(): string {
    return `current time: ${this.m_htmlVideoElement.currentTime} playbackRate: ${this.m_htmlVideoElement.playbackRate} duration: ${this.m_htmlVideoElement.duration} ended: ${this.m_htmlVideoElement.ended} error: ${this.m_htmlVideoElement.error} network state: ${this.m_htmlVideoElement.networkState} paused: ${this.m_htmlVideoElement.paused}`;
  }

  public debugStatus(indent: number): string {
    const frameRate = this.m_frameRateProvider.getFrameRate();

    return 'MseHtmlVideoElement' + Utils.indentNewLine(indent) +
      'MediaTime: ' + this.currentMediaTime + Utils.indentNewLine(indent) +
      'ReadyState: ' + this.readyState + Utils.indentNewLine(indent) +
      'PlaySpeed: ' + this.playSpeed + Utils.indentNewLine(indent) +
      'FrameRate: ' + frameRate.frameRateToString() + `(${frameRate.keyFrameRateToString()})` + Utils.indentNewLine(indent) +
      'HtmlState: ' + this.state() + Utils.indentNewLine(indent) +
      this.m_mseMediaSource.debugStatus(indent + Utils.Indentation);
  }
}

export enum HtmlVideoElementReadyState {
  Nothing = 0,
  MetaData = 1,
  CurrentData = 2,
  FutureData = 3,
  EnoughData = 4
}

export enum HtmlVideoElementEvents {
  abort = 'abort',
  canplay = 'canplay',
  canplaythrough = 'canplaythrough',
  durationchange = 'durationchange',
  emptied = 'emptied',
  ended = 'ended',
  error = 'error',
  loadeddata = 'loadeddata',
  loadedmetadata = 'loadedmetadata',
  loadstart = 'loadstart',
  pause = 'pause',
  play = 'play',
  playing = 'playing',
  progress = 'progress',
  ratechange = 'ratechange',
  seeked = 'seeked',
  seeking = 'seeking',
  stalled = 'stalled',
  suspend = 'suspend',
  timeupdate = 'timeupdate',
  volumechange = 'volumechange',
  waiting = 'waiting'
}
