<app-filter-host [filterDescriptor]="descriptor" [state]="state" [popupPosition]="popupPosition">
    <div class="filter-container p-2">
        <div class="filter-content">
            <div class="mb-1">
                <gen-numeric-input [ngModel]="from" (ngModelChange)="onFromInputChange($event)" [step]="1">
                    <gen-label>{{ 'STE_LABEL_TIMERANGE_FROM' | translate }} </gen-label>
                </gen-numeric-input>
                <gen-numeric-input [ngModel]="to" (ngModelChange)="onToInputChange($event)" [step]="1">
                    <gen-label>{{ 'STE_LABEL_TIMERANGE_TO' | translate }} </gen-label>
                </gen-numeric-input>
                <gen-button [flavor]="Flavor.Button.Flat" (click)="clearFilter()" appTooltip="{{ 'STE_LABEL_VERB_RESET' | translate }}">
                    <gen-icon [icon]="Icon.Reset"></gen-icon>
                </gen-button>
            </div>

            <gen-text *ngIf="invalidDateRange" [flavor]="Flavor.Text.Error">
                {{ 'STE_LABEL_FORMAT_INVALID_RANGE_BETWEEN_X_AND_Y' | translate | stringFormat: min:max }}
            </gen-text>
        </div>
    </div>
</app-filter-host>
