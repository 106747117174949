import { IGuid, SafeGuid } from 'safeguid';
import { IEntityBrowserResult } from '../../../entity-browser/interfaces/entity-browser-result';
import { BrowserItem } from './browser-item';

export class ShowMoreBrowserItem extends BrowserItem {
    public page!: number;
    public lastEntityId!: IGuid;
    public isQuerying = false;
    public isShowMore = true;

    constructor(result: IEntityBrowserResult) {
        super();

        this.page = result.currentPage;
        this.lastEntityId = result.lastEntityId;
        this.itemId = SafeGuid.newGuid().toString();
    }
}
