// Autogenerated: v2/help/Entities/ApiAgent?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { ITransaction } from './ITransaction';

// #region Fields

export class AgentEntityFields extends EntityFields {
    public static agentTypeField = 'AgentType';
    public static roleIdField = 'RoleId';
    public static serverIdField = 'ServerId';
    public static serverInfoField = 'ServerInfo';
    public static specificConfigXmlField = 'SpecificConfigXml';
    public static xmlDataField = 'XmlData';
}

// #endregion Fields

export interface IAgentEntity extends IEntity {
    // #region REST Properties

    readonly agentType: string;

    roleId: IGuid;

    serverId: IGuid;

    serverInfo: string;

    specificConfigXml: string;

    xmlData: string;

    // #endregion REST Properties

    // #region REST Methods

    getAgentDatabaseStatusAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;

    // #endregion REST Methods

}

