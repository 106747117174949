// Autogenerated: v2/help/Entities/ApiCustomEntity?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';
import { ICustomEntityTypeDescriptor } from './ICustomEntityManager';

// #region Fields

export class CustomEntityFields extends EntityFields {
    public static customEntityTypeDescriptorIdField = 'CustomEntityTypeDescriptorId';
}

// #endregion Fields

// #region Inner classes

export interface ICustomState extends IFieldObject {
    state0: string;
    state1: string;
}

export interface ICustomInfo extends IFieldObject {
    info0: string;
    info1: string;
}

// #endregion Inner classes

export interface ICustomEntity extends IEntity {
    // #region REST Properties

    customEntityTypeDescriptorId: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    getInfosAsync(transaction?: ITransaction | null): Promise<ICustomInfo | null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getStatesAsync(transaction?: ITransaction | null): Promise<ICustomState | null>;
    getTypeDescriptorAsync(transaction?: ITransaction | null): Promise<ICustomEntityTypeDescriptor | null>;

    // #endregion REST Methods

}

