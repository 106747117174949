import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GenMeltedItem } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-joystick-selection',
    templateUrl: './joystick-selection.component.html',
    styleUrls: ['./joystick-selection.component.scss'],
})
export class JoystickSelectionComponent implements OnInit, OnChanges {
    @Input() joysticks?: Gamepad[];
    @Input() activeJoystickId?: string;
    @Output() activeJoystickChanged = new EventEmitter<string | null>();

    // TODO : Adapt with ngFor when GenCombobox is WebComponent
    public joysticksItems: GenMeltedItem[] = [];

    private noneItemState: GenMeltedItem = { id: '', text: this.translateService.instant('STE_LABEL_NONE') as string };

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        this.refreshComboboxSelection();
    }
    ngOnChanges(): void {
        this.refreshComboboxSelection();
    }

    public refreshComboboxSelection(): void {
        if (!this.joysticks) return;

        this.joysticksItems = [this.noneItemState, ...this.joysticks.map((gp) => ({ id: gp.id, text: gp.id }))];
    }

    public onActiveJoystickChanged(id?: string): void {
        this.activeJoystickChanged.emit(id ?? null);
    }

    public onCheckboxChanged(event: CustomEvent<boolean>): void {
        this.onActiveJoystickChanged((event.detail && this.joysticks?.[0].id) || undefined);
    }

    public onComboboxChanged(selected: GenMeltedItem): void {
        this.onActiveJoystickChanged(selected.id === '' ? undefined : selected.id);
    }
}
