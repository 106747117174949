import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Icon, SelectionType } from '@genetec/gelato';
import { FilterContext, IFilterContent } from '@modules/shared/api/api';
import { FilterBaseComponent } from '../filter-base.component';
import { ListItem } from './../../../../interfaces/list-item';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
/**
 * Filter based on enum values as restraint
 */
@Component({
    selector: 'app-filter-enum',
    templateUrl: './filter-enum.component.html',
    styleUrls: ['./filter-enum.component.scss'],
})
export class FilterEnumComponent extends FilterBaseComponent<string[]> implements OnInit, OnDestroy {
    //#region Public fields

    @Input() public filterContext?: FilterContext;
    @Input() set values(array: Array<{ id: string; text: string }> | undefined) {
        if (array) {
            array.forEach((value) => this.enumValues.push({ ...value, isChecked: false } as ListItem));
            this.isContentLoaded = true;
        }
    }

    @Output() public lazyLoadEvent = new EventEmitter<IFilterContent>();

    public readonly Icon = Icon;
    public readonly ListSelectionType = SelectionType;

    public enumValues: ListItem[] = [];

    public get selectedEnumValues(): ListItem[] {
        return this.enumValues.filter((value) => value.isChecked);
    }

    public get isFooterVisible(): boolean {
        return this.enumValues.length > 0;
    }

    public get isAllSelected(): boolean {
        return this.enumValues.length === this.selectedEnumValues.length;
    }

    public get isNothingSelected(): boolean {
        return this.selectedEnumValues.length === 0;
    }

    public isContentLoaded = false;
    public isContentLoading = false;

    //#endregion

    ngOnInit() {
        super.ngOnInit();
        this.value = [];
    }

    public onSelectedEnumValuesChanged(item: ListItem, isChecked: boolean): void {
        item.isChecked = isChecked;
        this.updateValue(this.selectedEnumValues);
    }

    public onFilterToggled(isFilterOpened: boolean): void {
        if (isFilterOpened && !this.isContentLoaded && !this.isContentLoading) {
            this.lazyLoadContent().fireAndForget();
        }

        super.onFilterToggled(isFilterOpened);
    }

    public onCheckAllItemsClicked(): void {
        this.enumValues.forEach((enumValue) => {
            enumValue.isChecked = true;
        });
        this.updateValue(this.selectedEnumValues);
    }

    public updateState(): void {
        const selectedItems = this.selectedEnumValues;

        let selection = this.state.selection;

        if (!selectedItems?.length) {
            selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
        } else if (selectedItems.length === 1) {
            selection = selectedItems[0].text;
        } else {
            selection = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: selectedItems.length }) as string;
        }

        this.state = { ...this.state, selection };
    }

    public clearFilter(): void {
        this.enumValues.forEach((enumValue) => {
            enumValue.isChecked = false;
        });
        this.updateValue(this.selectedEnumValues);
    }

    public isDefaulted(): boolean {
        return this.value.length === 0;
    }

    public override isDirty(firstValue: string[], secondValue: string[]): boolean {
        if (firstValue.length !== secondValue.length) {
            return true;
        }

        // compare each item
        return firstValue.some((value, index) => secondValue.indexOf(value) === -1);
    }

    public override getDefaultValue(): string[] {
        return [];
    }

    /**
     * Fetches the enum values from the server.
     *
     * @param context
     */
    private async lazyLoadContent(): Promise<void> {
        this.isContentLoading = true;
        if (this.filterContext) {
            const content = await this.filterClient.getFilterContent(this.filterContext).toPromise();
            if (content) {
                this.lazyLoadEvent.emit(content);
            }
        }

        this.isContentLoading = false;
    }

    private updateValue(items: ListItem[]): void {
        this.value = items.map((x) => x.id);
    }
}
