import { Constants } from '@modules/shared/utilities/constants';
import { SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2022 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const KnownCustomHeaderIds = Constants({
    IncidentCustomHeaderId: SafeGuid.parse('1C1E8D8B-7EA7-4F3D-8076-E8C5BA911D0B'),
});
