// Autogenerated: CodeGen/EntityBrowserFilterData?language=ts&InterfaceOnly=False on 2021-05-10 11:54:25 AM
// Autogenerated: CodeGen/HierarchicalEntityBrowserFilterData?language=ts&InterfaceOnly=False on 2021-05-10 11:54:25 AM
// Autogenerated: CodeGen/FlatEntityBrowserFilterData?language=ts&InterfaceOnly=False on 2021-05-10 11:54:25 AM
import { IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { RegisterFieldObjectConstructor, RegisterFieldObjectOverload } from 'RestClient/Helpers/FieldObjectOverloads';
import { IEntityTypeData, EntityTypeData } from '../../models/entity-type-data';
import { IEntityBrowserFilterData, IHierarchicalEntityBrowserFilterData, IFlatEntityBrowserFilterData } from '../interfaces/filters/entity-browser-filter-data.interface';
import { IPageInfo, PageInfo } from '../interfaces/filters/page-info';
import { HierarchicalData } from '../../models/hierarchical-data';
import { FieldFilterData, IFieldFilterData } from '../../models/field-filter.data';
import { ExpanderFilterData, IExpanderFilterData } from '../../models/expander-filter-data';
import { CustomFieldData, ICustomFieldData } from './custom-field-data';

export class EntityBrowserFilterData extends HierarchicalData implements IEntityBrowserFilterData {
    // EntityIds
    public get entityIds(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('EntityIds');
    }
    public set entityIds(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('EntityIds', value);
    }
    // EntityOwnerTypes
    public get entityOwnerTypes(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('EntityOwnerTypes');
    }
    public set entityOwnerTypes(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('EntityOwnerTypes', value);
    }
    // EntityTypes
    public get entityTypes(): ObservableCollection<IEntityTypeData> {
        return this.getFieldObjectArray<EntityTypeData, IEntityTypeData>(EntityTypeData, 'EntityTypes');
    }
    public set entityTypes(value: ObservableCollection<IEntityTypeData>) {
        this.setFieldObjectArray('EntityTypes', value);
    }
    // ExcludedEntities
    public get excludedEntities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('ExcludedEntities');
    }
    public set excludedEntities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('ExcludedEntities', value);
    }
    // FieldFilters
    public get fieldFilters(): ObservableCollection<IFieldFilterData> {
        return this.getFieldObjectArray<FieldFilterData, IFieldFilterData>(FieldFilterData, 'FieldFilters');
    }
    public set fieldFilters(value: ObservableCollection<IFieldFilterData>) {
        this.setFieldObjectArray('FieldFilters', value);
    }
    // IncludedEntities
    public get includedEntities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('IncludedEntities');
    }
    public set includedEntities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('IncludedEntities', value);
    }
    // PageInfo
    public get pageInfo(): IPageInfo {
        return this.getFieldObject<PageInfo, IPageInfo>(PageInfo, 'PageInfo');
    }
    public set pageInfo(value: IPageInfo) {
        this.setFieldObject<IPageInfo>('PageInfo', value);
    }
    // RefresherType
    public get refresherType(): IGuid {
        return this.getFieldGuid('RefresherType');
    }
    public set refresherType(value: IGuid) {
        this.setFieldGuid('RefresherType', value);
    }
    // SearchLogicalIds
    public get searchLogicalIds(): boolean {
        return this.getField<boolean>('SearchLogicalIds');
    }
    public set searchLogicalIds(value: boolean) {
        this.setField<boolean>('SearchLogicalIds', value);
    }
    // TextFilter
    public get textFilter(): string {
        return this.getField<string>('TextFilter');
    }
    public set textFilter(value: string) {
        this.setField<string>('TextFilter', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldArrayGuid('EntityIds', new ObservableCollection<IGuid>());
        this.setFieldArrayGuid('EntityOwnerTypes', new ObservableCollection<IGuid>());
        this.setFieldArray<IEntityTypeData>('EntityTypes', new ObservableCollection<IEntityTypeData>());
        this.setFieldArrayGuid('ExcludedEntities', new ObservableCollection<IGuid>());
        this.setFieldArray<IFieldFilterData>('FieldFilters', new ObservableCollection<IFieldFilterData>());
        this.setFieldArrayGuid('IncludedEntities', new ObservableCollection<IGuid>());
        const objPageInfo = new PageInfo();
        objPageInfo.initializeAllFields();
        this.setFieldObject('PageInfo', objPageInfo);
        this.setFieldGuid('RefresherType', SafeGuid.EMPTY);
        this.setField<boolean>('SearchLogicalIds', false);
        this.setField<string>('TextFilter', '');
    }
}

@RegisterFieldObjectConstructor
@RegisterFieldObjectOverload('EntityBrowserFilterData', 'HierarchicalEntityBrowserFilterData')
export class HierarchicalEntityBrowserFilterData extends EntityBrowserFilterData implements IHierarchicalEntityBrowserFilterData {
    // IsRootExpanded
    public get isRootExpanded(): boolean {
        return this.getField<boolean>('IsRootExpanded');
    }
    public set isRootExpanded(value: boolean) {
        this.setField<boolean>('IsRootExpanded', value);
    }
    // ExpanderData
    public get expanderData(): ObservableCollection<IExpanderFilterData> {
        return this.getFieldObjectArray<ExpanderFilterData, IExpanderFilterData>(ExpanderFilterData, 'ExpanderData');
    }
    public set expanderData(value: ObservableCollection<IExpanderFilterData>) {
        this.setFieldObjectArray('ExpanderData', value);
    }
    // ExpandedParents
    public get expandedParents(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('ExpandedParents');
    }
    public set expandedParents(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('ExpandedParents', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<boolean>('IsRootExpanded', false);
        this.setFieldArray<IExpanderFilterData>('ExpanderData', new ObservableCollection<IExpanderFilterData>());
        this.setFieldArrayGuid('ExpandedParents', new ObservableCollection<IGuid>());
    }
}

@RegisterFieldObjectConstructor
@RegisterFieldObjectOverload('EntityBrowserFilterData', 'FlatEntityBrowserFilterData')
export class FlatEntityBrowserFilterData extends EntityBrowserFilterData implements IFlatEntityBrowserFilterData {
    // CustomFieldValues
    public get customFieldValues(): ObservableCollection<ICustomFieldData> {
        return this.getFieldObjectArray<CustomFieldData, ICustomFieldData>(CustomFieldData, 'CustomFieldValues');
    }
    public set customFieldValues(value: ObservableCollection<ICustomFieldData>) {
        this.setFieldObjectArray('CustomFieldValues', value);
    }
    // OwnerRole
    public get ownerRole(): IGuid {
        return this.getFieldGuid('OwnerRole');
    }
    public set ownerRole(value: IGuid) {
        this.setFieldGuid('OwnerRole', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldArray<ICustomFieldData>('CustomFieldValues', new ObservableCollection<ICustomFieldData>());
        this.setFieldGuid('OwnerRole', SafeGuid.EMPTY);
    }
}
