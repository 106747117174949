import { Pipe, PipeTransform } from '@angular/core';
import { TimeService } from '@shared/services/time/time.service';
import { LoggerService } from '@shared/services/logger/logger.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

/*
  Converts an utc time to local time, using a specific format.
  Ex: <div>{{ timeUtc | dateFormatWithTimezone: 'MM/DD/yyyy h:mm A' | async }}<div/>
*/
@Pipe({ name: 'dateFormatWithTimezone' })
export class TimezoneConversionPipe implements PipeTransform {
    private static utcTimezoneId = 'UTC';

    constructor(private timeService: TimeService, private loggerService: LoggerService) {}

    public transform = (utcTime: Date | null, format: string): Observable<string | null> =>
        utcTime
            ? this.timeService.timezone$.pipe(
                  map((timezone) => timezone?.iana ?? null),
                  this.traceErrorIfNull(this.timeService.useDeviceTimezone ? 'Unable to fetch device timezone' : 'Unable to use configured timezone'),
                  this.formatIana(utcTime, format)
              )
            : of(null);

    private traceErrorIfNull =
        (error: string) =>
        (source$: Observable<string | null>): Observable<string | null> =>
            source$.pipe(
                tap((timezone) => {
                    if (timezone === null) {
                        this.loggerService.traceError(error);
                    }
                })
            );

    private formatIana =
        (utc: Date, format: string) =>
        (source$: Observable<string | null>): Observable<string> =>
            source$.pipe(map((iana) => this.timeService.formatTime(utc, format, true, true, iana ?? TimezoneConversionPipe.utcTimezoneId)));
}
