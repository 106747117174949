import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * Put the keyboard focus on the modal when is open
 * To focus a specific element (ex : input) when the popup opens, add the "open-focus" class to your HTML element
 */
@Directive({
    selector: '[appFocusModalWhenOpen]',
})
export class FocusModalWhenOpenDirective implements OnInit {
    private oldFocus?: HTMLElement;

    constructor(private el: ElementRef<Node>) {}

    ngOnInit(): void {
        const mutationObserver = new MutationObserver(() => {
            const modalComponent = this.el.nativeElement as HTMLElement;
            const genModal = modalComponent?.children[0] as HTMLElement;
            const genDialog = genModal?.children[0] as HTMLElement;
            // If genDialog is defined, modal is open
            if (genDialog) {
                this.oldFocus = document.activeElement as HTMLElement;
                const specificFocusElement = genDialog.querySelector<HTMLElement>('.open-focus');
                if (specificFocusElement) {
                    //Temporary setTimeout until Gelato migrates gen-modal (https://dev.azure.com/GenetecCentral/UXDev/_workitems/edit/5435/)
                    setTimeout(() => {
                        specificFocusElement.focus();
                    }, 100);
                } else {
                    genDialog?.focus();
                }
            } else {
                // Restore focus on active element before modal opens
                this.oldFocus?.focus();
            }
        });
        mutationObserver.observe(this.el.nativeElement, { childList: true });
    }
}
