import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { Subscription } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { WhereMode } from '@modules/correlation/api/api';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { LanguageService } from '@modules/shared/services/language/language.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { EntityBrowserComponent } from '@modules/shared/components/entity-browser/entity-browser/entity-browser.component';
import { EntityBrowserCheckSelection } from '@modules/shared/entity-browser/entity-browser-selection';
import { EntityBrowserService } from '@modules/shared/entity-browser/entity-browser.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { InvestigateFilterBaseComponent } from '../investigate-filter-base.component';
import { InvestigateDataContext } from '../../services/investigate-data.context';
import { WhereFilter } from '../../services/investigate-data.interfaces';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-investigate-where-filter',
    templateUrl: './where-filter.component.html',
    styleUrls: ['./where-filter.component.scss'],
    providers: [],
})
export class InvestigateWhereFilterComponent extends InvestigateFilterBaseComponent<WhereFilter> implements OnInit, AfterViewInit {
    // Area browser filter
    @ViewChild('whereEntityBrowser') public whereEntityBrowser!: EntityBrowserComponent;
    public readonly filterId = SafeGuid.newGuid().toStringFormat('N');
    public readonly whereMode = WhereMode;

    public includedAreas: IGuid[] = [];
    public excludedAreas: IGuid[] = [];
    public wkt: string | null = null;
    public mode: WhereMode = WhereMode.Anywhere;

    private isError = false;

    // Allow selection of only Areas and SystemConfig
    private selectableEntityTypes = [EntityTypes.Areas, EntityTypes.SystemConfigurations];

    constructor(
        trackingService: TrackingService,
        private translateService: TranslateService,
        private dataContext: InvestigateDataContext,
        private entityBrowserService: EntityBrowserService,
        private languageService: LanguageService
    ) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.updateFilterState();
        // reset filter status if language changed to display the correct filter state
        this.languageService.languageChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.updateFilterState();
        });
    }

    async ngAfterViewInit() {
        // Display all logical entity types in the where filter entity browser
        const displayableEntityTypes = await this.entityBrowserService.getDefaultLogicalTypesAsync();

        this.whereEntityBrowser.refreshAsync(new EntityBrowserFilter(displayableEntityTypes, this.selectableEntityTypes)).fireAndForget();
    }

    public onCheckedEntitiesChanged(areaSelection: EntityBrowserCheckSelection): void {
        this.onAreaGuidChanged(areaSelection.ids, areaSelection.exclusions);
    }

    public onAnywhereClicked(): void {
        this.mode = this.whereMode.Anywhere;
        this.includedAreas = [];
        this.excludedAreas = [];
        this.updateFilterState();
        this.filterToggle.emit(false);
    }

    public onLogicalClicked(): void {
        this.mode = this.whereMode.Logical;
        this.includedAreas = this.whereEntityBrowser?.checkedEntities?.ids ?? [];
        this.excludedAreas = this.whereEntityBrowser?.checkedEntities?.exclusions ?? [];
        this.updateFilterState();
    }

    public onAreaGuidChanged(includedAreas: IGuid[], excludedAreas: IGuid[]): void {
        if (this.mode !== WhereMode.Logical) {
            return;
        }

        if (includedAreas?.length) {
            this.includedAreas = includedAreas;
        } else {
            this.includedAreas = [];
        }
        if (excludedAreas?.length) {
            this.excludedAreas = excludedAreas;
        } else {
            this.excludedAreas = [];
        }
        this.updateFilterState();
    }

    protected setupSubscription(): Subscription | undefined {
        if (!this.dataContext) return undefined;

        return this.dataContext.whereFilter$.pipe(untilDestroyed(this)).subscribe((newValue) => {
            if (newValue) {
                // special case so we don't just cycle setting value, getting value changed, setting value, etc. This will NOT trigger the value changed evt
                this.silentSetValue(newValue);

                // reset the old information (silently without triggering an update evt)
                this.wkt = this.value.wkt;
                this.isError = !this.value.isValid;
                if (this.value.includedAreas !== null) {
                    this.includedAreas = this.value.includedAreas;
                }
                if (this.value.excludedAreas !== null) {
                    this.excludedAreas = this.value.excludedAreas;
                }
                this.mode = this.value.mode;

                // save the new description
                this.updateFilterStatus();
                this.value.description = this.filterStatus;
            } else {
                // someone set undefined, re-set it to just an empty filter
                this.value = {
                    wkt: null,
                    description: this.translateService.instant('STE_LABEL_ANYWHERE') as string,
                    intersection: true,
                    isValid: true,
                    includedAreas: null,
                    excludedAreas: null,
                    mode: WhereMode.Anywhere,
                };
            }
        });
    }

    protected onValueChanged(): void {
        if (this.dataContext) {
            const newValue: WhereFilter = {
                includedAreas: this.value.includedAreas && [...this.value.includedAreas],
                excludedAreas: this.value.excludedAreas && [...this.value.excludedAreas],
                description: this.value.description,
                intersection: this.value.intersection,
                mode: this.value.mode,
                isValid: this.value.isValid,
                wkt: this.value.wkt,
            };
            this.dataContext.setWhereFilter(newValue);
        }
        super.onValueChanged();
    }

    private updateFilterStatus() {
        if (this.isError) {
            this.filterStatus = this.translateService.instant('STE_LABEL_INVALIDFILTER') as string;
            return;
        }
        this.filterStatus = this.translateService.instant('STE_LABEL_ANYWHERE') as string;

        switch (this.mode) {
            case WhereMode.Anywhere:
                this.filterStatus = this.translateService.instant('STE_LABEL_ANYWHERE') as string;
                break;
            case WhereMode.Logical:
                if (this.includedAreas?.length) {
                    this.filterStatus = this.translateService.instant('STE_LABEL_INAREA') as string;
                }
                break;
            default:
                break;
        }
    }

    private updateFilterState() {
        this.updateFilterStatus();

        this.value = {
            wkt: this.wkt,
            intersection: true,
            description: this.filterStatus,
            isValid: !this.isError,
            includedAreas: this.includedAreas && [...this.includedAreas],
            excludedAreas: this.excludedAreas && [...this.excludedAreas],
            mode: this.mode,
        };
    }
}
