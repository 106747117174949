/*
     * A simplified typed event handler :)
     * Taken from: https://stackoverflow.com/questions/12881212/does-typescript-support-events-on-classes
     *
     * USAGE EXAMPLE:
     * class Security{
     *   private readonly onLogin = new LiteEvent<string>();
     *   private readonly onLogout = new LiteEvent<void>();
     *
     *   public get LoggedIn() { return this.onLogin.expose(); }
     *   public get LoggedOut() { return this.onLogout.expose(); }
     *
     *   // ... onLogin.trigger('bob');
     * }
     *
     * function Init() {
     *   var security = new Security();
     *
     *   var loggedOut = () => { ... }
     *   security.LoggedIn.on((username?) => { ... });
     *   security.LoggedOut.on(loggedOut);
     *
     *   // ...
     *
     *   security.LoggedOut.unregister(loggedOut);
     *   }
     */

/** Event you can register your event handlers on to receive callbacks. Allows multiple handlers for the same event.
 * @public */
export interface ILiteEvent<T> {
  /** Register an event handler, don't forget to unregister it when you are done */
  register(handler: { (data: T): void }): void;
  /** Unregister the event handler to stop receiving callbacks */
  unregister(handler: { (data: T): void }): void;
}

export class LiteEvent<T> implements ILiteEvent<T> {
  private m_isDisposed: boolean = false;

  private handlers: { (data: T): void; }[] = [];

  public register(handler: { (data: T): void }): void {
    this.throwIfDisposed();
    this.handlers.push(handler);
  }

  // Not sure about the .bind mechanics for event handler so for now, added a little check on handlers that should lower after unregister is called
  public unregister(handler: { (data: T): void }): void {
    this.throwIfDisposed();
    const previousCount = this.handlers.length;
    this.handlers = this.handlers.filter((h) => h !== handler);
    if (previousCount === this.handlers.length) {
      throw new Error('Was not registered');
    }
  }

  public trigger(data: T) {
    this.throwIfDisposed();
    this.handlers.slice(0).forEach((h) => h(data));
  }

  public boundTrigger = (data: T) => {
    this.throwIfDisposed();
    this.handlers.slice(0).forEach((h) => h(data));
  }

  public expose(): ILiteEvent<T> {
    this.throwIfDisposed();
    return this;
  }

  public dispose() {
    this.handlers = [];
    this.m_isDisposed = true;
  }

  private throwIfDisposed(): void {
    if (this.m_isDisposed) {
      throw new Error('Object is disposed.');
    }
  }
}
