import { Injectable, Pipe, PipeTransform } from '@angular/core';

/*
  <li *ngFor="let it of its | filter : 'name' : 'value or variable'">{{it}}</li>
 */
@Pipe({
    name: 'filter',
})
@Injectable()
export class FilterPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public transform(items: any, field: string, value: any): any[] {
        if (!items || !(items instanceof Object)) {
            return [];
        }

        const itemsToFilter = items as Record<string, unknown>[];
        if (value instanceof String) {
            if (value.length === 0) {
                return itemsToFilter;
            }
            return itemsToFilter.filter((it) => {
                const fieldValue = it[field];
                if (fieldValue instanceof String) {
                    return fieldValue.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                }
                return false;
            });
        } else {
            return itemsToFilter.filter((it) => it[field] === value);
        }
    }
}
