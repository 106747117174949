// Autogenerated: v2/help/enumerations/ApiScheduleType?codegen=ts&showresources=False on 2020-07-23 3:40:07 PM

export class ScheduleType {
    public static None = 'None';
    public static Range = 'Range';
    public static RangeAndTwilight = 'RangeAndTwilight';

    public static readonly values = [
        ScheduleType.None,
        ScheduleType.Range,
        ScheduleType.RangeAndTwilight
    ];
}
