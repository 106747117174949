import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { GuidMap, IGuid } from 'safeguid';
import { SubscriptionCollection } from '../../../utilities/subscription-collection';
import { CommandUsage } from './command-usage';
import { CommandsUsage } from './commands-usage';

export class CommandsUsageCollection {
    private isReady$ = new BehaviorSubject<boolean>(true);

    private commandsUsageReadySubscriptions = new SubscriptionCollection();
    private commandSubscriptions = new Map<CommandsUsage, () => void>();

    public get length(): number {
        return this.commandSubscriptions.size;
    }

    private get isReady(): boolean {
        for (const commandsUsage of this.commandSubscriptions.keys()) {
            if (!commandsUsage.isReady$.getValue()) {
                return false;
            }
        }
        return true;
    }

    public get allCommandUsages(): CommandUsage[] {
        const filteredCommands = new GuidMap<CommandUsage>();
        for (const commandsUsage of this.commandSubscriptions.keys()) {
            for (const commandUsage of commandsUsage.commands) {
                if (!filteredCommands.has(commandUsage.id)) {
                    filteredCommands.set(commandUsage.id, commandUsage);
                }
            }
        }

        return Array.from(filteredCommands.values());
    }

    public [Symbol.iterator](): IterableIterator<CommandsUsage> {
        return this.commandSubscriptions.keys();
    }

    public addUsage(commandsUsage: CommandsUsage): void {
        if (!mapSome(this.commandSubscriptions, (x) => x[0].equals(commandsUsage))) {
            const commandSubscription = commandsUsage.subscribe();
            this.commandSubscriptions.set(commandsUsage, commandSubscription);
            if (!commandsUsage.isReady$.getValue()) {
                if (this.isReady$.getValue()) {
                    this.isReady$.next(false);
                }

                const subscription = commandsUsage.isReady$.subscribe((isReady) => {
                    if (!isReady) {
                        return;
                    }
                    if (this.isReady) {
                        this.isReady$.next(true);
                    }
                    this.commandsUsageReadySubscriptions.remove(subscription);
                    subscription.unsubscribe();
                });
                this.commandsUsageReadySubscriptions.add(subscription);
            }
        }
    }

    public addUsages(commandsUsages: CommandsUsage[] | CommandsUsageCollection): void {
        for (const commandsUage of commandsUsages) {
            this.addUsage(commandsUage);
        }
    }

    public clear(): void {
        this.commandsUsageReadySubscriptions.unsubscribeAll();
        for (const subscription of this.commandSubscriptions.values()) {
            subscription();
        }
        this.commandSubscriptions.clear();
    }

    public async createContextMenuItemsAsync(): Promise<ContextMenuItem[]> {
        await this.waitToBeReady();
        return CommandsUsage.createContextMenuItems(this.allCommandUsages);
    }

    public invalidateCanExecute(commandId: IGuid): void {
        for (const commandsUsage of this.commandSubscriptions.keys()) {
            commandsUsage.invalidateCanExecute(commandId);
        }
    }

    public invalidateDisplay(commandId: IGuid): void {
        for (const commandsUsage of this.commandSubscriptions.keys()) {
            commandsUsage.invalidateDisplay(commandId);
        }
    }

    public removeUsages(commandsUsages: CommandsUsage[] | CommandsUsageCollection): void {
        for (const commandsUsage of commandsUsages) {
            const subscription = this.commandSubscriptions.get(commandsUsage);
            if (subscription) {
                subscription();
                this.commandSubscriptions.delete(commandsUsage);
            }
        }
    }

    private async waitToBeReady(): Promise<void> {
        return this.isReady$
            .pipe(
                filter((isReady) => isReady),
                take(1),
                switchMap(() => of(undefined))
            )
            .toPromise();
    }
}
