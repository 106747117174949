<ng-template #nameTemplate let-params="params">
    <div class="flex-cell">
        <div class="incident-color" [style.background-Color]="params.data.argbColor | argb"></div>
        <div class="incident-type-icon-wrapper">
            <app-incident-icon class="incident-type-icon" [iconId]="params.data.iconId" width="24px" height="24px"></app-incident-icon>
        </div>
        <div class="va-center incident-name" [appTooltipOnlyIfOverflow]="true" [appTooltip]="params.data.name">
            {{ params.data.name }}
        </div>
    </div>
</ng-template>
<ng-template #ownerNameTemplate let-params="params">
    {{ params.data.ownerId | userName | async }}
</ng-template>

<ng-template #timeTemplate let-params="params">
    {{ params.data.triggerTimeUtc | dateFormatWithTimezone: 'MM/DD/yyyy h:mm A' | async }}
</ng-template>

<ng-template #locationNameTemplate let-params="params">
    <div class="flex-cell">
        <div *ngIf="params.data.Location?.entityId | locationIconPipe | async as icon">
            <gen-icon [icon]="icon" [size]="IconSize.Small"></gen-icon>
        </div>
        <div>
            {{ params.data.location?.entityId | incidentLocationName | async }}
        </div>
    </div>
</ng-template>

<ng-template #sortingLabelTemplate let-label="label" let-key="key">
    <div #divRef id="{{ key }}" (click)="onIncidentSortingChange(divRef, key)" class="sortCell h-100 w-100">
        {{ label }}
        <gen-icon [icon]="Icon.None" [size]="IconSize.Medium"></gen-icon>
    </div>
</ng-template>

<ng-template #priorityNameTemplate let-params="params">
    {{ params.data.priorityId | priorityName | async }}
</ng-template>

<ng-template #stateNameTemplate let-params="params">
    {{ params.data.stateId | stateName | async }}
</ng-template>

<container-element *ngIf="incidentServiceState$ | async as incidentServiceState" [ngSwitch]="incidentServiceState.state">
    <ng-container *ngSwitchCase="stateEnum.Unintialized">
        <div id="error-or-loading-height" class="ha-center">
            <gen-spinner [size]="SpinnerSize.Large">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="stateEnum.Running">
        <gen-app [genIsMaster]="false">
            <gen-header *ngIf="incidentComponentParameter.incidentTemplateStyle === IncidentTemplateStyle.List">
                <div class="pr-1 pl-1 flex-cell flex-cell-space">
                    <div class="flex-cell">
                        <app-incident-filter (incidentFilterChange)="onIncidentFilterChange($event)"></app-incident-filter>
                    </div>
                    <div class="flex-cell">
                        <gen-button class="trigger-incident-button" (click)="onTriggerIncidentClick()" [flavor]="ButtonFlavor.Flat" [hidden]="!hasTriggerIncidentPrivilege">
                            <gen-icon [icon]="Icon.Incident"></gen-icon>
                            {{ 'STE_TITLE_TRIGGERANINCIDENT' | translate }}
                        </gen-button>
                    </div>
                </div>
            </gen-header>
            <gen-main>
                <gen-spinner id="list-animation-spinner" [size]="SpinnerSize.Large" class="ha-center" *ngIf="!isListRendered">
                    {{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}
                </gen-spinner>
                <div
                    *ngIf="incidents$ | async as incidents"
                    [@inOutAnimation]
                    (@inOutAnimation.done)="isListRendered = true"
                    class="w-100 h-100"
                    #incidentListContainer
                    (appResizeWidth)="onResize($event)"
                >
                    <container-element [ngSwitch]="incidentComponentParameter.incidentTemplateStyle">
                        <ng-container *ngSwitchCase="IncidentTemplateStyle.List">
                            <gen-melted-table
                                #incidentsTable
                                class="w-100 h-100"
                                [@inOutAnimation]
                                (@inOutAnimation.done)="isListRendered = true"
                                id="incidents-table"
                                [genItemsSource]="incidents"
                                [genColumns]="columns"
                                [genSelectionType]="Table.SelectionType.Single"
                                [genFit]="Fit.Table.Fill"
                                [genDensity]="Density.Table.Compact"
                                (genCellClick)="onSelectItem($event.data)"
                                (genCellRightClick)="openContextMenu($event.data, $event.event)"
                                (contextmenu)="preventDefaultContext($event)"
                                infiniteScroll
                                [infiniteScrollDistance]="1"
                                [infiniteScrollUpDistance]="1"
                                [infiniteScrollContainer]="incidentComponentParameter.infiniteScrollContainer"
                                [infiniteScrollThrottle]="0"
                                (scrolled)="onScrollDown()"
                                (scrolledUp)="onScrollUp()"
                                [scrollWindow]="false"
                                [alwaysCallback]="true"
                                [infiniteScrollDisabled]="!isScrollEnabled"
                            >
                            </gen-melted-table>
                        </ng-container>
                        <ng-container *ngSwitchCase="IncidentTemplateStyle.Card">
                            <div
                                id="incident-cards-list"
                                class="w-100 h-100"
                                infiniteScroll
                                [infiniteScrollDistance]="1"
                                [infiniteScrollUpDistance]="1"
                                [infiniteScrollContainer]="incidentComponentParameter.infiniteScrollContainer"
                                [infiniteScrollThrottle]="0"
                                (scrolled)="onScrollDown()"
                                (scrolledUp)="onScrollUp()"
                                [scrollWindow]="false"
                                [alwaysCallback]="true"
                                [infiniteScrollDisabled]="!isScrollEnabled"
                                [fromRoot]="true"
                            >
                                <ul class="gen-list">
                                    <li *ngFor="let item of incidents" (contextmenu)="openContextMenu(item, $event)">
                                        <div class="w-100 p-relative">
                                            <gen-grid class="incident-grid p-1" [genColumns]="1" [genRows]="3">
                                                <gen-grid-item>
                                                    <div class="flex-cell h-100">
                                                        <div class="flex-cell incident-card-left">
                                                            <div class="incident-color-card" [style.background-Color]="item.argbColor | argb"></div>
                                                            <span class="pl-1 flex-image">
                                                                <app-incident-icon [iconId]="item.iconId" width="24px" height="24px"></app-incident-icon>
                                                            </span>
                                                            <gen-text class="va-center text-ellipsis" [flavor]="TextFlavor.Normal" [appTooltip]="item.name">
                                                                {{ item.name }} (#{{ item.displayId }})
                                                            </gen-text>
                                                        </div>
                                                        <div class="flex-cell incident-card-right">
                                                            {{ item.triggerTimeUtc | date: 'MM-dd h:mm aa' }}
                                                        </div>
                                                    </div>
                                                </gen-grid-item>
                                                <gen-grid-item>
                                                    <div class="flex-cell h-100">
                                                        <div class="flex-cell incident-card-left">
                                                            <div class="flex-cell" *ngIf="item.location">
                                                                <span class="flex-image">
                                                                    <gen-icon
                                                                        *ngIf="item.location.entityId | locationIconPipe | async as icon"
                                                                        [icon]="icon"
                                                                        class="image-24"
                                                                    ></gen-icon>
                                                                </span>
                                                                <gen-text [flavor]="TextFlavor.Secondary">
                                                                    {{ item.location.entityId | incidentLocationName | async }}
                                                                </gen-text>
                                                            </div>
                                                        </div>
                                                        <div class="flex-cell incident-card-right">
                                                            <gen-text [flavor]="TextFlavor.Secondary">
                                                                {{ item.priorityId | priorityName | async }}
                                                            </gen-text>
                                                        </div>
                                                    </div>
                                                </gen-grid-item>
                                                <gen-grid-item>
                                                    <div class="flex-cell h-100">
                                                        <div class="flex-cell incident-card-left">
                                                            <div class="flex-cell" *ngIf="item.ownerId">
                                                                <span class="flex-image">
                                                                    <gen-icon [icon]="Icon.Person" class="image-24"> </gen-icon>
                                                                </span>
                                                                <gen-text [flavor]="TextFlavor.Secondary">
                                                                    {{ item.ownerId | userName | async }}
                                                                </gen-text>
                                                            </div>
                                                        </div>
                                                        <div class="flex-cell incident-card-right">
                                                            <gen-text [flavor]="TextFlavor.Secondary">
                                                                {{ item.stateId | stateName | async }}
                                                            </gen-text>
                                                        </div>
                                                    </div>
                                                </gen-grid-item>
                                            </gen-grid>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </container-element>
                </div>
            </gen-main>
        </gen-app>
    </ng-container>

    <ng-container *ngSwitchCase="stateEnum.ErrorRetrying">
        <div id="error-or-loading-height" class="ha-center">
            <gen-spinner [size]="SpinnerSize.Large">
                {{ (incidentServiceState.error!.message | translate) + (incidentServiceState.details ? ' - ' + (incidentServiceState.details | translate) : '') }}
            </gen-spinner>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="stateEnum.ErrorUnrecoverable">
        <div id="error-or-loading-height">
            <gen-text [flavor]="TextFlavor.Error" class="ha-center">
                {{ (incidentServiceState.error!.message | translate) + (incidentServiceState.details ? ' - ' + (incidentServiceState.details | translate) : '') }}
            </gen-text>
        </div>
    </ng-container>
</container-element>
