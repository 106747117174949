// Autogenerated: v2/help/enumerations/ApiAccessPointRuleType?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class AccessPointRuleType {
    public static Permanent = 'Permanent';
    public static Temporary = 'Temporary';

    public static readonly values = [
        AccessPointRuleType.Permanent,
        AccessPointRuleType.Temporary
    ];
}
