import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GenMeltedItem, GenPopup, GenTableColumn, TableCellType, TableColumnPin } from '@genetec/gelato-angular';
import { JoystickButtonConfig, JoystickLocalConfig, JoystickService } from '@modules/general/services/joystick.service';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { COMMANDS_SERVICE, USER_SETTINGS_SERVICE, SettingsService, CommandsService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { ButtonGamepadEventData, GamepadEventData, GamepadEventListenerService } from '@modules/general/services/gamepad-event-listener.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

enum JoystickOptionsColumn {
    DownCommand = 'downCommand',
    DownArgs = 'downArgs',
    UpCommand = 'upCommand',
    UpArgs = 'upArgs',
}

export enum JoystickLocalConfigIds {
    ActiveJoystick = 'ActiveJoystick',
    JoysticksConfigsList = 'JoysticksConfigsList',
    Buttons = 'Buttons',
}

interface JoystickButtonRow {
    id: { index: number; uiGuid: SafeGuid };
    [JoystickOptionsColumn.DownCommand]?: GenMeltedItem | null;
    [JoystickOptionsColumn.DownArgs]: string;
    [JoystickOptionsColumn.UpCommand]?: GenMeltedItem | null;
    [JoystickOptionsColumn.UpArgs]: string;
}

@UntilDestroy()
@Component({
    selector: 'app-joystick-options',
    templateUrl: './joystick-options.component.html',
    styleUrls: ['./joystick-options.component.scss'],
})
@InternalPluginDescriptor({
    type: JoystickOptionsComponent,
    pluginTypes: [PluginTypes.Option],
    exposure: {
        id: JoystickOptionsComponent.pluginId,
        subSection: OptionSubSections.Peripherals,
    },
    isSupported: () => true,
})
export class JoystickOptionsComponent extends TrackedComponent implements OnInit, AfterViewInit {
    //#region Constants

    public static pluginId = SafeGuid.parse('623BC8FC-EA36-464A-A2E1-5DEB81BD6F6C');

    //#endregion

    //#region Fields
    @ViewChild('numericCommandPopup') private numericCommandPopup?: GenPopup;
    @ViewChild('actionsDownComboTemplate') private actionsDownComboTemplate?: TemplateRef<any>;
    @ViewChild('actionsUpComboTemplate') private actionsUpComboTemplate?: TemplateRef<any>;
    @ViewChild('actionsDownArgsTemplate') private actionsDownArgsTemplate?: TemplateRef<any>;
    @ViewChild('actionsUpArgsTemplate') private actionsUpArgsTemplate?: TemplateRef<any>;
    @ViewChild('coloredLabelOnEventCellRenderer') private coloredLabelOnEventCellRenderer?: TemplateRef<any>;

    public enableJoystick = false;
    public deadZone: number;
    public joysticksConfig: JoystickLocalConfig[] = [];

    public availableJoysticks: Gamepad[] = [];

    public currentJoystick: Gamepad | null = null;

    public buttonColumns: GenTableColumn[] = [];

    public buttonRows: JoystickButtonRow[] = [];

    public buttonActions: GenMeltedItem[] = [];

    public hasGamepadConneted = false;

    public isInitialized = false;
    public isContentVisible = false;

    private supportedCommands: IGuid[] = [
        SharedCommands.AddBookmark,
        SharedCommands.ClearTile,
        SharedCommands.ClearTiles,
        SharedCommands.ExpandTile,
        SharedCommands.ExportVideo,
        SharedCommands.ToggleListen,
        SharedCommands.FastForward,
        SharedCommands.MaximizeTileFullscreen,
        SharedCommands.PauseVideo,
        SharedCommands.PlayVideo,
        SharedCommands.Rewind,
        SharedCommands.SaveSnapshot,
        SharedCommands.SelectPreviousTile,
        SharedCommands.SelectNextTile,
        SharedCommands.SlowMotion,
        SharedCommands.StartStopRecording,
    ];

    private readonly defaultDeadzoneValue = 5;

    //#endregion

    //#region Constructor

    constructor(
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService,
        private translateService: TranslateService,
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        private gamepadService: GamepadEventListenerService
    ) {
        super(trackingService);

        this.deadZone = this.defaultDeadzoneValue;

        this.gamepadService.onGamepadsConnectedChange$.pipe(untilDestroyed(this)).subscribe((gamepads) => {
            this.onConnectedGamepadsChanged(gamepads);
        });

        this.gamepadService.onNewGamepadEvents$.pipe(untilDestroyed(this)).subscribe((events) => {
            this.onNewGamepadEvents(events);
        });
    }

    //#endregion

    //#region Public Methods

    ngOnInit() {
        super.ngOnInit();
        const contentVisibility = this.userSettingsService.getOptionAvailability(OptionTypes.Peripherals);
        if (contentVisibility) {
            this.isContentVisible = true;
            this.loadJoystickSettingsFromStorage();
        }
        this.isInitialized = true;
    }

    // The table uses a TemplateRef for a custom renderer, which is only available after the HTML is rendered
    async ngAfterViewInit(): Promise<void> {
        if (!this.isContentVisible) {
            return;
        }

        this.buttonColumns = [
            {
                columnName: 'id',
                label: '',
                cellType: TableCellType.Custom,
                cellTemplate: this.coloredLabelOnEventCellRenderer,
                pin: TableColumnPin.Left,
                maxWidth: 50,
                suppressSorting: true,
                suppressMovable: true,
            },
            {
                columnName: JoystickOptionsColumn.DownCommand,
                label: this.translateService.instant('STE_LABEL_DOWNCOMMAND') as string,
                cellType: TableCellType.Custom,
                cellTemplate: this.actionsDownComboTemplate,
                pin: TableColumnPin.Left,
                suppressSorting: true,
                suppressMovable: true,
                minWidth: 110,
            },
            {
                columnName: JoystickOptionsColumn.DownArgs,
                label: this.translateService.instant('STE_LABEL_ARGUMENTS') as string,
                cellType: TableCellType.Custom,
                cellTemplate: this.actionsDownArgsTemplate,
                pin: TableColumnPin.Left,
                suppressSorting: true,
                suppressMovable: true,
                minWidth: 80,
            },
            {
                columnName: JoystickOptionsColumn.UpCommand,
                label: this.translateService.instant('STE_LABEL_UPCOMMAND') as string,
                cellType: TableCellType.Custom,
                cellTemplate: this.actionsUpComboTemplate,
                pin: TableColumnPin.Left,
                suppressSorting: true,
                suppressMovable: true,
                minWidth: 100,
            },
            {
                columnName: JoystickOptionsColumn.UpArgs,
                label: this.translateService.instant('STE_LABEL_ARGUMENTS') as string,
                cellType: TableCellType.Custom,
                cellTemplate: this.actionsUpArgsTemplate,
                pin: TableColumnPin.Left,
                suppressSorting: true,
                suppressMovable: true,
                minWidth: 80,
            },
        ];

        // read the commands informations
        const commandsMap = new Map<string, GenMeltedItem>();

        // retrieve all the supported commands
        const allCommands = await this.commandsService.getCommandsAsync(this.supportedCommands);

        this.buttonActions.length = 0;
        this.supportedCommands.forEach((id) => {
            const command = allCommands.find((x) => x.id.equals(id));
            if (command) {
                const item: GenMeltedItem = {
                    id: id.toString().toLowerCase(),
                    text: command.name(),
                };
                this.buttonActions.push(item);
                commandsMap.set(id.toString().toLowerCase(), item);
            }
        });

        // order by name
        this.buttonActions.sort((a, b) => a.text.localeCompare(b.text));

        // read the buttons settings
        this.buttonRows = [];
        const buttons = this.userSettingsService.get<string>(OptionTypes.Peripherals, JoystickLocalConfigIds.Buttons);
        if (buttons) {
            const configs = JSON.parse(buttons) as JoystickButtonConfig[];
            configs.forEach((item) => {
                const downCommand = commandsMap.get(item.downCommand.toLowerCase());
                const upCommand = commandsMap.get(item.upCommand.toLowerCase());

                this.buttonRows.push({ id: { index: item.id, uiGuid: SafeGuid.newGuid() }, downCommand, downArgs: item.downArgs, upCommand, upArgs: item.upArgs });
            });
        }

        // ensure we have enough buttons or apply the default settings
        for (let i = this.buttonRows.length + 1; i <= JoystickService.maxButtons; ++i) {
            this.buttonRows.push({ id: { index: i, uiGuid: SafeGuid.newGuid() }, downCommand: null, downArgs: '', upCommand: null, upArgs: '' });
        }
    }

    //#endregion

    //#region Event Handlers

    public onActiveJoystickSelectionChanged(joystickId: string | null): void {
        if (joystickId) {
            // Set the new current joystick id
            if (joystickId !== this.currentJoystick?.id) {
                this.currentJoystick = this.gamepadService.getGamepadState(joystickId);
                this.userSettingsService.set(OptionTypes.Peripherals, JoystickLocalConfigIds.ActiveJoystick, joystickId);

                //Change the deadzone to match the setting associated with the new joystick selected
                this.updateDeadzoneValue(joystickId);
            }
        } else {
            this.currentJoystick = null;
            this.userSettingsService.set(OptionTypes.Peripherals, JoystickLocalConfigIds.ActiveJoystick, '');
        }
        this.gamepadService.setCurrentGamepadId(joystickId);
    }

    public onDeadZoneModified(deadZone: number): void {
        if (this.currentJoystick) {
            const joystickConfig = this.joysticksConfig.find((x) => x.joystickId === this.currentJoystick?.id);
            if (joystickConfig) {
                joystickConfig.deadZone = deadZone;
            } else {
                this.joysticksConfig.push({ joystickId: this.currentJoystick.id, deadZone });
            }
            const json = JSON.stringify(this.joysticksConfig);
            this.userSettingsService.set(OptionTypes.Peripherals, JoystickLocalConfigIds.JoysticksConfigsList, json);
        }
    }

    public onConnectedGamepadsChanged(connectedGamepads: Gamepad[]): void {
        this.availableJoysticks = connectedGamepads;
        this.hasGamepadConneted = connectedGamepads.length > 0;
        this.loadJoystickSettingsFromStorage();
    }

    public onButtonsConfigModified(): void {
        const config: JoystickButtonConfig[] = [];
        this.buttonRows.forEach((x) => {
            // GenTableRow is type any, we have no typings
            let downId = '';
            if (x.downCommand?.id) {
                downId = x.downCommand.id.toLowerCase().toString();
            }

            let upId = '';
            if (x.upCommand?.id) {
                upId = x.upCommand.id.toLowerCase().toString();
            }

            config.push(new JoystickButtonConfig(x.id.index, downId, x.downArgs, upId, x.upArgs));
        });

        const json = JSON.stringify(config);
        this.userSettingsService.set(OptionTypes.Peripherals, JoystickLocalConfigIds.Buttons, json);
    }

    public async toggleNumericCommandPopupInfo(): Promise<void> {
        await this.numericCommandPopup?.toggle();
    }

    private updateDeadzoneValue(joystickId?: string): void {
        const id = joystickId ?? this.currentJoystick?.id;
        if (!id || !this.joysticksConfig) this.deadZone = this.defaultDeadzoneValue;
        else this.deadZone = this.joysticksConfig?.find((conf) => conf.joystickId === id)?.deadZone ?? this.defaultDeadzoneValue;
    }

    private onNewGamepadEvents(events: GamepadEventData[]): void {
        for (const event of events) {
            if (event instanceof ButtonGamepadEventData && this.buttonRows.length > event.data.index) {
                const htmlTag: HTMLElement = document.getElementById('idCell-' + this.buttonRows[event.data.index].id.uiGuid.toString()) as HTMLElement;

                if (event.data.isButtonDown) {
                    // add btn pressed css class
                    if (!htmlTag.classList.contains('joystick-btn-pressed')) {
                        htmlTag.classList.add('joystick-btn-pressed');
                    }
                } else {
                    // remove css class
                    if (htmlTag.classList.contains('joystick-btn-pressed')) {
                        htmlTag.classList.remove('joystick-btn-pressed');
                    }
                }
            }
        }
    }

    private loadJoystickSettingsFromStorage() {
        const activeJoystickId = this.userSettingsService.get<string>(OptionTypes.Peripherals, JoystickLocalConfigIds.ActiveJoystick);
        if (activeJoystickId) {
            this.currentJoystick = this.gamepadService.getGamepadState(activeJoystickId);
        }
        const serialized = this.userSettingsService.get<string>(OptionTypes.Peripherals, JoystickLocalConfigIds.JoysticksConfigsList);
        if (serialized) {
            const joystickLocalSettings = JSON.parse(serialized) as JoystickLocalConfig[];
            if (joystickLocalSettings) {
                this.joysticksConfig = joystickLocalSettings;
                this.updateDeadzoneValue();
            }
        }
    }
}
