import { ChangeDetectorRef, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { ButtonFlavor, Icon, ItemSlot } from '@genetec/gelato';
import { DateFormat } from '@genetec/gelato-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { Content, ContentGroup } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentLoad } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { focusDomElement } from '@modules/shared/utilities/dom-helper';
import { SafeGuid } from 'safeguid';
import { CustomIconState } from '@modules/shared/api/api';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ContentOverlayService } from '@modules/shared/services/content-overlay/content-overlay.service';
import { PluginsStack } from '../plugins/plugins-stack';
import { TrackedComponent } from '../tracked/tracked.component';
import { SidePaneHeaderInfo } from './sidepane-header/sidepane-header.component';

// ==========================================================================
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@UntilDestroy()
@Component({
    selector: 'app-side-pane',
    templateUrl: './side-pane.component.html',
    styleUrls: ['./side-pane.component.scss'],
})
export class SidePaneComponent extends TrackedComponent implements OnInit {
    public static readonly WidthPx = 450;

    @Input()
    public elementId = 'appRightSidePane';

    @Input()
    public contextDataService!: ContentService;

    public pluginsStack: PluginsStack = new PluginsStack();

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly ItemSlot = ItemSlot;

    public headerInfo?: SidePaneHeaderInfo;
    public timestamp?: string;
    public color = '';
    public contentCount$: Observable<number>;

    public readonly defaultHeaderInfo: SidePaneHeaderInfo = {
        title: '',
        titleColor: '',
        titleIcon: '',
        titleCustomIconState: CustomIconState.All,
        description: '',
    };

    public isLoading = false;
    public hasContent = true;
    public hasCustomHeader = false;
    public sidePaneHeaderPluginItem?: PluginItem;

    private contentCountSubject = new BehaviorSubject<number>(0);
    private mainContentTimestamp?: Date;
    private deviceTimezone?: string;

    constructor(
        private pluginService: PluginService,
        private timeService: TimeService,
        trackingService: TrackingService,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(ContentOverlayService) @Optional() private contentOverlayService?: ContentOverlayService
    ) {
        super(trackingService);
        this.contentCount$ = this.contentCountSubject.asObservable();
    }

    ngOnInit() {
        super.ngOnInit();

        this.contextDataService?.currentContent$.pipe(untilDestroyed(this)).subscribe(async (content) => {
            // push count in subject
            this.contentCountSubject.next(this.contextDataService?.contentCount);
            await this.currentContentChanged(content);
        });

        this.contentCount$.pipe(untilDestroyed(this)).subscribe((count) => {
            if (this.headerInfo) {
                this.headerInfo.contentCount = count;
            }
        });

        this.timeService.timezone$.pipe(untilDestroyed(this)).subscribe((timezone) => {
            if (this.mainContentTimestamp) {
                this.timestamp = this.timeService.formatTime(
                    this.mainContentTimestamp,
                    DateFormat.DateTime,
                    true,
                    true,
                    this.timeService.useDeviceTimezone ? this.deviceTimezone : timezone
                );
                if (this.headerInfo) {
                    this.headerInfo.timestamp = this.timestamp;
                }
            }
        });
    }

    public closeSidePane(): void {
        this.contextDataService?.clearMainContent();
        this.contentOverlayService?.clearOverlay();
    }

    public onNavigateBack(): void {
        this.contextDataService?.popContent();
    }

    private async currentContentChanged(content: ContentGroup | null) {
        const sidepane = document.getElementById(this.elementId);

        let isLoadingContent = false;
        this.pluginsStack.empty();

        if (sidepane !== null) {
            if (content) {
                const mainContent = content.mainContent;

                isLoadingContent = mainContent.type.equals(ContentLoad.typeId);
                if (!isLoadingContent) {
                    const plugins = await this.getPlugins(content);

                    this.timestamp = this.getTimestamp(mainContent);
                    this.color = this.getNoAlphaColor(mainContent.color);
                    this.headerInfo = this.getHeaderInfo(mainContent);

                    await this.setCustomHeader(mainContent);

                    this.pluginsStack.fill(this.getSortedPlugins(plugins));
                }
            }

            this.isLoading = isLoadingContent;
            this.setAdditionalStyling(sidepane, content === null);
        }

        this.changeDetectorRef.markForCheck();
    }

    private getTimestamp(mainContent: Content) {
        if (mainContent.parameters?.hasField('timestamp')) {
            this.mainContentTimestamp = mainContent.parameters.getFieldDate('timestamp');
            this.deviceTimezone = mainContent.parameters.getFieldOrDefault<string>('timezone');
            const useSmartTimestamp = mainContent.parameters.getFieldOrDefault<boolean>('smartTimestamp', true);
            return this.timeService.formatTime(this.mainContentTimestamp, DateFormat.DateTime, true, true, this.deviceTimezone, useSmartTimestamp);
        }
    }

    private getNoAlphaColor(color: string) {
        if (color) {
            return `#' + ${color.slice(3)}`; // removes alpha
        } else {
            return 'black';
        }
    }

    private getHeaderInfo(mainContent: Content) {
        const header = {
            ...this.defaultHeaderInfo,
            title: mainContent.title,
            titleColor: mainContent.color,
            titleIcon: mainContent.icon,
            titleCustomIconId: mainContent.customIconId,
            description: mainContent.description,
            timestamp: this.timestamp,
        };

        const tryGuid = SafeGuid.tryParse(mainContent.source);
        // Take care, the Datum source might not necessarily be an entity
        if (tryGuid.success) {
            header.entityId = tryGuid.value;
        }

        return header;
    }

    private async getPlugins(contentGroup: ContentGroup) {
        const plugins = await this.pluginService.getPlugins(PluginTypes.Widget, contentGroup.mainContent);
        if (contentGroup.subContents) {
            for (const subContent of contentGroup.subContents) {
                const subPlugins = await this.pluginService.getPlugins(PluginTypes.Widget, subContent);
                subPlugins.forEach((item) => {
                    plugins.push(item);
                });
            }
        }
        return plugins;
    }

    private async setCustomHeader(mainContent: Content) {
        if (mainContent.parameters?.hasField('customHeaderId')) {
            const customHeaderId = mainContent.parameters.getFieldGuid('customHeaderId');
            this.sidePaneHeaderPluginItem = (await this.pluginService.getPlugins(PluginTypes.SidePaneHeader, null)).find((plugin) =>
                plugin.exposure.subSection?.equals(customHeaderId)
            );
            if (this.sidePaneHeaderPluginItem) {
                this.sidePaneHeaderPluginItem.data = this.headerInfo;
            }
            this.hasCustomHeader = !!this.sidePaneHeaderPluginItem;
        } else {
            this.hasCustomHeader = false;
        }
    }

    private getSortedPlugins(plugins: PluginItem[]) {
        // Sort by priority
        return plugins.sort((p1, p2) => {
            const priority1 = p1 === undefined || p1.exposure.priority === undefined ? 0 : p1.exposure.priority;
            const priority2 = p2 === undefined || p2.exposure.priority === undefined ? 0 : p2.exposure.priority;

            return priority1 - priority2;
        });
    }

    private setAdditionalStyling(sidepane: HTMLElement, isContentEmpty: boolean) {
        // TODO YN: Do not hardcode context pan width...
        //A null content indicates to close the side pane right now, while undefined means that the content is unavailable and we should display an error message
        if ((this.pluginsStack.verticalPlugins && this.pluginsStack.verticalPlugins.length > 0) || this.isLoading) {
            sidepane.style.width = `${SidePaneComponent.WidthPx}px`;
            sidepane.style.opacity = '1';
            sidepane.classList.add('open');
            this.hasContent = true;
            focusDomElement('.sidepane-container', sidepane);
            // Keep this check (undefined can enter)
        } else if (isContentEmpty || !this.pluginsStack.verticalPlugins) {
            sidepane.style.width = '0px';
            sidepane.style.opacity = '0';
            sidepane.classList.remove('open');
        } else if (this.pluginsStack.verticalPlugins.length === 0 && !this.isLoading) {
            sidepane.style.width = `${SidePaneComponent.WidthPx}px`;
            sidepane.style.opacity = '1';
            sidepane.classList.add('open');
            this.hasContent = false;
            focusDomElement('.sidepane-container', sidepane);
        }
    }
}
