import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { SelectionType } from '@genetec/gelato';
import { TreeItem } from '@modules/shared/interfaces/tree-item/tree-item';

/**
 * @description this component is meant to be used hand in hand with the tree-data-source.directive.ts,
 * altough you should be able to nest this in a <gen-tree> and it should work just fine.
 *
 * @example
 *
 * <gen-tree>
 *   <app-tree-items slot="gen-tree-item" [dataSource]="yourDataSource" [onItemClick]="onItemClickCallback.bind(this)"></app-tree-items>
 * </gen-tree
 */
@Component({
    selector: 'app-tree-items',
    templateUrl: './tree-items.component.html',
})
export class TreeItemsComponent {
    @ViewChild('template') template!: TemplateRef<any>;
    @Input() public dataSource?: TreeItem[];
    @Input() public set onItemClick(callback: (item: TreeItem, event: CustomEvent<boolean>) => any) {
        if (callback) {
            this.itemCallbackToUse = callback;
        } else {
            this.itemCallbackToUse = this.sendNotInitializedWarning;
        }
    }

    public readonly SelectionType = SelectionType;

    /**
     * defaults to an empty function.
     *
     * @param item tree item to act upon
     */
    public itemCallbackToUse = (item: TreeItem, event: CustomEvent<boolean>): void => {};

    public sendNotInitializedWarning = (item: TreeItem, event: CustomEvent<boolean>): void => {
        console.warn('attach a appTreeItemClick function callback on the gen-tree.');
    };
}
