import { EventBase } from 'RestClient/Client/Event/EventBase';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class MapObjectPositionUpdateEvent extends EventBase {
    public get latitude(): number {
        return this.getField<number>('latitude');
    }

    public get longitude(): number {
        return this.getField<number>('longitude');
    }

    public get altitude(): number {
        return this.getField<number>('altitude');
    }

    public get speed(): number {
        return this.getField<number>('speed');
    }

    public get heading(): number {
        return this.getField<number>('heading');
    }

    public get runningState(): string {
        return this.getField<string>('runningState');
    }
}
