import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenModalService } from '@genetec/gelato-angular';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { twentyFourHoursValidator } from '@modules/shared/validators/twenty-four-hours-validator';
import { ExportRequestParameter, VideoExportService } from '@modules/video/services/video-export.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import moment from 'moment';
import { ICameraEntity } from 'RestClient/Client/Interface/ICameraEntity';
import { CameraEntity } from 'RestClient/Client/Model/Video/CameraEntity';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, startWith, take } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { ThumbnailRangeRequest, VideoClient } from '../../api/api';
import { ExportVideoAuthorizationComponent } from '../export-video-authorization/export-video-authorization.component';
import { ExportVideoFormGroup } from './export-video.form-group';
import { FormatMenuItem } from './format-menu-item';
import { timeRangeValidator } from './validators/time-range-validator';

@UntilDestroy()
@Component({
    selector: 'app-export-video',
    templateUrl: './export-video.component.html',
    styleUrls: ['./export-video.component.scss'],
})
export class ExportVideoComponent extends ConnectionAwareModalComponent implements OnInit, OnDestroy {
    //#region Fields

    public cameraName = '';
    public entityId = SafeGuid.EMPTY;
    public encrypt = false;
    public encryptionKey = '';
    public exportAudio = false;
    public readonly formats: FormatMenuItem[] = [];
    public fromAuthorizationComponent = false;
    public form: ExportVideoFormGroup;
    public duration = '';
    public hasExportPrivilege = false;
    public readonly timeFormatInFilename = '-YYYY-MM-DD_HH[h]mm[min]ss[s]SSS[ms]';

    public get endTimeError(): string {
        if (this.form.errors?.timeRange) {
            return this.translateService.instant('STE_MESSAGE_ERROR_INVALIDTIMERANGE') as string;
        }

        if (this.form.errors?.twentyFourHours) {
            return this.translateService.instant('STE_MESSAGE_ERROR_EXPORTOVER24HOURS') as string;
        }

        return '';
    }

    private durationSubject = new BehaviorSubject<string>('5 m');
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public duration$?: Observable<string> = this.durationSubject.asObservable();

    private thumbnailSubject = new BehaviorSubject<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public thumbnail$: Observable<string | null> = this.thumbnailSubject.asObservable();

    private defaultFormat: keyof typeof this.formatsDefinition = 'MP4';

    private formatsDefinition = {
        G64: {
            id: 'G64',
            text: `G64 (${this.translateService.instant('STE_LABEL_COMPATIBILITYMODE_LC') as string})`,
            supportsAudio: false,
            supportsEncryption: false,
        },
        G64x: {
            id: 'G64x',
            text: 'G64x',
            supportsAudio: false,
            supportsEncryption: true,
        },
        MP4: {
            id: 'MP4',
            text: 'MP4',
            supportsAudio: true,
            supportsEncryption: false,
        },
        ASF: {
            id: 'ASF',
            text: 'ASF',
            supportsAudio: true,
            supportsEncryption: false,
        },
    };

    //#endregion

    //#region Constructors

    constructor(
        authService: AuthService,
        injector: Injector,
        trackingService: TrackingService,
        private timeService: TimeService,
        private translateService: TranslateService,
        private videoClient: VideoClient,
        private videoExportService: VideoExportService,
        private modalService: GenModalService,
        private securityCenterClientService: SecurityCenterClientService
    ) {
        super(authService, injector, trackingService);

        this.hasExportPrivilege = this.securityCenterClientService.scClient.isGlobalPrivilegeGranted(KnownPrivileges.singleUserExportPrivilege);

        // setup before OnInit because startTime and endTime can be changed later
        this.formats = this.getFormats();

        this.form = new FormGroup<ExportVideoFormGroup['controls']>(
            {
                filename: new FormControl('', {
                    validators: [Validators.required],
                    nonNullable: true,
                }),
                description: new FormControl('', {
                    validators: this.hasExportPrivilege ? [] : [Validators.required],
                    nonNullable: true,
                }),
                startTime: new FormControl(moment().subtract(5, 'minutes').toISOString(), {
                    validators: [Validators.required],
                    nonNullable: true,
                }),
                endTime: new FormControl(moment().toISOString(), {
                    validators: [Validators.required],
                    nonNullable: true,
                }),
                format: new FormControl(this.formatsDefinition[this.defaultFormat], {
                    nonNullable: true,
                }),
                exportAudio: new FormControl(false, {
                    nonNullable: true,
                }),
                encrypt: new FormControl(false, {
                    nonNullable: true,
                }),
                encryptionKey: new FormControl('', {
                    nonNullable: true,
                }),
            },
            { validators: [timeRangeValidator, twentyFourHoursValidator] }
        );

        combineLatest([this.form.controls.format.valueChanges, this.form.controls.encrypt.valueChanges])
            .pipe(untilDestroyed(this))
            .subscribe(([format, encrypt]: [FormatMenuItem, boolean]) => {
                this.setEncryptionKeyValidators(format, encrypt);
            });

        this.durationSubject.next(this.computeDuration(this.form.getRawValue().startTime, this.form.getRawValue().endTime));
    }

    //#endregion

    //#region Methods

    ngOnInit() {
        super.ngOnInit();

        if (!this.fromAuthorizationComponent) {
            this.securityCenterClientService.scClient
                .getEntityAsync<CameraEntity, ICameraEntity>(CameraEntity, this.entityId)
                .then((cameraEntity) => {
                    this.cameraName = cameraEntity?.name ?? '';
                    this.form.controls.filename.setValue(`${this.cameraName}${moment(this.form.value.startTime).format(this.timeFormatInFilename)}`);
                    this.form.controls.filename.markAsUntouched();
                })
                .fireAndForget();
        }

        combineLatest([
            this.form.controls.startTime.valueChanges.pipe(startWith(this.form.value.startTime)),
            this.form.controls.endTime.valueChanges.pipe(startWith(this.form.value.endTime)),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([startTime, endTime]) => {
                if (startTime && endTime) {
                    this.durationSubject.next(this.computeDuration(startTime, endTime));

                    this.updateThumbnail(startTime, endTime);
                }
            });

        this.form.controls.startTime.valueChanges
            .pipe(filter(() => !this.form.controls.filename.touched))
            .pipe(untilDestroyed(this))
            .subscribe((startTime: string) => {
                this.form.controls.filename.setValue(`${this.cameraName}${moment(startTime).format(this.timeFormatInFilename)}`);
                this.form.controls.filename.markAsUntouched();
            });
    }

    public onSubmit = (): Promise<boolean> => {
        if (!this.hasExportPrivilege) {
            const exportVideoAuthorizationComponent = this.modalService.open(ExportVideoAuthorizationComponent, {
                entityId: this.entityId,
                cameraName: this.cameraName,
                thumbnail: this.thumbnailSubject.getValue(),
                duration: this.durationSubject.getValue(),
            });
            exportVideoAuthorizationComponent.exportVideoForm = this.form;
        } else {
            const formRawValue = this.form.getRawValue();
            const request: ExportRequestParameter = {
                entityId: this.entityId,
                startTime: formRawValue.startTime,
                endTime: formRawValue.endTime,
                supportsEncryption: formRawValue.format?.supportsEncryption,
                encrypt: formRawValue.encrypt,
                encryptionKey: formRawValue.encryptionKey,
                exportAudio: formRawValue.exportAudio,
                format: formRawValue.format,
                description: formRawValue.description,
            };
            const modelValue = this.videoExportService.buildVideoExportRequest(request);
            if (modelValue && this.form.value.filename) {
                this.videoExportService.exportAsync(modelValue, this.form.value.filename).fireAndForget();
            }
        }

        return Promise.resolve(true);
    };

    //#endregion
    private setEncryptionKeyValidators(format: FormatMenuItem, encrypt: boolean): void {
        const required = format.supportsEncryption && encrypt;
        this.form.controls.encryptionKey.setValidators(required ? Validators.required : []);
        this.form.controls.encryptionKey.updateValueAndValidity();
    }

    private computeDuration(startTime: string, endTime: string): string {
        const length = moment(endTime).diff(moment(startTime), 'ms');

        return this.timeService.formatDuration(length);
    }

    private getFormats() {
        return Object.values(this.formatsDefinition);
    }

    private updateThumbnail(startTime: string, endTime: string): void {
        if (this.videoClient) {
            const request = new ThumbnailRangeRequest({
                cameraId: this.entityId,
                width: 192,
                startTime: moment(startTime).toISOString(),
                endTime: moment(endTime).toISOString(),
            });

            this.videoClient
                .getThumbnail(request)
                .pipe(take(1), untilDestroyed(this))
                .subscribe((res) => this.thumbnailSubject.next(!res?.thumbnail ? null : `data:image/png;base64,${res.thumbnail}`));
        }
    }
}
