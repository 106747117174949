import { IGuid } from 'safeguid';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.ChangeDescription;

export class ChangeDescriptionCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentId: IGuid, description: string) {
        super(
            `/v2/Incidents/${incidentId.toString()}`,
            JSON.stringify({
                Type: commandType,
                Description: description,
            })
        );
    }

    public errorMessage(statusCode?: number): string {
        return 'STE_MESSAGE_ERROR_EDIT_INCIDENT_DESCRIPTION_FAILED';
    }
}
