import { Radian } from './Radian';
import { Vec2 } from './Vec2';

export class PolarVector {
  private readonly m_radius: number;
  private readonly m_angle: Radian;

  public get Radius(): number {
    return this.m_radius;
  }

  public get Angle(): Radian {
    return this.m_angle;
  }

  constructor(radius: number, angle: Radian) {
    this.m_radius = radius;
    this.m_angle = angle;
  }

  public toString(): string {
    return `[${this.m_radius}, ${this.m_angle.toString()}]`;
  }

  public rotate(pan: Radian): PolarVector {
    return new PolarVector(this.m_radius, this.m_angle.add(pan));
  }

  public tilt(tilt: number): PolarVector {
    return new PolarVector(this.m_radius + tilt, this.m_angle);
  }

  public toCartesian(): Vec2 {
    return new Vec2(this.m_radius * Math.cos(this.m_angle.InRadian), this.m_radius * Math.sin(this.m_angle.InRadian));
  }
}
