/**
 * This enum is used to add a context to a log entry.
 */
export enum EventMonitoringContext {
    // This context refers to the watchlist
    Watchlist = 'Watchlist',
    // This context refers to the edit watchlist
    EditWatchlist = 'Edit watchlist',
    // This context refers to the contextual menu "Add to watchlist" command
    WatchlistCommand = 'Watchlist command',
}
