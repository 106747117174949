import { IMapEntity } from 'RestClient/Client/Interface/IMapEntity';
import { IGuid } from 'safeguid';
import { CustomMapProvider, CustomMapProviderOptions, MapOptions, MarkerClusterGroupOptions } from '@genetec/web-maps';
import { MapProviderTypes } from './map-provider-types';
import { MapProvider, MapProviderLoaderOptions } from './map-provider.interface';
import { MapLoader } from './map-loader';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class FallbackGeoreferencedMapProviderLoader implements MapProvider {
    private static readonly maxNativeZoom = 18;

    private static readonly defaultURL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

    public readonly type: IGuid = MapProviderTypes.Custom;

    constructor(private mapLoader: MapLoader) {}

    public async loadMapAsync(map: IMapEntity, options: MapProviderLoaderOptions): Promise<boolean> {
        const markerClustering: MarkerClusterGroupOptions = {
            chunkedLoading: true,
            maxClusterRadius: 50,
            spiderfyOnMaxZoom: false,
            zoomToBoundsOnClick: true,
        };

        const mapOptions: MapOptions = {
            clustering: markerClustering,
            closePopupOnClick: false,
            minZoom: 1.0,
            maxZoom: map.maxZoom,
            zoom: options.zoom ?? 4,
            maxBounds: [
                [-90.0, -220.0],
                [90.0, 220.0],
            ],
            bounceAtZoomLimits: false,
            inertia: false,
            maxBoundsViscosity: 1.0,
            userLocationEnabled: options.userLocationEnabled,
            initialBounds: options.defaultView,
            center: options.center,
        };

        const mapProviderOptions: CustomMapProviderOptions = {
            url: FallbackGeoreferencedMapProviderLoader.defaultURL,
            maxNativeZoom: FallbackGeoreferencedMapProviderLoader.maxNativeZoom,
            maxZoom: map.maxZoom,
            bounds: [
                [-90.0, -180.0],
                [90.0, 180.0],
            ],
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        };

        await this.mapLoader.loadMapAsync(CustomMapProvider, mapOptions, mapProviderOptions);

        this.mapLoader.map?.setGeolocationEnabled(true);

        return true;
    }
}
