import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Icon, ImageFit, ImageFlavor, TextFlavor } from '@genetec/gelato';
import { TranslateService } from '@ngx-translate/core';
import { CardholderEntityFields } from 'RestClient/Client/Interface/ICardholderEntity';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { KnownContentContext } from '@modules/shared/services/content/KnownContentContext';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';
import { CardholderStateWidgetBaseComponent } from '../cardholder-state-widget-base.component';

const isContentSupported = (content: Content): boolean => {
    return content?.contextContent !== null && content.type.equals(AccessControlContentTypes.Cardholder);
};

@InternalContentPluginDescriptor({
    type: CardholderPreviewWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CardholderPreviewWidgetComponent.pluginId, priority: 4 },
    isContentSupported,
})
@Component({
    selector: 'app-cardholder-preview-widget',
    templateUrl: './cardholder-preview-widget.component.html',
    styleUrls: ['./cardholder-preview-widget.component.scss'],
})
export class CardholderPreviewWidgetComponent extends CardholderStateWidgetBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('91A5F4A5-6BAA-4231-A426-79AF7178A15B');

    @Input() public title = '';

    public readonly Icon = Icon;
    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly TextFlavor = TextFlavor;

    protected get monitoredFields(): string[] {
        const fields = super.monitoredFields;
        fields.push(CardholderEntityFields.firstNameField);
        fields.push(CardholderEntityFields.lastNameField);
        fields.push(CardholderEntityFields.hasPictureField);
        return fields;
    }

    public cardholderFullName = '';
    public cardholderPicture: string | undefined = undefined;
    public hasPicture = false;
    public isLoadingPicture = false;

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        private contentProviderService: ContentProviderService,
        private sanitizer: DomSanitizer,
        timeService: TimeService,
        translateService: TranslateService,
        trackingService: TrackingService
    ) {
        super(securityCenterClientService, translateService, timeService, trackingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.title = this.translateService.instant('STE_ENTITY_CARDHOLDER') as string;
        if (this.content?.contextContent) {
            if (this.content.contextContent.type === AccessControlContentTypes.Credential) {
                this.title = this.translateService.instant('STE_LABEL_CREDENTIAL_ASSIGNEDTO') as string;
            }
        }

        this.isLoading = false;

        if (this.hasPicture) {
            this.isLoadingPicture = true;
            const picture = await this.cardholder?.getPictureAsync();
            if (picture) {
                this.cardholderPicture = 'data: image/png;base64,' + picture.data;
            }
            this.isLoadingPicture = false;
        }
    }

    public async navigateToCardholderAsync(): Promise<void> {
        if (this.cardholder) {
            const content = await this.contentProviderService.getContentAsync(this.cardholder.id, KnownContentContext.Navigation);
            if (content) {
                this.dataContext?.pushContent(content);
            }
        }
    }

    protected async initializeCardholerInfo(): Promise<void> {
        await super.initializeCardholerInfo();

        if (this.cardholder) {
            this.cardholderFullName = this.cardholder.firstName + ' ' + this.cardholder.lastName;

            this.hasPicture = this.cardholder.hasPicture;
        }
    }
}
