export class RingBuffer<T> {
  private readonly m_ringBuffer: Array<T>;

  private m_idx = 0;

  private m_totalItemAdded = 0;

  public get TotalItemAdded(): number {
    return this.m_totalItemAdded;
  }

  constructor(itemCount: number) {
    if (itemCount < 2) {
      throw new Error('Item count must be at least 2. Current value is ' + itemCount);
    }

    this.m_ringBuffer = new Array<T>(itemCount);
  }

  public reset() {
    this.m_idx = 0;
    this.m_totalItemAdded = 0;
  }

  public add(item: T) {
    this.m_ringBuffer[this.m_idx] = item;
    this.m_idx = (this.m_idx + 1) % this.m_ringBuffer.length;
    this.m_totalItemAdded++;
  }

  public get(index: number): T {
    return this.m_ringBuffer[index];
  }

  public firstAndLast(): [T, T] {
    if (this.m_totalItemAdded === 0) {
      throw new Error('Empty ring buffer');
    }

    return [this.m_ringBuffer[this.firstIndex()], this.m_ringBuffer[this.lastIndex()]];
  }

  public getAll(): Array<T> {
    if (this.m_totalItemAdded === 0) {
      return new Array<T>(0);
    }

    const start = this.firstIndex();
    const end = this.lastIndex();

    const result = [];

    let i = start;
    while (true) {
      result.push(this.get(i));
      ++i;

      if (i === (end + 1)) {
        break;
      }

      if (i >= this.m_ringBuffer.length) {
        i = 0;
      }
    }


    return result;
  }

  //Retrieve the index of the oldest introduced item in the ring buffer
  private firstIndex(): number {
    return this.m_totalItemAdded > this.m_ringBuffer.length ? this.m_idx : 0;
  }

  //Retrieve the index of the most recently introduced item in the ring buffer
  private lastIndex(): number {
    return this.m_idx === 0 ? this.m_ringBuffer.length - 1 : this.m_idx - 1;
  }
}
