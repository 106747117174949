import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const MapContentTypes = Constants({
    Map: SafeGuid.parse('969BFFA3-C451-4707-B07D-CF528C060DBD'),
    MobileUser: SafeGuid.parse('752D0B6F-734F-4074-8E81-F6AC9D53F256'),
});
