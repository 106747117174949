<!-- TODO : Set to separated readonly propery when migrate gen-combobox-->
<gen-combobox
    [genLabel]="genLabel"
    [genLabelComplement]="genLabelComplement"
    [genMessage]="genMessage"
    [genMessageSeverity]="genMessageSeverity"
    [disabled]="disabled || readonly"
    [genItemsSource]="genItemsSource"
    [ngModel]="selectedItem"
    (ngModelChange)="onSelectedItemChange($event)"
    (genOffClick)="onOffClick()"
></gen-combobox>
