import { EventBase } from 'RestClient/Client/Event/EventBase';
import { IGuid } from 'safeguid';
export class ThreatLevelChangedEvent extends EventBase {
    public get newThreatLevelGuid(): IGuid {
        return this.getField<IGuid>('NewThreatLevel');
    }
    public get affectedAreaGuid(): IGuid {
        return this.getField<IGuid>('AffectedArea');
    }
}
