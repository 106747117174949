/**
 * @beta
 * */
export class Camera {
  public readonly Id: string;

  constructor(id: string) {
    this.Id = id;
  }

  public toString(): string {
    return this.Id;
  }
}
