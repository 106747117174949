import { IGuid, SafeGuid } from 'safeguid';
import { toGuid } from '../utils/guid-utils';
export interface StateApi {
    Id: string;
    Name: string;
}

export class State {
    constructor(public id: IGuid, public name: string) {}

    public static assign = (state: StateApi): State => new State(toGuid(state.Id), state.Name);
}
