import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrackingService } from 'src/app/modules/shared/services/tracking.service';
import { InternalPluginDescriptor } from 'src/app/modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from 'src/app/modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { UnifiedReportFeatureFlags } from '@modules/correlation/feature-flags';
import { CorrelationFilterTypes } from '../../../enumerations/filter-types';
import { FilterPluginBaseComponent } from '../../../../general/components/filter-plugins/filter-plugin-base.component';

@Component({
    template: `<app-filter-data-types (valueChange)="updateFilter($event)"></app-filter-data-types>`,
})
@InternalPluginDescriptor({
    type: FilterPluginDataTypesComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginDataTypesComponent.pluginId,
        subSection: CorrelationFilterTypes.DataTypes,
    },
    isSupported: () => true,
    requirements: {
        licenses: [KnownLicenses.correlation],
        globalPrivileges: [KnownPrivileges.correlationReportPrivilege],
        enabledFeatureFlags: [UnifiedReportFeatureFlags.General],
    },
})
export class FilterPluginDataTypesComponent extends FilterPluginBaseComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('7c9e8e68-c555-48ff-83ec-85f421b24efd');

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginDataTypesComponent.pluginId);
    }
}
