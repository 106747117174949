import { LiteEvent, ILiteEvent } from '../utils/liteEvents';
import { Resolution } from '../utils/Resolution';

export interface ITileSizeObserver {
  readonly tileResolution: Resolution;
  readonly tileResizeEvent: ILiteEvent<Resolution>;
  dispose(): void;
}

export interface ITileSizeObserverBuilder {
  build(): ITileSizeObserver;
}

export class TileSizeObserverBuilder implements ITileSizeObserverBuilder {
  private readonly m_htmlDivElement: HTMLDivElement;

  constructor(htmlDivElement: HTMLDivElement) {
    this.m_htmlDivElement = htmlDivElement;
  }

  public build(): ITileSizeObserver {
    return new TileSizeObserver(this.m_htmlDivElement);
  }
}

export class TileSizeObserver implements ITileSizeObserver {
  private readonly m_observedDivElement: HTMLDivElement;

  private readonly m_divElementResizeObserver?: ResizeObserver;

  private readonly m_resizeEvent: LiteEvent<Resolution>;

  public get tileResolution(): Resolution {
    return Resolution.build(this.m_observedDivElement.clientWidth, this.m_observedDivElement.clientWidth);
  }

  public get tileResizeEvent(): ILiteEvent<Resolution> {
    return this.m_resizeEvent.expose();
  }

  public constructor(htmlDivElement: HTMLDivElement) {
    this.m_observedDivElement = htmlDivElement;
    this.m_resizeEvent = new LiteEvent<Resolution>();

    // This is best effort, if the ResizeObserver feature is not supported (IE11) we'll never detect resizes.
    if (TileSizeObserver.supportsResizeObserver()) {
      this.m_divElementResizeObserver = new ResizeObserver(this.onResize);
      this.m_divElementResizeObserver.observe(htmlDivElement);
    }
  }

  public static supportsResizeObserver(): boolean {
    return window.ResizeObserver !== undefined;
  }

  public dispose(): void {
    this.m_divElementResizeObserver?.unobserve(this.m_observedDivElement);
    this.m_resizeEvent.dispose();
  }

  private readonly onResize = () => {
    if (this.m_observedDivElement.clientWidth !== 0 && this.m_observedDivElement.clientWidth !== 0) {
      const newResolution = Resolution.build(this.m_observedDivElement.clientWidth, this.m_observedDivElement.clientHeight);
      this.m_resizeEvent.boundTrigger(newResolution);
    }
  }
}
