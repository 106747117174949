import { TrackedComponent } from '@shared/components/tracked/tracked.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Content, ContentPluginComponent, PluginComponentExposure } from '@shared/interfaces/plugins/public/plugin-public.interface';
import { MissionControlContentTypes } from '@modules/mission-control/mission-control-content-types';
import { InternalContentPluginDescriptor } from '@shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { IGuid, SafeGuid } from 'safeguid';
import { TrackingService } from '@shared/services/tracking.service';
import { TextFlavor } from '@genetec/gelato';
import { Icon, SpinnerSize } from '@genetec/gelato';
import { ButtonFlavor, GenMeltedItem, GenModalService, IconSize, MeltedModalAction, Severity } from '@genetec/gelato-angular';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import { filter, switchMap, tap } from 'rxjs/operators';
import { LinkedIncidentService } from '@modules/mission-control/services/linked-incident/linked-incident.service';
import { ContentProviderService } from '@shared/services/content/content-provider.service';
import { LoggerService } from '@shared/services/logger/logger.service';
import { ContentFields } from '@modules/mission-control/content-fields';
import { toGuid } from '@modules/mission-control/utils/guid-utils';
import { ContentService } from '@shared/interfaces/plugins/public/plugin-services-public.interface';
import { MCPrivileges } from '@modules/mission-control/mc-privileges';
import { PrivilegeService } from '@modules/shared/privilege/privilege.service';
import { LinkIncidentCommand } from '@modules/mission-control/models/commands/link-incident-command';
import { IncidentCommandService } from '@modules/mission-control/services/incident/incident-command.service';
import { UnlinkIncidentCommand } from '@modules/mission-control/models/commands/unlink-incident-command';
import { stringFormat } from '@shared/utilities/StringFormat';
import { TranslateService } from '@ngx-translate/core';
import { StateGuids } from '@modules/mission-control/state-guids';
import { McNotificationService } from '@modules/mission-control/services/mc-notification.service';
import { IncidentSelectionService } from '@modules/mission-control/services/incident/incident-selection.service';
import { IncidentFilter } from '@modules/mission-control/models/incident-filter';
import { LinkedIncidentFilterComponent } from '@modules/mission-control/components/linked-incident-filter/linked-incident-filter.component';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

const isContentSupported = (content: Content): boolean => !!content?.type.equals(MissionControlContentTypes.linkedIncident);

@UntilDestroy()
@Component({
    selector: 'app-linked-incident-widget',
    templateUrl: './linked-incident-widget.component.html',
    styleUrls: ['./linked-incident-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: LinkedIncidentWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: LinkedIncidentWidgetComponent.pluginId } as PluginComponentExposure,
    requirements: {
        optionalGlobalPrivileges: [MCPrivileges.linkIncidents, MCPrivileges.modifyReportedIncidents],
    },
    isContentSupported,
})
export class LinkedIncidentWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('A9258DF6-7A91-4232-8276-1C22D3B4FA63');

    @ViewChild('linkedIncidentFilter') public incidentFilter!: LinkedIncidentFilterComponent;

    public readonly TextFlavor = TextFlavor;
    public readonly Severity = Severity;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly SpinnerSize = SpinnerSize;

    public content: Content | null = null;
    public dataContext: ContentService | null = null;

    public linkIncidentAction: () => Promise<boolean>;
    public unlinkIncidentAction: () => Promise<boolean>;
    public targetLinkIncident: MCIncident | null = null;
    public targetUnlinkIncident: MCIncident | null = null;
    public linkedIncidents$: Observable<MCIncident[]>;
    public linkableIncidents$: Observable<MCIncident[]>;
    public linkIncidentModalId = 'link-incident-modal';
    public unlinkIncidentModalId = 'unlink-incident-modal';
    public hasLinkIncidentPrivilege = false;
    public hasModifyReportedIncidentPrivilege = false;
    public linkDisplayId: string | null = null;
    public unlinkFlavor = TextFlavor.Normal;
    public unlinkMessage = '';
    public incidentId: IGuid | null = null;
    public hiddenFilter = true;
    public timeList: Array<GenMeltedItem> = [];

    private _linkedIncident$: BehaviorSubject<MCIncident[]> = new BehaviorSubject<MCIncident[]>([]);
    private _requestingContext: IGuid | null = null;
    private _selectedIncidentDisplayId: string | null = null;
    private _incidentFilter$: BehaviorSubject<IncidentFilter> = new BehaviorSubject<IncidentFilter>(IncidentFilter.empty);
    private _searchDisplayId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private _translateService: TranslateService,
        private _privilegeService: PrivilegeService,
        private _linkedIncidentService: LinkedIncidentService,
        private _incidentCommandService: IncidentCommandService,
        private _contentProviderService: ContentProviderService,
        private _notificationService: McNotificationService,
        private _incidentSelectionService: IncidentSelectionService,
        private _modalService: GenModalService,
        private _logger: LoggerService,
        trackingService: TrackingService
    ) {
        super(trackingService);

        this.linkIncidentAction = this.linkIncident.bind(this);
        this.unlinkIncidentAction = this.unlinkIncident.bind(this);

        this.linkedIncidents$ = this._linkedIncident$.asObservable();

        this.linkableIncidents$ = combineLatest([this._searchDisplayId$, this._incidentFilter$]).pipe(
            switchMap(([displayId, incidentFilter]) => {
                if ((!displayId || displayId.trim() === '') && this.hiddenFilter) return of([]);

                return this._linkedIncidentService.getLinkableIncidents(displayId, incidentFilter);
            })
        );
    }

    public ngOnInit() {
        super.ngOnInit();

        this.initializePrivileges();

        this._linkedIncident$.next([]);

        const selectedIncident$ = this._incidentSelectionService.selectedIncident$.pipe(
            filter((i): i is MCIncident => i instanceof MCIncident && (this.incidentId?.equals(i.id) ?? false)),
            tap((incident) => (this._selectedIncidentDisplayId = incident.displayId.toString()))
        );

        selectedIncident$
            .pipe(
                switchMap((incident) => this._linkedIncidentService.getLinkedIncidents(incident.linkedIncidentIds)),
                untilDestroyed(this)
            )
            .subscribe((incidents) => this._linkedIncident$.next(incidents));
    }

    public canLinkIncident = (incident: MCIncident): boolean => {
        const displayId = incident.displayId.toString();
        const selectedDisplayId = this._selectedIncidentDisplayId?.toString() ?? null;
        const linkedDisplayIds = this._linkedIncident$.getValue().map((x) => x.displayId.toString());

        return !!selectedDisplayId && displayId !== selectedDisplayId && !linkedDisplayIds.includes(displayId);
    };

    public canUnlinkIncident = (): boolean =>
        !!this.targetUnlinkIncident && this.hasLinkIncidentPrivilege && (!this.targetUnlinkIncident.stateId.equals(StateGuids.CLOSED) || this.hasModifyReportedIncidentPrivilege);

    public selectLinkIncident(incident: MCIncident): void {
        this.targetLinkIncident = incident;
    }

    public selectUnlinkIncident(incident: MCIncident): void {
        this.targetUnlinkIncident = incident;
    }

    public async navigateToIncident(incident: MCIncident): Promise<void> {
        if (incident.stateId.equals(StateGuids.CLOSED)) {
            this._notificationService.notifyError('STE_MESSAGE_INCIDENT_IS_CLOSED');
            return;
        }
        const content = await this._contentProviderService.getContentAsync(incident.id, this._requestingContext ?? undefined);
        if (content) {
            this.dataContext?.pushContent(content);
        }
    }

    public updateIncidentFilter(incidentFilter: IncidentFilter): void {
        this._incidentFilter$.next(incidentFilter);
    }

    public toggleFilter(): void {
        this.hiddenFilter = !this.hiddenFilter;
        if (this.hiddenFilter) {
            this.resetFilter();
        }
    }

    public updateSelectedIncident(linkDisplayId: string | null): void {
        this._searchDisplayId$.next(linkDisplayId);
    }

    public setContent(content: Content): void {
        this.content = content;
        const hasIncidentId = this.content?.parameters?.hasField(ContentFields.incidentId) ?? false;
        const hasContextId = this.content?.parameters?.hasField(ContentFields.requestingContext) ?? false;

        this.incidentId = hasIncidentId ? toGuid(this.content?.parameters?.getField(ContentFields.incidentId) as string) : null;
        this._requestingContext = hasContextId ? toGuid(this.content?.parameters?.getField(ContentFields.requestingContext) as string) : null;
    }

    public setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext as ContentService;
    }

    public showLinkIncidentModal = (): void => {
        this.linkDisplayId = null;
        this._searchDisplayId$.next(null);
        this.targetLinkIncident = null;
        this.resetFilter();
        this.showModal(this.linkIncidentModalId);
    };

    public showUnlinkIncidentModal(): void {
        this.unlinkMessage = '';
        this.unlinkFlavor = TextFlavor.Normal;
        if (!this.targetUnlinkIncident) {
            return;
        } else if (this.targetUnlinkIncident.stateId.equals(StateGuids.CLOSED) && !this.hasModifyReportedIncidentPrivilege) {
            this.unlinkFlavor = TextFlavor.Warning;
            this.unlinkMessage = stringFormat(
                this._translateService.instant('STE_MESSAGE_MISSING_X_PRIVILEGE'),
                this._translateService.instant('STE_LABEL_MODIFY_REPORTED_INCIDENT_PRIVILEGE')
            );
        } else {
            const unavailableInformation = this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string;
            const targetIncidentDisplayId = this.targetUnlinkIncident?.displayId.toString() ?? unavailableInformation;
            this.unlinkMessage = stringFormat(
                this._translateService.instant('STE_MESSAGE_UNLINK_INCIDENT_X_FROM_INCIDENT_Y'),
                targetIncidentDisplayId,
                this._selectedIncidentDisplayId ?? unavailableInformation
            );
        }

        this.showModal(this.unlinkIncidentModalId);
    }

    public closeLinkIncidentModal = (): Promise<boolean> => {
        this._modalService.hide(this.linkIncidentModalId);
        return Promise.resolve(true);
    };

    public closeUnlinkIncidentModal = (): Promise<boolean> => {
        this._modalService.hide(this.unlinkIncidentModalId);
        return Promise.resolve(true);
    };

    private resetFilter = () => {
        this.hiddenFilter = true;
        this._incidentFilter$.next(IncidentFilter.empty);
        this.incidentFilter.sameIncidentType = false;
        this.incidentFilter.sameLocation = false;
        this.incidentFilter.selectedTimeRange = this.incidentFilter.anyTime;
    };

    private showModal(modalId: string): void {
        if (this._modalService) {
            this._modalService.show(modalId);
            // TODO: ideally, the modal service should handle this: not allow 2 modals to be open.
            (document.activeElement as HTMLElement)?.blur();
        }
    }

    private initializePrivileges(): void {
        this.hasLinkIncidentPrivilege = this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.linkIncidents);
        this.hasModifyReportedIncidentPrivilege = this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.modifyReportedIncidents);
    }

    private async linkIncident(): Promise<boolean> {
        if (!!this.incidentId && !!this.targetLinkIncident) {
            try {
                const success = await this._incidentCommandService.tryExecute(new LinkIncidentCommand(this.incidentId, this.targetLinkIncident.id)).toPromise();
                if (success) this.targetLinkIncident = null;
                return success;
            } catch (e) {
                this._logger.traceError(`Unable to link incidents ${e as string}`);
            }
        }

        return false;
    }

    private async unlinkIncident(): Promise<boolean> {
        if (!!this.incidentId && !!this.targetUnlinkIncident) {
            try {
                const success = await this._incidentCommandService.tryExecute(new UnlinkIncidentCommand([this.incidentId, this.targetUnlinkIncident.id])).toPromise();
                if (success) this.targetUnlinkIncident = null;
                return success;
            } catch (e) {
                this._logger.traceError(`Unable to unlink incidents ${e as string}`);
            }
        }
        return false;
    }
}
