import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ItemSlot } from '@genetec/gelato';
import { MeltedIcon } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { VideoContentTypes } from '../../../enumerations/video-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
const isContentSupported = (content: Content) => {
    if (content?.type && content.contextContent) {
        return content.type.equals(VideoContentTypes.Video) && content.contextContent.type.equals(VideoContentTypes.Event);
    }
    return false;
};

@Component({
    selector: 'app-camera-event-widget',
    templateUrl: './camera-event-widget.component.html',
    styleUrls: ['./camera-event-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalContentPluginDescriptor({
    type: CameraEventWidgetComponent,
    pluginTypes: [PluginTypes.MapPopupCompact, PluginTypes.MapPopupExpand],
    exposure: { id: CameraEventWidgetComponent.pluginId, priority: 0 },
    isContentSupported,
})
export class CameraEventWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('A1B48B6B-CE21-4DD3-B097-D9BF0629CB68');

    public content?: Content;
    public dataContext: unknown;

    public icon: MeltedIcon | null = MeltedIcon.Camera;
    public readonly ItemSlot = ItemSlot;
    public entityName = 'Unknown';

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        const videoContent = this.content?.contextsubContents?.find((content) => content.type.equals(VideoContentTypes.Video));

        if (videoContent) {
            this.entityName = videoContent.title;
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public onClick(): void {}
}
