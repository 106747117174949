import { AfterViewInit, Component } from '@angular/core';
import { skip } from 'rxjs/operators';
import { TypedConnectionAwareModalComponent } from './connection-aware-modal.component';

@Component({ template: '' })
export abstract class NonRestrictedConnectionAwareModalComponent extends TypedConnectionAwareModalComponent<void> implements AfterViewInit {
    ngAfterViewInit() {
        // Skip the first value, which is the initial value of the loggedIn$ observable
        this.loggedIn$ = this.authService.loggedIn$.pipe(skip(1));
        super.ngAfterViewInit();
    }
}
