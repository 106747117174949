import { Component, OnInit } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { SafeGuid } from 'safeguid';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { ZoneContentTypes } from '../../../enumerations/zone-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
const isContentSupported = (content: Content): boolean => {
    if (content && !content.contextContent) {
        return content.type.equals(ZoneContentTypes.Zone);
    }
    return false;
};

@Component({
    selector: 'app-zone-events-widget',
    templateUrl: './zone-events-widget.component.html',
    styleUrls: ['./zone-events-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: ZoneEventsWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: ZoneEventsWidgetComponent.pluginId, priority: 10 },
    isContentSupported,
})
export class ZoneEventsWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('19B7338B-DFC4-4DA1-94F6-7E7638334744');
    public content?: Content;
    public dataContext?: ContentService;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
