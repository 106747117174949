// Autogenerated: v2/help/Entities/ApiZone?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:09 PM
import { IGuid } from 'safeguid';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity, EntityFields } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { IRestResponse } from './IRestResponse';
import { ITransaction } from './ITransaction';

// #region Fields

export class ZoneEntityFields extends EntityFields {
    public static accessControlUnitField = 'AccessControlUnit';
    public static armingDelayField = 'ArmingDelay';
    public static armingDelayOnField = 'ArmingDelayOn';
    public static armingStateField = 'ArmingState';
    public static zoneStateField = 'ZoneState';
    public static zoneTypeField = 'ZoneType';
}

// #endregion Fields

export interface IZoneEntity extends IEntity {
    // #region REST Properties

    accessControlUnit: IGuid;

    armingDelay: number;

    armingDelayOn: boolean;

    readonly armingState: string;

    readonly zoneState: string;

    zoneType: string;

    // #endregion REST Properties

    // #region REST Methods

    armZoneAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getDevicesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    disarmZoneAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

