import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonFlavor, Icon, TextFlavor } from '@genetec/gelato';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MainSearchComponent } from '@modules/shared/components/main-search/main-search.component';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { CommandBindings, COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { NavigationService } from '@modules/shared/services/navigation/navigation.service';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { SearchResultGroup } from '@modules/shared/services/search/search.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { focusDomElement } from '@modules/shared/utilities/dom-helper';
import { IGuid } from 'safeguid';
import { AsidePosition, GenPopup, PopupPosition } from '@genetec/gelato-angular';
import { AccessControlFeatureFlags } from '@modules/access-control/feature-flags';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '../../security-center/services/authentication/auth.service';
import { NavBarService } from './services/nav-bar.service';
import { NavBarState } from './models/nav-bar-state';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent extends TrackedComponent implements OnInit, OnDestroy {
    @ViewChild('mainSearch') public mainSearch!: MainSearchComponent;
    @ViewChild('resultSection') public resultSection!: ElementRef<Element>;
    @ViewChild(GenPopup) subTasksPopup!: GenPopup;
    public readonly PopupPosition = PopupPosition;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;
    public readonly AsidePosition = AsidePosition;

    /**
     * Selected plugin (used to display the subTasks list in the popup)
     */
    public selectedTask: PluginItem | undefined;

    public hasAccessControlGeneralFeatureFlag? = false;

    public taskPlugins: PluginItem[] = [];
    public navBarExtensionPlugins: PluginItem[] = [];
    public wasPinned = false;
    public pinTooltip: string | undefined;
    public groupedSearchResults: SearchResultGroup[] | null = null;
    public resetNavbarAnimation: Subject<void> = new Subject<void>();
    public clearMainSearchText: Subject<void> = new Subject<void>();
    public isSearching = false;

    private timeout: number | null = null;

    public get activatedTask(): IGuid {
        return this.navigationService.getCurrentTask();
    }

    constructor(
        private pluginService: PluginService,
        private authService: AuthService,
        private translateService: TranslateService,
        private navigationService: NavigationService,
        @Inject(COMMANDS_SERVICE) public commandsService: InternalCommandsService,
        trackingService: TrackingService,
        public activatedRoute: ActivatedRoute,
        private contentProviderService: ContentProviderService,
        public navBarService: NavBarService,
        private advancedSettingsService: AdvancedSettingsService
    ) {
        super(trackingService);
        this.hasAccessControlGeneralFeatureFlag = AccessControlFeatureFlags.General.isEnabled(this.advancedSettingsService);
    }

    // off click handling
    @HostListener('document:click', ['$event'])
    public onClickAnywhere(mouseEvent: MouseEvent): void {
        combineLatest([this.navBarService.isPinned$, this.navBarService.isFocused$, this.navBarService.isOpened$, this.navBarService.skipHoveringCheck$])
            .pipe(
                take(1),
                map(([isPinned, isFocused, isOpened, skipHoveringCheck]) => {
                    return {
                        isPinned,
                        isFocused,
                        isOpened,
                        skipHoveringCheck,
                    } as NavBarState;
                }),
                untilDestroyed(this)
            )
            .subscribe((state: NavBarState) => {
                if (!mouseEvent || (!state.isOpened && !state.skipHoveringCheck)) {
                    return;
                }
                const specifiedElement = document.getElementById('floating-nav-bar');
                if (!specifiedElement) {
                    return;
                }

                const burgerMenuButton = document.getElementById('burger-menu-button');

                const isClickFromMenuBurger = burgerMenuButton === mouseEvent.target || (mouseEvent.target instanceof Node && burgerMenuButton?.contains(mouseEvent.target));
                if (!isClickFromMenuBurger && !specifiedElement.contains(mouseEvent.target as Node)) {
                    this.navBarService.setSkipHoveringCheck(false);
                    this.navBarService.setIsOpenedState(false);
                    this.dispatchIsOpenedStateMutation(false);
                }

                if (isClickFromMenuBurger && state.isOpened) {
                    // Focus on main search if nav-bar was opened by clicking menu-burger
                    this.mainSearch?.focus();
                }
            });
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe(async (loggedIn: boolean) => {
            if (loggedIn) {
                this.taskPlugins = await this.pluginService.getPlugins(PluginTypes.Task);
                this.navBarExtensionPlugins = await this.pluginService.getPlugins(PluginTypes.NavBarExtension);
            }
        });

        // Register keyboard shortcuts for this view.
        this.subscribeCommands();
    }

    public onCancelMenu(): void {
        this.navBarService.setSkipHoveringCheck(false);
        this.dispatchIsOpenedStateMutation(false);
    }

    public onIsSearchingChange(isSearching: boolean): void {
        this.isSearching = isSearching;
    }

    public onItemSelected(navigation?: string | null): void {
        if (!navigation || !this.canNavigate(navigation)) {
            return;
        }

        this.navigationService.navigate(navigation).fireAndForget();

        this.dispatchIsOpenedStateMutation(false);
        this.navBarService.setSkipHoveringCheck(false);

        this.navBarService.setIsFocusedState(false);
        this.resetNavbarAnimation.next();
        this.clearMainSearchText.next();
    }

    public async onItemContextMenu(mouseEvent: MouseEvent, id?: IGuid): Promise<void> {
        this.navBarService.setSkipHoveringCheck(true);
        if (id) {
            // own the context menu
            mouseEvent.preventDefault();
            mouseEvent.stopPropagation();

            const content = await this.contentProviderService.getContentAsync(id);
            if (content?.mainContent) {
                this.commandsService.showContextMenuAsync(mouseEvent, { type: ContextTypes.Content, data: content?.mainContent }).fireAndForget();
            }
        }
    }

    public onSearchClick(): void {
        this.navBarService.setSkipHoveringCheck(true);
        this.dispatchIsOpenedStateMutation(true);
    }

    public onTaskSelected(task: PluginItem): void {
        // If task contains sub tasks, open popup to select the subtask
        if (this.hasAccessControlGeneralFeatureFlag && task.subTaskPlugins?.length > 1) {
            //open menu
            this.selectedTask = task;
            this.subTasksPopup.show().fireAndForget();
        } else {
            this.onItemSelected(`/task/${task.exposure.id.toString()}`);
        }
    }

    public onSubTaskClick(item: PluginItem): void {
        this.subTasksPopup.close().fireAndForget();

        if (!this.selectedTask) return;
        this.onItemSelected(`/task/${this.selectedTask.exposure.id.toString()}/${item.exposure.id.toString()}`);
    }

    public onSearchResultsChange(searchResults: SearchResultGroup[] | null): void {
        this.groupedSearchResults = searchResults;
    }

    public onSidePaneOpenChange(val: boolean): void {
        this.dispatchIsOpenedStateMutation(val);
    }

    public onTogglePin(): void {
        this.navBarService.toggleIsPinnedState();
    }

    public pinMouseEnter(): void {
        this.timeout = window.setTimeout(() => {
            this.dispatchIsOpenedStateMutation(true);
            this.mainSearch?.focus();
        }, 300);
    }

    public pinMouseLeave(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public focusResultSection(): void {
        // Focus first element
        focusDomElement('li:not(.with-search-results) > a[tabindex="0"]', this.resultSection.nativeElement);
    }

    public onMainSearchFocus(): void {
        this.navBarService.isPinned$.pipe(take(1), untilDestroyed(this)).subscribe((isPinned: boolean) => {
            if (isPinned) {
                this.navBarService.setSkipHoveringCheck(true);
            }
        });
    }

    private dispatchIsOpenedStateMutation(newState: boolean): void {
        if (!newState) {
            if (this.groupedSearchResults) {
                this.groupedSearchResults.length = 0;
            }
        } else {
            this.mainSearch?.focus();
        }
        this.navBarService.setIsOpenedState(newState);

        this.mainSearch?.clear();
        this.clearMainSearchText.next();
    }

    private subscribeCommands(): void {
        this.commandsService.registerCommandShortcut(SharedCommands.Search, 'CTRL.F');

        const commandBindings = new CommandBindings();
        commandBindings.addCommand({
            commandId: SharedCommands.Search,
            executeCommandHandler: (executeCommandData) => {
                this.navBarService.setSkipHoveringCheck(true);
                this.mainSearch.focus();
                executeCommandData.isHandled = true;
            },
        });
        this.commandsService.registerCommandShortcut(SharedCommands.CloseNavBar, 'Esc');
        commandBindings.addCommand({
            commandId: SharedCommands.CloseNavBar,
            executeCommandHandler: (executeCommandData) => {
                this.navBarService.setSkipHoveringCheck(false);
                this.navBarService.setIsFocusedState(false);
                this.dispatchIsOpenedStateMutation(false);
                executeCommandData.isHandled = true;
            },
        });
        this.commandsService.subscribe(commandBindings, { priority: 100 });
    }

    private canNavigate(navigation: string) {
        const hasParams = navigation.includes('?');
        const hasSubTaskRegex = new RegExp('task/[^/]+/+.');
        const hasSubTask = hasSubTaskRegex.exec(navigation);

        // cannot navigate if we try to navigate to the already active task without any params or sub task
        if (!hasParams && !hasSubTask) {
            if (navigation.includes(this.activatedTask.toString())) {
                return false;
            }
        }

        return true;
    }
}
