// Autogenerated: v2/help/Entities/ApiLicensePlateManagerRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { RoleEntity } from '../Roles/RoleEntity';
import { FieldObject } from '../../../Helpers/FieldObject';
import { Transaction } from '../../Transaction';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { QueryFilter } from '../../Queries/QueryFilter';
import { Entity } from '../Entity';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { ILicensePlateManagerRoleEntity, IModuleConfig, LicensePlateManagerRoleEntityFields } from '../../Interface/ILicensePlateManagerRole';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class ModuleConfig extends FieldObject implements IModuleConfig {
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // IsActive
    public get isActive(): boolean {
        return this.getField<boolean>('IsActive');
    }
    public set isActive(value: boolean) {
        this.setField<boolean>('IsActive', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Id', '');
        this.setField<boolean>('IsActive', false);
    }
}

// #endregion Inner classes

export class LicensePlateManagerRoleEntity extends RoleEntity implements ILicensePlateManagerRoleEntity {
    // #region REST Properties

    // ArchiverId
    public get archiverId(): IGuid {
        return this.getFieldGuid(LicensePlateManagerRoleEntityFields.archiverIdField);
    }
    public set archiverId(value: IGuid) {
        this.setFieldGuid(LicensePlateManagerRoleEntityFields.archiverIdField, value);
    }

    // EventRetentionInDays
    public get eventRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.eventRetentionInDaysField);
    }
    public set eventRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.eventRetentionInDaysField, value);
    }

    // HitImageRetentionInDays
    public get hitImageRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.hitImageRetentionInDaysField);
    }
    public set hitImageRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.hitImageRetentionInDaysField, value);
    }

    // HitRetentionInDays
    public get hitRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.hitRetentionInDaysField);
    }
    public set hitRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.hitRetentionInDaysField, value);
    }

    // OccupancyRetentionInDays
    public get occupancyRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.occupancyRetentionInDaysField);
    }
    public set occupancyRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.occupancyRetentionInDaysField, value);
    }

    // ParkingZoneDataRetentionInDays
    public get parkingZoneDataRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.parkingZoneDataRetentionInDaysField);
    }
    public set parkingZoneDataRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.parkingZoneDataRetentionInDaysField, value);
    }

    // PositionRetentionInDays
    public get positionRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.positionRetentionInDaysField);
    }
    public set positionRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.positionRetentionInDaysField, value);
    }

    // ReadImageRetentionInDays
    public get readImageRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.readImageRetentionInDaysField);
    }
    public set readImageRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.readImageRetentionInDaysField, value);
    }

    // ReadRetentionInDays
    public get readRetentionInDays(): number {
        return this.getField<number>(LicensePlateManagerRoleEntityFields.readRetentionInDaysField);
    }
    public set readRetentionInDays(value: number) {
        this.setField<number>(LicensePlateManagerRoleEntityFields.readRetentionInDaysField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAssociationAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Associations', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getModulesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IModuleConfig> | null> {
        return this.getFieldObjectRelationAsync<ModuleConfig, IModuleConfig>(ModuleConfig, 'Modules', 'Id', true, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

