import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonFlavor, Icon, SelectionType } from '@genetec/gelato';
import { Flavor, GenMeltedListItem, SelectionType as MeltedSelectionType } from '@genetec/gelato-angular';
import { FilterContext, IFilterContent } from '@modules/shared/api/api';
import { TreeItem } from '@shared/interfaces/tree-item/tree-item';
import { cloneDeep, intersectionBy } from 'lodash-es';
import { FilterBaseComponent } from '../filter-base.component';
import { TreeViewFilterOptionTypes } from './enums/tree-view-filter-option-types.enum';
import { TreeView, TreeViewDefinition } from './filter-tree-view.model';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 * Filter based on an hierarchical list selection
 */
@Component({
    selector: 'app-filter-tree-view',
    templateUrl: './filter-tree-view.component.html',
    styleUrls: ['./filter-tree-view.component.scss'],
})
export class FilterTreeViewComponent extends FilterBaseComponent<string[]> implements OnInit {
    //#region Properties

    @Input() public set options(options: string[]) {
        if (options.length > 0) {
            this.applyOptions(options);
        }
    }
    @Input() public filterContext!: FilterContext;
    @Input() public set rootItems(items: unknown[] | undefined) {
        if (items) {
            this.treeView = new TreeView(this.convertToTreeViewDefinition(items));

            this.loadedTreeItems = this.treeView.generateTreeItems();

            this.nonFilteredTreeItems = cloneDeep(this.loadedTreeItems);

            this.displayedTreeItems = this.nonFilteredTreeItems;

            this.isTreeViewLoaded = true;
            this.deactivateSpinner();
        }
    }

    @Output() public updateRootItemsEvent = new EventEmitter<IFilterContent>();

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly SelectionTypes = SelectionType;
    public readonly MeltedSelectionType = MeltedSelectionType;
    public readonly Flavor = Flavor;

    // ** Only used to feed Gelato ** //
    public displayedTreeItems: TreeItem[] = [];
    public displayedShowOnlySelectedTreeItems: GenMeltedListItem[] = [];
    public displayedSelectedTreeItems: TreeItem[] = [];
    public showOnlySelectedTreeItems: GenMeltedListItem[] = [];
    // ***************************** //

    public searchText = '';
    public isFiltered = false;
    public isAllChecked = false;
    public onlyShowCheckedItems = false;
    public isTreeViewLoading = false;

    public isSearchAvailable = false;
    public isSelectAllAvailable = false;
    public isViewSelectedAvailable = false;

    private isTreeViewLoaded = false;

    private loadedTreeItems: TreeItem[] = [];
    private selectedTreeItems: TreeItem[] = [];
    private nonFilteredTreeItems: TreeItem[] = [];
    private filteredTreeItems: TreeItem[] = [];
    private filteredSelectedItems: TreeItem[] = [];
    private oldShowOnlySelectedItems: TreeItem[] = [];

    private treeView?: TreeView;

    public get isOnlyShowSelectedItemsVisible(): boolean {
        return this.selectedTreeItems.length > 0;
    }

    public get isUncheckAllHidden(): boolean {
        if (this.onlyShowCheckedItems) {
            return this.selectedTreeItems.length === 0;
        } else {
            return this.displayedSelectedTreeItems.length === 0;
        }
    }

    public get isNoItemsFound(): boolean {
        return !this.isTreeViewLoading && this.displayedTreeItems.length === 0 && !this.onlyShowCheckedItems;
    }

    //#endregion

    //#region Life cycle hooks

    ngOnInit() {
        super.ngOnInit();
        this.value = [];
    }

    //#endregion

    //#region Public methods

    /**
     * Raised when the filter popup has been opened
     *
     * @param isFilterOpened
     */
    public async onFilterToggled(isFilterOpened: boolean): Promise<void> {
        // Checks if the root items need to and can be loaded
        if (this.descriptor?.lazyLoad && isFilterOpened && !this.isTreeViewLoaded && !this.isTreeViewLoading) {
            await this.loadTreeViewItems();
        }

        super.onFilterToggled(isFilterOpened);
    }

    /**
     * 	Raised when the array of selected items changed.
     *
     * @param items
     */
    public onSelectedItemsChange(item: TreeItem, event: CustomEvent<boolean>): void {
        item.isChecked = event.detail;
        this.updateSelectedItems(this.treeItemsToFlatList(this.displayedTreeItems).filter((i) => i.isChecked === true));
        this.updateIsAllChecked();
        this.updateValue(this.selectedTreeItems);
    }

    /**
     * Raised when show only selected items array changed.
     *
     * @param item
     */
    public onShowOnlySelectedItemsChange(items: TreeItem[]): void {
        this.showOnlySelectedTreeItems = items as GenMeltedListItem[];

        const difference = this.genTreeItemArrayDifference(this.oldShowOnlySelectedItems, items);

        if (difference.length > 0) {
            if (this.oldShowOnlySelectedItems.length > items.length) {
                const itemToRemoveIndex: number = this.displayedSelectedTreeItems.findIndex((x) => x.id === difference[0].id);

                if (itemToRemoveIndex > -1) {
                    this.displayedSelectedTreeItems.splice(itemToRemoveIndex, 1);
                }
            } else this.displayedSelectedTreeItems.push(difference[0]);
        }

        this.oldShowOnlySelectedItems = cloneDeep(items);
        this.updateValue(this.displayedSelectedTreeItems);
    }

    /**
     * Raised when display checked items toggle changed
     */
    public onOnlyShowCheckedItemsClicked(): void {
        if (this.onlyShowCheckedItems) {
            this.showOnlySelectedTreeItems = this.selectedTreeItems.filter((x) => !x.children);
            this.displayedShowOnlySelectedTreeItems = this.showOnlySelectedTreeItems;
            this.oldShowOnlySelectedItems = this.showOnlySelectedTreeItems;
        } else this.updateIsAllChecked();
    }

    /**
     * Raised when check all items button clicked
     */
    public onCheckAllItemsClicked(): void {
        if (this.isFiltered) {
            this.addTreeItemsToSelectedTreeItems(this.filteredTreeItems);
            const filteredSelectedItems = this.treeItemsToFlatList(this.filteredTreeItems);
            this.displayedSelectedTreeItems = cloneDeep(filteredSelectedItems);
            this.filteredSelectedItems = filteredSelectedItems;
        } else {
            const nonFilteredSelectedItems = this.treeItemsToFlatList(this.nonFilteredTreeItems);
            this.displayedSelectedTreeItems = cloneDeep(nonFilteredSelectedItems);
            this.selectedTreeItems = nonFilteredSelectedItems;
        }

        this.isAllChecked = true;
        this.updateValue(this.selectedTreeItems);
    }

    /**
     * Raised when uncheck all items button clicked
     */
    public onUncheckAllItemsClicked(): void {
        this.selectedTreeItems = [];
        this.removeTreeItemsFromSelectedTreeItems(this.filteredTreeItems);

        this.displayedSelectedTreeItems = [];
        this.filteredSelectedItems = [];

        this.onlyShowCheckedItems = false;
        this.isAllChecked = false;
        this.updateValue([]);
    }

    /**
     * Raised on search text changed
     */
    public async onSearchTextChanged(newValue: string): Promise<void> {
        this.searchText = newValue;
        await this.searchAsync(this.searchText);
    }

    public updateState(): void {
        const selectedItems = this.selectedTreeItems;
        let selection = this.state.selection;

        if (!selectedItems || selectedItems.length === 0) {
            selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
        } else if (selectedItems.length === 1) {
            selection = selectedItems[0].text ?? '';
        } else {
            selection = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: selectedItems.length }) as string;
        }

        this.state = { ...this.state, selection };
    }

    public clearFilter(): void {
        this.onUncheckAllItemsClicked();
    }

    public isDefaulted(): boolean {
        return this.value === undefined || this.value.length === 0;
    }

    public override isDirty(firstValue: string[], secondValue: string[]): boolean {
        if (firstValue.length !== secondValue.length) {
            return true;
        }

        // compare each item
        return firstValue.some((value, index) => secondValue.indexOf(value) === -1);
    }

    public override getDefaultValue(): string[] {
        return [];
    }

    //#endregion

    //#region Private methods

    /**
     * Raised when search debounce done.
     *
     * @param event
     */
    private async searchAsync(searchText: string): Promise<void> {
        this.activateSpinner();

        if (searchText) {
            this.isFiltered = true;

            // TODO: Remove set timeout when GenTree will be loading faster
            // Only used to display a spinner while the GenTree component is being loaded.
            await new Promise((resolve) => setTimeout(resolve, 0));
            this.filteredTreeItems = this.filterTreeViewItems_CaseInsensitive(searchText, this.loadedTreeItems);

            this.displayedTreeItems = this.filteredTreeItems;
            this.filteredSelectedItems = intersectionBy(this.selectedTreeItems, this.treeItemsToFlatList(this.filteredTreeItems), 'id');
            this.displayedSelectedTreeItems = cloneDeep(this.filteredSelectedItems);
        } else {
            this.isFiltered = false;
            this.displayedTreeItems = this.nonFilteredTreeItems;

            // Force selected items refresh
            this.displayedSelectedTreeItems = cloneDeep(this.selectedTreeItems);
        }

        this.updateIsAllChecked();
        this.deactivateSpinner();
    }

    /**
     * Filters tree view items using the given search text.
     *
     * @param searchText
     * @param treeViewItems
     */
    private filterTreeViewItems_CaseInsensitive(searchText: string, treeViewItems: TreeItem[]): TreeItem[] {
        const filteredItems: TreeItem[] = cloneDeep(treeViewItems);
        const itemsToRemove: string[] = [];
        const searcTextUpper: string = searchText.toUpperCase();

        for (const item of filteredItems) {
            if (!item.children && item.text && !item.text.toUpperCase().includes(searcTextUpper)) {
                if (item.id) {
                    itemsToRemove.push(item.id);
                }
            } else {
                const matchingChildren = this.filterTreeViewItems_CaseInsensitive(searchText, item.children ?? []);

                if (matchingChildren.length === 0) {
                    if (item.text?.toUpperCase().includes(searcTextUpper)) {
                        item.isExpanded = false;
                    } else if (item.id) {
                        itemsToRemove.push(item.id);
                    }
                } else {
                    item.isExpanded = true;
                    item.children = matchingChildren;
                }
            }
        }

        itemsToRemove.forEach((id) => {
            const index: number = filteredItems.findIndex((x) => x.id === id);

            if (index !== -1) {
                filteredItems.splice(index, 1);
            }
        });

        return filteredItems;
    }

    /**
     * Updates is all checked property.
     */
    private updateIsAllChecked() {
        const nbDisplayedItems = this.getNbItems(this.displayedTreeItems);
        const nbSelectedItems = this.isFiltered ? this.filteredSelectedItems.length : this.selectedTreeItems.length;
        this.isAllChecked = nbDisplayedItems === nbSelectedItems;
    }

    /**
     * Updates selected items.
     *
     * @param newItems
     */
    private updateSelectedItems(newSelectedItems: TreeItem[]) {
        this.displayedSelectedTreeItems = newSelectedItems;

        if (this.isFiltered) {
            const difference = this.genTreeItemArrayDifference(this.filteredSelectedItems, newSelectedItems);

            if (difference.length > 0) {
                if (this.filteredSelectedItems.length > newSelectedItems.length) {
                    difference.forEach((item) => {
                        this.removeTreeItemFromSelectedTreeItems(item);
                    });
                } else {
                    difference.forEach((item) => {
                        this.addTreeItemToSelectedTreeItems(item);
                    });
                }
            }

            this.filteredSelectedItems = cloneDeep(newSelectedItems);
        } else {
            this.selectedTreeItems = cloneDeep(newSelectedItems);
        }
    }

    /**
     * Recursively counts the number of items inside of a given set of tree items.
     *
     * @param treeItems
     */
    private getNbItems(treeItems: TreeItem[]): number {
        let nbItems = 0;

        treeItems.forEach((x) => {
            nbItems++;

            if (x.children) {
                nbItems += this.getNbItems(x.children);
            }
        });

        return nbItems;
    }

    private addTreeItemsToSelectedTreeItems(treeItems: TreeItem[]) {
        treeItems.forEach((treeItem) => {
            this.addTreeItemToSelectedTreeItems(treeItem);

            if (treeItem.children) {
                this.addTreeItemsToSelectedTreeItems(treeItem.children);
            }
        });
    }

    private removeTreeItemsFromSelectedTreeItems(treeItems: TreeItem[]) {
        treeItems.forEach((treeItem) => {
            this.removeTreeItemFromSelectedTreeItems(treeItem);

            if (treeItem.children) {
                this.removeTreeItemsFromSelectedTreeItems(treeItem.children);
            }
        });
    }

    private addTreeItemToSelectedTreeItems(treeItem: TreeItem) {
        if (!this.selectedTreeItems.find((x) => x.id === treeItem.id)) {
            this.selectedTreeItems.push(treeItem);
        }
    }

    private removeTreeItemFromSelectedTreeItems(treeItem: TreeItem) {
        const index: number = this.selectedTreeItems.findIndex((x) => x.id === treeItem.id);

        if (index !== -1) {
            this.selectedTreeItems.splice(index, 1);
        }
    }

    /**
     * Converts a tree structure to a flat list.
     *
     * @param treeItem
     * @param flatList
     */
    private treeItemsToFlatList(treeItems: TreeItem[]): TreeItem[] {
        const flatList: TreeItem[] = [];

        treeItems.forEach((item) => {
            flatList.push(item);

            if (item.children) {
                flatList.push(...this.treeItemsToFlatList(item.children));
            }
        });

        return flatList;
    }

    /**
     * Returns the differences between the 2 arrays.
     *
     * @param array1
     * @param array2
     */
    private genTreeItemArrayDifference(array1: TreeItem[], array2: TreeItem[]): TreeItem[] {
        let a1: TreeItem[] = array1;
        let a2: TreeItem[] = array2;

        if (array1.length < array2.length) {
            a1 = array2;
            a2 = array1;
        }

        return a1.filter((item1) => !a2.some((item2) => item2.id === item1.id));
    }

    /**
     * Maps a TreeViewItem to a TreeItem.
     *
     * @param treeViewItem
     */
    private mapTreeViewItemToTreeItem(treeViewItem: TreeViewDefinition): TreeItem {
        return {
            id: treeViewItem.id,
            text: treeViewItem.label,
            children: treeViewItem.children.length > 0 ? treeViewItem.children.map((x) => this.mapTreeViewItemToTreeItem(x)) : undefined,
        } as TreeItem;
    }

    /**
     * Updates the currently displayed selection status and
     * adds the selected items to the filters values list.
     *
     * @param items
     */
    private updateValue(items: TreeItem[]) {
        const filteredSelectedItems = items.filter((x) => !x.children);

        // treeView becomes undefined when ngOnDestroy is called
        this.value = this.treeView?.getSelectedItemsRealIds(filteredSelectedItems) || this.getDefaultValue();
    }

    /**
     * Fetches the tree view root items.
     *
     * @param context
     */
    private async loadTreeViewItems() {
        this.activateSpinner();

        const content = await this.filterClient.getFilterContent(this.filterContext).toPromise();

        if (!content) {
            return;
        }

        this.updateRootItemsEvent.emit(content);
    }

    private applyOptions(options: string[]) {
        this.isSearchAvailable = options.includes(TreeViewFilterOptionTypes.Search);
        this.isSelectAllAvailable = options.includes(TreeViewFilterOptionTypes.SelectAll);
        this.isViewSelectedAvailable = options.includes(TreeViewFilterOptionTypes.ViewSelected);
    }

    private activateSpinner() {
        this.isTreeViewLoading = true;
    }

    private deactivateSpinner() {
        this.isTreeViewLoading = false;
    }

    private convertToTreeViewDefinition(treeViewDefinitions: any[]): TreeViewDefinition[] {
        return treeViewDefinitions.map((def) => {
            return {
                /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */
                // TODO: Fix typing
                id: def.Id as string,
                label: def.Label as string,
                children: this.convertToTreeViewDefinition(def.Children),
                icon: def.Icon as string,
            } as TreeViewDefinition;
        });
    }

    //#endregion
}
