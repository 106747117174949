// Autogenerated: v2/help/Entities/ApiOmnicastFederationRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-04-15 12:15:54 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IFederationBaseRoleEntity, FederationBaseRoleEntityFields } from './IFederationBaseRoleEntity';
import { ITransaction } from './ITransaction';
import { IFederationEvent } from './ISecurityCenterFederationRoleEntity';
import { IFieldObject } from './IFieldObject';

// #region Fields

export class OmnicastFederationRoleEntityFields extends FederationBaseRoleEntityFields {
    public static defaultStreamUsageField = 'DefaultStreamUsage';
    public static federateAlarmsField = 'FederateAlarms';
    public static versionField = 'Version';
    public static videoRedirectionModeField = 'VideoRedirectionMode';
}

// #endregion Fields

export interface IOmnicastFederationRoleEntity extends IFederationBaseRoleEntity {
    // #region REST Properties

    defaultStreamUsage: string;

    federateAlarms: boolean;

    version: string;

    videoRedirectionMode: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getEventsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IFederationEvent> | null>;
    getRedirectorsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null>;

    // #endregion REST Methods

}

