export class Timer {
  private _callback: (...args: any[]) => void;
  private _previousId: any = null;
  private _period = -1;

  constructor(callback: (...args: any[]) => void) {
    this._callback = callback;
  }

  public change(timeout: number) {
    if (this._previousId) {
      clearTimeout(this._previousId);
      this._previousId = null;
    }
    if (timeout !== -1) {
      this._previousId = setTimeout(() => {
        this._callback();
      }, timeout);
    }
  }

  public period(period: number) {
    this._period = period;
    if (this._previousId) {
      clearTimeout(this._previousId);
      this._previousId = null;
    }
    if (this._period !== -1) {
      this._previousId = setTimeout(() => {
        this._callback();
        this._previousId = null;
        this.period(this._period);
      }, this._period);
    }
  }

  public dispose() {
    if (this._previousId) {
      clearTimeout(this._previousId);
      this._previousId = null;
    }
  }
}
