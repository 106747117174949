<gen-button
    *ngIf="instanceCount$ | async as instanceCount"
    data-cy="active-alarms-button"
    [ngClass]="{ activate: instanceCount > 0, 'focus-style-override': true }"
    appTooltip="{{ 'STE_TOOLTIP_BUTTON_ACTIVEALARMS' | translate }}"
    [flavor]="ButtonFlavor.Flat"
    (click)="showMonitoredAlarms()"
>
    <gen-icon [icon]="Icon.Alarm"></gen-icon>
    {{ instanceCount }}
</gen-button>
