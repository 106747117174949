import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Icon, ImageFit, ImageFlavor, ButtonFlavor } from '@genetec/gelato';
import { AccessControlFeatureFlags } from '@modules/access-control/feature-flags';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { FileReaderService } from '@shared/services/file-reader/file-reader.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-cardholder-editable-picture',
    templateUrl: './cardholder-editable-picture.component.html',
    styleUrls: ['./cardholder-editable-picture.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CardholderEditablePictureComponent),
            multi: true,
        },
    ],
})
export class CardholderEditablePictureComponent implements ControlValueAccessor {
    @ViewSelectSnapshot(FeatureFlagsState.featureFlags(AccessControlFeatureFlags.CardholderEditPicture))
    public isEditFeatureFlagEnabled!: boolean;

    @ViewChild('removePictureModal') public removePictureModal!: HTMLGenModalElement;
    @Input() readonly = false;

    public cardholderPictureSrc$: Observable<string | undefined>;

    public isMouseOverCardholderPicture = false;
    public isPictureEditMenuOpen = false;

    public readonly Icon = Icon;
    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly ButtonFlavor = ButtonFlavor;

    protected isLoadingSubject = new BehaviorSubject(false);
    protected isDisabledSubject = new BehaviorSubject(false);

    private imageFileDataSubject = new BehaviorSubject<string | undefined>(undefined);
    private cardholderPictureSubject = new BehaviorSubject<string | undefined>(undefined);

    constructor(private fileReaderService: FileReaderService) {
        this.cardholderPictureSrc$ = combineLatest([this.cardholderPictureSubject, this.imageFileDataSubject]).pipe(
            map(([cardholderPicture, imageFileData]) => imageFileData ?? cardholderPicture)
        );
    }

    public onImageFileSelected(event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        this.readImageFileAsBase64(element.files?.item(0));
    }

    public readImageFileAsBase64(file?: File | null): void {
        if (file?.type.includes('image')) {
            this.isLoadingSubject.next(true);

            this.fileReaderService
                .readAsDataURL(file)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (result) => {
                        const pictureData = result?.split(',')[1] ?? ''; // Remove base64 prefix;

                        this.imageFileDataSubject.next(pictureData);
                        this.onChange(pictureData);

                        this.isLoadingSubject.next(false);
                    },
                    (error) => this.isLoadingSubject.next(false) // Error handling later
                );
        }
    }

    public removePicture(): void {
        this.imageFileDataSubject.next('');
        this.onChange('');
        this.toggleRemovePictureConfirmationModal(false);
    }

    public toggleMouseOver(isMouseOverPicture: boolean): void {
        this.isMouseOverCardholderPicture = isMouseOverPicture;
    }

    public toggleRemovePictureConfirmationModal(open: boolean): void {
        if (open) {
            this.removePictureModal.show()?.fireAndForget();
        } else {
            this.removePictureModal.close()?.fireAndForget();
        }
    }

    onChange: (value?: string) => void = () => {};
    onTouch: () => void = () => {};

    writeValue(value?: string): void {
        this.cardholderPictureSubject.next(value);
    }

    registerOnChange(fn: (value?: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabledSubject.next(isDisabled);
    }
}
