// Autogenerated: v2/help/Classes/ApiCardholderAccessEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:12:10 AM
import { TimezoneEvent } from './TimezoneEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { ICardholderAccessEvent } from '../Interface/ICardholderAccessEvent';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { CardholderEvent } from './CardholderEvent';

export class CardholderAccessEvent extends CardholderEvent implements ICardholderAccessEvent {
    // #region REST Properties

    // AccessPoint
    public get accessPoint(): IGuid {
        return this.getFieldGuid('AccessPoint');
    }
    public set accessPoint(value: IGuid) {
        this.setFieldGuid('AccessPoint', value);
    }

    // Credentials
    public get credentials(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('Credentials');
    }
    public set credentials(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('Credentials', value);
    }

    // DoorSide
    public get doorSide(): IGuid {
        return this.getFieldGuid('DoorSide');
    }
    public set doorSide(value: IGuid) {
        this.setFieldGuid('DoorSide', value);
    }

    // LicensePlateExactMatch
    public get licensePlateExactMatch(): boolean {
        return this.getField<boolean>('LicensePlateExactMatch');
    }
    public set licensePlateExactMatch(value: boolean) {
        this.setField<boolean>('LicensePlateExactMatch', value);
    }

    // UnderDuress
    public get underDuress(): boolean {
        return this.getField<boolean>('UnderDuress');
    }
    public set underDuress(value: boolean) {
        this.setField<boolean>('UnderDuress', value);
    }

    // WrongPin
    public get wrongPin(): string {
        return this.getField<string>('WrongPin');
    }
    public set wrongPin(value: string) {
        this.setField<string>('WrongPin', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('AccessPoint', SafeGuid.EMPTY);
        this.setFieldArrayGuid('Credentials', new ObservableCollection<IGuid>());
        this.setFieldGuid('DoorSide', SafeGuid.EMPTY);
        this.setField<boolean>('LicensePlateExactMatch', false);
        this.setField<boolean>('UnderDuress', false);
        this.setField<string>('WrongPin', '');
    }

    // #endregion Methods

}

