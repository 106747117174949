<div class="pointer">
    <div *ngFor="let verticalPlugin of ((expanded$ | async) ? this.expandedPluginsStack : this.pluginsStack).verticalPlugins$ | async" class="va-bottom">
        <app-navigation-plugin-host
            [contextDataPlugins]="verticalPlugin.navigationPlugins"
            [dataContext]="dataContext"
            [maxWidth]="maxWidth"
            [maxHeigth]="maxHeigth"
            (pluginClick)="onPluginClick($event)"
            (contextMenu)="onContextMenu($event)"
        >
        </app-navigation-plugin-host>
    </div>
</div>
