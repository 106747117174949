import { IWebSocketResponse } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';
import { VideoWatermarkConfig } from '../../../utils/VideoWatermarkingConfig';
import { DefaultStreamUsage } from '../DefaultStreamUsage';
import { DewarperSourceImageParameters } from '../../../dewarper/DewarperSourceImageParameters';

/**
 * @beta
 * */
export class CreateStreamResponse implements IWebSocketResponse {
  private readonly m_header: WebSocketMessageHeader;

  private readonly m_body: CreateStreamResponseBody;

  public get channel(): number {
    return this.m_header.channel;
  }

  public get body(): CreateStreamResponseBody {
    return this.m_body;
  }

  constructor(header: WebSocketMessageHeader, body: CreateStreamResponseBody) {
    this.m_header = header;
    this.m_body = body;
  }
}

/**
 * @beta
 * */
export class CreateStreamResponseBody {
  public readonly videoWatermarkingConfig: VideoWatermarkConfig;
  public readonly transcodingAllowance: TranscodingAllowance;
  public readonly defaultStreamUsage: DefaultStreamUsage;
  public readonly serverVersion: string
  public readonly dewarperConfig: DewarperSourceImageParameters;

  constructor(videoWatermarkingConfig: VideoWatermarkConfig, dewarperConfig: DewarperSourceImageParameters, transcodingAllowance: TranscodingAllowance, defaultStreamUsage: DefaultStreamUsage, serverVersion: string) {
    this.videoWatermarkingConfig = videoWatermarkingConfig;
    this.dewarperConfig = dewarperConfig;
    this.transcodingAllowance = transcodingAllowance;
    this.defaultStreamUsage = defaultStreamUsage;
    this.serverVersion = serverVersion;
  }

  public static deserialize(body: string): CreateStreamResponseBody {
    const bodyContent: any = JSON.parse(body);
    const videoWatermarkingConfig = VideoWatermarkConfig.build(bodyContent.VideoWatermarkConfig);

    let dewarperConfig: DewarperSourceImageParameters = DewarperSourceImageParameters.None;
    try {
      dewarperConfig = DewarperSourceImageParameters.deserialize(bodyContent['DewarperConfiguration']);
    } catch (e) {
      console.warn('Failed to deserialize dewarper configuration. Dewarping will not be available.', e, body);
    }

    const transcodingAllowance = bodyContent.TranscodingAllowance;
    const defaultStreamUsage = DefaultStreamUsage.build(bodyContent.DefaultStreamUsage);
    const serverVersion = bodyContent.ServerVersion ?? '0.0.0.0'; //remove me when PR for server to send its version has been merged
    return new CreateStreamResponseBody(videoWatermarkingConfig, dewarperConfig, transcodingAllowance, defaultStreamUsage, serverVersion);
  }
}

/**
 * @beta
 * */
export enum TranscodingAllowance {
  Never = 0,
  ForPtzOnly = 1,
  Always = 2
}
