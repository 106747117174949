import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { createSelector, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { ExpirationMode } from 'RestClient/Client/Enumerations/ExpirationMode';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { ICardholderEntity } from 'RestClient/Client/Interface/ICardholderEntity';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { PrivilegeService } from '@modules/shared/privilege/privilege.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardholderPrivileges, EvaluatedCardholderPrivileges } from './interfaces';
import { CardholderEditService } from './services/cardholder-edit-service';

/**
 * Type for a cardholder model, meant to be manipulated used with the view.
 */
export type CardholderModel = Pick<
    ICardholderEntity,
    | 'firstName'
    | 'lastName'
    | 'lastAccess'
    | 'hasPicture'
    | 'pictureId'
    | 'mobilePhoneNumber'
    | 'emailAddress'
    | 'accessStatus'
    | 'activationDate'
    | 'expirationDate'
    | 'expirationMode'
    | 'expirationDuration'
    | 'canEscort'
    | 'description'
    | 'logicalId'
    | 'antipassbackExemption'
    | 'antipassbackExemptionIsInherited'
    | 'accessPermissionLevel'
    | 'useExtendedGrantTime'
    | 'name'
    | 'inheritAccessPermissionLevelFromGroup'
    | 'isDeletable'
> & { id?: IGuid; picture?: string; readonly?: boolean };

export const createDefaultCardholderModel = (): CardholderModel => {
    return {
        accessStatus: AccessStatus.Active,
        firstName: '',
        lastName: '',
        emailAddress: '',
        name: '',
        lastAccess: new Date('01/01/01'),
        activationDate: new Date(),
        expirationDate: new Date('1/1/0001 12:00:00 AM'),
        expirationMode: ExpirationMode.DontExpire,
        inheritAccessPermissionLevelFromGroup: true,
        accessPermissionLevel: 0,
        antipassbackExemption: false,
        antipassbackExemptionIsInherited: false,
        canEscort: false,
        description: '',
        hasPicture: false,
        isDeletable: true,
        logicalId: null,
        mobilePhoneNumber: '',
        picture: '',
        pictureId: SafeGuid.newGuid(),
        useExtendedGrantTime: false,
        expirationDuration: 0,
    };
};

/**
 * Context state for the cardholder's edit pane
 */
export interface CardholderEditContextStateModel {
    cardholder: CardholderModel | null;
    cardholderId?: IGuid | null;
    errorMessageId: string | null;
    /**
     * Whether the details pane is shown on the side (**true**) or fullscreen (**false**)
     */
    isSideBySide: boolean;
    isAddMode: boolean;
    privileges: EvaluatedCardholderPrivileges | null;
}

class ClearCardholderEditContextCacheAction {
    static readonly type = '[CardholderEditContext] Clear cardholder edit context cached values';
}

class FetchGloballyGrantedPrivilegesAction {
    static readonly type = '[CardholderEditContext] Fetch global privileges associated to cardholders';
}

@State<CardholderEditContextStateModel>({
    name: 'cardholderEditContext',
    defaults: {
        cardholderId: null,
        cardholder: null,
        errorMessageId: null,
        isSideBySide: true,
        isAddMode: false,
        privileges: null,
    },
})
@UntilDestroy()
@Injectable()
export class CardholderEditContextState implements NgxsOnInit {
    private static cardholderEditService: CardholderEditService;
    private static privilegeService: PrivilegeService;
    private static globalPrivileges: CardholderEditContextStateModel['privileges'] | null;

    constructor(cardholderEditService: CardholderEditService, private privilegeService: PrivilegeService, private authService: AuthService) {
        CardholderEditContextState.cardholderEditService = cardholderEditService;
        CardholderEditContextState.privilegeService = privilegeService;
    }

    public static isPrivilegeGranted(privilege: keyof EvaluatedCardholderPrivileges): (state: CardholderEditContextStateModel) => boolean {
        return createSelector([CardholderEditContextState], (state: CardholderEditContextStateModel) => {
            return state.privileges?.[privilege] ?? false;
        });
    }

    @Selector()
    public static grantedPrivileges(state: CardholderEditContextStateModel): CardholderEditContextStateModel['privileges'] {
        return state.privileges;
    }

    @Selector()
    public static isSideBySide(state: CardholderEditContextStateModel): CardholderEditContextStateModel['isSideBySide'] {
        return state.isSideBySide;
    }

    @Selector()
    public static isAddMode(state: CardholderEditContextStateModel): CardholderEditContextStateModel['isAddMode'] {
        return state.isAddMode;
    }

    @Selector()
    public static cardholder(state: CardholderEditContextStateModel): CardholderEditContextStateModel['cardholder'] {
        return state.cardholder;
    }

    @Selector()
    public static cardholderId(state: CardholderEditContextStateModel): CardholderEditContextStateModel['cardholderId'] {
        return state.cardholderId;
    }

    @Selector()
    public static errorMessageId(state: CardholderEditContextStateModel): CardholderEditContextStateModel['errorMessageId'] {
        return state.errorMessageId;
    }

    /**
     * Sets a new cardholder id. Will automatically fetch the actual cardholder from the server and update the state with
     * the resulting cardholder. Also, will set the mode to Edit.
     *
     * For editing an existing cardholder
     */
    @Receiver()
    static setCardholderIdAndPicture(
        { patchState }: StateContext<CardholderEditContextStateModel>,
        { payload }: EmitterAction<{ id: CardholderEditContextStateModel['cardholderId'] | string; picture?: string | null } | null>
    ): void {
        patchState({ privileges: null, cardholder: null, cardholderId: null, errorMessageId: null, isAddMode: false });

        if (!payload?.id) {
            patchState({ cardholder: null, cardholderId: null });
            return;
        }

        const { value, success } = SafeGuid.tryParse(payload?.id.toString() ?? '');
        if (!success) {
            patchState({ errorMessageId: 'STE_MESSAGE_ERROR_CARDHOLDER_NOT_FOUND' });
            throw new Error('Cardholder load error: invalid Guid');
        }

        patchState({ cardholderId: value });

        const cardholder$ = this.cardholderEditService.fetchCardholder(value);
        const privileges$ = this.cardholderEditService.fetchGrantedPrivileges(value);

        combineLatest([cardholder$, privileges$])
            .pipe(take(1))
            .subscribe(([cardholder, privileges]) => {
                if (cardholder && payload.picture) {
                    cardholder.picture = payload.picture;
                }
                if (privileges.viewCardholdersPrivilege) {
                    patchState({
                        cardholder,
                        privileges,
                        errorMessageId: cardholder ? null : 'STE_MESSAGE_ERROR_CARDHOLDER_NOT_FOUND',
                    });
                } else {
                    patchState({
                        cardholder: null,
                        privileges,
                        errorMessageId: 'STE_MESSAGE_ERROR_NOTAVAILABLEORINSUFFICIENTPRIVILEGE',
                    });
                }
            });
    }

    /**
     * For adding a new cardholder.
     */
    @Receiver()
    static setNewCardholder({ patchState }: StateContext<CardholderEditContextStateModel>): void {
        patchState({
            cardholder: createDefaultCardholderModel(),
            errorMessageId: null,
            cardholderId: null,
            privileges: this.globalPrivileges,
        });
    }

    @Receiver()
    static toggleSideBySide({ patchState, getState }: StateContext<CardholderEditContextStateModel>): void {
        patchState({ isSideBySide: !getState().isSideBySide });
    }

    @Receiver()
    static setFullScreen({ patchState }: StateContext<CardholderEditContextStateModel>): void {
        patchState({ isSideBySide: false });
    }

    @Receiver()
    static setAddMode({ patchState }: StateContext<CardholderEditContextStateModel>): void {
        patchState({ isAddMode: true });
    }

    @Receiver()
    static setEditMode({ patchState }: StateContext<CardholderEditContextStateModel>): void {
        patchState({ isAddMode: false });
    }

    @Receiver({ action: ClearCardholderEditContextCacheAction })
    public static clearCardholderEditContextCache({ patchState }: StateContext<CardholderEditContextStateModel>): void {
        this.globalPrivileges = null;

        // Reset all cardholder edit context values.
        patchState({
            cardholderId: null,
            cardholder: null,
            errorMessageId: null,
            isSideBySide: true,
            isAddMode: false,
            privileges: null,
        });
    }

    @Receiver({ action: FetchGloballyGrantedPrivilegesAction })
    public static fetchGloballyGrantedPrivileges({ patchState }: StateContext<CardholderEditContextStateModel>): void {
        const privileges: EvaluatedCardholderPrivileges = {} as EvaluatedCardholderPrivileges;
        Object.entries(CardholderPrivileges).forEach(([key, entityPrivilege]) => {
            privileges[key as keyof EvaluatedCardholderPrivileges] = this.privilegeService.isGlobalPrivilegeGranted(entityPrivilege.id);
        });

        this.globalPrivileges = privileges;

        patchState({
            privileges,
        });
    }

    ngxsOnInit({ dispatch }: StateContext<CardholderEditContextStateModel>): void {
        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe((loggedIn: boolean) => {
            if (!loggedIn) {
                dispatch(ClearCardholderEditContextCacheAction);
            } else {
                dispatch(FetchGloballyGrantedPrivilegesAction);
            }
        });
    }
}
