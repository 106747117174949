import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonFlavor, ItemSlot, Icon } from '@genetec/gelato';
import { EventEntry } from '@modules/shared/api/api';
import { EventTimeService } from '@modules/shared/services/time/event-time.service';
import { IEntity } from 'RestClient/Client/Interface/IEntity';

@Component({
    selector: 'app-event-item',
    templateUrl: './event-item.component.html',
    styleUrls: ['./event-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventItemComponent {
    @Input() public monitoredEntity?: IEntity;
    @Input() public formattedTime?: string | null;
    @Input() public formattedDate?: string | null;
    @Input() public event?: EventEntry;

    @Input() public isNew = false;
    @Input() public showDismissOnHover = false;
    @Input() public showEntityName = true;
    @Output() public dismissed = new EventEmitter<void>();
    @Output() public seen = new EventEmitter<void>();

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly ItemSlot = ItemSlot;

    public showDismiss = false;

    constructor(private eventTimeService: EventTimeService) {}

    public onDismiss(): void {
        this.dismissed.emit();
    }

    public onMouseEnter(): void {
        this.showDismiss = this.showDismissOnHover;

        if (this.isNew) {
            this.seen.emit();
        }
    }

    public onMouseLeave(): void {
        this.showDismiss = false;
    }

    public formatTime(): string | null {
        this.formattedTime = this.event ? this.eventTimeService.formatTime(this.event) : null;
        return this.formattedTime;
    }

    public formatDate(): string | null {
        this.formattedDate = this.event ? this.eventTimeService.formatDate(this.event) : null;
        return this.formattedDate;
    }
}
