/* eslint-disable @typescript-eslint/no-namespace */
import { IGuid, SafeGuid } from 'safeguid';

export namespace DsopConstant {
    export const STEP_PROCEDURE_COMPLETED = SafeGuid.parse('8B4719CE-0FB9-4A8C-B1CE-7824D77A3C48');
    export const STEP_PROCEDURE_BEGIN = SafeGuid.parse('f510a412-d8c3-42c9-afa3-fa5950fe987f');
    export const OPTION_START_NODE = SafeGuid.parse('08C729C7-3BD8-4D6F-9605-57BFB60D9E7B');
    export const OPTION_YES_NO = SafeGuid.parse('AF5E7576-C1B3-45CB-BBF6-FC329EB29A1C');
    export const OPTION_DONE = SafeGuid.parse('57990620-FAAA-4E80-9E32-0B99C91C61D2');
    export const OPTION_YES = SafeGuid.parse('3D0C4B10-FE9E-4D13-8DF2-F43E201CAC0E');
    export const OPTION_NO = SafeGuid.parse('008E8B7D-787A-4A09-B5CC-4CE058263C46');
}
