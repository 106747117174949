import { ResolvedFrameTime } from '../../utils/TimeResolver';
import { SegmentInfo } from '../../services/eventHub';
import { Utils } from '../../utils/Utils';

export class SequenceInfo {
  private static readonly ENDTIME_TO_FRAMETIME_THRESHOLD = 200 // acceptable 200ms gap between endTime and frameTime

  public readonly StartResolvedTime!: ResolvedFrameTime;

  private readonly SequenceId: number;

  private SegmentId: number;

  private m_endResolvedTime: ResolvedFrameTime;

  constructor(segmentId: number, sequenceId: number, startResolvedTime: ResolvedFrameTime, endResolvedTime: ResolvedFrameTime) {
    this.SegmentId = segmentId;
    this.SequenceId = sequenceId;
    this.StartResolvedTime = startResolvedTime;
    this.m_endResolvedTime = endResolvedTime;
    this.EndResolvedTime = endResolvedTime;
  }

  public set EndResolvedTime(resolvedEndTime: ResolvedFrameTime) {
    this.m_endResolvedTime = resolvedEndTime;
    if (this.DurationMs <= 0) throw new Error('Invalid sequence duration; StartTime:' + Utils.formatDateUTC(this.StartResolvedTime.frameTime) + ', EndTime:' + Utils.formatDateUTC(this.EndResolvedTime.frameTime));
  }

  public get EndResolvedTime(): ResolvedFrameTime {
    return this.m_endResolvedTime;
  }

  public get DurationMs(): number {
    return this.m_endResolvedTime.frameTime.getTime() - this.StartResolvedTime.frameTime.getTime();
  }

  public static from(segmentInfo: SegmentInfo): SequenceInfo {
    const frameTime = segmentInfo.FrameTimeAsDate;
    const endTime = Utils.addSeconds(frameTime, segmentInfo.TotalSeconds);
    return new SequenceInfo(segmentInfo.Id, segmentInfo.SequenceId, new ResolvedFrameTime(frameTime, segmentInfo.MediaTime), new ResolvedFrameTime(endTime, segmentInfo.MediaTime + segmentInfo.TotalMilliseconds));
  }

  public add(segmentInfo: SegmentInfo): void {
    this.SegmentId = segmentInfo.Id;
    const segmentEndTime = Utils.addSeconds(segmentInfo.FrameTimeAsDate, segmentInfo.TotalSeconds);
    this.EndResolvedTime = new ResolvedFrameTime(segmentEndTime, segmentInfo.MediaTime + segmentInfo.TotalMilliseconds);
  }

  public isInSequence(segmentInfo: SegmentInfo): boolean {
    return this.SequenceId === segmentInfo.SequenceId && this.SegmentId + 1 === segmentInfo.Id && (Math.abs(segmentInfo.FrameTimeAsDate.getTime() - this.EndResolvedTime.frameTime.getTime()) <= SequenceInfo.ENDTIME_TO_FRAMETIME_THRESHOLD);
  }

  public debugStatus(indent: number): string {
    return 'SequenceInfo' + Utils.indentNewLine(indent) +
      'SequenceId: ' + this.SequenceId + Utils.indentNewLine(indent) +
      'SegmentId: ' + this.SegmentId + Utils.indentNewLine(indent) +
      'StarTime: ' + this.StartResolvedTime.toString() + Utils.indentNewLine(indent) +
      'EndTime: ' + this.EndResolvedTime.toString();
  }
}
