import { MCCommandBase, MCCommandType } from "@modules/mission-control/models/commands/command";
import { IGuid } from "safeguid";

const commandType = MCCommandType.LinkIncidents;

export class LinkIncidentCommand extends MCCommandBase{
    public type = commandType;

    constructor(incidentId: IGuid, incidentToLink: IGuid) {
        super(
            `/v2/Incidents`,
            JSON.stringify({
                Type: commandType,
                Ids: [incidentId.toString(),incidentToLink.toString()],
            })
        );
    }

    public errorMessage(statusCode?: number): string {
        return 'STE_MESSAGE_CANNOT_LINK_INCIDENTS';
    }
}
