export {};

declare global {
    // interface Map<K, V> {
    //     filter (predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): [K,V][];
    // }

    function mapEvery<K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): boolean;
    function mapFilter<K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): [K,V][];
    function mapFind<K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): [K,V] | undefined;
    function mapSome<K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): boolean;
    function setEvery<T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): boolean;
    function setFilter<T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): T[];
    function setFind<T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): T | undefined;
    function setSome<T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): boolean;
}

// if (!Map.prototype.filter) {
//     Map.prototype.filter = function <K, V>(this: Map<K, V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): [K, V][] {
//         return Array.from(this.entries()).filter(predicate);
//     };
// }

  globalThis.mapEvery = <K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): boolean => {
    return Array.from(map.entries()).every(predicate);
  };

  globalThis.mapFilter = <K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): [K,V][] => {
    return Array.from(map.entries()).filter(predicate);
  };

  globalThis.mapFind = <K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): [K,V] | undefined => {
    return Array.from(map.entries()).find(predicate);
  };

  globalThis.mapSome = <K,V>(map: Map<K,V>, predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): boolean => {
    return Array.from(map.entries()).some(predicate);
  };

  globalThis.setEvery = <T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): boolean => {
    return Array.from(set.values()).every(predicate);
  };

  globalThis.setFilter = <T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): T[] => {
    return Array.from(set.values()).filter(predicate);
  };

  globalThis.setFind = <T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): T | undefined => {
    return Array.from(set.values()).find(predicate);
  };

  globalThis.setSome = <T>(set: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): boolean => {
    return Array.from(set.values()).some(predicate);
  };