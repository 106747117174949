import { ILogger } from '../../utils/logger';

export interface IWebSocket {
  onopen: ((ev: Event) => any) | null;
  onmessage: ((ev: MessageEvent) => any) | null;
  onclose: ((ev: CloseEvent) => any) | null;
  onerror: ((ev: Event) => any) | null;

  send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void;
  close(code?: number, reason?: string): void;
}

export class WebSocketBuilder {
  private readonly m_logger: ILogger;

  private readonly m_webSocketEndpoint: URL;

  constructor(logger: ILogger, mediaGatewayUrl: string) {
    this.m_logger = logger.subLogger('WebSocketBuilder: ' + mediaGatewayUrl);

    this.m_webSocketEndpoint = new URL(mediaGatewayUrl);
    this.m_webSocketEndpoint.pathname += '/v2/ws';

    // JDT - Modif
    const isSecure = mediaGatewayUrl.toLowerCase().startsWith('wss');
    if (isSecure) {
      this.m_webSocketEndpoint.protocol = 'wss';
    } else {
      this.m_webSocketEndpoint.protocol = 'ws';
    }
  }

  public async establishWebSocket(): Promise<IWebSocket> {
    const webSocket = new WebSocket(this.m_webSocketEndpoint.toString());
    webSocket.binaryType = 'arraybuffer';

    const connectionPromise = new Promise<void>((resolve, reject: (ev: CloseEvent) => void) => {
      webSocket.onopen = () => resolve();
      // error does not contain any information and onclose will be called afterward with more information
      webSocket.onclose = (ev) => reject(ev);
    });

    this.m_logger.info?.trace('Awaiting connection:', this.m_webSocketEndpoint.toString());

    await connectionPromise.catch((closeEvent: CloseEvent) => {
      webSocket.onopen = null;
      webSocket.onclose = null;
      const errorMessage = `Connection to ${this.m_webSocketEndpoint.toString()} failed: ${closeEvent.code} ${closeEvent.reason}`;
      this.m_logger.warn?.trace(errorMessage);
      throw new Error(errorMessage);
    });

    this.m_logger.info?.trace('Connection established:', this.m_webSocketEndpoint.toString());

    webSocket.onopen = null;
    webSocket.onclose = null;

    return webSocket;
  }
}
