import { Vec3 } from '../utils/Vec3';
import { PlaneEquation } from './DewarpingPlane';
import { Uniform3f, Uniform4f } from './WebGL2/Uniform';

export class ControllerUniforms {
  private readonly m_pPrime: Uniform3f;
  private readonly m_uHat: Uniform3f;
  private readonly m_vHat: Uniform3f;
  private readonly m_dewarpingPlaneEquation: Uniform4f | null;

  public get pPrime(): Vec3 {
    return this.toVec3(this.m_pPrime);
  }

  public set pPrime(pPrime: Vec3) {
    this.m_pPrime.set(pPrime.X, pPrime.Y, pPrime.Z);
  }

  public get uHat(): Vec3 {
    return this.toVec3(this.m_uHat);
  }

  public set uHat(uHat: Vec3) {
    this.m_uHat.set(uHat.X, uHat.Y, uHat.Z);
  }

  public get vHat(): Vec3 {
    return this.toVec3(this.m_vHat);
  }

  public set vHat(vHat: Vec3) {
    this.m_vHat.set(vHat.X, vHat.Y, vHat.Z);
  }

  public get PlaneEquation(): PlaneEquation {
    if (this.m_dewarpingPlaneEquation === null) {
      return new PlaneEquation(0, 0, 0, 0);
    }
    const planeEquation = this.m_dewarpingPlaneEquation.get();
    return new PlaneEquation(planeEquation[0], planeEquation[1], planeEquation[2], planeEquation[3]);
  }

  public set PlaneEquation(dewarpingPlaneEquation: PlaneEquation) {
    if (this.m_dewarpingPlaneEquation === null) {
      return;
    }

    this.m_dewarpingPlaneEquation.set(dewarpingPlaneEquation.A, dewarpingPlaneEquation.B, dewarpingPlaneEquation.C, dewarpingPlaneEquation.D);
  }

  public constructor(pPrime: Uniform3f, uHat: Uniform3f, vHat: Uniform3f, dewarpingPlaneEquation: Uniform4f | null) {
    this.m_pPrime = pPrime;
    this.m_uHat = uHat;
    this.m_vHat = vHat;
    this.m_dewarpingPlaneEquation = dewarpingPlaneEquation;
  }

  private toVec3(uniform3: Uniform3f): Vec3 {
    const xyz = uniform3.get();
    return new Vec3(xyz[0], xyz[1], xyz[2]);
  }
}
