<gen-melted-modal
    id="SaveTaskModal"
    genTitle="{{ 'STE_TITLE_SAVE_TASK' | translate }}"
    [genDefaultActionText]="saveButtonText"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnDefaultAction]="onSaveClick"
    [genIsDefaultActionDisabled]="!canSave"
    [genCanClose]="true"
    [genIsBlocking]="true"
    [genDestructiveAction]="destructiveAction"
>
    <gen-melted-modal-body>
        <gen-text [flavor]="TextFlavor.Label"> {{ 'STE_LABEL_DESTINATION' | translate }} </gen-text>

        <gen-radio
            [(ngModel)]="taskVisibility"
            (ngModelChange)="onDestinationChange()"
            id="radioPrivate"
            value="private"
            name="destination"
            class="mt-1"
            [disabled]="privateTaskDisabled"
        >
            <gen-label>{{ 'STE_LABEL_PRIVATE_TASKS' | translate }}</gen-label>
        </gen-radio>
        <gen-radio
            [(ngModel)]="taskVisibility"
            (ngModelChange)="onDestinationChange()"
            id="radioPublic"
            value="public"
            name="destination"
            class="mt-1 mb-2"
            [disabled]="publicTaskDisabled"
        >
            <gen-label>{{ 'STE_LABEL_PUBLIC_TASKS' | translate }}</gen-label>
        </gen-radio>

        <gen-text [flavor]="TextFlavor.Label"> {{ 'STE_LABEL_EXISTING_TASKS' | translate }} </gen-text>

        <gen-text *ngIf="existingTaskItems.length === 0" class="savemodal__notasks"> {{ 'STE_LABEL_NO_TASKS' | translate }} </gen-text>

        <ng-container *ngIf="existingTaskItems.length > 0">
            <gen-tree class="tasktreeview" [appTreeDataSource]="existingTaskItems" [appTreeItemClick]="onSelectedTaskChange.bind(this)"></gen-tree>
        </ng-container>

        <gen-text-input
            [(ngModel)]="taskName"
            [readonly]="!taskVisibility"
            (ngModelChange)="onNameChange()"
            placeholder="{{ 'STE_LABEL_TASKNAME_PLACEHOLDER' | translate }}"
            class="mt-2 mb-2"
            [maxlength]="100"
        >
            <gen-label>{{ 'STE_LABEL_TASK_NAME' | translate }}</gen-label>
        </gen-text-input>

        <app-entity-combo
            #partitionEntityCombo
            *ngIf="showPartitionPicker"
            [(entityId)]="partitionGuid"
            (entityIdChange)="onSelectedPartitionChanged()"
            genLabel="{{ 'STE_ENTITY_PARTITION' | translate }}"
            [showClearButton]="false"
            [entityBrowserCanSelectItemFunction]="canSelectPartition"
        ></app-entity-combo>
    </gen-melted-modal-body>
</gen-melted-modal>
