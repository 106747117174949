// Autogenerated: v2/help/Classes/ApiRoutableEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:08 PM
import { EventBase } from './EventBase';
import { IRoutableEvent } from '../Interface/IRoutableEvent';

export class RoutableEvent extends EventBase implements IRoutableEvent {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
    }

    // #endregion Methods

}

