<div class="image">
    <gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
    <gen-image *ngIf="!isLoading && thumbnailPicture" [flavor]="ImageFlavor.Rectangle" [src]="thumbnailPicture" [fit]="ImageFit.Contain" class="lg"></gen-image>
</div>
<div>
    <gen-grid class="mt-2" [genColumns]="1" [genGridGap]="1">
        <!-- <gen-grid-item>
            <gen-text [flavor]="TextFlavor.Label">Username</gen-text>
            <gen-text [flavor]="TextFlavor.Normal">{{ username }}</gen-text>
        </gen-grid-item> -->
        <gen-grid-item *ngIf="email">
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_NOUN_EMAIL' | translate }}</gen-text> <gen-text [flavor]="TextFlavor.Normal">{{ email }}</gen-text>
        </gen-grid-item>
        <gen-grid-item *ngIf="lastPosition">
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_LASTPOSITION' | translate }}</gen-text> <gen-text [flavor]="TextFlavor.Normal">{{ lastPosition }}</gen-text>
        </gen-grid-item>
    </gen-grid>
</div>
