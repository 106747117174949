import { Component } from '@angular/core';
import { FilterTextBaseComponent } from '../filter-text-base.component';

/**
 * Filter for simple text, showing a full text box instead of a dropdown.
 */
@Component({
    selector: 'app-filter-search',
    templateUrl: './filter-search.component.html',
    styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent extends FilterTextBaseComponent {}
