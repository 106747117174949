import gwp, {
    AudioAvailabilityChangeEvent,
    AudioStateChangeEvent,
    ErrorStatusEvent,
    IDewarperControl,
    IDigitalZoomControl,
    ILiteEvent,
    IPtzControl,
    ITimelineProvider,
    PlayerModeChangeEvent,
    PlayerStateChangeEvent,
    PlaySpeedChangeEvent,
    StreamingConnectionStatusChangeEvent,
} from './Marmot/gwp';
import { ExportModel, ExportProgressEvent } from './export';
import { TimelinePartEvent } from './Timeline/timelinePartEvent';
import { IGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2020 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

// important: expose the gwp object in the console for diagnostics
declare global {
    interface Window {
        gwp: gwp;
    }
}

export class LoopChangeEvent {
    public readonly isLooping: boolean;
    public readonly start: Date | undefined;
    public readonly end: Date | undefined;

    constructor(isLooping: boolean, start: Date | undefined, end: Date | undefined) {
        this.isLooping = isLooping;
        this.start = start;
        this.end = end;
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IVideoPlayer {
    //#region Events

    /** Event raised when the player gets into an Error state */
    readonly onErrorStateRaised: ILiteEvent<ErrorStatusEvent>;
    /** Event raised periodically (not every time) when a frame is rendered */
    readonly onFrameRendered: ILiteEvent<Date>;
    /** Event raised when the streaming connection status changes */
    readonly onStreamStatusChanged: ILiteEvent<StreamingConnectionStatusChangeEvent>;
    /** Event raised when the player state changes */
    readonly onPlayerStateChanged: ILiteEvent<PlayerStateChangeEvent>;
    /** Event raised when play speed changes */
    readonly onPlaySpeedChanged: ILiteEvent<PlaySpeedChangeEvent>;
    /** Event raised when loop range changes */
    readonly onLoopChanged: ILiteEvent<LoopChangeEvent>;
    /** Event raised when audio playback state changes */
    readonly onAudioStateChanged: ILiteEvent<AudioStateChangeEvent>;
    /** Event raised when audio playback availability changes */
    readonly onAudioAvailabilityChanged: ILiteEvent<AudioAvailabilityChangeEvent>;
    /** Event raised when player mode changes between live and playback */
    readonly onPlayerModeChanged: ILiteEvent<PlayerModeChangeEvent>;
    /** Event raised periodically while player needs to buffer before it can start playing */
    readonly onBufferingProgress: ILiteEvent<number>;
    /** Event raised periodically while export is in progress */
    readonly onExportProgress: ILiteEvent<ExportProgressEvent>;
    /** Event raised when a new timeline part is received */
    readonly onTimelinePart: ILiteEvent<TimelinePartEvent>;

    //#endregion

    //#region Properties

    isAudioEnabled: boolean;
    isAudioAvailable: boolean;
    isStarted: boolean;
    lastFrameTime: Date | undefined;
    ptzControl: IPtzControl | undefined;
    digitalZoomControl: IDigitalZoomControl | null;
    dewarperControl: IDewarperControl | null;
    timelineProvider: ITimelineProvider | undefined;
    baseUrl: string | undefined;
    isPtz?: boolean;
    supportPtz?: boolean;
    //#endregion

    //#region Methods

    addTimelineProviders(providers: Array<IGuid>, add: boolean, entities: Array<IGuid>): Promise<Response>;
    getExportAsync(exportModel: ExportModel): Promise<Response>;
    getThumbnailAsync(timestamp: Date, width: number): Promise<string>;
    seek(seekTime: Date): void;
    playLive(): void;
    resume(maintainSpeed?: boolean): void;
    pause(maintainSpeed?: boolean): void;
    clearLoop(): void;
    setLoop(start: Date, end: Date): void;
    setPlaySpeed(playSpeed: number): void;
    takeSnapshotAsync(): Promise<Blob>;
    setAudioEnabled(isAudioEnabled: boolean): void;
    setPtzMode(ptzMode: boolean): void;
    //#endregion
}
