import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ModalEntitiesSelectorComponent } from '@modules/shared/components/entity-browser/modal-entities-selector/modal-entities-selector.component';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { Color } from '@genetec/gelato-angular';
import { Icon, TableDensity, SelectionType, TextFlavor, ButtonFlavor, TableFlavor } from '@genetec/gelato';
import { CardholderEditContextState } from '@modules/access-control/cardholder-edit-context.state';
import { StateObservable } from '@src/app/store/decorators/state-observable.decorator';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CardholderEditService, CardholderRelationEntityModel, CardholderRelationType } from '@modules/access-control/services/cardholder-edit-service';
import { IGuid } from 'safeguid';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { TypedSimpleChanges } from '@shared/utilities/typed-simple-changes';
import { CardholderRelationsColumnDescriptor } from './interfaces';

@UntilDestroy()
@Component({
    selector: 'app-cardholder-relations-sub-form[entityType]',
    templateUrl: './cardholder-relations-sub-form.component.html',
    styleUrls: ['./cardholder-relations-sub-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CardholderRelationsSubFormComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardholderRelationsSubFormComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() control?: UntypedFormControl;
    @Input() entityType!: CardholderRelationType;
    @Input() headerTitle?: string;
    @Input() modalTitle = '';
    @Input() emptyMessage: string = this.translateService.instant('STE_LABEL_NO_ITEMS') as string;
    @Input() formReset$?: Observable<IGuid | undefined>;
    @Input() showHeader = true;
    @Input() readonly = false;
    @Input() showDescriptionColumn = true;
    @Input() showDelete = true;
    @Input() showAdd = true;
    @Input() excludedIds: IGuid[] = [];
    @Input() hasRequiredPrivileges = false;
    @Input() refresherType?: IGuid;
    @Input() extraColumnsDescriptors?: CardholderRelationsColumnDescriptor[];

    @ViewChild('entitiesSelector') public entitiesSelector?: ModalEntitiesSelectorComponent;

    @Select(CardholderEditContextState.cardholderId)
    public cardholderId$!: StateObservable<typeof CardholderEditContextState.cardholderId>;

    public readonly Color = Color;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly TextFlavor = TextFlavor;
    public readonly Icon = Icon;
    public readonly SelectionType = SelectionType;
    public readonly TableDensity = TableDensity;
    public readonly TableFlavor = TableFlavor;

    public touched = false;
    public forbidDelete$!: Observable<boolean>;

    protected selectedTableRowIdsSubject = new BehaviorSubject([] as IGuid[]);
    protected entitiesSubject = new BehaviorSubject<CardholderRelationEntityModel[]>([]);
    protected entityBrowserFilterSubject!: BehaviorSubject<EntityBrowserFilter>;
    protected isLoadingSubject = new BehaviorSubject<boolean>(true);

    private currentCardholderId?: IGuid;
    private onCardholderChange: Subject<void> = new Subject();
    private selectedIds: IGuid[] = [];

    constructor(private cardholderEditService: CardholderEditService, private logger: LoggerService, private translateService: TranslateService) {}

    ngOnChanges(changes: TypedSimpleChanges<typeof this>): void {
        if (changes.entityType || changes.refresherType) {
            const entityBrowserFilter = new EntityBrowserFilter(this.entityType);
            if (this.refresherType) {
                entityBrowserFilter.refresherType = this.refresherType;
            }
            this.entityBrowserFilterSubject.next(entityBrowserFilter);
        }
    }

    ngOnInit(): void {
        const entityBrowserFilter = new EntityBrowserFilter(this.entityType);
        if (this.refresherType) {
            entityBrowserFilter.refresherType = this.refresherType;
        }
        this.entityBrowserFilterSubject = new BehaviorSubject<EntityBrowserFilter>(entityBrowserFilter);
        this.formReset$?.pipe(untilDestroyed(this)).subscribe(async (cardholderId: IGuid | undefined) => {
            this.currentCardholderId = cardholderId;
            this.onCardholderChange.next();
            this.selectedIds = (await this.cardholderEditService.fetchCardholderRelationIdsAsync(cardholderId, this.entityType)) || [];
            this.updateDisplayedEntities(this.selectedIds);
        });
        this.forbidDelete$ = combineLatest([this.selectedTableRowIdsSubject, this.entitiesSubject]).pipe(
            map(([selected, entities]) =>
                selected.some((id) => {
                    const associatedEntity = entities.find((entity) => entity.id.equals(id));
                    return !associatedEntity?.isDeletable;
                })
            )
        );
    }

    public removeEntity(id: IGuid): void {
        this.onTouched();

        this.selectedIds = this.selectedIds.filter((selectedId) => !selectedId.equals(id));
        this.updateDisplayedEntities(this.selectedIds || []);

        this.onChange(this.selectedIds);
    }

    public removeAllSelectedRows(): void {
        this.onTouched();
        const selectedTableRowIds = this.selectedTableRowIdsSubject.getValue();

        this.selectedIds = this.selectedIds.filter((id1) => !selectedTableRowIds.find((id2) => id1.equals(id2)));
        this.selectedTableRowIdsSubject.next([]);

        this.removeNonIncludedEntities(this.selectedIds ?? []);

        this.onChange(this.selectedIds);
    }

    public toggleRowSelection(rowId: IGuid): void {
        const selectedTableRowIds = this.selectedTableRowIdsSubject.getValue();
        if (selectedTableRowIds.includes(rowId)) {
            selectedTableRowIds.remove(rowId);
        } else {
            selectedTableRowIds.push(rowId);
        }
        this.selectedTableRowIdsSubject.next(selectedTableRowIds);
    }

    public onEntityIdsSelected(ids: IGuid[]): void {
        this.onTouched();

        this.selectedIds.push(...ids);
        this.updateDisplayedEntities(this.selectedIds || []);

        this.onChange(this.selectedIds);
    }

    openAddEntitiesModal(): void {
        this.entitiesSelector?.show();
    }

    writeValue(selectedIds: IGuid[] | null): void {
        this.selectedIds = selectedIds || [];
    }

    registerOnChange(onChange: (selectedIds: IGuid[]) => Record<string, never>): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => unknown): void {
        this.onTouched = onTouched;
    }

    private onTouched: () => unknown = () => {};

    private onChange = (_: IGuid[]): void => {};

    private updateEntityBrowserExcludedEntities(alreadySelectedIds: IGuid[]) {
        const entityBrowserFilter = this.entityBrowserFilterSubject?.getValue();
        if (entityBrowserFilter) {
            entityBrowserFilter.excludedEntities.clear();
            const ids = [...alreadySelectedIds, ...this.excludedIds];
            ids.forEach((id) => entityBrowserFilter?.excludedEntities.add(id));
            this.entityBrowserFilterSubject.next(entityBrowserFilter);
        }
    }

    private removeNonIncludedEntities(idsToKeep: IGuid[]) {
        // Keep only entities included in incoming list
        const remainingEntities = this.entitiesSubject.getValue().filter((entity) => idsToKeep.some((id) => id.equals(entity.id)));
        this.entitiesSubject.next(remainingEntities);

        // updates entity-browser's excluded entities
        this.updateEntityBrowserExcludedEntities(idsToKeep);
    }

    private updateDisplayedEntities(ids: IGuid[]) {
        // updates entities
        this.cardholderEditService
            .fetchRelationEntitiesAsync(ids, this.currentCardholderId, this.entityType)
            .then((entities) => {
                this.entitiesSubject.next(entities);
                this.isLoadingSubject.next(false);
            })
            .catch((error) => this.logger.traceDebug(error));

        // updates entity-browser's excluded entities
        this.updateEntityBrowserExcludedEntities(ids);

        this.selectedTableRowIdsSubject.next([]);
    }
}
