<gen-melted-modal
    [id]="id"
    [genTitle]="title || defaultTitle"
    [genIsBlocking]="false"
    [genIsDefaultActionDisabled]="entityBrowser.hasSelectedSomething()"
    [genDefaultActionText]="defaultActionText"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    (genModalAction)="onAction($event)"
    appFocusModalWhenOpen
>
    <gen-melted-modal-body>
        <app-entity-browser
            #entityBrowser
            class="entity-browser-size"
            [filter]="filter"
            [showFooter]="showFooter"
            [allowMultiSelect]="(allowMultiSelect$ | async) || defaultAllowMultiSelect"
            [areItemsCheckable]="areItemsCheckable"
            (entityDoubleClicked)="onEntityDoubleClicked($event)"
            (selectedEntitiesChanged)="!areItemsCheckable && onSelectedEntitiesChanged($event)"
            (checkedEntitiesChanged)="areItemsCheckable && onSelectedEntitiesChanged($event)"
        >
        </app-entity-browser>
    </gen-melted-modal-body>
</gen-melted-modal>
