// Autogenerated: v2/help/Entities/ApiWebClientRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-04-15 12:15:55 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IFieldObject } from './IFieldObject';
import { IPluginRoleEntity, PluginRoleEntityFields } from './IPluginRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class WebClientRoleEntityFields extends PluginRoleEntityFields {
    public static exportFolderField = 'ExportFolder';
    public static httpPortField = 'HttpPort';
    public static httpsPortField = 'HttpsPort';
    public static useDefaultWebServerPortField = 'UseDefaultWebServerPort';
    public static useInfiniteSessionsField = 'UseInfiniteSessions';
    public static webAddressField = 'WebAddress';
}

// #endregion Fields

// #region Inner classes

export interface ISetting extends IFieldObject {
    key: string;
    value: string;
}

// #endregion Inner classes

export interface IWebClientRoleEntity extends IPluginRoleEntity {
    // #region REST Properties

    exportFolder: string;

    httpPort: number;

    httpsPort: number;

    useDefaultWebServerPort: boolean;

    useInfiniteSessions: boolean;

    webAddress: string;

    // #endregion REST Properties

    // #region REST Methods

    getSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ISetting> | null>;

    // #endregion REST Methods

}

