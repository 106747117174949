import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GenAction, GenMeltedItem, GenListActions, GenMeltedListItem, GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { FilterClient } from '@modules/shared/api/api';
import { FilterBaseComponent } from '@modules/shared/components/filters/common-filters/filter-base.component';
import { MODAL_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { FilterCoordinatorService } from '@modules/shared/services/filters/filter-coordinator-service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { IGuid, SafeGuid } from 'safeguid';
import { CorrelationService } from '../../../services/correlation.service';
import { CorrelationParametersModalComponent } from '../../parameters-modal/correlation-parameters-modal.component';
import { CorrelationSearchParameter } from '../../parameters-modal/correlation-search-parameter';
import { FilterDataTypesModel } from './filter-data-types.model';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export class DataTypesFilter {
    public DataTypes: Array<IGuid> = [];
    public Parameters: Array<CorrelationSearchParameter> = [];
    public Description!: string;
}

@Component({
    selector: 'app-filter-data-types',
    templateUrl: './filter-data-types.component.html',
    styleUrls: ['./filter-data-types.component.scss'],
})
export class FilterDataTypesComponent extends FilterBaseComponent<FilterDataTypesModel> implements OnInit, OnDestroy {
    public static addParameterAction = '1acc3f1b-2978-4eec-a238-31cdff1422bd';
    public static editParameterAction = 'e8fc447c-eed0-4e40-9d9f-53b6d603021f';
    public static removeParameterAction = '96b5e08e-11fc-42ae-b194-9ccfb9f5694f';

    //#region Fields

    public currentFilter: FilterDataTypesModel = new FilterDataTypesModel();
    public dataTypes: Array<GenMeltedItem> = [];
    public parameters: Array<GenMeltedItem> = [];

    public conditionActions!: GenListActions;

    private addParameterAction: GenAction = {
        execute: (items) => this.addParameterActionExecute(items),
        canExecute: () => true,
        id: FilterDataTypesComponent.addParameterAction,
    };
    private editParameterAction: GenAction = {
        execute: (items) => this.editParameterActionExecute(items),
        canExecute: (items) => this.editParameterActionCanExecute(items),
        id: FilterDataTypesComponent.editParameterAction,
    };
    private removeParameterAction: GenAction = {
        execute: (items) => this.removeParameterActionExecute(items),
        canExecute: (items) => this.removeParameterActionCanExecute(items),
        id: FilterDataTypesComponent.removeParameterAction,
    };

    private selectedItems: Array<GenMeltedItem> = [];

    //#endregion

    constructor(
        trackingService: TrackingService,
        private correlationService: CorrelationService,
        @Inject(MODAL_SERVICE) private modalService: GenModalService,
        filterClient: FilterClient,
        filterCoordinatorService: FilterCoordinatorService,
        translateService: TranslateService
    ) {
        super(trackingService, filterClient, filterCoordinatorService, translateService);
        this.conditionActions = {
            add: this.addParameterAction,
            edit: this.editParameterAction,
            delete: this.removeParameterAction,
        };
    }

    //#region Public Methods

    public onParametersChanged(items: unknown): void {}

    public onDataTypesChanged(items: Array<GenMeltedListItem> | GenMeltedListItem | null): void {
        if (!items) {
            // early exit if items is null
            return;
        }

        let selectedItemsIds: string[];
        if (items instanceof Array) {
            this.selectedItems = items;
            selectedItemsIds = items.map((x: GenMeltedListItem) => x.id);
        } else {
            this.selectedItems = [items];
            selectedItemsIds = [items.id];
        }
        this.currentFilter.dataTypes = selectedItemsIds.map((grp: string) => SafeGuid.parse(grp));
        this.value = this.currentFilter;
    }

    public onFilterToggled(isFilterOpened: boolean): void {
        super.onFilterToggled(isFilterOpened);
    }

    public async ngOnInit() {
        super.ngOnInit();

        this.selectedItems = [];

        await this.loadDataTypes();

        this.value = this.currentFilter;
    }

    public updateState(): void {
        const selectedItems = this.selectedItems;
        let selection = this.state.selection;

        if (!selectedItems?.length) {
            selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
        } else if (selectedItems.length === 1) {
            selection = selectedItems[0].text;
        } else {
            selection = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: selectedItems.length }) as string;
        }

        this.state = { ...this.state, selection };
    }

    public clearFilter(): void {
        this.value = new FilterDataTypesModel();
    }

    public isDefaulted(): boolean {
        return this.value.dataTypes.length === 0 && this.value.parameters.length === 0;
    }

    public override getDefaultValue(): FilterDataTypesModel {
        return new FilterDataTypesModel();
    }

    //#endregion

    //#region Private Methods

    private async loadDataTypes() {
        this.dataTypes = [];
        if (this.correlationService) {
            const dataTypes = await this.correlationService.getDataTypes();
            if (dataTypes) {
                for (const dt of dataTypes) {
                    const item: GenMeltedItem = { text: dt.name, id: dt.dataType.toString() };
                    if (dt.customIcon) {
                        item.image = dt.customIcon;
                    } else if (dt.icon) {
                        item.icon = dt.icon as MeltedIcon;
                    } else {
                        item.icon = MeltedIcon.CorrelationService;
                    }
                    this.dataTypes.push(item);
                }
            }
        }
    }

    private async addParameterActionExecute(items?: any[] | undefined): Promise<void> {
        if (this.modalService) {
            const modal = this.modalService.open(CorrelationParametersModalComponent, undefined);
            if (modal) {
                // set the modal data & refresh the view
                await modal.refresh(this.currentFilter.dataTypes, undefined);

                const promise = new Promise<CorrelationSearchParameter | undefined>((resolve) => {
                    modal.returnResult = resolve;
                });
                const result = await promise;
                if (result) {
                    const item = {
                        id: result.FieldName + '||' + result.ValueType,
                        text: result.DisplayText,
                    };
                    this.parameters.push(item);
                    // set the value
                    this.currentFilter.parameters.push(result);
                    this.value = this.currentFilter;
                }
            }
        }
    }

    private async editParameterActionExecute(items?: any[] | undefined) {
        let parameter: CorrelationSearchParameter | undefined;
        if (items) {
            const item = items[0] as unknown;
            if (isObject(item) && typeof item.id === 'string') {
                const parts = item.id.split('||');
                if (parts.length >= 2) {
                    const filter = this.value;
                    if (filter?.parameters) {
                        parameter = filter?.parameters.find((param) => param.FieldName === parts[0] && param.ValueType === parts[1]);
                    }
                }
            }
        }
        if (parameter) {
            if (this.modalService) {
                const modal = this.modalService.open(CorrelationParametersModalComponent, undefined);
                if (modal) {
                    // set the modal data & refresh the view
                    await modal.refresh(this.currentFilter.dataTypes, parameter);

                    const promise = new Promise<CorrelationSearchParameter | undefined>((resolve) => {
                        modal.returnResult = resolve;
                    });
                    const result = await promise;
                    if (result) {
                        const id = result.FieldName + '||' + result.ValueType;
                        this.parameters = this.parameters.filter((param: GenMeltedItem) => param.id !== id);
                        const item = {
                            id,
                            text: result.DisplayText,
                        };
                        this.parameters.push(item);

                        // set the value
                        this.currentFilter.parameters = this.currentFilter.parameters.filter(
                            (item2) => item2.FieldName !== result?.FieldName && item2.ValueType !== result?.ValueType
                        );
                        this.currentFilter.parameters.push(result);
                        this.value = this.currentFilter;
                    }
                }
            }
        }
    }

    private editParameterActionCanExecute(items?: any[] | undefined): boolean {
        if (items) {
            if (items.length > 0) {
                return true;
            }
        }
        return false;
    }

    private removeParameterActionExecute(items?: any[] | undefined) {
        if (items) {
            const item = items[0] as unknown;
            if (isObject(item) && typeof item.id === 'string') {
                const parts = item.id.split('||');
                if (parts.length >= 2) {
                    this.parameters = this.parameters.filter((param) => param.id !== item.id);
                    this.currentFilter.parameters = this.currentFilter.parameters.filter((param) => param.FieldName !== parts[0] && param.ValueType !== parts[1]);
                    this.value = this.currentFilter;
                }
            }
        }
    }

    private removeParameterActionCanExecute(items?: any[] | undefined): boolean {
        if (items) {
            if (items.length > 0) {
                return true;
            }
        }
        return false;
    }

    //#endregion
}
