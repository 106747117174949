import { SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class CorrelationContentTypes {
    public static readonly datum = new SafeGuid('5473813F-AA41-42E5-A37D-62BA0314BEAF');
    //e205ee2d-c132-43ec-b52a-10401ba294cc
    public static readonly mobileUser = new SafeGuid('e205ee2d-c132-43ec-b52a-10401ba294cc');

    public static readonly visualModel = 'visualmodel';
    public static readonly dataType = 'datatype';
    public static readonly showTooltip = 'showtooltip';

    public static readonly specialCaseDatums = [CorrelationContentTypes.mobileUser];
}
