export class ShaderBuilder {
  public static readonly m_version300es = '#version 300 es';

  protected readonly m_gl: WebGL2RenderingContext;

  constructor(webGLRenderingContext: WebGL2RenderingContext) {
    this.m_gl = webGLRenderingContext;
  }

  public buildVertexShader(source: string): WebGLShader {
    return this.buildShader(this.m_gl.VERTEX_SHADER, source);
  }

  public buildFragmentShader(source: string): WebGLShader {
    return this.buildShader(this.m_gl.FRAGMENT_SHADER, source);
  }

  private buildShader(type: number, source: string): WebGLShader {
    const shader = this.m_gl.createShader(type);
    if (shader === null) {
      throw new Error(`Failed to make shader ${type}`);
    }

    this.m_gl.shaderSource(shader, source);
    this.m_gl.compileShader(shader);
    const success = this.m_gl.getShaderParameter(shader, this.m_gl.COMPILE_STATUS);
    if (success) {
      return shader;
    }

    const shaderInfoLog = this.m_gl.getShaderInfoLog(shader);
    this.m_gl.deleteShader(shader);

    console.error(source);

    throw Error(shaderInfoLog ?? 'Failed to compile shader');
  }
}
