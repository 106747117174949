import { Inject, Injectable } from '@angular/core';
import { GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { MapContentTypes } from '@modules/maps/enumerations/maps-content-types';
import { EventMonitoringContext } from '@modules/shared/enumerations/analytics-event-monitoring-context';
import { AnalyticsNames } from '@modules/shared/enumerations/analytics-names';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { EventsService } from '@modules/shared/services/events/events.service';
import { EventMonitoringFeatureFlags } from '@modules/shared/services/events/feature-flags';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { UserWatchlistService } from '@modules/shared/services/watchlist/user-watchlist.service';
import { FeatureFlag } from '@modules/feature-flags/feature-flag';
import { TilesTaskComponent } from '@modules/tiles/components/tiles-task/tiles-task.component';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { ShareEntityModalComponent } from '@shared/components/share-entity-modal/share-entity-modal.component';
import { SharedCommands } from '@shared/enumerations/shared-commands';
import { SharedContentFields } from '@shared/enumerations/shared-content-fields';
import {
    CommandBindings,
    CommandDisplay,
    CommandsService,
    COMMANDS_SERVICE,
    ExecuteCommandData,
    FEATURES_SERVICE,
} from '@shared/interfaces/plugins/public/plugin-services-public.interface';
import { CommandArgs, CommandDescriptor } from '@shared/services/commands/command-provider-base';
import { EntityCommandContext } from '@shared/services/commands/entity-command-context';
import { EntityCommandProviderBase, ExecuteEntityCommandData } from '@shared/services/commands/entity-command-provider-base';
import { LanguageService } from '@shared/services/language/language.service';
import { NavigationService } from '@shared/services/navigation/navigation.service';
import { stringFormat } from '@shared/utilities/StringFormat';
import { OptionsComponent } from '@src/app/components/options/options.component';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { IMobileApplicationEntity, MobileApplicationEntityFields } from 'RestClient/Client/Interface/IMobileApplicationEntity';
import { IUserEntity, UserEntityFields } from 'RestClient/Client/Interface/IUserEntity';
import { MobileApplicationEntity } from 'RestClient/Client/Model/Application/MobileApplicationEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { FullscreenService } from '@modules/shared/services/fullscreen/fullscreen.service';
import { take } from 'rxjs/operators';
import { SendMessageComponent } from '../components/send-message/send-message.component';
import { ShowMessageAction, ShowMessageComponent } from '../components/show-message/show-message.component';
import { MailboxEnvelop, MailboxService } from './mailbox.service';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class GeneralCommandProvider extends EntityCommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(SharedCommands.ShareEntity, {
                id: SharedCommands.ShareEntity,
                nameResourceId: 'STE_BUTTON_SHARE',
                icon: MeltedIcon.Share,
                requirements: this.fillCommandRequirements({
                    requiredPrivileges: SafeGuid.createSet([KnownPrivileges.displayEntityInSecurityDeskPrivilege]),
                    supportedEntityTypes: new Set<string>([
                        EntityTypes.Areas,
                        EntityTypes.CustomEntities,
                        EntityTypes.IntrusionAreas,
                        EntityTypes.TileLayouts,
                        EntityTypes.TilePlugins,
                        EntityTypes.Zones,
                    ]),
                }),
            });
            descriptors.set(SharedCommands.ShowOptions, {
                id: SharedCommands.ShowOptions,
                keyBinding: 'CTRL.O',
            });
            descriptors.set(SharedCommands.DisplayEntities, {
                id: SharedCommands.DisplayEntities,
            });
            descriptors.set(SharedCommands.ShowMessage, {
                id: SharedCommands.ShowMessage,
            });
            descriptors.set(SharedCommands.SendMessage, {
                id: SharedCommands.SendMessage,
                nameResourceId: 'STE_ACTION_SENDMESSAGE',
                icon: MeltedIcon.Message,
                groupIcon: MeltedIcon.Person,
                groupNameResourceId: 'STE_ENTITY_USER',
                requirements: this.fillCommandRequirements({
                    requiredPrivileges: SafeGuid.createSet([KnownPrivileges.sendMessagePrivilege]),
                    supportedContentTypes: SafeGuid.createSet([MapContentTypes.MobileUser]),
                    supportedEntityTypes: new Set<string>([EntityTypes.Users, EntityTypes.UserGroups]),
                }),
            });
            descriptors.set(SharedCommands.ToggleAddToWatchlist, {
                id: SharedCommands.ToggleAddToWatchlist,
                nameResourceId: 'STE_ACTION_ADDTOWATCHLIST',
                icon: MeltedIcon.Monitoring,
                requirements: this.fillCommandRequirements({
                    enabledFeatureFlags: new Set<FeatureFlag>([EventMonitoringFeatureFlags.General]),
                }),
            });
            this.internalCommandDescriptors = descriptors;
        }
        return this.internalCommandDescriptors;
    }

    // Always make sure these commands are added last as they are often generic to all entities
    public get priority(): number {
        return 99;
    }

    public get requiredEntityFields(): Set<string> {
        return new Set<string>([...super.requiredEntityFields, UserEntityFields.firstNameField, UserEntityFields.lastNameField, MobileApplicationEntityFields.loggedUserField]);
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        private modalService: GenModalService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        private navigationService: NavigationService,
        private mailboxService: MailboxService,
        protected advancedSettingsService: AdvancedSettingsService,
        private userWatchlistService: UserWatchlistService,
        private analyticsService: AnalyticsService,
        private eventsService: EventsService,
        private fullscreenService: FullscreenService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);

        // Prefetch privileges
        await this.prefetchPrivileges(commandContext, commandArgs);

        if (await this.isShareEntityAvailable(commandContext, commandArgs)) {
            commandIds.push(SharedCommands.ShareEntity);
        }
        if (await this.isSendMessageAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(SharedCommands.SendMessage);
        }
        if (await this.isToggleAddToWatchlistAvailable(commandContext, commandArgs)) {
            commandIds.push(SharedCommands.ToggleAddToWatchlist);
        }
        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: SharedCommands.ShareEntity,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeShareEntity(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isShareEntityAvailable(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: SharedCommands.ShowOptions,
            executeCommandHandler: (executeCommandData) => this.executeOpenOptions(executeCommandData),
        });
        bindings.addCommand({
            commandId: SharedCommands.DisplayEntities,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteCommand((safeExecuteCommandData) => this.executeDisplayEntities(safeExecuteCommandData), executeCommandData),
        });
        bindings.addCommand({
            commandId: SharedCommands.ShowMessage,
            executeCommandHandler: (executeCommandData) => this.safeExecuteCommand((safeExecuteCommandData) => this.executeShowMessage(safeExecuteCommandData), executeCommandData),
        });
        bindings.addCommand({
            commandId: SharedCommands.SendMessage,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeSendMessage(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isSendMessageAvailableAsync(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: SharedCommands.ToggleAddToWatchlist,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeToggleAddToWatchlist(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isToggleAddToWatchlistAvailable(entityCommandContext), commandContext),
            getCommandDisplayHandler: (commandContext) =>
                this.safeGetEntityCommandDisplayAsync((entityCommandContext) => this.getToggleAddToWatchlistDisplay(entityCommandContext), commandContext),
        });
    }

    private executeDisplayEntities(executeCommandData: ExecuteCommandData): void {
        const args = this.extractArgs(executeCommandData.commandContext);
        if (args.specificData) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const entities = args.specificData.entities as IGuid[] | undefined;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const timestamp = args.specificData.timestamp as Date | undefined;

            if (entities && SafeGuid.isGuid(entities[0])) {
                let params = '';
                if (timestamp) {
                    params = `&${SharedContentFields.Timestamp}=${timestamp.toString()}`;
                }

                if (entities.length === 1) {
                    const id = entities[0];
                    if (id) {
                        const path = `/sidepane?id=${id.toString()}${params}`;
                        this.navigationService.navigate(path).fireAndForget();
                    }
                } else {
                    entities.forEach((id) => {
                        if (id) {
                            const path = `/task/${TilesTaskComponent.pluginId.toString()}?id=${id.toString()}${params}`;
                            this.navigationService.navigate(path).fireAndForget();
                        }
                    });
                }
            }
        }
        executeCommandData.isHandled = true;
    }

    private executeOpenOptions(executeCommandData: ExecuteCommandData): void {
        const args = this.extractArgs(executeCommandData.commandContext);
        const options = this.modalService.open(OptionsComponent);

        // apply the arguments if there is any
        if (args.specificData) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const id = args.specificData.section as string;
            if (id) {
                options.setCategory(SafeGuid.parse(id));
            }
        }
        executeCommandData.isHandled = true;
    }

    private executeSendMessage(executeCommandData: ExecuteEntityCommandData): void {
        this.sendMessageAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private executeShareEntity(executeCommandData: ExecuteEntityCommandData): void {
        this.executeShareEntityAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async executeShareEntityAsync(commandContext: EntityCommandContext): Promise<void> {
        const args = this.extractArgs(commandContext);
        let entityId = args.entityId;
        let timestamp: Date | undefined;

        if (args.specificData) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const specificEntityId = args.specificData.entityId as IGuid | undefined;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const specificTimestamp = args.specificData.timestamp as Date | undefined;

            if (isNonEmptyGuid(specificEntityId)) {
                entityId = specificEntityId;
            }
            if (specificTimestamp instanceof Date) {
                timestamp = specificTimestamp;
            }
        } else if (args.content) {
            if (args.content.parameters?.hasField(SharedContentFields.Timestamp)) {
                timestamp = args.content.parameters.getFieldDate(SharedContentFields.Timestamp);
            }

            if (args.content.type.equals(MapContentTypes.MobileUser)) {
                const mobileAppEntity = await this.getEntityAsync<MobileApplicationEntity, IMobileApplicationEntity>(
                    MobileApplicationEntity,
                    new Set<string>([MobileApplicationEntityFields.loggedUserField]),
                    args.entity,
                    args.entityId
                );
                if (mobileAppEntity && isNonEmptyGuid(mobileAppEntity.loggedUser)) {
                    // Override entityId with loggedUser for privilege checks
                    entityId = mobileAppEntity.loggedUser;
                }
            }
        }

        this.fullscreenService.displayModal(ShareEntityModalComponent, { entityId, timestamp });
    }

    private executeShowMessage(executeCommandData: ExecuteCommandData): void {
        const args = this.extractArgs(executeCommandData.commandContext);

        if (args.specificData) {
            /* eslint-disable @typescript-eslint/no-unsafe-member-access */
            const title = args.specificData.title as string | undefined;
            const message = args.specificData.message as string | undefined;
            const timeout = args.specificData.timeout as number | undefined;
            /* eslint-enable @typescript-eslint/no-unsafe-member-access */

            if (message) {
                const onClosed = (result: ShowMessageAction) => {
                    switch (result) {
                        case ShowMessageAction.Timeout:
                            {
                                const envelop = new MailboxEnvelop();
                                envelop.id = SafeGuid.newGuid();
                                envelop.icon = MeltedIcon.Message;
                                envelop.title = title;
                                envelop.summary = message;
                                envelop.isRead = false;
                                this.mailboxService.add(envelop);
                            }
                            break;
                    }
                };

                const actionText = this.translateService.instant('STE_BUTTON_CLOSE') as string;
                ShowMessageComponent.showMessage(this.modalService, title || '', message, onClosed, actionText, undefined, timeout);
            }
        }
        executeCommandData.isHandled = true;
    }

    private executeToggleAddToWatchlist(executeCommandData: ExecuteEntityCommandData): void {
        const args = this.extractArgs(executeCommandData.commandContext);

        if (args.entityId) {
            if (!this.userWatchlistService.isEntityBeingWatched(args.entityId)) {
                this.userWatchlistService.add([args.entityId]);
                this.analyticsService.logEvent(AnalyticsNames.Shared.EventMonitoring, {
                    action: 'Added new monitored entity',
                    description: 'New monitored entity has been added from a command',
                    context: EventMonitoringContext.WatchlistCommand,
                });
            } else {
                this.userWatchlistService.remove(args.entityId);
                this.analyticsService.logEvent(AnalyticsNames.Shared.EventMonitoring, {
                    action: 'Removed monitored entity',
                    description: 'Monitored entity has been removed from a command',
                    context: EventMonitoringContext.WatchlistCommand,
                });
            }
        }

        this.invalidateCommandDisplay(SharedCommands.ToggleAddToWatchlist, executeCommandData.commandContext);

        executeCommandData.isHandled = true;
    }

    private async isSendMessageAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        const args = commandArgs ?? this.extractArgs(commandContext);
        if (args.content?.type.equals(MapContentTypes.MobileUser)) {
            const mobileAppEntity = await this.getEntityAsync<MobileApplicationEntity, IMobileApplicationEntity>(
                MobileApplicationEntity,
                new Set<string>([MobileApplicationEntityFields.loggedUserField]),
                args.entity,
                args.entityId
            );
            if (mobileAppEntity && isNonEmptyGuid(mobileAppEntity.loggedUser)) {
                // Override entityId with loggedUser for privilege checks
                args.entityId = mobileAppEntity.loggedUser;
            }
        }
        return await this.areCommandRequirementsMetAsync(SharedCommands.SendMessage, commandContext);
    }

    private async isShareEntityAvailable(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        let commandArgsToUse: CommandArgs | undefined = commandArgs;
        if (!commandArgsToUse) {
            commandArgsToUse = this.extractArgs(commandContext);
        }

        return await this.areCommandRequirementsMetAsync(SharedCommands.ShareEntity, commandContext, commandArgsToUse);
    }

    private async isToggleAddToWatchlistAvailable(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        const args = commandArgs ?? this.extractArgs(commandContext);
        if (!args?.entityId) {
            return false;
        }

        const entity = await this.getEntityAsync<Entity, IEntity>(Entity, new Set<string>([EntityFields.entityTypeField]), undefined, args.entityId);
        if (!entity) {
            return false;
        }

        const supportedEntityTypes = await this.eventsService.supportedEntityTypes$.pipe(take(1)).toPromise();

        if (!Array.from(supportedEntityTypes.values()).find((entityType) => entity.entityType === entityType.type)) {
            return false;
        }

        return await this.areCommandRequirementsMetAsync(SharedCommands.ToggleAddToWatchlist, commandContext);
    }

    private async getToggleAddToWatchlistDisplay(commandContext: EntityCommandContext): Promise<CommandDisplay | undefined> {
        const isToggleWatchlistAllowed = await this.isToggleAddToWatchlistAvailable(commandContext);
        const args = this.extractArgs(commandContext);

        let displayName = 'STE_ACTION_ADDTOWATCHLIST';
        if (args.entityId && this.userWatchlistService.isEntityBeingWatched(args.entityId)) {
            displayName = 'STE_ACTION_REMOVEFROMWATCHLIST';
        }

        const translatedDisplayName = this.translateService?.instant(displayName) as string;
        return { icon: MeltedIcon.Monitoring, name: () => translatedDisplayName, tooltip: () => translatedDisplayName, isAllowed: () => isToggleWatchlistAllowed };
    }

    private async sendMessageAsync(commandContext: EntityCommandContext): Promise<void> {
        const args = this.extractArgs(commandContext);
        let recipient: IGuid | undefined;
        let message = '';
        let timeout: number | undefined;
        if (args.content?.type.equals(MapContentTypes.MobileUser)) {
            const mobileAppEntity = await this.getEntityAsync<MobileApplicationEntity, IMobileApplicationEntity>(
                MobileApplicationEntity,
                new Set<string>([MobileApplicationEntityFields.loggedUserField]),
                args.entity,
                args.entityId
            );
            if (mobileAppEntity && isNonEmptyGuid(mobileAppEntity.loggedUser)) {
                // Override entityId with loggedUser for privilege checks
                recipient = mobileAppEntity.loggedUser;
            }
        } else if (args.entityId) {
            recipient = args.entityId;
        } else if (args.specificData) {
            /* eslint-disable @typescript-eslint/no-unsafe-member-access */
            const specificRecipient = args.specificData.recipient as IGuid | undefined;
            const specificMessage = args.specificData.message as string | undefined;
            const specificTimeout = args.specificData.timeout as number | undefined;
            /* eslint-enable @typescript-eslint/no-unsafe-member-access */

            if (isNonEmptyGuid(specificRecipient)) {
                recipient = specificRecipient;
            }

            if (isString(specificMessage)) {
                message = specificMessage;
            }

            if (isNumber(specificTimeout)) {
                timeout = specificTimeout;
            }
        }

        if (isNonEmptyGuid(recipient)) {
            const user = await this.getEntityAsync<UserEntity, IUserEntity>(UserEntity, undefined, undefined, recipient, commandContext.entityCache);
            if (user) {
                // if no message is specified, ask the user
                if (!message) {
                    const onClosed = async (result: boolean, msg: string) => {
                        if (result && msg) {
                            await user.sendMessageAsync(msg, timeout as any);
                        }
                    };

                    let fullname = user.name;
                    if (user.firstName && user.lastName) {
                        fullname = user.firstName + ' ' + user.lastName;
                    }

                    const title = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_SEND_MESSAGE_TO_USER_X') as string, fullname);
                    const cancelText = this.translateService.instant('STE_BUTTON_CANCEL') as string;
                    SendMessageComponent.show(this.modalService, title, message, onClosed, undefined, cancelText);
                } else {
                    await user.sendMessageAsync(message, timeout as any);
                }
            }
        }
    }
}
