// Autogenerated: v2/help/Entities/ApiDoorAccessPoint?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { AccessPointEntity } from './AccessPointEntity';
import { IGuid } from 'safeguid';
import { IDoorAccessPointEntity, DoorAccessPointEntityFields } from '../../Interface/IDoorAccessPointEntity';

export class DoorAccessPointEntity extends AccessPointEntity implements IDoorAccessPointEntity {
    // #region REST Properties

    // Device
    public get device(): IGuid {
        return this.getFieldGuid(DoorAccessPointEntityFields.deviceField);
    }
    public set device(value: IGuid) {
        this.setFieldGuid(DoorAccessPointEntityFields.deviceField, value);
    }

    // Door
    public get door(): IGuid {
        return this.getFieldGuid(DoorAccessPointEntityFields.doorField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

