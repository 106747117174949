import { Injectable } from '@angular/core';
import { State, StateContext } from '@ngxs/store';
import { Selector, Receiver } from '@src/app/store';
import { EmitterAction } from '@ngxs-labs/emitter';
import { Settings, SettingsMap } from './settings';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface UserSettingsStateModel {
    settings: SettingsMap;
    refreshOnApply: boolean;
}

@State<UserSettingsStateModel>({
    name: 'userSettingsState',
    defaults: {
        settings: new Map<string, Settings>(),
        refreshOnApply: false,
    },
})
@Injectable()
export class UserSettingsState {
    @Selector()
    public static settings(state: UserSettingsStateModel): SettingsMap {
        return state.settings;
    }

    @Selector()
    public static refreshOnApply(state: UserSettingsStateModel): boolean {
        return state.refreshOnApply;
    }

    @Receiver()
    public static setSettings({ setState }: StateContext<UserSettingsStateModel>, { payload }: EmitterAction<SettingsMap>): void {
        setState((state: UserSettingsStateModel) => {
            state.settings = new Map(payload);
            return state;
        });
    }

    @Receiver()
    public static setRefreshOnApply({ setState }: StateContext<UserSettingsStateModel>, { payload }: EmitterAction<boolean>): void {
        setState((state: UserSettingsStateModel) => {
            state.refreshOnApply = payload;
            return state;
        });
    }
}
