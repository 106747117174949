export class EventHelper {
    private static awaitedEvents = new Map<Event, Array<(value: boolean) => void>>();

    public static async tryCaptureEventAsync(event: Event): Promise<boolean> {
        const promises = this.awaitedEvents.get(event);
        if (promises) {
            const promise = new Promise<boolean>((resolve) => {
                promises.push(resolve);
            });
            return promise;
        } else {
            this.awaitedEvents.set(event, []);
        }
        return true;
    }

    public static releaseEvent(event: Event): void {
        const promises = this.awaitedEvents.get(event);
        if (promises) {
            if (event.cancelBubble) {
                while (promises.length > 0) {
                    const resolve = promises.shift();
                    if (resolve) {
                        resolve(false);
                    }
                }
            } else {
                const resolve = promises.shift();
                if (resolve) {
                    resolve(!event.cancelBubble);
                }
            }

            if (promises.length === 0) {
                this.awaitedEvents.delete(event);
            }
        }
    }
}
