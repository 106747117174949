import { StreamUsage } from './Api/CreateStream';
import { Resolution } from '../../utils/Resolution';

/**
 * @beta
* */
export class DefaultStreamUsage {
  public readonly StreamUsage: StreamUsage;

  public readonly LowToLiveThreshold: Resolution | undefined;

  public readonly LiveToHighThreshold: Resolution | undefined;

  constructor(streamUsage: StreamUsage, lowToLiveThreshold?: Resolution, liveToHighThreshold?: Resolution) {
    if (streamUsage === StreamUsage.Automatic) {
      if (lowToLiveThreshold === undefined || liveToHighThreshold === undefined) {
        throw new Error('Automatic stream usage requires thresholds');
      }
      if (liveToHighThreshold.Width < lowToLiveThreshold.Width ||
        liveToHighThreshold.Height < lowToLiveThreshold.Height) {
        throw new Error(`LiveToHighThreshold (${liveToHighThreshold.toString()}) must be greater or equal to LowToLiveThreshold (${lowToLiveThreshold.toString()})`);
      }

      this.LowToLiveThreshold = lowToLiveThreshold;
      this.LiveToHighThreshold = liveToHighThreshold;
    }

    this.StreamUsage = streamUsage;
  }

  public toString(): string {
    return (this.StreamUsage !== StreamUsage.Automatic) ?
      this.StreamUsage :
      `Automatic: ${this.LowToLiveThreshold?.toString()} / ${this.LiveToHighThreshold?.toString()}`;
  }

  public getStreamUsage(resolution?: Resolution): StreamUsage {
    if (this.StreamUsage === StreamUsage.Automatic) {
      if (resolution === undefined || !resolution.isGreaterOrEqualThan(this.LowToLiveThreshold!)) {
        return StreamUsage.LowResolution;
      }
      if (!resolution.isGreaterOrEqualThan(this.LiveToHighThreshold!)) {
        return StreamUsage.Live;
      }
      return StreamUsage.HighResolution;
    }

    return this.StreamUsage;
  }

  public static build(serialized: any): DefaultStreamUsage {
    if (serialized === undefined || serialized === null) {
      throw new Error('Unserialization of DefaultStreamUsage failed');
    }

    const streamUsage: StreamUsage = serialized.StreamUsage;
    if (!Object.values(StreamUsage).includes(streamUsage)) {
      throw new Error(`Unknown stream usage ${streamUsage}`);
    }

    if (streamUsage === StreamUsage.Automatic) {
      const lowToLiveThreshold = this.deserializeResolution(serialized.LowToLiveThreshold);
      const liveToHighThreshold = this.deserializeResolution(serialized.LiveToHighThreshold);
      return new DefaultStreamUsage(streamUsage, lowToLiveThreshold, liveToHighThreshold);
    }

    return new DefaultStreamUsage(streamUsage);
  }

  private static deserializeResolution(serialized: any): Resolution {
    const width: number = serialized.Width;
    const height: number = serialized.Height;
    if (width === undefined || height === undefined) {
      throw new Error(`Unserialization of Resolution failed ${serialized}`);
    }

    return Resolution.build(width, height);
  }
}
