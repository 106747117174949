import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, ViewContainerRef } from '@angular/core';
import { Icon, ButtonFlavor } from '@genetec/gelato';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { HideableComponent } from '@modules/shared/components/hideable/hideable.component';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, PluginComponentExposure } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { CommandBindings, CommandsService, CommandsSubscription, COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { ContentOverlayService } from '@modules/shared/services/content-overlay/content-overlay.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { VideoContentTypes } from '@modules/video/enumerations/video-content-types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { map, tap } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { VideoPlayerComponent } from '../video-player/video-player.component';

export const isContentSupported = (content: Content): boolean => {
    if (content?.type.equals(VideoContentTypes.Video)) {
        return content.grantedPrivileges.some(
            (privilege) => privilege.equals(KnownPrivileges.allowVideoPrivilege) || privilege.equals(KnownPrivileges.allowPlaybackVideoPrivilege)
        );
    }
    return false;
};

@UntilDestroy()
@Component({
    selector: 'app-expand-widget',
    templateUrl: './expand-widget.component.html',
    styleUrls: ['./expand-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalContentPluginDescriptor({
    type: ExpandWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: {
        id: ExpandWidgetComponent.pluginId,
        priority: 6,
        requiredParentPlugin: VideoPlayerComponent.pluginId,
    } as PluginComponentExposure,
    isContentSupported,
    requirements: { features: [KnownFeatures.videoId], licenses: [KnownLicenses.video] },
})
export class ExpandWidgetComponent extends HideableComponent implements AfterViewInit, OnDestroy {
    public static pluginId = SafeGuid.parse('8F33D8D0-EEC2-4CFF-8D58-ABB1B7CE2CFD');

    @ViewSelectSnapshot(FeatureFlagsState.featureFlags(GeneralFeatureFlags.ExpandWidget))
    public isPortalEnabled!: boolean;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;

    public isExpanded$ = this.contentOverlayService.contentOverlay$.pipe(map((content) => content !== null));

    // To define the parent host of the Dom element
    private parentHost?: Element | null;

    private commandsSubscription?: CommandsSubscription;

    constructor(
        private hostElement: ElementRef<Element>,
        private contentOverlayService: ContentOverlayService,
        viewContainerRef: ViewContainerRef,
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService
    ) {
        super(viewContainerRef, trackingService);
        this.registerVideoCommands();
    }

    ngAfterViewInit(): void {
        this.parentHost = this.hostElement.nativeElement.closest('app-navigation-plugin-host');

        if (this.parentHost && this.contentOverlayService.hasOverlay()) {
            this.contentOverlayService.setOverlay(this.parentHost);
        }
    }

    ngOnDestroy(): void {
        this.commandsSubscription?.unsubscribe();
    }

    public toggleExpandWidget(): void {
        if (this.contentOverlayService.hasOverlay()) {
            this.contentOverlayService.clearOverlay();
            this.hideRequestSubject.next(false);
        } else {
            if (this.parentHost) {
                this.contentOverlayService.setOverlay(this.parentHost);
                this.hideRequestSubject.next(true);
            }
        }
    }

    private registerVideoCommands(): void {
        const bindings = new CommandBindings();
        bindings.addCommand({
            commandId: SharedCommands.MinimizeVideo,
            executeCommandHandler: (executeCommandData) => {
                this.contentOverlayService.clearOverlay();
                executeCommandData.isHandled = true;
            },
            isCommandAvailableHandler: () => this.isPortalEnabled,
        });
        bindings.addCommand({
            commandId: SharedCommands.ToggleMaximizeVideo,
            executeCommandHandler: (executeCommandData) => {
                this.toggleExpandWidget();
                executeCommandData.isHandled = true;
            },
            isCommandAvailableHandler: () => this.isPortalEnabled,
        });

        this.commandsSubscription = this.commandsService.subscribe(bindings, {
            priority: 200,
        });
    }
}
