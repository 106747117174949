import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[appMouseCapture]',
})
export class MouseCaptureDirective implements AfterViewInit, OnChanges {
    @Input('appMouseCapture') public activeId = -1;

    private pointerId = -1;

    constructor(private el: ElementRef<Element>) {}

    ngAfterViewInit() {
        this.ngOnChanges();
    }

    ngOnChanges() {
        if (this.activeId >= 0) {
            // release the previous capture just in case
            this.release();

            // capture the mouse
            this.pointerId = this.activeId;
            this.el.nativeElement.setPointerCapture(this.pointerId);
        } else if (this.pointerId >= 0) {
            this.el.nativeElement.releasePointerCapture(this.pointerId);
            this.pointerId = -1;
        }
    }

    public release(): void {
        if (this.pointerId >= 0) {
            this.el.nativeElement.releasePointerCapture(this.pointerId);
            this.pointerId = -1;
        }
    }
}
