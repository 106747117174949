// Autogenerated: v2/help/Entities/ApiAlarm?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:12:20 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { Entity } from './Entity';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ValidFlags } from '../Enumerations/ValidFlags';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { ITriggerInfo, IRecipient, IAlarmEntity, AlarmEntityFields } from '../Interface/IAlarmEntity';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ITransaction } from '../Interface/ITransaction';

// #region Inner classes

export class TriggerInfo extends FieldObject implements ITriggerInfo {
    // SourceEntity
    public get sourceEntity(): IGuid | null {
        return this.getNullableFieldGuid('SourceEntity');
    }
    public set sourceEntity(value: IGuid | null) {
        this.setNullableFieldGuid('SourceEntity', value);
    }
    // Latitude
    public get latitude(): number | null {
        return this.getNullableField<number>('Latitude');
    }
    public set latitude(value: number | null) {
        this.setNullableField<number>('Latitude', value);
    }
    // Longitude
    public get longitude(): number | null {
        return this.getNullableField<number>('Longitude');
    }
    public set longitude(value: number | null) {
        this.setNullableField<number>('Longitude', value);
    }
    // AttachedEntities
    public get attachedEntities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('AttachedEntities');
    }
    public set attachedEntities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('AttachedEntities', value);
    }
    // Context
    public get context(): string {
        return this.getField<string>('Context');
    }
    public set context(value: string) {
        this.setField<string>('Context', value);
    }
    // ForwardedRecipients
    public get forwardedRecipients(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('ForwardedRecipients');
    }
    public set forwardedRecipients(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('ForwardedRecipients', value);
    }
    // Priority
    public get priority(): number | null {
        return this.getNullableField<number>('Priority');
    }
    public set priority(value: number | null) {
        this.setNullableField<number>('Priority', value);
    }
    // Urls
    public get urls(): ObservableCollection<string> {
        return this.getFieldArray<string>('Urls');
    }
    public set urls(value: ObservableCollection<string>) {
        this.setFieldArray<string>('Urls', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('SourceEntity', SafeGuid.EMPTY);
        this.setField<number>('Latitude', 0);
        this.setField<number>('Longitude', 0);
        this.setFieldArrayGuid('AttachedEntities', new ObservableCollection<IGuid>());
        this.setField<string>('Context', '');
        this.setFieldArrayGuid('ForwardedRecipients', new ObservableCollection<IGuid>());
        this.setField<number>('Priority', 0);
        this.setFieldArray<string>('Urls', new ObservableCollection<string>());
    }
}

export class Recipient extends FieldObject implements IRecipient {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // PropagationLevel
    public get propagationLevel(): number {
        return this.getField<number>('PropagationLevel');
    }
    public set propagationLevel(value: number) {
        this.setField<number>('PropagationLevel', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<number>('PropagationLevel', 0);
    }
}

// #endregion Inner classes

export class AlarmEntity extends Entity implements IAlarmEntity {
    // #region REST Properties

    // AlarmRecordingTime
    public get alarmRecordingTime(): number {
        return this.getField<number>(AlarmEntityFields.alarmRecordingTimeField, ValidFlags.Specific);
    }
    public set alarmRecordingTime(value: number) {
        this.setField<number>(AlarmEntityFields.alarmRecordingTimeField, value);
    }

    // AlarmVideoMode
    public get alarmVideoMode(): string {
        return this.getField<string>(AlarmEntityFields.alarmVideoModeField, ValidFlags.Specific);
    }
    public set alarmVideoMode(value: string) {
        this.setField<string>(AlarmEntityFields.alarmVideoModeField, value);
    }

    // AudioFile
    public get audioFile(): IGuid {
        return this.getFieldGuid(AlarmEntityFields.audioFileField, ValidFlags.Specific);
    }
    public set audioFile(value: IGuid) {
        this.setFieldGuid(AlarmEntityFields.audioFileField, value);
    }

    // AutoAckAfter
    public get autoAckAfter(): number {
        return this.getField<number>(AlarmEntityFields.autoAckAfterField, ValidFlags.Specific);
    }
    public set autoAckAfter(value: number) {
        this.setField<number>(AlarmEntityFields.autoAckAfterField, value);
    }

    // CanBeInvestigated
    public get canBeInvestigated(): boolean {
        return this.getField<boolean>(AlarmEntityFields.canBeInvestigatedField, ValidFlags.Specific);
    }
    public set canBeInvestigated(value: boolean) {
        this.setField<boolean>(AlarmEntityFields.canBeInvestigatedField, value);
    }

    // Color
    public get color(): string {
        return this.getField<string>(AlarmEntityFields.colorField, ValidFlags.Specific);
    }
    public set color(value: string) {
        this.setField<string>(AlarmEntityFields.colorField, value);
    }

    // CreateIncident
    public get createIncident(): boolean {
        return this.getField<boolean>(AlarmEntityFields.createIncidentField, ValidFlags.Specific);
    }
    public set createIncident(value: boolean) {
        this.setField<boolean>(AlarmEntityFields.createIncidentField, value);
    }

    // DistributionMode
    public get distributionMode(): string {
        return this.getField<string>(AlarmEntityFields.distributionModeField, ValidFlags.Specific);
    }
    public set distributionMode(value: string) {
        this.setField<string>(AlarmEntityFields.distributionModeField, value);
    }

    // DwellTime
    public get dwellTime(): number {
        return this.getField<number>(AlarmEntityFields.dwellTimeField, ValidFlags.Specific);
    }
    public set dwellTime(value: number) {
        this.setField<number>(AlarmEntityFields.dwellTimeField, value);
    }

    // EnableProcedure
    public get enableProcedure(): boolean {
        return this.getField<boolean>(AlarmEntityFields.enableProcedureField, ValidFlags.Specific);
    }
    public set enableProcedure(value: boolean) {
        this.setField<boolean>(AlarmEntityFields.enableProcedureField, value);
    }

    // PlaybackPreTriggerTime
    public get playbackPreTriggerTime(): number {
        return this.getField<number>(AlarmEntityFields.playbackPreTriggerTimeField, ValidFlags.Specific);
    }
    public set playbackPreTriggerTime(value: number) {
        this.setField<number>(AlarmEntityFields.playbackPreTriggerTimeField, value);
    }

    // PreviewDefaultPosition
    public get previewDefaultPosition(): string {
        return this.getField<string>(AlarmEntityFields.previewDefaultPositionField, ValidFlags.Specific);
    }
    public set previewDefaultPosition(value: string) {
        this.setField<string>(AlarmEntityFields.previewDefaultPositionField, value);
    }

    // PreviewSourceVideoMode
    public get previewSourceVideoMode(): string {
        return this.getField<string>(AlarmEntityFields.previewSourceVideoModeField, ValidFlags.Specific);
    }
    public set previewSourceVideoMode(value: string) {
        this.setField<string>(AlarmEntityFields.previewSourceVideoModeField, value);
    }

    // Priority
    public get priority(): number {
        return this.getField<number>(AlarmEntityFields.priorityField, ValidFlags.Specific);
    }
    public set priority(value: number) {
        this.setField<number>(AlarmEntityFields.priorityField, value);
    }

    // Procedure
    public get procedure(): string {
        return this.getField<string>(AlarmEntityFields.procedureField, ValidFlags.Specific);
    }
    public set procedure(value: string) {
        this.setField<string>(AlarmEntityFields.procedureField, value);
    }

    // ProtectVideoPeriod
    public get protectVideoPeriod(): number {
        return this.getField<number>(AlarmEntityFields.protectVideoPeriodField, ValidFlags.Specific);
    }
    public set protectVideoPeriod(value: number) {
        this.setField<number>(AlarmEntityFields.protectVideoPeriodField, value);
    }

    // RearmThreshold
    public get rearmThreshold(): number {
        return this.getField<number>(AlarmEntityFields.rearmThresholdField, ValidFlags.Specific);
    }
    public set rearmThreshold(value: number) {
        this.setField<number>(AlarmEntityFields.rearmThresholdField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Alarms);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAttachedEntitiesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AttachedEntities', 'Id', false, query, ValidFlags.Specific, transaction);
    }

    public async forceAckAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('ForceAck', 'POST', null, transaction, responseHandler);
    }

    public async getRecipientsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRecipient> | null> {
        return this.getFieldObjectRelationAsync<Recipient, IRecipient>(Recipient, 'Recipients', 'Id', false, true, ValidFlags.Membership, transaction);
    }

    public async getSchedulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Schedules', 'Id', false, query, ValidFlags.Membership2, transaction);
    }

    public async triggerAsync(value: ITriggerInfo | null, transaction?: ITransaction): Promise<number | null> {
        const responseHandler = (response: IRestResponse): Promise<number> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body['Id'] as number;
                return new Promise<number>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<number>(function (resolve, reject) { reject(response); });
            }
        };
        if ( value == null ) {
            value = new TriggerInfo();
        }

        return this.executeRequestTransactionAsync<number>('Trigger', 'POST', value, transaction, responseHandler);
    }

    // #endregion REST Methods

}

