import { IGuid } from 'safeguid';
import { List } from 'immutable';
import { McProcedure } from '@modules/mission-control/models/procedure';
import { Comment, CommentApi } from '@modules/mission-control/models/comment';
import { toGuid } from '../utils/guid-utils';
import { IncidentLocation } from './incident-location';
import { IncidentEvent, RecipientsMode } from './events/incident-event';

export interface IncidentLocationApi {
    EntityId: IGuid;
    Latitude: number | null;
    Longitude: number | null;
}

export interface MCIncidentApi {
    Id: string;
    Name: string;
    TypeId: string;
    TypeRevision: number;
    Description?: string;
    DisplayId: number;
    ExternalId?: string;
    RecipientsMode: number;
    RecipientIds?: string[];
    StateId: string;
    PriorityId: string;
    OwnerId?: string;
    TriggerTimeUtc?: string;
    Location?: IncidentLocationApi;
    IconId: string;
    ParentId?: string;
    ArgbColor: string;
    SoundId: IGuid;
    CurrentStepId?: string;
    ProcedureId?: string;
    ProcedureRevision?: number;
    ActiveComments?: CommentApi[];
    LinkedIncidentIds?: IGuid[];
    LocationAreaIds?: IGuid[];
    SourceIds?: IGuid[];
    SubIncidentIds?: IGuid[];
}

export class MCIncident {
    constructor(
        public readonly id: IGuid,
        public readonly name: string,
        public readonly typeId: IGuid,
        public readonly typeRevision: number,
        public readonly description: string | null,
        public readonly displayId: number,
        public readonly externalId: string | null,
        public readonly recipientsMode: RecipientsMode,
        public readonly recipientIds: List<IGuid>,
        public readonly stateId: IGuid,
        public readonly priorityId: IGuid,
        public readonly ownerId: IGuid | null,
        public readonly triggerTimeUtc: Date | null,
        public readonly location: IncidentLocation | null,
        public readonly iconId: IGuid,
        public readonly parentId: IGuid | null,
        public readonly argbColor: string,
        public readonly soundId: IGuid,
        public readonly currentStepId: IGuid | null,
        public readonly procedureId: IGuid | null,
        public readonly procedureRevision: number | null,
        public readonly events: List<IncidentEvent>,
        public readonly procedure: McProcedure | null,
        public readonly activeComments: Comment[] | null,
        public readonly linkedIncidentIds: IGuid[] | null,
        public readonly locationAreaIds: IGuid[] | null,
        public readonly sourceIds: IGuid[] | null,
        public readonly subIncidentIds: IGuid[] | null
    ) {
        Object.freeze(this);
    }

    public static assign(incident: MCIncidentApi): MCIncident {
        try {
            return new MCIncident(
                toGuid(incident.Id),
                incident.Name,
                toGuid(incident.TypeId),
                incident.TypeRevision,
                incident.Description ?? null,
                incident.DisplayId,
                incident.ExternalId ?? null,
                incident.RecipientsMode,
                incident.RecipientIds ? List(incident.RecipientIds.map(toGuid)) : List(),
                toGuid(incident.StateId),
                toGuid(incident.PriorityId),
                incident.OwnerId ? toGuid(incident.OwnerId) : null,
                incident.TriggerTimeUtc ? new Date(incident.TriggerTimeUtc) : null,
                incident.Location
                    ? new IncidentLocation(incident.Location.EntityId ? toGuid(incident.Location.EntityId) : null, incident.Location.Latitude, incident.Location.Longitude)
                    : null,
                toGuid(incident.IconId),
                incident.ParentId ? toGuid(incident.ParentId) : null,
                incident.ArgbColor,
                toGuid(incident.SoundId),
                incident.CurrentStepId ? toGuid(incident.CurrentStepId) : null,
                incident.ProcedureId ? toGuid(incident.ProcedureId) : null,
                incident.ProcedureRevision ?? null,
                List(),
                null,
                incident.ActiveComments?.map((c) => Comment.assign(c)) ?? null,
                incident.LinkedIncidentIds ?? null,
                incident.LocationAreaIds ?? null,
                incident.SourceIds ?? null,
                incident.SubIncidentIds ?? null
            );
        } catch (e) {
            console.error(`Incident ${incident.Id} \n\t${e as string}`);
            console.error(e);
            throw e;
        }
    }

    public with({
        id,
        name,
        typeId,
        typeRevision,
        description,
        displayId,
        externalId,
        recipientsMode,
        recipientIds,
        stateId,
        priorityId,
        ownerId,
        triggerTimeUtc,
        location,
        iconId,
        parentId,
        argbColor,
        soundId,
        currentStepId,
        procedureId,
        procedureRevision,
        events,
        procedure,
        activeComments,
        linkedIncidentIds,
        locationAreaIds,
        sourceIds,
        subIncidentIds,
    }: {
        id?: IGuid;
        name?: string;
        typeId?: IGuid;
        typeRevision?: number;
        description?: string | null;
        displayId?: number;
        externalId?: string | null;
        recipientsMode?: RecipientsMode;
        recipientIds?: List<IGuid>;
        stateId?: IGuid;
        priorityId?: IGuid;
        ownerId?: IGuid | null;
        triggerTimeUtc?: Date | null;
        location?: IncidentLocation | null;
        iconId?: IGuid;
        parentId?: IGuid | null;
        argbColor?: string;
        soundId?: IGuid;
        currentStepId?: IGuid | null;
        procedureId?: IGuid | null;
        procedureRevision?: number | null;
        events?: List<IncidentEvent>;
        procedure?: McProcedure;
        activeComments?: Comment[];
        linkedIncidentIds?: IGuid[];
        locationAreaIds?: IGuid[];
        sourceIds?: IGuid[];
        subIncidentIds?: IGuid[];
    }): MCIncident {
        return new MCIncident(
            id ?? this.id,
            name ?? this.name,
            typeId ?? this.typeId,
            typeRevision ?? this.typeRevision,
            description === undefined ? this.description : description,
            displayId ?? this.displayId,
            externalId === undefined ? this.externalId : externalId,
            recipientsMode ?? this.recipientsMode,
            recipientIds ?? this.recipientIds,
            stateId ?? this.stateId,
            priorityId ?? this.priorityId,
            ownerId === undefined ? this.ownerId : ownerId,
            triggerTimeUtc === undefined ? this.triggerTimeUtc : triggerTimeUtc,
            location === undefined ? this.location : location,
            iconId ?? this.iconId,
            parentId === undefined ? this.parentId : parentId,
            argbColor ?? this.argbColor,
            soundId ?? this.soundId,
            currentStepId === undefined ? this.currentStepId : currentStepId,
            procedureId === undefined ? this.procedureId : procedureId,
            procedureRevision === undefined ? this.procedureRevision : procedureRevision,
            events ?? this.events,
            procedure ?? this.procedure,
            activeComments ?? this.activeComments,
            linkedIncidentIds ?? this.linkedIncidentIds,
            locationAreaIds ?? this.locationAreaIds,
            sourceIds ?? this.sourceIds,
            subIncidentIds ?? this.subIncidentIds
        );
    }
}
