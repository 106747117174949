// Autogenerated: v2/help/Entities/ApiHotlist?codegen=ts&InterfaceOnly=True&workflow=False on 2020-07-23 3:40:12 PM
import { IContentFileEntity, ContentFileEntityFields } from './IContentFileEntity';

// #region Fields

export class HotlistEntityFields extends ContentFileEntityFields {
    public static colorField = 'Color';
    public static downloadOnlyAtLogonField = 'DownloadOnlyAtLogon';
    public static emailNotificationAddressField = 'EmailNotificationAddress';
    public static expeditiveTransferField = 'ExpeditiveTransfer';
    public static hitPriorityField = 'HitPriority';
    public static isCovertField = 'IsCovert';
    public static overridePrivacyField = 'OverridePrivacy';
    public static soundFileField = 'SoundFile';
    public static useWildcardsField = 'UseWildcards';
}

// #endregion Fields

export interface IHotlistEntity extends IContentFileEntity {
    // #region REST Properties

    color: string;

    downloadOnlyAtLogon: boolean;

    emailNotificationAddress: string;

    expeditiveTransfer: boolean;

    hitPriority: number;

    isCovert: boolean;

    overridePrivacy: boolean;

    soundFile: string;

    useWildcards: boolean;

    // #endregion REST Properties

}

