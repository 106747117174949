import { Component } from '@angular/core';
import { FilterTextBaseComponent } from '../filter-text-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 * Filter restraining on simple text
 */
@Component({
    selector: 'app-filter-text',
    templateUrl: './filter-text.component.html',
    styleUrls: ['./filter-text.component.scss'],
})
export class FilterTextComponent extends FilterTextBaseComponent {}
