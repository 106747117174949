import { ILiteEvent, LiteEvent } from '../../utils/liteEvents';
import { ILogger } from '../../utils/logger';
import { VideoWatermarkingRenderer } from '../../utils/VideoWatermarkingRenderer';
import { Utils } from '../../utils/Utils';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { Resolution } from '../../utils/Resolution';
import { RingBuffer } from '../../utils/RingBuffer';
import { TimeSpan } from '../../utils/TimeSpan';
import { ZoomConfiguration } from '../ZoomConfiguration';
import { PromiseCompletionSourceVoid } from '../../utils/PromiseCompletionSource';
import { RenderingCanvas } from '../../utils/RenderingCanvas';
import { IRenderer } from '../IRenderer';
export interface IJpegRenderer extends IRenderer {
  timeUpdated: ILiteEvent<Date>;
  clear(): void;
  render(buffer: Int8Array, frameTime: Date): void;
}

export class JpegRenderer implements IJpegRenderer {
  private readonly m_timeUpdateEvent = new LiteEvent<Date>();
  private readonly m_logger: ILogger;
  private readonly m_frameDropStatistic: FrameDropStatistic;
  private readonly m_renderingCanvas: RenderingCanvas;
  private m_lastTimeUpdatedRaised: number;
  private m_frameRenderedPromise: PromiseCompletionSourceVoid;
  private m_lastResolution: Resolution;
  private m_frameTime: Date | undefined;
  private m_image?: HTMLImageElement;
  private m_videoWatermarkingRenderer?: VideoWatermarkingRenderer;
  private m_zoomConfiguration: ZoomConfiguration = ZoomConfiguration.NoZoom;
  private m_isCleared: boolean;
  private m_isStarted = true; // Indicates if the player has been started and is ready to render
  private m_isPlayerActive = false; // Indicates if the player is currently receiving segments, and rendering them

  public get timeUpdated() {
    // Allows the audioPlayer to synchronize with the jpegPlayer
    return this.m_timeUpdateEvent.expose();
  }

  public get streamResolution(): Resolution {
    return this.m_lastResolution;
  }

  public get Image(): HTMLImageElement | undefined {
    return this.m_image;
  }

  public get FrameRendered(): Promise<void> {
    return this.m_frameRenderedPromise.Promise;
  }

  public get tileResolution(): Resolution {
    if (this.m_renderingCanvas.canvasElement.scrollWidth === 0 || this.m_renderingCanvas.canvasElement.scrollHeight === 0) {
      return Resolution.None;
    }
    return Resolution.build(this.m_renderingCanvas.canvasElement.scrollWidth, this.m_renderingCanvas.canvasElement.scrollHeight);
  }

  constructor(logger: ILogger, renderingCanvas: RenderingCanvas) {
    this.m_logger = logger.subLogger('JpegRenderer');
    this.m_image = undefined;
    this.m_videoWatermarkingRenderer = undefined;
    this.m_lastTimeUpdatedRaised = 0;
    this.m_isCleared = true;
    this.m_lastResolution = Resolution.None;
    this.m_frameDropStatistic = new FrameDropStatistic();
    this.m_renderingCanvas = renderingCanvas;
    this.m_frameRenderedPromise = new PromiseCompletionSourceVoid();
    this.m_logger.debug?.trace('Created');
  }

  public dispose(): void {
    if (this.m_image !== undefined && this.m_image.src) {
      window.URL.revokeObjectURL(this.m_image.src);
    }
    this.m_image = undefined;

    this.clear();
    this.m_logger.debug?.trace('Disposed');
  }

  public start(): void {
    this.m_isStarted = true;
    this.m_logger.debug?.trace('Start');
  }

  public stop(): void {
    this.m_isStarted = false;
    this.m_frameDropStatistic.reset();
    this.m_logger.debug?.trace('Stop');
  }

  public clear(): void {
    if (!this.m_isCleared && this.m_isPlayerActive) {
      // Clear the picture from the canvas
      const context = this.m_renderingCanvas.canvasElement.getContext('2d');
      context?.clearRect(0, 0, this.m_renderingCanvas.canvasElement.width, this.m_renderingCanvas.canvasElement.height);
      this.m_isCleared = true;
      this.m_lastResolution = Resolution.None;
      this.m_frameDropStatistic.reset();
      this.m_logger.debug?.trace('Clear');
    }
  }

  public render(buffer: Int8Array, frameTime: Date): boolean {
    if (!this.m_isStarted) {
      this.m_logger.intense?.trace('Not rendering because not started');
      return false;
    }

    if (this.m_image === undefined) {
      this.m_image = this.createRenderer();
    } else if (!this.m_image.complete) { // IE reports false for broken or with no source images
      this.m_frameDropStatistic.frameDropped(frameTime);
      this.m_logger.intense?.trace('Dropping frame', Utils.formatDateUTC(frameTime), 'because the previous one was not rendered yet');
      return true;
    }

    const oldSrc = this.m_image.src;
    this.m_image.src = window.URL.createObjectURL(new Blob([buffer]));
    this.m_frameTime = frameTime;
    window.URL.revokeObjectURL(oldSrc);

    return true;
  }

  public getSnapshot(fromZoomedImage: boolean = false): ImageData | null {
    if (this.m_image === undefined) {
      this.m_logger.info?.trace('getSnapshot(): no image rendered yet');
      return null; //No image was rendered
    }

    if (fromZoomedImage && this.m_zoomConfiguration.IsZoomed) {
      return this.m_renderingCanvas.getSnapshot();
    } else {
      const snapshotCanvas = document.createElement('canvas');
      snapshotCanvas.width = this.m_lastResolution.Width;
      snapshotCanvas.height = this.m_lastResolution.Height;
      this.m_logger.debug?.trace(`getSnapshot(): Resolution ${this.m_lastResolution.toString()}`);

      const context = snapshotCanvas.getContext('2d');
      if (!context) {
        this.m_logger.warn?.trace('getSnapshot(): Failed to obtain the 2d context of the jpeg temporary snapshot canvas');
        throw new Error('Failed to obtain the 2d context of the jpeg temporary canvas');
      }
      context.drawImage(this.m_image, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

      //Apply videowatermark if any
      this.m_videoWatermarkingRenderer?.updateVideoWatermarkingOverlaySize(snapshotCanvas.width, snapshotCanvas.height);
      this.m_videoWatermarkingRenderer?.drawVideoWatermarkingOverlay(context, 0, 0);

      this.m_logger.info?.trace(`getSnapshot(): returning a ${snapshotCanvas.width}x${snapshotCanvas.height} image`);
      return context.getImageData(0, 0, snapshotCanvas.width, snapshotCanvas.height);
    }
  }

  public updateZoomConfig(zoomConfig: ZoomConfiguration): void {
    if (this.m_zoomConfiguration.equals(zoomConfig)) {
      return;
    }
    this.m_zoomConfiguration = zoomConfig;
    this.completeRender();
  }

  private createRenderer(): HTMLImageElement {
    const image: HTMLImageElement = document.createElement('img');
    image.addEventListener('load',
      (() => {
        this.completeRender();
        this.m_logger.intense?.trace(`Frame rendered: ${Utils.formatDate(this.m_frameTime)}`);

        if (this.m_frameTime !== undefined) {
          this.raiseTimeUpdated(this.m_frameTime);
        }
      }));
    image.addEventListener('error',
      (() => {
        this.m_logger.warn?.trace('error decoding a Jpeg');
      }));

    return image;
  }

  private raiseTimeUpdated(frameTime: Date) {
    const now = new Date().getTime();
    if (now - this.m_lastTimeUpdatedRaised > 500) {
      this.m_timeUpdateEvent.trigger(frameTime);
      this.m_lastTimeUpdatedRaised = now;
    }
  }

  private completeRender(): void {
    const canvas = this.m_renderingCanvas.canvasElement;
    const ctx = canvas.getContext('2d'); // context is used to draw images, shapes and text

    if (ctx !== null && ctx !== undefined && this.m_image !== undefined && this.m_image.width !== 0 && this.m_image.height !== 0) {
      const img = this.m_image;
      if (this.m_lastResolution.Width !== img.width || this.m_lastResolution.Height !== img.height) {
        this.m_lastResolution = Resolution.build(img.width, img.height);
      }

      if (this.m_isPlayerActive) {
        // Only draw the image on the canvas if the mjpeg renderer is active.
        this.m_renderingCanvas.draw(this.m_image, Resolution.build(img.width, img.height), this.m_zoomConfiguration);
        this.triggerFrameRendered();
      }
    }

    this.m_isCleared = false;
  }

  private triggerFrameRendered(): void {
    const toResolve = this.m_frameRenderedPromise;
    this.m_frameRenderedPromise = new PromiseCompletionSourceVoid();
    toResolve.resolve();
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig) {
    this.m_renderingCanvas.updateVideoWatermarkingConfig(videoWatermarkingConfig);
    // Only create the WatermarkRenderer if the config is enabled
    if (videoWatermarkingConfig !== VideoWatermarkConfig.Disabled) {
      this.m_logger.debug?.trace('Updating video watermark configuration');
      this.m_videoWatermarkingRenderer = new VideoWatermarkingRenderer(videoWatermarkingConfig, this.m_renderingCanvas.canvasElement.scrollWidth, this.m_renderingCanvas.canvasElement.scrollHeight);
    } else {
      this.m_logger.debug?.trace('Disabling video watermark');
      this.m_videoWatermarkingRenderer = undefined;
    }
  }

  public setPlayerActive(isPlayerActive: boolean): void {
    this.m_isPlayerActive = isPlayerActive;
  }

  public debugStatus(indent: number): string {
    const frameDroppedFrequency = this.m_frameDropStatistic.FrameDroppedInLastMinute;
    let frameDroppedMessage: string;
    if (frameDroppedFrequency[0] === 0) {
      frameDroppedMessage = 'No frame dropped';
    } else {
      frameDroppedMessage = `Dropped ${frameDroppedFrequency[0]} frame(s) over the last ${frameDroppedFrequency[1].toString()}`;
    }

    return 'JpegRenderer' + Utils.indentNewLine(indent) +
      'Started: ' + this.m_isStarted + Utils.indentNewLine(indent) +
      'Cleared: ' + this.m_isCleared + Utils.indentNewLine(indent) +
      'Frame resolution: ' + this.m_lastResolution.toString() + Utils.indentNewLine(indent) +
      `Displayed resolution: ${this.m_renderingCanvas.canvasElement.scrollWidth}x${this.m_renderingCanvas.canvasElement.scrollHeight}` + Utils.indentNewLine(indent) +
      'Last time update: ' + Utils.formatDate(this.m_frameTime) + Utils.indentNewLine(indent) +
      'VideoWatermarking: ' + (this.m_videoWatermarkingRenderer === undefined ? 'off' : 'on') + Utils.indentNewLine(indent) +
      frameDroppedMessage;
  }
}

//Amount of frame rendered and dropped in the last minute
export class FrameDropStatistic {
  private static readonly SampleCount = 64;

  private readonly m_frameDropped = new RingBuffer<Date>(FrameDropStatistic.SampleCount);

  public get FrameDroppedInLastMinute(): [number, TimeSpan] {
    return this.frequencyOverLastMinuteOrLess(this.m_frameDropped);
  }

  public reset(): void {
    this.m_frameDropped.reset();
  }

  public frameDropped(frameTime: Date) {
    this.m_frameDropped.add(frameTime);
  }

  //Returns the number of frame dropped over a certain timespan. Will try to compute it over the last minute but the span might be smaller
  public frequencyOverLastMinuteOrLess(events: RingBuffer<Date>): [number, TimeSpan] {
    const allEvents = events.getAll();
    if (allEvents.length === 0) {
      //No frame dropped
      return [0, TimeSpan.Zero];
    }

    const now = new Date();
    const oneMinuteAgo = Utils.subtractMinutes(now, 1);

    let eventInLastMinute = 0;
    for (let i = 0; i < allEvents.length; ++i) {
      if (allEvents[i] > oneMinuteAgo) {
        ++eventInLastMinute;
      }
    }

    if (eventInLastMinute < allEvents.length) {
      return [eventInLastMinute, new TimeSpan(60000)];
    }

    //Filled the ring buffer capacity of event in less than a minute:
    //Filling period is between now and the first event
    return [eventInLastMinute, TimeSpan.between(allEvents[0], now)];
  }
}
