import { Component, Input } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { IGuid } from 'safeguid';
import { ItemSlot } from '@genetec/gelato';
import { CustomIconState } from '@modules/shared/api/api';

export interface SidePaneHeaderInfo {
    title: string;
    titleColor: string;
    titleIcon: string;
    titleCustomIconId?: IGuid;
    titleCustomIconState: CustomIconState;
    description: string;
    timestamp?: string;
    entityId?: IGuid | string;
    contentCount?: number;
}

/**
 * Default header for sidepane with icon and title
 */
@Component({
    selector: 'app-sidepane-header',
    templateUrl: './sidepane-header.component.html',
    styleUrls: ['./sidepane-header.component.scss'],
})
export class SidePaneHeaderComponent extends TrackedComponent {
    @Input() public headerInfo?: SidePaneHeaderInfo;

    public readonly ItemSlot = ItemSlot;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }
}
