// Autogenerated: v2/help/Classes/ApiCustomPayloadEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-12 06:41:26
import { TimezoneEvent } from './TimezoneEvent';
import { ICustomPayloadEvent } from '../Interface/ICustomPayloadEvent';

export class CustomPayloadEvent extends TimezoneEvent implements ICustomPayloadEvent {
    // #region REST Properties

    // CustomEventId
    public get customEventId(): number {
        return this.getField<number>('CustomEventId');
    }
    public set customEventId(value: number) {
        this.setField<number>('CustomEventId', value);
    }

    // Message
    public get message(): string {
        return this.getField<string>('Message');
    }
    public set message(value: string) {
        this.setField<string>('Message', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('CustomEventId', 0);
        this.setField<string>('Message', '');
    }

    // #endregion Methods

}

