<!-- Loading -->
<div class="ha-center va-center spinner" *ngIf="isLoading$ | async; else entries">
    <gen-spinner></gen-spinner>
</div>

<!-- Entries -->
<ng-template #entries>
    <div class="general-information">
        <div *ngFor="let entry of generalInformation | sortBy: 'asc':'key'" class="general-information__entry">
            <gen-text [flavor]="TextFlavor.Label">{{ entry.key | titlecase }}</gen-text>
            <gen-text>{{ entry.value }}</gen-text>
        </div>
    </div>
</ng-template>
