import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ImageFit, ImageFlavor, TextFlavor } from '@genetec/gelato';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { CommandsService, COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { TimeService } from '@modules/shared/services/time/time.service';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { CorrelationContentTypes } from '@modules/correlation/enumerations/correlation-content-types';
import { CorrelationFeatures } from '@modules/correlation/enumerations/correlation.features';
import { DatumVisualDirective } from '@modules/correlation/interfaces/correlation-datum';
import { CorrelationDatumSidepaneWidgetComponent } from '../datum-sidepane-widget/correlation-datum-sidepane-widget.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-mobile-details-sidepane-widget',
    templateUrl: './mobile-details-sidepane-widget.component.html',
    styleUrls: ['./mobile-details-sidepane-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: MobileUserDetailsSidepaneWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: MobileUserDetailsSidepaneWidgetComponent.pluginId, priority: 2 },
    isContentSupported: (content: Content) => {
        if (content) {
            if (content.type && content.parameters) {
                if (
                    CorrelationContentTypes.datum.equals(content.type) &&
                    content.parameters?.hasField(CorrelationContentTypes.visualModel) &&
                    content.parameters?.hasField(CorrelationContentTypes.dataType)
                ) {
                    const dataTypeString = content.parameters.getField<string>(CorrelationContentTypes.dataType);
                    if (dataTypeString) {
                        if (CorrelationContentTypes.mobileUser.equals(dataTypeString)) return true;
                    }
                }
            }
        }
        return false;
    },
    requirements: {
        licenses: [KnownLicenses.correlation],
        features: [CorrelationFeatures.mobileUserDatumFeatureId],
    },
})
export class MobileUserDetailsSidepaneWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent, AfterViewInit {
    //#region Fields

    public static pluginId = SafeGuid.parse('39ECB286-35CB-4777-BAF6-8B384AA31D72');

    public readonly ImageFlavor = ImageFlavor;
    public readonly ImageFit = ImageFit;
    public readonly TextFlavor = TextFlavor;

    public dataContext: unknown;
    public content: Content | undefined;

    public isLoading = true;
    public application = '';
    public user = '';
    public lastConnectionTime = '';
    public lastKnownAddress = '';
    public email = '';
    public emailLink = '';
    public picture = '';

    private directive!: DatumVisualDirective;

    //#endregion

    //#region Constructor

    constructor(
        trackingService: TrackingService,
        private translateService: TranslateService,
        @Inject(COMMANDS_SERVICE) public commandsService: CommandsService,
        private timeService: TimeService
    ) {
        super(trackingService);
    }

    //#endregion

    //#region Methods

    public ngAfterViewInit(): void {
        const parameters = this.content?.parameters;
        if (parameters?.hasField(CorrelationContentTypes.visualModel)) {
            const data = parameters.getField<string>(CorrelationContentTypes.visualModel);
            if (data) {
                const directive = JSON.parse(data) as DatumVisualDirective;
                if (directive) {
                    if (directive.Datum) {
                        this.directive = directive;

                        // app
                        const entity = this.directive.Datum.AdditionalParameters.EntityName;
                        if (entity) {
                            this.application = entity;
                        } else {
                            this.application = this.translateService.instant('STE_LABEL_APPLICATION_UNKNOWN') as string;
                        }

                        // user
                        const user = this.directive.Datum.AdditionalParameters.UserName;
                        if (user) {
                            this.user = user;
                        } else {
                            this.user = this.translateService.instant('STE_LABEL_USER_UNKNOWN') as string;
                        }

                        // last seen
                        if (this.directive.Datum.TimestampUtc) {
                            const local = moment.utc(this.directive.Datum.TimestampUtc).local();
                            this.lastConnectionTime = this.timeService.formatRelative(local.toDate());
                        } else {
                            this.lastConnectionTime = this.translateService.instant('STE_LABEL_LASTCONNECTIONTIME_UNKNOWN') as string;
                        }

                        // email address
                        this.email = '';
                        const email = this.directive.Datum.AdditionalParameters.Email;
                        if (email) {
                            this.email = email;
                            this.emailLink = 'mailto:' + this.email;
                        }

                        // last known address (get from formatted datum)
                        this.lastKnownAddress = '';
                        const addresses = this.directive.Fields?.filter((item) => item.FieldType === 'Geocode');
                        if (addresses?.length > 0) {
                            this.lastKnownAddress = addresses[0].Value[0];
                        }

                        // picture (get from formatted datum)
                        const picture = this.directive.Fields?.filter((item) => item.FieldType === 'Image');
                        if (picture?.length > 0) {
                            this.picture = 'data:image/png;base64,' + picture[0].Value[0];
                        }
                    }
                    this.isLoading = false;
                }
            }
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    //#endregion

    //#region Events

    public copyToClipboard(text: string): void {
        if (text) {
            CorrelationDatumSidepaneWidgetComponent.copyMessage(text);
        }
    }

    public goToUser(username: string): void {
        if (username) {
            // TODO:
        }
    }

    //#endregion
}
