<div *ngIf="formGroup" [formGroup]="formGroup">
    <gen-text [flavor]="Flavor.Header3"> {{ 'STE_TITLE_CARDHOLDER_ACCESS_RIGHTS' | translate }}</gen-text>
    <app-cardholder-relations-sub-form
        [headerTitle]="'STE_LABEL_CARDHOLDER_ACCESS_GROUPS' | translate"
        [modalTitle]="'STE_LABEL_CARDHOLDER_ADD_ACCESS_GROUPS' | translate"
        [emptyMessage]="'STE_LABEL_NOGROUPS' | translate"
        [entityType]="CardholderRelationType.CardholderGroups"
        [excludedIds]="excludedCardholdersGroupIds"
        [refresherType]="SharedEntityBrowserRefreshTypes.FlatViewNoFederatedEntities"
        [hasRequiredPrivileges]="!!(hasModifyCardholderGroupsPrivilege$ | async)"
        [extraColumnsDescriptors]="[{ columnKey: 'description', columnName: ('STE_LABEL_DESCRIPTION' | translate), template: descriptionTemplate }]"
        [formReset$]="formReset$"
        formControlName="groups"
        [readonly]="readonly"
    >
    </app-cardholder-relations-sub-form>
    <app-cardholder-relations-sub-form
        [headerTitle]="'STE_LABEL_CARDHOLDER_CREDENTIALS' | translate"
        [modalTitle]="'STE_LABEL_CARDHOLDER_ADD_CREDENTIALS' | translate"
        [emptyMessage]="'STE_LABEL_NOCREDENTIALS' | translate"
        [entityType]="CardholderRelationType.Credentials"
        [refresherType]="EntityBrowserRefreshTypes.UnassignedCredentialView"
        [hasRequiredPrivileges]="!!(hasModifyCredentialPrivilege$ | async)"
        [extraColumnsDescriptors]="[{ columnKey: 'status', columnName: ('STE_LABEL_STATUS' | translate), template: statusTemplate }]"
        [formReset$]="formReset$"
        formControlName="credentials"
        [readonly]="readonly"
    >
    </app-cardholder-relations-sub-form>
    <app-cardholder-relations-sub-form
        [headerTitle]="'STE_LABEL_CARDHOLDER_ACCESS_RULES' | translate"
        [modalTitle]="'STE_LABEL_CARDHOLDER_ADD_RULES' | translate"
        [emptyMessage]="'STE_LABEL_NOACCESSRULES' | translate"
        [entityType]="CardholderRelationType.AccessRules"
        [excludedIds]="excludedAccessRulesIds"
        [hasRequiredPrivileges]="!!(hasModifyAccessRulesPrivilege$ | async)"
        [extraColumnsDescriptors]="[{ columnKey: 'description', columnName: ('STE_LABEL_DESCRIPTION' | translate), template: descriptionTemplate }]"
        [formReset$]="formReset$"
        formControlName="rules"
        [readonly]="readonly"
    >
    </app-cardholder-relations-sub-form>
</div>

<ng-template #descriptionTemplate let-entityData="$implicit">
    <div class="text-row">
        <gen-text class="overflow-ellipsis" appTooltip="{{ entityData.description }}" [appTooltipOnlyIfOverflow]="true">{{ entityData.description }}</gen-text>
    </div>
</ng-template>
<ng-template #statusTemplate let-entityData="$implicit">
    <div class="text-row">
        <gen-text class="overflow-ellipsis" appTooltip="{{ entityData.accessStatus }}" [appTooltipOnlyIfOverflow]="true" [flavor]="entityData.accessStatus | accessStatusToColor">
            {{ entityData.accessStatus }}
        </gen-text>
    </div>
</ng-template>
