import { Component, Injector } from '@angular/core';
import { TextFlavor, ListFlavor, ButtonFlavor } from '@genetec/gelato';
import { ThreatLevelInfo } from '@modules/shared/api/api';
import { ThreatLevelService } from '@modules/shared/services/threat-level/threat-level.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { Observable } from 'rxjs';
import { SelectionType } from '@genetec/gelato';
import { Icon } from '@genetec/gelato';
import { GenModalService } from '@genetec/gelato-angular';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { AnalyticsNames } from '@modules/shared/enumerations/analytics-names';
import { ThreatLevelsContext } from '@modules/shared/enumerations/analytics-threat-levels-context';
import { ConnectionAwareModalComponent } from '../tracked/connection-aware-modal.component';
import { ThreatLevelConfirmationModalComponent } from './threat-level-confirmation-modal/threat-level-confirmation-modal.component';

@UntilDestroy()
@Component({
    selector: 'app-threat-level-modal',
    templateUrl: './threat-level-modal.component.html',
    styleUrls: ['./threat-level-modal.component.scss'],
})
export class ThreatLevelModalComponent extends ConnectionAwareModalComponent {
    public readonly ListSelectionType = SelectionType;
    public readonly TextFlavor = TextFlavor;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly ListFlavor = ListFlavor;
    public readonly Icon = Icon;

    private selected: ThreatLevelInfo | null = null;
    private activeThreatLevel: ThreatLevelInfo | null = null;

    constructor(
        protected authService: AuthService,
        injector: Injector,
        trackingService: TrackingService,
        private modalService: GenModalService,
        private threatLevelService: ThreatLevelService,
        private analyticsService: AnalyticsService
    ) {
        super(authService, injector, trackingService);

        this.threatLevelService.activeThreatLevel$.pipe(untilDestroyed(this)).subscribe((activeThreatLevel) => {
            this.activeThreatLevel = activeThreatLevel;
            this.updateSelectedItem(activeThreatLevel);
        });
    }

    get threatLevels$(): Observable<ThreatLevelInfo[]> {
        return this.threatLevelService.threatLevels$;
    }

    public openThreatLevelConfirmationModal = async (): Promise<boolean> => {
        this.modalService.open(ThreatLevelConfirmationModalComponent, {
            selected: this.selected,
        });
        this.analyticsService.logEvent(AnalyticsNames.Shared.ThreatLevels, {
            context: ThreatLevelsContext.ApplyThreatLevel,
            message: this.selected ? `Threat Level with name '${this.selected.name}' was selected` : `'None' Threat Level was selected`,
        });
        return Promise.resolve(true);
    };

    public get isSelectedTheActiveThreatLevel(): boolean {
        return this.isThreatLevelAlreadyActive(this.selected);
    }

    public async resetThreatLevel(): Promise<void> {
        this.selected = null;
        this.hideModal();
        await this.openThreatLevelConfirmationModal();
    }

    public isThreatLevelAlreadyActive(threatLevel: ThreatLevelInfo | null): boolean {
        return this.activeThreatLevel?.id === threatLevel?.id;
    }

    public updateSelectedItem(activeThreatLevel: ThreatLevelInfo | null): void {
        this.selected = activeThreatLevel;
    }
}
