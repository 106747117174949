import { AfterViewInit, Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsidePosition, GenAlertComponent, GenAlertService, MeltedModalAction, Orientation } from '@genetec/gelato-angular';
import { TextFlavor } from '@genetec/gelato';
import { GenTabItem } from '@genetec/gelato-angular/lib/components/gen-tabs/interfaces/gen-tab-item';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash-es';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { GenAlerts } from '@modules/shared/enumerations/gen-alerts';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { UserSettingsService } from '@modules/shared/services/user-settings/user-settings.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { IGuid, SafeGuid } from 'safeguid';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface CategoryDescriptor {
    title: string;
    description?: string;
}
@UntilDestroy()
@Component({
    selector: 'app-options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.scss'],
})
export class OptionsComponent extends ConnectionAwareModalComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild(GenAlertComponent) public genAlertComponent!: GenAlertComponent;

    public readonly AsidePosition = AsidePosition;
    public readonly Orientation = Orientation;
    public readonly TextFlavor = TextFlavor;

    public categories!: GenTabItem[];
    public allOptionPlugins: PluginItem[] = [];
    public currentOptionPlugins: PluginItem[] = [];
    public currentCategory?: GenTabItem;
    public currentCategoryDescriptor?: CategoryDescriptor;
    public optionsModal = 'optionsModal';
    public optionsConfirmRefreshAlertId = GenAlerts.OptionsConfirmRefreshAlert;

    private subSections: Map<string, CategoryDescriptor> = new Map<string, CategoryDescriptor>();
    private isClosing = false;
    private desiredCategory: IGuid | undefined;

    constructor(
        injector: Injector,
        private pluginService: PluginService,
        private activatedRoute: ActivatedRoute,
        private genAlertService: GenAlertService,
        private translateService: TranslateService,
        authService: AuthService,
        @Inject(USER_SETTINGS_SERVICE) private userSettingsService: UserSettingsService,
        trackingService: TrackingService
    ) {
        super(authService, injector, trackingService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.setCategoryDescriptors();
        this.onAppear().fireAndForget();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                this.genModalComponent.show();
                if (this.genAlertComponent) {
                    this.genAlertService.add(this.genAlertComponent);
                }
            }
        });
    }

    ngOnDestroy() {
        this.genAlertService.remove(this.genAlertComponent.id);
        super.ngOnDestroy();
    }

    public override hideModal(): void {
        this.onClosed();
    }

    public onModalDefaultAction = async (): Promise<boolean> => {
        const changesSaved = await this.onApplyChanges();
        return Promise.resolve(changesSaved);
    };

    public onModalAlternativeAction = (): Promise<boolean> => {
        this.onCancelChanges();
        return Promise.resolve(true);
    };

    public setCategory(id: IGuid): void {
        this.desiredCategory = id;
    }

    public onCategoryChanged(item: GenTabItem): void {
        const category: IGuid = SafeGuid.parse(item.id.toString());
        if (category) {
            this.showPlugins(category);
            this.currentCategoryDescriptor = this.subSections.get(category.toString().toLowerCase());
        }
    }

    public async onOptionsAction(action: MeltedModalAction): Promise<void> {
        if (action === MeltedModalAction.Default) {
            await this.onApplyChanges();
        } else {
            this.onCancelChanges();
        }
    }

    public nextTab(event: KeyboardEvent): void {
        if (!event.ctrlKey) return;
        const nextIndex = this.currentCategory ? this.categories.indexOf(this.currentCategory) + 1 : 0;
        if (nextIndex === this.categories.length) return;
        this.currentCategory = this.categories[nextIndex];
        this.onCategoryChanged(this.currentCategory);
    }

    public previousTab(event: KeyboardEvent): void {
        if (!event.ctrlKey) return;
        const prevIndex = this.currentCategory ? this.categories.indexOf(this.currentCategory) - 1 : this.categories.length - 1;
        if (prevIndex === -1) return;
        this.currentCategory = this.categories[prevIndex];
        this.onCategoryChanged(this.currentCategory);
    }

    private onCancelChanges(): void {
        this.genModalComponent.hide();
        this.userSettingsService.cancel();
    }

    private showPlugins(category: IGuid) {
        this.currentOptionPlugins = Array.from(this.allOptionPlugins).filter((item) => (item.exposure.subSection ? item.exposure.subSection.equals(category) : false));
    }

    private setCategoryDescriptors() {
        //Subsections determines the fixed order in which the options appears
        this.subSections.set(OptionSubSections.General.toString().toLowerCase(), {
            title: this.translateService.instant('STE_TITLE_OPTIONS_GENERAL') as string,
        });

        this.subSections.set(OptionSubSections.Video.toString().toLowerCase(), {
            title: this.translateService.instant('STE_TITLE_OPTIONS_VIDEO') as string,
            description: this.translateService.instant('STE_MESSAGE_INFO_OPTIONS_VIDEO') as string,
        });

        this.subSections.set(OptionSubSections.Map.toString().toLowerCase(), {
            title: this.translateService.instant('STE_TITLE_OPTIONS_MAP') as string,
            description: this.translateService.instant('STE_MESSAGE_INFO_OPTIONS_MAP') as string,
        });

        this.subSections.set(OptionSubSections.Events.toString().toLowerCase(), {
            title: this.translateService.instant('STE_TITLE_OPTIONS_EVENTS') as string,
            description: this.translateService.instant('STE_MESSAGE_INFO_OPTIONS_EVENTS') as string,
        });

        this.subSections.set(OptionSubSections.Peripherals.toString().toLowerCase(), {
            title: this.translateService.instant('STE_TITLE_OPTIONS_PERIPHERALS') as string,
            description: this.translateService.instant('STE_MESSAGE_INFO_OPTIONS_PERIPHERALS') as string,
        });

        this.subSections.set(OptionSubSections.Advanced.toString().toLowerCase(), { title: this.translateService.instant('STE_LABEL_ADVANCED_OPTIONS') as string });
    }

    private async onAppear(): Promise<void> {
        this.categories = [];

        this.allOptionPlugins = await this.pluginService.getPlugins(PluginTypes.Option, null);

        const sections: IGuid[] = [];
        this.allOptionPlugins.forEach((plugin) => {
            if (plugin.exposure.subSection) {
                sections.push(plugin.exposure.subSection);
            }
        });

        const distinctSections = uniq(sections);
        this.subSections.forEach((category, subSectionID) => {
            if (distinctSections.find((x) => x.toString().toLowerCase() === subSectionID)) {
                this.categories.push({
                    id: subSectionID,
                    text: category.title ?? '',
                });
            }
        });

        await this.userSettingsService.loadSettings();

        // if there no desired category yet
        if (!this.desiredCategory) {
            // be sure to select this category
            this.desiredCategory = OptionSubSections.General;

            // determine the category to select from the parameters
            this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
                const section = params.section as string;
                if (section) {
                    const id = SafeGuid.parse(section);
                    if (id) {
                        this.desiredCategory = id;
                    }
                }
            });
        }

        if (this.desiredCategory) {
            this.currentCategory = this.categories.find((x) => x.id === this.desiredCategory?.toString().toLowerCase());
            this.showPlugins(this.desiredCategory);
        }

        if (this.currentCategory) {
            this.onCategoryChanged(this.currentCategory);
        }
    }

    private onClosed(): void {
        // prevent reentrance
        if (this.isClosing) {
            return;
        }

        this.isClosing = true;
        if (this.genModalComponent) {
            this.genModalComponent.hide();
            this.userSettingsService.cancel();
        }

        this.isClosing = false;
    }

    private async onApplyChanges(): Promise<boolean> {
        const obs = await this.userSettingsService.apply();
        const prom = new Promise<boolean>((resolve, reject) => {
            obs.pipe(untilDestroyed(this)).subscribe((success) => {
                if (success) {
                    this.genModalComponent.hide();
                    resolve(true);
                } else {
                    reject(false);
                }
            });
        });
        return prom
            .then((value: boolean) => {
                return value;
            })
            .catch((error: boolean) => {
                return error;
            });
    }
}
