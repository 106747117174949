import { SafeGuid, IGuid } from 'safeguid';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import {
    CommandContext,
    CommandDisplay,
    CommandRequirements,
    EntityCommandRequirements,
    ExecuteCommandData,
} from '../../interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from './commands.service';
import { EntityCommandContext, isEntityCommandContext } from './entity-command-context';
import { CommandArgs, CommandDescriptor, CommandProviderBase, arePrivilegesGrantedAsync } from './command-provider-base';
import { EntityCommandProvider } from './entity-command-provider';

export interface ExecuteEntityCommandData extends ExecuteCommandData {
    commandContext: EntityCommandContext;
}

export const areFieldsDownloaded = (fieldObject: IFieldObject, fields: Set<string>): boolean => {
    for (const field of fields) {
        if (!fieldObject.hasField(field)) {
            return false;
        }
    }
    return true;
};

export abstract class EntityCommandProviderBase extends CommandProviderBase implements EntityCommandProvider {
    public get requiredEntityFields(): Set<string> {
        return new Set<string>();
    }

    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        return SafeGuid.createMap<CommandDescriptor>();
    }

    protected async areCommandRequirementsMetAsync(commandId: IGuid, commandContext?: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        if (!(await super.areCommandRequirementsMetAsync(commandId, commandContext, commandArgs))) {
            return false;
        }

        const requirements = this.extractRequirements(commandId) as EntityCommandRequirements;
        if (!requirements) {
            return true;
        }

        let areContentRequirementsMet = false;
        let grantedPrivileges: Set<IGuid> | undefined;
        const args = commandArgs ?? this.extractArgs(commandContext);
        const content = args.content;
        if (content) {
            if (requirements.supportedContentTypes) {
                areContentRequirementsMet = this.areCommandContentRequirementsMet(requirements.supportedContentTypes, content);
                if (!areContentRequirementsMet) {
                    return false;
                }
            }
            grantedPrivileges = SafeGuid.createSet(content.grantedPrivileges);
        }

        let entity: IEntity | null = null;
        if (
            (requirements.supportedEntityTypes && !areContentRequirementsMet) ||
            (requirements.requiredPrivileges && (!grantedPrivileges || grantedPrivileges.size === 0)) ||
            requirements.isFederated !== undefined
        ) {
            entity = await this.getEntityAsync<Entity, IEntity>(Entity, undefined, args.entity, args.entityId, commandContext?.entityCache);
            if (!entity || (requirements.isFederated !== undefined && entity.isFederated !== requirements.isFederated)) {
                return false;
            }
        }

        if (requirements.supportedEntityTypes && !areContentRequirementsMet) {
            if (!entity || !setSome(requirements.supportedEntityTypes, (entityType) => entity?.entityType === entityType)) {
                return false;
            }
        }

        if (requirements.requiredPrivileges) {
            if (!(await this.arePrivilegesGrantedAsync(requirements.requiredPrivileges, grantedPrivileges, entity ? entity : undefined))) {
                return false;
            }
        }

        return true;
    }

    protected extractRequirements(commandId: IGuid): CommandRequirements {
        const requirements = super.extractRequirements(commandId) as EntityCommandRequirements;

        const descritor = this.commandDescriptors.get(commandId);
        const internalCommandsService = this.commandsService as InternalCommandsService;
        const additionnalRequirements = internalCommandsService.getAdditionnalRequirements(commandId) as EntityCommandRequirements[];
        if (descritor?.requirements) {
            additionnalRequirements?.push(descritor.requirements);
        }

        for (const req of additionnalRequirements) {
            if (req.supportedEntityTypes) {
                requirements.supportedEntityTypes = requirements.supportedEntityTypes ?? new Set<string>();
                for (const type of req.supportedEntityTypes) {
                    requirements.supportedEntityTypes.add(type);
                }
            }
        }

        return requirements;
    }

    protected async arePrivilegesGrantedAsync(
        requiredPrivileges: Set<IGuid>,
        grantedPrivileges?: Set<IGuid>,
        entity?: IEntity,
        entityId?: IGuid,
        entityCache?: IEntityCacheTask
    ): Promise<boolean> {
        if (this.isUserAdmin) {
            return true;
        }
        return await arePrivilegesGrantedAsync(this.scClient, requiredPrivileges, grantedPrivileges, entity, entityId, entityCache);
    }

    protected fillCommandRequirements(requirements: EntityCommandRequirements): EntityCommandRequirements {
        return super.fillCommandRequirements(requirements);
    }

    protected async extractEntityAsync<TEntity extends Entity & TEntityInterface, TEntityInterface extends IEntity>(
        constructor: new () => TEntity,
        commandContext: EntityCommandContext,
        commandArgs?: CommandArgs
    ): Promise<TEntityInterface | null> {
        let entityId: IGuid | undefined;

        const args = commandArgs ?? this.extractArgs(commandContext);

        if (!args.entity) {
            entityId = args.entityId;
        }

        return this.getEntityAsync<TEntity, TEntityInterface>(constructor, undefined, args.entity, args.entityId, commandContext.entityCache);
    }

    protected async getEntityAsync<TEntity extends Entity & TEntityInterface, TEntityInterface extends IEntity>(
        constructor: new () => TEntity,
        requiredFields?: Set<string>,
        entity?: IEntity,
        entityId?: IGuid,
        entityCache?: IEntityCacheTask
    ): Promise<TEntityInterface | null> {
        const fields = requiredFields ?? this.requiredEntityFields;

        if (entity && areFieldsDownloaded(entity, fields)) {
            return entity as TEntityInterface; //TODO: Fix the typing. Do not use "as".
        }

        let value: TEntityInterface | null = null;

        if (isNonEmptyGuid(entityId)) {
            if (entityCache) {
                value = await entityCache.getEntityAsync<TEntity, TEntityInterface>(constructor, entityId);
            } else {
                value = await this.scClient.getEntityAsync<TEntity, TEntityInterface>(constructor, entityId, null, null, this.fieldSetToString(fields));
            }
        }

        return value;
    }

    protected async prefetchPrivileges(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<void> {
        if (this.requiredPrivileges.size === 0) {
            return;
        }

        const args = commandArgs || this.extractArgs(commandContext);
        if (args.content) {
            return;
        }

        await this.arePrivilegesGrantedAsync(this.requiredPrivileges, undefined, args.entity, args.entityId, commandContext.entityCache);
    }

    protected safeCanExecuteEntityCommand(canExecuteCommandFunction: (commandContext: EntityCommandContext) => boolean, commandContext?: CommandContext): boolean {
        if (!isEntityCommandContext(commandContext)) {
            return false;
        }
        return canExecuteCommandFunction(commandContext);
    }

    protected async safeCanExecuteEntityCommandAsync(
        canExecuteCommandFunction: (commandContext: EntityCommandContext) => Promise<boolean>,
        commandContext?: CommandContext
    ): Promise<boolean> {
        // TODO FM - TBFixed
        // if (!isEntityCommandContext(commandContext)) {
        //     return false;
        // }
        return await canExecuteCommandFunction(commandContext as EntityCommandContext);
    }

    protected safeExecuteEntityCommand(executeCommandFunction: (executeCommandData: ExecuteEntityCommandData) => void, executeCommandData: ExecuteCommandData): void {
        // TODO FM - TBFixed
        // const commandContext = executeCommandData.commandContext;
        // if (!isEntityCommandContext(commandContext)) {
        //     executeCommandData.isHandled = false;
        //     return;
        // }
        executeCommandFunction(executeCommandData as ExecuteEntityCommandData);
        if (executeCommandData.isHandled) {
            executeCommandData.event?.stopPropagation();
            executeCommandData.event?.preventDefault();
        }
    }

    protected safeGetEntityCommandDisplay(
        getCommandDisplayFunction: (commandContext: EntityCommandContext) => CommandDisplay | undefined,
        commandContext?: CommandContext
    ): CommandDisplay | undefined {
        if (!isEntityCommandContext(commandContext)) {
            return;
        }
        return getCommandDisplayFunction(commandContext);
    }

    protected async safeGetEntityCommandDisplayAsync(
        getCommandDisplayFunction: (commandContext: EntityCommandContext) => Promise<CommandDisplay | undefined>,
        commandContext?: CommandContext
    ): Promise<CommandDisplay | undefined> {
        if (!isEntityCommandContext(commandContext)) {
            return;
        }
        return await getCommandDisplayFunction(commandContext);
    }

    protected safeIsEntityCommandAvailable(isCommandAvailableFunction: (commandContext: EntityCommandContext) => boolean, commandContext?: CommandContext): boolean {
        return this.safeCanExecuteEntityCommand(isCommandAvailableFunction, commandContext);
    }

    protected async safeIsEntityCommandAvailableAsync(
        isCommandAvailableFunction: (commandContext: EntityCommandContext) => Promise<boolean>,
        commandContext?: CommandContext
    ): Promise<boolean> {
        return await this.safeCanExecuteEntityCommandAsync(isCommandAvailableFunction, commandContext);
    }

    protected async updateEntityAsync(entity: IEntity, entityCache?: IEntityCacheTask): Promise<void> {
        if (entityCache) {
            await entityCache.updateAsync(entity);
        } else {
            await this.scClient.updateAsync(entity);
        }
    }

    /**
     * Helper method to properly process requested fields in an entity query.
     *
     * @param fields set of fields to parse
     * @returns an array to string of fields
     */
    private fieldSetToString(fields: Set<string>): string {
        if (!fields) {
            return '';
        }
        return [...fields.values()].toString();
    }

    public abstract getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]>;
}
