import { ShaderBuilder } from './ShaderBuilder';

export class VertexShader {
  private readonly m_gl: WebGL2RenderingContext;
  private readonly m_shader: WebGLShader;
  private readonly m_source: string;

  public get Source(): string {
    return this.m_source;
  }

  public get Shader(): WebGLShader {
    return this.m_shader;
  }

  constructor(gl: WebGL2RenderingContext, vertexShaderCode: string) {
    this.m_gl = gl;

    this.m_source =
      `${ShaderBuilder.m_version300es}
${vertexShaderCode}`;

    const shaderBuilder = new ShaderBuilder(gl);
    this.m_shader = shaderBuilder.buildVertexShader(this.m_source);
  }

  public dispose() {
    this.m_gl.deleteShader(this.m_shader);
  }
}
