import { CancellationToken } from './Helpers';
import { RelationTrackerBase } from './RelationTrackerBase';
import { ITransaction } from '../Client/Interface/ITransaction';
import { IRestResponse } from '../Client/Interface/IRestResponse';
import { IRestObject } from '../Client/Interface/IRestObject';

export class RelationObjectTracker<T> extends RelationTrackerBase {
    //#region Fields

    protected _data: T | null = null;
    protected _loaded = false;
    private _fieldId = '';

    //#endregion

    //#region Properties

    //#endregion

    //#region constructor

    constructor(parent: IRestObject, relation: string, elementId: string) {
        super(parent, relation, null, true, false);
        this._fieldId = elementId;
    }

    //#endregion

    //#region methods

    public async getValueAsync(): Promise<T | null> {
        if (this._loaded === false) {
            if (this._parent.canExecuteRelations === true) {
                const response = await this._parent.executeRequestAsync('GET', this._relation, null, new CancellationToken());
                this.parseResponse(response);
            } else {
                this._data = null;
            }
        }
        return this._data;
    }

    public async pushTransactionAsync(transaction: ITransaction): Promise<void> {
        if (this._parent.canExecuteRelations === false) {
            this._data = null;
            this._loaded = true;
        }

        if (this._loaded === true) {
            // no need for transaction since we already got the data
            // add empty entry simply to return the data
            transaction.addTransactionOperation<T | null>('', '', null, (response: IRestResponse) => {
                return new Promise((resolve, reject) => {
                    resolve(this._data);
                });
            });
            return;
        }

        const uri = this._parent.baseUri + '/' + this.relation;
        transaction.addTransactionOperation<T | null>(uri, 'GET', null, (response: IRestResponse) => {
            return new Promise((resolve, reject) => {
                try {
                    if (response.body) {
                        this.parseResponse(response);
                    }
                    resolve(this._data);
                } catch (e) {
                    reject(e);
                }
            });
        });
        return;
    }

    private parseResponse(response: IRestResponse) {
        if (response.body) {
            this._data = response.body[this._fieldId];
        }
        this._loaded = true;
    }
    //#region
}
