import { IWebSocketRequest } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';
import { Serializer } from '../../../utils/Serializer';

/**
 * @beta
 * */
export class DeleteStreamRequest implements IWebSocketRequest {
  private static readonly command = 'DeleteStream';

  private readonly m_header: WebSocketMessageHeader;

  private readonly m_body: DeleteStreamRequestBody;

  public get channel(): number {
    return this.m_header.channel;
  }

  public get expectResponse() {
    return true;
  }

  constructor(channel: number) {
    this.m_header = new WebSocketMessageHeader(channel, DeleteStreamRequest.command);
    this.m_body = new DeleteStreamRequestBody();
  }

  public serialize(): ArrayBuffer {
    const header = new DataView(this.m_header.serialize());
    const body = new DataView(this.m_body.serialize());

    return Serializer.combineDataView(header, body);
  }

  public debugStatus(indent: number): string { // eslint-disable-line @typescript-eslint/no-unused-vars
    return 'DeleteStreamRequest';
  }
}

/**
 * @beta
 * */
export class DeleteStreamRequestBody {
  constructor() {

  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }
}
