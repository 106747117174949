import { isRetryableError } from './errors/mc-client-error';
import { McClientResponseError } from './errors/mc-client-response-error';

export class ServiceState {
    public state: StateEnum;
    public error?: Error;
    public details?: string | null;

    constructor(state: StateEnum, error?: Error, details?: string | null) {
        this.state = state;
        this.error = error;
        this.details = details;
    }

    public static fromError(err: Error): ServiceState {
        const mcClientError = err as McClientResponseError;
        const details = mcClientError?.subMessage;
        return isRetryableError(err) ? new ServiceState(StateEnum.ErrorRetrying, err, details) : new ServiceState(StateEnum.ErrorUnrecoverable, err, details);
    }
}

export enum StateEnum {
    Unintialized = 0,
    Running = 1,
    ErrorRetrying = 2,
    ErrorUnrecoverable = 3,
}
