// Autogenerated: v2/help/Classes/ApiAccessPointCredentialStatusEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { TimezoneEvent } from './TimezoneEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { IAccessPointCredentialStatusEvent } from '../Interface/IAccessPointCredentialStatusEvent';

export class AccessPointCredentialStatusEvent extends TimezoneEvent implements IAccessPointCredentialStatusEvent {
    // #region REST Properties

    // CredentialGuid
    public get credentialGuid(): IGuid {
        return this.getFieldGuid('CredentialGuid');
    }
    public set credentialGuid(value: IGuid) {
        this.setFieldGuid('CredentialGuid', value);
    }

    // CredentialGuidDesc
    public get credentialGuidDesc(): string {
        return this.getField<string>('CredentialGuidDesc');
    }
    public set credentialGuidDesc(value: string) {
        this.setField<string>('CredentialGuidDesc', value);
    }

    // DoorSideGuid
    public get doorSideGuid(): IGuid {
        return this.getFieldGuid('DoorSideGuid');
    }
    public set doorSideGuid(value: IGuid) {
        this.setFieldGuid('DoorSideGuid', value);
    }

    // DoorSideGuidDesc
    public get doorSideGuidDesc(): string {
        return this.getField<string>('DoorSideGuidDesc');
    }
    public set doorSideGuidDesc(value: string) {
        this.setField<string>('DoorSideGuidDesc', value);
    }

    // DoorSideGuidType
    public get doorSideGuidType(): string {
        return this.getField<string>('DoorSideGuidType');
    }
    public set doorSideGuidType(value: string) {
        this.setField<string>('DoorSideGuidType', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('CredentialGuid', SafeGuid.EMPTY);
        this.setField<string>('CredentialGuidDesc', '');
        this.setFieldGuid('DoorSideGuid', SafeGuid.EMPTY);
        this.setField<string>('DoorSideGuidDesc', '');
        this.setField<string>('DoorSideGuidType', '');
    }

    // #endregion Methods

}

