import { IWebSocketResponse } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';

/**
 * @beta
 * */
export class SuccessResponse implements IWebSocketResponse {
  private readonly m_header: WebSocketMessageHeader;

  private readonly m_body: SuccessResponseBody;

  public get channel(): number {
    return this.m_header.channel;
  }

  public get body(): SuccessResponseBody {
    return this.m_body;
  }

  constructor(header: WebSocketMessageHeader, body: SuccessResponseBody) {
    this.m_header = header;
    this.m_body = body;
  }
}

/**
 * @beta
 * */
export class SuccessResponseBody {
  constructor() {

  }

  public static deserialize(body: string): SuccessResponseBody {
    const successResponseBody: SuccessResponseBody = JSON.parse(body);
    return successResponseBody;
  }
}
