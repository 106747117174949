import { Resolution } from './Resolution';
import { NormalizedVec2, Vec2 } from './Vec2';

export class FitResolution {
  private readonly m_source: Resolution;

  private readonly m_destination: Resolution;

  private readonly m_scaled: Resolution;

  private readonly m_scale: number;

  private readonly m_offsetH: number;

  private readonly m_offsetV: number;

  public get Source(): Resolution {
    return this.m_source;
  }

  public get Destination(): Resolution {
    return this.m_destination;
  }

  public get Scaled(): Resolution {
    return this.m_scaled;
  }

  public get Scale(): number {
    return this.m_scale;
  }

  //In pixels
  public get OffsetH(): number {
    return this.m_offsetH;
  }

  //In pixels
  public get OffsetV(): number {
    return this.m_offsetV;
  }

  public get PixelOffset(): Vec2 {
    return new Vec2(this.OffsetH, this.OffsetV);
  }

  public get OffsetRatio(): NormalizedVec2 {
    return NormalizedVec2.fromVec2(this.PixelOffset, this.Scaled);
  }

  /**
   * Calculates the scaled resolution and the necessary offset in order
   * to entirely fit the source resolution into the destination resolution,
   * while maintaining both aspect ratios.
  */
  public constructor(source: Resolution, destination: Resolution) {
    this.m_source = source;
    this.m_destination = destination;

    const scaledWidth = destination.Width / source.Width;
    const scaledHeight = destination.Height / source.Height;

    //Carefull to reuse the destination value when building resolution instead of recomputing the value because of small floating point error that divide then remultiply introduces
    if (scaledWidth < scaledHeight) {
      this.m_scale = scaledWidth;
      this.m_scaled = Resolution.build(destination.Width, source.Height * this.m_scale);
    } else {
      this.m_scale = scaledHeight;
      this.m_scaled = Resolution.build(source.Width * this.m_scale, destination.Height);
    }

    this.m_offsetH = (destination.Width - this.m_scaled.Width) / 2;
    this.m_offsetV = (destination.Height - this.m_scaled.Height) / 2;
  }

  public toString(): string {
    return `${this.m_source.toString()} in ${this.m_destination.toString()} fitted as ${this.m_scaled.toString()} with offset [${this.m_offsetH},${this.m_offsetV}] (scale of ${this.m_scale})`;
  }
}
