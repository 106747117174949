import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { baseUrl, MapsProviderClient } from '@modules/map/api/api';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { GetBaseUriForCodeGen } from '@src/app/utilities/base-uri-getter';
import { MAPS_BOTTOM_CONTEXT_SERVICE } from '../shared/interfaces/plugins/public/plugin-services-public.interface';
import { SharedModule } from '../shared/shared.module';
import { MapControlsComponent } from './components/map-controls/map-controls.component';
import { MapPopupComponent } from './components/map-popup/map-popup.component';
import { MapsBottomPaneComponent } from './components/maps-bottom-pane/maps-bottom-pane.component';
import { MapsSidePaneComponent } from './components/maps-side-pane/maps-side-pane.component';
import { MapsTaskComponent } from './components/maps-task/maps-task.component';
import { MapsWidgetComponent } from './components/maps-widget/maps-widget.component';
import { MapCommandsProvider } from './services/map/map-commands-provider';
import { MapService } from './services/map/map-service';
import { MapState } from './services/map/map.state';
import { StateMapService } from './services/map/state-map-service';
import { MapsBottomContextDataService } from './services/maps-bottom-context-data/maps-bottom-context-data.service';
import { MapsBottomContextDataState } from './services/maps-bottom-context-data/maps-bottom-context-data.state';
import { MapsSideContextDataState } from './services/maps-side-context-data/maps-side-context-data.state';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@NgModule({
    imports: [CommonModule, SharedModule, TranslateModule, NgxsModule.forFeature([MapState, MapsSideContextDataState, MapsBottomContextDataState])],
    declarations: [MapsTaskComponent, MapsSidePaneComponent, MapsWidgetComponent, MapsBottomPaneComponent, MapControlsComponent, MapPopupComponent],
    providers: [
        StateMapService,
        MapsProviderClient,
        { provide: MAPS_BOTTOM_CONTEXT_SERVICE, useClass: MapsBottomContextDataService },
        MapService,
        MapCommandsProvider,
        { provide: baseUrl, deps: [], useFactory: GetBaseUriForCodeGen },
    ],
})
export class MapsModule {
    constructor(_mapCommandsProvider: MapCommandsProvider) {}
}
