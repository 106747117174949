import {
    createGeoReferencedImageCRS,
    createImageCRS,
    GenImageMapProvider,
    GenImageMapProviderOptions,
    Headers,
    LatLngLongLiteral,
    MapOptions,
    MarkerClusterGroupOptions,
    MiniMap,
    PointLiteral,
    toLatLngBounds,
} from '@genetec/web-maps';
import { MapOptionsComponent } from '@modules/general/components/options/maps-options/maps-options.component';
import { MapCommands } from '@modules/maps/enumerations/map-commands';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { MapContext, SettingsService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleGuids } from 'RestClient/Client/Enumerations/RoleGuids';
import { IMapEntity } from 'RestClient/Client/Interface/IMapEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { IGuid } from 'safeguid';
import { MapLoader } from './map-loader';
import { MapProviderTypes } from './map-provider-types';
import { MapProvider, MapProviderLoaderOptions, TokenSupport } from './map-provider.interface';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export class ImageMapProviderLoader implements MapProvider, TokenSupport {
    public type: IGuid = MapProviderTypes.Image;

    private imageMapTileUrlTemplate = '/{z}/{x}/{y}.png';
    private imageMapApiRequestUrlTemplate = '/v2/Entities/Roles/ExecuteApiRequest/';

    constructor(
        private mapLoader: MapLoader,
        private scClient: SecurityCenterClient,
        private loggerService: LoggerService,
        private commandService: InternalCommandsService,
        private translateService: TranslateService,
        private userSettingsService: SettingsService
    ) {}

    public async loadMapAsync(map: IMapEntity, options: MapProviderLoaderOptions): Promise<boolean> {
        const headersData = this.getConnectionHeaders();
        let webToken = this.scClient.rest.authorizationHeader;
        if (webToken.startsWith('Token')) {
            webToken = webToken.substr(5).trim();
        }

        const markerClustering: MarkerClusterGroupOptions = {
            chunkedLoading: true,
            maxClusterRadius: 50,
            spiderfyOnMaxZoom: false,
            zoomToBoundsOnClick: true,
        };

        const leafletOptions: MapOptions = {
            clustering: markerClustering,
            attributionControl: false,
            closePopupOnClick: false,
            minZoom: 1.0,
            maxZoom: map.maxZoom,
            zoom: options.zoom ?? 2,
            bounceAtZoomLimits: false,
            inertia: false,
            maxBoundsViscosity: 1.0,
            initialBounds: options.defaultView,
            center: options.center,
        };

        const isMinimapDisabled = this.userSettingsService.get<boolean>(OptionTypes.Maps, MapOptionsComponent.disableMinimapSettingId);

        const providerOptions: GenImageMapProviderOptions = {
            url: this.scClient.rest.restServerUrl + this.imageMapApiRequestUrlTemplate,
            useIndexedDBCache: true,
            headers: headersData,
            body: {
                roleType: RoleGuids.MapManagerRole.toString(),
                url: map.id.toString() + this.imageMapTileUrlTemplate,
                method: 'GET',
                headers: { authentication: webToken },
            },
            cacheVersion: map.version.toString(),
            showMinimap: true,
            minimapOptions: {
                disabled: isMinimapDisabled,
                minimumMapWidth: 500,
                contextMenuHandler: async (minimap: MiniMap, e: MouseEvent) => {
                    const commandUsage = await this.commandService.getCommandsUsage({ type: ContextTypes.Map, data: { mapId: map.id } as MapContext }, [MapCommands.ToggleMinimap]);
                    const contextMenuItem = await commandUsage.createContextMenuItemsAsync();
                    this.commandService.showContextMenu(e, contextMenuItem);
                },
            },
        };

        if (options.geoReference) {
            const sourcePoints = options.geoReference.sourcePoints.values().map((point) => ({ x: point.x, y: point.y })) as PointLiteral[];
            const targetCoordinates = options.geoReference.targetPoints
                .values()
                .map((coordinate) => ({ latitude: coordinate.latitude, longitude: coordinate.longitude })) as LatLngLongLiteral[];
            if (targetCoordinates.length >= 3 && targetCoordinates.length === sourcePoints.length) {
                leafletOptions.geoReferencedImageCRS = createGeoReferencedImageCRS(sourcePoints, targetCoordinates);
                leafletOptions.userLocationEnabled = options.userLocationEnabled;
            } else {
                this.loggerService.traceError('GeoReference invalid, not enough reference points or incompatibility between number of source points and target coordinates.');
            }
        } else {
            const scale = Math.pow(2, map.maxZoom);
            const mapSize = scale * 256;
            const halfSize = mapSize / 2;
            const maxBounds = toLatLngBounds([
                [-halfSize, -halfSize],
                [halfSize, halfSize],
            ]);

            leafletOptions.crs = createImageCRS(map.maxZoom);
            // Pads the bounds a bit so that we can pan more and leave room for sidepane for example.
            // Increased padding to 2 to avoid hiding entities that are found in the corners of maps.
            leafletOptions.maxBounds = maxBounds.pad(2);
            providerOptions.bounds = maxBounds;

            // No user location on non georeferenced image maps
            leafletOptions.userLocationEnabled = false;
        }

        await this.mapLoader.loadMapAsync(GenImageMapProvider, leafletOptions, providerOptions);

        return true;
    }

    public applyToken(token: string): void {
        if (!this.mapLoader.map?.isMapInitialized()) {
            this.loggerService.traceError('Map is not initialized!');
            return;
        }
        const provider = this.mapLoader.map.getCurrentProvider() as GenImageMapProvider;
        if (provider) {
            provider.updateWebToken(token);
        }
    }

    private getConnectionHeaders(): Headers {
        const headers: Headers = { authorization: this.scClient.rest.authorizationHeader };
        for (const [key, value] of this.scClient.rest.additionalHeaders) {
            headers[key] = value;
        }
        return headers;
    }
}
