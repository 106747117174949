// Autogenerated: v2/help/enumerations/ApiDeviceType?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class DeviceType {
    public static None = 'None';
    public static AlarmPanelInput = 'AlarmPanelInput';
    public static AlarmPanelOutput = 'AlarmPanelOutput';
    public static AnalogMonitor = 'AnalogMonitor';
    public static AudioDecoder = 'AudioDecoder';
    public static AudioEncoder = 'AudioEncoder';
    public static Input = 'Input';
    public static Lpr = 'Lpr';
    public static Output = 'Output';
    public static Reader = 'Reader';
    public static Serial = 'Serial';
    public static VideoEncoder = 'VideoEncoder';
    public static VideoUnitInput = 'VideoUnitInput';
    public static VideoUnitOutput = 'VideoUnitOutput';

    public static readonly values = [
        DeviceType.None,
        DeviceType.AlarmPanelInput,
        DeviceType.AlarmPanelOutput,
        DeviceType.AnalogMonitor,
        DeviceType.AudioDecoder,
        DeviceType.AudioEncoder,
        DeviceType.Input,
        DeviceType.Lpr,
        DeviceType.Output,
        DeviceType.Reader,
        DeviceType.Serial,
        DeviceType.VideoEncoder,
        DeviceType.VideoUnitInput,
        DeviceType.VideoUnitOutput
    ];
}
