import { IWebSocketResponse } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';

/**
 * @beta
 * */
export class ErrorResponse implements IWebSocketResponse {
  private readonly m_header: WebSocketMessageHeader;

  private readonly m_body: ErrorResponseBody;

  public get channel(): number {
    return this.m_header.channel;
  }

  public get body(): ErrorResponseBody {
    return this.m_body;
  }

  constructor(header: WebSocketMessageHeader, body: ErrorResponseBody) {
    this.m_header = header;
    this.m_body = body;
  }
}

/**
 * @beta
 * */
export class ErrorResponseBody {
  public readonly ErrorMessage: string;

  constructor(errorMessage: string) {
    this.ErrorMessage = errorMessage;
  }

  public static deserialize(body: string): ErrorResponseBody {
    const errorResponseBody: ErrorResponseBody = JSON.parse(body);
    return errorResponseBody;
  }
}
