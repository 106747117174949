// Autogenerated: v2/help/enumerations/ApiAccessStatus?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class AccessStatus {
    public static Active = 'Active';
    public static Inactive = 'Inactive';
    public static Lost = 'Lost';
    public static Stolen = 'Stolen';
    public static Expired = 'Expired';
    public static Requested = 'Requested';

    public static readonly values = [
        AccessStatus.Active,
        AccessStatus.Inactive,
        AccessStatus.Lost,
        AccessStatus.Stolen,
        AccessStatus.Expired,
        AccessStatus.Requested
    ];
}
