// Autogenerated: CodeGen/Content?language=ts&InterfaceOnly=True on 2021-09-14 3:37:12 PM
// Autogenerated: CodeGen/Content?language=ts&InterfaceOnly=False on 2021-09-14 3:37:12 PM
// Autogenerated: CodeGen/ContentGroup?language=ts&InterfaceOnly=True on 2021-09-14 3:37:12 PM
// Autogenerated: CodeGen/ContentGroup?language=ts&InterfaceOnly=False on 2021-09-14 3:37:12 PM
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { FieldObject } from 'RestClient/Helpers/FieldObject';

export interface IContent extends IFieldObject {
    id: IGuid;
    type: IGuid;
    title: string;
    icon: string;
    customIconId: IGuid | null;
    color: string;
    description: string;
    source: string;
    parameters: IFieldObject;
    grantedPrivileges: ObservableCollection<IGuid>;
}

export class Content extends FieldObject implements IContent {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Type
    public get type(): IGuid {
        return this.getFieldGuid('Type');
    }
    public set type(value: IGuid) {
        this.setFieldGuid('Type', value);
    }
    // Title
    public get title(): string {
        return this.getField<string>('Title');
    }
    public set title(value: string) {
        this.setField<string>('Title', value);
    }
    // Icon
    public get icon(): string {
        return this.getField<string>('Icon');
    }
    public set icon(value: string) {
        this.setField<string>('Icon', value);
    }
    // CustomIconId
    public get customIconId(): IGuid | null {
        return this.getNullableFieldGuid('CustomIconId');
    }
    public set customIconId(value: IGuid | null) {
        this.setNullableFieldGuid('CustomIconId', value);
    }
    // Color
    public get color(): string {
        return this.getField<string>('Color');
    }
    public set color(value: string) {
        this.setField<string>('Color', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // Source
    public get source(): string {
        return this.getField<string>('Source');
    }
    public set source(value: string) {
        this.setField<string>('Source', value);
    }
    // Parameters
    public get parameters(): IFieldObject {
        return this.getFieldObject<FieldObject, IFieldObject>(FieldObject, 'Parameters');
    }
    public set parameters(value: IFieldObject) {
        this.setFieldObject<IFieldObject>('Parameters', value);
    }
    // GrantedPrivileges
    public get grantedPrivileges(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('GrantedPrivileges');
    }
    public set grantedPrivileges(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('GrantedPrivileges', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setFieldGuid('Type', SafeGuid.EMPTY);
        this.setField<string>('Title', '');
        this.setField<string>('Icon', '');
        this.setFieldGuid('CustomIconId', SafeGuid.EMPTY);
        this.setField<string>('Color', '');
        this.setField<string>('Description', '');
        this.setField<string>('Source', '');
        const objParameters = new FieldObject();
        objParameters.initializeAllFields();
        this.setFieldObject('Parameters', objParameters);
        this.setFieldArrayGuid('GrantedPrivileges', new ObservableCollection<IGuid>());
    }
}

export interface IContentGroup extends IFieldObject {
    id: IGuid;
    mainContent: IContent;
    subContents: ObservableCollection<IContent>;
}

export class ContentGroup extends FieldObject implements IContentGroup {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // MainContent
    public get mainContent(): IContent {
        return this.getFieldObject<Content, IContent>(Content, 'MainContent');
    }
    public set mainContent(value: IContent) {
        this.setFieldObject<IContent>('MainContent', value);
    }
    // SubContents
    public get subContents(): ObservableCollection<IContent> {
        return this.getFieldObjectArray<Content, IContent>(Content, 'SubContents');
    }
    public set subContents(value: ObservableCollection<IContent>) {
        this.setFieldObjectArray('SubContents', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        const objMainContent = new Content();
        objMainContent.initializeAllFields();
        this.setFieldObject('MainContent', objMainContent);
        this.setFieldArray<IContent>('SubContents', new ObservableCollection<IContent>());
    }
}
