import { Inject, Injectable } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW } from '@utilities/common-helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
/**
 * Unique instance of a state service that manages the various possible states of the nav-bar.
 * It exposes observables of all possible states, and mutator for each of them as well.
 */
export class NavBarService {
    public get isPinned$(): Observable<boolean> {
        return this.isPinned.asObservable();
    }

    public get isOpened$(): Observable<boolean> {
        return this.isOpened.asObservable();
    }

    public get isFocused$(): Observable<boolean> {
        return this.isFocused.asObservable();
    }

    public get skipHoveringCheck$(): Observable<boolean> {
        return this.skipHoveringCheck.asObservable();
    }

    private isPinned: BehaviorSubject<boolean>;
    private isOpened = new BehaviorSubject<boolean>(false);
    private isFocused = new BehaviorSubject<boolean>(false);
    private skipHoveringCheck = new BehaviorSubject<boolean>(false);

    constructor(@Inject(WINDOW) private window: Window) {
        // read from local storage
        const isPinnedLocalStorage = this.window.localStorage.getItem('nav-bar-pinned')?.toLowerCase() === 'true';
        this.isPinned = new BehaviorSubject<boolean>(isPinnedLocalStorage);
        if (isPinnedLocalStorage) {
            // also open
            this.setIsOpenedState(true);
        }
    }

    /**
     * Pushed the newState in the isPinnedState subject.
     *
     * @param newState boolean value to push in the store
     */
    public setIsPinnedState(newState: boolean): void {
        this.setPinnedAndStore(newState);
    }

    /**
     * Retrieves the last emitted state, toggles it, and pushes it in the isPinnedState subject.
     */
    public toggleIsPinnedState(): void {
        this.isPinned$.pipe(take(1), untilDestroyed(this)).subscribe((value: boolean) => {
            this.setPinnedAndStore(!value);
        });
    }

    /**
     * Pushes the newState in the isOpened subject
     *
     * @param newState boolean value to push in the store
     */
    public setIsOpenedState(newState: boolean): void {
        this.isOpened.next(newState);
    }

    /**
     *
     * @param newState boolean value to push in the store
     */
    public setIsFocusedState(newState: boolean): void {
        this.isFocused.next(newState);
    }

    /**
     * Pushes the newState in the skipHoveringCheck subject.
     *
     * @param newState boolean value to push in the store
     */
    public setSkipHoveringCheck(newState: boolean): void {
        this.skipHoveringCheck.next(newState);
    }

    /**
     * Takes the isPinned boolean value, pushes it in the isPinnedSubject and saves it in the local storage.
     *
     * @param value
     */
    private setPinnedAndStore(value: boolean): void {
        this.isPinned.next(value);
        this.window.localStorage.setItem('nav-bar-pinned', value.toString());
    }
}
