<gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
<div *ngIf="!isLoading" [style.cursor]="'pointer'" (click)="navigateToCredentialAsync()" appClickActionOnSpaceEnter tabindex="0">
    <div class="title-bar">
        <gen-text [flavor]="TextFlavor.Label">{{ 'STE_ENTITY_CREDENTIAL' | translate }}</gen-text>
        <gen-icon [icon]="Icon.AngleRight"></gen-icon>
    </div>
    <gen-item>
        <gen-text>{{ credentialName }}</gen-text>
        <gen-text [slot]="ItemSlot.TextSecondary">{{ status }}</gen-text>
        <gen-icon [icon]="Icon.Fingerprint"></gen-icon>
    </gen-item>
</div>
