<gen-melted-modal
    id="sendFeedbackModal"
    genTitle="{{ 'STE_LABEL_SEND_FEEDBACK' | translate }}"
    [genIsBlocking]="true"
    [genCanClose]="true"
    genDefaultActionText="{{ 'STE_BUTTON_SUBMIT' | translate }}"
    [genOnDefaultAction]="onSubmit"
    appFocusModalWhenOpen
    appKeydownStopPropagation
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnAlternativeAction]="handleCancel"
>
    <gen-melted-modal-body>
        <ng-container [formGroup]="form">
            <gen-text-input formControlName="email" name="email-field-text" class="mt-2" appAutoFocus>
                <gen-label>{{ 'STE_LABEL_NOUN_EMAIL' | translate }}</gen-label>
                <gen-message severity="error" [appFormControlError]="form.controls.email"></gen-message>
            </gen-text-input>
            <gen-text-input formControlName="message" name="message-field-text" class="mt-1" [multiline]="8">
                <gen-label>{{ 'STE_MESSAGE_INFO_SEND_FEEDBACK_MESSAGE_TITLE' | translate }}</gen-label>
                <gen-message severity="error" [appFormControlError]="form.controls.message"></gen-message>
            </gen-text-input>
            <!-- No Internet access? Send an email directly to SCWebFeedback@genetec.com. -->
            <gen-label class="mt-2">{{ 'STE_MESSAGE_INFO_SEND_FEEDBACK_NO_INTERNET' | translate | stringFormat: [sendFeedbackGenetecEmail] }}</gen-label>
        </ng-container>
    </gen-melted-modal-body>
</gen-melted-modal>
