import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { TextFlavor, ItemSlot } from '@genetec/gelato';
import { GenAlertComponent } from '@genetec/gelato-angular';
import { TrackedGenModalComponent } from '@modules/shared/components/tracked/tracked-gen-modal.component';
import { LanguageService } from '@modules/shared/services/language/language.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { VersionService } from '@modules/shared/services/version/version.service';
import moment from 'moment';
import { ModuleLoadData } from '@modules/shared/api/api';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent extends TrackedGenModalComponent implements OnInit {
    @ViewChild(GenAlertComponent) public genAlertComponent!: GenAlertComponent;

    public readonly TextFlavor = TextFlavor;
    public readonly ItemSlot = ItemSlot;

    public appVersion = '';
    public buildVersion = '';
    public restVersion = '';
    public scVersion = '';
    public releaseDate = '';
    public copyrights = '';
    public modulesLoadData?: ModuleLoadData[];

    public modulesDisplayed = false;

    constructor(injector: Injector, private languageService: LanguageService, private versionService: VersionService, trackingService: TrackingService) {
        super(injector, trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        // execute both methods in parallel
        this.retrieveVersion().fireAndForget();
        this.retrieveCopyrights().fireAndForget();
    }

    private async retrieveCopyrights() {
        this.copyrights = await this.languageService.retrieveCopyrightsAsync();
    }

    private async retrieveVersion() {
        const versionInfo = await this.versionService.retrieveVersionAsync();
        if (versionInfo) {
            this.appVersion = versionInfo.appVersion;
            this.buildVersion = versionInfo.buildVersion;
            this.restVersion = versionInfo.restVersion;
            this.scVersion = versionInfo.securityCenterVersion;
            this.releaseDate = moment(versionInfo.releaseDate).format('YYYY/MM/DD');
            this.modulesLoadData = versionInfo.modulesLoadData;
        }
    }
}
