import { Component, OnInit } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { IGuid, SafeGuid } from 'safeguid';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { DateFormat } from '@genetec/gelato-angular';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { CredentialEntityFields } from 'RestClient/Client/Interface/ICredentialEntity';
import { TimeService } from '@modules/shared/services/time/time.service';
import { CredentialWidgetBaseComponent } from '../credential-widget-base.component';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
const isContentSupported = (content: Content): boolean => {
    return !!(!content.contextContent && content.type.equals(AccessControlContentTypes.Credential));
};

@Component({
    selector: 'app-credential-info-widget',
    templateUrl: './credential-info-widget.component.html',
    styleUrls: ['./credential-info-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: CredentialInfoWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CredentialInfoWidgetComponent.pluginId, priority: 5 },
    isContentSupported,
})
export class CredentialInfoWidgetComponent extends CredentialWidgetBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('7C43F5DA-55A8-4216-9E52-639C5834FFBA');

    public readonly TextFlavor = TextFlavor;

    protected get monitoredFields(): string[] {
        const fields = super.monitoredFields;
        fields.push(CredentialEntityFields.activationDateField);
        fields.push(CredentialEntityFields.expirationDateField);
        return fields;
    }

    public activationDate: string | undefined;
    public expirationDate: string | undefined;

    constructor(securityCenterClientService: SecurityCenterClientService, private timeService: TimeService, trackingService: TrackingService) {
        super(securityCenterClientService, trackingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = false;
    }

    protected async initializeCredentialInfo(): Promise<void> {
        await super.initializeCredentialInfo();

        if (this.credential) {
            const activationDate = this.credential.activationDate;
            this.activationDate = this.timeService.formatTime(activationDate, DateFormat.DateTime);

            const expirationDate = this.credential.expirationDate;
            if (expirationDate > activationDate) {
                this.expirationDate = this.timeService.formatTime(expirationDate, DateFormat.DateTime);
            } else {
                this.expirationDate = '-';
            }
        } else {
            this.activationDate = '-';
            this.expirationDate = '-';
        }
    }
}
