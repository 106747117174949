import { Injectable, OnDestroy } from '@angular/core';
import { ThreatLevelClient, ThreatLevelInfo } from '@modules/shared/api/api';
import { ThreatLevelChangedEvent } from '@modules/shared/controllers/threat-levels/threat-levels-changed-event';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IEventBase } from 'RestClient/Client/Interface/IEventBase';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { map, take, tap } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ThreatLevelService implements OnDestroy {
    public threatLevels$: Observable<ThreatLevelInfo[]>;
    public threatLevels: ThreatLevelInfo[] = [];

    private activeThreatLevelSubject: BehaviorSubject<ThreatLevelInfo | null>;
    get activeThreatLevel$(): Observable<ThreatLevelInfo | null> {
        return this.activeThreatLevelSubject.asObservable();
    }

    private scClient: SecurityCenterClient;

    private readonly threatLevelsEventKey = 'ThreatLevelChangedEvent';

    private threatLevelsSubscription?: Subscription;

    constructor(securityCenterClientService: SecurityCenterClientService, private threatLevelClient: ThreatLevelClient, private translateService: TranslateService) {
        this.scClient = securityCenterClientService?.scClient;
        this.subscribeThreatLevelsEvents();

        this.activeThreatLevelSubject = new BehaviorSubject<ThreatLevelInfo | null>(null);

        this.threatLevels$ = this.setupThreatLevels();
    }

    public ngOnDestroy(): void {
        this.threatLevelsSubscription?.unsubscribe();
    }

    public setThreatLevel(newThreatLevel: IGuid): void {
        this.threatLevelClient.setThreatLevel(newThreatLevel).pipe(take(1), untilDestroyed(this)).subscribe();
    }

    private setupThreatLevels(): Observable<ThreatLevelInfo[]> {
        return this.threatLevelClient.threatLevels().pipe(
            tap((results) => {
                if (!results?.length) {
                    return this.threatLevels;
                }
                results.sort((threatLevelA, threatLevelB) => threatLevelA.level - threatLevelB.level);
            }),
            map((threatLevelsInfo?: ThreatLevelInfo[]) => {
                this.threatLevels = threatLevelsInfo ?? [];
                const activeThreatLevel = this.findActiveThreatLevel(this.threatLevels);
                this.activeThreatLevelSubject.next(activeThreatLevel);
                return this.threatLevels;
            })
        );
    }

    private findActiveThreatLevel(threatLevels: ThreatLevelInfo[]): ThreatLevelInfo | null {
        return threatLevels.find((threatLevel) => threatLevel.isActive) ?? null;
    }

    private subscribeThreatLevelsEvents() {
        if (!this.scClient) {
            return;
        }
        this.scClient.registerAdditionalEventTypes(this.threatLevelsEventKey, ThreatLevelChangedEvent);
        this.threatLevelsSubscription = new Subscription(this.scClient.onEventReceived((arg) => this.onEventReceived(arg)));
    }

    private onEventReceived(arg: EventReceivedArg): void {
        const eventBase = arg?.event;
        const threatLevelsEvent = this.getThreatLevelsEvent(eventBase);
        if (threatLevelsEvent) {
            const activeThreatLevel = this.getThreatLevelInfo(threatLevelsEvent.newThreatLevelGuid);
            this.activeThreatLevelSubject.next(activeThreatLevel);
        }
    }

    private getThreatLevelInfo(threatLevelId: IGuid): ThreatLevelInfo | null {
        return this.threatLevels.find((threatLevel) => threatLevel.id.equals(threatLevelId)) ?? null;
    }

    private getThreatLevelsEvent(eventBase: IEventBase): ThreatLevelChangedEvent | undefined {
        return eventBase.eventType === this.threatLevelsEventKey && eventBase instanceof ThreatLevelChangedEvent ? eventBase : undefined;
    }
}
