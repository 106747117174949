import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, IconSize, TextFlavor, PopupPosition } from '@genetec/gelato';
import { GenModalService, GenPopup } from '@genetec/gelato-angular';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { ResourcesService } from '@modules/shared/services/resources/resources.service';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { VersionService } from '@modules/shared/services/version/version.service';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { WINDOW } from '@utilities/common-helper';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AboutComponent } from '../about/about.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { SendFeedbackComponent } from '../send-feedback/send-feedback.component';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends TrackedComponent implements OnInit {
    @ViewChild(GenPopup) profilePopup!: GenPopup;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly TextFlavor = TextFlavor;
    public readonly PopupPosition = PopupPosition;

    public optionsModal = 'optionsModal';
    public userPicture!: string;
    public userNameDisplay!: string;
    public isUserImportedFromExternalSystem = false;
    public directoryDisplay!: string | null | undefined;
    public hasUserPicture = false;
    public hasChangePasswordPrivilege = false;
    public isLoggedInWithOpenId = false;

    private readonly defaultTechdocsUrl = 'https://sc-webapp-help.genetec.com/';

    constructor(
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        private securityCenterClientService: SecurityCenterClientService,
        private authService: AuthService,
        private versionService: VersionService,
        private modalService: GenModalService,
        private resourcesService: ResourcesService,
        trackingService: TrackingService,
        private logger: LoggerService,
        @Inject(WINDOW) private window: Window,
        private translateServive: TranslateService
    ) {
        super(trackingService);
    }

    async ngOnInit() {
        super.ngOnInit();

        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe((loggedIn) => {
            if (loggedIn) {
                this.loadAppInformation().fireAndForget();
                this.loadUserInformation().fireAndForget();
            }
        });
        this.isLoggedInWithOpenId = await this.authService.getIsAuthenticatedWithOpenIdAsync();
    }

    public async onAbout(): Promise<void> {
        await this.profilePopup.close();
        this.modalService.open(AboutComponent);
    }

    public onHelp(): void {
        this.profilePopup.close().fireAndForget();
        this.window.open(this.defaultTechdocsUrl + this.translateServive.currentLang);
    }

    public async onSendFeedback(): Promise<void> {
        await this.profilePopup.close();
        this.modalService.open(SendFeedbackComponent);
    }

    public async onChangePassword(): Promise<void> {
        await this.profilePopup.close();
        this.modalService.open(ChangePasswordComponent);
    }

    public async onOptions(): Promise<void> {
        await this.profilePopup.close();
        this.commandsService.executeCommand(SharedCommands.ShowOptions);
    }

    public async onProfile(): Promise<void> {
        await this.profilePopup.toggle();
    }

    public async onSignOut(): Promise<void> {
        try {
            await this.authService.logOutAsync();
        } catch (e) {}
    }

    public async outputComponentStats(): Promise<void> {
        await this.profilePopup.close();
        this.trackingService.outputStats();
    }

    public async closeProfile(): Promise<void> {
        await this.profilePopup.close();
    }

    private async loadAppInformation() {
        const scClient = this.securityCenterClientService?.scClient;
        const versionInfo = await this.versionService.retrieveVersionAsync();
        if (versionInfo) {
            this.directoryDisplay = versionInfo.securityCenterServerName ?? versionInfo.serverName;
        }

        // resolve permission for change password
        this.hasChangePasswordPrivilege = scClient.isGlobalPrivilegeGranted(KnownPrivileges.changeOwnPasswordPrivilege);
    }

    private async loadUserInformation() {
        const scClient = this.securityCenterClientService?.scClient;
        try {
            const user = await scClient?.getEntityAsync<UserEntity, IUserEntity>(UserEntity, this.securityCenterClientService?.scClient.userId);
            if (user) {
                // resolve the username to display
                this.userNameDisplay = user.name;
                // resolve if user is coming from an external system
                this.isUserImportedFromExternalSystem = user.isImportedFromExternalSystem;

                if (user.firstName !== null && user.firstName.length > 0 && user.lastName !== null && user.lastName.length > 0) {
                    this.userNameDisplay = user.firstName + ' ' + user.lastName;
                }

                // download its thumbnail
                if (!user.thumbnailId.isEmpty()) {
                    const fileResult = await this.resourcesService.getFilesCacheAsync([{ entity: user, fileCacheId: user.thumbnailId }], { encrypted: true });

                    const thumbnail = fileResult[user.thumbnailId.toString()];
                    if (thumbnail) {
                        this.userPicture = thumbnail;
                        this.hasUserPicture = true;
                    }
                }
            }
        } catch (error) {
            this.userNameDisplay = scClient?.currentUserInfo?.name ?? (await scClient?.getCurrentUserInfoAsync())?.name;
            this.logger.traceError(error);
        }
    }
}
