// Autogenerated: v2/help/Entities/ApiUser?codegen=ts&InterfaceOnly=False&workflow=True on 2021-08-30 10:02:01 AM
import { EntityTypes } from '../Enumerations/EntityTypes';
import { Transaction } from '../Transaction';
import { FieldObject } from '../../Helpers/FieldObject';
import { EntityManager } from './EntityManager';
import { RestResponse } from '../../Connection/RestResponse';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IUserManager, ICurrentUserInfo } from '../Interface/IUserManager';
import { ITransaction } from '../Interface/ITransaction';

// #region Inner classes

export class CurrentUserInfo extends FieldObject implements ICurrentUserInfo {
    // UserGuid
    public get userGuid(): IGuid {
        return this.getFieldGuid('UserGuid');
    }
    public set userGuid(value: IGuid) {
        this.setFieldGuid('UserGuid', value);
    }
    // IsAdministrator
    public get isAdministrator(): boolean {
        return this.getField<boolean>('IsAdministrator');
    }
    public set isAdministrator(value: boolean) {
        this.setField<boolean>('IsAdministrator', value);
    }
    // UserName
    public get userName(): string {
        return this.getField<string>('UserName');
    }
    public set userName(value: string) {
        this.setField<string>('UserName', value);
    }
    // DirectoryGuid
    public get directoryGuid(): IGuid {
        return this.getFieldGuid('DirectoryGuid');
    }
    public set directoryGuid(value: IGuid) {
        this.setFieldGuid('DirectoryGuid', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('UserGuid', SafeGuid.EMPTY);
        this.setField<boolean>('IsAdministrator', false);
        this.setField<string>('UserName', '');
        this.setFieldGuid('DirectoryGuid', SafeGuid.EMPTY);
    }
}

// #endregion Inner classes

export class UserManager extends EntityManager implements IUserManager {
    // #region Constructor

    constructor() {
        super('v2/entities/Users');
    }

    // #endregion Constructor

    public async getCurrentUserInfoAsync(transaction?: ITransaction | null): Promise<ICurrentUserInfo | null> {
        return await this.getDataRelationAsync<CurrentUserInfo>(CurrentUserInfo, 'currentuserinfo', false, ValidFlags.None, transaction);
    }

    public async delegatedUserInfoAsync(authentification: string, transaction?: ITransaction): Promise<ICurrentUserInfo | null> {
        const responseHandler = (response: IRestResponse): Promise<ICurrentUserInfo> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = new CurrentUserInfo();
                result.loadFields(response.body);
                return new Promise<ICurrentUserInfo>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<ICurrentUserInfo>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('authentification', authentification);

        return this.executeRequestTransactionAsync<ICurrentUserInfo>('currentuserinfo', 'POST', inParam, transaction, responseHandler);
    }

    public async executeHotActionAsync(id: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('id', id);

        return this.executeRequestTransactionAsync<IRestResponse>('executehotaction', 'POST', inParam, transaction, responseHandler);
    }

}

