import { Injectable } from '@angular/core';
import { ActionBase } from 'RestClient/Client/Event/ActionBase';
import { ActionReceivedArg } from 'RestClient/Connection/RestArgs';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { LoggerService } from '../logger/logger.service';
import { SecurityCenterStateService } from '../state/security-center-state.service';

// Handler of the action (execution)
export interface ActionHandler {
    executeAsync(action: ActionBase): Promise<boolean>;
}

@Injectable({
    providedIn: 'root',
})
export class ActionsService extends SecurityCenterStateService {
    //#region Fields

    // map the action with their handler. for now, we only support 1 handler per action
    private handlers = new Map<string, ActionHandler>();

    //#endregion

    //#region Constructors

    constructor(securityCenterProvider: SecurityCenterClientService, private loggerService: LoggerService) {
        super(securityCenterProvider);
        securityCenterProvider.scClient?.onActionReceived((x) => this.onActionReceived(x));
    }

    //#endregion

    //#region Methods

    public registerHandler(actionType: string, handler: ActionHandler): void {
        if (handler) {
            this.handlers.set(actionType, handler);
        }
    }

    public unregisterHandler(actionType: string): void {
        this.handlers.delete(actionType);
    }

    //#endregion

    //#region Event Handlers

    private async onActionReceived(arg: ActionReceivedArg) {
        const fo = arg.action;
        if (fo.hasField('ActionType')) {
            const action = new ActionBase(new FieldObject());
            action.loadFrom(fo);

            // retrieve the handler responsible for this specific action
            const handler = this.handlers.get(action.actionType);
            if (handler) {
                try {
                    await handler.executeAsync(action);
                } catch (ex) {
                    this.loggerService.traceError(`Error occurred while executing action ${action.actionType}. ${(ex as Error).message}`);
                }
            }
        }
    }

    //#endregion
}
