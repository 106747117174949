/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, EmbeddedViewRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ButtonFlavor, Icon, IconSize, ItemSlot, TextFlavor, SpinnerSize } from '@genetec/gelato';
import { ContextMenuPosition, GenMeltedTableComponent, GenMenu, GenModalService, GenTableActions, GenTableColumn, TableCellType } from '@genetec/gelato-angular';
import { ContentFields } from '@modules/mission-control/content-fields';
import { ModalGuids } from '@modules/mission-control/mc-modal-ids';
import { MCPrivileges } from '@modules/mission-control/mc-privileges';
import { MissionControlContentTypes } from '@modules/mission-control/mission-control-content-types';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent, PluginComponentExposure } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContextMenuFactory } from '@modules/shared/services/context-menu/context-menu.factory';
import { FilterContext } from '@modules/shared/services/filters/filter';
import { FilterCoordinatorService, FILTER_CONTEXT } from '@modules/shared/services/filters/filter-coordinator-service';
import { TranslateService } from '@ngx-translate/core';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@shared/services/tracking.service';
import { debounce } from 'lodash';
import { Observable } from 'rxjs';
import { delay, switchMap, take, tap } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { ResizeWidthEvent } from 'src/app/modules/shared/directives/resized/interfaces/resize-width-event';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { IncidentFilter } from '../../models/incident-filter';
import { IncidentSorting, sortingOrientation, sortingParameter } from '../../models/incident-sorting';
import { ServiceState, StateEnum } from '../../models/service-state';
import { IncidentActionService } from '../../services/incident-actions/incident-actions.service';
import { IncidentLocationService } from '../../services/incident-location/incident-location.service';
import { IncidentMapSelectorService } from '../../services/incident-map-selector/incident-map-selector.service';
import { IncidentService } from '../../services/incident/incident.service';
import { PrivilegeService } from '../../../shared/privilege/privilege.service';
import { toGuid } from '../../utils/guid-utils';
import { TriggerIncidentModalComponent } from '../trigger-incident-modal/trigger-incident-modal.component';
import { IconService } from '@modules/mission-control/services/icons/icon.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

export const isContentSupported = (content: Content, pluginType: IGuid): boolean => {
    return content?.type?.equals(MissionControlContentTypes.incidentList) || pluginType === PluginTypes.Dashboard;
};

enum IncidentTemplateStyle {
    List,
    Card,
}

export enum IncidentOrientation {
    None,
    Up,
    Right,
    Bottom,
    Left,
}

/*
    Up or Down -> List
    Left or Right -> Card
    None -> Container width size decide the template style
*/
class IncidentComponentParameter {
    private _incidentOrientation = IncidentOrientation.Bottom;
    private _incidentTemplateStyle = IncidentTemplateStyle.List;
    private _infiniteScrollContainer: '.ag-body-viewport.ag-layout-normal' | '#incident-cards-list' = '.ag-body-viewport.ag-layout-normal';

    public get infiniteScrollContainer() {
        return this._infiniteScrollContainer;
    }

    public get incidentTemplateStyle(): IncidentTemplateStyle {
        return this._incidentTemplateStyle;
    }

    public set incidentTemplateStyle(value: IncidentTemplateStyle) {
        this._incidentTemplateStyle = value;
        if (this.incidentTemplateStyle === IncidentTemplateStyle.Card) this._infiniteScrollContainer = '#incident-cards-list';
        else this._infiniteScrollContainer = '.ag-body-viewport.ag-layout-normal';
    }

    public get incidentOrientation(): IncidentOrientation {
        return this._incidentOrientation;
    }

    public set incidentOrientation(value: IncidentOrientation) {
        this._incidentOrientation = value;
        if (this.incidentOrientation === IncidentOrientation.Left || this.incidentOrientation === IncidentOrientation.Right)
            this.incidentTemplateStyle = IncidentTemplateStyle.Card;
        else if (this.incidentOrientation === IncidentOrientation.Up || this.incidentOrientation === IncidentOrientation.Bottom)
            this.incidentTemplateStyle = IncidentTemplateStyle.List;
    }
}
@UntilDestroy()
@Component({
    selector: 'app-incidents-list',
    templateUrl: './incidents-list.component.html',
    styleUrls: ['./incidents-list.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [style({ height: 0, opacity: 0 }), animate('1s 1.5s ease-out', style({ height: '100%', opacity: 1 }))]),
            transition(':leave', [style({ height: '100%', opacity: 1 }), animate('1s ease-in', style({ height: 0, opacity: 0 }))]),
        ]),
    ],
    providers: [{ provide: FilterCoordinatorService }, { provide: FILTER_CONTEXT, useValue: FilterContext.IncidentList }],
})
@InternalContentPluginDescriptor({
    type: IncidentsListComponent,
    pluginTypes: [PluginTypes.Widget, PluginTypes.Dashboard],
    exposure: { id: IncidentsListComponent.pluginId, icon: Icon.Incident, title: 'Incidents' } as PluginComponentExposure,
    requirements: {
        optionalGlobalPrivileges: [
            MCPrivileges.triggerIncidents,
            MCPrivileges.editIncidentPriority,
            MCPrivileges.takeOwnership,
            MCPrivileges.overrideOwnership,
            MCPrivileges.forwardIncident,
            MCPrivileges.transferIncident,
        ],
        globalPrivileges: [KnownPrivileges.incidentMonitoringTaskPrivilege],
    },
    isContentSupported,
})
export class IncidentsListComponent extends TrackedComponent implements OnInit, ContentPluginComponent, AfterViewInit, AfterViewChecked {
    public static pluginId = SafeGuid.parse('786FCAF6-EA8A-43C3-95BB-D5319DA931EF');

    @ViewChild('sortingLabelTemplate') private sortingLabelTemplate: TemplateRef<any> | undefined;
    @ViewChild('incidentListContainer') private incidentListContainer!: ElementRef<HTMLElement>;
    @ViewChild('nameTemplate') private nameTemplate: TemplateRef<any> | undefined;
    @ViewChild('timeTemplate') private timeTemplate: TemplateRef<any> | undefined;
    @ViewChild('priorityNameTemplate') private priorityNameTemplate: TemplateRef<any> | undefined;
    @ViewChild('stateNameTemplate') private stateNameTemplate: TemplateRef<any> | undefined;
    @ViewChild('ownerNameTemplate') private ownerNameTemplate: TemplateRef<any> | undefined;
    @ViewChild('locationNameTemplate') private locationNameTemplate: TemplateRef<any> | undefined;
    @ViewChildren('incidentsTable') private tables!: QueryList<GenMeltedTableComponent>;
    @ViewChild('contextMenu') contextMenu!: GenMenu;

    public ContextMenuPosition = ContextMenuPosition;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly IconSize = IconSize;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;
    public readonly IncidentTemplateStyle = IncidentTemplateStyle;
    public readonly IncidentOrientation = IncidentOrientation;
    public readonly ItemSlot = ItemSlot;
    public readonly SpinnerSize = SpinnerSize;
    public readonly IconService = IconService;

    public columns: Array<GenTableColumn> = [];
    public actions: GenTableActions | undefined;
    public incidents$!: Observable<MCIncident[]>;
    public incidentServiceState$!: Observable<ServiceState>;
    public stateEnum = StateEnum;

    public content: Content | undefined;
    public dataContext: unknown;
    public hasTriggerIncidentPrivilege = false;
    public isScrollEnabled = true;
    public incidentActions$: Observable<ContextMenuItem[]> = new Observable<ContextMenuItem[]>();
    public contextPosition = { x: 0, y: 0 };
    public isListRendered = false;
    public incidentComponentParameter = new IncidentComponentParameter();

    private direction: 'UP' | 'DOWN' | 'NONE' = 'NONE';
    private savedScrollPosition = 0;
    private containsNewData = false;
    private allLabels = new Array<HTMLElement>();
    private currentSortingOrientation: sortingOrientation = '';
    private currentSortingParameter!: sortingParameter;
    private incidentTable: GenMeltedTableComponent | null = null;
    private selectedIncident: MCIncident | null = null;

    private supportedSorting: Record<string, sortingParameter> = {
        STE_LABEL_ABBREV_IDENTIFIER: 'displayId',
        STE_LABEL_NAME: 'name',
        STE_LABEL_INCIDENT_LOCATION: 'location',
        STE_LABEL_INCIDENT_TRIGGERINGTIME: 'triggerTime',
        STE_LABEL_INCIDENTSTATE: 'state',
        STE_LABEL_PRIORITY: 'priority',
    };

    private eventModelUpdated = debounce(() => this.selectIncident(this.selectedIncident?.id ?? null), 50) as () => void;

    private eventVirtualColumnsChange = debounce(() => {
        this.allLabels = [];
        this.arrangeSorting();
    }, 50) as () => void;

    private eventGridSizeChange = debounce(() => this.incidentTable?.gridApi?.sizeColumnsToFit(), 50) as () => void;

    constructor(
        trackingService: TrackingService,
        private _incidentService: IncidentService,
        private _translateService: TranslateService,
        private _modalService: GenModalService,
        private _privilegeService: PrivilegeService,
        private _incidentActionService: IncidentActionService,
        private _incidentLocationService: IncidentLocationService,
        private _incidentMapSelectorService: IncidentMapSelectorService,
        private _contextMenuFactory: ContextMenuFactory,
        private _viewContainerRef: ViewContainerRef
    ) {
        super(trackingService);
        this._incidentMapSelectorService.currentDisplayedIncident$.pipe(untilDestroyed(this)).subscribe((incident) => this.selectIncident(incident?.id ?? null));
    }

    public onResize(event: ResizeWidthEvent): void {
        if (this.incidentComponentParameter.incidentOrientation !== IncidentOrientation.None) return;
        if (event.newWidth < 720) {
            this.incidentComponentParameter.incidentTemplateStyle = IncidentTemplateStyle.Card;
        } else {
            this.incidentComponentParameter.incidentTemplateStyle = IncidentTemplateStyle.List;
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.initializePrivileges();
        this.incidentServiceState$ = this._incidentService.state$.pipe(
            tap((x) => {
                if (x.state !== StateEnum.Running) this.isListRendered = false;
            })
        );

        this._incidentService
            .getIncidents()
            .pipe(
                switchMap((incidents) => {
                    const locationsIds = incidents.map((i) => i.location?.entityId ?? null);
                    const nonEmptyLocationIds = locationsIds.filter((id): id is IGuid => id instanceof SafeGuid && !id.isEmpty());
                    return this._incidentLocationService.prefetch(nonEmptyLocationIds);
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.incidents$ = this._incidentService.getIncidents().pipe(
            tap((_) => {
                this.containsNewData = true;
                if (this.direction === 'NONE') this.saveScrollPosition();
            })
        );
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
        if (!this.content.parameters?.hasField(ContentFields.listOrientation)) return;
        const listOrientation: string = (JSON.parse(this.content.parameters.getField<string>(ContentFields.listOrientation)) as string) ?? IncidentOrientation.None.toString();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
        this.incidentComponentParameter.incidentOrientation = IncidentOrientation[listOrientation as keyof typeof IncidentOrientation];
    }

    public ngAfterViewInit(): void {
        // TODO: Update once gen-table will be migrated, allows selection to be preserve when table is updated
        // Hack with delay because gridApi is always null since Angular 12
        if (this.tables.last) {
            debounce(() => this.addEventsForIncidentTable(this.tables.last), 1000)();
        } else {
            this.tables.changes
                .pipe(delay(1000), untilDestroyed(this))
                .subscribe((components: QueryList<GenMeltedTableComponent>) => this.addEventsForIncidentTable(components.last));
        }

        this.columns = [
            { columnName: 'id', label: 'Id', cellType: TableCellType.Text, hide: true },
            { columnName: 'displayId', label: this._translateService.instant('STE_LABEL_ABBREV_IDENTIFIER'), cellType: TableCellType.Text, maxWidth: 60, suppressSorting: true },
            { columnName: 'name', label: this._translateService.instant('STE_LABEL_NAME'), cellType: TableCellType.Custom, cellTemplate: this.nameTemplate, suppressSorting: true },
            {
                columnName: 'location',
                label: this._translateService.instant('STE_LABEL_INCIDENT_LOCATION'),
                cellType: TableCellType.Custom,
                cellTemplate: this.locationNameTemplate,
                suppressSorting: true,
            },
            {
                columnName: 'triggerTimeUtc',
                label: this._translateService.instant('STE_LABEL_INCIDENT_TRIGGERINGTIME'),
                cellType: TableCellType.Custom,
                cellTemplate: this.timeTemplate,
                suppressSorting: true,
            },
            {
                columnName: 'stateId',
                label: this._translateService.instant('STE_LABEL_INCIDENTSTATE'),
                cellType: TableCellType.Custom,
                cellTemplate: this.stateNameTemplate,
                suppressSorting: true,
            },
            {
                columnName: 'priorityId',
                label: this._translateService.instant('STE_LABEL_PRIORITY'),
                cellType: TableCellType.Custom,
                cellTemplate: this.priorityNameTemplate,
                suppressSorting: true,
            },
            {
                columnName: 'ownerId',
                label: this._translateService.instant('STE_LABEL_OWNER'),
                cellType: TableCellType.Custom,
                cellTemplate: this.ownerNameTemplate,
                suppressSorting: true,
            },
        ];
    }

    public ngAfterViewChecked(): void {
        this.arrangeScrollbar();
        this.arrangeSorting();
    }

    public initializePrivileges(): void {
        this.hasTriggerIncidentPrivilege = this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.triggerIncidents);
    }

    public onTriggerIncidentClick(): void {
        if (this._modalService) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this._modalService.open(TriggerIncidentModalComponent, { id: ModalGuids.triggerIncidentModalId.toString() });
            // TODO: ideally, the modal service should handle this: not allow 2 modals to be open.
            this.removeFocusFromActiveElement();
        }
    }

    public onSelectItem(incident: MCIncident) {
        this.selectedIncident = incident;
        this._incidentMapSelectorService.setMapSidePaneSelection(incident);
    }

    public translate = (toTranslate: string): string => (this._translateService?.instant(toTranslate) as string) ?? toTranslate;

    public onIncidentFilterChange(incidentFilter: IncidentFilter): void {
        this._incidentService.applyFilter(incidentFilter);
    }

    public onIncidentSortingChange(element: HTMLDivElement, key: string): void {
        const toggleOrientation = (iconElem: HTMLGenIconElement) => {
            switch (this.currentSortingOrientation) {
                case '':
                case 'desc':
                    this.currentSortingOrientation = 'asc';
                    iconElem.icon = Icon.ArrowUp;
                    break;
                case 'asc':
                    this.currentSortingOrientation = 'desc';
                    iconElem.icon = Icon.ArrowDown;
                    break;
            }
        };

        //get the correct label from the hashmap
        const label = this.supportedSorting[key];
        if (element) {
            const iconElement = element.querySelector('gen-icon');

            if (!iconElement || !element) return;

            //we dont support sorting for the following labels
            if (label === this.currentSortingParameter) {
                toggleOrientation(iconElement);
            } else {
                //set the default orientation to asc on first click
                this.currentSortingParameter = label;
                this.currentSortingOrientation = 'asc';
                this.allLabels.forEach((x) => {
                    const e = x.querySelector('gen-icon');
                    if (!e) return;
                    e.icon = Icon.None;
                });
                iconElement.icon = Icon.ArrowUp;
            }

            const isNoOrientation = this.currentSortingOrientation === '';
            const sort = isNoOrientation ? new IncidentSorting() : new IncidentSorting(this.currentSortingParameter, this.currentSortingOrientation);
            this._incidentService.applySorting(sort);
        }
    }

    public removeFocusFromActiveElement(): void {
        (document.activeElement as HTMLElement)?.blur();
    }

    public onScrollDown(): void {
        if (this._incidentService.isLastPage()) return;
        this.isScrollEnabled = false;
        this.direction = 'DOWN';
        this._incidentService.nextPage();
        this.saveScrollPosition();
    }

    public onScrollUp(): void {
        if (this._incidentService.isFirstPage()) return;
        this.isScrollEnabled = false;
        this.direction = 'UP';
        this._incidentService.previousPage();
        this.saveScrollPosition();
    }

    public saveScrollPosition(): void {
        const container = this.incidentListContainer?.nativeElement?.querySelector(this.incidentComponentParameter.infiniteScrollContainer) as HTMLElement;
        //we do -1 pixel to cause a the incident list to NOT disappear on incident trigger or state change.
        this.savedScrollPosition = container?.scrollTop - 1 ?? 0;
    }

    public openContextMenu(data: MCIncident, event: Event | null | undefined): void {
        if (event) {
            const mouseEvent = event as MouseEvent;
            this.preventDefaultContext(mouseEvent);
            this.selectedIncident = data;
            this.selectIncident(data.id);
            const contextPosition = { x: mouseEvent.x ?? 0, y: mouseEvent.y ?? 0 };

            // Retrieve the actual values.
            this._incidentActionService
                .getActions(data)
                .pipe(take(1), untilDestroyed(this))
                .subscribe((itemList: ContextMenuItem[]) => {
                    if (itemList.length > 0) {
                        this._contextMenuFactory.showMenuAsync(this._viewContainerRef, itemList, contextPosition).fireAndForget();
                    }
                });
        }
    }

    public preventDefaultContext(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private selectIncident(id: IGuid | string | null): void {
        const incidentId = id ? toGuid(id) : null;

        this.incidentTable?.gridApi?.forEachNode((node) => node.selectThisNode(!!incidentId && node.data instanceof MCIncident && node.data.id.equals(incidentId)));
    }

    private arrangeSorting(): void {
        //create array of all the headers that match the class
        if (this?.incidentListContainer?.nativeElement && this.allLabels.length === 0) {
            this.allLabels = Array.from(this?.incidentListContainer.nativeElement.querySelectorAll('.ag-cell-label-container') as NodeListOf<HTMLElement>);
            //iterate thru each label class
            this.allLabels
                .filter((e) => !!this.supportedSorting[e.innerText])
                .forEach((labelCell: HTMLElement) => {
                    //create HTML element from template
                    const sortLabeltemplate = this.sortingLabelTemplate?.createEmbeddedView({
                        key: labelCell.innerText,
                        label: this.translate(labelCell.innerText),
                    }) as EmbeddedViewRef<HTMLElement>;
                    sortLabeltemplate.detectChanges();
                    labelCell.innerHTML = '';
                    //append the template to the label location
                    labelCell.appendChild(sortLabeltemplate.rootNodes[0]);
                });
            this.currentSortingOrientation = '';
            this.currentSortingParameter = 'displayId';
        }
    }

    private arrangeScrollbar(): void {
        if (!this.containsNewData) return;
        this.containsNewData = false;
        const container = this.incidentListContainer.nativeElement.querySelector(this.incidentComponentParameter.infiniteScrollContainer) as HTMLElement;
        if (container == null) return;
        if (this.direction === 'NONE') container.scrollTop = this.savedScrollPosition;
        else if (this.direction === 'UP') container.scrollTop = container.scrollHeight * 0.5;
        else if (this.direction === 'DOWN') container.scrollTop = this.savedScrollPosition * 0.5 - container.offsetHeight / 2;
        this.direction = 'NONE';
        this.isScrollEnabled = true;
    }

    private addEventsForIncidentTable(table: GenMeltedTableComponent) {
        this.incidentTable = table;
        if (!this.incidentTable?.gridApi) return;
        this.incidentTable.gridApi.removeEventListener('modelUpdated', this.eventModelUpdated.bind(this));
        this.incidentTable.gridApi.removeEventListener('virtualColumnsChanged', this.eventVirtualColumnsChange.bind(this));
        this.incidentTable.gridApi.removeEventListener('gridSizeChanged', this.eventGridSizeChange.bind(this));
        this.incidentTable.gridApi.addEventListener('modelUpdated', this.eventModelUpdated.bind(this));
        this.incidentTable.gridApi.addEventListener('virtualColumnsChanged', this.eventVirtualColumnsChange.bind(this));
        this.incidentTable.gridApi.addEventListener('gridSizeChanged', this.eventGridSizeChange.bind(this));
    }
}
