<gen-melted-modal
    id="expirynotificationPeriod"
    genTitle="Expiry notification period"
    genDefaultActionText="Change password"
    genAlternativeActionText="Close"
    [genOnDefaultAction]="openChangePasswordModal"
    [genCanClose]="true"
    [genIsBlocking]="true"
>
    <gen-melted-modal-body>
        <div *ngIf="daysRemaining === 1; else numberDaysLeft">{{ 'STE_LABEL_PASS_EXPIRE_TOMORROW' | translate }}</div>
    </gen-melted-modal-body>
</gen-melted-modal>

<ng-template #numberDaysLeft>
    {{ 'STE_LABEL_PASS_EXPIRE_IN_X_DAYS' | translate | stringFormat: [daysRemaining] }}
</ng-template>
