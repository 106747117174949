import { Attribute } from './Attribute';
import { Program } from './Program';

export class Buffer {
  private readonly m_program: Program;
  private readonly m_buffer: WebGLBuffer;

  protected get gl(): WebGL2RenderingContext {
    return this.m_program.gl;
  }

  public get WebGLBuffer(): WebGLBuffer {
    return this.m_buffer;
  }

  public constructor(program: Program) {
    this.m_program = program;
    this.m_buffer = program.createWebGLBuffer();
  }

  public dispose() {
    this.m_program.deleteBuffer(this.m_buffer);
  }

  public bind() {
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.m_buffer);
  }
}

export class BufferWithAttribute extends Buffer {
  protected readonly m_attribute: Attribute;

  constructor(program: Program, attributeName: string) {
    super(program);
    this.m_attribute = new Attribute(program, attributeName);
    this.gl.enableVertexAttribArray(this.m_attribute.Location);
  }
}
