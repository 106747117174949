import { CreateFeatureFlag, CreateFeatureFlagGroup } from '@modules/feature-flags/feature-flag';

export const AccessControlFeatureFlags = CreateFeatureFlagGroup({
    General: CreateFeatureFlag('Flag.AccessControl', true),
    Cardholders: CreateFeatureFlag('Flag.AccessControl.Cardholders', true),
    AddCardholders: CreateFeatureFlag('Flag.AccessControl.AddCardholders', true),
    CardholdersEditPane: CreateFeatureFlag('Flag.AccessControl.CardholdersEditPane', true),
    CardholderEditPicture: CreateFeatureFlag('Flag.AccessControl.CardholdersEditPane.EditPicture', false),
    EditPaneCreateCredentials: CreateFeatureFlag('Flag.AccessControl.CardholdersEditPane.CreateCredentials', false),
});
