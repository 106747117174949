import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageFit, ImageFlavor, TextFlavor } from '@genetec/gelato';
import { IMobileApplicationEntity } from 'RestClient/Client/Interface/IMobileApplicationEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { MobileApplicationEntity } from 'RestClient/Client/Model/Application/MobileApplicationEntity';
import { Coordinate } from 'RestClient/Client/Model/MapEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { GeocodeReport } from 'RestClient/Client/Reports/GeocodeReport';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { MapContentTypes } from '@modules/maps/enumerations/maps-content-types';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';

@InternalContentPluginDescriptor({
    type: MobileUserWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: MobileUserWidgetComponent.pluginId, priority: 1 },
    isContentSupported: (content: Content) => !!content?.type.equals(MapContentTypes.MobileUser),
})
@Component({
    selector: 'app-mobile-user-widget',
    templateUrl: './mobile-user-widget.component.html',
    styleUrls: ['./mobile-user-widget.component.scss'],
})
export class MobileUserWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    //#region Constants

    public static pluginId = SafeGuid.parse('8B9F319F-0E7C-4932-8F6C-835DFBC6D03F');

    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly TextFlavor = TextFlavor;

    //#endregion

    //#region Fields

    public dataContext?: ContentService;
    public content?: Content;
    public isLoading = true;

    // displayed fields
    public thumbnailPicture: string | undefined = undefined;
    public username = '';
    public email = '';
    public lastPosition = '';

    private scClient!: SecurityCenterClient;
    private user: IUserEntity | null = null;
    private mobileUser: MobileApplicationEntity | null = null;

    protected get monitoredFields(): string[] {
        return [];
    }

    //#endregion

    //#region Constructor

    constructor(securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService, private sanitizer: DomSanitizer) {
        super(trackingService);
        this.scClient = securityCenterClientService?.scClient;
    }

    //#endregion

    //#region Methods

    async ngOnInit() {
        if (this.content?.source) {
            if (SafeGuid.isGuid(this.content.source)) {
                const source = SafeGuid.parse(this.content.source);
                if (!source.isEmpty()) {
                    await this.getUserAsync(source);
                }
            }
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    private async getUserAsync(mobileApplicationId: IGuid) {
        try {
            if (this.scClient) {
                this.mobileUser = (await this.scClient.getEntityAsync<MobileApplicationEntity, IMobileApplicationEntity>(
                    MobileApplicationEntity,
                    mobileApplicationId,
                    null,
                    null,
                    '*'
                )) as MobileApplicationEntity;

                if (this.mobileUser) {
                    const userId = this.mobileUser.loggedUser.isEmpty() ? this.mobileUser.lastLoggedUser : this.mobileUser.loggedUser;
                    this.user = await this.scClient.getEntityAsync<UserEntity, IUserEntity>(UserEntity, userId);
                    if (this.user) {
                        // resolve the username to display
                        this.username = this.user.name;
                        if (this.user.firstName && this.user.lastName) {
                            this.username = `${this.user.firstName} ${this.user.lastName}`;
                        }

                        this.email = this.user.emailAddress;
                        this.fetchAddress();
                        this.fetchThumbnail();
                    }
                }
            }
        } finally {
            this.isLoading = false;
        }
    }

    private fetchAddress() {
        if (this.mobileUser) {
            // go fetch the address
            this.retrieveAddressAsync(this.mobileUser.latitude, this.mobileUser.longitude)
                .then((x) => (this.lastPosition = x))
                .fireAndForget();
        }
    }

    private fetchThumbnail() {
        if (this.user) {
            const pictureId = this.user.thumbnailId;
            if (pictureId && !pictureId.isEmpty()) {
                this.user
                    .getFilesCacheAsync(pictureId)
                    .then((fileCache) => {
                        if (fileCache !== null) {
                            this.thumbnailPicture = 'data: image/png;base64,' + fileCache.data;
                        }
                    })
                    .fireAndForget();
            }
        }
    }

    private async retrieveAddressAsync(lat: number, lng: number): Promise<string> {
        if (lat !== 0 && lng !== 0) {
            const report = new GeocodeReport();
            const coor = new Coordinate();
            coor.latitude = lat;
            coor.longitude = lng;
            coor.altitude = 0;
            report.coordinates.add(coor);

            const result = await report.queryAsync(this.scClient);
            if (result != null && result.geocodingResults.length > 0) {
                const address = result.geocodingResults[0].address;
                if (address) {
                    return address;
                }
            }
            return `${lat}, ${lng}`;
        }
        return '';
    }

    //#endregion
}
