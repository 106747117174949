import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { CommandsSubscription, ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { RecordingState } from 'RestClient/Client/Enumerations/RecordingStates';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { CameraEntityFields, ICameraEntity } from 'RestClient/Client/Interface/ICameraEntity';
import { IEntityCacheTask, modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CameraEntity } from 'RestClient/Client/Model/Video/CameraEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { IGuid, SafeGuid } from 'safeguid';

@Component({
    selector: 'app-camera-base-component',
    template: '',
})
export class CameraBaseComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    private static cameraFields = [
        CameraEntityFields.nameField,
        CameraEntityFields.recordingStateField,
        CameraEntityFields.runningStateField,
        CameraEntityFields.customIconIdField,
        CameraEntityFields.maintenanceField,
    ];

    public dataContext?: ContentService;
    public readonly excludedCommandIds = [SharedCommands.AddBookmark, SharedCommands.StartStopRecording];
    public content?: Content;
    public isLoading = true;
    public recordingState = RecordingState.Off;
    public cameraRunningState = RunningState.NotRunning;
    public cameraCustomIconId?: IGuid;
    public isMaintenanceModeActive?: boolean;

    private cameraEntity: CameraEntity | null = null;
    private entityCacheTask?: IEntityCacheTask;
    private commandsSubscription!: CommandsSubscription;

    public get hasWarnings(): boolean {
        return this.cameraRunningState === RunningState.Warning;
    }

    public get isOffline(): boolean {
        return this.cameraRunningState === RunningState.NotRunning;
    }

    public get isRecording(): boolean {
        return this.isRecordingState(this.recordingState);
    }

    public get recordingText(): string {
        if (this.isRecording) {
            return this.translateService.instant('STE_LABEL_RECORDING') as string;
        } else {
            return this.translateService.instant('STE_LABEL_NOT_RECORDING') as string;
        }
    }

    public get statusText(): string {
        if (this.isOffline) {
            return this.translateService.instant('STE_LABEL_OFFLINE') as string;
        } else if (this.hasWarnings) {
            return this.translateService.instant('STE_LABEL_WARNING') as string;
        } else {
            return this.translateService.instant('STE_LABEL_ONLINE') as string;
        }
    }

    constructor(private securityCenterClientService: SecurityCenterClientService, private translateService: TranslateService, trackingService: TrackingService) {
        super(trackingService);
    }

    public async ngOnDestroy(): Promise<void> {
        if (this.entityCacheTask) {
            await this.entityCacheTask.dispose();
        }

        if (this.commandsSubscription) {
            this.commandsSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();

        const scClient = this.securityCenterClientService?.scClient;
        if (scClient) {
            this.entityCacheTask = scClient.buildEntityCache(CameraBaseComponent.cameraFields);

            if (this.entityCacheTask && this.content) {
                this.cameraEntity = (await this.entityCacheTask.getEntityAsync<CameraEntity, ICameraEntity>(
                    CameraEntity,
                    SafeGuid.parse(this.content.source),
                    true
                )) as CameraEntity;
                if (this.cameraEntity) {
                    this.recordingState = this.cameraEntity.recordingState;
                    this.cameraRunningState = this.cameraEntity.runningState;
                    this.cameraCustomIconId = this.cameraEntity.customIconId;
                    this.isMaintenanceModeActive = this.cameraEntity.maintenance;

                    await this.entityCacheTask.detectFieldChangeAsync(
                        this.cameraEntity,
                        () => {
                            const _ = this.cameraEntity?.recordingState;
                        },
                        ((_: ICameraEntity, newValue: string, __: string) => {
                            this.recordingState = newValue;
                            return new Deferred<void>(true).promise;
                        }) as modificationHandlerField<string>
                    );

                    await this.entityCacheTask.detectFieldChangeAsync(
                        this.cameraEntity,
                        () => {
                            const _ = this.cameraEntity?.runningState;
                        },
                        ((_: ICameraEntity, newValue: string, __: string) => {
                            this.cameraRunningState = newValue;
                            return new Deferred<void>(true).promise;
                        }) as modificationHandlerField<string>
                    );

                    await this.entityCacheTask.detectFieldChangeAsync(
                        this.cameraEntity,
                        () => {
                            const _ = this.cameraEntity?.maintenance;
                        },
                        ((_: ICameraEntity, newValue: boolean, __: boolean) => {
                            this.isMaintenanceModeActive = newValue;
                            return new Deferred<void>(true).promise;
                        }) as modificationHandlerField<boolean>
                    );

                    await this.entityCacheTask.detectFieldChangeAsync(
                        this.cameraEntity,
                        () => {
                            const _ = this.cameraEntity?.customIconId;
                        },
                        ((_: ICameraEntity, newValue: string, __: string) => {
                            this.cameraCustomIconId = SafeGuid.parse(newValue);
                            return new Deferred<void>(true).promise;
                        }) as modificationHandlerField<string>
                    );
                }
            }
            this.isLoading = false;
        }
    }

    public setDataContext(context: ContentService): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    private isRecordingState(recordingState?: RecordingState): boolean {
        let state = recordingState;
        if (state === undefined) {
            state = this.recordingState;
        }
        return state === RecordingState.On || state === RecordingState.OnLocked || state === RecordingState.OnAboutToStop;
    }
}
