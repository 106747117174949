// Promise extensions
export {};

declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Promise<T> {
        /**
         * Catches any error that the promise might trigger, essentially a way to handle a promise without caring for the results or waiting for it to complete.
         *
         * _Note_: Use intelligently and make sure the exceptions are handled properly in the promise itself!
         */
        fireAndForget(): void;
    }
}

if (!Promise.prototype.fireAndForget) {
    Promise.prototype.fireAndForget = function <T>(this: Promise<T>) {
        this.catch((e) => {
            console.error(e);
        });
    };
}
