import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonFlavor } from '@genetec/gelato';
import { Watchlist } from '@modules/shared/api/api';
import { EventMonitoringContext } from '@modules/shared/enumerations/analytics-event-monitoring-context';
import { EventMonitoringContentTypes } from '@modules/shared/enumerations/event-monitoring-content-types';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { EventMonitoringLoggerService } from '@modules/shared/services/analytics/event-monitoring-logger.service';
import { EventMonitoringFeatureFlags } from '@modules/shared/services/events/feature-flags';
import { UserWatchlistRequestService } from '@modules/shared/services/watchlist/user-watchlist-request.service';
import { UserWatchlistService } from '@modules/shared/services/watchlist/user-watchlist.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';

@InternalContentPluginDescriptor({
    type: WatchlistComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: WatchlistComponent.pluginId, priority: 0, standalone: true },
    isContentSupported: (content: Content) => !!content?.type?.equals(EventMonitoringContentTypes.WatchList),
    requirements: { enabledFeatureFlags: [EventMonitoringFeatureFlags.General] },
})
@Component({
    selector: 'app-watchlist',
    templateUrl: './watchlist.component.html',
    styleUrls: ['./watchlist.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchlistComponent implements OnInit {
    public static pluginId = SafeGuid.parse('4b8249cd-1ec4-4be1-b2fd-fbb3a83ed63e');
    public ButtonFlavor = ButtonFlavor;
    public watchlist$!: Observable<Watchlist | null>;
    public hasWatchlistEntries$!: Observable<boolean>;

    constructor(
        private userWatchlistService: UserWatchlistService,
        private userWatchlistRequestService: UserWatchlistRequestService,
        private eventMonitoringLoggerService: EventMonitoringLoggerService
    ) {}

    ngOnInit(): void {
        this.watchlist$ = this.userWatchlistService.watchlist$.pipe(shareReplay(1));
        this.hasWatchlistEntries$ = this.watchlist$.pipe(map((watchlist) => (watchlist?.entries?.length ?? 0) > 0));
        this.eventMonitoringLoggerService.logEvent('Opened watchlist', 'Watchlist opened from the watchlist tray icon', EventMonitoringContext.Watchlist);
    }

    public async onAddItemsClick(): Promise<void> {
        await this.userWatchlistRequestService.showWatchlistEditorAsync();
        this.eventMonitoringLoggerService.logEvent('Opened edit watchlist', 'Add item button clicked when no entities are being monitored', EventMonitoringContext.Watchlist);
    }
}
