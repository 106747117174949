<app-entity-commands-widget-host [content]="content" [isOwnerLoading]="isLoading" [displayContext]="dataContext?.displayContext">
    <gen-text primaryState [flavor]="isActive ? TextFlavor.Success : TextFlavor.Error" class="ha-center">{{
        (isActive ? 'STE_LABEL_CARDHOLDER_STATUS_ACTIVE' : 'STE_LABEL_CARDHOLDER_STATUS_INACTIVE') | translate
    }}</gen-text>
    <gen-flex additionnalInfo *ngIf="displayLastAccess" class="justify-content-center">
        <gen-text>
            <span class="fg-color-secondary-dark">{{ 'STE_LABEL_CARDHOLDER_LASTSEEN' | translate }}:&nbsp;</span>
        </gen-text>
        <gen-text>{{ 'STE_LABEL_FORMAT_LASTSEEN_AT_LOCATION_X_AT_Y_OCLOCK' | translate | stringFormat: lastAccessLocation:lastAccessTime }}</gen-text>
    </gen-flex>
</app-entity-commands-widget-host>
