import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon, ImageFit, ImageFlavor, TextFlavor } from '@genetec/gelato';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';

@Component({
    selector: 'app-cardholder-card',
    templateUrl: './cardholder-card.component.html',
    styleUrls: ['./cardholder-card.component.scss'],
})
export class CardholderCardComponent extends TrackedComponent {
    @Input() public entityName?: string;
    @Input() public groups?: string[];
    @Input() public picture?: string;
    @Input() public guid?: string;
    @Input() public isSelected = false;
    @Input() public isViewCardholdersPrivilegeGranted = false;

    @Output() itemSelected: EventEmitter<{ guid: string; picture?: string }> = new EventEmitter<{ guid: string; picture?: string }>();

    public readonly Icon = Icon;
    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly TextFlavor = TextFlavor;

    private accessStatus?: AccessStatus;

    @Input()
    public set status(value: AccessStatus) {
        this.accessStatus = value;
    }
    public get status(): AccessStatus {
        return this.accessStatus ?? '';
    }

    public onCardholderSelected(): void {
        if (this.guid) {
            this.itemSelected.emit({ guid: this.guid, picture: this.picture });
        }
    }
}
