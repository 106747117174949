// Autogenerated: v2/help/Classes/ApiModifiedIncidentEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:12:11 AM
import { RoutableEvent } from './RoutableEvent';
import { IModifiedIncidentEvent } from '../Interface/IModifiedIncidentEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

export class ModifiedIncidentEvent extends RoutableEvent implements IModifiedIncidentEvent {
    // #region REST Properties

    // IncidentId
    public get incidentId(): IGuid {
        return this.getFieldGuid('IncidentId');
    }
    public set incidentId(value: IGuid) {
        this.setFieldGuid('IncidentId', value);
    }

    // IncidentNote
    public get incidentNote(): string {
        return this.getField<string>('IncidentNote');
    }
    public set incidentNote(value: string) {
        this.setField<string>('IncidentNote', value);
    }

    // IncidentTimestamp
    public get incidentTimestamp(): Date {
        return this.getFieldDate('IncidentTimestamp');
    }
    public set incidentTimestamp(value: Date) {
        this.setFieldDate('IncidentTimestamp', value);
    }

    // IncidentTitle
    public get incidentTitle(): string {
        return this.getField<string>('IncidentTitle');
    }
    public set incidentTitle(value: string) {
        this.setField<string>('IncidentTitle', value);
    }

    // IsDeleted
    public get isDeleted(): boolean {
        return this.getField<boolean>('IsDeleted');
    }
    public set isDeleted(value: boolean) {
        this.setField<boolean>('IsDeleted', value);
    }

    // Latitude
    public get latitude(): number | null {
        return this.getNullableField<number>('Latitude');
    }
    public set latitude(value: number | null) {
        this.setNullableField<number>('Latitude', value);
    }

    // Longitude
    public get longitude(): number | null {
        return this.getNullableField<number>('Longitude');
    }
    public set longitude(value: number | null) {
        this.setNullableField<number>('Longitude', value);
    }

    // ReferencedEntities
    public get referencedEntities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('ReferencedEntities');
    }
    public set referencedEntities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('ReferencedEntities', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('IncidentId', SafeGuid.EMPTY);
        this.setField<string>('IncidentNote', '');
        this.setFieldDate('IncidentTimestamp', new Date());
        this.setField<string>('IncidentTitle', '');
        this.setField<boolean>('IsDeleted', false);
        this.setField<number>('Latitude', 0);
        this.setField<number>('Longitude', 0);
        this.setFieldArrayGuid('ReferencedEntities', new ObservableCollection<IGuid>());
    }

    // #endregion Methods

}

