import { ILiteEvent, LiteEvent } from '../../utils/liteEvents';
import { SegmentInfo } from '../../services/eventHub';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { ILogger } from '../../utils/logger';
import { MseHtmlVideoElement } from './MseHtmlVideoElement';
import { Utils } from '../../utils/Utils';
import { ZoomConfiguration } from '../ZoomConfiguration';
import { IRenderer } from '../IRenderer';

export interface IMseRenderer extends IRenderer {
  timeUpdated: ILiteEvent<number>;
  errorOccurred: ILiteEvent<void>;
  readonly FrameRendered: Promise<void>;
  reset(): void;
  render(info: SegmentInfo, buffer: Int8Array): void;
  readonly initialization: Promise<void>;
}

export class MseRenderer implements IMseRenderer {
  private readonly m_logger: ILogger;

  private readonly m_videoElement: MseHtmlVideoElement;

  private readonly m_timeUpdateEvent = new LiteEvent<number>();

  private readonly m_errorOccurred = new LiteEvent<void>();

  public get timeUpdated() {
    return this.m_timeUpdateEvent.expose();
  }

  public get errorOccurred() {
    return this.m_errorOccurred.expose();
  }

  public get FrameRendered(): Promise<void> {
    return this.m_videoElement.FrameRendered;
  }

  public get streamResolution() {
    return this.m_videoElement.streamResolution;
  }

  public get tileResolution() {
    return this.m_videoElement.tileResolution;
  }

  public get initialization(): Promise<void> {
    return this.m_videoElement.initialization;
  }

  constructor(logger: ILogger, videoElement: MseHtmlVideoElement) {
    this.m_logger = logger.subLogger('MseRenderer');
    this.m_videoElement = videoElement;
    this.m_videoElement.timeUpdated.register(this.onTimeUpdated);
    this.m_videoElement.errorOccurred.register(this.onErrorOccurred);
  }

  private readonly onTimeUpdated = (mediaTime: number) => {
    this.m_timeUpdateEvent.trigger(mediaTime);
  }

  private readonly onErrorOccurred = () => {
    this.m_errorOccurred.trigger();
  }

  public dispose(): void {
    this.m_logger.info?.trace('dispose()');
    this.m_videoElement.timeUpdated.unregister(this.onTimeUpdated);
    this.m_videoElement.errorOccurred.unregister(this.onErrorOccurred);
    this.m_videoElement.dispose();
  }

  public async reset() {
    this.m_logger.info?.trace('reset()');
    await this.m_videoElement.reset();
  }

  public start(): void {
    this.m_logger.debug?.trace('start()');
    this.m_videoElement.play();
  }

  public stop(): void {
    this.m_logger.debug?.trace('stop()');
    this.m_videoElement.pause();
  }

  public render(info: SegmentInfo, buffer: Int8Array): void {
    this.m_logger.intense?.trace(info.IsKeyFrame ? 'K': 'P', 'Frame', info.FrameTime, 'converted to date: ', Utils.formatDateUTC(info.FrameTimeAsDate), 'mediaTime:', info.MediaTime, 'baseTime:', info.BaseDecodeTime);
    this.m_videoElement.decode(info, buffer);
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig) {
    this.m_videoElement.updateVideoWatermarkingConfig(videoWatermarkingConfig);
  }

  public updateZoomConfig(zoomConfig: ZoomConfiguration): void {
    this.m_videoElement.updateZoomConfig(zoomConfig);
  }

  public setPlayerActive(isPlayerActive: boolean): void {
    this.m_videoElement.setPlayerActive(isPlayerActive);
  }

  public getSnapshot(fromZoomedImage: boolean = false): ImageData | null {
    return this.m_videoElement.getSnapshot(fromZoomedImage);
  }

  public debugStatus(indent: number): string {
    return 'MseRenderer' + Utils.indentNewLine(indent) +
      this.m_videoElement.debugStatus(indent + Utils.Indentation);
  }
}
