import { IGuid } from 'safeguid';
import { CommandProvider } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { EntityCommandContext } from './entity-command-context';

export interface EntityCommandProvider extends CommandProvider {
    requiredEntityFields: Set<string>;
    getAvailableCommandIdsAsync(commandContextArgs: EntityCommandContext): Promise<IGuid[]>;
}

export const isEntityCommandProvider = (instance: unknown): instance is EntityCommandProvider => {
    return isOfType<EntityCommandProvider>(instance, 'requiredEntityFields');
};
