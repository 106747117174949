// Autogenerated: v2/help/Entities/ApiDoorTemplate?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:02 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { IDoorTemplateEntity, ICardAndPinSetting, IAccessRulesConfig, IAccessPointsInfo, DoorTemplateEntityFields } from '../../Interface/IDoorTemplateEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IQueryFilter } from '../../Interface/IQueryFilter';

// #region Inner classes

export class CardAndPinSetting extends FieldObject implements ICardAndPinSetting {
    // CardAndPinCoverage
    public get cardAndPinCoverage(): IGuid {
        return this.getFieldGuid('CardAndPinCoverage');
    }
    public set cardAndPinCoverage(value: IGuid) {
        this.setFieldGuid('CardAndPinCoverage', value);
    }
    // CardAndPinTimeout
    public get cardAndPinTimeout(): number {
        return this.getField<number>('CardAndPinTimeout');
    }
    public set cardAndPinTimeout(value: number) {
        this.setField<number>('CardAndPinTimeout', value);
    }
    // Side
    public get side(): string {
        return this.getField<string>('Side');
    }
    public set side(value: string) {
        this.setField<string>('Side', value);
    }
    // ReaderMode
    public get readerMode(): string {
        return this.getField<string>('ReaderMode');
    }
    public set readerMode(value: string) {
        this.setField<string>('ReaderMode', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('CardAndPinCoverage', SafeGuid.EMPTY);
        this.setField<number>('CardAndPinTimeout', 0);
        this.setField<string>('Side', '');
        this.setField<string>('ReaderMode', '');
    }
}

export class AccessRulesConfig extends FieldObject implements IAccessRulesConfig {
    // AccessRule
    public get accessRule(): IGuid {
        return this.getFieldGuid('AccessRule');
    }
    public set accessRule(value: IGuid) {
        this.setFieldGuid('AccessRule', value);
    }
    // Side
    public get side(): string {
        return this.getField<string>('Side');
    }
    public set side(value: string) {
        this.setField<string>('Side', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('AccessRule', SafeGuid.EMPTY);
        this.setField<string>('Side', '');
    }
}

export class AccessPointsInfo extends FieldObject implements IAccessPointsInfo {
    // DeviceId
    public get deviceId(): string {
        return this.getField<string>('DeviceId');
    }
    public set deviceId(value: string) {
        this.setField<string>('DeviceId', value);
    }
    // Side
    public get side(): string {
        return this.getField<string>('Side');
    }
    public set side(value: string) {
        this.setField<string>('Side', value);
    }
    // Type
    public get type(): string {
        return this.getField<string>('Type');
    }
    public set type(value: string) {
        this.setField<string>('Type', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('DeviceId', '');
        this.setField<string>('Side', '');
        this.setField<string>('Type', '');
    }
}

// #endregion Inner classes

export class DoorTemplateEntity extends Entity implements IDoorTemplateEntity {
    // #region REST Properties

    // DoorForcedBehavior
    public get doorForcedBehavior(): string {
        return this.getField<string>(DoorTemplateEntityFields.doorForcedBehaviorField);
    }
    public set doorForcedBehavior(value: string) {
        this.setField<string>(DoorTemplateEntityFields.doorForcedBehaviorField, value);
    }

    // DoorHeldBehavior
    public get doorHeldBehavior(): string {
        return this.getField<string>(DoorTemplateEntityFields.doorHeldBehaviorField);
    }
    public set doorHeldBehavior(value: string) {
        this.setField<string>(DoorTemplateEntityFields.doorHeldBehaviorField, value);
    }

    // DoorHeldOpenDelay
    public get doorHeldOpenDelay(): number {
        return this.getField<number>(DoorTemplateEntityFields.doorHeldOpenDelayField);
    }
    public set doorHeldOpenDelay(value: number) {
        this.setField<number>(DoorTemplateEntityFields.doorHeldOpenDelayField, value);
    }

    // DoorRelockDelay
    public get doorRelockDelay(): number {
        return this.getField<number>(DoorTemplateEntityFields.doorRelockDelayField);
    }
    public set doorRelockDelay(value: number) {
        this.setField<number>(DoorTemplateEntityFields.doorRelockDelayField, value);
    }

    // DoorRelockOnClose
    public get doorRelockOnClose(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.doorRelockOnCloseField);
    }
    public set doorRelockOnClose(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.doorRelockOnCloseField, value);
    }

    // EnforceVisitorEscortRulesAlphaSide
    public get enforceVisitorEscortRulesAlphaSide(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.enforceVisitorEscortRulesAlphaSideField);
    }
    public set enforceVisitorEscortRulesAlphaSide(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.enforceVisitorEscortRulesAlphaSideField, value);
    }

    // EnforceVisitorEscortRulesBetaSide
    public get enforceVisitorEscortRulesBetaSide(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.enforceVisitorEscortRulesBetaSideField);
    }
    public set enforceVisitorEscortRulesBetaSide(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.enforceVisitorEscortRulesBetaSideField, value);
    }

    // ExtendedEntryTime
    public get extendedEntryTime(): number {
        return this.getField<number>(DoorTemplateEntityFields.extendedEntryTimeField);
    }
    public set extendedEntryTime(value: number) {
        this.setField<number>(DoorTemplateEntityFields.extendedEntryTimeField, value);
    }

    // ExtendedGrantTime
    public get extendedGrantTime(): number {
        return this.getField<number>(DoorTemplateEntityFields.extendedGrantTimeField);
    }
    public set extendedGrantTime(value: number) {
        this.setField<number>(DoorTemplateEntityFields.extendedGrantTimeField, value);
    }

    // GrantOnRex
    public get grantOnRex(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.grantOnRexField);
    }
    public set grantOnRex(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.grantOnRexField, value);
    }

    // InterfaceModuleDeviceDefinition
    public get interfaceModuleDeviceDefinition(): IGuid {
        return this.getFieldGuid(DoorTemplateEntityFields.interfaceModuleDeviceDefinitionField);
    }
    public set interfaceModuleDeviceDefinition(value: IGuid) {
        this.setFieldGuid(DoorTemplateEntityFields.interfaceModuleDeviceDefinitionField, value);
    }

    // IsAccessRulesEnabled
    public get isAccessRulesEnabled(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.isAccessRulesEnabledField);
    }
    public set isAccessRulesEnabled(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.isAccessRulesEnabledField, value);
    }

    // IsDefault
    public get isDefault(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.isDefaultField);
    }
    public set isDefault(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.isDefaultField, value);
    }

    // IsDoorPropertiesEnabled
    public get isDoorPropertiesEnabled(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.isDoorPropertiesEnabledField);
    }
    public set isDoorPropertiesEnabled(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.isDoorPropertiesEnabledField, value);
    }

    // IsHardwareMappingEnabled
    public get isHardwareMappingEnabled(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.isHardwareMappingEnabledField);
    }
    public set isHardwareMappingEnabled(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.isHardwareMappingEnabledField, value);
    }

    // IsHardwareSettingsEnabled
    public get isHardwareSettingsEnabled(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.isHardwareSettingsEnabledField);
    }
    public set isHardwareSettingsEnabled(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.isHardwareSettingsEnabledField, value);
    }

    // MaskAccessWhenUnlockedBySchedule
    public get maskAccessWhenUnlockedBySchedule(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.maskAccessWhenUnlockedByScheduleField);
    }
    public set maskAccessWhenUnlockedBySchedule(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.maskAccessWhenUnlockedByScheduleField, value);
    }

    // MaskDoorOpenTooLongWhenUnlockedBySchedule
    public get maskDoorOpenTooLongWhenUnlockedBySchedule(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.maskDoorOpenTooLongWhenUnlockedByScheduleField);
    }
    public set maskDoorOpenTooLongWhenUnlockedBySchedule(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.maskDoorOpenTooLongWhenUnlockedByScheduleField, value);
    }

    // MaximumDelayBetweenSwipes
    public get maximumDelayBetweenSwipes(): number {
        return this.getField<number>(DoorTemplateEntityFields.maximumDelayBetweenSwipesField);
    }
    public set maximumDelayBetweenSwipes(value: number) {
        this.setField<number>(DoorTemplateEntityFields.maximumDelayBetweenSwipesField, value);
    }

    // NormalEntryTime
    public get normalEntryTime(): number {
        return this.getField<number>(DoorTemplateEntityFields.normalEntryTimeField);
    }
    public set normalEntryTime(value: number) {
        this.setField<number>(DoorTemplateEntityFields.normalEntryTimeField, value);
    }

    // NormalGrantTime
    public get normalGrantTime(): number {
        return this.getField<number>(DoorTemplateEntityFields.normalGrantTimeField);
    }
    public set normalGrantTime(value: number) {
        this.setField<number>(DoorTemplateEntityFields.normalGrantTimeField, value);
    }

    // RexHoldOffAfterClosedDelay
    public get rexHoldOffAfterClosedDelay(): number {
        return this.getField<number>(DoorTemplateEntityFields.rexHoldOffAfterClosedDelayField);
    }
    public set rexHoldOffAfterClosedDelay(value: number) {
        this.setField<number>(DoorTemplateEntityFields.rexHoldOffAfterClosedDelayField, value);
    }

    // RexHoldOffAfterGrantDelay
    public get rexHoldOffAfterGrantDelay(): number {
        return this.getField<number>(DoorTemplateEntityFields.rexHoldOffAfterGrantDelayField);
    }
    public set rexHoldOffAfterGrantDelay(value: number) {
        this.setField<number>(DoorTemplateEntityFields.rexHoldOffAfterGrantDelayField, value);
    }

    // RexHoldoffWhileOpened
    public get rexHoldoffWhileOpened(): boolean {
        return this.getField<boolean>(DoorTemplateEntityFields.rexHoldoffWhileOpenedField);
    }
    public set rexHoldoffWhileOpened(value: boolean) {
        this.setField<boolean>(DoorTemplateEntityFields.rexHoldoffWhileOpenedField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.DoorTemplates);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessPointsInfo> | null> {
        return this.getFieldObjectRelationAsync<AccessPointsInfo, IAccessPointsInfo>(AccessPointsInfo, 'AccessPoints', 'Id', false, false, ValidFlags.None, transaction);
    }

    public async getAccessRulesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessRulesConfig> | null> {
        return this.getFieldObjectRelationAsync<AccessRulesConfig, IAccessRulesConfig>(AccessRulesConfig, 'AccessRules', 'Id', false, false, ValidFlags.None, transaction);
    }

    public async getAppliedDoorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AppliedDoors', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async applyAsync(doorGuid: IGuid, unitGuid: IGuid, interfaceGuid: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('doorGuid', doorGuid);
        inParam.setFieldGuid('unitGuid', unitGuid);
        inParam.setFieldGuid('interfaceGuid', interfaceGuid);

        return this.executeRequestTransactionAsync<IRestResponse>('Apply', 'POST', inParam, transaction, responseHandler);
    }

    public async getCardAndPinSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICardAndPinSetting> | null> {
        return this.getFieldObjectRelationAsync<CardAndPinSetting, ICardAndPinSetting>(CardAndPinSetting, 'CardAndPinSettings', 'Id', false, false, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

