// Autogenerated: CodeGen/TimelinePart?language=ts&InterfaceOnly=True on 2021-01-06 1:55:53 PM
// Autogenerated: CodeGen/TimelinePart?language=ts&InterfaceOnly=False on 2021-01-06 1:55:53 PM
// Autogenerated: CodeGen/TimelineToolTip?language=ts&InterfaceOnly=True on 2021-01-06 1:55:53 PM
// Autogenerated: CodeGen/TimelineToolTip?language=ts&InterfaceOnly=False on 2021-01-06 1:55:53 PM
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { IGuid, SafeGuid } from 'safeguid';

export interface ITimelinePart extends IFieldObject {
    id: IGuid;
    duration: number;
    isPersistent: boolean;
    priority: number;
    timestamp: Date;
    renderer: string;
    sourceId: IGuid;
    toolTip: ITimelineToolTip;
}

export class TimelinePart extends FieldObject implements ITimelinePart {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Duration
    public get duration(): number {
        return this.getField<number>('Duration');
    }
    public set duration(value: number) {
        this.setField<number>('Duration', value);
    }
    // IsPersistent
    public get isPersistent(): boolean {
        return this.getField<boolean>('IsPersistent');
    }
    public set isPersistent(value: boolean) {
        this.setField<boolean>('IsPersistent', value);
    }
    // Priority
    public get priority(): number {
        return this.getField<number>('Priority');
    }
    public set priority(value: number) {
        this.setField<number>('Priority', value);
    }
    // Timestamp
    public get timestamp(): Date {
        return this.getFieldDate('Timestamp');
    }
    public set timestamp(value: Date) {
        this.setFieldDate('Timestamp', value);
    }
    // Renderer
    public get renderer(): string {
        return this.getField<string>('Renderer');
    }
    public set renderer(value: string) {
        this.setField<string>('Renderer', value);
    }
    // SourceId
    public get sourceId(): IGuid {
        return this.getFieldGuid('SourceId');
    }
    public set sourceId(value: IGuid) {
        this.setFieldGuid('SourceId', value);
    }
    // ToolTip
    public get toolTip(): ITimelineToolTip {
        return this.getFieldObject<TimelineToolTip, ITimelineToolTip>(TimelineToolTip, 'ToolTip');
    }
    public set toolTip(value: ITimelineToolTip) {
        this.setFieldObject<ITimelineToolTip>('ToolTip', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<number>('Duration', 0);
        this.setField<boolean>('IsPersistent', false);
        this.setField<number>('Priority', 0);
        this.setFieldDate('Timestamp', new Date());
        this.setField<string>('Renderer', '');
        this.setFieldGuid('SourceId', SafeGuid.EMPTY);
        const objToolTip = new TimelineToolTip();
        objToolTip.initializeAllFields();
        this.setFieldObject('ToolTip', objToolTip);
    }
}

export interface ITimelineToolTip extends IFieldObject {
    title: string;
    description: string;
    navigation: string;
    picture: string;
}

export class TimelineToolTip extends FieldObject implements ITimelineToolTip {
    // Title
    public get title(): string {
        return this.getField<string>('Title');
    }
    public set title(value: string) {
        this.setField<string>('Title', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }
    // Navigation
    public get navigation(): string {
        return this.getField<string>('Navigation');
    }
    public set navigation(value: string) {
        this.setField<string>('Navigation', value);
    }
    // Picture
    public get picture(): string {
        return this.getField<string>('Picture');
    }
    public set picture(value: string) {
        this.setField<string>('Picture', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Title', '');
        this.setField<string>('Description', '');
        this.setField<string>('Navigation', '');
        this.setField<string>('Picture', '');
    }
}
