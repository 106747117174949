import { SafeGuid } from 'safeguid';

export const KnownPrivileges = Object.freeze({
    runConfigToolPrivilege: SafeGuid.parse('1b03c89e-0b02-4d68-b281-ef5a4f76146c'),
    runSecurityDeskPrivilege: SafeGuid.parse('5c9348a7-faa7-4aa1-b8a9-d0761a7be118'),
    sdkLogonPrivilege: SafeGuid.parse('7ab29b46-f363-4cad-9e05-1bcb9b65d49c'),
    webClientConnectionPrivilege: SafeGuid.parse('a21a5c23-d214-420d-a3d9-b146f2b4e589'),
    federationLogonPrivilege: SafeGuid.parse('5603c2ff-8ae6-41ad-9381-1d73f65150c0'),
    globalCardholderSynchronizerLogonPrivilege: SafeGuid.parse('a3b53d78-d8ec-4e96-a416-686adb6f7a08'),
    mobileClientLogonPrivilege: SafeGuid.parse('99d21656-4500-4044-b715-6737f65796b2'),
    patrollerLogonPrivilege: SafeGuid.parse('d15f80ae-3c83-47a1-ba82-8d3d83638bf6'),
    patrollerAccessReadsHitsPrivilege: SafeGuid.parse('9439076d-2ba1-4cea-9053-5f843483ce8c'),
    viewSchedulesPrivilege: SafeGuid.parse('2c144bd7-62c4-4b34-86e4-94065d9ab2c6'),
    modifySchedulesPrivilege: SafeGuid.parse('f58e60a8-96ab-461e-86c3-81fac184d186'),
    addSchedulesPrivilege: SafeGuid.parse('55744566-9300-4290-adde-27f7cdf79b73'),
    deleteSchedulesPrivilege: SafeGuid.parse('6958b88f-a415-4584-b372-79effd8360a8'),
    viewAccessControlUnitsPrivilege: SafeGuid.parse('061254aa-420f-4b93-834a-a5fe78ccb803'),
    accessControlUnitFirmwareUpgradePrivilege: SafeGuid.parse('1E1363B5-EE7D-4160-9BB4-289568D921F8'),
    modifyAccessControlUnitsPrivilege: SafeGuid.parse('d82d8205-27ea-43b4-883e-078c5f96c0df'),
    addAccessControlUnitsPrivilege: SafeGuid.parse('81658baa-eb21-4eb8-b8ff-0703fb636053'),
    deleteAccessControlUnitsPrivilege: SafeGuid.parse('d80b69a6-22e3-44bb-84da-483184075330'),
    resetAccessControlUnitsPrivilege: SafeGuid.parse('4ad98314-bbc8-4e34-add3-3e161ecf2f7c'),
    viewPatrollersPrivilege: SafeGuid.parse('c578093d-278f-4ddd-b1fd-b951176b301f'),
    modifyPatrollersPrivilege: SafeGuid.parse('55e8ecb2-38d8-4266-8bee-6985735f4435'),
    deletePatrollersPrivilege: SafeGuid.parse('62a7121a-045d-45da-a4bd-a8454c51bae0'),
    viewLprUnitsPrivilege: SafeGuid.parse('45d906c7-c003-415f-9817-1364ead1911e'),
    modifyLprUnitsPrivilege: SafeGuid.parse('6e845236-b4fb-4985-916f-add9576fef95'),
    addLprUnitsPrivilege: SafeGuid.parse('074901e5-6f0c-45ce-a4b1-40659f7b662e'),
    deleteLprUnitsPrivilege: SafeGuid.parse('bea73aba-85b0-479c-abaa-c5dadb7ccd89'),
    resetLprUnitsPrivilege: SafeGuid.parse('4eb74ff3-358b-4d3f-81a8-5f0db66e8191'),
    triggerReadLprUnitsPrivilege: SafeGuid.parse('90aab494-7a91-48b8-903e-0407ef365b4a'),
    viewPlatelistEntriesPrivilege: SafeGuid.parse('2dbd02c0-b1a8-11e2-9e96-0800200c9a66'),
    modifyPlatelistEntriesPrivilege: SafeGuid.parse('42da6fd0-b1a8-11e2-9e96-0800200c9a66'),
    deletePlatelistEntriesPrivilege: SafeGuid.parse('6a678f10-b1a8-11e2-9e96-0800200c9a66'),
    addPlatelistEntriesPrivilege: SafeGuid.parse('ae37ad50-b1a9-11e2-9e96-0800200c9a66'),
    viewCardholdersPrivilege: SafeGuid.parse('db9d9c99-f571-4202-b768-d5585b6e1614'),
    modifyCardholdersPrivilege: SafeGuid.parse('af0902fb-34ba-427a-b246-52b5643b3d4d'),
    addCardholdersPrivilege: SafeGuid.parse('2cb1308f-1e15-4a5d-897f-876004020366'),
    deleteCardholdersPrivilege: SafeGuid.parse('b50a0b91-eddd-4c60-8725-744c304210b5'),
    viewCardholderGroupsPrivilege: SafeGuid.parse('b4ce6ce5-a190-48e4-b5bc-0b37a0716133'),
    changeCardholderOptionsPrivilege: SafeGuid.parse('dbc4ac46-af54-416c-8494-a4393899f5b0'),
    modifyCardholderGroupsPrivilege: SafeGuid.parse('7e6d62f7-abeb-4a41-b42e-1c7f2942c42e'),
    addCardholderGroupsPrivilege: SafeGuid.parse('760a7174-939f-425a-aeb1-993597d746d7'),
    deleteCardholderGroupsPrivilege: SafeGuid.parse('28f168c1-e170-4f65-9e7d-b379aeeb9209'),
    modifyCardholderGroupCustomFieldsPrivilege: SafeGuid.parse('c3942c62-c474-4683-a424-f05b136a38ae'),
    viewCredentialsPrivilege: SafeGuid.parse('7209fb2d-1943-4ce5-b8ec-24c22c68f60d'),
    modifyCredentialsPrivilege: SafeGuid.parse('6cc7d53b-3087-47a7-af91-5db1cbfcd812'),
    addCredentialsPrivilege: SafeGuid.parse('38de2e34-3746-4922-9e10-db87407393da'),
    deleteCredentialsPrivilege: SafeGuid.parse('3dc50349-a0aa-41dc-8bee-6eb2645fb5b8'),
    modifyCredentialInformationPrivilege: SafeGuid.parse('e0ef3539-8ed3-4bd2-9768-dac7bf87d985'),
    viewAccessRulesPrivilege: SafeGuid.parse('dcf2d1d1-5c2a-4fec-abb2-2b0a16c9b165'),
    modifyAccessRulesPrivilege: SafeGuid.parse('30b467a6-696a-4753-bfee-078561c1e2ff'),
    addAccessRulesPrivilege: SafeGuid.parse('c05f27d3-708e-412d-a2c3-e95374860227'),
    deleteAccessRulesPrivilege: SafeGuid.parse('b2609562-e0fb-4cd2-9f96-909f42f141d6'),
    viewAlarmsPrivilege: SafeGuid.parse('0cfe2198-ce16-48af-8f0b-fc029330e8e0'),
    modifyAlarmsPrivilege: SafeGuid.parse('b0f6025f-a29a-43c7-bfc8-6c6f77a2b70a'),
    addAlarmsPrivilege: SafeGuid.parse('068c4d28-fa2c-4900-a815-a0d5e7cec431'),
    deleteAlarmsPrivilege: SafeGuid.parse('a1bfe350-3a63-41dc-84d5-e4b0e1954852'),
    viewMonitorGroupsPrivilege: SafeGuid.parse('58314ca5-1eb4-4707-b2f9-ba9b1a591f60'),
    modifyMonitorGroupsPrivilege: SafeGuid.parse('706d38cd-120d-431d-9bc3-64d6a3ee1e9b'),
    addMonitorGroupsPrivilege: SafeGuid.parse('368a50d1-2d60-4110-984d-61cc4b3bd2cd'),
    deleteMonitorGroupsPrivilege: SafeGuid.parse('19536631-4287-4781-8517-4d32c04d9cb2'),
    viewCamerasPrivilege: SafeGuid.parse('7902233c-5e84-4de1-ae6e-7803ddf42384'),
    modifyCamerasPrivilege: SafeGuid.parse('529a30e2-802c-4a6f-9a89-f1eacccd8bfb'),
    addCamerasPrivilege: SafeGuid.parse('35914b9d-215c-4d60-bda1-69eb2f8c3ee7'),
    removeCamerasPrivilege: SafeGuid.parse('f877f653-4a5a-481b-b19c-3a474b570d93'),
    viewAnalogMonitorsPrivilege: SafeGuid.parse('1402c8e5-4240-4fe1-ac60-2f39f5dca0d2'),
    modifyAnalogMonitorsPrivilege: SafeGuid.parse('2260887a-7915-4b19-aaa8-30eaa519cfe9'),
    addAnalogMonitorsPrivilege: SafeGuid.parse('36aded3f-ffce-411d-8f26-5deb233baaae'),
    removeAnalogMonitorsPrivilege: SafeGuid.parse('96624b6c-4c1a-459d-832f-880053a3a752'),
    viewOutputBehaviorsPrivilege: SafeGuid.parse('2e01687e-8049-4ca5-9c17-947a8da71abe'),
    modifyOutputBehaviorsPrivilege: SafeGuid.parse('cc3d4209-4ef4-4310-9ac6-371985a49ed9'),
    addOutputBehaviorsPrivilege: SafeGuid.parse('0b5e1bca-6146-414b-b99d-19bcc39fe588'),
    deleteOutputBehaviorsPrivilege: SafeGuid.parse('2ce859b1-0ebb-4524-8419-26e3c20a045d'),
    viewDoorsPrivilege: SafeGuid.parse('c546db4d-ef5e-4ad1-a727-7690a19af891'),
    modifyDoorsPrivilege: SafeGuid.parse('7d5fbfa3-5b0d-4e1d-b80c-6ef089902793'),
    addDoorsPrivilege: SafeGuid.parse('bc963fd6-dcf3-45a6-bacd-be0c3552c552'),
    deleteDoorsPrivilege: SafeGuid.parse('b4aacc63-062a-4820-b3ed-9b048073d35b'),
    viewAreasPrivilege: SafeGuid.parse('6095e91e-25bb-430d-a9db-16fe6af51bdc'),
    modifyAreasPrivilege: SafeGuid.parse('d4586106-f2fb-4b29-8f03-4efeafc36e5f'),
    addAreasPrivilege: SafeGuid.parse('5156ab27-869d-4455-9fa8-caa853abcf13'),
    deleteAreasPrivilege: SafeGuid.parse('46e9fa16-7679-4755-9778-e8d61473fe46'),
    viewElevatorsPrivilege: SafeGuid.parse('133c66ce-afb6-44ef-ada7-eefc76406370'),
    modifyElevatorsPrivilege: SafeGuid.parse('8f830ceb-6452-451e-b2a0-a82425892b63'),
    addElevatorsPrivilege: SafeGuid.parse('4fdb33c9-0f1b-41fc-b01c-c833e78cd2d5'),
    deleteElevatorsPrivilege: SafeGuid.parse('93a63331-b30e-4da6-9b2b-3b4f8448a820'),
    viewZonesPrivilege: SafeGuid.parse('0e8e9e32-feab-45ee-8805-c51e627d67bb'),
    modifyZonesPrivilege: SafeGuid.parse('88051d44-ffd1-4dcd-a0d5-a613202e2470'),
    addZonesPrivilege: SafeGuid.parse('9738ba2a-e011-4b18-bee6-06c2929659c6'),
    deleteZonesPrivilege: SafeGuid.parse('95ffc35a-9def-48a0-99dd-b35835aff65c'),
    viewAssetsPrivilege: SafeGuid.parse('3d96d4e8-e2b7-4d0e-8e8e-653cf91777b5'),
    modifyAssetsPrivilege: SafeGuid.parse('02a4ce8c-bb1d-465d-b533-8e989725eddf'),
    addAssetsPrivilege: SafeGuid.parse('023f5365-971f-4ac2-8512-80b841b8b523'),
    deleteAssetsPrivilege: SafeGuid.parse('22937789-fafa-451e-8d02-bb4dd1dce8fd'),
    viewScheduledTasksPrivilege: SafeGuid.parse('9f63265d-e419-4e00-b0e5-a11308513b01'),
    modifyScheduledTasksPrivilege: SafeGuid.parse('67878339-bc75-47c4-ad5a-75163c4e1cc9'),
    addScheduledTasksPrivilege: SafeGuid.parse('09a919ac-2e6b-4828-937a-25c74102eb6f'),
    deleteScheduledTasksPrivilege: SafeGuid.parse('57a3e65a-3eac-4c67-83f7-272b492f1e34'),
    viewMacroPrivilege: SafeGuid.parse('80073FCA-31E8-499B-BE88-E2DC9884EBEC'),
    deleteMacroPrivilege: SafeGuid.parse('6BE427B3-63DF-4BEC-BE8F-4C3D54BEDA13'),
    viewMapsPrivilege: SafeGuid.parse('f645d890-21a4-4bb8-82ff-1c58e2cd4a94'),
    modifyMapsPrivilege: SafeGuid.parse('1eca9a86-5cfb-4cb1-8088-4ae5bb1ac8da'),
    addTilePluginsPrivilege: SafeGuid.parse('3bbdfcfd-bcfd-4ce5-9438-9bc0f3777840'),
    deleteTilePluginsPrivilege: SafeGuid.parse('a4eba300-e848-4e94-9bf1-ea1118ce704b'),
    viewBadgeTemplatesPrivilege: SafeGuid.parse('eeb68039-a326-4942-914d-86695839f66a'),
    modifyBadgeTemplatesPrivilege: SafeGuid.parse('2353e6de-28a3-4e7f-9ebe-9f363a234147'),
    addBadgeTemplatesPrivilege: SafeGuid.parse('64d81a2f-d488-49e0-82fd-b3cdcccbd51f'),
    deleteBadgeTemplatesPrivilege: SafeGuid.parse('a7ac056d-ee0e-46ad-9f28-1324c2f34990'),
    viewUserTasksPrivilege: SafeGuid.parse('a1df9a01-0366-4ced-bd8c-23fe2346d47b'),
    modifyUserTasksPrivilege: SafeGuid.parse('75e6e5d6-3c4f-4f99-951d-5ac2bf712254'),
    addPublicTasksPrivilege: SafeGuid.parse('36d52030-82b5-45e0-8d68-508f8f606d32'),
    deletePublicTasksPrivilege: SafeGuid.parse('f16e162a-496d-4a2d-89ee-512b406151c4'),
    managePrivateTasksPrivilege: SafeGuid.parse('db60a94a-157e-4e16-b766-f0f6617b6106'),
    viewLprRulesPrivilege: SafeGuid.parse('4c94becf-928b-4882-af43-26135c0f70e8'),
    modifyLprRulesPrivilege: SafeGuid.parse('25f576c9-a8c6-4662-8473-481d62e31d8d'),
    addLprRulesPrivilege: SafeGuid.parse('900bf477-cf76-4195-872b-3442be255b00'),
    deleteLprRulesPrivilege: SafeGuid.parse('ef5dc4f4-b37a-40aa-bc0e-2e4667eac9b7'),
    viewCashRegistersPrivilege: SafeGuid.parse('6e13c216-842a-4404-9f2a-eb4af9e66302'),
    modifyCashRegistersPrivilege: SafeGuid.parse('a748a4c4-9c4c-41e9-89e6-1654ec9a3b3c'),
    viewParkingZonesPrivilege: SafeGuid.parse('90EE64F0-B58A-43FF-97C4-C8D4B5D319DA'),
    modifyParkingZonesPrivilege: SafeGuid.parse('C400F81E-6DAC-4C29-AA28-3B394A645FB7'),
    addParkingZonesPrivilege: SafeGuid.parse('F2845732-6A0B-4B40-B663-EBB76CCDE345'),
    deleteParkingZonesPrivilege: SafeGuid.parse('B98E5CA0-2953-4707-ADE5-C77956D16823'),
    viewParkingZoneRulesPrivilege: SafeGuid.parse('765E576E-28E9-4CDC-BD46-87B172166FA0'),
    modifyParkingZoneRulesPrivilege: SafeGuid.parse('7A9401C5-B324-4ECE-97BB-12EF2C25CFE5'),
    addParkingZoneRulesPrivilege: SafeGuid.parse('06678D9E-45A0-4A87-9D1A-EBA377C55FC3'),
    deleteParkingZoneRulesPrivilege: SafeGuid.parse('6336F269-DA0B-4D9B-8E01-31FC59E14D53'),
    viewVisitorsPrivilege: SafeGuid.parse('75be2d69-ed8b-411c-9cad-74bc623b65d2'),
    modifyVisitorsPrivilege: SafeGuid.parse('206d057c-da4d-41d7-8090-a90b1bc340d3'),
    viewIntrusionUnitsPrivilege: SafeGuid.parse('eb7ea22e-5354-434d-bb21-1788a673df8f'),
    modifyIntrusionUnitsPrivilege: SafeGuid.parse('af87c076-1fc8-4e83-a1aa-230cd3b443f6'),
    resetIntrusionUnitsPrivilege: SafeGuid.parse('e0781bad-bf71-4b3c-8f66-1c237b98e82c'),
    intrusionUnitFirmwareUpgradePrivilege: SafeGuid.parse('e26597c7-079f-4a39-94c6-43325a359a45'),
    addIntrusionDetectionUnitsPrivilege: SafeGuid.parse('425aa8b3-d8ce-4b08-a7f1-6fd5f34b13b3'),
    deleteIntrusionDetectionUnitsPrivilege: SafeGuid.parse('2ca82e1a-3636-4e2e-b7ac-ca76e188e4e3'),
    viewIntrusionAreasPrivilege: SafeGuid.parse('29bc30eb-34d0-45d5-a00f-82384a89f8b3'),
    modifyIntrusionAreasPrivilege: SafeGuid.parse('a62806d8-b763-47ff-8de3-44153aa37b5a'),
    addIntrusionDetectionAreasPrivilege: SafeGuid.parse('87c80cef-a817-4a52-9fa6-fb25c2c67ccc'),
    deleteIntrusionDetectionAreasPrivilege: SafeGuid.parse('933cb83d-f10d-40c5-a866-297654bdebff'),
    modifyCameraSequencePrivilege: SafeGuid.parse('5651fd52-bfe0-4f8b-9d09-995af6e993de'),
    addCameraSequencesPrivilege: SafeGuid.parse('be80714a-1577-4450-a396-1b92d4ac6779'),
    deleteCameraSequencesPrivilege: SafeGuid.parse('74242111-4b2b-411a-8379-addb03863aa3'),
    viewVideoUnitsPrivilege: SafeGuid.parse('66e541a1-4235-4b69-b42a-d380a3e53a00'),
    videoUnitFirmwareUpgradePrivilege: SafeGuid.parse('a0d971b8-43c6-46ab-bb87-b89b7fedce92'),
    modifyVideoUnitsPrivilege: SafeGuid.parse('1f4ca9c1-edba-42cf-815f-5f0d67a616fb'),
    addVideoUnitsPrivilege: SafeGuid.parse('511683a9-927f-497e-a55a-02f47856dcf7'),
    deleteVideoUnitsPrivilege: SafeGuid.parse('ba25487b-556c-486b-8474-acce15be7268'),
    resetVideoUnitsPrivilege: SafeGuid.parse('adfa60d7-ca0b-443a-adf6-44c2d5c57ca0'),
    reconnectVideoUnitsPrivilege: SafeGuid.parse('e8fb098e-2d60-4024-be9d-9cfc57fc6348'),
    addCashRegistersPrivilege: SafeGuid.parse('29c56041-1d1f-4a99-beeb-0b83b1f89b8d'),
    deleteCashRegistersPrivilege: SafeGuid.parse('68621ff5-0144-475a-baaa-6be8b27258f9'),
    incidentsReportPrivilege: SafeGuid.parse('aa8d65c3-23b5-4922-af48-978478b031ea'),
    activityTrailsReportPrivilege: SafeGuid.parse('d295c62b-031f-4022-b829-5baf2ee29a6a'),
    auditTrailsReportPrivilege: SafeGuid.parse('91cec224-f624-43e0-8a96-c3edeed49906'),
    timeAttendanceReportPrivilege: SafeGuid.parse('29ff9fe0-76f1-4379-8dbc-a61c5615db5a'),
    cardholderActivityReportPrivilege: SafeGuid.parse('565b1d24-6d68-4866-a32e-a9d3834c215e'),
    doorActivityReportPrivilege: SafeGuid.parse('6fb20d7c-4153-4d96-80db-53b6322ccfc9'),
    areaActivityReportPrivilege: SafeGuid.parse('7062d6c4-2a6e-45bd-a457-ef2658acb299'),
    elevatorActivityReportPrivilege: SafeGuid.parse('a24a523a-ff83-4d83-9cff-a9cde38a4247'),
    areaPresenceReportPrivilege: SafeGuid.parse('ad479241-e91a-4ec7-994b-a8b4720f88d9'),
    intrusionAreaActivityReportPrivilege: SafeGuid.parse('ab07b90e-c866-418d-b8a5-33632d9925c6'),
    intrusionUnitActivityReportPrivilege: SafeGuid.parse('8b297c18-3bff-44bd-9b01-14caf51dbdf3'),
    credentialActivityReportPrivilege: SafeGuid.parse('ef9822d8-c7e6-465e-bb3d-d5f730bebd71'),
    credentialRequestHistoryReportPrivilege: SafeGuid.parse('fa60a172-33e1-433e-aaff-cdbe0355706d'),
    visitorActivityReportPrivilege: SafeGuid.parse('483f3bf3-76cc-43b1-b0f8-0afa84df8560'),
    assetActivityReportPrivilege: SafeGuid.parse('85fe2ea0-79d2-47d9-b115-8dd138232964'),
    assetInventoryReportPrivilege: SafeGuid.parse('c13cd251-e948-4ef8-9297-cf372b89ed1b'),
    unitActivityReportPrivilege: SafeGuid.parse('86f3aee4-f211-4d79-a518-015bd76c12c9'),
    zoneActivityReportPrivilege: SafeGuid.parse('be5a78be-42a5-43e1-8e83-ee96f4354af7'),
    hitsReportPrivilege: SafeGuid.parse('b046c1a8-2bea-4bb1-8502-b24b324815b2'),
    plateReadsReportPrivilege: SafeGuid.parse('9bf029f4-5402-45be-b283-9fc5cd5da7cb'),
    readHitsPerLprZoneReportPrivilege: SafeGuid.parse('6214650b-ec4a-44ba-880d-b062516f71a2'),
    readHitsPerDayReportPrivilege: SafeGuid.parse('494213e6-29c3-40b3-8ad2-7f06f238ba37'),
    readsGroupingReportPrivilege: SafeGuid.parse('ef66d716-3274-4911-8d49-3000401dbb72'),
    hitsGroupingReportPrivilege: SafeGuid.parse('e8f4ab25-1862-4ab3-b7f3-deaf4dcec699'),
    hitsPerTypeReportPrivilege: SafeGuid.parse('4a7300cc-4b9b-41b3-afc4-6d5775c001a7'),
    hitsPerAgeReportPrivilege: SafeGuid.parse('c07c1654-1f6e-4177-bdaa-6f01100fa4e1'),
    lprZoneOccupationReportPrivilege: SafeGuid.parse('f16a4577-9c4b-48c0-a303-eceae4454edf'),
    loginPerPatrollerReportPrivilege: SafeGuid.parse('ca036ede-0384-4005-85f1-3e80a8e4e73f'),
    dailyUsagePerPatrollerReportPrivilege: SafeGuid.parse('f4536854-85bb-4c7f-a3da-cd2ab5928907'),
    parkingZoneActivitiesReportPrivilege: SafeGuid.parse('2673BE4A-7A50-4BC9-9CFC-9587E1C915D5'),
    parkingSessionsReportPrivilege: SafeGuid.parse('FDD35074-4638-4676-BDC6-BC25892C1DE3'),
    videoArchiveReportPrivilege: SafeGuid.parse('48d9e227-bf6b-43dc-9d87-a979eff584d3'),
    videoBookmarkReportPrivilege: SafeGuid.parse('8507bc2c-eee9-45f1-a4c6-ad57ce96aa28'),
    videoMotionSearchReportPrivilege: SafeGuid.parse('342fd39a-c51e-4035-9a66-56c520a0524a'),
    forensicSearchReportPrivilege: SafeGuid.parse('1959de48-e6cb-4101-92e7-4138629c0805'),
    cameraActivityReportPrivilege: SafeGuid.parse('7c2d3803-46b0-4e75-ae9c-9f1b0d4525ac'),
    cameraConfigurationReportPrivilege: SafeGuid.parse('e758e10e-4e43-485e-a58d-94d7f580e848'),
    archiverEventsReportPrivilege: SafeGuid.parse('ed0c2693-d659-42c9-b8b9-86e3b5a26b98'),
    videoFilesReportPrivilege: SafeGuid.parse('a3893b07-bf15-4a74-b6a2-f4638e54b690'),
    pointOfSaleReportPrivilege: SafeGuid.parse('d5d3180b-fba4-4dc0-9b0a-25034ad29431'),
    iOConfigurationReportPrivilege: SafeGuid.parse('954ce185-0804-4c49-9f8a-115db58c9425'),
    visitorReportPrivilege: SafeGuid.parse('808cf8a9-6a61-4c3c-b896-661fe54e455d'),
    accessReportPrivilege: SafeGuid.parse('ca874005-80bd-431d-a876-6ecc1347787c'),
    enhancedAccessReportPrivilege: SafeGuid.parse('414e1b3b-bd80-43cb-ac94-382d5c550a51'),
    healthReportPrivilege: SafeGuid.parse('801f604f-adf9-4c7e-8be4-9c3ab616181e'),
    accessControlHealthHistoryPrivilege: SafeGuid.parse('37fbb79f-f3b0-4428-8a3a-8dda32a47b25'),
    accessTroubleshooterReportPrivilege: SafeGuid.parse('4c13baf8-cbdc-41a7-8ad4-55aa362670ac'),
    accessRulesConfigurationReportPrivilege: SafeGuid.parse('e9c20842-0c57-4ee5-956c-fae167c6442e'),
    cardholdersConfigurationReportPrivilege: SafeGuid.parse('631856a9-cb26-4961-a287-19dd930699d3'),
    credentialsConfigurationReportPrivilege: SafeGuid.parse('76221cc8-61f7-46b0-9b05-65570700121f'),
    unitsConfigurationReportPrivilege: SafeGuid.parse('60fc8dd2-db70-49d1-963d-f27db38ea724'),
    alarmReportPrivilege: SafeGuid.parse('d72f6995-548c-4c38-a4fa-0fddd2a7674d'),
    correlationReportPrivilege: SafeGuid.parse('905C2049-CA05-4A91-AD7C-2AF373999102'),
    cardholderCreatorPrivilege: SafeGuid.parse('dc0c0983-c737-4aa7-ba1e-798c4010c4e5'),
    credentialManagementPrivilege: SafeGuid.parse('e06ecf58-bfce-42c6-890a-c4761158bd20'),
    peopleCountingPrivilege: SafeGuid.parse('65534082-58a9-49db-911e-25d959171b69'),
    surveillancePrivilege: SafeGuid.parse('e6206b60-23e8-4466-8493-1418f088e7a9'),
    changeCardholderStatusPrivilege: SafeGuid.parse('d7e0e682-f7d0-4a14-8014-c8f77d02c429'),
    modifyCardholderNamePrivilege: SafeGuid.parse('4ea35b1b-f92f-4577-a56d-cc2cf0bca985'),
    modifyCardholderCustomFieldsPrivilege: SafeGuid.parse('6420fd0b-a9c3-40fe-8626-09d3e032710c'),
    modifyCardholderPicturePrivilege: SafeGuid.parse('a7bb370f-431b-4137-9000-b1bf9b1cf09e'),
    changeCredentialStatusPrivilege: SafeGuid.parse('14634e51-f8a0-4a46-b60a-e2fbc8f6cebc'),
    modifyCredentialNamePrivilege: SafeGuid.parse('7aa63f35-93c4-49f0-ba0f-86720c81259a'),
    modifyCredentialAssociationToCardholderPrivilege: SafeGuid.parse('915093f3-63aa-4778-958f-c03de46de8d2'),
    modifyCredentialCustomFieldsPrivilege: SafeGuid.parse('18873b3f-74c0-45bc-ba04-613020c52a7a'),
    printBadgesPrivilege: SafeGuid.parse('a74c018d-a184-4833-a0a0-dd2d716254f3'),
    checkInVisitorsPrivilege: SafeGuid.parse('d85b4380-16ee-4cef-ac41-a1795216c397'),
    checkOutVisitorsPrivilege: SafeGuid.parse('a9920d27-cae1-401d-b000-42cbf123f632'),
    modifyVisitorsCustomFieldsPrivilege: SafeGuid.parse('c277723c-5109-4a1c-a172-f78a4460dce0'),
    modifyVisitorPicturePrivilege: SafeGuid.parse('b2df9787-6678-41ac-b4cf-d28012c4686d'),
    saveAndPrintSnapshotsPrivilege: SafeGuid.parse('09f6cec5-c339-4248-b675-3ef52fd5883a'),
    useAudioPrivilege: SafeGuid.parse('18a3a89c-5c63-462f-8853-2f4c5fb00078'),
    setPtzPatternPrivilege: SafeGuid.parse('0bb88e83-1ade-4d26-a891-fadff836c1c6'),
    usePtzAuxiliariesPrivilege: SafeGuid.parse('fe698f1c-f6ef-4965-814d-486140b9579e'),
    setPtzAuxiliariesPrivilege: SafeGuid.parse('4a6535ec-2879-4999-afb6-c4adc8216e78'),
    snoozeAlarmsPrivilege: SafeGuid.parse('ef45f8eb-0ee2-49a1-87b6-08c1c034fca9'),
    forwardAlarmsPrivilege: SafeGuid.parse('6cef2b0f-aed4-4f83-9144-4f58d60e720b'),
    forceAckAlarmPrivilege: SafeGuid.parse('E0AC9C66-2561-4D6D-95AB-40118CD5CD5A'),
    inventoryReportPrivilege: SafeGuid.parse('e949531f-58ae-432d-b7f5-48b9defa2a62'),
    incidentReportMCPrivilege: SafeGuid.parse('b9f4ed40-d6b0-4088-9a3e-2cbca0690ae6'),
    incidentBaseReportMCPrivilege: SafeGuid.parse('3A0B09A4-2486-497C-AF57-26D7445045A7'),
    incidentProfilesReportMCPrivilege: SafeGuid.parse('5E1BAC29-40B4-49DD-8074-449DD2929EE9'),
    logicalViewTaskPrivilege: SafeGuid.parse('83468c8f-4453-4ead-ada8-50878dd03d32'),
    mapDesignerTaskPrivilege: SafeGuid.parse('80d89b24-5d2f-45fb-a54a-49b2b3102e02'),
    mapMonitoringTaskPrivilege: SafeGuid.parse('17574dde-92d5-430b-b4ab-24b50b48d063'),
    mapEditLayersPrivilege: SafeGuid.parse('17876748-50C9-4F36-97F0-F0862D38967E'),
    systemTaskPrivilege: SafeGuid.parse('4110908d-d55a-4af4-85d5-9404fe449a9a'),
    systemStatusTaskPrivilege: SafeGuid.parse('25bc8760-d4b9-4e8c-b127-ad7a407f179b'),
    videoManagementTaskPrivilege: SafeGuid.parse('b9956702-71f4-4085-a57b-5b0833286552'),
    accessControlTaskPrivilege: SafeGuid.parse('585a4682-6c5a-4eed-b1c8-eec4dce4bb7b'),
    intrusionDetectionTaskPrivilege: SafeGuid.parse('4c17b488-3c76-463b-bf89-e4faa317f115'),
    remoteSecurityDeskTaskPrivilege: SafeGuid.parse('9de5a91b-df80-4fcb-9395-c6c9ae27f3cf'),
    lprTaskPrivilege: SafeGuid.parse('3e366ef8-2a77-4873-8215-306ba312fb16'),
    pluginTaskPrivilege: SafeGuid.parse('413c53d7-fdb5-43ba-aee7-17ab5f69705e'),
    incidentMonitoringTaskPrivilege: SafeGuid.parse('5bd2cd08-6084-4aa2-8fb0-ca0a8f6d1030'),
    incidentConfigurationTaskPrivilege: SafeGuid.parse('FDF1988A-F8BB-437A-82B2-798C261986F7'),
    generalSettingsCustomFieldsPrivilege: SafeGuid.parse('3A454E27-4952-480F-9712-9ED8351B882A'),
    generalSettingsEventsPrivilege: SafeGuid.parse('4AAE9E60-D959-48D3-A728-11727A20A333'),
    generalSettingsActionsPrivilege: SafeGuid.parse('49FAC58F-F8F5-4232-9812-74C8FDBCA001'),
    generalSettingsLogicalIdPrivilege: SafeGuid.parse('7F3B935F-3E50-4722-B78B-06E1746406CC'),
    generalSettingsPasswordSettingsPrivilege: SafeGuid.parse('988127A3-3B05-40AA-B62C-DB80D1587FA8'),
    generalSettingsActivityTrailsPrivilege: SafeGuid.parse('2EAFBADC-ED8B-4D5A-AF18-4E800060D760'),
    generalSettingsAudioPrivilege: SafeGuid.parse('DD28CB8D-837B-4540-B203-9775FEDD9EA8'),
    generalSettingsIncidentCategoriesPrivilege: SafeGuid.parse('8077AA4B-2085-48B6-9AEC-B3B88288EF7A'),
    generalSettingsFeaturesPrivilege: SafeGuid.parse('AD8C5C4C-8AD2-4E8F-923A-1D46B44B17E8'),
    allowAlarmManagementPrivilege: SafeGuid.parse('c1514a8e-a820-4d4b-a51f-e5c5802ca58b'),
    explicitlyUnlockDoorPrivilege: SafeGuid.parse('6c5daa2a-f973-4f02-91bd-629e01423d00'),
    allowVisitorManagementPrivilege: SafeGuid.parse('a71e8cb5-af6b-481f-b285-72afa1865f93'),
    patrollerPlaybackPrivilege: SafeGuid.parse('3b88ce52-50cd-4a7c-88e8-ee4a7e2bd73a'),
    allowVideoPrivilege: SafeGuid.parse('b1e51df7-a180-4cde-90c7-6f925b819838'),
    allowPlaybackVideoPrivilege: SafeGuid.parse('6ab50ae5-c1b0-4c68-b95d-f5d31b1f6911'),
    removePrivacyProtectionPrivilege: SafeGuid.parse('7E137A7F-5E6B-40B2-BABF-003FF65BAC94'),
    recordManuallyPrivilege: SafeGuid.parse('a3ef1ed7-b755-4774-b23c-b6ef3cb2b9fc'),
    protectVideoFilePrivilege: SafeGuid.parse('8990e485-4be1-4f82-a750-e166865df8c7'),
    unprotectVideoFilePrivilege: SafeGuid.parse('5485d763-95bc-4853-94ef-d3853b3cdc4d'),
    addBookmarkPrivilege: SafeGuid.parse('47fab8f5-db1a-4754-aa89-4da5d01c10ff'),
    editBookmarkPrivilege: SafeGuid.parse('e1903181-5e04-4d52-bc6e-8212d11cf161'),
    deleteBookmarkPrivilege: SafeGuid.parse('ef693a66-be97-4b1e-bd45-bcc893639925'),
    exportVideoPrivilege: SafeGuid.parse('5fcb1e19-d873-4b4d-939b-7cb4c301edd6'),
    singleUserExportPrivilege: SafeGuid.parse('a9eb3e1a-77a6-4199-a774-0df23af3aa22'),
    changeFocusAndIrisPrivilege: SafeGuid.parse('acaa8270-2deb-4654-860f-c027acfed80d'),
    basicPtzOperationsPrivilege: SafeGuid.parse('b04cddbe-b774-4735-ac7d-32c1adbabd2b'),
    lockPtzPrivilege: SafeGuid.parse('7a046bc2-07b4-41bb-834e-3ad7865112da'),
    togglePtzMenuPrivilege: SafeGuid.parse('496ec32f-acf3-4e56-bd70-ef89d14e398e'),
    usePtzPresetsPrivilege: SafeGuid.parse('22750895-0efa-421f-aac3-0935ebebe3a2'),
    editPtzPresetPrivilege: SafeGuid.parse('f0cc7c1a-c96c-4f0a-90cc-c96cb6014b4c'),
    usePtzPatternsPrivilege: SafeGuid.parse('1bcbe02e-0520-4b7c-87d5-726f2ad5b0f5'),
    acknowledgeAlarmsPrivilege: SafeGuid.parse('44a227bb-7bda-4719-9969-7703f0056241'),
    changeOwnPasswordPrivilege: SafeGuid.parse('4c1d911f-b123-44fb-8a37-487b8532fc14'),
    triggerAlarmPrivilege: SafeGuid.parse('0f106d44-3718-4562-bc02-d8ccfcc53ed4'),
    viewAdvancedCredentialInformationPrivilege: SafeGuid.parse('0a920d11-5a74-4733-8398-e4188ef565cd'),
    executeMacrosPrivilege: SafeGuid.parse('ca2a41e8-1d56-4172-95a8-261fb897c988'),
    cameraMaintenanceModePrivilege: SafeGuid.parse('f5d2d163-aebc-4dd2-bffd-ef8c8b068ad2'),
    createIncidentsPrivilege: SafeGuid.parse('416b7e52-9bf5-470e-8cad-c633e4e7b0ad'),
    modifyIncidentsPrivilege: SafeGuid.parse('7ef737f9-44e3-47bc-a54a-8fa0121cefca'),
    deleteIncidentsPrivilege: SafeGuid.parse('6F10E487-5356-4FFA-8E1E-F169841A63AD'),
    autoUnlockOverridePrivilege: SafeGuid.parse('89488826-4ffd-4110-8c6e-e1eaa9f7d95d'),
    viewWebPagesPrivilege: SafeGuid.parse('bc1dc983-3dd1-4667-bf8d-50531d7c6bd7'),
    viewLiveCovertHitsPrivilege: SafeGuid.parse('d879c53a-a0be-46f7-8935-2902bb3fa518'),
    sendEmailPrivilege: SafeGuid.parse('8dfc13cb-8d25-4fda-afb7-bbc15b2c08cd'),
    forgiveAntipassbackViolationPrivilege: SafeGuid.parse('bd805edb-ce8c-42dc-8f26-0d736f582ea6'),
    sendMessagePrivilege: SafeGuid.parse('119df527-c009-4d5d-a1d0-eecfb01db92b'),
    sendUserTaskPrivilege: SafeGuid.parse('30ac1903-c55b-419c-be4c-dd7c431dada1'),
    playSoundPrivilege: SafeGuid.parse('0eeebcdb-aa10-4f4a-856a-925c91e1bf2e'),
    triggerOutputPrivilege: SafeGuid.parse('1e86af99-963d-410e-9307-8a04fd3222af'),
    printAndExportReportsPrivilege: SafeGuid.parse('c86bc49c-1ab8-4bf5-bb17-556a19253232'),
    removeResultsFromReportPrivilege: SafeGuid.parse('e1fb4be7-a1dd-40e4-b5da-96bf5253d3d4'),
    protectReadsHitsPrivilege: SafeGuid.parse('d66f9401-ee23-4de3-8415-20cb636c1375'),
    deleteLprHitPrivilege: SafeGuid.parse('63f17925-6b55-4fbb-82ed-fc6e80e80084'),
    triggerPastReadMatchingPrivilege: SafeGuid.parse('f0e72fd6-124e-4fe5-bc2e-824969b559ac'),
    reconcileInventoriesPrivilege: SafeGuid.parse('a34a1926-32bf-4d9b-a778-5a1e73813b54'),
    parkingZoneInventoryResetPrivilege: SafeGuid.parse('08069FFE-2977-4C9D-8A29-7EAA25BB6DFE'),
    parkingZoneSetOccupancyPrivilege: SafeGuid.parse('DC5C522F-6951-4E84-BDF0-B1F8C068440C'),
    updateInlotViolationsPrivilege: SafeGuid.parse('F283C0B8-D21C-40BF-9B5A-50E55BE7F518'),
    hotlistAndPermitEditorPrivilege: SafeGuid.parse('73adbba6-59bf-42ff-8ce8-7cff9e0841d4'),
    modifyDeleteInventoryReadsPrivilege: SafeGuid.parse('d51cc6dc-f31a-467a-ae6b-da343b401cb5'),
    editReadsPrivilege: SafeGuid.parse('5ea6e83d-0602-4ad6-b295-2e9478d2c612'),
    armDisarmIntrusionAreaPrivilege: SafeGuid.parse('112b1b66-418d-400f-add4-932ec7d3d67c'),
    triggerIntrusionAlarmPrivilege: SafeGuid.parse('d9ae66a3-ea94-408c-9434-c61db6088433'),
    silenceIntrusionAlarmPrivilege: SafeGuid.parse('5ab0955f-19f9-404f-9215-9d5e0dedb3fd'),
    ackIntrusionAlarmPrivilege: SafeGuid.parse('81b04e90-6797-466f-a267-ce08c7959f97'),
    armDisarmZonesPrivilege: SafeGuid.parse('2caee185-8227-4791-afe9-6f3bd989aa4b'),
    useSpecificCommandsPrivilege: SafeGuid.parse('6a74c0be-4288-11df-ab45-02ae55d89593'),
    overrideLockPtzPrivilege: SafeGuid.parse('63d02de9-ff9e-49ac-aad8-88262b5f951d'),
    changeWorkspacePrivilege: SafeGuid.parse('77064f62-5ecc-4916-b6a1-b67849cf3421'),
    startStopGuardtourPrivilege: SafeGuid.parse('dfd24407-8b4c-4768-8141-f8c69a3453bf'),
    changeSecurityDeskOptionsPrivilege: SafeGuid.parse('708c535c-60b2-4922-993d-937565032435'),
    changeTileContextPrivilege: SafeGuid.parse('15595806-92d1-4cda-97b2-a423550862d7'),
    changeTilePatternPrivilege: SafeGuid.parse('c5d3f637-e9f0-4be6-a102-7945fdae4872'),
    editTilePatternPrivilege: SafeGuid.parse('a52ac7f8-01b7-4fea-b05f-5da61d886791'),
    changeClientViewsPrivilege: SafeGuid.parse('72233940-ce2e-4508-96e7-b9a3604947a4'),
    cameraAnalyticsPrivilege: SafeGuid.parse('82b535c0-61d6-4586-8a62-7609aa6431bd'),
    digitalZoomPrivilege: SafeGuid.parse('b93970a3-18d1-4888-8daf-a812ccb3fe06'),
    displayVideoOverlaysPrivilege: SafeGuid.parse('3c043110-84f9-4513-a453-a27c9c52f7fa'),
    overrideVideoQualityPrivilege: SafeGuid.parse('390a8e0c-498d-45ab-8b24-67f1c7dd7627'),
    viewCameraSequencePrivilege: SafeGuid.parse('a30b505b-17d1-4cd8-980e-e6a7aed779d2'),
    startStopSequencePrivilege: SafeGuid.parse('28adb76e-2a90-4359-a373-e4e5667c76d4'),
    unitDiscoveryToolPrivilege: SafeGuid.parse('681c55ce-a869-419d-804a-1fdd53ec93f8'),
    selectManufacturersPrivilege: SafeGuid.parse('27ab8cbe-947f-4057-a3f5-c33c50a74438'),
    addUnitManuallyPrivilege: SafeGuid.parse('cab9216d-746a-4062-9be9-470a6ee41353'),
    moveUnitToolPrivilege: SafeGuid.parse('e7da184b-db32-4bd8-a39d-92c494754531'),
    silenceSoundBuzzerPrivilege: SafeGuid.parse('a79df101-9fa7-4bae-9470-b2a06b4b6c91'),
    maintenanceModePrivilege: SafeGuid.parse('95355180-a73a-4059-9e77-b129a2d37051'),
    emailReportPrivilege: SafeGuid.parse('aae95954-c0f0-4e1a-8fad-d2e2f18888cc'),
    displayEntityInSecurityDeskPrivilege: SafeGuid.parse('99d38ce8-0bc6-4e07-8cd4-1817c547c3f9'),
    convertExportedFilesPrivilege: SafeGuid.parse('278408e7-7dfa-44d9-a767-239c1063c46b'),
    resetPeopleCountPrivilege: SafeGuid.parse('44a30977-dbfd-4542-b634-69adb96bc3fe'),
    createMobileCredentialPrivilege: SafeGuid.parse('6e49046f-d854-451f-b901-b9c40f0db99a'),
    convertSharedEntitiesToLocalPrivilege: SafeGuid.parse('7a484aa5-085f-47d5-aa6a-e768d440d12e'),
    blockUnblockVideoPrivilege: SafeGuid.parse('910f2677-dc77-4904-8fd9-abfe2e2f99a1'),
    setThreatLevelPrivilege: SafeGuid.parse('08d9fcba-ecad-4b75-b7e3-9acb8b27ba4b'),
    removeEncryptionPrivilege: SafeGuid.parse('038408e7-4dfa-44d9-a767-239d1063c46b'),
    viewStreamStatisticsPrivilege: SafeGuid.parse('E8BB6484-F28C-4918-A4CB-9C18ACB93D0C'),
    viewStreamStatusPrivilege: SafeGuid.parse('3DD59613-3D8A-4953-AC58-139135FA9229'),
    viewRolePropertiesPrivilege: SafeGuid.parse('686731a2-12b5-479c-84f1-a8f627be09e5'),
    editRolePropertiesPrivilege: SafeGuid.parse('97dac763-eac5-4377-a73e-b7ea95215751'),
    createNewRolePrivilege: SafeGuid.parse('4717b48d-c37a-447a-a00a-2f00afb76b57'),
    deleteRolePrivilege: SafeGuid.parse('5aa44376-5ceb-4fde-936e-df1570312607'),
    viewUserPropertiesPrivilege: SafeGuid.parse('87f5ed6b-b67a-4a19-acab-d20c1962fd02'),
    editUserPropertiesPrivilege: SafeGuid.parse('3e421c07-625b-4fd8-9fcf-4a40304fbebd'),
    createNewUserPrivilege: SafeGuid.parse('d58d7e1a-e834-4d74-80d9-744c0adedfc7'),
    deleteUserPrivilege: SafeGuid.parse('b47c8146-b2f6-4f68-a20a-d7f22ee382d7'),
    viewUserGroupPropertiesPrivilege: SafeGuid.parse('024D7AB4-F392-4E40-B50F-AA8672188728'),
    editUserGroupPropertiesPrivilege: SafeGuid.parse('512516AC-8596-414B-83D6-2D20DFB2D651'),
    createNewUserGroupPrivilege: SafeGuid.parse('610720D7-6D97-4DDF-8014-5779030E375D'),
    deleteUserGroupPrivilege: SafeGuid.parse('D41261D2-49B5-479B-9C5B-5D1C17CB4A0B'),
    viewServerPropertiesPrivilege: SafeGuid.parse('b0464ce7-7fef-4ca1-bcd5-8bac6701e289'),
    editServerPrivilege: SafeGuid.parse('624cbf59-d991-45d8-963b-90455899ea92'),
    deleteServerPrivilege: SafeGuid.parse('a975e160-c7ea-4ef3-8cda-ced929a55de3'),
    viewNetworkPropertiesPrivilege: SafeGuid.parse('23e4c655-51ea-4bf4-9ab7-99e08e250765'),
    editNetworkPrivilege: SafeGuid.parse('e3624337-9536-45e0-8eaa-104a36254eea'),
    viewPartitionPropertiesPrivilege: SafeGuid.parse('6468f715-fa99-476e-a9f7-2e34ad4b01c4'),
    editPartitionPropertiesPrivilege: SafeGuid.parse('580f7a96-7eb7-46df-ae53-e43a9e6bfdee'),
    createNewPartitionPrivilege: SafeGuid.parse('6fead15d-dcf2-4a98-8fbd-7f65054843cf'),
    deletePartitionPrivilege: SafeGuid.parse('06dfbf4b-4577-47be-946a-ad8bc39be2ae'),
    hitsMultiRegionPrivilege: SafeGuid.parse('c12c52fc-c40a-4a93-a1c2-2e4796971e85'),
    readsMultiregionPrivilege: SafeGuid.parse('569744f6-2197-41c6-a051-e847ba3e34fb'),
    viewTileLayoutsPrivilege: SafeGuid.parse('d7b40f19-5e11-4a85-b4b6-e6807d38baee'),
    modifyTileLayoutsPrivilege: SafeGuid.parse('031dc559-2052-4230-9912-dfd1daceac31'),
    addTileLayoutsPrivilege: SafeGuid.parse('531ddb00-e9ab-4908-853e-95f59f46251b'),
    deleteTileLayoutsPrivilege: SafeGuid.parse('fa6f726f-797d-4986-ba1f-39f2b4d81e54'),
    editAlarmContextPrivilege: SafeGuid.parse('D13B9064-2A09-4E97-87A0-1509EAACB823'),
    viewChartsPrivilege: SafeGuid.parse('F37213A1-0114-4A8D-BD58-CB30BD100EC5'),
    accessCameraStreamingInfoPrivilege: SafeGuid.parse('cff2dc59-ea63-4c19-af54-748a4ae546e7'),
    overrideElevatorAccessSchedulePrivilege: SafeGuid.parse('190C9B94-61F0-428F-8AF3-203C534FCC78'),
    viewMobileUsersOnMapPrivilege: SafeGuid.parse('ED662EA4-57E3-4DF5-BBB2-6E9FC5FBBF93'),
    setCardholderSecurityClearancePrivilege: SafeGuid.parse('17d60dba-4417-4305-a532-1ab6089e817c'),
});
