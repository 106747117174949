// Autogenerated: v2/help/Entities/ApiMediaRouterRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { RoleEntity } from '../Roles/RoleEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { IMediaRouterRoleEntity, IStrategy, IUdpPortRange, IRedirector, MediaRouterRoleEntityFields } from '../../Interface/IMediaRouterRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class Strategy extends FieldObject implements IStrategy {
    // NetworkCardMac
    public get networkCardMac(): string {
        return this.getField<string>('NetworkCardMac');
    }
    public set networkCardMac(value: string) {
        this.setField<string>('NetworkCardMac', value);
    }
    // ExportTricklingEnabled
    public get exportTricklingEnabled(): boolean | null {
        return this.getNullableField<boolean>('ExportTricklingEnabled');
    }
    public set exportTricklingEnabled(value: boolean | null) {
        this.setNullableField<boolean>('ExportTricklingEnabled', value);
    }
    // LiveEnabled
    public get liveEnabled(): boolean | null {
        return this.getNullableField<boolean>('LiveEnabled');
    }
    public set liveEnabled(value: boolean | null) {
        this.setNullableField<boolean>('LiveEnabled', value);
    }
    // PlaybackEnabled
    public get playbackEnabled(): boolean | null {
        return this.getNullableField<boolean>('PlaybackEnabled');
    }
    public set playbackEnabled(value: boolean | null) {
        this.setNullableField<boolean>('PlaybackEnabled', value);
    }
    // Priority
    public get priority(): number | null {
        return this.getNullableField<number>('Priority');
    }
    public set priority(value: number | null) {
        this.setNullableField<number>('Priority', value);
    }
    // NetworkCardRtpPort
    public get networkCardRtpPort(): number | null {
        return this.getNullableField<number>('NetworkCardRtpPort');
    }
    public set networkCardRtpPort(value: number | null) {
        this.setNullableField<number>('NetworkCardRtpPort', value);
    }
    // NetworkCardRtspPort
    public get networkCardRtspPort(): number | null {
        return this.getNullableField<number>('NetworkCardRtspPort');
    }
    public set networkCardRtspPort(value: number | null) {
        this.setNullableField<number>('NetworkCardRtspPort', value);
    }
    // PublicAddresses
    public get publicAddresses(): string {
        return this.getField<string>('PublicAddresses');
    }
    public set publicAddresses(value: string) {
        this.setField<string>('PublicAddresses', value);
    }
    // NetworkCardIPAddress
    public get networkCardIPAddress(): string {
        return this.getField<string>('NetworkCardIPAddress');
    }
    public set networkCardIPAddress(value: string) {
        this.setField<string>('NetworkCardIPAddress', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('NetworkCardMac', '');
        this.setField<boolean>('ExportTricklingEnabled', false);
        this.setField<boolean>('LiveEnabled', false);
        this.setField<boolean>('PlaybackEnabled', false);
        this.setField<number>('Priority', 0);
        this.setField<number>('NetworkCardRtpPort', 0);
        this.setField<number>('NetworkCardRtspPort', 0);
        this.setField<string>('PublicAddresses', '');
        this.setField<string>('NetworkCardIPAddress', '');
    }
}

export class UdpPortRange extends FieldObject implements IUdpPortRange {
    // LowerBound
    public get lowerBound(): number {
        return this.getField<number>('LowerBound');
    }
    public set lowerBound(value: number) {
        this.setField<number>('LowerBound', value);
    }
    // UpperBound
    public get upperBound(): number {
        return this.getField<number>('UpperBound');
    }
    public set upperBound(value: number) {
        this.setField<number>('UpperBound', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('LowerBound', 0);
        this.setField<number>('UpperBound', 0);
    }
}

export class Redirector extends FieldObject implements IRedirector {
    // ServerId
    public get serverId(): IGuid {
        return this.getFieldGuid('ServerId');
    }
    public set serverId(value: IGuid) {
        this.setFieldGuid('ServerId', value);
    }
    // UdpPortRange
    public get udpPortRange(): IUdpPortRange {
        return this.getFieldObject<UdpPortRange, IUdpPortRange>(UdpPortRange, 'UdpPortRange');
    }
    public set udpPortRange(value: IUdpPortRange) {
        this.setFieldObject<IUdpPortRange>('UdpPortRange', value);
    }
    // RtpPort
    public get rtpPort(): number | null {
        return this.getNullableField<number>('RtpPort');
    }
    public set rtpPort(value: number | null) {
        this.setNullableField<number>('RtpPort', value);
    }
    // RtspPort
    public get rtspPort(): number | null {
        return this.getNullableField<number>('RtspPort');
    }
    public set rtspPort(value: number | null) {
        this.setNullableField<number>('RtspPort', value);
    }
    // MaximumLiveStreams
    public get maximumLiveStreams(): number | null {
        return this.getNullableField<number>('MaximumLiveStreams');
    }
    public set maximumLiveStreams(value: number | null) {
        this.setNullableField<number>('MaximumLiveStreams', value);
    }
    // MaximumPlaybackStreams
    public get maximumPlaybackStreams(): number | null {
        return this.getNullableField<number>('MaximumPlaybackStreams');
    }
    public set maximumPlaybackStreams(value: number | null) {
        this.setNullableField<number>('MaximumPlaybackStreams', value);
    }
    // LiveBandwidthHardLimit
    public get liveBandwidthHardLimit(): number | null {
        return this.getNullableField<number>('LiveBandwidthHardLimit');
    }
    public set liveBandwidthHardLimit(value: number | null) {
        this.setNullableField<number>('LiveBandwidthHardLimit', value);
    }
    // LiveBandwidthSoftLimit
    public get liveBandwidthSoftLimit(): number | null {
        return this.getNullableField<number>('LiveBandwidthSoftLimit');
    }
    public set liveBandwidthSoftLimit(value: number | null) {
        this.setNullableField<number>('LiveBandwidthSoftLimit', value);
    }
    // PlaybackBandwidthHardLimit
    public get playbackBandwidthHardLimit(): number | null {
        return this.getNullableField<number>('PlaybackBandwidthHardLimit');
    }
    public set playbackBandwidthHardLimit(value: number | null) {
        this.setNullableField<number>('PlaybackBandwidthHardLimit', value);
    }
    // PlaybackBandwidthSoftLimit
    public get playbackBandwidthSoftLimit(): number | null {
        return this.getNullableField<number>('PlaybackBandwidthSoftLimit');
    }
    public set playbackBandwidthSoftLimit(value: number | null) {
        this.setNullableField<number>('PlaybackBandwidthSoftLimit', value);
    }
    // Strategy
    public get strategy(): ObservableCollection<IStrategy> {
        return this.getFieldObjectArray<Strategy, IStrategy>(Strategy, 'Strategy');
    }
    public set strategy(value: ObservableCollection<IStrategy>) {
        this.setFieldObjectArray('Strategy', value);
    }
    // IsAdvanceRedirectionStrategy
    public get isAdvanceRedirectionStrategy(): boolean | null {
        return this.getNullableField<boolean>('IsAdvanceRedirectionStrategy');
    }
    public set isAdvanceRedirectionStrategy(value: boolean | null) {
        this.setNullableField<boolean>('IsAdvanceRedirectionStrategy', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('ServerId', SafeGuid.EMPTY);
        const objUdpPortRange = new UdpPortRange();
        objUdpPortRange.initializeAllFields();
        this.setFieldObject('UdpPortRange', objUdpPortRange);
        this.setField<number>('RtpPort', 0);
        this.setField<number>('RtspPort', 0);
        this.setField<number>('MaximumLiveStreams', 0);
        this.setField<number>('MaximumPlaybackStreams', 0);
        this.setField<number>('LiveBandwidthHardLimit', 0);
        this.setField<number>('LiveBandwidthSoftLimit', 0);
        this.setField<number>('PlaybackBandwidthHardLimit', 0);
        this.setField<number>('PlaybackBandwidthSoftLimit', 0);
        this.setFieldArray<IStrategy>('Strategy', new ObservableCollection<IStrategy>());
        this.setField<boolean>('IsAdvanceRedirectionStrategy', false);
    }
}

// #endregion Inner classes

export class MediaRouterRoleEntity extends RoleEntity implements IMediaRouterRoleEntity {
    // #region REST Properties

    // RtspPort
    public get rtspPort(): number {
        return this.getField<number>(MediaRouterRoleEntityFields.rtspPortField);
    }
    public set rtspPort(value: number) {
        this.setField<number>(MediaRouterRoleEntityFields.rtspPortField, value);
    }

    // StartMulticastAddress
    public get startMulticastAddress(): string {
        return this.getField<string>(MediaRouterRoleEntityFields.startMulticastAddressField);
    }
    public set startMulticastAddress(value: string) {
        this.setField<string>(MediaRouterRoleEntityFields.startMulticastAddressField, value);
    }

    // StartMulticastPort
    public get startMulticastPort(): number {
        return this.getField<number>(MediaRouterRoleEntityFields.startMulticastPortField);
    }
    public set startMulticastPort(value: number) {
        this.setField<number>(MediaRouterRoleEntityFields.startMulticastPortField, value);
    }

    // UseSecureCommunication
    public get useSecureCommunication(): boolean {
        return this.getField<boolean>(MediaRouterRoleEntityFields.useSecureCommunicationField);
    }
    public set useSecureCommunication(value: boolean) {
        this.setField<boolean>(MediaRouterRoleEntityFields.useSecureCommunicationField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getRedirectorsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRedirector> | null> {
        return this.getFieldObjectRelationAsync<Redirector, IRedirector>(Redirector, 'Redirectors', 'ServerId', false, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

