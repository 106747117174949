import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ButtonFlavor, IconSize, PopupPosition } from '@genetec/gelato';
import { GenMeltedItem, GenMeltedMenuItem, GenMenu, GenPopup, MeltedIcon, Orientation } from '@genetec/gelato-angular';
import { HideableComponent } from '@modules/shared/components/hideable/hideable.component';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { ContextMenuItemSwitcherService } from '@shared/services/context-menu/context-menu-item-switcher.service';
import { uniqueId } from 'lodash-es';
import { PlayerMode } from 'Marmot/Marmot/enums';
import { IPtzInfo } from 'RestClient/Client/Interface/ICameraEntity';
import { PtzCapabilitiesInfo } from 'RestClient/Client/Model/Video/CameraEntity';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { PtzControlsService } from './services/ptz-controls.service';
import { PtzPrivileges } from './services/ptz-privileges';
import { SupportedCommands } from './supported-commands';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-ptz-controls',
    templateUrl: './ptz-controls.component.html',
    styleUrls: ['./ptz-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [PtzControlsService],
})
/**
 * This version of the PtzControlsComponent is not a plugin, and it currently appears in the videoControls button overlay (when the PTZ icon is clicked)
 */
export class PtzControlsComponent extends HideableComponent implements OnInit, AfterViewInit {
    @Input()
    public content?: Content;

    @Input()
    public showContextMenu?: boolean;

    @Output()
    public hideRequested = new EventEmitter<boolean>();

    @ViewChild('speedMenuPopup') public speedMenuPopup!: GenPopup;
    @ViewChild('contextMenu') private contextMenu!: GenMenu;
    @ViewChild('hamburgerMenu') private hamburgerMenu!: GenMenu;

    // Public constants
    public readonly NUMBER_PAD_PRESETS_AMOUNT = 8;
    public readonly PTZ_DEFAULT_SPEED = 50;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly IconSize = IconSize;
    public readonly PopupPosition = PopupPosition;
    public readonly Orientation = Orientation;
    public readonly componentGuid = uniqueId();

    // PUBLIC members
    public speedIconToUse: MeltedIcon = MeltedIcon.SpeedLow;
    public ptzSpeed = this.PTZ_DEFAULT_SPEED;
    public ptzPrivileges$ = new Observable<PtzPrivileges>();
    public allSupportedCommands$ = new Observable<SupportedCommands>();
    public ptzCapabilities$ = new Observable<PtzCapabilitiesInfo>();
    public patternComboBoxItemList$: Observable<GenMeltedItem[] | GenMeltedMenuItem[]>;
    public presetsComboBoxItemList$: Observable<GenMeltedItem[] | GenMeltedMenuItem[]>;
    public patternComboBoxSelection: GenMeltedItem | undefined;
    public presetsComboBoxSelection: GenMeltedItem | undefined;
    public presetsNumpadItemList$: Observable<GenMeltedItem[]>;
    public playerMode$ = new Observable<PlayerMode>();
    public currentSelection: ContextMenuItem = { id: '', text: '' };
    public viewOptionsList: ContextMenuItem[] = [
        {
            id: 'ptz-mode-default',
            text: this.translateService.instant('STE_MENU_ITEM_PTZ_MODE_DEFAULT') as string,
            icon: MeltedIcon.Checkmark,
            actionItem: {
                execute: (item: ContextMenuItem): void => this.onContextMenuItemChange(item),
                isAllowed: (): boolean => true,
                canExecute: (): boolean => true,
            },
        },
        {
            id: 'ptz-mode-advanced',
            text: this.translateService.instant('STE_MENU_ITEM_PTZ_MODE_ADVANCED') as string,
            icon: MeltedIcon.None,
            actionItem: {
                execute: (item: ContextMenuItem): void => this.onContextMenuItemChange(item),
                isAllowed: (): boolean => true,
                canExecute: (): boolean => true,
            },
        },
    ];
    public hamburgerOptionsList: ContextMenuItem[] = [];
    public presetsNumpadDisabledState = new Array<boolean>(this.NUMBER_PAD_PRESETS_AMOUNT).fill(false);

    // Private Members
    // Pre-populate the dropdowns so it's compliant with the new restriction on the source of the gen-dropdown
    private patternComboBoxItemListSubject = new BehaviorSubject<GenMeltedItem[]>([
        { id: 'dropdown-no-item', text: this.translateService.instant('STE_VALUE_FORMAT_PTZ_ADVANCED_PATTERNS_DROPDOWN_NO_VALUE') as string },
    ]);
    private presetsComboBoxItemListSubject = new BehaviorSubject<GenMeltedItem[]>([
        { id: 'dropdown-no-item', text: this.translateService.instant('STE_VALUE_FORMAT_PTZ_ADVANCED_PRESETS_DROPDOWN_NO_VALUE') as string },
    ]);
    private presetsNumpadItemListSubject = new BehaviorSubject<GenMeltedItem[]>(this.generateGenericPresetsTooltip(this.NUMBER_PAD_PRESETS_AMOUNT));

    constructor(
        trackingService: TrackingService,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        public ptzControlsService: PtzControlsService,
        private contextMenuItemSwitcherService: ContextMenuItemSwitcherService,
        viewContainerRef: ViewContainerRef
    ) {
        super(viewContainerRef, trackingService);

        this.patternComboBoxItemList$ = this.patternComboBoxItemListSubject.asObservable();
        this.presetsComboBoxItemList$ = this.presetsComboBoxItemListSubject.asObservable();
        this.presetsNumpadItemList$ = this.presetsNumpadItemListSubject.asObservable();
    }

    ngOnInit(): void {
        // Process user privileges over this camera
        this.ptzPrivileges$ = this.ptzControlsService.getPtzPrivileges();

        // Process camera capabilitigites
        this.ptzCapabilities$ = this.ptzControlsService.getCameraCapabilities();

        // subscribe to the playerModeEvents
        this.playerMode$ = this.ptzControlsService.getPlayerMode();

        // Combine predicates, and then push to baseClass resulting predicate evaluation, it will handle css manipulation in order to hide the component
        combineLatest([this.ptzControlsService.getPtzPrivileges(), this.ptzControlsService.getCameraCapabilities(), this.ptzControlsService.getPlayerMode()])
            .pipe(untilDestroyed(this))
            .subscribe((vals) => {
                // compute privileges based actions for the UI
                this.addActionsInHamburger(vals[0]);

                // hide component if said predicate is true
                const predicate = vals[0] === undefined || !vals[1].ptzControlEnabled || vals[2] !== 'live';
                this.hideRequestSubject.next(predicate);
                this.hideRequested.emit(predicate);
            });

        // parse the capabilities
        this.processCapabilities();

        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        // Select "default mode" in the viewOptionsList
        this.currentSelection = this.viewOptionsList[0];

        // initialize the service
        if (this.content) {
            // Initialize PtzControlsService with the proper content information
            this.ptzControlsService.setContent(this.content);
        }
        this.changeDetectorRef.detectChanges();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Context Menu logic
    ///////////////////////////////////////////////////////////////////////////////////////
    public onContextMenuItemChange(item: ContextMenuItem): void {
        this.viewOptionsList = this.contextMenuItemSwitcherService.getNextDataSourceWithNewSelection(item.id, this.viewOptionsList);
        this.currentSelection = item;
        this.changeDetectorRef.detectChanges();
    }

    public async toggleContextMenu(): Promise<void> {
        await this.contextMenu.toggle();
        this.changeDetectorRef.markForCheck();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Hamburger Menu logic
    ///////////////////////////////////////////////////////////////////////////////////////
    public async toggleHamburgerMenu(): Promise<void> {
        await this.hamburgerMenu.toggle();
        this.changeDetectorRef.markForCheck();
    }

    public triggerHomePosition(): void {
        this.ptzControlsService.ptzGoHomePosition();
    }

    public unlockPtz(): void {
        this.ptzControlsService.ptzTriggerUnlock();
        this.changeDetectorRef.markForCheck();
    }

    public lockPtz(): void {
        this.ptzControlsService.ptzTriggerLock();
        this.changeDetectorRef.markForCheck();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Speed Menu logic
    ///////////////////////////////////////////////////////////////////////////////////////
    public async toggleSpeedMenu(): Promise<void> {
        await this.speedMenuPopup.toggle();
        this.changeDetectorRef.markForCheck();
    }

    public onSpeedSliderChange(newValue: number): void {
        this.ptzSpeed = newValue;
        const currentSpeed = this.ptzSpeed;
        if (currentSpeed <= 30) {
            this.speedIconToUse = MeltedIcon.SpeedLow;
        } else if (currentSpeed >= 31 && currentSpeed <= 60) {
            this.speedIconToUse = MeltedIcon.SpeedAverage;
        } else if (currentSpeed >= 61) {
            this.speedIconToUse = MeltedIcon.SpeedHigh;
        }
        this.changeDetectorRef.markForCheck();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Pan/Tilt Functionalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public stopPtz(): void {
        this.ptzControlsService.stopPtz();
    }

    public onUpArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: 0, y: this.ptzSpeed });
    }

    public onDiagDownRightArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: this.ptzSpeed, y: this.ptzSpeed * -1 });
    }

    public onDownArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: 0, y: this.ptzSpeed * -1 });
    }

    public onDiagDownLeftArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: this.ptzSpeed * -1, y: this.ptzSpeed * -1 });
    }

    public onRightArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: this.ptzSpeed, y: 0 });
    }

    public onLeftArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: this.ptzSpeed * -1, y: 0 });
    }

    public onDiagUpRightArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: this.ptzSpeed, y: this.ptzSpeed });
    }

    public onDiagUpLeftArrowMouseDown(): void {
        this.ptzControlsService.ptzStartPanTilt({ x: this.ptzSpeed * -1, y: this.ptzSpeed });
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Zooming Functionalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public onZoomInMouseDown(): void {
        this.ptzControlsService.setZoomSpeed(this.ptzSpeed);
        this.ptzControlsService.zoomIn();
    }

    public onZoomOutMouseDown(): void {
        this.ptzControlsService.setZoomSpeed(this.ptzSpeed * -1);
        this.ptzControlsService.zoomOut();
    }

    public onAnyZoomMouseUp(): void {
        this.ptzControlsService.stopZoom();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Presets Functionalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public goToPreset(presetNumber: string | number | undefined): void {
        if (presetNumber && presetNumber !== -1) {
            this.ptzControlsService.ptzGoToPreset(+presetNumber);
        }
    }

    public onPresetSelectionChange(newValue: GenMeltedItem): void {
        this.presetsComboBoxSelection = newValue;
        this.changeDetectorRef.markForCheck();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Patterns Functionalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public runPattern(patternNumber: string | number | undefined): void {
        if (patternNumber && patternNumber !== -1) {
            this.ptzControlsService.ptzRunPattern(+patternNumber);
        }
    }

    public onPatternSelectionChange(newValue: GenMeltedItem): void {
        this.patternComboBoxSelection = newValue;
        this.changeDetectorRef.markForCheck();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Focus Functionalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public focusIn(): void {
        this.ptzControlsService.ptzFocusIn(this.ptzSpeed);
    }

    public focusOut(): void {
        this.ptzControlsService.ptzFocusOut(this.ptzSpeed);
    }

    public stopFocus(): void {
        this.ptzControlsService.ptzStopFocus();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Iris Functionalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public irisOpen(): void {
        this.ptzControlsService.ptzIrisOpen(this.ptzSpeed);
    }

    public irisClose(): void {
        this.ptzControlsService.ptzIrisClose(this.ptzSpeed);
    }

    public stopIris(): void {
        this.ptzControlsService.ptzStopIris();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Status Functionalities
    ///////////////////////////////////////////////////////////////////////////////////////
    public getPtzLockingStateInformation(): Observable<string> {
        return this.ptzControlsService.getLockingStateInformation();
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Utilities methods
    ///////////////////////////////////////////////////////////////////////////////////////
    /**
     * Use this method to dynamically add elements based on privileges in the hamburger menu.
     */
    private addActionsInHamburger(privileges: PtzPrivileges) {
        const lockCallback: () => void = () => this.lockPtz();
        const unlockCallback: () => void = () => this.unlockPtz();

        // Only add home action if user has basic ptz privilege
        const newOptionsList: ContextMenuItem[] = [];
        this.addHomeMenuItem(newOptionsList, privileges);
        this.addLockMenuItem(newOptionsList, privileges, 'STE_MENU_ITEM_PTZ_HAMBURGER_MENU_LOCK', lockCallback);
        this.hamburgerOptionsList = newOptionsList;

        // Register lock change handler for this item
        // use the ptzService to discover if lock is applied right now
        this.getPtzLockingStateInformation()
            .pipe(untilDestroyed(this))
            .subscribe((lockString: string) => {
                this.mapLockCallback(lockString, privileges, unlockCallback, lockCallback);
            });
    }

    private mapLockCallback(lockString: string, privileges: PtzPrivileges, unlockCallback: () => void, lockCallback: () => void) {
        const newOptionsList: ContextMenuItem[] = [];
        if (lockString && lockString.length > 0 && lockString !== this.translateService.instant('STE_LABEL_PTZ_ANALOG_PTZ')) {
            // then someone has locked the camera, provide the unlock option.
            this.addHomeMenuItem(newOptionsList, privileges);
            this.addLockMenuItem(newOptionsList, privileges, 'STE_MENU_ITEM_PTZ_HAMBURGER_MENU_UNLOCK', unlockCallback);
        } else {
            this.addHomeMenuItem(newOptionsList, privileges);
            this.addLockMenuItem(newOptionsList, privileges, 'STE_MENU_ITEM_PTZ_HAMBURGER_MENU_LOCK', lockCallback);
        }
        this.hamburgerOptionsList = newOptionsList;
    }

    private addHomeMenuItem(array: ContextMenuItem[], privileges: PtzPrivileges): void {
        array.push({
            id: 'hamburger-menu-home',
            text: this.translateService.instant('STE_MENU_ITEM_PTZ_HAMBURGER_MENU_HOME') as string,
            icon: MeltedIcon.None,
            actionItem: {
                execute: () => this.triggerHomePosition(),
                isAllowed: () => privileges.allowBasicPtz,
            },
        });
    }

    private addLockMenuItem(array: ContextMenuItem[], privileges: PtzPrivileges, textKey: string, callback: () => void): void {
        array.push({
            id: 'hamburger-menu-lock',
            text: this.translateService.instant(textKey) as string,
            actionItem: {
                execute: callback,
                isAllowed: () => privileges.allowLockPtz || privileges.allowPtzLockOverride,
            },
        });
    }

    private processCapabilities(): void {
        this.ptzCapabilities$.pipe(untilDestroyed(this)).subscribe((capabilities: PtzCapabilitiesInfo) => {
            this.processPatterns(capabilities.patternBase, capabilities.numberOfPattern + capabilities.patternBase);
            this.processPresets(capabilities.presetBase, capabilities.numberOfPresets + capabilities.presetBase);
            this.processSupportedCommands(capabilities.supportedCommands);
        });
    }

    private processSupportedCommands(supportedCommands: string) {
        this.allSupportedCommands$ = this.ptzControlsService.getAllSupportedCommands(supportedCommands);
    }

    /**
     * Based on the patternAmount and patternBase, this will build an array with the corresponding GenItem. This method pushes the resulting array in the patternComboBoxItemList$ observable
     * so that the UI can properly update the values displayed in the patterns combobox.
     */
    private processPatterns(patternBase: number, patternAmount: number): void {
        const patternsNoValueDropdown = this.translateService.instant('STE_VALUE_FORMAT_PTZ_ADVANCED_PATTERNS_DROPDOWN_NO_VALUE') as string;
        const patternsDefaultString = this.translateService.instant('STE_VALUE_FORMAT_PTZ_ADVANCED_PATTERNS_DROPDOWN') as string;
        // if not empty, select first item by default for each combobox
        const emptyDropdown: GenMeltedItem = {
            id: 'combobox-no-item',
            text: patternsNoValueDropdown,
        } as GenMeltedItem;
        const patternArray: GenMeltedItem[] = [];

        // We must construct the patternArray with "default items"
        for (let i = patternBase; i < patternAmount; i++) {
            const value = stringFormat(patternsDefaultString, i.toString());
            patternArray.push({ id: i.toString(), text: value } as GenMeltedItem);
        }

        // run checks for Patterns
        this.ptzControlsService
            .getCameraPatterns()
            .pipe(untilDestroyed(this))
            .subscribe((patterns: ObservableCollection<IPtzInfo>) => {
                for (const pattern of patterns) {
                    patternArray.some((item, index) => {
                        if (+item?.id === pattern.id) {
                            patternArray[index] = { id: pattern.id.toString(), text: pattern.name };
                            return true;
                        }
                        return false;
                    });
                }
                this.patternComboBoxItemListSubject.next(patternArray);

                if (patternArray.length > 0) {
                    this.patternComboBoxSelection = patternArray[0];
                } else {
                    this.patternComboBoxItemListSubject.next([emptyDropdown]);
                    this.patternComboBoxSelection = emptyDropdown;
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    /**
     * Generates an array of MenuItem to be used as tooltips for the PresetsNumpad in the PTZ controller.
     *
     * @amount The quantity of tooltips you want. This typically should be the same amount as there are on the controller's template.
     * @returns array containing the MenuItem
     */
    private generateGenericPresetsTooltip(amount: number): GenMeltedItem[] {
        const presetsNumpadArray: GenMeltedItem[] = [];
        // Fill the array of the presets numpad
        for (let i = 0; i < amount; i++) {
            const value = stringFormat(this.translateService.instant('STE_VALUE_FORMAT_PTZ_ADVANCED_PRESETS_DROPDOWN') as string, i.toString());
            presetsNumpadArray.push({ id: i.toString(), text: value } as GenMeltedItem);
        }
        return presetsNumpadArray;
    }

    /**
     * Generates an array of MenuItem to display inside the presets dropdown. The text is generic, and will be replaced later on if they are configured
     * in the camera
     *
     * @startingId the number of the preset that will be displayed in the text
     * @desiredAmount the amount of MenuItem you want
     *
     * @returns an array of MenuItem
     */
    private generateGenericPresetsDropdownItems(startingId: number, desiredAmount: number): GenMeltedItem[] {
        const patternsDefaultString = this.translateService.instant('STE_VALUE_FORMAT_PTZ_ADVANCED_PRESETS_DROPDOWN') as string;
        const presetDropdownArray: GenMeltedItem[] = [];

        for (let i = startingId; i < desiredAmount; i++) {
            const value = stringFormat(patternsDefaultString, i.toString());
            presetDropdownArray.push({ id: i.toString(), text: value } as GenMeltedItem);
        }
        return presetDropdownArray;
    }

    /**
     * Generates a 1-1 correspondance array with the presets pad. Deactivating anyting that comes after the presetsAmount if its smaller than NUMBER_PAD_PRESETS_AMOUNT
     * and everything that comes before the configured presetsBase.
     *
     * @returns an array with either true or false as a value. True will disable the corresponding Preset numpad number. False will leave it enabled.
     */
    private deactivateCorrespondingPresets(presetsBase: number, presetsAmount: number): Array<boolean> {
        const array: Array<boolean> = new Array<boolean>(this.NUMBER_PAD_PRESETS_AMOUNT).fill(false);

        // Also deactivate all presets that comes before the presetsBase
        if (presetsBase > 0) {
            for (let i = 0; i < presetsBase; i++) {
                array[i] = true;
            }
        }
        // Also deactivate everything that comes after the threshold
        if (presetsAmount < this.NUMBER_PAD_PRESETS_AMOUNT) {
            for (let i = presetsAmount; i < this.NUMBER_PAD_PRESETS_AMOUNT; i++) {
                array[i] = true;
            }
        }
        return array;
    }

    /**
     * Based on the presetsAmount and presetsBase, this will build an array with the corresponding GenItem. This method pushes the resulting array in the presetComboBoxItemList$ observable
     * so that the UI can properly update the values displayed in the presets combobox.
     */
    private processPresets(presetsBase: number, presetsAmount: number): void {
        const presetsNoValueDropdown = this.translateService.instant('STE_VALUE_FORMAT_PTZ_ADVANCED_PRESETS_DROPDOWN_NO_VALUE') as string;

        // Run checks for presets
        const emptyDropdown: GenMeltedItem = {
            id: 'combobox-no-item',
            text: presetsNoValueDropdown,
        };

        // We must construct the presetArray with "default items"
        const presetDropdownArray: GenMeltedItem[] = this.generateGenericPresetsDropdownItems(presetsBase, presetsAmount);

        // Fill the array of the presets numpad
        const presetsNumpadArray: GenMeltedItem[] = this.generateGenericPresetsTooltip(presetsAmount);

        // Deactivate presets that are greater than the number of presets supported by the camera.
        this.presetsNumpadDisabledState = this.deactivateCorrespondingPresets(presetsBase, presetsAmount);

        // Component is ripe for change detection.
        this.changeDetectorRef.markForCheck();

        this.ptzControlsService
            .getCameraPresets()
            .pipe(untilDestroyed(this))
            .subscribe((userDefinedPresets: ObservableCollection<IPtzInfo>) => {
                for (const preset of userDefinedPresets) {
                    // Change the names in the dropdown from default to what's in the camera
                    presetDropdownArray.some((item: GenMeltedItem, index: number) => {
                        if (+item.id === preset.id) {
                            if (preset.name && preset.id) {
                                presetDropdownArray[index] = { id: preset.id.toString(), text: preset.name };
                                return true;
                            }
                        }
                        return false;
                    });

                    // Change the name for the presets numpad as well
                    presetsNumpadArray.some((item: GenMeltedItem, index: number) => {
                        if (+item.id === preset.id) {
                            if (preset.name && preset.id) {
                                presetsNumpadArray[index + presetsBase] = { id: preset.id.toString(), text: preset.name };
                                return true;
                            }
                        }
                        return false;
                    });
                }
                this.presetsNumpadItemListSubject.next(presetsNumpadArray);
                this.presetsComboBoxItemListSubject.next(presetDropdownArray);

                if (presetDropdownArray.length > 0) {
                    this.presetsComboBoxSelection = presetDropdownArray[0];
                } else {
                    this.presetsComboBoxItemListSubject.next([emptyDropdown]);
                    this.presetsComboBoxSelection = emptyDropdown;
                }
                this.changeDetectorRef.markForCheck();
            });
    }
}
