import { PtzRequest, PtzSubCommand } from './Marmot/players/WebSocket/Api/Ptz';
import { IPtzControlSession } from './Marmot/players/WebSocket/WsSessionV2';
import { ILogger } from './Marmot/utils/logger';
import { IPtzThrottlingService, ThrottledCommand } from './Marmot/ptzControl';

export class PtzThrottlingService implements IPtzThrottlingService {
    private readonly m_isEnabled = true;
    private m_timer: number | undefined;
    private readonly m_logger: ILogger;
    private readonly m_session: IPtzControlSession;
    private m_lastCommand: number = 0;

    // flag used to enable logs for more diagnostics
    private readonly enableLog = false;

    // represent the minimum delay in ms between two commands
    private readonly ThrottlingDelayInMS = 75;

    private readonly queueCommands: ThrottledCommand[] = [];

    constructor(logger: ILogger, session: IPtzControlSession) {
      this.m_logger = logger.subLogger('PtzThrottlingService');
      this.m_session = session;
    }

    private processQueue() {
      if (this.queueCommands.length > 0) {
        if (this.enableLog) {
          console.log('Processing queue (length = ' + this.queueCommands.length + ')');
        }

        const executed = new Set<PtzSubCommand>();
        // reverse the commands
        const reversed = this.queueCommands.reverse();

        reversed.forEach((throttled) => {
          const command = throttled.command;
          // ensure the command type wasn't already processed in that loop
          if (command && !executed.has(command.type)) {
            executed.add(command.type);
            this.sendCommand(command);
          } else if (this.enableLog) {
            console.log('Skipped from queue');
          }
        });
        this.queueCommands.length = 0;
      }

      // clear the timer when the queue is empty
      if (this.m_timer) {
        window.clearTimeout(this.m_timer);
        this.m_timer = undefined;
      }
    }

    /**
     * Sends a command in the queue. It will be processed only if it's different from the last one.
     * @param command throttled command
     */
    public enqueue(command: ThrottledCommand) {
      if (this.m_isEnabled) {
        const timestamp = performance.now();
        const diff = timestamp - this.m_lastCommand;

        // ensure we do not flood the backend with PTZ commands
        if (diff < this.ThrottlingDelayInMS) {
          // push it in the queue
          this.queueCommands.push(command);

          // if there is no timer yet, set one
          if (!this.m_timer) {
            this.m_timer = window.setTimeout(() => {
                this.processQueue();
            }, this.ThrottlingDelayInMS);
          }
        } else {
          // it's been long enough, execute it now
          this.m_lastCommand = timestamp;
          this.sendCommand(command.command);
        }
      } else {
        // if the throttler is disabled, execute it now
        this.sendCommand(command.command);
      }
    }

    /**
     * Only use this when you know what you are doing.
     * @param cmd command to send straight to the session.
     */
    public bypassQueue(command: PtzRequest) {
      this.queueCommands.length = 0;
      this.sendCommand(command);
    }

    /**
     * Use this method to send the command through the PtzControlSession
     * @param command ptz command to send
     */
    private sendCommand(command: PtzRequest) {
      try {
        if (this.enableLog) {
          console.log('Sending ' + command.type + ' ' + command.body?.toString());
        }

        this.m_session.sendCommand(command);
      } catch (error) {
            this.m_logger.error?.trace('Error while trying to send PTZ request:', error);
            throw error;
      }
    }
}
