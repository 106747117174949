<div class="zone-state h-100 w-100">
    <span>
        <app-custom-icon
            slot="gen-icon"
            [icon]="parameterIcon"
            [entityId]="zoneEntityId"
            [customIconId]="(zone$ | async)?.customIconId"
            [iconFontSizePx]="200"
            [customIconWidthPx]="200"
            [customIconHeightPx]="200"
        ></app-custom-icon>
    </span>
</div>
