// Autogenerated: v2/help/Classes/GeocodeReport?codegen=ts&InterfaceOnly=False&workflow=False on 2021-02-04 12:43:00 PM
import { BaseReport } from './BaseReport';
import { ISecurityCenterClient } from '../Interface/ISecurityCenterClient';
import * as Helpers from '../../Helpers/Helpers';
import { IGeocodeReport } from '../Interface/IGeocodeReport';
import { IGeocodeReportResult } from '../Interface/IGeocodeReportResult';
import { GeocodeReportResult } from './GeocodeReportResult';
import { LatLngRectangle, Coordinate } from '../Model/MapEntity';

export class GeocodeReport extends BaseReport implements IGeocodeReport {
    // #region REST Properties

    public addresses = new Set<string>();
    public boundingBox: LatLngRectangle | undefined;
    public coordinates = new Set<Coordinate>();

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super('v2/reports/Geocode');
        this._supportStreamedResponse = false;
    }

    // #endregion Constructor

    // #region Methods

    public queryAsync(client: ISecurityCenterClient, cancelToken?: Helpers.CancellationToken): Promise<IGeocodeReportResult> {
        return client.queryAsync<GeocodeReportResult, IGeocodeReportResult>(GeocodeReportResult, this, cancelToken);
    }

    public buildQuery(): string {
        let query = super.buildQuery();
        query = this._queryHelper.buildFieldObjectList(query, 'Coordinates', this.coordinates);
        query = this._queryHelper.buildElemList(query, 'Addresses', this.addresses);
        query = this._queryHelper.buildElem(query, 'BoundingBox', this.boundingBox);
        return query;
    }

    // #endregion Methods

}

