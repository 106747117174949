import { Cancellation, CancellationToken } from './CancellationToken';
import { PromiseCompletionSource } from './PromiseCompletionSource';
import { Vec2, NormalizedVec2 } from './Vec2';
import { Resolution } from './Resolution';

export class DragObserver {
  private readonly m_htmlElement: HTMLElement;
  private readonly m_disposeToken: CancellationToken;

  private m_dragging: boolean = false;
  private m_firstMovement: boolean = false;
  private m_startDragCoordinates: Vec2 = new Vec2(0, 0);
  private m_pointerDownTimeStamp: number = 0;
  private m_draggingPromise: PromiseCompletionSource<DragInfo>;

  public get Drag(): Promise<DragInfo | Cancellation> {
    return Promise.race([this.m_draggingPromise.Promise, this.m_disposeToken.Cancellation]);
  }

  constructor(htmlElement: HTMLElement) {
    this.m_htmlElement = htmlElement;

    this.m_htmlElement.addEventListener('pointerdown', this.onPointerDown, false);
    this.m_htmlElement.addEventListener('pointerup', this.onPointerUp, false);
    this.m_htmlElement.addEventListener('pointerover', this.onPointerOver, false);
    this.m_htmlElement.addEventListener('pointermove', this.onPointerMove, false);

    this.m_disposeToken = new CancellationToken();
    this.m_draggingPromise = new PromiseCompletionSource<DragInfo>();
  }

  public dispose(): void {
    this.m_htmlElement.removeEventListener('pointerdown', this.onPointerDown);
    this.m_htmlElement.removeEventListener('pointerup', this.onPointerUp);
    this.m_htmlElement.removeEventListener('pointerover', this.onPointerOver);
    this.m_htmlElement.removeEventListener('pointermove', this.onPointerMove);
    this.m_disposeToken.cancel('dispose');
  }

  private readonly onPointerDown = (event: PointerEvent): void => {
    if (this.m_dragging || !this.isMouseMainButtonDown(event)) {
      return;
    }
    this.m_pointerDownTimeStamp = event.timeStamp;
    this.m_startDragCoordinates = new Vec2(event.offsetX, event.offsetY);
    this.m_dragging = true;
    this.m_firstMovement = true;

    // Capture the mouse to ensure we can move even outside the player
    this.m_htmlElement.setPointerCapture(event.pointerId);
    event.stopPropagation();
  }

  private readonly onPointerMove = (event: PointerEvent): void => {
    if (!this.m_dragging || event.timeStamp - this.m_pointerDownTimeStamp <= 200) {
      return;
    }

    const newPosition = new Vec2(event.offsetX, event.offsetY);

    const dragEvent = new DragInfo(this.m_firstMovement, this.m_startDragCoordinates, newPosition, Resolution.build(this.m_htmlElement.scrollWidth, this.m_htmlElement.scrollHeight));

    this.m_firstMovement = false;
    this.m_startDragCoordinates = newPosition;

    const toResolve = this.m_draggingPromise;
    this.m_draggingPromise = new PromiseCompletionSource<DragInfo>();
    toResolve.resolve(dragEvent);

    event.stopPropagation();
  }

  private readonly onPointerUp = (event: PointerEvent): void => {
    this.m_htmlElement.releasePointerCapture(event.pointerId);

    if (!this.isMouseMainButtonDown(event)) {
      this.m_dragging = false;
      event.stopPropagation();
    }
  }

  private readonly onPointerOver = (event: PointerEvent): void => {
    if (!this.isMouseMainButtonDown(event)) {
      this.m_dragging = false;
    }
  }

  private isMouseMainButtonDown(event: PointerEvent): boolean {
    return ((event.buttons & 1) === 1);
  }
}

export class DragInfo {
  //Is this the first movement since the drag started. Will help give a better experience while crossing the center
  private readonly m_startOfDrag: boolean;
  private readonly m_start: Vec2;
  private readonly m_stop: Vec2;
  private readonly m_resolution: Resolution;

  public get StartOfDrag(): boolean {
    return this.m_startOfDrag;
  }

  public get Start(): Vec2 {
    return this.m_start;
  }

  public get Stop(): Vec2 {
    return this.m_stop;
  }

  public get StartNormalized(): NormalizedVec2 {
    return this.m_start.toNormalized(this.m_resolution);
  }

  public get StopNormalized(): NormalizedVec2 {
    return this.m_stop.toNormalized(this.m_resolution);
  }

  public constructor(startOfDrag: boolean, start: Vec2, stop: Vec2, resolution: Resolution) {
    this.m_startOfDrag = startOfDrag;
    this.m_start = start;
    this.m_stop = stop;
    this.m_resolution = resolution;
  }

  public toString(): string {
    return `${this.m_start.toString()} -> ${this.m_stop.toString()}`;
  }
}
