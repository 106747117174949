import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatAll, map, toArray } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { Category, CategoryApi } from '@modules/mission-control/models/category';
import { McClient } from '../mc-client.service';

@Injectable()
export class CategoryApiService {
    constructor(private _mcClient: McClient) {}

    public getCategory(id: IGuid): Observable<Category> {
        return this._mcClient.get<CategoryApi>(`v2/categories/${id.toString()}`).pipe(map((category) => Category.assign(category)));
    }

    public getCategories(): Observable<Category[]> {
        return this._mcClient.getAll<CategoryApi>('v2/categories').pipe(
            concatAll(),
            map((category) => Category.assign(category)),
            toArray()
        );
    }
}
