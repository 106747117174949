import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { SafeGuid } from 'safeguid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PluginTypes } from '../../interfaces/plugins/public/plugin-types';
import { PluginService } from '../plugin/plugin.service';
import { GlobalSearchContext, ISearchResult, SearchClient, SearchContext, SearchResult, SearchResultItem } from '../../api/api';

export class SearchResultGroup {
    public header: string;
    public groupName: string;
    public results: SearchResultItem[] = [];

    constructor(result: ISearchResult) {
        this.groupName = result.groupName ?? '';

        result.results.forEach((x) => {
            // Recreate the SearchResultItem to ensure all the field are set
            const item = new SearchResultItem({
                id: x.id,
                title: x.title,
                priority: x.priority,
                navigation: x.navigation ?? '',
                icon: (x.icon as MeltedIcon) ?? MeltedIcon.None,
            });

            this.results.push(item);
        });

        const title = result.groupName;
        const total = result.total;
        const resultsCount = result.results.length;
        this.header = title ?? '';
        if (resultsCount > 0) {
            // build the header string
            if (resultsCount <= 5 || resultsCount === total) {
                this.header = title ?? '';
            } else if (result.hasMore) {
                this.header = `${title || ''} (${resultsCount} / ${total}+)`;
            } else {
                this.header = `${title || ''} (${resultsCount} / ${total})`;
            }
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private searchStartSubject = new Subject();
    //#region Constructors

    constructor(private translateService: TranslateService, private pluginService: PluginService, private searchClient: SearchClient) {}

    //#endregion

    //#region Public Methods

    public async getSearchResults(context: SearchContext): Promise<SearchResultGroup[]> {
        const results: SearchResultGroup[] = [];
        this.searchStartSubject.next();
        const searchResults = await this.searchClient.getSearchResults(context).pipe(takeUntil(this.searchStartSubject)).toPromise();

        // ensure we have a search results to fill the client-side results
        if (searchResults) {
            searchResults.forEach((x) => results.push(new SearchResultGroup(x)));
        }

        if (context instanceof GlobalSearchContext) {
            const text = context.text;
            const taskPlugins = await this.pluginService.getPlugins(PluginTypes.Task);
            if (taskPlugins !== null) {
                const taskResults: SearchResultItem[] = [];

                for (const plugin of taskPlugins) {
                    const exposure = plugin.exposure;
                    if (exposure !== null) {
                        let contains = false;
                        const key = text.toLowerCase();

                        const tags = exposure.tags;
                        if (tags != null) {
                            // compare the tags
                            for (const tag of tags) {
                                // support translations
                                // Since tags are not shown in the ui, use the 'startsWith' operator instead of 'includes'.
                                // Otherwise, searching for 'al' would match the Tiles task that has the 'wall' tag.
                                if ((tag.startsWith('STE_') ? (this.translateService.instant(tag) as string) : tag).toLowerCase().startsWith(key)) {
                                    contains = true;
                                    break;
                                }
                            }
                        }

                        let title = '';
                        if (exposure.title) {
                            title = this.translateService.instant(exposure.title) as string;

                            // also check if we fit with the title
                            if (title.toLocaleLowerCase().includes(key)) {
                                contains = true;
                            }
                        }

                        if (contains) {
                            const item = new SearchResultItem();
                            item.id = SafeGuid.from(exposure.id);
                            item.title = title;
                            item.navigation = `/task/${exposure.id.toString()}`;
                            if (exposure.icon) {
                                item.icon = exposure.icon;
                            } else {
                                item.icon = MeltedIcon.None;
                            }
                            taskResults.push(item);
                        }
                    }
                }

                // embed the client side results
                if (taskResults.length > 0) {
                    const sr = new SearchResult();
                    sr.groupName = this.translateService.instant('STE_LABEL_TASKS') as string;
                    sr.hasMore = false;
                    sr.total = taskResults.length;
                    sr.results = Array.from(taskResults);
                    results.push(new SearchResultGroup(sr));
                }
            }
        }

        return results;
    }

    //#endregion
}
