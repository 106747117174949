import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IWebApiResponse } from '@modules/mission-control/models/web-api-response';
import { MCCommandBase } from '../../models/commands/command';
import { McNotificationService } from '../mc-notification.service';
import { McClient } from '../mc-client.service';

@Injectable()
export class IncidentCommandService {
    constructor(private _mcClient: McClient, private _notificationService: McNotificationService) {}

    public execute(command: MCCommandBase): Observable<IWebApiResponse> {
        return this._mcClient.post(command.url, command.body).pipe(
            tap((x) => this._notificationService.notifyFromCommandResponse(command, x.statusCode)),
            tap((x) => {
                if (!this.isSuccessStatusCode(x)) throw new Error('Command failed');
            })
        );
    }

    public tryExecute(command: MCCommandBase): Observable<boolean> {
        return this._mcClient.post(command.url, command.body).pipe(
            tap((x) => this._notificationService.notifyFromCommandResponse(command, x.statusCode)),
            map((response) => this.isSuccessStatusCode(response))
        );
    }

    private isSuccessStatusCode(response: IWebApiResponse): boolean {
        return response.statusCode >= 200 && response.statusCode < 300;
    }
}
