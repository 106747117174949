<app-filter-host [filterDescriptor]="descriptor" [state]="state" (filterToggled)="onFilterToggled($event)" [popupPosition]="popupPosition">
    <div class="tree-view-container">
        <!-- Search -->
        <gen-search-input
            *ngIf="isSearchAvailable"
            [ngModel]="searchText"
            (ngModelChange)="onSearchTextChanged($event)"
            [genDebounce]="1000"
            placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
        >
        </gen-search-input>

        <div class="tree-items-container">
            <gen-spinner *ngIf="isTreeViewLoading" class="spinner-align-center text-bold">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>

            <div *ngIf="isNoItemsFound" class="no-elements-align-center">
                <gen-text>
                    {{ 'STE_LABEL_NORESULTS' | translate }}
                </gen-text>
            </div>

            <gen-tree *ngIf="!onlyShowCheckedItems && !isTreeViewLoading" [selectionType]="SelectionTypes.Multiple">
                <gen-tree-item *ngFor="let item of displayedTreeItems" [selected]="item.isChecked" (selectedChange)="onSelectedItemsChange(item, $event)">
                    <gen-item>
                        <gen-icon *ngIf="item?.icon" [icon]="item.icon"></gen-icon>
                        <gen-text>{{ item.text }}</gen-text>
                    </gen-item>
                    <gen-tree *ngIf="item.children">
                        <gen-tree-item *ngFor="let child of item.children" [selected]="child.isChecked" (selectedChange)="onSelectedItemsChange(child, $event)">
                            <gen-item>
                                <gen-icon *ngIf="child?.icon" [icon]="child.icon"></gen-icon>
                                <gen-text> {{ child.text }} </gen-text>
                            </gen-item>
                        </gen-tree-item>
                    </gen-tree>
                </gen-tree-item>
            </gen-tree>

            <!-- This should be unmelted inside another scope because it needs a lot of refactoring some refactoring has already been started
                in the PR number : 8480.
            -->
            <gen-melted-list
                *ngIf="onlyShowCheckedItems"
                [genItemsSource]="displayedShowOnlySelectedTreeItems"
                [genSelectedItems]="showOnlySelectedTreeItems"
                [genSelectionType]="MeltedSelectionType.List.Multiple"
                (genSelectedItemsChange)="onShowOnlySelectedItemsChange($event)"
            >
            </gen-melted-list>
        </div>

        <div class="tree-view-footer pl-1 pr-1">
            <gen-toggle-button
                *ngIf="isViewSelectedAvailable && isOnlyShowSelectedItemsVisible"
                class="pl-1"
                [(genIsChecked)]="onlyShowCheckedItems"
                (genIsCheckedChange)="onOnlyShowCheckedItemsClicked()"
                [genIcon]="MeltedIcon.Show"
                [genIconChecked]="MeltedIcon.Hide"
                appTooltip="{{ (onlyShowCheckedItems ? 'STE_ACTION_SHOW_ALL' : 'STE_ACTION_SHOWSELECTEDITEMS') | translate }}"
            >
            </gen-toggle-button>

            <ng-container *ngIf="isSelectAllAvailable">
                <gen-button
                    class="pl-1"
                    [flavor]="ButtonFlavor.Flat"
                    [hidden]="isAllChecked || onlyShowCheckedItems"
                    appTooltip="{{ 'STE_ACTION_CHECKALL' | translate }}"
                    (click)="onCheckAllItemsClicked()"
                >
                    <gen-icon [icon]="Icon.CheckAll"></gen-icon>
                </gen-button>
                <gen-button [flavor]="ButtonFlavor.Flat" [hidden]="isUncheckAllHidden" appTooltip="{{ 'STE_ACTION_UNCHECKALL' | translate }}" (click)="onUncheckAllItemsClicked()">
                    <gen-icon [icon]="Icon.Close"></gen-icon>
                </gen-button>
            </ng-container>
        </div>
    </div>
</app-filter-host>
