import { State, StateContext } from '@ngxs/store';
import { EmitterAction } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import { LatLngBounds } from '@genetec/web-maps';
import { Receiver, Selector } from '../../../../store';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface MapStateModel {
    selectedMap: IGuid | undefined;
    selectedMapObject: IGuid | undefined;
    viewArea: LatLngBounds | undefined;
}

@State<MapStateModel>({
    name: 'mapState',
    defaults: { selectedMap: undefined, selectedMapObject: undefined, viewArea: undefined },
})
@Injectable()
export class MapState {
    @Selector()
    public static selectedMap(state: MapStateModel): IGuid | undefined {
        return state.selectedMap;
    }

    @Selector()
    public static selectedMapObject(state: MapStateModel): IGuid | undefined {
        return state.selectedMapObject;
    }

    @Selector()
    public static viewArea(state: MapStateModel): LatLngBounds | undefined {
        return state.viewArea;
    }

    @Receiver()
    public static setSelectedMap({ setState }: StateContext<MapStateModel>, { payload }: EmitterAction<IGuid | undefined>): void {
        setState((state: MapStateModel) => {
            state.selectedMap = payload;
            return state;
        });
    }

    @Receiver()
    public static setStateViewArea({ setState }: StateContext<MapStateModel>, { payload }: EmitterAction<LatLngBounds | undefined>): void {
        setState((state: MapStateModel) => {
            state.viewArea = payload;
            return state;
        });
    }

    @Receiver()
    public static setSelectedMapObject({ getState, setState }: StateContext<MapStateModel>, { payload }: EmitterAction<IGuid | undefined>): void {
        const currentState = getState();
        if (currentState.selectedMapObject?.equals(payload)) {
            return;
        }

        setState((state: MapStateModel) => {
            state.selectedMapObject = payload;
            return state;
        });
    }
}
