import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ButtonFlavor } from '@genetec/gelato-angular';
import { CardholderEditContextState } from '@modules/access-control/cardholder-edit-context.state';
import { BypassAntipassbackRules } from '@modules/access-control/enumerations';
import { CardholderRelationType } from '@modules/access-control/services/cardholder-edit-service';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TypedSimpleChanges } from '@shared/utilities/typed-simple-changes';
import { Observable } from 'rxjs';
import { IGuid } from 'safeguid';
import { AdvancedFormGroup } from '../cardholder-edit-form-group';

@Component({
    selector: 'app-cardholder-advanced-form',
    templateUrl: './cardholder-advanced-form.component.html',
    styleUrls: ['./cardholder-advanced-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardholderAdvancedFormComponent implements OnChanges {
    @ViewSelectSnapshot(CardholderEditContextState.isPrivilegeGranted('modifyCardholderNamePrivilege'))
    public hasChangeCardholderNamePrivilege!: boolean;

    @ViewSelectSnapshot(CardholderEditContextState.isPrivilegeGranted('changeCardholderOptionsPrivilege'))
    public hasChangeCardholderOptionsPrivilege!: boolean;

    @ViewSelectSnapshot(CardholderEditContextState.isPrivilegeGranted('generalSettingsLogicalIdPrivilege'))
    public hasGeneralSettingsLogicalIdPrivilege!: boolean;

    @Input() formGroup?: AdvancedFormGroup;
    @Input() formReset$?: Observable<IGuid | undefined>;
    @Input() readonly = false;

    public readonly BypassAntipassbackRules = BypassAntipassbackRules;
    public readonly CardholderRelationType = CardholderRelationType;
    public readonly ButtonFlavor = ButtonFlavor;

    constructor() {}

    public ngOnChanges(changes: TypedSimpleChanges<typeof this>): void {
        // TODO: Use gelato component disabled input instead when this bug is fixed https://dev.azure.com/GenetecCentral/UXDev/_workitems/edit/70293/
        if (changes.readonly.currentValue) {
            this.formGroup?.disable({ emitEvent: false });
        } else {
            this.formGroup?.enable({ emitEvent: false });
        }
    }

    public onPassbackModeChanged(event: CustomEvent<string>): void {
        // Workaround while the bug preventing correct value changes with gen-radio+reactive forms is still there
        this.formGroup?.controls.bypassAntipassbackRules.setValue(event.detail as BypassAntipassbackRules);
    }

    public onClearanceModeChanged(event: CustomEvent<string>): void {
        // Workaround while the bug preventing correct value changes with gen-radio+reactive forms is still there
        this.formGroup?.controls.isSecurityClearanceInheritedFromGroup.setValue(event.detail);

        this.formGroup?.controls.securityClearance.reset();
    }
}
