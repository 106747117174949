import { Component } from '@angular/core';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { VideoContentTypes } from '@modules/video/enumerations/video-content-types';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { CameraBaseComponent } from '../../camera-base.component';

const isContentSupported = (content: Content, pluginType: IGuid) => {
    if (content) {
        return content.type.equals(VideoContentTypes.Video) && (!content.contextContent || content.contextContent?.type.equals(VideoContentTypes.Event));
    }
    return false;
};

@Component({
    selector: 'app-camera-entity-state',
    templateUrl: './camera-entity-state.component.html',
    styleUrls: ['./camera-entity-state.component.scss'],
})
@InternalContentPluginDescriptor({
    type: CameraEntityStateComponent,
    pluginTypes: [PluginTypes.TileHeader],
    exposure: {
        id: CameraEntityStateComponent.pluginId,
        priority: -1,
    },
    isContentSupported,
    requirements: { features: [KnownFeatures.videoId], licenses: [KnownLicenses.video], enabledFeatureFlags: [GeneralFeatureFlags.EntityState] },
})
export class CameraEntityStateComponent extends CameraBaseComponent {
    public static pluginId = SafeGuid.parse('23147670-D00A-40B7-A9FC-FE8A840A8C95');

    constructor(securityCenterClientService: SecurityCenterClientService, translateService: TranslateService, trackingService: TrackingService) {
        super(securityCenterClientService, translateService, trackingService);
    }
}
