import { IWebSocketRequest } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';
import { Serializer } from '../../../utils/Serializer';

/**
 * @beta
 * */
export enum PtzSubCommand {
  PanTilt = 'PanTilt',
  Zoom = 'Zoom',
  GoTo = 'GoTo',
  GoToPreset = 'GoToPreset',
  GoHome = 'GoHome',
  Lock = 'Lock',
  Unlock = 'Unlock',
  RunPattern = 'RunPattern',
  Focus = 'Focus',
  Iris = 'Iris'
}

/**
 * @public
 * */
export enum PtzFocusDistance {
  Near,
  Far
}

/**
 * @public
 * */
export enum PtzIrisOperation {
  Open,
  Close
}

/**
 * @beta
 * */
export class PtzRequest implements IWebSocketRequest {
  private static readonly command = 'Ptz';

  private static readonly subCommandSeparator = ':';

  private readonly m_header: WebSocketMessageHeader;
  private readonly m_type: PtzSubCommand;
  private readonly m_requestBody: PtzRequestBody;

  public get body(): PtzRequestBody {
    return this.m_requestBody;
  }

  public get channel(): number {
    return this.m_header.channel;
  }

  public get expectResponse(): boolean {
    return false;
  }

  public get type(): PtzSubCommand {
    return this.m_type;
  }

  constructor(channel: number, subCommand: PtzSubCommand, requestBody: PtzRequestBody) {
    this.m_type = subCommand;
    this.m_requestBody = requestBody;
    this.m_header = new WebSocketMessageHeader(channel, PtzRequest.command + PtzRequest.subCommandSeparator + subCommand);
  }

  public serialize(): ArrayBuffer {
    const header = new DataView(this.m_header.serialize());
    const body = new DataView(this.m_requestBody.serialize());

    return Serializer.combineDataView(header, body);
  }

  public debugStatus(indent: number): string { // eslint-disable-line @typescript-eslint/no-unused-vars
    return 'PtzRequest';
  }
}

/**
 * @beta
 * */
export class PanTiltBody implements PtzRequestBody {
  public readonly Pan: number | null;
  public readonly Tilt: number | null;

  constructor(pan: number | null, tilt: number | null) {
    this.Pan = pan;
    this.Tilt = tilt;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `Pan: ${this.Pan}, Tilt: ${this.Tilt}`;
  }
}

/**
 * @beta
 * */
export class ZoomBody implements PtzRequestBody {
  public readonly Zoom: number | null;

  constructor(zoom: number | null) {
    this.Zoom = zoom;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `Zoom: ${this.Zoom}`;
  }
}

/**
 * @beta
 * */
export class GoToBody implements PtzRequestBody {
  public readonly IsAbsolute: boolean;
  public readonly Pan: number;
  public readonly Tilt: number;
  public readonly Zoom: number;

  constructor(isAbsolute: boolean, pan: number, tilt: number, zoom: number) {
    this.IsAbsolute = isAbsolute;
    this.Pan = pan;
    this.Tilt = tilt;
    this.Zoom = zoom;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `IsAbsolute: ${this.IsAbsolute}, Pan: ${this.Pan}, Tilt: ${this.Tilt}, Zoom: ${this.Zoom}`;
  }
}

/**
 * @beta
 * */
export class GoToPresetBody implements PtzRequestBody {
  public readonly Preset: number;

  constructor(preset: number) {
    this.Preset = preset;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `Preset: ${this.Preset}`;
  }
}

/**
 * @beta
 * */
export class GoHomeBody implements PtzRequestBody {
  constructor() { }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return 'GoHomeBody';
  }
}

/**
 * @beta
 * */
export class RunPatternBody implements PtzRequestBody {
  public readonly Pattern: number;

  constructor(pattern: number) {
    this.Pattern = pattern;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `Pattern: ${this.Pattern}`;
  }
}

export class LockBody implements PtzRequestBody {
  constructor() { }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return 'LockBody';
  }
}

export class UnlockBody implements PtzRequestBody {
  constructor() { }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return 'UnlockBody';
  }
}

/**
 * @beta
 * */
export class FocusBody implements PtzRequestBody {
  public readonly Distance: PtzFocusDistance | null;
  public readonly Speed: number | null;

  constructor(distance: PtzFocusDistance | null, speed: number | null) {
    this.Distance = distance;
    this.Speed = distance !== null ? speed : null;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `Distance: ${this.Distance}, Speed: ${this.Speed}`;
  }
}

/**
 * @beta
 * */
export class IrisBody {
  public readonly Operation: PtzIrisOperation | null;
  public readonly Speed: number | null;

  constructor(operation: PtzIrisOperation | null, speed: number | null) {
    this.Operation = operation;
    this.Speed = operation !== null ? speed : null;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `Operation: ${this.Operation}, Speed: ${this.Speed}`;
  }
}

/**
 * Empty Interface to determine contract of throttledCommands
 */
export interface PtzRequestBody {
  serialize(): ArrayBuffer;
  toString(): string;
}
