import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { PriorityService } from '../services/priority/priority.service';

@Pipe({ name: 'priorityName' })
export class PriorityNamePipe implements PipeTransform {
    constructor(private priorityService: PriorityService, private translateService: TranslateService) {}

    public transform(priorityId: IGuid): Observable<string> {
        return this.priorityService.getPriority(priorityId).pipe(map((x) => x?.name ?? (this.translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string)));
    }
}
