import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionType } from '@genetec/gelato';
import { TextFlavor } from '@genetec/gelato-angular';
import { TreeItem } from '@modules/shared/interfaces/tree-item/tree-item';
/**
 * This component is responsible to display tree items with children and to relay selection changes
 * back to the parent. This component is necessary because the treeDataSourceDirective is currently not working with
 * SelectionType.Multiple trees. And specifically in the EventsOptions, we need to sync selection in the filteredTree back into the
 * regular display tree.
 */
@Component({
    selector: 'app-events-option-tree',
    templateUrl: './events-option-tree.component.html',
})
export class EventsOptionTreeComponent {
    @Input() eventsSource: TreeItem[] | null = null;
    @Input() keepParentExpanded?: boolean = false;
    @Output() selectionChanged = new EventEmitter<TreeItem[]>();

    public readonly SelectionType = SelectionType;
    public readonly TextFlavor = TextFlavor;

    public onSelectedItemsChange(selectedItem: TreeItem, event: CustomEvent<boolean>): void {
        selectedItem.isChecked = event.detail;
        this.selectionChanged.emit(this.eventsSource ?? []);
    }
}
