// Autogenerated: v2/help/Entities/ApiUserTask?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:25 AM
import { IGuid } from 'safeguid';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IUserTaskEntity, UserTaskEntityFields } from '../Interface/IUserTaskEntity';
import { Entity } from './Entity';

export class UserTaskEntity extends Entity implements IUserTaskEntity {
    // #region REST Properties

    // Context
    public get context(): string {
        return this.getField<string>(UserTaskEntityFields.contextField, ValidFlags.Specific);
    }
    public set context(value: string) {
        this.setField<string>(UserTaskEntityFields.contextField, value);
    }

    // Guid
    public get guid(): IGuid {
        return this.getFieldGuid(UserTaskEntityFields.guidField, ValidFlags.Specific);
    }

    // IsSystemTask
    public get isSystemTask(): boolean {
        return this.getField<boolean>(UserTaskEntityFields.isSystemTaskField);
    }

    // Path
    public get path(): string {
        return this.getField<string>(UserTaskEntityFields.pathField, ValidFlags.Specific);
    }
    public set path(value: string) {
        this.setField<string>(UserTaskEntityFields.pathField, value);
    }

    // TaskSubType
    public get taskSubType(): IGuid {
        return this.getFieldGuid(UserTaskEntityFields.taskSubTypeField, ValidFlags.Specific);
    }
    public set taskSubType(value: IGuid) {
        this.setFieldGuid(UserTaskEntityFields.taskSubTypeField, value);
    }

    // TaskType
    public get taskType(): IGuid {
        return this.getFieldGuid(UserTaskEntityFields.taskTypeField, ValidFlags.Specific);
    }
    public set taskType(value: IGuid) {
        this.setFieldGuid(UserTaskEntityFields.taskTypeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.UserTasks);
    }

    // #endregion Constructor

}

