import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const GeneralCommands = Constants({
    ArmZone: SafeGuid.parse('A78BDC34-3076-4B1A-BDEE-3816DDC92FDA'),
});
