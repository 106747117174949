// Autogenerated: v2/help/Entities/ApiZoneManagementRole?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:18 PM
import { RoleEntity } from './RoleEntity';
import { IZoneManagementRoleEntity, ZoneManagementRoleEntityFields } from '../../Interface/IZoneManagementRoleEntity';

export class ZoneManagementRoleEntity extends RoleEntity implements IZoneManagementRoleEntity {
    // #region REST Properties

    // KeepHistory
    public get keepHistory(): boolean {
        return this.getField<boolean>(ZoneManagementRoleEntityFields.keepHistoryField);
    }
    public set keepHistory(value: boolean) {
        this.setField<boolean>(ZoneManagementRoleEntityFields.keepHistoryField, value);
    }

    // LogRetentionDays
    public get logRetentionDays(): number {
        return this.getField<number>(ZoneManagementRoleEntityFields.logRetentionDaysField);
    }
    public set logRetentionDays(value: number) {
        this.setField<number>(ZoneManagementRoleEntityFields.logRetentionDaysField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

