import { AfterViewInit, Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { TrackedGenModalBaseComponent } from '@modules/shared/components/tracked/tracked-gen-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({ template: '' })
export abstract class TypedConnectionAwareModalComponent<T> extends TrackedGenModalBaseComponent<T> implements AfterViewInit, OnInit, OnDestroy {
    protected loggedIn$?: Observable<boolean>;

    constructor(protected authService: AuthService, injector: Injector, trackingService: TrackingService) {
        super(injector, trackingService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        if (!this.loggedIn$) {
            this.loggedIn$ = this.authService.loggedIn$;
        }
        // Lookout for loggedIn state change
        this.loggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn: boolean) => {
            if (!isLoggedIn) {
                // Close modal if no longer logged in
                this.hideModal();
            }
        });
    }

    public hideModal(): void {
        this.genModalComponent?.hide();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
@Component({ template: '' })
export abstract class ConnectionAwareModalComponent extends TypedConnectionAwareModalComponent<void> {}
