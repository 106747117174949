import { Component, ElementRef, EventEmitter, HostListener, Inject, Injector, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { PLUGIN_CONTENT, PLUGIN_CONTEXT } from '@modules/shared/tokens';
import { ClassInstance } from '@modules/shared/utilities/types';
import { IGuid } from 'safeguid';
import { PluginItem } from '../../interfaces/plugins/internal/pluginItem';
import { ContextTypes } from '../../interfaces/plugins/public/context-types';
import { COMMANDS_SERVICE, CommandsSubscription, PluginComponentCommandHandler, CommandsService } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { Content } from '../../interfaces/plugins/public/plugin-public.interface';
import { LoggerService } from '../../services/logger/logger.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Component({
    selector: 'app-plugin-host',
    template: '<div #dynamicTemplate></div>',
})
export class PluginHostComponent implements OnDestroy {
    @ViewChild('dynamicTemplate', { static: true, read: ViewContainerRef }) public pluginHost?: ViewContainerRef;

    @Output() public pluginClick = new EventEmitter<PluginHostClickEvent>();

    @Input()
    public set pluginItem(item: PluginItem | undefined) {
        this.setPluginItem(item);
    }

    @Input()
    public set dataContext(dataContext: unknown) {
        this.setDataContext(dataContext);
    }

    private componentInstance?: ClassInstance;
    private theDataContext: unknown;
    private initData: unknown;
    private commandsSubscription!: CommandsSubscription;

    constructor(
        private hostElement: ElementRef,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        private loggerService: LoggerService,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    ) {}

    @HostListener('click', ['$event'])
    public onClickPressed(event: Event): void {
        const args: PluginHostClickEvent = { initData: this.initData, dataContext: this.theDataContext, handled: false };
        this.pluginClick.emit(args);
        if (args.handled) {
            event.stopPropagation();
        }
    }

    ngOnDestroy(): void {
        if (this.commandsSubscription) {
            this.commandsSubscription.unsubscribe();
        }
    }

    private setDataContext(dataContext: unknown) {
        this.theDataContext = dataContext;

        if (typeof this.componentInstance?.setDataContext === 'function') {
            this.componentInstance.setDataContext(dataContext);
        }
    }

    private setPluginItem(item: PluginItem | undefined) {
        if (this.pluginHost !== undefined) {
            this.pluginHost.clear();

            try {
                if (item !== undefined) {
                    const componentRef = this.viewContainerRef.createComponent(item.type, {
                        injector: Injector.create({
                            providers: [
                                {
                                    provide: PLUGIN_CONTENT,
                                    useValue: item.data,
                                },
                                {
                                    provide: PLUGIN_CONTEXT,
                                    useValue: this.dataContext,
                                },
                            ],
                            parent: this.injector,
                        }),
                    });
                    this.pluginHost.insert(componentRef.hostView);
                    this.componentInstance = componentRef.instance as ClassInstance;
                    this.initData = item.data;
                    this.applyInitData(this.componentInstance);
                    item.commandTarget = this.hostElement.nativeElement as HTMLElement | undefined;

                    this.setDataContext(this.theDataContext);

                    if (this.isCommandHandler(componentRef.instance)) {
                        this.setCommandBindings(componentRef.instance, item.exposure.id);
                    }
                }
            } catch (e) {
                this.loggerService.traceError('unable to insert plugin ' + (e as Error).message);
            }
        }
    }

    private setCommandBindings(commandHandler: PluginComponentCommandHandler, contextId: IGuid) {
        setTimeout(() => {
            const bindings = commandHandler.getCommandBindings();
            bindings.getCommands().forEach((command) => {
                command.target = this.hostElement.nativeElement as Element;
                command.targetData = { type: ContextTypes.Content, data: this.initData };
            });
            this.commandsSubscription = this.commandsService.subscribe(bindings, {
                priority: 200,
            });
        });
    }

    private isCommandHandler(plugin: unknown): plugin is PluginComponentCommandHandler {
        return plugin !== null && typeof plugin === 'object' && 'getCommandBindings' in plugin;
    }

    private applyInitData(plugin: ClassInstance) {
        if ('setInitData' in plugin && typeof plugin.setInitData === 'function') {
            plugin.setInitData(this.initData);
        } else if ('setContent' in plugin && typeof plugin.setContent === 'function') {
            plugin.setContent(this.initData as Content);
        }
    }
}

export interface PluginHostClickEvent {
    initData: unknown;
    dataContext: unknown;
    handled: boolean;
}
