import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Icon, IconSize, TextFlavor, ImageFit, ImageFlavor, TableColumnHeaderSort, TableFlavor } from '@genetec/gelato';
import { Marker, Polyline } from '@genetec/web-maps';
import { ButtonFlavor, Gelato, GenPopup, GenToastService, ToastFlavor } from '@genetec/gelato-angular';
import { MapService } from '@modules/maps/services/map/map-service';
import { TranslateService } from '@ngx-translate/core';
import { IWebMapObject } from '@modules/maps/controllers/map.controller.data';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { ResourcesService } from '@modules/shared/services/resources/resources.service';
import { IGuid } from 'safeguid';
import { ViewMode } from '@modules/correlation/enumerations/view-mode';
import { RowResult } from '@modules/correlation/interfaces/row-result';
import moment from 'moment-timezone';
import { delayAsPromise, toError } from '@src/app/utilities';
import { isEqual } from 'lodash-es';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { Debouncer } from 'RestClient/Helpers/Debouncer';
import { CommandUsage } from '@modules/shared/services/commands/commands-usage/command-usage';
import { trigger, transition, style, animate } from '@angular/animations';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UnifiedReportFeatureFlags } from '@modules/correlation/feature-flags';
import { CorrelationService } from '../../../services/correlation.service';
import { InvestigateSideContextDataService } from '../services/investigate-side-context-data.service';
import { CorrelationQueryResult, CorrelationQueryResultItem, FieldType } from '../../../api/api';
import { InvestigateDataContext } from '../services/investigate-data.context';
import { ColumnDescriptor } from '../services/investigate-data.interfaces';
import { RowActionsService } from '../services/row-actions.service';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

type SortingType = 'asc' | 'desc';
interface ColumnSorting {
    sortingType: SortingType;
    columnFieldKey: string;
}
@UntilDestroy()
@Component({
    selector: 'app-investigate-results-container',
    templateUrl: './investigate-results-container.component.html',
    styleUrls: ['./investigate-results-container.component.scss'],
    providers: [MapService, InvestigateSideContextDataService, RowActionsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [trigger('inOutAnimation', [transition(':enter', [style({ opacity: 0 }), animate('0.3s ease-in-out', style({ opacity: 1 }))])])],
})
export class InvestigateResultsContainerComponent extends Gelato implements OnInit, OnDestroy {
    //#region Properties

    @Input()
    public get viewMode(): ViewMode {
        return this.privateViewMode;
    }
    public set viewMode(mode: ViewMode) {
        this.privateViewMode = mode;
        // TODO : Uncomment when implementing view mode (tile/grid/map)
        // if (mode === ViewMode.Map) {
        //     void this.tryInitializeMap();
        // }
        //  else if (mode === ViewMode.Tile || mode === ViewMode.Grid) {
        //     void this.loadImagesAsync();
        // }
    }

    @Output()
    public selectionChanged = new EventEmitter<string>();

    @Output()
    public loadingMessageChanged = new EventEmitter<string>();

    @Output()
    public queryingChanged = new EventEmitter<boolean>();

    @Output()
    public resultsChanged = new EventEmitter<RowResult[]>();

    @SelectSnapshot(FeatureFlagsState.featureFlags(UnifiedReportFeatureFlags.UnifiedReportGenTable))
    public isUnifiedReportGenTableFeatureFlagEnabled!: boolean;

    public readonly ViewMode = ViewMode;
    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly TextFlavor = TextFlavor;
    public readonly TableColumnHeaderSort = TableColumnHeaderSort;
    public readonly FieldType = FieldType;
    public readonly TableFlavor = TableFlavor;
    public readonly ButtonFlavor = ButtonFlavor;

    public readonly infiniteScrollThresholdPx = 100;

    public currentResult: RowResult | null = null;

    public visibleResults$ = new BehaviorSubject([] as RowResult[]);
    public allResults?: RowResult[];

    public columns: ColumnDescriptor[] = [];
    public visibleColumns: string[] = [];
    public columnSorting: ColumnSorting;
    public tableBusy$: Observable<boolean> | undefined;
    public isMultiDataType = false;

    public cachedIcons: string[] = [];

    public hasMoreResults?: boolean = true;

    public scrollBatchSize = 50;

    public currentHoveredRowDatumKey = '';
    public isLoadRowActionsDebouncing$: Observable<boolean>;
    public areRowActionsReady$: Observable<boolean>;
    public expandedCommandUsages$: Observable<CommandUsage[]>;
    public collapsedCommandUsages$: Observable<CommandUsage[]>;

    private privateViewMode = ViewMode.Tile;
    private downloadMapObjects = true;

    private isMapInitialized = false;

    private currentMapObjects: IWebMapObject[] = [];
    private allMapObjects: IWebMapObject[] = [];

    private readonly defaultColumnSorting: ColumnSorting = { sortingType: 'desc', columnFieldKey: 'timestamp' };

    private readonly defaultMaximumRowActions = 3;
    private isLoadRowActionsDebouncingSubject = new BehaviorSubject<boolean>(false);
    private loadRowActionsDebouncer = new Debouncer(false, () => this.loadRowActions(), 1000, -1);

    //#endregion

    //#region Constructor

    constructor(
        public mapService: MapService,
        private translateService: TranslateService,
        private correlationService: CorrelationService,
        private loggerService: LoggerService,
        private resourcesService: ResourcesService,
        private dataContext: InvestigateDataContext,
        private toastService: GenToastService,
        private elementRef: ElementRef<HTMLElement>,
        private changeDetector: ChangeDetectorRef,
        private rowActionsService: RowActionsService
    ) {
        super();
        this.columnSorting = this.defaultColumnSorting;
        this.rowActionsService.setMaximumVisibleCommands(this.defaultMaximumRowActions);
        this.isLoadRowActionsDebouncing$ = this.isLoadRowActionsDebouncingSubject.asObservable();
        this.areRowActionsReady$ = this.rowActionsService.areRowActionsReady$;
        this.expandedCommandUsages$ = this.rowActionsService.expandedCommandUsages$;
        this.collapsedCommandUsages$ = this.rowActionsService.collapsedCommandUsages$;
    }

    //#endregion

    //#region Public Methods

    ngOnInit(): void {
        this.dataContext.columns$.pipe(untilDestroyed(this)).subscribe((columnDescriptors) => this.handleColumnsChanged(columnDescriptors));
        this.tableBusy$ = this.dataContext.tableBusy$;
    }

    ngOnDestroy(): void {
        this.mapService.destroy();
    }

    public handleColumnsChanged(cols: ColumnDescriptor[]): void {
        // Dont trigger change if nothing changed
        if (isEqual(this.columns, cols)) return;
        this.columns = cols;
        this.isMultiDataType = (this.dataContext.whatFilter && this.dataContext.whatFilter.dataTypes.length > 1) ?? false;
        this.changeDetector.detectChanges();
    }

    public setResults(report: RowResult[]): void {
        this.downloadMapObjects = true;
        this.allResults = report;

        this.allResults = this.sortResults(this.columnSorting);

        this.visibleResults$.next([]);
        this.changeDetector.detectChanges();
        this.loadMore();
    }

    public loadMore(): void {
        if (this.allResults) {
            const visibleResults = this.visibleResults$.getValue();
            const newVisibleResults = this.allResults.slice(visibleResults.length, Math.min(visibleResults.length + this.scrollBatchSize, this.allResults.length));
            newVisibleResults.forEach((rowResult: RowResult) => visibleResults.push(rowResult));
            this.visibleResults$.next(visibleResults);
            this.hasMoreResults = visibleResults.length < this.allResults.length;
            this.changeDetector.detectChanges();
            this.loadImagesAsync(newVisibleResults).fireAndForget();
        }
    }

    public fetchData(): void {
        // Let Gelato the time to show a spinner before pushing new rows in the table
        // TODO : Remove when https://dev.azure.com/GenetecCentral/UXDev/_workitems/edit/51093/ is fixed
        delayAsPromise(100)
            .then(() => this.loadMore())
            .fireAndForget();
    }

    public onSortChange(columnHeaderSort: CustomEvent<TableColumnHeaderSort>, column: ColumnDescriptor): void {
        column.sortOrder = columnHeaderSort.detail;
        this.sort(column);
    }

    public onSortClick(column: ColumnDescriptor): void {
        let sortOrder;
        if (column.sortOrder === TableColumnHeaderSort.Ascending) {
            sortOrder = TableColumnHeaderSort.Descending;
        } else if (column.sortOrder === TableColumnHeaderSort.Descending) {
            sortOrder = TableColumnHeaderSort.None;
        } else {
            sortOrder = TableColumnHeaderSort.Ascending;
        }
        this.columns.forEach((col) => (col.sortOrder = undefined));
        column.sortOrder = sortOrder;
        this.sort(column);
    }

    public async showPopup(popup: GenPopup, event: MouseEvent): Promise<void> {
        // Move the popup outside of the grid so it's not clipped by the grid's edges
        const element = event.target;
        if (element) {
            const htmlElement = element as HTMLElement;

            const popupElement = htmlElement.parentElement?.querySelector('gen-popup');

            if (popupElement) {
                this.elementRef.nativeElement.appendChild(popupElement);
            }
        }
        await popup.show();
    }

    //#endregion

    //#region Events

    public rowClicked(datum: RowResult): void {
        if (datum.datumKey) {
            this.selectionChanged.emit(datum.datumKey);
        }
    }

    public getReportData(reportResult: CorrelationQueryResult | null): RowResult[] {
        this.cachedIcons = [];
        const reportData: RowResult[] = [];
        if (reportResult?.items) {
            for (const item of reportResult.items) {
                if (item.sourceId) {
                    const row = {
                        datumKey: item.sourceId,
                        backgroundColor: item.backgroundColor ?? null,
                        gelatoIcon: this.extractResultGelatoIcon(item),
                        iconColor: item.iconColor ?? null,
                        reportColor: item.reportColor ?? null,
                        title: item.title,
                        description: item.description,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        hasThumbnail: item.hasThumbnailImage,
                        icon: this.extractResultImageIndex(item),
                        timestamp: item.timestamp ?? '',
                        mmtTime: item.timestamp && moment(item.timestamp),
                    } as RowResult;
                    if (item.fields) {
                        // Add property entries for each fields
                        for (const field of item.fields) {
                            if (field.key && field.value) {
                                row[field.key] = field.value;
                            }
                        }
                    }
                    if (row.title || row.image) {
                        reportData.push(row);
                    }
                }
            }
        }
        return reportData;
    }

    public async copyToClipboard(text: string, event: Event): Promise<void> {
        event.stopPropagation(); // Prevent click on row to open sidepane
        await navigator.clipboard.writeText(text);
        const toastMessage = this.translateService.instant('STE_MESSAGE_INFO_COPIED_CLIPBOARD') as string;
        this.toastService.show({ text: toastMessage, flavor: ToastFlavor.Success });
    }

    public onContextMenuRequested(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    public onMouseEnterRow(datumKey: string): void {
        if (!datumKey) return;

        this.isLoadRowActionsDebouncingSubject.next(true);
        this.currentHoveredRowDatumKey = datumKey;
        this.loadRowActionsDebouncer.resume();
        this.loadRowActionsDebouncer.trigger();
    }

    public onMouseLeaveRow(): void {
        this.loadRowActionsDebouncer.dispose();
    }

    private extractResultImageIndex(item: CorrelationQueryResultItem) {
        if (item.customIcon && !item.customIcon.includes('gelato:')) {
            let key = this.cachedIcons.indexOf(item.customIcon);
            if (key === -1) {
                this.cachedIcons.push(item.customIcon);
                key = this.cachedIcons.length - 1;
            }
            return key;
        }
        return null;
    }

    private extractResultGelatoIcon(item: CorrelationQueryResultItem) {
        if (item.customIcon?.includes('gelato:')) return item.customIcon.replace('gelato:', '');
        return item.gelatoIcon ?? Icon.None;
    }

    private async loadImagesAsync(rows: RowResult[]) {
        if (rows?.length > 0) {
            let keys = rows.filter((row) => row.hasThumbnail).map((row) => row.datumKey);

            // first try and process all the keys for (client-side) cached data
            const clientCachedData = keys.map((key) =>
                this.resourcesService.getCachedItem(`datumext:${key}`).then((item) => {
                    // cache hit
                    if (item) {
                        const row = rows.find((part) => part.datumKey === key);
                        if (row) {
                            row.image = item;
                            return undefined;
                        }
                    }
                    // cache miss, return the key for full later retrieval
                    return key;
                })
            );
            const cachedMisses = await Promise.all(clientCachedData);
            keys = cachedMisses.filter((key) => key !== undefined && key) as string[];

            const fulfilled = await this.correlationService.loadFirstImages(keys);
            if (fulfilled != null && fulfilled.length > 0) {
                for (const imageResult of fulfilled) {
                    const row = rows.find((result) => result.datumKey === imageResult.datumKey);
                    if (row) {
                        row.image = `data:image/png;base64,${imageResult.content}`;
                        // cache the result
                        this.resourcesService.updateCachedItem(`datumext:${imageResult.datumKey}`, row.image as string, true).fireAndForget();
                    }
                }
            }
            this.changeDetector.detectChanges();
        }
    }

    private tryInitializeMap() {
        if (!this.isMapInitialized) {
            // background the loading of the map so the div is there
            this.queryingChanged.emit(true);
            this.loadingMessageChanged.emit(this.translateService.instant('STE_LABEL_LOADINGMAP') as string);

            setTimeout(async () => {
                try {
                    await this.mapService.initializeMap({
                        mapId: null,
                        withEvents: false,
                    });

                    this.isMapInitialized = this.mapService?.mapAvailable ?? false;
                    if (this.isMapInitialized) {
                        this.mapService.onMapObjectSelected$.pipe(untilDestroyed(this)).subscribe((event) => {
                            // Retrieve the underlying datum key...
                            if (event.mapObjectView.data?.link) {
                                this.selectionChanged.emit(event.mapObjectView.data.link);
                            }
                        });
                        await this.loadMapObjectsAsync();
                    }
                } catch (ex) {
                    this.loggerService.traceError(toError(`Failed to initialize the map`, ex));
                    this.isMapInitialized = false;
                } finally {
                    this.queryingChanged.emit(false);
                }
            }, 100);
        }
    }

    private sort(column: ColumnDescriptor) {
        const SortingOrder = new Map<string, SortingType>([
            [TableColumnHeaderSort.Ascending, 'asc'],
            [TableColumnHeaderSort.Descending, 'desc'],
        ]);

        let columnSorting: ColumnSorting = this.defaultColumnSorting;
        const sortingMethod = SortingOrder.get(column.sortOrder ?? TableColumnHeaderSort.Ascending);
        if (sortingMethod) {
            columnSorting = { sortingType: sortingMethod, columnFieldKey: column.fieldKey };
        }

        this.allResults = this.sortResults(columnSorting);
        // Reset infinite scroll and load the first page
        this.visibleResults$.next([]);
        this.loadMore();
    }

    private async loadMapObjectsAsync() {
        this.queryingChanged.emit(true);
        this.loadingMessageChanged.emit(this.translateService.instant('STE_LABEL_LOADINGITEMS') as string);

        try {
            // wipe the previous map objects
            if (this.allMapObjects.length > 0) {
                // wipe the visible MOs
                this.mapService.map?.removeItems(this.currentMapObjects.map((item) => item.guid.toString()));
            }

            if (this.downloadMapObjects) {
                // delete the MO cache, since we're reloading from scratch
                this.allMapObjects = [];
                this.currentMapObjects = [];
            }

            // render the new MO's
            if (this.allResults && this.allResults.length > 0) {
                const ids = this.allResults.map((record) => record.datumKey);
                if (this.downloadMapObjects) {
                    const mapObjects = await this.mapService.getMapObjectsFromSourceIds(this.allResults?.map((record) => record.datumKey) ?? []);
                    if (mapObjects) {
                        // cache the result for rendering refreshes
                        this.allMapObjects = mapObjects;
                        // since the user may have filtered _prior_ to opening map mode, we need to download the world of MOs, then we can render the subset as needed
                        // and refreshes won't trigger re-queries
                        this.currentMapObjects = this.allMapObjects.filter((item) => ids.some((id) => id === item.source));
                    }
                } else {
                    // we're just filtering the MOs, compute the viewed MOs set
                    this.currentMapObjects = this.allMapObjects.filter((item) => ids.some((id) => id === item.source));
                }

                // render the MOs to be viewed
                if (this.currentMapObjects.length > 0) {
                    this.mapService.insertMapObjects(this.currentMapObjects, true);
                    this.fitMoViews(this.currentMapObjects.map((item) => item.guid));
                }
            }
        } catch (ex) {
            this.loggerService.traceError(toError(`Failed to query map objects`, ex));
            this.isMapInitialized = false;
        } finally {
            this.queryingChanged.emit(false);
            this.downloadMapObjects = false;
        }
    }

    private fitMoViews(ids: Iterable<IGuid> | undefined) {
        if (ids) {
            const latLngBounds: [number, number][] = [];

            const idArray = Array.from(ids);
            if (idArray.length > 0) {
                for (const mapObjectId of idArray) {
                    if (mapObjectId) {
                        const view = this.mapService.map?.getMapObjectView(mapObjectId.toTypescriptGuid());
                        if (view instanceof Marker) {
                            const offset = 0.002;
                            latLngBounds.push([view.getLatLng().lat + offset, view.getLatLng().lng - offset]);
                            latLngBounds.push([view.getLatLng().lat - offset, view.getLatLng().lng + offset]);
                        } else if (view instanceof Polyline) {
                            const bounds = view.getBounds().pad(0.25);
                            const nw = bounds.getNorthWest();
                            latLngBounds.push([nw.lat, nw.lng]);
                            const se = bounds.getSouthEast();
                            latLngBounds.push([se.lat, se.lng]);
                        }
                    }
                }
            }

            if (latLngBounds.length > 0) {
                if (this.mapService.map) {
                    this.mapService.map.fitBounds(latLngBounds);
                }
            }
        }
    }

    private sortResults(columnSorting: ColumnSorting) {
        // We will use the timestamp descending as default sorting.
        const sortingColumn = columnSorting.columnFieldKey ?? 'timestamp';

        // Consider any column as number to have a numerical first sorting
        const sortOptions: Intl.CollatorOptions = {
            numeric: true,
            ignorePunctuation: true,
            sensitivity: 'base',
        };

        const sortedResults = this.allResults?.sort((a, b) => {
            const col1 = a[sortingColumn];
            const col2 = b[sortingColumn];

            if (!col1 && col2) return 1;
            else if (col1 && !col2) return -1;
            else if (!col1 && !col2) return 0;

            const columnToSort = col1.toLowerCase() === col2.toLowerCase() ? 'timestamp' : sortingColumn;
            const result = a[columnToSort].localeCompare(b[columnToSort], undefined, sortOptions);
            return columnSorting.sortingType === 'asc' ? result : -result;
        });

        this.columnSorting = columnSorting;
        return sortedResults ?? [];
    }

    private loadRowActions(): void {
        this.isLoadRowActionsDebouncingSubject.next(false);
        this.rowActionsService.setCurrentRowDatumKey(this.currentHoveredRowDatumKey);
    }

    //#endregion
}
