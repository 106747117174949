// Autogenerated: v2/help/Entities/ApiArchiverAgent?codegen=ts&InterfaceOnly=True&workflow=False on 2021-04-15 12:15:50 PM
import { FieldObject } from '../../Helpers/FieldObject';
import { IRestResponse } from './IRestResponse';
import { IAgentEntity, AgentEntityFields } from './IAgentEntity';
import { ITransaction } from './ITransaction';
import { IFieldObject } from './IFieldObject';
import { IDatabaseCreateInfo, IDatabaseInfo } from './IRoleEntity';

// #region Fields

export class ArchiverAgentEntityFields extends AgentEntityFields {
    public static archiveBackupPathField = 'ArchiveBackupPath';
    public static archivingPriorityField = 'ArchivingPriority';
    public static automaticBackupFileCountField = 'AutomaticBackupFileCount';
    public static automaticBackupFrequencyField = 'AutomaticBackupFrequency';
    public static automaticBackupTimeField = 'AutomaticBackupTime';
    public static backupFolderField = 'BackupFolder';
    public static backupRetentionPeriodField = 'BackupRetentionPeriod';
    public static databaseFolderField = 'DatabaseFolder';
    public static databaseInstanceField = 'DatabaseInstance';
    public static databaseNameField = 'DatabaseName';
    public static databasePasswordField = 'DatabasePassword';
    public static databaseUserField = 'DatabaseUser';
    public static deleteOldBackupFilesOnDiskFullField = 'DeleteOldBackupFilesOnDiskFull';
    public static enableAutomaticBackupField = 'EnableAutomaticBackup';
    public static enableBackupCompressionField = 'EnableBackupCompression';
    public static notificationsDatabaseUsageField = 'NotificationsDatabaseUsage';
    public static notificationsDatabaseUsageAsPercentageField = 'NotificationsDatabaseUsageAsPercentage';
    public static notificationsDiskSpaceField = 'NotificationsDiskSpace';
    public static notificationsOnlyWhenLimitIsReachedField = 'NotificationsOnlyWhenLimitIsReached';
    public static notificationsRemainingGbField = 'NotificationsRemainingGb';
    public static rtspPortField = 'RtspPort';
    public static rtspPortUnitControlField = 'RtspPortUnitControl';
    public static telnetPortField = 'TelnetPort';
}

// #endregion Fields

export interface IArchiverAgentEntity extends IAgentEntity {
    // #region REST Properties

    archiveBackupPath: string;

    archivingPriority: number;

    automaticBackupFileCount: number;

    automaticBackupFrequency: string;

    automaticBackupTime: number;

    backupFolder: string;

    backupRetentionPeriod: number;

    databaseFolder: string;

    databaseInstance: string;

    databaseName: string;

    databasePassword: string;

    databaseUser: string;

    deleteOldBackupFilesOnDiskFull: boolean;

    enableAutomaticBackup: boolean;

    enableBackupCompression: boolean;

    notificationsDatabaseUsage: boolean;

    notificationsDatabaseUsageAsPercentage: number;

    notificationsDiskSpace: boolean;

    notificationsOnlyWhenLimitIsReached: boolean;

    notificationsRemainingGb: number;

    rtspPort: number;

    rtspPortUnitControl: number;

    telnetPort: number;

    // #endregion REST Properties

    // #region REST Methods

    getArchiverStatsAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;
    createDatabaseAsync(value: IDatabaseCreateInfo, transaction?: ITransaction): Promise<IRestResponse | null>;
    createDatabaseBackupAsync(folder: string, enableCompression: boolean, transaction?: ITransaction): Promise<IRestResponse | null>;
    deleteDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getDriveGroupsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null>;
    restoreDatabaseBackupAsync(file: string, transaction?: ITransaction): Promise<IRestResponse | null>;
    setDatabaseAsync(value: IDatabaseInfo, transaction?: ITransaction): Promise<IRestResponse | null>;
    upgradeDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

