import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Icon, ImageFlavor, ImageFit, TextFlavor } from '@genetec/gelato';
import { ReadClient, ReadImages, ReadInformation } from '@modules/lpr/api/api';
import { LprContentFields } from '@modules/lpr/enumerations/lpr-content-fields';
import { LprContentTypes } from '@modules/lpr/enumerations/lpr-content-types';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { SharedContentFields } from '@modules/shared/enumerations/shared-content-fields';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { Observable } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2022 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export class AdditionalInformationEntry {
    constructor(public name: string, public information: string) {}
}

const isContentSupported = (content: Content): boolean => {
    return content.type.equals(LprContentTypes.ReadEventContent);
};

@InternalContentPluginDescriptor({
    type: PlateReadWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: PlateReadWidgetComponent.pluginId, priority: 1 },
    isContentSupported,
})
@Component({
    selector: 'app-plate-read-widget',
    templateUrl: './plate-read-widget.component.html',
    styleUrls: ['./plate-read-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlateReadWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('2d646662-f55b-4a50-93e4-822b7871d666');
    public readonly TextFlavor = TextFlavor;
    public readonly Icon = Icon;
    public readonly ImageFlavor = ImageFlavor;
    public readonly ImageFit = ImageFit;

    public dataContext?: ContentService;
    public content?: Content;
    public readInformation?: ReadInformation;

    public readImages$?: Observable<ReadImages>;
    public plate?: string;
    public plateState?: string;
    public additionalInformation: AdditionalInformationEntry[] = [];

    constructor(trackingService: TrackingService, private readClient: ReadClient) {
        super(trackingService);
    }

    public setContent(content: Content): void {
        this.content = content;
    }
    public setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext as ContentService;
    }

    ngOnInit() {
        this.initializeFields();
    }

    private initializeFields(): void {
        const parameters = this.content?.parameters;
        if (parameters?.hasField(LprContentFields.ReadInformation)) {
            this.readInformation = ReadInformation.fromJS(parameters.getField(LprContentFields.ReadInformation));
            const plateRead = this.readInformation?.plateRead;
            if (plateRead) {
                this.plate = plateRead.plate ?? '';
                this.plateState = plateRead.plateState ?? '';

                if (plateRead.attributes) {
                    this.additionalInformation = Object.entries(plateRead.attributes).map(([key, value]) => new AdditionalInformationEntry(key, value));
                }
            }
        }

        if (parameters?.hasField(LprContentFields.ReadId) && parameters.hasField(SharedContentFields.Timestamp) && parameters.hasField(SharedContentFields.SourceEntityGuid)) {
            const readId = parameters.getField<IGuid>(LprContentFields.ReadId);
            const readTimestamp = parameters.getField<string>(SharedContentFields.Timestamp);
            const readSourceId = parameters.getField<IGuid>(SharedContentFields.SourceEntityGuid);
            if (readId && readTimestamp && readSourceId) {
                this.readImages$ = this.readClient.getReadImages(readId, readTimestamp, readSourceId);
            }
        }
    }
}
