import { Component, OnDestroy, OnInit } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginComponentExposure, PluginComponentRequirements } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { TimeService } from '@modules/shared/services/time/time.service';
import { IAlarmAcknowledgedEvent } from 'RestClient/Client/Interface/IAlarmAcknowledgedEvent';
import { AlarmInstanceWidgetBaseComponent } from '../../alarm-instance-widget-base.component';
import { AlarmsService } from '../../../services/alarms.service';
import { AlarmContentFields } from '../../../enumerations/alarm-content-fields';
import { AlarmContentTypes } from '../../../enumerations/alarm-content-types';

@Component({
    selector: 'app-alarm-actions-widget.component',
    templateUrl: './alarm-actions-widget.component.html',
    styleUrls: ['./alarm-actions-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: AlarmActionsWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: AlarmActionsWidgetComponent.pluginId, priority: 0 } as PluginComponentExposure,
    isContentSupported: (content: Content) => !!content?.type.equals(AlarmContentTypes.Alarm),
    requirements: { features: [KnownFeatures.alarmsId] },
})
export class AlarmActionsWidgetComponent extends AlarmInstanceWidgetBaseComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('B36DF810-3AE4-4AFE-853A-8780B9AAC97E');

    public alarmName!: string;
    public recipientInfo = '';
    public instanceCountString = '';
    public instanceCount!: number;
    public isRecipient = false;
    public isEventContent = false;

    constructor(trackingService: TrackingService, private translateService: TranslateService, alarmsService: AlarmsService, timeService: TimeService) {
        super(alarmsService, timeService, trackingService);
    }

    public ngOnInit() {
        super.ngOnInit();

        if (this.tryGetAlarmInstanceInfo(this.content?.contextContent)) {
            this.isRecipient = true;
            this.isEventContent = true;
        }

        const alarmContent = this.content;
        if (alarmContent) {
            this.alarmName = alarmContent.title;

            if (alarmContent.parameters) {
                if (!this.isEventContent) {
                    if (alarmContent.parameters.hasField(AlarmContentFields.IsUserRecipient)) {
                        this.isRecipient = alarmContent.parameters.getField(AlarmContentFields.IsUserRecipient);
                    }
                    if (alarmContent.parameters.hasField(AlarmContentFields.ActiveAlarmCount)) {
                        this.instanceCount = alarmContent.parameters.getField(AlarmContentFields.ActiveAlarmCount);
                    }
                }
            }
        }

        if (!this.isEventContent) {
            this.recipientInfo = (this.isRecipient ? this.translateService.instant('STE_LABEL_RECIPIENT') : this.translateService.instant('STE_LABEL_NOTARECIPIENT')) as string;

            if (this.instanceCount > 0) {
                this.instanceCountString = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_N_ACTIVE_INSTANCES') as string, this.instanceCount.toString());
            }
        }

        this.isLoading = false;
    }

    protected onAlarmAcknowledged(alarmEvent: IAlarmAcknowledgedEvent): void {
        super.onAlarmAcknowledged(alarmEvent);
        this.dataContext?.popContent();
    }
}
