<ng-template #datumCellTemplate let-params="value">
    <div [ngSwitch]="params.fieldType">
        <!-- Link -->
        <gen-link *ngSwitchCase="'Link'" [href]="params.value[0]" appTooltip="{{ 'STE_TOOLTIP_LINK_CLICKTOOPEN' | translate }}"></gen-link>

        <!-- Clipboard -->
        <div *ngSwitchCase="'Clipboard'" tabindex="0">
            <gen-text [flavor]="TextFlavor.Normal" [appTooltip]="params.allText">{{ params.value[0] }}</gen-text>
            <gen-button appTooltip="{{ 'STE_TOOLTIP_COPY_TO_CLIPBOARD' | translate }}" (click)="copyToClipboard(params.value[0])" [flavor]="ButtonFlavor.Flat">
                <gen-icon [icon]="Icon.Copy" size="small"></gen-icon>
            </gen-button>
        </div>

        <!-- Geocode -->
        <div *ngSwitchCase="'Geocode'" tabindex="0">
            <gen-text [flavor]="TextFlavor.Normal" [appTooltip]="params.allText">{{ params.value[0] }}</gen-text>
            <gen-button appTooltip="{{ 'STE_TOOLTIP_COPY_TO_CLIPBOARD' | translate }}" (click)="copyToClipboard(params.value[0])" [flavor]="ButtonFlavor.Flat">
                <gen-icon [icon]="Icon.Copy" size="small"></gen-icon>
            </gen-button>
        </div>

        <!-- Entity -->
        <gen-text *ngSwitchCase="'Entity'" class="ha-left">{{ params.value[0] }}</gen-text>
        <!-- EntityIcon -->
        <gen-icon *ngSwitchCase="'EntityIcon'" [icon]="params.value[0] === '' ? null : params.value[0]" [size]="IconSize.Small"></gen-icon>

        <!-- SoundPlayer -->
        <audio *ngSwitchCase="'SoundPlayer'" class="soundplayer-audio-control" controls autobuffer>
            <source [src]="params.value[0]" />
        </audio>

        <!-- Image -->
        <gen-image
            *ngSwitchCase="'Image'"
            class="double-click-image sm"
            [src]="params.value[0]"
            [fit]="ImageFit.Contain"
            [flavor]="ImageFlavor.Rectangle"
            appTooltip="{{ 'STE_ACTION_DOUBLECLICKTOOPENFULLSIZE' | translate }}"
            (dblclick)="onImageOpened($event)"
        >
        </gen-image>

        <!-- Color -->
        <gen-icon *ngSwitchCase="'Color'" [icon]="Icon.Record" [size]="IconSize.Medium" [ngStyle]="{ color: params.value[0] | argb }"></gen-icon>

        <!-- Unsupported presently: WebView, Barcode -->
        <!-- catch all -->
        <div *ngSwitchDefault [appTooltip]="params.allText">
            <gen-text class="ha-left" *ngFor="let block of params.value">{{ block }}</gen-text>
        </div>
    </div>
</ng-template>
<div class="p-0 container-max-height">
    <ng-container *ngIf="directive">
        <gen-text [flavor]="TextFlavor.Secondary" class="ha-left">{{ 'STE_LABEL_RECORD_DETAILS' | translate }}</gen-text>
        <gen-flex style="max-width: 100%">
            <gen-flex-item *ngFor="let row of datumTableRows" [genColumns]="12" class="remove-bottom-padding">
                <gen-text [flavor]="TextFlavor.Label">
                    {{ row.name }}
                </gen-text>
                <ng-container *ngTemplateOutlet="datumCellTemplate; context: row"></ng-container>
            </gen-flex-item>
        </gen-flex>
    </ng-container>
</div>
