<ng-container *ngIf="events$ | async as groupedEvents">
    <ng-container *ngIf="(isLoading$ | async) === false; else spinner">
        <ng-container *ngIf="hasEvents$ | async; else noRecentEvents">
            <ul class="h-100">
                <cdk-virtual-scroll-viewport itemSize="75" minBufferPx="200" maxBufferPx="800" (scrolledIndexChange)="onScroll($event)" class="scroll-container">
                    <ng-container *cdkVirtualFor="let item of groupedEvents; let index = index; let first = first; trackBy: trackByItem">
                        <li *ngIf="(hasMultiplePriorities$ | async) && (first || didPriorityChange(item, groupedEvents[index - 1]))" class="group-header">
                            <gen-text [flavor]="TextFlavor.Header3">{{ translatePriority(item.priority) }}</gen-text>
                            <gen-button [flavor]="ButtonFlavor.Flat" class="group-header__button" (click)="onDismissPriority(item.priority)">
                                {{ 'STE_BUTTON_DISMISS' | translate }}
                            </gen-button>
                        </li>
                        <li [attr.data-index]="index" class="an-event">
                            <app-event-item
                                class="an-event__item"
                                [event]="item.eventEntry"
                                [monitoredEntity]="item.entity"
                                [formattedTime]="item.formattedTime"
                                [formattedDate]="item.formattedDate"
                                [showDismissOnHover]="true"
                                [isNew]="item.isNew"
                                (seen)="onItemSeen(item)"
                                (dismissed)="onDismissEvent(item)"
                                (click)="openEventDetailsAsync(item.eventEntry)"
                            ></app-event-item>
                        </li>
                    </ng-container>
                </cdk-virtual-scroll-viewport>
            </ul>
            <ng-container *ngIf="isScrollToTopVisible">
                <gen-button [flavor]="ButtonFlavor.Solid" class="scroll-to-top-button" (click)="scrollBackToTop()"
                    ><gen-icon icon="angle-up"></gen-icon>{{ 'STE_BUTTON_NEW_EVENTS' | translate }}</gen-button
                >
            </ng-container>
        </ng-container>
    </ng-container>

    <!-- No recent events -->
    <ng-template #noRecentEvents>
        <div class="no-recent-events ha-center va-center">
            <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_MESSAGE_INFO_MONITORED_EVENTS_DISPLAYED_HERE' | translate }}</gen-text>
        </div>
    </ng-template>
</ng-container>

<!-- Spinner -->
<ng-template #spinner>
    <div class="ha-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
