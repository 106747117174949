// Autogenerated: v2/help/Entities/ApiMapManagerRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { IGuid } from 'safeguid';

// #region Fields

export class MapManagerRoleEntityFields extends RoleEntityFields {
    public static cacheLocationField = 'CacheLocation';
    public static connectionPortField = 'ConnectionPort';
    public static defaultMapIdField = 'DefaultMapId';
}

// #endregion Fields

export interface IMapManagerRoleEntity extends IRoleEntity {
    // #region REST Properties

    cacheLocation: string;

    connectionPort: number;

    defaultMapId: IGuid;

    // #endregion REST Properties

}

