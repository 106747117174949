// Autogenerated: v2/help/Entities/ApiAuxiliaryArchiverRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:03 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class AuxiliaryArchiverRoleEntityFields extends RoleEntityFields {
    public static archiveBackupMaxBandwidthField = 'ArchiveBackupMaxBandwidth';
    public static archiveFileSizeEnabledField = 'ArchiveFileSizeEnabled';
    public static backupRetentionPeriodField = 'BackupRetentionPeriod';
    public static defaultManualRecordingTimeField = 'DefaultManualRecordingTime';
    public static deleteOldFilesOnDiskFullField = 'DeleteOldFilesOnDiskFull';
    public static diskLoadWarningLimitField = 'DiskLoadWarningLimit';
    public static enableAudioRecordingField = 'EnableAudioRecording';
    public static enableAutomaticCleanupField = 'EnableAutomaticCleanup';
    public static enableMetadataRecordingField = 'EnableMetadataRecording';
    public static enableThumbnailsRequestsField = 'EnableThumbnailsRequests';
    public static isVideoWatermarkingField = 'IsVideoWatermarking';
    public static maximumVideoLengthField = 'MaximumVideoLength';
    public static maximumVideoSizeField = 'MaximumVideoSize';
    public static postEventRecordingTimeField = 'PostEventRecordingTime';
    public static preEventRecordingTimeField = 'PreEventRecordingTime';
    public static protectedVideoThresholdField = 'ProtectedVideoThreshold';
}

// #endregion Fields

// #region Inner classes

export interface IAuxRecordingMode extends IFieldObject {
    id: number;
    mode: string | null;
    scheduleId: IGuid | null;
}

// #endregion Inner classes

export interface IAuxiliaryArchiverRoleEntity extends IRoleEntity {
    // #region REST Properties

    archiveBackupMaxBandwidth: number;

    archiveFileSizeEnabled: boolean;

    backupRetentionPeriod: number;

    defaultManualRecordingTime: number;

    deleteOldFilesOnDiskFull: boolean;

    diskLoadWarningLimit: number;

    enableAudioRecording: boolean;

    enableAutomaticCleanup: boolean;

    enableMetadataRecording: boolean;

    enableThumbnailsRequests: boolean;

    isVideoWatermarking: boolean;

    maximumVideoLength: number;

    maximumVideoSize: number;

    postEventRecordingTime: number;

    preEventRecordingTime: number;

    protectedVideoThreshold: number;

    // #endregion REST Properties

    // #region REST Methods

    getArchiverStatsAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;
    getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getRecordingModesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAuxRecordingMode> | null>;

    // #endregion REST Methods

}

