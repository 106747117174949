import { InternalDewarperSourceImageParameters } from './DewarperSourceImageParameters';
import { FloatPrecision, FragmentShader } from './WebGL2/FragmentShader';

export class DewarperFragmentShader extends FragmentShader {
  public constructor(glContext: WebGL2RenderingContext, dewarperParameters: InternalDewarperSourceImageParameters, floatPrecision: FloatPrecision) {
    super(glContext, new DewarperFragmentShaderCodeBuilder(dewarperParameters).build(), floatPrecision);
  }
}

export class DewarperFragmentShaderCodeBuilder {
  private readonly m_dewarperParameters: InternalDewarperSourceImageParameters;

  constructor(dewarperParameters: InternalDewarperSourceImageParameters) {
    this.m_dewarperParameters = dewarperParameters;
  }

  public build(): string {
    return `${DewarperFragmentShaderCodeBuilder.Variables}

${this.getToPolar()}

${this.main()}
`;
  }

  private static readonly Variables: string =
    `

uniform vec4 u_outsideColor;  // When accessing outside of the warped image area, what color shall the pixel be

uniform vec2 u_warpedImageResolution;
uniform vec2 u_canvasResolutionAdjustedToSrcAspectRatio;
uniform vec2 u_canvasResolutionAspectRatioOverflow;

uniform vec2 u_cropping;

// warped image
uniform sampler2D u_warpedImage;

// The output color of the fragment shader execution
out vec4 outputColor;

uniform vec3 u_pPrime;  //Vector/Normal to origin of the dewarping plane
uniform vec3 u_uHat;    //Horizontal normalized vector of the dewarping plane (equivalent of x-Axis)
uniform vec3 u_vHat;    //Vertical normalized vector of the dewarping plane (equivalent of y-Axis)

`;

  private getToPolar(): string {
    return `

// Inputs : (cartesian) : Cartesian coordinates
// Output : Polar coordinates (x: radius, y: angle)
vec2 toPolar(vec2 cartesian) {
    return vec2(
        sqrt(cartesian.x * cartesian.x + cartesian.y * cartesian.y),
        atan(cartesian.y, cartesian.x));
}

float polarBeta(vec3 cartesian) {
    return atan(sqrt(cartesian.x * cartesian.x + cartesian.y * cartesian.y), cartesian.z);
}

float polarDelta(vec3 cartesian) {
    return atan(cartesian.y, cartesian.x);
}

`;
  }

  private main(): string {
    return `

void main()
{
  // Will be called from (0, 0) to viewPort (which should be the canvas resolution)
  // gl_FragCoord (0, 0) is lower left and is in pixel.
  vec2 v = gl_FragCoord.xy;

  v = v - u_canvasResolutionAspectRatioOverflow; //rest of the canvas resolution adjustment to src aspect ratio

  //To ClipSpace
  v = v / u_canvasResolutionAdjustedToSrcAspectRatio * vec2(2.0, 2.0) - vec2(1.0, 1.0);

  //To 3D
  vec3 xyz = u_pPrime + (u_uHat * v.x) + (u_vHat * v.y);
  float beta = polarBeta(xyz);
  float delta = polarDelta(xyz);

  float r_p = ${this.m_dewarperParameters.Projection.applyCode()}; //* m_R which is always 1 up to now

  v = r_p * vec2(cos(delta), sin(delta));

  //answer 'v' is in cliped circle coordinates. Fetch it from the warped image
  //normalize
  v = (v + vec2(1.0, -1.0)) / vec2(2.0, -2.0);

  //to circle resolution
  v = v * ${toFloat(this.m_dewarperParameters.RadiusRatio * 2.0)} * u_warpedImageResolution.y;

  //translate for the cropping
  v = v + u_cropping;

  if (v.x >= 0.0 && v.x < u_warpedImageResolution.x &&
      v.y >= 0.0 && v.y < u_warpedImageResolution.y) 
  {
    //scale to 0 to 1 for fetching pixel on texture
    v = v / u_warpedImageResolution; 
    outputColor = texture(u_warpedImage, v);
  }
  else
  {
    outputColor = u_outsideColor;
  }
}

`;
  }
}

function toFloat(x: number): string {
  return Number.isInteger(x) ? x + '.0' : x.toString();
}
