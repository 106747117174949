<ng-container *ngIf="ptzCapabilities$ | async as ptzCapabilities; else loading">
    <ng-container *ngIf="allSupportedCommands$ | async as supportedCommands; else loading">
        <ng-container *ngIf="ptzCapabilities.ptzControlEnabled">
            <ng-container *ngIf="playerMode$ | async as playerMode">
                <ng-container *ngIf="playerMode === 'live'">
                    <ng-container *ngIf="ptzPrivileges$ | async as ptzPriv">
                        <!-- Basics PTZ controls panel -->
                        <div class="ptz__basics-controls-container" appClickStopPropagation>
                            <!-- Header section -->
                            <div class="ptz__header">
                                <app-lcd-panel [text]="getPtzLockingStateInformation() | async"></app-lcd-panel>
                                <span class="tooltip" *ngIf="showContextMenu">
                                    <gen-button
                                        id="switch-menu-button-{{ componentGuid }}"
                                        [flavor]="ButtonFlavor.Flat"
                                        (click)="toggleContextMenu()"
                                        class="ptz__context-menu-button"
                                    >
                                        <gen-icon [icon]="MeltedIcon.AngleDown"></gen-icon>
                                    </gen-button>
                                    <div class="gen-tooltip left">
                                        <span>{{ 'STE_MENU_PTZ_CONTEXT_MENU' | translate }}</span>
                                    </div>
                                </span>
                                <gen-menu #contextMenu targetSelector="#switch-menu-button-{{ componentGuid }}" [appMenuDataSource]="viewOptionsList"></gen-menu>
                            </div>

                            <!-- Controls section, only displays if Default Mode or Advanced mode  -->
                            <div class="ptz__default-section-container">
                                <!-- Arrow controls -->
                                <div class="ptz__arrows-controller va-center">
                                    <gen-grid class="ptz__arrows-grid" [genColumns]="3" [genRows]="3">
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onDiagUpLeftArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowUpLeft"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onUpArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowUp"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onDiagUpRightArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowUpRight"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onLeftArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowLeft"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <div></div>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onRightArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowRight"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onDiagDownLeftArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowDownLeft"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onDownArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowDown"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                        <gen-grid-item>
                                            <gen-button
                                                class="ptz__arrows"
                                                [flavor]="ButtonFlavor.Flat"
                                                (mousedown)="onDiagDownRightArrowMouseDown()"
                                                (mouseup)="stopPtz()"
                                                [disabled]="!supportedCommands.supportsStartPanTilt() || !ptzPriv.allowBasicPtz"
                                            >
                                                <gen-icon [icon]="MeltedIcon.ArrowDownRight"></gen-icon>
                                            </gen-button>
                                        </gen-grid-item>
                                    </gen-grid>
                                </div>
                                <!-- Zoom in / Zoom out section-->
                                <div class="ptz__zoom-controller va-center" *ngIf="supportedCommands.supportsStartZoom()">
                                    <gen-separator [orientation]="Orientation.Vertical" class="ptz__zoom-controller--left-separator"></gen-separator>
                                    <div class="ptz__zoom-controller--controls-grid">
                                        <gen-button
                                            class="ptz__zoom-button"
                                            [flavor]="ButtonFlavor.Flat"
                                            (mousedown)="onZoomInMouseDown()"
                                            (mouseup)="onAnyZoomMouseUp()"
                                            [appTooltip]="'STE_ACTION_PTZ_CONTROLS_ZOOM_IN' | translate"
                                            [disabled]="!ptzPriv.allowBasicPtz"
                                        >
                                            <gen-icon [icon]="MeltedIcon.Plus"></gen-icon>
                                        </gen-button>
                                        <gen-button
                                            class="ptz__zoom-button"
                                            [flavor]="ButtonFlavor.Flat"
                                            (mousedown)="onZoomOutMouseDown()"
                                            (mouseup)="onAnyZoomMouseUp()"
                                            [appTooltip]="'STE_ACTION_PTZ_CONTROLS_ZOOM_OUT' | translate"
                                            [disabled]="!ptzPriv.allowBasicPtz"
                                        >
                                            <gen-icon [icon]="MeltedIcon.Minus"></gen-icon>
                                        </gen-button>
                                    </div>
                                    <gen-separator [orientation]="Orientation.Vertical" class="ptz__zoom-controller--right-separator"></gen-separator>
                                </div>

                                <!-- Presets section -->
                                <div class="ptz__presets-pad va-center" *ngIf="presetsNumpadItemList$ | async as presetsList">
                                    <ng-container *ngFor="let preset of presetsList; let presetIndex = index">
                                        <gen-button
                                            *ngIf="presetIndex < 8"
                                            [flavor]="ButtonFlavor.Flat"
                                            class="ptz__presets-pad-item"
                                            (click)="goToPreset(presetIndex)"
                                            [appTooltip]="preset.text"
                                            [disabled]="!ptzPriv.allowPresetsUse || !supportedCommands.supportsGoToPreset() || presetsNumpadDisabledState[presetIndex]"
                                            >{{ presetIndex }}</gen-button
                                        >
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- Advanced PTZ controls panel -->
                        <gen-separator class="ptz__advanced-controls-separator" *ngIf="currentSelection.id === 'ptz-mode-advanced'"></gen-separator>
                        <div class="ptz__advanced-controls-container" *ngIf="currentSelection.id === 'ptz-mode-advanced'" appClickStopPropagation>
                            <div class="ptz__advanced-icon-tray">
                                <!-- Hamburger menu button -->
                                <gen-button
                                    class="ptz__hamburger-menu-button"
                                    [flavor]="ButtonFlavor.Flat"
                                    (click)="toggleHamburgerMenu()"
                                    [appTooltip]="'STE_MENU_PTZ_HAMBURGER' | translate"
                                >
                                    <gen-icon [icon]="MeltedIcon.Burger"></gen-icon
                                ></gen-button>

                                <!-- PTZ speed button -->
                                <gen-button
                                    id="speed-menu-button-{{ componentGuid }}"
                                    class="ptz__speed-menu-button"
                                    [flavor]="ButtonFlavor.Flat"
                                    (click)="toggleSpeedMenu()"
                                    [appTooltip]="'STE_MENU_PTZ_SPEED' | translate"
                                    [disabled]="!ptzPriv.allowBasicPtz"
                                >
                                    <gen-icon [icon]="speedIconToUse"></gen-icon>
                                </gen-button>

                                <!-- Focus buttons -->
                                <gen-button
                                    class="ptz__focus-in-button"
                                    [flavor]="ButtonFlavor.Flat"
                                    (mousedown)="focusIn()"
                                    (mouseup)="stopFocus()"
                                    [appTooltip]="'STE_MENU_PTZ_FOCUS_IN' | translate"
                                    [disabled]="!ptzPriv.allowChangeFocusAndIris"
                                    *ngIf="supportedCommands.supportsStartFocus()"
                                >
                                    <gen-icon [icon]="MeltedIcon.FocusNear"></gen-icon>
                                </gen-button>
                                <gen-button
                                    class="ptz__focus-out-button"
                                    [flavor]="ButtonFlavor.Flat"
                                    (mousedown)="focusOut()"
                                    (mouseup)="stopFocus()"
                                    [appTooltip]="'STE_MENU_PTZ_FOCUS_OUT' | translate"
                                    [disabled]="!ptzPriv.allowChangeFocusAndIris"
                                    *ngIf="supportedCommands.supportsStartFocus()"
                                >
                                    <gen-icon [icon]="MeltedIcon.FocusFar"></gen-icon>
                                </gen-button>
                                <!-- Iris buttons -->
                                <gen-button
                                    *ngIf="supportedCommands.supportsStartIris()"
                                    [disabled]="!ptzPriv.allowChangeFocusAndIris"
                                    class="ptz__iris-close-button"
                                    [flavor]="ButtonFlavor.Flat"
                                    (mousedown)="irisClose()"
                                    (mouseup)="stopIris()"
                                    [appTooltip]="'STE_MENU_PTZ_IRIS_CLOSE' | translate"
                                >
                                    <gen-icon [icon]="MeltedIcon.IrisClose"></gen-icon>
                                </gen-button>
                                <gen-button
                                    *ngIf="supportedCommands.supportsStartIris()"
                                    [disabled]="!ptzPriv.allowChangeFocusAndIris"
                                    class="ptz__iris-open-button"
                                    [flavor]="ButtonFlavor.Flat"
                                    (mousedown)="irisOpen()"
                                    (mouseup)="stopIris()"
                                    [appTooltip]="'STE_MENU_PTZ_IRIS_OPEN' | translate"
                                >
                                    <gen-icon [icon]="MeltedIcon.IrisOpen"></gen-icon>
                                </gen-button>
                            </div>
                            <!-- Preset dropdown -->
                            <div class="ptz__advanced-combobox-row" *ngIf="supportedCommands.supportsGoToPreset() && (presetsComboBoxItemList$ | async) as presetsComboBoxItems">
                                <gen-combobox
                                    [ngModel]="presetsComboBoxSelection"
                                    (ngModelChange)="onPresetSelectionChange($event)"
                                    [genItemsSource]="presetsComboBoxItems"
                                    [genFlavor]="Flavor.Combobox.Solid"
                                    [genPosition]="Position.Combobox.BottomLeft"
                                    [disabled]="!ptzPriv.allowPresetsUse"
                                >
                                </gen-combobox>

                                <!-- EYE ICON -->
                                <gen-button
                                    class="ptz__advanced-combobox-button"
                                    (click)="goToPreset(presetsComboBoxSelection?.id)"
                                    [flavor]="ButtonFlavor.Flat"
                                    [appTooltip]="'STE_ACTION_PTZ_RUN_PRESET' | translate"
                                    [disabled]="!ptzPriv.allowPresetsUse"
                                >
                                    <gen-icon [icon]="MeltedIcon.Show"></gen-icon>
                                </gen-button>
                            </div>
                            <!-- Pattern dropdown -->
                            <div class="ptz__advanced-combobox-row" *ngIf="supportedCommands.supportsRunPattern() && (patternComboBoxItemList$ | async) as patternComboBoxItems">
                                <gen-combobox
                                    [ngModel]="patternComboBoxSelection"
                                    (ngModelChange)="onPatternSelectionChange($event)"
                                    [genItemsSource]="patternComboBoxItems"
                                    [genFlavor]="Flavor.Combobox.Solid"
                                    [genPosition]="Position.Combobox.BottomLeft"
                                    [disabled]="!ptzPriv.allowPatternsUse"
                                >
                                </gen-combobox>
                                <!-- PLAY ICON -->
                                <gen-button
                                    class="ptz__advanced-combobox-button"
                                    (click)="runPattern(patternComboBoxSelection?.id)"
                                    [flavor]="ButtonFlavor.Flat"
                                    [appTooltip]="'STE_ACTION_PTZ_RUN_PATTERN' | translate"
                                    [disabled]="!ptzPriv.allowPatternsUse"
                                    ><gen-icon [icon]="MeltedIcon.Play"></gen-icon
                                ></gen-button>
                            </div>

                            <!-- Hamburger menu context -->
                            <gen-menu #hamburgerMenu targetSelector=".ptz__hamburger-menu-button" [appMenuDataSource]="hamburgerOptionsList"></gen-menu>

                            <!-- Speed menu context -->
                            <gen-popup
                                #speedMenuPopup
                                class="ptz__speed-menu-popup"
                                [position]="Position.PopUp.Bottom"
                                [tailVisible]="true"
                                targetSelector="#speed-menu-button-{{ componentGuid }}"
                            >
                                <div class="ptz__speed-popup-body-grid-container p-1">
                                    <gen-icon class="ptz__speed-menu-popup-slow-icon" [icon]="MeltedIcon.SpeedLow" [size]="IconSize.Medium"></gen-icon>
                                    <gen-slider
                                        class="ptz__advanced-speed-slider"
                                        [ngModel]="ptzSpeed"
                                        (ngModelChange)="onSpeedSliderChange($event)"
                                        [max]="100"
                                        [step]="1"
                                    ></gen-slider>
                                    <gen-icon class="ptz__speed-menu-popup-fast-icon" [icon]="MeltedIcon.SpeedHigh" [size]="IconSize.Medium"></gen-icon>
                                </div>
                            </gen-popup>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loading>
    <gen-spinner>{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
</ng-template>
