<div
    class="lcdPanel"
    [ngStyle]="getStyles()"
    [ngClass]="{
        'lcdPanel__multi-line': shouldTextWrap(),
        'lcdPanel__single-line': !shouldTextWrap()
    }"
>
    {{ text }}
</div>
