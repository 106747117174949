<!-- The datum-info sidepane -->
<div class="gen-sidepane right overlay sm show" id="investigateRightSidepane">
    <div class="gen-sidepane-body">
        <app-investigate-side-pane></app-investigate-side-pane>
    </div>
</div>

<!-- The main view -->
<gen-app class="w-100 h-100">
    <!-- Toolbar -->
    <gen-header [hidden]="isSplashSreenOpen">
        <div class="investigate-toolbar">
            <div class="investigate-toolbar-left">
                <div [hidden]="isSplashSreenOpen" class="filters-container">
                    <!-- What -->
                    <div class="filter-container">
                        <gen-item
                            id="what-filter"
                            class="what-filter h-100"
                            [ngClass]="{ 'filter-open': whatPopup?.open }"
                            appClickActionOnSpaceEnter
                            tabindex="0"
                            (click)="toggleWhatFilter()"
                        >
                            <gen-text> {{ 'STE_LABEL_WHAT' | translate }} </gen-text>
                            <gen-text class="filter--secondary-text" [flavor]="!whatFilter.isValid ? TextFlavor.Error : TextFlavor.Secondary" [slot]="ItemSlot.TextSecondary">
                                {{ whatFilterDescription$ | async }}
                            </gen-text>
                        </gen-item>
                        <gen-popup #whatPopup [position]="PopupPosition.BottomLeft" [targetSelector]="'#what-filter'" appFocusPopupWhenOpen (keydown.esc)="toggleWhatFilter(false)">
                            <app-investigate-what-filter #whatFilter (filterToggle)="toggleWhatFilter()"></app-investigate-what-filter>
                        </gen-popup>
                    </div>

                    <!-- When -->
                    <div class="filter-container">
                        <gen-item
                            id="when-filter"
                            class="h-100"
                            [ngClass]="{ 'filter-open': whenPopup?.open }"
                            appClickActionOnSpaceEnter
                            tabindex="0"
                            (click)="toggleWhenFilter()"
                        >
                            <gen-text> {{ 'STE_LABEL_WHEN' | translate }} </gen-text>
                            <gen-text
                                [slot]="ItemSlot.TextSecondary"
                                class="filter--secondary-text"
                                [flavor]="(isWhenFilterValid$ | async) ? TextFlavor.Secondary : TextFlavor.Error"
                            >
                                {{ (isWhenFilterValid$ | async) ? (whenFilterDescription$ | async) : ('STE_LABEL_INVALIDFILTER' | translate) }}
                            </gen-text>
                        </gen-item>
                        <gen-popup #whenPopup [position]="PopupPosition.BottomLeft" [targetSelector]="'#when-filter'" appFocusPopupWhenOpen (keydown.esc)="toggleWhenFilter(false)">
                            <app-investigate-when-filter (filterToggle)="toggleWhenFilter()"></app-investigate-when-filter>
                        </gen-popup>
                    </div>
                    <!-- Where -->
                    <div class="filter-container">
                        <gen-item
                            id="where-filter"
                            class="h-100"
                            [ngClass]="{ 'filter-open': wherePopup?.open }"
                            appClickActionOnSpaceEnter
                            tabindex="0"
                            (click)="toggleWhereFilter()"
                        >
                            <gen-text> {{ 'STE_LABEL_WHERE' | translate }} </gen-text>
                            <gen-text [slot]="ItemSlot.TextSecondary"> {{ whereFilterDescription$ | async }} </gen-text>
                        </gen-item>
                        <gen-popup
                            #wherePopup
                            [position]="PopupPosition.BottomLeft"
                            [targetSelector]="'#where-filter'"
                            appFocusPopupWhenOpen
                            (keydown.esc)="toggleWhereFilter(false)"
                        >
                            <app-investigate-where-filter (filterToggle)="toggleWhereFilter()"></app-investigate-where-filter>
                        </gen-popup>
                    </div>

                    <div class="filter-container">
                        <!-- Generate button -->
                        <gen-button
                            [flavor]="ButtonFlavor.Solid"
                            class="center-button h-50 ml-2"
                            (click)="investigate()"
                            [disabled]="querying || (isWhenFilterValid$ | async) === false"
                        >
                            <gen-icon icon="charts"></gen-icon>
                            {{ 'STE_BUTTON_GENERATE' | translate }}
                        </gen-button>
                    </div>
                </div>
            </div>
            <div class="investigate-toolbar-right">
                <div class="other-buttons-container">
                    <!-- Report more button -->
                    <gen-button
                        id="btn-toggle-report-more"
                        (click)="toggleMoreMenu()"
                        [flavor]="ButtonFlavor.Flat"
                        [genAutomaticNavigation]="true"
                        genFindElementQuery="div.gen-list-item[tabindex='0']"
                        genFindDocumentElementQuery=".gen-contextmenu"
                        class="ml-1 va-center ha-center"
                    >
                        <gen-icon [icon]="Icon.EllipsisVertical"></gen-icon
                    ></gen-button>
                    <gen-menu id="menu" target-selector="#btn-toggle-report-more" [open]="isMoreMenuOpen" (didClose)="toggleMoreMenu(false)">
                        <gen-menu-item (click)="showColumnSelector()">
                            <gen-icon icon="show"></gen-icon>
                            <gen-text>{{ 'STE_ACTION_EDITCOLUMNS' | translate }}</gen-text>
                        </gen-menu-item>
                    </gen-menu>
                </div>
            </div>
        </div>
    </gen-header>
    <app-portal-outlet class="report-portal-outlet" *ngIf="isPortalEnabled$ | async"></app-portal-outlet>
    <gen-main [style]="querying ? 'pointer-events: none' : 'pointer-events: auto'">
        <div *ngIf="!isSplashSreenOpen; else splashscreen" class="h-100">
            <div [hidden]="!querying" class="spinner-overlay">
                <!-- Querying spinner overlay on results container -->
                <gen-spinner [size]="SpinnerSize.Large"></gen-spinner>
            </div>
            <div class="h-100">
                <!-- Now if there are results, load the results tile(s) view -->
                <app-investigate-results-container
                    [viewMode]="viewMode"
                    class="h-100 results-container"
                    (selectionChanged)="onSelectionChanged($event)"
                    (queryingChanged)="onQueryingChanged($event)"
                >
                </app-investigate-results-container>
            </div>

            <!-- Select columns modal component -->
            <app-select-investigate-columns #selectColumns></app-select-investigate-columns>
        </div>
        <ng-template #splashscreen>
            <div class="what-container-splash-screen">
                <!-- What -->
                <gen-text [flavor]="TextFlavor.Header2">{{ 'STE_TASK_REPORTS' | translate }}</gen-text>
                <gen-text [flavor]="TextFlavor.Header3">{{ 'STE_MESSAGE_INFO_WHATAREYOULOOKINGFOR' | translate }}</gen-text>
                <app-investigate-what-filter (itemSelected)="onItemSelected()" class="h-100 w-100" [tilesView]="true"></app-investigate-what-filter></div
        ></ng-template>
    </gen-main>
</gen-app>
