// Autogenerated: v2/help/Entities/ApiAccessPoint?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:25 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IAccessPointEntity, AccessPointEntityFields } from '../../Interface/IAccessPointEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class AccessPointEntity extends Entity implements IAccessPointEntity {
    // #region REST Properties

    // AccessPointSide
    public get accessPointSide(): string {
        return this.getField<string>(AccessPointEntityFields.accessPointSideField, ValidFlags.Specific);
    }

    // AccessPointType
    public get accessPointType(): string {
        return this.getField<string>(AccessPointEntityFields.accessPointTypeField);
    }

    // DeviceState
    public get deviceState(): string {
        return this.getField<string>(AccessPointEntityFields.deviceStateField, ValidFlags.Specific);
    }
    public set deviceState(value: string) {
        this.setField<string>(AccessPointEntityFields.deviceStateField, value);
    }

    // Parent
    public get parent(): IGuid {
        return this.getFieldGuid(AccessPointEntityFields.parentField, ValidFlags.MemberOf);
    }

    // TwoManRuleEnabled
    public get twoManRuleEnabled(): boolean {
        return this.getField<boolean>(AccessPointEntityFields.twoManRuleEnabledField, ValidFlags.Specific);
    }
    public set twoManRuleEnabled(value: boolean) {
        this.setField<boolean>(AccessPointEntityFields.twoManRuleEnabledField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.AccessPoints);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cameras', 'Id', false, query, ValidFlags.Specific, transaction);
    }

    // #endregion REST Methods

}

