import { MapObjectEventBase } from './MapObjectEventBase';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class MapObjectAddOrUpdateEvent extends MapObjectEventBase {
    constructor() {
        super();
    }

    public get event(): string {
        return this.getField('EventContent');
    }

    public get updateOnlyIfVisible(): boolean {
        return this.getField('UpdateOnlyIfVisible');
    }

    public get eventDisplayTime(): number | undefined {
        const eventDisplayTime = this.getNullableField<number>('EventContentDisplayTime');
        return eventDisplayTime ?? undefined;
    }
}
