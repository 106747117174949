import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { MapObjectView, Popup } from '@genetec/web-maps';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { ContentGroup } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { ShowPopupOptions } from '../components/map-popup/interfaces';
import { MapPopupComponent } from '../components/map-popup/map-popup.component';

const componentRefSymbol = Symbol('ComponentRef');

interface PopupWithComponentRef extends Popup {
    [componentRefSymbol]: ComponentRef<MapPopupComponent>;
}

// ==========================================================================x
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class MapPopupService {
    constructor(private pluginsService: PluginService, private loggerService: LoggerService) {}

    /**
     * Show a popup containing the passed content on the provided map object view.
     *
     * TODO: Remove viewContainerRef parameter when we upgrade Angular and have access to createComponent without viewContainerRef.
     *
     * @param mapObjectView - The map object view to open the popup of
     * @param contentGroup - The content to show in the popup
     * @param viewContainerRef - View container ref needed to dynamically create the component
     * @param showPopupOptions - Options related to the popup to be opened
     * @returns The create {@link MapPopupComponent} if successful, otherwise return **null**.
     */
    public async showPopupAsync(
        mapObjectView: MapObjectView,
        contentGroup: ContentGroup,
        viewContainerRef: ViewContainerRef,
        showPopupOptions?: ShowPopupOptions
    ): Promise<MapPopupComponent | null> {
        const compactPlugins = await this.pluginsService.getPluginsFromContentGroup(PluginTypes.MapPopupCompact, contentGroup);
        if (compactPlugins.length === 0) {
            this.loggerService.traceInformation('No MapPopupCompact plugins found.', `Title: ${contentGroup.mainContent.title.trim() || 'unknown'}.`);
            return null;
        }

        const popup = this.getOrCreatePopup(mapObjectView, viewContainerRef);
        if (!popup) {
            this.loggerService.traceError("Failed to create component's for popup.", `Title: ${contentGroup.mainContent.title.trim() || 'unknown'}.`);
            return null;
        }

        let expandedPlugins: PluginItem[] = [];
        if (showPopupOptions?.expandOnHover) {
            expandedPlugins = await this.pluginsService.getPluginsFromContentGroup(PluginTypes.MapPopupExpand, contentGroup);
        }

        this.openPopupWithNewContent(contentGroup, popup, compactPlugins, expandedPlugins, showPopupOptions);

        return popup[componentRefSymbol].instance;
    }

    /**
     * Returns the instance of {@link MapPopupComponent} linked to the provided map object's popup if there is one, otherwise returns **null**.
     *
     * @param mapObjectView - The map object view to extract the link **MapPopupComponent** from.
     * @returns The instance of **MapPopupComponent** if found, otherwise **null**.
     */
    public getMapPopupComponent(mapObjectView: MapObjectView): MapPopupComponent | null {
        const popup = mapObjectView.getPopup();
        if (popup && this.isPopupWithComponentRef(popup)) {
            return popup[componentRefSymbol].instance;
        }
        return null;
    }

    /**
     * Closes the popup of the map object view if it's opened, clearing the plugins in it (if there's any).
     *
     * @param mapObjectView - The Map object view to close the popup of.
     */
    public closePopup(mapObjectView: MapObjectView): void {
        const popup = mapObjectView.getPopup();
        if (popup) {
            const componentRef = this.getComponentRefFromPopup(popup);
            componentRef?.instance.clearAndClosePopup();
            // If the popup wasn't linked to a component ref for some reason, it might still be opened, force close.
            if (popup.isOpen()) {
                popup.close();
            }
        }
    }

    private openPopupWithNewContent(
        contentGroup: ContentGroup,
        popup: PopupWithComponentRef,
        plugins: PluginItem[],
        expandedPlugins: PluginItem[],
        showPopupOptions?: ShowPopupOptions
    ): void {
        const mapPopupComponent = popup[componentRefSymbol].instance;

        mapPopupComponent.updateContentAndOpenPopup({
            plugins,
            expandedPlugins,
            delayToCloseMs: showPopupOptions?.delayToCloseMs,
            webMapsPopup: popup,
            closeOnMouseLeave: showPopupOptions?.closeOnMouseLeave,
        });

        // If color is an empty string, we revert to default (undefined)
        const backgroundColor = contentGroup.mainContent.color.trim().slice(3);
        popup.updateColor(backgroundColor ? `#${backgroundColor}` : undefined);
    }

    private getOrCreatePopup(mapObjectView: MapObjectView, viewContainerRef: ViewContainerRef): PopupWithComponentRef | null {
        const webMapPopup = mapObjectView.getPopup();

        // Return popup if it already exists and has the MapPopupComponent instance already linked.
        if (webMapPopup && this.isPopupWithComponentRef(webMapPopup)) {
            return webMapPopup;
        } else {
            // Old popup was not created by this service, close it as it will be replaced.
            mapObjectView.closePopup();
        }

        const componentRef = viewContainerRef.createComponent(MapPopupComponent);
        if (componentRef?.location.nativeElement) {
            const popup = this.createWebMapPopup(componentRef);
            mapObjectView.bindPopup(popup);
            mapObjectView.once('remove', () => popup[componentRefSymbol].destroy());
            return popup;
        }

        return null;
    }

    private isPopupWithComponentRef(popup: Popup): popup is PopupWithComponentRef {
        return componentRefSymbol in popup;
    }

    private createWebMapPopup(contentComponentRef: ComponentRef<MapPopupComponent>): PopupWithComponentRef {
        const popup = new Popup({
            content: contentComponentRef.location.nativeElement as HTMLElement,
            offset: [0, 5],
            autoClose: false,
            closeOnClick: false,
            togglePopupOnClick: false,
            autoPan: false,
            reopenWhenSourceUnclusters: true,
        });
        this.setComponentReferenceToPopup(popup, contentComponentRef);
        return popup as PopupWithComponentRef;
    }

    private setComponentReferenceToPopup(popup: Popup, componentRef: ComponentRef<MapPopupComponent>) {
        (popup as PopupWithComponentRef)[componentRefSymbol] = componentRef;
    }

    private getComponentRefFromPopup(popup: Popup) {
        return (popup as PopupWithComponentRef)[componentRefSymbol];
    }
}
