<ng-container
    *ngIf="{
        isPinned: navBarService.isPinned$ | async,
        isOpened: navBarService.isOpened$ | async,
        isFocused: navBarService.isFocused$ | async,
        skipHoveringCheck: navBarService.skipHoveringCheck$ | async
    } as navState"
>
    <gen-sidepane
        id="floating-nav-bar"
        [hidden]="navState.isPinned === false && navState.isOpened === false && navState.skipHoveringCheck === false"
        [ngClass]="{ pinned: navState.isPinned, opened: navState.isOpened, focused: navState.isFocused, stayOpenedWithoutHovering: navState.skipHoveringCheck }"
        [genSize]="Size.SidePane.Small"
        [genIsOpen]="true"
        [genPosition]="Position.SidePane.Left"
        appHoverClass="hovered-with-delay"
        [appHoverClassDelay]="1000"
        [appHoverClassResetDelay]="resetNavbarAnimation"
        (genIsOpenChange)="onSidePaneOpenChange($event)"
    >
        <gen-sidepane-header>
            <div class="nav-bar-header">
                <gen-button [hidden]="navState.isPinned" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_BUTTON_CLOSE' | translate }}" (click)="onCancelMenu()">
                    <gen-icon class="floating-nav-bar-header-close" [icon]="Icon.Close"></gen-icon>
                </gen-button>
                <img class="floating-nav-bar-header-logo ml-1" src="assets/images/Genetec Web App White.svg" alt="SecurityCenter white logo" />
                <gen-button
                    class="floating-nav-bar-header-pin"
                    [flavor]="ButtonFlavor.Flat"
                    [appTooltip]="(navState.isPinned ? 'STE_ACTION_UNPINTASKBAR' : 'STE_ACTION_PINTASKBAR') | translate"
                    (click)="onTogglePin()"
                >
                    <gen-icon class="floating-nav-bar-header-close" [icon]="navState.isPinned ? Icon.PushpinOn : Icon.PushpinOff"></gen-icon>
                </gen-button>
            </div>
        </gen-sidepane-header>
        <gen-sidepane-body>
            <ul class="gen-nav-tabs vertical">
                <li class="hovered-body-header">
                    <app-main-search
                        #mainSearch
                        id="nav-bar-search"
                        [clearText]="clearMainSearchText"
                        (searchResultsChange)="onSearchResultsChange($event)"
                        (isSearchingChange)="onIsSearchingChange($event)"
                        (focusEvent)="onMainSearchFocus()"
                        genKeyboardNavigation
                        (genArrowDown)="focusResultSection()"
                        class="floating-nav-bar-search w-100"
                        tabindex="0"
                    ></app-main-search>
                    <gen-text *ngIf="!groupedSearchResults && !isSearching" [flavor]="TextFlavor.Label" class="tasks-label">{{ 'STE_LABEL_TASKS' | translate }}</gen-text>
                </li>

                <li class="unhovered-body-header">
                    <gen-button class="pinned-home-search" [flavor]="ButtonFlavor.Flat" (click)="onSearchClick()">
                        <gen-icon [icon]="Icon.Search"></gen-icon>
                    </gen-button>
                </li>

                <li #resultSection class="nav-bar-items" keyboardNavigation [genAutomaticNavigation]="true">
                    <ng-container>
                        <ng-container>
                            <li *ngFor="let taskPlugin of taskPlugins" class="task" [ngClass]="{ 'with-search-results': groupedSearchResults, searching: isSearching }">
                                <a
                                    appClickActionOnSpaceEnter
                                    tabindex="0"
                                    [attr.id]="'task_' + taskPlugin.exposure.id.toString()"
                                    genFindElementQuery="a[tabindex='0']"
                                    (click)="onTaskSelected(taskPlugin)"
                                    [attr.data-cy]="'task_' + taskPlugin.exposure.id.toString()"
                                >
                                    <gen-item [ngClass]="{ active: taskPlugin.exposure.id.equals(activatedTask) }">
                                        <gen-icon [icon]="taskPlugin.exposure.icon"></gen-icon>
                                        <gen-text *ngIf="taskPlugin.exposure.title as title" class="gen-item-description">{{ title | translate }}</gen-text>
                                        <!-- Display an arrow icon if Task has multiple subTaks available to display in a popup -->
                                        <gen-icon
                                            class="gen-item-subtask-icon"
                                            slot="gen-text-right-top"
                                            *ngIf="hasAccessControlGeneralFeatureFlag && taskPlugin.subTaskPlugins.length > 1"
                                            [icon]="Icon.AngleRight"
                                        ></gen-icon>
                                    </gen-item>
                                </a>
                            </li>
                            <!-- subtask popup (when task has multiple subTasks) -->
                            <gen-popup
                                *ngIf="hasAccessControlGeneralFeatureFlag"
                                [position]="PopupPosition.Right"
                                [staysOpen]="false"
                                [targetSelector]="'#task_' + selectedTask?.exposure?.id?.toString()"
                                appFocusPopupWhenOpen
                            >
                                <div class="popup-body">
                                    <div *ngFor="let item of selectedTask?.subTaskPlugins" style="display: inline-block">
                                        <gen-menu-item (click)="onSubTaskClick(item)">
                                            <gen-icon [icon]="item.exposure.icon"></gen-icon>
                                            <gen-text *ngIf="item.exposure.title as title">{{ title | translate }}</gen-text>
                                        </gen-menu-item>
                                    </div>
                                </div>
                            </gen-popup>
                        </ng-container>
                        <ng-container *ngIf="groupedSearchResults?.length && !isSearching">
                            <ng-container *ngFor="let group of groupedSearchResults">
                                <li class="search-result-group-label pl-2">
                                    <gen-text [flavor]="TextFlavor.Label">{{ group.header }}</gen-text>
                                </li>
                                <li *ngFor="let item of group.results" class="search-result-item pl-1">
                                    <a
                                        [attr.id]="'searchResult_' + item.id.toString()"
                                        (click)="onItemSelected(item.navigation)"
                                        appClickActionOnSpaceEnter
                                        (contextmenu)="onItemContextMenu($event, item.id)"
                                        tabindex="0"
                                    >
                                        <gen-item [appTooltip]="item.title">
                                            <gen-icon [icon]="item.icon ? item.icon : Icon.CorrelationService"></gen-icon>
                                            <gen-text>{{ item.title }}</gen-text>
                                        </gen-item>
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                        <div class="pl-2 no-search-results" *ngIf="groupedSearchResults && !groupedSearchResults?.length && !isSearching">
                            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_NORESULTS' | translate }}</gen-text>
                        </div>
                    </ng-container>

                    <div class="searching-spinner" *ngIf="isSearching">
                        <gen-spinner></gen-spinner>
                    </div>
                </li>
            </ul>
        </gen-sidepane-body>
    </gen-sidepane>

    <gen-aside [genPosition]="Position.Aside.Left" *ngIf="navState.isPinned">
        <section class="pinned-nav-bar"></section>
    </gen-aside>
</ng-container>
