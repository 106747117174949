import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { TextFlavor } from '@genetec/gelato';
import { CalendarFlavor, GenComboboxComponent, GenMeltedItem, Severity } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { getAccessStatusResourceString } from '@modules/shared/utilities/accessStatusTranslate';
import { ActivationModeLabel, ActiveExpirationModeLabel, InactiveExpirationModeLabel } from '@modules/access-control/enumerations';
import moment from 'moment';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CardholderEditContextState } from '@modules/access-control/cardholder-edit-context.state';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { isValidDate } from '@src/app/utilities/date-validator';
import { StatusFormGroup } from '../cardholder-edit-form-group';

@UntilDestroy()
@Component({
    selector: 'app-cardholder-status-form',
    templateUrl: './cardholder-status-form.component.html',
    styleUrls: ['./cardholder-status-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardholderStatusFormComponent implements OnDestroy, OnChanges {
    @ViewChild('activationMode') activationModeCombobox?: GenComboboxComponent;

    @Input() formGroup?: StatusFormGroup;
    @Input() readonly = false;

    @ViewSelectSnapshot(CardholderEditContextState.isPrivilegeGranted('changeCardholderStatusPrivilege'))
    public hasChangeCardholderStatusPrivilege!: boolean;

    @ViewSelectSnapshot(CardholderEditContextState.isAddMode)
    public isAddMode!: boolean;

    public selectedStatusItem: GenMeltedItem | null = null;
    public statuses!: GenMeltedItem[];
    public activationModes!: GenMeltedItem[];
    public activeExpirationModes!: GenMeltedItem[];
    public inactiveExpirationModes!: GenMeltedItem[];

    public readonly AccessStatus = AccessStatus;
    public readonly ActivationMode = ActivationModeLabel;
    public readonly ActiveExpirationMode = ActiveExpirationModeLabel;
    public readonly InactiveExpirationMode = InactiveExpirationModeLabel;
    public readonly errorSeverity = Severity.Error;
    public readonly dateTimeFlavor = CalendarFlavor.DateTime;
    public readonly header3Flavor = TextFlavor.Header3;

    public controls?: StatusFormGroup['controls'];
    public activationDate = '';
    private subscriptionCollection = new SubscriptionCollection();

    constructor(private translateService: TranslateService) {
        this.createAndTranslateItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formGroup) {
            if (changes.formGroup.previousValue) {
                this.subscriptionCollection.unsubscribeAll();
            }
            if (changes.formGroup.currentValue) {
                this.initializeSubscriptions();
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribeAll();
    }

    public isExpirationDateOtherThanDontExpire(): boolean {
        if (this.formGroup?.value.expirationMode === ActiveExpirationModeLabel.DontExpire) {
            return false;
        }
        // If ExpirationMode === ActivateOn, we need to verify if the ExpirationDate is valid or not.
        if (this.formGroup?.value.expirationMode === InactiveExpirationModeLabel.ActivateOn) {
            const isValid = isValidDate(moment(this.formGroup?.value.expirationDate).toDate());
            if (!isValid) {
                return false;
            }
        }

        return true;
    }

    private initializeSubscriptions() {
        if (!this.formGroup) {
            return;
        }

        this.subscriptionCollection.unsubscribeAll(); // Unsubscribe of previous form if needed.

        this.refreshStatusLayout(this.formGroup.getRawValue().accessStatus);

        this.subscriptionCollection.add(
            this.formGroup.controls.accessStatus.valueChanges.pipe(untilDestroyed(this)).subscribe((newStatus) => this.refreshStatusLayout(newStatus)),
            this.formGroup.controls.activationMode.valueChanges.pipe(untilDestroyed(this)).subscribe((newActivationMode) => this.updateActivationMode(newActivationMode)),
            this.formGroup.controls.expirationMode.valueChanges.pipe(untilDestroyed(this)).subscribe((newExpirationMode) => this.updateExpirationMode(newExpirationMode))
        );
    }

    private refreshStatusLayout(accessStatus: AccessStatus) {
        if (!this.formGroup) {
            return;
        }

        const isDefaultValue = this.formGroup.controls.accessStatus.defaultValue === accessStatus;

        this.assignNonSelectableComboboxItemIfApplicable(accessStatus);

        switch (accessStatus) {
            case AccessStatus.Active: {
                let activationDate = '';
                const defaultActivationDateMoment = isDefaultValue ? moment(this.formGroup.controls.activationDate.defaultValue ?? 0) : moment();
                // If date is start of time, set today as activationDate, otherwise use previously defined activation date.
                if (defaultActivationDateMoment.isValid() && moment(defaultActivationDateMoment).isAfter(moment(0))) {
                    activationDate = defaultActivationDateMoment.toISOString();
                    this.formGroup.controls.activationMode.reset();
                    this.formGroup.controls.expirationMode.reset();
                } else {
                    activationDate = moment().toISOString();
                    this.formGroup.controls.activationMode.setValue(ActivationModeLabel.ActivateOn);
                    this.formGroup.controls.expirationMode.reset(ActiveExpirationModeLabel.DontExpire);
                }

                this.formGroup.controls.activationDate.setValue(activationDate);
                this.activationDate = moment(activationDate).format('L LT'); // 09/04/1986 8:30 PM
                break;
            }
            default:
                if (this.isAddMode) {
                    this.formGroup.controls.activationMode.reset(ActivationModeLabel.Never);
                    this.formGroup.controls.expirationMode.reset(InactiveExpirationModeLabel.DontExpire);
                } else {
                    this.formGroup.controls.activationMode.reset();
                    this.formGroup.controls.expirationMode.reset();
                }

                this.activationDate = '';
                break;
        }
    }

    private updateActivationMode(activationMode: ActivationModeLabel) {
        if (!this.formGroup) {
            return;
        }

        switch (activationMode) {
            case ActivationModeLabel.Never:
                this.formGroup.controls.activationDate.setValue(null);
                this.formGroup.controls.expirationMode.setValue(ActiveExpirationModeLabel.DontExpire);
                break;
            case ActivationModeLabel.ActivateOn: {
                const today = moment();
                this.formGroup.controls.activationDate.setValue(today.toISOString() || this.formGroup.controls.activationDate.defaultValue);
                this.formGroup.controls.expirationMode.setValue(InactiveExpirationModeLabel.ActivateOn);
                break;
            }
        }
    }
    private updateExpirationMode(expirationMode: ActiveExpirationModeLabel | InactiveExpirationModeLabel) {
        if (!this.formGroup) {
            return;
        }
        const activationDateValue = this.formGroup.controls.activationDate.getRawValue();
        const accessStatusValue = this.formGroup.controls.accessStatus.getRawValue();

        switch (expirationMode) {
            case InactiveExpirationModeLabel.ActivateOn:
            case ActiveExpirationModeLabel.Expiring:
                if (accessStatusValue === AccessStatus.Inactive && isValidDate(moment(activationDateValue).toDate())) {
                    this.formGroup.controls.expirationDate.setValue(moment(activationDateValue).add(1, 'days').toISOString());
                } else {
                    this.formGroup.controls.expirationDate.setValue(moment().add(1, 'days').toISOString());
                }
                break;
            default:
                // DontExpire / ExpiredAfterActivation / WhenNotUsed
                this.formGroup.controls.expirationDate.setValue(null);
                this.resetExpirationDuration();
                break;
        }
    }

    /**
     * Initializes item in the various comboboxes.
     */
    private createAndTranslateItems() {
        this.statuses = [
            {
                id: AccessStatus.Active,
                text: this.translateService.instant(getAccessStatusResourceString(AccessStatus.Active)) as string,
            },
            {
                id: AccessStatus.Inactive,
                text: this.translateService.instant(getAccessStatusResourceString(AccessStatus.Inactive)) as string,
            },
        ];

        this.activationModes = Object.values(ActivationModeLabel).map(
            (activationMode) =>
                ({
                    id: activationMode,
                    text: this.translateService.instant(activationMode) as string,
                } as GenMeltedItem)
        );

        this.updateExpirationModesItems();
    }

    private updateExpirationModesItems(): void {
        this.activeExpirationModes = Object.values(ActiveExpirationModeLabel).map(
            (expirationMode) =>
                ({
                    id: expirationMode,
                    text: this.translateService.instant(expirationMode) as string,
                } as GenMeltedItem)
        );
        this.inactiveExpirationModes = Object.values(InactiveExpirationModeLabel).map(
            (expirationMode) =>
                ({
                    id: expirationMode,
                    text: this.translateService.instant(expirationMode) as string,
                } as GenMeltedItem)
        );
    }

    private assignNonSelectableComboboxItemIfApplicable(accessStatus: AccessStatus) {
        // Some possible access status are not selectable in the dropdown menu (Expired, Stolen, etc.), so assign item manually.
        const selectedStatus = this.statuses.find((item) => item.id === accessStatus);
        if (!selectedStatus) {
            this.selectedStatusItem = {
                id: accessStatus,
                text: this.translateService.instant(getAccessStatusResourceString(accessStatus)) as string,
            } as GenMeltedItem;
        }
    }

    private resetExpirationDuration() {
        if (!this.formGroup) {
            return;
        }

        if (this.formGroup.controls.expirationDuration.defaultValue <= 0) {
            this.formGroup.controls.expirationDuration.setValue(1);
        } else {
            this.formGroup.controls.expirationDuration.reset();
        }
    }
}
