/**
 * Freezes an object so that it's properties cannot be modified. Useful for creating
 * constant objects.
 *
 * @param obj Object that you want converted into a readonly object.
 * @returns The same object but with its properties readonly.
 */
export const Constants = <T extends Record<string, any>>(obj: T): Readonly<T> => {
    return Object.freeze(obj);
};
