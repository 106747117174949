import { Injectable } from '@angular/core';
import { WebAppClient } from 'WebClient/WebAppClient';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';

@Injectable({
    providedIn: 'root',
})
export class AdvancedSettingsService {
    private scClient: WebAppClient;

    constructor(securityCenterClientService: SecurityCenterClientService) {
        this.scClient = securityCenterClientService?.scClient;
    }

    /**
     * Get advanced setting based on the provided key
     *
     * @param fallbackValue Value that will be use if no setting was found or if it has no value
     * @returns The advanced setting in the provided type (string | number | boolean)
     */
    get<TType extends string | number | boolean>(key: string, fallbackValue: TType): TType {
        return this.scClient.getAdvancedSetting(key, fallbackValue);
    }
}
