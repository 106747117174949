import { FitResolution } from '../utils/FitResolution';
import { ILogger } from '../utils/logger';
import { Resolution } from '../utils/Resolution';
import { Utils } from '../utils/Utils';
import { Vec2 } from '../utils/Vec2';
import { ICameraPosition } from './CameraPosition';
import { InternalDewarperSourceImageParameters } from './DewarperSourceImageParameters';
import { IProjection } from './Projection';

export class DewarpingParameters {
  private readonly m_dewarperSourceImageParameters: InternalDewarperSourceImageParameters;
  private readonly m_streamResolution: Resolution;
  private readonly m_dewarpedResolution: Resolution;
  private readonly m_fitResolution: FitResolution;

  public get CircleCenterInPixels(): Vec2 {
    return this.m_dewarperSourceImageParameters.CircleCenter.toPixel(this.m_streamResolution);
  }

  public get RadiusInPixels(): number {
    return this.m_dewarperSourceImageParameters.RadiusRatio * this.m_streamResolution.Height;
  }

  public get FovInRadians(): number {
    return this.m_dewarperSourceImageParameters.FovInRadians;
  }

  public get Projection(): IProjection {
    return this.m_dewarperSourceImageParameters.Projection;
  }

  public get CameraPosition(): ICameraPosition {
    return this.m_dewarperSourceImageParameters.CameraPosition;
  }

  public get StreamResolution(): Resolution {
    return this.m_streamResolution;
  }

  public get DewarpedResolution(): Resolution {
    return this.m_dewarpedResolution;
  }

  public get FitResolution(): FitResolution {
    return this.m_fitResolution;
  }

  public get CircleResolution(): Resolution {
    const diameter = this.RadiusInPixels * 2.0;
    return Resolution.build(diameter, diameter);
  }

  //In pixels
  public get Cropping(): Vec2 {
    return this.CircleCenterInPixels.minusC(this.RadiusInPixels);
  }

  constructor(dewarperSourceImageParameters: InternalDewarperSourceImageParameters, streamResolution: Resolution, dewarpedResolution: Resolution) {
    if (streamResolution === Resolution.None) {
      throw new Error('DewarpingParameters requires a valid stream resolution');
    }

    if (dewarpedResolution === Resolution.None) {
      throw new Error('DewarpingParameters requires a valid dewarped resolution');
    }

    this.m_dewarperSourceImageParameters = dewarperSourceImageParameters;
    this.m_streamResolution = streamResolution;
    this.m_dewarpedResolution = dewarpedResolution;

    this.m_fitResolution = new FitResolution(this.CircleResolution, this.m_dewarpedResolution);
  }

  public debugStatus(indent: number): string {
    return 'DewarpingParameters: ' + Utils.indentNewLine(indent) +
      this.m_dewarperSourceImageParameters.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      `Stream resolution: ${this.m_streamResolution?.toString()}` + Utils.indentNewLine(indent) +
      `Dewarped resolution: ${this.m_dewarpedResolution.toString()}` + Utils.indentNewLine(indent) +
      `Fit resolution: ${this.m_fitResolution.toString()}`;
  }
}

export class DewarpingParametersBuilder {
  private readonly m_logger: ILogger;
  private readonly m_dewarperSourceImageParameters: InternalDewarperSourceImageParameters;
  private m_streamResolution: Resolution = Resolution.None;
  private m_dewarpedResolution: Resolution;

  private m_dewarpingParameters: DewarpingParameters | null = null;

  public get StreamResolution(): Resolution {
    return this.m_streamResolution;
  }

  public get DewarpingParameters(): DewarpingParameters | null {
    return this.m_dewarpingParameters;
  }

  constructor(logger: ILogger, dewarperSourceImageParameters: InternalDewarperSourceImageParameters, dewarpedResolution: Resolution) {
    this.m_logger = logger.subLogger('DewarpingParametersBuilder');
    this.m_dewarperSourceImageParameters = dewarperSourceImageParameters;
    this.m_dewarpedResolution = dewarpedResolution;
    this.m_logger.debug?.trace(`Created: ${this.debugStatus(0)}`);
  }

  public withDewarpedResolution(dewarpedResolution: Resolution): DewarpingParameters | null {
    if (!dewarpedResolution.IsNone && !dewarpedResolution.equals(this.m_dewarpedResolution)) {
      this.m_logger.debug?.trace(`Dewarped resolution changed from ${this.m_dewarpedResolution.toString()} to ${dewarpedResolution.toString()}`);
      this.m_dewarpedResolution = dewarpedResolution;
      this.m_dewarpingParameters = this.build();
    }

    return this.m_dewarpingParameters;
  }

  public withStreamResolution(streamResolution: Resolution): DewarpingParameters | null {
    if (!streamResolution.IsNone && !streamResolution.equals(this.m_streamResolution)) {
      this.m_logger.debug?.trace(`Stream resolution changed from ${this.m_streamResolution.toString()} to ${streamResolution.toString()}`);
      this.m_streamResolution = streamResolution;
      this.m_dewarpingParameters = this.build();
    }

    return this.m_dewarpingParameters;
  }

  public build(): DewarpingParameters | null {
    if (this.m_streamResolution.IsNone) {
      this.m_logger.debug?.trace('build did not work. Still missing stream resolution');
      return null;
    }

    return new DewarpingParameters(this.m_dewarperSourceImageParameters, this.m_streamResolution, this.m_dewarpedResolution);
  }

  public debugStatus(indent: number): string {
    return 'DewarpingParametersBuilder: ' + Utils.indentNewLine(indent) +
      this.m_dewarperSourceImageParameters.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      `Stream resolution: ${this.m_streamResolution?.toString()}` + Utils.indentNewLine(indent) +
      `Dewarped resolution: ${this.m_dewarpedResolution.toString()}` + Utils.indentNewLine(indent) +
      (this.m_dewarpingParameters === null ? (' '.repeat(Utils.Indentation) + 'Dewarping Parameters: null') : this.m_dewarpingParameters.debugStatus(indent + Utils.Indentation));
  }
}

