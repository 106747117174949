import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { IconSize, Icon, ImageFit, ImageFlavor, TextFlavor } from '@genetec/gelato';
import { ButtonFlavor, GenMeltedTableRow, GenModalService } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { MODAL_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { Entity } from 'RestClient/Client/Model/Entity';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { IconsService } from '@modules/shared/services/icons.service';
import { ImageViewerModalComponent } from '@modules/shared/components/image-viewer-modal/image-viewer-modal.component';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { DatumVisualDirective } from '@modules/correlation/interfaces/correlation-datum';
import { CorrelationContentTypes } from '../../../enumerations/correlation-content-types';
import { CorrelationFeatures } from '../../../enumerations/correlation.features';
import { RecordFusionFeatureFlags } from '@modules/correlation/feature-flags';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-correlation-datum-sidepane-widget',
    templateUrl: './correlation-datum-sidepane-widget.component.html',
    styleUrls: ['./correlation-datum-sidepane-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: CorrelationDatumSidepaneWidgetComponent,
    pluginTypes: [PluginTypes.Tile, PluginTypes.Widget], //, PluginTypes.MapPopupExpand, PluginTypes.MapPopupCompact
    exposure: {
        id: CorrelationDatumSidepaneWidgetComponent.pluginId,
        priority: 4,
    },
    isContentSupported: (content: Content) => {
        if (content?.type && content.parameters) {
            if (
                CorrelationContentTypes.datum.equals(content.type) &&
                content.parameters?.hasField(CorrelationContentTypes.visualModel) &&
                content.parameters?.hasField(CorrelationContentTypes.dataType)
            ) {
                const dataTypeString = content.parameters.getField<string>(CorrelationContentTypes.dataType);
                if (dataTypeString) {
                    for (const special of CorrelationContentTypes.specialCaseDatums) {
                        if (special.equals(dataTypeString)) return false;
                    }
                    return true;
                }
            }
        }
        return false;
    },
    requirements: {
        licenses: [KnownLicenses.correlation],
        features: [CorrelationFeatures.datumSidepaneFeatureId],
        enabledFeatureFlags: [RecordFusionFeatureFlags.General],
    },
})
export class CorrelationDatumSidepaneWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent, AfterViewInit {
    //#region Fields

    public static pluginId = SafeGuid.parse('39ECB286-35CB-4777-BAF6-8B384AA31D70');

    @Input()
    public dataContext: unknown;

    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly TextFlavor = TextFlavor;
    public readonly ButtonFlavor = ButtonFlavor;

    public content?: Content;

    public directive!: DatumVisualDirective;
    public datumTableRows: Array<GenMeltedTableRow> = [];

    private scClient: ISecurityCenterClient | null = null;

    //#endregion

    //#region Constructor

    constructor(
        trackingService: TrackingService,
        private securityCenterClientService: SecurityCenterClientService,
        @Inject(MODAL_SERVICE) private modalService: GenModalService,
        private iconsService: IconsService
    ) {
        super(trackingService);
    }

    //#endregion

    //#region Methods

    public static copyMessage(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    ngAfterViewInit() {
        this.scClient = this.securityCenterClientService.scClient;
        setTimeout(() => {
            const parameters = this.content?.parameters;
            if (parameters) {
                if (parameters.hasField(CorrelationContentTypes.visualModel)) {
                    const json = parameters.getField<string>(CorrelationContentTypes.visualModel);
                    if (json) {
                        const data = JSON.parse(json) as DatumVisualDirective;
                        if (data) {
                            this.directive = data;
                            void this.onDirectiveChanged();
                        }
                    }
                }
            }
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    //#endregion

    //#region Events

    public copyToClipboard(text: string): void {
        if (text) {
            CorrelationDatumSidepaneWidgetComponent.copyMessage(text);
        }
    }

    public onImageOpened(event: MouseEvent): void {
        const target = (event?.target || event?.currentTarget) as HTMLImageElement;
        const field = target?.src;
        if (target && field) {
            const modal = this.modalService.open(ImageViewerModalComponent, undefined);
            modal.image = field;
        }
    }

    private async onDirectiveChanged() {
        const results = [];

        if (this.directive) {
            if (this.directive.Fields) {
                for (const field of this.directive.Fields) {
                    // value must be defined in order to render the field control
                    if (field.Value?.length > 0) {
                        // Transformations on the value as needed
                        if (field.FieldType === 'Image') {
                            field.Value = ['data:image/png;base64,' + field.Value[0]];
                        } else if (field.FieldType === 'SoundPlayer') {
                            field.Value = ['data:audio/mp3;base64,' + field.Value[0]];
                        } else if (field.FieldType === 'Entity') {
                            const entityId = SafeGuid.parse(field.Value[0]);
                            if (entityId) {
                                if (this.scClient) {
                                    const entity = await this.scClient.getEntityAsync(Entity, entityId, null, null, EntityFields.nameField, EntityFields.idField);
                                    if (entity?.name) {
                                        field.Value[0] = entity.name;
                                    } else {
                                        field.Value[0] = '';
                                    }
                                }
                            }
                        } else if (field.FieldType === 'EntityIcon') {
                            const entityId = SafeGuid.parse(field.Value[0]);
                            if (entityId) {
                                if (this.scClient) {
                                    const entity = await this.scClient.getEntityAsync(Entity, entityId, null, null, EntityFields.entityTypeField, EntityFields.idField);
                                    if (entity) {
                                        const icon = this.iconsService.getEntityIcon(entity);
                                        if (icon) {
                                            field.Value[0] = icon;
                                        } else {
                                            field.Value[0] = '';
                                        }
                                    }
                                }
                            }
                        }
                        results.push({ name: field.DisplayText, value: { fieldType: field.FieldType, value: field.Value, allText: field.Value.join('\n') } });
                    }
                }
            }

            this.datumTableRows = results;
        }
    }

    //#endregion
}
