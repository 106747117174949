// Autogenerated: CodeGen/ContentController?language=ts&InterfaceOnly=False on 2021-06-21 12:13:15 PM
import { IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { RestObject } from 'RestClient/Client/Model/RestObject';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { ContentGroup, IContentGroup } from './content.controller.data';
import { IContentController, IContentFieldSource, IContentMultiSource, IContentSource } from './content.controller.interfaces';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FieldObject } from 'RestClient/Helpers/FieldObject';

export class ContentSource extends FieldObject implements IContentSource {
    // SourceId
    public get sourceId(): string {
        return this.getField<string>('SourceId');
    }
    public set sourceId(value: string) {
        this.setField<string>('SourceId', value);
    }
    // ContextId
    public get contextId(): IGuid {
        return this.getFieldGuid('ContextId');
    }
    public set contextId(value: IGuid) {
        this.setFieldGuid('ContextId', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('SourceId', '');
        this.setFieldGuid('ContextId', SafeGuid.EMPTY);
    }
}

export class ContentFieldSource extends FieldObject implements IContentFieldSource {
    // Fields
    public get fields(): IFieldObject {
        return this.getFieldObject<FieldObject, IFieldObject>(FieldObject, 'Fields');
    }
    public set fields(value: IFieldObject) {
        this.setFieldObject<IFieldObject>('Fields', value);
    }
    // ContextId
    public get contextId(): IGuid {
        return this.getFieldGuid('ContextId');
    }
    public set contextId(value: IGuid) {
        this.setFieldGuid('ContextId', value);
    }

    public initializeAllFields(): void {
        const objFields = new FieldObject();
        objFields.initializeAllFields();
        this.setFieldObject('Fields', objFields);
        this.setFieldGuid('ContextId', SafeGuid.EMPTY);
    }
}

export class ContentMultiSource extends FieldObject implements IContentMultiSource {
    // SourceIds
    public get sourceIds(): ObservableCollection<string> {
        return this.getFieldArray<string>('SourceIds');
    }
    public set sourceIds(value: ObservableCollection<string>) {
        this.setFieldArray<string>('SourceIds', value);
    }
    // ContextId
    public get contextId(): IGuid {
        return this.getFieldGuid('ContextId');
    }
    public set contextId(value: IGuid) {
        this.setFieldGuid('ContextId', value);
    }

    public initializeAllFields(): void {
        this.setFieldArray<string>('SourceIds', new ObservableCollection<string>());
        this.setFieldGuid('ContextId', SafeGuid.EMPTY);
    }
}

export class ContentController extends RestObject implements IContentController {
    constructor() {
        super('api/Content');
        this._canGet = false;
    }

    // #region Rest Methods

    public async getContentAsync(query: IContentSource, transaction?: ITransaction | null): Promise<IContentGroup | null> {
        return await this.getDataRelationAsync<ContentGroup>(ContentGroup, 'Content' + '?' + query.toQueryString(), false, null, transaction);
    }

    public async getContentFromFieldsAsync(query: IContentFieldSource, transaction?: ITransaction | null): Promise<IContentGroup | null> {
        return await this.getDataRelationAsync<ContentGroup>(ContentGroup, 'ContentFromField' + '?' + query.toQueryString(), false, null, transaction);
    }

    public async getContentsAsync(query: IContentMultiSource, transaction?: ITransaction | null): Promise<ObservableCollection<IContentGroup> | null> {
        return this.getFieldObjectRelationAsync<ContentGroup, IContentGroup>(ContentGroup, 'Contents' + '?' + query.toQueryString(), 'Id', true, false, null, transaction);
    }

    // #endregion Rest Methods

}
