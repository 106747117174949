import { Injectable } from '@angular/core';
import { MCIncident, MCIncidentApi } from '@modules/mission-control/models/mc-incident';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { PossibleStateTransitionList, PossibleStateTransitionListApi } from '@modules/mission-control/models/possible-state-transition-list';
import { IncidentFilter } from '@modules/mission-control/models/incident-filter';
import { List } from 'immutable';
import { ListResultApi } from '@modules/mission-control/models/list-result';
import { LoggerService } from '@shared/services/logger/logger.service';
import { StateService } from '../state/state.service';
import { McClient } from './../mc-client.service';
import { IncidentSorting } from './../../models/incident-sorting';
import { Step, StepApi } from './../../models/step';

const LIMIT = 100;

const expandPropertyNames = {
    LinkedIncidentIds: 'LinkedIncidentIds',
    SourceIds: 'SourceIds',
    SubIncidentIds: 'SubIncidentIds',
    ActiveComments: 'ActiveComments',
    LocationAreaIds: 'LocationAreaIds',
    All: 'All',
};

@Injectable()
export class IncidentApiService {
    constructor(private _mcClient: McClient, private _stateService: StateService, private _logger: LoggerService) {}

    public getIncident(incidentId: IGuid): Observable<MCIncident | null> {
        const searchParams = new URLSearchParams();
        searchParams.set('expand', expandPropertyNames.All);
        return this._mcClient.get<MCIncidentApi>(`v2/Incidents/${incidentId.toString()}`, 'json', searchParams).pipe(
            map((x) => MCIncident.assign(x)),
            catchError((_) => of(null))
        );
    }

    public getPossibleStateTransitions(id: IGuid): Observable<PossibleStateTransitionList | undefined> {
        return this._mcClient.get<PossibleStateTransitionListApi>(`v2/Incidents/${id.toString()}/PossibleStateTransitions`).pipe(
            map((x) => PossibleStateTransitionList.assign(x)),
            catchError((_) => of(undefined))
        );
    }

    public getIncidents(filter: IncidentFilter, sorting: IncidentSorting, offset: number): Observable<[List<MCIncident>, number | null]> {
        const queryParams = new URLSearchParams();
        queryParams.set('isRecipient', 'true');

        const createLocationFilter = (): void => {
            if (filter === undefined || filter.locationIds.count() === 0) return;
            queryParams.set('locationIds', filter.locationIds.join(','));
        };

        const createStateFilter = (): void => {
            if (filter === undefined || filter.stateIds.count() === 0) queryParams.set('stateIds', this._stateService.getActiveStatesIds.join(','));
            else queryParams.set('stateIds', filter.stateIds.join(','));
        };

        const createPriorityFilter = (): void => {
            if (filter === undefined || filter.priorityIds.count() === 0) return;
            queryParams.set('priorityIds', filter.priorityIds.join(','));
        };

        const createTypeFilter = (): void => {
            if (filter === undefined || filter.incidentTypeIds.count() === 0) return;
            queryParams.set('incidentTypeIds', filter.incidentTypeIds.join(','));
        };

        const createTimeFilter = (): void => {
            if (filter === undefined) return;
            if (filter.dateTimeFrom) queryParams.set('dateTimeFrom', filter.dateTimeFrom.toString());
            if (filter.dateTimeTo) queryParams.set('dateTimeTo', filter.dateTimeTo.toString());
        };

        const createOwnerFilter = (): void => {
            if (filter === undefined || filter.ownerIds.count() === 0) return;
            queryParams.set('ownerIds', filter.ownerIds.join(','));
        };

        createStateFilter();
        createPriorityFilter();
        createTypeFilter();
        createTimeFilter();
        createLocationFilter();
        createOwnerFilter();
        queryParams.set('limit', LIMIT.toString());
        queryParams.set('offset', offset.toString());
        queryParams.set('sortBy', sorting.toString());

        return this._mcClient.get<ListResultApi<MCIncidentApi>>(`v2/Incidents`, 'json', queryParams).pipe(
            map((x) => {
                let incidents: List<MCIncident> = List();
                let count: number | null = null;
                try {
                    incidents = List(x.Results.map(MCIncident.assign.bind(MCIncident)));
                    count = x.TotalResultsCount ?? null;
                } catch (e) {
                    this._logger.traceError(`Unable to fetch incidents : ${e as string}`);
                    throw e;
                }
                return [incidents, count];
            })
        );
    }

    public getCurrentProcedureStep(incidentId: IGuid): Observable<Step> {
        return this._mcClient.get<StepApi>(`v2/Incidents/${incidentId.toString()}/CurrentProcedureStep`).pipe(map((x) => Step.assign(x)));
    }
}
