import { Cancellation } from '../utils/CancellationToken';
import { Color, NormalizedColor } from '../utils/Color';
import { ILiteEvent, LiteEvent } from '../utils/liteEvents';
import { ILogger } from '../utils/logger';
import { Resolution } from '../utils/Resolution';
import { SafeResizeObserver } from '../utils/SafeResizeObserver';
import { StyleObserver } from '../utils/StyleObserver';

export class CanvasWrapper {
  private readonly m_logger: ILogger
  private readonly m_friendlyName: string;
  private readonly m_canvas: HTMLCanvasElement;
  private readonly m_resizeObserver: SafeResizeObserver;
  private readonly m_styleObserver: StyleObserver;
  private readonly m_resizeEvent: LiteEvent<Resolution>;

  protected get Logger(): ILogger {
    return this.m_logger;
  }

  public get Canvas(): HTMLCanvasElement {
    return this.m_canvas;
  }

  public set Show(show: boolean) {
    this.m_canvas.style.display = show ? 'block' : 'none';
  }

  public get Resolution(): Resolution {
    return Resolution.build(this.m_canvas.width, this.m_canvas.height);
  }

  public get StyleChange(): Promise<void | Cancellation> {
    return this.m_styleObserver.StyleChange;
  }

  public get Color(): NormalizedColor {
    const color = this.Canvas.style.color;
    const colorObj = Color.fromCSSColor(color);
    this.Logger.debug?.trace(`Converted ${color} to ${colorObj.toString()}`);
    return NormalizedColor.fromColor(colorObj);
  }

  public get Resized(): ILiteEvent<Resolution> {
    return this.m_resizeEvent.expose();
  }

  constructor(logger: ILogger, friendlyName: string, htmlCanvasElement: HTMLCanvasElement) {
    this.m_logger = logger.subLogger(friendlyName);
    this.m_friendlyName = friendlyName;
    this.m_canvas = htmlCanvasElement;
    this.m_resizeObserver = new SafeResizeObserver(htmlCanvasElement, this.onResizeCanvas);
    this.m_resizeEvent = new LiteEvent<Resolution>();
    this.m_styleObserver = new StyleObserver(htmlCanvasElement);
  }

  public dispose(): void {
    this.m_resizeObserver.dispose();
    this.m_styleObserver.dispose();
    this.m_logger.debug?.trace('dispose');
  }

  private readonly onResizeCanvas = (): void => {
    const canvas = this.m_canvas;

    if (canvas.clientWidth === 0 || canvas.clientHeight === 0) {
      return;
    }

    if (canvas.width === canvas.clientWidth && canvas.height === canvas.clientHeight) {
      this.m_logger.intense?.trace(`Resolution did not changed from ${canvas.width}x${canvas.height} to ${canvas.clientWidth}x${canvas.clientHeight}`);
      return;
    }

    this.Logger.info?.trace(`Update canvas resolution from ${canvas.width}x${canvas.height} to ${canvas.clientWidth}x${canvas.clientHeight}`);

    // Make the canvas drawing buffer size (canvas.width/height) the same as the displayed resolution (canvas.clientWidth/clientHeight)
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    this.m_resizeEvent.trigger(Resolution.build(canvas.width, canvas.height));
  }

  public debugStatus(_: number): string {
    const show: string = (this.m_canvas.style.display === 'none') ? 'hidden' : 'visible';
    return `Canvas [${this.m_friendlyName}] ${show} ${this.m_canvas.width}x${this.m_canvas.height} client:${this.m_canvas.clientWidth}x${this.m_canvas.clientHeight}`;
  }
}
