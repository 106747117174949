import { Component, OnInit } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { TranslateService } from '@ngx-translate/core';
import { CardholderEntityFields } from 'RestClient/Client/Interface/ICardholderEntity';
import { getAccessStatusResourceString } from '@shared/utilities/accessStatusTranslate';
import { CardholderWidgetBaseComponent } from '../cardholder-widget-base.component';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';

const isContentSupported = (content: Content): boolean => {
    return content && !content.contextContent && content.type.equals(AccessControlContentTypes.Cardholder);
};
@InternalContentPluginDescriptor({
    type: CardholderInfoWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CardholderInfoWidgetComponent.pluginId, priority: 5 },
    isContentSupported,
})
@Component({
    selector: 'app-cardholder-info-widget',
    templateUrl: './cardholder-info-widget.component.html',
    styleUrls: ['./cardholder-info-widget.component.scss'],
})
export class CardholderInfoWidgetComponent extends CardholderWidgetBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('16E5A3F2-4531-49AC-88F6-2FE76ED391A5');

    public readonly TextFlavor = TextFlavor;

    protected get monitoredFields(): string[] {
        const fields = super.monitoredFields;
        fields.push(CardholderEntityFields.emailAddressField);
        fields.push(CardholderEntityFields.accessStatusField);
        return fields;
    }

    public emailAddress = '';
    public groupNames: string[] = [];
    public status = '';

    constructor(securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService, private translateService: TranslateService) {
        super(securityCenterClientService, trackingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = false;
    }

    protected async initializeCardholerInfo(): Promise<void> {
        await super.initializeCardholerInfo();

        if (this.cardholder) {
            this.emailAddress = this.cardholder.emailAddress ?? '-';
            this.status = this.translateService.instant(getAccessStatusResourceString(this.cardholder.accessStatus)) as string;

            const cardholderGroups = await this.cardholder.getCardholdergroupsAsync();
            if (cardholderGroups) {
                if (cardholderGroups.size === 0) {
                    this.groupNames.push(this.translateService.instant('STE_LABEL_NO_GROUP') as string);
                } else {
                    for (const group of cardholderGroups) {
                        this.groupNames.push(group.name);
                    }
                }
            }
        }
    }
}
