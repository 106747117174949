import { MCCommandBase, MCCommandType } from "@modules/mission-control/models/commands/command";
import { IGuid } from "safeguid";

const commandType = MCCommandType.UnlinkIncidents;

export class UnlinkIncidentCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentsToUnlink: IGuid[]) {
        super('/v2/Incidents', JSON.stringify({ Type: commandType, Ids: incidentsToUnlink.map(id => id.toString()) }));
    }

    public errorMessage(statusCode?: number): string {
        return 'STE_MESSAGE_CANNOT_UNLINK_INCIDENTS';
    }
}
