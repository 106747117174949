import { Directive, HostListener, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
@UntilDestroy()
@Directive({
    selector: '[appHoverClass]',
})
export class HoverClassDirective implements OnInit, OnDestroy {
    @Input() public appHoverClass = '';
    @Input() public appHoverClassDelay = 0;
    @Input() public appHoverClassResetDelay?: Observable<void>;

    private cancelTimeout: number | null = null;

    constructor(public elementRef: ElementRef<Element>) {}

    @HostListener('mouseenter') public onMouseEnter(): void {
        if (this.appHoverClassDelay) {
            this.resetClassWithTimeout(this.appHoverClassDelay);
        } else {
            this.addHoverClass();
        }
    }

    @HostListener('mouseleave') public onMouseLeave(): void {
        this.removeHoverClass();
    }

    public resetClassWithTimeout(delay: number): void {
        this.tryCancelTimeout();
        this.cancelTimeout = window.setTimeout(() => this.addHoverClass(), delay);
    }

    public addHoverClass(): void {
        this.elementRef.nativeElement.classList.add(this.appHoverClass);
    }

    public removeHoverClass(): void {
        this.elementRef.nativeElement.classList.remove(this.appHoverClass);
        this.tryCancelTimeout();
    }

    public tryCancelTimeout(): void {
        if (this.cancelTimeout) {
            clearTimeout(this.cancelTimeout);
            this.cancelTimeout = null;
        }
    }

    ngOnDestroy(): void {
        this.tryCancelTimeout();
    }

    ngOnInit(): void {
        this.appHoverClassResetDelay?.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.appHoverClassDelay) {
                this.resetClassWithTimeout(Number(this.appHoverClassDelay) * 2);
            }
        });
    }
}
