// Autogenerated: CodeGen/EntityBrowserResult?language=ts&InterfaceOnly=True on 2022-07-12 12:36:21 PM
// Autogenerated: CodeGen/EntityBrowserResult?language=ts&InterfaceOnly=False on 2022-07-12 12:36:21 PM
// Autogenerated: CodeGen/HierarchicalEntityBrowserResult?language=ts&InterfaceOnly=True on 2022-07-12 12:36:21 PM
// Autogenerated: CodeGen/HierarchicalEntityBrowserResult?language=ts&InterfaceOnly=False on 2022-07-12 12:36:21 PM

import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { IEntityBrowserItemModel } from './items/entity-browser-item-model.interface';
import { EntityBrowserItemModel } from '../Items/entity-browser-item-model';
import { GuidMap, IGuid, SafeGuid } from 'safeguid';
import { RegisterFieldObjectConstructor, RegisterFieldObjectOverload } from 'RestClient/Helpers/FieldObjectOverloads';
import { HierarchicalData, IHierarchicalData } from '../../models/hierarchical-data';

export interface IEntityBrowserResult extends IHierarchicalData {
    currentPage: number;
    includedEntityIds: ObservableCollection<IGuid>;
    isResultComplete: boolean;
    isValid: boolean;
    entityBrowserItemModels: ObservableCollection<IEntityBrowserItemModel>;
    lastEntityId: IGuid;
}

export class EntityBrowserResult extends HierarchicalData implements IEntityBrowserResult {
    // CurrentPage
    public get currentPage(): number {
        return this.getField<number>('CurrentPage');
    }
    public set currentPage(value: number) {
        this.setField<number>('CurrentPage', value);
    }
    // IncludedEntityIds
    public get includedEntityIds(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('IncludedEntityIds');
    }
    public set includedEntityIds(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('IncludedEntityIds', value);
    }
    // IsResultComplete
    public get isResultComplete(): boolean {
        return this.getField<boolean>('IsResultComplete');
    }
    public set isResultComplete(value: boolean) {
        this.setField<boolean>('IsResultComplete', value);
    }
    // IsValid
    public get isValid(): boolean {
        return this.getField<boolean>('IsValid');
    }
    public set isValid(value: boolean) {
        this.setField<boolean>('IsValid', value);
    }
    // EntityBrowserItemModels
    public get entityBrowserItemModels(): ObservableCollection<IEntityBrowserItemModel> {
        return this.getFieldObjectArray<EntityBrowserItemModel, IEntityBrowserItemModel>(EntityBrowserItemModel, 'EntityBrowserItemModels');
    }
    public set entityBrowserItemModels(value: ObservableCollection<IEntityBrowserItemModel>) {
        this.setFieldObjectArray('EntityBrowserItemModels', value);
    }
    // LastEntityId
    public get lastEntityId(): IGuid {
        return this.getFieldGuid('LastEntityId');
    }
    public set lastEntityId(value: IGuid) {
        this.setFieldGuid('LastEntityId', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('CurrentPage', 0);
        this.setFieldArrayGuid('IncludedEntityIds', new ObservableCollection<IGuid>());
        this.setField<boolean>('IsResultComplete', false);
        this.setField<boolean>('IsValid', false);
        this.setFieldArray<IEntityBrowserItemModel>('EntityBrowserItemModels', new ObservableCollection<IEntityBrowserItemModel>());
        this.setFieldGuid('LastEntityId', SafeGuid.EMPTY);
    }
}

export interface IHierarchicalEntityBrowserResult extends IEntityBrowserResult {
    subResults: Map<IGuid, IHierarchicalEntityBrowserResult>;
}

@RegisterFieldObjectConstructor
@RegisterFieldObjectOverload('EntityBrowserResult', 'HierarchicalEntityBrowserResult')
export class HierarchicalEntityBrowserResult extends EntityBrowserResult implements IHierarchicalEntityBrowserResult {
    // SubResults
    public get subResults(): Map<IGuid, IHierarchicalEntityBrowserResult> {
        return this.getFieldObjectDictionary<IGuid, HierarchicalEntityBrowserResult, IHierarchicalEntityBrowserResult>(
            HierarchicalEntityBrowserResult,
            'SubResults',
            undefined,
            'IGuid'
        );
    }
    public set subResults(value: Map<IGuid, IHierarchicalEntityBrowserResult>) {
        this.setFieldObjectDictionary('SubResults', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldDictionary('SubResults', new GuidMap<IHierarchicalEntityBrowserResult>());
    }
}
