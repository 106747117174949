import { Component, Injector, OnInit } from '@angular/core';
import { MeltedModalAction, TextFlavor } from '@genetec/gelato-angular';
import { TypedConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { UnsavedChangesModalActions } from '../../../enumerations/unsaved-changes-modal-actions';

@Component({
    selector: 'app-unsaved-changes-modal',
    templateUrl: './unsaved-changes-modal.component.html',
})
export class CardholderCancelModalComponent extends TypedConnectionAwareModalComponent<UnsavedChangesModalActions> implements OnInit {
    public readonly MeltedModalAction = MeltedModalAction;
    public TextFlavor = TextFlavor;

    constructor(protected authService: AuthService, injector: Injector, trackingService: TrackingService) {
        super(authService, injector, trackingService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    public show(): void {
        if (this.genModalComponent) {
            this.genModalComponent.show();
        }
    }

    public onSave = (): Promise<boolean> => {
        this.emitResult(UnsavedChangesModalActions.Save);
        return Promise.resolve(true);
    };

    public onDiscard = (): Promise<boolean> => {
        this.emitResult(UnsavedChangesModalActions.Discard);
        return Promise.resolve(true);
    };

    public onCancel = (): Promise<boolean> => {
        this.emitResult(UnsavedChangesModalActions.Cancel);
        return Promise.resolve(true);
    };
}
