import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LogonState } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { LogonResult } from 'RestClient/Client/Enumerations/LogonResult';
import { BasicLogonInfo, LogonInfo, TokenLogonInfo } from 'RestClient/Client/Parameters/LogonInfo';
import * as Helpers from 'RestClient/Helpers/Helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { Constants } from '@src/constants';
import { PasswordConstraints } from '@modules/shared/models/logon-result-password-constraints';
import { ModuleService } from '@modules/shared/services/module/module.service';
import { UserLogin } from '@src/app/components/login/user-login.model';
import { WebAppClient } from 'WebClient/WebAppClient';
import { WINDOW } from '@utilities/common-helper';
import { SecurityCenterClientService } from '../client/security-center-client.service';
import { AuthenticationError } from './models/authentication-error';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable({ providedIn: 'root' })
export class AuthService {
    public static readonly defaultTimeout = 20000;
    public loginRedirectUrl: string | undefined;
    public loginStatus = '';
    public isLoggingOn = false;
    public isExpired = false;
    public isLoggingIn = false;
    public passwordConstraint?: PasswordConstraints;
    public userDirectory = '';

    private isLoggingOut = false;

    public get loggedIn$(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }

    private securityCenterClient: WebAppClient;
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject(Constants.baseUrlIdentifier) private baseUrl: string,
        private router: Router,
        private scProvider: SecurityCenterClientService,
        moduleService: ModuleService,
        pluginService: PluginService,
        private translateService: TranslateService,
        @Inject(WINDOW) private window: Window
    ) {
        this.isLoggingOn = true;
        this.securityCenterClient = scProvider?.scClient;
        this.securityCenterClient.onLogonStateChanged((logonState) => {
            this.loggedIn.next(logonState.loggedOn());

            // we may re-logging in, and we only want to state we're not logging on if we're any of the other known states
            this.isLoggingOn = logonState.state === LogonState.ReloggingOn;

            if (logonState.state === LogonState.LoggedOff) {
                this.loginRedirectUrl = this.router.url; // Memorize current page path.
            }
        });

        this.securityCenterClient.requestedPrivileges = Array.from(pluginService.getPluginsGlobalPrivileges(), (item) => item.toString()).concat(
            Array.from(moduleService.getModulesGlobalPrivileges(), (item) => item.toString())
        );

        this.initialLoginLogic();
    }

    public initialLoginLogic(): void {
        this.logInWithTokenAsync().catch(() => {
            this.scProvider.resetToken(); // clear the local authentication token as it isn't valid
            this.loginStatus = ''; // even if it fails, first request must not show anything
            this.isLoggingOn = false;
        });

        this.pollSessionAlive();
        this.bypassTabDuplicateSessionStorage();
    }

    public getConnectionHeaders(): Map<string, string> | null {
        try {
            if (this.securityCenterClient?.rest !== null) {
                const headers = new Map<string, string>();
                headers.set('Authorization', this.securityCenterClient.rest.authorizationHeader);
                for (const [key, value] of this.securityCenterClient.rest.additionalHeaders) {
                    headers.set(key, value);
                }
                return headers;
            }
        } catch {
            // for _some_ reason, we throw an exn if the rest property ^ is null, which is weird...
        }
        return null;
    }

    public logInWithCredentialsAsync(userLogin: UserLogin, cancelToken?: Helpers.CancellationToken): Promise<void> {
        const loginInfo = new BasicLogonInfo(this.baseUrl, userLogin.username.value.trim(), userLogin.password.value);
        return this.logInAsync(loginInfo, cancelToken);
    }

    public logInWithTokenAsync(cancelToken?: Helpers.CancellationToken): Promise<void> {
        const loginInfo = new TokenLogonInfo(this.baseUrl);
        return this.logInAsync(loginInfo, cancelToken);
    }

    public async logInAsync(userLogin: LogonInfo, cancelToken?: Helpers.CancellationToken): Promise<void> {
        try {
            this.loginStatus = '';
            let token = cancelToken;
            // ensure we have a cancellation token
            if (!token) {
                token = new Helpers.CancellationToken();
            }
            const response = await this.securityCenterClient.logonAsync(userLogin, token);

            this.passwordConstraint = response.passwordConstraints as PasswordConstraints | undefined;
            this.userDirectory = userLogin.directory;

            if (response.cancelled === true) {
                const timeout = this.translateService.instant('STE_MESSAGE_ERROR_OPERATIONTIMEOUT') as string;
                this.loginStatus = timeout;
                throw new Error(timeout);
            }
            if (response.statusCode !== 200) {
                const error = this.getLogonResultErrorString(response.logonResult, response.statusCode, response.body as string);
                this.loginStatus = error;
                throw new AuthenticationError(response.logonResult, error);
            } else {
                // logon succeeded, clear the error
                this.loginStatus = '';
            }
        } catch (e: unknown) {
            this.scProvider.resetUniqueAppId();
            throw e instanceof Error ? e : new Error(typeof e === 'string' ? e : undefined);
        }

        if (this.loginRedirectUrl !== undefined) {
            await this.router.navigateByUrl(this.loginRedirectUrl);
        }
    }

    public async logOutAsync(): Promise<void> {
        if (this.isLoggingOut) return;
        try {
            this.isLoggingOut = true;
            this.scProvider.resetUniqueAppId();
            this.scProvider.resetToken();
            this.loggedIn.next(false);
            if (await this.getIsAuthenticatedWithOpenIdAsync()) {
                window.location.href = `${this.baseUrl}api/Session/SignoutOpenid/?redirectUri=${encodeURIComponent(window.location.href)}`;
            } else {
                await this.securityCenterClient.logoffAsync();
            }
        } finally {
            this.isLoggingOut = false;
        }
    }

    public getIsAuthenticatedWithOpenIdAsync(): Promise<boolean> {
        try {
            return this.securityCenterClient.getIsAuthenticatedWithOpenIdAsync();
        } catch (e) {
            return Promise.resolve(false);
        }
    }

    public isSigningIn(bool: boolean): void {
        this.isLoggingIn = bool;
    }

    private getLogonResultErrorString(logonResult: string, statusCode: number, responseBody: string): string {
        switch (logonResult) {
            case LogonResult.InvalidCredentials:
                return this.translateService.instant('STE_MESSAGE_ERROR_INVALIDCREDENTIALS') as string;

            case LogonResult.InsufficientPrivileges:
                return this.translateService.instant('STE_MESSAGE_ERROR_INSUFFICIENTPRIVILEGES') as string;

            case LogonResult.InvalidServerVersion:
                return this.translateService.instant('STE_MESSAGE_ERROR_INVALIDSERVERVERSION') as string;

            case LogonResult.OperationTimeout:
                return this.translateService.instant('STE_MESSAGE_ERROR_OPERATIONTIMEOUT') as string;

            case LogonResult.LicenseError:
                return this.translateService.instant('STE_MESSAGE_ERROR_LICENSEERROR') as string;

            case LogonResult.NoAuthenticationAgent:
                return this.translateService.instant('STE_MESSAGE_ERROR_NOAUTHENTICATIONAGENT') as string;

            case LogonResult.PasswordExpired:
                return this.translateService.instant('STE_MESSAGE_ERROR_PASSWORDEXPIRED') as string;

            case LogonResult.UserAccountDisabledOrLocked:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_ACCOUNT_DISABLED_OR_LOCKED') as string;

            case LogonResult.DisallowedBySchedule:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_DISALLOWEDBYSCHEDULE') as string;

            case LogonResult.InsufficientSecurityLevel:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_INSUFFICIENT_SECURITY_LEVEL') as string;

            case LogonResult.InvalidSupervisor:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_SUPERVISORREQUIRED') as string;

            case LogonResult.SupervisorPasswordIsEmpty:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_SUPERVISORPASSWORDEMPTY') as string;

            case LogonResult.CantSuperviseSelf:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_CANTSUPERVISESELF') as string;

            case LogonResult.ExceededNumberOfWorkstations:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_EXCEEDEDWORKSATIONS') as string;

            case LogonResult.TooManyAuthenticationAgents:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_TOO_MANY_AUTHENTICATION_AGENTS') as string;

            case LogonResult.UserAndSupervisorOnDifferentDomains:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_USERSUPERVISORDIFFERENTDOMAIN') as string;

            case LogonResult.SpecifyDomain:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_SPECIFYDOMAIN') as string;

            case LogonResult.InteractiveLogonRequired:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_INTERACTIVELOGONREQUIRED') as string;

            case LogonResult.DeniedByFirewall:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_OPERATION_DENIED_BY_FIREWALL') as string;

            case LogonResult.AuthenticationAgentResponseTimeout:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_AUTHENTICATION_AGENT_RESPONSE_TIMEOUT') as string;

            case LogonResult.BruteForceThrottling:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_USERTEMPORARYLOCKED') as string;

            case LogonResult.AlreadyConnected:
                return this.translateService.instant('STE_MESSAGE_ERROR_LOGIN_ALREADYCONNECTED') as string;

            default:
                if (responseBody === LogonResult.LicenseError) {
                    return this.translateService.instant('STE_MESSAGE_ERROR_LICENSEERROR') as string;
                }

                return (this.translateService.instant('STE_LABEL_LOGONFAILED') as string) + ' (' + (logonResult ? logonResult + ', ' : '') + statusCode.toString() + ')';
        }
    }

    /** Gets a value from the local storage indicating if the authentication token was defined. The auth token is set in an httpOnly cookie, so the client cannot inspect the cookie.
     * Local storage is shared across the session, so if a user has more than 1 tab open, all tabs can rely on this value to know if they should log out or not.
     */
    private isAuthTokenSet(): boolean {
        return this.window.localStorage.getItem(Constants.isWebAppTokenSet) !== null;
    }

    /** Whenever local storage settings are modified, checks whether the WebApp token is set. If not, performs a logoff on all tabs, otherwise performs in implicit login (using auth token). */
    private pollSessionAlive(): void {
        this.window.addEventListener('storage', () => {
            this.loggedIn$.pipe(take(1)).subscribe(async (isLoggedIn: boolean) => {
                //If we're not logged on, but the token is valid, refresh the page to log back in (the user probably logged back on in another tab)
                if (!isLoggedIn && this.isAuthTokenSet() && !this.isLoggingOn && !this.isLoggingOut) {
                    this.isLoggingOn = true;
                    await this.logInWithTokenAsync();
                    return;
                }

                //If we're already logged in, but the token is invalid, log out the user immediately
                if (!this.isAuthTokenSet() && isLoggedIn) {
                    this.isLoggingOn = false;
                    await this.logOutAsync();
                }
            });
        });
    }

    //Only on Chrome browser, duplicating a tab copies the session storage to the new tab, causing both tabs to have the same unique App ID
    //This causes the first user to be logged out, so to prevent it we need to reset the app id when duplicating a tab
    private bypassTabDuplicateSessionStorage() {
        const lockName = '__lock__unique__app__id';

        this.window.addEventListener('beforeunload', (event) => {
            sessionStorage.removeItem(lockName);
        });

        if (sessionStorage.getItem(lockName)) {
            this.scProvider.resetUniqueAppId();
        }

        sessionStorage.setItem(lockName, '1');
    }
}
