<!--    Title -->
<div class="overview-widget-container">
    <div class="ha-left overview-widget-header">
        <gen-text [flavor]="Flavor.Text.Secondary">{{ 'STE_LABEL_OVERVIEW' | translate }}</gen-text>
        <gen-button [hidden]="editing || (isOwner$ | async) === false" [flavor]="ButtonFlavor.Flat" (click)="toggleEditing()" class="editing-icon">
            <gen-icon [icon]="Icon.Pencil"></gen-icon>
        </gen-button>
        <div [hidden]="!editing">
            <gen-button [flavor]="ButtonFlavor.Flat" (click)="toggleEditing()">
                {{ 'STE_BUTTON_CANCEL' | translate }}
            </gen-button>
            <gen-button
                [disabled]="
                    !hasChanged() || (hasExternalIdChanged() && !(externalIDInputModel?.valid ?? false)) || (hasDescriptionChanged() && !(descriptionInputModel?.valid ?? false))
                "
                [flavor]="ButtonFlavor.Flat"
                (click)="saveChangesClick(incidentDescriptionInput, incidentExternalIdInput, incidentPriorityInput)"
            >
                {{ 'STE_BUTTON_SAVE' | translate }}
            </gen-button>
        </div>
    </div>

    <div *ngIf="incident$ | async as incident; else loading">
        <!-- "   Start-->
        <gen-grid [genColumns]="2" class="gen-card-body">
            <gen-grid-item>
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_PRIORITY' | translate }}
                </gen-text>
                <gen-text *ngIf="incident.priorityId | priorityName | async as priority" [hidden]="editing && hasEditIncidentPriorityPrivilege" [genMeltedTooltip]="priority">
                    {{ truncate(priority) }}
                </gen-text>
                <gen-combobox
                    [hidden]="!editing || !hasEditIncidentPriorityPrivilege"
                    [(ngModel)]="incidentPriorityInput"
                    [genItemsSource]="prioritiesList"
                    [genFlavor]="Flavor.Combobox.Solid"
                    [genPosition]="Position.Combobox.BottomLeft"
                >
                </gen-combobox>
            </gen-grid-item>

            <gen-grid-item>
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_INCIDENT_DESCRIPTION' | translate }}
                </gen-text>
                <div [hidden]="editing && hasEditIncidentDescriptionPrivilege">
                    <gen-text *ngIf="incident.description; else labelNone" [genMeltedTooltip]="incident.description">
                        {{ truncate(incident.description) }}
                    </gen-text>
                </div>
                <app-incident-comment
                    [hidden]="!editing || !hasEditIncidentDescriptionPrivilege"
                    [(ngModel)]="incidentDescriptionInput"
                    [maxlength]="1024"
                    [placeholder]="incidentDescriptionInput"
                    [customError]="'STE_LABEL_INVALIDFILTER' | translate"
                    label=""
                    [multiline]="1"
                    #descriptionInput="ngModel"
                ></app-incident-comment>
            </gen-grid-item>

            <gen-grid-item>
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_STATE' | translate }}
                </gen-text>
                <gen-text *ngIf="incident.stateId | stateName | async as state" [genMeltedTooltip]="state">
                    {{ truncate(state) }}
                </gen-text>
            </gen-grid-item>

            <gen-grid-item>
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_TRIGGER_TIME' | translate }}
                </gen-text>
                <gen-text>
                    {{ incident.triggerTimeUtc | dateFormatWithTimezone: 'MMM d, y h:mm:ss A' | async }}
                </gen-text>
            </gen-grid-item>

            <gen-grid-item [hidden]="!isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_ENTITY_AREA' | translate }}
                </gen-text>
                <div *ngIf="locationAreaIds$ | async as locationAreaIds; else labelNone">
                    <div *ngIf="locationAreaIds.length > 0; else labelNone">
                        <gen-text *ngFor="let locationAreaId of locationAreaIds">
                            {{ locationAreaId | locationAreaName | async }}
                        </gen-text>
                    </div>
                </div>
            </gen-grid-item>
            <gen-grid-item [hidden]="!isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_INCIDENT_CATEGORY' | translate }}
                </gen-text>
                <gen-text *ngIf="category$ | async as category; else labelNone" [genMeltedTooltip]="category">
                    {{ truncate(category) }}
                </gen-text>
            </gen-grid-item>
            <gen-grid-item [hidden]="!isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_INCIDENT_SOURCES' | translate }}
                </gen-text>
                <div *ngIf="sourceIds$ | async as sourceIds; else labelNone">
                    <div *ngIf="sourceIds.length > 0; else labelNone">
                        <gen-text *ngFor="let sourceId of sourceIds">
                            {{ sourceId | sourceIdsName | async }}
                        </gen-text>
                    </div>
                </div>
            </gen-grid-item>

            <gen-grid-item *ngIf="isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_LOCATION' | translate }}
                </gen-text>
                <gen-text *ngIf="incident.location?.entityId | incidentLocationName | async as location" [genMeltedTooltip]="location">
                    {{ truncate(location) }}
                </gen-text>
            </gen-grid-item>

            <gen-grid-item *ngIf="isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_OWNER' | translate }}
                </gen-text>
                <gen-text *ngIf="incident.ownerId | userName | async as owner" [genMeltedTooltip]="owner">
                    {{ truncate(owner) }}
                </gen-text>
            </gen-grid-item>

            <gen-grid-item *ngIf="isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_INCIDENT_RECIPIENTS' | translate }}
                </gen-text>
                <div *ngIf="incident.recipientIds.count() > 0">
                    <div *ngFor="let recipient of incident.recipientIds">
                        <gen-text>
                            {{ recipient | userName | async }}
                        </gen-text>
                    </div>
                </div>

                <gen-text *ngIf="incident.recipientsMode === 1">
                    {{ 'STE_LABEL_EVERYONE' | translate }}
                </gen-text>
            </gen-grid-item>
            <gen-grid-item *ngIf="isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_LATITUDE' | translate }}
                </gen-text>
                <gen-text *ngIf="incident.location?.latitude; else labelNone">
                    {{ incident.location?.latitude }}
                </gen-text>
            </gen-grid-item>
            <gen-grid-item *ngIf="isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_LONGITUDE' | translate }}
                </gen-text>
                <gen-text *ngIf="incident.location?.longitude; else labelNone">
                    {{ incident.location?.longitude }}
                </gen-text>
            </gen-grid-item>
            <gen-grid-item [hidden]="!isExpanded">
                <gen-text [flavor]="Flavor.Text.Label">
                    {{ 'STE_LABEL_INCIDENT_EXTERNAL_ID' | translate }}
                </gen-text>
                <div [hidden]="editing">
                    <gen-text *ngIf="incident.externalId; else labelNone" [genMeltedTooltip]="incident.externalId">
                        {{ truncate(incident.externalId) }}
                    </gen-text>
                </div>
                <app-incident-comment
                    [(ngModel)]="incidentExternalIdInput"
                    [hidden]="!editing"
                    [maxlength]="128"
                    [placeholder]="incidentExternalIdInput"
                    [customError]="'STE_LABEL_INVALIDFILTER' | translate"
                    label=""
                    [multiline]="1"
                    #externalIDInput="ngModel"
                ></app-incident-comment>
            </gen-grid-item>
        </gen-grid>

        <div class="ha-left">
            <gen-button *ngIf="!isExpanded" [flavor]="ButtonFlavor.Flat" (click)="isExpanded = true">
                {{ 'STE_BUTTON_SHOWMORE' | translate }}
            </gen-button>

            <gen-button *ngIf="isExpanded" [flavor]="ButtonFlavor.Flat" (click)="isExpanded = false">
                {{ 'STE_BUTTON_SHOWLESS' | translate }}
            </gen-button>
        </div>
    </div>

    <ng-template #loading>
        <gen-grid-item class="pl-4 pr-4 ha-center gen-flex gen-flex-column">
            <gen-spinner [size]="SpinnerSize.Large" class="ha-center va-center">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        </gen-grid-item>
    </ng-template>

    <ng-template #labelNone>
        <gen-text>
            {{ 'STE_LABEL_NONE' | translate }}
        </gen-text>
    </ng-template>
</div>
