import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-lcd-panel',
    templateUrl: './lcd-panel.component.html',
    styleUrls: ['./lcd-panel.component.scss'],
})
export class LcdPanelComponent {
    @Input()
    public maxCharUntilOverflow = 36;

    private textValue = '';
    private fontColorValue = '#6ac66a';
    private backgroundColorValue = 'black';

    constructor() {}

    @Input()
    public set text(value: string | null) {
        if (value) {
            this.textValue = value;
        } else {
            this.textValue = '';
        }
    }
    public get text(): string | null {
        return this.textValue;
    }

    @Input()
    public set fontColor(color: string) {
        if (color) {
            this.fontColorValue = color;
        }
    }

    @Input()
    public set backgroundColor(bgColor: string) {
        if (bgColor) {
            this.backgroundColorValue = bgColor;
        }
    }

    public getStyles(): any {
        return {
            color: this.fontColorValue,
            backgroundColor: this.backgroundColorValue,
        };
    }

    public shouldTextWrap(): boolean {
        return this.textValue?.length > this.maxCharUntilOverflow;
    }
}
