// Autogenerated: v2/help/Entities/ApiCardholderGroup?codegen=ts&InterfaceOnly=True&workflow=False on 2020-07-23 3:40:10 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity, EntityFields } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class CardholderGroupEntityFields extends EntityFields {
    public static accessPermissionLevelField = 'AccessPermissionLevel';
    public static antipassbackExemptionField = 'AntipassbackExemption';
    public static antipassbackExemptionIsInheritedField = 'AntipassbackExemptionIsInherited';
    public static emailAddressField = 'EmailAddress';
    public static inheritAccessPermissionLevelFromParentGroupField = 'InheritAccessPermissionLevelFromParentGroup';
    public static isVisitorGroupField = 'IsVisitorGroup';
}

// #endregion Fields

export interface ICardholderGroupEntity extends IEntity {
    // #region REST Properties

    accessPermissionLevel: number;

    antipassbackExemption: boolean;

    antipassbackExemptionIsInherited: boolean;

    emailAddress: string;

    inheritAccessPermissionLevelFromParentGroup: boolean;

    isVisitorGroup: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

