/* eslint-disable @typescript-eslint/no-namespace */
// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export namespace ContentFields {
    export const sourceId = 'sourceid';
    export const incidentId = 'incidentid';
    export const listOrientation = 'listorientation';
    export const requestingContext = 'requestingcontext';
}
