// Autogenerated: v2/help/Entities/ApiZone?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IEntity } from '../../Interface/IEntity';
import { IZoneEntity, ZoneEntityFields } from '../../Interface/IZoneEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { IGuid } from 'safeguid';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IRestResponse } from '../../Interface/IRestResponse';

export class ZoneEntity extends Entity implements IZoneEntity {
    // #region REST Properties

    // AccessControlUnit
    public get accessControlUnit(): IGuid {
        return this.getFieldGuid(ZoneEntityFields.accessControlUnitField, ValidFlags.Specific);
    }
    public set accessControlUnit(value: IGuid) {
        this.setFieldGuid(ZoneEntityFields.accessControlUnitField, value);
    }

    // ArmingDelay
    public get armingDelay(): number {
        return this.getField<number>(ZoneEntityFields.armingDelayField, ValidFlags.Specific);
    }
    public set armingDelay(value: number) {
        this.setField<number>(ZoneEntityFields.armingDelayField, value);
    }

    // ArmingDelayOn
    public get armingDelayOn(): boolean {
        return this.getField<boolean>(ZoneEntityFields.armingDelayOnField, ValidFlags.Specific);
    }
    public set armingDelayOn(value: boolean) {
        this.setField<boolean>(ZoneEntityFields.armingDelayOnField, value);
    }

    // ArmingState
    public get armingState(): string {
        return this.getField<string>(ZoneEntityFields.armingStateField, ValidFlags.EntityStatus);
    }

    // ZoneState
    public get zoneState(): string {
        return this.getField<string>(ZoneEntityFields.zoneStateField, ValidFlags.EntityStatus);
    }

    // ZoneType
    public get zoneType(): string {
        return this.getField<string>(ZoneEntityFields.zoneTypeField, ValidFlags.Base);
    }
    public set zoneType(value: string) {
        this.setField<string>(ZoneEntityFields.zoneTypeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Zones);
    }

    // #endregion Constructor

    // #region REST Methods

    public async armZoneAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('ArmZone', 'POST', null, transaction, responseHandler);
    }

    public async getDevicesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Devices', 'Id', true, query, ValidFlags.Membership, transaction);
    }

    public async disarmZoneAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('DisarmZone', 'POST', null, transaction, responseHandler);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', true, query, ValidFlags.EntityMembership, transaction);
    }

    // #endregion REST Methods

}

