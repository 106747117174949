export const GetBaseUriForCodeGen = (): string | null => {
    const tags = document.getElementsByTagName('base');
    if (tags?.length > 0) {
        const uri = tags[0].href;
        if (uri) {
            if (uri.endsWith('/')) {
                return uri.substr(0, uri.length - 1);
            }
            return uri;
        }
    }
    return null;
};
