import { AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';

/**
 * Will validate if startTime comes before endTime.
 * To be used by passing directly to the validators array in a FormGroup.
 *
 * @param control - an AbstractControl (usually a FormGroup) that has a child control called 'startTime' and another called 'endTime'
 */
function timeRangeValidator(control: AbstractControl): ValidationErrors | null;

/**
 * Will validate if startTime comes before endTime.
 * To be used by wrapping around a function that calls timeRangeValidator with two FromControls, and then pass it to the validators array in a FormGroup.
 *
 * @param startTime - an AbstractControl (usually a FormControl) that reprensents a startTime
 * @param endTime - an AbstractControl (usually a FormControl) that reprensents an endTime
 */
function timeRangeValidator(startTime: AbstractControl | null, endTime: AbstractControl | null): ValidationErrors | null;

function timeRangeValidator(controlOrStartTime: AbstractControl | null, endTime?: AbstractControl | null): ValidationErrors | null {
    let startTime;
    if (controlOrStartTime && !endTime) {
        let control = controlOrStartTime;
        startTime = control.get('startTime');
        endTime = control.get('endTime');
    } else {
        startTime = controlOrStartTime;
    }

    // has error
    if (startTime && endTime && moment(startTime.value as string) >= moment(endTime.value as string)) {
        setErrors(startTime);
        setErrors(endTime);

        return { timeRange: true };
    }

    // no errors
    if (startTime) {
        removeErrors(startTime);
    }
    if (endTime) {
        removeErrors(endTime);
    }

    return null;
}

function setErrors(control: AbstractControl) {
    control.setErrors({ ...control.errors, timeRange: true });
}

function removeErrors(control: AbstractControl) {
    if (control.errors?.timeRange) {
        // Angular Forms considers a FormControl as invalid when Object.keys(control.errors) >= 1
        delete control.errors.timeRange;
        control.updateValueAndValidity();
    }
}

export { timeRangeValidator };
