import { SafeGuid } from 'safeguid';
import { AlterationType, IncidentEvent, IncidentEventType, RecipientAlteredEvent } from '@modules/mission-control/models/events/incident-event';
import { AudioService } from '@modules/shared/services/audio/audio.service';
import { McEventReceiverService } from './../events/mc-event-receiver.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { IncidentApiService } from '../incident/incident-api.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class McActionService {
    constructor(private _audioService: AudioService, private _incidentApiService: IncidentApiService, _eventReceiver: McEventReceiverService) {
        _eventReceiver
            .getIncidentEvents()
            .pipe(
                tap(async (events) => await this.handleEvent(events)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public async handleEvent(events: IncidentEvent[]): Promise<void> {
        for (const event of events) {
            switch (event.type) {
                case IncidentEventType.Created:
                case IncidentEventType.RecipientsAltered:
                    await this.playSound(event);
            }
        }
    }

    private async playSound(event: IncidentEvent): Promise<void> {
        const incident = await this._incidentApiService.getIncident(event.incidentId).toPromise();
        if (incident && (event.type === IncidentEventType.Created || (event as RecipientAlteredEvent).alterationType === AlterationType.Transfer)) {
            if (incident.soundId.equals(SafeGuid.EMPTY)) return;
            await this._audioService.playAudio(incident.soundId);
        }
    }
}
