import { Inject, Injectable, NgZone } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import { Subject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UserSettingsService } from '../../shared/services/user-settings/user-settings.service';
import { CommandsService, COMMANDS_SERVICE, ExecuteCommandData, USER_SETTINGS_SERVICE } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionTypes } from '../../shared/enumerations/option-types';
import { SharedCommands } from '../../shared/enumerations/shared-commands';
import { Point } from '../../shared/interfaces/drawing';
import { LoggerService } from '../../shared/services/logger/logger.service';
import { ContextTypes } from '../../shared/interfaces/plugins/public/context-types';
import { JoystickLocalConfigIds } from '../components/options/joystick-options/joystick-options.component';
import { AnalogicGamepadEventData, ButtonGamepadEventData, GamepadEventData, GamepadEventListenerService } from './gamepad-event-listener.service';

export class JoystickCommandData {
    public readonly velocity: Point;

    constructor(velocity: Point) {
        this.velocity = velocity;
    }

    public static extract(executeCommandData: ExecuteCommandData): JoystickCommandData | undefined {
        if (!executeCommandData.commandContext) {
            return;
        }

        if (executeCommandData.commandContext.type.equals(ContextTypes.Specific) && executeCommandData.commandContext.data instanceof JoystickCommandData) {
            return executeCommandData.commandContext.data;
        }
    }
}

export class JoystickButtonConfig {
    //#region Properties

    public id = 0;

    public downCommand = '';

    public downArgs = '';

    public upCommand = '';

    public upArgs = '';

    //#endregion

    //#region Constructor

    constructor(id: number, downCommand: string, downArgs: string, upCommand: string, upArgs: string) {
        this.id = id;
        this.downCommand = downCommand;
        this.downArgs = downArgs;
        this.upCommand = upCommand;
        this.upArgs = upArgs;
    }

    //#endregion
}

export class JoystickLocalConfig {
    public joystickId = '';
    public deadZone = 0.05;

    constructor(joystickId: string, deadZone: number) {
        this.joystickId = joystickId;
        this.deadZone = deadZone;
    }
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class JoystickService {
    //#region Constants

    public static maxButtons = 10;
    public static pollingTime = 100; // ms

    //#endregion

    //#region Fields

    // determine if the browser supports gamepad
    public isSupported = false;
    public deadZone = 0.05;

    private buttons: JoystickButtonConfig[] = [];

    // flag used to enable logs for more diagnostics
    private enableLog = false;

    private debugMode = false;

    private onDestroy: Subject<string> = new Subject();
    //#endregion

    //#region Constructor

    constructor(
        private zone: NgZone,
        private loggerService: LoggerService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: UserSettingsService,
        @Inject(COMMANDS_SERVICE) public commandsService: CommandsService,
        private gamepadEventListenerService: GamepadEventListenerService
    ) {
        this.userSettingsService.settings$.pipe(untilDestroyed(this)).subscribe(() => {
            this.loadSettings();
        });

        this.gamepadEventListenerService.onNewGamepadEvents$.pipe(untilDestroyed(this)).subscribe((events: GamepadEventData[]) => {
            this.gamepadEventHandler(events);
        });

        this.loadSettings();

        // For debugging purpose
        if (this.debugMode) {
            window.addEventListener('keydown', (event) => this.onWindowKeyDown(event), false);
            window.addEventListener('keyup', (event) => this.onWindowKeyUp(event), false);
        }

        // ensure to register the PTZ commands
        this.commandsService.registerCommandShortcut(SharedCommands.PtzStartPanTilt, '');
        this.commandsService.registerCommandShortcut(SharedCommands.PtzStopPanTilt, '');
    }

    //#endregion

    //#region Methods

    private executeCommand(commandId: IGuid, args?: any, event?: Event) {
        // process commands in Angular's zone
        this.zone.runGuarded(() => {
            this.commandsService.executeCommand(commandId, { type: ContextTypes.Specific, data: args as unknown }, event);
        });
    }

    private loadSettings() {
        const buttons = this.userSettingsService.get<string>(OptionTypes.Peripherals, JoystickLocalConfigIds.Buttons);

        if (buttons !== undefined) {
            this.buttons = JSON.parse(buttons) as JoystickButtonConfig[];
        }
    }

    private gamepadEventHandler(events: GamepadEventData[]) {
        for (const event of events) {
            if (event instanceof ButtonGamepadEventData) {
                const config = this.buttons[event.data.index];
                if (config) {
                    if (event.data.isButtonDown && config.downCommand) {
                        this.executeCommand(SafeGuid.parse(config.downCommand), config.downArgs);
                        this.log(`Executing down command : ${config.downCommand} with ${config.downArgs ?? 'no'} args`);
                    } else if (!event.data.isButtonDown && config.upCommand) {
                        this.executeCommand(SafeGuid.parse(config.upCommand), config.upArgs);
                        this.log(`Executing up command : ${config.upCommand} with ${config.upArgs ?? 'no'} args`);
                    }
                }
            } else if (event instanceof AnalogicGamepadEventData) {
                if (this.commandsService) {
                    if (event.data.velocity) {
                        const joystickEvent = new JoystickCommandData(event.data.velocity);
                        this.executeCommand(SharedCommands.PtzStartPanTilt, joystickEvent);
                    } else {
                        this.executeCommand(SharedCommands.PtzStopPanTilt);
                    }
                }
            } else {
                this.log(`${event.type} has not been implemented in the joystick service gamepadEventHandler method.`);
            }
        }
    }

    private log(msg: string) {
        if (this.enableLog) {
            this.loggerService.traceInformation('JoystickService: ' + msg);
        }
    }

    //#endregion

    //#region Events

    private onWindowKeyDown(event: KeyboardEvent) {
        // exit now if the service is not enabled
        if (!this.debugMode) {
            return;
        }

        let x = 0;
        let y = 0;

        switch (event.key) {
            case 'ArrowLeft':
                x = -1;
                break;
            case 'ArrowRight':
                x = 1;
                break;
            case 'ArrowUp':
                y = 1;
                break;
            case 'ArrowBottom':
                y = -1;
                break;
        }

        if (this.commandsService && (x !== 0 || y !== 0)) {
            const velocity = { x, y };

            // forward the command for routing to the selected tile
            const joystickEvent = new JoystickCommandData(velocity);
            this.executeCommand(SharedCommands.PtzStartPanTilt, joystickEvent);
        }

        // check for buttons press
        const buttonId = parseInt(event.key);
        if (!Number.isNaN(buttonId)) {
            const buttonConfig = this.buttons.find((item) => item.id === buttonId);
            if (buttonConfig) {
                const commandId = SafeGuid.parse(buttonConfig.downCommand);
                if (commandId) {
                    this.executeCommand(commandId, buttonConfig.downArgs, event);
                }
            }
        }
    }

    private onWindowKeyUp(event: KeyboardEvent) {
        // exit now if the service is not enabled
        if (!this.debugMode) {
            return;
        }

        let stop = false;
        switch (event.key) {
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'ArrowUp':
            case 'ArrowBottom':
                stop = true;
                break;
        }

        if (stop && this.commandsService) {
            // forward the command for routing to the selected tile
            this.executeCommand(SharedCommands.PtzStopPanTilt);
        }

        // check for buttons release
        const buttonId = Number.parseInt(event.key);
        if (!Number.isNaN(buttonId)) {
            const buttonConfig = this.buttons.find((item) => item.id === buttonId);
            if (buttonConfig) {
                const commandId = SafeGuid.parse(buttonConfig.upCommand);
                if (commandId) {
                    this.executeCommand(commandId, buttonConfig.upArgs, event);
                }
            }
        }
    }

    //#endregion
}
