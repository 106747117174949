/* eslint-disable no-underscore-dangle */
import { Component, Input } from '@angular/core';
import { ItemSlot, TextFlavor } from '@genetec/gelato';
import { MeltedIcon } from '@genetec/gelato-angular';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { Separator } from '@shared/interfaces/context-menu-item/menu-separator';
/**
 * This component is used to render <gen-menu-item> inside a gen menu. You can either use the ContextMenuFactory to create your popup, or go the declarative way and
 * use <app-context-menu-item></app-context-menu-item>.
 */
@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
})
export class MenuItemsComponent {
    @Input() public dataSource?: ContextMenuItem[];

    public readonly ItemSlot = ItemSlot;
    public readonly TextFlavor = TextFlavor;
    public readonly MeltedIcon = MeltedIcon;
    public readonly Separator = Separator;

    constructor() {}

    public getKeysIn(item: ContextMenuItem): string[] {
        return Object.keys(item);
    }

    /**
     * Attempts to extract the canExecute function of the actionItem. If there is none, defaults to true.
     *
     * @param item MenuItem on which to run the test
     * @returns whether or not the execute function can be called. Defaults to true.
     */
    public canExecute(item: ContextMenuItem): boolean {
        return item.actionItem?.canExecute?.() ?? true;
    }

    /**
     * Greys out the MenuItem if this returns false. Actives the MenuItem otherwise. Defaults to true.
     *
     * @param item MenuItem on which to run the test
     * @returns whether or not the user has sufficient privileges for the execute function to be called. Defaults to true.
     *
     */
    public isAllowed(item: ContextMenuItem): boolean {
        return item.actionItem?.isAllowed?.() ?? true;
    }

    /**
     * If the MenuItem has an execute function defined, AND if it isAllowed and canExecute, then call the execute function.
     *
     * @param item MenuItem on which to call the execute function.
     */
    public execute(item: ContextMenuItem): void {
        if (item.actionItem?.execute) {
            if (this.isAllowed(item) && this.canExecute(item)) {
                item.actionItem.execute(item);
            }
        }
    }
}
