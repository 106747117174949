import { Component, OnDestroy, OnInit } from '@angular/core';
import { Gelato } from '@genetec/gelato-angular';
import { uniqueId } from 'lodash-es';
import { TrackingService } from '../../services/tracking.service';

@Component({
    selector: 'app-tracked-component',
    template: '',
})
export class TrackedComponent extends Gelato implements OnInit, OnDestroy {
    public readonly componentId!: number | string;

    protected name!: string;

    constructor(protected trackingService: TrackingService) {
        super();
        this.name = this.constructor.name;
        this.componentId = uniqueId();
    }

    public ngOnInit(): void {
        this.trackingService.addActiveComponent(this.name);
    }

    public ngOnDestroy(): void {
        this.trackingService.removeActiveComponent(this.name);
    }

    protected async logAction(actionDescription: string): Promise<void> {
        await this.trackingService.logActionAsync({ name: actionDescription, originComponent: this.name });
    }
}
