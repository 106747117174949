// Autogenerated: v2/help/Entities/ApiCardholder?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:04 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity, EntityFields } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class CardholderEntityFields extends EntityFields {
    public static accessPermissionLevelField = 'AccessPermissionLevel';
    public static accessStatusField = 'AccessStatus';
    public static activationDateField = 'ActivationDate';
    public static antipassbackExemptionField = 'AntipassbackExemption';
    public static antipassbackExemptionIsInheritedField = 'AntipassbackExemptionIsInherited';
    public static canEscortField = 'CanEscort';
    public static emailAddressField = 'EmailAddress';
    public static expirationDateField = 'ExpirationDate';
    public static expirationDurationField = 'ExpirationDuration';
    public static expirationModeField = 'ExpirationMode';
    public static firstNameField = 'FirstName';
    public static hasPictureField = 'HasPicture';
    public static inheritAccessPermissionLevelFromGroupField = 'InheritAccessPermissionLevelFromGroup';
    public static isVisitorField = 'IsVisitor';
    public static lastAccessField = 'LastAccess';
    public static lastAccessGrantedField = 'LastAccessGranted';
    public static lastAccessLocationField = 'LastAccessLocation';
    public static lastNameField = 'LastName';
    public static mobilePhoneNumberField = 'MobilePhoneNumber';
    public static pictureIdField = 'PictureId';
    public static useExtendedGrantTimeField = 'UseExtendedGrantTime';
}

// #endregion Fields

// #region Inner classes

export interface ICardholderPicture extends IFieldObject {
    guid: IGuid;
    data: string;
}

// #endregion Inner classes

export interface ICardholderEntity extends IEntity {
    // #region REST Properties

    accessPermissionLevel: number;

    accessStatus: string;

    activationDate: Date;

    antipassbackExemption: boolean;

    antipassbackExemptionIsInherited: boolean;

    canEscort: boolean;

    emailAddress: string;

    expirationDate: Date;

    expirationDuration: number;

    expirationMode: string;

    firstName: string;

    readonly hasPicture: boolean;

    inheritAccessPermissionLevelFromGroup: boolean;

    readonly isVisitor: boolean;

    readonly lastAccess: Date;

    readonly lastAccessGranted: boolean | null;

    readonly lastAccessLocation: IGuid;

    lastName: string;

    mobilePhoneNumber: string;

    readonly pictureId: IGuid;

    useExtendedGrantTime: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getCardholdergroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getCredentialsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    forgiveAntipassbackAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getHostedVisitorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    deletePictureAsync(transaction?: ITransaction | null): Promise<IRestResponse>;
    getPictureAsync(transaction?: ITransaction | null): Promise<ICardholderPicture | null>;
    addPictureAsync(value: ICardholderPicture, transaction?: ITransaction | null): Promise<ICardholderPicture | null>;

    // #endregion REST Methods

}

