import { Component, OnInit } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { IGuid, SafeGuid } from 'safeguid';
import { AlarmContentTypes } from '../../../enumerations/alarm-content-types';

@Component({
    selector: 'app-alarm-event-compact-widget',
    templateUrl: './alarm-event-compact-widget.component.html',
    styleUrls: ['./alarm-event-compact-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: AlarmEventCompactWidgetComponent,
    pluginTypes: [PluginTypes.MapPopupCompact],
    exposure: { id: AlarmEventCompactWidgetComponent.pluginId, priority: 0 },
    isContentSupported: (content: Content) => !!content?.type.equals(AlarmContentTypes.Instance),
})
export class AlarmEventCompactWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('BBD79E59-8BE5-4E60-BCC5-29C61AE82872');

    public dataContext: unknown;
    public alarmName!: string;
    public entityName = 'Unknown';
    public icon: MeltedIcon | null = MeltedIcon.Alarm;
    public content?: Content;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.content) {
            let alarmContent;
            if (this.content.type.equals(AlarmContentTypes.Instance)) {
                alarmContent = this.content;
            }

            if (!alarmContent) {
                alarmContent = this.content.contextsubContents?.find((content) => content.type.equals(AlarmContentTypes.Instance));
            }

            if (alarmContent) {
                this.entityName = alarmContent.title;

                if (alarmContent.parameters) {
                    this.alarmName = alarmContent.parameters.getField('SourceEntityName');
                }
            }
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
