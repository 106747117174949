<div class="overview" *ngIf="readImages$ | async as readImages; else loading">
    <div class="overview__header">
        <gen-icon class="overview__header-icon" [icon]="Icon.Sharp"></gen-icon>
        <gen-text>{{ content?.title }}</gen-text>
    </div>

    <div class="overview__plate">
        <gen-image [src]="readImages.lprImage" class="overview__plate-image"></gen-image>
        <div>
            <h2 class="overview__plate-text">{{ plate }}</h2>
            <h2 class="overview__plate-text">{{ plateState }}</h2>
        </div>
    </div>

    <gen-image [src]="readImages.contextImage"></gen-image>

    <app-read-general-information [plateRead]="readInformation?.plateRead"></app-read-general-information>

    <div class="overview__additional-information">
        <gen-expander [genText]="'STE_LABEL_ADDITIONAL_INFORMATION' | translate">
            <gen-expander-content>
                <div class="overview__additional-information-container">
                    <div *ngFor="let entry of additionalInformation" class="overview__additional-information-container-entry">
                        <gen-text [flavor]="TextFlavor.Label">{{ entry.name | titlecase }}</gen-text>
                        <gen-text>{{ entry.information }}</gen-text>
                    </div>
                </div>
            </gen-expander-content>
        </gen-expander>
    </div>
</div>

<!-- Loading -->
<ng-template #loading>
    <div class="ha-center va-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
