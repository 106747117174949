import { AfterViewInit, Component, ComponentRef, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonFlavor, Color, GenMenu, TableCellType, BannerFlavor } from '@genetec/gelato-angular';
import { TextFlavor, Icon } from '@genetec/gelato';
import {
    CardholderManagementClient,
    FilterDescriptor,
    ReportDescriptor,
    SearchRequest,
    ReportTableColumnDescriptor,
    ReportResult,
    ReportExtendedSearchRequest,
    ReportTableColumnType,
} from '@modules/access-control/api/api';
import { AccessControlFeatureFlags } from '@modules/access-control/feature-flags';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { ContextMenuItem } from '@modules/shared/interfaces/context-menu-item/context-menu-item';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginComponentExposure } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { Datum } from '@modules/shared/interfaces/datum';
import { FilterContext, FiltersMap } from '@modules/shared/services/filters/filter';
import { FilterCoordinatorService, FILTER_CONTEXT } from '@modules/shared/services/filters/filter-coordinator-service';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { FiltersContext } from '@modules/shared/store/filters.context';
import { tryParseJson } from '@modules/shared/utilities/serialization.helper';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, take } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { FeatureFlagGroup } from '@modules/feature-flags/feature-flag';
import { MethodEmitter, StateEmitter } from '@src/app/store';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { StateObservable } from '@src/app/store/decorators/state-observable.decorator';
import { Select } from '@ngxs/store';
import { CardholderEditContextState } from '@modules/access-control/cardholder-edit-context.state';
import { FiltersState } from '@modules/shared/store/filters.state';
import { CardholderEditService } from '@modules/access-control/services/cardholder-edit-service';
import { CommandBindings, CommandsService, COMMANDS_SERVICE, CommandsSubscription } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { AccessControlCommands } from '@modules/access-control/enumerations';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { PrivilegeService } from '@modules/shared/privilege/privilege.service';
import { SortOptions } from '@modules/shared/components/table/models/sort-options';
import { TableComponent } from '@shared/components/table/table.component';
import { TableColumnDescriptor } from '@shared/components/table/models/table-column-descriptor';
import { isValidDate } from '@src/app/utilities/date-validator';
import { CardholderDetailsPaneComponent } from '../cardholder-details-pane/cardholder-details-pane.component';
@UntilDestroy()
@Component({
    selector: 'app-cardholder-sub-task',
    templateUrl: './cardholder-sub-task.component.html',
    styleUrls: ['./cardholder-sub-task.component.scss'],
    providers: [{ provide: FilterCoordinatorService }, { provide: FILTER_CONTEXT, useValue: FilterContext.CardholderList }],
})
@InternalPluginDescriptor({
    type: CardholderSubTaskComponent,
    pluginTypes: [PluginTypes.AccessControlSubTask],
    exposure: {
        id: CardholderSubTaskComponent.pluginId,
        icon: 'gen-ico-cardholder',
        title: 'STE_LABEL_CARDHOLDERS',
        tags: ['access control', 'cardholders', 'STE_TASK_ACCESS_CONTROL', 'STE_LABEL_CARDHOLDERS'],
        priority: 50,
    } as PluginComponentExposure,
    requirements: {
        features: [KnownFeatures.accessControlId],
        licenses: [KnownLicenses.accessControl],
        globalPrivileges: [KnownPrivileges.accessControlTaskPrivilege, KnownPrivileges.viewCardholdersPrivilege],
        enabledFeatureFlags: [AccessControlFeatureFlags.Cardholders],
    },
    isSupported: () => true,
})
export class CardholderSubTaskComponent extends TrackedComponent implements OnInit, OnDestroy, AfterViewInit {
    public static pluginId = SafeGuid.parse('A449ACC3-A6ED-493D-A604-B8435EC8F611');
    private static pageSize = 50;

    @ViewChild(TableComponent) public tableComponent?: TableComponent;
    @ViewChild('moreMenu') public moreMenu?: GenMenu;
    @ViewChild('detailsPane', { read: ElementRef }) cardholderDetailsPaneComponent!: ElementRef;
    @ViewChild('sidePaneDetailsContainer', { read: ViewContainerRef }) sidePaneViewContainerRef!: ViewContainerRef;
    @ViewChild('fullscreenDetailsContainer', { read: ViewContainerRef }) fullScreenViewContainerRef!: ViewContainerRef;

    @ViewSelectSnapshot(FeatureFlagsState.featureFlags(AccessControlFeatureFlags.AddCardholders))
    public isAddFeatureFlagEnabled!: boolean;

    @ViewSelectSnapshot(FeatureFlagsState.featureFlagGroups(AccessControlFeatureFlags))
    public featureFlags!: FeatureFlagGroup<typeof AccessControlFeatureFlags>;

    @Select(CardholderEditContextState.cardholderId)
    public selectedCardholderId$!: StateObservable<typeof CardholderEditContextState.cardholderId>;

    @ViewSelectSnapshot(CardholderEditContextState.cardholderId)
    public selectedCardholderId!: ReturnType<typeof CardholderEditContextState.cardholderId>;

    @MethodEmitter(CardholderEditContextState.setCardholderIdAndPicture)
    public setCardholderIdAndPicture!: StateEmitter<typeof CardholderEditContextState.setCardholderIdAndPicture>;

    @MethodEmitter(CardholderEditContextState.toggleSideBySide)
    public toggleSideBySide!: StateEmitter<typeof CardholderEditContextState.toggleSideBySide>;

    @MethodEmitter(CardholderEditContextState.setFullScreen)
    public setFullScreen!: StateEmitter<typeof CardholderEditContextState.setFullScreen>;

    @MethodEmitter(CardholderEditContextState.setAddMode)
    public setAddMode!: StateEmitter<typeof CardholderEditContextState.setAddMode>;

    @MethodEmitter(CardholderEditContextState.setEditMode)
    public setEditMode!: StateEmitter<typeof CardholderEditContextState.setEditMode>;

    @MethodEmitter(CardholderEditContextState.setNewCardholder)
    public setNewCardholder!: StateEmitter<typeof CardholderEditContextState.setNewCardholder>;

    @Select(CardholderEditContextState.isSideBySide)
    public isSideBySide$!: StateObservable<typeof CardholderEditContextState.isSideBySide>;

    @ViewSelectSnapshot(CardholderEditContextState.isSideBySide)
    public isSideBySide!: ReturnType<typeof CardholderEditContextState.isSideBySide>;

    @ViewSelectSnapshot(CardholderEditContextState.isAddMode)
    public isAddMode!: ReturnType<typeof CardholderEditContextState.isAddMode>;

    @SelectSnapshot(FiltersState.filters)
    private filters!: ReturnType<typeof FiltersState.filters>;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Colors = Color;
    public readonly TextFlavor = TextFlavor;
    public readonly Icon = Icon;

    public reportData$: Observable<Datum[] | null>;
    public hasMore$: Observable<boolean>;
    /**
     * Stores all the results shown in the DOM.
     * If we want to update the DOM list, consider using reportDataSubject.
     */
    public allResults: Datum[] = [];
    public refreshing$: Observable<boolean>;
    public isCardView$: Observable<boolean>;
    public reportDescriptor?: ReportDescriptor | null;
    public reportItemCount$!: Observable<number>;
    public isMoreMenuOpen = false;
    public isDetailsPaneOpened = false;
    public moreMenuItemSource: ContextMenuItem[] = [];
    public isBannerShowing = false;
    public errorText = '';
    public bannerFlavor = BannerFlavor.Error;
    public hasAddPrivilege = false;
    public currentPageNumber = 0;

    public set selectedCardholder(value: string | null) {
        this.cardholder = value;
        if (this.cardholder) {
            this.isDetailsPaneOpened = true;
        }
    }
    public get selectedCardholder(): string | null {
        return this.cardholder;
    }

    public get reportColumnDescriptors(): ReportTableColumnDescriptor[] | null {
        return this.reportDescriptor?.columns ?? null;
    }

    public get reportFilters(): FilterDescriptor[] | null {
        return this.reportDescriptor?.filters ?? null;
    }

    private cardholder: string | null = null;
    private cardholderEditPaneRef: ComponentRef<CardholderDetailsPaneComponent>;
    /**
     * Subject that will update the subscribers when called next on it.
     * If we want to modify the states of the table rows without calling next,
     * consider using allResults.
     */
    private reportDataSubject = new BehaviorSubject<Datum[] | null>(null);
    private isCardViewSubject = new BehaviorSubject<boolean>(false);
    private hasMoreSubject = new BehaviorSubject<boolean>(true);
    private escapeCommandSubscription: CommandsSubscription;
    private refreshingSubject = new BehaviorSubject<boolean>(false);
    private searchSubscription?: Subscription;
    private currentSort?: SortOptions;

    constructor(
        trackingService: TrackingService,
        private cardholderManagementClient: CardholderManagementClient,
        private timeService: TimeService,
        private sanitizer: DomSanitizer,
        private translateService: TranslateService,
        private cardholderEditService: CardholderEditService,
        private viewContainerRef: ViewContainerRef,
        private privilegeService: PrivilegeService,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        public reportFiltersService: FiltersContext
    ) {
        super(trackingService);

        this.hasMore$ = this.hasMoreSubject.asObservable();
        this.refreshing$ = this.refreshingSubject.asObservable();
        this.reportData$ = this.reportDataSubject.asObservable();
        this.isCardView$ = this.isCardViewSubject.asObservable();

        this.reportItemCount$ = this.reportDataSubject.pipe(map((data) => data?.length ?? 0));

        this.commandsService.registerCommandShortcut(AccessControlCommands.ExitFullscreen, 'Esc');
        const commandBindings = new CommandBindings();
        commandBindings.addCommand({
            commandId: AccessControlCommands.ExitFullscreen,
            executeCommandHandler: (executeCommandData) => {
                this.toggleSideBySide();
                executeCommandData.isHandled = true;
            },
        });
        this.escapeCommandSubscription = this.commandsService.subscribe(commandBindings, { priority: 200 });

        this.subscribeToRefreshRequests();
        this.cardholderEditPaneRef = this.viewContainerRef.createComponent(CardholderDetailsPaneComponent);
    }

    ngOnInit() {
        this.initializePrivileges().fireAndForget();
        this.initializeMoreMenuItemSource();
        this.setReportDescriptor();
    }

    ngAfterViewInit() {
        this.isSideBySide$.pipe(untilDestroyed(this)).subscribe((isSideBySide) => {
            const cardholderDetailsPaneViewRef = this.cardholderEditPaneRef?.hostView;
            if (!cardholderDetailsPaneViewRef) {
                return;
            }

            if (isSideBySide) {
                this.sidePaneViewContainerRef.move(cardholderDetailsPaneViewRef, 0);
            } else {
                this.fullScreenViewContainerRef.move(cardholderDetailsPaneViewRef, 0);
            }
        });
    }

    ngOnDestroy(): void {
        this.escapeCommandSubscription.unsubscribe();
    }

    public async initializePrivileges(): Promise<void> {
        const partitions = await this.privilegeService.getGrantedPrivilegedPartitionsAsync(KnownPrivileges.addCardholdersPrivilege);
        if (partitions === null || partitions.length === 0) {
            this.hasAddPrivilege = false;
            return;
        }

        this.hasAddPrivilege = true;
    }

    // Used for loading the initial batch, since we don`t want the loading spinner
    // over the table when loading additionnal pages (the table has its own at the bottom)
    public refreshCardholders(): void {
        this.refreshingSubject.next(true);
        this.resetSearch();
        this.loadMoreCardholders();
    }

    public loadMoreCardholders(): void {
        const searchRequest = new SearchRequest({
            page: ++this.currentPageNumber,
            pageSize: CardholderSubTaskComponent.pageSize,
            sourceTimeZone: this.timeService.getBrowserTimezone(),
            filters: this.modifyFilters(this.filters),
        });

        if (this.currentSort) {
            searchRequest.sortBy = this.currentSort.columnKey;
            searchRequest.sortOrder = this.currentSort.sortOrder;
        }

        this.searchSubscription?.unsubscribe();

        this.searchSubscription = this.cardholderManagementClient
            .search(searchRequest)
            .pipe(take(1), untilDestroyed(this))
            .subscribe((result: ReportResult) => this.onSearchResultsReceived(result));
    }

    public setListViewMode(): void {
        this.isCardViewSubject.next(false);
    }

    public setCardViewMode(): void {
        this.isCardViewSubject.next(true);
    }

    public async toggleMoreMenu(): Promise<void> {
        if (this.moreMenu) {
            await this.moreMenu.toggle();
            this.isMoreMenuOpen = !this.isMoreMenuOpen;
        }
    }

    public onCardholderSelected(cardholder: Datum): void {
        if (!this.featureFlags.CardholdersEditPane) {
            return;
        }
        this.askForFormCancelIfDirty(() => {
            if (isObject(cardholder) && typeof cardholder.guid === 'string') {
                const picture = typeof cardholder.picture === 'string' ? cardholder.picture : undefined;
                this.setCardholderIdAndPicture({ id: cardholder.guid, picture });
            }
        });
    }

    public onAddCardholderButtonClicked(): void {
        this.askForFormCancelIfDirty(() => {
            this.setAddMode();
            this.setNewCardholder();
        });
    }

    public onSortChanged(sortOption: SortOptions): void {
        this.currentSort = sortOption;
        this.refreshCardholders();
    }

    private askForFormCancelIfDirty(action: () => void): void {
        if (this.cardholderEditPaneRef.instance.isFormDirty) {
            this.cardholderEditPaneRef.instance
                .askForCancel()
                .pipe(untilDestroyed(this))
                .subscribe((shouldCancel) => {
                    if (!shouldCancel) {
                        action();
                    }
                });
        } else {
            action();
        }
    }

    private showColumnSelection(): void {
        this.tableComponent?.showColumnSelection();
    }

    private subscribeToRefreshRequests(): void {
        this.cardholderEditService.refreshCardholder$.pipe(untilDestroyed(this)).subscribe(() => {
            this.refreshCardholders();
        });
    }

    private onSearchResultsReceived(results: ReportResult): void {
        const newData = this.getReportData(results.reportItems);
        this.allResults = this.allResults.concat(newData);

        this.reportDataSubject.next(this.allResults);

        this.fetchExtendedProperties((this.currentPageNumber - 1) * CardholderSubTaskComponent.pageSize);

        if (results.error) {
            this.showError(results.error, results.errorSeverity ?? BannerFlavor.Error);
        } else {
            this.removeError();
        }

        this.refreshingSubject.next(false);

        if (results.lastResult) {
            this.hasMoreSubject.next(false);
        }
    }

    private showError(errorText: string, errorSeverity: string) {
        this.isBannerShowing = true;
        this.errorText = errorText;
        this.bannerFlavor = errorSeverity.toLowerCase() === BannerFlavor.Warning ? BannerFlavor.Warning : BannerFlavor.Error;
    }

    private removeError() {
        this.isBannerShowing = false;
        this.errorText = '';
    }

    // Orchestrate the loading of the properties marked as extended
    private fetchExtendedProperties(currentRowIndex: number) {
        const extendedProperties = this.getExtendedProperties();

        if (!extendedProperties.length || !this.reportDescriptor) return;

        const keyColumns = this.getKeyColumns();
        const newResults = this.allResults.slice(currentRowIndex, currentRowIndex + CardholderSubTaskComponent.pageSize);
        const dataKeyColumns = this.getExtendedSearchQueryParams(newResults, keyColumns);

        extendedProperties.forEach((propertyDescriptor) => {
            this.cardholderManagementClient
                .getExtendedCardholdersInformation(
                    new ReportExtendedSearchRequest({
                        extendedFieldId: propertyDescriptor.fieldId,
                        keys: dataKeyColumns,
                    })
                )
                .pipe(take(1), untilDestroyed(this))
                .subscribe((extendedInfoResults) => {
                    newResults.forEach((item, index) => {
                        const extendedFieldValue: unknown = extendedInfoResults.reportItems[index][extendedInfoResults.extendedFieldId];
                        if (extendedFieldValue) {
                            item[extendedInfoResults.extendedFieldId] = tryParseJson(extendedFieldValue) ?? extendedFieldValue;
                        }
                    });

                    this.reportDataSubject.next([...this.allResults]); // Update the report with the new extended data
                });
        });
    }

    // Get the columns that need to be loaded separatly from the report
    private getExtendedProperties(): TableColumnDescriptor[] {
        if (!this.reportDescriptor) return [];
        return this.reportDescriptor.columns.filter((x) => x.isExtendedProperty);
    }

    // Build a query string based on the reportItems and the columns to use
    private getExtendedSearchQueryParams(reportItems: Datum[], extendedColumns: TableColumnDescriptor[]): Record<string, unknown>[] {
        const extendedSearchQueryParams: Record<string, unknown>[] = [];
        reportItems.forEach((result: Datum) => {
            const field: Record<string, unknown> = {};
            extendedColumns.forEach((column) => {
                field[column.fieldId] = result[column.fieldId];
            });
            extendedSearchQueryParams.push(field);
        });
        return extendedSearchQueryParams;
    }

    // Get the columns descriptors that are used to identify uniquely a report record
    private getKeyColumns(): TableColumnDescriptor[] {
        if (!this.reportColumnDescriptors) return [];
        return this.reportColumnDescriptors.filter((column) => column.isKey);
    }

    private getReportData(reportItems: Datum[]): Datum[] {
        const reportData: Datum[] = [];
        const keys = this.reportColumnDescriptors?.filter((column) => column.isKey);
        const currentData = this.allResults;
        if (keys) {
            reportItems.forEach((reportItem) => {
                const rowData: Datum = this.buildTableRowForReportItem(reportItem);

                if (!this.isRowDataDuplicate(currentData, keys, rowData, reportItem)) {
                    reportData.push(rowData);
                }
            });
        }
        return reportData;
    }

    private setReportDescriptor(): void {
        this.cardholderManagementClient
            .getReportDescriptor()
            .pipe(untilDestroyed(this))
            .subscribe((reportDescriptor: ReportDescriptor) => {
                this.reportDescriptor = reportDescriptor;
                this.onReportDescriptionInitialized();
            });
    }

    private onReportDescriptionInitialized(): void {
        this.reportFiltersService.filters$
            .pipe(
                skip(1),
                debounceTime(10),
                distinctUntilChanged((previous: FiltersMap, current: FiltersMap) => isEqual(current, previous)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.refreshCardholders();
            });
    }

    private modifyFilters(filters: FiltersMap): Record<string, unknown> {
        const modifiedFilters: Record<string, unknown> = {};
        filters.forEach((f) => {
            modifiedFilters[f.filterId] = f.value;
        });

        return modifiedFilters;
    }

    private buildTableRowForReportItem(reportItem: Datum): Datum {
        const rowData: Datum = {};
        if (this.reportColumnDescriptors) {
            this.reportColumnDescriptors.forEach((column) => {
                const field = this.getFieldFromReportItem(reportItem, column);

                if (column.fieldId === 'picture') {
                    // Set every image as default item before real ones are loaded with extended properties
                    rowData[column.fieldId] = 'Icon.Cardholder';
                } else if (field != null) {
                    if (column.cellType === ReportTableColumnType.Image && typeof field === 'string') {
                        rowData[column.fieldId] = this.sanitizer.bypassSecurityTrustUrl(field);
                    } else {
                        rowData[column.fieldId] = field;
                    }
                }
            });
        }
        return rowData;
    }

    private isRowDataDuplicate(currentData: Datum[], keys: TableColumnDescriptor[], rowData: Datum, reportItem: Datum): boolean {
        let isDuplicated = false;
        if (currentData.length > 0 && keys && keys.length > 0) {
            isDuplicated = true;
            keys?.forEach((key) => {
                if (!isDuplicated) {
                    return;
                }
                if (!(key.fieldId in rowData)) {
                    isDuplicated = false;
                    return;
                }
                isDuplicated = currentData.some((existingRowData) => {
                    return key.fieldId in existingRowData && existingRowData[key.fieldId] === reportItem[key.fieldId];
                });
            });
        }
        return isDuplicated;
    }

    private getFieldFromReportItem(reportItemCell: Record<string, unknown>, column: TableColumnDescriptor): boolean | string | Record<string, unknown> | null {
        const key = column.fieldId;

        if (key in reportItemCell) {
            switch (Number(column.cellType) as TableCellType) {
                case TableCellType.Boolean:
                    return reportItemCell[key] as boolean;
                case TableCellType.Custom: {
                    const cellValue = reportItemCell[key] as string;
                    return tryParseJson(cellValue) ?? cellValue;
                }
                case TableCellType.DateTime: {
                    const cellValue = reportItemCell[key] as string;
                    if (!isValidDate(new Date(cellValue))) {
                        return '';
                    }
                    return cellValue;
                }
                default:
                    return reportItemCell[key] as string;
            }
        }
        return null;
    }

    private initializeMoreMenuItemSource() {
        this.isCardViewSubject.pipe(untilDestroyed(this)).subscribe((isCardView) => {
            this.moreMenuItemSource = [
                // Refresh
                {
                    id: 'refresh',
                    text: this.translateService.instant('STE_BUTTON_REFRESH') as string,
                    actionItem: {
                        execute: () => {
                            this.refreshCardholders();
                        },
                    },
                },
            ];
            if (!isCardView) {
                this.moreMenuItemSource.push(
                    // Select columns
                    {
                        id: 'selectColumns',
                        text: this.translateService.instant('STE_ACTION_SELECTCOLUMNS') as string,
                        actionItem: {
                            execute: () => this.showColumnSelection(),
                        },
                    }
                );
            }
        });
    }

    private resetSearch() {
        this.allResults = [];
        this.currentPageNumber = 0;
        this.hasMoreSubject.next(true);
        this.reportDataSubject.next(null);
    }
}
