import { SafeGuid } from 'safeguid';
import { Constants } from '../utilities/constants';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const FilterTypes = Constants({
    Text: SafeGuid.parse('052207A6-0E49-4808-9F24-B31DCCE4008E'),
    Search: SafeGuid.parse('F60AA3A3-840C-41DD-A041-AC2F31EDDF17'),
    Entities: SafeGuid.parse('F1F492AB-F8C4-42F2-BCCC-6028FECFCD61'),
    Date: SafeGuid.parse('D9DE7474-7A7A-40AA-BD76-D13BE80FED69'),
    Enum: SafeGuid.parse('FED3CFCA-5AA9-4268-99A4-13DC8411283D'),
    TreeView: SafeGuid.parse('26A05F33-EB4C-4D79-8F6E-61E2DE1BDE5C'),
    NumericRange: SafeGuid.parse('28CA4F6D-788C-4E90-B35D-FF0532FA2333'),
});
