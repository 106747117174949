<gen-melted-modal
    [id]="id"
    genTitle="{{ 'STE_LABEL_DEVICESHUNTING' | translate }}"
    [genIsBlocking]="false"
    genAlternativeActionText="{{ 'STE_BUTTON_CLOSE' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_SAVE' | translate }}"
    [genOnDefaultAction]="onShuntStateConfirm"
>
    <gen-melted-modal-body>
        <gen-spinner *ngIf="doorSideAccessPointEntityWithReaders === null">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        <div>
            <div class="readers-container" *ngFor="let device of doorSideAccessPointEntityWithReaders; let i = index" [attr.data-index]="i">
                <div class="reader-name">
                    {{ 'STE_LABEL_FORMAT_READER_X' | translate | stringFormat: [device.name] }}
                </div>
                <label>{{ 'STE_LABEL_DOOR_READER_ENABLED' | translate }}</label>
                <gen-switch [ngModel]="devicesShuntState[i]" (ngModelChange)="onShuntStateChange($event, i)" class="pr-1 pl-1"> </gen-switch>
                <label>{{ 'STE_LABEL_DOOR_READER_SHUNTED' | translate }}</label>
            </div>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
