import { Constants } from '@modules/shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const KnownCellRendererTypes = Constants({
    ColoredDot: '5FCE751E-A947-471B-AC7D-D2AA8EBB06EB',
    Thumbnail: '3D623108-7875-4390-83CF-931A7E16F031',
    ImageWithText: '4521F065-7BBC-47CC-ACBF-9F0D3B4503EC',
    List: '4618BC72-110E-4309-856A-6E38B3D91885',
    StatusWithColor: 'E3E23DD4-0810-4B8A-A505-C823C17D6ACD',
});
