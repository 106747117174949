import { Component } from '@angular/core';
import { ButtonFlavor } from '@genetec/gelato-angular';
import { TextFlavor, Icon } from '@genetec/gelato';
import { TrackedGenModalComponent } from '@modules/shared/components/tracked/tracked-gen-modal.component';

@Component({
    selector: 'app-send-feedback-success',
    templateUrl: './send-feedback-success.component.html',
    styleUrls: ['./send-feedback-success.component.scss'],
})
export class SendFeedbackSuccessComponent extends TrackedGenModalComponent {
    public readonly TextFlavor = TextFlavor;
    public readonly Icon = Icon;
    public readonly ButtonFlavor = ButtonFlavor;

    public onClose = (): Promise<boolean> => {
        this.hide();
        return Promise.resolve(true);
    };
}
