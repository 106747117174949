import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TilesTaskEventService {
    public tilePatternChanged$: Observable<void>;
    public fullscreenChanged$: Observable<void>;

    private tilePatternChangedSubject = new Subject<void>();
    private fullscreenChangedSubject = new Subject<void>();

    constructor() {
        this.tilePatternChanged$ = this.tilePatternChangedSubject.asObservable();
        this.fullscreenChanged$ = this.fullscreenChangedSubject.asObservable();
    }

    /**
     * Emits a new value in the tilePatternChanged$ observable to those who are interested in that event.
     */
    public publishTilePatternChangedEvent(): void {
        this.tilePatternChangedSubject.next();
    }

    /**
     * Emits a new value in the fullscreenChanged$ observable to those who are interested in that event.
     */
    public publishFullscreenChangedEvent(): void {
        this.fullscreenChangedSubject.next();
    }
}
