import { BufferWithAttribute } from './WebGL2/Buffer';
import { Program } from './WebGL2/Program';
import { VertexShader } from './WebGL2/VertexShader';

export class DewarperVertexShader extends VertexShader {
  private static readonly VertexShaderCode: string =
    `
in vec2 a_position;

void main() {
   gl_Position = vec4(a_position, 0, 1);
}`;

  constructor(glContext: WebGL2RenderingContext) {
    super(glContext, DewarperVertexShader.VertexShaderCode);
  }
}

export class PositionBuffer extends BufferWithAttribute {
  constructor(program: Program, name: string) {
    super(program, name);
    this.bind();
    this.gl.vertexAttribPointer(this.m_attribute.Location, 2, this.gl.FLOAT, false, 0, 0);
  }

  public setValue(values: Float32Array) {
    this.bind();
    this.gl.bufferData(this.gl.ARRAY_BUFFER, values, this.gl.STATIC_DRAW);
  }
}
