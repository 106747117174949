import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonFlavor, Icon, TextFlavor } from '@genetec/gelato';
import { Marker, MapObject, Polyline } from '@genetec/web-maps';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent, PluginComponentExposure } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { NavigationService } from '@modules/shared/services/navigation/navigation.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { IWebMapObject } from '../../controllers/map.controller.data';
import { MapContentTypes } from '../../enumerations/maps-content-types';
import { MapService } from '../../services/map/map-service';
import { MapsTaskComponent } from '../maps-task/maps-task.component';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

const isContentSupported = (content: Content): boolean => {
    return !!(content?.contextContent && content.type.equals(MapContentTypes.Map));
};

@UntilDestroy()
@Component({
    selector: 'app-maps-widget',
    templateUrl: './maps-widget.component.html',
    styleUrls: ['./maps-widget.component.scss'],
    providers: [MapService],
})
@InternalContentPluginDescriptor({
    type: MapsWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: MapsWidgetComponent.pluginId, priority: 2.5 } as PluginComponentExposure,
    isContentSupported,
    requirements: {
        features: [KnownFeatures.mapsId],
        licenses: [KnownLicenses.maps],
        globalPrivileges: [KnownPrivileges.mapMonitoringTaskPrivilege],
    },
})
export class MapsWidgetComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('0B1A00AB-DEE2-42FD-BB54-30C559560051');

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;

    public content?: Content;
    public dataContext: unknown;

    public canNavigate = false;

    private mapObject: MapObject | undefined;

    constructor(public mapService: MapService, private navigationService: NavigationService, trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        setTimeout(async () => {
            if (this.content) {
                await this.mapService.initializeMap({
                    mapId: SafeGuid.parse(this.content.source),
                    withEvents: false,
                });
                this.mapService.onMapTypesLoaded$.pipe(untilDestroyed(this)).subscribe(() => this.onMapTypesLoaded());
            }
        }, 300); // wait until side pane aimation finishes... else the leaflet map doesn't paint appropriately.
    }

    ngOnDestroy() {
        this.mapService.destroy();
        super.ngOnDestroy();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public async onOpenMap(): Promise<void> {
        if (this.mapObject && this.content) {
            const navigation = `/task/${MapsTaskComponent.pluginId.toString()}/?id=${this.content.source}&mapObjectId=${this.mapObject.id.toString()}`;
            const contextService = this.dataContext as ContentService;
            await this.navigationService.navigate(navigation);
            contextService.clearMainContent();
        }
    }

    private async onMapTypesLoaded() {
        let mapObjects!: IWebMapObject[];
        const params = this.content?.parameters;
        if (this.mapService.currentMapEntity) {
            if (params?.hasField('mapobjectids')) {
                const mapObjectIds = params?.getFieldArrayGuid('mapobjectids');
                if (mapObjectIds) {
                    mapObjects = await this.mapService.getMapObjectsFromIds(SafeGuid.createSet(Array.from(mapObjectIds)));
                }
            } else if (params?.hasField('mapobjects')) {
                mapObjects = params?.getField<Array<IWebMapObject>>('mapobjects').map((item) => item);
            }

            if (mapObjects?.length > 0) {
                // register notification filters only for states
                const distinct: IGuid[] = [];
                const types = mapObjects.map((item) => SafeGuid.parse(item.type.toString()));
                types.forEach((typeId) => {
                    if (distinct.every((item) => !item.equals(typeId))) {
                        distinct.push(typeId);
                    }
                });
                await this.mapService.updateNotificationFilterFromTypes(distinct);

                // insert them
                this.mapService.insertMapObjects(mapObjects, true);
                const ids = Array.from(mapObjects).map((item) => SafeGuid.from(item.guid));
                this.fitMoViews(ids);
                this.canNavigate = true;
            }
        }
    }

    private fitMoViews(ids: Iterable<IGuid> | undefined) {
        if (ids && this.mapService.map?.isMapInitialized()) {
            const idArray = Array.from(ids);
            if (idArray.length > 0) {
                for (const mapObjectId of idArray) {
                    if (mapObjectId) {
                        const view = this.mapService.map.getMapObjectView(mapObjectId.toTypescriptGuid());
                        this.mapObject = view?.data;

                        if (view instanceof Marker) {
                            const offset = 0.002;
                            this.mapService.map.fitBounds([
                                [view.getLatLng().lat + offset, view.getLatLng().lng - offset],
                                [view.getLatLng().lat - offset, view.getLatLng().lng + offset],
                            ]);
                            break;
                        } else if (view instanceof Polyline) {
                            this.mapService.map.fitBounds(view.getBounds().pad(0.25));
                            break;
                        }
                    }
                }
            }
        }
    }
}
