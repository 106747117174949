import { ErrorCode, StreamingConnectionStatus, PlayerState, TimelineEventKind, PlayerMode } from './enums';

/** Notification of a processing error within the player.
 * @public */
export class ErrorStatusEvent {
  /** Error code for the "Error" player state */
  public readonly errorCode: ErrorCode;
  /** Textual description of the error - not localized */
  public readonly value: string;
  /** Specifies whether the error is severe enough to stop the player */
  public get isFatal(): boolean {
    return this.errorCode === ErrorCode.UpdateSessionError;
  }
  /** @internal */
  constructor(errorCode: ErrorCode, details: string) {
    this.errorCode = errorCode;
    this.value = details;
  }
}

/**
 * Notification that the connection status of the player has changed.
 * Refer to the {@link StreamingConnectionStatus} enum for a description of the statuses.
 * @public */
export class StreamingConnectionStatusChangeEvent {
  /** New streaming connection status the player changed to */
  public readonly state: StreamingConnectionStatus;
  /** Textual description of the streaming connection status - not localized */
  public readonly value: string;
  /** @internal */
  constructor(streamStatus: StreamingConnectionStatus, details: string) {
    this.state = streamStatus;
    this.value = details;
  }
}

/**
 * Notification that the player state has changed.
 * Refer to the {@link PlayerState} enum for a description of the different states.
 * @public */
export class PlayerStateChangeEvent {
  /** New state the player changed to */
  public readonly playerState: PlayerState;
  /** Textual description of the player state - not localized */
  public readonly value: string;
  /** @internal */
  constructor(playerState: PlayerState, details: string) {
    this.playerState = playerState;
    this.value = details;
  }
}

/**
 * Notification that the play speed of the player has changed.
 * This might happen when the player processes a play speed command, or when the player update its play speed on its own.
 * @public */
export class PlaySpeedChangeEvent {
  /** New current playspeed */
  public readonly playSpeed: number;
  /** @internal */
  constructor(speed: number) {
    this.playSpeed = speed;
  }
}

/** Notification that a command to enable or disable the audio was processed.
 * @public */
export class AudioStateChangeEvent {
  /** If true, the player will request and play audio (if available) */
  public readonly isAudioEnabled: boolean;
  /** @internal */
  constructor(isAudioEnabled: boolean) {
    this.isAudioEnabled = isAudioEnabled;
  }
}

/** Notification that the audio availability has changed. The audio might have become unavailable, such as a camera with no microphone in live.
 * @public */
export class AudioAvailabilityChangeEvent {
  /** If true, the camera supports audio streaming */
  public readonly isAudioAvailable: boolean;
  /** @internal */
  constructor(isAudioAvailable: boolean) {
    this.isAudioAvailable = isAudioAvailable;
  }
}

/** Notification that the player transitionned from live to playback, or vice versa.
 * @public */
export class PlayerModeChangeEvent {
  /** The new player mode (live or playback) */
  public readonly playerMode: PlayerMode;
  /** @internal */
  constructor(playerMode: PlayerMode) {
    this.playerMode = playerMode;
  }
}

/** Notification of an update to the timeline coverage. This will contain a coverage range and all events included in that range.
 * Coverage range can be smaller than "range of interest". Anything outised the coverage range should be considered untouched.
 * @public */
export class TimelineContentEvent {
  /** Start time for the coverage of this timeline update */
  public readonly coverageStart: Date;
  /** End time for the coverage of this timeline update */
  public readonly coverageEnd: Date;
  /** Array of timeline events impacting the current coverage range */
  public readonly events: TimelineEvent[];
  /** @internal */
  constructor(coverageStart: Date, coverageEnd: Date, events: TimelineEvent[]) {
    this.coverageStart = coverageStart;
    this.coverageEnd = coverageEnd;
    this.events = events;
  }
}

/** A single timeline event.
 * @public */
export class TimelineEvent {
  /** Time at which the event occured or stated, if a duration is specified */
  public readonly time: Date;
  /** Duration of the event in seconds. If the duration is not specified, the event represent only a point in time. */
  public readonly duration: number | undefined;
  /** User provided details for this event. Optional, when it is present it is meant to help distinguish the event. */
  public readonly details: string | undefined;
  /** The type of event */
  public readonly kind: TimelineEventKind;
  /** @internal */
  constructor(time: Date, kind: TimelineEventKind, duration?: number, details?: string) {
    this.time = time;
    this.kind = kind;
    this.duration = duration;
    this.details = details;
  }
}
