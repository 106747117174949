// Autogenerated: CodeGen/EntityBrowserItemModel?language=ts&InterfaceOnly=False on 2021-09-13 2:38:04 PM
import { IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { ModelObject } from '../../models/model-object';
import { IEntityBrowserItemModel } from '../interfaces/items/entity-browser-item-model.interface';
import { IEntityTypeData, EntityTypeData } from '../../models/entity-type-data';

export class EntityBrowserItemModel extends ModelObject implements IEntityBrowserItemModel {
    // CanHaveChildren
    public get canHaveChildren(): boolean {
        return this.getField<boolean>('CanHaveChildren');
    }
    public set canHaveChildren(value: boolean) {
        this.setField<boolean>('CanHaveChildren', value);
    }
    // CustomIconId
    public get customIconId(): IGuid {
        return this.getFieldGuid('CustomIconId');
    }
    public set customIconId(value: IGuid) {
        this.setFieldGuid('CustomIconId', value);
    }
    // EntityType
    public get entityType(): IEntityTypeData {
        return this.getFieldObject<EntityTypeData, IEntityTypeData>(EntityTypeData, 'EntityType');
    }
    public set entityType(value: IEntityTypeData) {
        this.setFieldObject<IEntityTypeData>('EntityType', value);
    }
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // LogicalId
    public get logicalId(): number | null {
        return this.getNullableField<number>('LogicalId');
    }
    public set logicalId(value: number | null) {
        this.setNullableField<number>('LogicalId', value);
    }
    // Maintenance
    public get maintenance(): boolean {
        return this.getField<boolean>('Maintenance');
    }
    public set maintenance(value: boolean) {
        this.setField<boolean>('Maintenance', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // OwnerMasterRole
    public get ownerMasterRole(): IGuid {
        return this.getFieldGuid('OwnerMasterRole');
    }
    public set ownerMasterRole(value: IGuid) {
        this.setFieldGuid('OwnerMasterRole', value);
    }
    // OwnerMasterRoleTypeId
    public get ownerMasterRoleTypeId(): IGuid {
        return this.getFieldGuid('OwnerMasterRoleTypeId');
    }
    public set ownerMasterRoleTypeId(value: IGuid) {
        this.setFieldGuid('OwnerMasterRoleTypeId', value);
    }
    // Parents
    public get parents(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('Parents');
    }
    public set parents(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('Parents', value);
    }
    // RunningState
    public get runningState(): string {
        return this.getField<string>('RunningState');
    }
    public set runningState(value: string) {
        this.setField<string>('RunningState', value);
    }
    // SortPriority
    public get sortPriority(): number {
        return this.getField<number>('SortPriority');
    }
    public set sortPriority(value: number) {
        this.setField<number>('SortPriority', value);
    }
    // Synchronised
    public get synchronised(): boolean {
        return this.getField<boolean>('Synchronised');
    }
    public set synchronised(value: boolean) {
        this.setField<boolean>('Synchronised', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<boolean>('CanHaveChildren', false);
        this.setFieldGuid('CustomIconId', SafeGuid.EMPTY);
        const objEntityType = new EntityTypeData();
        objEntityType.initializeAllFields();
        this.setFieldObject('EntityType', objEntityType);
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<number>('LogicalId', 0);
        this.setField<boolean>('Maintenance', false);
        this.setField<string>('Name', '');
        this.setFieldGuid('OwnerMasterRole', SafeGuid.EMPTY);
        this.setFieldGuid('OwnerMasterRoleTypeId', SafeGuid.EMPTY);
        this.setFieldArrayGuid('Parents', new ObservableCollection<IGuid>());
        this.setField<string>('RunningState', '');
        this.setField<number>('SortPriority', 0);
        this.setField<boolean>('Synchronised', false);
    }
}
