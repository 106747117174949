<div #mainDiv *ngIf="!isLoading" class="control-buttons" (click)="onClicked($event)">
    <ng-container *ngIf="displayedCommands.length > 0">
        <ng-container *ngFor="let command of displayedCommands">
            <span class="tooltip d-inline button ml-0">
                <gen-button
                    [ngClass]="{ 'button-spaced': areButtonsSpaced, 'button-non-spaced': !areButtonsSpaced }"
                    [flavor]="mainButtonFlavor"
                    [disabled]="!command.canExecute"
                    [appTooltip]="getTooltip(command)"
                    (click)="command.execute()"
                >
                    <gen-icon slot="gen-icon" [icon]="command.icon" [genColor]="command.color()"></gen-icon>
                    {{ getName(command) }}
                </gen-button>
            </span>
        </ng-container>
    </ng-container>

    <span class="d-inline button ml-0" *ngIf="additionnalCommands.length > 0">
        <gen-button
            id="command-buttons-show-more-button-{{ componentId }}"
            class="button"
            [ngClass]="{ 'button-spaced': areButtonsSpaced, 'button-non-spaced': !areButtonsSpaced }"
            [flavor]="ButtonFlavor.Flat"
            (click)="toggleMoreMenu()"
            [appTooltip]="'STE_BUTTON_SHOWMORE' | translate"
        >
            <gen-icon [icon]="Icon.EllipsisVertical"></gen-icon>
        </gen-button>
    </span>

    <gen-menu
        [position]="MenuPosition.BottomRight"
        targetSelector="#command-buttons-show-more-button-{{ componentId }}"
        #moreMenu
        [appMenuDataSource]="moreMenuItemSource"
    ></gen-menu>
</div>
