// Autogenerated: v2/help/Classes/ApiEntityPageQuery?codegen=ts&InterfaceOnly=False&workflow=False on 2022-12-21 2:23:57 PM
import { FieldList } from '../../Helpers/FieldList';
import { BaseQuery } from './BaseQuery';
import { IGuid, GuidSet } from 'safeguid';
import { IEntityQuery } from '../Interface/IEntityQuery';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

export class EntityQuery extends BaseQuery implements IEntityQuery {
    // #region REST Properties

    public customEntityTypes = new GuidSet();
    public customFieldValues: string | undefined;
    public displayHiddenFromUI: boolean | undefined;
    public entityOwnerTypes = new GuidSet();
    public entitySubTypes = new Map<string,ObservableCollection<string>>();
    public entityTypes = new Set<string>();
    public excludedGuids = new GuidSet();
    public federatedState: string | undefined; // ApiStateMode
    public fields = new Set<string>();
    public guids = new GuidSet();
    public includedGuids = new GuidSet();
    public logicalId: number | undefined;
    public name: string | undefined;
    public nameOrder: string | undefined; // ApiSortingOption
    public page: number | undefined;
    public pageSize: number | undefined;
    public role: IGuid | undefined;
    public roleTypeIds = new GuidSet();
    public runningStates = new Set<string>();
    public validFlags: string | undefined; // ApiValidFlags

    // #endregion REST Properties

    // #region Constructor

    constructor(baseQuery?: string) {
        super(baseQuery);
    }

    // #endregion Constructor

    // #region Methods

    public buildQuery(): string {
        this.prepareQuery();
        let query = super.buildQuery();
        query = this._queryHelper.buildElem(query, 'ValidFlags', this.validFlags);
        query = this._queryHelper.buildElemList(query, 'Fields', this.fields);
        query = this._queryHelper.buildElem(query, 'Name', this.name);
        query = this._queryHelper.buildElem(query, 'NameOrder', this.nameOrder);
        query = this._queryHelper.buildElemList(query, 'Guids', this.guids);
        query = this._queryHelper.buildElemList(query, 'EntityTypes', this.entityTypes);
        query = this._queryHelper.BuildElemDictionary(query, 'EntitySubTypes', this.entitySubTypes);
        query = this._queryHelper.buildElemList(query, 'RoleTypeIds', this.roleTypeIds);
        query = this._queryHelper.buildElemList(query, 'CustomEntityTypes', this.customEntityTypes);
        query = this._queryHelper.buildElem(query, 'Page', this.page);
        query = this._queryHelper.buildElem(query, 'PageSize', this.pageSize);
        query = this._queryHelper.buildElem(query, 'LogicalId', this.logicalId);
        query = this._queryHelper.buildElem(query, 'DisplayHiddenFromUI', this.displayHiddenFromUI);
        query = this._queryHelper.buildElem(query, 'FederatedState', this.federatedState);
        query = this._queryHelper.buildElem(query, 'Role', this.role);
        query = this._queryHelper.buildElem(query, 'CustomFieldValues', this.customFieldValues);
        query = this._queryHelper.buildElemList(query, 'RunningStates', this.runningStates);
        query = this._queryHelper.buildElemList(query, 'ExcludedGuids', this.excludedGuids);
        query = this._queryHelper.buildElemList(query, 'IncludedGuids', this.includedGuids);
        query = this._queryHelper.buildElemList(query, 'EntityOwnerTypes', this.entityOwnerTypes);
        return query;
    }

    // #endregion Methods

    // #region Custom Methods

    public prepareQuery(): void {
        // to make sure the default field are set properlly
        const fl = new FieldList(...this.fields);
        this.fields = new Set<string>(fl.Fields);
        return;
    }

    // #endregion Custom Methods

}

