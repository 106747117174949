import { Directive, Input } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { debounce, DebouncedFunc } from 'lodash-es';
/**
 * Adds a debouncer to the _ngModelChange_ event.
 */
@Directive({
    selector: '[ngModel][appDebounce]',
    providers: [
        {
            provide: NgControl,
            useExisting: NgModel,
        },
    ],
})
export class DebounceDirective extends NgModel {
    public static ngAcceptInputType_appDebounce: string | number;
    public static DefaultDebounceTime = 1000;
    private debouncedViewToModelUpdate?: DebouncedFunc<(value: unknown) => void> = debounce(super.viewToModelUpdate.bind(this), DebounceDirective.DefaultDebounceTime);
    @Input()
    set appDebounce(debouncetime: number) {
        if (debouncetime > 0) {
            this.debouncedViewToModelUpdate = debounce(super.viewToModelUpdate.bind(this), debouncetime);
        } else {
            delete this.debouncedViewToModelUpdate;
        }
    }
    public viewToModelUpdate(newValue: unknown): void {
        if (this.debouncedViewToModelUpdate) {
            this.debouncedViewToModelUpdate(newValue);
        } else {
            super.viewToModelUpdate(newValue);
        }
    }
}
