import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenComboboxComponent, GenMeltedItem, GenMeltedMenuItem, LabelComplement, Severity } from '@genetec/gelato-angular';

/**
 * TODO: Remove if gen-combobox is migrated.
 *
 * Temporary component (until gen-combobox is migrated) to simplify the use of gen-combobox in forms.
 *
 * Allows its formControlName / [formControl] directive to be bound to the its items id instead of a
 * GenMeltedItem / GenMeltedMenuItem.
 *
 * When the form's value change, the correct item with the corresponding id will be automatically selected.
 */
@Component({
    selector: 'app-form-gen-combobox',
    styleUrls: ['./form-gen-combobox.component.scss'],
    templateUrl: './form-gen-combobox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormGenComboboxComponent,
            multi: true,
        },
    ],
})
export class FormGenComboboxComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild(NgModel) model!: NgModel;
    @ViewChild(GenComboboxComponent) genComboboxComponent?: GenComboboxComponent;

    @Input() genItemsSource: GenMeltedItem[] = [];
    @Input() genLabel = '';
    @Input() genLabelComplement = LabelComplement.None;
    @Input() genMessage = '';
    @Input() genMessageSeverity = Severity.Default;
    @Input() disabled = false;
    @Input() selectedItem: GenMeltedMenuItem | GenMeltedItem | null = null;
    @Input() readonly = false;

    private inputValue: string | null = null;

    public ngAfterViewInit(): void {
        this.writeValueToCombobox();
    }

    public registerOnChange(fn: (value: string | null) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: string): void {
        this.inputValue = value;
        this.writeValueToCombobox();
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public onSelectedItemChange(item: GenMeltedItem | GenMeltedMenuItem | null): void {
        this.inputValue = item?.id ?? null;
        this.onChange(this.inputValue);
    }

    public onOffClick(): void {
        this.onTouched();
    }

    private onChange = (_: string | null): void => {};
    private onTouched = (): void => {};

    private writeValueToCombobox() {
        this.selectedItem = this.genComboboxComponent?.genItemsSource?.find((item) => item.id === this.inputValue) ?? this.selectedItem;
    }
}
