// Autogenerated: v2/help/Entities/ApiPartition?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';
import { IPrivilege } from './IPrivilegedEntity';

// #region Fields

export class PartitionEntityFields extends EntityFields {
    public static canBeSharedField = 'CanBeShared';
}

// #endregion Fields

export interface IPartitionEntity extends IEntity {
    // #region REST Properties

    canBeShared: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getAdministratorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getChildPartitionsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getUserPrivilegesAsync(id: IGuid, transaction?: ITransaction | null): Promise<ObservableCollection<IPrivilege> | null>;
    getUsersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

