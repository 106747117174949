<app-modal-entities-selector
    #entitiesSelector
    [title]="modalTitle"
    [filter]="(entityBrowserFilterSubject | async) ?? undefined"
    (entityIdsSelected)="onEntityIdsSelected($event)"
    [allowMultiSelect]="true"
    [areItemsCheckable]="true"
    [showFooter]="false"
    defaultActionText="{{ 'STE_BUTTON_ADD' | translate }}"
></app-modal-entities-selector>

<gen-text *ngIf="headerTitle" [class.title]="showHeader" [flavor]="TextFlavor.Label">{{ headerTitle }}</gen-text>
<ng-container *ngIf="entitiesSubject | async as entities">
    <gen-table
        class="table"
        *ngLet="entities.length ?? 0 as entityCount"
        [class.no-entity]="entityCount === 0"
        [selectionType]="showDelete && !readonly && hasRequiredPrivileges ? SelectionType.Multiple : SelectionType.None"
        [density]="TableDensity.Compact"
        [flavor]="TableFlavor.Solid"
    >
        <gen-table-header *ngIf="showHeader">
            <gen-actions>
                <gen-actions-expanded *ngIf="!readonly">
                    <gen-text class="item-count" [flavor]="TextFlavor.Secondary">{{ entityCount }} items</gen-text>
                    <gen-button *ngIf="showAdd" [hidden]="!hasRequiredPrivileges" [flavor]="ButtonFlavor.Flat" (click)="openAddEntitiesModal()">
                        <gen-icon [icon]="Icon.Plus"></gen-icon>
                    </gen-button>
                    <ng-container *ngIf="selectedTableRowIdsSubject | async as selectedTableRowIds">
                        <gen-button
                            *ngIf="showDelete && selectedTableRowIds.length > 0"
                            [disabled]="forbidDelete$ | async"
                            [hidden]="!hasRequiredPrivileges"
                            [flavor]="ButtonFlavor.Flat"
                            (click)="removeAllSelectedRows()"
                        >
                            <gen-icon [icon]="Icon.Trash"></gen-icon> </gen-button
                    ></ng-container>
                </gen-actions-expanded>
                <!-- TODO: Change to built-in option when https://dev.azure.com/GenetecCentral/UXDev/_workitems/edit/62586 -->
                <div hidden slot="gen-actions-collapsed"></div>
            </gen-actions>
        </gen-table-header>
        <ng-container *ngLet="isLoadingSubject | async as isLoading">
            <gen-table-loading-overlay *ngIf="isLoading"><gen-spinner></gen-spinner></gen-table-loading-overlay>
            <gen-table-empty-content *ngIf="!isLoading && entityCount === 0"> {{ emptyMessage }} </gen-table-empty-content>
        </ng-container>
        <gen-table-row *ngIf="showHeader && entityCount > 0">
            <gen-table-column-header column-name="col-name">{{ 'STE_LABEL_NAME' | translate }}</gen-table-column-header>
            <gen-table-column-header *ngFor="let columnDescriptor of extraColumnsDescriptors" [attr.column-name]="'col-' + columnDescriptor.columnKey">
                {{ columnDescriptor.columnName }}
            </gen-table-column-header>
        </gen-table-row>
        <gen-table-row *ngFor="let entity of entities; let rowIndex = index" [attr.entity-id]="entity.id" (selectedChange)="toggleRowSelection(entity.id)">
            <gen-table-data column-name="col-name">
                <div class="text-row">
                    <gen-icon
                        *ngIf="!entity.isDeletable"
                        [icon]="Icon.Lock"
                        size="small"
                        [appTooltip]="'STE_TOOLTIP_ACCESSRULE_INHERITED_FROM_CARDHOLDERGROUP' | translate"
                    ></gen-icon>
                    <gen-text class="overflow-ellipsis" appTooltip="{{ entity.data.name }}" [appTooltipOnlyIfOverflow]="true">{{ entity.data.name }}</gen-text>
                </div>
            </gen-table-data>
            <gen-table-data *ngFor="let columnDescriptor of extraColumnsDescriptors" [attr.column-name]="'col-' + columnDescriptor.columnKey">
                <ng-container [ngTemplateOutlet]="columnDescriptor.template" [ngTemplateOutletContext]="{ $implicit: entity.data }"></ng-container>
            </gen-table-data>
        </gen-table-row>
    </gen-table>
</ng-container>
