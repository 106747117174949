import { Guard } from '../utils/Guard';
import { Utils } from '../utils/Utils';

export class ZoomFactor {
  private static readonly Min: number = 1.0;
  private static readonly Max: number = 20.0;
  public static readonly Default: ZoomFactor = new ZoomFactor(1);

  private static readonly Steps: number = 0.5;

  private readonly m_value: number;

  public get Value(): number {
    return this.m_value;
  }

  public get IsMinimum(): boolean {
    return this.m_value === ZoomFactor.Min;
  }

  public constructor(zoomFactor: number) {
    Guard.isInRangeInclusive(zoomFactor, ZoomFactor.Min, ZoomFactor.Max);
    this.m_value = zoomFactor;
  }

  public toString(): string {
    return this.m_value.toFixed(2);
  }

  public increase(): ZoomFactor {
    return new ZoomFactor(Utils.clip(this.m_value + ZoomFactor.Steps, ZoomFactor.Min, ZoomFactor.Max));
  }

  public decrease(): ZoomFactor {
    return new ZoomFactor(Utils.clip(this.m_value - ZoomFactor.Steps, ZoomFactor.Min, ZoomFactor.Max));
  }
}
