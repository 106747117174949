import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { Observable, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
/**
 * When a source entity changes, emits the new value of the field if it has changed.
 *
 * @example
 * const doorOpened$ = this.door$.pipe(filterFieldChanged('doorOpened'));
 */
export const filterFieldChanged =
    <T extends IEntity, F extends keyof T>(field: F): OperatorFunction<T | null, T[F] | null> =>
    (source$: Observable<T | null>) => {
        return source$.pipe(
            map((sourceEntity) => (sourceEntity?.[field] !== undefined ? sourceEntity : { [field]: null }) as T),
            pluck(field), // Made sure the field existed, will return null if sourceEntity is not valid.
            distinctUntilChanged()
        );
    };
