import { ILiteEvent, LiteEvent } from './utils/liteEvents';
import { TimelineContentEvent } from './events';
import { ITimelineEventSource } from './players/webPlayer';
import { TimeLineRange } from './TimeLineRange';
import { TimeSpan } from './utils/TimeSpan';

/**
 * This class regroups methods and events used to query the timeline events.
 * @public */
export interface ITimelineProvider {
  /**
   * Set the range of interest of the timeline events. You will receive TimelineContentEvent for bookmarks and sequences based on the range you provided.
   * This method may be called before calling playLive() or seek().
   *
   * @remarks
   * If the endTime parameter is in the future, you will periodically receive updates for the new Live events. The sequences provided via the event will not
   * overlap in the same update, but will overlap between updates. You will have to merge overlapping sequences to build the timeline.
   * Important: The range of interest (between start and end time) cannot be larger than 72 hours. We do not recommend displaying a timeline larger than 24h.
   * @param startTime - Start of the range of interest.
   * @param endTime - End of the range of interest.
   */
  setTimelineRange(startTime: Date, endTime: Date): void;

  /**
   * This event is fired every time there is new data to display in the timeline "range of interest"
   * @public */
  readonly onTimelineContentUpdated: ILiteEvent<TimelineContentEvent>;
}

/**
 * This class is actually just a facade to isolate the members and methods related to timeline events.
 * @internal */
export class TimelineProvider implements ITimelineProvider {
  public static readonly TimelineRangeLimit: TimeSpan = TimeSpan.fromHours(72);

  private readonly m_timelineContentEvent: LiteEvent<TimelineContentEvent> = new LiteEvent<TimelineContentEvent>();

  private m_timelineEventSource?: ITimelineEventSource;
  private m_timeLine?: TimeLineRange;

  public get onTimelineContentUpdated(): ILiteEvent<TimelineContentEvent> {
    return this.m_timelineContentEvent;
  }

  public dispose() {
    if (this.m_timelineEventSource !== undefined) {
      this.unregister(this.m_timelineEventSource);
    }
    this.m_timelineContentEvent.dispose();
  }

  public register(timelineEventSource: ITimelineEventSource) {
    if (this.m_timelineEventSource !== undefined) {
      throw new Error('You need to unregister the current timelineEventSource first.');
    }

    this.m_timelineEventSource = timelineEventSource;
    timelineEventSource.timelineContentUpdated.register(this.m_timelineContentEvent.boundTrigger);

    // We handle setting the timeline range before the player is intialized
    if (this.m_timeLine !== undefined) {
      timelineEventSource.setTimelineRange(this.m_timeLine);
    }
  }

  public unregister(timelineEventSource: ITimelineEventSource) {
    if (timelineEventSource !== this.m_timelineEventSource) {
      throw new Error('Unexpected timelineEventSource instance supplied');
    }

    this.m_timelineEventSource = undefined;
    timelineEventSource.timelineContentUpdated.unregister(this.m_timelineContentEvent.boundTrigger);
  }

  public setTimelineRange(startTime: Date, endTime: Date): void {
    const newRange = new TimeLineRange(startTime, endTime);
    if (newRange.Span.isGreaterThan(TimelineProvider.TimelineRangeLimit)) {
      throw new Error(`Requested Timeline range of interest is ${newRange.Span.toString()}. It cannot be more than ${TimelineProvider.TimelineRangeLimit.toString()}.`);
    }

    this.m_timeLine = newRange;

    if (this.m_timelineEventSource !== undefined) {
      this.m_timelineEventSource.setTimelineRange(this.m_timeLine);
    }
  }
}
