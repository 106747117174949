import { IWebSocketRequest } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';
import { PlaySpeed } from '../../PlaySpeed';
import { Token } from '../../../services/Token';
import { StreamUsage } from './CreateStream';
import { Serializer } from '../../../utils/Serializer';
import { PlayerMode } from '../../../enums';
import { VideoFormat, SupportedVideoFormats } from '../SupportedVideoFormats';
import { Utils } from '../../../utils/Utils';

/**
 * @beta
 * */
export class UpdateStreamRequest implements IWebSocketRequest {
  private static readonly command = 'UpdateStream';

  private readonly m_header: WebSocketMessageHeader;

  private readonly m_body: UpdateStreamRequestBody;

  public get channel(): number {
    return this.m_header.channel;
  }

  public get body(): UpdateStreamRequestBody {
    return this.m_body;
  }

  public get expectResponse() {
    return true;
  }

  constructor(channel: number,
    parameters: {
      playerMode?: PlayerMode, seekTime?: Date, playSpeed?: PlaySpeed, pause?: boolean, timeLine?: TimeLine,
      token?: Token, audioEnabled?: boolean, supportedFormats?: SupportedVideoFormats, streamUsage?: StreamUsage
    }) {
    this.m_header = new WebSocketMessageHeader(channel, UpdateStreamRequest.command);
    this.m_body = new UpdateStreamRequestBody(parameters);
  }

  public serialize(): ArrayBuffer {
    const header = new DataView(this.m_header.serialize());
    const body = new DataView(this.m_body.serialize());

    return Serializer.combineDataView(header, body);
  }

  static buildTokenUpdate(channel: number, newToken: Token): IWebSocketRequest {
    return new UpdateStreamRequest(channel, { token: newToken });
  }

  public debugStatus(indent: number): string {
    return this.m_body.debugStatus(indent);
  }
}

/**
 * @beta
 * */
export class UpdateStreamRequestBody {
  public readonly PlayerMode?: PlayerMode;
  public readonly SeekTime?: Date;
  public readonly PlaySpeed?: number;
  public readonly Pause?: boolean;
  public readonly TimeLine?: TimeLine;
  public readonly Token?: string;
  public readonly AudioEnabled?: boolean;
  public readonly SupportedFormats?: VideoFormat[]
  public readonly StreamUsage?: StreamUsage;

  constructor(parameters: {
    playerMode?: PlayerMode, seekTime?: Date, playSpeed?: PlaySpeed, pause?: boolean, timeLine?: TimeLine,
    token?: Token, audioEnabled?: boolean, supportedFormats?: SupportedVideoFormats, streamUsage?: StreamUsage
  }) {
    this.PlayerMode = parameters.playerMode;
    this.SeekTime = parameters.seekTime;
    this.PlaySpeed = parameters.playSpeed !== undefined ? parameters.playSpeed.Value : undefined;
    this.Pause = parameters.pause;
    this.AudioEnabled = parameters.audioEnabled;
    this.TimeLine = parameters.timeLine;
    this.Token = parameters.token?.Value;
    this.SupportedFormats = parameters.supportedFormats?.getAvailableFormats();
    this.StreamUsage = parameters.streamUsage;
  }

  public serialize(): ArrayBuffer {
    const bodyJson = JSON.stringify(this);
    return Serializer.serializeString(bodyJson);
  }

  public toString(): string {
    return `Player Mode: ${this.PlayerMode}
            Seek Time: ${Utils.formatDate(this.SeekTime)}
            PlaySpeed: ${this.PlaySpeed}
            Pause: ${this.Pause}
            AudioEnabled: ${this.AudioEnabled}
            TimeLine: ${this.TimeLine?.toString()}
            Token: ${this.Token}
            Supported Formats: ${this.SupportedFormats?.toString()}
            Stream Usage: ${this.StreamUsage}`;
  }

  public debugStatus(indent: number): string {
    return 'UpdateStream' + Utils.indentNewLine(indent) +
      `Player Mode: ${this.PlayerMode}` + Utils.indentNewLine(indent) +
      `Seek Time: ${Utils.formatDate(this.SeekTime)}` + Utils.indentNewLine(indent) +
      `PlaySpeed: ${this.PlaySpeed}` + Utils.indentNewLine(indent) +
      `Pause: ${this.Pause}` + Utils.indentNewLine(indent) +
      `AudioEnabled: ${this.AudioEnabled}` + Utils.indentNewLine(indent) +
      `TimeLine: ${this.TimeLine?.toString()}` + Utils.indentNewLine(indent) +
      `Token: ${this.Token}` + Utils.indentNewLine(indent) +
      `Supported Formats: ${this.SupportedFormats?.toString()}` + Utils.indentNewLine(indent) +
      `Stream Usage: ${this.StreamUsage}`;
  }
}

/**
 * @beta
 * */
export class TimeLine {
  public Start: Date;
  public End: Date;

  constructor(start: Date, end: Date) {
    this.Start = start;
    this.End = end;
  }

  public toString(): string {
    return `[${Utils.formatDate(this.Start)} - ${Utils.formatDate(this.End)}]`;
  }
}
