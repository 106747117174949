/* eslint-disable prefer-arrow/prefer-arrow-functions, @typescript-eslint/ban-types */
import { Selector as ngxsSelector } from '@ngxs/store';
import { SelectorSpec } from '@ngxs/store/src/decorators/selector/symbols';
import { ImmutableSelector } from '../immder-adapter';

export function Selector<T>(selectors?: T[]): Function {
    return <U>(target: any, key: string | symbol, descriptor: TypedPropertyDescriptor<SelectorSpec<T, U>>): TypedPropertyDescriptor<SelectorSpec<T, U>> | void => {
        const immutable = ImmutableSelector()(target, key, descriptor) as TypedPropertyDescriptor<SelectorSpec<T, U>>;
        return ngxsSelector(selectors)(target, key, immutable);
    };
}
