import { Component, Injector, OnInit } from '@angular/core';
import { GenModalService, Flavor } from '@genetec/gelato-angular';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ExportStatus2 } from '@modules/video/api/video-export-progress.event';
import { VideoExportService } from '@modules/video/services/video-export.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { SafeGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { ExportVideoComponent } from '../export-video/export-video.component';
import { ExportVideoFormGroup } from '../export-video/export-video.form-group';

@Component({
    selector: 'app-export-video-authorization',
    templateUrl: './export-video-authorization.component.html',
    styleUrls: ['./export-video-authorization.component.scss'],
})
export class ExportVideoAuthorizationComponent extends ConnectionAwareModalComponent implements OnInit {
    public password = '';
    public username = '';
    public errorMessage = false;
    public errorMessageString = '';
    public readonly Flavor = Flavor;
    public entityId!: SafeGuid;
    public supportsEncryption!: boolean;
    public encrypt!: boolean;
    public encryptionKey!: string;
    public exportAudio!: boolean;
    public cameraName!: string;
    public thumbnail!: string;
    public duration!: string;
    public enableAuthorize = false;
    public startTimeString = '';
    public endTimeString = '';
    public timeString = '';
    public isNewDay = false;
    public description = '';
    public exportVideoForm?: ExportVideoFormGroup;
    private user: IUserEntity | null = null;
    private scClient: WebAppClient;

    constructor(
        authService: AuthService,
        injector: Injector,
        securityCenterClientService: SecurityCenterClientService,
        trackingService: TrackingService,
        private translateService: TranslateService,
        private videoExportService: VideoExportService,
        private modalService: GenModalService
    ) {
        super(authService, injector, trackingService);
        this.scClient = securityCenterClientService?.scClient;
    }

    public async ngOnInit() {
        super.ngOnInit();
        this.entityInformationStringProducer();
        this.user = await this.scClient.getEntityAsync<UserEntity, IUserEntity>(UserEntity, this.scClient.userId, null, null, EntityFields.nameField);
    }
    public onAuthorize = async (): Promise<boolean> => {
        let authorized = false;
        if (this.user?.name.trim().toLowerCase() === this.username.trim().toLowerCase()) {
            this.errorMessage = true;
            this.errorMessageString = this.translateService.instant('STE_MESSAGE_ERROR_SAME_USER_EXPORT') as string;
            return authorized;
        }
        const credentialModel = { username: this.username, password: this.password };

        if (this.exportVideoForm) {
            const formRawValues = this.exportVideoForm.getRawValue();
            const modelValue = this.videoExportService.buildVideoExportRequest({
                entityId: this.entityId,
                startTime: formRawValues.startTime,
                endTime: formRawValues.endTime,
                supportsEncryption: formRawValues.format.supportsEncryption,
                encrypt: formRawValues.encrypt,
                encryptionKey: formRawValues.encryptionKey,
                exportAudio: formRawValues.exportAudio,
                format: formRawValues.format,
                description: formRawValues.description,
                credentialModel,
            });
            if (modelValue) {
                try {
                    await this.videoExportService.exportAsync(modelValue, formRawValues.filename);
                    authorized = true;
                } catch (error: any) {
                    if ((error as ExportStatus2) === ExportStatus2.InsufficientPrivilegeError) {
                        this.errorMessage = true;
                        this.errorMessageString = this.translateService.instant('STE_MESSAGE_ERROR_INVALID_CREDENTIALS_OR_INSUFFICIENT_PRIVILEGE') as string;
                    }
                }
            }
        }

        return authorized;
    };

    public onBack = (): Promise<boolean> => {
        const exportVideoComponent = this.modalService.open(ExportVideoComponent, {
            entityId: this.entityId,
            cameraName: this.cameraName,
            fromAuthorizationComponent: true,
        });

        if (this.exportVideoForm && exportVideoComponent.form) {
            exportVideoComponent.form.patchValue(this.exportVideoForm.value);
        }

        return Promise.resolve(true);
    };
    public onUsernameChanged(userInput: string): void {
        this.enableAuthorize = userInput !== '';
    }
    public entityInformationStringProducer(): void {
        if (this.exportVideoForm) {
            const formRawValues = this.exportVideoForm.getRawValue();
            this.startTimeString = formRawValues.startTime.split('T', 1)[0];
            this.endTimeString = formRawValues.endTime.split('T', 1)[0];
            this.isNewDay = formRawValues.endTime.split('T')[0] !== formRawValues.startTime.split('T')[0];
            this.timeString = formRawValues.startTime.split('T')[1].split('.')[0];
        }
    }
}
