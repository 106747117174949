import { RestResponse } from '../../Connection/RestResponse';
import { FieldObject } from '../../Helpers/FieldObject';
import * as Helpers from '../../Helpers/Helpers';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { RelationFieldObjectTracker } from '../../Helpers/RelationFieldObjectTracker';
import { RelationGuidTracker } from '../../Helpers/RelationGuidTracker';
import { RelationObjectTracker } from '../../Helpers/RelationObjectTracker';
import { RelationSimpleFieldObjectTracker } from '../../Helpers/RelationSimpleFieldObjectTracker';
import { RelationTrackerBase } from '../../Helpers/RelationTrackerBase';
import { Transaction } from '../Transaction';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { ITransaction } from '../Interface/ITransaction';
import { IRestResponse } from '../Interface/IRestResponse';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { IEntity } from '../Interface/IEntity';
import { IRestTransaction } from '../Interface/IRestTransaction';
import { IRestObject } from '../Interface/IRestObject';
import { IFieldObject } from '../Interface/IFieldObject';
import { ISecurityCenterClient } from '../Interface/ISecurityCenterClient';
import { RelationGuidTemplateTracker } from '../../Helpers/RelationGuidTemplateTracker';

export class RestObject extends FieldObject implements IRestObject {
    // #region Fields
    public baseUri: string;
    public lastRelationValidFlags = 0;
    public lastRelationId = '';

    protected _canGet = true;

    private _client: ISecurityCenterClient | null = null;
    private _relations: Map<string, RelationTrackerBase>;

    // #endregion

    // #region Properties

    public get relations(): ReadonlyMap<string, RelationTrackerBase> {
        return this._relations;
    }

    public get client(): ISecurityCenterClient | null {
        return this._client;
    }

    public get isDirty(): boolean {
        return super.isDirty;
    }

    public set isDirty(dirty: boolean) {
        super.isDirty = dirty;
        if (dirty === false) {
            const updatedRelations = new Set<string>();
            for (const [key, tracker] of this._relations) {
                if (tracker.pendingOperations.size > 0) {
                    updatedRelations.add(key);
                    tracker.isValid = false;
                }
            }
            for (const toDelete of updatedRelations) {
                this._relations.delete(toDelete);
            }
        }
    }

    public get canExecuteRelations(): boolean {
        return true;
    }

    public get canGet(): boolean {
        return this._canGet;
    }

    // #endregion

    // #region Constructor

    constructor(baseUri: string) {
        super();
        this._relations = new Map<string, RelationTrackerBase>();
        this.baseUri = baseUri;
    }

    // #endregion

    // #region Public methods

    public clearRelations(): void {
        this._relations.clear();
    }

    public getRelation<T extends RelationTrackerBase>(relationId: string, tracker: T, validFlags?: number | null) {
        this.lastRelationId = relationId;
        if (validFlags) {
            this.lastRelationValidFlags = validFlags;
        }
        if (this._relations.has(relationId) === false) {
            this._relations.set(relationId, tracker);
            return tracker;
        }
        return this._relations.get(relationId);
    }

    protected async getFieldObjectRelationAsync<T extends FieldObject & U, U extends IFieldObject>(
        classType: new () => T,
        relationId: string,
        elementId: string,
        readOnly: boolean,
        allowValueUpdate: boolean,
        validFlags?: number | null,
        transaction?: ITransaction | null,
    ): Promise<ObservableCollection<U> | null> {
        const tracker = this.getRelation(
            relationId,
            new RelationFieldObjectTracker<T, U>(classType, this, relationId, elementId, readOnly, allowValueUpdate),
            validFlags,
        ) as RelationFieldObjectTracker<T, U>;
        if (!transaction) {
            return await tracker.getValueAsync();
        } else {
            await tracker.pushTransactionAsync(transaction);
            return null;
        }
    }

    protected async getGuidRelationAsync(
        relationId: string,
        itemKey: string,
        readOnly: boolean,
        query?: IQueryFilter | null,
        validFlags?: number | null,
        transaction?: ITransaction | null,
    ): Promise<ObservableCollection<IEntity> | null> {
        if (!this.client) {
            throw new Error('client not set');
        }

        const tracker = new RelationGuidTracker(this.client, this, relationId, itemKey, readOnly, query);
        const newTracker = this.getRelation(tracker.relationAndFilter, tracker, validFlags) as RelationGuidTracker;

        // Special case because of RelationAndFilter
        if (!transaction) {
            return await newTracker.getValueAsync();
        } else {
            await tracker.pushTransactionAsync(transaction);
            return null;
        }
    }

    protected async getGuidTemplateRelationAsync<T extends IEntity>(
        classType: new () => T,
        relationId: string,
        itemKey: string,
        readOnly: boolean,
        query?: IQueryFilter | null,
        validFlags?: number | null,
        transaction?: Transaction | null,
    ): Promise<ObservableCollection<IEntity> | null> {
        if (!this.client) {
            throw new Error('client not set');
        }

        // Special case because of RelationAndFilter
        const tracker = new RelationGuidTemplateTracker<T>(classType, this.client, this, relationId, itemKey, readOnly, query);
        const newtracker = this.getRelation(tracker.relationAndFilter, tracker, validFlags) as RelationGuidTemplateTracker<T>;

        if (!transaction) {
            return await newtracker.getValueAsync();
        } else {
            await newtracker.pushTransactionAsync(transaction);
            return null;
        }
    }

    public resetRelation(relationId: string) {
        this._relations.delete(relationId);
    }

    protected async updateDataRelationAsync(relationId: string, verb: string, body: object | null, transaction?: ITransaction | null): Promise<IRestResponse> {
        const result = await this.executeRequestTransactionAsync2(relationId, verb, body, transaction);
        if (result && (result.statusCode === HttpStatusCode.OK || result.statusCode === HttpStatusCode.CREATED)) {
            this.resetRelation(relationId);
        }
        if (!result) {
            throw new Error('executeRequestTransactionAsync failed');
        }
        return result;
    }

    protected async updateDataRelation2Async<T extends FieldObject & U, U extends IFieldObject>(
        classType: new () => T,
        relationId: string,
        verb: string,
        updateSupported: boolean,
        validFlags: number | null,
        body: U | null,
    ): Promise<U | null> {
        const tracker = this.getRelation(
            relationId,
            new RelationSimpleFieldObjectTracker<T, U>(classType, this, relationId, updateSupported),
            validFlags,
        ) as RelationSimpleFieldObjectTracker<T, U>;
        return tracker.addOperation(verb, body);
    }

    public async getDataRelationAsync<T extends FieldObject>(
        classType: new () => T,
        relationId: string,
        updateSupported: boolean,
        validFlags?: number | null,
        transaction?: ITransaction | null,
    ): Promise<T | null> {
        const tracker = this.getRelation(
            relationId,
            new RelationSimpleFieldObjectTracker<T, IFieldObject>(classType, this, relationId, updateSupported),
            validFlags,
        ) as RelationSimpleFieldObjectTracker<T, IFieldObject>;
        if (!transaction) {
            return await tracker.getValueAsync();
        } else {
            await tracker.pushTransactionAsync(transaction);
            return null;
        }
    }

    public async getDataRelation2Async<T>(relationId: string, fieldId: string, validFlags?: number | null, transaction?: ITransaction | null): Promise<T | null> {
        const tracker = this.getRelation(relationId, new RelationObjectTracker<T>(this, relationId, fieldId), validFlags) as RelationObjectTracker<T>;
        if (!transaction) {
            return await tracker.getValueAsync();
        } else {
            await tracker.pushTransactionAsync(transaction);
            return null;
        }
    }

    public async executeRequestAsync(verb: string, uri: string, body: string | any, token?: Helpers.CancellationToken): Promise<RestResponse> {
        const newUri = this.baseUri + '/' + uri;
        if (!this._client) {
            throw new Error('client not defined');
        }
        return await this._client.rest.executeRequestAsync(verb, newUri, body, token);
    }

    public async executeRequestWithResponseTypeAsync(
        verb: string,
        uri: string,
        body: string | any,
        responseType: 'arraybuffer' | 'blob' | 'formdata' | 'json' | 'text',
        token?: Helpers.CancellationToken,
    ): Promise<RestResponse> {
        const newUri = this.baseUri + '/' + uri;
        if (!this._client) {
            throw new Error('client not defined');
        }
        return await this._client.rest.executeRequestWithResponseTypeAsync(verb, newUri, body, responseType, token);
    }

    // Transaction support
    public async executeRequestTransactionAsync<T>(
        operation: string,
        verb: string,
        body: object | null,
        transaction?: ITransaction,
        responseHandler?: (response: IRestResponse) => Promise<T>,
        token?: Helpers.CancellationToken,
    ): Promise<T | null> {
        if (!transaction) {
            const response = await this.executeRequestAsync(verb, operation, body != null ? body.toString() : null, token);
            if (!responseHandler) {
                throw new Error('responseHandler cannot be null');
            }
            return await responseHandler(response);
        } else {
            transaction.addTransactionOperation<T>(this.baseUri + '/' + operation, verb, body, responseHandler);
            return null;
        }
    }

    public async executeRequestTransactionAsync2(
        operation: string,
        verb: string,
        body: object | null,
        transaction?: ITransaction | null,
        token?: Helpers.CancellationToken,
    ): Promise<IRestResponse | null> {
        if (!transaction) {
            return await this.executeRequestAsync(verb, operation, body != null ? body.toString() : null, token);
        } else {
            const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
                if (response.statusCode === HttpStatusCode.OK) {
                    return new Promise<IRestResponse>(function (resolve, reject) {
                        resolve(response);
                    });
                } else {
                    return new Promise<IRestResponse>(function (resolve, reject) {
                        reject(response);
                    });
                }
            };
            transaction.addTransactionOperation<IRestResponse>(this.baseUri + '/' + operation, verb, body, responseHandler);
            return null;
        }
    }

    public async reloadAsync(clearRelationOnly = false): Promise<void> {
        this._relations.clear();
    }

    // #endregion

    // #region Internal methods

    public getUpdateBody(): object | null {
        let count = 0;
        const body = new FieldObject();
        for (const [key, field] of this.allFields) {
            if (field.dirty === true) {
                body.setField(field.fieldId, field.value);
                count++;
            }
        }
        if (count === 0) {
            return null;
        }
        return body;
    }

    public getCreateBody(): object | null {
        let count = 0;
        const body = new FieldObject();
        for (const [key, field] of this.allFields) {
            body.setField(field.fieldId, field.value);
            count++;
        }
        if (count === 0) {
            return null;
        }
        return body;
    }

    public addPendingOperation(transaction: IRestTransaction) {
        for (const [key, tracker] of this._relations) {
            tracker.addPendingOperation(transaction);
        }
        return;
    }

    public load(rest: ISecurityCenterClient, config?: object): void {
        this._client = rest;
        this.loadFields(config);
    }

    // #endregion

    // #region Protected methods

    public loadFrom(source: IRestObject) {
        this._client = source.client;
        this._relations.clear();
        for (const [key, relation] of source.relations) {
            this._relations.set(key, relation);
        }
        super.loadFrom(source);
    }

    public loadRelationsFrom(source: IRestObject, relations: Set<string>): void {
        for (const [key, relation] of source.relations) {
            if (relations.has(key)) {
                this._relations.set(key, relation);
            }
        }
    }

    public loadFieldsFrom(source: IRestObject, fields?: Set<string>): void {
        this._client = source.client;

        super.loadFieldsFrom(source, fields);
    }

    // #endregion
}
