// Autogenerated: v2/help/Entities/ApiVideoUnit?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { FieldObject } from '../../../Helpers/FieldObject';
import { UnitEntity } from '../UnitEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { IVideoUnitEntity, IPingSubResult, IPingResult, VideoUnitEntityFields } from '../../Interface/IVideoUnitEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { IFieldObject } from '../../Interface/IFieldObject';

// #region Inner classes

export class PingSubResult extends FieldObject implements IPingSubResult {
    // AuthenticationScheme
    public get authenticationScheme(): string {
        return this.getField<string>('AuthenticationScheme');
    }
    public set authenticationScheme(value: string) {
        this.setField<string>('AuthenticationScheme', value);
    }
    // AuthenticationSuccesful
    public get authenticationSuccesful(): boolean {
        return this.getField<boolean>('AuthenticationSuccesful');
    }
    public set authenticationSuccesful(value: boolean) {
        this.setField<boolean>('AuthenticationSuccesful', value);
    }
    // HttpConnectionSuccessful
    public get httpConnectionSuccessful(): boolean {
        return this.getField<boolean>('HttpConnectionSuccessful');
    }
    public set httpConnectionSuccessful(value: boolean) {
        this.setField<boolean>('HttpConnectionSuccessful', value);
    }
    // HttpSupported
    public get httpSupported(): boolean {
        return this.getField<boolean>('HttpSupported');
    }
    public set httpSupported(value: boolean) {
        this.setField<boolean>('HttpSupported', value);
    }
    // PingDelay
    public get pingDelay(): number {
        return this.getField<number>('PingDelay');
    }
    public set pingDelay(value: number) {
        this.setField<number>('PingDelay', value);
    }
    // PingFailureReason
    public get pingFailureReason(): string {
        return this.getField<string>('PingFailureReason');
    }
    public set pingFailureReason(value: string) {
        this.setField<string>('PingFailureReason', value);
    }
    // PingSuccessful
    public get pingSuccessful(): boolean {
        return this.getField<boolean>('PingSuccessful');
    }
    public set pingSuccessful(value: boolean) {
        this.setField<boolean>('PingSuccessful', value);
    }
    // ServerId
    public get serverId(): IGuid {
        return this.getFieldGuid('ServerId');
    }
    public set serverId(value: IGuid) {
        this.setFieldGuid('ServerId', value);
    }
    // TcpConnectionEndPoint
    public get tcpConnectionEndPoint(): string {
        return this.getField<string>('TcpConnectionEndPoint');
    }
    public set tcpConnectionEndPoint(value: string) {
        this.setField<string>('TcpConnectionEndPoint', value);
    }
    // TcpConnectionSuccessful
    public get tcpConnectionSuccessful(): boolean {
        return this.getField<boolean>('TcpConnectionSuccessful');
    }
    public set tcpConnectionSuccessful(value: boolean) {
        this.setField<boolean>('TcpConnectionSuccessful', value);
    }
    // TcpSupported
    public get tcpSupported(): boolean {
        return this.getField<boolean>('TcpSupported');
    }
    public set tcpSupported(value: boolean) {
        this.setField<boolean>('TcpSupported', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('AuthenticationScheme', '');
        this.setField<boolean>('AuthenticationSuccesful', false);
        this.setField<boolean>('HttpConnectionSuccessful', false);
        this.setField<boolean>('HttpSupported', false);
        this.setField<number>('PingDelay', 0);
        this.setField<string>('PingFailureReason', '');
        this.setField<boolean>('PingSuccessful', false);
        this.setFieldGuid('ServerId', SafeGuid.EMPTY);
        this.setField<string>('TcpConnectionEndPoint', '');
        this.setField<boolean>('TcpConnectionSuccessful', false);
        this.setField<boolean>('TcpSupported', false);
    }
}

export class PingResult extends FieldObject implements IPingResult {
    // Executed
    public get executed(): boolean {
        return this.getField<boolean>('Executed');
    }
    public set executed(value: boolean) {
        this.setField<boolean>('Executed', value);
    }
    // PingFailureReason
    public get pingFailureReason(): string {
        return this.getField<string>('PingFailureReason');
    }
    public set pingFailureReason(value: string) {
        this.setField<string>('PingFailureReason', value);
    }
    // Results
    public get results(): ObservableCollection<IPingSubResult> {
        return this.getFieldObjectArray<PingSubResult, IPingSubResult>(PingSubResult, 'Results');
    }
    public set results(value: ObservableCollection<IPingSubResult>) {
        this.setFieldObjectArray('Results', value);
    }

    public initializeAllFields(): void {
        this.setField<boolean>('Executed', false);
        this.setField<string>('PingFailureReason', '');
        this.setFieldArray<IPingSubResult>('Results', new ObservableCollection<IPingSubResult>());
    }
}

// #endregion Inner classes

export class VideoUnitEntity extends UnitEntity implements IVideoUnitEntity {
    // #region REST Properties

    // AuthenticationScheme
    public get authenticationScheme(): number {
        return this.getField<number>(VideoUnitEntityFields.authenticationSchemeField, ValidFlags.Specific);
    }
    public set authenticationScheme(value: number) {
        this.setField<number>(VideoUnitEntityFields.authenticationSchemeField, value);
    }

    // CertificateValidationInfo
    public get certificateValidationInfo(): string {
        return this.getField<string>(VideoUnitEntityFields.certificateValidationInfoField, ValidFlags.Specific);
    }
    public set certificateValidationInfo(value: string) {
        this.setField<string>(VideoUnitEntityFields.certificateValidationInfoField, value);
    }

    // CommandPort
    public get commandPort(): number {
        return this.getField<number>(VideoUnitEntityFields.commandPortField, ValidFlags.Specific);
    }
    public set commandPort(value: number) {
        this.setField<number>(VideoUnitEntityFields.commandPortField, value);
    }

    // ConnectionAddress
    public get connectionAddress(): string {
        return this.getField<string>(VideoUnitEntityFields.connectionAddressField, ValidFlags.Specific);
    }
    public set connectionAddress(value: string) {
        this.setField<string>(VideoUnitEntityFields.connectionAddressField, value);
    }

    // Dhcp
    public get dhcp(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.dhcpField, ValidFlags.Specific);
    }
    public set dhcp(value: boolean) {
        this.setField<boolean>(VideoUnitEntityFields.dhcpField, value);
    }

    // DiscoveryPort
    public get discoveryPort(): number {
        return this.getField<number>(VideoUnitEntityFields.discoveryPortField, ValidFlags.Specific);
    }
    public set discoveryPort(value: number) {
        this.setField<number>(VideoUnitEntityFields.discoveryPortField, value);
    }

    // FirmwareUpgradePort
    public get firmwareUpgradePort(): number {
        return this.getField<number>(VideoUnitEntityFields.firmwareUpgradePortField, ValidFlags.Specific);
    }
    public set firmwareUpgradePort(value: number) {
        this.setField<number>(VideoUnitEntityFields.firmwareUpgradePortField, value);
    }

    // FirstAddFeatureSet
    public get firstAddFeatureSet(): string {
        return this.getField<string>(VideoUnitEntityFields.firstAddFeatureSetField, ValidFlags.Specific);
    }
    public set firstAddFeatureSet(value: string) {
        this.setField<string>(VideoUnitEntityFields.firstAddFeatureSetField, value);
    }

    // Gateway
    public get gateway(): string {
        return this.getField<string>(VideoUnitEntityFields.gatewayField, ValidFlags.Specific);
    }
    public set gateway(value: string) {
        this.setField<string>(VideoUnitEntityFields.gatewayField, value);
    }

    // HardwareVersion
    public get hardwareVersion(): string {
        return this.getField<string>(VideoUnitEntityFields.hardwareVersionField, ValidFlags.Specific);
    }
    public set hardwareVersion(value: string) {
        this.setField<string>(VideoUnitEntityFields.hardwareVersionField, value);
    }

    // HostName
    public get hostName(): string {
        return this.getField<string>(VideoUnitEntityFields.hostNameField, ValidFlags.Specific);
    }
    public set hostName(value: string) {
        this.setField<string>(VideoUnitEntityFields.hostNameField, value);
    }

    // IsRestrictedUnit
    public get isRestrictedUnit(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.isRestrictedUnitField, ValidFlags.Specific);
    }

    // LastPasswordChange
    public get lastPasswordChange(): Date {
        return this.getFieldDate(VideoUnitEntityFields.lastPasswordChangeField, ValidFlags.Specific);
    }

    // LegacyConfigurations
    public get legacyConfigurations(): string {
        return this.getField<string>(VideoUnitEntityFields.legacyConfigurationsField, ValidFlags.Specific);
    }
    public set legacyConfigurations(value: string) {
        this.setField<string>(VideoUnitEntityFields.legacyConfigurationsField, value);
    }

    // PasswordStrength
    public get passwordStrength(): string {
        return this.getField<string>(VideoUnitEntityFields.passwordStrengthField, ValidFlags.Specific);
    }

    // PrefixLength
    public get prefixLength(): number {
        return this.getField<number>(VideoUnitEntityFields.prefixLengthField, ValidFlags.Specific);
    }
    public set prefixLength(value: number) {
        this.setField<number>(VideoUnitEntityFields.prefixLengthField, value);
    }

    // ProxyIdentifier
    public get proxyIdentifier(): string {
        return this.getField<string>(VideoUnitEntityFields.proxyIdentifierField, ValidFlags.Specific);
    }
    public set proxyIdentifier(value: string) {
        this.setField<string>(VideoUnitEntityFields.proxyIdentifierField, value);
    }

    // SecureConnection
    public get secureConnection(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.secureConnectionField, ValidFlags.Specific);
    }
    public set secureConnection(value: boolean) {
        this.setField<boolean>(VideoUnitEntityFields.secureConnectionField, value);
    }

    // SubnetMask
    public get subnetMask(): string {
        return this.getField<string>(VideoUnitEntityFields.subnetMaskField, ValidFlags.Specific);
    }
    public set subnetMask(value: string) {
        this.setField<string>(VideoUnitEntityFields.subnetMaskField, value);
    }

    // SupportsAudio
    public get supportsAudio(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsAudioField, ValidFlags.Specific);
    }

    // SupportsChangeCommandPort
    public get supportsChangeCommandPort(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsChangeCommandPortField, ValidFlags.Specific);
    }

    // SupportsChangeDiscoveryPort
    public get supportsChangeDiscoveryPort(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsChangeDiscoveryPortField, ValidFlags.Specific);
    }

    // SupportsChangeIP
    public get supportsChangeIP(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsChangeIPField, ValidFlags.Specific);
    }

    // SupportsFirmwareUpgrade
    public get supportsFirmwareUpgrade(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsFirmwareUpgradeField, ValidFlags.Specific);
    }

    // SupportsIdentify
    public get supportsIdentify(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsIdentifyField, ValidFlags.Specific);
    }

    // SupportsReboot
    public get supportsReboot(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsRebootField, ValidFlags.Specific);
    }

    // SupportsSSL
    public get supportsSSL(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.supportsSSLField, ValidFlags.Specific);
    }

    // UseDefaultCredentials
    public get useDefaultCredentials(): boolean {
        return this.getField<boolean>(VideoUnitEntityFields.useDefaultCredentialsField, ValidFlags.Specific);
    }
    public set useDefaultCredentials(value: boolean) {
        this.setField<boolean>(VideoUnitEntityFields.useDefaultCredentialsField, value);
    }

    // WebPage
    public get webPage(): string {
        return this.getField<string>(VideoUnitEntityFields.webPageField, ValidFlags.Specific);
    }
    public set webPage(value: string) {
        this.setField<string>(VideoUnitEntityFields.webPageField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.VideoUnits);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cameras', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async identifyAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('Identify', 'POST', null, transaction, responseHandler);
    }

    public async pingAsync(transaction?: ITransaction): Promise<IPingResult | null> {
        const responseHandler = (response: IRestResponse): Promise<IPingResult> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = new PingResult();
                result.loadFields(response.body);
                return new Promise<IPingResult>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<IPingResult>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IPingResult>('Ping', 'POST', null, transaction, responseHandler);
    }

    public async reconnectAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('Reconnect', 'POST', null, transaction, responseHandler);
    }

    public async getSpecificConfigAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'SpecificConfig', false, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

