import { Component, OnDestroy, OnInit } from '@angular/core';
import { ItemSlot } from '@genetec/gelato';
import { AccessControlCommands } from '@modules/access-control/enumerations/access-control-commands';
import { AccessControlContentTypes } from '@modules/access-control/enumerations/access-control-content-types';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Component({
    selector: 'app-door-compact-info',
    templateUrl: './door-compact-info.component.html',
    styleUrls: ['./door-compact-info.component.scss'],
})
@InternalContentPluginDescriptor({
    type: DoorCompactInfoComponent,
    pluginTypes: [PluginTypes.Tile],
    exposure: {
        id: DoorCompactInfoComponent.pluginId,
        priority: -1,
        availableCommands: [AccessControlCommands.ShuntReader, AccessControlCommands.UnlockDoor],
    },
    isContentSupported: (content: Content) => !!content?.type.equals(AccessControlContentTypes.Door),
    requirements: { licenses: [KnownLicenses.accessControl], disabledFeatureFlags: [GeneralFeatureFlags.EntityState] },
})
export class DoorCompactInfoComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('EE6B793C-3D71-4D8B-88EC-7A93E43B6702');
    public content?: Content;
    public dataContext: unknown;

    public title = '';
    public titleIcon = '';
    public titleCustomIconId?: IGuid;
    public entityId = '';
    public titleColor?: string;
    public description = '';
    public readonly ItemSlot = ItemSlot;

    constructor() {}

    ngOnInit() {
        const content = this.content;
        if (content) {
            this.entityId = content.source;
            this.title = content.title;
            this.description = content.description;
            this.titleIcon = content.icon;
            this.titleCustomIconId = content.customIconId;
            this.titleColor = content.color;
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    ngOnDestroy() {}
}
