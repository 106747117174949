<div class="container" [class.selected]="isSelected">
    <gen-card (click)="onCardholderSelected()" class="cardholder-card">
        <!-- Picture -->
        <div slot="gen-card-body" class="cardholder-card__cardholder-picture-container">
            <app-thumbnail
                [thumbnailData]="picture"
                [flavor]="ImageFlavor.Rounded"
                [fit]="ImageFit.Cover"
                thumbnailBorderRadius="4px 4px 0px 0px"
                [defaultPlaceholder]="Icon.Cardholder"
                placeholderOpacity="30%"
            ></app-thumbnail>
        </div>
        <!-- Cardholder informations -->
        <div slot="gen-card-footer" class="cardholder-card__footer ml-1 mr-1">
            <!--div used to align content vertically centered-->
            <div>
                <gen-text [flavor]="TextFlavor.Normal">
                    <span appTooltip="{{ entityName }}" [appTooltipOnlyIfOverflow]="true">{{ entityName }}</span>
                </gen-text>
                <gen-text *ngIf="isViewCardholdersPrivilegeGranted" [flavor]="TextFlavor.Secondary">
                    <!-- Multiple groups, display the first one with '+ 2 more'-->
                    <span
                        *ngIf="groups && groups.length > 1"
                        appTooltip="{{ groups[0] }} + {{ 'STE_LABEL_X_MORE_FORMAT' | translate | stringFormat: [(groups.length - 1).toString()] }}"
                        [appTooltipOnlyIfOverflow]="true"
                    >
                        {{ groups[0] }} + {{ 'STE_LABEL_X_MORE_FORMAT' | translate | stringFormat: [(groups.length - 1).toString()] }}
                    </span>
                    <!-- Only 1 group, display it-->
                    <span *ngIf="groups && groups.length === 1" [appTooltip]="groups[0]" [appTooltipOnlyIfOverflow]="true">
                        {{ groups[0] }}
                    </span>
                    <!-- No group-->
                    <span *ngIf="!groups || groups.length === 0">
                        {{ 'STE_LABEL_NO_GROUP' | translate }}
                    </span>
                </gen-text>
                <gen-text *ngIf="isViewCardholdersPrivilegeGranted" class="cardholder-card__footer--status-text" [flavor]="status | accessStatusToColor">{{
                    status | accessStatusToTranslatedString
                }}</gen-text>
            </div>
        </div>
    </gen-card>
</div>
