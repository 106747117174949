import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appDisableDefaultContextMenu]',
})
export class DisableDefaultContextMenuDirective {
    constructor() {}

    @HostListener('contextmenu', ['$event'])
    public onRightClick(event: Event): void {
        event.preventDefault();
    }
}
