import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginComponentExposure, PluginComponentRequirements } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { DateFormat } from '@genetec/gelato-angular';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { SharedContentFields } from '@modules/shared/enumerations/shared-content-fields';
import { IAlarmInvestigatingEvent } from 'RestClient/Client/Interface/IAlarmInvestigatingEvent';
import { TimeService } from '@modules/shared/services/time/time.service';
import { AlarmsService } from '../../../services/alarms.service';
import { AlarmInstanceWidgetBaseComponent } from '../../alarm-instance-widget-base.component';
import { AlarmContentFields } from '../../../enumerations/alarm-content-fields';
import { AlarmContentTypes } from '../../../enumerations/alarm-content-types';

@Component({
    selector: 'app-instance-info-actions-widget.component',
    templateUrl: './alarm-instance-info-widget.component.html',
    styleUrls: ['./alarm-instance-info-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: AlarmInstanceInfoWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: AlarmInstanceInfoWidgetComponent.pluginId, priority: 3 } as PluginComponentExposure,
    isContentSupported: (data: Content) => !!data?.type.equals(AlarmContentTypes.Instance),
    requirements: { features: [KnownFeatures.alarmsId] },
})
export class AlarmInstanceInfoWidgetComponent extends AlarmInstanceWidgetBaseComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('D19BFE61-E130-4024-8AAB-87DCD6FCF6B1');

    public readonly TextFlavor = TextFlavor;

    public priority: number | undefined;
    public sourceName: string | undefined;
    public investigatedBy = '-';
    public investigatedTime = '-';

    private scClient: SecurityCenterClient;

    constructor(securityCenterClientService: SecurityCenterClientService, alarmsService: AlarmsService, timeService: TimeService, trackingService: TrackingService) {
        super(alarmsService, timeService, trackingService);
        this.scClient = securityCenterClientService?.scClient;
    }

    public ngOnInit() {
        super.ngOnInit();

        this.tryGetAlarmInstanceInfo(this.content);

        this.isLoading = false;
    }

    protected onAlarmInvestigating(event: IAlarmInvestigatingEvent): void {
        super.onAlarmInvestigating(event);
        this.updateInvestigationInfoAsync(event.investigatedBy, event.timestamp).fireAndForget();
    }

    protected tryGetAlarmInstanceInfo(content?: Content | null): boolean {
        const result = super.tryGetAlarmInstanceInfo(content);
        if (content?.type.equals(AlarmContentTypes.Instance) && content.parameters) {
            this.sourceName = content.parameters.getField(SharedContentFields.SourceEntityName);
            this.priority = content.parameters.getField(AlarmContentFields.Priority);

            this.updateInvestigationInfoAsync(
                content.parameters.getFieldGuid(AlarmContentFields.InvestigatedBy),
                content.parameters.getField(AlarmContentFields.InvestigatedTime)
            ).fireAndForget();
        }
        return result;
    }

    private async updateInvestigationInfoAsync(investigatedBy: IGuid, investigationTime: Date) {
        const userEntity = await this.scClient.getEntityAsync<UserEntity, IUserEntity>(UserEntity, investigatedBy);
        if (userEntity) {
            this.investigatedBy = userEntity.name;
        }

        this.investigatedTime = this.timeService.formatTime(investigationTime, DateFormat.DateTime);
    }
}
