export class FieldList {
  // #region Fields

  private _fields = new Set<string>();
  private _defaultFields = new Set<string>(['id', 'name', 'entitytype']);

  // #endregion

  // #region Properties

  public get Fields(): IterableIterator<string> {
    return this._fields.values();
  }

  // #endregion

  // #region Constructor

  constructor(...fields: string[]) {
    if (fields === undefined || fields.length === 0) {
      this._fields.add('*');
    } else {
      this.addField(...fields);
    }
  }

  // #endregion

  // #region Methods

  public addField(...fields: string[]) {
    for (const arg of fields) {
      const fieldName = arg.toLowerCase().trim();
      if (fieldName.length > 0 && this._fields.has(fieldName) === false) {
        this._fields.add(fieldName);
      }
    }

    if (this._fields.has('*')) {
      return;
    }

    // make sure there's the minimum
    for (const field of this._defaultFields) {
      if (!this._fields.has(field)) {
        this._fields.add(field);
      }
    }
  }

  public toString(): string {
    let result = '';
    for (const field of this._fields) {
      if (result.length > 0) {
        result = result + ',' + field;
      } else {
        result = result + field;
      }
    }
    return result;
  }

  // #endregion
}
