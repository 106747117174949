/* eslint-disable no-param-reassign */
import { Component, OnInit, Input, OnDestroy, Inject, ViewChild, ElementRef, ChangeDetectionStrategy, NgZone, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import moment from 'moment';
import { VideoCorePlayer, Size } from 'Marmot/videoplayer';
import { Subscription } from 'rxjs';
import { SharedContentFields } from '@modules/shared/enumerations/shared-content-fields';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { CameraTypes } from 'RestClient/Client/Enumerations/CameraType';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { Point } from '@modules/shared/interfaces/drawing';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { JoystickCommandData, JoystickService } from '@modules/general/services/joystick.service';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import {
    AudioAvailabilityChangeEvent,
    AudioStateChangeEvent,
    ErrorCode,
    ErrorStatusEvent,
    IDewarperControl,
    IDigitalZoomControl,
    ILiteEvent,
    IPtzControl,
    ITimelineProvider,
    PlayerMode,
    PlayerModeChangeEvent,
    PlayerState,
    PlayerStateChangeEvent,
    PlaySpeedChangeEvent,
    StreamingConnectionStatus,
    StreamingConnectionStatusChangeEvent,
} from 'Marmot/Marmot/gwp';
import { LiteEvent } from 'Marmot/Marmot/utils/liteEvents';
import { IVideoPlayer, LoopChangeEvent } from 'Marmot/ivideoplayer';
import { ExportModel, ExportProgressEvent } from 'Marmot/export';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { TimelinePartEvent } from 'Marmot/Timeline/timelinePartEvent';
import { CameraEntityFields, ICameraEntity } from 'RestClient/Client/Interface/ICameraEntity';
import { debounce } from 'lodash-es';
import { WINDOW } from '@utilities/common-helper';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CameraEntity } from 'RestClient/Client/Model/Video/CameraEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { RenderingHelper } from '../../utilities/renderinghelper';
import { SecurityCenterClientService } from '../../../../security-center/services/client/security-center-client.service';
import { VideoContentTypes } from '../../enumerations/video-content-types';
import { InternalContentPluginDescriptor } from '../../../shared/interfaces/plugins/internal/plugin-internal.interface';
import {
    CONTENT_SERVICES_PROVIDER,
    ContentExtensionServicesProvider,
    COMMANDS_SERVICE,
    CommandsService,
    CommandBindings,
    CommandsSubscription,
    ExecuteCommandData,
} from '../../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { Content, ContentPluginComponent, DisplayContext, PluginComponentExposure, PluginContext } from '../../../shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '../../../shared/interfaces/plugins/public/plugin-types';
import { VideoDiagnosticService } from '../../services/video-diagnostic-service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

const isContentSupported = (content: Content, pluginType: IGuid) => {
    if (content) {
        if (content.type.equals(VideoContentTypes.Video)) {
            // do not want to display video in compact mode when having not only the video, this will happen when expanding the popup instead
            if (pluginType.equals(PluginTypes.MapPopupCompact) && content.contextContent) {
                return false;
            }

            return content.grantedPrivileges.some(
                (privilege) => privilege.equals(KnownPrivileges.allowVideoPrivilege) || privilege.equals(KnownPrivileges.allowPlaybackVideoPrivilege)
            );
        }
    }
    return false;
};

@UntilDestroy()
@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalContentPluginDescriptor({
    type: VideoPlayerComponent,
    pluginTypes: [PluginTypes.Tile, PluginTypes.Widget, PluginTypes.MapPopupCompact, PluginTypes.MapPopupExpand],
    exposure: { id: VideoPlayerComponent.pluginId, priority: 2, supportsCarousel: true } as PluginComponentExposure,
    isContentSupported,
    requirements: { features: [KnownFeatures.videoId], licenses: [KnownLicenses.video] },
})
export class VideoPlayerComponent extends TrackedComponent implements OnInit, OnDestroy, AfterViewInit, ContentPluginComponent, IVideoPlayer {
    public static pluginId = SafeGuid.parse('3957B0C1-7086-484F-A5DA-C3E5848B3F5B');
    // #region Properties

    // div player
    @ViewChild('div', { static: true, read: ElementRef }) public divElement!: ElementRef<HTMLDivElement>;

    @Input() public dataContext: unknown;

    // digital zoom snapshot image
    @ViewChild('zoomboximage') public zoomboximage!: ElementRef;

    // digital zoom view box
    @ViewChild('zoomboxlens') public zoomboxlens!: ElementRef;

    // parent container
    @ViewChild('parent')
    public set parentContent(child: ElementRef | undefined) {
        if (this.parent) {
            this.parent.removeEventListener('wheel', this.onParentMouseWheelHandler);
        }
        this.parent = child?.nativeElement as HTMLCanvasElement | undefined;
        if (this.parent) {
            // do not use the passive event handler in that case because we want to stopPropagation()
            this.parent.addEventListener('wheel', this.onParentMouseWheelHandler, { passive: false, capture: true });
        }
    }

    // free look container
    @ViewChild('freelook')
    public set freelookContent(child: ElementRef | undefined) {
        if (this.freelook) {
            this.freelook.removeEventListener('wheel', this.onMouseWheelHandler);
        }
        this.freelook = child?.nativeElement as HTMLCanvasElement | undefined;
        if (this.freelook) {
            // do not use the passive event handler in that case because we want to stopPropagation()
            this.freelook.addEventListener('wheel', this.onMouseWheelHandler, { passive: false });
        }
    }

    // gets a flag indicating if the digital zoom preview is currently visible
    public get isDigitalZoomEnabled(): boolean {
        return this.videoPlayer?.digitalZoomControl?.Preview.Enabled === true && this.videoPlayer?.digitalZoomControl?.Zoom > 1;
    }

    // H264 player
    public videoElement!: HTMLVideoElement;

    // Mjpeg player
    public canvasElement!: HTMLCanvasElement;

    // audio player
    public audioElement!: HTMLAudioElement;

    public content?: Content;
    public playerSubscription?: Subscription;
    public serverStatus?: string;
    public entityContent?: Content;
    public entityCacheTask: IEntityCacheTask;

    // determine if the user is currently moving the ptz with the mouse
    public freeLookIsGripped = false;

    // hold the pointer id capturing the mouse
    public freeLookMousecapture = -1;

    // determine if the mouse is currently over the control
    public isMouseOver = false;

    // represent the middle ptz dot opacity
    public dotOpacity = 0;

    // the state displayed to the client
    public displayedState: string | undefined;

    // determine if we can currently perform ptz operation (isPtz & live mode)
    public supportPtz = false;
    // determine if the current camera is a ptz
    public isPtz = false;

    // privileges
    public allowLiveVideo = false;
    public allowPlaybackVideo = false;
    public allowDigitalZoom = false;

    public stateBackgroundColor!: string | undefined;
    public displayVideo = true; // flag used to hide the video when blocked

    public isInPopup = false;

    private videoPlayer?: VideoCorePlayer;

    // Security Center client
    private scClient: SecurityCenterClient;

    private playerState: string | undefined;
    private streamingState: string | undefined;
    private errorState: string | undefined;

    private wheelTimerInterval = 500;
    private wheelTimerId: number | undefined;
    private wheelCumulativeDelta = 0;
    private readonly wheelDeltaFactor = 15;
    private isWheelSpeedPending = false;
    private unitToMouseWheelDeltaFactor = 100;

    private allowAudio = false;
    private allowBasicPtz = false;

    private parent?: HTMLCanvasElement | undefined;
    private freelook?: HTMLCanvasElement | undefined;
    private freelookFocused = false;

    private commandsSubscription!: CommandsSubscription;

    private isInSidePane = false;

    // determine is the video is currently in live mode
    private isLive = false;

    // the PTZ stopped dead zone where the ptz stop moving
    private deadZone = 0.05;

    // represent how far the old notification position to the actual mouse position need to be enough to trigger a new displacement notification
    private threshold = 0.03;

    // determine if the current camera has fish eye support
    private isPanoramic = false;

    // represent the current PTZ velocity applied
    private actualVelocity: Point = { x: 0, y: 0 };

    // debounce the displayed text update to prevent flickering
    private updateDisplayedStateDebounce = debounce(() => {
        this.updateDisplayedState();
    }, 200);

    private readonly errorEvent = new LiteEvent<ErrorStatusEvent>();
    private readonly frameRenderedEvent = new LiteEvent<Date>();
    private readonly streamStatusEvent = new LiteEvent<StreamingConnectionStatusChangeEvent>();
    private readonly playerStateEvent = new LiteEvent<PlayerStateChangeEvent>();
    private readonly playSpeedEvent = new LiteEvent<PlaySpeedChangeEvent>();
    private readonly loopEvent = new LiteEvent<LoopChangeEvent>();
    private readonly audioEvent = new LiteEvent<AudioStateChangeEvent>();
    private readonly audioAvailabilityEvent = new LiteEvent<AudioAvailabilityChangeEvent>();
    private readonly playerModeEvent = new LiteEvent<PlayerModeChangeEvent>();
    private readonly bufferingProgress = new LiteEvent<number>();
    private readonly exportProgress = new LiteEvent<ExportProgressEvent>();
    private readonly timelinePart = new LiteEvent<TimelinePartEvent>();

    public get isAudioAvailable(): boolean {
        return this.videoPlayer?.isAudioAvailable === true;
    }

    public get isAudioEnabled(): boolean {
        return this.videoPlayer?.isAudioEnabled === true;
    }

    public get isStarted(): boolean {
        return this.videoPlayer?.isStarted === true;
    }

    public get lastFrameTime(): Date | undefined {
        return this.videoPlayer?.lastFrameTime;
    }

    public get ptzControl(): IPtzControl | undefined {
        return this.videoPlayer?.ptzControl;
    }

    public get digitalZoomControl(): IDigitalZoomControl | null {
        if (this.videoPlayer?.digitalZoomControl) {
            return this.videoPlayer?.digitalZoomControl;
        }
        return null;
    }

    public get dewarperControl(): IDewarperControl | null {
        if (this.videoPlayer?.dewarperControl) {
            return this.videoPlayer?.dewarperControl;
        }
        return null;
    }

    public get timelineProvider(): ITimelineProvider | undefined {
        return this.videoPlayer?.timelineProvider;
    }

    public get baseUrl(): string | undefined {
        return this.videoPlayer?.baseUrl;
    }

    public get isGhost(): boolean {
        return this.tryGetCameraSubType() === CameraTypes.GhostCamera;
    }

    // flag used to enable logs for more diagnostics
    private enableLog = false;

    // #endregion

    // #region Events

    public get onErrorStateRaised(): ILiteEvent<ErrorStatusEvent> {
        return this.errorEvent.expose();
    }

    public get onFrameRendered(): ILiteEvent<Date> {
        return this.frameRenderedEvent.expose();
    }

    public get onStreamStatusChanged(): ILiteEvent<StreamingConnectionStatusChangeEvent> {
        return this.streamStatusEvent.expose();
    }

    public get onPlayerStateChanged(): ILiteEvent<PlayerStateChangeEvent> {
        return this.playerStateEvent.expose();
    }

    public get onPlaySpeedChanged(): ILiteEvent<PlaySpeedChangeEvent> {
        return this.playSpeedEvent.expose();
    }

    public get onLoopChanged(): ILiteEvent<LoopChangeEvent> {
        return this.loopEvent.expose();
    }

    public get onAudioStateChanged(): ILiteEvent<AudioStateChangeEvent> {
        return this.audioEvent.expose();
    }

    public get onAudioAvailabilityChanged(): ILiteEvent<AudioAvailabilityChangeEvent> {
        return this.audioAvailabilityEvent.expose();
    }

    public get onPlayerModeChanged(): ILiteEvent<PlayerModeChangeEvent> {
        return this.playerModeEvent.expose();
    }

    public get onBufferingProgress(): ILiteEvent<number> {
        return this.bufferingProgress.expose();
    }

    public get onExportProgress(): ILiteEvent<ExportProgressEvent> {
        return this.exportProgress.expose();
    }

    public get onTimelinePart(): ILiteEvent<TimelinePartEvent> {
        return this.timelinePart.expose();
    }

    // #endregion

    // #region Constructor

    constructor(
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private joystickService: JoystickService, // keep reference
        private restService: SecurityCenterClientService, //TODO: Remove injection of SecurityCenterClient
        private translateService: TranslateService,
        private loggerService: LoggerService,
        private videoDiagnosticService: VideoDiagnosticService,
        @Inject(CONTENT_SERVICES_PROVIDER) public contentServicesProvider: ContentExtensionServicesProvider,
        @Inject(COMMANDS_SERVICE) public commandsService: CommandsService,
        trackingService: TrackingService,
        @Inject(WINDOW) private window: Window // applicationRef: ApplicationRef
    ) {
        super(trackingService);

        // Code used to debug Angular's change detections from video
        // if (applicationRef) {
        //     const originalTick = applicationRef.tick;
        //     applicationRef.tick = function() {
        //         const windowPerfomance = this.window.performance;
        //         const  before = this.windowPerfomance.now();
        //         const retValue = originalTick.apply(this, []);
        //         const after = this.windowPerfomance.now();
        //         const runTime = after - before;
        //         this.loggerService.traceInformation('CHANGE DETECTION TIME (VideoPlayer)' , runTime);
        //         return retValue;
        //     };
        // }

        this.videoDiagnosticService.debugOverlayActivated$.pipe(untilDestroyed(this)).subscribe((show) => {
            this.videoPlayer?.showDebugOverlay(show);
        });

        // Retrieve SecurityCenterClient
        this.scClient = this.restService.scClient;
        // Build cacheTask
        this.entityCacheTask = this.scClient.buildEntityCache([CameraEntityFields.lockingUserIdField]);
    }

    // #endregion

    // #region Ng...

    public ngOnInit() {
        super.ngOnInit();

        if (this.commandsService) {
            const bindings = new CommandBindings();
            bindings.addCommand({
                commandId: SharedCommands.PtzStartPanTilt,
                executeCommandHandler: (executeCommandData) => {
                    this.ptzStartPanTilt(executeCommandData);
                },
                isCommandAvailableHandler: () => this.isPtz && this.allowBasicPtz,
            });
            bindings.addCommand({
                commandId: SharedCommands.PtzStopPanTilt,
                executeCommandHandler: (executeCommandData) => {
                    this.ptzStopPanTilt();
                    executeCommandData.isHandled = true;
                },
                isCommandAvailableHandler: () => this.isPtz && this.allowBasicPtz,
            });
            bindings.addCommand({
                commandId: SharedCommands.ToggleListen,
                executeCommandHandler: (executeCommandData) => {
                    this.toggleListen();
                    executeCommandData.isHandled = true;
                },
                isCommandAvailableHandler: () => this.allowAudio,
            });

            this.commandsService.registerCommandShortcut(SharedCommands.MinimizeVideo, 'Esc');

            this.commandsSubscription = this.commandsService.subscribe(bindings, {
                priority: 200,
            });
        }
    }

    public ngAfterViewInit() {
        if (this.content) {
            this.entityContent = this.content;

            // read privileges
            const priv = this.content.grantedPrivileges;
            this.allowBasicPtz = priv.some((item) => item.equals(KnownPrivileges.basicPtzOperationsPrivilege));
            this.allowLiveVideo = priv.some((item) => item.equals(KnownPrivileges.allowVideoPrivilege));
            this.allowPlaybackVideo = priv.some((item) => item.equals(KnownPrivileges.allowPlaybackVideoPrivilege));
            this.allowDigitalZoom = priv.some((item) => item.equals(KnownPrivileges.digitalZoomPrivilege));
            this.allowAudio = priv.some((item) => item.equals(KnownPrivileges.useAudioPrivilege));

            this.contentServicesProvider.setService<IVideoPlayer>(this.entityContent.id, this as IVideoPlayer);

            // IMPORTANT, setup the video player outside Angular to prevent change detection
            this.zone.runOutsideAngular(() => {
                this.videoPlayer = new VideoCorePlayer(this.restService.scClient, this.divElement.nativeElement);
                // retrieve the underlying controls from the video player
                this.videoElement = this.videoPlayer.videoElement;
                this.canvasElement = this.videoPlayer.canvasElement;
                this.audioElement = this.videoPlayer.audioElement;

                // register events
                this.videoPlayer.onAudioAvailabilityChanged.register(this.onAudioAvailabilityChangedHandler);
                this.videoPlayer.onAudioStateChanged.register(this.onAudioStateChangedHandler);
                this.videoPlayer.onErrorStateRaised.register(this.onErrorStateHandler);
                this.videoPlayer.onFrameRendered.register(this.onFrameRenderedHandler);
                this.videoPlayer.onExportProgress.register(this.onExportProgressHandler);
                this.videoPlayer.onPlayerModeChanged.register(this.onPlayerModeChangedHandler);
                this.videoPlayer.onPlaySpeedChanged.register(this.onPlayerSpeedChangedHandler);
                this.videoPlayer.onPlayerStateChanged.register(this.onPlayerStateChangedHandler);
                this.videoPlayer.onLoopChanged.register(this.onLoopChangedHandler);
                this.videoPlayer.onStreamStatusChanged.register(this.onStreamingConnectionStatusHandler);
                this.videoPlayer.onTimelinePart.register(this.onTimelinePartHandler);
            });

            // determine if the camera is a PTZ or not
            const cameraSubType = this.tryGetCameraSubType();
            if (cameraSubType) {
                this.isPtz = cameraSubType === CameraTypes.PTZCamera;
                this.isPanoramic = cameraSubType === CameraTypes.PanoramicCamera;
            }

            // start the player
            this.startPlayerAsync().fireAndForget();
        }
    }

    public ngOnDestroy() {
        try {
            this.entityCacheTask.dispose().fireAndForget();

            if (this.commandsSubscription) {
                this.commandsSubscription.unsubscribe();
            }

            // clearing the following properties will unsubcribe their event listeners
            this.parentContent = undefined;
            this.freelookContent = undefined;

            if (this.wheelTimerId) {
                clearInterval(this.wheelTimerId);
                this.wheelTimerId = undefined;
            }

            if (this.entityContent) {
                this.contentServicesProvider.removeService<IVideoPlayer>(this.entityContent.id);
            }
            if (this.videoPlayer) {
                // unregister events
                this.videoPlayer.onAudioAvailabilityChanged.unregister(this.onAudioAvailabilityChangedHandler);
                this.videoPlayer.onAudioStateChanged.unregister(this.onAudioStateChangedHandler);
                this.videoPlayer.onErrorStateRaised.unregister(this.onErrorStateHandler);
                this.videoPlayer.onFrameRendered.unregister(this.onFrameRenderedHandler);
                this.videoPlayer.onExportProgress.unregister(this.onExportProgressHandler);
                this.videoPlayer.onPlayerModeChanged.unregister(this.onPlayerModeChangedHandler);
                this.videoPlayer.onPlaySpeedChanged.unregister(this.onPlayerSpeedChangedHandler);
                this.videoPlayer.onPlayerStateChanged.unregister(this.onPlayerStateChangedHandler);
                this.videoPlayer.onLoopChanged.unregister(this.onLoopChangedHandler);
                this.videoPlayer.onStreamStatusChanged.unregister(this.onStreamingConnectionStatusHandler);
                this.videoPlayer.onTimelinePart.unregister(this.onTimelinePartHandler);
                if (this.videoPlayer.digitalZoomControl) {
                    this.videoPlayer.digitalZoomControl.onPositionChanged.unregister(this.onDigitalZoomPositionChangedHandler);
                }

                this.videoPlayer.dispose();
            }
            if (this.playerSubscription) {
                this.playerSubscription.unsubscribe();
            }
        } catch (exception) {
            this.loggerService.traceError(`Error occurs when destroying video player component ${(exception as Error).message}`);
        }

        super.ngOnDestroy();
    }

    public setDataContext(context: PluginContext | undefined): void {
        this.dataContext = context;
        if (context) {
            this.isInPopup = context.displayContext === DisplayContext.MapPopup;
            this.isInSidePane = context.displayContext === DisplayContext.Sidepane;
        }
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    //#endregion

    // #region IVideoPlayer

    public addTimelineProviders(providers: Array<IGuid>, add: boolean, entities: Array<IGuid>): Promise<Response> {
        if (this.videoPlayer) {
            return this.videoPlayer.addTimelineProviders(providers, add, entities);
        }
        return Promise.reject();
    }

    public async getExportAsync(exportModel: ExportModel): Promise<Response> {
        if (this.videoPlayer) {
            return await this.videoPlayer.getExportAsync(exportModel);
        }
        return Promise.reject();
    }

    public async getThumbnailAsync(timestamp: Date): Promise<string> {
        if (this.videoPlayer) {
            return await this.videoPlayer.getThumbnailAsync(timestamp);
        }
        return Promise.reject();
    }

    public ptzGoHomePosition(): void {
        // ensure the user has the basic PTZ privilege
        if (this.isPtz && this.allowBasicPtz) {
            this.videoPlayer?.ptzControl?.goHome();
        }
    }

    public ptzGoToPreset(preset: number): void {
        // ensure the user has the basic PTZ privilege
        if (this.isPtz && this.allowBasicPtz) {
            this.videoPlayer?.ptzControl?.goToPreset(preset);
        }
    }

    public seek(seekTime: Date): void {
        this.videoPlayer?.seek(seekTime);
    }

    public playLive(): void {
        this.videoPlayer?.playLive();
    }

    public resume(maintainSpeed: boolean = true): void {
        this.videoPlayer?.resume(maintainSpeed);
    }

    public pause(maintainSpeed: boolean = true): void {
        this.videoPlayer?.pause(maintainSpeed);
    }

    public clearLoop(): void {
        this.videoPlayer?.clearLoop();
    }

    public setLoop(start: Date, end: Date): void {
        this.videoPlayer?.setLoop(start, end);
    }

    public setAudioEnabled(isAudioEnabled: boolean): void {
        this.videoPlayer?.setAudioEnabled(isAudioEnabled);
    }

    public setPlaySpeed(playSpeed: number): void {
        this.videoPlayer?.setPlaySpeed(playSpeed);
    }

    public setPtzMode(ptzMode: boolean): void {
        this.videoPlayer?.setPtzMode(ptzMode);
    }

    public getPlayerState(state: PlayerState): string {
        switch (state) {
            case PlayerState.Buffering:
                return this.translateService.instant('STE_LABEL_PLAYERSTATE_BUFFERING') as string;

            case PlayerState.BeginReached:
                return this.translateService.instant('STE_LABEL_PLAYERSTATE_BEGINREACH') as string;

            case PlayerState.EndReached:
                return this.translateService.instant('STE_LABEL_PLAYERSTATE_ENDREACH') as string;

            case PlayerState.NoVideoSequenceAvailable:
            case PlayerState.AwaitingSynchronizedTime:
                return this.translateService.instant(this.isGhost ? 'STE_ENTITY_SUBTYPE_GHOST_CAMERA' : 'STE_LABEL_PLAYERSTATE_NOVIDEO') as string;

            case PlayerState.WaitingForArchiverData:
                return this.translateService.instant('STE_LABEL_PLAYERSTATE_WAITINGDATA') as string;

            case PlayerState.Starting:
                return this.translateService.instant('STE_LABEL_PLAYERSTATE_STARTING') as string;

            case PlayerState.Stopping:
                return this.translateService.instant('STE_LABEL_PLAYERSTATE_STOPPING') as string;

            case PlayerState.InsufficientSecurityInformation:
                return this.translateService.instant('STE_LABEL_PLAYERSTATE_INSUFFICIENT_SECURITY') as string;

            case PlayerState.Error:
                return this.translateService.instant('STE_LABEL_ERROR') as string;
        }

        return PlayerState[state];
    }

    public takeSnapshotAsync(): Promise<Blob> {
        if (this.videoPlayer) {
            return this.videoPlayer.takeSnapshotAsync();
        }
        return Promise.reject();
    }

    public toggleListen(): void {
        if (this.allowAudio && this.videoPlayer?.isAudioAvailable) {
            this.videoPlayer.setAudioEnabled(!this.videoPlayer.isAudioEnabled);
        }
    }

    // #endregion

    public onMouseEnter(event: MouseEvent): void {
        this.isMouseOver = true;
        this.changeDetectorRef.detectChanges();
    }

    public onMouseLeave(event: MouseEvent): void {
        this.isMouseOver = false;
        this.changeDetectorRef.detectChanges();
    }

    public onMouseDoubleClick(): void {
        if (this.isInSidePane) {
            this.commandsService.executeCommand(SharedCommands.ToggleMaximizeVideo);
        }
    }

    public onPointerDown(event: PointerEvent): void {
        // if the freelook doesn't have focus, give it. on next click, we will process the freelook events.
        if (!this.freelookFocused) {
            this.freelook?.focus();
        } else if (this.supportPtz) {
            if (event.button === 0) {
                // Clear text selection. This fixes a bug where PtzDot drag + selected text would spin
                // the ptz endlessly.
                this.window.getSelection()?.empty();

                this.freeLookIsGripped = true;
                this.freeLookMousecapture = event.pointerId;
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    public onPointerUp(event: PointerEvent): void {
        if (this.isPtz) {
            let handled = false;

            if (this.freeLookIsGripped) {
                this.freeLookIsGripped = false;
                this.stopPtz();
                handled = true;
            }

            // if the click was handled, force a redraw
            if (handled) {
                event.preventDefault();
                event.stopPropagation();
            }

            this.freeLookMousecapture = -1; // release mouse capture
            this.changeDetectorRef.detectChanges();
        }
    }

    public onPointerMove(event: PointerEvent): void {
        // ensure we have focus before controlling the PTZ
        if (this.supportPtz && this.freelookFocused) {
            const velocity = this.getVelocity(event.offsetX, event.offsetY);
            const min = Math.min(Math.abs(velocity.x), Math.abs(velocity.y));
            if (min <= 0.8) {
                this.dotOpacity = (1 - min) / 4;
            } else {
                this.dotOpacity = 0;
            }
            if (this.freeLookIsGripped) {
                // ensure where are not within the dead zone
                if (Math.abs(velocity.x) < this.deadZone && Math.abs(velocity.y) < this.deadZone) {
                    velocity.x = 0;
                    velocity.y = 0;
                }

                // to send notification, the movement need to be greated than the displacement threshold or the movement need to be newly in the deadzone
                if (
                    Math.abs(velocity.x - this.actualVelocity.x) > this.threshold ||
                    Math.abs(velocity.y - this.actualVelocity.y) > this.threshold ||
                    ((this.actualVelocity.x !== 0 || this.actualVelocity.y !== 0) && velocity.x === 0 && velocity.y === 0)
                ) {
                    this.movePtz(velocity);
                    this.changeDetectorRef.detectChanges();
                }
            }
        }
    }

    public onMouseWheel(event: WheelEvent): void {
        // if the user holds Shift, it means he want to do digital zoom
        if (this.supportPtz && !event.shiftKey) {
            const speed = -(event.deltaY / this.unitToMouseWheelDeltaFactor);

            // check whether there has been recent wheel activity or the wheel has changed direction
            if (!this.wheelTimerId) {
                this.setZoomSpeed(speed);

                // start the wheel timer
                clearInterval(this.wheelTimerId);
                this.wheelTimerId = this.window.setInterval(() => this.onTimerWheelActivity(), this.wheelTimerInterval);
            } else {
                // the wheel timer is already running, increase the speed
                this.wheelCumulativeDelta += speed;
                this.isWheelSpeedPending = true;
            }

            event.preventDefault();
            event.stopPropagation();
            this.changeDetectorRef.detectChanges();
        }
    }

    public onParentMouseWheel(event: WheelEvent): void {
        let handled = false;

        if (this.allowDigitalZoom) {
            const dewarperControl = this.dewarperControl;
            if (this.isPanoramic || dewarperControl) {
                if (dewarperControl) {
                    dewarperControl.Preview.Enabled = true;
                    // if dewrapping was off and we are zooming in, reputting on at 0,0
                    if (!dewarperControl.isDewarping && event.deltaY < 0) {
                        dewarperControl.gotoXY(0, 0);
                        handled = true;
                    }
                }
            } else if (!this.supportPtz || event.shiftKey || this.isDigitalZoomEnabled) {
                /* enable the digital if a PTZ operation is not currently possible */

                const digitalZoomControl = this.digitalZoomControl;
                if (digitalZoomControl) {
                    let currentZoom: number;
                    if (event.deltaY > 0) {
                        currentZoom = this.incrementZoom(-0.5, digitalZoomControl.Zoom);
                    } else {
                        currentZoom = this.incrementZoom(0.5, digitalZoomControl.Zoom);
                    }

                    // only apply the new zoom if it has changed
                    if (currentZoom !== digitalZoomControl.Zoom) {
                        const clientSize = this.getClientSize();
                        const x = event.offsetX / clientSize.width;
                        const y = event.offsetY / clientSize.height;
                        digitalZoomControl.zoomWithFocus(x, y, currentZoom);
                        digitalZoomControl.Preview.Enabled = true;
                        handled = true;
                    }
                }
            }
        }

        if (handled) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    public onGotFocus(): void {
        // note that we are focused so that we will enable the freelook
        this.freelookFocused = true;
    }

    public onLostFocus(): void {
        // note that we lost focus so that we will disable the freelook
        this.freelookFocused = false;
    }

    //#region Private methods

    private displayPlayerState(state: PlayerState): boolean {
        switch (state) {
            case PlayerState.Paused:
            case PlayerState.Playing:
            case PlayerState.Rewinding:
                return false;
        }
        return true;
    }

    private getVelocity(x: number, y: number): Point {
        const canvas = this.freelook;
        if (!canvas) return { x: 0, y: 0 };

        const height = canvas.scrollHeight;
        const width = canvas.scrollWidth;

        let xx = (x / width) * 2 - 1;
        let yy = -((y / height) * 2 - 1);

        // constraint value between -1 and 1
        if (xx < -1) {
            xx = -1;
        } else if (xx > 1) {
            xx = 1;
        }

        if (yy < -1) {
            yy = -1;
        } else if (yy > 1) {
            yy = 1;
        }
        return { x: xx, y: yy };
    }

    private getClientSize(): Size {
        const div = this.divElement.nativeElement;
        if (div) {
            return new Size(div.clientWidth, div.clientHeight);
        }
        return new Size(0, 0);
    }

    private incrementZoom(increment: number, currentZoom: number): number {
        currentZoom += increment;
        if (currentZoom < 1) {
            currentZoom = 1;
        } else if (currentZoom > 20) {
            currentZoom = 20;
        }
        return currentZoom;
    }

    private log(msg: string) {
        if (this.enableLog) {
            this.loggerService.traceInformation('VideoPlayer: ' + msg);
        }
    }

    private movePtz(velocity: Point): void {
        if (velocity.x !== 0 && velocity.y !== 0) {
            this.log(`${velocity.x} ${velocity.y}`);
            this.actualVelocity = velocity;
            this.videoPlayer?.ptzControl?.startPanTilt(velocity.x * 100, velocity.y * 100);
        } else {
            this.stopPtz();
        }
    }

    private ptzStartPanTilt(executeCommandData: ExecuteCommandData) {
        const joystickCommandData = JoystickCommandData.extract(executeCommandData);
        if (joystickCommandData) {
            // ensure the user has the basic PTZ privilege
            if (this.isPtz && this.allowBasicPtz) {
                this.videoPlayer?.ptzControl?.startPanTilt(joystickCommandData.velocity.x * 100, joystickCommandData.velocity.y * 100);
            }
        }
    }

    private ptzStopPanTilt() {
        // ensure the user has the basic PTZ privilege
        if (this.isPtz && this.allowBasicPtz) {
            this.videoPlayer?.ptzControl?.stopPanTilt();
        }
    }

    private setZoomSpeed(value: number) {
        let speed = value;
        // constraint the zoom value
        if (speed < -100) {
            speed = -100;
        } else if (speed > 100) {
            speed = 100;
        }

        this.log(`Setting zoom speed to ${speed}%`);
        this.videoPlayer?.ptzControl?.startZoom(speed);
    }

    private stopPtz() {
        this.log('Stop PTZ...');
        this.actualVelocity = { x: 0, y: 0 };
        this.ptzStopPanTilt();
    }

    private stopZoom() {
        this.log('Stop zoom...');
        this.videoPlayer?.ptzControl?.stopZoom();
    }

    private async startPlayerAsync() {
        // uncomment for full blown comments
        // Logger.enable(true);
        if (this.videoPlayer && this.entityContent && this.content) {
            await this.videoPlayer.startAsync(SafeGuid.parse(this.entityContent.source), null);

            // register to more events
            if (this.videoPlayer.digitalZoomControl) {
                this.videoPlayer.digitalZoomControl.onPositionChanged.register(this.onDigitalZoomPositionChangedHandler);
            }

            // verify if a seek time was provided
            let seekTime: Date | undefined;
            if (this.content.parameters && this.allowPlaybackVideo && this.content.parameters.hasField(SharedContentFields.Timestamp)) {
                const seekTimeValue = this.content.parameters.getField<Date>(SharedContentFields.Timestamp);

                // ensure the date format is correct by passing it into moment
                seekTime = moment(seekTimeValue).toDate();
            }
            // ensure the user has the privilege for live video
            if (this.allowLiveVideo && !this.isGhost) {
                this.playLive();
            } else if (this.allowPlaybackVideo && !seekTime) {
                // if we dont have access to live video, inject a 30 seconds delay
                seekTime = moment().subtract(30, 'seconds').utc().toDate();
            }

            // seek to the specified time if needed
            if (seekTime) {
                this.seek(seekTime);
            }

            this.videoPlayer.showDebugOverlay(this.videoDiagnosticService.isDebugOverlayActivated());

            const cameraEntity = await this.entityCacheTask.getEntityAsync<CameraEntity, ICameraEntity>(CameraEntity, SafeGuid.parse(this.entityContent.source));
            if (cameraEntity !== null) {
                this.entityCacheTask
                    .detectEntityChange(cameraEntity, 'lockingUserId')
                    .pipe(untilDestroyed(this))
                    .subscribe((updatedCameraEntity) => this.setPtzMode(!updatedCameraEntity?.lockingUserId.isEmpty()));
            }
        }
    }

    private async getCameraEntityCache(guid: IGuid): Promise<CameraEntity | null> {
        return this.entityCacheTask.getEntityAsync(CameraEntity, guid);
    }

    private updateDisplayedState() {
        const currentState = this.displayedState;

        if (this.errorState) {
            this.displayedState = this.errorState;
        } else if (this.streamingState) {
            this.displayedState = this.streamingState;
        } else if (this.playerState) {
            this.displayedState = this.playerState;
        } else {
            this.displayedState = undefined;
        }

        if (currentState !== this.displayedState) {
            this.changeDetectorRef.detectChanges();
        }
    }

    private updatePtzSupport() {
        this.supportPtz = this.allowBasicPtz && this.isPtz && this.isLive;

        // in case we support PTZ in live mode, ensure we close the digital zoom
        if (this.supportPtz) {
            this.videoPlayer?.digitalZoomControl?.stop();
        }
    }

    private tryGetCameraSubType(): string | null {
        if (this.entityContent?.parameters?.hasField(CameraEntityFields.cameraSubTypeField)) {
            return this.entityContent.parameters.getField<string>(CameraEntityFields.cameraSubTypeField);
        }
        return null;
    }

    // #endregion

    // #region Event Handlers

    private onParentMouseWheelHandler = (event: WheelEvent): void => {
        return this.onParentMouseWheel(event);
    };

    private onMouseWheelHandler = (event: WheelEvent): void => {
        this.onMouseWheel(event);
    };

    private onAudioAvailabilityChangedHandler = (event: AudioAvailabilityChangeEvent): void => {
        this.audioAvailabilityEvent.trigger(event);
    };

    private onAudioStateChangedHandler = (event: AudioStateChangeEvent): void => {
        this.audioEvent.trigger(event);
    };

    private onErrorStateHandler = (event: ErrorStatusEvent): void => {
        let errorState: string | undefined;
        if (event.errorCode) {
            switch (event.errorCode) {
                case ErrorCode.ConnectionLost:
                case ErrorCode.ConnectionFailed:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_CONNECTION_FAILED') as string;
                    break;
                case ErrorCode.InsufficientPrivilege:
                case ErrorCode.InsufficientCapability:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_MISSING_PRIVILEGES') as string;
                    break;
                case ErrorCode.LiveCapacityExceeded:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_LIVE_CAPACITY_EXCEEDED') as string;
                    break;
                case ErrorCode.PlaybackCapacityExceeded:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_PLAYBACK_CAPACITY_EXCEEDED') as string;
                    break;
                case ErrorCode.UnsupportedEncoding:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_UNSUPPORTED_ENCODING') as string;
                    break;
                case ErrorCode.Mp4CodecNotSupported:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_ENCODING_NOT_SUPPORTED') as string;
                    break;
                case ErrorCode.NoTranscodingAllowed:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_ENABLE_SECURE_COMMUNICATION') as string;
                    break;
                case ErrorCode.ForbiddenTransformation:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_FORBIDDEN_TRANSFORMATION') as string;
                    break;
                case ErrorCode.MissingCertificate:
                case ErrorCode.CantUsePrivateKey:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_CERTIFICATE_ERROR') as string;
                    break;
                case ErrorCode.DatabaseUpgradeRequired:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_DB_UPGRADE_REQUIRED') as string;
                    break;
                case ErrorCode.RequestTimedOut:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_REQUEST_TIMEOUT') as string;
                    break;
                case ErrorCode.StreamNotFound:
                    errorState = this.translateService.instant('STE_LABEL_ERROR_STREAM_NOT_FOUND') as string;
                    break;
                default:
                    errorState = `${this.translateService.instant('STE_LABEL_ERROR') as string} (${event.errorCode})`;
                    break;
            }
        }

        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.errorState = errorState;
            this.updateDisplayedStateDebounce();
        });

        this.errorEvent.trigger(event);
    };

    private onFrameRenderedHandler = (frameTime: Date): void => {
        // inform the rendering helper that we received a frame
        RenderingHelper.invalidate();

        this.frameRenderedEvent.trigger(frameTime);
    };

    private onExportProgressHandler = (event: ExportProgressEvent): void => {
        this.exportProgress.trigger(event);
    };

    private onLoopChangedHandler = (event: LoopChangeEvent): void => {
        this.loopEvent.trigger(event);
    };

    private onStreamingConnectionStatusHandler = (event: StreamingConnectionStatusChangeEvent): void => {
        let streamingState: string | undefined;
        let displayVideo = true;

        if (event.state === StreamingConnectionStatus.Blocked) {
            streamingState = this.translateService.instant('STE_LABEL_PLAYERSTATE_BLOCKED') as string;
            displayVideo = false;
        } else if (event.state === StreamingConnectionStatus.CameraNotRunning) {
            streamingState = this.translateService.instant('STE_LABEL_PLAYERSTATE_WAITING_OFFLINE_CAMERA') as string;
        }

        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.streamingState = streamingState;
            this.displayVideo = displayVideo;
            this.updateDisplayedStateDebounce();
        });

        this.streamStatusEvent.trigger(event);
    };

    private onTimelinePartHandler = (event: TimelinePartEvent): void => {
        this.timelinePart.trigger(event);
    };

    private onPlayerModeChangedHandler = (event: PlayerModeChangeEvent): void => {
        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.isLive = event.playerMode === PlayerMode.live;
            this.updatePtzSupport();
        });

        this.playerModeEvent.trigger(event);
    };

    private onPlayerSpeedChangedHandler = (event: PlaySpeedChangeEvent): void => {
        this.playSpeedEvent.trigger(event);
    };

    private onPlayerStateChangedHandler = (event: PlayerStateChangeEvent): void => {
        let playerState: string | undefined;
        if (event.playerState && this.displayPlayerState(event.playerState)) {
            playerState = this.getPlayerState(event.playerState);
        }

        // process updates in Angular's zone
        this.zone.runGuarded(() => {
            this.playerState = playerState;
            this.updatePtzSupport();

            if (event.playerState === PlayerState.NoVideoSequenceAvailable) {
                this.stateBackgroundColor = '#1b1e1f';
            } else if (event.playerState === PlayerState.Playing) {
                this.stateBackgroundColor = undefined;
            }

            // live update here
            this.updateDisplayedState();
        });

        this.playerStateEvent.trigger(event);
    };

    private onDigitalZoomPositionChangedHandler = (): void => {
        // trigger the change detection to restore the free look
        this.changeDetectorRef.detectChanges();
    };

    private onTimerWheelActivity() {
        // if the user increase the zoom speed before the timer tick, apply it
        if (this.isWheelSpeedPending) {
            this.isWheelSpeedPending = false;
            this.setZoomSpeed(this.wheelCumulativeDelta * this.wheelDeltaFactor);
        } else {
            // the wheel has been idle long enough, stop the timer and the zoom
            clearInterval(this.wheelTimerId);
            this.wheelTimerId = undefined;
            this.wheelCumulativeDelta = 0;
            this.stopZoom();
        }
    }

    //#endregion
}
