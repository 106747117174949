import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const AccessControlContentTypes = Constants({
    Door: SafeGuid.parse('096d174f-ba2a-4b8f-992c-749892285ed8'),
    Cardholder: SafeGuid.parse('ab02dcf5-1eda-4289-82d4-1afb72aa87e0'),
    Credential: SafeGuid.parse('2BB85BB5-0DBE-4F0C-BD0B-94EBD536A332'),
    Event: SafeGuid.parse('7fe628e8-668a-4807-9533-71122fefdd12'),
    Reader: SafeGuid.parse('5D5613E4-B0D7-4E95-BA50-9EF0542796AA'),
});
