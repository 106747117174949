import { Constants } from '@shared/utilities/constants';
import { SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2023 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const EntityBrowserRefreshTypes = Constants({
    UnassignedCredentialView: SafeGuid.parse('C6F210F3-6277-44E1-A798-16AD5D2B6DE3'),
});
