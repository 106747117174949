import { Utils } from '../../utils/Utils';

/**
 * @beta
 * */
export enum VideoFormat {
    Mjpeg = 'Mjpeg',
    H264 = 'H264',
    RawH264 = 'RawH264',
}

/**
 * @beta
 * */
export class SupportedVideoFormats {
  private readonly m_supportedVideoFormats: VideoFormat[];

  private m_availableFormats: VideoFormat[];

  constructor(supportedFormats: VideoFormat[]) {
    this.m_supportedVideoFormats = this.m_availableFormats = supportedFormats;
  }

  public static getBrowserSupportedFormats(): SupportedVideoFormats {
    if (Utils.supportsMse()) {
      return new SupportedVideoFormats([VideoFormat.Mjpeg, VideoFormat.H264]);
    } else {
      return new SupportedVideoFormats([VideoFormat.Mjpeg]);
    }
  }

  public setPtzMode(isPtzMode: boolean): void {
    if (isPtzMode) {
      this.m_availableFormats = [VideoFormat.Mjpeg];
    } else {
      this.m_availableFormats = this.m_supportedVideoFormats;
    }
  }

  public getAvailableFormats(): VideoFormat[] {
    return this.m_availableFormats;
  }

  public toString(): string {
    return `Supported Video Formats: [${this.m_supportedVideoFormats.join(', ')}] | Available Video Formats: [${this.m_availableFormats.join(', ')}]`;
  }
}
