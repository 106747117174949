import { Component, ElementRef, Inject, isDevMode, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ButtonFlavor, Icon, PopupPosition, ItemSlot } from '@genetec/gelato';
import { ContextMenuSize, GenMenu, GenPopup, TextFlavor } from '@genetec/gelato-angular';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { ContextMenuFactory } from '@modules/shared/services/context-menu/context-menu.factory';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationsService } from '@modules/shared/services/notifications/notifications.service';
import { UpdaterService } from '@modules/shared/services/updates/updater.service';
import { SendFeedbackService } from '@modules/general/services/send-feedback.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { ContentOverlayService } from '@shared/services/content-overlay/content-overlay.service';
import { NavBarService } from './components/nav-bar/services/nav-bar.service';
import { TrackedComponent } from './modules/shared/components/tracked/tracked.component';
import { SharedCommands } from './modules/shared/enumerations/shared-commands';
import { PluginItem } from './modules/shared/interfaces/plugins/internal/pluginItem';
import { COMMANDS_SERVICE } from './modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes, TraySubSections } from './modules/shared/interfaces/plugins/public/plugin-types';
import { ContextMenuHandler, InternalCommandsService } from './modules/shared/services/commands/commands.service';
import { LanguageService } from './modules/shared/services/language/language.service';
import { PluginService } from './modules/shared/services/plugin/plugin.service';
import { TrackingService } from './modules/shared/services/tracking.service';
import { AuthService } from './security-center/services/authentication/auth.service';
import { TouchEventHandler } from './utilities/touch-event-handler';
import { AdvancedSettingsService } from './modules/shared/services/advanced-settings/advanced-settings.service';
import { ApplicationInsightsFeatureFlags } from './modules/shared/services/analytics/feature-flags';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        // Service instance controlling the global dom portal outlet
        ContentOverlayService,
    ],
})
export class AppComponent extends TrackedComponent implements OnInit, ContextMenuHandler {
    @ViewChild(GenPopup) secondaryTrayPopup!: GenPopup;
    @ViewChild('contextMenu') contextMenu!: GenMenu;

    @Select(FeatureFlagsState.featureFlags(GeneralFeatureFlags.ExpandWidget))
    public isPortalEnabled$!: Observable<boolean>;

    public readonly ContextMenuSize = ContextMenuSize;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly PopupPosition = PopupPosition;
    public readonly TextFlavor = TextFlavor;
    public readonly ItemSlot = ItemSlot;

    public contextMenuItemsSource!: Array<ContextMenuItem>;
    public contextMenuPositionX!: number;
    public contextMenuPositionY!: number;
    public contextMenuReference: ElementRef | undefined = undefined;
    public mainTrayPlugins: PluginItem[] = [];
    public secondaryTrayPlugins: PluginItem[] = [];
    public title = 'app';
    public onContextMenuShow$: Observable<void>;
    public onContextMenuHide$: Observable<void>;
    private onContextMenuHideSubject = new Subject<void>();
    private onContextMenuShowSubject = new Subject<void>();

    // DO NOT REMOVE: We create this instance to manage touch events throughout the entire app, do not remove even if it isn't used elsewhere
    private touchEventHandler!: TouchEventHandler;

    private contextMenuOpen = false;
    public get isContextMenuOpen(): boolean {
        return this.contextMenuOpen;
    }
    public set isContextMenuOpen(value: boolean) {
        this.contextMenuOpen = value;
        if (!this.contextMenuOpen && value) {
            this.onContextMenuShowSubject.next();
        }
    }

    constructor(
        private contextMenuFactory: ContextMenuFactory,
        private pluginService: PluginService,
        public authService: AuthService,
        public languageService: LanguageService,
        trackingService: TrackingService,
        public navBarService: NavBarService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        private analyticsService: AnalyticsService,
        private advancedSettingsService: AdvancedSettingsService,
        public updaterService: UpdaterService,
        public notificationsService: NotificationsService,
        private sendFeedbackService: SendFeedbackService,
        private viewContainerRef: ViewContainerRef
    ) {
        super(trackingService);

        this.onContextMenuShow$ = this.onContextMenuShowSubject.asObservable();
        this.onContextMenuHide$ = this.onContextMenuHideSubject.asObservable();

        commandsService.registerContextMenuHandler(this);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        // DO NOT REMOVE: We create this instance to manage touch events throughout the entire app, do not remove even if it isn't used elsewhere
        this.touchEventHandler = new TouchEventHandler();

        this.sendFeedbackService.setupAppInsightsAsync().fireAndForget();

        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe(async (loggedIn: boolean) => {
            if (loggedIn) {
                const trayPlugins = await this.pluginService.getPlugins(PluginTypes.Tray);
                if (trayPlugins) {
                    this.mainTrayPlugins = trayPlugins.filter((plugin) => !plugin.exposure.subSection || plugin.exposure.subSection.equals(TraySubSections.MainBar));
                    this.secondaryTrayPlugins = trayPlugins.filter((plugin) => plugin.exposure.subSection?.equals(TraySubSections.Secondary));
                }
                if (ApplicationInsightsFeatureFlags.General.isEnabled(this.advancedSettingsService)) {
                    this.analyticsService.setupAppInsightsAsync().fireAndForget();
                }
            } else {
                this.analyticsService.clearAppInsightsData();
            }
        });
    }

    public showContextMenu(mouseEvent: MouseEvent, menuItems?: ContextMenuItem[], reference?: ElementRef): boolean {
        this.hideContextMenu();

        // TODO: Remove last condition (isHidden) when the following issue is resolved.
        // https://tfs.genetec.com/DefaultCollection/PortfolioManagement/_workitems/edit/2697313/
        if (!menuItems || menuItems.length === 0 || menuItems.filter((x) => !x.isHidden || !x.isHidden(x)).length === 0) {
            // in dev mode, let the browser menu appear
            if (isDevMode()) {
                return false;
            }
            mouseEvent.preventDefault();
            return false;
        }

        this.contextMenuFactory
            .showMenuAsync(this.viewContainerRef, menuItems, { x: mouseEvent.x, y: mouseEvent.y })
            .then((contextMenu) => {
                // Using instance's (contextMenu.instance) property does not work for gelato component's,
                // using native custom element's event instead.
                // Fix when we update Gelato version with this fix:
                // Bug 63289: Angular - Cannot manually subscribe to events
                // https://dev.azure.com/GenetecCentral/UXDev/_workitems/edit/63289/
                (contextMenu.location.nativeElement as Element).addEventListener('didClose', () => {
                    this.isContextMenuOpen = false;
                    this.onContextMenuHideSubject.next();
                });
            })
            .fireAndForget();

        this.isContextMenuOpen = true;
        mouseEvent.preventDefault();
        mouseEvent.stopPropagation();
        return true;
    }

    public hideContextMenu(): void {
        if (this.isContextMenuOpen) {
            this.isContextMenuOpen = false;
            this.contextMenuFactory.destroy();
        }
    }

    public onOpenOptionsClicked(): void {
        this.commandsService.executeCommand(SharedCommands.ShowOptions);
    }

    public async onShowSecondaryTrayClicked(): Promise<void> {
        await this.secondaryTrayPopup.toggle();
    }

    public onHamburgerMenuClick(): void {
        this.navBarService.isOpened$.pipe(take(1), untilDestroyed(this)).subscribe((val: boolean) => {
            this.navBarService.setIsOpenedState(!val);
        });
    }
}
