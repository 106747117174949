<ng-container *ngIf="formGroup" [formGroup]="formGroup">
    <div class="cardholder-advanced-params">
        <gen-expander [genText]="'STE_TITLE_CARDHOLDER_ADVANCED_PARAMETERS_SECTION' | translate" appScrollOnExpand scrollContainerSelector=".details-pane__body">
            <gen-expander-content>
                <div class="container">
                    <div class="cardholder-advanced-params__container__binary-choices">
                        <gen-checkbox formControlName="useExtendedGrantTime">
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_USE_EXTENDED_GRANT_TIME' | translate }}</gen-label>
                        </gen-checkbox>
                        <gen-checkbox formControlName="canEscortVisitors">
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_CAN_ESCORT_VISITORS' | translate }}</gen-label>
                        </gen-checkbox>
                    </div>

                    <div class="cardholder-advanced-params__text-fields">
                        <gen-text-input formControlName="displayName" [maxlength]="100" [readonly]="readonly">
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_DISPLAY_NAME' | translate }}</gen-label>
                        </gen-text-input>
                        <gen-text-input formControlName="description" [maxlength]="100" [readonly]="readonly">
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_DESCRIPTION' | translate }}</gen-label>
                        </gen-text-input>
                        <gen-numeric-input formControlName="logicalId" min="1" max="9999999" [readonly]="readonly">
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_LOGICAL_ID' | translate }}</gen-label>
                            <gen-message severity="error" [appFormControlError]="formGroup.controls.logicalId"></gen-message>
                        </gen-numeric-input>
                    </div>

                    <div class="cardholder-advanced-params__antipassback-exemption">
                        <gen-label>{{ 'STE_LABEL_CARDHOLDER_BYPASS_ANTIPASSBACK_RULES' | translate }}</gen-label>
                        <gen-radio
                            name="bar-radio"
                            formControlName="bypassAntipassbackRules"
                            [value]="BypassAntipassbackRules.Inherited"
                            [checked]="formGroup.value.bypassAntipassbackRules === BypassAntipassbackRules.Inherited"
                            (change)="onPassbackModeChanged($event)"
                        >
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_BYPASS_ANTIPASSBACK_RULES_INHERITED' | translate }}</gen-label>
                        </gen-radio>
                        <gen-radio
                            name="bar-radio"
                            formControlName="bypassAntipassbackRules"
                            [value]="BypassAntipassbackRules.Yes"
                            [checked]="formGroup.value.bypassAntipassbackRules === BypassAntipassbackRules.Yes"
                            (change)="onPassbackModeChanged($event)"
                        >
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_BYPASS_ANTIPASSBACK_RULES_YES' | translate }}</gen-label>
                        </gen-radio>
                        <gen-radio
                            name="bar-radio"
                            formControlName="bypassAntipassbackRules"
                            [value]="BypassAntipassbackRules.No"
                            [checked]="formGroup.value.bypassAntipassbackRules === BypassAntipassbackRules.No"
                            (change)="onPassbackModeChanged($event)"
                            [disabled]="true"
                        >
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_BYPASS_ANTIPASSBACK_RULES_NO' | translate }}</gen-label>
                        </gen-radio>
                    </div>

                    <div class="cardholder-advanced-params__security-clearance">
                        <gen-label>{{ 'STE_LABEL_CARDHOLDER_SECURITY_CLEARANCE' | translate }}</gen-label>

                        <gen-radio
                            name="isSecurityClearanceInherited"
                            formControlName="isSecurityClearanceInheritedFromGroup"
                            (change)="onClearanceModeChanged($event)"
                            [value]="'true'"
                            [checked]="formGroup.value.isSecurityClearanceInheritedFromGroup === 'true'"
                            [disabled]="true"
                        >
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_SECURITY_CLEARANCE_INHERITED' | translate }}</gen-label>
                        </gen-radio>
                        <gen-radio
                            name="isSecurityClearanceInherited"
                            formControlName="isSecurityClearanceInheritedFromGroup"
                            (change)="onClearanceModeChanged($event)"
                            [value]="'false'"
                            [checked]="formGroup.value.isSecurityClearanceInheritedFromGroup === 'false'"
                            [disabled]="true"
                        >
                            <gen-label>{{ 'STE_LABEL_CARDHOLDER_SECURITY_CLEARANCE_CUSTOM' | translate }}</gen-label>
                        </gen-radio>
                        <gen-numeric-input
                            *ngIf="this.formGroup.value.isSecurityClearanceInheritedFromGroup !== 'true'"
                            class="security-clearance-input"
                            min="0"
                            max="7"
                            formControlName="securityClearance"
                            [readonly]="readonly"
                        ></gen-numeric-input>
                    </div>
                    <div class="cardholder-advanced-params__partitions">
                        <app-cardholder-relations-sub-form
                            [headerTitle]="'STE_LABEL_CARDHOLDER_PARTITIONS' | translate"
                            [modalTitle]="'STE_TITLE_CARDHOLDER_ADD_PARTITION' | translate"
                            [emptyMessage]="'STE_LABEL_NOPARTITIONS' | translate"
                            [entityType]="CardholderRelationType.Partitions"
                            [formReset$]="formReset$"
                            formControlName="partitions"
                            [showDelete]="false"
                            [showAdd]="false"
                            [showHeader]="false"
                            [showDescriptionColumn]="false"
                            [readonly]="readonly"
                        >
                        </app-cardholder-relations-sub-form>
                    </div>
                </div>
            </gen-expander-content>
        </gen-expander>
    </div>
</ng-container>
