/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import moment from 'moment';
import { Entity } from 'RestClient/Client/Model/Entity';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { DisplayCategory, ExtendedSearchParameterType, LightCorrelationSearchParameter, LightCorrelationSupportedSearchParameter, ParameterComparison } from '../../api/api';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class CorrelationSearchParameter {
    //#region Properties

    public get id(): string {
        return this.FieldName + '||' + this.ValueType + '||' + this.Value;
    }

    public FieldName!: string;
    public ValueType!: ExtendedSearchParameterType;
    public Comparison!: ParameterComparison;
    public Value!: string;
    public DisplayName!: string;
    public DisplayText = '';

    public get isMultiselect(): boolean {
        return this.Comparison === ParameterComparison.In || this.Comparison === ParameterComparison.NotIn;
    }

    //#endregion

    //#region Constructors

    constructor() {
        this.FieldName = '';
        this.ValueType = ExtendedSearchParameterType.String;
        this.Comparison = ParameterComparison.Equal;
        this.Value = '';
        this.DisplayName = this.FieldName;
    }

    //#endregion

    //#region Methods

    public static parseId(id: string): { fieldName: string; valueType: string; value: string } | undefined {
        if (id) {
            const parts = id.split('||');
            if (parts.length >= 3) {
                const last = id.indexOf('||', 2);
                if (last > 0) {
                    return {
                        fieldName: parts[0],
                        valueType: parts[1],
                        value: id.substring(last),
                    };
                }
            }
        }
        return undefined;
    }

    public static getDefaultValue(valueType: ExtendedSearchParameterType): string {
        switch (valueType) {
            case ExtendedSearchParameterType.DateTime:
                return moment().utc().toISOString();
            case ExtendedSearchParameterType.Bool:
                return 'false';
            case ExtendedSearchParameterType.Int:
            case ExtendedSearchParameterType.Long:
                return '0';
            case ExtendedSearchParameterType.Double:
                return '0.0';
            case ExtendedSearchParameterType.Entity:
                return SafeGuid.EMPTY.toString();
            case ExtendedSearchParameterType.Categorical:
                return '';
        }
        return '';
    }

    public static localizeComparison(translateService: TranslateService, comparison: ParameterComparison): string {
        switch (comparison) {
            case ParameterComparison.GreaterThan:
                return '>';
            case ParameterComparison.GreaterThanOrEqualTo:
                return '>=';
            case ParameterComparison.LessThan:
                return '<';
            case ParameterComparison.LessThanOrEqualTo:
                return '<=';
            case ParameterComparison.Equal:
                return translateService.instant('STE_LABEL_OPERATORTYPE_EQUALS') as string;
            case ParameterComparison.NotEqual:
                return translateService.instant('STE_LABEL_OPERATORTYPE_DOESNOTEQUAL') as string;
            case ParameterComparison.Like:
                return translateService.instant('STE_LABEL_OPERATORTYPE_CONTAINS') as string;
            case ParameterComparison.In:
                return translateService.instant('STE_LABEL_OPERATORTYPE_IN') as string;
            case ParameterComparison.NotIn:
                return translateService.instant('STE_LABEL_OPERATORTYPE_NOTIN') as string;
            case ParameterComparison.Regex:
                return translateService.instant('STE_LABEL_OPERATORTYPE_REGULAREXPRESSION') as string;
        }
    }

    public static getDisplayTextFromComparison(parameter: CorrelationSearchParameter, text: string): string {
        if (parameter) {
            if (parameter.Comparison === ParameterComparison.In || parameter.Comparison === ParameterComparison.NotIn) {
                return '{' + text + '}';
            }
        }
        return text;
    }

    public static async setDisplayText(
        parameter: CorrelationSearchParameter,
        translateService: TranslateService,
        securityCenterClientService: SecurityCenterClientService,
        supported: LightCorrelationSupportedSearchParameter | undefined
    ): Promise<void> {
        if (parameter) {
            // base value
            let text =
                parameter.DisplayName +
                ' ' +
                CorrelationSearchParameter.localizeComparison(translateService, parameter.Comparison) +
                " '" +
                parameter.getDisplayTextFromComparison(parameter.Value) +
                "'";

            if (parameter.ValueType === ExtendedSearchParameterType.Entity) {
                const scClient = securityCenterClientService?.scClient;
                if (scClient) {
                    const guids = parameter.Value.split(',')
                        // eslint-disable-next-line @typescript-eslint/unbound-method
                        .map(SafeGuid.tryParse)
                        .filter((guid) => guid.success)
                        .map((guid) => guid.value);
                    if (guids && guids.length > 0) {
                        const query = new EntityQuery();
                        query.fields.add(EntityFields.nameField);
                        for (const guid of guids) {
                            query.guids.add(guid);
                        }

                        const entities = await scClient.getEntitiesAsync(Entity, query, null, null);
                        if (entities) {
                            text =
                                parameter.DisplayName +
                                ' ' +
                                CorrelationSearchParameter.localizeComparison(translateService, parameter.Comparison) +
                                " '" +
                                parameter.getDisplayTextFromComparison(
                                    entities
                                        .map((e) => e.name)
                                        .filter((e) => e)
                                        .join(',')
                                ) +
                                "'";
                        }
                    }
                }
            } else if (parameter.ValueType === ExtendedSearchParameterType.Categorical) {
                // map the categories to their supported display names for the UI
                if (supported) {
                    const lst: string[] = [];
                    const parts = parameter.Value.split(',');
                    for (const part of parts) {
                        // find the field & push the proper text
                        const field: DisplayCategory | undefined = supported.categories?.find((cat) => cat.id === part);
                        if (field) {
                            lst.push(field.text);
                        } else {
                            lst.push(part); // push the raw since we couldn't find anything
                        }
                    }

                    text =
                        parameter.DisplayName +
                        ' ' +
                        CorrelationSearchParameter.localizeComparison(translateService, parameter.Comparison) +
                        " '" +
                        parameter.getDisplayTextFromComparison(lst.join(',')) +
                        "'";
                }
            }

            parameter.DisplayText = text;
        }
    }

    public static ofLightParameter(parameter: LightCorrelationSearchParameter): CorrelationSearchParameter {
        const result = new CorrelationSearchParameter();
        result.Value = parameter.value ?? '';
        result.Comparison = parameter.comparison;
        result.DisplayName = parameter.fieldName;
        result.ValueType = parameter.valueType;
        result.FieldName = parameter.fieldName;
        return result;
    }

    // begin instance methods

    public async setDisplayText(
        translateService: TranslateService,
        securityCenterClientService: SecurityCenterClientService,
        supported: LightCorrelationSupportedSearchParameter | undefined
    ): Promise<void> {
        await CorrelationSearchParameter.setDisplayText(this, translateService, securityCenterClientService, supported);
    }

    public async setDisplayTextAuto(injector: Injector, supported: LightCorrelationSupportedSearchParameter | undefined): Promise<void> {
        const translateService = injector.get(TranslateService);
        const securityCenterClientService = injector.get(SecurityCenterClientService);
        if (translateService && securityCenterClientService) {
            await this.setDisplayText(translateService, securityCenterClientService, supported);
        }
    }

    public getDisplayTextFromComparison(text: string): string {
        return CorrelationSearchParameter.getDisplayTextFromComparison(this, text);
    }

    public resetValue(): void {
        this.Value = CorrelationSearchParameter.getDefaultValue(this.ValueType);
    }

    public setValueString(value: object | boolean | number): void {
        this.resetValue();
        if (this.ValueType && value != null) {
            switch (this.ValueType) {
                case ExtendedSearchParameterType.DateTime:
                    {
                        const mmt = moment(value as moment.MomentInput);
                        if (mmt) {
                            this.Value = mmt.utc().toISOString();
                        }
                    }
                    break;
                case ExtendedSearchParameterType.Bool:
                    {
                        const b = value as boolean;
                        if (b) {
                            this.Value = 'true';
                        } else {
                            this.Value = 'false';
                        }
                    }
                    break;
                case ExtendedSearchParameterType.Int:
                case ExtendedSearchParameterType.Long:
                case ExtendedSearchParameterType.Double:
                    {
                        const num = value as number;
                        if (num) {
                            this.Value = num.toString();
                        }
                    }
                    break;
                case ExtendedSearchParameterType.Entity:
                    {
                        if (this.Comparison === 'In' || this.Comparison === 'NotIn') {
                            if (typeof value === 'object') {
                                if (value instanceof Array) {
                                    const guids = value as IGuid[];
                                    if (guids?.length > 0) {
                                        this.Value = guids.map((guid) => guid.toString()).join(',');
                                    }
                                } else if (value?.toString && typeof value.toString === 'function') {
                                    this.Value = value.toString();
                                }
                            }
                        } else {
                            if (typeof value === 'object') {
                                if (value instanceof Array) {
                                    const arr = value as IGuid[];
                                    if (arr?.length > 0) {
                                        this.Value = arr[0].toString();
                                    } else {
                                        this.Value = SafeGuid.EMPTY.toString();
                                    }
                                } else if (value?.toString && typeof value.toString === 'function') {
                                    this.Value = value.toString();
                                }
                            } else {
                                this.Value = SafeGuid.EMPTY.toString();
                            }
                        }
                    }
                    break;
                case ExtendedSearchParameterType.Categorical:
                    if (typeof value === 'object' && value instanceof Array) {
                        this.Value = value.join(',');
                    } else {
                        if (value?.toString && typeof value.toString === 'function') {
                            this.Value = value.toString();
                        }
                    }
                    break;
                default:
                    if (value?.toString && typeof value.toString === 'function') {
                        this.Value = value.toString();
                    }
                    break;
            }
        }
    }

    public getFormattedValue(): string | boolean | number | IGuid | string[] | IGuid[] {
        let value: string | boolean | number | IGuid | string[] | IGuid[] = '';
        if (this.ValueType && this.Value != null) {
            switch (this.ValueType) {
                case ExtendedSearchParameterType.DateTime:
                    {
                        const mmt = moment(this.Value);
                        if (mmt) {
                            value = mmt.local().toISOString();
                        }
                    }
                    break;
                case ExtendedSearchParameterType.Bool:
                    if (this.Value.toLowerCase() === 'true') {
                        value = true;
                    } else {
                        value = false;
                    }
                    break;
                case ExtendedSearchParameterType.Int:
                case ExtendedSearchParameterType.Long:
                    value = parseInt(this.Value);
                    break;
                case ExtendedSearchParameterType.Double:
                    value = parseFloat(this.Value);
                    break;
                case ExtendedSearchParameterType.Entity:
                    if (this.Comparison === 'In' || this.Comparison === 'NotIn') {
                        const parts = this.Value.split(',').map(SafeGuid.tryParse);
                        if (parts.every((a) => a.success)) {
                            value = parts.map((a) => a.value);
                        }
                    } else {
                        if (SafeGuid.isGuid(this.Value)) {
                            value = SafeGuid.parse(this.Value);
                        }
                    }
                    break;
                case ExtendedSearchParameterType.Region:
                    value = this.Value;
                    break;
                case ExtendedSearchParameterType.Categorical:
                    if (this.Comparison === 'In' || this.Comparison === 'NotIn') {
                        const parts = this.Value.split(',').map((item) => item.replace('"', ''));
                        if (parts) {
                            value = parts;
                        }
                    } else {
                        value = this.Value.toString();
                    }
                    break;
                default:
                    value = this.Value.toString();
                    break;
            }
        }
        return value;
    }

    public toLightParameter(): LightCorrelationSearchParameter {
        const parameter = new LightCorrelationSearchParameter();
        parameter.fieldName = this.FieldName;
        parameter.value = this.Value;
        parameter.valueType = this.ValueType;
        parameter.comparison = this.Comparison;
        return parameter;
    }

    //#endregion
}
