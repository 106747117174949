/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

/*************************************************************************************
Source code taken from https://github.com/ngxs-labs/immer-adapter
*************************************************************************************/
import { StateContext } from '@ngxs/store';
import { ImmutableStateContext } from '../common/immutable-state-context';

export function ImmutableContext(): Function {
    return function (_target: Object, _key: string, descriptor: PropertyDescriptor): PropertyDescriptor {
        const method = descriptor.value;

        descriptor.value = function (ctx: StateContext<any>, action: any, ...args: any[]) {
            return method.apply(this, [new ImmutableStateContext(ctx), action, ...args]);
        };

        return descriptor;
    };
}
