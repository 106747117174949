// Autogenerated: v2/help/Classes/GeocodeReportResult?codegen=ts&InterfaceOnly=False&workflow=False on 2021-02-02 1:12:11 PM
import { ReportInstanceResult, ReportResult } from './ReportResult';
import { IReportInstanceResult } from '../Interface/IReportResult';
import { IGeocodeReportResult, IGeocodingResult } from '../Interface/IGeocodeReportResult';

// #region Inner classes

export class GeocodingResult extends ReportInstanceResult implements IGeocodingResult {
    public address = '';
    public x = 0;
    public y = 0;
    public isUnknown = false;
    public streetNumber = '';
    public locality = '';
    public adminstrativeArea2 = '';
    public administrativeArea1 = '';
    public country = '';
    public postalCode = '';
    public confidenceScore = 0;
    public displayAddress = '';
}

// #endregion Inner classes

export class GeocodeReportResult extends ReportResult implements IGeocodeReportResult {
    // #region Properties

    public geocodingResults = new Array<IGeocodingResult>();

    // #endregion Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public getResults(): Array<IReportInstanceResult> {
        return this.geocodingResults;
    }

    protected parseSpecificResponse(body: { [k: string]: any }): void {
        const rowResult = super.getRows(body);
        this.geocodingResults = new Array<IGeocodingResult>();
        for (const row of rowResult) {
            const ins = new GeocodingResult();
            ins.address = this.getSafeValue<string>('string', row[0]);
            ins.x = this.getSafeValue<number>('number', row[1]);
            ins.y = this.getSafeValue<number>('number', row[2]);
            ins.isUnknown = this.getSafeValue<boolean>('boolean', row[3]);
            ins.streetNumber = this.getSafeValue<string>('string', row[4]);
            ins.locality = this.getSafeValue<string>('string', row[5]);
            ins.adminstrativeArea2 = this.getSafeValue<string>('string', row[6]);
            ins.administrativeArea1 = this.getSafeValue<string>('string', row[7]);
            ins.country = this.getSafeValue<string>('string', row[8]);
            ins.postalCode = this.getSafeValue<string>('string', row[9]);
            ins.confidenceScore = this.getSafeValue<number>('number', row[10]);
            ins.displayAddress = this.getSafeValue<string>('string', row[11]);
            this.geocodingResults.push(ins);
        }
    }

    // #endregion Methods

}

