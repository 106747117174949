// Autogenerated: CodeGen/HierarchicalFieldObject?language=ts&InterfaceOnly=True on 2020-07-14 7:45:31 AM
// Autogenerated: CodeGen/HierarchicalFieldObject?language=ts&InterfaceOnly=False on 2020-07-14 7:45:31 AM

import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { FieldObjectOverloads } from 'RestClient/Helpers/FieldObjectOverloads';

export interface IHierarchicalFieldObject extends IFieldObject {
    overloadTypeName: string;
}

export class HierarchicalFieldObject extends FieldObject implements IHierarchicalFieldObject {
    // OverloadTypeName
    public get overloadTypeName(): string {
        return this.getField<string>('OverloadTypeName');
    }
    public set overloadTypeName(value: string) {
        this.setField<string>('OverloadTypeName', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('OverloadTypeName', '');
    }

    // #region Custom Methods

    constructor() {
        super();
        this.overloadTypeName = 'HierarchicalFieldObject';
    }

    public getOverload<T extends HierarchicalFieldObject>(): T {
        if (this.overloadTypeName && this.overloadTypeName !== 'HierarchicalFieldObject') {
            const constructor = FieldObjectOverloads.getConstructor(this.overloadTypeName);
            if (constructor) {
                const overload = new constructor();
                overload.loadFrom(this);
                return overload as T;
            }
        }

        return (this as unknown) as T;
    }

    //#endregion Custom Methods
}
