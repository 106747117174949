import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ButtonFlavor, Icon } from '@genetec/gelato';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { EventMonitoringFeatureFlags } from '@modules/shared/services/events/feature-flags';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { UserWatchlistRequestService } from '@modules/shared/services/watchlist/user-watchlist-request.service';
import { EventsMonitoringService } from '@modules/general/services/Events/events-monitoring.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-watchlist-tray',
    templateUrl: './watchlist-tray.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalPluginDescriptor({
    type: WatchlistTrayComponent,
    pluginTypes: [PluginTypes.Tray],
    exposure: { id: WatchlistTrayComponent.pluginId },
    isSupported: () => true,
    requirements: { enabledFeatureFlags: [EventMonitoringFeatureFlags.General] },
})
export class WatchlistTrayComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('4321759f-e237-43b9-a35c-ed8cbf3ba22a');
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public missedEvents = 0;
    public maxEventsCount = 0;

    constructor(
        trackingService: TrackingService,
        private eventsMonitoringService: EventsMonitoringService,
        private userWatchlistRequestService: UserWatchlistRequestService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(trackingService);
        this.maxEventsCount = this.eventsMonitoringService.MAX_EVENTS_COUNT;
    }

    public ngOnInit() {
        this.eventsMonitoringService.missedEventsCount$.pipe(untilDestroyed(this)).subscribe((missedEventsCount) => {
            this.missedEvents = missedEventsCount;

            // Tell Angular our component has been modified.
            // showWatchList() indirectly triggers a new missedEventsCount$ value after our lifecycle, and the UI has to be updated to re-render.
            this.changeDetectorRef.detectChanges();
        });
    }

    /**
     * Fetch and open the watch list in the side pane if closed; else close the side pane
     */
    public async showWatchList(): Promise<void> {
        await this.userWatchlistRequestService.toggleShowWatchListAsync();
    }
}
