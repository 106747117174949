type predicate<T> = (arg: T) => boolean;

export class FilterSet<T> {
  private _array = new Array<T>();
  public [Symbol.toStringTag] = "FilterSet";
  public get size(): number {
    return this._array.length;
  }

  constructor() {
  }

  public add(value: T): this {
    this._array.push(value);
    return this;
  }

  clear(): void {
    this._array.length = 0;
  }

  delete(value: T): boolean {
    const i = this._array.indexOf(value);
    if (i >= 0) {
        // JDT - We should use splice()
        delete this._array[i];
        return true;
    }
    return false;
  }

  forEach(callbackfn: (value: T) => void): void {
    for ( let i = 0 ; i < this._array.length; ++i ) {
      callbackfn(this._array[i]);
    }
  }

  *[Symbol.iterator]() {
    for (const i of this._array) {
        yield i;
    }
  }

  has(value: T): boolean {
    const i = this._array.indexOf(value);
    if (i >= 0) {
        return true;
    }
    return false;
  }

  values() {
    return this._array;
  }

  public first(pred?: predicate<T>): T {
    for (const elem of this._array) {
      if (pred) {
        if (pred(elem)) {
          return elem;
        }
      } else {
        return elem;
      }
    }
    throw new Error("Can't find element");
  }

  public firstOrDefault(pred?: predicate<T>): T | null {
    for (const elem of this._array) {
      if (pred) {
        if (pred(elem)) {
          return elem;
        }
      } else {
        return elem;
      }
    }
    return null;
  }

  public where(pred: predicate<T>): Set<T> {
    const result = new Set<T>();
    for (const value of this._array) {
      if (pred(value)) {
        result.add(value);
      }
    }
    return result;
  }

  public any(pred: predicate<T>): boolean {
    for (const elem of this._array) {
      if (pred(elem)) {
        return true;
      }
    }
    return false;
  }
}

export class FilterMap<U, V> extends Map<U, V> {
  constructor() {
    super();
  }

  public where(pred: predicate<V>): Set<[U, V]> {
    const result = new Set<[U, V]>();
    for (const [key, value] of this) {
      if (pred(value)) {
        result.add([key, value]);
      }
    }
    return result;
  }
}
