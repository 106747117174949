import { BehaviorSubject, Observable } from 'rxjs';
import { PluginItem } from '../../interfaces/plugins/internal/pluginItem';
import { MultiPlugin } from './multi-plugin';
import { VerticalPlugin } from './vertical-plugin';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export class PluginsStack {
    /**
     *  [] = Vertical
     *  < > = Navigation
     *  () = Multi
     *
     *  Example of verticalPlugins:
     *  [
     *      < ( Video Player ) ( Video Control ) ( Video Expander ) >
     *      < ( Video Player ) ( Video Control ) ( Video Expander ) >
     *  ]
     *  [ < ( Latest Events Widget ) > ]
     */
    public verticalPlugins?: VerticalPlugin[];
    public verticalPlugins$: Observable<VerticalPlugin[] | undefined>;

    private verticalPluginsSubject: BehaviorSubject<VerticalPlugin[] | undefined> = new BehaviorSubject(this.verticalPlugins);

    constructor() {
        this.verticalPlugins$ = this.verticalPluginsSubject.asObservable();
    }

    public empty(): void {
        if (this.verticalPlugins) {
            this.verticalPlugins.length = 0;
            this.verticalPluginsSubject.next(this.verticalPlugins);
        }
    }

    public fill(plugins: PluginItem[]): void {
        if (!this.verticalPlugins) this.verticalPlugins = [];

        // Compute navigable parent plugins (no parent)
        // e.g.:
        // [p1]<->[p2]
        // [p3]<->[p4]
        // [p5]
        for (const plugin of plugins) {
            if (!plugin.exposure.requiredParentPlugin) {
                // Look for the left most sibling of the same exposure id in the vertical list
                const leftSiblingGroup = this.verticalPlugins.find((p) => p.navigationPlugins[0].parentPlugin.exposure.id.equals(plugin.exposure.id));
                const newNavigationPlugin: MultiPlugin = {
                    parentPlugin: plugin,
                    childPlugins: [],
                };
                if (leftSiblingGroup && plugin.exposure.supportsCarousel) {
                    leftSiblingGroup.navigationPlugins.push(newNavigationPlugin);
                } else {
                    // Create the horizontal group if no left sibling is found or does not support carousel
                    this.verticalPlugins.push({ navigationPlugins: [newNavigationPlugin] });
                }
            }
        }

        // Stacked plugins (no parent and left sibling of the same exposure id)
        // e.g.:
        // [p1]<->[p2]
        // [p3/p6]<->[p4/p7]
        // [p5]
        for (const plugin of plugins.filter((p) => p.exposure.requiredParentPlugin)) {
            // Loop through all vertical to find one with the same exposure id as required parent
            const parentVerticals = this.verticalPlugins.find((p) => p.navigationPlugins[0].parentPlugin.exposure.id.equals(plugin.exposure.requiredParentPlugin));
            if (!parentVerticals) {
                throw new Error(
                    'Parent plugin not found with child :\n' +
                        JSON.stringify({
                            parentExposure: plugin.exposure.requiredParentPlugin,
                            childExposure: plugin.exposure.id,
                        })
                );
            }
            // Loop through all horizontal to find one with the same exposure id as the plugin
            for (const potentialParent of parentVerticals.navigationPlugins) {
                // If a plugin of the same exposure id is not already in the stack, add it
                if (!potentialParent.childPlugins.some((p) => p.exposure.id.equals(plugin.exposure.id))) {
                    potentialParent.childPlugins.push(plugin);
                    break;
                }
            }
        }

        this.verticalPluginsSubject.next(this.verticalPlugins);
    }

    /**
     * Replace the plugin stack, clear it and refilling it with<t he provided plugins.
     * @param plugins - The new plugins to replace the stack with
     */
    public set(plugins: PluginItem[]): void {
        if (this.verticalPlugins) {
            this.verticalPlugins.length = 0;
        }
        this.fill(plugins);
    }
}
