import { IGuid, SafeGuid } from 'safeguid';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Entity } from 'RestClient/Client/Model/Entity';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { TileLayoutEntity } from 'RestClient/Client/Model/TileLayoutEntity';
import { ITilePattern } from 'RestClient/Client/Interface/ITileLayoutEntity';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { EntityBrowserItem } from '../../shared/components/entity-browser/items/entity-browser-item';
import { ContentProviderService } from '../../shared/services/content/content-provider.service';
import { ContentSerializer } from '../../shared/utilities/content.serializer';
import { ContentGroup } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { TilesConstants } from '../enumerations/tiles-constants';
import { DragDropTypes } from '../../shared/enumerations/drag-drop-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export enum DragDropEffect {
    None = 'none',
    Copy = 'copy',
    Link = 'link',
    Move = 'move',
}

export interface TileDragDropData {
    // tile pattern to apply
    tilePattern: ITilePattern | undefined;

    // contents to apply
    contents: ContentGroup[] | null;
}

export enum DragTarget {
    None = 1,
    Tile,
    TilesTask,
}

export class DragDropHelper {
    private static dragData = new Map<string, any>();
    private static dropEffect = DragDropEffect.None;

    public static async extractTileDragDropData(
        event: DragEvent,
        securityCenterProvider: SecurityCenterClientService,
        contentProviderService: ContentProviderService
    ): Promise<TileDragDropData | null> {
        let tileContents: ContentGroup[] | null = null;
        let tilePattern: ITilePattern | undefined;

        // try to extract external data first
        let xml = this.dragData.get('text/plain') as string;
        if (!xml && event?.dataTransfer) {
            // no xml is found in the buffered data, try to extract it from th event (from Security Desk)
            xml = event.dataTransfer.getData('text/plain');
        }

        if (xml) {
            tileContents = await DragDropHelper.extractSecurityCenterDragContent(xml, contentProviderService);
        } else {
            const contentGroupJson = this.dragData.get('sc-content') as string;
            if (contentGroupJson) {
                const content = ContentSerializer.deserializeContentGroup(contentGroupJson);
                if (content) {
                    content.id = SafeGuid.newGuid();
                    content.mainContent.id = SafeGuid.newGuid();
                    tileContents = [content];
                }
            } else {
                const entityIds = this.dragData.get(DragDropTypes.EntityId) as IGuid[];
                if (entityIds) {
                    tileContents = [];

                    const scClient = securityCenterProvider.scClient;

                    if (scClient) {
                        const entityQuery = new EntityQuery();
                        entityIds.forEach((entity) => entityQuery.guids.add(entity));
                        const entities = await scClient.getEntitiesAsync<Entity, IEntity>(Entity, entityQuery);
                        for (const entity of entities) {
                            // special case to handle when we drag a tile layout
                            if (entity?.entityType === EntityTypes.TileLayouts) {
                                // if the list contains a TileLayout entity, we override all the rest
                                tileContents.length = 0;

                                const tileLayout = entity as TileLayoutEntity;
                                const content = await tileLayout.getContentAsync();
                                if (content !== null) {
                                    tilePattern = content.pattern;
                                    const contents = await contentProviderService.getContentsAsync(Array.from(content.entities), TilesConstants.TilesContentContextId);
                                    if (contents) {
                                        tileContents = contents;
                                    }
                                }
                                break;
                            }
                        }
                    }

                    // not built from a tile layout, get the contents from the ids specified
                    if (tileContents.length === 0) {
                        const contentGroups = await contentProviderService.getContentsAsync(entityIds, TilesConstants.TilesContentContextId);
                        if (contentGroups) {
                            tileContents = contentGroups;
                        }
                    }
                }
            }
        }

        let result: TileDragDropData | null = null;
        if (tileContents) {
            result = { contents: tileContents, tilePattern };
        }
        return result;
    }

    // extract the public xml from Security Center's application
    public static async extractSecurityCenterDragContent(xml: string, contentProviderService: ContentProviderService): Promise<ContentGroup[] | null> {
        let tileContents: ContentGroup[] | null = null;

        if (xml) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xml, 'text/xml');
            const contents = xmlDoc.getElementsByTagName('Contents');
            if (contents) {
                if (contents.length === 1) {
                    const children = contents[0].children;
                    tileContents = [];

                    for (const child of Array.from(children)) {
                        const cameraIdString = child.getAttribute('Camera');
                        if (cameraIdString && SafeGuid.isGuid(cameraIdString)) {
                            const cameraId = SafeGuid.parse(cameraIdString);
                            const contentGroup = await contentProviderService.getContentAsync(cameraId, TilesConstants.TilesContentContextId);
                            if (contentGroup != null) {
                                tileContents.push(contentGroup);
                            }
                        }
                    }
                }
            }
        }

        return tileContents;
    }

    public static getDragData(dragType: string): any {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.dragData.get(dragType);
    }

    public static getDragTarget(event: DragEvent): DragTarget {
        // try to extract browser items first
        const items = DragDropHelper.getDragData(DragDropTypes.EntityBrowserItems) as EntityBrowserItem[];
        if (items) {
            // if we contain multiple items, let the tile task handle it
            if (items.length > 1) {
                return DragTarget.TilesTask;
            } else {
                // if we contain a TileLayout entity, let the tile task handle it
                const layouts = items.find((x) => x.model.entityType.type === EntityTypes.TileLayouts);
                if (layouts) {
                    return DragTarget.TilesTask;
                }
            }
        }

        return DragTarget.Tile;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static startDrag(dragType: string, dragData: any): void {
        this.dragData.clear();
        this.updateDragData(dragType, dragData);
    }

    public static endDrag(): void {
        this.dragData.clear();
    }

    public static supportType(supportedDragTypes: string[]): boolean {
        for (const supportedType of supportedDragTypes) {
            for (const type of this.dragData.keys()) {
                if (supportedType === type) {
                    return true;
                }
            }
        }

        return false;
    }

    public static getDropEffect(): string {
        return this.dropEffect;
    }

    public static setDropEffect(dropEffect: DragDropEffect, event?: DragEvent): void {
        this.dropEffect = dropEffect;
        if (event?.dataTransfer) {
            event.dataTransfer.dropEffect = dropEffect;
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static updateDragData(dragType: string, dragData: any): void {
        this.dragData.set(dragType, dragData);
    }
}
