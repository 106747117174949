// Autogenerated: v2/help/Entities/ApiSecurityCenterFederationRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:27 AM
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FederationBaseRoleEntity } from './FederationBaseRoleEntity';
import { IGuid } from 'safeguid';
import { IFederationEvent, ISecurityCenterFederationRoleEntity, SecurityCenterFederationRoleEntityFields } from '../../Interface/ISecurityCenterFederationRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class FederationEvent extends FieldObject implements IFederationEvent {
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // Enabled
    public get enabled(): boolean {
        return this.getField<boolean>('Enabled');
    }
    public set enabled(value: boolean) {
        this.setField<boolean>('Enabled', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('Id', '');
        this.setField<boolean>('Enabled', false);
    }
}

// #endregion Inner classes

export class SecurityCenterFederationRoleEntity extends FederationBaseRoleEntity implements ISecurityCenterFederationRoleEntity {
    // #region REST Properties

    // DefaultStreamUsage
    public get defaultStreamUsage(): string {
        return this.getField<string>(SecurityCenterFederationRoleEntityFields.defaultStreamUsageField);
    }
    public set defaultStreamUsage(value: string) {
        this.setField<string>(SecurityCenterFederationRoleEntityFields.defaultStreamUsageField, value);
    }

    // FederateAlarms
    public get federateAlarms(): boolean {
        return this.getField<boolean>(SecurityCenterFederationRoleEntityFields.federateAlarmsField);
    }
    public set federateAlarms(value: boolean) {
        this.setField<boolean>(SecurityCenterFederationRoleEntityFields.federateAlarmsField, value);
    }

    // ForwardDirectoryReports
    public get forwardDirectoryReports(): boolean {
        return this.getField<boolean>(SecurityCenterFederationRoleEntityFields.forwardDirectoryReportsField);
    }
    public set forwardDirectoryReports(value: boolean) {
        this.setField<boolean>(SecurityCenterFederationRoleEntityFields.forwardDirectoryReportsField, value);
    }

    // ResiliencyTimeoutInMilliseconds
    public get resiliencyTimeoutInMilliseconds(): number {
        return this.getField<number>(SecurityCenterFederationRoleEntityFields.resiliencyTimeoutInMillisecondsField);
    }
    public set resiliencyTimeoutInMilliseconds(value: number) {
        this.setField<number>(SecurityCenterFederationRoleEntityFields.resiliencyTimeoutInMillisecondsField, value);
    }

    // RootAreaId
    public get rootAreaId(): IGuid {
        return this.getFieldGuid(SecurityCenterFederationRoleEntityFields.rootAreaIdField);
    }

    // UseResilientConnection
    public get useResilientConnection(): boolean {
        return this.getField<boolean>(SecurityCenterFederationRoleEntityFields.useResilientConnectionField);
    }
    public set useResilientConnection(value: boolean) {
        this.setField<boolean>(SecurityCenterFederationRoleEntityFields.useResilientConnectionField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getEventsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IFederationEvent> | null> {
        return this.getFieldObjectRelationAsync<FederationEvent, IFederationEvent>(FederationEvent, 'Events', 'Id', true, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

