import { Degree } from '../utils/Degree';
import { Radian } from '../utils/Radian';
import { assertUnreachable } from '../utils/Utils';
import { ClipSpaceVec2 } from '../utils/Vec2';
import { CameraPosition } from './DewarperSourceImageParameters';

export interface ICameraPosition {
  getRotation(xy: ClipSpaceVec2): Radian;
  toString(): string;
}

export class CameraPositionBuilder {
  public static build(cameraPosition: CameraPosition): ICameraPosition {
    switch (cameraPosition) {
      case CameraPosition.Wall:
        return new CameraPositionWall();
      case CameraPosition.Ceiling:
        return new CameraPositionCeiling();
      case CameraPosition.Floor:
        return new CameraPositionFloor();
      default:
        assertUnreachable(cameraPosition);
    }
  }
}

export class CameraPositionWall implements ICameraPosition {
  public getRotation(xy: ClipSpaceVec2): Radian {
    return xy.toPolar().Angle.opposite(); //Todo: Find out why need to reverse here and not in prototype?
  }

  public toString(): string {
    return 'Wall';
  }
}

export class CameraPositionCeiling implements ICameraPosition {
  private static readonly requiredRotation: Radian = new Degree(-90).toRadian();

  public getRotation(_: ClipSpaceVec2): Radian {
    return CameraPositionCeiling.requiredRotation;
  }

  public toString(): string {
    return 'Ceiling';
  }
}

export class CameraPositionFloor implements ICameraPosition {
  private static readonly requiredRotation: Radian = new Degree(90).toRadian();

  public getRotation(_: ClipSpaceVec2): Radian {
    return CameraPositionFloor.requiredRotation;
  }

  public toString(): string {
    return 'Floor';
  }
}
