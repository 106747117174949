import { Component, Inject, OnInit } from '@angular/core';
import { SelectionType } from '@genetec/gelato';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { EventsService } from '@modules/shared/services/events/events.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TreeItem } from '@shared/interfaces/tree-item/tree-item';
import { Observable } from 'rxjs';
import { SafeGuid } from 'safeguid';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EventsSettingsService } from '@modules/shared/services/events/events-settings.service';
import { EventsOptionTreeService } from './services/events-option-tree.service';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 * Exposes the various Events to listen to in the App. Changes to the events selection via the tree
 * will save the new values in the local storage.
 * On load, state is also retrieved from local storage using the 'listeningEvents'
 * key.
 */
@UntilDestroy()
@Component({
    selector: 'app-events-option',
    templateUrl: './events-option.component.html',
    styleUrls: ['./events-option.component.scss'],
    providers: [EventsOptionTreeService],
})
@InternalPluginDescriptor({
    type: EventsOptionComponent,
    pluginTypes: [PluginTypes.Option],
    exposure: {
        id: EventsOptionComponent.pluginId,
        subSection: OptionSubSections.Events,
    },
    isSupported: () => true,
})
export class EventsOptionComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('96E8DF44-D47E-4AA5-BA34-BA5C872495B3');

    public readonly SelectionTypes = SelectionType;

    public searchCriteria = '';
    public eventsSource: TreeItem[] = [];
    public filteredEvents$: Observable<TreeItem[]>;
    public events$: Observable<TreeItem[]>;
    public isContentVisible = false;

    constructor(
        trackingService: TrackingService,
        private eventsService: EventsService,
        private eventsTreeService: EventsOptionTreeService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService,
        private eventsSettingsService: EventsSettingsService
    ) {
        super(trackingService);

        this.filteredEvents$ = this.eventsTreeService.filteredEvents$;
        this.events$ = this.eventsTreeService.events$;

        // look out for changes in events coming from the mergeEvents methods.
        this.events$.pipe(untilDestroyed(this)).subscribe((events: TreeItem[]) => {
            if (events.length > 0) {
                this.eventsTreeService.saveListeningEventSelection(events);
            }
        });
    }

    async ngOnInit() {
        super.ngOnInit();

        const contentVisibility = this.userSettingsService.getOptionAvailability(OptionTypes.Events);
        if (contentVisibility) {
            this.isContentVisible = true;
            // retrieve info from local storage.
            const availableEventTypes = this.eventsSettingsService.getListeningEventsFromLocalStorage();

            // retrieve definitions from the backend
            const eventDefinitions = (await this.eventsService.fetchRegisteredEventTypesAsync()) ?? [];

            // Get TreeItems from eventTypes and Definitions.
            this.eventsTreeService.initService(availableEventTypes ?? [], eventDefinitions);
        }
    }

    /**
     * triggers a search in the tree after the debounced delay.
     *
     * @param criteria string to look for in the tree
     */
    public onSearchCriteriaChange(criteria: string): void {
        this.searchCriteria = criteria;
        this.eventsTreeService.doSearch(criteria);
    }

    /**
     * Syncs changes made in FilteredEvents view back into Regular Events Tree.
     *
     * @param filteredEvents changes to sync
     */
    public syncWithRegularEvents(filteredEvents: TreeItem[]): void {
        this.eventsTreeService.mergeEvents(filteredEvents);
    }

    /**
     * Allows to prevent the modal to be closed when someone presses "enter" in the search input.
     *
     * @param event "enter" key pressed.
     */
    public onEnterKeyPress(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Save a flattened array containing all the checked elements.
     *
     * @param selectedEvents array containing all the tree elements.
     */
    public saveSelectionToLocalStorage(selectedEvents: TreeItem[]): void {
        this.eventsTreeService.saveListeningEventSelection(selectedEvents);
    }
}
