import { GenModalService } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TileCanvasStateService } from '../state/tile-canvas-state.service';
import { ContentGroup } from '../../shared/interfaces/plugins/public/plugin-public.interface';

/*
 * The CarouselService determines how to render contents in a tile layout
 */

@Injectable()
export class CarouselService {
    static readonly firstTileId = 1;

    constructor(private tilesTaskStateService: TileCanvasStateService, private modalService: GenModalService, private translateService: TranslateService) {}

    public getFirstAvailableTileId(): number | null {
        const all = this.tilesTaskStateService?.contents;
        const tileCount = all.length;
        for (let tileId = 1; tileId <= tileCount; ++tileId) {
            if (this.isEmpty(all[tileId - 1])) {
                return tileId;
            }
        }
        return null;
    }

    public displayContent(content: ContentGroup): void {
        this.displayContents([content], false);
    }

    public displayContents(contents: ContentGroup[] | null, clear: boolean): void {
        if (contents) {
            // if the tiles need to be cleared
            if (clear) {
                this.tilesTaskStateService?.clearTiles(null);
                // apply the content for each tiles
                // the contents array should have a content for each tile
                for (let i = 0; i < contents.length; ++i) {
                    const contentGroup = contents[i];
                    // update the content even if it's null. Please note that tile id is 1 based
                    this.tilesTaskStateService?.changeContent(i + 1, contentGroup);
                }
            } else {
                // retrieve the list of all contents
                const all = this.tilesTaskStateService?.contents;
                if (all) {
                    let placedContents = 0;
                    for (const contentGroup of contents) {
                        // find the first available tile
                        const availableTileId = this.getFirstAvailableTileId();
                        if (availableTileId) {
                            this.tilesTaskStateService?.changeContent(availableTileId, contentGroup);
                            placedContents++;
                        } else {
                            break;
                        }
                    }

                    // determine if we are missing tiles to display contents
                    if (placedContents < contents.length) {
                        if (contents.length === 1) {
                            // Special case.
                            // If all tiles are occupied and we only have one content to place, reuse tile #1 (same as Web Client)
                            this.tilesTaskStateService?.changeContent(CarouselService.firstTileId, contents[0]);
                        } else {
                            const title = this.translateService.instant('STE_LABEL_ERROR') as string;
                            const message = this.translateService.instant('STE_MESSAGE_ERROR_NOTENOUGHFREETILES') as string;
                            const close = this.translateService.instant('STE_BUTTON_CLOSE') as string;

                            this.modalService.showMessage(title, message, close).fireAndForget();
                        }
                    }
                }
            }
        }
    }

    private isEmpty(content: ContentGroup | null | undefined): boolean {
        if (content) {
            if (!content.id.isEmpty()) {
                return false;
            }
        }
        return true;
    }
}
