import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { GenAction, GenListActions, GenMeltedListItem, MeltedIcon } from '@genetec/gelato-angular';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { ISystemConfigurationManager } from 'RestClient/Client/Interface/ISystemConfigurationManager';
import { DisplayEntity, SystemConfigurationManager } from 'RestClient/Client/Managers/SystemConfigurationManager';
import { Entity } from 'RestClient/Client/Model/Entity';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { EntityBrowserFilter } from '../../entity-browser/filters/entity-browser-filter';
import { TrackingService } from '../../services/tracking.service';
import { ModalEntitiesSelectorComponent } from '../entity-browser/modal-entities-selector/modal-entities-selector.component';
import { ConnectionAwareModalComponent } from '../tracked/connection-aware-modal.component';

@Component({
    selector: 'app-share-entity-modal',
    templateUrl: './share-entity-modal.component.html',
    styleUrls: ['./share-entity-modal.component.scss'],
})
export class ShareEntityModalComponent extends ConnectionAwareModalComponent implements OnInit {
    @Input() public entityId!: IGuid;
    @Input() public recipientId!: IGuid;
    @Input() public timestamp!: Date;

    @ViewChild('userEntitySelector') public userEntitySelector!: ModalEntitiesSelectorComponent;

    public scClient: WebAppClient;
    public selectedListItems: GenMeltedListItem[] = [];
    public message = '';
    public isLoading = true;
    public isShareButtonEnabled = false;
    public systemConfigurationManager!: ISystemConfigurationManager;
    public isCurrentlySharing = false;

    public userSelectionBrowserFilter = new EntityBrowserFilter([EntityTypes.Users, EntityTypes.UserGroups]);

    public selectedUsersActions: GenListActions;

    private addUserAction: GenAction = {
        id: SafeGuid.newGuid().toString(),
        execute: () => {
            this.userSelectionBrowserFilter.excludedEntities = this.excludedEntities;
            this.userEntitySelector.show();
        },
    };

    private deleteUserAction: GenAction = {
        id: SafeGuid.newGuid().toString(),
        canExecute: (items?: any) => {
            return !!this.selectedUser;
        },
        execute: () => {
            const index = this.selectedListItems.findIndex((user) => user.id === this.selectedUser?.id);
            if (index !== -1) {
                for (const excludedEntity of this.excludedEntities) {
                    if (excludedEntity.equals(this.selectedUserEntities[index].id)) {
                        this.excludedEntities.delete(excludedEntity);
                        break;
                    }
                }
                this.selectedListItems.splice(index, 1);
                this.selectedUserEntities.splice(index, 1);
                this.selectedUser = null;
            }
            this.validate();
        },
    };

    private selectedUserEntities: IEntity[] = [];
    private selectedUser: GenMeltedListItem | null = null;
    private excludedEntities = SafeGuid.createSet();

    constructor(securityCenterClientService: SecurityCenterClientService, protected authService: AuthService, injector: Injector, trackingService: TrackingService) {
        super(authService, injector, trackingService);

        this.scClient = securityCenterClientService?.scClient;

        this.selectedUsersActions = {
            add: this.addUserAction,
            delete: this.deleteUserAction,
        };
    }

    async ngOnInit() {
        super.ngOnInit();

        this.systemConfigurationManager = await this.scClient?.getAsync<SystemConfigurationManager, ISystemConfigurationManager>(SystemConfigurationManager);

        if (this.recipientId) {
            await this.addRecipient(this.recipientId);
        }
        this.validate();
        this.isLoading = false;
    }

    public onSelectedEntityChanged(): void {
        this.validate();
    }

    public onEntityIdsSelected(ids: IGuid[]): void {
        this.addRecipients(ids).fireAndForget();
    }

    public onSelectedUserChange(selectedUser: GenMeltedListItem | null): void {
        this.selectedUser = selectedUser;
    }

    public onShareClick = async (): Promise<boolean> => {
        this.isShareButtonEnabled = false;
        this.isCurrentlySharing = true;
        const value = new DisplayEntity();

        const recipients = ObservableCollection.From(this.selectedUserEntities.map((userEntity) => userEntity.id));
        const entities = ObservableCollection.From([this.entityId]);

        value.recipients = recipients;
        value.entities = entities;
        value.annotationMessage = this.message;
        value.forceDisplay = false;
        if (this.timestamp) {
            value.playbackTime = this.timestamp;
        }
        await this.systemConfigurationManager.displayEntityAsync(value);
        return true;
    };

    public onMessageChange(newMessage: string): void {
        this.message = newMessage;
    }

    private validate() {
        this.isShareButtonEnabled = this.selectedListItems.length > 0 && this.entityId && !this.entityId.isEmpty();
    }

    private addRecipient(recipientId: IGuid): Promise<void> {
        return this.addRecipients([recipientId]);
    }

    private async addRecipients(recipientIds: IGuid[]): Promise<void> {
        if (recipientIds.length > 0) {
            const users = await this.scClient.getEntitiesAsync<Entity, IEntity>(Entity, this.createRecipientsEntityQuery(recipientIds));
            this.addRecipientEntities(users);
        }
        this.validate();
    }

    private addRecipientEntities(recipients: IEntity[]): void {
        if (recipients.length > 0) {
            for (const recipient of recipients) {
                this.selectedListItems.push({
                    id: recipient.id.toString(),
                    text: recipient.name,
                    icon: recipient.entityType === EntityTypes.UserGroups ? MeltedIcon.People : MeltedIcon.Person,
                });
                this.selectedUserEntities.push(recipient);
                this.excludedEntities.add(recipient.id);
            }
        }
    }

    private createRecipientsEntityQuery(entityIds: IGuid[]): EntityQuery {
        const entityQuery = new EntityQuery();
        entityQuery.guids = SafeGuid.createSet(entityIds);
        entityQuery.entityTypes = new Set<string>([EntityTypes.Users, EntityTypes.UserGroups]);
        return entityQuery;
    }
}
