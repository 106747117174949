<gen-melted-modal
    genTitle="{{ 'STE_TITLE_CONFIRM_THREAT_LEVEL' | translate }}"
    id="confirmThreatLevelModal"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_BUTTON_CONFIRM' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnDefaultAction]="setThreatLevel"
    [genOnAlternativeAction]="openThreatLevelModal"
    appFocusModalWhenOpen
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <gen-text
            *ngIf="selected; else noThreatLevel"
            [innerHTML]="'STE_MESSAGE_CONFIRM_FORMAT_CHANGE_THREAT_LEVEL_TO_X' | translate | boldString: '{0}' | stringFormat: selected.name"
        >
        </gen-text>
    </gen-melted-modal-body>
</gen-melted-modal>
<ng-template #noThreatLevel>{{ 'STE_MESSAGE_CONFIRM_RESET_THREAT_LEVEL' | translate }}</ng-template>
