export class GLUtils {
/* draw a rectangle using 2 triangles
 * (x1, y1)   (x2, y1)
 * |-----------|
 * |          /|
 * |         / |
 * |        /  |
 * |       /   |
 * |      /    |
 * |     /     |
 * |    /      |
 * |   /       |
 * |  /        |
 * | /         |
 * |/          |
 * |-----------|
 * (x1, y2)   (x2, y2)
 */
  public static getRectangle12Points(x: number, y: number, width: number, height: number): Float32Array {
    const x1 = x;
    const x2 = x + width;
    const y1 = y;
    const y2 = y + height;
    return new Float32Array([
      x1, y1,
      x2, y1,
      x1, y2, //first triangle
      x1, y2,
      x2, y1,
      x2, y2, //second triangle
    ]);
  }

  public static getWebGL2Context(canvas: HTMLCanvasElement): WebGL2RenderingContext {
    const gl = canvas.getContext('webgl2');
    if (gl === null) {
      throw Error('No webgl2 support');
    }

    return gl;
  }
}
