// Autogenerated: v2/help/enumerations/ApiEventTypes?codegen=ts&showresources=True&showinternalid=False on 2022-12-21 2:23:41 PM

export class EventTypes {
    public static None = 'None';
    public static Invalidate = 'Invalidate';
    public static ManualAction = 'ManualAction';
    public static AlarmTriggered = 'AlarmTriggered';
    public static AlarmAcknowledged = 'AlarmAcknowledged';
    public static ApplicationConnected = 'ApplicationConnected';
    public static ApplicationDisconnected = 'ApplicationDisconnected';
    public static ServerLost = 'ServerLost';
    public static CameraLiveBookmarkAdded = 'CameraLiveBookmarkAdded';
    public static CameraPlaybackBookmarkAdded = 'CameraPlaybackBookmarkAdded';
    public static CameraMotionOn = 'CameraMotionOn';
    public static CameraMotionOff = 'CameraMotionOff';
    public static CardholderAccessGranted = 'CardholderAccessGranted';
    public static CameraAbnormalMotion = 'CameraAbnormalMotion';
    public static CameraNominalMotion = 'CameraNominalMotion';
    public static CardholderAccessRefused = 'CardholderAccessRefused';
    public static CardholderWalkthrough = 'CardholderWalkthrough';
    public static CardholderInactive = 'CardholderInactive';
    public static CardHolderCredentialExpired = 'CardHolderCredentialExpired';
    public static CardHolderCredentialInactive = 'CardHolderCredentialInactive';
    public static CardHolderCredentialLost = 'CardHolderCredentialLost';
    public static CardHolderCredentialStolen = 'CardHolderCredentialStolen';
    public static CardholderAntipassback = 'CardholderAntipassback';
    public static CustomEvent = 'CustomEvent';
    public static UnitConnected = 'UnitConnected';
    public static UnitDisconnected = 'UnitDisconnected';
    public static InterfaceOnline = 'InterfaceOnline';
    public static InterfaceOffline = 'InterfaceOffline';
    public static AccessControlUnitWarningActivated = 'AccessControlUnitWarningActivated';
    public static AccessControlUnitWarningDeactivated = 'AccessControlUnitWarningDeactivated';
    public static FirmwareUpgradeScheduled = 'FirmwareUpgradeScheduled';
    public static FirmwareUpgradeCancelled = 'FirmwareUpgradeCancelled';
    public static InterfaceFirmwareUpgradeStarted = 'InterfaceFirmwareUpgradeStarted';
    public static InterfaceFirmwareUpgradeSuccess = 'InterfaceFirmwareUpgradeSuccess';
    public static InterfaceFirmwareUpgradeFailure = 'InterfaceFirmwareUpgradeFailure';
    public static InterfaceFirmwareUpgradeScheduled = 'InterfaceFirmwareUpgradeScheduled';
    public static InterfaceFirmwareUpgradeCancelled = 'InterfaceFirmwareUpgradeCancelled';
    public static InterfaceFirmwareUpgradeTransferred = 'InterfaceFirmwareUpgradeTransferred';
    public static UnitACFail = 'UnitACFail';
    public static UnitBatFail = 'UnitBatFail';
    public static UnitInvalidConfiguration = 'UnitInvalidConfiguration';
    public static UnitTemperatureAlarm = 'UnitTemperatureAlarm';
    public static UnitTimeOutOfSync = 'UnitTimeOutOfSync';
    public static UnitTimeOutOfSyncRecovered = 'UnitTimeOutOfSyncRecovered';
    public static UnitDiskActionError = 'UnitDiskActionError';
    public static UnitDiskFormatted = 'UnitDiskFormatted';
    public static UnitDiskLocked = 'UnitDiskLocked';
    public static UnitDiskMounted = 'UnitDiskMounted';
    public static UnitDiskUnlocked = 'UnitDiskUnlocked';
    public static UnitDiskUnmounted = 'UnitDiskUnmounted';
    public static ArchiveTransferSequenceImported = 'ArchiveTransferSequenceImported';
    public static UserLoggedOn = 'UserLoggedOn';
    public static UserLoggedOff = 'UserLoggedOff';
    public static UserLogonFailed = 'UserLogonFailed';
    public static FirmwareUpgradeStarted = 'FirmwareUpgradeStarted';
    public static FirmwareUpgradeSuccess = 'FirmwareUpgradeSuccess';
    public static FirmwareUpgradeFailure = 'FirmwareUpgradeFailure';
    public static DoorRexOn = 'DoorRexOn';
    public static DoorRexOff = 'DoorRexOff';
    public static DoorOpenedForTooLong = 'DoorOpenedForTooLong';
    public static DoorPullStationActivated = 'DoorPullStationActivated';
    public static DoorPullStationNormal = 'DoorPullStationNormal';
    public static DoorOpen = 'DoorOpen';
    public static DoorClose = 'DoorClose';
    public static DoorForcedOpen = 'DoorForcedOpen';
    public static DoorLock = 'DoorLock';
    public static DoorUnlock = 'DoorUnlock';
    public static DoorOpenWhileLockSecure = 'DoorOpenWhileLockSecure';
    public static AccessUnknownCredential = 'AccessUnknownCredential';
    public static AccessUnassignedCredential = 'AccessUnassignedCredential';
    public static AccessGranted = 'AccessGranted';
    public static AccessRefused = 'AccessRefused';
    public static AccessExpiredCredential = 'AccessExpiredCredential';
    public static AccessStolenCredential = 'AccessStolenCredential';
    public static AccessLostCredential = 'AccessLostCredential';
    public static AccessInactiveCredential = 'AccessInactiveCredential';
    public static AccessInactivePerson = 'AccessInactivePerson';
    public static DoorTamper = 'DoorTamper';
    public static InputTroubleShort = 'InputTroubleShort';
    public static InputTroubleOpen = 'InputTroubleOpen';
    public static EmergencyDoorRex = 'EmergencyDoorRex';
    public static EmergencyDoorUnlockPostponed = 'EmergencyDoorUnlockPostponed';
    public static EmergencyDoorUnlocked = 'EmergencyDoorUnlocked';
    public static EmergencyDoorUnlockedOperatorUnlock = 'EmergencyDoorUnlockedOperatorUnlock';
    public static EmergencyDoorUnlockedReleaseDelayTimeExpired = 'EmergencyDoorUnlockedReleaseDelayTimeExpired';
    public static EmergencyDoorUnlockedExtensionGuardLockingExpired = 'EmergencyDoorUnlockedExtensionGuardLockingExpired';
    public static EmergencyDoorUnlockedMaximumLockingTimeExpired = 'EmergencyDoorUnlockedMaximumLockingTimeExpired';
    public static EmergencyDoorUnlockedHostConnectionTimeout = 'EmergencyDoorUnlockedHostConnectionTimeout';
    public static EmergencyDoorReset = 'EmergencyDoorReset';
    public static EmergencyDoorResetRemoteOperator = 'EmergencyDoorResetRemoteOperator';
    public static EmergencyDoorResetPhysicalButton = 'EmergencyDoorResetPhysicalButton';
    public static EmergencyDoorResetAuto = 'EmergencyDoorResetAuto';
    public static DoorTestStarted = 'DoorTestStarted';
    public static DoorTestSucceeded = 'DoorTestSucceeded';
    public static DoorTestFailed = 'DoorTestFailed';
    public static DoorTestFailedAborted = 'DoorTestFailedAborted';
    public static DoorTestFailedReaderShunted = 'DoorTestFailedReaderShunted';
    public static DoorTestFailedDoorNotSecured = 'DoorTestFailedDoorNotSecured';
    public static DoorTestFailedError = 'DoorTestFailedError';
    public static DoorTestFailedUnlockFailed = 'DoorTestFailedUnlockFailed';
    public static DoorTestFailedRelockFailed = 'DoorTestFailedRelockFailed';
    public static AreaFirstManIn = 'AreaFirstManIn';
    public static AreaLastManOut = 'AreaLastManOut';
    public static AreaAntipassbackDetected = 'AreaAntipassbackDetected';
    public static AreaAntipassbackForgiven = 'AreaAntipassbackForgiven';
    public static PeopleCountReset = 'PeopleCountReset';
    public static AreaCardholderRemoved = 'AreaCardholderRemoved';
    public static AreaCardholderAdded = 'AreaCardholderAdded';
    public static CredentialUnassigned = 'CredentialUnassigned';
    public static CredentialExpired = 'CredentialExpired';
    public static CredentialStolen = 'CredentialStolen';
    public static CredentialLost = 'CredentialLost';
    public static CredentialInactive = 'CredentialInactive';
    public static CredentialHasExpired = 'CredentialHasExpired';
    public static CredentialIsExpiring = 'CredentialIsExpiring';
    public static ZoneGlassBreak = 'ZoneGlassBreak';
    public static ZoneWindowOpened = 'ZoneWindowOpened';
    public static ZoneWindowClosed = 'ZoneWindowClosed';
    public static ZoneDeadBoltLocked = 'ZoneDeadBoltLocked';
    public static ZoneDeadBoltUnlocked = 'ZoneDeadBoltUnlocked';
    public static ZoneKnobInplace = 'ZoneKnobInplace';
    public static ZoneKnobRotated = 'ZoneKnobRotated';
    public static ZoneLockSecured = 'ZoneLockSecured';
    public static ZoneLockReleased = 'ZoneLockReleased';
    public static MacroStarted = 'MacroStarted';
    public static MacroCompleted = 'MacroCompleted';
    public static MacroAborted = 'MacroAborted';
    public static AlarmAcknowledgedAlternate = 'AlarmAcknowledgedAlternate';
    public static LprFirst = 'LprFirst';
    public static LprRead = 'LprRead';
    public static LprHit = 'LprHit';
    public static ProcessMlpiResultsResultEvent = 'ProcessMlpiResultsResultEvent';
    public static LprOffloadSucceeded = 'LprOffloadSucceeded';
    public static LprOffloadFailed = 'LprOffloadFailed';
    public static LprNoMatch = 'LprNoMatch';
    public static LprHotlistChanged = 'LprHotlistChanged';
    public static LprUpdateInstalled = 'LprUpdateInstalled';
    public static LprUpdateUninstalled = 'LprUpdateUninstalled';
    public static LprUpdatePublished = 'LprUpdatePublished';
    public static LprUpdateError = 'LprUpdateError';
    public static LprUpdateInstalling = 'LprUpdateInstalling';
    public static LprUpdateUninstalling = 'LprUpdateUninstalling';
    public static IntrusionAreaDuress = 'IntrusionAreaDuress';
    public static IntrusionAreaEntryDelay = 'IntrusionAreaEntryDelay';
    public static IntrusionAreaAlarmActivated = 'IntrusionAreaAlarmActivated';
    public static IntrusionAreaArming = 'IntrusionAreaArming';
    public static IntrusionAreaArmingPostponed = 'IntrusionAreaArmingPostponed';
    public static IntrusionAreaMasterArmed = 'IntrusionAreaMasterArmed';
    public static IntrusionAreaPerimeterArmed = 'IntrusionAreaPerimeterArmed';
    public static IntrusionAreaDisarmed = 'IntrusionAreaDisarmed';
    public static IntrusionAreaInputTrouble = 'IntrusionAreaInputTrouble';
    public static IntrusionAreaInputBypassActivated = 'IntrusionAreaInputBypassActivated';
    public static IntrusionAreaInputBypassDeactivated = 'IntrusionAreaInputBypassDeactivated';
    public static IntrusionAreaForcedArming = 'IntrusionAreaForcedArming';
    public static IntrusionAreaCanceledAlarm = 'IntrusionAreaCanceledAlarm';
    public static IntrusionAreaMasterArmRequest = 'IntrusionAreaMasterArmRequest';
    public static IntrusionAreaPerimeterArmRequest = 'IntrusionAreaPerimeterArmRequest';
    public static IntrusionAreaDisarmRequest = 'IntrusionAreaDisarmRequest';
    public static IntrusionAreaArmingPostponedRequest = 'IntrusionAreaArmingPostponedRequest';
    public static IntrusionAreaCanceledAlarmRequest = 'IntrusionAreaCanceledAlarmRequest';
    public static IntrusionAreaAlarmSilenced = 'IntrusionAreaAlarmSilenced';
    public static IntrusionUnitTamper = 'IntrusionUnitTamper';
    public static ZoneArmed = 'ZoneArmed';
    public static ZoneDisarmed = 'ZoneDisarmed';
    public static ZoneMaintenanceOn = 'ZoneMaintenanceOn';
    public static ZoneMaintenanceOff = 'ZoneMaintenanceOff';
    public static ZoneWarningUnitOffline = 'ZoneWarningUnitOffline';
    public static DoorMaintenanceOff = 'DoorMaintenanceOff';
    public static DoorMaintenanceOn = 'DoorMaintenanceOn';
    public static DoorScheduledLock = 'DoorScheduledLock';
    public static DoorScheduledUnlock = 'DoorScheduledUnlock';
    public static DoorWarningUnitOffline = 'DoorWarningUnitOffline';
    public static DoorWarningInterfaceAcFail = 'DoorWarningInterfaceAcFail';
    public static DoorWarningInterfaceAcFailRestored = 'DoorWarningInterfaceAcFailRestored';
    public static DoorWarningInterfaceBatteryFail = 'DoorWarningInterfaceBatteryFail';
    public static DoorWarningInterfaceBatteryFailRestored = 'DoorWarningInterfaceBatteryFailRestored';
    public static DoorWarningInterfaceTamperFail = 'DoorWarningInterfaceTamperFail';
    public static DoorWarningInterfaceTamperFailRestored = 'DoorWarningInterfaceTamperFailRestored';
    public static DoorManuallyUnlocked = 'DoorManuallyUnlocked';
    public static DoorOnline = 'DoorOnline';
    public static DoorUnsecured = 'DoorUnsecured';
    public static DoorSecured = 'DoorSecured';
    public static VideoAnalyticsAudioAlarm = 'VideoAnalyticsAudioAlarm';
    public static VideoAnalyticsLicensePlateInSight = 'VideoAnalyticsLicensePlateInSight';
    public static VideoAnalyticsLicensePlateOutofSight = 'VideoAnalyticsLicensePlateOutofSight';
    public static VideoAnalyticsLicensePlateReading = 'VideoAnalyticsLicensePlateReading';
    public static VideoAnalyticsObjectConditionChange = 'VideoAnalyticsObjectConditionChange';
    public static VideoAnalyticsObjectCrossedLine = 'VideoAnalyticsObjectCrossedLine';
    public static VideoAnalyticsObjectEntered = 'VideoAnalyticsObjectEntered';
    public static VideoAnalyticsObjectExited = 'VideoAnalyticsObjectExited';
    public static VideoAnalyticsObjectFall = 'VideoAnalyticsObjectFall';
    public static VideoAnalyticsObjectFollowingRoute = 'VideoAnalyticsObjectFollowingRoute';
    public static VideoAnalyticsObjectInField = 'VideoAnalyticsObjectInField';
    public static VideoAnalyticsObjectLeft = 'VideoAnalyticsObjectLeft';
    public static VideoAnalyticsObjectLoitering = 'VideoAnalyticsObjectLoitering';
    public static VideoAnalyticsObjectMerged = 'VideoAnalyticsObjectMerged';
    public static VideoAnalyticsObjectRemoved = 'VideoAnalyticsObjectRemoved';
    public static VideoAnalyticsObjectRun = 'VideoAnalyticsObjectRun';
    public static VideoAnalyticsObjectSlide = 'VideoAnalyticsObjectSlide';
    public static VideoAnalyticsObjectSplit = 'VideoAnalyticsObjectSplit';
    public static VideoAnalyticsObjectStopped = 'VideoAnalyticsObjectStopped';
    public static VideoAnalyticsTailgating = 'VideoAnalyticsTailgating';
    public static VideoAnalyticsTampering = 'VideoAnalyticsTampering';
    public static VideoAnalyticsFaceDetected = 'VideoAnalyticsFaceDetected';
    public static VideoAnalyticsUndefined = 'VideoAnalyticsUndefined';
    public static VideoAnalyticsDirectionAlarm = 'VideoAnalyticsDirectionAlarm';
    public static VideoAnalyticsTamperingOff = 'VideoAnalyticsTamperingOff';
    public static VideoAnalyticsCrowdDetected = 'VideoAnalyticsCrowdDetected';
    public static VideoAnalyticsFaceRecognized = 'VideoAnalyticsFaceRecognized';
    public static VideoAnalyticsObjectDetected = 'VideoAnalyticsObjectDetected';
    public static VideoAnalyticsHeatMapChanged = 'VideoAnalyticsHeatMapChanged';
    public static VideoAnalyticsObjectCountChanged = 'VideoAnalyticsObjectCountChanged';
    public static VideoAnalyticsObjectVelocityChanged = 'VideoAnalyticsObjectVelocityChanged';
    public static VideoAnalyticsObjectDirectionChanged = 'VideoAnalyticsObjectDirectionChanged';
    public static VideoAnalyticsPeopleCounting = 'VideoAnalyticsPeopleCounting';
    public static VideoAnalyticsPeopleCountingAlarm = 'VideoAnalyticsPeopleCountingAlarm';
    public static VideoAnalyticsCrowdSize = 'VideoAnalyticsCrowdSize';
    public static FloorAccess = 'FloorAccess';
    public static AssetMoved = 'AssetMoved';
    public static AssetLowBattery = 'AssetLowBattery';
    public static AssetOffline = 'AssetOffline';
    public static AssetOnline = 'AssetOnline';
    public static ArchivingStartedAutomatically = 'ArchivingStartedAutomatically';
    public static ArchivingStoppedAutomatically = 'ArchivingStoppedAutomatically';
    public static ArchivingStartedManually = 'ArchivingStartedManually';
    public static ArchivingStoppedManually = 'ArchivingStoppedManually';
    public static ArchivingStartedOnMotionEvent = 'ArchivingStartedOnMotionEvent';
    public static ArchivingStoppedOnMotionEvent = 'ArchivingStoppedOnMotionEvent';
    public static ArchivingStartedByExternalSource = 'ArchivingStartedByExternalSource';
    public static ArchivingStoppedByExternalSource = 'ArchivingStoppedByExternalSource';
    public static ArchivingStartedByAlarm = 'ArchivingStartedByAlarm';
    public static ArchivingStoppedByAlarm = 'ArchivingStoppedByAlarm';
    public static CameraStartLiveStreaming = 'CameraStartLiveStreaming';
    public static CameraStopLiveStreaming = 'CameraStopLiveStreaming';
    public static ArchiveFileDeleted = 'ArchiveFileDeleted';
    public static CameraSignalLost = 'CameraSignalLost';
    public static CameraSignalRecovered = 'CameraSignalRecovered';
    public static ArchivingQueueFull = 'ArchivingQueueFull';
    public static CameraNotArchiving = 'CameraNotArchiving';
    public static CameraTransmissionLost = 'CameraTransmissionLost';
    public static CameraRtpPacketsLost = 'CameraRtpPacketsLost';
    public static CameraMultipleSourceDetected = 'CameraMultipleSourceDetected';
    public static CameraStartBlock = 'CameraStartBlock';
    public static CameraStopBlock = 'CameraStopBlock';
    public static CameraTransmissionRecovered = 'CameraTransmissionRecovered';
    public static CameraEdgeRecordingCommandTimeout = 'CameraEdgeRecordingCommandTimeout';
    public static CameraEdgestorageFailure = 'CameraEdgestorageFailure';
    public static CameraMotion = 'CameraMotion';
    public static CameraEdgestorageEnabled = 'CameraEdgestorageEnabled';
    public static CameraRtpPacketLostRecovery = 'CameraRtpPacketLostRecovery';
    public static PtzActivated = 'PtzActivated';
    public static PtzStopped = 'PtzStopped';
    public static PtzZoomByUserStarted = 'PtzZoomByUserStarted';
    public static PtzZoomByUserStopped = 'PtzZoomByUserStopped';
    public static PtzLocked = 'PtzLocked';
    public static HealthMonitoringEvent = 'HealthMonitoringEvent';
    public static AreaAntipassbackDisabledMixedMode = 'AreaAntipassbackDisabledMixedMode';
    public static AreaAntipassbackDisabledUnitOffline = 'AreaAntipassbackDisabledUnitOffline';
    public static AreaPeopleCountDisabledUnitOffline = 'AreaPeopleCountDisabledUnitOffline';
    public static AreaAntipassbackDisabledPerimeterOnElevator = 'AreaAntipassbackDisabledPerimeterOnElevator';
    public static AreaSasOverrideOn = 'AreaSasOverrideOn';
    public static AreaSasOverrideOff = 'AreaSasOverrideOff';
    public static AreaSasLockdownOn = 'AreaSasLockdownOn';
    public static AreaSasLockdownOff = 'AreaSasLockdownOff';
    public static AreaSasMultipleUnitWarning = 'AreaSasMultipleUnitWarning';
    public static AreaSasUnsupportedWarning = 'AreaSasUnsupportedWarning';
    public static AreaSasMaintenanceModeWarning = 'AreaSasMaintenanceModeWarning';
    public static AreaSasFreeAccessWarning = 'AreaSasFreeAccessWarning';
    public static AreaSasAntipassbackWarning = 'AreaSasAntipassbackWarning';
    public static AreaSasFloorWarning = 'AreaSasFloorWarning';
    public static AreaAntipassbackDisabledInvalidSettings = 'AreaAntipassbackDisabledInvalidSettings';
    public static AreaSasDoorWithNoSensorConfigured = 'AreaSasDoorWithNoSensorConfigured';
    public static AreaSasOnlyOnePerimeterFloor = 'AreaSasOnlyOnePerimeterFloor';
    public static SinglePersonCheckFailed = 'SinglePersonCheckFailed';
    public static SinglePersonCheckFailedNoPresenceDetected = 'SinglePersonCheckFailedNoPresenceDetected';
    public static SinglePersonCheckFailedTooManyPeople = 'SinglePersonCheckFailedTooManyPeople';
    public static AreaEvacuationStarted = 'AreaEvacuationStarted';
    public static AreaEvacuationEnded = 'AreaEvacuationEnded';
    public static AreaMusteringStarted = 'AreaMusteringStarted';
    public static AreaMusteringEnded = 'AreaMusteringEnded';
    public static AreaLast = 'AreaLast';
    public static ElevatorWarningUnitOffline = 'ElevatorWarningUnitOffline';
    public static ElevatorSecuredOnSchedule = 'ElevatorSecuredOnSchedule';
    public static ElevatorFreeAccessOnSchedule = 'ElevatorFreeAccessOnSchedule';
    public static AlarmInvestigating = 'AlarmInvestigating';
    public static AlarmForciblyAcked = 'AlarmForciblyAcked';
    public static ThreatLevelActivated = 'ThreatLevelActivated';
    public static ThreatLevelDeactivated = 'ThreatLevelDeactivated';
    public static CardholderNoEntryDetected = 'CardholderNoEntryDetected';
    public static DoorNoEntryDetected = 'DoorNoEntryDetected';
    public static CardholderEntryDetected = 'CardholderEntryDetected';
    public static CardholderEntryAssumed = 'CardholderEntryAssumed';
    public static VisitorAstray = 'VisitorAstray';
    public static MissingTailEscort = 'MissingTailEscort';
    public static DoorEntryDetected = 'DoorEntryDetected';
    public static DoorEntryAssumed = 'DoorEntryAssumed';
    public static DoorSupervisorInAccessRule = 'DoorSupervisorInAccessRule';
    public static DoorSupervisorInUnlockingSchedule = 'DoorSupervisorInUnlockingSchedule';
    public static AreaMaxOccupancyReached = 'AreaMaxOccupancyReached';
    public static AreaMaxOccupancyExceeded = 'AreaMaxOccupancyExceeded';
    public static AreaBelowMaxOccupancy = 'AreaBelowMaxOccupancy';
    public static DuressPinEntered = 'DuressPinEntered';
    public static DoubleBadgeOn = 'DoubleBadgeOn';
    public static DoubleBadgeOff = 'DoubleBadgeOff';
    public static AccessGrantConfirmationRequested = 'AccessGrantConfirmationRequested';
    public static IdentityValidationSucceeded = 'IdentityValidationSucceeded';
    public static IdentityValidationSucceededBiometric = 'IdentityValidationSucceededBiometric';
    public static IdentityValidationSucceededManual = 'IdentityValidationSucceededManual';
    public static IdentityValidationFailed = 'IdentityValidationFailed';
    public static IdentityValidationFailedBiometricMismatch = 'IdentityValidationFailedBiometricMismatch';
    public static IdentityValidationFailedBiometricMissing = 'IdentityValidationFailedBiometricMissing';
    public static IdentityValidationFailedManual = 'IdentityValidationFailedManual';
    public static IdentityValidationFailedTimeout = 'IdentityValidationFailedTimeout';
    public static OfflineSynchronizationStarted = 'OfflineSynchronizationStarted';
    public static OfflineSynchronizationFailure = 'OfflineSynchronizationFailure';
    public static OfflineSynchronizationSuccess = 'OfflineSynchronizationSuccess';
    public static AccessInvalidPin = 'AccessInvalidPin';
    public static AccessValidCardInvalidPin = 'AccessValidCardInvalidPin';
    public static AccessDeniedByAccessRule = 'AccessDeniedByAccessRule';
    public static AccessInsufficientPrivileges = 'AccessInsufficientPrivileges';
    public static AccessAntipassbackViolation = 'AccessAntipassbackViolation';
    public static AccessOutOfSchedule = 'AccessOutOfSchedule';
    public static AccessNoAccessRuleAssigned = 'AccessNoAccessRuleAssigned';
    public static AccessAbsentSupervisor = 'AccessAbsentSupervisor';
    public static AccessEscortRequired = 'AccessEscortRequired';
    public static AccessSecondCardholderRequired = 'AccessSecondCardholderRequired';
    public static AccessEscortNotSupported = 'AccessEscortNotSupported';
    public static CompanionVisitorDenied = 'CompanionVisitorDenied';
    public static Interlock = 'Interlock';
    public static AccessCardAndPINTimeout = 'AccessCardAndPINTimeout';
    public static AccessValidCardInactivePIN = 'AccessValidCardInactivePIN';
    public static AccessDeniedByMaxOccupancy = 'AccessDeniedByMaxOccupancy';
    public static UID = 'UID';
    public static Warning = 'Warning';
    public static UID2 = 'UID2';
    public static UserFirst = 'UserFirst';
    public static ModifiedIncident = 'ModifiedIncident';
    public static UserLast = 'UserLast';
    public static AccessPointFirst = 'AccessPointFirst';
    public static AccessPointLast = 'AccessPointLast';
    public static AlarmFirst = 'AlarmFirst';
    public static AlarmConditionCleared = 'AlarmConditionCleared';
    public static AlarmLast = 'AlarmLast';
    public static DoorFirst = 'DoorFirst';
    public static ScheduleUnlockIgnored = 'ScheduleUnlockIgnored';
    public static DoorLast = 'DoorLast';
    public static AreaFirst = 'AreaFirst';
    public static UnitFirst = 'UnitFirst';
    public static UnitLast = 'UnitLast';
    public static CredentialFirst = 'CredentialFirst';
    public static CredentialAccessDenied = 'CredentialAccessDenied';
    public static CredentialLast = 'CredentialLast';
    public static CardholderFirst = 'CardholderFirst';
    public static CardholderLast = 'CardholderLast';
    public static CardholderDuressPinEntered = 'CardholderDuressPinEntered';
    public static CardholderDoubleBadgeOn = 'CardholderDoubleBadgeOn';
    public static CardholderDoubleBadgeOff = 'CardholderDoubleBadgeOff';
    public static CardholderAccessGrantConfirmationRequested = 'CardholderAccessGrantConfirmationRequested';
    public static CardholderIdentityValidationSucceeded = 'CardholderIdentityValidationSucceeded';
    public static CardholderIdentityValidationSucceededBiometric = 'CardholderIdentityValidationSucceededBiometric';
    public static CardholderIdentityValidationSucceededManual = 'CardholderIdentityValidationSucceededManual';
    public static CardholderIdentityValidationFailed = 'CardholderIdentityValidationFailed';
    public static CardholderIdentityValidationFailedBiometricMismatch = 'CardholderIdentityValidationFailedBiometricMismatch';
    public static CardholderIdentityValidationFailedBiometricMissing = 'CardholderIdentityValidationFailedBiometricMissing';
    public static CardholderIdentityValidationFailedManual = 'CardholderIdentityValidationFailedManual';
    public static CardholderIdentityValidationFailedTimeout = 'CardholderIdentityValidationFailedTimeout';
    public static CardholderSinglePersonCheckFailed = 'CardholderSinglePersonCheckFailed';
    public static CardholderSinglePersonCheckFailedNoPresenceDetected = 'CardholderSinglePersonCheckFailedNoPresenceDetected';
    public static CardholderSinglePersonCheckFailedTooManyPeople = 'CardholderSinglePersonCheckFailedTooManyPeople';
    public static CredentialRequested = 'CredentialRequested';
    public static CredentialRequestedComplete = 'CredentialRequestedComplete';
    public static CredentialRequestedCancelled = 'CredentialRequestedCancelled';
    public static CredentialDoubleBadgeOn = 'CredentialDoubleBadgeOn';
    public static CredentialDoubleBadgeOff = 'CredentialDoubleBadgeOff';
    public static ApplicationFirst = 'ApplicationFirst';
    public static ApplicationLast = 'ApplicationLast';
    public static CameraFirst = 'CameraFirst';
    public static CameraLast = 'CameraLast';
    public static ExternalSystemFirst = 'ExternalSystemFirst';
    public static ExternalSystemAlarmTriggered = 'ExternalSystemAlarmTriggered';
    public static ExternalSystemSyncStarted = 'ExternalSystemSyncStarted';
    public static ExternalSystemSyncCompleted = 'ExternalSystemSyncCompleted';
    public static ExternalSystemSyncError = 'ExternalSystemSyncError';
    public static ExternalSystemSyncPartiallyCompleted = 'ExternalSystemSyncPartiallyCompleted';
    public static ExternalSystemLast = 'ExternalSystemLast';
    public static ZoneFirst = 'ZoneFirst';
    public static ZoneLast = 'ZoneLast';
    public static ElevatorFirst = 'ElevatorFirst';
    public static ElevatorLast = 'ElevatorLast';
    public static MacroFirst = 'MacroFirst';
    public static MacroLast = 'MacroLast';
    public static LprLast = 'LprLast';
    public static LprUpdatePackage = 'LprUpdatePackage';
    public static InvalidatePatrollerMlpiReadsResultEvent = 'InvalidatePatrollerMlpiReadsResultEvent';
    public static MlpiLast = 'MlpiLast';
    public static IntrusionAreaFirst = 'IntrusionAreaFirst';
    public static IntrusionAreaLast = 'IntrusionAreaLast';
    public static IntrusionUnitInputFirst = 'IntrusionUnitInputFirst';
    public static IntrusionUnitInputTrouble = 'IntrusionUnitInputTrouble';
    public static IntrusionUnitInputBypassActivated = 'IntrusionUnitInputBypassActivated';
    public static IntrusionUnitInputBypassDeactivated = 'IntrusionUnitInputBypassDeactivated';
    public static IntrusionUnitInputLast = 'IntrusionUnitInputLast';
    public static ArchiverEventsFirst = 'ArchiverEventsFirst';
    public static ArchiverCanWrite = 'ArchiverCanWrite';
    public static ArchiverDatabaseLost = 'ArchiverDatabaseLost';
    public static ArchiverDatabaseRecovered = 'ArchiverDatabaseRecovered';
    public static ArchiverDiskChanged = 'ArchiverDiskChanged';
    public static ArchiverDiskFull = 'ArchiverDiskFull';
    public static ArchiverDiskLoad = 'ArchiverDiskLoad';
    public static ArchiverInvalidCustomEncryption = 'ArchiverInvalidCustomEncryption';
    public static ArchiverNoWrite = 'ArchiverNoWrite';
    public static ArchiverNoWriteOnSpecifiedDisk = 'ArchiverNoWriteOnSpecifiedDisk';
    public static ArchiverArchivingStopped = 'ArchiverArchivingStopped';
    public static ArchiverBackupStarted = 'ArchiverBackupStarted';
    public static ArchiverBackupSucceeded = 'ArchiverBackupSucceeded';
    public static ArchiverBackupFailed = 'ArchiverBackupFailed';
    public static ArchiverProtectionThresholdExceeded = 'ArchiverProtectionThresholdExceeded';
    public static ArchiverVrmConnectionAttempt = 'ArchiverVrmConnectionAttempt';
    public static ArchiverVrmConnectionFailure = 'ArchiverVrmConnectionFailure';
    public static ArchiverPathTooLong = 'ArchiverPathTooLong';
    public static ArchiverFileProtectionSuccess = 'ArchiverFileProtectionSuccess';
    public static ArchiverFileProtectionFailure = 'ArchiverFileProtectionFailure';
    public static ArchiverDatabaseFlooding = 'ArchiverDatabaseFlooding';
    public static ArchiverEventsLast = 'ArchiverEventsLast';
    public static VideoAnalyticsFirst = 'VideoAnalyticsFirst';
    public static VideoAnalyticsTemperatureDetectionOn = 'VideoAnalyticsTemperatureDetectionOn';
    public static VideoAnalyticsTemperatureDetectionOff = 'VideoAnalyticsTemperatureDetectionOff';
    public static VideoAnalyticsTemperatureDetectionAlarm = 'VideoAnalyticsTemperatureDetectionAlarm';
    public static VideoAnalyticsAdaptiveMotion = 'VideoAnalyticsAdaptiveMotion';
    public static VideoAnalyticsObjectCountReached = 'VideoAnalyticsObjectCountReached';
    public static VideoAnalyticsLast = 'VideoAnalyticsLast';
    public static AssetFirst = 'AssetFirst';
    public static AssetLast = 'AssetLast';
    public static HealthMonitoringFirst = 'HealthMonitoringFirst';
    public static HealthMonitoringLast = 'HealthMonitoringLast';
    public static IntrusionUnitFirst = 'IntrusionUnitFirst';
    public static IntrusionUnitLast = 'IntrusionUnitLast';
    public static ThreatLevelFirst = 'ThreatLevelFirst';
    public static ThreatLevelLast = 'ThreatLevelLast';
    public static AutoActionExecute = 'AutoActionExecute';
    public static DeviceFirst = 'DeviceFirst';
    public static InputStateActive = 'InputStateActive';
    public static InputStateNormal = 'InputStateNormal';
    public static InputStateTrouble = 'InputStateTrouble';
    public static InputAlarmActive = 'InputAlarmActive';
    public static InputAlarmRestored = 'InputAlarmRestored';
    public static InputBypassed = 'InputBypassed';
    public static InputBypassRestored = 'InputBypassRestored';
    public static DeviceLast = 'DeviceLast';
    public static InputStateChanged = 'InputStateChanged';
    public static ParkingZoneFirst = 'ParkingZoneFirst';
    public static ParkingSessionStartedByVehicleEntered = 'ParkingSessionStartedByVehicleEntered';
    public static ParkingSessionStartedByUnknownVehicleExited = 'ParkingSessionStartedByUnknownVehicleExited';
    public static ParkingSessionConvenienceTimeStarted = 'ParkingSessionConvenienceTimeStarted';
    public static ParkingSessionValidatingPaidTimeByPaidTimeExpired = 'ParkingSessionValidatingPaidTimeByPaidTimeExpired';
    public static ParkingSessionValidatingPaidTimeByConvenienceTimeExpired = 'ParkingSessionValidatingPaidTimeByConvenienceTimeExpired';
    public static ParkingsessionPaidTimeStartedByPaidTimeValid = 'ParkingsessionPaidTimeStartedByPaidTimeValid';
    public static ParkingSessionPaidTimeStartedByValidationError = 'ParkingSessionPaidTimeStartedByValidationError';
    public static ParkingSessionGracePeriodStartedConvenienceTimeExpired = 'ParkingSessionGracePeriodStartedConvenienceTimeExpired';
    public static ParkingSessionGracePeriodStartedByPaidTimeInvalid = 'ParkingSessionGracePeriodStartedByPaidTimeInvalid';
    public static ParkingSessionViolationDetectedByConvenienceTimeExpired = 'ParkingSessionViolationDetectedByConvenienceTimeExpired';
    public static ParkingSessionViolationDetectedByGracePeriodExpired = 'ParkingSessionViolationDetectedByGracePeriodExpired';
    public static ParkingSessionViolationDetectedByPaidTimeInvalid = 'ParkingSessionViolationDetectedByPaidTimeInvalid';
    public static ParkingSessionViolationEnforced = 'ParkingSessionViolationEnforced';
    public static ParkingSessionCompletedByInventoryReset = 'ParkingSessionCompletedByInventoryReset';
    public static ParkingSessionCompletedByMaxSessionTimeExceeded = 'ParkingSessionCompletedByMaxSessionTimeExceeded';
    public static ParkingSessionCompletedByVehicledExited = 'ParkingSessionCompletedByVehicledExited';
    public static ParkingSessionCompletedByUnknownVehicledExited = 'ParkingSessionCompletedByUnknownVehicledExited';
    public static ParkingZoneInventoryReset = 'ParkingZoneInventoryReset';
    public static ParkingZoneMaximumCapacityReached = 'ParkingZoneMaximumCapacityReached';
    public static ParkingSessionStarted = 'ParkingSessionStarted';
    public static ParkingSessionValidatingPaidTime = 'ParkingSessionValidatingPaidTime';
    public static ParkingSessionPaidTimeStarted = 'ParkingSessionPaidTimeStarted';
    public static ParkingSessionGracePeriodStarted = 'ParkingSessionGracePeriodStarted';
    public static ParkingSessionViolationDetected = 'ParkingSessionViolationDetected';
    public static ParkingSessionCompleted = 'ParkingSessionCompleted';
    public static ParkingZoneLast = 'ParkingZoneLast';
    public static AlarmContextChanged = 'AlarmContextChanged';
    public static AudioAnalyticsFirst = 'AudioAnalyticsFirst';
    public static AudioAnalyticsCustom = 'AudioAnalyticsCustom';
    public static AudioAnalyticsLast = 'AudioAnalyticsLast';
    public static ActiveAlarms = 'ActiveAlarms';
    public static ActiveAlarmsRecovered = 'ActiveAlarmsRecovered';
    public static AgentStarted = 'AgentStarted';
    public static AgentStopped = 'AgentStopped';
    public static AgentStoppedUnexpectedly = 'AgentStoppedUnexpectedly';
    public static AllArchivingRestarted = 'AllArchivingRestarted';
    public static AllArchivingStopped = 'AllArchivingStopped';
    public static ApplicationConnect = 'ApplicationConnect';
    public static ApplicationDisconnectedByUser = 'ApplicationDisconnectedByUser';
    public static ApplicationDisconnectedUnexpectedly = 'ApplicationDisconnectedUnexpectedly';
    public static ApplicationStarted = 'ApplicationStarted';
    public static ApplicationStoppedByUser = 'ApplicationStoppedByUser';
    public static ApplicationStoppedUnexpectedl = 'ApplicationStoppedUnexpectedl';
    public static ArchiveBackupFailure = 'ArchiveBackupFailure';
    public static ArchiveBackupSuccess = 'ArchiveBackupSuccess';
    public static ArchiveConsolidationFailure = 'ArchiveConsolidationFailure';
    public static ArchiveConsolidationPartialFailure = 'ArchiveConsolidationPartialFailure';
    public static ArchiveConsolidationSuccess = 'ArchiveConsolidationSuccess';
    public static ArchiverTricklingFailure = 'ArchiverTricklingFailure';
    public static ArchiverTricklingPartialFailure = 'ArchiverTricklingPartialFailure';
    public static ArchiverTricklingSuccess = 'ArchiverTricklingSuccess';
    public static ArchiverTricklingRetry = 'ArchiverTricklingRetry';
    public static ArchiverTricklingCancel = 'ArchiverTricklingCancel';
    public static CameraConnectionEstablished = 'CameraConnectionEstablished';
    public static CameraConnectionStoppedByUser = 'CameraConnectionStoppedByUser';
    public static CameraConnectionStoppedUnexpectedly = 'CameraConnectionStoppedUnexpectedly';
    public static CameraRecordingProblem = 'CameraRecordingProblem';
    public static CameraRecordingProblemRecovered = 'CameraRecordingProblemRecovered';
    public static ConnectionFailed = 'ConnectionFailed';
    public static ConnectionRestored = 'ConnectionRestored';
    public static DatabaseAutomaticBackupFailed = 'DatabaseAutomaticBackupFailed';
    public static DatabaseAutomaticBackupRecovered = 'DatabaseAutomaticBackupRecovered';
    public static DatabaseFailoverMasterLost = 'DatabaseFailoverMasterLost';
    public static DatabaseFailoverMasterRecovered = 'DatabaseFailoverMasterRecovered';
    public static DatabaseLost = 'DatabaseLost';
    public static DatabaseRecovered = 'DatabaseRecovered';
    public static DatabaseRestoreFailure = 'DatabaseRestoreFailure';
    public static DatabaseRestoreSuccess = 'DatabaseRestoreSuccess';
    public static DirectoryStarted = 'DirectoryStarted';
    public static DirectoryStoppedByUser = 'DirectoryStoppedByUser';
    public static DirectoryStoppedUnexpectedly = 'DirectoryStoppedUnexpectedly';
    public static HighCPUUsage = 'HighCPUUsage';
    public static HighCPUUsageRecovered = 'HighCPUUsageRecovered';
    public static HighMemoryUsage = 'HighMemoryUsage';
    public static HighMemoryUsageRecovered = 'HighMemoryUsageRecovered';
    public static LiveHealthMonitoringRecovered = 'LiveHealthMonitoringRecovered';
    public static LiveHealthMonitoringStopped = 'LiveHealthMonitoringStopped';
    public static LowArchiveSpace = 'LowArchiveSpace';
    public static LowArchiveSpaceRecovered = 'LowArchiveSpaceRecovered';
    public static LowDatabaseSpace = 'LowDatabaseSpace';
    public static LowDatabaseSpaceRecovered = 'LowDatabaseSpaceRecovered';
    public static OffloadFailed = 'OffloadFailed';
    public static OffloadRecovered = 'OffloadRecovered';
    public static PatrollerOffline = 'PatrollerOffline';
    public static PatrollerOnline = 'PatrollerOnline';
    public static POSDatabaseLost = 'POSDatabaseLost';
    public static POSDatabaseRecovered = 'POSDatabaseRecovered';
    public static RoleStarted = 'RoleStarted';
    public static RoleStoppedByUser = 'RoleStoppedByUser';
    public static RoleStoppedUnexpectedly = 'RoleStoppedUnexpectedly';
    public static RTPPacketLoss = 'RTPPacketLoss';
    public static RTPPacketLossRecovered = 'RTPPacketLossRecovered';
    public static ServiceStarted = 'ServiceStarted';
    public static ServiceStoppedByUser = 'ServiceStoppedByUser';
    public static ServiceStoppedUnexpectedly = 'ServiceStoppedUnexpectedly';
    public static SynchronizationFailed = 'SynchronizationFailed';
    public static SynchronizationRecovered = 'SynchronizationRecovered';
    public static TransmissionLost = 'TransmissionLost';
    public static TransmissionRecovered = 'TransmissionRecovered';
    public static UnauthorizedDisk = 'UnauthorizedDisk';
    public static UnauthorizedDiskRecovered = 'UnauthorizedDiskRecovered';
    public static UnitConnectionFailed = 'UnitConnectionFailed';
    public static UnitConnectionRestored = 'UnitConnectionRestored';
    public static UnitConnectionStopped = 'UnitConnectionStopped';
    public static UnitTimeDrift = 'UnitTimeDrift';
    public static UnitTimeDriftRecovered = 'UnitTimeDriftRecovered';
    public static UnitTricklingFailure = 'UnitTricklingFailure';
    public static UnitTricklingPartialFailure = 'UnitTricklingPartialFailure';
    public static UnitTricklingSuccess = 'UnitTricklingSuccess';
    public static VideoSignalLoss = 'VideoSignalLoss';
    public static VideoSignalRecovered = 'VideoSignalRecovered';
    public static EdgeStorageRecovered = 'EdgeStorageRecovered';
    public static EdgeStorageFailure = 'EdgeStorageFailure';
    public static EdgeStorageRecordingRecovered = 'EdgeStorageRecordingRecovered';
    public static EdgeStorageRecordingFailure = 'EdgeStorageRecordingFailure';
    public static CertificateValid = 'CertificateValid';
    public static CertificateWarning = 'CertificateWarning';
    public static CertificateError = 'CertificateError';
    public static ValidDriverPack = 'ValidDriverPack';
    public static InvalidDriverPack = 'InvalidDriverPack';
    public static ArchivingNotMonitored = 'ArchivingNotMonitored';
    public static ApplicationConnectionNotMonitored = 'ApplicationConnectionNotMonitored';
    public static ApplicationNotMonitored = 'ApplicationNotMonitored';
    public static ConnectionNotMonitored = 'ConnectionNotMonitored';
    public static UnitConnectionNotMonitored = 'UnitConnectionNotMonitored';
    public static DatabaseAutomaticBackupNotMonitored = 'DatabaseAutomaticBackupNotMonitored';
    public static DatabaseNotMonitored = 'DatabaseNotMonitored';
    public static HighCPUUsageNotMonitored = 'HighCPUUsageNotMonitored';
    public static HighMemoryUsageNotMonitored = 'HighMemoryUsageNotMonitored';
    public static LowDatabaseSpaceNotMonitored = 'LowDatabaseSpaceNotMonitored';
    public static OffloadNotMonitored = 'OffloadNotMonitored';
    public static PatrollerNotMonitored = 'PatrollerNotMonitored';
    public static POSDatabaseNotMonitored = 'POSDatabaseNotMonitored';
    public static RoleNotMonitored = 'RoleNotMonitored';
    public static RTPPacketNotMonitored = 'RTPPacketNotMonitored';
    public static ServiceNotMonitored = 'ServiceNotMonitored';
    public static SynchronizationNotMonitored = 'SynchronizationNotMonitored';
    public static VideoSignalNotMonitored = 'VideoSignalNotMonitored';
    public static UnauthorizedDiskNotMonitored = 'UnauthorizedDiskNotMonitored';
    public static ActiveAlarmsNotMonitored = 'ActiveAlarmsNotMonitored';
    public static DirectoryNotMonitored = 'DirectoryNotMonitored';
    public static LowArchiveSpaceNotMonitored = 'LowArchiveSpaceNotMonitored';
    public static LiveHealthMonitoringNotMonitored = 'LiveHealthMonitoringNotMonitored';
    public static DatabaseFailoverMasterNotMonitored = 'DatabaseFailoverMasterNotMonitored';
    public static DatabaseRestoreNotMonitored = 'DatabaseRestoreNotMonitored';
    public static UnitTricklingNotMonitored = 'UnitTricklingNotMonitored';
    public static ArchiveBackupNotMonitored = 'ArchiveBackupNotMonitored';
    public static AgentNotMonitored = 'AgentNotMonitored';
    public static ArchiveConsolidationNotMonitored = 'ArchiveConsolidationNotMonitored';
    public static CameraRecordingNotMonitored = 'CameraRecordingNotMonitored';
    public static CameraConnectionNotMonitored = 'CameraConnectionNotMonitored';
    public static UnitTimeDriftNotMonitored = 'UnitTimeDriftNotMonitored';
    public static TransmissionNotMonitored = 'TransmissionNotMonitored';
    public static EdgeStorageNotMonitored = 'EdgeStorageNotMonitored';
    public static EdgeStorageRecordingNotMonitored = 'EdgeStorageRecordingNotMonitored';
    public static ArchiverTricklingNotMonitored = 'ArchiverTricklingNotMonitored';
    public static CertificateNotMonitored = 'CertificateNotMonitored';
    public static DriverPackNotMonitored = 'DriverPackNotMonitored';
    public static ScTokenValid = 'ScTokenValid';
    public static ScTokenError = 'ScTokenError';
    public static ScTokenNotMonitored = 'ScTokenNotMonitored';
    public static EntityOnline = 'EntityOnline';
    public static EntityOffline = 'EntityOffline';
    public static EntityNotMonitored = 'EntityNotMonitored';
    public static OptimizedDirectoryStartupSucceeded = 'OptimizedDirectoryStartupSucceeded';
    public static OptimizedDirectoryStartupFailed = 'OptimizedDirectoryStartupFailed';
    public static OptimizedDirectoryStartupNotMonitored = 'OptimizedDirectoryStartupNotMonitored';
    public static CommunicationsInviteSent = 'CommunicationsInviteSent';
    public static CommunicationsCallFirst = 'CommunicationsCallFirst';
    public static CommunicationsCallRinging = 'CommunicationsCallRinging';
    public static CommunicationsCallDeclined = 'CommunicationsCallDeclined';
    public static CommunicationsCallAnswered = 'CommunicationsCallAnswered';
    public static CommunicationsCallRejected = 'CommunicationsCallRejected';
    public static CommunicationsCallTimeout = 'CommunicationsCallTimeout';
    public static CommunicationsCallEnded = 'CommunicationsCallEnded';
    public static CommunicationsCallStarted = 'CommunicationsCallStarted';
    public static CommunicationsCallLast = 'CommunicationsCallLast';
    public static CommunicationsContactFirst = 'CommunicationsContactFirst';
    public static CommunicationsContactRegistered = 'CommunicationsContactRegistered';
    public static CommunicationsContactUnregistered = 'CommunicationsContactUnregistered';
    public static CommunicationsContactLast = 'CommunicationsContactLast';
    public static CommunicationsTrunkFirst = 'CommunicationsTrunkFirst';
    public static CommunicationsTrunkRegistered = 'CommunicationsTrunkRegistered';
    public static CommunicationsTrunkUnregistered = 'CommunicationsTrunkUnregistered';
    public static CommunicationsTrunkLast = 'CommunicationsTrunkLast';
    public static CorrelationFirst = 'CorrelationFirst';
    public static CorrelationEvent = 'CorrelationEvent';
    public static CorrelationRuleHitEvent = 'CorrelationRuleHitEvent';
    public static CorrelationLast = 'CorrelationLast';
    public static SecretKeyEvent = 'SecretKeyEvent';

    public static readonly values = [
        EventTypes.None,
        EventTypes.Invalidate,
        EventTypes.ManualAction,
        EventTypes.AlarmTriggered,
        EventTypes.AlarmAcknowledged,
        EventTypes.ApplicationConnected,
        EventTypes.ApplicationDisconnected,
        EventTypes.ServerLost,
        EventTypes.CameraLiveBookmarkAdded,
        EventTypes.CameraPlaybackBookmarkAdded,
        EventTypes.CameraMotionOn,
        EventTypes.CameraMotionOff,
        EventTypes.CardholderAccessGranted,
        EventTypes.CameraAbnormalMotion,
        EventTypes.CameraNominalMotion,
        EventTypes.CardholderAccessRefused,
        EventTypes.CardholderWalkthrough,
        EventTypes.CardholderInactive,
        EventTypes.CardHolderCredentialExpired,
        EventTypes.CardHolderCredentialInactive,
        EventTypes.CardHolderCredentialLost,
        EventTypes.CardHolderCredentialStolen,
        EventTypes.CardholderAntipassback,
        EventTypes.CustomEvent,
        EventTypes.UnitConnected,
        EventTypes.UnitDisconnected,
        EventTypes.InterfaceOnline,
        EventTypes.InterfaceOffline,
        EventTypes.AccessControlUnitWarningActivated,
        EventTypes.AccessControlUnitWarningDeactivated,
        EventTypes.FirmwareUpgradeScheduled,
        EventTypes.FirmwareUpgradeCancelled,
        EventTypes.InterfaceFirmwareUpgradeStarted,
        EventTypes.InterfaceFirmwareUpgradeSuccess,
        EventTypes.InterfaceFirmwareUpgradeFailure,
        EventTypes.InterfaceFirmwareUpgradeScheduled,
        EventTypes.InterfaceFirmwareUpgradeCancelled,
        EventTypes.InterfaceFirmwareUpgradeTransferred,
        EventTypes.UnitACFail,
        EventTypes.UnitBatFail,
        EventTypes.UnitInvalidConfiguration,
        EventTypes.UnitTemperatureAlarm,
        EventTypes.UnitTimeOutOfSync,
        EventTypes.UnitTimeOutOfSyncRecovered,
        EventTypes.UnitDiskActionError,
        EventTypes.UnitDiskFormatted,
        EventTypes.UnitDiskLocked,
        EventTypes.UnitDiskMounted,
        EventTypes.UnitDiskUnlocked,
        EventTypes.UnitDiskUnmounted,
        EventTypes.ArchiveTransferSequenceImported,
        EventTypes.UserLoggedOn,
        EventTypes.UserLoggedOff,
        EventTypes.UserLogonFailed,
        EventTypes.FirmwareUpgradeStarted,
        EventTypes.FirmwareUpgradeSuccess,
        EventTypes.FirmwareUpgradeFailure,
        EventTypes.DoorRexOn,
        EventTypes.DoorRexOff,
        EventTypes.DoorOpenedForTooLong,
        EventTypes.DoorPullStationActivated,
        EventTypes.DoorPullStationNormal,
        EventTypes.DoorOpen,
        EventTypes.DoorClose,
        EventTypes.DoorForcedOpen,
        EventTypes.DoorLock,
        EventTypes.DoorUnlock,
        EventTypes.DoorOpenWhileLockSecure,
        EventTypes.AccessUnknownCredential,
        EventTypes.AccessUnassignedCredential,
        EventTypes.AccessGranted,
        EventTypes.AccessRefused,
        EventTypes.AccessExpiredCredential,
        EventTypes.AccessStolenCredential,
        EventTypes.AccessLostCredential,
        EventTypes.AccessInactiveCredential,
        EventTypes.AccessInactivePerson,
        EventTypes.DoorTamper,
        EventTypes.InputTroubleShort,
        EventTypes.InputTroubleOpen,
        EventTypes.EmergencyDoorRex,
        EventTypes.EmergencyDoorUnlockPostponed,
        EventTypes.EmergencyDoorUnlocked,
        EventTypes.EmergencyDoorUnlockedOperatorUnlock,
        EventTypes.EmergencyDoorUnlockedReleaseDelayTimeExpired,
        EventTypes.EmergencyDoorUnlockedExtensionGuardLockingExpired,
        EventTypes.EmergencyDoorUnlockedMaximumLockingTimeExpired,
        EventTypes.EmergencyDoorUnlockedHostConnectionTimeout,
        EventTypes.EmergencyDoorReset,
        EventTypes.EmergencyDoorResetRemoteOperator,
        EventTypes.EmergencyDoorResetPhysicalButton,
        EventTypes.EmergencyDoorResetAuto,
        EventTypes.DoorTestStarted,
        EventTypes.DoorTestSucceeded,
        EventTypes.DoorTestFailed,
        EventTypes.DoorTestFailedAborted,
        EventTypes.DoorTestFailedReaderShunted,
        EventTypes.DoorTestFailedDoorNotSecured,
        EventTypes.DoorTestFailedError,
        EventTypes.DoorTestFailedUnlockFailed,
        EventTypes.DoorTestFailedRelockFailed,
        EventTypes.AreaFirstManIn,
        EventTypes.AreaLastManOut,
        EventTypes.AreaAntipassbackDetected,
        EventTypes.AreaAntipassbackForgiven,
        EventTypes.PeopleCountReset,
        EventTypes.AreaCardholderRemoved,
        EventTypes.AreaCardholderAdded,
        EventTypes.CredentialUnassigned,
        EventTypes.CredentialExpired,
        EventTypes.CredentialStolen,
        EventTypes.CredentialLost,
        EventTypes.CredentialInactive,
        EventTypes.CredentialHasExpired,
        EventTypes.CredentialIsExpiring,
        EventTypes.ZoneGlassBreak,
        EventTypes.ZoneWindowOpened,
        EventTypes.ZoneWindowClosed,
        EventTypes.ZoneDeadBoltLocked,
        EventTypes.ZoneDeadBoltUnlocked,
        EventTypes.ZoneKnobInplace,
        EventTypes.ZoneKnobRotated,
        EventTypes.ZoneLockSecured,
        EventTypes.ZoneLockReleased,
        EventTypes.MacroStarted,
        EventTypes.MacroCompleted,
        EventTypes.MacroAborted,
        EventTypes.AlarmAcknowledgedAlternate,
        EventTypes.LprFirst,
        EventTypes.LprRead,
        EventTypes.LprHit,
        EventTypes.ProcessMlpiResultsResultEvent,
        EventTypes.LprOffloadSucceeded,
        EventTypes.LprOffloadFailed,
        EventTypes.LprNoMatch,
        EventTypes.LprHotlistChanged,
        EventTypes.LprUpdateInstalled,
        EventTypes.LprUpdateUninstalled,
        EventTypes.LprUpdatePublished,
        EventTypes.LprUpdateError,
        EventTypes.LprUpdateInstalling,
        EventTypes.LprUpdateUninstalling,
        EventTypes.IntrusionAreaDuress,
        EventTypes.IntrusionAreaEntryDelay,
        EventTypes.IntrusionAreaAlarmActivated,
        EventTypes.IntrusionAreaArming,
        EventTypes.IntrusionAreaArmingPostponed,
        EventTypes.IntrusionAreaMasterArmed,
        EventTypes.IntrusionAreaPerimeterArmed,
        EventTypes.IntrusionAreaDisarmed,
        EventTypes.IntrusionAreaInputTrouble,
        EventTypes.IntrusionAreaInputBypassActivated,
        EventTypes.IntrusionAreaInputBypassDeactivated,
        EventTypes.IntrusionAreaForcedArming,
        EventTypes.IntrusionAreaCanceledAlarm,
        EventTypes.IntrusionAreaMasterArmRequest,
        EventTypes.IntrusionAreaPerimeterArmRequest,
        EventTypes.IntrusionAreaDisarmRequest,
        EventTypes.IntrusionAreaArmingPostponedRequest,
        EventTypes.IntrusionAreaCanceledAlarmRequest,
        EventTypes.IntrusionAreaAlarmSilenced,
        EventTypes.IntrusionUnitTamper,
        EventTypes.ZoneArmed,
        EventTypes.ZoneDisarmed,
        EventTypes.ZoneMaintenanceOn,
        EventTypes.ZoneMaintenanceOff,
        EventTypes.ZoneWarningUnitOffline,
        EventTypes.DoorMaintenanceOff,
        EventTypes.DoorMaintenanceOn,
        EventTypes.DoorScheduledLock,
        EventTypes.DoorScheduledUnlock,
        EventTypes.DoorWarningUnitOffline,
        EventTypes.DoorWarningInterfaceAcFail,
        EventTypes.DoorWarningInterfaceAcFailRestored,
        EventTypes.DoorWarningInterfaceBatteryFail,
        EventTypes.DoorWarningInterfaceBatteryFailRestored,
        EventTypes.DoorWarningInterfaceTamperFail,
        EventTypes.DoorWarningInterfaceTamperFailRestored,
        EventTypes.DoorManuallyUnlocked,
        EventTypes.DoorOnline,
        EventTypes.DoorUnsecured,
        EventTypes.DoorSecured,
        EventTypes.VideoAnalyticsAudioAlarm,
        EventTypes.VideoAnalyticsLicensePlateInSight,
        EventTypes.VideoAnalyticsLicensePlateOutofSight,
        EventTypes.VideoAnalyticsLicensePlateReading,
        EventTypes.VideoAnalyticsObjectConditionChange,
        EventTypes.VideoAnalyticsObjectCrossedLine,
        EventTypes.VideoAnalyticsObjectEntered,
        EventTypes.VideoAnalyticsObjectExited,
        EventTypes.VideoAnalyticsObjectFall,
        EventTypes.VideoAnalyticsObjectFollowingRoute,
        EventTypes.VideoAnalyticsObjectInField,
        EventTypes.VideoAnalyticsObjectLeft,
        EventTypes.VideoAnalyticsObjectLoitering,
        EventTypes.VideoAnalyticsObjectMerged,
        EventTypes.VideoAnalyticsObjectRemoved,
        EventTypes.VideoAnalyticsObjectRun,
        EventTypes.VideoAnalyticsObjectSlide,
        EventTypes.VideoAnalyticsObjectSplit,
        EventTypes.VideoAnalyticsObjectStopped,
        EventTypes.VideoAnalyticsTailgating,
        EventTypes.VideoAnalyticsTampering,
        EventTypes.VideoAnalyticsFaceDetected,
        EventTypes.VideoAnalyticsUndefined,
        EventTypes.VideoAnalyticsDirectionAlarm,
        EventTypes.VideoAnalyticsTamperingOff,
        EventTypes.VideoAnalyticsCrowdDetected,
        EventTypes.VideoAnalyticsFaceRecognized,
        EventTypes.VideoAnalyticsObjectDetected,
        EventTypes.VideoAnalyticsHeatMapChanged,
        EventTypes.VideoAnalyticsObjectCountChanged,
        EventTypes.VideoAnalyticsObjectVelocityChanged,
        EventTypes.VideoAnalyticsObjectDirectionChanged,
        EventTypes.VideoAnalyticsPeopleCounting,
        EventTypes.VideoAnalyticsPeopleCountingAlarm,
        EventTypes.VideoAnalyticsCrowdSize,
        EventTypes.FloorAccess,
        EventTypes.AssetMoved,
        EventTypes.AssetLowBattery,
        EventTypes.AssetOffline,
        EventTypes.AssetOnline,
        EventTypes.ArchivingStartedAutomatically,
        EventTypes.ArchivingStoppedAutomatically,
        EventTypes.ArchivingStartedManually,
        EventTypes.ArchivingStoppedManually,
        EventTypes.ArchivingStartedOnMotionEvent,
        EventTypes.ArchivingStoppedOnMotionEvent,
        EventTypes.ArchivingStartedByExternalSource,
        EventTypes.ArchivingStoppedByExternalSource,
        EventTypes.ArchivingStartedByAlarm,
        EventTypes.ArchivingStoppedByAlarm,
        EventTypes.CameraStartLiveStreaming,
        EventTypes.CameraStopLiveStreaming,
        EventTypes.ArchiveFileDeleted,
        EventTypes.CameraSignalLost,
        EventTypes.CameraSignalRecovered,
        EventTypes.ArchivingQueueFull,
        EventTypes.CameraNotArchiving,
        EventTypes.CameraTransmissionLost,
        EventTypes.CameraRtpPacketsLost,
        EventTypes.CameraMultipleSourceDetected,
        EventTypes.CameraStartBlock,
        EventTypes.CameraStopBlock,
        EventTypes.CameraTransmissionRecovered,
        EventTypes.CameraEdgeRecordingCommandTimeout,
        EventTypes.CameraEdgestorageFailure,
        EventTypes.CameraMotion,
        EventTypes.CameraEdgestorageEnabled,
        EventTypes.CameraRtpPacketLostRecovery,
        EventTypes.PtzActivated,
        EventTypes.PtzStopped,
        EventTypes.PtzZoomByUserStarted,
        EventTypes.PtzZoomByUserStopped,
        EventTypes.PtzLocked,
        EventTypes.HealthMonitoringEvent,
        EventTypes.AreaAntipassbackDisabledMixedMode,
        EventTypes.AreaAntipassbackDisabledUnitOffline,
        EventTypes.AreaPeopleCountDisabledUnitOffline,
        EventTypes.AreaAntipassbackDisabledPerimeterOnElevator,
        EventTypes.AreaSasOverrideOn,
        EventTypes.AreaSasOverrideOff,
        EventTypes.AreaSasLockdownOn,
        EventTypes.AreaSasLockdownOff,
        EventTypes.AreaSasMultipleUnitWarning,
        EventTypes.AreaSasUnsupportedWarning,
        EventTypes.AreaSasMaintenanceModeWarning,
        EventTypes.AreaSasFreeAccessWarning,
        EventTypes.AreaSasAntipassbackWarning,
        EventTypes.AreaSasFloorWarning,
        EventTypes.AreaAntipassbackDisabledInvalidSettings,
        EventTypes.AreaSasDoorWithNoSensorConfigured,
        EventTypes.AreaSasOnlyOnePerimeterFloor,
        EventTypes.SinglePersonCheckFailed,
        EventTypes.SinglePersonCheckFailedNoPresenceDetected,
        EventTypes.SinglePersonCheckFailedTooManyPeople,
        EventTypes.AreaEvacuationStarted,
        EventTypes.AreaEvacuationEnded,
        EventTypes.AreaMusteringStarted,
        EventTypes.AreaMusteringEnded,
        EventTypes.AreaLast,
        EventTypes.ElevatorWarningUnitOffline,
        EventTypes.ElevatorSecuredOnSchedule,
        EventTypes.ElevatorFreeAccessOnSchedule,
        EventTypes.AlarmInvestigating,
        EventTypes.AlarmForciblyAcked,
        EventTypes.ThreatLevelActivated,
        EventTypes.ThreatLevelDeactivated,
        EventTypes.CardholderNoEntryDetected,
        EventTypes.DoorNoEntryDetected,
        EventTypes.CardholderEntryDetected,
        EventTypes.CardholderEntryAssumed,
        EventTypes.VisitorAstray,
        EventTypes.MissingTailEscort,
        EventTypes.DoorEntryDetected,
        EventTypes.DoorEntryAssumed,
        EventTypes.DoorSupervisorInAccessRule,
        EventTypes.DoorSupervisorInUnlockingSchedule,
        EventTypes.AreaMaxOccupancyReached,
        EventTypes.AreaMaxOccupancyExceeded,
        EventTypes.AreaBelowMaxOccupancy,
        EventTypes.DuressPinEntered,
        EventTypes.DoubleBadgeOn,
        EventTypes.DoubleBadgeOff,
        EventTypes.AccessGrantConfirmationRequested,
        EventTypes.IdentityValidationSucceeded,
        EventTypes.IdentityValidationSucceededBiometric,
        EventTypes.IdentityValidationSucceededManual,
        EventTypes.IdentityValidationFailed,
        EventTypes.IdentityValidationFailedBiometricMismatch,
        EventTypes.IdentityValidationFailedBiometricMissing,
        EventTypes.IdentityValidationFailedManual,
        EventTypes.IdentityValidationFailedTimeout,
        EventTypes.OfflineSynchronizationStarted,
        EventTypes.OfflineSynchronizationFailure,
        EventTypes.OfflineSynchronizationSuccess,
        EventTypes.AccessInvalidPin,
        EventTypes.AccessValidCardInvalidPin,
        EventTypes.AccessDeniedByAccessRule,
        EventTypes.AccessInsufficientPrivileges,
        EventTypes.AccessAntipassbackViolation,
        EventTypes.AccessOutOfSchedule,
        EventTypes.AccessNoAccessRuleAssigned,
        EventTypes.AccessAbsentSupervisor,
        EventTypes.AccessEscortRequired,
        EventTypes.AccessSecondCardholderRequired,
        EventTypes.AccessEscortNotSupported,
        EventTypes.CompanionVisitorDenied,
        EventTypes.Interlock,
        EventTypes.AccessCardAndPINTimeout,
        EventTypes.AccessValidCardInactivePIN,
        EventTypes.AccessDeniedByMaxOccupancy,
        EventTypes.UID,
        EventTypes.Warning,
        EventTypes.UID2,
        EventTypes.UserFirst,
        EventTypes.ModifiedIncident,
        EventTypes.UserLast,
        EventTypes.AccessPointFirst,
        EventTypes.AccessPointLast,
        EventTypes.AlarmFirst,
        EventTypes.AlarmConditionCleared,
        EventTypes.AlarmLast,
        EventTypes.DoorFirst,
        EventTypes.ScheduleUnlockIgnored,
        EventTypes.DoorLast,
        EventTypes.AreaFirst,
        EventTypes.UnitFirst,
        EventTypes.UnitLast,
        EventTypes.CredentialFirst,
        EventTypes.CredentialAccessDenied,
        EventTypes.CredentialLast,
        EventTypes.CardholderFirst,
        EventTypes.CardholderLast,
        EventTypes.CardholderDuressPinEntered,
        EventTypes.CardholderDoubleBadgeOn,
        EventTypes.CardholderDoubleBadgeOff,
        EventTypes.CardholderAccessGrantConfirmationRequested,
        EventTypes.CardholderIdentityValidationSucceeded,
        EventTypes.CardholderIdentityValidationSucceededBiometric,
        EventTypes.CardholderIdentityValidationSucceededManual,
        EventTypes.CardholderIdentityValidationFailed,
        EventTypes.CardholderIdentityValidationFailedBiometricMismatch,
        EventTypes.CardholderIdentityValidationFailedBiometricMissing,
        EventTypes.CardholderIdentityValidationFailedManual,
        EventTypes.CardholderIdentityValidationFailedTimeout,
        EventTypes.CardholderSinglePersonCheckFailed,
        EventTypes.CardholderSinglePersonCheckFailedNoPresenceDetected,
        EventTypes.CardholderSinglePersonCheckFailedTooManyPeople,
        EventTypes.CredentialRequested,
        EventTypes.CredentialRequestedComplete,
        EventTypes.CredentialRequestedCancelled,
        EventTypes.CredentialDoubleBadgeOn,
        EventTypes.CredentialDoubleBadgeOff,
        EventTypes.ApplicationFirst,
        EventTypes.ApplicationLast,
        EventTypes.CameraFirst,
        EventTypes.CameraLast,
        EventTypes.ExternalSystemFirst,
        EventTypes.ExternalSystemAlarmTriggered,
        EventTypes.ExternalSystemSyncStarted,
        EventTypes.ExternalSystemSyncCompleted,
        EventTypes.ExternalSystemSyncError,
        EventTypes.ExternalSystemSyncPartiallyCompleted,
        EventTypes.ExternalSystemLast,
        EventTypes.ZoneFirst,
        EventTypes.ZoneLast,
        EventTypes.ElevatorFirst,
        EventTypes.ElevatorLast,
        EventTypes.MacroFirst,
        EventTypes.MacroLast,
        EventTypes.LprLast,
        EventTypes.LprUpdatePackage,
        EventTypes.InvalidatePatrollerMlpiReadsResultEvent,
        EventTypes.MlpiLast,
        EventTypes.IntrusionAreaFirst,
        EventTypes.IntrusionAreaLast,
        EventTypes.IntrusionUnitInputFirst,
        EventTypes.IntrusionUnitInputTrouble,
        EventTypes.IntrusionUnitInputBypassActivated,
        EventTypes.IntrusionUnitInputBypassDeactivated,
        EventTypes.IntrusionUnitInputLast,
        EventTypes.ArchiverEventsFirst,
        EventTypes.ArchiverCanWrite,
        EventTypes.ArchiverDatabaseLost,
        EventTypes.ArchiverDatabaseRecovered,
        EventTypes.ArchiverDiskChanged,
        EventTypes.ArchiverDiskFull,
        EventTypes.ArchiverDiskLoad,
        EventTypes.ArchiverInvalidCustomEncryption,
        EventTypes.ArchiverNoWrite,
        EventTypes.ArchiverNoWriteOnSpecifiedDisk,
        EventTypes.ArchiverArchivingStopped,
        EventTypes.ArchiverBackupStarted,
        EventTypes.ArchiverBackupSucceeded,
        EventTypes.ArchiverBackupFailed,
        EventTypes.ArchiverProtectionThresholdExceeded,
        EventTypes.ArchiverVrmConnectionAttempt,
        EventTypes.ArchiverVrmConnectionFailure,
        EventTypes.ArchiverPathTooLong,
        EventTypes.ArchiverFileProtectionSuccess,
        EventTypes.ArchiverFileProtectionFailure,
        EventTypes.ArchiverDatabaseFlooding,
        EventTypes.ArchiverEventsLast,
        EventTypes.VideoAnalyticsFirst,
        EventTypes.VideoAnalyticsTemperatureDetectionOn,
        EventTypes.VideoAnalyticsTemperatureDetectionOff,
        EventTypes.VideoAnalyticsTemperatureDetectionAlarm,
        EventTypes.VideoAnalyticsAdaptiveMotion,
        EventTypes.VideoAnalyticsObjectCountReached,
        EventTypes.VideoAnalyticsLast,
        EventTypes.AssetFirst,
        EventTypes.AssetLast,
        EventTypes.HealthMonitoringFirst,
        EventTypes.HealthMonitoringLast,
        EventTypes.IntrusionUnitFirst,
        EventTypes.IntrusionUnitLast,
        EventTypes.ThreatLevelFirst,
        EventTypes.ThreatLevelLast,
        EventTypes.AutoActionExecute,
        EventTypes.DeviceFirst,
        EventTypes.InputStateActive,
        EventTypes.InputStateNormal,
        EventTypes.InputStateTrouble,
        EventTypes.InputAlarmActive,
        EventTypes.InputAlarmRestored,
        EventTypes.InputBypassed,
        EventTypes.InputBypassRestored,
        EventTypes.DeviceLast,
        EventTypes.InputStateChanged,
        EventTypes.ParkingZoneFirst,
        EventTypes.ParkingSessionStartedByVehicleEntered,
        EventTypes.ParkingSessionStartedByUnknownVehicleExited,
        EventTypes.ParkingSessionConvenienceTimeStarted,
        EventTypes.ParkingSessionValidatingPaidTimeByPaidTimeExpired,
        EventTypes.ParkingSessionValidatingPaidTimeByConvenienceTimeExpired,
        EventTypes.ParkingsessionPaidTimeStartedByPaidTimeValid,
        EventTypes.ParkingSessionPaidTimeStartedByValidationError,
        EventTypes.ParkingSessionGracePeriodStartedConvenienceTimeExpired,
        EventTypes.ParkingSessionGracePeriodStartedByPaidTimeInvalid,
        EventTypes.ParkingSessionViolationDetectedByConvenienceTimeExpired,
        EventTypes.ParkingSessionViolationDetectedByGracePeriodExpired,
        EventTypes.ParkingSessionViolationDetectedByPaidTimeInvalid,
        EventTypes.ParkingSessionViolationEnforced,
        EventTypes.ParkingSessionCompletedByInventoryReset,
        EventTypes.ParkingSessionCompletedByMaxSessionTimeExceeded,
        EventTypes.ParkingSessionCompletedByVehicledExited,
        EventTypes.ParkingSessionCompletedByUnknownVehicledExited,
        EventTypes.ParkingZoneInventoryReset,
        EventTypes.ParkingZoneMaximumCapacityReached,
        EventTypes.ParkingSessionStarted,
        EventTypes.ParkingSessionValidatingPaidTime,
        EventTypes.ParkingSessionPaidTimeStarted,
        EventTypes.ParkingSessionGracePeriodStarted,
        EventTypes.ParkingSessionViolationDetected,
        EventTypes.ParkingSessionCompleted,
        EventTypes.ParkingZoneLast,
        EventTypes.AlarmContextChanged,
        EventTypes.AudioAnalyticsFirst,
        EventTypes.AudioAnalyticsCustom,
        EventTypes.AudioAnalyticsLast,
        EventTypes.ActiveAlarms,
        EventTypes.ActiveAlarmsRecovered,
        EventTypes.AgentStarted,
        EventTypes.AgentStopped,
        EventTypes.AgentStoppedUnexpectedly,
        EventTypes.AllArchivingRestarted,
        EventTypes.AllArchivingStopped,
        EventTypes.ApplicationConnect,
        EventTypes.ApplicationDisconnectedByUser,
        EventTypes.ApplicationDisconnectedUnexpectedly,
        EventTypes.ApplicationStarted,
        EventTypes.ApplicationStoppedByUser,
        EventTypes.ApplicationStoppedUnexpectedl,
        EventTypes.ArchiveBackupFailure,
        EventTypes.ArchiveBackupSuccess,
        EventTypes.ArchiveConsolidationFailure,
        EventTypes.ArchiveConsolidationPartialFailure,
        EventTypes.ArchiveConsolidationSuccess,
        EventTypes.ArchiverTricklingFailure,
        EventTypes.ArchiverTricklingPartialFailure,
        EventTypes.ArchiverTricklingSuccess,
        EventTypes.ArchiverTricklingRetry,
        EventTypes.ArchiverTricklingCancel,
        EventTypes.CameraConnectionEstablished,
        EventTypes.CameraConnectionStoppedByUser,
        EventTypes.CameraConnectionStoppedUnexpectedly,
        EventTypes.CameraRecordingProblem,
        EventTypes.CameraRecordingProblemRecovered,
        EventTypes.ConnectionFailed,
        EventTypes.ConnectionRestored,
        EventTypes.DatabaseAutomaticBackupFailed,
        EventTypes.DatabaseAutomaticBackupRecovered,
        EventTypes.DatabaseFailoverMasterLost,
        EventTypes.DatabaseFailoverMasterRecovered,
        EventTypes.DatabaseLost,
        EventTypes.DatabaseRecovered,
        EventTypes.DatabaseRestoreFailure,
        EventTypes.DatabaseRestoreSuccess,
        EventTypes.DirectoryStarted,
        EventTypes.DirectoryStoppedByUser,
        EventTypes.DirectoryStoppedUnexpectedly,
        EventTypes.HighCPUUsage,
        EventTypes.HighCPUUsageRecovered,
        EventTypes.HighMemoryUsage,
        EventTypes.HighMemoryUsageRecovered,
        EventTypes.LiveHealthMonitoringRecovered,
        EventTypes.LiveHealthMonitoringStopped,
        EventTypes.LowArchiveSpace,
        EventTypes.LowArchiveSpaceRecovered,
        EventTypes.LowDatabaseSpace,
        EventTypes.LowDatabaseSpaceRecovered,
        EventTypes.OffloadFailed,
        EventTypes.OffloadRecovered,
        EventTypes.PatrollerOffline,
        EventTypes.PatrollerOnline,
        EventTypes.POSDatabaseLost,
        EventTypes.POSDatabaseRecovered,
        EventTypes.RoleStarted,
        EventTypes.RoleStoppedByUser,
        EventTypes.RoleStoppedUnexpectedly,
        EventTypes.RTPPacketLoss,
        EventTypes.RTPPacketLossRecovered,
        EventTypes.ServiceStarted,
        EventTypes.ServiceStoppedByUser,
        EventTypes.ServiceStoppedUnexpectedly,
        EventTypes.SynchronizationFailed,
        EventTypes.SynchronizationRecovered,
        EventTypes.TransmissionLost,
        EventTypes.TransmissionRecovered,
        EventTypes.UnauthorizedDisk,
        EventTypes.UnauthorizedDiskRecovered,
        EventTypes.UnitConnectionFailed,
        EventTypes.UnitConnectionRestored,
        EventTypes.UnitConnectionStopped,
        EventTypes.UnitTimeDrift,
        EventTypes.UnitTimeDriftRecovered,
        EventTypes.UnitTricklingFailure,
        EventTypes.UnitTricklingPartialFailure,
        EventTypes.UnitTricklingSuccess,
        EventTypes.VideoSignalLoss,
        EventTypes.VideoSignalRecovered,
        EventTypes.EdgeStorageRecovered,
        EventTypes.EdgeStorageFailure,
        EventTypes.EdgeStorageRecordingRecovered,
        EventTypes.EdgeStorageRecordingFailure,
        EventTypes.CertificateValid,
        EventTypes.CertificateWarning,
        EventTypes.CertificateError,
        EventTypes.ValidDriverPack,
        EventTypes.InvalidDriverPack,
        EventTypes.ArchivingNotMonitored,
        EventTypes.ApplicationConnectionNotMonitored,
        EventTypes.ApplicationNotMonitored,
        EventTypes.ConnectionNotMonitored,
        EventTypes.UnitConnectionNotMonitored,
        EventTypes.DatabaseAutomaticBackupNotMonitored,
        EventTypes.DatabaseNotMonitored,
        EventTypes.HighCPUUsageNotMonitored,
        EventTypes.HighMemoryUsageNotMonitored,
        EventTypes.LowDatabaseSpaceNotMonitored,
        EventTypes.OffloadNotMonitored,
        EventTypes.PatrollerNotMonitored,
        EventTypes.POSDatabaseNotMonitored,
        EventTypes.RoleNotMonitored,
        EventTypes.RTPPacketNotMonitored,
        EventTypes.ServiceNotMonitored,
        EventTypes.SynchronizationNotMonitored,
        EventTypes.VideoSignalNotMonitored,
        EventTypes.UnauthorizedDiskNotMonitored,
        EventTypes.ActiveAlarmsNotMonitored,
        EventTypes.DirectoryNotMonitored,
        EventTypes.LowArchiveSpaceNotMonitored,
        EventTypes.LiveHealthMonitoringNotMonitored,
        EventTypes.DatabaseFailoverMasterNotMonitored,
        EventTypes.DatabaseRestoreNotMonitored,
        EventTypes.UnitTricklingNotMonitored,
        EventTypes.ArchiveBackupNotMonitored,
        EventTypes.AgentNotMonitored,
        EventTypes.ArchiveConsolidationNotMonitored,
        EventTypes.CameraRecordingNotMonitored,
        EventTypes.CameraConnectionNotMonitored,
        EventTypes.UnitTimeDriftNotMonitored,
        EventTypes.TransmissionNotMonitored,
        EventTypes.EdgeStorageNotMonitored,
        EventTypes.EdgeStorageRecordingNotMonitored,
        EventTypes.ArchiverTricklingNotMonitored,
        EventTypes.CertificateNotMonitored,
        EventTypes.DriverPackNotMonitored,
        EventTypes.ScTokenValid,
        EventTypes.ScTokenError,
        EventTypes.ScTokenNotMonitored,
        EventTypes.EntityOnline,
        EventTypes.EntityOffline,
        EventTypes.EntityNotMonitored,
        EventTypes.OptimizedDirectoryStartupSucceeded,
        EventTypes.OptimizedDirectoryStartupFailed,
        EventTypes.OptimizedDirectoryStartupNotMonitored,
        EventTypes.CommunicationsInviteSent,
        EventTypes.CommunicationsCallFirst,
        EventTypes.CommunicationsCallRinging,
        EventTypes.CommunicationsCallDeclined,
        EventTypes.CommunicationsCallAnswered,
        EventTypes.CommunicationsCallRejected,
        EventTypes.CommunicationsCallTimeout,
        EventTypes.CommunicationsCallEnded,
        EventTypes.CommunicationsCallStarted,
        EventTypes.CommunicationsCallLast,
        EventTypes.CommunicationsContactFirst,
        EventTypes.CommunicationsContactRegistered,
        EventTypes.CommunicationsContactUnregistered,
        EventTypes.CommunicationsContactLast,
        EventTypes.CommunicationsTrunkFirst,
        EventTypes.CommunicationsTrunkRegistered,
        EventTypes.CommunicationsTrunkUnregistered,
        EventTypes.CommunicationsTrunkLast,
        EventTypes.CorrelationFirst,
        EventTypes.CorrelationEvent,
        EventTypes.CorrelationRuleHitEvent,
        EventTypes.CorrelationLast,
        EventTypes.SecretKeyEvent
    ];

    public static ResourceDict = new Map<string, number>();
    static EventTypes() {
        this.ResourceDict.set('None', -1);
        this.ResourceDict.set('Invalidate', -1);
        this.ResourceDict.set('ManualAction', 904);
        this.ResourceDict.set('AlarmTriggered', 357);
        this.ResourceDict.set('AlarmAcknowledged', 356);
        this.ResourceDict.set('ApplicationConnected', 669);
        this.ResourceDict.set('ApplicationDisconnected', 670);
        this.ResourceDict.set('ServerLost', -1);
        this.ResourceDict.set('CameraLiveBookmarkAdded', 1456);
        this.ResourceDict.set('CameraPlaybackBookmarkAdded', 1457);
        this.ResourceDict.set('CameraMotionOn', 1458);
        this.ResourceDict.set('CameraMotionOff', 1459);
        this.ResourceDict.set('CardholderAccessGranted', 267);
        this.ResourceDict.set('CameraAbnormalMotion', 16915);
        this.ResourceDict.set('CameraNominalMotion', 16914);
        this.ResourceDict.set('CardholderAccessRefused', 268);
        this.ResourceDict.set('CardholderWalkthrough', -1);
        this.ResourceDict.set('CardholderInactive', 767);
        this.ResourceDict.set('CardHolderCredentialExpired', 765);
        this.ResourceDict.set('CardHolderCredentialInactive', 766);
        this.ResourceDict.set('CardHolderCredentialLost', 768);
        this.ResourceDict.set('CardHolderCredentialStolen', 769);
        this.ResourceDict.set('CardholderAntipassback', 1019);
        this.ResourceDict.set('CustomEvent', 1604);
        this.ResourceDict.set('UnitConnected', 627);
        this.ResourceDict.set('UnitDisconnected', 628);
        this.ResourceDict.set('InterfaceOnline', 15425);
        this.ResourceDict.set('InterfaceOffline', 15426);
        this.ResourceDict.set('AccessControlUnitWarningActivated', 16520);
        this.ResourceDict.set('AccessControlUnitWarningDeactivated', 16521);
        this.ResourceDict.set('FirmwareUpgradeScheduled', 16560);
        this.ResourceDict.set('FirmwareUpgradeCancelled', 16561);
        this.ResourceDict.set('InterfaceFirmwareUpgradeStarted', 16563);
        this.ResourceDict.set('InterfaceFirmwareUpgradeSuccess', 16562);
        this.ResourceDict.set('InterfaceFirmwareUpgradeFailure', 16564);
        this.ResourceDict.set('InterfaceFirmwareUpgradeScheduled', 16566);
        this.ResourceDict.set('InterfaceFirmwareUpgradeCancelled', 16565);
        this.ResourceDict.set('InterfaceFirmwareUpgradeTransferred', 16567);
        this.ResourceDict.set('UnitACFail', 2018);
        this.ResourceDict.set('UnitBatFail', 2019);
        this.ResourceDict.set('UnitInvalidConfiguration', 5246);
        this.ResourceDict.set('UnitTemperatureAlarm', 10560);
        this.ResourceDict.set('UnitTimeOutOfSync', -1);
        this.ResourceDict.set('UnitTimeOutOfSyncRecovered', -1);
        this.ResourceDict.set('UnitDiskActionError', 14791);
        this.ResourceDict.set('UnitDiskFormatted', 14792);
        this.ResourceDict.set('UnitDiskLocked', 14793);
        this.ResourceDict.set('UnitDiskMounted', 14794);
        this.ResourceDict.set('UnitDiskUnlocked', 14795);
        this.ResourceDict.set('UnitDiskUnmounted', 14796);
        this.ResourceDict.set('ArchiveTransferSequenceImported', 16243);
        this.ResourceDict.set('UserLoggedOn', 271);
        this.ResourceDict.set('UserLoggedOff', 272);
        this.ResourceDict.set('UserLogonFailed', 12634);
        this.ResourceDict.set('FirmwareUpgradeStarted', 1597);
        this.ResourceDict.set('FirmwareUpgradeSuccess', 1598);
        this.ResourceDict.set('FirmwareUpgradeFailure', 1599);
        this.ResourceDict.set('DoorRexOn', 577);
        this.ResourceDict.set('DoorRexOff', 2000);
        this.ResourceDict.set('DoorOpenedForTooLong', 469);
        this.ResourceDict.set('DoorPullStationActivated', 581);
        this.ResourceDict.set('DoorPullStationNormal', 582);
        this.ResourceDict.set('DoorOpen', 580);
        this.ResourceDict.set('DoorClose', 579);
        this.ResourceDict.set('DoorForcedOpen', -1);
        this.ResourceDict.set('DoorLock', 843);
        this.ResourceDict.set('DoorUnlock', 842);
        this.ResourceDict.set('DoorOpenWhileLockSecure', 847);
        this.ResourceDict.set('AccessUnknownCredential', 270);
        this.ResourceDict.set('AccessUnassignedCredential', 8932);
        this.ResourceDict.set('AccessGranted', 267);
        this.ResourceDict.set('AccessRefused', 268);
        this.ResourceDict.set('AccessExpiredCredential', 8928);
        this.ResourceDict.set('AccessStolenCredential', 8931);
        this.ResourceDict.set('AccessLostCredential', 8930);
        this.ResourceDict.set('AccessInactiveCredential', 8929);
        this.ResourceDict.set('AccessInactivePerson', 767);
        this.ResourceDict.set('DoorTamper', 968);
        this.ResourceDict.set('InputTroubleShort', 1530);
        this.ResourceDict.set('InputTroubleOpen', 1531);
        this.ResourceDict.set('EmergencyDoorRex', 15767);
        this.ResourceDict.set('EmergencyDoorUnlockPostponed', 15768);
        this.ResourceDict.set('EmergencyDoorUnlocked', 15769);
        this.ResourceDict.set('EmergencyDoorUnlockedOperatorUnlock', 15774);
        this.ResourceDict.set('EmergencyDoorUnlockedReleaseDelayTimeExpired', 15776);
        this.ResourceDict.set('EmergencyDoorUnlockedExtensionGuardLockingExpired', 15770);
        this.ResourceDict.set('EmergencyDoorUnlockedMaximumLockingTimeExpired', 15772);
        this.ResourceDict.set('EmergencyDoorUnlockedHostConnectionTimeout', 15778);
        this.ResourceDict.set('EmergencyDoorReset', 15780);
        this.ResourceDict.set('EmergencyDoorResetRemoteOperator', 15785);
        this.ResourceDict.set('EmergencyDoorResetPhysicalButton', 15783);
        this.ResourceDict.set('EmergencyDoorResetAuto', 15781);
        this.ResourceDict.set('DoorTestStarted', 16541);
        this.ResourceDict.set('DoorTestSucceeded', 16543);
        this.ResourceDict.set('DoorTestFailed', 16542);
        this.ResourceDict.set('DoorTestFailedAborted', 16554);
        this.ResourceDict.set('DoorTestFailedReaderShunted', 16555);
        this.ResourceDict.set('DoorTestFailedDoorNotSecured', 16556);
        this.ResourceDict.set('DoorTestFailedError', 16557);
        this.ResourceDict.set('DoorTestFailedUnlockFailed', 16558);
        this.ResourceDict.set('DoorTestFailedRelockFailed', 16559);
        this.ResourceDict.set('AreaFirstManIn', 1020);
        this.ResourceDict.set('AreaLastManOut', 1021);
        this.ResourceDict.set('AreaAntipassbackDetected', 1019);
        this.ResourceDict.set('AreaAntipassbackForgiven', 1199);
        this.ResourceDict.set('PeopleCountReset', 3356);
        this.ResourceDict.set('AreaCardholderRemoved', 14101);
        this.ResourceDict.set('AreaCardholderAdded', 14230);
        this.ResourceDict.set('CredentialUnassigned', 269);
        this.ResourceDict.set('CredentialExpired', 765);
        this.ResourceDict.set('CredentialStolen', 769);
        this.ResourceDict.set('CredentialLost', 768);
        this.ResourceDict.set('CredentialInactive', 766);
        this.ResourceDict.set('CredentialHasExpired', 2194);
        this.ResourceDict.set('CredentialIsExpiring', 2195);
        this.ResourceDict.set('ZoneGlassBreak', 1606);
        this.ResourceDict.set('ZoneWindowOpened', 1607);
        this.ResourceDict.set('ZoneWindowClosed', 1608);
        this.ResourceDict.set('ZoneDeadBoltLocked', 1609);
        this.ResourceDict.set('ZoneDeadBoltUnlocked', 1610);
        this.ResourceDict.set('ZoneKnobInplace', 1611);
        this.ResourceDict.set('ZoneKnobRotated', 1612);
        this.ResourceDict.set('ZoneLockSecured', 1613);
        this.ResourceDict.set('ZoneLockReleased', 1614);
        this.ResourceDict.set('MacroStarted', 2156);
        this.ResourceDict.set('MacroCompleted', 2157);
        this.ResourceDict.set('MacroAborted', 2158);
        this.ResourceDict.set('AlarmAcknowledgedAlternate', 1665);
        this.ResourceDict.set('LprFirst', -1);
        this.ResourceDict.set('LprRead', 4233);
        this.ResourceDict.set('LprHit', 4234);
        this.ResourceDict.set('ProcessMlpiResultsResultEvent', -1);
        this.ResourceDict.set('LprOffloadSucceeded', 8205);
        this.ResourceDict.set('LprOffloadFailed', 8358);
        this.ResourceDict.set('LprNoMatch', 9506);
        this.ResourceDict.set('LprHotlistChanged', 12911);
        this.ResourceDict.set('LprUpdateInstalled', 7520);
        this.ResourceDict.set('LprUpdateUninstalled', 7521);
        this.ResourceDict.set('LprUpdatePublished', 8080);
        this.ResourceDict.set('LprUpdateError', 8078);
        this.ResourceDict.set('LprUpdateInstalling', 8079);
        this.ResourceDict.set('LprUpdateUninstalling', 8081);
        this.ResourceDict.set('IntrusionAreaDuress', 5018);
        this.ResourceDict.set('IntrusionAreaEntryDelay', 5019);
        this.ResourceDict.set('IntrusionAreaAlarmActivated', 5014);
        this.ResourceDict.set('IntrusionAreaArming', 5015);
        this.ResourceDict.set('IntrusionAreaArmingPostponed', 5016);
        this.ResourceDict.set('IntrusionAreaMasterArmed', 5023);
        this.ResourceDict.set('IntrusionAreaPerimeterArmed', 5024);
        this.ResourceDict.set('IntrusionAreaDisarmed', 5017);
        this.ResourceDict.set('IntrusionAreaInputTrouble', 5022);
        this.ResourceDict.set('IntrusionAreaInputBypassActivated', 5020);
        this.ResourceDict.set('IntrusionAreaInputBypassDeactivated', 5021);
        this.ResourceDict.set('IntrusionAreaForcedArming', 7117);
        this.ResourceDict.set('IntrusionAreaCanceledAlarm', 5743);
        this.ResourceDict.set('IntrusionAreaMasterArmRequest', 5790);
        this.ResourceDict.set('IntrusionAreaPerimeterArmRequest', 5791);
        this.ResourceDict.set('IntrusionAreaDisarmRequest', 5792);
        this.ResourceDict.set('IntrusionAreaArmingPostponedRequest', 5880);
        this.ResourceDict.set('IntrusionAreaCanceledAlarmRequest', 5881);
        this.ResourceDict.set('IntrusionAreaAlarmSilenced', 11877);
        this.ResourceDict.set('IntrusionUnitTamper', 8481);
        this.ResourceDict.set('ZoneArmed', 5794);
        this.ResourceDict.set('ZoneDisarmed', 5795);
        this.ResourceDict.set('ZoneMaintenanceOn', 12783);
        this.ResourceDict.set('ZoneMaintenanceOff', 12784);
        this.ResourceDict.set('ZoneWarningUnitOffline', 12879);
        this.ResourceDict.set('DoorMaintenanceOff', 1670);
        this.ResourceDict.set('DoorMaintenanceOn', 1669);
        this.ResourceDict.set('DoorScheduledLock', 1027);
        this.ResourceDict.set('DoorScheduledUnlock', 1028);
        this.ResourceDict.set('DoorWarningUnitOffline', 1672);
        this.ResourceDict.set('DoorWarningInterfaceAcFail', 14348);
        this.ResourceDict.set('DoorWarningInterfaceAcFailRestored', 14354);
        this.ResourceDict.set('DoorWarningInterfaceBatteryFail', 14347);
        this.ResourceDict.set('DoorWarningInterfaceBatteryFailRestored', 14353);
        this.ResourceDict.set('DoorWarningInterfaceTamperFail', 14349);
        this.ResourceDict.set('DoorWarningInterfaceTamperFailRestored', 14355);
        this.ResourceDict.set('DoorManuallyUnlocked', 762);
        this.ResourceDict.set('DoorOnline', 15394);
        this.ResourceDict.set('DoorUnsecured', 15450);
        this.ResourceDict.set('DoorSecured', 15449);
        this.ResourceDict.set('VideoAnalyticsAudioAlarm', 7109);
        this.ResourceDict.set('VideoAnalyticsLicensePlateInSight', 5247);
        this.ResourceDict.set('VideoAnalyticsLicensePlateOutofSight', 5248);
        this.ResourceDict.set('VideoAnalyticsLicensePlateReading', 5249);
        this.ResourceDict.set('VideoAnalyticsObjectConditionChange', 5250);
        this.ResourceDict.set('VideoAnalyticsObjectCrossedLine', 5251);
        this.ResourceDict.set('VideoAnalyticsObjectEntered', 5252);
        this.ResourceDict.set('VideoAnalyticsObjectExited', 5253);
        this.ResourceDict.set('VideoAnalyticsObjectFall', 5254);
        this.ResourceDict.set('VideoAnalyticsObjectFollowingRoute', 5255);
        this.ResourceDict.set('VideoAnalyticsObjectInField', 5256);
        this.ResourceDict.set('VideoAnalyticsObjectLeft', 5257);
        this.ResourceDict.set('VideoAnalyticsObjectLoitering', 5258);
        this.ResourceDict.set('VideoAnalyticsObjectMerged', 5259);
        this.ResourceDict.set('VideoAnalyticsObjectRemoved', 5260);
        this.ResourceDict.set('VideoAnalyticsObjectRun', 5261);
        this.ResourceDict.set('VideoAnalyticsObjectSlide', 5262);
        this.ResourceDict.set('VideoAnalyticsObjectSplit', 5263);
        this.ResourceDict.set('VideoAnalyticsObjectStopped', 5264);
        this.ResourceDict.set('VideoAnalyticsTailgating', 5265);
        this.ResourceDict.set('VideoAnalyticsTampering', 5266);
        this.ResourceDict.set('VideoAnalyticsFaceDetected', 10094);
        this.ResourceDict.set('VideoAnalyticsUndefined', 5267);
        this.ResourceDict.set('VideoAnalyticsDirectionAlarm', 10878);
        this.ResourceDict.set('VideoAnalyticsTamperingOff', 9973);
        this.ResourceDict.set('VideoAnalyticsCrowdDetected', 13398);
        this.ResourceDict.set('VideoAnalyticsFaceRecognized', 13399);
        this.ResourceDict.set('VideoAnalyticsObjectDetected', 13401);
        this.ResourceDict.set('VideoAnalyticsHeatMapChanged', 13400);
        this.ResourceDict.set('VideoAnalyticsObjectCountChanged', 13402);
        this.ResourceDict.set('VideoAnalyticsObjectVelocityChanged', 13405);
        this.ResourceDict.set('VideoAnalyticsObjectDirectionChanged', 13403);
        this.ResourceDict.set('VideoAnalyticsPeopleCounting', 268436032);
        this.ResourceDict.set('VideoAnalyticsPeopleCountingAlarm', 268436033);
        this.ResourceDict.set('VideoAnalyticsCrowdSize', 268437953);
        this.ResourceDict.set('FloorAccess', 2234);
        this.ResourceDict.set('AssetMoved', 8109);
        this.ResourceDict.set('AssetLowBattery', 8111);
        this.ResourceDict.set('AssetOffline', 8107);
        this.ResourceDict.set('AssetOnline', 8105);
        this.ResourceDict.set('ArchivingStartedAutomatically', 2536);
        this.ResourceDict.set('ArchivingStoppedAutomatically', 2537);
        this.ResourceDict.set('ArchivingStartedManually', 2538);
        this.ResourceDict.set('ArchivingStoppedManually', 2539);
        this.ResourceDict.set('ArchivingStartedOnMotionEvent', 2544);
        this.ResourceDict.set('ArchivingStoppedOnMotionEvent', 2545);
        this.ResourceDict.set('ArchivingStartedByExternalSource', 2546);
        this.ResourceDict.set('ArchivingStoppedByExternalSource', 2547);
        this.ResourceDict.set('ArchivingStartedByAlarm', 2548);
        this.ResourceDict.set('ArchivingStoppedByAlarm', 2549);
        this.ResourceDict.set('CameraStartLiveStreaming', 17114);
        this.ResourceDict.set('CameraStopLiveStreaming', 17130);
        this.ResourceDict.set('ArchiveFileDeleted', 2540);
        this.ResourceDict.set('CameraSignalLost', 5004);
        this.ResourceDict.set('CameraSignalRecovered', 5005);
        this.ResourceDict.set('ArchivingQueueFull', 5231);
        this.ResourceDict.set('CameraNotArchiving', 5233);
        this.ResourceDict.set('CameraTransmissionLost', 5235);
        this.ResourceDict.set('CameraRtpPacketsLost', 5234);
        this.ResourceDict.set('CameraMultipleSourceDetected', 5232);
        this.ResourceDict.set('CameraStartBlock', 8972);
        this.ResourceDict.set('CameraStopBlock', 8973);
        this.ResourceDict.set('CameraTransmissionRecovered', 8367);
        this.ResourceDict.set('CameraEdgeRecordingCommandTimeout', 9721);
        this.ResourceDict.set('CameraEdgestorageFailure', 9718);
        this.ResourceDict.set('CameraMotion', 2535);
        this.ResourceDict.set('CameraEdgestorageEnabled', 14381);
        this.ResourceDict.set('CameraRtpPacketLostRecovery', 8368);
        this.ResourceDict.set('PtzActivated', 5129);
        this.ResourceDict.set('PtzStopped', 5130);
        this.ResourceDict.set('PtzZoomByUserStarted', 5132);
        this.ResourceDict.set('PtzZoomByUserStopped', 5133);
        this.ResourceDict.set('PtzLocked', 5131);
        this.ResourceDict.set('HealthMonitoringEvent', 7843);
        this.ResourceDict.set('AreaAntipassbackDisabledMixedMode', 1622);
        this.ResourceDict.set('AreaAntipassbackDisabledUnitOffline', 1623);
        this.ResourceDict.set('AreaPeopleCountDisabledUnitOffline', 1624);
        this.ResourceDict.set('AreaAntipassbackDisabledPerimeterOnElevator', 2002);
        this.ResourceDict.set('AreaSasOverrideOn', 2448);
        this.ResourceDict.set('AreaSasOverrideOff', 2449);
        this.ResourceDict.set('AreaSasLockdownOn', 2450);
        this.ResourceDict.set('AreaSasLockdownOff', 2451);
        this.ResourceDict.set('AreaSasMultipleUnitWarning', 2453);
        this.ResourceDict.set('AreaSasUnsupportedWarning', 2454);
        this.ResourceDict.set('AreaSasMaintenanceModeWarning', 2455);
        this.ResourceDict.set('AreaSasFreeAccessWarning', 2456);
        this.ResourceDict.set('AreaSasAntipassbackWarning', 2457);
        this.ResourceDict.set('AreaSasFloorWarning', 2458);
        this.ResourceDict.set('AreaAntipassbackDisabledInvalidSettings', 5316);
        this.ResourceDict.set('AreaSasDoorWithNoSensorConfigured', 11108);
        this.ResourceDict.set('AreaSasOnlyOnePerimeterFloor', 11107);
        this.ResourceDict.set('SinglePersonCheckFailed', 15762);
        this.ResourceDict.set('SinglePersonCheckFailedNoPresenceDetected', 15763);
        this.ResourceDict.set('SinglePersonCheckFailedTooManyPeople', 15765);
        this.ResourceDict.set('AreaEvacuationStarted', 16488);
        this.ResourceDict.set('AreaEvacuationEnded', 16489);
        this.ResourceDict.set('AreaMusteringStarted', 16501);
        this.ResourceDict.set('AreaMusteringEnded', 16500);
        this.ResourceDict.set('AreaLast', -1);
        this.ResourceDict.set('ElevatorWarningUnitOffline', 2030);
        this.ResourceDict.set('ElevatorSecuredOnSchedule', 1988);
        this.ResourceDict.set('ElevatorFreeAccessOnSchedule', 1987);
        this.ResourceDict.set('AlarmInvestigating', 8794);
        this.ResourceDict.set('AlarmForciblyAcked', 8595);
        this.ResourceDict.set('ThreatLevelActivated', 8956);
        this.ResourceDict.set('ThreatLevelDeactivated', 9295);
        this.ResourceDict.set('CardholderNoEntryDetected', 9221);
        this.ResourceDict.set('DoorNoEntryDetected', 9221);
        this.ResourceDict.set('CardholderEntryDetected', 9222);
        this.ResourceDict.set('CardholderEntryAssumed', 9539);
        this.ResourceDict.set('VisitorAstray', 14133);
        this.ResourceDict.set('MissingTailEscort', 14136);
        this.ResourceDict.set('DoorEntryDetected', 9222);
        this.ResourceDict.set('DoorEntryAssumed', 9539);
        this.ResourceDict.set('DoorSupervisorInAccessRule', 13245);
        this.ResourceDict.set('DoorSupervisorInUnlockingSchedule', 13246);
        this.ResourceDict.set('AreaMaxOccupancyReached', 14123);
        this.ResourceDict.set('AreaMaxOccupancyExceeded', 14124);
        this.ResourceDict.set('AreaBelowMaxOccupancy', 14125);
        this.ResourceDict.set('DuressPinEntered', 14129);
        this.ResourceDict.set('DoubleBadgeOn', 14827);
        this.ResourceDict.set('DoubleBadgeOff', 14828);
        this.ResourceDict.set('AccessGrantConfirmationRequested', 15748);
        this.ResourceDict.set('IdentityValidationSucceeded', 15749);
        this.ResourceDict.set('IdentityValidationSucceededBiometric', 15750);
        this.ResourceDict.set('IdentityValidationSucceededManual', 15752);
        this.ResourceDict.set('IdentityValidationFailed', 15754);
        this.ResourceDict.set('IdentityValidationFailedBiometricMismatch', 15756);
        this.ResourceDict.set('IdentityValidationFailedBiometricMissing', 15758);
        this.ResourceDict.set('IdentityValidationFailedManual', 15755);
        this.ResourceDict.set('IdentityValidationFailedTimeout', 15760);
        this.ResourceDict.set('OfflineSynchronizationStarted', 1309);
        this.ResourceDict.set('OfflineSynchronizationFailure', 1311);
        this.ResourceDict.set('OfflineSynchronizationSuccess', 1310);
        this.ResourceDict.set('AccessInvalidPin', 9192);
        this.ResourceDict.set('AccessValidCardInvalidPin', 9193);
        this.ResourceDict.set('AccessDeniedByAccessRule', 9194);
        this.ResourceDict.set('AccessInsufficientPrivileges', 9195);
        this.ResourceDict.set('AccessAntipassbackViolation', 9196);
        this.ResourceDict.set('AccessOutOfSchedule', 9197);
        this.ResourceDict.set('AccessNoAccessRuleAssigned', 9635);
        this.ResourceDict.set('AccessAbsentSupervisor', 10757);
        this.ResourceDict.set('AccessEscortRequired', 10046);
        this.ResourceDict.set('AccessSecondCardholderRequired', 10048);
        this.ResourceDict.set('AccessEscortNotSupported', 67108873);
        this.ResourceDict.set('CompanionVisitorDenied', 67108875);
        this.ResourceDict.set('Interlock', 10890);
        this.ResourceDict.set('AccessCardAndPINTimeout', 12380);
        this.ResourceDict.set('AccessValidCardInactivePIN', 12382);
        this.ResourceDict.set('AccessDeniedByMaxOccupancy', 14120);
        this.ResourceDict.set('UID', -1);
        this.ResourceDict.set('Warning', 1634);
        this.ResourceDict.set('UID2', -1);
        this.ResourceDict.set('UserFirst', -1);
        this.ResourceDict.set('ModifiedIncident', 8532);
        this.ResourceDict.set('UserLast', -1);
        this.ResourceDict.set('AccessPointFirst', -1);
        this.ResourceDict.set('AccessPointLast', -1);
        this.ResourceDict.set('AlarmFirst', -1);
        this.ResourceDict.set('AlarmConditionCleared', 8593);
        this.ResourceDict.set('AlarmLast', -1);
        this.ResourceDict.set('DoorFirst', -1);
        this.ResourceDict.set('ScheduleUnlockIgnored', 10839);
        this.ResourceDict.set('DoorLast', -1);
        this.ResourceDict.set('AreaFirst', -1);
        this.ResourceDict.set('UnitFirst', -1);
        this.ResourceDict.set('UnitLast', -1);
        this.ResourceDict.set('CredentialFirst', -1);
        this.ResourceDict.set('CredentialAccessDenied', 268);
        this.ResourceDict.set('CredentialLast', -1);
        this.ResourceDict.set('CardholderFirst', -1);
        this.ResourceDict.set('CardholderLast', -1);
        this.ResourceDict.set('CardholderDuressPinEntered', 14129);
        this.ResourceDict.set('CardholderDoubleBadgeOn', 14827);
        this.ResourceDict.set('CardholderDoubleBadgeOff', 14828);
        this.ResourceDict.set('CardholderAccessGrantConfirmationRequested', 15748);
        this.ResourceDict.set('CardholderIdentityValidationSucceeded', 15749);
        this.ResourceDict.set('CardholderIdentityValidationSucceededBiometric', 15750);
        this.ResourceDict.set('CardholderIdentityValidationSucceededManual', 15752);
        this.ResourceDict.set('CardholderIdentityValidationFailed', 15754);
        this.ResourceDict.set('CardholderIdentityValidationFailedBiometricMismatch', 15756);
        this.ResourceDict.set('CardholderIdentityValidationFailedBiometricMissing', 15758);
        this.ResourceDict.set('CardholderIdentityValidationFailedManual', 15755);
        this.ResourceDict.set('CardholderIdentityValidationFailedTimeout', 15760);
        this.ResourceDict.set('CardholderSinglePersonCheckFailed', 15762);
        this.ResourceDict.set('CardholderSinglePersonCheckFailedNoPresenceDetected', 15763);
        this.ResourceDict.set('CardholderSinglePersonCheckFailedTooManyPeople', 15765);
        this.ResourceDict.set('CredentialRequested', 8587);
        this.ResourceDict.set('CredentialRequestedComplete', 8589);
        this.ResourceDict.set('CredentialRequestedCancelled', 8588);
        this.ResourceDict.set('CredentialDoubleBadgeOn', 14827);
        this.ResourceDict.set('CredentialDoubleBadgeOff', 14828);
        this.ResourceDict.set('ApplicationFirst', -1);
        this.ResourceDict.set('ApplicationLast', -1);
        this.ResourceDict.set('CameraFirst', -1);
        this.ResourceDict.set('CameraLast', -1);
        this.ResourceDict.set('ExternalSystemFirst', -1);
        this.ResourceDict.set('ExternalSystemAlarmTriggered', 1549);
        this.ResourceDict.set('ExternalSystemSyncStarted', 2532);
        this.ResourceDict.set('ExternalSystemSyncCompleted', 2533);
        this.ResourceDict.set('ExternalSystemSyncError', 2534);
        this.ResourceDict.set('ExternalSystemSyncPartiallyCompleted', 13251);
        this.ResourceDict.set('ExternalSystemLast', -1);
        this.ResourceDict.set('ZoneFirst', -1);
        this.ResourceDict.set('ZoneLast', -1);
        this.ResourceDict.set('ElevatorFirst', -1);
        this.ResourceDict.set('ElevatorLast', -1);
        this.ResourceDict.set('MacroFirst', -1);
        this.ResourceDict.set('MacroLast', -1);
        this.ResourceDict.set('LprLast', -1);
        this.ResourceDict.set('LprUpdatePackage', -1);
        this.ResourceDict.set('InvalidatePatrollerMlpiReadsResultEvent', -1);
        this.ResourceDict.set('MlpiLast', -1);
        this.ResourceDict.set('IntrusionAreaFirst', -1);
        this.ResourceDict.set('IntrusionAreaLast', -1);
        this.ResourceDict.set('IntrusionUnitInputFirst', -1);
        this.ResourceDict.set('IntrusionUnitInputTrouble', 5013);
        this.ResourceDict.set('IntrusionUnitInputBypassActivated', 5011);
        this.ResourceDict.set('IntrusionUnitInputBypassDeactivated', 5012);
        this.ResourceDict.set('IntrusionUnitInputLast', -1);
        this.ResourceDict.set('ArchiverEventsFirst', -1);
        this.ResourceDict.set('ArchiverCanWrite', 5237);
        this.ResourceDict.set('ArchiverDatabaseLost', 5238);
        this.ResourceDict.set('ArchiverDatabaseRecovered', 5239);
        this.ResourceDict.set('ArchiverDiskChanged', 5240);
        this.ResourceDict.set('ArchiverDiskFull', 5241);
        this.ResourceDict.set('ArchiverDiskLoad', 5242);
        this.ResourceDict.set('ArchiverInvalidCustomEncryption', 5243);
        this.ResourceDict.set('ArchiverNoWrite', 5244);
        this.ResourceDict.set('ArchiverNoWriteOnSpecifiedDisk', 5245);
        this.ResourceDict.set('ArchiverArchivingStopped', 5236);
        this.ResourceDict.set('ArchiverBackupStarted', 6762);
        this.ResourceDict.set('ArchiverBackupSucceeded', 6763);
        this.ResourceDict.set('ArchiverBackupFailed', 6764);
        this.ResourceDict.set('ArchiverProtectionThresholdExceeded', 6765);
        this.ResourceDict.set('ArchiverVrmConnectionAttempt', 8370);
        this.ResourceDict.set('ArchiverVrmConnectionFailure', 8369);
        this.ResourceDict.set('ArchiverPathTooLong', 10994);
        this.ResourceDict.set('ArchiverFileProtectionSuccess', -1);
        this.ResourceDict.set('ArchiverFileProtectionFailure', -1);
        this.ResourceDict.set('ArchiverDatabaseFlooding', 17113);
        this.ResourceDict.set('ArchiverEventsLast', -1);
        this.ResourceDict.set('VideoAnalyticsFirst', -1);
        this.ResourceDict.set('VideoAnalyticsTemperatureDetectionOn', 11934);
        this.ResourceDict.set('VideoAnalyticsTemperatureDetectionOff', 11935);
        this.ResourceDict.set('VideoAnalyticsTemperatureDetectionAlarm', 16314);
        this.ResourceDict.set('VideoAnalyticsAdaptiveMotion', 268435769);
        this.ResourceDict.set('VideoAnalyticsObjectCountReached', 268435768);
        this.ResourceDict.set('VideoAnalyticsLast', -1);
        this.ResourceDict.set('AssetFirst', -1);
        this.ResourceDict.set('AssetLast', -1);
        this.ResourceDict.set('HealthMonitoringFirst', -1);
        this.ResourceDict.set('HealthMonitoringLast', -1);
        this.ResourceDict.set('IntrusionUnitFirst', -1);
        this.ResourceDict.set('IntrusionUnitLast', -1);
        this.ResourceDict.set('ThreatLevelFirst', -1);
        this.ResourceDict.set('ThreatLevelLast', -1);
        this.ResourceDict.set('AutoActionExecute', -1);
        this.ResourceDict.set('DeviceFirst', -1);
        this.ResourceDict.set('InputStateActive', 11952);
        this.ResourceDict.set('InputStateNormal', 11953);
        this.ResourceDict.set('InputStateTrouble', 11954);
        this.ResourceDict.set('InputAlarmActive', 134228915);
        this.ResourceDict.set('InputAlarmRestored', 134228916);
        this.ResourceDict.set('InputBypassed', 7024);
        this.ResourceDict.set('InputBypassRestored', 134228917);
        this.ResourceDict.set('DeviceLast', -1);
        this.ResourceDict.set('InputStateChanged', 11722);
        this.ResourceDict.set('ParkingZoneFirst', -1);
        this.ResourceDict.set('ParkingSessionStartedByVehicleEntered', 13217);
        this.ResourceDict.set('ParkingSessionStartedByUnknownVehicleExited', 13216);
        this.ResourceDict.set('ParkingSessionConvenienceTimeStarted', 13174);
        this.ResourceDict.set('ParkingSessionValidatingPaidTimeByPaidTimeExpired', 13221);
        this.ResourceDict.set('ParkingSessionValidatingPaidTimeByConvenienceTimeExpired', 13220);
        this.ResourceDict.set('ParkingsessionPaidTimeStartedByPaidTimeValid', 13222);
        this.ResourceDict.set('ParkingSessionPaidTimeStartedByValidationError', 13223);
        this.ResourceDict.set('ParkingSessionGracePeriodStartedConvenienceTimeExpired', 13218);
        this.ResourceDict.set('ParkingSessionGracePeriodStartedByPaidTimeInvalid', 13219);
        this.ResourceDict.set('ParkingSessionViolationDetectedByConvenienceTimeExpired', 13224);
        this.ResourceDict.set('ParkingSessionViolationDetectedByGracePeriodExpired', 13225);
        this.ResourceDict.set('ParkingSessionViolationDetectedByPaidTimeInvalid', 13226);
        this.ResourceDict.set('ParkingSessionViolationEnforced', 13110);
        this.ResourceDict.set('ParkingSessionCompletedByInventoryReset', 13227);
        this.ResourceDict.set('ParkingSessionCompletedByMaxSessionTimeExceeded', 13228);
        this.ResourceDict.set('ParkingSessionCompletedByVehicledExited', 13229);
        this.ResourceDict.set('ParkingSessionCompletedByUnknownVehicledExited', 13532);
        this.ResourceDict.set('ParkingZoneInventoryReset', 13105);
        this.ResourceDict.set('ParkingZoneMaximumCapacityReached', 13106);
        this.ResourceDict.set('ParkingSessionStarted', 13173);
        this.ResourceDict.set('ParkingSessionValidatingPaidTime', 13176);
        this.ResourceDict.set('ParkingSessionPaidTimeStarted', 13177);
        this.ResourceDict.set('ParkingSessionGracePeriodStarted', 13175);
        this.ResourceDict.set('ParkingSessionViolationDetected', 13109);
        this.ResourceDict.set('ParkingSessionCompleted', 13178);
        this.ResourceDict.set('ParkingZoneLast', -1);
        this.ResourceDict.set('AlarmContextChanged', 13808);
        this.ResourceDict.set('AudioAnalyticsFirst', -1);
        this.ResourceDict.set('AudioAnalyticsCustom', 268435971);
        this.ResourceDict.set('AudioAnalyticsLast', -1);
        this.ResourceDict.set('ActiveAlarms', 8195);
        this.ResourceDict.set('ActiveAlarmsRecovered', 8196);
        this.ResourceDict.set('AgentStarted', 13311);
        this.ResourceDict.set('AgentStopped', 13312);
        this.ResourceDict.set('AgentStoppedUnexpectedly', 13313);
        this.ResourceDict.set('AllArchivingRestarted', 7685);
        this.ResourceDict.set('AllArchivingStopped', 7684);
        this.ResourceDict.set('ApplicationConnect', 7688);
        this.ResourceDict.set('ApplicationDisconnectedByUser', 7686);
        this.ResourceDict.set('ApplicationDisconnectedUnexpectedly', 7687);
        this.ResourceDict.set('ApplicationStarted', 7691);
        this.ResourceDict.set('ApplicationStoppedByUser', 7689);
        this.ResourceDict.set('ApplicationStoppedUnexpectedl', 7690);
        this.ResourceDict.set('ArchiveBackupFailure', 10256);
        this.ResourceDict.set('ArchiveBackupSuccess', 10257);
        this.ResourceDict.set('ArchiveConsolidationFailure', 13444);
        this.ResourceDict.set('ArchiveConsolidationPartialFailure', 13445);
        this.ResourceDict.set('ArchiveConsolidationSuccess', 13443);
        this.ResourceDict.set('ArchiverTricklingFailure', 10261);
        this.ResourceDict.set('ArchiverTricklingPartialFailure', 10262);
        this.ResourceDict.set('ArchiverTricklingSuccess', 10263);
        this.ResourceDict.set('ArchiverTricklingRetry', 15571);
        this.ResourceDict.set('ArchiverTricklingCancel', 15577);
        this.ResourceDict.set('CameraConnectionEstablished', 14254);
        this.ResourceDict.set('CameraConnectionStoppedByUser', 14256);
        this.ResourceDict.set('CameraConnectionStoppedUnexpectedly', 14255);
        this.ResourceDict.set('CameraRecordingProblem', 13869);
        this.ResourceDict.set('CameraRecordingProblemRecovered', 13870);
        this.ResourceDict.set('ConnectionFailed', 7694);
        this.ResourceDict.set('ConnectionRestored', 7695);
        this.ResourceDict.set('DatabaseAutomaticBackupFailed', 7700);
        this.ResourceDict.set('DatabaseAutomaticBackupRecovered', 7701);
        this.ResourceDict.set('DatabaseFailoverMasterLost', 8389);
        this.ResourceDict.set('DatabaseFailoverMasterRecovered', 8390);
        this.ResourceDict.set('DatabaseLost', 7703);
        this.ResourceDict.set('DatabaseRecovered', 7704);
        this.ResourceDict.set('DatabaseRestoreFailure', 8452);
        this.ResourceDict.set('DatabaseRestoreSuccess', 8451);
        this.ResourceDict.set('DirectoryStarted', 8257);
        this.ResourceDict.set('DirectoryStoppedByUser', 8258);
        this.ResourceDict.set('DirectoryStoppedUnexpectedly', 8259);
        this.ResourceDict.set('HighCPUUsage', 7709);
        this.ResourceDict.set('HighCPUUsageRecovered', 7710);
        this.ResourceDict.set('HighMemoryUsage', 7715);
        this.ResourceDict.set('HighMemoryUsageRecovered', 7716);
        this.ResourceDict.set('LiveHealthMonitoringRecovered', 8373);
        this.ResourceDict.set('LiveHealthMonitoringStopped', 8372);
        this.ResourceDict.set('LowArchiveSpace', 8221);
        this.ResourceDict.set('LowArchiveSpaceRecovered', 8220);
        this.ResourceDict.set('LowDatabaseSpace', 7719);
        this.ResourceDict.set('LowDatabaseSpaceRecovered', 7720);
        this.ResourceDict.set('OffloadFailed', 7727);
        this.ResourceDict.set('OffloadRecovered', 7728);
        this.ResourceDict.set('PatrollerOffline', 7729);
        this.ResourceDict.set('PatrollerOnline', 7730);
        this.ResourceDict.set('POSDatabaseLost', 7732);
        this.ResourceDict.set('POSDatabaseRecovered', 7733);
        this.ResourceDict.set('RoleStarted', 7736);
        this.ResourceDict.set('RoleStoppedByUser', 7737);
        this.ResourceDict.set('RoleStoppedUnexpectedly', 7738);
        this.ResourceDict.set('RTPPacketLoss', 7739);
        this.ResourceDict.set('RTPPacketLossRecovered', 7740);
        this.ResourceDict.set('ServiceStarted', 7743);
        this.ResourceDict.set('ServiceStoppedByUser', 7741);
        this.ResourceDict.set('ServiceStoppedUnexpectedly', 7742);
        this.ResourceDict.set('SynchronizationFailed', 7744);
        this.ResourceDict.set('SynchronizationRecovered', 7745);
        this.ResourceDict.set('TransmissionLost', 5235);
        this.ResourceDict.set('TransmissionRecovered', 8367);
        this.ResourceDict.set('UnauthorizedDisk', 8088);
        this.ResourceDict.set('UnauthorizedDiskRecovered', 8089);
        this.ResourceDict.set('UnitConnectionFailed', 7696);
        this.ResourceDict.set('UnitConnectionRestored', 7697);
        this.ResourceDict.set('UnitConnectionStopped', 8498);
        this.ResourceDict.set('UnitTimeDrift', 15334);
        this.ResourceDict.set('UnitTimeDriftRecovered', 15333);
        this.ResourceDict.set('UnitTricklingFailure', 10258);
        this.ResourceDict.set('UnitTricklingPartialFailure', 10259);
        this.ResourceDict.set('UnitTricklingSuccess', 10260);
        this.ResourceDict.set('VideoSignalLoss', 7752);
        this.ResourceDict.set('VideoSignalRecovered', 7753);
        this.ResourceDict.set('EdgeStorageRecovered', 15564);
        this.ResourceDict.set('EdgeStorageFailure', 15567);
        this.ResourceDict.set('EdgeStorageRecordingRecovered', 15566);
        this.ResourceDict.set('EdgeStorageRecordingFailure', 15565);
        this.ResourceDict.set('CertificateValid', 16239);
        this.ResourceDict.set('CertificateWarning', 16240);
        this.ResourceDict.set('CertificateError', 16241);
        this.ResourceDict.set('ValidDriverPack', 16280);
        this.ResourceDict.set('InvalidDriverPack', 16281);
        this.ResourceDict.set('ArchivingNotMonitored', 16398);
        this.ResourceDict.set('ApplicationConnectionNotMonitored', 16399);
        this.ResourceDict.set('ApplicationNotMonitored', 16400);
        this.ResourceDict.set('ConnectionNotMonitored', 16401);
        this.ResourceDict.set('UnitConnectionNotMonitored', 16402);
        this.ResourceDict.set('DatabaseAutomaticBackupNotMonitored', 16403);
        this.ResourceDict.set('DatabaseNotMonitored', 16404);
        this.ResourceDict.set('HighCPUUsageNotMonitored', 16405);
        this.ResourceDict.set('HighMemoryUsageNotMonitored', 16406);
        this.ResourceDict.set('LowDatabaseSpaceNotMonitored', 16407);
        this.ResourceDict.set('OffloadNotMonitored', 16408);
        this.ResourceDict.set('PatrollerNotMonitored', 16409);
        this.ResourceDict.set('POSDatabaseNotMonitored', 16410);
        this.ResourceDict.set('RoleNotMonitored', 16411);
        this.ResourceDict.set('RTPPacketNotMonitored', 16412);
        this.ResourceDict.set('ServiceNotMonitored', 16413);
        this.ResourceDict.set('SynchronizationNotMonitored', 16414);
        this.ResourceDict.set('VideoSignalNotMonitored', 16415);
        this.ResourceDict.set('UnauthorizedDiskNotMonitored', 16416);
        this.ResourceDict.set('ActiveAlarmsNotMonitored', 16417);
        this.ResourceDict.set('DirectoryNotMonitored', 16418);
        this.ResourceDict.set('LowArchiveSpaceNotMonitored', 16419);
        this.ResourceDict.set('LiveHealthMonitoringNotMonitored', 16420);
        this.ResourceDict.set('DatabaseFailoverMasterNotMonitored', 16421);
        this.ResourceDict.set('DatabaseRestoreNotMonitored', 16422);
        this.ResourceDict.set('UnitTricklingNotMonitored', 16423);
        this.ResourceDict.set('ArchiveBackupNotMonitored', 16424);
        this.ResourceDict.set('AgentNotMonitored', 16425);
        this.ResourceDict.set('ArchiveConsolidationNotMonitored', 16426);
        this.ResourceDict.set('CameraRecordingNotMonitored', 16427);
        this.ResourceDict.set('CameraConnectionNotMonitored', 16429);
        this.ResourceDict.set('UnitTimeDriftNotMonitored', 16428);
        this.ResourceDict.set('TransmissionNotMonitored', 16430);
        this.ResourceDict.set('EdgeStorageNotMonitored', 16431);
        this.ResourceDict.set('EdgeStorageRecordingNotMonitored', 16432);
        this.ResourceDict.set('ArchiverTricklingNotMonitored', 16433);
        this.ResourceDict.set('CertificateNotMonitored', 16434);
        this.ResourceDict.set('DriverPackNotMonitored', 16435);
        this.ResourceDict.set('ScTokenValid', 16769);
        this.ResourceDict.set('ScTokenError', 16770);
        this.ResourceDict.set('ScTokenNotMonitored', 16802);
        this.ResourceDict.set('EntityOnline', 16844);
        this.ResourceDict.set('EntityOffline', 16845);
        this.ResourceDict.set('EntityNotMonitored', 16846);
        this.ResourceDict.set('OptimizedDirectoryStartupSucceeded', 17198);
        this.ResourceDict.set('OptimizedDirectoryStartupFailed', 17199);
        this.ResourceDict.set('OptimizedDirectoryStartupNotMonitored', 17200);
        this.ResourceDict.set('CommunicationsInviteSent', -1);
        this.ResourceDict.set('CommunicationsCallFirst', -1);
        this.ResourceDict.set('CommunicationsCallRinging', -1);
        this.ResourceDict.set('CommunicationsCallDeclined', -1);
        this.ResourceDict.set('CommunicationsCallAnswered', -1);
        this.ResourceDict.set('CommunicationsCallRejected', -1);
        this.ResourceDict.set('CommunicationsCallTimeout', -1);
        this.ResourceDict.set('CommunicationsCallEnded', -1);
        this.ResourceDict.set('CommunicationsCallStarted', -1);
        this.ResourceDict.set('CommunicationsCallLast', -1);
        this.ResourceDict.set('CommunicationsContactFirst', -1);
        this.ResourceDict.set('CommunicationsContactRegistered', -1);
        this.ResourceDict.set('CommunicationsContactUnregistered', -1);
        this.ResourceDict.set('CommunicationsContactLast', -1);
        this.ResourceDict.set('CommunicationsTrunkFirst', -1);
        this.ResourceDict.set('CommunicationsTrunkRegistered', -1);
        this.ResourceDict.set('CommunicationsTrunkUnregistered', -1);
        this.ResourceDict.set('CommunicationsTrunkLast', -1);
        this.ResourceDict.set('CorrelationFirst', -1);
        this.ResourceDict.set('CorrelationEvent', 15720);
        this.ResourceDict.set('CorrelationRuleHitEvent', 15721);
        this.ResourceDict.set('CorrelationLast', -1);
        this.ResourceDict.set('SecretKeyEvent', -1);
    }
}
