import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { SubscriptionCollection } from '../../shared/utilities/subscription-collection';

export class MailboxEnvelop {
    public id: IGuid = SafeGuid.EMPTY;
    public icon: string | undefined;
    public title: string | undefined;
    public summary: string | undefined;
    public navigation: string | undefined;
    public isRead = false;
    public timestamp: Date = new Date(Date.now());
    public openHandler: (() => void) | undefined = undefined;

    public MailboxEnvelop(id: IGuid, title: string): void {
        this.id = id;
        this.title = title;
    }
}

@Injectable({
    providedIn: 'root',
})
export class MailboxService implements OnDestroy {
    //#region Fields

    public envelops: MailboxEnvelop[] = [];

    public mailboxChanged = new EventEmitter<MailboxEnvelop[]>();

    private supportNotifications = false;
    private subscriptions = new SubscriptionCollection();

    //#endregion

    //#region Constructor

    constructor(securityCenterProvider: SecurityCenterClientService) {
        // // let's check if the browser supports notifications
        // if (("Notification" in window)) {
        //     this.supportNotifications = true;
        // }

        this.subscriptions.add(securityCenterProvider.scClient?.onLogonStateChanged((arg) => this.onLogonStateChanged(arg)));
    }

    //#endregion

    //#region Methods

    public ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }

    public add(envelop: MailboxEnvelop): void {
        this.envelops.push(envelop);
        this.mailboxChanged.emit(this.envelops);

        if (this.supportNotifications) {
            let showNotification = false;

            // Let's check whether notification permissions have already been granted
            if (Notification.permission === 'granted') {
                showNotification = true;
            }
            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== 'denied') {
                Notification.requestPermission()
                    .then((permission) => {
                        // If the user accepts, let's create a notification
                        if (permission === 'granted') {
                            showNotification = true;
                        }
                    })
                    .fireAndForget();
            }

            if (showNotification && envelop.title) {
                // If it's okay let's create a notification
            }
        }
    }

    public remove(...mails: MailboxEnvelop[] | IGuid[]): void {
        let isDirty = false;
        for (const mail of mails) {
            if (mail instanceof MailboxEnvelop) {
                this.envelops.remove(mail);
                isDirty = true;
            }
            if (isInstanceOfGuid(mail)) {
                const envObj = this.envelops.find((x) => x.id.equals(mail));
                if (envObj) {
                    this.envelops.remove(envObj);
                    isDirty = true;
                }
            }
        }
        if (isDirty) {
            this.mailboxChanged.emit(this.envelops);
        }
    }

    public executeHandler(mailId: IGuid): void {
        const mail = this.envelops.find((x) => x.id.equals(mailId));
        mail?.openHandler?.();
    }

    //#endregion

    //#region Event Handlers

    private onLogonStateChanged(args: LogonStateChangedArgs) {
        if (!args.loggedOn()) {
            this.envelops.length = 0;
        }
    }

    //#endregion
}
