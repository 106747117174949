import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { IGuid, SafeGuid } from 'safeguid';
import { CommandProvider, CommandContext, CommandsService } from '../../../interfaces/plugins/public/plugin-services-public.interface';
import { isEntityCommandProvider } from '../entity-command-provider';
import { CommandsUsage } from './commands-usage';

export class CommandsUsageManager {
    private commandsUsages: CommandsUsage[] = [];

    constructor(private commandsService: CommandsService) {}

    public invalidateCanExecuteForAllCommandsUsage(commandId: IGuid, canExecute?: boolean): void {
        this.commandsUsages.forEach((commandsUsage) => commandsUsage.invalidateCanExecute(commandId, canExecute));
    }

    public async createUsageAsync(
        scClient: ISecurityCenterClient,
        commandProviders: CommandProvider[],
        commandContext?: CommandContext,
        specificCommandIds?: IGuid[],
        onlyUseSpecificCommands = false,
        targetElement?: Element
    ): Promise<CommandsUsage> {
        let context = commandContext;
        const target = targetElement ?? commandContext?.target;
        if (!context) {
            context = { type: SafeGuid.EMPTY, data: undefined } as CommandContext;
        }

        const existingUsage = this.commandsUsages.find((x) => x.represents(context as CommandContext, specificCommandIds, target));
        if (existingUsage) {
            return existingUsage;
        }

        const entityFields: string[] = [];
        for (const provider of commandProviders) {
            if (isEntityCommandProvider(provider)) {
                for (const field of provider.requiredEntityFields) {
                    entityFields.push(field);
                }
            }
        }

        const entityCache = scClient.buildEntityCache(entityFields);
        const commandsUsage = new CommandsUsage(this.commandsService, context, entityCache, target, specificCommandIds);

        // Add in array right away even if missing commands to avoid creating duplicates of same usage while awaiting for commands
        this.commandsUsages.push(commandsUsage);
        const hasSpecificCommand = specificCommandIds && specificCommandIds.length > 0;

        if (specificCommandIds && hasSpecificCommand) {
            commandsUsage.addCommands(await this.commandsService.getCommandsAsync(specificCommandIds, true, commandsUsage.commandContext));
        }

        if (!hasSpecificCommand || !onlyUseSpecificCommands) {
            commandsUsage.addCommands(await this.commandsService.getAvailableCommandsAsync(commandsUsage.commandContext));
        }

        const usageDoneUnsubscribe = commandsUsage.usageDone.subscribe(() => {
            setTimeout(() => {
                const index = this.commandsUsages.indexOf(commandsUsage);
                if (index > -1 && commandsUsage.subscriberCount === 0) {
                    usageDoneUnsubscribe();
                    commandsUsage.dispose();
                    this.commandsUsages.splice(index, 1);
                }
            }, 5000);
        });
        return commandsUsage;
    }
}
