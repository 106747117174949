import { GuidMap, IGuid, SafeGuid } from 'safeguid';
import { EntityBrowserItem } from '../components/entity-browser/items/entity-browser-item';
import { EntityBrowserItemModel } from './Items/entity-browser-item-model';

export class EntityBrowserSelection {
    private items = new GuidMap<EntityBrowserItem>();

    public get allIds(): IGuid[] {
        return Array.from(this.items.keys());
    }

    public get allItems(): EntityBrowserItem[] {
        return Array.from(this.items.values());
    }

    public get hasMany(): boolean {
        return this.items.size > 1;
    }

    public get singleId(): IGuid {
        if (this.items.size > 0) {
            for (const guid of this.allIds) {
                return guid;
            }
        }

        return SafeGuid.EMPTY;
    }

    public get singleItem(): EntityBrowserItem | null {
        if (this.items.size > 0) {
            for (const item of this.allItems) {
                return item;
            }
        }

        return null;
    }

    constructor(items: EntityBrowserItem[]) {
        for (const item of items) {
            this.items.set(item.model.id, item);
        }
    }
}

export class EntityBrowserCheckSelection {
    public exclusions: IGuid[] = [];
    private itemMap = new GuidMap<EntityBrowserItemModel>();

    public get ids(): IGuid[] {
        return Array.from(this.itemMap.keys());
    }

    public get items(): EntityBrowserItemModel[] {
        return Array.from(this.itemMap.values());
    }

    constructor(items: EntityBrowserItemModel[], exclusions?: EntityBrowserItemModel[]) {
        for (const item of items) {
            this.itemMap.set(item.id, item);
        }

        if (exclusions) {
            for (const item of exclusions) {
                this.exclusions.push(item.id);
            }
        }
    }
}
