import { IGuid, SafeGuid } from 'safeguid';
import { List } from 'immutable';
import { toGuid } from './../utils/guid-utils';

export interface IncidentTypeApi {
    Id: string;
    Revision: number;
    Name: string;
    Description: string;
    IsManuallyTriggerable: boolean;
    CategoryId?: string;
    PriorityId: string;
    SubIncidentIds: Array<string>;
    ArgbColor?: string;
    IconId: string;
    ProcedureId?: string;
    ProcedureRevision?: number;
}

export class IncidentType {
    constructor(
        public id: IGuid,
        public revision: number,
        public name: string,
        public description: string,
        public isManuallyTriggerable: boolean,
        public categoryId: IGuid | null,
        public priorityId: IGuid,
        public subIncidentIds: List<IGuid>,
        public argbColor: string | null,
        public iconId: IGuid,
        public procedureId: IGuid | null,
        public procedureRevision: number | null
    ) {}

    public static assign = (incidentType: IncidentTypeApi): IncidentType =>
        new IncidentType(
            toGuid(incidentType.Id),
            incidentType.Revision,
            incidentType.Name,
            incidentType.Description,
            incidentType.IsManuallyTriggerable,
            incidentType.CategoryId ? toGuid(incidentType.CategoryId) : null,
            toGuid(incidentType.PriorityId),
            incidentType.SubIncidentIds ? List(incidentType.SubIncidentIds.map(toGuid)) : List(),
            incidentType.ArgbColor ?? null,
            toGuid(incidentType.IconId),
            incidentType.ProcedureId ? toGuid(incidentType.ProcedureId) : null,
            incidentType.Revision ?? null
        );
}
