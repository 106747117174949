<div>
    <div class="incident-status">
        <div *ngIf="data$ | async as data; else loading">
            <span>{{ data.stateName }}</span>
            <span *ngIf="data.ownerName">{{ ', ' + ('STE_LABEL_INCIDENTOWNEDBY_X' | translate | stringFormat: data.ownerName) }}</span>
        </div>
        <ng-template #loading>
            <gen-spinner></gen-spinner>
        </ng-template>
    </div>
    <div class="incident-actions">
        <gen-button id="addCommentButton" [disabled]="!hasCommentPrivilege" [flavor]="ButtonFlavor.Flat" (click)="openModal()" [appTooltip]="'STE_LABEL_ADD_COMMENT' | translate">
            <gen-icon [icon]="MeltedIcon.Message"></gen-icon>
        </gen-button>
    </div>
</div>
<!--Add comment modal-->
<gen-melted-modal
    id="add-comment-modal"
    [genTitle]="'STE_LABEL_ADD_COMMENT' | translate"
    [genDefaultActionText]="'STE_BUTTON_ADD' | translate"
    [genAlternativeActionText]="'STE_BUTTON_CANCEL' | translate"
    [genOnDefaultAction]="addCommentAction"
    [genOnAlternativeAction]="closeModal"
    [genIsDefaultActionDisabled]="comment === '' || !commentInput.valid"
    [genCanClose]="true"
>
    <gen-melted-modal-body>
        <div id="add-comment-popup">
            <div class="comment-input">
                <app-incident-comment [(ngModel)]="comment" #commentInput="ngModel" [maxlength]="1024"></app-incident-comment>
            </div>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
