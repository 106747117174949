import { EventEntry, IEventEntry } from '@modules/shared/api/api';
import { EventBase } from 'RestClient/Client/Event/EventBase';
import { IGuid } from 'safeguid';
export class MonitoredEntityEvent extends EventBase {
    public get monitoredEntityId(): IGuid {
        return this.getFieldGuid('MonitoredEntityId');
    }

    private eventEntry?: EventEntry;

    // Event
    public get event(): EventEntry {
        if (!this.eventEntry) {
            const eventEntryString = this.getField<string>('Event');
            this.eventEntry = EventEntry.fromJS(eventEntryString);
        }

        return this.eventEntry;
    }
}
