import { IGuid } from 'safeguid';

export interface CommentApi {
    Id: IGuid;
    Value: string;
    CreatedBy: IGuid;
    LastModifiedTime: Date;
}

export class Comment {
    constructor(public id: IGuid, public value: string, public createdBy: IGuid, public lastModifiedTime: Date) {}

    public static assign = (commentApi: CommentApi): Comment => new Comment(commentApi.Id, commentApi.Value, commentApi.CreatedBy, commentApi.LastModifiedTime);
}
