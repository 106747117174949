import { EmitterAction, ɵc as InjectorAccessor } from '@ngxs-labs/emitter/';
import { StateContext } from '@ngxs/store';

type ClassPropertyDescriptor = (target: unknown, key: string | symbol, descriptor?: PropertyDescriptor) => void;

export const MethodEmitter = <T, V>(receiver: (context: StateContext<T>, action: EmitterAction<V>) => void): ClassPropertyDescriptor => {
    return (target: unknown, key: string | symbol, descriptor?: PropertyDescriptor) => {
        const emitFunction = (...args: unknown[]) => {
            const store = InjectorAccessor.getEmitStore();
            return store.emitter<unknown, unknown>(receiver).emit(args[0]);
        };
        if (descriptor) {
            descriptor.value = emitFunction;
        } else {
            Object.defineProperty(target, key, {
                value: emitFunction,
            });
        }

        return descriptor;
    };
};
