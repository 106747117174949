// Autogenerated: v2/help/Entities/ApiDoor?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { IFieldObject } from './IFieldObject';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity, EntityFields } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class DoorEntityFields extends EntityFields {
    public static doorForcedBehaviorField = 'DoorForcedBehavior';
    public static doorHeldBehaviorField = 'DoorHeldBehavior';
    public static doorLockField = 'DoorLock';
    public static doorOpenedField = 'DoorOpened';
    public static doorRelockDelayField = 'DoorRelockDelay';
    public static doorRelockOnCloseField = 'DoorRelockOnClose';
    public static doorSensorField = 'DoorSensor';
    public static entrySensorExtendedGrantTimeField = 'EntrySensorExtendedGrantTime';
    public static entrySensorStandardGrantTimeField = 'EntrySensorStandardGrantTime';
    public static extendedGrantTimeField = 'ExtendedGrantTime';
    public static interfaceModuleField = 'InterfaceModule';
    public static isLockedField = 'IsLocked';
    public static isLockSensorUnsecuredField = 'IsLockSensorUnsecured';
    public static maintenanceModeActiveField = 'MaintenanceModeActive';
    public static mainUnitField = 'MainUnit';
    public static manualStationPulledField = 'ManualStationPulled';
    public static maskAccessWhenUnlockedByScheduleField = 'MaskAccessWhenUnlockedBySchedule';
    public static maskDoorOpenTooLongWhenUnlockedByScheduleField = 'MaskDoorOpenTooLongWhenUnlockedBySchedule';
    public static maximumDelayBetweenSwipesField = 'MaximumDelayBetweenSwipes';
    public static normalGrantTimeField = 'NormalGrantTime';
    public static rexHoldOffAfterClosedDelayField = 'RexHoldOffAfterClosedDelay';
    public static rexHoldOffAfterGrantDelayField = 'RexHoldOffAfterGrantDelay';
    public static rexHoldoffWhileOpenedField = 'RexHoldoffWhileOpened';
    public static unlockOnRexField = 'UnlockOnRex';
}

// #endregion Fields

// #region Inner classes

export interface IAutoUnlockOverride extends IFieldObject {
    start: Date;
    end: Date;
    isUnlocking: boolean;
}

// #endregion Inner classes

export interface IDoorEntity extends IEntity {
    // #region REST Properties

    doorForcedBehavior: string;

    doorHeldBehavior: string;

    readonly doorLock: IGuid;

    readonly doorOpened: boolean;

    doorRelockDelay: number;

    doorRelockOnClose: boolean;

    readonly doorSensor: IGuid;

    entrySensorExtendedGrantTime: number;

    entrySensorStandardGrantTime: number;

    extendedGrantTime: number;

    interfaceModule: IGuid;

    readonly isLocked: boolean;

    readonly isLockSensorUnsecured: boolean;

    maintenanceModeActive: boolean;

    mainUnit: IGuid;

    readonly manualStationPulled: boolean;

    maskAccessWhenUnlockedBySchedule: boolean;

    maskDoorOpenTooLongWhenUnlockedBySchedule: boolean;

    maximumDelayBetweenSwipes: number;

    normalGrantTime: number;

    rexHoldOffAfterClosedDelay: number;

    rexHoldOffAfterGrantDelay: number;

    rexHoldoffWhileOpened: boolean;

    unlockOnRex: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    deleteAutoUnlockOverrideAsync(transaction?: ITransaction | null): Promise<IRestResponse>;
    getAutoUnlockOverrideAsync(transaction?: ITransaction | null): Promise<IAutoUnlockOverride | null>;
    addAutoUnlockOverrideAsync(value: IAutoUnlockOverride, transaction?: ITransaction | null): Promise<IAutoUnlockOverride | null>;
    getDoorSidesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    unlockAsync(transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

