import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { isEqual } from 'lodash-es';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TimelineController } from '../../shared/controllers/timeline/timeline.controller';
import { ITimelineController, ITimelineProvider } from '../../shared/controllers/timeline/timeline.controller.interfaces';
import { SettingsService, USER_SETTINGS_SERVICE } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionTypes } from '../../shared/enumerations/option-types';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class TimelineService {
    //#region Constants

    private static readonly bookmarksProvider = SafeGuid.parse('156F26CE-B014-4992-8186-E70344A4D794');

    //#endregion

    //#region Fields

    @Output() public activeProviders: IGuid[] = [];
    @Output() public bookmarksVisible = true;

    @Output() public providersChanged = new EventEmitter();

    private scClient: SecurityCenterClient;

    private activeProvidersString: string[] = [];
    private providers: ITimelineProvider[] | undefined;

    //#endregion

    //#region Constructors

    constructor(securityCenterClientService: SecurityCenterClientService, @Inject(USER_SETTINGS_SERVICE) private userSettingsService: SettingsService) {
        this.scClient = securityCenterClientService?.scClient;

        userSettingsService.onSettingsChanged$.pipe(untilDestroyed(this)).subscribe(() => this.onSettingsChanged());

        this.init().fireAndForget();

        this.scClient.onLogonStateChanged((args) => {
            if (!args.loggedOn()) {
                this.activeProviders.length = 0;
                this.providers = undefined;
            } else {
                this.applyDefaultSettingsIfNotSet().fireAndForget();
            }
        });
    }

    //#endregion

    public async applyDefaultSettingsAsync(): Promise<string> {
        let settings = '';

        // if none, be sure the settings is applied one time
        const providers = await this.fetchProvidersAsync();
        if (providers) {
            this.activeProvidersString.length = 0;

            providers.forEach((provider) => {
                if (provider.isEnabledDefault) {
                    this.activeProvidersString.push(provider.id.toString());
                }
            });

            settings = JSON.stringify(this.activeProvidersString);
            this.userSettingsService.set(OptionTypes.Appearance, 'timelineProviders', settings);
        }
        return settings;
    }

    public async fetchProvidersAsync(): Promise<ITimelineProvider[] | undefined> {
        const timelineController = await this.scClient.getAsync<TimelineController, ITimelineController>(TimelineController);
        if (timelineController) {
            const providers = await timelineController.getProvidersAsync();
            if (providers) {
                this.providers = [];
                providers.forEach((x) => this.providers?.push(x));
            }
        }
        return this.providers;
    }

    private async applyDefaultSettingsIfNotSet(): Promise<void> {
        const providersString = this.userSettingsService.get<string>(OptionTypes.Appearance, 'timelineProviders');
        if (!providersString) {
            await this.applyDefaultSettingsAsync();
        }
    }

    private loadSettings(): void {
        const providersString = this.userSettingsService.get<string>(OptionTypes.Appearance, 'timelineProviders');
        if (providersString) {
            const activeProviders = JSON.parse(providersString) as string[];

            if (!isEqual(this.activeProvidersString, activeProviders) || this.activeProviders.length === 0) {
                this.activeProvidersString = activeProviders;

                this.activeProviders.length = 0;
                this.bookmarksVisible = false;

                this.activeProvidersString.forEach((x) => {
                    const guid = SafeGuid.parse(x);
                    this.activeProviders.push(guid);

                    // keep a variable determining if the bookmarks are enabled
                    if (guid.equals(TimelineService.bookmarksProvider)) {
                        this.bookmarksVisible = true;
                    }
                });

                // send an event informing that the list of active providers has changed
                this.providersChanged.emit();
            }
        }
    }

    private onSettingsChanged() {
        this.loadSettings();
    }

    private async init() {
        await this.applyDefaultSettingsIfNotSet();
        this.loadSettings();
    }
}
