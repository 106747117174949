import { backwardCompatible, BackwardCompatible } from './IEntityPropWrapper';
import { Entity } from 'RestClient/Client/Model/Entity';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { AlarmEntity } from 'RestClient/Client/Model/AlarmEntity';
import { AccessControlUnitEntity } from 'RestClient/Client/Model/AccessControl/AccessControlUnitEntity';
import { AccessPointRuleEntity } from 'RestClient/Client/Model/AccessControl/AccessPointRuleEntity';
import { DoorEntity } from 'RestClient/Client/Model/AccessControl/DoorEntity';
import { MapEntity } from 'RestClient/Client/Model/MapEntity';
import { SafeGuid } from 'safeguid/dist/safeguid';
import { AccessPointEntity } from 'RestClient/Client/Model/AccessControl/AccessPointEntity';
import { ApplicationEntity } from 'RestClient/Client/Model/Application/ApplicationEntity';
import { AreaEntity } from 'RestClient/Client/Model/AreaEntity';
import { CameraEntity } from 'RestClient/Client/Model/Video/CameraEntity';
import { CardholderEntity } from 'RestClient/Client/Model/AccessControl/CardholderEntity';
import { CardholderGroupEntity } from 'RestClient/Client/Model/AccessControl/CardholderGroupEntity';
import { CredentialEntity } from 'RestClient/Client/Model/AccessControl/CredentialEntity';
import { DeviceEntity } from 'RestClient/Client/Model/Devices/DeviceEntity';
import { ElevatorEntity } from 'RestClient/Client/Model/AccessControl/ElevatorEntity';
import { RoleEntity } from 'RestClient/Client/Model/Roles/RoleEntity';
import { PartitionEntity } from 'RestClient/Client/Model/PartitionEntity';
import { SystemConfigurationEntity } from 'RestClient/Client/Model/SystemConfigurationEntity';
import { ScheduleEntity } from 'RestClient/Client/Model/ScheduleEntity';
import { UnitEntity } from 'RestClient/Client/Model/UnitEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { UserGroupEntity } from 'RestClient/Client/Model/UserGroupEntity';
import { VisitorEntity } from 'RestClient/Client/Model/AccessControl/VisitorEntity';
import { ZoneEntity } from 'RestClient/Client/Model/AccessControl/ZoneEntity';
import { MacroEntity } from "RestClient/Client/Model/MacroEntity";
import { LprUnitEntity } from 'RestClient/Client/Model/LicensePlateRecognition/LprUnitEntity';
import { PatrollerEntity } from 'RestClient/Client/Model/LicensePlateRecognition/LprPatrollerEntity';
import { HotlistEntity } from 'RestClient/Client/Model/LicensePlateRecognition/HotlistEntity';
import { UserTaskEntity } from 'RestClient/Client/Model/UserTaskEntity';
import { IntrusionAreaEntity } from 'RestClient/Client/Model/Intrusion/IntrusionAreaEntity';
import { IntrusionUnitEntity } from 'RestClient/Client/Model/Intrusion/IntrusionUnitEntity';
import { ServerEntity } from 'RestClient/Client/Model/ServerEntity';
import { VideoUnitEntity } from 'RestClient/Client/Model/Video/VideoUnitEntity';
import { FileEntity } from 'RestClient/Client/Model/FileEntity';
import { ThreatLevelEntity } from 'RestClient/Client/Model/ThreatLevelEntity';
import { StreamEntity } from 'RestClient/Client/Model/Video/StreamEntity';
import { NetworkEntity } from 'RestClient/Client/Model/NetworkEntity';
import { AgentEntity } from 'RestClient/Client/Model/AgentEntity';
import { RouteEntity } from 'RestClient/Client/Model/RouteEntity';
import { SequenceEntity } from 'RestClient/Client/Model/Video/SequenceEntity';
import { TilePluginEntity } from 'RestClient/Client/Model/TilePluginEntity';
import { TransferGroupEntity } from 'RestClient/Client/Model/Video/TransferGroupEntity';
import { CustomEntity } from 'RestClient/Client/Model/CustomEntity';
import { TileLayoutEntity } from 'RestClient/Client/Model/TileLayoutEntity';
import { DoorTemplateEntity } from 'RestClient/Client/Model/AccessControl/DoorTemplateEntity';
import { AccessManagerRoleEntity } from 'RestClient/Client/Model/AccessControl/AccessManagerRoleEntity';
import { AreaAccessPointEntity } from 'RestClient/Client/Model/AccessControl/AreaAccessPointEntity';
import { DoorAccessPointEntity } from 'RestClient/Client/Model/AccessControl/DoorAccessPointEntity';
import { DoorSideAccessPointEntity } from 'RestClient/Client/Model/AccessControl/DoorSideAccessPointEntity';
import { MobileApplicationEntity } from 'RestClient/Client/Model/Application/MobileApplicationEntity';
import { InputDeviceEntity } from 'RestClient/Client/Model/Devices/InputDeviceEntity';
import { IntrusionInputDeviceEntity } from 'RestClient/Client/Model/Devices/IntrusionInputDeviceEntity';
import { OutputDeviceEntity } from 'RestClient/Client/Model/Devices/OutputDeviceEntity';
import { ContentFileEntity } from 'RestClient/Client/Model/LicensePlateRecognition/ContentFileEntity';
import { LicensePlateManagerRoleEntity } from 'RestClient/Client/Model/LicensePlateRecognition/LicensePlateManagerRole';
import { LocatableEntity } from 'RestClient/Client/Model/LocatableEntity';
import { ArchiverAgentEntity } from 'RestClient/Client/Model/Video/ArchiverAgentEntity';
import { ArchiverRoleEntity } from 'RestClient/Client/Model/Video/ArchiverRoleEntity';
import { AuxiliaryArchiverRoleEntity } from 'RestClient/Client/Model/Video/AuxiliaryArchiverRoleEntity';
import { MediaRouterRoleEntity } from 'RestClient/Client/Model/Video/MediaRouterRoleEntity';
import { ActiveDirectoryRoleEntity } from 'RestClient/Client/Model/Roles/ActiveDirectoryRoleEntity';
import { AdfsRoleEntity } from 'RestClient/Client/Model/Roles/AdfsRoleEntity';
import { AuthenticationRoleEntity } from 'RestClient/Client/Model/Roles/AuthenticationRoleEntity';
import { DirectoryFailoverRoleEntity } from 'RestClient/Client/Model/Roles/DirectoryFailOverRoleEntity';
import { FederationBaseRoleEntity } from 'RestClient/Client/Model/Roles/FederationBaseRoleEntity';
import { HealthMonitoringRoleEntity } from 'RestClient/Client/Model/Roles/HealthMonitoringRoleEntity';
import { IncidentManagementRoleEntity } from 'RestClient/Client/Model/Roles/IncidentManagementRoleEntity';
import { MapManagerRoleEntity } from 'RestClient/Client/Model/Roles/MapManagerRoleEntity';
import { MediaGatewayRoleEntity } from 'RestClient/Client/Model/Roles/MediaGatewayRoleEntity';
import { OmnicastFederationRoleEntity } from 'RestClient/Client/Model/Roles/OmnicastFederationRoleEntity';
import { PluginRoleEntity } from 'RestClient/Client/Model/Roles/PluginRoleEntity';
import { PointOfSaleRoleEntity } from 'RestClient/Client/Model/Roles/PointOfSaleRoleEntity';
import { ReverseTunnelClientRoleEntity } from 'RestClient/Client/Model/Roles/ReverseTunnelClientRoleEntity';
import { ReverseTunnelServerRoleEntity } from 'RestClient/Client/Model/Roles/ReverseTunnelServerRoleEntity';
import { SecurityCenterFederationRoleEntity } from 'RestClient/Client/Model/Roles/SecurityCenterFederationRoleEntity';
import { WebClientRoleEntity } from 'RestClient/Client/Model/Roles/WebClientRoleEntity';
import { ZoneManagementRoleEntity } from 'RestClient/Client/Model/Roles/ZoneManagementRoleEntity';
import { PrivilegedEntity } from 'RestClient/Client/Model/PrivilegedEntity';

export const EntityWrapper = BackwardCompatible<Entity>([
    backwardCompatible('method', '5.10.3.0', 'getCustomIconsAsync', async function (this: Entity, transaction?: ITransaction | null) {
        const customIcon = await this.getCustomIconAsync(transaction);
        return customIcon ? ObservableCollection.From([customIcon]) : null;
    }),
])(Entity);

// Access Control
export const AccessControlUnitEntityWrapper = BackwardCompatible<AccessControlUnitEntity>([])(AccessControlUnitEntity);
export const AccessManagerRoleEntityWrapper = BackwardCompatible<AccessManagerRoleEntity>([])(AccessManagerRoleEntity);
export const AccessPointEntityWrapper = BackwardCompatible<AccessPointEntity>([])(AccessPointEntity);
export const AccessPointRuleEntityWrapper = BackwardCompatible<AccessPointRuleEntity>([])(AccessPointRuleEntity);
export const AreaAccessPointEntityWrapper = BackwardCompatible<AreaAccessPointEntity>([])(AreaAccessPointEntity);
export const CardholderEntityWrapper = BackwardCompatible<CardholderEntity>([])(CardholderEntity);
export const CardholderGroupEntityWrapper = BackwardCompatible<CardholderGroupEntity>([])(CardholderGroupEntity);
export const CredentialEntityWrapper = BackwardCompatible<CredentialEntity>([])(CredentialEntity);
export const DoorAccessPointEntityWrapper = BackwardCompatible<DoorAccessPointEntity>([])(DoorAccessPointEntity);
export const DoorEntityWrapper = BackwardCompatible<DoorEntity>([])(DoorEntity);
export const DoorSideAccessPointEntityWrapper = BackwardCompatible<DoorSideAccessPointEntity>([])(DoorSideAccessPointEntity);
export const DoorTemplateEntityWrapper = BackwardCompatible<DoorTemplateEntity>([])(DoorTemplateEntity);
export const ElevatorEntityWrapper = BackwardCompatible<ElevatorEntity>([])(ElevatorEntity);
export const VisitorEntityWrapper = BackwardCompatible<VisitorEntity>([])(VisitorEntity);
export const ZoneEntityWrapper = BackwardCompatible<ZoneEntity>([])(ZoneEntity);

// Application
export const ApplicationEntityWrapper = BackwardCompatible<ApplicationEntity>([])(ApplicationEntity);
export const MobileApplicationEntityWrapper = BackwardCompatible<MobileApplicationEntity>([])(MobileApplicationEntity);

// Devices
export const DeviceEntityWrapper = BackwardCompatible<DeviceEntity>([])(DeviceEntity);
export const InputDeviceEntityWrapper = BackwardCompatible<InputDeviceEntity>([])(InputDeviceEntity);
export const IntrusionInputDeviceEntityWrapper = BackwardCompatible<IntrusionInputDeviceEntity>([])(IntrusionInputDeviceEntity);
export const OutputDeviceEntityWrapper = BackwardCompatible<OutputDeviceEntity>([])(OutputDeviceEntity);

// Intrusion
export const IntrusionAreaEntityWrapper = BackwardCompatible<IntrusionAreaEntity>([])(IntrusionAreaEntity);
export const IntrusionUnitEntityWrapper = BackwardCompatible<IntrusionUnitEntity>([])(IntrusionUnitEntity);

// LPR
export const ContentFileEntityWrapper = BackwardCompatible<ContentFileEntity>([])(ContentFileEntity);
export const HotlistEntityWrapper = BackwardCompatible<HotlistEntity>([])(HotlistEntity);
export const LicensePlateManagerRoleEntityWrapper = BackwardCompatible<LicensePlateManagerRoleEntity>([])(LicensePlateManagerRoleEntity);
export const PatrollerEntityWrapper = BackwardCompatible<PatrollerEntity>([])(PatrollerEntity);
export const LprUnitEntityWrapper = BackwardCompatible<LprUnitEntity>([])(LprUnitEntity);

// Roles
export const ActiveDirectoryRoleEntityWrapper = BackwardCompatible<ActiveDirectoryRoleEntity>([])(ActiveDirectoryRoleEntity);
export const AdfsRoleEntityWrapper = BackwardCompatible<AdfsRoleEntity>([])(AdfsRoleEntity);
export const AuthenticationRoleEntityWrapper = BackwardCompatible<AuthenticationRoleEntity>([])(AuthenticationRoleEntity);
export const DirectoryFailoverRoleEntityWrapper = BackwardCompatible<DirectoryFailoverRoleEntity>([])(DirectoryFailoverRoleEntity);
export const FederationBaseRoleEntityWrapper = BackwardCompatible<FederationBaseRoleEntity>([])(FederationBaseRoleEntity);
export const HealthMonitoringRoleEntityWrapper = BackwardCompatible<HealthMonitoringRoleEntity>([])(HealthMonitoringRoleEntity);
export const IncidentManagementRoleEntityWrapper = BackwardCompatible<IncidentManagementRoleEntity>([])(IncidentManagementRoleEntity);
export const MapManagerRoleEntityWrapper = BackwardCompatible<MapManagerRoleEntity>([])(MapManagerRoleEntity);
export const MediaGatewayRoleEntityWrapper = BackwardCompatible<MediaGatewayRoleEntity>([])(MediaGatewayRoleEntity);
export const OmnicastFederationRoleEntityWrapper = BackwardCompatible<OmnicastFederationRoleEntity>([])(OmnicastFederationRoleEntity);
export const PluginRoleEntityWrapper = BackwardCompatible<PluginRoleEntity>([])(PluginRoleEntity);
export const PointOfSaleRoleEntityWrapper = BackwardCompatible<PointOfSaleRoleEntity>([])(PointOfSaleRoleEntity);
export const ReverseTunnelClientRoleEntityWrapper = BackwardCompatible<ReverseTunnelClientRoleEntity>([])(ReverseTunnelClientRoleEntity);
export const ReverseTunnelServerRoleEntityWrapper = BackwardCompatible<ReverseTunnelServerRoleEntity>([])(ReverseTunnelServerRoleEntity);
export const RoleEntityWrapper = BackwardCompatible<RoleEntity>([])(RoleEntity);
export const SecurityCenterFederationRoleEntityWrapper = BackwardCompatible<SecurityCenterFederationRoleEntity>([])(SecurityCenterFederationRoleEntity);
export const WebClientRoleEntityWrapper = BackwardCompatible<WebClientRoleEntity>([])(WebClientRoleEntity);
export const ZoneManagementRoleEntityWrapper = BackwardCompatible<ZoneManagementRoleEntity>([])(ZoneManagementRoleEntity);

// Video
export const ArchiverAgentEntityWrapper = BackwardCompatible<ArchiverAgentEntity>([])(ArchiverAgentEntity);
export const ArchiverRoleEntityWrapper = BackwardCompatible<ArchiverRoleEntity>([])(ArchiverRoleEntity);
export const AuxiliaryArchiverRoleEntityWrapper = BackwardCompatible<AuxiliaryArchiverRoleEntity>([])(AuxiliaryArchiverRoleEntity);
export const CameraEntityWrapper = BackwardCompatible<CameraEntity>([])(CameraEntity);
export const MediaRouterRoleEntityWrapper = BackwardCompatible<MediaRouterRoleEntity>([])(MediaRouterRoleEntity);
export const SequenceEntityWrapper = BackwardCompatible<SequenceEntity>([])(SequenceEntity);
export const StreamEntityWrapper = BackwardCompatible<StreamEntity>([])(StreamEntity);
export const TransferGroupEntityWrapper = BackwardCompatible<TransferGroupEntity>([])(TransferGroupEntity);
export const VideoUnitEntityWrapper = BackwardCompatible<VideoUnitEntity>([])(VideoUnitEntity);

// Generic
export const AgentEntityWrapper = BackwardCompatible<AgentEntity>([])(AgentEntity);
export const AlarmEntityWrapper = BackwardCompatible<AlarmEntity>([])(AlarmEntity);
export const AreaEntityWrapper = BackwardCompatible<AreaEntity>([])(AreaEntity);
export const CustomEntityWrapper = BackwardCompatible<CustomEntity>([])(CustomEntity);
export const FileEntityWrapper = BackwardCompatible<FileEntity>([])(FileEntity);
export const LocatableEntityWrapper = BackwardCompatible<LocatableEntity>([])(LocatableEntity);
export const MacroEntityWrapper = BackwardCompatible<MacroEntity>([])(MacroEntity);
export const MapEntityWrapper = BackwardCompatible<MapEntity>([
    backwardCompatible('get', '5.10.3.0', 'backgroundColor', 0),
    backwardCompatible('get', '5.10.3.0', 'cruncherId', SafeGuid.EMPTY),
    backwardCompatible('get', '5.10.3.0', 'geoLocalized', false),
    backwardCompatible('get', '5.10.3.0', 'hideEmptyLayers', false),
    backwardCompatible('get', '5.10.3.0', 'info', ''),
    backwardCompatible('get', '5.10.3.0', 'isMapImage', false),
    backwardCompatible('get', '5.10.3.0', 'lockDisplay', false),
    backwardCompatible('get', '5.10.3.0', 'lockZoom', false),
    backwardCompatible('get', '5.10.3.0', 'originalFileExtension', ''),
    backwardCompatible('get', '5.10.3.0', 'projection', ''),
    backwardCompatible('get', '5.10.3.0', 'rotation', 0),
    backwardCompatible('get', '5.10.3.0', 'scaleFactor', 1),
])(MapEntity);
export const NetworkEntityWrapper = BackwardCompatible<NetworkEntity>([])(NetworkEntity);
export const PartitionEntityWrapper = BackwardCompatible<PartitionEntity>([])(PartitionEntity);
export const PrivilegedEntityWrapper = BackwardCompatible<PrivilegedEntity>([])(PrivilegedEntity);
export const RouteEntityWrapper = BackwardCompatible<RouteEntity>([])(RouteEntity);
export const ScheduleEntityWrapper = BackwardCompatible<ScheduleEntity>([])(ScheduleEntity);
export const ServerEntityWrapper = BackwardCompatible<ServerEntity>([])(ServerEntity);
export const SystemConfigurationEntityWrapper = BackwardCompatible<SystemConfigurationEntity>([])(SystemConfigurationEntity);
export const ThreatLevelEntityWrapper = BackwardCompatible<ThreatLevelEntity>([])(ThreatLevelEntity);
export const TileLayoutEntityWrapper = BackwardCompatible<TileLayoutEntity>([])(TileLayoutEntity);
export const TilePluginEntityWrapper = BackwardCompatible<TilePluginEntity>([])(TilePluginEntity);
export const UnitEntityWrapper = BackwardCompatible<UnitEntity>([])(UnitEntity);
export const UserEntityWrapper = BackwardCompatible<UserEntity>([])(UserEntity);
export const UserGroupEntityWrapper = BackwardCompatible<UserGroupEntity>([])(UserGroupEntity);
export const UserTaskEntityWrapper = BackwardCompatible<UserTaskEntity>([])(UserTaskEntity);
