import { Component, Injector } from '@angular/core';
import { GenModalService } from '@genetec/gelato-angular';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { ChangePasswordComponent } from '../change-password.component';

@Component({
    selector: 'app-change-password-notification',
    templateUrl: './change-password-notification.component.html',
})
export class ChangePasswordNotificationComponent extends ConnectionAwareModalComponent {
    public daysRemaining?: number;
    constructor(protected authService: AuthService, injector: Injector, trackingService: TrackingService, private modalService: GenModalService) {
        super(authService, injector, trackingService);
    }
    public openChangePasswordModal = async (): Promise<boolean> => {
        this.modalService.open(ChangePasswordComponent);
        return Promise.resolve(true);
    };
}
