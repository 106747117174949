import { Injectable, OnDestroy } from '@angular/core';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import CryptoJS from 'crypto-js';
import Dexie from 'dexie';
import { get } from 'lodash-es';
import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { IFileEntity } from 'RestClient/Client/Interface/IFileEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { ApiException, CustomIconQueryEntry, CustomIconState, EncryptionKey, FileCacheQueryEntry, ResourcesClient } from '../../api/api';
import { IDBService } from '../idb/idb.service';
import { IDBStringDataObject } from '../idb/interfaces';
import { LoggerService } from '../logger/logger.service';
import { ResourcesOptions } from './interfaces';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Injectable({
    providedIn: 'root',
})
export class ResourcesService implements OnDestroy {
    private encryptionKey: EncryptionKey | null = null;
    private subscriptions = new SubscriptionCollection();

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        private resourcesClient: ResourcesClient,
        private idbService: IDBService,
        private loggerService: LoggerService
    ) {
        this.subscriptions.add(
            securityCenterClientService.scClient.onLogonStateChanged((arg) => {
                this.onLogonStateChanged(arg);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribeAll();
    }

    /**
     * Clears the cache for the tables used by [[ResourcesService]]
     *
     * @param maxAge - If set, the records that were access between now and maxAge will be kept (key lastAccess)
     */
    public clearCache(maxAge?: number): void {
        if (maxAge) {
            [this.idbService.files, this.idbService.resources].forEach((table) => {
                table
                    ?.where('lastAccess')
                    .below(Date.now() - maxAge)
                    .delete()
                    .then((deletedRecords) => {
                        this.loggerService.traceDebug(`${deletedRecords} records deleted from IDB from table ${table.name}`);
                    })
                    .catch(() => {
                        this.loggerService.traceError(`Error trying to clear the ${table.name} cache.`);
                    });
            });
        } else {
            this.idbService.files?.clear().catch(() => {
                this.loggerService.traceError(`Error trying to clear the files cache.`);
            });
            this.idbService.resources?.clear().catch(() => {
                this.loggerService.traceError(`Error trying to clear the resources cache.`);
            });
        }
    }

    /**
     * Retrieve a cached item from the cache (files table).
     *
     * @param key - The item's key
     * @param useEncryption - (Optional) Boolean flag indicitating if encryption should be utilized, default: _true_
     * @param updateCache - (Optional) Boolean flag indicating if the cache should be updated when item retrieved (last access bump), default: _true_
     * @returns The cached data or _null_ if not found
     */
    public async getCachedItem(key: string, useEncryption?: boolean, updateCache?: boolean): Promise<string | null> {
        return this.getCachedItemInternalAsync(this.idbService.files, key, useEncryption, updateCache);
    }

    /**
     * Update the cache at the specified key with the supplied data (files table).
     *
     * @param key - The key of the item
     * @param data - The data payload
     * @param useEncryption - (Optional) Boolean flag to utilize encryption, default: _true_
     * @returns _true_ if the update was successfully added or updated to the cache, _false_ otherwise
     */
    public async updateCachedItem(key: string, data: string, useEncryption?: boolean): Promise<boolean> {
        return this.updateCachedItemInternalAsync(this.idbService.files, key, data, useEncryption);
    }

    /**
     * Gets the custom icon of entities. You have to provide the **customIconId** since it will be used to fetch the icon from the IndexedDB cache first before
     * sending any requests to the role. Alternatively, provide the [[Entity]] instance and the [[IEntity.customIconId]] property of the entity will be used.
     *
     * *Note: Only one icon per entity can be requested at once. Separate calls if you want custom icons from two different states.*
     *
     * @param customIcons The entity to get the custom icons from. Have to provide the **customIconId** and **state** for caching purposes (they will be basically used to construct a key for the cache table).
     * @example
     * [
     *     { entity: '00000001-0000-babe-0000-c4546da31914f', customIconId: 'c713a355-4289-444b-b731-8faec5aa4c3c'},
     *     { entity: 'ba0cbe7a-e5e3-4e08-8cb8-11cea91c6c73f', customIconId: '446c748e-6048-41e1-8d08-9efffc404275', state: CustomIconState.Active},
     *     { entity: myEntity, state: CustomIconState.Active},
     *     { entity: mySecondEntity},
     *     ...
     * ]
     * @param options Options related to IndexedDB Caching.
     * @returns A record-like object linking the entityId to its custom icon data (base64 string)
     * @example
     * const customIconResult = this.resourcesService.getCustomIconAsync([
     *      { entity: '00000001-0000-babe-0000-c4546da31914f', customIconId: 'c713a355-4289-444b-b731-8faec5aa4c3c'}
     * ]);
     *
     * const customIconBase64 = customIconResult['00000001-0000-babe-0000-c4546da31914f'];
     */
    public async getCustomIconAsync(
        customIcons: ({ entity: IEntity; state?: CustomIconState } | { entity: IGuid | string; customIconId: IGuid | string; state?: CustomIconState })[],
        options?: ResourcesOptions
    ): Promise<Record<string, string>> {
        const result: Record<string, string> = {};

        // Cache flag setups
        const useCache = this.idbService.isIDBSupported() && !options?.bypassCache; // Defaults to not bypassing cache
        const updateCache = this.idbService.isIDBSupported() && (options?.updateCache ?? true); // Defaults to updating cache
        const encrypted = options?.encrypted ?? true; // Encrypted by default

        // Prepare an eventual query to the backend
        const customIconQueryEntries: CustomIconQueryEntry[] = [];

        // Try to get custom icons from cache
        const customIconIdsByEntityId = this.createPropsByEntityIdMap(customIcons) as Record<string, { customIconId: IGuid | string; state?: CustomIconState }>;
        for (const [entityId, { customIconId, state }] of Object.entries(customIconIdsByEntityId)) {
            let data = null;
            if (useCache && customIconId) {
                const cacheKey = this.createCustomIconCacheKey(customIconId, state);
                data = await this.getCachedItemInternalAsync(this.idbService.files, cacheKey, encrypted, updateCache);

                if (data) {
                    result[entityId.toString()] = data;
                }
            }

            // If not using cache or not found in cache, add to eventual query.
            if (!data) {
                // Not found in cache, will have to fetch from server
                customIconQueryEntries.push(
                    new CustomIconQueryEntry({
                        entityId: SafeGuid.parse(entityId.toString()),
                        state,
                    })
                );
            }
        }

        // Query the backend for icons not found in the cache
        if (customIconQueryEntries.length) {
            try {
                const customIconResult = await this.resourcesClient.getCustomIcon(customIconQueryEntries).toPromise();
                if (customIconResult) {
                    // Only need to loop in results if we tried fetching some from the IDB cache. Otherwise we can just return the results directly.
                    if (useCache) {
                        // Update cache or merge results from cache with results from server
                        await Promise.all(
                            Object.entries(customIconResult).map(([entityId, customIconData]) => {
                                if (updateCache) {
                                    // Get back customIconId and state from entityId
                                    const { customIconId, state } = customIconIdsByEntityId[entityId] || [undefined, undefined];

                                    // Add or update to cache
                                    if (customIconId && !SafeGuid.EMPTY.equals(entityId)) {
                                        this.updateCachedItemInternalAsync(
                                            this.idbService.files,
                                            this.createCustomIconCacheKey(customIconId, state),
                                            customIconData,
                                            encrypted
                                        ).fireAndForget();
                                    }
                                }

                                result[entityId] = customIconData;
                            })
                        );
                    } else {
                        return customIconResult;
                    }
                }
            } catch (e) {
                if (e instanceof ApiException && e.status === 400) {
                    this.loggerService.traceError(`ResourcesService getCustomIcon Bad Request${e.response ? `: ${JSON.stringify(e.response)}` : ''}`);
                } else {
                    this.loggerService.traceError(`ResourcesService getCustomIcon Unknown Error${e instanceof Error ? `: ${e.message}` : ''}`);
                }
            }
        }

        return result;
    }

    public async getFilesCacheAsync(
        fileCacheQueryEntry: IFileEntity | { entity: IGuid | string | IEntity; fileCacheId: IGuid | string }[],
        options?: ResourcesOptions
    ): Promise<Record<string, string>> {
        const result: Record<string, string> = {};

        // Cache flag setups
        const useCache = this.idbService.isIDBSupported() && !options?.bypassCache; // Defaults to not bypassing cache
        const updateCache = this.idbService.isIDBSupported() && (options?.updateCache ?? true); // Defaults to updating cache
        const encrypted = options?.encrypted ?? true; // Encrypted by default

        // Prepare the query to the backend
        const fileCacheQueryEntries: FileCacheQueryEntry[] = [];

        // Argument discrimination, create a structure easier to deal with that contains all the information we need.
        // fileCacheGuid -> { entity: IGuid | string | IEntity }
        let fileCacheIdsByEntityId: Record<string, { entity: IEntity | IGuid | string }>;
        if (Array.isArray(fileCacheQueryEntry)) {
            fileCacheIdsByEntityId = this.createPropsByEntityIdMap(fileCacheQueryEntry, 'fileCacheId') as Record<string, { entity: IEntity | IGuid | string }>;
        } else {
            // IFileEntity
            fileCacheIdsByEntityId = { [fileCacheQueryEntry.cacheGuid.toString()]: { entity: fileCacheQueryEntry.id.toString() } };
        }

        // Try to get custom icons from cache
        // Deconstruct arguments
        for (const [fileCacheId, { entity }] of Object.entries(fileCacheIdsByEntityId)) {
            let data = null;
            const fileId = fileCacheId.toString();
            if (useCache) {
                if (fileId) {
                    data = await this.getCachedItemInternalAsync(this.idbService.files, fileId, encrypted, updateCache);
                    if (data) {
                        result[fileId] = data;
                    }
                }
            }

            if (!data && fileCacheId) {
                // Not found in cache, will have to fetch from server
                fileCacheQueryEntries.push(
                    new FileCacheQueryEntry({
                        entityId: SafeGuid.parse((typeof entity === 'object' && 'id' in entity ? entity.id : entity).toString()),
                        fileCacheId: SafeGuid.parse(fileId),
                    })
                );
            }
        }

        if (fileCacheQueryEntries.length) {
            try {
                const fileCacheResult = await this.resourcesClient.getFileCache(fileCacheQueryEntries).toPromise();
                if (fileCacheResult) {
                    // Only need to loop in results if we tried fetching some from the IDB cache.
                    if (useCache) {
                        // Update cache or merge results from cache with results from server
                        await Promise.all(
                            Object.entries(fileCacheResult).map(([fileCacheId, fileCacheData]) => {
                                if (updateCache) {
                                    // Add or update to cache
                                    if (!SafeGuid.EMPTY.equals(fileCacheId)) {
                                        this.updateCachedItemInternalAsync(this.idbService.files, fileCacheId, fileCacheData, encrypted).fireAndForget();
                                    }
                                }

                                result[fileCacheId] = fileCacheData;
                            })
                        );
                    } else {
                        return fileCacheResult;
                    }
                }
            } catch (e) {
                if (e instanceof ApiException && e.status === 400) {
                    this.loggerService.traceError(`ResourcesService getFileCache Bad Request${e.response ? `: ${JSON.stringify(e.response)}` : ''}`);
                } else {
                    this.loggerService.traceError(`ResourcesService getFileCache Unknown Error${e instanceof Error ? `: ${e.message}` : ''}`);
                }
            }
        }

        return result;
    }

    /**
     * Gets the resource from the IDB cache or the server if not found in the cache.
     *
     * @param {string} key - The key of the resource to get
     * @param {ResourcesOptions} options - Options related to IndexedDB Caching.
     * @returns A promise with string representing the resource if found, null if not.
     */
    public async getResourcesAsync(key: string, options?: ResourcesOptions): Promise<string | null>;
    /**
     * Gets the resources from the IDB cache or the server if not found in the cache.
     *
     * @param {string} key - The key of the resources to get
     * @param {ResourcesOptions} options - Options related to IndexedDB Caching.
     * @returns A promise returning a Map of the resources mapped by key. Map contains entries for resources that were not found and have the value null.
     */
    public async getResourcesAsync(keys: string[], options?: ResourcesOptions): Promise<Record<string, string>>;
    public async getResourcesAsync(keys: string | string[], options?: ResourcesOptions): Promise<string | null | Record<string, string>> {
        const result: Record<string, string> = {};
        const useCache = this.idbService.isIDBSupported() && !options?.bypassCache; // Defaults to not bypassing cache
        const updateCache = this.idbService.isIDBSupported() && (options?.updateCache ?? true); // Defaults to updating cache
        const encrypted = options?.encrypted ?? true; // Encrypted by default

        const keysArray = this.toArray(keys);
        const resourcesToFetch: string[] = useCache ? [] : keysArray;

        if (useCache) {
            await Promise.all(
                keysArray.map(async (key) => {
                    const data = await this.getCachedItemInternalAsync(this.idbService.resources, key.toString(), encrypted, updateCache);
                    if (data) {
                        result[key] = data;
                    } else {
                        // add to resources to get from server
                        resourcesToFetch.push(key);
                    }
                })
            );
        }

        // Get any resources not found in cache (if supported, if not get all requested resources)
        if (resourcesToFetch.length) {
            const resourcesData = await this.getResourcesFromServerAsync(resourcesToFetch);
            if (resourcesData != null) {
                resourcesToFetch.forEach((key) => {
                    const data = resourcesData[key];

                    // add or update to cache
                    if (updateCache && data) {
                        this.updateCachedItemInternalAsync(this.idbService.resources, key, data, encrypted).fireAndForget();
                    }

                    if (data) {
                        result[key] = data;
                    }
                });
            }
        }

        // Only return the data directly if only request a single resource
        return Array.isArray(keys) ? result : Object.values(result)[0] ?? null;
    }

    private async getCachedItemInternalAsync(table: Dexie.Table<IDBStringDataObject> | null, key: string, useEncryption?: boolean, updateCache?: boolean): Promise<string | null> {
        const useCache = this.idbService.isIDBSupported(); // Defaults to not bypassing cache
        const update = useCache && (updateCache ?? true);
        const encrypted = useEncryption ?? true; // Encrypted by default

        if (useCache && table && key) {
            this.loggerService.traceDebug(`Getting cached data with key ${key}`);
            const storeData = (
                await table.get(key).catch((error) => {
                    this.loggerService.traceError(`Error trying to access item in cache`, error);
                    return undefined;
                })
            )?.data;

            if (storeData) {
                if (update) {
                    this.updateLastAccess(table, key, storeData);
                }

                // Decrypt if needed.
                return encrypted ? await this.decryptAsync(storeData) : storeData;
            }
        }
        return null;
    }

    private updateLastAccess(table: Dexie.Table<IDBStringDataObject, any>, key: string, storeData: string) {
        // Update the DB (LastAccess) [Background]
        table
            .put({
                id: key,
                data: storeData,
                lastAccess: Date.now(),
            })
            .catch((e) => {
                this.loggerService.traceError(`Error trying to update last access item in cache`, e);
            });
    }

    private async updateCachedItemInternalAsync(table: Dexie.Table<IDBStringDataObject> | null, key: string, data: string, useEncryption?: boolean): Promise<boolean> {
        const useCache = this.idbService.isIDBSupported(); // Defaults to not bypassing cache
        const encrypted = useEncryption ?? true; // Encrypted by default
        // Add or update to cache
        if (useCache && table && key && data) {
            this.loggerService.traceDebug(`Updating item in cache with id ${key}, will be encrypted: ${encrypted}`, data);
            let storeData: string | null = data;

            // Do we need to encrypt the data?
            if (encrypted) {
                storeData = await this.encryptAsync(data);
                if (!storeData) {
                    this.loggerService.traceDebug(`Failed to update item in cache because data it could not be encrypted`);
                }
            }

            // Ensure the data is still valid and update the cache
            if (storeData) {
                this.updateLastAccess(table, key, storeData);
                // Success!
                return true;
            }
        }
        // Something did not succeed updating the cache
        return false;
    }

    /**
     * Create a map-like object with entityIds as key and the rest of the properties as a value
     *
     * @example
     * createPropsByEntityIdMap([{ entity: 'myguid', prop1: '1', prop2: '2'}, ...]) // Returns { 'myguid': { 'entity': 'myguid', prop1: '1', prop2: '2' }, ... }
     */
    private createPropsByEntityIdMap(
        entities: ({ entity: IEntity | IGuid | string } & Record<string, unknown>)[],
        uniqueIdPropNamePath = 'entity'
    ): Record<string, Record<string, unknown>> {
        const result = entities.reduce((map, obj) => {
            const id = get(obj, uniqueIdPropNamePath);
            if (isGuid(id)) {
                const idString = id.toString();
                map[idString] = {};
                Object.entries(obj).forEach(([key, value]) => {
                    map[idString][key] = value;
                });
            }
            return map;
        }, {} as Record<string, Record<string, unknown>>);
        return result;
    }

    private createCustomIconCacheKey(customIconId: IGuid | string, state?: CustomIconState) {
        return `${customIconId.toString()}${state || ''}`;
    }

    private async encryptAsync(data: string): Promise<string | null> {
        try {
            this.loggerService.traceDebug('Encrypting data', data);
            // fetch the encryption key from server when needed
            if (!this.encryptionKey) {
                // retrieve the encryption key from server and cache it
                this.loggerService.traceDebug(`Getting encryption key from backend`);
                this.encryptionKey = await this.resourcesClient.getEncryptionKey().toPromise();
            }

            // ensure we have a valid encryption key
            if (this.encryptionKey && this.encryptionKey.type.toLowerCase() === 'aes') {
                const encrypted = CryptoJS.AES.encrypt(data, this.encryptionKey.key, { mode: CryptoJS.mode.ECB });
                return encrypted.toString();
            }
        } catch (e) {
            this.loggerService.traceWarning('Could not encrypt data', data, e);
        }
        return null;
    }

    private async decryptAsync(encrypted: string): Promise<string | null> {
        try {
            this.loggerService.traceDebug('Decrypting data', encrypted);
            // fetch the encryption key from server when needed
            if (!this.encryptionKey) {
                // retrieve the encryption key from server and cache it
                this.loggerService.traceDebug(`Getting encryption key from backend`);
                this.encryptionKey = await this.resourcesClient.getEncryptionKey().toPromise();
            }

            // ensure we have a valid encryption key
            if (this.encryptionKey && this.encryptionKey.type.toLowerCase() === 'aes') {
                const decrypted = CryptoJS.AES.decrypt(encrypted, this.encryptionKey.key, { mode: CryptoJS.mode.ECB });
                return decrypted.toString(CryptoJS.enc.Utf8);
            }
        } catch (error) {
            this.loggerService.traceWarning(`Could not decrypt data`, error);
        }
        return null;
    }

    private async getResourcesFromServerAsync(resources: string[]): Promise<Record<string, string> | null> {
        if (!resources.length) return null;

        this.loggerService.traceDebug(`Getting resource from server`, resources);
        const data = await this.resourcesClient.getResources(resources).toPromise();

        if (!data) {
            this.loggerService.traceWarning(`Resources not found`);
        }

        return data;
    }

    private toArray<T>(obj: T | T[]): T[] {
        return Array.isArray(obj) ? obj : [obj];
    }

    //#region Events

    private onLogonStateChanged(e: LogonStateChangedArgs) {
        // On logoff, clear the encryption key
        if (!e.loggedOn()) {
            this.encryptionKey = null;
        }
    }

    //#endregion
}
