import { Component, ViewChild, Injector } from '@angular/core';
import { GenMeltedModalComponent, MeltedModalAction } from '@genetec/gelato-angular';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { AuthService } from '@securityCenter/services/authentication/auth.service';

@Component({
    selector: 'app-goto-time',
    templateUrl: './goto-time.component.html',
    styleUrls: ['./goto-time.component.scss'],
})
export class GotoTimeComponent extends ConnectionAwareModalComponent {
    @ViewChild(GenMeltedModalComponent) public genModalComponent!: GenMeltedModalComponent;

    public isMilitaryTime = false;
    public timestamp = moment().utc().toISOString();

    constructor(authService: AuthService, injector: Injector, trackingService: TrackingService, private loggerService: LoggerService, translationService: TranslateService) {
        super(authService, injector, trackingService);
        const timeFormat = moment().locale(translationService.currentLang).localeData().longDateFormat('LT');
        // Covers the case where HH, H, kk or k is specified. (k, kk is 24 hours from 1 to 24)
        this.isMilitaryTime = timeFormat.includes('H') || timeFormat.includes('k');
    }

    public onAction(action: MeltedModalAction): void {
        this.loggerService.traceInformation(this.timestamp);
    }

    public getModalInstance(): GenMeltedModalComponent {
        return this.genModalComponent;
    }

    public close(): void {
        this.genModalComponent.onCloseClick().fireAndForget();
    }
}
