<gen-button
    id="btn-toggle-exports"
    nf
    *ngIf="exports.length > 0"
    appTooltip="{{ 'STE_LABEL_NOUN_EXPORTSTATUS' | translate }}"
    [flavor]="ButtonFlavor.Flat"
    (click)="showExportingVideos()"
>
    <gen-icon [icon]="Icon.Download"></gen-icon>
    {{ exports.length }}
</gen-button>

<gen-popup [position]="PopupPosition.BottomRight" targetSelector="#btn-toggle-exports">
    <div class="export-popup-container">
        <div *ngFor="let instance of exports" class="va-center ha-left bg-background-dark o-hidden export-popup-inner-container">
            <div class="gen-item export-popup-element-container" [ngStyle]="{ 'border-left-color': instance.statusColor }">
                <gen-progress class="w-100" max="100" [value]="instance.progress" appTooltip="{{ instance.progress | number: '1.0-0' }}%">
                    <gen-label>{{ instance.filename }}</gen-label>
                    <gen-message [severity]="Severity.Error">{{ instance.errorMessage }}</gen-message>
                </gen-progress>
                <gen-button [flavor]="ButtonFlavor.Flat" [appTooltip]="instance.closeTooltip" (click)="onButtonRemoveClick(instance)">
                    <gen-icon [icon]="Icon.Close"></gen-icon>
                </gen-button>
            </div>
        </div>
    </div>
</gen-popup>
