/* eslint-disable @typescript-eslint/unbound-method */
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LatLngBounds } from '@genetec/web-maps';
import { MapsProviderClient } from '@modules/map/api/api';
import {
    COMMANDS_SERVICE,
    ContentExtensionServicesProvider,
    CONTENT_SERVICES_PROVIDER,
    SettingsService,
    USER_SETTINGS_SERVICE,
} from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { ResourcesService } from '@modules/shared/services/resources/resources.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { MethodEmitter, StateEmitter } from '@src/app/store';
import { WINDOW } from '@utilities/common-helper';
import { LogonState } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { Observable } from 'rxjs';
import { IGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { MapService } from './map-service';
import { MapState } from './map.state';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class StateMapService extends MapService {
    @Select(MapState.selectedMap)
    public stateSelectedMap$!: Observable<IGuid | undefined>;

    @Select(MapState.selectedMapObject)
    public stateSelectedMapObject$!: Observable<IGuid | undefined>;

    @SelectSnapshot(MapState.selectedMap)
    public stateSelectedMap: IGuid | undefined;

    @SelectSnapshot(MapState.selectedMapObject)
    public stateSelectedMapObject: IGuid | undefined;

    @Select(MapState.viewArea)
    public stateViewArea$!: Observable<LatLngBounds | undefined>;

    @SelectSnapshot(MapState.viewArea)
    public stateViewArea!: LatLngBounds | undefined;

    @MethodEmitter(MapState.setSelectedMap)
    public setStateSelectedMap!: StateEmitter<typeof MapState.setSelectedMap>;

    @MethodEmitter(MapState.setSelectedMapObject)
    public setStateSelectedMapObject!: StateEmitter<typeof MapState.setSelectedMapObject>;

    @MethodEmitter(MapState.setStateViewArea)
    public setStateViewArea!: StateEmitter<typeof MapState.setStateViewArea>;

    constructor(
        securityCenterProvider: SecurityCenterClientService,
        mapsProviderClient: MapsProviderClient,
        loggerService: LoggerService,
        resourcseService: ResourcesService,
        @Inject(CONTENT_SERVICES_PROVIDER) contentServicesProvider: ContentExtensionServicesProvider,
        @Inject(COMMANDS_SERVICE) commandsService: InternalCommandsService,
        translateService: TranslateService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService,
        @Inject(WINDOW) window: Window,
        domSanitizer: DomSanitizer
    ) {
        super(
            securityCenterProvider,
            mapsProviderClient,
            loggerService,
            resourcseService,
            contentServicesProvider,
            commandsService,
            translateService,
            userSettingsService,
            window,
            domSanitizer
        );

        // always check availability since we use reflection to load this component
        if (this.scClient instanceof WebAppClient) {
            this.scClient.onLogonStateChanged((arg) => this.onLogonStateChanged(arg.state));
        }
    }

    private onLogonStateChanged(state: LogonState) {
        if (state === LogonState.LoggedOff) {
            this.setStateSelectedMap(undefined);
            this.setStateSelectedMapObject(undefined);
            this.setStateViewArea(undefined);
        }
    }
}
