import { LogonState } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class SecurityCenterStateService {
    protected scClient: ISecurityCenterClient;

    constructor(securityCenterProvider: SecurityCenterClientService) {
        this.scClient = securityCenterProvider.scClient;

        // always check availability since we use reflection to load this component
        this.scClient = securityCenterProvider?.scClient;
        this.scClient?.onLogonStateChanged((arg) => this.onLogonStateChanged(arg.state));
    }

    protected clearState(): void {
        // override to clear the state on logged off
    }

    protected loadState(): void {
        // override if you want to load a SecurityCenter state here
    }

    private onLogonStateChanged(state: LogonState) {
        if (state === LogonState.LoggedOff) {
            // clear the states of the tiles in case we switch systems
            this.clearState();
        } else if (state === LogonState.LoggedOn || state === LogonState.ReloggedOn) {
            this.loadState();
        }
    }
}
