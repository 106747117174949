// Autogenerated: v2/help/Entities/ApiWebClientRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-15 12:15:59 PM
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { PluginRoleEntity } from './PluginRoleEntity';
import { IWebClientRoleEntity, ISetting, WebClientRoleEntityFields } from '../../Interface/IWebClientRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class Setting extends FieldObject implements ISetting {
    // Key
    public get key(): string {
        return this.getField<string>('Key');
    }
    public set key(value: string) {
        this.setField<string>('Key', value);
    }
    // Value
    public get value(): string {
        return this.getField<string>('Value');
    }
    public set value(value: string) {
        this.setField<string>('Value', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('Key', '');
        this.setField<string>('Value', '');
    }
}

// #endregion Inner classes

export class WebClientRoleEntity extends PluginRoleEntity implements IWebClientRoleEntity {
    // #region REST Properties

    // ExportFolder
    public get exportFolder(): string {
        return this.getField<string>(WebClientRoleEntityFields.exportFolderField);
    }
    public set exportFolder(value: string) {
        this.setField<string>(WebClientRoleEntityFields.exportFolderField, value);
    }

    // HttpPort
    public get httpPort(): number {
        return this.getField<number>(WebClientRoleEntityFields.httpPortField);
    }
    public set httpPort(value: number) {
        this.setField<number>(WebClientRoleEntityFields.httpPortField, value);
    }

    // HttpsPort
    public get httpsPort(): number {
        return this.getField<number>(WebClientRoleEntityFields.httpsPortField);
    }
    public set httpsPort(value: number) {
        this.setField<number>(WebClientRoleEntityFields.httpsPortField, value);
    }

    // UseDefaultWebServerPort
    public get useDefaultWebServerPort(): boolean {
        return this.getField<boolean>(WebClientRoleEntityFields.useDefaultWebServerPortField);
    }
    public set useDefaultWebServerPort(value: boolean) {
        this.setField<boolean>(WebClientRoleEntityFields.useDefaultWebServerPortField, value);
    }

    // UseInfiniteSessions
    public get useInfiniteSessions(): boolean {
        return this.getField<boolean>(WebClientRoleEntityFields.useInfiniteSessionsField);
    }
    public set useInfiniteSessions(value: boolean) {
        this.setField<boolean>(WebClientRoleEntityFields.useInfiniteSessionsField, value);
    }

    // WebAddress
    public get webAddress(): string {
        return this.getField<string>(WebClientRoleEntityFields.webAddressField);
    }
    public set webAddress(value: string) {
        this.setField<string>(WebClientRoleEntityFields.webAddressField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ISetting> | null> {
        return this.getFieldObjectRelationAsync<Setting, ISetting>(Setting, 'Settings', 'Id', false, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

