import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, ConnectableObservable } from 'rxjs';
import { map, publishReplay, switchMap, takeUntil } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { Priority, PriorityApi } from '../../models/priority';
import { McClient } from '../mc-client.service';

@Injectable()
export class PriorityService implements OnDestroy {
    private _priorities$: Observable<Priority[]>;
    private _refresh$ = new Subject();
    private _stopObs$ = new Subject();

    constructor(private _mcClient: McClient) {
        const obs = this._refresh$.pipe(
            switchMap(() => this.fetchPriorities()),
            takeUntil(this._stopObs$),
            publishReplay(1)
        ) as ConnectableObservable<Priority[]>;
        obs.connect();

        this._priorities$ = obs;
        this._refresh$.next();
    }

    public ngOnDestroy(): void {
        this._stopObs$.next();
    }

    public getPriorities$(): Observable<Priority[]> {
        return this._priorities$;
    }

    public refresh(): void {
        this._refresh$.next();
    }

    public getPriority(id: IGuid): Observable<Priority | undefined> {
        return this._priorities$.pipe(
            map((priorities) => {
                return priorities.find((x) => x.id.equals(id));
            })
        );
    }

    private fetchPriorities(): Observable<Priority[]> {
        return this._mcClient.getAll<PriorityApi>('v2/priorities').pipe(map((priorities) => priorities.map(Priority.assign)));
    }
}
