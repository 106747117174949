<div class="filter-content">
    <ul>
        <li>
            <!-- Specific areas-->
            <div
                class="toggle-button h-100"
                [style.cursor]="'pointer'"
                (click)="onLogicalClicked()"
                appClickActionOnSpaceEnter
                [style.background-color]="mode === whereMode.Logical ? 'rgba(61, 193, 243, 0.14902)' : 'transparent'"
            >
                <div class="gen-button-group">
                    <gen-item class="w-100">
                        <gen-text class="ml-1 mr-1">
                            <span>{{ 'STE_LABEL_WHERE_FILTER_SPECIFIC' | translate }}</span>
                        </gen-text>
                        <i slot="gen-content-right" class="gen-ico-angle-right right-caret"></i>
                    </gen-item>
                </div>
            </div>
        </li>
        <li>
            <!-- Anywhere -->
            <div
                class="toggle-button h-100"
                [style.cursor]="'pointer'"
                (click)="onAnywhereClicked()"
                appClickActionOnSpaceEnter
                [style.background-color]="mode === whereMode.Anywhere ? 'rgba(61, 193, 243, 0.14902)' : 'transparent'"
            >
                <div class="gen-button-group">
                    <gen-item class="w-100">
                        <gen-text class="ml-1 mr-1">
                            <span>{{ 'STE_LABEL_ANYWHERE' | translate }}</span>
                        </gen-text>
                    </gen-item>
                </div>
            </div>
        </li>
    </ul>
    <ul>
        <li>
            <!--Specific areas entity browser-->
            <div [hidden]="mode !== whereMode.Logical">
                <app-entity-browser
                    #whereEntityBrowser
                    class="entity-browser-size"
                    (checkedEntitiesChanged)="onCheckedEntitiesChanged($event)"
                    [autoRefresh]="false"
                    [areItemsCheckable]="true"
                >
                </app-entity-browser>
            </div>
        </li>
    </ul>
</div>
