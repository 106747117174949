// Autogenerated: v2/help/Entities/ApiPartition?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-26 2:56:10 PM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../Interface/IEntity';
import { IPartitionEntity, PartitionEntityFields } from '../Interface/IPartitionEntity';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ITransaction } from '../Interface/ITransaction';
import { Privilege } from './PrivilegedEntity';
import { IPrivilege } from '../Interface/IPrivilegedEntity';

export class PartitionEntity extends Entity implements IPartitionEntity {
    // #region REST Properties

    // CanBeShared
    public get canBeShared(): boolean {
        return this.getField<boolean>(PartitionEntityFields.canBeSharedField);
    }
    public set canBeShared(value: boolean) {
        this.setField<boolean>(PartitionEntityFields.canBeSharedField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Partitions);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAdministratorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Administrators', 'Id', true, query, ValidFlags.Membership2, transaction);
    }

    public async getChildPartitionsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('ChildPartitions', 'Id', true, query, ValidFlags.Membership3, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.Membership, transaction);
    }

    public async getUserPrivilegesAsync(id: IGuid, transaction?: ITransaction | null): Promise<ObservableCollection<IPrivilege> | null> {
        return this.getFieldObjectRelationAsync<Privilege, IPrivilege>(Privilege, 'UserPrivileges/' + id, 'Id', false, true, ValidFlags.Membership2, transaction);
    }

    public async getUsersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Users', 'Id', true, query, ValidFlags.Membership2, transaction);
    }

    // #endregion REST Methods

}

