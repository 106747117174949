<div class="filter-container" [attr.data-cy]="'filter_' + filterType">
    <div
        [id]="filterDescriptor.filterId"
        class="filter-box"
        [class.filter-open]="popup.open"
        (click)="toggleFilter()"
        genKeyboardNavigation
        appClickActionOnSpaceEnter
        tabindex="0"
    >
        <gen-text class="filter-name" *ngLet="filterDescriptor.label | translate as label" [appTooltip]="label" appTooltipOnlyIfOverflow [attr.data-cy]="label">
            {{ label }}
        </gen-text>
        <gen-item>
            <gen-icon
                [icon]="state.status | lowercase"
                [size]="IconSize.Small"
                [appTooltip]="state.message"
                [ngClass]="{ 'fg-color-warning': state.status === 'Warning', 'fg-color-error': state.status === 'Error' }"
                [tabindex]="state.status === 'Warning' ? 0 : -1"
                class="status-icon"
            ></gen-icon>
            <gen-text
                [slot]="ItemSlot.TextSecondary"
                class="status-text"
                [appTooltip]="state.selection"
                appTooltipOnlyIfOverflow
                [style.margin-left]="state.status === 'Ok' ? '-8px' : '-4px'"
            >
                {{ state.status !== 'Error' ? state.selection : ('STE_LABEL_INVALIDFILTER' | translate) }}
            </gen-text>
        </gen-item>
    </div>

    <gen-popup [staysOpen]="staysOpen" [position]="popupPosition" [targetSelector]="'#' + filterDescriptor.filterId" (genEscape)="toggleFilter()">
        <ng-content></ng-content>
    </gen-popup>
</div>
