// Autogenerated: v2/help/enumerations/ApiAlarmStates?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class AlarmStates {
    public static None = 'None';
    public static Active = 'Active';
    public static Acked = 'Acked';
    public static SourceConditionInvestigating = 'SourceConditionInvestigating';
    public static AcknowledgeRequired = 'AcknowledgeRequired';

    public static readonly values = [
        AlarmStates.None,
        AlarmStates.Active,
        AlarmStates.Acked,
        AlarmStates.SourceConditionInvestigating,
        AlarmStates.AcknowledgeRequired
    ];
}
