import { MeltedIcon } from '@genetec/gelato-angular';
import { IGuid, SafeGuid } from 'safeguid';
import { TreeItem } from '@shared/interfaces/tree-item/tree-item';

export interface TreeViewDefinition {
    id: string;
    label: string;
    icon: string;
    children: TreeViewDefinition[];
}

export class TreeViewItem {
    public uniqueId: string;
    public id: string;
    public label: string;
    public icon: string;
    public children: TreeViewItem[];

    public get hasChildren(): boolean {
        return this.children.length > 0;
    }

    constructor(uniqueId: IGuid, id: string, label: string, children: TreeViewItem[], icon: string) {
        this.uniqueId = uniqueId.toString();
        this.id = id;
        this.label = label;
        this.icon = icon;
        this.children = children;
    }
}

export class TreeView {
    private treeViewItems: TreeViewItem[];

    constructor(treeViewItems: TreeViewDefinition[]) {
        this.treeViewItems = this.mapITreeViewItemsToTreeViewItems(treeViewItems);
    }

    public getSelectedItemsRealIds(genTreeItems: TreeItem[]): string[] {
        return this.getTreeItemsIdsByUniqueIds(genTreeItems);
    }

    public generateTreeItems(): TreeItem[] {
        return this.mapTreeViewItemsToTreeItems(this.treeViewItems);
    }

    private mapITreeViewItemsToTreeViewItems(treeViewItems: TreeViewDefinition[]): TreeViewItem[] {
        return treeViewItems.map(
            (treeViewItem) =>
                new TreeViewItem(SafeGuid.newGuid(), treeViewItem.id, treeViewItem.label, this.mapITreeViewItemsToTreeViewItems(treeViewItem.children), treeViewItem.icon)
        );
    }

    private mapTreeViewItemsToTreeItems(treeViewItems: TreeViewItem[]): TreeItem[] {
        // If only one root, display as flat
        const singleRoot = treeViewItems.length === 1;

        return (singleRoot ? treeViewItems[0].children : treeViewItems).map((treeViewItem) => {
            return {
                id: treeViewItem.uniqueId,
                text: treeViewItem.label,
                children: treeViewItem.hasChildren ? this.mapTreeViewItemsToTreeItems(treeViewItem.children) : undefined,
                icon: treeViewItem.icon as MeltedIcon,
                isChecked: false,
            };
        });
    }

    private getTreeViewLeaves(treeViewItems: TreeViewItem[]): TreeViewItem[] {
        const leaves: TreeViewItem[] = [];

        treeViewItems.forEach((treeViewItem) => {
            if (!treeViewItem.hasChildren) leaves.push(treeViewItem);
            else leaves.push(...this.getTreeViewLeaves(treeViewItem.children));
        });

        return leaves;
    }

    private getTreeItemsIdsByUniqueIds(genTreeItems: TreeItem[]): string[] {
        const treeViewLeaves = this.getTreeViewLeaves(this.treeViewItems);
        return treeViewLeaves.filter((leaf) => genTreeItems.find((x) => x.id === leaf.uniqueId)).map((x) => x.id);
    }
}
