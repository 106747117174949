// Autogenerated: v2/help/Entities/ApiCamera?codegen=ts&InterfaceOnly=False&workflow=False on 2022-07-06 9:10:41 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { LocatableEntity } from '../LocatableEntity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { FieldObject } from '../../../Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { ICameraEntity, IVideoAttribute, IStreamUsageInfo, IRecordingMode, IPtzCapabilitiesInfo, IThumbnailData, IThumbnailRequest, IFeatureCapability, IEncryptionCertificate, IControlPtz, IBlockInfo, IPtzInfo, CameraEntityFields, IMotionDetectionZone, IMotionDetection, IProtectVideo, IUserInfo, ILensInfo, ILensConfiguration, ILensType, IVector } from '../../Interface/ICameraEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { IFieldObject } from '../../Interface/IFieldObject';
import { FieldObjectWithRelations } from '../../../Helpers/FieldObjectWithRelations';

// #region Inner classes

export class VideoAttribute extends FieldObject implements IVideoAttribute {
    // ScheduleId
    public get scheduleId(): IGuid {
        return this.getFieldGuid('ScheduleId');
    }
    public set scheduleId(value: IGuid) {
        this.setFieldGuid('ScheduleId', value);
    }
    // Brightness
    public get brightness(): number | null {
        return this.getNullableField<number>('Brightness');
    }
    public set brightness(value: number | null) {
        this.setNullableField<number>('Brightness', value);
    }
    // Contrast
    public get contrast(): number | null {
        return this.getNullableField<number>('Contrast');
    }
    public set contrast(value: number | null) {
        this.setNullableField<number>('Contrast', value);
    }
    // Hue
    public get hue(): number | null {
        return this.getNullableField<number>('Hue');
    }
    public set hue(value: number | null) {
        this.setNullableField<number>('Hue', value);
    }
    // Saturation
    public get saturation(): number | null {
        return this.getNullableField<number>('Saturation');
    }
    public set saturation(value: number | null) {
        this.setNullableField<number>('Saturation', value);
    }
    // Sharpness
    public get sharpness(): number | null {
        return this.getNullableField<number>('Sharpness');
    }
    public set sharpness(value: number | null) {
        this.setNullableField<number>('Sharpness', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('ScheduleId', SafeGuid.EMPTY);
        this.setField<number>('Brightness', 0);
        this.setField<number>('Contrast', 0);
        this.setField<number>('Hue', 0);
        this.setField<number>('Saturation', 0);
        this.setField<number>('Sharpness', 0);
    }
}

export class StreamUsageInfo extends FieldObject implements IStreamUsageInfo {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // StreamUsage
    public get streamUsage(): string {
        return this.getField<string>('StreamUsage');
    }
    public set streamUsage(value: string) {
        this.setField<string>('StreamUsage', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('StreamUsage', '');
    }
}

export class RecordingMode extends FieldObject implements IRecordingMode {
    // Id
    public get id(): number {
        return this.getField<number>('Id');
    }
    public set id(value: number) {
        this.setField<number>('Id', value);
    }
    // ScheduleId
    public get scheduleId(): IGuid {
        return this.getFieldGuid('ScheduleId');
    }
    public set scheduleId(value: IGuid) {
        this.setFieldGuid('ScheduleId', value);
    }
    // Mode
    public get mode(): string {
        return this.getField<string>('Mode');
    }
    public set mode(value: string) {
        this.setField<string>('Mode', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('Id', 0);
        this.setFieldGuid('ScheduleId', SafeGuid.EMPTY);
        this.setField<string>('Mode', '');
    }
}

export class PtzCapabilitiesInfo extends FieldObject implements IPtzCapabilitiesInfo {
    // ProtocolChangeSupported
    public get protocolChangeSupported(): boolean {
        return this.getField<boolean>('ProtocolChangeSupported');
    }
    public set protocolChangeSupported(value: boolean) {
        this.setField<boolean>('ProtocolChangeSupported', value);
    }
    // PtzControlEnabled
    public get ptzControlEnabled(): boolean {
        return this.getField<boolean>('PtzControlEnabled');
    }
    public set ptzControlEnabled(value: boolean) {
        this.setField<boolean>('PtzControlEnabled', value);
    }
    // PtzNumberChangeSupported
    public get ptzNumberChangeSupported(): boolean {
        return this.getField<boolean>('PtzNumberChangeSupported');
    }
    public set ptzNumberChangeSupported(value: boolean) {
        this.setField<boolean>('PtzNumberChangeSupported', value);
    }
    // PtzSpeedControlSupported
    public get ptzSpeedControlSupported(): boolean {
        return this.getField<boolean>('PtzSpeedControlSupported');
    }
    public set ptzSpeedControlSupported(value: boolean) {
        this.setField<boolean>('PtzSpeedControlSupported', value);
    }
    // CameraLinkChangeSupported
    public get cameraLinkChangeSupported(): boolean {
        return this.getField<boolean>('CameraLinkChangeSupported');
    }
    public set cameraLinkChangeSupported(value: boolean) {
        this.setField<boolean>('CameraLinkChangeSupported', value);
    }
    // DeletionSupported
    public get deletionSupported(): boolean {
        return this.getField<boolean>('DeletionSupported');
    }
    public set deletionSupported(value: boolean) {
        this.setField<boolean>('DeletionSupported', value);
    }
    // IdleDelayChangeSupported
    public get idleDelayChangeSupported(): boolean {
        return this.getField<boolean>('IdleDelayChangeSupported');
    }
    public set idleDelayChangeSupported(value: boolean) {
        this.setField<boolean>('IdleDelayChangeSupported', value);
    }
    // LockDelayChangeSupported
    public get lockDelayChangeSupported(): boolean {
        return this.getField<boolean>('LockDelayChangeSupported');
    }
    public set lockDelayChangeSupported(value: boolean) {
        this.setField<boolean>('LockDelayChangeSupported', value);
    }
    // SupportedCommands
    public get supportedCommands(): string {
        return this.getField<string>('SupportedCommands');
    }
    public set supportedCommands(value: string) {
        this.setField<string>('SupportedCommands', value);
    }
    // SecondaryIOPortRequired
    public get secondaryIOPortRequired(): boolean {
        return this.getField<boolean>('SecondaryIOPortRequired');
    }
    public set secondaryIOPortRequired(value: boolean) {
        this.setField<boolean>('SecondaryIOPortRequired', value);
    }
    // RemoteDataRequired
    public get remoteDataRequired(): boolean {
        return this.getField<boolean>('RemoteDataRequired');
    }
    public set remoteDataRequired(value: boolean) {
        this.setField<boolean>('RemoteDataRequired', value);
    }
    // NumberOfPresets
    public get numberOfPresets(): number {
        return this.getField<number>('NumberOfPresets');
    }
    public set numberOfPresets(value: number) {
        this.setField<number>('NumberOfPresets', value);
    }
    // NumberOfPattern
    public get numberOfPattern(): number {
        return this.getField<number>('NumberOfPattern');
    }
    public set numberOfPattern(value: number) {
        this.setField<number>('NumberOfPattern', value);
    }
    // NumberOfPresetTours
    public get numberOfPresetTours(): number {
        return this.getField<number>('NumberOfPresetTours');
    }
    public set numberOfPresetTours(value: number) {
        this.setField<number>('NumberOfPresetTours', value);
    }
    // NumberOfAux
    public get numberOfAux(): number {
        return this.getField<number>('NumberOfAux');
    }
    public set numberOfAux(value: number) {
        this.setField<number>('NumberOfAux', value);
    }
    // MinimumCameraNumber
    public get minimumCameraNumber(): number {
        return this.getField<number>('MinimumCameraNumber');
    }
    public set minimumCameraNumber(value: number) {
        this.setField<number>('MinimumCameraNumber', value);
    }
    // MaximumCameraNumber
    public get maximumCameraNumber(): number {
        return this.getField<number>('MaximumCameraNumber');
    }
    public set maximumCameraNumber(value: number) {
        this.setField<number>('MaximumCameraNumber', value);
    }
    // PresetBase
    public get presetBase(): number {
        return this.getField<number>('PresetBase');
    }
    public set presetBase(value: number) {
        this.setField<number>('PresetBase', value);
    }
    // PatternBase
    public get patternBase(): number {
        return this.getField<number>('PatternBase');
    }
    public set patternBase(value: number) {
        this.setField<number>('PatternBase', value);
    }
    // AuxBase
    public get auxBase(): number {
        return this.getField<number>('AuxBase');
    }
    public set auxBase(value: number) {
        this.setField<number>('AuxBase', value);
    }
    // PresetTourBase
    public get presetTourBase(): number {
        return this.getField<number>('PresetTourBase');
    }
    public set presetTourBase(value: number) {
        this.setField<number>('PresetTourBase', value);
    }
    // ProductCode
    public get productCode(): number {
        return this.getField<number>('ProductCode');
    }
    public set productCode(value: number) {
        this.setField<number>('ProductCode', value);
    }
    // MaximumZoomFactor
    public get maximumZoomFactor(): number {
        return this.getField<number>('MaximumZoomFactor');
    }
    public set maximumZoomFactor(value: number) {
        this.setField<number>('MaximumZoomFactor', value);
    }
    // MinimumPan
    public get minimumPan(): number {
        return this.getField<number>('MinimumPan');
    }
    public set minimumPan(value: number) {
        this.setField<number>('MinimumPan', value);
    }
    // MaximumPan
    public get maximumPan(): number {
        return this.getField<number>('MaximumPan');
    }
    public set maximumPan(value: number) {
        this.setField<number>('MaximumPan', value);
    }
    // MinimumTilt
    public get minimumTilt(): number {
        return this.getField<number>('MinimumTilt');
    }
    public set minimumTilt(value: number) {
        this.setField<number>('MinimumTilt', value);
    }
    // MaximumTilt
    public get maximumTilt(): number {
        return this.getField<number>('MaximumTilt');
    }
    public set maximumTilt(value: number) {
        this.setField<number>('MaximumTilt', value);
    }
    // SphericalSpaceMinimumPan
    public get sphericalSpaceMinimumPan(): number | null {
        return this.getNullableField<number>('SphericalSpaceMinimumPan');
    }
    public set sphericalSpaceMinimumPan(value: number | null) {
        this.setNullableField<number>('SphericalSpaceMinimumPan', value);
    }
    // SphericalSpaceMaximumPan
    public get sphericalSpaceMaximumPan(): number | null {
        return this.getNullableField<number>('SphericalSpaceMaximumPan');
    }
    public set sphericalSpaceMaximumPan(value: number | null) {
        this.setNullableField<number>('SphericalSpaceMaximumPan', value);
    }
    // SphericalSpaceMinimumTilt
    public get sphericalSpaceMinimumTilt(): number | null {
        return this.getNullableField<number>('SphericalSpaceMinimumTilt');
    }
    public set sphericalSpaceMinimumTilt(value: number | null) {
        this.setNullableField<number>('SphericalSpaceMinimumTilt', value);
    }
    // SphericalSpaceMaximumTilt
    public get sphericalSpaceMaximumTilt(): number | null {
        return this.getNullableField<number>('SphericalSpaceMaximumTilt');
    }
    public set sphericalSpaceMaximumTilt(value: number | null) {
        this.setNullableField<number>('SphericalSpaceMaximumTilt', value);
    }
    // SphericalSpacePanLeftDeviceLimit
    public get sphericalSpacePanLeftDeviceLimit(): number | null {
        return this.getNullableField<number>('SphericalSpacePanLeftDeviceLimit');
    }
    public set sphericalSpacePanLeftDeviceLimit(value: number | null) {
        this.setNullableField<number>('SphericalSpacePanLeftDeviceLimit', value);
    }
    // SphericalSpacePanRightDeviceLimit
    public get sphericalSpacePanRightDeviceLimit(): number | null {
        return this.getNullableField<number>('SphericalSpacePanRightDeviceLimit');
    }
    public set sphericalSpacePanRightDeviceLimit(value: number | null) {
        this.setNullableField<number>('SphericalSpacePanRightDeviceLimit', value);
    }
    // SphericalSpaceTiltUpDeviceLimit
    public get sphericalSpaceTiltUpDeviceLimit(): number | null {
        return this.getNullableField<number>('SphericalSpaceTiltUpDeviceLimit');
    }
    public set sphericalSpaceTiltUpDeviceLimit(value: number | null) {
        this.setNullableField<number>('SphericalSpaceTiltUpDeviceLimit', value);
    }
    // SphericalSpaceTiltDownDeviceLimit
    public get sphericalSpaceTiltDownDeviceLimit(): number | null {
        return this.getNullableField<number>('SphericalSpaceTiltDownDeviceLimit');
    }
    public set sphericalSpaceTiltDownDeviceLimit(value: number | null) {
        this.setNullableField<number>('SphericalSpaceTiltDownDeviceLimit', value);
    }
    // ZoomFactorSpaceMinimum
    public get zoomFactorSpaceMinimum(): number | null {
        return this.getNullableField<number>('ZoomFactorSpaceMinimum');
    }
    public set zoomFactorSpaceMinimum(value: number | null) {
        this.setNullableField<number>('ZoomFactorSpaceMinimum', value);
    }
    // ZoomFactorSpaceMaximum
    public get zoomFactorSpaceMaximum(): number | null {
        return this.getNullableField<number>('ZoomFactorSpaceMaximum');
    }
    public set zoomFactorSpaceMaximum(value: number | null) {
        this.setNullableField<number>('ZoomFactorSpaceMaximum', value);
    }
    // MinimumHorizontalFieldOfView
    public get minimumHorizontalFieldOfView(): number | null {
        return this.getNullableField<number>('MinimumHorizontalFieldOfView');
    }
    public set minimumHorizontalFieldOfView(value: number | null) {
        this.setNullableField<number>('MinimumHorizontalFieldOfView', value);
    }
    // MaximumHorizontalFieldOfView
    public get maximumHorizontalFieldOfView(): number | null {
        return this.getNullableField<number>('MaximumHorizontalFieldOfView');
    }
    public set maximumHorizontalFieldOfView(value: number | null) {
        this.setNullableField<number>('MaximumHorizontalFieldOfView', value);
    }
    // MinimumVerticalFieldOfView
    public get minimumVerticalFieldOfView(): number | null {
        return this.getNullableField<number>('MinimumVerticalFieldOfView');
    }
    public set minimumVerticalFieldOfView(value: number | null) {
        this.setNullableField<number>('MinimumVerticalFieldOfView', value);
    }
    // MaximumVerticalFieldOfView
    public get maximumVerticalFieldOfView(): number | null {
        return this.getNullableField<number>('MaximumVerticalFieldOfView');
    }
    public set maximumVerticalFieldOfView(value: number | null) {
        this.setNullableField<number>('MaximumVerticalFieldOfView', value);
    }
    // MinimumFocalLength
    public get minimumFocalLength(): number | null {
        return this.getNullableField<number>('MinimumFocalLength');
    }
    public set minimumFocalLength(value: number | null) {
        this.setNullableField<number>('MinimumFocalLength', value);
    }
    // MaximumFocalLength
    public get maximumFocalLength(): number | null {
        return this.getNullableField<number>('MaximumFocalLength');
    }
    public set maximumFocalLength(value: number | null) {
        this.setNullableField<number>('MaximumFocalLength', value);
    }
    // UnknownSpaceMinimumPan
    public get unknownSpaceMinimumPan(): number | null {
        return this.getNullableField<number>('UnknownSpaceMinimumPan');
    }
    public set unknownSpaceMinimumPan(value: number | null) {
        this.setNullableField<number>('UnknownSpaceMinimumPan', value);
    }
    // UnknownSpaceMaximumPan
    public get unknownSpaceMaximumPan(): number | null {
        return this.getNullableField<number>('UnknownSpaceMaximumPan');
    }
    public set unknownSpaceMaximumPan(value: number | null) {
        this.setNullableField<number>('UnknownSpaceMaximumPan', value);
    }
    // UnknownSpaceMinimumTilt
    public get unknownSpaceMinimumTilt(): number | null {
        return this.getNullableField<number>('UnknownSpaceMinimumTilt');
    }
    public set unknownSpaceMinimumTilt(value: number | null) {
        this.setNullableField<number>('UnknownSpaceMinimumTilt', value);
    }
    // UnknownSpaceMaximumTilt
    public get unknownSpaceMaximumTilt(): number | null {
        return this.getNullableField<number>('UnknownSpaceMaximumTilt');
    }
    public set unknownSpaceMaximumTilt(value: number | null) {
        this.setNullableField<number>('UnknownSpaceMaximumTilt', value);
    }
    // UnknownSpaceMinimumZoom
    public get unknownSpaceMinimumZoom(): number | null {
        return this.getNullableField<number>('UnknownSpaceMinimumZoom');
    }
    public set unknownSpaceMinimumZoom(value: number | null) {
        this.setNullableField<number>('UnknownSpaceMinimumZoom', value);
    }
    // UnknownSpaceMaximumZoom
    public get unknownSpaceMaximumZoom(): number | null {
        return this.getNullableField<number>('UnknownSpaceMaximumZoom');
    }
    public set unknownSpaceMaximumZoom(value: number | null) {
        this.setNullableField<number>('UnknownSpaceMaximumZoom', value);
    }

    public initializeAllFields(): void {
        this.setField<boolean>('ProtocolChangeSupported', false);
        this.setField<boolean>('PtzControlEnabled', false);
        this.setField<boolean>('PtzNumberChangeSupported', false);
        this.setField<boolean>('PtzSpeedControlSupported', false);
        this.setField<boolean>('CameraLinkChangeSupported', false);
        this.setField<boolean>('DeletionSupported', false);
        this.setField<boolean>('IdleDelayChangeSupported', false);
        this.setField<boolean>('LockDelayChangeSupported', false);
        this.setField<string>('SupportedCommands', '');
        this.setField<boolean>('SecondaryIOPortRequired', false);
        this.setField<boolean>('RemoteDataRequired', false);
        this.setField<number>('NumberOfPresets', 0);
        this.setField<number>('NumberOfPattern', 0);
        this.setField<number>('NumberOfPresetTours', 0);
        this.setField<number>('NumberOfAux', 0);
        this.setField<number>('MinimumCameraNumber', 0);
        this.setField<number>('MaximumCameraNumber', 0);
        this.setField<number>('PresetBase', 0);
        this.setField<number>('PatternBase', 0);
        this.setField<number>('AuxBase', 0);
        this.setField<number>('PresetTourBase', 0);
        this.setField<number>('ProductCode', 0);
        this.setField<number>('MaximumZoomFactor', 0);
        this.setField<number>('MinimumPan', 0);
        this.setField<number>('MaximumPan', 0);
        this.setField<number>('MinimumTilt', 0);
        this.setField<number>('MaximumTilt', 0);
        this.setField<number>('SphericalSpaceMinimumPan', 0);
        this.setField<number>('SphericalSpaceMaximumPan', 0);
        this.setField<number>('SphericalSpaceMinimumTilt', 0);
        this.setField<number>('SphericalSpaceMaximumTilt', 0);
        this.setField<number>('SphericalSpacePanLeftDeviceLimit', 0);
        this.setField<number>('SphericalSpacePanRightDeviceLimit', 0);
        this.setField<number>('SphericalSpaceTiltUpDeviceLimit', 0);
        this.setField<number>('SphericalSpaceTiltDownDeviceLimit', 0);
        this.setField<number>('ZoomFactorSpaceMinimum', 0);
        this.setField<number>('ZoomFactorSpaceMaximum', 0);
        this.setField<number>('MinimumHorizontalFieldOfView', 0);
        this.setField<number>('MaximumHorizontalFieldOfView', 0);
        this.setField<number>('MinimumVerticalFieldOfView', 0);
        this.setField<number>('MaximumVerticalFieldOfView', 0);
        this.setField<number>('MinimumFocalLength', 0);
        this.setField<number>('MaximumFocalLength', 0);
        this.setField<number>('UnknownSpaceMinimumPan', 0);
        this.setField<number>('UnknownSpaceMaximumPan', 0);
        this.setField<number>('UnknownSpaceMinimumTilt', 0);
        this.setField<number>('UnknownSpaceMaximumTilt', 0);
        this.setField<number>('UnknownSpaceMinimumZoom', 0);
        this.setField<number>('UnknownSpaceMaximumZoom', 0);
    }
}

export class ProtectVideo extends FieldObject implements IProtectVideo {
    // Start
    public get start(): Date {
        return this.getFieldDate('Start');
    }
    public set start(value: Date) {
        this.setFieldDate('Start', value);
    }
    // Stop
    public get stop(): Date {
        return this.getFieldDate('Stop');
    }
    public set stop(value: Date) {
        this.setFieldDate('Stop', value);
    }
    // Duration
    public get duration(): number | null {
        return this.getNullableField<number>('Duration');
    }
    public set duration(value: number | null) {
        this.setNullableField<number>('Duration', value);
    }
    // Protect
    public get protect(): boolean {
        return this.getField<boolean>('Protect');
    }
    public set protect(value: boolean) {
        this.setField<boolean>('Protect', value);
    }

    public initializeAllFields(): void {
        this.setFieldDate('Start', new Date());
        this.setFieldDate('Stop', new Date());
        this.setField<number>('Duration', 0);
        this.setField<boolean>('Protect', false);
    }
}

export class MotionDetectionZone extends FieldObject implements IMotionDetectionZone {
    // Id
    public get id(): number {
        return this.getField<number>('Id');
    }
    public set id(value: number) {
        this.setField<number>('Id', value);
    }
    // MacroBlockHeight
    public get macroBlockHeight(): number | null {
        return this.getNullableField<number>('MacroBlockHeight');
    }
    public set macroBlockHeight(value: number | null) {
        this.setNullableField<number>('MacroBlockHeight', value);
    }
    // MacroBlockWidth
    public get macroBlockWidth(): number | null {
        return this.getNullableField<number>('MacroBlockWidth');
    }
    public set macroBlockWidth(value: number | null) {
        this.setNullableField<number>('MacroBlockWidth', value);
    }
    // MapHeight
    public get mapHeight(): number | null {
        return this.getNullableField<number>('MapHeight');
    }
    public set mapHeight(value: number | null) {
        this.setNullableField<number>('MapHeight', value);
    }
    // MapWidth
    public get mapWidth(): number | null {
        return this.getNullableField<number>('MapWidth');
    }
    public set mapWidth(value: number | null) {
        this.setNullableField<number>('MapWidth', value);
    }
    // MotionOffEvent
    public get motionOffEvent(): number | null {
        return this.getNullableField<number>('MotionOffEvent');
    }
    public set motionOffEvent(value: number | null) {
        this.setNullableField<number>('MotionOffEvent', value);
    }
    // MotionOffThreshold
    public get motionOffThreshold(): number | null {
        return this.getNullableField<number>('MotionOffThreshold');
    }
    public set motionOffThreshold(value: number | null) {
        this.setNullableField<number>('MotionOffThreshold', value);
    }
    // MotionOnEvent
    public get motionOnEvent(): number | null {
        return this.getNullableField<number>('MotionOnEvent');
    }
    public set motionOnEvent(value: number | null) {
        this.setNullableField<number>('MotionOnEvent', value);
    }
    // MotionOnThreshold
    public get motionOnThreshold(): number | null {
        return this.getNullableField<number>('MotionOnThreshold');
    }
    public set motionOnThreshold(value: number | null) {
        this.setNullableField<number>('MotionOnThreshold', value);
    }
    // Sensitivity
    public get sensitivity(): number | null {
        return this.getNullableField<number>('Sensitivity');
    }
    public set sensitivity(value: number | null) {
        this.setNullableField<number>('Sensitivity', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Id', 0);
        this.setField<number>('MacroBlockHeight', 0);
        this.setField<number>('MacroBlockWidth', 0);
        this.setField<number>('MapHeight', 0);
        this.setField<number>('MapWidth', 0);
        this.setField<number>('MotionOffEvent', 0);
        this.setField<number>('MotionOffThreshold', 0);
        this.setField<number>('MotionOnEvent', 0);
        this.setField<number>('MotionOnThreshold', 0);
        this.setField<number>('Sensitivity', 0);
    }
}

export class MotionDetection extends FieldObjectWithRelations implements IMotionDetection {
    // ScheduleId
    public get scheduleId(): IGuid {
        return this.getFieldGuid('ScheduleId');
    }
    public set scheduleId(value: IGuid) {
        this.setFieldGuid('ScheduleId', value);
    }
    // Enabled
    public get enabled(): boolean | null {
        return this.getNullableField<boolean>('Enabled');
    }
    public set enabled(value: boolean | null) {
        this.setNullableField<boolean>('Enabled', value);
    }
    // CameraApplicationHardwareDetection
    public get cameraApplicationHardwareDetection(): string {
        return this.getField<string>('CameraApplicationHardwareDetection');
    }
    public set cameraApplicationHardwareDetection(value: string) {
        this.setField<string>('CameraApplicationHardwareDetection', value);
    }
    // ConsecutiveFrame
    public get consecutiveFrame(): boolean | null {
        return this.getNullableField<boolean>('ConsecutiveFrame');
    }
    public set consecutiveFrame(value: boolean | null) {
        this.setNullableField<boolean>('ConsecutiveFrame', value);
    }
    // HardwareDetection
    public get hardwareDetection(): boolean | null {
        return this.getNullableField<boolean>('HardwareDetection');
    }
    public set hardwareDetection(value: boolean | null) {
        this.setNullableField<boolean>('HardwareDetection', value);
    }
    // IsH264
    public get isH264(): boolean | null {
        return this.getNullableField<boolean>('IsH264');
    }
    public set isH264(value: boolean | null) {
        this.setNullableField<boolean>('IsH264', value);
    }
    // H264ChromaWeight
    public get h264ChromaWeight(): number | null {
        return this.getNullableField<number>('H264ChromaWeight');
    }
    public set h264ChromaWeight(value: number | null) {
        this.setNullableField<number>('H264ChromaWeight', value);
    }
    // H264LumaWeight
    public get h264LumaWeight(): number | null {
        return this.getNullableField<number>('H264LumaWeight');
    }
    public set h264LumaWeight(value: number | null) {
        this.setNullableField<number>('H264LumaWeight', value);
    }
    // H264MacroBlockWeight
    public get h264MacroBlockWeight(): number | null {
        return this.getNullableField<number>('H264MacroBlockWeight');
    }
    public set h264MacroBlockWeight(value: number | null) {
        this.setNullableField<number>('H264MacroBlockWeight', value);
    }
    // H264VectorWeight
    public get h264VectorWeight(): number | null {
        return this.getNullableField<number>('H264VectorWeight');
    }
    public set h264VectorWeight(value: number | null) {
        this.setNullableField<number>('H264VectorWeight', value);
    }
    // MotionDetectorMode
    public get motionDetectorMode(): string {
        return this.getField<string>('MotionDetectorMode');
    }
    public set motionDetectorMode(value: string) {
        this.setField<string>('MotionDetectorMode', value);
    }
    // Sensitivity
    public get sensitivity(): number | null {
        return this.getNullableField<number>('Sensitivity');
    }
    public set sensitivity(value: number | null) {
        this.setNullableField<number>('Sensitivity', value);
    }

    public async getZonesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMotionDetectionZone> | null> {
        return this.getFieldObjectRelationAsync<MotionDetectionZone, IMotionDetectionZone>(MotionDetectionZone, 'MotionDetections/' + this.scheduleId + '/Zones', 'Id', false, true, null, transaction);
    }


    public initializeAllFields(): void {
        this.setFieldGuid('ScheduleId', SafeGuid.EMPTY);
        this.setField<boolean>('Enabled', false);
        this.setField<string>('CameraApplicationHardwareDetection', '');
        this.setField<boolean>('ConsecutiveFrame', false);
        this.setField<boolean>('HardwareDetection', false);
        this.setField<boolean>('IsH264', false);
        this.setField<number>('H264ChromaWeight', 0);
        this.setField<number>('H264LumaWeight', 0);
        this.setField<number>('H264MacroBlockWeight', 0);
        this.setField<number>('H264VectorWeight', 0);
        this.setField<string>('MotionDetectorMode', '');
        this.setField<number>('Sensitivity', 0);
    }
}

export class LensType extends FieldObject implements ILensType {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Manufacturer
    public get manufacturer(): string {
        return this.getField<string>('Manufacturer');
    }
    public set manufacturer(value: string) {
        this.setField<string>('Manufacturer', value);
    }
    // Data
    public get data(): string {
        return this.getField<string>('Data');
    }
    public set data(value: string) {
        this.setField<string>('Data', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Manufacturer', '');
        this.setField<string>('Data', '');
    }
}

export class Vector extends FieldObject implements IVector {
    // Y
    public get y(): number {
        return this.getField<number>('Y');
    }
    public set y(value: number) {
        this.setField<number>('Y', value);
    }
    // X
    public get x(): number {
        return this.getField<number>('X');
    }
    public set x(value: number) {
        this.setField<number>('X', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Y', 0);
        this.setField<number>('X', 0);
    }
}

export class LensConfiguration extends FieldObject implements ILensConfiguration {
    // LensProperties
    public get lensProperties(): string {
        return this.getField<string>('LensProperties');
    }
    public set lensProperties(value: string) {
        this.setField<string>('LensProperties', value);
    }
    // IsHardwareDewarpingSupported
    public get isHardwareDewarpingSupported(): boolean {
        return this.getField<boolean>('IsHardwareDewarpingSupported');
    }
    public set isHardwareDewarpingSupported(value: boolean) {
        this.setField<boolean>('IsHardwareDewarpingSupported', value);
    }
    // Vector
    public get vector(): IVector {
        return this.getFieldObject<Vector, IVector>(Vector, 'Vector');
    }
    public set vector(value: IVector) {
        this.setFieldObject<IVector>('Vector', value);
    }
    // ViewingModes
    public get viewingModes(): ObservableCollection<string> {
        return this.getFieldArray<string>('ViewingModes');
    }
    public set viewingModes(value: ObservableCollection<string>) {
        this.setFieldArray<string>('ViewingModes', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('LensProperties', '');
        this.setField<boolean>('IsHardwareDewarpingSupported', false);
        const objVector = new Vector();
        objVector.initializeAllFields();
        this.setFieldObject('Vector', objVector);
        this.setFieldArray<string>('ViewingModes', new ObservableCollection<string>());
    }
}

export class LensInfo extends FieldObject implements ILensInfo {
    // LensConfiguration
    public get lensConfiguration(): ILensConfiguration {
        return this.getFieldObject<LensConfiguration, ILensConfiguration>(LensConfiguration, 'LensConfiguration');
    }
    public set lensConfiguration(value: ILensConfiguration) {
        this.setFieldObject<ILensConfiguration>('LensConfiguration', value);
    }
    // LensType
    public get lensType(): ILensType {
        return this.getFieldObject<LensType, ILensType>(LensType, 'LensType');
    }
    public set lensType(value: ILensType) {
        this.setFieldObject<ILensType>('LensType', value);
    }

    public initializeAllFields(): void {
        const objLensConfiguration = new LensConfiguration();
        objLensConfiguration.initializeAllFields();
        this.setFieldObject('LensConfiguration', objLensConfiguration);
        const objLensType = new LensType();
        objLensType.initializeAllFields();
        this.setFieldObject('LensType', objLensType);
    }
}

export class ThumbnailData extends FieldObject implements IThumbnailData {
    // Camera
    public get camera(): IGuid {
        return this.getFieldGuid('Camera');
    }
    public set camera(value: IGuid) {
        this.setFieldGuid('Camera', value);
    }
    // Timestamp
    public get timestamp(): Date {
        return this.getFieldDate('Timestamp');
    }
    public set timestamp(value: Date) {
        this.setFieldDate('Timestamp', value);
    }
    // Thumbnail
    public get thumbnail(): string {
        return this.getField<string>('Thumbnail');
    }
    public set thumbnail(value: string) {
        this.setField<string>('Thumbnail', value);
    }
    // Context
    public get context(): IGuid {
        return this.getFieldGuid('Context');
    }
    public set context(value: IGuid) {
        this.setFieldGuid('Context', value);
    }
    // LatestFrame
    public get latestFrame(): Date {
        return this.getFieldDate('LatestFrame');
    }
    public set latestFrame(value: Date) {
        this.setFieldDate('LatestFrame', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Camera', SafeGuid.EMPTY);
        this.setFieldDate('Timestamp', new Date());
        this.setField<string>('Thumbnail', '');
        this.setFieldGuid('Context', SafeGuid.EMPTY);
        this.setFieldDate('LatestFrame', new Date());
    }
}

export class ThumbnailRequest extends FieldObject implements IThumbnailRequest {
    // Time
    public get time(): Date | null {
        return this.getNullableFieldDate('Time');
    }
    public set time(value: Date | null) {
        this.setNullableFieldDate('Time', value);
    }
    // StartTime
    public get startTime(): Date | null {
        return this.getNullableFieldDate('StartTime');
    }
    public set startTime(value: Date | null) {
        this.setNullableFieldDate('StartTime', value);
    }
    // EndTime
    public get endTime(): Date | null {
        return this.getNullableFieldDate('EndTime');
    }
    public set endTime(value: Date | null) {
        this.setNullableFieldDate('EndTime', value);
    }
    // Count
    public get count(): number | null {
        return this.getNullableField<number>('Count');
    }
    public set count(value: number | null) {
        this.setNullableField<number>('Count', value);
    }
    // Width
    public get width(): number {
        return this.getField<number>('Width');
    }
    public set width(value: number) {
        this.setField<number>('Width', value);
    }
    // Timeout
    public get timeout(): number | null {
        return this.getNullableField<number>('Timeout');
    }
    public set timeout(value: number | null) {
        this.setNullableField<number>('Timeout', value);
    }
    // Context
    public get context(): IGuid {
        return this.getFieldGuid('Context');
    }
    public set context(value: IGuid) {
        this.setFieldGuid('Context', value);
    }
    // IsHighPrecision
    public get isHighPrecision(): boolean {
        return this.getField<boolean>('IsHighPrecision');
    }
    public set isHighPrecision(value: boolean) {
        this.setField<boolean>('IsHighPrecision', value);
    }

    public initializeAllFields(): void {
        this.setFieldDate('Time', new Date());
        this.setFieldDate('StartTime', new Date());
        this.setFieldDate('EndTime', new Date());
        this.setField<number>('Count', 0);
        this.setField<number>('Width', 0);
        this.setField<number>('Timeout', 0);
        this.setFieldGuid('Context', SafeGuid.EMPTY);
        this.setField<boolean>('IsHighPrecision', false);
    }
}

export class UserInfo extends FieldObject implements IUserInfo {
    // UserId
    public get userId(): IGuid {
        return this.getFieldGuid('UserId');
    }
    public set userId(value: IGuid) {
        this.setFieldGuid('UserId', value);
    }
    // UserLevel
    public get userLevel(): number {
        return this.getField<number>('UserLevel');
    }
    public set userLevel(value: number) {
        this.setField<number>('UserLevel', value);
    }
    // SystemId
    public get systemId(): string {
        return this.getField<string>('SystemId');
    }
    public set systemId(value: string) {
        this.setField<string>('SystemId', value);
    }
    // SkipPtzEvaluation
    public get skipPtzEvaluation(): boolean {
        return this.getField<boolean>('SkipPtzEvaluation');
    }
    public set skipPtzEvaluation(value: boolean) {
        this.setField<boolean>('SkipPtzEvaluation', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('UserId', SafeGuid.EMPTY);
        this.setField<number>('UserLevel', 0);
        this.setField<string>('SystemId', '');
        this.setField<boolean>('SkipPtzEvaluation', false);
    }
}

export class FeatureCapability extends FieldObject implements IFeatureCapability {
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // Supported
    public get supported(): boolean {
        return this.getField<boolean>('Supported');
    }
    public set supported(value: boolean) {
        this.setField<boolean>('Supported', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Id', '');
        this.setField<boolean>('Supported', false);
    }
}

export class EncryptionCertificate extends FieldObject implements IEncryptionCertificate {
    // Id
    public get id(): number {
        return this.getField<number>('Id');
    }
    public set id(value: number) {
        this.setField<number>('Id', value);
    }
    // Cert
    public get cert(): string {
        return this.getField<string>('Cert');
    }
    public set cert(value: string) {
        this.setField<string>('Cert', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('Id', 0);
        this.setField<string>('Cert', '');
    }
}

export class ControlPtz extends FieldObject implements IControlPtz {
    // Command
    public get command(): string {
        return this.getField<string>('Command');
    }
    public set command(value: string) {
        this.setField<string>('Command', value);
    }
    // Arg1
    public get arg1(): number {
        return this.getField<number>('Arg1');
    }
    public set arg1(value: number) {
        this.setField<number>('Arg1', value);
    }
    // Arg2
    public get arg2(): number {
        return this.getField<number>('Arg2');
    }
    public set arg2(value: number) {
        this.setField<number>('Arg2', value);
    }
    // UserAppId
    public get userAppId(): IGuid | null {
        return this.getNullableFieldGuid('UserAppId');
    }
    public set userAppId(value: IGuid | null) {
        this.setNullableFieldGuid('UserAppId', value);
    }
    // UserId
    public get userId(): IGuid | null {
        return this.getNullableFieldGuid('UserId');
    }
    public set userId(value: IGuid | null) {
        this.setNullableFieldGuid('UserId', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Command', '');
        this.setField<number>('Arg1', 0);
        this.setField<number>('Arg2', 0);
        this.setFieldGuid('UserAppId', SafeGuid.EMPTY);
        this.setFieldGuid('UserId', SafeGuid.EMPTY);
    }
}

export class BlockInfo extends FieldObject implements IBlockInfo {
    // IsBlocked
    public get isBlocked(): boolean {
        return this.getField<boolean>('IsBlocked');
    }
    public set isBlocked(value: boolean) {
        this.setField<boolean>('IsBlocked', value);
    }
    // BlockLevel
    public get blockLevel(): number {
        return this.getField<number>('BlockLevel');
    }
    public set blockLevel(value: number) {
        this.setField<number>('BlockLevel', value);
    }
    // UserLevel
    public get userLevel(): number {
        return this.getField<number>('UserLevel');
    }
    public set userLevel(value: number) {
        this.setField<number>('UserLevel', value);
    }
    // StartTime
    public get startTime(): Date {
        return this.getFieldDate('StartTime');
    }
    public set startTime(value: Date) {
        this.setFieldDate('StartTime', value);
    }
    // EndTime
    public get endTime(): Date {
        return this.getFieldDate('EndTime');
    }
    public set endTime(value: Date) {
        this.setFieldDate('EndTime', value);
    }

    public initializeAllFields(): void {
        this.setField<boolean>('IsBlocked', false);
        this.setField<number>('BlockLevel', 0);
        this.setField<number>('UserLevel', 0);
        this.setFieldDate('StartTime', new Date());
        this.setFieldDate('EndTime', new Date());
    }
}

export class PtzInfo extends FieldObject implements IPtzInfo {
    // Id
    public get id(): number {
        return this.getField<number>('Id');
    }
    public set id(value: number) {
        this.setField<number>('Id', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Id', 0);
        this.setField<string>('Name', '');
    }
}

// #endregion Inner classes

export class CameraEntity extends LocatableEntity implements ICameraEntity {
    // #region REST Properties

    // AudioRecording
    public get audioRecording(): boolean {
        return this.getField<boolean>(CameraEntityFields.audioRecordingField);
    }
    public set audioRecording(value: boolean) {
        this.setField<boolean>(CameraEntityFields.audioRecordingField, value);
    }

    // AudioStreamIn
    public get audioStreamIn(): IGuid {
        return this.getFieldGuid(CameraEntityFields.audioStreamInField);
    }

    // AudioStreamOut
    public get audioStreamOut(): IGuid {
        return this.getFieldGuid(CameraEntityFields.audioStreamOutField);
    }

    // AutomaticCleanup
    public get automaticCleanup(): boolean {
        return this.getField<boolean>(CameraEntityFields.automaticCleanupField);
    }
    public set automaticCleanup(value: boolean) {
        this.setField<boolean>(CameraEntityFields.automaticCleanupField, value);
    }

    // CameraSubType
    public get cameraSubType(): string {
        return this.getField<string>(CameraEntityFields.cameraSubTypeField);
    }

    // DefaultManualRecordingTime
    public get defaultManualRecordingTime(): number {
        return this.getField<number>(CameraEntityFields.defaultManualRecordingTimeField);
    }
    public set defaultManualRecordingTime(value: number) {
        this.setField<number>(CameraEntityFields.defaultManualRecordingTimeField, value);
    }

    // EncryptionEnabled
    public get encryptionEnabled(): boolean {
        return this.getField<boolean>(CameraEntityFields.encryptionEnabledField);
    }
    public set encryptionEnabled(value: boolean) {
        this.setField<boolean>(CameraEntityFields.encryptionEnabledField, value);
    }

    // EncryptionType
    public get encryptionType(): string {
        return this.getField<string>(CameraEntityFields.encryptionTypeField);
    }
    public set encryptionType(value: string) {
        this.setField<string>(CameraEntityFields.encryptionTypeField, value);
    }

    // ExternalSystemType
    public get externalSystemType(): string {
        return this.getField<string>(CameraEntityFields.externalSystemTypeField);
    }

    // FederationStreamUsage
    public get federationStreamUsage(): string {
        return this.getField<string>(CameraEntityFields.federationStreamUsageField);
    }

    // FederationUserId
    public get federationUserId(): IGuid {
        return this.getFieldGuid(CameraEntityFields.federationUserIdField);
    }

    // FederationUserSecurityLevel
    public get federationUserSecurityLevel(): number {
        return this.getField<number>(CameraEntityFields.federationUserSecurityLevelField);
    }

    // IsDVRInput
    public get isDVRInput(): boolean {
        return this.getField<boolean>(CameraEntityFields.isDVRInputField);
    }

    // IsEdgeAnalyticsCamera
    public get isEdgeAnalyticsCamera(): boolean {
        return this.getField<boolean>(CameraEntityFields.isEdgeAnalyticsCameraField);
    }

    // IsLicensedCamera
    public get isLicensedCamera(): boolean {
        return this.getField<boolean>(CameraEntityFields.isLicensedCameraField);
    }

    // IsNative
    public get isNative(): boolean {
        return this.getField<boolean>(CameraEntityFields.isNativeField);
    }

    // IsPluginCamera
    public get isPluginCamera(): boolean {
        return this.getField<boolean>(CameraEntityFields.isPluginCameraField);
    }

    // IsPrivacyProtected
    public get isPrivacyProtected(): boolean {
        return this.getField<boolean>(CameraEntityFields.isPrivacyProtectedField);
    }

    // LockingUserAppId
    public get lockingUserAppId(): IGuid {
        return this.getFieldGuid(CameraEntityFields.lockingUserAppIdField, ValidFlags.EntityStatus);
    }

    // LockingUserId
    public get lockingUserId(): IGuid {
        return this.getFieldGuid(CameraEntityFields.lockingUserIdField, ValidFlags.EntityStatus);
    }

    // LockingUserInfo
    public get lockingUserInfo(): any {
        return this.getField<any>(CameraEntityFields.lockingUserInfoField);
    }

    // LockState
    public get lockState(): string {
        return this.getField<string>(CameraEntityFields.lockStateField, ValidFlags.EntityStatus);
    }
    public set lockState(value: string) {
        this.setField<string>(CameraEntityFields.lockStateField, value);
    }

    // MetadataRecording
    public get metadataRecording(): boolean {
        return this.getField<boolean>(CameraEntityFields.metadataRecordingField);
    }
    public set metadataRecording(value: boolean) {
        this.setField<boolean>(CameraEntityFields.metadataRecordingField, value);
    }

    // OmnicastCameraGuid
    public get omnicastCameraGuid(): IGuid {
        return this.getFieldGuid(CameraEntityFields.omnicastCameraGuidField);
    }

    // PostEventRecordingTime
    public get postEventRecordingTime(): number {
        return this.getField<number>(CameraEntityFields.postEventRecordingTimeField);
    }
    public set postEventRecordingTime(value: number) {
        this.setField<number>(CameraEntityFields.postEventRecordingTimeField, value);
    }

    // PreEventRecordingTime
    public get preEventRecordingTime(): number {
        return this.getField<number>(CameraEntityFields.preEventRecordingTimeField);
    }
    public set preEventRecordingTime(value: number) {
        this.setField<number>(CameraEntityFields.preEventRecordingTimeField, value);
    }

    // PtzAddress
    public get ptzAddress(): number {
        return this.getField<number>(CameraEntityFields.ptzAddressField);
    }

    // PtzCapabilities
    public get ptzCapabilities(): string {
        return this.getField<string>(CameraEntityFields.ptzCapabilitiesField);
    }

    // PtzIdleAction
    public get ptzIdleAction(): string {
        return this.getField<string>(CameraEntityFields.ptzIdleActionField);
    }

    // PtzIdleActionNumber
    public get ptzIdleActionNumber(): number {
        return this.getField<number>(CameraEntityFields.ptzIdleActionNumberField);
    }

    // PtzIdleDelay
    public get ptzIdleDelay(): number {
        return this.getField<number>(CameraEntityFields.ptzIdleDelayField);
    }

    // PtzLockDelay
    public get ptzLockDelay(): number {
        return this.getField<number>(CameraEntityFields.ptzLockDelayField);
    }

    // PtzLockStateTimeUp
    public get ptzLockStateTimeUp(): Date {
        return this.getFieldDate(CameraEntityFields.ptzLockStateTimeUpField, ValidFlags.EntityStatus);
    }

    // PtzProtocol
    public get ptzProtocol(): number {
        return this.getField<number>(CameraEntityFields.ptzProtocolField);
    }

    // PtzSerialPortId
    public get ptzSerialPortId(): IGuid {
        return this.getFieldGuid(CameraEntityFields.ptzSerialPortIdField);
    }

    // PtzSpecificConfig
    public get ptzSpecificConfig(): string {
        return this.getField<string>(CameraEntityFields.ptzSpecificConfigField);
    }

    // RecordingLength
    public get recordingLength(): string {
        return this.getField<string>(CameraEntityFields.recordingLengthField);
    }

    // RecordingState
    public get recordingState(): string {
        return this.getField<string>(CameraEntityFields.recordingStateField, ValidFlags.EntityStatus);
    }

    // RedundantArchiving
    public get redundantArchiving(): boolean {
        return this.getField<boolean>(CameraEntityFields.redundantArchivingField);
    }
    public set redundantArchiving(value: boolean) {
        this.setField<boolean>(CameraEntityFields.redundantArchivingField, value);
    }

    // RetentionPeriod
    public get retentionPeriod(): number {
        return this.getField<number>(CameraEntityFields.retentionPeriodField);
    }
    public set retentionPeriod(value: number) {
        this.setField<number>(CameraEntityFields.retentionPeriodField, value);
    }

    // RoleType
    public get roleType(): IGuid {
        return this.getFieldGuid(CameraEntityFields.roleTypeField);
    }

    // SignalState
    public get signalState(): string {
        return this.getField<string>(CameraEntityFields.signalStateField, ValidFlags.EntityStatus);
    }

    // StreamGroupId
    public get streamGroupId(): string {
        return this.getField<string>(CameraEntityFields.streamGroupIdField);
    }
    public set streamGroupId(value: string) {
        this.setField<string>(CameraEntityFields.streamGroupIdField, value);
    }

    // Tailing
    public get tailing(): string {
        return this.getField<string>(CameraEntityFields.tailingField);
    }

    // ThumbnailId
    public get thumbnailId(): IGuid {
        return this.getFieldGuid(CameraEntityFields.thumbnailIdField);
    }

    // Unit
    public get unit(): IGuid {
        return this.getFieldGuid(CameraEntityFields.unitField);
    }

    // UseCustomRecordingSettings
    public get useCustomRecordingSettings(): boolean {
        return this.getField<boolean>(CameraEntityFields.useCustomRecordingSettingsField);
    }
    public set useCustomRecordingSettings(value: boolean) {
        this.setField<boolean>(CameraEntityFields.useCustomRecordingSettingsField, value);
    }

    // VideoEncoderDevice
    public get videoEncoderDevice(): IGuid {
        return this.getFieldGuid(CameraEntityFields.videoEncoderDeviceField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Cameras);
    }

    // #endregion Constructor

    // #region REST Methods

    public async addBookmarkAsync(description: string, timestamp: Date, playback: boolean, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('description', description);
        inParam.setFieldDate('timestamp', timestamp);
        inParam.setField('playback', playback);

        return this.executeRequestTransactionAsync<IRestResponse>('AddBookmark', 'POST', inParam, transaction, responseHandler);
    }

    public async getArchivedStreamsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('ArchivedStreams', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getAttachedCashRegistersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AttachedCashRegisters', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getAuxAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzInfo> | null> {
        return this.getFieldObjectRelationAsync<PtzInfo, IPtzInfo>(PtzInfo, 'Aux', 'Id', true, true, ValidFlags.None, transaction);
    }

    public async blockAsync(start: Date, end: Date, blockingLevel: number, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldDate('start', start);
        inParam.setFieldDate('end', end);
        inParam.setField('blockingLevel', blockingLevel);

        return this.executeRequestTransactionAsync<IRestResponse>('Block', 'POST', inParam, transaction, responseHandler);
    }

    public async getBlockCameraInfoAsync(transaction?: ITransaction | null): Promise<IBlockInfo | null> {
        return await this.getDataRelationAsync<BlockInfo>(BlockInfo, 'BlockCameraInfo', false, ValidFlags.Specific, transaction);
    }

    public async boostQualityAsScheduledAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('BoostQualityAsScheduled', 'POST', null, transaction, responseHandler);
    }

    public async controlPtzAsync(value: IControlPtz, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('ControlPtz', 'POST', value, transaction, responseHandler);
    }

    public async donateArchivesAsync(id: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('id', id);

        return this.executeRequestTransactionAsync<IRestResponse>('DonateArchives', 'POST', inParam, transaction, responseHandler);
    }

    public async getEncryptionCertificatesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEncryptionCertificate> | null> {
        return this.getFieldObjectRelationAsync<EncryptionCertificate, IEncryptionCertificate>(EncryptionCertificate, 'EncryptionCertificates', 'Id', false, false, ValidFlags.None, transaction);
    }

    public async getFeatureCapabilitiesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IFeatureCapability> | null> {
        return this.getFieldObjectRelationAsync<FeatureCapability, IFeatureCapability>(FeatureCapability, 'FeatureCapabilities', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async getFederationUserMultilevelInfoAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IUserInfo> | null> {
        return this.getFieldObjectRelationAsync<UserInfo, IUserInfo>(UserInfo, 'FederationUserInfo', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async generateThumbnailsAsync(value: IThumbnailRequest, transaction?: ITransaction): Promise<Array<IThumbnailData> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IThumbnailData>> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = new Array<IThumbnailData>();
                const body = response.body as Array<any>;
                for (const entry of body) {
                    const fo = new ThumbnailData();
                    fo.loadFields(entry);
                    result.push(fo);
                }
                return new Promise<Array<IThumbnailData>>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IThumbnailData>>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<Array<IThumbnailData>>('GenerateThumbnails', 'POST', value, transaction, responseHandler);
    }

    public async gotoHomeAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('GotoHome', 'POST', null, transaction, responseHandler);
    }

    public async getHardwareConfigDataAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'HardwareConfigData', false, ValidFlags.None, transaction);
    }

    public async getLensAsync(transaction?: ITransaction | null): Promise<ILensInfo | null> {
        return await this.getDataRelationAsync<LensInfo>(LensInfo, 'Lens', false, ValidFlags.Specific, transaction);
    }

    public async getMetadataStreamsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('MetadataStreams', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getMotionDetectionAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMotionDetection> | null> {
        return this.getFieldObjectRelationAsync<MotionDetection, IMotionDetection>(MotionDetection, 'MotionDetections', 'ScheduleId', false, true, ValidFlags.None, transaction);
    }

    public async getPatternsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzInfo> | null> {
        return this.getFieldObjectRelationAsync<PtzInfo, IPtzInfo>(PtzInfo, 'Patterns', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async getPresetsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzInfo> | null> {
        return this.getFieldObjectRelationAsync<PtzInfo, IPtzInfo>(PtzInfo, 'Presets', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async protectVideoPlaybackAsync(value: IProtectVideo, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('ProtectVideoPlayback', 'POST', value, transaction, responseHandler);
    }

    public async getPtzCapabilitiesAsync(transaction?: ITransaction | null): Promise<IPtzCapabilitiesInfo | null> {
        return await this.getDataRelationAsync<PtzCapabilitiesInfo>(PtzCapabilitiesInfo, 'PtzCapabilities', false, ValidFlags.None, transaction);
    }

    public async getRecordingModesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRecordingMode> | null> {
        return this.getFieldObjectRelationAsync<RecordingMode, IRecordingMode>(RecordingMode, 'RecordingModes', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async startLiveStreamingAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('StartLiveStreaming', 'POST', null, transaction, responseHandler);
    }

    public async startRecordingAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('StartRecording', 'POST', null, transaction, responseHandler);
    }

    public async stopLiveStreamingAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('StopLiveStreaming', 'POST', null, transaction, responseHandler);
    }

    public async stopRecordingAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('StopRecording', 'POST', null, transaction, responseHandler);
    }

    public async getStreamsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IStreamUsageInfo> | null> {
        return this.getFieldObjectRelationAsync<StreamUsageInfo, IStreamUsageInfo>(StreamUsageInfo, 'Streams', 'Id', true, true, ValidFlags.EntityDependency2, transaction);
    }

    public async getTransferGroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('TransferGroups', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async unblockAsync(start: Date, end: Date, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldDate('start', start);
        inParam.setFieldDate('end', end);

        return this.executeRequestTransactionAsync<IRestResponse>('Unblock', 'POST', inParam, transaction, responseHandler);
    }

    public async getVideoAttributesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IVideoAttribute> | null> {
        return this.getFieldObjectRelationAsync<VideoAttribute, IVideoAttribute>(VideoAttribute, 'VideoAttributes', 'ScheduleId', false, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

