// Autogenerated: v2/help/Entities/ApiPrivilegedEntity?codegen=ts&InterfaceOnly=True&workflow=False on 2022-02-24 3:03:12 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class PrivilegedEntityFields extends EntityFields {
    public static archiveViewingLimitationField = 'ArchiveViewingLimitation';
    public static autoLogOffDurationField = 'AutoLogOffDuration';
    public static autoLogOffDurationInMinutesField = 'AutoLogOffDurationInMinutes';
    public static domainField = 'Domain';
    public static emailAddressField = 'EmailAddress';
    public static inheritArchiveViewingLimitationFromUserGroupField = 'InheritArchiveViewingLimitationFromUserGroup';
    public static inheritUserBurningConfigurationFromUserGroupField = 'InheritUserBurningConfigurationFromUserGroup';
    public static isAdministratorField = 'IsAdministrator';
    public static isAutoLogOffInheritedField = 'IsAutoLogOffInherited';
    public static isSecurityLevelInheritedField = 'IsSecurityLevelInherited';
    public static qualifiedNameField = 'QualifiedName';
    public static securityLevelField = 'SecurityLevel';
}

// #endregion Fields

// #region Inner classes

export interface IUserBurningConfiguration extends IFieldObject {
    isEnabled: boolean | null;
    opacity: number | null;
    size: number | null;
    includeUsername: boolean | null;
    includeCameraName: boolean | null;
    includeWorkstation: boolean | null;
    overlayType: string | null;
    mosaicOrientation: string | null;
    overlayPosition: string | null;
}

export interface IPtzPriority extends IFieldObject {
    task: IGuid;
    priority: number;
}

export interface IPrivilege extends IFieldObject {
    id: IGuid;
    state: string;
    description: string;
    parents: ObservableCollection<IGuid>;
    setChildren: boolean;
    setParentsIfNecessary: boolean;
}

// #endregion Inner classes

export interface IPrivilegedEntity extends IEntity {
    // #region REST Properties

    archiveViewingLimitation: number;

    autoLogOffDuration: number;

    autoLogOffDurationInMinutes: number;

    domain: string;

    emailAddress: string;

    inheritArchiveViewingLimitationFromUserGroup: boolean;

    inheritUserBurningConfigurationFromUserGroup: boolean;

    readonly isAdministrator: boolean;

    isAutoLogOffInherited: boolean;

    isSecurityLevelInherited: boolean;

    readonly qualifiedName: string;

    securityLevel: number;

    // #endregion REST Properties

    // #region REST Methods

    isLicenseSupportPrivilegeAsync(privilege: IGuid, transaction?: ITransaction): Promise<boolean | null>;
    isPrivilegeGrantedAsync(privilege: IGuid, entity: IGuid, transaction?: ITransaction): Promise<boolean | null>;
    getPartitionAdminsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getPartitionExceptionsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getPartitionUserAccessAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getPrivilegesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPrivilege> | null>;
    getPtzPrioritiesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzPriority> | null>;
    getPtzPrioritiesRecursiveAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzPriority> | null>;
    getRelatedAlarmsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getRemoteControllableAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    sendMessageAsync(message: string, timeoutDuration: number, transaction?: ITransaction): Promise<IRestResponse | null>;
    getUserBurningConfigurationAsync(transaction?: ITransaction | null): Promise<IUserBurningConfiguration | null>;
    getUsergroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getUsergroupsRecursiveAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

