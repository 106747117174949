import { FieldObject } from '../Helpers/FieldObject';
import { LogSeverity } from './Enumerations/LogSeverity';
import { SecurityCenterClient } from './SecurityCenterClient';
import { ConsoleCommandRequestReceivedArg } from '../Connection/RestArgs';
import { EventDispatcher, Handler } from '../Helpers/Helpers';
import { RestResponse } from '../Connection/RestResponse';

export class DiagnosticEventArgs {
    public logSeverity = '';
    public name = '';
    public message = '';
    public details = '';

    constructor(logSeverity: string, name: string, message: string, details: string) {
        this.logSeverity = logSeverity;
        this.name = name;
        this.message = message;
        this.details = details;
    }
}

export class ServiceDomain {
    public serviceDomainId = '';
    public processId = 0;
    public loadingStrategy = '';

    constructor(serviceDomainId: string, processId: number, loadingStrategy: string) {
        this.serviceDomainId = serviceDomainId;
        this.processId = processId;
        this.loadingStrategy = loadingStrategy;
    }
}

export class Service {
    public serviceId = '';
    public serviceType = '';

    public Service(serviceId: string, serviceType: string) {
        this.serviceId = serviceId;
        this.serviceType = serviceType;
    }
}

export class DiagnosticManager {
    // #region Fields

    public consoleCommandDefaultAnswerEnabled = true;
    public enabled = true;
    private client: SecurityCenterClient;
    private _enabledLevel = new Map<string, boolean>();
    private _handlers = new Map<string, (args: ConsoleCommandRequestReceivedArg) => Promise<string>>();
    private _streamerConsoleCommandRequestReceivedDispatcher = new EventDispatcher<ConsoleCommandRequestReceivedArg>();

    // #endregion

    // #region Constructor

    constructor(client: SecurityCenterClient) {
        this.client = client;

        // All level enabled by default
        this._enabledLevel.set(LogSeverity.Debug, true);
        this._enabledLevel.set(LogSeverity.Error, true);
        this._enabledLevel.set(LogSeverity.Fatal, true);
        this._enabledLevel.set(LogSeverity.Information, true);
        this._enabledLevel.set(LogSeverity.Performance, true);
        this._enabledLevel.set(LogSeverity.Warning, true);
    }

    // #endregion

    // #region Public methods

    public enableTraceLevel(level: string, enable: boolean) {
        if (this._enabledLevel.has(level)) {
            this._enabledLevel.set(level, enable);
        }
    }

    public async sendTraceAsync(level: string, name: string, message: string, details: string) {
        if (!this.enabled) {
            return;
        }

        if (this.client.isLoggedOn) {
            try {
                const body = new FieldObject();
                body.setField('Level', level);
                body.setField('Name', name);
                body.setField('Message', message);
                body.setField('Details', details);
                await this.client.rest.executeRequestAsync('POST', '/v2/Traces', body.toString());
            } catch (e) {
                // ignore
            }
        }
    }

    public async addConsoleDebugCommandAsync(
        handler: (args: ConsoleCommandRequestReceivedArg) => Promise<string>,
        group: string,
        method: string,
        param1: string,
        param2: string,
        param3: string,
    ) {
        const key = group + ' ' + method;
        if (this._handlers.has(key)) {
            this._handlers.delete(key);
        }
        this._handlers.set(key, handler);
        return this.client.rest.addConsoleDebugCommandAsync(group, method, param1, param2, param3);
    }

    public async executeDebugCommandAsync(logger: string, method: string, ...params: string[]): Promise<RestResponse> {
        const body = new FieldObject();
        body.setField('logger', logger);
        body.setField('command', method);
        const parameters = new Array<object>();

        let i = 0;
        for (const param of params) {
            const zeParam = new FieldObject();
            zeParam.setField('index', i);
            zeParam.setField('name', 'param' + i);
            zeParam.setField('type', 'System.String');
            zeParam.setField('value', param);
            parameters.push(zeParam.toJson());
            i = i + 1;
        }
        body.setField('parameters', JSON.stringify(parameters));

        const response = await this.client.rest.executeRequestAsync('POST', '/v2/DebugCommand', body.toString());
        return response;
    }

    public onConsoleCommandRequestReceived(handler: Handler<ConsoleCommandRequestReceivedArg>) {
        return this._streamerConsoleCommandRequestReceivedDispatcher.subscribe(handler);
    }

    /*
    public virtual async Task<List<ServiceDomain>> GetServiceDomainsAsync()
    {
        List<ServiceDomain> result = new List<ServiceDomain>();
        var response = await _client.Rest.ExecuteRequestAsync($"/v2/ServiceDomains", "GET", null).ConfigureAwait(false);
        var serviceDomains = response.GetArray();
        foreach( var domain in serviceDomains )
        {
            var domainId = domain.SelectStringToken("ServiceDomainId");
            var processId = domain.SelectIntToken("ProcessId");
            var loadingStrategy = domain.SelectStringToken("LoadingStrategy");
            ServiceDomain newDomain = new ServiceDomain(domainId, processId.Value, loadingStrategy);
            result.Add(newDomain);
        }
        return result;
    }

    public virtual async Task<List<Service>> GetServicesAsync(string serviceDomainId)
    {
        List<Service> result = new List<Service>();
        var response = await _client.Rest.ExecuteRequestAsync($"/v2/ServiceDomains/{serviceDomainId}", "GET", null).ConfigureAwait(false);
        var services = response.GetArray();
        foreach (var service in services)
        {
            var serviceId = service.SelectStringToken("ServiceId");
            var serviceType = service.SelectStringToken("ServiceType");
            Service newService = new Service(serviceId, serviceType);
            result.Add(newService);
        }
        return result;
    }

    public virtual Task<RestResponse> KillServiceDomainAsync(string serviceDomainId)
    {
        return _client.Rest.ExecuteRequestAsync($"/v2/ServiceDomains/{serviceDomainId}", "DELETE", null);
    }

    public virtual Task<RestResponse> KillServiceAsync(string serviceDomainId, string serviceId)
    {
        return _client.Rest.ExecuteRequestAsync($"/v2/ServiceDomains/{serviceDomainId}/{serviceId}", "DELETE", null);
    }
    */

    // #endregion

    // #region Private Methods

    // #endregion

    // #region Internal

    public newConnectionCreated() {
        if (this.client.rest != null) {
            this.client.rest.onConsoleCommandRequestReceived((arg) => this.onInternalConsoleCommandRequestReceived(arg));
        }
    }

    // #endregion

    // #region Event handlers

    private async onInternalConsoleCommandRequestReceived(args: ConsoleCommandRequestReceivedArg) {
        try {
            const key = args.group + ' ' + args.method;
            if (this.enabled) {
                if (this._handlers.has(key)) {
                    const handler = this._handlers.get(key);
                    if (handler != null) {
                        const response = await handler(args);
                        if (response != null) {
                            await this.client.rest.sendRequestResponseAsync(args.id, response);
                        }
                        return;
                    }
                }
            }
            if (this.consoleCommandDefaultAnswerEnabled === true) {
                await this.client.rest.sendRequestResponseAsync(args.id, 'OK'); // always respond something
                return;
            }
            this._streamerConsoleCommandRequestReceivedDispatcher.dispatch(args);
        } catch (e: any) {
            await this.client.rest.sendRequestResponseAsync(args.id, e.toString()); // always respond something
        }
    }

    // #endregion
}
