import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { PluginHostClickEvent } from '@modules/shared/components/plugins/plugin-host.component';
import { PluginsStack } from '@modules/shared/components/plugins/plugins-stack';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { DisplayContext, PluginContext } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { Popup } from '@genetec/web-maps';
import { PLUGIN_CONTEXT } from '@modules/shared/tokens';
import { merge, Observable, Subject, timer, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MapPopupContent } from './interfaces';

@UntilDestroy()
@Component({
    selector: 'app-map-popup',
    templateUrl: './map-popup.component.html',
    providers: [
        {
            provide: PLUGIN_CONTEXT,
            useValue: {
                displayContext: DisplayContext.MapPopup,
            },
        },
    ],
})
export class MapPopupComponent extends TrackedComponent implements OnInit, OnDestroy {
    private static TIMEOUT_TO_SHOW_EXPANDED_PLUGINS_MS = 500;

    @Output() public pluginClick = new EventEmitter<PluginHostClickEvent>();
    @Output() public contextMenuOpen = new EventEmitter<boolean>();

    public readonly maxWidth = 400;
    public readonly maxHeigth = 300;

    // Plugins shown on the popup by default
    public pluginsStack: PluginsStack = new PluginsStack();

    // Plugins shown on the popup when expanded (hovered)
    public expandedPluginsStack: PluginsStack = new PluginsStack();

    public isContextMenuOpen = false;

    public expanded$: Observable<boolean>;

    /**
     * WebMaps linked map object. Its popup will be linked to this component.
     * When this component's plugin are cleared, the popup on the map object will close.
     */
    public webMapsPopup?: Popup;

    public get isPermanent(): boolean {
        return this.permanent;
    }

    private timeToCloseTimeout?: number;

    private mouseEnterSubject = new Subject<void>();
    private mouseLeaveSubject = new Subject<void>();
    private mouseEnter$ = this.mouseEnterSubject.asObservable();
    private mouseLeave$ = this.mouseLeaveSubject.asObservable();

    private currentContextMenuCloseSubscription: Subscription | null = null;

    private closeOnMouseLeave = false;

    private permanent = false;

    constructor(
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        @Inject(PLUGIN_CONTEXT) public dataContext: PluginContext
    ) {
        super(trackingService);

        this.expanded$ = merge(
            this.mouseEnter$.pipe(
                filter(() => (this.expandedPluginsStack.verticalPlugins?.length ?? 0) > 0),
                switchMap(() =>
                    timer(MapPopupComponent.TIMEOUT_TO_SHOW_EXPANDED_PLUGINS_MS).pipe(
                        map(() => true),
                        takeUntil(this.mouseLeaveSubject)
                    )
                )
            ),
            this.mouseLeave$.pipe(map(() => false))
        ).pipe(untilDestroyed(this));
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(): void {
        this.mouseEnterSubject.next();
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseLeave(): void {
        this.mouseLeaveSubject.next();
    }

    public onContextMenu(shown: boolean): void {
        this.isContextMenuOpen = shown;
        this.contextMenuOpen.emit(shown);
    }

    public onPluginClick(event?: PluginHostClickEvent): void {
        if (this.isContextMenuOpen) {
            this.closeContextMenu();
        }
        this.pluginClick.emit(event);
    }

    public updateContentAndOpenPopup(content: MapPopupContent): void {
        this.expandedPluginsStack.set(content.expandedPlugins ?? []);
        this.pluginsStack.set(content.plugins);

        this.replacePopup(content.webMapsPopup);

        content.webMapsPopup.getSourceLayer()?.openPopup();
        if (content.closeOnMouseLeave) {
            // once will unsubscribe automatically after a single event.
            content.webMapsPopup.getSourceLayer()?.once('mouseleaveincludepopup', () => {
                this.clearCloseTimeoutAndSubscription();
                if (!this.isContextMenuOpen) {
                    this.clearAndClosePopup();
                } else {
                    this.currentContextMenuCloseSubscription = this.contextMenuOpen.pipe(take(1), untilDestroyed(this)).subscribe(() => {
                        this.clearAndClosePopup();
                    });
                }
            });
        }

        this.resetDelayToCloseInterval(content.delayToCloseMs);
        this.closeOnMouseLeave = content.closeOnMouseLeave ?? false;
        this.permanent = content.delayToCloseMs === undefined;
    }

    public clearAndClosePopup(): void {
        this.pluginsStack.empty();
        this.expandedPluginsStack.empty();
        this.closePopup();
    }

    private replacePopup(popup: Popup) {
        if (this.webMapsPopup !== popup) {
            this.closePopup();
            this.webMapsPopup = popup;
        }
    }

    private closePopup() {
        this.clearCloseTimeoutAndSubscription();
        if (this.isContextMenuOpen) {
            this.closeContextMenu();
        }

        // Close through source layer if possible because of some instances where isPopupOpen
        // on the layer might still return true even when popup was closed.
        const source = this.webMapsPopup?.getSourceLayer();
        if (source) {
            source.closePopup();
        } else {
            this.webMapsPopup?.close();
        }
        this.permanent = false;
    }

    private resetDelayToCloseInterval(newDelayToDisappear?: number): void {
        this.clearCloseTimeoutAndSubscription();

        if (newDelayToDisappear !== undefined) {
            this.timeToCloseTimeout = window.setTimeout(() => {
                if (!this.isContextMenuOpen) {
                    this.closePopup();
                } else {
                    this.currentContextMenuCloseSubscription = this.contextMenuOpen.pipe(take(1)).subscribe(() => {
                        this.closePopup();
                    });
                }
            }, newDelayToDisappear);
        } else {
            this.timeToCloseTimeout = undefined;
        }
    }

    private clearCloseTimeoutAndSubscription(): void {
        window.clearTimeout(this.timeToCloseTimeout);
        this.currentContextMenuCloseSubscription?.unsubscribe();
    }

    private closeContextMenu(): void {
        this.commandsService.hideCurrentContextMenu();
        this.isContextMenuOpen = false;
    }
}
