import { Program } from './Program';

export class Attribute {
  protected readonly m_name: string;
  protected readonly m_attributeLocation: number;

  public get Location(): number {
    return this.m_attributeLocation;
  }

  public constructor(program: Program, name: string) {
    this.m_name = name;
    this.m_attributeLocation = program.getAttributeLocation(this.m_name);
  }
}
