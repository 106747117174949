// Autogenerated: CodeGen/MapController?language=ts&InterfaceOnly=False on 2021-10-05 12:08:42 PM
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { RestObject } from 'RestClient/Client/Model/RestObject';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { IGuid } from 'safeguid';
import { IMapController } from './map.controller.interfaces';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import { IWebMapObjectFilter, IWebMapLayer, WebMapLayer, IWebMapObjectResult, WebMapObjectResult, IConcreteWebMapObjectFilter, IWebMapNotificationFilterRequest, IFloorQueryResult, FloorQueryResult, IFloorQuery } from './map.controller.data';
import { IWebMapNotificationFilter, WebMapNotificationFilter } from './map.controller.data';
import { PushResponseReceiver } from 'WebClient/PushResponseReceiver';

export class MapController extends RestObject implements IMapController {
    constructor() {
        super('api/Map');
        this._canGet = false;
    }

    // #region Rest Methods

    public async getAllMapObjectsAsync(query: IWebMapObjectFilter, callback: (size: number, buffer: ArrayBuffer) => void): Promise<IRestResponse> {
        if (this.client === null) {
            throw new Error('this.client is null');
        }
        const receiver = new PushResponseReceiver(this.client);
        const response = await receiver.getAsync(this.baseUri + '/' + 'AllMapObjects' + '?' + query.toQueryString(), (size: number, buffer: ArrayBuffer) => {
            callback(size, buffer);
        });
        return response;
    }

    public async getAvailableLayersAsync(mapId: IGuid, transaction?: ITransaction | null): Promise<ObservableCollection<IWebMapLayer> | null> {
        return this.getFieldObjectRelationAsync<WebMapLayer, IWebMapLayer>(WebMapLayer, 'AvailableLayers/' + mapId, 'mapId', true, false, null, transaction);
    }

    public async getFloorsAsync(query: IFloorQuery, transaction?: ITransaction | null): Promise<IFloorQueryResult | null> {
        return await this.getDataRelationAsync<FloorQueryResult>(FloorQueryResult, 'Floors' + '?' + query.toQueryString(), false, null, transaction);
    }

    public async getMapObjectsAsync(query: IConcreteWebMapObjectFilter, transaction?: ITransaction | null): Promise<IWebMapObjectResult | null> {
        return await this.getDataRelationAsync<WebMapObjectResult>(WebMapObjectResult, 'MapObjects' + '?' + query.toQueryString(), false, null, transaction);
    }

    public async getNotificationFilterAsync(query: IWebMapNotificationFilterRequest, transaction?: ITransaction | null): Promise<IWebMapNotificationFilter | null> {
        return await this.getDataRelationAsync<WebMapNotificationFilter>(WebMapNotificationFilter, 'NotificationFilter' + '?' + query.toQueryString(), true, null, transaction);
    }

    // #endregion Rest Methods

}
