import { Component, ElementRef, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AccessControlContentTypes } from '@modules/access-control/enumerations/access-control-content-types';
import { ZoneContentTypes } from '@modules/general/enumerations/zone-content-types';
import { NavigationPluginHostComponent } from '@modules/shared/components/plugins/navigation-plugin-host/navigation-plugin-host.component';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { DragDropTypes } from '@modules/shared/enumerations/drag-drop-types';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { FullscreenService } from '@modules/shared/services/fullscreen/fullscreen.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TileBoundary } from '@modules/tiles/models/tile-boundary';
import { TileItem } from '@modules/tiles/models/tile-item';
import { VideoContentTypes } from '@modules/video/enumerations/video-content-types';
import { SafeGuid } from 'safeguid';
import { TileHeaderService } from '@modules/tiles/components/tile-state-header/services/tile-state-header.service';

// ==========================================================================
// Copyright (C) 2020 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: TileComponent.selector,
    templateUrl: './tile.component.html',
    styleUrls: ['./tile.component.scss'],
    providers: [TileHeaderService],
})
export class TileComponent extends TrackedComponent implements OnDestroy, OnInit {
    public static pluginId = SafeGuid.parse('D806B5D4-99D7-4907-ADC5-59E123257A62');
    public static selector = 'app-tile';
    public static supportedDragTypes = ['sc-content', 'sc-tile', 'text/plain', DragDropTypes.EntityId];

    /**
     * This is needed for the entity-state-overlay plugin to allow the user to change current video feed (camera)
     */
    @ViewChildren('navigationPluginHost') navigationPlugins!: QueryList<NavigationPluginHostComponent>;

    @HostBinding('class.isDraggingOver') public get isDraggingOver(): boolean {
        return this.state.isDraggingOver;
    }

    @HostBinding('class.expanded') public get isExpanded(): boolean {
        return this.state.isExpanded;
    }

    @Input() public canDrag!: boolean;
    @Input() public set state(state: TileItem) {
        this.#state = state;
        this.tileHeaderService.updateState(state);
    }
    public get state(): TileItem {
        return this.#state as TileItem;
    }

    @Input() public set isFullscreen(value: boolean) {
        if (value) {
            this.fullscreenService.enterFullscreenFor(this.hostElement).fireAndForget();
        } else {
            this.fullscreenService.exitFullscreen().fireAndForget();
        }
    }

    @Input()
    @HostBinding('class.selected')
    public get isSelected(): boolean {
        return this.state.isSelected;
    }
    public set isSelected(value: boolean) {
        this.state.isSelected = value;
        this.tileHeaderService.updateState(this.state);
        if (value) {
            this.activateCommands();
        } else {
            this.deactivateCommands();
        }
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public dataContext: unknown;
    public hideNavigationArrows = false;
    #state: unknown;

    private displayedPlugins: PluginItem[][] = [];

    constructor(
        public hostElement: ElementRef<Element>,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        trackingService: TrackingService,
        private tileHeaderService: TileHeaderService,
        private fullscreenService: FullscreenService
    ) {
        super(trackingService);

        // keep track of state changes. Adjust navigation arrows accordingly.
        this.tileHeaderService.state$.pipe(untilDestroyed(this)).subscribe((state) => {
            this.hideNavigationArrows = this.hasAlreadyNavigationInHeader(state);
        });
    }

    @HostListener('mouseenter') onEnter(): void {
        this.state.isMouseOver = true;
        this.tileHeaderService.updateState(this.state);
    }

    @HostListener('mouseleave') onLeave(): void {
        this.state.isMouseOver = false;
        this.tileHeaderService.updateState(this.state);
    }

    public ngOnDestroy() {
        this.deactivateCommands();
        this.state.clear();
        super.ngOnDestroy();
    }

    public ngOnInit() {
        super.ngOnInit();
        if (!this.state) {
            // ensure we have a state for when we are used as a standalone tile
            this.state = new TileItem(0, TileBoundary.topLeft);
            this.tileHeaderService.updateState(this.state);
        }

        this.state.clearing$.pipe(untilDestroyed(this)).subscribe(() => this.deactivateCommands());
    }

    /**
     * checks if current main content is of a supported type.
     * Door, Zone or Camera
     *
     * @returns true if supported, false otherwise.
     */
    public hasAlreadyNavigationInHeader(state: TileItem | null): boolean {
        if (state?.content) {
            const supportedEntities = [AccessControlContentTypes.Door.toString(), ZoneContentTypes.Zone.toString(), VideoContentTypes.Video.toString()];
            const mainContentType = state.content?.mainContent.type;
            const isSupported = supportedEntities.includes(mainContentType.toString());
            return isSupported;
        }
        return false;
    }

    public onDisplayedPluginsChanged(displayedPlugins: PluginItem[], verticalStackIndex: number): void {
        if (this.state.pluginsStack.verticalPlugins && this.state.pluginsStack.verticalPlugins.length !== this.displayedPlugins.length) {
            this.displayedPlugins = this.state.pluginsStack.verticalPlugins.map(() => []);
        }
        this.displayedPlugins[verticalStackIndex] = displayedPlugins;

        const resetCommandsAsync = async () => {
            this.deactivateCommands();
            await this.getPluginCommandsAsync();
            if (this.isSelected) {
                this.activateCommands();
            }
        };

        resetCommandsAsync().fireAndForget();
    }

    private activateCommands(): void {
        if (this.state.commandsUsages?.length > 0) {
            this.commandsService.addActiveCommandsUsages(this.state.commandsUsages);
        }

        this.commandsService.activeTarget = this.hostElement.nativeElement;
    }

    private deactivateCommands(): void {
        if (this.state.commandsUsages?.length > 0) {
            this.commandsService.removeActiveCommandsUsages(this.state.commandsUsages);
        }

        if (this.commandsService.activeTarget === this.hostElement.nativeElement) {
            this.commandsService.activeTarget = undefined;
        }
    }

    private async getPluginCommandsAsync() {
        this.state.commandsUsages.clear();
        for (const pluginItems of this.displayedPlugins) {
            for (const pluginItem of pluginItems) {
                const usage = await this.commandsService.getCommandsUsage(
                    { type: ContextTypes.Content, data: pluginItem.data },
                    pluginItem?.exposure?.availableCommands,
                    false,
                    this.hostElement.nativeElement
                );
                this.state.commandsUsages.addUsage(usage);
            }
        }
    }
}
