<div *ngIf="!isLoading; else spinner" [style.cursor]="'pointer'" (click)="navigateToCardholderAsync()" appClickActionOnSpaceEnter tabindex="0">
    <div class="title-bar">
        <gen-text [flavor]="TextFlavor.Label">{{ title }}</gen-text>
        <gen-icon [icon]="Icon.AngleRight"></gen-icon>
    </div>
    <div class="content">
        <div class="picture-container">
            <div *ngIf="!cardholderPicture; else customImage" class="picture-container__image-placeholder">
                <div *ngIf="isLoadingPicture; else defaultIcon">
                    <gen-spinner></gen-spinner>
                </div>
                <ng-template #defaultIcon>
                    <gen-icon class="picture-container__big-icon" [icon]="Icon.Person"></gen-icon>
                </ng-template>
            </div>
            <ng-template #customImage>
                <gen-image class="picture-container__custom-image" [flavor]="ImageFlavor.Rounded" [src]="cardholderPicture" [fit]="ImageFit.Contain"></gen-image>
            </ng-template>
        </div>
        <div class="info">
            <gen-text [flavor]="TextFlavor.Normal">{{ cardholderFullName }}</gen-text>
            <ng-container *ngIf="displayLastAccess">
                <gen-text class="info__last-seen" [flavor]="TextFlavor.Label">{{ 'STE_LABEL_CARDHOLDER_LASTSEEN' | translate }}</gen-text>
                <gen-text [flavor]="TextFlavor.Secondary">
                    {{ (wasLastAccessGranted ? 'STE_EVENT_TYPE_NAME_ACCESSGRANTED' : 'STE_EVENT_TYPE_NAME_ACCESSREFUSED') | translate }}
                </gen-text>
                <gen-text [flavor]="TextFlavor.Secondary">{{ lastAccessLocation }}</gen-text>
                <gen-text [flavor]="TextFlavor.Secondary">{{ lastAccessTime }}</gen-text>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #spinner>
    <gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
</ng-template>
