import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonFlavor, Icon, IconSize, ItemSlot, PopupPosition, TextFlavor, SpinnerSize } from '@genetec/gelato';
import { GenPopup, GenToastService, MeltedIcon, ToastFlavor } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { Observable } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { UnifiedReportFeatureFlags } from '@modules/correlation/feature-flags';
import { CorrelationService } from '@modules/correlation/services/correlation.service';
import { SortOrder } from '@modules/correlation/enumerations/sort-order';
import { ViewMode } from '@modules/correlation/enumerations/view-mode';
import { RowResult } from '@modules/correlation/interfaces/row-result';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { AnalyticsNames } from '@modules/shared/enumerations/analytics-names';
import { InvestigateContext } from '@modules/correlation/enumerations/analytics-investigate-context';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import moment from 'moment';
import { DateFilterMode } from '@modules/shared/components/filters/common-filters/filter-date/enums/date-filter-mode.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { notNullOrUndefined } from '@modules/shared/operators/not-null-or-undefined';
import { Select } from '@ngxs/store';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { Constants } from '@src/constants';
import { ContentOverlayService } from '@shared/services/content-overlay/content-overlay.service';
import { CorrelationSearchParameter } from '../../parameters-modal/correlation-search-parameter';
import { WhatFilter } from '../services/investigate-data.interfaces';
import { ApiException, CorrelationClient, CorrelationQueryArguments, CorrelationQueryResult, ErrorSeverity, LocationFilter, ModelStateEntry, WhereMode } from '../../../api/api';
import { CorrelationFeatures } from '../../../enumerations/correlation.features';
import { InvestigateResultsContainerComponent } from '../results/investigate-results-container.component';
import { InvestigateDataContext } from '../services/investigate-data.context';
import { InvestigateSideContextDataService } from '../services/investigate-side-context-data.service';
import { SelectInvestigateColumnsComponent } from '../../general/select-investigate-columns/select-investigate-columns.component';
import { InvestigateWhatFilterComponent } from '../filters/what/what-filter.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@UntilDestroy()
@Component({
    selector: 'app-investigate',
    templateUrl: './investigate-task.component.html',
    styleUrls: ['./investigate-task.component.scss'],
    providers: [InvestigateSideContextDataService, InvestigateDataContext, ContentOverlayService],
})
@InternalPluginDescriptor({
    type: InvestigateTaskComponent,
    pluginTypes: [PluginTypes.Task],
    exposure: {
        id: InvestigateTaskComponent.pluginId,
        icon: 'gen-ico-charts',
        title: 'STE_TASK_REPORTS',
        tags: ['STE_BUTTON_INVESTIGATE', 'STE_TASK_REPORTS', 'STE_LABEL_DATA', 'datum'],
        priority: 20,
    },
    requirements: {
        features: [CorrelationFeatures.investigationTaskFeature],
        licenses: [KnownLicenses.correlation],
        globalPrivileges: [KnownPrivileges.correlationReportPrivilege],
        enabledFeatureFlags: [UnifiedReportFeatureFlags.General],
    },
    isSupported: () => true,
})
export class InvestigateTaskComponent extends TrackedComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('EE14C544-8E6E-4626-8737-55B2A7AF9B49');
    public static maxResultCount = 500;

    @ViewChild(InvestigateResultsContainerComponent) public resultsContainerComponent!: InvestigateResultsContainerComponent;
    @ViewChild('whatPopup') whatPopup!: GenPopup;
    @ViewChild('whenPopup') whenPopup!: GenPopup;
    @ViewChild('wherePopup') wherePopup!: GenPopup;
    @ViewChild('selectColumns') selectColumnsComponent!: SelectInvestigateColumnsComponent;

    @Select(FeatureFlagsState.featureFlags(GeneralFeatureFlags.ExpandWidget))
    public isPortalEnabled$!: Observable<boolean>;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly ItemSlot = ItemSlot;
    public readonly TextFlavor = TextFlavor;
    public readonly PopupPosition = PopupPosition;
    public readonly SpinnerSize = SpinnerSize;

    public isSplashSreenOpen = true;
    public isMoreMenuOpen = false;
    public viewMode = ViewMode.Grid;
    public numResults!: string;
    public querying = false;
    public sortOrder = SortOrder.Ascending;

    public isWhenFilterValid$: Observable<boolean>;
    public whatFilterDescription$: Observable<string>;
    public whereFilterDescription$: Observable<string>;
    public whenFilterDescription$: Observable<string>;

    public maxNumberOfResultsToDisplayInView;

    private readonly defaultNumberOfResultsToDisplayInView = 2000;
    private timeout: number | null = null;
    private lastQueriedResults: RowResult[] = [];

    constructor(
        trackingService: TrackingService,
        private translateService: TranslateService,
        private loggerService: LoggerService,
        private contentProviderService: ContentProviderService,
        private contextDataService: InvestigateSideContextDataService,
        private route: ActivatedRoute,
        public dataContext: InvestigateDataContext,
        public notificationService: GenToastService,
        private correlationClient: CorrelationClient,
        private changeDetectorRef: ChangeDetectorRef,
        private correlationService: CorrelationService,
        private securityCenterClientService: SecurityCenterClientService,
        private analyticsService: AnalyticsService,
        private advancedSettingsService: AdvancedSettingsService
    ) {
        super(trackingService);
        this.maxNumberOfResultsToDisplayInView = this.getMaxUnifiedReportRows();

        this.whatFilterDescription$ = this.dataContext.whatFilter$.pipe(notNullOrUndefined(), pluck('description'), distinctUntilChanged());
        this.whenFilterDescription$ = this.dataContext.whenFilter$.pipe(notNullOrUndefined(), pluck('description'), distinctUntilChanged());
        this.whereFilterDescription$ = this.dataContext.whereFilter$.pipe(notNullOrUndefined(), pluck('description'), distinctUntilChanged());
        this.isWhenFilterValid$ = this.dataContext.whenFilter$.pipe(
            notNullOrUndefined(),
            map((filter) => filter.isValid ?? false),
            distinctUntilChanged()
        );
    }

    //#region Methods

    public ngOnInit() {
        super.ngOnInit();

        const params = this.route.snapshot?.paramMap;
        if (params?.has(Constants.subTaskIdentifier)) {
            const subTaskId = params.get(Constants.subTaskIdentifier);
            if (subTaskId) {
                const dataTypeId = SafeGuid.from(subTaskId) ?? InvestigateWhatFilterComponent.AnythingDataTypeId;
                if (!dataTypeId.isEmpty()) {
                    this.openReport(dataTypeId).fireAndForget();
                    return;
                }
            }
        }

        // This will load the last generated report if the filters are still stored in the dataContext
        this.displayLastGeneratedReport();
    }

    public ngOnDestroy() {
        this.contextDataService?.clearMainContent();
        super.ngOnDestroy();
    }

    //#endregion

    //#region Methods

    public onItemSelected(): void {
        this.investigate().fireAndForget();
        this.isSplashSreenOpen = false;
    }

    public toggleMoreMenu(override?: boolean): void {
        this.isMoreMenuOpen = override ?? !this.isMoreMenuOpen;
    }

    public async investigate(context: InvestigateContext = InvestigateContext.Report): Promise<void> {
        this.logInvestigateEvent(context).fireAndForget();
        await this.executeQueryAsync();
    }

    public async executeQueryAsync(): Promise<void> {
        try {
            // set display status
            this.querying = true;
            // build arguments
            const args = this.buildQueryArguments();
            // execute query
            const report = await this.correlationClient.query(args).toPromise();
            // if the report has an error message, display the toast
            if (report.error) {
                this.loggerService.traceDebug(`Investigate report error received ${report.error}`);
                this.displayReportError(report).fireAndForget();
            }
            let len = report.items.length ?? 0;
            if (len && len > this.maxNumberOfResultsToDisplayInView) {
                len = this.maxNumberOfResultsToDisplayInView;
            }
            this.numResults = len.toString();
            this.loggerService.traceDebug(`Investigate report retrieved ${this.numResults} items`);
            this.setReportResults(report);
        } catch (err) {
            this.loggerService.traceDebug(`Error while execute investigate query report`);
            let showNoResults = true;
            if (err instanceof ApiException) {
                if (err.status === 400) {
                    let subText: string | undefined;
                    if (err.result instanceof ModelStateEntry && err.result.errors) {
                        subText = err.result.errors?.length > 0 ? err.result.errors[0]?.errorMessage ?? undefined : undefined;
                    }
                    // bad request
                    this.notificationService.show({
                        text: this.translateService.instant('STE_LABEL_INVALIDFILTER') as string,
                        flavor: ToastFlavor.Warning,
                        icon: MeltedIcon.Warning,
                        secondaryText: subText,
                    });
                    showNoResults = false;
                } else if (err.status === 500) {
                    let subtext: string | undefined;
                    if (typeof err.result === 'string' && err.result) {
                        subtext = err.result;
                    }
                    // something fatal happened
                    this.notificationService.show({
                        text: this.translateService.instant('STE_LABEL_UNKNOWNERROR') as string,
                        flavor: ToastFlavor.Error,
                        icon: MeltedIcon.Error,
                        secondaryText: subtext,
                    });
                    showNoResults = false;
                }
            }
            // fallback, some other API error occurred. Just show no results
            if (showNoResults) {
                this.notificationService.show({
                    text: this.translateService.instant('STE_LABEL_NORESULTS') as string,
                    secondaryText: this.translateService.instant('STE_MESSAGE_INFO_NORESULTS_DETAILS') as string,
                    flavor: ToastFlavor.Information,
                    icon: MeltedIcon.Info,
                });
            }
        } finally {
            // clear status + pop notification window if now results
            this.querying = false;
        }
    }

    public async toggleWhatFilter(open?: boolean): Promise<void> {
        if (open !== undefined) {
            this.whatPopup.open = open;
        } else {
            await this.whatPopup.toggle();
        }
    }

    public async toggleWhenFilter(open?: boolean): Promise<void> {
        if (open !== undefined) {
            this.whenPopup.open = open;
        } else {
            await this.whenPopup.toggle();
        }
    }

    public async toggleWhereFilter(open?: boolean): Promise<void> {
        if (open !== undefined) {
            this.wherePopup.open = open;
        } else {
            await this.wherePopup.toggle();
        }
    }

    public onSelectionChanged(key?: string): void {
        if (key) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.timeout = window.setTimeout(async () => {
                this.contextDataService?.setLoadingMainContent();

                const fo = new FieldObject();
                fo.setField('SourceId', key);
                await this.updateSidePane(fo);
            });
        }
    }

    public onQueryingChanged(querying: boolean): void {
        this.querying = querying;
    }

    public showColumnSelector(): void {
        this.selectColumnsComponent.show();
        this.changeDetectorRef.detectChanges();
    }

    //#endregion

    //#region Private Methods

    private async openReport(dataTypeId: IGuid): Promise<void> {
        this.dataContext.setWhatFilter({
            dataTypes: [dataTypeId],
            parameters: [] as CorrelationSearchParameter[],
            description: await this.getDataTypeDescription(dataTypeId),
        } as WhatFilter);
        setTimeout(() => this.investigate(), 50);
        this.displayLastGeneratedReport();
    }

    private async getDataTypeDescription(requestedDataType: IGuid): Promise<string> {
        let dataTypeDescription = '';
        const dataTypes = await this.correlationService.getDataTypes();
        if (dataTypes && requestedDataType) {
            dataTypes.forEach((element) => {
                if (element.dataType.equals(requestedDataType)) {
                    dataTypeDescription = element.name;
                }
            });
        }
        return dataTypeDescription;
    }

    private getMaxUnifiedReportRows(): number {
        const count = this.advancedSettingsService.get('MaxUnifiedReportRows', this.defaultNumberOfResultsToDisplayInView);
        if (count < 0) {
            return this.defaultNumberOfResultsToDisplayInView;
        }
        return count;
    }

    private async logInvestigateEvent(investigateContext: InvestigateContext) {
        // Get what filter analytics description
        const whatFilterAnalyticsDescription = await this.getSelectedDataTypesName();

        // Get when filter analytics description
        const whenFilter = this.dataContext?.whenFilter;

        // TODO: Get where filter analystics description when that filter will be refactored
        const filterMap = { what: whatFilterAnalyticsDescription, when: whenFilter, where: this.whereFilterDescription$, context: investigateContext };
        this.analyticsService.logEvent(AnalyticsNames.Investigate.ReportGenerated, filterMap);
    }

    private async getSelectedDataTypesName() {
        const whatFilterDataTypes = this.dataContext.whatFilter?.dataTypes;
        const dataTypes = await this.correlationService.getDataTypes();

        const whatFilterAnalyticsDescription = dataTypes
            ?.filter((dataTypeModel) => whatFilterDataTypes?.find((wFilter) => wFilter.equals(dataTypeModel.dataType)))
            .map((data) => ({ guid: data.dataType.toString(), friendlyName: data.name }));
        return whatFilterAnalyticsDescription ?? [];
    }

    private displayLastGeneratedReport() {
        const whatFilter = this.dataContext.whatFilter;
        if (whatFilter && whatFilter.dataTypes?.length > 0) {
            this.isSplashSreenOpen = false;
            this.investigate(InvestigateContext.Refresh).fireAndForget();
        }
    }

    private computeTimeFilter(): { timeStart: string | null; timeEnd: string | null } {
        if (this.dataContext?.whenFilter && this.dataContext.whenFilter.mode === DateFilterMode.DuringLastPeriod && this.dataContext.whenFilter.period) {
            const timeStartComputed = moment.utc().subtract(this.dataContext.whenFilter.period.duration, this.dataContext.whenFilter.period.durationUnit).toISOString() as
                | string
                | null;
            const timeEndComputed = moment.utc().toISOString() as string | null;
            return { timeStart: timeStartComputed, timeEnd: timeEndComputed };
        }
        const timeStart = this.dataContext.whenFilter?.timeStart ?? null;
        const timeEnd = this.dataContext.whenFilter?.timeEnd ?? null;
        return { timeStart, timeEnd };
    }

    private buildQueryArguments(): CorrelationQueryArguments {
        // build arguments
        const { timeStart, timeEnd } = this.computeTimeFilter();
        const args = new CorrelationQueryArguments({
            dataTypes: this.dataContext.whatFilter?.dataTypes?.map((item) => item) ?? [],
            locationFilter: new LocationFilter({
                intersection: this.dataContext.whereFilter?.intersection ?? true,
                whereMode: this.dataContext.whereFilter?.mode ?? WhereMode.Anywhere,
                includedAreas: this.dataContext.whereFilter?.includedAreas ?? null,
                excludedAreas: this.dataContext.whereFilter?.excludedAreas ?? null,
                wkt: this.dataContext.whereFilter?.wkt ?? undefined,
            }),
            parameters: this.dataContext.whatFilter?.parameters?.map((item) => item.toLightParameter()) ?? [],
            endTime: timeEnd,
            startTime: timeStart,
            maxResults: InvestigateTaskComponent.maxResultCount,
        });
        return args;
    }

    private async displayReportError(report: CorrelationQueryResult) {
        // the report has a message, display the toast
        if (report.error) {
            let flavor = ToastFlavor.Information;
            let icon = MeltedIcon.None;
            if (report.errorSeverity === ErrorSeverity.Warning) {
                flavor = ToastFlavor.Warning;
                icon = MeltedIcon.Warning;
            } else if (report.errorSeverity === ErrorSeverity.Error) {
                flavor = ToastFlavor.Error;
                icon = MeltedIcon.Error;
            }
            let toasted = false;
            if (report.source && !report.source.equals(SafeGuid.EMPTY)) {
                // retrieve the source entity and display it with the error
                const client = this.securityCenterClientService?.scClient;
                if (client) {
                    const entityName = await client.getEntityAsync(Entity, report.source, null, null, EntityFields.nameField);
                    if (entityName) {
                        this.notificationService.show({
                            text: `(${entityName.name}) ${report.error}`,
                            flavor,
                            icon,
                            secondaryText: report.errorMessage ? report.errorMessage : undefined,
                        });
                        toasted = true;
                    }
                }
            }
            if (!toasted) {
                this.notificationService.show({
                    text: report.error,
                    flavor,
                    icon,
                    secondaryText: report.errorMessage ? report.errorMessage : undefined,
                });
            }
        }
    }

    private setReportResults(report: CorrelationQueryResult) {
        const data = this.resultsContainerComponent.getReportData(report);
        if (data?.length > 0) {
            this.lastQueriedResults = data;
            this.resultsContainerComponent.setResults(data.slice(0, this.maxNumberOfResultsToDisplayInView));
        } else {
            this.lastQueriedResults = [];
            this.resultsContainerComponent.setResults([]);
        }
    }

    private async updateSidePane(contentSourceData: FieldObject) {
        if (contentSourceData) {
            const content = await this.contentProviderService.getContentFromFieldsAsync(contentSourceData, InvestigateTaskComponent.pluginId);
            this.contextDataService?.setMainContent(content ? content : undefined);
        }
    }

    //#endregion
}
