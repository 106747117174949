import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { GenMeltedModalComponent, MeltedModalAction } from '@genetec/gelato-angular';
import { isNullOrEmpty } from 'RestClient/Helpers/Helpers';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';

@Component({
    selector: 'app-bookmark',
    templateUrl: './bookmark.component.html',
    styleUrls: ['./bookmark.component.scss'],
})
export class BookmarkComponent extends ConnectionAwareModalComponent implements OnInit {
    @ViewChild(GenMeltedModalComponent) public genModalComponent!: GenMeltedModalComponent;

    public message = '';
    public isValid = false;
    public protectVideo = false;

    constructor(
        injector: Injector,
        // private activatedRoute: ActivatedRoute,
        authService: AuthService,
        trackingService: TrackingService
    ) {
        super(authService, injector, trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        // this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
        //     if (params !== undefined) {
        //         if (params['entityId'] !== undefined && params['timestamp'] !== undefined) {
        //             this.entityId = SafeGuid.parse(params['entityId']);
        //             this.timestamp = new Date(params['timestamp']);
        //         }
        //     }
        // });
    }

    public createBookmark(): void {
        // ensure we have a valid message
        if (this.message) {
            this.genModalComponent.genModalAction.emit(MeltedModalAction.Default);
            this.hide();
        }
    }

    public keyPressed(e: KeyboardEvent): void {
        if (e.code === 'Enter') {
            this.createBookmark();
        }
    }

    public hideModal(): void {}

    public onMessageChanged(): void {
        this.isValid = !isNullOrEmpty(this.message);
    }
}
