import { Injectable, OnDestroy } from '@angular/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { LoggerService } from '@shared/services/logger/logger.service';
import { List } from 'immutable';
import { isEqual } from 'lodash-es';
import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { BehaviorSubject, combineLatest, ConnectableObservable, defer, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, publishReplay, retryWhen, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import {
    AlterationType,
    DescriptionChangedEvent,
    ExternalIdChangedEvent,
    IncidentEvent,
    IncidentEventType,
    LocationChangedEvent,
    LocationClearedEvent,
    OwnershipChangedEvent,
    PriorityChangedEvent,
    ProcedureStepAnsweredEvent,
    RecipientAlteredEvent,
    StateChangedEvent,
    TypeChangedEvent,
} from '../../models/events/incident-event';
import { IncidentFilter } from '../../models/incident-filter';
import { IncidentSorting } from '../../models/incident-sorting';
import { MCIncident } from '../../models/mc-incident';
import { PossibleStateTransitionList } from '../../models/possible-state-transition-list';
import { ServiceState as OperationState, ServiceState, StateEnum } from '../../models/service-state';
import { retryWithConstantTime } from '../../utils/rxjs-utils';
import { McEventReceiverService } from '../events/mc-event-receiver.service';
import { StateService } from '../state/state.service';
import { IncidentApiService } from './incident-api.service';
import { IncidentHandler } from './incident-handler';
import { IncidentPipelineParameter } from './incident-pipeline-parameter';

const LIMIT = 100;

interface IncidentEntry {
    index: number;
    incident: MCIncident;
}

@Injectable()
export class IncidentService implements OnDestroy {
    private _fetchIncidentsOperationState$!: BehaviorSubject<OperationState>;
    private _serviceState$!: Observable<ServiceState>;
    private _currentOffset = 0;
    private _pipeline$ = new BehaviorSubject<IncidentPipelineParameter>(new IncidentPipelineParameter());
    private _totalIncidents$ = new BehaviorSubject<number>(0);
    private _refresh$ = new Subject<IncidentPipelineParameter>();
    private _stopObs$ = new Subject();

    constructor(
        private _eventReceiver: McEventReceiverService,
        private _incidentStateService: StateService,
        private _loggerService: LoggerService,
        private _incidentApiService: IncidentApiService,
        securityCenterProvider: SecurityCenterClientService
    ) {
        this.createServiceStateObservables();

        securityCenterProvider.scClient.onLogonStateChanged((args: LogonStateChangedArgs) => {
            if (args.loggedOn()) this.resetIncidentsPipeline();
        });

        const obs$ = this._refresh$.pipe(
            distinctUntilChanged((prev, curr) => {
                return prev.incidents === curr.incidents && isEqual(prev.filter, curr.filter) && prev.needRefresh === curr.needRefresh && prev.page === curr.page;
            }),
            switchMap((ipp) => {
                if (ipp.needRefresh) {
                    return this.fetchIncidents(ipp).pipe(map((incidents) => ipp.with({ incidents, needRefresh: false })));
                }

                return of(ipp);
            }),
            tap((_) => {
                if (this._fetchIncidentsOperationState$.value.state !== StateEnum.Running) this._fetchIncidentsOperationState$.next(new ServiceState(StateEnum.Running));
            }),
            takeUntil(this._stopObs$),
            shareReplay(1)
        );

        obs$.subscribe(this._pipeline$);
        const events$ = _eventReceiver.getIncidentEvents().pipe(
            takeUntil(this._stopObs$),
            withLatestFrom(obs$),
            tap(async ([events, incidentParameter]) => await this.handleIncidentEvent(events, incidentParameter))
        );
        events$.subscribe();
        this.resetIncidentsPipeline();
    }

    ngOnDestroy(): void {
        this._stopObs$.next();
    }

    public get state$(): Observable<ServiceState> {
        return this._serviceState$;
    }

    public get totalIncidents$(): Observable<number> {
        return this._totalIncidents$;
    }

    public getIncidents(): Observable<MCIncident[]> {
        return this._pipeline$.pipe(
            distinctUntilChanged((a, b) => a.incidents === b.incidents),
            map((x) => x.incidents.toArray())
        );
    }

    public getIncident = (id: IGuid): Observable<MCIncident | null> => this._incidentApiService.getIncident(id);

    public getPossibleStateTransitions = (id: IGuid): Observable<PossibleStateTransitionList | undefined> => this._incidentApiService.getPossibleStateTransitions(id);

    public applyFilter(filter: IncidentFilter): void {
        const parameters = this._pipeline$.getValue();
        this._refresh$.next(parameters.with({ filter, needRefresh: true, page: 0 }));
    }

    public applySorting(sorting: IncidentSorting): void {
        const parameters = this._pipeline$.getValue();
        this._refresh$.next(parameters.with({ sorting, needRefresh: true }));
    }

    public nextPage(): void {
        const parameters = this._pipeline$.getValue();
        if (this.isLastPage()) return;
        const page = parameters.page + 1;
        this._refresh$.next(parameters.with({ page, needRefresh: true }));
    }

    public previousPage(): void {
        const parameters = this._pipeline$.getValue();
        if (this.isFirstPage()) return;
        const page = parameters.page - 1;
        this._refresh$.next(parameters.with({ page, needRefresh: true }));
    }

    public getPage(): number {
        return this._pipeline$.getValue().page;
    }

    public isFirstPage(): boolean {
        const parameters = this._pipeline$.getValue();
        return parameters.page === 0;
    }

    public isLastPage(): boolean {
        const totalIncidents = this._totalIncidents$.getValue();
        return this._currentOffset === totalIncidents - LIMIT || totalIncidents <= LIMIT;
    }

    private createServiceStateObservables(): void {
        this._fetchIncidentsOperationState$ = new BehaviorSubject<ServiceState>(new ServiceState(StateEnum.Unintialized));

        this._serviceState$ = combineLatest([this._fetchIncidentsOperationState$, this._eventReceiver.state$]).pipe(
            map((states) => {
                const errors = states.filter((ss) => ss.state === StateEnum.ErrorRetrying || ss.state === StateEnum.ErrorUnrecoverable);
                return errors.length > 0 ? errors[0] : states[0];
            }),
            distinctUntilChanged((prev, curr) => prev.state === curr.state && prev.error?.message === curr.error?.message),
            takeUntil(this._stopObs$),
            publishReplay(1)
        );

        (this._serviceState$ as ConnectableObservable<ServiceState>).connect();
    }

    private fetchIncidents({ filter, page, sorting }: IncidentPipelineParameter): Observable<List<MCIncident>> {
        const calculateOffset = (): number => {
            // Pages have LIMIT length and are overlapping at the middle.
            const currentTotalIncidents = this._totalIncidents$.getValue();
            let offset = page * (LIMIT / 2);
            if (currentTotalIncidents > LIMIT && LIMIT + offset > currentTotalIncidents) offset = currentTotalIncidents - LIMIT;
            return offset;
        };
        this._currentOffset = calculateOffset();

        return defer(() => this._incidentApiService.getIncidents(filter, sorting, this._currentOffset)).pipe(
            catchError((err) => {
                this._fetchIncidentsOperationState$.next(ServiceState.fromError(err));
                return throwError(err);
            }),
            retryWhen(retryWithConstantTime('Fetch incidents')),
            tap(([_, totalCount]) => {
                if (totalCount) {
                    this._totalIncidents$.next(totalCount);
                }
            }),
            map(([incidents, _]) => incidents)
        );
    }

    private resetIncidentsPipeline() {
        this._refresh$.next(new IncidentPipelineParameter());
    }

    private async handleIncidentEvent(events: IncidentEvent[], pipelineParameter: IncidentPipelineParameter): Promise<void> {
        for (const event of events) {
            try {
                const handleListEvent = async (pipeline: IncidentPipelineParameter, incident: IncidentEntry | null): Promise<IncidentPipelineParameter> => {
                    switch (event?.type) {
                        case IncidentEventType.Created:
                            // Put at least one incident in the list to force refresh
                            return pipelineParameter.with({ needRefresh: true, incidents: List.of({} as MCIncident) });
                        case IncidentEventType.StateChanged:
                            return this.handleListStateChange(incident, event as StateChangedEvent, pipeline);
                        case IncidentEventType.RecipientsAltered:
                            return await this.handleListRecipientChange(incident, event as RecipientAlteredEvent, pipeline);
                        case IncidentEventType.PriorityChanged:
                            return this.handlePriorityChange(incident, event as PriorityChangedEvent, pipeline);
                        case IncidentEventType.LocationChanged:
                            return this.handleLocationChange(incident, event as LocationChangedEvent, pipeline);
                        case IncidentEventType.DescriptionChanged:
                            return this.handleDescriptionChange(incident, event as DescriptionChangedEvent, pipeline);
                        case IncidentEventType.ExternalIdChanged:
                            return this.handleExternalIdChange(incident, event as ExternalIdChangedEvent, pipeline);
                        case IncidentEventType.ProcedureStepAnswered:
                            return this.handleProcedureStepAnswered(incident, event as ProcedureStepAnsweredEvent, pipelineParameter);
                        case IncidentEventType.OwnershipChanged:
                            return this.handleOwnershipChange(incident, event as OwnershipChangedEvent, pipeline);
                        case IncidentEventType.LocationCleared:
                            return this.handleClearLocation(incident, event as LocationClearedEvent, pipeline);
                        case IncidentEventType.IncidentTypeChanged:
                            return await this.handleListIncidentTypeChange(incident, event as TypeChangedEvent, pipeline);
                    }
                    return pipeline;
                };

                if (!pipelineParameter.needRefresh) {
                    const entry = pipelineParameter.incidents.findEntry((i) => i.id.equals(event.incidentId));
                    const incidentToModify: IncidentEntry | null = entry ? { index: entry[0], incident: entry[1] } : null;
                    pipelineParameter = await handleListEvent(pipelineParameter, incidentToModify);
                }

                const quarterLimit = LIMIT / 4;
                // Ensure list have enought elements when a lots of incidents are deleted at once.
                if (pipelineParameter.incidents.count() <= quarterLimit && this._totalIncidents$.getValue() > quarterLimit)
                    pipelineParameter = pipelineParameter.with({ needRefresh: true });
            } catch (err) {
                this._loggerService.traceError(`Error ${err as string} occurred while processing event ${JSON.stringify(event)}.`);
            }
        }
        this._refresh$.next(pipelineParameter);
    }

    private handleListStateChange(incidentToModify: IncidentEntry | null, event: StateChangedEvent, incidentParameter: IncidentPipelineParameter): IncidentPipelineParameter {
        const states = incidentParameter.filter.stateIds.count() === 0 ? this._incidentStateService.getActiveStatesIds : incidentParameter.filter.stateIds;
        return this.handleEventWithFilter(incidentToModify, incidentParameter, event, event.stateId, states, IncidentHandler.stateChange);
    }

    private handlePriorityChange(incidentToModify: IncidentEntry | null, event: PriorityChangedEvent, incidentParameter: IncidentPipelineParameter): IncidentPipelineParameter {
        return this.handleEventWithFilter(incidentToModify, incidentParameter, event, event.priorityId, incidentParameter.filter.priorityIds, IncidentHandler.priorityChange);
    }

    private handleLocationChange(incidentToModify: IncidentEntry | null, event: LocationChangedEvent, incidentParameter: IncidentPipelineParameter): IncidentPipelineParameter {
        return this.handleEventWithFilter(
            incidentToModify,
            incidentParameter,
            event,
            event.location.entityId,
            incidentParameter.filter.locationIds,
            IncidentHandler.locationChange
        );
    }

    private handleDescriptionChange(
        incidentToModify: IncidentEntry | null,
        event: DescriptionChangedEvent,
        incidentParameter: IncidentPipelineParameter
    ): IncidentPipelineParameter {
        if (incidentToModify) {
            incidentParameter = incidentParameter.with({
                incidents: incidentParameter.incidents.set(incidentToModify.index, IncidentHandler.descriptionChange(incidentToModify.incident, event)),
            });
        }
        return incidentParameter;
    }

    private handleExternalIdChange(incidentToModify: IncidentEntry | null, event: ExternalIdChangedEvent, incidentParameter: IncidentPipelineParameter): IncidentPipelineParameter {
        if (incidentToModify) {
            incidentParameter = incidentParameter.with({
                incidents: incidentParameter.incidents.set(incidentToModify.index, IncidentHandler.externalIdChange(incidentToModify.incident, event)),
            });
        }
        return incidentParameter;
    }

    private handleProcedureStepAnswered(
        incidentToModify: IncidentEntry | null,
        event: ProcedureStepAnsweredEvent,
        incidentParameter: IncidentPipelineParameter
    ): IncidentPipelineParameter {
        if (incidentToModify) {
            incidentParameter = incidentParameter.with({
                incidents: incidentParameter.incidents.set(incidentToModify.index, IncidentHandler.currentStepChange(incidentToModify.incident, event)),
            });
        }
        return incidentParameter;
    }

    private handleOwnershipChange(incidentToModify: IncidentEntry | null, event: OwnershipChangedEvent, incidentParameter: IncidentPipelineParameter): IncidentPipelineParameter {
        return this.handleEventWithFilter(incidentToModify, incidentParameter, event, event.owner ?? null, incidentParameter.filter.ownerIds, IncidentHandler.ownerChange);
    }

    private handleClearLocation(incidentToModify: IncidentEntry | null, event: IncidentEvent, incidentParameter: IncidentPipelineParameter): IncidentPipelineParameter {
        return this.handleEventWithFilter(incidentToModify, incidentParameter, event, null, incidentParameter.filter.locationIds, IncidentHandler.clearLocation);
    }

    private async handleListIncidentTypeChange(
        incidentToModify: IncidentEntry | null,
        event: TypeChangedEvent,
        incidentParameter: IncidentPipelineParameter
    ): Promise<IncidentPipelineParameter> {
        if (!incidentToModify) return incidentParameter;
        const newIncident = await this.getIncident(incidentToModify.incident.id).toPromise();
        if (newIncident) {
            const incident = IncidentHandler.incidentTypeChange(incidentToModify.incident, newIncident);
            return incidentParameter.with({ incidents: incidentParameter.incidents.set(incidentToModify.index, incident) });
        } else {
            this.decrementTotalIncident();
            return incidentParameter.with({ incidents: incidentParameter.incidents.delete(incidentToModify.index) });
        }
    }

    private async handleListRecipientChange(
        incidentToModify: IncidentEntry | null,
        event: RecipientAlteredEvent,
        incidentParameter: IncidentPipelineParameter
    ): Promise<IncidentPipelineParameter> {
        if (!incidentToModify) {
            return incidentParameter.with({ needRefresh: true });
        }
        incidentToModify.incident = incidentToModify.incident.with({ recipientsMode: event.recipientsMode });
        switch (event.alterationType) {
            case AlterationType.Dispatch:
            case AlterationType.Forward: {
                return incidentParameter.with({
                    incidents: incidentParameter.incidents.set(incidentToModify.index, IncidentHandler.recipientChange(incidentToModify.incident, event)),
                });
            }
            case AlterationType.Transfer: {
                const newIncident = await this.getIncident(incidentToModify.incident.id).toPromise();
                if (!newIncident) {
                    this.decrementTotalIncident();
                    return incidentParameter.with({ incidents: incidentParameter.incidents.delete(incidentToModify.index) });
                }
                return incidentParameter.with({ incidents: incidentParameter.incidents.set(incidentToModify.index, newIncident) });
            }
        }
        return incidentParameter;
    }

    private handleEventWithFilter(
        incidentToModify: IncidentEntry | null,
        incidentParameter: IncidentPipelineParameter,
        event: IncidentEvent,
        eventObjectId: IGuid | null,
        filterIds: List<IGuid>,
        actionToModify: (incident: MCIncident, event: any) => MCIncident
    ): IncidentPipelineParameter {
        const filtersAsString = filterIds.map((f) => f.toString().toUpperCase());
        const eventObjectIdsAsString = eventObjectId?.toString()?.toUpperCase() ?? '';
        if (!incidentToModify) {
            if (filtersAsString.includes(eventObjectIdsAsString) || event.isEventForSort(incidentParameter.sorting.parameter)) {
                return incidentParameter.with({ needRefresh: true });
            }
        } else if (
            (filterIds.count() > 0 && !filtersAsString.includes(eventObjectIdsAsString)) ||
            (!incidentParameter.sorting.isDefault() && event.isEventForSort(incidentParameter.sorting.parameter))
        ) {
            this.decrementTotalIncident();
            return incidentParameter.with({ incidents: incidentParameter.incidents.delete(incidentToModify.index) });
        } else {
            const incident = actionToModify(incidentToModify.incident, event);
            incidentParameter = incidentParameter.with({
                incidents: incidentParameter.incidents.set(incidentToModify.index, incident),
            });
        }
        return incidentParameter;
    }

    private decrementTotalIncident(): void {
        this._totalIncidents$.next(this._totalIncidents$.getValue() - 1);
    }
}
