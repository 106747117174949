import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const TilesConstants = Constants({
    TilesContentContextId: SafeGuid.parse('DB8C7286-07E1-41F6-B54B-037DF302C0F9'),
});
