
import { EventBase } from "./EventBase";

export class EventTypeProvider {
  // tslint:disable-next-line: variable-name
  EventType!: string;
}

export class MultiEvent extends EventBase {
  public get events(): Array<EventTypeProvider> {
    const events = this.getField<string>("Events");
    return JSON.parse(events);
  }
}
