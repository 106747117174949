import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { GenPopup, GenTooltip } from '@genetec/gelato-angular';
import { TooltipService } from '@modules/shared/services/tooltip/tooltip.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'app-app-tooltip',
    templateUrl: './app-tooltip.component.html',
    styleUrls: ['./app-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTooltipComponent {
    @ViewChild(GenPopup) genPopup!: GenPopup;
    @ViewChild(GenTooltip) genTooltip!: GenTooltip;

    public message$: Observable<string>;
    private messageSubject = new BehaviorSubject<string>('');

    constructor(tooltipService: TooltipService, elementRef: ElementRef<HTMLElement>, renderer: Renderer2) {
        this.message$ = this.messageSubject.asObservable();
        tooltipService.registerTooltip(this, elementRef, renderer);
    }

    public show(message: string, targetElement: HTMLElement): void {
        this.messageSubject.next(message);
        this.genPopup.targetElement = targetElement;
        this.genPopup.show().fireAndForget();
    }

    public close(): void {
        this.genPopup.close().fireAndForget();
    }
}
