import { RestObject } from '../Model/RestObject';
import { IEntityManager } from '../Interface/IEntityManager';

export class EntityManager extends RestObject implements IEntityManager {
  // #region Constructor

  constructor(uri: string) {
    super(uri);
  } // #endregion Constructor
}
