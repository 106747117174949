// Autogenerated: v2/help/Entities/ApiHotlist?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:17 PM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ContentFileEntity } from './ContentFileEntity';
import { IHotlistEntity, HotlistEntityFields } from '../../Interface/IHotlistEntity';

export class HotlistEntity extends ContentFileEntity implements IHotlistEntity {
    // #region REST Properties

    // Color
    public get color(): string {
        return this.getField<string>(HotlistEntityFields.colorField);
    }
    public set color(value: string) {
        this.setField<string>(HotlistEntityFields.colorField, value);
    }

    // DownloadOnlyAtLogon
    public get downloadOnlyAtLogon(): boolean {
        return this.getField<boolean>(HotlistEntityFields.downloadOnlyAtLogonField);
    }
    public set downloadOnlyAtLogon(value: boolean) {
        this.setField<boolean>(HotlistEntityFields.downloadOnlyAtLogonField, value);
    }

    // EmailNotificationAddress
    public get emailNotificationAddress(): string {
        return this.getField<string>(HotlistEntityFields.emailNotificationAddressField);
    }
    public set emailNotificationAddress(value: string) {
        this.setField<string>(HotlistEntityFields.emailNotificationAddressField, value);
    }

    // ExpeditiveTransfer
    public get expeditiveTransfer(): boolean {
        return this.getField<boolean>(HotlistEntityFields.expeditiveTransferField);
    }
    public set expeditiveTransfer(value: boolean) {
        this.setField<boolean>(HotlistEntityFields.expeditiveTransferField, value);
    }

    // HitPriority
    public get hitPriority(): number {
        return this.getField<number>(HotlistEntityFields.hitPriorityField);
    }
    public set hitPriority(value: number) {
        this.setField<number>(HotlistEntityFields.hitPriorityField, value);
    }

    // IsCovert
    public get isCovert(): boolean {
        return this.getField<boolean>(HotlistEntityFields.isCovertField);
    }
    public set isCovert(value: boolean) {
        this.setField<boolean>(HotlistEntityFields.isCovertField, value);
    }

    // OverridePrivacy
    public get overridePrivacy(): boolean {
        return this.getField<boolean>(HotlistEntityFields.overridePrivacyField);
    }
    public set overridePrivacy(value: boolean) {
        this.setField<boolean>(HotlistEntityFields.overridePrivacyField, value);
    }

    // SoundFile
    public get soundFile(): string {
        return this.getField<string>(HotlistEntityFields.soundFileField);
    }
    public set soundFile(value: string) {
        this.setField<string>(HotlistEntityFields.soundFileField, value);
    }

    // UseWildcards
    public get useWildcards(): boolean {
        return this.getField<boolean>(HotlistEntityFields.useWildcardsField);
    }
    public set useWildcards(value: boolean) {
        this.setField<boolean>(HotlistEntityFields.useWildcardsField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Hotlists);
    }

    // #endregion Constructor

}

