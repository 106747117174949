// Autogenerated: v2/help/enumerations/ApiAccessPointType?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class AccessPointType {
    public static None = 'None';
    public static CardReader = 'CardReader';
    public static Rex = 'Rex';
    public static DoorSensor = 'DoorSensor';
    public static AreaDirection = 'AreaDirection';
    public static PullStation = 'PullStation';
    public static DoorLock = 'DoorLock';
    public static Zone = 'Zone';
    public static Floor = 'Floor';
    public static Buzzer = 'Buzzer';
    public static FloorInput = 'FloorInput';
    public static FloorOutput = 'FloorOutput';
    public static EntrySensor = 'EntrySensor';
    public static PlateReader = 'PlateReader';
    public static IntrusionAreaArming = 'IntrusionAreaArming';
    public static IntrusionAreaDirection = 'IntrusionAreaDirection';
    public static LockSensor = 'LockSensor';
    public static OperatorConfirmation = 'OperatorConfirmation';

    public static readonly values = [
        AccessPointType.None,
        AccessPointType.CardReader,
        AccessPointType.Rex,
        AccessPointType.DoorSensor,
        AccessPointType.AreaDirection,
        AccessPointType.PullStation,
        AccessPointType.DoorLock,
        AccessPointType.Zone,
        AccessPointType.Floor,
        AccessPointType.Buzzer,
        AccessPointType.FloorInput,
        AccessPointType.FloorOutput,
        AccessPointType.EntrySensor,
        AccessPointType.PlateReader,
        AccessPointType.IntrusionAreaArming,
        AccessPointType.IntrusionAreaDirection,
        AccessPointType.LockSensor,
        AccessPointType.OperatorConfirmation
    ];
}
