// Autogenerated: CodeGen/EntityBrowserController?language=ts&InterfaceOnly=True on 2021-06-21 12:13:15 PM
// Autogenerated: CodeGen/EntityBrowserController?language=ts&InterfaceOnly=False on 2021-06-21 12:13:15 PM

import { IGuid, SafeGuid } from 'safeguid';
import { EntityBrowserFilterData } from '../../entity-browser/filters/entity-browser-filter.data';
import { IRestObject } from 'RestClient/Client/Interface/IRestObject';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { RestObject } from 'RestClient/Client/Model/RestObject';
import { IEntityBrowserResult, EntityBrowserResult } from '../../entity-browser/interfaces/entity-browser-result';
import { IEntityBrowserFilterData } from '../../entity-browser/interfaces/filters/entity-browser-filter-data.interface';
import { RegisterFieldObjectConstructor, RegisterFieldObjectOverload } from 'RestClient/Helpers/FieldObjectOverloads';
import { HierarchicalData, IHierarchicalData } from '../../models/hierarchical-data';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { EntityTypeData, IEntityTypeData } from '../../models/entity-type-data';

export interface IEntityBrowserData extends IHierarchicalData {
    componentId: IGuid;
}

export interface IEntityBrowserExpandData extends IEntityBrowserData {
    entityId: IGuid;
    filter: IEntityBrowserFilterData;
}

export interface IEntityBrowserRefreshData extends IEntityBrowserData {
    isFullRefresh: boolean;
    filter: IEntityBrowserFilterData;
}

export class EntityBrowserData extends HierarchicalData implements IEntityBrowserData {
    // ComponentId
    public get componentId(): IGuid {
        return this.getFieldGuid('ComponentId');
    }
    public set componentId(value: IGuid) {
        this.setFieldGuid('ComponentId', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('ComponentId', SafeGuid.EMPTY);
    }
}

@RegisterFieldObjectConstructor
@RegisterFieldObjectOverload('EntityBrowserData', 'EntityBrowserExpandData')
export class EntityBrowserExpandData extends EntityBrowserData implements IEntityBrowserExpandData {
    // EntityId
    public get entityId(): IGuid {
        return this.getFieldGuid('EntityId');
    }
    public set entityId(value: IGuid) {
        this.setFieldGuid('EntityId', value);
    }
    // Filter
    public get filter(): IEntityBrowserFilterData {
        return this.getFieldObject<EntityBrowserFilterData, IEntityBrowserFilterData>(EntityBrowserFilterData, 'Filter');
    }
    public set filter(value: IEntityBrowserFilterData) {
        this.setFieldObject<IEntityBrowserFilterData>('Filter', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('EntityId', SafeGuid.EMPTY);
        const objFilter = new EntityBrowserFilterData();
        objFilter.initializeAllFields();
        this.setFieldObject('Filter', objFilter);
    }
}

@RegisterFieldObjectConstructor
@RegisterFieldObjectOverload('EntityBrowserData', 'EntityBrowserRefreshData')
export class EntityBrowserRefreshData extends EntityBrowserData implements IEntityBrowserRefreshData {
    // IsFullRefresh
    public get isFullRefresh(): boolean {
        return this.getField<boolean>('IsFullRefresh');
    }
    public set isFullRefresh(value: boolean) {
        this.setField<boolean>('IsFullRefresh', value);
    }
    // Filter
    public get filter(): IEntityBrowserFilterData {
        return this.getFieldObject<EntityBrowserFilterData, IEntityBrowserFilterData>(EntityBrowserFilterData, 'Filter');
    }
    public set filter(value: IEntityBrowserFilterData) {
        this.setFieldObject<IEntityBrowserFilterData>('Filter', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<boolean>('IsFullRefresh', false);
        const objFilter = new EntityBrowserFilterData();
        objFilter.initializeAllFields();
        this.setFieldObject('Filter', objFilter);
    }
}

export interface IEntityBrowserController extends IRestObject {
    // #region Rest Methods

    getExpandAsync(query: IEntityBrowserExpandData, transaction?: ITransaction | null): Promise<IEntityBrowserResult | null>;
    getLogicalTypesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEntityTypeData> | null>;
    getRefreshAsync(query: IEntityBrowserRefreshData, transaction?: ITransaction | null): Promise<IEntityBrowserResult | null>;

    // #endregion Rest Methods

}

export class EntityBrowserController extends RestObject implements IEntityBrowserController {
    constructor() {
        super('api/EntityBrowser');
        this._canGet = false;
    }

    // #region Rest Methods

    public async getExpandAsync(query: IEntityBrowserExpandData, transaction?: ITransaction | null): Promise<IEntityBrowserResult | null> {
        return await this.getDataRelationAsync<EntityBrowserResult>(EntityBrowserResult, 'Expand' + '?' + query.toQueryString(), false, null, transaction);
    }

    public async getLogicalTypesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEntityTypeData> | null> {
        return this.getFieldObjectRelationAsync<EntityTypeData, IEntityTypeData>(EntityTypeData, 'LogicalTypes', 'Id', true, false, null, transaction);
    }

    public async getRefreshAsync(query: IEntityBrowserRefreshData, transaction?: ITransaction | null): Promise<IEntityBrowserResult | null> {
        return await this.getDataRelationAsync<EntityBrowserResult>(EntityBrowserResult, 'Refresh' + '?' + query.toQueryString(), false, null, transaction);
    }

    // #endregion Rest Methods

}
