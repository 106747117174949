import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WebSocketResponse } from './web-socket-response';

export const WEB_SOCKET_SERVICE = new InjectionToken<WebSocketService>('Service to use the web WebSocket');

export interface WebSocketService {
    /**
     * Observable for WebSocket responses
     */
    response$: Observable<WebSocketResponse>;
    /**
     *
     * @param msgType - type of the message to send to the backend
     * @param payload - payload of the message
     */
    writeToWebSocket(msgType: string, payload?: object | string | number): void;
}
