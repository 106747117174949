import { defer, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export enum FinalizeReason {
    Unsubscribe = 'unsubscribe',
    NoMoreSubscribers = 'noMoreSubscribers',
    Complete = 'complete',
    Error = 'error',
}

/**
 * Based on the finalize() operator, but will tell the reason why the observable was finalized.
 *
 * @example
 * observable$.pipe(
 *  finalizeWithReason((reason) => {
 *      if (reason === FinalizeReason.Complete) {
 *          // observable was completed
 *      } else if (reason === FinalizeReason.NoMoreSubscribers) {
 *          // last observer unsubscribed from the observable
 *      }
 * }))
 */
export const finalizeWithReason =
    <T>(callback: (reason: FinalizeReason) => void): MonoTypeOperatorFunction<T> =>
    (source$: Observable<T>) => {
        let finalized = false;
        let numberOfSubscribers = 0;
        let errored = false;
        let completed = false;
        return defer(() => {
            numberOfSubscribers++;
            return source$.pipe(
                tap({
                    error: () => (errored = true),
                    complete: () => (completed = true),
                }),
                finalize(() => {
                    if (finalized) {
                        return;
                    }
                    numberOfSubscribers--;
                    if (errored) {
                        callback(FinalizeReason.Error);
                        finalized = true;
                    } else if (completed) {
                        callback(FinalizeReason.Complete);
                        finalized = true;
                    } else if (numberOfSubscribers === 0) {
                        callback(FinalizeReason.NoMoreSubscribers);
                    } else {
                        callback(FinalizeReason.Unsubscribe);
                    }
                })
            );
        });
    };
