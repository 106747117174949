<div>
    <gen-menu #contextMenu [positionX]="contextMenuPositionX" [positionY]="contextMenuPositionY" [appMenuDataSource]="contextMenuItemsSource"> </gen-menu>
    <gen-card class="w-100 h-100">
        <gen-card-body class="w-100">
            <div class="p-1">
                <app-breadcrumbs [breadcrumbs]="breadcrumbs" [home]="home" (crumbSelected)="breadcrumbClicked($event)" [truncation]="15" [levelLimit]="3"> </app-breadcrumbs>
            </div>
            <div style="display: block">
                <canvas
                    baseChart
                    [datasets]="chartData"
                    [labels]="chartLabels"
                    [options]="chartOptions"
                    [plugins]="chartPlugins"
                    [legend]="chartLegend"
                    [colors]="chartColors"
                    [chartType]="chartType"
                >
                </canvas>
            </div>
        </gen-card-body>
        <gen-card-footer>
            <div style="display: flex">
                <div>
                    <gen-button appTooltip="{{ 'STE_LABEL_SETTINGS' | translate }}" [flavor]="ButtonFlavor.Flat" (click)="onSettingsClick()">
                        <gen-icon [icon]="Icon.Cog"></gen-icon>
                    </gen-button>
                </div>
                <div>
                    <gen-combobox [ngModel]="selectedChartType" (ngModelChange)="onSelectedChartTypeChanged($event)" [genItemsSource]="chartTypes"> </gen-combobox>
                </div>
                <div style="flex-grow: 1; text-align: right">
                    <gen-button appTooltip="{{ 'STE_TOOLTIP_BUTTON_OPEN_REPORT' | translate }}" (click)="onOpenReport()">
                        <gen-icon [icon]="Icon.Table"></gen-icon>
                        {{ 'STE_BUTTON_OPEN_REPORT' | translate }}
                    </gen-button>
                    <gen-button appTooltip="{{ 'STE_BUTTON_REFRESH' | translate }}" [flavor]="ButtonFlavor.Flat" (click)="refresh()">
                        <gen-icon [icon]="Icon.Reload"></gen-icon>
                    </gen-button>
                </div>
            </div>
        </gen-card-footer>
    </gen-card>
</div>
