<div class="fg-color-primary va-top">
    <div class="item-body">
        <gen-item class="event-item">
            <gen-icon [icon]="icon"></gen-icon>
            <gen-text>{{ alarmName }}</gen-text>
            <gen-text [slot]="ItemSlot.TextSecondary">{{ timestamp }}</gen-text>
        </gen-item>

        <app-command-buttons [commandsUsage]="commandsUsage" [maximumButtonCount]="3"></app-command-buttons>
    </div>
</div>
