import { MeltedIcon } from '@genetec/gelato-angular';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { Constants } from '@src/constants';
import { IGuid } from 'safeguid';
import { PluginCommand } from '../../../interfaces/plugins/public/plugin-public.interface';
import { CommandContext } from '../../../interfaces/plugins/public/plugin-services-public.interface';

export class CommandUsage {
    public icon: MeltedIcon;
    public id: IGuid;
    public name: () => string;
    public tooltip: () => string;
    public color: () => string;
    public isAllowed?: () => boolean;

    public get canExecute(): boolean {
        return this.canExecuteInternal;
    }

    public set canExecute(value: boolean) {
        if (value && !this.canExecuteInternal) {
            this.contextMenuRefreshRequired = true;
        }
        this.canExecuteInternal = value;
    }

    private canExecuteInternal = false;
    private contextMenuRefreshRequired = false;

    constructor(
        public readonly command: PluginCommand,
        public readonly commandContext: CommandContext,
        public readonly execute: () => void,
        public readonly targetElement?: Element
    ) {
        this.id = command.id;
        this.icon = command.icon ?? MeltedIcon.None;
        this.name = command.name;
        this.tooltip = command.tooltip ? command.tooltip : () => '';
        this.color = command.color ? command.color : () => '';
    }

    public createMenuItem(): ContextMenuItem {
        // build the kyboard shortcut's text
        let secondaryText = '';
        if (this.command.keyBinding) {
            const parts = this.command.keyBinding.split('.');
            for (let i = 0; i < parts.length; ++i) {
                // try to convert modifiers
                const text = parts[i].toLocaleLowerCase();
                let modifier = '';
                switch (text) {
                    case 'alt':
                        {
                            modifier = Constants.altText;
                        }
                        break;

                    case 'ctrl':
                    case 'control':
                        {
                            modifier = Constants.ctrlText;
                        }
                        break;

                    case 'shift':
                        {
                            modifier = Constants.shiftText;
                        }
                        break;
                }

                if (modifier) {
                    secondaryText += modifier;
                } else if (text.length === 1) {
                    // single key are always upper case
                    secondaryText += text.toUpperCase();
                } else {
                    secondaryText += parts[i];
                }

                if (i < parts.length - 1) {
                    secondaryText += '+';
                }
            }
        }

        return {
            id: this.id.toString(),
            text: this.name(),
            icon: this.icon,
            iconColor: this.color(),
            secondaryText,
            actionItem: {
                execute: () => this.execute(),
                isAllowed: this.isAllowed ?? (() => this.canExecute),
            },
            isHidden: (item: ContextMenuItem) => {
                if (this.contextMenuRefreshRequired) {
                    item.text = this.name();
                    item.icon = this.icon;
                    this.contextMenuRefreshRequired = false;
                }
                return !this.canExecute;
            },
        };
    }
}
