// Autogenerated: v2/help/Entities/ApiDirectoryFailoverRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class DirectoryFailoverRoleEntityFields extends RoleEntityFields {
    public static forceDirectoryOnMasterField = 'ForceDirectoryOnMaster';
}

// #endregion Fields

// #region Inner classes

export interface IDirectoryFailoverRoleServer extends IFieldObject {
    id: IGuid;
    isGatewayOnly: boolean;
    isDisasterRecovery: boolean | null;
}

// #endregion Inner classes

export interface IDirectoryFailoverRoleEntity extends IRoleEntity {
    // #region REST Properties

    forceDirectoryOnMaster: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getServersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDirectoryFailoverRoleServer> | null>;
    getValidationKeyAsync(servers: ObservableCollection<IGuid>, transaction?: ITransaction): Promise<string | null>;

    // #endregion REST Methods

}

