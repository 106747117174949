import { Coords } from '../utils/Coords';
import { NormalizedCoords } from '../utils/NormalizedCoords';
import { Utils } from '../utils/Utils';
import { CameraPositionBuilder, ICameraPosition } from './CameraPosition';
import { IProjection, ProjectionBuilder } from './Projection';

/** @beta */
export enum Projection {
  None = 'None',
  Orthographic = 'Orthographic',
  Equidistant = 'Equidistant',
  Equisolid = 'Equisolid',
  Stereographic = 'Stereographic',
}

/** @beta */
export enum CameraPosition {
  Wall = 'Wall',
  Ceiling = 'Ceiling',
  Floor = 'Floor'
}

/** @beta */
export class DewarperSourceImageParameters {
  public static readonly None: DewarperSourceImageParameters = new DewarperSourceImageParameters(Projection.Orthographic, CameraPosition.Wall, 0, 0, Coords.Origin);

  private readonly m_projection: Projection;
  private readonly m_cameraPosition: CameraPosition;
  private readonly m_fovInDegrees: number = 180;
  private readonly m_radiusRatio: number;
  private readonly m_circleCenter: Coords;

  public get Projection(): Projection {
    return this.m_projection;
  }

  public get CameraPosition(): CameraPosition {
    return this.m_cameraPosition;
  }

  public get FovInDegrees(): number {
    return this.m_fovInDegrees;
  }

  //Ratio of the radius according to the height of the image
  public get RadiusRatio(): number {
    return this.m_radiusRatio;
  }

  public get CircleCenter(): Coords {
    return this.m_circleCenter;
  }

  constructor(projection: Projection, cameraPosition: CameraPosition, fovInDegree: number, radiusRatio: number, circleCenter: Coords) {
    this.m_projection = projection;
    this.m_cameraPosition = cameraPosition;
    this.m_fovInDegrees = fovInDegree;

    this.m_radiusRatio = radiusRatio;
    this.m_circleCenter = circleCenter;
  }

  public toString(): string {
    return this.debugStatus(0);
  }

  public debugStatus(indent: number): string {
    return 'DewarperSourceImageParameters: ' + Utils.indentNewLine(indent) +
      'Position: ' + this.m_cameraPosition.toString() + Utils.indentNewLine(indent) +
      'Projection: ' + this.m_projection.toString() + ` fov: ${this.m_fovInDegrees}°` + Utils.indentNewLine(indent) +
      `Center and radius: ${this.m_circleCenter.toString()} ${this.m_radiusRatio}`;
  }

  // Expected dewarper configuration
  //{
  //  "DewarperConfiguration": {
  //    "Projection":"Stereographic",
  //    "CameraPosition":"Floor",
  //    "Fov":200,
  //    "RadiusRatio":0.8,
  //    "CircleCenter": {
  //      "X": 0.5,
  //      "Y": 0.6
  //    }
  //  }
  //}
  public static deserialize(serialized: any): DewarperSourceImageParameters {
    if (serialized === undefined || serialized === null) {
      return DewarperSourceImageParameters.None;
    }

    const projection: Projection = serialized.Projection;
    if (!Object.values(Projection).includes(projection)) {
      throw new Error(`Unknown projection ${projection}`);
    }

    const cameraPosition: CameraPosition = serialized.CameraPosition;
    if (!Object.values(CameraPosition).includes(cameraPosition)) {
      throw new Error(`Unknown camera position ${cameraPosition}`);
    }

    const fovInDegree: number = serialized.Fov;
    const radiusRatio: number = serialized.RadiusRatio;

    if (projection === undefined ||
      cameraPosition === undefined ||
      fovInDegree === undefined ||
      radiusRatio === undefined ||
      serialized.CircleCenter === undefined ||
      serialized.CircleCenter.X === undefined ||
      serialized.CircleCenter.Y === undefined) {
      throw new Error(`Incompatible dewarper configuration. Dewarping will not be available: ${serialized}`);
    }

    return new DewarperSourceImageParameters(projection, cameraPosition, fovInDegree, radiusRatio, new NormalizedCoords(serialized.CircleCenter.X, serialized.CircleCenter.Y));
  }
}

export class InternalDewarperSourceImageParameters {
  public static readonly None: InternalDewarperSourceImageParameters = new InternalDewarperSourceImageParameters(Projection.Orthographic, CameraPosition.Wall, 0, 0, NormalizedCoords.Origin);

  private readonly m_projection: IProjection;
  private readonly m_cameraPosition: ICameraPosition;
  private readonly m_fovInDegrees: number = 180;
  private readonly m_radiusRatio: number;
  private readonly m_circleCenter: NormalizedCoords;

  public get Projection(): IProjection {
    return this.m_projection;
  }

  public get CameraPosition(): ICameraPosition {
    return this.m_cameraPosition;
  }

  public get FovInDegrees(): number {
    return this.m_fovInDegrees;
  }

  public get FovInRadians(): number {
    return this.m_fovInDegrees * Math.PI / 180.0;
  }

  //Ratio of the radius according to the height of the image
  public get RadiusRatio(): number {
    return this.m_radiusRatio;
  }

  public get CircleCenter(): NormalizedCoords {
    return this.m_circleCenter;
  }

  public static build(dewarperSourceImageParameters: DewarperSourceImageParameters) {
    return new InternalDewarperSourceImageParameters(dewarperSourceImageParameters.Projection, dewarperSourceImageParameters.CameraPosition, dewarperSourceImageParameters.FovInDegrees, dewarperSourceImageParameters.RadiusRatio,
      new NormalizedCoords(dewarperSourceImageParameters.CircleCenter.X, dewarperSourceImageParameters.CircleCenter.Y));
  }

  constructor(projection: Projection, cameraPosition: CameraPosition, fovInDegree: number, radiusRatio: number, circleCenter: NormalizedCoords) {
    this.m_projection = ProjectionBuilder.build(projection);
    this.m_cameraPosition = CameraPositionBuilder.build(cameraPosition);
    this.m_fovInDegrees = fovInDegree;

    this.m_radiusRatio = radiusRatio;
    this.m_circleCenter = circleCenter;
  }

  public toString(): string {
    return this.debugStatus(0);
  }

  public debugStatus(indent: number): string {
    return 'DewarperSourceImageParameters: ' + Utils.indentNewLine(indent) +
      'Position: ' + this.m_cameraPosition.toString() + Utils.indentNewLine(indent) +
      'Projection: ' + this.m_projection.toString() + ` fov: ${this.m_fovInDegrees}°` + Utils.indentNewLine(indent) +
      `Center and radius: ${this.m_circleCenter.toString()} ${this.m_radiusRatio}`;
  }
}
