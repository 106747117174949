import { InjectionToken } from '@angular/core';

/**
 * Utility fuctions to have a delay promise instead of using a callback
 *
 * @param timeToWait time in millisecond
 * @returns promise resolved when timeout
 */
export const delayAsPromise = (timeToWait: number): Promise<void> => {
    return new Promise((res) => setTimeout(res, timeToWait));
};

/**
 * Utility function to validate required inputs has been set
 *
 * @param inputs
 */
export const checkRequiredInputs = (inputs: Record<string, unknown>): void => {
    const undefinedInputKeys = Object.entries(inputs)
        .filter(([_, value]) => value === undefined) // use only value
        .map((key) => key); // use only key
    if (undefinedInputKeys.length) throw Error(`Inputs required : ${undefinedInputKeys.join(', ')}`);
};

export const WINDOW = new InjectionToken<Window>('Global window object');
