<ng-container *ngIf="!isLoading; else spinner">
    <div class="watchlist-actions">
        <gen-text class="items-count" [flavor]="TextFlavor.Secondary">{{ 'STE_LABEL_REPORT_ITEMS_COUNT_FORMAT' | translate | stringFormat: entities.length }}</gen-text>
        <gen-button [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_TOOLTIP_BUTTON_ADD' | translate }}" (click)="addItems()"><gen-icon [icon]="Icon.Plus"></gen-icon></gen-button>
    </div>

    <gen-table [selectionType]="SelectionType.None">
        <!-- Header -->
        <gen-table-row>
            <!-- Name -->
            <gen-table-column-header column-name="name-column" column-size="2.5fr">
                <gen-text>{{ 'STE_LABEL_NAME' | translate }}</gen-text>
            </gen-table-column-header>
            <!-- Flag -->
            <gen-table-column-header column-name="flag-column" [appTooltip]="'STE_MESSAGE_INFO_FLAGGED_ITEMS_TOP' | translate">
                <gen-item>
                    <gen-icon [icon]="Icon.Event"></gen-icon>
                    <gen-text>{{ 'STE_LABEL_FLAG' | translate }}</gen-text>
                </gen-item>
            </gen-table-column-header>
            <!-- Delete -->
            <gen-table-column-header column-name="delete-column"></gen-table-column-header>
        </gen-table-row>

        <!-- Rows -->
        <gen-table-row *ngFor="let entity of entities">
            <gen-table-data column-name="name-column">
                <app-entity-state-item [entity]="entity" [withExpander]="false" [showIcon]="true" [appTooltipOnlyIfOverflow]="true"></app-entity-state-item>
            </gen-table-data>
            <gen-table-data column-name="flag-column">
                <div class="check-margin">
                    <gen-checkbox [id]="entity.guid" [ngModel]="entity.flagged" (ngModelChange)="setItemIsFlagged(entity, $event)"></gen-checkbox>
                </div>
            </gen-table-data>
            <gen-table-data column-name="delete-column">
                <gen-button class="delete-button" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_LABEL_REMOVE' | translate }}" (click)="removeItem(entity)">
                    <gen-icon [icon]="Icon.Trash"></gen-icon>
                </gen-button>
            </gen-table-data>
        </gen-table-row>
    </gen-table>
</ng-container>

<app-modal-entities-selector
    #entitiesSelector
    title="{{ 'STE_TITLE_SELECT_ENTITIES_WATCH' | translate }}"
    defaultActionText="{{ 'STE_BUTTON_ADD' | translate }}"
    [filter]="(entityBrowserFilter$ | async) ?? undefined"
    [showFooter]="false"
    (entitiesSelected)="onEntitiesSelected($event)"
></app-modal-entities-selector>

<ng-template #spinner>
    <div class="ha-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
