import { EntityQuery } from './EntityQuery';
import { IQueryFilter } from '../Interface/IQueryFilter';

export class QueryFilter extends EntityQuery implements IQueryFilter {
  constructor(...columns: string[]) {
    super();
    for (const f of columns) {
      // start monitoring that entity
      this.fields.add(f);
    }
  }

  public toString(): string {
    let query = super.buildQuery();
    query = query.substring(query.indexOf('?')).replace('?', '');
    return query;
  }
}
