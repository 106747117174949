import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { GenMenu } from '@genetec/gelato-angular';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { MenuItemsComponent } from '@shared/components/menu-item/menu-item.component';

interface Coords {
    x: number;
    y: number;
}

/**
 * This factory allows to generate a ContextMenuPopup (GenMenu) based on a dynamic list of MenuItem(with children or not) and coordinates or targetSelector.
 * This factory takes into account whether there is a fullscreen element, if it does the ContextMenu will be display on top of it, otherwise, it will be appended to
 * the gen-design-menu-system.
 *
 * This factory is designed in a way that there can only be one contextMenu at a time.
 */
@Injectable({
    providedIn: 'root',
})
export class ContextMenuFactory {
    public currentMenu?: ComponentRef<GenMenu>;

    public get isContextMenuOpen(): boolean {
        return this.currentMenu?.instance?.open ?? false;
    }

    /**
     * Builds a GenMenu component reference and attaches it to the DOM.
     * The menu is not shown automatically.
     * Caller is in charge of destroying the menu once done with it.
     */
    public buildMenu(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], coords?: Coords): ComponentRef<GenMenu>;
    public buildMenu(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], targetSelector?: string): ComponentRef<GenMenu>;
    public buildMenu(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], targetElement?: HTMLElement): ComponentRef<GenMenu>;
    public buildMenu(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], target?: HTMLElement | Coords | string): ComponentRef<GenMenu> {
        return this.build(viewContainerRef, dataSource, target);
    }

    /**
     * Builds and shows a menu
     */
    public async showMenuAsync(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], coords?: Coords): Promise<ComponentRef<GenMenu>>;
    public async showMenuAsync(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], targetSelector?: string): Promise<ComponentRef<GenMenu>>;
    public async showMenuAsync(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], targetElement?: HTMLElement): Promise<ComponentRef<GenMenu>>;
    public async showMenuAsync(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], target?: HTMLElement | Coords | string): Promise<ComponentRef<GenMenu>> {
        // ensures there is only one popup at a time.
        this.currentMenu?.destroy();

        this.currentMenu = this.build(viewContainerRef, dataSource, target);

        // Then show it.
        await this.currentMenu.instance.show();

        return this.currentMenu;
    }

    public destroy(): void {
        this.currentMenu?.instance?.close().fireAndForget();
        this.currentMenu?.destroy();
    }

    private build(viewContainerRef: ViewContainerRef, dataSource: ContextMenuItem[], target?: HTMLElement | Coords | string): ComponentRef<GenMenu> {
        const genMenuRef = viewContainerRef.createComponent(GenMenu);

        // Create the menu items.
        const menuItems = viewContainerRef.createComponent(MenuItemsComponent);
        menuItems.instance.dataSource = dataSource;

        // Run change detection for bindings.
        menuItems.changeDetectorRef.detectChanges();

        // Inject them in the context menu
        const domElement = genMenuRef.location.nativeElement as HTMLElement;
        domElement.appendChild(menuItems.location.nativeElement);

        // Append the menu to the appropriate section.
        let appendToElement;
        if (document.fullscreenEnabled && document.fullscreenElement) {
            appendToElement = document.fullscreenElement;
        } else {
            appendToElement = document.querySelector('gen-design-system-provider');
        }

        appendToElement?.appendChild(domElement);
        if (target) {
            if (typeof target === 'string') {
                genMenuRef.instance.targetSelector = target;
            } else if (isCoords(target)) {
                genMenuRef.instance.positionX = target.x;
                genMenuRef.instance.positionY = target.y;
            } else {
                genMenuRef.instance.targetElement = target;
            }
        }

        return genMenuRef;
    }
}

const isCoords = (target: HTMLElement | Coords): target is Coords => {
    return 'x' in target && 'y' in target && !isNaN(target.x) && !isNaN(target.y);
};
