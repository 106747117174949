// Autogenerated: v2/help/Entities/ApiDoor?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:02 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { IAutoUnlockOverride, IDoorEntity, DoorEntityFields } from '../../Interface/IDoorEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { RestResponse } from '../../../Connection/RestResponse';

// #region Inner classes

export class AutoUnlockOverride extends FieldObject implements IAutoUnlockOverride {
    // Start
    public get start(): Date {
        return this.getFieldDate('Start');
    }
    public set start(value: Date) {
        this.setFieldDate('Start', value);
    }
    // End
    public get end(): Date {
        return this.getFieldDate('End');
    }
    public set end(value: Date) {
        this.setFieldDate('End', value);
    }
    // IsUnlocking
    public get isUnlocking(): boolean {
        return this.getField<boolean>('IsUnlocking');
    }
    public set isUnlocking(value: boolean) {
        this.setField<boolean>('IsUnlocking', value);
    }

    public initializeAllFields(): void {
        this.setFieldDate('Start', new Date());
        this.setFieldDate('End', new Date());
        this.setField<boolean>('IsUnlocking', false);
    }
}

// #endregion Inner classes

export class DoorEntity extends Entity implements IDoorEntity {
    // #region REST Properties

    // DoorForcedBehavior
    public get doorForcedBehavior(): string {
        return this.getField<string>(DoorEntityFields.doorForcedBehaviorField, ValidFlags.Specific);
    }
    public set doorForcedBehavior(value: string) {
        this.setField<string>(DoorEntityFields.doorForcedBehaviorField, value);
    }

    // DoorHeldBehavior
    public get doorHeldBehavior(): string {
        return this.getField<string>(DoorEntityFields.doorHeldBehaviorField, ValidFlags.Specific);
    }
    public set doorHeldBehavior(value: string) {
        this.setField<string>(DoorEntityFields.doorHeldBehaviorField, value);
    }

    // DoorLock
    public get doorLock(): IGuid {
        return this.getFieldGuid(DoorEntityFields.doorLockField, ValidFlags.Membership);
    }

    // DoorOpened
    public get doorOpened(): boolean {
        return this.getField<boolean>(DoorEntityFields.doorOpenedField, ValidFlags.EntityStatus);
    }

    // DoorRelockDelay
    public get doorRelockDelay(): number {
        return this.getField<number>(DoorEntityFields.doorRelockDelayField, ValidFlags.Specific);
    }
    public set doorRelockDelay(value: number) {
        this.setField<number>(DoorEntityFields.doorRelockDelayField, value);
    }

    // DoorRelockOnClose
    public get doorRelockOnClose(): boolean {
        return this.getField<boolean>(DoorEntityFields.doorRelockOnCloseField, ValidFlags.Specific);
    }
    public set doorRelockOnClose(value: boolean) {
        this.setField<boolean>(DoorEntityFields.doorRelockOnCloseField, value);
    }

    // DoorSensor
    public get doorSensor(): IGuid {
        return this.getFieldGuid(DoorEntityFields.doorSensorField, ValidFlags.Membership);
    }

    // EntrySensorExtendedGrantTime
    public get entrySensorExtendedGrantTime(): number {
        return this.getField<number>(DoorEntityFields.entrySensorExtendedGrantTimeField, ValidFlags.Specific);
    }
    public set entrySensorExtendedGrantTime(value: number) {
        this.setField<number>(DoorEntityFields.entrySensorExtendedGrantTimeField, value);
    }

    // EntrySensorStandardGrantTime
    public get entrySensorStandardGrantTime(): number {
        return this.getField<number>(DoorEntityFields.entrySensorStandardGrantTimeField, ValidFlags.Specific);
    }
    public set entrySensorStandardGrantTime(value: number) {
        this.setField<number>(DoorEntityFields.entrySensorStandardGrantTimeField, value);
    }

    // ExtendedGrantTime
    public get extendedGrantTime(): number {
        return this.getField<number>(DoorEntityFields.extendedGrantTimeField, ValidFlags.Specific);
    }
    public set extendedGrantTime(value: number) {
        this.setField<number>(DoorEntityFields.extendedGrantTimeField, value);
    }

    // InterfaceModule
    public get interfaceModule(): IGuid {
        return this.getFieldGuid(DoorEntityFields.interfaceModuleField, ValidFlags.Specific);
    }
    public set interfaceModule(value: IGuid) {
        this.setFieldGuid(DoorEntityFields.interfaceModuleField, value);
    }

    // IsLocked
    public get isLocked(): boolean {
        return this.getField<boolean>(DoorEntityFields.isLockedField, ValidFlags.EntityStatus);
    }

    // IsLockSensorUnsecured
    public get isLockSensorUnsecured(): boolean {
        return this.getField<boolean>(DoorEntityFields.isLockSensorUnsecuredField, ValidFlags.EntityStatus);
    }

    // MaintenanceModeActive
    public get maintenanceModeActive(): boolean {
        return this.getField<boolean>(DoorEntityFields.maintenanceModeActiveField, ValidFlags.Behavior);
    }
    public set maintenanceModeActive(value: boolean) {
        this.setField<boolean>(DoorEntityFields.maintenanceModeActiveField, value);
    }

    // MainUnit
    public get mainUnit(): IGuid {
        return this.getFieldGuid(DoorEntityFields.mainUnitField, ValidFlags.Specific);
    }
    public set mainUnit(value: IGuid) {
        this.setFieldGuid(DoorEntityFields.mainUnitField, value);
    }

    // ManualStationPulled
    public get manualStationPulled(): boolean {
        return this.getField<boolean>(DoorEntityFields.manualStationPulledField, ValidFlags.EntityStatus);
    }

    // MaskAccessWhenUnlockedBySchedule
    public get maskAccessWhenUnlockedBySchedule(): boolean {
        return this.getField<boolean>(DoorEntityFields.maskAccessWhenUnlockedByScheduleField, ValidFlags.Specific);
    }
    public set maskAccessWhenUnlockedBySchedule(value: boolean) {
        this.setField<boolean>(DoorEntityFields.maskAccessWhenUnlockedByScheduleField, value);
    }

    // MaskDoorOpenTooLongWhenUnlockedBySchedule
    public get maskDoorOpenTooLongWhenUnlockedBySchedule(): boolean {
        return this.getField<boolean>(DoorEntityFields.maskDoorOpenTooLongWhenUnlockedByScheduleField, ValidFlags.Specific);
    }
    public set maskDoorOpenTooLongWhenUnlockedBySchedule(value: boolean) {
        this.setField<boolean>(DoorEntityFields.maskDoorOpenTooLongWhenUnlockedByScheduleField, value);
    }

    // MaximumDelayBetweenSwipes
    public get maximumDelayBetweenSwipes(): number {
        return this.getField<number>(DoorEntityFields.maximumDelayBetweenSwipesField, ValidFlags.Specific);
    }
    public set maximumDelayBetweenSwipes(value: number) {
        this.setField<number>(DoorEntityFields.maximumDelayBetweenSwipesField, value);
    }

    // NormalGrantTime
    public get normalGrantTime(): number {
        return this.getField<number>(DoorEntityFields.normalGrantTimeField, ValidFlags.Specific);
    }
    public set normalGrantTime(value: number) {
        this.setField<number>(DoorEntityFields.normalGrantTimeField, value);
    }

    // RexHoldOffAfterClosedDelay
    public get rexHoldOffAfterClosedDelay(): number {
        return this.getField<number>(DoorEntityFields.rexHoldOffAfterClosedDelayField, ValidFlags.Specific);
    }
    public set rexHoldOffAfterClosedDelay(value: number) {
        this.setField<number>(DoorEntityFields.rexHoldOffAfterClosedDelayField, value);
    }

    // RexHoldOffAfterGrantDelay
    public get rexHoldOffAfterGrantDelay(): number {
        return this.getField<number>(DoorEntityFields.rexHoldOffAfterGrantDelayField, ValidFlags.Specific);
    }
    public set rexHoldOffAfterGrantDelay(value: number) {
        this.setField<number>(DoorEntityFields.rexHoldOffAfterGrantDelayField, value);
    }

    // RexHoldoffWhileOpened
    public get rexHoldoffWhileOpened(): boolean {
        return this.getField<boolean>(DoorEntityFields.rexHoldoffWhileOpenedField, ValidFlags.Specific);
    }
    public set rexHoldoffWhileOpened(value: boolean) {
        this.setField<boolean>(DoorEntityFields.rexHoldoffWhileOpenedField, value);
    }

    // UnlockOnRex
    public get unlockOnRex(): boolean {
        return this.getField<boolean>(DoorEntityFields.unlockOnRexField, ValidFlags.Behavior);
    }
    public set unlockOnRex(value: boolean) {
        this.setField<boolean>(DoorEntityFields.unlockOnRexField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Doors);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPoints', 'Id', true, query, ValidFlags.Membership, transaction);
    }

    public async deleteAutoUnlockOverrideAsync(): Promise<IRestResponse> {
        await this.updateDataRelation2Async<AutoUnlockOverride, IAutoUnlockOverride>(AutoUnlockOverride, 'AutoUnlockOverride', 'DELETE', true, ValidFlags.Behavior, null);
        return new RestResponse(HttpStatusCode.OK);
    }

    public async getAutoUnlockOverrideAsync(transaction?: ITransaction | null): Promise<IAutoUnlockOverride | null> {
        return await this.getDataRelationAsync<AutoUnlockOverride>(AutoUnlockOverride, 'AutoUnlockOverride', true, ValidFlags.Behavior, transaction);
    }

    public async addAutoUnlockOverrideAsync(value: IAutoUnlockOverride, transaction?: ITransaction | null): Promise<IAutoUnlockOverride | null> {
        return await this.updateDataRelation2Async<AutoUnlockOverride, IAutoUnlockOverride>(AutoUnlockOverride, 'AutoUnlockOverride', 'POST', true, ValidFlags.Behavior, value);
    }

    public async getDoorSidesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('DoorSides', 'Id', true, query, ValidFlags.Membership, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', true, query, ValidFlags.EntityMembership, transaction);
    }

    public async unlockAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('Unlock', 'POST', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

