<div class="gen-icon-container" *ngIf="isGenIconShowing(); else customIcon" [@leaveAnimation] [@.disabled]="!isLoading">
    <gen-spinner *ngIf="isLoading" appTooltip="{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}"></gen-spinner>
    <gen-icon [icon]="icon" [size]="size" [style.fontSize.px]="iconFontSizePx" [style.opacity]="isLoading ? 0.25 : 1"></gen-icon>
</div>
<ng-template #customIcon>
    <div class="gen-image-container" [ngClass]="size ? 'custom-icon-img--' + size : ''" [style.width.px]="customIconWidthPx" [style.height.px]="customIconHeightPx">
        <!-- TODO remove slot="" when version @genetec/gelato-angular@1.58.1-migration has been updated -->
        <gen-image slot="" [@showAnimation] [@.disabled]="!isLoading" *ngIf="customIconSrc" class="gen-image-container__custom-icon-img" [src]="customIconSrc"></gen-image>
    </div>
</ng-template>
