// Autogenerated: v2/help/Entities/ApiNetwork?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { IGuid } from 'safeguid';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class NetworkEntityFields extends EntityFields {
    public static iPv4AddressPrefixField = 'IPv4AddressPrefix';
    public static iPv4NetworkMaskField = 'IPv4NetworkMask';
    public static iPv6PrefixField = 'IPv6Prefix';
    public static iPv6PrefixLengthField = 'IPv6PrefixLength';
    public static isDefaultNetworkField = 'IsDefaultNetwork';
    public static parentRouteField = 'ParentRoute';
    public static proxyServerField = 'ProxyServer';
    public static publicAddressField = 'PublicAddress';
    public static remoteStreamLocatorIdField = 'RemoteStreamLocatorId';
    public static supportedTransportsField = 'SupportedTransports';
    public static supportsIpv6Field = 'SupportsIpv6';
}

// #endregion Fields

// #region Inner classes

export interface IPublicServer extends IFieldObject {
    serverId: IGuid;
    publicEndPoint: string;
    isProxy: boolean;
}

// #endregion Inner classes

export interface INetworkEntity extends IEntity {
    // #region REST Properties

    iPv4AddressPrefix: string;

    iPv4NetworkMask: string;

    iPv6Prefix: string;

    iPv6PrefixLength: number;

    readonly isDefaultNetwork: boolean;

    readonly parentRoute: IGuid;

    proxyServer: IGuid;

    publicAddress: string;

    remoteStreamLocatorId: IGuid;

    supportedTransports: string;

    readonly supportsIpv6: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getPublicServersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPublicServer> | null>;
    getRoutesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getServersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getSubNetworksAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

