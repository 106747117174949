export enum MCCommandType {
    None = 0,
    TriggerIncident = 1,
    ChangeState = 2,
    PlaceOnHold = 3,
    ChangePriority = 4,
    LinkIncidents = 5,
    UnlinkIncidents = 6,
    AddComment = 7,
    ChangeComment = 8,
    DeleteComment = 9,
    ChangeDescription = 10,
    ChangeExternalId = 11,
    ChangeIncidentType = 12,
    ChangeLocation = 13,
    ClearLocation = 14,
    TakeOwnership = 15,
    ReleaseOwnership = 16,
    AddNote = 17,
    AnswerProcedureStep = 18,
    TransferIncident = 19,
    ForwardIncident = 20,
}

export abstract class MCCommandBase {
    public abstract type: MCCommandType;

    protected constructor(public url: string, public body: string) {}

    public successMessage(statusCode?: number): string {
        return '';
    }

    public errorMessage(statusCode?: number): string {
        return '';
    }
}
