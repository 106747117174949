// Autogenerated: v2/help/Entities/ApiAreaAccessPoint?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';
import { IAccessPointEntity, AccessPointEntityFields } from './IAccessPointEntity';

// #region Fields

export class AreaAccessPointEntityFields extends AccessPointEntityFields {
    public static areaField = 'Area';
}

// #endregion Fields

export interface IAreaAccessPointEntity extends IAccessPointEntity {
    // #region REST Properties

    readonly area: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

