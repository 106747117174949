import { delayAsPromise } from '../../../utilities/common-helper';

/**
 * Focus an html element using a given selector
 *
 * @param selector CSS selector
 * @param source Source object from where we should query with the selector, default is document
 * @param forceFocus If yes, a tabindex="-1" will be added to the element if there is no tabindex and the element is not natively focusable (ex:div)
 */
export const focusDomElement = (selector: string, source?: Element, forceFocus?: boolean): void => {
    // Delegate the next lines at the end of the stack to let the to be focus element appear
    if (!selector) {
        return;
    }
    setTimeout(() => {
        const from = (source ?? document) as HTMLElement;
        const el = from?.querySelector(selector) as HTMLElement;
        if (!el) throw Error(`No result with selector (${selector}) and from (${source ? `${from.tagName}.${from.className}` : 'document'})`);
        el.focus();
        if (document.activeElement !== el && forceFocus) {
            // Here, focus didn't work but forceFocus is true
            if (!el.hasAttribute('tabindex')) el.setAttribute('tabindex', '-1');
            el.focus();
        }
    }, 10);
};
