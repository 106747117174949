// Autogenerated: v2/help/Entities/ApiIntrusionInputDevice?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IIntrusionInputDeviceEntity, IntrusionInputDeviceEntityFields } from '../../Interface/IIntrusionInputDevice';
import { IRestResponse } from '../../Interface/IRestResponse';
import { InputDeviceEntity } from './InputDeviceEntity';

export class IntrusionInputDeviceEntity extends InputDeviceEntity implements IIntrusionInputDeviceEntity {
    // #region REST Properties

    // AlarmState
    public get alarmState(): string {
        return this.getField<string>(IntrusionInputDeviceEntityFields.alarmStateField, ValidFlags.EntityStatus2);
    }
    public set alarmState(value: string) {
        this.setField<string>(IntrusionInputDeviceEntityFields.alarmStateField, value);
    }

    // BypassedState
    public get bypassedState(): string {
        return this.getField<string>(IntrusionInputDeviceEntityFields.bypassedStateField, ValidFlags.EntityStatus2);
    }
    public set bypassedState(value: string) {
        this.setField<string>(IntrusionInputDeviceEntityFields.bypassedStateField, value);
    }

    // Capabilities
    public get capabilities(): string {
        return this.getField<string>(IntrusionInputDeviceEntityFields.capabilitiesField, ValidFlags.Specific);
    }

    // InputType
    public get inputType(): string {
        return this.getField<string>(IntrusionInputDeviceEntityFields.inputTypeField, ValidFlags.Specific);
    }

    // InputTypeCustomIcon
    public get inputTypeCustomIcon(): IGuid {
        return this.getFieldGuid(IntrusionInputDeviceEntityFields.inputTypeCustomIconField, ValidFlags.Specific);
    }

    // InputTypeId
    public get inputTypeId(): IGuid {
        return this.getFieldGuid(IntrusionInputDeviceEntityFields.inputTypeIdField, ValidFlags.Specific);
    }

    // InputTypeName
    public get inputTypeName(): string {
        return this.getField<string>(IntrusionInputDeviceEntityFields.inputTypeNameField, ValidFlags.Specific);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAlarmSourcesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AlarmSources', 'Id', false, query, ValidFlags.EntityStatus2, transaction);
    }

    public async bypassInputAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('BypassInput', 'POST', null, transaction, responseHandler);
    }

    public async getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cameras', 'Id', false, query, ValidFlags.Specific, transaction);
    }

    public async unbypassInputAsync(transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        return this.executeRequestTransactionAsync<IRestResponse>('UnbypassInput', 'POST', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

