/* eslint-disable @typescript-eslint/prefer-regexp-exec, no-useless-escape */
// translated from https://github.com/tedgreen29/typescript-natural-sort/blob/master/naturalSort.js

export const naturalSort = (item1: string | number, item2: string | number, caseSensitive = false): 0 | -1 | 1 => {
    const re = /(^([+\-]?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+\-]?\d+)?)?$|^0x[0-9a-f]+$|\d+)/gi;
    const sre = /(^[ ]*|[ ]*$)/g;
    const dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/;
    const hre = /^0x[0-9a-f]+$/i;
    const ore = /^0/;
    const i = (s: string | number) => (!caseSensitive && s.toString().toLowerCase()) || s.toString();
    // convert all to strings strip whitespace
    const x = i(item1).replace(sre, '') || '';
    const y = i(item2).replace(sre, '') || '';
    // chunk/tokenize
    const xN = x.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');
    const yN = y.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');
    // numeric, hex or date detection
    const xMatch = x.match(hre);
    const yMatch = y.match(hre);
    const xD = (xMatch && parseInt(xMatch[0], 16)) || (xN.length !== 1 && x.match(dre) && Date.parse(x));
    const yD = (yMatch && parseInt(yMatch[0], 16)) || (xD && y.match(dre) && Date.parse(y)) || null;
    let oFxNcL;
    let oFyNcL;
    // first try and sort Hex codes or Dates
    if (xD && yD) {
        if (xD < yD) {
            return -1;
        } else if (xD > yD) {
            return 1;
        }
    }
    // natural sorting through split numeric strings and default strings
    for (let cLoc = 0, numS = Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
        // find floats not starting with '0', string or 0 if not defined (Clint Priest)
        oFxNcL = (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0;
        oFyNcL = (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0;
        // handle numeric vs string comparison - number < string - (Kyle Adams)
        if ((typeof oFxNcL === 'number') !== (typeof oFyNcL === 'number')) {
            return typeof oFxNcL === 'number' ? -1 : 1;
        } else if (typeof oFxNcL !== typeof oFyNcL) {
            oFxNcL += '';
            oFyNcL += '';
        }
        if (oFxNcL < oFyNcL) {
            return -1;
        }
        if (oFxNcL > oFyNcL) {
            return 1;
        }
    }
    return 0;
};
