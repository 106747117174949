import { AfterViewInit, Input, ViewChild } from '@angular/core';
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { GenMeltedModal, GenMeltedModalComponent } from '@genetec/gelato-angular';
import { Subject } from 'rxjs';
import { TrackingService } from '../../services/tracking.service';

@Component({
    selector: 'app-tracked-gen-modal-base-component',
    template: '',
})
export class TrackedGenModalBaseComponent<T> extends GenMeltedModal implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(GenMeltedModalComponent) public genModalComponent!: GenMeltedModalComponent;

    @Input() hiddenOnInit?: boolean;

    public result$ = new Subject<T>();
    protected name!: string;

    constructor(protected injector: Injector, protected trackingService: TrackingService) {
        super();

        this.name = this.constructor.name;
    }

    public getModalInstance(): GenMeltedModalComponent {
        return this.genModalComponent;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.trackingService.addActiveComponent(this.name);
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.hiddenOnInit) {
            this.genModalComponent.hide();
        }
    }

    public ngOnDestroy(): void {
        this.trackingService.removeActiveComponent(this.name);
    }

    protected emitResult(result?: T): void {
        this.result$.next(result);
    }

    protected async logAction(actionDescription: string): Promise<void> {
        await this.trackingService.logActionAsync({ name: actionDescription, originComponent: this.name });
    }
}

@Component({
    selector: 'app-tracked-gen-modal-component',
    template: '',
})
export class TrackedGenModalComponent extends TrackedGenModalBaseComponent<void> {}
