import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SafeGuid, IGuid } from 'safeguid';
import { GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { Coordinate } from 'RestClient/Client/Model/MapEntity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { LanguageService } from '../../shared/services/language/language.service';
import {
    CommandBindings,
    CommandContext,
    CommandRequirements,
    CommandsService,
    COMMANDS_SERVICE,
    ExecuteCommandData,
    FEATURES_SERVICE,
    GeoCoordinate,
    MODAL_SERVICE,
} from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { Commands } from '../enumerations/commands';
import { IngestDataModalComponent } from '../components/ingest-data-modal/data-ingestion-modal.component';
import { FeaturesService } from '../../shared/services/features/features.service';
import { CorrelationFeatures } from '../enumerations/correlation.features';
import { CommandProviderBase, CommandDescriptor, CommandArgs } from '../../shared/services/commands/command-provider-base';
import { IngestionService } from './ingestion.service';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class IngestionCommandProvider extends CommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(Commands.ingestData, this.createIngestionCommandDescriptor(Commands.ingestData, 'STE_ACTION_INGESTDATA', MeltedIcon.Database));
            this.internalCommandDescriptors = descriptors;
        }
        return this.internalCommandDescriptors;
    }

    public get requiredFeatures(): Set<IGuid> {
        return SafeGuid.createSet([...super.requiredFeatures, CorrelationFeatures.ingestDataFeature]);
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        @Inject(MODAL_SERVICE) private modalService: GenModalService,
        private ingestionService: IngestionService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: CommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);
        if (await this.isIngestAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(Commands.ingestData);
        }
        return new Promise<IGuid[]>((resolve) => resolve(commandIds));
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: Commands.ingestData,
            executeCommandHandler: (executeCommandData) => this.safeExecuteCommand((safeExecuteCommandData) => this.executeIngestData(safeExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsCommandAvailableAsync((safeCommandContext) => this.isIngestAvailableAsync(safeCommandContext), commandContext),
        });
    }

    private createIngestionCommandDescriptor(
        commandId: IGuid,
        nameResourceId: string,
        icon: MeltedIcon,
        requirements?: CommandRequirements,
        keyBinding?: string
    ): CommandDescriptor {
        return {
            id: commandId,
            nameResourceId,
            icon,
            groupNameResourceId: 'STE_LABEL_NOUN_RECORDCACHING',
            groupIcon: MeltedIcon.CorrelationService,
            keyBinding,
            requirements,
        };
    }

    private executeIngestData(executeCommandData: ExecuteCommandData): void {
        let coordinate: GeoCoordinate | undefined;
        const args = this.extractArgs(executeCommandData.commandContext);
        if (args.mapContext) {
            if (args.mapContext.geoLocalised) {
                coordinate = args.mapContext.location as GeoCoordinate;
            }
        }

        if (coordinate && this.modalService) {
            // pop ingest data modal
            const modal = this.modalService.open(IngestDataModalComponent, undefined);
            if (modal) {
                const coord = new Coordinate();
                coord.initializeAllFields();
                coord.latitude = coordinate.latitude;
                coord.longitude = coordinate.longitude;
                modal.location = coord;
            }
        }
        executeCommandData.isHandled = true;
    }

    private async isIngestAvailableAsync(commandContext: CommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        let args = commandArgs;
        if (!args) {
            args = this.extractArgs(commandContext);
        }

        if (this.ingestionService && args.mapContext?.geoLocalised && (await this.areCommandRequirementsMetAsync(Commands.ingestData, commandContext, commandArgs))) {
            const cache = this.ingestionService.getIngestionTypeCache();
            if (cache && cache.length > 0) {
                return true;
            }
        }
        return false;
    }
}
