<ng-container *ngIf="isInitialized; else spinner">
    <ng-container *ngIf="isContentVisible; else insufficientPrivileges">
        <gen-label>{{ 'STE_LABEL_SHOWTILENUMBER' | translate }}</gen-label>
        <gen-radio
            class="d-block"
            name="tileHeaderVisibilityCheckbox"
            [value]="TileHeaderStateOptions.Always"
            [(ngModel)]="tileHeaderVisibility"
            (ngModelChange)="onTileHeaderVisibilityChange()"
        >
            <gen-label>
                {{ 'STE_BUTTON_RADIO_ALWAYSDISPLAYTILENUMBER' | translate }}
            </gen-label>
        </gen-radio>
        <gen-radio
            class="d-block"
            name="tileHeaderVisibilityCheckbox"
            [value]="TileHeaderStateOptions.Never"
            [(ngModel)]="tileHeaderVisibility"
            (ngModelChange)="onTileHeaderVisibilityChange()"
        >
            <gen-label>
                {{ 'STE_BUTTON_RADIO_NEVERDISPLAYTILENUMBER' | translate }}
            </gen-label>
        </gen-radio>
        <gen-radio
            class="d-block"
            name="tileHeaderVisibilityCheckbox"
            [value]="TileHeaderStateOptions.OnlyWhenHovering"
            [(ngModel)]="tileHeaderVisibility"
            (ngModelChange)="onTileHeaderVisibilityChange()"
        >
            <gen-label>
                {{ 'STE_BUTTON_RADIO_WHENHOVERINGDISPLAYTILENUMBER' | translate }}
            </gen-label>
        </gen-radio>
    </ng-container>
</ng-container>

<ng-template #insufficientPrivileges>
    <app-insufficient-privileges></app-insufficient-privileges>
</ng-template>

<ng-template #spinner>
    <div class="ha-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
