// Autogenerated: v2/help/Entities/ApiOutputDevice?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { IGuid } from 'safeguid';
import { ITransaction } from './ITransaction';
import { DeviceEntityFields, IDeviceEntity } from './IDeviceEntity';
import { IRestResponse } from './IRestResponse';

// #region Fields

export class OutputDeviceEntityFields extends DeviceEntityFields {
    public static outputStateField = 'OutputState';
}

// #endregion Fields

export interface IOutputDeviceEntity extends IDeviceEntity {
    // #region REST Properties

    outputState: string;

    // #endregion REST Properties

    // #region REST Methods

    triggerOutputAsync(outputBehaviorId: IGuid, transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

