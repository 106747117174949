export class UserTokenData {
  public token = '';
  public validUntil: Date = new Date();
  public millisecondsValid = 0;

  public getAuthentificationHeader(): string {
    if ( (this.token.startsWith('LocalToken') === false) && (this.token.startsWith('LocalToken') === false) ) {
      return 'Token ' + this.token;
    }
    return this.token;
  }
}
