import { Component, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, PopupPosition } from '@genetec/gelato';
import { GenPopup, Severity } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes, TraySubSections } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { VideoExportInstance, VideoExportService } from '../../services/video-export.service';

@Component({
    selector: 'app-export-video-tray',
    templateUrl: './export-video-tray.component.html',
    styleUrls: ['./export-video-tray.component.scss'],
})
@InternalPluginDescriptor({
    type: ExportVideoTrayComponent,
    pluginTypes: [PluginTypes.Tray],
    exposure: { id: ExportVideoTrayComponent.pluginId, subSection: TraySubSections.MainBar },
    isSupported: () => true,
    requirements: { features: [KnownFeatures.videoId], licenses: [KnownLicenses.video], globalPrivileges: [KnownPrivileges.exportVideoPrivilege] },
})
export class ExportVideoTrayComponent extends TrackedComponent {
    public static pluginId = SafeGuid.parse('266DC88E-FA83-4195-808A-601DB847ECF1');

    @ViewChild(GenPopup) exportPopup!: GenPopup;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly PopupPosition = PopupPosition;
    public readonly Severity = Severity;

    public instanceCount = 0;

    public get exports(): VideoExportInstance[] {
        return this.videoExportService.exports;
    }

    constructor(private videoExportService: VideoExportService, trackingService: TrackingService) {
        super(trackingService);
    }

    public async showExportingVideos(): Promise<void> {
        await this.exportPopup.toggle();
    }

    public onButtonRemoveClick(instance: VideoExportInstance): void {
        if (instance) {
            instance.cancel().fireAndForget();
        }
    }
}
