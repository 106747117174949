// Autogenerated: v2/help/Entities/ApiArea?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:01 AM
import { Entity } from './Entity';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { FieldObject } from '../../Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { ITransaction } from '../Interface/ITransaction';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { IAreaEntity, IMap, AreaEntityFields, IAccessPointGroupThreatLevelMapping } from '../Interface/IAreaEntity';

// #region Inner classes

export class AccessPointGroupThreatLevelMapping extends FieldObject implements IAccessPointGroupThreatLevelMapping {
    // AccessPointGroupId
    public get accessPointGroupId(): IGuid {
        return this.getFieldGuid('AccessPointGroupId');
    }
    public set accessPointGroupId(value: IGuid) {
        this.setFieldGuid('AccessPointGroupId', value);
    }
    // ThreatLevelId
    public get threatLevelId(): IGuid {
        return this.getFieldGuid('ThreatLevelId');
    }
    public set threatLevelId(value: IGuid) {
        this.setFieldGuid('ThreatLevelId', value);
    }
    // XmlInfo
    public get xmlInfo(): string {
        return this.getField<string>('XmlInfo');
    }
    public set xmlInfo(value: string) {
        this.setField<string>('XmlInfo', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('AccessPointGroupId', SafeGuid.EMPTY);
        this.setFieldGuid('ThreatLevelId', SafeGuid.EMPTY);
        this.setField<string>('XmlInfo', '');
    }
}

export class Map extends FieldObject implements IMap {
    // Provider
    public get provider(): IGuid | null {
        return this.getNullableFieldGuid('Provider');
    }
    public set provider(value: IGuid | null) {
        this.setNullableFieldGuid('Provider', value);
    }
    // FileExtension
    public get fileExtension(): string {
        return this.getField<string>('FileExtension');
    }
    public set fileExtension(value: string) {
        this.setField<string>('FileExtension', value);
    }
    // FileData
    public get fileData(): string {
        return this.getField<string>('FileData');
    }
    public set fileData(value: string) {
        this.setField<string>('FileData', value);
    }
    // ZoomLevel
    public get zoomLevel(): number | null {
        return this.getNullableField<number>('ZoomLevel');
    }
    public set zoomLevel(value: number | null) {
        this.setNullableField<number>('ZoomLevel', value);
    }
    // Resolution
    public get resolution(): number | null {
        return this.getNullableField<number>('Resolution');
    }
    public set resolution(value: number | null) {
        this.setNullableField<number>('Resolution', value);
    }
    // ScaleFactor
    public get scaleFactor(): number | null {
        return this.getNullableField<number>('ScaleFactor');
    }
    public set scaleFactor(value: number | null) {
        this.setNullableField<number>('ScaleFactor', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Provider', SafeGuid.EMPTY);
        this.setField<string>('FileExtension', '');
        this.setField<string>('FileData', '');
        this.setField<number>('ZoomLevel', 0);
        this.setField<number>('Resolution', 0);
        this.setField<number>('ScaleFactor', 0);
    }
}

// #endregion Inner classes

export class AreaEntity extends Entity implements IAreaEntity {
    // #region REST Properties

    // AccessControlSupport
    public get accessControlSupport(): boolean {
        return this.getField<boolean>(AreaEntityFields.accessControlSupportField, ValidFlags.Specific);
    }
    public set accessControlSupport(value: boolean) {
        this.setField<boolean>(AreaEntityFields.accessControlSupportField, value);
    }

    // AccessPermissionLevel
    public get accessPermissionLevel(): number {
        return this.getField<number>(AreaEntityFields.accessPermissionLevelField, ValidFlags.EntityStatus2);
    }
    public set accessPermissionLevel(value: number) {
        this.setField<number>(AreaEntityFields.accessPermissionLevelField, value);
    }

    // AntipassbackMode
    public get antipassbackMode(): string {
        return this.getField<string>(AreaEntityFields.antipassbackModeField, ValidFlags.Specific);
    }
    public set antipassbackMode(value: string) {
        this.setField<string>(AreaEntityFields.antipassbackModeField, value);
    }

    // AntipassbackSchedule
    public get antipassbackSchedule(): IGuid {
        return this.getFieldGuid(AreaEntityFields.antipassbackScheduleField, ValidFlags.Specific);
    }
    public set antipassbackSchedule(value: IGuid) {
        this.setFieldGuid(AreaEntityFields.antipassbackScheduleField, value);
    }

    // AntipassbackStrict
    public get antipassbackStrict(): boolean {
        return this.getField<boolean>(AreaEntityFields.antipassbackStrictField, ValidFlags.Specific);
    }
    public set antipassbackStrict(value: boolean) {
        this.setField<boolean>(AreaEntityFields.antipassbackStrictField, value);
    }

    // AntipassbackTimeout
    public get antipassbackTimeout(): number {
        return this.getField<number>(AreaEntityFields.antipassbackTimeoutField, ValidFlags.Specific);
    }
    public set antipassbackTimeout(value: number) {
        this.setField<number>(AreaEntityFields.antipassbackTimeoutField, value);
    }

    // CaptiveAccessPoint
    public get captiveAccessPoint(): IGuid {
        return this.getFieldGuid(AreaEntityFields.captiveAccessPointField, ValidFlags.Membership);
    }

    // Duress
    public get duress(): boolean {
        return this.getField<boolean>(AreaEntityFields.duressField, ValidFlags.Specific);
    }
    public set duress(value: boolean) {
        this.setField<boolean>(AreaEntityFields.duressField, value);
    }

    // EnforceVisitorEscortRules
    public get enforceVisitorEscortRules(): boolean {
        return this.getField<boolean>(AreaEntityFields.enforceVisitorEscortRulesField, ValidFlags.Specific);
    }
    public set enforceVisitorEscortRules(value: boolean) {
        this.setField<boolean>(AreaEntityFields.enforceVisitorEscortRulesField, value);
    }

    // EntryAccessPoint
    public get entryAccessPoint(): IGuid {
        return this.getFieldGuid(AreaEntityFields.entryAccessPointField, ValidFlags.Membership);
    }

    // ExitAccessPoint
    public get exitAccessPoint(): IGuid {
        return this.getFieldGuid(AreaEntityFields.exitAccessPointField, ValidFlags.Membership);
    }

    // FirstInRuleForAccessRulesApplySchedule
    public get firstInRuleForAccessRulesApplySchedule(): IGuid {
        return this.getFieldGuid(AreaEntityFields.firstInRuleForAccessRulesApplyScheduleField, ValidFlags.Specific);
    }
    public set firstInRuleForAccessRulesApplySchedule(value: IGuid) {
        this.setFieldGuid(AreaEntityFields.firstInRuleForAccessRulesApplyScheduleField, value);
    }

    // FirstInRuleForAccessRulesEnabled
    public get firstInRuleForAccessRulesEnabled(): boolean {
        return this.getField<boolean>(AreaEntityFields.firstInRuleForAccessRulesEnabledField, ValidFlags.Specific);
    }
    public set firstInRuleForAccessRulesEnabled(value: boolean) {
        this.setField<boolean>(AreaEntityFields.firstInRuleForAccessRulesEnabledField, value);
    }

    // FirstInRuleForUnlockSchedulesEnabled
    public get firstInRuleForUnlockSchedulesEnabled(): boolean {
        return this.getField<boolean>(AreaEntityFields.firstInRuleForUnlockSchedulesEnabledField, ValidFlags.Specific);
    }
    public set firstInRuleForUnlockSchedulesEnabled(value: boolean) {
        this.setField<boolean>(AreaEntityFields.firstInRuleForUnlockSchedulesEnabledField, value);
    }

    // FirstInRuleUnlockScheduleOffset
    public get firstInRuleUnlockScheduleOffset(): number {
        return this.getField<number>(AreaEntityFields.firstInRuleUnlockScheduleOffsetField, ValidFlags.Specific);
    }
    public set firstInRuleUnlockScheduleOffset(value: number) {
        this.setField<number>(AreaEntityFields.firstInRuleUnlockScheduleOffsetField, value);
    }

    // InheritEnforceVisitorEscortRules
    public get inheritEnforceVisitorEscortRules(): boolean {
        return this.getField<boolean>(AreaEntityFields.inheritEnforceVisitorEscortRulesField, ValidFlags.Specific);
    }
    public set inheritEnforceVisitorEscortRules(value: boolean) {
        this.setField<boolean>(AreaEntityFields.inheritEnforceVisitorEscortRulesField, value);
    }

    // IntrusionAccessPointIn
    public get intrusionAccessPointIn(): IGuid {
        return this.getFieldGuid(AreaEntityFields.intrusionAccessPointInField, ValidFlags.Membership);
    }

    // IntrusionAccessPointOut
    public get intrusionAccessPointOut(): IGuid {
        return this.getFieldGuid(AreaEntityFields.intrusionAccessPointOutField, ValidFlags.Membership);
    }

    // IntrusionArmingAccessPoint
    public get intrusionArmingAccessPoint(): IGuid {
        return this.getFieldGuid(AreaEntityFields.intrusionArmingAccessPointField, ValidFlags.Membership);
    }

    // IntrusionDisarmingAccessPoint
    public get intrusionDisarmingAccessPoint(): IGuid {
        return this.getFieldGuid(AreaEntityFields.intrusionDisarmingAccessPointField, ValidFlags.Membership);
    }

    // IsFederatedDirectory
    public get isFederatedDirectory(): boolean {
        return this.getField<boolean>(AreaEntityFields.isFederatedDirectoryField);
    }

    // Link
    public get link(): IGuid {
        return this.getFieldGuid(AreaEntityFields.linkField);
    }
    public set link(value: IGuid) {
        this.setFieldGuid(AreaEntityFields.linkField, value);
    }

    // MaxOccupancyLimit
    public get maxOccupancyLimit(): number {
        return this.getField<number>(AreaEntityFields.maxOccupancyLimitField, ValidFlags.Specific);
    }
    public set maxOccupancyLimit(value: number) {
        this.setField<number>(AreaEntityFields.maxOccupancyLimitField, value);
    }

    // MaxOccupancyMode
    public get maxOccupancyMode(): string {
        return this.getField<string>(AreaEntityFields.maxOccupancyModeField, ValidFlags.Specific);
    }
    public set maxOccupancyMode(value: string) {
        this.setField<string>(AreaEntityFields.maxOccupancyModeField, value);
    }

    // PeopleCountEnabled
    public get peopleCountEnabled(): boolean {
        return this.getField<boolean>(AreaEntityFields.peopleCountEnabledField, ValidFlags.AreaPeopleCount);
    }
    public set peopleCountEnabled(value: boolean) {
        this.setField<boolean>(AreaEntityFields.peopleCountEnabledField, value);
    }

    // PeopleCountOwner
    public get peopleCountOwner(): boolean {
        return this.getField<boolean>(AreaEntityFields.peopleCountOwnerField);
    }
    public set peopleCountOwner(value: boolean) {
        this.setField<boolean>(AreaEntityFields.peopleCountOwnerField, value);
    }

    // ThreatLevel
    public get threatLevel(): IGuid {
        return this.getFieldGuid(AreaEntityFields.threatLevelField, ValidFlags.EntityStatus2);
    }
    public set threatLevel(value: IGuid) {
        this.setFieldGuid(AreaEntityFields.threatLevelField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Areas);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPoints', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async createMapAsync(value: IMap, transaction?: ITransaction): Promise<IGuid | null> {
        const responseHandler = (response: IRestResponse): Promise<IGuid> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = SafeGuid.parse((response.body['Id'] as string).toLowerCase());
                return new Promise<IGuid>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<IGuid>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IGuid>('CreateMap', 'POST', value, transaction, responseHandler);
    }

    public async getFirstInRuleBypassListAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('FirstInRuleBypassList', 'Id', false, query, ValidFlags.Specific, transaction);
    }

    public async getFirstInRuleSupervisorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('FirstInRuleSupervisors', 'Id', false, query, ValidFlags.Specific, transaction);
    }

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getThreatLevelMappingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessPointGroupThreatLevelMapping> | null> {
        return this.getFieldObjectRelationAsync<AccessPointGroupThreatLevelMapping, IAccessPointGroupThreatLevelMapping>(AccessPointGroupThreatLevelMapping, 'ThreatLevelMappings', 'ThreatLevelId', false, false, ValidFlags.EntityDependency, transaction);
    }

    // #endregion REST Methods

}

