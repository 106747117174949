import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ButtonFlavor, Icon, ItemSlot, PopupPosition, TextFlavor, SelectionType, SpinnerSize } from '@genetec/gelato';
import { GenMenu, GenPopup, ImageFit, ImageFlavor, MeltedIcon } from '@genetec/gelato-angular';
import { LatLngBounds, LeafletMouseEvent, MapObjectEvent, MapObjectView, Point } from '@genetec/web-maps';
import { AreaBrowserItemModel } from '@modules/general/entity-browser/items/area-browser-item-model';
import { IFloor } from '@modules/maps/controllers/map.controller.data';
import { MapObjectAddOrUpdateEvent } from '@modules/maps/data/MapObjectAddOrUpdateEvent';
import { MapObjectRemoveEvent } from '@modules/maps/data/MapObjectRemoveEvent';
import { MapFloorInfo, MapSelectionData } from '@modules/maps/models';
import { MapPopupService } from '@modules/maps/services/map-popup.service';
import { StateMapService } from '@modules/maps/services/map/state-map-service';
import { MapsSideContextDataService } from '@modules/maps/services/maps-side-context-data/maps-side-context-data.service';
import { GeoCoordinate, MapSearchContext, MapViewArea } from '@modules/shared/api/api';
import { EntityBrowserComponent } from '@modules/shared/components/entity-browser/entity-browser/entity-browser.component';
import { SidePaneComponent } from '@modules/shared/components/side-pane/side-pane.component';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { EntityBrowserSelection } from '@modules/shared/entity-browser/entity-browser-selection';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { EntityBrowserItemModel } from '@modules/shared/entity-browser/Items/entity-browser-item-model';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { ContentGroup, PluginComponentExposure } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { COMMANDS_SERVICE, MAPS_BOTTOM_CONTEXT_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { KnownContentContext } from '@modules/shared/services/content/KnownContentContext';
import { EventsService } from '@modules/shared/services/events/events.service';
import { NavigationService } from '@modules/shared/services/navigation/navigation.service';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { SearchService } from '@modules/shared/services/search/search.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { InternalPluginDescriptor } from '@shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { IconsService } from '@shared/services/icons.service';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { AreaEntityFields, IAreaEntity } from 'RestClient/Client/Interface/IAreaEntity';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { IDefaultView, IMapEntity } from 'RestClient/Client/Interface/IMapEntity';
import { AreaEntity } from 'RestClient/Client/Model/AreaEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { MapEntity } from 'RestClient/Client/Model/MapEntity';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { BehaviorSubject, from, Observable, timer } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { WebAppClient } from 'WebClient/WebAppClient';
import { WINDOW } from '@src/app/utilities';
import { Select } from '@ngxs/store';
import { MapsSideContextDataState } from '@modules/maps/services/maps-side-context-data/maps-side-context-data.state';
import { StateObservable } from '@src/app/store/decorators/state-observable.decorator';
import { ListItem } from '@modules/shared/interfaces/list-item';
import { MethodEmitter, StateEmitter } from '@src/app/store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { MapsBottomContextDataService } from '@modules/maps/services/maps-bottom-context-data/maps-bottom-context-data.service';
import { ContentOverlayService } from '@shared/services/content-overlay/content-overlay.service';
import { MapControlsComponent } from '../map-controls/map-controls.component';
import { ShowPopupOptions } from '../map-popup/interfaces';
import { MapEntryParams } from './map-task-entry-params';
import { MapSearchInputItem } from './maps-search-input-item';

// ==========================================================================
// Copyright (C) 2019 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-maps-task',
    templateUrl: './maps-task.component.html',
    styleUrls: ['./maps-task.component.scss'],
    providers: [MapsSideContextDataService, MapPopupService, ContentOverlayService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalPluginDescriptor({
    type: MapsTaskComponent,
    pluginTypes: [PluginTypes.Task, PluginTypes.Dashboard],
    exposure: {
        id: MapsTaskComponent.pluginId,
        icon: 'gen-ico-folded-map',
        title: 'STE_TASK_MAPS',
        tags: ['map', 'STE_LABEL_NOUN_MAP', 'STE_LABEL_LOCATION'],
        priority: 10,
    } as PluginComponentExposure,
    requirements: {
        features: [KnownFeatures.mapsId],
        licenses: [KnownLicenses.maps],
        globalPrivileges: [KnownPrivileges.mapMonitoringTaskPrivilege],
    },
    isSupported: () => true,
})
export class MapsTaskComponent extends TrackedComponent implements OnInit, OnDestroy {
    public static pluginId = SafeGuid.parse('88FB8276-7191-4DA7-BDC5-38721EA78A21');

    @ViewChild('mapEntityBrowser') public mapEntityBrowser!: EntityBrowserComponent;
    @ViewChild('mapSelectionBrowser') public mapSelectionBrowser!: GenPopup;
    @ViewChild('mapLayersBrowser') public mapLayersBrowser!: GenPopup;
    @ViewChild('contextMenu') public contextMenu?: GenMenu;
    @ViewChild('linkContextMenu') public linkContextMenu?: GenMenu;
    @ViewChild('appMapControls') public appMapControls!: MapControlsComponent;

    @Select(FeatureFlagsState.featureFlags(GeneralFeatureFlags.ExpandWidget))
    public isPortalEnabled$!: Observable<boolean>;

    @Select(MapsSideContextDataState.currentContent)
    private sideContextCurrentContent$!: StateObservable<typeof MapsSideContextDataState.currentContent>;

    @MethodEmitter(MapsSideContextDataState.setLoadingMainContent)
    private setLoadingMainContent!: StateEmitter<typeof MapsSideContextDataState.setLoadingMainContent>;

    @MethodEmitter(MapsSideContextDataState.setMainContent)
    private setSideContextMainContent!: StateEmitter<typeof MapsSideContextDataState.setMainContent>;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly ImageFlavor = ImageFlavor;
    public readonly MeltedIcon = MeltedIcon;
    public readonly ImageFit = ImageFit;
    public readonly TextFlavor = TextFlavor;
    public readonly ListSelectionType = SelectionType;
    public readonly PopupPosition = PopupPosition;
    public readonly ItemSlot = ItemSlot;
    public readonly SpinnerSize = SpinnerSize;

    public readonly mapSelectionBrowserFilter = new EntityBrowserFilter(EntityTypes.Areas);

    public mapTrayPlugins$?: Observable<PluginItem[]>;
    public initialMapLoadItemsDone = false;
    public mapUnavailableReason = '';
    public searchText = '';

    public clickPositionX = 0;
    public clickPositionY = 0;
    public toggleMiniMapButton$?: Observable<HTMLElement | null>;
    public minimapTooltip$: Observable<string>;

    public layersAsListItems$: Observable<ListItem[]>;
    public isSelectLayerOpen = false;
    public isSelectMapOpen = false;
    public maps: MapEntity[] = [];
    public contextMenuLinks: ContextMenuItem[] = [];
    public floorInfo: MapFloorInfo = {
        floors: [],
    };
    public defaultView?: IDefaultView;
    public presets: IDefaultView[] = [];
    public selectedFloor: IFloor | null = null;
    public currentMapAreaId?: IGuid;
    public currentMapAreaCustomIconId?: IGuid;
    public hidingOfflineMobileUsers = false;
    public isSearching$: Observable<boolean>;
    public contextMenuLinks$?: Observable<ContextMenuItem[]>;
    public isLoadingContextMenu$: Observable<boolean>;

    public get isGoToParentEnabled(): boolean {
        return isNonEmptyGuid(this.floorInfo.parentMapId);
    }

    public get currentMapId(): IGuid | undefined {
        return this.mapService.currentMapEntity?.id;
    }

    public selectedLayers$: Observable<ListItem[]>;

    private hoverTimeouts = new Map<string, number>();
    private initialLoadDone = false;

    private scClient: WebAppClient;

    private mapEntryParams: MapEntryParams | undefined;
    private activeContent!: ContentGroup | null;
    private wasMapEntityBrowserRefreshed = false;
    private keepViewWhenChangingMap = false;
    private floorSwitched = false;
    private isCtrlKeyPressed = false;
    private lastSearchInstanceId = SafeGuid.EMPTY;

    private isSearchingSubject = new BehaviorSubject<boolean>(false);
    private isLoadingContextMenuSubject = new BehaviorSubject<boolean>(false);
    private layersAsListItemsSubject = new BehaviorSubject<ListItem[]>([]);
    private minimapTooltipSubject = new BehaviorSubject<string>('');

    private minimizeMiniMapTooltip = this.translateService.instant('STE_TOOLTIP_MINIMIZE_MINIMAP') as string;
    private restoreMiniMapTooltip = this.translateService.instant('STE_TOOLTIP_RESTORE_MINIMAP') as string;

    private readonly defaultMapBounds = new LatLngBounds([-45, -90], [45, 90]);

    constructor(
        private loggerService: LoggerService,
        private pluginService: PluginService,
        public mapService: StateMapService,
        private translateService: TranslateService,
        securityCenterProvider: SecurityCenterClientService,
        private contentProviderService: ContentProviderService,
        private route: ActivatedRoute,
        private searchService: SearchService,
        private mapPopupService: MapPopupService,
        private navigationService: NavigationService,
        @Inject(COMMANDS_SERVICE) public commandsService: InternalCommandsService,
        @Inject(MAPS_BOTTOM_CONTEXT_SERVICE) public bottomContextDataService: MapsBottomContextDataService,
        trackingService: TrackingService,
        private iconService: IconsService,
        private eventsService: EventsService, // Keep reference to register event filters in maps task
        @Inject(WINDOW) private window: Window,
        private changeDetectorRef: ChangeDetectorRef,
        private viewContainerRef: ViewContainerRef
    ) {
        super(trackingService);

        this.scClient = securityCenterProvider?.scClient;
        this.isSearching$ = this.isSearchingSubject.asObservable();
        this.isLoadingContextMenu$ = this.isLoadingContextMenuSubject.asObservable();
        this.layersAsListItems$ = this.layersAsListItemsSubject.asObservable();
        this.minimapTooltip$ = this.minimapTooltipSubject.asObservable();
        this.selectedLayers$ = this.layersAsListItems$.pipe(map((layers) => layers.filter((layer) => layer.isChecked)));
    }

    @HostListener('document:keydown', ['$event'])
    public handleKeyDownEvent(event: KeyboardEvent): void {
        if (event.ctrlKey) {
            this.isCtrlKeyPressed = true;
        }
    }

    @HostListener('document:keyup', ['$event'])
    public handleKeyUpEvent(event: KeyboardEvent): void {
        if (event.key === 'Control') {
            this.isCtrlKeyPressed = false;
        }
    }
    public ngOnInit() {
        super.ngOnInit();

        this.mapTrayPlugins$ = from(this.pluginService.getPlugins(PluginTypes.MapTray));

        this.toggleMiniMapButton$ = this.mapService.onMiniMapStateChanged$.pipe(
            tap((event) => this.minimapTooltipSubject.next(event.minimized ? this.restoreMiniMapTooltip : this.minimizeMiniMapTooltip)),
            map(() => document.querySelector('.leaflet-control-minimap-toggle-display'))
        );

        this.sideContextCurrentContent$.pipe(untilDestroyed(this)).subscribe((content) => {
            if (this.mapService.map?.isMapInitialized()) {
                if (!content) {
                    this.mapService.setStateSelectedMapObject(undefined);
                    this.mapService.map.clearSelection();
                    this.mapService.map.setCenterViewPaddingBottomRight([0, 0]);
                } else {
                    this.mapService.map.setCenterViewPaddingBottomRight([SidePaneComponent.WidthPx, 0]);
                }
            }
        });

        this.route.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
            // When changing task from map to another, no need for further checks
            if (this.route.snapshot.url[1].toString().toLowerCase() !== MapsTaskComponent.pluginId.toLowerCase()) {
                return;
            }

            this.mapEntryParams = this.extractMapEntryParamsFromQueryParams(params);

            // If the map is already loaded, apply the parameters now
            if (this.initialLoadDone) {
                this.processLocationFromRoute();
            }

            // Update parent
            const oldParentAreaId = this.floorInfo.parentAreaId ?? SafeGuid.EMPTY;
            this.floorInfo.parentAreaId = this.mapEntryParams.parentAreaId ?? null;

            // If a map change was requested
            if (this.mapEntryParams.mapId && !this.mapEntryParams.mapId.equals(this.mapService.currentMapEntity?.id)) {
                await this.setCurrentMap(this.mapEntryParams.mapId);
            } else if (this.mapService.currentMapEntity && this.mapEntryParams.mapId && !oldParentAreaId.equals(this.mapEntryParams.parentAreaId ?? SafeGuid.EMPTY)) {
                // Setup floors if only the parent changed (floor configuration will change even if the map doesn't)
                await this.setupFloors();
            } else if (!this.initialLoadDone && !this.mapService.stateSelectedMap) {
                // Default one if none specified
                this.clearSideContextContent();
                this.mapService.setStateViewArea(undefined);
                this.mapService.setStateSelectedMap(undefined);
            }

            // Subscribe once all query params have been read and applied
            if (!this.initialLoadDone) {
                this.subscribeMapService();
                this.initialLoadDone = true;
            }
        });
    }

    ngOnDestroy() {
        try {
            this.clearSideContextContent();
            if (this.mapService.map?.isMapInitialized()) {
                this.mapService.setStateSelectedMap(this.mapService.currentMapEntity?.id);
                this.mapService.setStateViewArea(this.mapService.map.getBounds());
                this.mapService.emptyMap(true);
            }
        } catch (exception) {
            console.log(exception);
        }

        super.ngOnDestroy();
    }

    public canSelectMap(model: EntityBrowserItemModel): Promise<boolean> {
        if (model instanceof AreaBrowserItemModel) {
            return Promise.resolve(!model.map.isEmpty());
        }
        return Promise.resolve(false);
    }

    // Needs this indirection to have access to component's instance
    public onMapSearch = async (searchText: string): Promise<MapSearchInputItem[]> => {
        const currentSearchId = SafeGuid.newGuid();
        try {
            this.isSearchingSubject.next(true);
            const searchResult = await this.search(searchText, currentSearchId);
            return searchResult;
        } finally {
            // Is a new search more recent in progress?
            if (currentSearchId.equals(this.lastSearchInstanceId)) {
                this.isSearchingSubject.next(false);
            }

            // Mark the gen-search-input for check so the results are updated correctly
            this.changeDetectorRef.markForCheck();
        }
    };

    public async onMapSelected(selection: EntityBrowserSelection): Promise<void> {
        if (selection && !selection.singleId.isEmpty()) {
            const area = await this.scClient.getEntityAsync<AreaEntity, IAreaEntity>(
                AreaEntity,
                selection.singleId,
                null,
                null,
                [AreaEntityFields.linkField, AreaEntityFields.customIconIdField].toString()
            );
            if (area?.link) {
                this.currentMapAreaId = area.id;
                this.currentMapAreaCustomIconId = area.customIconId;
                const currentMapId = this.mapService.currentMapEntity?.id;
                if (
                    !currentMapId ||
                    !currentMapId.equals(area.link) ||
                    !this.mapService.mapAvailable ||
                    !this.floorInfo.parentMapId?.equals(selection.singleItem?.parent?.itemId ?? SafeGuid.EMPTY)
                ) {
                    // Keep view if selected map is a sibling floor
                    this.keepViewWhenChangingMap = !!this.floorInfo.floors.find((floor) => floor.id === currentMapId);
                    this.updateEntityBrowserSelectedId(area.id);
                    const itemId = selection.singleItem?.parent?.itemId;
                    await this.navigateToMapAsync(area.link, itemId ? SafeGuid.parse(itemId) : undefined);
                }

                // Mark for check for:
                // - mapService.currentMapEntity?.name
                // - this.currentMapAreaId
                // - this.currentMapAreaCustomIconId
                this.changeDetectorRef.markForCheck();
            }

            await this.mapSelectionBrowser.close();
        }
    }

    public async toggleLayerSelection(): Promise<void> {
        await this.mapLayersBrowser.toggle();
    }

    public async toggleMapSelectionAsync(): Promise<void> {
        await this.mapSelectionBrowser.toggle();

        if (this.mapSelectionBrowser.open) {
            let needRefresh = false;
            const [entityBrowserSelectedId] = this.mapSelectionBrowserFilter.selectedEntityIds;
            if (
                this.mapService.currentMapEntity?.associatedArea &&
                (!entityBrowserSelectedId || !entityBrowserSelectedId.equals(this.mapService.currentMapEntity?.associatedArea))
            ) {
                this.updateEntityBrowserSelectedId(this.mapService.currentMapEntity.associatedArea);
                needRefresh = true;
            }

            if (needRefresh || !this.wasMapEntityBrowserRefreshed) {
                await this.mapEntityBrowser.refreshAsync(this.mapSelectionBrowserFilter);
                this.wasMapEntityBrowserRefreshed = true;
            }
        }
    }

    public onSelectedItemsChange(item: ListItem, isChecked: boolean): void {
        item.isChecked = isChecked;
        this.applyLayersSelection();
    }

    public applyLayersSelection = (): void => {
        this.selectedLayers$
            .pipe(
                map((layers) => layers.map((layer) => SafeGuid.parse(layer.id))),
                take(1),
                untilDestroyed(this)
            )
            .subscribe((selectedLayers) => {
                this.mapService.setVisibleLayers(selectedLayers).fireAndForget();
            });
    };

    public onMapSearchResultSelected(itemSelected: MapSearchInputItem): void {
        if (!this.mapService.map?.isMapInitialized()) return;

        const id = itemSelected.id;

        // first, look if the returned results is a map object present on map
        if (this.mapService.map.mapObjectViewExists(id)) {
            this.mapService.map.select(itemSelected.id);
            this.mapService.map.centerMapObject(itemSelected.id);
        }

        // if the item has a navigation path, execute it
        if (itemSelected.navigation) {
            this.navigationService.navigate(itemSelected.navigation).fireAndForget();
        }
    }

    public clearSearch(): void {
        this.searchText = '';
    }

    public onZoomIn(): void {
        this.mapService.map?.zoomIn();
    }

    public onZoomOut(): void {
        this.mapService.map?.zoomOut();
    }

    public onPresetClick(view: IDefaultView): void {
        this.mapService.map?.fitBounds(this.defaultViewToLatLngBounds(view), { animate: true });
    }

    public onContextMenu(event: MouseEvent): void {
        event.preventDefault();
    }

    public onMapControlWidthChange(): void {
        this.updateMiniMapPosition();
    }

    // Remove when Gelato bug https://dev.azure.com/GenetecCentral/UXDev/_workitems/edit/46086 is fixed and merged in our package.
    // Needed because panning the map won't close context menu (only closes on automatically on mouseup).
    public onMouseDown(): void {
        if (this.linkContextMenu?.open) {
            this.linkContextMenu.close().fireAndForget();
        }
    }

    public onMouseUp(evt: MouseEvent): void {
        // right-click, double
        if (evt.button === 2 && evt.detail === 2) {
            this.onZoomOut();
        }
    }

    public onClick(evt: MouseEvent): void {
        this.clickPositionX = evt.clientX;
        this.clickPositionY = evt.clientY;
    }

    public async onLinkClick(clickedItem: ContextMenuItem): Promise<void> {
        if (clickedItem.id) {
            await this.navigateToLink(clickedItem.id.toString());
        }
    }

    public switchFloor(floor: IFloor): void {
        this.keepViewWhenChangingMap = !this.isCtrlKeyPressed;
        this.floorSwitched = true;
        this.requestMapChange(floor.id);
    }

    public isFloorSelected(floor: IFloor): boolean {
        return floor === this.selectedFloor;
    }

    public getSortedFloors(): IFloor[] {
        return this.floorInfo.floors.sort((floorA, floorB) => floorB.orderPosition - floorA.orderPosition);
    }

    public goToParentMap(): void {
        const parentMapId = this.floorInfo.parentMapId;
        this.clearMapFloorInfo();
        this.requestMapChange(parentMapId ?? undefined);
    }

    private subscribeMapService(): void {
        this.mapService.stateSelectedMap$
            .pipe(
                tap(() => (this.initialMapLoadItemsDone = false)),
                switchMap((selectedMap) =>
                    from(
                        this.mapService.initializeMap({
                            mapId: selectedMap ?? null,
                            withEvents: true,
                            userLocationEnabled: true,
                            previousViewArea: (this.keepViewWhenChangingMap && this.mapService.map?.getBounds()) || this.mapService.stateViewArea,
                        })
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe();

        this.mapService.onMapObjectSelected$.pipe(untilDestroyed(this)).subscribe((event) => this.onMapObjectSelected(event));
        this.mapService.onMapObjectUnselected$.pipe(untilDestroyed(this)).subscribe((view) => this.onMapObjectUnselected(view));
        this.mapService.onMapObjectMouseEnter$.pipe(untilDestroyed(this)).subscribe((view) => this.onMouseEnter(view));
        this.mapService.onMapObjectMouseLeave$.pipe(untilDestroyed(this)).subscribe((view) => this.onMouseLeave(view));
        this.mapService.onMapTypesLoaded$.pipe(untilDestroyed(this)).subscribe(() => this.onMapTypesLoaded());
        this.mapService.onMapLoaded$.pipe(untilDestroyed(this)).subscribe(() => this.onMapLoaded());
        this.mapService.onEventAdded$.pipe(untilDestroyed(this)).subscribe((mapEvent) => this.onEventAdded(mapEvent));
        this.mapService.onEventRemoved$.pipe(untilDestroyed(this)).subscribe((mapEvent) => this.onEventRemoved(mapEvent));
        this.mapService.onMapUnavailable$.pipe(untilDestroyed(this)).subscribe((error) => this.onMapUnavailable(error));
        this.mapService.onSelectionRequested$.pipe(untilDestroyed(this)).subscribe((data) => this.select(data));
        this.mapService.onUpdateContextualDataRequested$.pipe(untilDestroyed(this)).subscribe((data) => this.updateSidePaneWithContent(data));
        this.mapService.onClearContextualDataRequested$.pipe(untilDestroyed(this)).subscribe((id) => this.clearSidePaneWithContent(id));
    }

    private async setCurrentMap(selectedMap?: IGuid, sameBuilding = false) {
        let selectedMapId: IGuid | undefined;
        if (selectedMap && !selectedMap.isEmpty()) {
            selectedMapId = selectedMap;
            const entity = await this.scClient.getEntityAsync<Entity, IEntity>(Entity, selectedMap, null, null, AreaEntityFields.linkField);
            if (entity instanceof AreaEntity) {
                selectedMapId = entity.link;
            }
        }

        this.clearSideContextContent();
        this.mapService.setStateViewArea(undefined);
        this.mapService.setStateSelectedMap(selectedMapId);
    }

    private async insertAllMapObjects(_: IMapEntity) {
        if (!this.initialMapLoadItemsDone) {
            this.initialMapLoadItemsDone = true;

            await this.setLayerFilters();
        }
    }

    private processLocationFromRoute(): boolean {
        let result = false;

        if (this.mapEntryParams) {
            const lat = this.mapEntryParams.viewLat;
            const lng = this.mapEntryParams.viewLng;
            this.mapEntryParams.viewLat = undefined;
            this.mapEntryParams.viewLng = undefined;
            if (lat != null && lng != null && !isNaN(lat) && !isNaN(lng)) {
                const offset = 0.001;
                this.mapService.map?.fitBounds([
                    [lat + offset, lng - offset],
                    [lat - offset, lng + offset],
                ]);
                // be sure no last selection is made when having lat lng position.
                this.mapService.setStateSelectedMapObject(undefined);
                result = true;
            }
        }

        return result;
    }

    private async setLayerFilters() {
        // by getting the notification filter, we get the available layers for the currently viewing map
        const notificationFilter = await this.mapService.getNotificationFilter();

        const layers = this.mapService.layers;
        if (layers) {
            this.layersAsListItemsSubject.next(
                Array.from(layers)
                    .map((layer) => {
                        return {
                            id: layer.id.toString(),
                            text: layer.name,
                            isChecked: notificationFilter?.layerFilters.firstOrDefault((item) => item.layerId.equals(layer.id))?.receiveNotifications,
                            icon: layer.icon,
                            image: layer.customIcon,
                            highlightColor: layer.color,
                        } as ListItem;
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
            );

            this.applyLayersSelection();
        }
    }

    private select(data: MapSelectionData): void {
        const sameMap = !data.mapId || this.mapService.currentMapEntity?.id.equals(data.mapId);
        if (sameMap) {
            // select right away
            this.mapService.selectAndZoom(data.mapObjectId);
        } else {
            this.requestMapChange(data.mapId, SafeGuid.parse(data.mapObjectId));
        }
    }

    private async updateSidePane(sourceId: string) {
        if (sourceId.length > 0) {
            this.setLoadingMainContent();
            if (!this.activeContent || !(this.activeContent.mainContent.source.toLowerCase() === sourceId.toLowerCase())) {
                this.activeContent = await this.contentProviderService.getContentAsync(sourceId, KnownContentContext.Map);
            }
            this.setSideContextMainContent(this.activeContent);
            this.loggerService.traceDebug(
                `Map-task updateSidePane with source ID ${sourceId}: Make sure this warning doesnt appear more than once consequently! May be an indication of double widgets in sidepane`
            );
        }
    }

    // This method is used when someone externally wants to update the side pane without any selection
    private updateSidePaneWithContent(content: ContentGroup | null) {
        // clear selection
        if (this.mapService.map) {
            this.mapService.setStateSelectedMapObject(undefined);
            this.mapService.map.clearSelection();
        }

        // update side pane with data provided
        this.activeContent = content;
        this.setSideContextMainContent(this.activeContent);
        this.loggerService.traceDebug(
            `Map-task updateSidePaneWithContent with content ID ${
                content?.id.toString() ?? 'unknown'
            }: Make sure this warning doesnt appear more than once consequently! May be an indication of double widgets in sidepane`
        );
    }

    private clearSidePaneWithContent(contentId: IGuid) {
        if (this.activeContent?.id.equals(contentId)) {
            this.activeContent = null;
            this.clearSideContextContent();
            this.loggerService.traceDebug(`Map-task with content ID ${contentId.toString()}: Make sure this warning coresponds the same number of times as the later one`);
        }
    }

    private async navigateToLink(linkId: string, supportsNavigation = true): Promise<void> {
        if (!SafeGuid.isGuid(linkId)) {
            // not a guid, no link can be discovered, simply update the pane
            await this.updateSidePane(linkId);
            return;
        }

        let canNavigate = false;
        if (isGuid(linkId)) {
            let navigationLink = SafeGuid.parse(linkId);
            if (!navigationLink.isEmpty()) {
                if (supportsNavigation) {
                    const entity = await this.scClient.getEntityAsync<Entity, IEntity>(Entity, navigationLink, null, null, AreaEntityFields.linkField);
                    if (entity?.hasField(AreaEntityFields.linkField)) {
                        const mapId = entity.getFieldGuid(AreaEntityFields.linkField);
                        if (mapId && !mapId.isEmpty()) {
                            navigationLink = mapId;
                            canNavigate = true;
                        }
                    }
                }

                if (canNavigate) {
                    const mapEntity = await this.scClient.getEntityAsync<MapEntity, IMapEntity>(MapEntity, navigationLink);
                    if (mapEntity) {
                        await this.navigateToMapAsync(SafeGuid.parse(linkId));
                    }
                } else {
                    // couldn't resolve as a navigation link, just update the side pane
                    await this.updateSidePane(linkId);
                }
            }
        }
    }

    private viewSupportsNavigation(mapObjectView: MapObjectView) {
        const viewType = mapObjectView.data.type;
        const layers = this.mapService.layers;
        if (viewType && layers) {
            const viewTypeId = SafeGuid.parse(viewType);

            const supportNavigationTypes: IGuid[] = [];
            layers.forEach((layer) => {
                Array.from(layer.includedTypes.where((includedType) => includedType.supportsNavigation)).forEach((includedType) => {
                    supportNavigationTypes.push(includedType.typeId);
                });
            });
            return supportNavigationTypes.some((type) => type.equals(viewTypeId));
        }

        return false;
    }

    private closePopup(mapObjectView: MapObjectView) {
        this.mapPopupService.closePopup(mapObjectView);
    }

    private async displayPopupAsync(mapObjectView: MapObjectView, contentGroup: ContentGroup, options: ShowPopupOptions) {
        if (options.delayToCloseMs !== undefined && this.mapPopupService.getMapPopupComponent(mapObjectView)?.isPermanent) {
            return;
        }

        await this.mapPopupService.showPopupAsync(mapObjectView, contentGroup, this.viewContainerRef, options);
    }

    private async setupFloors() {
        if (this.mapService.currentMapEntity?.id) {
            const mapId = this.mapService.currentMapEntity.id;
            const floorQueryResult = await this.mapService.getFloors(mapId, this.floorInfo.parentAreaId ?? SafeGuid.EMPTY);

            // Check again, could have changed task while loading floors
            if (this.mapService.currentMapEntity?.id) {
                const newFloorInfo: MapFloorInfo = { ...this.floorInfo };
                if (floorQueryResult) {
                    newFloorInfo.parentAreaId = floorQueryResult.parentAreaId;
                    newFloorInfo.parentMapId = floorQueryResult.parentMapId;
                }

                const queryParentId = this.route.snapshot.queryParams.parent as string | undefined;
                // Only update query params to set the parent (if it changed)
                if (isNonEmptyGuid(newFloorInfo.parentAreaId) && !newFloorInfo.parentAreaId?.equals(queryParentId)) {
                    this.navigateToMapAsync(mapId, newFloorInfo.parentAreaId).fireAndForget();
                }

                newFloorInfo.floors = floorQueryResult?.floors ? Array.from(floorQueryResult.floors) : [];
                this.floorInfo = newFloorInfo;
                this.selectedFloor = this.getFloorInfoData(this.mapService.currentMapEntity.id);
            }
        }
    }

    private clearMapFloorInfo() {
        this.floorInfo.floors = [];
        this.floorInfo.parentAreaId = null;
        this.floorInfo.parentMapId = null;
    }

    private extractMapEntryParamsFromQueryParams(params: Params): MapEntryParams {
        const mapEntryParams = new MapEntryParams();

        if (typeof params.id === 'string' && isGuid(params.id)) {
            mapEntryParams.mapId = SafeGuid.parse(params.id);
        }
        if (!isNaN(Number(params.lat))) {
            mapEntryParams.viewLat = +params.lat;
        }
        if (!isNaN(Number(params.lng))) {
            mapEntryParams.viewLng = +params.lng;
        }
        if (params.mapObjectId && SafeGuid.isGuid(params.mapObjectId)) {
            mapEntryParams.selectedMapObjectId = params.mapObjectId as string;
        }
        if (params.parent && isGuid(params.parent)) {
            mapEntryParams.parentAreaId = SafeGuid.parse(params.parent.toString());
        }

        return mapEntryParams;
    }

    private updateMiniMapPosition() {
        this.mapService?.setMiniMapRightMargin(this.appMapControls.width);
    }

    private isDefaultViewValid(view: IDefaultView) {
        return !(
            view.viewArea.bottomRight.latitude === 0 &&
            view.viewArea.bottomRight.longitude === 0 &&
            view.viewArea.topLeft.latitude === 0 &&
            view.viewArea.topLeft.longitude === 0
        );
    }

    private navigateToMapAsync(mapId: IGuid, parentAreaId?: IGuid, selectedMapObjectId?: IGuid) {
        let navigation = `/task/${MapsTaskComponent.pluginId.toString()}/?id=${mapId.toString()}`;

        if (isNonEmptyGuid(parentAreaId)) {
            navigation += `&parent=${parentAreaId.toString()}`;
        }
        if (isNonEmptyGuid(selectedMapObjectId)) {
            navigation += `&mapObjectId=${selectedMapObjectId.toString()}`;
        }
        return this.navigationService.navigate(navigation);
    }

    private async setupPresets(defaultView: IDefaultView | null, mapEntity: IMapEntity) {
        this.defaultView = defaultView ?? undefined;
        const presetViews = await mapEntity.getViewAreaPresetsAsync();
        this.presets = presetViews ? Array.from(presetViews) : [];
    }

    private defaultViewToLatLngBounds(view: IDefaultView) {
        const bounds = new LatLngBounds(
            { lat: view.viewArea.bottomRight.latitude, lng: view.viewArea.topLeft.longitude },
            { lat: view.viewArea.topLeft.latitude, lng: view.viewArea.bottomRight.longitude }
        );
        return this.isDefaultViewValid(view) ? bounds : this.defaultMapBounds;
    }

    private async onMapLoaded() {
        this.keepViewWhenChangingMap = false;
        const currentMap = this.mapService.currentMapEntity;
        if (currentMap) {
            this.selectedFloor = this.getFloorInfoData(currentMap.id);
            if (this.floorInfo.floors.length && !this.selectedFloor) {
                this.clearMapFloorInfo();
            }

            const defaultView = await currentMap.getDefaultViewAsync();
            const setupPresetsPromise = this.setupPresets(defaultView, currentMap);

            let setupFloorsPromise: Promise<void> | null = null;
            if (!this.floorSwitched) {
                setupFloorsPromise = this.setupFloors();
            }

            await Promise.all([setupPresetsPromise, setupFloorsPromise]);

            this.floorSwitched = false;
            this.updateMiniMapPosition();
        }
    }

    private getFloorInfoData(id?: IGuid): IFloor | null {
        return id ? this.floorInfo.floors.find((floor) => floor.id.equals(id)) ?? null : null;
    }

    private requestMapChange(id: IGuid | undefined, selectedMapObjectId?: IGuid) {
        // Set it immediately so that the UI can reflect the selected floor before the map is loaded
        this.selectedFloor = this.getFloorInfoData(id);
        this.clearSideContextContent();
        this.mapService.setStateViewArea(undefined);
        if (id) {
            this.navigateToMapAsync(id, this.floorInfo.parentAreaId ?? undefined, selectedMapObjectId).fireAndForget();
        }
    }

    private async onMapTypesLoaded() {
        let zoomOnSelectedMapObject = false;
        let selectedMapObject = this.mapService.stateSelectedMapObject?.toString();
        if (this.mapEntryParams?.selectedMapObjectId) {
            selectedMapObject = this.mapEntryParams.selectedMapObjectId;
            this.mapEntryParams.selectedMapObjectId = undefined;
            zoomOnSelectedMapObject = true;
        }

        if (this.mapService.currentMapEntity) {
            await this.insertAllMapObjects(this.mapService.currentMapEntity);
        }

        if (selectedMapObject) {
            if (zoomOnSelectedMapObject) {
                this.mapService.selectAndZoom(selectedMapObject);
            } else {
                this.mapService.map?.select(selectedMapObject);
            }
        }
    }

    private updateEntityBrowserSelectedId(id: IGuid) {
        this.mapSelectionBrowserFilter.selectedEntityIds.clear();
        this.mapSelectionBrowserFilter.selectedEntityIds.add(id);
    }

    private async search(searchText: string, searchInstanceId: IGuid): Promise<MapSearchInputItem[]> {
        const searchResults: MapSearchInputItem[] = [];
        const currentMap = this.mapService.currentMapEntity;
        const layers = this.mapService.layers;
        if (!currentMap || !layers || !this.mapService.map) {
            return searchResults;
        }

        if (searchText.length > 0) {
            const context = new MapSearchContext();
            context.text = searchText;
            context.mapId = new SafeGuid(currentMap.id);
            const bounds = this.mapService.map.getBounds();

            // only apply the view area if the map is geolocalized otherwise, the controller's
            // validator will throw an exception
            if (this.mapService.currentMapEntity?.geoLocalized === true) {
                const nw = bounds.getNorthWest().wrap();
                const se = bounds.getSouthEast().wrap();
                const viewArea = new MapViewArea({
                    topLeft: new GeoCoordinate({
                        latitude: nw.lat,
                        longitude: nw.lng,
                    }),
                    bottomRight: new GeoCoordinate({
                        latitude: se.lat,
                        longitude: se.lng,
                    }),
                });

                context.viewArea = viewArea;
            }

            if (this.mapService.displayedLayerIds?.length > 0) {
                context.activeLayers = this.mapService.displayedLayerIds;
            }

            this.lastSearchInstanceId = searchInstanceId;
            const results = await this.searchService.getSearchResults(context);

            // Is a new search more recent in progress?
            if (!searchInstanceId.equals(this.lastSearchInstanceId)) {
                return searchResults;
            }

            if (results.length > 0) {
                results.forEach((group) => {
                    group.results.forEach((item) => {
                        const searchResult = new MapSearchInputItem(item.id.toString(), item.title);
                        searchResult.groupName = group.groupName;
                        searchResult.icon = item.icon as MeltedIcon;
                        searchResult.navigation = item.navigation ?? undefined;
                        searchResults.push(searchResult);
                    });
                });
            } else {
                const searchResult = new MapSearchInputItem(SafeGuid.EMPTY.toString(), this.translateService.instant('STE_LABEL_NORESULTS') as string);
                searchResults.push(searchResult);
            }
        }

        return searchResults;
    }

    private onEventAdded(event: MapObjectAddOrUpdateEvent): void {
        if (!this.mapService.map?.isMapInitialized()) return;

        const displayedMapObjects: MapObjectView[] = this.mapService.filterEventsReceiverItems(Array.from(event.mapObjectIds), event.eventDisplayTime !== undefined);

        if (displayedMapObjects.length > 0) {
            const contentGroup = this.contentProviderService.getContentFromString(event.event);
            displayedMapObjects.forEach(async (mapObject) => {
                if (mapObject && contentGroup) {
                    await this.displayPopupAsync(mapObject, contentGroup, {
                        // If temporary popup, close on mouse leave
                        closeOnMouseLeave: event.eventDisplayTime !== undefined,
                        delayToCloseMs: event.eventDisplayTime,
                        expandOnHover: true,
                    });
                }
            });
        }
    }

    private onMapUnavailable(error: string) {
        this.mapUnavailableReason = error;
        this.toggleMapSelectionAsync().fireAndForget();
    }

    private onEventRemoved(event: MapObjectRemoveEvent): void {
        if (!this.mapService.map?.isMapInitialized() || !event.removeEventContentOnly) return;

        const lealfetMap = this.mapService.map;
        event.mapObjectIds.forEach((mapObjectId) => {
            const view = lealfetMap.getMapObjectView(mapObjectId.toString());
            if (view) {
                this.closePopup(view);
            }
        });
    }

    private onMapObjectUnselected(mapObjectView: MapObjectView) {
        // clear side pane
        this.activeContent = null;
        this.setSideContextMainContent(this.activeContent);
    }

    private async onMapObjectSelected(event: MapObjectEvent) {
        if (event.mapObjectView) {
            const mapObjectView = event.mapObjectView;
            const viewId = SafeGuid.parse(mapObjectView.id);

            // if any popup, close it
            this.closePopup(mapObjectView);

            const links: string[] = [];
            const supportsNavigation = this.viewSupportsNavigation(mapObjectView);
            if (supportsNavigation) {
                const mapObjects = await this.mapService.getMapObjectsFromIds(SafeGuid.createSet([viewId]));
                if (mapObjects) {
                    const mapObject = mapObjects[0];
                    links.push(...Array.from(mapObject.additionalLinks, (item) => item.toString()));
                }
            }

            const viewData = mapObjectView.data;
            if (viewData?.link && viewData.link.length > 0) {
                if (SafeGuid.isGuid(viewData.link)) {
                    const link = SafeGuid.parse(viewData.link);
                    if (!link.isEmpty()) {
                        if (!links.find((item) => item === viewData.link)) {
                            links.push(viewData.link);
                        }
                    }
                } else {
                    links.push(viewData.link);
                }
            }

            if (links.length === 1) {
                // Check to see if the sidepane opened due to navigating to the link
                this.sideContextCurrentContent$.pipe(takeUntil(timer(500))).subscribe((content) => {
                    if (content && this.mapService.map) {
                        // Only center the map object in the case of a click if the map object would be behind the sidepane
                        if (event.originalEvent?.type === 'click') {
                            const layerPoint = (event.originalEvent as LeafletMouseEvent).containerPoint as Point | undefined;
                            const container = this.mapService.map.getContainer();
                            if (layerPoint && container && layerPoint.x >= container.clientWidth - SidePaneComponent.WidthPx) {
                                this.mapService.map.centerMapObject(mapObjectView.id);
                            }
                        } else {
                            // If it's not a click and the map object has been selected programmatically, we want to center the map object.
                            this.mapService.map.centerMapObject(mapObjectView.id);
                        }
                    }
                });
                this.navigateToLink(links[0], supportsNavigation)
                    .then(() => {
                        this.mapService.setStateSelectedMapObject(viewId);
                    })
                    .fireAndForget();
            } else if (links.length > 0) {
                this.displayLinksContextMenu(links);
            }
        }
    }

    private onMouseEnter(mapObjectView: MapObjectView) {
        if (mapObjectView.options.selected || mapObjectView.isPopupOpen()) {
            return;
        }
        if (!this.hoverTimeouts.has(mapObjectView.id)) {
            const timeout = this.window.setTimeout(async () => {
                if (mapObjectView && !mapObjectView.options.selected) {
                    const link = mapObjectView?.data?.link;
                    if (link) {
                        const contentGroup = await this.contentProviderService.getContentAsync(link, KnownContentContext.Map);
                        if (contentGroup && !mapObjectView.options.selected) {
                            this.activeContent = contentGroup;
                            await this.displayPopupAsync(mapObjectView, contentGroup, {
                                closeOnMouseLeave: true,
                                expandOnHover: false,
                            });
                        }
                    }
                }
                this.hoverTimeouts.delete(mapObjectView.id);
            }, 500);

            this.hoverTimeouts.set(mapObjectView.id, timeout);
        }
    }

    private onMouseLeave(mapObjectView: MapObjectView) {
        const timeout = this.hoverTimeouts.get(mapObjectView.id);
        if (timeout) {
            clearTimeout(timeout);
            this.hoverTimeouts.delete(mapObjectView.id);
        }
    }

    private displayLinksContextMenu(links: string[]): void {
        this.contextMenuLinks$ = this.getContextMenuFromLinks(links).pipe(
            tap(() => {
                // be sure to clear selection as we have not yet selected any source (since multiple links for this item).
                this.mapService.setStateSelectedMapObject(undefined);
                this.mapService.map?.clearSelection();
            })
        );
    }

    private getContextMenuFromLinks(links: string[]): Observable<ContextMenuItem[]> {
        const query = new EntityQuery();
        query.fields.add(EntityFields.nameField);
        query.fields.add(EntityFields.entityTypeField);

        for (const link of links) {
            const tryGuid = SafeGuid.tryParse(link);
            if (tryGuid.success) {
                query.guids.add(tryGuid.value);
            }
        }

        const getEntities$ = from(this.scClient?.getEntitiesAsync<Entity, IEntity>(Entity, query, null, null));
        return getEntities$.pipe(
            tap(() => this.isLoadingContextMenuSubject.next(true)),
            tap(() => {
                if (!links.length) {
                    this.linkContextMenu?.close().fireAndForget();
                }
            }),
            filter(() => links.length > 0),
            tap(() => this.linkContextMenu?.show().fireAndForget()),
            map((entities) =>
                entities.map((entity) => {
                    return {
                        id: entity.id.toString(),
                        text: entity.name,
                        icon: ('gen-ico-' + (this.iconService.getEntityTypeIcon(entity.entityType) as string)) as MeltedIcon,
                        actionItem: {
                            execute: (item: ContextMenuItem) => this.onLinkClick(item),
                        },
                    };
                })
            ),
            tap((menuItems) => {
                if (menuItems.length === 0) {
                    this.linkContextMenu?.close().fireAndForget();
                }
            }),
            tap(() => this.isLoadingContextMenuSubject.next(false))
        );
    }

    private clearSideContextContent() {
        this.setSideContextMainContent(null);
    }
}
