// Autogenerated: v2/help/Entities/ApiUserGroup?codegen=ts&InterfaceOnly=True&workflow=False on 2021-04-30 8:57:47 AM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity } from '../Interface/IEntity';
import { IPrivilegedEntity, PrivilegedEntityFields } from './IPrivilegedEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class UserGroupEntityFields extends PrivilegedEntityFields {
    public static externalUniqueIdField = 'ExternalUniqueId';
    public static externalUniqueIdProviderField = 'ExternalUniqueIdProvider';
    public static skipPtzEvaluationField = 'SkipPtzEvaluation';
}

// #endregion Fields

export interface IUserGroupEntity extends IPrivilegedEntity {
    // #region REST Properties

    externalUniqueId: string;

    externalUniqueIdProvider: string;

    skipPtzEvaluation: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersRecursiveAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

