import { Inject, Injectable } from '@angular/core';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { WINDOW } from '@src/app/utilities';
import { LogSeverity } from 'RestClient/Client/Enumerations/LogSeverity';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { EventTypeData } from './event-type-data';

@Injectable({
    providedIn: 'root',
})
export class EventsSettingsService {
    public listeningEvents$: Observable<EventTypeData[]>;
    private readonly defaultEventsLocalStorageKey = 'defaultListeningEvents';
    private readonly listeningEventsOptionsKey = 'listeningEvents';

    constructor(@Inject(USER_SETTINGS_SERVICE) private userSettingsService: SettingsService, @Inject(WINDOW) private window: Window) {
        this.listeningEvents$ = this.userSettingsService.onSettingsChanged$.pipe(
            map(() => this.getListeningEventsSettings()),
            filter((events) => !!events)
        ) as Observable<EventTypeData[]>;
    }

    public getListeningEventsSettings(): EventTypeData[] | undefined {
        const eventsData = this.userSettingsService.get<string>(OptionTypes.Events, this.listeningEventsOptionsKey);
        return eventsData ? (JSON.parse(eventsData) as EventTypeData[]) : undefined;
    }

    public setListeningEventsSettings(defaultEvents: EventTypeData[]): void {
        return this.userSettingsService.set(OptionTypes.Events, this.listeningEventsOptionsKey, this.serializeEvents(defaultEvents));
    }

    /**
     * Fetch the default events from the local storage
     *
     * @returns Default events if the storage was already set or undefined if not
     */
    public getListeningEventsFromLocalStorage(): EventTypeData[] | undefined {
        const eventsData = this.window.localStorage.getItem(this.defaultEventsLocalStorageKey);
        if (!eventsData) {
            return undefined;
        }
        try {
            return JSON.parse(eventsData) as EventTypeData[];
        } catch {
            if (window.scwLogSeverity.includes(LogSeverity.Warning)) {
                console.warn('Unable to parse listening events from local storage');
            }
        } // Ignore parsing errors
        return undefined;
    }

    public setListeningEventsToLocalStorage(defaultEvents: EventTypeData[]): void {
        this.window.localStorage.setItem(this.defaultEventsLocalStorageKey, this.serializeEvents(defaultEvents));
    }

    private serializeEvents(events: EventTypeData[]): string {
        return JSON.stringify(events);
    }
}
