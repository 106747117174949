import { Degree } from '../utils/Degree';
import { DragInfo } from '../utils/DragObserver';
import { ILiteEvent, LiteEvent } from '../utils/liteEvents';
import { ILogger } from '../utils/logger';
import { PolarVector } from '../utils/PolarVec2';
import { Radian } from '../utils/Radian';
import { Utils } from '../utils/Utils';
import { ClipSpaceVec2, Vec2 } from '../utils/Vec2';
import { CameraPositionCeiling, CameraPositionFloor, CameraPositionWall } from './CameraPosition';
import { ControllerUniforms } from './ControllerUniforms';
import { DewarpedPointSourceFinder } from './DewarpedPointSourceFinder';
import { IInternalDewarperControl } from './DewarperInterfaces';
import { InternalDewarperSourceImageParameters } from './DewarperSourceImageParameters';
import { DewarpingParameters } from './DewarpingParameters';
import { DewarpingPlane, PlaneEquation } from './DewarpingPlane';
import { ImageGeometry } from './ImageGeometry';
import { ZoomFactor } from './ZoomFactor';

export class DewarperController implements IInternalDewarperControl {
  private readonly m_logger: ILogger;
  private readonly m_dewarperSourceImageParameters: InternalDewarperSourceImageParameters;
  private m_imageGeometry: ImageGeometry | null; //Will be null until we have an image source to have a resolution to work with
  private m_dewarpingParameters: DewarpingParameters | null = null;

  private readonly m_controllerUniform: ControllerUniforms;

  private m_requestedImageXY: ClipSpaceVec2 = ClipSpaceVec2.Origin;
  private m_requestedZoom: ZoomFactor = ZoomFactor.Default;
  private m_dewarpingPlane: DewarpingPlane | null = null;

  private readonly m_sceneUpdatedEvent: LiteEvent<void>;

  public get sceneUpdated(): ILiteEvent<void> {
    return this.m_sceneUpdatedEvent.expose();
  }

  public get XY(): ClipSpaceVec2 {
    return this.m_requestedImageXY;
  }

  public get ZoomFactor(): ZoomFactor {
    return this.m_requestedZoom;
  }

  public get Pan(): Degree {
    return this.m_imageGeometry?.panFrom(this.m_requestedImageXY).toDegree() ?? new Degree(0);
  }

  public get Tilt(): Degree {
    return this.m_imageGeometry?.tiltFrom(this.m_requestedImageXY).toDegree() ?? new Degree(0);
  }

  public set DewarpingParameters(dewarpingParameters: DewarpingParameters) {
    this.m_dewarpingParameters = dewarpingParameters;
    this.m_imageGeometry = new ImageGeometry(dewarpingParameters);
    this.gotoXY(this.m_requestedImageXY);
  }

  constructor(logger: ILogger, dewarperSourceImageParameters: InternalDewarperSourceImageParameters, controllerUniform: ControllerUniforms) {
    this.m_logger = logger;
    this.m_dewarperSourceImageParameters = dewarperSourceImageParameters;
    this.m_imageGeometry = null;

    this.m_controllerUniform = controllerUniform;
    this.m_sceneUpdatedEvent = new LiteEvent<void>();
  }

  public gotoXY(requestedImageXY: ClipSpaceVec2): void {
    this.m_requestedImageXY = requestedImageXY;
    this.m_logger.intense?.trace(`gotoXY(${requestedImageXY.toString()}) [Image coordinates]`);
    this.createScene();
  }

  public gotoPanTilt(pan: Radian, tilt: Radian): void {
    if (this.m_imageGeometry === null) {
      this.m_logger.info?.trace(`gotoPanTilt(${pan.InRadian.toFixed(2)}R, ${tilt.InRadian.toFixed(2)}R) aborted. No image to work on.`);
      return;
    }

    this.m_logger.debug?.trace(`gotoPanTilt(${pan.InRadian.toFixed(2)}R, ${tilt.InRadian.toFixed(2)}R)`);

    switch (this.m_dewarperSourceImageParameters.CameraPosition.constructor) {
      case CameraPositionWall:
        {
          const panRatio = pan.InRadian / (this.m_dewarperSourceImageParameters.FovInRadians / 2);
          const tiltRatio = tilt.InRadian / (this.m_dewarperSourceImageParameters.FovInRadians / 2);

          const translated = this.m_requestedImageXY.plus(new Vec2(panRatio, tiltRatio));
          this.gotoXY(new ClipSpaceVec2(translated.X, translated.Y));
        }
        break;
      case CameraPositionCeiling:
        {
          const rotated = this.m_imageGeometry.rotateAroundCircle(this.m_requestedImageXY, pan);
          const rotatedAndTilted = this.m_imageGeometry.tiltInCircle(rotated, tilt);

          this.gotoXY(rotatedAndTilted);
        }
        break;
      case CameraPositionFloor:
        {
          const rotated = this.m_imageGeometry.rotateAroundCircle(this.m_requestedImageXY, pan.opposite());
          const rotatedAndTilted = this.m_imageGeometry.tiltInCircle(rotated, tilt.opposite());

          this.gotoXY(rotatedAndTilted);
        }
        break;
      default:
        throw new Error(`Unexpected camera position: ${this.m_dewarperSourceImageParameters}`);
    }

    this.triggerSceneChanged();
  }

  public zoom(zoomFactor: ZoomFactor): void {
    this.m_logger.info?.trace(`gotoZoom(${zoomFactor.toString()})`);
    this.m_requestedZoom = zoomFactor;
    this.createScene();
  }

  private m_crossCenter: boolean = false;

  public drag(dragInfo: DragInfo): void {
    if (dragInfo.StartOfDrag) {
      this.m_crossCenter = false;
    }
    this.m_logger.intense?.trace(`Drag (${dragInfo.toString()})`);

    if (this.m_imageGeometry === null) {
      this.m_logger.warn?.trace('Failed to drag while no frame has been rendered.');
      //Cannot work without the image source resolution. (or can we? using only ratio, it should be possible...)
      return;
    }

    //todo: put dewarping plane inside m_imageGeometry ?
    if (this.m_dewarpingParameters === null) {
      this.m_logger.warn?.trace('Failed to drag while no dewarping parameters was received. This should not happen if m_imageGeometry is set');
      //Cannot work without the image source resolution. (or can we? using only ratio, it should be possible...)
      return;
    }

    if (this.m_dewarpingPlane === null) {
      this.m_logger.warn?.trace('Failed to drag while no dewarping plane was created. This should not happen if m_imageGeometry is set');
      return;
    }

    const pointFinder = new DewarpedPointSourceFinder(this.m_dewarpingParameters, this.m_dewarpingPlane);
    const startImage = pointFinder.find(dragInfo.Start);
    const endImage = pointFinder.find(dragInfo.Stop);
    const startPolar = this.m_imageGeometry.imageToCircle(startImage).toPolar();
    const endPolar = this.m_imageGeometry.imageToCircle(endImage).toPolar();

    const radianMovement = endPolar.Angle.subtract(startPolar.Angle);
    const radiusMovement = this.m_crossCenter ? startPolar.Radius - endPolar.Radius : endPolar.Radius - startPolar.Radius;

    const requestedPolar = this.m_imageGeometry.imageToCircle(this.m_requestedImageXY).toPolar();

    const newRequestedPolar = new PolarVector(requestedPolar.Radius - radiusMovement, requestedPolar.Angle.subtract(radianMovement));

    if (radiusMovement > requestedPolar.Radius) {
      this.m_crossCenter = true;
    }

    const newRequestCartesian = newRequestedPolar.toCartesian();

    const newImageXY = this.m_imageGeometry.circleToImage(new ClipSpaceVec2(newRequestCartesian.X, newRequestCartesian.Y)).toClipSpace(this.m_dewarpingParameters.StreamResolution);
    this.gotoXY(newImageXY);
  }

  private triggerSceneChanged(): void {
    this.m_sceneUpdatedEvent.trigger();
  }

  private createScene(): void {
    if (this.m_imageGeometry === null) {
      this.m_logger.warn?.trace('Failed to build scene while no frame has been rendered.');
      //Cannot work without the image source resolution. (or can we? using only ratio, it should be possible...)
      return;
    }

    const [limitedUnprojectedXY, userlimitedInput] = this.m_imageGeometry.limitToWarpedCircle(this.m_requestedImageXY, this.m_requestedZoom);
    this.m_requestedImageXY = userlimitedInput;

    this.m_dewarpingPlane = new DewarpingPlane(limitedUnprojectedXY, this.m_requestedZoom, this.m_dewarperSourceImageParameters.CameraPosition.getRotation(limitedUnprojectedXY));

    this.m_controllerUniform.pPrime = this.m_dewarpingPlane.pPrime;
    this.m_controllerUniform.uHat = this.m_dewarpingPlane.uHat;
    this.m_controllerUniform.vHat = this.m_dewarpingPlane.vHat;
    this.m_controllerUniform.PlaneEquation = PlaneEquation.buildFromNormalAndPoint(this.m_dewarpingPlane.pPrime, this.m_dewarpingPlane.pPrime);

    this.triggerSceneChanged();
  }

  public debugStatus(indent: number): string {
    let planeEquationString: string = '';

    if (this.m_controllerUniform.PlaneEquation !== null) {
      planeEquationString = Utils.indentNewLine(indent) + `PlaneEquation: ${this.m_controllerUniform.PlaneEquation.toString()}`;
    }

    return 'DewarperController: ' + Utils.indentNewLine(indent) +
      `Requested XY: ${this.m_requestedImageXY.toString()}` + Utils.indentNewLine(indent) +
      `Zoom factor: ${this.m_requestedZoom.toString()}` + Utils.indentNewLine(indent) +
      `pPrime: ${this.m_controllerUniform.pPrime} û:${this.m_controllerUniform.uHat} v̂:${this.m_controllerUniform.vHat}` +
      planeEquationString;
  }
}

export class DewarpingRoi {
  private readonly m_imageXY: ClipSpaceVec2;
  private readonly m_zoomFactor: ZoomFactor;

  constructor(imageXY: ClipSpaceVec2, zoomFactor: ZoomFactor) {
    this.m_imageXY = imageXY;
    this.m_zoomFactor = zoomFactor;
  }
}
