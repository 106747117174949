import { IGuid, SafeGuid } from 'safeguid';
import { toGuid } from '../utils/guid-utils';
import { Step, StepApi } from './step';

export interface McProcedureApi {
    Id: IGuid;
    Revision: number;
    Steps: StepApi[];
}

export class McProcedure {
    constructor(public id: IGuid, public revision: number, public steps: Step[]) {
        Object.freeze(this);
    }

    public static assign(procedure: McProcedureApi): McProcedure {
        return new McProcedure(toGuid(procedure.Id), procedure.Revision, procedure.Steps.map(Step.assign));
    }
}
