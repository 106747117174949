import { Directive, Inject, Input } from '@angular/core';
import { GenExpanderComponent } from '@genetec/gelato-angular';
import { delayAsPromise, WINDOW } from '@src/app/utilities';

@Directive({
    selector: 'gen-expander[appScrollOnExpand]',
})
export class ScrollOnExpandDirective {
    @Input() scrollContainerSelector?: string;
    @Input() scrollContainerElement?: Element;
    @Input() scrollAmountPx = 300;

    constructor(private banner: GenExpanderComponent, @Inject(WINDOW) private window: Window) {
        this.banner.genIsOpenChange.subscribe(async (isExpand) => {
            if (isExpand) {
                await delayAsPromise(0); // Wait the view to be rendered
                this.scroll();
            }
        });
    }

    private scroll(): void {
        let scrollView: Element | Window | null = this.window;
        if (this.scrollContainerElement) {
            scrollView = this.scrollContainerElement;
        } else if (this.scrollContainerSelector) {
            scrollView = this.window.document.querySelector(this.scrollContainerSelector);
            if (!scrollView) {
                throw new Error(`ScrollView not found with selector ${this.scrollContainerSelector ?? 'window'}`);
            }
        }
        scrollView.scrollBy({
            top: this.scrollAmountPx,
            behavior: 'smooth',
        });
    }
}
