import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { FilterBaseComponent } from '@modules/shared/components/filters/common-filters/filter-base.component';
import { FiltersContext } from '@modules/shared/store/filters.context';
import { Debouncer } from 'RestClient/Helpers/Debouncer';
import { AdvancedSettingsService } from '../advanced-settings/advanced-settings.service';
import { FilterContext } from './filter';

type Filter = FilterBaseComponent<any>;

export const FILTER_CONTEXT = new InjectionToken<FilterContext>('Filter Context');

/**
 * Service used to coordinate dynamically the interaction between a group of filters and their updates
 */
@Injectable()
export class FilterCoordinatorService {
    //#region Public fields
    public allDefaulted = true;
    public allValid = true;
    //#endregion

    //#region Private fields
    private activeFilters: Filter[] = [];
    private debouncer?: Debouncer;
    private readonly defaultDebouncerTimeout = 2500;
    private readonly debouncerTimeout: number;
    private filtersToUpdate = new Set<Filter>();
    //#endregion

    constructor(
        private advancedSettingService: AdvancedSettingsService,
        private reportFilterService: FiltersContext,
        @Optional() @Inject(FILTER_CONTEXT) public context = FilterContext.None
    ) {
        this.debouncerTimeout = this.advancedSettingService.get('FilterQueryDelay', this.defaultDebouncerTimeout); //Get timeout from the settings
        this.setDebouncerActivity(context === FilterContext.IncidentList); //Activate debouncer in IncidentList
        this.setDebouncerActivity(context === FilterContext.CardholderList); //Activate debouncer in CardholderList
    }

    //#region Public methods

    /**
     * Adds a filter into the group so it's now tracked by the service
     *
     * @param filter Filter component instance to register in
     */
    public register(filter: Filter): void {
        this.activeFilters.push(filter);
        filter.isPrimary = this.isPrimary(filter);
    }

    /**
     * Removes a filter from the group so it's not tracked anymore
     *
     * @param filter Filter component instance to unregister
     */
    public unregister(filter: Filter): void {
        this.activeFilters.remove(filter);
    }

    /**
     * Push an update for a potentially delayed update
     *
     * @param filter Filter to update
     */
    public pushUpdate(filter: Filter): void {
        if (this.debouncer) {
            //If the debouncer is active, push the update for later
            this.filtersToUpdate.add(filter);
            this.debouncer.trigger();
        } else {
            //If not, execute it right away
            filter.updateFilter();
        }
        this.performChecks();
    }

    /**
     * Toggles the debouncer and delayed updates
     *
     * @param active Enables or disables delayed updates
     * @param overrideTimeout Override the timeout found in Advanced Settings
     * @param extraCallback Calls an additional function upon delayed update completion
     */
    public setDebouncerActivity(active: boolean, overrideTimeout?: number, extraCallback?: () => void): void {
        if (active) {
            this.debouncer = new Debouncer(
                false,
                () => {
                    this.onDebouncerTriggered();
                    if (extraCallback) extraCallback();
                },
                overrideTimeout && overrideTimeout >= 0 ? overrideTimeout : this.debouncerTimeout,
                -1
            );
        } else {
            this.debouncer = undefined;
        }
    }

    /**
     * Applies all updates immediately
     */
    public applyAllUpdatesNow(): void {
        this.onDebouncerTriggered();
    }

    /**
     * Cancels all updates
     */
    public cancelAllUpdates(): void {
        this.filtersToUpdate.clear();
    }

    /**
     * Clears out all filters to their default values immediately
     */
    public resetAllFiltersToDefault(): void {
        this.activeFilters.forEach((f) => {
            f.clearFilter();
        });
        this.reportFilterService.reset();
    }

    //#endregion

    //#region Private methods

    private onDebouncerTriggered(): void {
        this.filtersToUpdate.forEach((filter) => filter.updateFilter());
        this.filtersToUpdate.clear();
        this.performChecks();
    }

    //Used to precompute widely used filters aggregate properties
    private performChecks(): void {
        this.allDefaulted = this.activeFilters.every((f) => f.isDefaulted());
        this.allValid = this.activeFilters.every((f) => f.state.status !== 'Error');
    }

    //Returns whether the provided filter is the first of its type
    private isPrimary<T extends Filter>(filter: T): boolean {
        const first = this.activeFilters.filter((f) => f.constructor === filter.constructor)[0];
        return first === filter;
    }

    //#endregion
}
