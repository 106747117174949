import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IGuid } from 'safeguid';
import { map } from 'rxjs/operators';
import { IncidentLocationService } from '../services/incident-location/incident-location.service';
import { toGuid } from '../utils/guid-utils';
import { TranslateService } from "@ngx-translate/core";

@Pipe({name: 'sourceIdsName'})
export class SourceIdsNamePipe implements PipeTransform {
    private readonly _unavailableInformation: string;
    private readonly _none: string;

    constructor(private translateService: TranslateService, private incidentLocationService: IncidentLocationService) {
        this._unavailableInformation = translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string;
        this._none = translateService.instant('STE_LABEL_NONE') as string;
    }

    public transform(id: IGuid | string | null | undefined): Observable<string> {
        if (!id) return of(this._none);

        return this.incidentLocationService.getLocation(toGuid(id)).pipe(
            map((entity) => entity?.name ?? this._unavailableInformation),
        );
    }
}
