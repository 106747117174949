import { Component, OnInit } from '@angular/core';
import { Icon, ItemSlot, TextFlavor } from '@genetec/gelato';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { KnownContentContext } from '@modules/shared/services/content/KnownContentContext';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IDoorEntity } from 'RestClient/Client/Interface/IDoorEntity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';
import { DoorWidgetBaseComponent } from '../door-widget-base.component';

const isContentSupported = (content: Content): boolean => {
    return !!(content?.contextContent !== null && content?.type.equals(AccessControlContentTypes.Door || content?.type.equals(AccessControlContentTypes.Reader)));
};
@InternalContentPluginDescriptor({
    type: DoorPreviewWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: DoorPreviewWidgetComponent.pluginId, priority: 1 },
    isContentSupported,
})
@Component({
    selector: 'app-door-preview-widget',
    templateUrl: './door-preview-widget.component.html',
    styleUrls: ['./door-preview-widget.component.scss'],
})
export class DoorPreviewWidgetComponent extends DoorWidgetBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('CC369303-8FBF-4E1B-9B1E-891945070998');

    public readonly Icon = Icon;
    public readonly ItemSlot = ItemSlot;
    public readonly TextFlavor = TextFlavor;

    public doorSecondaryText$: Observable<string>;

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        translateService: TranslateService,
        trackingService: TrackingService,
        private contentProviderService: ContentProviderService
    ) {
        super(securityCenterClientService, translateService, trackingService);
        this.doorSecondaryText$ = this.door$.pipe(map((door) => this.getDoorString(door)));
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = false;
    }

    public async navigateToDoorAsync(): Promise<void> {
        const door = this.door;
        if (door) {
            const content = await this.contentProviderService.getContentAsync(door.id, KnownContentContext.Navigation);
            if (content) {
                const contentService = this.dataContext as ContentService;
                contentService.pushContent(content);
            }
        }
    }

    private getDoorString(door: IDoorEntity | null): string {
        if (!door) {
            return '';
        }

        const openedStateString = this.translateService.instant(door.doorOpened ? 'STE_LABEL_DOOROPENED' : 'STE_LABEL_DOORCLOSED') as string;
        let stateString;
        if (door.maintenanceModeActive) {
            stateString = this.translateService.instant('STE_LABEL_DOOR_MAINTENANCE_MODE') as string;
        } else {
            stateString = this.translateService.instant(door.isLocked ? 'STE_LABEL_DOOR_LOCKED' : 'STE_LABEL_DOOR_UNLOCKED') as string;
        }

        return `${openedStateString} - ${stateString}`;
    }
}
