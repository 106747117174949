// Autogenerated: v2/help/Entities/ApiReverseTunnelServerRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { FieldObject } from '../../../Helpers/FieldObject';
import { RoleEntity } from './RoleEntity';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IReverseTunnelServerRoleEntity, ISite, ReverseTunnelServerRoleEntityFields } from '../../Interface/IReverseTunnelServerRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class Site extends FieldObject implements ISite {
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // MandatoryExistingEntity
    public get mandatoryExistingEntity(): IGuid | null {
        return this.getNullableFieldGuid('MandatoryExistingEntity');
    }
    public set mandatoryExistingEntity(value: IGuid | null) {
        this.setNullableFieldGuid('MandatoryExistingEntity', value);
    }
    // FriendlyDescription
    public get friendlyDescription(): string {
        return this.getField<string>('FriendlyDescription');
    }
    public set friendlyDescription(value: string) {
        this.setField<string>('FriendlyDescription', value);
    }
    // EncryptionMode
    public get encryptionMode(): string {
        return this.getField<string>('EncryptionMode');
    }
    public set encryptionMode(value: string) {
        this.setField<string>('EncryptionMode', value);
    }
    // ForceReset
    public get forceReset(): boolean {
        return this.getField<boolean>('ForceReset');
    }
    public set forceReset(value: boolean) {
        this.setField<boolean>('ForceReset', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Name', '');
        this.setFieldGuid('MandatoryExistingEntity', SafeGuid.EMPTY);
        this.setField<string>('FriendlyDescription', '');
        this.setField<string>('EncryptionMode', '');
        this.setField<boolean>('ForceReset', false);
    }
}

// #endregion Inner classes

export class ReverseTunnelServerRoleEntity extends RoleEntity implements IReverseTunnelServerRoleEntity {
    // #region REST Properties

    // GlobalEncryptionMode
    public get globalEncryptionMode(): string {
        return this.getField<string>(ReverseTunnelServerRoleEntityFields.globalEncryptionModeField);
    }
    public set globalEncryptionMode(value: string) {
        this.setField<string>(ReverseTunnelServerRoleEntityFields.globalEncryptionModeField, value);
    }

    // GlobalTcpPortRangeEnd
    public get globalTcpPortRangeEnd(): number {
        return this.getField<number>(ReverseTunnelServerRoleEntityFields.globalTcpPortRangeEndField);
    }
    public set globalTcpPortRangeEnd(value: number) {
        this.setField<number>(ReverseTunnelServerRoleEntityFields.globalTcpPortRangeEndField, value);
    }

    // GlobalTcpPortRangeStart
    public get globalTcpPortRangeStart(): number {
        return this.getField<number>(ReverseTunnelServerRoleEntityFields.globalTcpPortRangeStartField);
    }
    public set globalTcpPortRangeStart(value: number) {
        this.setField<number>(ReverseTunnelServerRoleEntityFields.globalTcpPortRangeStartField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async addSitesAsync(value: ISite, transaction?: ITransaction): Promise<string | null> {
        const responseHandler = (response: IRestResponse): Promise<string> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body as string;
                return new Promise<string>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<string>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<string>('Sites', 'POST', value, transaction, responseHandler);
    }

    public async deleteSitesAsync(name: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('Sites' + '/' + name, 'DELETE', null, transaction, responseHandler);
    }

    // #endregion REST Methods

}

