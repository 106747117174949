import { IConnectionEvent } from './WsSessionV2';
import { WebSocketBuilder, IWebSocket } from './WebSocketBuilder';
import { ILogger } from '../../utils/logger';
import { LiteEvent } from '../../utils/liteEvents';
import { Utils } from '../../utils/Utils';

export interface ISocket extends IConnectionEvent {
  onmessage: ((arrayBuffer: ArrayBuffer) => any) | null;

  send(data: ArrayBuffer): void;

  dispose(): Promise<void>;

  debugStatus(indent: number): string;
}

export class AutoReconnectSocket implements ISocket {
  private static readonly ReconnectionAttemptDelay = 2000;

  private readonly m_webSocketBuilder: WebSocketBuilder;

  private readonly m_logger: ILogger;

  private m_isDisposed = false;

  private m_webSocket: IWebSocket;

  private m_isConnected: boolean = true; // We receive a connected socket on the constructor

  private readonly m_connectionReestablishedEvent = new LiteEvent<void>();

  private readonly m_connectionLostEvent = new LiteEvent<void>();

  public onmessage: ((arrayBuffer: ArrayBuffer) => any) | null;

  public get ConnectionReestablished() {
    return this.m_connectionReestablishedEvent.expose();
  }

  public get ConnectionLost() {
    return this.m_connectionLostEvent.expose();
  }

  constructor(logger: ILogger, webSocketBuilder: WebSocketBuilder, webSocket: IWebSocket) {
    this.m_logger = logger.subLogger('AutoReconnectSocket');
    this.m_webSocketBuilder = webSocketBuilder;
    this.m_webSocket = webSocket;

    this.onmessage = null;

    this.bind();
  }

  public static async build(logger: ILogger, webSocketBuilder: WebSocketBuilder) {
    const webSocket = await webSocketBuilder.establishWebSocket();
    return new AutoReconnectSocket(logger, webSocketBuilder, webSocket);
  }

  public send(data: ArrayBuffer): void {
    this.m_webSocket.send(data);
  }

  public async dispose(): Promise<void> {
    this.m_isDisposed = true;
    // todo: add management to halt a reconnection attempt
    this.unbind();

    const closedPromise = new Promise<void>((resolve, reject) => {
      this.m_webSocket.onclose = () => resolve();
      this.m_webSocket.onerror = (ev) => reject(ev);
    });

    this.m_webSocket.close(1000);

    await closedPromise;
  }

  private onSocketMessage(messageEvent: MessageEvent) {
    if (this.onmessage !== null) {
      this.onmessage(messageEvent.data);
    }
  }

  private onSocketClose(closeEvent: CloseEvent) {
    this.m_isConnected = false;
    this.m_logger.info?.trace('Socket closed');
    this.m_logger.info?.trace(closeEvent);
    this.m_connectionLostEvent.trigger();
    this.reconnect();
  }

  private onSocketError(event: Event): void {
    this.m_isConnected = false;
    this.m_logger.warn?.trace('Socket error');
    this.m_logger.warn?.trace(event);
  }

  private bind() {
    this.m_webSocket.onmessage = this.onSocketMessage.bind(this);
    this.m_webSocket.onclose = this.onSocketClose.bind(this);
    this.m_webSocket.onerror = this.onSocketError.bind(this);
  }

  private unbind() {
    this.m_webSocket.onmessage = null;
    this.m_webSocket.onclose = null;
    this.m_webSocket.onerror = null;
  }

  private async reconnect(): Promise<void> {
    this.unbind();
    this.m_webSocket = await this.connectLoop();
    this.m_logger.info?.trace('Reconnected');
    this.bind();
    this.m_isConnected = true;
    this.m_connectionReestablishedEvent.trigger();
  }

  private async connectLoop(): Promise<IWebSocket> {
    while (!this.m_isDisposed) {
      try {
        const webSocket = await this.m_webSocketBuilder.establishWebSocket();
        return webSocket;
      } catch (e) {
        this.m_logger.warn?.trace('Socket error: ', e);
      }

      await Utils.delay(AutoReconnectSocket.ReconnectionAttemptDelay);
    }

    throw new Error('AutoConnection interrupted by dispose.');
  }

  public debugStatus(indent: number): string {
    return 'AutoReconnectSocket' + Utils.indentNewLine(indent) +
      (this.m_isDisposed ? `${Utils.indentNewLine(indent)}Disposed${Utils.indentNewLine(indent)}` : '') +
      (this.m_isConnected ? 'Connected' : 'Disconnected');
  }
}
