<gen-melted-modal
    class="xl"
    genTitle="{{ 'STE_TITLE_VIEW_IMAGE' | translate }}"
    genDefaultActionText="{{ 'STE_LABEL_OK' | translate }}"
    [genCanClose]="true"
    [genIsBlocking]="true"
>
    <gen-melted-modal-body>
        <div class="viewer-area">
            <gen-image *ngIf="image" [src]="image" [flavor]="ImageFlavor.Rectangle"></gen-image>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
