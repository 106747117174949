// Autogenerated: v2/help/Entities/ApiIntrusionUnit?codegen=ts&InterfaceOnly=False&workflow=False on 2021-01-27 7:34:50 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { UnitEntity } from '../UnitEntity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IEntity } from '../../Interface/IEntity';
import { IIntrusionUnitEntity, IntrusionUnitEntityFields } from '../../Interface/IIntrusionUnitEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class IntrusionUnitEntity extends UnitEntity implements IIntrusionUnitEntity {
    // #region REST Properties

    // ACFailure
    public get aCFailure(): boolean {
        return this.getField<boolean>(IntrusionUnitEntityFields.aCFailureField, ValidFlags.EntityStatus);
    }
    public set aCFailure(value: boolean) {
        this.setField<boolean>(IntrusionUnitEntityFields.aCFailureField, value);
    }

    // BatteryFailure
    public get batteryFailure(): boolean {
        return this.getField<boolean>(IntrusionUnitEntityFields.batteryFailureField, ValidFlags.EntityStatus);
    }
    public set batteryFailure(value: boolean) {
        this.setField<boolean>(IntrusionUnitEntityFields.batteryFailureField, value);
    }

    // Capabilities
    public get capabilities(): string {
        return this.getField<string>(IntrusionUnitEntityFields.capabilitiesField, ValidFlags.Specific);
    }

    // ClearLogsAfterDownload
    public get clearLogsAfterDownload(): boolean {
        return this.getField<boolean>(IntrusionUnitEntityFields.clearLogsAfterDownloadField, ValidFlags.Specific);
    }
    public set clearLogsAfterDownload(value: boolean) {
        this.setField<boolean>(IntrusionUnitEntityFields.clearLogsAfterDownloadField, value);
    }

    // CommandPort
    public get commandPort(): number {
        return this.getField<number>(IntrusionUnitEntityFields.commandPortField, ValidFlags.Specific);
    }
    public set commandPort(value: number) {
        this.setField<number>(IntrusionUnitEntityFields.commandPortField, value);
    }

    // IntrusionUnitId
    public get intrusionUnitId(): string {
        return this.getField<string>(IntrusionUnitEntityFields.intrusionUnitIdField, ValidFlags.Specific);
    }
    public set intrusionUnitId(value: string) {
        this.setField<string>(IntrusionUnitEntityFields.intrusionUnitIdField, value);
    }

    // IsActivated
    public get isActivated(): boolean {
        return this.getField<boolean>(IntrusionUnitEntityFields.isActivatedField, ValidFlags.Specific);
    }
    public set isActivated(value: boolean) {
        this.setField<boolean>(IntrusionUnitEntityFields.isActivatedField, value);
    }

    // SerialPort
    public get serialPort(): string {
        return this.getField<string>(IntrusionUnitEntityFields.serialPortField, ValidFlags.Specific);
    }
    public set serialPort(value: string) {
        this.setField<string>(IntrusionUnitEntityFields.serialPortField, value);
    }

    // Tamper
    public get tamper(): boolean {
        return this.getField<boolean>(IntrusionUnitEntityFields.tamperField, ValidFlags.EntityStatus);
    }
    public set tamper(value: boolean) {
        this.setField<boolean>(IntrusionUnitEntityFields.tamperField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.IntrusionUnits);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getIntrusionAreasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('IntrusionAreas', 'Id', false, query, ValidFlags.Membership3, transaction);
    }

    // #endregion REST Methods

}

