import { IGuid, SafeGuid } from 'safeguid';
import { EntityInvalidatedArg } from '../../Connection/RestArgs';
import { Mutex } from '../../Helpers/semaphore';
import { LogonStateChangedArgs } from '../Args/LogonStateChangedArgs';
import { LogSeverity } from '../Enumerations/LogSeverity';
import { EntityFields, IEntity } from '../Interface/IEntity';
import { ISecurityCenterClient } from '../Interface/ISecurityCenterClient';
import { Entity } from '../Model/Entity';
import { EntityQuery } from '../Queries/EntityQuery';
import { SecurityCenterClient } from '../SecurityCenterClient';

const allFields = '*';

const padFields = (fields1: Iterable<string>, fields2: Iterable<string>): Set<string> => {
    const paddedFields = new Set<string>(fields1);
    for (const field of fields2) {
        paddedFields.add(field);
    }
    return paddedFields;
};

const cleanupFields = (fields: Set<string>): Set<string> => {
    if (fields.has(allFields)) {
        return new Set<string>(allFields);
    }
    return fields;
};

const copyEntity = (entity: IEntity, client: ISecurityCenterClient, fields?: Set<string>): IEntity => {
    const classType = entity.constructor as new () => IEntity;
    const copy = client.createEntityModel(classType, { [EntityFields.entityTypeField]: entity.entityType }) as IEntity;
    if (fields) {
        copy.loadFieldsFrom(entity, fields);
    } else {
        copy.loadFrom(entity);
    }
    return copy;
};

class MonitoreEntityCacheEntry {
    public readonly fieldsToDownload = new Set<string>();
    public readonly relationsToClear = new Set<string>();
    public readonly validFlagFields = new Map<number, Set<string>>();
    public readonly validFlagRelations = new Map<number, Set<string>>();
    public monitoredValidFlags = 0;
    public isInvalidated = false;

    constructor(public readonly cacheId: IGuid, public entity: IEntity) {}

    public clearInvalidate(): void {
        this.isInvalidated = false;
        for (const relation of this.relationsToClear) {
            this.entity.resetRelation(relation);
        }
        this.relationsToClear.clear();
        this.fieldsToDownload.clear();
    }
}

class MonitoredEntityEntry {
    private readonly _monitoredFields = new Set<string>();
    private readonly fieldsCaches = new Map<string, Set<IGuid>>();
    public readonly cacheEntries = SafeGuid.createMap<MonitoreEntityCacheEntry>();

    public get monitoredFields(): Set<string> {
        return cleanupFields(this._monitoredFields);
    }

    constructor(public readonly entity: IEntity) {}

    public addMonitoredField(cacheId: IGuid, entity: IEntity, field: string, validFlag: number): void {
        field = field.toLowerCase();
        const fieldCaches = this.fieldsCaches.get(field);
        if (fieldCaches) {
            fieldCaches.add(cacheId);
        } else {
            this.fieldsCaches.set(field, SafeGuid.createSet([cacheId]));
        }
        this._monitoredFields.add(field);

        let cacheEntry = this.cacheEntries.get(cacheId);
        if (!cacheEntry) {
            cacheEntry = new MonitoreEntityCacheEntry(cacheId, entity);
            this.cacheEntries.set(cacheId, cacheEntry);
        }

        let cacheEntryValidFlagFields = cacheEntry.validFlagFields.get(validFlag);
        if (!cacheEntryValidFlagFields) {
            cacheEntryValidFlagFields = new Set<string>();
            cacheEntry.validFlagFields.set(validFlag, cacheEntryValidFlagFields);
        }
        cacheEntryValidFlagFields.add(field);
        cacheEntry.monitoredValidFlags |= validFlag;
    }

    public addMonitoredRelation(cacheId: IGuid, entity: IEntity, relation: string, validFlag: number): void {
        let cacheEntry = this.cacheEntries.get(cacheId);
        if (!cacheEntry) {
            cacheEntry = new MonitoreEntityCacheEntry(cacheId, entity);
            this.cacheEntries.set(cacheId, cacheEntry);
        }

        let cacheEntryValidFlagRelations = cacheEntry.validFlagRelations.get(validFlag);
        if (!cacheEntryValidFlagRelations) {
            cacheEntryValidFlagRelations = new Set<string>();
            cacheEntry.validFlagRelations.set(validFlag, cacheEntryValidFlagRelations);
        }
        cacheEntryValidFlagRelations.add(relation);
        cacheEntry.monitoredValidFlags |= validFlag;
    }

    public removeCache(cacheId: IGuid): void {
        this.cacheEntries.delete(cacheId);

        const fieldsToRemove: string[] = [];
        for (const [field, fieldCaches] of this.fieldsCaches) {
            fieldCaches.delete(cacheId);
            if (fieldCaches.size === 0) {
                fieldsToRemove.push(field);
            }
        }

        for (const field of fieldsToRemove) {
            this.fieldsCaches.delete(field);
            this._monitoredFields.delete(field);
        }
    }
}

export class EntityCacheTaskMonitor {
    private static monitors = new Map<SecurityCenterClient, EntityCacheTaskMonitor>();

    private monitoredEntityEntries = SafeGuid.createMap<MonitoredEntityEntry>();
    private entityCacheTasks = SafeGuid.createMap<Set<IGuid>>();
    private getEntityMutexes = SafeGuid.createMap<Mutex>();
    private entityInvalidateCacheEntries = SafeGuid.createMap<Set<MonitoreEntityCacheEntry>>();
    private subscriptions: (() => void)[] = [];

    constructor(private client: SecurityCenterClient) {
        this.subscriptions.push(client.onLogonStateChanged((arg) => this.onLogonStateChanged(arg)));
    }

    private onLogonStateChanged(arg: LogonStateChangedArgs): void {
        if (!arg.loggedOn()) {
            this.monitoredEntityEntries.clear();
            this.entityCacheTasks.clear();
            this.entityInvalidateCacheEntries.clear();
            this.getEntityMutexes.clear();
        }
    }

    public onEntityInvalidated(cacheId: IGuid, arg: EntityInvalidatedArg): void {
        const monitoredEntityEntry = this.monitoredEntityEntries.get(arg.id);
        if (monitoredEntityEntry) {
            const cacheEntry = monitoredEntityEntry.cacheEntries.get(cacheId);
            if (cacheEntry && (cacheEntry.monitoredValidFlags & arg.flags) === arg.flags) {
                cacheEntry.isInvalidated = true;
                const fields = cacheEntry.validFlagFields.get(arg.flags);
                if (fields) {
                    for (const field of fields) {
                        cacheEntry.fieldsToDownload.add(field);
                    }
                }

                const relationsToClear = cacheEntry.validFlagRelations.get(arg.flags);
                if (relationsToClear) {
                    for (const relation of relationsToClear) {
                        cacheEntry.relationsToClear.add(relation);
                    }
                }

                let invalidateCacheEntries = this.entityInvalidateCacheEntries.get(arg.id);
                if (!invalidateCacheEntries) {
                    invalidateCacheEntries = new Set<MonitoreEntityCacheEntry>();
                    this.entityInvalidateCacheEntries.set(arg.id, invalidateCacheEntries);
                }
                invalidateCacheEntries.add(cacheEntry);
            }
        }
    }

    public static get(client: SecurityCenterClient): EntityCacheTaskMonitor {
        let monitor = this.monitors.get(client);
        if (monitor) {
            return monitor;
        }

        monitor = new EntityCacheTaskMonitor(client);
        this.monitors.set(client, monitor);
        return monitor;
    }

    public addMonitoredEntityField(cacheId: IGuid, entity: IEntity, field: string, validFlag: number): void {
        let cacheEntry = this.monitoredEntityEntries.get(entity.id);
        if (!cacheEntry) {
            cacheEntry = new MonitoredEntityEntry(copyEntity(entity, this.client));
            this.monitoredEntityEntries.set(entity.id, cacheEntry);
        }
        cacheEntry.addMonitoredField(cacheId, entity, field, validFlag);

        const entityCacheTasks = this.entityCacheTasks.get(entity.id);
        if (entityCacheTasks) {
            entityCacheTasks.add(cacheId);
        } else {
            this.entityCacheTasks.set(entity.id, SafeGuid.createSet([cacheId]));
        }
    }

    public addMonitoredEntityRelation(cacheId: IGuid, entity: IEntity, relation: string, validFlag: number): void {
        let cacheEntry = this.monitoredEntityEntries.get(entity.id);
        if (!cacheEntry) {
            cacheEntry = new MonitoredEntityEntry(copyEntity(entity, this.client));
            this.monitoredEntityEntries.set(entity.id, cacheEntry);
        }
        cacheEntry.addMonitoredRelation(cacheId, entity, relation, validFlag);

        const entityCacheTasks = this.entityCacheTasks.get(entity.id);
        if (entityCacheTasks) {
            entityCacheTasks.add(cacheId);
        } else {
            this.entityCacheTasks.set(entity.id, SafeGuid.createSet([cacheId]));
        }
    }

    public removeMonitoredEntity(cacheId: IGuid, entityId: IGuid): void {
        const entityCacheTasks = this.entityCacheTasks.get(entityId);
        if (entityCacheTasks) {
            entityCacheTasks.delete(cacheId);
            if (entityCacheTasks.size === 0) {
                this.monitoredEntityEntries.delete(entityId);
                this.getEntityMutexes.delete(entityId);
                this.entityInvalidateCacheEntries.delete(entityId);

                if (this.monitoredEntityEntries.size === 0) {
                    for (const unsubscribe of this.subscriptions) {
                        unsubscribe();
                    }
                    EntityCacheTaskMonitor.monitors.delete(this.client);
                }
            } else {
                const cacheEntry = this.monitoredEntityEntries.get(entityId);
                if (cacheEntry) {
                    cacheEntry.removeCache(cacheId);
                }
                const invalidateCacheEntries = this.entityInvalidateCacheEntries.get(entityId);
                if (invalidateCacheEntries) {
                    const monitoredEntityCacheEntryToDelete = setFind(invalidateCacheEntries, (x) => x.cacheId.equals(cacheId));
                    if (monitoredEntityCacheEntryToDelete) {
                        invalidateCacheEntries.delete(monitoredEntityCacheEntryToDelete);
                    }
                }
            }
        }
    }

    public async getEntitiesAsync<T extends Entity & U, U extends IEntity>(cacheId: IGuid, classType: new () => U, query: EntityQuery): Promise<Array<U>> {
        const releases = SafeGuid.createMap<() => void>();
        for (const entityId of query.guids) {
            if (this.monitoredEntityEntries.has(entityId)) {
                let mutex = this.getEntityMutexes.get(entityId);
                if (!mutex) {
                    mutex = new Mutex();
                    this.getEntityMutexes.set(entityId, mutex);
                }
                releases.set(entityId, await mutex.acquire());
            }
        }

        const result: U[] = [];
        try {
            const entityIdsToDownload = SafeGuid.createSet();
            const entityFieldsToRefresh = SafeGuid.createMap<Set<string>>();
            for (const entityId of query.guids) {
                const monitoredEntityEntry = this.monitoredEntityEntries.get(entityId);
                if (monitoredEntityEntry) {
                    const cacheEntry = monitoredEntityEntry.cacheEntries.get(cacheId);
                    if (cacheEntry && cacheEntry.isInvalidated && cacheEntry.fieldsToDownload.size === 0) {
                        // Entry was just invalidated and is fully updated, return it
                        cacheEntry.clearInvalidate();
                        result.push(cacheEntry.entity as unknown as U);
                        const release = releases.get(entityId);
                        if (release) {
                            release();
                            releases.delete(entityId);
                        }
                        continue;
                    }
                }

                const invalidateCacheEntries = this.entityInvalidateCacheEntries.get(entityId);
                const entity = await this.getEntityAsync<U>(entityId, query.fields);
                if (entity && !invalidateCacheEntries) {
                    // Entity exists in cache and all the required fields are monitored and up to date, return it
                    result.push(entity);
                    const release = releases.get(entityId);
                    if (release) {
                        release();
                        releases.delete(entityId);
                    }
                } else {
                    entityIdsToDownload.add(entityId);
                    if (invalidateCacheEntries) {
                        // Entity was invalidated, check which fields must be downloaded to update it
                        const fieldsToDownload = new Set<string>();
                        entityFieldsToRefresh.set(entityId, fieldsToDownload);
                        for (const cacheEntry of invalidateCacheEntries) {
                            for (const field of cacheEntry.fieldsToDownload) {
                                fieldsToDownload.add(field);
                            }
                        }
                    }
                }
            }

            if (entityIdsToDownload.size > 0) {
                query.guids = SafeGuid.createSet(entityIdsToDownload.keys());

                // If refreshing cache of a single entity, make sure to download invalidated fields as well
                if (entityIdsToDownload.size === 1) {
                    const invalidateFields = entityFieldsToRefresh.get(Array.from(entityIdsToDownload)[0]);
                    if (invalidateFields) {
                        query.fields = cleanupFields(padFields(query.fields, invalidateFields));
                    }
                }

                const entities = await this.client.getEntitiesAsync<T, U>(classType, query, null, null);
                for (const entity of entities) {
                    let entityToReturn = entity;
                    const entityId = entity.id;

                    const monitoredEntityEntry = this.monitoredEntityEntries.get(entityId);
                    if (monitoredEntityEntry) {
                        // Update shared cache entity with new fields
                        if (query.fields.has(allFields)) {
                            monitoredEntityEntry.entity.loadFieldsFrom(entity);
                        } else {
                            monitoredEntityEntry.entity.loadFieldsFrom(entity, query.fields);
                        }

                        const invalidateCacheEntries = this.entityInvalidateCacheEntries.get(entityId);
                        if (invalidateCacheEntries) {
                            const invalideCacheEntriesToRemove: MonitoreEntityCacheEntry[] = [];
                            for (const cacheEntry of invalidateCacheEntries) {
                                const fieldsToDownload = new Set<string>(cacheEntry.fieldsToDownload);

                                // Create new entity, load fields and relations from current one in cache, then update with downladed fields and replace the one in cache
                                const newCacheEntity = copyEntity(cacheEntry.entity, this.client);
                                newCacheEntity.loadFieldsFrom(entity, fieldsToDownload);
                                cacheEntry.entity = newCacheEntity;

                                // Clean up downloaded fields for entry
                                for (const field of fieldsToDownload) {
                                    if (query.fields.has(field) || query.fields.has(allFields)) {
                                        cacheEntry.fieldsToDownload.delete(field);
                                    }
                                }

                                if (cacheEntry.fieldsToDownload.size === 0) {
                                    invalideCacheEntriesToRemove.push(cacheEntry);
                                }
                            }

                            for (const cacheEntry of invalideCacheEntriesToRemove) {
                                invalidateCacheEntries.delete(cacheEntry);
                            }

                            if (invalidateCacheEntries.size === 0) {
                                this.entityInvalidateCacheEntries.delete(entityId);
                            }
                        }

                        const cacheEntry = monitoredEntityEntry.cacheEntries.get(cacheId);
                        if (cacheEntry && cacheEntry.isInvalidated && cacheEntry.fieldsToDownload.size === 0) {
                            // Entry was just invalided and is fully updated, return it
                            cacheEntry.clearInvalidate();
                            entityToReturn = cacheEntry.entity as unknown as U;
                        }
                    }

                    result.push(entityToReturn);
                }
            }
        } finally {
            for (const release of releases.values()) {
                release();
            }
            releases.clear();
        }

        return result;
    }

    private async getEntityAsync<U extends IEntity>(entityId: IGuid, fields: Set<string>): Promise<U | null> {
        const monitoredEntityEntry = this.monitoredEntityEntries.get(entityId);
        if (monitoredEntityEntry) {
            let isEntryValid = true;
            if (isEntryValid) {
                for (const field of cleanupFields(fields)) {
                    if (!monitoredEntityEntry.monitoredFields.has(field)) {
                        isEntryValid = false;
                        break;
                    }
                }
            }

            if (!isEntryValid) {
                return null;
            }

            try {
                // Entity exists in cache and all the required fields are monitored and up to date, create copy and return it
                const newEntity = copyEntity(monitoredEntityEntry.entity, this.client, fields.has(allFields) ? undefined : fields);
                newEntity.clearRelations();

                return newEntity as unknown as U;
            } catch (error) {
                this.client.diagnosticManager?.sendTraceAsync(LogSeverity.Error, 'EntityCacheTaskMonitor.getEntity', 'Failed to create entity', '').catch();
            }
        }

        return null;
    }
}
