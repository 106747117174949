<ng-container *ngIf="isInitialized; else spinner">
    <ng-container *ngIf="isContentVisible; else insufficientPrivileges">
        <div *ngIf="!hasGamepadConneted">
            <gen-text>{{ 'STE_MESSAGE_INFO_NO_DEVICE_DETECTED' | translate }}</gen-text>
        </div>
        <div [ngClass]="{ hidden: !hasGamepadConneted }">
            <div class="mb-2">
                <app-joystick-selection
                    [joysticks]="availableJoysticks"
                    [activeJoystickId]="currentJoystick?.id"
                    (activeJoystickChanged)="onActiveJoystickSelectionChanged($event)"
                ></app-joystick-selection>
            </div>

            <div class="mb-2">
                <gen-numeric-input
                    class="deadzone-input"
                    [ngModel]="deadZone"
                    (ngModelChange)="onDeadZoneModified($event)"
                    [min]="0"
                    [max]="99"
                    [step]="1"
                    [disabled]="!currentJoystick"
                >
                    <gen-label>{{ 'STE_LABEL_DEADZONE' | translate }}</gen-label>
                    <gen-message class="mt-1" style="font-size: 13px" [innerHtml]="'STE_MESSAGE_INFO_OPTIONS_JOYSTICK_DEADZONE_EXPLANATION' | translate"></gen-message>
                    <gen-suffix>%</gen-suffix>
                </gen-numeric-input>
            </div>

            <div>
                <ng-template #coloredLabelOnEventCellRenderer let-cellValue let-params="params">
                    <p id="idCell-{{ cellValue.uiGuid }}">{{ cellValue?.index }}</p>
                </ng-template>

                <ng-template #actionsDownComboTemplate let-params="params">
                    <gen-combobox
                        [genItemsSource]="buttonActions"
                        [(ngModel)]="params.data.downCommand"
                        (ngModelChange)="onButtonsConfigModified()"
                        genPlaceholder=" "
                        [genShowClearButton]="true"
                    ></gen-combobox>
                </ng-template>

                <ng-template #actionsUpComboTemplate let-params="params">
                    <gen-combobox
                        [genItemsSource]="buttonActions"
                        [(ngModel)]="params.data.upCommand"
                        (ngModelChange)="onButtonsConfigModified()"
                        genPlaceholder=" "
                        [genShowClearButton]="true"
                    ></gen-combobox>
                </ng-template>

                <ng-template #actionsDownArgsTemplate let-params="params">
                    <gen-text-input [(ngModel)]="params.data.downArgs" (ngModelChange)="onButtonsConfigModified()"></gen-text-input>
                </ng-template>

                <ng-template #actionsUpArgsTemplate let-params="params">
                    <gen-text-input [(ngModel)]="params.data.upArgs" (ngModelChange)="onButtonsConfigModified()"></gen-text-input>
                </ng-template>

                <div id="numerics" class="numerics-input">
                    <div class="custom-margin">
                        <gen-text class="mr-2" flavor="label">{{ 'STE_LABEL_NUMERIC_COMMANDS' | translate }}</gen-text>
                    </div>
                    <gen-item (mouseenter)="toggleNumericCommandPopupInfo()" (mouseleave)="toggleNumericCommandPopupInfo()">
                        <gen-icon icon="info" size="small"></gen-icon>
                        <gen-text class="small-fontsize">
                            {{ 'STE_LABEL_INFO_POPUP' | translate }}
                        </gen-text>
                    </gen-item>
                </div>

                <gen-popup #numericCommandPopup target-selector="#numerics">
                    <div style="width: 21rem; padding: var(--gen-spacing-base)" [innerHtml]="'STE_MESSAGE_INFO_OPTIONS_JOYSTICK_NUM_COMMANDS_EXPLANATION' | translate"></div>
                </gen-popup>

                <gen-melted-table
                    *ngIf="hasGamepadConneted"
                    [genColumns]="buttonColumns"
                    [genFit]="Fit.Table.Auto"
                    [genItemsSource]="buttonRows"
                    [genSelectionType]="Table.SelectionType.Single"
                >
                </gen-melted-table>
                <gen-text class="small-fontsize">{{ 'STE_MESSAGE_INFO_JOYSTICK_BTN_PRESSED' | translate }}</gen-text>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #insufficientPrivileges>
    <app-insufficient-privileges></app-insufficient-privileges>
</ng-template>

<ng-template #spinner>
    <div class="ha-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
