<div #tableContainer class="w-100 h-100">
    <gen-table selection-type="single" [infiniteScroll]="hasMore" (fetchData)="onLoadMore()" (columnOrderChange)="columnOrderChanged($event)">
        <gen-table-header></gen-table-header>
        <gen-table-row>
            <gen-table-column-header
                class="table-header-column"
                *ngFor="let colHeader of columnDescriptors"
                [columnName]="colHeader.fieldId"
                [hidden]="colHeader.isHidden"
                [sort]="colHeader.isSortable ? TableColumnHeaderSort.None : null"
                (sortChange)="onSortChange($event, colHeader)"
            >
                {{ colHeader.label }}
            </gen-table-column-header>
        </gen-table-row>
        <gen-table-loading-overlay *ngIf="!data || refreshing">
            <gen-spinner>{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        </gen-table-loading-overlay>
        <gen-table-empty-content *ngIf="data && !data.length">
            <div class="no-result-container">
                <gen-text class="no-result-message">{{ 'STE_LABEL_NORESULTS' | translate }}</gen-text>
                <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_MESSAGE_INFO_NORESULTS_DETAILS' | translate }}</gen-text>
            </div>
        </gen-table-empty-content>
        <gen-table-row *ngFor="let row of data; index as rowIndex" (click)="onSelectionChange(row)" [class.opened]="!!selectedId?.equals(row.guid)">
            <gen-table-data [columnName]="col.fieldId" *ngFor="let col of columnDescriptors">
                <ng-container
                    *ngTemplateOutlet="
                        cellTemplate;
                        context: {
                            cellType: col.cellType,
                            customRenderer: col.cellRendererGuid?.toString(),
                            key: col.fieldId,
                            cellValue: row[col.fieldId],
                            rowIndex: rowIndex,
                            isTimezoneConvert: col.isTimezoneConvert
                        }
                    "
                >
                </ng-container>
            </gen-table-data>
        </gen-table-row>
    </gen-table>
</div>

<ng-template
    #cellTemplate
    let-cellType="cellType"
    let-key="key"
    let-cellValue="cellValue"
    let-rowIndex="rowIndex"
    let-customRenderer="customRenderer"
    let-isTimezoneConvert="isTimezoneConvert"
>
    <ng-container [ngSwitch]="cellType">
        <!-- Moment format : 2:04:52 PM -->
        <ng-template [ngSwitchCase]="TableCellType.Time">
            <gen-text *ngLet="cellValue | time: ['LTS'] as time" [appTooltip]="time" appTooltipOnlyIfOverflow>{{ time }}</gen-text>
        </ng-template>

        <!-- Moment format : December 14, 2022 -->
        <ng-template [ngSwitchCase]="TableCellType.Date">
            <gen-text *ngLet="cellValue | time: ['LL'] as date" [appTooltip]="date" appTooltipOnlyIfOverflow>{{ date }}</gen-text>
        </ng-template>

        <!-- Moment format : December 14, 2022 2:04 PM / 12/14/2022 2:04:00 PM EST-->
        <ng-template [ngSwitchCase]="TableCellType.DateTime">
            <gen-text *ngLet="cellValue | time: [isTimezoneConvert ? 'L LTS' : 'LLL'] as dateTime" [appTooltip]="dateTime" appTooltipOnlyIfOverflow>{{ dateTime }}</gen-text>
        </ng-template>

        <!-- Custom cell renderer -->
        <ng-template [ngSwitchCase]="TableCellType.Custom">
            <ng-container [ngSwitch]="customRenderer | lowercase">
                <!-- ColoredDot -->
                <ng-template [ngSwitchCase]="KnownCellRendererTypes.ColoredDot | lowercase">
                    <gen-icon [icon]="Icon.Record" [size]="IconSize.Medium" [ngStyle]="{ color: cellValue }"> </gen-icon>
                </ng-template>
                <!-- Thumbnail -->
                <ng-template [ngSwitchCase]="KnownCellRendererTypes.Thumbnail | lowercase">
                    <app-thumbnail
                        class="thumbnail"
                        [id]="rowIndex"
                        [thumbnailData]="cellValue"
                        [displayPopup]="true"
                        [popupPosition]="PopupPosition.Right"
                        placeholderOpacity="19%"
                    ></app-thumbnail>
                </ng-template>
                <!-- List -->
                <ng-template [ngSwitchCase]="KnownCellRendererTypes.List | lowercase">
                    <gen-text *ngLet="cellValue ? cellValue.join(', ') : '' as listData" [appTooltip]="listData" appTooltipOnlyIfOverflow>
                        {{ listData }}
                    </gen-text>
                </ng-template>
                <!-- ImageWithText -->
                <ng-template [ngSwitchCase]="KnownCellRendererTypes.ImageWithText | lowercase">
                    <ng-container *ngIf="cellValue?.Image">
                        <app-thumbnail
                            class="thumbnail-with-text"
                            [id]="rowIndex"
                            [thumbnailData]="cellValue.Image"
                            [displayPopup]="true"
                            [popupPosition]="PopupPosition.Right"
                            placeholderOpacity="19%"
                        ></app-thumbnail>
                    </ng-container>
                    <gen-text class="ml-1" [appTooltip]="cellValue?.Text" appTooltipOnlyIfOverflow>{{ cellValue?.Text }}</gen-text>
                </ng-template>
                <!-- StatusWithColor -->
                <ng-template [ngSwitchCase]="KnownCellRendererTypes.StatusWithColor | lowercase">
                    <gen-text
                        [flavor]="cellValue | accessStatusToColor"
                        *ngLet="cellValue | accessStatusToTranslatedString as accessStatus"
                        [appTooltip]="accessStatus"
                        appTooltipOnlyIfOverflow
                    >
                        {{ accessStatus }}
                    </gen-text>
                </ng-template>
            </ng-container>
        </ng-template>

        <!-- Fallback to a default text cell -->
        <ng-template ngSwitchDefault>
            <gen-text [appTooltip]="cellValue" appTooltipOnlyIfOverflow>{{ cellValue }}</gen-text>
        </ng-template>
    </ng-container>
</ng-template>
