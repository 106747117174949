import { Component, Inject, OnInit } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { SharedCommands } from '@modules/shared/enumerations/shared-commands';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { CommandContext, COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { EventMonitoringFeatureFlags } from '@modules/shared/services/events/feature-flags';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { UserWatchlistService } from '@modules/shared/services/watchlist/user-watchlist.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Entity } from 'RestClient/Client/Model/Entity';
import { IconsService } from '@modules/shared/services/icons.service';
import { EntityQuery } from 'RestClient/Client/Queries/EntityQuery';
import { WatchlistEntry } from '@modules/shared/api/api';
import { from, Observable, of } from 'rxjs';
import { KnownCustomHeaderIds } from '@modules/shared/enumerations/side-pane-custom-headers';
import { UserWatchlistRequestService } from '@modules/shared/services/watchlist/user-watchlist-request.service';
import { EventMonitoringContext } from '@modules/shared/enumerations/analytics-event-monitoring-context';
import { EventMonitoringLoggerService } from '@modules/shared/services/analytics/event-monitoring-logger.service';
import { map, switchMap } from 'rxjs/operators';
import { EventsMonitoringService } from '@modules/general/services/Events/events-monitoring.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

interface MonitoredEntity {
    icon: string;
    name: string;
}

@UntilDestroy()
@Component({
    selector: 'app-watchlist-sidepane-header',
    templateUrl: './watchlist-sidepane-header.component.html',
    styleUrls: ['./watchlist-sidepane-header.component.scss'],
})
@InternalPluginDescriptor({
    type: WatchlistSidepaneHeaderComponent,
    pluginTypes: [PluginTypes.SidePaneHeader],
    exposure: {
        id: WatchlistSidepaneHeaderComponent.pluginId,
        subSection: KnownCustomHeaderIds.WatchlistCustomHeaderId,
    },
    isSupported: () => true,
    requirements: { enabledFeatureFlags: [EventMonitoringFeatureFlags.General] },
})
export class WatchlistSidepaneHeaderComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('A2853EED-581C-47D0-A7AB-9B13B660F3E1');
    public monitoredEntities: MonitoredEntity[] = [];
    public isLoading = false;
    public isMenuOpened = false;
    public headerTitle = '';
    public monitoredEntitiesButtonLabel = '';
    public hasEvents$: Observable<boolean>;
    public hasUnreadEvents$: Observable<boolean>;

    constructor(
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        trackingService: TrackingService,
        private translateService: TranslateService,
        private userWatchlistService: UserWatchlistService,
        private securityCenterClientService: SecurityCenterClientService,
        private iconsService: IconsService,
        private eventsMonitoringService: EventsMonitoringService,
        private userWatchlistRequestService: UserWatchlistRequestService,
        private eventMonitoringLoggerService: EventMonitoringLoggerService
    ) {
        super(trackingService);
        this.hasEvents$ = eventsMonitoringService.receivedEvents$.pipe(map((events) => events.length > 0));
        this.hasUnreadEvents$ = eventsMonitoringService.receivedEvents$.pipe(map((events) => events.filter((event) => event.isNew).length > 0));
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.fetchMonitoredEntities();
        this.updateHeaderLabels();
    }

    public toggleMenu(forceClose: boolean = false): void {
        if (forceClose && this.isMenuOpened) this.isMenuOpened = false;
        else {
            this.isMenuOpened = !this.isMenuOpened;
            this.eventMonitoringLoggerService.logEvent(
                'Watchlist header menu toggled',
                'Watchlist header menu has been toggled',
                EventMonitoringContext.Watchlist,
                this.isMenuOpened ? 'Opened' : 'Closed'
            );
        }
    }

    public async enterEditMode(): Promise<void> {
        await this.userWatchlistRequestService.showWatchlistEditorAsync();
        this.eventMonitoringLoggerService.logEvent('Opened edit watchlist', 'Opened edit watchlist from the watchlist header', EventMonitoringContext.Watchlist);
    }

    public openEventOptions(): void {
        this.commandsService.executeCommand(SharedCommands.ShowOptions, { type: ContextTypes.Specific, data: { section: OptionSubSections.Events } } as CommandContext);
        this.eventMonitoringLoggerService.logEvent('Opened event options', 'Opened event options from the watchlist header', EventMonitoringContext.Watchlist);
    }

    public markAllAsRead(): void {
        this.eventsMonitoringService.markAllAsRead();
    }

    public dismissAllEvents(): void {
        this.eventsMonitoringService.dismissAllEvents();
        this.eventMonitoringLoggerService.logEvent('Dismissed all events', 'Dismissed all events from the watchlist header', EventMonitoringContext.Watchlist);
    }

    private fetchMonitoredEntities(): void {
        this.isLoading = true;
        this.userWatchlistService.watchlist$
            .pipe(
                switchMap((watchlist) => (watchlist ? from(this.setMonitoredEntities(watchlist.entries)) : of(null))),
                untilDestroyed(this)
            )
            .subscribe(() => (this.isLoading = false));
    }

    private async setMonitoredEntities(entries: WatchlistEntry[]): Promise<void> {
        if (this.monitoredEntities.length > 0) {
            this.monitoredEntities = [];
        }

        const scClient = this.securityCenterClientService?.scClient;

        if (!scClient || entries.length === 0) {
            this.updateHeaderLabels();
            return;
        }

        const monitoredEntitiesQuery = new EntityQuery();
        entries.forEach((userWatchlistItem) => {
            monitoredEntitiesQuery.guids.add(userWatchlistItem.entityId);
        });

        const entities = await scClient.getEntitiesAsync(Entity, monitoredEntitiesQuery);
        for (const entity of entities) {
            const icon = this.iconsService.getEntityIcon(entity);
            this.monitoredEntities.push({ icon: icon.toString(), name: entity.name } as MonitoredEntity);
        }

        this.updateHeaderLabels();
    }

    private updateHeaderLabels(): void {
        // Update header title
        this.headerTitle =
            (this.translateService.instant('STE_LABEL_WATCH_LIST') as string) +
            ' - ' +
            (this.monitoredEntities.length === 0 ? (this.translateService.instant('STE_LABEL_EMPTY') as string) : '');

        // Update monitored entities button label
        if (this.monitoredEntities.length === 1) {
            this.monitoredEntitiesButtonLabel = this.translateService.instant('STE_LABEL_SINGLE_ENTITYSELECTION') as string;
        } else {
            this.monitoredEntitiesButtonLabel = stringFormat(this.translateService.instant('STE_LABEL_N_ENTITIESSELECTED') as string, this.monitoredEntities.length.toString());
        }
    }
}
