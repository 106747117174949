import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const AlarmContentTypes = Constants({
    AlarmList: SafeGuid.parse('35E506A8-B5CA-40DB-BE3A-BD64DCB7324B'),
    Instance: SafeGuid.parse('C96A7865-4569-4AFB-8081-05DF70BC2B37'),
    Alarm: SafeGuid.parse('5231F6F5-B2E4-4D18-B05C-7BB785277EAB'),
});
