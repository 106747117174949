import { Inject, Injectable } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeGuid, IGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { ZoneEntity } from 'RestClient/Client/Model/AccessControl/ZoneEntity';
import { IZoneEntity, ZoneEntityFields } from 'RestClient/Client/Interface/IZoneEntity';
import { ZoneType } from 'RestClient/Client/Enumerations/ZoneType';
import { ZoneArmingState } from 'RestClient/Client/Enumerations/ZoneArmingState';
import { ZoneState } from 'RestClient/Client/Enumerations/ZoneState';
import { AccessControlUnitEntity } from 'RestClient/Client/Model/AccessControl/AccessControlUnitEntity';
import { IAccessControlUnitEntity } from 'RestClient/Client/Interface/IAccessControlUnitEntity';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { GeneralCommands } from '../enumerations/general-commands';
import { ZoneContentTypes } from '../enumerations/zone-content-types';
import { CommandArgs, CommandDescriptor } from '../../shared/services/commands/command-provider-base';
import { EntityCommandsUsageContext } from '../../shared/services/commands/commands-usage/entity-commands-usage-context';
import { EntityCommandContext } from '../../shared/services/commands/entity-command-context';
import { EntityCommandProviderBase, ExecuteEntityCommandData } from '../../shared/services/commands/entity-command-provider-base';
import {
    CommandBindings,
    CommandsService,
    COMMANDS_SERVICE,
    CommandDisplay,
    EntityCommandRequirements,
    FEATURES_SERVICE,
} from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { LanguageService } from '../../shared/services/language/language.service';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class ZoneCommandProvider extends EntityCommandProviderBase {
    public get requiredEntityFields(): Set<string> {
        return new Set<string>([
            ...super.requiredEntityFields,
            ZoneEntityFields.armingStateField,
            ZoneEntityFields.zoneStateField,
            ZoneEntityFields.accessControlUnitField,
            ZoneEntityFields.maintenanceField,
            ZoneEntityFields.zoneTypeField,
        ]);
    }

    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(
                GeneralCommands.ArmZone,
                this.createZoneCommandDescriptor(
                    GeneralCommands.ArmZone,
                    'STE_BUTTON_ARMZONE',
                    MeltedIcon.Lock,
                    this.fillCommandRequirements({ requiredPrivileges: SafeGuid.createSet([KnownPrivileges.armDisarmZonesPrivilege]) })
                )
            );

            this.internalCommandDescriptors = descriptors;
        }

        return this.internalCommandDescriptors;
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);

        // Prefetch privileges
        await this.prefetchPrivileges(commandContext, commandArgs);

        if (await this.isArmZoneAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(GeneralCommands.ArmZone);
            this.configureArmZoneInvalidateCanExecuteAsync(commandContext as EntityCommandsUsageContext).fireAndForget();
        }
        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: GeneralCommands.ArmZone,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeArmZone(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isArmZoneAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) =>
                this.safeCanExecuteEntityCommandAsync((entityCommandContext) => this.canArmZoneAsync(entityCommandContext), commandContext),
            getCommandDisplayHandler: (commandContext) =>
                this.safeGetEntityCommandDisplayAsync((entityCommandContext) => this.getArmZoneDisplayAsync(entityCommandContext), commandContext),
        });
    }

    protected fillCommandRequirements(requirements: EntityCommandRequirements): EntityCommandRequirements {
        if (!requirements.supportedContentTypes) {
            requirements.supportedContentTypes = SafeGuid.createSet([ZoneContentTypes.Zone]);
        }

        if (!requirements.supportedEntityTypes) {
            requirements.supportedEntityTypes = new Set<string>([EntityTypes.Zones]);
        }

        return super.fillCommandRequirements(requirements);
    }

    private async armZoneAsync(commandContext: EntityCommandContext): Promise<void> {
        const zone = await this.extractZoneEntity(commandContext);
        if (zone && (zone.zoneType === ZoneType.Software || zone.zoneType === ZoneType.IO)) {
            if (zone.armingState === ZoneArmingState.Armed) {
                await zone.disarmZoneAsync();
            } else if (zone.armingState === ZoneArmingState.Disarmed) {
                if (zone.zoneState === ZoneState.Active || zone.zoneState === ZoneState.Trouble) {
                    // TODO YN: warn user
                    // STE_MESSAGE_ARM_ZONE_WITH_ACTIVE_TROUBLE_INPUT
                    // continue or not question
                }
                await zone.armZoneAsync();
            }
        }
    }

    private async canArmZoneAsync(commandContext: EntityCommandContext): Promise<boolean> {
        let validState = false;
        const zoneEntity = await this.extractZoneEntity(commandContext);
        if (zoneEntity) {
            if (zoneEntity.zoneType === ZoneType.Software) {
                validState = true;
            } else if (zoneEntity.zoneType === ZoneType.IO && !zoneEntity.maintenance && (await this.canIOZoneManuallyArmOrDisarmAsync(zoneEntity))) {
                // We can also arm a IO zone manually if not in maintenance and master unit is online
                validState = true;
            }
        }
        return validState;
    }

    private async canIOZoneManuallyArmOrDisarmAsync(zoneEntity: IZoneEntity): Promise<boolean> {
        if (!zoneEntity) {
            return false;
        }

        // Do not assume that zone.Running is a valid assumption about the Zone ability to be manually Armed or Disarmed
        // A zone not running could be a zone with all its inputs offline, but its master unit AND its outputs could be running
        //   and still able to receive arm or disarm actions
        if (zoneEntity.accessControlUnit.isEmpty()) {
            return false;
        }

        const unit = await this.getEntityAsync<AccessControlUnitEntity, IAccessControlUnitEntity>(AccessControlUnitEntity, undefined, undefined, zoneEntity.accessControlUnit);
        if (!unit) {
            return false;
        }
        return true;
    }

    private async configureArmZoneInvalidateCanExecuteAsync(commandContext: EntityCommandsUsageContext): Promise<void> {
        if (commandContext.entityCache && commandContext.invalidateCanExecute) {
            const zoneEntity = await this.extractZoneEntity(commandContext);
            if (zoneEntity) {
                commandContext.entityCache
                    .detectFieldChangeAsync(zoneEntity, () => zoneEntity.armingState, ((entity1: IZoneEntity, newValue: string, oldValue: string) => {
                        commandContext.invalidateCanExecute(GeneralCommands.ArmZone);
                        commandContext.invalidateDisplay(GeneralCommands.ArmZone);
                        return new Deferred<void>(true).promise;
                    }) as modificationHandlerField<string>)
                    .fireAndForget();
            }
        }
    }

    private createZoneCommandDescriptor(
        commandId: IGuid,
        nameResourceId: string,
        icon: MeltedIcon,
        requirements?: EntityCommandRequirements,
        keyBinding?: string
    ): CommandDescriptor {
        return {
            id: commandId,
            nameResourceId,
            icon,
            groupNameResourceId: 'STE_ENTITY_ZONE',
            groupIcon: MeltedIcon.Perimeter,
            requirements,
            keyBinding,
        };
    }

    private executeArmZone(executeCommandData: ExecuteEntityCommandData): void {
        this.armZoneAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async extractZoneEntity(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<IZoneEntity | null> {
        return this.extractEntityAsync<ZoneEntity, IZoneEntity>(ZoneEntity, commandContext, commandArgs);
    }

    private async getArmZoneDisplayAsync(commandContext: EntityCommandContext): Promise<CommandDisplay | undefined> {
        const zoneEntity = await this.extractZoneEntity(commandContext);
        if (zoneEntity) {
            if (zoneEntity.armingState === ZoneArmingState.Armed) {
                return { name: () => this.translateService?.instant('STE_BUTTON_DISARMZONE') as string, icon: MeltedIcon.Unlock };
            } else {
                return { name: () => this.translateService?.instant('STE_BUTTON_ARMZONE') as string, icon: MeltedIcon.Lock };
            }
        }
    }

    private async isArmZoneAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(GeneralCommands.ArmZone, commandContext, commandArgs);
    }

    //#endregion
}
