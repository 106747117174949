import { IGuid, SafeGuid } from 'safeguid';
import { toGuid } from '../utils/guid-utils';
import { IncidentEvent, ProcedureStepActionType, ProcedureStepAnsweredEvent } from './events/incident-event';
import { Option, OptionApi } from './option';

export interface StepApi {
    Id: IGuid;
    Name: string;
    Instructions: string;
    Options: OptionApi[];
    IsSkippable: boolean;
    IsCommentRequired: boolean;
    IsFirstStep: boolean;
}

export class Step {
    constructor(
        public id: IGuid,
        public name: string,
        public instructions: string,
        public options: Option[],
        public isSkippable: boolean,
        public isCommentRequired: boolean,
        public isFirstStep: boolean
    ) {}

    public static assign(step: StepApi): Step {
        return new Step(toGuid(step.Id), step.Name, step.Instructions, step.Options.map(Option.assign), step.IsSkippable, step.IsCommentRequired, step.IsFirstStep);
    }

    public isRoot(incidentEvents: IncidentEvent[]): boolean {
        const forwardSteps = [ProcedureStepActionType.Complete, ProcedureStepActionType.Resume, ProcedureStepActionType.SystemComplete];
        const stepAnsweredEvents = incidentEvents
            .filter((iie) => iie instanceof ProcedureStepAnsweredEvent && iie.nextStepId.equals(this.id) && Object.values(forwardSteps).includes(iie.actionType))
            .sort((a, b) => new Date(a.processTimeUtc).getTime() - new Date(b.processTimeUtc).getTime())
            .reverse();

        const stepAnsweredEvent = stepAnsweredEvents.length > 0 ? (stepAnsweredEvents[0] as ProcedureStepAnsweredEvent) : null;

        return (
            this.isFirstStep ||
            (!!stepAnsweredEvent && (ProcedureStepActionType[stepAnsweredEvent?.actionType] === ProcedureStepActionType[ProcedureStepActionType.SystemComplete] ?? false))
        );
    }
}
