import { VideoWatermarkingRenderer } from '../../utils/VideoWatermarkingRenderer';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { ILogger } from '../../utils/logger';
import { Timer } from '../../utils/Timer';
import { TimeSpan } from '../../utils/TimeSpan';

export class MseWatermarkOverlay {
  private readonly m_htmlVideoElement: HTMLVideoElement;

  private readonly m_vwoRenderingCanvasElement: HTMLCanvasElement;

  private readonly m_logger: ILogger;

  private readonly m_timer: Timer;

  private readonly m_divContainer: HTMLDivElement;

  private m_watermarkRenderer?: VideoWatermarkingRenderer;

  private m_watermarkNeedsRefresh: boolean;

  public get watermarkRenderer(): VideoWatermarkingRenderer | undefined {
    return this.m_watermarkRenderer;
  }

  constructor(logger: ILogger, divContainer: HTMLDivElement, videoElement: HTMLVideoElement, vwoRenderingCanvasElement: HTMLCanvasElement) {
    this.m_logger = logger;
    this.m_divContainer = divContainer;
    this.m_htmlVideoElement = videoElement;
    this.m_vwoRenderingCanvasElement = vwoRenderingCanvasElement;
    this.m_watermarkRenderer = undefined;
    this.m_watermarkNeedsRefresh = true;
    this.m_timer = new Timer(this.drawWatermarkOverlay, new TimeSpan(500));
  }

  public dispose() {
    this.m_timer?.dispose();
    this.clearCanvas();
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig) {
    // Only create a new WatermarkRenderer if the config is enabled
    if (videoWatermarkingConfig !== VideoWatermarkConfig.Disabled) {
      this.m_watermarkRenderer = new VideoWatermarkingRenderer(videoWatermarkingConfig, this.m_vwoRenderingCanvasElement.scrollWidth, this.m_vwoRenderingCanvasElement.scrollHeight);
      this.m_watermarkNeedsRefresh = true;
      this.drawWatermarkOverlay();
      this.m_watermarkNeedsRefresh = true;
    } else {
      this.m_watermarkRenderer = undefined;
      this.clearCanvas();
    }
  }

  public toggleOverlay(enabled: boolean) {
    this.m_logger.debug?.trace(`Setting watermark overlay enabled to ${enabled}`);
    this.clearCanvas();
    if (enabled) {
      this.m_watermarkNeedsRefresh = true;
      this.drawWatermarkOverlay();
      this.m_watermarkNeedsRefresh = true;
      this.m_timer.start();
    } else {
      this.m_timer.stop();
    }
  }

  private clearCanvas() {
    const context = this.m_vwoRenderingCanvasElement.getContext('2d');
    if (!context) {
      throw new Error('Unable to get context for VWO rendering canvas');
    }
    context.clearRect(0, 0, this.m_vwoRenderingCanvasElement.width, this.m_vwoRenderingCanvasElement.height);
    this.m_watermarkNeedsRefresh = true;
  }

  private readonly drawWatermarkOverlay = () => {
    if (this.m_watermarkRenderer === undefined) {
      return;
    }
    const canvas = this.m_vwoRenderingCanvasElement;
    if (canvas.scrollWidth === canvas.width && canvas.scrollHeight === canvas.height && !this.m_watermarkNeedsRefresh) {
      return;
    }
    const video = this.m_htmlVideoElement;
    const ctx = canvas.getContext('2d');

    if (ctx !== null) {
      let width = video.videoWidth;
      let height = video.videoHeight;
      let drawnWithVideoDimensions = true;
      if (width === 0 || height === 0) {
        width = this.m_divContainer.scrollWidth;
        height = this.m_divContainer.scrollHeight;
        if (width === 0 || height === 0) {
          return;
        }
        drawnWithVideoDimensions = false;
      }

      // Handle resizing
      canvas.width = canvas.scrollWidth;
      canvas.height = canvas.scrollHeight;

      // Scale and draw the picture to the canvas buffer
      const scaleW = canvas.width / width;
      const scaleH = canvas.height / height;
      let scale; let offsetW = 0; let offsetH = 0;
      if (scaleW > scaleH) {
        scale = scaleH;
        offsetW = (canvas.width - (width * scale)) / 2 / scale;
      } else {
        scale = scaleW;
        offsetH = (canvas.height - (height * scale)) / 2 / scale;
      }
      ctx.scale(scale, scale);
      this.m_watermarkRenderer.updateVideoWatermarkingOverlaySize(width, height);
      this.m_watermarkRenderer.drawVideoWatermarkingOverlay(ctx, offsetW, offsetH);
      ctx.scale(1 / scale, 1 / scale); // always clean up! Reverse the scale
      this.m_watermarkNeedsRefresh = !drawnWithVideoDimensions;
    }
  }
}
