/* eslint-disable */
import { SafeGuid } from 'safeguid';

export class SerializationHelper {
    static toInstance<T>(obj: T, json: string): T {
        const jsonObj = JSON.parse(json);
        if (typeof (obj as any).fromJSON === 'function') {
            (obj as any).fromJSON(jsonObj);
        } else {
            for (const propName in jsonObj) {
                const propType = typeof (obj as any)[propName];
                if (propType === typeof SafeGuid) {
                    (obj as any)[propName] = SafeGuid.parse(jsonObj[propName].value);
                } else {
                    (obj as any)[propName] = jsonObj[propName];
                }
            }
        }
        return obj;
    }
}

/**
 * @param str String to parse
 * @returns The parsed object when it succeeds, else null
 */
export function tryParseJson(str: unknown): Record<string, unknown> | null {
    try {
        return JSON.parse(str as string);
    } catch {
        return null;
    }
}
