import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { SecurityCenterVersion } from './SecurityCenterVersion';

export interface EntityPropWrapper<T extends IEntity, P extends keyof T> {
    type: 'get' | 'method';
    minimumVersion: string;
    propertyName: P;
    default: T[P] | ((...args: any[]) => unknown);
}

export const backwardCompatible = <E extends IEntity, P extends keyof E, PT extends E[P]>(
    type: 'get' | 'method',
    minimumVersion: string,
    propertyName: P,
    defaultValue: PT
): EntityPropWrapper<E, P> => {
    return {
        type,
        minimumVersion,
        propertyName,
        default: defaultValue,
    };
};

export function BackwardCompatible<E extends Entity>(propWrappers?: EntityPropWrapper<E, keyof E>[]) {
    return (constructor: new (...args: any[]) => Entity): new (...args: any[]) => E => {
        const classWrapped: new (...args: any[]) => E = class WrappedEntity extends constructor {
        } as new (entityType?: string) => E;
        for (const propertyToWrap of propWrappers ?? []) {
            if (propertyToWrap.type === 'get') {
                const propertyDescriptor = Object.getOwnPropertyDescriptor(constructor.prototype, propertyToWrap.propertyName);
                if (propertyDescriptor) {
                    const originalGet = propertyDescriptor.get;
                    const originalSet = propertyDescriptor.set;
                    if (originalGet) {
                        Object.defineProperty(classWrapped.prototype, propertyToWrap.propertyName, {
                            get: function () {
                                const currentVersion = this._client.securityCenterVersion?.securityCenterExactVersion ?? null;
                                return SecurityCenterVersion.isCompatible(currentVersion, propertyToWrap.minimumVersion) ? originalGet.call(this) : propertyToWrap.default;
                            },
                            set: function (value: unknown) {
                                const currentVersion = this._client.securityCenterVersion?.securityCenterExactVersion ?? null;
                                if (SecurityCenterVersion.isCompatible(currentVersion, propertyToWrap.minimumVersion)) originalSet?.call(this, value);
                            },
                        });
                    }
                }
            } else {
                const originalImpl = classWrapped.prototype[propertyToWrap.propertyName];
                if (typeof originalImpl === 'function' && typeof propertyToWrap.default === 'function') {
                    classWrapped.prototype[propertyToWrap.propertyName] = function (...args: any[]) {
                        const currentVersion = this._client.securityCenterVersion?.securityCenterExactVersion ?? null;
                        return SecurityCenterVersion.isCompatible(currentVersion, propertyToWrap.minimumVersion)
                            ? originalImpl.call(this, ...args)
                            : (propertyToWrap.default as any).call(this, ...args);
                    };
                }
            }
        }
        return classWrapped;
    };
}
