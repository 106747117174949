<div #mapControl class="map-control" (appResizeWidth)="onMapControlResize($event)">
    <div class="map-control__group map-control__group__floor" *ngIf="floorInfo.floors.length > 1">
        <div class="map-control__group__sub-group">
            <gen-button
                id="mapControlsGoToParent"
                appTooltip="{{ 'STE_LABEL_NAVIGATE_TO_PARENT' | translate }}"
                data-cy="goToParent"
                [disabled]="!isGoToParentEnabled"
                [flavor]="ButtonFlavor.Flat"
                (click)="goToParentMap()"
            >
                <gen-icon [icon]="Icon.ArrowLeft"></gen-icon
            ></gen-button>
            <gen-separator *ngIf="floorInfo.floors.length > 4"></gen-separator>
            <div #floorsScrollContainer class="map-control__group__floor__scroll" data-cy="floorsButtonContainer">
                <div class="gen-toggle-button" [ngClass]="{ selected: isFloorSelected(floor) }" *ngFor="let floor of getSortedFloors(); let i = index" tabindex="-1">
                    <button
                        class="gen-button flat"
                        tabindex="0"
                        [appTooltip]="floor.name"
                        (click)="switchFloor(floor)"
                        (keydown.arrowup)="focus(i - 1, $event)"
                        (keydown.arrowdown)="focus(i + 1, $event)"
                        [innerHtml]="floor.abbreviation.slice(0, 3)"
                    ></button>
                </div>
            </div>
        </div>
    </div>

    <div class="map-control__group">
        <div class="map-control__group__sub-group">
            <gen-button
                id="presetButton"
                class="map-button map-button--presets"
                appTooltip="{{ 'STE_LABEL_PRESETS' | translate }}"
                genFindElementQuery="div.gen-list-item[tabindex='0']"
                genFindDocumentElementQuery=".gen-contextmenu"
                [flavor]="ButtonFlavor.Flat"
                [genAutomaticNavigation]="true"
                (click)="toggleIsPresetsOpen()"
            >
                <gen-icon [icon]="Icon.Show"></gen-icon
            ></gen-button>
        </div>
        <div class="map-control__group__sub-group">
            <gen-button class="map-button map-button--zoom" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_BUTTON_ZOOMIN' | translate }}" (click)="zoom(true)">
                <gen-icon [icon]="Icon.Plus"></gen-icon
            ></gen-button>

            <gen-button class="map-button map-button--zoom" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_BUTTON_ZOOMOUT' | translate }}" (click)="zoom(false)">
                <gen-icon [icon]="Icon.Minus"></gen-icon
            ></gen-button>
        </div>
    </div>
</div>

<gen-menu #contextMenu targetSelector="#presetButton" [appMenuDataSource]="contextMenuPresets"></gen-menu>
