import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Icon, ItemSlot } from '@genetec/gelato';
import { ICardholderEntity } from 'RestClient/Client/Interface/ICardholderEntity';
import { CardholderEntity } from 'RestClient/Client/Model/AccessControl/CardholderEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SafeGuid } from 'safeguid';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
//
const isContentSupported = (content: Content): boolean => {
    return !!(content?.contextContent && content.type.equals(AccessControlContentTypes.Door) && content.contextContent?.type.equals(AccessControlContentTypes.Event));
};

@Component({
    selector: 'app-door-event-widget',
    templateUrl: './door-event-widget.component.html',
    styleUrls: ['./door-event-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalContentPluginDescriptor({
    type: DoorEventWidgetComponent,
    pluginTypes: [PluginTypes.MapPopupCompact, PluginTypes.MapPopupExpand],
    exposure: { id: DoorEventWidgetComponent.pluginId, priority: 0 },
    isContentSupported,
})
export class DoorEventWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('6E3428A3-D538-4DA2-93B1-56ECF98FC0F2');

    public content?: Content;
    public dataContext: unknown;

    public icon: Icon | null = Icon.Cardholder;
    public readonly ItemSlot = ItemSlot;
    public description: string | undefined = 'Unknown';
    public cardholderPicture: string | undefined = undefined;

    private scClient!: SecurityCenterClient;

    constructor(private securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService) {
        super(trackingService);
    }

    public async ngOnInit() {
        super.ngOnInit();

        if (this.content) {
            this.description = this.content.contextContent?.description;
            const chContent = this.content.contextsubContents?.find((content) => content.type.equals(AccessControlContentTypes.Cardholder));

            if (chContent) {
                this.description = chContent.title;
                this.scClient = this.securityCenterClientService?.scClient;
                const cardholder = await this.scClient?.getEntityAsync<CardholderEntity, ICardholderEntity>(CardholderEntity, SafeGuid.parse(chContent.source), null, null, '');

                if (cardholder) {
                    const picture = await cardholder.getPictureAsync();
                    if (picture !== null) {
                        const pictureData = picture.data;
                        if (pictureData) {
                            this.cardholderPicture = 'data:image/png;base64,' + pictureData;
                        }
                    }
                }
            }
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
