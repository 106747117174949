import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

/**
 * Put the keyboard focus on the popup when it opens
 * To focus a specific element (ex : input) when the popup opens, add the "open-focus" class to your HTML element
 */
@Directive({
    selector: '[appFocusPopupWhenOpen]',
})
export class FocusPopupWhenOpenDirective implements OnInit, OnDestroy {
    private popup!: HTMLGenPopupElement;
    private oldFocus?: HTMLElement;
    private mutationObserver?: MutationObserver;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        this.popup = this.el.nativeElement as HTMLGenPopupElement;
        this.popup.addEventListener('didOpen', didOpenCallback);

        this.mutationObserver = new MutationObserver(() => {
            if (this.popup.open) {
                // Tabindex -1 will be focusable via .focus() but will not be accessible by keyboard
                if (!this.popup.hasAttribute('tabindex')) {
                    this.popup.setAttribute('tabindex', '-1');
                }

                this.oldFocus = document.activeElement as HTMLElement;

                if (!this.popup.querySelector<HTMLElement>('.open-focus')) {
                    this.popup?.focus();
                }
            } else if (this.oldFocus) {
                // Restore focus on active element before popup closes
                this.oldFocus.focus();
                this.oldFocus = undefined;
            }
        });

        this.mutationObserver.observe(this.el.nativeElement as HTMLGenPopupElement, { attributes: true });
    }

    ngOnDestroy(): void {
        this.mutationObserver?.disconnect();
        this.popup.removeEventListener('didOpen', didOpenCallback);
    }
}

function didOpenCallback(this: HTMLElement): void {
    this.querySelector<HTMLElement>('.open-focus')?.focus();
}
