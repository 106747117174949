import { IGuid, SafeGuid } from 'safeguid';

export class EventFilter {
    public expandInfo = true;
    public entities: Set<IGuid>;
    public eventTypes: Set<string>;
    public entityTypes: Set<string>;
    public customEventTypeIds: Set<number>;

    constructor() {
        this.entities = SafeGuid.createSet();
        this.eventTypes = new Set<string>();
        this.entityTypes = new Set<string>();
        this.customEventTypeIds = new Set<number>();
    }

    public static buildAsync(sources: IterableIterator<EventFilter>): EventFilter {
        const newFilter = new EventFilter();
        for (const value of sources) {
            newFilter.merge(value);
        }
        return newFilter;
    }

    public clone(): EventFilter {
        const filter = new EventFilter();
        for (const value of this.entities) {
            filter.entities.add(value);
        }

        for (const value of this.entityTypes) {
            filter.entityTypes.add(value);
        }

        for (const value of this.eventTypes) {
            filter.eventTypes.add(value);
        }

        for (const value of this.customEventTypeIds) {
            filter.customEventTypeIds.add(value);
        }

        filter.expandInfo = this.expandInfo;
        return filter;
    }

    public compareTo(newFilter: EventFilter): CompareResult {
        const result = new CompareResult();

        ///////////////////////////////////////////////////////////////
        // Entitites
        for (const id of newFilter.entities) {
            if (this.entities.has(id) === false) {
                result.addedFilter.entities.add(id);
                result.added = true;
            }
        }

        for (const id of this.entities) {
            if (newFilter.entities.has(id) === false) {
                result.removedFilter.entities.add(id);
                result.removed = true;
            }
        }

        //////////////////////////////////////////////////////////////
        // Events
        for (const evt of newFilter.eventTypes) {
            if (this.eventTypes.has(evt) === false) {
                result.addedFilter.eventTypes.add(evt);
                result.added = true;
            }
        }

        for (const evt of this.eventTypes) {
            if (newFilter.eventTypes.has(evt) === false) {
                result.removedFilter.eventTypes.add(evt);
                result.removed = true;
            }
        }

        //////////////////////////////////////////////////////////////
        // CustomEvents
        for (const evt of newFilter.customEventTypeIds) {
            if (this.customEventTypeIds.has(evt) === false) {
                result.addedFilter.customEventTypeIds.add(evt);
                result.added = true;
            }
        }

        for (const evt of this.customEventTypeIds) {
            if (newFilter.customEventTypeIds.has(evt) === false) {
                result.removedFilter.customEventTypeIds.add(evt);
                result.removed = true;
            }
        }

        //////////////////////////////////////////////////////////////
        // EntityTypes
        for (const eType of newFilter.entityTypes) {
            if (this.entityTypes.has(eType) === false) {
                result.addedFilter.entityTypes.add(eType);
                result.added = true;
            }
        }

        for (const eType of this.entityTypes) {
            if (newFilter.entityTypes.has(eType) === false) {
                result.removedFilter.entityTypes.add(eType);
                result.removed = true;
            }
        }

        return result;
    }

    public merge(newFitler: EventFilter): boolean {
        let modified = false;

        for (const id of newFitler.entities) {
            if (this.entities.has(id) === false) {
                this.entities.add(id);
                modified = true;
            }
        }

        for (const evt of newFitler.eventTypes) {
            if (this.eventTypes.has(evt) === false) {
                this.eventTypes.add(evt);
                modified = true;
            }
        }

        for (const evt of newFitler.customEventTypeIds) {
            if (this.customEventTypeIds.has(evt) === false) {
                this.customEventTypeIds.add(evt);
                modified = true;
            }
        }

        for (const eType of newFitler.entityTypes) {
            if (this.entityTypes.has(eType) === false) {
                this.entityTypes.add(eType);
                modified = true;
            }
        }

        // can only set merge to true... never merge back to false;
        if (this.expandInfo === false && newFitler.expandInfo === true) {
            this.expandInfo = true;
            modified = true;
        }

        return modified;
    }

    public toJson(): string {
        const obj = {
            Entities: new Array<object>(),
            EntityTypes: new Array<string>(),
            EventTypes: new Array<string>(),
            CustomEventTypeIds: new Array<number>(),
            ExpandInfo: this.expandInfo,
        };
        for (const value of this.entities) {
            obj.Entities.push({ key: value.toString(), Value: 'All' });
        }
        for (const value of this.eventTypes) {
            obj.EventTypes.push(value);
        }
        for (const value of this.customEventTypeIds) {
            obj.CustomEventTypeIds.push(value);
        }
        for (const value of this.entityTypes) {
            obj.EntityTypes.push(value);
        }
        return JSON.stringify(obj);
    }
}

class CompareResult {
    public addedFilter: EventFilter;
    public removedFilter: EventFilter;
    public added: boolean;
    public removed: boolean;

    constructor() {
        this.addedFilter = new EventFilter();
        this.removedFilter = new EventFilter();
        this.added = false;
        this.removed = false;
    }
}
