<gen-item
    class="gen-item"
    *ngIf="entity"
    [ngClass]="{
        'fg-color-danger gen-item-danger': entity.isOffline,
        'fg-color-warning gen-item-warning': entity.hasWarnings && !entity.isInMaintenance,
        'fg-color-maintenance gen-item-maintenance': entity.isInMaintenance && !entity.isOffline,
        'with-expander': withExpander
    }"
>
    <app-custom-icon
        slot="gen-icon"
        class="item-icon"
        [icon]="showIcon ? entity.icon : undefined"
        [entityId]="entity.guid"
        [customIconId]="showIcon ? entity.customIconId : undefined"
    >
    </app-custom-icon>
    <gen-text slot="gen-text" [appTooltip]="entity.name" [appTooltipOnlyIfOverflow]="appTooltipOnlyIfOverflow ?? false">
        <span>{{ entity.name }}</span>
    </gen-text>
</gen-item>
