import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { IGuid, SafeGuid } from 'safeguid';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { InternalPluginDescriptor } from '../../../interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '../../../interfaces/plugins/public/plugin-types';
import { EntityBrowserComponent } from '../entity-browser/entity-browser.component';
import { EntityBrowserService } from '../../../entity-browser/entity-browser.service';
import { TrackedComponent } from '../../tracked/tracked.component';
import { TrackingService } from '../../../services/tracking.service';
import { EntityBrowserFilter } from '../../../entity-browser/filters/entity-browser-filter';
import { SharedEntityBrowserRefreshTypes } from '../../../entity-browser/enumerations/shared-entity-browser-refresher-types';

@Component({
    selector: 'app-entity-browser-task',
    templateUrl: './entity-browser-task.component.html',
    styleUrls: ['./entity-browser-task.component.scss'],
})
@InternalPluginDescriptor({
    type: EntityBrowserTaskComponent,
    pluginTypes: [PluginTypes.Task],
    exposure: {
        id: EntityBrowserTaskComponent.pluginId,
        icon: MeltedIcon.Biohazard,
        priority: 1000,
        title: 'Entity browser test page',
        // tags: ['test'],
    },
    isSupported: () => false,
})
export class EntityBrowserTaskComponent extends TrackedComponent implements AfterViewInit, OnInit {
    public static pluginId = SafeGuid.parse('C9C9674B-13B5-4C4C-935F-FC4BFB4F3F34');

    @ViewChild('entityBrowser')
    public entityBrowser!: EntityBrowserComponent;

    public areItemsCheckable = false;

    constructor(trackingService: TrackingService, private entityBrowserService: EntityBrowserService) {
        super(trackingService);
    }

    public ngOnInit(): void {
        this.areItemsCheckable = true;
    }

    public ngAfterViewInit(): void {
        // const filter = new EntityBrowserFilter(await this.entityBrowserService.getDefaultHierarchicalTypesAsync());

        // if (this.filter instanceof HierarchicalEntityBrowserFilter) {
        //    filter.expandedParents.add(SafeGuid.parse('fd1fcc08-5b8f-47cc-a232-8eabd37b0318'));
        // }

        const filter = new EntityBrowserFilter();
        filter.entityTypes.add(EntityTypes.Cameras);
        this.entityBrowser.areItemsCheckable = false;
        filter.pageInfo.pageSize = 100;
        filter.refresherType = SharedEntityBrowserRefreshTypes.FlatView;
        //filter.excludedEntities.add(Guids.Administrators);

        setTimeout(async () => {
            if (this.entityBrowser) {
                await this.entityBrowser.refreshAsync(filter);
            }
        });
    }
}
