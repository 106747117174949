// Autogenerated: v2/help/Classes/ApiUserEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2020-07-23 3:40:08 PM
import { RoutableEvent } from './RoutableEvent';
import { IUserEvent } from '../Interface/IUserEvent';

export class UserEvent extends RoutableEvent implements IUserEvent {
    // #region REST Properties

    // UserDescriptor
    public get userDescriptor(): string {
        return this.getField<string>('UserDescriptor');
    }
    public set userDescriptor(value: string) {
        this.setField<string>('UserDescriptor', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('UserDescriptor', '');
    }

    // #endregion Methods

}

