import { ClipSpaceVec2, Vec2 } from '../utils/Vec2';
import { Vec3 } from '../utils/Vec3';
import { DewarpingParameters } from './DewarpingParameters';
import { DewarpingPlane } from './DewarpingPlane';

//This is the dewarping algorithm but only for a single pixel. It can obtain the coordinates of the the warped pixel for a pixel in the dewarped image
export class DewarpedPointSourceFinder {
  private static readonly m_R: number = 1.0; //Radius of warped image. Normalized to 1.

  private readonly m_dewarpingParameters: DewarpingParameters;
  private readonly m_dewarpingPlane: DewarpingPlane;

  constructor(dewarpingParameters: DewarpingParameters, dewarpingPlane: DewarpingPlane) {
    this.m_dewarpingParameters = dewarpingParameters;
    this.m_dewarpingPlane = dewarpingPlane;
  }

  public find(dewarpedPixel: Vec2): ClipSpaceVec2 {
    const dewarpedPixelInFitResolutionSpace = dewarpedPixel.minus(this.m_dewarpingParameters.FitResolution.PixelOffset);
    const dewarpedCliped = dewarpedPixelInFitResolutionSpace.toClipSpace(this.m_dewarpingParameters.FitResolution.Scaled);

    return this.mapInput(dewarpedCliped);
  }

  private mapInput(clipSpaceVec2: ClipSpaceVec2): ClipSpaceVec2 {
    const pXYZ: Vec3 = this.m_dewarpingPlane.to3D(clipSpaceVec2);

    const polarBeta = pXYZ.PolarBeta;
    const polarDelta = pXYZ.PolarDelta;

    const r_p = DewarpedPointSourceFinder.m_R * this.m_dewarpingParameters.Projection.apply(polarBeta.InRadian);
    const result = new Vec2(polarDelta.cos(), polarDelta.sin()).multiplyC(r_p);
    return new ClipSpaceVec2(result.X, result.Y);
  }
}
