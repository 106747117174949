import { Content, ContentPluginComponent, PluginComponentExposure, PluginComponentRequirements } from '@shared/interfaces/plugins/public/plugin-public.interface';
import { MissionControlContentTypes } from '@modules/mission-control/mission-control-content-types';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InternalContentPluginDescriptor } from '@shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { MCPrivileges } from '@modules/mission-control/mc-privileges';
import { TrackedComponent } from '@shared/components/tracked/tracked.component';
import { IGuid, SafeGuid } from 'safeguid';
import { ButtonFlavor, GenModalService, PopupPosition } from '@genetec/gelato-angular';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { IncidentSelectionService } from '@modules/mission-control/services/incident/incident-selection.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { TrackingService } from '@shared/services/tracking.service';
import { McNotificationService } from '@modules/mission-control/services/mc-notification.service';
import { IncidentCommandService } from '@modules/mission-control/services/incident/incident-command.service';
import { AddCommentCommand } from '@modules/mission-control/models/commands/add-comment-command';
import { PrivilegeService } from '@modules/shared/privilege/privilege.service';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import { StateService } from '@modules/mission-control/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';
import { McUserService } from '@modules/mission-control/services/mc-user.service';
import { ContentFields } from '@modules/mission-control/content-fields';
import { toGuid } from '@modules/mission-control/utils/guid-utils';
import { stringFormat } from '@shared/utilities/StringFormat';

const isContentSupported = (content: Content): boolean => !!content?.type.equals(MissionControlContentTypes.incident);

@Component({
    selector: 'app-quick-action-widget',
    templateUrl: './quick-action-widget.component.html',
    styleUrls: ['./quick-action-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: QuickActionWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: QuickActionWidgetComponent.pluginId } as PluginComponentExposure,
    requirements: {
        optionalGlobalPrivileges: [MCPrivileges.addComment],
    },
    isContentSupported,
})
export class QuickActionWidgetComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('8DDBE03B-8162-4E14-88F9-4DE8DD8F271A');

    public content: Content | null = null;

    public ButtonFlavor = ButtonFlavor;
    public PopupPosition = PopupPosition;

    public comment = '';
    public addCommentAction: () => Promise<boolean>;
    public hasCommentPrivilege = false;
    public data$!: Observable<{ stateName: string; ownerName: string | null }>;

    private addCommentModalId = 'add-comment-modal';
    private _subscription: Subscription | null = null;
    private _selectedIncidentId: IGuid | null = null;

    constructor(
        private _notificationService: McNotificationService,
        private _incidentCommandService: IncidentCommandService,
        private stateService: StateService,
        private _privilegeService: PrivilegeService,
        private _modalService: GenModalService,
        private _selectionService: IncidentSelectionService,
        private _translateService: TranslateService,
        private _userService: McUserService,
        trackingService: TrackingService
    ) {
        super(trackingService);
        this.addCommentAction = this.addComment.bind(this);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.initializePrivileges();
        const selectedIncident$ = this._selectionService.selectedIncident$.pipe(
            filter((i): i is MCIncident => i instanceof MCIncident && this._selectedIncidentId?.equals(i.id) === true)
        );
        const stateName$ = this.getStateName(selectedIncident$);
        const ownerName$ = this.getOwnerName(selectedIncident$);
        this.data$ = combineLatest([stateName$, ownerName$]).pipe(map(([stateName, ownerName]) => ({ stateName, ownerName })));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this._subscription?.unsubscribe();
    }

    public initializePrivileges(): void {
        this.hasCommentPrivilege = this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.addComment);
    }

    public async addComment(): Promise<boolean> {
        if (!this.hasCommentPrivilege) {
            const translatedMessage = this._translateService.instant('STE_MESSAGE_MISSING_X_PRIVILEGE') as string;
            const translatedPrivilegeName = this._translateService.instant('STE_LABEL_ADD_INCIDENT_COMMENT_PRIVILEGE') as string;
            this._notificationService.notifyError(stringFormat(translatedMessage, translatedPrivilegeName));
            return false;
        }

        if (!this._selectedIncidentId) {
            throw new Error('No selected incident id');
        }

        const command = new AddCommentCommand(this._selectedIncidentId.toString(), this.comment);
        return await this._incidentCommandService.tryExecute(command).toPromise();
    }

    public openModal = (): void => {
        this.comment = '';
        this._modalService.show(this.addCommentModalId);
    };

    public closeModal = (): Promise<boolean> => {
        this._modalService.hide(this.addCommentModalId);
        return Promise.resolve(true);
    };

    public setContent(content: Content): void {
        this.content = content;
        if (this.content?.parameters?.hasField(ContentFields.incidentId) ?? false) {
            const incidentIdString = this.content?.parameters?.getField(ContentFields.incidentId) as string;
            this._selectedIncidentId = toGuid(incidentIdString);
        }
    }

    public setDataContext(dataContext: unknown): void {}

    private getStateName(selectedIncident$: Observable<MCIncident>): Observable<string> {
        return selectedIncident$.pipe(
            map((i) => i.stateId),
            switchMap((stateId) =>
                this.stateService.getState$(stateId).pipe(map((x) => x?.name ?? (this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string)))
            )
        );
    }

    private getOwnerName(selectedIncident$: Observable<MCIncident>): Observable<string | null> {
        return selectedIncident$.pipe(
            map((i) => i.ownerId),
            switchMap((ownerId) => {
                if (!ownerId) return of(null);

                if (ownerId.isEmpty()) return of(null);

                return from(this._userService.getUserOrGroup(ownerId)).pipe(map((x) => x?.name));
            })
        );
    }
}
