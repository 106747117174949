// Autogenerated: v2/help/enumerations/systemids?codegen=ts on 2022-07-05 4:50:11 PM
import { SafeGuid } from 'safeguid';

export class Guids {
    public static AccessRuleReportTemplate = SafeGuid.parse('d52780fa-1fb9-452a-842c-5e54f59abf0f');
    public static ActiveOutputBehavior = SafeGuid.parse('00000000-0000-0000-0000-00000000000e');
    public static RemoteUser = SafeGuid.parse('00000000-0000-0000-0000-000000000005');
    public static Administrator = SafeGuid.parse('00000000-0000-0000-0000-000000000003');
    public static Administrators = SafeGuid.parse('00000000-0000-0000-0000-000000000002');
    public static SCCompactOperator = SafeGuid.parse('12aba0d0-6821-4140-93c2-31eea4a18ab4');
    public static SCCompactViewer = SafeGuid.parse('aff25b68-4814-4767-adac-5ecd27836d0f');
    public static SCCompactRestrictedPartition = SafeGuid.parse('33170d62-ea13-431d-8cee-e0bae02dbccf');
    public static AlarmReportTemplate = SafeGuid.parse('89d3dcef-a0fe-48e5-8236-b3fe85f29bfa');
    public static AlertAudioFile = SafeGuid.parse('7eb04bf7-b47c-4505-8194-89ac7413a889');
    public static AllCardholders = SafeGuid.parse('00000000-0000-0000-0000-000000000008');
    public static AllOpenRule = SafeGuid.parse('00000000-0000-0000-0000-00000000000a');
    public static AlwaysSchedule = SafeGuid.parse('00000000-0000-0000-0000-000000000006');
    public static AntipassbackRule = SafeGuid.parse('00000000-0000-0000-0000-00000000000c');
    public static AreaPresenceReportTemplate = SafeGuid.parse('a1c3d7e2-4cef-48a0-bf66-af1630636fbd');
    public static AutoGeneratedReportTemplate = SafeGuid.parse('f67ee4ba-22cc-44af-a0b7-70292df541cf');
    public static Bell1AudioFile = SafeGuid.parse('6b85acd1-b8b8-4b92-9044-5b93c44810cd');
    public static Bell2AudioFile = SafeGuid.parse('faf65682-1be1-4456-966b-5d651070eff9');
    public static Bell3AudioFile = SafeGuid.parse('1281203a-3e58-4245-ab15-a8fb2f38f0df');
    public static BuzzerAudioFile = SafeGuid.parse('d3154a22-b84d-4689-8c7f-c7e35f5541d4');
    public static CardholderActivityReportTemplate = SafeGuid.parse('8ea875c2-ed64-4cb0-8e8c-d10a5100a3ff');
    public static CardholderUnknownImage = SafeGuid.parse('256ed239-8731-4ab0-9ff6-197c8708fcf8');
    public static UnknownImage = SafeGuid.parse('a0b7fd3b-f81a-4581-b206-ef1c3cc3a706');
    public static RootPartition = SafeGuid.parse('00000000-0000-0000-0000-00000000000b');
    public static MasterPartition = SafeGuid.parse('00000000-0000-0000-0000-000000000bbb');
    public static SystemPartition = SafeGuid.parse('00000000-0000-0000-0000-0000000000bb');
    public static DoorActivityReportTemplate = SafeGuid.parse('ab0d97c5-b5ce-44b0-af28-374ba3d43c84');
    public static DoorAudioFile = SafeGuid.parse('cf7ad417-846a-49e7-a818-0f082ed2a3af');
    public static DoorBell1AudioFile = SafeGuid.parse('a976ca14-97e4-4cff-a006-f7e49df81f5f');
    public static DoorBell2AudioFile = SafeGuid.parse('ac3445b4-7b69-4706-9c7f-69b584f07631');
    public static FallbackPrivilege = SafeGuid.parse('cb0157c7-4b19-4e95-b4b7-94a22faa9995');
    public static GlassAudioFile = SafeGuid.parse('760be459-8db3-40e4-ba0a-e850e90373b9');
    public static HealthReportTemplate = SafeGuid.parse('f6e971df-59c0-4a2a-9f6b-ca94bd2fbeba');
    public static LockAudioFile = SafeGuid.parse('0cab3e75-57c6-46d3-a68b-f8af95adc1c5');
    public static LockdownRule = SafeGuid.parse('00000000-0000-0000-0000-000000000009');
    public static NormalOutputBehavior = SafeGuid.parse('00000000-0000-0000-0000-00000000000f');
    public static RootPrivilege = SafeGuid.parse('38acc600-4a32-44ff-8df5-0797d888930b');
    public static Service = SafeGuid.parse('00000000-0000-0000-0000-000000000004');
    public static SystemConfiguration = SafeGuid.parse('00000000-0000-0000-0000-000000000007');
    public static ThreeBellsAudioFile = SafeGuid.parse('5fa90e60-b028-4f75-87ef-b9a4e848cdd7');
    public static TimeAndAttendanceReportTemplate = SafeGuid.parse('528f23bb-174e-4e60-8abb-bc93e9ee9100');
    public static UnitActivityReportTemplate = SafeGuid.parse('f8bc0a89-2a04-434e-8a68-9e2aeb9c192d');
    public static VisitedPersonCustomField = SafeGuid.parse('807bd603-56ed-4fb7-be5c-69321012378e');
    public static VisitedPersonFirstNameCustomField = SafeGuid.parse('c645ce1c-0dff-4208-ab59-73ac93c45058');
    public static VisitedPersonLastNameCustomField = SafeGuid.parse('b6baabdd-09e3-462c-a9da-53f714e0c2c1');
    public static VisitorActivityReportTemplate = SafeGuid.parse('66685b40-3c13-4357-b2c3-6b9f822fdb9d');
    public static VisitorCompanyCustomField = SafeGuid.parse('b40fb66a-e9d5-4b67-82f4-4f55ec529335');
    public static VisitorPhoneNumberCustomField = SafeGuid.parse('c8fee095-3e9c-4e8b-80f1-894ee65604d5');
    public static Visitors = SafeGuid.parse('00000000-0000-0000-0000-00000000000d');
    public static VisitorVisitReasonCustomField = SafeGuid.parse('451c360d-f4c1-498d-b036-28adbe8ec92b');
    public static ZoneActivityReportTemplate = SafeGuid.parse('2a118abb-d4a6-4a36-8c76-8897e6576f99');
    public static PermitZoneEveryone = SafeGuid.parse('0362449c-e193-4747-9d66-e8b483069f1c');
    public static PermitZoneNoPermits = SafeGuid.parse('e216707f-1fc0-46f4-b454-2ce8fd0ca5b4');
    public static PermitZoneAllPermits = SafeGuid.parse('72d06e91-596f-4259-8012-bd2a0a657017');
    public static DefaultUserId = SafeGuid.parse('2ac07729-a2aa-4559-968d-97ce7c7b33e7');
    public static DefaultLprPermitRuleId = SafeGuid.parse('c817318b-10ef-402f-8d35-06e2c2839e4d');
    public static DefaultLprOvertimeRuleId = SafeGuid.parse('7218fc3a-77ab-4568-a0cd-6813f8310c1f');
    public static LprSharedPermitRule = SafeGuid.parse('8c13ce28-0c2c-4517-b8d6-7a22869b87ba');
    public static LprNewWantedRule = SafeGuid.parse('3d7f3d2c-14bb-47b2-b6c8-ccdef109f4fe');
    public static DefaultParkingZoneRule = SafeGuid.parse('09e29d39-83da-4cdc-81cc-0191833cb9a6');
    public static PatrollerDefaultUserId = SafeGuid.parse('04f46295-a54b-499c-80cd-201dd09a3e60');
    public static PublicUserGroup = SafeGuid.parse('00000000-0000-0000-0000-0000000000b2');
    public static RoleModuleApplication = SafeGuid.parse('57e0923a-ace3-4b0a-9cd0-011b7f082705');
    public static DefaultMapId = SafeGuid.parse('6f9592a3-933c-4f6d-9b89-4a0c09056372');
    public static DefaultArchiveConsolidationTransferGroup = SafeGuid.parse('ddcc530c-f8e6-4842-ac3c-7ae9d9c3c917');
    public static RecommendedFirmwareTypeGuid = SafeGuid.parse('52367f49-0261-4237-b02f-4275cbdcefb2');
    public static HardeningEntryGuid = SafeGuid.parse('86ac267a-4b0e-4b65-88f6-1ea97772798d');
    public static LicenseCategory_SecurityCenter = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f61');
    public static LicenseCategory_Synergis = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f62');
    public static LicenseCategory_MissionControl = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f63');
    public static LicenseCategory_Omnicast = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f64');
    public static LicenseCategory_AutoVu = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f65');
    public static LicenseCategory_PlanManager = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f66');
    public static LicenseCategory_Mobile = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f67');
    public static LicenseCategory_Sipelia = SafeGuid.parse('bcedcfb3-e097-4d12-92d4-763811090f68');

    public static readonly values = [
        Guids.AccessRuleReportTemplate,
        Guids.ActiveOutputBehavior,
        Guids.RemoteUser,
        Guids.Administrator,
        Guids.Administrators,
        Guids.SCCompactOperator,
        Guids.SCCompactViewer,
        Guids.SCCompactRestrictedPartition,
        Guids.AlarmReportTemplate,
        Guids.AlertAudioFile,
        Guids.AllCardholders,
        Guids.AllOpenRule,
        Guids.AlwaysSchedule,
        Guids.AntipassbackRule,
        Guids.AreaPresenceReportTemplate,
        Guids.AutoGeneratedReportTemplate,
        Guids.Bell1AudioFile,
        Guids.Bell2AudioFile,
        Guids.Bell3AudioFile,
        Guids.BuzzerAudioFile,
        Guids.CardholderActivityReportTemplate,
        Guids.CardholderUnknownImage,
        Guids.UnknownImage,
        Guids.RootPartition,
        Guids.MasterPartition,
        Guids.SystemPartition,
        Guids.DoorActivityReportTemplate,
        Guids.DoorAudioFile,
        Guids.DoorBell1AudioFile,
        Guids.DoorBell2AudioFile,
        Guids.FallbackPrivilege,
        Guids.GlassAudioFile,
        Guids.HealthReportTemplate,
        Guids.LockAudioFile,
        Guids.LockdownRule,
        Guids.NormalOutputBehavior,
        Guids.RootPrivilege,
        Guids.Service,
        Guids.SystemConfiguration,
        Guids.ThreeBellsAudioFile,
        Guids.TimeAndAttendanceReportTemplate,
        Guids.UnitActivityReportTemplate,
        Guids.VisitedPersonCustomField,
        Guids.VisitedPersonFirstNameCustomField,
        Guids.VisitedPersonLastNameCustomField,
        Guids.VisitorActivityReportTemplate,
        Guids.VisitorCompanyCustomField,
        Guids.VisitorPhoneNumberCustomField,
        Guids.Visitors,
        Guids.VisitorVisitReasonCustomField,
        Guids.ZoneActivityReportTemplate,
        Guids.PermitZoneEveryone,
        Guids.PermitZoneNoPermits,
        Guids.PermitZoneAllPermits,
        Guids.DefaultUserId,
        Guids.DefaultLprPermitRuleId,
        Guids.DefaultLprOvertimeRuleId,
        Guids.LprSharedPermitRule,
        Guids.LprNewWantedRule,
        Guids.DefaultParkingZoneRule,
        Guids.PatrollerDefaultUserId,
        Guids.PublicUserGroup,
        Guids.RoleModuleApplication,
        Guids.DefaultMapId,
        Guids.DefaultArchiveConsolidationTransferGroup,
        Guids.RecommendedFirmwareTypeGuid,
        Guids.HardeningEntryGuid,
        Guids.LicenseCategory_SecurityCenter,
        Guids.LicenseCategory_Synergis,
        Guids.LicenseCategory_MissionControl,
        Guids.LicenseCategory_Omnicast,
        Guids.LicenseCategory_AutoVu,
        Guids.LicenseCategory_PlanManager,
        Guids.LicenseCategory_Mobile,
        Guids.LicenseCategory_Sipelia
    ];
}
