import { ILiteEvent, LiteEvent } from '../../utils/liteEvents';
import { ILogger } from '../../utils/logger';
import { ITransport } from '../../services/ITransport';
import { IWebSocketRequest, IWebSocketResponse, CreateStreamRequest, DeleteStreamRequest, PtzRequest, CreateStreamResponse } from './Messages';
import { SessionCreationParameters, SessionParameters } from './SessionParameters';
import { Utils } from '../../utils/Utils';
import { WebSocketTransportV2 } from '../../services/WebSocketTransportV2';

/**
 * @beta
 * */
export interface IConnectionEvent {
  ConnectionReestablished: ILiteEvent<void>;
  ConnectionLost: ILiteEvent<void>;
}

export interface ISession extends IConnectionEvent {
  readonly ptzControlSession: IPtzControlSession;
  readonly sessionParameters: SessionParameters;

  dispose(): Promise<void>;
  sendCommand(command: IWebSocketRequest): Promise<IWebSocketResponse>;

  debugStatus(indent: number): string;
}

export interface IPtzControlSession {
  sendCommand(command: PtzRequest): void
}

export class WsSessionV2Builder {
  private readonly m_logger: ILogger;

  private readonly m_transport: ITransport;

  public constructor(logger: ILogger, transport: ITransport) {
    this.m_logger = logger.subLogger('WsSessionV2Builder');
    this.m_transport = transport;
  }

  public async build(sessionCreationParameters: SessionCreationParameters): Promise<WsSessionV2> {
    const token = await sessionCreationParameters.tokenRenewer.Token;
    const createStreamRequest = new CreateStreamRequest(sessionCreationParameters.channel, sessionCreationParameters.camera, token, sessionCreationParameters.supportedVideoFormats, sessionCreationParameters.clientVersion);

    this.m_logger.debug?.trace('Sending create stream command');

    const createStreamResponse = await this.m_transport.send(createStreamRequest)
      .catch((reason) => {
        throw new Error(reason);
      }) as CreateStreamResponse;

    const sessionParameters = new SessionParameters(sessionCreationParameters, createStreamResponse.body.transcodingAllowance, createStreamResponse.body.defaultStreamUsage, createStreamResponse.body.videoWatermarkingConfig, createStreamResponse.body.dewarperConfig, createStreamResponse.body.serverVersion);
    return new WsSessionV2(this.m_logger, this.m_transport, sessionParameters);
  }
}

export class WsSessionV2 implements ISession, IPtzControlSession {
  private readonly m_logger: ILogger;

  private readonly m_transport: ITransport;

  private m_sessionParameters: SessionParameters;

  private readonly m_connectionReestablishedEvent = new LiteEvent<void>();

  private readonly m_connectionLostEvent = new LiteEvent<void>();

  private m_isDisposed: boolean = false;

  private m_isConnected: boolean = true; //When connection is created, it is supplied an already connected transport

  public get ConnectionReestablished(): ILiteEvent<void> {
    return this.m_connectionReestablishedEvent.expose();
  }

  public get ConnectionLost(): ILiteEvent<void> {
    return this.m_connectionLostEvent.expose();
  }

  public get ptzControlSession(): IPtzControlSession {
    return this;
  }

  public get sessionParameters(): SessionParameters {
    return this.m_sessionParameters;
  }

  public constructor(logger: ILogger, transport: ITransport, sessionParameters: SessionParameters) {
    this.m_logger = logger.subLogger('WsSessionV2');
    this.m_transport = transport;
    this.m_sessionParameters = sessionParameters;

    this.m_transport.ConnectionReestablished.register(this.onConnectionReestablished);
    this.m_transport.ConnectionLost.register(this.onConnectionLost);
  }

  public async dispose(): Promise<void> {
    if (this.m_isDisposed) {
      return;
    }
    this.m_isDisposed = true;

    this.m_transport.ConnectionReestablished.unregister(this.onConnectionReestablished);
    this.m_transport.ConnectionLost.unregister(this.onConnectionLost);

    if (this.m_isConnected) {
      const deleteStreamCommand = new DeleteStreamRequest(this.m_sessionParameters.channel);
      await this.sendCommand(deleteStreamCommand);
    }
  }

  public async sendCommand(command: IWebSocketRequest): Promise<IWebSocketResponse> {
    return await this.m_transport.send(command);
  }

  private readonly onConnectionReestablished = async () => {
    this.m_logger.info?.trace('Socket connection reestablished, sending create stream command');

    const token = await this.m_sessionParameters.tokenRenewer.Token;
    const createStreamRequest = new CreateStreamRequest(this.m_sessionParameters.channel, this.m_sessionParameters.camera, token, this.m_sessionParameters.supportedVideoFormats, this.m_sessionParameters.clientVersion);
    this.m_transport.send(createStreamRequest)
      .then((response) => {
        const createStreamResponse = response as CreateStreamResponse;
        this.m_sessionParameters = this.sessionParameters.withServerSideSessionParameters(createStreamResponse.body.transcodingAllowance, createStreamResponse.body.defaultStreamUsage, createStreamResponse.body.videoWatermarkingConfig, createStreamResponse.body.dewarperConfig, createStreamResponse.body.serverVersion);

        this.m_logger.info?.trace(`Stream creation request completed with server version: ${createStreamResponse.body.serverVersion}. Raising connection reestablished`);

        this.m_isConnected = true;
        this.m_connectionReestablishedEvent.trigger();
      }, (error) => {
        this.m_logger.warn?.trace('Failed to recreate the stream connection:', error);
      });
  }

  private readonly onConnectionLost = () => {
    this.m_isConnected = false;
    this.m_connectionLostEvent.trigger();
  }

  public debugStatus(indent: number): string {
    let webSocketTransportV2: WebSocketTransportV2 | undefined;
    if (this.m_transport instanceof WebSocketTransportV2) {
      webSocketTransportV2 = this.m_transport;
    }

    return 'WsSessionV2' + Utils.indentNewLine(indent) +
      (this.m_isDisposed ? `${Utils.indentNewLine(indent)}Disposed${Utils.indentNewLine(indent)}` : '') +
      this.m_sessionParameters.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      webSocketTransportV2?.debugStatus(indent + Utils.Indentation);
  }
}
