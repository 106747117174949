import { EventBase } from 'RestClient/Client/Event/EventBase';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { IGuid } from 'safeguid';

export class NotificationsChangedEvent extends EventBase {
    public get added(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('added');
    }

    public get removed(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('removed');
    }
}
