<!-- Tab index -1 : can be focusable with code but not navigable via keyboard navigation -->
<div class="sidepane-container" genKeyboardNavigation (genEscape)="closeSidePane()" tabindex="-1">
    <div class="side-pane-header">
        <div class="header-items">
            <div class="header-wrapper" *ngIf="contentCount$ | async as contentCount">
                <gen-button (click)="onNavigateBack()" [flavor]="ButtonFlavor.Flat" [hidden]="contentCount <= 1">
                    <gen-icon [icon]="Icon.ArrowLeft"></gen-icon>
                </gen-button>
                <gen-spinner class="columns-span-11 vertical-align-center" [hidden]="!isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>

                <app-sidepane-header *ngIf="!isLoading && !hasCustomHeader" [headerInfo]="headerInfo ?? defaultHeaderInfo"></app-sidepane-header>
                <ng-container *ngIf="!isLoading && hasCustomHeader">
                    <app-plugin-host [pluginItem]="sidePaneHeaderPluginItem" style="width: 100%"></app-plugin-host>
                </ng-container>

                <gen-button [flavor]="ButtonFlavor.Flat" (click)="closeSidePane()" tabindex="-1">
                    <gen-icon [icon]="Icon.Close"></gen-icon>
                </gen-button>
            </div>
        </div>
    </div>
    <gen-separator class="side-pane-separator" [style.borderColor]="color"></gen-separator>
    <div class="side-pane-body" *ngIf="pluginsStack.verticalPlugins$ | async as verticalPlugins">
        <ng-container *ngLet="verticalPlugins.length === 1 && (verticalPlugins[0].navigationPlugins[0].parentPlugin.exposure?.standalone ?? false) as isStandalonePlugin">
            <ng-container *ngIf="isStandalonePlugin; else multiplePlugins">
                <app-plugin-host
                    class="side-pane-content-plugin"
                    [pluginItem]="verticalPlugins[0].navigationPlugins[0].parentPlugin"
                    [dataContext]="contextDataService"
                ></app-plugin-host>
            </ng-container>
            <ng-template #multiplePlugins>
                <ng-container *ngFor="let vertical of verticalPlugins" class="va-bottom ha-center">
                    <gen-card class="context-plugin-card focus-style-override">
                        <gen-card-body class="context-plugin-card-body">
                            <ng-container *ngIf="vertical.navigationPlugins[0].childPlugins.length; else standalonePlugin">
                                <app-navigation-plugin-host
                                    class="navigation-plugin"
                                    [contextDataPlugins]="vertical.navigationPlugins"
                                    mainClass="h-100 w-100 p-relative"
                                    [dataContext]="contextDataService"
                                >
                                </app-navigation-plugin-host>
                            </ng-container>
                            <ng-template #standalonePlugin>
                                <app-plugin-host [pluginItem]="vertical.navigationPlugins[0].parentPlugin" [dataContext]="contextDataService"></app-plugin-host>
                            </ng-template>
                        </gen-card-body>
                    </gen-card>
                </ng-container>
            </ng-template>
        </ng-container>

        <div *ngIf="!hasContent && !isLoading" class="no-content w-100 h-80">
            <div class="error-message">
                <img src="assets/images/illustration-no-content.svg" alt="Girl being sorry" />
                <gen-text class="mt-1">{{ 'STE_MESSAGE_INFO_NO_CONTENT' | translate }}</gen-text>
                <gen-text flavor="secondary">{{ 'STE_MESSAGE_INFO_CONTENT_UNAVAILABLE_OR_NON_EXISTING' | translate }}</gen-text>
            </div>
        </div>
    </div>
</div>
