import { ILogger } from '../../utils/logger';
import { SegmentInfo } from '../../services/eventHub';

export class BufferQueue {
  private readonly m_logger: ILogger;

  private readonly m_queue: [SegmentInfo, Int8Array][];

  public get isEmpty(): boolean {
    return this.m_queue.length === 0;
  }

  public get count(): number {
    return this.m_queue.length;
  }

  constructor(logger: ILogger) {
    this.m_logger = logger.subLogger('BufferQueue');
    this.m_queue = new Array<[SegmentInfo, Int8Array]>(0);
  }

  public dispose() {
    this.m_logger.debug?.trace(`dispose() (dropping ${this.m_queue.length} buffers)`);
    this.m_queue.length = 0;
  }

  public reset() {
    this.m_logger.debug?.trace(`reset() (dropping ${this.m_queue.length} buffers)`);
    this.m_queue.length = 0;
  }

  public enqueue(info: SegmentInfo, buffer: Int8Array) {
    const queueLength = this.m_queue.push([info, buffer]);
    this.m_logger.intense?.trace('Queued buffer', info.FrameTime, info.MediaTime, buffer.length, 'bytes. Queue length:', queueLength);
  }

  public dequeue(): [SegmentInfo, Int8Array] {
    const dequeuedItem = this.m_queue.shift();
    if (dequeuedItem === undefined) {
      throw new Error('Check IsEmpty first');
    }

    return dequeuedItem;
  }
}
