import { RestResponse } from './RestResponse';
import HttpStatusCode from '../Client/Enumerations/HttpStatusCode';
import { ITransactionResult, IRestTransactionResponse } from '../Client/Interface/IRestTransactionResponse';

export class TransactionResult implements ITransactionResult {
  public index = 0;
  public context: any;
  public verb = '';
  public uri = '';
  public statusCode = HttpStatusCode.INTERNAL_SERVER_ERROR;
  public result: { [k: string]: any } | null = null;
}

export class RestTransactionResponse extends RestResponse implements IRestTransactionResponse {
  public transactionResult = new Array<ITransactionResult>();

  constructor(code?: number) {
    super(code);
  }

  public parseResponse(response: RestResponse) {
    this._statusCode = response.statusCode;
    if (this.statusCode === HttpStatusCode.OK && response.body) {
      let i = 0;
      for (const result of response.body) {
        // the result comes in the same order as they were requested
        this.transactionResult[i].statusCode = result.StatusCode;
        this.transactionResult[i].result = result.Result;
        ++i;
      }
    } else {
      this._body = response.body;
    }
  }
}
