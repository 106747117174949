import { ILogger } from '../../utils/logger';
import { SegmentInfo } from '../../services/eventHub';
import { Utils } from '../../utils/Utils';
import { MediaSourceReadyState } from './MseMediaSource';

export class MseSourceBuffer {
  private static readonly SecondsToKeepInCache: number = 5;

  private readonly m_logger: ILogger;

  private readonly m_sourceBuffer: SourceBuffer;

  private readonly m_parentMediaSource: MediaSource;

  public get isUpdating(): boolean {
    return this.m_sourceBuffer.updating;
  }

  constructor(logger: ILogger, sourceBuffer: SourceBuffer, parentMediaSource: MediaSource) {
    this.m_logger = logger.subLogger('MseSourceBuffer');
    this.m_sourceBuffer = sourceBuffer;
    this.m_parentMediaSource = parentMediaSource;
  }

  public dispose() {
    this.m_logger.debug?.trace('dispose()');
    if (this.m_parentMediaSource.readyState === MediaSourceReadyState.closed) {
      this.m_logger.warn?.trace('Parent media source is already closed');
      return;
    }
    try {
      this.m_parentMediaSource.removeSourceBuffer(this.m_sourceBuffer);
    } catch (e) {
      this.m_logger.error?.trace('Error removing the source buffer from the media source:', e);
    }
  }

  public async reset(): Promise<void> {
    this.m_logger.debug?.trace('reset()');

    await this.abortAsync();

    if (this.m_sourceBuffer.buffered.length > 0) {
      this.m_sourceBuffer.remove(this.m_sourceBuffer.buffered.start(0), this.m_sourceBuffer.buffered.end(0));
      await SourceBufferExtensions.updateEndPromise(this.m_sourceBuffer);
    }
    this.m_logger.debug?.trace('reset() completed');
  }

  public decode(segmentInfo: SegmentInfo, buffer: Int8Array) {
    this.m_logger.intense?.trace('appendBuffer', segmentInfo.MediaTime, segmentInfo.FrameTime, buffer.length, 'bytes');
    const wasUpdating = this.m_sourceBuffer.updating;
    try {
      this.m_sourceBuffer.appendBuffer(buffer);
    } catch (e) {
      this.m_logger.error?.trace('Failed to append buffer to SourceBuffer. Was updating:', wasUpdating, 'Is updating:', this.m_sourceBuffer.updating, e);
    }
  }

  public addEventListener<K extends keyof SourceBufferEventMap>(type: K, listener: (this: SourceBuffer, ev: SourceBufferEventMap[K]) => any, _options?: boolean | AddEventListenerOptions): void {
    this.m_sourceBuffer.addEventListener(type, listener);
  }

  public removeEventListener<K extends keyof SourceBufferEventMap>(type: K, listener: (this: SourceBuffer, ev: SourceBufferEventMap[K]) => any, _options?: boolean | EventListenerOptions): void {
    this.m_sourceBuffer.removeEventListener(type, listener);
  }

  public async updatingEnd(): Promise<void> {
    if (!this.m_sourceBuffer.updating) {
      return Promise.resolve();
    }

    let updatingAwaitCount = 0;
    while (this.m_sourceBuffer.updating) {
      await SourceBufferExtensions.updateEndPromise(this.m_sourceBuffer);
      ++updatingAwaitCount;
    }

    if (updatingAwaitCount !== 0) {
      this.m_logger.debug?.trace('took', updatingAwaitCount, 'time get updating to false');
    }
  }

  // Note: the caller must ensure that the source buffer is not updating. I removed the check to make sure it throws if this condition is not respected.
  public emptyCache() {
    if (this.m_sourceBuffer.buffered.length > 0 && this.m_parentMediaSource.readyState === MediaSourceReadyState.open) {
      this.m_logger.intense?.trace('Cache removal requested, currently: ' + this.getCacheDurationInSec() + ' sec');
      const start = this.m_sourceBuffer.buffered.start(0);
      const end = this.m_sourceBuffer.buffered.end(this.m_sourceBuffer.buffered.length - 1);

      // Keep some video in cache otherwise it breaks stuff
      if (start < end - MseSourceBuffer.SecondsToKeepInCache) {
        this.m_sourceBuffer.remove(start, end - MseSourceBuffer.SecondsToKeepInCache);
      }
    } else {
      this.m_logger.intense?.trace('Cache removal skipped');
    }
  }

  private async abortAsync(): Promise<void> {
    if (!this.m_sourceBuffer.updating) {
      this.m_logger.debug?.trace('Aborting synchronously');
      this.m_sourceBuffer.abort();
      return;
    }

    this.m_logger.debug?.trace('Aborting asynchronously');
    const abortEventAwaiter = SourceBufferExtensions.abortEventPromise(this.m_sourceBuffer);
    this.m_sourceBuffer.abort();
    await abortEventAwaiter;
    this.m_logger.debug?.trace('Aborting completed');
  }

  private getCacheDurationInSec(): number {
    let bufferedSeconds = 0;
    for (let i = 0; i < this.m_sourceBuffer.buffered.length; i++) {
      bufferedSeconds += this.m_sourceBuffer.buffered.end(i) - this.m_sourceBuffer.buffered.start(i);
    }
    return bufferedSeconds;
  }

  public debugStatus(indent: number): string {
    return 'MseSourceBuffer' + Utils.indentNewLine(indent) +
      'IsUpdating: ' + this.isUpdating + Utils.indentNewLine(indent) +
      'Buffered Time: ' + this.getCacheDurationInSec() + ' sec';
  }
}

export class SourceBufferExtensions {
  public static abortEventPromise(sourceBuffer: SourceBuffer): Promise<void> {
    return SourceBufferExtensions.eventPromise(sourceBuffer, SourceBufferEvent.abort);
  }

  public static updateEndPromise(sourceBuffer: SourceBuffer): Promise<void> {
    return SourceBufferExtensions.eventPromise(sourceBuffer, SourceBufferEvent.updateEnd);
  }

  private static async eventPromise(sourceBuffer: SourceBuffer, eventName: string): Promise<void> {
    return new Promise<void>((resolve) => {
      sourceBuffer.addEventListener(eventName, () => resolve(), { once: true });
    });
  }
}

export enum SourceBufferEvent {
  abort = 'abort',
  error = 'error',
  update = 'update',
  updateEnd = 'updateend',
  updateStart = 'updatestart',
}
