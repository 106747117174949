// Autogenerated: v2/help/Entities/ApiArchiverRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';
import { IEncryptionCertificate, IRecordingMode } from './ICameraEntity';

// #region Fields

export class ArchiverRoleEntityFields extends RoleEntityFields {
    public static archiveBackupMaxBandwidthField = 'ArchiveBackupMaxBandwidth';
    public static archiveFileSizeEnabledField = 'ArchiveFileSizeEnabled';
    public static archivingLoadThresholdField = 'ArchivingLoadThreshold';
    public static audioRecordingField = 'AudioRecording';
    public static autodiscoverAreaField = 'AutodiscoverArea';
    public static backupRetentionPeriodField = 'BackupRetentionPeriod';
    public static defaultManualRecordingTimeField = 'DefaultManualRecordingTime';
    public static deleteOldFilesOnDiskFullField = 'DeleteOldFilesOnDiskFull';
    public static enableArchiveConsolidationField = 'EnableArchiveConsolidation';
    public static enableAutomaticCleanupField = 'EnableAutomaticCleanup';
    public static enableCloudArchivesField = 'EnableCloudArchives';
    public static enableEdgePlaybackRequestsField = 'EnableEdgePlaybackRequests';
    public static enableThumbnailsRequestsField = 'EnableThumbnailsRequests';
    public static encryptionEnabledField = 'EncryptionEnabled';
    public static encryptionTypeField = 'EncryptionType';
    public static isVideoWatermarkingField = 'IsVideoWatermarking';
    public static maximumSimultaneousDownloadField = 'MaximumSimultaneousDownload';
    public static maximumVideoLenghtField = 'MaximumVideoLenght';
    public static maximumVideoSizeField = 'MaximumVideoSize';
    public static metadataRecordingField = 'MetadataRecording';
    public static postEventRecordingTimeField = 'PostEventRecordingTime';
    public static preEventRecordingTimeField = 'PreEventRecordingTime';
    public static primaryArchiverIdField = 'PrimaryArchiverId';
    public static protectedVideoThresholdField = 'ProtectedVideoThreshold';
    public static redundantArchivingField = 'RedundantArchiving';
    public static telnetEnabledField = 'TelnetEnabled';
    public static telnetPasswordField = 'TelnetPassword';
}

// #endregion Fields

// #region Inner classes

export interface IVideoQualityPreset extends IFieldObject {
    frameRate: number;
    resolution: string;
}

export interface INTPSetting extends IFieldObject {
    pollTimeout: number | null;
    port: number | null;
    server: string;
}

export interface IExtension extends IFieldObject {
    id: IGuid;
    extensionType: IGuid;
    refuseBasicAuthentication: boolean;

    getAdvancedSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAdvanceSetting> | null>;
}

export interface IAdvanceSetting extends IFieldObject {
    name: string;
    value: string;
}

// #endregion Inner classes

export interface IArchiverRoleEntity extends IRoleEntity {
    // #region REST Properties

    archiveBackupMaxBandwidth: number;

    archiveFileSizeEnabled: boolean;

    archivingLoadThreshold: number;

    audioRecording: boolean;

    autodiscoverArea: IGuid;

    backupRetentionPeriod: number;

    defaultManualRecordingTime: number;

    deleteOldFilesOnDiskFull: boolean;

    enableArchiveConsolidation: boolean;

    enableAutomaticCleanup: boolean;

    enableCloudArchives: boolean;

    enableEdgePlaybackRequests: boolean;

    enableThumbnailsRequests: boolean;

    encryptionEnabled: boolean;

    encryptionType: string;

    isVideoWatermarking: boolean;

    maximumSimultaneousDownload: number;

    maximumVideoLenght: number;

    maximumVideoSize: number;

    metadataRecording: boolean;

    postEventRecordingTime: number;

    preEventRecordingTime: number;

    readonly primaryArchiverId: string;

    protectedVideoThreshold: number;

    redundantArchiving: boolean;

    telnetEnabled: boolean;

    telnetPassword: string;

    // #endregion REST Properties

    // #region REST Methods

    getAdvancedSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAdvanceSetting> | null>;
    getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getEncryptionCertificatesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEncryptionCertificate> | null>;
    getExtensionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IExtension> | null>;
    getNTPSettingsAsync(transaction?: ITransaction | null): Promise<INTPSetting | null>;
    getRecordingModesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRecordingMode> | null>;
    getVideoQualityPresetsAsync(transaction?: ITransaction | null): Promise<IVideoQualityPreset | null>;
    getVideoUnitsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

