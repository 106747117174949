<gen-table
    class="table-results"
    [selectionType]="SelectionType.Table.Single"
    *ngIf="isUnifiedReportGenTableFeatureFlagEnabled; else htmlTable"
    [infiniteScroll]="hasMoreResults"
    (fetchData)="fetchData()"
    [flavor]="TableFlavor.Solid"
>
    <gen-table-loading-overlay *ngIf="tableBusy$ | async"><gen-spinner></gen-spinner></gen-table-loading-overlay>
    <gen-table-empty-content *ngIf="allResults && !allResults.length">
        <div class="no-result-container">
            <gen-text class="no-result">{{ 'STE_LABEL_NORESULTS' | translate }}</gen-text>
            <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_MESSAGE_INFO_NORESULTS_DETAILS' | translate }}</gen-text>
        </div>
    </gen-table-empty-content>
    <gen-table-row>
        <gen-table-column-header column-name="datatype-icon"></gen-table-column-header>
        <gen-table-column-header
            *ngFor="let col of columns"
            [columnName]="col.dataType ? col.fieldKey + col.dataType.dataType : col.fieldKey"
            [sort]="col.isSortable ? TableColumnHeaderSort.None : null"
            [attr.hidden]="!col.displayed"
            (sortChange)="onSortChange($event, col)"
            class="prevent-select"
        >
            {{ col.name | translate }} <span *ngIf="isMultiDataType && col.dataType">({{ col.dataType.name }})</span>
        </gen-table-column-header>
    </gen-table-row>
    <gen-table-row
        *ngFor="let result of visibleResults$ | async; let rowIndex = index"
        (click)="rowClicked(result)"
        (contextmenu)="onContextMenuRequested($event)"
        (mouseenter)="onMouseEnterRow(result.datumKey)"
        (mouseleave)="onMouseLeaveRow()"
        [attr.data-datum]="result.datumKey"
        appClickActionOnSpaceEnter
    >
        <!-- icon -->
        <gen-table-data column-name="datatype-icon">
            <div>
                <div class="highlight-icon-container">
                    <!-- Do not display the color for now as it is not working with record fusion data
                         Need to check if we can support this or create a new column for the color-->
                    <!-- <div [style.backgroundColor]="result.reportColor" class="highlight-icon-container__highlight"></div> -->
                    <gen-icon *ngIf="result.gelatoIcon !== Icon.None" [size]="IconSize.Medium" [icon]="result.gelatoIcon" class="ha-center ml-1"></gen-icon>
                    <gen-image
                        *ngIf="result.gelatoIcon === Icon.None && result.icon !== null"
                        [fit]="ImageFit.Contain"
                        [src]="cachedIcons[result.icon]"
                        class="ha-center ml-1"
                    ></gen-image>
                </div>
            </div>
        </gen-table-data>
        <gen-table-data [columnName]="col.dataType ? col.fieldKey + col.dataType.dataType : col.fieldKey" *ngFor="let col of columns">
            <ng-container *ngTemplateOutlet="cellTemplate; context: { type: col.type, fieldKey: col.fieldKey, result: result, rowIndex: rowIndex }"> </ng-container>
        </gen-table-data>
        <gen-actions>
            <ng-container *ngIf="currentHoveredRowDatumKey === result.datumKey && (isLoadRowActionsDebouncing$ | async) === false">
                <gen-actions-expanded>
                    <ng-container *ngIf="(areRowActionsReady$ | async) === false; else rowActions">
                        <div class="spinner-container px-3" [@inOutAnimation]>
                            <gen-spinner></gen-spinner>
                        </div>
                    </ng-container>
                    <ng-template #rowActions>
                        <ng-container *ngFor="let commandUsage of expandedCommandUsages$ | async">
                            <gen-button [disabled]="!commandUsage.canExecute" [appTooltip]="commandUsage.name()" (click)="commandUsage.execute()" flavor="flat" [@inOutAnimation]>
                                <gen-icon [icon]="commandUsage.icon" [genColor]="commandUsage.color()"></gen-icon>
                            </gen-button>
                        </ng-container>
                    </ng-template>
                </gen-actions-expanded>
                <gen-actions-collapsed *ngIf="(areRowActionsReady$ | async) && (collapsedCommandUsages$ | async) as collapsedCommandUsages">
                    <ng-container *ngFor="let commandUsage of collapsedCommandUsages">
                        <gen-menu-item data-gen-action="" data-gen-pinned [disabled]="!commandUsage.canExecute" (click)="commandUsage.execute()">
                            <gen-icon [icon]="commandUsage.icon" [genColor]="commandUsage.color()"></gen-icon>
                            <gen-text>{{ commandUsage.name() }}</gen-text>
                        </gen-menu-item>
                    </ng-container>
                </gen-actions-collapsed>
            </ng-container>
        </gen-actions>
    </gen-table-row>
</gen-table>
<ng-container *ngIf="allResults?.length">
    <div class="rows-count-container">
        <!-- Results count -->
        <gen-text>{{ (visibleResults$ | async)?.length ?? 0 }} / {{ 'STE_LABEL_REPORT_ITEMS_COUNT_FORMAT' | translate | stringFormat: allResults?.length }}</gen-text>
    </div>
</ng-container>

<ng-template #htmlTable>
    <div *ngIf="allResults && !allResults.length" class="center no-result-container">
        <gen-text class="no-result">{{ 'STE_LABEL_NORESULTS' | translate }}</gen-text>
        <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_MESSAGE_INFO_NORESULTS_DETAILS' | translate }}</gen-text>
    </div>
    <table class="html-table-results">
        <thead>
            <tr>
                <td></td>
                <td *ngFor="let col of columns" [ngClass]="{ sortable: col.isSortable }" (click)="onSortClick(col)" [hidden]="!col.displayed">
                    <div class="col-header-wrapper">
                        {{ col.name | translate }} <span *ngIf="isMultiDataType && col.dataType" class="ml-1">({{ col.dataType.name }})</span>
                        <div *ngIf="col.isSortable" [ngSwitch]="col.sortOrder" class="sort-icon-wrapper">
                            <gen-icon *ngSwitchCase="TableColumnHeaderSort.Descending" size="small" [icon]="Icon.AngleDown"></gen-icon>
                            <gen-icon *ngSwitchCase="TableColumnHeaderSort.Ascending" size="small" [icon]="Icon.AngleUp"></gen-icon>
                        </div>
                    </div>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let result of visibleResults$ | async; let rowIndex = index"
                (click)="rowClicked(result)"
                appClickActionOnSpaceEnter
                [attr.data-datum]="result.datumKey"
                tabindex="0"
            >
                <!-- icon -->
                <td>
                    <div>
                        <div class="highlight-icon-container">
                            <!-- Do not display the color for now as it is not working with record fusion data
                         Need to check if we can support this or create a new column for the color-->
                            <!-- <div [style.backgroundColor]="result.reportColor" class="highlight-icon-container__highlight"></div> -->
                            <gen-icon *ngIf="result.gelatoIcon !== Icon.None" [size]="IconSize.Medium" [icon]="result.gelatoIcon" class="ha-center ml-1"></gen-icon>
                            <gen-image
                                *ngIf="result.gelatoIcon === Icon.None && result.icon !== null"
                                [fit]="ImageFit.Contain"
                                [src]="cachedIcons[result.icon]"
                                class="ha-center ml-1"
                            ></gen-image>
                        </div>
                    </div>
                </td>
                <td *ngFor="let col of columns" [hidden]="!col.displayed">
                    <ng-container *ngTemplateOutlet="cellTemplate; context: { type: col.type, fieldKey: col.fieldKey, result: result, rowIndex: rowIndex }"> </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>

<!-- Display properly the cell content based on the column data type -->
<ng-template #cellTemplate let-type="type" let-fieldKey="fieldKey" let-result="result" let-rowIndex="rowIndex">
    <ng-container [ngSwitch]="type">
        <!-- Image cell -->
        <ng-template [ngSwitchCase]="FieldType.Image" *ngIf="result.hasThumbnail">
            <gen-popup #popup [targetSelector]="'#thumbail-' + rowIndex">
                <gen-image [src]="result[fieldKey]"></gen-image>
            </gen-popup>
            <gen-image
                id="thumbail-{{ rowIndex }}"
                *ngIf="result[fieldKey]"
                class="va-center pointer cell-image"
                [src]="result[fieldKey]"
                [fit]="ImageFit.Contain"
                [flavor]="ImageFlavor.Rounded"
                (mouseenter)="showPopup(popup, $event)"
                (mouseleave)="popup.close()"
            ></gen-image>
        </ng-template>
        <!-- Color cell -->
        <ng-template [ngSwitchCase]="FieldType.Color">
            <gen-icon *ngIf="result[fieldKey]" [icon]="Icon.Record" [size]="IconSize.Medium" [ngStyle]="{ color: result[fieldKey] | argb }"></gen-icon>
        </ng-template>
        <!-- Link cell -->
        <ng-template [ngSwitchCase]="FieldType.Link">
            <a [href]="result[fieldKey]">{{ result[fieldKey] }}</a>
        </ng-template>
        <!-- Clipboard cell -->
        <ng-template [ngSwitchCase]="FieldType.Clipboard">
            <div class="container">
                <gen-text [flavor]="TextFlavor.Normal" class="cell-content" [appTooltip]="result[fieldKey]" appTooltipOnlyIfOverflow>
                    {{ result[fieldKey] }}
                </gen-text>
                <gen-button (click)="copyToClipboard(result[fieldKey], $event)" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_TOOLTIP_COPY_TO_CLIPBOARD' | translate }}">
                    <gen-icon [icon]="Icon.Copy" size="small"></gen-icon>
                </gen-button>
            </div>
        </ng-template>
        <!-- Timestamp cell -->
        <ng-template [ngSwitchCase]="FieldType.Timestamp">
            <gen-text
                *ngLet="result[fieldKey] | time: ['L LTS', true, true, result.TimeZone] as time"
                [flavor]="TextFlavor.Normal"
                class="cell-content"
                [appTooltip]="time"
                appTooltipOnlyIfOverflow
            >
                {{ time }}
            </gen-text>
        </ng-template>
        <!-- Fallback to a default text cell -->
        <ng-template ngSwitchDefault>
            <gen-text [flavor]="TextFlavor.Normal" class="cell-content" [appTooltip]="result[fieldKey]" appTooltipOnlyIfOverflow>
                {{ result[fieldKey] }}
            </gen-text>
        </ng-template>
    </ng-container>
</ng-template>
