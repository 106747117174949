<div class="content-parent-plugin" appClickActionOnSpaceEnter tabindex="0">
    <div *ngIf="!hideNavigationArrows" class="navigate-content navigate-content-left pointer" [hidden]="!canNavigate()">
        <span class="tooltip d-inline arrow-prev">
            <gen-icon (click)="onNavigatePlugin(true, $event)" [appTooltip]="leftNavigationTitle" [icon]="Icon.AngleLeft" [size]="IconSize.Small"></gen-icon>
        </span>
    </div>

    <app-multi-plugin-host
        [mainClass]="mainClass"
        [maxHeigth]="maxHeigth"
        [maxWidth]="maxWidth"
        [pluginHostClassName]="pluginHostClassName"
        [pluginsHostClassName]="pluginsHostClassName"
        class="content-plugin"
        [pluginItems]="displayedPlugins$ | async"
        [dataContext]="dataContext"
        (pluginClick)="onClicked($event)"
        (contextMenu)="onContextMenu($event)"
        [captureContextMenu]="captureContextMenu"
        [supportStackedPlugins]="supportStackedPlugins"
        appClickActionOnSpaceEnter
    ></app-multi-plugin-host>

    <div *ngIf="!hideNavigationArrows" class="navigate-content navigate-content-right pointer" [hidden]="!canNavigate()">
        <span class="tooltip d-inline arrow-next">
            <gen-icon (click)="onNavigatePlugin(false, $event)" [appTooltip]="rightNavigationTitle" [icon]="Icon.AngleRight" [size]="IconSize.Small"></gen-icon>
        </span>
    </div>
</div>
