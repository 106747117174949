import { Inject, Injectable } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { AccessPointEntityFields, IAccessPointEntity } from 'RestClient/Client/Interface/IAccessPointEntity';
import { modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { AccessPointEntity } from 'RestClient/Client/Model/AccessControl/AccessPointEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SafeGuid, IGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { DeviceStates } from '../../shared/enumerations/device-states';
import {
    CommandBindings,
    CommandDisplay,
    CommandsService,
    COMMANDS_SERVICE,
    EntityCommandRequirements,
    FEATURES_SERVICE,
} from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { CommandArgs, CommandDescriptor } from '../../shared/services/commands/command-provider-base';
import { EntityCommandsUsageContext } from '../../shared/services/commands/commands-usage/entity-commands-usage-context';
import { EntityCommandContext } from '../../shared/services/commands/entity-command-context';
import { EntityCommandProviderBase, ExecuteEntityCommandData } from '../../shared/services/commands/entity-command-provider-base';
import { LanguageService } from '../../shared/services/language/language.service';
import { AccessControlCommands } from '../enumerations/access-control-commands';
import { AccessControlContentTypes } from '../enumerations/access-control-content-types';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class ReaderCommandProvider extends EntityCommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(AccessControlCommands.ShuntReaderDevice, {
                id: AccessControlCommands.ShuntReaderDevice,
                nameResourceId: 'STE_BUTTON_SHUNT_READER',
                requirements: this.fillCommandRequirements({
                    requiredPrivileges: SafeGuid.createSet([KnownPrivileges.modifyAccessControlUnitsPrivilege, KnownPrivileges.maintenanceModePrivilege]),
                }),
            });
            this.internalCommandDescriptors = descriptors;
        }

        return this.internalCommandDescriptors;
    }

    public get requiredEntityFields(): Set<string> {
        return new Set<string>([...super.requiredEntityFields, AccessPointEntityFields.deviceStateField]);
    }

    public get requiredFeatures(): Set<IGuid> {
        return SafeGuid.createSet([...super.requiredFeatures, KnownFeatures.accessControlId]);
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);
        await this.prefetchPrivileges(commandContext, commandArgs);

        if (await this.isShuntCommandAvailable(commandContext, commandArgs)) {
            commandIds.push(AccessControlCommands.ShuntReaderDevice);
            this.configureShuntReaderInvalidateDisplayAsync(commandContext as EntityCommandsUsageContext).fireAndForget();
        }
        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: AccessControlCommands.ShuntReaderDevice,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeShuntReader(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isShuntCommandAvailable(entityCommandContext), commandContext),
            getCommandDisplayHandler: (commandContext) =>
                this.safeGetEntityCommandDisplayAsync((entityCommandContext) => this.getShuntDisplay(entityCommandContext), commandContext),
        });
    }

    protected fillCommandRequirements(requirements: EntityCommandRequirements): EntityCommandRequirements {
        if (!requirements.supportedContentTypes) {
            requirements.supportedContentTypes = SafeGuid.createSet([AccessControlContentTypes.Reader]);
        }

        if (!requirements.supportedEntityTypes) {
            requirements.supportedEntityTypes = new Set<string>([EntityTypes.AccessPoints]);
        }
        return super.fillCommandRequirements(requirements);
    }

    private async configureShuntReaderInvalidateDisplayAsync(commandContext: EntityCommandsUsageContext): Promise<void> {
        if (commandContext.entityCache && commandContext.invalidateDisplay) {
            const accessPointEntity = await this.extractAccessPointEntity(commandContext);
            if (accessPointEntity) {
                await commandContext.entityCache.detectFieldChangeAsync(
                    accessPointEntity,
                    () => {
                        const _ = accessPointEntity.deviceState;
                    },
                    (() => {
                        commandContext.invalidateDisplay(AccessControlCommands.ShuntReaderDevice);
                        return new Deferred<void>(true).promise;
                    }) as modificationHandlerField<boolean>
                );
            }
        }
    }

    private async executeShuntReader(executeCommandData: ExecuteEntityCommandData): Promise<void> {
        const accessPoint = await this.extractAccessPointEntity(executeCommandData.commandContext);
        if (accessPoint) {
            const currentShuntState = accessPoint.deviceState === DeviceStates.Disabled;
            const entity = cloneDeep(accessPoint);
            entity.deviceState = currentShuntState ? DeviceStates.Enabled : DeviceStates.Disabled;
            await this.scClient.updateAsync(entity);
        }
        executeCommandData.isHandled = true;
    }

    private async extractAccessPointEntity(commandContext: EntityCommandContext): Promise<IAccessPointEntity | null> {
        return await this.extractEntityAsync<AccessPointEntity, IAccessPointEntity>(AccessPointEntity, commandContext);
    }

    private async getShuntDisplay(commandContext: EntityCommandContext): Promise<CommandDisplay | undefined> {
        const accessPointEntity = await this.extractAccessPointEntity(commandContext);
        if (accessPointEntity) {
            if (accessPointEntity.deviceState === DeviceStates.Disabled) {
                return {
                    name: () => this.translateService.instant('STE_ACTION_ACTIVATEREADER') as string,
                    icon: MeltedIcon.None,
                };
            } else if (accessPointEntity.deviceState === DeviceStates.Enabled) {
                return {
                    name: () => this.translateService.instant('STE_ACTION_SHUNTREADER') as string,
                    icon: MeltedIcon.None,
                };
            }
        }
    }

    private async isShuntCommandAvailable(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(AccessControlCommands.ShuntReaderDevice, commandContext, commandArgs);
    }

    //#endregion
}
