import { isNullOrUndefined } from '@genetec/web-maps';
import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { IGuid } from 'safeguid';
import { catchError, switchMap } from 'rxjs/operators';
import { Icon } from '@genetec/gelato';
import { IconsService } from '@shared/services/icons.service';
import { IncidentLocationService } from '../services/incident-location/incident-location.service';
import { toGuid } from '../utils/guid-utils';

@Pipe({ name: 'locationIconPipe' })
export class LocationIconPipe implements PipeTransform {
    constructor(private iconsService: IconsService, private incidentLocationService: IncidentLocationService) {}

    public transform(id: IGuid | string | null | undefined): Observable<Icon> {
        if (isNullOrUndefined(id)) return EMPTY;
        return this.incidentLocationService.getLocation(toGuid(id)).pipe(
            switchMap((entity) => (entity ? of(this.iconsService.getEntityIcon(entity)) : EMPTY)),
            catchError((_) => EMPTY)
        );
    }
}
