import { Injectable } from '@angular/core';
import { DateFormat } from '@genetec/gelato-angular';
import { IEventEntry } from '@modules/shared/api/api';
import { TimeService } from './time.service';

@Injectable({ providedIn: 'root' })
export class EventTimeService {
    constructor(private timeService: TimeService) {}

    public formatTime(eventEntry: IEventEntry): string {
        return this.timeService.formatTime(eventEntry.timestamp, DateFormat.Time, true, true, eventEntry.timezone);
    }

    public formatDate(eventEntry: IEventEntry): string {
        return this.timeService.formatTime(eventEntry.timestamp, DateFormat.Date, true, false, eventEntry.timezone);
    }
}
