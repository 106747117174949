import { PlayerMode } from '../../enums';
import { PlaySpeed } from '../PlaySpeed';
import { DesiredState } from './DesiredState';
import { StreamUsage } from './Api/CreateStream';
import { TimeLineRange } from '../../TimeLineRange';
import { Utils } from '../../utils/Utils';

export interface ISessionStateView {
  isPaused: boolean;
  isLivePaused: boolean;
  playSpeed: PlaySpeed;
  isAudioEnabled: boolean;
  isPtzMode: boolean;
  playerMode: PlayerMode | undefined;
  timelineRange: TimeLineRange | undefined;
  streamUsage: StreamUsage;

  getRestorePlaybackSeekTime(): Date;
}

export enum Pause {
  NotPaused,
  Paused,
  LivePaused
}

export class SessionState implements ISessionStateView {
  private m_playerMode: PlayerMode | undefined;

  private m_paused: Pause;

  private m_playSpeed: PlaySpeed;

  private m_audioEnabled: boolean;

  private m_timelineRange: TimeLineRange | undefined;

  private m_isPtzMode: boolean;

  private m_lastRenderedFrameTime: Date | undefined;

  private m_lastSeekTime: Date | undefined;

  // The time at which the request to play live was sent
  private m_timeOfPlayLive: Date | undefined;

  private m_streamUsage: StreamUsage;

  public get playerMode(): PlayerMode | undefined {
    return this.m_playerMode;
  }

  public get isPaused(): boolean {
    return this.m_paused !== Pause.NotPaused;
  }

  public get playSpeed(): PlaySpeed {
    return this.m_playSpeed;
  }

  public get isAudioEnabled(): boolean {
    return this.m_audioEnabled;
  }

  public get timelineRange(): TimeLineRange | undefined {
    return this.m_timelineRange;
  }

  public get isLivePaused(): boolean {
    return this.m_paused === Pause.LivePaused;
  }

  public get isPtzMode(): boolean {
    return this.m_isPtzMode;
  }

  public get streamUsage(): StreamUsage {
    return this.m_streamUsage;
  }

  public constructor(defaultStreamUsage: StreamUsage) {
    if (defaultStreamUsage === StreamUsage.Automatic) {
      throw new Error('Automatic is not a valid stream usage for the ServerState');
    }

    if (!Object.values(StreamUsage).includes(defaultStreamUsage)) {
      throw new Error(`Unknown stream usage ${defaultStreamUsage}`);
    }

    this.m_playerMode = undefined;
    this.m_paused = Pause.NotPaused;
    this.m_playSpeed = PlaySpeed._1x;
    this.m_audioEnabled = false;
    this.m_lastRenderedFrameTime = undefined;
    this.m_lastSeekTime = undefined;
    this.m_isPtzMode = false;
    this.m_streamUsage = defaultStreamUsage;
  }

  // When doing resume after playlive/pause, we go in playback at the last rendered frame.
  // if no frame could be rendered, we use the time at which play was done.
  public getResumeLiveSeekTime(): Date {
    if (this.m_lastRenderedFrameTime !== undefined) {
      return this.m_lastRenderedFrameTime;
    }

    if (this.m_timeOfPlayLive !== undefined) {
      return this.m_timeOfPlayLive;
    }

    throw new Error('Invalid operation. Playing live state was never reached.');
  }

  // When restoring a connection after connection lost. If we were in playback, we will restore the
  // session to a playback state seeking to the last rendered frame. If no frame had the time to be displayed, we will use the seek time.
  public getRestorePlaybackSeekTime(): Date {
    if (this.m_lastRenderedFrameTime !== undefined) {
      return this.m_lastRenderedFrameTime;
    }

    if (this.m_lastSeekTime !== undefined) {
      return this.m_lastSeekTime;
    }

    // Live -> pause, no frame rendered, lose connection
    //  PlayerMode will be playback and lastSeekTime will be undefined. The time to seek is timeOfPlayLive in that case.
    if (this.m_timeOfPlayLive !== undefined) {
      return this.m_timeOfPlayLive;
    }

    throw new Error('Invalid operation. Trying to restore the player state when nothing was done.');
  }

  // Live -> reverse speed
  public getLiveToReverseSeekTime(): Date {
    // Usual case, we were playing live and switch to negative speed. Start at the last rendered frame
    if (this.m_lastRenderedFrameTime !== undefined) {
      return this.m_lastRenderedFrameTime;
    }
    // Live was requested but we no frame was rendered and now we switch to reverse speed.
    // Could be either because those command were queued rapidly or because server is slow to send frame
    // Start at the time we requested live.
    if (this.m_timeOfPlayLive !== undefined) {
      return this.m_timeOfPlayLive;
    }

    // Live was requested but we did not had the time to send it to server (maybe another query is in flight) and reverse speed was requested.
    return new Date();
  }

  public updateRenderedFrameTime(frameTime: Date) {
    this.m_lastRenderedFrameTime = frameTime;
  }

  public update(playerMode: PlayerMode | undefined, playSpeed: PlaySpeed | undefined, pause: boolean | undefined, seekTime: Date | undefined, audioEnabled: boolean | undefined, timelineRange: TimeLineRange | undefined, isPtzMode: boolean | undefined, streamUsage: StreamUsage | undefined) {
    // Very specific: live-paused handling
    if (this.m_playerMode === PlayerMode.live && playerMode === PlayerMode.playback && pause === true && seekTime === undefined) {
      this.m_paused = Pause.LivePaused;
      seekTime = this.getResumeLiveSeekTime();
    } else if (pause !== undefined) { // User changed pause value specifically, we are going into either pause or not paused. It cancels Live-Paused for sure.
      this.m_paused = pause ? Pause.Paused : Pause.NotPaused;
    } else if (seekTime !== undefined && this.m_paused === Pause.LivePaused) { // Seeking while Live-paussed cancels the LivePaused effects.
      this.m_paused = Pause.Paused;
    }

    switch (playerMode) {
      case PlayerMode.live:
        if (seekTime !== undefined) {
          throw new Error('Live cannot specify a seekTime');
        }
        this.m_playerMode = playerMode;
        this.m_timeOfPlayLive = new Date();
        this.m_lastSeekTime = undefined;
        this.m_lastRenderedFrameTime = undefined;
        break;

      case PlayerMode.playback:
        if (seekTime === undefined) {
          throw new Error('Playback requires a seekTime');
        }
        this.m_playerMode = playerMode;
        this.m_timeOfPlayLive = undefined;
        this.m_lastSeekTime = seekTime;
        this.m_lastRenderedFrameTime = undefined;
        break;

      case undefined:
        if (seekTime !== undefined) {
          if (this.m_playerMode === PlayerMode.live) {
            throw new Error('Specifying a seekTime requires to be in playback');
          }
          if (this.m_playerMode === undefined) {
            throw new Error('Cannot update seekTime when player mode is undefined');
          }
          //Updating seek time while in playback. It is a regular seek
          this.m_lastSeekTime = seekTime;
          this.m_lastRenderedFrameTime = undefined;
        }
        break;
    }

    if (playSpeed !== undefined) {
      this.m_playSpeed = playSpeed;
    }

    if (audioEnabled !== undefined) {
      this.m_audioEnabled = audioEnabled;
    }

    if (timelineRange !== undefined) {
      this.m_timelineRange = timelineRange;
    }

    if (isPtzMode !== undefined) {
      this.m_isPtzMode = isPtzMode;
    }

    if (streamUsage !== undefined) {
      this.m_streamUsage = streamUsage;
    }
  }

  public validateNewState(desiredState: DesiredState) {
    /** PtzMode cannot be request while in playback mode
    * CurrentState playerMode is playback & DesiredState playerMode is not live, ptz mode cannot be requested
    * DesiredState playerMode is playback, ptz mode cannot be requested
    **/
    if (desiredState.isPtzMode === true &&
      ((this.m_playerMode === PlayerMode.playback && desiredState.playerMode !== PlayerMode.live) || (desiredState.playerMode === PlayerMode.playback))) {
      throw new Error('Invalid command. Ptz mode cannot be activated because a request has already been made to pause or set to playback the web player.');
    }

    /** PlaySpeed cannot be different than 1 in live mode
  * CurrentState playerMode is live and DesiredState playermode is undefined, play speed cannot be different than 1
  * DesiredState playerMode is live, play speed cannot be different than 1
  **/
    if (desiredState.playSpeed !== undefined && !desiredState.playSpeed.equals(PlaySpeed._1x) &&
      (desiredState.playerMode === PlayerMode.live || (desiredState.playerMode === undefined && this.playerMode === PlayerMode.live))) {
      throw new Error('Invalid command. PlaySpeed must be of 1x in live mode');
    }
  }

  public debugStatus(indent: number): string {
    return 'SessionState: ' + Utils.indentNewLine(indent) +
      'Mode: ' + this.m_playerMode + Utils.indentNewLine(indent) +
      'Paused: ' + Pause[this.m_paused] + Utils.indentNewLine(indent) +
      'Speed: ' + this.m_playSpeed + Utils.indentNewLine(indent) +
      'Last seek: ' + Utils.formatDate(this.m_lastSeekTime) + Utils.indentNewLine(indent) +
      'Audio: ' + this.m_audioEnabled + Utils.indentNewLine(indent) +
      'Ptz mode: ' + this.m_isPtzMode + Utils.indentNewLine(indent) +
      'Last rendered frame time: ' + Utils.formatDate(this.m_lastRenderedFrameTime) + Utils.indentNewLine(indent) +
      'Time of Play live: ' + Utils.formatDate(this.m_timeOfPlayLive) + Utils.indentNewLine(indent) +
      'Stream usage: ' + this.m_streamUsage + Utils.indentNewLine(indent) +
      'TimeLine range: ' + this.m_timelineRange?.toString() + `(${this.m_timelineRange?.Span.toString()})`;
  }
}
