import { FilterSet } from './Linq';

export class ObservableCollection<T> extends FilterSet<T> {
  private onAdded?: (element: T) => void;
  private onRemoved?: (element: T) => void;
  private onCleared?: () => void;

  public suspend = false;

  constructor(onAdded?: (element: T) => void, onRemoved?: (element: T) => void, onCleared?: () => void) {
    super();
    this.onAdded = onAdded;
    this.onRemoved = onRemoved;
    this.onCleared = onCleared;
  }

  public static From<T>(array: T[]): ObservableCollection<T> {
    const result = new ObservableCollection<T>();
    for (const item of array) {
      result.add(item);
    }
    return result;
  }

  public add(value: T): this {
    const result = super.add(value);
    if ( (this.suspend === false) && (this.onAdded !== undefined) ) {
      this.onAdded(value);
    }
    return result;
  }

  public clear(): void {
    super.clear();
    if ( (this.suspend === false) && (this.onCleared !== undefined) ) {
      this.onCleared();
    }
    return;
  }

  public delete(value: T): boolean {
    const result = super.delete(value);
    if ( (this.suspend === false) && (this.onRemoved !== undefined) ) {
      this.onRemoved(value);
    }
    return result;
  }
}
