import { Component, OnInit } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { IGuid, SafeGuid } from 'safeguid';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TimeService } from '@modules/shared/services/time/time.service';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';
import { CardholderStateWidgetBaseComponent } from '../cardholder-state-widget-base.component';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
const isContentSupported = (content: Content): boolean => {
    if (content && !content.contextContent) {
        return content.type.equals(AccessControlContentTypes.Cardholder);
    }
    return false;
};

@InternalContentPluginDescriptor({
    type: CardholderActionWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CardholderActionWidgetComponent.pluginId, priority: 0 },
    isContentSupported,
})
@Component({
    selector: 'app-cardholder-action-widget',
    templateUrl: './cardholder-action-widget.component.html',
    styleUrls: ['./cardholder-action-widget.component.scss'],
})
export class CardholderActionWidgetComponent extends CardholderStateWidgetBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('874BC2AD-0A11-4393-A238-0A650A10027F');

    public readonly TextFlavor = TextFlavor;

    constructor(securityCenterClientService: SecurityCenterClientService, translateService: TranslateService, timeService: TimeService, trackingService: TrackingService) {
        super(securityCenterClientService, translateService, timeService, trackingService);
    }

    async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = false;
    }
}
