import { Pipe, PipeTransform } from '@angular/core';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { TranslateService } from '@ngx-translate/core';
import { getAccessStatusResourceString } from '@modules/shared/utilities/accessStatusTranslate';

@Pipe({ name: 'accessStatusToTranslatedString' })
export class AccessStatusToTranslatedStringPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    public transform(accessStatus: AccessStatus): string {
        return this.translateService.instant(getAccessStatusResourceString(accessStatus)) as string;
    }
}
