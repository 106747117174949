import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogonState } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { WebAppClient } from 'WebClient/WebAppClient';
import { UpdaterService } from '@modules/shared/services/updates/updater.service';

/**
 * Loading screen component is tied to the WebAppClient connection state, if it's "reconnecting", it will
 * display a loading overlay, and the property "loadingText".
 */
@Component({
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingScreenComponent {
    public showProgressEllipsis = true;
    public showSpinner: Observable<boolean> = new BehaviorSubject(true);
    public progressEllipsis?: Observable<string>;
    public isReconnecting = false;
    public message?: Observable<string>;
    private securityCenterClient: WebAppClient;

    constructor(
        private scProvider: SecurityCenterClientService,
        private changeDetectorRef: ChangeDetectorRef,
        private updaterService: UpdaterService,
        private translateService: TranslateService
    ) {
        this.refreshMessage();

        this.securityCenterClient = this.scProvider?.scClient;
        this.securityCenterClient?.onLogonStateChanged((stateChanged) => {
            this.refreshMessage();

            if (stateChanged.state === LogonState.ReloggingOn) {
                this.isReconnecting = true;
                this.changeDetectorRef.detectChanges();
            } else {
                this.isReconnecting = false;
                this.changeDetectorRef.detectChanges();
            }
        });
        if (this.showProgressEllipsis) {
            const array = ['', ' ⠄', ' ⠤', ' ⠴', ' ⠶', ' ⠲', ' ⠒', ' ⠂'];

            this.progressEllipsis = timer(0, 1000).pipe(map((e) => array[e % array.length]));
        }
    }

    private refreshMessage() {
        if (this.updaterService.isUpgrading || this.updaterService.isTimedOut) {
            this.message = this.updaterService.message$;
            this.showSpinner = this.updaterService.isTimedOut$.pipe(map((x) => !x));
        } else {
            const key = 'STE_LABEL_CONNECTION_LOST_RECONNECTING';
            let message = this.translateService.instant(key) as string;
            if (message === key) {
                message = 'Connection lost, reconnecting';
            }
            this.message = new BehaviorSubject(message);
        }
    }
}
