// Autogenerated: v2/help/Entities/ApiTransferGroup?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:09 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class TransferGroupEntityFields extends EntityFields {
    public static destinationField = 'Destination';
    public static minutesToIgnoreField = 'MinutesToIgnore';
    public static simultaneousDownloadField = 'SimultaneousDownload';
    public static transferGroupTypeField = 'TransferGroupType';
}

// #endregion Fields

export interface ITransferGroupEntity extends IEntity {
    // #region REST Properties

    readonly destination: IGuid;

    readonly minutesToIgnore: number;

    readonly simultaneousDownload: number;

    readonly transferGroupType: string;

    // #endregion REST Properties

    // #region REST Methods

    getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getCamerasAndArchiversAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getSourceAreasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getSourcesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

