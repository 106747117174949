import { Pipe, PipeTransform } from '@angular/core';

/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
 */
@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
    public transform(value: unknown[], order = 'asc', ...column: string[]): any[] {
        if (!value || value.length <= 1 || !order || (order !== 'asc' && order !== 'desc')) {
            return value;
        }

        let comparer = this.defaultCompare.bind(this);
        if (column && column.length > 0 && value instanceof Object) {
            comparer = (a: Record<string, any>, b: Record<string, any>) => {
                // for now we only support a single column
                const col = column[0];
                return this.defaultCompare(a[col], b[col]);
            };
        }

        let sorted = value.sort(comparer);
        if (order !== 'asc') {
            sorted = sorted.reverse();
        }

        return sorted;
    }

    private defaultCompare(a: any, b: any): number {
        return (typeof a === 'string' && typeof b === 'string' && a.localeCompare(b)) || 0;
    }
}
