import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LoggerService } from '../services/logger/logger.service';
import { stringFormat } from '../utilities/StringFormat';

// maxLengthValidator
// https://github.com/angular/angular/blob/970a3b5c70fee29aa40945836ebeb464d75438e4/packages/forms/src/validators.ts#L519
interface MaxLengthValidatorError {
    requiredLength: number;
    actualLength: number;
}

@UntilDestroy()
@Directive({
    selector: 'gen-message[appFormControlError]',
})
export class FormControlErrorDirective implements OnInit {
    private static readonly defaultErrorMax = 1;

    @Input() appFormControlError?: AbstractControl;
    /**
     * Max number of errors to show (new lines between each ones).
     * Default: 1
     */
    @Input() appFormControlErrorMax?: number;
    /**
     * Overrides default translated string for specific errors.
     */
    @Input() appFormControlErrorOverrides?: Record<string, string>;

    private element?: HTMLElement;

    constructor(private elementRef: ElementRef, private loggerService: LoggerService, private translateService: TranslateService) {}

    ngOnInit(): void {
        if (!this.appFormControlError) {
            this.loggerService.traceWarning('No form control passed to appFormControlError.');
        }
        if (!('innerHTML' in this.elementRef.nativeElement)) {
            this.loggerService.traceWarning('appFormControlError affected to an invalid element.');
        } else {
            this.element = this.elementRef.nativeElement as HTMLElement;
        }
        this.appFormControlError?.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
            if (!this.element || !this.appFormControlError) {
                return;
            }

            if (status !== 'INVALID') {
                this.element.textContent = '';
            } else if (this.appFormControlError.dirty || this.appFormControlError.touched) {
                this.element.textContent = this.errorToTranslatedString(this.appFormControlError.errors);
            }
        });
    }

    private errorToTranslatedString(errors: ValidationErrors | null): string {
        if (errors == null) {
            return '';
        }

        const errorMessages: string[] = [];
        if (typeof errors !== 'object') {
            throw new Error('Unexpected value for ValidatorsErrorTranslatePipe');
        }

        for (const [errorKey, value] of Object.entries(errors).slice(0, this.appFormControlErrorMax ?? FormControlErrorDirective.defaultErrorMax)) {
            switch (errorKey) {
                case 'required':
                    errorMessages.push(this.translateService.instant(this.appFormControlErrorOverrides?.required ?? 'STE_MESSAGE_ERROR_FIELDISREQUIRED') as string);
                    break;
                case 'email':
                    errorMessages.push(this.translateService.instant(this.appFormControlErrorOverrides?.email ?? 'STE_MESSAGE_ERROR_CARDHOLDER_INVALIDEMAIL') as string);
                    break;
                case 'maxlength':
                    errorMessages.push(
                        stringFormat(
                            this.translateService.instant(this.appFormControlErrorOverrides?.email ?? 'STE_MESSAGE_ERROR_CANNOTEXCEEDXCHARACTERS') as string,
                            (value as MaxLengthValidatorError).requiredLength.toString()
                        )
                    );
                    break;
                default:
                    if (this.appFormControlErrorOverrides?.errorKey) {
                        errorMessages.push(this.translateService.instant(this.appFormControlErrorOverrides?.errorKey) as string);
                    }
                    break;
            }
        }
        return errorMessages.join('<br>');
    }
}
