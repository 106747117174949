export class QueryHelper {
  public buildElemList(query: string, fieldName: string, list: Set<any>): string {
    if (list.size > 0) {
      if (query.endsWith('?') === false) {
        query = query + '&' + fieldName + '=';
      } else {
        query = query + fieldName + '=';
      }

      let index = 0;

      for (const elem of list) {
        if (index > 0) {
          query = query + ',' + elem;
        } else {
          query = query + elem;
        }
        index++;
      }
    }
    return query;
  }

  public BuildElemDictionary(query: string, fieldName: string, map: Map<any, any>): string {
    if (map.size > 0) {
      if (query.endsWith('?') === false) {
        query = query + '&' + fieldName + '=';
      } else {
        query = query + fieldName + '=';
      }

      const obj: any = {};
      for (let entry of map) {
        if ( typeof entry[1] === "object" ) {
          // right now consider it is an array
          obj[entry[0]] = Array.from(entry[1]);
        } else {
          obj[entry[0]] = entry[1];
        }
      };

      const json = JSON.stringify(obj);
      query = query + json;
    }
    return query;
  }

  public buildElem(query: string, fieldName: string, value: any) {
    if (query.length > 0 && value !== undefined) {
      var aDate = value as Date;
      var strValue = "";
      if ( ( aDate ) && ( aDate.toISOString !== undefined ) ){
        strValue = aDate.toISOString();
        strValue = strValue.replace("Z", "0000Z"); // missing precision in js vs c#... $^^$%?#??!?!?
      } else {
        strValue = value.toString();
      }
      if (query.endsWith('?') === false) {
        query = query + '&' + fieldName + '=' + strValue;
      } else {
        query = query + fieldName + '=' + strValue;
      }
    }
    return query;
  }

  public buildFieldObjectList(query: string, fieldName: string, list: Set<any>) {
    if (list.size > 0) {
      if (query.endsWith('?') === false) {
        query = query + '&' + fieldName + '=';
      } else {
        query = query + fieldName + '=';
      }

      const array: string[] = [];
      for (const elem of list) {
        array.push(elem.toString());
      }

      query += JSON.stringify(array);
    }
    return query;
  }
}
