import { CommonModule } from '@angular/common';
import { Inject, NgModule, NgZone } from '@angular/core';
import { GenAngularModule, MeltedIcon } from '@genetec/gelato-angular';
import { GeneralModule } from '@modules/general/general.module';
import { ModuleDescriptor } from '@modules/shared/interfaces/modules/public/module-public.interface';
import { TranslateService } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { SharedCommands } from '@shared/enumerations/shared-commands';
import { CommandsService, COMMANDS_SERVICE } from '@shared/interfaces/plugins/public/plugin-services-public.interface';
import { LanguageService } from '@shared/services/language/language.service';
import { SharedModule } from '@shared/shared.module';
import { WINDOW } from '@utilities/common-helper';
import { GetBaseUriForCodeGen } from '@utilities/base-uri-getter';
import gwp from 'Marmot/Marmot/gwp';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ReactiveFormsModule } from '@angular/forms';
import { baseUrl, VideoClient } from './api/api';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { CameraBaseComponent } from './components/camera/camera-base.component';
import { CameraEntityStateComponent } from './components/camera/tile/camera-entity-state/camera-entity-state.component';
import { CameraActionsWidgetComponent } from './components/camera/widgets/camera-actions-widget/camera-actions-widget.component';
import { CameraEventsWidgetComponent } from './components/camera/widgets/camera-events-widget/camera-events-widget.component';
import { ExportVideoAuthorizationComponent } from './components/export-video-authorization/export-video-authorization.component';
import { ExportVideoComponent } from './components/export-video/export-video.component';
import { GotoTimeComponent } from './components/goto-time/goto-time.component';
import { CameraEventWidgetComponent } from './components/maps/camera-event-widget/camera-event-widget.component';
import { LcdPanelComponent } from './components/ptz-controls/lcd-panel/lcd-panel.component';
import { PtzControlsPluginComponent } from './components/ptz-controls/ptz-controls-plugin/ptz-controls-plugin.component';
import { PtzControlsComponent } from './components/ptz-controls/ptz-controls.component';
import { VideoControlsComponent } from './components/video-controls/video-controls.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { CameraCommandProvider } from './services/camera-command-provider';
import { RenderingHelper } from './utilities/renderinghelper';
import { VideoSpeedPipe } from './pipes/video-speed.pipe';
import { ExpandWidgetComponent } from './components/expand-widget/expand-widget.component';

@UntilDestroy()
@NgModule({
    imports: [CommonModule, SharedModule, NgxsModule, GenAngularModule, GeneralModule, ReactiveFormsModule],
    declarations: [
        VideoControlsComponent,
        VideoPlayerComponent,
        BookmarkComponent,
        GotoTimeComponent,
        ExportVideoComponent,
        CameraActionsWidgetComponent,
        CameraEventsWidgetComponent,
        CameraEventWidgetComponent,
        PtzControlsComponent,
        PtzControlsPluginComponent,
        LcdPanelComponent,
        ExportVideoAuthorizationComponent,
        CameraEntityStateComponent,
        CameraBaseComponent,
        VideoSpeedPipe,
        ExpandWidgetComponent,
    ],
    exports: [VideoPlayerComponent],
    providers: [
        VideoClient,
        {
            provide: baseUrl,
            deps: [],
            useFactory: GetBaseUriForCodeGen,
        },
    ],
})
@ModuleDescriptor({
    type: VideoModule,
    inclusions: {
        globalPrivileges: [KnownPrivileges.singleUserExportPrivilege],
    },
})
export class VideoModule {
    public static references = [];

    constructor(
        private translateService: TranslateService,
        languageService: LanguageService,
        cameraCommandProvider: CameraCommandProvider, // DO NOT DELETE,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        zone: NgZone,
        @Inject(WINDOW) private window: Window
    ) {
        languageService.ready.pipe(untilDestroyed(this)).subscribe(() => {
            this.registerCommands();
        });
        RenderingHelper.initialize(zone);

        // ensure GWP is created
        if (!window.gwp) {
            window.gwp = new gwp();
        }
    }

    private registerCommands() {
        // allow share command for some of our entity types
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.commandsService.appendRequirements(SharedCommands.ShareEntity, { supportedEntityTypes: new Set<string>([EntityTypes.Cameras, EntityTypes.Sequences]) });

        this.commandsService.registerCommands([
            {
                id: SharedCommands.FastForward,
                name: () => this.translateService?.instant('STE_BUTTON_FASTFORWARD') as string,
                icon: MeltedIcon.FastForward,
                groupName: () => this.translateService?.instant('STE_ENTITY_CAMERA') as string,
                groupIcon: MeltedIcon.Camera,
            },
            {
                id: SharedCommands.PauseVideo,
                name: () => this.translateService?.instant('STE_BUTTON_PAUSEVIDEO') as string,
                icon: MeltedIcon.Pause,
                groupName: () => this.translateService?.instant('STE_ENTITY_CAMERA') as string,
                groupIcon: MeltedIcon.Pause,
            },
            {
                id: SharedCommands.PlayVideo,
                name: () => this.translateService?.instant('STE_BUTTON_RESUMEVIDEO') as string,
                icon: MeltedIcon.Play,
                groupName: () => this.translateService?.instant('STE_ENTITY_CAMERA') as string,
                groupIcon: MeltedIcon.Play,
            },
            {
                id: SharedCommands.Rewind,
                name: () => this.translateService?.instant('STE_BUTTON_REWINDVIDEO') as string,
                icon: MeltedIcon.Rewind,
                groupName: () => this.translateService?.instant('STE_ENTITY_CAMERA') as string,
                groupIcon: MeltedIcon.Camera,
            },
            {
                id: SharedCommands.SaveSnapshot,
                name: () => this.translateService?.instant('STE_BUTTON_SAVESNAPSHOT') as string,
                icon: MeltedIcon.Snapshot,
                groupName: () => this.translateService?.instant('STE_ENTITY_CAMERA') as string,
                groupIcon: MeltedIcon.Camera,
            },
            {
                id: SharedCommands.SlowMotion,
                name: () => this.translateService?.instant('STE_BUTTON_SLOWMOTION') as string,
                icon: MeltedIcon.Slow,
                groupName: () => this.translateService?.instant('STE_ENTITY_CAMERA') as string,
                groupIcon: MeltedIcon.Camera,
                keyBinding: 'Shift.-',
            },
            {
                id: SharedCommands.ToggleListen,
                name: () => this.translateService?.instant('STE_BUTTON_TOGGLE_LISTENING') as string,
                icon: MeltedIcon.VolumeLoud,
                groupName: () => this.translateService?.instant('STE_ENTITY_CAMERA') as string,
                groupIcon: MeltedIcon.Camera,
            },
        ]);
    }
}
