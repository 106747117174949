import { Resolution } from './Resolution';
import { TopLeftCoords } from './TopLeftCoords';

export class Rectangle {
  private readonly m_topLeftCoords: TopLeftCoords;

  private readonly m_dimensions: Resolution;

  get TopLeftCoords(): TopLeftCoords {
    return this.m_topLeftCoords;
  }

  get Dimensions(): Resolution {
    return this.m_dimensions;
  }
  get X(): number {
    return this.m_topLeftCoords.X;
  }

  get Y(): number {
    return this.m_topLeftCoords.Y;
  }

  get Width(): number {
    return this.m_dimensions.Width;
  }

  get Height(): number {
    return this.m_dimensions.Height;
  }

  constructor(topLeftCoords: TopLeftCoords, dimensions: Resolution) {
    if (dimensions === Resolution.None) {
      throw new Error('Resolution cannot be 0x0');
    }
    this.m_topLeftCoords = topLeftCoords;
    this.m_dimensions = dimensions;
  }

  public clip(other: Rectangle): Rectangle {
    if (this.isNotOverlapping(other)) {
      throw new Error('Rectangle does not overlap destination rectangle.');
    }
    const right = this.X + this.Width;
    const bottom = this.Y + this.Height;
    const otherRight = other.X + other.Width;
    const otherBottom = other.Y + other.Height;

    const intersectLeft = Math.max(this.X, other.X);
    const intersectRight = Math.min(right, otherRight);
    const intersectTop = Math.max(this.Y, other.Y);
    const intersectBottom = Math.min(bottom, otherBottom);

    const newX = intersectLeft;
    const newWidth = intersectRight - intersectLeft;
    const newY = intersectTop;
    const newHeight = intersectBottom - intersectTop;

    return new Rectangle(new TopLeftCoords(newX, newY), Resolution.build(newWidth, newHeight));
  }

  public equals(other: Rectangle) {
    return this.m_dimensions.equals(other.m_dimensions) && this.m_topLeftCoords.equals(other.TopLeftCoords);
  }

  private isNotOverlapping(other: Rectangle): boolean {
    return (this.X >= other.X + other.Width ||
      this.Y >= other.Y + other.Height ||
      this.X + this.Width <= other.X ||
      this.Y + this.Height <= other.Y);
  }
}
