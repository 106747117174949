// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class StreamDataView {
  private position = 0;
  private view: DataView;

  public constructor(arrayBuffer: ArrayBuffer, private useLittleEndian?: boolean) {
    this.view = new DataView(arrayBuffer);

    if (this.useLittleEndian === undefined) {
      this.useLittleEndian = true;
    }
  }

  public getUByte(): number {
    const data = this.view.getUint8(this.position++);
    return data;
  }

  public getByte(): number {
    const data = this.view.getInt8(this.position++);
    return data;
  }

  public getUInt16(): number {
    const data = this.view.getUint16(this.position, this.useLittleEndian);
    this.position += 2;
    return data;
  }

  public getInt16(): number {
    const data = this.view.getInt16(this.position, this.useLittleEndian);
    this.position += 2;
    return data;
  }

  public getUInt32(): number {
    const data = this.view.getUint32(this.position, this.useLittleEndian);
    this.position += 4;
    return data;
  }

  public getInt32(): number {
    const data = this.view.getInt32(this.position, this.useLittleEndian);
    this.position += 4;
    return data;
  }

  public getFloat32(): number {
    const data = this.view.getFloat32(this.position, this.useLittleEndian);
    this.position += 4;
    return data;
  }

  public getFloat64(): number {
    const data = this.view.getFloat64(this.position, this.useLittleEndian);
    this.position += 8;
    return data;
  }

  public getString(): string {
    const data = this.getStringData(this.view, this.position);
    this.position += 4 + data.length * 2; // unicode UTF-16 always
    return data;
  }

  public getGuidString(): string {
    const data = this.getGuidStringData(this.view, this.position);
    this.position += 4 + data.length + 1;
    return data;
  }

  private getStringData(dataView: DataView, startOffset: number): string {
    const length = dataView.getInt32(startOffset, this.useLittleEndian);
    startOffset += 4;
    const chars: number[] = [];
    for (let i = 0; i < length; i++) {
      chars.push(dataView.getInt16(startOffset, this.useLittleEndian));
      startOffset += 2;
    }
    return String.fromCharCode.apply(null, chars);
  }

  private getGuidStringData(dataView: DataView, startOffset: number): string {
    // leave as optimized for guids since they are not unicode dependant...
    const length = dataView.getInt32(startOffset, this.useLittleEndian);
    startOffset += 4;
    startOffset++;

    let str = '';
    for (let i = 0; i < length; i++) {
      str += '%' + this.pad(dataView.getUint8(startOffset).toString(16));
      startOffset++;
    }
    return decodeURIComponent(str);
  }

  private pad(n: string) {
    return n.length < 2 ? '0' + n : n;
  }
}
