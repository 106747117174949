// Autogenerated: v2/help/Entities/ApiContentFileEntity?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:04 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IFieldObject } from './IFieldObject';
import { IEntity, EntityFields } from './IEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class ContentFileEntityFields extends EntityFields {
    public static passwordField = 'Password';
    public static pathField = 'Path';
    public static schemaField = 'Schema';
    public static supportsEditorField = 'SupportsEditor';
    public static userNameField = 'UserName';
}

// #endregion Fields

// #region Inner classes

export interface IRuleTranslation extends IFieldObject {
    from: string;
    to: string;
}

export interface IRuleAttribute extends IFieldObject {
    isMandatory: boolean;
    key: string;
    defaultValue: string;
    length: number;
    start: number;
    dateFormat: string;
    translations: ObservableCollection<IRuleTranslation>;
}

// #endregion Inner classes

export interface IContentFileEntity extends IEntity {
    // #region REST Properties

    password: string;

    path: string;

    readonly schema: string;

    supportsEditor: boolean;

    userName: string;

    // #endregion REST Properties

    // #region REST Methods

    getAttributesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRuleAttribute> | null>;
    addPlateAsync(value: IFieldObject, transaction?: ITransaction): Promise<IRestResponse | null>;
    getRolesAsync(transaction?: ITransaction): Promise<Array<IGuid> | null>;

    // #endregion REST Methods

}

