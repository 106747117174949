// Autogenerated: v2/help/Entities/ApiZoneManagementRole?codegen=ts&InterfaceOnly=True&workflow=False on 2020-07-23 3:40:15 PM
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';

// #region Fields

export class ZoneManagementRoleEntityFields extends RoleEntityFields {
    public static keepHistoryField = 'KeepHistory';
    public static logRetentionDaysField = 'LogRetentionDays';
}

// #endregion Fields

export interface IZoneManagementRoleEntity extends IRoleEntity {
    // #region REST Properties

    keepHistory: boolean;

    logRetentionDays: number;

    // #endregion REST Properties

}

