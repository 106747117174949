import { Inject, Injectable } from '@angular/core';
import { ApplicationType } from 'RestClient/Client/Enumerations/ApplicationType';
import { IGuid, SafeGuid } from 'safeguid';
import { WebAppClient, WebAppRegistrationInfo } from 'WebClient/WebAppClient';
import { Constants } from '@src/constants';
import { WINDOW } from '@utilities/common-helper';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Injectable({ providedIn: 'root' })
export class SecurityCenterClientService {
    public get scClient(): WebAppClient {
        return this.securityCenterClient;
    }

    private webAppUniqueIdHeader = 'WebAppUniqueId';
    private webAppMachineUniqueIdHeader = 'WebAppMachineUniqueId';
    private securityCenterClient: WebAppClient;

    constructor(@Inject(WINDOW) private window: Window) {
        const storedWebAppUniqueId = this.window.sessionStorage.getItem(this.webAppUniqueIdHeader) ?? this.resetUniqueAppId();

        let storedWebAppMachineUniqueId = this.window.localStorage.getItem(this.webAppMachineUniqueIdHeader);
        if (storedWebAppMachineUniqueId === null) {
            storedWebAppMachineUniqueId = SafeGuid.newGuid().toString();
            this.window.localStorage.setItem(this.webAppMachineUniqueIdHeader, storedWebAppMachineUniqueId);
        }

        this.securityCenterClient = new WebAppClient(new WebAppRegistrationInfo(storedWebAppUniqueId));
        this.securityCenterClient.applicationType = ApplicationType.WebClient;
        this.securityCenterClient.debugAppName = 'SC6 Incubation';
        this.securityCenterClient.registrationInfo.machineId = storedWebAppMachineUniqueId;

        const xsrfToken = this.getCookie('XSRF-TOKEN');
        this.securityCenterClient.addAdditionalHeader('X-XSRF-TOKEN', xsrfToken);

        this.securityCenterClient.onTokenApplied(() => {
            this.window.localStorage.setItem(Constants.isWebAppTokenSet, 'true');
        });
    }

    public resetUniqueAppId(): string {
        const storedWebAppUniqueId = SafeGuid.newGuid().toString();
        this.window.sessionStorage.setItem(this.webAppUniqueIdHeader, storedWebAppUniqueId);
        if (this.securityCenterClient !== undefined) {
            this.securityCenterClient.registrationInfo.uniqueId = storedWebAppUniqueId;
        }
        return storedWebAppUniqueId;
    }

    public resetToken(): void {
        this.window.localStorage.removeItem(Constants.isWebAppTokenSet);
    }

    public getCookie(cname: string): string {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
