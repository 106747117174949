import { ILogger, Logger } from '../utils/logger';
import { TokenRetriever, TokenRetrieverFct } from './TokenRetriever';
import { EventHubDispatcher, IEventHubProvider } from './eventHubDispatcher';
import { ITransport, ITransportBuilder } from './ITransport';
import { WebSocketTransportBuilder } from './WebSocketTransportV2';
import { Camera } from '../players/camera';
import { IStatefulSession, StatefulSession } from '../players/WebSocket/StatefulSession';
import { SupportedVideoFormats } from '../players/WebSocket/SupportedVideoFormats';
import { SessionCreationParameters } from '../players/WebSocket/SessionParameters';
import { WsSessionV2Builder } from '../players/WebSocket/WsSessionV2';
import { IStreamUsageSelectorBuilder } from '../players/StreamUsageSelector';
import { version } from '../gwp';

/**
 * @public
 * Create a new media gateway service that will coordinate requests and event with a media gateway server.
 * The service may be used to control one or more {@link IWebPlayer} instances.
 * @param url - URL to the media gateway instance you want to use. Must be in the form `https://<endpoint>/<applicationPath>`
 * @param tokenRetriever - Function to use to resolve a new access token for the specified camera.
 * @returns Promise to a new Media Gateway Service instance.
 */
export async function buildMediaGatewayService(url: string, tokenRetriever: TokenRetrieverFct): Promise<IMediaGatewayService> {
  const transportBuilder = new WebSocketTransportBuilder(new Logger(0, 'MediaGatewayService'), url);
  return await MediaGatewayService.build(transportBuilder, new TokenRetriever(tokenRetriever));
}

/**
 * @beta
 * */
export async function buildMediaGatewayServiceWithTransport(url: string, tokenRetriever: TokenRetrieverFct, transportBuilder: ITransportBuilder, streamUsageSelectorBuilder: IStreamUsageSelectorBuilder | null): Promise<IMediaGatewayService> {
  return await MediaGatewayService.build(transportBuilder, new TokenRetriever(tokenRetriever), streamUsageSelectorBuilder);
}

/**
 * @public
 * Represent the connection to a Media Gateway server.
 * May be used to initialize one of more {@link IWebPlayer} instances.
 * */
export interface IMediaGatewayService {
}

/** @internal */
export class MediaGatewayService implements IMediaGatewayService {
  private readonly m_logger: ILogger;
  private readonly _tokenRetriever: TokenRetriever;
  private readonly _eventDispatcher: EventHubDispatcher;
  private readonly _transport: ITransport;
  private readonly m_streamUsageSelectorBuilder: IStreamUsageSelectorBuilder | null;

  public get eventHubProvider(): IEventHubProvider {
    return this._eventDispatcher;
  }

  private constructor(logger: ILogger, tokenRetriever: TokenRetriever, eventDispatcher: EventHubDispatcher, transport: ITransport, streamUsageSelectorBuilder: IStreamUsageSelectorBuilder | null = null) {
    this.m_logger = logger.subLogger('MediaGatewayService');
    this._tokenRetriever = tokenRetriever;
    this._eventDispatcher = eventDispatcher;
    this._transport = transport;
    this.m_streamUsageSelectorBuilder = streamUsageSelectorBuilder;
    this.m_logger.info?.trace('New MediaGatewayService created');
  }

  public static async build(transportBuilder: ITransportBuilder, tokenRetriever: TokenRetriever, streamUsageSelectorBuilder: IStreamUsageSelectorBuilder | null = null): Promise<MediaGatewayService> {
    const logger: ILogger = new Logger(0, 'MediaGatewayService');
    const eventHubDispatcher = new EventHubDispatcher(logger);

    const transport = await transportBuilder.build(eventHubDispatcher);

    return new MediaGatewayService(logger, tokenRetriever, eventHubDispatcher, transport, streamUsageSelectorBuilder);
  }

  /* For testing */
  public static fromTransport(url: string, tokenRetriever: TokenRetriever, transport: ITransport): MediaGatewayService {
    const logger: ILogger = new Logger(0, 'MediaGatewayService');
    const eventHubDispatcher = new EventHubDispatcher(logger);
    return new MediaGatewayService(logger, tokenRetriever, eventHubDispatcher, transport);
  }

  public async startStream(logger: ILogger, channel: number, camera: Camera, supportedFormats: SupportedVideoFormats, streamUsageSelectorBuilder: IStreamUsageSelectorBuilder): Promise<IStatefulSession> {
    const tokenRenewer = await this._tokenRetriever.acquireRenewer(logger, camera);
    const sessionParameters = new SessionCreationParameters(channel, camera, tokenRenewer, supportedFormats, this.getVersion());
    const sessionBuilder = new WsSessionV2Builder(logger, this._transport);
    const session = await StatefulSession.build(logger, sessionBuilder, sessionParameters, this.m_streamUsageSelectorBuilder ?? streamUsageSelectorBuilder);
    return session;
  }

  public async stop() {
    this._transport.stop();
  }

  private getVersion(): string {
    let gwpVersion = version();

    if (gwpVersion === '[VERSION]') {
      gwpVersion = '999.999.99999';
    }

    return gwpVersion;
  }
}
