<!-- Loading -->
<div class="ha-center va-center" *ngIf="isLoading">
    <gen-spinner></gen-spinner>
</div>

<!-- Addresses -->
<ng-container *ngIf="!isLoading">
    <div *ngFor="let readLocation of readLocations" class="address-container">
        <gen-item *ngIf="readLocation.address">
            <gen-icon [icon]="Icon.MapPin"></gen-icon>
            <gen-text>{{ readLocation.address }} </gen-text>
        </gen-item>
    </div>
</ng-container>

<!-- The Map -->
<div id="map" class="read-location-map" [class.loading]="isLoading"></div>
