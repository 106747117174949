<div>
    <gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
    <div *ngIf="!isLoading">
        <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_OVERVIEW' | translate }}</gen-text>
        <gen-grid class="mt-2" [genColumns]="2" [genGridGap]="1">
            <gen-grid-item>
                <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_NOUN_EMAIL' | translate }}</gen-text>
                <div class="tooltip">
                    <div id="email">{{ emailAddress }}</div>
                    <div class="gen-tooltip top">
                        <span>{{ emailAddress }}</span>
                    </div>
                </div>
            </gen-grid-item>
            <gen-grid-item>
                <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_STATUS' | translate }}</gen-text>
                <gen-text [flavor]="TextFlavor.Normal">{{ status }}</gen-text>
            </gen-grid-item>
            <gen-grid-item class="columns-span-2">
                <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_NOUN_GROUP' | translate }}</gen-text>
                <gen-list class="group-container" density="compact">
                    <gen-list-item class="group-container__item" *ngFor="let groupName of groupNames | sortBy: 'asc'">
                        <gen-text class="group-container__item__content" [appTooltip]="groupName" appTooltipOnlyIfOverflow>{{ groupName }}</gen-text>
                    </gen-list-item>
                </gen-list>
            </gen-grid-item>
        </gen-grid>
    </div>

    <!-- Disabled the show more for now as we have nothing more to show :) -->
    <!--<gen-button class="show-more" [flavor]="ButtonFlavor.Flat" >{{ 'STE_BUTTON_SHOWMORE' | translate }}</gen-button>-->
</div>
