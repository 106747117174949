import { AfterViewInit, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import { EntityBrowserCheckSelection } from '@modules/shared/entity-browser/entity-browser-selection';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { EntityBrowserComponent } from '@modules/shared/components/entity-browser/entity-browser/entity-browser.component';
import { TrackingService } from '../../../shared/services/tracking.service';
import { MCIncident } from '../../models/mc-incident';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { AuthService } from '@securityCenter/services/authentication/auth.service';

@Component({
    selector: 'app-incident-recipient-modal',
    templateUrl: './incident-recipient-modal.component.html',
    styleUrls: ['incident-recipient-modal.component.scss'],
})
export class IncidentRecipientModalComponent extends ConnectionAwareModalComponent implements OnInit, AfterViewInit {
    public static pluginId = SafeGuid.parse('cf52c8c9-c2cb-4445-886e-7b5e97d74bfa');

    @ViewChild('entityBrowser')
    public entityBrowser!: EntityBrowserComponent;

    @Input() public incident!: MCIncident;

    @Input() public recipientChange!: (recipients: IGuid[]) => void;

    public entityBrowserFilter!: EntityBrowserFilter;

    public seletedRecipients: IGuid[] = [];

    public recipientChangeAction!: () => Promise<boolean>;

    constructor(protected authService: AuthService, injector: Injector, trackingService: TrackingService) {
        super(authService, injector, trackingService);
        this.recipientChangeAction = this.onRecipientChangePressed.bind(this);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        const filter = new EntityBrowserFilter([EntityTypes.UserGroups, EntityTypes.Users]);
        if (this.incident.recipientIds.count() > 0) {
            filter.excludedEntities = SafeGuid.createSet(this.incident.recipientIds.toArray());
        }
        this.entityBrowserFilter = filter;
    }

    public onRecipientChange(recipientSelection: EntityBrowserCheckSelection): void {
        this.seletedRecipients = recipientSelection.ids;
    }

    private onRecipientChangePressed(): Promise<boolean> {
        this.recipientChange(this.seletedRecipients);
        return Promise.resolve(true);
    }
}
