/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Observable } from 'rxjs';
import { MethodEmitter } from '../../../store';
import { FiltersService } from '../interfaces/plugins/public/plugin-services-public.interface';
import { Filter, FiltersMap } from '../services/filters/filter';
import { SecurityCenterStateService } from '../services/state/security-center-state.service';
import { FiltersState } from './filters.state';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable({
    providedIn: 'root',
})
export class FiltersContext extends SecurityCenterStateService implements FiltersService {
    @Select(FiltersState.filters)
    public filters$!: Observable<FiltersMap>;

    @SelectSnapshot(FiltersState.filters)
    public filters!: FiltersMap;

    constructor(securityCenterClientService: SecurityCenterClientService) {
        super(securityCenterClientService);
    }

    @MethodEmitter(FiltersState.set)
    public set(filter: Filter): void {}

    @MethodEmitter(FiltersState.reset)
    public reset(): void {}

    protected clearState(): void {
        super.clearState();

        this.reset();
    }
}
