import { Resolution } from '../gwp';
import { Guard } from './Guard';
import { ClipSpaceVec2 } from './Vec2';

//Origin is top left
export class TopLeftCoords {
  private readonly m_x: number;

  private readonly m_y: number;

  public get X() {
    return this.m_x;
  }

  public get Y() {
    return this.m_y;
  }

  public constructor(x: number, y: number) {
    this.m_x = x;
    this.m_y = y;
  }

  public toString(): string {
    return `${this.m_x.toFixed(2)}, ${this.m_y.toFixed(2)}`;
  }

  public toClipSpaceVec2(resolution: Resolution): ClipSpaceVec2 {
    const clipX = (2 * this.m_x / resolution.Width) - 1;
    const clipY = -((2 * this.m_y / resolution.Height) - 1); //* -1 because since y = 0 is the top in coords, it translates to top = -1 and bottom = 1 and we want the opposite in clip space
    return new ClipSpaceVec2(clipX, clipY);
  }

  public equals(other: TopLeftCoords): boolean {
    return this.X === other.X && this.Y === other.Y;
  }
}

export class NormalizedTopLeftCoords extends TopLeftCoords {
  public constructor(x: number, y: number) {
    Guard.isInRangeInclusive(x, 0, 1);
    Guard.isInRangeInclusive(y, 0, 1);
    super(x, y);
  }

  public toPixelTopLeftCoords(destRes: Resolution): TopLeftCoords {
    return new TopLeftCoords(this.X * destRes.Width, this.Y * destRes.Height);
  }
}
