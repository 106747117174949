import { ILogger } from '../utils/logger';
import { VideoWatermarkConfig } from '../utils/VideoWatermarkingConfig';
import { VideoWatermarkingRenderer } from '../utils/VideoWatermarkingRenderer';

export interface IDewarperVwoOverlay {
  Show: boolean;

  updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void;
  drawVwo(): void;
}

export class DewarperVwoOverlay implements IDewarperVwoOverlay {
  private readonly m_logger: ILogger;
  private readonly m_canvas: HTMLCanvasElement;

  private m_watermarkRenderer: VideoWatermarkingRenderer | null = null;
  private m_watermarkNeedsRefresh: boolean = true;

  public set Show(show: boolean) {
    this.m_canvas.style.display = show ? 'block' : 'none';
  }

  public constructor(logger: ILogger, canvas: HTMLCanvasElement) {
    this.m_logger = logger.subLogger('DewarperVwoOverlay');
    this.m_canvas = canvas;
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void {
    // Only create the WatermarkRenderer if the config is enabled
    if (videoWatermarkingConfig !== VideoWatermarkConfig.Disabled) {
      this.m_logger.debug?.trace('Updating video watermark configuration');
      this.m_watermarkRenderer = new VideoWatermarkingRenderer(videoWatermarkingConfig, this.m_canvas.scrollWidth, this.m_canvas.scrollHeight);
      this.m_watermarkNeedsRefresh = true;
    } else {
      this.m_logger.debug?.trace('Disabling video watermark');
      this.m_watermarkRenderer = null;
    }
  }

  public drawVwo(): void {
    if (this.m_watermarkRenderer === null) {
      return;
    }
    const canvas = this.m_canvas;
    if (canvas.scrollWidth === canvas.width && canvas.scrollHeight === canvas.height && !this.m_watermarkNeedsRefresh) {
      return;
    }

    // Make the canvas drawing buffer size (canvas.width/height) the same as the displayed resolution (canvas.clientWidth/clientHeight)
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    if (canvas.width === 0 || canvas.height === 0) {
      this.m_logger.info?.trace('VWO canvas has size zero, won\'t be drawn');
      return;
    }

    //const video = this.m_htmlVideoElement;
    const ctx = canvas.getContext('2d');
    if (ctx !== null) {
      this.m_watermarkRenderer.updateVideoWatermarkingOverlaySize(canvas.width, canvas.height);
      this.m_watermarkRenderer.drawVideoWatermarkingOverlay(ctx, 0, 0);
      this.m_watermarkNeedsRefresh = false;
    } else {
      this.m_logger.error?.trace('Failed to obtain canvas context to draw VWO on dewarping canvas.');
    }
  }
}
