import { MapObjectEventBase } from './MapObjectEventBase';

export class MapObjectRemoveEvent extends MapObjectEventBase {
    public get removeEventContentOnly(): boolean {
        return this.getField('RemoveEventContentOnly');
    }

    constructor() {
        super();
    }
}
