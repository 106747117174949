<div id="fullscreenDetailsContainer" [class.show]="isSideBySide || selectedCardholderId || isAddMode">
    <ng-container #fullscreenDetailsContainer></ng-container>
</div>

<gen-app *ngLet="isCardView$ | async as isCardView">
    <ng-container *ngLet="hasMore$ | async as hasMore">
        <gen-header>
            <div class="toolbar">
                <div class="toolbar__left">
                    <ng-container *ngIf="isAddFeatureFlagEnabled">
                        <!-- Add cardholder button -->
                        <gen-button *ngIf="hasAddPrivilege" (click)="onAddCardholderButtonClicked()">
                            <gen-icon [icon]="Icon.Plus"></gen-icon>
                            {{ 'STE_BUTTON_ADD_CARDHOLDER' | translate }}
                        </gen-button>
                    </ng-container>
                    <!-- Filters Component -->
                    <app-filters *ngIf="reportFilters" [filterDescriptors]="reportFilters" [filtersService]="reportFiltersService"></app-filters>
                </div>
                <gen-banner *ngIf="isBannerShowing" [flavor]="bannerFlavor" canDismiss>
                    {{ errorText }}
                </gen-banner>

                <div class="toolbar__right">
                    <!-- List view button -->
                    <gen-button (click)="setListViewMode()" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_LABEL_LIST_VIEW' | translate }}">
                        <gen-icon [icon]="MeltedIcon.BulletList" [genColor]="isCardView ? Colors.Primary : Colors.Puffo"></gen-icon>
                    </gen-button>

                    <!-- Card view button -->
                    <gen-button (click)="setCardViewMode()" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_LABEL_CARD_VIEW' | translate }}">
                        <gen-icon [icon]="MeltedIcon.Cards" [genColor]="isCardView ? Colors.Puffo : Colors.Primary"></gen-icon>
                    </gen-button>

                    <gen-separator class="ml-1 mr-1" [orientation]="Orientation.Vertical"></gen-separator>

                    <!-- Side By Side pane button -->
                    <gen-button
                        *ngIf="featureFlags.CardholdersEditPane"
                        id="btn-side-by-side"
                        data-query="btn-sideBySide"
                        (click)="toggleSideBySide()"
                        [flavor]="ButtonFlavor.Flat"
                        appTooltip="{{ 'STE_LABEL_DETAILS_PANE' | translate }}"
                    >
                        <gen-icon [icon]="MeltedIcon.Layout2X1" [genColor]="isSideBySide ? Colors.Puffo : Colors.Primary"></gen-icon>
                    </gen-button>

                    <!-- More menu Ellipsis button-->
                    <gen-button
                        id="btn-toggle-more"
                        (click)="toggleMoreMenu()"
                        [flavor]="ButtonFlavor.Flat"
                        appTooltip="{{ 'STE_BUTTON_SHOWMORE' | translate }}"
                        [genAutomaticNavigation]="true"
                        genFindElementQuery="div.gen-list-item[tabindex='0']"
                        genFindDocumentElementQuery=".gen-contextmenu"
                    >
                        <gen-icon [icon]="MeltedIcon.EllipsisVertical"></gen-icon>
                    </gen-button>
                    <gen-menu #moreMenu targetSelector="#btn-toggle-more" [appMenuDataSource]="moreMenuItemSource"></gen-menu>
                </div>
            </div>
            <gen-banner *ngIf="isBannerShowing" [flavor]="bannerFlavor" canDismiss>
                {{ errorText }}
            </gen-banner>
        </gen-header>

        <gen-main class="h-100 w-100" *ngLet="selectedCardholderId$ | async as selectedId">
            <ng-container *ngLet="!!(refreshing$ | async) as refreshing">
                <ng-container *ngLet="!!(hasMore$ | async) as hasMore">
                    <!-- Card view -->
                    <app-cardholder-card-view
                        [hidden]="!isCardView"
                        [cardholders]="reportData$ | async"
                        [selectedId]="selectedId ?? null"
                        (selectedChanged)="onCardholderSelected($event)"
                        [refreshing]="refreshing"
                        (loadMore)="loadMoreCardholders()"
                        [hasMore]="hasMore"
                    >
                    </app-cardholder-card-view>
                    <!-- List view -->
                    <app-table
                        [hidden]="isCardView"
                        #table
                        *ngIf="reportColumnDescriptors"
                        [columnDescriptors]="reportColumnDescriptors"
                        [data]="reportData$ | async"
                        class="pl-1"
                        [refreshing]="refreshing"
                        [selectedId]="selectedId ?? null"
                        (selectedChanged)="onCardholderSelected($event)"
                        (sortChanged)="onSortChanged($event)"
                        (loadMore)="loadMoreCardholders()"
                        [hasMore]="hasMore"
                    ></app-table>
                </ng-container>
            </ng-container>
        </gen-main>
        <gen-aside class="details-pane" *ngIf="featureFlags.CardholdersEditPane" [style.display]="isSideBySide ? 'block' : 'none'" [genPosition]="Position.Aside.Right">
            <ng-container #sidePaneDetailsContainer></ng-container>
        </gen-aside>

        <ng-container *ngIf="reportDescriptor">
            <gen-footer>
                <div class="rows-count-container">
                    <gen-text *ngIf="reportItemCount$ | async as reportCount">
                        {{ 'STE_LABEL_REPORT_ITEMS_COUNT_FORMAT' | translate | stringFormat: reportCount }}
                        {{ hasMore ? '(' + ('STE_LABEL_SCROLL_TO_LOAD_MORE' | translate) + ')' : '' }}
                    </gen-text>
                </div>
            </gen-footer>
        </ng-container>
    </ng-container>
</gen-app>
