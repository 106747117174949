import { Utils } from './Utils';

/**
 * @beta
 * */
export class VideoWatermarkConfig {
  /// <summary>
  /// From 0 (invisible)
  /// to 1 (fully opaque)
  /// </summary>
  public readonly Opacity: number;

  /// <summary>
  /// From 1 (minimum size)
  /// to 100 (maximum size)
  /// </summary>
  public readonly Size: number;

  /// <summary>
  /// From 0 (no rotation)
  /// to 45 (45°)
  /// </summary>
  public readonly Rotation: number;

  public readonly Position: number;

  public readonly IncludeUsername: boolean;

  public readonly IncludeWorkstation: boolean;

  public readonly IncludeCameraName: boolean;

  public readonly Username: string;

  public readonly CameraName: string;

  public readonly Workstation: string;

  public overlayText: string[] = [];

  public static readonly Disabled: VideoWatermarkConfig = new VideoWatermarkConfig(0, 0, 0, 0, false, false, false, '', '', '');

  private constructor(opacityOn255: number, size: number, position: number, rotation: number, includeUsername: boolean, includeWorkstation: boolean,
    includeCameraName: boolean, username: string, cameraName: string, workstation: string) {
    const MaxOpacityFromSecurityCenter = 255;
    this.Opacity = opacityOn255 / MaxOpacityFromSecurityCenter;
    this.Size = size;
    this.Position = position;
    this.Rotation = rotation;
    this.Username = username;
    this.CameraName = cameraName;
    this.Workstation = workstation;
    this.IncludeUsername = includeUsername;
    this.IncludeWorkstation = includeWorkstation;
    this.IncludeCameraName = includeCameraName;
    if (this.IncludeUsername) this.overlayText.push(this.Username);
    // if (this.IncludeWorkstation) this.overlayText.push(this.Workstation); // Currently, it is media gateway and it is not relevant
    if (this.IncludeCameraName) this.overlayText.push(this.CameraName);
  }

  public toString(): string {
    return this.debugStatus(0);
  }

  public static build(serialized: any): VideoWatermarkConfig {
    if (serialized === null) {
      return VideoWatermarkConfig.Disabled;
    }
    if (serialized === undefined || serialized.Opacity === undefined || serialized.Size === undefined || serialized.Rotation === undefined || serialized.Position === undefined || serialized.IncludeUsername === undefined || serialized.IncludeWorkstation === undefined || serialized.IncludeCameraName === undefined || serialized.Username === undefined || serialized.CameraName === undefined || serialized.Workstation === undefined) {
      throw new Error('VideoWatermarkConfig has incorrect format: ' + serialized);
    }

    const videoWatermarkConfig = new VideoWatermarkConfig(serialized.Opacity, serialized.Size, serialized.Position, serialized.Rotation, serialized.IncludeUsername, serialized.IncludeWorkstation, serialized.IncludeCameraName, serialized.Username, serialized.CameraName, serialized.Workstation);

    //We do not display the machine name. If userName and camerName are not displayed, there is nothing to display. Save computing by disabling videowatermark.
    return (videoWatermarkConfig.IncludeUsername || videoWatermarkConfig.IncludeCameraName) ? videoWatermarkConfig : VideoWatermarkConfig.Disabled;
  }

  public debugStatus(indent: number): string {
    if (this === VideoWatermarkConfig.Disabled) {
      return 'Video watermarking config: Disabled';
    }

    return 'Video watermarking config:' + Utils.indentNewLine(indent) +
      'Opacity: ' + this.Opacity + Utils.indentNewLine(indent) +
      'Size: ' + this.Size + Utils.indentNewLine(indent) +
      'Position: ' + TextOverlayPosition[this.Position] + Utils.indentNewLine(indent) +
      'Rotation: ' + this.Rotation + Utils.indentNewLine(indent) +
      'Username: ' + this.Username + Utils.indentNewLine(indent) +
      'CameraName: ' + this.CameraName + Utils.indentNewLine(indent) +
      'Workstation: ' + this.Workstation + Utils.indentNewLine(indent) +
      'IncludeUsername: ' + this.IncludeUsername + Utils.indentNewLine(indent) +
      'IncludeWorkstation: ' + this.IncludeWorkstation + Utils.indentNewLine(indent) +
      'IncludeCameraName: ' + this.IncludeCameraName + Utils.indentNewLine(indent) +
      'OverlayText: ' + this.overlayText;
  }
}

export enum TextOverlayPosition {
  Mosaic = 0,
  TopLeft = 1,
  Top = 2,
  TopRight = 3,
  CenterLeft = 4,
  Center = 5,
  CenterRight = 6,
  BottomLeft = 7,
  Bottom = 8,
  BottomRight = 9
}
