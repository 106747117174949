<div class="header-container">
    <gen-spinner class="columns-span-11 vertical-align-center" [hidden]="!isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
    <ng-container *ngIf="!isLoading">
        <gen-item class="columns-span-10">
            <gen-icon icon="monitoring" size="medium"></gen-icon>
            <gen-text appTooltip="{{ 'STE_LABEL_WATCH_LIST' | translate }}" [appTooltipOnlyIfOverflow]="true">{{ headerTitle }}</gen-text>
        </gen-item>
        <div class="justify-next-end">
            <gen-button *ngIf="monitoredEntities.length > 0" id="monitored-entities-tooltip-trigger" flavor="flat" (click)="enterEditMode()">
                {{ monitoredEntitiesButtonLabel }}
            </gen-button>
        </div>
        <div class="justify-self-end">
            <gen-button id="menu-trigger" flavor="flat" (click)="toggleMenu()"><gen-icon icon="ellipsis-vertical"></gen-icon></gen-button>
        </div>
    </ng-container>
</div>

<gen-tooltip *ngIf="monitoredEntities.length > 0" position="bottom" targetSelector="#monitored-entities-tooltip-trigger">
    <div *ngFor="let monitoredEntity of monitoredEntities" class="tooltip-item-padding tooltip-max-width">
        <gen-item>
            <gen-icon [icon]="monitoredEntity.icon"></gen-icon>
            <gen-text>{{ monitoredEntity.name }}</gen-text>
        </gen-item>
    </div>
</gen-tooltip>

<gen-menu [open]="isMenuOpened" targetSelector="#menu-trigger" (didClose)="toggleMenu(true)">
    <gen-menu-item (click)="openEventOptions()">
        <gen-text>{{ 'STE_MENU_ITEM_EVENT_OPTIONS' | translate }}</gen-text>
    </gen-menu-item>
    <gen-menu-item (click)="markAllAsRead()" *ngIf="hasUnreadEvents$ | async">
        <gen-text>{{ 'STE_MENU_ITEM_MARK_ALL_AS_READ' | translate }}</gen-text>
    </gen-menu-item>
    <gen-menu-item (click)="dismissAllEvents()" *ngIf="hasEvents$ | async">
        <gen-text>{{ 'STE_MENU_ITEM_DISMISS_ALL' | translate }}</gen-text>
    </gen-menu-item>
</gen-menu>
