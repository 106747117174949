// Autogenerated: v2/help/Classes/ApiLprHitEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:52 AM
import { TimezoneEvent } from './TimezoneEvent';
import { LprReadEvent } from './LprReadEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { ILprHitEvent } from '../Interface/ILprHitEvent';
import { ILprReadEvent } from '../Interface/ILprReadEvent';

export class LprHitEvent extends TimezoneEvent implements ILprHitEvent {
    // #region REST Properties

    // AttributesXml
    public get attributesXml(): string {
        return this.getField<string>('AttributesXml');
    }
    public set attributesXml(value: string) {
        this.setField<string>('AttributesXml', value);
    }

    // DefaultColor
    public get defaultColor(): string {
        return this.getField<string>('DefaultColor');
    }
    public set defaultColor(value: string) {
        this.setField<string>('DefaultColor', value);
    }

    // HitType
    public get hitType(): string {
        return this.getField<string>('HitType');
    }
    public set hitType(value: string) {
        this.setField<string>('HitType', value);
    }

    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }

    // IsProtected
    public get isProtected(): boolean {
        return this.getField<boolean>('IsProtected');
    }
    public set isProtected(value: boolean) {
        this.setField<boolean>('IsProtected', value);
    }

    // IsRealTime
    public get isRealTime(): boolean {
        return this.getField<boolean>('IsRealTime');
    }
    public set isRealTime(value: boolean) {
        this.setField<boolean>('IsRealTime', value);
    }

    // LprRuleId
    public get lprRuleId(): IGuid {
        return this.getFieldGuid('LprRuleId');
    }
    public set lprRuleId(value: IGuid) {
        this.setFieldGuid('LprRuleId', value);
    }

    // Read1
    public get read1(): ILprReadEvent {
        return this.getFieldObject<LprReadEvent, ILprReadEvent>(LprReadEvent, 'Read1');
    }
    public set read1(value: ILprReadEvent) {
        this.setFieldObject<ILprReadEvent>('Read1', value);
    }

    // Read2
    public get read2(): ILprReadEvent {
        return this.getFieldObject<LprReadEvent, ILprReadEvent>(LprReadEvent, 'Read2');
    }
    public set read2(value: ILprReadEvent) {
        this.setFieldObject<ILprReadEvent>('Read2', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('AttributesXml', '');
        this.setField<string>('DefaultColor', '');
        this.setField<string>('HitType', '');
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('IsProtected', false);
        this.setField<boolean>('IsRealTime', false);
        this.setFieldGuid('LprRuleId', SafeGuid.EMPTY);
        const objRead1 = new LprReadEvent();
        objRead1.initializeAllFields();
        this.setFieldObject('Read1', objRead1);
        const objRead2 = new LprReadEvent();
        objRead2.initializeAllFields();
        this.setFieldObject('Read2', objRead2);
    }

    // #endregion Methods

}

