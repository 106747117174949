import { Component, OnInit } from '@angular/core';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { FiltersService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { SafeGuid } from 'safeguid';
import { FilterDescriptor } from '@modules/general/components/filter-plugins/interfaces/filter-descriptor';
import { MetadataPluginComponent } from '../../../shared/interfaces/plugins/public/plugin-public.interface';
import { TrackedComponent } from '../../../shared/components/tracked/tracked.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template: '',
})
export abstract class FilterPluginBaseComponent extends TrackedComponent implements OnInit, MetadataPluginComponent {
    protected initData!: FilterDescriptor; // Will be set in setInitData via plugin injector
    protected filterService!: FiltersService;

    public get descriptor(): FilterDescriptor {
        return this.initData;
    }

    protected get typeSpecificFields(): Record<string, unknown>[] | undefined {
        return Array.isArray(this.initData?.typeSpecificFields) ? (this.initData.typeSpecificFields as Record<string, any>[]) : [];
    }

    constructor(trackingService: TrackingService, protected pluginId: SafeGuid) {
        super(trackingService);
    }

    public ngOnInit(): void {}

    public setInitData(initData: unknown): void {
        this.initData = initData as FilterDescriptor;
    }

    public setDataContext(context: unknown): void {
        this.filterService = context as FiltersService;
    }

    public updateFilter(value: unknown): void {
        if (this.filterService && this.initData) {
            this.filterService.set({ pluginId: this.pluginId, filterId: this.initData.filterId, value, type: this.initData.type });
        }
    }

    protected getField<T>(fieldName: string): T | undefined {
        const lowerCaseFieldName = fieldName.toLowerCase();
        let originalKeyValue: string | undefined;
        const item = this.typeSpecificFields?.find((field) => {
            const originalKeys = Object.keys(field);
            const lowerCaseKeys = originalKeys.map((key) => key.toLowerCase());
            const keyIndex = lowerCaseKeys.indexOf(lowerCaseFieldName);
            if (keyIndex !== -1) {
                originalKeyValue = originalKeys[keyIndex];
                return true;
            }
            return false;
        });
        if (!originalKeyValue || !item) {
            return undefined;
        }
        return item[originalKeyValue] as T | undefined;
    }
}
