// Autogenerated: v2/help/enumerations/ApiServerType?codegen=ts&showresources=False on 2020-07-23 3:40:07 PM

export class ServerType {
    public static Extension = 'Extension';
    public static Main = 'Main';

    public static readonly values = [
        ServerType.Extension,
        ServerType.Main
    ];
}
