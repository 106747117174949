// Autogenerated: v2/help/Entities/ApiReverseTunnelClientRole?codegen=ts&InterfaceOnly=True&workflow=False on 2020-07-23 3:40:14 PM
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';

// #region Fields

export class ReverseTunnelClientRoleEntityFields extends RoleEntityFields {
    public static encryptionModeField = 'EncryptionMode';
    public static keyFileField = 'KeyFile';
    public static remoteEncryptionModeField = 'RemoteEncryptionMode';
}

// #endregion Fields

export interface IReverseTunnelClientRoleEntity extends IRoleEntity {
    // #region REST Properties

    encryptionMode: string;

    readonly keyFile: string;

    readonly remoteEncryptionMode: string;

    // #endregion REST Properties

}

