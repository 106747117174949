import { EventHubDispatcher } from './eventHubDispatcher';
import { ErrorCode, PlayerState, StreamingConnectionStatus, TimelineEventKind } from '../enums';

export class EventHub {
  private readonly m_eventHubDispatcher: EventHubDispatcher;

  private readonly m_streamId: string;

  constructor(eventHubDispatcher: EventHubDispatcher, streamId: string) {
    this.m_eventHubDispatcher = eventHubDispatcher;
    this.m_streamId = streamId;
  }

  public dispose() {
    this.m_eventHubDispatcher.unregisterStream(this.m_streamId);

    this.receiveSegment = undefined;
    this.receiveJpeg = undefined;
    this.receivePlayerState = undefined;
    this.receiveStreamingConnectionStatus = undefined;
    this.receiveBufferingProgress = undefined;
    this.receiveErrorDetails = undefined;
    this.receiveTimelineContent = undefined;
    this.receivePlaySpeedChange = undefined;
    this.receiveAudioAvailable = undefined;
  }

  public receiveSegment?: (info: SegmentInfo, buffer: Int8Array) => void;
  public receiveJpeg?: (info: JpegInfo, buffer: Int8Array) => void;
  public receivePlayerState?: (state: SerializedPlayerState) => void;
  public receiveStreamingConnectionStatus?: (status: StreamingStatus) => void;
  public receiveBufferingProgress?: (progress: number) => void;
  public receiveErrorDetails?: (errorDetails: ErrorDetails) => void;
  public receiveTimelineContent?: (content: TimelineContent) => void;
  public receivePlaySpeedChange?: (playSpeed: SerializedPlaySpeed) => void;
  public receiveAudioAvailable?: (audioAvailable: boolean) => void;
}

/**
 * @beta
 * */
export class SegmentInfo {
  private m_frameTimeAsDate: Date | undefined;

  public readonly Id!: number;
  public readonly IsInit!: boolean;
  public readonly IsAudio!: boolean;
  public readonly TotalSeconds!: number;
  public readonly FrameTime!: string;
  public readonly SequenceId!: number;
  public readonly BaseDecodeTime!: number;
  public readonly MediaTime!: number;
  public readonly IsFrameHidden!: boolean;
  public readonly IsKeyFrame!: boolean;
  public readonly Generation!: number;

  public static build(id: number, isInit: boolean, isAudio: boolean, totalSeconds: number, frameTime: string, sequenceId: number, baseDecodeTime: number, mediaTime: number, isFrameHidden: boolean, isKeyFrame: boolean, generation: number): SegmentInfo {
    return new SegmentInfo({ Id: id, IsInit: isInit, IsAudio: isAudio, TotalSeconds: totalSeconds, FrameTime: frameTime, SequenceId: sequenceId, BaseDecodeTime: baseDecodeTime, MediaTime: mediaTime, IsFrameHidden: isFrameHidden, IsKeyFrame: isKeyFrame, Generation: generation });
  }

  constructor(event: any) {
    this.Id = event.Id;
    this.IsInit = event.IsInit;
    this.IsAudio = event.IsAudio;
    this.TotalSeconds = event.TotalSeconds;
    this.FrameTime = event.FrameTime;
    this.SequenceId = event.SequenceId;
    this.BaseDecodeTime = event.BaseDecodeTime;
    this.MediaTime = event.MediaTime;
    this.IsFrameHidden = event.IsFrameHidden;
    this.IsKeyFrame = event.IsKeyFrame;
    this.Generation = event.Generation;
  }

  public get TotalMilliseconds() {
    return this.TotalSeconds * 1000;
  }

  public get FrameTimeAsDate(): Date {
    if (this.m_frameTimeAsDate === undefined) {
      this.m_frameTimeAsDate = new Date(this.FrameTime);
    }
    return this.m_frameTimeAsDate;
  }

  public get IsVideo() {
    return !this.IsAudio;
  }

  public toString(): string {
    return `${this.Id} ${this.BaseDecodeTime}, ${this.FrameTimeAsDate} ${this.MediaTime}, Init: ${this.IsInit} Key: ${this.IsKeyFrame} Sequence: ${this.SequenceId} Gen: ${this.Generation} TotalSec: ${this.TotalSeconds}`;
  }
}

/**
 * @beta
 * */
export class JpegInfo {
  public readonly Timestamp!: string;
  public readonly Generation!: number;

  public constructor(timestamp: string, generation: number) {
    this.Timestamp = timestamp;
    this.Generation = generation;
  }
}

/**
 * @beta
 * */
export class ErrorDetails {
  public readonly ErrorCode!: ErrorCode;
  public readonly Value!: string;

  public constructor(errorCode: ErrorCode, detail: string) {
    this.ErrorCode = errorCode;
    this.Value = detail;
  }
}

/**
 * @beta
 * */
export class SerializedPlayerState {
  public readonly State!: PlayerState;
  public readonly Value!: string;

  public constructor(playerState: PlayerState, detail: string) {
    this.State = playerState;
    this.Value = detail;
  }
}

/**
 * @beta
 * */
export class StreamingStatus {
  public readonly Value!: string;
  public readonly State!: StreamingConnectionStatus;

  public constructor(streamingConnectionStatus: StreamingConnectionStatus, detail: string) {
    this.State = streamingConnectionStatus;
    this.Value = detail;
  }
}

/**
 * @beta
 * */
export class SerializedPlaySpeed {
  public readonly Value!: number;

  public constructor(value: number) {
    this.Value = value;
  }
}

/**
 * @beta
 * */
export class TimelineContent {
  public readonly Coverage!: DateTimeSpan;
  public readonly Events!: SerializedTimelineEvent[];

  public constructor(coverage: DateTimeSpan, events: SerializedTimelineEvent[]) {
    this.Coverage = coverage;
    this.Events = events;
  }
}

/**
 * @beta
 * */
export class DateTimeSpan {
  public readonly Start!: string;
  public readonly End!: string;

  public constructor(start: string, end: string) {
    this.Start = start;
    this.End = end;
  }
}

/**
 * @beta
 * */
export class SerializedTimelineEvent {
  public readonly Kind!: TimelineEventKind;
  public readonly EventTime!: string;
  public readonly Duration?: number;
  public readonly Details?: string;

  public constructor(kind: TimelineEventKind, eventTime: string, duration: number, details: string) {
    this.Kind = kind;
    this.EventTime = eventTime;
    this.Duration = duration;
    this.Details = details;
  }
}
