<gen-melted-modal
    genTitle="{{ 'STE_TITLE_SYSTEM_THREAT_LEVEL' | translate }}"
    id="threatLevelModal"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_BUTTON_APPLY' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnDefaultAction]="openThreatLevelConfirmationModal"
    [genIsDefaultActionDisabled]="isSelectedTheActiveThreatLevel"
    appFocusModalWhenOpen
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_LABEL_SELECT_THREAT_LEVEL' | translate }}</gen-text>
        <ng-container *ngIf="threatLevels$ | async as threatLevels; else loading">
            <gen-list class="threat-levels-container" [selectionType]="ListSelectionType.Single" [flavor]="ListFlavor.Solid">
                <gen-list-item *ngFor="let threatLevel of threatLevels" (selectedChange)="updateSelectedItem(threatLevel)" [selected]="isThreatLevelAlreadyActive(threatLevel)">
                    <gen-item>
                        <gen-text [appTooltip]="threatLevel.name" appTooltipOnlyIfOverflow>{{ threatLevel.name }}</gen-text>
                        <gen-icon [icon]="Icon.Stop" [genColor]="threatLevel.color | argb"></gen-icon>
                    </gen-item>
                </gen-list-item>
            </gen-list>
        </ng-container>
    </gen-melted-modal-body>
    <gen-melted-additional-footer
        ><gen-button [flavor]="ButtonFlavor.Flat" [disabled]="isThreatLevelAlreadyActive(null)" (click)="resetThreatLevel()">{{ 'STE_LABEL_VERB_RESET' | translate }}</gen-button>
    </gen-melted-additional-footer>
</gen-melted-modal>

<ng-template #loading>
    <gen-spinner class="threat-levels-container">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
</ng-template>
