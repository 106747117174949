import { IGuid, SafeGuid } from 'safeguid';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.ForwardIncident;
export class ForwardCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentId: IGuid, recipients: IGuid[]) {
        super(
            `/v2/Incidents/${incidentId.toString()}`,
            JSON.stringify({
                Type: commandType,
                Recipients: recipients.map((x) => x.toString()),
            })
        );
    }
}
