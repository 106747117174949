import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenAngularModule } from '@genetec/gelato-angular';
import { SharedModule } from '@modules/shared/shared.module';
import { PlateReadWidgetComponent } from './components/widgets/plate-read-widget/plate-read-widget.component';
import { ReadGeneralInformationComponent } from './components/widgets/plate-read-widget/read-general-information/read-general-information.component';
import { ReadLocationWidgetComponent } from './components/widgets/read-location-widget/read-location-widget.component';
import { HitEventWidgetComponent } from './components/widgets/hit-event-widget/hit-event-widget.component';
import { HitClient, ReadClient } from './api/api';

@NgModule({
    imports: [CommonModule, GenAngularModule, SharedModule],
    declarations: [PlateReadWidgetComponent, ReadLocationWidgetComponent, ReadGeneralInformationComponent, HitEventWidgetComponent],
    providers: [HitClient, ReadClient],
})
export class LprModule {}
