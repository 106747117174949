import { IGuid, SafeGuid } from 'safeguid';

declare global {
    // interface Map<K, V> {
    //     filter (predicate: (value: [K,V], index: number, array: [K,V][]) => unknown, thisArg?: any): [K,V][];
    // }

    function isString(object: unknown): object is string;
    function isNonEmptyString(object: unknown): object is string;
    function isNumber(object: unknown): object is number;
    function isNonEmptyGuid(object: unknown): object is IGuid;
    function isGuid(object: unknown): object is IGuid | string;
    function isInstanceOfGuid(object: unknown): object is IGuid;
    function toGuid(object: unknown): IGuid;
    function isOfType<T>(object: unknown, ...fieldsToCheck: (keyof T)[]): object is T;
    function isObject(object: unknown): object is Record<string, unknown>;
    function isArray(object: unknown): object is Array<unknown>;
    /**
     * Puts the guids in the string in lower case, leave other characters as they were.
     *
     * @param object the string to lowercase the guids from.
     */
    function toLowerCaseGuids(object: string): string;
}

const guidRegExp = /\(?\{?(([a-z0-9]{8})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{12}))\)?}?|(([a-z0-9]{8})([a-z0-9]{4})([a-z0-9]{4})([a-z0-9]{4})([a-z0-9]{12}))/gi;

globalThis.isString = (object: unknown): object is string => {
    return typeof object === 'string';
};

globalThis.isNonEmptyString = (object: unknown): object is string => {
    return typeof object === 'string' && object.length > 0;
};

globalThis.isNumber = (object: unknown): object is number => {
    return typeof object === 'number';
};

globalThis.isNonEmptyGuid = (object: unknown): object is IGuid => {
    return isInstanceOfGuid(object) && !object.isEmpty();
};

globalThis.toLowerCaseGuids = (object: string): string => {
    return object.replace(guidRegExp, (match) => match.toLowerCase());
};

/**
 * Returns true if the argument is a valid guid (string of **Guid** class instance).
 *
 * @param object The object or string to test.
 * @returns True if the object is of the Guid instance or a valid guid represented as a **string**.
 */
globalThis.isGuid = (object: unknown): object is IGuid | string => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return !!(object && (typeof object === 'string' || Object.getPrototypeOf(object).constructor.name === 'SafeGuid') && SafeGuid.isGuid(object));
};

/**
 * Returns true if the argument is a **Guid** class instance.
 *
 * @param object The  object or string to test.
 * @returns True if the object is an instance of Guid.
 */
globalThis.isInstanceOfGuid = (object: unknown): object is IGuid => {
    return typeof object !== 'string' && isGuid(object);
};

/**
 * Creates a new **Guid** class instance from the string or return itself if it's already a **Guid** instance. Retrurns empty guid if not a valid guid.
 *
 * @param object The string
 * @returns
 */
globalThis.toGuid = (object: unknown): IGuid => {
    if (isInstanceOfGuid(object)) {
        return object;
    }

    return isGuid(object) ? SafeGuid.parse(object as string) : SafeGuid.EMPTY;
};

globalThis.isOfType = <T>(object: unknown, ...fieldsToCheck: (keyof T)[]): object is T => {
    return object instanceof Object && fieldsToCheck.every((field) => field in object);
};

globalThis.isObject = (object: unknown): object is Record<string, unknown> => {
    const type = typeof object;
    return !!object && !Array.isArray(object) && (type === 'function' || type === 'object');
};

globalThis.isArray = (object: unknown): object is Array<unknown> => {
    return Array.isArray(object);
};
