import { IFieldObject } from '../Client/Interface/IFieldObject';

export class FieldObjectOverloads {
    private static CONSTRUCTORS = new Map<string, new (...args: any[]) => IFieldObject>();
    private static OVERLOADS = new Map<string, Set<string>>();
    private static LAST_OVERLOAD_TYPE: string;

    public static registerOverload(parentType: string, overloadType: string): void {
        let childTypes = this.OVERLOADS.get(parentType);
        if (childTypes) {
            childTypes.add(overloadType);
        } else {
            childTypes = new Set<string>();
            childTypes.add(overloadType);
            this.OVERLOADS.set(parentType, childTypes);
        }

        FieldObjectOverloads.LAST_OVERLOAD_TYPE = overloadType;
    }

    public static registerConstructor(constructor: new (...args: any[]) => IFieldObject): void {
        FieldObjectOverloads.CONSTRUCTORS.set(FieldObjectOverloads.LAST_OVERLOAD_TYPE, constructor);
    }

    public static getConstructor(name: string): (new (...args: any[]) => IFieldObject) | undefined {
        return FieldObjectOverloads.CONSTRUCTORS.get(name);
    }

    public static getOverloads(parentType: string): Set<string> | undefined {
        return FieldObjectOverloads.OVERLOADS.get(parentType);
    }
}

export function RegisterFieldObjectConstructor<T extends new (...args: any[]) => IFieldObject>(constructor: T) {
    FieldObjectOverloads.registerConstructor(constructor);
}

export function RegisterFieldObjectOverload(parentType: string, overloadType: string) {
    FieldObjectOverloads.registerOverload(parentType, overloadType);
    const constructor =  function <T extends new (...args: any[]) => IFieldObject>(_: T) {
        return class extends _ {
            overloadTypeName = overloadType;
        };
    };

    return constructor;
}
