import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { FilterBaseComponent } from '../filter-base.component';
import { NumericRangeModel } from './filter-numeric-range.model';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 * Filter restraining with a numeric range
 *
 * @example 0 to 255
 */
@Component({
    selector: 'app-filter-numeric-range',
    templateUrl: './filter-numeric-range.component.html',
    styleUrls: ['./filter-numeric-range.component.scss'],
})
export class FilterNumericRangeComponent extends FilterBaseComponent<NumericRangeModel> implements OnInit {
    //#region Public fields

    @Input() readonly min = Number.MIN_VALUE;
    @Input() readonly max = Number.MAX_VALUE;

    public readonly Icon = Icon;

    public from = 1;
    public to = 100;
    public invalidDateRange = false;

    //#endregion

    //#region Public methods

    ngOnInit(): void {
        super.ngOnInit();
        this.initFilter();
    }

    public onFromInputChange(newValue: number): void {
        this.from = newValue;
        this.updateRange();
    }

    public onToInputChange(newValue: number): void {
        this.to = newValue;
        this.updateRange();
    }

    public updateState(): void {
        let selection = this.state.selection;

        if (this.min === this.from && this.max === this.to) {
            selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
        } else {
            selection = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_NUM_RANGE_FROM_X_TO_Y') as string, this.from.toString(), this.to.toString());
        }

        this.state = { ...this.state, selection, status: !this.isRangeValid() ? 'Error' : 'Ok' };
    }

    public clearFilter(): void {
        this.initFilter();
    }

    public isDefaulted(): boolean {
        return this.min === this.from && this.max === this.to;
    }

    public override isDirty(firstValue: NumericRangeModel, secondValue: NumericRangeModel): boolean {
        return firstValue.from !== secondValue.from || firstValue.to !== secondValue.to;
    }

    public override getDefaultValue(): NumericRangeModel {
        return {
            from: this.min === Number.MIN_VALUE ? 0 : this.min,
            to: this.max === Number.MAX_VALUE ? 100 : this.max,
        };
    }
    //#endregion

    //#region Private methods

    private initFilter(): void {
        const defaultValue = this.getDefaultValue();
        this.from = defaultValue.from;
        this.to = defaultValue.to;
        this.updateRange();
    }

    private updateRange(): void {
        this.value = { from: Math.min(this.from, this.to), to: Math.max(this.from, this.to) };
    }

    private isRangeValid(): boolean {
        this.invalidDateRange = !this.validateInputRespectsBounds(this.from) || !this.validateInputRespectsBounds(this.to);
        return !this.invalidDateRange;
    }

    private validateInputRespectsBounds(input: number): boolean {
        return input >= this.min && input <= this.max;
    }

    //#endregion
}
