import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ThreatLevelService } from '@modules/shared/services/threat-level/threat-level.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { TextFlavor } from '@genetec/gelato';
import { ThreatLevelInfo } from '@modules/shared/api/api';
import { SafeGuid } from 'safeguid';
import { GenModalService } from '@genetec/gelato-angular';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { AnalyticsNames } from '@modules/shared/enumerations/analytics-names';
import { ThreatLevelsContext } from '@modules/shared/enumerations/analytics-threat-levels-context';
import { ConnectionAwareModalComponent } from '../../tracked/connection-aware-modal.component';
import { ThreatLevelModalComponent } from '../threat-level-modal.component';

@Component({
    selector: 'app-threat-level-confirmation-modal',
    templateUrl: './threat-level-confirmation-modal.component.html',
})
export class ThreatLevelConfirmationModalComponent extends ConnectionAwareModalComponent implements OnInit, OnDestroy {
    public selected!: ThreatLevelInfo | null;

    public readonly TextFlavor = TextFlavor;

    constructor(
        protected authService: AuthService,
        injector: Injector,
        trackingService: TrackingService,
        private threatLevelService: ThreatLevelService,
        private modalService: GenModalService,
        private analyticsService: AnalyticsService
    ) {
        super(authService, injector, trackingService);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public setThreatLevel = async (): Promise<boolean> => {
        const threatLevelId = this.selected ? toGuid(this.selected.id) : SafeGuid.EMPTY;
        this.threatLevelService.setThreatLevel(threatLevelId);
        this.analyticsService.logEvent(AnalyticsNames.Shared.ThreatLevels, {
            context: ThreatLevelsContext.ConfirmThreatLevel,
            message: `Selected Threat Level '${this.selected ? this.selected.name : 'None'}' was set`,
        });
        return Promise.resolve(true);
    };

    public openThreatLevelModal = async (): Promise<boolean> => {
        this.analyticsService.logEvent(AnalyticsNames.Shared.ThreatLevels, {
            context: ThreatLevelsContext.CancelThreatLevel,
            message: `Previously selected Threat Level '${this.selected ? this.selected.name : 'None'}' was not set`,
        });
        this.modalService.open(ThreatLevelModalComponent);
        return Promise.resolve(true);
    };
}
