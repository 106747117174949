import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid } from 'safeguid';
import { Inject, Injectable } from '@angular/core';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { GenAlertService } from '@genetec/gelato-angular';
import { Select } from '@ngxs/store';
import { MethodEmitter } from '@src/app/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { WINDOW } from '@utilities/common-helper';
import { OptionsClient } from '@modules/shared/api/api';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { SettingsService } from '../../interfaces/plugins/public/plugin-services-public.interface';
import { SecurityCenterStateService } from '../state/security-center-state.service';
import { GenAlerts } from '../../enumerations/gen-alerts';
import { UserSettingsState } from './user-settings.state';
import { Settings, SettingsMap } from './settings';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class UserSettingsService extends SecurityCenterStateService implements SettingsService {
    /* eslint-disable @typescript-eslint/unbound-method */
    @Select(UserSettingsState.settings)
    public settings$!: Observable<SettingsMap>;

    public get onCancel$(): Observable<void> {
        return this.onCancel.asObservable();
    }

    @SelectSnapshot(UserSettingsState.settings.bind(this))
    private settings!: SettingsMap;

    @SelectSnapshot(UserSettingsState.refreshOnApply.bind(this))
    private refreshOnApply!: boolean;

    public onSettingsChanged$: Observable<void>;
    private onSettingsChangedSubject = new Subject<void>();

    private optionsConfirmRefreshAlertId = GenAlerts.OptionsConfirmRefreshAlert;
    private onCancel: Subject<void> = new Subject();
    private tempSettings: SettingsMap = new Map<string, Settings>();

    constructor(
        securityCenterProvider: SecurityCenterClientService,
        private router: Router,
        private genAlertService: GenAlertService,
        @Inject(WINDOW) private window: Window,
        private optionsClient: OptionsClient
    ) {
        super(securityCenterProvider);

        // NgXs store not loaded right away after injection... Use setTimeout to prevent crash
        setTimeout(() => {
            this.settings$.pipe(untilDestroyed(this)).subscribe(() => {
                this.onSettingsChangedSubject.next();
            });
        }, 1);

        this.onSettingsChanged$ = this.onSettingsChangedSubject.asObservable();
    }

    @MethodEmitter(UserSettingsState.setSettings)
    private setSettings(settings?: SettingsMap) {}

    @MethodEmitter(UserSettingsState.setRefreshOnApply)
    private setRefreshOnApply(refreshOnApply: boolean) {}

    public async apply(): Promise<Observable<boolean>> {
        const confirmApplySubject = new Subject<boolean>();
        if (this.refreshOnApply) {
            this.genAlertService.show(this.optionsConfirmRefreshAlertId);
            const confirmRefreshAlert = this.genAlertService.getAlerts()?.find((x) => x.id === this.optionsConfirmRefreshAlertId);
            // TODO replace action: any for ModalAction once export is fixed in Gelato
            confirmRefreshAlert?.genAlertAction.pipe(untilDestroyed(this)).subscribe(async (action: any) => {
                if (action === 'default') {
                    await this.onApplyConfirmed();
                    confirmApplySubject.next(true);
                }

                confirmApplySubject.next(false);
            });
        } else {
            await this.onApplyConfirmed();

            // Wait after the subject is returned and the user subscribed
            setTimeout(() => {
                confirmApplySubject.next(true);
            });
        }

        return confirmApplySubject;
    }

    public async loadSettings(): Promise<void> {
        const options = await this.optionsClient.getOptions().toPromise();

        if (options) {
            const settingsItems: SettingsMap = new Map<string, Settings>();

            options.forEach((option) => {
                const settings = new Settings();

                settings.isAvailable = option.isAvailable;

                if (option.isAvailable) {
                    if (option.localOnly) {
                        // retrieve the local options
                        const json = this.window.localStorage.getItem(option.id.toString());
                        if (json) {
                            const fieldObject = new FieldObject();
                            fieldObject.fromJson(json);

                            for (const key of fieldObject.AllFieldsKey) {
                                settings.set(key, fieldObject.getField<any>(key));
                            }
                        }
                    } else {
                        if (option.settings && typeof option.settings === 'object') {
                            this.setNewSettings(settings, option.settings as Record<string, unknown>);
                        }
                    }
                }
                settingsItems.set(option.id.toString(), settings);
            });

            this.setSettings(settingsItems);
        }
    }

    public cancel(): void {
        this.onCancel.next();
        this.resetState();
    }

    public set(settingSectionId: IGuid, settingId: string, value: unknown, refreshOnApply: boolean = false): void {
        let settings = new Settings();
        const sectionId = settingSectionId.toString().toLowerCase();
        if (this.tempSettings.has(sectionId)) {
            settings = this.tempSettings.get(sectionId) as Settings;
        }
        settings.set(settingId, value);

        // be sure to put saved values also
        if (this.settings.has(sectionId)) {
            const savedSettings = (this.settings.get(sectionId) as Settings).clone();
            savedSettings.data.forEach((v, k) => {
                if (!settings.data.has(k)) {
                    settings.set(k, v);
                }
            });
        }
        this.tempSettings.set(sectionId, settings);

        // Specifies if the page needs to be refreshed when applying the settings
        this.setRefreshOnApply(this.refreshOnApply || refreshOnApply);
    }

    public get<T = any>(settingSectionId: IGuid, sectionId: string, lookupTemp: boolean = true): T | undefined {
        const settings = this.getSectionSettings(settingSectionId, lookupTemp);
        return settings?.get<T>(sectionId);
    }

    public getOptionAvailability(settingSectionId: IGuid): boolean {
        const settings = this.getSectionSettings(settingSectionId, false);
        return settings?.isAvailable ?? false;
    }

    public getIsRefreshRequired(): boolean {
        return this.refreshOnApply;
    }

    protected clearstate(): void {
        super.clearState();

        this.resetState();
    }

    protected async loadState(): Promise<void> {
        super.loadState();
        await this.loadSettings();
    }

    private async onApplyConfirmed() {
        const options = await this.optionsClient.getOptions().toPromise();

        if (options) {
            for (const option of options) {
                const setting = this.tempSettings.get(option.id.toString().toLowerCase());
                if (setting !== undefined) {
                    if (option.localOnly) {
                        const fieldObject = new FieldObject();

                        setting.data.forEach((value: any, key: string) => {
                            fieldObject.setField(key, value);
                        });

                        // store the local options
                        this.window.localStorage.setItem(option.id.toString(), fieldObject.toString());
                    } else {
                        const newSettings = this.getNewSettings(setting, option.settings as Record<string, unknown>);
                        await this.optionsClient.setOptions(option.id, newSettings).toPromise();
                    }
                }
            }

            // success => update (only if settings were modified)
            if (this.tempSettings.size !== 0) {
                this.setSettings(this.tempSettings);
            }

            if (this.refreshOnApply) {
                this.resetState();
                this.reloadCurrentRoute();
            } else {
                this.resetState();
            }
        }
    }

    private getSectionSettings(settingSectionId: IGuid, lookupTemp: boolean = true): Settings | undefined {
        const sectionId = settingSectionId.toString().toLowerCase();
        if (lookupTemp && this.tempSettings.has(sectionId)) {
            return (this.tempSettings.get(sectionId) as Settings).clone();
        } else {
            if (this.settings.has(sectionId)) {
                return (this.settings.get(sectionId) as Settings).clone();
            }
        }
        return undefined;
    }

    private reloadCurrentRoute() {
        const currentUrl = this.router.url;
        // give a little for the detect changes of options take effect before reloading
        setTimeout(async () => {
            await this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
                await this.router.navigateByUrl(currentUrl);
            });
        }, 1000);
    }

    private resetState() {
        this.tempSettings = new Map<string, Settings>();
        this.setRefreshOnApply(false);
    }

    private setNewSettings(settings: Settings, newSettings: Record<string, unknown>): void {
        for (const key of Object.keys(newSettings)) {
            settings.set(key, newSettings[key]);
        }
    }

    private getNewSettings(updatedSettings: Settings, settings: Record<string, unknown>): Record<string, unknown> {
        const newSettings: Record<string, unknown> = {};
        for (const key of Object.keys(settings)) {
            newSettings[key] = updatedSettings.get(key);
        }
        return newSettings;
    }
}
