// Autogenerated: v2/help/Entities/ApiAgent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:24 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { FieldObject } from '../../Helpers/FieldObject';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IAgentEntity, AgentEntityFields } from '../Interface/IAgentEntity';
import { ITransaction } from '../Interface/ITransaction';
import { IFieldObject } from '../Interface/IFieldObject';

export class AgentEntity extends Entity implements IAgentEntity {
    // #region REST Properties

    // AgentType
    public get agentType(): string {
        return this.getField<string>(AgentEntityFields.agentTypeField);
    }

    // RoleId
    public get roleId(): IGuid {
        return this.getFieldGuid(AgentEntityFields.roleIdField, ValidFlags.Specific);
    }
    public set roleId(value: IGuid) {
        this.setFieldGuid(AgentEntityFields.roleIdField, value);
    }

    // ServerId
    public get serverId(): IGuid {
        return this.getFieldGuid(AgentEntityFields.serverIdField, ValidFlags.Membership2);
    }
    public set serverId(value: IGuid) {
        this.setFieldGuid(AgentEntityFields.serverIdField, value);
    }

    // ServerInfo
    public get serverInfo(): string {
        return this.getField<string>(AgentEntityFields.serverInfoField, ValidFlags.Membership2);
    }
    public set serverInfo(value: string) {
        this.setField<string>(AgentEntityFields.serverInfoField, value);
    }

    // SpecificConfigXml
    public get specificConfigXml(): string {
        return this.getField<string>(AgentEntityFields.specificConfigXmlField, ValidFlags.Specific);
    }
    public set specificConfigXml(value: string) {
        this.setField<string>(AgentEntityFields.specificConfigXmlField, value);
    }

    // XmlData
    public get xmlData(): string {
        return this.getField<string>(AgentEntityFields.xmlDataField, ValidFlags.Specific);
    }
    public set xmlData(value: string) {
        this.setField<string>(AgentEntityFields.xmlDataField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Agents);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAgentDatabaseStatusAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'DatabaseStatus', false, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

