import { DomPortal } from '@angular/cdk/portal';
import { Component, Input } from '@angular/core';
import { ContentOverlayService } from '@shared/services/content-overlay/content-overlay.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-portal-outlet',
    templateUrl: 'portal-outlet.component.html',
    styleUrls: ['portal-outlet.component.scss'],
})
export class PortalOutletComponent {
    @Input() activatePortalOutlet = false;
    @Input() isGlobal = false;
    public portalDOM$: Observable<DomPortal<Element> | null>;

    constructor(private contentOverlayService: ContentOverlayService) {
        this.portalDOM$ = this.contentOverlayService.contentOverlay$;
    }

    enablePortalOutlet(isEnabled: boolean): void {
        this.activatePortalOutlet = isEnabled;
    }
}
