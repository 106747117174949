// Autogenerated: v2/help/Entities/ApiSystemConfiguration?codegen=ts&InterfaceOnly=False&workflow=True on 2021-08-30 10:12:20 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { EntityManager } from './EntityManager';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { ISystemConfigurationManager, IIncidentAttachedDataEntry, IIncidentAttachedKind, IIncidentData, IDisplayEntity } from '../Interface/ISystemConfigurationManager';
import { ITransaction } from '../Interface/ITransaction';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

// #region Inner classes

export class IncidentAttachedDataEntry extends FieldObject implements IIncidentAttachedDataEntry {
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // Data
    public get data(): string {
        return this.getField<string>('Data');
    }
    public set data(value: string) {
        this.setField<string>('Data', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Id', '');
        this.setField<string>('Data', '');
    }
}

export class IncidentAttachedKind extends FieldObject implements IIncidentAttachedKind {
    // Kind
    public get kind(): string {
        return this.getField<string>('Kind');
    }
    public set kind(value: string) {
        this.setField<string>('Kind', value);
    }
    // AttachedData
    public get attachedData(): ObservableCollection<IIncidentAttachedDataEntry> {
        return this.getFieldObjectArray<IncidentAttachedDataEntry, IIncidentAttachedDataEntry>(IncidentAttachedDataEntry, 'AttachedData');
    }
    public set attachedData(value: ObservableCollection<IIncidentAttachedDataEntry>) {
        this.setFieldObjectArray('AttachedData', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Kind', '');
        this.setFieldArray<IIncidentAttachedDataEntry>('AttachedData', new ObservableCollection<IIncidentAttachedDataEntry>());
    }
}

export class IncidentData extends FieldObject implements IIncidentData {
    // Guid
    public get guid(): IGuid | null {
        return this.getNullableFieldGuid('Guid');
    }
    public set guid(value: IGuid | null) {
        this.setNullableFieldGuid('Guid', value);
    }
    // AlarmInstance
    public get alarmInstance(): number | null {
        return this.getNullableField<number>('AlarmInstance');
    }
    public set alarmInstance(value: number | null) {
        this.setNullableField<number>('AlarmInstance', value);
    }
    // Category
    public get category(): string {
        return this.getField<string>('Category');
    }
    public set category(value: string) {
        this.setField<string>('Category', value);
    }
    // Data
    public get data(): string {
        return this.getField<string>('Data');
    }
    public set data(value: string) {
        this.setField<string>('Data', value);
    }
    // Event
    public get event(): string | null {
        return this.getNullableField<string>('Event');
    }
    public set event(value: string | null) {
        this.setNullableField<string>('Event', value);
    }
    // EventEntities
    public get eventEntities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('EventEntities');
    }
    public set eventEntities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('EventEntities', value);
    }
    // FormattedText
    public get formattedText(): string {
        return this.getField<string>('FormattedText');
    }
    public set formattedText(value: string) {
        this.setField<string>('FormattedText', value);
    }
    // Notes
    public get notes(): string {
        return this.getField<string>('Notes');
    }
    public set notes(value: string) {
        this.setField<string>('Notes', value);
    }
    // References
    public get references(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('References');
    }
    public set references(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('References', value);
    }
    // Source
    public get source(): IGuid | null {
        return this.getNullableFieldGuid('Source');
    }
    public set source(value: IGuid | null) {
        this.setNullableFieldGuid('Source', value);
    }
    // Template
    public get template(): IGuid | null {
        return this.getNullableFieldGuid('Template');
    }
    public set template(value: IGuid | null) {
        this.setNullableFieldGuid('Template', value);
    }
    // Timestamp
    public get timestamp(): Date | null {
        return this.getNullableFieldDate('Timestamp');
    }
    public set timestamp(value: Date | null) {
        this.setNullableFieldDate('Timestamp', value);
    }
    // Title
    public get title(): string {
        return this.getField<string>('Title');
    }
    public set title(value: string) {
        this.setField<string>('Title', value);
    }
    // Latitude
    public get latitude(): number | null {
        return this.getNullableField<number>('Latitude');
    }
    public set latitude(value: number | null) {
        this.setNullableField<number>('Latitude', value);
    }
    // Longitude
    public get longitude(): number | null {
        return this.getNullableField<number>('Longitude');
    }
    public set longitude(value: number | null) {
        this.setNullableField<number>('Longitude', value);
    }
    // AttachedData
    public get attachedData(): ObservableCollection<IIncidentAttachedKind> {
        return this.getFieldObjectArray<IncidentAttachedKind, IIncidentAttachedKind>(IncidentAttachedKind, 'AttachedData');
    }
    public set attachedData(value: ObservableCollection<IIncidentAttachedKind>) {
        this.setFieldObjectArray('AttachedData', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Guid', SafeGuid.EMPTY);
        this.setField<number>('AlarmInstance', 0);
        this.setField<string>('Category', '');
        this.setField<string>('Data', '');
        this.setField<string>('Event', '');
        this.setFieldArrayGuid('EventEntities', new ObservableCollection<IGuid>());
        this.setField<string>('FormattedText', '');
        this.setField<string>('Notes', '');
        this.setFieldArrayGuid('References', new ObservableCollection<IGuid>());
        this.setFieldGuid('Source', SafeGuid.EMPTY);
        this.setFieldGuid('Template', SafeGuid.EMPTY);
        this.setFieldDate('Timestamp', new Date());
        this.setField<string>('Title', '');
        this.setField<number>('Latitude', 0);
        this.setField<number>('Longitude', 0);
        this.setFieldArray<IIncidentAttachedKind>('AttachedData', new ObservableCollection<IIncidentAttachedKind>());
    }
}

export class DisplayEntity extends FieldObject implements IDisplayEntity {
    // Recipients
    public get recipients(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('Recipients');
    }
    public set recipients(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('Recipients', value);
    }
    // Entities
    public get entities(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('Entities');
    }
    public set entities(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('Entities', value);
    }
    // ForceDisplay
    public get forceDisplay(): boolean {
        return this.getField<boolean>('ForceDisplay');
    }
    public set forceDisplay(value: boolean) {
        this.setField<boolean>('ForceDisplay', value);
    }
    // AnnotationMessage
    public get annotationMessage(): string {
        return this.getField<string>('AnnotationMessage');
    }
    public set annotationMessage(value: string) {
        this.setField<string>('AnnotationMessage', value);
    }
    // EnableAudio
    public get enableAudio(): boolean {
        return this.getField<boolean>('EnableAudio');
    }
    public set enableAudio(value: boolean) {
        this.setField<boolean>('EnableAudio', value);
    }
    // PlaybackTime
    public get playbackTime(): Date | null {
        return this.getNullableFieldDate('PlaybackTime');
    }
    public set playbackTime(value: Date | null) {
        this.setNullableFieldDate('PlaybackTime', value);
    }

    public initializeAllFields(): void {
        this.setFieldArrayGuid('Recipients', new ObservableCollection<IGuid>());
        this.setFieldArrayGuid('Entities', new ObservableCollection<IGuid>());
        this.setField<boolean>('ForceDisplay', false);
        this.setField<string>('AnnotationMessage', '');
        this.setField<boolean>('EnableAudio', false);
        this.setFieldDate('PlaybackTime', new Date());
    }
}

// #endregion Inner classes

export class SystemConfigurationManager extends EntityManager implements ISystemConfigurationManager {
    // #region Constructor

    constructor() {
        super('v2/entities/SystemConfigurations');
    }

    // #endregion Constructor

    public async displayEntityAsync(value: IDisplayEntity, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('displayentity', 'POST', value, transaction, responseHandler);
    }

    public async addIncidentAsync(value: IIncidentData, transaction?: ITransaction): Promise<IGuid | null> {
        const responseHandler = (response: IRestResponse): Promise<IGuid> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = SafeGuid.parse((response.body['id'] as string).toLowerCase());
                return new Promise<IGuid>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<IGuid>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IGuid>('incident', 'POST', value, transaction, responseHandler);
    }

    public async setIncidentAsync(value: IIncidentData, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('incident', 'PUT', value, transaction, responseHandler);
    }

    public async deleteIncidentAsync(guid: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('incident' + '/' + guid, 'DELETE', null, transaction, responseHandler);
    }

    public async throwCustomEventAsync(id: number, message: string, entitySourceId: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('id', id);
        inParam.setField('message', message);
        inParam.setFieldGuid('entitySourceId', entitySourceId);

        return this.executeRequestTransactionAsync<IRestResponse>('throwcustomevent', 'POST', inParam, transaction, responseHandler);
    }

}

