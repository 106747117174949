import { SecurityCenterClient } from '../SecurityCenterClient';
import { FieldObject } from '../../Helpers/FieldObject';

export class ActionBase extends FieldObject {
  // #region Fields

  // #endregion

  // #region Properties

  public get recipients(): Array<string> {
    return this.getField<Array<string>>('Recipients');
  }

  public get destinationApplications(): Array<string> {
    return this.getField<Array<string>>('DestinationApplications');
  }

  public get actionType(): string {
    return this.getField<string>('ActionType');
  }

  public get timestamp(): Date {
    return this.getField<Date>('Timestamp');
  }

  public get sourceApplication(): string {
    return this.getField<string>('SourceApplication');
  }

  // #endregion

  // #region Constructor

  constructor(data: FieldObject) {
    super();
    this.loadFields(data);
  }

  // #endregion

  // region Public methods

  public static registerActions(client: SecurityCenterClient): void {
    // client.RegisterActionType(ActionTypes.SendMessage, typeof(SendMessageAction));
    // client.RegisterActionType(ActionTypes.DisplayEntity, typeof(DisplayEntityAction));
  }

  // #endregion
}
