import { Component, Inject, OnInit } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { ContentPluginComponent, Content, PluginContext } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { CommandsService, COMMANDS_SERVICE, ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { DatumVisualDirective } from '@modules/correlation/interfaces/correlation-datum';
import { CorrelationContentTypes } from '../../../enumerations/correlation-content-types';
import { CorrelationFeatures } from '../../../enumerations/correlation.features';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Component({
    selector: 'app-mobile-state-sidepane-widget',
    templateUrl: './mobile-sidepane-state-widget.component.html',
    styleUrls: ['./mobile-sidepane-state-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: MobileUserStateSidepaneWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: MobileUserStateSidepaneWidgetComponent.pluginId, priority: 1 },
    isContentSupported: (content: Content) => {
        if (content) {
            if (content.type && content.parameters) {
                if (
                    CorrelationContentTypes.datum.equals(content.type) &&
                    content.parameters?.hasField(CorrelationContentTypes.visualModel) &&
                    content.parameters?.hasField(CorrelationContentTypes.dataType)
                ) {
                    const dataTypeString = content.parameters.getField<string>(CorrelationContentTypes.dataType);
                    if (dataTypeString) {
                        if (CorrelationContentTypes.mobileUser.equals(dataTypeString)) return true;
                    }
                }
            }
        }
        return false;
    },
    requirements: {
        licenses: [KnownLicenses.correlation],
        features: [CorrelationFeatures.mobileUserDatumFeatureId],
    },
})
export class MobileUserStateSidepaneWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('39ECB286-35CB-4777-BAF6-8B384AA31D71');

    public dataContext?: PluginContext;
    public content?: Content;
    public isLoading = true;
    public isOnlineStateString = '';
    public userStateString = '';
    public isOnlineValue = false;

    public get isOnline(): boolean {
        return this.isOnlineValue;
    }
    public set isOnline(value: boolean) {
        this.isOnlineValue = value;
        this.isOnlineStateString = this.translateService.instant(value ? 'STE_LABEL_MOBILEUSER_ONLINE' : 'STE_LABEL_MOBILEUSER_OFFLINE') as string;
    }

    private userGuid: IGuid = SafeGuid.EMPTY;
    private directive!: DatumVisualDirective;

    constructor(trackingService: TrackingService, private translateService: TranslateService, @Inject(COMMANDS_SERVICE) public commandsService: CommandsService) {
        super(trackingService);
    }

    public ngOnInit() {
        super.ngOnInit();
        const parameters = this.content?.parameters;
        if (parameters?.hasField(CorrelationContentTypes.visualModel)) {
            const data = parameters.getField<string>(CorrelationContentTypes.visualModel);
            if (data) {
                const directive = JSON.parse(data) as DatumVisualDirective;
                if (directive) {
                    if (directive.Datum) {
                        this.directive = directive;
                        const online = this.directive.Datum.AdditionalParameters.IsOnline?.toLowerCase();
                        if (online && online === 'true') {
                            this.isOnline = true;
                        } else {
                            this.isOnline = false;
                        }

                        const username = this.directive.Datum.AdditionalParameters.UserName;
                        const entity = this.directive.Datum.AdditionalParameters.EntityName;
                        if (username) {
                            this.userStateString = username;
                        } else if (entity) {
                            this.userStateString = entity;
                        } else {
                            this.userStateString = this.translateService.instant('STE_LABEL_USER_UNKNOWN') as string;
                        }

                        const userGuidString = this.directive?.Datum?.AdditionalParameters.UserGuid;
                        if (userGuidString && SafeGuid.isGuid(userGuidString)) {
                            const userGuid = SafeGuid.parse(userGuidString);
                            if (!userGuid.equals(SafeGuid.EMPTY)) {
                                this.userGuid = userGuid;
                            }
                        }
                    }
                    this.isLoading = false;
                }
            }
        }
    }

    public setDataContext(context: ContentService): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
