import { Radian } from './Radian';
import { Vec3 } from './Vec3';

export class PolarVec3 {
  private readonly m_radius: number
  private readonly m_beta: Radian;
  private readonly m_delta: Radian;

  constructor(radius: number, beta: Radian, delta: Radian) {
    this.m_radius = radius;
    this.m_beta = beta;
    this.m_delta = delta;
  }

  public toString(): string {
    return `(R:${this.m_radius.toFixed(3)}, beta:${this.m_beta.toString()}, delta: ${this.m_delta.toString()})`;
  }

  public toCartesian(): Vec3 {
    const x = this.m_radius * this.m_beta.sin() * this.m_delta.cos();
    const y = this.m_radius * this.m_beta.sin() * this.m_delta.sin();
    const z = this.m_radius * this.m_beta.cos();
    return new Vec3(x, y, z);
  }
}
