import { Injectable } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { SafeGuid } from 'safeguid';
import { PluginService } from '../plugin/plugin.service';
import { AnalyticsGeneralInfo } from './analytics.service';

interface EnvelopeExtTrace {
    name: string;
}

/**
 * A class that contains telemetry initializer functions to be used by an instance of ApplicationInsights
 *
 * @class TelemetryInitializers
 */

@Injectable({ providedIn: 'root' })
export class TelemetryInitializers {
    // a regex used to capture the Guid from the url
    private readonly regexp = new RegExp(`/(.*)/task/(?<guid>[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})`, 'i');
    private readonly hostnameRegex = /(https?:\/\/)([^:/\n\r\s]+)/gi;

    constructor(private pluginService: PluginService) {}

    /**
     * A method that will add a field to the telemetry log for the name of the visited page, whenever it is a PageviewData or PageviewPerformanceData log.
     *
     * @param envelope A telemetry item containing all the information that will be sent to Application Insights servers.
     */
    public addPluginNameToEnvelope(envelope: ITelemetryItem): void {
        if (envelope.baseType === 'PageviewData' || envelope.baseType === 'PageviewPerformanceData') {
            const uri = (envelope.ext?.trace as EnvelopeExtTrace | undefined)?.name;
            const matches = typeof uri === 'string' ? this.regexp.exec(uri) : null;
            const pluginId = matches?.groups?.guid;
            if (pluginId) {
                const pluginName = this.pluginService.getPluginName(SafeGuid.parse(pluginId));
                if (envelope.data) {
                    envelope.data.pluginName = pluginName || 'N/A';
                    envelope.data.pluginId = pluginId || 'N/A';
                }
            }
        }
    }

    /**
     * A method that goes through all baseData fields and anonymizes any strings that look like an absolute URL, replacing the hostname with dashes.
     *
     * @param envelope A telemetry item containing all the information that will be sent to Application Insights servers.
     */
    public anonymizeHostNames(envelope: ITelemetryItem): void {
        if (!envelope.baseData) {
            return;
        }

        const stringValue = JSON.stringify(envelope.baseData);

        if (this.hostnameRegex.exec(stringValue)) {
            envelope.baseData = JSON.parse(stringValue.replace(this.hostnameRegex, '$1--')) as { [key: string]: any };
        }
    }

    /**
     * A method that will add general information about the app to every log. This includes the following:
     * - Security Center version
     * - Web App version
     * - Licenses
     * - License type
     *
     * @param envelope A telemetry item containing all the information that will be sent to Application Insights servers.
     */
    public addGeneralInfoToEnvelope(envelope: ITelemetryItem, info: AnalyticsGeneralInfo): void {
        envelope.data = {
            ...envelope.data,
            ...info,
        };
    }

    /**
     * A method that will filter the telemetry items sent to Application Insights.
     *
     * @param envelope A telemetry item containing all the information that will be sent to Application Insights servers.
     * @returns will return false if the telemetry item is filtered
     */
    public filter(envelope: ITelemetryItem): boolean {
        if (envelope.name.endsWith('.RemoteDependency')) {
            return false;
        }
        return true;
    }
}
