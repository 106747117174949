import { FieldObject } from './FieldObject';
import { RelationTrackerBase } from './RelationTrackerBase';
import { ITransaction } from '../Client/Interface/ITransaction';
import { IRestResponse } from '../Client/Interface/IRestResponse';
import { IFieldObject } from '../Client/Interface/IFieldObject';
import { IRestObject } from '../Client/Interface/IRestObject';

export class RelationSimpleFieldObjectTracker<T extends FieldObject, U extends IFieldObject> extends RelationTrackerBase {
    //#region Fields

    private _classType: any;
    private _data: T | null = null;
    private _updateSupported = false;

    //#endregion

    //#region Properties

    //#endregion

    //#region constructor

    constructor(classType: new () => T, parent: IRestObject, relation: string, updateSupported: boolean) {
        super(parent, relation, null, true, false);
        this._classType = classType;
        this._updateSupported = updateSupported;
    }

    //#endregion

    //#region methods

    public async getValueAsync(): Promise<T | null> {
        if (this._data == null) {
            if (this._parent.canExecuteRelations === true) {
                const response = await this._parent.executeRequestAsync('GET', this.relation, null);
                if (response.body) {
                    this._data = new this._classType() as T;
                    this._data.roParent = this._parent;
                    this._data.loadFields(response.body);
                    this.detectChanges();
                }
            }
        }
        return this._data;
    }

    public async pushTransactionAsync(transaction: ITransaction): Promise<void> {
        if (this._parent.canExecuteRelations === false) {
            this._data = new this._classType() as T;
        }

        if (this._data != null) {
            // no need for transaction since we already got the data
            // add empty entry simply to return the data
            transaction.addTransactionOperation<T | null>('', '', null, (response: IRestResponse) => {
                return new Promise((resolve, reject) => {
                    resolve(this._data);
                    return;
                });
            });
            return;
        }

        const uri = this._parent.baseUri + '/' + this.relation;

        transaction.addTransactionOperation<T | null>(uri, 'GET', null, (response: IRestResponse) => {
            return new Promise((resolve, reject) => {
                try {
                    if (response.body) {
                        this._data = new this._classType() as T;
                        this._data.roParent = this._parent;
                        this._data.loadFields(response.body);
                        this.detectChanges();
                    }
                    resolve(this._data);
                } catch (e) {
                    reject(e);
                }
            });
        });
        return;
    }

    public addOperation(verb: string, value: U | null): U | null {
        this.addRelationOperation(this._relation, verb, value == null ? null : value);
        if (verb === 'POST' && value !== null) {
            if (this._data != null) {
                // todo release
            }
            this._data = new this._classType() as T;
            this._data.roParent = this._parent;
            this._data.loadFrom(value);
            this.detectChanges();
            return this._data as unknown as U;
        }
        return value;
    }

    private detectChanges(): void {
        if (this._data === null) {
            return;
        }
        this._data.onPropertyChanged((fieldObject) => {
            if (this._updateSupported === false) {
                throw new Error('Relation is readonly');
            }
            if (this._data === null) {
                return;
            }
            const found = this.pendingOperations.firstOrDefault((x) => x.verb === 'PUT');
            if (found !== null) {
                found.body = this._data;
            } else {
                this.addRelationOperation(this._relation, 'PUT', this._data);
            }
        });
    }

    //#region
}
