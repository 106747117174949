// Decreasing priority
export enum VideoButtonsLeftBar {
    Record,
    PlayOrPause,
    JumpBack,
    JumpForward,
    Rewind,
    FastForward,
    SlowMotion,
    GoLive,
}

// Decreasing priority
export enum VideoButtonsRightBar {
    Expand,
    Bookmarks,
    SaveSnapshot,
    Audio,
    PTZControls,
}
