// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.

import { GenInputModel } from '@modules/shared/models/gen-input.model';

// ==========================================================================
export class UserLogin {
    public username: GenInputModel;
    public password: GenInputModel;

    public rememberMe?: boolean;

    constructor(username?: string, password?: string, rememberMe?: boolean) {
        this.username = new GenInputModel(username);
        this.password = new GenInputModel(password);
        this.rememberMe = rememberMe;
    }
}
