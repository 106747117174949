// Autogenerated: v2/help/Entities/ApiActiveDirectoryRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class ActiveDirectoryRoleEntityFields extends RoleEntityFields {
    public static connectionStatusField = 'ConnectionStatus';
    public static dCField = 'DC';
    public static defaultBadgeTemplateField = 'DefaultBadgeTemplate';
    public static defaultCardFormatField = 'DefaultCardFormat';
    public static domainField = 'Domain';
    public static externalAddressField = 'ExternalAddress';
    public static maxPictureSizeField = 'MaxPictureSize';
    public static maxPictureSizeLimitField = 'MaxPictureSizeLimit';
    public static netBiosNameField = 'NetBiosName';
    public static partitionGuidField = 'PartitionGuid';
    public static passwordField = 'Password';
    public static statusField = 'Status';
    public static uploadPicturesToActiveDirectoryField = 'UploadPicturesToActiveDirectory';
    public static useApplicationCredentialsField = 'UseApplicationCredentials';
    public static useDCField = 'UseDC';
    public static useGlobalCatalogField = 'UseGlobalCatalog';
    public static usernameField = 'Username';
    public static useSSLConnectionField = 'UseSSLConnection';
}

// #endregion Fields

// #region Inner classes

export interface IImportedGroup extends IFieldObject {
    groupAccountGuid: IGuid;
    groupAccountName: string;
    synchronizeCardholders: boolean | null;
    synchronizeCredentials: boolean | null;
    synchronizeUserGroup: boolean | null;
    synchronizeUsers: boolean | null;
}

// #endregion Inner classes

export interface IActiveDirectoryRoleEntity extends IRoleEntity {
    // #region REST Properties

    readonly connectionStatus: string;

    dC: string;

    defaultBadgeTemplate: IGuid;

    defaultCardFormat: IGuid;

    domain: string;

    externalAddress: string;

    maxPictureSize: number;

    maxPictureSizeLimit: number;

    netBiosName: string;

    partitionGuid: IGuid;

    password: string;

    readonly status: string;

    uploadPicturesToActiveDirectory: boolean;

    useApplicationCredentials: boolean;

    useDC: boolean;

    useGlobalCatalog: boolean;

    username: string;

    useSSLConnection: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getImportedGroupsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IImportedGroup> | null>;
    triggerSynchronizationAsync(transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

