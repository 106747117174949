// Autogenerated: v2/help/Entities/ApiAccessPointRule?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class AccessPointRuleEntityFields extends EntityFields {
    public static accessPointRuleTypeField = 'AccessPointRuleType';
    public static defaultRuleField = 'DefaultRule';
    public static endTimeField = 'EndTime';
    public static positiveField = 'Positive';
    public static scheduleField = 'Schedule';
    public static startTimeField = 'StartTime';
}

// #endregion Fields

export interface IAccessPointRuleEntity extends IEntity {
    // #region REST Properties

    readonly accessPointRuleType: string;

    defaultRule: boolean;

    endTime: Date;

    positive: boolean;

    schedule: IGuid;

    startTime: Date;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointGroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getCardholdersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

