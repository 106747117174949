// Autogenerated: v2/help/Entities/ApiEntity?codegen=ts&InterfaceOnly=True&workflow=False on 2022-07-12 11:51:55 AM
import { IGuid } from 'safeguid';
import { ITransaction } from './ITransaction';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IRestResponse } from './IRestResponse';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { IRestObject } from './IRestObject';
import { SecurityCache } from '../Model/SecurityCache';

// #region Fields

export class EntityFields {
    public static canDiagnoseField = 'CanDiagnose';
    public static creationTimeField = 'CreationTime';
    public static customIconIdField = 'CustomIconId';
    public static defaultPartitionField = 'DefaultPartition';
    public static descriptionField = 'Description';
    public static entityTypeField = 'EntityType';
    public static federationLogicalParentField = 'FederationLogicalParent';
    public static hiddenFromUIField = 'HiddenFromUI';
    public static idField = 'Id';
    public static isCopyConfigSupportedField = 'IsCopyConfigSupported';
    public static isDeletableField = 'IsDeletable';
    public static isFederatedField = 'IsFederated';
    public static isImportedFromExternalSystemField = 'IsImportedFromExternalSystem';
    public static isLogicalEntityField = 'IsLogicalEntity';
    public static isOwnedByPluginField = 'IsOwnedByPlugin';
    public static isReadOnlyField = 'IsReadOnly';
    public static isSynchronizedFromPluginField = 'IsSynchronizedFromPlugin';
    public static isSynchronizingField = 'IsSynchronizing';
    public static isVirtualChildField = 'IsVirtualChild';
    public static localNameField = 'LocalName';
    public static logicalIdField = 'LogicalId';
    public static maintenanceField = 'Maintenance';
    public static maintenanceEndTimeField = 'MaintenanceEndTime';
    public static maintenanceReasonField = 'MaintenanceReason';
    public static maxNameLengthField = 'MaxNameLength';
    public static nameField = 'Name';
    public static nameDependsOnOtherEntityField = 'NameDependsOnOtherEntity';
    public static networkParentField = 'NetworkParent';
    public static originalDirectoryField = 'OriginalDirectory';
    public static originalIdField = 'OriginalId';
    public static originalNameField = 'OriginalName';
    public static ownerMasterApplicationField = 'OwnerMasterApplication';
    public static ownerMasterRoleField = 'OwnerMasterRole';
    public static ownerMasterRoleTypeField = 'OwnerMasterRoleType';
    public static ownerMasterRoleTypeIdField = 'OwnerMasterRoleTypeId';
    public static runningStateField = 'RunningState';
    public static sharedField = 'Shared';
    public static sharedOverrideField = 'SharedOverride';
    public static supportsMembershipField = 'SupportsMembership';
    public static synchronisedField = 'Synchronised';
}

// #endregion Fields

// #region Inner classes

export interface IHealthEvent extends IFieldObject {
    healthEventType: string;
    timestamp: Date;
    description: string;
    timezone: string;
    observerGuid: IGuid;
    observerSourceType: string;
    observerServerName: string;
}

export interface IEntityNode extends IFieldObject {
    id: IGuid;
    name: string;
    entityType: string;
    areas: ObservableCollection<IEntityNode>;
}

export interface IFileCacheItem extends IFieldObject {
    data: string;
    context: string;
    localFilePath: string;
    relatedEntityGuid: IGuid;
    fileGuid: IGuid;
    fileLength: number;
    lastAccessTimeUtc: Date;
    extension: string;
}

export interface IDiagnostic extends IFieldObject {
    result: string;
    message: string;
    details: string;
}

export interface ICustomIcon extends IFieldObject {
    guid: IGuid;
    dataSmall: string;
    dataLarge: string;
}

export interface ICustomField extends IFieldObject {
    id: IGuid;
    name: string;
    value: string;
}

// #endregion Inner classes

export interface IEntity extends IRestObject {
    // #region REST Properties

    readonly canDiagnose: boolean;

    readonly creationTime: Date;

    readonly customIconId: IGuid;


    description: string;

    entityType: string;

    readonly federationLogicalParent: IGuid;

    hiddenFromUI: boolean;

    readonly id: IGuid;

    readonly isCopyConfigSupported: boolean;

    readonly isDeletable: boolean;

    readonly isFederated: boolean;

    readonly isImportedFromExternalSystem: boolean;

    readonly isLogicalEntity: boolean;

    readonly isOwnedByPlugin: boolean;

    readonly isReadOnly: boolean;

    readonly isSynchronizedFromPlugin: boolean;

    isSynchronizing: boolean;

    readonly isVirtualChild: boolean;

    readonly localName: string;

    logicalId: number | null;

    maintenance: boolean;

    readonly maintenanceEndTime: Date;

    readonly maintenanceReason: string;

    readonly maxNameLength: number;

    name: string;

    readonly nameDependsOnOtherEntity: boolean;

    readonly networkParent: IGuid;

    originalDirectory: string;

    originalId: IGuid;

    originalName: string;

    ownerMasterApplication: IGuid;

    ownerMasterRole: IGuid;

    readonly ownerMasterRoleType: string;

    readonly ownerMasterRoleTypeId: IGuid;

    runningState: string;

    shared: boolean;

    readonly sharedOverride: boolean;

    readonly supportsMembership: boolean;

    readonly synchronised: boolean;

    // #endregion REST Properties

    // #region Properties

    securityCache: SecurityCache | undefined;

    // #endregion Properties

    // #region REST Methods

    getAreasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getCopyConfigDataTemplateAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;
    getCustomFieldValuesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomField> | null>;
    getCustomIconAsync(transaction?: ITransaction | null): Promise<ICustomIcon | null>;
    getCustomIconsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomIcon> | null>;
    getDiagnosticsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDiagnostic> | null>;
    enableMaintenanceAsync(endTime: Date, reason: string, transaction?: ITransaction): Promise<IRestResponse | null>;
    getEntityPrivilegesAsync(transaction?: ITransaction): Promise<Array<IGuid> | null>;
    deleteFilesCacheAsync(id: IGuid, transaction?: ITransaction | null): Promise<IRestResponse>;
    getFilesCacheAsync(id: IGuid, transaction?: ITransaction | null): Promise<IFileCacheItem | null>;
    addFilesCacheAsync(id: IGuid, value: IFileCacheItem, transaction?: ITransaction | null): Promise<IFileCacheItem | null>;
    getInformationProvidersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getLinkedMapsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getPartitionsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getRecursiveParentAreaAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEntityNode> | null>;
    sendHealthEventsAsync(value: Array<IHealthEvent>, transaction?: ITransaction): Promise<IRestResponse | null>;
    sendRequestAsync(value: IFieldObject, transaction?: ITransaction): Promise<string | null>;

    // #endregion REST Methods

    // #region Custom Methods

    hasPrivilegesAsync(privileges: Set<IGuid>, transaction?: ITransaction): Promise<boolean | null>;

    isOfType<T extends IEntity>(classType: new () => T): this is T;

    // #endregion Custom Methods

}

