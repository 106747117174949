import { PromiseSimpleEventDispatcher } from './strongly-typed-events/promise-simple-events/PromiseSimpleEventDispatcher';
import { SimpleEventDispatcher } from './strongly-typed-events/simple-events/SimpleEventDispatcher';
import { SignalDispatcher } from './strongly-typed-events/signals/SignalDispatcher';
import { EventDispatcher as StronglyTypedEventDispatcher } from './strongly-typed-events/events/EventDispatcher';
import { PromiseSignalDispatcher } from './strongly-typed-events/primise-signal/PromiseSignalDispatcher';
import { PromiseEventDispatcher } from './strongly-typed-events/promise-events/PromiseEventDispatcher';

export type Handler<TArgs> = (args: TArgs) => void;
export type AsyncHandler<TArgs> = (args: TArgs) => Promise<void>;
export type Signal = () => void;

export class SenderEventDispatcher<TSender, TArgs> extends StronglyTypedEventDispatcher<TSender, TArgs> {}

export class AsyncSenderEventDispatcher<TSender, TArgs> extends PromiseEventDispatcher<TSender, TArgs> {}

export class EventDispatcher<TArgs> extends SimpleEventDispatcher<TArgs> {}

export class AsyncEventDispatcher<TArgs> extends PromiseSimpleEventDispatcher<TArgs> {}

export class SignalEventDispatcher extends SignalDispatcher {}

export class AsyncSignalEventDispatcher extends PromiseSignalDispatcher {}

export class CancellationToken {
    public isCancellationRequested: boolean;
    public timeout: number;
    private _cancelDispatcher = new SignalEventDispatcher();

    constructor(timeout = -1) {
        this.isCancellationRequested = false;
        this.timeout = timeout;
        if (timeout !== -1) {
            setTimeout((e: any) => {
                this.isCancellationRequested = true;
                this._cancelDispatcher.dispatch();
            }, timeout);
        }
    }

    public cancelToken() {
        this.isCancellationRequested = true;
        this._cancelDispatcher.dispatch();
    }

    public onCancel(handler: Signal): () => void {
        return this._cancelDispatcher.subscribe(handler);
    }
}

export function sleepAsync(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isNullOrEmpty(value: string) {
    return value === null || value.length === 0;
}

export class Deferred<T> {
    public promise: Promise<T>;
    public resolve: any;
    public reject: any;

    constructor(autoCompleteData?: any, success?: boolean) {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            if (autoCompleteData !== undefined) {
                if (success === undefined || success === true) {
                    resolve(autoCompleteData);
                } else {
                    reject(autoCompleteData);
                }
            }
        });
    }

    public reset() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}
