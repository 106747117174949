import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 1989-2018 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export class GrantedPrivilegesCache {
    public grantedPrivileges = SafeGuid.createSet();
    public lastTouch: Date;

    constructor(privileges: Set<IGuid>) {
        this.grantedPrivileges = privileges;
        this.lastTouch = new Date();
    }

    public touch() {
        this.lastTouch = new Date();
    }
}

/// <summary>
/// Cache use to keep granted privileges per entity
/// </summary>
export class SecurityCache {
    private readonly cleanuptTimeout = 60000 * 60; // 1 hour
    private timeoutHandle: number;

    private _grantedPrivileges = SafeGuid.createMap<GrantedPrivilegesCache>();

    constructor() {
        this.timeoutHandle = window.setTimeout(this.cleanup, this.cleanuptTimeout);
        this.cleanup();
    }

    /// <summary>
    /// Insert the specified data in the cache
    /// </summary>
    /// <param name="entity">entity</param>
    /// <param name="grantedPrivileges">The granted privileges</param>
    public insert( entity: IGuid, grantedPrivileges: Set<IGuid>): boolean {
        return this.keep(entity, grantedPrivileges);
    }

    /// <summary>
    /// Retrieve the granted privileges from the cache for the specified entity
    /// </summary>
    /// <param name="entity">Entity</param>
    /// <param name="grantedPrivileges">The privileges associated to this entity</param>
    /// <returns>Granted privileges</returns>
    public tryGet(entity: IGuid, grantedPrivileges: Set<IGuid>): Set<IGuid> | undefined {
        if (entity.isEmpty() || !this._grantedPrivileges.has(entity)) {
            return undefined;
        }

        const cache = this._grantedPrivileges.get(entity);
        if (!cache) {
            return undefined;
        }

        cache.touch();
        return cache.grantedPrivileges;
    }

    public clear() {
        this._grantedPrivileges.clear();
    }

    private cleanup() {
        const keepTime = new Date().getTime() - this.cleanuptTimeout;
        const keysToClear: IGuid[] = [];

        for ( const entityId of this._grantedPrivileges.keys()) {
            const privilege = this._grantedPrivileges.get(entityId);
            if (privilege) {
                // if old enough clear !
                if (privilege.lastTouch.getTime() < keepTime) {
                    keysToClear.push(entityId);
                }
            }
        }

        keysToClear.forEach(item => this._grantedPrivileges.delete(item));

        this.timeoutHandle = window.setTimeout(this.cleanup, this.cleanuptTimeout);
    }

    private keep(entity: IGuid, grantedPrivileges: Set<IGuid>, forceOverwrite: boolean = false) {
        let exists = this._grantedPrivileges.has(entity);
        if (exists && forceOverwrite) {
            this._grantedPrivileges.delete(entity);
            exists = false;
        }

        if (!exists) {
            this._grantedPrivileges.set(entity, new GrantedPrivilegesCache(grantedPrivileges));
            return true;
        }

        return false;
    }
}
