import { RestObject } from '../Client/Model/RestObject';
import { ISecurityCenterClient } from '../Client/Interface/ISecurityCenterClient';
import { RelationGuidTracker } from './RelationGuidTracker';
import { IQueryFilter } from '../Client/Interface/IQueryFilter';
import { IEntity } from '../Client/Interface/IEntity';

export class RelationGuidTemplateTracker<T extends IEntity> extends RelationGuidTracker {
    //#region Fields

    private _classType: any;

    //#endregion

    //#region Properties

    //#endregion

    //#region constructor

    constructor(classType: new () => T, client: ISecurityCenterClient, parent: RestObject, relation: string, itemKey: string, readOnly: boolean, query?: IQueryFilter | null) {
        super(client, parent, relation, itemKey, readOnly, query);
        this._classType = classType;
    }

    //#endregion

    //#region methods

    public createEntityModel(config: object): IEntity | null {
        return this._client.createEntityModel(this._classType, config);
    }

    //#region
}
