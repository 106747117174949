// Autogenerated: v2/help/Entities/ApiVideoUnit?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:09 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IUnitEntity, UnitEntityFields } from './IUnitEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class VideoUnitEntityFields extends UnitEntityFields {
    public static authenticationSchemeField = 'AuthenticationScheme';
    public static certificateValidationInfoField = 'CertificateValidationInfo';
    public static commandPortField = 'CommandPort';
    public static connectionAddressField = 'ConnectionAddress';
    public static dhcpField = 'Dhcp';
    public static discoveryPortField = 'DiscoveryPort';
    public static firmwareUpgradePortField = 'FirmwareUpgradePort';
    public static firstAddFeatureSetField = 'FirstAddFeatureSet';
    public static gatewayField = 'Gateway';
    public static hardwareVersionField = 'HardwareVersion';
    public static hostNameField = 'HostName';
    public static isRestrictedUnitField = 'IsRestrictedUnit';
    public static lastPasswordChangeField = 'LastPasswordChange';
    public static legacyConfigurationsField = 'LegacyConfigurations';
    public static passwordStrengthField = 'PasswordStrength';
    public static prefixLengthField = 'PrefixLength';
    public static proxyIdentifierField = 'ProxyIdentifier';
    public static secureConnectionField = 'SecureConnection';
    public static subnetMaskField = 'SubnetMask';
    public static supportsAudioField = 'SupportsAudio';
    public static supportsChangeCommandPortField = 'SupportsChangeCommandPort';
    public static supportsChangeDiscoveryPortField = 'SupportsChangeDiscoveryPort';
    public static supportsChangeIPField = 'SupportsChangeIP';
    public static supportsFirmwareUpgradeField = 'SupportsFirmwareUpgrade';
    public static supportsIdentifyField = 'SupportsIdentify';
    public static supportsRebootField = 'SupportsReboot';
    public static supportsSSLField = 'SupportsSSL';
    public static useDefaultCredentialsField = 'UseDefaultCredentials';
    public static webPageField = 'WebPage';
}

// #endregion Fields

// #region Inner classes

export interface IPingSubResult extends IFieldObject {
    authenticationScheme: string;
    authenticationSuccesful: boolean;
    httpConnectionSuccessful: boolean;
    httpSupported: boolean;
    pingDelay: number;
    pingFailureReason: string;
    pingSuccessful: boolean;
    serverId: IGuid;
    tcpConnectionEndPoint: string;
    tcpConnectionSuccessful: boolean;
    tcpSupported: boolean;
}

export interface IPingResult extends IFieldObject {
    executed: boolean;
    pingFailureReason: string;
    results: ObservableCollection<IPingSubResult>;
}

// #endregion Inner classes

export interface IVideoUnitEntity extends IUnitEntity {
    // #region REST Properties

    authenticationScheme: number;

    certificateValidationInfo: string;

    commandPort: number;

    connectionAddress: string;

    dhcp: boolean;

    discoveryPort: number;

    firmwareUpgradePort: number;

    firstAddFeatureSet: string;

    gateway: string;

    hardwareVersion: string;

    hostName: string;

    readonly isRestrictedUnit: boolean;

    readonly lastPasswordChange: Date;

    legacyConfigurations: string;

    readonly passwordStrength: string;

    prefixLength: number;

    proxyIdentifier: string;

    secureConnection: boolean;

    subnetMask: string;

    readonly supportsAudio: boolean;

    readonly supportsChangeCommandPort: boolean;

    readonly supportsChangeDiscoveryPort: boolean;

    readonly supportsChangeIP: boolean;

    readonly supportsFirmwareUpgrade: boolean;

    readonly supportsIdentify: boolean;

    readonly supportsReboot: boolean;

    readonly supportsSSL: boolean;

    useDefaultCredentials: boolean;

    webPage: string;

    // #endregion REST Properties

    // #region REST Methods

    getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    identifyAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    pingAsync(transaction?: ITransaction): Promise<IPingResult | null>;
    reconnectAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getSpecificConfigAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;

    // #endregion REST Methods

}

