import { Component, OnInit } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SafeGuid } from 'safeguid';
import { ImageFit, ImageFlavor } from '@genetec/gelato';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';
import { ContentFields } from '../../../enumerations/content-fields';
import { CardholderWidgetBaseComponent } from '../cardholder-widget-base.component';

const isContentSupported = (content: Content): boolean => {
    if (!content.contextContent && content.type.equals(AccessControlContentTypes.Cardholder)) {
        if (content.parameters?.hasField(ContentFields.CardholderPicture) === true) {
            const pictureId = content.parameters?.getFieldGuid(ContentFields.CardholderPicture);
            return !pictureId.isEmpty();
        }
    }
    return false;
};

@InternalContentPluginDescriptor({
    type: CardholderPictureWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CardholderPictureWidgetComponent.pluginId, priority: 1 },
    isContentSupported,
})
@Component({
    selector: 'app-cardholder-picture-widget',
    templateUrl: './cardholder-picture-widget.component.html',
    styleUrls: ['./cardholder-picture-widget.component.scss'],
})
export class CardholderPictureWidgetComponent extends CardholderWidgetBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('87D91CAF-9455-49CC-B324-A3876E9E3A5D');

    public readonly ImageFlavor = ImageFlavor;
    public readonly ImageFit = ImageFit;

    public cardholderPicture: string | undefined = undefined;

    constructor(securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService) {
        super(securityCenterClientService, trackingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();
        this.isLoading = false;
    }

    protected async initializeCardholerInfo(): Promise<void> {
        await super.initializeCardholerInfo();

        if (this.cardholder && this.content) {
            const pictureId = this.content.parameters?.getFieldGuid(ContentFields.CardholderPicture);
            if (pictureId && !pictureId.isEmpty()) {
                const fileCache = await this.cardholder.getFilesCacheAsync(pictureId);
                if (fileCache !== null) {
                    this.cardholderPicture = 'data: image/png;base64,' + fileCache.data;
                }
            }
        }
    }
}
