import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SharedCommands } from '../../shared/enumerations/shared-commands';
import { CommandBindings, CommandsService, COMMANDS_SERVICE, CommandsSubscription } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Injectable({
    providedIn: 'root',
})
export class VideoDiagnosticService implements OnDestroy {
    public get debugOverlayActivated$(): Observable<boolean> {
        return this.debugOverlayActivated.asObservable();
    }

    private commandsSubscription!: CommandsSubscription;

    private debugOverlayActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private debugOverlay = false;

    constructor(@Inject(COMMANDS_SERVICE) private commandsService: CommandsService) {
        if (this.commandsService) {
            const bindings = new CommandBindings();
            bindings.addCommand({
                commandId: SharedCommands.ShowVideoDiagnostics,
                executeCommandHandler: (executeCommandData) => {
                    this.debugOverlay = !this.debugOverlay;
                    this.debugOverlayActivated.next(this.debugOverlay);
                    executeCommandData.isHandled = true;
                },
            });

            this.commandsSubscription = this.commandsService.subscribe(bindings, {
                priority: 200,
            });
        }
    }

    public ngOnDestroy(): void {
        if (this.commandsSubscription) {
            this.commandsSubscription.unsubscribe();
        }
    }

    public isDebugOverlayActivated(): boolean {
        return this.debugOverlayActivated.value;
    }
}
