import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, ItemSlot } from '@genetec/gelato';
import { Color, ContextMenuPosition, ContextMenuSize, GenMeltedItem, GenMenu, GenModalService, GenToastService, MeltedIcon, ToastFlavor } from '@genetec/gelato-angular';
import { DateFilterMode } from '@modules/shared/components/filters/common-filters/filter-date/enums/date-filter-mode.enum';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { NavigationService } from '@modules/shared/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import moment from 'moment';
import { Label, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import { EntityFields, IEntity } from 'RestClient/Client/Interface/IEntity';
import { Entity } from 'RestClient/Client/Model/Entity';
import { SafeGuid } from 'safeguid';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { UnifiedReportFeatureFlags } from '@modules/correlation/feature-flags';
import {
    AggregationChartable,
    AggregationFieldType,
    AggregationGrouping,
    AggregationQueryFilter,
    AggregationType,
    ApiException,
    CorrelationClient,
    ExtendedSearchParameterType,
    GeoCoordinate,
    GroupAggregationResult,
    LightCorrelationSearchParameter,
    LightDataSourceRegistration,
    ParameterComparison,
    WhereMode,
} from '../../../api/api';
import { CorrelationFeatures } from '../../../enumerations/correlation.features';
import { CorrelationService } from '../../../services/correlation.service';
import { Breadcrumb, BreadcrumbsComponent } from '../../general/breadcrumbs/breadcrumbs.component';
import { WhatFilter, WhenFilter, WhereFilter } from '../../investigate/services/investigate-data.interfaces';
import { InvestigateDataContext } from '../../investigate/services/investigate-data.context';
import { InvestigateTaskComponent } from '../../investigate/task/investigate-task.component';
import { CorrelationSearchParameter } from '../../parameters-modal/correlation-search-parameter';
import { AggregationWidgetSettingsModalComponent } from '../aggregation-widget-settings-modal/aggregation-widget-settings-modal.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface ChartColor {
    backgroundColor?: string | string[];
    borderWidth?: number | number[];
    borderColor?: string | string[];
    borderCapStyle?: string;
    borderDash?: number[];
    borderDashOffset?: number;
    borderJoinStyle?: string;
    pointBorderColor?: string | string[];
    pointBackgroundColor?: string | string[];
    pointBorderWidth?: number | number[];
    pointRadius?: number | number[];
    pointHoverRadius?: number | number[];
    pointHitRadius?: number | number[];
    pointHoverBackgroundColor?: string | string[];
    pointHoverBorderColor?: string | string[];
    pointHoverBorderWidth?: number | number[];
    pointStyle?: string | string[];
    hoverBackgroundColor?: string | string[];
    hoverBorderColor?: string | string[];
    hoverBorderWidth?: number;
}

@Component({
    selector: 'app-aggregation-widget',
    templateUrl: './aggregation-widget.component.html',
    styleUrls: ['./aggregation-widget.component.scss'],
    providers: [InvestigateDataContext],
})
@InternalPluginDescriptor({
    type: AggregationDashboardWidgetComponent,
    pluginTypes: [PluginTypes.Dashboard],
    exposure: { id: AggregationDashboardWidgetComponent.pluginId, icon: MeltedIcon.BarLineChart, title: 'STE_LABEL_AGGREGATION' },
    isSupported: () => true,
    requirements: {
        licenses: [KnownLicenses.correlation],
        features: [CorrelationFeatures.aggregationWidgetFeatureId],
        enabledFeatureFlags: [UnifiedReportFeatureFlags.General],
    },
})
export class AggregationDashboardWidgetComponent implements OnInit {
    //#region Constants

    public static pluginId = SafeGuid.parse('58e8bd9d-91bc-49fe-ae7c-a23b8283a9ab');
    // 'bubble', 'scatter' <- not supported by this widget
    public static readonly knownChartingTypes: string[] = ['line', 'bar', 'horizontalBar', 'radar', 'doughnut', 'polarArea', 'pie'];

    private static readonly excludedAggregationDrillDownOptions = [AggregationGrouping.None, AggregationGrouping.Custom];

    private static readonly drillNoFurther = [AggregationGrouping.Month, AggregationGrouping.Day, AggregationGrouping.Hour, AggregationGrouping.Minute];

    //#endregion

    //#region Properties

    @ViewChild(BreadcrumbsComponent)
    public breadcrumbsComponent!: BreadcrumbsComponent<AggregationQueryFilter>;

    @ViewChild('contextMenu') public contextMenu!: GenMenu;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly ContextMenuSize = ContextMenuSize;
    public readonly ContextMenuPosition = ContextMenuPosition;
    public readonly ItemSlot = ItemSlot;

    public results: AggregationChartable[] = [];
    public baseFilter: AggregationQueryFilter | null = null;
    public breadcrumbs: Breadcrumb<AggregationQueryFilter>[] = [];
    public home!: Breadcrumb<AggregationQueryFilter>;
    public get filter(): AggregationQueryFilter | null {
        if (this.currentFilterPointer !== null) {
            return this.currentFilterPointer;
        }
        return this.baseFilter;
    }

    public chartOptions: ChartOptions = {
        responsive: true,
        onClick: (event?: MouseEvent, activeElements?: []) => this.onElementClick(event, activeElements),
    };
    public chartLegend = false;
    public chartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [];
    public chartLabels: Label[] = [];
    public chartData: ChartDataSets[] = [];
    public chartColors: ChartColor[] = [
        {
            backgroundColor: Object.values(Color)
                .filter((item) => item !== Color.Transparent)
                .shuffle(),
        },
    ];

    public chartTypes: GenMeltedItem[] = [];
    // default to bar chart
    public selectedChartType: GenMeltedItem | undefined = undefined;
    public get chartType(): ChartType {
        if (this.selectedChartType) {
            return this.selectedChartType.id as ChartType;
        }
        return 'bar';
    }

    // context menu
    public contextMenuPositionX = 0;
    public contextMenuPositionY = 0;
    public contextMenuItemsSource: ContextMenuItem[] = [];
    public contextMenuOpened = false;

    // private properties
    private currentFilterPointer: AggregationQueryFilter | null = null;
    private localPopupTarget: AggregationChartable | null = null;

    //#endregion

    //#region Constructors

    constructor(
        private correlationClient: CorrelationClient,
        private toastService: GenToastService,
        private modalService: GenModalService,
        private translateService: TranslateService,
        private correlationService: CorrelationService,
        private securityCenterClientService: SecurityCenterClientService,
        private navigationService: NavigationService,
        private injector: Injector
    ) {}

    //#endregion

    //#region Static Methods

    private static getChartingIcon(chartType: string): MeltedIcon {
        switch (chartType) {
            case 'line':
                return MeltedIcon.LineChart;
            case 'bar':
                return MeltedIcon.BarChart;
            case 'horizontalBar':
                return MeltedIcon.BarChart;
            case 'radar':
                return MeltedIcon.PieChart;
            case 'doughnut':
                return MeltedIcon.PieChart;
            case 'polarArea':
                return MeltedIcon.AreaChart;
            case 'bubble':
                return MeltedIcon.AreaChart;
            case 'pie':
                return MeltedIcon.PieChart;
            case 'scatter':
                return MeltedIcon.Charts;
        }
        return MeltedIcon.None;
    }

    //#endregion

    //#region Public Methods

    ngOnInit() {
        // Setup a fake filter, with some data for a 4hr window
        this.baseFilter = new AggregationQueryFilter();

        this.baseFilter.dataTypes.push(SafeGuid.parse('8957b022-4b9b-4756-982c-4b449ceeb08d')); // arrest
        this.baseFilter.dataTypes.push(SafeGuid.parse('bee80f95-d6a1-4b12-9ef8-f9d1b21c8e7a')); // sex offenders
        this.baseFilter.dataTypes.push(SafeGuid.parse('409a3c91-7d2c-4c8b-b622-88c663f7aa74')); // gun offenders
        //this.baseFilter.dataTypes.push('4e4fb526-7dbd-42b9-bca1-f955d5aeab3f'); // alarms
        //this.baseFilter.dataTypes.push('e205ee2d-c132-43ec-b52a-10401ba294d8'); // building

        this.baseFilter.aggregationType = AggregationType.Average;
        this.baseFilter.aggregationGroup = AggregationGrouping.DataType;
        this.baseFilter.aggregationFieldType = AggregationFieldType.Custom;
        this.baseFilter.customAggregationField = 'Age';
        this.baseFilter.referenceTimeUtc = moment().utc().toISOString();
        //this.baseFilter.timeWindow = "4.00:00:00";
        this.baseFilter.timeWindow = null;
        this.baseFilter.radius = null;
        this.baseFilter.additionalParameters = null;
        this.baseFilter.centerPoint = new GeoCoordinate({ latitude: 0, longitude: 0 });

        this.home = {
            selected: true,
            icon: MeltedIcon.Home,
            crumb: this.baseFilter,
            text: '',
        };

        // Initialize strings which need to be translated
        this.chartTypes = AggregationDashboardWidgetComponent.knownChartingTypes.map((chartType) => {
            return {
                id: chartType,
                text: this.translateService.instant('STE_LABEL_CHARTTYPE_' + chartType.toUpperCase()) as string,
                icon: AggregationDashboardWidgetComponent.getChartingIcon(chartType),
            };
        });
        this.selectedChartType = this.chartTypes.find((chart) => chart.id === 'bar');

        // initial query
        void this.query();
    }

    //#endregion

    //#region Event Handlers

    public async onSettingsClick(): Promise<void> {
        this.hideContextMenu();
        const modal = this.modalService.open(AggregationWidgetSettingsModalComponent, undefined);
        if (modal) {
            await modal.refresh(this.baseFilter);
            const promise = new Promise<AggregationQueryFilter | undefined>((resolve) => {
                modal.returnResult = resolve;
            });
            const result = await promise;
            if (result) {
                this.baseFilter = result;
                this.home = {
                    selected: true,
                    crumb: this.baseFilter,
                    icon: MeltedIcon.Home,
                    text: '',
                };
                await this.refresh();
            }
        }
    }

    public onOpenReport(): void {
        this.hideContextMenu();
        const currentFilter = this.filter;
        const filterDataService = this.injector.get(InvestigateDataContext);
        if (filterDataService && this.navigationService && currentFilter) {
            const parameters = currentFilter.additionalParameters?.map(CorrelationSearchParameter.ofLightParameter) ?? [];
            for (const parameter of parameters) {
                void parameter.setDisplayTextAuto(this.injector, undefined);
            }

            const what: WhatFilter = {
                dataTypes: currentFilter.dataTypes,
                description: '',
                parameters,
            };
            const when: WhenFilter | null = {
                description: '',
                mode: DateFilterMode.Range,
                period: null,
                timeStart: currentFilter.timeWindow === null ? null : moment.utc(currentFilter.referenceTimeUtc).subtract(currentFilter.timeWindow).toISOString(),
                timeEnd: currentFilter.timeWindow === null ? null : moment.utc(currentFilter.referenceTimeUtc).add(currentFilter.timeWindow).toISOString(),
            };
            // default "wildcard" where filter
            let where: WhereFilter = {
                includedAreas: null,
                excludedAreas: null,
                description: '',
                intersection: false,
                mode: WhereMode.Anywhere,
                isValid: true,
                wkt: null,
            };
            // if there is a region parameter, set the physical filter
            const regionFilter = currentFilter.additionalParameters?.find((item) => item.valueType === ExtendedSearchParameterType.Region);
            if (regionFilter) {
                where = {
                    includedAreas: null,
                    excludedAreas: null,
                    description: '',
                    intersection: regionFilter.comparison !== ParameterComparison.In ? false : true,
                    mode: WhereMode.Physical,
                    isValid: regionFilter.value !== null,
                    wkt: regionFilter.value ?? '',
                };
            }

            filterDataService.setWhatFilter(what);
            filterDataService.setWhenFilter(when);
            filterDataService.setWhereFilter(where);
            // navigate to the investigate task with the auto-query flag = true
            void this.navigationService.navigate(`/task/${InvestigateTaskComponent.pluginId.toString()}?query=true`);
        }
    }

    public async refresh(): Promise<void> {
        this.hideContextMenu();
        this.breadcrumbs = [];
        this.currentFilterPointer = this.baseFilter;
        await this.query();
    }

    public onSelectedChartTypeChanged(selection?: GenMeltedItem | undefined): void {
        this.hideContextMenu();
        if (selection && selection?.id !== this.selectedChartType?.id) {
            this.selectedChartType = selection;
        }
    }

    public breadcrumbClicked(crumb: Breadcrumb<AggregationQueryFilter>): void {
        this.hideContextMenu();
        if (crumb?.crumb) {
            if (crumb.crumb instanceof AggregationQueryFilter) {
                this.currentFilterPointer = crumb.crumb;

                this.home.selected = false;
                this.breadcrumbs.forEach((c) => (c.selected = false));
                // select THIS crumb
                crumb.selected = true;

                void this.query();
            }
        }

        this.breadcrumbsComponent?.refreshView();
    }

    private async onElementClick(event: MouseEvent | undefined, activeElements: any[] | undefined) {
        this.localPopupTarget = null;
        this.hideContextMenu();

        // find the clicked result field, we'll need it to generate the popup fields
        if (event && activeElements) {
            if (event.button === 0) {
                if (activeElements.length > 0 && activeElements[0]) {
                    if ('_index' in activeElements[0]) {
                        const activeElement = activeElements[0] as { _index: number };
                        // eslint-disable-next-line no-underscore-dangle
                        const idx = activeElement._index;
                        if (idx >= 0) {
                            const originalData = this.results[idx];
                            if (originalData) {
                                this.localPopupTarget = originalData;
                            }
                        }
                    }
                }
            }
        }

        if (AggregationDashboardWidgetComponent.drillNoFurther.find((item) => item === this.filter?.aggregationGroup)) {
            // we cannot drill further on these specific types
            return;
        }

        if (event && this.localPopupTarget !== null) {
            const menuItems: ContextMenuItem[] = [];

            // Menu Header
            {
                const header = {
                    id: this.translateService.instant('STE_MENU_ITEM_DRILL_DOWN_OPTIONS') as string,
                    text: this.translateService.instant('STE_MENU_ITEM_DRILL_DOWN_OPTIONS') as string,
                    icon: MeltedIcon.ZoomIn,
                };
                menuItems.push(header);
            }

            // General Drill-Down options
            {
                const category: ContextMenuItem = {
                    id: this.translateService.instant('STE_TITLE_OPTIONS_GENERAL') as string,
                    text: this.translateService.instant('STE_TITLE_OPTIONS_GENERAL') as string,
                    children: [],
                    icon: MeltedIcon.Cog,
                };

                for (const grouping of Object.values(AggregationGrouping).filter(
                    (grp) => !AggregationDashboardWidgetComponent.excludedAggregationDrillDownOptions.some((itm) => itm === grp)
                )) {
                    if (grouping === this.filter?.aggregationGroup) {
                        // filter out this grouping since it's what's currently applied
                        continue;
                    }

                    if (grouping === AggregationGrouping.SourceRole && this.filter?.additionalParameters?.some((param) => param.fieldName === '_sourcerole_')) {
                        // source role is already in the filter, cannot drill down by this factor
                        continue;
                    }

                    if (this.filter?.dataTypes && this.filter?.dataTypes.length === 1) {
                        // we're filtered to a single data-type, we cannot group further by record type
                        if (grouping === AggregationGrouping.DataType) {
                            continue;
                        }
                    }

                    const item = {
                        id: this.translateService.instant('STE_LABEL_AGGREGATIONGROUPING_' + grouping.toUpperCase()) as string,
                        text: this.translateService.instant('STE_LABEL_AGGREGATIONGROUPING_' + grouping.toUpperCase()) as string,
                        actionItem: {
                            execute: () => this.drillDownClicked(grouping),
                        },
                    };
                    category.children?.push(item);
                }

                menuItems.push(category);
            }

            // Specific drill-down options
            {
                const currentGrouping = this.filter?.aggregationGroup ?? AggregationGrouping.None;
                const groupingValue = currentGrouping === AggregationGrouping.DataType ? this.localPopupTarget.group?.group : '';
                const registrations = await this.getValidRegistrations(groupingValue);
                if (registrations !== null) {
                    for (const registration of registrations) {
                        let fields: { value: string; name: string }[] = [];
                        if (registration.supportedSearchParameters && registration.supportedSearchParameters?.length > 0) {
                            fields = registration.supportedSearchParameters
                                .filter((item) => item.valueType !== ExtendedSearchParameterType.Region)
                                .map((item) => {
                                    return {
                                        value: item.fieldName,
                                        name: item.displayName,
                                    };
                                })
                                .sort((a, b) => {
                                    if (a.name > b.name) {
                                        return 1;
                                    } else {
                                        return -1;
                                    }
                                })
                                .distinctBy((item) => item.value);
                        }

                        if (fields.length > 0) {
                            const category: ContextMenuItem = {
                                children: [],
                                id: registration.name,
                                text: registration.name,
                                icon: MeltedIcon.CorrelationService,
                            };

                            for (const field of fields) {
                                if (currentGrouping === AggregationGrouping.Custom && field.value === this.filter?.customAggregationGroup) {
                                    continue;
                                }

                                // check the additional parameters to see if they are already being filtered and don't add
                                // them to the context menu since splitting from an existing filter makes no sense
                                if (this.filter?.additionalParameters && this.filter?.additionalParameters?.length > 0) {
                                    if (
                                        this.filter.additionalParameters.find((param) => {
                                            return param.fieldName === field.value;
                                        })
                                    ) {
                                        continue;
                                    }
                                }

                                const item = {
                                    text: field.name,
                                    id: field.value,
                                    actionItem: {
                                        execute: () => this.drillDownClicked(AggregationGrouping.Custom, field.value),
                                    },
                                };
                                category.children?.push(item);
                            }

                            menuItems.push(category);
                        }
                    }
                }
            }

            this.showContextMenu(event, menuItems);
        }
    }

    private async drillDownClicked(grp: AggregationGrouping, customGrouping?: string | undefined) {
        this.hideContextMenu();
        // trim off the breadcrumbs, as a "branch" is being created
        if (this.filter === this.baseFilter) {
            // the pointer is at the "home" position, wipe the breadcrumbs
            this.breadcrumbs = [];
        } else {
            this.breadcrumbs = this.breadcrumbs.removeAfter((item) => item.crumb === this.filter);
        }

        const registrations = await this.getValidRegistrations(undefined);

        if (this.filter && this.filter !== null && this.localPopupTarget?.group) {
            const breadcrumb = await this.computeNewOptions(this.filter, this.localPopupTarget.group, registrations);
            // if (breadcrumb?.text)
            breadcrumb.crumb.aggregationGroup = grp;
            breadcrumb.crumb.customAggregationGroup = customGrouping;

            // clear any old selections
            this.home.selected = false;

            this.breadcrumbs.forEach((crumb) => (crumb.selected = false));
            this.breadcrumbs.push(breadcrumb);

            // increment the pointer
            this.currentFilterPointer = breadcrumb.crumb;
            void this.query();
        }

        this.breadcrumbsComponent?.refreshView();
    }

    //#endregion

    //#region Private Methods

    private setData() {
        if (this.results?.length > 0) {
            this.chartLabels = this.results.map((item) => item.formattedName);
            this.chartData = [
                {
                    data: this.results.map((item) => item.value),
                },
            ];

            // set chart bounds so there's a buffer above & below the data range
            const values = this.results.map((item) => item.value);
            const max = values.max() ?? 0;
            const min = values.min() ?? 0;

            let maxP20 = max + (max - min) * 0.2;
            let minM20 = min - (max - min) * 0.2;
            if (maxP20 > 2) {
                maxP20 = Math.ceil(maxP20);
                minM20 = Math.floor(minM20);
            }

            Chart.scaleService.updateScaleDefaults('linear', {
                ticks: {
                    max: maxP20,
                    min: minM20,
                },
            });
        }
    }

    private async query() {
        this.hideContextMenu();
        this.toastService.show({
            flavor: ToastFlavor.Information,
            text: this.translateService.instant('STE_LABEL_DATASOURCE_REFRESHING') as string,
            icon: MeltedIcon.None,
        });

        if (this.filter && this.filter !== null) {
            try {
                const results = await this.correlationClient.computeAggregation(this.filter).toPromise();
                if (results) {
                    this.results = results;

                    this.setData();
                    this.toastService.show({
                        flavor: ToastFlavor.Success,
                        text: this.translateService.instant('STE_LABEL_DATASOURCE_REFRESHED') as string,
                        icon: MeltedIcon.None,
                    });
                } else {
                    this.toastService.show({
                        flavor: ToastFlavor.Warning,
                        text: this.translateService.instant('STE_LABEL_NO_DATA_AVAILABLE') as string,
                        icon: MeltedIcon.Warning,
                    });
                }
            } catch (err) {
                if (err instanceof ApiException) {
                    const ex = err;
                    this.toastService.show({
                        flavor: ToastFlavor.Error,
                        text: ex.message,
                        icon: MeltedIcon.BarLineChart,
                        secondaryText: ex?.result as string,
                    });
                } else if (typeof err === 'string') {
                    const msg = err;
                    this.toastService.show({
                        flavor: ToastFlavor.Error,
                        text: msg,
                        icon: MeltedIcon.BarLineChart,
                    });
                }
            }
        }
    }

    private showContextMenu(mouseEvent: MouseEvent, menuItems?: ContextMenuItem[]): void {
        this.hideContextMenu();

        if (!menuItems || menuItems.length === 0) {
            return;
        }

        this.contextMenuPositionX = mouseEvent.x;
        this.contextMenuPositionY = mouseEvent.y;
        this.contextMenuItemsSource = menuItems;
        this.contextMenuOpened = true;
        this.contextMenu.show().fireAndForget();
    }

    private hideContextMenu(): void {
        this.contextMenuOpened = false;
        this.contextMenu.close().fireAndForget();
    }

    private async getValidRegistrations(selectedGroup?: string | undefined) {
        const registrations = await this.correlationService.getRegistrations(undefined);
        const keys: LightDataSourceRegistration[] = [];
        if (registrations) {
            if (this.filter?.dataTypes && this.filter.dataTypes.length > 0) {
                for (const reg of registrations.filter((item) => this.filter?.dataTypes.find((a) => a.equals(item.dataType)))) {
                    keys.push(reg);
                }
            } else {
                for (const reg of registrations) {
                    keys.push(reg);
                }
            }
        }

        if (selectedGroup) {
            const tryParse = SafeGuid.tryParse(selectedGroup);
            if (tryParse.success) {
                return keys.filter((key) => key.dataType.equals(tryParse.value));
            }
        }

        return keys;
    }

    private getFirstValueTypeForFieldName(registrations: LightDataSourceRegistration[], customGroup: string) {
        let regs = registrations;
        if (this.filter?.dataTypes && this.filter.dataTypes?.length > 0) {
            regs = registrations.filter((reg) => this.filter?.dataTypes.some((item) => item.equals(reg.dataType)));
        }
        for (const reg of regs) {
            if (reg.supportedSearchParameters) {
                for (const param of reg.supportedSearchParameters) {
                    if (param.fieldName === customGroup) {
                        return {
                            name: param.displayName,
                            type: param.valueType,
                        };
                    }
                }
            }
        }
        return {
            name: customGroup,
            type: ExtendedSearchParameterType.String,
        };
    }

    private async formatValueFromGroupingType(value: string, registrations: LightDataSourceRegistration[], group?: AggregationGrouping | undefined) {
        if (group) {
            switch (group) {
                case AggregationGrouping.Month:
                    {
                        const month = parseInt(value);
                        switch (month) {
                            case 1:
                                return this.translateService.instant('STE_LABEL_MONTH_JANUARY') as string;
                            case 2:
                                return this.translateService.instant('STE_LABEL_MONTH_FEBRUARY') as string;
                            case 3:
                                return this.translateService.instant('STE_LABEL_MONTH_MARCH') as string;
                            case 4:
                                return this.translateService.instant('STE_LABEL_MONTH_APRIL') as string;
                            case 5:
                                return this.translateService.instant('STE_LABEL_MONTH_MAY') as string;
                            case 6:
                                return this.translateService.instant('STE_LABEL_MONTH_JUNE') as string;
                            case 7:
                                return this.translateService.instant('STE_LABEL_MONTH_JULY') as string;
                            case 8:
                                return this.translateService.instant('STE_LABEL_MONTH_AUGUST') as string;
                            case 9:
                                return this.translateService.instant('STE_LABEL_MONTH_SEPTEMBER') as string;
                            case 10:
                                return this.translateService.instant('STE_LABEL_MONTH_OCTOBER') as string;
                            case 11:
                                return this.translateService.instant('STE_LABEL_MONTH_NOVEMBER') as string;
                            case 12:
                                return this.translateService.instant('STE_LABEL_MONTH_DECEMBER') as string;
                            default:
                                break;
                        }
                    }
                    break;
                case AggregationGrouping.Day:
                    {
                        const day = parseInt(value);
                        switch (day) {
                            case 0:
                                return this.translateService.instant('STE_LABEL_DAYOFWEEK_SUNDAY') as string;
                            case 1:
                                return this.translateService.instant('STE_LABEL_DAYOFWEEK_MONDAY') as string;
                            case 2:
                                return this.translateService.instant('STE_LABEL_DAYOFWEEK_TUESDAY') as string;
                            case 3:
                                return this.translateService.instant('STE_LABEL_DAYOFWEEK_WEDNESDAY') as string;
                            case 4:
                                return this.translateService.instant('STE_LABEL_DAYOFWEEK_THURSDAY') as string;
                            case 5:
                                return this.translateService.instant('STE_LABEL_DAYOFWEEK_FRIDAY') as string;
                            case 6:
                                return this.translateService.instant('STE_LABEL_DAYOFWEEK_SATURDAY') as string;
                            default:
                                break;
                        }
                    }
                    break;
                case AggregationGrouping.DataType:
                    {
                        const tryParse = SafeGuid.tryParse(value);
                        if (tryParse.success) {
                            const reg = registrations.find((item) => item.dataType.equals(tryParse.value));
                            if (reg) {
                                return reg.name;
                            }
                        }
                    }
                    break;
                case AggregationGrouping.SourceRole:
                case AggregationGrouping.Custom:
                    {
                        const tryParse = SafeGuid.tryParse(value);
                        const client = this.securityCenterClientService?.scClient;
                        if (client && tryParse.success) {
                            const entity = await client.getEntityAsync(Entity, tryParse.value, undefined, undefined, EntityFields.name);
                            if (entity) {
                                return entity.name;
                            }
                        }
                    }
                    // TODO: else run the base localizer on the value, server-side :(
                    break;
                default:
                    break;
            }
        }
        return value;
    }

    private async computeNewOptions(oldOptions: AggregationQueryFilter, aggregationResult: GroupAggregationResult, registrations: LightDataSourceRegistration[]) {
        const result: Breadcrumb<AggregationQueryFilter> = {
            text: '',
            crumb: oldOptions.clone(),
            selected: true,
        };

        const scClient = this.securityCenterClientService?.scClient;
        const grouping = result.crumb.aggregationGroup;
        const customGroup = result.crumb.customAggregationGroup ?? '';

        result.text = `[${this.translateService.instant(`STE_LABEL_AGGREGATIONGROUPING_${grouping.toUpperCase()}`) as string}]="${aggregationResult.group}"`;

        switch (grouping) {
            case AggregationGrouping.None:
                result.text = this.translateService.instant('STE_LABEL_RESULT_NONE') as string;
                break;
            case AggregationGrouping.Custom:
                {
                    const displayAndValue = this.getFirstValueTypeForFieldName(registrations, customGroup);
                    const parameter = new LightCorrelationSearchParameter();
                    parameter.valueType = displayAndValue.type;
                    parameter.comparison = ParameterComparison.Equal;
                    parameter.fieldName = customGroup;
                    parameter.value = aggregationResult.group;
                    if (!result.crumb.additionalParameters) {
                        result.crumb.additionalParameters = [];
                    }
                    result.crumb.additionalParameters.push(parameter);

                    const breadcrumbDisplay = await this.formatValueFromGroupingType(parameter.value, registrations, this.filter?.aggregationGroup);
                    result.text = '[' + parameter.fieldName + ']="' + breadcrumbDisplay + '"';
                }
                break;
            case AggregationGrouping.DataType:
                {
                    const tryParse = SafeGuid.tryParse(aggregationResult.group);
                    if (tryParse.success && !tryParse.value.isEmpty()) {
                        result.crumb.dataTypes = [tryParse.value];
                        const regName = registrations.find((item) => item.dataType.equals(tryParse.value))?.name ?? aggregationResult.group;
                        result.text = `[${this.translateService.instant('STE_LABEL_AGGREGATIONGROUPING_DATATYPE') as string}]="${regName}"`;
                    }
                }
                break;
            case AggregationGrouping.SourceRole:
                {
                    const tryParse = SafeGuid.tryParse(aggregationResult.group);
                    if (tryParse.success && !tryParse.value.isEmpty()) {
                        const parameter = new LightCorrelationSearchParameter();
                        parameter.valueType = ExtendedSearchParameterType.Entity;
                        parameter.comparison = ParameterComparison.Equal;
                        parameter.fieldName = '_sourcerole_';
                        parameter.value = tryParse.value.toString();
                        if (!result.crumb.additionalParameters) {
                            result.crumb.additionalParameters = [];
                        }
                        result.crumb.additionalParameters.push(parameter);

                        let entityName = '';
                        if (scClient) {
                            const entity = await scClient.getEntityAsync<Entity, IEntity>(Entity, tryParse.value, undefined, undefined, EntityFields.name);
                            entityName = entity?.name ?? aggregationResult.group;
                        }
                        result.text = `[${this.translateService.instant('STE_LABEL_AGGREGATIONGROUPING_SOURCEROLE') as string}]="${entityName}"`;
                    }
                }
                break;
            case AggregationGrouping.Year:
                {
                    const value = parseInt(aggregationResult.group);
                    if (value > 0) {
                        result.crumb.referenceTimeUtc = moment.utc([value, 1, 1]).add(0.5, 'years').toISOString();
                        result.crumb.timeWindow = '182.12:00:00'; // 1/2 a year
                    }
                }
                break;
            case AggregationGrouping.Month:
                {
                    let year = moment.utc().year();
                    const value = parseInt(aggregationResult.group);
                    if (value > 0) {
                        const utcTime = moment.utc(result.crumb.referenceTimeUtc);
                        if (result.crumb.referenceTimeUtc && utcTime) {
                            year = utcTime.year();
                        }
                        result.crumb.referenceTimeUtc = moment.utc([year, value, 1]).add(0.5, 'months').toISOString();
                        result.crumb.timeWindow = '15.00:00:00';
                    }
                }
                break;
            case AggregationGrouping.Day:
                {
                    let year = moment.utc().year();
                    let month = moment.utc().month();
                    const value = parseInt(aggregationResult.group);
                    if (value > 0) {
                        const utcTime = moment.utc(result.crumb.referenceTimeUtc);
                        if (result.crumb.referenceTimeUtc && utcTime) {
                            year = utcTime.year();
                            month = utcTime.month();
                        }
                        result.crumb.referenceTimeUtc = moment.utc([year, month, value]).add(0.5, 'days').toISOString();
                        result.crumb.timeWindow = '12:00:00';
                    }
                }
                break;
            case AggregationGrouping.Hour:
                {
                    let year = moment.utc().year();
                    let month = moment.utc().month();
                    let day = moment.utc().day();
                    const value = parseInt(aggregationResult.group);
                    if (value >= 0) {
                        const utcTime = moment.utc(result.crumb.referenceTimeUtc);
                        if (result.crumb.referenceTimeUtc && utcTime) {
                            year = utcTime.year();
                            month = utcTime.month();
                            day = utcTime.day();
                        }
                        result.crumb.referenceTimeUtc = moment.utc([year, month, day, value, 0, 0]).add(0.5, 'hours').toISOString();
                        result.crumb.timeWindow = '00:30:00';
                    }
                }
                break;
            case AggregationGrouping.Minute:
                {
                    let year = moment.utc().year();
                    let month = moment.utc().month();
                    let day = moment.utc().day();
                    let hour = moment.utc().hour();

                    const value = parseInt(aggregationResult.group);
                    if (value >= 0) {
                        const utcTime = moment.utc(result.crumb.referenceTimeUtc);
                        if (result.crumb.referenceTimeUtc && utcTime) {
                            year = utcTime.year();
                            month = utcTime.month();
                            day = utcTime.day();
                            hour = utcTime.hour();
                        }
                        result.crumb.referenceTimeUtc = moment.utc([year, month, day, hour, value, 0]).add(0.5, 'minutes').toISOString();
                        result.crumb.timeWindow = '00:00:30';
                    }
                }
                break;
            default:
                break;
        }

        return result;
    }

    //#endregion
}
