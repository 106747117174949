import { Component, ViewContainerRef } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { TrackingService } from '../../services/tracking.service';
import { TrackedComponent } from '../tracked/tracked.component';

@UntilDestroy()
@Component({
    selector: 'app-hideable-component',
    template: '',
})
/**
 * This base class allows a component to interact with the "hideRequestSubject" by pushing it's conditions to toggle it's display to none.
 * This class will evaluate the given boolean expression and will then either append the 'hideCssClassSelector' value to the 'rootWrappingElement's classList.
 * By default, the wrapperElement is considered to be a gen-card.context-plugin-card
 * And will also by default assign the 'hideable-component--hidden' css class. Make sure to react accordingly in the scss file of the component that contains the RootWrapperElement.
 * For Example: The side-pane contains PTZ-Controller that is displayed in a gen-card, so the side-pane.component.scss should contain a hideable-component--hidden rule.
 */
export class HideableComponent extends TrackedComponent {
    /** interact with this property by using it's 'next()' function */
    protected hideRequestSubject = new Subject<boolean>();
    /** Css class that will be added to the rootWrapperElement. Defaults to hideCssClassSelector */
    protected hideCssClassSelector = 'hideable-component--hidden';
    /** Selector to the wrapper element that surrounds your HideableComponent. Defaults to gen-card.context-plugin-card */
    protected rootWrapperElementSelector = 'gen-card.context-plugin-card'; // heavily based on my implementation.. not sure if good idea.

    private rootWrappingElement?: Element | null;

    constructor(protected viewContainerRef: ViewContainerRef, protected trackingService: TrackingService) {
        super(trackingService);

        // Make sure we subscribe in the constructor
        this.hideRequestSubject.pipe(untilDestroyed(this)).subscribe((condition: boolean) => {
            this.retrieveRootElement();
            if (condition) {
                this.hideComponent();
            } else {
                this.showComponent();
            }
        });
    }

    private hideComponent(): void {
        this.rootWrappingElement?.classList.add(this.hideCssClassSelector);
    }

    private showComponent(): void {
        if (!this.rootWrappingElement) {
            return;
        }

        const isHidden = this.rootWrappingElement.classList.contains(this.hideCssClassSelector);
        if (isHidden) {
            this.rootWrappingElement.classList.remove(this.hideCssClassSelector);
        }
    }

    private retrieveRootElement(): void {
        const elem = this.viewContainerRef.element.nativeElement as HTMLElement;
        this.rootWrappingElement ??= elem.closest(this.rootWrapperElementSelector);
    }
}
