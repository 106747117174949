// Autogenerated: v2/help/Entities/ApiIntrusionUnit?codegen=ts&InterfaceOnly=True&workflow=False on 2020-07-23 3:40:12 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity } from './IEntity';
import { IUnitEntity, UnitEntityFields } from './IUnitEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class IntrusionUnitEntityFields extends UnitEntityFields {
    public static aCFailureField = 'ACFailure';
    public static batteryFailureField = 'BatteryFailure';
    public static capabilitiesField = 'Capabilities';
    public static clearLogsAfterDownloadField = 'ClearLogsAfterDownload';
    public static commandPortField = 'CommandPort';
    public static intrusionUnitIdField = 'IntrusionUnitId';
    public static isActivatedField = 'IsActivated';
    public static serialPortField = 'SerialPort';
    public static tamperField = 'Tamper';
}

// #endregion Fields

export interface IIntrusionUnitEntity extends IUnitEntity {
    // #region REST Properties

    aCFailure: boolean;

    batteryFailure: boolean;

    readonly capabilities: string;

    clearLogsAfterDownload: boolean;

    commandPort: number;

    intrusionUnitId: string;

    isActivated: boolean;

    serialPort: string;

    tamper: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getIntrusionAreasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

