export class FieldValue {
  // #region Fields

  private _object: any = null;
  private _value: any;
  private _fieldId: string;

  // #endregion

  // #region Constructor, Dispose

  constructor(fieldId: string, value: any) {
    this._fieldId = fieldId;
    this._value = value;
    this._object = null;
  }

  // #endregion

  // #region Properties

  public dirty = false;
  private outputFunc: () => object = () => this._value;

  // #endregion

  // #region Methods

  public get value(): any {
    return this.outputFunc();
  }

  public get object(): any {
    return this._object;
  }

  public get fieldId(): string {
    return this._fieldId;
  }

  public get isExtracted(): boolean {
    return this._object !== null;
  }

  public setValue(value: any) {
    this._value = value;
    this.outputFunc = () => this._value;
    this._object = null;
    this.dirty = true;
  }

  public setObject(value: any, outputFunc: () => any): void {
    this._object = value;
    this._value = null;
    this.outputFunc = outputFunc;
    this.dirty = true;
  }

  // #endregion
}
