// Autogenerated: v2/help/Entities/ApiSchedule?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:01 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { FieldObject } from '../../Helpers/FieldObject';
import { IRestResponse } from '../Interface/IRestResponse';
import { IDateRange, IScheduleEntity, ScheduleEntityFields } from '../Interface/IScheduleEntity';
import { ITransaction } from '../Interface/ITransaction';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IEntity } from '../Interface/IEntity';

// #region Inner classes

export class DateRange extends FieldObject implements IDateRange {
    // Start
    public get start(): Date {
        return this.getFieldDate('Start');
    }
    public set start(value: Date) {
        this.setFieldDate('Start', value);
    }
    // End
    public get end(): Date {
        return this.getFieldDate('End');
    }
    public set end(value: Date) {
        this.setFieldDate('End', value);
    }

    public initializeAllFields(): void {
        this.setFieldDate('Start', new Date());
        this.setFieldDate('End', new Date());
    }
}

// #endregion Inner classes

export class ScheduleEntity extends Entity implements IScheduleEntity {
    // #region REST Properties

    // CoverageType
    public get coverageType(): string {
        return this.getField<string>(ScheduleEntityFields.coverageTypeField);
    }
    public set coverageType(value: string) {
        this.setField<string>(ScheduleEntityFields.coverageTypeField, value);
    }

    // Data
    public get data(): string {
        return this.getField<string>(ScheduleEntityFields.dataField);
    }
    public set data(value: string) {
        this.setField<string>(ScheduleEntityFields.dataField, value);
    }

    // IsArchiverFriendly
    public get isArchiverFriendly(): boolean {
        return this.getField<boolean>(ScheduleEntityFields.isArchiverFriendlyField);
    }

    // ScheduleType
    public get scheduleType(): string {
        return this.getField<string>(ScheduleEntityFields.scheduleTypeField);
    }
    public set scheduleType(value: string) {
        this.setField<string>(ScheduleEntityFields.scheduleTypeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Schedules);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPointRules', 'Id', true, query, ValidFlags.EntityDependency, transaction);
    }

    public async isTimeCoverAsync(time: Date, transaction?: ITransaction): Promise<boolean | null> {
        const responseHandler = (response: IRestResponse): Promise<boolean> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body['isTimeCover'] as boolean;
                return new Promise<boolean>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<boolean>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldDate('time', time);

        return this.executeRequestTransactionAsync<boolean>('IsTimeCover', 'POST', inParam, transaction, responseHandler);
    }

    public async normalizeFlattenedDatesAsync(time: Date, transaction?: ITransaction): Promise<Array<IDateRange> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IDateRange>> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = new Array<IDateRange>();
                const body = response.body['Range'] as Array<any>;
                for (const entry of body) {
                    const fo = new DateRange();
                    fo.loadFields(entry);
                    result.push(fo);
                }
                return new Promise<Array<IDateRange>>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IDateRange>>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldDate('time', time);

        return this.executeRequestTransactionAsync<Array<IDateRange>>('NormalizeFlattenedDates', 'POST', inParam, transaction, responseHandler);
    }

    // #endregion REST Methods

}

