import { MapsProviderClient } from '@modules/map/api/api';
import { SettingsService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { TranslateService } from '@ngx-translate/core';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { IMapEntity } from 'RestClient/Client/Interface/IMapEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { LoggerService } from 'src/app/modules/shared/services/logger/logger.service';
import { GoogleMapProviderLoader } from './google-map-provider-loader';
import { ImageMapProviderLoader } from './image-map-provider-loader';
import { MapLoader } from './map-loader';
import { MapProvider, MapProviderLoaderOptions, TokenSupport } from './map-provider.interface';
import { FallbackGeoreferencedMapProviderLoader } from './fallback-georeferenced-map-provider-loader';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class MapProviderLoader {
    private mapProviders: Set<MapProvider> = new Set<MapProvider>();
    private cacheTask!: IEntityCacheTask;

    private readonly fallbackGeoMapProviderLoader: FallbackGeoreferencedMapProviderLoader;

    constructor(
        mapLoader: MapLoader,
        scClient: SecurityCenterClient,
        mapsProviderClient: MapsProviderClient,
        private loggerService: LoggerService,
        commandService: InternalCommandsService,
        translateService: TranslateService,
        userSettingsService: SettingsService
    ) {
        this.cacheTask = scClient.buildEntityCache();
        this.mapProviders.add(new ImageMapProviderLoader(mapLoader, scClient, loggerService, commandService, translateService, userSettingsService));
        this.mapProviders.add(new GoogleMapProviderLoader(mapLoader, this.cacheTask, mapsProviderClient, loggerService));
        // this.mapProviders.add(new CustomMapProviderLoader(mapLoader)); Not supported yet.
        this.fallbackGeoMapProviderLoader = new FallbackGeoreferencedMapProviderLoader(mapLoader);
    }

    public async loadMapAsync(map: IMapEntity, options: MapProviderLoaderOptions): Promise<boolean> {
        const mapProvider = this.getMapProviderLoader(map);
        if (mapProvider) {
            return this.loadProviderAsync(mapProvider, map, options);
        }

        // Couldn't load geolocalized map, use fallback map provider.
        if (map.geoLocalized) {
            this.logProviderNotFound(map, true);
            return this.loadProviderAsync(this.fallbackGeoMapProviderLoader, map, options);
        }

        this.logProviderNotFound(map);
        return false;
    }

    public applyToken(map: IMapEntity, token: string): void {
        const provider = this.getMapProviderLoader(map) as MapProvider | (MapProvider & TokenSupport) | undefined;
        if (provider && 'applyToken' in provider) {
            provider.applyToken(token);
        }
    }

    public destroy(): void {
        this.cacheTask?.dispose().fireAndForget();
    }

    private getMapProviderLoader(map: IMapEntity): MapProvider | undefined {
        return Array.from(this.mapProviders).find((item) => item.type.equals(map.providerId));
    }

    private loadProviderAsync(mapProvider: MapProvider, map: IMapEntity, options: MapProviderLoaderOptions): Promise<boolean> {
        try {
            return mapProvider.loadMapAsync(map, options);
        } catch (e) {
            this.loggerService.traceError(e);
        }
        return Promise.resolve(false);
    }

    private logProviderNotFound(mapEntity: IMapEntity, usingFallback = false) {
        this.loggerService.traceDebug(`No supported provider found provider id ${mapEntity.providerId.toString()}${usingFallback ? ', using fallback map provider' : ''}.`);
    }
}
