import { Pipe, PipeTransform } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';

@Pipe({
    name: 'StringToIcon',
})
export class StringToIconPipe implements PipeTransform {
    public transform(value: string): MeltedIcon {
        return value as MeltedIcon;
    }
}
