import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { McClient } from '../mc-client.service';
import { ListResultApi } from '../../models/list-result';
import { MCIncidentApi } from '../../models/mc-incident';
import { LoggerService } from '../../../shared/services/logger/logger.service';
import { StateService } from '../state/state.service';

@Injectable()
export class IncidentCountService {
    constructor(private mcClient: McClient, private stateService: StateService, private logger: LoggerService) {}

    public getNewIncidentCount(): Observable<number> {
        return this.stateService.getActiveStates$().pipe(
            map((states) => states.map((state) => state.id).join()),
            map((joinedStates) => `v2/Incidents?limit=1&stateIds=${joinedStates}&isRecipient=true`),
            switchMap((url) =>
                this.mcClient.get<ListResultApi<MCIncidentApi>>(url).pipe(
                    map(({ TotalResultsCount }) => TotalResultsCount ?? 0),
                    catchError((error: Error) => {
                        this.logger.traceError('An exception occured while refreshing the incident count', error.message);
                        return EMPTY;
                    })
                )
            )
        );
    }
}
