// Autogenerated: v2/help/Entities/ApiArea?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class AreaEntityFields extends EntityFields {
    public static accessControlSupportField = 'AccessControlSupport';
    public static accessPermissionLevelField = 'AccessPermissionLevel';
    public static antipassbackModeField = 'AntipassbackMode';
    public static antipassbackScheduleField = 'AntipassbackSchedule';
    public static antipassbackStrictField = 'AntipassbackStrict';
    public static antipassbackTimeoutField = 'AntipassbackTimeout';
    public static captiveAccessPointField = 'CaptiveAccessPoint';
    public static duressField = 'Duress';
    public static enforceVisitorEscortRulesField = 'EnforceVisitorEscortRules';
    public static entryAccessPointField = 'EntryAccessPoint';
    public static exitAccessPointField = 'ExitAccessPoint';
    public static firstInRuleForAccessRulesApplyScheduleField = 'FirstInRuleForAccessRulesApplySchedule';
    public static firstInRuleForAccessRulesEnabledField = 'FirstInRuleForAccessRulesEnabled';
    public static firstInRuleForUnlockSchedulesEnabledField = 'FirstInRuleForUnlockSchedulesEnabled';
    public static firstInRuleUnlockScheduleOffsetField = 'FirstInRuleUnlockScheduleOffset';
    public static inheritEnforceVisitorEscortRulesField = 'InheritEnforceVisitorEscortRules';
    public static intrusionAccessPointInField = 'IntrusionAccessPointIn';
    public static intrusionAccessPointOutField = 'IntrusionAccessPointOut';
    public static intrusionArmingAccessPointField = 'IntrusionArmingAccessPoint';
    public static intrusionDisarmingAccessPointField = 'IntrusionDisarmingAccessPoint';
    public static isFederatedDirectoryField = 'IsFederatedDirectory';
    public static linkField = 'Link';
    public static maxOccupancyLimitField = 'MaxOccupancyLimit';
    public static maxOccupancyModeField = 'MaxOccupancyMode';
    public static peopleCountEnabledField = 'PeopleCountEnabled';
    public static peopleCountOwnerField = 'PeopleCountOwner';
    public static threatLevelField = 'ThreatLevel';
}

// #endregion Fields

// #region Inner classes

export interface IAccessPointGroupThreatLevelMapping extends IFieldObject {
    accessPointGroupId: IGuid;
    threatLevelId: IGuid;
    xmlInfo: string;
}

export interface IMap extends IFieldObject {
    provider: IGuid | null;
    fileExtension: string;
    fileData: string;
    zoomLevel: number | null;
    resolution: number | null;
    scaleFactor: number | null;
}

// #endregion Inner classes

export interface IAreaEntity extends IEntity {
    // #region REST Properties

    accessControlSupport: boolean;

    accessPermissionLevel: number;

    antipassbackMode: string;

    antipassbackSchedule: IGuid;

    antipassbackStrict: boolean;

    antipassbackTimeout: number;

    readonly captiveAccessPoint: IGuid;

    duress: boolean;

    enforceVisitorEscortRules: boolean;

    readonly entryAccessPoint: IGuid;

    readonly exitAccessPoint: IGuid;

    firstInRuleForAccessRulesApplySchedule: IGuid;

    firstInRuleForAccessRulesEnabled: boolean;

    firstInRuleForUnlockSchedulesEnabled: boolean;

    firstInRuleUnlockScheduleOffset: number;

    inheritEnforceVisitorEscortRules: boolean;

    readonly intrusionAccessPointIn: IGuid;

    readonly intrusionAccessPointOut: IGuid;

    readonly intrusionArmingAccessPoint: IGuid;

    readonly intrusionDisarmingAccessPoint: IGuid;

    readonly isFederatedDirectory: boolean;

    link: IGuid;

    maxOccupancyLimit: number;

    maxOccupancyMode: string;

    peopleCountEnabled: boolean;

    peopleCountOwner: boolean;

    threatLevel: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    createMapAsync(value: IMap, transaction?: ITransaction): Promise<IGuid | null>;
    getFirstInRuleBypassListAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getFirstInRuleSupervisorsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getThreatLevelMappingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAccessPointGroupThreatLevelMapping> | null>;

    // #endregion REST Methods

}

