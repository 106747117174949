// Autogenerated: v2/help/Entities/ApiMap?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:07 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IFieldObject } from './IFieldObject';
import { IEntity, EntityFields } from './IEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class MapEntityFields extends EntityFields {
    public static associatedAreaField = 'AssociatedArea';
    public static backgroundColorField = 'BackgroundColor';
    public static cruncherIdField = 'CruncherId';
    public static dPIField = 'DPI';
    public static geoLocalizedField = 'GeoLocalized';
    public static hideEmptyLayersField = 'HideEmptyLayers';
    public static infoField = 'Info';
    public static isMapImageField = 'IsMapImage';
    public static lockDisplayField = 'LockDisplay';
    public static lockZoomField = 'LockZoom';
    public static maxZoomField = 'MaxZoom';
    public static originalFileExtensionField = 'OriginalFileExtension';
    public static projectionField = 'Projection';
    public static providerIdField = 'ProviderId';
    public static rotationField = 'Rotation';
    public static scaleFactorField = 'ScaleFactor';
    public static serverUrisField = 'ServerUris';
    public static statusField = 'Status';
    public static thumbnailIdField = 'ThumbnailId';
    public static versionField = 'Version';
}

// #endregion Fields

// #region Inner classes

export interface ICustomVisibleLayer extends IFieldObject {
    id: string;
    isVisible: boolean;
    subLayers: ObservableCollection<ICustomVisibleLayer>;
}

export interface ICustomRefreshableLayer extends IFieldObject {
    url: string;
    refreshRate: number;
    isLayerRefreshing: boolean;
    lastRefreshTime: Date;
    isInDesignMode: boolean;
}

export interface ICustomMapLayer extends IFieldObject {
    refreshableLayer: ICustomRefreshableLayer;
    isInDesignMode: boolean;
    refreshRate: string;
    refreshRateTs: number;
    isRefreshingLayer: boolean;
    url: string;
    zIndex: number;
    isPartialChecked: boolean;
    opacity: number;
    subLayers: ObservableCollection<ICustomVisibleLayer>;
    parentLayerId: string;
    isDisplayed: boolean;
    id: string;
    title: string;
    token: string;
}

export interface ICustomSpecificMapConfiguration extends IFieldObject {
    id: IGuid;
    layers: ObservableCollection<ICustomMapLayer>;
}

export interface IStateColor extends IFieldObject {
    state: number;
    color: string;
    visible: boolean;
}

export interface IMapObject extends IFieldObject {
    guid: IGuid;
    data: string;
    file: IGuid | null;
    latitude: number;
    link: IGuid | null;
    longitude: number;
    relativeHeight: number;
    relativeWidth: number;
    rotation: number;
    showFov: boolean;
    fovDistance: number;
    fovMaxDistance: number;
    fovElevation: number;
    fovOrientation: number;
    fovWidth: number;
    type: IGuid;
    stateColors: ObservableCollection<IStateColor>;
}

export interface IVisibleLayer extends IFieldObject {
    id: IGuid;
    isDisplayed: boolean;
    zIndex: number;
}

export interface IPoint extends IFieldObject {
    x: number;
    y: number;
}

export interface IGeoReference extends IFieldObject {
    targetPoints: ObservableCollection<ICoordinate>;
    sourcePoints: ObservableCollection<IPoint>;
    isValid: boolean;
}

export interface IMapFloorData extends IFieldObject {
    id: IGuid;
    abbreviation: string;
    orderPosition: number;
}

export interface ILatLngRectangle extends IFieldObject {
    bottomRight: ICoordinate;
    topLeft: ICoordinate;
}

export interface ICoordinate extends IFieldObject {
    latitude: number;
    longitude: number;
    altitude: number | null;
}

export interface IDefaultView extends IFieldObject {
    name: string;
    scaleFactor: number;
    center: ICoordinate;
    viewArea: ILatLngRectangle;
}

export interface IThickness extends IFieldObject {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

// #endregion Inner classes

export interface IMapEntity extends IEntity {
    // #region REST Properties

    readonly associatedArea: IGuid;

    backgroundColor: number;

    cruncherId: IGuid;

    dPI: number;

    geoLocalized: boolean;

    hideEmptyLayers: boolean;

    info: string;

    readonly isMapImage: boolean;

    lockDisplay: boolean;

    lockZoom: boolean;

    maxZoom: number;

    originalFileExtension: string;

    projection: string;

    providerId: IGuid;

    rotation: number;

    scaleFactor: number;

    readonly serverUris: ObservableCollection<string>;

    readonly status: string;

    readonly thumbnailId: IGuid;

    version: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    getCropAsync(transaction?: ITransaction | null): Promise<IThickness | null>;
    getDefaultViewAsync(transaction?: ITransaction | null): Promise<IDefaultView | null>;
    getFloorInfoAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMapFloorData> | null>;
    getGeoReferenceAsync(transaction?: ITransaction | null): Promise<IGeoReference | null>;
    getLayersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IVisibleLayer> | null>;
    getMapObjectsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMapObject> | null>;
    getSpecificMapsConfigurationsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomSpecificMapConfiguration> | null>;
    getViewAreaPresetsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDefaultView> | null>;

    // #endregion REST Methods

}

