export type sortingParameter = 'displayId' | 'name' | 'location' | 'triggerTime' | 'state' | 'priority';

export type sortingOrientation = 'desc' | 'asc' | '';
export class IncidentSorting {
    constructor(public readonly parameter: sortingParameter = 'displayId', public readonly orientation: sortingOrientation = 'desc') {
        Object.freeze(this);
    }

    public with({ parameter, orientation }: { parameter?: sortingParameter; orientation?: sortingOrientation }): IncidentSorting {
        return new IncidentSorting(parameter ?? this.parameter, orientation ?? this.orientation);
    }

    public toString = (): string => `${this.parameter}.${this.orientation}`;

    public isDefault = (): boolean => this.parameter === 'displayId' && this.orientation === 'desc';
}
