import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { CurrentUserInfo } from 'RestClient/Client/Parameters/CurrentUserInfo';
import { Injectable, OnDestroy } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { IGuid } from 'safeguid';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { map, switchMap, withLatestFrom, take, takeUntil, shareReplay } from 'rxjs/operators';
import { WebAppClient } from 'WebClient/WebAppClient';
import { Entity } from 'RestClient/Client/Model/Entity';
import { LoggerService } from '../../shared/services/logger/logger.service';
import { BatchEntitiesObservable } from '../utils/batch-entities-observable';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

interface CurrentUser {
    userInfo: CurrentUserInfo;
    userGroup: Array<Entity>;
}

@UntilDestroy()
@Injectable()
export class McUserService implements OnDestroy {
    public currentUser!: CurrentUser;
    private userBatchObservable: BatchEntitiesObservable;
    private onDestroy$ = new Subject();
    private _scClient: WebAppClient;

    constructor(securityCenterProvider: SecurityCenterClientService, logger: LoggerService) {
        this._scClient = securityCenterProvider.scClient;

        const fetchUserGroup = () =>
            this.fetchCurrentUserGroup()
                .pipe(untilDestroyed(this))
                .subscribe((x) => (this.currentUser = x));

        if (this._scClient.isLoggedOn) fetchUserGroup();

        this._scClient.onLogonStateChanged((args: LogonStateChangedArgs) => {
            if (!args.loggedOn()) return;
            fetchUserGroup();
        });

        this.userBatchObservable = new BatchEntitiesObservable(this._scClient, logger);
    }

    public ngOnDestroy() {
        void this.userBatchObservable.unsubscribe();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public getUserOrGroup(userId: IGuid | string): Observable<IEntity> {
        return this.userBatchObservable.registerEntity(userId);
    }

    public getUser(userId: IGuid | string): Observable<IUserEntity> {
        return this.userBatchObservable.registerEntity(userId).pipe(
            map((entity) => {
                if (entity.entityType === EntityTypes.Users) {
                    return entity as IUserEntity;
                }
                throw new Error(`Entity with id ${userId} is not an user.`);
            })
        );
    }

    public isCurrentUserOwner(ownerId: IGuid | null): boolean {
        if (!ownerId) return false;
        return this.currentUser?.userInfo.id.equals(ownerId) ?? false;
    }

    private fetchCurrentUser(): Observable<CurrentUserInfo> {
        return from(this._scClient.getCurrentUserInfoAsync());
    }

    private fetchCurrentUserGroup(): Observable<CurrentUser> {
        const userInfo$ = this.fetchCurrentUser().pipe(shareReplay(1));
        return userInfo$.pipe(
            take(1),
            switchMap((x) => this.getUser(x.id)),
            switchMap((x) => from(x.getUsergroupsAsync())),
            map((x) => x?.values() ?? []),
            withLatestFrom(userInfo$),
            map(([userGroup, userInfo]) => ({ userGroup, userInfo } as CurrentUser))
        );
    }
}
