// Autogenerated: v2/help/Entities/ApiSecurityCenterFederationRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:08 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IFieldObject } from './IFieldObject';
import { IFederationBaseRoleEntity, FederationBaseRoleEntityFields } from './IFederationBaseRoleEntity';
import { ITransaction } from './ITransaction';

// #region Fields

export class SecurityCenterFederationRoleEntityFields extends FederationBaseRoleEntityFields {
    public static defaultStreamUsageField = 'DefaultStreamUsage';
    public static federateAlarmsField = 'FederateAlarms';
    public static forwardDirectoryReportsField = 'ForwardDirectoryReports';
    public static resiliencyTimeoutInMillisecondsField = 'ResiliencyTimeoutInMilliseconds';
    public static rootAreaIdField = 'RootAreaId';
    public static useResilientConnectionField = 'UseResilientConnection';
}

// #endregion Fields

// #region Inner classes

export interface IFederationEvent extends IFieldObject {
    id: string;
    enabled: boolean;
}

// #endregion Inner classes

export interface ISecurityCenterFederationRoleEntity extends IFederationBaseRoleEntity {
    // #region REST Properties

    defaultStreamUsage: string;

    federateAlarms: boolean;

    forwardDirectoryReports: boolean;

    resiliencyTimeoutInMilliseconds: number;

    readonly rootAreaId: IGuid;

    useResilientConnection: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getEventsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IFederationEvent> | null>;

    // #endregion REST Methods

}

