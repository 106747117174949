// Autogenerated: v2/help/Classes/ApiCardholderAccessGrantConfirmationRequestedEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-06-04 9:12:13 AM
import { TimezoneEvent } from './TimezoneEvent';
import { IGuid } from 'safeguid';
import { ICardholderAccessGrantConfirmationRequestedEvent } from '../Interface/ICardholderAccessGrantConfirmationRequestedEvent';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { CardholderIdentityValidationEvent } from './CardholderIdentityValidationEvent';

export class CardholderAccessGrantConfirmationRequestedEvent extends CardholderIdentityValidationEvent implements ICardholderAccessGrantConfirmationRequestedEvent {
    // #region REST Properties

    // BiometricScore
    public get biometricScore(): number {
        return this.getField<number>('BiometricScore');
    }
    public set biometricScore(value: number) {
        this.setField<number>('BiometricScore', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('BiometricScore', 0);
    }

    // #endregion Methods

}

