import { IGuid, SafeGuid } from 'safeguid';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.TakeOwnership;
export class TakeOwnershipCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentId: IGuid) {
        super(
            `/v2/Incidents/${incidentId.toString()}`,
            JSON.stringify({
                Type: commandType,
            })
        );
    }
}
