import { Inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    DivIcon,
    ImageMapObject,
    LatLng,
    LatLngBounds,
    LeafletEvent,
    LeafletMapProviderBase,
    LeafletMouseEvent,
    Map as WebMap,
    MapObject,
    MapObjectCategory,
    MapObjectEvent,
    MapObjectView,
    MapOptions,
    MapProviderClassType,
    Marker,
    MarkerClassNameIcon,
    MarkerClassNameOverlayIcon,
    MarkerIcon,
    MarkerIconStyle,
    MarkerImgIcon,
    MarkerOverlayIcon,
    MarkerTextIcon,
    MiniMap,
    MiniMapStateChangeEvent,
    Polyline,
    ShapeMapObject,
    ShapeType,
    TextMapObject,
} from '@genetec/web-maps';
import { MapOptionsComponent } from '@modules/general/components/options/maps-options/maps-options.component';
import { MapsProviderClient } from '@modules/map/api/api';
import { InitializeMapOptions, MapSelectionData } from '@modules/maps/models';
import { CustomIconState } from '@modules/shared/api/api';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { ContentGroup } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import {
    COMMANDS_SERVICE,
    ContentExtensionServicesProvider,
    CONTENT_SERVICES_PROVIDER,
    MapContext,
    mapsServiceId,
    PublicMapService,
    SettingsService,
    USER_SETTINGS_SERVICE,
} from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { ResourcesService } from '@modules/shared/services/resources/resources.service';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { toError } from '@src/app/utilities';
import { WINDOW } from '@utilities/common-helper';
import { EntityTypeIds } from 'RestClient/Client/Enumerations/EntityTypeIds';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { IAreaEntity } from 'RestClient/Client/Interface/IAreaEntity';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { IDefaultView, IMapEntity, MapEntityFields } from 'RestClient/Client/Interface/IMapEntity';
import { ISystemConfigurationEntity } from 'RestClient/Client/Interface/ISystemConfigurationEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { AreaEntity } from 'RestClient/Client/Model/AreaEntity';
import { MapEntity } from 'RestClient/Client/Model/MapEntity';
import { SystemConfigurationEntity } from 'RestClient/Client/Model/SystemConfigurationEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { UserTokenData } from 'RestClient/Client/Parameters/UserTokenData';
import { Debouncer } from 'RestClient/Helpers/Debouncer';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { ListFieldObjectSerializer } from 'WebClient/Model/ListFieldObjectSerializer';
import { WebAppClient } from 'WebClient/WebAppClient';
import { MapController } from '../../controllers/map.controller';
import {
    ConcreteWebMapObjectFilter,
    FloorQuery,
    IFloorQueryResult,
    IWebMapLayer,
    IWebMapNotificationFilter,
    IWebMapObject,
    IWebMapObjectType,
    WebMapLayerNotificationFilter,
    WebMapNotificationFilterRequest,
    WebMapObject,
    WebMapObjectFilter,
} from '../../controllers/map.controller.data';
import { IMapController } from '../../controllers/map.controller.interfaces';
import { MapObjectAddOrUpdateEvent } from '../../data/MapObjectAddOrUpdateEvent';
import { MapObjectRemoveEvent } from '../../data/MapObjectRemoveEvent';
import { ImageWebMapObject, ShapeWebMapObject, TextWebMapObject } from '../../data/maps-data';
import { MapEventProvider } from './map-event-provider/map-event-provider';
import { MapProviderLoader } from './map-provider/map-provider-loader';
import { MapProviderLoaderOptions } from './map-provider/map-provider.interface';

// Interface for a WebMapObject with an extra property added by a separate request (getFileCache).
interface WebMapObjectWithCustomIcon extends IWebMapObject {
    customIconSrc?: string;
}

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class MapService implements PublicMapService {
    public map?: WebMap;

    public get onMapObjectSelected$(): Observable<MapObjectEvent> {
        return this.onMapObjectSelectedSubject.asObservable();
    }

    public get onMapObjectUnselected$(): Observable<MapObjectView> {
        return this.onMapObjectUnselectedSubject.asObservable();
    }

    public get onMapObjectMouseEnter$(): Observable<MapObjectView> {
        return this.onMapObjectMouseEnterSubject.asObservable();
    }

    public get onMapObjectMouseLeave$(): Observable<MapObjectView> {
        return this.onMapObjectMouseLeaveSubject.asObservable();
    }

    public get onMapTypesLoaded$(): Observable<void> {
        return this.onMapTypesLoadedSubject.asObservable();
    }

    public get onMapLoaded$(): Observable<void> {
        return this.onMapLoadedSubject.asObservable();
    }

    public get onEventAdded$(): Observable<MapObjectAddOrUpdateEvent> {
        return this.onEventAddedSubject.asObservable();
    }

    public get onEventRemoved$(): Observable<MapObjectRemoveEvent> {
        return this.onEventRemovedSubject.asObservable();
    }

    public get onMapUnavailable$(): Observable<string> {
        return this.onMapUnavailableSubject.asObservable();
    }

    public get onMiniMapStateChanged$(): Observable<MiniMapStateChangeEvent> {
        return this.onMiniMapStateChangedSubject.asObservable();
    }

    //TODO YN: This event is to be removed and replaced with commands with the composable Tasks, for now available to let plugins change map on the main task
    public get onSelectionRequested$(): Observable<MapSelectionData> {
        return this.onSelectionRequestedSubject.asObservable();
    }

    //TODO YN: This event is to be removed and replaced with commands with the composable Tasks, for now available to let plugins change content on the map task
    public get onUpdateContextualDataRequested$(): Observable<ContentGroup> {
        return this.onUpdateContextualDataRequestedSubject.asObservable();
    }

    //TODO YN: This event is to be removed and replaced with commands with the composable Tasks, for now available to let plugins change content on the map task
    public get onClearContextualDataRequested$(): Observable<IGuid> {
        return this.onClearContextualDataRequestedSubject.asObservable();
    }

    public layers: ObservableCollection<IWebMapLayer> | null = null;

    public get mapAvailable(): boolean {
        return this.mapAvailableSubject.getValue();
    }
    public mapAvailable$: Observable<boolean>;

    public currentMapEntity: IMapEntity | null = null;

    public loadingMapObjects$: Observable<boolean>;

    public get displayedLayerIds(): IGuid[] {
        return this.currentlyDisplayedLayers.map((item) => new SafeGuid(item));
    }

    protected readonly scClient: WebAppClient;

    private onMapObjectSelectedSubject = new Subject<MapObjectEvent>();
    private onMapObjectUnselectedSubject = new Subject<MapObjectView>();
    private onMapObjectMouseEnterSubject = new Subject<MapObjectView>();
    private onMapObjectMouseLeaveSubject = new Subject<MapObjectView>();
    private onMapTypesLoadedSubject = new Subject<void>();
    private onMapLoadedSubject = new Subject<void>();
    private onEventAddedSubject = new Subject<MapObjectAddOrUpdateEvent>();
    private onEventRemovedSubject = new Subject<MapObjectRemoveEvent>();
    private onMapUnavailableSubject = new Subject<string>();
    private onMiniMapStateChangedSubject = new Subject<MiniMapStateChangeEvent>();

    //TODO YN: This event is to be removed and replaced with commands with the composable Tasks, for now available to let plugins change map on the main task
    private onSelectionRequestedSubject = new Subject<MapSelectionData>();
    //TODO YN: This event is to be removed and replaced with commands with the composable Tasks, for now available to let plugins change content on the map task
    private onUpdateContextualDataRequestedSubject = new Subject<ContentGroup>();
    private onClearContextualDataRequestedSubject = new Subject<IGuid>();

    private readonly markerRefreshMinDelay = 200;
    private readonly markerRefreshMaxDelay = 2000;
    private readonly loadingDisplayTimeout = 1000;

    private readonly requesterId = SafeGuid.newGuid();

    private debouncerMarkerDownload!: Debouncer;
    private markersToDownload = SafeGuid.createSet();
    private markersToUpdateIfVisible = SafeGuid.createSet();

    private mapLoadItemsDownloaded = false;
    private readonly mapProviderLoader: MapProviderLoader;
    private mapObjectTypes = new Map<string, IWebMapObjectType>();
    private currentlyDisplayedLayers!: IGuid[];
    private hiddenMapObjectTypes: IGuid[] = [];
    private mapEventProvider: MapEventProvider | undefined;
    private subscriptions = new SubscriptionCollection();
    private eventSubscriptions = new SubscriptionCollection();
    private callMapLoadedOnVisibilityChange = false;
    private visibilityChangedHandler: () => void;
    private mapEntityCacheTask?: IEntityCacheTask;

    private mapAvailableSubject = new BehaviorSubject<boolean>(true);
    private loadingMapObjectsSubject = new BehaviorSubject<boolean>(false);

    // Map Object Type Ids that we do not want to see when they are marked "offline"
    private onlyOnlineMapObjectTypes = new Set<string>();

    private eventsRegistered = false;

    private readonly mapEntityFieldsToDownload = [
        MapEntityFields.geoLocalizedField,
        MapEntityFields.maxZoomField,
        MapEntityFields.providerIdField,
        MapEntityFields.versionField,
        MapEntityFields.associatedAreaField,
    ];

    constructor(
        securityCenterProvider: SecurityCenterClientService,
        mapsProviderClient: MapsProviderClient,
        private loggerService: LoggerService,
        private resourceService: ResourcesService,
        @Inject(CONTENT_SERVICES_PROVIDER) contentServicesProvider: ContentExtensionServicesProvider,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        private translateService: TranslateService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService,
        @Inject(WINDOW) private window: Window,
        private domSanitizer: DomSanitizer
    ) {
        this.scClient = securityCenterProvider?.scClient;
        this.mapProviderLoader = new MapProviderLoader(this, this.scClient, mapsProviderClient, this.loggerService, commandsService, translateService, userSettingsService);

        this.setupDebouncer();

        if (this.scClient) {
            this.subscriptions.add(this.scClient.onTokenApplied((tokenData) => this.onTokenApplied(tokenData)));
            this.subscriptions.add(
                this.scClient.onLogonStateChanged((state) => {
                    if (!state.loggedOn()) {
                        this.emptyMap(true);
                        this.mapEntityCacheTask?.dispose().fireAndForget();
                    }
                })
            );
        }

        this.subscriptions.add(
            this.userSettingsService.onSettingsChanged$.subscribe(() => {
                const miniMap = this.getMiniMap();
                if (miniMap) {
                    const miniMapDisabled = this.userSettingsService.get<boolean>(OptionTypes.Maps, MapOptionsComponent.disableMinimapSettingId);
                    if (miniMapDisabled) {
                        miniMap.hide();
                    } else {
                        miniMap.show();
                    }
                }

                const showOfflineMobileUsers = this.userSettingsService.get<boolean>(OptionTypes.Maps, MapOptionsComponent.showOfflineMobileUsersId);
                if (showOfflineMobileUsers) {
                    this.removeOnlyOnlineMapObjectTypeFilter(EntityTypeIds.MobileApp);
                } else {
                    this.addOnlyOnlineMapObjectTypeFilter(EntityTypeIds.MobileApp);
                }
            })
        );

        // set this map as a public interface for external usages, keep PublicMapService as limited as possible
        contentServicesProvider.setService(mapsServiceId, this as PublicMapService);

        // If a map is created while we are not the active window, the map container's size could be wrong
        // and mess up the map.
        this.visibilityChangedHandler = this.onDocumentVisbilityChanged.bind(this);
        document.addEventListener('visibilitychange', this.visibilityChangedHandler);

        this.mapAvailable$ = this.mapAvailableSubject.asObservable();
        this.loadingMapObjects$ = this.loadingMapObjectsSubject.asObservable();
    }

    public destroy(): void {
        this.map?.off();
        this.debouncerMarkerDownload?.dispose();
        this.mapProviderLoader?.destroy();
        this.subscriptions.unsubscribeAll();
        this.cleanMapEventProvider();

        this.emptyMap(true);

        document.removeEventListener('visibilitychange', this.visibilityChangedHandler);
        this.mapEntityCacheTask?.dispose().fireAndForget();
    }

    public async loadMapAsync<T extends LeafletMapProviderBase>(provider: MapProviderClassType<T>, mapOptions: MapOptions, providerOptions: T['options']): Promise<T> {
        if (!this.map?.isMapInitialized()) {
            this.map = new WebMap('map', mapOptions);

            // Removes the Leaflet attribution
            this.map.leafletMap?.attributionControl?.setPrefix('');

            this.eventsRegistered = false;
        }

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.map.whenReady(this.onLoaded, this);

        if (!this.eventsRegistered) {
            // 'this' is set through parameter */
            /* eslint-disable @typescript-eslint/unbound-method */
            this.loggerService.traceDebug('Map-service : Make sure this warning doesnt appear more than once consequently! May be an indication of double widgets in sidepane');
            this.map.on('moveend', this.onAreaViewChanged, this);
            this.map.on('zoomend', this.onAreaViewChanged, this);
            this.map.on('mapobjectadd', this.onMarkerAdded, this);
            this.map.on('mapobjectshow', this.onMarkerShown, this);
            /* eslint-enable @typescript-eslint/unbound-method */
            this.map.on('mapobjectselect', (event: MapObjectEvent) => this.onMapObjectSelectedSubject.next(event));
            this.map.on('mapobjectunselect', (event: MapObjectEvent) => this.onMapObjectUnselectedSubject.next(event.mapObjectView));
            this.map.on('mapobjectmouseenter', (event: MapObjectEvent) => this.onMapObjectMouseEnterSubject.next(event.mapObjectView));
            this.map.on('mapobjectmouseleave', (event: MapObjectEvent) => this.onMapObjectMouseLeaveSubject.next(event.mapObjectView));

            this.map.on('minimapstatechange', (event: MiniMapStateChangeEvent) => this.onMiniMapStateChangedSubject.next(event));

            this.eventsRegistered = true;
        }

        return this.map.loadProviderAsync(provider, providerOptions, mapOptions);
    }

    public async initializeMap(options: InitializeMapOptions): Promise<void> {
        this.cleanMapEventProvider();

        if (!this.scClient.isLoggedOn) {
            return;
        }

        if (this.currentMapEntity) {
            this.emptyMap();
        }

        const map = await this.getMapEntityAsync(options.mapId, options.withEvents);

        if (map?.id.equals(Guids.DefaultMapId)) {
            map.name = this.translateService.instant('STE_LABEL_WORLD_MAP') as string;
        }

        const mapLoaded = !!(map && (await this.loadMapProviderAsync(map, options)));
        if (mapLoaded) {
            // map event provider is always loaded for the state changes
            this.mapEventProvider = new MapEventProvider(this.scClient, this);
            if (options.withEvents) {
                this.eventSubscriptions.add(
                    this.mapEventProvider.onEventAdded$.subscribe((mapEvent) => {
                        this.onEventAddedSubject.next(mapEvent);
                    }),
                    this.mapEventProvider.onEventRemoved$.subscribe((mapEvent) => this.onEventRemovedSubject.next(mapEvent))
                );
                this.map?.on('contextmenu', (e: LeafletMouseEvent) => this.onContextMenu(e));
            }
        } else {
            if (!mapLoaded) {
                this.currentMapEntity = null;
                let errorMessage;
                if (map) {
                    errorMessage = options.mapId
                        ? (this.translateService.instant('STE_MESSAGE_ERROR_MAPTYPENOTSUPPORTED') as string)
                        : (this.translateService.instant('STE_MESSAGE_ERROR_DEFAULTMAPUNDEFINED') as string);
                } else {
                    errorMessage = this.translateService.instant('STE_MESSAGE_ERROR_MAP_UNAVAILABLE_OR_INSUFFICIENT_PRIVILEGES') as string;
                }
                this.onMapUnavailableSubject.next(errorMessage);

                this.map?.clearMap();
            }
        }
        this.mapAvailableSubject.next(mapLoaded);
    }

    public async refreshLayers(layerIds: IGuid[]): Promise<void> {
        if (this.currentMapEntity) {
            const layersToRefresh = this.currentlyDisplayedLayers.filter((layer) => layerIds.some((layerId) => layerId.equals(layer)));

            const typesToRefresh = this.getTypesFromLayers(layerIds);

            this.map?.clearItemsOfTypes(typesToRefresh.map((item) => item.toString()));
            await this.insertMapObjectsFromLayersAsync(this.currentMapEntity.id, layersToRefresh);
        }
    }

    public async getNotificationFilter(): Promise<IWebMapNotificationFilter | null> {
        const mapController = await this.scClient.getAsync<MapController, IMapController>(MapController);
        return this.getNotificationFilterInternal(mapController);
    }

    public addOnlyOnlineMapObjectTypeFilter(mapObjectTypeId: IGuid): void {
        const idString = mapObjectTypeId.toLowerCase();
        if (!this.onlyOnlineMapObjectTypes.has(idString)) {
            this.onlyOnlineMapObjectTypes.add(idString);
            if (this.currentMapEntity) {
                this.refreshLayers([mapObjectTypeId]).fireAndForget();
            }
        }
    }

    public removeOnlyOnlineMapObjectTypeFilter(mapObjectTypeId: IGuid): void {
        const idString = mapObjectTypeId.toLowerCase();
        if (this.onlyOnlineMapObjectTypes.has(idString)) {
            this.onlyOnlineMapObjectTypes.delete(idString);
            if (this.currentMapEntity) {
                this.refreshLayers([mapObjectTypeId]).fireAndForget();
            }
        }
    }

    public async setVisibleLayers(layerIds: IGuid[]): Promise<void> {
        await this.updateNotificationFilter(layerIds);

        if (this.currentMapEntity) {
            let excludedLayers: IGuid[] = [];
            let layersToAdd = layerIds;

            if (this.currentlyDisplayedLayers) {
                excludedLayers = this.currentlyDisplayedLayers.filter((layer) => !layerIds.some((layerId) => layerId.equals(layer)));
                layersToAdd = layerIds.filter((layerId) => !this.currentlyDisplayedLayers.some((layer) => layer.equals(layerId)));
            }

            const excludedTypes = this.getTypesFromLayers(excludedLayers);

            this.currentlyDisplayedLayers = layerIds;

            this.map?.clearItemsOfTypes(excludedTypes.map((item) => item.toString()));
            if (this.layers) {
                const hiddenLayers = this.layers.where((item) => !this.currentlyDisplayedLayers.some((layer) => item.id.equals(layer)));
                this.hiddenMapObjectTypes = Array.from(hiddenLayers, (item) => item.id);
            }

            await this.insertMapObjectsFromLayersAsync(this.currentMapEntity.id, layersToAdd);
        }
    }

    public displayMapObject(mapObjectId: IGuid): void {
        this.deferDisplayMapObject(mapObjectId);
    }

    public updateMapObjectIfVisible(mapObjectId: IGuid): void {
        this.deferUpdateMapObject(mapObjectId);
    }

    /**
     * Empties the map of all map objects. Optionally it can also destroy to map itself.
     *
     * @param destroyMap - If true, the map will be destroy and will have to be recreated from scratch the next time
     * we create a map.
     */
    public emptyMap(destroyMap = false): void {
        this.mapLoadItemsDownloaded = false;
        this.currentlyDisplayedLayers = [];
        if (this.currentMapEntity) {
            this.clearNotificationFilter().fireAndForget();

            this.mapObjectTypes.clear();
            try {
                if (this.map?.isMapInitialized()) {
                    if (destroyMap) {
                        this.currentMapEntity = null;
                        this.map.clearMap();
                    } else {
                        this.map.clearAllItems();
                    }
                }
            } catch (exception) {
                this.loggerService.traceError(toError('Unable to clear map', exception));
                this.loggerService.traceDebug('Map-Service: Make sure this warning corresponds the same number of times as the later one');
            }
        }
    }

    public async getMapObjectsFromSourceIds(sourceIds: string[]): Promise<IWebMapObject[]> {
        let mapObjects: IWebMapObject[] = [];
        if (this.currentMapEntity) {
            const filter = new ConcreteWebMapObjectFilter();
            filter.mapId = this.currentMapEntity.id;
            filter.sourceIds = ObservableCollection.From(sourceIds);
            mapObjects = await this.getMapObjects(filter);
        }
        return mapObjects;
    }

    public async getMapObjectsFromIds(sourceIds: Set<IGuid>): Promise<IWebMapObject[]> {
        let mapObjects: IWebMapObject[] = [];
        if (this.currentMapEntity) {
            const filter = new ConcreteWebMapObjectFilter();
            filter.mapId = this.currentMapEntity.id;
            filter.mapObjectIds = ObservableCollection.From(Array.from(sourceIds));
            mapObjects = await this.getMapObjects(filter);
        }
        return mapObjects;
    }

    public insertMapObjects(mapObjects: (IWebMapObject | WebMapObjectWithCustomIcon)[], areFullObjects: boolean): void {
        if (!this.map?.isMapInitialized() || !this.currentMapEntity) {
            this.loggerService.traceError('Map is not initialized!');
            return;
        }

        const genMapObjects: Set<MapObject> = new Set<MapObject>();
        const getImagePromises: Promise<ImageMapObject | null>[] = [];

        mapObjects.forEach((mapObject) => {
            const webMapObjectType = this.mapObjectTypes.get(mapObject.type.toString());
            if (webMapObjectType === undefined) {
                this.loggerService.traceError(`WebMap object type undefined ${mapObject.type.toString()}`);
                return;
            }

            if (mapObject.latitude === 0 && mapObject.longitude === 0) {
                return;
            }

            if (this.hiddenMapObjectTypes.some((type) => type.equals(mapObject.type))) {
                return;
            }

            const mapObjectId = mapObject.guid.toLowerCase();
            switch (webMapObjectType.category) {
                case MapObjectCategory.Image:
                    if (!areFullObjects) {
                        this.deferDisplayMapObject(mapObject.guid);
                    } else {
                        // In forEach, doesn't know it's not null
                        const image = new ImageWebMapObject();
                        image.loadFrom(mapObject);

                        const mapEntity = this.currentMapEntity as IMapEntity;

                        if (image.file) {
                            // Get the files data from the cache or server, then add all the images at once later once the data was received.
                            getImagePromises.push(
                                this.resourceService.getFilesCacheAsync([{ entity: mapEntity, fileCacheId: image.file }]).then((fileResult) => {
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    const data = fileResult[image.file!.toString()];
                                    if (data) {
                                        return {
                                            id: mapObjectId,
                                            latitude: mapObject.latitude,
                                            longitude: mapObject.longitude,
                                            type: mapObject.type.toString().toLowerCase(),
                                            link: mapObject.source,
                                            width: image.width,
                                            height: image.height,
                                            stretch: image.stretch,
                                            rotation: image.rotation,
                                            selectable: mapObject.source.length > 0 || (mapObject.additionalLinks && mapObject.additionalLinks.size > 0),
                                            imageSrc: (data && `data:image/png;base64,${data}`) || undefined,
                                            tooltipContent: this.sanitize(mapObject.name),
                                        } as ImageMapObject;
                                    } else {
                                        return null;
                                    }
                                })
                            );
                        }
                    }
                    break;
                case MapObjectCategory.Shape:
                    {
                        const latitude = mapObject.latitude;
                        const longitude = mapObject.longitude;
                        // shapes need extra data for the points coordinate, cannot display right away if not expanded.
                        if (!areFullObjects) {
                            // Add a placeholder shape while waiting for full map objects
                            genMapObjects.add({
                                opacity: 0,
                                id: mapObjectId,
                                type: mapObject.type.toString().toLowerCase(),
                                link: mapObject.source,
                                shape: this.getShapeTypeFromMapObjectType(webMapObjectType),
                                points: [
                                    {
                                        latitude,
                                        longitude,
                                    },
                                    {
                                        latitude,
                                        longitude,
                                    },
                                    {
                                        latitude,
                                        longitude,
                                    },
                                    {
                                        latitude,
                                        longitude,
                                    },
                                ],
                                popupPosition: new LatLng(latitude, longitude),
                            } as ShapeMapObject);
                            this.deferDisplayMapObject(mapObject.guid);
                        } else {
                            const shape = new ShapeWebMapObject();
                            shape.loadFrom(mapObject);

                            genMapObjects.add({
                                id: mapObjectId,
                                opacity: shape.opacity,
                                background: shape.stateColor,
                                borderColor: shape.borderColor,
                                type: mapObject.type.toString().toLowerCase(),
                                link: mapObject.source,
                                borderThickness: shape.borderThickness,
                                shape: this.getShapeTypeFromMapObjectType(webMapObjectType),
                                selectable: shape.source.length > 0 || (shape.additionalLinks && shape.additionalLinks.size > 0),
                                rotation: shape.rotation,
                                points: [...shape.points].map((point) => ({ latitude: point.Y, longitude: point.X })),
                                isPolyline: shape.isPolyline,
                                tooltipContent: this.sanitize(mapObject.name),
                                popupPosition: new LatLng(latitude, longitude),
                            } as ShapeMapObject);

                            // If there's an unopened popup bound to the placeholder shape
                            const existingShape = this.map?.getMapObjectView(mapObjectId);
                            if (existingShape?.getPopup() && (existingShape?.options.data as ShapeMapObject).opacity === 0) {
                                existingShape.openPopup();
                            }
                        }
                    }
                    break;
                case MapObjectCategory.Text:
                    // text need extra data for the text and styling, cannot display right away if not expanded.
                    if (!areFullObjects) {
                        this.deferDisplayMapObject(mapObject.guid);
                    } else {
                        const text = new TextWebMapObject();
                        text.loadFrom(mapObject);

                        genMapObjects.add({
                            id: mapObjectId,
                            latitude: mapObject.latitude,
                            longitude: mapObject.longitude,
                            type: mapObject.type.toString().toLowerCase(),
                            link: mapObject.source,
                            rotation: mapObject.rotation,
                            selectable: text.source.length > 0 || (text.additionalLinks && text.additionalLinks.size > 0),
                            width: text.width,
                            height: text.height,
                            backgroundColor: text.stateColor,
                            borderColor: text.borderColor,
                            borderThickness: text.borderThickness,
                            opacity: text.opacity,
                            fontFamily: text.fontFamily,
                            fontIsBold: text.fontIsBold,
                            fontIsItalic: text.fontIsItalic,
                            fontIsUnderline: text.fontIsUnderline,
                            isShadowDisplayed: text.isShadowDisplayed,
                            fontSize: text.fontSize,
                            foreground: text.foreground,
                            text: text.text,
                            textAlignment: text.textAlignment,
                            tooltipContent: this.sanitize(mapObject.name),
                        } as TextMapObject);
                    }
                    break;
                case MapObjectCategory.Marker: {
                    if (!areFullObjects && !webMapObjectType.clusterable) {
                        // always full display non clustered markers
                        this.deferDisplayMapObject(mapObject.guid);
                    } else {
                        let icon: MarkerIcon | undefined;
                        let overlay: MarkerOverlayIcon | null = null;

                        const color = (areFullObjects && mapObject.stateColor) || webMapObjectType.color;
                        const markerOptions = {
                            style: MarkerIconStyle.Circle,
                            color,
                        };

                        let customIcon: string | null;
                        // Set the custom icon (from SC)
                        if ('customIconSrc' in mapObject && mapObject.customIconSrc) {
                            customIcon = mapObject.customIconSrc;
                        } else {
                            // Make sure the customIcon is a valid data uri for img src
                            customIcon = webMapObjectType.customIcon?.startsWith('data:image') ? webMapObjectType.customIcon : null;
                        }
                        const overridenImgSrcIcon = (areFullObjects && mapObject.overridenMainIcon?.startsWith('data:image') && mapObject.overridenMainIcon) || null;
                        const iconImgSrc = overridenImgSrcIcon || customIcon;

                        if (iconImgSrc) {
                            icon = new MarkerImgIcon({
                                ...markerOptions,
                                iconImgSrc,
                            });
                        } else if (areFullObjects && mapObject.lettersToDisplayOnMarker) {
                            // More initials mean lower font size (shouldn't go over 3 letters)
                            let fontSize = 25;
                            if (mapObject.lettersToDisplayOnMarker.length >= 3) {
                                fontSize = 10;
                            } else if (mapObject.lettersToDisplayOnMarker.length === 2) {
                                fontSize = 15;
                            }

                            icon = new MarkerTextIcon({
                                ...markerOptions,
                                iconText: mapObject.lettersToDisplayOnMarker,
                                innerIconSize: fontSize,
                            });
                        } else {
                            icon = new MarkerClassNameIcon({
                                ...markerOptions,
                                iconClassName: (areFullObjects && mapObject.overridenMainIcon) || webMapObjectType.icon || '',
                            });
                        }

                        if (areFullObjects && mapObject.overlayIcon) {
                            overlay = new MarkerClassNameOverlayIcon({
                                color: mapObject.overlayIconColor,
                                backgroundColor: 'black',
                                iconClassName: mapObject.overlayIcon,
                            });
                        }

                        genMapObjects.add({
                            id: mapObjectId,
                            type: mapObject.type.toString().toLocaleLowerCase(),
                            clusterable: webMapObjectType.clusterable,
                            selectable: mapObject.source.length > 0,
                            latitude: mapObject.latitude,
                            longitude: mapObject.longitude,
                            link: mapObject.source,
                            tooltipContent: this.sanitize(mapObject.name),
                            icon,
                            overlay,
                        });
                    }
                    break;
                }
            }
        });

        this.map.addOrUpdateMapObjects(genMapObjects);

        // Add all image map objects once files are downloaded
        Promise.all(getImagePromises)
            .then((imageMapObjects: (ImageMapObject | null)[]) => {
                // Removes results that were null
                const imageMapObjectsToAdd = imageMapObjects.filter((imageMapObject) => imageMapObject) as ImageMapObject[];
                this.map?.addOrUpdateMapObjects(imageMapObjectsToAdd);
            })
            .catch((e) => {
                console.log(`Error adding image map objects on the map: ${(e as Error).message}`);
            });
    }

    public removeMapObjects(mapObjects: IWebMapObject[]): void {
        this.map?.removeItems(mapObjects.map((mapObject) => mapObject.guid.toLowerCase()));
    }

    public updateContextualData(content: ContentGroup): void {
        this.onUpdateContextualDataRequestedSubject.next(content);
    }

    public clearContextualData(contentId: IGuid): void {
        this.onClearContextualDataRequestedSubject.next(contentId);
    }

    public select(mapObjectId: string, mapId?: IGuid): void {
        this.onSelectionRequestedSubject.next({ mapObjectId, mapId });
    }

    public selectAndZoom(mapObjectId: string): void {
        if (!this.map?.isMapInitialized()) {
            this.loggerService.traceError('Map is not initialized!');
            return;
        }

        let selectedMapView: MapObjectView | undefined;
        const tryGuid = SafeGuid.tryParse(mapObjectId);
        if (tryGuid.success) {
            selectedMapView = this.map.getMapObjectView(tryGuid.value.toTypescriptGuid());
        } else {
            selectedMapView = this.map.getMapObjectView(mapObjectId);
        }

        if (selectedMapView instanceof Marker) {
            const offset = 0.003;
            this.map.fitBounds([
                [selectedMapView.getLatLng().lat + offset, selectedMapView.getLatLng().lng - offset],
                [selectedMapView.getLatLng().lat - offset, selectedMapView.getLatLng().lng + offset],
            ]);
        } else if (selectedMapView instanceof Polyline) {
            this.map.fitBounds(selectedMapView.getBounds().pad(0.25));
        }

        this.map.centerMapObject(mapObjectId);
        this.map.select(mapObjectId);
    }

    public async updateNotificationFilterFromTypes(types: IGuid[]): Promise<void> {
        const mapController = await this.scClient.getAsync<MapController, IMapController>(MapController);
        const filter = await this.getNotificationFilterInternal(mapController);

        if (mapController && filter && this.mapObjectTypes) {
            // update server
            filter.layerFilters.clear();

            for (const objectType of this.mapObjectTypes.values()) {
                const layerFilter = new WebMapLayerNotificationFilter();
                layerFilter.layerId = objectType.layerId;
                layerFilter.receiveNotifications = types.some((item) => item.equals(objectType.typeId));
                filter.layerFilters.add(layerFilter);
            }
            await this.scClient.updateAsync(mapController);
        }
    }

    public addPin(pinId: string, location: { latitude: number; longitude: number }): void {
        this.map?.addOrUpdateMapObjects({
            id: pinId,
            zIndex: 50,
            latitude: location.latitude,
            longitude: location.longitude,
            type: 'pin',
            selectable: false,
            clusterable: false,
            icon: new DivIcon({
                html: `<i class="gen-ico-map-pin" style="font-size: 40px; color: #000"></i>`,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                className: `gwm-marker`,
            }),
        });
    }

    public removePin(pinId: string): void {
        this.map?.removeItem(pinId);
    }

    // events are shown only on items outside of a cluster and events that are permanent (i.e. alarms)
    public filterEventsReceiverItems(items: IGuid[], criticalEvent: boolean): MapObjectView[] {
        const displayedMapObjects: MapObjectView[] = [];

        items.forEach((mapObjectId) => {
            const mapObject = this.map?.getMapObjectView(mapObjectId.toString());
            if (mapObject) {
                const marker = mapObject as Marker;
                if (marker?.category === MapObjectCategory.Marker && criticalEvent) {
                    if (!marker.topMostCluster) {
                        displayedMapObjects.push(mapObject);
                    }
                } else {
                    displayedMapObjects.push(mapObject);
                }
            }
        });

        return displayedMapObjects;
    }

    public async getFloors(mapId: IGuid, parentAreaId: IGuid): Promise<IFloorQueryResult | null> {
        if (mapId.isEmpty()) {
            return null;
        }

        const mapController = await this.scClient.getAsync<MapController, IMapController>(MapController);
        if (!mapController) {
            return null;
        }

        const query = new FloorQuery();
        query.loadFields({
            mapId,
            parentAreaId,
        });
        return mapController.getFloorsAsync(query);
    }

    public getMiniMap(): MiniMap | undefined {
        return this.map?.miniMap;
    }

    public restoreMiniMapLeftOffset(transitionDuration: number): void {
        this.moveMiniMap(0, transitionDuration);
    }

    public moveMiniMapLeft(leftOffset: number, transitionDuration: number): void {
        this.moveMiniMap(leftOffset, transitionDuration);
    }

    public setMiniMapRightMargin(margin: number): void {
        this.getMiniMap()?.setRightMargin(margin);
    }

    private async loadMapProviderAsync(map: IMapEntity, options: InitializeMapOptions): Promise<boolean> {
        // Switching provider won't keep the same view area if the two providers have different georeference.
        if (options.previousViewArea && this.currentMapEntity && this.currentMapEntity.geoLocalized !== map.geoLocalized) {
            delete options.previousViewArea;
        }

        this.currentMapEntity = map;
        const mapProviderOptions = await this.createMapProviderOptionsAsync(map, options);
        return this.mapProviderLoader.loadMapAsync(map, mapProviderOptions);
    }

    private async getDefaultMapIdAsync(): Promise<IGuid | null> {
        let mapId: IGuid | null = null;
        const user = await this.scClient.getEntityAsync<UserEntity, IUserEntity>(UserEntity, this.scClient.userId);
        if (user) {
            const areaId = user.defaultMapId;
            const area = await this.scClient.getEntityAsync<AreaEntity, IAreaEntity>(AreaEntity, areaId);
            if (area) {
                mapId = area.link;
            }
        }

        if (!mapId) {
            const systemConfig = await this.scClient.getEntityAsync<SystemConfigurationEntity, ISystemConfigurationEntity>(SystemConfigurationEntity, Guids.SystemConfiguration);
            if (systemConfig) {
                mapId = (await systemConfig.getDefaultSystemMapAsync()) ?? SafeGuid.EMPTY;
            }
        }

        return mapId;
    }

    private moveMiniMap(leftOffset: number, transitionDuration: number): void {
        this.getMiniMap()?.moveLeft(leftOffset, transitionDuration);
    }

    private async createMapProviderOptionsAsync(mapEntity: IMapEntity, options: InitializeMapOptions): Promise<MapProviderLoaderOptions> {
        const geoReference = (mapEntity.geoLocalized && (await mapEntity.getGeoReferenceAsync())) || undefined;

        const mapProviderOptions: MapProviderLoaderOptions = {
            geoReference,
            userLocationEnabled: options.userLocationEnabled,
        };

        await this.applyProviderViewOptionsAsync(mapEntity, mapProviderOptions, options.previousViewArea);

        return mapProviderOptions;
    }

    private async applyProviderViewOptionsAsync(mapEntity: IMapEntity, providerOptions: MapProviderLoaderOptions, viewArea?: LatLngBounds) {
        if (!viewArea) {
            const defaultView = await mapEntity.getDefaultViewAsync();
            if (this.isDefaultViewValid(defaultView)) {
                providerOptions.defaultView = new LatLngBounds(
                    { lat: defaultView.viewArea.bottomRight.latitude, lng: defaultView.viewArea.topLeft.longitude },
                    { lat: defaultView.viewArea.topLeft.latitude, lng: defaultView.viewArea.bottomRight.longitude }
                );
            } else {
                providerOptions.center = new LatLng(0, 0);
            }
        } else {
            providerOptions.defaultView = viewArea;
        }
    }

    private async getMapEntityAsync(id: IGuid | null, withEvents: boolean): Promise<IMapEntity | null> {
        const mapId = id ?? (await this.getDefaultMapIdAsync());
        if (!mapId) {
            return null;
        }

        if (!this.mapEntityCacheTask || (this.currentMapEntity && mapId !== this.currentMapEntity.id)) {
            await this.mapEntityCacheTask?.dispose();
            this.mapEntityCacheTask = this.scClient.buildEntityCache(this.mapEntityFieldsToDownload.toString());
        }

        const mapEntity = await this.mapEntityCacheTask.getEntityAsync<MapEntity, IMapEntity>(MapEntity, mapId, true);
        if (mapEntity && mapEntity.id !== mapId) {
            // Detect background and georeference changes. Reload map when it happens.
            this.mapEntityCacheTask
                .detectRelationChangeAsync(
                    mapEntity,
                    () => {
                        const val = mapEntity?.getGeoReferenceAsync();
                    },
                    async (newEntity: IEntity): Promise<void> => {
                        // entity cache task returns an Entity instance if the entity was in the cache
                        // so we need to create a MapEntity ourselves.
                        const newMapEntity = new MapEntity();
                        newMapEntity.loadFrom(newEntity);
                        return this.loadMapProviderAsync(newMapEntity, {
                            mapId: newEntity.id,
                            previousViewArea: this.map?.getBounds(),
                            userLocationEnabled: !!this.map?.options.userLocationEnabled,
                            withEvents,
                        }).then(() => {
                            return this.refreshLayers(this.currentlyDisplayedLayers);
                        });
                    }
                )
                .fireAndForget();
        }

        return mapEntity;
    }

    private async getNotificationFilterInternal(mapController: IMapController): Promise<IWebMapNotificationFilter | null> {
        if (!mapController || !this.currentMapEntity) {
            return null;
        }

        const webMapNotificationFilterRequest = new WebMapNotificationFilterRequest();
        webMapNotificationFilterRequest.requesterId = this.requesterId;
        webMapNotificationFilterRequest.mapId = this.currentMapEntity.id;
        return await mapController.getNotificationFilterAsync(webMapNotificationFilterRequest);
    }

    private cleanMapEventProvider() {
        if (this.mapEventProvider) {
            this.eventSubscriptions.unsubscribeAll();
            this.mapEventProvider.destroy();
        }
    }

    // setup a debouncer to download data
    private setupDebouncer() {
        this.debouncerMarkerDownload = new Debouncer(
            false,
            async () => {
                for (const markerToUpdate of this.markersToUpdateIfVisible) {
                    // be sure not inside a cluster before downloading it
                    const marker = this.map?.getMapObjectView(markerToUpdate.toTypescriptGuid()) as Marker;
                    if (marker) {
                        if (marker.category === MapObjectCategory.Marker) {
                            if (!marker.topMostCluster) {
                                this.markersToDownload.add(markerToUpdate);
                            }
                        } else {
                            // non marker are always visible
                            this.markersToDownload.add(markerToUpdate);
                        }
                    }
                }

                await this.updateMapObjectsFromIds(this.markersToDownload);
                this.markersToUpdateIfVisible.clear();
                this.markersToDownload.clear();
            },
            this.markerRefreshMinDelay,
            this.markerRefreshMaxDelay
        );
    }

    private async updateMapObjectsFromIds(itemsToDownload: Set<IGuid>) {
        if (itemsToDownload.size > 0) {
            // only show loading if more than a second wait.
            const timeout = setTimeout(() => {
                this.loadingMapObjectsSubject.next(true);
            }, this.loadingDisplayTimeout);

            try {
                const fullObjects = await this.getMapObjectsFromIds(itemsToDownload);

                if (fullObjects?.length) {
                    const { mapObjectsToAdd, mapObjectsToRemove } = this.divideMapObjectToAddAndRemove(fullObjects);

                    this.insertMapObjects(mapObjectsToAdd, true);
                    this.removeMapObjects(mapObjectsToRemove);
                    this.loggerService.traceInformation(`Add or update ${itemsToDownload.size} full map objects`);
                }
            } finally {
                clearTimeout(timeout);
                this.loadingMapObjectsSubject.next(false);
            }
        }
    }

    private divideMapObjectToAddAndRemove(mapObjects: IWebMapObject[]) {
        return mapObjects.reduce(
            ({ mapObjectsToAdd, mapObjectsToRemove }, mapObject) => {
                const toAdd = mapObject.getFieldOrDefault('IsOnline') !== false || !this.onlyOnlineMapObjectTypes.has(mapObject.type.toLowerCase());
                (toAdd ? mapObjectsToAdd : mapObjectsToRemove).push(mapObject);
                return { mapObjectsToAdd, mapObjectsToRemove };
            },
            { mapObjectsToAdd: [] as IWebMapObject[], mapObjectsToRemove: [] as IWebMapObject[] }
        );
    }

    private clearNotificationFilter() {
        const layerIds: IGuid[] = [];
        return this.updateNotificationFilter(layerIds);
    }

    private updateNotificationFilter(layerIds: IGuid[]) {
        const layerTypes: IGuid[] = [];

        this.layers?.forEach((layer) => {
            if (layerIds.some((layerId) => layerId.equals(layer.id))) {
                layer.includedTypes.forEach((includedType) => {
                    layerTypes.push(includedType.typeId);
                });
            }
        });

        return this.updateNotificationFilterFromTypes(layerTypes);
    }

    private getTypesFromLayers(layers: IGuid[]): IGuid[] {
        // exclude all types part of the excluded layers
        const types: IGuid[] = [];

        this.layers?.forEach((layer) => {
            Array.from(layer.includedTypes.where((includedType) => layers.some((layer2) => includedType.layerId.equals(layer2)))).forEach((includedType) => {
                types.push(includedType.typeId);
            });
        });

        return types;
    }

    private async insertAllMapObjectTypes(map: IMapEntity) {
        const mapController = await this.scClient.getAsync<MapController, IMapController>(MapController);
        if (!map || !mapController) {
            return;
        }

        this.layers = await mapController.getAvailableLayersAsync(map.id);
        if (this.layers) {
            this.loggerService.traceInformation(`${this.layers.size} layers available`);

            this.insertMapObjectTypes(this.layers);
        }
    }

    private insertMapObjectTypes(layers: ObservableCollection<IWebMapLayer>) {
        if (this.mapLoadItemsDownloaded) {
            return;
        }

        if (!this.map?.isMapInitialized()) {
            this.loggerService.traceError('Map is not initialized! Map Object Types not added.');
            return;
        }

        this.mapLoadItemsDownloaded = true;

        layers.forEach((layer) => {
            layer.includedTypes.forEach((displayType) => {
                this.mapObjectTypes.set(displayType.typeId.toString(), displayType);
            });
        });

        this.onMapTypesLoadedSubject.next();
    }

    private async insertMapObjectsFromLayersAsync(mapId: IGuid, layers: Array<IGuid>) {
        const mapController = await this.scClient.getAsync<MapController, IMapController>(MapController);
        if (!mapController || !this.currentMapEntity || layers.length === 0) {
            return;
        }

        const filter = new WebMapObjectFilter();
        filter.mapId = mapId;
        filter.layerIds = ObservableCollection.From(layers);
        filter.onlyOnlineLayerIds = ObservableCollection.From(Array.from(this.onlyOnlineMapObjectTypes, (typeId) => SafeGuid.parse(typeId)));

        this.loadingMapObjectsSubject.next(true);
        await mapController.getAllMapObjectsAsync(filter, (size: number, buffer: ArrayBuffer) => {
            const data = buffer.slice(4, size);
            const mapObjects = ListFieldObjectSerializer.deserialize<WebMapObject, IWebMapObject>(WebMapObject, data);
            this.loggerService.traceInformation(`Add or update ${mapObjects.size} minimum size map objects`);
            this.insertMapObjects(Array.from(mapObjects), false);
        });
        this.loadingMapObjectsSubject.next(false);
    }

    private deferUpdateMapObject(id: IGuid) {
        this.markersToUpdateIfVisible.add(id);
        this.debouncerMarkerDownload.trigger();
    }

    private deferDisplayMapObject(id: IGuid) {
        this.markersToDownload.add(id);
        this.debouncerMarkerDownload.trigger();
    }

    private async getMapObjects(filter: ConcreteWebMapObjectFilter): Promise<IWebMapObject[]> {
        let mapObjects: WebMapObjectWithCustomIcon[] = [];
        const mapController = await this.scClient.getAsync<MapController, IMapController>(MapController);
        if (mapController) {
            const mapObjectResult = await mapController.getMapObjectsAsync(filter);
            if (mapObjectResult?.mapObjects) {
                mapObjects = mapObjectResult?.mapObjects?.values() as WebMapObjectWithCustomIcon[];
            }

            // Prepare query to get all custom icons from map objects that have custom icons
            const mapObjectsWithCustomIcons: { entity: string; customIconId: string; state?: CustomIconState; index: number }[] = [];
            mapObjects.forEach((mapObject, index) => {
                if (mapObject.customIconId && !mapObject.customIconId.equals(SafeGuid.EMPTY)) {
                    mapObjectsWithCustomIcons.push({
                        entity: mapObject.source,
                        customIconId: mapObject.customIconId.toString(),
                        state: mapObject.customIconState as CustomIconState,
                        index, // Keep track of index for faster editing of mapObjects in the mapObjects array
                    });
                }
            });

            try {
                if (mapObjectsWithCustomIcons.length) {
                    const customIconEntries = await this.resourceService.getCustomIconAsync(mapObjectsWithCustomIcons);
                    mapObjectsWithCustomIcons.forEach((mapObjectWithCustomIcon) => {
                        // Add the custom icon data to the map object to eventually add to the map
                        const mapObject = mapObjects[mapObjectWithCustomIcon.index];
                        const customIconData = customIconEntries[mapObject.source];
                        if (customIconData) {
                            mapObject.customIconSrc = `data:image/png;base64,${customIconData}`;
                        }
                    });
                }
            } catch (exception) {
                this.loggerService.traceError(toError('Unable to load custom icon for a map object', exception));
            }
        }
        return mapObjects;
    }

    private async onAreaViewChanged(event: LeafletEvent) {}

    private async onLoaded() {
        // Fixes Bug #2811272 Map glitch when not focused during page reload.
        // gelato-design-system-provider has its elements size (0, 0) on page load if tab is not
        // focused or browser is minimized. Map size will be invalidated and recalculated when the
        // page becomes visible.
        if (document.visibilityState !== 'visible') {
            this.callMapLoadedOnVisibilityChange = true;
        } else {
            this.onMapLoadedSubject.next();
        }

        if (this.currentMapEntity && this.mapObjectTypes.size === 0) {
            await this.insertAllMapObjectTypes(this.currentMapEntity);
        }
    }

    private onTokenApplied(tokenData: UserTokenData) {
        if (this.currentMapEntity) {
            this.mapProviderLoader.applyToken(this.currentMapEntity, tokenData.getAuthentificationHeader());
        }
    }

    private onMarkerAdded(event: MapObjectEvent) {}

    // this is the handler called when a marker is unclustered
    private onMarkerShown(event: MapObjectEvent) {
        const id = event.mapObjectView.id;
        const mapObjectType = event.mapObjectView?.data?.type;
        if (SafeGuid.isGuid(id) && mapObjectType) {
            // only update those in visible layers
            if (!this.hiddenMapObjectTypes.some((type) => type.equals(SafeGuid.parse(mapObjectType)))) {
                this.deferUpdateMapObject(SafeGuid.parse(id));
            }
        }
    }

    private onContextMenu(event: LeafletMouseEvent) {
        event.originalEvent.stopPropagation();

        if (this.currentMapEntity) {
            const mapContext: MapContext = {
                mapId: this.currentMapEntity.id,
                geoLocalised: this.currentMapEntity.geoLocalized,
                location: { latitude: event.latlng.lat, longitude: event.latlng.lng, altitude: event.latlng.alt },
            };

            if (!this.currentMapEntity.geoLocalized) {
                mapContext.location = { x: event.latlng.lng, y: event.latlng.lat };
            }

            this.commandsService.showContextMenuAsync(event.originalEvent, { type: ContextTypes.Map, data: mapContext }).fireAndForget();
        }
    }

    private getShapeTypeFromMapObjectType(webMapObjectType: IWebMapObjectType): ShapeType {
        return (Object.values(ShapeType).includes(webMapObjectType.name as ShapeType) ? webMapObjectType.name : ShapeType.Polygon) as ShapeType;
    }

    private onDocumentVisbilityChanged() {
        if (this.map && document.visibilityState === 'visible' && this.callMapLoadedOnVisibilityChange) {
            this.callMapLoadedOnVisibilityChange = false;
            this.window.requestAnimationFrame(() => {
                this.map?.invalidateSize();
                this.map
                    ?.reloadProviderAsync()
                    .then(() => {
                        this.onLoaded().fireAndForget();
                    })
                    .fireAndForget();
            });
        }
    }

    private isDefaultViewValid(defaultView: IDefaultView | null): defaultView is IDefaultView {
        const viewArea = defaultView?.viewArea;
        return !!viewArea && viewArea.topLeft.latitude !== 0 && viewArea.topLeft.longitude !== 0 && viewArea.bottomRight.latitude !== 0 && viewArea.bottomRight.longitude !== 0;
    }

    private sanitize(value: string): string | null {
        const valueWithoutBrackets = value.replace('<', '&lt;').replace('>', '&gt;');
        return this.domSanitizer.sanitize(SecurityContext.HTML, valueWithoutBrackets);
    }
}
