import { IGuid, SafeGuid } from 'safeguid';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.ChangeState;

export class ChangeStateCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentId: IGuid, stateId: IGuid) {
        super(
            `/v2/Incidents/${incidentId.toString()}`,
            JSON.stringify({
                Type: commandType,
                StateId: stateId.toString(),
            })
        );
    }

    public errorMessage(statusCode?: number): string {
        return 'STE_MESSAGE_CANNOT_CHANGE_STATE';
    }
}
