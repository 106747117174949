import { Component, OnInit, ViewChild } from '@angular/core';
import { ButtonFlavor, Icon, TextFlavor, ItemSlot, PopupPosition } from '@genetec/gelato';
import { GenListActions, GenMeltedListItem, GenPopup } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes, TraySubSections } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { MailboxEnvelop, MailboxService } from '../../services/mailbox.service';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-mailbox-tray',
    templateUrl: './mailbox-tray.component.html',
    styleUrls: ['./mailbox-tray.component.scss'],
})
@InternalPluginDescriptor({
    type: MailboxTrayComponent,
    pluginTypes: [PluginTypes.Tray],
    exposure: { id: MailboxTrayComponent.pluginId, subSection: TraySubSections.MainBar },
    isSupported: () => true,
})
export class MailboxTrayComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('2087902A-3390-45D1-8975-DE9F108DDBC0');

    @ViewChild(GenPopup, { static: true }) mailboxPopup!: GenPopup;

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;
    public readonly TextFlavor = TextFlavor;
    public readonly ItemSlot = ItemSlot;
    public readonly PopupPosition = PopupPosition;

    public mailList: GenMeltedListItem[] = [];
    public mailActions: GenListActions = {
        delete: {
            id: 'delete',
            execute: (itemsSelected?: Array<GenMeltedListItem>) => this.removeMail(itemsSelected ?? []),
            canExecute: () => true,
        },
    };

    constructor(private mailboxService: MailboxService, trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit() {
        this.mailboxService.mailboxChanged.pipe(untilDestroyed(this)).subscribe((envelops: MailboxEnvelop[]) => this.onMailboxChanged(envelops));
    }

    public async toggleIsPopupOpen(): Promise<void> {
        await this.mailboxPopup.toggle();
    }

    public async openMail(mail: GenMeltedListItem | null): Promise<void> {
        if (mail === null) {
            return;
        }
        this.mailboxService.executeHandler(SafeGuid.parse(mail.id));
        await this.mailboxPopup.close(); // once execute, close the popup
    }

    public async removeMail(mails: GenMeltedListItem[]): Promise<void> {
        if (this.mailList.length - mails.length === 0) {
            await this.mailboxPopup.close();
        }
        this.mailboxService.remove(...mails.map((x) => SafeGuid.parse(x.id)));
    }

    private onMailboxChanged(mails: MailboxEnvelop[]): void {
        this.mailList = mails.map(
            (x) =>
                ({
                    id: x.id.toString(),
                    text: x.title ?? '',
                    secondaryText: x.summary,
                    icon: x.icon,
                } as GenMeltedListItem)
        );
    }
}
