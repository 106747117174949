import { Component } from '@angular/core';
import { FilterTypes } from 'src/app/modules/shared/enumerations/filter-types';
import { TrackingService } from 'src/app/modules/shared/services/tracking.service';
import { InternalPluginDescriptor } from 'src/app/modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from 'src/app/modules/shared/interfaces/plugins/public/plugin-types';
import { SafeGuid } from 'safeguid';
import { FilterPluginBaseComponent } from './filter-plugin-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template: '<app-filter-text [descriptor]="descriptor" (valueChange)="updateFilter($event)"></app-filter-text>',
})
@InternalPluginDescriptor({
    type: FilterPluginTextComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginTextComponent.pluginId,
        subSection: FilterTypes.Text,
    },
    isSupported: () => true,
})
export class FilterPluginTextComponent extends FilterPluginBaseComponent {
    public static pluginId = SafeGuid.parse('DE7C988C-5294-45CE-AB36-4BCAAC2C0C9B');

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginTextComponent.pluginId);
    }
}
