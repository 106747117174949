// Autogenerated: v2/help/Entities/ApiDoorAccessPoint?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { IAccessPointEntity, AccessPointEntityFields } from './IAccessPointEntity';
import { IGuid } from 'safeguid';

// #region Fields

export class DoorAccessPointEntityFields extends AccessPointEntityFields {
    public static deviceField = 'Device';
    public static doorField = 'Door';
}

// #endregion Fields

export interface IDoorAccessPointEntity extends IAccessPointEntity {
    // #region REST Properties

    device: IGuid;

    readonly door: IGuid;

    // #endregion REST Properties

}

