<app-filter-host [filterDescriptor]="descriptor" [state]="state" [popupPosition]="popupPosition">
    <div class="filter-list-content">
        <gen-search-input *ngIf="isSearchable" [ngModel]="searchText" (ngModelChange)="onSearchChange($event)" [genDebounce]="300" [genMinLength]="3"></gen-search-input>
        <gen-list [selectionType]="ListSelectionType.Multiple">
            <gen-list-item
                *ngFor="let visibleItemSource of visibleItemsSource"
                [selected]="visibleItemSource.isChecked"
                (selectedChange)="onSelectedItemsChange(visibleItemSource, $event.detail)"
            >
                <gen-text>{{ visibleItemSource.text }}</gen-text>
            </gen-list-item>
        </gen-list>

        <div class="filter-list-footer pl-1 pr-1">
            <gen-button (click)="onReload()" [flavor]="Flavor.Button.Flat" appTooltip="{{ 'STE_BUTTON_REFRESH' | translate }}">
                <gen-icon [icon]="Icon.Reload"></gen-icon>
            </gen-button>
            <gen-button (click)="onSelectAll()" [flavor]="Flavor.Button.Flat" appTooltip="{{ 'STE_ACTION_CHECKALL' | translate }}">
                <gen-icon [icon]="MeltedIcon.CheckAll"></gen-icon>
            </gen-button>
            <gen-button [hidden]="value.length === 0" (click)="onUnselectAll()" [flavor]="Flavor.Button.Flat" appTooltip="{{ 'STE_ACTION_UNCHECKALL' | translate }}">
                <gen-icon [icon]="Icon.UncheckAll"></gen-icon>
            </gen-button>
        </div>
    </div>
</app-filter-host>
