<gen-melted-modal
    genTitle="{{ 'STE_BUTTON_SHARE' | translate }}"
    [genIsBlocking]="false"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_SHARE' | translate }}"
    [genOnDefaultAction]="onShareClick"
    [genIsDefaultActionDisabled]="!isShareButtonEnabled"
    appFocusModalWhenOpen
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        <ul *ngIf="!isLoading && !isCurrentlySharing" class="p-2">
            <li class="mt-2 mb-2">
                <app-entity-combo
                    [(entityId)]="entityId"
                    (entityIdChange)="onSelectedEntityChanged()"
                    genLabel="{{ 'STE_LABEL_ENTITY' | translate }}"
                    [showClearButton]="false"
                    tabindex="0"
                ></app-entity-combo>
            </li>
            <li class="mt-2 mb-2">
                <!-- This component must be migrated once genActions is migrated -->
                <gen-melted-list
                    [genItemsSource]="selectedListItems"
                    [genSelectionType]="SelectionType.List.Single"
                    (genSelectedItemChange)="onSelectedUserChange($event)"
                    [genActions]="selectedUsersActions"
                    genLabel="{{ 'STE_LABEL_SHAREWITHUSERS' | translate }}"
                >
                </gen-melted-list>
            </li>
            <li class="mt-2 mb-2">
                <gen-text-input [ngModel]="message" (ngModelChange)="onMessageChange($event)" [multiline]="3">
                    <gen-label>{{ 'STE_LABEL_BOOKMARK_MESSAGE' | translate }}</gen-label>
                </gen-text-input>
            </li>
        </ul>
        <div *ngIf="isCurrentlySharing" class="d-flex justify-content-center align-content-center">
            <gen-spinner> {{ 'STE_LABEL_SHARING_ENTITY' | translate }} </gen-spinner>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>

<app-modal-entities-selector #userEntitySelector [filter]="userSelectionBrowserFilter" (entityIdsSelected)="onEntityIdsSelected($event)"> </app-modal-entities-selector>
