<app-entity-state-overlay
    [draggable]="true"
    [content]="content"
    [commandsUsage]="commandsUsage$ | async"
    [excludedCommandIds]="excludedCommands"
    [customIconId]="(zone$ | async)?.customIconId"
>
    <span
        entityTitle
        *ngIf="zone$ | async as zone"
        class="entity-name"
        [ngClass]="{
            'fg-color-warning': zone.runningState === RunningState.Warning && !zone.maintenance,
            'fg-color-danger': zone.runningState === RunningState.NotRunning,
            'fg-color-maintenance': zone.maintenance
        }"
    >
        {{ content?.title }}
    </span>
    <span *ngIf="zoneIsArmed$ | async as zoneIsArmed" primaryState [ngClass]="{ 'fg-color-danger': !zoneIsArmed, 'fg-color-success': zoneIsArmed }" class="hyphen-after">{{
        (zoneIsArmed ? 'STE_LABEL_ZONE_ARMED' : 'STE_LABEL_ZONE_DISARMED') | translate
    }}</span>
    <span secondaryState>{{ zoneStateString$ | async }} </span>
</app-entity-state-overlay>
