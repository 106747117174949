import { ModuleWithProviders, NgModule } from '@angular/core';
import { DetailedFeatureFlagGroup } from './feature-flag';
import { KnownFeatureFlagsToken } from './tokens';
/**
 * This module is used merely to register feature flags by adding them to an injection token, KnownFeatureFlagsToken.
 */
@NgModule()
export class FeatureFlagsModule {
    static forRoot(...featureFlagGroups: DetailedFeatureFlagGroup[]): ModuleWithProviders<FeatureFlagsModule> {
        const providers = [];
        if (featureFlagGroups?.length) {
            providers.push({
                provide: KnownFeatureFlagsToken,
                useValue: featureFlagGroups,
                multi: true,
            });
        }
        return {
            ngModule: FeatureFlagsModule,
            providers,
        };
    }
}
