import { IGuid, SafeGuid } from 'safeguid';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.ChangePriority;

export class ChangePriorityCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentId: IGuid, priorityId: IGuid) {
        super(
            `/v2/Incidents/${incidentId.toString()}`,
            JSON.stringify({
                Type: commandType,
                PriorityId: priorityId.toString(),
            })
        );
    }

    public errorMessage(statusCode?: number): string {
        return 'STE_MESSAGE_ERROR_EDIT_INCIDENT_PRIORITY_FAILED';
    }
}
