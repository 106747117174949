import { Injectable } from '@angular/core';
import { VersionClient, VersionInfo } from '../../api/api';

@Injectable({ providedIn: 'root' })
export class VersionService {
    //#region Fields

    private versionInfo: VersionInfo | null = null;

    //#endregion

    //#region Constructors

    constructor(private versionApiController: VersionClient) {}

    //#endregion

    //#region Methods

    public async retrieveVersionAsync(): Promise<VersionInfo | null> {
        if (!this.versionInfo) {
            const result = await this.versionApiController.getVersionInfo().toPromise();
            this.versionInfo = result;
        }

        return this.versionInfo;
    }

    //#endregion
}
