import { Injectable } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { ContextMenuItem } from '@modules/shared/interfaces/context-menu-item/context-menu-item';
import { MenuItem } from '@modules/shared/interfaces/menu-item';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
/**
 * @description Use this service anytime you have a context menu, built with a datasource and when you want to track current selection with a Checkmark icon.
 * @link example https://nietzsche.genetec.com/display/UW/WebApp+-+Tiles+-+Entity+States?preview=/125743333/125745491/updated-tile-state-swap-menu.png
 */
@Injectable({
    providedIn: 'root',
})
export class ContextMenuItemSwitcherService {
    public readonly MeltedIcon = MeltedIcon;

    /**
     * Changes the previous selection's icon from Checkmark to None, and sets the new selection's icon to Checkmark.
     *
     * @param newSelectionId id of the new selection
     * @param trackingDataSource array containing the data
     * @returns an altered copy of the trackingDataSource array
     */
    public getNextDataSourceWithNewSelection(newSelectionId: string, trackingDataSource: ContextMenuItem[]): ContextMenuItem[] {
        // then we must uncheck previous selection, and mark the new one
        const previousIdx = trackingDataSource.findIndex((option: MenuItem) => option.icon !== MeltedIcon.None);
        if (previousIdx !== -1) {
            trackingDataSource[previousIdx].icon = MeltedIcon.None;
        }
        const newSelectionIdx = trackingDataSource.findIndex((option) => option.id === newSelectionId);
        if (newSelectionIdx !== -1) {
            trackingDataSource[newSelectionIdx].icon = MeltedIcon.Checkmark;
        }
        return [...trackingDataSource];
    }
}
