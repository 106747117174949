import { DateTimeSpan } from "./Marmot/gwp";

// ==========================================================================
// Copyright (C) 2020 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export enum ExportStatus {
    Running = 0,
    Success = 1,
    NoVideoError = 2,
    EncryptedError = 3,
    CodecNotSupportedError = 4,
    CameraNotFoundError = 5,
    TooManyConnectionsError = 6,
    InsufficientPrivilegeError = 7,
    UnknownError = 8,
    Cancelled = 9,
    TooManyConcurrentRequestsError = 10,
    RequestIsThrottled = 11,
    LegacyExportWithEncryptionNotSupported = 12,
}

export abstract class ExportModel {
    public exportType: string;
    public cameraId = "";
    public timeRange: DateTimeSpan = new DateTimeSpan(new Date().toISOString(), new Date().toISOString());
    public alwaysZip: boolean = false; // We do not want zip files by default
    constructor(exportType: string) {
        this.exportType = exportType;
    }
}

export class ExportG64Model extends ExportModel {
    public encryptionKey: string | null = null;
    constructor() {
        super("G64");
    }
}

export class ExportMp4Model extends ExportModel {
    public excludeAudio = true;
    constructor() {
        super("Mp4");
    }
}

export class ExportAsfModel extends ExportModel {
    public excludeAudio = true;
    public includeDateTime = false;
    public includeCameraName = false;
    constructor() {
        super("Asf");
    }
}

export class ExportProgressEvent {
    public readonly status: ExportStatus;
    public readonly progress: number;

    constructor(status: ExportStatus, progress: number) {
        this.status = status;
        this.progress = progress;
    }
}
  