<gen-melted-modal
    id="aboutModal"
    genTitle="{{ 'STE_LABEL_ABOUT' | translate }}"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_BUTTON_CLOSE' | translate }}"
    appFocusModalWhenOpen
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <div tabindex="0" class="text-wrapper focus-style-override-keyboard">
            <gen-image class="logo m-2" src="assets/images/Genetec Web App White.svg" [alt]="'STE_TOOLTIP_GENETEC_WEBAPP_LOGO' | translate"></gen-image>
            <gen-item>
                <gen-text>{{ 'STE_LABEL_APPLICATIONVERSION' | translate }}</gen-text>
                <gen-text [slot]="ItemSlot.TextSecondary">{{ buildVersion }} ({{ releaseDate }})</gen-text>
            </gen-item>
            <gen-item>
                <gen-text>{{ 'STE_LABEL_SECURITYCENTERVERSION' | translate }}</gen-text>
                <gen-text [slot]="ItemSlot.TextSecondary">{{ scVersion }}</gen-text>
            </gen-item>
            <gen-expander [(genIsOpen)]="modulesDisplayed" genText="{{ 'STE_LABEL_MODULES' | translate }}">
                <gen-expander-content>
                    <div *ngFor="let module of modulesLoadData">
                        <gen-text [flavor]="TextFlavor.Label">{{ module.name }} ({{ module.buildVersion }})</gen-text>
                    </div>
                </gen-expander-content>
            </gen-expander>
            <p class="copyrights m-2">{{ copyrights }}</p>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
