import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class MissionControlContentTypes {
    public static incident = SafeGuid.parse('17608296-B2C4-4483-912A-450320924FF2');
    public static incidentList = SafeGuid.parse('B019AB8F-92BA-427C-8BA1-BF2E91C78459');
    public static procedure = SafeGuid.parse('26E5AFFB-4DE9-4DAB-9721-19B76169B3D7');
    public static activity = SafeGuid.parse('57EA9C36-FB30-4D60-83D4-811B921A10DA');
    public static overview = SafeGuid.parse('013128F9-BFD8-4427-B5A7-1C50E5562FBC');
    public static linkedIncident = SafeGuid.parse('1B9A8A06-58A5-47C3-B865-87005507D6D1');
}
