// Autogenerated: v2/help/Entities/ApiSystemConfiguration?codegen=ts&InterfaceOnly=True&workflow=False on 2022-07-06 9:10:36 AM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';
import { ICustomEntityTypeDescriptor } from './ICustomEntityManager';

// #region Fields

export class SystemConfigurationEntityFields extends EntityFields {
    public static enhancedPtzOnAllNewUnitField = 'EnhancedPtzOnAllNewUnit';
    public static isFirstRunField = 'IsFirstRun';
    public static maxImageFileSizeInKBField = 'MaxImageFileSizeInKB';
    public static passwordExpiryNotificationPeriodField = 'PasswordExpiryNotificationPeriod';
    public static passwordMinCharacterField = 'PasswordMinCharacter';
    public static passwordMinLowerCharacterField = 'PasswordMinLowerCharacter';
    public static passwordMinNumericalCharacterField = 'PasswordMinNumericalCharacter';
    public static passwordMinSpecialCharacterField = 'PasswordMinSpecialCharacter';
    public static passwordMinUpperCharacterField = 'PasswordMinUpperCharacter';
    public static securityLevelField = 'SecurityLevel';
    public static threatLevelField = 'ThreatLevel';
    public static totalActiveThreatLevelCountField = 'TotalActiveThreatLevelCount';
}

// #endregion Fields

// #region Inner classes

export interface IActivityTrailParameter extends IFieldObject {
    value: string;
    keepCurrentFormat: boolean;
}

export interface INewActivityTrail extends IFieldObject {
    templateId: number;
    activityType: IGuid;
    initiator: IGuid;
    impactedEntity: IGuid;
    machineName: string;
    appType: string;
    parameters: ObservableCollection<IActivityTrailParameter>;
    appVersion: string;
}

export interface IMapServer extends IFieldObject {
    name: string;
    serverUrl: string;
    username: string;
    uniqueId: IGuid;
    password: string;
    useAuthentication: boolean;
}

export interface IMapProvider extends IFieldObject {
    name: string;
    type: IGuid;
    licenseKey: string;
    supportLicense: boolean;
    invertCoordinates: boolean;
    isCustom: boolean;
    isDefault: boolean;
    maxZoom: number;
    projection: string;
    providerId: IGuid;
    urlAddress: string;
    urlFormat: string;
    useAuthentication: boolean;
    username: string;
    password: string;
    mapServers: ObservableCollection<IMapServer>;
    mappingType: string;
}

export interface ILicenseUsage extends IFieldObject {
    id: string;
    description: string;
    type: string;
    value: string;
    current: number;
    max: number;
    active: boolean;
}

export interface IEventMetadata extends IFieldObject {
    color: string;
    eventType: string;
    eventSubType: number;
}

export interface ICustomFieldConfig extends IFieldObject {
    entityType: string;
    name: string;
    defaultValue: string;
    groupName: string;
    groupPriority: number;
    id: IGuid;
    mandatory: boolean;
    unique: boolean;
    showInReports: boolean;
    valueType: string;
    owner: IGuid;
    isReadOnly: boolean;
    customDataTypes: number;
}

export interface ICustomEvent extends IFieldObject {
    id: number;
    name: string;
    role: IGuid;
    roleSubType: IGuid;
    supportedEntityType: string;
}

export interface ICustomFieldTypeDescriptor extends IFieldObject {
    id: number | null;
    name: string;
    description: string;
    dataType: string;
    values: ObservableCollection<string>;
}

export interface IActivityTrailConfig extends IFieldObject {
    id: IGuid;
    enabled: boolean;
    descriptionId: number;
    description: string;
}

// #endregion Inner classes

export interface ISystemConfigurationEntity extends IEntity {
    // #region REST Properties

    enhancedPtzOnAllNewUnit: boolean;

    isFirstRun: boolean;

    maxImageFileSizeInKB: number;

    passwordExpiryNotificationPeriod: number;

    passwordMinCharacter: number;

    passwordMinLowerCharacter: number;

    passwordMinNumericalCharacter: number;

    passwordMinSpecialCharacter: number;

    passwordMinUpperCharacter: number;

    securityLevel: number;

    threatLevel: IGuid;

    readonly totalActiveThreatLevelCount: number;

    // #endregion REST Properties

    // #region REST Methods

    getActivityTrailsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IActivityTrailConfig> | null>;
    getCustomDataTypesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomFieldTypeDescriptor> | null>;
    getCustomEntityTypeDescriptorsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomEntityTypeDescriptor> | null>;
    getCustomEventsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomEvent> | null>;
    getCustomFieldConfigAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomFieldConfig> | null>;
    getDefaultSystemMapAsync(transaction?: ITransaction|null): Promise<IGuid|null>;
    getEventsMetadataAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEventMetadata> | null>;
    frozenSessionTestAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    isPrivilegeLicenseSupportedAsync(id: IGuid, transaction?: ITransaction): Promise<boolean | null>;
    getLicenseDetailsAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;
    getLicenseUsageAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ILicenseUsage> | null>;
    getLprAcceptReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null>;
    getLprAnnotationFieldsDefinitionsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null>;
    getLprHotlistRejectReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null>;
    getLprOvertimetRejectReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null>;
    getLprPermitRejectReasonsAsync(transaction?: ITransaction): Promise<Array<IFieldObject> | null>;
    getMapProvidersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMapProvider> | null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getRootPartitionsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    sendActivityTrailsAsync(value: INewActivityTrail, transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

