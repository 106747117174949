import { Directive, ElementRef, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';

// Directive that outputs an event when the element's width or height changes
@Directive({
    selector: '[appResize]',
})
export class ResizeDirective implements OnInit, OnDestroy {
    @Output() public appResize = new EventEmitter<void>();

    private oldWidth = -1;
    private oldHeight = -1;
    private resizeSensor?: ResizeSensor;

    constructor(public elementRef: ElementRef<Element>) {}

    ngOnInit(): void {
        if (ResizeSensor) {
            this.resizeSensor = new ResizeSensor(this.elementRef.nativeElement, () => this.onResized());
        }
    }

    ngOnDestroy(): void {
        if (this.resizeSensor) {
            this.resizeSensor.detach();
        }
    }

    private onResized(): void {
        const newWidth = this.elementRef.nativeElement.clientWidth;
        const newHeight = this.elementRef.nativeElement.clientHeight;

        if (newWidth === this.oldWidth && newHeight === this.oldHeight) {
            return;
        }

        this.oldWidth = this.elementRef.nativeElement.clientWidth;
        this.oldHeight = this.elementRef.nativeElement.clientHeight;

        this.appResize.emit();
    }
}
