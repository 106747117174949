import { SharedContentFields } from '../../shared/enumerations/shared-content-fields';
import { Constants } from '../../shared/utilities/constants';

export const AlarmContentFields = Constants({
    ...SharedContentFields,
    AlarmInstanceId: 'AlarmInstanceId',
    AlarmProcedure: 'AlarmProcedure',
    IsUserRecipient: 'IsUserRecipient',
    ActiveAlarmCount: 'ActiveAlarmCount',
    State: 'State',
    InvestigatedTime: 'InvestigatedTime',
    Priority: 'Priority',
    InvestigatedBy: 'InvestigatedBy',
});
