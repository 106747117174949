import { Component, OnInit } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { IGuid, SafeGuid } from 'safeguid';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { MapContentTypes } from '@modules/maps/enumerations/maps-content-types';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TranslateService } from '@ngx-translate/core';
import { MobileApplicationEntity } from 'RestClient/Client/Model/Application/MobileApplicationEntity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { WebAppClient } from 'WebClient/WebAppClient';
import { IMobileApplicationEntity } from 'RestClient/Client/Interface/IMobileApplicationEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { TimeService } from '@modules/shared/services/time/time.service';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@InternalContentPluginDescriptor({
    type: MobileUserActionsWidgetComponent,
    pluginTypes: [PluginTypes.Widget, PluginTypes.MapPopupCompact],
    exposure: { id: MobileUserActionsWidgetComponent.pluginId, priority: 0 },
    isContentSupported: (content: Content) => !!content?.type.equals(MapContentTypes.MobileUser),
})
@Component({
    selector: 'app-mobile-user-actions-widget',
    templateUrl: './mobile-user-actions-widget.component.html',
    styleUrls: ['./mobile-user-actions-widget.component.scss'],
})
export class MobileUserActionsWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('DBAA2E8A-93DF-44BC-BB83-EECE48DC937C');

    public mobileUser: MobileApplicationEntity | null = null;
    public isLoading = true;
    public content?: Content;
    public dataContext?: ContentService;
    public isOnline = false;
    public status = '';
    public lastSeen = '';
    private loggedUserId: IGuid | undefined;
    private scClient: WebAppClient | null = null;

    constructor(
        private translateService: TranslateService,
        trackingService: TrackingService,
        private timeService: TimeService,
        securityCenterClientService: SecurityCenterClientService
    ) {
        super(trackingService);

        this.scClient = securityCenterClientService?.scClient;
    }

    public async ngOnInit() {
        if (this.scClient && this.content) {
            this.mobileUser = (await this.scClient.getEntityAsync<MobileApplicationEntity, IMobileApplicationEntity>(
                MobileApplicationEntity,
                SafeGuid.parse(this.content.source)
            )) as MobileApplicationEntity;
            if (this.mobileUser) {
                this.isOnline = this.mobileUser.runningState !== RunningState.NotRunning;
                if (this.isOnline) {
                    this.status = this.translateService.instant('STE_LABEL_MOBILEUSER_ONLINE') as string;
                } else {
                    this.status = this.translateService.instant('STE_LABEL_MOBILEUSER_OFFLINE') as string;
                }

                this.loggedUserId = this.mobileUser.loggedUser;

                // if the user is offline
                if (this.loggedUserId.isEmpty()) {
                    this.loggedUserId = this.mobileUser.lastLoggedUser;
                }

                const user = (await this.scClient.getEntityAsync<UserEntity, IUserEntity>(UserEntity, this.loggedUserId)) as UserEntity;
                if (user) {
                    // retrieve the last seen timestamp (this info come from the backend which listen for invalidates)
                    const lastSeen = this.content.parameters?.getFieldDate('Timestamp');
                    if (lastSeen) {
                        this.lastSeen = this.timeService.formatRelative(lastSeen);
                    }
                }
            }
        }
        this.isLoading = false;
    }

    public setDataContext(context: ContentService): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
