// Autogenerated: v2/help/Entities/ApiFederationBaseRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:06 PM
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { IGuid } from 'safeguid';

// #region Fields

export class FederationBaseRoleEntityFields extends RoleEntityFields {
    public static defaultParentAreaField = 'DefaultParentArea';
    public static enablePlaybackField = 'EnablePlayback';
    public static iPField = 'IP';
    public static passwordField = 'Password';
    public static portField = 'Port';
    public static statusDescriptionField = 'StatusDescription';
    public static streamingVersionField = 'StreamingVersion';
    public static usernameField = 'Username';
}

// #endregion Fields

export interface IFederationBaseRoleEntity extends IRoleEntity {
    // #region REST Properties

    defaultParentArea: IGuid;

    enablePlayback: boolean;

    iP: string;

    password: string;

    port: number;

    readonly statusDescription: string;

    readonly streamingVersion: number;

    username: string;

    // #endregion REST Properties

}

