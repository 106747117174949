import { HttpStatusCode } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { IEventBase } from 'RestClient/Client/Interface/IEventBase';
import { EventReceivedArg } from 'RestClient/Connection/RestArgs';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WebAppClient } from 'WebClient/WebAppClient';
import { UpgradeStartedEvent } from './UpgradeStartedEvent';

@Injectable({
    providedIn: 'root',
})
export class UpdaterService implements OnDestroy {
    //#region Fields

    @Output() public isUpgrading = false;

    @Output() public stateChanged = new EventEmitter();

    private isTimedOutValue = new BehaviorSubject<boolean>(false);
    private messageValue = new BehaviorSubject<string>('');

    private readonly scClient: WebAppClient;
    private timer: number | undefined;
    private upgradeTimestamp: number | undefined;

    // represent the delay between reconnection retry
    private readonly ReconnectionDelayInMS = 5000;

    // represent the maximum upgrade delay before abandoning
    private readonly MaximumUpgradeDelayInMS = 300000;

    private eventHandlers: Map<string, (event: IEventBase) => void> = new Map<string, (event: IEventBase) => void>();
    private subscriptions = new SubscriptionCollection();

    //#endregion

    //#region Constructors

    constructor(scClientService: SecurityCenterClientService, private translateService: TranslateService) {
        this.scClient = scClientService.scClient;
        this.initialize();
    }

    //#endregion

    //#region Methods

    public get isTimedOut(): boolean {
        return this.isTimedOutValue.getValue();
    }

    public get isTimedOut$(): Observable<boolean> {
        return this.isTimedOutValue.asObservable();
    }

    public get message$(): Observable<string> {
        return this.messageValue.asObservable();
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }

    private initialize() {
        this.scClient.registerAdditionalEventTypes('UpgradeStartedEvent', UpgradeStartedEvent);
        this.eventHandlers.set('UpgradeStartedEvent', (event) => this.onUpgradeStartedEvent(event as UpgradeStartedEvent));

        this.subscriptions.add(this.scClient.onLogonStateChanged((arg) => this.onLogonStateChanged(arg)));
        this.subscriptions.add(this.scClient.onEventReceived((arg) => this.onEventReceived(arg)));
    }

    private getString(key: string, defaultValue: string): string {
        let result = this.translateService.instant(key) as string;
        if (result === key) {
            result = defaultValue;
        }
        return result;
    }

    //#endregion

    //#region Event Handlers

    private onEventReceived(args: EventReceivedArg) {
        const eventHandler = this.eventHandlers.get(args.event.eventType);
        if (eventHandler) {
            eventHandler(args.event);
        }
    }

    private onLogonStateChanged(args: LogonStateChangedArgs) {
        // When the application is logged back on, we are not upgrading anymore for sure
        if (args.loggedOn()) {
            this.isUpgrading = false;
        }
    }

    private async onUpgradeStartedEvent(event: UpgradeStartedEvent) {
        this.upgradeTimestamp = new Date().getTime();
        this.isUpgrading = true;
        this.isTimedOutValue.next(false);

        const message = this.getString('STE_MESSAGE_INFO_UPGRADE_IN_PROGRESS', 'Upgrade in progress. Please wait.');
        this.messageValue.next(message);
        this.onStateChanged();

        await this.scClient.pauseConnectionAsync();

        // if there is no timer yet, set one
        if (!this.timer) {
            this.timer = window.setInterval(() => {
                this.tryReconnectAsync().fireAndForget();
            }, this.ReconnectionDelayInMS);
        }
    }

    private async tryReconnectAsync() {
        const url = this.scClient.rest.restServerUrl;

        // We are fetch the version info to see if the backend is back online
        const fetchResponse = await fetch(url + '/Api/Version/VersionInfo');
        if (fetchResponse.body === null) {
            return;
        }
        if (fetchResponse.status === HttpStatusCode.Ok) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const versionInfo = await fetchResponse.json();
            if (versionInfo) {
                // Nice, the server is back online
                this.isUpgrading = false;
                this.upgradeTimestamp = undefined;

                // Stop the timer
                window.clearInterval(this.timer);
                this.onStateChanged();

                // The index.html has the no-cache header and the main.js has an hash in it's name so the reload will load the new version
                location.reload();
                return;
            }
        } else if (this.upgradeTimestamp) {
            const elapsed = new Date().getTime() - this.upgradeTimestamp;
            if (elapsed >= this.MaximumUpgradeDelayInMS) {
                // Stop the timer
                window.clearInterval(this.timer);

                this.isTimedOutValue.next(true);

                const message = this.getString('STE_MESSAGE_INFO_UPGRADE_TOO_LONG', 'The upgrade is taking too long. Please try to refresh the page.');
                this.messageValue.next(message);
                this.onStateChanged();
            }
        }
    }

    private onStateChanged() {
        this.stateChanged?.emit();
    }

    //#endregion
}
