import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon, IconSize, ImageFit, ImageFlavor, TextFlavor } from '@genetec/gelato';
import { ButtonFlavor, Gelato, GenMeltedListItem } from '@genetec/gelato-angular';
@Component({
    selector: 'app-image-name-toggle-button',
    templateUrl: './image-name-toggle-button.component.html',
    styleUrls: ['./image-name-toggle-button.component.scss'],
})
export class ImageNameToggleButtonComponent extends Gelato {
    @Input()
    public item?: GenMeltedListItem;

    @Output()
    public isCheckedChanged = new EventEmitter<boolean>();

    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly TextFlavor = TextFlavor;

    public toggleButtonClick(): void {
        if (!this.item) {
            return;
        }
        if (!this.item.isChecked) {
            this.item.isChecked = false;
        }
        this.item.isChecked = !this.item.isChecked;
        this.isCheckedChanged.emit(this.item.isChecked);
    }
}
