import { RestConnection } from '../RestConnection';
import { EventFilter } from '../EventFilter';
import { ConnectionState } from './ConnectionState';
import * as Helpers from '../../Helpers/Helpers';
import * as Args from '../RestArgs';
import { CancellationToken } from '../../Helpers/Helpers';
import { RestResponse } from '../RestResponse';
import HttpStatusCode from '../../Client/Enumerations/HttpStatusCode';
import { FieldObject } from '../../Helpers/FieldObject';
import { Timer } from '../../Helpers/Timer';
import { IGuid, SafeGuid } from 'safeguid';
import { IFieldObject } from '../../Client/Interface/IFieldObject';

export abstract class StreamerBase {
    // #region Const, Enums

    protected maxStreamerCallDelay = 60000;
    // Increase this value if you debug the application. 
    protected waitForRestDelay = 10000;

    // #endregion

    // #region Fields

    protected _parent: RestConnection;
    protected _currentStreamerFilter: EventFilter | null = null;
    protected _requestedStreamerFilters = SafeGuid.createMap<EventFilter>();
    protected _streamerStarted = false;
    protected _currentRetryAttempt = 0;
    public _streamerStateChangedDispatcher = new Helpers.EventDispatcher<Args.StateChangeArg>();
    public _streamerEntityInvalidatedDispatcher = new Helpers.EventDispatcher<Args.EntityInvalidatedArg>();
    public _streamerEntityAddedDispatcher = new Helpers.EventDispatcher<Args.EntityAddedArg>();
    public _streamerEntityRemovedDispatcher = new Helpers.EventDispatcher<Args.EntityRemovedArg>();
    public _streamerEventDispatcher = new Helpers.EventDispatcher<FieldObject>();
    public _streamerActionDispatcher = new Helpers.EventDispatcher<FieldObject>();
    public _streamerQueryDispatcher = new Helpers.EventDispatcher<Args.QueryReceivedArg>();
    public _streamerRequestDispatcher = new Helpers.EventDispatcher<Args.RequestReceivedArg>();
    public _streamerDisconnectRequestDispatcher = new Helpers.EventDispatcher<Args.DisconnectRequestArgs>();
    public _streamerConsoleCommandRequestReceivedDispatcher = new Helpers.EventDispatcher<Args.ConsoleCommandRequestReceivedArg>();
    public _unknownRequestDispatcher = new Helpers.EventDispatcher<Args.UnknownRequestReceivedArg>();
    public _streamerSessionIdReceivedDispatcher = new Helpers.AsyncEventDispatcher<string>();
    protected _startStreamerToken: Helpers.CancellationToken | null = null;
    protected _restSessionId = '';
    protected _restartStreamer: Timer;
    protected _connectionRetryTimer: Timer;
    protected _streamerConnectionId = '';

    // #endregion

    // #region Properties

    public streamerConnectionState: ConnectionState = ConnectionState.Disconnected;

    public get streamerConnectionId(): string {
        return this._streamerConnectionId;
    }

    public get streamerStarted(): boolean {
        return this._streamerStarted;
    }

    public authorizationHeader = '';

    public retryDelay = 15000;
    public retryCount = -1; // -1 stands for infinity
    // private int RestTimeout => (int)Math.Max(RetryCount * RetryDelay.TotalMilliseconds * 2, 10000);

    public get restSessionId(): string {
        return this._restSessionId;
    }

    protected get restTimeout(): number {
        let delay = this.retryCount * this.retryDelay * 2;
        if (delay < 10000) {
            delay = 10000;
        }
        return delay;
    }

    // #endregion

    // #region Constructor, Destructor, Dispose

    constructor(parent: RestConnection) {
        this._parent = parent;
        this._restartStreamer = new Timer(async () => {
            await this.onRestartStreamer();
        });
        this._connectionRetryTimer = new Timer(async () => {
            await this.onConnectionRetryDelayExpired();
        });
    }

    public async disposeAsync() {
        if (this._streamerStarted) {
            await this.stopStreamerAsync();
        }

        this._streamerStateChangedDispatcher.clear();
        this._streamerEntityInvalidatedDispatcher.clear();
        this._streamerEntityAddedDispatcher.clear();
        this._streamerEntityRemovedDispatcher.clear();
        this._streamerEventDispatcher.clear();
        this._streamerActionDispatcher.clear();
        this._streamerQueryDispatcher.clear();
        this._streamerRequestDispatcher.clear();
        this._streamerConsoleCommandRequestReceivedDispatcher.clear();
        this._streamerSessionIdReceivedDispatcher.clear();
        this._streamerDisconnectRequestDispatcher.clear();
        this._unknownRequestDispatcher.clear();

        this._restartStreamer.dispose();
        this._connectionRetryTimer.dispose();
    }

    // #endregion

    // #region Public methods

    public abstract startStreamerAsync(cancelToken?: Helpers.CancellationToken | null): Promise<RestResponse>;

    public async stopStreamerAsync(): Promise<void> {
        return await this.stopStreamerInternalAsync(true);
    }

    public clearCurrentFilter(): void {
        this._currentStreamerFilter = null;
    }

    public async setStreamerFilterAsync(filter: EventFilter) {
        const newId = SafeGuid.newGuid();
        this._currentStreamerFilter = filter;
        this._requestedStreamerFilters.clear();
        this._requestedStreamerFilters.set(newId, filter);
        const filterJson = this._currentStreamerFilter.toJson();
        return await this.invokeOnStreamerAsync('SetFilter', filterJson);
    }

    public async addToStreamerFilterAsync(filter: EventFilter): Promise<IGuid> {
        if (this._currentStreamerFilter == null) {
            this._currentStreamerFilter = new EventFilter();
        }

        const newId = SafeGuid.newGuid();
        this._requestedStreamerFilters.set(newId, filter);

        if (this._currentStreamerFilter.merge(filter) === true) {
            await this.invokeOnStreamerAsync('AddToFilter', filter.toJson());
            return newId;
        } else {
            // already there... no need to call REST
            return new Promise<IGuid>(function (resolve, reject) {
                resolve(newId);
            });
        }
    }

    public async removeFromStreamerFilterAsync(filterIds: Set<IGuid>) {
        const currentFilter = this._currentStreamerFilter;
        if (currentFilter == null) {
            return;
        }

        for (const filterId of filterIds) {
            if (this._requestedStreamerFilters.has(filterId)) {
                this._requestedStreamerFilters.delete(filterId);
            }
        }

        const newFilter = await EventFilter.buildAsync(this._requestedStreamerFilters.values());
        const compareResult = currentFilter.compareTo(newFilter);
        this._currentStreamerFilter = newFilter;
        if (this._streamerStarted === true) {
            if (compareResult.added && compareResult.addedFilter != null) {
                await this.invokeOnStreamerAsync('AddToFilter', compareResult.addedFilter.toJson());
            }
            if (compareResult.removed && compareResult.removedFilter != null) {
                await this.invokeOnStreamerAsync('RemoveFromFilter', compareResult.removedFilter.toJson());
            }
        }
    }

    public async sendRequestResponseAsync(id: string, response: string) {
        return await this.invokeOnStreamerAsync('SendRequestResponse', id, response);
    }

    public async increaseDelayRequestResponseAsync(id: string, timeout: number) {
        return await this.invokeOnStreamerAsync('IncreaseDelayRequestResponse', id, timeout);
    }

    public async setReportFilterAsync(customReportIds: Set<string>) {
        const filter = new FieldObject();
        filter.setField('CustomReports', customReportIds);
        return await this.invokeOnStreamerAsync('SetReportFilter', filter);
    }

    public async sendQueryResultAsync(result: FieldObject) {
        return await this.invokeOnStreamerAsync('SendQueryResult', result);
    }

    public async sendQueryCompletedAsync(result: FieldObject) {
        return await this.invokeOnStreamerAsync('SendQueryCompleted', result);
    }

    public async addConsoleDebugCommandAsync(group: string, method: string, param1: string, param2: string, param3: string) {
        return await this.invokeOnStreamerAsync('AddConsoleDebugCommand', group, method, param1, param2, param3);
    }

    public async getApplicationIdAsync(): Promise<string> {
        const result = await this.getInvokeOnStreamerAsync<string>('GetApplicationId');
        return result;
    }

    public async verifyConnectionAsync(): Promise<FieldObject> {
        const logonResult = await this.getObjectInvokeOnStreamerAsync<FieldObject>(FieldObject, 'VerifyConnection');
        if (logonResult.hasField('SessionId')) {
            this._restSessionId = logonResult.getField<string>('SessionId');
        }
        return logonResult;
    }

    protected parseResponseAsync(response: FieldObject): boolean {
        const msgType = response.getField<string>('MsgType').toLowerCase();
        const payload = response.getFieldObject<FieldObject, IFieldObject>(FieldObject, 'Payload');
        switch (msgType) {
            case 'actionreceived':
                {
                    this.entity_ActionReceived(payload.toString());
                }
                break;

            case 'connectionid':
                {
                    this._streamerConnectionId = payload.getField<string>('id');
                    return true; // true == connection id received
                }
                break;

            case 'entityinvalidated':
                {
                    const id = payload.getFieldGuid('Id');
                    const entityType = payload.getField<string>('EntityType');
                    const validflags = payload.getField<number>('Validflags');
                    this.entity_Invalidated(id, entityType, validflags);
                }
                break;

            case 'entityremoved':
                {
                    const id = payload.getFieldGuid('Id');
                    const entityType = payload.getField<string>('EntityType');
                    this.entity_Removed(id, entityType);
                }
                break;

            case 'entityadded':
                {
                    const id = payload.getFieldGuid('Id');
                    const entityType = payload.getField<string>('EntityType');
                    this.entity_Added(id, entityType);
                }
                break;

            case 'eventreceived':
                {
                    this.entity_EventReceived(payload.toString());
                }
                break;

            case 'queryreceived':
                {
                    this.report_QueryReceived(payload.toString());
                }
                break;

            case 'requestreceived':
                {
                    const id = payload.getField<string>('Id');
                    const request = payload.getFieldObject<FieldObject, IFieldObject>(FieldObject, 'Request');
                    const sourceUser = payload.getField<string>('SourceUser');
                    const sourceApplication = payload.getField<string>('SourceApplication');
                    this.request_Received(id, request, sourceUser, sourceApplication);
                }
                break;

            case 'consolecommandrequestreceived':
                {
                    const id = payload.getField<string>('Id');
                    const groupName = payload.getField<string>('GroupName');
                    const methodName = payload.getField<string>('MethodName');
                    const param1 = payload.getField<string>('Param1');
                    const param2 = payload.getField<string>('Param2');
                    const param3 = payload.getField<string>('Param3');
                    this.request_ConsoleCommandReceived(id, groupName, methodName, param1, param2, param3);
                }
                break;

            case 'disconnectrequest':
                {
                    const tryToReconnect = payload.getField<boolean>('TryReconnect');
                    this.request_DisconnectReceived(tryToReconnect);
                }
                break;

            case 'idle':
                break;

            default:
                {
                    this.unknownRequestReceived(msgType, payload);
                }
                break;
        }
        return false; // false == this isn't the connection id message
    }

    public async getObjectInvokeOnStreamerAsync<T extends FieldObject>(classType: new () => T, method: string, ...args: (string | object | number)[]): Promise<T> {
        const payload = new FieldObject();
        payload.setField('Method', method);
        payload.setField('Params', args);
        const response = await this._parent.executeRequestAsync('POST', 'v2/EntitiesStream/' + this._streamerConnectionId + '/invoke', payload.toString());
        if (response.statusCode !== HttpStatusCode.OK) {
            throw new Error('InvokeOnStreamerAsync failed with ' + response.statusCode);
        }
        const newFO = new classType();
        newFO.loadFields(response.body);
        return newFO;
    }

    public async getInvokeOnStreamerAsync<T>(method: string, ...args: (string | object | number)[]): Promise<T> {
        if (this._streamerConnectionId === null || this._streamerConnectionId.length === 0) {
            throw new Error('Not connected');
        }

        const payload = new FieldObject();
        payload.setField('Method', method);
        payload.setField('Params', args);
        const response = await this._parent.executeRequestAsync('POST', 'v2/EntitiesStream/' + this._streamerConnectionId + '/invoke', payload.toString());
        if (response.statusCode !== HttpStatusCode.OK) {
            throw new Error('InvokeOnStreamerAsync failed with ' + response.statusCode);
        }
        const result = response.body as T;
        return result;
    }

    public async invokeOnStreamerAsync(method: string, ...args: (string | object | number)[]): Promise<void> {
        if (this._streamerConnectionId === null || this._streamerConnectionId.length === 0) {
            throw new Error('Not connected');
        }

        const payload = new FieldObject();
        payload.setField('Method', method);
        payload.setField('Params', args);
        const response = await this._parent.executeRequestAsync('POST', 'v2/EntitiesStream/' + this._streamerConnectionId + '/invoke', payload.toString());
        if (response.statusCode !== HttpStatusCode.OK) {
            throw new Error('InvokeOnStreamerAsync failed with ' + response.statusCode);
        }
        return;
    }

    // #endregion

    // #region Event handlers

    protected async onStateChangedAsync(newState: ConnectionState) {
        try {
            let sendState = true;

            if (this._streamerStarted === false) {
                return; // ignore
            }
            switch (newState) {
                case ConnectionState.Connected: {
                    this._restartStreamer.change(-1);
                    this._currentRetryAttempt = 0;
                    if (this._currentStreamerFilter) {
                        await this.setStreamerFilterAsync(this._currentStreamerFilter);
                    }
                    break;
                }

                case ConnectionState.Disconnected: {
                    // never send state disconnected if we have retries
                    sendState = this.retryCount === 0;
                    if ((this.retryCount > 0 || this.retryCount === -1) && this._currentRetryAttempt === 0) {
                        await this.scheduleReconnectionAsync();
                    }
                    break;
                }

                case ConnectionState.Connecting: {
                    if (this.streamerStarted && this._currentRetryAttempt > 0) {
                        this._restartStreamer.change(this.restTimeout);
                        newState = ConnectionState.Reconnecting;
                    }
                    break;
                }

                case ConnectionState.Reconnecting: {
                    this._restartStreamer.change(this.restTimeout);
                    break;
                }
            }

            if (sendState) {
                this.fireConnectionChange(newState);
            }
        } catch (e) {
            // not much we can do
            await this.disposeAsync();
        }
    }

    protected fireConnectionChange(state: number) {
        if (this.streamerConnectionState !== state) {
            this.streamerConnectionState = state;
            const arg = new Args.StateChangeArg();
            arg.state = state;
            this._streamerStateChangedDispatcher.dispatch(arg);
        }
    }

    protected entity_Removed(id: IGuid, entityType: string): void {
        const arg = new Args.EntityRemovedArg();
        arg.id = id;
        arg.entityType = entityType;
        this._streamerEntityRemovedDispatcher.dispatch(arg);
    }

    protected entity_Added(id: IGuid, entityType: string): void {
        const arg = new Args.EntityAddedArg();
        arg.id = id;
        arg.entityType = entityType;
        this._streamerEntityAddedDispatcher.dispatch(arg);
    }

    protected entity_Invalidated(id: IGuid, entityType: string, flags: number) {
        const arg = new Args.EntityInvalidatedArg();
        arg.id = id;
        arg.entityType = entityType;
        arg.flags = flags;
        this._streamerEntityInvalidatedDispatcher.dispatch(arg);
    }

    public entity_ActionReceived(apiAction: string) {
        const fo = new FieldObject();
        fo.loadFields(JSON.parse(apiAction));
        this._streamerActionDispatcher.dispatch(fo);
    }

    public entity_EventReceived(apiEvent: string) {
        const fo = new FieldObject();
        fo.loadFields(JSON.parse(apiEvent));
        this._streamerEventDispatcher.dispatch(fo);
    }

    protected report_QueryReceived(query: string) {
        const arg = new Args.QueryReceivedArg();
        arg.query = JSON.parse(query);
        this._streamerQueryDispatcher.dispatch(arg);
    }

    protected unknownRequestReceived(msgType: string, payload: IFieldObject) {
        const args = new Args.UnknownRequestReceivedArg(msgType, payload);
        this._unknownRequestDispatcher.dispatch(args);
    }

    protected request_Received(id: string, request: IFieldObject, user: string, application: string) {
        const arg = new Args.RequestReceivedArg(id, request, user, application);
        this._streamerRequestDispatcher.dispatch(arg);
    }

    protected request_DisconnectReceived(tryToReconnect: boolean) {
        const arg = new Args.DisconnectRequestArgs(tryToReconnect);
        this._streamerDisconnectRequestDispatcher.dispatch(arg);
    }

    protected request_ConsoleCommandReceived(id: string, group: string, method: string, param1: string, param2: string, param3: string) {
        const args = new Args.ConsoleCommandRequestReceivedArg(id, group, method, param1, param2, param3);
        this._streamerConsoleCommandRequestReceivedDispatcher.dispatch(args);
    }

    protected async onConnectionRetryDelayExpired() {
        let failed = false;

        try {
            this._connectionRetryTimer.change(-1);
            const tokenSource = new CancellationToken(this.waitForRestDelay);
            const response = await this.startStreamerAsync(tokenSource);
            failed = response.statusCode !== HttpStatusCode.OK;
        } catch (e) {
            failed = true;
        }
        if (failed === true) {
            if (!(await this.scheduleReconnectionAsync())) {
                this.fireConnectionChange(ConnectionState.Disconnected);
            }
        }
    }

    // #endregion

    // #region Private methods

    private async onRestartStreamer() {
        if (this.streamerConnectionState !== ConnectionState.Connected) {
            // still not connect... abandon
            await this.stopStreamerAsync();
        }
    }

    protected async stopStreamerInternalAsync(fireEvent: boolean) {
        if (this._streamerStarted === true) {
            this._streamerStarted = false;
            await this.stopStreamerInternalImplAsync();
            if (fireEvent && this.streamerConnectionState !== ConnectionState.Disconnected) {
                this.fireConnectionChange(ConnectionState.Disconnected);
            } else {
                this.streamerConnectionState = ConnectionState.Disconnected;
            }
        }
        return;
    }

    protected abstract stopStreamerInternalImplAsync(): Promise<void>;

    // #region Resiliency check methods

    protected async scheduleReconnectionAsync(): Promise<boolean> {
        if (this.streamerStarted && (this._currentRetryAttempt < this.retryCount || this.retryCount === -1)) {
            this._currentRetryAttempt++;
            try {
                await this.stopStreamerInternalAsync(false);
            } catch (e) {}

            try {
                this._connectionRetryTimer.change(this.retryDelay);
            } catch {}
            return true;
        }
        return false;
    }

    public async pauseConnectionAsync(): Promise<void> {
        await this.stopStreamerInternalAsync(false);
        this.fireConnectionChange(ConnectionState.Reconnecting);
    }

    /*
      protected async Task EnsureRestConnectionValidAsync()
      {
          // no signal R, retry attempts are based on signal R connectivity for now...
          if (!StreamerStarted)
          {
              return;
          }
  
          int retryCount = 0;
          bool allowedToContinue = false;
  
          do
          {
              if (StreamerStarted && StreamerConnectionState == ConnectionState.Connected)
              {
                  allowedToContinue = true;
              }
              else if (StreamerStarted && retryCount < RetryCount)
              {
                  await Task.Delay(RetryDelay).ConfigureAwait(false);
                  retryCount++;
              }
              else
              {
                  throw new RestResponseException(new RestResponse(WebExceptionStatus.ConnectionClosed));
              }
  
          } while (!allowedToContinue && retryCount <= RetryCount);
      }
  
      */

    // #endregion

    // #endregion
}
