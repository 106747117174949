import { CreateFeatureFlag, CreateFeatureFlagGroup } from '@modules/feature-flags/feature-flag';

export const UnifiedReportFeatureFlags = CreateFeatureFlagGroup({
    General: CreateFeatureFlag('Flag.UnifiedReport', true),
    UnifiedReportGenTable: CreateFeatureFlag('Flag.UnifiedReportGenTable', true),
    MultiSelectWhatFilterFeatureFlag: CreateFeatureFlag('Flag.UnifiedReport.MultiSelectWhatFilter', false),
});

export const RecordFusionFeatureFlags = CreateFeatureFlagGroup({
    General: CreateFeatureFlag('Flag.RecordFusion', false),
});
