import { PolarVec3 } from '../utils/PolarVec3';
import { Radian } from '../utils/Radian';
import { assertUnreachable } from '../utils/Utils';
import { ClipSpaceVec2 } from '../utils/Vec2';
import { Projection } from './DewarperSourceImageParameters';

export interface IProjection {
  apply(x: number): number;
  applyCode(): string; //to produce webgl code

  reverse(x: number): number;
  reverseCode(variableName: string): string; //to produce webgl code

  adjustXY(xy: ClipSpaceVec2): ClipSpaceVec2;
  unadjustXY(xy: ClipSpaceVec2): ClipSpaceVec2;

  toString(): string;
}

export class ProjectionBuilder {
  public static build(projection: Projection): IProjection {
    switch (projection) {
      case Projection.Orthographic:
        return new Orthographic();
      case Projection.Equisolid:
        return new Equisolid();
      case Projection.Equidistant:
        return new Equidistant();
      case Projection.Stereographic:
        return new Stereographic();
      case Projection.None:
        return new None();
      default:
        assertUnreachable(projection);
    }
  }
}

export class Orthographic implements IProjection {
  public apply(beta: number): number {
    return Math.sin(beta);
  }

  public applyCode(): string {
    return '(sin(beta))';
  }

  public reverse(reverse: number): number {
    return Math.asin(reverse);
  }

  public reverseCode(variableName: string): string {
    return `(asin(${variableName}))`;
  }

  public adjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.adjustXY(xy, this);
  }

  public unadjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.unadjustXY(xy, this);
  }

  public toString(): string {
    return 'Orthographic';
  }
}

export class Stereographic implements IProjection {
  public apply(beta: number): number {
    return Math.tan(beta / 2.0);
  }

  public applyCode(): string {
    return '(tan(beta/2.0))';
  }

  public reverse(reverse: number): number {
    return Math.atan(reverse) * 2.0;
  }

  public reverseCode(variableName: string): string {
    return `(atan(${variableName}) * 2.0)`; //todo: watchout for atan2 (different quadrant problem)
  }

  public adjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.adjustXY(xy, this);
  }

  public unadjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.unadjustXY(xy, this);
  }

  public toString(): string {
    return 'Stereographic';
  }
}

export class Equisolid implements IProjection {
  private static readonly sqrtOf2 = Math.sqrt(2.0);

  public apply(beta: number): number {
    return Equisolid.sqrtOf2 * Math.sin(beta/ 2.0);
  }

  public applyCode(): string {
    return `(${toFloat(Equisolid.sqrtOf2)} * sin(beta/2.0))`;
  }

  public reverse(reverse: number): number {
    return 2.0 * Math.asin(reverse / Equisolid.sqrtOf2);
  }

  public reverseCode(variableName: string): string {
    return `(2.0 * asin(${variableName} / ${toFloat(Equisolid.sqrtOf2)}))`;
  }

  public adjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.adjustXY(xy, this);
  }

  public unadjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.unadjustXY(xy, this);
  }

  public toString(): string {
    return 'Equisolid';
  }
}

export class Equidistant implements IProjection {
  private static readonly twoOnPi = 2.0 / Math.PI;

  public apply(beta: number): number {
    return Equidistant.twoOnPi * beta;
  }

  public applyCode(): string {
    return `(${toFloat(Equidistant.twoOnPi)} * beta)`;
  }

  public reverse(reverse: number): number {
    return reverse / Equidistant.twoOnPi;
  }

  public reverseCode(variableName: string): string {
    return `(${variableName} / ${toFloat(Equidistant.twoOnPi)})`;
  }

  public adjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.adjustXY(xy, this);
  }

  public unadjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return ProjectionBase.unadjustXY(xy, this);
  }

  public toString(): string {
    return 'Equidistant';
  }
}

export class None implements IProjection {
  public apply(beta: number): number {
    return beta;
  }

  public applyCode(): string {
    return '(beta)';
  }

  public reverse(reverse: number): number {
    return reverse;
  }

  public reverseCode(variableName: string): string {
    return `(${variableName})`;
  }

  public adjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return xy;
  }

  public unadjustXY(xy: ClipSpaceVec2): ClipSpaceVec2 {
    return xy;
  }

  public toString(): string {
    return 'None';
  }
}

class ProjectionBase {
  public static adjustXY(xy: ClipSpaceVec2, projection: IProjection): ClipSpaceVec2 {
    const requestedAngle = xy.toPolar().Angle;

    const polarPrimeXYZdelta: Radian = requestedAngle;
    let polarPrimeXYZbeta: Radian;
    if (xy.X === 0 && xy.Y !== 0) {
      polarPrimeXYZbeta = new Radian(projection.reverse(xy.Y) / requestedAngle.sin());
    } else {
      polarPrimeXYZbeta = new Radian(projection.reverse(xy.X) / requestedAngle.cos());
    }

    //The zoom does not matter when adjusting the goto location so we simplify it to 1
    const pPrimePolarXYZ = new PolarVec3(1.0, polarPrimeXYZbeta, polarPrimeXYZdelta);
    return pPrimePolarXYZ.toCartesian().dropZ();
  }

  //This reverses the computation done in the adjustXY
  //Made possible by knowing that the angle part of the polar coordinates will not change so the resulting atan(x, y) does not change and we can reverse the process
  public static unadjustXY(xy: ClipSpaceVec2, projection: IProjection): ClipSpaceVec2 {
    const atan = Math.atan2(xy.Y, xy.X);
    const x = projection.apply(Math.cos(atan) * Math.asin(xy.X / Math.cos(atan)));
    const y = Math.tan(atan) * x;
    return new ClipSpaceVec2(x, y);
  }
}

function toFloat(x: number): string {
  return Number.isInteger(x) ? x + '.0' : x.toString();
}
