import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { CustomIconState } from '@modules/shared/api/api';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { filterFieldChanged } from '@modules/shared/operators/filter-field-change';
import { CommandsUsage } from '@modules/shared/services/commands/commands-usage/commands-usage';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';
import { DoorWidgetBaseComponent } from '../../door/door-widget-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-door-entity-state',
    templateUrl: './door-entity-state.component.html',
    styleUrls: ['./door-entity-state.component.scss'],
})
@InternalContentPluginDescriptor({
    type: DoorEntityStateComponent,
    pluginTypes: [PluginTypes.TileHeader],
    exposure: {
        id: DoorEntityStateComponent.pluginId,
        priority: -1,
    },
    isContentSupported: (content: Content) => {
        if (content && !content.contextContent) {
            if (content.type) {
                return content.type.equals(AccessControlContentTypes.Door);
            }
        }
        return false;
    },
    requirements: { licenses: [KnownLicenses.accessControl], enabledFeatureFlags: [GeneralFeatureFlags.EntityState] },
})
export class DoorEntityStateComponent extends DoorWidgetBaseComponent implements OnInit, ContentPluginComponent, OnDestroy {
    public static pluginId = SafeGuid.parse('8A4EA39D-36B5-4C9E-826B-0C4763D06E0B');

    public readonly RunningState = RunningState;

    // public properties
    public commandsUsage!: CommandsUsage;
    public doorCustomIconState$: Observable<CustomIconState>;

    // Private properties
    private subscriptions = new SubscriptionCollection();

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        translateService: TranslateService,
        trackingService: TrackingService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(securityCenterClientService, translateService, trackingService);

        this.doorCustomIconState$ = this.door$.pipe(
            filterFieldChanged('doorOpened'),
            map((doorOpened) => (doorOpened ? CustomIconState.Active : CustomIconState.Normal), distinctUntilChanged())
        );
    }

    public async ngOnDestroy(): Promise<void> {
        await super.ngOnDestroy();
        this.subscriptions.unsubscribeAll();
    }

    public setContent(content: Content): void {
        this.content = content;
        this.createCommandsUsageAsync().fireAndForget();
    }

    private async createCommandsUsageAsync() {
        this.commandsUsage = await this.commandsService.getCommandsUsage({ type: ContextTypes.Content, data: this.content }, []);
        this.subscriptions.add(this.commandsUsage.subscribe());
        this.changeDetectorRef.markForCheck();
    }
}
