import { Severity } from '@genetec/gelato-angular';

export class GenInputModel {
    public message!: string;
    public severity!: Severity;

    constructor(public value: string = '') {
        this.initialize();
    }

    private initialize(){
        this.message = '';
        this.severity = Severity.Default;
    }

    public resetMessage (){
        this.initialize();
    }
}
