export class Config {
  public static readonly instance = {};
}

export class DebugSwitch {
  // Next attempt to decode using Mse H264 decoder will fail and force fallback to mjpeg
  // Usage: in the console, type: "gwp.config.breakMse = true"
  private static readonly breakMse = 'breakMse';

  // Set the frequency at which we clean the mse cache for a given player (to reduce RAM usage)
  // Usage: in the console, type: "gwp.config.mseCleanupFreqSeconds = 10"
  private static readonly mseCleanupFreqSeconds = 'mseCleanupFreqSeconds';

  // Every 10 second is what the browser seems to do by default but we need to do it ourselves since the browser keeps 600 second of cache
  private static readonly DefaultMseCleanupFreqSeconds = 10;

  public static get IsMseBroken(): boolean {
    return DebugSwitch.isEnabled(DebugSwitch.breakMse);
  }

  public static get MseCleanupFreqSeconds(): number {
    return DebugSwitch.getValue(DebugSwitch.mseCleanupFreqSeconds) ?? DebugSwitch.DefaultMseCleanupFreqSeconds;
  }

  private static isEnabled(switchName: string): boolean {
    return Config.instance.hasOwnProperty(switchName) && (<any>Config.instance)[switchName] === true;
  }

  private static getValue(switchName: string): number | null {
    return Config.instance.hasOwnProperty(switchName) ? (<any>Config.instance)[switchName] : null;
  }
}
