import { Color, NormalizedColor } from '../../utils/Color';
import { ILogger } from '../../utils/logger';
import { CanvasWrapper } from '../CanvasWrapper';
import { IDewarperCanvas } from '../DewarperCanvas';
import { GLUtils } from '../WebGL2/GLUtils';

export interface IDewarpedAreaCanvas extends IDewarperCanvas {
  readonly DewarpedAreaColor: NormalizedColor;
  readonly DewarpedAreaBorderColor: NormalizedColor;
}

export class DewarpedAreaCanvas extends CanvasWrapper implements IDewarpedAreaCanvas {
  private static readonly DefaultDewarpedAreaColor: NormalizedColor = new NormalizedColor((90.0/255.0), 0, 0, 0.35);
  private static readonly DefaultDewarpedAreaBorderColor: NormalizedColor = new NormalizedColor(1, 0, 0, 1);

  private readonly m_gl: WebGL2RenderingContext;

  constructor(logger: ILogger, htmlCanvasElement: HTMLCanvasElement) {
    super(logger, 'DewarperAreaCanvas', htmlCanvasElement);
    this.m_gl = GLUtils.getWebGL2Context(this.Canvas);
  }

  public getWebGL2Context(): WebGL2RenderingContext {
    return this.m_gl;
  }

  public get DewarpedAreaColor(): NormalizedColor {
    try {
      return this.Color;
    } catch (e) {
      this.Logger.warn?.trace('Failed to obtain canvas color. Fallbacking to default color. ' + e);
      return DewarpedAreaCanvas.DefaultDewarpedAreaColor;
    }
  }

  public get DewarpedAreaBorderColor(): NormalizedColor {
    try {
      const color = this.Canvas.style.borderColor;
      const colorObj = Color.fromCSSColor(color);
      this.Logger.debug?.trace(`Border color ${color} converted to ${colorObj.toString()}`);
      return NormalizedColor.fromColor(colorObj);
    } catch (e) {
      this.Logger.warn?.trace('Failed to obtain canvas border color. Fallbacking to default color. ' + e);
      return DewarpedAreaCanvas.DefaultDewarpedAreaBorderColor;
    }
  }
}
