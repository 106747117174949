import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    //#region Constants

    private static readonly knownAuthHeaders = ['Authorization', 'WebAppSessionIdHeader', 'WebAppUniqueIdHeader', 'X-XSRF-TOKEN'];

    //#endregion

    constructor(private authService: AuthService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headersDictionary: { [name: string]: string | string[] } = {
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
        };

        // Try and get the active session headers
        const headers = this.authService.getConnectionHeaders();
        if (headers) {
            for (const header of headers) {
                if (!headersDictionary[header[0]] && AuthInterceptor.knownAuthHeaders.some((item) => item === header[0])) {
                    headersDictionary[header[0]] = header[1];
                }
            }
        }

        // clone the request with the adjusted headers
        const clonedReq = req.clone({
            setHeaders: headersDictionary,
        });

        return next.handle(clonedReq);
    }
}
