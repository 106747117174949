<gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
<gen-text [hidden]="alarmItems.length > 0 || isLoading" [flavor]="TextFlavor.Label">{{ 'STE_LABEL_NOACTIVEALARMS' | translate }}</gen-text>

<ul class="gen-list">
    <li *ngFor="let item of alarmItems">
        <div class="w-100 p-relative">
            <gen-item
                [ngClass]="{ 'columns-span-10 ha-left pointer': item.investigating, 'columns-span-11 ha-left pointer': !item.investigating, 'focus-style-override': true }"
                [highlightColor]="item.color"
                (click)="onAlarmInfoRequested(item)"
                appClickActionOnSpaceEnter
                tabindex="0"
            >
                <app-custom-icon slot="gen-icon" [icon]="getAlarmIcon(item)" [entityId]="item.alarmId" [customIconId]="item.customIconId"></app-custom-icon>
                <gen-text>{{ item.name }}</gen-text>
                <gen-text [slot]="ItemSlot.TextSecondary">{{ item.description }}</gen-text>
                <gen-button
                    [style.visibility]="item.hasMoreMenu ? '' : 'hidden'"
                    [slot]="ItemSlot.ContentRight"
                    [attr.id]="'alarm' + item.instanceId"
                    [flavor]="ButtonFlavor.Flat"
                    (click)="onOptionsClicked(item, $event)"
                    appClickActionOnSpaceEnter
                    class="va-center context-menu-button"
                    appTooltip="{{ 'STE_BUTTON_SHOWMORE' | translate }}"
                >
                    <gen-icon [icon]="Icon.EllipsisVertical"></gen-icon>
                </gen-button>
            </gen-item>
        </div>
    </li>
</ul>
