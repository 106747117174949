import { Timer } from './Timer';

export class Debouncer {
  private _timer: Timer;
  private _maxTimer: Timer;
  private _action: any;
  private _timeout = 0;
  private _maxTimeout = 0;
  private _suspended = false; // while we are execution the action, prevent reentrance
  private _discardOnAction = false; // true == drop the trigger that were made durin the action, else ==  retrigger on resume
  private _triggeredWhileSuspended = false; // action was requested during the action execution
  private _triggerCount = 0;
  private _isAsync = false;

  constructor(discardOnAction: boolean, action: any, timeout: number, maxTimeout: number) {
    this._action = action;
    this._timeout = timeout;
    this._maxTimeout = maxTimeout;
    this._timer = new Timer(() => {
      this.timerCallback();
    });
    this._maxTimer = new Timer(() => {
      this.timerCallback();
    });
    this._discardOnAction = discardOnAction;
    this._isAsync = action.constructor.name === "AsyncFunction";
  }

  public trigger(): void {
    if (this._triggerCount === 0) {
      this._maxTimer.change(this._maxTimeout);
    }
    this._triggerCount++;
    if (this._suspended === false) {
      this._timer.change(this._timeout);
    } else {
      this._triggeredWhileSuspended = true;
    }
  }

  private async timerCallback(): Promise<void> {
    if (this._action == null) {
      return; // disposed
    }

    this.suspend();
    try {
      if ( this._isAsync === false ) {
        this._action();
      } else {
        await this._action();
      }
     
    } catch (e) {
      // Console.WriteLine($"Timer_callback {ex}");
    }
    this.resume();
  }

  public suspend(): void {
    this._suspended = true;
    this._timer.change(-1);
    this._maxTimer.change(-1);
    this._triggerCount = 0;
  }

  public resume(): void {
    this._suspended = false;
    if (this._triggeredWhileSuspended && this._discardOnAction === false) {
      this._triggeredWhileSuspended = false;
      this.trigger();
    }
  }

  public dispose(): void {
    this.suspend();
  }
}
