import { Inject, Injectable } from '@angular/core';
import { Color, GenMeltedItem, GenModalService, GenToastService, MeltedIcon } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { SafeGuid } from 'safeguid';
import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { Constants } from '@src/constants';
import moment from 'moment';
import { DateTimeSpan } from 'Marmot/Marmot/gwp';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExportStatus2, VideoExportProgressEvent } from '../api/video-export-progress.event';
import { VideoExportCompletedEvent } from '../api/video-export-completed.event';
import {
    ApiException,
    ExportAsfRequest,
    ExportG64Request,
    ExportMp4Request,
    ExportRequest,
    IVideoExportSession,
    SerializableDateTimeSpan,
    UserCredentials,
    VideoClient,
} from '../api/api';

/** Represents a video export instance */
export class VideoExportInstance {
    public statusColor = '';
    public closeTooltip = '';
    public errorMessage = '';
    public progress = 0;
    public hasInsufficientPrivilege = false;

    private downloadProgressReceived = new Subject<ExportStatus2 | undefined>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public downloadProgressReceived$ = this.downloadProgressReceived.asObservable();

    private isError = false;
    private isCancelled = false;
    private exportSession: IVideoExportSession | undefined;
    private exportModel: ExportRequest;
    private lastEvent: VideoExportProgressEvent | undefined;
    private subscription: (() => void) | undefined;

    constructor(
        private baseUrl: string,
        public filename: string,
        private service: VideoExportService,
        private translateService: TranslateService,
        private scClient: SecurityCenterClient,
        private toastService: GenToastService,
        private modalService: GenModalService,
        private videoClient: VideoClient,
        request: ExportRequest
    ) {
        this.exportModel = request;
        this.closeTooltip = this.translateService.instant('STE_BUTTON_CANCEL') as string;
    }

    //#region Public Methods

    public static convertErrorToString(translateService: TranslateService, status: ExportStatus2): string {
        let result = '?';

        switch (status) {
            case ExportStatus2.NoVideoError:
                result = translateService.instant('STE_MESSAGE_ERROR_NOVIDEOFOUND') as string;
                break;

            case ExportStatus2.EncryptedError:
                result = translateService.instant('STE_LABEL_ENCRYPTIONERROR') as string;
                break;

            case ExportStatus2.CodecNotSupportedError:
                result = translateService.instant('STE_LABEL_CODECNOTSUPPORTED') as string;
                break;

            case ExportStatus2.CameraNotFoundError:
                result = translateService.instant('STE_MESSAGE_ERROR_CAMERANOTFOUND') as string;
                break;

            case ExportStatus2.TooManyConnectionsError:
                result = translateService.instant('STE_MESSAGE_ERROR_TOOMANYCONNECTIONS') as string;
                break;

            case ExportStatus2.InsufficientPrivilegeError:
                result = translateService.instant('STE_MESSAGE_ERROR_INSUFFICIENTPRIVILEGE') as string;
                break;

            case ExportStatus2.UnknownError:
                result = translateService.instant('STE_LABEL_UNKNOWNERROR') as string;
                break;

            case ExportStatus2.Cancelled:
                result = translateService.instant('STE_LABEL_EXPORT_CANCELLED') as string;
                break;

            case ExportStatus2.TooManyConcurrentRequestsError:
                result = translateService.instant('STE_MESSAGE_ERROR_TOOMANYCONCURRENTREQUESTS') as string;
                break;

            case ExportStatus2.RequestIsThrottled:
                result = translateService.instant('STE_MESSAGE_ERROR_REQUESTISTHROTTLED') as string;
                break;

            case ExportStatus2.LegacyExportWithEncryptionNotSupported:
                result = translateService.instant('STE_MESSAGE_ERROR_LEGACYEXPORTWITHENCRYPTIONNOTSUPPORTED') as string;
                break;
        }

        return result;
    }

    public onDestroy(): void {
        if (this.subscription) {
            this.subscription();
            this.subscription = undefined;
        }
    }

    public async cancel(force: boolean = true): Promise<void> {
        let remove = this.lastEvent?.completed || this.isError;

        if (!force && !this.lastEvent?.completed && !this.isError && !this.isCancelled) {
            const title = this.translateService.instant('STE_TITLE_CANCELEXPORT') as string;
            const message = this.translateService.instant('STE_MESSAGE_CONFIRM_CANCELEXPORT') as string;
            const yes = this.translateService.instant('STE_BUTTON_YES') as string;
            const cancel = this.translateService.instant('STE_BUTTON_CANCEL') as string;

            const action = await this.modalService.showMessage(title, message, yes, cancel);
            if (action === 'default') {
                this.isCancelled = true;
                this.errorMessage = '';
                this.statusColor = 'orange';

                remove = true;
            } else {
                remove = false;
            }
        }

        if (remove || force) {
            const index = this.service.exports.indexOf(this);
            if (index > -1) {
                this.service.exports.splice(index, 1);
            }
            this.onDestroy();
        }
    }

    public async start(): Promise<void> {
        this.toastService.show({ text: this.translateService.instant('STE_LABEL_EXPORTSTARTED') as string });

        if (this.exportModel) {
            try {
                // listen for events
                if (!this.subscription) {
                    this.subscription = VideoExportProgressEvent.setupCallbackOnRestConnection(this.scClient, (args) => this.onEvent(args));
                }
                // Start the export + event processing server-side
                this.exportSession = await this.videoClient.exportVideo(this.exportModel).toPromise();
                if (this.exportSession.exportId.equals(SafeGuid.EMPTY)) {
                    // unknown error, couldn't get a export context id
                    const message = `${this.translateService.instant('STE_LABEL_UNKNOWNERROR') as string}`;
                    this.toastService.show({ text: message, flavorCustomColor: Color.Mango });
                    // cleanup the subscription, since we can't do anything
                    this.onDestroy();
                }
            } catch (err) {
                let message = this.translateService.instant('STE_LABEL_UNKNOWNERROR') as string;
                if (err instanceof ApiException) {
                    if (err.status === 400) {
                        // bad request
                        message = this.translateService.instant('STE_LABEL_INVALIDFILTER') as string;
                    } else if (err.status === 401) {
                        // unauthorized
                        message = this.translateService.instant('STE_LABEL_UNAUTHORIZED') as string;
                    } else if (err.status === 404) {
                        // not found
                        message = this.translateService.instant('STE_MESSAGE_CAMERA_NOT_FOUND') as string;
                    } else if (err.status === 408) {
                        // timeout / cancelled
                        message = this.translateService.instant('STE_MESSAGE_ERROR_OPERATIONTIMEOUT') as string;
                    } else if (err.status === 500) {
                        // unknown
                        message = `${this.translateService.instant('STE_LABEL_UNKNOWNERROR') as string}: ${err.result as string}`;
                    }
                }
                this.setError(message);
                this.toastService.show({ text: message, flavorCustomColor: Color.Mango });
                // unwire the events
                this.onDestroy();
            }
        }
    }

    //#endregion

    //#region Private Methods

    private setError(error: string) {
        this.statusColor = Color.Fragola;
        this.isError = true;
        const message = this.translateService.instant('STE_LABEL_EXPORTFAILED') as string;
        this.errorMessage = `${message} (${error})`;
    }

    //#endregion

    //#region Event Handlers

    private onExportCompleted(): void {
        if (
            this.lastEvent?.completed &&
            this.lastEvent?.status === ExportStatus2.Success &&
            !this.isError &&
            !this.isCancelled &&
            this.exportSession &&
            !this.exportSession.exportId.equals(SafeGuid.EMPTY)
        ) {
            // export completed
            this.toastService.show({ text: this.translateService.instant('STE_LABEL_EXPORTCOMPLETED') as string, flavorCustomColor: Color.Pistacchio });

            if (this.exportSession.exportId && this.exportSession.mediaGatewayRoleId && this.exportSession.mediaGatewayServerId) {
                const a = document.createElement('a');
                document.body.appendChild(a);
                try {
                    // click on the download
                    let href = `${
                        this.baseUrl
                    }api/video/ExportVideo/${this.exportSession.mediaGatewayRoleId.toString()}/${this.exportSession.mediaGatewayServerId.toString()}/${this.exportSession.exportId.toString()}`;
                    if (this.filename) {
                        href += `?filename=${encodeURIComponent(this.filename)}`;
                    }
                    a.href = href;
                    // Set the target to "_blank" to ensure we are not using the main connection. Otherwise, the user could cancel or timeout the main connection.
                    a.target = '_blank';
                    a.click();
                } finally {
                    document.body.removeChild(a);
                }
            }
        }
    }

    private onEvent(event: VideoExportProgressEvent): void {
        // check the export id to make sure the events are related to this export session
        if (this.exportSession?.exportId.equals(event.exportId)) {
            // record the relevant event
            this.lastEvent = event;

            if (this.lastEvent?.progress) {
                if (this.lastEvent.progress !== 1) {
                    this.downloadProgressReceived.next();
                }
                this.progress = this.lastEvent.progress * 100;
            }
            if (event.status === ExportStatus2.Success) {
                // export is completed
                this.statusColor = Color.Pistacchio;
                this.closeTooltip = this.translateService.instant('STE_LABEL_REMOVE') as string;
            } else if (event.status === ExportStatus2.Running) {
                // export is running
                this.statusColor = Color.Puffo; // blue
            } else if (!this.isError) {
                // export has failed
                this.downloadProgressReceived.next(event.status);
                const error = VideoExportInstance.convertErrorToString(this.translateService, event.status);
                this.setError(error);
                this.toastService.show({ text: this.errorMessage, flavorCustomColor: Color.Fragola });
            }

            // if it's completed, cleanup the handle & process the resulting download if possible
            if (event.completed) {
                this.downloadProgressReceived.next();
                // when we get the completed event, wipe out the subscription
                this.onDestroy();
                // process the completed download
                void this.onExportCompleted();
            }
        }
    }

    //#endregion
}

@Injectable({
    providedIn: 'root',
})
export class VideoExportService {
    //#region Properties

    public exports: VideoExportInstance[] = [];
    private subscriptions: (() => void)[] = [];

    //#endregion

    //#region Constructors

    constructor(
        @Inject(Constants.baseUrlIdentifier) private baseUrl: string,
        private translateService: TranslateService,
        private toastService: GenToastService,
        private modalService: GenModalService,
        private securityCenterClientService: SecurityCenterClientService,
        private videoClient: VideoClient
    ) {
        this.subscriptions.push(
            this.securityCenterClientService.scClient.onLogonStateChanged((arg) => {
                this.onLogonStateChanged(arg);
            })
        );
        const callbackSub = VideoExportCompletedEvent.setupCallbackOnRestConnection(this.securityCenterClientService.scClient, (arg) => {
            if (arg.statusCode !== 200) {
                const statusMessage = VideoExportInstance.convertErrorToString(this.translateService, arg.status);
                const titleMessage = VideoExportInstance.convertErrorToString(this.translateService, ExportStatus2.Cancelled);
                // some error occurred while attempting a download
                this.toastService.show({
                    text: `${titleMessage}: ${statusMessage}`,
                    secondaryText: `${arg.statusCode}: ${arg.message}`,
                    flavorCustomColor: Color.Fragola,
                    icon: MeltedIcon.Warning,
                });
            }
        });
        if (callbackSub) {
            this.subscriptions.push(callbackSub);
        }
    }

    //#endregion

    //#region Methods
    public buildVideoExportRequest(exportRequest: ExportRequestParameter): ExportRequest | undefined {
        // create an object to hold all the parameters
        let modelValue: ExportRequest | undefined;
        const { entityId, startTime, endTime, format, supportsEncryption, encrypt, encryptionKey, exportAudio, credentialModel, description } = exportRequest;

        if (entityId && startTime && endTime) {
            const range = new DateTimeSpan(moment(startTime).toISOString(), moment(endTime).toISOString());
            modelValue = new ExportRequest();

            switch (format?.id) {
                case 'G64': {
                    const export2Model = new ExportG64Request();
                    if (supportsEncryption && encrypt) {
                        export2Model.encryptionKey = encryptionKey;
                    }
                    modelValue = export2Model;
                    break;
                }
                case 'G64x': {
                    const export2Model = new ExportG64Request();
                    if (supportsEncryption && encrypt) {
                        export2Model.encryptionKey = encryptionKey;
                    }
                    modelValue = export2Model;
                    break;
                }
                case 'MP4': {
                    const export2Model = new ExportMp4Request();
                    export2Model.excludeAudio = !exportAudio;
                    modelValue = export2Model;
                    break;
                }
                case 'ASF': {
                    const export2Model = new ExportAsfRequest();
                    export2Model.excludeAudio = !exportAudio;
                    export2Model.includeDateTime = false;
                    export2Model.includeCameraName = false;
                    modelValue = export2Model;
                    break;
                }
            }

            modelValue.cameraId = new SafeGuid(entityId);
            modelValue.description = description;
            modelValue.timeRange = new SerializableDateTimeSpan({
                end: range.End,
                start: range.Start,
            });
            if (credentialModel) {
                modelValue.secondUser = new UserCredentials({
                    username: credentialModel.username,
                    password: credentialModel.password,
                });
            }
        }

        return modelValue;
    }

    public async exportAsync(request: ExportRequest, filename: string): Promise<ExportStatus2 | undefined> {
        const instance = new VideoExportInstance(
            this.baseUrl,
            filename,
            this,
            this.translateService,
            this.securityCenterClientService?.scClient,
            this.toastService,
            this.modalService,
            this.videoClient,
            request
        );
        this.exports.push(instance);
        const downloadProgressPromise = new Promise<ExportStatus2 | undefined>((resolve, reject) => {
            instance.downloadProgressReceived$.pipe(take(1)).subscribe((exportStatus?: ExportStatus2) => {
                if (exportStatus) {
                    reject(exportStatus);
                } else {
                    resolve(undefined);
                }
            });
        });

        await instance.start();
        return downloadProgressPromise;
    }

    //#endregion

    //#region Events

    private onLogonStateChanged(e: LogonStateChangedArgs) {
        // On logoff, clear the video exports
        if (!e.loggedOn()) {
            this.exports.forEach((x) => x.cancel());
            this.exports.length = 0;
        }
    }

    //#endregion
}

export interface ExportRequestParameter {
    entityId: SafeGuid;
    startTime: string;
    endTime: string;
    supportsEncryption: boolean;
    encrypt: boolean;
    encryptionKey: string;
    exportAudio: boolean;
    format?: GenMeltedItem;
    description?: string;
    credentialModel?: { username: string; password: string };
}
