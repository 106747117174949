class FeatureFlagSymbol extends String {
    public key: string;
    public defaultValue: boolean;

    constructor(featureFlagKey: string, defaultValue = false) {
        super(featureFlagKey);
        this.key = featureFlagKey;
        this.defaultValue = defaultValue;
    }

    public isEnabled(advancedSettingsService: any): boolean {
        return advancedSettingsService.get(this.key, this.defaultValue);
    }
}

export type FeatureFlag = FeatureFlagSymbol & string;

export const CreateFeatureFlag = (featureFlagKey: string, defaultValue = false): FeatureFlag => {
    return new FeatureFlagSymbol(featureFlagKey, defaultValue) as FeatureFlag;
};

export const CreateFeatureFlagGroup = <T extends Record<string, FeatureFlag>>(obj: T): DetailedFeatureFlagGroup<T> => {
    return Object.freeze(obj);
};

export type DetailedFeatureFlagGroup<T = Record<string, FeatureFlag>> = Record<keyof T, FeatureFlag>;

/**
 * Feature flag group that has its feature flags already evaluated.
 */
export type FeatureFlagGroup<T extends DetailedFeatureFlagGroup> = Record<keyof T, boolean>;
