import { Base64Decoder } from '../utils/Base64Decoder';
import { Utils } from '../utils/Utils';

/**
 * @beta
* */
export class Token {
  private readonly m_token: string;

  private readonly m_expirationDate: Date;

  public get Value(): string {
    return this.m_token;
  }

  public get ExpirationDate(): Date {
    return this.m_expirationDate;
  }

  constructor(token: string) {
    this.m_token = token;

    const tokenParts = this.m_token.toString().split('.', 3);
    if (tokenParts.length !== 3) {
      throw new Error(`Token is not of a supported format. Only JWTs are accepted ${token}`);
    }
    // Second part is base64 JSON of the claims
    const jsonTokenString: string = Base64Decoder.decode(tokenParts[1]);
    const payload = JSON.parse(jsonTokenString);
    if (!payload.hasOwnProperty('exp')) {
      throw new Error('Token does not have an exp field for expiration');
    }
    if (typeof payload['exp'] !== 'number') {
      throw new Error('Token exp field is not a number');
    }

    const expireTime = payload['exp'] * 1000;
    this.m_expirationDate = new Date(expireTime);
  }

  public toString(): string {
    return `${this.m_token} expires at ${Utils.formatDate(this.m_expirationDate)}`;
  }
}
