import { ILogger } from './utils/logger';
import { StatisticProvider } from './utils/StatisticProvider';
import { LiteEvent, ILiteEvent } from './utils/liteEvents';
import { StreamingConnectionStatusChangeEvent, PlayerStateChangeEvent, PlaySpeedChangeEvent, AudioStateChangeEvent, AudioAvailabilityChangeEvent, TimelineContentEvent, PlayerModeChangeEvent, TimelineEvent, ErrorStatusEvent } from './events';
import { ResolvedFrameTime } from './utils/TimeResolver';
import { BitRate } from './utils/BitRateComputer';
import { FrameRate } from './utils/FrameRateComputer';
import { ErrorDetails, SerializedPlayerState, StreamingStatus, TimelineContent } from './services/eventHub';
import { ErrorCode, StreamingConnectionStatus, PlayerMode } from './enums';
import { Resolution } from './utils/Resolution';
import { TimeSpan } from './utils/TimeSpan';
import { Utils } from './utils/Utils';
import { IEventHub } from './services/eventHubDispatcher';
import { PlaySpeed } from './players/PlaySpeed';

export class EventManager implements IEventHub {
  // The minimum time between each "frame rendered" event (in MS)
  private static readonly FrameRenderedEventFrequency = 250;

  private readonly m_logger: ILogger;
  private readonly m_statisticProvider: StatisticProvider;

  private readonly onError = new LiteEvent<ErrorStatusEvent>();
  private readonly onFrameRendered = new LiteEvent<Date>();
  private readonly onStreamStatusChange = new LiteEvent<StreamingConnectionStatusChangeEvent>();
  private readonly onPlayerStateChange = new LiteEvent<PlayerStateChangeEvent>();
  private readonly onPlaySpeedChange = new LiteEvent<PlaySpeedChangeEvent>();
  private readonly onAudioStateChange = new LiteEvent<AudioStateChangeEvent>();
  private readonly onAudioAvailabilityChange = new LiteEvent<AudioAvailabilityChangeEvent>();
  private readonly onTimelineContentUpdated = new LiteEvent<TimelineContentEvent>();
  private readonly onPlayerModeChange = new LiteEvent<PlayerModeChangeEvent>();
  private readonly onBufferingProgress = new LiteEvent<number>();

  private readonly m_intervalHandle: number;
  private m_lastFrameReceived?: ResolvedFrameTime;
  private m_lastFrameRendered?: ResolvedFrameTime;
  private m_videoCodec: Codec = Codec.None;
  private m_lastJpegResolution: Resolution = Resolution.None;
  private m_networkLatency: number | null = 0;
  private m_globalLatency: number | null = 0;
  private m_audioAvailable: boolean = false;

  // *** Events exposure
  public get errorStateRaised(): ILiteEvent<ErrorStatusEvent> {
    return this.onError.expose();
  }
  public get frameRendered(): ILiteEvent<Date> {
    return this.onFrameRendered.expose();
  }
  public get streamStatusChanged(): ILiteEvent<StreamingConnectionStatusChangeEvent> {
    return this.onStreamStatusChange.expose();
  }
  public get playerStateChanged(): ILiteEvent<PlayerStateChangeEvent> {
    return this.onPlayerStateChange.expose();
  }
  public get playSpeedChanged(): ILiteEvent<PlaySpeedChangeEvent> {
    return this.onPlaySpeedChange.expose();
  }
  public get audioStateChanged(): ILiteEvent<AudioStateChangeEvent> {
    return this.onAudioStateChange.expose();
  }
  public get audioAvailabilityChanged(): ILiteEvent<AudioAvailabilityChangeEvent> {
    return this.onAudioAvailabilityChange.expose();
  }
  public get timelineContentUpdated(): ILiteEvent<TimelineContentEvent> {
    return this.onTimelineContentUpdated.expose();
  }
  public get playerModeChanged(): ILiteEvent<PlayerModeChangeEvent> {
    return this.onPlayerModeChange.expose();
  }
  public get bufferingProgress(): ILiteEvent<number> {
    return this.onBufferingProgress.expose();
  }
  // *** Events

  public get codec(): string {
    return this.m_videoCodec;
  }

  public get lastJpegResolution(): Resolution {
    return this.m_lastJpegResolution;
  }

  public get networkLatency(): number | null {
    return this.m_networkLatency;
  }

  public get globalLatency(): number | null {
    return this.m_globalLatency;
  }

  public get lastFrameReceived(): ResolvedFrameTime {
    if (this.m_lastFrameReceived === undefined) {
      return new ResolvedFrameTime(new Date(0), 0);
    }
    return this.m_lastFrameReceived;
  }

  public get lastFrameRendered(): ResolvedFrameTime {
    if (this.m_lastFrameRendered === undefined) {
      return new ResolvedFrameTime(new Date(0), 0);
    }
    return this.m_lastFrameRendered;
  }

  public get bitRate(): BitRate {
    return this.m_statisticProvider.getBitRate();
  }

  public get audioBitRate(): BitRate {
    return this.m_statisticProvider.getAudioBitRate();
  }

  public get frameRate(): FrameRate {
    return this.m_statisticProvider.getFrameRate();
  }

  public get isAudioAvailable(): boolean {
    return this.m_audioAvailable;
  }

  constructor(logger: ILogger, statisticProvider: StatisticProvider, frameRenderedEventFrequency?: number) {
    this.m_logger = logger.subLogger('EventManager');
    this.m_statisticProvider = statisticProvider;

    // We have to raise the frame rendered event ourselves because the "video" element doesn't do it consistently
    frameRenderedEventFrequency = frameRenderedEventFrequency ?? EventManager.FrameRenderedEventFrequency;
    this.m_intervalHandle = window.setInterval(this.onTimeToRaiseFrameRendered.bind(this), frameRenderedEventFrequency);
    this.m_logger.debug?.trace(`Setting frame rendered event frequency to ${new TimeSpan(frameRenderedEventFrequency).toString()}`);
  }

  public dispose() {
    window.clearInterval(this.m_intervalHandle);
    this.onError.dispose();
    this.onFrameRendered.dispose();
    this.onStreamStatusChange.dispose();
    this.onPlayerStateChange.dispose();
    this.onPlaySpeedChange.dispose();
    this.onPlayerModeChange.dispose();
  }

  public reset() {
    this.m_videoCodec = Codec.None;
    this.m_lastJpegResolution = Resolution.None;
    this.m_networkLatency = 0;
    this.m_globalLatency = 0;
    this.m_lastFrameReceived = undefined;
    this.m_lastFrameRendered = undefined;
    this.m_lastRaisedFrameRendered = undefined;
  }

  public setLastFrameReceived(receivedFrameTime: ResolvedFrameTime, videoCodec: Codec) {
    this.m_lastFrameReceived = receivedFrameTime;
    this.m_videoCodec = videoCodec;

    // Keep track of live latency when possible
    const now = new Date().getTime();
    const frameTime = receivedFrameTime.frameTime.getTime();
    if (now < frameTime) { // Can happen if browser and MediaGateway clock are not synchronized
      this.m_networkLatency = 0;
    } else {
      this.m_networkLatency = (now - frameTime) / 1000;
    }
  }

  public setLastFrameRendered(renderedFrameTime: ResolvedFrameTime) {
    this.m_logger.intense?.trace(`Setting last frame rendered to ${Utils.formatDate(renderedFrameTime.frameTime)}`);
    this.m_lastFrameRendered = renderedFrameTime;

    const now = new Date().getTime();
    const frameTime = renderedFrameTime.frameTime.getTime();
    if (now < frameTime) { // Can happen if browser and MediaGateway clock are not synchronized
      this.m_globalLatency = 0;
    } else {
      this.m_globalLatency = (now - frameTime) / 1000;
    }
  }

  public setLastJpegFrameRendered(frameTime: Date, lastJpegResolution: Resolution) {
    this.m_logger.intense?.trace(`Setting last frame rendered by jpeg to ${Utils.formatDate(frameTime)}`);
    this.m_lastFrameRendered = new ResolvedFrameTime(frameTime);
    this.m_lastJpegResolution = lastJpegResolution;

    const now = new Date().getTime();
    const frameTimeMs = frameTime.getTime();
    if (now < frameTimeMs) { // Can happen if browser and MediaGateway clock are not synchronized
      this.m_globalLatency = 0;
    } else {
      this.m_globalLatency = (now - frameTimeMs) / 1000;
    }
  }

  public receiveAudioAvailable(isAudioAvailable: boolean) {
    this.m_logger.debug?.trace('SetAudioAvailable:', isAudioAvailable);
    this.m_audioAvailable = isAudioAvailable;
    this.onAudioAvailabilityChange.trigger(new AudioAvailabilityChangeEvent(isAudioAvailable));
  }

  public receiveErrorDetails(details: ErrorDetails) {
    this.m_logger.warn?.trace('server stream stopped. Received error details:', details);
    const errorCode: ErrorCode = details.ErrorCode ?? ErrorCode.Unknown;
    this.onError.trigger(new ErrorStatusEvent(errorCode, details.Value));
  }

  public receivePlayerState(playerState: SerializedPlayerState) {
    this.m_logger.info?.trace('Player state changed:', playerState);
    this.onPlayerStateChange.trigger(new PlayerStateChangeEvent(playerState.State, playerState.Value));
  }

  public receiveStreamingConnectionStatus = (streamingStatus: StreamingStatus) => {
    this.m_logger.intense?.trace('Streaming connection status update:', StreamingConnectionStatus[streamingStatus.State]);
    this.onStreamStatusChange.trigger(new StreamingConnectionStatusChangeEvent(streamingStatus.State, streamingStatus.Value));
  }

  public receivePlaySpeed = (videoSpeed: PlaySpeed) => {
    this.m_logger.info?.trace('Play speed changed:', videoSpeed);
    this.onPlaySpeedChange.trigger(new PlaySpeedChangeEvent(videoSpeed.Value));
  }

  public receivePlayerMode = (playerMode: PlayerMode) => {
    this.m_logger.info?.trace('Player mode changed:', playerMode);
    this.onPlayerModeChange.trigger(new PlayerModeChangeEvent(playerMode));
  }

  public receiveAudioState = (isAudioEnabled: boolean) => {
    this.m_logger.info?.trace('Audio state changed:', isAudioEnabled);
    this.onAudioStateChange.trigger(new AudioStateChangeEvent(isAudioEnabled));
  }

  public receiveTimelineContent = (timelineContent: TimelineContent) => {
    this.onTimelineContentUpdated.trigger(new TimelineContentEvent(new Date(timelineContent.Coverage.Start),
      new Date(timelineContent.Coverage.End),
      timelineContent.Events.map((e) => new TimelineEvent(new Date(e.EventTime), e.Kind, e.Duration, e.Details))));
  }

  public receiveBufferingProgress = (progress: number) => {
    this.onBufferingProgress.trigger(progress);
  };

  private m_lastRaisedFrameRendered: Date | undefined = undefined;

  private onTimeToRaiseFrameRendered() {
    if (this.m_lastFrameRendered === undefined || this.m_lastFrameRendered.frameTime === this.m_lastRaisedFrameRendered) {
      return; // No frame were rendered yet
    }

    this.m_lastRaisedFrameRendered = this.m_lastFrameRendered.frameTime;
    this.onFrameRendered.trigger(this.m_lastFrameRendered.frameTime);
  }
}

export enum Codec {
  None = 'None',
  H264 = 'H264',
  Mjpeg = 'Mjpeg',
}
