// Autogenerated: v2/help/Entities/ApiTilePlugin?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:25 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { IGuid } from 'safeguid';
import { ITilePluginEntity, TilePluginEntityFields } from '../Interface/ITilePluginEntity';
import { ValidFlags } from '../Enumerations/ValidFlags';

export class TilePluginEntity extends Entity implements ITilePluginEntity {
    // #region REST Properties

    // Context
    public get context(): string {
        return this.getField<string>(TilePluginEntityFields.contextField, ValidFlags.Specific);
    }
    public set context(value: string) {
        this.setField<string>(TilePluginEntityFields.contextField, value);
    }

    // ContextId
    public get contextId(): IGuid {
        return this.getFieldGuid(TilePluginEntityFields.contextIdField, ValidFlags.Specific);
    }
    public set contextId(value: IGuid) {
        this.setFieldGuid(TilePluginEntityFields.contextIdField, value);
    }

    // File
    public get file(): IGuid {
        return this.getFieldGuid(TilePluginEntityFields.fileField, ValidFlags.Specific);
    }

    // RawLargeImage
    public get rawLargeImage(): string {
        return this.getField<string>(TilePluginEntityFields.rawLargeImageField, ValidFlags.Specific);
    }

    // RawSmallImage
    public get rawSmallImage(): string {
        return this.getField<string>(TilePluginEntityFields.rawSmallImageField, ValidFlags.Specific);
    }

    // Url
    public get url(): string {
        return this.getField<string>(TilePluginEntityFields.urlField, ValidFlags.Specific);
    }
    public set url(value: string) {
        this.setField<string>(TilePluginEntityFields.urlField, value);
    }

    // UrlDisplayMode
    public get urlDisplayMode(): string {
        return this.getField<string>(TilePluginEntityFields.urlDisplayModeField, ValidFlags.Specific);
    }
    public set urlDisplayMode(value: string) {
        this.setField<string>(TilePluginEntityFields.urlDisplayModeField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.TilePlugins);
    }

    // #endregion Constructor

}

