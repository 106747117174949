/* eslint-disable @typescript-eslint/member-ordering */
import { Inject, Injectable } from '@angular/core';
import { TileNumberDisplayPreference, TilesOptionsComponent } from '@modules/general/components/options/tiles-options/tiles-options.component';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TileItem } from '@modules/tiles/models/tile-item';
import { BehaviorSubject, Observable } from 'rxjs';
import { TileDetailsVisibility, TileNumberVisibility } from '../tile-state-header.component';

@UntilDestroy()
@Injectable()
/**
 * A unique instance of this service will be provided for each tile present in the tile canvas.
 *
 * This services relays state information such as mouseOver state, and also reads from application Storage the preferences over whether we should
 * display only the tileNumber, or the partial tile header.
 */
export class TileHeaderService {
    private stateSubject = new BehaviorSubject<TileItem | null>(null);
    public state$ = this.stateSubject.asObservable();

    /**
     * Whether the tile details are fully shown, compact, or hidden.
     */
    public tileDetailsVisibility$: Observable<TileDetailsVisibility>;

    /**
     * Whether the tile number is shown or not.
     */
    public tileNumberVisibility$: Observable<TileNumberVisibility>;

    /**
     * Options -> Video -> Show tile number
     *  */
    private tileNumberVisibilitySubject: BehaviorSubject<TileNumberVisibility>;
    private tileNumberDisplayPreference: TileNumberDisplayPreference;

    private tileDetailsVisibilitySubject: BehaviorSubject<TileDetailsVisibility>;
    private tileDetailsVisibility: TileDetailsVisibility = TileDetailsVisibility.Partial;

    constructor(@Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService) {
        this.tileDetailsVisibilitySubject = new BehaviorSubject<TileDetailsVisibility>(TileDetailsVisibility.Partial);
        this.tileDetailsVisibility$ = this.tileDetailsVisibilitySubject.asObservable();

        this.tileNumberDisplayPreference =
            this.userSettingsService.get<TileNumberDisplayPreference>(OptionTypes.Appearance, TilesOptionsComponent.TileHeaderVisibilityId) ||
            TileNumberDisplayPreference.OnlyWhenHovering;

        this.tileNumberVisibilitySubject = new BehaviorSubject<TileNumberVisibility>(TileNumberVisibility.Hidden);
        this.tileNumberVisibility$ = this.tileNumberVisibilitySubject.asObservable();

        this.userSettingsService.onSettingsChanged$.pipe(untilDestroyed(this)).subscribe(() => {
            this.readFromAppStorage();
        });
    }

    /**
     * Pushes a new state in the observable pipeline. And also pushes new visibility based on mouseOver state.
     *
     * @param state TileItem state.
     */
    public updateState(state: TileItem): void {
        this.stateSubject.next(state);

        switch (this.tileNumberDisplayPreference) {
            case TileNumberDisplayPreference.Always:
                this.tileNumberVisibilitySubject.next(TileNumberVisibility.Shown);
                break;
            case TileNumberDisplayPreference.Never:
                this.tileNumberVisibilitySubject.next(TileNumberVisibility.Hidden);
                break;
            case TileNumberDisplayPreference.OnlyWhenHovering:
                this.tileNumberVisibilitySubject.next(state.isMouseOver || state.isSelected ? TileNumberVisibility.Shown : TileNumberVisibility.Hidden);
                break;
        }

        if (state.isMouseOver || state.isSelected) {
            this.tileDetailsVisibilitySubject.next(TileDetailsVisibility.Full);
        } else {
            this.tileDetailsVisibilitySubject.next(this.tileDetailsVisibility);
        }
    }

    private readFromAppStorage(): void {
        // Read from storage chosen option.
        this.tileNumberDisplayPreference =
            this.userSettingsService.get<TileNumberDisplayPreference>(OptionTypes.Appearance, TilesOptionsComponent.TileHeaderVisibilityId) ||
            TileNumberDisplayPreference.OnlyWhenHovering;

        // push default tileDetailsVisibility
        this.tileDetailsVisibilitySubject.next(TileDetailsVisibility.Partial);

        this.updateCurrentState();
    }

    private updateCurrentState(): void {
        const currentState = this.stateSubject.getValue();
        if (currentState) {
            this.updateState(currentState);
        }
    }
}
