import { Injectable } from '@angular/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { Mutex } from 'RestClient/Helpers/semaphore';
import { IGuid, SafeGuid } from 'safeguid';
import { ApiException, FeaturesClient } from '../../api/api';
import { LoggerService } from '../logger/logger.service';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable({
    providedIn: 'root',
})
export class FeaturesService {
    //#region Fields

    private featuresInternal = SafeGuid.createSet();
    private getFeaturesMutex = new Mutex();
    private getFeaturesMutexRelease: (() => void) | null = null;
    private scClient!: SecurityCenterClient;

    //#endregion

    //#region Constructors

    constructor(private featuresClient: FeaturesClient, scClientService: SecurityCenterClientService, private logger: LoggerService) {
        this.scClient = scClientService.scClient;
        if (this.scClient) {
            this.aquireGetFeaturesMutex().fireAndForget();
            if (this.scClient.isLoggedOn) {
                this.refreshFeatures().fireAndForget();
            }
            this.scClient.onLogonStateChanged(async (args) => {
                if (args.loggedOn()) {
                    await this.refreshFeatures();
                } else {
                    if (!this.getFeaturesMutexRelease) {
                        await this.aquireGetFeaturesMutex();
                    }

                    this.featuresInternal.clear();
                }
            });
        }
    }

    //#endregion

    //#region Public Methods

    public async getFeaturesAsync(): Promise<Set<IGuid>> {
        (await this.getFeaturesMutex.acquire())();
        return this.featuresInternal;
    }

    private async aquireGetFeaturesMutex() {
        this.getFeaturesMutexRelease = await this.getFeaturesMutex.acquire();
    }

    private async refreshFeatures(): Promise<void> {
        try {
            this.featuresInternal = SafeGuid.createSet(await this.featuresClient.getAvailableFeatures().toPromise());
            this.logger.traceDebug(`There are ${this.featuresInternal.size} features enabled`);
        } catch (err) {
            if (err instanceof ApiException) {
                this.logger.traceError(`Failed to retrieve feature set : Code ${err.status}`, err.message);
            } else {
                this.logger.traceError('Failed to retrieve feature set : Unknown error', err);
            }
        } finally {
            if (this.getFeaturesMutexRelease) {
                this.getFeaturesMutexRelease();
                this.getFeaturesMutexRelease = null;
            }
        }
    }

    //#endregion
}
