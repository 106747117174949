import { Component, OnInit, ViewChild } from '@angular/core';
import { McUserService } from '@modules/mission-control/services/mc-user.service';
import { FilterClient } from '@modules/shared/api/api';
import { EntityComboComponent } from '@modules/shared/components/entity-browser/entity-combo/entity-combo.component';
import { FilterBaseComponent } from '@modules/shared/components/filters/common-filters/filter-base.component';
import { SharedEntityBrowserRefreshTypes } from '@modules/shared/entity-browser/enumerations/shared-entity-browser-refresher-types';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { FilterCoordinatorService } from '@modules/shared/services/filters/filter-coordinator-service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { TranslateService } from '@ngx-translate/core';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { IGuid, SafeGuid } from 'safeguid';
import { FilterOwnerMode } from './filter-owner-mode';

@Component({
    selector: 'app-filter-owner',
    templateUrl: './filter-owner.component.html',
    styleUrls: ['./filter-owner.component.scss'],
})
export class FilterOwnerComponent extends FilterBaseComponent<IGuid[]> implements OnInit {
    //#region Public fields
    @ViewChild('entityCombo') public entityCombo!: EntityComboComponent;
    public readonly FilterOwnerMode = FilterOwnerMode;

    public mode = FilterOwnerMode.Any;
    public users = new Array<IGuid>();
    public userName = '';
    public ownerBrowserFilter = new EntityBrowserFilter(EntityTypes.Users);

    //#endregion

    constructor(
        trackingService: TrackingService,
        filterClient: FilterClient,
        filterCoordinatorService: FilterCoordinatorService,
        translateService: TranslateService,
        private _mcUserService: McUserService
    ) {
        super(trackingService, filterClient, filterCoordinatorService, translateService);
        //Needed to not show user groups because MC Incident Manager backend doesn't work with user groups
        this.ownerBrowserFilter.refresherType = SharedEntityBrowserRefreshTypes.FlatView;
    }

    //#region Public methods

    public ngOnInit(): void {
        super.ngOnInit();
        this.value = [];
    }

    public onFilterChange(newMode: string): void {
        this.mode = FilterOwnerMode[newMode as keyof typeof FilterOwnerMode];

        switch (this.mode) {
            case FilterOwnerMode.Any:
                this.users = [];
                break;
            case FilterOwnerMode.OwnedByMe: {
                const user = this._mcUserService.currentUser;
                if (!user) return;
                this.users = [user.userInfo.id];
                break;
            }
        }

        this.updateValue();
    }

    public async onUserChange(users: IGuid[]): Promise<void> {
        this.users = users;
        if (users.length === 1) this.userName = (await this._mcUserService.getUser(this.users[0]).toPromise())?.name ?? '';
        this.updateValue();
    }

    public updateState(): void {
        let selection = this.state.selection;

        switch (this.mode) {
            case FilterOwnerMode.Any:
                selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
                break;
            case FilterOwnerMode.OwnedByMe:
                selection = this.translateService.instant('STE_LABEL_INCIDENTOWNEDBYME') as string;
                break;
            case FilterOwnerMode.User:
                if (this.users.length === 0) {
                    selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
                } else if (this.users.length === 1) {
                    selection = stringFormat(this.translateService.instant('STE_LABEL_INCIDENTOWNEDBY_X'), this.userName);
                } else {
                    selection = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: this.users.length }) as string;
                }
                break;
        }

        this.state = { ...this.state, selection };
    }

    public clearFilter(): void {
        this.entityCombo.clearSelection();
        this.mode = FilterOwnerMode.Any;
        this.users = [];
        this.updateValue();
    }

    public isDefaulted(): boolean {
        return this.value.length === 0;
    }

    public override isDirty(firstValue: IGuid[], secondValue: IGuid[]) {
        const arr1 = firstValue.map((guid: SafeGuid) => guid.toString());
        const arr2 = secondValue.map((guid: SafeGuid) => guid.toString());

        if (arr1.length !== arr2.length) {
            return true;
        }

        // compare each item
        return arr1.some((value, index) => arr2.indexOf(value) === -1);
    }

    public override getDefaultValue(): IGuid[] {
        return [];
    }

    //#endregion

    //#region Protected and Private methods

    private updateValue() {
        this.value = this.users;
    }

    //#endregion
}
