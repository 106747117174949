<gen-melted-modal
    genTitle="{{ 'STE_TITLE_TRIGGERANINCIDENT' | translate }}"
    id="incidentTypesModal"
    [genCanClose]="true"
    [genDefaultActionText]="'STE_BUTTON_TRIGGER' | translate"
    [genOnDefaultAction]="triggerAction"
    [genIsDefaultActionDisabled]="!canUserTrigger || !commentInput.valid"
    [genAlternativeActionText]="'STE_BUTTON_CANCEL' | translate"
>
    <gen-melted-modal-body>
        <gen-grid [genColumnsMD]="1" [genColumnsLG]="2" [genRows]="1">
            <gen-grid-item [genRowSpan]="2">
                <gen-search-input
                    [ngModel]="searchText"
                    (ngModelChange)="onSearchInputChange($event)"
                    [genDebounce]="300"
                    [genMinLength]="3"
                    genLabel="{{ 'STE_LABEL_NAME' | translate }}"
                    [genLabelComplement]="LabelComplement.Required"
                >
                </gen-search-input>
                <div *ngIf="incidentTypeTreeItems$ | async as incidentTypeTreeItems; else loading" class="incident-type-tree-view">
                    <gen-tree
                        *ngIf="incidentTypeTreeItems.length > 0; else noIncidentError"
                        id="incidentTypeTree"
                        [selectionType]="SelectionTypes.Single"
                        [density]="TreeDensity.Compact"
                    >
                        <ng-container *ngTemplateOutlet="treeItem; context: { list: incidentTypeTreeItems }"></ng-container>
                    </gen-tree>
                    <ng-template #noIncidentError>
                        <div *ngIf="incidentTypeTreeItems.length === 0" class="mt-2">
                            <gen-text [flavor]="TextFlavor.Warning">{{ 'STE_MESSAGE_NO_INCIDENTS_FOUND' | translate }}</gen-text>
                        </div>
                    </ng-template>
                </div>
            </gen-grid-item>
            <gen-grid-item>
                <app-entity-combo
                    [entityId]="selectedLocation"
                    (entityIdsChange)="onLocationChanged($event[0])"
                    name="incidentLocation"
                    genLabel="{{ 'STE_LABEL_INCIDENT_LOCATION' | translate }}"
                    [multiSelect]="false"
                    [entityBrowserFilter]="incidentTypeModalBrowserFilter"
                >
                </app-entity-combo>
                <div class="ml-1 mt-1">
                    <gen-text
                        [flavor]="Flavor.Text.Normal"
                        [hidden]="!selectedCoordinates"
                        [textContent]="selectedCoordinates ? selectedCoordinates.x.toFixed(6) + ' / ' + selectedCoordinates.y.toFixed(6) : ''"
                    ></gen-text>
                </div>
                <div class="incident-type-second-row">
                    <app-incident-comment [(ngModel)]="comment" #commentInput="ngModel" [maxlength]="1024"></app-incident-comment>
                </div>
            </gen-grid-item>
        </gen-grid>
    </gen-melted-modal-body>
</gen-melted-modal>

<ng-template #loading>
    <div class="p-2">
        <gen-spinner class="va-center">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
    </div>
</ng-template>

<ng-template let-list="list" #treeItem>
    <gen-tree-item *ngFor="let item of list" [selected]="item.isChecked" [expanded]="item.isExpanded ?? false" (selectedChange)="onSelectedIncidentTypeChange(item, $event)">
        <gen-tree *ngIf="item.children?.length" [selectionType]="SelectionTypes.Single" [density]="TreeDensity.Compact">
            <ng-container *ngTemplateOutlet="treeItem; context: { list: item.children }"></ng-container>
        </gen-tree>

        <div class="incident-type-tree-item">
            <gen-image *ngIf="item.image" [src]="item.image"></gen-image>
            <gen-icon *ngIf="!item.image" [icon]="item.icon"></gen-icon>
            <gen-text>{{ item.text }}</gen-text>
        </div>
    </gen-tree-item>
</ng-template>
