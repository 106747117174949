import { Component, OnInit } from '@angular/core';
import { Icon, TextFlavor, ItemSlot } from '@genetec/gelato';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { CredentialEntityFields } from 'RestClient/Client/Interface/ICredentialEntity';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { KnownContentContext } from '@modules/shared/services/content/KnownContentContext';
import { CredentialWidgetBaseComponent } from '../credential-widget-base.component';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';

const isContentSupported = (content: Content): boolean => {
    return !!(content?.contextContent && content.type.equals(AccessControlContentTypes.Credential));
};

@InternalContentPluginDescriptor({
    type: CredentialPreviewWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CredentialPreviewWidgetComponent.pluginId, priority: 4 },
    isContentSupported,
})
@Component({
    selector: 'app-credential-preview-widget',
    templateUrl: './credential-preview-widget.component.html',
    styleUrls: ['./credential-preview-widget.component.scss'],
})
export class CredentialPreviewWidgetComponent extends CredentialWidgetBaseComponent implements OnInit {
    public static pluginId = SafeGuid.parse('C583E613-0D7E-41FE-9D1E-689901B033AE');

    public readonly Icon = Icon;
    public readonly ItemSlot = ItemSlot;
    public readonly TextFlavor = TextFlavor;

    public credentialName = '';
    public status = '';

    protected get monitoredFields(): string[] {
        const fields = super.monitoredFields;
        fields.push(CredentialEntityFields.accessStatusField);
        return fields;
    }

    constructor(
        securityCenterClientService: SecurityCenterClientService,
        private contentProviderService: ContentProviderService,
        private translateService: TranslateService,
        trackingService: TrackingService
    ) {
        super(securityCenterClientService, trackingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();
        this.isLoading = false;
    }

    public async navigateToCredentialAsync(): Promise<void> {
        if (this.credential) {
            const content = await this.contentProviderService.getContentAsync(this.credential.id, KnownContentContext.Navigation);
            if (content) {
                this.dataContext?.pushContent(content);
            }
        }
    }

    protected async initializeCredentialInfo(): Promise<void> {
        await super.initializeCredentialInfo();

        if (this.credential) {
            this.credentialName = this.credential.name;
            const now = new Date();
            this.status = this.translateService.instant(
                this.credential.accessStatus === AccessStatus.Active ? 'STE_LABEL_CREDENTIAL_ACTIVE' : 'STE_LABEL_CREDENTIAL_INACTIVE'
            ) as string;
        } else {
            this.credentialName = this.translateService.instant('STE_LABEL_CREDENTIAL_UNKNOWN') as string;
            this.status = '';
        }
    }
}
