// Autogenerated: CodeGen/WebMapObjectType?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapObjectType?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapLayer?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapLayer?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapObject?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapObject?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapObjectResult?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapObjectResult?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapObjectFilter?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapObjectFilter?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapLayerNotificationFilter?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapLayerNotificationFilter?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapNotificationFilter?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapNotificationFilter?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/ConcreteWebMapObjectFilter?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/ConcreteWebMapObjectFilter?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapNotificationFilterRequest?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/WebMapNotificationFilterRequest?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/FloorQuery?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/FloorQuery?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/FloorQueryResult?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/FloorQueryResult?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/Floor?language=ts&InterfaceOnly=True on 2023-01-16 1:33:30 PM
// Autogenerated: CodeGen/Floor?language=ts&InterfaceOnly=False on 2023-01-16 1:33:30 PM

import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';

export interface IWebMapObjectType extends IFieldObject {
    typeId: IGuid;
    layerId: IGuid;
    name: string;
    icon: string;
    color: string;
    category: string;
    customIcon: string;
    supportsNavigation: boolean;
    clusterable: boolean;
}

export class WebMapObjectType extends FieldObject implements IWebMapObjectType {
    // TypeId
    public get typeId(): IGuid {
        return this.getFieldGuid('TypeId');
    }
    public set typeId(value: IGuid) {
        this.setFieldGuid('TypeId', value);
    }
    // LayerId
    public get layerId(): IGuid {
        return this.getFieldGuid('LayerId');
    }
    public set layerId(value: IGuid) {
        this.setFieldGuid('LayerId', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Icon
    public get icon(): string {
        return this.getField<string>('Icon');
    }
    public set icon(value: string) {
        this.setField<string>('Icon', value);
    }
    // Color
    public get color(): string {
        return this.getField<string>('Color');
    }
    public set color(value: string) {
        this.setField<string>('Color', value);
    }
    // Category
    public get category(): string {
        return this.getField<string>('Category');
    }
    public set category(value: string) {
        this.setField<string>('Category', value);
    }
    // CustomIcon
    public get customIcon(): string {
        return this.getField<string>('CustomIcon');
    }
    public set customIcon(value: string) {
        this.setField<string>('CustomIcon', value);
    }
    // SupportsNavigation
    public get supportsNavigation(): boolean {
        return this.getField<boolean>('SupportsNavigation');
    }
    public set supportsNavigation(value: boolean) {
        this.setField<boolean>('SupportsNavigation', value);
    }
    // Clusterable
    public get clusterable(): boolean {
        return this.getField<boolean>('Clusterable');
    }
    public set clusterable(value: boolean) {
        this.setField<boolean>('Clusterable', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('TypeId', SafeGuid.EMPTY);
        this.setFieldGuid('LayerId', SafeGuid.EMPTY);
        this.setField<string>('Name', '');
        this.setField<string>('Icon', '');
        this.setField<string>('Color', '');
        this.setField<string>('Category', '');
        this.setField<string>('CustomIcon', '');
        this.setField<boolean>('SupportsNavigation', false);
        this.setField<boolean>('Clusterable', false);
    }
}

export interface IWebMapLayer extends IFieldObject {
    id: IGuid;
    includedTypes: ObservableCollection<IWebMapObjectType>;
    name: string;
    icon: string;
    customIcon: string;
    color: string;
}

export class WebMapLayer extends FieldObject implements IWebMapLayer {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // IncludedTypes
    public get includedTypes(): ObservableCollection<IWebMapObjectType> {
        return this.getFieldObjectArray<WebMapObjectType, IWebMapObjectType>(WebMapObjectType, 'IncludedTypes');
    }
    public set includedTypes(value: ObservableCollection<IWebMapObjectType>) {
        this.setFieldObjectArray('IncludedTypes', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Icon
    public get icon(): string {
        return this.getField<string>('Icon');
    }
    public set icon(value: string) {
        this.setField<string>('Icon', value);
    }
    // CustomIcon
    public get customIcon(): string {
        return this.getField<string>('CustomIcon');
    }
    public set customIcon(value: string) {
        this.setField<string>('CustomIcon', value);
    }
    // Color
    public get color(): string {
        return this.getField<string>('Color');
    }
    public set color(value: string) {
        this.setField<string>('Color', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setFieldArray<IWebMapObjectType>('IncludedTypes', new ObservableCollection<IWebMapObjectType>());
        this.setField<string>('Name', '');
        this.setField<string>('Icon', '');
        this.setField<string>('CustomIcon', '');
        this.setField<string>('Color', '');
    }
}

export interface IWebMapObject extends IFieldObject {
    additionalLinks: ObservableCollection<IGuid>;
    background: string;
    rotation: number;
    zIndex: number;
    overridenMainIcon: string;
    overlayIcon: string;
    overlayIconColor: string;
    stateColor: string;
    lettersToDisplayOnMarker: string;
    guid: IGuid;
    type: IGuid;
    source: string;
    name: string;
    latitude: number;
    longitude: number;
    file: IGuid | null;
    customIconId: IGuid | null;
    customIconState: string;
}

export class WebMapObject extends FieldObject implements IWebMapObject {
    // AdditionalLinks
    public get additionalLinks(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('AdditionalLinks');
    }
    public set additionalLinks(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('AdditionalLinks', value);
    }
    // Background
    public get background(): string {
        return this.getField<string>('Background');
    }
    public set background(value: string) {
        this.setField<string>('Background', value);
    }
    // Rotation
    public get rotation(): number {
        return this.getField<number>('Rotation');
    }
    public set rotation(value: number) {
        this.setField<number>('Rotation', value);
    }
    // ZIndex
    public get zIndex(): number {
        return this.getField<number>('ZIndex');
    }
    public set zIndex(value: number) {
        this.setField<number>('ZIndex', value);
    }
    // OverridenMainIcon
    public get overridenMainIcon(): string {
        return this.getField<string>('OverridenMainIcon');
    }
    public set overridenMainIcon(value: string) {
        this.setField<string>('OverridenMainIcon', value);
    }
    // OverlayIcon
    public get overlayIcon(): string {
        return this.getField<string>('OverlayIcon');
    }
    public set overlayIcon(value: string) {
        this.setField<string>('OverlayIcon', value);
    }
    // OverlayIconColor
    public get overlayIconColor(): string {
        return this.getField<string>('OverlayIconColor');
    }
    public set overlayIconColor(value: string) {
        this.setField<string>('OverlayIconColor', value);
    }
    // StateColor
    public get stateColor(): string {
        return this.getField<string>('StateColor');
    }
    public set stateColor(value: string) {
        this.setField<string>('StateColor', value);
    }
    // LettersToDisplayOnMarker
    public get lettersToDisplayOnMarker(): string {
        return this.getField<string>('LettersToDisplayOnMarker');
    }
    public set lettersToDisplayOnMarker(value: string) {
        this.setField<string>('LettersToDisplayOnMarker', value);
    }
    // Guid
    public get guid(): IGuid {
        return this.getFieldGuid('Guid');
    }
    public set guid(value: IGuid) {
        this.setFieldGuid('Guid', value);
    }
    // Type
    public get type(): IGuid {
        return this.getFieldGuid('Type');
    }
    public set type(value: IGuid) {
        this.setFieldGuid('Type', value);
    }
    // Source
    public get source(): string {
        return this.getField<string>('Source');
    }
    public set source(value: string) {
        this.setField<string>('Source', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // Latitude
    public get latitude(): number {
        return this.getField<number>('Latitude');
    }
    public set latitude(value: number) {
        this.setField<number>('Latitude', value);
    }
    // Longitude
    public get longitude(): number {
        return this.getField<number>('Longitude');
    }
    public set longitude(value: number) {
        this.setField<number>('Longitude', value);
    }
    // File
    public get file(): IGuid | null {
        return this.getNullableFieldGuid('File');
    }
    public set file(value: IGuid | null) {
        this.setNullableFieldGuid('File', value);
    }
    // CustomIconId
    public get customIconId(): IGuid | null {
        return this.getNullableFieldGuid('CustomIconId');
    }
    public set customIconId(value: IGuid | null) {
        this.setNullableFieldGuid('CustomIconId', value);
    }
    // CustomIconState
    public get customIconState(): string {
        return this.getField<string>('CustomIconState');
    }
    public set customIconState(value: string) {
        this.setField<string>('CustomIconState', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldArrayGuid('AdditionalLinks', new ObservableCollection<IGuid>());
        this.setField<string>('Background', '');
        this.setField<number>('Rotation', 0);
        this.setField<number>('ZIndex', 0);
        this.setField<string>('OverridenMainIcon', '');
        this.setField<string>('OverlayIcon', '');
        this.setField<string>('OverlayIconColor', '');
        this.setField<string>('StateColor', '');
        this.setField<string>('LettersToDisplayOnMarker', '');
        this.setFieldGuid('Guid', SafeGuid.EMPTY);
        this.setFieldGuid('Type', SafeGuid.EMPTY);
        this.setField<string>('Source', '');
        this.setField<string>('Name', '');
        this.setField<number>('Latitude', 0);
        this.setField<number>('Longitude', 0);
        this.setFieldGuid('File', SafeGuid.EMPTY);
        this.setFieldGuid('CustomIconId', SafeGuid.EMPTY);
        this.setField<string>('CustomIconState', '');
    }
}

export interface IWebMapObjectResult extends IFieldObject {
    mapObjects: ObservableCollection<IWebMapObject>;
}

export class WebMapObjectResult extends FieldObject implements IWebMapObjectResult {
    // MapObjects
    public get mapObjects(): ObservableCollection<IWebMapObject> {
        return this.getFieldObjectArray<WebMapObject, IWebMapObject>(WebMapObject, 'MapObjects');
    }
    public set mapObjects(value: ObservableCollection<IWebMapObject>) {
        this.setFieldObjectArray('MapObjects', value);
    }

    public initializeAllFields(): void {
        this.setFieldArray<IWebMapObject>('MapObjects', new ObservableCollection<IWebMapObject>());
    }
}

export interface IWebMapObjectFilter extends IFieldObject {
    mapId: IGuid;
    name: string;
    layerIds: ObservableCollection<IGuid>;
    onlyOnlineLayerIds: ObservableCollection<IGuid>;
}

export class WebMapObjectFilter extends FieldObject implements IWebMapObjectFilter {
    // MapId
    public get mapId(): IGuid {
        return this.getFieldGuid('MapId');
    }
    public set mapId(value: IGuid) {
        this.setFieldGuid('MapId', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // LayerIds
    public get layerIds(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('LayerIds');
    }
    public set layerIds(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('LayerIds', value);
    }
    // OnlyOnlineLayerIds
    public get onlyOnlineLayerIds(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('OnlyOnlineLayerIds');
    }
    public set onlyOnlineLayerIds(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('OnlyOnlineLayerIds', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('MapId', SafeGuid.EMPTY);
        this.setField<string>('Name', '');
        this.setFieldArrayGuid('LayerIds', new ObservableCollection<IGuid>());
        this.setFieldArrayGuid('OnlyOnlineLayerIds', new ObservableCollection<IGuid>());
    }
}

export interface IWebMapLayerNotificationFilter extends IFieldObject {
    layerId: IGuid;
    receiveNotifications: boolean;
}

export class WebMapLayerNotificationFilter extends FieldObject implements IWebMapLayerNotificationFilter {
    // LayerId
    public get layerId(): IGuid {
        return this.getFieldGuid('LayerId');
    }
    public set layerId(value: IGuid) {
        this.setFieldGuid('LayerId', value);
    }
    // ReceiveNotifications
    public get receiveNotifications(): boolean {
        return this.getField<boolean>('ReceiveNotifications');
    }
    public set receiveNotifications(value: boolean) {
        this.setField<boolean>('ReceiveNotifications', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('LayerId', SafeGuid.EMPTY);
        this.setField<boolean>('ReceiveNotifications', false);
    }
}

export interface IWebMapNotificationFilter extends IFieldObject {
    requesterId: IGuid;
    mapId: IGuid;
    layerFilters: ObservableCollection<IWebMapLayerNotificationFilter>;
}

export class WebMapNotificationFilter extends FieldObject implements IWebMapNotificationFilter {
    // RequesterId
    public get requesterId(): IGuid {
        return this.getFieldGuid('RequesterId');
    }
    public set requesterId(value: IGuid) {
        this.setFieldGuid('RequesterId', value);
    }
    // MapId
    public get mapId(): IGuid {
        return this.getFieldGuid('MapId');
    }
    public set mapId(value: IGuid) {
        this.setFieldGuid('MapId', value);
    }
    // LayerFilters
    public get layerFilters(): ObservableCollection<IWebMapLayerNotificationFilter> {
        return this.getFieldObjectArray<WebMapLayerNotificationFilter, IWebMapLayerNotificationFilter>(WebMapLayerNotificationFilter, 'LayerFilters');
    }
    public set layerFilters(value: ObservableCollection<IWebMapLayerNotificationFilter>) {
        this.setFieldObjectArray('LayerFilters', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('RequesterId', SafeGuid.EMPTY);
        this.setFieldGuid('MapId', SafeGuid.EMPTY);
        this.setFieldArray<IWebMapLayerNotificationFilter>('LayerFilters', new ObservableCollection<IWebMapLayerNotificationFilter>());
    }
}

export interface IConcreteWebMapObjectFilter extends IFieldObject {
    mapId: IGuid;
    mapObjectIds: ObservableCollection<IGuid>;
    sourceIds: ObservableCollection<string>;
}

export class ConcreteWebMapObjectFilter extends FieldObject implements IConcreteWebMapObjectFilter {
    // MapId
    public get mapId(): IGuid {
        return this.getFieldGuid('MapId');
    }
    public set mapId(value: IGuid) {
        this.setFieldGuid('MapId', value);
    }
    // MapObjectIds
    public get mapObjectIds(): ObservableCollection<IGuid> {
        return this.getFieldArrayGuid('MapObjectIds');
    }
    public set mapObjectIds(value: ObservableCollection<IGuid>) {
        this.setFieldArrayGuid('MapObjectIds', value);
    }
    // SourceIds
    public get sourceIds(): ObservableCollection<string> {
        return this.getFieldArray<string>('SourceIds');
    }
    public set sourceIds(value: ObservableCollection<string>) {
        this.setFieldArray<string>('SourceIds', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('MapId', SafeGuid.EMPTY);
        this.setFieldArrayGuid('MapObjectIds', new ObservableCollection<IGuid>());
        this.setFieldArray<string>('SourceIds', new ObservableCollection<string>());
    }
}

export interface IWebMapNotificationFilterRequest extends IFieldObject {
    requesterId: IGuid;
    mapId: IGuid;
}

export class WebMapNotificationFilterRequest extends FieldObject implements IWebMapNotificationFilterRequest {
    // RequesterId
    public get requesterId(): IGuid {
        return this.getFieldGuid('RequesterId');
    }
    public set requesterId(value: IGuid) {
        this.setFieldGuid('RequesterId', value);
    }
    // MapId
    public get mapId(): IGuid {
        return this.getFieldGuid('MapId');
    }
    public set mapId(value: IGuid) {
        this.setFieldGuid('MapId', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('RequesterId', SafeGuid.EMPTY);
        this.setFieldGuid('MapId', SafeGuid.EMPTY);
    }
}

export interface IFloorQuery extends IFieldObject {
    mapId: IGuid;
    parentAreaId: IGuid;
}

export class FloorQuery extends FieldObject implements IFloorQuery {
    // MapId
    public get mapId(): IGuid {
        return this.getFieldGuid('MapId');
    }
    public set mapId(value: IGuid) {
        this.setFieldGuid('MapId', value);
    }
    // ParentAreaId
    public get parentAreaId(): IGuid {
        return this.getFieldGuid('ParentAreaId');
    }
    public set parentAreaId(value: IGuid) {
        this.setFieldGuid('ParentAreaId', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('MapId', SafeGuid.EMPTY);
        this.setFieldGuid('ParentAreaId', SafeGuid.EMPTY);
    }
}

export interface IFloorQueryResult extends IFieldObject {
    parentAreaId: IGuid;
    parentMapId: IGuid;
    floors: ObservableCollection<IFloor>;
}

export class FloorQueryResult extends FieldObject implements IFloorQueryResult {
    // ParentAreaId
    public get parentAreaId(): IGuid {
        return this.getFieldGuid('ParentAreaId');
    }
    public set parentAreaId(value: IGuid) {
        this.setFieldGuid('ParentAreaId', value);
    }
    // ParentMapId
    public get parentMapId(): IGuid {
        return this.getFieldGuid('ParentMapId');
    }
    public set parentMapId(value: IGuid) {
        this.setFieldGuid('ParentMapId', value);
    }
    // Floors
    public get floors(): ObservableCollection<IFloor> {
        return this.getFieldObjectArray<Floor, IFloor>(Floor, 'Floors');
    }
    public set floors(value: ObservableCollection<IFloor>) {
        this.setFieldObjectArray('Floors', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('ParentAreaId', SafeGuid.EMPTY);
        this.setFieldGuid('ParentMapId', SafeGuid.EMPTY);
        this.setFieldArray<IFloor>('Floors', new ObservableCollection<IFloor>());
    }
}

export interface IFloor extends IFieldObject {
    id: IGuid;
    abbreviation: string;
    name: string;
    orderPosition: number;
    coversMultipleAreas: boolean;
}

export class Floor extends FieldObject implements IFloor {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Abbreviation
    public get abbreviation(): string {
        return this.getField<string>('Abbreviation');
    }
    public set abbreviation(value: string) {
        this.setField<string>('Abbreviation', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // OrderPosition
    public get orderPosition(): number {
        return this.getField<number>('OrderPosition');
    }
    public set orderPosition(value: number) {
        this.setField<number>('OrderPosition', value);
    }
    // CoversMultipleAreas
    public get coversMultipleAreas(): boolean {
        return this.getField<boolean>('CoversMultipleAreas');
    }
    public set coversMultipleAreas(value: boolean) {
        this.setField<boolean>('CoversMultipleAreas', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Abbreviation', '');
        this.setField<string>('Name', '');
        this.setField<number>('OrderPosition', 0);
        this.setField<boolean>('CoversMultipleAreas', false);
    }
}
