import { Content } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';

export class PtzPrivileges {
    public readonly allowBasicPtz: boolean;
    public readonly allowVideo: boolean;
    public readonly allowPlayback: boolean;
    public readonly allowDigitalZoom: boolean;
    public readonly allowAudio: boolean;
    public readonly allowPtzLockOverride: boolean;
    public readonly allowSpecificCommands: boolean;
    public readonly allowChangeFocusAndIris: boolean;
    public readonly allowPresetsUse: boolean;
    public readonly allowEditPtzPreset: boolean;
    public readonly allowTogglePtzMenu: boolean;
    public readonly allowUsePtzAuxiliaries: boolean;
    public readonly allowSetPtzAuxiliaries: boolean;
    public readonly allowPatternsUse: boolean;
    public readonly allowLockPtz: boolean;

    constructor(initData: Content) {
        const priv = initData.grantedPrivileges;
        this.allowBasicPtz = priv.some((item) => item.equals(KnownPrivileges.basicPtzOperationsPrivilege));
        this.allowVideo = priv.some((item) => item.equals(KnownPrivileges.allowVideoPrivilege));
        this.allowPlayback = priv.some((item) => item.equals(KnownPrivileges.allowPlaybackVideoPrivilege));
        this.allowDigitalZoom = priv.some((item) => item.equals(KnownPrivileges.digitalZoomPrivilege));
        this.allowAudio = priv.some((item) => item.equals(KnownPrivileges.useAudioPrivilege));
        this.allowPtzLockOverride = priv.some((item) => item.equals(KnownPrivileges.overrideLockPtzPrivilege));
        this.allowSpecificCommands = priv.some((item) => item.equals(KnownPrivileges.useSpecificCommandsPrivilege));
        this.allowChangeFocusAndIris = priv.some((item) => item.equals(KnownPrivileges.changeFocusAndIrisPrivilege));
        this.allowPresetsUse = priv.some((item) => item.equals(KnownPrivileges.usePtzPresetsPrivilege));
        this.allowEditPtzPreset = priv.some((item) => item.equals(KnownPrivileges.editPtzPresetPrivilege));
        this.allowTogglePtzMenu = priv.some((item) => item.equals(KnownPrivileges.togglePtzMenuPrivilege));
        this.allowSetPtzAuxiliaries = priv.some((item) => item.equals(KnownPrivileges.setPtzAuxiliariesPrivilege));
        this.allowUsePtzAuxiliaries = priv.some((item) => item.equals(KnownPrivileges.usePtzAuxiliariesPrivilege));
        this.allowPatternsUse = priv.some((item) => item.equals(KnownPrivileges.usePtzPatternsPrivilege));
        this.allowLockPtz = priv.some((item) => item.equals(KnownPrivileges.lockPtzPrivilege));
    }

    public hasAtLeastOnePtzPrivilege(): boolean {
        return (
            this.allowBasicPtz ||
            this.allowPtzLockOverride ||
            this.allowSpecificCommands ||
            this.allowChangeFocusAndIris ||
            this.allowPresetsUse ||
            this.allowEditPtzPreset ||
            this.allowTogglePtzMenu ||
            this.allowSetPtzAuxiliaries ||
            this.allowUsePtzAuxiliaries ||
            this.allowPatternsUse ||
            this.allowLockPtz
        );
    }
}
