import { FinalizeCallbackSubject } from '@modules/shared/subjects/finalize-callback-subject';
import { IEventBase } from 'RestClient/Client/Interface/IEventBase';
import { Observable, Subject } from 'rxjs';
import { IGuid } from 'safeguid';

export class EntityEventObserver<T extends IEventBase> {
    private entitySubjects = new Map<string, FinalizeCallbackSubject<T[]>>();

    public complete(): void {
        this.entitySubjects.forEach((observer) => observer.complete());
    }

    public setObservableFor(entityId: IGuid, finalizeCallback: () => void): Observable<T[]> {
        return this.setSubject(entityId, finalizeCallback);
    }

    public getObservableFor(entityId: IGuid): Observable<T[]> | undefined {
        return this.getSubject(entityId)?.asObservable();
    }

    public publishEvents(entityId: IGuid, events: T[]): void {
        this.getSubject(entityId)?.next(events);
    }

    private getSubject(entityId: IGuid): Subject<T[]> | undefined {
        return this.entitySubjects.get(entityId.toString());
    }

    private setSubject(entityId: IGuid, callbackWhenNoMoreObservers: () => void): Observable<T[]> {
        const newSubject = new FinalizeCallbackSubject<T[]>(() => {
            this.deleteSubject(entityId);
            callbackWhenNoMoreObservers();
        });
        this.entitySubjects.set(entityId.toString(), newSubject);
        return newSubject.asObservable();
    }

    private deleteSubject(entityId: IGuid): void {
        if (this.getSubject(entityId)) {
            this.entitySubjects.delete(entityId.toString());
        }
    }
}
