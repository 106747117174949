<div class="gen-combobox {{ genFlavor }}" [attr.disabled]="disabled || null">
    <label class="gen-label" *ngIf="genLabel">
        {{ genLabel }}
        <span *ngIf="genLabelComplement"> ({{ genLabelComplement }})</span>
    </label>
    <div
        #comboElement
        class="gen-combobox-input focus-style-override"
        [ngClass]="{ 'inline-block': genSuffix }"
        genKeyboardNavigation
        [genAutomaticNavigation]="true"
        (genEnter)="toggleComboOpen()"
        (click)="toggleComboOpen()"
        (genBackspace)="clearSelection()"
        (keydown.backspace)="clearSelection()"
        tabindex="0"
    >
        <!-- If no item selected, display a placeholder -->
        <div class="gen-combobox-input-text fg-color-primary-darker" *ngIf="!displayText">
            {{ genPlaceholder }}
        </div>
        <!-- Selected item -->
        <div class="gen-combobox-input-text" *ngIf="displayText">
            <app-custom-icon *ngIf="icon" slot="gen-icon" [icon]="icon" [entityId]="entityId" [customIconId]="entityCustomId" class="mr-1"></app-custom-icon>
            <span>{{ displayText }}</span>
        </div>
        <!-- Combo caret -->
        <div class="gen-combobox-input-caret">
            <i class="gen-ico-angle-down"></i>
        </div>
    </div>
    <gen-popup [position]="PopupPosition.BottomLeft" [fitTargetWidth]="true" [targetElement]="comboElement" (genEscape)="toggleComboOpen()" appFocusPopupWhenOpen>
        <div class="p-0">
            <div class="entity-browser-wrapper">
                <app-entity-browser
                    #entityBrowser
                    [autoRefresh]="false"
                    [filter]="entityBrowserFilter"
                    [canSelectItemFunction]="entityBrowserCanSelectItemFunction"
                    [areItemsCheckable]="multiSelect"
                    (selectedEntitiesChanged)="onEntitySelected($event)"
                    (checkedEntitiesChanged)="onEntityChecksChanged($event)"
                >
                    <ng-container *ngIf="showClearButton" ngProjectAs="additionalAction">
                        <gen-button (click)="clearSelection()" genStopPropagation [flavor]="ButtonFlavor.Flat">
                            {{ 'STE_BUTTON_CLEAR' | translate }}
                        </gen-button>
                    </ng-container>
                </app-entity-browser>
            </div>
        </div>
    </gen-popup>
</div>
