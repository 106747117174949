import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { ButtonFlavor, Color } from '@genetec/gelato-angular';
import { StateObservable } from '@src/app/store/decorators/state-observable.decorator';
import { CardholderEditContextState } from '@modules/access-control/cardholder-edit-context.state';
import { Select } from '@ngxs/store';
import { CardholderEditService, CardholderRelationType } from '@modules/access-control/services/cardholder-edit-service';
import { Observable } from 'rxjs';
import { IGuid } from 'safeguid';
import { EntityBrowserRefreshTypes } from '@modules/access-control/entity-browser/enumerations/entity-browser-refresher-types';
import { SharedEntityBrowserRefreshTypes } from '@shared/entity-browser/enumerations/shared-entity-browser-refresher-types';
import { CardholderEditFormGroup } from '../cardholder-edit-form-group';

@Component({
    selector: 'app-cardholder-access-rights-form',
    templateUrl: './cardholder-access-rights-form.component.html',
    styleUrls: ['./cardholder-access-rights-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardholderAccessRightsFormComponent {
    @Input() formGroup?: CardholderEditFormGroup['controls']['accessRightsFormGroup'];
    @Input() formReset$?: Observable<IGuid | undefined>;
    @Input() readonly = false;

    @Select(CardholderEditContextState.cardholderId)
    public cardholderId$!: StateObservable<typeof CardholderEditContextState.cardholderId>;

    @Select(CardholderEditContextState.isPrivilegeGranted('modifyCardholderGroupsPrivilege'))
    public hasModifyCardholderGroupsPrivilege$!: StateObservable<ReturnType<typeof CardholderEditContextState.isPrivilegeGranted>>;

    @Select(CardholderEditContextState.isPrivilegeGranted('modifyCredentialAssociationToCardholderPrivilege'))
    public hasModifyCredentialPrivilege$!: StateObservable<ReturnType<typeof CardholderEditContextState.isPrivilegeGranted>>;

    @Select(CardholderEditContextState.isPrivilegeGranted('modifyAccessRulesPrivilege'))
    public hasModifyAccessRulesPrivilege$!: StateObservable<ReturnType<typeof CardholderEditContextState.isPrivilegeGranted>>;

    public readonly Colors = Color;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly CardholderRelationType = CardholderRelationType;
    public readonly Flavor = TextFlavor;

    public readonly EntityBrowserRefreshTypes = EntityBrowserRefreshTypes;
    public readonly SharedEntityBrowserRefreshTypes = SharedEntityBrowserRefreshTypes;
    public readonly excludedCardholdersGroupIds = CardholderEditService.excludedCardholdersGroupIds;
    public readonly excludedAccessRulesIds = CardholderEditService.excludedAccessRulesIds;
}
