import { CardholderModel } from '@modules/access-control/cardholder-edit-context.state';
import {
    ActivationModeLabel,
    ActiveExpirationModeLabel,
    BypassAntipassbackRules,
    InactiveExpirationModeLabel,
    stringToExpirationModeKey,
} from '@modules/access-control/enumerations';
import { isValidDate } from '@src/app/utilities/date-validator';
import moment from 'moment';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { ExpirationMode } from 'RestClient/Client/Enumerations/ExpirationMode';

export class CardholderHelper {
    public static getExpirationModeLabel = (cardholderModel: CardholderModel): ActiveExpirationModeLabel | InactiveExpirationModeLabel => {
        let expirationModeLabel: ActiveExpirationModeLabel | InactiveExpirationModeLabel;
        if (cardholderModel.accessStatus === AccessStatus.Inactive) {
            expirationModeLabel =
                (Object.entries(InactiveExpirationModeLabel).find(([key]) => key.toLowerCase() === cardholderModel.expirationMode.toLowerCase())?.[1] as
                    | InactiveExpirationModeLabel
                    | undefined) ?? InactiveExpirationModeLabel.DontExpire;
        } else {
            expirationModeLabel =
                (Object.entries(ActiveExpirationModeLabel).find(([key]) => key.toLowerCase() === cardholderModel.expirationMode.toLowerCase())?.[1] as
                    | ActiveExpirationModeLabel
                    | undefined) ?? ActiveExpirationModeLabel.DontExpire;
        }
        if (expirationModeLabel === InactiveExpirationModeLabel.ActivateOn && !isValidDate(cardholderModel.expirationDate)) {
            expirationModeLabel = InactiveExpirationModeLabel.DontExpire;
        }

        return expirationModeLabel;
    };

    public static getActivationModeLabel = (cardholder: CardholderModel): ActivationModeLabel => {
        if (cardholder.accessStatus === AccessStatus.Expired && cardholder.expirationMode !== ExpirationMode.ActivateOn) {
            return ActivationModeLabel.Never;
        }
        const momentDate = moment(cardholder.activationDate);
        return momentDate.isValid() && moment(momentDate).isAfter(moment(0)) ? ActivationModeLabel.ActivateOn : ActivationModeLabel.Never;
    };

    public static getBypassAntipassbackRule = (cardholder: CardholderModel): BypassAntipassbackRules => {
        if (cardholder.antipassbackExemptionIsInherited) {
            return BypassAntipassbackRules.Inherited;
        }
        return cardholder.antipassbackExemption ? BypassAntipassbackRules.Yes : BypassAntipassbackRules.No;
    };

    public static buildExpirationMode(
        accessStatus: string | undefined,
        activationMode: string | undefined,
        expirationModeValue: ActiveExpirationModeLabel | InactiveExpirationModeLabel | undefined
    ): string {
        return (accessStatus === AccessStatus.Inactive || accessStatus === AccessStatus.Expired) && activationMode === ActivationModeLabel.ActivateOn
            ? ExpirationMode.ActivateOn
            : stringToExpirationModeKey(expirationModeValue, accessStatus ?? '');
    }

    public static isEntityNameDefaultValue(cardholderModel: CardholderModel): boolean {
        return cardholderModel.name === CardholderHelper.buildEntityName(cardholderModel);
    }

    public static buildEntityName(cardholderModel: CardholderModel): string {
        return `${cardholderModel.firstName} ${cardholderModel.lastName}`.trim();
    }
}
