<div [className]="mainClass">
    <div *ngFor="let pluginItem of pluginItems" [className]="pluginsHostClassName">
        <app-plugin-host
            [pluginItem]="pluginItem"
            [className]="pluginHostClassName + ' focus-style-override'"
            [style.width.px]="maxWidth"
            [style.heigth.px]="maxHeigth"
            [dataContext]="dataContext"
            (pluginClick)="onClicked($event)"
            appClickActionOnSpaceEnter
        ></app-plugin-host>
    </div>
</div>
