import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonFlavor, Icon } from '@genetec/gelato';
import { GenModalService } from '@genetec/gelato-angular';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { ThreatLevelInfo } from '@modules/shared/api/api';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ThreatLevelService } from '@modules/shared/services/threat-level/threat-level.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { TranslateService } from '@ngx-translate/core';
import { ThreatLevelModalComponent } from '../threat-levels-modal/threat-level-modal.component';
import { TrackedComponent } from '../tracked/tracked.component';
import { KnownLicenses } from 'WebClient/KnownLicenses';

@UntilDestroy()
@Component({
    selector: 'app-threat-levels-tray',
    templateUrl: './threat-levels-tray.component.html',
    styleUrls: ['./threat-levels-tray.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@InternalPluginDescriptor({
    type: ThreatLevelsTrayComponent,
    pluginTypes: [PluginTypes.Tray],
    exposure: { id: ThreatLevelsTrayComponent.pluginId },
    isSupported: () => true,
    requirements: {
        enabledFeatureFlags: [GeneralFeatureFlags.ThreatLevels],
        optionalGlobalPrivileges: [KnownPrivileges.setThreatLevelPrivilege],
        licenses: [KnownLicenses.threatLevels],
    },
})
export class ThreatLevelsTrayComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('2236e53a-37c5-4a62-aeb3-9c76d15370ac');

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;

    public hasSetThreatLevelPrivilege = false;

    public isThereAnActiveThreatLevel$: Observable<boolean>;
    public activeThreatLevelName$: Observable<string>;
    public threatLevelColor$: Observable<string | null>;
    public trayTooltip$: Observable<string>;

    private activeThreatLevel$: Observable<ThreatLevelInfo | null>;

    constructor(
        trackingService: TrackingService,
        private threatLevelService: ThreatLevelService,
        private modalService: GenModalService,
        private securityCenterClientService: SecurityCenterClientService,
        private translateService: TranslateService
    ) {
        super(trackingService);
        this.activeThreatLevel$ = this.threatLevelService.activeThreatLevel$.pipe(shareReplay(1));
        this.isThereAnActiveThreatLevel$ = this.activeThreatLevel$.pipe(map((threatLevel: ThreatLevelInfo | null): boolean => threatLevel !== null));
        this.activeThreatLevelName$ = this.activeThreatLevel$.pipe(
            map((threatLevel: ThreatLevelInfo | null): string => {
                return threatLevel?.name ?? '';
            })
        );
        this.threatLevelColor$ = this.activeThreatLevel$.pipe(
            map((threatLevel: ThreatLevelInfo | null): string | null => {
                return threatLevel?.color ?? null;
            })
        );
        this.trayTooltip$ = this.activeThreatLevelName$.pipe(
            map((name: string) => (this.hasSetThreatLevelPrivilege ? name : (this.translateService.instant('STE_TOOLTIP_SETTHREATLEVEL_INSUFFICIENT_PRIVILEGES') as string)))
        );
    }

    ngOnInit(): void {
        this.threatLevelService.threatLevels$.pipe(take(1), untilDestroyed(this)).subscribe();
        this.hasSetThreatLevelPrivilege = this.securityCenterClientService.scClient.isGlobalPrivilegeGranted(KnownPrivileges.setThreatLevelPrivilege);
    }

    public openThreatLevelsModal(): void {
        if (!this.hasSetThreatLevelPrivilege) {
            return;
        }
        this.modalService.open(ThreatLevelModalComponent, {});
    }
}
