import { Radian } from './Radian';
import { ClipSpaceVec2 } from './Vec2';

export class Vec3 {
  public static readonly Zero: Vec3 = new Vec3(0, 0, 0);

  private readonly m_x: number;
  private readonly m_y: number;
  private readonly m_z: number;

  public get X(): number {
    return this.m_x;
  }

  public get Y(): number {
    return this.m_y;
  }

  public get Z(): number {
    return this.m_z;
  }

  constructor(x: number, y: number, z: number) {
    this.m_x = x;
    this.m_y = y;
    this.m_z = z;
  }

  public toString(): string {
    return `(${this.m_x.toFixed(3)}, ${this.m_y.toFixed(3)}, ${this.m_z.toFixed(3)})`;
  }

  public static build(value: number): Vec3 {
    return new Vec3(value, value, value);
  }

  public plus(right: Vec3): Vec3 {
    return new Vec3(this.m_x + right.m_x, this.m_y + right.m_y, this.m_z + right.m_z);
  }

  public minus(right: Vec3): Vec3 {
    return new Vec3(this.m_x - right.m_x, this.m_y - right.m_y, this.m_z - right.m_z);
  }

  public multiply(right: Vec3): Vec3 {
    return new Vec3(this.m_x * right.m_x, this.m_y * right.m_y, this.m_z * right.m_z);
  }

  public plusC(right: number): Vec3 {
    return this.plus(Vec3.build(right));
  }

  public multiplyC(right: number): Vec3 {
    return this.multiply(Vec3.build(right));
  }

  public dropZ(): ClipSpaceVec2 {
    return new ClipSpaceVec2(this.m_x, this.m_y);
  }

  //Angle vs Z axis
  public get PolarBeta(): Radian {
    return new Radian(Math.atan2(Math.sqrt(this.m_x * this.m_x + this.m_y * this.m_y), this.m_z));
  }

  //Angle on the X-Y plane
  public get PolarDelta(): Radian {
    return new Radian(Math.atan2(this.m_y, this.m_x));
  }
}
