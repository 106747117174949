export class TimeSpan {
  public static readonly Zero: TimeSpan = new TimeSpan(0);

  private readonly m_milliseconds: number;

  public get InMs(): number {
    return this.m_milliseconds;
  }

  public static fromMilliseconds(milliseconds: number): TimeSpan {
    return new TimeSpan(milliseconds);
  }

  public static fromSeconds(seconds: number): TimeSpan {
    return new TimeSpan(seconds * 1000);
  }

  public static fromHours(hours: number): TimeSpan {
    return new TimeSpan(hours * 60 * 60 * 1000);
  }

  public static fromNowUntil(then: Date): TimeSpan {
    return TimeSpan.between(new Date(), then);
  }

  public constructor(milliseconds: number) {
    this.m_milliseconds = Math.trunc(milliseconds);
  }

  public toString(): string {
    if (this.m_milliseconds < 1000) {
      return `${this.m_milliseconds} ms`;
    } else if (this.m_milliseconds < 60 * 1000) {
      return `${(this.m_milliseconds / 1000).toFixed(3)} sec`;
    } else if (this.m_milliseconds < 60 * 60 * 1000) {
      return `${(this.m_milliseconds / (60 * 1000)).toFixed(2)} min`;
    } else if (this.m_milliseconds < 24 * 60 * 60 * 1000) {
      return `${(this.m_milliseconds / (60 * 60 * 1000)).toFixed(2)} hr`;
    }

    return `${(this.m_milliseconds / (24 * 60 * 60 * 1000)).toFixed(2)} days`;
  }

  public isGreaterThan(other: TimeSpan): boolean {
    return this.m_milliseconds > other.m_milliseconds;
  }

  public isLesserThan(other: TimeSpan): boolean {
    return this.m_milliseconds < other.m_milliseconds;
  }

  public divideBy(divider: number): TimeSpan {
    if (divider === 0) {
      throw new Error('Cannot divide by zero.');
    }

    return new TimeSpan(this.m_milliseconds / divider);
  }

  public static between(start: Date, end: Date): TimeSpan {
    return new TimeSpan(end.getTime() - start.getTime());
  }
}
