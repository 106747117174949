export enum LogonState {
  LoggedOn,
  LoggedOff,
  ReloggingOn,
  ReloggedOn,
}

export class LogonStateChangedArgs {
  public state: LogonState;

  constructor(state: LogonState) {
    this.state = state;
  }

  public loggedOn(): boolean {
    return this.state === LogonState.LoggedOn || this.state === LogonState.ReloggedOn;
  }
}
