<div *ngIf="!tilesView" class="content-list">
    <gen-search-input
        *ngIf="dataTypesListItems.length > 15"
        [genDelay]="100"
        placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
        [ngModel]="searchText"
        (ngModelChange)="onSearchChange($event)"
    >
    </gen-search-input>
    <!-- DataTypes as List, used in toolbar -->
    <ng-container *ngFor="let item of visibleDataTypes">
        <div attr.data-cy="{{item.id}}">
            <app-image-name-toggle-button class="w-100" [item]="item" (isCheckedChanged)="onDataTypeChanged(item)" > </app-image-name-toggle-button>
        </div>
    </ng-container>
</div>

<div *ngIf="tilesView" class="content-tiles">
    <!-- DataTypes as Cards, used in splash screen-->
    <div class="content-tiles__items-container">
        <div *ngFor="let item of dataTypeDisplayModel" class="content-tiles__items-container__card-container pr-2 pb-2">
            <gen-card
                appClickActionOnSpaceEnter
                tabindex="0"
                [style.cursor]="'pointer'"
                (click)="onReportSelectedFromCard(item)"
                class="content-tiles__items-container__card-container__card"
                attr.data-cy="{{item.dataType}}"
            >
                <div slot="gen-card-body" class="content-tiles__items-container__card-container__card__body p-1">
                    <!-- image (if available) -->
                    <gen-image *ngIf="item?.customIcon" [src]="item?.customIcon" class="ha-center" [fit]="ImageFit.Contain" [flavor]="ImageFlavor.Rectangle"> </gen-image>
                    <!-- icon (if no image available) -->
                    <gen-icon *ngIf="!item?.customIcon" [icon]="item.icon ? item.icon : Icon.CorrelationService"></gen-icon>
                </div>
                <div slot="gen-card-footer" class="content-tiles__items-container__card-container__card__footer p-1">
                    <gen-text [flavor]="TextFlavor.Normal">
                        <span appTooltip="{{ item.name }}" [appTooltipOnlyIfOverflow]="true">{{ item.name }}</span>
                    </gen-text>
                </div>
            </gen-card>
        </div>
    </div>
</div>
<div [hidden]="!isLoading" class="spinner-overlay h-100 v-100">
    <!-- Querying spinner overlay on results container -->
    <gen-spinner [size]="SpinnerSize.Large"></gen-spinner>
</div>
