// Autogenerated: v2/help/Entities/ApiLocatable?codegen=ts&InterfaceOnly=True&workflow=False on 2020-07-23 3:40:12 PM
import { IEntity, EntityFields } from './IEntity';

// #region Fields

export class LocatableEntityFields extends EntityFields {
    public static isPredefinedLocationField = 'IsPredefinedLocation';
    public static latitudeField = 'Latitude';
    public static longitudeField = 'Longitude';
    public static timeZoneIdField = 'TimeZoneId';
}

// #endregion Fields

export interface ILocatableEntity extends IEntity {
    // #region REST Properties

    readonly isPredefinedLocation: boolean;

    latitude: number;

    longitude: number;

    timeZoneId: string;

    // #endregion REST Properties

}

