/* eslint-disable @typescript-eslint/member-ordering */
import { Inject, Injectable } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { IEventDefinition } from '@modules/shared/api/api';
import { TreeItem } from '@modules/shared/interfaces/tree-item/tree-item';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { naturalSort } from '@modules/shared/utilities/natural-sort';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventTypeData } from '@modules/shared/services/events/event-type-data';
import { EventsSettingsService } from '@modules/shared/services/events/events-settings.service';

/**
 * @description
 * This service is responsible for tracking two set of data at the same time:
 * 1. Events source from the regular tree view
 * 2. Events source from the filtered tree view.
 *
 * We need two separate entities because otherwise the search functionnality would overwrite the whole state whenever a new
 * search criterion were provided.
 *
 * This service is provided in the EventsOptionComponent.
 * @requires initService() to have been called prior to usage
 * @limitations this service isn't tested for recursive data. i.e: Children containing treeItem that also contains children.
 */
@UntilDestroy()
@Injectable()
export class EventsOptionTreeService {
    // Synchronization observable

    // regular view treeItems observable
    private eventsSubject = new BehaviorSubject<TreeItem[]>([]);
    public events$ = this.eventsSubject.asObservable();

    // filtered view treeItems observable
    public filteredEventsSubject = new BehaviorSubject<TreeItem[]>([]);
    public filteredEvents$ = this.filteredEventsSubject.asObservable();

    constructor(private translateService: TranslateService, private eventsSettingsService: EventsSettingsService) {}

    /**
     * This method has to be called before anything else can be done with this service.
     *
     * @param eventTypes eventTypes retrieved from storage
     * @param eventDefinitions eventDefinitions returned from the backend
     */
    public initService(eventTypes: EventTypeData[], eventDefinitions: IEventDefinition[]): void {
        if (eventDefinitions.length === 0) {
            throw new Error('Please provide non empty arrays when initializing the EventsOptionTreeService');
        }
        const events = this.getEventsFromDefinitions(eventTypes, eventDefinitions);
        this.eventsSubject.next(events);
    }

    /**
     * Save a flattened array containing all the checked elements.
     * Push selected items to the pending setting
     *
     * @param selectedEvents array containing all the tree elements.
     */
    public saveListeningEventSelection(selectedEvents: TreeItem[]): void {
        // make selected events a flat array
        const flattened: TreeItem[] = this.flattenAllChildren(selectedEvents).filter((val: TreeItem) => {
            return val.isChecked === true;
        });

        const ids = flattened.map((item) => this.idToEventType(item.id ?? ''));
        this.eventsSettingsService.setListeningEventsSettings(ids);
    }

    /**
     * EventTypes and EventDefinitions both have to be set prior.
     * Populates the tree by category, retrieves previous state from localstorage, and applies selected/unselected state
     * to each leaf in the tree.
     *
     * @returns the array containing the tree hierarchy and its state.
     */
    private getEventsFromDefinitions(eventTypes: EventTypeData[], eventDefinitions: IEventDefinition[]): TreeItem[] {
        if (eventDefinitions) {
            const treeItems: TreeItem[] = [];
            const treeItemsSelected: TreeItem[] = [];
            eventDefinitions.forEach((eventDefinition) => {
                if (!eventDefinition.listenable) {
                    return;
                }

                if (eventDefinition.category) {
                    let category = treeItems.find((treeItem) => treeItem.id === eventDefinition.category);
                    if (!category) {
                        category = { id: eventDefinition.category, text: this.translateService.instant(eventDefinition.category) as string, children: [] };
                    }

                    if (category && (eventDefinition.name !== '' || eventDefinition.rawName !== '')) {
                        const eventTypeId = this.eventTypeToId(eventDefinition.eventType, eventDefinition.eventSubType);
                        const icon = eventDefinition.icon as MeltedIcon;
                        const selected = eventTypes
                            ? eventTypes.some((event) => event.type === eventDefinition.eventType && event.subType === eventDefinition.eventSubType)
                            : eventDefinition.listenByDefault;
                        const name = eventDefinition.name ? (this.translateService.instant(eventDefinition.name) as string) : eventDefinition.rawName;
                        const item = { id: eventTypeId, text: name, icon, isChecked: selected };
                        // Insert item as child in current category.
                        category.children?.push(item);
                        if (selected) {
                            treeItemsSelected.push(item);
                        }
                        // insert category in tree items list, only if not already present.
                        if (!treeItems.find((treeItem) => treeItem.id === eventDefinition.category)) {
                            treeItems.push(category);
                        }
                    }
                }
            });

            const sortedTreeItems = this.sortTreeItems(treeItems);
            return sortedTreeItems ?? [];
        }
        return [];
    }

    /**
     * Use to synchronize two treeItem arrays. This will only synchronize the "isChecked" property.
     *
     * @param eventsToMerge array to merge with our inner state.
     */
    public mergeEvents(eventsToMerge: TreeItem[]): void {
        const flatEventsArray = this.flattenAllChildren(eventsToMerge);

        this.events$.pipe(take(1), untilDestroyed(this)).subscribe((eventsCategory: TreeItem[]) => {
            flatEventsArray.forEach((event: TreeItem) => {
                eventsCategory?.forEach((category: TreeItem) => {
                    const found = category.children?.find((child) => child.id === event.id);
                    if (found) {
                        // alter original array with new value.
                        found.isChecked = event.isChecked;
                    }
                });
            });
            this.eventsSubject.next(eventsCategory);
            // Make sure to synchronise new selection.
            this.saveListeningEventSelection(eventsCategory);
        });
    }

    /**
     * flatten one level of children in a TreeItem array.
     *
     * @param array flattened array.
     */
    public flattenAllChildren(array: TreeItem[]): TreeItem[] {
        return array.reduce((a: TreeItem[], b) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return a.concat(b.children as TreeItem[]);
        }, []);
    }

    /**
     * Applies search criterions and build a new TreeItem[] that will be used for the filtered tree view.
     */
    public doSearch(criteria: string): void {
        this.events$.pipe(take(1), untilDestroyed(this)).subscribe((events: TreeItem[]) => {
            // make a "deep" copy to avoid side effects.
            const eventsCopy: TreeItem[] = JSON.parse(JSON.stringify(events)) as TreeItem[];

            // do the actual filtering
            const filteredEvents = this.filterEvents(criteria, eventsCopy);

            // push new filtered results.
            this.filteredEventsSubject.next(filteredEvents);
        });
    }

    /**
     * Transforms an eventType object to an id string.
     *
     * @param type object type you want to convert
     * @param subType object subtype you want to convert
     * @returns a string in the type?SubType format.
     */
    public eventTypeToId(type: string, subType: number): string {
        return `${type}?SubType=${subType}`;
    }

    /**
     * Converts the type?SubType format back to its object form.
     *
     * @param id type?SubType string format
     * @returns the object in its EventType form.
     */
    public idToEventType(id: string): EventTypeData {
        const splittedString = id.split('?SubType=');
        return new EventTypeData(splittedString[0], Number(splittedString[1]));
    }

    /**
     * Uses natural sort over an array of TreeItem
     *
     * @param items items want to sort
     * @returns the sorted array.
     */
    private sortTreeItems(items?: TreeItem[]): TreeItem[] | undefined {
        if (items) {
            for (const item of items) {
                if (!item.isChecked) {
                    item.isChecked = false;
                }
                item.children = this.sortTreeItems(item.children);
            }

            return items.sort((item1, item2) => naturalSort(item1.text ?? '', item2.text ?? ''));
        }
        return undefined;
    }

    /**
     * Uses the criteria string and filters out anything that doesn't match the criteria.
     *
     * @param criteria string to search for in the TreeItem array.
     * @param allEvents Array representing the Tree view
     * @returns an array that contains all the elements that corresponds to the criteria
     */
    private filterEvents(criteria: string, allEvents: TreeItem[]): TreeItem[] {
        const parentsToRemove: TreeItem[] = [];
        if (criteria) {
            for (const event of allEvents) {
                const toRemove: TreeItem[] = [];
                event.children?.forEach((child: TreeItem) => {
                    if (child?.text && !child.text.toLowerCase().includes(criteria.toLowerCase())) {
                        toRemove.push(child);
                    }
                });
                toRemove.forEach((itemRemove) => event.children?.remove(itemRemove));
                if (event.children?.length === 0) {
                    parentsToRemove.push(event);
                } else {
                    event.isExpanded = true;
                }
            }
            parentsToRemove.forEach((parent) => allEvents?.remove(parent));
        }
        return allEvents;
    }
}
