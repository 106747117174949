import { Utils } from './Utils';
import { version } from '../gwp';

// Display banner each time this library is loaded
(function() {
  // For tests, version is not defined
  if (typeof version === 'function') {
    const v = version() === '[VERSION]' ? 'DEV' : version();
    console.info(`🐹 Genetec Web Player™ version ${v} loaded`);
  }
})();

export interface ILogger {
  intense: ITracer | null;
  debug: ITracer | null;
  info: ITracer | null;
  warn: ITracer | null;
  error: ITracer | null;
  subLogger(prefix: string): ILogger;
}

export interface ITracer {
  trace(...args: any[]): void;
}

export class Logger implements ILogger {
  private static m_showDebug: boolean = false;
  private static m_showIntense: boolean = false;
  private static m_showInfo: boolean = false;
  private static m_showTimestamps: boolean = true;

  private readonly m_id: number;

  private readonly m_debugTracer: ITracer;
  private readonly m_infoTracer: ITracer;
  private readonly m_warnTracer: ITracer;
  private readonly m_errorTracer: ITracer;

  public static get timestampsEnabled(): boolean {
    return this.m_showTimestamps;
  }

  public constructor(id: number, name: string) {
    this.m_id = id;

    this.m_debugTracer = new DebugTracer(id, name);
    this.m_infoTracer = new InfoTracer(id, name);
    this.m_warnTracer = new WarnTracer(id, name);
    this.m_errorTracer = new ErrorTracer(id, name);
  }

  public static enableLogs(intense?: boolean) {
    Logger.m_showInfo = true;
    Logger.m_showDebug = true;
    Logger.m_showIntense = intense === true;
  }

  public static enableTimestamps(timestamps: boolean) {
    Logger.m_showTimestamps = timestamps;
  }

  public static disableLogs() {
    Logger.m_showInfo = false;
    Logger.m_showDebug = false;
    Logger.m_showIntense = false;
  }

  public get intense(): ITracer | null{
    if (Logger.m_showIntense) return this.m_debugTracer;
    return null;
  }

  public get debug(): ITracer | null {
    if (Logger.m_showDebug) return this.m_debugTracer;
    return null;
  }

  public get info(): ITracer | null {
    if (Logger.m_showInfo) return this.m_infoTracer;
    return null;
  }

  public get warn(): ITracer {
    return this.m_warnTracer;
  }

  public get error(): ITracer {
    return this.m_errorTracer;
  }

  public subLogger(name: string): ILogger {
    return new Logger(this.m_id, name);
  }
}

export abstract class Tracer implements ITracer {
  private readonly m_id: number;

  private readonly m_name: string;

  public constructor(id: number, name: string) {
    this.m_id = id;
    this.m_name = name;
  }

  public abstract trace(...args: any[]): void

  protected get prefix(): string {
    if (Logger.timestampsEnabled) {
      const now = new Date();
      return `🐹 ${this.m_id} ${Utils.pad(now.getMinutes(), 2)}:${Utils.pad(now.getSeconds(), 2)}.${Utils.pad(now.getMilliseconds(), 3)} ${this.m_name}:`;
    } else {
      return `🐹 ${this.m_id} ${this.m_name}:`;
    }
  }
}

export class DebugTracer extends Tracer {
  public trace(...args: any[]): void {
    console.log(this.prefix, ...args);
  }
}

export class InfoTracer extends Tracer {
  public trace(...args: any[]): void {
    console.info(this.prefix, ...args);
  }
}

export class WarnTracer extends Tracer {
  public trace(...args: any[]): void {
    console.warn(this.prefix, ...args);
  }
}

export class ErrorTracer extends Tracer {
  public trace(...args: any[]): void {
    console.error(this.prefix, ...args);
  }
}
