import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW } from '@utilities/common-helper';
import Dexie from 'dexie';
import { BehaviorSubject, Subject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LoggerService } from '../logger/logger.service';
import { IDBStringDataObject } from './interfaces';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class IDBService extends Dexie {
    private static readonly DBVersion = 1; // IMPORTANT: Change this when changing the structure of the database!
    private static readonly DBName = 'GenWebApp';
    private static readonly FilesStore = 'files';
    private static readonly ResourcesStore = 'resources';

    public files: Dexie.Table<IDBStringDataObject, string> | null = null;
    public resources: Dexie.Table<IDBStringDataObject, string> | null = null;

    private canOpenDBSubject = new BehaviorSubject<boolean | null>(null);

    constructor(private loggerService: LoggerService, @Inject(WINDOW) private window: Window) {
        super(IDBService.DBName);

        this.updateCanOpenDB();

        this.canOpenDBSubject.pipe(untilDestroyed(this)).subscribe((canOpenDB: boolean | null) => {
            if (canOpenDB) {
                this.setupTables();
            }
        });
    }

    /**
     * Returns if IndexedDB is supported in the current browser.
     *
     * @returns If IndexedDB is supported or not
     */
    public isIDBSupported(): boolean {
        return !!this.window.indexedDB && this.canOpenDBSubject.value === true;
    }

    private setupTables() {
        this.version(IDBService.DBVersion).stores({
            [IDBService.FilesStore]: `id, lastAccess`,
            [IDBService.ResourcesStore]: `id, lastAccess`,
        });
        this.files = this.table(IDBService.FilesStore);
        this.resources = this.table(IDBService.ResourcesStore);
    }

    private updateCanOpenDB() {
        try {
            const newDB = this.window.indexedDB.open(IDBService.DBName);
            newDB.onsuccess = () => {
                this.canOpenDBSubject.next(true);
                this.loggerService.traceDebug('IDB supported.');
            };
            newDB.onerror = () => {
                // IndexedDB not supported in Firefox & Private Browsing
                // See https://bugzilla.mozilla.org/show_bug.cgi?id=1639542
                this.canOpenDBSubject.next(false);
                this.loggerService.traceDebug('IDB is not supported. Error trying to open a table');
                this.window.indexedDB.deleteDatabase(IDBService.DBName);
            };
        } catch {
            this.canOpenDBSubject.next(false);
            this.loggerService.traceDebug('IDB is not supported. Error trying to open a table');
        }
    }
}
