import { Radian } from '../utils/Radian';
import { ClipSpaceVec2 } from '../utils/Vec2';
import { Vec3 } from '../utils/Vec3';
import { ZoomFactor } from './ZoomFactor';

export class DewarpingPlane {
  private static readonly m_R = 1.0;

  private static readonly u = new ClipSpaceVec2(1, 0);
  private static readonly v = new ClipSpaceVec2(0, 1);

  private readonly m_toString: string;

  private readonly m_pPrimeXYZ: Vec3;
  private readonly m_uHat: Vec3;
  private readonly m_vHat: Vec3;

  private readonly m_equation: PlaneEquation;

  public get pPrime(): Vec3 {
    return this.m_pPrimeXYZ;
  }

  public get uHat(): Vec3 {
    return this.m_uHat;
  }

  public get vHat(): Vec3 {
    return this.m_vHat;
  }

  public get Equation(): PlaneEquation {
    return this.m_equation;
  }

  public constructor(warpedPoi: ClipSpaceVec2, zoom: ZoomFactor, cameraPositionRotation: Radian) {
    const poiPolar = warpedPoi.toPolar();
    const beta = new Radian(Math.asin(poiPolar.Radius / DewarpingPlane.m_R));
    const delta = poiPolar.Angle;

    const pPrimeX = delta.cos() * beta.sin();
    const pPrimeY = delta.sin() * beta.sin();
    const pPrimeZ = beta.cos();

    this.m_pPrimeXYZ = new Vec3(pPrimeX, pPrimeY, pPrimeZ).multiplyC(DewarpingPlane.m_R * zoom.Value);

    this.m_uHat = this.dewarpingPlaneToHemisphere(DewarpingPlane.u, cameraPositionRotation, beta, delta);
    this.m_vHat = this.dewarpingPlaneToHemisphere(DewarpingPlane.v, cameraPositionRotation, beta, delta);

    //pPrimeXYZ is the origin of our 2d plane so it is a point of it and it is also the normal of the plane
    this.m_equation = PlaneEquation.buildFromNormalAndPoint(this.m_pPrimeXYZ, this.m_pPrimeXYZ);

    this.m_toString = `Built from ${warpedPoi.toString()} zoom:${zoom.toString()} rotation: ${cameraPositionRotation.toString()}\r\npPrime:${this.m_pPrimeXYZ.toString()} û:${this.m_uHat.toString()} v̂:${this.m_vHat.toString()}\r\nbeta: ${beta.toString()} delta: ${delta.toString()}`;
  }

  public toString(): string {
    return this.m_toString;
  }

  public to3D(vec2: ClipSpaceVec2): Vec3 {
    return this.m_pPrimeXYZ.plus(this.m_uHat.multiplyC(vec2.X)).plus(this.m_vHat.multiplyC(vec2.Y));
  }

  public BackTo2D(vec3: Vec3): ClipSpaceVec2 {
    //We want to go back to the 2D dewarping plane, reverse the To3D method:
    //  (x, y) = m_pPrimeXYZ + (m_uHat * vec2.X) + (m_vHat * vec2.Y);
    // and find the value of vec2

    vec3 = vec3.minus(this.m_pPrimeXYZ);

    const a = this.m_uHat.X;
    const b = this.m_vHat.X;
    const c = vec3.X;
    const d = this.m_uHat.Y;
    const e = this.m_vHat.Y;
    const f = vec3.Y;

    const [x, y] = DewarpingPlane.twoEquationsTwoUnknownResolve(a, b, c, d, e, f);

    return new ClipSpaceVec2(x, y);
  }

  private dewarpingPlaneToHemisphere(v: ClipSpaceVec2, cameraPositionRotation: Radian, beta: Radian, delta: Radian): Vec3 {
    //Floating point errors when bouncing back and forth between degree/radian, sometimes,
    // something that should give exactly zero and becomes a very small number (e.g.1E-16)
    // those values instead of 0 breaks the algorithm
    const a = v.rotate(cameraPositionRotation).roundEspilonToZero();
    const b = new ClipSpaceVec2(a.X, 0).rotate(beta.opposite()).roundEspilonToZero();
    const c = new ClipSpaceVec2(b.X, a.Y).rotate(delta).roundEspilonToZero();
    return new Vec3(c.X, c.Y, b.Y);
  }

  // ax + by = c
  // dx + ey = f
  // This does not work as a general equation resolver, there are too many edge case of division by 0.
  // So for now, its stays as a private function here.
  // One could improve it and make it a general purpose 2 equations 2 unknown resolvers if he was to handle all those possible case.
  // -> b == 0 or d == 0 when a == 0
  // ->  a*e == d*b when a != 0
  private static twoEquationsTwoUnknownResolve(a: number, b: number, c: number, d: number, e: number, f: number): [number, number] {
    if (a === 0.0) {
      //The general algorithm would divide by zero. Change the approach:
      // ax + by = c
      // dx + ey = f
      //becomes
      // by = c, so y = c/b
      // And knowing y, we can do the second equation. Isolate 'x'
      // dx + ey = f
      // dx = f - ey
      // x = (f - ey)/d

      const y = c / b;
      const x = (f - e * y) / d;
      return [x, y];
    } else {
      //isolating x in first equation gives
      // x = (c - by)/a

      //Replacing x by [(c - by)/a] in second equation gives
      // (d((c - by)/a) + ey = f

      //isolating y in second equation gives
      //y = (af - dc)/(ae - db)
      const y = (a * f - d * c) / (a * e - d * b);
      const x = (c - b * y) / a;
      return [x, y];
    }
  }
}

export class PlaneEquation {
  public readonly A: number;
  public readonly B: number;
  public readonly C: number;
  public readonly D: number;

  //The plane equation can be found by using a normal vector n (perpendicular to the plane) and point p
  //nx(x - px) + ny(y - py) + nz(z - pz) = 0
  //nx(x) + ny(y) + nz(z) - (nx(px) + ny(py) + nz(pz)) = 0
  //The generalized plane equation is Ax + By + Cz = D
  //so
  //  A = nx
  //  B = ny
  //  C = nz
  //  D = -(nx(px) + ny(py) + nz(pz))
  public static buildFromNormalAndPoint(normal: Vec3, point: Vec3): PlaneEquation {
    const a = normal.X;
    const b = normal.Y;
    const c = normal.Z;
    const d = -(normal.X * point.X + normal.Y * point.Y + normal.Z * point.Z);
    return new PlaneEquation(a, b, c, d);
  }

  constructor(a: number, b: number, c: number, d: number) {
    this.A = a;
    this.B = b;
    this.C = c;
    this.D = d;
  }

  public toString(): string {
    return `${this.A}(x) +${this.B}(y) + ${this.C}(z) -${this.D} = 0`;
  }
}
