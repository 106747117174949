/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, ElementRef, Input, Optional } from '@angular/core';
import { FormControlName, NgControl } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LoggerService } from '@modules/shared/services/logger/logger.service';

type ElementThatSupportsValidity = HTMLButtonElement | HTMLFieldSetElement | HTMLInputElement | HTMLObjectElement | HTMLOutputElement | HTMLSelectElement | HTMLTextAreaElement;

// Add Gelato components that support :invalid pseudo elements in the directive's selector property and in getElementThatSupportsValidity methods.

/**
 * Directive that automatically syncs a form control's, bound to a Gelato component, validity state to the correct element down in the
 * Gelato component's shadow DOM (applies *:invalid pseudo* class so we get invalid style of the Gelato component when the form control is invalid).
 */
@UntilDestroy()
@Directive({
    selector: `
        gen-text-input[formControlName], gen-text-input[formControl], 
        gen-password-input[formControlName], gen-password-input[formControl], 
        [appGelatoInvalidityCompatControl]
    `,
    providers: [],
})
export class GelatoInvalidityCompatDirective implements AfterViewInit {
    @Input() appGelatoInvalidityCompatControl?: NgControl;
    element: ElementThatSupportsValidity | null = null;

    private initialized = false;
    private formControl?: NgControl;
    private nativeElement: HTMLElement;
    constructor(
        private host: ElementRef<HTMLElement>,
        private loggerService: LoggerService,
        @Optional() public ngControl: NgControl,
        @Optional() public formControlName: FormControlName
    ) {
        this.nativeElement = host.nativeElement;
        this.formControl = ngControl ?? formControlName;
    }

    ngAfterViewInit(): void {
        if (!this.formControl?.statusChanges && !this.appGelatoInvalidityCompatControl?.statusChanges) {
            this.loggerService.traceError(`Couldn't find control associated with Gelato component ${this.nativeElement.tagName.toLowerCase()}`);
            return;
        }

        (this.appGelatoInvalidityCompatControl ?? this.formControl)?.statusChanges?.pipe(untilDestroyed(this)).subscribe((newStatus) => {
            if (!this.initialized && this.nativeElement.classList.contains('hydrated')) {
                this.element = this.getElementThatSupportsValidity();
                this.initialized = true;
            }

            if (this.element) {
                if (newStatus === 'VALID') {
                    this.element.setCustomValidity('');
                } else {
                    // Set :invalid
                    this.element.setCustomValidity('error');
                }
            }
        });
    }

    private getElementThatSupportsValidity(): ElementThatSupportsValidity | null {
        switch (this.nativeElement.tagName.toLowerCase()) {
            case 'gen-text-input':
                // textarea is required when gen-text-imput is multiline
                return (
                    this.nativeElement.shadowRoot?.querySelector<HTMLInputElement>('input[type=text]') ??
                    this.nativeElement.shadowRoot?.querySelector<HTMLInputElement>('textarea') ??
                    null
                );
            case 'gen-password-input':
                return this.nativeElement.shadowRoot?.querySelector<HTMLInputElement>('input[type=password]') ?? null;
            // Add other Gelato components here...
        }

        return null;
    }
}
