import { TimeSpan } from './TimeSpan';

export class Timer {
  private readonly m_callback: () => void;
  private readonly m_frequency: TimeSpan;

  private m_timerId: number | undefined;

  constructor(callback: () => void, frequency: TimeSpan) {
    this.m_callback = callback;
    this.m_frequency = frequency;
  }

  public dispose(): void {
    this.stop();
  }

  public start() {
    if (this.m_timerId !== undefined) {
      return;
    }
    this.m_timerId = window.setInterval(this.m_callback, this.m_frequency.InMs);
  }

  public stop() {
    if (this.m_timerId !== undefined) {
      window.clearInterval(this.m_timerId);
      this.m_timerId = undefined;
    }
  }
}
