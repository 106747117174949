import { Inject, Injectable, NgZone } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { WINDOW } from '@src/app/utilities';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UserSettingsService } from '../../shared/services/user-settings/user-settings.service';
import { USER_SETTINGS_SERVICE } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionTypes } from '../../shared/enumerations/option-types';
import { LoggerService } from '../../shared/services/logger/logger.service';
import { JoystickLocalConfigIds } from '../components/options/joystick-options/joystick-options.component';
import { Point } from '../../shared/interfaces/drawing';
import { JoystickLocalConfig, JoystickService } from './joystick.service';

type GamepadEventType = 'Button' | 'Analogic';
export interface GamepadEventData {
    type: GamepadEventType;
    data: any;
}

export class ButtonGamepadEventData implements GamepadEventData {
    public type: GamepadEventType = 'Button';
    public data: { index: number; isButtonDown: boolean };

    constructor(index: number, isButtonDown: boolean) {
        this.data = {
            index,
            isButtonDown,
        };
    }
}

export class AnalogicGamepadEventData implements GamepadEventData {
    public type: GamepadEventType = 'Analogic';
    public data: { velocity: Point | null } = { velocity: null };

    constructor(velocity?: Point) {
        if (velocity) {
            this.data = { velocity };
        }
    }
}

/**
 * @copyright Copyright (C) 2022 by Genetec Inc. All rights reserved. May be used only in accordance with a valid Source Code License Agreement.
 * @description It's a service for connected gamepads that listen event on a specific gamepad that is set in the user settings.
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class GamepadEventListenerService {
    //#region getters

    public get onGamepadsConnectedChange$(): Observable<Gamepad[]> {
        return this.gamepadsConnectedSubject.asObservable();
    }

    public get onNewGamepadEvents$(): Observable<GamepadEventData[]> {
        return this.gamepadEventsSubject.asObservable();
    }

    public get gamepads(): Gamepad[] {
        return this.connectedGamepads;
    }

    //#endregion

    //#region Properties

    public isSupported = false;
    public deadZone;

    private readonly deadZoneDefaultValue = 0.05;
    private connectedGamepads: Gamepad[] = [];
    private currentGamepad: Gamepad | null = null;
    private oldGamepadSerialized = '';
    private buttonsStates: (boolean | undefined)[] = [];
    private pendingPanTilt = false;

    private gamepadEventsSubject: Subject<GamepadEventData[]> = new Subject();
    private gamepadsConnectedSubject: ReplaySubject<Gamepad[]> = new ReplaySubject<Gamepad[]>(1);

    // timer id for polling
    private pollingTimer: number | null = null;
    private enableLog = false;

    //#endregion

    constructor(
        private zone: NgZone,
        private loggerService: LoggerService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: UserSettingsService,
        @Inject(WINDOW) private window: Window
    ) {
        // determine if the browser supports gamepad

        this.isSupported = 'GamepadEvent' in this.window;
        this.deadZone = this.deadZoneDefaultValue;

        this.userSettingsService.settings$.pipe(untilDestroyed(this)).subscribe(() => {
            this.loadSettings();
        });

        this.userSettingsService.onCancel$.pipe(untilDestroyed(this)).subscribe(() => {
            // To make sure that we are listening events on the good global user joystick.
            this.loadSettings();
        });

        if (this.isSupported) {
            this.log('Gamepad API is supported');
            this.loadInitialData();
            this.loadSettings();
            this.window.addEventListener('gamepadconnected', (event) => this.onGamepadConnected(event));
            this.window.addEventListener('gamepaddisconnected', (event) => this.onGamepadDisconnected(event));
        } else {
            this.log('Gamepad API is not supported on browser');
        }
    }

    //#region Methods

    public getGamepadState(id: string): Gamepad | null {
        const navigatorGamepads = this.getNavigatorGamepads();
        for (const navigatorGamepad of navigatorGamepads) {
            //Check if it's the active joystick from the peripherals user settings
            const gp = navigatorGamepad;
            if (gp?.connected === true && gp.id === id) {
                return gp;
            }
        }
        return null;
    }

    public setCurrentGamepadId(id: string | null): void {
        this.currentGamepad = (id && this.connectedGamepads.find((gamepad) => gamepad.id === id)) || null;
        this.updateGamepadEventMonitoringState();
    }

    private getNavigatorGamepads(): (Gamepad | null)[] {
        const nav = this.window.navigator as Navigator | { webkitGetGamepads: () => Gamepad[] };
        let navigatorGamepads: (Gamepad | null)[] = [];

        if ('getGamepads' in nav) {
            navigatorGamepads = nav.getGamepads();
        } else if ('webkitGetGamepads' in nav) {
            navigatorGamepads = nav.webkitGetGamepads();
        }
        return navigatorGamepads;
    }

    private startPollingTimer() {
        if (!this.pollingTimer) {
            this.buttonsStates = [];
            this.zone.runOutsideAngular(() => {
                this.pollingTimer = this.window.setInterval(() => this.scanGamepadEvent(), JoystickService.pollingTime);
            });
        }
    }

    private stopPollingTimer() {
        if (this.pollingTimer) {
            clearInterval(this.pollingTimer);
            this.pollingTimer = null;
        }
    }

    // Loading the settings related to peripherals (ActiveJoystickId & settings that are associated to that joystick)
    private loadSettings() {
        const activeGamepadId = this.userSettingsService.get<string>(OptionTypes.Peripherals, JoystickLocalConfigIds.ActiveJoystick);
        // We want to load the joystick local settings only if the activeGamepadId is defined
        if (activeGamepadId) {
            const serialized = this.userSettingsService.get<string>(OptionTypes.Peripherals, JoystickLocalConfigIds.JoysticksConfigsList);
            if (serialized) {
                const joystickLocalSettings = JSON.parse(serialized) as JoystickLocalConfig[];
                const deadZone = joystickLocalSettings.find((joystickConfig) => joystickConfig.joystickId === activeGamepadId)?.deadZone;

                if (deadZone) {
                    this.deadZone = deadZone / 100;
                } else {
                    //Deadzone default value
                    this.deadZone = this.deadZoneDefaultValue;
                }
            }
        }

        this.updateGamepadEventMonitoringState(activeGamepadId);
    }

    private loadInitialData() {
        const navigatorGamepads = this.getNavigatorGamepads();
        let updateConnectedGamepads = false;

        for (const navigatorGamepad of navigatorGamepads) {
            //Check if it's the current joystick from the peripherals options settings
            const gp = navigatorGamepad;

            if (gp?.connected === true && !this.isGamepadConnected(gp.id)) {
                updateConnectedGamepads = true;
                this.connectedGamepads.push(gp);
            }
        }

        if (updateConnectedGamepads) {
            this.gamepadsConnectedSubject.next(this.connectedGamepads);
        }
    }

    private log(msg: string) {
        if (this.enableLog) {
            this.loggerService.traceInformation('JoystickService: ' + msg);
        }
    }

    private isGamepadConnected(id: string): boolean {
        return !!this.connectedGamepads.find((gamepad) => gamepad.id === id);
    }

    private updateGamepadEventMonitoringState(newActiveGamepadId?: string) {
        const activeGamepadId = newActiveGamepadId ?? this.currentGamepad?.id;
        if (activeGamepadId) {
            const gamepad = this.connectedGamepads.find((gp) => gp.id === activeGamepadId);
            if (this.currentGamepad !== gamepad) {
                this.currentGamepad = gamepad ?? null;
            }
            if (!this.pollingTimer && gamepad) {
                this.startPollingTimer();
            }
        } else {
            this.stopPollingTimer();
            this.currentGamepad = null;
        }
    }

    //#endregion

    //#region Events

    private onGamepadConnected(event: GamepadEvent) {
        this.log('Gamepad ' + event.gamepad.id + ' connected!');
        const gamepad: Gamepad = event.gamepad;

        if (!this.isGamepadConnected(gamepad.id)) {
            this.connectedGamepads.push(gamepad);
            this.gamepadsConnectedSubject.next(this.connectedGamepads);
        }

        this.updateGamepadEventMonitoringState();
    }

    private onGamepadDisconnected(event: GamepadEvent) {
        this.log('Gamepad ' + event.gamepad.id + ' disconnected!');
        // Remove the gamepad from the connetedGamepad list
        this.connectedGamepads.forEach((gamepad, index) => {
            if (gamepad.id === event.gamepad.id) {
                this.connectedGamepads.splice(index, 1);
                this.gamepadsConnectedSubject.next(this.connectedGamepads);
                return;
            }
        });

        this.updateGamepadEventMonitoringState();
    }

    private scanGamepadEvent() {
        const activeGamepadInputs = this.getActiveGamepadInputs();
        if (!activeGamepadInputs) {
            return;
        }

        const buttonEvents = this.getButtonsInputEvents(activeGamepadInputs.buttons);
        const analogicEvents = this.getAxesInputEvents(activeGamepadInputs.axes);

        const gamepadEvents = buttonEvents.concat(analogicEvents);
        if (gamepadEvents.length > 0) {
            this.gamepadEventsSubject.next(gamepadEvents);
        }
    }

    private getActiveGamepadInputs() {
        if (!this.currentGamepad) {
            this.stopPollingTimer();
            return null;
        }

        const gamepad = this.getNavigatorGamepads()[this.currentGamepad.index];

        // Identify if theres any event to detect
        const gamepadInputs = { buttons: gamepad?.buttons.map((btn) => btn.pressed) ?? [], axes: gamepad?.axes ?? [] };
        const serialized = JSON.stringify(gamepadInputs);

        if (serialized !== this.oldGamepadSerialized) {
            this.oldGamepadSerialized = serialized;
            return gamepadInputs;
        }
        return null;
    }

    private getButtonsInputEvents(buttons: boolean[]) {
        const gamepadButtonsEvents: GamepadEventData[] = [];

        for (let i = 0; i < buttons.length; i++) {
            const newValue = buttons[i];
            const oldValue = this.buttonsStates[i];

            if (newValue !== oldValue) {
                // update the buffered state
                this.buttonsStates[i] = newValue;
                if (oldValue !== undefined || newValue) {
                    this.log(`${newValue ? 'Down' : 'Up'} command triggered for button #${i}`);
                    gamepadButtonsEvents.push(new ButtonGamepadEventData(i, newValue));
                }
            }
        }
        return gamepadButtonsEvents;
    }

    private getAxesInputEvents(axes: readonly number[]) {
        const gamepadAxesEvents: GamepadEventData[] = [];
        const pan = axes[0];
        const tilt = axes[1];

        const velocity = { x: pan, y: tilt };

        if (Math.abs(velocity.x) > this.deadZone || Math.abs(velocity.y) > this.deadZone) {
            this.log(`Velocity x=${velocity.x}, y=${velocity.y}`);
            gamepadAxesEvents.push(new AnalogicGamepadEventData(velocity));
            this.pendingPanTilt = true;
        } else if (this.pendingPanTilt) {
            this.pendingPanTilt = false;
            gamepadAxesEvents.push(new AnalogicGamepadEventData());
        }
        return gamepadAxesEvents;
    }

    //#endregion
}
