import { IBitRateProvider, BitRateComputer, BitRate } from './BitRateComputer';
import { IFrameRateProvider, FrameRateComputer, FrameRate } from './FrameRateComputer';

export class StatisticProvider implements IBitRateProvider, IFrameRateProvider {
  private m_bitRateProvider: BitRateComputer;

  private m_audioBitRateProvider: BitRateComputer;

  private m_frameRateProvider: FrameRateComputer;

  constructor() {
    this.m_bitRateProvider = new BitRateComputer();
    this.m_audioBitRateProvider = new BitRateComputer();
    this.m_frameRateProvider = new FrameRateComputer();
  }

  public get bitRateProvider(): BitRateComputer {
    return this.m_bitRateProvider;
  }

  public get audioBitRateProvider(): BitRateComputer {
    return this.m_audioBitRateProvider;
  }

  public get frameRateProvider(): FrameRateComputer {
    return this.m_frameRateProvider;
  }

  reset() {
    this.m_bitRateProvider = new BitRateComputer();
    this.m_audioBitRateProvider = new BitRateComputer();
    this.m_frameRateProvider = new FrameRateComputer();
  }

  getBitRate(): BitRate {
    return this.m_bitRateProvider.getBitRate();
  }

  getAudioBitRate(): BitRate {
    return this.m_audioBitRateProvider.getBitRate();
  }

  getFrameRate(): FrameRate {
    return this.m_frameRateProvider.getFrameRate();
  }
}
