import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateFormat, MeltedIcon } from '@genetec/gelato-angular';
import { ItemSlot } from '@genetec/gelato';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { IGuid, SafeGuid } from 'safeguid';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { CommandsUsage } from '@modules/shared/services/commands/commands-usage/commands-usage';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { TimeService } from '@modules/shared/services/time/time.service';
import { AlarmContentTypes } from '../../../enumerations/alarm-content-types';
import { AlarmAcknowledgeAction } from '../../../controllers/alarms.controller';
import { AlarmsService } from '../../../services/alarms.service';
import { AlarmEventCompactWidgetComponent } from '../alarm-event-compact-widget/alarm-event-compact-widget.component';

@Component({
    selector: 'app-alarm-event-expand-widget',
    templateUrl: './alarm-event-expand-widget.component.html',
    styleUrls: ['./alarm-event-expand-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: AlarmEventExpandWidgetComponent,
    pluginTypes: [PluginTypes.MapPopupExpand],
    exposure: { id: AlarmEventCompactWidgetComponent.pluginId, priority: 0 },
    isContentSupported: (content: Content) => !!content?.type.equals(AlarmContentTypes.Instance),
})
export class AlarmEventExpandWidgetComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('653CDDA2-9881-4394-87D8-F015BB82925C');

    public alarmName!: string;
    public commandsUsage!: CommandsUsage;
    public dataContext: unknown;
    public icon: MeltedIcon | null = MeltedIcon.Alarm;
    public readonly ItemSlot = ItemSlot;
    public content?: Content;
    public timestamp!: string;

    private alarmId!: IGuid;
    private instanceId!: number;
    private subscriptions = new SubscriptionCollection();

    constructor(
        trackingService: TrackingService,
        private alarmsService: AlarmsService,
        private timeService: TimeService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService
    ) {
        super(trackingService);
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
        super.ngOnDestroy();
    }

    public async ngOnInit() {
        super.ngOnInit();

        if (this.content) {
            let alarmContent;
            if (this.content.type.equals(AlarmContentTypes.Instance)) {
                alarmContent = this.content;
            }

            if (alarmContent?.parameters) {
                this.instanceId = alarmContent.parameters.getField('AlarmInstanceId');
                this.alarmId = alarmContent.parameters.getFieldGuid('SourceEntityGuid');
                this.alarmName = `${alarmContent.parameters.getField<string>('SourceEntityName')} (${this.instanceId})`;
                this.timestamp = this.timeService.formatTime(alarmContent.parameters.getFieldDate('Timestamp'), DateFormat.DateTime);
            }

            this.commandsUsage = await this.commandsService.getCommandsUsage({ type: ContextTypes.Content, data: alarmContent });
            this.subscriptions.add(this.commandsUsage.subscribe());
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public async onAckAlarmClicked(event?: MouseEvent): Promise<void> {
        event?.stopPropagation();
        const action = new AlarmAcknowledgeAction();
        action.instanceId = this.instanceId;
        action.alarmId = this.alarmId;
        await this.alarmsService?.acknowledgeAsync(action);
    }
}
