import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { CustomIconState } from '@modules/shared/api/api';
import { ResourcesService } from '@modules/shared/services/resources/resources.service';
import { IGuid, SafeGuid } from 'safeguid';

@Component({
    selector: 'app-custom-icon',
    templateUrl: './custom-icon.component.html',
    styleUrls: ['./custom-icon.component.scss'],
    animations: [
        trigger('showAnimation', [transition(':enter', [style({ opacity: 0 }), animate('250ms', style({ opacity: 1 }))])]),
        trigger('leaveAnimation', [transition(':leave', [style({ opacity: 0.25 }), animate('250ms', style({ opacity: 0 }))])]),
    ],
})
export class CustomIconComponent implements OnInit, OnChanges {
    @Input() public icon?: Icon | string | null = Icon.Info;

    @Input() public size?: 'small' | 'medium' | 'large' | '' = 'medium';

    @Input() public color?: string;

    @Input() public entityId?: IGuid | string | null = SafeGuid.EMPTY;

    @Input() public customIconId?: IGuid | string | null;

    @Input() public customIconState?: CustomIconState | null;

    @Input() public iconFontSizePx?: number;

    @Input() public customIconWidthPx?: number;

    @Input() public customIconHeightPx?: number;

    public customIconSrc?: string;

    public isLoading = false;

    private loadingFailed = false;

    constructor(private resourcesService: ResourcesService, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        let needReloadCustomIcon = false;
        if (changes.customIconId) {
            needReloadCustomIcon = !toGuid(changes.customIconId?.previousValue).equals(toGuid(changes.customIconId?.currentValue));
        }

        if (!needReloadCustomIcon && changes.customIconState) {
            needReloadCustomIcon = changes.customIconState?.previousValue !== changes.customIconState?.currentValue;
        }

        if (this.icon?.startsWith('data:image/')) {
            this.customIconSrc = this.icon;
            needReloadCustomIcon = false;
        }

        if (needReloadCustomIcon) {
            this.loadCustomIcon();
        }
    }

    public isGenIconShowing(): boolean {
        return this.loadingFailed || !this.customIconSrc || (this.isLoading && !(this.customIconId && isNonEmptyGuid(this.customIconId)));
    }

    private loadCustomIcon() {
        const customIconId = toGuid(this.customIconId);
        const entity = toGuid(this.entityId);

        if (isNonEmptyGuid(customIconId) && isNonEmptyGuid(entity)) {
            // Flag is Loading after a second in case custom icon comes in fast. Otherwise show loading.
            const timeout = setTimeout(() => {
                this.isLoading = true;
            }, 1000);

            this.resourcesService
                .getCustomIconAsync([{ customIconId, entity, state: this.customIconState ?? undefined }])
                .then((result) => {
                    const customIconIdSrc = entity ? result[entity.toString()] : undefined;
                    this.customIconSrc = customIconIdSrc ? `data:image/png;base64,${customIconIdSrc}` : '';
                    this.cdRef.detectChanges();
                })
                .catch(() => {
                    this.customIconSrc = '';
                })
                .finally(() => {
                    this.loadingFailed = !this.customIconSrc;

                    if (this.isLoading) {
                        // Clear loading flag a bit later to allow animation if went from loading to custom icon

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 750);
                    }

                    clearTimeout(timeout);
                })
                .fireAndForget();
        } else {
            this.customIconSrc = '';
        }
    }
}
