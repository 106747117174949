<app-filter-host [filterDescriptor]="descriptor" [state]="state">
    <div class="filter-content">
        <div class="gen-row">
            <div class="gen-col-xs-6">
                <!-- DataTypes List -->
                <gen-melted-list
                    [genItemsSource]="dataTypes"
                    genLabel="{{ 'STE_LABEL_NOUN_RECORDTYPES' | translate }}"
                    (genSelectedItemChange)="onDataTypesChanged($event)"
                    (genSelectedItemsChange)="onDataTypesChanged($event)"
                    [genSelectionType]="SelectionType.List.Multiple"
                >
                </gen-melted-list>
            </div>
            <div class="gen-col-xs-6">
                <!-- Parameters List -->
                <gen-melted-list
                    [genItemsSource]="parameters"
                    genLabel="{{ 'STE_LABEL_CONDITIONS' | translate }}"
                    [genActions]="conditionActions"
                    [genSelectionType]="SelectionType.List.Single"
                    [genDensity]="Density.List.Compact"
                >
                </gen-melted-list>
            </div>
        </div>
    </div>
</app-filter-host>
