import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { map } from 'rxjs/operators';
import { McUserService } from './../services/mc-user.service';

@Pipe({ name: 'userName' })
export class UserNamePipe implements PipeTransform {
    constructor(private _userService: McUserService, private _translateService: TranslateService) {}

    public transform(id: IGuid | string | null | undefined, defaultName: string = 'STE_LABEL_USERNAME_NONE'): Observable<string> {
        const defaultTranslateName = (this._translateService?.instant(defaultName) as string) ?? defaultName;
        if (!id) return of(defaultTranslateName);
        const userId = isInstanceOfGuid(id) ? id : SafeGuid.parse(id);
        if (userId.isEmpty()) return of(defaultTranslateName);

        return from(this._userService.getUserOrGroup(userId)).pipe(map((x) => x?.name ?? defaultTranslateName));
    }
}
