import { SafeGuid } from 'safeguid';
import { Constants } from '../utilities/constants';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const KnownCustomHeaderIds = Constants({
    WatchlistCustomHeaderId: SafeGuid.parse('A2853EED-581C-47D0-A7AB-9B13B660F3E1'),
});
