import { ApplicationRef, ComponentFactoryResolver, ElementRef, EmbeddedViewRef, Inject, Injectable, Injector } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { GenModalService, GenMeltedModal } from '@genetec/gelato-angular';
import { SubscriptionCollection } from '@modules/shared/utilities/subscription-collection';
import { Type } from 'ng-mocks';
import { Observable, Subject, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WINDOW } from '@src/app/utilities';

declare global {
    interface Document {
        // Exit fullscreen
        webkitExitFullscreen(): void;
        webkitExitFullScreen(): void;
        mozCancelFullScreen(): void;
        msExitFullscreen(): void;
    }

    interface Element {
        // Fullscreen
        webkitRequestFullscreen(): void;
        webkitRequestFullScreen(): void;
        mozRequestFullScreen(): void;
        msRequestFullscreen(): void;
    }
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class FullscreenService {
    static readonly fullscreenChangeTypes = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange'];

    public fullscreenElementChanged$: Observable<Element | null>;
    private fullscreenElementChangedSubject = new Subject<Element | null>();
    private openedModalsSubscriptions = new SubscriptionCollection();

    constructor(
        private genModalService: GenModalService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        @Inject(WINDOW) private window: Window
    ) {
        this.fullscreenElementChanged$ = this.fullscreenElementChangedSubject.asObservable();

        FullscreenService.fullscreenChangeTypes.forEach((type) =>
            fromEvent(document, type)
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.fullscreenChanged();
                })
        );
    }

    public get currentFullScreenElement(): Element | null {
        // Chrome, Firefox & Edge
        if (this.window.document.fullscreenElement) {
            return this.window.document.fullscreenElement;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const unsafeDocument = this.window.document as any;
        if (!unsafeDocument) {
            return null;
        }
        /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access */
        if (unsafeDocument.webkitFullscreenElement) {
            return unsafeDocument.webkitFullscreenElement;
        }
        if (unsafeDocument.mozFullScreenElement) {
            return unsafeDocument.mozFullScreenElement;
        }
        if (unsafeDocument.msFullscreenElement) {
            return unsafeDocument.msFullscreenElement;
        }
        /* eslint-enable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access */
        return null;
    }

    public get isFullscreenOn(): boolean {
        return this.currentFullScreenElement !== null;
    }

    public displayModal<T extends GenMeltedModal>(modalType: Type<T>, params?: T['params']): T;
    public displayModal<T extends GenMeltedModal>(localModalInstance?: T): T;
    public displayModal<T extends GenMeltedModal>(modal: Type<T> | T, params?: T['params']): T {
        if (modal instanceof GenMeltedModal) {
            if (this.isFullscreenOn) {
                this.registerFullscreenModal(modal);
            }
            this.genModalService.show(modal.id);
            return modal;
        } else {
            if (this.currentFullScreenElement) {
                // TODO: Fix when we have a way to create component's without viewContainerRef probably in Angular 14+
                // https://github.com/angular/angular/pull/46685
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modal);
                const componentRef = componentFactory.create(this.injector);
                this.applicationRef.attachView(componentRef.hostView);
                const embeddedViewRef = componentRef.hostView as EmbeddedViewRef<unknown>;
                const element = embeddedViewRef.rootNodes[0] as HTMLElement;
                Object.assign(componentRef.instance, params);
                this.currentFullScreenElement.appendChild(element);
                this.registerFullscreenModal(componentRef.instance);
                return componentRef.instance;
            }
            return this.genModalService.open(modal, params);
        }
    }

    public async enterFullscreenFor(elementRef: ElementRef<Element>): Promise<void> {
        // Check which implementation is available
        /* eslint-disable @typescript-eslint/unbound-method */
        const requestMethod =
            elementRef.nativeElement.requestFullscreen ||
            elementRef.nativeElement.webkitRequestFullscreen ||
            elementRef.nativeElement.webkitRequestFullScreen ||
            elementRef.nativeElement.mozRequestFullScreen ||
            elementRef.nativeElement.msRequestFullscreen;
        /* eslint-enable @typescript-eslint/unbound-method */
        if (requestMethod) {
            await requestMethod.apply(elementRef.nativeElement);
        }
    }

    public async exitFullscreen(): Promise<void> {
        if (this.isFullscreenOn) {
            // Check which implementation is available
            /* eslint-disable @typescript-eslint/unbound-method */
            const requestMethod =
                this.window.document.exitFullscreen ||
                this.window.document.webkitExitFullscreen ||
                this.window.document.webkitExitFullScreen ||
                this.window.document.mozCancelFullScreen ||
                this.window.document.msExitFullscreen;
            /* eslint-enable @typescript-eslint/unbound-method */
            if (requestMethod) {
                await requestMethod.apply(this.window.document);
            }
        }
    }

    private registerFullscreenModal<T extends GenMeltedModal>(modal: T): void {
        this.openedModalsSubscriptions.add(
            this.fullscreenElementChanged$
                .pipe(
                    filter((element: Element | null) => !element),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    modal.hide();
                })
        );
    }

    private fullscreenChanged(): void {
        this.fullscreenElementChangedSubject.next(this.currentFullScreenElement);
        if (!this.isFullscreenOn) {
            this.openedModalsSubscriptions.unsubscribeAll();
        }
    }
}
