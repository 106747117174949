// Autogenerated: v2/help/Entities/ApiLprUnit?codegen=ts&InterfaceOnly=False&workflow=False on 2021-05-11 9:36:14 AM
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { UnitEntity } from '../UnitEntity';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IEntity } from '../../Interface/IEntity';
import { ILprUnitEntity, LprUnitEntityFields } from '../../Interface/ILprUnitEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class LprUnitEntity extends UnitEntity implements ILprUnitEntity {
    // #region REST Properties

    // ExtendedInfo
    public get extendedInfo(): string {
        return this.getField<string>(LprUnitEntityFields.extendedInfoField);
    }

    // LprUnitType
    public get lprUnitType(): string {
        return this.getField<string>(LprUnitEntityFields.lprUnitTypeField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.LprUnits);
    }

    // #endregion Constructor

}

