import { Serializer } from '../../../utils/Serializer';

/**
 * @beta
 * */
export class WebSocketMessageHeader {
  private static readonly versionSize = 1;

  private static readonly version = 1;

  private static readonly channelSize = 4;

  private readonly m_channel: number;

  private readonly m_messageType: string;

  public get channel(): number {
    return this.m_channel;
  }

  constructor(channel: number, messageType: string) {
    this.m_channel = WebSocketMessageHeader.toInteger(channel); // to make sure channel is not a floating point number
    this.m_messageType = messageType;
  }

  public serialize(): ArrayBuffer {
    const uint8Array = new TextEncoder().encode(this.m_messageType);
    const serializer = Serializer.build(WebSocketMessageHeader.versionSize + WebSocketMessageHeader.channelSize + 4 + uint8Array.length); // 4 for the string length
    serializer.writeUint8(WebSocketMessageHeader.version);
    serializer.writeUint32(this.m_channel);
    serializer.writeString(this.m_messageType);
    return serializer.arrayBuffer;
  }

  private static toInteger(float: number): number {
    return Math.trunc(float);
  }
}
