<div class="browser w-100 h-100">
    <!-- Search -->
    <div class="browser-search">
        <div class="gen-input-group">
            <input
                #entitySearchInput
                genAutofocus
                class="gen-input"
                type="text"
                spellcheck="false"
                placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
                [autocomplete]="false"
                [disabled]="false"
                [(ngModel)]="searchText"
                (ngModelChange)="onSearchTextChanged()"
                genKeyboardNavigation
                [genAutomaticNavigation]="true"
                (genEnter)="searchAsync($event)"
                #myInput="ngModel"
                data-cy="entityBrowserSearch"
                [maxlength]="100"
            />

            <!-- Search Icon -->
            <div class="gen-input-group-append">
                <gen-button [flavor]="ButtonFlavor.Flat" [hidden]="searchText === ''" appTooltip="{{ 'STE_BUTTON_CLEAR' | translate }}" (click)="clearSearch()">
                    <gen-icon [icon]="Icon.Close"></gen-icon>
                </gen-button>
                <!-- <gen-button
                    class="no-pointer"
                    [flavor]="ButtonFlavor.Flat"
                    [hidden]="searchText !== ''"
                >
                    <gen-icon [icon]="Icon.Search" ></gen-icon>
                </gen-button> -->
                <button class="gen-button flat no-pointer" type="button" [hidden]="searchText !== ''">
                    <i [class]="MeltedIcon.Search"></i>
                </button>
            </div>
        </div>
    </div>

    <div #browserTree class="browser-tree" data-cy="entityBrowserResultTree">
        <ng-container *ngIf="isRefreshing">
            <gen-spinner class="spinner-align-center" *ngIf="isRefreshing">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
        </ng-container>
        <!-- List -->
        <!-- <BASED ON gen-tree-component.html> -->
        <div *ngIf="!isRefreshing" (dragend)="onBrowserDragEnd($event)" [ngClass]="{ 'browser-with-items': currentItemsSource.rootItems.length >= 1 }">
            <div *ngIf="currentItemsSource.rootItems.length < 1" class="no-elements-align-center">
                <gen-text [innerHTML]="emptyText"></gen-text>
            </div>
            <div [hidden]="itemsSource.rootItems.length < 1 || onlyShowCheckedItems || isFiltered" [attr.id]="treeId" class="gen-tree">
                <ng-container *ngTemplateOutlet="treeNode; context: { itemSource: itemsSource.rootItems }"></ng-container>
            </div>
            <div [hidden]="filteredItemsSource.rootItems.length < 1 || onlyShowCheckedItems || !isFiltered" [attr.id]="filteredTreeId" class="gen-tree">
                <ng-container *ngTemplateOutlet="treeNode; context: { itemSource: filteredItemsSource.rootItems }"></ng-container>
            </div>
            <div *ngIf="areItemsCheckable" [hidden]="currentItemsSource.checkedEntityIds.size < 1 || !onlyShowCheckedItems" [attr.id]="checkedTreeId" class="gen-tree">
                <ng-container *ngTemplateOutlet="treeNode; context: { itemSource: sortedCheckedItems }"></ng-container>
            </div>
        </div>
    </div>

    <div class="browser-footer pl-1 pr-1" *ngIf="showFooter">
        <gen-button class="pl-1" [flavor]="ButtonFlavor.Flat" appTooltip="{{ 'STE_BUTTON_REFRESH' | translate }}" (click)="refreshAsync(undefined, true)">
            <gen-icon [icon]="Icon.Reload"></gen-icon>
        </gen-button>
        <gen-toggle-button
            class="pl-1"
            [(genIsChecked)]="onlyShowCheckedItems"
            (genIsCheckedChange)="updateOnlyShowCheckedItemsAsync()"
            [genIcon]="MeltedIcon.Show"
            [genIconChecked]="MeltedIcon.Hide"
            appTooltip="{{ (onlyShowCheckedItems ? 'STE_ACTION_SHOW_ALL' : 'STE_ACTION_SHOWSELECTEDITEMS') | translate }}"
            *ngIf="areItemsCheckable && (!isHierarchical || alwaysShowCheckedItemsActions) && currentItemsSource.checkedEntityIds.size > 0"
        ></gen-toggle-button>
        <gen-button
            class="pl-1"
            [flavor]="ButtonFlavor.Flat"
            [hidden]="!(areItemsCheckable && !isHierarchical && !onlyShowCheckedItems)"
            appTooltip="{{ 'STE_ACTION_CHECKALL' | translate }}"
            (click)="onCheckAllItemsClicked()"
        >
            <gen-icon [icon]="Icon.CheckAll"></gen-icon>
        </gen-button>
        <gen-button
            [flavor]="ButtonFlavor.Flat"
            [hidden]="!(areItemsCheckable && currentItemsSource.checkedEntityIds.size > 0)"
            appTooltip="{{ 'STE_ACTION_UNCHECKALL' | translate }}"
            (click)="onUncheckAllItemsClicked()"
        >
            <gen-icon [icon]="Icon.Close"></gen-icon>
        </gen-button>
        <ng-content select="additionalAction"></ng-content>
    </div>

    <!-- Switch between a leaf node and a nested tree node -->
    <ng-template #treeNode let-itemSource="itemSource" let-parentNode="parentNode">
        <ul [ngClass]="{ 'show-tree': parentNode && parentNode.isExpanded }">
            <ng-container *ngFor="let item of itemSource; trackBy: getItemId">
                <li
                    (click)="onItemExpanderToggled(item, $event)"
                    genStopPropagation
                    class="pointer"
                    [ngClass]="{
                        selected: isItemSelected(item),
                        parent: item.canExpand,
                        'gen-node-expanded': item.isExpanded
                    }"
                >
                    <ng-container *ngTemplateOutlet="itemNode; context: { item: item }"></ng-container>
                    <ng-container *ngIf="item.canExpand">
                        <ng-container *ngTemplateOutlet="treeNode; context: { itemSource: item.children, parentNode: item }"></ng-container>
                    </ng-container>
                </li>
            </ng-container>
        </ul>
    </ng-template>

    <!-- Item node -->
    <ng-template #itemNode let-item="item">
        <ng-container *ngIf="item.isShowMore">
            <div [hidden]="item.isQuerying" class="p-1 pl-3">
                <span class="fg-color-accent text-bold" (click)="onShowMoreItemClicked(item)">{{ 'STE_ACTION_LOADMOREITEMS' | translate }}</span>
            </div>
            <div [hidden]="!item.isQuerying" class="pl-3 my-auto">
                <gen-spinner class="fg-color-accent text-bold">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!item.isShowMore">
            <gen-spinner *ngIf="item.isExpanding" class="expand-spinner"></gen-spinner>
            <ng-container *ngTemplateOutlet="nodeCheckbox; context: { item: item }"></ng-container>
            <app-entity-state-item
                [entity]="item"
                [withExpander]="isHierarchical"
                [showIcon]="true"
                [id]="id + '-' + item.id"
                [tabindex]="(!item.parent || item.parent.isExpanded) && !areItemsCheckable ? '0' : '-1'"
                (click)="onItemClicked(item, $event)"
                (dblclick)="onItemDoubleClicked(item, $event)"
                (contextmenu)="onItemContextMenu(item, $event)"
                (keydown)="onItemKeyDown(item, $event)"
                appEntityBrowserKeyboardNavigation
                [browserItem]="item"
                genStopPropagation
                genKeyboardNavigation
                (genArrowRight)="onItemArrowRightPressed(item, $event)"
                (genArrowLeft)="onItemArrowLeftPressed(item, $event)"
                (genArrowUp)="onItemArrowUpPressed(item, $event)"
                (genArrowDown)="onItemArrowDownPressed(item, $event)"
                (dragover)="onItemDragEnter(item, $event)"
                (dragstart)="onItemDragStart(item, $event)"
                (drop)="onItemDragDrop(item, $event)"
                draggable="true"
                [appTooltipOnlyIfOverflow]="false"
            ></app-entity-state-item>
            <!-- genStopPropagation
                genKeyboardNavigation
                [genNestedNavigation]="true"
                genFindElementQuery="div.gen-item[tabindex='0']"
                [genNestedNavigationContainerId]="currentTreeElementId" -->
        </ng-container>
    </ng-template>

    <!-- Checkbox -->
    <ng-template #nodeCheckbox let-item="item">
        <ng-container *ngIf="areItemsCheckable">
            <gen-melted-checkbox
                [disabled]="!canCheckItem(item)"
                [ngClass]="{ 'with-expander': isHierarchical }"
                (click)="onItemCheckboxToggled(item, $event)"
                genStopPropagation
                [id]="'tree-node-checkbox' + id + '-' + item.id"
                [tabindex]="!item.parent || item.parent.isExpanded ? '0' : '-1'"
                [(ngModel)]="item.isChecked"
                [genIsIndeterminate]="item.isPartialChecked"
                genKeyboardNavigation
                (genSpace)="onItemCheckboxToggled(item, $event)"
                (genArrowRight)="onItemArrowRightPressed(item, $event)"
                (genArrowLeft)="onItemArrowLeftPressed(item, $event)"
            ></gen-melted-checkbox>
        </ng-container>
    </ng-template>
    <!-- </BASED ON gen-tree-component.html> -->
</div>
