// Autogenerated: v2/help/enumerations/ApiLogonResult?codegen=ts&showresources=True on 2020-09-21 3:53:59 PM

export class LogonResult {
    public static Success = 'Success';
    public static Failure = 'Failure';
    public static InvalidServerVersion = 'InvalidServerVersion';
    public static ConnectionLost = 'ConnectionLost';
    public static NewConnection = 'NewConnection';
    public static NewBackupConnection = 'NewBackupConnection';
    public static InvalidCredentials = 'InvalidCredentials';
    public static InsufficientPrivileges = 'InsufficientPrivileges';
    public static InsufficientSecurityLevel = 'InsufficientSecurityLevel';
    public static ConnectionAttempt = 'ConnectionAttempt';
    public static OperationTimeout = 'OperationTimeout';
    public static LicenseError = 'LicenseError';
    public static UnableToRetrievePrivileges = 'UnableToRetrievePrivileges';
    public static UnableToIdentifyClientApplication = 'UnableToIdentifyClientApplication';
    public static NoAuthenticationAgent = 'NoAuthenticationAgent';
    public static TooManyAuthenticationAgents = 'TooManyAuthenticationAgents';
    public static AuthenticationAgentResponseTimeout = 'AuthenticationAgentResponseTimeout';
    public static AuthenticationAgentImportFailure = 'AuthenticationAgentImportFailure';
    public static UserAccountDisabledOrLocked = 'UserAccountDisabledOrLocked';
    public static UserAndSupervisorOnDifferentDomains = 'UserAndSupervisorOnDifferentDomains';
    public static AlreadyConnected = 'AlreadyConnected';
    public static PasswordExpired = 'PasswordExpired';
    public static SpecifyDomain = 'SpecifyDomain';
    public static DisallowedBySchedule = 'DisallowedBySchedule';
    public static ExceededNumberOfWorkstations = 'ExceededNumberOfWorkstations';
    public static CertificateRegistrationError = 'CertificateRegistrationError';
    public static PasswordComplexityInsufficient = 'PasswordComplexityInsufficient';
    public static InvalidSupervisor = 'InvalidSupervisor';
    public static SupervisorPasswordIsEmpty = 'SupervisorPasswordIsEmpty';
    public static CantSuperviseSelf = 'CantSuperviseSelf';
    public static DirectoryRedirect = 'DirectoryRedirect';
    public static LogonAborted = 'LogonAborted';
    public static MissingRequestUserChangePassordEvent = 'MissingRequestUserChangePassordEvent';
    public static LogonNegotiate = 'LogonNegotiate';
    public static DirectoryCertificateNotTrusted = 'DirectoryCertificateNotTrusted';
    public static SessionExpired = 'SessionExpired';
    public static InteractiveLogonRequired = 'InteractiveLogonRequired';
    public static InvalidServerEndpoint = 'InvalidServerEndpoint';
    public static BruteForceThrottling = 'BruteForceThrottling';
    public static DeniedByFirewall = 'DeniedByFirewall';
    public static ExternalPasswordExpired = 'ExternalPasswordExpired';

    public static readonly values = [
        LogonResult.Success,
        LogonResult.Failure,
        LogonResult.InvalidServerVersion,
        LogonResult.ConnectionLost,
        LogonResult.NewConnection,
        LogonResult.NewBackupConnection,
        LogonResult.InvalidCredentials,
        LogonResult.InsufficientPrivileges,
        LogonResult.InsufficientSecurityLevel,
        LogonResult.ConnectionAttempt,
        LogonResult.OperationTimeout,
        LogonResult.LicenseError,
        LogonResult.UnableToRetrievePrivileges,
        LogonResult.UnableToIdentifyClientApplication,
        LogonResult.NoAuthenticationAgent,
        LogonResult.TooManyAuthenticationAgents,
        LogonResult.AuthenticationAgentResponseTimeout,
        LogonResult.AuthenticationAgentImportFailure,
        LogonResult.UserAccountDisabledOrLocked,
        LogonResult.UserAndSupervisorOnDifferentDomains,
        LogonResult.AlreadyConnected,
        LogonResult.PasswordExpired,
        LogonResult.SpecifyDomain,
        LogonResult.DisallowedBySchedule,
        LogonResult.ExceededNumberOfWorkstations,
        LogonResult.CertificateRegistrationError,
        LogonResult.PasswordComplexityInsufficient,
        LogonResult.InvalidSupervisor,
        LogonResult.SupervisorPasswordIsEmpty,
        LogonResult.CantSuperviseSelf,
        LogonResult.DirectoryRedirect,
        LogonResult.LogonAborted,
        LogonResult.MissingRequestUserChangePassordEvent,
        LogonResult.LogonNegotiate,
        LogonResult.DirectoryCertificateNotTrusted,
        LogonResult.SessionExpired,
        LogonResult.InteractiveLogonRequired,
        LogonResult.InvalidServerEndpoint,
        LogonResult.BruteForceThrottling,
        LogonResult.DeniedByFirewall,
        LogonResult.ExternalPasswordExpired
    ];

    public static ResourceDict = new Map<string, number>();
    static LogonResult() {
        this.ResourceDict.set('Success', 679);
        this.ResourceDict.set('Failure', 672);
        this.ResourceDict.set('InvalidServerVersion', 675);
        this.ResourceDict.set('ConnectionLost', 671);
        this.ResourceDict.set('NewConnection', 5144);
        this.ResourceDict.set('NewBackupConnection', 676);
        this.ResourceDict.set('InvalidCredentials', 674);
        this.ResourceDict.set('InsufficientPrivileges', 673);
        this.ResourceDict.set('InsufficientSecurityLevel', 9760);
        this.ResourceDict.set('ConnectionAttempt', 680);
        this.ResourceDict.set('OperationTimeout', 678);
        this.ResourceDict.set('LicenseError', 974);
        this.ResourceDict.set('UnableToRetrievePrivileges', 3958);
        this.ResourceDict.set('UnableToIdentifyClientApplication', 4193);
        this.ResourceDict.set('NoAuthenticationAgent', 2430);
        this.ResourceDict.set('TooManyAuthenticationAgents', 2429);
        this.ResourceDict.set('AuthenticationAgentResponseTimeout', 2431);
        this.ResourceDict.set('AuthenticationAgentImportFailure', 2438);
        this.ResourceDict.set('UserAccountDisabledOrLocked', 3936);
        this.ResourceDict.set('UserAndSupervisorOnDifferentDomains', 7785);
        this.ResourceDict.set('AlreadyConnected', 8536);
        this.ResourceDict.set('PasswordExpired', 7287);
        this.ResourceDict.set('SpecifyDomain', 7669);
        this.ResourceDict.set('DisallowedBySchedule', 7289);
        this.ResourceDict.set('ExceededNumberOfWorkstations', 7290);
        this.ResourceDict.set('CertificateRegistrationError', -1);
        this.ResourceDict.set('PasswordComplexityInsufficient', -1);
        this.ResourceDict.set('InvalidSupervisor', 7288);
        this.ResourceDict.set('SupervisorPasswordIsEmpty', 9437);
        this.ResourceDict.set('CantSuperviseSelf', 7576);
        this.ResourceDict.set('DirectoryRedirect', 8204);
        this.ResourceDict.set('LogonAborted', 8631);
        this.ResourceDict.set('MissingRequestUserChangePassordEvent', 8632);
        this.ResourceDict.set('LogonNegotiate', 11090);
        this.ResourceDict.set('DirectoryCertificateNotTrusted', 12097);
        this.ResourceDict.set('SessionExpired', -1);
        this.ResourceDict.set('InteractiveLogonRequired', 14028);
        this.ResourceDict.set('InvalidServerEndpoint', 14234);
        this.ResourceDict.set('BruteForceThrottling', 14397);
        this.ResourceDict.set('DeniedByFirewall', 14889);
        this.ResourceDict.set('ExternalPasswordExpired', 7287);
    }
}
