import { Inject, Injectable } from '@angular/core';
import { GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IMapEntity } from 'RestClient/Client/Interface/IMapEntity';
import { MapEntity } from 'RestClient/Client/Model/MapEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { ContextTypes } from '@shared/interfaces/plugins/public/context-types';
import {
    CommandBindings,
    CommandContext,
    CommandsService,
    COMMANDS_SERVICE,
    Coordinate,
    ExecuteCommandData,
    FEATURES_SERVICE,
    GeoCoordinate,
} from '@shared/interfaces/plugins/public/plugin-services-public.interface';
import { CommandArgs, CommandDescriptor, CommandProviderBase } from '@shared/services/commands/command-provider-base';
import { LanguageService } from '@shared/services/language/language.service';
import { TriggerIncidentModalComponent } from '../components/trigger-incident-modal/trigger-incident-modal.component';
import { ModalGuids } from '../mc-modal-ids';
import { MCPrivileges } from '../mc-privileges';
import { PrivilegeService } from '@shared/privilege/privilege.service';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { MCFeature } from '../mc-feature';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';

export namespace MissionControlCommands {
    export const triggerIncident: IGuid = SafeGuid.parse('FF592BB3-1451-4F7A-984B-CA3BFE2BDAB6');
    export const changePriority: IGuid = SafeGuid.parse('9DB8A670-36F8-4DD8-8BB2-2A6D0773B507');
}

// TODO Expose Mission Control commands needed for external module action like trigger on map and keyboard shortcut here.
@Injectable()
export class IncidentCommandProvider extends CommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(MissionControlCommands.triggerIncident, {
                id: MissionControlCommands.triggerIncident,
                icon: MeltedIcon.Incident,
                groupIcon: MeltedIcon.Incident,
                nameResourceId: 'STE_TITLE_TRIGGERANINCIDENT',
                requirements: this.fillCommandRequirements({
                    supportedContentTypes: SafeGuid.createSet([ContextTypes.Map]),
                    requiredPrivileges: SafeGuid.createSet([MCPrivileges.triggerIncidents]),
                }),
            });

            this.internalCommandDescriptors = descriptors;
        }
        return this.internalCommandDescriptors;
    }

    public get requiredFeatures(): Set<IGuid> {
        return SafeGuid.createSet([...super.requiredFeatures, MCFeature.missionControlfeature]);
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        private _privilegeService: PrivilegeService,
        private _modalService: GenModalService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: CommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];

        if (this.isTriggerIncidentAvailable(commandContext)) {
            commandIds.push(MissionControlCommands.triggerIncident);
        }

        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: MissionControlCommands.triggerIncident,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteCommand((safeExecuteCommandData) => this.executeTriggerIncident(safeExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) => this.safeIsCommandAvailable((safeCommandContext) => this.isTriggerIncidentAvailable(safeCommandContext), commandContext),
            canExecuteCommandHandler: () => this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.triggerIncidents),
        });
    }

    private async executeTriggerIncident(executeCommandData: ExecuteCommandData): Promise<void> {
        const args = this.extractArgs(executeCommandData.commandContext);
        const coordinates: GeoCoordinate | Coordinate | null = args.mapContext?.location ?? null;
        if (coordinates && args.mapContext) {
            const mapEntity = await this.scClient.getEntityAsync<MapEntity, IMapEntity>(MapEntity, args.mapContext?.mapId, null, null, 'AssociatedArea');
            if (this._modalService && mapEntity?.id && !mapEntity.id.equals(SafeGuid.EMPTY)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const triggerIncidentModal = this._modalService.open(TriggerIncidentModalComponent, { id: ModalGuids.triggerIncidentModalId.toString() });
                triggerIncidentModal.initializeLocation(mapEntity.associatedArea, coordinates, args.mapContext.geoLocalised);
            }
        }
        executeCommandData.isHandled = true;
    }

    private isTriggerIncidentAvailable(commandContext: CommandContext, commandArgs?: CommandArgs): boolean {
        commandArgs = commandArgs ?? this.extractArgs(commandContext);
        return !!commandArgs.mapContext?.geoLocalised;
    }
}
