import { Component, OnInit } from '@angular/core';
import { ICredentialEntity } from 'RestClient/Client/Interface/ICredentialEntity';
import { CredentialEntity } from 'RestClient/Client/Model/AccessControl/CredentialEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';

@Component({
    selector: 'app-credential-widget-base-component',
    template: '',
})
export class CredentialWidgetBaseComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public dataContext?: ContentService;
    public content?: Content;
    public isLoading = true;

    protected credential: CredentialEntity | null = null;
    protected scClient!: SecurityCenterClient;

    protected get monitoredFields(): string[] {
        return [];
    }

    constructor(securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService) {
        super(trackingService);

        this.scClient = securityCenterClientService?.scClient;
    }

    public async ngOnInit() {
        super.ngOnInit();

        if (this.content?.source) {
            if (SafeGuid.isGuid(this.content.source)) {
                const sourceId = SafeGuid.parse(this.content.source);
                if (!sourceId.isEmpty()) {
                    await this.getCredentialAsync(sourceId);
                    await this.initializeCredentialInfo();
                }
            }
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    protected async getCredentialAsync(credentialId: IGuid): Promise<void> {
        if (this.scClient) {
            this.credential = (await this.scClient.getEntityAsync<CredentialEntity, ICredentialEntity>(
                CredentialEntity,
                credentialId,
                null,
                null,
                this.monitoredFields.toString()
            )) as CredentialEntity;
        }
    }

    protected async initializeCredentialInfo(): Promise<void> {}
}
