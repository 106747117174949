import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CardholderModel } from '@modules/access-control/cardholder-edit-context.state';
import { ActivationModeLabel, ActiveExpirationModeLabel, BypassAntipassbackRules, InactiveExpirationModeLabel } from '@modules/access-control/enumerations';
import { AccessStatus } from 'RestClient/Client/Enumerations/AccessStatus';
import { LabeledFormControl } from '@modules/shared/forms/LabeledFormControl';
import { EvaluatedCardholderPrivileges } from '@modules/access-control/interfaces';
import { IGuid } from 'safeguid';
import { CardholderHelper } from '../cardholder-helper';
import { activationDateValidator, expirationDateValidator, firstNameLastNameValidator } from './validators';

export type IdentityFormGroup = FormGroup<{
    firstName: LabeledFormControl<string>;
    lastName: LabeledFormControl<string>;
    emailAddress: LabeledFormControl<string>;
    mobilePhoneNumber: LabeledFormControl<string>;
    picture: FormControl<string | null>;
}>;

export type StatusFormGroup = FormGroup<{
    accessStatus: FormControl<AccessStatus>;
    activationDate: FormControl<string | null>;
    expirationDate: FormControl<string | null>;
    expirationMode: FormControl<ActiveExpirationModeLabel | InactiveExpirationModeLabel>;
    activationMode: FormControl<ActivationModeLabel>;
    expirationDuration: FormControl<number>;
}>;

export type AccessRightsFormGroup = FormGroup<{
    groups: LabeledFormControl<IGuid[]>;
    credentials: LabeledFormControl<IGuid[]>;
    rules: LabeledFormControl<IGuid[]>;
}>;

export type AdvancedFormGroup = FormGroup<{
    useExtendedGrantTime: FormControl<boolean>;
    canEscortVisitors: FormControl<boolean>;
    displayName: FormControl<string | null>;
    description: FormControl<string | null>;
    logicalId: FormControl<number | null>;
    bypassAntipassbackRules: FormControl<BypassAntipassbackRules>;
    securityClearance: FormControl<number>;
    isSecurityClearanceInheritedFromGroup: FormControl<string>;
    partitions: FormControl<IGuid[] | null>;
}>;

export class CardholderEditFormGroup extends FormGroup<{
    identityFormGroup: IdentityFormGroup;
    statusFormGroup: StatusFormGroup;
    accessRightsFormGroup: AccessRightsFormGroup;
    advancedFormGroup: AdvancedFormGroup;
}> {
    private static readonly defaultExpirationDuration = 1;

    constructor(cardholderModel: CardholderModel, isAddMode: boolean, grantedPrivileges: EvaluatedCardholderPrivileges) {
        // TODO: Add the rest of the validations...
        super({
            identityFormGroup: new FormGroup<IdentityFormGroup['controls']>(
                {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    firstName: new LabeledFormControl(
                        'STE_LABEL_CARDHOLDER_FIRSTNAME',
                        { value: cardholderModel.firstName, disabled: !grantedPrivileges.modifyCardholderNamePrivilege && !isAddMode },
                        [Validators.maxLength(100)]
                    ),
                    lastName: new LabeledFormControl(
                        'STE_LABEL_CARDHOLDER_LASTNAME',
                        { value: cardholderModel.lastName, disabled: !grantedPrivileges.modifyCardholderNamePrivilege && !isAddMode },
                        [Validators.maxLength(100)]
                    ),
                    emailAddress: new LabeledFormControl(
                        'STE_LABEL_CARDHOLDER_EMAIL',
                        { value: cardholderModel.emailAddress, disabled: !grantedPrivileges.modifyCardholderNamePrivilege },
                        [Validators.maxLength(100), Validators.email]
                    ),
                    mobilePhoneNumber: new LabeledFormControl(
                        'STE_LABEL_CARDHOLDER_MOBILEPHONENUMBER',
                        { value: cardholderModel.mobilePhoneNumber, disabled: !grantedPrivileges.modifyCardholderNamePrivilege },
                        [Validators.maxLength(100), Validators.maxLength(100)]
                    ),
                    picture: new FormControl({ value: cardholderModel.picture ?? null, disabled: !grantedPrivileges.modifyCardholderPicturePrivilege }),
                },
                { validators: [firstNameLastNameValidator] }
            ),

            statusFormGroup: new FormGroup<StatusFormGroup['controls']>({
                accessStatus: new FormControl({ value: cardholderModel.accessStatus, disabled: !grantedPrivileges.changeCardholderStatusPrivilege }, { nonNullable: true }),
                activationDate: new LabeledFormControl(
                    'STE_LABEL_CARDHOLDER_ACTIVATIONDATE',
                    { value: cardholderModel.activationDate.toISOString(), disabled: !grantedPrivileges.changeCardholderStatusPrivilege },
                    {
                        nonNullable: true,
                        validators: activationDateValidator,
                    }
                ),
                expirationDate: new LabeledFormControl(
                    'STE_LABEL_CARDHOLDER_EXPIRATIONDATE',
                    { value: cardholderModel.expirationDate.toISOString(), disabled: !grantedPrivileges.changeCardholderStatusPrivilege },
                    {
                        nonNullable: true,
                        validators: expirationDateValidator,
                    }
                ),
                activationMode: new FormControl(
                    {
                        value: CardholderHelper.getActivationModeLabel(cardholderModel),
                        disabled: !grantedPrivileges.changeCardholderStatusPrivilege,
                    },
                    { nonNullable: true }
                ),
                expirationMode: new FormControl(
                    {
                        value: CardholderHelper.getExpirationModeLabel(cardholderModel),
                        disabled: !grantedPrivileges.changeCardholderStatusPrivilege,
                    },
                    { nonNullable: true }
                ),
                expirationDuration: new FormControl(
                    {
                        value: cardholderModel.expirationDuration ?? CardholderEditFormGroup.defaultExpirationDuration,
                        disabled: !grantedPrivileges.changeCardholderStatusPrivilege,
                    },
                    { nonNullable: true }
                ),
            }),

            accessRightsFormGroup: new FormGroup<AccessRightsFormGroup['controls']>({
                groups: new LabeledFormControl('', []),
                credentials: new LabeledFormControl('', []),
                rules: new LabeledFormControl('', []),
            }),

            advancedFormGroup: new FormGroup<AdvancedFormGroup['controls']>({
                useExtendedGrantTime: new FormControl(
                    { value: cardholderModel.useExtendedGrantTime, disabled: !grantedPrivileges.changeCardholderOptionsPrivilege },
                    { nonNullable: true }
                ),
                canEscortVisitors: new FormControl({ value: cardholderModel.canEscort, disabled: !grantedPrivileges.changeCardholderOptionsPrivilege }, { nonNullable: true }),
                displayName: new FormControl({ value: cardholderModel.name, disabled: !grantedPrivileges.modifyCardholderNamePrivilege && !isAddMode }, { nonNullable: true }),
                description: new FormControl(
                    { value: cardholderModel.description, disabled: !grantedPrivileges.modifyCardholderNamePrivilege && !isAddMode },
                    { nonNullable: true }
                ),
                logicalId: new FormControl(
                    { value: cardholderModel.logicalId, disabled: !grantedPrivileges.generalSettingsLogicalIdPrivilege },
                    {
                        nonNullable: false,
                        validators: [Validators.min(1), Validators.max(9999999)],
                    }
                ),
                bypassAntipassbackRules: new FormControl(
                    { value: CardholderHelper.getBypassAntipassbackRule(cardholderModel), disabled: !grantedPrivileges.changeCardholderOptionsPrivilege },
                    { nonNullable: true }
                ),
                securityClearance: new FormControl(
                    { value: cardholderModel.accessPermissionLevel, disabled: !grantedPrivileges.setCardholderSecurityClearancePrivilege },
                    { nonNullable: true }
                ),
                isSecurityClearanceInheritedFromGroup: new FormControl(
                    { value: cardholderModel.inheritAccessPermissionLevelFromGroup.toString(), disabled: !grantedPrivileges.setCardholderSecurityClearancePrivilege },
                    { nonNullable: true }
                ),
                partitions: new FormControl([]),
            }),
        });
    }
}
