import { IWebSocketRequest, TranscodingAllowance } from './Messages';
import { ILogger } from '../../utils/logger';
import { DesiredState, StateDifferences } from './DesiredState';
import { UpdateStreamRequest } from './Messages';
import { ISessionStateView, SessionState } from './SessionState';
import { PlayerMode } from '../../enums';
import { VideoFormat, SupportedVideoFormats } from './SupportedVideoFormats';

export interface IBuildUpdateStreamRequestResult {
  updateStreamRequest: IWebSocketRequest | null;
  desiredState: DesiredState;
}

export class UpdateStreamRequestBuilder {
  private readonly m_transcodingAllowance: TranscodingAllowance;

  private readonly m_logger: ILogger;

  private readonly m_channelId: number;

  private readonly m_supportedFormats: SupportedVideoFormats;

  constructor(logger: ILogger, channelId: number, transcodingAllowance: TranscodingAllowance, supportedFormats: SupportedVideoFormats) {
    this.m_logger = logger.subLogger('UpdateStreamRequestBuilder');
    this.m_channelId = channelId;
    this.m_supportedFormats = supportedFormats;
    this.m_transcodingAllowance = transcodingAllowance;
  }

  public build(serverState: SessionState, desiredState: DesiredState): IBuildUpdateStreamRequestResult {
    if (desiredState.isNoRequest) {
      this.m_logger.debug?.trace('No request');
      return { updateStreamRequest: null, desiredState: desiredState };
    }

    this.m_logger.debug?.trace('Building update stream request -> serverState: ', serverState, ' desiredState ', desiredState);

    const differences: StateDifferences = DesiredState.getDifferences(desiredState, serverState);

    // Was not started and is still not.
    if (serverState.playerMode === undefined && desiredState.playerMode === undefined) {
      //Handling the timeline range here so remove it from the desiredState we are going to return
      desiredState = desiredState.clearTimelineRange();

      if (differences.timelineRange !== undefined) {
        // Timeline updates are allowed when we have no state yet.
        const update = new UpdateStreamRequest(this.m_channelId, {
          timeLine: differences.timelineRange.toTimeLine(),
        });
        serverState.update(undefined, undefined, undefined, undefined, undefined, differences.timelineRange, undefined, undefined);
        this.m_logger.debug?.trace(`Timeline range request will be sent: ${differences.timelineRange}`);
        return { updateStreamRequest: update, desiredState };
      }

      this.m_logger.debug?.trace('Still no selection between live and playback. No command sent.');
      return { updateStreamRequest: null, desiredState };
    }

    // Things were requested but they are all equivalent to current state. Nothing to do
    if (differences.noDifference) {
      this.m_logger.debug?.trace('DesiredState did not change. No command sent.');
      return { updateStreamRequest: null, desiredState: DesiredState.Empty };
    }

    const videoFormatsRequested = this.determineRequestedVideoFormats(differences);
    const seekTimeRequested = this.getSeekTime(differences, serverState);
    const updateStreamRequest = new UpdateStreamRequest(this.m_channelId, {
      playerMode: differences.playerMode,
      playSpeed: differences.playSpeed,
      pause: differences.isPaused,
      audioEnabled: differences.audioEnabled,
      seekTime: seekTimeRequested,
      timeLine: differences.timelineRange?.toTimeLine(),
      supportedFormats: videoFormatsRequested,
      streamUsage: differences.streamUsage,
    });

    serverState.update(differences.playerMode, differences.playSpeed, differences.isPaused, differences.seekTime, differences.audioEnabled, differences.timelineRange, differences.isPtzMode, differences.streamUsage);

    return { updateStreamRequest: updateStreamRequest, desiredState: DesiredState.Empty };
  }

  private getSeekTime(differences: StateDifferences, serverState: SessionState): Date | undefined {
    if (differences.isPaused === true && differences.seekTime === undefined && serverState.playerMode === PlayerMode.live) {
      return serverState.getResumeLiveSeekTime();
    }
    return differences.seekTime;
  }

  private determineRequestedVideoFormats(differences: StateDifferences): SupportedVideoFormats | undefined {
    if (differences.isPtzMode === undefined) {
      return undefined;
    }

    if (this.m_transcodingAllowance === TranscodingAllowance.Never) {
      this.m_logger.debug?.trace('Cannot start ptz mode since Transcoding is not allowed on the server side');
      differences.isPtzMode = undefined;
      return undefined;
    }

    this.m_supportedFormats.setPtzMode(differences.isPtzMode);
    return this.m_supportedFormats;
  }

  public buildRestoreState(previousServerState: ISessionStateView) {
    this.m_logger.info?.trace('Reapply state:', previousServerState);
    switch (previousServerState.playerMode) {
      case PlayerMode.live:
        const supportedVideoFormats = previousServerState.isPtzMode ? new SupportedVideoFormats([VideoFormat.Mjpeg]) : this.m_supportedFormats;
        return new UpdateStreamRequest(this.m_channelId, { playerMode: PlayerMode.live, playSpeed: previousServerState.playSpeed, pause: previousServerState.isPaused, audioEnabled: previousServerState.isAudioEnabled, streamUsage: previousServerState.streamUsage, timeLine: previousServerState.timelineRange?.toTimeLine(), supportedFormats: supportedVideoFormats });
      case PlayerMode.playback:
        return new UpdateStreamRequest(this.m_channelId, { playerMode: PlayerMode.playback, playSpeed: previousServerState.playSpeed, pause: previousServerState.isPaused, seekTime: previousServerState.getRestorePlaybackSeekTime(), audioEnabled: previousServerState.isAudioEnabled, streamUsage: previousServerState.streamUsage, timeLine: previousServerState.timelineRange?.toTimeLine() });
      default:
        if (previousServerState.timelineRange !== undefined) {
          return new UpdateStreamRequest(this.m_channelId, { timeLine: previousServerState.timelineRange.toTimeLine() });
        }
        return null;
    }
  }
}
