import { Cancellation } from './CancellationToken';

export class PromiseCompletionSource<T> {
  private readonly m_promise: Promise<T>;
  private m_resolver: (arg: T) => void = () => {
    throw new Error('Constructor of PromiseCompletionSource failed');
  };

  public get Promise(): Promise<T> {
    return this.m_promise;
  }

  constructor() {
    this.m_promise = new Promise<T>((resolve) => this.m_resolver = resolve);
  }

  public resolve(arg: T): Promise<T> {
    this.m_resolver(arg);
    return new Promise((resolve) => setTimeout(resolve));
  }
}

export class PromiseCompletionSourceVoid extends PromiseCompletionSource<void> {

}

export class CancellablePromise<T> extends PromiseCompletionSource<T | Cancellation> {
  public cancel(reason?: string): void {
    this.resolve(new Cancellation(reason));
  }
}

//Prevent an error in the console by adding the catch before rejecting the promise
export function rejectedPromise<T>(errorMessage: string): Promise<T> {
  let rejecter: (reason?: any) => void = () => { };
  const p = new Promise<T>((_, reject) => {
    rejecter = reject;
  });
  p.catch(() => { });
  if (rejecter !== undefined) {
    rejecter(new Error(errorMessage));
  }
  return p;
}
