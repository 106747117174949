import moment from 'moment';

declare module 'moment' {
    interface Moment {
        /**
         * Get the min date based on utc and c# datetime
         */
        utcMinDate(): moment.Moment;

        /**
         * Get the max date based on utc and c# datetime
         */
        utcMaxDate(): moment.Moment;
    }
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
if (!moment.prototype.utcMinDate) {
    moment.prototype.utcMinDate = function () {
        return moment.utc('0001-01-01T00:00:00');
    };
}

if (!moment.prototype.utcMaxDate) {
    moment.prototype.utcMaxDate = function () {
        return moment.utc('9999-12-31T23:59:59');
    };
}
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
