import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Icon, IconSize, TextFlavor } from '@genetec/gelato';
import { GenMeltedModalComponent, GenToastService, MeltedIcon, MeltedModalAction } from '@genetec/gelato-angular';
import { GenMeltedItem } from '@genetec/gelato-angular/lib/interfaces/gen-melted-item';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { ConnectionAwareModalComponent } from '@modules/shared/components/tracked/connection-aware-modal.component';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { toInteger, toNumber } from 'lodash-es';
import moment from 'moment';
import { Coordinate } from 'RestClient/Client/Model/MapEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { DataRow, DataSchema, GeoCoordinate } from '../../api/api';
import { IngestionFieldContainer, IngestionFieldType, IngestionService } from '../../services/ingestion.service';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export const parseBase64FileAsync = (file: File): Promise<string | null> => {
    // Always return a Promise
    return new Promise((resolve, reject) => {
        let content = '';
        const reader = new FileReader();
        // Wait till complete
        reader.onloadend = (e: ProgressEvent<FileReader>) => {
            content = e.target?.result as string;
            if (content) {
                resolve(btoa(content));
            } else {
                resolve(null);
            }
        };
        // Make sure to handle error states
        reader.onerror = (e: ProgressEvent<FileReader>) => {
            reject(e);
        };
        reader.readAsBinaryString(file);
    });
};

@UntilDestroy()
@Component({
    selector: 'app-data-ingestion-modal',
    templateUrl: './data-ingestion-modal.component.html',
    styleUrls: ['./data-ingestion-modal.component.scss'],
})
export class IngestDataModalComponent extends ConnectionAwareModalComponent implements AfterViewInit, OnInit {
    //#region Fields

    @ViewChild(GenMeltedModalComponent)
    public genModalComponent!: GenMeltedModalComponent;

    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly TextFlavor = TextFlavor;

    public selectedRoleGuid: IGuid | null = null;
    public selectedSchema: DataSchema | null = null;
    public selectedTypeItem: ContextMenuItem | null = null;
    public types: GenMeltedItem[] = [];
    public sourceId: string = SafeGuid.newGuid().toString();
    public time: string | null = null;
    public location: Coordinate | null = null;
    public fields: Array<IngestionFieldContainer> | null = null;

    public advanced = false;
    public cannotSave = true;
    public isUpdate = false;

    private previousRecord: DataRow | null = null;

    //#endregion

    //#region Constructors

    constructor(
        injector: Injector,
        trackingService: TrackingService,
        authService: AuthService,
        private ingestionService: IngestionService,
        private toastService: GenToastService,
        private translateService: TranslateService,
        protected sanitizer: DomSanitizer,
        private loggerService: LoggerService
    ) {
        super(authService, injector, trackingService);

        // hook up to the modal send action
        this.genModalAction.pipe(untilDestroyed(this)).subscribe((modalAction: MeltedModalAction) => this.onSubmit(modalAction));
    }

    //#endregion

    //#region Methods

    public ngOnInit() {
        super.ngOnInit();
    }

    public async ngAfterViewInit() {
        super.ngAfterViewInit();
        // populate the types choices
        const types = await this.ingestionService.listTypes();
        if (types) {
            this.types = [];

            types.forEach((element) => {
                const id = element.roleGuid.toString() + '_' + element.dataType.toString();
                const text = element.name;
                const subText = `(${element.roleName ?? ''})`;

                // TODO: sanitize the image below within Gelato?
                if (element.customIcon?.startsWith('gelato:')) {
                    this.types?.push({
                        id,
                        text,
                        secondaryText: subText,
                        icon: element.customIcon.substring('gelato:'.length) as MeltedIcon,
                    });
                } else if (element.customIcon) {
                    this.types?.push({
                        id,
                        text,
                        secondaryText: subText,
                        image: this.sanitizer.bypassSecurityTrustUrl(element.customIcon) as string,
                    });
                } else {
                    this.types?.push({
                        id,
                        text,
                        icon: MeltedIcon.CorrelationService,
                        secondaryText: subText,
                    });
                }
            });
        }
    }

    public setPreviousRecord(row: DataRow): void {
        this.previousRecord = row;
        this.isUpdate = true;
        this.updateSchemaChanges();
    }

    public clearPreviousRecord(): void {
        this.previousRecord = null;
        this.isUpdate = false;
        this.updateSchemaChanges();
    }

    public getDefaultValue(fieldType: IngestionFieldType): string | number | boolean | IGuid {
        switch (fieldType) {
            case IngestionFieldType.String:
            case IngestionFieldType.Base64Binary:
            case IngestionFieldType.LargeString:
            case IngestionFieldType.File:
                return '';
            case IngestionFieldType.Integer:
                return 0;
            case IngestionFieldType.Long:
                return 0;
            case IngestionFieldType.Double:
                return 0.0;
            case IngestionFieldType.Boolean:
                return true;
            case IngestionFieldType.Entity:
                return SafeGuid.EMPTY;
            case IngestionFieldType.DateTime:
                return moment().toISOString();
        }
    }

    public getFormattedValue(fieldType: IngestionFieldType, map: { [key: string]: string }, key: string): string | number | boolean | IGuid {
        const value = map[key];
        if (!value) {
            return this.getDefaultValue(fieldType);
        }
        switch (fieldType) {
            case IngestionFieldType.String:
            case IngestionFieldType.Base64Binary:
            case IngestionFieldType.LargeString:
            case IngestionFieldType.File:
                return value;
            case IngestionFieldType.Integer:
            case IngestionFieldType.Long:
                return toInteger(value);
            case IngestionFieldType.Double:
                return toNumber(value);
            case IngestionFieldType.Boolean:
                return value.toLowerCase() === 'true';
            case IngestionFieldType.Entity:
                return SafeGuid.parse(value);
            case IngestionFieldType.DateTime:
                return moment(value).toISOString();
            default:
                return this.getDefaultValue(fieldType);
        }
    }

    public getInstance(fieldType: string): IngestionFieldType {
        if (fieldType === 'Integer') {
            return IngestionFieldType.Integer;
        }
        if (fieldType === 'Long') {
            return IngestionFieldType.Long;
        }
        if (fieldType === 'Double') {
            return IngestionFieldType.Double;
        }
        if (fieldType === 'Boolean') {
            return IngestionFieldType.Boolean;
        }
        if (fieldType === 'Entity') {
            return IngestionFieldType.Entity;
        }
        if (fieldType === 'Base64Binary') {
            return IngestionFieldType.Base64Binary;
        }
        if (fieldType === 'LargeString') {
            return IngestionFieldType.LargeString;
        }
        if (fieldType === 'File') {
            return IngestionFieldType.File;
        }
        if (fieldType === 'DateTime') {
            return IngestionFieldType.DateTime;
        }
        return IngestionFieldType.String;
    }

    //#endregion

    //#region Events

    public async onSubmit(modalAction: MeltedModalAction): Promise<void> {
        if (modalAction === MeltedModalAction.Default) {
            let saved = true;
            try {
                const record = new DataRow({
                    roleId: this.selectedRoleGuid?.isEmpty() === false ? this.selectedRoleGuid : SafeGuid.EMPTY,
                    dataType: this.selectedSchema?.dataType ?? SafeGuid.EMPTY,
                    id: this.sourceId,
                });

                // save clicked
                if (this.previousRecord) {
                    // is update
                    record.id = this.sourceId;
                    if (this.selectedRoleGuid?.isEmpty() === false) {
                        record.roleId = this.selectedRoleGuid;
                    }
                    if (this.selectedSchema) {
                        record.dataType = this.selectedSchema.dataType;
                    }

                    record.time = null;
                    if (this.time) {
                        const mmt = moment(this.time).utc();
                        if (this.previousRecord.time) {
                            const previousMmt = moment(this.previousRecord.time);
                            if (previousMmt !== mmt) {
                                // the time has changed, update it
                                record.time = mmt.toISOString();
                            }
                        }
                    }

                    record.location = new GeoCoordinate({ latitude: 0, longitude: 0 });
                    if (this.location && Math.abs(this.location.latitude) > 0 && Math.abs(this.location.longitude) > 0) {
                        if (this.location.latitude !== this.previousRecord.location?.latitude && this.location.longitude !== this.previousRecord.location?.longitude) {
                            // location has changed, update the record
                            record.location.latitude = this.location.latitude;
                            record.location.longitude = this.location.longitude;
                        }
                    }

                    record.otherFields = {};
                    if (this.fields) {
                        for (const field of this.fields) {
                            switch (field.type) {
                                case IngestionFieldType.String:
                                case IngestionFieldType.LargeString:
                                case IngestionFieldType.Integer:
                                case IngestionFieldType.Long:
                                case IngestionFieldType.Double:
                                case IngestionFieldType.Boolean:
                                case IngestionFieldType.Entity:
                                    {
                                        const value = field.value;
                                        if (value?.toString()) {
                                            record.otherFields[field.display] = value.toString();
                                        }
                                    }
                                    break;
                                case IngestionFieldType.Base64Binary:
                                case IngestionFieldType.File:
                                    {
                                        const fileList = field.value;
                                        if (fileList) {
                                            const content = await parseBase64FileAsync(fileList[0]);
                                            if (content) {
                                                record.otherFields[field.display] = content;
                                            }
                                        }
                                    }
                                    break;
                                case IngestionFieldType.DateTime:
                                    {
                                        if (field.value) {
                                            const item = field.value;
                                            if (item) {
                                                const mmt = moment(item).utc().toISOString();
                                                record.otherFields[field.display] = mmt;
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }

                    saved = await this.ingestionService.updateData(record);
                } else {
                    // is new record (i.e. create operation)
                    record.id = this.sourceId;
                    if (this.selectedRoleGuid?.isEmpty() === false) {
                        record.roleId = this.selectedRoleGuid;
                    }
                    if (this.selectedSchema) {
                        record.dataType = this.selectedSchema.dataType;
                    }

                    record.time = null;
                    if (this.time) {
                        record.time = moment(this.time).utc().toISOString();
                    }
                    record.location = new GeoCoordinate({ latitude: 0, longitude: 0 });

                    if (this.location && Math.abs(this.location.latitude) > 0 && Math.abs(this.location.longitude) > 0) {
                        record.location.latitude = this.location.latitude;
                        record.location.longitude = this.location.longitude;
                    }
                    record.otherFields = {};
                    if (this.fields) {
                        for (const field of this.fields) {
                            switch (field.type) {
                                case IngestionFieldType.String:
                                case IngestionFieldType.LargeString:
                                case IngestionFieldType.Integer:
                                case IngestionFieldType.Long:
                                case IngestionFieldType.Double:
                                case IngestionFieldType.Boolean:
                                case IngestionFieldType.Entity:
                                    {
                                        const value = field.value;
                                        if (value?.toString()) {
                                            record.otherFields[field.display] = value.toString();
                                        }
                                    }
                                    break;
                                case IngestionFieldType.Base64Binary:
                                case IngestionFieldType.File:
                                    {
                                        const fileList = field.value;
                                        if (fileList) {
                                            const content = await parseBase64FileAsync(fileList[0]);
                                            if (content) {
                                                record.otherFields[field.display] = content;
                                            }
                                        }
                                    }
                                    break;
                                case IngestionFieldType.DateTime:
                                    {
                                        if (field.value) {
                                            const item = field.value;
                                            if (item) {
                                                const mmt = moment(item).utc().toISOString();
                                                record.otherFields[field.display] = mmt;
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    // fields are set, fire off the record
                    saved = await this.ingestionService.addData(record);
                }
            } catch (err) {
                saved = false;
                if (this.loggerService) {
                    this.loggerService.traceError(err);
                }
            }
            if (!saved) {
                const notification = this.translateService.instant('STE_MESSAGE_ERROR_DATA_SAVE_FAILED') as string;
                this.toastService.show({ text: notification, flavorCustomColor: this.Color.Fragola });
            } else {
                const notification = this.translateService.instant('STE_MESSAGE_INFO_DATA_SAVED') as string;
                this.toastService.show({ text: notification, flavorCustomColor: this.Color.Pistacchio });
            }
        }
    }

    public async onSelectedTypeChanged(newType: ContextMenuItem): Promise<void> {
        this.selectedSchema = null;
        this.selectedRoleGuid = null;

        if (newType?.id) {
            const parts = newType.id.split('_', 2);
            if (parts.length === 2) {
                const roleGuid = SafeGuid.parse(parts[0]);
                const typeGuid = SafeGuid.parse(parts[1]);
                const schema = await this.ingestionService.getSchema(roleGuid, typeGuid);
                this.selectedRoleGuid = roleGuid;
                this.selectedSchema = schema;
                this.updateSchemaChanges();
            }
        }
    }

    public toggleAdvancedSection(event: MouseEvent): void {
        this.advanced = !this.advanced;
    }

    public regenerateId(event: MouseEvent): void {
        this.sourceId = SafeGuid.newGuid().toString();
    }

    public onValueChanged(): void {
        this.cannotSave = false;
    }

    //#endregion

    //#region Private Methods

    private updateSchemaChanges() {
        this.fields = null;
        if (this.selectedSchema) {
            // re-disable the save ability
            this.cannotSave = true;

            // check & set time
            if (this.selectedSchema.timeField) {
                if (this.previousRecord?.time) {
                    this.time = moment(this.previousRecord.time).toISOString();
                } else {
                    this.time = moment().toISOString();
                }
            } else {
                this.time = null;
            }

            // check and set the location
            if (!this.selectedSchema?.locationFields) {
                this.location = null;
            } else if (this.selectedSchema.locationFields.length === 0) {
                this.location = null;
            } else if (this.location == null) {
                this.location = new Coordinate();
                this.location.initializeAllFields();
            }

            // check and set the fields array
            this.fields = [];
            this.selectedSchema.fields.forEach((element) => {
                if (this.selectedSchema?.idField) {
                    if (element.name === this.selectedSchema.idField) {
                        // ignore this field
                        return;
                    }
                }
                if (this.selectedSchema?.timeField) {
                    if (element.name === this.selectedSchema.timeField) {
                        // ignore this field
                        return;
                    }
                }
                if (this.selectedSchema?.locationFields) {
                    if (
                        this.selectedSchema.locationFields.some((fieldName) => {
                            return fieldName === element.name;
                        })
                    ) {
                        // ignore this field (indexed location field)
                        return;
                    }
                }

                const type = this.getInstance(element.type);
                if (this.fields) {
                    if (this.previousRecord?.otherFields) {
                        // we're reloading an existing record (for edit). Load the appropriate fields
                        let value: string | IGuid = this.previousRecord.otherFields[element.name];
                        if (!value) {
                            value = this.getDefaultValue(type) as string | IGuid;
                        } else if (type === IngestionFieldType.Entity) {
                            const guid = SafeGuid.tryParse(value);
                            if (guid.success) {
                                value = guid.value;
                            }
                        }
                        this.fields?.push({
                            display: element.name,
                            value: this.getFormattedValue(type, this.previousRecord.otherFields, element.name) as string,
                            type,
                            id: SafeGuid.newGuid().toString(),
                        } as IngestionFieldContainer);
                    } else {
                        this.fields?.push({
                            display: element.name,
                            value: this.getDefaultValue(type),
                            type,
                            id: SafeGuid.newGuid().toString(),
                        } as IngestionFieldContainer);
                    }
                }
            });
        }
    }

    //#endregion
}
