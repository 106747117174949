import { Injectable } from '@angular/core';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { IEntity } from 'RestClient/Client/Interface/IEntity';
import { IPartitionManager } from 'RestClient/Client/Interface/IPartitionManager';
import { PartitionManager } from 'RestClient/Client/Managers/PartitionManager';
import { IGuid } from 'safeguid';
import { WebAppClient } from 'WebClient/WebAppClient';
import { SecurityCenterClientService } from '../../../security-center/services/client/security-center-client.service';

@Injectable()
export class PrivilegeService {
    private scClient: WebAppClient;

    constructor(securityCenterProvider: SecurityCenterClientService) {
        this.scClient = securityCenterProvider.scClient;
    }

    /**
     * Gets all the partitions for which the privilegeId is enabled.
     * Guids.SystemPartition (System partition) is removed from the list. This partition is not meaningful, because it is always there even when the privilege is disabled for all the partitions.
     * The only time System partition is not in the list is when the privilege is globally disabled.
     *
     * @param privilegeId IGuid of the privilege
     * @returns List of the partitions for which the privilege is enabled
     */
    public async getGrantedPrivilegedPartitionsAsync(privilegeId: IGuid): Promise<IEntity[]> {
        const partitionManager = await this.scClient.getAsync<PartitionManager, IPartitionManager>(PartitionManager);
        const partitions = await partitionManager.getGrantedPrivilegedPartitionsAsync(privilegeId);
        return partitions?.filter((partition) => partition.id !== Guids.SystemPartition) ?? [];
    }

    public isGlobalPrivilegeGranted(privilegeId: IGuid): boolean {
        return this.scClient.isGlobalPrivilegeGranted(privilegeId);
    }
}
