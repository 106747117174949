// Autogenerated: CodeGen/HierarchicalData?language=ts&InterfaceOnly=True on 2020-11-16 11:14:13 AM
// Autogenerated: CodeGen/HierarchicalData?language=ts&InterfaceOnly=False on 2020-11-16 11:14:13 AM
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { FieldObjectOverloads } from 'RestClient/Helpers/FieldObjectOverloads';

export interface IHierarchicalData extends IFieldObject {
    overloadTypeName: string;
}

export class HierarchicalData extends FieldObject implements IHierarchicalData {
    // OverloadTypeName
    public get overloadTypeName(): string {
        return this.getField<string>('OverloadTypeName');
    }
    public set overloadTypeName(value: string) {
        this.setField<string>('OverloadTypeName', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('OverloadTypeName', '');
    }

    // #region Custom Methods

    constructor() {
        super();
        this.overloadTypeName = 'HierarchicalData';
    }

    public getOverload<T extends HierarchicalData>(): T {
        if (this.overloadTypeName && this.overloadTypeName !== 'HierarchicalData') {
            const constructor = FieldObjectOverloads.getConstructor(this.overloadTypeName);
            if (constructor) {
                const overload = new constructor();
                overload.loadFrom(this);
                return overload as T;
            }
        }

        return (this as unknown) as T;
    }

    // #endregion Custom Methods
}
