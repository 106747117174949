// Autogenerated: v2/help/Entities/ApiIntrusionArea?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:06 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';
import { IGuid } from 'safeguid';
import { IFieldObject } from './IFieldObject';
import { IRestResponse } from './IRestResponse';

// #region Fields

export class IntrusionAreaEntityFields extends EntityFields {
    public static alarmStateField = 'AlarmState';
    public static alarmTriggeringInputField = 'AlarmTriggeringInput';
    public static alarmTriggeringOutputField = 'AlarmTriggeringOutput';
    public static armingStateField = 'ArmingState';
    public static capabilitiesField = 'Capabilities';
    public static extensionStatusField = 'ExtensionStatus';
    public static inputActiveField = 'InputActive';
    public static inputTroubleField = 'InputTrouble';
    public static intrusionUnitField = 'IntrusionUnit';
    public static isArmedField = 'IsArmed';
    public static isBypassedField = 'IsBypassed';
    public static physicalNameField = 'PhysicalName';
    public static synergisAreaField = 'SynergisArea';
}

// #endregion Fields

// #region Inner classes

export interface IArmData extends IFieldObject {
    scope: string;
    timing: string;
    type: string;
    customDelay: number;
}

// #endregion Inner classes

export interface IIntrusionAreaEntity extends IEntity {
    // #region REST Properties

    alarmState: string;

    alarmTriggeringInput: IGuid;

    alarmTriggeringOutput: IGuid;

    armingState: string;

    readonly capabilities: string;

    extensionStatus: string;

    readonly inputActive: boolean;

    readonly inputTrouble: boolean;

    intrusionUnit: IGuid;

    readonly isArmed: boolean;

    readonly isBypassed: boolean;

    physicalName: string;

    synergisArea: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    acknowledgeIntrusionAlarmAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    armZoneAsync(value: IArmData, transaction?: ITransaction): Promise<IRestResponse | null>;
    cancelPostponeArmingAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    disarmZoneAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getInputsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    postponeArmingAsync(delay: number, masterArm: boolean, armingDelay: number, transaction?: ITransaction): Promise<IRestResponse | null>;
    silenceIntrusionAlarmAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    triggerIntrusionAlarmAsync(transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

