import { VertexShader } from './VertexShader';
import { FragmentShader } from './FragmentShader';
import { Uniform1f, Uniform2f, Uniform3f, Uniform4f } from './Uniform';
import { Buffer } from './Buffer';
import { Texture } from './Texture';
import { ProgramLinker } from './ProgramLinker';
import { CompiledProgram } from './CompiledProgram';

export class Program {
  protected readonly m_gl: WebGL2RenderingContext;
  protected readonly m_compiledProgram: CompiledProgram;

  private get Program(): WebGLProgram {
    return this.m_compiledProgram.Program;
  }

  public get gl(): WebGL2RenderingContext {
    return this.m_gl;
  }

  constructor(gl: WebGL2RenderingContext, vertexShader: VertexShader, fragmentShader: FragmentShader) {
    this.m_gl = gl;
    this.m_compiledProgram = ProgramLinker.link(gl, vertexShader, fragmentShader);
  }

  public dispose() {
    this.m_compiledProgram.dispose();
  }

  public getAttributeLocation(attribute: string): number {
    return this.m_gl.getAttribLocation(this.Program, attribute);
  }

  public createWebGLBuffer(): WebGLBuffer {
    const buffer = this.m_gl.createBuffer();
    if (buffer === null) {
      throw Error('Failed to create buffer');
    }

    return buffer;
  }

  public deleteBuffer(buffer: WebGLBuffer) {
    this.m_gl.deleteBuffer(buffer);
  }

  protected buildUniform1f(uniformName: string): Uniform1f {
    return new Uniform1f(this.m_gl, this.Program, this.getUniformLocation(uniformName));
  }

  protected buildUniform2f(uniformName: string): Uniform2f {
    return new Uniform2f(this.m_gl, this.Program, this.getUniformLocation(uniformName));
  }

  protected buildUniform3f(uniformName: string): Uniform3f {
    return new Uniform3f(this.m_gl, this.Program, this.getUniformLocation(uniformName));
  }

  protected buildUniform4f(uniformName: string): Uniform4f {
    return new Uniform4f(this.m_gl, this.Program, this.getUniformLocation(uniformName));
  }

  private getUniformLocation(uniformName: string): WebGLUniformLocation {
    const uniformLocation = this.m_gl.getUniformLocation(this.Program, uniformName);
    if (uniformLocation === null) {
      console.error(this.m_compiledProgram.VertexShaderSource);
      console.error(this.m_compiledProgram.FragmentShaderSource);
      throw Error(`Could not get uniform location for ${uniformName}`);
    }

    return uniformLocation;
  }

  protected createTexture(): Texture {
    return new Texture(this.m_gl, this.createBuffer());
  }

  private createBuffer(): Buffer {
    return new Buffer(this);
  }
}
