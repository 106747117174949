// Autogenerated: v2/help/Entities/ApiUser?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:12:21 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { PrivilegedEntity } from './PrivilegedEntity';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { IUserEntity, IWebClientTask, IUserSetting, ILogOnSchedule, IHotAction, IHasAccessResult, UserEntityFields } from '../Interface/IUserEntity';
import { ITransaction } from '../Interface/ITransaction';
import { IQueryFilter } from '../Interface/IQueryFilter';

// #region Inner classes

export class WebClientTask extends FieldObject implements IWebClientTask {
    // Path
    public get path(): string {
        return this.getField<string>('Path');
    }
    public set path(value: string) {
        this.setField<string>('Path', value);
    }
    // Guid
    public get guid(): IGuid {
        return this.getFieldGuid('Guid');
    }
    public set guid(value: IGuid) {
        this.setFieldGuid('Guid', value);
    }
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // TaskType
    public get taskType(): IGuid {
        return this.getFieldGuid('TaskType');
    }
    public set taskType(value: IGuid) {
        this.setFieldGuid('TaskType', value);
    }
    // TaskSubType
    public get taskSubType(): IGuid {
        return this.getFieldGuid('TaskSubType');
    }
    public set taskSubType(value: IGuid) {
        this.setFieldGuid('TaskSubType', value);
    }
    // Context
    public get context(): string {
        return this.getField<string>('Context');
    }
    public set context(value: string) {
        this.setField<string>('Context', value);
    }
    // IsSystemTask
    public get isSystemTask(): boolean {
        return this.getField<boolean>('IsSystemTask');
    }
    public set isSystemTask(value: boolean) {
        this.setField<boolean>('IsSystemTask', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Path', '');
        this.setFieldGuid('Guid', SafeGuid.EMPTY);
        this.setField<string>('Name', '');
        this.setFieldGuid('TaskType', SafeGuid.EMPTY);
        this.setFieldGuid('TaskSubType', SafeGuid.EMPTY);
        this.setField<string>('Context', '');
        this.setField<boolean>('IsSystemTask', false);
    }
}

export class UserSetting extends FieldObject implements IUserSetting {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Value
    public get value(): string {
        return this.getField<string>('Value');
    }
    public set value(value: string) {
        this.setField<string>('Value', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Value', '');
    }
}

export class LogOnSchedule extends FieldObject implements ILogOnSchedule {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Allowed
    public get allowed(): boolean {
        return this.getField<boolean>('Allowed');
    }
    public set allowed(value: boolean) {
        this.setField<boolean>('Allowed', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('Allowed', false);
    }
}

export class HotAction extends FieldObject implements IHotAction {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Description
    public get description(): string {
        return this.getField<string>('Description');
    }
    public set description(value: string) {
        this.setField<string>('Description', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Description', '');
    }
}

export class HasAccessResult extends FieldObject implements IHasAccessResult {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // HasAccess
    public get hasAccess(): boolean {
        return this.getField<boolean>('HasAccess');
    }
    public set hasAccess(value: boolean) {
        this.setField<boolean>('HasAccess', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('HasAccess', false);
    }
}

// #endregion Inner classes

export class UserEntity extends PrivilegedEntity implements IUserEntity {
    // #region REST Properties

    // AppendMapToIncidentContent
    public get appendMapToIncidentContent(): boolean {
        return this.getField<boolean>(UserEntityFields.appendMapToIncidentContentField, ValidFlags.SpecificExtra);
    }
    public set appendMapToIncidentContent(value: boolean) {
        this.setField<boolean>(UserEntityFields.appendMapToIncidentContentField, value);
    }

    // AreIncidentMonitoringTasksSynchronized
    public get areIncidentMonitoringTasksSynchronized(): boolean {
        return this.getField<boolean>(UserEntityFields.areIncidentMonitoringTasksSynchronizedField, ValidFlags.SpecificExtra);
    }
    public set areIncidentMonitoringTasksSynchronized(value: boolean) {
        this.setField<boolean>(UserEntityFields.areIncidentMonitoringTasksSynchronizedField, value);
    }

    // AutoLogOffIsActivated
    public get autoLogOffIsActivated(): boolean {
        return this.getField<boolean>(UserEntityFields.autoLogOffIsActivatedField, ValidFlags.Specific);
    }
    public set autoLogOffIsActivated(value: boolean) {
        this.setField<boolean>(UserEntityFields.autoLogOffIsActivatedField, value);
    }

    // AutomaticallyChangeIncidentState
    public get automaticallyChangeIncidentState(): boolean {
        return this.getField<boolean>(UserEntityFields.automaticallyChangeIncidentStateField, ValidFlags.SpecificExtra);
    }
    public set automaticallyChangeIncidentState(value: boolean) {
        this.setField<boolean>(UserEntityFields.automaticallyChangeIncidentStateField, value);
    }

    // DefaultMapId
    public get defaultMapId(): IGuid {
        return this.getFieldGuid(UserEntityFields.defaultMapIdField, ValidFlags.SpecificExtra);
    }
    public set defaultMapId(value: IGuid) {
        this.setFieldGuid(UserEntityFields.defaultMapIdField, value);
    }

    // DefaultMeasurementSystem
    public get defaultMeasurementSystem(): string {
        return this.getField<string>(UserEntityFields.defaultMeasurementSystemField, ValidFlags.SpecificExtra);
    }
    public set defaultMeasurementSystem(value: string) {
        this.setField<string>(UserEntityFields.defaultMeasurementSystemField, value);
    }

    // FirstName
    public get firstName(): string {
        return this.getField<string>(UserEntityFields.firstNameField, ValidFlags.Specific);
    }
    public set firstName(value: string) {
        this.setField<string>(UserEntityFields.firstNameField, value);
    }

    // HideAreaViewForIncident
    public get hideAreaViewForIncident(): boolean {
        return this.getField<boolean>(UserEntityFields.hideAreaViewForIncidentField, ValidFlags.SpecificExtra);
    }
    public set hideAreaViewForIncident(value: boolean) {
        this.setField<boolean>(UserEntityFields.hideAreaViewForIncidentField, value);
    }

    // IncidentContentSpecificScreen
    public get incidentContentSpecificScreen(): number {
        return this.getField<number>(UserEntityFields.incidentContentSpecificScreenField, ValidFlags.SpecificExtra);
    }
    public set incidentContentSpecificScreen(value: number) {
        this.setField<number>(UserEntityFields.incidentContentSpecificScreenField, value);
    }

    // IncludeAuditMetadata
    public get includeAuditMetadata(): boolean {
        return this.getField<boolean>(UserEntityFields.includeAuditMetadataField, ValidFlags.SpecificExtra);
    }

    // IsActive
    public get isActive(): boolean {
        return this.getField<boolean>(UserEntityFields.isActiveField, ValidFlags.Specific);
    }
    public set isActive(value: boolean) {
        this.setField<boolean>(UserEntityFields.isActiveField, value);
    }

    // IsDomainDisplayed
    public get isDomainDisplayed(): boolean {
        return this.getField<boolean>(UserEntityFields.isDomainDisplayedField, ValidFlags.SpecificExtra);
    }

    // IsOptionsAlreadyImported
    public get isOptionsAlreadyImported(): boolean {
        return this.getField<boolean>(UserEntityFields.isOptionsAlreadyImportedField, ValidFlags.SpecificExtra);
    }
    public set isOptionsAlreadyImported(value: boolean) {
        this.setField<boolean>(UserEntityFields.isOptionsAlreadyImportedField, value);
    }

    // IsPrivateTasksSortedByType
    public get isPrivateTasksSortedByType(): boolean {
        return this.getField<boolean>(UserEntityFields.isPrivateTasksSortedByTypeField, ValidFlags.SpecificExtra);
    }
    public set isPrivateTasksSortedByType(value: boolean) {
        this.setField<boolean>(UserEntityFields.isPrivateTasksSortedByTypeField, value);
    }

    // IsPublicTasksSortedByType
    public get isPublicTasksSortedByType(): boolean {
        return this.getField<boolean>(UserEntityFields.isPublicTasksSortedByTypeField, ValidFlags.SpecificExtra);
    }
    public set isPublicTasksSortedByType(value: boolean) {
        this.setField<boolean>(UserEntityFields.isPublicTasksSortedByTypeField, value);
    }

    // LastMobileApplicationId
    public get lastMobileApplicationId(): string {
        return this.getField<string>(UserEntityFields.lastMobileApplicationIdField, ValidFlags.SpecificExtra);
    }
    public set lastMobileApplicationId(value: string) {
        this.setField<string>(UserEntityFields.lastMobileApplicationIdField, value);
    }

    // LastName
    public get lastName(): string {
        return this.getField<string>(UserEntityFields.lastNameField, ValidFlags.Specific);
    }
    public set lastName(value: string) {
        this.setField<string>(UserEntityFields.lastNameField, value);
    }

    // LastSelectedBadgeTemplate
    public get lastSelectedBadgeTemplate(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedBadgeTemplateField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedBadgeTemplate(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedBadgeTemplateField, value);
    }

    // LastSelectedBypassAntipassbackRulesOption
    public get lastSelectedBypassAntipassbackRulesOption(): boolean {
        return this.getField<boolean>(UserEntityFields.lastSelectedBypassAntipassbackRulesOptionField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedBypassAntipassbackRulesOption(value: boolean) {
        this.setField<boolean>(UserEntityFields.lastSelectedBypassAntipassbackRulesOptionField, value);
    }

    // LastSelectedCameraForSnapshot
    public get lastSelectedCameraForSnapshot(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedCameraForSnapshotField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedCameraForSnapshot(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedCameraForSnapshotField, value);
    }

    // LastSelectedCardholderGroup
    public get lastSelectedCardholderGroup(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedCardholderGroupField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedCardholderGroup(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedCardholderGroupField, value);
    }

    // LastSelectedCredentialConfiguration
    public get lastSelectedCredentialConfiguration(): number {
        return this.getField<number>(UserEntityFields.lastSelectedCredentialConfigurationField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedCredentialConfiguration(value: number) {
        this.setField<number>(UserEntityFields.lastSelectedCredentialConfigurationField, value);
    }

    // LastSelectedCredentialFormat
    public get lastSelectedCredentialFormat(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedCredentialFormatField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedCredentialFormat(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedCredentialFormatField, value);
    }

    // LastSelectedPartition
    public get lastSelectedPartition(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedPartitionField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedPartition(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedPartitionField, value);
    }

    // LastSelectedSaveAsPublicTask
    public get lastSelectedSaveAsPublicTask(): boolean {
        return this.getField<boolean>(UserEntityFields.lastSelectedSaveAsPublicTaskField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedSaveAsPublicTask(value: boolean) {
        this.setField<boolean>(UserEntityFields.lastSelectedSaveAsPublicTaskField, value);
    }

    // LastSelectedTemporaryCardExpiration
    public get lastSelectedTemporaryCardExpiration(): number {
        return this.getField<number>(UserEntityFields.lastSelectedTemporaryCardExpirationField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedTemporaryCardExpiration(value: number) {
        this.setField<number>(UserEntityFields.lastSelectedTemporaryCardExpirationField, value);
    }

    // LastSelectedUseExtendedGrantTimeOption
    public get lastSelectedUseExtendedGrantTimeOption(): boolean {
        return this.getField<boolean>(UserEntityFields.lastSelectedUseExtendedGrantTimeOptionField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedUseExtendedGrantTimeOption(value: boolean) {
        this.setField<boolean>(UserEntityFields.lastSelectedUseExtendedGrantTimeOptionField, value);
    }

    // LastSelectedVisitorEscort
    public get lastSelectedVisitorEscort(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedVisitorEscortField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedVisitorEscort(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedVisitorEscortField, value);
    }

    // LastSelectedVisitorEscort2
    public get lastSelectedVisitorEscort2(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedVisitorEscort2Field, ValidFlags.SpecificExtra);
    }
    public set lastSelectedVisitorEscort2(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedVisitorEscort2Field, value);
    }

    // LastSelectedVisitorEscortMandatory
    public get lastSelectedVisitorEscortMandatory(): boolean {
        return this.getField<boolean>(UserEntityFields.lastSelectedVisitorEscortMandatoryField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedVisitorEscortMandatory(value: boolean) {
        this.setField<boolean>(UserEntityFields.lastSelectedVisitorEscortMandatoryField, value);
    }

    // LastSelectedVisitorExpirationTime
    public get lastSelectedVisitorExpirationTime(): number {
        return this.getField<number>(UserEntityFields.lastSelectedVisitorExpirationTimeField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedVisitorExpirationTime(value: number) {
        this.setField<number>(UserEntityFields.lastSelectedVisitorExpirationTimeField, value);
    }

    // LastSelectedVisitorGroup
    public get lastSelectedVisitorGroup(): IGuid {
        return this.getFieldGuid(UserEntityFields.lastSelectedVisitorGroupField, ValidFlags.SpecificExtra);
    }
    public set lastSelectedVisitorGroup(value: IGuid) {
        this.setFieldGuid(UserEntityFields.lastSelectedVisitorGroupField, value);
    }

    // MaxConcurrentLogons
    public get maxConcurrentLogons(): number {
        return this.getField<number>(UserEntityFields.maxConcurrentLogonsField, ValidFlags.Specific);
    }
    public set maxConcurrentLogons(value: number) {
        this.setField<number>(UserEntityFields.maxConcurrentLogonsField, value);
    }

    // MeasurementSystem
    public get measurementSystem(): string {
        return this.getField<string>(UserEntityFields.measurementSystemField, ValidFlags.SpecificExtra);
    }
    public set measurementSystem(value: string) {
        this.setField<string>(UserEntityFields.measurementSystemField, value);
    }

    // MinumumIncidentPriorityInTray
    public get minumumIncidentPriorityInTray(): IGuid {
        return this.getFieldGuid(UserEntityFields.minumumIncidentPriorityInTrayField, ValidFlags.SpecificExtra);
    }
    public set minumumIncidentPriorityInTray(value: IGuid) {
        this.setFieldGuid(UserEntityFields.minumumIncidentPriorityInTrayField, value);
    }

    // NeedChangePassword
    public get needChangePassword(): boolean {
        return this.getField<boolean>(UserEntityFields.needChangePasswordField, ValidFlags.Specific);
    }
    public set needChangePassword(value: boolean) {
        this.setField<boolean>(UserEntityFields.needChangePasswordField, value);
    }

    // ObfuscateEntityNames
    public get obfuscateEntityNames(): boolean {
        return this.getField<boolean>(UserEntityFields.obfuscateEntityNamesField, ValidFlags.Specific);
    }
    public set obfuscateEntityNames(value: boolean) {
        this.setField<boolean>(UserEntityFields.obfuscateEntityNamesField, value);
    }

    // OpenIncidentContentInSpecificScreen
    public get openIncidentContentInSpecificScreen(): boolean {
        return this.getField<boolean>(UserEntityFields.openIncidentContentInSpecificScreenField, ValidFlags.SpecificExtra);
    }
    public set openIncidentContentInSpecificScreen(value: boolean) {
        this.setField<boolean>(UserEntityFields.openIncidentContentInSpecificScreenField, value);
    }

    // Password
    public get password(): string {
        return this.getField<string>(UserEntityFields.passwordField, ValidFlags.Specific);
    }
    public set password(value: string) {
        this.setField<string>(UserEntityFields.passwordField, value);
    }

    // PasswordExpiration
    public get passwordExpiration(): number {
        return this.getField<number>(UserEntityFields.passwordExpirationField, ValidFlags.Specific);
    }
    public set passwordExpiration(value: number) {
        this.setField<number>(UserEntityFields.passwordExpirationField, value);
    }

    // PasswordLastModified
    public get passwordLastModified(): Date {
        return this.getFieldDate(UserEntityFields.passwordLastModifiedField, ValidFlags.Specific);
    }

    // QueryUsingDeviceTimeZones
    public get queryUsingDeviceTimeZones(): boolean {
        return this.getField<boolean>(UserEntityFields.queryUsingDeviceTimeZonesField, ValidFlags.SpecificExtra);
    }

    // ShowAllIncidentsOnDefaultMap
    public get showAllIncidentsOnDefaultMap(): boolean {
        return this.getField<boolean>(UserEntityFields.showAllIncidentsOnDefaultMapField, ValidFlags.SpecificExtra);
    }
    public set showAllIncidentsOnDefaultMap(value: boolean) {
        this.setField<boolean>(UserEntityFields.showAllIncidentsOnDefaultMapField, value);
    }

    // ShowMapAssistant
    public get showMapAssistant(): boolean {
        return this.getField<boolean>(UserEntityFields.showMapAssistantField, ValidFlags.SpecificExtra);
    }
    public set showMapAssistant(value: boolean) {
        this.setField<boolean>(UserEntityFields.showMapAssistantField, value);
    }

    // SkipPtzEvaluation
    public get skipPtzEvaluation(): boolean {
        return this.getField<boolean>(UserEntityFields.skipPtzEvaluationField, ValidFlags.SpecificExtra);
    }
    public set skipPtzEvaluation(value: boolean) {
        this.setField<boolean>(UserEntityFields.skipPtzEvaluationField, value);
    }

    // SpecificTimezone
    public get specificTimezone(): string {
        return this.getField<string>(UserEntityFields.specificTimezoneField, ValidFlags.SpecificExtra);
    }
    public set specificTimezone(value: string) {
        this.setField<string>(UserEntityFields.specificTimezoneField, value);
    }

    // ThumbnailId
    public get thumbnailId(): IGuid {
        return this.getFieldGuid(UserEntityFields.thumbnailIdField, ValidFlags.Specific);
    }
    public set thumbnailId(value: IGuid) {
        this.setFieldGuid(UserEntityFields.thumbnailIdField, value);
    }

    // UseDeviceTimezone
    public get useDeviceTimezone(): boolean {
        return this.getField<boolean>(UserEntityFields.useDeviceTimezoneField, ValidFlags.SpecificExtra);
    }
    public set useDeviceTimezone(value: boolean) {
        this.setField<boolean>(UserEntityFields.useDeviceTimezoneField, value);
    }

    // UseTimezoneAbbreviations
    public get useTimezoneAbbreviations(): boolean {
        return this.getField<boolean>(UserEntityFields.useTimezoneAbbreviationsField, ValidFlags.SpecificExtra);
    }
    public set useTimezoneAbbreviations(value: boolean) {
        this.setField<boolean>(UserEntityFields.useTimezoneAbbreviationsField, value);
    }

    // WebClientLanguage
    public get webClientLanguage(): string {
        return this.getField<string>(UserEntityFields.webClientLanguageField, ValidFlags.SpecificExtra);
    }
    public set webClientLanguage(value: string) {
        this.setField<string>(UserEntityFields.webClientLanguageField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Users);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getApplicationLoggedAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('ApplicationLogged', 'Id', true, query, ValidFlags.EntityDependency, transaction);
    }

    public async getFavoritesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Favorites', 'Id', false, query, ValidFlags.SpecificExtra, transaction);
    }

    public async getFavoritesCTAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('FavoritesCT', 'Id', false, query, ValidFlags.SpecificExtra, transaction);
    }

    public async getFavoritesMapsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('FavoritesMaps', 'Id', false, query, ValidFlags.SpecificExtra, transaction);
    }

    public async addForceUserStatusAsync(isActive: boolean, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('isActive', isActive);

        return this.executeRequestTransactionAsync<IRestResponse>('ForceUserStatus', 'POST', inParam, transaction, responseHandler);
    }

    public async hasAccessAsync(entityId: IGuid, transaction?: ITransaction): Promise<boolean | null> {
        const responseHandler = (response: IRestResponse): Promise<boolean> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body['HasAccess'] as boolean;
                return new Promise<boolean>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<boolean>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('entityId', entityId);

        return this.executeRequestTransactionAsync<boolean>('HasAccess', 'POST', inParam, transaction, responseHandler);
    }

    public async hasAccessListAsync(ids: ObservableCollection<IGuid>, transaction?: ITransaction): Promise<Array<IHasAccessResult> | null> {
        const responseHandler = (response: IRestResponse): Promise<Array<IHasAccessResult>> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = new Array<IHasAccessResult>();
                const body = response.body['HasAccessList'] as Array<any>;
                for (const entry of body) {
                    const fo = new HasAccessResult();
                    fo.loadFields(entry);
                    result.push(fo);
                }
                return new Promise<Array<IHasAccessResult>>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<Array<IHasAccessResult>>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldArrayGuid('ids', ids);

        return this.executeRequestTransactionAsync<Array<IHasAccessResult>>('HasAccessList', 'POST', inParam, transaction, responseHandler);
    }

    public async getHotActionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IHotAction> | null> {
        return this.getFieldObjectRelationAsync<HotAction, IHotAction>(HotAction, 'HotActions', 'Id', true, false, ValidFlags.SpecificExtra, transaction);
    }

    public async getLogonSchedulesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ILogOnSchedule> | null> {
        return this.getFieldObjectRelationAsync<LogOnSchedule, ILogOnSchedule>(LogOnSchedule, 'LogonSchedules', 'Id', false, true, ValidFlags.Specific, transaction);
    }

    public async getRecentlyUsedAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('RecentlyUsed', 'Id', false, query, ValidFlags.SpecificExtra, transaction);
    }

    public async getRecentlyUsedCTAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('RecentlyUsedCT', 'Id', false, query, ValidFlags.SpecificExtra, transaction);
    }

    public async safeUpdatePasswordAsync(newValue: string, oldValue: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('new', newValue);
        inParam.setField('old', oldValue);

        return this.executeRequestTransactionAsync<IRestResponse>('SafeUpdatePassword', 'POST', inParam, transaction, responseHandler);
    }

    public async getUserSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IUserSetting> | null> {
        return this.getFieldObjectRelationAsync<UserSetting, IUserSetting>(UserSetting, 'UserSettings', 'Id', false, true, ValidFlags.SpecificExtra, transaction);
    }

    public async getWebClientTasksAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IWebClientTask> | null> {
        return this.getFieldObjectRelationAsync<WebClientTask, IWebClientTask>(WebClientTask, 'WebClientTasks', 'Guid', false, true, ValidFlags.SpecificExtra, transaction);
    }

    // #endregion REST Methods

}

