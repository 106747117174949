export class FeatureFlag {
  // ** template **
  // private static s_superFeatureFlag = false;

  // public static get IsSuperFeature() : boolean {
  //   return FeatureFlag.s_superFeatureFlag;
  //}

  // public static ToggleSuperFeature(): string {
  //   FeatureFlag.s_superFeatureFlag = !FeatureFlag.s_superFeatureFlag;
  //   return FeatureFlag.s_superFeatureFlag ? 'Using new super feature' : 'Using old crappy feature';
  // }

  private static s_dewarperEnabled = true;

  public static get IsDewarperEnabled(): boolean {
    return FeatureFlag.s_dewarperEnabled;
  }

  public static ToggleDewarper(): string {
    FeatureFlag.s_dewarperEnabled = !FeatureFlag.s_dewarperEnabled;
    return FeatureFlag.s_dewarperEnabled ? 'Using dewarper' : 'Dewarper disabled';
  }
}
