// Autogenerated: v2/help/Entities/ApiAdfsRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-26 3:26:01 PM
import { FieldObject } from '../../../Helpers/FieldObject';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { ITransaction } from '../../Interface/ITransaction';
import { IDomainName, IDomain, IAdfsRoleEntity } from '../../Interface/IAdfsRoleEntity';
import { AuthenticationRoleEntity } from './AuthenticationRoleEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { IEntity } from '../../Interface/IEntity';

// #region Inner classes

export class DomainName extends FieldObject implements IDomainName {
    // FullyQualifiedForm
    public get fullyQualifiedForm(): string {
        return this.getField<string>('FullyQualifiedForm');
    }
    public set fullyQualifiedForm(value: string) {
        this.setField<string>('FullyQualifiedForm', value);
    }
    // NetbiosForm
    public get netbiosForm(): string {
        return this.getField<string>('NetbiosForm');
    }
    public set netbiosForm(value: string) {
        this.setField<string>('NetbiosForm', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('FullyQualifiedForm', '');
        this.setField<string>('NetbiosForm', '');
    }
}

export class Domain extends FieldObject implements IDomain {
    // Domains
    public get domains(): ObservableCollection<IDomainName> {
        return this.getFieldObjectArray<DomainName, IDomainName>(DomainName, 'Domains');
    }
    public set domains(value: ObservableCollection<IDomainName>) {
        this.setFieldObjectArray('Domains', value);
    }
    // Url
    public get url(): string {
        return this.getField<string>('Url');
    }
    public set url(value: string) {
        this.setField<string>('Url', value);
    }
    // RelyingPartyId
    public get relyingPartyId(): string {
        return this.getField<string>('RelyingPartyId');
    }
    public set relyingPartyId(value: string) {
        this.setField<string>('RelyingPartyId', value);
    }
    // EnablePassiveAuth
    public get enablePassiveAuth(): boolean {
        return this.getField<boolean>('EnablePassiveAuth');
    }
    public set enablePassiveAuth(value: boolean) {
        this.setField<boolean>('EnablePassiveAuth', value);
    }
    // ExternalIdentityProviders
    public get externalIdentityProviders(): ObservableCollection<IDomain> {
        return this.getFieldObjectArray<Domain, IDomain>(Domain, 'ExternalIdentityProviders');
    }
    public set externalIdentityProviders(value: ObservableCollection<IDomain>) {
        this.setFieldObjectArray('ExternalIdentityProviders', value);
    }

    public initializeAllFields(): void {
        this.setFieldArray<IDomainName>('Domains', new ObservableCollection<IDomainName>());
        this.setField<string>('Url', '');
        this.setField<string>('RelyingPartyId', '');
        this.setField<boolean>('EnablePassiveAuth', false);
        this.setFieldArray<IDomain>('ExternalIdentityProviders', new ObservableCollection<IDomain>());
    }
}

// #endregion Inner classes

export class AdfsRoleEntity extends AuthenticationRoleEntity implements IAdfsRoleEntity {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getTrustChainsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDomain> | null> {
        return this.getFieldObjectRelationAsync<Domain, IDomain>(Domain, 'TrustChains', 'Id', false, false, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

