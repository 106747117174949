import { Component, OnInit, ViewChild } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { GenMeltedItem } from '@genetec/gelato-angular';
import { ButtonFlavor, Icon, IconSize, TextFlavor, SpinnerSize } from '@genetec/gelato';
import { TranslateService } from '@ngx-translate/core';
import { TrackedComponent } from '@shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { Content, ContentPluginComponent, PluginComponentExposure } from '@shared/interfaces/plugins/public/plugin-public.interface';
import { TrackingService } from '@shared/services/tracking.service';
import { MCIncident } from '@modules/mission-control/models/mc-incident';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IncidentLocationService } from '@modules/mission-control/services/incident-location/incident-location.service';
import { IncidentSelectionService } from '@modules/mission-control/services/incident/incident-selection.service';
import { IncidentApiService } from '@modules/mission-control/services/incident/incident-api.service';
import { CategoryApiService } from '@modules/mission-control/services/category/category-api.service';
import { IncidentTypeService } from '@modules/mission-control/services/incident-type.service';
import { Category } from '@modules/mission-control/models/category';
import { IncidentCommandService } from '@modules/mission-control/services/incident/incident-command.service';
import { ChangeDescriptionCommand } from '@modules/mission-control/models/commands/change-description-command';
import { ChangeExternalIdCommand } from '@modules/mission-control/models/commands/change-external-id-command';
import { McUserService } from '@modules/mission-control/services/mc-user.service';
import { ChangePriorityCommand } from '@modules/mission-control/models/commands/change-priority-command';
import { PriorityService } from '@modules/mission-control/services/priority/priority.service';
import { MCPrivileges } from '@modules/mission-control/mc-privileges';
import { PrivilegeService } from '@modules/shared/privilege/privilege.service';
import { LoggerService } from '@shared/services/logger/logger.service';
import { MissionControlContentTypes } from '../../mission-control-content-types';
import { NgControl } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

const isContentSupported = (content: Content): boolean => !!content?.type.equals(MissionControlContentTypes.overview);

@UntilDestroy()
@Component({
    selector: 'app-overview-widget',
    templateUrl: './overview-widget.component.html',
    styleUrls: ['./overview-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: OverviewWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: OverviewWidgetComponent.pluginId } as PluginComponentExposure,
    requirements: {
        optionalGlobalPrivileges: [MCPrivileges.editIncidentPriority, MCPrivileges.editIncidentDescription],
    },
    isContentSupported,
})
export class OverviewWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('8DDBE03B-8162-4E14-88F9-4DE8DD8F271A');

    @ViewChild('descriptionInput', { read: NgControl }) descriptionInputModel?: NgControl;
    @ViewChild('externalIDInput', { read: NgControl }) externalIDInputModel?: NgControl;

    public readonly TextFlavor = TextFlavor;
    public readonly IconSize = IconSize;
    public readonly Icon = Icon;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly SpinnerSize = SpinnerSize;

    public maxTextLength = 20;
    public content: Content | null = null;
    public dataContext: unknown;
    public incident$!: Observable<MCIncident>;
    public sourceIds$!: Observable<IGuid[] | null>;
    public locationAreaIds$!: Observable<IGuid[] | null>;
    public category$!: Observable<string>;
    public isOwner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public recipientIds: IGuid[] = [];
    public incidentDescriptionInput = '';
    public incidentExternalIdInput = '';
    public incidentPriorityName = '';
    public incidentPriorityInput = { id: '', text: '' };
    public prioritiesList: Array<GenMeltedItem> = [];
    public hasEditIncidentDescriptionPrivilege = false;
    public hasEditIncidentExternalIdPrivilege = false;
    public hasEditIncidentPriorityPrivilege = false;
    public timezone$!: Observable<string>;

    public get selectedEventTypes(): GenMeltedItem[] {
        return this._selectedEventTypes$.getValue();
    }

    public isExpanded = false;
    public editing = false;

    private _selectedEventTypes$ = new BehaviorSubject<Array<GenMeltedItem>>([]);
    private incidentId: IGuid = toGuid('');
    private incidentPriorityId: IGuid = toGuid('');
    private _previousDescription: string | null = null;
    private _previousExternalId: string | null = null;
    private _previousPriorityId: GenMeltedItem | null = null;

    constructor(
        private _incidentSelectionService: IncidentSelectionService,
        private _incidentApiService: IncidentApiService,
        private _incidentTypeService: IncidentTypeService,
        private _translateService: TranslateService,
        private _incidentLocationService: IncidentLocationService,
        private _categoryApiService: CategoryApiService,
        private _incidentCommandService: IncidentCommandService,
        private _priorityService: PriorityService,
        private _mcUserService: McUserService,
        private _privilegeService: PrivilegeService,
        private _logger: LoggerService,
        trackingService: TrackingService
    ) {
        super(trackingService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.initializePrivileges();

        this.incident$ = this._incidentSelectionService.selectedIncident$.pipe(
            filter((incident): incident is MCIncident => incident instanceof MCIncident),
            tap((incident) => {
                this.incidentId = incident.id;
                this.incidentPriorityId = incident.priorityId;
                this._previousDescription = incident.description;
                this._previousExternalId = incident.externalId;
                const priorityText =
                    this.prioritiesList.find((element) => element.id === incident.priorityId.toString())?.text ??
                    (this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string);
                this._previousPriorityId = { id: incident.priorityId.toString(), text: priorityText };
            }),
            shareReplay(1)
        );

        this.sourceIds$ = this.incident$.pipe(
            map((incident) => incident.sourceIds),
            filter((sourceIds) => !!sourceIds),
            tap((sourceIds) => this._incidentLocationService.prefetch(sourceIds!))
        );

        this.locationAreaIds$ = this.incident$.pipe(
            map((incident) => incident.locationAreaIds),
            filter((locationAreaIds) => !!locationAreaIds),
            tap((locationAreaIds) => this._incidentLocationService.prefetch(locationAreaIds!))
        );

        this.category$ = this.incident$.pipe(
            switchMap((incident) => this._incidentTypeService.getIncidentType(incident.typeId, incident.typeRevision)),
            switchMap((incidentType) => (incidentType.categoryId ? this._categoryApiService.getCategory(incidentType.categoryId) : of(null))),
            map((category) => (category as Category)?.name ?? this._translateService.instant('STE_LABEL_NONE'))
        );

        this.incident$
            .pipe(
                map((incident) => this._mcUserService.isCurrentUserOwner(incident.ownerId)),
                untilDestroyed(this)
            )
            .subscribe(this.isOwner$);

        this.initializePriorities();
    }

    public initializePrivileges(): void {
        this.hasEditIncidentDescriptionPrivilege = this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.editIncidentDescription);
        this.hasEditIncidentPriorityPrivilege = this._privilegeService.isGlobalPrivilegeGranted(MCPrivileges.editIncidentPriority);
    }

    public truncate = (input: string): string => {
        return input.length > this.maxTextLength ? `${input.substring(0, this.maxTextLength)}...` : input;
    };

    public hasChanged = (): boolean => this.hasDescriptionChanged() || this.hasExternalIdChanged() || this.hasPriorityChanged();

    public hasDescriptionChanged = (): boolean => this.incidentDescriptionInput !== this._previousDescription;

    public hasExternalIdChanged = (): boolean => this.incidentExternalIdInput !== this._previousExternalId;

    public hasPriorityChanged = (): boolean => this.incidentPriorityInput.id !== this._previousPriorityId?.id;

    public setContent(content: Content): void {
        this.content = content;
    }

    public setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext;
    }

    public toggleEditing(): void {
        if (!this.editing) {
            this.incident$.pipe(untilDestroyed(this)).subscribe((incident) => {
                this._previousDescription = incident.description ?? '';
                this.incidentDescriptionInput = this._previousDescription;

                this._previousExternalId = incident.externalId ?? '';
                this.incidentExternalIdInput = this._previousExternalId;

                const priorityText = this.prioritiesList.find((element) => element.id === incident.priorityId.toString())?.text;
                this._previousPriorityId = {
                    id: incident.priorityId.toString(),
                    text: this.truncate(priorityText ?? (this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string)),
                };
                this.incidentPriorityInput = this._previousPriorityId;
            });
        }
        this.editing = !this.editing;
    }

    public async saveChangesClick(descriptionText: string, externalIdText: string, priorityText: GenMeltedItem | undefined): Promise<void> {
        const commands: Promise<any>[] = [];

        if (this.hasExternalIdChanged()) {
            const externalIdCommand = this._incidentCommandService.execute(new ChangeExternalIdCommand(this.incidentId, externalIdText)).toPromise();
            commands.push(externalIdCommand);
        }

        if (this.hasDescriptionChanged()) {
            const descriptionCommand = this._incidentCommandService.execute(new ChangeDescriptionCommand(this.incidentId, descriptionText)).toPromise();
            commands.push(descriptionCommand);
        }

        if (this.hasPriorityChanged()) {
            const priorityCommand = this._incidentCommandService
                .execute(new ChangePriorityCommand(this.incidentId, priorityText ? toGuid(priorityText.id) : toGuid('')))
                .toPromise();
            commands.push(priorityCommand);
        }

        if (commands.length > 0) {
            await Promise.allSettled(commands);
        }

        this.toggleEditing();
    }

    private initializePriorities() {
        this._priorityService
            .getPriorities$()
            .pipe(untilDestroyed(this))
            .subscribe((priorities) => {
                for (const priority of priorities) {
                    this.prioritiesList.push({
                        id: priority.id.toString(),
                        text: this.truncate(priority.name),
                    });
                }
            });

        this._priorityService
            .getPriority(this.incidentPriorityId)
            .pipe(untilDestroyed(this))
            .subscribe((priority) => (this.incidentPriorityName = priority ? priority.name : ''));
    }
}
