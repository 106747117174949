import { State, StateContext } from '@ngxs/store';
import { EmitterAction } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';
import { ContextDataStateModel } from '@modules/shared/services/side-context-data/side-context-data.state';
import { Receiver, Selector } from '../../../../store';
import { ContentGroup } from '../../../shared/interfaces/plugins/public/plugin-public.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export type MapsBottomContextDataStateModel = ContextDataStateModel;

@State<MapsBottomContextDataStateModel>({
    name: 'mapsBottomContextDataState',
    defaults: { contents: [], currentContent: null },
})
@Injectable()
export class MapsBottomContextDataState {
    @Selector()
    public static currentContent(state: MapsBottomContextDataStateModel): ContentGroup | null {
        return state.currentContent;
    }

    @Selector()
    public static contentCount(state: MapsBottomContextDataStateModel): number {
        return state.contents.length;
    }

    @Receiver()
    public static setMainContent({ setState }: StateContext<MapsBottomContextDataStateModel>, { payload }: EmitterAction<ContentGroup>): void {
        setState((state: MapsBottomContextDataStateModel) => {
            state.contents.length = 0;
            state.currentContent = null;
            if (payload) {
                state.contents.push(payload);
                state.currentContent = payload;
            }

            return state;
        });
    }

    @Receiver()
    public static pushContent({ setState }: StateContext<MapsBottomContextDataStateModel>, { payload }: EmitterAction<ContentGroup>): void {
        setState((state: MapsBottomContextDataStateModel) => {
            state.contents.push(payload);
            state.currentContent = payload;
            return state;
        });
    }

    @Receiver()
    public static popContent({ setState }: StateContext<MapsBottomContextDataStateModel>): void {
        setState((state: MapsBottomContextDataStateModel) => {
            state.contents.pop();
            state.currentContent = state.contents.length > 0 ? state.contents[state.contents.length - 1] : null;
            return state;
        });
    }
}
