<div class="joystick-selection-wrapper" *ngIf="joysticks" [ngClass]="{ 'joystick-radio': joysticks.length === 2, 'joystick-combo': joysticks.length === 3 }">
    <gen-text flavor="label">{{ 'STE_LABEL_OPTIONS_ACTIVE_DEVICE' | translate }}</gen-text>
    <ng-container *ngIf="joysticks.length === 1">
        <gen-checkbox (change)="onCheckboxChanged($event)" [checked]="joysticks[0].id === activeJoystickId">
            <gen-label>{{ joysticks[0].id }}</gen-label>
        </gen-checkbox>
        <gen-message>{{ 'STE_MESSAGE_INFO_OPTIONS_ACTIVE_DEVICE' | translate }}</gen-message>
    </ng-container>

    <ng-container *ngIf="joysticks.length === 2">
        <gen-radio name="activeJoystickRadio" [value]="0" (change)="onActiveJoystickChanged()" [checked]="!activeJoystickId">
            <gen-label>{{ 'STE_LABEL_NONE' | translate }}</gen-label>
        </gen-radio>
        <gen-radio
            *ngFor="let joystick of joysticks"
            name="activeJoystickRadio"
            [value]="joystick.id"
            (change)="onActiveJoystickChanged(joystick.id)"
            [checked]="activeJoystickId === joystick.id"
        >
            <gen-label>{{ joystick.id }}</gen-label>
        </gen-radio>
        <gen-message>{{ 'STE_MESSAGE_INFO_OPTIONS_ACTIVE_DEVICE' | translate }}</gen-message>
    </ng-container>

    <ng-container *ngIf="joysticks.length >= 3">
        <gen-combobox
            name="active-joystick"
            [ngModel]="activeJoystickId ? { id: activeJoystickId, text: activeJoystickId } : { id: '', text: 'STE_LABEL_NONE' | translate }"
            (ngModelChange)="onComboboxChanged($event)"
            [genItemsSource]="joysticksItems"
            genMessage="{{ 'STE_MESSAGE_INFO_OPTIONS_ACTIVE_DEVICE' | translate }}"
            (genIsOpenChange)="refreshComboboxSelection()"
        >
        </gen-combobox>
    </ng-container>
</div>
