// Autogenerated: v2/help/Entities/ApiDevice?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:05 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from '../Interface/IEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class DeviceEntityFields extends EntityFields {
    public static deviceTypeField = 'DeviceType';
    public static isDisabledField = 'IsDisabled';
    public static physicalNameField = 'PhysicalName';
    public static uniqueIdField = 'UniqueId';
    public static unitField = 'Unit';
}

// #endregion Fields

export interface IDeviceEntity extends IEntity {
    // #region REST Properties

    readonly deviceType: string;

    isDisabled: boolean;

    readonly physicalName: string;

    readonly uniqueId: string;

    readonly unit: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    getAccessPointAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

