import { PromiseCompletionSource } from './PromiseCompletionSource';
import { TimeSpan } from './TimeSpan';

export class Cancellation {
  private readonly m_reason: string;

  public get Reason(): string {
    return this.m_reason;
  }

  constructor(reason: string = '') {
    this.m_reason = reason;
  }
}

export class CancellationToken {
  private readonly m_promise: PromiseCompletionSource<Cancellation> = new PromiseCompletionSource<Cancellation>();
  private m_isCancelled = false;

  public get IsCancelled(): boolean {
    return this.m_isCancelled;
  }

  constructor() {
    this.m_promise.Promise.then(() => this.m_isCancelled = true);
  }

  public get Cancellation(): Promise<Cancellation> {
    return this.m_promise.Promise;
  }

  public cancel(reason: string): void {
    this.m_promise.resolve(new Cancellation(reason));
  }
}

export async function withCancellation<T>(cancellablePromise: Promise<T>, cancellationToken: CancellationToken): Promise<T | Cancellation> {
  return await Promise.race([cancellablePromise, cancellationToken.Cancellation]);
}

export async function cancellableDelay(delay: TimeSpan, cancellationToken: CancellationToken): Promise<void | Cancellation> {
  let timeoutId: number | null = null;
  const delayResult = await withCancellation<void>(new Promise((resolve) => {
    timeoutId = window.setTimeout(resolve, delay.InMs);
  }), cancellationToken);

  if (delayResult instanceof Cancellation && timeoutId !== null) {
    clearTimeout(timeoutId);
  }

  return delayResult;
}
