import { Component, OnInit } from '@angular/core';
import { SafeGuid } from 'safeguid';
import { FilterTypes } from 'src/app/modules/shared/enumerations/filter-types';
import { InternalPluginDescriptor } from 'src/app/modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginComponentExposure } from 'src/app/modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from 'src/app/modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from 'src/app/modules/shared/services/tracking.service';
import { FilterPluginBaseComponent } from './filter-plugin-base.component';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    template: '<app-filter-numeric-range [min]="min" [max]="max" [descriptor]="descriptor" (valueChange)="updateFilter($event)"></app-filter-numeric-range>',
})
@InternalPluginDescriptor({
    type: FilterPluginNumericRangeComponent,
    pluginTypes: [PluginTypes.Filter],
    exposure: {
        id: FilterPluginNumericRangeComponent.pluginId,
        subSection: FilterTypes.NumericRange,
    } as PluginComponentExposure,
    isSupported: () => true,
})
export class FilterPluginNumericRangeComponent extends FilterPluginBaseComponent implements OnInit {
    public static readonly pluginId = SafeGuid.parse('222B82E4-E1EB-4C7A-AE5F-CCBF5BE4D63C');
    public min: number = Number.MIN_VALUE;
    public max: number = Number.MAX_VALUE;

    constructor(trackingService: TrackingService) {
        super(trackingService, FilterPluginNumericRangeComponent.pluginId);
    }

    ngOnInit() {
        this.min = this.getField<number>('min') ?? this.min;
        this.max = this.getField<number>('max') ?? this.max;
    }
}
