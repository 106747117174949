import { VideoWatermarkConfig, TextOverlayPosition } from './VideoWatermarkingConfig';
import { PositionFinder } from './PositionFinder';

export class VideoWatermarkingOverlay {
  static readonly SpaceBetweenLines = 1;

  private readonly m_videoWatermarkingConfig: VideoWatermarkConfig;

  private readonly m_streamWidth: number;

  private readonly m_streamHeight: number;

  constructor(watermarkConfiguration: VideoWatermarkConfig, streamWidth: number, streamHeight: number) {
    this.m_streamWidth = streamWidth;
    this.m_streamHeight = streamHeight;
    this.m_videoWatermarkingConfig = watermarkConfiguration;
  }

  public createVideoWatermarkingOverlay(overlay: HTMLCanvasElement, overlayBlock: HTMLCanvasElement, blockWidth: number, blockHeight: number) {
    overlay.width = this.m_streamWidth;
    overlay.height = this.m_streamHeight;
    const overlayContext = overlay.getContext('2d');

    if (overlayContext !== null) {
      // Draw the overlayBlock containing the watermark info at the proper position on the overlay
      if (this.m_videoWatermarkingConfig.Position === TextOverlayPosition.Mosaic) {
        this.drawMosaic(overlayContext, overlayBlock, blockWidth, blockHeight);
      } else {
        const positionFinder = new PositionFinder(this.m_videoWatermarkingConfig.Position, blockWidth, blockHeight);
        const position = positionFinder.findPosition(this.m_streamWidth, this.m_streamHeight);
        overlayContext.drawImage(overlayBlock, position[0], position[1]);
      }
    }
  }

  // Creation of a transparent canvas with the username or/and the camera name or/and the workstation name.
  public createOverlayBlock(fontSize: number): HTMLCanvasElement {
    const overlayBlock = document.createElement('canvas');
    this.fillText(overlayBlock, fontSize);

    return overlayBlock;
  }

  // Specify a new width and height for the block; this resets the canvas context, and therefore the overlay text.
  public setOverlaySize(overlayBlock: HTMLCanvasElement, blockWidth: number, blockHeight: number) {
    overlayBlock.width = blockWidth;
    overlayBlock.height = blockHeight;
  }

  public fillText(overlayBlock: HTMLCanvasElement, fontSize: number) {
    const WatermarkFont = 'Segoe UI';
    const WatermarkColor = 'rgb(255, 255, 255)';

    const overlayBlockContext = overlayBlock.getContext('2d');
    if (overlayBlockContext !== null) {
      overlayBlockContext.font = fontSize.toString() + 'px ' + WatermarkFont;
      overlayBlockContext.fillStyle = WatermarkColor;

      // Remove the SpaceBetweenLines for the first iteration to start exactly at the top
      let yPosition = -VideoWatermarkingOverlay.SpaceBetweenLines;
      for (let i = 0; i < this.m_videoWatermarkingConfig.overlayText.length; i++) {
        yPosition += fontSize + VideoWatermarkingOverlay.SpaceBetweenLines;
        overlayBlockContext.fillText(this.m_videoWatermarkingConfig.overlayText[i], 0, yPosition);
      }
    }
  }

  private drawMosaic(canvasContext: CanvasRenderingContext2D, overlayBlock: HTMLCanvasElement, blockWidth: number, blockHeight: number) {
    const SpaceBetweenMosaicBlocks = 1;
    let xPosition = 0;
    let yPosition = 0;
    let isNextRowOffset = true;

    while (yPosition < this.m_streamHeight) {
      while (xPosition < this.m_streamWidth) {
        canvasContext.drawImage(overlayBlock, xPosition, yPosition);
        xPosition += (blockWidth + SpaceBetweenMosaicBlocks * this.m_videoWatermarkingConfig.Size);
      }

      xPosition = 0;
      // Remove the size of half a block for second line, fourth line, etc
      if (isNextRowOffset) {
        xPosition -= (blockWidth + SpaceBetweenMosaicBlocks * this.m_videoWatermarkingConfig.Size) / 2;
        isNextRowOffset = false;
      } else {
        isNextRowOffset = true;
      }

      yPosition += blockHeight + blockHeight / 2;
    }
  }

  public rotateOverlayBlock(overlayBlock: HTMLCanvasElement, blockWidth: number, blockHeight: number): [HTMLCanvasElement, number, number] {
    const PiOn180 = Math.PI / 180;
    const newOverlayBlock = document.createElement('canvas');
    const newOverlayBlockContext = newOverlayBlock.getContext('2d');

    if (newOverlayBlockContext !== null) {
      const radian = this.m_videoWatermarkingConfig.Rotation * PiOn180;
      const oppositeRadian = (90 + this.m_videoWatermarkingConfig.Rotation) * PiOn180;
      const radianForRotation = (360 - this.m_videoWatermarkingConfig.Rotation) * PiOn180;

      // Calculate size of the block with rotated text
      const beforeRotationHeight = blockHeight;
      const beforeRotationWidth = blockWidth;
      // Set the new height and width of the block with the rotated text
      blockHeight = Math.abs((beforeRotationWidth * Math.sin(radian))) + Math.abs(beforeRotationHeight * Math.sin(Math.PI / 2 - radian));
      blockWidth = Math.abs(beforeRotationWidth * Math.cos(radian)) + Math.abs(beforeRotationHeight * Math.cos(oppositeRadian));

      newOverlayBlock.height = blockHeight;
      newOverlayBlock.width = blockWidth;

      newOverlayBlockContext.translate(0, Math.abs((beforeRotationWidth * Math.sin(radian))));
      newOverlayBlockContext.rotate(radianForRotation);
      newOverlayBlockContext.drawImage(overlayBlock, 0, 0);
    }

    return [newOverlayBlock, blockWidth, blockHeight];
  }

  public calculateOverlayBlockSize(overlayBlock: HTMLCanvasElement, fontSize: number): [number, number] {
    let blockWidth = 0;
    let blockHeight = 0;
    const overlayBlockContext = overlayBlock.getContext('2d');
    if (overlayBlockContext !== null) {
      // For each line of text, add the height of the line + set the width if it is the longest line
      for (let i = 0; i < this.m_videoWatermarkingConfig.overlayText.length; i++) {
        blockHeight += fontSize + VideoWatermarkingOverlay.SpaceBetweenLines * this.m_videoWatermarkingConfig.Size;

        const textSize = overlayBlockContext.measureText(this.m_videoWatermarkingConfig.overlayText[i]);
        if (textSize.width > blockWidth) {
          blockWidth = textSize.width;
        }
      }
    }

    return [blockWidth, blockHeight];
  }

  public calculateFontSize(): number {
    const ReferenceWidth = 1920;
    return this.m_streamWidth * ((this.m_videoWatermarkingConfig.Size * 2) + 50) / ReferenceWidth;
  }
}
