export class Guard {
  //todo: isDate

  public static isNumber(x: any, parameterName: string) {
    if (typeof x !== 'number') {
      throw new Error(`${parameterName} must be a number`);
    }
  }

  public static isFiniteNumber(x: any, parameterName: string) {
    if (typeof x !== 'number') {
      throw new Error(`${parameterName} must be a number`);
    }
    if (isNaN(x)) {
      throw new Error(`${parameterName} must be a finite number`);
    }
  }

  public static isString(x: any, parameterName: string) {
    if (typeof x !== 'string') {
      throw new Error(`${parameterName} must be a string`);
    }
  }

  public static isInRangeInclusive(x: number, lowerBound: number, upperBound: number) {
    if (!(lowerBound <= x && x <= upperBound)) {
      throw new Error(`Value ${x} is not inside requested range: [${lowerBound}, ${upperBound}]`);
    }
  }
}
