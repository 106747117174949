// Autogenerated: v2/help/Entities/ApiAccessPoint?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:02 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity, EntityFields } from './IEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class AccessPointEntityFields extends EntityFields {
    public static accessPointSideField = 'AccessPointSide';
    public static accessPointTypeField = 'AccessPointType';
    public static deviceStateField = 'DeviceState';
    public static parentField = 'Parent';
    public static twoManRuleEnabledField = 'TwoManRuleEnabled';
}

// #endregion Fields

export interface IAccessPointEntity extends IEntity {
    // #region REST Properties

    readonly accessPointSide: string;

    readonly accessPointType: string;

    deviceState: string;

    readonly parent: IGuid;

    twoManRuleEnabled: boolean;

    // #endregion REST Properties

    // #region REST Methods

    getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;

    // #endregion REST Methods

}

