// Autogenerated: v2/help/enumerations/ApiApplicationType?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class ApplicationType {
    public static Datastore = 'Datastore';
    public static Test = 'Test';
    public static ConfigTool = 'ConfigTool';
    public static Controller = 'Controller';
    public static SecurityDesk = 'SecurityDesk';
    public static AdminTool = 'AdminTool';
    public static WDTray = 'WDTray';
    public static WDService = 'WDService';
    public static SDK = 'SDK';
    public static AccessDatastoreMacroManager = 'AccessDatastoreMacroManager';
    public static DirectoryPlugin = 'DirectoryPlugin';
    public static UnitSimulator = 'UnitSimulator';
    public static RoleController = 'RoleController';
    public static IntegrationService = 'IntegrationService';
    public static WebClient = 'WebClient';
    public static Federation = 'Federation';
    public static RoamingFederation = 'RoamingFederation';
    public static SecurityDeskSimulator = 'SecurityDeskSimulator';
    public static UnitEmulator = 'UnitEmulator';
    public static Patroller = 'Patroller';
    public static ServerManager = 'ServerManager';
    public static RoleModule = 'RoleModule';
    public static MigrationTool = 'MigrationTool';
    public static MobileServer = 'MobileServer';
    public static MobileServerSlave = 'MobileServerSlave';
    public static ImportTool = 'ImportTool';
    public static PatrollerConfigTool = 'PatrollerConfigTool';
    public static MobileClient = 'MobileClient';
    public static AuthenticationService = 'AuthenticationService';
    public static RoleAgent = 'RoleAgent';
    public static GcsRemote = 'GcsRemote';
    public static DirectoryCache = 'DirectoryCache';
    public static UnKnown = 'UnKnown';

    public static readonly values = [
        ApplicationType.Datastore,
        ApplicationType.Test,
        ApplicationType.ConfigTool,
        ApplicationType.Controller,
        ApplicationType.SecurityDesk,
        ApplicationType.AdminTool,
        ApplicationType.WDTray,
        ApplicationType.WDService,
        ApplicationType.SDK,
        ApplicationType.AccessDatastoreMacroManager,
        ApplicationType.DirectoryPlugin,
        ApplicationType.UnitSimulator,
        ApplicationType.RoleController,
        ApplicationType.IntegrationService,
        ApplicationType.WebClient,
        ApplicationType.Federation,
        ApplicationType.RoamingFederation,
        ApplicationType.SecurityDeskSimulator,
        ApplicationType.UnitEmulator,
        ApplicationType.Patroller,
        ApplicationType.ServerManager,
        ApplicationType.RoleModule,
        ApplicationType.MigrationTool,
        ApplicationType.MobileServer,
        ApplicationType.MobileServerSlave,
        ApplicationType.ImportTool,
        ApplicationType.PatrollerConfigTool,
        ApplicationType.MobileClient,
        ApplicationType.AuthenticationService,
        ApplicationType.RoleAgent,
        ApplicationType.GcsRemote,
        ApplicationType.DirectoryCache,
        ApplicationType.UnKnown
    ];
}
