// Autogenerated: v2/help/enumerations/ApiZoneArmingState?codegen=ts&showresources=False on 2021-03-02 10:20:51 AM

export class ZoneArmingState {
    public static Unknown = 'Unknown';
    public static Armed = 'Armed';
    public static Disarmed = 'Disarmed';

    public static readonly values = [
        ZoneArmingState.Unknown,
        ZoneArmingState.Armed,
        ZoneArmingState.Disarmed
    ];
}
