/** @beta */
export class Coords {
  public static readonly Origin = new Coords(0, 0);

  protected readonly m_x: number;

  protected readonly m_y: number;

  public get X() {
    return this.m_x;
  }

  public get Y() {
    return this.m_y;
  }

  public constructor(x: number, y: number) {
    this.m_x = x;
    this.m_y = y;
  }

  public toString(): string {
    return `(${this.m_x.toFixed(2)}, ${this.m_y.toFixed(2)})`;
  }
}
