<app-entity-state-overlay [draggable]="true" [content]="content" [commandsUsage]="null" [customIconId]="cameraCustomIconId">
    <span
        entityTitle
        class="entity-name"
        [ngClass]="{
            'fg-color-danger': isOffline,
            'fg-color-warning': hasWarnings && !isMaintenanceModeActive,
            'fg-color-maintenance': isMaintenanceModeActive
        }"
    >
        {{ content?.title }}
    </span>
    <span primaryState class="hyphen-after" [ngClass]="{ 'fg-color-danger': isOffline, 'fg-color-warning': hasWarnings }">{{ statusText }}</span>
    <span secondaryState>{{ recordingText }}</span>
</app-entity-state-overlay>
