import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TileNumberDisplayPreference } from '@modules/general/components/options/tiles-options/tiles-options.component';
import { GeneralFeatureFlags } from '@modules/general/feature-flags';
import { PluginItem } from '@modules/shared/interfaces/plugins/internal/pluginItem';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { Content, ContentGroup } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { PluginService } from '@modules/shared/services/plugin/plugin.service';
import { TileItem } from '@modules/tiles/models/tile-item';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TileHeaderService } from './services/tile-state-header.service';

@Component({
    selector: 'app-tile-state-header',
    templateUrl: './tile-state-header.component.html',
    styleUrls: ['./tile-state-header.component.scss'],
    animations: [
        trigger('fadeSlideIn', [transition(':enter', [style({ opacity: 0, transform: 'translateY(10px)' }), animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))])]),
    ],
})
export class TileStateHeaderComponent implements OnInit, OnDestroy {
    @Input()
    public tileState!: TileItem | null;

    @Input()
    public tileHostElement!: ElementRef<Element>;

    public readonly TileHeaderVisibility = TileDetailsVisibility;
    public readonly TileNumberDisplayPreference = TileNumberDisplayPreference;
    public readonly TileNumberVisibility = TileNumberVisibility;

    public tileHeaderVisibility?: TileDetailsVisibility;
    public pluginItem?: PluginItem;
    public tileStateHeaderContext!: TileStateHeaderContext;
    public tileNumberDisplayPreference?: TileNumberDisplayPreference;
    public tileNumberVisibility$: Observable<TileNumberVisibility>;
    public tileHeaderVisible$: Observable<boolean>;
    public hasTileHeaderFeatureFlag?: boolean;

    public get tileNumber(): number {
        return this.tileState?.id ?? 0;
    }

    public get content(): ContentGroup | null {
        return this.tileState?.content ?? null;
    }

    private onDestroySubject$ = new Subject();

    constructor(
        private pluginService: PluginService,
        private tileHeaderService: TileHeaderService,
        private advancedSettingsService: AdvancedSettingsService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService
    ) {
        this.hasTileHeaderFeatureFlag = GeneralFeatureFlags.EntityState.isEnabled(this.advancedSettingsService);

        // Should be done this way because @UntilDestroy() cause a circular dependency injection
        this.tileHeaderService.tileDetailsVisibility$.pipe(takeUntil(this.onDestroySubject$)).subscribe((visibility: TileDetailsVisibility) => {
            this.tileHeaderVisibility = visibility;
        });

        this.tileHeaderVisible$ = this.tileHeaderService.tileDetailsVisibility$.pipe(map((visibility) => visibility !== TileDetailsVisibility.Hidden));
        this.tileNumberVisibility$ = this.tileHeaderService.tileNumberVisibility$;
    }

    ngOnDestroy(): void {
        this.onDestroySubject$.next();
        this.onDestroySubject$.complete();
    }

    async ngOnInit() {
        if (this.tileState) {
            // push the initial state in the service.
            this.tileHeaderService.updateState(this.tileState);

            const pluginItems = await this.pluginService.getPlugins(PluginTypes.TileHeader, this.content?.mainContent);
            // PluginItems are sorted by priority. Take the first on top.
            this.pluginItem = pluginItems[0];

            if (this.pluginItem) {
                // Add our plugin commands to the tileState commands for context menu and keyboard shortcuts
                const usage = await this.commandsService.getCommandsUsage(
                    { type: ContextTypes.Content, data: this.pluginItem.data },
                    this.pluginItem?.exposure?.availableCommands,
                    false,
                    this.tileHostElement?.nativeElement
                );
                this.tileState.commandsUsages.addUsage(usage);
            }
        }
    }
}

export interface TileStateHeaderContext {
    tileItem: TileItem;
    contextSubContent?: Content[];
}

export enum TileDetailsVisibility {
    Hidden = 'Hidden',
    Partial = 'Partial',
    Full = 'Full',
}

export enum TileNumberVisibility {
    Shown = 'Shown',
    Hidden = 'Hidden',
}
