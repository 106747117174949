import { TimeSpan } from './TimeSpan';

export class Utils {
  public static readonly Indentation = 4;

  public static delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public static delayFromTimeSpan(timeSpan: TimeSpan): Promise<void> {
    return Utils.delay(timeSpan.InMs);
  }

  public static addMilliseconds(date: Date, milliseconds: number): Date {
    return new Date(date.getTime() + milliseconds);
  }

  public static addSeconds(date: Date, seconds: number): Date {
    return new Date(date.getTime() + seconds * 1000);
  }

  public static addHours(date: Date, hours: number): Date {
    return new Date(date.getTime() + (hours * 60 * 60 * 1000));
  }

  public static addDays(date: Date, days: number): Date {
    return new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
  }

  public static subtractMinutes(date: Date, minutes: number): Date {
    return Utils.addMilliseconds(date, -1 * minutes * 60 * 1000);
  }

  public static addTimeSpan(date: Date, timeSpan: TimeSpan): Date {
    return Utils.addMilliseconds(date, timeSpan.InMs);
  }

  public static spanBetweenDates(start: Date, end: Date): TimeSpan {
    return new TimeSpan(end.getTime() - start.getTime());
  }

  public static formatDateUTC(date: Date | undefined): string {
    if (date === undefined) {
      return 'undefined';
    }

    return Utils.pad(date.getUTCHours(), 2) + ':' +
      Utils.pad(date.getUTCMinutes(), 2) + ':' +
      Utils.pad(date.getUTCSeconds(), 2) + '.' +
      Utils.pad(date.getUTCMilliseconds(), 3) + ' ' +
      date.getUTCFullYear() + '-' +
      Utils.pad(date.getUTCMonth() + 1, 2) + '-' +
      Utils.pad(date.getUTCDate(), 2);
  }

  public static formatDate(date: Date | undefined) {
    if (date === undefined) {
      return 'undefined';
    }

    return Utils.pad(date.getHours(), 2) + ':' +
      Utils.pad(date.getMinutes(), 2) + ':' +
      Utils.pad(date.getSeconds(), 2) + '.' +
      Utils.pad(date.getMilliseconds(), 3) + ' ' +
      date.getFullYear() + '-' +
      Utils.pad(date.getMonth() + 1, 2) + '-' +
      Utils.pad(date.getDate(), 2);
  }

  public static pad(n: number, length: number) {
    let s = String(n);
    while (s.length < length) {
      s = '0' + s;
    }
    return s;
  }

  public static supportsMse(): boolean {
    return !!window.MediaSource && MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E"');
  }

  private static readonly _1K: number = 1024;
  private static readonly _1M: number = Utils._1K * 1024;
  private static readonly _1G: number = Utils._1M * 1024;

  public static readableByteCount(byteCount: number): string {
    if (byteCount <= this._1K) {
      return `${byteCount} bytes`;
    }
    if (byteCount < this._1M) {
      return `${(byteCount / this._1K).toFixed(1)} KB`;
    }
    if (byteCount < this._1G) {
      return `${(byteCount / this._1M).toFixed(1)} MB`;
    }
    return `${(byteCount / this._1G).toFixed(1)} GB`;
  }

  public static clip(n: number, min: number, max: number) {
    if (min > max) {
      throw new Error(`Cannot clip with a minimum higher than the maximum ([${min}],[${max}])`);
    }
    return n < min ? min : n > max ? max : n;
  }

  public static indentNewLine(indentation: number) {
    return `\r\n${' '.repeat(indentation)}`;
  }
}

export function assertUnreachable(_x: never): never {
  throw new Error('Did not expect to get here');
}
