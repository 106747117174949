import { RecordingState } from 'RestClient/Client/Enumerations/RecordingStates';

export class CameraHelper {
    public static isRecording(state: string): boolean {
        switch (state) {
            case RecordingState.OffLocked:
            case RecordingState.Off:
                return false;
            case RecordingState.OnLocked:
            case RecordingState.OnAboutToStop:
            case RecordingState.On:
                return true;
        }
        return false;
    }

    public static isRecordingLocked(state: string): boolean {
        switch (state) {
            case RecordingState.OffLocked:
            case RecordingState.OnLocked:
                return true;
            case RecordingState.Off:
            case RecordingState.On:
                return false;
        }
        return false;
    }
}
