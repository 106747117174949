/* eslint-disable id-blacklist */
import { Inject, Injectable } from '@angular/core';
import { LogonStateChangedArgs } from 'RestClient/Client/Args/LogonStateChangedArgs';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid } from 'safeguid';
import { LoggerService } from '../../shared/services/logger/logger.service';
import { DataIngestionClient, DataRow, DataSchema, DatumKey, IngestionType } from '../api/api';
import { FEATURES_SERVICE } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { FeaturesService } from '../../shared/services/features/features.service';
import { CorrelationFeatures } from '../enumerations/correlation.features';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export enum IngestionFieldType {
    String = 'String',
    Integer = 'Integer',
    Long = 'Long',
    Double = 'Double',
    DateTime = 'DateTime',
    Boolean = 'Boolean',
    Entity = 'Entity',
    Base64Binary = 'Base64Binary',
    LargeString = 'LargeString',
    File = 'File',
}

interface IngestionFieldContainerBase<T extends IngestionFieldType, V> {
    display: string;
    id: string;
    type: T;
    value: V;
}

export type IngestionFieldContainer = IngestionFieldContainerBase<IngestionFieldType, unknown> &
    (
        | IngestionFieldContainerBase<IngestionFieldType.String, string | null>
        | IngestionFieldContainerBase<IngestionFieldType.Boolean, boolean | null>
        | IngestionFieldContainerBase<IngestionFieldType.Double, number | null>
        | IngestionFieldContainerBase<IngestionFieldType.Integer, number | null>
        | IngestionFieldContainerBase<IngestionFieldType.Long, number | null>
        | IngestionFieldContainerBase<IngestionFieldType.LargeString, string | null>
        | IngestionFieldContainerBase<IngestionFieldType.Entity, IGuid | null>
        | IngestionFieldContainerBase<IngestionFieldType.Base64Binary, FileList | null>
        | IngestionFieldContainerBase<IngestionFieldType.File, FileList | null>
        | IngestionFieldContainerBase<IngestionFieldType.DateTime, string | null>
    );

@Injectable()
export class IngestionService {
    //#region Fields

    private readonly scClient: ISecurityCenterClient;
    private subscriptions: (() => void)[] = [];

    private ingestionTypes: IngestionType[] | undefined;

    //#endregion

    //#region Constructor

    constructor(
        private securityCenterClientService: SecurityCenterClientService,
        @Inject(FEATURES_SERVICE) private featuresService: FeaturesService,
        private loggerService: LoggerService,
        private ingestionApiController: DataIngestionClient
    ) {
        this.scClient = this.securityCenterClientService.scClient;
        this.subscriptions.push(
            this.scClient.onLogonStateChanged((arg) => {
                this.onLogonStateChanged(arg);
            })
        );
    }

    //#endregion

    //#region Public Methods

    public getIngestionTypeCache(): IngestionType[] | undefined {
        return this.ingestionTypes;
    }

    public async listTypes(): Promise<IngestionType[] | undefined> {
        if (!this.ingestionTypes) {
            await this.constructIngestionCache();
        }

        return this.ingestionTypes;
    }

    public async getSchema(role: IGuid, type: IGuid): Promise<DataSchema | null> {
        const result = await this.ingestionApiController.getSchema(role, type).toPromise();
        if (result) {
            return result;
        }
        return null;
    }

    public async getData(id: string, type: IGuid, role: IGuid): Promise<DataRow | null> {
        const result = await this.ingestionApiController.getRecord(role, type, id).toPromise();
        if (result) {
            return result;
        }
        return null;
    }

    public async addData(row: DataRow): Promise<boolean> {
        try {
            await this.ingestionApiController.addRecord(row).toPromise();
            return true;
        } catch (err) {
            this.loggerService.traceError(`Failed to add record: ${JSON.stringify(err)}`);
            return false;
        }
    }

    public async updateData(row: DataRow): Promise<boolean> {
        try {
            await this.ingestionApiController.updateRecord(row).toPromise();
            return true;
        } catch (err) {
            this.loggerService.traceError(`Failed to update record: ${JSON.stringify(err)}`);
            return false;
        }
    }

    public async deleteData(id: string, type: IGuid, role: IGuid): Promise<boolean> {
        // TODO: pop dialog and confirm record deletion
        try {
            const key = new DatumKey({
                sourceId: id,
                sourceRole: role,
                dataType: type,
            });
            await this.ingestionApiController.deleteRecord(key).toPromise();
            return true;
        } catch (err) {
            this.loggerService.traceError(`Failed to delete record: ${JSON.stringify(err)}`);
            return false;
        }
    }

    //#endregion

    //#region Events

    private onLogonStateChanged(e: LogonStateChangedArgs): void {
        if (e.loggedOn() === true) {
            void this.listTypes();
        } else {
            this.ingestionTypes = undefined;
        }
    }

    //#endregion

    //#region Private Methods

    private async constructIngestionCache(): Promise<void> {
        if (!this.ingestionTypes) {
            // ensure the feature is enabled
            if (setSome(await this.featuresService.getFeaturesAsync(), (feat) => feat.equals(CorrelationFeatures.ingestDataFeature))) {
                const data = await this.ingestionApiController.listIngestionTypes().toPromise();
                if (data) {
                    this.ingestionTypes = data;
                    const message = `There are ${data.length} ingestable data types available`;
                    this.loggerService.traceInformation(message);
                }
            }
        }
    }

    //#endregion
}
