import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CalendarTimeFrame, DateFormat, Flavor } from '@genetec/gelato-angular';
import { SelectionType, TextFlavor } from '@genetec/gelato';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { SafeGuid } from 'safeguid';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { DurationItem } from '@shared/interfaces/duration-item';
import { Item } from '@modules/shared/interfaces/item';
import { DateFilterMode } from '@modules/shared/components/filters/common-filters/filter-date/enums/date-filter-mode.enum';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LanguageService } from '@modules/shared/services/language/language.service';
import { DurationUnit } from '../../services/investigate-filter-data.enumerations';
import { LastPeriodPreset, WhenFilter } from '../../services/investigate-data.interfaces';
import { InvestigateFilterBaseComponent } from '../investigate-filter-base.component';
import { InvestigateDataContext } from '../../services/investigate-data.context';

enum DateTimePeriodValidationState {
    InvalidRange = 'STE_MESSAGE_ERROR_ENDTIMEEARLIERTHANSTARTTIME',
    Valid = '',
}

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-investigate-when-filter',
    templateUrl: './when-filter.component.html',
    styleUrls: ['./when-filter.component.scss'],
    providers: [],
})
export class InvestigateWhenFilterComponent extends InvestigateFilterBaseComponent<WhenFilter> implements OnInit, AfterViewInit {
    //#region Properties

    public readonly ListSelectionType = SelectionType;
    public readonly DateFormat = DateFormat;
    public readonly CalendarFlavor = Flavor.Calendar;
    public readonly TextFlavor = TextFlavor;

    public readonly filterId = SafeGuid.newGuid().toStringFormat('N');
    public readonly CalendarTimeFrame = CalendarTimeFrame;
    public readonly dateFilterMode = DateFilterMode;
    public readonly DateTimePeriodValidationState = DateTimePeriodValidationState;

    public readonly MaxDurationValue = 999;
    public readonly MinDurationValue = 1;

    public currentLastPeriod?: LastPeriodPreset;
    public mode = DateFilterMode.DuringLastPeriod;
    public timeStart: string | null = null;
    public timeEnd: string | null = null;
    public specificDateTimePeriodValidationState: DateTimePeriodValidationState = DateTimePeriodValidationState.Valid;
    public lastPeriodFilters: LastPeriodPreset[] = [];
    public durationUnitsComboboxItems: DurationItem[] = [];
    public selectedDurationUnit?: Item;
    public lastPeriodDuration = 1;

    //#endregion

    //#region Constructors

    constructor(
        trackingService: TrackingService,
        private translateService: TranslateService,
        private dataContext: InvestigateDataContext,
        private languageService: LanguageService
    ) {
        super(trackingService);
        this.loadLastXFilters();
    }

    //#endregion

    //#region Public Methods

    ngOnInit() {
        super.ngOnInit();
        this.updateFilterState();
        // reset filter status if language changed to display the correct filter state
        this.languageService.languageChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.updateFilterState();
        });
    }

    ngAfterViewInit() {}

    //#endregion

    //#region Events

    public onFilterChange(newMode: DateFilterMode, silent: boolean = false): void {
        if (this.mode === newMode) return;
        this.mode = newMode;

        switch (this.mode) {
            case DateFilterMode.Any:
                this.timeStart = null;
                this.timeEnd = null;
                this.filterToggle.emit(false);
                break;
            case DateFilterMode.DuringLastPeriod:
            case DateFilterMode.ForNextPeriod:
                this.onDurationValueChange(this.lastPeriodDuration);
                break;
            case DateFilterMode.Range:
                this.timeStart = this.timeStart ?? moment.utc(moment().subtract(1, 'day').startOf('day')).toISOString();
                this.timeEnd = this.timeEnd ?? moment.utc(moment().endOf('day')).toISOString();
                break;
        }
        this.updateFilterState();
    }

    public onTimeStartChange(newDate: string): void {
        this.timeStart = newDate;
        this.updateFilterState();
    }

    public onTimeEndChange(newDate: string): void {
        // When we set a end time, make sure it ends at the last second of the minute
        // the UI only allow to set hour and minutes, so the seconds/ms need to be added manually
        // ex: 03h30 --> we want to query until 03h30 59sec
        // This way, if we query from 03:30 to 03:30, we will get results from events at 3:30 15sec
        try {
            this.timeEnd = this.toEndOfMinute(newDate);
        } catch {
            this.timeEnd = newDate;
        }
        this.updateFilterState();
    }

    public onDurationUnitComboboxChange(durationUnitItem: DurationItem): void {
        const durationUnitLabel = this.lastPeriodFilters.find((filter) => filter.durationUnit === durationUnitItem.durationUnit)?.label;
        if (durationUnitLabel) {
            this.setDuringLastX(new LastPeriodPreset(durationUnitLabel, this.lastPeriodDuration, durationUnitItem.durationUnit ?? 'h'));
        }
    }

    public onDurationValueChange(duration: number): void {
        const currentPeriod = this.currentLastPeriod;
        if (currentPeriod) {
            currentPeriod.duration = duration;
            this.setDuringLastX(currentPeriod);
        }
    }

    public setDuringLastX(period: LastPeriodPreset): void {
        this.mode = DateFilterMode.DuringLastPeriod;
        this.currentLastPeriod = period;
        this.timeStart = null;
        this.timeEnd = null;

        this.updateFilterState();
    }

    //#endregion

    //#region Protected Methods

    protected setupSubscription(): Subscription | undefined {
        if (!this.dataContext) return undefined;

        return this.dataContext.whenFilter$.pipe(untilDestroyed(this)).subscribe((newValue) => {
            if (newValue) {
                // special case so we don't just cycle setting value, getting value changed, setting value, etc. This will NOT trigger the value changed evt
                this.silentSetValue(newValue);
                // reset the old information (silently without triggering an update evt)
                this.mode = this.value.mode;
                const period = this.value.period;
                if (period) {
                    this.currentLastPeriod = period;

                    // Since we are using the gelato angular combobox we need to update the selectedDurationUnit prop.
                    this.selectedDurationUnit = this.durationUnitsComboboxItems.find((durationUnitItem) => durationUnitItem.durationUnit === period.durationUnit);
                    this.lastPeriodDuration = period.duration;
                }
                this.timeStart = this.value.timeStart;
                this.timeEnd = this.value.timeEnd;

                // save the new description
                this.updateFilterStatus();
                this.value.description = this.filterStatus;
            } else {
                // someone set undefined, re-set it to just an empty filter
                this.setFilterDefaultValue();
            }
        });
    }

    protected onValueChanged(): void {
        if (this.dataContext) {
            const newValue: WhenFilter = {
                description: this.value.description,
                mode: this.value.mode,
                period: this.value.period,
                timeEnd: this.value.timeEnd,
                timeStart: this.value.timeStart,
                isValid: this.value.isValid,
            };
            this.dataContext.setWhenFilter(newValue);
        }
        super.onValueChanged();
    }

    //#endregion

    //#region Private Methods

    private loadLastXFilters() {
        this.lastPeriodFilters.push(new LastPeriodPreset(DurationUnit.Minutes, 1, 'm'));
        this.lastPeriodFilters.push(new LastPeriodPreset(DurationUnit.Hours, 1, 'h'));
        this.lastPeriodFilters.push(new LastPeriodPreset(DurationUnit.Days, 1, 'd'));
        this.lastPeriodFilters.push(new LastPeriodPreset(DurationUnit.Weeks, 1, 'w'));
        this.lastPeriodFilters.push(new LastPeriodPreset(DurationUnit.Months, 1, 'M'));
        this.lastPeriodFilters.push(new LastPeriodPreset(DurationUnit.Years, 1, 'y'));

        // Default Duration Units Items
        this.durationUnitsComboboxItems = this.lastPeriodFilters.map((filter) => ({
            id: filter.id,
            text: this.translateService.instant(filter.label) as string,
            durationUnit: filter.durationUnit,
        }));
    }

    private updateFilterStatus() {
        switch (this.mode) {
            case DateFilterMode.Any:
                this.filterStatus = this.translateService.instant('STE_LABEL_ANYTIME') as string;
                break;
            case DateFilterMode.DuringLastPeriod:
            case DateFilterMode.ForNextPeriod:
                {
                    if (this.currentLastPeriod) {
                        this.filterStatus = stringFormat(
                            this.translateService.instant('STE_LABEL_LAST_XY_DURATION_UNIT') as string,
                            this.currentLastPeriod.duration.toString(),
                            this.translateService.instant(this.currentLastPeriod.label) as string
                        );
                    } else {
                        this.filterStatus = '';
                    }
                }
                break;
            case DateFilterMode.Range:
                this.filterStatus = this.translateService.instant('STE_LABEL_SPECIFICDATE') as string;
                break;
        }
    }

    private updateFilterState() {
        this.updateFilterStatus();
        const isPeriodValid = this.validateTimePeriod();
        const period = this.mode === DateFilterMode.DuringLastPeriod ? this.currentLastPeriod : null;
        this.value = {
            timeStart: this.timeStart,
            timeEnd: this.timeEnd,
            description: this.filterStatus,
            mode: this.mode,
            period: period ?? null,
            isValid: isPeriodValid,
        };
    }

    private setFilterDefaultValue() {
        this.mode = DateFilterMode.DuringLastPeriod;
        // We are using last hour preset as the default period.
        const period = this.lastPeriodFilters.find((lastPeriod) => lastPeriod.duration === 1 && ['h', 'hour', 'hours'].includes(lastPeriod.durationUnit));
        this.selectedDurationUnit = this.durationUnitsComboboxItems.find((item) => ['h', 'hour', 'hours'].includes(item.durationUnit));
        if (period) {
            this.setDuringLastX(period);
        }
    }

    private toEndOfMinute(newDate: string): string {
        const dateTimeEnd = moment(newDate, moment.ISO_8601).toDate();
        dateTimeEnd.setSeconds(59, 999);
        return dateTimeEnd.toISOString();
    }

    private validateTimePeriod(): boolean {
        let isValid = false;
        this.specificDateTimePeriodValidationState = DateTimePeriodValidationState.Valid;

        if (this.mode === DateFilterMode.Any) {
            // Anytime
            isValid = true;
        } else if (this.mode === DateFilterMode.DuringLastPeriod && this.currentLastPeriod) {
            // During the last
            isValid = this.currentLastPeriod.duration <= this.MaxDurationValue && this.currentLastPeriod.duration >= this.MinDurationValue;
        } else {
            // Specific date
            const timeStart = this.timeStart;
            const timeEnd = this.timeEnd;
            if (timeStart && timeEnd) {
                const dateTimeEnd = new Date(timeEnd);
                const dateTimeStart = new Date(timeStart);
                isValid = dateTimeEnd.getTime() >= dateTimeStart.getTime();
                this.specificDateTimePeriodValidationState = isValid ? DateTimePeriodValidationState.Valid : DateTimePeriodValidationState.InvalidRange;
            }
        }
        return isValid;
    }

    //#endregion
}
