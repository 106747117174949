import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Gelato } from '@genetec/gelato-angular';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { PluginItem } from '../../../interfaces/plugins/internal/pluginItem';
import { ContextTypes } from '../../../interfaces/plugins/public/context-types';
import { COMMANDS_SERVICE } from '../../../interfaces/plugins/public/plugin-services-public.interface';
import { CommandsUsageCollection } from '../../../services/commands/commands-usage/commands-usage-collection';
import { InternalCommandsService } from '../../../services/commands/commands.service';
import { PluginHostClickEvent } from '../plugin-host.component';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-multi-plugin-host',
    templateUrl: './multi-plugin-host.component.html',
    styleUrls: ['./multi-plugin-host.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiPluginHostComponent extends Gelato implements OnInit, OnDestroy {
    @Input() public pluginItems: PluginItem[] | null = [];
    @Input() public dataContext: any;

    @Input() public maxWidth: number | undefined = undefined;
    @Input() public maxHeigth: number | undefined = undefined;
    @Input() public captureContextMenu = true;

    @Input() public mainClass = '';
    @Input() public pluginsHostClassName = '';
    @Input() public pluginHostClassName = '';

    @Input() public supportStackedPlugins = true;

    @Output() public pluginClick = new EventEmitter<PluginHostClickEvent>();
    /**
     * Emits **true** if the context menu for one of the plugin opens, emits **false** it closes.
     */
    @Output() public contextMenu = new EventEmitter<boolean>();

    private commandsUsages = new CommandsUsageCollection();

    constructor(private hostElement: ElementRef<Element>, @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService) {
        super();
    }

    @HostListener('contextmenu', ['$event']) public onContextMenuRequested(mouseEvent: MouseEvent): void {
        if (this.captureContextMenu) {
            this.showCommands(mouseEvent).fireAndForget();

            mouseEvent.preventDefault();
            mouseEvent.stopPropagation();
        }
    }

    ngOnDestroy(): void {
        this.commandsUsages.clear();
    }

    ngOnInit() {
        if (!this.maxHeigth) {
            this.pluginsHostClassName = this.supportStackedPlugins ? 'plugins-host' : 'plugins-host-no-stack';
            this.pluginHostClassName = 'plugin-host';
        }
    }

    public onClicked(clickEvent: PluginHostClickEvent): void {
        this.pluginClick.emit(clickEvent);
    }

    private async showCommands(mouseEvent: MouseEvent) {
        // Manage ourselves the context menu if capture context is on, since we want to gather all plugins commands by specifying a target
        // else, the active target (i.e. tiles) will receive the command if same plugin as been spawned there...
        this.commandsUsages.clear();

        for (const pluginItem of this.pluginItems ?? []) {
            this.commandsUsages.addUsage(
                await this.commandsService.getCommandsUsage(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    { type: ContextTypes.Content, data: pluginItem.data },
                    pluginItem?.exposure?.availableCommands,
                    false,
                    this.hostElement.nativeElement
                )
            );
        }

        if (this.commandsService.showContextMenu(mouseEvent, await this.commandsUsages.createContextMenuItemsAsync())) {
            this.contextMenu.emit(true);

            // Emit context menu hide for this plugin host next time the context menu hides
            this.commandsService.onContextMenuHide$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
                this.contextMenu.emit(false);
            });
        }
    }
}
