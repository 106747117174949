import { InjectionToken } from '@angular/core';
import { GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { FeatureFlag } from '@modules/feature-flags/feature-flag';
import { UserSettingsService } from '@modules/shared/services/user-settings/user-settings.service';
import { Observable } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { FeaturesService } from '../../../services/features/features.service';
import { ContentGroup, PluginCommand, PluginContext } from './plugin-public.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

// #region services injection names

/**
 * Token used to inject the content services provider in your plugin. Used  to share services between related plugins.
 */
export const CONTENT_SERVICES_PROVIDER = new InjectionToken<ContentExtensionServicesProvider>('Content service provider');

/**
 * Token used this to inject the keyboad shortcut service in your plugin to intercept keyboard commands and to get the commands usage, aka commands
 * available for the given content and data.
 *
 * TODO: Technical Debt Bug 59148: WebApp - Review use of InternalCommandsService.
 * The type of this token is the interface, but throughout the app, we use InternalCommandsService as type of the injected service, which is cheating and may cause bugs in the future.
 */
export const COMMANDS_SERVICE = new InjectionToken<CommandsService>('Commands service');

/**
 * Token used to inject the side context service in your plugin (if your plugin needs to interact with it).
 * Use this to interact with the shell's side pane content.
 */
export const SIDE_CONTEXT_SERVICE = new InjectionToken<ContentService>('Side Ccontext service');

/**
 * Token used to inject the user settings service in your plugin (if your plugin needs to interact with it).
 * Use this to get and set user settings service (localStorage or not).
 */
export const USER_SETTINGS_SERVICE = new InjectionToken<UserSettingsService>('User settings service');

/**
 * Token used to inject the modal service in your plugin (if your plugin needs to interact with it).
 */
export const MODAL_SERVICE = new InjectionToken<GenModalService>('Modal service');

/**
 * Token used to inject the features service in your plugin (if your plugin needs to interact with it).
 */
export const FEATURES_SERVICE = new InjectionToken<FeaturesService>('Features service');

/**
 * Token used to inject the maps bottom context in your plugin (if your plugin needs to interact with it).
 */
export const MAPS_BOTTOM_CONTEXT_SERVICE = new InjectionToken<ContentService>('Maps bottom context service');

export const mapsServiceId: IGuid = SafeGuid.parse('153D5191-13CE-4FD6-AE9E-42802A306378');

// #endregion

export interface PublicMapService {
    select(id: string, mapId?: IGuid): void;
    updateContextualData(content: ContentGroup): void;
    clearContextualData(contentId: IGuid): void;
}

// #region Content services support

/**
 *  Service used to put a content in a state that is listened by a place holder component
 */
export interface ContentService extends PluginContext {
    currentContent$: Observable<ContentGroup | null>;

    currentContent: ContentGroup | null;

    contentCount: number;

    /**
     *  undefined content means the content isn't reachable/available while null content means there's no content ever
     */
    setMainContent(content: ContentGroup | undefined | null): void;

    clearMainContent(): void;

    pushContent(content?: ContentGroup): void;

    popContent(content?: ContentGroup): void;
}

/**
 *  Basic extension service that can be used to
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExtensionService {}

/**
 *  This service is used to be able to add service bound to a content
 */
export interface ContentExtensionServicesProvider {
    /**
     *  retrieves a service
     */
    getService<T extends ExtensionService>(contentId: IGuid): T | undefined;

    /**
     *  injects a service
     */
    setService<T extends ExtensionService>(contentId: IGuid, contentService: T): void;

    /**
     *  removes an injected service
     */
    removeService<T extends ExtensionService>(contentId: IGuid): boolean;
}

// #endregion

// #region Options extension

/**
 *  interface to get settings and set settings for a whole section
 */
export interface SettingsService {
    /**
     *  All the saved settings
     */
    onSettingsChanged$: Observable<void>;

    /**
     * The changes in the settings has been canceled.
     */
    onCancel$: Observable<void>;

    /**
     *  set a setting (only to use in the options component itself)
     */
    set(settingSectionId: IGuid, settingId: string, value: any, refreshOnApply?: boolean): void;

    /**
     *  get a setting
     */
    get<T>(settingSectionId: IGuid, settingId: string): T | undefined;

    /**
     *  get a setting content visibility
     */
    getOptionAvailability(settingSectionId: IGuid): boolean | undefined;
}

// #endregion

// #region Filters extension

/**
 *  interface to set filters
 */
export interface FiltersService {
    /**
     *  set a filter
     */
    set(filter: Filter): void;

    /**
     *  reset all filters
     */
    reset(): void;
}

/**
 *  specifies what a filter should contain in order to save its state in the store via the FiltersService
 */
export interface Filter {
    pluginId: IGuid;
    filterId: string;
    value: any;
    type: IGuid;
}

export interface Coordinate {
    x: number;
    y: number;
}

export interface MapContext {
    mapId: IGuid;
    geoLocalised: boolean;
    location: GeoCoordinate | Coordinate;
}

export interface GeoCoordinate {
    latitude: number;
    longitude: number;
    altitude?: number;
}

// #endregion

// #region Commands

export interface CommandDisplay {
    /**
     *  icon of the command
     */
    icon?: MeltedIcon;
    /**
     *  name of the command (method here to always have right language)
     */
    name: () => string;
    /**
     *  tooltip of the command (method here to always have right language)
     */
    tooltip?: () => string;
    isAllowed?: () => boolean;
}

export interface CommandContext {
    type: IGuid;
    data: any;
    target?: Element;
}

export interface ExecuteCommandData {
    commandContext?: CommandContext;
    event?: Event;
    isHandled?: boolean;
}

/**
 *  Handler of the command (execution)
 */
export type ExecuteCommandHandler = (executeCommandData: ExecuteCommandData) => void;

/**
 *  can execute of the command ( can execution)
 */
export type CanExecuteCommandHandler = (commandContext?: CommandContext) => Promise<boolean> | boolean;

/**
 *  is command available handler
 */
export type IsCommandAvailableHandler = (commandContext?: CommandContext) => Promise<boolean> | boolean;

/**
 *  handler to get the name and icon of the command if it can be diffrent from the default one
 */
export type GetCommandDisplayHandler = (commandContext?: CommandContext) => Promise<CommandDisplay | undefined> | CommandDisplay | undefined;

export interface CommandProvider {
    readonly priority: number;
    readonly requiredFeatures: Set<IGuid>;
    getAvailableCommandIdsAsync(commandContext: CommandContext): Promise<IGuid[]>;
}

// eslint-disable max-classes-per-file
export class CommandEntry {
    public targetData?: CommandContext;
    public target?: Element;

    public get key(): string | undefined {
        return this.keyValue;
    }

    public get executeHandler(): ExecuteCommandHandler | undefined {
        return this.handlerValue;
    }

    public get canExecuteHandler(): CanExecuteCommandHandler | undefined {
        return this.canExecuteHandlerValue;
    }

    public get isAvailableHandler(): IsCommandAvailableHandler | undefined {
        return this.isAvailableHandlerValue;
    }

    public get getDisplayHandler(): GetCommandDisplayHandler | undefined {
        return this.getDisplayHandlerValue;
    }

    private keyValue?: string;

    constructor(
        public commandId: IGuid,
        private handlerValue: ExecuteCommandHandler,
        private isAvailableHandlerValue?: IsCommandAvailableHandler,
        private canExecuteHandlerValue?: CanExecuteCommandHandler,
        private getDisplayHandlerValue?: GetCommandDisplayHandler
    ) {}
}

/**
 *  Use this to map a command to a receiver of the command (execution code)
 */
// eslint-disable max-classes-per-file
export class CommandBindings {
    /**
     *  map the entries with their command id
     */
    private commandIds = new Map<string, CommandEntry>();

    /**
     *  map the entries with their command keys
     */
    private commandKeys = new Map<string, CommandEntry>();

    public addCommand(commandInfo: {
        commandId: IGuid;
        executeCommandHandler: ExecuteCommandHandler;
        isCommandAvailableHandler?: IsCommandAvailableHandler;
        canExecuteCommandHandler?: CanExecuteCommandHandler;
        getCommandDisplayHandler?: GetCommandDisplayHandler;
    }): void {
        const entry = new CommandEntry(
            commandInfo.commandId,
            commandInfo.executeCommandHandler,
            commandInfo.isCommandAvailableHandler,
            commandInfo.canExecuteCommandHandler,
            commandInfo.getCommandDisplayHandler
        );
        this.commandIds.set(commandInfo.commandId.toString().toLowerCase(), entry);
    }

    public assignKey(commandId: IGuid, key: string): void {
        /**
         *  TODO: Ensure the key is not used somehwere else
         */
        const entry = this.getCommand(commandId);
        if (entry) {
            /**
             *  this is a private field so cast as any
             */
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (entry as any).keyValue = key;

            /**
             *  add an entry in the Map to find it faster
             */
            this.commandKeys.set(key, entry);
        }
    }

    public getCommand(commandId: IGuid): CommandEntry | undefined {
        return this.commandIds.get(commandId.toString().toLowerCase());
    }

    public getCommandFromKey(key: string): CommandEntry | undefined {
        return this.commandKeys.get(key);
    }

    public getCommands(): CommandEntry[] {
        const result: CommandEntry[] = Array.from(this.commandIds.values());
        return result;
    }
}

export interface PluginComponentCommandHandler {
    getCommandBindings(): CommandBindings;
}

/**
 *  options passed to the subscribe
 */
export interface CommandSubscriptionOptions {
    /**
     *  used to customize the priority of commands to be executed (mostly used for keyboard shortcuts)
     *  This way, if two different views are listening to the same global key-event,
     *  the 'winning' event-handler becomes the one with the higher priority; it no longer depends on the coincidental structure of the component tree.
     */
    priority: number;
}

/**
 *  use this to unsubscribe, an object of this type is received after subscribing to command(s)
 */
export interface CommandsSubscription {
    unsubscribe(): void;
}

export interface CommandRequirements {
    supportedContentTypes?: Set<IGuid>;
    requiredPrivileges?: Set<IGuid>;
    requiredFeatures?: Set<IGuid>;
    enabledFeatureFlags?: Set<FeatureFlag>;
}

export interface EntityCommandRequirements extends CommandRequirements {
    isFederated?: boolean;
    supportedEntityTypes?: Set<string>;
}

/**
 *  Service to register for a command
 */
export interface CommandsService {
    /**
     *  determine the currently active element for commands having a target (like tiles)
     */
    activeTarget: Element | undefined;

    /**
     *  subscribe to a command by specifying the receiver of the command (execution of the command)
     */
    subscribe(bindings: CommandBindings, options: CommandSubscriptionOptions): CommandsSubscription;

    /**
     *  register the list of commands
     */
    registerCommands(commands: PluginCommand[]): void;

    /**
     *  appends requirements for a known command
     */
    appendRequirements(commandId: IGuid, requirements: CommandRequirements | EntityCommandRequirements): void;

    /**
     *  Registers a command to a keyboard shortcut
     *  Note: no need to register the command if you fill up the availableCommands property of the pluginComponent
     */
    registerCommandShortcut(commandId: IGuid, shortcut: string): void;

    /**
     *  registers a context command provider, those providers will be called to get supported commands given a context
     */
    registerCommandProvider(provider: CommandProvider): void;

    /**
     *  execute the specified command
     */
    executeCommand(commandId: IGuid, commandContext?: CommandContext, event?: Event, targetElement?: Element): void;

    /**
     *  checks if the specified command can be executed
     */
    canExecuteCommandAsync(commandId: IGuid, commandContext?: CommandContext): Promise<boolean>;

    /**
     *  gets the name and icon of the command that fits the specified arguments
     */
    getCommandDisplayAsync(commandId: IGuid, commandContext?: CommandContext): Promise<CommandDisplay | undefined>;

    /**
     *  execute the specified command forcefully (without executing the can execute handler because it was premptively done)
     */
    forceExecuteCommand(commandId: IGuid, commandContext?: CommandContext, event?: Event, targetElement?: Element): void;

    /**
     *  gets the command having the specified id
     */
    getCommandAsync(commandId: IGuid, executable?: boolean, commandContext?: CommandContext): Promise<PluginCommand | undefined>;

    /**
     *  gets the specified commands
     */
    getCommandsAsync(commandIds: IGuid[], executable?: boolean, commandContext?: CommandContext): Promise<PluginCommand[]>;

    /**
     *  gets the available commands for the given context
     */
    getAvailableCommandsAsync(contextArgs: CommandContext): Promise<PluginCommand[]>;

    /**
     * Hides the current context menu
     */
    hideCurrentContextMenu(): void;
}
