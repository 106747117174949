<div class="gen-combobox {{ genFlavor }}" [attr.disabled]="disabled || null">
    <!-- Optional label on top of the combobox -->
    <label class="gen-label" *ngIf="genLabel">{{ genLabel }} </label>
    <div
        #genFormControl
        class="gen-combobox-input"
        [id]="comboboxId"
        genKeyboardNavigation
        [genAutomaticNavigation]="true"
        genFindElementQuery="div.gen-item"
        appClickActionOnSpaceEnter
        (click)="toggleDropdown($event)"
        (genOffClick)="closeDropdown()"
        [genOffClickIf]="popup.open"
    >
        <!-- If no item selected, display a placeholder -->
        <div class="gen-combobox-input-text fg-color-primary-darker" *ngIf="values.length === 0">
            {{ genPlaceholder }}
        </div>
        <!-- Selected item -->
        <div class="gen-combobox-input-text" *ngIf="values.length === 1">
            <gen-icon *ngIf="values[0].icon" [icon]="values[0].icon"></gen-icon>
            <span>{{ values[0].text }}</span>
        </div>
        <!-- Selected item"S" -->
        <div class="gen-combobox-input-text" *ngIf="values.length > 1 && multiSelect">
            <span> {{ selectedText }} </span>
        </div>
        <div class="gen-combobox-input-caret">
            <i class="gen-ico-angle-down"></i>
        </div>
    </div>
    <gen-popup [position]="PopupPosition.BottomLeft" targetSelector="#{{ comboboxId }}" [fitTargetWidth]="true" appFocusPopupWhenOpen>
        <div class="popup-body">
            <gen-search-input
                *ngIf="options.length > 15"
                [genDelay]="100"
                placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
                [ngModel]="searchText"
                (ngModelChange)="onSearchChange($event)"
                (click)="searchInputClick($event)"
            >
            </gen-search-input>
            <div class="gen-list" style="max-height: 400px">
                <ul readonly>
                    <!-- Template for each item -->
                    <li
                        *ngFor="let item of visibleOptions"
                        id="item-{{ item.id }}"
                        [tabindex]="0"
                        [ngClass]="{
                            active: item.isChecked,
                            'pl-1': true,
                            'pr-1': true
                        }"
                        (click)="selectItem(item)"
                    >
                        <gen-checkbox
                            *ngIf="multiSelect"
                            [ngModel]="item.isChecked"
                            style="padding-left: 16px; padding-top: 8px; height: 24px"
                            (ngModelChange)="onItemCheckChanged($event, item.id)"
                        >
                        </gen-checkbox>
                        <gen-item class="va-center gen-item" [highlightColor]="item.highlightColor">
                            <gen-image *ngIf="item.image" [src]="item.image" [alt]="item.alt"></gen-image>
                            <gen-icon *ngIf="item.icon" [icon]="item.icon"></gen-icon>
                            <gen-text>{{ item.text }}</gen-text>
                            <gen-text *ngIf="item.secondaryText" [slot]="ItemSlot.TextSecondary">{{ item.secondaryText }}</gen-text>
                        </gen-item>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="genShowClearButton || multiSelect">
            <gen-button *ngIf="genShowClearButton" (click)="clearSelection($event)" genStopPropagation [flavor]="ButtonFlavor.Flat">
                {{ 'STE_LABEL_CLEAR' | translate }}
            </gen-button>
            <!-- In the event that multiselect has been chosen, we need to add an Ok button so that the user can close the popup after making their selections -->
            <gen-button *ngIf="multiSelect" (click)="closeDropdown()" genStopPropagation [flavor]="ButtonFlavor.Flat">
                {{ 'STE_LABEL_OK' | translate }}
            </gen-button>
        </div>
    </gen-popup>
</div>
