import { InternalDewarperSourceImageParameters } from '../DewarperSourceImageParameters';
import { FloatPrecision, FragmentShader } from '../WebGL2/FragmentShader';

export class DewarpedAreaFragmentShader extends FragmentShader {
  constructor(gl: WebGL2RenderingContext, dewarperSourceImageParameters: InternalDewarperSourceImageParameters) {
    super(gl, new DewarpedAreaFragmentShaderCodeBuilder(dewarperSourceImageParameters).build(), FloatPrecision.medium);
  }
}

export class DewarpedAreaFragmentShaderCodeBuilder {
  private readonly m_dewarperSourceImageParameters: InternalDewarperSourceImageParameters;

  constructor(dewarperSourceImageParameters: InternalDewarperSourceImageParameters) {
    this.m_dewarperSourceImageParameters = dewarperSourceImageParameters;
  }

  public build(): string {
    return `${DewarpedAreaFragmentShaderCodeBuilder.Variables}

${this.getToPolar()}

${this.toCartesian()}

${this.main()}
`;
  }

  private static readonly Variables: string =
    `

uniform vec4 u_dewarpedAreaColor;  // The dewarped area color
uniform vec4 u_dewarpedAreaBorderColor; // The dewarped area border color

uniform vec2 u_viewportResolution;  // Resolution of this viewport
uniform vec2 u_viewportOffset;      // vertical viewport offset. Will influence the gl_FragCoord

uniform vec2 u_warpedImageResolution; // Resolution of the warped image

uniform vec2 u_lowerDewarpedLimit;
uniform vec2 u_higherDewarpedLimit;

uniform float u_borderWidthRatio;

// The output color of the fragment shader execution
out vec4 outputColor;

uniform vec4 u_dewarpingPlane; //stored in this order: (A, B, C, D)

uniform vec3 u_pPrime;  //Vector/Normal to origin of the dewarping plane
uniform vec3 u_uHat;    //Horizontal normalized vector of the dewarping plane (equivalent of x-Axis)
uniform vec3 u_vHat;    //Vertical normalized vector of the dewarping plane (equivalent of y-Axis)

`;

  private getToPolar(): string {
    return `

// Inputs : Cartesian coordinates
// Output : Polar coordinates (x: radius, y: angle)
vec2 toPolar(vec2 cartesian) {
    return vec2(
        sqrt(pow(cartesian.x, 2.0) + pow(cartesian.y, 2.0)),
        atan(cartesian.y, cartesian.x));
}

`;
  }

  private toCartesian(): string {
    return `

// Inputs : Polar coordinates (x: radius, y: beta, z: delta) beta: angle vs z axis, delta: angle on the x-y plane
// Output : Cartesian coordinates
vec3 toCartesian(vec3 polar) {
  float sinBeta = sin(polar.y);
  float cosBeta = cos(polar.y);
  float sinDelta = sin(polar.z);
  float cosDelta = cos(polar.z);
  vec3 cartesian = vec3(sinBeta * cosDelta, sinBeta * sinDelta, cosBeta);
  return cartesian * polar.x;
}
`;
  }

  private getReversedRdFunction(variableName: string): string {
    return this.m_dewarperSourceImageParameters.Projection.reverseCode(variableName);
  }


  private main(): string {
    return `

void main()
{
  // Will be called from (0, 0) to viewPort (which should be the canvas resolution)
  // gl_FragCoord (0, 0) is lower left and is in pixel. <-- Maybe not so true with the viewport being set on the 'y' axis value and the aspectRatio overflow computation
  vec2 v = gl_FragCoord.xy - u_viewportOffset;

  // scale view port pixels to the warped image resolution
  v = v * u_warpedImageResolution;
  v = v / u_viewportResolution;

  //Translate to circle center
  v = v - (vec2(${toFloat(this.m_dewarperSourceImageParameters.CircleCenter.X)}, ${toFloat(this.m_dewarperSourceImageParameters.CircleCenter.Y)}) * u_warpedImageResolution);

  //Scale to circle resolution
  v = v / (${toFloat(this.m_dewarperSourceImageParameters.RadiusRatio)} * u_warpedImageResolution.y);

  vec2 polar = toPolar(v);

  //Early exit for outside of dewarping circle
  if (polar.x > 1.0)
  {
    return;
  }

  float delta = polar.y;

  //reversed projection
  vec3 polarXYZ = vec3(1.0, ${this.getReversedRdFunction('(polar.x)')}, delta);

  vec3 v3 = toCartesian(polarXYZ);
  float t = -u_dewarpingPlane[3] / (u_dewarpingPlane[0] * v3.x + u_dewarpingPlane[1] * v3.y + u_dewarpingPlane[2] * v3.z);

  v3 = v3 * t;

  //back to 2D:
  v3 = v3 - u_pPrime;

  //a = u_uHat.x;
  //b = u_vHat.x;
  //c = v3.x;
  //d = u_uHat.y;
  //e = u_vHat.y;
  //f = v3.y;
  //float y = (a * f - d * c) / (a * e - d * b);
  //float x = (c - b * y) / a;

  float y = 0.0;
  float x = 0.0;
  if (u_uHat.x == 0.0) {
    // ax + by = c
    // dx + ey = f
    // when a == 0 becomes
    // by = c
    // y = c/b
    // And knowing y, we can do the second equation. Isolate 'x'
    // dx + ey = f
    // dx = f - ey
    // x = (f - ey)/d

    y = v3.x / u_vHat.x;
    x = (v3.y - u_vHat.y * y) / u_uHat.y;
  } else {
    //isolating x in first equation gives
    // x = (c - by)/a

    //Replacing x by [(c - by)/a] in second equation gives
    // (d((c - by)/a) + ey = f

    //isolating y in second equation gives
    //y = (af - dc)/(ae - db)
    y = (u_uHat.x * v3.y - u_uHat.y * v3.x) / (u_uHat.x * u_vHat.y - u_uHat.y * u_vHat.x);
    x = (v3.x - u_vHat.x * y) / u_uHat.x;
  }

  if (u_lowerDewarpedLimit.x <= x && x <= u_higherDewarpedLimit.x &&
      u_lowerDewarpedLimit.y <= y && y <= u_higherDewarpedLimit.y)
  {
    if (u_lowerDewarpedLimit.x + u_borderWidthRatio < x && x < u_higherDewarpedLimit.x - u_borderWidthRatio &&
        u_lowerDewarpedLimit.y + u_borderWidthRatio < y && y < u_higherDewarpedLimit.y - u_borderWidthRatio)
    {
      //Inside the border
      outputColor = u_dewarpedAreaColor;
    }
    else
    {
      //The border itself
      outputColor = u_dewarpedAreaBorderColor;
    }
  }
}

`;
  }
}

function toFloat(x: number): string {
  return Number.isInteger(x) ? x + '.0' : x.toString();
}
