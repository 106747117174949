import { ApplicationRef, enableProdMode, isDevMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { Constants } from './constants';
import { environment, getBaseUrl } from './environments/environment';

// base url is the location of server
const providers = [{ provide: Constants.baseUrlIdentifier, useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .then((moduleRef) => {
        // in dev mode, start the angular profiler to diagnose performance issues
        if (isDevMode()) {
            const applicationRef = moduleRef.injector.get(ApplicationRef);
            const componentRef = applicationRef.components[0];
            // allows to run 'ng.profiler.timeChangeDetection();'
            enableDebugTools(componentRef);
        }
    })
    .catch((err) => console.log(err));
