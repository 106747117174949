import { WebSocketPayloadV2 } from '../../services/WebSocketTransportV2';
import { Deserializer } from '../../utils/Deserializer';
import { WebSocketMessageHeader, CreateStreamResponse, CreateStreamResponseBody, UpdateStreamResponse, SuccessResponse, ErrorResponse, SuccessResponseBody, UpdateStreamResponseBody, ErrorResponseBody } from './Messages';

export enum WebSocketMessageTypeV2 {
  Segment = 'H264',
  Jpeg = 'JPEG',
  PlaySpeed = 'PlaySpeed',
  Buffering = 'Buffering',
  Error = 'Error',
  StreamingStatus = 'StreamingStatus',
  PlayerState = 'PlayerState',
  TimelineUpdate = 'TimelineUpdate',
  SuccessResponse = 'SuccessResponse',
  ErrorResponse = 'ErrorResponse',
  CreateStreamResponse = 'CreateStreamResponse',
  UpdateStreamResponse = 'UpdateStreamResponse',
}

export interface IMessageParser {
  parse(arrayBuffer: ArrayBuffer): WebSocketPayloadV2 | undefined;
}

export class MessageParser implements IMessageParser {
  public parse(arrayBuffer: ArrayBuffer): WebSocketPayloadV2 | undefined {
    return new MessageParsing(arrayBuffer).parseMsg();
  }
}

class MessageParsing {
  private static readonly version = 1; // Still kind of an hybrid between version 1 and 2. todo: put '2' here

  private static readonly SizeOfInt = 4;

  private readonly m_arrayBuffer: ArrayBuffer;

  private readonly m_deserializer: Deserializer;

  constructor(arrayBuffer: ArrayBuffer) {
    this.m_arrayBuffer = arrayBuffer;
    this.m_deserializer = new Deserializer(arrayBuffer);
  }

  public parseMsg(): WebSocketPayloadV2 | undefined {
    this.readVersion();
    const channelId = this.readChannel();
    const messageType = this.readMessageType();

    switch (messageType) {
      case WebSocketMessageTypeV2.Segment:
      case WebSocketMessageTypeV2.Jpeg:
        const streamEvent = this.readEvent();
        const payload = new Int8Array(this.m_arrayBuffer.slice(streamEvent.DataOffset + MessageParsing.SizeOfInt));
        return new WebSocketPayloadV2(messageType, channelId, streamEvent.Event, payload);

      case WebSocketMessageTypeV2.PlaySpeed:
      case WebSocketMessageTypeV2.Buffering:
      case WebSocketMessageTypeV2.Error:
      case WebSocketMessageTypeV2.StreamingStatus:
      case WebSocketMessageTypeV2.PlayerState:
      case WebSocketMessageTypeV2.TimelineUpdate:
        const event = this.readEvent();
        return new WebSocketPayloadV2(messageType, channelId, event.Event);

      case WebSocketMessageTypeV2.UpdateStreamResponse:
        const updateStreamResponseHeader = new WebSocketMessageHeader(channelId, messageType);
        const updateStreamResponseBody = this.readUpdateStreamResponseBody();
        return new WebSocketPayloadV2(messageType, channelId, new UpdateStreamResponse(updateStreamResponseHeader, updateStreamResponseBody));

      case WebSocketMessageTypeV2.SuccessResponse:
        const successHeader = new WebSocketMessageHeader(channelId, messageType);
        const successBody = this.readSuccessResponseBody();
        return new WebSocketPayloadV2(messageType, channelId, new SuccessResponse(successHeader, successBody));

      case WebSocketMessageTypeV2.ErrorResponse:
        const errorHeader = new WebSocketMessageHeader(channelId, messageType);
        const errorBody = this.readErrorResponseBody();
        return new WebSocketPayloadV2(messageType, channelId, new ErrorResponse(errorHeader, errorBody));

      case WebSocketMessageTypeV2.CreateStreamResponse:
        const responseHeader = new WebSocketMessageHeader(channelId, messageType);
        const responseBody = this.readCreateStreamResponseBody();
        return new WebSocketPayloadV2(messageType, channelId, new CreateStreamResponse(responseHeader, responseBody));

      // DH : Don't want console output on custom message
      // default:
      //   console.error(`Message [${messageType}] isn\'t supported by this version of marmot`);
    }
    return undefined;
  }

  private readSuccessResponseBody(): SuccessResponseBody {
    const text = this.m_deserializer.getString();
    return SuccessResponseBody.deserialize(text);
  }

  private readUpdateStreamResponseBody(): UpdateStreamResponseBody {
    const text = this.m_deserializer.getString();
    return UpdateStreamResponseBody.deserialize(text);
  }

  private readErrorResponseBody(): ErrorResponseBody {
    const text = this.m_deserializer.getString();
    return ErrorResponseBody.deserialize(text);
  }

  private readCreateStreamResponseBody(): CreateStreamResponseBody {
    const text = this.m_deserializer.getString();
    return CreateStreamResponseBody.deserialize(text);
  }

  private readVersion() {
    const version = this.m_deserializer.getUint8();
    if (version !== MessageParsing.version) {
      const msg = `WebSocket transport version ${version} isn\'t supported`;
      console.error(msg);
      throw new Error(msg);
    }
  }

  private readChannel() {
    return this.m_deserializer.getUint32();
  }

  private readMessageType(): string {
    return this.m_deserializer.getString();
  }

  private readEvent() {
    const jsonString = this.m_deserializer.getString();
    const object = JSON.parse(jsonString);
    return { Event: object, DataOffset: this.m_deserializer.Offset };
  }
}
