import { NormalizedColor } from '../utils/Color';
import { ILiteEvent } from '../utils/liteEvents';
import { ILogger } from '../utils/logger';
import { Resolution } from '../utils/Resolution';
import { CanvasWrapper } from './CanvasWrapper';
import { GLUtils } from './WebGL2/GLUtils';
import { Cancellation } from '../utils/CancellationToken';

export interface IDewarperCanvas {
  readonly Resolution: Resolution;
  readonly Color: NormalizedColor;

  readonly Resized: ILiteEvent<Resolution>;
  readonly StyleChange: Promise<void | Cancellation>;

  Show: boolean;

  dispose(): void;
  getWebGL2Context(): WebGL2RenderingContext;

  debugStatus(indent: number): string;
}

export class DewarperCanvas extends CanvasWrapper implements IDewarperCanvas {
  private readonly m_gl: WebGL2RenderingContext;

  constructor(logger: ILogger, htmlCanvasElement: HTMLCanvasElement) {
    super(logger, 'DewarperCanvas', htmlCanvasElement);
    this.m_gl = GLUtils.getWebGL2Context(htmlCanvasElement);
  }

  public getWebGL2Context(): WebGL2RenderingContext {
    return this.m_gl;
  }
}

