import { Injectable } from '@angular/core';
import { EmitterAction } from '@ngxs-labs/emitter';
import { State, StateContext } from '@ngxs/store';
import { Filter, FiltersMap } from '@shared/services/filters/filter';
import { Receiver, Selector } from '@src/app/store';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface FiltersStateModel {
    filters: FiltersMap;
}

@State<FiltersStateModel>({
    name: 'filtersState',
    defaults: { filters: new Map<string, Filter>() },
})
@Injectable()
export class FiltersState {
    @Selector()
    public static filters(state: FiltersStateModel): FiltersMap {
        return state.filters;
    }

    @Receiver({ type: '[ReportFilters] set filters' })
    public static set({ setState }: StateContext<FiltersStateModel>, { payload: payload }: EmitterAction<Filter>): void {
        setState((state: FiltersStateModel) => {
            if (state.filters) {
                state.filters.set(payload.filterId, payload);
            }
            return state;
        });
    }

    @Receiver({ type: '[ReportFilters] reset filters' })
    public static reset({ setState }: StateContext<FiltersStateModel>): void {
        setState((state: FiltersStateModel) => {
            state.filters = new Map();
            return state;
        });
    }
}
