import { Injectable, OnDestroy } from '@angular/core';
import { IWatchlistEntry, UserWatchlistClient, Watchlist, WatchlistEntry, WatchlistPriority } from '@modules/shared/api/api';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, shareReplay, skip, switchMap, takeUntil, take, tap } from 'rxjs/operators';
import { GuidSet, IGuid } from 'safeguid';
import { AdvancedSettingsService } from '../advanced-settings/advanced-settings.service';
import { EventMonitoringFeatureFlags } from '../events/feature-flags';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class UserWatchlistService implements OnDestroy {
    public watchlist$: Observable<Watchlist | null>;
    public added$: Observable<IWatchlistEntry[]>;
    public removed$: Observable<IGuid>;
    public modified$: Observable<IWatchlistEntry>;

    private watchlist: Watchlist | null = null;

    private addedSubject$ = new Subject<IWatchlistEntry[]>();
    private watchlistSubject$ = new BehaviorSubject<Watchlist | null>(null);
    private removedSubject$ = new Subject<IGuid>();
    private modifiedSubject$ = new Subject<IWatchlistEntry>();
    private onLoggedOut$ = new Subject();

    constructor(private userWatchlistClient: UserWatchlistClient, private authService: AuthService, private advancedSettingsService: AdvancedSettingsService) {
        this.added$ = this.addedSubject$.asObservable();
        this.watchlist$ = this.watchlistSubject$.asObservable().pipe(shareReplay(1));
        this.modified$ = this.modifiedSubject$.asObservable();
        this.removed$ = this.removedSubject$.asObservable();
        this.subscribeAuthService();
    }

    ngOnDestroy(): void {
        this.onLoggedOut();
    }

    public add(entityIds: IGuid | IGuid[]): void {
        if (this.watchlist) {
            const addedEntries: WatchlistEntry[] = [];
            const guids = Array.isArray(entityIds) ? entityIds : [entityIds];
            const guidsNotAlreadyAdded = new GuidSet(guids.filter((entityId) => !this.getExistingEntry(entityId)));
            for (const entityId of guidsNotAlreadyAdded) {
                const newItem = new WatchlistEntry({ entityId, priority: WatchlistPriority.Normal });
                this.watchlist.entries.push(newItem);
                addedEntries.push(newItem);
            }

            if (addedEntries.length > 0) {
                this.addedSubject$.next(addedEntries);
                this.updateWatchlist();
            }
        }
    }

    public remove(entityId: IGuid): void {
        const existingEntry = this.getExistingEntry(entityId);
        if (this.watchlist && existingEntry) {
            this.watchlist.entries.remove(existingEntry);
            this.removedSubject$.next(entityId);
            this.updateWatchlist();
        }
    }

    public updateIsFlagged(entityId: IGuid, isFlagged: boolean): void {
        const existingEntry = this.getExistingEntry(entityId);
        const newPriority = this.getItemPriority(isFlagged);
        if (existingEntry && existingEntry.priority !== newPriority) {
            existingEntry.priority = this.getItemPriority(isFlagged);
            this.modifiedSubject$.next(existingEntry);
            this.updateWatchlist();
        }
    }

    public isEntityBeingWatched(entityId: IGuid): boolean {
        return !!this.getExistingEntry(entityId);
    }

    private getExistingEntry(entityId: IGuid): WatchlistEntry | null {
        return this.watchlist?.entries.find((entry) => entry.entityId?.equals(entityId)) ?? null;
    }

    private getItemPriority(isFlagged: boolean): WatchlistPriority {
        return isFlagged ? WatchlistPriority.Flagged : WatchlistPriority.Normal;
    }

    private saveWatchlist(): void {
        if (this.watchlist) {
            this.userWatchlistClient.setUserWatchlist(this.watchlist).pipe(take(1), untilDestroyed(this)).subscribe();
        }
    }

    private subscribeAuthService(): void {
        this.authService.loggedIn$
            .pipe(
                filter((isLoggedIn) => !isLoggedIn || this.isEventMonitoringFlagEnabled()),
                tap((isLoggedIn) => (isLoggedIn ? this.onLoggedIn() : this.onLoggedOut())),
                switchMap((isLoggedIn) => (isLoggedIn ? this.fetchUserWatchlist().pipe(switchMap(() => this.saveWatchlistWhenUpdated())) : of(undefined))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private isEventMonitoringFlagEnabled(): boolean {
        return EventMonitoringFeatureFlags.General.isEnabled(this.advancedSettingsService);
    }

    private fetchUserWatchlist(): Observable<Watchlist | null> {
        return this.userWatchlistClient.getUserWatchlist().pipe(
            take(1),
            untilDestroyed(this),
            tap((watchlist) => {
                this.watchlist = watchlist;
                this.updateWatchlist();
            })
        );
    }

    private saveWatchlistWhenUpdated(): Observable<Watchlist | null> {
        return this.watchlist$.pipe(
            // Skip first result, since it's a BehaviorSubject (will send result when subscribed).
            skip(1),
            tap(() => this.saveWatchlist()),
            takeUntil(this.onLoggedOut$)
        );
    }

    private onLoggedIn(): void {
        if (!this.onLoggedOut$.closed) {
            this.onLoggedOut();
        }
        this.onLoggedOut$ = new Subject();
    }

    private onLoggedOut(): void {
        if (this.onLoggedOut$) {
            this.onLoggedOut$.next();
            this.onLoggedOut$.complete();
        }
        this.watchlist = null;
        this.updateWatchlist();
    }

    private updateWatchlist(): void {
        this.watchlistSubject$.next(this.watchlist);
    }
}
