import { SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class CorrelationFeatures {
    public static readonly investigationTaskFeature = SafeGuid.parse('89ddadb9-b63c-4e65-999d-a06b8c0c3d1b');
    public static readonly ingestDataFeature = SafeGuid.parse('c1259362-89d0-43cf-9ecb-2946ca312a54');
    public static readonly mobileUserDatumFeatureId = SafeGuid.parse('19435acc-4456-4786-adfc-470f76f7d2cc');
    public static readonly datumSidepaneFeatureId = SafeGuid.parse('9c5d4232-319f-4ea6-913d-222e49d15098');
    public static readonly aggregationWidgetFeatureId = SafeGuid.parse('c90654b2-5885-44f2-be85-88b4dc9a9823');

    public static readonly all = [
        CorrelationFeatures.investigationTaskFeature,
        CorrelationFeatures.ingestDataFeature,
        CorrelationFeatures.mobileUserDatumFeatureId,
        CorrelationFeatures.datumSidepaneFeatureId,
        CorrelationFeatures.aggregationWidgetFeatureId,
    ];
}
