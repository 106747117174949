<div *ngIf="eventsSource; else loading">
    <ng-container *ngIf="eventsSource.length > 0; else noData">
        <gen-tree [selectionType]="SelectionType.Multiple">
            <ng-container *ngFor="let item of eventsSource; let parentIndex = index">
                <gen-tree-item [selected]="item.isChecked" (selectedChange)="onSelectedItemsChange(item, $event)" [expanded]="keepParentExpanded">
                    <gen-item>
                        <gen-icon [icon]="item.icon"></gen-icon>
                        <gen-text [title]="item.text">{{ item.text }}</gen-text>
                    </gen-item>
                    <ng-container *ngIf="item.children">
                        <gen-tree>
                            <ng-container *ngFor="let child of item.children; let childIndex = index">
                                <gen-tree-item [selected]="child.isChecked" (selectedChange)="onSelectedItemsChange(child, $event)">
                                    <gen-item>
                                        <gen-icon [icon]="child.icon"></gen-icon>
                                        <gen-text id="child-with-tooltip-{{ parentIndex }}-{{ childIndex }}" [appTooltipOnlyIfOverflow]="true" [appTooltip]="child.text">
                                            {{ child.text }}
                                        </gen-text>
                                    </gen-item>
                                </gen-tree-item>
                            </ng-container>
                        </gen-tree>
                    </ng-container>
                </gen-tree-item>
            </ng-container>
        </gen-tree>
    </ng-container>
</div>

<ng-template #loading>
    <gen-spinner class="d-block p-3"></gen-spinner>
</ng-template>

<ng-template #noData>
    <gen-text class="d-block pt-2" [flavor]="TextFlavor.Secondary"> {{ 'STE_LABEL_NORESULTS' | translate }}</gen-text>
</ng-template>
