// Autogenerated: CodeGen/ExternalPluginDescriptor?language=ts&InterfaceOnly=True on 2020-08-21 8:19:16 AM
// Autogenerated: CodeGen/ExternalPluginDescriptor?language=ts&InterfaceOnly=False on 2020-08-21 8:19:16 AM
// Autogenerated: CodeGen/ExternalPluginDependency?language=ts&InterfaceOnly=True on 2020-08-21 8:19:16 AM
// Autogenerated: CodeGen/ExternalPluginDependency?language=ts&InterfaceOnly=False on 2020-08-21 8:19:16 AM
// Autogenerated: CodeGen/ModulePluginDescriptor?language=ts&InterfaceOnly=True on 2020-08-21 8:19:16 AM
// Autogenerated: CodeGen/ModulePluginDescriptor?language=ts&InterfaceOnly=False on 2020-08-21 8:19:16 AM
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';

export interface IExternalPluginDescriptor extends IFieldObject {
    packageFileName: string;
    pluginDescriptor: IModulePluginDescriptor;
    dependencies: ObservableCollection<IExternalPluginDependency>;
}

export class ExternalPluginDescriptor extends FieldObject implements IExternalPluginDescriptor {
    // PackageFileName
    public get packageFileName(): string {
        return this.getField<string>('PackageFileName');
    }
    public set packageFileName(value: string) {
        this.setField<string>('PackageFileName', value);
    }
    // PluginDescriptor
    public get pluginDescriptor(): IModulePluginDescriptor {
        return this.getFieldObject<ModulePluginDescriptor, IModulePluginDescriptor>(ModulePluginDescriptor, 'PluginDescriptor');
    }
    public set pluginDescriptor(value: IModulePluginDescriptor) {
        this.setFieldObject<IModulePluginDescriptor>('PluginDescriptor', value);
    }
    // Dependencies
    public get dependencies(): ObservableCollection<IExternalPluginDependency> {
        return this.getFieldObjectArray<ExternalPluginDependency, IExternalPluginDependency>(ExternalPluginDependency, 'Dependencies');
    }
    public set dependencies(value: ObservableCollection<IExternalPluginDependency>) {
        this.setFieldObjectArray('Dependencies', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('PackageFileName', '');
        const objPluginDescriptor = new ModulePluginDescriptor();
        objPluginDescriptor.initializeAllFields();
        this.setFieldObject('PluginDescriptor', objPluginDescriptor);
        this.setFieldArray<IExternalPluginDependency>('Dependencies', new ObservableCollection<IExternalPluginDependency>());
    }
}

export interface IExternalPluginDependency extends IFieldObject {
    key: string;
    libraryName: string;
}

export class ExternalPluginDependency extends FieldObject implements IExternalPluginDependency {
    // Key
    public get key(): string {
        return this.getField<string>('Key');
    }
    public set key(value: string) {
        this.setField<string>('Key', value);
    }
    // LibraryName
    public get libraryName(): string {
        return this.getField<string>('LibraryName');
    }
    public set libraryName(value: string) {
        this.setField<string>('LibraryName', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Key', '');
        this.setField<string>('LibraryName', '');
    }
}

export interface IModulePluginDescriptor extends IFieldObject {
    moduleClassType: string;
    componentType: string;
    pluginType: IGuid;
}

export class ModulePluginDescriptor extends FieldObject implements IModulePluginDescriptor {
    // ModuleClassType
    public get moduleClassType(): string {
        return this.getField<string>('ModuleClassType');
    }
    public set moduleClassType(value: string) {
        this.setField<string>('ModuleClassType', value);
    }
    // ComponentType
    public get componentType(): string {
        return this.getField<string>('ComponentType');
    }
    public set componentType(value: string) {
        this.setField<string>('ComponentType', value);
    }
    // PluginType
    public get pluginType(): IGuid {
        return this.getFieldGuid('PluginType');
    }
    public set pluginType(value: IGuid) {
        this.setFieldGuid('PluginType', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('ModuleClassType', '');
        this.setField<string>('ComponentType', '');
        this.setFieldGuid('PluginType', SafeGuid.EMPTY);
    }
}
