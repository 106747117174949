import { Inject, Injectable } from '@angular/core';
import { ActionType } from 'RestClient/Client/Enumerations/ActionTypes';
import { ActionBase } from 'RestClient/Client/Event/ActionBase';
import { IApplicationEntity } from 'RestClient/Client/Interface/IApplicationEntity';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { ApplicationEntity } from 'RestClient/Client/Model/Application/ApplicationEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { TranslateService } from '@ngx-translate/core';
import { GenModalService, GenToastService, MeltedIcon } from '@genetec/gelato-angular';
import { ActionHandler } from '../../shared/services/actions/actions.service';
import { stringFormat } from '../../shared/utilities/StringFormat';
import { InternalCommandsService } from '../../shared/services/commands/commands.service';
import { COMMANDS_SERVICE } from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { ShowMessageAction, ShowMessageComponent } from '../components/show-message/show-message.component';
import { TilesTaskComponent } from '../../tiles/components/tiles-task/tiles-task.component';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { MailboxEnvelop, MailboxService } from '../services/mailbox.service';
import { SharedCommands } from '../../shared/enumerations/shared-commands';
import { ContextTypes } from '../../shared/interfaces/plugins/public/context-types';

@Injectable({
    providedIn: 'root',
})
export class DisplayEntitiesActionHandler implements ActionHandler {
    constructor(
        private modalService: GenModalService,
        private translateService: TranslateService,
        private mailboxService: MailboxService,
        private navigationService: NavigationService,
        private toastService: GenToastService,
        private securityCenterClientService: SecurityCenterClientService,
        @Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService
    ) {}

    public async executeAsync(action: ActionBase): Promise<boolean> {
        if (action.actionType === ActionType.DisplayEntity) {
            const message = action.getField<string>('annotationmessage');
            const forceDisplay = action.getField<string>('forcedisplay');
            const timestamp = action.getField<Date>('playbacktime');
            const actionEntities = action.getField<string[]>('entities');
            const sourceApp = action.getFieldGuid('sourceapplication');

            const task = this.navigationService.getCurrentTask();
            const canExecute = task.equals(TilesTaskComponent.pluginId);

            // extract the title
            let title = '';
            const sourceName = await this.getSourceName(sourceApp);
            if (sourceName) {
                title = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_MESSAGE_FROM_USER_X') as string, sourceName);
            }

            const onExecute = () => {
                if (actionEntities) {
                    const entities = actionEntities.map((id) => SafeGuid.parse(id));
                    const data = {
                        entities,
                        timestamp,
                    };
                    this.commandsService.executeCommand(SharedCommands.DisplayEntities, { type: ContextTypes.Specific, data });

                    return true;
                }
            };

            // method to add the message in the mailbox
            const addToMailbox = (mailboxMessage: string) => {
                const envelop = new MailboxEnvelop();
                envelop.id = SafeGuid.newGuid();
                envelop.icon = MeltedIcon.Share;
                envelop.title = title;
                envelop.summary = mailboxMessage;
                envelop.isRead = false;
                envelop.openHandler = onExecute;
                this.mailboxService.add(envelop);
            };

            // if display is forced, execute now
            if (forceDisplay) {
                onExecute();
            } else if (!message) {
                // if there's no message but we can execute, do it now
                if (canExecute) {
                    onExecute();
                } else {
                    if (title) {
                        this.toastService.show({ text: title });

                        // if we have no message and we cannot execute now, put a message in the mailbox
                        addToMailbox('');
                    }
                }
            } else if (message) {
                // display the message to the user
                const onClosed = (result: ShowMessageAction) => {
                    switch (result) {
                        case ShowMessageAction.Timeout:
                            {
                                addToMailbox(message);
                            }
                            break;
                        case ShowMessageAction.Ok:
                            {
                                onExecute();
                            }
                            break;
                    }
                };

                if (sourceName) {
                    title = stringFormat(this.translateService.instant('STE_LABEL_FORMAT_X_SHARED_THIS_CONTENT') as string, sourceName);
                }

                const actionText = this.translateService.instant('STE_BUTTON_DISPLAY') as string;
                const cancelText = this.translateService.instant('STE_BUTTON_DISMISS') as string;
                const timeout = 10; // put a default timeout of 10 seconds
                ShowMessageComponent.showMessage(this.modalService, title, message, onClosed, actionText, cancelText, timeout);
            }
        }
        return false;
    }

    private async getSourceName(sourceApplication: IGuid): Promise<string | null> {
        let result: string | null = null;
        const application = await this.securityCenterClientService.scClient?.getEntityAsync<ApplicationEntity, IApplicationEntity>(ApplicationEntity, sourceApplication);
        if (application) {
            result = application.name;
            if (!application.loggedUser.equals(SafeGuid.EMPTY)) {
                const loggedUser = await this.securityCenterClientService.scClient?.getEntityAsync<UserEntity, IUserEntity>(UserEntity, application.loggedUser);
                if (loggedUser) {
                    result = loggedUser.name;
                    if (loggedUser.firstName && loggedUser.lastName) {
                        result = loggedUser.firstName + ' ' + loggedUser.lastName;
                    }
                }
            }
        }
        return result;
    }
}
