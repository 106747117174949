import { FieldValue } from './FieldValue';
import { IFieldObject } from '../Client/Interface/IFieldObject';
import { ObservableCollection } from './ObservableCollection';
import { EventDispatcher, Handler } from './Helpers';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestObject } from '../Client/Interface/IRestObject';

export class FieldObject implements IFieldObject {
    // #region Constants
    // #endregion

    // #region Fields

    protected _fields = new Map<string, FieldValue>();
    private _propertyChangedDispatcher = new EventDispatcher<FieldObject>();

    // #endregion

    // #region Properties

    public lastFieldName = '';
    public lastFieldValidFlags = 0;
    public lastFieldType = '';

    public roParent: IRestObject | null = null;

    public static buildFrom<T extends FieldObject>(typeConstructor: new () => T, otherFieldObject: IFieldObject): T {
        const result = new typeConstructor();
        result.loadFrom(otherFieldObject);
        return result;
    }

    public get AllFieldsKey(): IterableIterator<string> {
        return this._fields.keys();
    }

    public get isDirty(): boolean {
        for (const [key, value] of this._fields) {
            if (value.dirty === true) {
                return true;
            }
        }
        return false;
    }

    public set isDirty(dirty: boolean) {
        for (const [key, value] of this._fields) {
            value.dirty = dirty;
        }
    }

    public get allFields(): ReadonlyMap<string, FieldValue> {
        return this._fields;
    }

    // #endregion

    // #region Events

    public onPropertyChanged(handler: Handler<FieldObject>) {
        return this._propertyChangedDispatcher.subscribe(handler);
    }

    // #endregion

    // #region Constructor, Dispose

    public dispose() {
        this._fields.clear();
    }

    // #endregion

    // #region Methods

    public hasField(fieldId: string): boolean {
        return this._fields.has(fieldId.toLowerCase());
    }

    // #region Field

    public getNullableField<T>(fieldId: string, validFlags?: number): T | null {
        fieldId = fieldId.toLowerCase();
        if (this.isNullableFieldNull(fieldId)) {
            return null;
        }
        return this.getField<T>(fieldId, validFlags);
    }

    public getField<T>(fieldId: string, validFlags?: number): T {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'primitive';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }
        const container = this._fields.get(fieldId);
        if (container) {
            return container.value as T;
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    public getFieldOrDefault<T>(fieldId: string, defaultValue?: T, validFlags?: number): T | undefined {
        if (this.hasField(fieldId)) {
            return this.getField<T>(fieldId, validFlags);
        } else if (defaultValue) {
            return defaultValue;
        }
    }

    public setNullableField<T>(fieldId: string, newValue: T | null): void {
        if (newValue === null) {
            this._fields.delete(fieldId);
        } else {
            this.setField(fieldId, newValue);
        }
    }

    public setField<T>(fieldId: string, newValue: T): void {
        // Key must be case insensitive
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(newValue);
            }
        } else {
            const container = new FieldValue(fieldId, newValue);
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    // #endregion

    // #region FieldDate

    public getNullableFieldDate(fieldId: string, validFlags?: number): Date | null {
        fieldId = fieldId.toLowerCase();
        if (this.isNullableFieldNull(fieldId)) {
            return null;
        }
        return this.getFieldDate(fieldId, validFlags);
    }

    public getFieldDate(fieldId: string, validFlags?: number): Date {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'Date';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }
        const container = this._fields.get(fieldId);
        if (container) {
            return new Date(container.value);
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    public setNullableFieldDate(fieldId: string, newValue: Date | null): void {
        if (newValue === null) {
            this._fields.delete(fieldId);
        } else {
            this.setFieldDate(fieldId, newValue);
        }
    }

    public setFieldDate(fieldId: string, newValue: Date): void {
        // Key must be case insensitive
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(newValue.toISOString());
            }
        } else {
            const container = new FieldValue(fieldId, newValue.toISOString());
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    // #endregion

    // #region FieldGuid

    public getNullableFieldGuid(fieldId: string, validFlags?: number): IGuid | null {
        fieldId = fieldId.toLowerCase();
        if (this.isNullableFieldNull(fieldId)) {
            return null;
        }
        return this.getFieldGuid(fieldId, validFlags);
    }

    public getFieldGuid(fieldId: string, validFlags?: number): IGuid {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'Guid';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }
        const container = this._fields.get(fieldId);
        if (container) {
            if (container.value === null) {
                return SafeGuid.EMPTY;
            }
            return SafeGuid.parse(container.value.toLowerCase());
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    public setNullableFieldGuid(fieldId: string, newValue: IGuid | null): void {
        if (newValue === null) {
            this._fields.delete(fieldId);
        } else {
            this.setFieldGuid(fieldId, newValue);
        }
    }

    public setFieldGuid(fieldId: string, newValue: IGuid): void {
        // Key must be case insensitive
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(newValue.toString());
            }
        } else {
            const container = new FieldValue(fieldId, newValue.toString().toLowerCase());
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    // #endregion

    // #region FieldArray<T>

    public setFieldArray<T>(fieldId: string, newValue: ObservableCollection<T>): void {
        // Key must be case insensitive
        const dataArray = new Array<T>();
        for (const elem of newValue) {
            dataArray.push(elem);
        }

        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(dataArray);
            }
        } else {
            const container = new FieldValue(fieldId, dataArray);
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    public getFieldArray<T>(fieldId: string, validFlags?: number): ObservableCollection<T> {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'Array<T>';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }
        const container = this._fields.get(fieldId);
        if (container) {
            if (container.isExtracted === false) {
                const result = new ObservableCollection<T>(
                    (e: T) => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                    (e: T) => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                    () => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                );
                result.suspend = true;
                for (const item of container.value) {
                    result.add(item);
                }
                result.suspend = false;
                container.setObject(result, () => {
                    const currentValue = new Array<T>();
                    for (const item of result) {
                        currentValue.push(item);
                    }
                    return currentValue;
                });
                return result;
            } else {
                return container.object;
            }
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    // #endregion FieldArray<T>

    // #region FieldArrayGuid

    public setFieldArrayGuid(fieldId: string, newValue: ObservableCollection<IGuid>): void {
        // Key must be case insensitive
        const stringArray = new Array<string>();
        for (const elem of newValue) {
            stringArray.push(elem.toString());
        }

        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(stringArray);
            }
        } else {
            const container = new FieldValue(fieldId, stringArray);
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    public getFieldArrayGuid(fieldId: string, validFlags?: number): ObservableCollection<IGuid> {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'Array<IGuid>';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }
        const container = this._fields.get(fieldId);
        if (container) {
            if (container.isExtracted === false) {
                const result = new ObservableCollection<IGuid>(
                    (e: IGuid) => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                    (e: IGuid) => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                    () => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                );
                result.suspend = true;
                for (const item of container.value) {
                    result.add(SafeGuid.parse(item.toString().toLowerCase()));
                }
                result.suspend = false;
                container.setObject(result, () => {
                    const currentValue = new Array<string>();
                    for (const item of result) {
                        currentValue.push(item.toString());
                    }
                    return currentValue;
                });
                return result;
            } else {
                return container.object;
            }
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    // #endregion FieldArrayGuid

    // #region FieldDictionary

    public getFieldDictionary<TKey, TValue>(fieldId: string, validFlags?: number, keyType?: string): Map<TKey, TValue> {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'Map';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }

        const container = this._fields.get(fieldId);
        if (container) {
            if (container.isExtracted === false) {
                const result = new Map<TKey, TValue>();
                if (container.value !== null) {
                    for (const key of Object.keys(container.value)) {
                        if (keyType === 'number') {
                            result.set(Number(key) as unknown as TKey, container.value[key]);
                        } else {
                            // string, guid
                            result.set(key as unknown as TKey, container.value[key]);
                        }
                    }
                }

                container.setObject(result, () => {
                    const jsonObject: any = {};
                    result.forEach((value, key) => {
                        jsonObject[key] = value;
                    });
                    return jsonObject;
                });

                return result;
            } else {
                return container.object;
            }
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    public setFieldDictionary<TKey, TValue>(fieldId: string, newValue: Map<TKey, TValue>): void {
        // Key must be case insensitive
        fieldId = fieldId.toLowerCase();

        const jsonObject: any = {};
        newValue.forEach((value, key) => {
            jsonObject[key] = value;
        });

        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(jsonObject);
            }
        } else {
            const container = new FieldValue(fieldId, jsonObject);
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    // #endregion

    // #region FieldObject

    public getFieldObject<TImpl extends FieldObject, T extends IFieldObject>(classType: new () => TImpl, fieldId: string, validFlags?: number): T {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'FieldObject';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }
        const container = this._fields.get(fieldId);
        if (container) {
            if (container.isExtracted === false) {
                const newFo = new classType();
                newFo.loadFields(container.value);
                container.setObject(newFo, () => newFo.toJson());
                return newFo as unknown as T;
            } else {
                return container.object;
            }
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    public setFieldObject<T extends IFieldObject>(fieldId: string, newValue: T): void {
        // Key must be case insensitive
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(newValue.toJson());
            }
        } else {
            const container = new FieldValue(fieldId, newValue.toJson());
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    // #endregion

    // #region FieldObjectArray

    public setFieldObjectArray<T extends IFieldObject>(fieldId: string, newValue: ObservableCollection<T>): void {
        // Key must be case insensitive
        fieldId = fieldId.toLowerCase();

        const currentValue = new Array<object>();
        for (const item of newValue) {
            currentValue.push(item.toJson());
        }

        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(currentValue);
            }
        } else {
            const container = new FieldValue(fieldId, currentValue);
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    public getFieldObjectArray<TImpl extends FieldObject & T, T extends IFieldObject>(classType: new () => TImpl, fieldId: string, validFlags?: number): ObservableCollection<T> {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'Array<FieldObject>';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }
        const container = this._fields.get(fieldId);
        if (container) {
            if (container.isExtracted === false) {
                const result = new ObservableCollection<T>(
                    (e: T) => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                    (e: T) => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                    () => {
                        container.dirty = true;
                        this._propertyChangedDispatcher.dispatch(this);
                    },
                );
                result.suspend = true;
                for (const item of container.value) {
                    const newFo = new classType();
                    newFo.loadFields(item);
                    result.add(newFo as unknown as T);
                }
                result.suspend = false;
                container.setObject(result, () => {
                    const currentValue = new Array<object>();
                    for (const item of result) {
                        currentValue.push(item.toJson());
                    }
                    return currentValue;
                });
                return result;
            } else {
                return container.object;
            }
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    // #endregion

    // #region FieldObjectDictionary

    public getFieldObjectDictionary<TKey, TValueImpl extends FieldObject & TValue, TValue extends IFieldObject>(
        classType: new () => TValueImpl,
        fieldId: string,
        validFlags?: number,
        keyType?: string,
    ): Map<TKey, TValue> {
        if (validFlags) {
            this.lastFieldName = fieldId;
            this.lastFieldValidFlags = validFlags;
            this.lastFieldType = 'Map';
        }
        fieldId = fieldId.toLowerCase();
        if (this._fields.has(fieldId) === false) {
            throw new Error('field ' + fieldId + ' not downloaded');
        }

        const container = this._fields.get(fieldId);
        if (container) {
            if (container.isExtracted === false) {
                const result = new Map<TKey, TValue>();
                if (container.value !== null) {
                    for (const key of Object.keys(container.value)) {
                        const newFo = new classType();
                        newFo.loadFields(container.value[key]);
                        if (keyType === 'number') {
                            result.set(Number(key) as unknown as TKey, newFo as unknown as TValue);
                        } else {
                            // string, guid
                            result.set(key as unknown as TKey, newFo as unknown as TValue);
                        }
                    }
                }

                container.setObject(result, () => {
                    const jsonObject: any = {};
                    result.forEach((value, key) => {
                        jsonObject[key] = value;
                    });
                    return jsonObject;
                });

                return result;
            } else {
                return container.object;
            }
        }
        throw new Error('field ' + fieldId + ' not downloaded');
    }

    public setFieldObjectDictionary<TKey, TValue extends IFieldObject>(fieldId: string, newValue: Map<TKey, TValue>): void {
        // Key must be case insensitive
        fieldId = fieldId.toLowerCase();

        const jsonObject: any = {};
        newValue.forEach((value, key) => {
            jsonObject[key] = value.toJson();
        });

        if (this._fields.has(fieldId)) {
            const current = this._fields.get(fieldId);
            if (current) {
                current.setValue(jsonObject);
            }
        } else {
            const container = new FieldValue(fieldId, jsonObject);
            this._fields.set(fieldId, container);
        }
        this._propertyChangedDispatcher.dispatch(this);
    }

    //#endregion

    // #region Base

    public loadFields(config?: { [k: string]: any }): void {
        if (config) {
            this._fields = new Map<string, any>();
            for (const key of Object.keys(config)) {
                // Key must be case insensitive
                const value = config[key];
                this._fields.set(key.toLowerCase(), new FieldValue(key, value));
            }
        }
        this.fieldLoaded();
    }

    public loadFrom(source: IFieldObject): void {
        this._fields.clear();
        for (const [key, value] of source.allFields) {
            const container = new FieldValue(key, value.value);
            this._fields.set(key, container);
        }
        this.fieldLoaded();
    }

    public loadFieldsFrom(source: IFieldObject, fields?: Set<string>): void {
        const fieldsCopy = fields ? new Set<string>(Array.from(fields).map((x) => x.toLowerCase())) : undefined;
        for (const [key, value] of source.allFields) {
            if (!fieldsCopy || fieldsCopy.has(key)) {
                const container = new FieldValue(key, value.value);
                this._fields.set(key, container);
            }
        }
        this.fieldLoaded();
    }

    protected fieldLoaded(): void {}

    public fromJson(json: string) {
        const fields = JSON.parse(json);
        this.loadFields(fields);
    }

    public toJson(): object {
        const result: { [k: string]: any } = {};
        for (const [key, value] of this._fields) {
            result[key] = value.value;
        }
        return result;
    }

    public toQueryString(): string {
        const json = JSON.stringify(this.toJson());
        const result = 'jparam=' + json;
        return result;
    }

    public toString(): string {
        return JSON.stringify(this.toJson());
    }

    public initializeAllFields(): void {}

    // #endregion

    // #endregion

    private isNullableFieldNull(fieldId: string): boolean {
        if (!this._fields.has(fieldId)) {
            return true;
        }

        const container = this._fields.get(fieldId);
        return !container || container.value === null;
    }
}
