import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class VideoContentTypes {
    public static Video = SafeGuid.parse('698a0b80-26c2-42f9-b6a4-dadc88265ded');
    public static Event = SafeGuid.parse('2E6999E3-ECDF-4A65-8110-3A4C01267DA9');
}
