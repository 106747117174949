import { Inject, Injectable } from '@angular/core';
import { Color, GenToastService, MeltedIcon, ToastFlavor } from '@genetec/gelato-angular';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import { EventTypes } from 'RestClient/Client/Enumerations/EventTypes';
import { AlarmStates } from 'RestClient/Client/Enumerations/AlarmStates';
import { AlarmEntityFields, IAlarmEntity } from 'RestClient/Client/Interface/IAlarmEntity';
import { AlarmEntity } from 'RestClient/Client/Model/AlarmEntity';
import { IAlarmEvent } from 'RestClient/Client/Interface/IAlarmEvent';
import { IGuid, GuidSet, GuidMap } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { TranslateService } from '@ngx-translate/core';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { WINDOW } from '@utilities/common-helper';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import {
    CommandBindings,
    CommandContext,
    CommandsService,
    COMMANDS_SERVICE,
    EntityCommandRequirements,
    FEATURES_SERVICE,
} from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { Content } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { CommandDescriptor, CommandArgs } from '../../shared/services/commands/command-provider-base';
import { EntityCommandContext } from '../../shared/services/commands/entity-command-context';
import { EntityCommandProviderBase, ExecuteEntityCommandData } from '../../shared/services/commands/entity-command-provider-base';
import { SharedContentFields } from '../../shared/enumerations/shared-content-fields';
import { AlarmCommands } from '../enumerations/alarm-commands';
import { AlarmContentFields } from '../enumerations/alarm-content-fields';
import { AlarmContentTypes } from '../enumerations/alarm-content-types';
import { AlarmAcknowledgeAction } from '../controllers/alarms.controller';
import { LanguageService } from '../../shared/services/language/language.service';
import { EntityCommandsUsageContext } from '../../shared/services/commands/commands-usage/entity-commands-usage-context';
import { AlarmsService } from './alarms.service';

enum AckType {
    Ack,
    Nack,
    Force,
}

interface AlarmInfo {
    alarmId: IGuid;
}

interface AlarmInstanceInfo extends AlarmInfo {
    instanceId: number;
}

const isAlarmInstanceInfo = (instance: any): instance is AlarmInstanceInfo => {
    return isOfType<AlarmInstanceInfo>(instance, 'alarmId', 'instanceId');
};

@UntilDestroy()
@Injectable()
export class AlarmCommandProvider extends EntityCommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const ackAlarmRequirements = this.fillCommandRequirements({
                requiredPrivileges: new GuidSet([KnownPrivileges.acknowledgeAlarmsPrivilege]),
                supportedContentTypes: new GuidSet([AlarmContentTypes.Instance]),
            });
            const descriptors = new GuidMap<CommandDescriptor>();
            descriptors.set(
                AlarmCommands.AcknowledgeAlarm,
                this.createAlarmCommandDescriptor(AlarmCommands.AcknowledgeAlarm, 'STE_ACTION_ALARM_ACK', MeltedIcon.Checkmark, ackAlarmRequirements, Color.Pistacchio)
            );
            descriptors.set(
                AlarmCommands.AlternateAcknowledgeAlarm,
                this.createAlarmCommandDescriptor(AlarmCommands.AlternateAcknowledgeAlarm, 'STE_ACTION_ALARM_NACK', MeltedIcon.Checkmark, ackAlarmRequirements, Color.Arancia)
            );
            descriptors.set(
                AlarmCommands.ForceAcknowledgeAlarm,
                this.createAlarmCommandDescriptor(
                    AlarmCommands.ForceAcknowledgeAlarm,
                    'STE_ACTION_ALARM_FORCE_ACK',
                    MeltedIcon.ForciblyAcknowledge,
                    this.fillCommandRequirements({ supportedContentTypes: new GuidSet([AlarmContentTypes.Instance]) })
                )
            );
            descriptors.set(
                AlarmCommands.InvestigateAlarm,
                this.createAlarmCommandDescriptor(AlarmCommands.InvestigateAlarm, 'STE_ACTION_ALARM_INVESTIGATE', MeltedIcon.Search, ackAlarmRequirements)
            );
            descriptors.set(
                AlarmCommands.TriggerAlarm,
                this.createAlarmCommandDescriptor(
                    AlarmCommands.TriggerAlarm,
                    'STE_ACTION_ALARM_TRIGGER',
                    MeltedIcon.Alarm,
                    this.fillCommandRequirements({
                        requiredPrivileges: new GuidSet([KnownPrivileges.triggerAlarmPrivilege]),
                        supportedContentTypes: new GuidSet([AlarmContentTypes.Alarm]),
                    })
                )
            );
            descriptors.set(
                AlarmCommands.ShowAlarmProcedure,
                this.createAlarmCommandDescriptor(
                    AlarmCommands.ShowAlarmProcedure,
                    'STE_ACTION_SHOWALARMPROCEDURE',
                    MeltedIcon.Link,
                    this.fillCommandRequirements({ supportedContentTypes: new GuidSet([AlarmContentTypes.Alarm, AlarmContentTypes.Instance]) })
                )
            );
            this.internalCommandDescriptors = descriptors;
        }
        return this.internalCommandDescriptors;
    }

    public get requiredEntityFields(): Set<string> {
        return new Set<string>([...super.requiredEntityFields, AlarmEntityFields.procedureField, AlarmEntityFields.enableProcedureField]);
    }

    public get requiredFeatures(): Set<IGuid> {
        return new GuidSet([...super.requiredFeatures, KnownFeatures.alarmsId]);
    }

    constructor(
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        @Inject(WINDOW) private window: Window,
        private alarmsService: AlarmsService,
        private toastService: GenToastService,
        @Inject(COMMANDS_SERVICE) protected commandsService: CommandsService,
        protected translateService: TranslateService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);

        // Prefetch privileges
        await this.prefetchPrivileges(commandContext, commandArgs);

        const canAck = await this.isAckAvailableAsync(commandContext, commandArgs);
        if (canAck) {
            commandIds.push(AlarmCommands.AcknowledgeAlarm);
            commandIds.push(AlarmCommands.AlternateAcknowledgeAlarm);
            if (await this.isForceAckAvailableAsync(commandContext, commandArgs)) {
                commandIds.push(AlarmCommands.ForceAcknowledgeAlarm);
            }
            commandIds.push(AlarmCommands.InvestigateAlarm);
            this.configureAckAlarmInvalidateCanExecute(commandContext as EntityCommandsUsageContext);
        }
        if (await this.isTriggerAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(AlarmCommands.TriggerAlarm);
        }
        if (await this.isShowProcedureAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(AlarmCommands.ShowAlarmProcedure);
        }
        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: AlarmCommands.AcknowledgeAlarm,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeAck(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isAckAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) => this.safeCanExecuteEntityCommand((entityCommandContext) => this.canAck(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AlarmCommands.AlternateAcknowledgeAlarm,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeNack(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isAckAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) => this.safeCanExecuteEntityCommand((entityCommandContext) => this.canAck(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AlarmCommands.ForceAcknowledgeAlarm,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeForceAck(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isForceAckAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) => this.safeCanExecuteEntityCommand((entityCommandContext) => this.canAck(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AlarmCommands.InvestigateAlarm,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeInvestigate(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isAckAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) => this.safeCanExecuteEntityCommand((entityCommandContext) => this.canInvestigate(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AlarmCommands.TriggerAlarm,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeTrigger(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isTriggerAvailableAsync(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AlarmCommands.ShowAlarmProcedure,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeShowProcedure(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isShowProcedureAvailableAsync(entityCommandContext), commandContext),
        });
    }

    protected fillCommandRequirements(requirements: EntityCommandRequirements): EntityCommandRequirements {
        if (!requirements.supportedEntityTypes) {
            requirements.supportedEntityTypes = new Set<string>([EntityTypes.Alarms]);
        }
        return super.fillCommandRequirements(requirements);
    }

    private async ackAlarmAsync(commandContext: CommandContext, ackType: AckType): Promise<IRestResponse | null> {
        const alarmInstanceInfo = this.extractAlarmInstanceInfo(commandContext);
        if (alarmInstanceInfo) {
            const action = this.buildAckAction(alarmInstanceInfo);
            switch (ackType) {
                case AckType.Force:
                    action.force = true;
                    break;
                case AckType.Nack:
                    action.alternate = true;
                    break;
            }
            await this.alarmsService.acknowledgeAsync(action);
        }

        return null;
    }

    private buildAckAction(alarmInfo: AlarmInstanceInfo): AlarmAcknowledgeAction {
        const action = new AlarmAcknowledgeAction();
        action.instanceId = alarmInfo.instanceId;
        action.alarmId = alarmInfo.alarmId;
        return action;
    }

    private canAck(commandContext: CommandContext, commandArgs?: CommandArgs): boolean {
        const args = commandArgs || this.extractArgs(commandContext);
        const content = args.content;
        if (content?.parameters?.hasField(AlarmContentFields.State)) {
            const alarmState = content.parameters.getField<string>(AlarmContentFields.State);
            if (alarmState) {
                return alarmState !== AlarmStates.Acked;
            }
        }
        return false;
    }

    private canInvestigate(commandContext: CommandContext, commandArgs?: CommandArgs): boolean {
        const args = commandArgs || this.extractArgs(commandContext);
        const content = args.content;
        if (content?.parameters?.hasField(AlarmContentFields.State)) {
            const alarmState = content.parameters.getField<string>(AlarmContentFields.State);
            if (alarmState) {
                return alarmState === AlarmStates.Active;
            }
        }
        return false;
    }

    private configureAckAlarmInvalidateCanExecute(commandContext: EntityCommandsUsageContext): void {
        if (commandContext.entityCache && commandContext.invalidateCanExecute) {
            const args = this.extractArgs(commandContext);
            if (args?.content && isNonEmptyGuid(args.entityId)) {
                const alarmInstanceId = args.content.parameters?.getField<number>(AlarmContentFields.AlarmInstanceId);

                commandContext.subscriptions.add(
                    this.alarmsService.alarmEventReceived$.pipe(untilDestroyed(this)).subscribe((eventArg) => {
                        if (args.entityId && eventArg.event.sourceEntity.equals(args.entityId) && (eventArg.event as IAlarmEvent).instanceId === alarmInstanceId) {
                            if (eventArg.event.eventType === EventTypes.AlarmAcknowledged || eventArg.event.eventType === EventTypes.AlarmAcknowledgedAlternate) {
                                this.updateAlarmInstanceContentState(args.content, AlarmStates.Acked);
                                this.invalidateAckRelatedCommandsCanExecute(commandContext);
                            }

                            if (eventArg.event.eventType === EventTypes.AlarmInvestigating) {
                                this.updateAlarmInstanceContentState(args.content, AlarmStates.SourceConditionInvestigating);
                                commandContext.invalidateCanExecute(AlarmCommands.InvestigateAlarm);
                            }
                        }
                    })
                );
            }
        }
    }

    private createAlarmCommandDescriptor(commandId: IGuid, nameResourceId: string, icon: MeltedIcon, requirements?: EntityCommandRequirements, color?: string): CommandDescriptor {
        return {
            id: commandId,
            nameResourceId,
            icon,
            color,
            groupNameResourceId: 'STE_ENTITY_ALARM',
            groupIcon: MeltedIcon.Alarm,
            requirements,
        };
    }

    private executeTrigger(executeCommandData: ExecuteEntityCommandData): void {
        const args = this.extractArgs(executeCommandData.commandContext);
        if (args.entityId) {
            this.alarmsService
                .triggerAsync(args.entityId)
                .then(() => {
                    // we only send this toast for the trigger alarm request since the other has visual feedbacks already (if you can ack, you have the instance of the alarm already).
                    // the trigger might not be applied due to multiple reasons (i.e. reactivation treshold) and since this is an action to the directory in the end
                    // we don't know if it has worked or not. So simply tell that the request has been sent as a visual feedback.
                    const notification = this.translateService.instant('STE_ACTION_TRIGGER_ALARM_REQUEST_SENT') as string;
                    this.toastService.clear();
                    this.toastService.show({ text: notification, flavor: ToastFlavor.Success });
                })
                .fireAndForget();
        }
        executeCommandData.isHandled = true;
    }

    private executeAck(executeCommandData: ExecuteEntityCommandData): void {
        this.ackAlarmAsync(executeCommandData.commandContext, AckType.Ack).fireAndForget();

        executeCommandData.isHandled = true;
    }

    private executeNack(executeCommandData: ExecuteEntityCommandData): void {
        this.ackAlarmAsync(executeCommandData.commandContext, AckType.Nack).fireAndForget();

        executeCommandData.isHandled = true;
    }

    private executeForceAck(executeCommandData: ExecuteEntityCommandData): void {
        this.ackAlarmAsync(executeCommandData.commandContext, AckType.Force).fireAndForget();

        executeCommandData.isHandled = true;
    }

    private executeInvestigate(executeCommandData: ExecuteEntityCommandData): void {
        const alarmInstanceInfo = this.extractAlarmInstanceInfo(executeCommandData.commandContext);
        if (alarmInstanceInfo) {
            this.alarmsService.investigateAsync(alarmInstanceInfo.instanceId, alarmInstanceInfo.alarmId).fireAndForget();
        }

        executeCommandData.isHandled = true;
    }

    private executeShowProcedure(executeCommandData: ExecuteEntityCommandData): void {
        this.executeShowProcedureAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async executeShowProcedureAsync(commandContext: EntityCommandContext): Promise<void> {
        let procedure = await this.extractAlarmProcedureAsync(commandContext);
        if (procedure) {
            if (procedure.indexOf('https://') < 0 && procedure.indexOf('http://') < 0) {
                procedure = 'http://' + procedure;
            }
            this.window.open(procedure, '_blank');
        }
    }

    private extractAlarmInstanceInfo(commandContext: CommandContext): AlarmInstanceInfo | undefined {
        const args = this.extractArgs(commandContext);
        let alarmInstanceInfo: AlarmInstanceInfo | undefined;
        const content = args.content;
        if (content) {
            if (
                content.type.equals(AlarmContentTypes.Instance) &&
                content.parameters &&
                content.parameters.hasField(SharedContentFields.SourceEntityGuid) &&
                content.parameters.hasField(AlarmContentFields.AlarmInstanceId)
            ) {
                alarmInstanceInfo = {
                    alarmId: content.parameters.getFieldGuid(SharedContentFields.SourceEntityGuid),
                    instanceId: content.parameters.getField<number>(AlarmContentFields.AlarmInstanceId),
                };
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const specificData = args.specificData;
            if (isAlarmInstanceInfo(specificData)) {
                alarmInstanceInfo = specificData;
            }
        }
        return alarmInstanceInfo;
    }

    private async extractAlarmProcedureAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<string | undefined> {
        const args = commandArgs || this.extractArgs(commandContext);
        let procedure: string | undefined;
        const content = args.content;
        if (content) {
            if (content.type.equals(AlarmContentTypes.Alarm) && content.parameters && content.parameters.hasField(AlarmContentFields.AlarmProcedure)) {
                procedure = content.parameters.getField<string>(AlarmContentFields.AlarmProcedure);
            }
        } else if (args.entity || args.entityId) {
            const alarm = await this.getEntityAsync<AlarmEntity, IAlarmEntity>(AlarmEntity, undefined, args.entity, args.entityId, commandContext.entityCache);
            if (alarm?.enableProcedure) {
                procedure = alarm.procedure;
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const specificData = args.specificData;
            if (isString(specificData)) {
                procedure = specificData;
            }
        }

        if (procedure) {
            return procedure;
        }
    }

    private invalidateAckRelatedCommandsCanExecute(commandContext: EntityCommandsUsageContext): void {
        commandContext.invalidateCanExecute(AlarmCommands.AcknowledgeAlarm);
        commandContext.invalidateCanExecute(AlarmCommands.AlternateAcknowledgeAlarm);
        commandContext.invalidateCanExecute(AlarmCommands.ForceAcknowledgeAlarm);
        commandContext.invalidateCanExecute(AlarmCommands.InvestigateAlarm);
    }

    private async isAckAvailableAsync(commandContext: EntityCommandContext, args?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(AlarmCommands.AcknowledgeAlarm, commandContext, args);
    }

    private async isForceAckAvailableAsync(commandContext: EntityCommandContext, args?: CommandArgs): Promise<boolean> {
        const areRequirementsMet = await this.areCommandRequirementsMetAsync(AlarmCommands.ForceAcknowledgeAlarm, commandContext, args);
        if (areRequirementsMet) {
            return this.isUserAdmin;
        }
        return false;
    }

    private async isShowProcedureAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        const args = commandArgs || this.extractArgs(commandContext);
        const areRequirementsMet = await this.areCommandRequirementsMetAsync(AlarmCommands.ForceAcknowledgeAlarm, commandContext, args);
        if (areRequirementsMet) {
            const procedure = await this.extractAlarmProcedureAsync(commandContext, args);
            if (procedure) {
                return true;
            }
        }
        return false;
    }

    private async isTriggerAvailableAsync(commandContext: EntityCommandContext, args?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(AlarmCommands.TriggerAlarm, commandContext, args);
    }

    private updateAlarmInstanceContentState(content: Content | undefined, alarmState: string): void {
        if (content?.type.equals(AlarmContentTypes.Instance) && content.parameters && content.parameters.hasField(AlarmContentFields.State)) {
            content.parameters.setField(AlarmContentFields.State, alarmState);
        }
    }
}
