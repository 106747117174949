import { EvaluatedPrivileges } from '@modules/general/interfaces';
import { Constants } from '@modules/shared/utilities/constants';
import { IGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';

export type EvaluatedCardholderGlobalPrivileges = EvaluatedPrivileges<'generalSettingsLogicalIdPrivilege'>;

export type EvaluatedCardholderPrivileges = EvaluatedPrivileges<
    | 'modifyCardholderNamePrivilege'
    | 'changeCardholderStatusPrivilege'
    | 'modifyCardholdersPrivilege'
    | 'modifyCardholderGroupsPrivilege'
    | 'modifyCredentialAssociationToCardholderPrivilege'
    | 'addCredentialsPrivilege'
    | 'modifyAccessRulesPrivilege'
    | 'changeCardholderOptionsPrivilege'
    | 'deleteCardholdersPrivilege'
    | 'generalSettingsLogicalIdPrivilege'
    | 'setCardholderSecurityClearancePrivilege'
    | 'modifyCardholderPicturePrivilege'
    | 'viewCardholdersPrivilege'
>;

export interface EntityPrivilege {
    id: IGuid;
    isGlobal: boolean;
}

const createEntityPrivilege = (id: IGuid, isGlobal = false): EntityPrivilege => ({
    id,
    isGlobal,
});

export const CardholderPrivileges: Record<keyof EvaluatedCardholderPrivileges, EntityPrivilege> = Constants({
    modifyCardholderNamePrivilege: createEntityPrivilege(KnownPrivileges.modifyCardholderNamePrivilege),
    changeCardholderStatusPrivilege: createEntityPrivilege(KnownPrivileges.changeCardholderStatusPrivilege),
    modifyCardholdersPrivilege: createEntityPrivilege(KnownPrivileges.modifyCardholdersPrivilege),
    modifyCardholderGroupsPrivilege: createEntityPrivilege(KnownPrivileges.modifyCardholderGroupsPrivilege),
    modifyCredentialAssociationToCardholderPrivilege: createEntityPrivilege(KnownPrivileges.modifyCredentialAssociationToCardholderPrivilege),
    addCredentialsPrivilege: createEntityPrivilege(KnownPrivileges.addCredentialsPrivilege),
    modifyAccessRulesPrivilege: createEntityPrivilege(KnownPrivileges.modifyAccessRulesPrivilege),
    changeCardholderOptionsPrivilege: createEntityPrivilege(KnownPrivileges.changeCardholderOptionsPrivilege),
    deleteCardholdersPrivilege: createEntityPrivilege(KnownPrivileges.deleteCardholdersPrivilege),
    generalSettingsLogicalIdPrivilege: createEntityPrivilege(KnownPrivileges.generalSettingsLogicalIdPrivilege, true),
    setCardholderSecurityClearancePrivilege: createEntityPrivilege(KnownPrivileges.setCardholderSecurityClearancePrivilege),
    modifyCardholderPicturePrivilege: createEntityPrivilege(KnownPrivileges.modifyCardholderPicturePrivilege),
    viewCardholdersPrivilege: createEntityPrivilege(KnownPrivileges.viewCardholdersPrivilege),
});

// TODO manageSecurityClearance come from license, will need to handle that permission too.
