// Autogenerated: v2/help/enumerations/ApiRunningState?codegen=ts&showresources=False on 2020-07-23 3:40:07 PM

export class RunningState {
    public static NotRunning = 'NotRunning';
    public static Warning = 'Warning';
    public static Running = 'Running';

    public static readonly values = [
        RunningState.NotRunning,
        RunningState.Warning,
        RunningState.Running
    ];
}
