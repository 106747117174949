// Autogenerated: CodeGen/FieldFilterData?language=ts&InterfaceOnly=True on 2020-12-11 6:54:37 AM
// Autogenerated: CodeGen/FieldFilterData?language=ts&InterfaceOnly=False on 2020-12-11 6:54:37 AM
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { HierarchicalData, IHierarchicalData } from './hierarchical-data';

export interface IFieldFilterData extends IHierarchicalData {
    fieldName: string;
    values: ObservableCollection<any>;
}

export class FieldFilterData extends HierarchicalData implements IFieldFilterData {
    // FieldName
    public get fieldName(): string {
        return this.getField<string>('FieldName');
    }
    public set fieldName(value: string) {
        this.setField<string>('FieldName', value);
    }
    // Values
    public get values(): ObservableCollection<any> {
        return this.getFieldArray<any>('Values');
    }
    public set values(value: ObservableCollection<any>) {
        this.setFieldArray<any>('Values', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<string>('FieldName', '');
        this.setFieldArray<any>('Values', new ObservableCollection<any>());
    }
}
