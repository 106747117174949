import { ComponentFactoryResolver, ComponentRef, Directive, Input, ViewContainerRef } from '@angular/core';
import { TreeItemsComponent } from '@shared/components/tree-items/tree-items.component';
import { TreeItem } from '@shared/interfaces/tree-item/tree-item';
/**
 * @deprecated Do not use anymore as this leads to unexpected results
 * @description
 *
 * Right now, this only works for SelectionType.Single trees. more work will be needed for recursive + SelectionType.Multiple directive.
 * Use this directive to automatically add gen-tree-item(s) based on a datasource. This directive will invoke the TreeItemsComponent to do the rendering.
 * If you want a more "granular" control over the behavior of the gen-menu-items, use the declarative approach as stated in the Gelato documentation.
 *
 * This directive was mainly written to accomodate backward compatibility with the previous behaviour of the GenTree (which supported datasource).
 *
 * @input dataSource the datasource that you want rendered.
 *
 * @example
 *
 * <gen-tree [appTreeDataSource]="datasource" [appTreeItemClick]="onItemClickCallback.bind(this)"></gen-tree>

 */
@Directive({
    selector: '[appTreeDataSource]',
})
export class TreeDataSourceDirective {
    private treeItemsComponent?: ComponentRef<TreeItemsComponent>;
    // eslint-disable-next-line @typescript-eslint/member-ordering
    /**
     * Function that will be called when an item is clicked. Do not forget to bind(this) when you pass the callback to the gen-tree
     */
    @Input() set appTreeItemClick(callback: (item: TreeItem, event: CustomEvent) => any) {
        if (this.treeItemsComponent) {
            // assign provided callback.
            this.treeItemsComponent.instance.itemCallbackToUse = callback;
            // run a round of change detection
            this.treeItemsComponent.changeDetectorRef.detectChanges();
        }
    }
    @Input() set appTreeDataSource(dataSource: TreeItem[] | undefined) {
        if (dataSource) {
            // First time render
            if (!this.treeItemsComponent) {
                // create the TreeItemComponent
                const treeItemsComponentFactory = this.componentFactoryResolver.resolveComponentFactory(TreeItemsComponent);
                this.treeItemsComponent = treeItemsComponentFactory.create(this.viewContainer.injector);

                // Pass our datasource to it
                this.treeItemsComponent.instance.dataSource = dataSource ?? [];

                // run a round of change detection
                this.treeItemsComponent.changeDetectorRef.detectChanges();

                // create an embeddedView in the viewContainer (omits the host tag <app-tree-items>)
                this.viewContainer.createEmbeddedView(this.treeItemsComponent.instance.template);
            } else {
                // Component already created, only reassing datasource and run change detection.
                // Pass our datasource to it
                this.treeItemsComponent.instance.dataSource = dataSource;

                // run a round of change detection
                this.treeItemsComponent.changeDetectorRef.detectChanges();
            }
        }
    }

    constructor(private viewContainer: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}
}
