<gen-grid>
    <gen-grid-item id="linked-incident-header">
        <div>
            <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_LABEL_LINKED_INCIDENT' | translate }}</gen-text>
            <gen-text *ngIf="linkedIncidents$ | async as linkedIncidents" [flavor]="TextFlavor.Secondary">{{ ' (' + linkedIncidents.length + ')' }}</gen-text>
        </div>
        <div>
            <gen-button [flavor]="ButtonFlavor.Flat" (click)="showLinkIncidentModal()" [disabled]="!hasLinkIncidentPrivilege">
                <gen-icon [icon]="MeltedIcon.Plus"></gen-icon>
            </gen-button>
            <gen-button [flavor]="ButtonFlavor.Flat" (click)="showUnlinkIncidentModal()" [disabled]="!hasLinkIncidentPrivilege || !targetUnlinkIncident">
                <gen-icon [icon]="MeltedIcon.Minus"></gen-icon>
            </gen-button>
        </div>
    </gen-grid-item>
    <gen-grid-item>
        <div *ngIf="linkedIncidents$ | async as linkedIncidents; else loading">
            <div *ngIf="linkedIncidents.length === 0" class="empty-linked-incident-list">
                <gen-text [flavor]="TextFlavor.Normal">{{ 'STE_MESSAGE_NO_LINKED_INCIDENT' | translate }}</gen-text>
            </div>
            <div *ngIf="linkedIncidents.length > 0" class="linked-incident-list">
                <div
                    *ngFor="let linkedIncident of linkedIncidents"
                    class="incident-item"
                    (click)="selectUnlinkIncident(linkedIncident)"
                    (dblclick)="navigateToIncident(linkedIncident)"
                >
                    <div class="linked-list-grid" *ngIf="!!linkedIncident" [ngClass]="{ 'selected-incident': targetUnlinkIncident?.id?.equals(linkedIncident.id) }">
                        <div class="incident-color" [style.background-Color]="linkedIncident.argbColor | argb"></div>
                        <app-incident-icon class="incident-type-icon" [iconId]="linkedIncident.iconId"></app-incident-icon>
                        <div class="first-row">
                            <div class="flex-cell">
                                <div class="truncate">{{ linkedIncident.name }}</div>
                                <div class="ml-1">{{ '(#' + linkedIncident.displayId + ')' }}</div>
                            </div>
                        </div>
                        <div class="second-row">
                            <gen-text [flavor]="TextFlavor.Secondary">
                                {{ linkedIncident.location?.entityId | incidentLocationName | async }}
                            </gen-text>
                            <gen-text [flavor]="TextFlavor.Secondary">
                                {{ linkedIncident.priorityId | priorityName | async }}
                            </gen-text>
                        </div>
                        <div class="third-row">
                            <gen-text [flavor]="TextFlavor.Secondary">
                                {{ linkedIncident.triggerTimeUtc | dateFormatWithTimezone: 'MM-DD h:mm:ss A' | async }}
                            </gen-text>
                            <gen-text [flavor]="TextFlavor.Secondary">{{ linkedIncident.stateId | stateName | async }} </gen-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #loading>
            <div class="empty-linked-incident-list">
                <gen-spinner [size]="SpinnerSize.Large" class="ha-center va-center">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
            </div>
        </ng-template>
    </gen-grid-item>
</gen-grid>
<!--Link incident modal-->
<gen-melted-modal
    id="link-incident-modal"
    [genTitle]="'STE_TITLE_LINK_INCIDENT' | translate"
    [genDefaultActionText]="'STE_LABEL_LINK' | translate"
    [genAlternativeActionText]="'STE_BUTTON_CANCEL' | translate"
    [genOnAlternativeAction]="closeLinkIncidentModal"
    [genOnDefaultAction]="linkIncidentAction"
    [genIsDefaultActionDisabled]="!targetLinkIncident"
    [genCanClose]="true"
>
    <gen-melted-modal-body>
        <div class="link-incident-modal">
            <div class="linked-incident-input">
                <gen-search-input
                    [genLabel]="'STE_LABEL_INCIDENT_DISPLAY_ID' | translate"
                    [genDebounce]="300"
                    [genMessageSeverity]="Severity.Warning"
                    [(ngModel)]="linkDisplayId"
                    (ngModelChange)="updateSelectedIncident(linkDisplayId)"
                ></gen-search-input>
                <gen-button [flavor]="ButtonFlavor.Flat" (click)="toggleFilter()">
                    <gen-icon [icon]="Icon.Filter" [genColor]="hiddenFilter ? '' : Color.Puffo"></gen-icon>
                </gen-button>
            </div>

            <app-linked-incident-filter #linkedIncidentFilter [hiddenFilter]="hiddenFilter" (incidentFilterChanged)="updateIncidentFilter($event)"> </app-linked-incident-filter>

            <div *ngIf="linkableIncidents$ | async as linkableIncidents" [class.filters-shown]="!hiddenFilter" class="filtered-incidents">
                <div *ngIf="linkableIncidents.length > 0">
                    <app-incident-card
                        *ngFor="let linkableIncident of linkableIncidents"
                        (click)="canLinkIncident(linkableIncident) && selectLinkIncident(linkableIncident)"
                        [selected]="targetLinkIncident === linkableIncident"
                        [disabled]="!canLinkIncident(linkableIncident)"
                        [incident]="linkableIncident"
                    >
                    </app-incident-card>
                </div>
                <div *ngIf="linkableIncidents.length <= 0 && (!hiddenFilter || !!linkDisplayId)">
                    <gen-text class="mt-1" [flavor]="TextFlavor.Warning">{{ 'STE_MESSAGE_NO_INCIDENTS_FOUND' | translate }}</gen-text>
                </div>
            </div>
        </div>
    </gen-melted-modal-body>
</gen-melted-modal>
<!--Unlink incident modal-->
<gen-melted-modal
    id="unlink-incident-modal"
    [genTitle]="'STE_TITLE_UNLINK_INCIDENT' | translate"
    [genDefaultActionText]="'STE_LABEL_UNLINK' | translate"
    [genAlternativeActionText]="'STE_BUTTON_CANCEL' | translate"
    [genOnDefaultAction]="unlinkIncidentAction"
    [genOnAlternativeAction]="closeUnlinkIncidentModal"
    [genIsDefaultActionDisabled]="!canUnlinkIncident()"
    [genCanClose]="true"
>
    <gen-melted-modal-body>
        <gen-text [flavor]="unlinkFlavor">{{ unlinkMessage }}</gen-text>
    </gen-melted-modal-body>
</gen-melted-modal>
