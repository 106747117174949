import { Observable, Subject } from 'rxjs';
import { ContentGroup } from '../../shared/interfaces/plugins/public/plugin-public.interface';
import { PluginsStack } from '../../shared/components/plugins/plugins-stack';
import { CommandsUsageCollection } from '../../shared/services/commands/commands-usage/commands-usage-collection';
import { TileBoundary } from './tile-boundary';

export class TileItem {
    public content: ContentGroup | null = null;

    public pluginsStack: PluginsStack = new PluginsStack();
    public commandsUsages = new CommandsUsageCollection();
    public isExpanded = false;
    public isSelected = false;
    public isFullscreen = false;
    public isDraggingOver = false;
    public isLoading = false;
    public isMouseOver = false;

    private clearingSubject: Subject<void> = new Subject<void>();

    // Clearing event to notify that the state is getting cleared
    public get clearing$(): Observable<void> {
        return this.clearingSubject.asObservable();
    }

    public get title(): string {
        return `tile ${this.id}`;
    }

    public get hasContent(): boolean {
        return this.content !== null;
    }

    constructor(public id: number, public boundary: TileBoundary) {}

    public clear(): void {
        // Raise clearing before plugins and commands are cleared
        this.clearingSubject.next();
        this.content = null;
        this.pluginsStack = new PluginsStack();
        this.commandsUsages.clear();
    }
}
