import { Component, OnInit } from '@angular/core';
import { SelectionType } from '@genetec/gelato';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { Item } from '@modules/shared/interfaces/item';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
//
export const isContentSupported = (content: Content): boolean => {
    if (content) {
        if (content.parameters?.hasField('properties')) {
            return true;
        }
    }
    return false;
};

export class PropertyItem {
    public Title!: string;
    public Type!: string;
    public Value!: string;
}

@Component({
    selector: 'app-properties-widget',
    templateUrl: './properties-widget.component.html',
    styleUrls: ['./properties-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: PropertiesWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: PropertiesWidgetComponent.pluginId, priority: 100 },
    isContentSupported,
})
export class PropertiesWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('FA7942ED-1C22-4CD8-ABF4-24F473621BC3');
    public readonly ListSelectionType = SelectionType;
    public content?: Content;
    public dataContext?: ContentService;

    public properties: Item[] = [];

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.properties.length = 0;
        const parameters = this.content?.parameters;
        if (parameters) {
            if (parameters.hasField('properties')) {
                const json = parameters.getField<string>('properties');
                if (json) {
                    const items = JSON.parse(json) as PropertyItem[];
                    if (items) {
                        let id = 0;
                        items.forEach((x) => {
                            this.properties.push({ id: (id++).toString(), text: x.Title, secondaryText: x.Value });
                        });
                    }
                }
            }
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }
}
