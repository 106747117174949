// Autogenerated: v2/help/Entities/ApiMap?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:01 AM
import { Entity } from './Entity';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { FieldObject } from '../../Helpers/FieldObject';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IMapEntity, ICustomVisibleLayer, ICustomRefreshableLayer, ICustomMapLayer, ICustomSpecificMapConfiguration, IMapObject, IVisibleLayer, IPoint, IGeoReference, ICoordinate, ILatLngRectangle, IDefaultView, MapEntityFields, IStateColor, IMapFloorData, IThickness } from '../Interface/IMapEntity';
import { ITransaction } from '../Interface/ITransaction';

// #region Inner classes

export class CustomVisibleLayer extends FieldObject implements ICustomVisibleLayer {
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // IsVisible
    public get isVisible(): boolean {
        return this.getField<boolean>('IsVisible');
    }
    public set isVisible(value: boolean) {
        this.setField<boolean>('IsVisible', value);
    }
    // SubLayers
    public get subLayers(): ObservableCollection<ICustomVisibleLayer> {
        return this.getFieldObjectArray<CustomVisibleLayer, ICustomVisibleLayer>(CustomVisibleLayer, 'SubLayers');
    }
    public set subLayers(value: ObservableCollection<ICustomVisibleLayer>) {
        this.setFieldObjectArray('SubLayers', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Id', '');
        this.setField<boolean>('IsVisible', false);
        this.setFieldArray<ICustomVisibleLayer>('SubLayers', new ObservableCollection<ICustomVisibleLayer>());
    }
}

export class CustomRefreshableLayer extends FieldObject implements ICustomRefreshableLayer {
    // Url
    public get url(): string {
        return this.getField<string>('Url');
    }
    public set url(value: string) {
        this.setField<string>('Url', value);
    }
    // RefreshRate
    public get refreshRate(): number {
        return this.getField<number>('RefreshRate');
    }
    public set refreshRate(value: number) {
        this.setField<number>('RefreshRate', value);
    }
    // IsLayerRefreshing
    public get isLayerRefreshing(): boolean {
        return this.getField<boolean>('IsLayerRefreshing');
    }
    public set isLayerRefreshing(value: boolean) {
        this.setField<boolean>('IsLayerRefreshing', value);
    }
    // LastRefreshTime
    public get lastRefreshTime(): Date {
        return this.getFieldDate('LastRefreshTime');
    }
    public set lastRefreshTime(value: Date) {
        this.setFieldDate('LastRefreshTime', value);
    }
    // IsInDesignMode
    public get isInDesignMode(): boolean {
        return this.getField<boolean>('IsInDesignMode');
    }
    public set isInDesignMode(value: boolean) {
        this.setField<boolean>('IsInDesignMode', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Url', '');
        this.setField<number>('RefreshRate', 0);
        this.setField<boolean>('IsLayerRefreshing', false);
        this.setFieldDate('LastRefreshTime', new Date());
        this.setField<boolean>('IsInDesignMode', false);
    }
}

export class CustomMapLayer extends FieldObject implements ICustomMapLayer {
    // RefreshableLayer
    public get refreshableLayer(): ICustomRefreshableLayer {
        return this.getFieldObject<CustomRefreshableLayer, ICustomRefreshableLayer>(CustomRefreshableLayer, 'RefreshableLayer');
    }
    public set refreshableLayer(value: ICustomRefreshableLayer) {
        this.setFieldObject<ICustomRefreshableLayer>('RefreshableLayer', value);
    }
    // IsInDesignMode
    public get isInDesignMode(): boolean {
        return this.getField<boolean>('IsInDesignMode');
    }
    public set isInDesignMode(value: boolean) {
        this.setField<boolean>('IsInDesignMode', value);
    }
    // RefreshRate
    public get refreshRate(): string {
        return this.getField<string>('RefreshRate');
    }
    public set refreshRate(value: string) {
        this.setField<string>('RefreshRate', value);
    }
    // RefreshRateTs
    public get refreshRateTs(): number {
        return this.getField<number>('RefreshRateTs');
    }
    public set refreshRateTs(value: number) {
        this.setField<number>('RefreshRateTs', value);
    }
    // IsRefreshingLayer
    public get isRefreshingLayer(): boolean {
        return this.getField<boolean>('IsRefreshingLayer');
    }
    public set isRefreshingLayer(value: boolean) {
        this.setField<boolean>('IsRefreshingLayer', value);
    }
    // Url
    public get url(): string {
        return this.getField<string>('Url');
    }
    public set url(value: string) {
        this.setField<string>('Url', value);
    }
    // ZIndex
    public get zIndex(): number {
        return this.getField<number>('ZIndex');
    }
    public set zIndex(value: number) {
        this.setField<number>('ZIndex', value);
    }
    // IsPartialChecked
    public get isPartialChecked(): boolean {
        return this.getField<boolean>('IsPartialChecked');
    }
    public set isPartialChecked(value: boolean) {
        this.setField<boolean>('IsPartialChecked', value);
    }
    // Opacity
    public get opacity(): number {
        return this.getField<number>('Opacity');
    }
    public set opacity(value: number) {
        this.setField<number>('Opacity', value);
    }
    // SubLayers
    public get subLayers(): ObservableCollection<ICustomVisibleLayer> {
        return this.getFieldObjectArray<CustomVisibleLayer, ICustomVisibleLayer>(CustomVisibleLayer, 'SubLayers');
    }
    public set subLayers(value: ObservableCollection<ICustomVisibleLayer>) {
        this.setFieldObjectArray('SubLayers', value);
    }
    // ParentLayerId
    public get parentLayerId(): string {
        return this.getField<string>('ParentLayerId');
    }
    public set parentLayerId(value: string) {
        this.setField<string>('ParentLayerId', value);
    }
    // IsDisplayed
    public get isDisplayed(): boolean {
        return this.getField<boolean>('IsDisplayed');
    }
    public set isDisplayed(value: boolean) {
        this.setField<boolean>('IsDisplayed', value);
    }
    // Id
    public get id(): string {
        return this.getField<string>('Id');
    }
    public set id(value: string) {
        this.setField<string>('Id', value);
    }
    // Title
    public get title(): string {
        return this.getField<string>('Title');
    }
    public set title(value: string) {
        this.setField<string>('Title', value);
    }
    // Token
    public get token(): string {
        return this.getField<string>('Token');
    }
    public set token(value: string) {
        this.setField<string>('Token', value);
    }

    public initializeAllFields(): void {
        const objRefreshableLayer = new CustomRefreshableLayer();
        objRefreshableLayer.initializeAllFields();
        this.setFieldObject('RefreshableLayer', objRefreshableLayer);
        this.setField<boolean>('IsInDesignMode', false);
        this.setField<string>('RefreshRate', '');
        this.setField<number>('RefreshRateTs', 0);
        this.setField<boolean>('IsRefreshingLayer', false);
        this.setField<string>('Url', '');
        this.setField<number>('ZIndex', 0);
        this.setField<boolean>('IsPartialChecked', false);
        this.setField<number>('Opacity', 0);
        this.setFieldArray<ICustomVisibleLayer>('SubLayers', new ObservableCollection<ICustomVisibleLayer>());
        this.setField<string>('ParentLayerId', '');
        this.setField<boolean>('IsDisplayed', false);
        this.setField<string>('Id', '');
        this.setField<string>('Title', '');
        this.setField<string>('Token', '');
    }
}

export class CustomSpecificMapConfiguration extends FieldObject implements ICustomSpecificMapConfiguration {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Layers
    public get layers(): ObservableCollection<ICustomMapLayer> {
        return this.getFieldObjectArray<CustomMapLayer, ICustomMapLayer>(CustomMapLayer, 'Layers');
    }
    public set layers(value: ObservableCollection<ICustomMapLayer>) {
        this.setFieldObjectArray('Layers', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setFieldArray<ICustomMapLayer>('Layers', new ObservableCollection<ICustomMapLayer>());
    }
}

export class StateColor extends FieldObject implements IStateColor {
    // State
    public get state(): number {
        return this.getField<number>('State');
    }
    public set state(value: number) {
        this.setField<number>('State', value);
    }
    // Color
    public get color(): string {
        return this.getField<string>('Color');
    }
    public set color(value: string) {
        this.setField<string>('Color', value);
    }
    // Visible
    public get visible(): boolean {
        return this.getField<boolean>('Visible');
    }
    public set visible(value: boolean) {
        this.setField<boolean>('Visible', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('State', 0);
        this.setField<string>('Color', '');
        this.setField<boolean>('Visible', false);
    }
}

export class MapObject extends FieldObject implements IMapObject {
    // Guid
    public get guid(): IGuid {
        return this.getFieldGuid('Guid');
    }
    public set guid(value: IGuid) {
        this.setFieldGuid('Guid', value);
    }
    // Data
    public get data(): string {
        return this.getField<string>('Data');
    }
    public set data(value: string) {
        this.setField<string>('Data', value);
    }
    // File
    public get file(): IGuid | null {
        return this.getNullableFieldGuid('File');
    }
    public set file(value: IGuid | null) {
        this.setNullableFieldGuid('File', value);
    }
    // Latitude
    public get latitude(): number {
        return this.getField<number>('Latitude');
    }
    public set latitude(value: number) {
        this.setField<number>('Latitude', value);
    }
    // Link
    public get link(): IGuid | null {
        return this.getNullableFieldGuid('Link');
    }
    public set link(value: IGuid | null) {
        this.setNullableFieldGuid('Link', value);
    }
    // Longitude
    public get longitude(): number {
        return this.getField<number>('Longitude');
    }
    public set longitude(value: number) {
        this.setField<number>('Longitude', value);
    }
    // RelativeHeight
    public get relativeHeight(): number {
        return this.getField<number>('RelativeHeight');
    }
    public set relativeHeight(value: number) {
        this.setField<number>('RelativeHeight', value);
    }
    // RelativeWidth
    public get relativeWidth(): number {
        return this.getField<number>('RelativeWidth');
    }
    public set relativeWidth(value: number) {
        this.setField<number>('RelativeWidth', value);
    }
    // Rotation
    public get rotation(): number {
        return this.getField<number>('Rotation');
    }
    public set rotation(value: number) {
        this.setField<number>('Rotation', value);
    }
    // ShowFov
    public get showFov(): boolean {
        return this.getField<boolean>('ShowFov');
    }
    public set showFov(value: boolean) {
        this.setField<boolean>('ShowFov', value);
    }
    // FovDistance
    public get fovDistance(): number {
        return this.getField<number>('FovDistance');
    }
    public set fovDistance(value: number) {
        this.setField<number>('FovDistance', value);
    }
    // FovMaxDistance
    public get fovMaxDistance(): number {
        return this.getField<number>('FovMaxDistance');
    }
    public set fovMaxDistance(value: number) {
        this.setField<number>('FovMaxDistance', value);
    }
    // FovElevation
    public get fovElevation(): number {
        return this.getField<number>('FovElevation');
    }
    public set fovElevation(value: number) {
        this.setField<number>('FovElevation', value);
    }
    // FovOrientation
    public get fovOrientation(): number {
        return this.getField<number>('FovOrientation');
    }
    public set fovOrientation(value: number) {
        this.setField<number>('FovOrientation', value);
    }
    // FovWidth
    public get fovWidth(): number {
        return this.getField<number>('FovWidth');
    }
    public set fovWidth(value: number) {
        this.setField<number>('FovWidth', value);
    }
    // Type
    public get type(): IGuid {
        return this.getFieldGuid('Type');
    }
    public set type(value: IGuid) {
        this.setFieldGuid('Type', value);
    }
    // StateColors
    public get stateColors(): ObservableCollection<IStateColor> {
        return this.getFieldObjectArray<StateColor, IStateColor>(StateColor, 'StateColors');
    }
    public set stateColors(value: ObservableCollection<IStateColor>) {
        this.setFieldObjectArray('StateColors', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Guid', SafeGuid.EMPTY);
        this.setField<string>('Data', '');
        this.setFieldGuid('File', SafeGuid.EMPTY);
        this.setField<number>('Latitude', 0);
        this.setFieldGuid('Link', SafeGuid.EMPTY);
        this.setField<number>('Longitude', 0);
        this.setField<number>('RelativeHeight', 0);
        this.setField<number>('RelativeWidth', 0);
        this.setField<number>('Rotation', 0);
        this.setField<boolean>('ShowFov', false);
        this.setField<number>('FovDistance', 0);
        this.setField<number>('FovMaxDistance', 0);
        this.setField<number>('FovElevation', 0);
        this.setField<number>('FovOrientation', 0);
        this.setField<number>('FovWidth', 0);
        this.setFieldGuid('Type', SafeGuid.EMPTY);
        this.setFieldArray<IStateColor>('StateColors', new ObservableCollection<IStateColor>());
    }
}

export class VisibleLayer extends FieldObject implements IVisibleLayer {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // IsDisplayed
    public get isDisplayed(): boolean {
        return this.getField<boolean>('IsDisplayed');
    }
    public set isDisplayed(value: boolean) {
        this.setField<boolean>('IsDisplayed', value);
    }
    // ZIndex
    public get zIndex(): number {
        return this.getField<number>('ZIndex');
    }
    public set zIndex(value: number) {
        this.setField<number>('ZIndex', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('IsDisplayed', false);
        this.setField<number>('ZIndex', 0);
    }
}

export class Point extends FieldObject implements IPoint {
    // X
    public get x(): number {
        return this.getField<number>('X');
    }
    public set x(value: number) {
        this.setField<number>('X', value);
    }
    // Y
    public get y(): number {
        return this.getField<number>('Y');
    }
    public set y(value: number) {
        this.setField<number>('Y', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('X', 0);
        this.setField<number>('Y', 0);
    }
}

export class GeoReference extends FieldObject implements IGeoReference {
    // TargetPoints
    public get targetPoints(): ObservableCollection<ICoordinate> {
        return this.getFieldObjectArray<Coordinate, ICoordinate>(Coordinate, 'TargetPoints');
    }
    public set targetPoints(value: ObservableCollection<ICoordinate>) {
        this.setFieldObjectArray('TargetPoints', value);
    }
    // SourcePoints
    public get sourcePoints(): ObservableCollection<IPoint> {
        return this.getFieldObjectArray<Point, IPoint>(Point, 'SourcePoints');
    }
    public set sourcePoints(value: ObservableCollection<IPoint>) {
        this.setFieldObjectArray('SourcePoints', value);
    }
    // IsValid
    public get isValid(): boolean {
        return this.getField<boolean>('IsValid');
    }
    public set isValid(value: boolean) {
        this.setField<boolean>('IsValid', value);
    }

    public initializeAllFields(): void {
        this.setFieldArray<ICoordinate>('TargetPoints', new ObservableCollection<ICoordinate>());
        this.setFieldArray<IPoint>('SourcePoints', new ObservableCollection<IPoint>());
        this.setField<boolean>('IsValid', false);
    }
}

export class MapFloorData extends FieldObject implements IMapFloorData {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // Abbreviation
    public get abbreviation(): string {
        return this.getField<string>('Abbreviation');
    }
    public set abbreviation(value: string) {
        this.setField<string>('Abbreviation', value);
    }
    // OrderPosition
    public get orderPosition(): number {
        return this.getField<number>('OrderPosition');
    }
    public set orderPosition(value: number) {
        this.setField<number>('OrderPosition', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('Abbreviation', '');
        this.setField<number>('OrderPosition', 0);
    }
}

export class LatLngRectangle extends FieldObject implements ILatLngRectangle {
    // BottomRight
    public get bottomRight(): ICoordinate {
        return this.getFieldObject<Coordinate, ICoordinate>(Coordinate, 'BottomRight');
    }
    public set bottomRight(value: ICoordinate) {
        this.setFieldObject<ICoordinate>('BottomRight', value);
    }
    // TopLeft
    public get topLeft(): ICoordinate {
        return this.getFieldObject<Coordinate, ICoordinate>(Coordinate, 'TopLeft');
    }
    public set topLeft(value: ICoordinate) {
        this.setFieldObject<ICoordinate>('TopLeft', value);
    }

    public initializeAllFields(): void {
        const objBottomRight = new Coordinate();
        objBottomRight.initializeAllFields();
        this.setFieldObject('BottomRight', objBottomRight);
        const objTopLeft = new Coordinate();
        objTopLeft.initializeAllFields();
        this.setFieldObject('TopLeft', objTopLeft);
    }
}

export class Coordinate extends FieldObject implements ICoordinate {
    // Latitude
    public get latitude(): number {
        return this.getField<number>('Latitude');
    }
    public set latitude(value: number) {
        this.setField<number>('Latitude', value);
    }
    // Longitude
    public get longitude(): number {
        return this.getField<number>('Longitude');
    }
    public set longitude(value: number) {
        this.setField<number>('Longitude', value);
    }
    // Altitude
    public get altitude(): number | null {
        return this.getNullableField<number>('Altitude');
    }
    public set altitude(value: number | null) {
        this.setNullableField<number>('Altitude', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Latitude', 0);
        this.setField<number>('Longitude', 0);
        this.setField<number>('Altitude', 0);
    }
}

export class DefaultView extends FieldObject implements IDefaultView {
    // Name
    public get name(): string {
        return this.getField<string>('Name');
    }
    public set name(value: string) {
        this.setField<string>('Name', value);
    }
    // ScaleFactor
    public get scaleFactor(): number {
        return this.getField<number>('ScaleFactor');
    }
    public set scaleFactor(value: number) {
        this.setField<number>('ScaleFactor', value);
    }
    // Center
    public get center(): ICoordinate {
        return this.getFieldObject<Coordinate, ICoordinate>(Coordinate, 'Center');
    }
    public set center(value: ICoordinate) {
        this.setFieldObject<ICoordinate>('Center', value);
    }
    // ViewArea
    public get viewArea(): ILatLngRectangle {
        return this.getFieldObject<LatLngRectangle, ILatLngRectangle>(LatLngRectangle, 'ViewArea');
    }
    public set viewArea(value: ILatLngRectangle) {
        this.setFieldObject<ILatLngRectangle>('ViewArea', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('Name', '');
        this.setField<number>('ScaleFactor', 0);
        const objCenter = new Coordinate();
        objCenter.initializeAllFields();
        this.setFieldObject('Center', objCenter);
        const objViewArea = new LatLngRectangle();
        objViewArea.initializeAllFields();
        this.setFieldObject('ViewArea', objViewArea);
    }
}

export class Thickness extends FieldObject implements IThickness {
    // Left
    public get left(): number {
        return this.getField<number>('Left');
    }
    public set left(value: number) {
        this.setField<number>('Left', value);
    }
    // Top
    public get top(): number {
        return this.getField<number>('Top');
    }
    public set top(value: number) {
        this.setField<number>('Top', value);
    }
    // Right
    public get right(): number {
        return this.getField<number>('Right');
    }
    public set right(value: number) {
        this.setField<number>('Right', value);
    }
    // Bottom
    public get bottom(): number {
        return this.getField<number>('Bottom');
    }
    public set bottom(value: number) {
        this.setField<number>('Bottom', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Left', 0);
        this.setField<number>('Top', 0);
        this.setField<number>('Right', 0);
        this.setField<number>('Bottom', 0);
    }
}

// #endregion Inner classes

export class MapEntity extends Entity implements IMapEntity {
    // #region REST Properties

    // AssociatedArea
    public get associatedArea(): IGuid {
        return this.getFieldGuid(MapEntityFields.associatedAreaField, ValidFlags.Specific);
    }

    // BackgroundColor
    public get backgroundColor(): number {
        return this.getField<number>(MapEntityFields.backgroundColorField, ValidFlags.Specific);
    }
    public set backgroundColor(value: number) {
        this.setField<number>(MapEntityFields.backgroundColorField, value);
    }

    // CruncherId
    public get cruncherId(): IGuid {
        return this.getFieldGuid(MapEntityFields.cruncherIdField, ValidFlags.Specific);
    }
    public set cruncherId(value: IGuid) {
        this.setFieldGuid(MapEntityFields.cruncherIdField, value);
    }

    // DPI
    public get dPI(): number {
        return this.getField<number>(MapEntityFields.dPIField, ValidFlags.Specific);
    }
    public set dPI(value: number) {
        this.setField<number>(MapEntityFields.dPIField, value);
    }

    // GeoLocalized
    public get geoLocalized(): boolean {
        return this.getField<boolean>(MapEntityFields.geoLocalizedField, ValidFlags.Specific);
    }
    public set geoLocalized(value: boolean) {
        this.setField<boolean>(MapEntityFields.geoLocalizedField, value);
    }

    // HideEmptyLayers
    public get hideEmptyLayers(): boolean {
        return this.getField<boolean>(MapEntityFields.hideEmptyLayersField, ValidFlags.Specific);
    }
    public set hideEmptyLayers(value: boolean) {
        this.setField<boolean>(MapEntityFields.hideEmptyLayersField, value);
    }

    // Info
    public get info(): string {
        return this.getField<string>(MapEntityFields.infoField, ValidFlags.Specific);
    }
    public set info(value: string) {
        this.setField<string>(MapEntityFields.infoField, value);
    }

    // IsMapImage
    public get isMapImage(): boolean {
        return this.getField<boolean>(MapEntityFields.isMapImageField);
    }

    // LockDisplay
    public get lockDisplay(): boolean {
        return this.getField<boolean>(MapEntityFields.lockDisplayField, ValidFlags.Specific);
    }
    public set lockDisplay(value: boolean) {
        this.setField<boolean>(MapEntityFields.lockDisplayField, value);
    }

    // LockZoom
    public get lockZoom(): boolean {
        return this.getField<boolean>(MapEntityFields.lockZoomField, ValidFlags.Specific);
    }
    public set lockZoom(value: boolean) {
        this.setField<boolean>(MapEntityFields.lockZoomField, value);
    }

    // MaxZoom
    public get maxZoom(): number {
        return this.getField<number>(MapEntityFields.maxZoomField, ValidFlags.Specific);
    }
    public set maxZoom(value: number) {
        this.setField<number>(MapEntityFields.maxZoomField, value);
    }

    // OriginalFileExtension
    public get originalFileExtension(): string {
        return this.getField<string>(MapEntityFields.originalFileExtensionField, ValidFlags.Specific);
    }
    public set originalFileExtension(value: string) {
        this.setField<string>(MapEntityFields.originalFileExtensionField, value);
    }

    // Projection
    public get projection(): string {
        return this.getField<string>(MapEntityFields.projectionField, ValidFlags.Specific);
    }
    public set projection(value: string) {
        this.setField<string>(MapEntityFields.projectionField, value);
    }

    // ProviderId
    public get providerId(): IGuid {
        return this.getFieldGuid(MapEntityFields.providerIdField, ValidFlags.Specific);
    }
    public set providerId(value: IGuid) {
        this.setFieldGuid(MapEntityFields.providerIdField, value);
    }

    // Rotation
    public get rotation(): number {
        return this.getField<number>(MapEntityFields.rotationField, ValidFlags.Specific);
    }
    public set rotation(value: number) {
        this.setField<number>(MapEntityFields.rotationField, value);
    }

    // ScaleFactor
    public get scaleFactor(): number {
        return this.getField<number>(MapEntityFields.scaleFactorField, ValidFlags.Specific);
    }
    public set scaleFactor(value: number) {
        this.setField<number>(MapEntityFields.scaleFactorField, value);
    }

    // ServerUris
    public get serverUris(): ObservableCollection<string> {
        return this.getFieldArray<string>(MapEntityFields.serverUrisField);
    }

    // Status
    public get status(): string {
        return this.getField<string>(MapEntityFields.statusField, ValidFlags.EntityStatus2);
    }

    // ThumbnailId
    public get thumbnailId(): IGuid {
        return this.getFieldGuid(MapEntityFields.thumbnailIdField, ValidFlags.Specific);
    }

    // Version
    public get version(): IGuid {
        return this.getFieldGuid(MapEntityFields.versionField, ValidFlags.Specific);
    }
    public set version(value: IGuid) {
        this.setFieldGuid(MapEntityFields.versionField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Maps);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getCropAsync(transaction?: ITransaction | null): Promise<IThickness | null> {
        return await this.getDataRelationAsync<Thickness>(Thickness, 'Crop', true, ValidFlags.Specific, transaction);
    }

    public async getDefaultViewAsync(transaction?: ITransaction | null): Promise<IDefaultView | null> {
        return await this.getDataRelationAsync<DefaultView>(DefaultView, 'DefaultView', true, ValidFlags.Specific, transaction);
    }

    public async getFloorInfoAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMapFloorData> | null> {
        return this.getFieldObjectRelationAsync<MapFloorData, IMapFloorData>(MapFloorData, 'FloorInfo', 'Id', false, true, ValidFlags.Specific, transaction);
    }

    public async getGeoReferenceAsync(transaction?: ITransaction | null): Promise<IGeoReference | null> {
        return await this.getDataRelationAsync<GeoReference>(GeoReference, 'GeoReference', false, ValidFlags.Specific, transaction);
    }

    public async getLayersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IVisibleLayer> | null> {
        return this.getFieldObjectRelationAsync<VisibleLayer, IVisibleLayer>(VisibleLayer, 'Layers', '', true, false, ValidFlags.Specific, transaction);
    }

    public async getMapObjectsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMapObject> | null> {
        return this.getFieldObjectRelationAsync<MapObject, IMapObject>(MapObject, 'MapObjects', 'Guid', false, true, ValidFlags.Membership, transaction);
    }

    public async getSpecificMapsConfigurationsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICustomSpecificMapConfiguration> | null> {
        return this.getFieldObjectRelationAsync<CustomSpecificMapConfiguration, ICustomSpecificMapConfiguration>(CustomSpecificMapConfiguration, 'SpecificMapsConfigurations', 'Id', true, false, ValidFlags.Specific, transaction);
    }

    public async getViewAreaPresetsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDefaultView> | null> {
        return this.getFieldObjectRelationAsync<DefaultView, IDefaultView>(DefaultView, 'ViewAreaPresets', 'Id', true, false, ValidFlags.Specific, transaction);
    }

    // #endregion REST Methods

}

