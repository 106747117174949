import 'reflect-metadata';
import { IGuid, SafeGuid } from 'safeguid';
import { CustomIconState } from '@modules/shared/api/api';
import { PluginDescriptor, ContentPluginDescriptor, MetadataKey } from '../public/plugin-public.interface';
import { PluginService } from '../../../services/plugin/plugin.service';
import { Content } from '../public/plugin-public.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

// This interface is used to create plugin inside this app directly
export interface InternalPluginDescriptor extends PluginDescriptor {
    /**
     * @deprecated PluginDescriptor now uses the class' the decorator is on as type, do not use this property.
     *
     * TODO: Remove all uses of type property in all modules.
     */
    type?: Constructor;
}

export interface InternalContentPluginDescriptor extends ContentPluginDescriptor {
    /**
     * @deprecated PluginDescriptor now uses the class' the decorator is on as type, do not use this property.
     *
     * TODO: Remove all uses of type property in all modules.
     */
    type?: Constructor;
}

type Constructor<T = any> = new (...args: any[]) => T;

const PluginDescriptorImpl = (pluginDescriptor: InternalPluginDescriptor | InternalContentPluginDescriptor): ((constructor: Constructor) => void) => {
    return (constructor: Constructor) => {
        PluginService.availablePlugins.push(pluginDescriptor.type ?? constructor);
        Reflect.defineMetadata(MetadataKey, pluginDescriptor, constructor);
    };
};

export const InternalPluginDescriptor = (pluginDescriptor: InternalPluginDescriptor): ((constructor: Constructor) => void) => PluginDescriptorImpl(pluginDescriptor);

export const InternalContentPluginDescriptor = (pluginDescriptor: InternalContentPluginDescriptor): ((constructor: Constructor) => void) => PluginDescriptorImpl(pluginDescriptor);

export interface ContentLoadingSupport {
    setLoadingMainContent(): void;
}

export class ContentLoad implements Content {
    public static typeId = SafeGuid.parse('846FAC1E-63D6-4D82-9D45-EE10B33DA13E');
    public id: IGuid = SafeGuid.EMPTY;
    public type: IGuid = ContentLoad.typeId;
    public title = '';
    public description = '';
    public icon = '';
    public customIconId = SafeGuid.EMPTY;
    public customIconState = CustomIconState.All;
    public color = '';
    public source = '';
    public contextContent = null;
    public contextsubContents = [];
    public parameters = null;
    public grantedPrivileges = [];
}
