import { IVideoFrame } from '../../players/VideoFrameSource';
import { Cancellation } from '../../utils/CancellationToken';
import { ILogger } from '../../utils/logger';
import { Utils } from '../../utils/Utils';
import { ClipSpaceVec2 } from '../../utils/Vec2';
import { VideoWatermarkConfig } from '../../utils/VideoWatermarkingConfig';
import { DewarpedArea } from '../DewarpedArea/DewarpedArea';
import { IDewarperPreview, IInternalDewarperControl } from '../DewarperInterfaces';
import { DewarpingParameters } from '../DewarpingParameters';
import { IPreviewCanvas } from './PreviewCanvas';

export class DewarperPreview implements IDewarperPreview {
  private readonly m_logger: ILogger;

  private m_lastImageSource: IVideoFrame | null = null;

  private readonly m_previewCanvas: IPreviewCanvas;
  private readonly m_dewarperControl: IInternalDewarperControl;

  private m_isEnabled: boolean = false; // Preview is configured to be displayed when dewarping is occuring
  private m_isDewarping: boolean = false; // Indicate if we are currently dewarping

  private readonly m_dewarpedArea: DewarpedArea | null;

  public setDewarpingParameters(dewarpingParameters: DewarpingParameters) {
    if (this.m_dewarpedArea !== null) {
      this.m_dewarpedArea.setDewarpingParameters(dewarpingParameters);
    }
  }

  public get Enabled(): boolean {
    return this.m_isEnabled;
  }

  public set Enabled(value: boolean) {
    if (this.m_isEnabled !== value) {
      this.m_isEnabled = value;
      //For now we link enabling the dewarpedArea with enabling the preview.
      //If computing the dewarped area is ever a performance problem or whatever other reason, it could be on its own property
      if (this.m_dewarpedArea !== null) {
        this.m_dewarpedArea.Enabled = value;
      }
      this.setPreviewVisibility();
    }
  }

  public set IsDewarping(value: boolean) {
    if (this.m_isDewarping !== value) {
      this.m_isDewarping = value;
      if (this.m_dewarpedArea !== null) {
        this.m_dewarpedArea.IsDewarping = value;
      }
      this.setPreviewVisibility();
    }
  }

  // So the user can reposition/resize or do anything else with the preview
  public get Canvas(): HTMLCanvasElement {
    return this.m_previewCanvas.Canvas;
  }

  public constructor(logger: ILogger, previewCanvas: IPreviewCanvas, dewarperControl: IInternalDewarperControl, dewarpedArea: DewarpedArea | null) {
    this.m_logger = logger.subLogger('DewarperPreview');
    this.m_previewCanvas = previewCanvas;
    this.m_dewarperControl = dewarperControl;

    this.m_previewCanvas.Clicked.register(this.onClick);
    this.m_previewCanvas.Resized.register(this.onPreviewCanvasResize);

    this.m_dewarpedArea = dewarpedArea;

    this.observeMouseWheelLoop();
  }

  public dispose() {
    this.m_logger.debug?.trace('dispose');
    this.m_previewCanvas.Clicked.unregister(this.onClick);
    this.m_previewCanvas.Resized.unregister(this.onPreviewCanvasResize);
    this.m_previewCanvas.dispose();

    this.m_dewarpedArea?.dispose();
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig): void {
    this.m_previewCanvas.updateVideoWatermarkingConfig(videoWatermarkingConfig);
  }

  public render(imageSource: IVideoFrame): void {
    this.m_lastImageSource = imageSource;
    this.m_previewCanvas.render(imageSource);
  }

  private setPreviewVisibility(): void {
    const show = this.m_isDewarping && this.m_isEnabled;
    this.m_logger.debug?.trace(`${(show ? 'showing' : 'hiding')} dewarper preview [Dewarping: ${this.m_isDewarping}, Enabled: ${this.m_isEnabled}]`);
    this.m_previewCanvas.Show = show;
  }

  private readonly onPreviewCanvasResize = (): void => {
    if (this.m_lastImageSource !== null) {
      this.m_previewCanvas.render(this.m_lastImageSource);
    }
  }

  private readonly onClick = (clipSpaceCoordinates: ClipSpaceVec2): void => {
    this.m_dewarperControl.gotoXY(clipSpaceCoordinates);
    this.refresh();
  }

  private async observeMouseWheelLoop() {
    let mouseWheeled = await this.m_previewCanvas.MouseWheel;
    while (!(mouseWheeled instanceof Cancellation)) {
      const decrease: boolean = (<number>mouseWheeled > 0);

      const newZoom = decrease ?
        this.m_dewarperControl.ZoomFactor.decrease() :
        this.m_dewarperControl.ZoomFactor.increase();

      this.m_dewarperControl.zoom(newZoom);

      mouseWheeled = await this.m_previewCanvas.MouseWheel;
    }
    this.m_logger.debug?.trace('End of mouse wheel observation loop');
  }

  private refresh(): void {
    if (this.m_lastImageSource !== null) {
      this.render(this.m_lastImageSource);
    }
  }

  public debugStatus(indent: number): string {
    return `Dewarper preview: (${this.m_isEnabled ? 'enabled' : 'disabled'})` + Utils.indentNewLine(indent) +
      'Is dewarping: ' + this.m_isDewarping + Utils.indentNewLine(indent) +
      this.m_previewCanvas.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      (this.m_dewarpedArea !== null ? this.m_dewarpedArea.debugStatus(indent + Utils.Indentation) : 'No dewarped area');
  }
}
