/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { MethodEmitter } from '@src/app/store';
import { Observable } from 'rxjs';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { SecurityCenterStateService } from '@modules/shared/services/state/security-center-state.service';
import { SafeGuid } from 'safeguid';
import { ContentLoad, ContentLoadingSupport } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { ContentGroup, DisplayContext } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { MapsSideContextDataState } from './maps-side-context-data.state';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Injectable()
export class MapsSideContextDataService extends SecurityCenterStateService implements ContentService, ContentLoadingSupport {
    @Select(MapsSideContextDataState.currentContent)
    public currentContent$!: Observable<ContentGroup | null>;

    @SelectSnapshot(MapsSideContextDataState.currentContent)
    public currentContent!: ContentGroup | null;

    @SelectSnapshot(MapsSideContextDataState.contentCount)
    public contentCount!: number;

    displayContext: DisplayContext = DisplayContext.Sidepane;

    constructor(securityCenterClient: SecurityCenterClientService) {
        super(securityCenterClient);
    }

    @MethodEmitter(MapsSideContextDataState.setMainContent)
    public setMainContent(_: ContentGroup | undefined | null): void {}

    @MethodEmitter(MapsSideContextDataState.pushContent)
    public pushContent(_?: ContentGroup): void {}

    @MethodEmitter(MapsSideContextDataState.popContent)
    public popContent(): void {}

    public setLoadingMainContent(): void {
        this.setMainContent({ id: SafeGuid.newGuid(), mainContent: new ContentLoad(), subContents: [] });
    }

    public clearMainContent(): void {
        this.setMainContent(null);
    }

    protected clearState(): void {
        super.clearState();

        this.setMainContent(null);
    }
}
