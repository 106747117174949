import { SafeGuid } from 'safeguid';
import { Constants } from '../../../utilities/constants';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export const PluginTypes = Constants({
    /**
     *  Plugins of this type will be rendered in the left hand side nav bar
     */
    Task: SafeGuid.parse('BCA27DB8-BB36-4AE4-A554-61BC6226F8C7'),

    /**
     * Plugins of this type will be rendered in the tasks's sidepane
     */
    AccessControlSubTask: SafeGuid.parse('1EE7BF28-70BC-4749-9526-E1CB2FBE88F9'),

    /**
     * Plugins of this type will be rendered in the top right corner of the app (application tray)
     */
    Tray: SafeGuid.parse('86DB5251-048E-492E-931C-9854C1AC3E7B'),

    /**
     * Plugins of this type will be rendered at the bottom of the left hand side nav bar
     */
    NavBarExtension: SafeGuid.parse('1E56B59F-C779-440D-9437-E04AB13F7DDD'),

    /**
     * Plugins of this type will be rendered in the tile task
     */
    Tile: SafeGuid.parse('500CF7EE-5F0B-4B78-A54C-4B925C549815'),

    /**
     *  Plugins of this type will be rendered in the right hand side pane. sidepane plugins are called widgets.
     */
    Widget: SafeGuid.parse('BF6B5AF6-1F7F-4F47-A3AE-4E0200E10C05'),

    /**
     *  Plugins of this type will be rendered inside the dashboard task
     */
    Dashboard: SafeGuid.parse('A2AA46CB-020E-4DEC-81DA-C77BE5AD158F'),

    /**
     *  Plugins of this type will be rendered in the Options dialog as submenus
     */
    Option: SafeGuid.parse('15D0D94A-DF90-4CDB-A8EC-CB755436F063'),

    /**
     * Plugins of this type will be rendered in the reports task
     */
    Filter: SafeGuid.parse('F2F65026-8311-4C37-A7D4-9E7562147004'),

    /**
     *  Plugins of this type will be rendered as soon as there is an event triggered on the map, or a mouse hover is issued.
     */
    MapPopupCompact: SafeGuid.parse('2C1B1BF8-F3FD-410B-BFF8-14CDFF193D77'),

    /**
     * Plugins of this type will replace the MapPopupCompact content on mouse hover.
     * When mouse out is issued, the content will be replaced back to MapPopupCompact.
     */
    MapPopupExpand: SafeGuid.parse('A801EAE0-1249-414A-A64F-49760B9C58B6'),

    /**
     *  Plugins of this type will be rendered in the MapTaskTray (Left of Map Layers action icon).
     */
    MapTray: SafeGuid.parse('9D3E77A8-3B1E-4CE1-AA2A-F37A3B3D9095'),

    /**
     * Plugins of this type will be rendered on top of their supported Tile
     *
     * @example The app-door-entity-state displayed over as dragged door on a tile
     */
    TileHeader: SafeGuid.parse('523FF5CD-ED11-43AF-90EC-2963B0BA0FFE'),

    /**
     * Plugins of this type will be rendered on top of the side pane
     *
     * @example The watchlist-sidepane-header displayed when we open the event monitoring task in the side pane
     */
    SidePaneHeader: SafeGuid.parse('EF3F895B-DA54-4209-8B16-34F8831FEBE7'),
});

/**
 * Various "tabs" that appears on the left hand side of the options dialog.
 */
export const OptionSubSections = Constants({
    General: SafeGuid.parse('2E6D5B14-BBE1-4AE8-AA19-E68ABCD0C235'),
    Peripherals: SafeGuid.parse('E84E2CD6-5ADA-453A-89E2-E24C62ADBD86'),
    Advanced: SafeGuid.parse('01F32B50-9EAD-4890-8F6E-0928E21A8C26'),
    Video: SafeGuid.parse('B5163DCE-30D5-47FA-8D03-9C0B43E85E7E'),
    Events: SafeGuid.parse('22BE457C-E0DA-417A-AB59-119718015221'),
    Map: SafeGuid.parse('59E37707-C77E-C7F4-11CE-9A9449077EBB'),
    Tiles: SafeGuid.parse('44E0E750-8288-43B0-B138-41323A3F2DA0'),
});

export const TraySubSections = Constants({
    /**
     * Default value when PluginType is Tray.
     */
    MainBar: SafeGuid.parse('13CD4507-7311-4B29-A49B-988F5EB2381C'),

    /**
     * Tray items displayed under a caret to preserve Tray real estate.
     */
    Secondary: SafeGuid.parse('1C252E13-EE3D-4C64-B570-1509DFAB3610'),
});
