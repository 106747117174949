// Autogenerated: CodeGen/ExpanderFilterData?language=ts&InterfaceOnly=True on 2021-05-10 11:39:53 AM
// Autogenerated: CodeGen/ExpanderFilterData?language=ts&InterfaceOnly=False on 2021-05-10 11:39:53 AM
import { IGuid, SafeGuid } from 'safeguid';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { EntityTypeData, IEntityTypeData } from './entity-type-data';

export interface IExpanderFilterData extends IFieldObject {
    entityTypeData: IEntityTypeData;
    expanderType: IGuid;
}

export class ExpanderFilterData extends FieldObject implements IExpanderFilterData {
    // EntityTypeData
    public get entityTypeData(): IEntityTypeData {
        return this.getFieldObject<EntityTypeData, IEntityTypeData>(EntityTypeData, 'EntityTypeData');
    }
    public set entityTypeData(value: IEntityTypeData) {
        this.setFieldObject<IEntityTypeData>('EntityTypeData', value);
    }
    // ExpanderType
    public get expanderType(): IGuid {
        return this.getFieldGuid('ExpanderType');
    }
    public set expanderType(value: IGuid) {
        this.setFieldGuid('ExpanderType', value);
    }

    public initializeAllFields(): void {
        const objEntityTypeData = new EntityTypeData();
        objEntityTypeData.initializeAllFields();
        this.setFieldObject('EntityTypeData', objEntityTypeData);
        this.setFieldGuid('ExpanderType', SafeGuid.EMPTY);
    }
}
