import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon, IconSize } from '@genetec/gelato';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGuid } from 'safeguid';
import { PluginItem } from '../../../interfaces/plugins/internal/pluginItem';
import { Content } from '../../../interfaces/plugins/public/plugin-public.interface';
import { TrackingService } from '../../../services/tracking.service';
import { TrackedComponent } from '../../tracked/tracked.component';
import { MultiPlugin } from '../multi-plugin';
import { PluginHostClickEvent } from '../plugin-host.component';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export interface ExpansionInfo {
    delay: number;
    isPermanent: boolean;
    notifyIfNotExpandedDelay: number | undefined;
    pluginType: IGuid;
    compactPluginType: IGuid;
}

@Component({
    selector: 'app-navigation-plugin-host',
    templateUrl: './navigation-plugin-host.component.html',
    styleUrls: ['./navigation-plugin-host.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationPluginHostComponent extends TrackedComponent implements OnInit {
    @Input() public contextDataPlugins: MultiPlugin[] | undefined = [];
    @Input() public dataContext: any;

    @Input() public mainClass = '';
    @Input() public pluginsHostClassName = '';
    @Input() public pluginHostClassName = '';
    @Input() public maxWidth: number | undefined = undefined;
    @Input() public maxHeigth: number | undefined = undefined;
    @Input() public captureContextMenu = true;
    @Input() public supportStackedPlugins = true;
    @Input() public hideNavigationArrows = false;

    @Output() public pluginClick = new EventEmitter<PluginHostClickEvent>();
    /**
     * Emits **true** if the context menu for one of the plugin opens, emits **false** it closes.
     */
    @Output() public contextMenu = new EventEmitter<boolean>();
    @Output() public displayedPluginsChanged = new EventEmitter<PluginItem[]>();

    public readonly Icon = Icon;
    public readonly IconSize = IconSize;

    public displayedPlugins$: Observable<PluginItem[]>;
    public leftNavigationTitle = '';
    public rightNavigationTitle = '';

    private selectedIndex = 0;
    private displayedPluginsSubject$ = new BehaviorSubject<PluginItem[]>([]);

    constructor(trackingService: TrackingService, private changeDetectorRef: ChangeDetectorRef) {
        super(trackingService);
        this.displayedPlugins$ = this.displayedPluginsSubject$.asObservable();
    }

    ngOnInit() {
        if (this.contextDataPlugins) {
            this.setDisplayedPlugin(this.contextDataPlugins[this.selectedIndex]);
        }

        this.updateNavigationTitle();
    }

    public onNavigatePlugin(left: boolean, event: Event): void {
        if (event) {
            event.stopPropagation();
        }

        if (this.contextDataPlugins) {
            if (left) {
                this.selectedIndex -= 1;
                if (this.selectedIndex < 0) {
                    this.selectedIndex = this.contextDataPlugins.length - 1;
                }
            } else {
                this.selectedIndex += 1;
                if (this.selectedIndex >= this.contextDataPlugins.length) {
                    this.selectedIndex = 0;
                }
            }

            this.displayedPluginsSubject$.next([]);
            this.setDisplayedPlugin(this.contextDataPlugins[this.selectedIndex]);
        }

        this.updateNavigationTitle();
    }

    public canNavigate(): boolean {
        if (this.contextDataPlugins && this.contextDataPlugins.length > 1) {
            return true;
        }
        return false;
    }

    public onClicked(clickEvent: PluginHostClickEvent): void {
        this.pluginClick.emit(clickEvent);
    }

    public onContextMenu(shown: boolean): void {
        this.contextMenu.emit(shown);
    }

    private setDisplayedPlugin(multiPlugin: MultiPlugin) {
        const plugins = [multiPlugin.parentPlugin, ...multiPlugin.childPlugins];
        this.displayedPluginsSubject$.next(plugins);
        this.displayedPluginsChanged.emit(plugins);
    }

    private updateNavigationTitle() {
        if (this.contextDataPlugins?.length) {
            const leftIndex = this.selectedIndex - 1 < 0 ? this.contextDataPlugins.length - 1 : this.selectedIndex - 1;
            const rightIndex = this.selectedIndex + 1 >= this.contextDataPlugins.length ? 0 : this.selectedIndex + 1;

            const leftData = this.contextDataPlugins[leftIndex].parentPlugin.data as Content;
            const rightData = this.contextDataPlugins[rightIndex].parentPlugin?.data as Content;
            this.leftNavigationTitle = leftData?.title || '';
            this.rightNavigationTitle = rightData?.title || '';

            this.changeDetectorRef.markForCheck();
        }
    }
}
