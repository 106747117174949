import { Component, OnDestroy, OnInit } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { DoorEntityFields, IDoorEntity } from 'RestClient/Client/Interface/IDoorEntity';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { DoorEntity } from 'RestClient/Client/Model/AccessControl/DoorEntity';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { filterFieldChanged } from '@modules/shared/operators/filter-field-change';
import { notNullOrUndefined } from '@modules/shared/operators/not-null-or-undefined';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { WebAppClient } from 'WebClient/WebAppClient';
import { CustomIconState } from '@modules/shared/api/api';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { AccessControlContentTypes } from '../../../enumerations/access-control-content-types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, pluck, takeUntil } from 'rxjs/operators';

//
export const isContentSupported = (content: Content, pluginType: IGuid): boolean => {
    if (content?.type?.equals(AccessControlContentTypes.Door) && pluginType.equals(PluginTypes.Tile)) {
        // only display the door controls in tiles if we don't have anything else to display
        return !content.parameters?.getField<boolean>('HasAttachedData');
    }
    return false;
};

@Component({
    selector: 'app-door-state',
    templateUrl: './door-state.component.html',
    styleUrls: ['./door-state.component.scss'],
})
@InternalContentPluginDescriptor({
    type: DoorStateComponent,
    pluginTypes: [PluginTypes.Tile],
    exposure: { id: DoorStateComponent.pluginId, priority: 0 },
    isContentSupported,
    requirements: { licenses: [KnownLicenses.accessControl] },
})
export class DoorStateComponent extends TrackedComponent implements OnInit, OnDestroy, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('E5666486-A150-49A0-9934-2E50F2541FC4');
    public content?: Content;
    public dataContext: unknown;
    public doorIcon$: Observable<string>;
    public doorId$: Observable<IGuid>;
    public doorCustomIconId$: Observable<IGuid | null>;
    public doorCustomIconState$: Observable<CustomIconState>;
    private entityCacheTask!: IEntityCacheTask;

    private scClient: WebAppClient | null = null;
    private doorSubject = new BehaviorSubject<IDoorEntity | null>(null);
    private onDestroy = new Subject<void>();

    constructor(private securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService) {
        super(trackingService);

        this.scClient = securityCenterClientService?.scClient;
        this.entityCacheTask = this.scClient?.buildEntityCache([DoorEntityFields.doorOpenedField, EntityFields.customIconIdField]);

        const door$ = this.doorSubject.asObservable();
        const doorOpened$ = door$.pipe(filterFieldChanged('doorOpened'));
        this.doorIcon$ = doorOpened$.pipe(map((doorOpened) => (doorOpened ? Icon.DoorOpened : Icon.DoorClosed)));
        this.doorId$ = door$.pipe(notNullOrUndefined(), pluck('id'), distinctUntilChanged());
        this.doorCustomIconId$ = door$.pipe(filterFieldChanged('customIconId'));
        this.doorCustomIconState$ = doorOpened$.pipe(map((doorOpened) => (doorOpened ? CustomIconState.Active : CustomIconState.Normal)));
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();
        await this.initializeEntityCache();
    }

    public async ngOnDestroy(): Promise<void> {
        await this.entityCacheTask?.dispose();
        this.onDestroy.next();
        this.onDestroy.complete();
        super.ngOnDestroy();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    private async initializeEntityCache() {
        this.scClient = this.securityCenterClientService?.scClient;
        if (this.scClient && this.content) {
            const door = await this.entityCacheTask?.getEntityAsync<DoorEntity, IDoorEntity>(DoorEntity, SafeGuid.parse(this.content.source), true);
            this.doorSubject.next(door);
            if (door) {
                this.entityCacheTask
                    .detectEntityChange(door, 'doorOpened', 'isLocked', 'runningState', 'maintenanceModeActive')
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe((updatedDoor) => {
                        this.doorSubject.next(updatedDoor);
                    });
            }
        }
    }
}
