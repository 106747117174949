import { IMseRenderer } from './mse/MseRenderer';
import { IJpegRenderer } from './jpeg/JpegRenderer';
import { ILogger } from '../utils/logger';
import { Codec } from '../eventManager';
import { Resolution } from '../utils/Resolution';
import { ITileSizeObserver } from './TileSizeProvider';
import { ZoomConfiguration } from './ZoomConfiguration';
import { IRenderer } from './IRenderer';
import { PromiseCompletionSourceVoid } from '../utils/PromiseCompletionSource';
import { CancellationToken, Cancellation } from '../utils/CancellationToken';
import { FitResolution } from '../utils/FitResolution';
import { VideoWatermarkConfig } from '../utils/VideoWatermarkingConfig';

export class Tile {
  private readonly m_logger: ILogger;

  private readonly m_mseRenderer: IMseRenderer | null;

  private readonly m_jpegRenderer: IJpegRenderer;

  private readonly m_tileSizeObserver: ITileSizeObserver;

  private m_streamResolution = Resolution.None;

  private m_codec = Codec.None;

  private m_tileDimensionsChangePromise: PromiseCompletionSourceVoid;
  private readonly m_disposeToken: CancellationToken;

  private get activeRenderer(): IRenderer {
    return this.m_codec === Codec.H264 && this.m_mseRenderer !== null ? this.m_mseRenderer : this.m_jpegRenderer;
  }

  public get TileDimensionsChange(): Promise<void | Cancellation> {
    return Promise.race([this.m_tileDimensionsChangePromise.Promise, this.m_disposeToken.Cancellation]);
  }

  public get fitResolution(): FitResolution | null {
    if (this.activeRenderer.streamResolution.equals(Resolution.None)) {
      return null;
    }
    return new FitResolution(this.activeRenderer.streamResolution, this.activeRenderer.tileResolution);
  }

  constructor(logger: ILogger, mseRenderer: IMseRenderer | null, jpegRenderer: IJpegRenderer, tileSizeObserver: ITileSizeObserver) {
    this.m_logger = logger.subLogger('Tile');
    this.m_mseRenderer = mseRenderer;
    this.m_jpegRenderer = jpegRenderer;
    this.m_tileSizeObserver = tileSizeObserver;
    this.m_tileSizeObserver.tileResizeEvent.register(this.onTileResized);

    this.m_tileDimensionsChangePromise = new PromiseCompletionSourceVoid();
    this.m_disposeToken = new CancellationToken();
  }

  public dispose() {
    this.m_tileSizeObserver.tileResizeEvent.unregister(this.onTileResized);
    this.m_disposeToken.cancel('dispose');
  }

  public setCodec(codec: Codec) {
    if (codec !== this.m_codec) {
      this.m_logger.debug?.trace('Switching codec to', codec);
      this.m_codec = codec;
      this.m_mseRenderer?.setPlayerActive(codec === Codec.H264);
      this.m_jpegRenderer.setPlayerActive(codec === Codec.Mjpeg);
    }
    this.refreshTileSize();
  }

  public getSnapshot(fromZoomedImage: boolean): ImageData | null {
    return this.activeRenderer.getSnapshot(fromZoomedImage);
  }

  public applyDigitalZoomConfig(zoomConfig: ZoomConfiguration) {
    this.m_jpegRenderer.updateZoomConfig(zoomConfig);
    this.m_mseRenderer?.updateZoomConfig(zoomConfig);
  }

  public updateVideoWatermarkingConfig(videoWatermarkingConfig: VideoWatermarkConfig) {
    this.m_jpegRenderer.updateVideoWatermarkingConfig(videoWatermarkingConfig);
    this.m_mseRenderer?.updateVideoWatermarkingConfig(videoWatermarkingConfig);
  }

  private readonly onTileResized = () => {
    this.m_streamResolution = this.activeRenderer.streamResolution;
    const toResolve = this.m_tileDimensionsChangePromise;
    this.m_tileDimensionsChangePromise = new PromiseCompletionSourceVoid();
    toResolve.resolve();
  }

  private refreshTileSize(): void {
    if (this.m_streamResolution === Resolution.None) {
      this.m_logger.info?.trace(`Setting the tile resolution to ${this.activeRenderer.tileResolution.toString()}`);
      this.onTileResized();
    }
  }
}
