// Autogenerated: v2/help/Entities/ApiRole?codegen=ts&InterfaceOnly=False&workflow=True on 2021-08-30 10:02:01 AM
import { FieldObject } from '../../Helpers/FieldObject';
import { EntityManager } from './EntityManager';
import HttpStatusCode from '../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { ITransaction } from '../Interface/ITransaction';
import { IExecuteRequest, IRoleManager } from '../Interface/IRoleManager';

// #region Inner classes

export class ExecuteRequest extends FieldObject implements IExecuteRequest {
    // RoleId
    public get roleId(): IGuid | null {
        return this.getNullableFieldGuid('RoleId');
    }
    public set roleId(value: IGuid | null) {
        this.setNullableFieldGuid('RoleId', value);
    }
    // RoleType
    public get roleType(): IGuid | null {
        return this.getNullableFieldGuid('RoleType');
    }
    public set roleType(value: IGuid | null) {
        this.setNullableFieldGuid('RoleType', value);
    }
    // Method
    public get method(): string {
        return this.getField<string>('Method');
    }
    public set method(value: string) {
        this.setField<string>('Method', value);
    }
    // Url
    public get url(): string {
        return this.getField<string>('Url');
    }
    public set url(value: string) {
        this.setField<string>('Url', value);
    }
    // Content
    public get content(): string {
        return this.getField<string>('Content');
    }
    public set content(value: string) {
        this.setField<string>('Content', value);
    }
    // ContentType
    public get contentType(): string {
        return this.getField<string>('ContentType');
    }
    public set contentType(value: string) {
        this.setField<string>('ContentType', value);
    }
    // Headers
    public get headers(): Map<string, string> {
        return this.getFieldDictionary('Headers', undefined, 'string');
    }
    public set headers(value: Map<string, string>) {
        this.setFieldDictionary('Headers', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('RoleId', SafeGuid.EMPTY);
        this.setFieldGuid('RoleType', SafeGuid.EMPTY);
        this.setField<string>('Method', '');
        this.setField<string>('Url', '');
        this.setField<string>('Content', '');
        this.setField<string>('ContentType', '');
        this.setFieldDictionary('Headers', new Map<string, string>());
    }
}

// #endregion Inner classes

export class RoleManager extends EntityManager implements IRoleManager {
    // #region Constructor

    constructor() {
        super('v2/entities/Roles');
    }

    // #endregion Constructor

    public async executeApiRequestAsync(value: IExecuteRequest, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('executeapirequest', 'POST', value, transaction, responseHandler);
    }

}

