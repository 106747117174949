<ng-container *ngIf="isData(thumbnailData); else placeholder">
    <gen-popup #popup [targetSelector]="'#thumbnail-' + id" [position]="popupPosition">
        <gen-image [src]="formatImageData(thumbnailData)"></gen-image>
    </gen-popup>
    <gen-image
        class="thumbnail"
        id="thumbnail-{{ id }}"
        [flavor]="flavor"
        [fit]="fit"
        [src]="formatImageData(thumbnailData)"
        (mouseenter)="showPopupAsync(popup)"
        (mouseleave)="popup.close()"
        [style.border-radius]="thumbnailBorderRadius"
    ></gen-image>
</ng-container>
<ng-template #placeholder>
    <div class="placeholder-container">
        <gen-icon [size]="IconSize.Large" [icon]="getIcon(thumbnailData)" [style.opacity]="placeholderOpacity"></gen-icon>
    </div>
</ng-template>
