<div class="ha-center">
    <gen-container class="state-container bg-color-inverted ha-center p-1" [appTooltip]="stateTooltip">
        <!-- genText="{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}" -->
        <gen-spinner *ngIf="isLoading"></gen-spinner>
        <ng-container *ngIf="!isLoading">
            <div class="state" [ngClass]="{ 'm-1': !additionnalInfoRef?.innerHTML?.trim() }">
                <ng-content select="[primaryState]"></ng-content>
                <span class="ml-1 mr-1" *ngIf="secondaryStateRef?.innerHTML?.trim()">-</span>
                <div #secondaryStateRef>
                    <ng-content select="[secondaryState]"></ng-content>
                </div>
            </div>
            <div #additionnalInfoRef class="state">
                <ng-content select="[additionnalInfo]"></ng-content>
            </div>
        </ng-container>
    </gen-container>
    <div class="mt-1" [ngClass]="{ 'mb-1': displayContext === DisplayContext.MapPopup }">
        <app-command-buttons
            [commandsUsage]="commandsUsage"
            [excludedCommandIds]="excludedCommandIds"
            [maximumButtonCount]="maximumButtonCount"
            [areButtonsSpaced]="areButtonsSpaced"
            (isLoadingChange)="onAreCommandsLoadingChanged($event)"
        >
        </app-command-buttons>
    </div>
</div>
