// Autogenerated: v2/help/Classes/ApiAlarmAcknowledgedEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { AlarmEvent } from './AlarmEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { IAlarmAcknowledgedEvent } from '../Interface/IAlarmAcknowledgedEvent';

export class AlarmAcknowledgedEvent extends AlarmEvent implements IAlarmAcknowledgedEvent {
    // #region REST Properties

    // AckBy
    public get ackBy(): IGuid {
        return this.getFieldGuid('AckBy');
    }
    public set ackBy(value: IGuid) {
        this.setFieldGuid('AckBy', value);
    }

    // AckByDesc
    public get ackByDesc(): string {
        return this.getField<string>('AckByDesc');
    }
    public set ackByDesc(value: string) {
        this.setField<string>('AckByDesc', value);
    }

    // AckByType
    public get ackByType(): string {
        return this.getField<string>('AckByType');
    }
    public set ackByType(value: string) {
        this.setField<string>('AckByType', value);
    }

    // AckReason
    public get ackReason(): number {
        return this.getField<number>('AckReason');
    }
    public set ackReason(value: number) {
        this.setField<number>('AckReason', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('AckBy', SafeGuid.EMPTY);
        this.setField<string>('AckByDesc', '');
        this.setField<string>('AckByType', '');
        this.setField<number>('AckReason', 0);
    }

    // #endregion Methods

}

