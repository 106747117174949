import { RingBuffer } from './RingBuffer';

export interface IFrameRateProvider {
  getFrameRate(): FrameRate;
}

// Todo: make a simpler version without keyframe rate computing for mjpeg and dewarper
export class FrameRateComputer implements IFrameRateProvider {
  private static readonly SampleCount = 60;
  private readonly m_frameTimes = new RingBuffer<Date>(FrameRateComputer.SampleCount);
  private m_lastKeyFrame: Date = new Date(0);
  private m_theOneBeforeThat: Date = new Date(0);
  private m_currentPFrameCount: number = 0;
  private m_lastPFrameCount: number = 0;

  public addFrame(frame: 'k' | 'p') {
    const now = new Date();
    this.m_frameTimes.add(now);
    if (frame === 'k') {
      this.m_theOneBeforeThat = this.m_lastKeyFrame;
      this.m_lastKeyFrame = now;

      this.m_lastPFrameCount = this.m_currentPFrameCount;
      this.m_currentPFrameCount = 0;
    } else {
      this.m_currentPFrameCount++;
    }
  }

  public getFrameRate(): FrameRate {
    if (this.m_frameTimes.TotalItemAdded < 2) {
      return new FrameRate(0, 0, 0);
    }

    const [first, last] = this.m_frameTimes.firstAndLast();
    const elapsedSeconds = (last.getTime() - first.getTime()) / 1000;
    const frameCount = Math.min(this.m_frameTimes.TotalItemAdded, FrameRateComputer.SampleCount);

    const frameRate = elapsedSeconds !== 0 ? frameCount / elapsedSeconds : 0;
    return new FrameRate(frameRate, this.m_lastPFrameCount, (this.m_lastKeyFrame.getTime() - this.m_theOneBeforeThat.getTime()) / 1000);
  }
}

export class FrameRate {
  private readonly m_frameRate: number;
  private readonly m_pFrameCount: number;
  private readonly m_keyFrameIntervalInSeconds: number;

  public get frameRate(): number {
    return this.m_frameRate;
  }

  public get timeBetween2Frames(): number {
    return this.m_frameRate > 0 ?
      Math.min(1000 / this.m_frameRate, 1000) : //Protect against bad frame rate computation that could happens following a sequence jump
      0;
  }

  constructor(frameRate: number, pFrameCount: number, keyFrameIntervalInSeconds: number) {
    this.m_frameRate = frameRate;
    this.m_pFrameCount = pFrameCount;
    this.m_keyFrameIntervalInSeconds = keyFrameIntervalInSeconds;
  }

  public frameRateToString(): string {
    return `${this.m_frameRate.toFixed(1)} fps`;
  }

  public keyFrameRateToString(): string {
    const keyFrameIntervalInSeconds = this.m_keyFrameIntervalInSeconds < 1 ? this.m_keyFrameIntervalInSeconds.toFixed(3) : this.m_keyFrameIntervalInSeconds.toFixed(1);
    return `${keyFrameIntervalInSeconds} sec / ${this.m_pFrameCount + 1} frames`;
  }
}
