// Autogenerated: v2/help/Entities/ApiMacro?codegen=ts&InterfaceOnly=True&workflow=False on 2021-04-30 8:57:45 AM
import { IEntity, EntityFields } from './IEntity';
import { ITransaction } from './ITransaction';
import { IRestResponse } from './IRestResponse';

// #region Fields

export class MacroEntityFields extends EntityFields {
    public static codeField = 'Code';
    public static executionIntegrityField = 'ExecutionIntegrity';
    public static languageField = 'Language';
    public static loadingStrategyField = 'LoadingStrategy';
    public static mustBeSingleInstanceField = 'MustBeSingleInstance';
}

// #endregion Fields

export interface IMacroEntity extends IEntity {
    // #region REST Properties

    code: string;

    executionIntegrity: string;

    readonly language: string;

    loadingStrategy: string;

    mustBeSingleInstance: boolean;

    // #endregion REST Properties

    // #region REST Methods

    executeAsync(transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

