import { IPtzControlSession, IConnectionEvent, ISession, WsSessionV2, WsSessionV2Builder } from './WsSessionV2';
import { ISessionStateView, SessionState } from './SessionState';
import { PlaySpeed } from '../PlaySpeed';
import { IWebSocketRequest, IWebSocketResponse, StreamUsage, UpdateStreamRequest } from './Messages';
import { ILogger } from '../../utils/logger';
import { LiteEvent, ILiteEvent } from '../../utils/liteEvents';
import { UpdateStreamRequestBuilder, IBuildUpdateStreamRequestResult } from './UpdateStreamRequestBuilder';
import { DesiredState } from './DesiredState';
import { DefaultStreamUsage } from './DefaultStreamUsage';
import { PlayerMode } from '../../enums';
import { TimeLineRange } from '../../TimeLineRange';
import { SessionCreationParameters, SessionParameters } from './SessionParameters';
import { IStreamUsageSelector, IStreamUsageSelectorBuilder } from '../StreamUsageSelector';
import { Utils } from '../../utils/Utils';
import { PromiseCompletionSource, rejectedPromise } from '../../utils/PromiseCompletionSource';

export interface IPtzControlSessionProvider {
  readonly ptzControlSession: IPtzControlSession;
}

export interface IStatefulSession extends IConnectionEvent, IPtzControlSessionProvider {
  readonly sessionStateView: ISessionStateView;
  readonly sessionParameters: SessionParameters;

  SessionError: ILiteEvent<string>;

  setLastFrameRendered(lastFrameRendered: Date): void;

  dispose(): Promise<void>;
  playLive(): boolean;
  pause(): boolean;
  resume(): boolean;
  seek(seekTime: Date): boolean;
  setPlaySpeed(playSpeed: PlaySpeed): boolean;
  setAudioEnabled(audioEnabled: boolean): boolean;
  setTimelineRange(timelineRange: TimeLineRange): void;
  setPtzMode(ptzModeEnabled: boolean): boolean;

  restartSession(sessionCreationParameters: SessionCreationParameters): void;

  debugStatus(indent: number): string;
}

/*
 * When doing changes to the State management, try to keep in mind the following scenarios
 * Live -> Pause -> Live (returns to live)
 * Live -> Pause -> Resume (start playback at the time we did pause).
 * Live -> Reverse speed (starts playback in reverse at last rendered frame, time of play request if no frame was rendered yet)
 * Seeking while paused (ptz mode or not)
 * Disconnection -> Reconnection -> Reapply the state of our session.
 * - Restore ptz mode, audio, speed, stream usage, timeline, pause
 * - If in playback seek back to last frame rendered, time of seek if no frame was rendered, time playLive was requested if no seek was done (playlive, pause, lose connection)
 */

export class StatefulSession implements IStatefulSession {
  private static readonly DisconnectedSentinel = rejectedPromise<IWebSocketResponse>('Disconnected sentinel should never be awaited');

  private readonly m_logger: ILogger;

  private m_isDisposed: boolean = false;

  private m_session: ISession;

  private m_renewTokenRequest: IWebSocketRequest | null = null;

  private readonly m_sessionBuilder: WsSessionV2Builder;

  private readonly m_streamUsageSelectorBuilder: IStreamUsageSelectorBuilder;

  private m_streamUsageSelector: IStreamUsageSelector;

  private readonly m_defaultStreamUsage: DefaultStreamUsage;

  private readonly m_serverState: SessionState;

  private readonly m_connectionReestablishedEvent = new LiteEvent<void>();

  private readonly m_connectionLostEvent = new LiteEvent<void>();

  private readonly m_sessionErrorEvent = new LiteEvent<string>();

  private m_updateStreamRequestBuilder: UpdateStreamRequestBuilder;

  private m_desiredState: DesiredState;

  private m_queryInFlight?: Promise<IWebSocketResponse>;

  private m_queryInFlightItself?: IWebSocketRequest;

  public get sessionStateView(): ISessionStateView {
    return this.m_serverState;
  }

  public get sessionParameters(): SessionParameters {
    return this.m_session.sessionParameters;
  }

  public get ConnectionReestablished() {
    return this.m_connectionReestablishedEvent.expose();
  }

  public get ConnectionLost() {
    return this.m_connectionLostEvent.expose();
  }

  public get SessionError(): ILiteEvent<string> {
    return this.m_sessionErrorEvent.expose();
  }

  private constructor(logger: ILogger, sessionBuilder: WsSessionV2Builder, session: ISession, streamUsageSelectorBuilder: IStreamUsageSelectorBuilder) {
    this.m_logger = logger.subLogger('StatefulSession');
    this.m_logger.debug?.trace(`Session Established with server version ${session.sessionParameters.serverVersion}`);
    this.m_session = session;
    this.m_sessionBuilder = sessionBuilder;
    this.m_streamUsageSelectorBuilder = streamUsageSelectorBuilder;
    this.m_defaultStreamUsage = session.sessionParameters.defaultStreamUsage;
    this.m_updateStreamRequestBuilder = new UpdateStreamRequestBuilder(logger, session.sessionParameters.channel, session.sessionParameters.transcodingAllowance, session.sessionParameters.supportedVideoFormats);
    this.m_desiredState = DesiredState.Empty;
    this.m_serverState = new SessionState(this.m_defaultStreamUsage.getStreamUsage(undefined));
    this.m_session.ConnectionReestablished.register(this.onConnectionReestablished);
    this.m_session.ConnectionLost.register(this.onConnectionLost);
    this.m_streamUsageSelector = this.m_streamUsageSelectorBuilder.build(this.m_defaultStreamUsage);
    this.m_streamUsageSelector.onRequiresStreamUsageUpdate.register(this.onRequiresStreamUsageUpdate);

    this.onRequiresStreamUsageUpdate(this.m_streamUsageSelector.streamUsage);
    this.tokenRenewalLoop();
  }

  public static async build(logger: ILogger, sessionBuilder: WsSessionV2Builder, sessionParameters: SessionCreationParameters, streamUsageSelectorBuilder: IStreamUsageSelectorBuilder): Promise<StatefulSession> {
    const wsSessionV2: WsSessionV2 = await sessionBuilder.build(sessionParameters);
    return new StatefulSession(logger, sessionBuilder, wsSessionV2, streamUsageSelectorBuilder);
  }

  public async dispose(): Promise<void> {
    if (this.m_isDisposed) {
      return;
    }
    this.m_isDisposed = true;

    this.m_renewTokenRequest = null;

    this.m_session.ConnectionReestablished.unregister(this.onConnectionReestablished);
    this.m_session.ConnectionLost.unregister(this.onConnectionLost);
    this.m_streamUsageSelector.onRequiresStreamUsageUpdate.unregister(this.onRequiresStreamUsageUpdate);

    // When query in flight finishes, it can automatically queue an other one. Wait until this possible loop is done.
    while (this.m_queryInFlight !== undefined && this.m_queryInFlight !== StatefulSession.DisconnectedSentinel) {
      this.m_logger.debug?.trace(`Waiting on ${this.m_queryInFlightItself?.debugStatus(0)}`);
      await this.m_queryInFlight;
    }

    this.m_queryInFlight = StatefulSession.DisconnectedSentinel; // Prevents other query to be tried

    await this.m_session.dispose();
    this.m_session.sessionParameters.tokenRenewer.dispose();
    this.m_logger.debug?.trace('session disposed');
  }

  public setLastFrameRendered(lastFrameRendered: Date) {
    this.m_serverState.updateRenderedFrameTime(lastFrameRendered);
  }

  public setPtzMode(isEnabled: boolean): boolean {
    return this.applyNewDesiredState(this.m_desiredState.withPtzMode(isEnabled));
  }

  public playLive(): boolean {
    return this.applyNewDesiredState(this.m_desiredState.withPlayLive());
  }

  public pause(): boolean {
    return this.applyNewDesiredState(this.m_desiredState.withPause());
  }

  public resume(): boolean {
    return this.applyNewDesiredState(this.m_desiredState.withResume());
  }

  public seek(seekTime: Date): boolean {
    return this.applyNewDesiredState(this.m_desiredState.withSeek(seekTime));
  }

  public setPlaySpeed(playSpeed: PlaySpeed): boolean {
    // Management of Live -> reverse speed
    if (playSpeed.IsReverse &&
      this.m_serverState.playerMode === PlayerMode.live || this.m_desiredState.playerMode === PlayerMode.live) {
      this.m_desiredState = this.m_desiredState.withSeek(this.m_serverState.getLiveToReverseSeekTime());
    }

    return this.applyNewDesiredState(this.m_desiredState.withPlaySpeed(playSpeed));
  }

  public setAudioEnabled(audioEnabled: boolean): boolean {
    return this.applyNewDesiredState(this.m_desiredState.withAudioEnabled(audioEnabled));
  }

  public setTimelineRange(timelineRange: TimeLineRange): boolean {
    return this.applyNewDesiredState(this.m_desiredState.withTimelineRange(timelineRange));
  }

  public get ptzControlSession(): IPtzControlSession {
    return this.m_session.ptzControlSession;
  }

  public async restartSession(sessionCreationParameters: SessionCreationParameters): Promise<void> {
    // When query in flight finishes, it can automatically queue an other one. Wait until this possible loop is done.
    while (this.m_queryInFlight !== undefined) {
      if (this.m_queryInFlight === StatefulSession.DisconnectedSentinel) {
        return;
      }
      await this.m_queryInFlight;
    }

    const sessionRestarted = new PromiseCompletionSource<IWebSocketResponse>();
    this.m_queryInFlight = sessionRestarted.Promise;

    this.m_session.ConnectionReestablished.unregister(this.onConnectionReestablished);
    this.m_session.ConnectionLost.unregister(this.onConnectionLost);
    await this.m_session.dispose();

    this.m_session = await this.m_sessionBuilder.build(sessionCreationParameters);
    this.m_session.ConnectionReestablished.register(this.onConnectionReestablished);
    this.m_session.ConnectionLost.register(this.onConnectionLost);
    this.replaceStreamUsageSelector();
    this.onRequiresStreamUsageUpdate(this.m_streamUsageSelector.streamUsage);
    this.m_updateStreamRequestBuilder = new UpdateStreamRequestBuilder(this.m_logger, this.m_session.sessionParameters.channel, this.m_session.sessionParameters.transcodingAllowance, this.m_session.sessionParameters.supportedVideoFormats);

    sessionRestarted.resolve(new FakeWebSocketResponse(this.m_session.sessionParameters.channel));

    this.onConnectionReestablished();
  }

  private replaceStreamUsageSelector(): void {
    this.m_streamUsageSelector.onRequiresStreamUsageUpdate.unregister(this.onRequiresStreamUsageUpdate);
    this.m_streamUsageSelector.dispose();

    this.m_streamUsageSelector = this.m_streamUsageSelectorBuilder.build(this.m_session.sessionParameters.defaultStreamUsage);
    this.m_streamUsageSelector.onRequiresStreamUsageUpdate.register(this.onRequiresStreamUsageUpdate);
  }

  private applyNewDesiredState(newDesiredState: DesiredState | undefined): boolean {
    if (newDesiredState === undefined) return false;
    this.m_serverState.validateNewState(newDesiredState);
    this.m_desiredState = newDesiredState;
    this.applyRequests();
    return true;
  }

  private applyRequests(fromSendCompletion: boolean = false): void {
    if (this.m_queryInFlight !== undefined) {
      this.m_logger.debug?.trace('query in flight. waiting on it to finish before sending the state change request.');
      return;
    }

    const result: IBuildUpdateStreamRequestResult = this.m_updateStreamRequestBuilder.build(this.m_serverState, this.m_desiredState);
    const updateStreamRequest = result.updateStreamRequest ?? this.processPendingRenewTokenRequest();
    this.m_desiredState = result.desiredState;
    if (updateStreamRequest === null) {
      if (!fromSendCompletion) {
        this.m_logger.debug?.trace('Current state represent the requested state. Nothing to do.');
      }
      return;
    }

    this.m_logger.debug?.trace('Sending ', updateStreamRequest);
    this.m_logger.debug?.trace('ServerState is now considered ', this.m_serverState);
    this.m_queryInFlightItself = updateStreamRequest;
    this.m_queryInFlight = this.m_session.sendCommand(updateStreamRequest);
    this.m_queryInFlight.then(() => {
      this.m_queryInFlightItself = undefined;
      this.m_queryInFlight = undefined;
      this.applyRequests(true);
    }).catch((e) => {
      this.m_logger.warn?.trace('Request to update stream failed.\nRequested state:', updateStreamRequest?.debugStatus(0), '\nError message:', e);
      this.m_queryInFlightItself = undefined;
      this.m_queryInFlight = undefined;
      if (updateStreamRequest !== null) {
        this.m_sessionErrorEvent.trigger(e);
      }
    });
  }

  private processPendingRenewTokenRequest(): IWebSocketRequest | null {
    const renewTokenRequest = this.m_renewTokenRequest;
    this.m_renewTokenRequest = null;
    return renewTokenRequest;
  }

  private readonly onConnectionReestablished = () => {
    this.m_logger.info?.trace('Connection restored');
    this.replaceStreamUsageSelector();
    this.m_serverState.update(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.m_streamUsageSelector.streamUsage);
    const restoreUpdateStreamRequest = this.m_updateStreamRequestBuilder.buildRestoreState(this.m_serverState);
    // if connection was lost before we had the chance to do anything, there might not be anything to send to the server.
    if (restoreUpdateStreamRequest !== null) {
      this.m_queryInFlightItself = restoreUpdateStreamRequest;
      this.m_queryInFlight = this.m_session.sendCommand(restoreUpdateStreamRequest);
      this.m_queryInFlight
        .then((_) => {
          this.m_queryInFlightItself = undefined;
          this.m_queryInFlight = undefined;
          this.applyRequests(true);
          this.m_connectionReestablishedEvent.trigger();
        })
        .catch((e) => {
          this.m_queryInFlightItself = undefined;
          this.m_queryInFlight = undefined;
          this.m_logger.error?.trace(`Failed to apply state ${e}`);
          this.m_sessionErrorEvent.trigger(e);
        });
    } else {
      this.m_connectionReestablishedEvent.trigger();
    }
  }

  private readonly onConnectionLost = () => {
    this.m_queryInFlight = StatefulSession.DisconnectedSentinel;
    this.m_connectionLostEvent.trigger();
  }

  private readonly onRequiresStreamUsageUpdate = (streamUsage: StreamUsage): boolean => {
    return this.applyNewDesiredState(this.m_desiredState.withStreamUsage(streamUsage));
  }

  private async tokenRenewalLoop() {
    while (!this.m_isDisposed) {
      const newToken = await this.m_session.sessionParameters.tokenRenewer.RenewalPromise; //Even if we restart a new session with different sessionParameters, the tokenRenewer is recycled so this await should complete
      if (newToken === null || this.m_isDisposed) {
        this.m_logger.debug?.trace('Token null or disposed. Stopping token renewal loop.');
        return;
      }

      this.m_renewTokenRequest = UpdateStreamRequest.buildTokenUpdate(this.m_session.sessionParameters.channel, newToken);
      this.applyRequests();
    }
  }

  public debugStatus(indent: number): string {
    return 'StatefulSession' + Utils.indentNewLine(indent) +
      this.m_desiredState.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      this.m_serverState.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      'Request in flight: ' + (this.m_queryInFlight !== undefined ? 'yes' : 'no') + Utils.indentNewLine(indent + (this.m_queryInFlight !== undefined ? Utils.Indentation : 0)) +
      (this.m_queryInFlightItself !== undefined ? (this.m_queryInFlightItself.debugStatus(indent + Utils.Indentation + Utils.Indentation) + Utils.indentNewLine(indent)) : '') +
      'Default stream usage: ' + this.m_defaultStreamUsage.StreamUsage + Utils.indentNewLine(indent) +
      (this.m_renewTokenRequest !== null ? ('Renew token request pending' + Utils.indentNewLine(indent)) : '') +
      this.m_session.debugStatus(indent + Utils.Indentation);
  }
}


class FakeWebSocketResponse implements IWebSocketResponse {
  public readonly channel: number;

  public constructor(channel: number) {
    this.channel = channel;
  }
}
