import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Color, Gelato } from '@genetec/gelato-angular';
import moment from 'moment';
import { debounce } from 'lodash-es';
import { TimeService } from '@modules/shared/services/time/time.service';
import { stringFormat } from '@modules/shared/utilities/StringFormat';
import { TranslateService } from '@ngx-translate/core';
import { CoreRowResult } from '@modules/correlation/interfaces/row-result';
import { Moment } from 'moment-timezone';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

export interface HistogramBin {
    center: moment.Moment;
    widthMs: number;
    count: number;
}

@Component({
    selector: 'app-investigate-results-event-timeline',
    templateUrl: './event-timeline.component.html',
    styleUrls: ['./event-timeline.component.scss'],
    providers: [],
})
export class InvestigateResultsEventTimelineComponent extends Gelato {
    // #region Constants

    private static readonly minLength = 30000; // 30 s
    private static readonly maxLength = 86400000 * 365; // 1 day * 365 = 1 yr
    private static readonly tickIntervals: { ms: number; ticks: number }[] = [
        { ms: -1, ticks: 0 }, // invalid
        { ms: 1, ticks: 5 }, // 5 ms
        { ms: 10, ticks: 5 }, // 50 ms
        { ms: 1000, ticks: 5 }, // 5 s
        { ms: 5000, ticks: 6 }, // 30 s
        { ms: 10000, ticks: 6 }, // 1 min
        { ms: 30000, ticks: 4 }, // 2 min
        { ms: 60000, ticks: 5 }, // 5 min
        { ms: 120000, ticks: 5 }, // 10 min
        { ms: 300000, ticks: 6 }, // 30 min
        { ms: 600000, ticks: 6 }, // 1 hr
        { ms: 1800000, ticks: 4 }, // 2 hr
        { ms: 3600000, ticks: 6 }, // 6 hr
        { ms: 7200000, ticks: 6 }, // 12 hr
        { ms: 14400000, ticks: 6 }, // 1 d
        { ms: 28800000, ticks: 6 }, // 2 d
        { ms: 86400000, ticks: 7 }, // 1 w
        { ms: 483800000, ticks: 8 }, // 4 w
        { ms: 3154000000, ticks: 6 }, // 6 mo
        { ms: 6308000000, ticks: 6 }, // 1 y
    ];

    // #endregion

    //#region Fields

    /* ViewChild, Input, and Output bindings */
    @ViewChild('maindiv') public maindiv!: ElementRef<HTMLDivElement>;
    @ViewChild('canvas') public canvas!: ElementRef<HTMLCanvasElement>;
    @ViewChild('ticks') public ticks!: ElementRef<HTMLCanvasElement>;

    @Input()
    public get results(): CoreRowResult[] {
        return this.privateResults;
    }
    public set results(value: CoreRowResult[]) {
        if (value) {
            this.privateResults = InvestigateResultsEventTimelineComponent.sortByTimeDesc(value);
            if (this.privateResults.length >= 2) {
                const min = this.privateResults[0];
                const max = this.privateResults[this.privateResults.length - 1];
                const range = max.mmtTime.clone().diff(min.mmtTime.clone()); // in ms
                // add +/- 5% of the time range to the tails, so the bins are inside the span
                this.dataStartTime = min.mmtTime.clone().subtract(range * 0.05, 'ms') as unknown as moment.Moment;
                this.dataEndTime = max.mmtTime.clone().add(range * 0.05, 'ms') as unknown as moment.Moment;
                this.privateTime = {
                    start: this.dataStartTime.clone(),
                    end: this.dataEndTime.clone(),
                };
            } else if (this.privateResults.length === 1) {
                // +/- 1 minute around "the only element"
                this.dataStartTime = this.privateResults[0].mmtTime.clone().subtract(1, 'minute') as unknown as moment.Moment;
                this.dataEndTime = this.privateResults[0].mmtTime.clone().add(1, 'minute') as unknown as moment.Moment;
                this.privateTime = {
                    start: this.dataStartTime.clone(),
                    end: this.dataStartTime.clone(),
                };
            } else {
                // +/- 1 minute around "now"
                const time = moment().clone();
                this.dataStartTime = time.clone().subtract(1, 'minute');
                this.dataEndTime = time.clone().add(1, 'minute');
                this.privateTime = {
                    start: this.dataStartTime.clone(),
                    end: this.dataStartTime.clone(),
                };
            }

            this.draw();
            setTimeout(() => this.debouncedDraw(), 51);
        }
    }

    @Output()
    public timeRangeChanged = new EventEmitter<[moment.Moment, moment.Moment]>();

    @Output()
    public query = new EventEmitter<[moment.Moment, moment.Moment]>();

    @Output()
    public binSelected = new EventEmitter<[moment.Moment, moment.Moment] | null>();

    /* Public Fields */
    public get time(): { start: moment.Moment; end: moment.Moment } {
        return this.privateTime;
    }
    public set time(newRange: { start: moment.Moment; end: moment.Moment }) {
        this.privateTime = {
            start: newRange.start.clone(),
            end: newRange.end.clone(),
        };
        this.debouncedChanges();
    }
    public tooltips: string[] = [];
    public captureMouse = -1;
    public isPanning = false;
    public cursorX = 0;
    public currentBin: HistogramBin | null = null;
    public get currentBinCount(): string {
        return stringFormat(this.translateService.instant('STE_LABEL_RESULTCOUNT') as string, this.currentBin?.count?.toString() ?? '');
    }

    /* Private Fields */
    private debouncedDraw = debounce(() => {
        this.draw();
    }, 50);
    private debouncedChanges = debounce(() => {
        const low = this.privateTime.start.clone();
        const high = this.privateTime.end.clone();
        this.timeRangeChanged.emit([low, high]);
    }, 100);
    private currentBins: HistogramBin[] = [];
    private mouseDownPosition?: number = undefined;
    private zoomFactor = 2;
    private initialMovingTime?: moment.Moment;
    private currentHeight = 0;
    private currentWidth = 0;
    private privateResults: CoreRowResult[] = [];
    private dataStartTime!: moment.Moment;
    private dataEndTime!: moment.Moment;
    private privateTime!: { start: moment.Moment; end: moment.Moment };
    private currentTickInterval!: { ms: number; ticks: number };
    private selectedBin: HistogramBin | null = null;

    //#endregion

    //#region Constructors

    constructor(private timeService: TimeService, private translateService: TranslateService) {
        super();
    }

    //#endregion

    // #region Drawing

    /**
     * Draws a rounded rectangle using the current state of the canvas.
     * If you omit the last three params, it will draw a rectangle
     * outline with a 5 pixel border radius
     */
    private static roundRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        o_radius: number | { tl: number; tr: number; br: number; bl: number },
        fill: boolean,
        o_stroke?: boolean
    ) {
        let stroke = o_stroke;
        let radius = o_radius;
        if (typeof stroke === 'undefined') {
            stroke = true;
        }
        if (typeof radius === 'undefined') {
            radius = 5;
        }
        if (typeof radius === 'number') {
            radius = { tl: radius, tr: radius, br: radius, bl: radius };
        } else {
            radius.tl = radius.tl || 0;
            radius.tr = radius.tr || 0;
            radius.br = radius.br || 0;
            radius.bl = radius.bl || 0;
        }
        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
            ctx.fill();
        }
        if (stroke) {
            ctx.stroke();
        }
    }

    private static filterTime(values: CoreRowResult[], start: moment.Moment, end: moment.Moment): CoreRowResult[] {
        const s = start.toDate().getTime();
        const e = end.toDate().getTime();

        return values.filter((item) => {
            const time = item.mmtTime.toDate().getTime();
            return time >= s && time < e;
        });
    }

    private static hist(values: CoreRowResult[], nbins: number, startDate: moment.Moment, endDate: moment.Moment): HistogramBin[] {
        const bins: HistogramBin[] = new Array<HistogramBin>(nbins);

        const duration = Math.abs(endDate.diff(startDate));
        const binWidth = duration / nbins;
        const halfWidth = binWidth / 2.0;
        for (let i = 0; i < nbins; i++) {
            let center = startDate.clone().add(halfWidth, 'ms');
            if (i > 0) {
                center = center.clone().add(binWidth * i, 'ms');
            }

            // left edge
            const low = center.clone().subtract(halfWidth, 'ms');
            // right edge
            const high = center.clone().add(halfWidth, 'ms');
            // count the points between the edges
            const count = this.filterTime(values, low, high).length;
            bins[i] = {
                center,
                widthMs: binWidth,
                count,
            };
        }

        return bins;
    }

    private static sortByTimeDesc(value: CoreRowResult[]): CoreRowResult[] {
        // Apply the sorting
        if (!value) return value;

        return value.sort((a, b) => {
            if (a.mmtTime && b.mmtTime) {
                return a.mmtTime.toDate().getTime() - b.mmtTime.toDate().getTime();
            } else return -1;
        });
    }

    public draw(): void {
        const canvas = this.canvas.nativeElement;
        if (!canvas) {
            return;
        }

        // ensure we have a valid length
        const length = this.time.end.diff(this.time.start);
        if (length <= 0) {
            this.time.end = this.time.end.clone().add(1, 'minute');
            this.time.start = this.time.start.clone().add(1, 'minute');
        }

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        this.currentWidth = ctx.canvas.scrollWidth;
        this.currentHeight = ctx.canvas.scrollHeight;
        canvas.width = this.currentWidth;

        // apply a different style depending on mouse over
        canvas.height = 50;

        // draw background
        ctx.fillStyle = 'black';
        ctx.globalAlpha = 0.5;
        ctx.fillRect(0, 0, this.currentWidth, this.currentHeight);
        ctx.globalAlpha = 1;

        // draw sequence events first
        // unselected: #007CAA
        ctx.fillStyle = Color.Puffo;
        this.drawEvents(ctx, InvestigateResultsEventTimelineComponent.filterTime(this.privateResults, this.time.start, this.time.end), false);

        this.drawTicks(length);

        // draw the region which the data covers
        ctx.fillStyle = '#C0C0C0'; // Silver
        ctx.globalAlpha = 0.2;

        const loopStartX =
            this.time.start.toDate().getTime() >= this.dataStartTime.toDate().getTime() ? 0 : this.getPercentageFromTime(this.dataStartTime.clone()) * this.currentWidth;
        const loopEndX =
            this.time.end.toDate().getTime() <= this.dataEndTime.toDate().getTime() ? this.currentWidth : this.getPercentageFromTime(this.dataEndTime.clone()) * this.currentWidth;
        if (loopEndX > loopStartX) {
            ctx.fillRect(loopStartX, 0, loopEndX, this.currentHeight);
        }
    }

    public drawEvents(ctx: CanvasRenderingContext2D, events: CoreRowResult[], isPonctual: boolean): void {
        const histogram = InvestigateResultsEventTimelineComponent.hist(events, 50, this.time.start, this.time.end);
        if (histogram == null) return;

        // we only care about the non-zero bins
        this.currentBins = histogram.filter((bin) => bin.count > 0);

        // there are no bins to render, just exit
        if (this.currentBins.length === 0) {
            return;
        }

        const selected = this.selectedBin?.center?.toDate()?.getTime();

        const maxCount = Math.max.apply(
            null,
            histogram.map((item) => item.count)
        );
        for (const element of histogram) {
            // don't draw 0-elements
            if (element.count <= 0) continue;

            const fractionalDraw = element.count / maxCount;
            let posStartPercent = this.getPercentageFromTime(
                element.center
                    .clone()
                    .subtract(element.widthMs / 2.0, 'ms')
                    .clone()
            );
            const posEndPercent = this.getPercentageFromTime(
                element.center
                    .clone()
                    .add(element.widthMs / 2.0, 'ms')
                    .clone()
            );

            // ensure events starting before start time are drawn from x=0
            if (posStartPercent < 0) {
                if (isPonctual) {
                    continue;
                } else {
                    posStartPercent = 0;
                }
            }

            const histPadding = 3; // pixels
            const startX = posStartPercent * this.currentWidth + histPadding;
            let stopX = posEndPercent * this.currentWidth - histPadding;

            // ensure events ending after end time stop at the end of the timeline
            if (stopX > this.currentWidth) {
                stopX = this.currentWidth;
            }

            /*
            // if we need to apply transparency
            let alpha = 1.0;
            ctx.globalAlpha = alpha;
            */

            // only draw if it's in the timeline boundaries
            if (startX >= 0 && startX <= this.currentWidth && stopX >= 0 && stopX <= this.currentWidth) {
                const y = this.currentHeight - this.currentHeight * fractionalDraw;
                if (selected && element.center.toDate().getTime() !== selected) {
                    // fade "unselected" bins
                    ctx.globalAlpha = 0.4;
                } else {
                    ctx.globalAlpha = 1.0;
                }

                InvestigateResultsEventTimelineComponent.roundRect(
                    ctx,
                    startX,
                    y,
                    stopX - startX,
                    this.currentHeight * fractionalDraw,
                    { tl: 5, tr: 5, bl: 0, br: 0 },
                    true,
                    false
                );
            }
        }
        // restore the alpha
        ctx.globalAlpha = 1.0;
    }

    public drawTicks(length: number): void {
        const ticks = this.ticks.nativeElement;
        if (!ticks) {
            return;
        }

        const ticskHeight = 14;
        const ctx = ticks.getContext('2d') as CanvasRenderingContext2D;
        ticks.width = this.currentWidth;
        ticks.height = ticskHeight;

        this.updateTickSpacing();

        // draw ticks
        const ms = this.currentTickInterval.ms;
        const majorTicks = this.currentTickInterval.ticks;
        // const showMilliseconds = ms < 200;
        const showSeconds = ms < 10000;
        const showMinutes = ms < 600000;
        const showHours = ms < 14400000;
        const showDays = ms >= 14400000;
        const is24HourFormat = this.timeService.is24HourFormat;

        let timeFormat = 'h a';
        if (showDays) {
            timeFormat = 'DDMMM';
        } else if (showHours && !showMinutes && !showSeconds) {
            timeFormat = is24HourFormat ? (timeFormat = 'HH') : (timeFormat = 'h a');
        } else if (showHours && showMinutes && !showSeconds) {
            timeFormat = is24HourFormat ? (timeFormat = 'HH:mm') : (timeFormat = 'h:mm a');
        } else if (showHours && showMinutes && showSeconds) {
            timeFormat = is24HourFormat ? (timeFormat = 'HH:mm:ss') : (timeFormat = 'h:mm:ss');
        }

        const tickCount = length / ms;

        // draw every tick
        ctx.beginPath();
        ctx.lineWidth = 0.5;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.strokeStyle = '#000000';
        ctx.fillStyle = 'white';
        ctx.font = '11px Arial';
        ctx.textAlign = 'left';

        let currentTimestamp = this.time.start.clone();
        for (let i = 0; i <= tickCount; ++i) {
            currentTimestamp.add(ms, 'ms');
            const percentX = this.getPercentageFromTime(currentTimestamp);
            const x = Math.floor(percentX * this.currentWidth) + 0.5;
            let y = ticskHeight / 2;
            let lineHeight = ticskHeight / 2;

            // detect major ticks
            const isMajorTick = majorTicks !== 0 && i % majorTicks === 0;
            if (isMajorTick) {
                y = 0;
                lineHeight = ticskHeight;
            }

            ctx.moveTo(x, y);
            ctx.lineTo(x, y + lineHeight);
            ctx.stroke();
        }

        // draw start time
        const startTimeText = this.time.start.clone().format('ddd D');
        ctx.fillText(startTimeText, 2, 11);
        // const startTextWidth = ctx.measureText(startTimeText);

        // draw total time
        ctx.textAlign = 'right';
        const totalMs = this.time.end.clone().diff(this.time.start);
        const totalTimeText = '(' + this.timeService.formatDuration(totalMs) + ')';
        ctx.fillText(totalTimeText, this.currentWidth - 3, 11);
        const totalTextWidth = ctx.measureText(totalTimeText);
        const totalTextX = this.currentWidth - 3 - totalTextWidth.width;

        // draw timestamps
        currentTimestamp = this.time.start.clone();
        for (let i = 1; i <= tickCount - 1; ++i) {
            currentTimestamp.add(ms, 'ms');
            const percentX = this.getPercentageFromTime(currentTimestamp);
            const x = Math.floor(percentX * this.currentWidth) + 0.5;

            // detect major ticks
            const isMajorTick = majorTicks !== 0 && i % majorTicks === 0;
            // skip the first time because we will dispaly date
            if (isMajorTick) {
                const text = currentTimestamp.clone().format(timeFormat);
                const textWidth = ctx.measureText(text).width;
                const textX = x + 3;
                // ensure the text doesn't overlap
                if (textX + textWidth < totalTextX + 6) {
                    ctx.fillText(text, textX, 11);
                }
            }
        }
    }

    // #endregion

    //#region Events

    public onResize(event: UIEvent): void {
        this.debouncedDraw();
    }

    public onMouseUp(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    public onPointerDown(event: PointerEvent): void {
        // note the mouse down position because we are going to determine the action to perfrom on mouse move
        this.mouseDownPosition = event.offsetX;

        if (this.currentBin && this.currentBin.center.toDate().getTime() !== this.selectedBin?.center?.toDate()?.getTime()) {
            const center = this.currentBin.center;
            const left = center.clone().subtract(this.currentBin.widthMs / 2, 'ms');
            const right = center.clone().add(this.currentBin.widthMs / 2, 'ms');
            this.selectedBin = this.currentBin;
            this.binSelected.emit([left, right]);
            this.draw();
        } else {
            this.binSelected.emit(null);
            this.selectedBin = null;
            this.draw();
        }
    }

    public onPointerUp(event: PointerEvent): void {
        let handled = false;

        if (this.isPanning) {
            // if we were pannig, cancel it
            this.isPanning = false;
            this.initialMovingTime = undefined;
            handled = true;
        }

        // if the click was handled, force a redraw
        if (handled) {
            this.draw();

            event.preventDefault();
            event.stopPropagation();
        }

        this.captureMouse = -1; // release mouse capture
    }

    public onPointerMove(event: PointerEvent): void {
        // adapt the x position relative to the main div
        const divRect = this.maindiv.nativeElement.getBoundingClientRect();
        const x = event.x - divRect.x;
        this.cursorX = x;

        const timestamp = this.getTimeFromPercentage(this.cursorX / this.currentWidth);

        let handled = false;

        // find the current bin the pointer is over
        const currentbin = this.currentBins.find((bin) => {
            const centerMs = bin.center.toDate().getTime();
            const pt = timestamp.toDate().getTime();
            const halfWidth = bin.widthMs / 2.0;
            return centerMs - halfWidth < pt && centerMs + halfWidth >= pt;
        });
        if (currentbin) {
            this.currentBin = currentbin;
        } else {
            this.currentBin = null;
        }

        if (this.mouseDownPosition) {
            // detect mouse move threshold
            if (Math.abs(this.mouseDownPosition - event.offsetX) > 2) {
                // if left button down
                if (event.buttons === 1) {
                    // if the user is not dragging, perform a seek
                    if (!this.isPanning) {
                        this.initialMovingTime = timestamp.clone();
                        this.isPanning = true;
                        this.captureMouse = event.pointerId;
                        this.currentBin = null;
                        handled = true;
                    }
                }

                // clear the mouse down
                this.mouseDownPosition = undefined;
            }
        }

        // are we panning the timeline?
        if (this.isPanning) {
            const diff = moment(timestamp).diff(this.initialMovingTime);
            this.time = {
                start: this.time.start.clone().subtract(diff, 'ms'),
                end: this.time.end.clone().subtract(diff, 'ms'),
            };
            this.selectedBin = null;
            handled = true;
        }

        if (handled) {
            this.draw();

            event.preventDefault();
            event.stopPropagation();
        }
    }

    public onMouseWheel(event: Event): void {
        if (event instanceof WheelEvent) {
            const percentX = event.offsetX / this.currentWidth;

            if (event.deltaY > 0) {
                this.expand(percentX);
            } else if (event.deltaY < 0) {
                this.compress(percentX);
            }
        }
    }

    public onZoomIn(): void {
        this.compress(0.5);
    }

    public onZoomOut(): void {
        this.expand(0.5);
    }

    public onRequery(): void {
        const low = this.privateTime.start.clone();
        const high = this.privateTime.end.clone();
        this.query.emit([low, high]);
    }

    //#endregion

    //#region Private Methods

    private getTotalDifference(): number {
        return Math.abs(this.time.end.diff(this.time.start));
    }

    private getPercentageFromTime(time: moment.Moment): number {
        // 1pm -> 2pm (7600 seconds). 2pm - 1:45pm (15 minutes = 900 seconds)
        // 900/7600 ~ 12% | 100 - 12 ~ 88% | return 88%
        const totalDifference = this.getTotalDifference();
        if (totalDifference < 1) {
            // who knows what will happen if we divide by 0
            return -1;
        }

        const timeDifference = time.diff(this.time.start);
        return timeDifference / totalDifference;
    }

    private getTimeFromPercentage(percentage: number): moment.Moment {
        const totalDifference = this.getTotalDifference();
        const result = totalDifference * percentage;
        return this.time.start.clone().add(result, 'ms');
    }

    private updateTickSpacing() {
        const length = this.time.end.clone().diff(this.time.start);
        const tickSpacing = (10 * length) / this.currentWidth;

        // find the best tick interval for the current time range
        for (let i = 0; i < InvestigateResultsEventTimelineComponent.tickIntervals.length; ++i) {
            if (i < InvestigateResultsEventTimelineComponent.tickIntervals.length - 1) {
                if (tickSpacing >= InvestigateResultsEventTimelineComponent.tickIntervals[i].ms && tickSpacing < InvestigateResultsEventTimelineComponent.tickIntervals[i + 1].ms) {
                    this.currentTickInterval = InvestigateResultsEventTimelineComponent.tickIntervals[i + 1];
                    break;
                }
            } else {
                this.currentTickInterval = InvestigateResultsEventTimelineComponent.tickIntervals[i];
                break;
            }
        }
    }

    private moveTo(start: Date, end: Date) {
        this.time = {
            start: moment(start),
            end: moment(end),
        };

        this.draw();
    }

    private compress(origin: number) {
        const length = this.time.end.clone().diff(this.time.start);
        let newLength = length / this.zoomFactor;

        // we cannot go under 30 seconds range
        if (newLength < InvestigateResultsEventTimelineComponent.minLength) {
            newLength = InvestigateResultsEventTimelineComponent.minLength;
        }

        const offset = newLength * origin;
        const centerTime = this.time.start.clone().add(length * origin, 'ms');
        const start = centerTime.clone().subtract(offset, 'ms').toDate();
        const end = centerTime
            .clone()
            .add(newLength - offset, 'ms')
            .toDate();

        if (start && end) {
            this.moveTo(start, end);
            this.draw();
        }
    }

    private expand(origin: number) {
        const length = this.time.end.clone().diff(this.time.start);
        let newLength = length * this.zoomFactor;

        // we cannot go over 24 hours
        if (newLength > InvestigateResultsEventTimelineComponent.maxLength) {
            newLength = InvestigateResultsEventTimelineComponent.maxLength;
        }

        const offset = newLength * origin;
        const centerTime = this.time.start.clone().add(length * origin, 'ms');
        const start = centerTime.clone().subtract(offset, 'ms').toDate();
        const end = centerTime
            .clone()
            .add(newLength - offset, 'ms')
            .toDate();
        this.moveTo(start, end);
        this.draw();
    }

    //#endregion
}
