// Autogenerated: v2/help/Entities/ApiAuxiliaryArchiverRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { RoleEntity } from '../Roles/RoleEntity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { IAuxiliaryArchiverRoleEntity, IAuxRecordingMode, AuxiliaryArchiverRoleEntityFields } from '../../Interface/IAuxiliaryArchiverRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IFieldObject } from '../../Interface/IFieldObject';
import { IQueryFilter } from '../../Interface/IQueryFilter';

// #region Inner classes

export class AuxRecordingMode extends FieldObject implements IAuxRecordingMode {
    // Id
    public get id(): number {
        return this.getField<number>('Id');
    }
    public set id(value: number) {
        this.setField<number>('Id', value);
    }
    // Mode
    public get mode(): string | null {
        return this.getNullableField<string>('Mode');
    }
    public set mode(value: string | null) {
        this.setNullableField<string>('Mode', value);
    }
    // ScheduleId
    public get scheduleId(): IGuid | null {
        return this.getNullableFieldGuid('ScheduleId');
    }
    public set scheduleId(value: IGuid | null) {
        this.setNullableFieldGuid('ScheduleId', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('Id', 0);
        this.setField<string>('Mode', '');
        this.setFieldGuid('ScheduleId', SafeGuid.EMPTY);
    }
}

// #endregion Inner classes

export class AuxiliaryArchiverRoleEntity extends RoleEntity implements IAuxiliaryArchiverRoleEntity {
    // #region REST Properties

    // ArchiveBackupMaxBandwidth
    public get archiveBackupMaxBandwidth(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.archiveBackupMaxBandwidthField);
    }
    public set archiveBackupMaxBandwidth(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.archiveBackupMaxBandwidthField, value);
    }

    // ArchiveFileSizeEnabled
    public get archiveFileSizeEnabled(): boolean {
        return this.getField<boolean>(AuxiliaryArchiverRoleEntityFields.archiveFileSizeEnabledField);
    }
    public set archiveFileSizeEnabled(value: boolean) {
        this.setField<boolean>(AuxiliaryArchiverRoleEntityFields.archiveFileSizeEnabledField, value);
    }

    // BackupRetentionPeriod
    public get backupRetentionPeriod(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.backupRetentionPeriodField);
    }
    public set backupRetentionPeriod(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.backupRetentionPeriodField, value);
    }

    // DefaultManualRecordingTime
    public get defaultManualRecordingTime(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.defaultManualRecordingTimeField);
    }
    public set defaultManualRecordingTime(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.defaultManualRecordingTimeField, value);
    }

    // DeleteOldFilesOnDiskFull
    public get deleteOldFilesOnDiskFull(): boolean {
        return this.getField<boolean>(AuxiliaryArchiverRoleEntityFields.deleteOldFilesOnDiskFullField);
    }
    public set deleteOldFilesOnDiskFull(value: boolean) {
        this.setField<boolean>(AuxiliaryArchiverRoleEntityFields.deleteOldFilesOnDiskFullField, value);
    }

    // DiskLoadWarningLimit
    public get diskLoadWarningLimit(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.diskLoadWarningLimitField);
    }
    public set diskLoadWarningLimit(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.diskLoadWarningLimitField, value);
    }

    // EnableAudioRecording
    public get enableAudioRecording(): boolean {
        return this.getField<boolean>(AuxiliaryArchiverRoleEntityFields.enableAudioRecordingField);
    }
    public set enableAudioRecording(value: boolean) {
        this.setField<boolean>(AuxiliaryArchiverRoleEntityFields.enableAudioRecordingField, value);
    }

    // EnableAutomaticCleanup
    public get enableAutomaticCleanup(): boolean {
        return this.getField<boolean>(AuxiliaryArchiverRoleEntityFields.enableAutomaticCleanupField);
    }
    public set enableAutomaticCleanup(value: boolean) {
        this.setField<boolean>(AuxiliaryArchiverRoleEntityFields.enableAutomaticCleanupField, value);
    }

    // EnableMetadataRecording
    public get enableMetadataRecording(): boolean {
        return this.getField<boolean>(AuxiliaryArchiverRoleEntityFields.enableMetadataRecordingField);
    }
    public set enableMetadataRecording(value: boolean) {
        this.setField<boolean>(AuxiliaryArchiverRoleEntityFields.enableMetadataRecordingField, value);
    }

    // EnableThumbnailsRequests
    public get enableThumbnailsRequests(): boolean {
        return this.getField<boolean>(AuxiliaryArchiverRoleEntityFields.enableThumbnailsRequestsField);
    }
    public set enableThumbnailsRequests(value: boolean) {
        this.setField<boolean>(AuxiliaryArchiverRoleEntityFields.enableThumbnailsRequestsField, value);
    }

    // IsVideoWatermarking
    public get isVideoWatermarking(): boolean {
        return this.getField<boolean>(AuxiliaryArchiverRoleEntityFields.isVideoWatermarkingField);
    }
    public set isVideoWatermarking(value: boolean) {
        this.setField<boolean>(AuxiliaryArchiverRoleEntityFields.isVideoWatermarkingField, value);
    }

    // MaximumVideoLength
    public get maximumVideoLength(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.maximumVideoLengthField);
    }
    public set maximumVideoLength(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.maximumVideoLengthField, value);
    }

    // MaximumVideoSize
    public get maximumVideoSize(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.maximumVideoSizeField);
    }
    public set maximumVideoSize(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.maximumVideoSizeField, value);
    }

    // PostEventRecordingTime
    public get postEventRecordingTime(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.postEventRecordingTimeField);
    }
    public set postEventRecordingTime(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.postEventRecordingTimeField, value);
    }

    // PreEventRecordingTime
    public get preEventRecordingTime(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.preEventRecordingTimeField);
    }
    public set preEventRecordingTime(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.preEventRecordingTimeField, value);
    }

    // ProtectedVideoThreshold
    public get protectedVideoThreshold(): number {
        return this.getField<number>(AuxiliaryArchiverRoleEntityFields.protectedVideoThresholdField);
    }
    public set protectedVideoThreshold(value: number) {
        this.setField<number>(AuxiliaryArchiverRoleEntityFields.protectedVideoThresholdField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getArchiverStatsAsync(transaction?: ITransaction | null): Promise<IFieldObject | null> {
        return await this.getDataRelationAsync<FieldObject>(FieldObject, 'ArchiverStats', false, ValidFlags.None, transaction);
    }

    public async getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cameras', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getRecordingModesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IAuxRecordingMode> | null> {
        return this.getFieldObjectRelationAsync<AuxRecordingMode, IAuxRecordingMode>(AuxRecordingMode, 'RecordingModes', 'Id', true, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

