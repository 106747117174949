import { ITimelineToolTip } from 'Marmot/Timeline/timelinePart';
import { NavigationService } from '../../services/navigation/navigation.service';

export class TooltipItem {
    public title = '';
    public description = '';
    public hasPicture = false;
    public picture = '';
    private navigation?: string;

    constructor(private navigationService: NavigationService, public timestamp: Date) {}

    public loadFrom(tooltip: ITimelineToolTip): boolean {
        let result = false;

        if (tooltip.hasField('title')) {
            this.title = tooltip.title;
            result = true;
        }
        if (tooltip.hasField('description')) {
            this.description = tooltip.description;
            result = true;
        }
        if (tooltip.hasField('navigation')) {
            this.navigation = tooltip.navigation;
            result = true;
        }
        if (tooltip.hasField('picture') && tooltip.picture) {
            this.picture = tooltip.picture;
            this.hasPicture = true;
            result = true;
        }

        return result;
    }

    public click(): void {
        if (this.navigation) {
            this.navigationService.navigate(this.navigation, true).fireAndForget();
        }
    }
}
