import { Resolution } from '../gwp';
import { Coords } from './Coords';
import { ClipSpaceVec2 } from './Vec2';

//Origin (0, 0) is top left
export class TopLeftPixelCoords extends Coords {
  private readonly m_resolution: Resolution;

  public constructor(x: number, y: number, resolution: Resolution) {
    super(x, y);
    this.m_resolution = resolution;
  }

  public toClipSpaceVec2(): ClipSpaceVec2 {
    const clipX = (2 * this.m_x / this.m_resolution.Width) - 1;
    const clipY = -((2 * this.m_y / this.m_resolution.Height) - 1); //* -1 because since y = 0 is the top in coords, it translates to top = -1 and bottom = 1 and we want the opposite in clip space
    return new ClipSpaceVec2(clipX, clipY);
  }
}
