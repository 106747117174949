// Autogenerated: v2/help/Classes/ApiAccessUnknownCredentialEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { AccessEvent } from './AccessEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { IAccessUnknownCredentialEvent } from '../Interface/IAccessUnknownCredentialEvent';

export class AccessUnknownCredentialEvent extends AccessEvent implements IAccessUnknownCredentialEvent {
    // #region REST Properties

    // BitLength
    public get bitLength(): number {
        return this.getField<number>('BitLength');
    }
    public set bitLength(value: number) {
        this.setField<number>('BitLength', value);
    }

    // CardFormat
    public get cardFormat(): IGuid {
        return this.getFieldGuid('CardFormat');
    }
    public set cardFormat(value: IGuid) {
        this.setFieldGuid('CardFormat', value);
    }

    // CredentialType
    public get credentialType(): string {
        return this.getField<string>('CredentialType');
    }
    public set credentialType(value: string) {
        this.setField<string>('CredentialType', value);
    }

    // UniqueID
    public get uniqueID(): string {
        return this.getField<string>('UniqueID');
    }
    public set uniqueID(value: string) {
        this.setField<string>('UniqueID', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setField<number>('BitLength', 0);
        this.setFieldGuid('CardFormat', SafeGuid.EMPTY);
        this.setField<string>('CredentialType', '');
        this.setField<string>('UniqueID', '');
    }

    // #endregion Methods

}

