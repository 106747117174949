/** Specifies the boundary of a grid cell. Indices are 1 based.
 * For example:
 *   columnStart = 1 represents the left edge of the grid
 *   rowStart = 1 represents the top edge of the grid. */
export class TileBoundary {
    /** Returns the boundary of the first cell of a pattern (top-left) */
    public static readonly topLeft = Object.freeze(new TileBoundary(1, 1, 2, 2));

    constructor(public startColumn: number, public startRow: number, public endColumn: number = -1, public endRow: number = -1) {}
}
