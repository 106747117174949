import 'reflect-metadata';

import { IGuid } from 'safeguid';
import { MeltedIcon } from '@genetec/gelato-angular'; // mmmm for plugins ?
import { Injector } from '@angular/core';
import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FeatureFlag } from '@modules/feature-flags/feature-flag';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 *  Used to identify and set properties for a plugin
 */
interface PluginDescriptorBase {
    /**
     *  Plugin types supported by this plugin
     */
    pluginTypes: IGuid[];

    /**
     *  exposure data of a plugin (this is used to show the plugin appearance or location, like a button or icon)
     */
    exposure: PluginComponentExposure;

    /**
     *  requirements for this plugin to be displayed
     */
    requirements?: PluginComponentRequirements;
}

export interface PluginDescriptor extends PluginDescriptorBase {
    /**
     *  indicates if this plugin is supported for a particular data
     */
    isSupported(data: unknown, pluginType: IGuid, injector: Injector): boolean;
}

export interface ContentPluginDescriptor extends PluginDescriptorBase {
    /**
     *  indicates if this plugin is supported for a particular data
     */
    isContentSupported(data: Content, pluginType: IGuid, injector: Injector): boolean;
}

export interface PluginComponent {
    /**
     *  data context to interract with the plugin
     */
    setDataContext?(dataContext: unknown): void;
}

export interface MetadataPluginComponent extends PluginComponent {
    /**
     *  initData that will be passed to this plugin to interact with at the instanciation of it
     */
    setInitData?(initData: unknown): void;
}

export interface ContentPluginComponent extends PluginComponent {
    /**
     *  content that will be passed to this plugin to interact with at the instanciation of it
     */
    setContent?(content: Content): void;
}

/**
 *  when the plugin wants to add a command
 */
export interface PluginCommand {
    /**
     *  the command id that will be called
     */
    id: IGuid;

    /**
     *  name of the command (method here to always have right language)
     */
    name: () => string;

    /**
     *  icon of the command
     */
    icon: MeltedIcon;

    /**
     *  text of the command (method here to always have right language)
     */
    tooltip?: () => string;

    /**
     *  color of the command's icon
     */
    color?: () => string;

    /**
     *  Section where the command goes under
     */
    sectionName?: () => string;

    /**
     *  Folder group where the command goes
     */
    groupName?: () => string;

    /**
     *  icon representing the section
     */
    groupIcon?: MeltedIcon;

    /**
     *  Keys to press to execute command
     */
    keyBinding?: string;
}

/**
 *  exposure data of a plugin (normaly this is used to show the plugin first appearance, like a button or icon)
 */
export interface PluginComponentExposure {
    /**
     *  id of the plugin
     */
    id: IGuid;

    /**
     *  title to display
     */
    title?: string;

    /**
     *  icon to be used
     */
    icon?: string;

    /**
     *  plugin sub Section (usefull to indicate another placement location inside the plugin container if the plugin container supports it)
     */
    subSection?: IGuid;

    /**
     *  priority where the plugin will be instanciated compared to others that support the same data
     */
    priority?: number;

    /**
     *  list of tags that represent this component
     */
    tags?: string[];

    /**
     *  indicates if this plugin is dependant of another, meaning it will not be exposed if the dependant plugin is not there.
     */
    requiredParentPlugin?: IGuid;

    /**
     *  all available commands for this plugin
     */
    availableCommands?: IGuid[];

    /**
     *  indicates if multiple data using this plugin type can be navigated through a carousel or if multiple instances of the plugin must be created
     */
    supportsCarousel?: boolean;

    /**
     *  Sub task plugin type
     */
    subTaskPluginType?: IGuid;

    /**
     * indicates if the plugin is standalone and should be displayed as one item
     */
    standalone?: boolean;
}

/**
 *  requirement for the plugin to display
 */
export interface PluginComponentRequirements {
    /**
     *  list of features to be enabled
     */
    features?: IGuid[];

    /**
     *  list of licenses needed
     */
    licenses?: string[];

    /**
     *  Global privileges needed
     */
    globalPrivileges?: IGuid[];

    /**
     *  Global privileges that are verified bu not mandatory
     */
    optionalGlobalPrivileges?: IGuid[];

    /**
     *  Any feature flag needed (OR)
     */
    enabledFeatureFlags?: FeatureFlag[];

    /**
     *  All disabled feature flag needed (AND)
     */
    disabledFeatureFlags?: FeatureFlag[];
}

/**
 *  Data group used to interact with within the plugin
 */
export interface ContentGroup {
    /**
     *  unique id of the content group
     */
    id: IGuid;

    /**
     *  main content of the group
     */
    mainContent: Content;

    /**
     *  sub contents adding data to the main content
     */
    subContents: Content[];
}

/**
 *   specific data used to interact with within the plugin
 * */
export interface Content {
    /**
     *  unique id of the content
     */
    id: IGuid;

    /**
     *  type of the content
     */
    type: IGuid;

    /**
     *  title of the content
     */
    title: string;

    /**
     *  small description of the content
     */
    description: string;

    /**
     *  icon representing this content
     */
    icon: string;

    /**
     *  custom icon representing this content if there is one
     */
    customIconId?: IGuid;

    /**
     *  color assigned to this content
     */
    color: string;

    /**
     *  source of the content
     */
    source: string;

    /**
     *  the mainContent of its group (null if mainContent itself)
     */
    /**
     *  this is usefull to know the context as of what to display
     */
    contextContent: Content | null;

    /**
     *  other sub contents of the group
     */
    contextsubContents: Content[] | null;

    /**
     * any relevent data attached to the content
     */
    parameters: IFieldObject | null;

    /**
     *  privileges related to this content
     */
    grantedPrivileges: IGuid[];
}

export enum DisplayContext {
    Unknown = 0,
    MapPopup = 1,
    Sidepane = 2,
}

export interface LicensePartsState {
    [licensePart: string]: boolean;
}
export interface PluginContext {
    /**
     * Specify the context where the plugin is displayed
     */
    displayContext: DisplayContext;
}

// #region Decorators for plugins

/**
 *  Metadata key to identify the data inserted using reflection for the PluginDescriptor decorator
 */
export const MetadataKey = 'PluginDescriptorMetadataKey';
export const PluginDescriptor = (pluginDescriptor: PluginDescriptor): ((constructor: new (...args: any[]) => any) => void) => {
    return (constructor: new (...args: any[]) => any) => {
        Reflect.defineMetadata(MetadataKey, pluginDescriptor, constructor);
    };
};
