import { Injectable, OnDestroy } from '@angular/core';
import { retryWithConstantTime } from '@modules/mission-control/utils/rxjs-utils';
import { List } from 'immutable';
import { Observable, Subject, ConnectableObservable, BehaviorSubject } from 'rxjs';
import { map, publishReplay, retryWhen, switchMap, takeUntil } from 'rxjs/operators';
import { IGuid } from 'safeguid';
import { State, StateApi } from '../../models/state';
import { StateGuids } from '../../state-guids';
import { McClient } from '../mc-client.service';

export const DEFAULT_STATES: State[] = [
    new State(StateGuids.IN_PROGRESS, 'In Progress'),
    new State(StateGuids.NEW, 'New'),
    new State(StateGuids.PARKED, 'On Hold'),
    new State(StateGuids.SOLVED, 'Resolved'),
];

@Injectable()
export class StateService implements OnDestroy {
    private _states$: BehaviorSubject<State[]>;
    private _refresh$ = new Subject();
    private _stopObs$ = new Subject();

    constructor(private _mcClient: McClient) {
        const obs$ = this._refresh$.pipe(
            switchMap(() => this.fetchStates$()),
            retryWhen(retryWithConstantTime('Fetch incident states')),
            takeUntil(this._stopObs$),
            publishReplay(1)
        ) as ConnectableObservable<State[]>;
        obs$.connect();

        this._states$ = new BehaviorSubject<State[]>(DEFAULT_STATES);
        obs$.subscribe(this._states$);

        this._refresh$.next();
    }

    public ngOnDestroy(): void {
        this._states$.complete();

        this._refresh$.complete();

        this._stopObs$.next();
        this._stopObs$.complete();
    }

    public getStates$(): Observable<State[]> {
        return this._states$.asObservable();
    }

    public getStates(stateIds?: IGuid[]): State[] {
        return stateIds ? this._states$.getValue().filter((state) => stateIds.find((stateId) => stateId.equals(state.id))) : this._states$.getValue();
    }

    public getActiveStates$(): Observable<State[]> {
        return this._states$.pipe(map((states) => states.filter((s) => !s.id.equals(StateGuids.CLOSED))));
    }

    public getActiveStates(): State[] {
        return this._states$.getValue().filter((s) => !s.id.equals(StateGuids.CLOSED));
    }

    public get getActiveStatesIds(): List<IGuid> {
        return List(this.getActiveStates().map((s) => s.id));
    }

    public refresh(): void {
        this._refresh$.next();
    }

    public getState$(id: IGuid): Observable<State | undefined> {
        return this._states$.pipe(
            map((states) => {
                return states.find((x) => x.id.equals(id));
            })
        );
    }

    private fetchStates$(): Observable<State[]> {
        return this._mcClient.getAll<StateApi>('v2/states').pipe(
            map((states) => {
                return states.length > 0 ? states.map(State.assign) : DEFAULT_STATES;
            })
        );
    }
}
