import { GoogleMapProvider, GoogleMapProviderOptions, MapOptions, MarkerClusterGroupOptions } from '@genetec/web-maps';
import { MapsProviderClient } from '@modules/map/api/api';
import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { IMapEntity } from 'RestClient/Client/Interface/IMapEntity';
import { SystemConfigurationEntity } from 'RestClient/Client/Model/SystemConfigurationEntity';
import { IGuid } from 'safeguid';
import { MapLoader } from './map-loader';
import { MapProviderTypes } from './map-provider-types';
import { MapProvider, MapProviderLoaderOptions } from './map-provider.interface';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class GoogleMapProviderLoader implements MapProvider {
    public type: IGuid = MapProviderTypes.Google;
    private detectChangeDone = false;

    constructor(private mapLoader: MapLoader, private entityCacheTask: IEntityCacheTask, private mapsProviderClient: MapsProviderClient, private loggerService: LoggerService) {}

    public async loadMapAsync(map: IMapEntity, options: MapProviderLoaderOptions): Promise<boolean> {
        const markerClustering: MarkerClusterGroupOptions = {
            chunkedLoading: true,
            maxClusterRadius: 50,
            spiderfyOnMaxZoom: false,
            zoomToBoundsOnClick: true,
        };

        const mapOptions: MapOptions = {
            clustering: markerClustering,
            attributionControl: false,
            closePopupOnClick: false,
            minZoom: 1.0,
            maxZoom: map.maxZoom,
            zoom: options.zoom ?? 4,
            maxBounds: [
                [-90.0, -220.0],
                [90.0, 220.0],
            ],
            bounceAtZoomLimits: false,
            inertia: false,
            maxBoundsViscosity: 1.0,
            userLocationEnabled: options.userLocationEnabled,
            initialBounds: options.defaultView,
            center: options.center,
        };

        let key!: string;

        const systemConfig = (await this.entityCacheTask.getEntityAsync(SystemConfigurationEntity, Guids.SystemConfiguration, true)) as SystemConfigurationEntity;
        if (systemConfig) {
            key = await this.getKey();

            if (!this.detectChangeDone) {
                this.entityCacheTask.detectRelationChangeAsync(systemConfig, () => systemConfig.getMapProvidersAsync()).fireAndForget();
                this.detectChangeDone = true;
            }
        }

        if (!key) {
            this.loggerService.traceError('No google API Key provided');
            return false;
        }

        // Missing types from gridLayer.GoogleMutantOptions
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const mapProviderOptions: GoogleMapProviderOptions = {
            noWrap: true,
            apiKey: key,
        } as any;

        await this.mapLoader.loadMapAsync(GoogleMapProvider, mapOptions, mapProviderOptions);

        return true;
    }

    private async getKey(): Promise<string> {
        // license key is fetched like the SD, the api key from google has a restriction protection underneath the key to only allow
        // requester from a certain location. https://developers.google.com/maps/api-key-best-practices
        const key = await this.mapsProviderClient.getProviderKey(this.type).toPromise();
        return key;
    }
}
