// Autogenerated: CodeGen/AreaBrowserItemModel?language=ts&InterfaceOnly=True on 2023-01-10 2:23:02 PM
// Autogenerated: CodeGen/AreaBrowserItemModel?language=ts&InterfaceOnly=False on 2023-01-10 2:23:02 PM

import { IEntityBrowserItemModel } from '@modules/shared/entity-browser/interfaces/items/entity-browser-item-model.interface';
import { IGuid, SafeGuid } from 'safeguid';
import { RegisterFieldObjectConstructor, RegisterFieldObjectOverload } from 'RestClient/Helpers/FieldObjectOverloads';
import { EntityBrowserItemModel } from '@modules/shared/entity-browser/Items/entity-browser-item-model';

export interface IAreaBrowserItemModel extends IEntityBrowserItemModel {
    map: IGuid;
    isFederatedDirectory: boolean;
}

@RegisterFieldObjectConstructor
@RegisterFieldObjectOverload('EntityBrowserItemModel', 'AreaBrowserItemModel')
export class AreaBrowserItemModel extends EntityBrowserItemModel implements IAreaBrowserItemModel {
    // Map
    public get map(): IGuid {
        return this.getFieldGuid('Map');
    }
    public set map(value: IGuid) {
        this.setFieldGuid('Map', value);
    }
    // IsFederatedDirectory
    public get isFederatedDirectory(): boolean {
        return this.getField<boolean>('IsFederatedDirectory');
    }
    public set isFederatedDirectory(value: boolean) {
        this.setField<boolean>('IsFederatedDirectory', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Map', SafeGuid.EMPTY);
        this.setField<boolean>('IsFederatedDirectory', false);
    }
}
