// Autogenerated: v2/help/Entities/ApiPatroller?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { LocatableEntity } from '../LocatableEntity';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { IPatrollerEntity, PatrollerEntityFields } from '../../Interface/IPatrollerEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IQueryFilter } from '../../Interface/IQueryFilter';

export class PatrollerEntity extends LocatableEntity implements IPatrollerEntity {
    // #region REST Properties

    // SoftwareVersion
    public get softwareVersion(): string {
        return this.getField<string>(PatrollerEntityFields.softwareVersionField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Patrollers);
    }

    // #endregion Constructor

    // #region REST Methods

    public async moveUnitAsync(id: IGuid, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldGuid('id', id);

        return this.executeRequestTransactionAsync<IRestResponse>('MoveUnit', 'POST', inParam, transaction, responseHandler);
    }

    public async getUnitsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Units', 'Id', true, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

