import { GenAngularModule, GenModalService } from '@genetec/gelato-angular';
import { Router } from '@angular/router';
import { Inject, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { GetBaseUriForCodeGen } from '@utilities/base-uri-getter';
import { FeatureFlagsModule } from '@modules/feature-flags/feature-flags.module';
import { ChartsModule } from 'ng2-charts';
import { CommandsService, COMMANDS_SERVICE, MODAL_SERVICE } from '../shared/interfaces/plugins/public/plugin-services-public.interface';
import { SharedModule } from '../shared/shared.module';
import { GeneralModule } from '../general/general.module';
import { IngestionService } from './services/ingestion.service';
import { IngestDataModalComponent } from './components/ingest-data-modal/data-ingestion-modal.component';
import { CorrelationDatumMapWidgetComponent } from './components/datum/correlation-datum-map-widget/correlation-datum-map-widget.component';
import { CorrelationService } from './services/correlation.service';
import { CorrelationDatumSidepaneWidgetComponent } from './components/datum/datum-sidepane-widget/correlation-datum-sidepane-widget.component';
import { FilterDataTypesComponent } from './components/filters/data-types/filter-data-types.component';
import { CorrelationParametersModalComponent } from './components/parameters-modal/correlation-parameters-modal.component';
import { IngestionCommandProvider } from './services/ingestion-command-provider';
import { MobileUserStateSidepaneWidgetComponent } from './components/datum/mobile-state-sidepane-widget/mobile-sidepane-state-widget.component';
import { MobileUserDetailsSidepaneWidgetComponent } from './components/datum/mobile-details-sidepane-widget/mobile-details-sidepane-widget.component';
import { InvestigateTaskComponent } from './components/investigate/task/investigate-task.component';
import { InvestigateWhatFilterComponent } from './components/investigate/filters/what/what-filter.component';
import { InvestigateSideContextDataState } from './components/investigate/services/investigate-side-context-data.state';
import { InvestigateSidePaneComponent } from './components/investigate/sidepane/investigate-side-pane.component';
import { InvestigateDataState } from './components/investigate/services/investigate-data.state';
import { ImageNameToggleButtonComponent } from './components/general/image-name-toggle-button/image-name-toggle-button.component';
import { InvestigateResultsContainerComponent } from './components/investigate/results/investigate-results-container.component';
import { InvestigateWhenFilterComponent } from './components/investigate/filters/when/when-filter.component';
import { InvestigateResultsEventTimelineComponent } from './components/investigate/timeline/event-timeline.component';
import { InvestigateWhereFilterComponent } from './components/investigate/filters/where/where-filter.component';
import { baseUrl, CorrelationClient, DataIngestionClient } from './api/api';
import { AggregationDashboardWidgetComponent } from './components/dashboard/aggregation-widget/aggregation-widget.component';
import { AggregationWidgetSettingsModalComponent } from './components/dashboard/aggregation-widget-settings-modal/aggregation-widget-settings-modal.component';
import { BreadcrumbsComponent } from './components/general/breadcrumbs/breadcrumbs.component';
import { OneOrManyComboboxComponent } from './components/general/one-or-many-combobox/one-or-many-combobox-combobox.component';
import { FilterPluginDataTypesComponent } from './components/filters/data-types/filter-plugin-data-types.component';
import { SelectInvestigateColumnsComponent } from './components/general/select-investigate-columns/select-investigate-columns.component';
import { InvestigateDataContext } from './components/investigate/services/investigate-data.context';
import { UnifiedReportFeatureFlags } from './feature-flags';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        GenAngularModule,
        GeneralModule, // we need this for app-filters in the investigate task... that's not ideal :/
        NgxsModule.forFeature([InvestigateSideContextDataState, InvestigateDataState]),
        ChartsModule,
        FeatureFlagsModule.forRoot(UnifiedReportFeatureFlags),
    ],
    declarations: [
        IngestDataModalComponent,
        CorrelationDatumMapWidgetComponent,
        CorrelationDatumSidepaneWidgetComponent,
        FilterDataTypesComponent,
        CorrelationParametersModalComponent,
        MobileUserStateSidepaneWidgetComponent,
        MobileUserDetailsSidepaneWidgetComponent,
        InvestigateTaskComponent,
        InvestigateWhatFilterComponent,
        InvestigateWhenFilterComponent,
        InvestigateWhereFilterComponent,
        InvestigateSidePaneComponent,
        ImageNameToggleButtonComponent,
        InvestigateResultsContainerComponent,
        InvestigateResultsEventTimelineComponent,
        AggregationDashboardWidgetComponent,
        AggregationWidgetSettingsModalComponent,
        BreadcrumbsComponent,
        OneOrManyComboboxComponent,
        FilterPluginDataTypesComponent,
        SelectInvestigateColumnsComponent,
    ],
    providers: [
        IngestionService,
        CorrelationService,
        IngestionCommandProvider,
        CorrelationClient,
        DataIngestionClient,
        InvestigateDataContext,
        {
            provide: baseUrl,
            deps: [],
            useFactory: GetBaseUriForCodeGen,
        },
    ],
    exports: [],
})
export class CorrelationModule {
    //#region Constructors

    constructor(
        router: Router,
        injector: Injector,
        private ingestionService: IngestionService,
        private correlationService: CorrelationService,
        @Inject(MODAL_SERVICE) private modalService: GenModalService,
        @Inject(COMMANDS_SERVICE) private commandsService: CommandsService,
        ingestionCommandProvider: IngestionCommandProvider
    ) {}

    //#endregion
}
