import { IGuid } from 'safeguid';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.ChangeExternalId;

export class ChangeExternalIdCommand extends MCCommandBase {
    public type = commandType;

    constructor(incidentId: IGuid, externalId: string) {
        super(
            `/v2/Incidents/${incidentId.toString()}`,
            JSON.stringify({
                Type: commandType,
                ExternalId: externalId,
            })
        );
    }

    public errorMessage(statusCode?: number): string {
        return 'STE_MESSAGE_ERROR_EDIT_INCIDENT_EXTERNAL_ID_FAILED';
    }
}
