import { LoggerService } from '@modules/shared/services/logger/logger.service';
import { Injectable, OnDestroy } from '@angular/core';
import { ConnectableObservable, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, switchMap, takeUntil } from 'rxjs/operators';
import { IncidentType, IncidentTypeApi } from '../models/incident-type';
import { McClient } from './mc-client.service';
import { IGuid } from 'safeguid';

@Injectable()
export class IncidentTypeService implements OnDestroy {
    private _incidentTypes$: Observable<IncidentType[]>;
    private _refresh$ = new Subject();
    private _stopObs$ = new Subject();

    constructor(private _mcClient: McClient) {
        const obs$ = this._refresh$.pipe(
            switchMap(() => this.fetchIncidentTypes()),
            takeUntil(this._stopObs$),
            publishReplay(1)
        ) as ConnectableObservable<IncidentType[]>;

        obs$.connect();
        this._incidentTypes$ = obs$;
    }

    public ngOnDestroy(): void {
        this._stopObs$.next();
    }

    public getIncidentTypes(): Observable<IncidentType[]> {
        this._refresh$.next();
        return this._incidentTypes$;
    }

    public refresh(): void {
        this._refresh$.next();
    }

    public getIncidentType(id: IGuid, revision: number): Observable<IncidentType> {
        return this._mcClient.get<IncidentTypeApi>(`v2/IncidentTypes/${id}/Revisions/${revision}`).pipe(map((incidentType) => IncidentType.assign(incidentType)));
    }

    private fetchIncidentTypes(): Observable<IncidentType[]> {
        return this._mcClient.getAll<IncidentTypeApi>('v2/IncidentTypes').pipe(
            map((x) => x.map((i) => IncidentType.assign(i)).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))),
            catchError(() => {
                return of([]);
            })
        );
    }
}
