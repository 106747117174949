export class SafeResizeObserver {
  private readonly m_resizeObserver: ResizeObserver | null;
  private readonly m_observedElement: HTMLElement;

  constructor(htmlElement: HTMLElement, fct: () => void) {
    this.m_observedElement = htmlElement;

    if (window.ResizeObserver === undefined) {
      this.m_resizeObserver = null;
      return;
    }

    this.m_resizeObserver = new ResizeObserver(fct);
    this.m_resizeObserver.observe(htmlElement);
  }

  public dispose(): void {
    if (this.m_resizeObserver !== null) {
      this.m_resizeObserver.unobserve(this.m_observedElement);
    }
  }
}
