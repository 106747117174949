<div class="incident-filter d-flex" style="flex-wrap: wrap">
    <app-filter-list
        id="typeFilter"
        [descriptor]="{ filterId: 'typeFilter', label: 'STE_LABEL_NAME' | translate }"
        [isSearchable]="true"
        [itemsSource]="typeFilteredFilterItem$ | async"
        (reload)="onReloadTypes()"
        (searchChange)="onSearchTypeChange($event)"
        (valueChange)="typeFilterChange($event)"
        [popupPosition]="PopupPosition.TopLeft"
    >
    </app-filter-list>

    <app-filter-list
        id="prioritiesFilter"
        [descriptor]="{ filterId: 'prioritiesFilter', label: 'STE_LABEL_PRIORITY' | translate }"
        [itemsSource]="priorityFilterItem$ | async"
        (reload)="onReloadPriorities()"
        (valueChange)="priorityFilterChange($event)"
        [popupPosition]="PopupPosition.TopLeft"
    >
    </app-filter-list>

    <app-filter-list
        id="statesFilter"
        [descriptor]="{ filterId: 'statesFilter', label: 'STE_LABEL_INCIDENTSTATE' | translate }"
        [itemsSource]="stateFilterItem$ | async"
        (reload)="onReloadStates()"
        (valueChange)="stateFilterChange($event)"
        [popupPosition]="PopupPosition.TopLeft"
    >
    </app-filter-list>

    <app-filter-date
        id="triggerTimeFilter"
        [descriptor]="{ filterId: 'triggerTimeFilter', label: 'STE_LABEL_INCIDENT_TRIGGERINGTIME' | translate }"
        [calendarTimeFrame]="CalendarTimeFrame.Past"
        [calendarFlavor]="CalendarFlavor.DateTime"
        (valueChange)="onDateChange($event)"
        [popupPosition]="PopupPosition.TopLeft"
    >
    </app-filter-date>

    <app-filter-entity
        id="incidentLocationFilter"
        [descriptor]="{ filterId: 'incidentLocationFilter', label: 'STE_LABEL_INCIDENT_LOCATION' | translate }"
        [entityTypes]="locationEntityTypes"
        [refresherType]="SharedEntityBrowserRefreshTypes.FlatView"
        (valueChange)="onEntityChange($event)"
        [popupPosition]="PopupPosition.TopLeft"
    >
    </app-filter-entity>

    <app-filter-owner
        id="incidentOwnerFilter"
        [descriptor]="{ filterId: 'incidentOwnerFilter', label: 'STE_LABEL_OWNER' | translate }"
        (valueChange)="onOwnerChange($event)"
        [popupPosition]="PopupPosition.TopLeft"
    >
    </app-filter-owner>

    <gen-button flavor="flat" class="m-auto" (click)="clearFilters()" *ngIf="!areFiltersCleared()" appTooltip="{{ 'STE_BUTTON_CLEARALLFILTERS' | translate }}">
        <gen-icon icon="close"> </gen-icon>
    </gen-button>
</div>
