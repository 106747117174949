import { Cancellation, CancellationToken } from '../utils/CancellationToken';
import { ILogger } from '../utils/logger';
import { PromiseCompletionSource } from '../utils/PromiseCompletionSource';
import { Resolution } from '../utils/Resolution';
import { Utils } from '../utils/Utils';
import { JpegRenderer } from './jpeg/JpegRenderer';
import { IMseRenderer } from './msePlayer';

export interface IVideoFrameSource {
  getImage(): Promise<IVideoFrame | Cancellation>;
  dispose(): void;
}

//Aggregates the jpeg and mse as video frame source
export class VideoFrameSources implements IVideoFrameSource {
  private readonly m_jpegFrameSource: MjpegFrameSource;
  private readonly m_videoElementFrameSource: VideoElementFrameSource | null;

  private m_frameResolver: (result: IVideoFrame | Cancellation) => void = (_) => { };

  constructor(jpegRenderer: JpegRenderer, mseRenderer: IMseRenderer | undefined, htmlVideoElement: HTMLVideoElement) {
    this.m_jpegFrameSource = new MjpegFrameSource(jpegRenderer);
    this.m_videoElementFrameSource = mseRenderer !== undefined ? new VideoElementFrameSource(mseRenderer, htmlVideoElement) : null;
  }

  public dispose(): void {
    this.m_jpegFrameSource.dispose();
    this.m_videoElementFrameSource?.dispose();
  }

  private m_jpegSubscribed = false;
  private m_mseSubscribed = false;

  public getImage(): Promise<IVideoFrame | Cancellation> {
    if (this.m_videoElementFrameSource === null) {
      return this.m_jpegFrameSource.getImage();
    }

    const videoElementFrameSource = this.m_videoElementFrameSource;
    const codecRace = new Promise<IVideoFrame | Cancellation>((resolve) => {
      this.m_frameResolver = resolve;
      if (!this.m_jpegSubscribed) {
        this.m_jpegSubscribed = true;
        this.m_jpegFrameSource.getImage().then((frame) => {
          this.m_jpegSubscribed = false;
          this.m_frameResolver(frame);
        });
      }

      if (!this.m_mseSubscribed) {
        this.m_mseSubscribed = true;
        videoElementFrameSource.getImage().then((frame) => {
          this.m_mseSubscribed = false;
          this.m_frameResolver(frame);
        });
      }
    });

    return codecRace;
  }
}

export class VideoElementFrameSource implements IVideoFrameSource {
  private readonly m_mseRenderer: IMseRenderer;
  private readonly m_htmlVideoElement: HTMLVideoElement;

  private readonly m_disposeToken: CancellationToken;
  private m_interupt: ((cancellation: Cancellation) => void) = () => { };

  public constructor(mseRenderer: IMseRenderer, htmlVideoElement: HTMLVideoElement) {
    this.m_mseRenderer = mseRenderer;
    this.m_htmlVideoElement = htmlVideoElement;
    this.m_disposeToken = new CancellationToken;

    this.m_disposeToken.Cancellation.then((cancellation) => this.m_interupt(cancellation));
  }

  public dispose(): void {
    this.m_disposeToken.cancel('dispose');
  }

  public async getImage(): Promise<IVideoFrame | Cancellation> {
    if (this.m_disposeToken.IsCancelled) {
      return new Cancellation('Disposed');
    }

    while (true) {
      const result = await new Promise<void | Cancellation>((resolve) => {
        this.m_mseRenderer.FrameRendered.then(() => resolve());
        this.m_interupt = resolve;
      });

      if (result instanceof Cancellation) {
        return result;
      }

      if (this.m_mseRenderer !== undefined) {
        const videoFrame = VideoElementFrame.build(this.m_htmlVideoElement);
        if (videoFrame !== null) {
          return videoFrame;
        }
      }
    }
  }
}

export class MjpegFrameSource implements IVideoFrameSource {
  private readonly m_jpegRenderer: JpegRenderer;

  private readonly m_disposeToken: CancellationToken;
  private m_interupt: ((cancellation: Cancellation) => void) = () => { };

  public constructor(jpegRenderer: JpegRenderer) {
    this.m_jpegRenderer = jpegRenderer;

    this.m_disposeToken = new CancellationToken;

    this.m_disposeToken.Cancellation.then((cancellation) => this.m_interupt(cancellation));
  }

  public dispose(): void {
    this.m_disposeToken.cancel('dispose');
  }

  public async getImage(): Promise<IVideoFrame | Cancellation> {
    if (this.m_disposeToken.IsCancelled) {
      return new Cancellation('Disposed');
    }

    while (true) {
      const result = await new Promise<void | Cancellation>((resolve) => {
        this.m_jpegRenderer.FrameRendered.then(() => resolve());
        this.m_interupt = resolve;
      });

      if (result instanceof Cancellation) {
        return result;
      }

      const htmlImageElement = this.m_jpegRenderer.Image;
      if (htmlImageElement !== undefined) {
        const mjpegFrame = MjpegFrame.build(htmlImageElement);
        if (mjpegFrame !== null) {
          return mjpegFrame;
        }
      }
    }
  }
}

/**
 * @beta
 * */
export interface IVideoFrame {
  readonly Resolution: Resolution;
  readonly Image: HTMLImageElement | HTMLVideoElement;
  toString(): string;
}

export class MjpegFrame implements IVideoFrame {
  private readonly m_imageSource: HTMLImageElement;
  private readonly m_resolution: Resolution;

  public get Image(): HTMLImageElement | HTMLVideoElement {
    return this.m_imageSource;
  }

  public get Resolution(): Resolution {
    return this.m_resolution;
  }

  public static build(imageSource: HTMLImageElement): IVideoFrame | null {
    if (imageSource.width === 0 || imageSource.height === 0) {
      return null;
    }
    return new MjpegFrame(imageSource);
  }

  private constructor(imageSource: HTMLImageElement) {
    this.m_imageSource = imageSource;
    this.m_resolution = Resolution.build(imageSource.width, imageSource.height);
  }

  public toString(): string {
    return `Mjpeg ${this.m_resolution.toString()}`;
  }
}

export class VideoElementFrame implements IVideoFrame {
  private readonly m_imageSource: HTMLVideoElement;
  private readonly m_resolution: Resolution;

  public get Image(): HTMLImageElement | HTMLVideoElement {
    return this.m_imageSource;
  }

  public get Resolution(): Resolution {
    return this.m_resolution;
  }

  public static build(imageSource: HTMLVideoElement): IVideoFrame | null {
    if (imageSource.videoWidth === 0 || imageSource.videoHeight === 0) {
      return null;
    }
    return new VideoElementFrame(imageSource);
  }

  private constructor(imageSource: HTMLVideoElement) {
    this.m_imageSource = imageSource;
    this.m_resolution = Resolution.build(imageSource.videoWidth, imageSource.videoHeight);
  }

  public toString(): string {
    return `VideoElement ${this.m_resolution.toString()}`;
  }
}

export class ThrottledVideoSource implements IVideoFrameSource {
  private readonly m_logger: ILogger;
  private readonly m_videoFrameSource: IVideoFrameSource;
  private readonly m_minMsBetweenFrames: number;
  private m_started: boolean;
  private m_getFramePromise: PromiseCompletionSource<IVideoFrame | Cancellation>;

  public get IsStarted(): boolean {
    return this.m_started;
  }

  public constructor(logger: ILogger, videoFrameSource: IVideoFrameSource, maxFps: number) {
    this.m_logger = logger.subLogger('ThrottledVideoSource');
    this.m_videoFrameSource = videoFrameSource;
    this.m_minMsBetweenFrames = 1000 / maxFps;
    this.m_started = false;
    this.m_getFramePromise = new PromiseCompletionSource<IVideoFrame | Cancellation>();

    this.m_logger.debug?.trace(`Created to throttle at ${this.m_minMsBetweenFrames}ms between frames (${maxFps} fps)`);
  }

  public dispose(): void {
    this.stop();
    this.m_videoFrameSource.dispose();
    this.m_logger.debug?.trace('Disposed');
  }

  public start(): void {
    if (this.m_started) {
      this.m_logger.intense?.trace('Already started');
      return;
    }

    this.m_logger.info?.trace('start()');
    this.m_started = true;
    this.videoFrameSourceListenLoop();
  }

  public stop(): void {
    this.m_logger.info?.trace('stop()');
    this.m_started = false;
  }

  public getImage(): Promise<IVideoFrame | Cancellation> {
    return this.m_getFramePromise.Promise;
  }

  private async videoFrameSourceListenLoop() {
    this.m_logger.debug?.trace('Start of videoFrameSourceListenLoop()');

    while (this.m_started) {
      const result = await this.m_videoFrameSource.getImage();
      const currentFrameReception = new Date().getTime();
      const nextFrameMinTime = currentFrameReception + this.m_minMsBetweenFrames;
      this.m_logger.intense?.trace(`Received a frame at: ${currentFrameReception}`);

      const toResolve = this.m_getFramePromise;
      this.m_getFramePromise = new PromiseCompletionSource<IVideoFrame | Cancellation>();
      toResolve.resolve(result);

      //Throttle if necessary
      const toWait = nextFrameMinTime - new Date().getTime();
      this.m_logger.intense?.trace(`NextFrame not until: ${nextFrameMinTime} Sleeping ${toWait}`);
      if (toWait > 0) {
        await Utils.delay(toWait);
        if (!this.m_started) {
          return;
        }
        this.m_logger.intense?.trace(`Sleeping done. It is now : ${new Date().getTime()}. Ready for a new frame`);
      }
    }

    this.m_logger.debug?.trace('End of videoFrameSourceListenLoop()');
  }
}
