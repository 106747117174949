<gen-melted-modal
    id="{{ optionsModal }}"
    genTitle="{{ 'STE_TITLE_OPTIONS' | translate }}"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_BUTTON_SAVE' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genOnDefaultAction]="onModalDefaultAction"
    [genOnAlternativeAction]="onModalAlternativeAction"
    [genOnDismiss]="onModalAlternativeAction"
    appFocusModalWhenOpen
    genKeyboardNavigation
    (genArrowUp)="previousTab($event)"
    (genArrowDown)="nextTab($event)"
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <gen-app>
            <!-- <gen-header>
                <gen-grid genColumns="3">
                    <gen-grid-item genColSpan="2">
                        <gen-search-input ngModel id="input1" placeholder="{{ 'STE_ACTION_SEARCH' | translate }}" style="padding-bottom: 10px"></gen-search-input>
                    </gen-grid-item>
                </gen-grid>
            </gen-header> -->

            <gen-aside id="options-categories" [genPosition]="Position.Aside.Left">
                <gen-tabs
                    [genOrientation]="Orientation.Vertical"
                    [genItemsSource]="categories"
                    [(genSelectedItem)]="currentCategory"
                    (genSelectedItemChange)="onCategoryChanged($event)"
                    tabindex="0"
                >
                </gen-tabs>
            </gen-aside>

            <gen-main>
                <!-- Options panes -->
                <div class="category-header">
                    <gen-text class="category-title">{{ currentCategoryDescriptor?.title }}</gen-text>
                    <gen-text [flavor]="TextFlavor.Secondary" class="category-description">{{ currentCategoryDescriptor?.description ?? '' }}</gen-text>
                </div>
                <div class="gen-tab-content">
                    <app-plugin-host *ngFor="let optionPlugin of currentOptionPlugins" [pluginItem]="optionPlugin"></app-plugin-host>
                </div>
            </gen-main>
        </gen-app>
    </gen-melted-modal-body>
    <!--<gen-melted-additional-footer>
        <gen-item genText="{{ 'STE_MESSAGE_INFO_SETTINGSMANAGEDBYORGANIZATION' | translate }}" [genIcon]="MeltedIcon.Lock" class="no-pointer"></gen-item>
    </gen-melted-additional-footer>-->
</gen-melted-modal>

<gen-alert
    [id]="optionsConfirmRefreshAlertId"
    genText="{{ 'STE_MESSAGE_WARNING_PAGEREFRESH' | translate }}"
    genSecondaryText="{{ 'STE_MESSAGE_WARNING_UNSAVEDWORKDISCARDED' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_CONTINUE' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genFlavor]="Flavor.Alert.Warning"
    [genIsDefaultActionDisabled]="false"
>
</gen-alert>
