// Autogenerated: v2/help/Entities/ApiRole?codegen=ts&InterfaceOnly=True&workflow=False on 2022-02-24 3:03:12 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity, EntityFields } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class RoleEntityFields extends EntityFields {
    public static apiUrlPrefixesField = 'ApiUrlPrefixes';
    public static automaticBackupFileCountField = 'AutomaticBackupFileCount';
    public static automaticBackupFrequencyField = 'AutomaticBackupFrequency';
    public static automaticBackupTimeField = 'AutomaticBackupTime';
    public static backupFolderField = 'BackupFolder';
    public static databaseFolderField = 'DatabaseFolder';
    public static databaseInstanceField = 'DatabaseInstance';
    public static databaseNameField = 'DatabaseName';
    public static disabledField = 'Disabled';
    public static enableAutomaticBackupField = 'EnableAutomaticBackup';
    public static enableBackupCompressionField = 'EnableBackupCompression';
    public static encryptDatabaseConnectionField = 'EncryptDatabaseConnection';
    public static enforceDatabaseServerCertificateField = 'EnforceDatabaseServerCertificate';
    public static forceExecutionByPriorityField = 'ForceExecutionByPriority';
    public static groupField = 'Group';
    public static notificationsDatabaseUsageField = 'NotificationsDatabaseUsage';
    public static notificationsDatabaseUsageAsPercentageField = 'NotificationsDatabaseUsageAsPercentage';
    public static notificationsDiskSpaceField = 'NotificationsDiskSpace';
    public static notificationsRemainingGbField = 'NotificationsRemainingGb';
    public static notifyOnlyWhenLimitIsReachedField = 'NotifyOnlyWhenLimitIsReached';
    public static overriddenLoadingStrategyField = 'OverriddenLoadingStrategy';
    public static roleSubTypeField = 'RoleSubType';
    public static roleTypeIdField = 'RoleTypeId';
    public static serverField = 'Server';
    public static specificXmlField = 'SpecificXml';
}

// #endregion Fields

// #region Inner classes

export interface IDatabaseInfo extends IFieldObject {
    database: string;
    server: string;
    username: string;
    password: string;
}

export interface ISecureData extends IFieldObject {
    id: string;
    payload: string;
}

export interface IRoleServer extends IFieldObject {
    id: IGuid;
    roleServerNetworkInterface: string;
    roleServerOrder: number | null;
}

export interface IDatabaseCreateInfo extends IFieldObject {
    overwrite: boolean;
    folder: string;
    logFolder: string;
    database: string;
    server: string;
    username: string;
    password: string;
}

// #endregion Inner classes

export interface IRoleEntity extends IEntity {
    // #region REST Properties

    apiUrlPrefixes: ObservableCollection<string>;

    automaticBackupFileCount: number;

    automaticBackupFrequency: string;

    automaticBackupTime: number;

    backupFolder: string;

    databaseFolder: string;

    databaseInstance: string;

    databaseName: string;

    disabled: boolean;

    enableAutomaticBackup: boolean;

    enableBackupCompression: boolean;

    encryptDatabaseConnection: boolean;

    enforceDatabaseServerCertificate: boolean;

    forceExecutionByPriority: boolean;

    group: string;

    notificationsDatabaseUsage: boolean;

    notificationsDatabaseUsageAsPercentage: number;

    notificationsDiskSpace: boolean;

    notificationsRemainingGb: number;

    notifyOnlyWhenLimitIsReached: boolean;

    overriddenLoadingStrategy: string;

    roleSubType: IGuid;

    roleTypeId: IGuid;

    server: IGuid;

    specificXml: string;

    // #endregion REST Properties

    // #region REST Methods

    getAgentsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    createDatabaseAsync(value: IDatabaseCreateInfo, transaction?: ITransaction): Promise<IRestResponse | null>;
    createDatabaseBackupAsync(folder: string, enableCompression: boolean, transaction?: ITransaction): Promise<IRestResponse | null>;
    getRoleDatabaseStatusAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;
    deleteDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getOwnershipEntitiesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    restoreDatabaseBackupAsync(file: string, transaction?: ITransaction): Promise<IRestResponse | null>;
    getRoleServersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRoleServer> | null>;
    deleteSecureDataAsync(id: string, transaction?: ITransaction | null): Promise<IRestResponse>;
    getSecureDataAsync(id: string, transaction?: ITransaction | null): Promise<ISecureData | null>;
    addSecureDataAsync(id: string, value: ISecureData, transaction?: ITransaction | null): Promise<ISecureData | null>;
    setDatabaseAsync(value: IDatabaseInfo, transaction?: ITransaction): Promise<IRestResponse | null>;
    upgradeDatabaseAsync(transaction?: ITransaction): Promise<IRestResponse | null>;

    // #endregion REST Methods

}

