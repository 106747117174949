// Autogenerated: v2/help/Entities/ApiAuthenticationRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-26 3:26:01 PM
import { RoleEntity } from './RoleEntity';
import { IAuthenticationRoleEntity, INetworkEndPoint } from '../../Interface/IAuthenticationRoleEntity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IEntity } from '../../Interface/IEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import { FieldObject } from '../../../Helpers/FieldObject';

// #region Inner classes

export class NetworkEndPoint extends FieldObject implements INetworkEndPoint {
    // MetadataEndpoints
    public get metadataEndpoints(): ObservableCollection<string> {
        return this.getFieldArray<string>('MetadataEndpoints');
    }
    public set metadataEndpoints(value: ObservableCollection<string>) {
        this.setFieldArray<string>('MetadataEndpoints', value);
    }
    // RedirectUris
    public get redirectUris(): ObservableCollection<string> {
        return this.getFieldArray<string>('RedirectUris');
    }
    public set redirectUris(value: ObservableCollection<string>) {
        this.setFieldArray<string>('RedirectUris', value);
    }
    // LogoutUris
    public get logoutUris(): ObservableCollection<string> {
        return this.getFieldArray<string>('LogoutUris');
    }
    public set logoutUris(value: ObservableCollection<string>) {
        this.setFieldArray<string>('LogoutUris', value);
    }

    public initializeAllFields(): void {
        this.setFieldArray<string>('MetadataEndpoints', new ObservableCollection<string>());
        this.setFieldArray<string>('RedirectUris', new ObservableCollection<string>());
        this.setFieldArray<string>('LogoutUris', new ObservableCollection<string>());
    }
}

// #endregion Inner classes

export class AuthenticationRoleEntity extends RoleEntity implements IAuthenticationRoleEntity {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getNetworkEndPointsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<INetworkEndPoint> | null> {
        return this.getFieldObjectRelationAsync<NetworkEndPoint, INetworkEndPoint>(NetworkEndPoint, 'NetworkEndPoints', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async getUsergroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Usergroups', 'Id', false, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

