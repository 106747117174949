// Autogenerated: v2/help/Entities/ApiPluginRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { RoleEntity } from './RoleEntity';
import { Diagnostic } from '../Entity';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IRoleAgentApplication, ISlotInfo, IDbUpgradeItem, IDatabaseScript, IDatabaseConnectionInfo, IPluginRoleEntity, IAgentUrl, IPluginRoleConfig, IRestVersion } from '../../Interface/IPluginRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';
import { IDiagnostic } from '../../Interface/IEntity';

// #region Inner classes

export class SlotInfo extends FieldObject implements ISlotInfo {
    // Count
    public get count(): number {
        return this.getField<number>('Count');
    }
    public set count(value: number) {
        this.setField<number>('Count', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Count', 0);
    }
}

export class RoleAgentApplication extends FieldObject implements IRoleAgentApplication {
    // AppId
    public get appId(): IGuid {
        return this.getFieldGuid('AppId');
    }
    public set appId(value: IGuid) {
        this.setFieldGuid('AppId', value);
    }
    // ServerId
    public get serverId(): IGuid {
        return this.getFieldGuid('ServerId');
    }
    public set serverId(value: IGuid) {
        this.setFieldGuid('ServerId', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('AppId', SafeGuid.EMPTY);
        this.setFieldGuid('ServerId', SafeGuid.EMPTY);
    }
}

export class AgentUrl extends FieldObject implements IAgentUrl {
    // ServerId
    public get serverId(): IGuid {
        return this.getFieldGuid('ServerId');
    }
    public set serverId(value: IGuid) {
        this.setFieldGuid('ServerId', value);
    }
    // ApplicationPath
    public get applicationPath(): string {
        return this.getField<string>('ApplicationPath');
    }
    public set applicationPath(value: string) {
        this.setField<string>('ApplicationPath', value);
    }
    // RestUrl
    public get restUrl(): string {
        return this.getField<string>('RestUrl');
    }
    public set restUrl(value: string) {
        this.setField<string>('RestUrl', value);
    }

    public initializeAllFields(): void {
        this.setFieldGuid('ServerId', SafeGuid.EMPTY);
        this.setField<string>('ApplicationPath', '');
        this.setField<string>('RestUrl', '');
    }
}

export class RestVersion extends FieldObject implements IRestVersion {
    // Minor
    public get minor(): number {
        return this.getField<number>('Minor');
    }
    public set minor(value: number) {
        this.setField<number>('Minor', value);
    }
    // Major
    public get major(): number {
        return this.getField<number>('Major');
    }
    public set major(value: number) {
        this.setField<number>('Major', value);
    }
    // Build
    public get build(): number {
        return this.getField<number>('Build');
    }
    public set build(value: number) {
        this.setField<number>('Build', value);
    }
    // Revision
    public get revision(): number {
        return this.getField<number>('Revision');
    }
    public set revision(value: number) {
        this.setField<number>('Revision', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('Minor', 0);
        this.setField<number>('Major', 0);
        this.setField<number>('Build', 0);
        this.setField<number>('Revision', 0);
    }
}

export class PluginRoleConfig extends FieldObject implements IPluginRoleConfig {
    // Version
    public get version(): IRestVersion {
        return this.getFieldObject<RestVersion, IRestVersion>(RestVersion, 'Version');
    }
    public set version(value: IRestVersion) {
        this.setFieldObject<IRestVersion>('Version', value);
    }
    // Urls
    public get urls(): ObservableCollection<IAgentUrl> {
        return this.getFieldObjectArray<AgentUrl, IAgentUrl>(AgentUrl, 'Urls');
    }
    public set urls(value: ObservableCollection<IAgentUrl>) {
        this.setFieldObjectArray('Urls', value);
    }
    // ExternalPlugin
    public get externalPlugin(): boolean {
        return this.getField<boolean>('ExternalPlugin');
    }
    public set externalPlugin(value: boolean) {
        this.setField<boolean>('ExternalPlugin', value);
    }
    // ConfigToolData
    public get configToolData(): string {
        return this.getField<string>('ConfigToolData');
    }
    public set configToolData(value: string) {
        this.setField<string>('ConfigToolData', value);
    }
    // ConfigToolHtml
    public get configToolHtml(): string {
        return this.getField<string>('ConfigToolHtml');
    }
    public set configToolHtml(value: string) {
        this.setField<string>('ConfigToolHtml', value);
    }
    // ConfigToolHtmlZip
    public get configToolHtmlZip(): IGuid {
        return this.getFieldGuid('ConfigToolHtmlZip');
    }
    public set configToolHtmlZip(value: IGuid) {
        this.setFieldGuid('ConfigToolHtmlZip', value);
    }
    // ConfigToolHtmlZipMd5
    public get configToolHtmlZipMd5(): string {
        return this.getField<string>('ConfigToolHtmlZipMd5');
    }
    public set configToolHtmlZipMd5(value: string) {
        this.setField<string>('ConfigToolHtmlZipMd5', value);
    }
    // RoleCustomData
    public get roleCustomData(): string {
        return this.getField<string>('RoleCustomData');
    }
    public set roleCustomData(value: string) {
        this.setField<string>('RoleCustomData', value);
    }
    // SupportDatabase
    public get supportDatabase(): boolean {
        return this.getField<boolean>('SupportDatabase');
    }
    public set supportDatabase(value: boolean) {
        this.setField<boolean>('SupportDatabase', value);
    }
    // NameSpace
    public get nameSpace(): string {
        return this.getField<string>('NameSpace');
    }
    public set nameSpace(value: string) {
        this.setField<string>('NameSpace', value);
    }

    public initializeAllFields(): void {
        const objVersion = new RestVersion();
        objVersion.initializeAllFields();
        this.setFieldObject('Version', objVersion);
        this.setFieldArray<IAgentUrl>('Urls', new ObservableCollection<IAgentUrl>());
        this.setField<boolean>('ExternalPlugin', false);
        this.setField<string>('ConfigToolData', '');
        this.setField<string>('ConfigToolHtml', '');
        this.setFieldGuid('ConfigToolHtmlZip', SafeGuid.EMPTY);
        this.setField<string>('ConfigToolHtmlZipMd5', '');
        this.setField<string>('RoleCustomData', '');
        this.setField<boolean>('SupportDatabase', false);
        this.setField<string>('NameSpace', '');
    }
}

export class DbUpgradeItem extends FieldObject implements IDbUpgradeItem {
    // SourceVersion
    public get sourceVersion(): number {
        return this.getField<number>('SourceVersion');
    }
    public set sourceVersion(value: number) {
        this.setField<number>('SourceVersion', value);
    }
    // TargetVersion
    public get targetVersion(): number {
        return this.getField<number>('TargetVersion');
    }
    public set targetVersion(value: number) {
        this.setField<number>('TargetVersion', value);
    }
    // UpgradeScript
    public get upgradeScript(): string {
        return this.getField<string>('UpgradeScript');
    }
    public set upgradeScript(value: string) {
        this.setField<string>('UpgradeScript', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('SourceVersion', 0);
        this.setField<number>('TargetVersion', 0);
        this.setField<string>('UpgradeScript', '');
    }
}

export class DatabaseScript extends FieldObject implements IDatabaseScript {
    // ExpectedVersion
    public get expectedVersion(): number {
        return this.getField<number>('ExpectedVersion');
    }
    public set expectedVersion(value: number) {
        this.setField<number>('ExpectedVersion', value);
    }
    // CreationScript
    public get creationScript(): string {
        return this.getField<string>('CreationScript');
    }
    public set creationScript(value: string) {
        this.setField<string>('CreationScript', value);
    }
    // UpgradeItems
    public get upgradeItems(): ObservableCollection<IDbUpgradeItem> {
        return this.getFieldObjectArray<DbUpgradeItem, IDbUpgradeItem>(DbUpgradeItem, 'UpgradeItems');
    }
    public set upgradeItems(value: ObservableCollection<IDbUpgradeItem>) {
        this.setFieldObjectArray('UpgradeItems', value);
    }

    public initializeAllFields(): void {
        this.setField<number>('ExpectedVersion', 0);
        this.setField<string>('CreationScript', '');
        this.setFieldArray<IDbUpgradeItem>('UpgradeItems', new ObservableCollection<IDbUpgradeItem>());
    }
}

export class DatabaseConnectionInfo extends FieldObject implements IDatabaseConnectionInfo {
    // DatabaseInstance
    public get databaseInstance(): string {
        return this.getField<string>('DatabaseInstance');
    }
    public set databaseInstance(value: string) {
        this.setField<string>('DatabaseInstance', value);
    }
    // DatabaseName
    public get databaseName(): string {
        return this.getField<string>('DatabaseName');
    }
    public set databaseName(value: string) {
        this.setField<string>('DatabaseName', value);
    }
    // ConnectionString
    public get connectionString(): string {
        return this.getField<string>('ConnectionString');
    }
    public set connectionString(value: string) {
        this.setField<string>('ConnectionString', value);
    }

    public initializeAllFields(): void {
        this.setField<string>('DatabaseInstance', '');
        this.setField<string>('DatabaseName', '');
        this.setField<string>('ConnectionString', '');
    }
}

// #endregion Inner classes

export class PluginRoleEntity extends RoleEntity implements IPluginRoleEntity {
    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getDatabaseConfigurationAsync(transaction?: ITransaction | null): Promise<IDatabaseConnectionInfo | null> {
        return await this.getDataRelationAsync<DatabaseConnectionInfo>(DatabaseConnectionInfo, 'DatabaseConnectionInfo', false, ValidFlags.Specific, transaction);
    }

    public async setDatabaseScriptsAsync(value: IDatabaseScript, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };

        return this.executeRequestTransactionAsync<IRestResponse>('DatabaseScripts', 'POST', value, transaction, responseHandler);
    }

    public async getDiagnosticsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDiagnostic> | null> {
        return this.getFieldObjectRelationAsync<Diagnostic, IDiagnostic>(Diagnostic, 'Diagnostics', 'Id', false, false, ValidFlags.None, transaction);
    }

    public async freeSlotAsync(groupId: string, slotId: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('groupId', groupId);
        inParam.setField('slotId', slotId);

        return this.executeRequestTransactionAsync<IRestResponse>('FreeSlot', 'POST', inParam, transaction, responseHandler);
    }

    public async modifyLicenseItemAsync(item: string, count: number, tag: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('item', item);
        inParam.setField('count', count);
        inParam.setField('tag', tag);

        return this.executeRequestTransactionAsync<IRestResponse>('LicenseItem', 'POST', inParam, transaction, responseHandler);
    }

    public async getPluginRoleConfigAsync(transaction?: ITransaction | null): Promise<IPluginRoleConfig | null> {
        return await this.getDataRelationAsync<PluginRoleConfig>(PluginRoleConfig, 'PluginRoleConfig', true, ValidFlags.None, transaction);
    }

    public async reserveSlotAsync(maxCountAllowed: number, groupId: string, slotId: string, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('maxCountAllowed', maxCountAllowed);
        inParam.setField('groupId', groupId);
        inParam.setField('slotId', slotId);

        return this.executeRequestTransactionAsync<IRestResponse>('ReserveSlot', 'POST', inParam, transaction, responseHandler);
    }

    public async getRoleAgentApplicationsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRoleAgentApplication> | null> {
        return this.getFieldObjectRelationAsync<RoleAgentApplication, IRoleAgentApplication>(RoleAgentApplication, 'RoleAgentApplication', 'Id', true, false, ValidFlags.None, transaction);
    }

    public async getFreeSlotAsync(id: string, transaction?: ITransaction | null): Promise<ISlotInfo | null> {
        return await this.getDataRelationAsync<SlotInfo>(SlotInfo, 'SlotCount/' + id, false, ValidFlags.None, transaction);
    }

    public async startOpenIdServerAsync(applicationPath: string, authCallbackUri: string, port: number, transaction?: ITransaction): Promise<IRestResponse | null> {
        const responseHandler = (response: IRestResponse): Promise<IRestResponse> => {
            if (response.statusCode === HttpStatusCode.OK) {
                return new Promise<IRestResponse>(function (resolve, reject) { resolve(response); });
            } else {
                return new Promise<IRestResponse>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setField('applicationPath', applicationPath);
        inParam.setField('authCallbackUri', authCallbackUri);
        inParam.setField('port', port);

        return this.executeRequestTransactionAsync<IRestResponse>('StartOpenIdServer', 'POST', inParam, transaction, responseHandler);
    }

    // #endregion REST Methods

}

