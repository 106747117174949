// Autogenerated: v2/help/Entities/ApiDirectoryFailoverRole?codegen=ts&InterfaceOnly=False&workflow=False on 2022-07-06 9:10:40 AM
import { RoleEntity } from './RoleEntity';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IDirectoryFailoverRoleServer, IDirectoryFailoverRoleEntity, DirectoryFailoverRoleEntityFields } from '../../Interface/IDirectoryFailOverRoleEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class DirectoryFailoverRoleServer extends FieldObject implements IDirectoryFailoverRoleServer {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // IsGatewayOnly
    public get isGatewayOnly(): boolean {
        return this.getField<boolean>('IsGatewayOnly');
    }
    public set isGatewayOnly(value: boolean) {
        this.setField<boolean>('IsGatewayOnly', value);
    }
    // IsDisasterRecovery
    public get isDisasterRecovery(): boolean | null {
        return this.getNullableField<boolean>('IsDisasterRecovery');
    }
    public set isDisasterRecovery(value: boolean | null) {
        this.setNullableField<boolean>('IsDisasterRecovery', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<boolean>('IsGatewayOnly', false);
        this.setField<boolean>('IsDisasterRecovery', false);
    }
}

// #endregion Inner classes

export class DirectoryFailoverRoleEntity extends RoleEntity implements IDirectoryFailoverRoleEntity {
    // #region REST Properties

    // ForceDirectoryOnMaster
    public get forceDirectoryOnMaster(): boolean {
        return this.getField<boolean>(DirectoryFailoverRoleEntityFields.forceDirectoryOnMasterField);
    }
    public set forceDirectoryOnMaster(value: boolean) {
        this.setField<boolean>(DirectoryFailoverRoleEntityFields.forceDirectoryOnMasterField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region REST Methods

    public async getServersAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IDirectoryFailoverRoleServer> | null> {
        return this.getFieldObjectRelationAsync<DirectoryFailoverRoleServer, IDirectoryFailoverRoleServer>(DirectoryFailoverRoleServer, 'Servers', 'Id', false, true, ValidFlags.None, transaction);
    }

    public async getValidationKeyAsync(servers: ObservableCollection<IGuid>, transaction?: ITransaction): Promise<string | null> {
        const responseHandler = (response: IRestResponse): Promise<string> => {
            if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
                const result = response.body as string;
                return new Promise<string>(function (resolve, reject) { resolve(result); });
            } else {
                return new Promise<string>(function (resolve, reject) { reject(response); });
            }
        };
        const inParam = new FieldObject();
        inParam.setFieldArrayGuid('servers', servers);

        return this.executeRequestTransactionAsync<string>('ValidationKey', 'POST', inParam, transaction, responseHandler);
    }

    // #endregion REST Methods

}

