// Autogenerated: v2/help/enumerations/ApiCameraTypes?codegen=ts&showresources=False on 2020-07-23 3:40:04 PM

export class CameraTypes {
    public static None = 'None';
    public static PTZCamera = 'PTZCamera';
    public static VirtualCamera = 'VirtualCamera';
    public static PTZVirtualCamera = 'PTZVirtualCamera';
    public static Sequence = 'Sequence';
    public static GhostCamera = 'GhostCamera';
    public static PanoramicCamera = 'PanoramicCamera';
    public static WearableCamera = 'WearableCamera';

    public static readonly values = [
        CameraTypes.None,
        CameraTypes.PTZCamera,
        CameraTypes.VirtualCamera,
        CameraTypes.PTZVirtualCamera,
        CameraTypes.Sequence,
        CameraTypes.GhostCamera,
        CameraTypes.PanoramicCamera,
        CameraTypes.WearableCamera
    ];
}
