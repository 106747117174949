// Autogenerated: v2/help/Entities/ApiInputDevice?codegen=ts&InterfaceOnly=True&workflow=False on 2020-12-11 12:11:51 PM
import { DeviceEntityFields, IDeviceEntity } from './IDeviceEntity';

// #region Fields

export class InputDeviceEntityFields extends DeviceEntityFields {
    public static inputStateField = 'InputState';
}

// #endregion Fields

export interface IInputDeviceEntity extends IDeviceEntity {
    // #region REST Properties

    inputState: string;

    // #endregion REST Properties

}

