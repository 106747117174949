<gen-spinner *ngIf="isLoading">{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
<div *ngIf="!isLoading">
    <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_OVERVIEW' | translate }}</gen-text>
    <br />
    <gen-grid [genColumns]="2">
        <gen-grid-item>
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_CREDENTIAL_ACTIVATEDON' | translate }}</gen-text>
            <gen-text [flavor]="TextFlavor.Normal">{{ activationDate }}</gen-text>
        </gen-grid-item>
        <gen-grid-item>
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_CREDENTIAL_EXPIRINGON' | translate }}</gen-text>
            <gen-text [flavor]="TextFlavor.Normal">{{ expirationDate }}</gen-text>
        </gen-grid-item>
    </gen-grid>

    <!-- Disabled the show more for now as we have nothing more to show :) -->
    <!--<gen-button class="show-more" [flavor]="ButtonFlavor.Flat" >{{ 'STE_BUTTON_SHOWMORE' | translate }}</gen-button>-->
</div>
