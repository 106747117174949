import { IGuid, SafeGuid } from 'safeguid';
import { ProcedureStepActionType } from '../procedure-step-action-type';
import { MCCommandBase, MCCommandType } from './command';

const commandType = MCCommandType.AnswerProcedureStep;

export class AnswerProcedureCommand extends MCCommandBase {
    type = commandType;

    constructor(incidentId: IGuid, actionType: ProcedureStepActionType, optionId: IGuid, comment: string) {
        super(
            `/v2/Incidents/${incidentId.toString()}`,
            JSON.stringify({
                Type: commandType,
                ActionType: actionType,
                OptionId: optionId?.toString(),
                Comment: comment ?? '',
            })
        );
    }
}
