// Autogenerated: v2/help/Entities/ApiUser?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:09 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from '../Interface/IRestResponse';
import { IEntity } from '../Interface/IEntity';
import { IFieldObject } from './IFieldObject';
import { IPrivilegedEntity, PrivilegedEntityFields } from './IPrivilegedEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class UserEntityFields extends PrivilegedEntityFields {
    public static appendMapToIncidentContentField = 'AppendMapToIncidentContent';
    public static areIncidentMonitoringTasksSynchronizedField = 'AreIncidentMonitoringTasksSynchronized';
    public static autoLogOffIsActivatedField = 'AutoLogOffIsActivated';
    public static automaticallyChangeIncidentStateField = 'AutomaticallyChangeIncidentState';
    public static defaultMapIdField = 'DefaultMapId';
    public static defaultMeasurementSystemField = 'DefaultMeasurementSystem';
    public static firstNameField = 'FirstName';
    public static hideAreaViewForIncidentField = 'HideAreaViewForIncident';
    public static incidentContentSpecificScreenField = 'IncidentContentSpecificScreen';
    public static includeAuditMetadataField = 'IncludeAuditMetadata';
    public static isActiveField = 'IsActive';
    public static isDomainDisplayedField = 'IsDomainDisplayed';
    public static isOptionsAlreadyImportedField = 'IsOptionsAlreadyImported';
    public static isPrivateTasksSortedByTypeField = 'IsPrivateTasksSortedByType';
    public static isPublicTasksSortedByTypeField = 'IsPublicTasksSortedByType';
    public static lastMobileApplicationIdField = 'LastMobileApplicationId';
    public static lastNameField = 'LastName';
    public static lastSelectedBadgeTemplateField = 'LastSelectedBadgeTemplate';
    public static lastSelectedBypassAntipassbackRulesOptionField = 'LastSelectedBypassAntipassbackRulesOption';
    public static lastSelectedCameraForSnapshotField = 'LastSelectedCameraForSnapshot';
    public static lastSelectedCardholderGroupField = 'LastSelectedCardholderGroup';
    public static lastSelectedCredentialConfigurationField = 'LastSelectedCredentialConfiguration';
    public static lastSelectedCredentialFormatField = 'LastSelectedCredentialFormat';
    public static lastSelectedPartitionField = 'LastSelectedPartition';
    public static lastSelectedSaveAsPublicTaskField = 'LastSelectedSaveAsPublicTask';
    public static lastSelectedTemporaryCardExpirationField = 'LastSelectedTemporaryCardExpiration';
    public static lastSelectedUseExtendedGrantTimeOptionField = 'LastSelectedUseExtendedGrantTimeOption';
    public static lastSelectedVisitorEscortField = 'LastSelectedVisitorEscort';
    public static lastSelectedVisitorEscort2Field = 'LastSelectedVisitorEscort2';
    public static lastSelectedVisitorEscortMandatoryField = 'LastSelectedVisitorEscortMandatory';
    public static lastSelectedVisitorExpirationTimeField = 'LastSelectedVisitorExpirationTime';
    public static lastSelectedVisitorGroupField = 'LastSelectedVisitorGroup';
    public static maxConcurrentLogonsField = 'MaxConcurrentLogons';
    public static measurementSystemField = 'MeasurementSystem';
    public static minumumIncidentPriorityInTrayField = 'MinumumIncidentPriorityInTray';
    public static needChangePasswordField = 'NeedChangePassword';
    public static obfuscateEntityNamesField = 'ObfuscateEntityNames';
    public static openIncidentContentInSpecificScreenField = 'OpenIncidentContentInSpecificScreen';
    public static passwordField = 'Password';
    public static passwordExpirationField = 'PasswordExpiration';
    public static passwordLastModifiedField = 'PasswordLastModified';
    public static queryUsingDeviceTimeZonesField = 'QueryUsingDeviceTimeZones';
    public static showAllIncidentsOnDefaultMapField = 'ShowAllIncidentsOnDefaultMap';
    public static showMapAssistantField = 'ShowMapAssistant';
    public static skipPtzEvaluationField = 'SkipPtzEvaluation';
    public static specificTimezoneField = 'SpecificTimezone';
    public static thumbnailIdField = 'ThumbnailId';
    public static useDeviceTimezoneField = 'UseDeviceTimezone';
    public static useTimezoneAbbreviationsField = 'UseTimezoneAbbreviations';
    public static webClientLanguageField = 'WebClientLanguage';
}

// #endregion Fields

// #region Inner classes

export interface IWebClientTask extends IFieldObject {
    path: string;
    guid: IGuid;
    name: string;
    taskType: IGuid;
    taskSubType: IGuid;
    context: string;
    isSystemTask: boolean;
}

export interface IUserSetting extends IFieldObject {
    id: IGuid;
    value: string;
}

export interface ILogOnSchedule extends IFieldObject {
    id: IGuid;
    allowed: boolean;
}

export interface IHotAction extends IFieldObject {
    id: IGuid;
    description: string;
}

export interface IHasAccessResult extends IFieldObject {
    id: IGuid;
    hasAccess: boolean;
}

// #endregion Inner classes

export interface IUserEntity extends IPrivilegedEntity {
    // #region REST Properties

    appendMapToIncidentContent: boolean;

    areIncidentMonitoringTasksSynchronized: boolean;

    autoLogOffIsActivated: boolean;

    automaticallyChangeIncidentState: boolean;

    defaultMapId: IGuid;

    defaultMeasurementSystem: string;

    firstName: string;

    hideAreaViewForIncident: boolean;

    incidentContentSpecificScreen: number;

    readonly includeAuditMetadata: boolean;

    isActive: boolean;

    readonly isDomainDisplayed: boolean;

    isOptionsAlreadyImported: boolean;

    isPrivateTasksSortedByType: boolean;

    isPublicTasksSortedByType: boolean;

    lastMobileApplicationId: string;

    lastName: string;

    lastSelectedBadgeTemplate: IGuid;

    lastSelectedBypassAntipassbackRulesOption: boolean;

    lastSelectedCameraForSnapshot: IGuid;

    lastSelectedCardholderGroup: IGuid;

    lastSelectedCredentialConfiguration: number;

    lastSelectedCredentialFormat: IGuid;

    lastSelectedPartition: IGuid;

    lastSelectedSaveAsPublicTask: boolean;

    lastSelectedTemporaryCardExpiration: number;

    lastSelectedUseExtendedGrantTimeOption: boolean;

    lastSelectedVisitorEscort: IGuid;

    lastSelectedVisitorEscort2: IGuid;

    lastSelectedVisitorEscortMandatory: boolean;

    lastSelectedVisitorExpirationTime: number;

    lastSelectedVisitorGroup: IGuid;

    maxConcurrentLogons: number;

    measurementSystem: string;

    minumumIncidentPriorityInTray: IGuid;

    needChangePassword: boolean;

    obfuscateEntityNames: boolean;

    openIncidentContentInSpecificScreen: boolean;

    password: string;

    passwordExpiration: number;

    readonly passwordLastModified: Date;

    readonly queryUsingDeviceTimeZones: boolean;

    showAllIncidentsOnDefaultMap: boolean;

    showMapAssistant: boolean;

    skipPtzEvaluation: boolean;

    specificTimezone: string;

    thumbnailId: IGuid;

    useDeviceTimezone: boolean;

    useTimezoneAbbreviations: boolean;

    webClientLanguage: string;

    // #endregion REST Properties

    // #region REST Methods

    getApplicationLoggedAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getFavoritesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getFavoritesCTAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getFavoritesMapsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    addForceUserStatusAsync(isActive: boolean, transaction?: ITransaction): Promise<IRestResponse | null>;
    hasAccessAsync(entityId: IGuid, transaction?: ITransaction): Promise<boolean | null>;
    hasAccessListAsync(ids: ObservableCollection<IGuid>, transaction?: ITransaction): Promise<Array<IHasAccessResult> | null>;
    getHotActionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IHotAction> | null>;
    getLogonSchedulesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ILogOnSchedule> | null>;
    getRecentlyUsedAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getRecentlyUsedCTAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    safeUpdatePasswordAsync(newValue: string, oldValue: string, transaction?: ITransaction): Promise<IRestResponse | null>;
    getUserSettingsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IUserSetting> | null>;
    getWebClientTasksAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IWebClientTask> | null>;

    // #endregion REST Methods

}

