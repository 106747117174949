// Autogenerated: v2/help/Classes/ApiCredentialStatusEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { TimezoneEvent } from './TimezoneEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { ICredentialStatusEvent } from '../Interface/ICredentialStatusEvent';
import { ObservableCollection } from '../../Helpers/ObservableCollection';

export class CredentialStatusEvent extends TimezoneEvent implements ICredentialStatusEvent {
    // #region REST Properties

    // AccessPointGuid
    public get accessPointGuid(): IGuid {
        return this.getFieldGuid('AccessPointGuid');
    }
    public set accessPointGuid(value: IGuid) {
        this.setFieldGuid('AccessPointGuid', value);
    }

    // AccessPointGuidDesc
    public get accessPointGuidDesc(): string {
        return this.getField<string>('AccessPointGuidDesc');
    }
    public set accessPointGuidDesc(value: string) {
        this.setField<string>('AccessPointGuidDesc', value);
    }

    // DoorSideGuid
    public get doorSideGuid(): IGuid {
        return this.getFieldGuid('DoorSideGuid');
    }
    public set doorSideGuid(value: IGuid) {
        this.setFieldGuid('DoorSideGuid', value);
    }

    // DoorSideGuidDesc
    public get doorSideGuidDesc(): string {
        return this.getField<string>('DoorSideGuidDesc');
    }
    public set doorSideGuidDesc(value: string) {
        this.setField<string>('DoorSideGuidDesc', value);
    }

    // DoorSideGuidType
    public get doorSideGuidType(): string {
        return this.getField<string>('DoorSideGuidType');
    }
    public set doorSideGuidType(value: string) {
        this.setField<string>('DoorSideGuidType', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('AccessPointGuid', SafeGuid.EMPTY);
        this.setField<string>('AccessPointGuidDesc', '');
        this.setFieldGuid('DoorSideGuid', SafeGuid.EMPTY);
        this.setField<string>('DoorSideGuidDesc', '');
        this.setField<string>('DoorSideGuidType', '');
    }

    // #endregion Methods

}

