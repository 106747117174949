import { Degree } from '../utils/Degree';
import { DragInfo } from '../utils/DragObserver';
import { Guard } from '../utils/Guard';
import { ILiteEvent, LiteEvent } from '../utils/liteEvents';
import { Radian } from '../utils/Radian';
import { ClipSpaceVec2 } from '../utils/Vec2';
import { Dewarper } from './Dewarper';
import { ZoomFactor } from './ZoomFactor';

/**
 * Interface used to control the dewarping of the video stream
 * @public
 * */
export interface IDewarperControl {
  /** Is the webplayer currently dewarping a stream */
  readonly isDewarping: boolean;

  /** Stops the dewarping of the stream and return to the warped image.
   Does nothing if the stream was not currently being dewarped.*/
  stopDewarping(): void;

  /** Places the center of the dewarped area at the requested coordinates.
   * Requires values in the [-1, 1] range, (0, 0) being the center of the warped image.
   * Based on the resolution of the whole image, not only the warped circle.
   * The dewarper will cap requests at the edges of the circle.
   * @param x - X coordinate of the desired dewarped area
   * @param y - Y coordinate of the desired dewarped area
   * */
  gotoXY(x: number, y: number): void;

  /** X coordinate in the whole image for the center of the currently dewarped area */
  readonly X: number;
  /** Y coordinate in the whole image for the center of the currently dewarped area */
  readonly Y: number;

  /**
   * Move the dewarped area according taking into account the camera position using values into degree.
   * @param panDegrees - The amount of degrees to pan
   * @param tiltDegrees - The amount of degrees to tilt
   */
  gotoPanTilt(panDegrees: number, tiltDegrees: number): void;
  /** The amount of pan degrees the currently dewarped area is from the center */
  readonly PanDegrees: number;
  /** The amount of tilt degrees the currently dewarped area is from the center */
  readonly TiltDegrees: number;

  /**
   * Zoom on the center of the currently dewarped area.
   * Request to zoom outside of the supported range will raise an error.
   * @param zoomFactor - The zoom factor. Must be between 1 and 20, 1 being not zoomed and 20 being the maximum zoom available.
   */
  gotoZoom(zoomFactor: number): void;

  /** The currently used zoom factor. */
  readonly ZoomFactor: number;

  /** Obtain the interface to manipulate the dewarping preview control */
  readonly Preview: IDewarperPreview;

  /** Obtain a snapshot of the currently dewarped view */
  //getSnapshot(): ImageData | null;
}

export class DewarperControl implements IDewarperControl {
  private readonly m_dewarper: Dewarper;
  private readonly m_dewarperPreview: IDewarperPreview;
  private readonly m_dewarperControl: IInternalDewarperControl;

  public get isDewarping(): boolean {
    return this.m_dewarper.isDewarping;
  }

  public stopDewarping(): void {
    this.m_dewarper.stopDewarping();
  }

  public gotoXY(x: number, y: number): void {
    Guard.isFiniteNumber(x, 'x');
    Guard.isFiniteNumber(y, 'y');

    this.m_dewarperControl.gotoXY(new ClipSpaceVec2(x, y));
  }

  public get X(): number {
    return this.m_dewarperControl.XY.X;
  }

  public get Y(): number {
    return this.m_dewarperControl.XY.Y;
  }

  public gotoPanTilt(panDegrees: number, tiltDegrees: number): void {
    Guard.isFiniteNumber(panDegrees, 'panDegrees');
    Guard.isFiniteNumber(tiltDegrees, 'tiltDegrees');

    this.m_dewarperControl.gotoPanTilt(new Degree(panDegrees).toRadian(), new Degree(tiltDegrees).toRadian());
  }

  public get PanDegrees(): number {
    return this.m_dewarperControl.Pan.InDegree;
  }

  public get TiltDegrees(): number {
    return this.m_dewarperControl.Tilt.InDegree;
  }

  public gotoZoom(zoomFactor: number): void {
    Guard.isFiniteNumber(zoomFactor, 'zoomFactor');
    return this.m_dewarperControl.zoom(new ZoomFactor(zoomFactor));
  }

  public get ZoomFactor(): number {
    return this.m_dewarperControl.ZoomFactor.Value;
  }

  public get Preview(): IDewarperPreview {
    return this.m_dewarperPreview;
  }

  public constructor(dewarper: Dewarper, dewarperPreview: IDewarperPreview, dewarperControl: IInternalDewarperControl) {
    this.m_dewarper = dewarper;
    this.m_dewarperPreview = dewarperPreview;
    this.m_dewarperControl = dewarperControl;
  }
}

/**
 * Interface to manipulate the dewarping preview control
 * @public
 * */
export interface IDewarperPreview {
  /** Enable or disable the preview control */
  Enabled: boolean;
  /** Obtain to do manipulation on the dewarper preview canvas such as resize and reposition it.
   The color of the dewarped area and its border can also be changed by setting the css color and border-color properties*/
  readonly Canvas: HTMLCanvasElement;
}

export interface IInternalDewarperControl {
  readonly sceneUpdated: ILiteEvent<void>;

  gotoXY(xy: ClipSpaceVec2): void;
  readonly XY: ClipSpaceVec2;

  gotoPanTilt(pan: Radian, tilt: Radian): void;
  readonly Pan: Degree;
  readonly Tilt: Degree;

  zoom(zoomFactor: ZoomFactor): void;
  readonly ZoomFactor: ZoomFactor;

  drag(dragInfo: DragInfo): void;
}

export class ControllerAggregate implements IInternalDewarperControl {
  private readonly m_internalControl: IInternalDewarperControl;
  private readonly m_secondListener: IInternalDewarperControl | null;
  private readonly m_sceneUpdatedEvent: LiteEvent<void>;

  public get sceneUpdated(): ILiteEvent<void> {
    return this.m_sceneUpdatedEvent.expose();
  }

  public get XY(): ClipSpaceVec2 {
    return this.m_internalControl.XY;
  }

  public get Pan(): Degree {
    return this.m_internalControl.Pan;
  }

  public get Tilt(): Degree {
    return this.m_internalControl.Tilt;
  }

  public get ZoomFactor(): ZoomFactor {
    return this.m_internalControl.ZoomFactor;
  }

  constructor(internalControl: IInternalDewarperControl, secondListener: IInternalDewarperControl | null) {
    this.m_internalControl = internalControl;
    this.m_secondListener = secondListener;
    this.m_sceneUpdatedEvent = new LiteEvent<void>();
  }

  public gotoXY(xy: ClipSpaceVec2): void {
    this.m_internalControl.gotoXY(xy);
    this.m_secondListener?.gotoXY(xy);
    this.triggerSceneChanged();
  }

  public gotoPanTilt(pan: Radian, tilt: Radian): void {
    this.m_internalControl.gotoPanTilt(pan, tilt);
    this.m_secondListener?.gotoPanTilt(pan, tilt);
    this.triggerSceneChanged();
  }

  public zoom(zoomFactor: ZoomFactor): void {
    this.m_internalControl.zoom(zoomFactor);
    this.m_secondListener?.zoom(zoomFactor);
    this.triggerSceneChanged();
  }

  public drag(dragInfo: DragInfo): void {
    this.m_internalControl.drag(dragInfo);
    this.m_secondListener?.drag(dragInfo);
    this.triggerSceneChanged();
  }

  private triggerSceneChanged(): void {
    this.m_sceneUpdatedEvent.trigger();
  }
}
