import { Component } from '@angular/core';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { VideoContentTypes } from '@modules/video/enumerations/video-content-types';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { CameraBaseComponent } from '../../camera-base.component';

const isContentSupported = (content: Content, pluginType: IGuid) => {
    if (content) {
        return content.type.equals(VideoContentTypes.Video) && (!content.contextContent || content.contextContent?.type.equals(VideoContentTypes.Event));
    }
    return false;
};

@Component({
    selector: 'app-camera-actions-widget.component',
    templateUrl: './camera-actions-widget.component.html',
    styleUrls: ['./camera-actions-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: CameraActionsWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: CameraActionsWidgetComponent.pluginId, priority: 0 },
    isContentSupported,
    requirements: { features: [KnownFeatures.videoId], licenses: [KnownLicenses.video] },
})
export class CameraActionsWidgetComponent extends CameraBaseComponent implements ContentPluginComponent {
    public static pluginId = SafeGuid.parse('E117DDEE-B661-4B10-9F17-F7041ED4F545');

    constructor(securityCenterClientService: SecurityCenterClientService, translateService: TranslateService, trackingService: TrackingService) {
        super(securityCenterClientService, translateService, trackingService);
    }
}
