import { SafeGuid } from 'safeguid';
import { Observable } from 'rxjs';
import { ButtonFlavor } from '@genetec/gelato-angular';
import { defaultIfEmpty, filter, startWith, switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { InternalPluginDescriptor } from '@shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { MCFeature } from '../../mc-feature';
import { TrackedComponent } from '@shared/components/tracked/tracked.component';
import { TrackingService } from '@shared/services/tracking.service';
import { McEventReceiverService } from '../../services/events/mc-event-receiver.service';
import { Header, IncidentEventType, StateChangedEvent } from '../../models/events/incident-event';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IncidentCountService } from '../../services/incident-count/incident-count.service';
import { StateGuids } from '../../state-guids';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';

@Component({
    selector: 'app-incident-notification',
    templateUrl: './incident-notification.component.html',
    styleUrls: ['./incident-notification.component.scss'],
})
@InternalPluginDescriptor({
    type: IncidentNotificationComponent,
    pluginTypes: [PluginTypes.Tray],
    exposure: { id: IncidentNotificationComponent.pluginId },
    isSupported: () => true,
    requirements: {
        features: [MCFeature.missionControlfeature],
        globalPrivileges: [KnownPrivileges.incidentMonitoringTaskPrivilege],
    },
})
export class IncidentNotificationComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('786FCAF6-EA8A-43C3-95BB-D5319DA931EF');

    public readonly ButtonFlavor = ButtonFlavor;
    public readonly Icon = Icon;

    public incidentCount$!: Observable<number>;

    private initializingEvent = new StateChangedEvent(IncidentEventType.StateChanged, SafeGuid.EMPTY, new Date(), SafeGuid.EMPTY, StateGuids.NEW, Header.Empty());

    private targetedEvent: IncidentEventType[] = [IncidentEventType.Created, IncidentEventType.StateChanged, IncidentEventType.RecipientsAltered];

    constructor(
        trackingService: TrackingService,
        securityCenterClientService: SecurityCenterClientService,
        private _eventService: McEventReceiverService,
        private _incidentService: IncidentCountService
    ) {
        super(trackingService);
    }

    ngOnInit() {
        this.incidentCount$ = this._eventService.getIncidentEvents().pipe(
            startWith([this.initializingEvent]),
            filter((x) => x.some((event) => this.targetedEvent.some((type) => event.type === type))),
            switchMap((_) => this._incidentService.getNewIncidentCount().pipe(defaultIfEmpty(0)))
        );
    }
}
