import { FieldObject } from '../../Helpers/FieldObject';
import { IColumnInfo, IReportInstanceResult, IReportResult } from '../Interface/IReportResult';
import { SafeGuid } from 'safeguid';
import { IFieldObject } from '../Interface/IFieldObject';

export class ColumnInfo implements IColumnInfo {
  public index = 0;
  public name = '';
  public type = '';

  constructor(indexValue: number, nameValue: string, typeValue: string) {
    this.index = indexValue;
    this.name = nameValue;
    this.type = typeValue;
  }
}

export abstract class ReportInstanceResult implements IReportInstanceResult
{
    public ToJson(): IFieldObject {
        var json = JSON.stringify(this);
        var fo = new FieldObject();
        fo.fromJson(json);
        return fo;
    }
}

export abstract class ReportResult implements IReportResult {
  public entityLookup = new Map<string, string>();

  public successful = false;
  public errorSeverity = '';
  public error = '';
  public errorMessage = '';
  public source = '';
  public lastResult = false;

  constructor() {}

  public load(body: { [k: string]: any }): void {
    this.successful = body['Successful'];
    this.errorSeverity = body['ErrorSeverity'];
    this.error = body['Error'];
    this.errorMessage = body['ErrorMessage'];
    this.source = body['Source'];
    this.lastResult = body['LastResult'];

    // Do we have entitylookup?
    const fo = new FieldObject();
    if (body['EntityLookup']) {
      fo.loadFields(body['EntityLookup']);
      for (const key of fo.AllFieldsKey) {
        this.entityLookup.set(key, fo.getField(key));
      }
    }

    this.parseSpecificResponse(body);
  }

  protected getColumn(columns: any): Map<string, ColumnInfo> {
    const results = new Map<string, ColumnInfo>();

    let index = 0;
    for (const column of columns) {
      const name = column['Name'];
      const type = column['Type'];
      const col = new ColumnInfo(index, name, type);
      results.set(name, col);
      ++index;
    }
    return results;
  }

  protected getRows(body: { [k: string]: any }): Array<Array<any>> {
    const rowResult = new Array<Array<any>>();
    const results = body['Results'];
    if (results != null) {
      for (const result of results) {
        const rows = result['Rows'];
        for (const row of rows) {
          rowResult.push(row);
        }
      }
    }
    return rowResult;
  }

  protected abstract parseSpecificResponse(body: { [k: string]: any }): void;

  public abstract getResults() : Array<IReportInstanceResult>;

  protected getSafeValue<T>(type: string, rowEntry: any): T {
    let isNull = false;
    if (rowEntry === null || rowEntry === undefined) {
      isNull = true;
    }

    switch (type) {
      case "string": {
        if (isNull === true) {
          return "" as unknown as T;
        }
        return rowEntry;
      }
      case "Guid": {
        if (isNull === true) {
          // TODO FM: Check to see if parsing necesary
          return SafeGuid.EMPTY as unknown as T;
        }
        return SafeGuid.parse(rowEntry.toLowerCase()) as unknown as T;
      }
      case "Date": {
        if (isNull === true) {
          return new Date() as unknown as T;
        }
        return new Date(rowEntry) as unknown as T;
      }
      case "number": {
        if (isNull === true) {
          return 0 as unknown as T;
        }
        return rowEntry;
      }
      case "boolean": {
        if (isNull === true) {
          return false as unknown as T;
        }
        return rowEntry;
      }
      default: {
        return undefined as unknown as T;
      }
    }
  }

}
