<gen-melted-modal
    id="bookmark"
    genTitle="{{ 'STE_BUTTON_ADDBOOKMARK' | translate }}"
    [genIsDefaultActionDisabled]="!isValid"
    [genIsBlocking]="true"
    genDefaultActionText="{{ 'STE_LABEL_OK' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    appKeydownStopPropagation
>
    <gen-melted-modal-body>
        <form (keydown)="keyPressed($event)" (ngSubmit)="createBookmark()">
            <gen-grid>
                <gen-grid-item>
                    <gen-text-input name="inputMessage" genAutofocus [(ngModel)]="message" (ngModelChange)="onMessageChanged()">
                        <gen-label>{{ 'STE_LABEL_BOOKMARK_MESSAGE' | translate }}</gen-label>
                    </gen-text-input>

                    <!-- <gen-switch genText="Protect video from deletion" [(ngModel)]="protectVideo"> </gen-switch> -->
                </gen-grid-item>
            </gen-grid>
        </form>
    </gen-melted-modal-body>
</gen-melted-modal>
