import { Component, OnInit } from '@angular/core';
import { ICardholderEntity } from 'RestClient/Client/Interface/ICardholderEntity';
import { CardholderEntity } from 'RestClient/Client/Model/AccessControl/CardholderEntity';
import { SecurityCenterClient } from 'RestClient/Client/SecurityCenterClient';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';

@Component({
    selector: 'app-cardholder-widget-base-component',
    template: '',
})
export class CardholderWidgetBaseComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public dataContext?: ContentService;
    public content?: Content;
    public isLoading = true;

    protected cardholder: CardholderEntity | null = null;
    protected scClient!: SecurityCenterClient;

    protected get monitoredFields(): string[] {
        return [];
    }

    constructor(securityCenterClientService: SecurityCenterClientService, trackingService: TrackingService) {
        super(trackingService);

        this.scClient = securityCenterClientService?.scClient;
    }

    public async ngOnInit() {
        super.ngOnInit();

        if (this.content?.source) {
            const sourceId = SafeGuid.parse(this.content?.source);
            if (!sourceId.isEmpty()) {
                await this.getCardholderAsync(sourceId);
                await this.initializeCardholerInfo();
            }
        }
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    protected async getCardholderAsync(cardholderId: IGuid): Promise<void> {
        if (this.scClient) {
            this.cardholder = (await this.scClient.getEntityAsync<CardholderEntity, ICardholderEntity>(
                CardholderEntity,
                cardholderId,
                null,
                null,
                this.monitoredFields.toString()
            )) as CardholderEntity;
        }
    }

    protected async initializeCardholerInfo(): Promise<void> {}
}
