import { IWebSocketResponse } from './IWebSocketMessage';
import { WebSocketMessageHeader } from './WebSocketMessageHeader';

/**
 * @beta
 * */
export class UpdateStreamResponse implements IWebSocketResponse {
  private readonly m_header: WebSocketMessageHeader;

  private readonly m_body: UpdateStreamResponseBody;

  public get channel(): number {
    return this.m_header.channel;
  }

  public get body(): UpdateStreamResponseBody {
    return this.m_body;
  }

  constructor(header: WebSocketMessageHeader, body: UpdateStreamResponseBody) {
    this.m_header = header;
    this.m_body = body;
  }
}

/**
 * @beta
 * */
export class UpdateStreamResponseBody {
  public readonly AudioAvailable: boolean;

  constructor(audioAvailable: boolean) {
    this.AudioAvailable = audioAvailable;
  }

  public static deserialize(body: string): UpdateStreamResponseBody {
    const updateStreamResponseBody: UpdateStreamResponseBody = JSON.parse(body);
    return updateStreamResponseBody;
  }
}
