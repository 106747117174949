import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { LicensePartsState } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { Constants } from '@src/constants';
import { AnalyticsClient, AnalyticsInitializationData } from '../../../general/api/api';
import { PluginService } from '../plugin/plugin.service';
import { VersionService } from '../version/version.service';
import { TelemetryInitializers } from './telemetryInitializers';

export enum CollectionPolicy {
    On = 'On',
    Off = 'Off',
    Anonymous = 'Anonymous',
}

export class AnalyticsGeneralInfo {
    securityCenterVersion?: string;
    appVersion?: string;
    licenses?: LicensePartsState;
    licenseType?: string;
    features?: { [key: string]: boolean };
}

/**
 * A singleton service that offers a wrapper around Application Insights SDK
 * and adds additional data to all analytics logs.
 *
 * @class AnalyticsService
 */

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
    private appInsights: ApplicationInsights | null = null;
    private collectionPolicy: CollectionPolicy = CollectionPolicy.Off;
    private userId?: string;
    private systemId?: string;
    private ingestionEndpoint?: string;
    private generalInfo: AnalyticsGeneralInfo = {};

    constructor(
        private versionService: VersionService,
        private pluginService: PluginService,
        private analyticsApiController: AnalyticsClient,
        @Inject(Constants.baseUrlIdentifier) private baseUrl: string,
        private telemetryInitializers: TelemetryInitializers
    ) {}

    /**
     * Will create the singleton instance of Application Insights if the collection policy setting is On or Anonymous.
     */
    public setupAppInsightsAsync = async (): Promise<void> => {
        // "/v2/track" will be added to the endpoint by the SDK
        this.ingestionEndpoint = `${this.baseUrl}Api/Analytics/Proxy`;
        if (this.appInsights) {
            return;
        }
        await this.retrieveInitializationDataAsync();

        if (this.collectionPolicy === CollectionPolicy.Off) {
            return;
        }

        this.generalInfo.licenses = this.pluginService.getLicensesPartsState();
        await this.retrieveVersionAsync();

        const appInsights = new ApplicationInsights({
            config: {
                connectionString: `InstrumentationKey=00000000-0000-0000-0000-000000000000;IngestionEndpoint=${this.ingestionEndpoint}`,
                enableAutoRouteTracking: true, // option to log all route changes
            },
        });

        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.telemetryInitializers.filter(envelope));
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.telemetryInitializers.addGeneralInfoToEnvelope(envelope, this.generalInfo));
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.telemetryInitializers.addPluginNameToEnvelope(envelope));

        if (this.collectionPolicy === CollectionPolicy.Anonymous) {
            appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.telemetryInitializers.anonymizeHostNames(envelope));
            appInsights.clearAuthenticatedUserContext();
        }

        appInsights.setAuthenticatedUserContext(this.userId as string, this.systemId as string, true);
        this.appInsights = appInsights;
    };

    public clearAppInsightsData = (): void => {
        if (this.appInsights) {
            this.appInsights.clearAuthenticatedUserContext();
            this.appInsights = null;
        }
    };

    // #region Public methods

    /**
     * Will track one page view event.
     *
     * @param name - a name for the tracking event
     * @param uri - the uri of the page to track
     */
    public logPageView(name?: string, uri?: string): void {
        // option to call manually
        if (!this.areAnalyticsEnabled()) {
            return;
        }
        this.appInsights?.trackPageView({
            name,
            uri,
        });
    }

    /**
     * Will track one custom event.
     *
     * @param name - a name for the tracking event
     * @param properties - an object that can contain any key, should be representative of the event we want to track
     */
    public logEvent(name: string, properties?: { [key: string]: any }): void {
        if (!this.areAnalyticsEnabled()) {
            return;
        }
        this.appInsights?.trackEvent({ name }, { customProperties: properties });
    }

    /**
     * Will track a custom metric event.
     *
     * @param name - a name for the tracking event
     * @param average - a value measured in the application that represents the name parameter
     * @param properties - an object that can contain any key, should be representative of the event we want to track
     */
    public logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        if (!this.areAnalyticsEnabled()) {
            return;
        }
        this.appInsights?.trackMetric({ name, average }, properties);
    }

    /**
     * Will track a custom exception event.
     *
     * @param exception - an exception generated by the app that we want to track
     * @param severityLevel - the severity level of the generated exception
     */
    public logException(exception: Error, severityLevel?: number): void {
        if (!this.areAnalyticsEnabled()) {
            return;
        }
        this.appInsights?.trackException({ exception, severityLevel });
    }

    /**
     * Will track a custom trace event.
     *
     * @param message - the message corresponding to the trace event
     * @param properties - an object that can contain any key, should be representative of the event we want to track
     */
    public logTrace(message: string, properties?: { [key: string]: any }): void {
        if (!this.areAnalyticsEnabled()) {
            return;
        }
        this.appInsights?.trackTrace({ message }, properties);
    }

    // #endregion

    // #region Private methods

    /**
     * Will retrieve the values for the security center version and web app version and assign them to the class' properties.
     */
    private async retrieveVersionAsync(): Promise<void> {
        const versionInfo = await this.versionService.retrieveVersionAsync();
        if (versionInfo) {
            this.generalInfo.securityCenterVersion = versionInfo.securityCenterExactVersion;
            this.generalInfo.appVersion = versionInfo.buildVersion;
        }
    }

    /**
     * Will retrieve the values needed to initialize the Application Insights SDK
     * and assign them to the class' properties.
     */
    private async retrieveInitializationDataAsync(): Promise<void> {
        const data: AnalyticsInitializationData = await this.analyticsApiController.getInitializationData().toPromise();
        if (data.userId) {
            this.userId = data.userId;
        }
        if (data.systemId) {
            this.systemId = data.systemId;
        }
        if (data.collectionPolicy) {
            this.collectionPolicy = this.stringToCollectionPolicy(data.collectionPolicy);
        }
        if (data.licenseType) {
            this.generalInfo.licenseType = data.licenseType;
        }
        if (data.features) {
            this.generalInfo.features = data.features;
        }
    }

    /**
     * A helper method to convert the collection policy in string to an enum.
     *
     * @param stringValue - a value of either "on", "off" or "anonymous" corresponding to the collection policy set by an administrator
     * @returns
     */
    private stringToCollectionPolicy(stringValue: string): CollectionPolicy {
        switch (stringValue.toLowerCase()) {
            case 'on':
                return CollectionPolicy.On;
            case 'off':
                return CollectionPolicy.Off;
            case 'anonymous':
                return CollectionPolicy.Anonymous;
            default:
                return CollectionPolicy.Off;
        }
    }

    /**
     * @returns true if collection policy is either "On" or "Anonymous" AND application insights is initialized. Otherwise, returns false.
     */
    private areAnalyticsEnabled(): boolean {
        return this.collectionPolicy !== CollectionPolicy.Off && !!this.appInsights;
    }

    // #endregion
}
