<app-filter-host [filterDescriptor]="descriptor" [state]="state" (filterToggled)="onFilterToggled($event)" [popupPosition]="popupPosition">
    <gen-text-input
        class="filter-text-input open-focus"
        [maxlength]="100"
        [(ngModel)]="actualText"
        (ngModelChange)="onFilterChanged($event)"
        placeholder="{{ 'STE_ACTION_SEARCH' | translate }}"
    ></gen-text-input>
    <gen-button *ngIf="this.value.length > 0" class="gen-input-group-append" [flavor]="Flavor.Button.Flat" (click)="onFilterChanged('')">
        <gen-icon [icon]="MeltedIcon.Close"></gen-icon>
    </gen-button>
</app-filter-host>
