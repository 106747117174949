import { ZoomConfiguration } from '../players/ZoomConfiguration';
import { Guard } from '../utils/Guard';
import { ILogger } from '../utils/logger';
import { NormalizedTopLeftCoords } from '../utils/TopLeftCoords';
import { Utils } from '../utils/Utils';
import { Tile } from '../players/Tile';
import { Cancellation, CancellationToken } from '../utils/CancellationToken';
import { PromiseCompletionSourceVoid } from '../utils/PromiseCompletionSource';
import { IDigitalZoomPreview, DigitalZoomPreview } from './preview/DigitalZoomPreview';
import { DragObserver } from '../utils/DragObserver';
import { VideoWatermarkConfig } from '../utils/VideoWatermarkingConfig';
import { ILiteEvent, LiteEvent } from '../utils/liteEvents';

/** This interface regroups the control and preview for the DigitalZoom
 * @public */
export interface IDigitalZoomControl {
  /** Get the current zoom factor. The zoom factor is constrained between 1 (no zoom), and 20 */
  readonly Zoom: number;
  /**
  * Get the normalized (between 0 and 1) x-coordinate of the current view's midpoint, with respect to the original image.
  * Can be negative, or larger than 1.0, when centered on the black bars around the image (when the video aspect ratio does not fit the tile)
  */
  readonly X: number;
  /**
  * Get the normalized (between 0 and 1) y-coordinate of the current view's midpoint, with respect to the original image.
  * Can be negative, or larger than 1.0, when centered on the black bars around the image (when the video aspect ratio does not fit the tile)
  */
  readonly Y: number;

  /**
   * Get the preview for the Digital Zoom
   */
  readonly Preview: IDigitalZoomPreview;

  /** Event raised when the digital zoom position has changed */
  readonly onPositionChanged: ILiteEvent<void>;

  /**
  * Go to a specific point on the original image
  * @param x - normalized x-coordinate where the middle of the zoomed image should be, with respect to the original image
  * @param y - normalized y-coordinate where the middle of the zoomed image should be, with respect to the original image
  * @param zoomFactor - Desired zoom factor
  */
  goTo(x: number, y: number, zoomFactor: number): void;
  /**
  * Zoom into the middle of the current view
  * @param zoomFactor - Absolute value representing the desired zoom factor
  */
  zoom(zoomFactor: number): void;
  /**
  * Move around the image at the current zoom level
  * @param x - normalized value [-1, 1] representing how much to move the current view in the horizontal axis, with respect to the current view
  * @param y - normalized value [-1, 1] representing how much to move the current view in the vertical axis, with respect to the current view
  */
  move(x: number, y: number): void;
  /**
  * Zoom on the current view while keeping the provided coordinates in their current location in the view
  * @param x - normalized x-coordinate representing the relative position of the point of interest, with respect to the current view
  * @param x - normalized y-coordinate representing the relative position of the point of interest, with respect to the current view
  * @param zoomFactor - Absolute value representing the desired zoom factor
  */
  zoomWithFocus(x: number, y: number, zoomFactor: number): void;
  /**
  * Reset the zoom and show the full image
  */
  stop(): void;
  /**
  * Take a snapshot of the current view
  */
  snapshot(): ImageData | null;
}

export class DigitalZoomControl implements IDigitalZoomControl {
  private readonly m_digitalZoom: DigitalZoom;

  private readonly m_digitalZoomPreview: DigitalZoomPreview;

  public get Zoom(): number {
    return this.m_digitalZoom.Zoom;
  }

  public get X(): number {
    return this.m_digitalZoom.X;
  }

  public get Y(): number {
    return this.m_digitalZoom.Y;
  }

  public get Preview(): IDigitalZoomPreview {
    return this.m_digitalZoomPreview;
  }

  public get onPositionChanged(): ILiteEvent<void> {
    return this.m_digitalZoom.onPositionChanged.expose();
  }

  constructor(digitalZoom: DigitalZoom, preview: DigitalZoomPreview) {
    this.m_digitalZoom = digitalZoom;
    this.m_digitalZoomPreview = preview;
  }

  public dispose(): void {
    this.m_digitalZoom.dispose();
    this.m_digitalZoomPreview.dispose();
  }

  public goTo(x: number, y: number, zoomFactor: number): void {
    this.m_digitalZoom.goTo(x, y, zoomFactor);
  }

  public zoom(zoomFactor: number): void {
    this.m_digitalZoom.zoom(zoomFactor);
  }

  public move(x: number, y: number): void {
    this.m_digitalZoom.move(x, y);
  }

  public zoomWithFocus(x: number, y: number, zoomFactor: number): void {
    this.m_digitalZoom.zoomWithFocus(x, y, zoomFactor);
  }

  public stop(): void {
    this.m_digitalZoom.stop();
  }

  public snapshot(): ImageData | null {
    return this.m_digitalZoom.snapshot();
  }

  public updateVideoWatermarkingConfig(videoWatermarkConfig: VideoWatermarkConfig): void {
    this.m_digitalZoomPreview.updateVideoWatermarkingConfig(videoWatermarkConfig);
  }

  public debugStatus(indent: number): string {
    return 'DigitalZoom' + Utils.indentNewLine(indent) +
      this.m_digitalZoom.debugStatus(indent + Utils.Indentation) + Utils.indentNewLine(indent) +
      this.m_digitalZoomPreview.debugStatus(indent + Utils.Indentation);
  }
}

export interface IDigitalZoom {
  /** Get the current zoom factor. The zoom factor is constrained between 1 (no zoom), and 20 */
  readonly Zoom: number;
  /**
  * Get the normalized (between 0 and 1) x-coordinate of the current view's midpoint, with respect to the original image.
  * Can be negative, or larger than 1.0, when centered on the black bars around the image (when the video aspect ratio does not fit the tile)
  */
  readonly X: number;
  /**
  * Get the normalized (between 0 and 1) y-coordinate of the current view's midpoint, with respect to the original image.
  * Can be negative, or larger than 1.0, when centered on the black bars around the image (when the video aspect ratio does not fit the tile)
  */
  readonly Y: number;

  /**
  * Go to a specific point on the original image
  * @param x - normalized x-coordinate where the middle of the zoomed image should be, with respect to the original image
  * @param y - normalized y-coordinate where the middle of the zoomed image should be, with respect to the original image
  * @param zoomFactor - Desired zoom factor
  */
  goTo(x: number, y: number, zoomFactor: number): void;
  /**
  * Zoom into the middle of the current view
  * @param zoomFactor - Absolute value representing the desired zoom factor
  */
  zoom(zoomFactor: number): void;
  /**
  * Move around the image at the current zoom level
  * @param x - normalized value [-1, 1] representing how much to move the current view in the horizontal axis, with respect to the current view
  * @param y - normalized value [-1, 1] representing how much to move the current view in the vertical axis, with respect to the current view
  */
  move(x: number, y: number): void;
  /**
  * Zoom on the current view while keeping the provided coordinates in their current location in the view
  * @param x - normalized x-coordinate representing the relative position of the point of interest, with respect to the current view
  * @param x - normalized y-coordinate representing the relative position of the point of interest, with respect to the current view
  * @param zoomFactor - Absolute value representing the desired zoom factor
  */
  zoomWithFocus(x: number, y: number, zoomFactor: number): void;
  /**
  * Reset the zoom and show the full image
  */
  stop(): void;
  /**
  * Take a snapshot of the current view
  */
  snapshot(): ImageData | null;
}

export class DigitalZoom implements IDigitalZoom {
  private readonly m_logger: ILogger;
  private readonly m_tile: Tile;
  private readonly m_dragObserver: DragObserver;
  private readonly m_positionChanged = new LiteEvent<void>();
  private m_coords = new NormalizedTopLeftCoords(0, 0); // Normalized TopLeft coords of the current viewing rectangle, with respect to the canvas
  private m_zoomFactor: number = 1.0; // Zoom factor of the current viewing rectangle
  private m_viewOffset = new NormalizedTopLeftCoords(0, 0); // Normalized TopLeft coords representing where the image starts in the canvas, when the image is surrounded by black bars.

  private m_zoomConfigUpdatedPromise: PromiseCompletionSourceVoid;
  private readonly m_disposeToken: CancellationToken;

  public get Zoom(): number {
    return this.m_zoomFactor;
  }

  public get X(): number {
    return (this.m_coords.X + 1 / this.m_zoomFactor / 2 - this.m_viewOffset.X) / (1 - 2 * this.m_viewOffset.X);
  }

  public get Y(): number {
    return (this.m_coords.Y + 1 / this.m_zoomFactor / 2 - this.m_viewOffset.Y) / (1 - 2 * this.m_viewOffset.Y);
  }

  public get IsZoomed(): boolean {
    return this.m_zoomFactor > 1.0;
  }

  public get ZoomConfigChange(): Promise<void | Cancellation> {
    return Promise.race([this.m_zoomConfigUpdatedPromise.Promise, this.m_disposeToken.Cancellation]);
  }

  public get onPositionChanged(): LiteEvent<void> {
    return this.m_positionChanged;
  }

  constructor(logger: ILogger, tile: Tile, dragObserver: DragObserver) {
    this.m_logger = logger.subLogger('DigitalZoom');
    this.m_tile = tile;

    this.m_zoomConfigUpdatedPromise = new PromiseCompletionSourceVoid();
    this.m_disposeToken = new CancellationToken();

    this.m_dragObserver = dragObserver;
    this.observeTileDimensionsChange();
    this.observeDragChange();
  }

  public dispose(): void {
    this.stop();
    this.m_dragObserver.dispose();
    this.m_disposeToken.cancel('dispose');
  }

  public goTo(x: number, y: number, zoomFactor: number): void {
    Guard.isInRangeInclusive(x, 0, 1);
    Guard.isInRangeInclusive(y, 0, 1);
    Guard.isInRangeInclusive(zoomFactor, 1, 20);

    this.m_logger.debug?.trace(`goTo (${x.toFixed(3)}, ${y.toFixed(3)}), ${zoomFactor}x (offset ${this.m_viewOffset.toString()})`);

    if (zoomFactor === 1.0) {
      this.stop();
      return;
    }

    // Should there be black bars on both sides at the current zoom level, center the image regardless of the input coordinates
    const newX = 1 / zoomFactor >= (1 - 2 * this.m_viewOffset.X) ?
      (1 - 1 / zoomFactor) / 2 :
      Utils.clip((this.m_viewOffset.X + x * (1 - 2 * this.m_viewOffset.X)) - 1 / zoomFactor / 2,
        this.m_viewOffset.X, 1 - 1 / zoomFactor - this.m_viewOffset.X);

    const newY = 1 / zoomFactor >= (1 - 2 * this.m_viewOffset.Y) ?
      (1 - 1 / zoomFactor) / 2 :
      Utils.clip((this.m_viewOffset.Y + y * (1 - 2 * this.m_viewOffset.Y)) - 1 / zoomFactor / 2,
        this.m_viewOffset.Y, 1 - 1 / zoomFactor - this.m_viewOffset.Y);

    this.m_coords = new NormalizedTopLeftCoords(newX, newY);

    this.m_zoomFactor = zoomFactor;

    this.updateZoomConfig();
  }

  public zoom(zoomFactor: number): void {
    Guard.isInRangeInclusive(zoomFactor, 1, 20);

    this.m_logger.debug?.trace(`zoom ${zoomFactor}x`);

    this.zoomWithFocus(0.5, 0.5, zoomFactor);
  }

  public move(x: number, y: number): void {
    Guard.isInRangeInclusive(x, -1, 1);
    Guard.isInRangeInclusive(y, -1, 1);

    this.m_logger.debug?.trace(`move (${x}, ${y})`);

    let newX: number;

    if (1 / this.m_zoomFactor >= (1 - 2 * this.m_viewOffset.X)) {
      // The entire image is visible, surrounded by black bars on both sides. Center the image.
      newX = (1 - 1 / this.m_zoomFactor) / 2;
    } else if (this.m_coords.X < this.m_viewOffset.X) {
      // We have a black bar on the left side of the image; prevent the movement going left
      newX = x <= 0 ? this.m_coords.X : Utils.clip(this.m_coords.X + (x / this.m_zoomFactor), 0, 1 - 1 / this.m_zoomFactor);
    } else if ((this.m_coords.X + 1 / this.m_zoomFactor) > (1 - this.m_viewOffset.X)) {
      // Black bar to the right of the image; prevent the movement going right.
      newX = x > 0 ? this.m_coords.X : Utils.clip(this.m_coords.X + (x / this.m_zoomFactor), this.m_viewOffset.X, 1 - 1 / this.m_zoomFactor);
    } else {
      // Within the image and won't have to deal with black bars
      newX = Utils.clip(this.m_coords.X + (x / this.m_zoomFactor), this.m_viewOffset.X, 1 - 1 / this.m_zoomFactor - this.m_viewOffset.X);
    }

    let newY: number;

    if (1 / this.m_zoomFactor >= (1 - 2 * this.m_viewOffset.Y)) {
      newY = (1 - 1 / this.m_zoomFactor) / 2;
    } else if (this.m_coords.Y < this.m_viewOffset.Y) {
      newY = y <= 0 ? this.m_coords.Y : Utils.clip(this.m_coords.Y + (y / this.m_zoomFactor), 0, 1 - 1 / this.m_zoomFactor);
    } else if ((this.m_coords.Y + 1 / this.m_zoomFactor) > (1 - this.m_viewOffset.Y)) {
      newY = y > 0 ? this.m_coords.Y : Utils.clip(this.m_coords.Y + (y / this.m_zoomFactor), this.m_viewOffset.Y, 1 - 1 / this.m_zoomFactor);
    } else {
      newY = Utils.clip(this.m_coords.Y + (y / this.m_zoomFactor), this.m_viewOffset.Y, 1 - 1 / this.m_zoomFactor - this.m_viewOffset.Y);
    }

    this.m_coords = new NormalizedTopLeftCoords(newX, newY);
    this.updateZoomConfig();
  }

  public zoomWithFocus(x: number, y: number, zoomFactor: number): void {
    Guard.isInRangeInclusive(x, 0, 1);
    Guard.isInRangeInclusive(y, 0, 1);
    Guard.isInRangeInclusive(zoomFactor, 1, 20);

    this.m_logger.debug?.trace(`zoomWithFocus (${x}, ${y}), ${zoomFactor}x`);

    if (zoomFactor === 1.0) {
      this.stop();
      return;
    }
    if (zoomFactor >= this.m_zoomFactor) {
      if (this.m_coords.X + x / this.m_zoomFactor < this.m_viewOffset.X || this.m_coords.Y + y / this.m_zoomFactor < this.m_viewOffset.Y ||
        this.m_coords.X + x / this.m_zoomFactor > 1 - this.m_viewOffset.X || this.m_coords.Y + y / this.m_zoomFactor > 1 - this.m_viewOffset.Y) {
        // Trying to zoom into the black bars; do not zoom
        return;
      }
      // In the zoomed image, the provided coordinates should be exactly in the same position on the canvas as they were before the zoom.
      this.m_coords = new NormalizedTopLeftCoords(
        Utils.clip(this.m_coords.X + (x / this.m_zoomFactor) - (x / zoomFactor), 0, 1),
        Utils.clip(this.m_coords.Y + (y / this.m_zoomFactor) - (y / zoomFactor), 0, 1));
    } else {
      // When zooming out, the offset ratio needs to be maintained
      this.m_coords = new NormalizedTopLeftCoords(
        (this.m_coords.X / (1 - (1 / this.m_zoomFactor))) * (1 - 1 / zoomFactor),
        (this.m_coords.Y / (1 - (1 / this.m_zoomFactor))) * (1 - 1 / zoomFactor));
    }
    this.m_zoomFactor = zoomFactor;
    this.updateZoomConfig();
  }

  public stop(): void {
    this.m_logger.debug?.trace('stop()');
    this.m_coords = new NormalizedTopLeftCoords(0, 0);
    this.m_zoomFactor = 1.0;
    this.updateZoomConfig();
  }

  public snapshot(): ImageData | null {
    return this.m_tile.getSnapshot(true);
  }

  private updateZoomConfig() {
    this.m_tile.applyDigitalZoomConfig(new ZoomConfiguration(this.m_coords, this.m_zoomFactor));

    const toResolve = this.m_zoomConfigUpdatedPromise;
    this.m_zoomConfigUpdatedPromise = new PromiseCompletionSourceVoid();
    toResolve.resolve();
    this.m_positionChanged.trigger();
  }

  private async observeTileDimensionsChange() {
    let tileDimensionsChange = await this.m_tile.TileDimensionsChange;
    while (!(tileDimensionsChange instanceof Cancellation)) {
      this.updateTileResolution();
      tileDimensionsChange = await this.m_tile.TileDimensionsChange;
    }
    this.m_logger.debug?.trace('End of tile dimension observation loop');
  }

  private async observeDragChange() {
    let dragChange = await this.m_dragObserver.Drag;
    while (!(dragChange instanceof Cancellation)) {
      const drag = dragChange.StartNormalized.minus(dragChange.StopNormalized);
      this.move(drag.X, drag.Y);
      dragChange = await this.m_dragObserver.Drag;
    }
    this.m_logger.debug?.trace('End of drag observation loop');
  }

  private readonly updateTileResolution = () => {
    const fitResolution = this.m_tile.fitResolution;

    if (fitResolution === null) {
      return; // Happens if no video is currently playing
    }

    const x = fitResolution.Destination.Width === 0 ? 0 : fitResolution.OffsetH / fitResolution.Destination.Width;
    const y = fitResolution.Destination.Height === 0 ? 0 : fitResolution.OffsetV / fitResolution.Destination.Height;
    this.m_viewOffset = new NormalizedTopLeftCoords(x, y);
  }

  public debugStatus(indent: number): string {
    return 'CurrentPosX: ' + this.X + Utils.indentNewLine(indent) +
      'CurrentPosY: ' + this.Y + Utils.indentNewLine(indent) +
      'CurrentZoom: ' + this.Zoom + Utils.indentNewLine(indent);
  }
}
