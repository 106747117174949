import { State, StateContext } from '@ngxs/store';
import { EmitterAction } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';
import { Receiver, Selector } from '../../../../store';

import { ContentGroup } from '../../interfaces/plugins/public/plugin-public.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface ContextDataStateModel {
    contents: ContentGroup[];
    currentContent: ContentGroup | null;
}

@State<ContextDataStateModel>({
    name: 'contextDataState',
    defaults: { contents: [], currentContent: null },
})
@Injectable()
export class SideContextDataState {
    @Selector()
    public static currentContent(state: ContextDataStateModel): ContentGroup | null {
        return state.currentContent;
    }

    @Selector()
    public static contentCount(state: ContextDataStateModel): number {
        return state.contents.length;
    }

    @Receiver()
    public static setMainContent({ setState }: StateContext<ContextDataStateModel>, { payload }: EmitterAction<ContentGroup>): void {
        setState((state: ContextDataStateModel) => {
            state.contents.length = 0;
            state.contents.push(payload);
            state.currentContent = payload;
            return state;
        });
    }

    @Receiver()
    public static pushContent({ setState }: StateContext<ContextDataStateModel>, { payload }: EmitterAction<ContentGroup>): void {
        setState((state: ContextDataStateModel) => {
            state.contents.push(payload);
            state.currentContent = payload;
            return state;
        });
    }

    @Receiver()
    public static popContent({ setState }: StateContext<ContextDataStateModel>, { payload }: EmitterAction<ContentGroup>): void {
        setState((state: ContextDataStateModel) => {
            state.contents.pop();
            state.currentContent = state.contents.length > 0 ? state.contents[state.contents.length - 1] : null;
            return state;
        });
    }
}
