import { SafeGuid, IGuid } from 'safeguid';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoggerService } from '@shared/services/logger/logger.service';
import { McClient } from '../mc-client.service';
import { toGuid } from '../../utils/guid-utils';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Icon } from '@genetec/gelato';

@UntilDestroy()
@Injectable()
export class IconService {
    private icons = SafeGuid.createMap<ReplaySubject<string>>();
    static readonly DefaultIncidentIconID = '9780C3C0-6D67-4023-9C2F-1BB5802FA127';
    constructor(private mcClient: McClient, private logger: LoggerService) {}

    public getIcon(id: IGuid | string, defaultIcon: string = Icon.Incident): Observable<string> {
        const idGuid = toGuid(id);

        //Check if default MC Icon (yellow lightning)
        if (IconService.isDefaultIncidentIcon(idGuid)) {
            return of(defaultIcon);
        }

        //Check if already downloaded
        if (this.icons.has(idGuid)) {
            return this.icons.get(idGuid)!.asObservable();
        }

        const obs$ = new ReplaySubject<string>(1);
        this.icons.set(idGuid, obs$);
        this.mcClient
            .get(`v2/Icons/${id.toString()}`, 'blob')
            .pipe(
                switchMap((x) => {
                    return new Observable<string>((observer) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(x);
                        reader.onloadend = (e) => {
                            const response = e?.target?.result as string;
                            if (!response) this.logger.traceWarning(`Icon ${id.toString()} not found`);
                            observer.next(response);
                            observer.complete();
                        };
                        reader.onerror = () => observer.complete();
                    });
                }),
                catchError((err) => {
                    console.error(err);
                    return of(undefined);
                }),
                untilDestroyed(this)
            )
            .subscribe(obs$);
        return obs$;
    }

    public static isDefaultIncidentIcon(id: IGuid | string): boolean {
        return toGuid(id).equals(IconService.DefaultIncidentIconID);
    }
}
