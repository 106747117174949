import { CompiledProgram } from './CompiledProgram';
import { FragmentShader } from './FragmentShader';
import { VertexShader } from './VertexShader';

export class ProgramLinker {
  public static link(gl: WebGL2RenderingContext, vertexShader: VertexShader, fragmentShader: FragmentShader) {
    const program = this.createProgram(gl);

    gl.attachShader(program, vertexShader.Shader);
    gl.attachShader(program, fragmentShader.Shader);
    gl.linkProgram(program);

    const success = ProgramLinker.getLinkStatus(gl, program);
    if (success) {
      gl.useProgram(program);
      return new CompiledProgram(gl, program, vertexShader, fragmentShader);
    }

    throw Error('Failed to link program');
  }

  private static createProgram(gl: WebGL2RenderingContext): WebGLProgram {
    const program = gl.createProgram();
    if (program === null) {
      throw Error('Failed to create gl program');
    }
    return program;
  }

  private static getLinkStatus(gl: WebGL2RenderingContext, program: WebGLProgram) {
    return gl.getProgramParameter(program, gl.LINK_STATUS);
  }
}
