import { Component, Input } from '@angular/core';
import { CardholderEditContextState } from '@modules/access-control/cardholder-edit-context.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { IdentityFormGroup } from '../cardholder-edit-form-group';

@Component({
    selector: 'app-cardholder-identity-form',
    templateUrl: './cardholder-identity-form.component.html',
    styleUrls: ['./cardholder-identity-form.component.scss'],
})
export class CardholderIdentityFormComponent {
    @ViewSelectSnapshot(CardholderEditContextState.cardholder)
    public cardholder!: ReturnType<typeof CardholderEditContextState.cardholder>;

    @ViewSelectSnapshot(CardholderEditContextState.isPrivilegeGranted('modifyCardholderNamePrivilege'))
    public hasModifyCardholderNamePrivilege!: boolean;

    @Input() formGroup?: IdentityFormGroup;
    @Input() readonly = false;
}
