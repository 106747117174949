import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@securityCenter/services/authentication/auth.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { UnknownRequestReceivedArg } from 'RestClient/Connection/RestArgs';
import { EventDispatcher } from 'RestClient/Helpers/Helpers';
import { Observable, Subject } from 'rxjs';
import { LoggerService } from '../logger/logger.service';
import { WebSocketResponse } from './web-socket-response';
import { WebSocketSerializerService, WEB_SOCKET_SERIALIZER_SERVICE } from './web-socket-serializer.service';
import { WebSocketService } from './web-socket.service';

export interface SCStreamer {
    _unknownRequestDispatcher: EventDispatcher<UnknownRequestReceivedArg>;
    writeMessageToWebSocket(message: string): void;
}

@UntilDestroy()
@Injectable()
export class SCClientWebSocketService implements WebSocketService {
    public response$: Observable<WebSocketResponse>;

    private streamer: SCStreamer | null = null;
    private unsubscribeWebSocketResponse?: () => void;
    private responseSubject = new Subject<WebSocketResponse>();

    constructor(
        private securityCenterClientService: SecurityCenterClientService,
        private authService: AuthService,
        private loggerService: LoggerService,
        @Inject(WEB_SOCKET_SERIALIZER_SERVICE) private webSocketSerializerService: WebSocketSerializerService
    ) {
        this.response$ = this.responseSubject.asObservable();
        this.subscribeToWebSocketWhenLoggedIn();
    }

    public writeToWebSocket(msgType: string, payload?: Record<string, unknown> | string | number): void {
        if (this.streamer) {
            if (!msgType.trim()) {
                this.loggerService.traceDebug('Cannot send message to web socket: no message type.');
                return;
            }

            const serializedContent = this.webSocketSerializerService.serialize({ msgType, payload });
            this.streamer.writeMessageToWebSocket(serializedContent);
        } else {
            this.loggerService.traceDebug('Cannot send message to web socket: streamer not initialized');
        }
    }

    private subscribeToWebSocketWhenLoggedIn(): void {
        this.authService.loggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.retrieveStreamer();
            } else {
                this.releaseStreamer();
            }
        });
    }

    private retrieveStreamer(): void {
        const streamerBase = this.securityCenterClientService?.scClient?.rest?.streamer;
        if (this.isSCStreamer(streamerBase)) {
            this.streamer = streamerBase;
            // eslint-disable-next-line no-underscore-dangle
            this.unsubscribeWebSocketResponse = this.streamer._unknownRequestDispatcher.subscribe((unknownRequestReceivedArgs: UnknownRequestReceivedArg) => {
                this.notifyWebSocketResponse(unknownRequestReceivedArgs);
            });
        } else {
            this.streamer = null;
            this.loggerService.traceDebug('Failed to retrieve WebSocket from streamer.');
        }
    }

    private releaseStreamer(): void {
        this.streamer = null;
        this.unsubscribeWebSocketResponse?.();
    }

    private notifyWebSocketResponse(unknownRequestReceivedArgs: UnknownRequestReceivedArg): void {
        this.responseSubject.next(unknownRequestReceivedArgs);
    }

    private isSCStreamer(streamerBase: object): streamerBase is SCStreamer {
        return '_unknownRequestDispatcher' in streamerBase && 'writeMessageToWebSocket' in streamerBase;
    }
}
