import { Component, OnInit } from '@angular/core';
import { StateMapService } from '@modules/maps/services/map/state-map-service';
import { SidePaneComponent } from '@modules/shared/components/side-pane/side-pane.component';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MapsSideContextDataService } from '../../services/maps-side-context-data/maps-side-context-data.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-maps-side-pane',
    templateUrl: 'maps-side-pane.component.html',
})
export class MapsSidePaneComponent implements OnInit {
    private miniMapTransitionDuration = 0.4;

    constructor(public contextDataService: MapsSideContextDataService, private mapService: StateMapService) {}

    ngOnInit(): void {
        this.contextDataService.currentContent$.pipe(untilDestroyed(this)).subscribe((content) => {
            if (content) {
                this.mapService?.moveMiniMapLeft(SidePaneComponent.WidthPx, this.miniMapTransitionDuration);
            } else {
                this.mapService?.restoreMiniMapLeftOffset(this.miniMapTransitionDuration);
            }
        });
    }
}
