import { MeltedIcon } from '@genetec/gelato-angular';
import { MenuSeparator } from '@shared/interfaces/context-menu-item/menu-separator';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { SignalEventDispatcher } from 'RestClient/Helpers/Helpers';
import { BehaviorSubject } from 'rxjs';
import { GuidSet, IGuid } from 'safeguid';
import { ContextTypes } from '@shared/interfaces/plugins/public/context-types';
import { Content, PluginCommand } from '@shared/interfaces/plugins/public/plugin-public.interface';
import { CommandContext, CommandDisplay, CommandsService } from '@shared/interfaces/plugins/public/plugin-services-public.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CommandUsage } from './command-usage';
import { EntityCommandsUsageContext } from './entity-commands-usage-context';
@UntilDestroy()
export class CommandsUsage {
    public readonly commandContext: EntityCommandsUsageContext;

    public commands: CommandUsage[] = [];
    public isReady$ = new BehaviorSubject<boolean>(true);
    public usageDone = new SignalEventDispatcher();

    private commandIds = new GuidSet();
    private initializingCommands = new GuidSet();
    private subscribers = new Set<() => void>();
    private specificCommandIds?: IGuid[];

    public get subscriberCount(): number {
        return this.subscribers.size;
    }

    constructor(
        private commandsService: CommandsService,
        commandContext: CommandContext,
        entityCache: IEntityCacheTask,
        public targetElement?: Element,
        specificCommandIds?: IGuid[]
    ) {
        this.commandContext = new EntityCommandsUsageContext(
            commandContext,
            entityCache,
            (commandId, canExecute?) => this.invalidateCanExecute(commandId, canExecute),
            (commandId, commandDisplay?) => this.invalidateDisplay(commandId, commandDisplay)
        );

        if (specificCommandIds && specificCommandIds.length > 0) {
            this.specificCommandIds = specificCommandIds;
        }
    }

    // TODO YN: Write tests for this...
    // still missing sub folder of sub folder
    public static createContextMenuItems(commandUsages: CommandUsage[]): ContextMenuItem[] {
        const items: ContextMenuItem[] = [];

        const allCommands = commandUsages.sort((p1, p2) => {
            // for now sub folder at start
            if (p1.command.groupName) {
                if (p2.command.groupName) {
                    return 0;
                }
                return -1;
            }

            // no section, on top
            if (!p1.command.sectionName) {
                if (p2.command.groupName) {
                    return 1;
                }
                return -1;
            }

            return 1;
        });

        const isGroupHidden = (item: ContextMenuItem): boolean => {
            let isChildVisible = false;
            for (const child of item.children ?? []) {
                if (!child.isHidden || !child.isHidden(child)) {
                    isChildVisible = true;
                    break;
                }
            }
            return !isChildVisible;
        };

        allCommands.forEach((commandUsage) => {
            let itemsToInsert = items;
            const groupName = commandUsage.command.groupName ? commandUsage.command.groupName() : undefined;
            if (groupName && groupName !== '') {
                let subMenu = items.find((item: ContextMenuItem) => item.text === groupName);
                if (!subMenu) {
                    subMenu = {
                        id: groupName,
                        text: groupName,
                        icon: commandUsage.command.groupIcon,
                        children: [],
                        iconColor: commandUsage.color(),
                        isHidden: isGroupHidden,
                    };
                    items.push(subMenu);
                }
                itemsToInsert = subMenu.children ?? [];
            }

            const sectionName = commandUsage.command.sectionName ? commandUsage.command.sectionName() : undefined;
            if (sectionName && sectionName !== '') {
                const subSection = items.findIndex((item) => item instanceof MenuSeparator);
                if (subSection === -1) {
                    itemsToInsert.push(new MenuSeparator());
                }
            }
            itemsToInsert.push(commandUsage.createMenuItem());
        });

        // return only the children if only one section...
        if (items.length === 1 && items[0].children) {
            return items[0].children;
        }

        return items;
    }

    public addCommand(command: PluginCommand): void {
        const commandId = command.id;
        if (!this.commandIds.has(commandId)) {
            if (this.isReady$.getValue()) {
                this.isReady$.next(false);
            }

            this.initializingCommands.add(commandId);
            this.commandIds.add(commandId);
            const commandUsage = new CommandUsage(command, this.commandContext, () => this.executeCommand(commandId), this.targetElement);
            this.commands.push(commandUsage);
            this.initializeCommand(commandUsage).fireAndForget();
        }
    }

    public addCommands(commands: PluginCommand[]): void {
        for (const command of commands) {
            this.addCommand(command);
        }
    }

    public async createContextMenuItemsAsync(): Promise<ContextMenuItem[]> {
        await this.waitToBeReady();
        return CommandsUsage.createContextMenuItems(this.commands);
    }

    public dispose(): void {
        this.commandContext.entityCache?.dispose().fireAndForget();
        this.commandContext.subscriptions?.unsubscribeAll();
    }

    public equals(other: CommandsUsage): boolean {
        return this === other || this.represents(other.commandContext, other.specificCommandIds, other.targetElement);
    }

    public represents(commandContext: CommandContext, specificCommandIds?: IGuid[], targetElement?: Element): boolean {
        if (commandContext.type.equals(this.commandContext.type)) {
            if ((specificCommandIds && !this.specificCommandIds) || (!specificCommandIds && specificCommandIds)) {
                return false;
            }
            if (specificCommandIds && this.specificCommandIds) {
                for (const commandId of specificCommandIds) {
                    if (!this.specificCommandIds.some((x) => x.equals(commandId))) {
                        return false;
                    }
                }
                for (const commandId of this.specificCommandIds) {
                    if (!specificCommandIds.some((x) => x.equals(commandId))) {
                        return false;
                    }
                }
            }

            if (this.targetElement && targetElement && !this.targetElement.contains(targetElement)) {
                return false;
            }

            if (commandContext.type.equals(ContextTypes.Content) && commandContext.data && this.commandContext.data) {
                return (commandContext.data as Content).id.equals((this.commandContext.data as Content).id);
            } else if (commandContext.type.equals(ContextTypes.EntityId)) {
                return (commandContext.data as IGuid).equals(this.commandContext.data as IGuid);
            } else {
                return commandContext.data === this.commandContext.data;
            }
        }
        return false;
    }

    public invalidateCanExecute(commandId: IGuid, canExecute?: boolean): void {
        const commandUsage = this.commands.find((x) => x.id.equals(commandId));
        if (commandUsage) {
            if (canExecute !== undefined) {
                commandUsage.canExecute = canExecute;
            } else {
                this.refreshCanExecuteAsync(commandUsage).fireAndForget();
            }
        }
    }

    public invalidateDisplay(commandId: IGuid, commandDisplay?: CommandDisplay): void {
        const commandUsage = this.commands.find((x) => x.id.equals(commandId));
        if (commandUsage) {
            if (commandDisplay) {
                this.setCommandDisplay(commandUsage, commandDisplay);
            } else {
                this.refreshDisplayAsync(commandUsage).fireAndForget();
            }
        }
    }

    public subscribe(): () => void {
        const unsubscribe = () => {};
        this.subscribers.add(unsubscribe);
        return () => this.unsubscribe(unsubscribe);
    }

    private async canExecuteCommandAsync(commandId: IGuid): Promise<boolean> {
        return await this.commandsService.canExecuteCommandAsync(commandId, this.commandContext);
    }

    private executeCommand(commandId: IGuid): void {
        // Force execute because isAvailable and canExecute are already evaluated at this point
        this.commandsService.forceExecuteCommand(commandId, this.commandContext, undefined, this.targetElement);
    }

    private async getCommandDisplayAsync(commandId: IGuid): Promise<CommandDisplay | undefined> {
        return await this.commandsService.getCommandDisplayAsync(commandId, this.commandContext);
    }

    private async initializeCommand(commandUsage: CommandUsage): Promise<void> {
        await this.refreshDisplayAsync(commandUsage);
        await this.refreshCanExecuteAsync(commandUsage);
        this.onCommandInitialized(commandUsage.id);
    }

    private onCommandInitialized(commandId: IGuid): void {
        this.initializingCommands.delete(commandId);
        if (this.initializingCommands.size === 0) {
            this.isReady$.next(true);
        }
    }

    private async refreshCanExecuteAsync(commandUsage: CommandUsage): Promise<void> {
        const canExecute = await this.canExecuteCommandAsync(commandUsage.id);
        commandUsage.canExecute = canExecute;
    }

    private async refreshDisplayAsync(commandUsage: CommandUsage): Promise<void> {
        const commandDisplay = await this.getCommandDisplayAsync(commandUsage.id);
        if (commandDisplay) {
            this.setCommandDisplay(commandUsage, commandDisplay);
        }
    }

    private setCommandDisplay(commandUsage: CommandUsage, commandDisplay: CommandDisplay) {
        commandUsage.name = commandDisplay.name;
        commandUsage.icon = commandDisplay.icon || MeltedIcon.None;
        commandUsage.tooltip = commandDisplay.tooltip ? commandDisplay.tooltip : () => '';
        commandUsage.isAllowed = commandDisplay.isAllowed;
    }

    private unsubscribe(subscription: () => void): void {
        this.subscribers.delete(subscription);
        if (this.subscribers.size === 0) {
            this.usageDone.dispatchAsync();
        }
    }

    private async waitToBeReady(): Promise<void> {
        if (this.isReady$.getValue()) {
            return;
        }

        let resolvePromise: () => void;
        const promise = new Promise<void>((resolve) => {
            resolvePromise = resolve;
        });
        const subscription = this.isReady$.pipe(untilDestroyed(this)).subscribe((isReady) => {
            if (isReady) {
                resolvePromise();
                subscription.unsubscribe();
            }
        });
        return promise;
    }
}
