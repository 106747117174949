import { unitOfTime } from 'moment';
import { Constants } from '@modules/shared/utilities/constants';

/**
 * Different periods for a Date filter
 */
export const DateFilterPeriod = Constants({
    Seconds: 's' as unitOfTime.DurationConstructor,
    Minutes: 'm' as unitOfTime.DurationConstructor,
    Hours: 'h' as unitOfTime.DurationConstructor,
    Days: 'd' as unitOfTime.DurationConstructor,
    Weeks: 'w' as unitOfTime.DurationConstructor,
    Months: 'M' as unitOfTime.DurationConstructor,
    Years: 'y' as unitOfTime.DurationConstructor,
});
