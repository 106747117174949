export class PlaySpeed {
  public static readonly _1x: PlaySpeed = new PlaySpeed(1);

  private readonly m_value: number;

  public get Value(): number {
    return this.m_value;
  }

  public get IsReverse(): boolean {
    return this.m_value < 0;
  }

  constructor(playbackRate: number) {
    if (playbackRate === 0) {
      throw new Error('Rate cannot be 0.');
    }
    this.m_value = playbackRate;
  }

  public toString(): string {
    return this.m_value + 'x';
  }

  public equals(other: PlaySpeed | undefined): boolean {
    if (other === undefined) {
      return false;
    }
    return this.m_value === other.m_value;
  }
}
