import { Component, OnInit } from '@angular/core';
import { ItemSlot } from '@genetec/gelato';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SafeGuid } from 'safeguid';
import { CorrelationContentTypes } from '../../../enumerations/correlation-content-types';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-correlation-datum-map-widget',
    templateUrl: './correlation-datum-map-widget.component.html',
    styleUrls: ['./correlation-datum-map-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: CorrelationDatumMapWidgetComponent,
    pluginTypes: [PluginTypes.MapPopupCompact, PluginTypes.MapPopupExpand],
    exposure: { id: CorrelationDatumMapWidgetComponent.pluginId, priority: 0 },
    isContentSupported: (content: Content) => {
        // if the showtooltip flag is not there or is true, then we can show the popup
        if (content?.type) {
            if (CorrelationContentTypes.datum.equals(content.type)) {
                if (content.parameters?.hasField(CorrelationContentTypes.showTooltip)) {
                    const shouldShow = content.parameters.getField<boolean>(CorrelationContentTypes.showTooltip);
                    return shouldShow;
                } else {
                    return true;
                }
            }
        }
        return false;
    },
})
export class CorrelationDatumMapWidgetComponent extends TrackedComponent implements OnInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('32A4C964-2E25-4805-8369-2A746C28A9F9');

    public content?: Content;
    public dataContext: unknown;

    public readonly ItemSlot = ItemSlot;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public onClick(): void {}
}
