import { MeltedIcon } from '@genetec/gelato-angular';
import { CustomIconState } from '@modules/shared/api/api';
import { EventDispatcher } from 'RestClient/Helpers/Helpers';
import { IGuid, SafeGuid } from 'safeguid';

export class BrowserItem {
    public canExpand = false;
    public children: BrowserItem[] | null = null;
    public collapsed = new EventDispatcher<BrowserItem>();
    public expanded = new EventDispatcher<BrowserItem>();
    public htmlElement!: HTMLElement;
    public icon = MeltedIcon.None;
    public image?: string;
    public isChecked = false;
    public isExpanding = false;
    public isPartialChecked?: boolean;
    public parent: BrowserItem | null = null;
    public sortPriority = -1;
    public text = '';
    public customIconId?: IGuid;
    public customIconState = CustomIconState.All;
    public itemId = SafeGuid.newGuid().toString();

    public get id(): string {
        const parentId = this.parent?.id ?? '';
        return `${parentId}.${this.itemId}`;
    }

    public get isExpanded(): boolean {
        return this.itemExpanded;
    }

    public set isExpanded(value: boolean) {
        if (!this.canExpand) {
            return;
        }

        const oldValue = this.itemExpanded;
        this.itemExpanded = value;

        if (value && !this.children && !this.isExpanding) {
            this.expanded.dispatch(this);
        } else if (!value && oldValue) {
            this.collapsed.dispatch(this);
        }
    }

    protected itemExpanded = false;
}
