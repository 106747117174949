import { RestConnection } from '../../Connection/RestConnection';

export abstract class LogonInfo {
  public authorizationHeader = '';
  public directory = '';

  constructor(directory: string) {
    this.directory = directory;
  }
}

export class BasicLogonInfo extends LogonInfo {
  public password: string;
  public username: string;

  constructor(directory: string, username: string, password: string) {
    super(directory);
    this.password = password;
    this.username = username;
    this.authorizationHeader = RestConnection.buildBasicAuthorizationHeader(username, password);
  }
}

export class TokenLogonInfo extends LogonInfo {
  constructor(directory: string, token?: string) {
    super(directory);
    if (token && !token.startsWith('Token ') && !token.startsWith('LocalToken ')) {
      this.authorizationHeader = RestConnection.buildTokenAuthorizationHeader(token);
    } else {
      this.authorizationHeader = token ?? '';
    }
  }
}
