import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNullOrUndefined } from '@genetec/web-maps';
import { Gelato, MeltedIcon } from '@genetec/gelato-angular';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface Breadcrumb<TCrumb = unknown> {
    text: string;
    icon?: MeltedIcon;
    crumb: TCrumb;
    click?: (context: Breadcrumb<TCrumb>) => void;
    selected: boolean;
}

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent<TCrumb = unknown> extends Gelato {
    //#region Properties

    @Input()
    public get breadcrumbs(): Breadcrumb<TCrumb>[] {
        return this.crumbs;
    }
    public set breadcrumbs(crumbs: Breadcrumb<TCrumb>[]) {
        this.crumbs = crumbs;
        const visible = this.getVisibleBreadcrumbs();
        this.theCache = visible;
    }

    @Input()
    public home!: Breadcrumb<TCrumb>;

    @Input()
    public moreDataIcon: MeltedIcon = MeltedIcon.EllipsisHorizontal;

    @Input()
    public truncation?: number;

    @Input()
    public levelLimit?: number;

    @Output()
    public crumbSelected = new EventEmitter<Breadcrumb<TCrumb>>();

    public get leftCrumbs(): Breadcrumb<TCrumb>[] {
        return this.theCache.left;
    }

    public get rightCrumbs(): Breadcrumb<TCrumb>[] {
        return this.theCache.right;
    }

    /**
     * Determines if the '...' item should be shown on the left of the breadcrumbs.
     */
    public get hasBreadcrumbOverflowLeft(): boolean {
        return this.theCache.overflowLeft;
    }

    /**
     * Determines if the '...' item should be shown on the right of the breadcrumbs.
     */
    public get hasBreadcrumbOverflowRight(): boolean {
        return this.theCache.overflowRight;
    }

    private theCache: { left: Breadcrumb<TCrumb>[]; right: Breadcrumb<TCrumb>[]; overflowLeft: boolean; overflowRight: boolean } = {
        left: [],
        right: [],
        overflowLeft: false,
        overflowRight: false,
    };
    private crumbs: Breadcrumb<TCrumb>[] = [];

    //#endregion

    //#region Constructor

    constructor() {
        super();
    }

    //#endregion

    //#region Public Methods

    public refreshView(): void {
        this.theCache = this.getVisibleBreadcrumbs();
    }

    /**
     * Indicates if the given breadcrumb item shoudl have a tooltip
     */
    public hasTooltip(item: Breadcrumb<TCrumb>): boolean {
        return this.truncation !== undefined && !isNullOrUndefined(item?.text) && item.text.length > this.truncation;
    }

    public getVisibleBreadcrumbs(): { left: Breadcrumb<TCrumb>[]; right: Breadcrumb<TCrumb>[]; overflowLeft: boolean; overflowRight: boolean } {
        // else a crumb is selected, compute the L/R amounts to show
        if (isNullOrUndefined(this.levelLimit) || this.levelLimit <= 0 || isNullOrUndefined(this.breadcrumbs) || this.breadcrumbs.length === 0) {
            return {
                left: this.breadcrumbs,
                right: [],
                overflowLeft: false,
                overflowRight: false,
            };
        }

        const idx = this.getSelectedIndex();
        if (idx < 0) {
            // home is selected, show leveLimit to the right
            return {
                left: this.breadcrumbs.slice(0, this.levelLimit),
                right: [],
                overflowLeft: false,
                overflowRight: this.breadcrumbs.length > this.levelLimit,
            };
        }

        let lOrR = true;
        let depth = 1;
        let nbLeft = 0;
        let nbRight = 0;
        while (nbLeft + nbRight < this.levelLimit - 1) {
            // the "selected" item is already in view & counts so we need ll-1 on the left AND right
            if (lOrR) {
                // try and add to the left
                if (idx - depth >= 0) {
                    nbLeft += 1;
                }
            } else {
                // try and add to the right
                if (idx + depth < this.breadcrumbs.length) {
                    nbRight += 1;
                }
            }

            lOrR = !lOrR;
            if (lOrR) {
                depth += 1;
            }

            nbLeft = Math.min(nbLeft, idx);
            nbRight = Math.min(nbRight, this.breadcrumbs.length - idx - 1);

            if (idx - depth < 0 && idx + depth >= this.breadcrumbs.length) {
                break;
            }
        }

        // get the number of left & right elements to show
        const nleft = nbLeft;
        const nright = nbRight;

        // push the selected item to the LEFT side of the RIGHT array
        const left = this.breadcrumbs.sub(Math.max(idx - nleft - 1, 0), nleft);
        const right = this.breadcrumbs.sub(idx, nright + 1); // the + 1 is to include the "selected" node in the "right"

        return {
            left,
            right,
            overflowLeft: nleft > 0 && idx - nleft > 0,
            overflowRight: nright > 0 && this.breadcrumbs.length - idx - nright - 1 > 0,
        };
    }

    public nbOfBreadcrumbsExceptRootToShow(): number {
        let nb = 0;
        if (isNullOrUndefined(this.levelLimit) || (!isNullOrUndefined(this.breadcrumbs) && this.levelLimit > this.breadcrumbs?.length)) {
            nb = this.breadcrumbs?.length;
        } else if (this.levelLimit > 0) {
            nb = this.levelLimit;
        }
        return nb;
    }

    /**
     * Returns true if the root should be shown
     */
    public showRoot(): boolean {
        return this.breadcrumbs?.length === 0 || isNullOrUndefined(this.levelLimit) || this.levelLimit > 0;
    }

    public getIcon(item: Breadcrumb<TCrumb>): MeltedIcon {
        return item?.icon ?? MeltedIcon.None;
    }

    public hasIcon(item: Breadcrumb<TCrumb>): boolean {
        return !isNullOrUndefined(item?.icon);
    }

    public truncate(itemName: string): string {
        return (!isNullOrUndefined(this.truncation) && this.truncation >= 0 && itemName.length > this.truncation && `${itemName.slice(0, this.truncation)}...`) || itemName;
    }

    //#endregion

    //#region Events

    public clickItem(item?: Breadcrumb<TCrumb> | undefined): void {
        if (item && !item.selected) {
            this.crumbSelected.emit(item);
        }
    }

    //#endregion

    //#region Private Methods

    private getSelectedIndex() {
        if (this.home?.selected) {
            return -1;
        }

        return this.breadcrumbs.findIndex((item) => item?.selected);
    }

    //#endregion
}
