// Autogenerated: v2/help/enumerations/ApiValidFlags?codegen=ts on 2020-07-23 3:40:07 PM

export class ValidFlags {
    public static None = 0x0;
    public static Base = 0x1;
    public static Specific = 0x2;
    public static Membership = 0x4;
    public static MemberOf = 0x8;
    public static EntityDependency = 0x10;
    public static RunningStatus = 0x20;
    public static Event2action = 0x40;
    public static Membership2 = 0x80;
    public static MemberOf2 = 0x100;
    public static PartitionOf = 0x200;
    public static EntityStatus = 0x400;
    public static AreaPeopleCount = 0x800;
    public static Membership3 = 0x1000;
    public static MemberOf3 = 0x2000;
    public static EntityDependency2 = 0x4000;
    public static EntityCustomfield = 0x8000;
    public static Behavior = 0x10000;
    public static EntityExtra = 0x20000;
    public static SpecificExtra = 0x40000;
    public static EntityMembership = 0x80000;
    public static EntityMemberOf = 0x100000;
    public static StealthData = 0x200000;
    public static EntityDependency3 = 0x400000;
    public static EntityStatus2 = 0x800000;
    public static EntityStatus3 = 0x1000000;
    public static Membership4 = 0x2000000;
    public static SyncStatus = 0x4000000;
    public static EntityInfo = 0x8000000;
    public static InformationProviders = 0x10000000;
    public static MemberOf4 = 0x20000000;
    public static MemberOfMap = 0x40000000;
    public static All = 0xFFFFFFFF;
}
