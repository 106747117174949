/* eslint-disable @typescript-eslint/prefer-regexp-exec */
// Match allows to have cleaner and simpler code as exec only counts the first itteration of the regex
import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { Flavor, GenToastService } from '@genetec/gelato-angular';
import { Icon } from '@genetec/gelato';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { IRestResponse } from 'RestClient/Client/Interface/IRestResponse';
import { IUserEntity } from 'RestClient/Client/Interface/IUserEntity';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { WebAppClient } from 'WebClient/WebAppClient';
import { ISystemConfigurationEntity } from 'RestClient/Client/Interface/ISystemConfigurationEntity';
import { SystemConfigurationEntity } from 'RestClient/Client/Model/SystemConfigurationEntity';
import { Guids } from 'RestClient/Client/Enumerations/Guids';
import { TranslateService } from '@ngx-translate/core';
import { PasswordConstraints } from 'src/app/modules/shared/models/logon-result-password-constraints';
import { UserLogin } from 'src/app/components/login/user-login.model';
import { AuthService } from 'src/app/security-center/services/authentication/auth.service';
import { CancellationToken } from 'RestClient/Helpers/Helpers';
import { PasswordStrengthClient, PasswordStrengthRequest } from '@modules/shared/api/api';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Severity } from '@genetec/gelato-angular';
import { NonRestrictedConnectionAwareModalComponent } from '@modules/shared/components/tracked/non-restricted-connection-aware-modal.component';

@UntilDestroy()
@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends NonRestrictedConnectionAwareModalComponent implements OnInit, OnDestroy {
    public isNewPasswordBlurredOrDirty = false; //TODO - Tech Debt 62284 to remove hack
    public isConfirmPasswordBlurredOrDirty = false;
    public newPassword = '';
    public oldPassword = '';
    public confirmedPassword = '';
    public readonly Icon = Icon;
    public readonly Flavor = Flavor;
    public minCharNum = 0;
    public minLowerCase = 0;
    public minUpperCase = 0;
    public minSpecialChar = 0;
    public minNumericalChar = 0;
    public isInvalidCredentials = false;
    public isPasswordsMatching = false;
    public isCharNumMatch = false;
    public isLowerCaseMatch = false;
    public isUpperCaseMatch = false;
    public isSpecialCharMatch = false;
    public isNumericalCharMatch = false;
    public isPasswordAccepted = false;
    public isPasswordEqualToUserName = false;
    public isOldPasswordEqualToNew = false;
    public passwordStrengthScore = 0;
    public complexityMessage = this.translateService.instant('STE_LABEL_PASSWORD_STRENGTH_VERY_WEAK') as string;
    public progressBarPercent = '0%';
    public complexityMessageSeverity = Severity.Error;

    // required to open the modal from login component
    public isExpired?: boolean;
    public username?: string;
    private directory?: string;

    private specialRegex = /[^A-Za-z0-9]/g;
    private numericalRegex = /[0-9]/g;
    private upperCaseRegex = /[A-Z]/g;
    private lowerCaseRegex = /[a-z]/g;
    private user: IUserEntity | null = null;
    private logonResultPasswordConstraint?: PasswordConstraints;
    private passwordConstraintRest: ISystemConfigurationEntity | null = null;
    private cancellationToken?: CancellationToken;
    private response: IRestResponse | null = null;
    private scClient: WebAppClient;

    private evaluatePasswordStrength: Subject<string> = new Subject<string>();

    constructor(
        injector: Injector,
        trackingService: TrackingService,
        securityCenterClientService: SecurityCenterClientService,
        protected authService: AuthService,
        private genToastService: GenToastService,
        private translateService: TranslateService,
        private passwordStrenghClient: PasswordStrengthClient
    ) {
        super(authService, injector, trackingService);
        this.scClient = securityCenterClientService?.scClient;
    }

    public async ngOnInit() {
        super.ngOnInit();
        await this.initializePasswordConstraint();
        if (!this.isExpired) {
            this.user = await this.scClient.getEntityAsync<UserEntity, IUserEntity>(UserEntity, this.scClient.userId);
        }

        this.evaluatePasswordStrength.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this)).subscribe(async (value) => await this.updatePasswordStrength(value));
    }

    public onOldPassword(oldPasswordInput: string): void {
        this.verifyPasswordConstraint();
    }

    public onNewPassword(newPasswordInput: string): void {
        this.isNewPasswordBlurredOrDirty = true;

        this.verifyPasswordConstraint();

        if (this.confirmedPassword !== '') {
            this.isPasswordsMatching = this.newPassword === this.confirmedPassword;
        }

        this.evaluatePasswordStrength.next(this.newPassword);
    }

    public onConfirmedPassword(confirmedPasswordInput: string): void {
        this.isConfirmPasswordBlurredOrDirty = true;
        this.confirmedPassword = confirmedPasswordInput;
        this.isPasswordsMatching = this.newPassword === this.confirmedPassword;
    }

    public onNewPasswordBlurred(): void {
        this.isNewPasswordBlurredOrDirty = true;
    }

    public onConfirmPasswordBlurred(): void {
        this.isConfirmPasswordBlurredOrDirty = true;
    }

    public verifyPasswordConstraint(): void {
        this.isLowerCaseMatch = (this.newPassword.match(this.lowerCaseRegex) || []).length >= this.minLowerCase;
        this.isUpperCaseMatch = (this.newPassword.match(this.upperCaseRegex) || []).length >= this.minUpperCase;
        this.isNumericalCharMatch = (this.newPassword.match(this.numericalRegex) || []).length >= this.minNumericalChar;
        this.isSpecialCharMatch = (this.newPassword.match(this.specialRegex) || []).length >= this.minSpecialChar;
        this.isCharNumMatch = this.newPassword.length >= this.minCharNum;
        this.isOldPasswordEqualToNew = this.oldPassword.trim() === this.newPassword.trim();
        if (!this.isExpired) {
            this.isPasswordEqualToUserName =
                this.newPassword !== '' &&
                (this.same(this.user?.name, this.newPassword) || this.same(this.user?.lastName, this.newPassword) || this.same(this.user?.firstName, this.newPassword));
        } else {
            this.isPasswordEqualToUserName = this.newPassword !== '' && this.same(this.username, this.newPassword); //TODO : make last name and first name accessible in the login page
        }
        this.isPasswordAccepted =
            this.isCharNumMatch &&
            this.isSpecialCharMatch &&
            this.isNumericalCharMatch &&
            this.isUpperCaseMatch &&
            this.isLowerCaseMatch &&
            !this.isPasswordEqualToUserName &&
            !this.isOldPasswordEqualToNew;
    }

    public onSave = async (): Promise<boolean> => {
        try {
            if (this.isExpired && this.directory && this.username) {
                this.response = await this.scClient.changeExpiredPasswordAsync(this.directory, this.username, this.oldPassword, this.newPassword);
            } else if (this.user != null) {
                this.response = await this.user.safeUpdatePasswordAsync(this.newPassword, this.oldPassword);
            }
        } catch {
            this.isInvalidCredentials = true;
            return false;
        }
        if (this.response?.statusCode === HttpStatusCode.OK) {
            const msg = this.translateService.instant('STE_LABEL_SUCCESSFUL_OPERATION') as string;
            this.genToastService.show({ text: msg, flavorCustomColor: this.Color.Pistacchio });
            if (this.isExpired) {
                this.authService.isSigningIn(true);
                const userLogin = new UserLogin(this.username, this.newPassword);
                this.cancellationToken = new CancellationToken(AuthService.defaultTimeout);
                this.authService.logInWithCredentialsAsync(userLogin, this.cancellationToken).fireAndForget();
            }

            return true;
        }

        this.authService.isSigningIn(false);
        return false;
    };

    public async initializePasswordConstraint(): Promise<void> {
        if (this.isExpired && this.logonResultPasswordConstraint) {
            this.minCharNum = this.logonResultPasswordConstraint.MinCharacters;
            this.minLowerCase = this.logonResultPasswordConstraint.MinLowerCharacters;
            this.minUpperCase = this.logonResultPasswordConstraint.MinUpperCharacters;
            this.minSpecialChar = this.logonResultPasswordConstraint.MinSpecialCharacters;
            this.minNumericalChar = this.logonResultPasswordConstraint.MinNumericalCharacters;
        } else {
            this.passwordConstraintRest = await this.scClient.getEntityAsync<SystemConfigurationEntity, ISystemConfigurationEntity>(
                SystemConfigurationEntity,
                Guids.SystemConfiguration
            );
            if (this.passwordConstraintRest) {
                this.minCharNum = this.passwordConstraintRest.passwordMinCharacter;
                this.minLowerCase = this.passwordConstraintRest.passwordMinLowerCharacter;
                this.minUpperCase = this.passwordConstraintRest.passwordMinUpperCharacter;
                this.minSpecialChar = this.passwordConstraintRest.passwordMinSpecialCharacter;
                this.minNumericalChar = this.passwordConstraintRest.passwordMinNumericalCharacter;
            }
        }
    }
    public getComplexityPasswordInfo(score: number): void {
        switch (score) {
            case 0: {
                this.complexityMessage = this.translateService.instant('STE_LABEL_PASSWORD_STRENGTH_VERY_WEAK') as string;
                //this.progressBarPercent = '20%';
                this.complexityMessageSeverity = Severity.Error;
                break;
            }
            case 1: {
                this.complexityMessage = this.translateService.instant('STE_LABEL_PASSWORD_STRENGTH_WEAK') as string;
                //this.progressBarPercent = '40%';
                this.complexityMessageSeverity = Severity.Error;
                break;
            }
            case 2: {
                this.complexityMessage = this.translateService.instant('STE_LABEL_PASSWORD_STRENGTH_FAIR') as string;
                //this.progressBarPercent = '60%';
                this.complexityMessageSeverity = Severity.Warning;
                break;
            }
            case 3: {
                this.complexityMessage = this.translateService.instant('STE_LABEL_PASSWORD_STRENGTH_STRONG') as string;
                //this.progressBarPercent = '80%';
                this.complexityMessageSeverity = Severity.Success;
                break;
            }
            case 4: {
                this.complexityMessage = this.translateService.instant('STE_LABEL_PASSWORD_STRENGTH_VERY_STRONG') as string;
                //this.progressBarPercent = '100%';
                this.complexityMessageSeverity = Severity.Success;
                break;
            }
            default: {
                this.complexityMessage = this.translateService.instant('STE_LABEL_PASSWORD_STRENGTH_VERY_WEAK') as string;
                //this.progressBarPercent = '0%';
                this.complexityMessageSeverity = Severity.Error;
                break;
            }
        }
    }

    private async updatePasswordStrength(newPassword: string): Promise<void> {
        if (this.isPasswordEqualToUserName) {
            this.passwordStrengthScore = 0;
        } else {
            const req = new PasswordStrengthRequest({ newPassword });
            this.passwordStrengthScore = await this.passwordStrenghClient.passwordStrength(req).toPromise();
        }

        this.getComplexityPasswordInfo(this.passwordStrengthScore);
    }

    /** Compare 2 strings. Returns true if they are the same (case insensitive) */
    private same(first?: string, second?: string): boolean {
        if (first === undefined || second === undefined) {
            return false;
        }

        return first.toUpperCase() === second.toUpperCase();
    }
}
