import 'reflect-metadata';
import { IGuid } from 'safeguid';
import { ModuleService } from '../../../services/module/module.service';

/**
 *  Used to identify and set properties for a module
 */
export interface ModuleDescriptor {
    type: Constructor;
    inclusions?: ModuleInclusions;
}

type Constructor<T = any> = new (...args: any[]) => T;

export interface ModuleInclusions {
    /**
     *  Privileges that are included in the claims. Privileges are not mandatory for the module to be loaded.
     */
    globalPrivileges?: IGuid[];
}

// #region Decorators for modules
/**
 * Metadata key to identify the data inserted using reflection for the ModuleDescriptor decorator
 */
export const MetadataKey = 'ModuleDescriptorMetadataKey';
export const ModuleDescriptor = (descriptor: ModuleDescriptor): ((constructor: new (...args: any[]) => any) => void) => {
    ModuleService.availableModules.push(descriptor.type);
    return (constructor: new (...args: any[]) => any) => {
        Reflect.defineMetadata(MetadataKey, descriptor, constructor);
    };
};
