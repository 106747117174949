import { IGuid } from 'safeguid';
import { toGuid } from "../utils/guid-utils";

export interface OptionApi {
    Id: IGuid;
    Name: string;
}
export class Option {
    constructor(public id: IGuid, public name: string) {}

    public static assign(option: OptionApi): Option {
        return new Option(toGuid(option.Id), option.Name);
    }
}
