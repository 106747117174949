// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class Constants {
    static readonly baseUrlIdentifier = 'BASE_URL';

    static readonly pluginsBaseUrl = 'assets/plugins/';
    static readonly pluginTaskIdentifier = 'pluginId';
    static readonly subTaskIdentifier = 'subTaskId';

    static readonly altText = 'Alt';
    static readonly ctrlText = 'Ctrl';
    static readonly shiftText = 'Shift';

    static readonly accessToken = 'access_token';
    static readonly isWebAppTokenSet = 'WebApp.IsTokenSet';
}
