import { List } from 'immutable';
import { IGuid, SafeGuid } from 'safeguid';
import { toGuid } from '../utils/guid-utils';
import { ListResult, ListResultApi } from './list-result';
interface StateTranstitionApi {
    Id: string;
    Enabled: boolean;
}

class StateTranstition {
    constructor(public id: IGuid, public enabled: boolean) {}
}

export interface PossibleStateTransitionListApi extends ListResultApi<StateTranstitionApi> {
    NextState?: StateTranstitionApi;
}

export class PossibleStateTransitionList extends ListResult<StateTranstition> {
    constructor(public nextState: StateTranstition | null, results: List<StateTranstition>, totalResultsCount: number | null) {
        super(totalResultsCount, results);
    }

    public static assign(stateTransition: PossibleStateTransitionListApi): PossibleStateTransitionList {
        const nextState = stateTransition.NextState ? new StateTranstition(toGuid(stateTransition.NextState.Id), stateTransition.NextState.Enabled) : null;
        return new PossibleStateTransitionList(
            nextState,
            List(stateTransition.Results.map((x) => new StateTranstition(toGuid(x.Id), x.Enabled))),
            stateTransition.TotalResultsCount ?? null
        );
    }
}
