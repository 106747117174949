// Autogenerated: v2/help/enumerations/ApiZoneState?codegen=ts&showresources=False on 2021-03-02 10:20:51 AM

export class ZoneState {
    public static Unknown = 'Unknown';
    public static Normal = 'Normal';
    public static Active = 'Active';
    public static Trouble = 'Trouble';

    public static readonly values = [
        ZoneState.Unknown,
        ZoneState.Normal,
        ZoneState.Active,
        ZoneState.Trouble
    ];
}
