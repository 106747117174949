import { Inject, Injectable } from '@angular/core';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import { COMMANDS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { CommandUsage } from '@modules/shared/services/commands/commands-usage/command-usage';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { ContentProviderService } from '@modules/shared/services/content/content-provider.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, BehaviorSubject, Subject, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class RowActionsService {
    public expandedCommandUsages$: Observable<CommandUsage[]>;
    public collapsedCommandUsages$: Observable<CommandUsage[]>;
    public areRowActionsReady$: Observable<boolean>;

    private maximumVisibleCommands?: number | undefined;
    private loadedCommandUsages = new Map<string, CommandUsage[]>();

    private currentRowDatumKeySubject = new Subject<string>();
    private expandedCommandUsagesSubject = new BehaviorSubject<CommandUsage[]>([]);
    private collapsedCommandUsagesSubject = new BehaviorSubject<CommandUsage[]>([]);
    private areRowActionsReadySubject = new BehaviorSubject<boolean>(false);

    constructor(@Inject(COMMANDS_SERVICE) private commandsService: InternalCommandsService, private contentProviderService: ContentProviderService) {
        this.expandedCommandUsages$ = this.expandedCommandUsagesSubject.asObservable().pipe(untilDestroyed(this));
        this.collapsedCommandUsages$ = this.collapsedCommandUsagesSubject.asObservable().pipe(untilDestroyed(this));
        this.areRowActionsReady$ = this.areRowActionsReadySubject.asObservable().pipe(untilDestroyed(this));

        this.currentRowDatumKeySubject
            .pipe(
                switchMap((datumKey: string) => from(this.loadRowActions(datumKey))),
                untilDestroyed(this)
            )
            .subscribe((commandUsages: CommandUsage[]) => {
                this.areRowActionsReadySubject.next(true);
                this.sortCommandUsagesByVisibility(commandUsages);
            });
    }

    public setCurrentRowDatumKey(newDatumKey: string): void {
        this.currentRowDatumKeySubject.next(newDatumKey);
    }

    public setMaximumVisibleCommands(maximumVisibleCommands: number): void {
        this.maximumVisibleCommands = maximumVisibleCommands;
    }

    private async loadRowActions(datumKey: string): Promise<CommandUsage[]> {
        // Tell subscribers we are loading the content
        this.areRowActionsReadySubject.next(false);

        // Check if actions for this row have already been loaded
        const commandUsages = this.loadedCommandUsages.get(datumKey);
        if (commandUsages) return commandUsages;

        // Get content
        const content = await this.contentProviderService.getContentAsync(datumKey);
        if (!content?.mainContent) return [];

        // Get commands usage
        const commandsUsage = await this.commandsService.getCommandsUsage({ type: ContextTypes.Content, data: content.mainContent }, undefined, undefined);
        this.loadedCommandUsages.set(datumKey, commandsUsage.commands);

        return commandsUsage.commands;
    }

    private sortCommandUsagesByVisibility(commandUsages: CommandUsage[]): void {
        if (!this.maximumVisibleCommands) {
            this.expandedCommandUsagesSubject.next(commandUsages);
            return;
        }

        const expandedCommandUsages: CommandUsage[] = [];
        const collapsedCommandUsages: CommandUsage[] = [];
        for (const [index, commandUsage] of commandUsages.entries()) {
            if (index < this.maximumVisibleCommands) {
                expandedCommandUsages.push(commandUsage);
            } else {
                collapsedCommandUsages.push(commandUsage);
            }
        }

        this.expandedCommandUsagesSubject.next(expandedCommandUsages);
        this.collapsedCommandUsagesSubject.next(collapsedCommandUsages);
    }
}
