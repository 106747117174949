import { State, StateContext } from '@ngxs/store';
import { EmitterAction } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';
import { Receiver, Selector } from '../../../../../store';
import { ColumnDescriptor, InvestigateDataStateModel as InvestigateDataStateModel, WhatFilter, WhenFilter, WhereFilter } from './investigate-data.interfaces';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@State<InvestigateDataStateModel>({
    name: 'investigateDataState',
    defaults: { whatFilter: null, whenFilter: null, whereFilter: null, columns: [], tableBusy: false },
})
@Injectable()
export class InvestigateDataState {
    @Selector()
    public static whatFilter(state: InvestigateDataStateModel): WhatFilter | null {
        return state.whatFilter;
    }

    @Selector()
    public static whenFilter(state: InvestigateDataStateModel): WhenFilter | null {
        return state.whenFilter;
    }

    @Selector()
    public static whereFilter(state: InvestigateDataStateModel): WhereFilter | null {
        return state.whereFilter;
    }

    @Selector()
    public static columns(state: InvestigateDataStateModel): ColumnDescriptor[] {
        return state.columns;
    }

    @Selector()
    public static tableBusy(state: InvestigateDataStateModel): boolean {
        return state.tableBusy ?? false;
    }

    @Receiver()
    public static setWhatFilter({ setState }: StateContext<InvestigateDataStateModel>, { payload }: EmitterAction<WhatFilter>): void {
        setState((state: InvestigateDataStateModel) => {
            state.whatFilter = payload;
            return state;
        });
    }

    @Receiver()
    public static setWhenFilter({ setState }: StateContext<InvestigateDataStateModel>, { payload }: EmitterAction<WhenFilter>): void {
        setState((state: InvestigateDataStateModel) => {
            state.whenFilter = payload;
            return state;
        });
    }

    @Receiver()
    public static setWhereFilter({ setState }: StateContext<InvestigateDataStateModel>, { payload }: EmitterAction<WhereFilter>): void {
        setState((state: InvestigateDataStateModel) => {
            state.whereFilter = payload;
            return state;
        });
    }

    @Receiver()
    public static setColumns({ setState }: StateContext<InvestigateDataStateModel>, { payload }: EmitterAction<ColumnDescriptor[]>): void {
        setState((state: InvestigateDataStateModel) => {
            state.columns = payload;
            return state;
        });
    }

    @Receiver()
    public static setTableBusy({ setState }: StateContext<InvestigateDataStateModel>, { payload }: EmitterAction<boolean>): void {
        setState((state: InvestigateDataStateModel) => {
            state.tableBusy = payload;
            return state;
        });
    }
}
