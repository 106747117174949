import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EntityBrowserComponent } from '@modules/shared/components/entity-browser/entity-browser/entity-browser.component';
import { EntityBrowserCheckSelection } from '@modules/shared/entity-browser/entity-browser-selection';
import { EntityBrowserFilter } from '@modules/shared/entity-browser/filters/entity-browser-filter';
import { FilterContext } from '@modules/shared/services/filters/filter';
import { focusDomElement } from '@modules/shared/utilities/dom-helper';
import { IGuid, SafeGuid } from 'safeguid';
import { FilterBaseComponent } from '../filter-base.component';
import { EntityFilterModel } from './filter-entity.model';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

/**
 * Filter restraining with an Entity Browser to select target entities
 */
@Component({
    selector: 'app-filter-entity',
    templateUrl: './filter-entity.component.html',
    styleUrls: ['./filter-entity.component.scss'],
})
export class FilterEntityComponent extends FilterBaseComponent<EntityFilterModel> implements OnInit {
    //#region Public fields
    @Input() public entityTypes: string[] = [];
    @Input() public refresherType?: IGuid;
    @Input() public alwaysShowCheckedItemsActions = false;
    @ViewChild('browser') public browser?: EntityBrowserComponent;

    @Input() public entityBrowserFilter?: EntityBrowserFilter;
    //#endregion

    //#region Private fields
    private isBrowserRefreshRequired = true;
    //#endregion

    //#region Public methods

    ngOnInit() {
        super.ngOnInit();
        this.value = {
            includedEntities: [],
            excludedEntities: [],
        };
    }

    public onBrowserCheckedEntitiesChanged(selectedEntities: EntityBrowserCheckSelection): void {
        this.value = {
            includedEntities: selectedEntities.ids.map((guid) => new SafeGuid(guid)),
            excludedEntities: selectedEntities.exclusions.map((guid) => new SafeGuid(guid)),
        };
    }

    public onFilterToggled(isFilterOpened: boolean): void {
        super.onFilterToggled(isFilterOpened);

        if (isFilterOpened) {
            focusDomElement('.entity-browser-wrapper .browser-search .gen-input');
            if (this.isBrowserRefreshRequired) {
                this.refreshEntityList();
            }
        }
    }

    public updateState(): void {
        const selectedItems = this?.browser?.checkedEntities.items;
        let selection = this.state.selection;

        if (!selectedItems?.length) {
            selection = this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string;
        } else if (selectedItems.length === 1) {
            selection = selectedItems[0].name;
        } else {
            selection = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: selectedItems.length }) as string;
        }

        //Apply warning message if necessary
        const message = this.isCoveringAllSystemInReportsContext() ? (this.translateService.instant('STE_LABEL_WARNING_ENTITY_FILTER_SEARCH_TOO_LONG') as string) : '';

        this.state = { status: this.isCoveringAllSystemInReportsContext() ? 'Warning' : 'Ok', selection, message };
    }

    public clearFilter(): void {
        this.browser?.onUncheckAllItemsClicked();
    }

    public isDefaulted(): boolean {
        return this.value.excludedEntities.length === 0 && this.value.includedEntities.length === 0;
    }

    public override isDirty(firstValue: EntityFilterModel, secondValue: EntityFilterModel): boolean {
        const arr1 = firstValue.includedEntities.map((guid: SafeGuid) => guid.toString());
        const arr2 = secondValue.includedEntities.map((guid: SafeGuid) => guid.toString());

        if (arr1.length !== arr2.length) {
            return true;
        }

        // compare each item
        return arr1.some((value, index) => arr2.indexOf(value) === -1);
    }

    public override getDefaultValue(): EntityFilterModel {
        return { includedEntities: [], excludedEntities: [] };
    }

    //#endregion

    //#region Private methods

    private isCoveringAllSystemInReportsContext() {
        return this.isPrimary && this.isDefaulted() && this.filterCoordinatorService.context === FilterContext.Reports;
    }

    private refreshEntityList() {
        const filter = new EntityBrowserFilter(this.entityTypes);
        if (this.refresherType && !this.refresherType.equals(SafeGuid.EMPTY)) {
            filter.refresherType = this.refresherType;
        }
        this.entityBrowserFilter = filter;
        this.isBrowserRefreshRequired = false;
    }

    //#endregion
}
