// Autogenerated: v2/help/enumerations/ApiRecordingState?codegen=ts&showresources=False on 2020-07-23 3:40:06 PM

export class RecordingState {
    public static OffLocked = 'OffLocked';
    public static Off = 'Off';
    public static OnLocked = 'OnLocked';
    public static On = 'On';
    public static OnAboutToStop = 'OnAboutToStop';
    public static Problem = 'Problem';

    public static readonly values = [
        RecordingState.OffLocked,
        RecordingState.Off,
        RecordingState.OnLocked,
        RecordingState.On,
        RecordingState.OnAboutToStop,
        RecordingState.Problem
    ];
}
