import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { DomPortal } from '@angular/cdk/portal';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../analytics/analytics.service';
import { NavigationService } from '../navigation/navigation.service';

/**
 * @description
 * This service allows content to be set as a DOMportal to be displayed in a portal outlet.
 */
@UntilDestroy()
@Injectable()
export class ContentOverlayService {
    public contentOverlay$: Observable<DomPortal<Element> | null>;
    private contentOverlaySubject = new BehaviorSubject<DomPortal<Element> | null>(null);

    constructor(private analyticsService: AnalyticsService, private navigationService: NavigationService) {
        this.contentOverlay$ = this.contentOverlaySubject.asObservable();
        this.navigationService.routeChanging$.pipe(untilDestroyed(this)).subscribe(() => this.clearOverlay());
    }

    /**
     * @description
     * This method will set the the new content for the portal outlet and hide the content's widget in the side pane
     */
    public setOverlay(overlay: Element | ElementRef<Element>): void {
        this.contentOverlaySubject.next(new DomPortal(overlay));
        this.analyticsService.logEvent('Content overlay set');
    }

    /**
     * @description
     * This method will remove the DOMportal from the portal outlet and make the side pane widget visible again
     */
    public clearOverlay(): void {
        this.contentOverlaySubject.next(null);

        this.analyticsService.logEvent('Content overlay cleared');
    }

    /**
     * Returns whether or not content is currently in the content overlay.
     *
     * @returns **True** if the content overlay has been set a portal, **false** if not or is cleared.
     */
    public hasOverlay(): boolean {
        return !!this.contentOverlaySubject.getValue();
    }
}
