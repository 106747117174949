<div *ngIf="isInitialized; else spinner">
    <ng-container *ngIf="isContentVisible; else insufficientPrivileges">
        <gen-combobox
            data-cy="languageCombo"
            class="d-block mb-2 w-50 focus-style-override"
            genLabel="{{ 'STE_LABEL_LANGUAGE' | translate }}"
            [genItemsSource]="languages"
            [(ngModel)]="selectedLanguage"
            (ngModelChange)="onSelectedLanguageChanged($event)"
            name="languageCombo"
        >
        </gen-combobox>

        <gen-separator class="pt-1 pb-1"></gen-separator>

        <div class="mb-2 mt-2">
            <gen-radio
                [ngModel]="selectedTimezoneMode.toString()"
                [value]="timezoneMode.Device.toString()"
                (ngModelChange)="onUseDeviceTimezoneChanged($event)"
                name="tzGroup"
                class="d-block"
            >
                <gen-label>{{ 'STE_LABEL_USE_DEVICE_TIME_ZONE' | translate }}</gen-label>
            </gen-radio>
            <gen-radio
                [ngModel]="selectedTimezoneMode.toString()"
                [value]="timezoneMode.Specific.toString()"
                (ngModelChange)="onUseDeviceTimezoneChanged($event)"
                name="tzGroup"
                class="d-block"
            >
                <gen-label>{{ 'STE_LABEL_USE_SPECIFIC_TIME_ZONE' | translate }}</gen-label>
            </gen-radio>
            <gen-combobox
                class="d-block ml-4 focus-style-override"
                [disabled]="selectedTimezoneMode === timezoneMode.Device"
                [genItemsSource]="timezones"
                [(ngModel)]="selectedTimezone"
                (ngModelChange)="onSelectedTimezoneChanged($event)"
                name="timezoneCombo"
            >
            </gen-combobox>
        </div>

        <div class="mb-2">
            <gen-checkbox [(ngModel)]="isTimezoneDisplayed" (ngModelChange)="onDisplayTimezoneChanged($event)" name="timezomeCheckbox">
                <gen-label>
                    {{ 'STE_CHECKBOX_DISPLAYTIMEZONE' | translate }}
                </gen-label>
            </gen-checkbox>
        </div>
    </ng-container>
</div>

<ng-template #insufficientPrivileges>
    <app-insufficient-privileges></app-insufficient-privileges>
</ng-template>

<ng-template #spinner>
    <div class="ha-center"><gen-spinner></gen-spinner></div
></ng-template>
