import { IGuid, SafeGuid } from 'safeguid';
import { ContentExtensionServicesProvider, ExtensionService } from '../../interfaces/plugins/public/plugin-services-public.interface';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
// This service is used to be able to add service bound to a content
export class ContentServicesProviderService implements ContentExtensionServicesProvider {
    private contentServices = new Map<string, ExtensionService[]>();

    public getService<T extends ExtensionService>(contentId: IGuid): T | undefined {
        const services: ExtensionService[] | undefined = this.contentServices.get(contentId.toString());

        if (services === undefined) {
            return undefined;
        }

        return services.find((item) => (item as T) !== undefined) as T;
    }

    public setService<T extends ExtensionService>(contentId: IGuid, contentService: T): void {
        let services: ExtensionService[] | undefined = this.contentServices.get(contentId.toString());

        if (services === undefined) {
            services = [];
            this.contentServices.set(contentId.toString(), services);
        }

        const existingService = services.find((item) => (item as T) !== undefined);
        if (existingService) {
            this.removeService<T>(contentId);
        }

        services.push(contentService);
    }

    public removeService<T extends ExtensionService>(contentId: IGuid): boolean {
        const services: ExtensionService[] | undefined = this.contentServices.get(contentId.toString());

        if (services === undefined) {
            return false;
        }

        const service = services.find((item) => (item as T) !== undefined);
        if (!service) {
            return false;
        }

        const indexOfService = services.indexOf(service);
        if (indexOfService === -1) {
            return false;
        }

        services.splice(indexOfService, 1);
        return true;
    }
}
