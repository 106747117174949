<ng-template #ingestStringTemplate let-params="params">
    <gen-text-input [id]="params.id" [(ngModel)]="params.value" (ngModelChange)="onValueChanged()">
        <gen-label>{{ params.display }}</gen-label>
    </gen-text-input>
</ng-template>
<ng-template #ingestIntegerTemplate let-params="params">
    <gen-numeric-input [id]="params.id" [(ngModel)]="params.value" (ngModelChange)="onValueChanged()" [step]="1" [min]="-2147483648" [max]="2147483648">
        <gen-label>{{ params.display }}</gen-label>
    </gen-numeric-input>
</ng-template>
<ng-template #ingestLongTemplate let-params="params">
    <gen-numeric-input [id]="params.id" [(ngModel)]="params.value" (ngModelChange)="onValueChanged()" [step]="1">
        <gen-label>{{ params.display }}</gen-label>
    </gen-numeric-input>
</ng-template>
<ng-template #ingestDoubleTemplate let-params="params">
    <gen-numeric-input [id]="params.id" [(ngModel)]="params.value" (ngModelChange)="onValueChanged()" [step]="0.00001">
        <gen-label>{{ params.display }}</gen-label>
    </gen-numeric-input>
</ng-template>
<ng-template #ingestDateTimeTemplate let-params="params">
    <gen-datetime-input
        [id]="params.id"
        [(ngModel)]="params.value"
        (ngModelChange)="onValueChanged()"
        [genFlavor]="Flavor.Calendar.DateTime"
        [genLabel]="params.display"
        genFormat="L LT"
    ></gen-datetime-input>
</ng-template>
<ng-template #ingestBooleanTemplate let-params="params">
    <gen-switch [id]="params.id" [(ngModel)]="params.value" (ngModelChange)="onValueChanged()">
        <gen-label>{{ params.display }}</gen-label>
    </gen-switch>
</ng-template>
<ng-template #ingestEntityTemplate let-params="params">
    <app-entity-combo [id]="params.id" [(entityId)]="params.value" (entityIdChange)="onValueChanged()" [genLabel]="params.display" [showClearButton]="true"></app-entity-combo>
</ng-template>
<ng-template #ingestBinaryFileTemplate let-params="params">
    <gen-file-input [id]="params.id" [(ngModel)]="params.value" (ngModelChange)="onValueChanged()" [genLabel]="params.display" genExtensions="*"></gen-file-input>
</ng-template>
<ng-template #ingestLargeStringTemplate let-params="params">
    <gen-text-input [id]="params.id" [(ngModel)]="params.value" (ngModelChange)="onValueChanged()" [multiline]="5">
        <gen-label>{{ params.display }}</gen-label>
    </gen-text-input>
</ng-template>

<ng-template #pleaseSelectADataType>
    <gen-item class="pt-1">
        <gen-text>{{ 'STE_LABEL_SELECTADATATYPE' | translate }}</gen-text>
    </gen-item>
</ng-template>

<gen-melted-modal
    genTitle="{{ 'STE_ACTION_INGESTDATA' | translate }}"
    genDefaultActionText="{{ 'STE_BUTTON_SAVE' | translate }}"
    genAlternativeActionText="{{ 'STE_BUTTON_CANCEL' | translate }}"
    [genCanClose]="true"
    [genIsDefaultActionDisabled]="cannotSave"
    [genIsBlocking]="true"
>
    <gen-melted-modal-body>
        <div style="min-height: 35vh; max-height: 50vh; overflow: auto; min-width: 25vw">
            <gen-combobox
                id="ingestion-data-type-combo"
                [ngModel]="selectedTypeItem"
                (ngModelChange)="onSelectedTypeChanged($event)"
                [genItemsSource]="types"
                genLabel="{{ 'STE_LABEL_NOUN_RECORDTYPE' | translate }}"
                [genLabelComplement]="LabelComplement.Required"
                [genFlavor]="Flavor.Combobox.Solid"
                [genPosition]="Position.Combobox.BottomLeft"
                appTooltip="{{ 'STE_TOOLTIP_SELECT_RECORD_TYPE' | translate }}"
            >
            </gen-combobox>

            <!-- Render this div only if a schema has been selected, inside will lie the appropriate controls -->
            <ng-container *ngIf="selectedSchema; else pleaseSelectADataType">
                <div *ngFor="let field of fields">
                    <ng-container [ngSwitch]="field.type">
                        <div *ngSwitchCase="'Integer'">
                            <ng-container *ngTemplateOutlet="ingestIntegerTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'Long'">
                            <ng-container *ngTemplateOutlet="ingestLongTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'Double'">
                            <ng-container *ngTemplateOutlet="ingestDoubleTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'DateTime'">
                            <ng-container *ngTemplateOutlet="ingestDateTimeTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'Boolean'">
                            <ng-container *ngTemplateOutlet="ingestBooleanTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'Entity'">
                            <ng-container *ngTemplateOutlet="ingestEntityTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'Base64Binary'">
                            <ng-container *ngTemplateOutlet="ingestBinaryFileTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'LargeString'">
                            <ng-container *ngTemplateOutlet="ingestLargeStringTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchCase="'File'">
                            <ng-container *ngTemplateOutlet="ingestBinaryFileTemplate; context: { params: field }"></ng-container>
                        </div>
                        <div *ngSwitchDefault>
                            <ng-container *ngTemplateOutlet="ingestStringTemplate; context: { params: field }"></ng-container>
                        </div>
                    </ng-container>
                </div>

                <!-- Advanced settings section -->
                <gen-expander [(genIsOpen)]="advanced" genText="{{ 'STE_LABEL_ADVANCED_OPTIONS' | translate }}">
                    <gen-expander-content>
                        <div class="gen-row import-data-dlg">
                            <div class="gen-col-xs-12 vertical-align-center" *ngIf="isUpdate">
                                <gen-text [flavor]="TextFlavor.Label" class="ha-left">
                                    {{ 'STE_LABEL_ABBREV_IDENTIFIER' | translate }}
                                </gen-text>
                                <gen-text class="ha-left">
                                    {{ sourceId }}
                                </gen-text>
                            </div>
                            <div class="gen-col-xs-9 gen-col-sm-10 gen-col-md-11 vertical-align-center" *ngIf="!isUpdate">
                                <gen-text-input id="ingestion-data-source-id" [(ngModel)]="sourceId" (ngModelChange)="onValueChanged()">
                                    <gen-label>{{ 'STE_LABEL_ABBREV_IDENTIFIER' | translate }}</gen-label>
                                </gen-text-input>
                            </div>
                            <!-- Only display if the user didn't set a re-load record -->
                            <div class="gen-col-xs-3 gen-col-sm-2 gen-col-md-1 vertical-align-bottom" *ngIf="!isUpdate">
                                <gen-button (click)="regenerateId($event)" appTooltip="{{ 'STE_TOOLTIP_BUTTON_REGENERATERANDOMID' | translate }}">
                                    <gen-icon [icon]="Icon.Reload" [size]="IconSize.Medium"></gen-icon>
                                </gen-button>
                            </div>
                        </div>

                        <div *ngIf="time">
                            <gen-datetime-input
                                id="ingestion-data-time"
                                [(ngModel)]="time"
                                (ngModelChange)="onValueChanged()"
                                genLabel="{{ 'STE_LABEL_TIME' | translate }}"
                                genFormat="L LT"
                                [genFlavor]="Flavor.Calendar.DateTime"
                            ></gen-datetime-input>
                        </div>
                        <div class="gen-row import-data-dlg" *ngIf="location">
                            <div class="gen-col-xs-6">
                                <gen-numeric-input
                                    [(ngModel)]="location.latitude"
                                    (ngModelChange)="onValueChanged()"
                                    [min]="-90.0"
                                    [max]="90.0"
                                    [step]="0.000000001"
                                    class="full-width"
                                    id="ingestion-data-latitude"
                                >
                                    <gen-label>{{ 'STE_LABEL_LATITUDE' | translate }}</gen-label>
                                </gen-numeric-input>
                            </div>
                            <div class="gen-col-xs-6">
                                <gen-numeric-input
                                    [(ngModel)]="location.longitude"
                                    (ngModelChange)="onValueChanged()"
                                    [min]="-180.0"
                                    [max]="180.0"
                                    [step]="0.000000001"
                                    class="full-width"
                                    id="ingestion-data-longitude"
                                >
                                    <gen-label>{{ 'STE_LABEL_LONGITUDE' | translate }}</gen-label>
                                </gen-numeric-input>
                            </div>
                        </div>
                    </gen-expander-content>
                </gen-expander>
            </ng-container>
        </div>
    </gen-melted-modal-body>
    <gen-melted-additional-footer> </gen-melted-additional-footer>
</gen-melted-modal>
