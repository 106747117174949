import { QueryFilter } from '../Client/Queries/QueryFilter';
import { ObservableCollection } from './ObservableCollection';
import { RelationTrackerBase } from './RelationTrackerBase';
import { FieldList } from './FieldList';
import { CancellationToken } from './Helpers';
import { FieldObject } from './FieldObject';
import { IQueryFilter } from '../Client/Interface/IQueryFilter';
import { ITransaction } from '../Client/Interface/ITransaction';
import { IRestResponse } from '../Client/Interface/IRestResponse';
import { IEntity } from '../Client/Interface/IEntity';
import { ISecurityCenterClient } from '../Client/Interface/ISecurityCenterClient';
import { IRestObject } from '../Client/Interface/IRestObject';
import { Entity } from '../Client/Model/Entity';

export class RelationGuidTracker extends RelationTrackerBase {
    //#region Fields

    protected _members: ObservableCollection<IEntity> | null = null;
    private _relationAndFilter: string;
    protected _query: IQueryFilter | null = null;
    protected _client: ISecurityCenterClient;

    //#endregion

    //#region Properties

    public get relationAndFilter(): string {
        return this._relationAndFilter;
    }

    //#endregion

    //#region constructor

    constructor(client: ISecurityCenterClient, parent: IRestObject, relation: string, itemKey: string, readOnly: boolean, query?: IQueryFilter | null) {
        super(parent, relation, itemKey, readOnly, false);
        this._client = client;
        if (query) {
            this._query = query;
        } else {
            this._query = new QueryFilter();
        }

        if (!this._query.fields) {
            if (client.defaultFields != null) {
                this._query.fields = new Set<string>(client.defaultFields.Fields);
            } else {
                // new entity not pushed to directory yet
                const defField = new FieldList();
                this._query.fields = new Set<string>(defField.Fields);
            }
        }

        if (this._relation.indexOf('?') === -1) {
            this._relationAndFilter = this._relation + '?' + this._query.toString();
        } else {
            this._relationAndFilter = this._relation + '&' + this._query.toString();
        }
    }

    //#endregion

    //#region methods

    public async getValueAsync(): Promise<ObservableCollection<IEntity>> {
        if (this._members == null) {
            this._members = new ObservableCollection<IEntity>(
                (e: IEntity) => {
                    this.onAdded(e);
                },
                (e: IEntity) => {
                    this.onRemoved(e);
                },
                () => {
                    this.onCleared();
                },
            );
            this._members.suspend = true;
            if (this._parent.canExecuteRelations === true) {
                const response = await this._parent.executeRequestAsync('GET', this._relationAndFilter, null, new CancellationToken());
                if (response.body) {
                    for (const item of response.body) {
                        const scEntity = this.createEntityModel(item);
                        if (scEntity) {
                            this._members.add(scEntity);
                        }
                    }
                }
            }
            this._members.suspend = false;
        }
        return this._members;
    }

    public async pushTransactionAsync(transaction: ITransaction): Promise<void> {
        if (this._parent.canExecuteRelations === false) {
            this._members = new ObservableCollection<IEntity>(
                (e: IEntity) => {
                    this.onAdded(e);
                },
                (e: IEntity) => {
                    this.onRemoved(e);
                },
                () => {
                    this.onCleared();
                },
            );
        }

        if (this._members != null) {
            // no need for transaction since we already got the data
            // add empty entry simply to return the data
            transaction.addTransactionOperation<ObservableCollection<IEntity>>('', '', null, () => {
                return new Promise((resolve, reject) => {
                    if (this._members != null) {
                        resolve(this._members);
                        return;
                    }
                    reject('not supposed to be here!');
                    return;
                });
            });
            return;
        } else {
            this._members = new ObservableCollection<IEntity>(
                (e: IEntity) => {
                    this.onAdded(e);
                },
                (e: IEntity) => {
                    this.onRemoved(e);
                },
                () => {
                    this.onCleared();
                },
            );
        }

        const uri = this._parent.baseUri + '/' + this.relationAndFilter;
        transaction.addTransactionOperation<ObservableCollection<IEntity>>(uri, 'GET', null, (response: IRestResponse) => {
            return new Promise((resolve, reject) => {
                if (!this._members) {
                    throw new Error('Member null');
                }
                try {
                    if (response.body) {
                        this._members.suspend = true;
                        for (const item of response.body) {
                            const scEntity = this.createEntityModel(item);
                            if (scEntity) {
                                this._members.add(scEntity);
                            }
                        }
                        this._members.suspend = false;
                    }
                    resolve(this._members);
                } catch (e) {
                    reject(e);
                }
            });
        });
        return;
    }

    public createEntityModel(config: object): IEntity | null {
        return this._client.createEntityModel<IEntity>(Entity, config);
    }

    public onAdded(element: IEntity): void {
        if (this._readOnly === true) {
            throw new Error('the collection is read only');
        }
        const body = new FieldObject();
        body.setField(this._elementId, element.id);
        this.addRelationOperation(this._relation, 'POST', body);
    }

    public onRemoved(element: IEntity): void {
        this.addRelationOperation(this._relation + '/' + element.id, 'DELETE', null);
    }

    public onCleared(): void {
        this.addRelationOperation(this._relation, 'DELETE', null);
    }

    //#region
}
