import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryInitializers } from '@modules/shared/services/analytics/telemetryInitializers';
import { VersionService } from '@modules/shared/services/version/version.service';
import { Constants } from '@src/constants';

export class AnalyticsGeneralInfo {
    securityCenterVersion?: string;
    appVersion?: string;
}

@Injectable({
    providedIn: 'root',
})
export class SendFeedbackService {
    private appInsights?: ApplicationInsights;
    private analyticsGeneralInfo: AnalyticsGeneralInfo = {};
    private ingestionEndpoint?: string;

    constructor(
        public platformService: Platform,
        private versionService: VersionService,
        private telemetryInitializers: TelemetryInitializers,
        @Inject(Constants.baseUrlIdentifier) private baseUrl: string
    ) {}

    public sendFeedback(message: string, email: string): boolean {
        const action = 'Send feedback';
        const browser = this.getCurrentBrowser();
        const properties: Record<string, unknown> = {
            action,
            message,
            email,
            browser,
        };
        return this.logSendFeedback(action, properties);
    }

    /**
     * Will create the singleton instance of Application Insights
     */
    public setupAppInsightsAsync = async (): Promise<void> => {
        if (this.appInsights) {
            return;
        }
        await this.retrieveVersionAsync();

        this.ingestionEndpoint = `${this.baseUrl}Api/Analytics/SendFeedbackProxy`;
        const appInsights = new ApplicationInsights({
            config: {
                connectionString: `InstrumentationKey=00000000-0000-0000-0000-000000000000;IngestionEndpoint=${this.ingestionEndpoint}`,
                enableAutoRouteTracking: false,
            },
        });

        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.telemetryInitializers.filter(envelope));
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.telemetryInitializers.addGeneralInfoToEnvelope(envelope, this.analyticsGeneralInfo));
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.telemetryInitializers.addPluginNameToEnvelope(envelope));

        this.appInsights = appInsights;
    };

    /**
     * Will track a send feedback event.
     *
     * @param name - the message corresponding to the trace event
     * @param properties - an object that can contain any key, should be representative of the event we want to track
     */
    public logSendFeedback(name: string, properties?: { [key: string]: any }): boolean {
        if (!this.appInsights) {
            return false;
        }
        this.appInsights.trackEvent({ name }, { customProperties: properties });
        return true;
    }

    /**
     * Will retrieve the values for the security center version and web app version and assign them to the class' properties.
     */
    private async retrieveVersionAsync(): Promise<void> {
        const versionInfo = await this.versionService.retrieveVersionAsync();
        if (versionInfo) {
            this.analyticsGeneralInfo.securityCenterVersion = versionInfo.securityCenterVersion;
            this.analyticsGeneralInfo.appVersion = versionInfo.appVersion;
        }
    }

    /**
     * Will retrieve the current browser of the user.
     */
    private getCurrentBrowser(): string {
        if (this.platformService.EDGE) {
            return 'Edge (old: version less than 79)';
        }
        if (this.platformService.ANDROID) {
            return 'Android';
        }
        if (this.platformService.SAFARI) {
            return 'Safari';
        }
        if (this.platformService.FIREFOX) {
            return 'Firefox';
        }
        if (this.platformService.IOS) {
            return 'iOS';
        }
        if (this.platformService.WEBKIT) {
            return 'WebKit based browser (i.e. Opera)';
        }
        if (this.platformService.TRIDENT) {
            return 'IE';
        }
        if (this.platformService.BLINK) {
            return 'Chrome';
        }

        return 'Unknown';
    }
}
