import { EventTypes } from 'RestClient/Client/Enumerations/EventTypes';
import { getResourceString } from './resources.helper';

export class EventTypesHelper {
    public static getStringKey(eventType: string): string | undefined {
        const stringId = this.getResourceId(eventType);
        if (stringId !== undefined) {
            const fullString = getResourceString(stringId);
            if (fullString !== undefined) {
                // We split with '_RE_L_S_' since the resource strings all start with the namespace so that string is needed to get only the last part which starts with "STE"
                // e.g. "Genetec_Sentinel_Resources_RE_L_S_STE_ADMINTOOL_CANTDELETEDB"
                return fullString.split('_RE_L_S_')[1].trim();
            }
        }
        return undefined;
    }

    private static getResourceId(eventType: string) {
        if (EventTypes.ResourceDict.size === 0) {
            EventTypes.EventTypes();
        }
        return EventTypes.ResourceDict.get(eventType);
    }
}
