import { Injectable, Type } from '@angular/core';
import { SafeGuid, IGuid } from 'safeguid';
import { MetadataKey, ModuleDescriptor } from '../../interfaces/modules/public/module-public.interface';
import { LoggerService } from '../logger/logger.service';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

@Injectable({
    providedIn: 'root',
})
export class ModuleService {
    //#region Fields

    public static availableModules: Type<ModuleDescriptor>[] = [];

    //#endregion

    //#region Constructors

    constructor(private loggerService: LoggerService) {}

    //#endregion

    //#region Public Methods

    public getModulesGlobalPrivileges(): Set<IGuid> {
        const globalPrivileges = SafeGuid.createSet();

        for (const module of ModuleService.availableModules) {
            const descriptor = this.getModuleDescriptor(module);
            if (descriptor?.inclusions) {
                descriptor.inclusions.globalPrivileges?.forEach((item) => globalPrivileges.add(item));
            }
        }

        return globalPrivileges;
    }

    //#endregion

    //#region Private Methods

    private getModuleDescriptor<T>(type: Type<T>): ModuleDescriptor | undefined {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            return Reflect.getMetadata(MetadataKey, type.prototype.constructor) as ModuleDescriptor | undefined;
        } catch (e) {
            this.loggerService.traceError(`Unable to find module descriptor of module ${type.name}, details: ${(e as Error).message}`);
        }
    }

    //#endregion
}
