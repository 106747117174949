import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IGuid, SafeGuid } from 'safeguid';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, concatAll, defaultIfEmpty, distinctUntilChanged, filter, map, tap, toArray } from 'rxjs/operators';
import { GenModalService, TableCellType, ButtonFlavor, GenPopup, GenMenu, GenTableColumn } from '@genetec/gelato-angular';
import { TranslateService } from '@ngx-translate/core';
import { TextFlavor, PopupPosition, ItemSlot, SpinnerSize } from '@genetec/gelato';
import { TrackedComponent } from '@shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@shared/interfaces/plugins/internal/plugin-internal.interface';
import { PluginTypes } from '@shared/interfaces/plugins/public/plugin-types';
import { Content, ContentPluginComponent } from '@shared/interfaces/plugins/public/plugin-public.interface';
import { TrackingService } from '@shared/services/tracking.service';
import { LoggerService } from '@shared/services/logger/logger.service';
import { stringFormat } from '@shared/utilities/StringFormat';
import { List } from 'immutable';
import { IncidentSelectionService } from '@modules/mission-control/services/incident/incident-selection.service';
import { IWebApiResponse } from '@modules/mission-control/models/web-api-response';
import { IncidentApiService } from '@modules/mission-control/services/incident/incident-api.service';
import { MissionControlContentTypes } from '../../mission-control-content-types';
import { Step } from '../../models/step';
import { IncidentCommandService } from '../../services/incident/incident-command.service';
import { AnswerProcedureCommand } from '../../models/commands/answer-procedure-command';
import { ChangeStateCommand } from '../../models/commands/change-state-command';
import { StateGuids } from '../../state-guids';
import { TakeOwnershipCommand } from '../../models/commands/take-ownership-command';
import { MCCommandBase } from '../../models/commands/command';
import { MCIncident } from '../../models/mc-incident';
import { IncidentEvent, ProcedureStepActionType, ProcedureStepAnsweredEvent, StateChangedEvent } from '../../models/events/incident-event';
import { McUserService } from '../../services/mc-user.service';
import { TimeSpan } from '../../utils/timespan';
import { McNotificationService } from '../../services/mc-notification.service';
import { DsopConstant } from '../../dsop-constants';
import { ContextMenuItem } from '@shared/interfaces/context-menu-item/context-menu-item';
import { NgControl } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

const isContentSupported = (content: Content): boolean => !!content?.type.equals(MissionControlContentTypes.procedure);

interface TTRDetails {
    name: string;
    elapsedTime: TimeSpan;
    cumulativeTime: TimeSpan;
}

@UntilDestroy()
@Component({
    selector: 'app-procedure-widget',
    templateUrl: './procedure-widget.component.html',
    styleUrls: ['./procedure-widget.component.scss'],
})
@InternalContentPluginDescriptor({
    type: ProcedureWidgetComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: ProcedureWidgetComponent.pluginId },
    isContentSupported,
})
export class ProcedureWidgetComponent extends TrackedComponent implements OnInit, AfterViewInit, ContentPluginComponent {
    public static pluginId = SafeGuid.parse('FAC4D8FD-2A84-4FC8-9C1A-4B0088B7EC78');

    @ViewChild('nameTemplate') private nameTemplate: TemplateRef<any> | undefined;
    @ViewChild('elapsedTimeTemplate') private elapsedTimeTemplate!: TemplateRef<any> | undefined;
    @ViewChild('cumulativeTimeTemplate') private cumulativeTimeTemplate: TemplateRef<any> | undefined;
    @ViewChild('optionsPopup') optionsPopup!: GenPopup;
    @ViewChild('contextMenu') contextMenu!: GenMenu;
    @ViewChild('commentInput', { read: NgControl }) commentInputModel?: NgControl;

    public content?: Content;
    public dataContext: unknown;

    public readonly TextFlavor = TextFlavor;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly PopupPosition = PopupPosition;
    public readonly ItemSlot = ItemSlot;
    public readonly SpinnerSize = SpinnerSize;

    public isCurrentStepRoot = false;
    public skipAction: () => Promise<boolean>;
    public restartAction: () => Promise<boolean>;
    public closeAction: () => Promise<boolean>;
    public ttrOkAction: () => Promise<boolean>;
    public currentStepDisplayed$!: Observable<Step>;
    public currentStepOptionsAsGenItem$!: Observable<ContextMenuItem[]>;
    public ttrDetails$: Observable<TTRDetails[]>;
    public ttrDetails: Subject<TTRDetails[]> = new Subject<TTRDetails[]>();
    public skipReason = '';
    public restartReason = '';
    public isContextMenuOpen = false;
    public manualCommentOpen = false;
    public comment = '';
    public procedureMenuOptions: Array<ContextMenuItem> = new Array<ContextMenuItem>();
    public ttrColumns: Array<GenTableColumn> = [];
    public completedLabel = '';
    public isReadonly = false;

    public readonly startId: IGuid = DsopConstant.STEP_PROCEDURE_BEGIN;
    public readonly loadingId: IGuid = SafeGuid.parse('4C5B7DB6-4500-4DF4-BAFF-0B267C1B9780');
    public readonly interruptedId: IGuid = SafeGuid.parse('1A9FF984-81BA-47DE-9913-093A0BF49A10');
    public readonly endId: IGuid = DsopConstant.STEP_PROCEDURE_COMPLETED;

    private _incident!: MCIncident;
    private readonly loadingStep: Step = new Step(this.loadingId, 'Loading', 'Loading', [], false, false, false);
    private _currentStep$: BehaviorSubject<Step> = new BehaviorSubject<Step>(this.loadingStep);
    private _hadFirstStateChangeToInProgress = false;
    private _lastKnownStepId: IGuid | null = null;
    private _hasOwner = false;
    private _isOwner = false;
    private _isReverting = false;

    private readonly _skipStepModalId: string = 'skip-step-modal';
    private readonly _restartModalId: string = 'restart-modal';
    private readonly _ttrModalId: string = 'ttr-modal';
    private readonly startStep: Step = new Step(this.startId, 'Start', 'Start', [], false, false, false);
    private readonly interruptedStep: Step = new Step(this.interruptedId, 'Interrupted', 'Interrupted', [], false, false, false);
    private readonly endStep: Step = new Step(this.endId, 'End', 'End', [], false, false, false);
    private readonly _steps: Map<string, Step> = new Map<string, Step>();

    constructor(
        private _incidentCommandService: IncidentCommandService,
        private _logger: LoggerService,
        private _mcUserService: McUserService,
        private _incidentApiService: IncidentApiService,
        private _modalService: GenModalService,
        private _translateService: TranslateService,
        private _notificationService: McNotificationService,
        private _incidentSelectionService: IncidentSelectionService,
        trackingService: TrackingService
    ) {
        super(trackingService);

        this.currentStepDisplayed$ = this._currentStep$.pipe(
            tap((_) => {
                this.comment = '';
                this.manualCommentOpen = false;
            }),
            catchError((e) => {
                this._logger.traceError(e);
                return EMPTY;
            }),
            defaultIfEmpty(this.loadingStep),
            distinctUntilChanged((prev, next) => !this._isReverting && !!this._lastKnownStepId && this._lastKnownStepId?.equals(next.id)),
            tap((_) => {
                if (this._isReverting) this._isReverting = false;
            })
        );

        this.skipAction = this.skip.bind(this);
        this.restartAction = this.restartProcedure.bind(this);
        this.closeAction = this.cancelRestart.bind(this);
        this.ttrOkAction = this.closeTTRModal.bind(this);
        this.ttrDetails$ = this.ttrDetails;

        this.handleUpdate.bind(this);
    }

    public ngOnInit() {
        super.ngOnInit();

        this._incidentSelectionService.selectedIncident$
            .pipe(
                filter((i): i is MCIncident => i instanceof MCIncident),
                tap(async (incident) => await this.handleUpdate(incident)),
                catchError((e) => {
                    this._logger.traceError(e);
                    return EMPTY;
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.currentStepOptionsAsGenItem$ = this.currentStepDisplayed$.pipe(
            map((x) => x.options),
            concatAll(),
            map((x) => ({ text: '', id: x.id.toString().toUpperCase(), type: x.name })),
            toArray()
        );
    }

    public ngAfterViewInit() {
        this.ttrColumns = [
            {
                columnName: 'name',
                label: this._translateService.instant('STE_LABEL_NAME') as string,
                cellType: TableCellType.Custom,
                cellTemplate: this.nameTemplate,
                minWidth: 80,
                suppressSorting: true,
            },
            {
                columnName: 'elapsedTime',
                label: this._translateService.instant('STE_LABEL_ELAPSED_TIME') as string,
                cellType: TableCellType.Custom,
                cellTemplate: this.elapsedTimeTemplate,
                minWidth: 80,
                maxWidth: 160,
                suppressSorting: true,
            },
            {
                columnName: 'cumulativeTime',
                label: this._translateService.instant('STE_LABEL_CUMULATIVE_TIME') as string,
                cellType: TableCellType.Custom,
                cellTemplate: this.cumulativeTimeTemplate,
                minWidth: 80,
                maxWidth: 160,
                suppressSorting: true,
            },
        ];
    }

    public isStart = (currentStepId: IGuid): boolean => currentStepId.equals(this.startId);

    public isLoading = (currentStepId: IGuid): boolean => {
        return currentStepId.equals(this.loadingId);
    };

    public isInProgress = (currentStepId: IGuid): boolean => {
        return !this.isLoading(currentStepId) && !this.isStart(currentStepId) && !this.isEnd(currentStepId) && !this.isInterrupted(currentStepId);
    };

    public isInterrupted = (currentStepId: IGuid): boolean => {
        return currentStepId.equals(this.interruptedId);
    };

    public isEnd = (currentStepId: IGuid): boolean => {
        return currentStepId.equals(this.endId);
    };

    public isProcedureStarted = (): boolean => {
        return this._hadFirstStateChangeToInProgress || !this._incident.stateId.equals(StateGuids.NEW);
    };

    public isProcedureInterrupted = (): boolean => {
        return !this._incident.currentStepId?.isEmpty() && !this._incident.currentStepId?.equals(this.endId) && this._incident.stateId.equals(StateGuids.SOLVED);
    };

    public isProcedureCompleted = (): boolean => {
        return !this._incident.currentStepId?.isEmpty() && (this._incident.currentStepId?.equals(this.endId) || this._incident.stateId.equals(StateGuids.CLOSED));
    };

    public isResetCommandVisible = (): boolean => !this.isReadonly && (!this.isCurrentStepRoot || this.isProcedureCompleted());

    public async back(): Promise<void> {
        try {
            this._currentStep$.next(this.loadingStep);
            switch (this._incident.stateId?.toString().toUpperCase()) {
                case StateGuids.SOLVED.toString().toUpperCase():
                    await this.executeCommandOrThrow(new ChangeStateCommand(this._incident.id, StateGuids.IN_PROGRESS));
                    if (this._incident.currentStepId?.equals(this.endId)) {
                        await this.executeCommandOrThrow(new AnswerProcedureCommand(this._incident.id, ProcedureStepActionType.Previous, SafeGuid.EMPTY, this.comment));
                    }
                    break;
                case StateGuids.IN_PROGRESS.toString().toUpperCase():
                    await this.executeCommandOrThrow(new AnswerProcedureCommand(this._incident.id, ProcedureStepActionType.Previous, SafeGuid.EMPTY, this.comment));
                    break;
                default:
                    await this.executeCommandOrThrow(new ChangeStateCommand(this._incident.id, StateGuids.IN_PROGRESS));
                    await this.executeCommandOrThrow(new AnswerProcedureCommand(this._incident.id, ProcedureStepActionType.Previous, SafeGuid.EMPTY, this.comment));
                    break;
            }
            return;
        } catch (e) {
            this._logger.traceError(e);
            this._notificationService.notifyError(this._translateService.instant('STE_MESSAGE_GO_BACK_FAILED') as string);
            this.revertToLatest();
        }
    }

    public async skip(): Promise<boolean> {
        try {
            if (!this._incident.id) return false;
            this._currentStep$.next(this.loadingStep);
            if (!this._incident.stateId?.equals(StateGuids.IN_PROGRESS)) {
                await this.executeCommandOrThrow(new ChangeStateCommand(this._incident.id, StateGuids.IN_PROGRESS));
            }
            const command = new AnswerProcedureCommand(this._incident.id, ProcedureStepActionType.Skip, SafeGuid.EMPTY, this.skipReason);
            await this.executeCommandOrThrow(command);
            this.skipReason = '';

            return true;
        } catch (e) {
            this._logger.traceError(e);
            this._notificationService.notifyError(this._translateService.instant('STE_MESSAGE_SKIP_STEP_FAILED') as string);
            this.revertToLatest();
        }
        return false;
    }

    public async onOptionClicked(optionId: IGuid): Promise<void> {
        try {
            const comment = this.comment;
            this._currentStep$.next(this.loadingStep);
            // TODO: WAPI should change ownership when swapping state or answering procedure but it does not
            if (!this._isOwner) await this.executeCommandOrThrow(new TakeOwnershipCommand(this._incident.id));
            if (optionId.equals(this.startId)) {
                if (!this._incident.stateId?.equals(StateGuids.IN_PROGRESS)) {
                    await this.executeCommandOrThrow(new ChangeStateCommand(this._incident.id, StateGuids.IN_PROGRESS));
                }
            } else {
                await this.executeCommandOrThrow(new AnswerProcedureCommand(this._incident.id, ProcedureStepActionType.Complete, optionId, comment));
                await this.optionsPopup?.close();
            }
            return;
        } catch (e) {
            this._logger.traceError(e);
            this._notificationService.notifyError(this._translateService.instant('STE_MESSAGE_COMPLETE_STEP_FAILED') as string);
            this.revertToLatest();
        }
    }

    public async toggleOptions(): Promise<void> {
        await this.optionsPopup?.toggle();
    }

    public toggleContextMenu(): void {
        this.isContextMenuOpen = !this.isContextMenuOpen && this.procedureMenuOptions.length > 0;
        this.contextMenu.toggle().fireAndForget();
    }

    public toggleSkipModal = (): void => this.showModal(this._skipStepModalId);

    public toggleRestartModal = (): void => this.showModal(this._restartModalId);

    public toggleTTRModal = (): void => this.showModal(this._ttrModalId);

    public addComment(): void {
        this.manualCommentOpen = true;
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public setDataContext(dataContext: unknown): void {
        this.dataContext = dataContext;
    }

    private showModal(modalId: string): void {
        if (this._modalService) {
            this._modalService.show(modalId);
            this.loseFocus();
        }
    }

    private executeCommandOrThrow(command: MCCommandBase): Promise<IWebApiResponse> {
        return this._incidentCommandService.execute(command).toPromise();
    }

    private loseFocus(): void {
        // TODO: ideally, the modal service should handle this: not allow 2 modals to be open.
        (document.activeElement as HTMLElement)?.blur();
    }

    private async restartProcedure(): Promise<boolean> {
        try {
            this._currentStep$.next(this.loadingStep);
            await this.executeCommandOrThrow(new AnswerProcedureCommand(this._incident.id, ProcedureStepActionType.Reset, SafeGuid.EMPTY, this.restartReason));
            this.restartReason = '';
            return true;
        } catch (e) {
            this._logger.traceError(e);
            this.revertToLatest();
        }
        return false;
    }

    private cancelRestart(): Promise<boolean> {
        this.restartReason = '';
        return this.closeModal(this._restartModalId);
    }

    private closeTTRModal = (): Promise<boolean> => this.closeModal(this._ttrModalId);

    private closeModal(modalId: string): Promise<boolean> {
        if (this._modalService) {
            this._modalService.hide(modalId);
            return Promise.resolve(true);
        }
        return Promise.resolve(false);
    }

    private updateContextMenu() {
        this.procedureMenuOptions = [];
        if (this.isResetCommandVisible()) {
            this.procedureMenuOptions.push({
                id: '1',
                text: this._translateService.instant('STE_LABEL_RESTART_PROCEDURE') as string,
                actionItem: {
                    execute: () => this.toggleRestartModal(),
                },
            });
        }
        if (this._isOwner) {
            this.procedureMenuOptions.push({
                id: '1',
                text: this._translateService.instant('STE_LABEL_TTR_DETAILS') as string,
                actionItem: {
                    execute: () => this.toggleTTRModal(),
                },
            });
        }
    }

    private buildTTRDetails(events: List<IncidentEvent>, steps: Step[]) {
        const eventsOrderedByProcessTime = events?.sort((a, b) => new Date(a.processTimeUtc).getTime() - new Date(b.processTimeUtc).getTime());
        const startedTime = eventsOrderedByProcessTime.find((e) => e instanceof StateChangedEvent && e.stateId.equals(StateGuids.IN_PROGRESS))?.processTimeUtc ?? null;
        const entries: { name: string; elapsedTime: TimeSpan; cumulativeTime: TimeSpan }[] = [];

        if (startedTime) {
            let previousElapsedTime = new TimeSpan(new Date(startedTime).getTime());
            events
                .filter((e): e is ProcedureStepAnsweredEvent => e instanceof ProcedureStepAnsweredEvent)
                .sort((a, b) => new Date(a.processTimeUtc).getTime() - new Date(b.processTimeUtc).getTime())
                .forEach((value) => {
                    const processTime = new Date(value.processTimeUtc);
                    const name =
                        value.stepId === this.endId
                            ? (this._translateService.instant('STE_LABEL_COMPLETED') as string)
                            : steps.find((step) => step.id.equals(value.stepId))?.name ?? (this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string);
                    const cumulativeTime = new TimeSpan(processTime.getTime() - new Date(startedTime).getTime());
                    const elapsedTime = new TimeSpan(processTime.getTime() - previousElapsedTime.totalMilliseconds);
                    entries.push({ cumulativeTime, name, elapsedTime });
                    previousElapsedTime = new TimeSpan(processTime.getTime());
                });
        }
        this.ttrDetails.next(entries.sort((a, b) => a.cumulativeTime.totalMilliseconds - b.cumulativeTime.totalMilliseconds));
    }

    private revertToLatest(): void {
        const latestKnownStep = this._steps.get(this._lastKnownStepId!.toString().toUpperCase())!;
        this._isReverting = true;
        this._currentStep$.next(latestKnownStep);
    }

    private getStepName(stepId: IGuid): string {
        if (stepId.equals(this.endId)) return this._translateService.instant('STE_LABEL_COMPLETED') as string;

        return this._steps.get(stepId.toString().toUpperCase())?.name ?? (this._translateService.instant('STE_LABEL_UNAVAILABLE_INFORMATION') as string);
    }

    private async handleUpdate(incident: MCIncident): Promise<void> {
        if (!incident.procedure) {
            this._logger.traceWarning('Incident procedure was not defined for procedure widget');
            return;
        }

        this._incident = incident;
        const events = incident.events;

        const stateChangedEvents = events?.filter((e) => e instanceof StateChangedEvent).map((e) => e as StateChangedEvent);
        this._hadFirstStateChangeToInProgress = !!stateChangedEvents?.find((e) => e.stateId.equals(StateGuids.IN_PROGRESS));

        const ownerId = incident.ownerId ?? null;
        this._hasOwner = !!ownerId;

        this._isOwner = !!(ownerId && this._mcUserService.currentUser.userInfo.id?.equals(ownerId));

        this.isReadonly = this._hasOwner && !this._isOwner;

        this.buildTTRDetails(events, incident.procedure.steps);

        let elapsedTime = new TimeSpan(0);
        let nextStep: Step | null;
        if (!this.isProcedureStarted()) {
            nextStep = this.startStep;
        } else if (this.isProcedureInterrupted()) {
            nextStep = this.interruptedStep;
        } else if (this.isProcedureCompleted()) {
            nextStep = this.endStep;
            const processTimeSortedEvents = events.sort((a, b) => new Date(a.processTimeUtc).getTime() - new Date(b.processTimeUtc).getTime());
            const startEvent = processTimeSortedEvents.find((e) => e instanceof StateChangedEvent && e.stateId.equals(StateGuids.IN_PROGRESS)) ?? null;
            const startedTime = new TimeSpan(startEvent ? new Date(startEvent.processTimeUtc).getTime() : 0);
            const endEvent = processTimeSortedEvents.reverse().find((e) => e instanceof ProcedureStepAnsweredEvent && e.nextStepId.equals(this.endId));
            const endTime = new TimeSpan(endEvent ? new Date(endEvent.processTimeUtc).getTime() : 0);
            elapsedTime = new TimeSpan(endTime.totalMilliseconds - startedTime.totalMilliseconds);
        } else {
            try {
                nextStep = await this._incidentApiService.getCurrentProcedureStep(incident.id).toPromise();
                this._steps.set(nextStep.id.toString().toUpperCase(), nextStep);
            } catch (e) {
                this._notificationService.notifyError(this._translateService.instant('STE_MESSAGE_STEP_FAILED') as string);
                this.revertToLatest();
                throw e;
            }
        }

        this.completedLabel =
            elapsedTime.totalMilliseconds > 0
                ? stringFormat(this._translateService.instant('STE_LABEL_COMPLETED_IN') as string, elapsedTime.toString())
                : (this._translateService.instant('STE_LABEL_COMPLETED') as string);

        if (nextStep) {
            this.isCurrentStepRoot = nextStep.isRoot(events.toArray());
            this._currentStep$.next(nextStep);
            this._lastKnownStepId = nextStep.id;
        }

        this.updateContextMenu();
    }
}
