// Autogenerated: v2/help/Entities/ApiCamera?codegen=ts&InterfaceOnly=True&workflow=False on 2022-07-05 4:50:16 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IRestResponse } from './IRestResponse';
import { IEntity } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { ILocatableEntity, LocatableEntityFields } from './ILocatableEntity';
import { ITransaction } from './ITransaction';
import { IQueryFilter } from './IQueryFilter';

// #region Fields

export class CameraEntityFields extends LocatableEntityFields {
    public static audioRecordingField = 'AudioRecording';
    public static audioStreamInField = 'AudioStreamIn';
    public static audioStreamOutField = 'AudioStreamOut';
    public static automaticCleanupField = 'AutomaticCleanup';
    public static cameraSubTypeField = 'CameraSubType';
    public static defaultManualRecordingTimeField = 'DefaultManualRecordingTime';
    public static encryptionEnabledField = 'EncryptionEnabled';
    public static encryptionTypeField = 'EncryptionType';
    public static externalSystemTypeField = 'ExternalSystemType';
    public static federationStreamUsageField = 'FederationStreamUsage';
    public static federationUserIdField = 'FederationUserId';
    public static federationUserSecurityLevelField = 'FederationUserSecurityLevel';
    public static isDVRInputField = 'IsDVRInput';
    public static isEdgeAnalyticsCameraField = 'IsEdgeAnalyticsCamera';
    public static isLicensedCameraField = 'IsLicensedCamera';
    public static isNativeField = 'IsNative';
    public static isPluginCameraField = 'IsPluginCamera';
    public static isPrivacyProtectedField = 'IsPrivacyProtected';
    public static lockingUserAppIdField = 'LockingUserAppId';
    public static lockingUserIdField = 'LockingUserId';
    public static lockingUserInfoField = 'LockingUserInfo';
    public static lockStateField = 'LockState';
    public static metadataRecordingField = 'MetadataRecording';
    public static omnicastCameraGuidField = 'OmnicastCameraGuid';
    public static postEventRecordingTimeField = 'PostEventRecordingTime';
    public static preEventRecordingTimeField = 'PreEventRecordingTime';
    public static ptzAddressField = 'PtzAddress';
    public static ptzCapabilitiesField = 'PtzCapabilities';
    public static ptzIdleActionField = 'PtzIdleAction';
    public static ptzIdleActionNumberField = 'PtzIdleActionNumber';
    public static ptzIdleDelayField = 'PtzIdleDelay';
    public static ptzLockDelayField = 'PtzLockDelay';
    public static ptzLockStateTimeUpField = 'PtzLockStateTimeUp';
    public static ptzProtocolField = 'PtzProtocol';
    public static ptzSerialPortIdField = 'PtzSerialPortId';
    public static ptzSpecificConfigField = 'PtzSpecificConfig';
    public static recordingLengthField = 'RecordingLength';
    public static recordingStateField = 'RecordingState';
    public static redundantArchivingField = 'RedundantArchiving';
    public static retentionPeriodField = 'RetentionPeriod';
    public static roleTypeField = 'RoleType';
    public static signalStateField = 'SignalState';
    public static streamGroupIdField = 'StreamGroupId';
    public static tailingField = 'Tailing';
    public static thumbnailIdField = 'ThumbnailId';
    public static unitField = 'Unit';
    public static useCustomRecordingSettingsField = 'UseCustomRecordingSettings';
    public static videoEncoderDeviceField = 'VideoEncoderDevice';
}

// #endregion Fields

// #region Inner classes

export interface IVideoAttribute extends IFieldObject {
    scheduleId: IGuid;
    brightness: number | null;
    contrast: number | null;
    hue: number | null;
    saturation: number | null;
    sharpness: number | null;
}

export interface IStreamUsageInfo extends IFieldObject {
    id: IGuid;
    streamUsage: string;
}

export interface IRecordingMode extends IFieldObject {
    id: number;
    scheduleId: IGuid;
    mode: string;
}

export interface IPtzCapabilitiesInfo extends IFieldObject {
    protocolChangeSupported: boolean;
    ptzControlEnabled: boolean;
    ptzNumberChangeSupported: boolean;
    ptzSpeedControlSupported: boolean;
    cameraLinkChangeSupported: boolean;
    deletionSupported: boolean;
    idleDelayChangeSupported: boolean;
    lockDelayChangeSupported: boolean;
    supportedCommands: string;
    secondaryIOPortRequired: boolean;
    remoteDataRequired: boolean;
    numberOfPresets: number;
    numberOfPattern: number;
    numberOfPresetTours: number;
    numberOfAux: number;
    minimumCameraNumber: number;
    maximumCameraNumber: number;
    presetBase: number;
    patternBase: number;
    auxBase: number;
    presetTourBase: number;
    productCode: number;
    maximumZoomFactor: number;
    minimumPan: number;
    maximumPan: number;
    minimumTilt: number;
    maximumTilt: number;
    sphericalSpaceMinimumPan: number | null;
    sphericalSpaceMaximumPan: number | null;
    sphericalSpaceMinimumTilt: number | null;
    sphericalSpaceMaximumTilt: number | null;
    sphericalSpacePanLeftDeviceLimit: number | null;
    sphericalSpacePanRightDeviceLimit: number | null;
    sphericalSpaceTiltUpDeviceLimit: number | null;
    sphericalSpaceTiltDownDeviceLimit: number | null;
    zoomFactorSpaceMinimum: number | null;
    zoomFactorSpaceMaximum: number | null;
    minimumHorizontalFieldOfView: number | null;
    maximumHorizontalFieldOfView: number | null;
    minimumVerticalFieldOfView: number | null;
    maximumVerticalFieldOfView: number | null;
    minimumFocalLength: number | null;
    maximumFocalLength: number | null;
    unknownSpaceMinimumPan: number | null;
    unknownSpaceMaximumPan: number | null;
    unknownSpaceMinimumTilt: number | null;
    unknownSpaceMaximumTilt: number | null;
    unknownSpaceMinimumZoom: number | null;
    unknownSpaceMaximumZoom: number | null;
}

export interface IProtectVideo extends IFieldObject {
    start: Date;
    stop: Date;
    duration: number | null;
    protect: boolean;
}

export interface IMotionDetectionZone extends IFieldObject {
    id: number;
    macroBlockHeight: number | null;
    macroBlockWidth: number | null;
    mapHeight: number | null;
    mapWidth: number | null;
    motionOffEvent: number | null;
    motionOffThreshold: number | null;
    motionOnEvent: number | null;
    motionOnThreshold: number | null;
    sensitivity: number | null;
}

export interface IMotionDetection extends IFieldObject {
    scheduleId: IGuid;
    enabled: boolean | null;
    cameraApplicationHardwareDetection: string;
    consecutiveFrame: boolean | null;
    hardwareDetection: boolean | null;
    isH264: boolean | null;
    h264ChromaWeight: number | null;
    h264LumaWeight: number | null;
    h264MacroBlockWeight: number | null;
    h264VectorWeight: number | null;
    motionDetectorMode: string;
    sensitivity: number | null;

    getZonesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMotionDetectionZone> | null>;
}

export interface ILensType extends IFieldObject {
    id: IGuid;
    manufacturer: string;
    data: string;
}

export interface IVector extends IFieldObject {
    y: number;
    x: number;
}

export interface ILensConfiguration extends IFieldObject {
    lensProperties: string;
    isHardwareDewarpingSupported: boolean;
    vector: IVector;
    viewingModes: ObservableCollection<string>;
}

export interface ILensInfo extends IFieldObject {
    lensConfiguration: ILensConfiguration;
    lensType: ILensType;
}

export interface IThumbnailData extends IFieldObject {
    camera: IGuid;
    timestamp: Date;
    thumbnail: string;
    context: IGuid;
    latestFrame: Date;
}

export interface IThumbnailRequest extends IFieldObject {
    time: Date | null;
    startTime: Date | null;
    endTime: Date | null;
    count: number | null;
    width: number;
    timeout: number | null;
    context: IGuid;
    isHighPrecision: boolean;
}

export interface IUserInfo extends IFieldObject {
    userId: IGuid;
    userLevel: number;
    systemId: string;
    skipPtzEvaluation: boolean;
}

export interface IFeatureCapability extends IFieldObject {
    id: string;
    supported: boolean;
}

export interface IEncryptionCertificate extends IFieldObject {
    id: number;
    cert: string;
}

export interface IControlPtz extends IFieldObject {
    command: string;
    arg1: number;
    arg2: number;
    userAppId: IGuid | null;
    userId: IGuid | null;
}

export interface IBlockInfo extends IFieldObject {
    isBlocked: boolean;
    blockLevel: number;
    userLevel: number;
    startTime: Date;
    endTime: Date;
}

export interface IPtzInfo extends IFieldObject {
    id: number;
    name: string;
}

// #endregion Inner classes

export interface ICameraEntity extends ILocatableEntity {
    // #region REST Properties

    audioRecording: boolean;

    readonly audioStreamIn: IGuid;

    readonly audioStreamOut: IGuid;

    automaticCleanup: boolean;

    readonly cameraSubType: string;

    defaultManualRecordingTime: number;

    encryptionEnabled: boolean;

    encryptionType: string;

    readonly externalSystemType: string;

    readonly federationStreamUsage: string;

    readonly federationUserId: IGuid;

    readonly federationUserSecurityLevel: number;

    readonly isDVRInput: boolean;

    readonly isEdgeAnalyticsCamera: boolean;

    readonly isLicensedCamera: boolean;

    readonly isNative: boolean;

    readonly isPluginCamera: boolean;

    readonly isPrivacyProtected: boolean;

    readonly lockingUserAppId: IGuid;

    readonly lockingUserId: IGuid;

    readonly lockingUserInfo: any;

    lockState: string;

    metadataRecording: boolean;

    readonly omnicastCameraGuid: IGuid;

    postEventRecordingTime: number;

    preEventRecordingTime: number;

    readonly ptzAddress: number;

    readonly ptzCapabilities: string;

    readonly ptzIdleAction: string;

    readonly ptzIdleActionNumber: number;

    readonly ptzIdleDelay: number;

    readonly ptzLockDelay: number;

    readonly ptzLockStateTimeUp: Date;

    readonly ptzProtocol: number;

    readonly ptzSerialPortId: IGuid;

    readonly ptzSpecificConfig: string;

    readonly recordingLength: string;

    readonly recordingState: string;

    redundantArchiving: boolean;

    retentionPeriod: number;

    readonly roleType: IGuid;

    readonly signalState: string;

    streamGroupId: string;

    readonly tailing: string;

    readonly thumbnailId: IGuid;

    readonly unit: IGuid;

    useCustomRecordingSettings: boolean;

    readonly videoEncoderDevice: IGuid;

    // #endregion REST Properties

    // #region REST Methods

    addBookmarkAsync(description: string, timestamp: Date, playback: boolean, transaction?: ITransaction): Promise<IRestResponse | null>;
    getArchivedStreamsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getAttachedCashRegistersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getAuxAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzInfo> | null>;
    blockAsync(start: Date, end: Date, blockingLevel: number, transaction?: ITransaction): Promise<IRestResponse | null>;
    getBlockCameraInfoAsync(transaction?: ITransaction | null): Promise<IBlockInfo | null>;
    boostQualityAsScheduledAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    controlPtzAsync(value: IControlPtz, transaction?: ITransaction): Promise<IRestResponse | null>;
    donateArchivesAsync(id: IGuid, transaction?: ITransaction): Promise<IRestResponse | null>;
    getEncryptionCertificatesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IEncryptionCertificate> | null>;
    getFeatureCapabilitiesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IFeatureCapability> | null>;
    getFederationUserMultilevelInfoAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IUserInfo> | null>;
    generateThumbnailsAsync(value: IThumbnailRequest, transaction?: ITransaction): Promise<Array<IThumbnailData> | null>;
    gotoHomeAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getHardwareConfigDataAsync(transaction?: ITransaction | null): Promise<IFieldObject | null>;
    getLensAsync(transaction?: ITransaction | null): Promise<ILensInfo | null>;
    getMetadataStreamsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getMotionDetectionAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IMotionDetection> | null>;
    getPatternsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzInfo> | null>;
    getPresetsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IPtzInfo> | null>;
    protectVideoPlaybackAsync(value: IProtectVideo, transaction?: ITransaction): Promise<IRestResponse | null>;
    getPtzCapabilitiesAsync(transaction?: ITransaction | null): Promise<IPtzCapabilitiesInfo | null>;
    getRecordingModesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IRecordingMode> | null>;
    startLiveStreamingAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    startRecordingAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    stopLiveStreamingAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    stopRecordingAsync(transaction?: ITransaction): Promise<IRestResponse | null>;
    getStreamsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IStreamUsageInfo> | null>;
    getTransferGroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    unblockAsync(start: Date, end: Date, transaction?: ITransaction): Promise<IRestResponse | null>;
    getVideoAttributesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IVideoAttribute> | null>;

    // #endregion REST Methods

}

