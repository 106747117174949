import { Component, Inject, OnInit } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { GenMeltedItem } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { InternalPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { SettingsService, USER_SETTINGS_SERVICE } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { OptionSubSections, PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { LanguageService } from '@modules/shared/services/language/language.service';
import { TimeService } from '@modules/shared/services/time/time.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export interface LanguageItem {
    id: string;
    text: string;
}

export enum TimezoneMode {
    Device = 0,
    Specific = 1,
}

@Component({
    selector: 'app-language-time-options',
    templateUrl: './language-time-options.component.html',
    styleUrls: ['./language-time-options.component.scss'],
})
@InternalPluginDescriptor({
    type: LanguageTimeOptionsComponent,
    pluginTypes: [PluginTypes.Option],
    exposure: {
        id: LanguageTimeOptionsComponent.pluginId,
        subSection: OptionSubSections.General,
    },
    isSupported: () => true,
})
export class LanguageTimeOptionsComponent extends TrackedComponent implements OnInit {
    public static pluginId = SafeGuid.parse('CB3D93A0-D429-411A-BF56-FCD0C9CBE5F7');

    public readonly TextFlavor = TextFlavor;
    public readonly timezoneMode = TimezoneMode;

    public languages: LanguageItem[] = [];

    public timezones: GenMeltedItem[] = [];

    public selectedLanguage: LanguageItem | null = null;
    public selectedTimezone: GenMeltedItem | undefined;
    public selectedTimezoneMode = TimezoneMode.Device;
    public isTimezoneDisplayed!: boolean;
    public isInitialized = false;
    public isContentVisible = false;

    private webAppSelectedLanguage = 'SelectedLanguage';
    private webAppSelectedTimezone = 'SelectedTimezone';
    private webAppDisplayTimeZone = 'DisplayTimezone';
    private webAppUseDeviceTimezone = 'UseDeviceTimezone';

    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
        private timeService: TimeService,
        trackingService: TrackingService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: SettingsService
    ) {
        super(trackingService);
    }

    async ngOnInit() {
        super.ngOnInit();

        const contentVisibility = this.userSettingsService.getOptionAvailability(OptionTypes.LanguageAndTime);

        if (contentVisibility) {
            const langs = await this.languageService.getAvailableLanguages();

            if (langs) {
                langs.forEach((element) => {
                    const text = this.translateService.instant(element.name ?? '') as string;
                    this.languages.push({
                        id: element.id ?? '',
                        text,
                    });
                });
            }

            this.isContentVisible = true;
            const selectedLanguageId = this.userSettingsService.get<string>(OptionTypes.LanguageAndTime, this.webAppSelectedLanguage);
            if (selectedLanguageId) {
                const language = Array.from(this.languages).find((item) => item.id === selectedLanguageId);
                if (language) {
                    this.selectedLanguage = language;
                }
            }

            // ensure a language is selected
            if (!this.selectedLanguage) {
                // might be a new user, select the default language
                const language = Array.from(this.languages).find((item) => item.id === LanguageService.DefaultLanguage);
                if (language) {
                    this.selectedLanguage = language;
                }
            }

            // fetch timezones
            const timezones = await this.timeService.getTimezonesAsync();
            if (timezones) {
                timezones.forEach((x) => {
                    if (x.id && x.displayName) {
                        this.timezones.push({ text: x.displayName, id: x.id });
                    }
                });
            }

            let selectedTimezoneId = this.userSettingsService.get<string>(OptionTypes.LanguageAndTime, this.webAppSelectedTimezone);

            // in case no timezone is set, retrieve the default one from the server
            if (!selectedTimezoneId) {
                const systemTimezone = await this.timeService.getLocalTimezone();
                if (systemTimezone?.id) {
                    selectedTimezoneId = systemTimezone.id;
                }
            }

            if (selectedTimezoneId) {
                this.selectedTimezone = Array.from(this.timezones).find((item) => item.id === selectedTimezoneId);
            }

            const useDeviceTimezone = this.userSettingsService.get(OptionTypes.LanguageAndTime, this.webAppUseDeviceTimezone);
            if (useDeviceTimezone) {
                this.selectedTimezoneMode = TimezoneMode.Device;
            } else {
                this.selectedTimezoneMode = TimezoneMode.Specific;
            }

            const isTimezoneDisplayed = this.userSettingsService.get(OptionTypes.LanguageAndTime, this.webAppDisplayTimeZone);
            if (isTimezoneDisplayed) {
                this.isTimezoneDisplayed = isTimezoneDisplayed as boolean;
            }
        }

        this.isInitialized = true;
    }

    public onSelectedLanguageChanged(selectedLanguage: { text: string; id: string }): void {
        this.userSettingsService.set(OptionTypes.LanguageAndTime, this.webAppSelectedLanguage, selectedLanguage.id, true);
    }

    public onSelectedTimezoneChanged(selectedTimezone: { text: string; id: string }): void {
        this.userSettingsService.set(OptionTypes.LanguageAndTime, this.webAppSelectedTimezone, selectedTimezone.id);
    }

    public onDisplayTimezoneChanged(isTimezoneDisplayed: boolean): void {
        this.userSettingsService.set(OptionTypes.LanguageAndTime, this.webAppDisplayTimeZone, isTimezoneDisplayed);
    }

    public onUseDeviceTimezoneChanged(mode: string): void {
        this.selectedTimezoneMode = Number.parseInt(mode);
        const useDeviceTimezone = this.selectedTimezoneMode === TimezoneMode.Device;
        this.userSettingsService.set(OptionTypes.LanguageAndTime, this.webAppUseDeviceTimezone, useDeviceTimezone);
    }
}
