// Autogenerated: v2/help/Entities/ApiVisitor?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:02 AM
import { Entity } from '../Entity';
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IRestResponse } from '../../Interface/IRestResponse';
import { IEntity } from '../../Interface/IEntity';
import { IVisitorEntity, IVisitorImage, VisitorEntityFields } from '../../Interface/IVisitorEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';
import HttpStatusCode from '../../Enumerations/HttpStatusCode';
import { RestResponse } from '../../../Connection/RestResponse';

// #region Inner classes

export class VisitorImage extends FieldObject implements IVisitorImage {
    // Guid
    public get guid(): IGuid {
        return this.getFieldGuid('Guid');
    }
    public set guid(value: IGuid) {
        this.setFieldGuid('Guid', value);
    }
    // Data
    public get data(): string {
        return this.getField<string>('Data');
    }
    public set data(value: string) {
        this.setField<string>('Data', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Guid', SafeGuid.EMPTY);
        this.setField<string>('Data', '');
    }
}

// #endregion Inner classes

export class VisitorEntity extends Entity implements IVisitorEntity {
    // #region REST Properties

    // AccessPermissionLevel
    public get accessPermissionLevel(): number {
        return this.getField<number>(VisitorEntityFields.accessPermissionLevelField);
    }
    public set accessPermissionLevel(value: number) {
        this.setField<number>(VisitorEntityFields.accessPermissionLevelField, value);
    }

    // AccessStatus
    public get accessStatus(): string {
        return this.getField<string>(VisitorEntityFields.accessStatusField);
    }
    public set accessStatus(value: string) {
        this.setField<string>(VisitorEntityFields.accessStatusField, value);
    }

    // ActivationDate
    public get activationDate(): Date {
        return this.getFieldDate(VisitorEntityFields.activationDateField);
    }
    public set activationDate(value: Date) {
        this.setFieldDate(VisitorEntityFields.activationDateField, value);
    }

    // AntipassbackExemption
    public get antipassbackExemption(): boolean {
        return this.getField<boolean>(VisitorEntityFields.antipassbackExemptionField);
    }
    public set antipassbackExemption(value: boolean) {
        this.setField<boolean>(VisitorEntityFields.antipassbackExemptionField, value);
    }

    // AntipassbackExemptionIsInherited
    public get antipassbackExemptionIsInherited(): boolean {
        return this.getField<boolean>(VisitorEntityFields.antipassbackExemptionIsInheritedField);
    }
    public set antipassbackExemptionIsInherited(value: boolean) {
        this.setField<boolean>(VisitorEntityFields.antipassbackExemptionIsInheritedField, value);
    }

    // CheckinDate
    public get checkinDate(): Date {
        return this.getFieldDate(VisitorEntityFields.checkinDateField);
    }

    // CheckoutDate
    public get checkoutDate(): Date {
        return this.getFieldDate(VisitorEntityFields.checkoutDateField);
    }

    // EmailAddress
    public get emailAddress(): string {
        return this.getField<string>(VisitorEntityFields.emailAddressField);
    }
    public set emailAddress(value: string) {
        this.setField<string>(VisitorEntityFields.emailAddressField, value);
    }

    // Escort
    public get escort(): IGuid {
        return this.getFieldGuid(VisitorEntityFields.escortField);
    }
    public set escort(value: IGuid) {
        this.setFieldGuid(VisitorEntityFields.escortField, value);
    }

    // Escort2
    public get escort2(): IGuid {
        return this.getFieldGuid(VisitorEntityFields.escort2Field);
    }
    public set escort2(value: IGuid) {
        this.setFieldGuid(VisitorEntityFields.escort2Field, value);
    }

    // ExpirationDate
    public get expirationDate(): Date {
        return this.getFieldDate(VisitorEntityFields.expirationDateField);
    }
    public set expirationDate(value: Date) {
        this.setFieldDate(VisitorEntityFields.expirationDateField, value);
    }

    // ExpirationDuration
    public get expirationDuration(): number {
        return this.getField<number>(VisitorEntityFields.expirationDurationField);
    }
    public set expirationDuration(value: number) {
        this.setField<number>(VisitorEntityFields.expirationDurationField, value);
    }

    // ExpirationMode
    public get expirationMode(): string {
        return this.getField<string>(VisitorEntityFields.expirationModeField);
    }
    public set expirationMode(value: string) {
        this.setField<string>(VisitorEntityFields.expirationModeField, value);
    }

    // FirstName
    public get firstName(): string {
        return this.getField<string>(VisitorEntityFields.firstNameField);
    }
    public set firstName(value: string) {
        this.setField<string>(VisitorEntityFields.firstNameField, value);
    }

    // HasPicture
    public get hasPicture(): boolean {
        return this.getField<boolean>(VisitorEntityFields.hasPictureField);
    }

    // InheritAccessPermissionLevelFromGroup
    public get inheritAccessPermissionLevelFromGroup(): boolean {
        return this.getField<boolean>(VisitorEntityFields.inheritAccessPermissionLevelFromGroupField);
    }
    public set inheritAccessPermissionLevelFromGroup(value: boolean) {
        this.setField<boolean>(VisitorEntityFields.inheritAccessPermissionLevelFromGroupField, value);
    }

    // IsArchived
    public get isArchived(): boolean {
        return this.getField<boolean>(VisitorEntityFields.isArchivedField);
    }

    // IsVisitor
    public get isVisitor(): boolean {
        return this.getField<boolean>(VisitorEntityFields.isVisitorField);
    }

    // LastAccess
    public get lastAccess(): Date {
        return this.getFieldDate(VisitorEntityFields.lastAccessField);
    }

    // LastAccessGranted
    public get lastAccessGranted(): boolean | null {
        return this.getNullableField<boolean>(VisitorEntityFields.lastAccessGrantedField);
    }

    // LastAccessLocation
    public get lastAccessLocation(): IGuid {
        return this.getFieldGuid(VisitorEntityFields.lastAccessLocationField);
    }

    // LastName
    public get lastName(): string {
        return this.getField<string>(VisitorEntityFields.lastNameField);
    }
    public set lastName(value: string) {
        this.setField<string>(VisitorEntityFields.lastNameField, value);
    }

    // MandatoryEscort
    public get mandatoryEscort(): boolean {
        return this.getField<boolean>(VisitorEntityFields.mandatoryEscortField);
    }
    public set mandatoryEscort(value: boolean) {
        this.setField<boolean>(VisitorEntityFields.mandatoryEscortField, value);
    }

    // MobilePhoneNumber
    public get mobilePhoneNumber(): string {
        return this.getField<string>(VisitorEntityFields.mobilePhoneNumberField);
    }
    public set mobilePhoneNumber(value: string) {
        this.setField<string>(VisitorEntityFields.mobilePhoneNumberField, value);
    }

    // UseExtendedGrantTime
    public get useExtendedGrantTime(): boolean {
        return this.getField<boolean>(VisitorEntityFields.useExtendedGrantTimeField);
    }
    public set useExtendedGrantTime(value: boolean) {
        this.setField<boolean>(VisitorEntityFields.useExtendedGrantTimeField, value);
    }

    // ValidateEscortRules
    public set validateEscortRules(value: boolean) {
        this.setField<boolean>(VisitorEntityFields.validateEscortRulesField, value);
    }

    // VisitDate
    public get visitDate(): Date {
        return this.getFieldDate(VisitorEntityFields.visitDateField);
    }
    public set visitDate(value: Date) {
        this.setFieldDate(VisitorEntityFields.visitDateField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Visitors);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getAccessPointRulesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('AccessPointRules', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getUsergroupsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cardholdergroups', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getCredentialsAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Credentials', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async deletePictureAsync(): Promise<IRestResponse> {
        await this.updateDataRelation2Async<VisitorImage, IVisitorImage>(VisitorImage, 'Picture', 'DELETE', false, ValidFlags.None, null);
        return new RestResponse(HttpStatusCode.OK);
    }

    public async getPictureAsync(transaction?: ITransaction | null): Promise<IVisitorImage | null> {
        return await this.getDataRelationAsync<VisitorImage>(VisitorImage, 'Picture', false, ValidFlags.None, transaction);
    }

    public async addPictureAsync(value: IVisitorImage, transaction?: ITransaction | null): Promise<IVisitorImage | null> {
        return await this.updateDataRelation2Async<VisitorImage, IVisitorImage>(VisitorImage, 'Picture', 'POST', false, ValidFlags.None, value);
    }

    // #endregion REST Methods

}

