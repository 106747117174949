import { GuidSet, IGuid, SafeGuid } from 'safeguid';
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { IEntityBrowserFilter } from '../interfaces/filters/entity-browser-filter.interface';
import { EntityTypeSet } from '../entity-type-set';
import { FieldFilterSet } from '../../models/field-filter-set';
import { IFieldFilterData } from '../../models/field-filter.data';
import { IEntityTypeData } from '../../models/entity-type-data';
import { IEntityBrowserFilterData } from '../interfaces/filters/entity-browser-filter-data.interface';
import { PageInfo } from '../interfaces/filters/page-info';
import { IEntityTypeSet } from '../interfaces/entity-type-set.interface';
import { EntityBrowserFilterData } from './entity-browser-filter.data';

export class EntityBrowserFilter implements IEntityBrowserFilter {
    public checkedEntityIds?: Set<IGuid>;
    public entityIds = new GuidSet();
    public entityOwnerTypes = new GuidSet();
    public entityTypes: IEntityTypeSet;
    public selectableEntityTypes: IEntityTypeSet;
    public excludedCheckedEntityIds?: Set<IGuid>;
    public excludedEntities = new GuidSet();
    public fieldFilters = new FieldFilterSet();
    public includedEntities = new GuidSet();
    public pageInfo = new PageInfo();
    public refresherType = SafeGuid.EMPTY;
    public searchLogicalIds = false;
    public selectedEntityIds = new GuidSet();
    public textFilter = '';

    public get isHierarchical(): boolean {
        return false;
    }

    constructor(
        entityTypes?: IEntityTypeData | string | IEntityTypeData[] | Iterable<IEntityTypeData> | string[] | Iterable<string>,
        selectableEntityTypes?: IEntityTypeData | string | IEntityTypeData[] | Iterable<IEntityTypeData> | string[] | Iterable<string>
    ) {
        this.pageInfo.page = 1;
        this.pageInfo.pageSize = 100;
        this.pageInfo.lastEntityId = SafeGuid.EMPTY;

        this.entityTypes = new EntityTypeSet();
        if (entityTypes) {
            if (isString(entityTypes) || 'type' in entityTypes) {
                this.entityTypes.add(entityTypes);
            } else {
                this.entityTypes.addRange(entityTypes);
            }
        }

        this.selectableEntityTypes = new EntityTypeSet();
        if (selectableEntityTypes) {
            if (isString(selectableEntityTypes) || 'type' in selectableEntityTypes) {
                this.selectableEntityTypes.add(selectableEntityTypes);
            } else {
                this.selectableEntityTypes.addRange(selectableEntityTypes);
            }
        }
    }

    public clone(filter?: IEntityBrowserFilter): IEntityBrowserFilter {
        let clonedFilter = filter;
        if (!clonedFilter) {
            clonedFilter = this.createFilter();
        }
        clonedFilter.entityIds = new GuidSet(this.entityIds);
        clonedFilter.entityOwnerTypes = new GuidSet(this.entityOwnerTypes);
        clonedFilter.entityTypes = new EntityTypeSet(this.entityTypes);
        clonedFilter.selectableEntityTypes = new EntityTypeSet(this.selectableEntityTypes);
        clonedFilter.excludedEntities = new GuidSet(this.excludedEntities);
        clonedFilter.fieldFilters = new FieldFilterSet(this.fieldFilters);
        clonedFilter.includedEntities = new GuidSet(this.includedEntities);
        clonedFilter.pageInfo = this.pageInfo.clone();
        clonedFilter.refresherType = this.refresherType;
        clonedFilter.searchLogicalIds = this.searchLogicalIds;
        clonedFilter.textFilter = this.textFilter;
        return clonedFilter;
    }

    public extractRestParam(): IEntityBrowserFilterData {
        const param = this.createRestParam();
        this.fillRestParam(param);
        return param;
    }

    protected createFilter(): EntityBrowserFilter {
        return new EntityBrowserFilter();
    }

    protected createRestParam(): IEntityBrowserFilterData {
        return new EntityBrowserFilterData();
    }

    protected fillRestParam(param: IEntityBrowserFilterData): void {
        if (param instanceof EntityBrowserFilterData) {
            param.entityIds = new ObservableCollection<IGuid>();
            for (const guid of this.entityIds) {
                param.entityIds.add(guid);
            }

            param.entityOwnerTypes = new ObservableCollection<IGuid>();
            for (const guid of this.entityOwnerTypes) {
                param.entityOwnerTypes.add(guid);
            }

            param.entityTypes = new ObservableCollection<IEntityTypeData>();
            for (const entityType of this.entityTypes) {
                param.entityTypes.add(entityType);
            }

            param.excludedEntities = new ObservableCollection<IGuid>();
            for (const guid of this.excludedEntities) {
                param.excludedEntities.add(guid);
            }

            param.fieldFilters = new ObservableCollection<IFieldFilterData>();
            for (const filter of this.fieldFilters) {
                param.fieldFilters.add(filter.extractRestParam());
            }

            param.includedEntities = new ObservableCollection<IGuid>();
            for (const guid of this.includedEntities) {
                param.includedEntities.add(guid);
            }

            param.pageInfo = this.pageInfo;
            param.refresherType = this.refresherType;
            param.searchLogicalIds = this.searchLogicalIds;
            param.textFilter = this.formatText(this.textFilter);
        }
    }

    private formatText(textToFilter: string): string {
        const result = JSON.stringify(textToFilter);
        return encodeURIComponent(result.substring(1, result.length - 1));
    }
}
