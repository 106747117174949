import { Inject, Injectable } from '@angular/core';
import { MeltedIcon } from '@genetec/gelato-angular';
import { CollapsedReasons, MiniMap, MiniMapStateEnum } from '@genetec/web-maps';
import { MapOptionsComponent } from '@modules/general/components/options/maps-options/maps-options.component';
import { MapCommands } from '@modules/maps/enumerations/map-commands';
import { MapContentTypes } from '@modules/maps/enumerations/maps-content-types';
import { OptionTypes } from '@modules/shared/enumerations/option-types';
import { ContextTypes } from '@modules/shared/interfaces/plugins/public/context-types';
import {
    CommandBindings,
    CommandDisplay,
    CommandsService,
    COMMANDS_SERVICE,
    FEATURES_SERVICE,
    USER_SETTINGS_SERVICE,
} from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { CommandArgs, CommandDescriptor } from '@modules/shared/services/commands/command-provider-base';
import { EntityCommandContext } from '@modules/shared/services/commands/entity-command-context';
import { EntityCommandProviderBase } from '@modules/shared/services/commands/entity-command-provider-base';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { LanguageService } from '@modules/shared/services/language/language.service';
import { UserSettingsService } from '@modules/shared/services/user-settings/user-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { IGuid, SafeGuid } from 'safeguid';
import { StateMapService } from './state-map-service';

@Injectable()
export class MapCommandsProvider extends EntityCommandProviderBase {
    constructor(
        @Inject(COMMANDS_SERVICE) protected commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        protected translateService: TranslateService,
        private mapService: StateMapService,
        @Inject(USER_SETTINGS_SERVICE) public userSettingsService: UserSettingsService,
        protected advancedSettingsService: AdvancedSettingsService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(MapCommands.ToggleMinimap, {
                id: MapCommands.ToggleMinimap,
                nameResourceId: this.translateService.instant('STE_ACTION_SHOW_MINIMAP') as string,
                icon: MeltedIcon.Rectangle,
                requirements: this.fillCommandRequirements({ supportedContentTypes: SafeGuid.createSet([MapContentTypes.Map]) }),
                keyBinding: 'SHIFT.M',
            });

            this.internalCommandDescriptors = descriptors;
        }
        return this.internalCommandDescriptors;
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);

        if (await this.isToggleMiniMapCommandAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(MapCommands.ToggleMinimap);
        }

        return Promise.resolve(commandIds);
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: MapCommands.ToggleMinimap,
            isCommandAvailableHandler: (commandContext) => this.safeCanExecuteEntityCommand(() => this.isShowMiniMapCommandAvailable(), commandContext),
            executeCommandHandler: (executeCommandData) => this.safeExecuteEntityCommand(() => this.toggleMiniMap(), executeCommandData),
            getCommandDisplayHandler: (commandContext) => this.safeGetEntityCommandDisplay(() => this.getShowMiniMapDisplay(), commandContext),
        });
    }

    private getMiniMapState(): MiniMapStateEnum | null {
        return this.mapService?.getMiniMap()?.getState() ?? null;
    }

    private toggleMiniMap() {
        let isMiniMapDisabled = false;
        const miniMapState = this.getMiniMapState();
        switch (miniMapState) {
            case MiniMapStateEnum.Disabled:
                this.mapService.map?.miniMap?.show();
                break;
            case MiniMapStateEnum.Enabled:
                this.mapService.map?.miniMap?.hide();
                isMiniMapDisabled = true;
                break;
        }

        this.userSettingsService.set(OptionTypes.Maps, MapOptionsComponent.disableMinimapSettingId, isMiniMapDisabled);
        this.userSettingsService.apply().fireAndForget();
    }

    private isShowMiniMapCommandAvailable(): boolean {
        return this.getMiniMapState() !== MiniMapStateEnum.Collapsed;
    }

    private getShowMiniMapDisplay(): CommandDisplay | undefined {
        const miniMapState = this.getMiniMapState();
        switch (miniMapState) {
            case MiniMapStateEnum.Disabled:
                return {
                    name: () => this.translateService.instant('STE_ACTION_SHOW_MINIMAP') as string,
                    icon: MeltedIcon.Rectangle,
                };
            case MiniMapStateEnum.Enabled:
                return {
                    name: () => this.translateService.instant('STE_ACTION_DISABLE_MINIMAP') as string,
                };
        }

        return undefined;
    }

    private async isToggleMiniMapCommandAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return (
            commandContext.type.equals(ContextTypes.Map) &&
            this.getMiniMapState() != null &&
            (await this.areCommandRequirementsMetAsync(MapCommands.ToggleMinimap, commandContext, commandArgs))
        );
    }
}
