import { EventBase } from 'RestClient/Client/Event/EventBase';
import { ISecurityCenterClient } from 'RestClient/Client/Interface/ISecurityCenterClient';
import { IGuid, SafeGuid } from 'safeguid';

export enum ExportStatus2 {
    Running = 'Running',
    Success = 'Success',
    NoVideoError = 'NoVideoError',
    EncryptedError = 'EncryptedError',
    CodecNotSupportedError = 'CodecNotSupportedError',
    CameraNotFoundError = 'CameraNotFoundError',
    TooManyConnectionsError = 'TooManyConnectionsError',
    InsufficientPrivilegeError = 'InsufficientPrivilegeError',
    UnknownError = 'UnknownError',
    Cancelled = 'Cancelled',
    TooManyConcurrentRequestsError = 'TooManyConcurrentRequestsError',
    RequestIsThrottled = 'RequestIsThrottled',
    LegacyExportWithEncryptionNotSupported = 'LegacyExportWithEncryptionNotSupported',
}

/** Represents a video export progress updated event. Keep in sync with VideoExportProgressEvent.cs */
export class VideoExportProgressEvent extends EventBase {
    //#region Properties

    // Status
    public get status(): ExportStatus2 {
        const statusString = this.getField<string>('Status');
        if (statusString as ExportStatus2) {
            return statusString as ExportStatus2;
        } else {
            return ExportStatus2.UnknownError;
        }
    }
    public set status(status: ExportStatus2) {
        this.setField<string>('Status', status);
    }

    // Progress
    public get progress(): number {
        return this.getField<number>('Progress');
    }
    public set progress(progress: number) {
        this.setField<number>('Progress', progress);
    }

    // ExportId
    public get exportId(): IGuid {
        return this.getFieldGuid('ExportId');
    }
    public set exportId(exportId: IGuid) {
        this.setFieldGuid('ExportId', exportId);
    }

    // Completed
    public get completed(): boolean {
        return this.getField<boolean>('Completed');
    }
    public set completed(completed: boolean) {
        this.setField<boolean>('Completed', completed);
    }

    // CameraId
    public get cameraId(): IGuid {
        return this.getFieldGuid('CameraId');
    }
    public set cameraId(cameraId: IGuid) {
        this.setFieldGuid('CameraId', cameraId);
    }

    //#endregion

    //#region Constructors

    constructor() {
        super();
        this.eventType = 'VideoExportProgressEvent';
    }

    //#endregion

    //#region Public Methods

    /** Setup the handler callback on the REST subscription, so we are subscribing to "VideoExportProgressEvent"s */
    public static setupCallbackOnRestConnection(client: ISecurityCenterClient, callback: (event: VideoExportProgressEvent) => void): (() => void) | undefined {
        if (client) {
            // add the event def
            client.registerAdditionalEventTypes('VideoExportProgressEvent', VideoExportProgressEvent);
            // wire up the subscription
            return client.onEventReceived((arg) => {
                if (arg?.event?.eventType === 'VideoExportProgressEvent') {
                    callback(arg.event as VideoExportProgressEvent);
                }
            });
        }
        return undefined;
    }

    //#endregion
}
