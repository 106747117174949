import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';

export const INCIDENT_LOCATION = [
    EntityTypes.Areas,
    EntityTypes.Alarms,
    EntityTypes.Cameras,
    EntityTypes.Doors,
    EntityTypes.LprUnits,
    EntityTypes.Patrollers,
    EntityTypes.TileLayouts,
    EntityTypes.TilePlugins,
    EntityTypes.Sequences,
];
