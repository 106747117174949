<ng-container *ngIf="watchlist$ | async; else spinner">
    <!-- The list of events -->
    <app-event-list *ngIf="hasWatchlistEntries$ | async; else addItems"></app-event-list>

    <!-- Add new items -->
    <ng-template #addItems>
        <div class="add-items-container">
            <gen-button class="add-items-button ha-center va-center" [flavor]="ButtonFlavor.Flat" (click)="onAddItemsClick()">{{ 'STE_BUTTON_ADD_ITEMS' | translate }}</gen-button>
        </div>
    </ng-template>
</ng-container>

<!-- Spinner -->
<ng-template #spinner>
    <div class="ha-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
