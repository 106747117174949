// Autogenerated: v2/help/Entities/ApiUserGroup?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-30 8:57:49 AM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { ValidFlags } from '../Enumerations/ValidFlags';
import { EntityTypes } from '../Enumerations/EntityTypes';
import { PrivilegedEntity } from './PrivilegedEntity';
import { IEntity } from '../Interface/IEntity';
import { IUserGroupEntity, UserGroupEntityFields } from '../Interface/IUserGroupEntity';
import { IQueryFilter } from '../Interface/IQueryFilter';
import { ITransaction } from '../Interface/ITransaction';

export class UserGroupEntity extends PrivilegedEntity implements IUserGroupEntity {
    // #region REST Properties

    // ExternalUniqueId
    public get externalUniqueId(): string {
        return this.getField<string>(UserGroupEntityFields.externalUniqueIdField, ValidFlags.Specific);
    }
    public set externalUniqueId(value: string) {
        this.setField<string>(UserGroupEntityFields.externalUniqueIdField, value);
    }

    // ExternalUniqueIdProvider
    public get externalUniqueIdProvider(): string {
        return this.getField<string>(UserGroupEntityFields.externalUniqueIdProviderField, ValidFlags.Specific);
    }
    public set externalUniqueIdProvider(value: string) {
        this.setField<string>(UserGroupEntityFields.externalUniqueIdProviderField, value);
    }

    // SkipPtzEvaluation
    public get skipPtzEvaluation(): boolean {
        return this.getField<boolean>(UserGroupEntityFields.skipPtzEvaluationField, ValidFlags.SpecificExtra);
    }
    public set skipPtzEvaluation(value: boolean) {
        this.setField<boolean>(UserGroupEntityFields.skipPtzEvaluationField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.UserGroups);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getMembersAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Members', 'Id', false, query, ValidFlags.None, transaction);
    }

    public async getMembersRecursiveAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('MembersRecursive', 'Id', true, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

