import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { TextFlavor } from '@genetec/gelato-angular';
import { Datum } from '@modules/shared/interfaces/datum';
import { PrivilegeService } from '@shared/privilege/privilege.service';
import { TypedSimpleChanges } from '@modules/shared/utilities/typed-simple-changes';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';

@Component({
    selector: 'app-cardholder-card-view',
    templateUrl: './cardholder-card-view.component.html',
    styleUrls: ['./cardholder-card-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardholderCardViewComponent implements OnChanges {
    @Input() selectedId: IGuid | null = null;
    @Input() cardholders: Datum[] | null = null;
    @Input() refreshing = true;
    @Input() hasMore = true;
    @Output() loadMore = new EventEmitter();
    @Output() selectedChanged = new EventEmitter<Datum>();

    @ViewChild('container') container!: HTMLElement;

    public isLoadingMore$: Observable<boolean>;
    public readonly TextFlavor = TextFlavor;

    public isViewCardholdersPrivilegeGranted = false;
    private isLoadingMoreSubject = new BehaviorSubject<boolean>(false);

    constructor(privilegeService: PrivilegeService) {
        this.isViewCardholdersPrivilegeGranted = privilegeService.isGlobalPrivilegeGranted(KnownPrivileges.viewCardholdersPrivilege);
        this.isLoadingMore$ = this.isLoadingMoreSubject.asObservable();
    }

    ngOnChanges(changes: TypedSimpleChanges<CardholderCardViewComponent>): void {
        if (changes.cardholders?.previousValue && (changes.cardholders?.currentValue?.length ?? 0) > changes.cardholders.previousValue.length) {
            this.isLoadingMoreSubject.next(false);
        }
    }

    onLoadMore(): void {
        this.isLoadingMoreSubject.next(true);
        this.loadMore.emit();
    }

    onSelectedChanged(cardholder: Datum): void {
        if (!this.selectedId || (SafeGuid.isGuid(cardholder.guid) && !this.selectedId?.equals(cardholder.guid as string | IGuid))) {
            this.selectedChanged.emit(cardholder);
        }
    }

    public buildEntityName(cardholder: Datum): string {
        if (cardholder.firstName || cardholder.lastName) {
            return ((cardholder.firstName as string) + ' ' + (cardholder.lastName as string)).trim();
        }
        return cardholder.name as string;
    }
}
