import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { EventHelper } from '../modules/shared/utilities/event.helper';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class TouchEventHandler implements OnDestroy {
    private static CONTEXT_MENU_DELAY = 900;
    private static DOUBLE_CLICK_DELAY = 500;
    private static DRAG_IMAGE_OPACITY = 0.5;
    private static DRAG_MOVEMENT_THRESHOLD = 5;

    public dropTarget: Element | null = null;
    public dragSource: Element | null = null;

    private customDragImage: Element | null = null;
    private dataTransfer: DataTransfer | null = null;
    private dragImage: HTMLElement | null = null;
    private dragImageOffset: { x: number; y: number } = { x: 0, y: 0 };
    private isDoubleClicking = false;
    private isDragging = false;
    private isPressingDown = false;
    private lastClickTime = 0;
    private lastDragOverTarget: Element | null = null;
    private lastTouchMoveEvent: TouchEvent | null = null;
    private lastTouchStartEvent: TouchEvent | null = null;
    private timeouts: ReturnType<typeof setTimeout>[] = [];
    private touchStartPoint: { x: number; y: number } | null = null;

    constructor() {
        this.subscribeToHost();
    }

    public ngOnDestroy(): void {
        this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
        });
    }

    private copyStyle(source: Element, destination: Element) {
        destination.removeAttribute('id');
        destination.removeAttribute('class');
        destination.removeAttribute('style');
        destination.removeAttribute('draggable');

        if (source instanceof HTMLCanvasElement && destination instanceof HTMLCanvasElement) {
            const sourceCopy = source;
            const destinationCopy = destination;
            destinationCopy.width = sourceCopy.width;
            destinationCopy.height = sourceCopy.height;
            destinationCopy?.getContext('2d')?.drawImage(sourceCopy, 0, 0);
        }

        if (source instanceof HTMLElement && destination instanceof HTMLElement) {
            const style = getComputedStyle(source);
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < style.length; i++) {
                const key = style[i];
                destination.style.setProperty(key, style.getPropertyValue(key));
            }
            destination.style.pointerEvents = 'none';
            for (let i = 0; i < source.children.length; i++) {
                this.copyStyle(source.children[i], destination.children[i]);
            }
        }
    }

    private createDragEvent(eventType: string, touchEvent: TouchEvent | null, target: EventTarget | null): DragEvent | null {
        if (touchEvent && target) {
            const touch = this.getTouch(touchEvent);
            if (touch) {
                const movement = this.getMouvement(touchEvent);
                const eventInit: DragEventInit = {
                    altKey: touchEvent.altKey,
                    bubbles: touchEvent.bubbles,
                    button: 0,
                    buttons: 1,
                    cancelable: true,
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                    composed: touchEvent.composed,
                    ctrlKey: touchEvent.ctrlKey,
                    dataTransfer: this.dataTransfer,
                    detail: touchEvent.detail,
                    metaKey: touchEvent.metaKey,
                    movementX: movement.x,
                    movementY: movement.y,
                    relatedTarget: target,
                    screenX: touch.screenX,
                    screenY: touch.screenY,
                    shiftKey: touchEvent.shiftKey,
                    view: touchEvent.view,
                };
                return new DragEvent(eventType, eventInit);
            }
        }
        return null;
    }

    private createMouseEvent(eventType: string, touchEvent: TouchEvent | null, target: EventTarget | null): MouseEvent | null {
        if (touchEvent && target) {
            const touch = this.getTouch(touchEvent);
            if (touch) {
                const movement = this.getMouvement(touchEvent);

                const eventInit: MouseEventInit = {
                    altKey: touchEvent.altKey,
                    bubbles: touchEvent.bubbles,
                    button: 0,
                    buttons: 1,
                    cancelable: true,
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                    composed: touchEvent.composed,
                    ctrlKey: touchEvent.ctrlKey,
                    detail: touchEvent.detail,
                    metaKey: touchEvent.metaKey,
                    movementX: movement.x,
                    movementY: movement.y,
                    relatedTarget: target,
                    screenX: touch.screenX,
                    screenY: touch.screenY,
                    shiftKey: touchEvent.shiftKey,
                    view: touchEvent.view,
                };
                return new MouseEvent(eventType, eventInit);
            }
        }
        return null;
    }

    private createImage(event: TouchEvent): void {
        if (this.dragImage) {
            this.destroyImage();
        }

        const imageSource = this.customDragImage || this.dragSource;
        if (imageSource) {
            this.dragImage = imageSource.cloneNode(true) as HTMLElement;
            this.copyStyle(imageSource, this.dragImage);
            this.dragImage.style.top = this.dragImage.style.left = '-9999px';
            if (!this.customDragImage) {
                const rect = imageSource.getBoundingClientRect();
                const point = this.getPoint(event);
                if (point) {
                    this.dragImageOffset = { x: point.x - rect.left, y: point.y - rect.top };
                }
                this.dragImage.style.opacity = TouchEventHandler.DRAG_IMAGE_OPACITY.toString();
            }
            this.moveImage(event);
            document.body.appendChild(this.dragImage);
        }
    }

    private destroyImage(): void {
        if (this.dragImage?.parentElement) {
            this.dragImage.parentElement.removeChild(this.dragImage);
        }
        this.dragImage = null;
    }

    private async endTouchAsync(event: TouchEvent, isCancel: boolean) {
        try {
            if (!this.isValidEvent(event)) {
                return;
            }

            if (await this.tryDispatchMouseEventAsync('mouseup', event, event.target)) {
                event.preventDefault();
                return;
            }

            if (!this.dragImage && this.lastTouchStartEvent && event.target === this.lastTouchStartEvent.target) {
                if (this.isDoubleClicking) {
                    if (await this.tryDispatchMouseEventAsync('dblclick', this.lastTouchStartEvent, this.lastTouchStartEvent.target)) {
                        event.preventDefault();
                        return;
                    }
                } else {
                    // click already seems to be handled fine for touch events
                    // await this.tryDispatchMouseEventAsync('click', this.lastTouchStartEvent, this.lastTouchStartEvent.target);
                    this.lastClickTime = Date.now();
                }
            }

            this.destroyImage();
            if (this.lastTouchMoveEvent) {
                if (!isCancel) {
                    await this.tryDispatchDragEventAsync('drop', this.lastTouchMoveEvent, this.dropTarget);
                }
                await this.tryDispatchDragEventAsync('dragend', this.lastTouchMoveEvent, this.dragSource);
            }
        } finally {
            this.reset();
        }
    }

    private getClosestDraggable(event: TouchEvent) {
        let element: Element | null = null;
        const target = event.target;
        if (target instanceof Element) {
            element = target;
        }

        while (element && !element.hasAttribute('draggable')) {
            element = element.parentElement as Element;
        }

        return element;
    }

    private getDropTarget(event: TouchEvent) {
        const point = this.getPoint(event);
        if (point) {
            let element = document.elementFromPoint(point.x, point.y);
            while (element && getComputedStyle(element).pointerEvents === 'none') {
                element = element.parentElement;
            }
            return element;
        }
        return null;
    }

    private getMouvement(touchEvent: TouchEvent) {
        if (touchEvent.touches.length === 1) {
            const touch = touchEvent.touches[0];

            let movementX = 0;
            let movementY = 0;
            if (touchEvent.changedTouches.length === 1) {
                const changeTouch = touchEvent.changedTouches[0];
                movementX = touch.screenX - changeTouch.screenX;
                movementY = touch.screenY - changeTouch.screenY;
            }

            return { x: movementX, y: movementY };
        }

        return { x: 0, y: 0 };
    }

    private getTotalMovement(event: TouchEvent) {
        if (this.touchStartPoint) {
            const p = this.getPoint(event);
            if (p) {
                return Math.abs(p.x - this.touchStartPoint.x) + Math.abs(p.y - this.touchStartPoint.y);
            }
        }

        return 0;
    }

    private getTouch(touchEvent: TouchEvent) {
        if (touchEvent.touches.length === 1) {
            const touch = touchEvent.touches[0];

            let movementX = 0;
            let movementY = 0;
            if (touchEvent.changedTouches.length === 1) {
                const changeTouch = touchEvent.changedTouches[0];
                movementX = touch.screenX - changeTouch.screenX;
                movementY = touch.screenY - changeTouch.screenY;
            }

            return touch;
        }

        return null;
    }

    private getPoint(event: TouchEvent, usePage = false) {
        if (event?.touches && event.touches.length === 1) {
            const touch = event.touches[0];
            return { x: usePage ? touch.pageX : touch.clientX, y: usePage ? touch.pageY : touch.clientY };
        }

        return null;
    }

    private async handleTouchCancelAsync(event: TouchEvent) {
        await this.endTouchAsync(event, true);
    }

    private async handleTouchEndAsync(event: TouchEvent) {
        await this.endTouchAsync(event, false);
    }

    private async handleTouchMoveAsync(event: TouchEvent) {
        try {
            if (!this.isValidEvent(event)) {
                return;
            }

            const target = this.getDropTarget(event);
            if (target) {
                if (await this.tryDispatchMouseEventAsync('mousemove', event, target)) {
                    event.preventDefault();
                    return;
                }

                if (!this.isDragging) {
                    const delta = this.getTotalMovement(event);
                    if (delta > TouchEventHandler.DRAG_MOVEMENT_THRESHOLD) {
                        this.dataTransfer = new DataTransfer();
                        this.dataTransfer.setDragImage = this.setDragImage.bind(this);
                        this.isDragging = true;
                        await this.tryDispatchDragEventAsync('dragstart', event, this.dragSource);
                        this.createImage(event);
                        await this.tryDispatchDragEventAsync('dragenter', event, target);
                        this.dropTarget = target;
                    }
                }

                if (this.isDragging) {
                    event.preventDefault(); // prevent scrolling
                    if (this.dropTarget && this.dropTarget !== target) {
                        await this.tryDispatchDragEventAsync('dragleave', this.lastTouchMoveEvent, this.dropTarget);
                        await this.tryDispatchDragEventAsync('dragenter', event, target);
                        this.dropTarget = target;
                    }

                    this.moveImage(event);
                    await this.tryDispatchDragEventAsync('dragover', event, target);
                }
            }
        } finally {
            this.lastTouchStartEvent = null;
            this.lastTouchMoveEvent = event;
        }
    }

    private async handleTouchStartAsync(event: TouchEvent) {
        if (!this.isValidEvent(event)) {
            return;
        }

        try {
            if (
                !this.isDoubleClicking &&
                Date.now() - this.lastClickTime < TouchEventHandler.DOUBLE_CLICK_DELAY &&
                this.lastTouchStartEvent &&
                this.lastTouchStartEvent.target === event.target
            ) {
                this.isDoubleClicking = true;
                return;
            }

            this.reset();
            const sourceElement = this.getClosestDraggable(event);
            if (sourceElement) {
                if (!(await this.tryDispatchMouseEventAsync('mousemove', event, event.target)) && !(await this.tryDispatchMouseEventAsync('mousedown', event, event.target))) {
                    this.dragSource = sourceElement;
                    this.touchStartPoint = this.getPoint(event);
                    event.preventDefault();
                }
            }

            this.timeouts.push(
                setTimeout(async () => {
                    if (event === this.lastTouchStartEvent && this.isPressingDown) {
                        if (await this.tryDispatchMouseEventAsync('contextmenu', this.lastTouchStartEvent, this.lastTouchStartEvent.target)) {
                            this.reset();
                        }
                    }
                }, TouchEventHandler.CONTEXT_MENU_DELAY)
            );
        } finally {
            this.lastTouchStartEvent = event;
            this.lastTouchMoveEvent = null;
            this.isPressingDown = true;
        }
    }

    private isValidEvent(event: Event): boolean {
        if (event && !event.defaultPrevented) {
            if (event instanceof TouchEvent) {
                return event.touches && event.touches.length < 2;
            }
            return true;
        }
        return false;
    }

    private moveImage(event: TouchEvent) {
        requestAnimationFrame(() => {
            if (this.dragImage) {
                const pt = this.getPoint(event, true);
                if (pt) {
                    const s = this.dragImage.style;
                    s.position = 'absolute';
                    s.pointerEvents = 'none';
                    s.zIndex = '999999';
                    s.left = `${Math.round(pt.x - this.dragImageOffset.x)}px`;
                    s.top = `${Math.round(pt.y - this.dragImageOffset.y)}px`;
                }
            }
        });
    }

    private reset(): void {
        this.destroyImage();
        this.isDragging = false;
        this.lastTouchMoveEvent = null;
        this.touchStartPoint = null;
        this.dataTransfer = null;
        this.dragSource = null;
        this.dropTarget = null;
        this.isDoubleClicking = false;
        this.isPressingDown = false;
        this.customDragImage = null;
        this.dragImageOffset = { x: 0, y: 0 };
    }

    private setDragImage(image: Element, x: number, y: number) {
        this.customDragImage = image;
        this.dragImageOffset = { x, y };
    }

    private subscribeToHost(): void {
        const touchStart$ = fromEvent<TouchEvent>(document, 'touchstart');
        const touchMove$ = fromEvent<TouchEvent>(document, 'touchmove');
        const touchEnd$ = fromEvent<TouchEvent>(document, 'touchend');
        const touchCancel$ = fromEvent<TouchEvent>(document, 'touchcancel');

        touchStart$.pipe(untilDestroyed(this)).subscribe(async (event: TouchEvent) => {
            await this.handleTouchStartAsync(event);
        });

        touchMove$.pipe(untilDestroyed(this)).subscribe(async (event: TouchEvent) => {
            await this.handleTouchMoveAsync(event);
        });

        touchEnd$.pipe(untilDestroyed(this)).subscribe(async (event: TouchEvent) => {
            await this.handleTouchEndAsync(event);
        });

        touchCancel$.pipe(untilDestroyed(this)).subscribe(async (event: TouchEvent) => {
            await this.handleTouchCancelAsync(event);
        });
    }

    private async tryDispatchDragEventAsync(eventType: string, event: TouchEvent | null, target: EventTarget | null) {
        const dragEvent = this.createDragEvent(eventType, event, target);
        return await this.tryDispatchEventAsync(dragEvent, target);
    }

    private async tryDispatchEventAsync(event: Event | null, target: EventTarget | null) {
        if (event && target) {
            target.dispatchEvent(event);
            await EventHelper.tryCaptureEventAsync(event);
            EventHelper.releaseEvent(event);
            return event.defaultPrevented;
        }
        return false;
    }

    private async tryDispatchMouseEventAsync(eventType: string, event: TouchEvent | null, target: EventTarget | null) {
        const mouseEvent = this.createMouseEvent(eventType, event, target);
        return await this.tryDispatchEventAsync(mouseEvent, target);
    }
}
