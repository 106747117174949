// Autogenerated: CodeGen/PluginsController?language=ts&InterfaceOnly=False on 2021-04-21 3:54:24 PM
import { ObservableCollection } from 'RestClient/Helpers/ObservableCollection';
import { IGuid, SafeGuid } from 'safeguid';
import { RestObject } from 'RestClient/Client/Model/RestObject';
import { ITransaction } from 'RestClient/Client/Interface/ITransaction';
import { ExternalPluginDescriptor, IExternalPluginDescriptor } from './plugins.controller.data';
import { IPluginsController } from './plugins.controller.interfaces';

export class PluginsController extends RestObject implements IPluginsController {
    constructor() {
        super('api/Plugins');
        this._canGet = false;
    }

    // #region Rest Methods

    public async getExternalDescriptorsAsync(pluginType: IGuid, transaction?: ITransaction | null): Promise<ObservableCollection<IExternalPluginDescriptor> | null> {
        return this.getFieldObjectRelationAsync<ExternalPluginDescriptor, IExternalPluginDescriptor>(ExternalPluginDescriptor, 'ExternalDescriptors/' + pluginType, 'pluginType', true, false, null, transaction);
    }

    // #endregion Rest Methods

}
