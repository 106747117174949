import { Subscription } from 'rxjs';

export class Unsubscribable {
    private subscription: Subscription | (() => void);

    constructor(subscription: Subscription | (() => void)) {
        this.subscription = subscription;
    }

    public static unsubscribe(subscription: Subscription | (() => void)): void {
        if (subscription instanceof Subscription) {
            subscription.unsubscribe();
        } else {
            subscription();
        }
    }

    public unsubscribe(): void {
        Unsubscribable.unsubscribe(this.subscription);
    }
}

export class SubscriptionCollection {
    private subscriptions: (Subscription | (() => void))[] = [];

    public add(...subscription: (Subscription | (() => void))[]): void {
        this.subscriptions.push(...subscription);
    }

    public remove(subscription: Subscription | (() => void)): Unsubscribable | undefined {
        const index = this.subscriptions.indexOf(subscription);
        if (index > 0) {
            const removed = this.subscriptions.splice(index, 1);
            return new Unsubscribable(removed[0]);
        }
        return undefined;
    }

    public unsubscribeAll(): void {
        for (const subscription of this.subscriptions) {
            Unsubscribable.unsubscribe(subscription);
        }
        this.subscriptions.length = 0;
    }
}
