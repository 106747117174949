// Autogenerated: v2/help/Entities/ApiLicensePlateManagerRole?codegen=ts&InterfaceOnly=True&workflow=False on 2021-08-26 2:56:06 PM
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { IGuid } from 'safeguid';
import { IEntity } from './IEntity';
import { IFieldObject } from './IFieldObject';
import { IRoleEntity, RoleEntityFields } from './IRoleEntity';
import { IQueryFilter } from './IQueryFilter';
import { ITransaction } from './ITransaction';

// #region Fields

export class LicensePlateManagerRoleEntityFields extends RoleEntityFields {
    public static archiverIdField = 'ArchiverId';
    public static eventRetentionInDaysField = 'EventRetentionInDays';
    public static hitImageRetentionInDaysField = 'HitImageRetentionInDays';
    public static hitRetentionInDaysField = 'HitRetentionInDays';
    public static occupancyRetentionInDaysField = 'OccupancyRetentionInDays';
    public static parkingZoneDataRetentionInDaysField = 'ParkingZoneDataRetentionInDays';
    public static positionRetentionInDaysField = 'PositionRetentionInDays';
    public static readImageRetentionInDaysField = 'ReadImageRetentionInDays';
    public static readRetentionInDaysField = 'ReadRetentionInDays';
}

// #endregion Fields

// #region Inner classes

export interface IModuleConfig extends IFieldObject {
    id: string;
    isActive: boolean;
}

// #endregion Inner classes

export interface ILicensePlateManagerRoleEntity extends IRoleEntity {
    // #region REST Properties

    archiverId: IGuid;

    eventRetentionInDays: number;

    hitImageRetentionInDays: number;

    hitRetentionInDays: number;

    occupancyRetentionInDays: number;

    parkingZoneDataRetentionInDays: number;

    positionRetentionInDays: number;

    readImageRetentionInDays: number;

    readRetentionInDays: number;

    // #endregion REST Properties

    // #region REST Methods

    getAssociationAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null>;
    getModulesAsync(transaction?: ITransaction | null): Promise<ObservableCollection<IModuleConfig> | null>;

    // #endregion REST Methods

}

