import { IFieldObject } from 'RestClient/Client/Interface/IFieldObject';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { IGuid, SafeGuid } from 'safeguid';

/* eslint-disable */
export interface ICustomFieldData extends IFieldObject {
    id: IGuid;
    valueType: string;
    value: any;
}

export class CustomFieldData extends FieldObject implements ICustomFieldData {
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setField('Id', value);
    }
    public get valueType(): string {
        return this.getField('ValueType');
    }
    public set valueType(value: string) {
        this.setField('ValueType', value);
    }
    public get value(): any {
        return this.getField<any>('Value');
    }
    public set value(value: any) {
        this.setField('Value', value);
    }
}
