import { LogSeverity } from 'RestClient/Client/Enumerations/LogSeverity';

export const environment = {
    production: true,
    logSeverities: [LogSeverity.Warning, LogSeverity.Error],
};

export const getBaseUrl = (): string => {
    return document.getElementsByTagName('base')[0].href;
};
