<gen-button
    *ngIf="quickActions.length > 0"
    data-cy="quick-actions-button"
    [flavor]="ButtonFlavor.Flat"
    appTooltip="{{ 'STE_LABEL_QUICKACTIONS' | translate }}"
    id="btn-toggle-actions"
    (click)="togglePopupOpen()"
    class="focus-style-override"
>
    <gen-icon [icon]="Icon.Action"></gen-icon>
</gen-button>

<gen-popup
    class="quick-action-popup"
    data-cy="quickActionsPopup"
    [position]="PopupPosition.Bottom"
    targetSelector="#btn-toggle-actions"
    appFocusPopupWhenOpen
    genKeyboardNavigation
    (genEscape)="togglePopupOpen()"
>
    <div class="gen-h4 p-1">
        {{ 'STE_LABEL_QUICKACTIONS' | translate }}
    </div>
    <gen-list #list [selectionType]="ListSelectionType.None">
        <gen-list-item class="pointer" *ngFor="let quickAction of quickActions" (click)="executeAction(quickAction)">
            <gen-item>
                <gen-text>{{ quickAction.text }}</gen-text>
                <gen-text [slot]="ItemSlot.TextSecondary">{{ quickAction.secondaryText }}</gen-text>
            </gen-item>
        </gen-list-item>
    </gen-list>
</gen-popup>
