import { IRestObject } from '../Client/Interface/IRestObject';

export class RestOperation {
    public verb = 'GET';
    public body: object | null = null;
    protected _uri = '';

    public get uri(): string {
        return this._uri;
    }

    public set uri(value: string) {
        this._uri = value;
    }

    public toString(): string {
        return JSON.stringify({ verb: this.verb, uri: this.verb, body: this.body });
    }
}

export class RestRelationOperation extends RestOperation {
    public restObject: IRestObject | null = null;

    public get uri(): string {
        if (!this.restObject) {
            throw new Error('restObject is null');
        }
        return this.restObject.baseUri + '/' + this._uri;
    }

    public set uri(value: string) {
        this._uri = value;
    }
}
