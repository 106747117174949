import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { GenAngularModule, GenLanguageModule, GenModalService } from '@genetec/gelato-angular';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { enableMapSet } from 'immer';
import { MapsModule } from '@modules/maps/maps.module';
import { AccessControlModule } from '@modules/access-control/access-control.module';
import { AlarmsModule } from '@modules/alarms/alarms.module';
import { ClearanceModule } from '@modules/clearance/clearance.module';
import { CorrelationModule } from '@modules/correlation/correlation.module';
import { GeneralModule } from '@modules/general/general.module';
import { MissionControlModule } from '@modules/mission-control/mission-control.module';
import { LprModule } from '@modules/lpr/lpr.module';
import { InternalCommandsService } from '@modules/shared/services/commands/commands.service';
import { ContentServicesProviderService } from '@modules/shared/services/content/content-services-provider.service';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { MissingTranslationHelper } from '@modules/shared/services/language/language.service';
import { SideContextDataService } from '@modules/shared/services/side-context-data/side-context-data.service';
import { SideContextDataState } from '@modules/shared/services/side-context-data/side-context-data.state';
import { UserSettingsService } from '@modules/shared/services/user-settings/user-settings.service';
import { UserSettingsState } from '@modules/shared/services/user-settings/user-settings.state';
import { FeatureFlagsState } from '@modules/feature-flags/feature-flags.state';
import { SharedModule } from '@modules/shared/shared.module';
import { TilesModule } from '@modules/tiles/tiles.module';
import { VideoModule } from '@modules/video/video.module';
import { ActivityTrailClient, baseUrl, FeaturesClient, LanguageClient, PasswordStrengthClient, SessionClient, TasksClient } from '@modules/shared/api/api';
import {
    COMMANDS_SERVICE,
    CONTENT_SERVICES_PROVIDER,
    FEATURES_SERVICE,
    MODAL_SERVICE,
    SIDE_CONTEXT_SERVICE,
    USER_SETTINGS_SERVICE,
} from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { GetBaseUriForCodeGen } from '@utilities/base-uri-getter';
import { WINDOW } from '@utilities';
import { PrivilegeService } from '@modules/shared/privilege/privilege.service';
import { AppComponent } from './app.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoginComponent } from './components/login/login.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { TaskPluginComponent } from './components/nav-bar/task-plugin/task-plugin.component';
import { OptionsComponent } from './components/options/options.component';
import { SaveTaskComponent } from './components/save-task/save-task.component';
import { AppSidePaneComponent } from './components/side-pane/side-pane.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { AuthInterceptor } from './security-center/services/authentication/auth-interceptor.service';
// import & load globals
import '@utilities/extensions';

const baseHrefFactory = (_basePath: string, _window: Window) => {
    const basePathOnly = _basePath.substring(_window.location.origin.length);
    const currentBaseHref = _window.location.pathname.substring(0, basePathOnly.length);
    return currentBaseHref;
};

@NgModule({
    declarations: [AppComponent, NavBarComponent, AppSidePaneComponent, TaskPluginComponent, LoginComponent, OptionsComponent, LoadingScreenComponent, SaveTaskComponent],
    imports: [
        NgxsModule.forRoot([SideContextDataState, UserSettingsState, FeatureFlagsState], {
            developmentMode: !environment.production,
            compatibility: {
                strictContentSecurityPolicy: true,
            },
        }),
        NgxsEmitPluginModule.forRoot(),
        NgxsSelectSnapshotModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule.forRoot(),
        MapsModule,
        TilesModule,
        AlarmsModule,
        VideoModule,
        AccessControlModule,
        CorrelationModule,
        MissionControlModule,
        GeneralModule,
        ClearanceModule,
        GenAngularModule.forRoot(),
        GenLanguageModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
        }),
        LprModule,
    ],
    providers: [
        FeaturesClient, // Auto-generated service
        TasksClient,
        SessionClient,
        PasswordStrengthClient,
        LanguageClient,
        ActivityTrailClient,
        { provide: baseUrl, deps: [], useFactory: GetBaseUriForCodeGen },
        { provide: LOCALE_ID, useValue: 'en' },
        { provide: CONTENT_SERVICES_PROVIDER, useClass: ContentServicesProviderService },
        { provide: USER_SETTINGS_SERVICE, useClass: UserSettingsService },
        { provide: COMMANDS_SERVICE, useClass: InternalCommandsService },
        { provide: SIDE_CONTEXT_SERVICE, useClass: SideContextDataService },
        { provide: MODAL_SERVICE, useClass: GenModalService },
        { provide: FEATURES_SERVICE, useClass: FeaturesService },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: WINDOW, useValue: window },
        { provide: APP_BASE_HREF, useFactory: baseHrefFactory, deps: [baseUrl, WINDOW] },
        PrivilegeService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        // Load map and set plugin in immer
        enableMapSet();
    }
}
