import { IEntityCacheTask } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CommandContext } from '../../interfaces/plugins/public/plugin-services-public.interface';

export interface EntityCommandContext extends CommandContext {
    entityCache?: IEntityCacheTask;
}

export const isEntityCommandContext = (instance: unknown): instance is EntityCommandContext => {
    return isOfType<EntityCommandContext>(instance, 'entityCache');
};
