// Autogenerated: v2/help/Classes/ApiCardholderIdentityValidationEvent?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:01:51 AM
import { TimezoneEvent } from './TimezoneEvent';
import { IGuid, SafeGuid } from 'safeguid';
import { ICardholderIdentityValidationEvent } from '../Interface/ICardholderIdentityValidationEvent';
import { ObservableCollection } from '../../Helpers/ObservableCollection';
import { CardholderEvent } from './CardholderEvent';

export class CardholderIdentityValidationEvent extends CardholderEvent implements ICardholderIdentityValidationEvent {
    // #region REST Properties

    // AccessPoint
    public get accessPoint(): IGuid {
        return this.getFieldGuid('AccessPoint');
    }
    public set accessPoint(value: IGuid) {
        this.setFieldGuid('AccessPoint', value);
    }

    // Area
    public get area(): IGuid {
        return this.getFieldGuid('Area');
    }
    public set area(value: IGuid) {
        this.setFieldGuid('Area', value);
    }

    // Door
    public get door(): IGuid {
        return this.getFieldGuid('Door');
    }
    public set door(value: IGuid) {
        this.setFieldGuid('Door', value);
    }

    // DoorSide
    public get doorSide(): IGuid {
        return this.getFieldGuid('DoorSide');
    }
    public set doorSide(value: IGuid) {
        this.setFieldGuid('DoorSide', value);
    }

    // Unit
    public get unit(): IGuid {
        return this.getFieldGuid('Unit');
    }
    public set unit(value: IGuid) {
        this.setFieldGuid('Unit', value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

    // #region Methods

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('AccessPoint', SafeGuid.EMPTY);
        this.setFieldGuid('Area', SafeGuid.EMPTY);
        this.setFieldGuid('Door', SafeGuid.EMPTY);
        this.setFieldGuid('DoorSide', SafeGuid.EMPTY);
        this.setFieldGuid('Unit', SafeGuid.EMPTY);
    }

    // #endregion Methods

}

