/**
 * @beta
 * */
export class Resolution {
  public static readonly None: Resolution = new Resolution(0, 0);

  public readonly Width: number;
  public readonly Height: number;

  public get IsNone(): boolean {
    return this.equals(Resolution.None);
  }

  private constructor(width: number, height: number) {
    this.Width = width;
    this.Height = height;
  }

  public static build(width: number, height: number): Resolution {
    if (width <= 0 || height <= 0) {
      return Resolution.None;
    }

    return new Resolution(width, height);
  }

  public isGreaterOrEqualThan(compared: Resolution): boolean {
    return this.Width >= compared.Width && this.Height >= compared.Height;
  }

  public equals(compared: Resolution): boolean {
    return this.Width === compared.Width && this.Height === compared.Height;
  }

  public toString(): string {
    return `${this.Width.toFixed(0)}x${this.Height.toFixed(0)}`;
  }

  public fitInto(destination: Resolution): Resolution {
    const scale = this.scaleToFitInto(destination);
    return Resolution.build(this.Width * scale, this.Height * scale);
  }

  public scaleToFitInto(destination: Resolution): number {
    const scaleW = destination.Width / this.Width;
    const scaleH = destination.Height / this.Height;

    return (scaleW > scaleH) ? scaleH : scaleW;
  }
}
