import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { InternalContentPluginDescriptor } from '@modules/shared/interfaces/plugins/internal/plugin-internal.interface';
import { Content, ContentPluginComponent, PluginComponentExposure, PluginComponentRequirements } from '@modules/shared/interfaces/plugins/public/plugin-public.interface';
import { ContentService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { PluginTypes } from '@modules/shared/interfaces/plugins/public/plugin-types';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownLicenses } from 'WebClient/KnownLicenses';
import { VideoContentTypes } from '../../../enumerations/video-content-types';
import { PtzPrivileges } from '../services/ptz-privileges';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

const isContentSupported = (content: Content, pluginType: IGuid) => {
    if (content) {
        if (content.type.equals(VideoContentTypes.Video)) {
            // do not want to display video in compact mode when having not only the video, this will happen when expanding the popup instead
            if (pluginType.equals(PluginTypes.MapPopupCompact) && content.contextContent) {
                return false;
            }

            // Make sure our content has at least one ptz related privilege set to true.
            return new PtzPrivileges(content).hasAtLeastOnePtzPrivilege();
        }
    }
    return false;
};

@InternalContentPluginDescriptor({
    type: PtzControlsPluginComponent,
    pluginTypes: [PluginTypes.Widget],
    exposure: { id: PtzControlsPluginComponent.pluginId, priority: 2.1, supportsCarousel: true } as PluginComponentExposure,
    isContentSupported,
    requirements: { features: [KnownFeatures.videoId], licenses: [KnownLicenses.video] },
})
@Component({
    selector: 'app-ptz-controls-plugin',
    templateUrl: './ptz-controls-plugin.component.html',
    styleUrls: ['./ptz-controls-plugin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PtzControlsPluginComponent extends TrackedComponent implements ContentPluginComponent {
    // PUBLIC members
    public static pluginId = SafeGuid.parse('0526E7C5-7713-493C-B88D-D711FC5679F0');
    public dataContext?: ContentService;
    public content?: Content;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    public setContent(content: Content): void {
        this.content = content;
    }

    public setDataContext(context: unknown): void {
        this.dataContext = context as ContentService;
    }
}
