<ng-container *ngIf="isInitialized; else spinner">
    <ng-container *ngIf="isContentVisible; else insufficientPrivileges">
        <gen-checkbox [ngModel]="!isMiniMapDisabled" (ngModelChange)="onShowMiniMapChanged($event)">
            <gen-label>
                {{ 'STE_CHECKBOX_ENABLE_MINIMAPS' | translate }}
                <gen-text [flavor]="TextFlavor.Secondary"> ({{ 'STE_LABEL_OPTIONS_SHOW_MINIMAPS_ONLY_ON_IMAGE_MAPS' | translate }})</gen-text>
            </gen-label>
        </gen-checkbox>

        <gen-checkbox [(ngModel)]="isShowingOfflineMobileUsers" (ngModelChange)="onShowingOfflineMobileUsersChanged($event)">
            <gen-label>{{ 'STE_CHECKBOX_SHOW_OFFLINE_MOBILE_USERS' | translate }}</gen-label>
        </gen-checkbox>

        <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_MESSAGE_INFO_MAP_EVENTS_SELECTION_LOCATION' | translate }}</gen-text>
    </ng-container>
</ng-container>

<ng-template #insufficientPrivileges>
    <app-insufficient-privileges></app-insufficient-privileges>
</ng-template>

<ng-template #spinner>
    <div class="ha-center">
        <gen-spinner></gen-spinner>
    </div>
</ng-template>
