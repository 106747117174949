import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Icon, IconSize, TextFlavor, ImageFit, ImageFlavor, TableColumnHeaderSort, TableFlavor } from '@genetec/gelato';
import { GenTable, PopupPosition } from '@genetec/gelato-angular';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { isEqual } from 'lodash';
import { TypedSimpleChanges } from '@modules/shared/utilities/typed-simple-changes';
import { Datum } from '@modules/shared/interfaces/datum';
import { IGuid } from 'safeguid';
import { KnownCellRendererTypes } from './enumerations/known-cell-renderer-types';
import { TableCellType } from './enumerations/table-cell-type';
import { TableColumnDescriptor } from './models/table-column-descriptor';
import { SortOptions } from './models/sort-options';

@UntilDestroy()
@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends TrackedComponent implements AfterViewInit, OnChanges {
    @Input() public columnDescriptors: TableColumnDescriptor[] | null = null;
    @Input() public data: Datum[] | null = null;
    @Input() public refreshing = false;
    @Input() hasMore = true;
    @Input() selectedId: IGuid | null = null;
    @Output() selectedChanged = new EventEmitter<Datum>();
    @Output() sortChanged: EventEmitter<SortOptions> = new EventEmitter<SortOptions>();
    @Output() loadMore = new EventEmitter();
    @ViewChild(GenTable) public table!: GenTable;
    public readonly TableCellType = TableCellType;
    public readonly Icon = Icon;
    public readonly IconSize = IconSize;
    public readonly ImageFit = ImageFit;
    public readonly ImageFlavor = ImageFlavor;
    public readonly PopupPosition = PopupPosition;
    public readonly TextFlavor = TextFlavor;
    public readonly TableColumnHeaderSort = TableColumnHeaderSort;
    public readonly TableFlavor = TableFlavor;
    public readonly KnownCellRendererTypes = KnownCellRendererTypes;

    public readonly isLoadingMore$!: Observable<boolean>;

    private readonly isLoadingMoreSubject = new BehaviorSubject<boolean>(false);

    constructor(trackingService: TrackingService) {
        super(trackingService);
        this.isLoadingMore$ = this.isLoadingMoreSubject.asObservable();
    }

    public ngAfterViewInit() {
        this.onDescriptorChanged();
    }

    ngOnChanges(changes: TypedSimpleChanges<TableComponent>): void {
        if (changes.columnDescriptors && !isEqual(changes.columnDescriptors.currentValue, changes.columnDescriptors.previousValue)) {
            this.onDescriptorChanged();
        }
        if (changes.data && !isEqual(changes.data.currentValue, changes.data.previousValue)) {
            this.isLoadingMoreSubject.next(false);
        }
        if (changes.selectedId && !isEqual(changes.selectedId.currentValue, changes.selectedId.previousValue)) {
            if (!changes.selectedId.currentValue) {
                this.deselectAll();
            }
        }
    }

    onLoadMore(): void {
        if (!this.isLoadingMoreSubject.getValue()) {
            this.isLoadingMoreSubject.next(true);
            this.loadMore.emit();
        }
    }

    public onSelectionChange(row: Datum): void {
        if (!this.selectedId?.equals(row.guid as unknown as IGuid)) {
            this.selectedChanged.emit(row);
        }
    }

    public deselectAll(): void {
        this.table?.deselectAll().fireAndForget();
        this.selectedId = null;
    }

    public showColumnSelection(): void {
        this.table.showEditColumnsModal().fireAndForget();
    }

    public onSortChange(columnHeaderSort: CustomEvent<TableColumnHeaderSort>, column: TableColumnDescriptor): void {
        let sortOption: SortOptions | undefined;
        if (columnHeaderSort.detail !== TableColumnHeaderSort.None) {
            sortOption = {
                columnKey: column.fieldId,
                sortOrder: columnHeaderSort.detail,
            };
        }
        this.sortChanged.emit(sortOption);
    }

    public columnOrderChanged(event: CustomEvent<string[]>): void {
        if (!this.columnDescriptors) {
            return;
        }
        this.columnDescriptors = this.columnDescriptors.sort((col1, col2) => {
            const col1Index = event.detail.indexOf(col1.fieldId);
            const col2Index = event.detail.indexOf(col2.fieldId);
            return col1Index - col2Index;
        });
    }

    private onDescriptorChanged(): void {
        if (!this.columnDescriptors) return;
        const newTableColumns = this.columnDescriptors.filter((col) => !col.isKey);
        this.columnDescriptors = newTableColumns.sort((col1, col2) => {
            // -1 is the default value for priority, so we want to move it to the end of the list
            if (col1.priority !== -1 && col2.priority === -1) {
                return -1;
            }
            return col1.priority - col2.priority;
        });
    }
}
