import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

export const AccessControlCommands = Constants({
    ShuntReader: SafeGuid.parse('BA6D0905-9E72-41A2-B86E-F98855C3E4F4'),
    ShuntReaderDevice: SafeGuid.parse('7E30E47F-B05F-4108-B71D-4C80CF2C037E'),
    UnlockDoor: SafeGuid.parse('9D153743-16E6-432A-B45A-4FA078C18E32'),
    OverrideUnlockSchedule: SafeGuid.parse('26871F1E-4525-458C-9112-0E3B1B5AF48F'),
    ForgiveAntiPassback: SafeGuid.parse('514B164E-D998-4650-B13C-49F0BAFAD922'),
    ActivateDeactivateCardholder: SafeGuid.parse('5F573758-13C7-4B05-B12F-70D7BFAB05D6'),
    ActivateDeactivateCredential: SafeGuid.parse('E7F858B3-75B1-4B19-AD7C-A05764DA4640'),
    ReportLostCredential: SafeGuid.parse('B3356646-7829-4753-91BC-14101CC9A03B'),
    ReportStolenCredential: SafeGuid.parse('80971DBE-50CB-4845-BEC6-F5E57268FDD9'),
    ReportLostCardholderCredential: SafeGuid.parse('5D9AE020-FE2D-4E55-A0B1-D33C89125BCE'),
    ReportStolenCardholderCredential: SafeGuid.parse('57507E74-251F-4605-99AE-15C8BF05E503'),
    ExitFullscreen: SafeGuid.parse('040AC660-8104-4561-8AB5-2B4CAC4DAE5F'),
});
