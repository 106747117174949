import { TextOverlayPosition } from './VideoWatermarkingConfig';

export class PositionFinder {
  private readonly m_position: TextOverlayPosition;

  private readonly m_blockWidth: number;

  private readonly m_blockHeight: number;

  constructor(position: TextOverlayPosition, blockWidth: number, blockHeight: number) {
    this.m_position = position;
    this.m_blockWidth = blockWidth;
    this.m_blockHeight = blockHeight;
  }

  public findPosition(width: number, height: number): [number, number] {
    switch (this.m_position) {
      case TextOverlayPosition.TopLeft:
        return [0, 0];
      case TextOverlayPosition.Top:
        return [width / 2 - this.m_blockWidth / 2, 0];
      case TextOverlayPosition.TopRight:
        return [width - this.m_blockWidth, 0];
      case TextOverlayPosition.CenterLeft:
        return [0, height / 2 - this.m_blockHeight / 2];
      case TextOverlayPosition.Center:
        return [width / 2 - this.m_blockWidth / 2, (height / 2 - this.m_blockHeight / 2)];
      case TextOverlayPosition.CenterRight:
        return [(width - this.m_blockWidth), (height / 2 - this.m_blockHeight / 2)];
      case TextOverlayPosition.BottomLeft:
        return [0, (height - this.m_blockHeight)];
      case TextOverlayPosition.Bottom:
        return [(width / 2 - this.m_blockWidth / 2), (height - this.m_blockHeight)];
      case TextOverlayPosition.BottomRight:
        return [(width - this.m_blockWidth), (height - this.m_blockHeight)];
      default:
        return [0, 0];
    }
  }
}
