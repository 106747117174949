// Autogenerated: v2/help/Entities/ApiFederationBaseRole?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:26 AM
import { RoleEntity } from './RoleEntity';
import { IGuid } from 'safeguid';
import { IFederationBaseRoleEntity, FederationBaseRoleEntityFields } from '../../Interface/IFederationBaseRoleEntity';

export class FederationBaseRoleEntity extends RoleEntity implements IFederationBaseRoleEntity {
    // #region REST Properties

    // DefaultParentArea
    public get defaultParentArea(): IGuid {
        return this.getFieldGuid(FederationBaseRoleEntityFields.defaultParentAreaField);
    }
    public set defaultParentArea(value: IGuid) {
        this.setFieldGuid(FederationBaseRoleEntityFields.defaultParentAreaField, value);
    }

    // EnablePlayback
    public get enablePlayback(): boolean {
        return this.getField<boolean>(FederationBaseRoleEntityFields.enablePlaybackField);
    }
    public set enablePlayback(value: boolean) {
        this.setField<boolean>(FederationBaseRoleEntityFields.enablePlaybackField, value);
    }

    // IP
    public get iP(): string {
        return this.getField<string>(FederationBaseRoleEntityFields.iPField);
    }
    public set iP(value: string) {
        this.setField<string>(FederationBaseRoleEntityFields.iPField, value);
    }

    // Password
    public get password(): string {
        return this.getField<string>(FederationBaseRoleEntityFields.passwordField);
    }
    public set password(value: string) {
        this.setField<string>(FederationBaseRoleEntityFields.passwordField, value);
    }

    // Port
    public get port(): number {
        return this.getField<number>(FederationBaseRoleEntityFields.portField);
    }
    public set port(value: number) {
        this.setField<number>(FederationBaseRoleEntityFields.portField, value);
    }

    // StatusDescription
    public get statusDescription(): string {
        return this.getField<string>(FederationBaseRoleEntityFields.statusDescriptionField);
    }

    // StreamingVersion
    public get streamingVersion(): number {
        return this.getField<number>(FederationBaseRoleEntityFields.streamingVersionField);
    }

    // Username
    public get username(): string {
        return this.getField<string>(FederationBaseRoleEntityFields.usernameField);
    }
    public set username(value: string) {
        this.setField<string>(FederationBaseRoleEntityFields.usernameField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

