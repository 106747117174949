// Autogenerated: v2/help/Entities/ApiInputDevice?codegen=ts&InterfaceOnly=False&workflow=False on 2021-04-15 12:15:59 PM
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IInputDeviceEntity, InputDeviceEntityFields } from '../../Interface/IInputDeviceEntity';
import { DeviceEntity } from './DeviceEntity';

export class InputDeviceEntity extends DeviceEntity implements IInputDeviceEntity {
    // #region REST Properties

    // InputState
    public get inputState(): string {
        return this.getField<string>(InputDeviceEntityFields.inputStateField, ValidFlags.EntityStatus);
    }
    public set inputState(value: string) {
        this.setField<string>(InputDeviceEntityFields.inputStateField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super();
    }

    // #endregion Constructor

}

