import { Component, Input } from '@angular/core';
import { ButtonFlavor } from '@genetec/gelato';
import { CommandUsage } from '../../services/commands/commands-usage/command-usage';
import { TrackingService } from '../../services/tracking.service';
import { TrackedComponent } from '../tracked/tracked.component';

@Component({
    selector: 'app-command-button',
    templateUrl: './command-button.component.html',
    styleUrls: ['./command-button.component.scss'],
})
export class CommandButtonComponent extends TrackedComponent {
    @Input() public command!: CommandUsage;

    public readonly ButtonFlavor = ButtonFlavor;

    constructor(trackingService: TrackingService) {
        super(trackingService);
    }

    public getName(command: CommandUsage): string | undefined {
        // if there is no icon, use the text
        if (!command.icon) {
            return command.name();
        }
    }

    public getTooltip(command: CommandUsage): string {
        let tooltip = command.tooltip();
        if (!tooltip) {
            // if there is no tooltip and that we only have an icon, use the name as tooltip
            if (command.icon) {
                tooltip = command.name();
            }
        }
        return tooltip;
    }

    public onButtonClicked(event: Event): void {
        event.cancelBubble = true;
        event.preventDefault();
    }
}
