<gen-text-input
    [attr.disabled]="disabled"
    [readonly]="readonly"
    [required]="required"
    [multiline]="multiline < 2 ? undefined : multiline"
    [maxlength]="maxlength ?? null"
    [autofocus]="autofocus"
    [placeholder]="placeholder"
    [(ngModel)]="comment"
    (ngModelChange)="onChange($event)"
    (blur)="onTouch()"
    [appGelatoInvalidityCompatControl]="ngControl"
>
    <gen-label>{{ label !== undefined ? label : ('STE_LABEL_INCIDENT_COMMENT' | translate) }}</gen-label>
    <gen-message [style.visibility]="!ngControl.valid && ngControl.dirty ? 'visible' : 'hidden'" [severity]="Severity.Error">
        <ng-container *ngIf="ngControl.errors">
            <ng-container *ngIf="customError; else errors">{{ customError }}</ng-container>

            <ng-template #errors>
                <ng-container *ngIf="ngControl.errors.required">
                    {{ 'STE_MESSAGE_ERROR_FIELDISREQUIRED' | translate }}
                </ng-container>

                <ng-container *ngIf="ngControl.errors.invalidFirstChar">
                    {{ 'STE_MESSAGE_ERROR_FORMAT_COMMENT_CANNOT_START_WITH_X_CHARACTERS' | translate | stringFormat: ngControl.errors.invalidFirstChar }}
                </ng-container>

                <ng-container *ngIf="!ngControl.errors.invalidFirstChar && ngControl.errors.invalidChars">
                    {{ 'STE_MESSAGE_ERROR_FORMAT_COMMENT_CANNOT_CONTAIN_XYZ_CHARACTERS' | translate | stringFormat: ngControl.errors.invalidChars }}
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container></ng-container>
    </gen-message>
</gen-text-input>
