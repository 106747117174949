import { ILogger } from '../../utils/logger';
import { BufferQueue } from './BufferQueue';
import { MseSourceBuffer, SourceBufferEvent } from './MseSourceBuffer';
import { SegmentInfo } from '../../services/eventHub';
import { Utils } from '../../utils/Utils';
import { FrameRateComputer } from '../../utils/FrameRateComputer';
import { PromiseCompletionSourceVoid } from '../../utils/PromiseCompletionSource';
import { DebugSwitch } from '../../utils/Config';

export class MseMediaSource {
  private static readonly VideoSourceBufferType = 'video/mp4; codecs="avc1.42E01E"';

  private static readonly AudioSourceBufferType = 'audio/mp4;codecs="mp4a.40.2"';

  private static readonly VideoWebMSourceBufferType = 'video/webm; codecs="opus, vp09.00.10.08"';

  private readonly m_logger: ILogger;

  private readonly m_mediaSource: MediaSource;

  private readonly m_queue: BufferQueue;

  private readonly m_decoderOutputFps = new FrameRateComputer();

  private readonly m_mediaSpecification: string;

  private readonly m_mediaSourceInitialization: Promise<void>;

  private m_nextCleanupTime: number = 0;

  private m_resetInProgress: boolean = false;

  private m_mseSourceBuffer?: MseSourceBuffer;

  private m_isPaused: boolean = false;

  private m_frameRenderedPromise: PromiseCompletionSourceVoid;

  public get sourceBuffer(): MseSourceBuffer {
    if (this.m_mseSourceBuffer === undefined) {
      throw new Error('Wait for initialization to complete');
    }
    return this.m_mseSourceBuffer;
  }

  public get initialization(): Promise<void> {
    return this.m_mediaSourceInitialization;
  }

  public get readyState(): ReadyState {
    return this.m_mediaSource.readyState;
  }

  public get FrameRendered(): Promise<void> {
    return this.m_frameRenderedPromise.Promise;
  }

  constructor(logger: ILogger, mediaSource: MediaSource, mediaType: MediaType, bufferQueue: BufferQueue) {
    this.m_logger = logger.subLogger('MseMediaSource: ' + mediaType);
    switch (mediaType) {
      case MediaType.video:
        this.m_mediaSpecification = MseMediaSource.VideoSourceBufferType;
        break;
      case MediaType.audio:
        this.m_mediaSpecification = MseMediaSource.AudioSourceBufferType;
        break;
      case MediaType.video_webm:
        this.m_mediaSpecification = MseMediaSource.VideoWebMSourceBufferType;
        break;
      default:
        throw new Error(`Unknown media type: ${mediaType}`);
    }

    this.m_mediaSource = mediaSource;
    this.m_mediaSource.addEventListener(MediaSourceEvent.sourceClose, this.onSourceClosed);

    this.m_mediaSourceInitialization = this.open();
    this.m_queue = bufferQueue;

    this.m_frameRenderedPromise = new PromiseCompletionSourceVoid();
  }

  public dispose(): void {
    this.m_logger.debug?.trace('dispose()');

    if (this.m_mseSourceBuffer !== undefined) {
      this.m_mseSourceBuffer.removeEventListener(SourceBufferEvent.updateEnd, this.onUpdateEnded);
      this.m_mseSourceBuffer.dispose();
    }

    this.m_queue.dispose();
    this.m_mediaSource.removeEventListener(MediaSourceEvent.sourceClose, this.onSourceClosed);
  }

  public async reset() {
    this.m_resetInProgress = true;

    try {
      this.m_logger.debug?.trace('reset()');

      //important to clear the queue before the first await. Otherwise, we will clear the new stuff that is queued while we do the reset.
      this.m_queue.reset();

      if (this.m_mseSourceBuffer !== undefined) {
        await this.m_mseSourceBuffer.reset();
        await this.m_mseSourceBuffer.updatingEnd();
        if (this.m_mseSourceBuffer.isUpdating) {
          this.m_logger.warn?.trace('unexpected MseSourceBuffer.isUpdating flag is on');
        }
      }

      // clearLiveSeekableRange Not supported in IE11
      // To be removed may be because there is no setLiveSeekableRange
      if (MediaSource.prototype.clearLiveSeekableRange !== undefined) {
        this.m_mediaSource.clearLiveSeekableRange();
      }
      this.m_mediaSource.duration = 0;
    } finally {
      this.m_logger.debug?.trace('reset completed()');
      this.m_resetInProgress = false;

      //Try to dequeue in case buffers where
      if (!this.m_queue.isEmpty) {
        this.m_logger.debug?.trace('Dequeuing after reset()');
        this.onUpdateEnded();
      }
    }
  }

  public decode(segmentInfo: SegmentInfo, buffer: Int8Array) {
    if (this.sourceBuffer.isUpdating || !this.m_queue.isEmpty || this.m_resetInProgress || this.m_isPaused) {
      this.m_queue.enqueue(segmentInfo, buffer);
      return;
    }

    this.sourceBuffer.decode(segmentInfo, buffer);
  }

  public pause() {
    this.m_isPaused = true;
  }

  public play() {
    this.m_isPaused = false;
    this.onUpdateEnded();
  }

  private async open(): Promise<void> {
    await MseMediaSourceExtensions.openEventPromise(this.m_mediaSource);
    this.m_mediaSource.duration = Infinity;
    this.m_mseSourceBuffer = new MseSourceBuffer(this.m_logger, this.m_mediaSource.addSourceBuffer(this.m_mediaSpecification), this.m_mediaSource);
    this.m_mseSourceBuffer.addEventListener(SourceBufferEvent.updateEnd, this.onUpdateEnded);
    this.m_logger.debug?.trace('MediaSource:', this.m_mediaSource.readyState, 'with', this.m_mediaSpecification);
  }

  private readonly onUpdateEnded = () => {
    const toResolve = this.m_frameRenderedPromise;
    this.m_frameRenderedPromise = new PromiseCompletionSourceVoid();
    toResolve.resolve();

    this.m_decoderOutputFps.addFrame('p');

    // It often happen that when this event is fired, the sourceBuffer is still in updating state. It will fire again.
    if (this.sourceBuffer.isUpdating || this.m_resetInProgress || this.m_isPaused || this.m_queue.isEmpty) {
      this.m_logger.intense?.trace('onUpdateEnded no dequeuing, IsUpdating:', this.sourceBuffer.isUpdating, 'IsEmpty:', this.m_queue.isEmpty, 'ResetInProgress:', this.m_resetInProgress, 'IsPaused:', this.m_isPaused);
      return;
    }

    // Periodically do the cache cleanup to avoid storing 10 minutes of video in RAM and add a bit of randomness to help spread this operation in time accross tiles.
    if (this.m_nextCleanupTime < Date.now()) {
      this.m_nextCleanupTime = Date.now() + ((DebugSwitch.MseCleanupFreqSeconds + Math.random()) * 1000);
      this.sourceBuffer.emptyCache();
      if (this.sourceBuffer.isUpdating) {
        return; // emptyCache may set "isUpdating" flag to true, in that case we can't append a buffer right now and need to retry later.
      }
    }

    const [segmentInfo, buffer] = this.m_queue.dequeue();
    this.m_logger.intense?.trace('onUpdateEnded will appendBuffer', segmentInfo.MediaTime, segmentInfo.FrameTime, buffer.length, 'bytes');

    try {
      this.sourceBuffer.decode(segmentInfo, buffer);
    } catch (e) {
      this.m_logger.error?.trace('Failed to append buffer to SourceBuffer. ReadyState:', this.m_mediaSource.readyState, e);
    }
  }

  private readonly onSourceClosed = (ev: Event) => {
    this.m_logger.error?.trace('Media source was closed', ev);
  }

  public debugStatus(indent: number): string {
    return `MseMediaSource ${this.m_mediaSpecification} ${this.m_decoderOutputFps.getFrameRate().frameRateToString()}` + Utils.indentNewLine(indent) +
      'Paused: ' + this.m_isPaused + Utils.indentNewLine(indent) +
      'Reset in progress: ' + this.m_resetInProgress + Utils.indentNewLine(indent) +
      'Queue length: ' + this.m_queue.count + Utils.indentNewLine(indent) +
      (this.m_mseSourceBuffer?.debugStatus(indent + Utils.Indentation) ?? 'No mse source buffer');
  }
}

export class MseMediaSourceExtensions {
  public static async openEventPromise(mediaSource: MediaSource): Promise<void> {
    return MseMediaSourceExtensions.awaitEvent(mediaSource, 'sourceopen');
  }

  private static async awaitEvent(mediaSource: MediaSource, eventName: string): Promise<void> {
    return new Promise<void>((resolve) => {
      mediaSource.addEventListener(eventName, () => resolve(), { once: true });
    });
  }
}

export enum MediaSourceReadyState {
  closed = 'closed',
  ended = 'ended',
  open = 'open'
}

enum MediaSourceEvent {
  sourceClose = 'sourceclose',
  sourceEnded = 'sourceended',
  sourceOpen = 'sourceopen'
}

export enum MediaType {
  video = 'video',
  audio = 'audio',
  video_webm = 'video-webm'
}
