import { Injectable } from '@angular/core';
import { Icon } from '@genetec/gelato';
import { IconsService } from '../services/icons.service';
import { AreaBrowserItemModel } from '../../general/entity-browser/items/area-browser-item-model';
import { EntityBrowserItemModel } from './Items/entity-browser-item-model';

@Injectable({ providedIn: 'root' })
export class EntityBrowserItemService {
    constructor(public iconsService: IconsService) {}

    public getItemIcon(model: EntityBrowserItemModel): Icon {
        if (model instanceof AreaBrowserItemModel) {
            if (!model.map.isEmpty()) {
                return Icon.FoldedMap;
            }
            if (model.isFederatedDirectory) {
                return Icon.SecurityCenterFederation;
            }
        }
        return this.iconsService.getEntityTypeIcon(model.entityType);
    }
}
