import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { Gelato, MeltedIcon } from '@genetec/gelato-angular';
import { coerceBooleanProperty } from '@modules/shared/utilities/coerceBooleanProperty';
import { EnumAsString } from '@src/app/utilities/types';
import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@Component({
    selector: 'app-generic-slider',
    templateUrl: './generic-slider.component.html',
    styleUrls: ['./generic-slider.component.scss'],
    providers: [],
})
export class GenericSliderComponent extends Gelato implements OnInit {
    @Input()
    public name: string;

    @Input()
    public valueEnumerationType: EnumAsString = MeltedIcon;

    @Input()
    public get sliderValue(): string {
        return this.tickLabels[this.value];
    }

    public set sliderValue(newValue: string) {
        const oldValue = this.value;

        // if needed, initialize the tick labels
        if (this.tickLabels.length === 0) {
            this.tickLabels = Object.values(this.valueEnumerationType);
        }

        this.value = this.tickLabels.findIndex((item) => item === newValue);
        if (this.value >= 0 && this.value !== oldValue) {
            this.updateValuePercentage();
            this.sliderValueChanged.emit(newValue);
        } else {
            // revert the change
            this.value = oldValue;
        }
    }

    @Output()
    public sliderValueChanged = new EventEmitter<string>();

    /**
     * Whether a tooltip with the current value is displayed when hovering the knob.
     */
    @Input()
    public get genIsTooltipVisible(): boolean {
        return this.isTooltipVisible;
    }
    public set genIsTooltipVisible(value: boolean) {
        this.isTooltipVisible = coerceBooleanProperty(value);
    }

    public readonly TextFlavor = TextFlavor;

    public get tooltipPosition(): string {
        const val = this.value;
        const min = 0;
        const max = this.tickLabels.length - 1;
        const newVal = Number(((val - min) * 100) / (max - min));

        return `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    }

    public value = 0;

    // Fill Background
    public readonly normalFillColor = 'rgba(240, 240, 240, 0.3)'; // Background color of the fill in normal state
    public readonly hoverFillColor = 'rgb(0, 174, 239)'; // Background color of the fill when hovering
    public fillColor: string = this.normalFillColor; // Current fill background color
    public valuePercentage = 0; // Percentage used for the fill background

    public tickLabels: string[] = [];

    private isTooltipVisible = false;
    private readonly filterId: string = SafeGuid.newGuid().toString();

    constructor() {
        super();
        this.name = this.filterId;
    }

    public ngOnInit() {
        // initialize the slider payload information
        this.tickLabels = Object.values(this.valueEnumerationType);
    }
    public onPeriodSliderValueChanged(idx: number): void {
        if (idx && idx >= 0 && idx < this.tickLabels.length) {
            const currentTick = this.tickLabels[idx];
            this.sliderValue = currentTick;
        }
    }

    public onMouseEnter(): void {
        this.fillColor = this.hoverFillColor;
    }

    public onMouseLeave(): void {
        this.fillColor = this.normalFillColor;
    }

    private updateValuePercentage() {
        this.valuePercentage = (this.value * 100) / Math.max(this.tickLabels.length - 1, 1);
    }
}
