import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TextFlavor } from '@genetec/gelato';
import { Icon } from '@genetec/gelato';
import { ButtonFlavor, ComboboxFlavor, ComboboxPosition, GenMeltedItem } from '@genetec/gelato-angular';
import { IncidentFilter } from '@modules/mission-control/models/incident-filter';
import { Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import moment from 'moment/moment';
import { tap } from 'rxjs/operators';
import { List } from 'immutable';
import { IncidentSelectionService } from '@modules/mission-control/services/incident/incident-selection.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-linked-incident-filter',
    templateUrl: './linked-incident-filter.component.html',
    styleUrls: ['./linked-incident-filter.component.scss'],
})
export class LinkedIncidentFilterComponent implements OnInit, OnChanges {
    @Input()
    public hiddenFilter = false;

    @Output()
    public incidentFilterChanged = new EventEmitter<IncidentFilter>();

    public readonly TextFlavor = TextFlavor;
    public readonly ButtonFlavor = ButtonFlavor;
    public readonly ComboboxPosition = ComboboxPosition;
    public readonly ComboboxFlavor = ComboboxFlavor;
    public readonly Icon = Icon;
    public timeFrameList: GenMeltedItem[] = [];
    public sameIncidentType = false;
    public sameLocation = false;
    public selectedTimeRange: GenMeltedItem | null = null;
    public anyTime: GenMeltedItem = { id: '0', text: this._translateService.instant('STE_LABEL_ANY_TIME') };

    private _sameIncidentType$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _sameLocation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _isFilterVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _dateTimeFrom$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private _lastHour: GenMeltedItem = { id: '1', text: this._translateService.instant('STE_LABEL_LAST_HOUR') };
    private _lastDay: GenMeltedItem = { id: '2', text: this._translateService.instant('STE_LABEL_LAST_DAY') };
    private _lastWeek: GenMeltedItem = { id: '3', text: this._translateService.instant('STE_LABEL_LAST_WEEK') };
    private _lastMonth: GenMeltedItem = { id: '4', text: this._translateService.instant('STE_LABEL_LAST_MONTH') };

    constructor(private _translateService: TranslateService, private _incidentSelectionService: IncidentSelectionService) {}

    public ngOnInit() {
        this.timeFrameList = [this.anyTime, this._lastHour, this._lastDay, this._lastWeek, this._lastMonth];

        combineLatest([this._incidentSelectionService.selectedIncident$, this._sameIncidentType$, this._sameLocation$, this._dateTimeFrom$, this._isFilterVisible$])
            .pipe(
                tap(([selectedIncident, sameIncidentType, sameLocation, dateTimeFrom, isFilterVisible]) => {
                    let incidentFilter = IncidentFilter.empty;

                    if (!!selectedIncident && isFilterVisible) {
                        if (sameIncidentType) incidentFilter = incidentFilter.with({ incidentTypeIds: List([selectedIncident.typeId]) });
                        if (sameLocation && selectedIncident.location?.entityId) incidentFilter = incidentFilter.with({ locationIds: List([selectedIncident.location.entityId]) });
                        if (dateTimeFrom) incidentFilter = incidentFilter.with({ dateTimeFrom });
                    }

                    this.incidentFilterChanged.emit(incidentFilter);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('hiddenFilter')) {
            this._isFilterVisible$.next(!changes.hiddenFilter.currentValue);
        }
    }

    public onSameIncidentTypeChanged = (event: CustomEvent) => this._sameIncidentType$.next(event.detail);

    public onSameLocationChanged = (event: CustomEvent) => this._sameLocation$.next(event.detail);

    public onTimeFrameChanged(item: GenMeltedItem) {
        const now = moment().utc();
        switch (item.id) {
            case '0':
                this._dateTimeFrom$.next(null);
                break;
            case '1':
                this._dateTimeFrom$.next(now.subtract(1, 'hours').toISOString());
                break;
            case '2':
                this._dateTimeFrom$.next(now.subtract(1, 'days').toISOString());
                break;
            case '3':
                this._dateTimeFrom$.next(now.subtract(1, 'weeks').toISOString());
                break;
            case '4':
                this._dateTimeFrom$.next(now.subtract(1, 'months').toISOString());
                break;
        }
    }
}
