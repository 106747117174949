<div>
    <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_OVERVIEW' | translate }}</gen-text>
    <br />
    <gen-grid class="mt-2" [genColumns]="2">
        <gen-grid-item>
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_ENTITY_ALARM' | translate }}</gen-text> <gen-text [flavor]="TextFlavor.Normal">{{ sourceName }}</gen-text>

            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_ABBREV_IDENTIFIER' | translate }}</gen-text> <gen-text [flavor]="TextFlavor.Normal">{{ instanceId }}</gen-text>
        </gen-grid-item>
        <gen-grid-item>
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_PRIORITY' | translate }}</gen-text> <gen-text [flavor]="TextFlavor.Normal">{{ priority }}</gen-text>

            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_STATE' | translate }}</gen-text> <gen-text [flavor]="TextFlavor.Normal">{{ alarmStateString }}</gen-text>
        </gen-grid-item>
        <gen-grid-item>
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_ALARM_INVESTIGATED_BY' | translate }}</gen-text>
            <gen-text [flavor]="TextFlavor.Normal">{{ investigatedBy }}</gen-text>
        </gen-grid-item>
        <gen-grid-item>
            <gen-text [flavor]="TextFlavor.Label">{{ 'STE_LABEL_ALARM_INVESTIGATED_ON' | translate }}</gen-text>
            <gen-text [flavor]="TextFlavor.Normal">{{ investigatedTime }}</gen-text>
        </gen-grid-item>
    </gen-grid>

    <!-- TODO YN: Show more ! -->
    <!-- <gen-button class="show-more" [flavor]="ButtonFlavor.Flat" >{{ 'STE_BUTTON_SHOWMORE' | translate }}</gen-button>-->
</div>
