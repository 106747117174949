import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class Commands {
    public static readonly ingestData: IGuid = SafeGuid.parse('98cc3a1b-b19c-46d1-adcf-9f8eed5ea2db');
}
