// Autogenerated: v2/help/Entities/ApiTransferGroup?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 9:30:27 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { Entity } from '../Entity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid } from 'safeguid';
import { IEntity } from '../../Interface/IEntity';
import { ITransferGroupEntity, TransferGroupEntityFields } from '../../Interface/ITransferGroupEntity';
import { IQueryFilter } from '../../Interface/IQueryFilter';
import { ITransaction } from '../../Interface/ITransaction';

export class TransferGroupEntity extends Entity implements ITransferGroupEntity {
    // #region REST Properties

    // Destination
    public get destination(): IGuid {
        return this.getFieldGuid(TransferGroupEntityFields.destinationField);
    }

    // MinutesToIgnore
    public get minutesToIgnore(): number {
        return this.getField<number>(TransferGroupEntityFields.minutesToIgnoreField);
    }

    // SimultaneousDownload
    public get simultaneousDownload(): number {
        return this.getField<number>(TransferGroupEntityFields.simultaneousDownloadField);
    }

    // TransferGroupType
    public get transferGroupType(): string {
        return this.getField<string>(TransferGroupEntityFields.transferGroupTypeField);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.TransferGroups);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getCamerasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Cameras', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getCamerasAndArchiversAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('CamerasAndArchivers', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getSourceAreasAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('SourceAreas', 'Id', true, query, ValidFlags.None, transaction);
    }

    public async getSourcesAsync(query?: IQueryFilter|null, transaction?: ITransaction|null): Promise<ObservableCollection<IEntity>|null> {
        return this.getGuidRelationAsync('Sources', 'Id', true, query, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

