import { IGuid, SafeGuid } from 'safeguid';
import { Constants } from '../../shared/utilities/constants';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const ZoneContentTypes = Constants({
    Zone: SafeGuid.parse('5AD7B3F2-FA5C-4089-A239-C93F99AC002E'),
    Event: SafeGuid.parse('CAEE2C45-3B93-456E-8FEF-7D7E33FFBEB7'),
});
