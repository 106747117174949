<div (appResize)="onResize()">
    <div #tooltip class="tooltip" *ngIf="showTooltip" [style.left.px]="tooltipX" (mouseenter)="onTooltipMouseEnter()" (mouseleave)="onTooltipMouseLeave()">
        {{ cursorTime }}
        <ul *ngIf="tooltips.length > 0" style="pointer-events: all">
            <li *ngFor="let tooltip of tooltips" (click)="tooltip.click()">
                <div class="tooltip-grid">
                    <gen-text class="tooltip-title">{{ tooltip.title }}</gen-text>
                    <gen-text class="tooltip-description" [flavor]="TextFlavor.Secondary">{{ tooltip.description }}</gen-text>
                    <img class="tooltip-picture" *ngIf="tooltip.hasPicture" src="data:image/png;base64,{{ tooltip.picture }}" />
                </div>
            </li>
        </ul>
        <div (click)="onThumbnailClick(currentThumbnail)">
            <img class="tooltip-thumbnail" *ngIf="currentThumbnail" src="data:image/png;base64,{{ currentThumbnail.image }}" />
        </div>
        <div class="arrow" [style.left]="tooltipArrowOffset"></div>
    </div>
    <div
        #maindiv
        class="maindiv"
        [style.cursor]="isPanning ? 'col-resize' : 'default'"
        [appMouseCapture]="captureMouse"
        (contextmenu)="onContextMenu($event)"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        (pointerdown)="onPointerDown($event)"
        (pointerup)="onPointerUp($event)"
        (pointermove)="onPointerMove($event)"
        (mouseup)="onMouseUp($event)"
    >
        <canvas #ticks class="tickscanvas"></canvas>
        <canvas #canvas class="drawingcanvas"></canvas>
        <div *ngIf="isLoopEnabled" class="loop">
            <i #loopstart class="loopstart" *ngIf="loopThumbs.left" [style.left.px]="loopThumbs.left"></i>
            <i #loopend class="loopend" *ngIf="loopThumbs.right" [style.left.px]="loopThumbs.right"></i>
        </div>
        <i #thumb class="cursor" (pointerdown)="onCursorMouseDown($event)" [style.left.px]="currentX"></i>
    </div>
</div>
