import { IGuid } from 'safeguid';

export class IncidentLocation {
    constructor(public entityId: IGuid | null, public latitude: number | null, public longitude: number | null) {
    }

    public isSet = (): boolean => this.isGeoCoordinated() || this.entityId !== null;

    public isGeoCoordinated = (): boolean => this.latitude != null && this.longitude != null;
}
