import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TextFlavor, Icon } from '@genetec/gelato';
import { GenMeltedListItem, ImageFit, ImageFlavor, MeltedIcon, SpinnerSize } from '@genetec/gelato-angular';
import { CorrelationService } from '@modules/correlation/services/correlation.service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeGuid } from 'safeguid';
import { Subscription } from 'rxjs';
import { DataTypeDisplayModel } from '@modules/correlation/api/api';
import { sortAscending } from '@modules/shared/utilities/sort.helper';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { UnifiedReportFeatureFlags } from '@modules/correlation/feature-flags';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { InvestigateFilterBaseComponent } from '../investigate-filter-base.component';
import { WhatFilter } from '../../services/investigate-data.interfaces';
import { InvestigateDataContext } from '../../services/investigate-data.context';

// ==========================================================================
// Copyright (C) 2021 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Component({
    selector: 'app-investigate-what-filter',
    templateUrl: './what-filter.component.html',
    styleUrls: ['./what-filter.component.scss'],
    providers: [],
})
export class InvestigateWhatFilterComponent extends InvestigateFilterBaseComponent<WhatFilter> implements AfterViewInit {
    public static AnythingDataTypeId = SafeGuid.parse('7E8D6D16-9B9A-4B92-93CD-CCF4E11726E0');
    @Input() public tilesView = false;
    @Output() itemSelected: EventEmitter<void> = new EventEmitter<void>();

    public readonly TextFlavor = TextFlavor;
    public readonly ImageFlavor = ImageFlavor;
    public readonly ImageFit = ImageFit;
    public readonly Icon = Icon;
    public readonly SpinnerSize = SpinnerSize;

    public visibleDataTypes: Array<GenMeltedListItem> = [];
    public dataTypesListItems: Array<GenMeltedListItem> = [];
    public dataTypeDisplayModel: DataTypeDisplayModel[] = [];
    public searchText = '';
    public isLoading = false;
    public hasMultiSelectFeatureFlag = false;
    public isValid = false;

    constructor(
        trackingService: TrackingService,
        private correlationService: CorrelationService,
        protected sanitizer: DomSanitizer,
        private translateService: TranslateService,
        private dataContext: InvestigateDataContext,
        private advancedSettingsService: AdvancedSettingsService
    ) {
        super(trackingService);

        this.hasMultiSelectFeatureFlag = UnifiedReportFeatureFlags.MultiSelectWhatFilterFeatureFlag.isEnabled(this.advancedSettingsService);
    }

    public onDataTypeChanged(item?: GenMeltedListItem): void {
        if (item) {
            if (!this.hasMultiSelectFeatureFlag) {
                // Limit to single selection only
                if (item.isChecked) {
                    this.uncheckAllItemsExcept(item);
                } else {
                    // do not allow to uncheck everything
                    item.isChecked = true;
                    return;
                }
            }
            const selectedItems = this.dataTypesListItems.filter((dt) => dt.isChecked);
            this.onDataTypesChanged(selectedItems);
            this.filterToggle.emit(false);
        }
    }

    public onReportSelectedFromCard(item: DataTypeDisplayModel): void {
        const selectedItems = this.dataTypesListItems.filter((dt) => dt.id === item.dataType.toString());
        this.onDataTypesChanged(selectedItems);
        this.itemSelected.emit();

        this.tilesView = false;
    }

    public onDataTypesChanged(items: Array<GenMeltedListItem>): void {
        this.setSelectionStatus(items);

        const selectedItemsIds = items.map((x) => x.id);

        this.value.description = this.filterStatus;
        this.value.dataTypes = selectedItemsIds.map(SafeGuid.parse);
        // if Anything is selected, add all types (except anything) to the dataTypes
        const anythingFound = this.value.dataTypes.find((x) => x.toString() === InvestigateWhatFilterComponent.AnythingDataTypeId.toString());
        if (anythingFound) {
            this.value.dataTypes = this.dataTypesListItems
                .filter((dt) => dt.id !== InvestigateWhatFilterComponent.AnythingDataTypeId.toString())
                .map(({ id }) => SafeGuid.parse(id));
        }

        this.onValueChanged();
    }

    public onSearchChange(newSearchText: string): void {
        this.searchText = newSearchText;
        if (this.searchText) {
            this.visibleDataTypes = this.dataTypesListItems.filter((item) => item.text.toLowerCase().includes(this.searchText.toLowerCase()));
        } else {
            this.visibleDataTypes = this.dataTypesListItems;
        }
    }

    async ngAfterViewInit() {
        await this.loadDataTypes();
    }

    protected setupSubscription(): Subscription | undefined {
        if (!this.dataContext) return undefined;

        return this.dataContext.whatFilter$.pipe(untilDestroyed(this)).subscribe((newValue) => {
            if (newValue) {
                // special case so we don't just cycle setting value, getting value changed, setting value, etc. This will NOT trigger the value changed evt
                this.silentSetValue(newValue);
                this.setSelectedItems();
            } else {
                // someone set undefined, re-set it to just an empty filter
                this.value = { dataTypes: [], parameters: [], description: this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string };
            }
        });
    }

    protected onValueChanged(): void {
        this.setSelectedItems();
        if (this.dataContext) {
            // copy the other stuff manually, since cloneDeep has limited support for classes
            const guids = this.value.dataTypes.map((item) => item.clone());
            const newValue: WhatFilter = { dataTypes: guids, parameters: [], description: this.value.description };

            this.dataContext.setWhatFilter(newValue);
        }
        super.onValueChanged();
    }

    private uncheckAllItemsExcept(item: GenMeltedListItem) {
        this.dataTypesListItems.forEach((element) => {
            if (element !== item) {
                element.isChecked = false;
            }
        });
    }

    private checkAllItems() {
        this.dataTypesListItems.forEach((element) => {
            element.isChecked = true;
        });
    }

    private setAnythingDataType(): void {
        const anythingDataType = this.dataTypesListItems.find((item) => item.id === InvestigateWhatFilterComponent.AnythingDataTypeId.toString());
        if (anythingDataType) {
            anythingDataType.isChecked = true;
        } else {
            // if anything does not exist, check all items
            this.checkAllItems();
        }
    }

    private setSelectedItems() {
        if (this.dataTypesListItems) {
            // wipe all the check's
            this.dataTypesListItems.forEach((dt) => (dt.isChecked = false));
            // reset the checks
            if (this.value?.dataTypes) {
                if (this.value?.dataTypes.length === this.dataTypesListItems.length - 1) {
                    // check only Anything
                    this.setAnythingDataType();
                } else {
                    this.value.dataTypes.forEach((item) => {
                        const dataTypeItem = this.dataTypesListItems.find((item2) => item.equals(item2.id));
                        if (dataTypeItem) {
                            dataTypeItem.isChecked = true;
                        }
                    });
                }
            }
        }

        this.setSelectionStatus(this.dataTypesListItems.filter((item) => item.isChecked));
        this.value.description = this.filterStatus;
    }

    private async loadDataTypes() {
        this.isLoading = true;
        const dataTypeResults: GenMeltedListItem[] = [];
        if (this.correlationService) {
            const dataTypes = await this.correlationService.getDataTypes();
            if (dataTypes) {
                if (dataTypes.length > 1) {
                    // Add Anything as the last item only when we have 2 or more data types
                    this.dataTypeDisplayModel = [...dataTypes.sort(sortAscending('name')), this.createAnythingDataType()];
                } else {
                    this.dataTypeDisplayModel = dataTypes.sort(sortAscending('name'));
                }

                for (const dt of this.dataTypeDisplayModel) {
                    const item: GenMeltedListItem = { text: dt.name, id: dt.dataType.toString() };
                    if (dt.customIcon) {
                        item.image = dt.customIcon;
                    } else if (dt.icon) {
                        item.icon = (dt.icon as MeltedIcon) ?? MeltedIcon.CorrelationService;
                    } else {
                        item.icon = MeltedIcon.CorrelationService;
                    }
                    dataTypeResults.push(item);
                }
            }
        }
        this.dataTypesListItems = dataTypeResults;
        this.visibleDataTypes = this.dataTypesListItems;
        // if there is no value set already, initialize the value
        if (!this.value) {
            this.value = { dataTypes: [], parameters: [], description: this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string };
        } else {
            // there's a previous value, reload the selected items
            this.setSelectedItems();
            this.onValueChanged();
        }
        this.isLoading = false;
    }

    private createAnythingDataType(): DataTypeDisplayModel {
        const anythingModel = new DataTypeDisplayModel();
        anythingModel.name = this.translateService.instant('STE_LABEL_FILTER_ANY_DATA_TYPE') as string;
        anythingModel.icon = MeltedIcon.CheckAll;
        anythingModel.dataType = InvestigateWhatFilterComponent.AnythingDataTypeId;

        return anythingModel;
    }

    private setSelectionStatus(selectedItems?: GenMeltedListItem[] | null) {
        if (!selectedItems?.length) {
            this.isValid = false;
            this.filterStatus = this.translateService.instant('STE_LABEL_EMPTY') as string;
        } else if (selectedItems.length === 1) {
            this.isValid = true;
            this.filterStatus = selectedItems[0].text;
        } else {
            this.isValid = true;
            this.filterStatus = this.translateService.instant('STE_LABEL_FORMAT_N_ITEMSSELECTED', { count: selectedItems.length }) as string;
        }
    }
}
