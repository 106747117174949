import { Radian } from './Radian';

export class Degree {
  private readonly m_angle: number;

  public get InDegree(): number {
    return this.m_angle;
  }

  constructor(angle: number) {
    this.m_angle = angle;
  }

  public toString(): string {
    return `${this.m_angle.toFixed(3)}°`;
  }

  public toRadian(): Radian {
    return new Radian(this.m_angle * Math.PI / 180);
  }
}
