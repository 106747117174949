// Autogenerated: v2/help/enumerations/ApiStreamType?codegen=ts&showresources=False on 2020-07-23 3:40:07 PM

export class StreamType {
    public static Unknown = 'Unknown';
    public static Video = 'Video';
    public static Audio = 'Audio';
    public static Serial = 'Serial';
    public static MetaData = 'MetaData';
    public static PrivacyProtection = 'PrivacyProtection';

    public static readonly values = [
        StreamType.Unknown,
        StreamType.Video,
        StreamType.Audio,
        StreamType.Serial,
        StreamType.MetaData,
        StreamType.PrivacyProtection
    ];
}
