import { SafeGuid } from 'safeguid';
import { Constants } from '../../utilities/constants';

// ==========================================================================
// Copyright (C) 2019 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export const SharedEntityBrowserRefreshTypes = Constants({
    FlatView: SafeGuid.parse('2F8E96C0-378B-4447-ADE7-4B0E94D6EF48'),
    LogicalView: SafeGuid.parse('8EC99F70-039F-4C2F-8952-9F55A0D44072'),
    FlatViewNoFederatedEntities: SafeGuid.parse('FECCBFC1-F39B-4220-8B58-36ACB0401C13'),
});
