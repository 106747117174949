// Autogenerated: v2/help/Entities/ApiStream?codegen=ts&InterfaceOnly=False&workflow=False on 2021-08-30 10:02:04 AM
import { EntityTypes } from '../../Enumerations/EntityTypes';
import { Entity } from '../Entity';
import { ObservableCollection } from '../../../Helpers/ObservableCollection';
import { FieldObject } from '../../../Helpers/FieldObject';
import { ValidFlags } from '../../Enumerations/ValidFlags';
import { IGuid, SafeGuid } from 'safeguid';
import { IStreamEntity, ICompression, StreamEntityFields } from '../../Interface/IStreamEntity';
import { ITransaction } from '../../Interface/ITransaction';

// #region Inner classes

export class Compression extends FieldObject implements ICompression {
    // Id
    public get id(): IGuid {
        return this.getFieldGuid('Id');
    }
    public set id(value: IGuid) {
        this.setFieldGuid('Id', value);
    }
    // VideoCompressionAlgorithm
    public get videoCompressionAlgorithm(): string {
        return this.getField<string>('VideoCompressionAlgorithm');
    }
    public set videoCompressionAlgorithm(value: string) {
        this.setField<string>('VideoCompressionAlgorithm', value);
    }
    // FrameRate
    public get frameRate(): number | null {
        return this.getNullableField<number>('FrameRate');
    }
    public set frameRate(value: number | null) {
        this.setNullableField<number>('FrameRate', value);
    }
    // BitRate
    public get bitRate(): number | null {
        return this.getNullableField<number>('BitRate');
    }
    public set bitRate(value: number | null) {
        this.setNullableField<number>('BitRate', value);
    }
    // ImageQuality
    public get imageQuality(): number | null {
        return this.getNullableField<number>('ImageQuality');
    }
    public set imageQuality(value: number | null) {
        this.setNullableField<number>('ImageQuality', value);
    }
    // KeyframeInterval
    public get keyframeInterval(): number | null {
        return this.getNullableField<number>('KeyframeInterval');
    }
    public set keyframeInterval(value: number | null) {
        this.setNullableField<number>('KeyframeInterval', value);
    }
    // ResolutionX
    public get resolutionX(): number | null {
        return this.getNullableField<number>('ResolutionX');
    }
    public set resolutionX(value: number | null) {
        this.setNullableField<number>('ResolutionX', value);
    }
    // ResolutionY
    public get resolutionY(): number | null {
        return this.getNullableField<number>('ResolutionY');
    }
    public set resolutionY(value: number | null) {
        this.setNullableField<number>('ResolutionY', value);
    }
    // RecordingFrameInterval
    public get recordingFrameInterval(): string | null {
        return this.getNullableField<string>('RecordingFrameInterval');
    }
    public set recordingFrameInterval(value: string | null) {
        this.setNullableField<string>('RecordingFrameInterval', value);
    }

    public initializeAllFields(): void {
        super.initializeAllFields();
        this.setFieldGuid('Id', SafeGuid.EMPTY);
        this.setField<string>('VideoCompressionAlgorithm', '');
        this.setField<number>('FrameRate', 0);
        this.setField<number>('BitRate', 0);
        this.setField<number>('ImageQuality', 0);
        this.setField<number>('KeyframeInterval', 0);
        this.setField<number>('ResolutionX', 0);
        this.setField<number>('ResolutionY', 0);
        this.setField<string>('RecordingFrameInterval', '');
    }
}

// #endregion Inner classes

export class StreamEntity extends Entity implements IStreamEntity {
    // #region REST Properties

    // BlockSize
    public get blockSize(): number {
        return this.getField<number>(StreamEntityFields.blockSizeField);
    }
    public set blockSize(value: number) {
        this.setField<number>(StreamEntityFields.blockSizeField, value);
    }

    // Camera
    public get camera(): IGuid {
        return this.getFieldGuid(StreamEntityFields.cameraField);
    }

    // ConfiguredConnection
    public get configuredConnection(): string {
        return this.getField<string>(StreamEntityFields.configuredConnectionField);
    }
    public set configuredConnection(value: string) {
        this.setField<string>(StreamEntityFields.configuredConnectionField, value);
    }

    // Device
    public get device(): IGuid {
        return this.getFieldGuid(StreamEntityFields.deviceField);
    }
    public set device(value: IGuid) {
        this.setFieldGuid(StreamEntityFields.deviceField, value);
    }

    // IsPresent
    public get isPresent(): boolean {
        return this.getField<boolean>(StreamEntityFields.isPresentField);
    }
    public set isPresent(value: boolean) {
        this.setField<boolean>(StreamEntityFields.isPresentField, value);
    }

    // LegacyConfigurations
    public get legacyConfigurations(): string {
        return this.getField<string>(StreamEntityFields.legacyConfigurationsField);
    }
    public set legacyConfigurations(value: string) {
        this.setField<string>(StreamEntityFields.legacyConfigurationsField, value);
    }

    // MulticastAddress
    public get multicastAddress(): string {
        return this.getField<string>(StreamEntityFields.multicastAddressField);
    }
    public set multicastAddress(value: string) {
        this.setField<string>(StreamEntityFields.multicastAddressField, value);
    }

    // MulticastPort
    public get multicastPort(): number {
        return this.getField<number>(StreamEntityFields.multicastPortField);
    }
    public set multicastPort(value: number) {
        this.setField<number>(StreamEntityFields.multicastPortField, value);
    }

    // Number
    public get numberValue(): number {
        return this.getField<number>(StreamEntityFields.numberField);
    }
    public set numberValue(value: number) {
        this.setField<number>(StreamEntityFields.numberField, value);
    }

    // StreamType
    public get streamType(): string {
        return this.getField<string>(StreamEntityFields.streamTypeField);
    }
    public set streamType(value: string) {
        this.setField<string>(StreamEntityFields.streamTypeField, value);
    }

    // SupportedBlockSize
    public get supportedBlockSize(): string {
        return this.getField<string>(StreamEntityFields.supportedBlockSizeField);
    }
    public set supportedBlockSize(value: string) {
        this.setField<string>(StreamEntityFields.supportedBlockSizeField, value);
    }

    // SupportedConnection
    public get supportedConnection(): string {
        return this.getField<string>(StreamEntityFields.supportedConnectionField);
    }
    public set supportedConnection(value: string) {
        this.setField<string>(StreamEntityFields.supportedConnectionField, value);
    }

    // UDPPort
    public get uDPPort(): number {
        return this.getField<number>(StreamEntityFields.uDPPortField);
    }
    public set uDPPort(value: number) {
        this.setField<number>(StreamEntityFields.uDPPortField, value);
    }

    // #endregion REST Properties

    // #region Constructor

    constructor() {
        super(EntityTypes.Streams);
    }

    // #endregion Constructor

    // #region REST Methods

    public async getCompressionsAsync(transaction?: ITransaction | null): Promise<ObservableCollection<ICompression> | null> {
        return this.getFieldObjectRelationAsync<Compression, ICompression>(Compression, 'Compressions', 'Id', true, true, ValidFlags.None, transaction);
    }

    // #endregion REST Methods

}

