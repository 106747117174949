import { Inject, Injectable, Version } from '@angular/core';
import { GenModalService, MeltedIcon } from '@genetec/gelato-angular';
import { ActivityTrailClient, ForgiveAntipassbackViolation } from '@modules/shared/api/api';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AdvancedSettingsService } from '@modules/shared/services/advanced-settings/advanced-settings.service';
import { FeaturesService } from '@modules/shared/services/features/features.service';
import { TranslateService } from '@ngx-translate/core';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { EntityTypes } from 'RestClient/Client/Enumerations/EntityTypes';
import HttpStatusCode from 'RestClient/Client/Enumerations/HttpStatusCode';
import { RunningState } from 'RestClient/Client/Enumerations/RunningState';
import { ICardholderEntity } from 'RestClient/Client/Interface/ICardholderEntity';
import { DoorEntityFields, IDoorEntity } from 'RestClient/Client/Interface/IDoorEntity';
import { EntityFields } from 'RestClient/Client/Interface/IEntity';
import { modificationHandlerField } from 'RestClient/Client/Interface/IEntityCacheTask';
import { CardholderEntity } from 'RestClient/Client/Model/AccessControl/CardholderEntity';
import { DoorEntity } from 'RestClient/Client/Model/AccessControl/DoorEntity';
import { Deferred } from 'RestClient/Helpers/Helpers';
import { take } from 'rxjs/operators';
import { IGuid, SafeGuid } from 'safeguid';
import { KnownFeatures } from 'WebClient/KnownFeatures';
import { KnownPrivileges } from 'WebClient/KnownPrivileges';
import { DoorSideAccessPointEntity } from 'RestClient/Client/Model/AccessControl/DoorSideAccessPointEntity';
import { DoorSideAccessPointEntityFields, IDoorSideAccessPointEntity } from 'RestClient/Client/Interface/IDoorSideAccessPointEntity';
import { FieldObject } from 'RestClient/Helpers/FieldObject';
import { FullscreenService } from '@modules/shared/services/fullscreen/fullscreen.service';
import {
    CommandBindings,
    CommandContext,
    CommandDisplay,
    CommandsService,
    COMMANDS_SERVICE,
    EntityCommandRequirements,
    FEATURES_SERVICE,
} from '../../shared/interfaces/plugins/public/plugin-services-public.interface';
import { CommandArgs, CommandDescriptor } from '../../shared/services/commands/command-provider-base';
import { EntityCommandsUsageContext } from '../../shared/services/commands/commands-usage/entity-commands-usage-context';
import { EntityCommandContext } from '../../shared/services/commands/entity-command-context';
import { EntityCommandProviderBase, ExecuteEntityCommandData } from '../../shared/services/commands/entity-command-provider-base';
import { LanguageService } from '../../shared/services/language/language.service';
import { OverrideScheduleModalComponent } from '../components/door/override-schedule-modal/override-schedule-modal.component';
import { ShuntReaderModalComponent } from '../components/door/shunt-reader-modal/shunt-reader-modal.component';
import { AccessControlCommands } from '../enumerations/access-control-commands';
import { AccessControlContentFields } from '../enumerations/access-control-content-fields';
import { AccessControlContentTypes } from '../enumerations/access-control-content-types';

// ==========================================================================
// Copyright (C) 2020 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
@UntilDestroy()
@Injectable()
export class DoorCommandProvider extends EntityCommandProviderBase {
    protected get commandDescriptors(): Map<IGuid, CommandDescriptor> {
        if (!this.internalCommandDescriptors) {
            const descriptors = SafeGuid.createMap<CommandDescriptor>();
            descriptors.set(
                AccessControlCommands.UnlockDoor,
                this.createDoorCommandDescriptor(
                    AccessControlCommands.UnlockDoor,
                    'STE_ACTION_UNLOCKDOOR',
                    MeltedIcon.Unlock,
                    this.fillCommandRequirements({
                        requiredPrivileges: SafeGuid.createSet([KnownPrivileges.explicitlyUnlockDoorPrivilege]),
                    }),
                    'U'
                )
            );
            descriptors.set(
                AccessControlCommands.OverrideUnlockSchedule,
                this.createDoorCommandDescriptor(
                    AccessControlCommands.OverrideUnlockSchedule,
                    'STE_BUTTON_OVERRIDE_UNLOCK_SCHEDULES',
                    MeltedIcon.Calendar,
                    this.fillCommandRequirements({ requiredPrivileges: SafeGuid.createSet([KnownPrivileges.autoUnlockOverridePrivilege, KnownPrivileges.modifyDoorsPrivilege]) })
                )
            );
            descriptors.set(
                AccessControlCommands.ShuntReader,
                this.createDoorCommandDescriptor(
                    AccessControlCommands.ShuntReader,
                    'STE_BUTTON_SHUNT_READER',
                    MeltedIcon.Reader,
                    this.fillCommandRequirements({
                        requiredPrivileges: SafeGuid.createSet([KnownPrivileges.maintenanceModePrivilege, KnownPrivileges.modifyAccessControlUnitsPrivilege]),
                    })
                )
            );
            descriptors.set(
                AccessControlCommands.ForgiveAntiPassback,
                this.createDoorCommandDescriptor(AccessControlCommands.ForgiveAntiPassback, 'STE_TOOLTIP_FORGIVE_ANTIPASSBACK', MeltedIcon.Eraser, this.fillCommandRequirements({}))
            );
            this.internalCommandDescriptors = descriptors;
        }

        return this.internalCommandDescriptors;
    }

    public get requiredEntityFields(): Set<string> {
        return new Set<string>([
            ...super.requiredEntityFields,
            DoorEntityFields.isLockedField,
            DoorEntityFields.maintenanceModeActiveField,
            EntityFields.runningStateField,
            EntityFields.isFederatedField,
        ]);
    }

    public get requiredFeatures(): Set<IGuid> {
        return SafeGuid.createSet([...super.requiredFeatures, KnownFeatures.accessControlId]);
    }

    constructor(
        @Inject(COMMANDS_SERVICE) commandsService: CommandsService,
        scClientService: SecurityCenterClientService,
        languageService: LanguageService,
        @Inject(FEATURES_SERVICE) featuresService: FeaturesService,
        translateService: TranslateService,
        private modalService: GenModalService,
        private activityTrailClient: ActivityTrailClient,
        protected advancedSettingsService: AdvancedSettingsService,
        private fullscreenService: FullscreenService
    ) {
        super(commandsService, scClientService, languageService, featuresService, translateService, advancedSettingsService);
    }

    public async getAvailableCommandIdsAsync(commandContext: EntityCommandContext): Promise<IGuid[]> {
        const commandIds: IGuid[] = [];
        const commandArgs = this.extractArgs(commandContext);

        // Prefetch privileges
        await this.prefetchPrivileges(commandContext, commandArgs);

        if (await this.isUnlockDoorAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(AccessControlCommands.UnlockDoor);
            this.configureUnlockDoorInvalidateCanExecuteAsync(commandContext as EntityCommandsUsageContext).fireAndForget();
        }
        if (await this.isOverrideUnlockScheduleAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(AccessControlCommands.OverrideUnlockSchedule);
            this.configureOverrideUnlockScheduleInvalidatesAsync(commandContext as EntityCommandsUsageContext).fireAndForget();
        }
        if (await this.isShuntReaderAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(AccessControlCommands.ShuntReader);
            this.configureShuntReaderInvalidateCanExecuteAsync(commandContext as EntityCommandsUsageContext).fireAndForget();
        }
        if (await this.isForgiveAntiPassbackAvailableAsync(commandContext, commandArgs)) {
            commandIds.push(AccessControlCommands.ForgiveAntiPassback);
        }
        return commandIds;
    }

    protected fillCommandBindings(bindings: CommandBindings): void {
        bindings.addCommand({
            commandId: AccessControlCommands.UnlockDoor,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeUnlockDoor(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isUnlockDoorAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) =>
                this.safeCanExecuteEntityCommandAsync((entityCommandContext) => this.canUnlockAsync(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AccessControlCommands.OverrideUnlockSchedule,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeOverrideUnlockSchedule(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isOverrideUnlockScheduleAvailableAsync(entityCommandContext), commandContext),
            getCommandDisplayHandler: (commandContext) =>
                this.safeGetEntityCommandDisplayAsync((entityCommandContext) => this.getOverrideUnlockScheduleDisplay(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AccessControlCommands.ShuntReader,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeShuntReaderAsync(entityExecuteCommandData).fireAndForget(), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isShuntReaderAvailableAsync(entityCommandContext), commandContext),
            canExecuteCommandHandler: (commandContext) =>
                this.safeCanExecuteEntityCommandAsync((entityCommandContext) => this.canShuntReaderAsync(entityCommandContext), commandContext),
        });
        bindings.addCommand({
            commandId: AccessControlCommands.ForgiveAntiPassback,
            executeCommandHandler: (executeCommandData) =>
                this.safeExecuteEntityCommand((entityExecuteCommandData) => this.executeForgiveAntiPassback(entityExecuteCommandData), executeCommandData),
            isCommandAvailableHandler: (commandContext) =>
                this.safeIsEntityCommandAvailableAsync((entityCommandContext) => this.isForgiveAntiPassbackAvailableAsync(entityCommandContext), commandContext),
        });
    }

    protected fillCommandRequirements(requirements: EntityCommandRequirements): EntityCommandRequirements {
        if (!requirements.supportedContentTypes) {
            requirements.supportedContentTypes = SafeGuid.createSet([AccessControlContentTypes.Door]);
        }

        if (!requirements.supportedEntityTypes) {
            requirements.supportedEntityTypes = new Set<string>([EntityTypes.Doors]);
        }
        return super.fillCommandRequirements(requirements);
    }

    private async cancelOverrideScheduleAsync(commandContext: EntityCommandContext): Promise<void> {
        const doorEntity = await this.extractDoorEntity(commandContext);
        if (doorEntity) {
            const result = await this.modalService.showMessage(
                this.translateService?.instant('STE_MESSAGE_WARNING_CANCELOVERRIDESCHEDULE') as string,
                this.translateService?.instant('STE_MESSAGE_WARNING_CAUSETEMPORARYDISRUPTIONS') as string,
                this.translateService?.instant('STE_BUTTON_CONTINUE') as string,
                this.translateService?.instant('STE_BUTTON_CANCEL') as string
            );
            if (result === 'default') {
                if (doorEntity.maintenanceModeActive) {
                    doorEntity.maintenanceModeActive = false;
                } else {
                    await doorEntity.deleteAutoUnlockOverrideAsync();
                }
                const response = await this.scClient.updateAsync(doorEntity);
                if (response.statusCode === HttpStatusCode.OK) {
                    this.setCommandCanExecute(AccessControlCommands.OverrideUnlockSchedule, commandContext, false);
                }
            }
        }
    }

    private async canShuntReaderAsync(commandContext: EntityCommandContext): Promise<boolean> {
        const doorentity = await this.extractDoorEntity(commandContext);
        if (doorentity) {
            return doorentity.runningState !== RunningState.NotRunning;
        }
        return false;
    }

    private async canUnlockAsync(commandContext: EntityCommandContext): Promise<boolean> {
        const doorentity = await this.extractDoorEntity(commandContext);
        if (doorentity) {
            return doorentity.isLocked && doorentity.runningState !== RunningState.NotRunning;
        }
        return false;
    }

    private async configureOverrideUnlockScheduleInvalidatesAsync(commandContext: EntityCommandsUsageContext): Promise<void> {
        if (commandContext.entityCache) {
            const doorEntity = await this.extractDoorEntity(commandContext);
            if (doorEntity) {
                await commandContext.entityCache.detectFieldChangeAsync(
                    doorEntity,
                    () => {
                        const _ = doorEntity.maintenanceModeActive;
                    },
                    (async (entity1: IDoorEntity, _: boolean, __: boolean) => {
                        const commandDisplay = await this.getOverrideUnlockScheduleDisplay(undefined, entity1);
                        if (commandDisplay) {
                            this.setCommandDisplay(AccessControlCommands.OverrideUnlockSchedule, commandContext, commandDisplay);
                        }
                        this.setCommandCanExecute(AccessControlCommands.OverrideUnlockSchedule, commandContext, true);
                        return new Deferred<void>(true).promise;
                    }) as modificationHandlerField<boolean>
                );
                await commandContext.entityCache.detectRelationChangeAsync(
                    doorEntity,
                    async () => {
                        const _ = await doorEntity.getAutoUnlockOverrideAsync();
                    },
                    async (newEntity: IDoorEntity, _): Promise<void> => {
                        const commandDisplay = await this.getOverrideUnlockScheduleDisplay(undefined, newEntity);
                        if (commandDisplay) {
                            this.setCommandDisplay(AccessControlCommands.OverrideUnlockSchedule, commandContext, commandDisplay);
                        }
                        this.setCommandCanExecute(AccessControlCommands.OverrideUnlockSchedule, commandContext, true);
                        return new Deferred<void>(true).promise;
                    }
                );
            }
        }
    }

    private async configureShuntReaderInvalidateCanExecuteAsync(commandContext: EntityCommandsUsageContext): Promise<void> {
        if (commandContext.entityCache && commandContext.invalidateCanExecute) {
            const doorEntity = await this.extractDoorEntity(commandContext);
            if (doorEntity) {
                await commandContext.entityCache.detectFieldChangeAsync(
                    doorEntity,
                    () => {
                        const _ = doorEntity.runningState !== RunningState.NotRunning;
                    },
                    (() => {
                        commandContext.invalidateCanExecute(AccessControlCommands.ShuntReader);
                        return new Deferred<void>(true).promise;
                    }) as modificationHandlerField<boolean>
                );
            }
        }
    }

    private async configureUnlockDoorInvalidateCanExecuteAsync(commandContext: EntityCommandsUsageContext): Promise<void> {
        if (commandContext.entityCache && commandContext.invalidateCanExecute) {
            const doorEntity = await this.extractDoorEntity(commandContext);
            if (doorEntity) {
                commandContext.entityCache
                    .detectFieldChangeAsync(
                        doorEntity,
                        () => {
                            const _ = doorEntity.isLocked;
                        },
                        (() => {
                            commandContext.invalidateCanExecute(AccessControlCommands.UnlockDoor);
                            return new Deferred<void>(true).promise;
                        }) as modificationHandlerField<boolean>
                    )
                    .fireAndForget();
            }
        }
    }

    private createDoorCommandDescriptor(
        commandId: IGuid,
        nameResourceId: string,
        icon: MeltedIcon,
        requirements?: EntityCommandRequirements,
        keyBinding?: string
    ): CommandDescriptor {
        return {
            id: commandId,
            nameResourceId,
            icon,
            groupNameResourceId: 'STE_ENTITY_DOOR',
            groupIcon: MeltedIcon.DoorClosed,
            requirements,
            keyBinding,
        };
    }

    private executeForgiveAntiPassback(executeCommandData: ExecuteEntityCommandData): void {
        this.forgiveAntiPassbackAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private executeOverrideUnlockSchedule(executeCommandData: ExecuteEntityCommandData): void {
        this.executeOverrideUnlockScheduleAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async executeOverrideUnlockScheduleAsync(commandContext: EntityCommandContext): Promise<void> {
        const doorEntity = await this.extractDoorEntity(commandContext);
        if (doorEntity) {
            if (await this.isDoorUnlockOverrideActiveAsync(doorEntity)) {
                await this.cancelOverrideScheduleAsync(commandContext);
            } else if (this.isDoorInMaintenanceMode(doorEntity)) {
                doorEntity.maintenanceModeActive = false;
                const response = await this.scClient.updateAsync(doorEntity);
                if (response.statusCode === HttpStatusCode.OK) {
                    this.setCommandCanExecute(AccessControlCommands.OverrideUnlockSchedule, commandContext, false);
                }
            } else {
                await this.overrideUnlockScheduleAsync(commandContext);
            }
        }
    }

    private async executeShuntReaderAsync(executeCommandData: ExecuteEntityCommandData): Promise<void> {
        const args = this.extractArgs(executeCommandData.commandContext);
        if (args.entityId) {
            this.fullscreenService.displayModal(ShuntReaderModalComponent, {
                entityId: args.entityId,
                doorSideAccessPointEntityWithReaders: await this.getShuntReadersAsync(executeCommandData.commandContext),
            });
        }
        executeCommandData.isHandled = true;
    }

    private executeUnlockDoor(executeCommandData: ExecuteEntityCommandData): void {
        this.unlockDoorAsync(executeCommandData.commandContext).fireAndForget();
        executeCommandData.isHandled = true;
    }

    private async extractCardholderEntity(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<ICardholderEntity | undefined> {
        const content = commandArgs?.content ?? this.extractArgs(commandContext)?.content;
        if (content?.type.equals(AccessControlContentTypes.Door)) {
            const eventContent = content.contextContent;
            if (eventContent?.type.equals(AccessControlContentTypes.Event) && eventContent.parameters && eventContent.parameters.hasField(AccessControlContentFields.Cardholder)) {
                const cardholderId = eventContent.parameters.getFieldGuid(AccessControlContentFields.Cardholder);
                const cardholder = await this.getEntityAsync<CardholderEntity, ICardholderEntity>(
                    CardholderEntity,
                    new Set<string>(),
                    undefined,
                    cardholderId,
                    commandContext.entityCache
                );
                if (cardholder) {
                    return cardholder;
                }
            }
        }
    }

    private async extractDoorEntity(commandContext: EntityCommandContext): Promise<IDoorEntity | null> {
        return this.extractEntityAsync<DoorEntity, IDoorEntity>(DoorEntity, commandContext);
    }

    private async forgiveAntiPassbackAsync(commandContext: EntityCommandContext): Promise<void> {
        const cardholderEntity = await this.extractCardholderEntity(commandContext);
        if (cardholderEntity) {
            const response = await cardholderEntity.forgiveAntipassbackAsync();

            if (response?.statusCode === 200) {
                const forgiveEntry = new ForgiveAntipassbackViolation();
                forgiveEntry.cardholderToForgive = cardholderEntity.id;
                await this.activityTrailClient.forgiveAntipassbackViolation(forgiveEntry).toPromise();
            }
        }
    }

    private async getOverrideUnlockScheduleDisplay(commandContext?: EntityCommandContext, doorEntity?: IDoorEntity): Promise<CommandDisplay | undefined> {
        let entity = doorEntity || null;
        if (!entity && commandContext) {
            entity = await this.extractDoorEntity(commandContext);
        }

        if (!entity) {
            return undefined;
        }

        if ((await this.isDoorUnlockOverrideActiveAsync(entity)) || this.isDoorInMaintenanceMode(entity)) {
            return { name: () => this.translateService?.instant('STE_ACTION_REMOVEUNLOCKSCHEDULEOVERRIDE') as string, icon: MeltedIcon.Denied };
        } else {
            return { name: () => this.translateService?.instant('STE_BUTTON_OVERRIDE_UNLOCK_SCHEDULES') as string, icon: MeltedIcon.Calendar };
        }
    }

    private isDoorInMaintenanceMode(doorEntity: IDoorEntity): boolean {
        return doorEntity?.maintenanceModeActive ?? false;
    }

    private async isDoorUnlockOverrideActiveAsync(doorEntity: IDoorEntity): Promise<boolean> {
        if (doorEntity) {
            const autoUnlockOverride = await doorEntity.getAutoUnlockOverrideAsync();
            if (autoUnlockOverride?.hasField('isUnlocking')) {
                return true;
            }
        }
        return false;
    }

    private async isForgiveAntiPassbackAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        const areCommandRequirementsMet = await this.areCommandRequirementsMetAsync(AccessControlCommands.ForgiveAntiPassback, commandContext, commandArgs);
        if (!areCommandRequirementsMet) {
            return false;
        }

        const door = await this.extractDoorEntity(commandContext);
        if (door === null || door.isFederated) {
            return false;
        }

        const cardholderEntity = await this.extractCardholderEntity(commandContext);
        if (cardholderEntity) {
            return await this.arePrivilegesGrantedAsync(SafeGuid.createSet([KnownPrivileges.forgiveAntipassbackViolationPrivilege]), undefined, cardholderEntity);
        }
        return false;
    }

    private async isOverrideUnlockScheduleAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        const areCommandRequirementsMet = await this.areCommandRequirementsMetAsync(AccessControlCommands.OverrideUnlockSchedule, commandContext, commandArgs);
        if (!areCommandRequirementsMet) {
            return false;
        }

        const door = await this.extractDoorEntity(commandContext);
        if (door === null || this.scClient.securityCenterVersion === null) {
            return false;
        }

        // Override unlock schedule and maintenance mode for federated door not supported before 5.11.1
        const scVersion = new Version(this.scClient.securityCenterVersion.securityCenterVersion);
        if (door.isFederated && scVersion.full < OverrideScheduleModalComponent.MinimumSecurityCenterVersionForFederatedDoors.full) {
            return false;
        }

        return true;
    }

    private async isShuntReaderAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        const areCommaandRequirementsMet = await this.areCommandRequirementsMetAsync(AccessControlCommands.ShuntReader, commandContext, commandArgs);
        if (!areCommaandRequirementsMet) {
            return false;
        }
        return this.isShuntReaderDefinedAsync(commandContext);
    }

    private async isUnlockDoorAvailableAsync(commandContext: EntityCommandContext, commandArgs?: CommandArgs): Promise<boolean> {
        return await this.areCommandRequirementsMetAsync(AccessControlCommands.UnlockDoor, commandContext, commandArgs);
    }

    private async overrideUnlockScheduleAsync(commandContext: CommandContext): Promise<void> {
        const args = this.extractArgs(commandContext);

        let grantedPrivileges: Set<IGuid> | undefined;
        const content = args.content;
        if (content) {
            grantedPrivileges = SafeGuid.createSet(content.grantedPrivileges);
        }
        const canUnlockForMaintenance = await this.arePrivilegesGrantedAsync(
            SafeGuid.createSet([KnownPrivileges.acknowledgeAlarmsPrivilege]),
            grantedPrivileges,
            args.entity,
            args.entityId
        );

        const overrideScheduleModal = this.fullscreenService.displayModal(OverrideScheduleModalComponent, { entityId: args.entityId, canUnlockForMaintenance });

        overrideScheduleModal.result$.pipe(take(1), untilDestroyed(this)).subscribe((result) => {
            if (result) {
                this.setCommandCanExecute(AccessControlCommands.OverrideUnlockSchedule, commandContext, false);
            }
        });
    }

    private async unlockDoorAsync(commandContext: EntityCommandContext): Promise<void> {
        const door = await this.extractDoorEntity(commandContext);
        if (door) {
            await door.unlockAsync();
        }
    }

    private async getShuntReadersAsync(commandContext: EntityCommandContext): Promise<IDoorSideAccessPointEntity[]> {
        const door = await this.extractDoorEntity(commandContext);
        const doorSideAccessPointEntityWithReaders: IDoorSideAccessPointEntity[] = [];

        if (door) {
            const doorSideAccessPointEntities = await door.getDoorSidesAsync();

            doorSideAccessPointEntities?.forEach((entity) => {
                if (entity && !entity.getFieldGuid(DoorSideAccessPointEntityFields.deviceField).isEmpty()) {
                    doorSideAccessPointEntityWithReaders.push(FieldObject.buildFrom(DoorSideAccessPointEntity, entity));
                }
            });
        }
        return doorSideAccessPointEntityWithReaders;
    }

    private async isShuntReaderDefinedAsync(commandContext: EntityCommandContext): Promise<boolean> {
        const shuntReader = await this.getShuntReadersAsync(commandContext);

        return shuntReader.length > 0;
    }

    //#endregion
}
