<div [class.w-full]="isSideBySide">
    <div id="cardholder-pane" class="details-pane" [class.details-pane--side-by-side]="isSideBySide">
        <ng-container *ngIf="cardholder$ | async as cardholder; else noCardholderModel">
            <form *ngIf="form" [formGroup]="form" [class.w-full]="isSideBySide" (ngSubmit)="onSubmit()" class="h-full">
                <!-- Header -->
                <div class="details-pane__header-container" [class.details-pane__header-container--shadow]="!isSideBySide">
                    <div class="details-pane__header">
                        <div class="details-pane__header-title-container">
                            <!-- add mode -->
                            <ng-container *ngIf="isAddMode; else isEditMode">
                                <gen-text class="details-pane__header-title" [flavor]="TextFlavor.Normal"> {{ 'STE_TITLE_NEW_CARDHOLDER' | translate }} </gen-text>
                            </ng-container>
                            <!-- edit mode -->
                            <ng-template #isEditMode>
                                <gen-banner *ngIf="cardholder.readonly" class="w-100" [canDismiss]="true">
                                    <gen-text [flavor]="TextFlavor.Normal">{{ 'STE_MESSAGE_INFO_FEDERATED_EXTERNAL_READONLY' | translate }}</gen-text>
                                </gen-banner>
                                <gen-text class="details-pane__header-title" [flavor]="TextFlavor.Normal">
                                    <span appTooltip="{{ cardholder.firstName }} {{ cardholder.lastName }}" [appTooltipOnlyIfOverflow]="true">
                                        {{ cardholder.firstName }} {{ cardholder.lastName }}
                                    </span>
                                </gen-text>
                                <gen-text [flavor]="TextFlavor.Secondary">{{ 'STE_LABEL_CARDHOLDER_LASTSEEN' | translate }}:&nbsp;</gen-text>
                                <gen-text id="lastAccess" [flavor]="TextFlavor.Normal" [appTooltip]="lastAccessString">{{ smartLastAccessString }}</gen-text>
                            </ng-template>
                        </div>
                        <div class="details-pane__header-buttons-container" *ngIf="!cardholder.readonly">
                            <!-- More menu Ellipsis button-->
                            <!-- ngIf on the gen-button: refactor code if there's not just Delete item in the menu -->
                            <gen-button
                                id="btn-toggle-cardholder-more"
                                *ngIf="!isAddMode && moreMenuItemSource && moreMenuItemSource.length > 0"
                                (click)="toggleMoreMenu()"
                                [flavor]="ButtonFlavor.Flat"
                                appTooltip="{{ 'STE_BUTTON_CARDHOLDER_SHOWMORE' | translate }}"
                                [genAutomaticNavigation]="true"
                            >
                                <gen-icon [icon]="meltedIcon.EllipsisVertical"></gen-icon>
                            </gen-button>
                            <gen-menu #moreMenu targetSelector="#btn-toggle-cardholder-more" [appMenuDataSource]="moreMenuItemSource"></gen-menu>
                            <gen-button [flavor]="ButtonFlavor.Outline" (click)="onCancelClick()">
                                {{ 'STE_BUTTON_CANCEL' | translate }}
                            </gen-button>
                            <gen-button data-test="submit" [type]="ButtonType.Button" [flavor]="ButtonFlavor.Solid" [disabled]="!form.dirty" (click)="onSubmit()">
                                {{ (isAddMode ? 'STE_BUTTON_ADD' : 'STE_BUTTON_SAVE') | translate }}
                            </gen-button>
                        </div>
                    </div>
                </div>
                <!--Body-->
                <div class="details-pane__body">
                    <div class="details-pane__form">
                        <app-cardholder-identity-form [readonly]="!!cardholder.readonly" [formGroup]="form.controls.identityFormGroup"></app-cardholder-identity-form>
                        <app-cardholder-status-form [readonly]="!!cardholder.readonly" [formGroup]="form.controls.statusFormGroup"></app-cardholder-status-form>
                        <app-cardholder-access-rights-form
                            [readonly]="!!cardholder.readonly"
                            [formGroup]="form.controls.accessRightsFormGroup"
                            [formReset$]="formReset$"
                        ></app-cardholder-access-rights-form>
                        <app-cardholder-advanced-form
                            [readonly]="!!cardholder.readonly"
                            [formGroup]="form.controls.advancedFormGroup"
                            [formReset$]="formReset$"
                        ></app-cardholder-advanced-form>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>

    <!-- Cardholder Loading -->
    <ng-template #noCardholderModel>
        <div class="details-pane__empty">
            <ng-container *ngIf="cardholderId && (errorMessageId$ | async) === null; else noneSelected">
                <gen-spinner>{{ 'STE_LABEL_LOADING_ELLIPSIS' | translate }}</gen-spinner>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #noneSelected>
        <!-- No cardholder selected / Cardholder not found -->
        <gen-text
            *ngLet="errorMessageId$ | async as cardholderErrorMessageId"
            class="details-pane__no-cardholder"
            [flavor]="cardholderErrorMessageId ? TextFlavor.Error : TextFlavor.Secondary"
        >
            {{ cardholderErrorMessageId ?? 'STE_MESSAGE_INFO_NO_CARDHOLDER_SELECTED' | translate }}
        </gen-text>

        <gen-button *ngIf="!isSideBySide" [flavor]="ButtonFlavor.Flat" (click)="onCancelClick()">
            {{ 'STE_BUTTON_BACK' | translate }}
        </gen-button>
    </ng-template>
</div>
