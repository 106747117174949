import { Injectable } from '@angular/core';
import { IGuid } from 'safeguid';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { McProcedure, McProcedureApi } from '../../models/procedure';
import { McClient } from '../mc-client.service';

@Injectable()
export class ProcedureService {
    constructor(private _mcClient: McClient) {}

    public getProcedure(procedureGuid: IGuid | string | null, procedureRevision: number | null): Observable<McProcedure> {
        if (procedureGuid === null) return EMPTY;

        const procedureId = isInstanceOfGuid(procedureGuid) ? procedureGuid.toString() : procedureGuid;
        const url = procedureRevision ? `v2/Procedures/${procedureId}/Revisions/${procedureRevision}` : `v2/Procedures/${procedureId}`;
        return this._mcClient.get<McProcedureApi>(url).pipe(map((procedure) => McProcedure.assign(procedure)));
    }
}
