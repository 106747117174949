import { IGuid, SafeGuid } from 'safeguid';

// ==========================================================================
// Copyright (C) 2021 by Genetec Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
export class OptionTypes {
    public static readonly clearanceOptions: IGuid = SafeGuid.parse('25dccd5a-7ad5-49b7-a5d0-da482288d765');
}
