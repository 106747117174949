import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PopupPosition } from '@genetec/gelato';
import { FilterClient } from '@modules/shared/api/api';
import { TrackedComponent } from '@modules/shared/components/tracked/tracked.component';
import { FiltersService } from '@modules/shared/interfaces/plugins/public/plugin-services-public.interface';
import { FilterState } from '@modules/shared/services/filters/filter';
import { FilterCoordinatorService } from '@modules/shared/services/filters/filter-coordinator-service';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { checkRequiredInputs } from '@src/app/utilities/common-helper';
import { Subscription } from 'rxjs';
import { FilterHostDescriptor } from '../filter-host/filter-host.component';

/**
 * Base component for all child filter types
 *
 * @param T Type of the underlying data model for the filter
 */
@Component({ template: '' })
export abstract class FilterBaseComponent<T> extends TrackedComponent implements OnInit, OnDestroy {
    //#region Decorated fields
    @Input() public descriptor!: FilterHostDescriptor;
    @Input() public popupPosition = PopupPosition.BottomLeft;
    @Output() protected valueChange = new EventEmitter<T | null>();

    //#endregion

    //#region Public fields

    public state: Readonly<FilterState> = { status: 'Ok', selection: this.translateService.instant('STE_LABEL_NO_FILTER_APPLIED') as string, message: '' };

    public get value(): T {
        return this.filterValue;
    }
    public set value(value: T) {
        this.filterValue = value;
        this.updateState();
        this.onValueChanged();
    }

    public dataContext!: FiltersService;
    public isPrimary = false;

    //#endregion

    //#region Protected and Private fields

    protected subscriptions: Subscription[] = [];

    private filterValue!: T;
    private storedValue: T | null = null;

    //#endregion

    constructor(
        trackingService: TrackingService,
        protected filterClient: FilterClient,
        protected filterCoordinatorService: FilterCoordinatorService,
        protected translateService: TranslateService
    ) {
        super(trackingService);
    }

    //#region Pulic Methods

    ngOnInit() {
        checkRequiredInputs({ filterId: this.descriptor.filterId, label: this.descriptor.label });
        this.filterCoordinatorService.register(this);
    }

    ngOnDestroy() {
        this.filterCoordinatorService.unregister(this);
        this.clearFilter();
        this.updateFilter();
    }

    public onFilterToggled(isFilterOpened: boolean): void {}

    /**
     * @example Can be used to update ngrx store or any services that catches the filter value before sending it to backend
     */
    public updateFilter(): void {
        if (this.storedValue && !this.isDirty(this.value, this.storedValue)) {
            return;
        }
        if (this.isDefaulted()) {
            this.valueChange.emit(null);
        } else {
            this.valueChange.emit(this.value);
        }
    }

    /**
     * A method that can be overriden by a subclass. By default, it will return true (we consider that any change to the filters is dirty)
     *
     * @returns a boolean representing the dirtiness of a filter (compared to its value in the ngxs store)
     */
    public isDirty(firstValue: T, secondValue: T): boolean {
        return true;
    }

    //#endregion

    //#region Private methods

    /**
     * Triggered automatically when the value of the filter changes and sends it to a service that handles delayed updates
     */
    private onValueChanged() {
        this.filterCoordinatorService.pushUpdate(this);
    }

    //#endregion

    //#region Abstracts methods
    public abstract clearFilter(): void;
    public abstract isDefaulted(): boolean;
    public abstract updateState(): void;
    public abstract getDefaultValue(): T;
    //#endregion
}
