import { Component, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GenModalService, GenToastService, ToastFlavor } from '@genetec/gelato-angular';
import { SendFeedbackService } from '@modules/general/services/send-feedback.service';
import { TrackedGenModalComponent } from '@modules/shared/components/tracked/tracked-gen-modal.component';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { SecurityCenterClientService } from '@securityCenter/services/client/security-center-client.service';
import { UserEntity } from 'RestClient/Client/Model/UserEntity';
import { TranslateService } from '@ngx-translate/core';
import { deepUpdateValueAndValidity } from '@modules/shared/forms';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { AnalyticsNames } from '@modules/shared/enumerations/analytics-names';
import { SendFeedbackSuccessComponent } from './success-component/send-feedback-success.component';

@Component({
    selector: 'app-send-feedback',
    templateUrl: './send-feedback.component.html',
    styleUrls: ['./send-feedback.component.scss'],
})
export class SendFeedbackComponent extends TrackedGenModalComponent {
    public form = new UntypedFormGroup({
        // AppInsights limitation for a value is 8192, if we change our way to send feedback we can remove that
        message: new UntypedFormControl('', [Validators.required, Validators.maxLength(8192)]),
        email: new UntypedFormControl('', [Validators.required, Validators.maxLength(320), Validators.email]),
    });

    public readonly sendFeedbackGenetecEmail = 'SCWebFeedback@genetec.com';

    constructor(
        injector: Injector,
        trackingService: TrackingService,
        private modalService: GenModalService,
        private securityCenterProvider: SecurityCenterClientService,
        private sendFeedbackService: SendFeedbackService,
        private genToastService: GenToastService,
        private translateService: TranslateService,
        private analyticsService: AnalyticsService
    ) {
        super(injector, trackingService);
        this.analyticsService.logTrace(AnalyticsNames.SendFeedback.DialogOpened);

        this.setCurrentUserEmail().fireAndForget();
    }

    public onSubmit = (): Promise<boolean> => {
        deepUpdateValueAndValidity(this.form);

        if (this.form.valid === false) {
            this.analyticsService.logTrace(AnalyticsNames.SendFeedback.SubmitFailed);
            return Promise.resolve(false);
        }
        // eslint-disable-next-line
        const result = this.sendFeedbackService.sendFeedback(this.form.value.message, this.form.value.email);

        if (result) {
            this.modalService.open(SendFeedbackSuccessComponent);
        } else {
            const error = this.translateService.instant('STE_MESSAGE_ERROR_UNABLE_SEND_FEEDBACK') as string;
            this.genToastService.show({ text: error, flavor: ToastFlavor.Error });
        }

        this.analyticsService.logTrace(AnalyticsNames.SendFeedback.DialogClosedSuccess);
        return Promise.resolve(result);
    };

    public handleCancel = (): Promise<boolean> => {
        this.analyticsService.logTrace(AnalyticsNames.SendFeedback.DialogClosedCancel);
        return Promise.resolve(true);
    };

    private async setCurrentUserEmail(): Promise<void> {
        if (this.securityCenterProvider?.scClient != null) {
            const userEntity = await this.securityCenterProvider.scClient.getEntityAsync(UserEntity, this.securityCenterProvider.scClient.userId);
            if (userEntity !== null && userEntity.emailAddress?.length > 0) {
                this.form.controls.email.setValue(userEntity.emailAddress);
            }
        }
    }
}
